import {
    AccountFilterField,
    SelectFilterField,
    TextFilterField
} from '../../atomic-components/molecules/search/fancy-filter-fields';
import { JobOverviewModel, OverviewModel } from '../abstract';
import { RequestCancellerService } from '../request-canceller';
import { StorageJobModelService } from './storage-job-model';

import * as ng from 'angular';

export class StorageJobOverviewModel extends JobOverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['storageJobModel', '$translate']
    );

    public readonly service = 'storageJob';

    protected listCallbacks = [{callback: this.storageModel.list}];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private storageModel: StorageJobModelService,
        protected $translate: ng.translate.ITranslateService
    ) {
        super(RequestCanceller);
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('jobDisplayName', this.$translate.instant('TR_140119-796b28_TR')),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR')),
                new SelectFilterField(
                    'jobAction',
                    this.$translate.instant('TR_140119-3b64a8_TR'),
                    [{
                        name: this.$translate.instant('TR_140119-50d91c_TR'),
                        value: 'create'
                    }, {
                        name: this.$translate.instant('TR_140119-38c852_TR'),
                        value: 'install'
                    }, {
                        name: this.$translate.instant('TR_140119-a1847d_TR'),
                        value: 'update'
                    }, {
                        name: this.$translate.instant('TR_221220-fb6a14_TR'),
                        value: 'AddIndividualDomainName'
                    }, {
                        name: this.$translate.instant('TR_221220-b360a2_TR'),
                        value: 'updateIndividualDomainName'
                    }, {
                        name: this.$translate.instant('TR_221220-b52c48_TR'),
                        value: 'enableNextcloud'
                    }, {
                        name: this.$translate.instant('TR_221220-145347_TR'),
                        value: 'startMaintenance'
                    }, {
                        name: this.$translate.instant('TR_221220-6d4572_TR'),
                        value: 'revokeAdminAccess'
                    }, {
                        name: this.$translate.instant('TR_221220-c3b6c2_TR'),
                        value: 'nextcloudUpgrade'
                    }, {
                        name: this.$translate.instant('TR_221220-582b89_TR'),
                        value: 'nextcloudCliUpdate'
                    }, {
                        name: this.$translate.instant('TR_101219-1b649a_TR'),
                        value: 'modifyNextcloudUsers'
                    }, {
                        name: this.$translate.instant('TR_221220-580235_TR'),
                        value: 'grantAdminAccess'
                    }, {
                        name: this.$translate.instant('TR_221220-4f59ae_TR'),
                        value: 'removeIndividualDomainName'
                    }, {
                        name: this.$translate.instant('TR_221220-36b7a9_TR'),
                        value: 'modifyNextcloudGroupFolders'
                    }, {
                        name: this.$translate.instant('TR_120819-f55f41_TR'),
                        value: 'setupIndividualDomainName'
                    }, {
                        name: this.$translate.instant('GENERAL.JOB.ACTION.RESET'),
                        value: 'reset'
                    }, {
                        name: this.$translate.instant('TR_140119-aee71e_TR'),
                        value: 'delete'
                    }, {
                        name: this.$translate.instant('TR_140119-447f57_TR'),
                        value: 'purgeRestorable'
                    }, {
                        name: this.$translate.instant('TR_140119-d46abf_TR'),
                        value: 'deleteScheduled'
                    }, {
                        name: this.$translate.instant('TR_140119-2c5206_TR'),
                        value: 'deletionCancel'
                    }, {
                        name: this.$translate.instant('TR_140119-e90ad6_TR'),
                        value: 'purgeRestorable'
                    }, {
                        name: this.$translate.instant('TR_140119-d1b7a4_TR'),
                        value: 'restore'
                    }]),
                new SelectFilterField(
                    'jobStatus',
                    this.$translate.instant('TR_140119-8e4b60_TR'),
                    [{
                        name: this.$translate.instant('TR_140119-888f29_TR'),
                        value: 'timed'
                    }, {
                        name: this.$translate.instant('TR_140119-601a80_TR'),
                        value: 'new'
                    }, {
                        name: this.$translate.instant('TR_140119-d5047f_TR'),
                        value: 'inProgress'
                    }, {
                        name: this.$translate.instant('TR_140119-8bd7bf_TR'),
                        value: 'successful'
                    }, {
                        name: this.$translate.instant('TR_140119-8399ef_TR'),
                        value: 'failed'
                    }, {
                        name: this.$translate.instant('TR_140119-7acde1_TR'),
                        value: 'support'
                    }, {
                        name: this.$translate.instant('TR_140119-a235c9_TR'),
                        value: 'canceled'
                    }, {
                        name: this.$translate.instant('TR_140119-5b87d8_TR'),
                        value: 'done'
                    }]
                )
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }
}
