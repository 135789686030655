import { UiLanguageDefaultSettingsConst } from '@/configuration';
import {
    AlertManagerService,
    AuthContextService, NavigationService,
    RightModelService,
    UserModelService
} from '@/services';
import * as Types from '@/types';

import * as ng from 'angular';

export class OrganismEditFormUserCreateController {
    public static $inject: string[] = [
        '$state',
        '$translate',
        'alertManager',
        'rightModel',
        'userModel',
        'navigation'
    ];

    public rightsTemplates: Types.AccountApi.RightsTemplate[];
    public rightsTemplatesDropdownItems: string|any[];
    public allRights: any[];
    public parentAccountId: string;
    public password: string;
    public newUser: any = {
        language: UiLanguageDefaultSettingsConst.languageApiIso,
        rights: [],
        rightsTemplateId: ''
    };

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private rightModel: RightModelService,
        private userModel: UserModelService,
        private navigation: NavigationService
    ) {}

    public $onInit(): void {
        if (this.$state.current.name.split('.').indexOf('subaccounts') < 0) {
             this.parentAccountId = AuthContextService.account.id;
        } else {
            this.parentAccountId = this.$state.$current.locals.globals.account.id;
        }

        this.createRightsTemplatesDropdownItems(this.rightsTemplates);
    }

    public updateRightsTemplates = (parentAccountId: string): void => {
        const filter: Types.Finding.Filter = {
            subFilter: [
                {
                    field: 'accountId',
                    value: parentAccountId
                },
                {
                    field: 'rightsTemplateUsableForUsers',
                    value: '1'
                }
            ],
            subFilterConnective: 'AND'
        };

        void this.rightModel.rightsTemplate(undefined, undefined, filter)
        .then(
            (reply) => {
                if (
                    !reply.data.some(
                        (template) => template.id === this.newUser.rightsTemplateId
                    )
                ) {
                    this.newUser.rightsTemplateId = '';
                }

                this.createRightsTemplatesDropdownItems(reply.data);
            }
        );
    };

    public createUser = (): void => {
        const user: Types.AccountApi.User = {...this.newUser};
        user.accountId = this.parentAccountId;
        this.userModel.create(user, this.password, AuthContextService.account.id).then(
            () => {
                if (this.$state.current.name.split('.').indexOf('subaccounts') < 0) {
                    void this.navigation.go('account.users.overview');
                } else {
                    void this.navigation.go('reseller.subaccounts.id.edit');
                }
            },
            () => {
                this.alertManager.error(this.$translate.instant('TR_110119-c81957_TR'));
            }
        );
    };

    private createRightsTemplatesDropdownItems = (rightsTemplates: Types.AccountApi.RightsTemplate[]): void => {
        this.rightsTemplatesDropdownItems = [{
            name: 'keine',
            value: ''
        }];

        for (const template of rightsTemplates) {
            this.rightsTemplatesDropdownItems.push({
                name: template.name,
                value: template.id
            });
        }
    };
}

export class OrganismEditFormUserCreateComponent implements ng.IComponentOptions {
    public bindings = {
        allRights: '<',
        rightsTemplates: '<'
    };

    public controller = OrganismEditFormUserCreateController;
    public template = require('./user-create.html');
}
