import * as ng from 'angular';
import * as Types from '../../../../../types';

import { DatabaseComposerService } from '../../../../../services/database/database-composer';
import { ViewTypes } from '../../../../../types/view-types';

export class OrganismDatabaseWizardWrapperController {
    public static $inject: string[] = ['$timeout', 'databaseComposer'];

    public outerAccount: Types.AccountApi.Account;
    public outerProductCode: string;
    public productFamily: string;
    public account: Types.AccountApi.Account;
    public productCode: string;

    public wizardComposerObject: Types.ViewTypes.WizardComposerObject = {
        apiObject: {},
        summaryObject: []
    };
    public viewType = 'config';

    constructor(
        private $timeout: ng.ITimeoutService,
        private databaseComposer: DatabaseComposerService
    ) {}

    public loadSummaryView = (metadata: ViewTypes.ProductConfigDatabaseObject) => {
        this.wizardComposerObject.summaryObject = [];
        metadata.productFamily = this.productFamily;

        this.databaseComposer.buildDatabaseApiObject(metadata)
        .then(
            (composerObject) => {
                this.$timeout(
                    () => {
                        this.viewType = 'summary';
                        this.wizardComposerObject = composerObject;
                    }
                );
            }
        );
    };

    public loadConfirmView = () => {
        this.viewType = 'confirm';
    };
}

export class OrganismDatabaseWizardWrapperComponent implements ng.IComponentOptions {
    public bindings = {
        outerAccount: '<?account',
        outerProductCode: '<?productCode'
    };
    public template = require('./database-wizard-wrapper.html');
    public controller = OrganismDatabaseWizardWrapperController;
}
