import { UiRights } from '../../../../configuration';
import { AuthContextService } from '../../../../services';
import { Breadcrumb, BreadcrumbItem } from '../breadcrumb';
import { BreadcrumbTopicModel, TopicCases } from './topic-model';

export class BreadcrumbMailboxTopicModel extends BreadcrumbTopicModel {
    public static $inject: string[] = BreadcrumbTopicModel.$inject.concat(['breadcrumbDomainTopicModel']);

    public readonly topicName = 'mailbox';
    public readonly specialTopicCases: TopicCases;

    /**
     *   List of route parts, which are excluded from mail class generalRoutes methode
     */
    public readonly excludeGeneralRouteParts = ['edit', 'overview', 'new'];

    private mailboxActionDropdownItems: BreadcrumbItem[] = [];

    constructor(protected $state: ng.ui.IStateService, protected $translate: ng.translate.ITranslateService) {
        super($state, $translate);

        this.mailboxActionDropdownItems = [
            {
                isGranted: UiRights.EMAIL_MAILBOX_LIST,
                part: 'overview',
                route: 'email.mailboxes.overview'
            },
            {
                isGranted: UiRights.EMAIL_JOBS_LIST,
                part: 'jobs',
                route: 'email.mailboxes.jobs'
            },
            {
                isGrantedAny: [
                    UiRights.EMAIL_MAILINGLIST_CREATE,
                    UiRights.EMAIL_IMAPMAILBOX_CREATE,
                    UiRights.EMAIL_FORWARDER_CREATE,
                    UiRights.EMAIL_EXCHANGE_CREATE,
                    UiRights.EMAIL_CATCHALL_CREATE
                ],
                part: 'new',
                route: 'email.mailboxes.new'
            },
            {
                isGranted: UiRights.EMAIL_ORGANIZATION_LIST,
                part: 'organizations',
                route: 'email.organizations.overview',
                translationId: 'TR_110119-f722ac_TR'
            }
        ];

        this.specialTopicCases = {
            details: this.jobDetailsRoute,
            domainsettings: this.getDomainSettingRoute,
            edit: this.getEditRoute,
            id: this.getIdRoute,
            jobs: () => this.getProductActionDropdown(this.mailboxActionDropdownItems),
            new: () => this.getProductActionDropdown(this.mailboxActionDropdownItems),
            organizations: () => this.getProductActionDropdown(this.mailboxActionDropdownItems),
            overview: this.getOverviewsRoute
        };
    }

    public getIdRoute = () => {
        let breadcrumbs = [];
        if (this.steps[this.index + 1] === 'restrictions') {
            breadcrumbs = this.getProductActionDropdown(this.mailboxActionDropdownItems, {}, 'overview');
            breadcrumbs.push({
                active: false,
                route: 'email.mailboxes.id.edit',
                routeParams: { mailboxId: this.globals.mailbox.id },
                type: 'text',
                value: this.getActiveDropdownValue({ part: 'edit' })
            });
            return breadcrumbs;
        }
    };

    public getFirstLevelData = (): Breadcrumb[] => {
        this.breadcrumbs.push({
            active: false,
            type: 'text',
            value: this.$translate.instant('MENU.MAIN.PRODUCTS.LABEL')
        });

        if (AuthContextService.isGranted(UiRights.EMAIL_MAILBOX_LIST)) {
            this.breadcrumbs = this.breadcrumbs.concat(
                this.getServiceListDropdownBox({
                    active: this.steps.length === 2,
                    isGranted: UiRights.EMAIL_MAILBOX_LIST,
                    name: this.$translate.instant('TR_080119-8dd4a1_TR'),
                    route: 'email.dashboard',
                    routeParams: {},
                    step: 'mailboxes',
                    title: this.$translate.instant('TR_080119-e997a1_TR')
                })
            );
        }

        return this.breadcrumbs;
    };

    public getOverviewsRoute = (): Breadcrumb[] => {
        switch (this.steps[this.index - 1]) {
            case 'mailboxes':
                return this.getProductActionDropdown(this.mailboxActionDropdownItems);
            default:
                break;
        }
    };

    public getEditRoute = (): Breadcrumb[] => {
        let breadcrumbs: Breadcrumb[] = [];
        let route = '';
        let routeParams = {};

        if (this.steps[this.index - 2] === 'mailboxes') {
            route = 'email.mailboxes.id.edit';
            routeParams = { mailboxId: this.globals.mailbox.id };
            breadcrumbs = this.getProductActionDropdown(this.mailboxActionDropdownItems, routeParams, 'overview');
        }

        breadcrumbs.push({
            active: true,
            route: route,
            routeParams: routeParams,
            type: 'text',
            value: this.getActiveDropdownValue({ part: 'edit' })
        });
        return breadcrumbs;
    };

    public getDomainSettingRoute = (): Breadcrumb[] => [
        {
            active: true,
            route: '',
            type: 'text',
            value: this.$translate.instant('TR_090119-7178c1_TR')
        }
    ];
}
