import { CronjobPathPrefixConst } from '../../configuration';
import { Validator } from './abstract-validator';

import * as ng from 'angular';

export class ValidateCronjobScript extends Validator {

    constructor(private $translate: ng.translate.ITranslateService) {
        super();
    }

    public validate = (value: string) => {
        const missingScriptNameText = 'Bitte geben Sie ein auszuführendes Skript an.';

        if ([undefined, null, ''].indexOf(value) >= 0) {
            return [{
                text: missingScriptNameText
            }];
        }

        const onlyPrefixSet = CronjobPathPrefixConst.some((prefix) => prefix.value === value);
        if (onlyPrefixSet) {
            return [{ text: missingScriptNameText }];
        }

        const valueParts = value.split(' ');
        if (valueParts.length > 1) {
            return [{ text: this.$translate.instant('TR_170119-57da8c_TR') }];
        }

        return [];
    };
}
