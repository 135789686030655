import * as ng from 'angular';
import { WizardObject } from '..';
import {
    AuthContextService,
    BundleHelperService
} from '../../../../services';
import { WizardHelperService } from '../wizard-helper';

import './wizard-pool-bundle-availability.scss';

export class MoleculeWizardPoolBundleAvailabilityController implements ng.IController {
    public static $inject: string[] = ['$q', 'authContext', 'bundleHelper', 'wizardHelper', '$translate'];

    public serviceObject: WizardObject;
    public contingentObject;
    public selectedProductFamily; // unused?
    private _availableContingentItemsCache = [];
    private _contingentProductCode: string;
    private _availablePoolListCache: any;

    constructor(
        private $q: ng.IQService,
        private authContext: AuthContextService,
        private bundleHelper: BundleHelperService,
        private wizardHelper: WizardHelperService,
        protected $translate: ng.translate.ITranslateService
    ) {
        this.bundleHelper.resetBundleCache();
    }

    public $onInit() {
        // reset Cache
        this._availableContingentItemsCache = [];
        this.bundleHelper.resetBundleCache();
    }

    public $onChanges(changes) {
        if (changes.selectedProductFamily !== undefined
            && changes.selectedProductFamily.currentValue !== changes.selectedProductFamily.previousValue) {
            this.checkProductContingents();
        }
    }

    public selectedContingent = (selectedContingent) => {
        this.serviceObject.settings.selectedContingent = selectedContingent;

        if ([undefined, null].indexOf(selectedContingent) >= 0) {
            // order at cost - reset selectedProce for choosing billingCycle
            this.serviceObject.selectedProduct.selectedPrice = null;
            this.serviceObject.display.contingentType = null;
            this.serviceObject.display.contingent = null;
        } else {
            // select a contingent
            const contingentItem = this._getContingent(selectedContingent);
            this.serviceObject.display.contingentType = contingentItem.type;
            this.serviceObject.display.contingent = contingentItem;

            if (contingentItem.type === 'bundle') {
                this.serviceObject.selectedProduct.selectedPrice = this.serviceObject.selectedProduct.prices
                .filter(
                    (price) => {
                        return price.productCode === this._contingentProductCode;
                    }
                )[0];
            } else {
                let price = null;

                this.serviceObject.selectedProduct.prices
                .map(
                    (productPrice) => {
                        if (price === null || productPrice.billingCycle < price.billingCycle) {
                            price = productPrice;
                        }
                    }
                );

                this.serviceObject.selectedProduct.selectedPrice = price;
            }
        }
    };

    public checkProductContingents = () => {
        this._resetContingentData();
        this.wizardHelper.setProductType();

        if ([undefined, null, false].indexOf(this.serviceObject.display.inBundle) === -1) {
            const bundleContingents = this._getBundleContingents([this.serviceObject.display.inBundle]);

            if (bundleContingents.length > 0) {
                const bundleContingentObject = this._setContingentItemObject(bundleContingents[0], 'bundle');
                this.serviceObject.display.availableContingentItems.push(bundleContingentObject);
                this.selectedContingent(this.serviceObject.display.availableContingentItems[0].value);
                this._normalizeContingentsItems();
            }

            return;
        }

        let contingentObject;
        const promises = [];
        promises.push(this._checkPoolContingents());
        promises.push(this._checkBundleContingent());

        this.$q.all(promises)
        .then(
            (results) => {
                results.map(
                    (result, index) => {
                        result.map(
                            (object) => {
                                contingentObject = this._setContingentItemObject(
                                    object, index === 0 ? 'pool'  : 'bundle'
                                );
                                this.serviceObject.display.availableContingentItems.push(contingentObject);
                            }
                        );
                    }
                );

                this._normalizeContingentsItems();
                this.selectedContingent(this.serviceObject.display?.availableContingentItems[0]?.value);
            }
        );
    };

    public get contingentAvailable() {
        return this.serviceObject.display.contingentAvailable;
    }

    // temporary fix until we decide how to handle pools with multiple resources
    public get availablePoolList() {
        const poolIdList = [];
        const poolList = [];
        if ([undefined].indexOf(this._availablePoolListCache) < 0
            && this.serviceObject.display.availableContingentItems.length === this._availablePoolListCache.length) {
            return this._availablePoolListCache;
        } else {
            this.serviceObject.display.availableContingentItems
            .map(
                (contingentItem) => {
                    if (poolIdList.indexOf(contingentItem.value) < 0) {
                        poolIdList.push(contingentItem.value);
                        poolList.push(contingentItem);
                    }
                }
            );

            this._availablePoolListCache = poolList;
            return poolList;
        }
    }

    private _setContingentItemObject = (object, type) => {
        let name = '';
        let  id = '';
        let poolId: null|string = null;
        switch (type) {
            case 'bundle':
                name = this.$translate.instant('TR_100119-90ef6e_TR') + ': \''  + object.name  + '\'';
                id = object.id;
                break;
            case 'pool':
                name = this.$translate.instant('TR_100119-fdd58c_TR') + ' \''
                    + ([undefined].indexOf(object.poolName) >= 0 ? object.name : object.poolName) + '\''
                    + ([undefined, '[VM not found]'].indexOf(object.virtualMachineName) >= 0 ? '' : ' ('
                    + object.virtualMachineName + ')');
                id = object.id;
                poolId = (object.poolId === undefined ? object.id : object.poolId);
                break;
            default:
                break;
        }
        return {
            name: name,
            poolId: poolId,
            type: type,
            value: id
        };
    };

    private _resetContingentData = () => {
        this.serviceObject.display.availableContingentItems = [];
        this.serviceObject.display.contingentAvailable = false;
        this.selectedContingent(null);
    };

    private _normalizeContingentsItems = () => {
        this.serviceObject.display.contingentAvailable = this._issetAvailableContingentItems();
        if (this.serviceObject.display.contingentAvailable && !this.serviceObject.display.inBundle) {
            this.serviceObject.display.contingentAvailable = this.serviceObject.display.availableContingentItems
            .push(
                {
                    name: this.$translate.instant('TR_100119-b13e52_TR'),
                    type: 'chargeable',
                    value: null
                }
            );
        }
        if ([undefined, null].indexOf(this.serviceObject.settings.selectedContingent) === -1) {
            const selectedContingentAvailable = this.serviceObject.display.availableContingentItems
            .some((item) => item.value === this.serviceObject.settings.selectedContingent);

            if (!selectedContingentAvailable) {
                this.serviceObject.settings.selectedContingent = undefined;
            }
        }
    };

    private _checkBundleContingent = () => {
        return this.bundleHelper.getActiveAccountBundles(this.serviceObject.settings.accountId)
            .then(this._getBundleContingents);
    };

    private _getBundleContingents = (bundles) => {
        // get form selected product, possible productCodes
        const productCodeList = this.serviceObject.selectedProduct.prices.map((priceObject) => priceObject.productCode);

        // get bundles with contingent of productcodes
        const items = bundles
        .filter(
            (bundle) =>  productCodeList
            .some(
                (productCode) => {
                    const hasContingent = this.bundleHelper.checkBundleContingents(productCode, bundle);

                    if (hasContingent) {
                        this._contingentProductCode = productCode;
                    }

                    return hasContingent;
                }
            )
        );

        const bundleContingents = {
            accountId: this.serviceObject.settings.accountId,
            items: items
        };
        // add bundleContingents to cache
        this._availableContingentItemsCache.push(bundleContingents);
        return items;
    };

    private _checkPoolContingents = () => {
        return this.wizardHelper.inPool(this.serviceObject);
    };

    private _issetAvailableContingentItems = () => {
        if (this.serviceObject.display === undefined
            || this.serviceObject.display.availableContingentItems === undefined
        ) {
            return false;
        }
        if (this.serviceObject.display.availableContingentItems.length > 1) {
            return true;
        }

        if (this.serviceObject.display.availableContingentItems.length === 1) {
            return this.serviceObject.display.availableContingentItems.some(
                (item) => ['bundle', 'pool'].indexOf(item.type) >= 0
            );
        }

        return false;
    };

    private _getContingent = (contingentId) => {
        let contingentObject = null;
        this.serviceObject.display.availableContingentItems.some((item) => {
            if (item.value === contingentId) {
                contingentObject = item;
                return true;
            }
            return false;
        });
        return contingentObject;
    };
}

export class MoleculeWizardPoolBundleAvailabilityComponent implements ng.IComponentOptions {
    public bindings = {
        selectedProductFamily: '<',
        serviceObject: '='
    };
    public controller =  MoleculeWizardPoolBundleAvailabilityController;
    public template = require('./wizard-pool-bundle-availability.html');
}
