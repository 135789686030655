import * as ng from 'angular';
import { WebspaceModelService } from '../services/webhosting/webspace-model';

export class WebspaceName {
    public static $inject: string[] = ['webspaceModel'];

    public static Factory(webspaceModel: WebspaceModelService) {
        const cache = {};

        const webspaceNameFilter: any = (value) => {
            if (cache[value]) {
                if (cache[value] !== 'waiting') {
                    return cache[value].name;
                }
                return undefined;
            }
            cache[value] = 'waiting';
            webspaceModel.findOne(value)
            .then(
                (result) => {
                    cache[result.id] = result;
                },
                () => {
                    // Wenn der Request fehlschägt, versuchen wir es nicht erneut
                    cache[value] = 'x';
                }
            );

            return undefined;
        };

        webspaceNameFilter.$stateful = true;

        return webspaceNameFilter;
    }
}
