import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateWebhostingVhostSslEditController {
    public static $inject: string[] = ['$translate'];

    public modifier = '';
    public pageHeaderData: PanelHeaderData;

    public webspace: any;
    public vHost: any;
    public profiles: any;
    public phpIni: any;
    public userPanelRight: any;
    public backRoute: any;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit = () => {
        const vHost = ng.copy(this.vHost);
        const webspace = ng.copy(this.webspace);

        this.pageHeaderData = {
            backwardLink: webspace.bundleId
                ? 'bundle.id.domains.id.edit'
                : 'webhosting.webspaces.id.vhosts.id.edit',
            backwardText: this.$translate.instant('TR_140119-da8d35_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: webspace.bundleId
                ? { bundleId: webspace.bundleId, domainId: vHost.id, vhost: 'vhost' }
                : { webspaceId: webspace.id, domainId: vHost.id },
            panelIcon: 'globe',
            panelTitle: this.$translate.instant(
                /* translationId */ 'TR_140119-7f9bb0_TR',
                { domainName: vHost.domainNameUnicode }
            )
        };

        this.backRoute = {
            route: ng.copy(this.pageHeaderData.backwardLink),
            routeParams: ng.copy(this.pageHeaderData.panelHeaderRouteParams)
        };

        this.userPanelRight = {
            editPanelButton: true
        };
    };
}

export class TemplateWebhostingVhostSslEditComponent implements ng.IComponentOptions {
    public bindings = {
        phpIni: '<',
        profiles: '<',
        vHost: '<',
        webspace: '<'
    };
    public controller = TemplateWebhostingVhostSslEditController;
    public controllerAs = '$TemplateWebhostingVhostSslEditCtrl';
    public template = require('./vhost-edit-ssl-template.html');
}
