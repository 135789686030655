import * as ng from 'angular';

import { PanelHeaderData } from '../../../atomic-components/molecules/panels';
import * as Types from '../../../types';

export class TemplateDomainRemoveFromBundleController {
    public static $inject: string[] = ['$translate'];

    public bundle: Types.BundleApi.Bundle;
    public domain: Types.DomainApi.Domain;
    public pageHeaderData: PanelHeaderData;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit() {
        this.pageHeaderData = {
            backwardLink: 'domain.domains.id.edit',
            backwardText: this.$translate.instant('TR_110119-c3e68c_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: { domainId: this.domain.id },
            panelIcon: 'globe',
            panelTitle: this.$translate.instant('TR_061020-076f51_TR')
        };
    }
}

export class TemplateDomainRemoveFromBundleComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<',
        domain: '<'
    };
    public template = require('./domain-remove-from-bundle-template.html');
    public controller = TemplateDomainRemoveFromBundleController;
}
