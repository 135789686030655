import * as ng from 'angular';
import {
    AccountModelService,
    AlertManagerService,
    AuthContextService,
    NavigationService,
    WebhostingUserModelService
} from '../../../../../services';
import * as Types from '../../../../../types';
import { EditPanelStatus } from '../../../../molecules/forms/form-edit/form-edit';

export class OrganismNewFormWebhostingUserController {
    public static $inject: string[] = [
        '$q',
        '$translate',
        'alertManager',
        'accountModel',
        'authContext',
        'navigation',
        'webhostingUserModel'
    ];

    public user: Types.WebhostingApi.User;
    public password: string;
    public accountId: string;
    public showAccountSelect = false;
    public userPanelRight: unknown;
    public initialStatus = EditPanelStatus.ACTIVE;

    constructor(
        private $q: ng.IQService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        accountModel: AccountModelService,
        private authContext: AuthContextService,
        private navigation: NavigationService,
        private webhostingUserModel: WebhostingUserModelService
    ) {
        void accountModel.listSubaccountsWithoutPagination(1).then(
            (res) => this.showAccountSelect = res.data.length > 0
        );
    }

    public save = (): PromiseLike<unknown> => {
        if (this.accountId) {
            this.user.accountId = this.accountId;
        }

        return this.webhostingUserModel.createUser(this.user, this.password)
            .then(
                (user) => {
                    void this.alertManager.success(this.$translate.instant('TR_050219-1fa65e_TR'));
                    void this.navigation.go('webhosting.users.id.edit', { userId: user.id }, {reload: true});
                    return this.$q.reject();
                }
            );
    };

    public $onInit = (): void => {
        this.accountId = this.authContext.account.id;
        this.user = {
            name: ''
        };
    };
}

export class OrganismNewFormWebhostingUserComponent implements ng.IComponentOptions {
    public bindings = {
        userPanelRight: '='
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismNewFormWebhostingUserController;
    public template = require('./webhosting-user-new.html');
}
