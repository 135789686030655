import * as ng from 'angular';
import { UiRights } from '../../../../configuration';
import { AccountModelService, AuthContextService } from '../../../../services';
import { SelectedProduct } from '../../../../services';
import { ApiErrorModel, ApiErrorObject } from '../../../../services';

export interface WizardObject {
    service: string;
    selectedProduct: SelectedProduct;
    productFamilies: string[];
    active: boolean;
    completed: boolean;
    settings: any;
    display: any;
    installFunction: () => any;
    children: any;
    onAccountChange?: () => any;
}

export class MoleculeWizardSupervisorController implements ng.IController {
    public static $inject: string[] = ['$q', 'apiErrorModel', 'accountModel', 'authContext'];
    public wizardObjects: WizardObject[];
    public orderResponses: any[];
    public displayOrderConfirmation = false;
    public subAccountDropdownItems: any[] = [];
    public requestsWithError = false;
    public apiErrors: ApiErrorObject[] = [];

    constructor(
        private $q: ng.IQService,
        private apiErrorModel: ApiErrorModel,
        private accountModel: AccountModelService,
        private authContext: AuthContextService
    ) {}

    public $onInit() {
        if (this.authContext.isGranted(UiRights.ACC_SUBACCOUNT_LIST)) {
            // Load subaccounts of the reseller
            this.accountModel.listSubaccountsWithoutPagination().then(
                (reply) => reply.data.map(
                    (subAccount) => this.subAccountDropdownItems.push({name: subAccount.name, value: subAccount.id})
                )
            );
        }
    }

    public get displaySummaryPage() {
        return this.wizardObjects.every(this.isCompleted) && !this.displayOrderConfirmation;
    }

    public orderObjects: () => ng.IPromise<any> = () => {
        const promises: any[] = this.wizardObjects
        .map((object) => object.installFunction());

        return this.$q.all(promises).then(
            (res) => {
                this.orderResponses = res;
                this.displayOrderConfirmation = true;
                this.apiErrors = this.apiErrorModel.errorObjectList;
                this.requestsWithError = res.some((r) => r.status === 'error');
            }
        );
    };

    private isCompleted = (object: WizardObject) => {
        return object.completed || false;
    };
}

export class MoleculeWizardSupervisorComponent implements ng.IComponentOptions {
    public bindings = {
        wizardObjects: '='
    };
    public template = require('./wizard-supervisor.html');
    public controller =  MoleculeWizardSupervisorController;
}
