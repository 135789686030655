import * as ng from 'angular';
import * as q from 'q';
import {
    ApiObject,
    AuthContextService,
    BundleModelService,
    DomainHelperService,
    DomainModelService,
    VhostModelService,
    WebspaceModelService
} from '../';
import { UiRights } from '../../configuration';
import * as Types from '../../types';

export class BundleHelperService {
    public static $inject: string[] = [
        'bundleModel',
        'domainHelper',
        'domainModel',
        'webspaceModel',
        'vhostModel'
    ];
    private tmpBundleCache = {
        accountBundles: [],
        bundleDomains: [],
        bundles: []
    };
    private bundleCache = {
        accountBundles: [],
        bundleDomains: [],
        bundles: []
    };
    private bundleContingents = {};

    constructor(
        private bundleModel: BundleModelService,
        private domainHelper: DomainHelperService,
        private domainModel: DomainModelService,
        private webspaceModel: WebspaceModelService,
        private vhostModel: VhostModelService
    ) {}

    public getContingent = (productCode, type) => {
        return this.bundleContingents[productCode] !== undefined ? this.bundleContingents[productCode][type] : 0;
    };

    public checkBundleContingents = (productCode, bundle) => {
        if ([null, undefined].indexOf(bundle) >= 0)  {
            return false;
        }

        return bundle.effectiveContingentUsage.some((contingent) => {
            if (contingent.productCodes.indexOf(productCode) >= 0 && contingent.availableCapacity > 0) {
                this.bundleContingents[productCode] = {inUse: contingent.usedCapacity, limit: contingent.totalCapacity};
                return true;
            }
            return false;
        });
    };

    public checkBundleContingentsWithProductCodePart = (productCode, bundle) => {
        if ([null, undefined].indexOf(bundle) >= 0)  {
            return false;
        }

        return bundle.effectiveContingentUsage.some((contingent) => {
            return contingent.productCodes.some((contingentProductCode) => {
                if (contingentProductCode.indexOf(productCode) >= 0 && contingent.availableCapacity > 0) {
                    this.bundleContingents[productCode] = {
                        inUse: contingent.usedCapacity,
                        limit: contingent.totalCapacity
                    };
                    return true;
                }
                return false;
            });
        });
    };

    public getBundle = (bundleId): PromiseLike<any> => {
        if (!AuthContextService.isGranted(UiRights.BIL_BUNDLE_LIST)) {
            return null;
        }

        if (this.checkBundleCache('bundles', bundleId)) {
            return q.resolve(this.getBundleCache('bundles', bundleId));
        } else {
            return this.bundleModel.findOne(bundleId).then((bundle) => {
                this.addBundleCache('bundles', bundleId, bundle);
                return bundle;
            });
        }
    };

    public getBundleData = (bundleId, products) => {
        const bundleData = {
            bundle: null,
            bundleDomains: null,
            contingents: {},
            hasContingents: false
        };

        if (
            !AuthContextService.isGranted(UiRights.BIL_BUNDLE_LIST)
            || [null, 'undefined'].indexOf(bundleId) >= 0
            || [null, 'undefined'].indexOf(products) >= 0
        ) {
            return q.resolve(bundleData);
        }

        return this.getBundle(bundleId)
        .then((bundle) => {
            bundleData.bundle = bundle;
            const hasContingents = products.map((product) => {
                const productCode = Object.getOwnPropertyNames(product)[0];
                if (this.checkBundleContingents(productCode, bundle)) {
                    bundleData.contingents[productCode] = {
                        inUse: this.getContingent(productCode, 'inUse'),
                        limit: this.getContingent(productCode, 'limit')
                    };
                    return true;
                } else {
                    bundleData.contingents[productCode] = null;
                    return false;
                }
            });
            if (hasContingents.length) {
                // prüfe ob domains im Bundle vorhanden sind!
                 return this.getBundleDomains(bundle.id)
                    .then((bundleDomains) => {
                        bundleData.bundleDomains = bundleDomains;
                        bundleData.hasContingents = true;
                        return bundleData;
                    });
            }
            bundleData.hasContingents = false;
            return bundleData;
        });
    };

    public getBundleDomains = (bundleId) => {
        if (AuthContextService.isGranted(UiRights.BIL_BUNDLE_LIST) && bundleId !== undefined) {
            const promises = [];
            let vhostFilter = {};
            const domainFilter = {
                subFilter: [
                    {field: 'bundleId', value: bundleId},
                    {field: 'domainStatus', value: 'active'}
                ],
                subFilterConnective: 'AND'
            };

            if (this.checkBundleCache('bundleDomains', bundleId)) {
                return q.resolve(this.getBundleCache('bundleDomains', bundleId));
            }

            promises.push(this.domainModel.list(50, 1, domainFilter).then((domains) => domains.data));
            promises.push(this.webspaceModel.list(1, 1, {field: 'bundleId', value: bundleId}, null)
                .then((response) => {
                    return response.data.length > 0 ? response.data[0].id : null;
                })
                .then((webspaceId) => {
                    vhostFilter = {
                        subFilter: [
                            {field: 'webspaceId', value: webspaceId},
                            {field: 'vhostStatus', value: 'active'}
                        ],
                        subFilterConnective: 'AND'
                    };
                    return this.vhostModel.list(250, 1, vhostFilter).then((vhosts) => vhosts.data);
                })
            );

            return q.all(promises).then((results) => {
                const bundleDomains = [];
                results[0].forEach((domain) => {
                    bundleDomains.push({nameUnicode: domain.nameUnicode});
                });
                results[1].forEach((vhost) => {
                    const isIncludes = bundleDomains.some((domain) => {
                        return domain.nameUnicode === vhost.domainNameUnicode;
                    });
                    if (!isIncludes) {
                        bundleDomains.push({nameUnicode: vhost.domainNameUnicode});
                        if (vhost.enableAlias) {
                            let domainNameUnicodeEnableAlias = 'www.' + vhost.domainNameUnicode;
                            if (vhost.domainNameUnicode.startsWith('www.')) {
                                domainNameUnicodeEnableAlias = vhost.domainNameUnicode.replace('www.', '');
                            }
                            bundleDomains.push({nameUnicode: domainNameUnicodeEnableAlias});
                        }
                        if (vhost.additionalDomainNamesUnicode.length > 0) {
                            vhost.additionalDomainNamesUnicode.forEach((additionalDomainNameUnicode) => {
                                bundleDomains.push({nameUnicode: additionalDomainNameUnicode});
                            });
                        }
                    }
                });

                bundleDomains.sort((a, b) => {
                    if (a.nameUnicode > b.nameUnicode) {
                        return 1;
                    }
                    if (a.nameUnicode < b.nameUnicode) {
                        return -1;
                    }

                    return 0;
                });
                this.addBundleCache('bundleDomains', bundleId, bundleDomains);
                return bundleDomains;
            });
        }
        return null;
    };

    public getBundleVhosts = (bundle: Types.BundleApi.Bundle, limit?: number) => {
        const bundleHandler = ApiObject.bundle(bundle);
        const returnData = {
            data: []
        };
        limit = limit || 10;

        return this.bundleModel.bundleVhostFind(bundle.id).then(
            (vhosts) => {
                if (vhosts.status === 'successful') {
                    vhosts.roots.map(
                        (vhost) => {
                            const childrens = [];
                            this.domainHelper.normalizeVhostTree(vhost, bundle.id);
                            returnData.data.push(
                                DomainHelperService.setVhostOverviewObject(
                                    { name: vhost.name, nameUnicode: vhost.nameUnicode },
                                    bundle.id,
                                    vhost.domainDetails,
                                    vhost.vHostDetails,
                                    false,
                                    true
                                )
                            );

                            if (childrens.length > 0) {
                                childrens.map(
                                    (child) => returnData.data.push(child)
                                );
                            }
                        }
                    );

                    return q.when(returnData);
                } else {
                    // more than max vhost in bundles - load default overview
                    if (bundleHandler.hasWebspaceContingent) {
                        return this.webspaceModel.listWithoutPagination(
                            1,
                            1,
                            { field: 'BundleId', value: bundle.id }
                        ).then((webspace) => {
                            let overviewData;
                            if (webspace.data.length > 0) {
                                overviewData = {
                                    additionData: {
                                        webspace: webspace.data[0]
                                    },
                                    pagination: {
                                        limit: limit
                                    }
                                };
                            }
                            return this.domainHelper.stdOverviewOnLoadMethod(
                                returnData,
                                bundle.id,
                                overviewData,
                                true
                            );
                        });
                    }

                    return this.domainHelper.stdOverviewOnLoadMethod(returnData, bundle.id, undefined, true);
                }
            }
        );
    };

    public getActiveAccountBundles = (accountId): PromiseLike<any> => {
        if (AuthContextService.isGranted(UiRights.BIL_BUNDLE_LIST)) {
            if (this.checkBundleCache('accountBundles', accountId)) {
                return q.resolve(this.getBundleCache('accountBundles', accountId));
            } else {
                const filters = {
                    subFilter: [
                        { field: 'accountId', value: accountId },
                        { field: 'BundleStatus', value: 'active' }
                    ],
                    subFilterConnective: 'AND'
                };
                return this.bundleModel.list(100, 1, filters, undefined, undefined)
                .then((result) => {
                    this.addBundleCache('accountBundles', accountId, result.data);
                    result.data.forEach((bundleObj) => {
                        this.addBundleCache('bundles', bundleObj.id, bundleObj);
                    });
                    return result.data;
                });
            }
        } else {
            return q.resolve(null);
        }
    };

    public resetBundleCache = (type?, key?) => {
        if (key !== undefined && type !== undefined && this.bundleCache[type][key] !== undefined) {
            delete this.bundleCache[type][key];
        } else if (type !== undefined && this.bundleCache[type] !== undefined) {
            this.bundleCache[type] = [];
        } else {
            this.bundleCache = ng.copy(this.tmpBundleCache);
        }
    };

    public getDomainContingentTlds = (bundle: Types.BundleApi.Bundle) => {
        const tlds = [];
        for (const contingent of bundle.effectiveContingentUsage) {
            contingent.productCodes.some((productCode) => {
                if (productCode.indexOf('domain-') === 0) {
                    const productCodeParts = productCode.split('-');
                    if (!tlds.includes(productCodeParts[1])) {
                        tlds.push(productCodeParts[1]);
                    }
                }
            });
        }
        return tlds;
    };

    private checkBundleCache = (type, key) => {
        return this.bundleCache[type][key] !== undefined;
    };

    private addBundleCache = (type, key, cacheData) => {
        this.bundleCache[type][key] = cacheData;
    };

    private getBundleCache = (type, key) => {
        return this.bundleCache[type][key];
    };
}
