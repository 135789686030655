import * as ng from 'angular';

import { MoleculeFormEditController, PanelType } from '@/atomic-components/molecules/forms/form-edit/form-edit';
import {
    OrganismEditFormNextcloudBusinessUpgradesController
} from '@/atomic-components/organisms/forms/edit-forms';
import { UiLanguagesConst, UiRights } from '@/configuration';
import { AuthContextService, NextcloudHelperService, ProductsModelService } from '@/services';
import * as Types from '@/types';

export class OrganEditPanelNextcloudBusinessUpgradeController {
    public static $inject: string[] = [
        '$translate',
        'nextcloudHelper',
        'productsModel'
    ];

    public $editFormMolecule: MoleculeFormEditController;
    public $editFormOrganism: OrganismEditFormNextcloudBusinessUpgradesController;
    public typeOverride = PanelType.MISCELLANEOUS_ACTION;
    public searchString = '';
    public sliderLabelText = '';
    public noUsersFound: boolean;
    public nextcloudProduct: any;
    public nextcloud: any;
    public originalNextcloud: any;

    public addonProduct: Types.ProductApi.Product;
    public attribute: 'maxNumberOfUsers' | 'officeUsers' | 'storageQuota' | 'talkUsers';
    private registrationIndex: number;
    private _quotaChangeConfirmed = false;

    public panelRight = {
        editPanelButton: AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT)
    };

    public attributeData: {
        max: number;
        min: number;
        step: number;
        unit: string;
        used: number;
    };

    public addonProductForChanger: {
        productCode: string;
        value: string;
    };

    private _addonQuantity = 0;

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private nextcloudHelper: NextcloudHelperService,
        private productsModel: ProductsModelService
    ) {}

    public $onInit = async (): Promise<void> => {

        let productResponse: Types.UI.APIResponse<Types.ProductApi.AbstractProduct>;
        this.registrationIndex = this.$editFormMolecule.registerValidator(this);
        this.$editFormMolecule.validatorStatus[this.registrationIndex] = true;

        // Required for max getter for talk users
        const $editPanelOrgan = this; // eslint-disable-line

        switch (this.attribute) {
            case 'maxNumberOfUsers':
                productResponse = await this.productsModel.findOne(
                    this.nextcloudHelper.getValueFromProductSpecification(
                        'additionalUsersProductCode',
                        this.nextcloudProduct
                    ) as string,
                    UiLanguagesConst[AuthContextService.user.language as 'de'|'en'],
                    true
                );

                this.addonProduct = productResponse.response as Types.ProductApi.Product;

                this.attributeData = {
                    max: this.nextcloudHelper.getValueFromProductSpecification(
                            'nextcloudUsersMaximum',
                            this.nextcloudProduct
                    ) as number,
                    min: this.nextcloud.includedNumberOfUsers,
                    step: this.nextcloudHelper.getValueFromProductSpecification(
                        'additionalUsers',
                        this.addonProduct
                    ) as number,
                    unit: this.$translate.instant('TR_220519-49de95_TR'),
                    used: this.nextcloud.currentNumberOfUsers
                };

                this.addonProductForChanger = {
                    productCode: this.addonProduct.productCode,
                    value: `${this.attributeData.step}`
                };
                break;

            case 'storageQuota':
                productResponse = await this.productsModel.findOne(
                    this.nextcloudHelper.getValueFromProductSpecification(
                        'additionalStorageProductCode',
                        this.nextcloudProduct
                    ) as string,
                    UiLanguagesConst[AuthContextService.user.language as 'de'|'en'],
                    true
                );

                this.addonProduct = productResponse.response as Types.ProductApi.Product;

                this.attributeData = {
                    max: this.nextcloudHelper.getValueFromProductSpecification(
                        'storageQuotaMaximum',
                        this.nextcloudProduct
                    ) as number,
                    min: this.nextcloud.storageQuotaIncluded,
                    step: this.nextcloudHelper.getValueFromProductSpecification(
                        'storage',
                        this.addonProduct
                    ) as number,
                    unit: 'MB',
                    used: this.nextcloud.storageUsed
                };

                this.addonProductForChanger = {
                    productCode: this.addonProduct.productCode,
                    value: `${this.attributeData.step}`
                };
                break;
        }
    };

    public $onDestroy = (): void => {
        this.$editFormMolecule.unregisterValidator(this.registrationIndex);
        this.$editFormMolecule.validatorStatus[this.registrationIndex] = true;
    };

    get addonQuantity(): number {
        return this._addonQuantity;
    }

    set addonQuantity(value: number) {
        if (this._addonQuantity !== value && this.sliderLabelText.length > 0) {
            this.quotaChangeConfirmed = value < 0;
        }
        this._addonQuantity = value;
    }

    get quotaChangeConfirmed(): boolean {
        return this._quotaChangeConfirmed;
    }

    set quotaChangeConfirmed(value: boolean) {
        if (this.sliderLabelText.length > 0) {
            this.$editFormMolecule.validatorStatus[this.registrationIndex] = value;
        }

        this._quotaChangeConfirmed = value;
    }

    public get displayValue(): string {
        if (!this.attributeData || !this.nextcloud) {
            return '';
        }

        if (this.attribute === 'storageQuota') {
            return `${this.nextcloud[this.attribute] / 1024}`
                + ' GB';
        } else {
            return `${this.nextcloud[this.attribute]}`
                + ' '
                + `${this.attributeData.unit}`;
        }
    }

    public get model(): number {
        if (!this.nextcloud || !this.attribute) {
            return 0;
        }
        return this.nextcloud[this.attribute];
    }

    public set model(value: number) {
        if (!this.nextcloud || !this.attribute) {
            return;
        }
        this.nextcloud[this.attribute] = value;
    }

    public cancel = (): void => {
        if ([undefined, null].includes(this.nextcloud)) {
            return;
        }
        // eslint-disable-next-line
        this.nextcloud[this.attribute] = this.originalNextcloud[this.attribute];
    };
}

export class OrganEditPanelNextcloudBusinessUpgradeComponent implements ng.IComponentOptions {
    public bindings = {
        attribute: '@',
        nextcloudProduct: '<',
        nextcloud: '=',
        originalNextcloud: '<',
        panelTitle: '@',
        sliderLabelText: '@?'
    };
    public transclude = {
        description: '?upgradeDescription',
        label: '?editRowLabel'
    };
    public require = {
        $editFormMolecule: '^moleculeFormEdit'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./nextcloud-business-upgrade.html');
    public controller = OrganEditPanelNextcloudBusinessUpgradeController;
}
