import * as ng from 'angular';
import './input-text-voucher.scss';

export class AtomFormInputTextVoucherController implements ng.IController {
    public static $inject: string[] = ['$timeout'];

    public value: string;
    public validationErrorList: any[];
    public callbackOnEnter: () => {};
    public voucherInputParts = ['', '', '', ''];

    private _activInputPart: number;
    private _lastCursorPosition: number;
    private _sendingCode = false;
    private _pasteEvent = false;

    constructor(
        private $timeout: ng.ITimeoutService
    ) {}

    $onInit(): void {
        if (this.value !== undefined && this.value.length > 0) {
            this._setParts(this.value);
        }
    }

    public onKeyEvent = (event) => {
        if ([16, 9, 17].indexOf(event.keyCode) >= 0) {
            return;
        }

        if (event.target.value.length >= 4 && this._activInputPart <= 5) {
            if (event.target.value.length > 4) {
                event.target.value = event.target.value.substr(0, 4);
            }

            if (this._activInputPart === 3) {
                this.setFocus(event.target.form[this._activInputPart + 2]);
            } else {
                this.setFocus(event.target.form[this._activInputPart + 1]);
            }
        }

        // Allow/ignore paste, a-zA-Z0-9 or shift-, crtl- and tab-key events
        if (
            this._pasteEvent
            || new RegExp('^[a-zA-Z0-9]+$').test(event.key)
        ) {
            return;
        }

        switch (event.keyCode) {
            // arr left event
            case 37:
                if (event.target.selectionStart === 0 && this._activInputPart > 0 && this._lastCursorPosition === 0) {
                    this.setFocus(event.target.form[this._activInputPart - 1]);
                    event.target.form[this._activInputPart - 1].setSelectionRange(4, 4);
                    this._activInputPart = this._activInputPart - 1;
                }
                this._lastCursorPosition = event.target.selectionStart;
                break;

            // arr right event
            case 39:
                if (event.target.selectionStart === 4 && this._activInputPart < 3 && this._lastCursorPosition === 4) {
                    this.setFocus(event.target.form[this._activInputPart + 1]);
                    event.target.form[this._activInputPart + 1].setSelectionRange(0, 0);
                    this._activInputPart = this._activInputPart + 1;
                }
                this._lastCursorPosition = event.target.selectionStart;
                break;

            // on delete remove press
            case 8:
            case 45:
            case 46:
                if (event.target.value.length === 0 && this._activInputPart > 0) {
                    this.setFocus(event.target.form[this._activInputPart - 1]);
                }
                break;

            default:
                event.preventDefault();
                this.voucherInputParts = this.voucherInputParts.map((el) => el.split(event.key).join(''));
                break;
        }
    };

    public setFocus = (element) => {
        if (element !== undefined) {
            element.focus();
        }
    };

    public resetRedeemCode = () => {
        this.voucherInputParts = ['', '', '', ''];
    };

    public isPartDisabled = (index) => {
        if (index === 0) {
            return false;
        }

        let fieldParts = ng.copy(this.voucherInputParts);
        fieldParts = fieldParts.slice(0, index);

        return !(fieldParts.every(part => {
            if (part === undefined) {
                return false;
            }
            return part.length >= 4;
        }));
    };

    public get isRedeemButtonDisabled() {
        return this.voucherInputParts.some(part => part.length < 4);
    }

    public onPaste = (index) => {
        if (index !== 0 ) {
            return;
        }
        this._pasteEvent = true;

        this.$timeout(() => {
            this._setParts(this.voucherInputParts[0].replace(/[^A-Za-z0-9]/g, ''));
            this._pasteEvent = false;
        }, 50);
    };

    public onFocus(index): void {
        this._activInputPart = index;
    }

    public get activePart() {
        return this._activInputPart;
    }

    public redeemVoucherCode = () => {
        if (this._sendingCode) {
            return;
        }

        this._sendingCode = true;
        this.value = this.voucherInputParts.join('');
        if (this.callbackOnEnter !== undefined) {
            this.$timeout(() => {
                this.callbackOnEnter();
                this._sendingCode = false;
            }, 50);
        }
    };

    private _setParts = (value) => {
        if (value.length > 16) {
            value = value.substring(0, 16);
        }

        const newParts = value.match(/.{1,4}/g);
        for (let i = 0; i <= 3; i++) {
            this.voucherInputParts[i] = (newParts[i] !== undefined) ? newParts[i] : '';
        }
    };
}

export class AtomFormInputTextVoucherComponent implements ng.IComponentOptions {
    public bindings = {
        value: '=',
        callbackOnEnter: '<',
        validationErrorList: '=?'
    };
    public controller = AtomFormInputTextVoucherController;
    public template = require('./input-text-voucher.html');
}
