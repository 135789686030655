import * as ng from 'angular';

import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateDomainTransferOverviewController {
    public static $inject: string[] = ['$translate'];

    public expertView: boolean;
    public overviewServiceData: any;
    public overviewModel;
    public pageHeaderData: PanelHeaderData = {
        backwardLink: 'domain.dashboard',
        backwardText: this.$translate.instant('TR_110119-774118_TR'),
        panelHeaderRoute: '',
        panelIcon: 'globe',
        panelTitle: this.$translate.instant('TR_110119-a4fd40_TR')
    };

    private simpleFilter;
    private extendedFilters;
    private overviewUiSettings;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit() {
        this.expertView = true; // Domaintransfers gibt es nur in der Expert View
        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter
        };
    }

    public expandWrapper = () => { /* not empty */ };
}

export class TemplateDomainTransferOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public template = require('./transfer-overview-template.html');
    public controller = TemplateDomainTransferOverviewController;
}
