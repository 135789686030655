import * as ng from 'angular';
import { InputPlaceholderConst, LongDebounceInterval } from '../../../../../configuration';
import {
    AuthContextService,
    DnsZoneModelService,
    ValidateFqdn
} from '../../../../../services';
import * as Types from '../../../../../types';
import './panel-edit-row-subdomain-checker.scss';

export class MoleculePanelEditRowSubdomainCheckerController implements ng.IController {
    public static $inject: string[] = ['authContext', '$translate', 'dnsZoneModel'];

    public subDomainFirstPart: string;
    public subDomainSecondPart: string;
    public subdomainIsOptional: boolean;
    public domainDropdownItems: any[];
    public validationErrorList: any[] = [];
    public accountId: string;
    public placeholderLabel: string;
    public callbackOnChange: (model: string) => void;
    public unknownDomain = false;
    public longDebounceInterval = LongDebounceInterval;

    protected inputPlaceholder = InputPlaceholderConst;

    private fqdnValidate: ValidateFqdn;
    private _subdomain: string;
    private initValue: string;

    constructor(
        private authContext: AuthContextService,
        private $translate: ng.translate.ITranslateService,
        private dnsZoneModel: DnsZoneModelService
    ) {}

    public $onInit() {
        this.subdomainIsOptional = this.subdomainIsOptional || false;
        this.fqdnValidate = new ValidateFqdn(this.$translate, false);
        this.placeholderLabel = this.placeholderLabel
            || ('subdomain' + (this.subdomainIsOptional ? ' (optional)' : ''));
        if ([undefined, null, ''].indexOf(this.initValue) < 0) {
            const urlParts = this.initValue.split('.');
            if (urlParts.length === 2 || urlParts[1] === 'co') {
                this.subDomainFirstPart = '';
                this.subDomainSecondPart = this.initValue;
            } else {
                this.subDomainFirstPart = urlParts[0];
                this.subDomainSecondPart = urlParts.splice(1).join('.');
            }
            this.checkSubDomain();
        }
    }

    public $onChanges(changes) {
        if (changes.accountId !== undefined) {
            this.subDomainSecondPart = undefined;
            this.checkSubDomain();
        }
    }

    public $onDestroy() {
        this.checkSubDomain();
    }

    public createDomainDropdownItems = () => {
        if ([undefined, null, ''].indexOf(this.subDomainSecondPart) >= 0) {
            return;
        }

        const filter: Types.Finding.Filter = {
            subFilter: [
                {
                    field: 'accountId',
                    value: this.accountId
                },
                {
                    field: 'zoneStatus',
                    value: 'active'
                },
                {
                    field: 'zoneName',
                    value: `*${this.subDomainSecondPart}*`
                }
            ] as Types.Finding.Filter[],
            subFilterConnective: 'AND'
        };

        this.dnsZoneModel.list(undefined, undefined, filter).then(
            (reply) => {
                this.domainDropdownItems = reply.data.map(
                    (zone) => {
                        return {
                            name: zone.nameUnicode,
                            value: zone.name
                        };
                    }
                );
            }
        );

        this.checkSubDomain();
    };

    public checkSubDomain = () => {
        this.unknownDomain = false;
        if (
            ( !this.subdomainIsOptional
                && ( [undefined, null, ''].indexOf(this.subDomainFirstPart) >= 0
                     || [undefined, null, ''].indexOf(this.subDomainSecondPart) >= 0) )
            || ( this.subdomainIsOptional && [undefined, null, ''].indexOf(this.subDomainSecondPart) >= 0 )
        ) {
            this._setSubDomain();
        } else {
            let subDomain = '';
            if (this.subdomainIsOptional && [undefined, null, ''].indexOf(this.subDomainFirstPart) >= 0) {
                subDomain = this.subDomainSecondPart;
            } else {
                subDomain = this.subDomainFirstPart + '.' + this.subDomainSecondPart;
            }
            this.validationErrorList = this.fqdnValidate.validate(subDomain);
            if (!this.domainDropdownItems.some((item) => item.value === this.subDomainSecondPart)) {
                this.unknownDomain = true;
            }
            this._setSubDomain();
        }
        this.onChange();
    };

    public onSecondPartSelect = (subDomainSecondPart: string) => {
        this.subDomainSecondPart = subDomainSecondPart;
        this.checkSubDomain();
    };

    private _setSubDomain = () => {
            if (this.subdomainIsOptional && [undefined, null, ''].indexOf(this.subDomainFirstPart) >= 0) {
                this._subdomain = this.subDomainSecondPart;
            } else if ([undefined, null, ''].indexOf(this.subDomainFirstPart) < 0
                && [undefined, null, ''].indexOf(this.subDomainSecondPart) < 0) {
                this._subdomain = this.subDomainFirstPart + '.' + this.subDomainSecondPart;
            } else {
                this._subdomain = this.subDomainFirstPart || '';
            }
    };

    private onChange = () => {
        if ([undefined, null].indexOf(this.callbackOnChange) < 0) {
            this.callbackOnChange(this._subdomain);
        }
    };
}

export class MoleculePanelEditRowSubdomainCheckerComponent implements ng.IComponentOptions {
    public transclude = {
        label: 'rowLabel'
    };
    public bindings = {
        _subdomain: '=value',
        accountId: '@',
        callbackOnChange: '<',
        domainDropdownItems: '=',
        initValue: '<?',
        placeholderLabel: '@?',
        subdomainIsOptional: '<?',
        validationErrorList: '=?'
    };
    public controller = MoleculePanelEditRowSubdomainCheckerController;
    public template = require('./panel-edit-row-subdomain-checker.html');
}
