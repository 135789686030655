import { DnsTemplateModelService, WizardNewHelperService } from '@/services';
import * as ng from 'angular';
import * as Types from '../../../../../types';
import { AccountApi, ViewTypes } from '../../../../../types';

export class OrganismDnsWizardProductConfigZoneTemplateController {
    public static $inject: string[] = [
        '$timeout',
        'dnsTemplateModel',
        'wizardNewHelper'
    ];

    public metadata: ViewTypes.ProductConfigDnsObject;
    public service = 'dns';
    public nameserverDropdownItems: any[];
    public displayNoTemplatesFoundWarning = false;
    public dnsRecordsTemplate: any[];
    public nameserversComplete = false;
    public templateHasApexNsRecords = false;
    public hideTemplateVars = false;
    public requiredReplacementTypes = {
        ipv4Replacement: false,
        ipv6Replacement: false,
        mailIpv4Replacement: false,
        mailIpv6Replacement: false
    };
    public formCompleted = {
        // tslint:disable:no-empty
        fqdn: () => [null, undefined].indexOf(this.metadata.fqdnList) < 0 && this.metadata.fqdnList.length > 0,
        template: () => this.metadata?.templateValues?.templateId?.length > 0,
        replacements: () => {
            let complete = true;

            Object.keys(this.requiredReplacementTypes).forEach(
                (key) => {
                    if (this.requiredReplacementTypes[key]) {
                        if (
                            [undefined, null, ''].indexOf(this.metadata.templateValues.templateReplacements[key]) >= 0
                        ) {
                            complete = false;
                        }
                    }
                }
            );

            return complete;
        },
        nsSettings: () => {
            return this.metadata.nameserver.values.default
                || (
                    this.metadata.nameserver.ownNameserversComplete
                    && this.metadata.nameserver.ownNameservers.length >= 2
                )
                || [undefined, null, ''].indexOf(this.metadata.nameserver.values.id) < 0
                || this.templateHasApexNsRecords;
        }
        // tslint:enable:no-empty
    };

    private _account: Types.AccountApi.Account | Types.AccountApi.Subaccount;

    constructor(
        private $timeout: ng.ITimeoutService,
        public dnsTemplateModel: DnsTemplateModelService,
        private wizardNewHelper: WizardNewHelperService
    ) {}

    public $onInit = () => {
        this._setMetadata();
        this._setNameserverSets();
    };

    public get showZonePreview(): boolean {
        return !this.hideTemplateVars
            && [null, undefined].indexOf(this.metadata.records) < 0
            && this.metadata.records.length > 0;
    }

    public get ownNameserversComplete(): boolean {
        return this.metadata.nameserver.ownNameserversComplete;
    }
    public set ownNameserversComplete(value: boolean) {
        if (
            this.metadata.nameserver.ownNameserversComplete !== value
            && value
        ) {
            this.updateRecords();
        }

        this.metadata.nameserver.ownNameserversComplete = value;
    }

    public get account(): AccountApi.Account | AccountApi.Subaccount {
        return this._account;
    }
    public set account(value: AccountApi.Account | AccountApi.Subaccount) {
        if (value && this.metadata && this.metadata.account) {
            this.metadata.account = value;
        }

        this._account = value;
    }

    public set configCompleted({}) {} // tslint:disable-line:no-empty
    public get configCompleted() {
        let completed = true;

        for (const part in this.formCompleted) {
            if (!this.formCompleted[part]()) {
                completed = false;
            }
        }

        return completed;
    }

    public onNameserverOptionChange = (selection: any) => {
        this.$timeout(() => {
            this.updateRecords();
        });
    };

    public updateRecords = () => {
        const tplRecords = ng.copy(this.dnsRecordsTemplate);

        if (tplRecords === undefined) {
            return;
        }

        this.metadata.records = tplRecords;

        let nsList = [];
        this.templateHasApexNsRecords = tplRecords && tplRecords.some((rec) => rec.type === 'NS' && rec.name === '');

        if (this.templateHasApexNsRecords) {
            this.metadata.nameserver.ownNameserversComplete = false;
            this.metadata.nameserver.values = {
                default: false,
                id: null,
                nameservers: null,
                type: null
            };
            this.metadata.nameserver.ownNameservers = [];
            this.$timeout(() => {
                this.hideTemplateVars = false;
            });
        } else {
            if (
                [null, undefined].indexOf(this.metadata.nameserver.values.nameservers) < 0
                && this.metadata.nameserver.values.nameservers.length > 0
            ) {
                nsList = this.metadata.nameserver.values.nameservers;
            } else if (
                [null, undefined].indexOf(this.metadata.nameserver.ownNameservers) < 0
                && this.metadata.nameserver.ownNameservers.length > 0
            ) {
                nsList = this.metadata.nameserver.ownNameservers;
            }

            this.$timeout(() => {
                for (const record of nsList) {
                    if (
                        !this.metadata.records.some(
                            (rec) => rec.content === record.name
                                && rec.name === ''
                                && rec.type === 'NS')
                    ) {
                        this.metadata.records.push({
                            content: record.name,
                            name: '',
                            ttl: 86400,
                            type: 'NS'
                        });
                    }
                }
                this.hideTemplateVars = false;
            });
        }
    };

    public listTemplates = (
        limit?: number,
        page?: number,
        filters?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions,
        timeout?: number
    ) => {
        this.displayNoTemplatesFoundWarning = false;

        const filterObject: any = {
            subFilter: [{
                field: 'accountId',
                value: this.metadata.account.id
            }],
            subFilterConnective: 'AND'
        };

        if ([undefined, null].indexOf(filters) >= 0) {
            filterObject.subFilter.push({
                field: 'templateName',
                value: '*'
            });
        } else {
            filterObject.subFilter.push(filters);
        }

        return this.dnsTemplateModel.list(limit, page, filterObject, sort, timeout).then(
            (apiResponse) => {
                if ([undefined, null].indexOf(filters) >= 0 && apiResponse.data?.length === 0) {
                    this.displayNoTemplatesFoundWarning = true;
                }

                return apiResponse;
            }
        );
    };

    public dnsTemplateSelected = (template: string) => {
        if ([undefined, null].indexOf(template) >= 0) {
            this.metadata.records = [];
            return;
        }
        this.hideTemplateVars = true;

        this.dnsTemplateModel.findRecords(template).then(
            (reply) => {
                this.dnsRecordsTemplate = reply;
                this.updateRecords();
            }
        );
    };

    private _getServerIp = () => {
        const serverIp = this.metadata.ipAddress;
        return [undefined, null].indexOf(serverIp) < 0 ? serverIp : '';
    };

    private _setNameserverSets = () => {
        this.wizardNewHelper.getNameserverSets(this.metadata.account.id)
            .then((nameserverSets) => {
                this.metadata.nameserver.values = this.wizardNewHelper.objectCache.nameserSets[0].items[0].value;
                this.nameserverDropdownItems = nameserverSets;
            });
    };

    private _setMetadata = () => {
        this.metadata = {
            account: this._account,
            fqdnList: [],
            ipAddress: undefined,
            productCode: undefined,
            productFamily: undefined,
            records: [],
            nameserver: {
                ownNameservers: [],
                ownNameserversComplete: false,
                values: {
                    default: false,
                    id: null,
                    nameservers: null,
                    type: null
                }
            },
            templateValues: {
                templateId: null,
                templateReplacements: {
                    ipv4Replacement: null,
                    ipv6Replacement: null,
                    mailIpv4Replacement: null,
                    mailIpv6Replacement: null
                },
                tieToTemplate: false
            }
        };
    };
}

export class OrganismDnsWizardProductConfigZoneTemplateComponent implements ng.IComponentOptions {
    public bindings = {
        account: '<account',
        configCompleted: '=configCompleted',
        metadata: '='
    };
    public template = require('./dns-wizard-product-config-zone-template.html');
    public controller = OrganismDnsWizardProductConfigZoneTemplateController;
}
