import * as ng from 'angular';
import { UiRights } from '../../../configuration';
import {
    AlertManagerService,
    AuthContextService,
    SwitchUserManagerService,
    UserRobotService
} from '../../../services';
import * as Types from '../../../types';

export class TemplateSubAccountEditController implements ng.IController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'authContext',
        'switchUserManager',
        'userRobot'
    ];

    public subAccountData: Types.AccountApi.Subaccount;
    public pageHeaderData: any;
    public parentAccountLanguage: string;
    public panelRightSettings: any;
    public overviewUiSettings: any;
    public simpleFilter: any;
    public extendedFilters: any;
    public rightsTemplates: any[];
    public allRights: any[];

    private lockUnlockActions: any = {};

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private authContext: AuthContextService,
        private switchUserManager: SwitchUserManagerService,
        private userRobot: UserRobotService
    ) {}

    public $onInit() {
        this.pageHeaderData = {
            backwardLink: 'reseller.subaccounts.overview',
            backwardText: this.$translate.instant('TR_090119-e73cf8_TR'),
            panelHeaderRoute: 'reseller.subaccounts.id.edit',
            panelIcon: 'address-book',
            panelTitle: this.subAccountData.name,
            productActions: [{
                actionFunction: this.loginAsSubaccount,
                title: this.$translate.instant('GENERAL.ACTION.LOGIN-AS')
            }]
        };

        this.lockUnlockActions = {
            lock: {
                actionFunction: () => this.toggleUserStatus('lock'),
                lookup: 'lock',
                title: this.$translate.instant('TR_090119-3bf28a_TR')
            },
            unlock: {
                actionFunction: () => this.toggleUserStatus('unlock'),
                lookup: 'unlock',
                title: this.$translate.instant('TR_090119-db4687_TR')
            }
        };

        if (this.subAccountData.enabled) {
            if (this.authContext.isGranted(UiRights.ACC_SUBACCOUNT_LOCK)) {
                this.pageHeaderData.productActions.push(this.lockUnlockActions.lock);
            }
        } else {
            if (this.authContext.isGranted(UiRights.ACC_SUBACCOUNT_UNLOCK)) {
                this.pageHeaderData.productActions.push(this.lockUnlockActions.unlock);
            }
        }

        this.panelRightSettings = {
            editPanelButton: this.authContext.isGranted(UiRights.ACC_SUBACCOUNT_EDIT)
        };
    }

    public toggleUserStatus = (action: string) => {
        if (action === 'lock') {
            this.userRobot.disableSubaccount(this.subAccountData.id)
            .then(
                () => {
                    this.alertManager.success(
                        this.$translate.instant(
                            /* translationId */ 'ACCOUNT.MESSAGE.SUBACCOUNT-DISABLE.SUCCESS'
                        )
                    );
                    this.toggleLockMenuItem(action);
                }
            );
        } else {
            this.userRobot.enableSubaccount(this.subAccountData.id)
            .then(
                () => {
                    this.alertManager.success(this.$translate.instant('ACCOUNT.MESSAGE.SUBACCOUNT-ENABLE.SUCCESS'));
                    this.toggleLockMenuItem(action);
                }
            );
        }
    };

    public setPanelRightSettings = () => {
        this.panelRightSettings = {
            editPanelButton: this.authContext.isGranted(UiRights.ACC_SUBACCOUNT_EDIT)
        };
    };

    public loginAsSubaccount = () => {
        this.switchUserManager.switchTo(this.subAccountData.id);
    };

    private toggleLockMenuItem = (action: string) => {
        const oppositeAction = {
            lock: 'unlock',
            unlock: 'lock'
        };

        this.pageHeaderData.productActions.find(
            (menuElement, index) => {
                if ('lookup' in menuElement && menuElement.lookup === action) {
                    delete this.pageHeaderData.productActions[index];
                    this.pageHeaderData.productActions[index] = this.lockUnlockActions[oppositeAction[action]];
                    return true;
                }
                return false;
            }
        );
    };
}

export class TemplateSubAccountEditComponent implements ng.IComponentOptions {
    public bindings = {
        allRights: '<',
        extendedFilters: '<',
        overviewUiSettings: '<',
        parentAccountLanguage: '<',
        rightsTemplates: '<',
        simpleFilter: '<',
        subAccountData: '<'
    };

    public controller = TemplateSubAccountEditController;
    public template = require('./sub-account-edit.html');
}
