import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';
import * as Types from '../../../types';

export class TemplateWebhostingUserEditController {
    public static $inject: string[] = ['$translate', 'authContext'];

    public modifier = '';
    public pageHeaderData: PanelHeaderData;

    public originalUser: Types.WebhostingApi.User;

    public userPanelRight: any;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'webhosting.users.overview',
            backwardText: this.$translate.instant('TR_140119-f37dd1_TR'),
            panelHeaderRoute: '',
            panelIcon: 'user',
            panelTitle: this.$translate.instant('TR_140119-65a1fc_TR')
        };

        this.userPanelRight = {
            delete: {
                editPanelButton: this.authContext.isGranted(UiRights.WEB_USER_DELETE)
            },
            general: {
                editPanelButton: this.authContext.isGranted(UiRights.WEB_USER_EDIT)
            }
        };
    };
}

export class TemplateWebhostingUserEditComponent implements ng.IComponentOptions {
    public bindings = {
        originalUser: '<user'
    };
    public template = require('./webhosting-user-edit-template.html');
    public controller = TemplateWebhostingUserEditController;
    public controllerAs = '$TemplateWebhostingUserEditCtrl';
}
