import * as ng from 'angular';
import * as Types from '../../../../../types';

export interface WizardWebspaceObject {
    orchestratorObject: Types.OrchestrationApi.Webspace;
    prices: [];
}

export class OrganCreateConfigurationWebspaceController implements ng.IController {
    public static $inject: string[] = [];

    public webspaceObject: WizardWebspaceObject;
    public panelHidden = false;
    public required = false;

    private _projectName = '';
    private _productCode = '';

    public $onChanges(changes: any) {
        if ([undefined, null].indexOf(this.webspaceObject) >= 0
            || Object.keys(this.webspaceObject).length === 0
        ) {
            this._setWebspaceObject();
        }
        if (changes._projectName !== undefined) {
            this.webspaceObject.orchestratorObject.name = changes._projectName.currentValue;
        }
        if (changes.productCode !== undefined && changes.productCode.currentValue !== undefined) {
            this.webspaceObject.orchestratorObject.productCode = changes.productCode.currentValue;
        }
    }

    public get completed() {
        if (!this.required) {
            return true;
        }

        return [undefined, null, ''].indexOf(this.webspaceObject.orchestratorObject.name) < 0;
    }

    public set completed({}) {} // tslint:disable-line:no-empty

    private _setWebspaceObject = () => {
        this.webspaceObject = {
            orchestratorObject: {
                items: [],
                name: [undefined, null].indexOf(this._projectName) < 0
                    ? this._projectName
                    : '',
                password: '',
                productCode: [undefined, null].indexOf(this._productCode) < 0
                    ? this._productCode
                    : '',
                storage: 0,
                type: 'Webspace',
                userName: ''
            },
            prices: []
        };
    };
}

export class OrganCreateConfigurationWebspaceComponent implements ng.IComponentOptions {
    public bindings = {
        _productCode: '<?productCode',
        _projectName: '<?projectName',
        panelHidden: '<?',
        required: '<?',
        webspaceObject: '='
    };

    public template = require('./webspace-config.html');
    public controller =  OrganCreateConfigurationWebspaceController;
}
