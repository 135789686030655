import { PanelHeaderData } from '@/atomic-components';
import * as ng from 'angular';

export class TemplateRedirectionWizardController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.pageHeaderData = {
            backwardLink: 'domain.redirections.overview',
            backwardText: this.$translate.instant('TR_110119-4f9658_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: {},
            panelIcon: 'globe',
            panelTitle: this.$translate.instant('WEBHOSTING.VHOST.REDIRECT.NEW.TITLE')
        };
    }
}

export class TemplateRedirectionWizardComponent implements ng.IComponentOptions {
    public bindings = {};
    public template = require('./redirection-wizard-template.html');
    public controller = TemplateRedirectionWizardController;
}
