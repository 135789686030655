import * as ng from 'angular';
import './text-bubble.scss';

export class AtomTextBubbleController implements ng.IController {
    public static $inject: string[] = [];

    public textModifier: string;
    public stroke: boolean;
    public disabled: boolean;
    public color: string;

    $onInit() {
        this.textModifier = this.textModifier || '';
        this.stroke = this.stroke || false;
        this.disabled = this.disabled || false;
    }
}

export class AtomTextBubbleComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        textModifier: '@',
        stroke: '<',
        disabled: '<',
        color: '@'
    };
    public controller =  AtomTextBubbleController;
    public template = require('./text-bubble.html');
}
