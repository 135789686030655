import * as ng from 'angular';
import './task-list.scss';

export interface TaskListItem {
    title: string;
    status: string;
    route: string;
    description: string;
}

export class MoleculeTaskListController implements ng.IController {
    public static $inject: string[] = [];

    public taskList: TaskListItem[];
}

export class MoleculeTaskListComponent implements ng.IComponentOptions {
    public bindings = {
        taskList: '<'
    };
    public controller =  MoleculeTaskListController;
    public template = require('./task-list.html');
}
