import ng from 'angular';
import { PaginatedUsers } from '@/atomic-components/molecules';
import { MoleculeFormEditController } from '@/atomic-components/molecules/forms/form-edit/form-edit';
import {
    MoleculePanelEditTableController
} from '@/atomic-components/molecules/panels/panel-edit/table/panel-edit-table';
import { OrganismEditFormWebspaceUsersController } from '@/atomic-components/organisms';
import { OrganEditPanelWebspaceUsersController } from '@/atomic-components/organs';
import { WebhostingUserModelService } from '@/services';
import { Finding, ViewTypes, WebhostingApi} from '@/types';

export class MoleculePanelEditRowTableWebspaceUsersController implements ng.IController {
    public static $inject: string[] = ['$timeout', '$translate', 'webhostingUserModel'];

    // Dependency injections
    public $editForm: MoleculeFormEditController;
    public $organismEditForm: OrganismEditFormWebspaceUsersController;
    public $organWebspaceUsers: OrganEditPanelWebspaceUsersController;
    public $panelEditTable: MoleculePanelEditTableController;

    // Public parameters
    public apiUsers: PaginatedUsers = {
        data: [],
        pagination: {
            currentPage: 1,
            entries: 0,
            limit: 5
        }
    };

    public isUserListViewable = false;
    public startAccessViewRangeIndex = 0;
    public endAccessViewRangeIndex = 0;
    public accessTypeButtons: {value: string; label: string}[] = [];
    public webspaceUsers: WebhostingApi.User[] = [];
    public panelRight: Record<string, boolean>;

    // Private parameters
    private _searchString = '';

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private webhostingUserModel: WebhostingUserModelService
    ) {}

    public $onInit(): void {
        this.accessTypeButtons.push(
            { value: ViewTypes.UserAccessType.existing, label: this.$translate.instant('TR_110119-3743e6_TR') },
            { value: ViewTypes.UserAccessType.new, label: this.$translate.instant('TR_110119-b61be5_TR') }
        );
        void this.updateUserList();
    }

    // Getter and setters
    get searchString(): string {
        return this._searchString;
    }

    set searchString(value: string) {
        if (this._searchString !== value && typeof value === 'string') {
            this.apiUsers.pagination.currentPage = 1;
            this._searchString = value;
            void this.updateUserList();
            return;
        }
        this._searchString = value;
    }

    public get noAccessesFound(): boolean {
        return this.$organismEditForm.userAccessList.length === 0;
    }

    public getShortenedSshKey(access: ViewTypes.WebspaceUserAccessObject): string {
        const sshKey: string = access.sshKey;

        if ([undefined, null, ''].indexOf(sshKey) < 0 && sshKey.length > 60) {
            return sshKey.substring(0, 16) + '[...]' + sshKey.substring(sshKey.length - 40);
        }

        return sshKey;
    }

    public get unsavedChanges(): boolean {
        return this.$organismEditForm.userAccessList.some((user) => user.props.isBeingEdited === true);
    }

    // Public methods
    public hideUserRow = (access: ViewTypes.WebspaceUserAccessObject, index: number): boolean => {
        if (access.props.isBeingEdited || access.props.isMarkedForRemoval || access.props.isNewUser) {
            return false;
        }

        return access.props.isHidden
            || index < this.startAccessViewRangeIndex
            || index > this.endAccessViewRangeIndex;
    };

    public getAccessLeverErrors = (access: ViewTypes.WebspaceUserAccessObject): {text: string}[] => {
        return access.props.validationErrorList.accessLevel;
    };

    public accessLevelCheckboxChanged = (value: { access: ViewTypes.WebspaceUserAccessObject}): void => {
        void this.$timeout(() => {
            this._hasAccessLevelError(value.access);
        });
    };

    public loadingWebspaceUserOnNewAccess = (access: ViewTypes.WebspaceUserAccessObject): boolean => {
        if (!access.props.isNewUser) {
            return false;
        }

        return this.$organWebspaceUsers.loadingWebspaceUserOnNewAccess;
    };

    public updateUserList = async (limit?: number|boolean, page?: number): Promise<void> => {
        limit = typeof limit === 'number' ? limit : this.apiUsers.pagination.limit;
        page = page || this.apiUsers.pagination.currentPage || 1;
        this.startAccessViewRangeIndex = page === 1 ? 0 : limit * (page - 1);
        this.endAccessViewRangeIndex = page === 1 ? limit - 1 : this.startAccessViewRangeIndex + limit - 1;

        // get all webspace users
        const webspaceUsersOfAccesses = await this._getWebspaceUsers();
        // Compare webspace accesses and webspace users
        const comparedAccessesAndWebspaceUsers = this._compareAccessAndWebspaceUser(
            webspaceUsersOfAccesses
        );

        // get visible accesses
        this._applySearchFilter();
        const userAccessList: ViewTypes.WebspaceUserAccessObject[] = [];
        for (const access of comparedAccessesAndWebspaceUsers) {
            if (!access.props.isHidden) {
                userAccessList.push(access);
            }
        }

        this.$organismEditForm.userAccessList = userAccessList;

        // set paginationData
        this.apiUsers.data = userAccessList;
        this.apiUsers.pagination.currentPage = page;
        this.apiUsers.pagination.limit = limit;
        this.apiUsers.pagination.entries = webspaceUsersOfAccesses.length;

        void this.$timeout(() => { this.isUserListViewable = true; });
    };

    public toggleUserEdit = (userData: { index: number; access: ViewTypes.WebspaceUserAccessObject }): void => {
        if (!this._validateForm()) {
            return;
        }

        void this.$timeout(() => {
            const access = userData.access;
            if (access.props.isBeingEdited) {
                access.props.type = 'edit';
                access.props.isBeingEdited = !access.props.isBeingEdited;
                this._updateCurrentUser(userData.index, access);
            } else {
                access.props.initialState = undefined;
                access.props.initialState = ng.copy(access);
                access.props.isBeingEdited = !access.props.isBeingEdited;
            }
        });
    };

    public cancelUserEdit = (userData: { index: number; access: ViewTypes.WebspaceUserAccessObject }): void => {
        let access = userData.access;

        if (access.props.initialState) {
            access = ng.copy(access.props.initialState);
            access.props.initialState = undefined;
            this._updateCurrentUser(userData.index, access);
        } else {
            this._removeCurrentUser(userData.index);
        }
    };

    // mark user for deletion
    public toggleUserDelete = (access: ViewTypes.WebspaceUserAccessObject): void => {
        access.props.isMarkedForRemoval = !access.props.isMarkedForRemoval;
        this._validateForm();
    };

    public isDeleteButtonViewable = (access: ViewTypes.WebspaceUserAccessObject): boolean => {
        return !access.props.isBeingEdited;
    };

    public isCancelButtonViewable = (access: ViewTypes.WebspaceUserAccessObject): boolean => {
        return access.props.isBeingEdited;
    };

    public isEditButtonViewable = (access: ViewTypes.WebspaceUserAccessObject): boolean => {
        return !access.props.isBeingEdited
            && !access.props.isMarkedForRemoval
            && this.panelRight.editUser;
    };

    public isConfirmButtonViewable = (access: ViewTypes.WebspaceUserAccessObject): boolean => {
        return access.props.isBeingEdited;
    };

    public isAccessReadonly = (access: ViewTypes.WebspaceUserAccessObject): boolean => {
        return !access.props.isBeingEdited;
    };

    public isAccessEditable = (access: ViewTypes.WebspaceUserAccessObject): boolean => {
        return this.$panelEditTable.showEditableContent && access.props.isBeingEdited;
    };

    public isCommentsRowViewable = (access: ViewTypes.WebspaceUserAccessObject): boolean => {
        return access.props.isNewUser
            || access.comments.length > 0;
    };

    public isNewAccessTypeSelectionViewable = (access: ViewTypes.WebspaceUserAccessObject): boolean => {
        return this.panelRight.createUser
            && access.props.isNewUser
            && this.$organWebspaceUsers.morePossibleExisitingWebspaceUsers;
    };

    public isExistendWebspaceUserSelectionViewable = (access: ViewTypes.WebspaceUserAccessObject): boolean => {
        return this.isNewAccessTypeSelectionViewable(access)
            && access.props.newAccessCreateType === ViewTypes.UserAccessType.existing;
    };

    public isAccessDataEditable = (access: ViewTypes.WebspaceUserAccessObject): boolean => {
        if (access.props.isNewUser) {
            return !!access.props.newAccessCreateType;
        }

        return access.props.isBeingEdited;
    };

    public isAccessnameEditable = (access: ViewTypes.WebspaceUserAccessObject): boolean => {
        return access.props.isNewUser
            && access.props.newAccessCreateType ===  ViewTypes.UserAccessType.new;
    };

    public isAccessUsernameEditable = (access: ViewTypes.WebspaceUserAccessObject): boolean => {
        if (access.props.isNewUser) {
            return access.props.newAccessCreateType ===  ViewTypes.UserAccessType.new;
        }

        return access.props.isBeingEdited;
    };

    public isSshKeyReadonlyAndCopyable = (access: ViewTypes.WebspaceUserAccessObject): boolean => {
        return access.sshKey.length > 0;
    };

    public isNewAccessWithoutWebspaceUserLinked = (access: ViewTypes.WebspaceUserAccessObject): boolean => {
        return access.props.isNewUser
            && access.props.newAccessCreateType === ViewTypes.UserAccessType.new;
    };

    public isWebAccessNotEditableHintViewable = (access: ViewTypes.WebspaceUserAccessObject): boolean => {
        if (this.isNewAccessWithoutWebspaceUserLinked(access)) {
            return false;
        }

        return access.props.isBeingEdited;
    };

    public selectExistingUser = (selectedUserId: string): void => {
        if (!selectedUserId) {
            return;
        }
        /**
         * We want to display the access name after selecting the webspace user.
         * For this we have to find the user object based on the selected userId
         * and then find the access based on the userId and assign the name of the
         * selected webspace user to the object.
         */
        let webspaceUser: WebhostingApi.User;
        this.$organismEditForm.webspaceUsers.some(
            (user) => {
                if (user.id === selectedUserId) {
                    webspaceUser = user;
                    return true;
                }
                return false;
            }
        );

        void this.$timeout(() => {
            this.$organismEditForm.userAccessList.some(
                (userAccess) => {
                    if (userAccess.userId === selectedUserId) {
                        userAccess.name = webspaceUser.name;
                        return true;
                    }
                    return false;
                }
            );
        });
    };

    // Private methods
    private _validateForm = (): boolean => {
        // check accesslevel
        let hasAccessLevelError = false;
        for (const access of this.$organismEditForm.userAccessList) {
            if (this._hasAccessLevelError(access)) {
                hasAccessLevelError = true;
            }
        }

        if (hasAccessLevelError) {
            return false;
        }

        return this.$editForm.validateAll();
    };

    private _hasAccessLevelError = (access: ViewTypes.WebspaceUserAccessObject): boolean => {
        if (
            !access.accessLevel.ftpAccess
            && !access.accessLevel.ftpLimited
            && !access.accessLevel.sshAccess
            && !access.accessLevel.statsAccess
        ) {
            access.props.validationErrorList.accessLevel.push(
                { text: this.$translate.instant('TR_100921-bb86d3_TR')}
            );
            return true;
        }

        access.props.validationErrorList.accessLevel = [];
        return false;
    };

    private _getWebspaceUsers = async (): Promise<WebhostingApi.User[]> => {
        if (this.$organismEditForm.webspace.accesses.length === 0) {
            return Promise.resolve([] as WebhostingApi.User[]);
        }

        const filters: Finding.Filter = { field: 'UserAccessesWebspaceId', value: this.$organismEditForm.webspace.id };
        return await this.webhostingUserModel
            .list(250, null, filters)
            .then((res: ViewTypes.ApiListResponse) => res.data
        );
    };

    private _compareAccessAndWebspaceUser = (
        webspaceUsers: WebhostingApi.User[]
    ): ViewTypes.WebspaceUserAccessObject[] => {
        const comparedAccesses: ViewTypes.WebspaceUserAccessObject[] = [];
        const userNameList: string[] = [];

        // Add new accesses
        for (const access of this.$organismEditForm.userAccessList) {
            if (access.props.isNewUser) {
                comparedAccesses.push(access);
            }
        }

        // Check existend webspace users and set access object
        for (const webspaceUser of webspaceUsers) {
            this.$organismEditForm.webspace.accesses.some(
                (access) => {
                    if (webspaceUser.id === access.userId) {
                        // Check if access exists in template user access list
                        let comparedAccess: ViewTypes.WebspaceUserAccessObject;
                        this.$organismEditForm.userAccessList.some(
                            (userAccess) => {
                                if (userAccess.userId === access.userId) {
                                    comparedAccess = userAccess;
                                    return true;
                                }

                                return false;
                            }
                        );

                        if (!comparedAccess) {
                            const ftpLimited = access.ftpAccess && access.homeDir.length > 0;

                            comparedAccess = {
                                accessLevel: {
                                    ftpAccess: access.ftpAccess && !ftpLimited,
                                    ftpLimited: ftpLimited,
                                    sshAccess: access.sshAccess,
                                    statsAccess: access.statsAccess
                                },
                                accessType: ViewTypes.UserAccessType.existing,
                                comments: webspaceUser.comments,
                                homeDir: access.homeDir,
                                name: webspaceUser.name,
                                password: undefined,
                                sshKey: webspaceUser.sshKey,
                                props: this.$organWebspaceUsers.addDefaultWebspaceUserObjectProperties(),
                                userId: access.userId,
                                userName: access.userName,
                                status: webspaceUser.status
                            };
                        }

                        const accessAlreadyInComparedList = userNameList.some(
                            (userName: string) => userName === comparedAccess.userName
                        );

                        if (!accessAlreadyInComparedList) {
                            comparedAccesses.push(comparedAccess);
                            userNameList.push(comparedAccess.userName);
                        }

                        return true;
                    }

                    return false;
                }
            );
        }

        return comparedAccesses;
    };

    private _applySearchFilter = (): void => {
        for (const access of this.$organismEditForm.userAccessList) {
            if (
                access.props.isBeingEdited
                || (access.props.isNewUser && !access.props.isMarkedForRemoval)
                || (access.props.isMarkedForRemoval && !access.props.isNewUser)
            ) {
                access.props.isHidden = false;
            } else {
                const lowerCaseAccessName = access.name.toLocaleLowerCase();
                const lowerCaseAccessUserName = access.userName.toLocaleLowerCase();
                access.props.isHidden = lowerCaseAccessName.indexOf(this.searchString.toLocaleLowerCase()) === -1
                    && lowerCaseAccessUserName.indexOf(this.searchString) === -1;
            }
        }
    };

    private _updateCurrentUser = (index: number, access: ViewTypes.WebspaceUserAccessObject): void => {
        this.$organismEditForm.userAccessList[index] = access;
    };

    private _removeCurrentUser = (index: number): void => {
        this.$organismEditForm.userAccessList = this.$organismEditForm.userAccessList.filter(
            (existingUser, currentIndex) => currentIndex !== index
        );
    };
}

export class MoleculePanelEditRowTableWebspaceUsersComponent implements ng.IComponentOptions {
    public require = {
        $editForm: '^moleculeFormEdit',
        $organismEditForm: '^organismEditFormWebspaceUsers',
        $organWebspaceUsers: '^organEditPanelWebspaceUsers',
        $panelEditTable: '^moleculePanelEditTable'
    };

    public bindings = {
        searchString: '=',
        panelRight: '<'
    };

    public controller = MoleculePanelEditRowTableWebspaceUsersController;
    public controllerAs = '$panelEditRowTable';
    public template = require('./webspace-users.html');
}
