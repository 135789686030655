/* eslint-disable */

import {
    BillingCycleToContractPeriodeConst,
    MachineProductFamilies,
    UiLanguagesConst
} from '@/configuration';
import { OrchestrationApi, ProductApi, ViewTypes } from '@/types';
import * as Types from '@/types';
import * as ng from 'angular';
import * as q from 'q';
import {
    AuthContextService,
    BillingHelperService,
    DomainInfoHelperService, InstallerModelService,
    ProductHelperService,
    WizardNewHelperService
} from '../';
import WizardComposerObject = ViewTypes.WizardComposerObject;

export class MachineComposerService {
    public static $inject: string[] = [
        '$state',
        '$translate',
        'billingHelper',
        'domainInfoHelper',
        'installerModel',
        'productHelper',
        'wizardNewHelper'
    ];

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private billingHelper: BillingHelperService,
        private domainInfoHelper: DomainInfoHelperService,
        public installerModel: InstallerModelService,
        private productHelper: ProductHelperService,
        private wizardNewHelper: WizardNewHelperService
    ) {}

    public buildMachineApiObject = async (metadata: ViewTypes.ProductConfigMachineObject) => {
        let composerObject: WizardComposerObject;
        const promises = [];
        let machineProduct: any;
        let domainInfo: any;
        let domainPrice: any;
        let domainProductCode: any;
        let contractPeriod: any;
        let contractPeriodDomain: any;
        let machineFactSheets: any = [];
        let machineBackupProduct: Types.ProductApi.AbstractProduct;
        let machineBackupFactSheet: ViewTypes.WizardSummaryViewDataObject[] = [];

        if (metadata.domain?.vHostOnly === false) {
            domainInfo = await this.domainInfoHelper.getDomainInfo(
                metadata.domain.nameUnicode,
                true,
                parseInt(metadata.account.id, 10)
            );
            domainProductCode = domainInfo.productCodes[domainInfo.orderAction];
        }

        // HERE BE DRAGONS !!!
        // don't change the order of these elements! If you do also adjust the mapping below in the q.all block!
        promises.push(this.billingHelper.getPriceByProductCode(metadata.productCode)); // machine price
        promises.push(this.productHelper.getProductFromProductCode(metadata.productCode)); // machine product
        promises.push(this.wizardNewHelper.factSheetConditioner(metadata.productCode)); // fact sheet items

        if (domainInfo) {
            promises.push( // domain product
                this.billingHelper.getPriceByProductCode(domainProductCode)
            );
        } else {
            promises.push(Promise.resolve());
        }

        if ([undefined, null].indexOf(metadata.machineBackupProductCode) < 0) {
            promises.push(this.productHelper.getProductFromProductCode(metadata.machineBackupProductCode));
            promises.push(this.wizardNewHelper.factSheetConditioner(metadata.machineBackupProductCode));
        }

        return q.all(promises).then((promiseResolves) => {
            promiseResolves.map(
                (resolve: any, index: number) => {
                    switch (index) {
                        case 0: // machine price -> billing cycle
                            contractPeriod = resolve.contractPeriod;
                            break;

                        case 1: // machine product
                            machineProduct = resolve;
                            break;

                        case 2: // fact sheet items
                            machineFactSheets = [{ // Laufzeit
                                description: '',
                                label: this.$translate.instant('TR_110419-f5d08d_TR'),
                                type: 'string',
                                value: this.$translate.instant(
                                    this.productHelper.getReadableBillingCycleMonthsFromWording(contractPeriod)
                                )
                            }];

                            if (metadata.installApp) {
                                const appList = this.$state.$current.locals.globals.appList.applications;
                                const selectedApp = appList.filter(
                                    (appData: any) => appData.general.pkgName === metadata.applicationSettings.appId
                                );
                                machineFactSheets.push({ // Selected App
                                    description: '',
                                    label: this.$translate.instant('TR_260520-88e5b5_TR'),
                                    type: 'string',
                                    value: selectedApp[0].data.provides.info.name + ' ('
                                        + this.$translate.instant('c0470e54-9d75-489a-8ebf-ae858e5a7996') // Version
                                        + ' ' + selectedApp[0].data.provides.info.version + ')'
                                });
                            }

                            const additionalFactSheetItems = resolve.filter(
                                (factSheetItem: any) => this._filterFactSheetItems(
                                    factSheetItem,
                                    metadata.productFamily
                                )
                            );

                            machineFactSheets = machineFactSheets.concat(additionalFactSheetItems);
                            break;

                        case 3: // domain product
                            if (resolve) {
                                domainPrice = resolve;
                                contractPeriodDomain = resolve.contractPeriod;
                            }
                            break;

                        case 4: // backup product
                            machineBackupProduct = resolve;
                            break;

                        case 5: // backup fact sheet
                            machineBackupFactSheet = [{
                                description: '',
                                label: this.$translate.instant('TR_110419-f5d08d_TR'),
                                type: ('string' as const),
                                value: this.$translate.instant(
                                    this.productHelper.getBillingCycleFromNumber(
                                        machineBackupProduct.billingCycle
                                    )
                                )
                                // @ts-ignore TS2769
                            }].concat(resolve);
                            break;
                    }
                }
            );

            // get base composer object
            switch (metadata.productFamily) {
                case MachineProductFamilies.ecommerceServer:
                    composerObject = this._getEcommerceMachineComposerBaseObject(metadata, domainInfo);
                    break;

                default:
                    composerObject = this._getMachineComposerBaseObject(metadata);
                    break;
            }

            composerObject.summaryObject.push({
                account: metadata.account,
                factSheets: machineFactSheets,
                product: machineProduct,
                productCode: metadata.productCode,
                voucher: metadata.voucher
            });

            if ([undefined, null].indexOf(metadata.machineBackupProductCode) < 0) {
                composerObject.summaryObject.push({
                    account: metadata.account,
                    factSheets: machineBackupFactSheet,
                    product: machineBackupProduct,
                    productCode: metadata.machineBackupProductCode
                });
            }

            if (domainProductCode) {
                composerObject.summaryObject.push({
                    account: metadata.account,
                    factSheets: [],
                    domainName: domainInfo.domainNameUnicode,
                    objectAction: domainInfo.orderAction,
                    product: {
                        addGracePeriod: 0,
                        billingCycle: BillingCycleToContractPeriodeConst[contractPeriodDomain],
                        description: '',
                        factSheetItems: [],
                        family: '',
                        featured: false,
                        language: UiLanguagesConst[AuthContextService.user.language],
                        name: this.$translate.instant('TR_090119-5ebc34_TR') + ': '
                            + domainInfo.domainNameUnicode,
                        productCode: domainProductCode,
                        productCodeTemplate: null,
                        productType: '',
                        requiredCreationRight: '',
                        restoreGracePeriod: null,
                        shortDescription: '',
                        specificationItems: [],
                        type: 'Product',
                        verificationAlternatives: []
                    } as ProductApi.Product,
                    productCode: domainProductCode
                });
            }

            return composerObject;
        });
    };

    public buildIndividualMachineApiObject = async (metadata: ViewTypes.ProductConfigMachineIndividualObject) => {
        const factSheetsArray: ViewTypes.WizardSummaryViewDataObject[] = [];

        factSheetsArray.push({
            label: this.$translate.instant('TR_140119-796b28_TR'),
            labelType: 'string',
            description: '',
            type: 'string',
            value: metadata.virtualMachine.name
        },
        {
            label: this.$translate.instant('3544cfe9-5051-4d0e-8ff8-6780de8607a2'),
            labelType: 'string',
            description: '',
            type: 'price_per_period',
            value: `${metadata.individualProperties.price} EUR / ${metadata.selectedBillingCycle}  Monat`
        },
        {
            label: this.$translate.instant('TR_090620-7df22e_TR'),
            labelType: 'string',
            description: '',
            type: 'string',
            value: metadata.individualProperties.managementType
        },
        {
            label: this.$translate.instant('TR_090620-da97d6_TR'),
            labelType: 'string',
            description: '',
            type: 'string',
            value: metadata.individualProperties.architecture
        },
        {
            label: this.$translate.instant('TR_090620-1a2f85_TR'),
            labelType: 'string',
            description: [
                `${this.$translate.instant('TR_090620-0f25d3_TR')}: ${metadata.individualProperties.cpu.cpuQuota}`,
                `${this.$translate.instant('TR_090620-4185d0_TR')}: ${metadata.individualProperties.cpu.cpuPeriod}`
            ],
            type: 'string',
            value: metadata.individualProperties.cpu.cpuCount
        },
        {
            label: `${this.$translate.instant('83276e15-cffa-49c7-b1dc-2556486d0eb6')} (${metadata.individualProperties.memory.capacity >= 1 ? 'GB' : 'MB'})`,
            labelType: 'string',
            description: '',
            type: 'string',
            value: metadata.individualProperties.memory.capacity >= 1
            ? metadata.individualProperties.memory.capacity
            : (metadata.individualProperties.memory.capacity * 1024).toFixed(2)
        },
        {
            label: `${this.$translate.instant('TR_111019-b7d378_TR')} (GB)`,
            labelType: 'string',
            description: [
                // tslint:disable-next-line:max-line-length
                `${this.$translate.instant('TR_090620-27c21d_TR')}: ${metadata.individualProperties.diskControllerType}`,
                `${this.$translate.instant('TR_090620-c79be7_TR')}: ${metadata.individualProperties.disk.type}`,
                `${this.$translate.instant('TR_090620-28ee5b_TR', { unit: '(MB/s)' })}: ${metadata.individualProperties.disk.Bps}`,
                `${this.$translate.instant('TR_090620-596e1c_TR')}: ${metadata.individualProperties.disk.Iops}`
            ],
            type: 'string',
            value: `${metadata.individualProperties.disk.capacity}`
        },
        {
            label: `${this.$translate.instant('TR_090620-8457e4_TR')} (Mbit/s)`,
            labelType: 'string',
            description: '',
            type: 'string',
            value: metadata.individualProperties.networkBandwidth
        });

        const composerObject = this._getIndividualMachineComposerBaseObject(metadata);
        const summaryObject: ViewTypes.WizardSummaryProductViewPanelObject[] = [{
            account: metadata.virtualMachine.account,
            productFamily: metadata.productFamily,
            productCode: metadata.productCode,
            product: {
                name: metadata.productCode,
                nameType: 'productCode'
            },
            factSheets: factSheetsArray
        }];
        composerObject.summaryObject.push(...summaryObject);
        return Promise.resolve(composerObject);
    };

    private _filterFactSheetItems = (factSheetItem: any, productFamily: any) => {
        let displayedCategories: any = [];
        let displayedKeywords: any = [];
        let removeKeywords: any = [];
        let displayAll = false;

        switch (productFamily) {
            case MachineProductFamilies.managedCloudServer:
            case MachineProductFamilies.ecommerceServer:
                displayedCategories = ['hardware'];
                displayedKeywords = ['monitoring', 'email_support', 'phone_support'];
                removeKeywords = ['raidlevel', 'virtualization'];
                break;

            default:
                displayAll = true;
        }

        const elementInDisplayList = displayedCategories.indexOf(factSheetItem.category) >= 0
            || displayedKeywords.indexOf(factSheetItem.keyword) >= 0;

        const elementInRemoveList = removeKeywords.indexOf(factSheetItem.keyword) >= 0;

        return ( elementInDisplayList && !elementInRemoveList ) || displayAll;
    };

    private _getIndividualMachineComposerBaseObject = (metadata: ViewTypes.ProductConfigMachineIndividualObject) => {
        const composerObject: WizardComposerObject = {
            apiObject: {
                virtualMachine: {
                    backupEnabled: !!metadata.machineBackupProductCode,
                    accountId: metadata.virtualMachine.account.id,
                    name: metadata.virtualMachine.name,
                    productCode: metadata.productCode,
                    diskControllerType: metadata.individualProperties.diskControllerType
                },
                virtualMachineSpecification: {
                    architecture: metadata.individualProperties.architecture,
                    cpuNumber: metadata.individualProperties.cpu.cpuCount,
                    cpuPeriod: metadata.individualProperties.cpu.cpuPeriod,
                    cpuQuota: metadata.individualProperties.cpu.cpuQuota,
                    memory: parseFloat(String(metadata.individualProperties.memory.capacity)) * 1024,
                    networkBandwidth: parseFloat(String(metadata.individualProperties.networkBandwidth))
                },
                diskSpecification: {
                    size: parseFloat(String(metadata.individualProperties.disk.capacity)) * 1024,
                    readBps: parseFloat(String(metadata.individualProperties.disk.Bps)) * 1024 * 1024,
                    readIops: parseFloat(String(metadata.individualProperties.disk.Iops)) * 1024 * 1024,
                    writeBps: parseFloat(String(metadata.individualProperties.disk.Bps)) * 1024 * 1024,
                    writeIops: parseFloat(String(metadata.individualProperties.disk.Iops)) * 1024 * 1024,
                    type: metadata.individualProperties.disk.type
                },
                // retail price has to be type int in cents
                retailPrice: parseFloat(String(metadata.individualProperties.price).replace(',', '.')) * 100,
                managementType: metadata.individualProperties.managementType,
                virtualMachineHostId: metadata.individualProperties.virtualMachineHostId
            },
            summaryObject: [],
            productFamily: metadata.productFamily
        };
        return composerObject;
    };

    private _getMachineComposerBaseObject = (metadata: ViewTypes.ProductConfigMachineObject) => {
        const composerObject: WizardComposerObject = {
            apiObject: {
                accountId: metadata.account.id,
                osId: null,
                osInstallerData: null,
                poolId: null,
                virtualMachineHostId: null,
                virtualMachine: {
                    backupEnabled: !!metadata.machineBackupProductCode,
                    productCode: metadata.productCode,
                    name: metadata.virtualMachine.name,
                    hostName: metadata.virtualMachine.hostName
                },
                voucher: null,
            },
            summaryObject: [],
            productFamily: metadata.productFamily,
        };

        if (metadata.installOs) {
            const osInstallerObject = {
                extraUserKeys: metadata.virtualMachine.os.extraUserKeys,
                extraUserName: metadata.virtualMachine.os.extraUserName,
                extraUserPass: metadata.virtualMachine.os.extraUserPass,
                rootUserKeys: metadata.virtualMachine.os.rootUserKeys,
                rootUserPass: metadata.virtualMachine.os.rootUserPass
            };
            composerObject.apiObject.osId = metadata.virtualMachine.os.id;
            composerObject.apiObject.osInstallerData = osInstallerObject;
        }

        if (metadata.voucher && metadata.voucher.voucherCode) {
            composerObject.apiObject.voucher = metadata.voucher;
        }

        return composerObject;
    };

    private _getEcommerceMachineComposerBaseObject = (metadata: any, domainInfo: any) => {
        /* ! ! ! HERE BE DRAGONS ! ! !
         * Don't change the order in which Elements are pushed into orderElementsStack!
         * For more details see: https://internal.keenlogics.com/confluence/pages/viewpage.action?pageId=23233586
         */
        const orderElementsStack = [];

        const managedVirtualMachineOrderItem: OrchestrationApi.ManagedVirtualMachine = {
            productCode: metadata.productCode,
            name: metadata.virtualMachine.name,
            type: 'ManagedVirtualMachine',
            poolId: null // is set in OrganismMachineWizardConfirmViewController
        };
        orderElementsStack.push(managedVirtualMachineOrderItem);

        const webspaceOrderItem: OrchestrationApi.Webspace = {
            name: metadata.domain.nameUnicode,
            productCode: 'webhosting-webspace-v1-12m',
            type: 'Webspace'
        };
        orderElementsStack.push(webspaceOrderItem);

        const domainOrderItem: OrchestrationApi.Domain = {
            createMode: this._getDomainCreateModeByStatus(domainInfo?.domainStatus?.status, metadata.domain.vHostOnly),
            domainNameUnicode: metadata.domain.nameUnicode,
            billingMode: 'standalone',
            type: 'Domain'
        };

        if (domainOrderItem.createMode === 'transfer') {
            domainOrderItem.authCode = metadata.domain.authCode;
        }

        orderElementsStack.push(domainOrderItem);

        if (metadata.installApp) {
            const dbOrderItem: OrchestrationApi.ApplicationDatabase = {
                productCode: 'database-mariadb-single-v1-12m',
                type: 'ApplicationDatabase'
            };
            orderElementsStack.push(dbOrderItem);

            const appOrderItem: OrchestrationApi.Application = {
                appId: metadata.applicationSettings.appId,
                installData: JSON.stringify(metadata.applicationSettings.appSettings),
                type: 'Application'
            };
            orderElementsStack.push(appOrderItem);
        }

        const composerObject: WizardComposerObject = {
            apiObject: this._buildOrderItems(orderElementsStack),
            summaryObject: [],
            productFamily: metadata.productFamily,
            accountId: metadata.account.id
        };

        return composerObject;
    };

    /* Stacks array elements into one another
     * given an array with 3 elements the end result is:
     *  {
     *      ...array[0],
     *      items: [
     *          {
     *              ...array[1]
     *              items: [
     *                  {
     *                      ...array[2]
     *                  }
     *              ]
     *          }
     *      ]
     *   }
     */
    private _buildOrderItems = (orderElementsStack: any[]) => {
        let implodedData;
        let lastNode;

        for (const orderElement of orderElementsStack) {
            if (!implodedData) {
                implodedData = orderElement;
            } else {
                lastNode.items.push(orderElement);
            }

            orderElement.items = [];
            lastNode = orderElement;
        }

        delete(lastNode.items);

        return implodedData;
    };

    private _getDomainCreateModeByStatus = (domainStatus: string, vHostOnly: boolean) => {
        if (vHostOnly) {
            return 'vHostOnly';
        }

        switch (domainStatus) {
            case 'available':
                return 'register';

            case 'registered':
                return 'transfer';

            default:
                return 'error: ' + domainStatus;
        }
    };
}
