import * as ng from 'angular';

import './dns-template-placeholder.scss';

export class OrganViewPanelDnsTemplatePlaceholderController {
    public static $inject: string[] = [];

    public records: ({
        name: string;
        type: string;
        ttl: number;
        content: string;
        priority?: number;
    })[];

    $onInit() {
        this.records = [
            { name: '*', type: 'A', ttl: 86400, content: '##IPV4##' },
            { name: '', type: 'A', ttl: 86400, content: '##IPV4##' },
            { name: 'mail', type: 'MX', ttl: 86400, content: '##DOMAIN##', priority: 0 },
            { name: '', type: 'MX', ttl: 86400, content: 'mail.##DOMAIN##', priority: 0 }
        ];
    }
}

export class OrganViewPanelDnsTemplatePlaceholderComponent implements ng.IComponentOptions {
    public controller = OrganViewPanelDnsTemplatePlaceholderController;
    public template = require('./dns-template-placeholder.html');
}
