import * as ng from 'angular';
import { WizardObject } from '../../wizard-supervisor/wizard-supervisor';

export class MoleculeWizardSummaryItemRedirectionController implements ng.IController {
    public static $inject: string[] = ['$translate'];
    public serviceObject: WizardObject;
    public domains: string[] = [];

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.domains = this.extractDomains();
    }

    public get redirectionType() {
        switch (this.serviceObject.settings.type) {
            case '301': return this.$translate.instant('TR_100119-847f87_TR');
            case '302': return this.$translate.instant('TR_100119-6405c3_TR');
            case 'frame': return this.$translate.instant('TR_100119-f48ca7_TR');
        }
    }

    public extractDomains = () => {
        if ([undefined, null, ''].indexOf(this.serviceObject.settings.domainName) >= 0) {
            return [];
        }

        return this.serviceObject.settings.domainName
            .split(/\r?\n/)
            .filter((line) => line.trim().length > 0)
            .filter((line, index, lines) => lines.indexOf(line) === index);
    };
}

export class MoleculeWizardSummaryItemRedirectionComponent implements ng.IComponentOptions {
    public bindings = {
        serviceObject: '='
    };
    public template = require('./wizard-summary-redirection.html');
    public controller =  MoleculeWizardSummaryItemRedirectionController;
}
