import * as ng from 'angular';
import { ValidationErrorList } from '@/atomic-components/molecules';
import { UiRights } from '@/configuration/rights';
import { InputPlaceholderConst } from '@/configuration/system';
import {
    AuthContextService,
    DataObject,
    DnsZoneModelService,
    DomainCheckerService,
    VhostModelService
} from '@/services';
import { debounce } from '@/services/helpers/debounce';
import { DomainInfoHelperService } from '@/services/helpers/domain-info-helper';
import { ValidateURL } from '@/services/validators/validate-url';

import { MachineApi, ResourceApi, ViewTypes } from '@/types';

export class OrganismWebspaceWizardProductVhostConfigController {
    public static $inject: string[] = [
        '$state',
        '$translate',
        'dnsZoneModel',
        'domainChecker',
        'domainInfoHelper',
        'vhostModel'
    ];

    public metaData: ViewTypes.ProductConfigWebspaceObject;
    public service = 'webspace';
    public productCodeList: string[];

    public vhostValidationErrors: ValidationErrorList;

    public serverTypes: Record<string, string>;
    public domainTypeButtons: { label: string; value: string }[];
    public redirectionDropdownItems: { name: string; value: string }[];
    public profileDropdownItems: { name: string; value: string }[];
    public webServerDropdownItems: { name: string; value: string }[];
    public redirectionUrlValidationInstructions: DataObject[];

    private _vhostAlreadyExists: boolean;
    private _vhostChecked: boolean;
    public profiles: any;
    public profileLoaded: boolean;
    public webserverRessource: ResourceApi.Resource;
    public pool: ResourceApi.Pool;
    public machine: MachineApi.VirtualMachine;
    public inputPlaceholder = InputPlaceholderConst;
    private _dnsZoneExists: boolean;
    public get dnsZoneExists(): boolean {
        return this._dnsZoneExists;
    }
    public set dnsZoneExists(value: boolean) {
        this.metaData.vhostSettings.domainExtern = value;
        this._dnsZoneExists = value;
    }
    public domainNameDebounce: (vhostName: string) => void;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private dnsZoneModel: DnsZoneModelService,
        private domainChecker: DomainCheckerService,
        private domainInfoHelper: DomainInfoHelperService,
        private vhostModel: VhostModelService
    ) {}

    public $onInit = async (): Promise<void> => {
        this._initGlobals();
        this.serverTypes = { apache: 'Apache', nginx: 'nginx' };
        this.vhostValidationErrors = [];
        this.metaData.vhostSettings.systemAlias = this.$translate.instant('TR_110119-ae3a43_TR');
        this.domainTypeButtons = [
            {
                label: this.$translate.instant('TR_100119-e6d165_TR'),
                value: 'website'
            },
            {
                label: this.$translate.instant('TR_100119-688348_TR'),
                value: 'redirect'
            }
        ];

        this.profileDropdownItems = [{
            name: this.$translate.instant('TR_100119-d642b2_TR'),
            value: null
        }];
        this.metaData.vhostSettings.domainProfile = null;
        for await (const profile of this.profiles) {
            this.profileDropdownItems.push({
                name: profile.name,
                value: profile
            });
        }

        this.webServerDropdownItems = Object.keys(this.serverTypes)
        .map(
            (key) => ({
                name: this.serverTypes[key],
                value: key
            })
        );

        this.redirectionDropdownItems = [
            {
                name: this.$translate.instant('TR_110119-7afd3e_TR'),
                value: '301'
            },
            {
                name: this.$translate.instant('TR_110119-abaecd_TR'),
                value: '302'
            },
            {
                name: this.$translate.instant('TR_110119-287708_TR'),
                value: '307'
            }
        ];

        this.redirectionUrlValidationInstructions = [
            {
                instructions: {},
                validator: new ValidateURL(this.$translate, this.domainInfoHelper, false, ['https', 'http'])
            }
        ];

        this.domainNameDebounce = debounce(this._vhostNameChanged, 500);
    };

    public get additionalAvailableFqdn(): string {
        return `www.${this.metaData.vhostSettings.domainName}`;
    }

    private _initGlobals = (): void => {
        this.webserverRessource = this.$state.$current.locals.globals.webserverRessource;
        this.profiles = this.$state.$current.locals.globals.profiles;
        this.pool = this.$state.$current.locals.globals.pool;
        this.machine = this.$state.$current.locals.globals.machine;
    };

    private _vhostNameChanged = (value: string): void => {
        if ([null, undefined, ''].includes(value)) {
            return;
        }
        this._checkVhost(value);
        this._checkDns(value);
        this.metaData.vhostSettings.configPath = value;
    };

    private _checkVhost = (vhostName: string): void => {
        if (vhostName?.length < 1 || this.vhostValidationErrors?.length > 0) {
            return;
        }

        this._vhostChecked = false;

        void this.domainChecker.checkOne(vhostName).then((domainStatus) => {
            void this.vhostModel.listWithoutPagination(
                null,
                1,
                {field: 'VHostDomainNameAce', value: domainStatus.domainName})
                .then(
                    (vhost) => {
                        this._vhostAlreadyExists = vhost.data.length > 0;
                        this._vhostChecked = true;
                    }
                );
        });
    };

    private _checkDns = (vhostName: string): void => {
        void this.dnsZoneModel.findOneByName(vhostName, true).then((result) => {
            if (!result) {
                this.dnsZoneExists = false;
                this.metaData.vhostSettings.createNewARecord.status = false;
                return;
            }
            this.dnsZoneExists = result.nameUnicode === vhostName;
            this.metaData.vhostSettings.createNewARecord.id = result.id;
            this.metaData.vhostSettings.createNewARecord.status = true;
        });
    };

    // eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function
    public set vhostAlreadyExists({}) {}
    public get vhostAlreadyExists(): boolean {
        return this._vhostAlreadyExists;
    }

    public get showVhostAlreadyExistsHint(): boolean {
        return this._vhostChecked && this.vhostAlreadyExists;
    }

    public get domainName(): string {
        return this.metaData?.vhostSettings?.domainName;
    }

    public set domainName(value: string) {
        if (this.metaData.vhostSettings.domainName === value) {
            return;
        }
        this.metaData.vhostSettings.domainName = value;
        this.domainNameDebounce(value);
    }

    public get isRedirect(): boolean {
        return this.metaData.vhostSettings?.configTyp === 'redirect';
    }

    public get isAdmin(): boolean {
        return AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_WEBHOSTING_ADMIN);
    }
}

export class OrganismWebspaceWizardProductVhostConfigComponent implements ng.IComponentOptions {
    public bindings = {
        metaData: '='
    };

    public template = require('./webspace-wizard-products-vhost-config.html');
    public controller = OrganismWebspaceWizardProductVhostConfigController;
}
