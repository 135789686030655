/* eslint-disable */
import ng from 'angular';
import { OverviewModelUtilityChart } from '@/atomic-components/molecules/charts/utility-status/utility-status';
import { LinkListOverviewItem } from '@/atomic-components/molecules/links/link-list-overview/link-list-overview';
import { OverviewDataObject } from '@/atomic-components/organisms/overview/overview';
import { UiRights } from '@/configuration';
import { FilterField } from '@/atomic-components/molecules/search';
import { BundleApi } from '@/types';
import { ListCallbacks, OverviewModel } from '@/services/abstract';
import {
    AuthContextService,
    BundleModelService,
    DomainHelperService,
    DomainModelService,
    RequestCancellerService,
    VhostModelService
} from '@/services';

export interface DomainVhostOverviewObject {
    id: string;
    bundleId: string|null;
    domainId: string|null;
    vHostId: string|null;
    domainName: string;
    domainNameUnicode: string;
    path: string;
    phpVersion: string;
    type: string;
    status: string;
    rawType?: string;
    child?: boolean;
    isRedirect?: boolean;
}

export class DomainVhostOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat([
        '$translate',
        'authContext',
        'bundleModel',
        'domainModel',
        'domainHelper',
        'vhostModel'
    ]);

    public availableFilters: FilterField<unknown>[];

    public readonly service = 'domain';
    public readonly objectType = 'Domain';
    public readonly idField = 'domainId';
    public readonly defaultFilterFields = ['domainNameUnicode'];

    public activeMassAction: string | boolean = false;
    public selected = {};
    public selectedAll =false;
    public actionLinksAvailabilities = {};
    public overviewData: OverviewDataObject;
    public overviewUtilityChart: unknown[] = [];
    protected listCallbacks: ListCallbacks[] = [];

    private _childrens: unknown[] = [];
    private returnData: unknown;
    private usedListMethod: string;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private bundleModel: BundleModelService,
        private domainModel: DomainModelService,
        private domainHelper: DomainHelperService,
        private vhostModel: VhostModelService
    ) {
        super(RequestCanceller);
        this.availableFilters = [];

        // declare multi action availably methods
        this.actionLinksAvailabilities = {};

        // declare multi action availably methods
        this.cutomizedModelListMethods = {
            overviewOnLoad: this.overviewOnLoadMethod
        };

        // Define listCallbacks
        this.listCallbacks.push({callback: this.domainModel.list});
        this.listCallbacks.push({
            callback: (limit?, page?, filters?, sort?, webspaceId?) => {
                if ([undefined, null, ''].indexOf(filters) < 0) {
                    let filterString = JSON.stringify(filters);
                    filterString = filterString.replace(
                        /([ '"\\])(domainName(?:Ace|Unicode))([ '"\\])/gi,
                        '$1VHost$2$3'
                    );

                    return this.vhostModel.list(limit, page, JSON.parse(filterString), sort, webspaceId);
                }

                return this.vhostModel.list(limit, page, filters, sort, webspaceId);
            }
        });
    }

    public actionLinks = (): LinkListOverviewItem[] => {
        return [{
            isGranted: this.authContext.isGrantedAny([
                UiRights.DOM_DOMAINS_CREATE_WITH_CONTACTS,
                UiRights.DOM_DOMAINS_CREATE_WITH_DEFAULTS,
                UiRights.DOM_DOMAINS_CREATE_WITH_OWN_NS,
                UiRights.WEB_VHOST_CREATE
            ]),
            route: 'bundle.id.domains.new',
            text: this.$translate.instant('TR_140119-58b9d3_TR')
        }] as LinkListOverviewItem[];
    };

    /**
     * Domain in Bundle, set utilityChart for status chartValue
     */

    public bundleUtilityChart = (): OverviewModelUtilityChart => {
        return {
            inUse: true,
            infoText: this.$translate.instant('TR_140119-8db9f9_TR'),
            maxUse: true,
            productcodes: ['domain-de-renew'],
            showAbsolute: true
        };
    };

    /**
     * Customized overview onLoad methode
     */
    public overviewOnLoadMethod = (): Promise<unknown> => {
        // set overview response data object
        this.returnData = {
            data: [],
            pagination: {
                currentPage: this.overviewData.pagination.currentPage,
                entries: 0,
                limit: this.overviewData.pagination.limit
            }
        };

        if ([undefined, null].indexOf(this.overviewData.additionData.bundle) >= 0) {
            this.usedListMethod = 'standard';

            void this.domainHelper.stdOverviewOnLoadMethod(
                this.returnData,
                null,
                this.overviewData
            );
        } else {
            this.usedListMethod = 'bundleVhost';
            const bundleId = this.overviewData.additionData.bundle.id;

            return this.bundleModel.bundleVhostFind(bundleId)
            .then(
                (vhosts) => {
                    // Warning: This method gets called twice on overview load, therefore
                    // we must reset `returnData.data` here in the handler. Otherwise the result
                    // might be pushed into data twice.
                    this.returnData.data = [];
                    if (vhosts.status === 'successful') {
                        vhosts.roots.map(
                            (vhost: BundleApi.BundleVHostTreeVertex) => {
                                this._childrens = this.domainHelper.normalizeVhostTree(vhost, bundleId);

                                this.returnData.data.push(
                                    DomainHelperService.setVhostOverviewObject(
                                        { name: vhost.name, nameUnicode: vhost.nameUnicode },
                                        bundleId,
                                        vhost.domainDetails,
                                        vhost.vHostDetails
                                    )
                                );

                                if (this._childrens.length > 0) {
                                    this._childrens.map(
                                        (child) => this.returnData.data.push(child as DomainVhostOverviewObject)
                                    );
                                }
                            }
                        );

                        return this.returnData;
                    } else {
                        // more than max vhost in bundles - load default overview
                        this.usedListMethod = 'standard';

                        return this.domainHelper.stdOverviewOnLoadMethod(
                            this.returnData,
                            bundleId,
                            this.overviewData
                        );
                    }
                }
            );
        }
    };

    /**
     * Option to manipulate disableFilter in service overview modelHelper
     **/
    public getDisableFilter = (): {fancyFilter: boolean; searchFilter: boolean} => {
        return this.usedListMethod === 'standard'
        ? {
            fancyFilter: false,
            searchFilter: false
        }
        : {
            fancyFilter: true,
            searchFilter: true
        };
    };
}
/* eslint-enable */
