import * as ng from 'angular';

import { UIRegExp } from '@/configuration';

import {
    AlertManagerService, ApiErrorModel, AuthenticationRobotService, DateTimeWrapper,
    NavigationService
} from '../../../services';

export const AUTHENTICATION_PASSWORD_RESET_ROUTES = [{
    name: 'password-reset',

    state: {
        data: {
            authenticate: false
        },
        onEnter: () => {
            ng.element(document.getElementsByTagName('body')[0]).addClass('login');
        },
        resolve: {
            code: ($stateParams: ng.ui.IStateParamsService) => {
                return $stateParams.code;
            },
            email: ($stateParams: ng.ui.IStateParamsService) => {
                return $stateParams.email;
            },
            validUntil: ($stateParams: ng.ui.IStateParamsService) => {
                return $stateParams.validUntil;
            }
        },
        url: '/password-reset?email&code&validUntil', /* Used in E-Mails
            Do not change the name of "password-reset"
            Nur in Absprache mit Michi zu ändern */
        views: {
            'wrapper@': {
                // tslint:disable-next-line:max-line-length
                template: `<template-password-reset
                                email="$resolve.email"
                                code="$resolve.code"
                                valid-until="$resolve.validUntil"
                            ></template-password-reset>`
            }
        }
    }
}];

export class TemplatePasswordResetController implements ng.IController {
    public static $inject: string[] = ['authenticationRobot', 'navigation'];
    public email: string;
    public code: string;
    public validUntil: string;
    public codeIsStillValid = true;
    public passwordResetFailed = false;
    public passwordResetFailedMessage: string;
    public resetCodeOrEmailMissing = false;
    public requestSent = false;
    public password = '';

    constructor(
        private authenticationRobot: AuthenticationRobotService,
        private navigation: NavigationService
    ) {}

    public $onInit = () => {
        if (this.validUntil && UIRegExp.IsValidDateFormat.test(this.validUntil)) {
            const validUntilDate = new DateTimeWrapper(this.validUntil);
            const nowDate = new DateTimeWrapper();
            nowDate.setToday();
            this.codeIsStillValid = validUntilDate.toUnixTimestamp() > nowDate.toUnixTimestamp();
            if (!this.codeIsStillValid) {
                this.code = undefined;
            }
        }
    };

    public submit = () => {
        if ([undefined, null, ''].indexOf(this.code) >= 0) {
            this.authenticationRobot.requestPasswordReset(this.email)
            .then(() => this.requestSent = true);
        }

        if ([undefined, null, ''].indexOf(this.password) >= 0) {
            return;
        }

        this.authenticationRobot.resetPassword(this.email, this.code, this.password)
        .then(
            (_response) => {
                this.requestSent = true;
                this.navigation.go('login', {emailAddress: this.email});
            },
            (error) => {
                if (error?.code === 20313) {
                    this.resetCodeOrEmailMissing = true;
                } else {
                    this.passwordResetFailed = true;
                    this.passwordResetFailedMessage = error;
                }
                this.code = '';
                this.password = '';
            }
        );
    };
}

export class TemplatePasswordResetComponent implements ng.IComponentOptions {
    public bindings = {
        code: '<',
        email: '<',
        validUntil: '<'
    };
    public template = require('./password-reset.html');
    public controller = TemplatePasswordResetController;
}
