
/**
 *  NEW ROUTES
 */
 import { ACCOUNT_ROUTES, RESELLER_ROUTES } from './account/routes';
 import { AUTHENTICATION_PASSWORD_RESET_ROUTES } from './authentication/password-reset/password-reset';
 import { BILLING_ROUTES } from './billing/routes';
 import { BUNDLE_ROUTES } from './bundle/routes';
 import { DATABASE_ROUTES } from './database/routes';
 import { DNS_ROUTES } from './dns/routes';
 import { DOMAIN_ROUTES } from './domain/routes';
 import { MAILBOX_ROUTES } from './email/routes';
 import { HELPDESK_ROUTES } from './global/helpdesk/routes';
 import { MACHINE_MANAGED_ROUTES } from './machine/machine-managed/routes';
 import { MACHINE_ROUTES } from './machine/routes';
 import { NETWORK_ROUTES } from './network/routes';
 import { REDIRECT_ROUTES } from './redirect/routes';
 import { SSL_ROUTES } from './ssl/routes';
 import { NEXTCLOUD_ROUTES, STORAGE_ROUTES } from './storage';
 import { WEBHOSTING_ROUTES } from './webhosting/routes';

 /**
  *  OLD ROUTES
  */
 import { AUTHENTICATION_LOGIN_ROUTES } from './authentication/login/login';

 import { STATES_ROUTES } from '@/scripts/states';

 export interface IRouteRights {
    isGranted?: string;
    isGrantedAll?: string[];
    isGrantedAny?: string[];
}

 export interface IRoutesArray {
    name: string;
    state: {
        data?: IRouteRights,
        url?: string;
    } | ng.ui.IState;
}

 export const routesArray: IRoutesArray[] = [].concat(
    AUTHENTICATION_LOGIN_ROUTES,
    AUTHENTICATION_PASSWORD_RESET_ROUTES,

    STATES_ROUTES,
    // new routesArray
    ACCOUNT_ROUTES,
    BILLING_ROUTES,
    BUNDLE_ROUTES,
    DATABASE_ROUTES,
    DNS_ROUTES,
    DOMAIN_ROUTES,
    HELPDESK_ROUTES,
    MACHINE_ROUTES,
    MACHINE_MANAGED_ROUTES,
    MAILBOX_ROUTES,
    NETWORK_ROUTES,
    NEXTCLOUD_ROUTES,
    REDIRECT_ROUTES,
    RESELLER_ROUTES,
    SSL_ROUTES,
    STORAGE_ROUTES,
    WEBHOSTING_ROUTES
);
