import * as ng from 'angular';
import './rights-table.scss';

export class MoleculeRightsTableController implements ng.IController {
    public rightsGroup: any;
    public rightsList: string[];
    public editableRights: string[];
    public inalienableRights: string[];
    public disabled = false;
    public masterCheckboxRights: string[] = [];
    public hasLoaded = false;
    public callbackOnClick: () => any;

    public rightExists = (right: string, subGroupItems: any[]) => {
        return subGroupItems[right] != null;
    };

    public isSingleRight = (subGroupItems: any) => {
        const sgi = subGroupItems;
        return (sgi.l === null && sgi.c === null && sgi.e === null && sgi.d === null);
    };

    public isIndeterminate = (subGroupItems: any) => {
        if ([null, undefined].indexOf(subGroupItems.items) !== -1) {
            return false;
        }
        const rights = Object.keys(subGroupItems.items).map(key => subGroupItems.items[key]);
        let cntRights = 0;
        let cntRightsSelected = 0;
        rights.map(
            (right) => {
                if (right !== null) {
                    cntRights++;
                    if (this.rightsList.indexOf(right.value) !== -1) {
                        cntRightsSelected++;
                    }
                }
            }
        );
        return (cntRightsSelected < cntRights) && cntRightsSelected !== 0;
    };

    public toggleHelp = (subGroup: any) => {
        subGroup.showHelp = !subGroup.showHelp;
    };
}

export class MoleculeRightsTableComponent implements ng.IComponentOptions {
    public bindings = {
        rightsGroup: '<',
        rightsList: '=',
        editableRights: '<',
        inalienableRights: '<',
        disabled: '<',
        callbackOnClick: '<'
    };
    public controller = MoleculeRightsTableController;
    public template = require('./rights-table.html');
}
