import ng from 'angular';

import { Contingent } from '@/atomic-components/organs';
import { AsteriskNoteService } from '@/services/billing/asterisk-note-service';
import * as Types from '@/types';
import { ViewTypes } from '@/types/view-types';

export class OrganismWebspaceWizardConfigViewController {
    public static $inject: string[] = [
        '$scope',
        'asteriskNote'
    ];

    public callback: (metaData: ViewTypes.ProductConfigWebspaceObject) => void;
    public outerAccount: Types.AccountApi.Account;
    public outerProductCode: string;
    public productFamilies: string[] = ['webspaces'];
    public productFamily: string;
    public service = 'webhosting';
    public sufficientCreditAvailable = true;
    public metaData: ViewTypes.ProductConfigWebspaceObject;

    private _account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    private productCodeList: string[];

    public constructor(
        private $scope: ng.IScope,
        private asteriskNote: AsteriskNoteService
    ) {}

    public $onInit = (): void => {
        this.asteriskNote.resetNodes();
        if ([undefined, null].indexOf(this.outerAccount) < 0) {
            this.account = this.outerAccount;
        }
        this.metaData =  {
            account: null,
            productFamily: '',
            productCode: '',
            contingent: {} as Contingent,
            webspaceName: '',
            storageQuota: 10240,
            additionalStorageItems: [],
            vhostSettings: {
                assignDomain: true,
                domainName: '',
                configTyp: 'website',
                configPath: '',
                redirectStatus: null,
                redirectTarget: '',
                enableDirectoryListing: false,
                enablePrefix: false,
                enableRedirect: false,
                enableSystemname: false,
                webserverType: 'nginx',
                domainProfile: null,
                createNewARecord: {
                    status: false,
                    id: ''
                }
            } as ViewTypes.ProductConfigWebspaceVhostSettingsObject,
            user: {
                typ: 'new',
                accesses: [],
                id: '',
                name: '',
                password: ''
            } as Partial<ViewTypes.ProductConfigWebspaceUserSettingsObject>
        } as ViewTypes.ProductConfigWebspaceObject;
        this._watchProductCodeList();
    };

    private _watchProductCodeList = (): void => {
        this.productCodeList = [];
        this.$scope.$watchCollection(() => this.metaData,
        (newMetaData: ViewTypes.ProductConfigWebspaceObject) => {
            if ([null, undefined].includes(newMetaData?.productCode)) {
                return;
            }
            this.productCodeList = [];
            this.productCodeList.push(newMetaData.productCode);
            if (![null, undefined].includes(newMetaData.additionalStorageItems)) {
                this.productCodeList.push(...newMetaData.additionalStorageItems);
            }
        });
    };

    public unsavedChangesExist = (): boolean => {
        if (this.metaData?.productCode?.length > 0
        || this.metaData?.webspaceName?.length > 0
        ) {
            return true;
        }
        return false;
    };

    get account(): Types.AccountApi.Account | Types.AccountApi.Subaccount {
        return this._account;
    }

    set account(value: Types.AccountApi.Account | Types.AccountApi.Subaccount) {
        if (
            [undefined, null].indexOf(value) < 0
            && value !== this._account
            && [undefined, null].indexOf(this.metaData) < 0
        ) {
            this.metaData.account = value;
        }

        this._account = value;
    }

    public finishConfiguration = (): void => {
        if (this.callback !== undefined) {
            this.callback(this.metaData);
        }
    };

    public get configCompleted(): boolean {
        return this._generalConfigCompleted
            &&  this._vhostConfigCompleted
            &&  this._userConfigCompleted;
    }

    private get _generalConfigCompleted(): boolean {
        return this.metaData.account
            &&  this.metaData.productCode?.length > 0
            &&  !isNaN(this.metaData.storageQuota)
            &&  this.metaData.webspaceName.length > 0;
    }

    private get _vhostConfigCompleted(): boolean {
        if ([false, undefined, null].includes(this.metaData.vhostSettings?.assignDomain)) {
            return true;
        }
        return this.metaData.vhostSettings?.domainName?.length > 0
            && this.metaData.vhostSettings?.configPath?.length > 0;
    }

    private get _userConfigCompleted(): boolean {
        if (this.metaData.user.userType === 'new') {
            return this.metaData.user?.name?.length > 0
                && this.metaData.user?.password?.length > 0
                && this.metaData.user?.accesses?.length > 0;
        }

        return this.metaData.user?.id
            && this.metaData.user?.accesses.length > 0;
    }
}

export class OrganismWebspaceWizardConfigViewComponent implements ng.IComponentOptions {
    public bindings = {
        callback: '<',
        productFamily: '=',
        outerAccount: '<',
        outerProductCode: '<'
    };

    public template = require('./webspace-wizard-config-view.html');
    public controller = OrganismWebspaceWizardConfigViewController;
}
