import * as ng from 'angular';

import { InputPlaceholderConst, UIRegExp, UiRights } from '../../../../../configuration';
import {
    AuthContextService, DomainInfoHelperService, MailboxModelService, ValidateEmail
} from '../../../../../services';
import * as Types from '../../../../../types';
import './panel-edit-row-email-checker.scss';

export class MoleculePanelEditRowEmailCheckerController implements ng.IController {
    public static $inject: string[] = ['$timeout', '$translate', 'domainInfoHelper', 'mailboxModel'];

    // used in template!
    public userHasRightAdminSystemSuperUserEdit = AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_SUPER_USER_EDIT);
    public emailFirstPart: string;
    public emailSecondPart: string;
    public message = '';
    public isCatchall: boolean;
    public domainDropdownItems: any[];
    public validationErrors: any[] = [];
    public accountId: string;
    public placeholderLabel: string;
    public dnsValidation = false;
    public dnsValidationCode = '';
    public callbackSearchDomain: (arg: any) => void;
    public callbackDomainSelected: (arg: any) => void;
    public productCode = '';
    public checkedEmailResponse: Types.EmailApi.CheckEmailAddressResult;
    public setAutoConfigureDns = false;
    public skipDnsCheck = false;

    protected inputPlaceholder = InputPlaceholderConst;

    private checkSuccessful = false;
    private lastCheckedAddress: string;
    private emailValidator: ValidateEmail;
    private _emailAddress: string;
    private validationCounter = 0;
    private _checkEmailInProcess = false;
    private _checkEmailDone = false;

    constructor(
        private $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        private domainInfoHelper: DomainInfoHelperService,
        private mailboxModel: MailboxModelService
    ) {}

    public $onInit() {
        this._issetEmailValidator();
        this._setEmailParts();
        this.placeholderLabel = this.placeholderLabel || '';
    }

    public $onChanges(changes: { [key: string]: ng.IChangesObject<any> }) {
        if (changes.accountId !== undefined) {
            this.checkEmailAddress();
        }
    }

    public $onDestroy() {
        this._setEmailAdress();
    }

    public checkEmailAddress = () => {
        this._checkEmailDone = false;
        this._issetEmailValidator();

        const currentNumber = (++this.validationCounter) + 0;

        this.$timeout(
            () => {
                if (this.validationCounter !== currentNumber) {
                    return;
                }

                if ([undefined, ''].indexOf(this.emailFirstPart) < 0) {
                    const firstPartValid = UIRegExp.EmailFirstPart.exec(this.emailFirstPart);
                    this.validationErrors = [];
                    if (firstPartValid === null) {
                        this.dnsValidation = false;
                        this.validationErrors.push(
                            { text: this.$translate.instant('TR_220819-fa8602_TR') } // tslint:disable-line
                        );
                        this.lastCheckedAddress = undefined;
                        this.checkSuccessful = false;
                        this._setEmailAdress();
                        return;
                    }
                }

                if (
                    [undefined, ''].indexOf(this.emailSecondPart) >= 0
                    || (
                        [undefined, ''].indexOf(this.emailFirstPart) >= 0
                        && !this.isCatchall
                    )
                ) {
                    this.dnsValidation = false;
                    this.message = '';
                    this.lastCheckedAddress = undefined;
                    this.checkSuccessful = false;
                    this._setEmailAdress();
                    return;
                }

                const address = (this.isCatchall ? '*' : this.emailFirstPart)
                + `@${this.emailSecondPart}`;

                this.validationErrors = this.emailValidator.validate(address);

                if (this.validationErrors.length > 0) {
                    this.dnsValidation = false;
                    this._setEmailAdress(false);
                    return;
                }

                this.checkedEmailResponse = undefined;
                this._checkEmailInProcess = true;
                this.mailboxModel.emailCheck(address, this.accountId, null, this.productCode).then(
                    (reply) => {
                        this.checkedEmailResponse = reply;
                        this.lastCheckedAddress = address;
                        this.dnsValidation = false;
                        switch (reply.status) {
                            case 'verificationRequired': {
                                this.dnsValidation = true;
                                this.dnsValidationCode = reply.verificationCode;
                                this.message = this.$translate.instant('TR_110119-f654f6_TR') + reply.verificationCode;
                                this.checkSuccessful = false;
                                break;
                            }
                            case 'emailAddressTaken': {
                                this.message = this.$translate.instant('TR_110119-e03806_TR');
                                this.checkSuccessful = false;
                                break;
                            }
                            case 'emailAddressAvailable': {
                                this.message = '';
                                this.checkSuccessful = true;
                                break;
                            }
                            case 'emailAddressInvalid': {
                                this.message = this.$translate.instant('TR_110119-0a213b_TR');
                                this.checkSuccessful = false;
                                break;
                            }
                            default: {
                                /*
                                    TODO: (RD) this mean, if we don't know what went wrong we give the user no hint
                                    what to do **UX-Fail**, shouldn't we say something please contact the support?
                                */
                                this.message = '';
                                this.checkSuccessful = false;
                            }
                        }

                        if (this.validationErrors.length === 0 && this.message.length > 0 && !this.dnsValidation) {
                            this.validationErrors.push({ text: this.message });
                        }
                        this._setEmailAdress();
                        this._checkEmailInProcess = false;
                        this._checkEmailDone = true;
                    }
                );
            },
            800
        );
    };

    public get emailCheckInProcess() {

        return this._checkEmailInProcess;
    }

    public set emailCheckInProcess({}) {} // tslint:disable-line:no-empty

    public get emailCheckDone() {
        return this._checkEmailDone;
    }

    public set emailCheckDone({}) {} // tslint:disable-line:no-empty

    public onSecondPartSelect = (emailSecondPart: string) => {
        this.emailSecondPart = emailSecondPart;
        this.checkEmailAddress();
        if (this.callbackDomainSelected !== undefined) {
            this.callbackDomainSelected(emailSecondPart);
        }
    };

    public skipDnsCheckChanged = (skipStatus: { status: boolean }) => {
        if (skipStatus.status !== this.skipDnsCheck) {
            this.skipDnsCheck = skipStatus.status;
        }
        this._setEmailAdress();
    };

    private _issetEmailValidator = () => {
        if ([undefined, null].indexOf(this.emailValidator) >= 0) {
            this.emailValidator = new ValidateEmail(true, this.$translate, this.domainInfoHelper);
        }
    };

    private _setEmailParts = (emailAdress?: string) => {
        emailAdress = emailAdress || this._emailAddress;

        if ([undefined, null].indexOf(emailAdress) >= 0) {
            this.emailFirstPart = '';
            this.emailSecondPart = '';
            return;
        }

        this.emailSecondPart = emailAdress.split('@')[1];
        if (!this.isCatchall) {
            this.emailFirstPart = emailAdress.split('@')[0];
        }

        this.checkEmailAddress();
    };

    private _setEmailAdress = (status?: boolean) => {
        status = status || this.checkSuccessful;

        if (this.lastCheckedAddress === undefined) {
            // set emailAdress binding only, if email adress was checked
            return;
        }

        if (status) {
            this._emailAddress = this._buildEmailAddress();
        } else {
            this._emailAddress = this.skipDnsCheck ? this._buildEmailAddress() : undefined;
        }
    };

    private _buildEmailAddress = () => {
        return (this.isCatchall ? '*' : this.emailFirstPart) + `@${this.emailSecondPart}`;
    };
}

export class MoleculePanelEditRowEmailCheckerComponent implements ng.IComponentOptions {
    public transclude = {
        label: 'rowLabel'
    };
    public bindings = {
        _emailAddress: '=value',
        accountId: '@',
        callbackDomainSelected: '<?',
        callbackSearchDomain: '<',
        domainDropdownItems: '<',
        isCatchall: '<',
        placeholderLabel: '@',
        productCode: '<',
        setAutoConfigureDns: '=?',
        skipDnsCheck: '=?'
    };
    public controller = MoleculePanelEditRowEmailCheckerController;
    public template = require('./panel-edit-row-email-checker.html');
}
