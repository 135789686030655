import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import {
    AlertManagerService,
    AuthContextService,
    DateWrapper,
    DdnsModelService, NavigationService
} from '../../../../../services';
import { DnsApi } from '../../../../../types';
import { EditPanelStatus } from '../../../../molecules/forms';

export class OrganismEditFormDdnsHostnameEditController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'alertManager',
        'authContext',
        'ddnsModel',
        'navigation'
    ];

    public userHasRightDnsDdnshostnameDelete = AuthContextService.isGranted(UiRights.DNS_DDNSHOSTNAME_DELETE);
    public userHasRightDnsDdnshostnameRestore = AuthContextService.isGranted(UiRights.DNS_DDNSHOSTNAME_RESTORE);
    public ddnsHost: DnsApi.DynamicDnsHostname;
    public ddnsHostInitial: DnsApi.DynamicDnsHostname;
    public availableDdnsUsers: DnsApi.DynamicDnsCredentials[];
    public availableDdnsUsersDropdownItems: any[];
    public showObjectId = false;
    public userConfirmedDeletion = false;
    public deletionDate: DateWrapper;
    public selectedRestoreAction: string;
    public deletionInitalStatus: EditPanelStatus;
    public password: string;
    public comment = '';
    public hostname = '';
    public chosenDdnsUserId = '';
    public productCode = 'dns-ddns-hostname-12m';
    public zoneId: string;
    public hostHasBeenSelected = false;
    public newUser = {
        accountId: '',
        comments: '',
        name: '',
        userName: ''
    };

    private _isPaidUntilExceeded: boolean;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private authContext: AuthContextService,
        private ddnsModel: DdnsModelService,
        private navigation: NavigationService
    ) {}

    public $onInit() {
        this.ddnsHostInitial = ng.copy(this.ddnsHost);
        this.newUser.accountId = ng.copy(this.ddnsHost.accountId);
        this.chosenDdnsUserId =  ng.copy(this.ddnsHost.credentialsId);
        this.availableDdnsUsersDropdownItems = [];
        this.deletionInitalStatus = this.ddnsHost.deletionScheduledFor === null
            ? EditPanelStatus.READONLY
            : EditPanelStatus.DELETIONSCHEDULED;
        this.availableDdnsUsersDropdownItems = this.availableDdnsUsers.map(
            (user) => {
                return {
                    names: [ user.name, user.comments ],
                    value: user.id
                };
            }
        );
    }

    public set isPaidUntilExceeded(value: boolean) {
        this._isPaidUntilExceeded = value;
    }

    public get isPaidUntilExceeded(): boolean {
        return this._isPaidUntilExceeded;
    }

    public cancel = () => {
        this.ddnsHost = ng.copy(this.ddnsHostInitial);
        this.chosenDdnsUserId =  ng.copy(this.ddnsHost.credentialsId);
        this.newUser = {
            accountId: this.ddnsHost.accountId,
            comments: '',
            name: '',
            userName: ''
        };
    };

    public save = () => {
        if (this.newUser.name.length > 0 && this.chosenDdnsUserId === '') {
            this.ddnsModel.createDdnsUser(this.newUser, this.password).then(
                (apiResponse) => {
                    this.chosenDdnsUserId = apiResponse.response.id;
                    this.ddnsHost.credentialsId = this.chosenDdnsUserId;
                    this.alertManager.success(this.$translate.instant('TR_130319-7e589e_TR'));
                    this.updateDdnsHost();
                },
                (apiResponse) => {
                    return apiResponse;
                }
            );
        } else {
            if (this.chosenDdnsUserId.length > 0) {
                this.ddnsHost.credentialsId = this.chosenDdnsUserId;
            }
            this.updateDdnsHost();
        }
    };

    public delete = () => {
        this.ddnsModel.deleteDdnsHost(this.ddnsHost.id, this.deletionDate).then(
            (apiResponse) => {
                this.alertManager.success(this.$translate.instant('TR_200319-8e9672_TR'));
                this.navigation.reloadCurrentState();
            },
            (apiResponse) => {
                return apiResponse;
            }
        );
    };

    public cancelDeletion = () => {
        this.ddnsModel.ddnsHostCancelDeletion(this.ddnsHost.id).then(
            (apiResponse) => {
                this.alertManager.success(this.$translate.instant('TR_200319-993fd2_TR'));
                this.navigation.reloadCurrentState();
            },
            (apiResponse) => {
                return apiResponse;
            }
        );
    };

    public restoreOrPurge = () => {
        if (this.selectedRestoreAction === 'restore') {
            this.ddnsModel.restoreDdnsHost(this.ddnsHost.id).then(
                (apiResponse) => {
                    this.alertManager.success(this.$translate.instant('TR_200319-f727da_TR'));
                    this.navigation.reloadCurrentState();
                }
            );
        } else if (this.selectedRestoreAction === 'purge') {
            this.ddnsModel.purgeRestorableDdnsHost(this.ddnsHost.id).then(
                (apiResponse) => {
                    this.alertManager.success(this.$translate.instant('TR_200319-0c59e3_TR'));
                    this.navigation.go('dns.ddns.hostnames.overview');
                }
            );
        }
    };

    private updateDdnsHost = () => {
        this.ddnsModel.updateDdnsHost(this.ddnsHost).then(
            (response) => {
                this.alertManager.success(this.$translate.instant('TR_190319-f26f0f_TR'));
                this.navigation.reloadCurrentState();

            },
            (apiResponse) => {
                return apiResponse;
            }
        );
    };
}

export class OrganismEditFormDdnsHostnameEditComponent implements ng.IComponentOptions {
    public bindings = {
        availableDdnsUsers: '<',
        showObjectId: '<',
        ddnsHost: '<'
    };

    public controller = OrganismEditFormDdnsHostnameEditController;
    public template = require('./ddns-hostname-edit.html');
}
