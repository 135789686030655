import * as ng from 'angular';
import { WizardConfirmationBase } from '../../general/confirm';
import {
    ApiErrorModel,
    ErrorMapperService,
    OrchestratorRobotService,
    SslRobotService
 } from './../../../../../services/';

export class SslWizardConfirmation extends WizardConfirmationBase {
    public static $inject: string[] = WizardConfirmationBase.$inject.concat(['sslRobot']);

    constructor(
        protected $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        protected apiErrorModel: ApiErrorModel,
        protected errorMapper: ErrorMapperService,
        protected localInterval: ng.IIntervalService,
        protected orchestratorRobot: OrchestratorRobotService,
        private sslRobot: SslRobotService
    ) {
        super(
            $timeout,
            $translate,
            apiErrorModel,
            errorMapper,
            localInterval,
            orchestratorRobot
        );
        this.isJobDependendWizard = false;
    }

    public getJobByFilter = (filter, cacheTimeout, objectType?: string) => {
        return this.sslRobot
            .jobsFind(
                filter,
                null,
                null,
                null,
                true,
                cacheTimeout
            )
            .then(this.normalizeResponseObject);
    };
}
