import * as ng from 'angular';
import { FormDropDownItems, ValidationErrorList } from '@/atomic-components/molecules/';
import { ValidateNotEmpty } from '@/services';
import './homedir.scss';

export class MoleculeFormHomedirController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public value: string;
    public disabled: boolean;
    public homedirPaths: FormDropDownItems[];
    public homedirpath = '';
    public homedir = '';
    public required = false;
    public validationInstructions: {instructions: null; validator: unknown}[] = [];
    public validationErrorList: ValidationErrorList[] = [];

    public constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    $onInit(): void {
        this.homedirPaths = [
            {value: '/html/', name:'/html/'},
            {value: '/data/', name: '/data/'}
        ];

        if (this.required) {
            this.validationInstructions = [
                {
                    instructions: null,
                    validator: new ValidateNotEmpty(this.$translate)
                }
            ];
        }

        if (this.value !== undefined && this.value.length > 0) {
            this.homedirPaths.some(path => {
                const index = this.value.indexOf(path.value);
                if (index >= 0) {
                    this.homedirpath = this.value.substr(index, path.value.length);
                    this.homedir = this.value.substr((path.value.length), this.value.length);
                    return true;
                }
                return false;
            });
        } else {
            this.homedirpath = this.homedirPaths[0].value;
        }

        this.value = this.homedirpath + this.homedir;
    }

    public changed = (): void => {
        this.value = this.homedirpath + this.homedir;
    };
}

export class MoleculeFormHomedirComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnChange: '<?',
        disabled: '<',
        required: '<?',
        value: '=',
        validationErrorList: '<?'
    };

    public controller =  MoleculeFormHomedirController;
    public controllerAs = '$formHomedir';
    public template = require('./homedir.html');
}
