import * as ng from 'angular';

export class SheriffDirective {

    public static Factory() {
        const directive = () => {
            return new SheriffDirective();
        };

        directive.$inject = [];

        return directive;
    }

    public restrict = 'A';
    public scope = {
        sherrif: '='
    };

    public link = ($scope: ng.IScope|any, $element: ng.IAugmentedJQuery) => {
        const isInvalid = (ignoreRequired) => {
            if (!$scope.sherrif) { return false; }

            const errors = Object.keys($scope.sherrif.$error);
            if (!ignoreRequired && errors.length === 1 && errors[0] === 'required') {
                return false;
            }

            return $scope.sherrif.$invalid;
        };

        $scope.$on(
            'show-errors-check-validity',
            () => {
                $element.toggleClass('has-error', isInvalid(true));
            }
        );

        $scope.$watch(
            isInvalid,
            () => {
                $element.toggleClass('has-error', isInvalid(false));
            }
        );
    };
}
