/* eslint-disable */

import * as ng from 'angular';
import { LinkListItem } from '../../links/link-list/link-list';
import { UiLanguagesConst } from '@/configuration';
import { AuthContextService, ProductsModelService } from '@/services';
import * as Types from '@/types';
import * as Sentry from '@sentry/browser';

export class MoleculePanelMachineDashboardController implements ng.IController {
    public static $inject: string[] = ['$translate', 'productsModel'];

    public machineItem: Types.MachineApi.VirtualMachine;
    public machineProduct: Types.ProductApi.AbstractProduct;
    public machineStatus = 'creating';
    public linkListFooter: LinkListItem[] = [];
    public panelHeader = {
        includeShowDetailsLink: false,
        panelHeaderRoute: 'machine.virtualmachines.id.edit',
        panelHeaderRouteParams: {}
    };
    public canHaveBackups: boolean = false;

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private productModel: ProductsModelService
    ) {}

    public $onInit(): void {
        this.panelHeader.includeShowDetailsLink = true;
        this.panelHeader.panelHeaderRouteParams = {
            machineId: this.machineItem.id
        };
        this.linkListFooter = [];

        this.productModel.findOne(
            this.machineItem.productCode,
            UiLanguagesConst[AuthContextService.user.language],
            true
        ).then(
            (apiResponse) => {
                if (apiResponse.status != 'success') {
                    const errorMessage: string = 'Server not found in general dashboard-view.';
                    // Throw console.error for development
                    console.error(errorMessage, apiResponse);
                    // Throw sentry error to track in live system
                    Sentry.captureMessage(
                        errorMessage,
                        {
                            extra: { apiResponse: apiResponse, message: errorMessage }
                        }
                    );
                }
                this.machineProduct = apiResponse.response;
                this.setCanHaveBackups();
            }
        );
    }

    public setCanHaveBackups(): void {
        let specificationItemsBackup = this.machineProduct?.specificationItems?.filter(
            (item) => item.name === 'backupProductCode'
        );
        if (specificationItemsBackup?.length > 0 && specificationItemsBackup.some(
            (item) => !!item.value)
        ) {
            this.canHaveBackups = true;
        } else {
            this.canHaveBackups = false;
        }
    }
}

export class MoleculePanelMachineDashboardComponent implements ng.IComponentOptions {
    public bindings = {
        linkListBottom: '<',
        linkListTop: '<',
        machineItem: '<'
    };
    public controller =  MoleculePanelMachineDashboardController;
    public template = require('./panel-machine-dashboard.html');
}
