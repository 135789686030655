import { LinkListOverviewItem } from '../../atomic-components/molecules/links/link-list-overview/link-list-overview';
import { UiRights } from '../../configuration';
import { OverviewModel } from '../abstract';
import { AuthContextService } from '../auth-context';
import { RequestCancellerService } from '../request-canceller';
import { DnsTemplateModelService } from './template-model';

export class DnsTemplateOverviewModel extends OverviewModel {

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = null;
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', 'dnsTemplateModel', 'authContext', '$q', 'objectStatusFilter']
    );

    readonly service = 'dns';
    readonly objectType = 'DnsZoneTemplate';
    readonly idField = 'templateId';
    readonly defaultFilterFields = ['TemplateName'];

    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};

    protected listCallbacks = [{callback: this.dnsTemplateModel.list}];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private dnsTemplateModel: DnsTemplateModelService,
        private authContext: AuthContextService,
        private $q: ng.IQService,
        objectStatusFilter
    ) {
        super(RequestCanceller);

        // declare multi action availably methods
        this.actionLinksAvailabilities = {};
    }

    public actionLinks = (params: { [param: string]: any }): LinkListOverviewItem[] => {
        return [
            {
                text: this.$translate.instant('TR_140119-6cc1cd_TR'),
                route: 'dns.templates.new',
                isGranted: this.authContext.isGranted(UiRights.DNS_TEMPLATES_CREATE)
            }
        ] as LinkListOverviewItem[];
    };

    /**
     *  Submit methods
     */

    /**
     *  Action availabbility definitions
     */
}
