import * as ng from 'angular';
import { AlertManagerService, DomainRobotService } from '../../../../services';
import './panel-whois.scss';

export class MoleculePanelWhoisController implements ng.IController {
    public static $inject: string[] = ['alertManager', 'domainRobot'];

    public domainName: null | string;
    public whoisInformationText: string;
    public showPanel = false;

    constructor(
        private alertManager: AlertManagerService, // Needed?
        private domainRobot: DomainRobotService
    ) {}

    $onChanges(changes) {
        if (changes.domainName !== undefined) {
            this._checkVisibility();
        }
    }

    public resetDomain = () => {
        this.domainName = null;
    };

    private _checkVisibility = () => {
        if ([undefined, null].indexOf(this.domainName) === -1) {
            this.getWhoiInfo().then((showPanel) => this.showPanel = showPanel);
        } else {
            this.showPanel = false;
            this.whoisInformationText = '';
        }
    };

    private getWhoiInfo = () => {
        if ([undefined, null].indexOf(this.domainName) >= 0) {
            return;
        }

        return this.domainRobot.whois(this.domainName).then(
            res => {
                this.whoisInformationText = res.response;
                return true;
            },
            err => {
                this.whoisInformationText = '';
                return false;
            }
        );
    };
}

export class MoleculePanelWhoisComponent implements ng.IComponentOptions {
    public bindings = {
        domainName: '<'
    };
    public controller =  MoleculePanelWhoisController;
    public template = require('./panel-whois.html');
}
