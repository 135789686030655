import * as ng from 'angular';
import { VhostDefaultLocationsObjectConst } from '../../../../configuration';
import { DomainInfoHelperService, ValidateDns, ValidateURL, VhostModelService } from '../../../../services';
import { WizardObject } from '../../../molecules';

export class OrganWizardWebspaceVhostController implements ng.IController {
    public static $inject: string[] = ['$translate', 'domainInfoHelper', 'vhostModel'];

    public serviceObject: WizardObject;
    public webServerDropdownItems = [];
    public vhostSingle: boolean;
    public webspaceId: string|null;
    public displayContent = false;

    private _vhost: any = {};

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private domainInfoHelper: DomainInfoHelperService,
        private vhostModel: VhostModelService
    ) {}

    public async $onInit() {
        this.webspaceId = this.webspaceId || null;
        this.vhostSingle = this.vhostSingle || false;

        this._vhost = await this.vhostModel.defaultVhostObject();
        this.displayContent = true;
        this._vhost.systemAlias = this.$translate.instant('TR_110119-18f3ba_TR');
        this._vhost.locations = ng.copy(VhostDefaultLocationsObjectConst);

        this._vhost.accountId = this.serviceObject.settings.accountId;
        this._vhost.webspace = this.serviceObject.settings.webspace;

        if (this.serviceObject.display.linkDomainToWebspace === undefined) {
            this.serviceObject.display.linkDomainToWebspace = true;
        }

        if (this.serviceObject.display.defaultLocation === undefined) {
            // this.serviceObject.display.defaultLocation = this._vhost.locations[0];
            Object.defineProperty(
                this.serviceObject.display,
                'defaultLocation',
                {
                    get: () => this._vhost.locations
                    .filter((location) => location.matchType === 'default')
                    [0]
                }
            );
        }

        if (this.serviceObject.display.isRedirect === undefined) {
            // this.serviceObject.display.isRedirect = false;
            Object.defineProperty(
                this.serviceObject.display,
                'isRedirect',
                {
                    get: () => {
                        return this.serviceObject.display.defaultLocation.locationType === 'redirect';
                    },
                    set: (value: boolean) => {
                        this.serviceObject.display.defaultLocation.locationType = value ? 'redirect' : 'generic';
                    }
                }
            );
        }

        this.serviceObject.settings.vhost = this.serviceObject.settings.vhost || this._vhost;
        this.serviceObject.settings.createNewARecord = false;
    }

    public get complete() {
        if (this.serviceObject.display.linkDomainToWebspace === false) {
            return true;
        }

        if (!this._validateDomainName()) {
            return false;
        }

        if (this.serviceObject.display.isRedirect && !this._validateRedirectUrl()) {
            return false;
        }

        return !(
            !this.serviceObject.display.isRedirect
            && (
                this.serviceObject.settings.vhost.webRoot !== undefined
                && this.serviceObject.settings.vhost.webRoot.length === 0
            )
        );
    }

    /* tslint:disable-next-line:no-empty */
    public set complete({}) {}

    public linkDomainToWebspaceChanged = (newValue) => {
        this.serviceObject.settings.createNewARecord = false;
    };

    public getErrorDomainText = (error: any) => {
        return error.domainSuffix.join(', ');
    };

    private _validateDomainName = () => {
        if (!this.serviceObject.settings.vhost) {
            return false;
        }

        const validator = new ValidateDns(this.$translate, false);
        return validator.validate(this.serviceObject.settings.vhost.domainNameUnicode).length === 0;
    };

    private _validateRedirectUrl = () => {
        if (this.serviceObject.display.defaultLocation.redirectionUrl === undefined) {
            return false;
        }
        const validator = new ValidateURL(this.$translate, this.domainInfoHelper, false);
        return validator.validate(this.serviceObject.display.defaultLocation.redirectionUrl).length === 0;
    };
}

export class OrganWizardWebspaceVhostComponent implements ng.IComponentOptions {
    public bindings = {
        complete: '=',
        serviceObject: '=',
        vhostSingle: '<',
        webspaceId: '<'
    };
    public controller =  OrganWizardWebspaceVhostController;
    public template = require('./vhost.html');
}
