import * as ng from 'angular';

export class OrganCreateConfirmationDomainController implements ng.IController {
    public static $inject: string[] = [];

    public domainObject: any;
    public panelHidden = false;
}

export class OrganCreateConfirmationDomainComponent implements ng.IComponentOptions {
    public bindings = {
        domainObject: '<',
        panelHidden: '<?'
    };
    public template = require('./domain-confirmation.html');
    public controller =  OrganCreateConfirmationDomainController;
}
