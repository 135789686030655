import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { UserSettingsManagerService } from '../../../services';

export class TemplateVirtualMachineOverviewController {
    public static $inject: string[] = ['$translate', 'userSettingsManager'];

    public pageHeaderData: PanelHeaderData;
    public overviewServiceData: any;
    public expertView: boolean;
    public overviewModel: any;
    public disableFilter: any = {
        fancyFilter: false,
        searchFilter: false
    };
    public simpleFilter: any;
    public extendedFilters: any;
    public overviewUiSettings: any;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private userSettingsManager: UserSettingsManagerService
    ) {}

    public $onInit() {
        this.expertView = true;

        this.pageHeaderData = {
            backwardLink: 'machine.dashboard',
            backwardText: this.$translate.instant('9a606991-45b5-4ec6-a927-ccfbaf9b24ae'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: 'cloud',
            panelTitle: this.$translate.instant('TR_140119-241fb6_TR')
        };

        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter
        };

        // Surpress backlink to dashboard on expert view because it auto-redirects to overview.
        this.userSettingsManager.getUiSettings().then(
            (result) => {
                if (result?.overviewSettings?.VirtualMachineOverviewCtrl?.expertView) {
                    this.pageHeaderData.backwardText = '';
                    this.pageHeaderData.backwardLink = '';
                    this.pageHeaderData.panelHeaderRouteParams = null;
                }
            }
        );
    }

    public expandWrapper = (toggle) => {
        this.expertView = toggle.expand;
    };
}

export class TemplateVirtualMachineOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public controller = TemplateVirtualMachineOverviewController;
    public controllerAs = 'TemplateVirtualMachineOverviewCtrl';
    public template = require('./virtualmachine-overview-template.html');
}
