import { OsInstallerRobotService, WizardNewHelperService } from '@/services';
import * as ng from 'angular';
import * as Types from '../../../../../types';
import { ViewTypes } from '../../../../../types';

export class OrganCreateConfigurationMachineOsController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'osInstallerRobot',
        'wizardNewHelper'
    ];

    public metadata: ViewTypes.ProductConfigMachineObject;
    public osSelectionDropdownItems: any[] = [];
    public osSelectionInfoItems: any[] = [];
    public osListReady = false;

    private _selectedOs: string;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private osInstallerRobot: OsInstallerRobotService,
        private wizardNewHelper: WizardNewHelperService
    ) {}

    public $onInit() {
        this.installOs = false;
        this._updateOsList(this.metadata.productCode);
        this._resetOsObject();
    }

    get selectedOs(): string {
        return this._selectedOs;
    }

    set selectedOs(value: string) {
        this._selectedOs = value;
        this.metadata.virtualMachine.os.id = this.installOs ? value : '';
        this._resetOsObject();
    }

    get installOs(): boolean {
        return this.metadata.installOs;
    }

    set installOs(value: boolean) {
        this.metadata.installOs = value;
        this._resetOsObject();
    }

    public set completed({}) {} // tslint:disable-line:no-empty
    public get completed() {
        return this._osSelectionComplete;
    }

    private _resetOsObject = () => {
        this.metadata.virtualMachine.os = {
            id: !this.installOs ? '' : this.selectedOs,
            rootUserPass: '',
            rootUserKeys: [],
            extraUserName: '',
            extraUserPass: '',
            extraUserKeys: []
        };
    };

    private _updateOsList = (productCode: string) => {
        if (productCode === undefined || productCode === null) {
            return;
        }

        this.osInstallerRobot.listOsInstallers(undefined, productCode).then(
            (reply) => {
                this.osSelectionDropdownItems = [];
                this.osSelectionInfoItems = [];

                for (const item of reply.response.applications) {
                    const os: Types.AppInstaller.AppSpecification = JSON.parse(item);

                    this.osSelectionDropdownItems.push({
                        name: `${os.data.provides.info.name} (${os.data.provides.info.version})`,
                        value: os.general.pkgName
                    });

                    this.osSelectionInfoItems.push({
                        description: os.data.provides.info.translations.de,
                        link: os.data.provides.info.projectURL,
                        name: os.general.pkgName,
                        title: os.data.provides.info.name
                    });
                    this.osListReady = true;
                }
            }
        );
    };

    public get osDescription() {
        if (this.selectedOs === null) {
            return '';
        }

        for (const item of this.osSelectionInfoItems) {
            if (item.name === this.selectedOs) {
                return item.description;
            }
        }

        return '';
    }

    public get osLink() {
        if (this.selectedOs === null ) {
            return '';
        }

        for (const item of this.osSelectionInfoItems) {
            if (item.name === this.selectedOs) {
                return item.link;
            }
        }

        return '';
    }

    public get osName() {
        if (this.selectedOs === null) {
            return ' - ';
        }

        for (const item of this.osSelectionInfoItems) {
            if  (item.name === this.selectedOs) {
                return item.title;
            }
        }

        return '';
    }

    private _osSelectionComplete = () => {
        if (!this.metadata) {
            return false;
        }

        const osSelected = this.metadata.virtualMachine.os?.id?.length > 0;
        const rootPasswordSet = this.metadata.virtualMachine.os?.rootUserPass?.length > 0;
        const osNeedsExtraUser = this.wizardNewHelper.osNeedsExtraUser(this.metadata.virtualMachine.os?.id);
        const extraUserNameSet = this.metadata.virtualMachine.os?.extraUserName?.length > 0;
        const extraUserPasswordSet = this.metadata.virtualMachine.os?.extraUserPass?.length > 0;

        return !this.metadata.installOs
            || (
                osSelected && rootPasswordSet
                && (
                    !osNeedsExtraUser
                    || (
                        extraUserNameSet
                        && extraUserPasswordSet
                    )
                )
            );
    };
}

export class OrganCreateConfigurationMachineOsComponent implements ng.IComponentOptions {
    public bindings = {
        metadata: '=',
        completed: '='
    };

    public template = require('./machine-os-config.html');
    public controller =  OrganCreateConfigurationMachineOsController;
}
