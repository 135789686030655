import * as ng from 'angular';
import { AlertManagerService, ApiErrorModel, AuthContextService, DomainModelService } from '../../../../../services';

export class OrganEditPanelDomainMoveContactsController {
    public static $inject: string[] = ['$translate', 'alertManager', 'apiErrorModel', 'authContext', 'domainModel'];
    public domain: any;
    public domains: any[] = [];
    public selectedDomains: any[] = [];
    public selectedDomainNames: string[] = [];
    public subaccounts: any;
    public contactData: any[] = [];
    public contacts: any[];
    public contactDropdownItems: any[];
    public moveIsPossible: boolean;
    public domainAccountId = '';
    private _targetAccount: string | null;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private authContext: AuthContextService,
        private domainModel: DomainModelService
    ) {}

    public get targetAccount() {
        return this._targetAccount;
    }

    public set targetAccount(value: string) {
        if (
            [undefined, null, this._targetAccount, this.domainAccountId, '', '0'].indexOf(value) < 0
        ) {
            this._targetAccount = value;
            this.apiErrorModel.destroyErrorList();
            this.domainMoveQuery(value);
            this.createContactReplacements(value);
        } else {
            this._targetAccount = value;
            this.contactData = [];
            this.moveIsPossible = false;
        }
    }

    public domainMoveQuery = (targetAccount: string) => {
        if (this.selectedDomainNames === undefined || this.selectedDomainNames.length === 0) {
            return;
        }

        this.domainModel.domainMoveQuery(this.selectedDomainNames, targetAccount).then(
            (res) => {
                this.contactData = [];
                if (res.response.domainErrors.length > 0) {
                    this.moveIsPossible = false;
                    res.response.domainErrors.map((error) => {
                        this.alertManager.error(
                            this.$translate.instant('TR_110119-c869ca_TR')
                                + error.domainNameUnicode + ': ' + error.error.join(', ')
                        );
                    });
                } else {
                    res.response.contactData.map(
                        (contact) => {
                            this.moveIsPossible = true;
                            this.contactData.push({
                                allocations: contact.allocations,
                                availableOptions: contact.availableOptions,
                                contactHandle: contact.contactHandle,
                                contactId: contact.contactId,
                                replacement: undefined,
                                selectedOption: this._setDefaultContactSelectionOption(contact.availableOptions)
                            });
                        }
                    );
                }
            }
        );
    };

    public createContactReplacements = (targetAccount: string) => {
        this.contactDropdownItems = [];
        if (this.contacts === undefined) {
            return;
        }
        this.contacts.map(
            (contact) => {
                if (contact.accountId === targetAccount
                    || (contact.accountId === this.authContext.account.id
                    && contact.usableBySubAccount)
                ) {
                    const names = [
                        contact.handle,
                        contact.name,
                        contact.street[0] + ', ' + contact.city,
                        contact.organization,
                        contact.type
                    ];
                    this.contactDropdownItems.push({
                        names: names,
                        value: contact.id
                    });
                }
            }
        );
    };

    public get showContactBox() {
        return this.contactData !== undefined
            && this.contactData.length > 0
            && this.moveIsPossible;
    }

    private _setDefaultContactSelectionOption = (options) => {
        if (options.indexOf('keep') >= 0) {
            return 'keep';
        }
        if (options.indexOf('move') >= 0) {
            return 'move';
        }
        return undefined;
    };
}

export class OrganEditPanelDomainMoveContactsComponent implements ng.IComponentOptions {
    public bindings = {
        contactData: '=',
        contacts: '<',
        domain: '<?',
        domainAccountId: '<',
        domains: '<?',
        moveIsPossible: '=',
        selectedDomainNames: '<',
        subaccounts: '<',
        targetAccount: '<'
    };
    public controller = OrganEditPanelDomainMoveContactsController;
    public template = require('./domain-move-contacts.html');
}
