import * as ng from 'angular';

import { UIRegExp } from '@/configuration';

import {
    AccountModelService,
    AuthContextService,
    SwitchUserManagerService
} from '@/services';
import { PanicMode } from '@/services/panic-mode';
import { AccountApi } from '@/types';
import './link-subaccount-login.scss';

export class MoleculeLinkSubaccountLoginController implements ng.IController {
    public static $inject: string[] = [
        '$sanitize',
        '$timeout',
        '$transclude',
        '$translate',
        'accountModel',
        'switchUserManager'
    ];

    private static requestCounter = 0;

    public subaccount: AccountApi.AbstractAccount;
    public modifier: string;
    public enabled: boolean;
    public showCustomerNumber: boolean;
    public hideAccount: boolean;

    private _loadedAccountName = '';
    private doApiRequests = false;
    private previousSubaccountId: string;

    private subAccountTitle = this.$translate.instant('ACCOUNT.GENERAL.PARENT-ACCOUNT');
    private parentAccountText = this.$sanitize(this.$translate.instant('ACCOUNT.GENERAL.PARENT-ACCOUNT'));
    private parentAccountId: string;
    private subaccountId: string;

    constructor(
        private $sanitize: ng.sanitize.ISanitizeService,
        private $timeout: ng.ITimeoutService,
        private $transclude: ng.ITranscludeFunction,
        private $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService,
        private switchUserManager: SwitchUserManagerService
    ) {}

    public async $onInit(): Promise<void> {
        this.hideAccount = this.hideAccount || false;
        this.modifier = !this.enabled ? 'disabled' : '';
        this.enabled = this.enabled || false;

        if (this.subaccount !== undefined && [null, undefined].indexOf(this.subaccount.parentAccountId) < 0) {
            this.parentAccountId = this.subaccount.parentAccountId;
        }

        this.$transclude(
            (clone: JQLite) => {
                if (clone.length === 1 && [undefined, null].indexOf(clone[0]) < 0) {
                    // If text was transcluded, assume that account data was loaded and injected.
                    return;
                }

                // Otherwise, fetch account data...
                this.doApiRequests = true;
            }
        );

        await this.setSubaccountData();
    }

    public $onChanges(changes: ng.IOnChangesObject): void {
        if (changes.subaccountId) {
            void this.setSubaccountData();
        }
    }

    public keyUpEventCheck = (event: KeyboardEvent): void => {
        if (event.key === 'Enter') {
            this.switchUserManager.switchTo(this.subaccountId);
        }
    };

    public login = (event: KeyboardEvent): void => {
        event.stopPropagation();
        if (!this.enabled) {
            return;
        }
        this.switchUserManager.switchTo(this.subaccountId);
    };

    public get isDirectCustomer(): boolean {
        if ([undefined].indexOf(this.parentAccountId) >= 0) {
            return true;
        }

        if (this.currentUserIsCompanyOrRoot()) {
            return UIRegExp.IsNumeric.test('' + this.parentAccountId)
                && parseInt(this.parentAccountId, 10) < 1000;
        }

        return this.parentAccountId === AuthContextService.account.id;
    }

    public get isOwnAccount(): boolean {
        return this.subaccountId === AuthContextService.account.id;
    }

    public get isParentAccount(): boolean {
        return this.subaccountId === AuthContextService.account.parentAccountId;
    }

    public get loadedAccountName(): string {
        return this._loadedAccountName;
    }

    public get panicModeActive(): boolean {
        return PanicMode.active;
    }

    public get showLoginAsButton(): boolean {
        return !this.isOwnAccount && this.enabled;
    }

    public get userCurrentlyLoggedIn(): boolean {
        return this.isOwnAccount && !this.enabled;
    }

    public get isAccountDeactivated(): boolean {
        return !this.isOwnAccount && !this.enabled;
    }

    public get showLoadedAccountName(): boolean {
        if (this.hideAccount) {
            return false;
        }

        return this.loadedAccountName !== undefined ? true : false;
    }

    private setSubaccountData = (): Promise<void> => {
        if (!this.doApiRequests || this.subaccountId === this.previousSubaccountId) {
            return Promise.resolve();
        }
        this.parentAccountId = undefined;
        if (this.isOwnAccount) {
            this.enabled = false;
            this.previousSubaccountId = AuthContextService.account.id;
            this._loadedAccountName = AuthContextService.account.name;
        } else if (this.isParentAccount) {
            this.enabled = false;
            this.previousSubaccountId = AuthContextService.account.parentAccountId;

            this._loadedAccountName = `<fa-icon
                class="space-right"
                icon="level-up"
                title="${this.subAccountTitle}"
            ></fa-icon> ${this.parentAccountText}`;
        } else {
            MoleculeLinkSubaccountLoginController.requestCounter++;

            return void this.accountModel
                .findOneSubaccount(this.subaccountId)
                .then(
                    (subaccount: AccountApi.Subaccount) => {
                        this.parentAccountId = subaccount.parentAccountId;
                        this.enabled = subaccount.enabled;
                        this.previousSubaccountId = subaccount.id;
                        this._loadedAccountName = subaccount.name;

                        if (this.showCustomerNumber && [undefined, null, ''].indexOf(subaccount.customerNumber) < 0) {
                            this._loadedAccountName += ` (${subaccount.customerNumber})`;
                        }
                        MoleculeLinkSubaccountLoginController.requestCounter--;

                        if (MoleculeLinkSubaccountLoginController.requestCounter === 0) {
                            void this.$timeout();
                        }
                    },
                    () => {
                        this.enabled = false;
                        this.previousSubaccountId = this.subaccountId;
                        MoleculeLinkSubaccountLoginController.requestCounter--;

                        if (MoleculeLinkSubaccountLoginController.requestCounter === 0) {
                            void this.$timeout();
                        }
                    }
                );
        }

        return Promise.resolve();
    };

    private currentUserIsCompanyOrRoot = (): boolean => {
        return UIRegExp.IsNumeric.test(AuthContextService.account.id)
            && parseInt(AuthContextService.account.id, 10) < 1000;
    };
}

export class MoleculeLinkSubaccountLoginComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        enabled: '<?',
        hideAccount: '<?',
        showCustomerNumber: '<?',
        subaccount: '<?',
        subaccountId: '<'
    };
    public controller =  MoleculeLinkSubaccountLoginController;
    public template = require('./link-subaccount-login.html');
}
