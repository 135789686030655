import { CsvGenerator } from '../csv-generator';

export class DomainCsvGenerator {
    public static generateJobCsv = (jobs) => {
        const lines = jobs.map(
            (job) => {
                const errors = job.errors.map(DomainCsvGenerator.returnCode);
                const errorString = errors.join(';');

                return [
                    job.domainNameAce,
                    job.domainNameUnicode,
                    job.accountId,
                    job.addDate,
                    job.executionDate,
                    job.lastChangeDate,
                    job.type,
                    job.state,
                    errorString.length ? '"' + errorString + '"' : ''
                ];
            }
        );

        lines.unshift(
            [
                'Domain ACE',
                'Domain Unicode',
                'AccountID',
                'AddDate',
                'ExecutionDate',
                'LastChangeDate',
                'Type',
                'State',
                'Errors'
            ]
        );

        return CsvGenerator.generate(lines);
    };

    public static generateDomainCsv = (domains) => {
        const lines = domains.map(
            (domain) => {
                return [
                    domain.name,
                    domain.nameUnicode,
                    domain.accountId,
                    domain.addDate,
                    domain.lastUpdateDate,
                    domain.currentContractPeriodEnd,
                    domain.deletionDate,
                    domain.nameservers.map(DomainCsvGenerator.nameserverName).join(',')
                ];
            }
        );

        lines.unshift(
            [
                'Domain ACE',
                'Domain Unicode',
                'AccountID',
                'Added',
                'LastUpdate',
                'EndOfContract',
                'Delete',
                'Nameservers'
            ]
        );

        return CsvGenerator.generate(lines);
    };

    public static generateDomainPortfolioCsv = (domains) => {
        const lines = domains.map(
            (domain) => {
                return [
                    domain.id,
                    domain.domainNameAce,
                    domain.domainNameUnicode,
                    domain.accountId,
                    domain.customerNumber,
                    domain.ownerC,
                    domain.adminC,
                    domain.techC,
                    domain.zoneC,
                    domain.createDate,
                    domain.lastUpdate,
                    domain.addDate,
                    domain.expiryDate,
                    domain.deleteDate,
                    domain.nameservers.join(',')
                ];
            }
        );

        lines.unshift(
            [
                'ID',
                'Domain ACE',
                'Domain Unicode',
                'Account ID',
                'Customer number',
                'OwnerC',
                'AdminC',
                'TechC',
                'ZoneC',
                'Create date',
                'Last update',
                'Add date',
                'End of contract',
                'Delete date',
                'Nameservers'
            ]
        );

        return CsvGenerator.generate(lines);
    };

    public static generateDomainCountCsv = (entries) => {
        const lines = entries.map(
            (entry) => {
                return [
                    entry.domainSuffix,
                    entry.count
                ];
            }
        );

        lines.unshift(['Suffix', 'Count']);

        return CsvGenerator.generate(lines);
    };

    public static generateDomainJobsCsv = (jobs) => {
        const lines = jobs.map(
            (job) => {
                return [
                    job.id,
                    job.domainNameAce,
                    job.type,
                    job.state,
                    job.startDate,
                    job.execDate
                ];
            }
        );

        lines.unshift(['ID', 'Domain ACE', 'Type', 'State', 'StartDate', 'ExecDate']);

        return CsvGenerator.generate(lines);
    };

    public static generateDomainTurnoverCsv = (turnover) => {
        const lines = turnover.map(
            (turnoverLine) => {
                return [
                    turnoverLine.accountId,
                    turnoverLine.customerNumber,
                    turnoverLine.domainNameAce,
                    turnoverLine.execDate,
                    turnoverLine.startDate,
                    turnoverLine.type
                ];
            }
        );

        lines.unshift(['ID', 'CustomerNumber', 'Domain ACE', 'ExecDate', 'StartDate', 'Type']);

        return CsvGenerator.generate(lines);
    };

    public static generateDomainSubAccountTurnoverCsv = (turnover) => {
        const lines = turnover.map(
            (turnoverLine) => {
                return [
                    turnoverLine.accountId,
                    turnoverLine.accountName,
                    turnoverLine.created,
                    turnoverLine.customerNumber,
                    turnoverLine.deleted,
                    turnoverLine.transferIn,
                    turnoverLine.transferOut
                ];
            }
        );

        lines.unshift(['ID', 'AccountName', 'Created', 'CustomerNumber', 'Deleted', 'TransferIn', 'TransferOut']);

        return CsvGenerator.generate(lines);
    };

    private static returnCode(error) {
        return error.code;
    }

    private static nameserverName(nameserver) {
        return nameserver.name;
    }
}
