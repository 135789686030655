import * as ng from 'angular';

class MoleculePanelDebugController implements ng.IController {
    public hiddenKeys: string[] = [];
    public initialKeys: string[] = [];
    public name: string;
    public object;
    private shownKeys: string[] = [];

    public $onInit() {
        if ([undefined, null].indexOf(this.initialKeys) < 0 && this.initialKeys.length > 0) {
            this.shownKeys = this.initialKeys;
        }
    }

    public get keys() {
        if ([undefined, null].indexOf(this.object) >= 0) {
            return [];
        }

        return Object.keys(this.object)
        .filter((key) => this.object.hasOwnProperty(key));
    }

    public shown = (key: string) => this.shownKeys.indexOf(key) >= 0;

    public hidden = (key: string) => this.hiddenKeys.indexOf(key) >= 0;

    public toggle = (key: string) => {
        if (this.shown(key)) {
            this.shownKeys.splice(this.shownKeys.indexOf(key), 1);
        } else {
            this.shownKeys.push(key);
        }
    };
}

export class MoleculePanelDebugComponent implements ng.IComponentOptions {
    public bindings = {
        hiddenKeys: '<?hide',
        initialKeys: '<?show',
        name: '@?',
        object: '='
    };

    public controller = MoleculePanelDebugController;

    /* tslint:disable:max-line-length */
    public template = `
        <molecule-panel-view panel-title="!!! DEBUG{{ [undefined, null, ''].indexOf($ctrl.name) >= 0 ? '' : (': ' + $ctrl.name) }} !!!">
            <transclude-content>
                <molecule-panel-edit-row-info ng-repeat-start="key in $ctrl.keys" ng-if="!$ctrl.hidden(key)">
                    <atom-text modifier="subheadline">
                    <span ng-if="$ctrl.shown(key)" ng-click="$ctrl.toggle(key)">-</span>
                    <span ng-if="!$ctrl.shown(key)" ng-click="$ctrl.toggle(key)">+</span>
                    <span>{{ key }}</span>
                    </atom-text>
                </molecule-panel-edit-row-info>
                <molecule-panel-edit-row-info ng-repeat-end ng-if="!$ctrl.hidden(key)">
                    <pre ng-if="$ctrl.shown(key)">{{ $ctrl.object[key] | json }}</pre>
                </molecule-panel-edit-row-info>
            </transclude-content>
        </molecule-panel-view>
    `;
    /* tslint:ensable:max-line-length */
}
