import * as ng from 'angular';
import './utility-status.scss';

export interface UtilityStatusObject {
    inUse: string;
    maxUse?: string;
    addon?: string;
    isStorage?: string;
    showAbsolute?: boolean;
    infoText?: string;
}

export interface OverviewModelUtilityChart {
    inUse: boolean;
    maxUse: boolean;
    isStorage?: string;
    showAbsolute: boolean;
    infoText: string;
    productcodes: string[];
}

export class MoleculeChartUtilityStatusController implements ng.IController {
    public static $inject: string[] = ['$timeout', 'formatMegabyteFilter'];

    public reachedPercentage: string;
    public addonPercentage: string;
    public statusInformation = '';
    public showCircle = false;
    public chartValue: string;
    public ignoreStatusModifier = true;
    public containsUnit = false;

    public inUse: string;
    public maxUse: string;
    public infoText: string;
    public isStorage = false;
    public showAbsolute = false;
    public showBar = false;

    private _addon: string;
    private _initialized = false;
    private _totalChecksFetchingChartValue = 0;

    constructor(
        private $timeout: ng.ITimeoutService,
        private formatMegabyte
    ) {}

    public get addon() {
        return this._addon;
    }

    public set addon(value) {
        this._addon = value;

        if (this._initialized) {
            this.$onInit();
        }
    }

    public $onInit = () => {
        this._initialized = true;
        this.reachedPercentage = this.getReachablePercentage();
        this.addonPercentage = this.getAddonPercentage();

        const totalUsed = parseInt(`${this.inUse}`, 10)
        + (
            [undefined, null, ''].indexOf(this.addon) < 0
            ? parseInt(`${this.addon}`, 10)
            : 0
        );

        this.chartValue = `${totalUsed}`;

        if (this.inUse === undefined || this.inUse.length === 0) {
            return;
        }

        if (this.infoText !== undefined) {
            this.statusInformation = this.infoText;
        }

        if ([undefined, null, ''].indexOf(this.maxUse) >= 0) {
            // no maxUse given - show absolute
            if (this.isStorage !== undefined && this.isStorage) {
                // show value as storage
                this.setStorageAvailableUsedText();
            } else {
                if (+this.inUse > 999) {
                    this.showCircle = false;
                    this.chartValue = `${totalUsed}`;
                } else {
                    this.showCircle = true;
                    this.ignoreStatusModifier = true;
                    this.reachedPercentage = '100';
                }
            }
        } else {
            // maxUse given
            if (this.isStorage !== undefined && this.isStorage) {
                // show value as storage
                this.setStorageAvailableUsedText();
            }
            this.showCircle = true;
            if (this.showAbsolute !== undefined && this.showAbsolute) {
                this.ignoreStatusModifier = true;

                // show chart value absolute
                if (this.inUse.length >= 3 || this.maxUse.length >= 3 || this.maxUse.length + this.inUse.length >= 4) {
                    // show only inUse value
                    this.chartValue = `${totalUsed}`;
                } else {
                    // show inUse and maxUse as value
                    this.chartValue = `${totalUsed}` +  '/' + this.maxUse;
                }

            } else {
                this.chartValue = this.reachedPercentage + '%';
                this.ignoreStatusModifier = false;
            }
        }

        // Calls $onInit up to five times, as long as the chartValue is zero.
        // We can not be sure if zero is an error state or the unfetched value.
        this.$timeout(() => {
            if (!this._initialized || (++this._totalChecksFetchingChartValue) <= 5) {
                if ([undefined, null, '', '0'].indexOf(this.chartValue) >= 0) {
                    this.$onInit();
                }
            }
        }, 250);
    };

    private getReachablePercentage = () => {
        if ([undefined, null, ''].indexOf(this.maxUse) >= 0) {
            return '100';
        }
        if ([undefined, null, '', 0, '0'].indexOf(this.inUse) >= 0) {
            return '0';
        }
        const pt = (+this.inUse / +this.maxUse) * 100;
        return String((Math.round(pt * 100) / 100).toFixed(0));
    };

    private getAddonPercentage = () => {
        if ([undefined, null, ''].indexOf(this.addon) >= 0) {
            return '';
        }

        if ([undefined, null, ''].indexOf(this.maxUse) >= 0 && [undefined, null, ''].indexOf(this.inUse) >= 0) {
            return '';
        }

        let pt: number;

        if ([undefined, null, ''].indexOf(this.maxUse) < 0) {
            pt = (+this.addon / +this.maxUse) * 100;
        } else {
            pt = (+this.addon / +this.inUse) * 100;
        }

        return String((Math.round(pt * 100) / 100).toFixed(0));
    };

    private setStorageAvailableUsedText() {
        // show value as storage
        this.containsUnit = true;
        this.showCircle = true;
        this.reachedPercentage = String((+this.inUse / (+this.maxUse / 100)).toFixed(0));
        this.statusInformation = this.formatMegabyte(this.inUse) + ' / ' + this.formatMegabyte(this.maxUse);
    }
}

export class MoleculeChartUtilityStatusComponent implements ng.IComponentOptions {
    public bindings = {
        addon: '@',
        inUse: '@',
        infoText: '@',
        isStorage: '<',
        maxUse: '@',
        showAbsolute: '<',
        showBar: '<?'
    };
    public controller =  MoleculeChartUtilityStatusController;
    public template = require('./utility-status.html');
}
