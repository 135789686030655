import { AlphaNumericCaseInsensitive } from '@/configuration';

interface SimpleObservableSuccessEvent<T> {
    type: 'success';
    content: T;
}

interface SimpleObservableNoticeEvent {
    type: 'notice';
    content: any;
}

interface SimpleObservableErrorEvent {
    type: 'error';
    content: any;
}

type SimpleObservableEvent<T>
= SimpleObservableSuccessEvent<T>
| SimpleObservableNoticeEvent
| SimpleObservableErrorEvent;

const idCharacters = AlphaNumericCaseInsensitive;

class SimpleObservableSubscriber<T> {
    public readonly uniqueId: string;

    constructor(
        private success?: (value: T) => void,
        private notice?: (notice: any) => void,
        private error?: (error: any) => void
    ) {
        this.uniqueId = '';

        for (let i = 0; i < 128; i++) {
            this.uniqueId += idCharacters.charAt(
                Math.floor(Math.random() * idCharacters.length)
            );
        }
    }

    public handleEvent = (event: SimpleObservableEvent<T>) => {
        switch (event.type) {
            default: break;

            case 'error':
                if ([undefined, null].indexOf(this.error) < 0) {
                    this.error(JSON.parse(JSON.stringify(event.content)));
                }

                break;

            case 'notice':
                if ([undefined, null].indexOf(this.notice) < 0) {
                    this.notice(JSON.parse(JSON.stringify(event.content)));
                }

                break;

            case 'success':
                if ([undefined, null].indexOf(this.success) < 0) {
                    this.success(JSON.parse(JSON.stringify(event.content)));
                }

                break;
        }
    };
}

export class SimpleObservable<T> {
    private events: SimpleObservableEvent<T>[] = [];

    private subscribers: SimpleObservableSubscriber<T>[] = [];

    /** Subscribe to this observable. Returns unsubscribe function. */
    public subscribe: (
        successCallback?: (value: T) => void,
        noticeCallback?: (notice: any) => void,
        errorCallback?: (error: any) => void
    ) => () => void
    = (successCallback, noticeCallback, errorCallback) => {
        const subscriber: SimpleObservableSubscriber<T> = new SimpleObservableSubscriber<T>(
            successCallback,
            noticeCallback,
            errorCallback
        );

        this.events.forEach(subscriber.handleEvent);

        this.subscribers.push(subscriber);

        return () => this.subscribers = this.subscribers.filter((sub) => sub.uniqueId !== subscriber.uniqueId);
    };

    public error = (error: any) => {
        const event: SimpleObservableErrorEvent = { type: 'error', content: error };

        this.events = [event];

        this.subscribers.forEach((subscriber) => subscriber.handleEvent(event));
    };

    public notice = (notice: any) => {
        const event: SimpleObservableNoticeEvent = { type: 'notice', content: notice };

        this.events = this.events
        .filter((oldEvent) => oldEvent.type === 'notice')
        .concat([event]);

        this.subscribers.forEach((subscriber) => subscriber.handleEvent(event));
    };

    public success = (value: T) => {
        const event: SimpleObservableSuccessEvent<T> = { type: 'success', content: value };

        this.events = [event];

        this.subscribers.forEach((subscriber) => subscriber.handleEvent(event));
    };
}
