import { DateWrapper } from '../date';
import { Validator } from './abstract-validator';

import * as ng from 'angular';

export class ValidateDate extends Validator {
    private forbidFuture: boolean;
    private forbidPast: boolean;
    private allowEmpty: boolean;
    private currentDate: Date;

    constructor(
        private $translate: ng.translate.ITranslateService,
        forbidFuture?: boolean,
        forbidPast?: boolean,
        allowEmpty?: boolean
    ) {
        super();
        this.forbidFuture = forbidFuture || false;
        this.forbidPast = forbidPast || false;
        this.allowEmpty = [undefined, null].indexOf(allowEmpty) < 0 ? allowEmpty : true;
        this.currentDate = new Date();
    }

    public validate = (date: DateWrapper) => {
        if (!this.allowEmpty && !date.dateObj) {
            return [{ text: this.$translate.instant('TR_100320-825e59_TR')}];
        }

        if (this.forbidFuture) {
            const message = [{ text: this.$translate.instant('TR_170119-74240b_TR') }];

            if (date.year > this.currentDate.getUTCFullYear()) {
                return message;
            } else if (date.year < this.currentDate.getUTCFullYear()) {
                return [];
            }

            if (date.month > this.currentDate.getUTCMonth() + 1) {
                return message;
            } else if (date.month < this.currentDate.getUTCMonth() + 1) {
                return [];
            }

            if (date.day > this.currentDate.getUTCDate()) {
                return message;
            }

            return [];
        }

        if (this.forbidPast) {
            const message = [{ text: this.$translate.instant('TR_170119-296050_TR') }];

            if (date.year < this.currentDate.getUTCFullYear()) {
                return message;
            } else if (date.year > this.currentDate.getUTCFullYear()) {
                return [];
            }

            if (date.month < this.currentDate.getUTCMonth() + 1) {
                return message;
            } else if (date.month > this.currentDate.getUTCMonth() + 1) {
                return [];
            }

            if (date.day < this.currentDate.getUTCDate()) {
                return message;
            }

            return [];
        }

        return [];
    };
}
