import * as ng from 'angular';
import { DepositModelService, ValidateIban } from '../../../../../services';

export class MoleculeInputTextIbanController implements ng.IController {
    public static $inject: string[] = ['$translate', 'depositModel'];

    public inputType: string;
    public validationErrorList;
    public value: string;
    public placeholder: string;
    public validationInstructions: any[];
    public customCallbackOnEnter;
    public inputId: string;
    public callbackOnClear: (arg0: any) => any;
    public callbackOnClearParams: any;
    public clearable: boolean;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private depositModel: DepositModelService
    ) {}

    $onInit() {
        this.inputType = 'iban';
        this.value = this.value || '';
        this.validationErrorList = this.validationErrorList || [];

        this.validationInstructions = [{
            validator: new ValidateIban(this.$translate),
            instructions: null
        }];
    }

    public onPaste = (paste) => {
        // implement callback on paste here when needed!
    };
}

export class MoleculeInputTextIbanComponent implements ng.IComponentOptions {
    public bindings = {
        value: '=',
        placeholder: '@',
        validationErrorList: '=',
        inputId: '@?',
        callbackOnClear: '<',
        callbackOnClearParams: '<',
        clearable: '<'
    };
    public controller = MoleculeInputTextIbanController;
    public template = require('./input-text-with-validation-wrapper.html');
}
