import * as ng from 'angular';

export class TemplateExchangeOrganizationNewController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public faqArticleIdList: string[] = [
        'email-exchange-definition',
        'email-exchange-server'
    ];

    public pageHeaderData = {
        backwardLink: 'email.organizations.overview',
        backwardText: this.$translate.instant('TR_110119-854aac_TR'),
        panelHeaderRoute: 'email.organizations.overview',
        panelIcon: 'envelope',
        panelTitle: this.$translate.instant('TR_110119-80437b_TR')
    };

    constructor(private $translate: ng.translate.ITranslateService) {}
}

export class TemplateExchangeOrganizationNewComponent implements ng.IComponentOptions {
    public controller = TemplateExchangeOrganizationNewController;
    public template = require('./exchange-organisation-new-template.html');
}
