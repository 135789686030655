import * as Sentry from '@sentry/browser';
import * as ng from 'angular';
import { OrganismEditFormWebspaceVhostSslController } from '../../../..';
import { InputPlaceholderConst } from '../../../../../configuration';
import {
    CryptoToolsService,
    SslCertValidator,
    ValidateNotEmpty,
    VhostModelService
} from '../../../../../services';
import { SslCertificateModelService } from './../../../../../services/ssl/ssl-certificate-model';
import { SslApi } from './../../../../../types/ssl-api';
import { WebhostingApi } from './../../../../../types/webhosting-api';
export class OrganEditPanelWebspaceVhostSslController {
    public static $inject: string[] = ['$translate', 'cryptoTools', 'vhostModel'];

    public $editFormOrganism: OrganismEditFormWebspaceVhostSslController;
    public panelRight: any;
    public privateKeyValidationInstructions: any;
    public certValidationInstructions: any;
    public disableAddButton: boolean;
    public options: any;
    public inputPlaceholder = InputPlaceholderConst;
    public componentInitialized = false;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private cryptoTools: CryptoToolsService,
        private vhostModel: VhostModelService
    ) {}

    public isSslKeyExisting = async () => {
        try {
            const vhost = await this.vhostModel.findOne(this.$editFormOrganism.vHost.id);
            const sslSettings: WebhostingApi.SSLSettings = vhost.sslSettings;
            if ([undefined, null, ''].indexOf(sslSettings?.certificates) < 0) {
                this.$editFormOrganism.ssl.certificateList = [];
                // wollte ogu so haben betrifft PUI-5486
                const certificatesParsed = sslSettings.certificates
                    .match(/^(-{3,}BEGIN\sCERTIFICATE(?:(?!-{3,}BEGIN\sCERTIFICATE)[\s\S])*?-{3,}END\sCERTIFICATE-{3,})\s*$/gm);
                if (certificatesParsed?.length > 0) {
                    certificatesParsed.forEach(element => {
                        this.$editFormOrganism.ssl.certificateList.push(
                        {
                            additionalInfo:[],
                            modified:false,
                            valid:true,
                            certificate: element
                        });
                    });
                } else {
                    this.$editFormOrganism.ssl.certificateList.push(
                    {
                        additionalInfo:[],
                        modified:false,
                        valid:true,
                        certificate: sslSettings.certificates
                    });
                }
                return true;
            }
        } catch (error) {
            Sentry.captureMessage(
                'Unexpected error that sometimes triggers in Vhost SSL onInit',
                {
                    extra: { name: 'Vhost SSL', code: '1234', reason: JSON.stringify(error) },
                    tags: { key: 'ssl' }
                }
            );
        }
        return false;
    };

    public async $onInit(): Promise<void> {
        this.componentInitialized = false;
        this.options = {
            profiles: [
                {
                    name: this.$translate.instant('WEBHOSTING.VHOST.SSL.PROFILE.OPTIONS.MODERN'),
                    value: 'modern'
                }, {
                    name: this.$translate.instant('WEBHOSTING.VHOST.SSL.PROFILE.OPTIONS.INTERMEDIATE'),
                    value: 'intermediate'
                }
            ],
            sslKeyExists: false,
            sslKeyReplace: [undefined, null, '']
                .indexOf(this.$editFormOrganism.vHost.sslSettings?.managedSslProductCode) < 0
        };

        await this.isSslKeyExisting().then(keyExists => this.options.sslKeyExists = keyExists);

        this.privateKeyValidationInstructions = [{
            instructions: null,
            validator: new ValidateNotEmpty(this.$translate)
        }];

        this.certValidationInstructions = [{
            instructions: null,
            validator: new ValidateNotEmpty(this.$translate)
        }, {
            instructions: null,
            validator: new SslCertValidator(this.$translate, this.cryptoTools)
        }];

        if (this.$editFormOrganism.vHost.sslSettings === null) {
            this.$editFormOrganism.vHost.sslSettings = {
                certAdditionalInfo: '',
                certificates: '',
                hstsActivated: false,
                hstsAllowPreload: false,
                hstsIncludeSubdomains: false,
                hstsMaxAge: null,
                managedSslProductCode: '',
                profile: 'modern',
                sslPrivateKey: ''
            };
            this.$editFormOrganism.addCertificate();
            this.componentInitialized = true;
        } else {
            if ([undefined, null].indexOf(this.$editFormOrganism.vHost.sslSettings.managedSslProductCode) >= 0) {
                this.$editFormOrganism.vHost.sslSettings.managedSslProductCode = '';
            } else if ([undefined, null].indexOf(this.$editFormOrganism.vHost.sslSettings.managedSslProductCode) < 0) {
                this.options.sslKeyReplace = false;
                this.$editFormOrganism.vHost.sslSettings.sslPrivateKey = '';
                this.$editFormOrganism.vHost.sslSettings.managedSslProductCode = '';
                this.$editFormOrganism.vHost.sslSettings.managedSslStatus = '';
            }

            this.$editFormOrganism.vHost.sslSettings.hstsActivated =
                (this.$editFormOrganism.vHost.sslSettings.hstsMaxAge !== null);

            if ([undefined, null, ''].indexOf(this.$editFormOrganism.vHost.sslSettings.certificates) < 0) {
                this.options.sslKeyExists = true;
            }

            this.$editFormOrganism.separateSSLCertificate();
            this.componentInitialized = true;
        }
    }

    public statusHstsradioChanged = (callback: any) => {
        if (!callback.status) {
            this.$editFormOrganism.vHost.sslSettings.hstsMaxAge = null;
        } else {
            this.$editFormOrganism.vHost.sslSettings.hstsMaxAge = 15552000; // 180 days
        }
    };

    public onChangeCertificate = (response: any) => {
        if (response.cert.modified) {
            this.options.sslKeyReplace = true;
        }

        response.cert.modified = true;
    };

    public set replacePrivateKeyActive({}) {} // tslint:disable-line:no-empty
    public get replacePrivateKeyActive() {
        return !this.options.sslKeyExists || this.options.sslKeyReplace;
    }
}

export class OrganEditPanelWebspaceVhostSslComponent implements ng.IComponentOptions {
    public bindings = {
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormWebspaceVhostSsl'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./webspace-vhost-ssl.html');
    public controller = OrganEditPanelWebspaceVhostSslController;
}
