import { Validator } from './abstract-validator';

export class ValidateCaa extends Validator {

    constructor(private $translate: ng.translate.ITranslateService) {
        super();
    }

    public validate = (value: string) => {
        if (/^((0|128) (issue|issuewild|iodef) \".*\")$/.test(value)) {
            return [];
        } else {
            return [{ text: this.$translate.instant('TR_160119-72b655_TR') }];
        }
    };
}
