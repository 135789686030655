import * as ng from 'angular';
import { DepositModelService } from '../../../../services';

export class MoleculePanelInvoicePaymentOptionsController implements ng.IController {
    public static $inject: string[] = ['$timeout', 'depositModel'];

    public invoice;
    public paymentMethod = 'paypal';
    public onChanged: () => {};
    public directDebitHintChecked: boolean;
    public accountPaymentTypePostpaid = false;
    public availablePaymentMethods;
    public paymentMethodAvailable: boolean;

    private _sortedPaymentMethods = [
        'paypal',
        'stripe'
    ];

    constructor(
        private $timeout: ng.ITimeoutService,
        private depositModel: DepositModelService
    ) {}

    $onInit() {
        this.directDebitHintChecked = this.directDebitHintChecked || false;

        this.depositModel.paymentMethodAvailability().then(
            (paymentMethodAvailability) => {
                this.availablePaymentMethods = this.getPaymentMethods(paymentMethodAvailability);
            }
        );
    }

    public isPaymentType = (paymentMethod, method): boolean => {
        return paymentMethod.toLowerCase() === method.toLowerCase();
    };

    public paymentMethodChanged = () => {
        this.$timeout(() => {
            this.directDebitHintChecked = this.paymentMethod !== 'direct-debit';
        });
    };

    public showCCHint = (): boolean => {
        return this.paymentMethodAvailable && this.paymentMethod === 'stripe';
    };

    public showPayPalUserHint = (): boolean => {
        return this.paymentMethodAvailable && this.paymentMethod === 'paypal';
    };

    private getPaymentMethods = (paymentMethodAvailability) => {
        const potentialPaymentMethods = paymentMethodAvailability
            .map((method) => method.paymentMethod);

        const paymentMethods = this._sortedPaymentMethods.filter(
            (method) => {
                return potentialPaymentMethods.some(
                    (accountMethod) => {
                        return accountMethod.toLowerCase() === method.toLowerCase();
                    }
                );
            }
        );

        for (const method of potentialPaymentMethods) {
            if (paymentMethods.indexOf(method.toLowerCase()) < 0) {
                if (method.toLowerCase() !== 'directdebit') {
                    paymentMethods.push(method.toLowerCase());
                }
            }
        }

        return paymentMethods;
    };
}

export class MoleculePanelInvoicePaymentOptionsComponent implements ng.IComponentOptions {
    public bindings = {
        directDebitHintChecked: '=',
        onChanged: '<',
        paymentMethod: '=',
        paymentMethodAvailable: '='
    };
    public controller = MoleculePanelInvoicePaymentOptionsController;
    public template = require('./panel-invoice-payment-options.html');
}
