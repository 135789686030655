import * as ng from 'angular';
import './storage-quota-display.scss';

export class MoleculeFormStorageQuotaDisplayController implements ng.IController {
    public static $inject: string[] = ['formatMegabyteFilter'];

    public total: number;
    public used: number;
    public change: number;
    public modifier = '';
    public hideUsedSpace = false;
    public unit: string;

    constructor(
        private formatMegabyte
    ) {}

    public get unused() {
        return this.change > 0 ? this.total - this.used : this.total - this.used + this.change;
    }

    public get usedBar() {
        return (this.used === 0 && this.unused === 0 && this.increase === 0) ? 1 : this.used;
    }

    public get increase() {
        return this.change > 0 ? this.change : 0;
    }

    public get decrease() {
        return this.change < 0 ? -this.change : 0;
    }

    public get max() {
        return this.total + (this.change > 0 ? this.change : 0);
    }

    public get overQuota() {
        return this.used >= (this.total + this.change);
    }

    public get storageChangeText() {
        if (this.change === 0) {
            return '';
        } else {
            if (this.unitText !== 'MB') {
                return ' (' + (this.change > 0 ? '+' : '') + this.change + ' ' + this.unitText + ')';
            }
            return ' (' + (this.change > 0 ? '+' : '') + this.formatMegabyte(this.change) + ')';
        }
    }
    public get unitText() {
        if (this.unit == null || this.unit === undefined) {
            return '';
        } else {
            return this.unit;
        }
    }
}

export class MoleculeFormStorageQuotaDisplayComponent implements ng.IComponentOptions {
    public bindings = {
        change: '<',
        modifier: '@',
        total: '<',
        used: '<',
        unit: '@',
        hideUsedSpace: '<?'
    };
    public controllerAs = '$storageQuotaDisplay';
    public controller =  MoleculeFormStorageQuotaDisplayController;
    public template = require('./storage-quota-display.html');
}
