import * as ng from 'angular';
import { AlertManagerService } from '@/services/alert-manager';
import { ApiErrorModel } from '@/services/errors';
import { NotFoundError } from '@/services/errors/not-found-error.class';
import * as Types from '@/types';
import { EntityWithPagination } from '@/types';

export class ModelHelper {
    public static returnListResponse<T>(result): EntityWithPagination<T>{
        return {
            data: result.response.data,
            pagination: {
                currentPage: result.response.page,
                entries: result.response.totalEntries,
                limit: result.response.limit
            }
        };
    }

    public static returnFindResponse = (result) => {
        if (Array.isArray(result.response.data)) {
            return result.response.data;
        }

        throw new NotFoundError();
    };

    public static returnFindSingleData = (result) => {
        if (Array.isArray(result.data)) {
            return result.data[0];
        }

        throw new NotFoundError();
    };

    public static returnFindOneResponse = <T>(result: Types.UI.SynchronousAPIResponse<{ data: T[] }>): T => {
        if (Array.isArray(result?.response?.data) && result.response.data.length >= 1) {
            return result.response.data[0];
        }

        throw new NotFoundError();
    };

    public static returnFindOneObjectResponse = (result) => {
        if (typeof result.response.data === 'object' && result.response.data !== null) {
            return result.response.data;
        }

        throw new NotFoundError();
    };

    public static returnOneResponse = (result) => {
        if (typeof result.response === 'object' && result.response !== null) {
            return result.response;
        }

        throw new NotFoundError();
    };

    public static sequentiallyRequestApi = (
        apiObject: any,
        sanityCheckIndexField: string,
        model: any,
        callMethodName: string,
        requestAreStillBeingHandledTranslationId?,
        timeout?: ng.ITimeoutService,
        alertManager?: AlertManagerService,
        callbackStatusUpdate?: (
            doneElements: number,
            failedElements: number,
            queuedElements: number,
            failedElementList: any[]
        ) => void,
        apiErrorModel?: ApiErrorModel
    ) => {
        let doneElements = 0;
        let failedElements = 0;
        let queuedElements = apiObject.length;
        let lastObject: any;
        let userNotification: any;
        const failedElementList = [];

        if (!callbackStatusUpdate) {
            userNotification = timeout(
                () => {
                    alertManager.info(requestAreStillBeingHandledTranslationId);
                }
                , 1500
            );
        } else {
            callbackStatusUpdate(doneElements, failedElements, queuedElements, failedElementList);
        }

        return apiObject.concat({}).reduce( // concat {} so that the result of the last API-Object gets processed
            (previousPromise, nextObject) => {
                return previousPromise.then(
                    (apiResponse) => {
                        if (apiResponse !== true) {
                            queuedElements --;

                            if (apiErrorModel && apiErrorModel.apiResponseHasError(apiResponse)) {
                                failedElements++;
                                lastObject.serverTransactionId = apiResponse.metadata?.serverTransactionId;
                                failedElementList.push(lastObject);
                            } else {
                                doneElements ++;
                            }
                        }

                        if (callbackStatusUpdate) {
                            callbackStatusUpdate(doneElements, failedElements, queuedElements, failedElementList);
                        } else if (queuedElements === 0) {
                            timeout.cancel(userNotification);
                        }

                        if (nextObject[sanityCheckIndexField] !== undefined) {
                            lastObject = nextObject;

                            return model[callMethodName](nextObject);
                        } else {
                            // last loop: nothing to do just result checking.
                            return Promise.resolve(true);
                        }
                    },
                    (errResponse) => {
                        queuedElements --;
                        failedElements ++;

                        lastObject.serverTransactionId = errResponse.serverTransactionId;
                        failedElementList.push(lastObject);

                        if (callbackStatusUpdate) {
                            callbackStatusUpdate(doneElements, failedElements, queuedElements, failedElementList);
                        } else if (queuedElements === 0) {
                            timeout.cancel(userNotification);
                        }

                        lastObject = nextObject;

                        if (nextObject[sanityCheckIndexField] !== undefined) {
                            return model[callMethodName](nextObject);
                        } else {
                            // last loop: nothing to do just result checking.
                            return Promise.resolve(true);
                        }
                    }
                );
            },
            Promise.resolve(true)
        );
    };

    public static returnTotalEntries = (result) => result.response.totalEntries;
}
