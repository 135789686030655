import { AuthContextService, DomainTransferOverviewModel } from '@/services';

export class OrganDashboardWidgetOverviewDomainTransfersOutController implements ng.IController {
    public static $inject: string[] = ['$translate', 'domainTransferOverviewModel'];

    public runningTransfers: unknown[] = [];
    public demoData: unknown[] = [];
    public panelTitle: string;

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private domainTransferOverviewModel: DomainTransferOverviewModel
    ) {
        this.panelTitle = this.$translate.instant('TR_020221-3e003e_TR');
    }

    public $onInit(): void {
        const filter = {
            subFilter: [
                {
                    field: 'jobType',
                    value: 'domainTransferOut'
                },
                {
                    field: 'jobState',
                    value: 'successful',
                    relation: 'unequal'
                },
                {
                    field: 'jobState',
                    value: 'failed',
                    relation: 'unequal'
                },
                {
                    field: 'jobState',
                    value: 'canceled',
                    relation: 'unequal'
                }
            ],
            subFilterConnective: 'AND'
        };

        const sort = {
            field: 'JobLastChangeDate',
            order: 'DESC'
        };

        void this.domainTransferOverviewModel.listTransfers(2, 1, filter, sort, true)
            .then((transferRes) => {
                if ((transferRes.data as unknown[]).length > 0) {
                    this.runningTransfers = transferRes.data as unknown[];
                } else if (this.demoData) {
                    this.runningTransfers = this.demoData.map((transfer) => {
                        transfer.accountId = AuthContextService.account.id;
                        return transfer;
                    });
                    return;
                }
            });
    }
}

export class OrganDashboardWidgetOverviewDomainTransfersOutComponent implements ng.IComponentOptions {
    public bindings = {
        demoData: '<'
    };
    public template = require('./dashboard-widget-overview-domain-transfers-out.html');
    public controller = OrganDashboardWidgetOverviewDomainTransfersOutController;
}
