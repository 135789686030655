import { UserSettingsService } from './account/user-settings';
import { NotFoundError } from './errors/not-found-error.class';

export const showAccounts = ['userSettings', (userSettings: UserSettingsService) => {
    return userSettings.showAccounts();
}];

export function check404(message: string, service = ''): <T>(item: T) => T {
    return (item) => {
        switch (service) {
            case 'ddns-host':
            case 'ddns-user':
                if (item === undefined || (item as any).id === undefined) {
                    throw new NotFoundError(message);
                }
                break;

            case 'storage':
                if (item === undefined
                    || (item as any).response === undefined
                    || (item as any).response.data === undefined
                    || (
                        (Array.isArray((item as any).response.data) && (item as any).response.data.length === 0)
                    )
                ) {
                    throw new NotFoundError(message);
                }
                break;

            default:
                if (!item || (Array.isArray(item) && item.length === 0)) {
                    throw new NotFoundError(message);
                }
                break;
        }

        return item;
    };
}
