import * as ng from 'angular';

export class MoleculePanelAppInstallController implements ng.IController {
    public static $inject: string[] = [];
    public title: string;
    public options: any;
    public serviceObject: any;
    public language: string;
    public bundleId: string;
    public $editForm;

    public get panelCompleted() {
        return this.options.every((option) =>
        {
            if (this.serviceObject[option.key] === undefined) {
                return false;
            }

            return true;
        });
    }

    /* tslint:disable-next-line:no-empty */
    public set panelCompleted({}) {}
}

export class MoleculePanelAppInstallComponent implements ng.IComponentOptions {
    public bindings = {
        title: '@',
        options: '<',
        serviceObject: '=',
        language: '<',
        panelCompleted: '=?',
        bundleId: '<'
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public controller =  MoleculePanelAppInstallController;
    public template = require('./panel-app-install.html');
}
