import * as ng from 'angular';

import { UIRegExp } from '@/configuration';

import { AccountModelService } from '@/services/account/account-model';
import { AuthContextService } from '@/services/auth-context';
import { AccountApi } from '@/types';

export class AccountName {
    public static $inject: string[] = ['$rootScope', '$sanitize', '$translate', 'accountModel'];

    /** Cached accounts */
    private static cache = {};

    /** Pseudo-account object for parent account */
    private static parentAccount: { name: string };

    /** Current account */
    private static account: AccountApi.Account;

    public static Factory(
        $rootScope: ng.IRootScopeService|any,
        $sanitize: ng.sanitize.ISanitizeService,
        $translate: ng.translate.ITranslateService,
        accountModel: AccountModelService
    ) {
        /* Preparation */

        AccountName.parentAccount = { name: $translate.instant('ACCOUNT.GENERAL.PARENT-ACCOUNT') };
        AccountName.account = AuthContextService.account;
        AccountName.cache[AccountName.account.id] = AccountName.account;
        AccountName.cache[AccountName.account.parentAccountId] = AccountName.parentAccount;

        $rootScope.$on('login', ({}, {}, account) => {
            AccountName.cache[account.id] = account;
            AccountName.cache[account.parentAccountId] = AccountName.parentAccount;
        });

        $rootScope.$on('logout', ({}, {}, account) => {
            AccountName.account = undefined;
            AccountName.cache[account.id] = undefined;
            AccountName.cache[account.parentAccountId] = undefined;
        });

        /* End preparation */

        const accountNameFilter: any = (value: string) => {
            if ([undefined, null, ''].indexOf(value) >= 0) {
                return undefined;
            }

            if (AccountName.cache[value] !== undefined) {
                if (AccountName.cache[value] !== 'waiting') {
                    if (value === AuthContextService.account.parentAccountId) {
                        return $sanitize(`<fa-icon
                            class="space-right"
                            icon="level-up-alt"
                            title="${$translate.instant('ACCOUNT.GENERAL.PARENT-ACCOUNT')}"
                        ></fa-icon> ${AccountName.cache[value].name}`);
                    }

                    if (AccountName.isDirectCustomer(value)) {
                        return $sanitize(AccountName.cache[value].name);
                    }

                    return $sanitize(`<fa-icon
                            class="space-right"
                            icon="level-down-alt"
                            title="${$translate.instant('TR_140119-5789c6_TR')}"
                        ></fa-icon> ${AccountName.cache[value].name}`);
                }

                return undefined;
            }

            AccountName.cache[value] = 'waiting';

            accountModel.findOne(value)
                .then(AccountName.addResultToCache,
                () => {
                    // Wenn der Request fehlschägt, versuchen wir es nicht erneut
                    AccountName.cache[value] = {
                        name: value
                    };
                });

            return undefined;
        };

        accountNameFilter.$stateful = true;

        return accountNameFilter;
    }

    private static isDirectCustomer(value: string | number) {
        if (AccountName.account.id === '1') {
            // Wenn wir keine Daten aus der Datenbank kriegen, zeigen wir kein Icon an
            if (!AccountName.cache[value].parentAccountId) {
                return true;
            }

            return UIRegExp.IsNumeric.test('' + AccountName.cache[value].parentAccountId)
                && parseInt(AccountName.cache[value].parentAccountId, 10) < 1000;
        } else {
            return AccountName.cache[value].parentAccountId === AccountName.account.id
                || AccountName.cache[value].id === AccountName.account.id;
        }
    }

    private static addResultToCache(result: { id?: string | number }) {
        if (result !== undefined) {
            AccountName.cache[result.id] = result;
        }
    }
}
