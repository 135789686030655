import * as ng from 'angular';
import { UiRights } from '../../../../configuration';
import { AuthContextService, MailboxModelService } from '../../../../services';
import { LinkListItem } from '../../links';

export class MoleculePanelDomainEmailListController implements ng.IController {
    public static $inject: string[] = [
        '$state',
        '$translate',
        'mailboxModel',
        'objectTypeFilter'
    ];

    public emailList: LinkListItem[] = [];
    public showAllLink = {
        route: undefined,
        routeParams: undefined,
        text: undefined
    };

    private domainNameUnicode: string;
    private stateInBundle: boolean;
    private stateInMachine: boolean;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private mailboxModel: MailboxModelService,
        private objectTypeFilter
    ) {}

    public $onInit() {
        if (!AuthContextService.isGranted(UiRights.EMAIL_MAILBOX_EDIT)) {
            return;
        }

        this.stateInBundle = this.$state.current.name.split('.').indexOf('bundle') >= 0;
        this.stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';

        let additionalFilter;
        if (this.stateInMachine) {
            additionalFilter = { field: 'poolId', value: this.$state.$current.locals.globals.machine.resourcePoolId };
        }

        this.mailboxModel.findActiveMailAdressesByDomain(this.domainNameUnicode, 9, additionalFilter).then(
            (result) => {
                this.emailList = result
                    .slice(0, 8)
                    .map(
                        (mailbox) => {
                            const route = this.getEditRoute(mailbox.id);
                            return {
                                route: route.route,
                                routeParams: route.routeParams,
                                text: mailbox.emailAddressUnicode + ' (' + this.objectTypeFilter(mailbox.type) + ')'
                            };
                        }
                    );

                if (result.length > 8) {
                    const route = this.getOverviewRoute();
                    this.showAllLink = {
                        route: route.route,
                        routeParams: route.routeParams,
                        text: this.$translate.instant('TR_220819-f325de_TR')
                    };
                }
            }
        );
    }

    public getOverviewRoute = () => {
        let route;
        let routeParams;

        switch (true) {
            case this.stateInBundle: // currently disabled (panel is hidden here)
                route = 'bundle.id.mailboxes.overview';
                routeParams = {
                    bundleId: this.$state.$current.locals.globals.bundle.id
                };
                break;

            case this.stateInMachine:
                route = 'managed-servers.id.mailboxes.overview';
                routeParams = {
                    machineId: this.$state.$current.locals.globals.machine.id
                };
                break;

            default:
                route = 'email.mailboxes.overview';
                routeParams = {};
        }

        return {
            route: route,
            routeParams: routeParams
        };
    };

    public getEditRoute = (objectId: string) => {
        let route;
        let routeParams;

        switch (true) {
            case this.stateInBundle: // currently disabled (panel is hidden here)
                route = 'bundle.id.mailboxes.id.edit';
                routeParams = {
                    bundleId: this.$state.$current.locals.globals.bundle.id,
                    mailboxId: objectId
                };
                break;

            case this.stateInMachine:
                route = 'managed-servers.id.mailboxes.id.edit';
                routeParams = {
                    machineId: this.$state.$current.locals.globals.machine.id,
                    mailboxId: objectId
                };

                break;

            default:
                route = 'email.mailboxes.id.edit';
                routeParams = {
                    mailboxId: objectId
                };
        }

        return {
            route: route,
            routeParams: routeParams
        };
    };
}

export class MoleculePanelDomainEmailListComponent implements ng.IComponentOptions {
    public controller =  MoleculePanelDomainEmailListController;
    public bindings = {
        domainNameUnicode: '<domain'
    };
    public template = require('./panel-domain-email-list.html');
}
