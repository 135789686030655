import * as ng from 'angular';
import { OrganismOverviewController, OverviewDataObject } from '../../../../../organisms';

export class MoleculeOverviewRowPanelRedirectionController implements ng.IController {
    public static $inject: string[] = [];

    public overviewData: OverviewDataObject;
    public OrganismOverview: OrganismOverviewController;
    public overviewLoad: (selection: any) => any;
    public internalPaginationLimit: number;
}

export class MoleculeOverviewRowPanelRedirectionComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelRedirectionController;
    public template = require('./redirection-row.html');
}
