import * as ng from 'angular';
import { UiRights } from '../../configuration';
import {
    DocumentModelService,
    FilterCacheService,
    InvoiceOverviewModel,
    NavigationService,
    PaymentOverviewModel,
    UserSettingsManagerService
} from '../../services';

export const BILLING_ROUTES = [
    {
        name: 'billing',
        state: {
            abstract: true,
            parent: 'authenticated',
            url: '/billing'
        }
    },
    {
        name: 'billing.balance',
        state: {
            onEnter: (navigation: NavigationService) => { navigation.go('billing.payment-options'); },
            url: '/balance'
        }
    },
    {
        name: 'billing.fund-deposit',
        state: {
            onEnter: (navigation: NavigationService) => { navigation.go('billing.payment-options'); },
            url: '/fund-deposit'
        }
    },
    {
        name: 'billing.dashboard',
        state: {
            data: {
                isGrantedAny: [
                    UiRights.BIL_DEPOSIT_FUND,
                    UiRights.BIL_LIST_BOOKINGS,
                    UiRights.BIL_LIST_DOCUMENTS,
                    UiRights.BIL_LIST_PAYMENTS,
                    UiRights.BIL_LIST_PRICES_DATABASE,
                    UiRights.BIL_LIST_PRICES_DNS,
                    UiRights.BIL_LIST_PRICES_DOMAIN,
                    UiRights.BIL_LIST_PRICES_EMAIL,
                    UiRights.BIL_LIST_PRICES_MACHINE,
                    UiRights.BIL_LIST_PRICES_SSL,
                    UiRights.BIL_LIST_PRICES_WEBHOSTING
                ]
            },
            url: '/',
            views: {
                'content@authenticated': {
                    template: '<template-billing-dashboard></template-billing-dashboard>'
                }
            }
        }
    },
    {
        name: 'billing.payment-options', // Bei Änderungen auch `fund-deposit` und `change-billing-model` anpassen!
        state: {
            data: {
                isGrantedAll: [UiRights.BIL_GET_DEPOSIT, UiRights.BIL_DEPOSIT_FUND]
            },
            url: '/options',
            views: {
                'content@authenticated': {
                    template: '<template-billing-payment-options></template-billing-payment-options>'
                }
            }
        }
    },
    {
        name: 'billing.payment-options.commit',
        state: {
            abstract: true,
            url: '/commit'
        }
    },
    {
        name: 'billing.payment-options.commit.paypal',
        state: {
            resolve: {
                payerId: ($stateParams: ng.ui.IStateParamsService) => {
                    return $stateParams.PayerID;
                },
                token: ($stateParams: ng.ui.IStateParamsService) => {
                    return $stateParams.token;
                }
            },
            url: '/paypal?{token}&{PayerID}',
            views: {
                'content@authenticated': {
                    template: `<template-billing-payment-options
                                    token="$resolve.token"
                                    payer-id="$resolve.payerId"
                                    action="commit"
                                    payment-service-type="paypal"
                                ></template-billing-payment-options>`
                }
            }
        }
    },
    {
        name: 'billing.payment-options.commit.stripe',
        state: {
            resolve: {
                paymentId: ($stateParams: ng.ui.IStateParamsService) => {
                    return $stateParams.billingApiPaymentId;
                }
            },
            url: '/stripe?{billingApiPaymentId}',
            views: {
                'content@authenticated': {
                    template: `<template-billing-payment-options
                                    payment-id="$resolve.paymentId"
                                    action="commit"
                                    payment-service-type="stripe"
                                ></template-billing-payment-options>`
                }
            }
        }
    },
    {
        name: 'billing.payment-options.cancel',
        state: {
            abstract: true,
            url: '/cancel'
        }
    },
    {
        name: 'billing.payment-options.cancel.paypal',
        state: {
            resolve: {
                token: ($stateParams: ng.ui.IStateParamsService) => {
                    return $stateParams.token;
                }
            },
            url: '/paypal?{token}',
            views: {
                'content@authenticated': {
                    template: `<template-billing-payment-options
                                    token="$resolve.token"
                                    action="cancel"
                                    payment-service-type="paypal"
                                ></template-billing-payment-options>`
                }
            }
        }
    },
    {
        name: 'billing.payment-options.cancel.stripe',
        state: {
            resolve: {
                paymentId: ($stateParams: ng.ui.IStateParamsService) => {
                    return $stateParams.billingApiPaymentId;
                }
            },
            url: '/stripe?{billingApiPaymentId}',
            views: {
                'content@authenticated': {
                    template: `<template-billing-payment-options
                                    payment-id="$resolve.paymentId"
                                    action="cancel"
                                    payment-service-type="stripe"
                                ></template-billing-payment-options>`
                }
            }
        }
    },
    {
        name: 'billing.payments-overview',
        state: {
            data: {
                isGranted: UiRights.BIL_LIST_PAYMENTS
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('billing.payment');
                },
                overviewModel: (paymentOverviewModel: PaymentOverviewModel) => paymentOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('PaymentOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('billing.payment');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-payment-overview
                                    simple-filter="$resolve.simpleFilter"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                ></template-payment-overview>`
                }
            }
        }
    },
    {
        name: 'billing.invoices',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.BIL_LIST_DOCUMENTS
            },
            url: '/invoices'
        }
    },
    {
        name: 'billing.invoices.overview',
        state: {
            resolve: {
                overviewModel: (invoiceOverviewModel: InvoiceOverviewModel) => invoiceOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('AccountInvoiceOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('account.subAccounts');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-invoice-overview
                                    simple-filter="$resolve.simpleFilter"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                ></template-invoice-overview>`
                }
            }
        }
    },
    {
        name: 'billing.invoices.id',
        state: {
            abstract: true,
            params: {
                invoiceId: ''
            },
            resolve: {
                invoice: (documentModel: DocumentModelService, $stateParams: ng.ui.IStateParamsService) => {
                    // To-DO: Change filter to get invoice with id - after filter works
                    // {field: 'documentId', value: $stateParams.invoiceId};
                    const filters = {
                        subFilter: [
                            { field: 'documentNumber', value: $stateParams.invoiceId },
                            {
                                subFilter: [
                                    {
                                        field: 'type',
                                        value: 'invoice'
                                    },
                                    {
                                        field: 'type',
                                        value: 'creditnote'
                                    },
                                    {
                                        field: 'type',
                                        value: 'returndebitnote'
                                    }
                                ],
                                subFilterConnective: 'OR'
                            }
                        ],
                        subFilterConnective: 'AND'
                    };

                    return documentModel.listDocuments(1, 1, filters)
                        .then(
                            (res) => {
                                return res.data.length > 0 ? res.data[0] : null;
                            }
                        );
                },
                openInvoices: (
                    documentModel: DocumentModelService,
                    $stateParams: ng.ui.IStateParamsService,
                    invoice: { documentId: any; }
                ) => {
                    const filters = {
                        subFilter: [
                            [undefined, null].indexOf(invoice) < 0
                                ? { field: 'documentId', value: invoice.documentId, relation: 'unequal' }
                                : { field: 'documentNumber', value: $stateParams.invoiceId, relation: 'unequal' },
                            { field: 'outstanding', value: '0', relation: 'unequal' },
                            {
                                subFilter: [
                                    {
                                        field: 'type',
                                        value: 'invoice'
                                    },
                                    {
                                        field: 'type',
                                        value: 'creditnote'
                                    },
                                    {
                                        field: 'type',
                                        value: 'returndebitnote'
                                    }
                                ],
                                subFilterConnective: 'OR'
                            }
                        ],
                        subFilterConnective: 'AND'
                    };

                    return documentModel.listDocuments(null, 1, filters)
                        .then(
                            (docs) => [invoice].concat(docs.data.filter(
                                (invoiceData: { paymentReceived: any; documentId: any; }) => !invoiceData.paymentReceived
                                    && invoiceData.documentId !== invoice.documentId
                            ))
                        );
                }
            },
            url: '/id/{invoiceId}'
        }
    },
    {
        name: 'billing.invoices.id.edit',
        state: {
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-invoice-edit
                                    invoice="$resolve.invoice"
                                    open-invoices="$resolve.openInvoices"
                                ></template-invoice-edit>`
                }
            }
        }
    },
    {
        name: 'billing.pricelists',
        state: {
            data: {
                isGrantedAny: [
                    UiRights.BIL_LIST_PRICES_DATABASE,
                    UiRights.BIL_LIST_PRICES_DNS,
                    UiRights.BIL_LIST_PRICES_DOMAIN,
                    UiRights.BIL_LIST_PRICES_EMAIL,
                    UiRights.BIL_LIST_PRICES_MACHINE,
                    UiRights.BIL_LIST_PRICES_SSL,
                    UiRights.BIL_LIST_PRICES_WEBHOSTING
                ]
            },
            url: '/pricelists',
            views: {
                'content@authenticated': {
                    template: '<template-billing-pricelists></temlate-billing-pricelists>'
                }
            }
        }
    },
    {
        name: 'billing.invoices.commit',
        state: {
            abstract: true,
            url: '/commit'
        }
    },
    {
        name: 'billing.invoices.commit.paypal',
        state: {
            resolve: {
                payerId: ($stateParams: ng.ui.IStateParamsService) => {
                    return $stateParams.PayerID;
                },
                token: ($stateParams: ng.ui.IStateParamsService) => {
                    return $stateParams.token;
                }
            },
            url: '/paypal?{token}&{PayerID}',
            views: {
                'content@authenticated': {
                    template: `<template-invoice-payment-commit
                                    token="$resolve.token"
                                    payer-id="$resolve.payerId"
                                    payment-service-type="paypal"
                                ></template-invoice-payment-commit>`
                }
            }
        }
    },
    {
        name: 'billing.invoices.commit.stripe',
        state: {
            resolve: {
                token: ($stateParams: ng.ui.IStateParamsService) => {
                    return $stateParams.billingApiPaymentId;
                }
            },
            url: '/stripe?{billingApiPaymentId}',
            views: {
                'content@authenticated': {
                    template: `<template-invoice-payment-commit
                                    token="$resolve.token"
                                    payer-id="$resolve.payerId"
                                    payment-service-type="stripe"
                                ></template-invoice-payment-commit>`
                }
            }
        }
    },
    {
        name: 'billing.invoices.cancel',
        state: {
            abstract: true,
            url: '/cancel'
        }
    },
    {
        name: 'billing.invoices.cancel.paypal',
        state: {
            resolve: {
                token: ($stateParams: ng.ui.IStateParamsService) => {
                    return $stateParams.token;
                }
            },
            url: '/paypal?{token}',
            views: {
                'content@authenticated': {
                    template: `<template-invoice-payment-cancel
                                    token="$resolve.token"
                                ></template-invoice-payment-cancel>`
                }
            }
        }
    },
    {
        name: 'billing.invoices.cancel.stripe',
        state: {
            resolve: {
                token: ($stateParams: ng.ui.IStateParamsService) => {
                    return $stateParams.billingApiPaymentId;
                }
            },
            url: '/stripe?{billingApiPaymentId}',
            views: {
                'content@authenticated': {
                    template: `<template-invoice-payment-cancel
                                    token="$resolve.token"
                                ></template-invoice-payment-cancel>`
                }
            }
        }
    },
    {
        name: 'billing.pricelists.dns',
        state: {
            data: {
                isGranted: UiRights.BIL_LIST_PRICES_DNS
            },
            url: '/dns',
            views: {
                'content@authenticated': {
                    template: `<template-billing-pricelist-dns-overview
                    ></temlate-billing-pricelist-dns-overview>`
                }
            }
        }
    },
    {
        name: 'billing.pricelists.domains',
        state: {
            data: {
                isGranted: UiRights.BIL_LIST_PRICES_DOMAIN
            },
            url: '/domains',
            views: {
                'content@authenticated': {
                    template: `<template-billing-pricelist-domains-overview
                    ></temlate-billing-pricelist-domains-overview>`
                }
            }
        }
    },
    {
        name: 'billing.pricelists.machines',
        state: {
            data: {
                isGranted: UiRights.BIL_LIST_PRICES_MACHINE
            },
            url: '/cloud-servers',
            views: {
                'content@authenticated': {
                    template: `<template-billing-pricelist-machines-overview
                    ></temlate-billing-pricelist-machines-overview>`
                }
            }
        }
    },
    {
        name: 'billing.pricelists.ssl',
        state: {
            data: {
                isGranted: UiRights.BIL_LIST_PRICES_SSL
            },
            url: '/ssl',
            views: {
                'content@authenticated': {
                    template: `<template-billing-pricelist-ssl-overview
                    ></temlate-billing-pricelist-ssl-overview>`
                }
            }
        }
    },
    {
        name: 'billing.pricelists.mailboxes',
        state: {
            data: {
                isGranted: UiRights.BIL_LIST_PRICES_EMAIL
            },
            url: '/emails',
            views: {
                'content@authenticated': {
                    template: `<template-billing-pricelist-mailboxes-overview
                    ></temlate-billing-pricelist-mailboxes-overview>`
                }
            }
        }
    },
    {
        name: 'billing.pricelists.webhosting',
        state: {
            data: {
                isGranted: UiRights.BIL_LIST_PRICES_WEBHOSTING
            },
            url: '/webhosting',
            views: {
                'content@authenticated': {
                    template: `<template-billing-pricelist-webhosting-overview
                    ></temlate-billing-pricelist-webhosting-overview>`
                }
            }
        }
    },
    {
        name: 'billing.pricelists.databases',
        state: {
            data: {
                isGranted: UiRights.BIL_LIST_PRICES_DATABASE
            },
            url: '/databases',
            views: {
                'content@authenticated': {
                    template: `<template-billing-pricelist-databases-overview
                    ></temlate-billing-pricelist-databases-overview>`
                }
            }
        }
    },
    {
        name: 'billing.pricelists.storage-product',
        state: {
            data: {
                isGranted: UiRights.BIL_LIST_PRICES_MANAGEDAPPLICATION
            },
            url: '/storage-product',
            views: {
                'content@authenticated': {
                    template: `<template-billing-pricelist-storage-product-overview
                    ></temlate-billing-pricelist-storage-product-overview>`
                }
            }
        }
    },
    {
        name: 'billing.pricelists.promotions',
        state: {
            data: {
                isGranted: UiRights.BIL_LIST_PRICES_DATABASE
            },
            url: '/promotions',
            views: {
                'content@authenticated': {
                    template: `<template-billing-pricelist-promotions-overview
                    ></temlate-billing-pricelist-promotions-overview>`
                }
            }
        }
    },
    {
        name: 'billing.pricelists.changes',
        state: {
            data: {
                isGrantedAny: [
                    UiRights.BIL_LIST_PRICES_DATABASE,
                    UiRights.BIL_LIST_PRICES_DNS,
                    UiRights.BIL_LIST_PRICES_DOMAIN,
                    UiRights.BIL_LIST_PRICES_EMAIL,
                    UiRights.BIL_LIST_PRICES_MACHINE,
                    UiRights.BIL_LIST_PRICES_SSL,
                    UiRights.BIL_LIST_PRICES_WEBHOSTING
                ]
            },
            url: '/changes',
            views: {
                'content@authenticated': {
                    template: `<template-billing-pricelist-changes-overview
                    ></temlate-billing-pricelist-changes-overview>`
                }
            }
        }
    }
];
