export class ApiValue {
    public static Factory() {
        return ApiValue.parseApiValue;
    }

    public static parseApiValue(value: number) {
        if ([undefined, null].indexOf(value) >= 0 || typeof value !== 'number') {
            return value;
        } else {
            return ApiValue.roundDigits(value / 10000.0, 4, 2);
        }
    }

    private static roundDigits(value: number, startDigits: number, endDigits: number) {
        if (startDigits >= endDigits) {
            return ApiValue.roundDigits(
                parseFloat(value.toFixed(startDigits)),
                --startDigits,
                endDigits
            );
        }

        return value;
    }
}
