import * as ng from 'angular';

export class OrganismEditFormMachineUpgradeController {
    public static $inject: string[] = [];

    public machine: any;
}

export class OrganismEditFormMachineUpgradeComponent implements ng.IComponentOptions {
    public bindings = {
        machine: '<'
    };
    public controller = OrganismEditFormMachineUpgradeController;
    public template = require('./machine-upgrade.html');
}
