import * as ng from 'angular';
import { UiRights } from '@/configuration';
import {
    EmailJobModelService,
    EmailJobOverviewModel,
    FilterCacheService,
    MailboxModelService,
    MailboxOverviewModel,
    NavigationService,
    OrganizationModelService,
    OrganizationOverviewModel,
    ProductHelperService,
    ProductsModelService,
    RedirectOnNotFoundCallback,
    RestrictionHelperService,
    UserSettingsManagerService
} from '@/services';
import * as stateUtilityFunctions from '@/services/state-utility-functions';
import * as Types from '@/types';

export const MAILBOX_ROUTES = [
    {
        name: 'email',
        state: {
            abstract: true,
            parent: 'authenticated',
            url: '/email'
        }
    },
    {
        name: 'email.dashboard',
        state: {
            data: {
                isGrantedAny: [
                    UiRights.EMAIL_MAILBOX_LIST,
                    UiRights.EMAIL_ORGANIZATION_LIST,
                    UiRights.EMAIL_JOBS_LIST
                ]
            },
            onEnter: (navigation: NavigationService, userSettingsManager: UserSettingsManagerService) => {
                userSettingsManager.getUiSettings().then(
                    (result) => {
                        if (result?.overviewSettings?.EmailOverviewCtrl?.expertView) {
                            navigation.go('email.mailboxes.overview');
                        }
                    }
                );
            },
            url: '/',
            views: {
                'content@authenticated': {
                    template: '<template-mailbox-dashboard></template-mailbox-dashboard>'
                }
            }
        }
    },
    {
        name: 'email.mailboxes',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.EMAIL_MAILBOX_LIST
            },
            resolve: {
                comeFrom: () => 'products'
            },
            url: '/mailboxes'
        }
    },
    {
        name: 'email.mailboxes.id',
        state: {
            abstract: true,
            resolve: {
                mailbox: (
                    $stateParams: ng.ui.IStateParamsService,
                    mailboxModel: MailboxModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return mailboxModel.findOne($stateParams.mailboxId)
                        .then(stateUtilityFunctions.check404('Mailbox not found'))
                        .then(null, redirectOnNotFound);
                }
            },
            url: '/id/{mailboxId}'
        }
    },
    {
        name: 'email.mailboxes.id.edit',
        state: {
            data: {
                isGrantedAny: [UiRights.EMAIL_MAILBOX_EDIT, UiRights.EMAIL_CATCHALL_EDIT]
            },
            resolve: {
                maxMailingListMembers: (mailbox: Types.EmailApi.Mailbox, productsModel: ProductsModelService) => {
                    return productsModel.findOne(mailbox.productCode).then(
                        (reply) => {
                            return ProductHelperService.wrapSpecificationItems(
                                reply?.response?.specificationItems
                            ).members?.intValue || 0;
                        }
                    );
                },
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-mailbox-edit
                                    show-object-id="$resolve.showObjectId"
                                    mailbox="$resolve.mailbox"
                                    max-mailing-list-members="$resolve.maxMailingListMembers"
                                    come-from="$resolve.comeFrom"
                                ></template-mailbox-edit>`
                }
            }
        }
    },
    {
        name: 'email.mailboxes.id.domainsettings',
        state: {
            data: {
                isGrantedAll: [UiRights.ACC_SUBACCOUNT_LIST, UiRights.EMAIL_DOMAINSETTINGS_LIST]
            },
            resolve: {
                domainSettings: (mailbox, mailboxModel: MailboxModelService) => {
                    return mailboxModel.findDomainsettingsByDomainName(mailbox.domainName)
                        .then(stateUtilityFunctions.check404('Domain settings not found'));
                }
            },
            url: '/domainsettings',
            views: {
                'content@authenticated': {
                    template: `<template-mailbox-domainsettings
                                    mailbox="$resolve.mailbox"
                                    domain-settings="$resolve.domainSettings"
                                    come-from="$resolve.comeFrom"
                                ></template-mailbox-domainsettings>`
                }
            }
        }
    },
    {
        name: 'email.mailboxes.id.restrictions',
        state: {
            data: {
                isGrantedAny: [UiRights.EMAIL_MAILBOX_EDIT, UiRights.EMAIL_CATCHALL_EDIT]
            },
            resolve: {
                restrictionHelper: (
                    mailbox,
                    restrictionHelper: RestrictionHelperService,
                    mailboxModel: MailboxModelService
                ) => {
                    restrictionHelper.init(mailbox, 'email', mailboxModel, 'mailboxModifyRestrictions');
                    return restrictionHelper;
                },
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/restrictions',
            views: {
                'content@authenticated': {
                    template: `<template-restrictions-edit
                        api-object="$resolve.mailbox"
                        restriction-helper="$resolve.restrictionHelper"
                        show-object-id="$resolve.showObjectId"
                    ></template-restrictions-edit>`
                }
            }
        }
    },
    {
        name: 'email.mailboxes.overview',
        state: {
            data: {
                isGranted: UiRights.EMAIL_MAILBOX_LIST
            },
            params: {
                fancyFilters: { value: null, squash: true }
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('Mailboxes');
                },
                overviewModel: (mailboxOverviewModel: MailboxOverviewModel) => mailboxOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('EmailOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('Mailboxes');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-email-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                ></template-email-overview>`
                }
            }
        }
    },
    {
        name: 'email.mailboxes.new',
        state: {
            data: {
                isGrantedAny: [
                    UiRights.EMAIL_MAILINGLIST_CREATE,
                    UiRights.EMAIL_IMAPMAILBOX_CREATE,
                    UiRights.EMAIL_FORWARDER_CREATE,
                    UiRights.EMAIL_EXCHANGE_CREATE,
                    UiRights.EMAIL_CATCHALL_CREATE
                ]
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-email-wizard></template-email-wizard>'
                }
            }
        }
    },
    {
        name: 'email.mailboxes.jobs',
        state: {
            data: {
                isGranted: UiRights.EMAIL_JOBS_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('EmailJobs');
                },
                overviewModel: (emailJobOverviewModel: EmailJobOverviewModel) => emailJobOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('EmailOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('EmailJobs');
                }
            },
            url: '/jobs',
            views: {
                'content@authenticated': {
                    template: `<template-email-job-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                    come-from="$resolve.comeFrom"
                                ></template-email-job-overview>`
                }
            }
        }
    },
    {
        name: 'email.mailboxes.jobs.id',
        state: {
            abstract: true,
            resolve: {
                job: (
                    $stateParams: ng.ui.IStateParamsService,
                    emailJobModel: EmailJobModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return emailJobModel.findOne($stateParams.jobId).then(null, redirectOnNotFound);
                }
            },
            url: '/id/{jobId}'
        }
    },
    {
        name: 'email.mailboxes.jobs.id.details',
        state: {
            url: '/details',
            views: {
                'content@authenticated': {
                    // tslint:disable-next-line: max-line-length
                    template: `<template-email-mailbox-job-details
                                    job="$resolve.job"
                                ></template-email-mailbox-job-details>`
                }
            }
        }
    },
    {
        name: 'email.organizations',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.EMAIL_ORGANIZATION_LIST
            },
            resolve: {
                organizations: (organizationModel: OrganizationModelService) => {
                    return organizationModel.list().then((response) => response.data);
                }
            },
            url: '/organizations'
        }
    },
    {
        name: 'email.organizations.new',
        state: {
            data: {
                isGranted: UiRights.EMAIL_ORGANIZATION_CREATE
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-exchange-organization-new></template-exchange-organization-new>'
                }
            }
        }
    },
    {
        name: 'email.organizations.id',
        state: {
            abstract: true,
            resolve: {
                organization: (
                    $stateParams: ng.ui.IStateParamsService,
                    organizationModel: MailboxModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return organizationModel.findOne($stateParams.organizationId)
                        .then(stateUtilityFunctions.check404('Organization not found'))
                        .then(null, redirectOnNotFound);
                }
            },
            url: '/id/{organizationId}'
        }
    },
    {
        name: 'email.organizations.id.edit',
        state: {
            data: {
                isGranted: UiRights.EMAIL_ORGANIZATION_EDIT
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    // tslint:disable-next-line: max-line-length
                    template: '<template-exchange-organization-edit organization="$resolve.organization"></template-exchange-organization-edit>'
                }
            }
        }
    },
    {
        name: 'email.organizations.overview',
        state: {
            data: {
                isGranted: UiRights.EMAIL_ORGANIZATION_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('ExchangeOrganizations');
                },
                overviewModel: (organizationOverviewModel: OrganizationOverviewModel) => organizationOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('EmailOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('ExchangeOrganizations');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-exchange-organization-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                ></template-exchange-organization-overview>`
                }
            }
        }
    }
];
