import * as ng from 'angular';
import { NavigationService } from '../../../../services';
import { DashboardPanelDataObject, OrganismDashboardOverviewsController } from '../../../organisms/overview';
import './panel-dashboard-main-header.scss';

export class MoleculePanelDashboardMainHeaderController implements ng.IController {
    public $inject: string[] = ['navigation'];
    public searchValue: string;
    public dashboardPanelData: DashboardPanelDataObject;
    public OrganismDashboardOverview: OrganismDashboardOverviewsController;

    constructor(
        private navigation: NavigationService
    ) {}

    public $onInit() {
        this.searchValue = this.OrganismDashboardOverview.simpleFilterValue;
    }

    public get redirectToExpertOverview() {
        if ([undefined, null].indexOf(this.OrganismDashboardOverview.dashboardOverview.simpleFilter) < 0) {
            return this._redirectToExpertOverview;
        } else {
            return undefined;
        }
    }
    public multipleHeaderLinks = () => {
        return Array.isArray(this.dashboardPanelData.mainHeader);
    };

    public searchClick = () => {
        this.OrganismDashboardOverview.simpleFilterValue = this.searchValue;
        this.OrganismDashboardOverview.overviewLoad();
    };

    private _redirectToExpertOverview = () => {
        this.OrganismDashboardOverview.dashboardOverview.simpleFilter.show = true;
        this.navigation.go(
            this.OrganismDashboardOverview.dashboardOverview.overviewsData[0].panelHeaderData.panelHeaderRoute
        );
    };

}

export class MoleculePanelDashboardMainHeaderComponent implements ng.IComponentOptions {
    public require = {
        OrganismDashboardOverview: '^organismDashboardOverviews'
    };
    public bindings = {
        dashboardPanelData: '<',
        searchValue: '=?'
    };
    public controller =  MoleculePanelDashboardMainHeaderController;
    public controllerAs = 'PanelDashboardMainHeader';
    public template = require('./panel-dashboard-main-header.html');
}
