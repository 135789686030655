import * as ng from 'angular';
import { FunctionRelayService } from '../function-relay';
import { ModelHelper } from '../model-helper';
import { RequestStatus } from '../rpc-client';
import { SslRobotService } from './robot';

export class SslOrderModelService {
    public static $inject: string[] = ['sslRobot', '$rootScope', 'functionRelay'];

    constructor(
        private sslRobot: SslRobotService,
        private $rootScope: ng.IRootScopeService,
        private functionRelay: FunctionRelayService
    ) {}

    public list(limit, page, filters, sort) {
        sort = sort || {
            field: 'orderStartDate',
            order: 'ASC'
        };
        page = page || 1;

        return this.sslRobot.certificatesFind(filters, limit, page, sort)
            .then(ModelHelper.returnListResponse);
    }

    public findOne(id) {
        return this.sslRobot.certificatesFind({field: 'orderId', value: id}, 10, 1)
            .then(ModelHelper.returnFindOneResponse);
    }

    public findById(ids) {
        const filter = {subFilterConnective: 'OR', subFilter: ids.map(this.idFilter)};

        return this.sslRobot.certificatesFind(filter, 0, 1)
            .then(ModelHelper.returnFindResponse);
    }

    public getDomainApproverList(domainName) {
        return this.sslRobot.domainApproverList(domainName)
            .then(this.returnResponses);
    }

    public create(order, owner) {
        return this.sslRobot.orderCreate(order, owner)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED))
            .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
    }

    public resend(orders) {
        const promises = orders.map(this.resendOne);

        return Promise.all(promises)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    }

    private idFilter(id) {
        return {field: 'orderId', value: id};
    }

    private refresh = (status: RequestStatus = RequestStatus.SUCCESSFUL) => (result) => {
        this.$rootScope.$broadcast('orders.refresh');

        if (status === RequestStatus.SUCCESSFUL) {
            return result;
        } else {
            return Promise.reject(result);
        }
    };

    private refreshDeposit = (status: RequestStatus = RequestStatus.SUCCESSFUL) => (result) => {
        this.functionRelay.call('updateDeposit', {});

        if (status === RequestStatus.SUCCESSFUL) {
            return result;
        } else {
            return Promise.reject(result);
        }
    };

    private returnResponses(result) {
        return result.responses;
    }

    private resendOne(order) {
        return this.sslRobot.orderResendApproverEmail(order.id);
    }
}
