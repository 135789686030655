import * as ng from 'angular';
import { ManagedMachineProjectObject } from '../../../organisms';

export class OrganManagedMachineProjectConfirmationSubaccountController implements ng.IController {
    public static $inject: string[] = [];

    public projectData: ManagedMachineProjectObject;
    public emailInUse = false;
    public usedEmailAddress;
    public saveCallback;

    public $onInit() {
        this.usedEmailAddress = ng.copy(this.projectData.newSubAccount.emailAddress);
    }

    public get finished() {
        return this.projectData.finishedCreating.webspaces;
    }

    public get allObjectsCompleted() {
        return this.projectData.finishedCreating.webspaces === 1;
    }

    public get emailChanged() {
        return this.projectData.newSubAccount.emailAddress !== this.usedEmailAddress;
    }

    public set emailChanged(value) {
        return;
    }

    public save = () => {
        this.usedEmailAddress = ng.copy(this.projectData.newSubAccount.emailAddress);
        if (this.saveCallback !== undefined) {
            this.saveCallback();
        }
    };
}

export class OrganManagedMachineProjectConfirmationSubaccountComponent implements ng.IComponentOptions {
    public bindings = {
        emailInUse: '<',
        projectData: '=',
        saveCallback: '<'
    };

    public template = require('./subaccount.html');
    public controller =  OrganManagedMachineProjectConfirmationSubaccountController;
}
