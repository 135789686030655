import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { UserSettingsManagerService } from '../../../services';

export class TemplateWebspaceOverviewController {
    public static $inject: string[] = ['$state', '$translate', 'userSettingsManager'];

    public pageHeaderData: PanelHeaderData;
    public overviewServiceData: any;
    public expertView: boolean;
    public overviewModel: any;

    private simpleFilter: any;
    private extendedFilters: any;
    private overviewUiSettings: any;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private userSettingsManager: UserSettingsManagerService
    ) {}

    public $onInit() {
        const stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';

        if ([undefined, null].indexOf(this.simpleFilter) === -1) {
            this.expertView = this.overviewUiSettings.expertView || this.simpleFilter.show;
        } else {
            this.expertView = this.overviewUiSettings.expertView || false;
        }

        const panelHeaderRouteParams = stateInMachine
            ? { machineId: this.$state.$current.locals.globals.machine.id }
            : null;
        const panelHeaderBackwardLink = stateInMachine ? 'managed-servers.id.dashboard' : 'webhosting.dashboard';
        const panelHeaderBackwardText = stateInMachine
            ? this.$translate.instant('TR_220319-1ac2e3_TR')
            : this.$translate.instant('TR_140119-285630_TR');

        this.pageHeaderData = {
            backwardLink: panelHeaderBackwardLink,
            backwardText: panelHeaderBackwardText,
            panelHeaderRoute: '',
            panelHeaderRouteParams: panelHeaderRouteParams,
            panelIcon: 'server',
            panelTitle: this.$translate.instant('TR_080119-f4ef2a_TR')
        };

        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter
        };

        // Surpress backlink to dashboard on expert view because it auto-redirects to overview.
        this.userSettingsManager.getUiSettings().then(
            (result) => {
                if (result?.overviewSettings?.WebspaceOverviewCtrl?.expertView) {
                    this.pageHeaderData.backwardText = '';
                    this.pageHeaderData.backwardLink = '';
                    this.pageHeaderData.panelHeaderRouteParams = null;
                }
            }
        );
    }

    public expandWrapper = (toggle: { expand: boolean }) => {
        this.expertView = toggle.expand;
    };
}

export class TemplateWebspaceOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public controller = TemplateWebspaceOverviewController;
    public controllerAs = 'TemplateWebspaceOverviewCtrl';
    public template = require('./webspace-overview-template.html');
}
