import ng from 'angular';
import { UiRights } from '../../../../configuration';
import { Breadcrumb, BreadcrumbItem } from '../breadcrumb';
import { BreadcrumbTopicModel, TopicCases } from './topic-model';

export class BreadcrumbProfileTopicModel extends BreadcrumbTopicModel {
    public static $inject: string[] = BreadcrumbTopicModel.$inject.concat([]);

    public readonly topicName = 'profile';
    public readonly specialTopicCases: TopicCases;

    /**
     *   List of route parts, which are excluded from mail class generalRoutes method
     */
    public readonly excludeGeneralRouteParts = [];

    private resellerActionDropdownItems: BreadcrumbItem[] = [];

    constructor(protected $state: ng.ui.IStateService, protected $translate: ng.translate.ITranslateService) {
        super($state, $translate);

        this.specialTopicCases = {
            payment: this.getResellerEditRoutes
        };
    }

    public getFirstLevelData = (): Breadcrumb[] => {
        this.breadcrumbs.push({
            active: this.steps.length === 1,
            route: 'profile.dashboard',
            routeParams: {},
            title: this.$translate.instant('TR_080119-bcb4c2_TR'),
            type: 'text',
            value: this.$translate.instant('WEBHOSTING.VHOST.SSL.PROFILE.LABEL')
        });

        return this.breadcrumbs;
    };

    public getAccountEditRoutes = (): Breadcrumb[] => {
        const breadcrumbs = [];
        let route = '';
        let routeParams = {};

        if (this.steps[this.index - 2] === 'users') {
            breadcrumbs.push({
                active: false,
                isGranted: UiRights.ACC_ACCOUNT_USER_LIST,
                route: 'account.users.overview',
                routeParams: {},
                type: 'text',
                value: this.getActiveDropdownValue({ part: 'overview' })
            });

            route = 'account.users.id.edit';
            routeParams = { userId: this.globals.userData.id };
            breadcrumbs.push({
                active: true,
                isGranted: UiRights.ACC_ACCOUNT_USER_EDIT,
                route: route,
                routeParams: routeParams,
                type: 'text',
                value: this.getActiveDropdownValue({ part: 'edit' })
            });
        }

        return breadcrumbs;
    };

    public getResellerEditRoutes = (): Breadcrumb[] => {
        switch (this.step) {
            case 'edit':
                if (this.steps[this.index - 2] === 'subaccounts') {
                    this.breadcrumbs.push({
                        active: true,
                        isGranted: UiRights.ACC_SUBACCOUNT_LIST,
                        route: 'reseller.subaccounts.overview',
                        routeParams: {},
                        type: 'text',
                        value: this.getActiveDropdownValue({ part: 'overview' })
                    });
                }
                break;
            default:
                this.breadcrumbs = this.getProductActionDropdown(this.resellerActionDropdownItems);
        }

        this.breadcrumbs = this.breadcrumbs.concat(this.getEditRoute());

        return this.breadcrumbs;
    };
}
