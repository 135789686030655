import * as ng from 'angular';

import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateDomainContactsVerificationController {
    public static $inject: string[] = ['$translate'];

    public expertView: boolean;
    public overviewServiceData: any;
    public overviewModel;
    public pageHeaderData: PanelHeaderData = {
        backwardLink: 'domain.contacts.overview',
        backwardText: this.$translate.instant('TR_110119-9adcd4_TR'),
        panelHeaderRoute: '',
        panelHeaderRouteParams: {},
        panelIcon: 'globe',
        panelTitle: this.$translate.instant('MENU.CONTEXT.DOMAIN.CONTACT-VERIFICATION')
    };
    private simpleFilter;
    private extendedFilters;
    private overviewUiSettings;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit() {
        this.expertView = true; // Kontaktverifizierungen gbt es nur im expert Mode
        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter
        };
    }
    // tslint:disable-next-line:no-empty
    public expandWrapper = () => {};
}

export class TemplateDomainContactsVerificationComponent implements ng.IComponentOptions {
    public bindings = {
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public template = require('./domain-contacts-verification-template.html');
    public controller = TemplateDomainContactsVerificationController;
}
