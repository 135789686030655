import ng from 'angular';
import { HandleDefaultLabels } from '@/configuration';
import { DomainContactValidatorService, DomainHelper } from '@/services';
import { DomainApi, ViewTypes } from '@/types';

import './contacts.scss';

export class OrganEditPanelDomainEditBulkContactsController {
    public static $inject: string[] = ['$translate', 'domainContactValidator'];

    public selectedDomains: DomainApi.Domain[] = [];
    public numberOfDomains: number; // doesn't do much, is needed to trigger an $onChange if selected domains change
    public disableContactSettings = true;
    public selectedContacts: ViewTypes.DomainContactObject[] = [];
    public contacts: DomainApi.Contact[] = [];
    public contactDropdownItems: {names: string[]; value: string}[];
    public selectedDomainNames: { tld: string }[];
    public contactSetValidationErrors: DomainApi.ContactSetUsableProblem[];
    public handleList: ViewTypes.DomainContactObject[] = [];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private domainContactValidator: DomainContactValidatorService
    ) {}

    public $onInit(): void {
        this.reset();
        this._setupHandleList();
        this._setupContacts();
    }

    public $onChanges(change: ng.IOnChangesObject): void {
        if (change.numberOfDomains !== undefined && change.numberOfDomains.currentValue !== undefined) {
            this.disableContactSettings = this._differentAccounts();
            this.selectedDomainNames = this.selectedDomains.map(
                (domain) => {
                    return {
                        tld: DomainHelper.getTld(domain.nameUnicode)
                    };
                }
            );
        }
    }

    public contactSetValidation = (): void => {
        if (!this._allContactsSelected()) {
                this.contactSetValidationErrors = [];
                return;
        }

        const contacts = this.selectedContacts
            .map(
                (handle) => ({
                    contact: handle.contactId,
                    type: handle.contactType
                })
            );

        const tlds = this.selectedDomainNames.map(
            (domain) => domain.tld
        );

        /*
         * We set "useTrustee" to false here because we don't validate the contacts separately for each domain.
         */

        void this.domainContactValidator.contactSetUsableFor(contacts, tlds, false, this.selectedDomains[0].accountId)
        .then(
            (reply) => this.contactSetValidationErrors = reply
        );
    };

    public reset = (): void => {
        this.selectedContacts = HandleDefaultLabels.map(
            (handle) => ({
                contactType: handle.contactType as DomainApi.DomainContactType,
                contactId: null,
                label: this.$translate.instant(handle.label)
            })
        );
        this._setupHandleList();
    };

    public get contactDataIsValid(): boolean {
        if (
            this._differentAccounts()
            || this._noOneContactSelected()
        ) {
            return true;
        }

        return this._oneContactSelected()
            && (this.contactSetValidationErrors === undefined || this.contactSetValidationErrors.length === 0);
    }

    public set contactDataIsValid(_) {/* */}

    private _differentAccounts = (): boolean => {
        for (const domain of this.selectedDomains) {
            if (domain.accountId !== this.selectedDomains[0].accountId) {
                return true;
            }
        }
        return false;
    };

    private _setupContacts = (): void => {
        this.contactDropdownItems = this.contacts.map(
            (contact) => {
                return {
                    names: [contact.handle, contact.name, contact.city, contact.organization, contact.type],
                    value: contact.id
                };
            }
        );
    };

    private _setupHandleList = (): void => {
        this.handleList = HandleDefaultLabels.map(
            (handle) => ({
                contactId: null,
                contactType: handle.contactType as DomainApi.DomainContactType,
                label: this.$translate.instant(handle.label)
            })
        );
    };

    private _noOneContactSelected = (): boolean => {
        return this.selectedContacts.every(
            (handle) => [undefined, null, '', '0'].includes(handle.contactId)
        );
    };

    private _oneContactSelected = (): boolean => {
        return this.selectedContacts.some(
            (handle) => ![undefined, null, '', '0'].includes(handle.contactId)
        );
    };

    private _allContactsSelected = (): boolean => {
        return this.selectedContacts.every(
            (handle) => ![undefined, null, '', '0'].includes(handle.contactId)
        );
    };
}

export class OrganEditPanelDomainEditBulkContactsComponent implements ng.IComponentOptions {
    public bindings = {
        contactDataIsValid: '=',
        contacts: '<',
        numberOfDomains: '<',
        selectedContacts: '=',
        selectedDomains: '<'
    };
    public controller = OrganEditPanelDomainEditBulkContactsController;
    public template = require('./contacts.html');
}
