import * as ng from 'angular';
import { OrganismOverviewController, DisableFilter } from '@/atomic-components/organisms/overview/overview';
import { UtilityStatusObject } from '../../charts/utility-status/utility-status';
import { LinkListItem } from '../../links/link-list/link-list';

import './panel-overview-header.scss';

export class MoleculePanelOverviewHeaderController implements ng.IController {
    public static $inject: string[] = ['$stateParams'];

    public actionLinks: LinkListItem[];
    public fancyFilterExpand: boolean;
    public disableFilter: DisableFilter;
    public bundle: any;
    public utilityStatus: false | UtilityStatusObject;
    public expandWrapper: (param: any) => any;
    public OverviewCtrl: OrganismOverviewController;
    public displaySearchBar = true;
    public hideTopBar = false;
    public reloadStateOnRefresh = false;

    constructor(
        private $stateParams: ng.ui.IStateParamsService
    ) {}

    public $onInit() {
        if (
            [null, undefined].indexOf(this.OverviewCtrl.overviewServiceData.simpleFilter) === -1
            && [null, undefined].indexOf(this.OverviewCtrl.overviewServiceData.simpleFilter.hidden) === -1
        ) {
            this.displaySearchBar = false;
        }
        this.fancyFilterExpand = this.fancyFilterExpand || false;

        // Check if fancyfilter values were transmitted via route and set fancyfilter search values accordingly.
        if ([undefined, null].indexOf(this.$stateParams.fancyFilters) < 0
            && Array.isArray(this.$stateParams.fancyFilters)
        ) {
            let expand = JSON.parse(JSON.stringify(this.fancyFilterExpand)); // copy value
            this.OverviewCtrl.overviewModel.availableFilters.map((filter) => {
                for (const fancyFilter of this.$stateParams.fancyFilters) {
                    if (filter.filterField === fancyFilter.field) {
                        filter.value = fancyFilter.value;
                        expand = true;
                    }
                }

            });
            this.fancyFilterExpand = expand;
        }
        this.setExpandWrapper(this.fancyFilterExpand);

        this.bundle = this.bundle || null;
        if (this.OverviewCtrl.bundleUtilityChart) {
            this.utilityStatus = this.OverviewCtrl.bundleUtilityChart();
        } else {
            this.utilityStatus = false;
        }
    }

    public $onDestroy() {
        this.OverviewCtrl.overviewModel.activeMassAction = false;
    }

    public toggleFancyFilter() {
        this.fancyFilterExpand = !this.fancyFilterExpand;

        this.setExpandWrapper(this.fancyFilterExpand);
        this.OverviewCtrl.overviewModel.selected = {};
    }

    public setExpandWrapper = (expand: boolean) => {
        if (this.expandWrapper !== undefined) {
            this.expandWrapper({expand: expand});
            this.OverviewCtrl.expertView = expand;
        }
    };
}

export class MoleculePanelOverviewHeaderComponent implements ng.IComponentOptions {
    public require = {
        OverviewCtrl: '^organismOverview'
    };
    public bindings = {
        actionLinks: '<',
        bundle: '<',
        disableFilter: '<',
        expandWrapper: '<',
        fancyFilterExpand: '=',
        hideTopBar: '<?',
        reloadStateOnRefresh: '<'
    };
    public controller =  MoleculePanelOverviewHeaderController;
    public controllerAs = 'PanelOverviewHeader';
    public template = require('./panel-overview-header.html');
}
