import * as ng from 'angular';
import { EditPanelStatus } from '../../../../';

export class OrganEditPanelBundleAddDomainController {
    public static $inject: string[] = [];

    public readonly initialStatus = EditPanelStatus.ACTIVE;

    public cancel;
    public $editFormOrganism;
    public panelRight;
    public dateSelect: Date = new Date(Date.now());
}

export class OrganEditPanelBundleAddDomainComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormBundleAddDomain'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./bundle-add-domain.html');
    public controller = OrganEditPanelBundleAddDomainController;
}
