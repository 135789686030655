import { AuthContextService, DomainHandleModelService } from '@/services';
import * as Types from '@/types';

export class OrganDashboardWidgetOverviewContactVerificationController implements ng.IController {
    public static $inject: string[] = ['$translate', 'domainHandleModel'];

    public contactVerifications: Types.DomainApi.ContactVerification[] = [];
    public demoData: unknown[] = [];
    public panelTitle: string;

    public constructor(
        $translate: ng.translate.ITranslateService,
        private domainHandleModel: DomainHandleModelService
    ) {
        this.panelTitle = $translate.instant('TR_110119-7a3071_TR');
    }

    public $onInit(): void {
        const sort = {
            field: 'ContactVerificationLastChangeDate',
            order: 'DESC'
        };

        const filters = { field: 'ContactVerificationStatus', value: 'pending'};
        void this.domainHandleModel.listVerificationsWithoutPagination(3, 1, filters, sort)
            .then(
                (verificationRes) => {
                    if ((verificationRes.data as Types.DomainApi.ContactVerification[])?.length > 0) {
                        this.contactVerifications = verificationRes.data;
                    } else if (this.demoData) {
                        this.contactVerifications = this.demoData.map(
                            (contact: Types.DomainApi.Contact) => {
                                contact.accountId = AuthContextService.account.id;
                                return contact;
                            }
                        );
                    }
                }
            );
    }
}

export class OrganDashboardWidgetOverviewContactVerificationComponent implements ng.IComponentOptions {
    public bindings = {
        demoData: '<'
    };
    public template = require('./dashboard-widget-overview-contact-verification.html');
    public controller = OrganDashboardWidgetOverviewContactVerificationController;
}
