
import { BackupApi } from '@/types';
import * as ng from 'angular';

export class OrganEditPanelAdminBackupsController {
    public adminData: BackupApi.BackupExtendedObject;
}

export class OrganEditPanelAdminBackupsComponent implements ng.IComponentOptions {
    public bindings = {
        adminData: '<'
    };

    public controllerAs = '$ctrl';
    public template = require('./admin-backups.html');
    public controller = OrganEditPanelAdminBackupsController;
}
