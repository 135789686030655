/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import * as ng from 'angular';

import { DropdownElementData } from '@/atomic-components';
import './drop-down-revised-multi.scss';

export class MoleculeFormDropDownRevisedMultiController implements ng.IController {
    public static $inject: string[] = ['$element', '$timeout', '$translate', '$window'];

    // Bindings
    public _options: (string | DropdownElementData)[];// The actual data to be displayed in the dropdown
    public callbackOnChange: (...args: any[]) => any; // Gets called when any selection changes internally
    public isCustomisable: boolean;                   // Defines whether the chosen value must be in the options
    public isDisabled: boolean;                       // Defines whether this component is active (enabled) or not
    public isHideChips: boolean;                      // hide chips that indicate selected options
    public isHideFilterField: boolean;                // Indicates whether the filter input-field is visible and active
    public isShowSelected: boolean;                   // Defines whether selected option values are shown
    public isTextMultiselect: boolean;                // Defines whether dropdown is used as a simple text multi select
    public placeholder: string;                       // Visible when nothing is selected
    public showLimit: number;                         // Maximum number of options shown
    public multiSelectedElements: (string | DropdownElementData)[] = []; // References to multi selected elements

    private displayRenderCallback: (entry: DropdownElementData | string) => string;

    // Properties
    protected canOpenTowardsBottom = true;
    protected customOptions: (string | DropdownElementData)[] = []; // Contains new, user-created options
    protected filterValue = '';                          // The current filter that gets applied to the list of options
    protected filteredTabIndex = -1;                     // Indicates the selected option. -1 = nothing selected
    protected isFocused = false;                         // Returns true when the component is in focus
    protected isOpen = false;                            // Returns true when the list of options is open and visible
    protected isShowAllResults = false;                  // When set to true, it ignores the `show-limit` binding
    protected lastTabIndex = -1;                         // Saves the last state from tab-index
    protected realTabIndex = -1;                         // For multiselect options that aren't removed
    protected selectedElement: string | DropdownElementData = null; // Reference to the currently selected element

    protected _model: (string | DropdownElementData)[]; // Selected option value

    // Listen for changes on model and update selectedElement
    public get model(): any {
        return this._model;
    }
    public set model(value) {
        this._model = value;
        this.multiSelectedElements = value;
    }

    public get options(): (string | DropdownElementData)[] {
        return this._options;
    }
    public set options(value) {
        if ([undefined, null].indexOf(value) < 0) {
            this._options = value.map(
                (entry: string | DropdownElementData) => {
                    if (this.displayRenderCallback && typeof entry !== 'string') {
                        return {
                            name: this.displayRenderCallback(entry),
                            value: entry.value
                        };
                    }
                    return entry;
                }
            );
        }
    }

    // Calculate tab index
    protected get tabIndex(): number {
        return (this.isShowSelected && this.filterValue === '')
            ? this.realTabIndex
            : this.filteredTabIndex;
    }
    protected set tabIndex(newTabIndex) {
        if (this.isShowSelected && this.filterValue === '') {
            this.realTabIndex = newTabIndex;
        } else {
            this.filteredTabIndex = newTabIndex;
        }
    }

    // Only show the placeholder if no chips are using the space and no element is selected
    public get isShowPlaceholder(): boolean {
        return this.multiSelectedElements.length === 0;
    }

    // Hide the searchbar when either the binding is set or the options array is
    // smaller than five and no API search-function is defined
    public get isHideSearchbar(): boolean {
        if (this.isCustomisable) {
            return false;
        }

        return this.isHideFilterField
            || (
                this.options
                && this.options.length <= 5
            );
    }

    public get newEntriesPlaceholder(): string {
        return this.options?.length === 0 && !this.isCustomisable
            ? this.$translate.instant('TR_010420-38aade_TR')
            : this.placeholder;
    }

    constructor(
        public $element: ng.IAugmentedJQuery,
        public $timeout: ng.ITimeoutService,
        public $translate: ng.translate.ITranslateService,
        public $window: ng.IWindowService
    ) {
        // Prevent body from scrolling on space
        document.addEventListener('keydown', (keyEvent: KeyboardEvent) => {
            if (['Space', 'ArrowUp', 'ArrowDown'].indexOf(keyEvent.code) >= 0) {
                if (this.isFocused && !this.isOpen && !keyEvent.shiftKey) {
                    keyEvent.preventDefault();

                    void this.$timeout(() => {
                        this.open();
                    });
                }
            }
        });
    }

    // eslint-disable-next-line complexity
    public $onInit(): void {
        // Init bindings
        this.options = this.options || [];
        this.isCustomisable = this.isCustomisable || false;
        this.isDisabled = this.isDisabled || false;
        this.isShowSelected = this.isShowSelected || false;
        this.isHideChips = this.isHideChips || false;
        this.isHideFilterField = this.isHideFilterField || false;
        this.showLimit = this.showLimit || 25;
        this.canOpenTowardsBottom = true;
        this.placeholder = (this.placeholder && this.placeholder !== '')
            ? this.placeholder
            : this.$translate.instant('BUNDLE.DELETE.DOMAIN-ACTION.CHOOSE');

        if (this.model && Array.isArray(this.model)) {
            this.multiSelectedElements = (this.model as any);
        }

        // Init properties
        this.tabIndex = -1;
        this.filterValue = '';
    }

    // Show all results
    public showAllResults = (isShowAll: boolean): void => {
        this.isShowAllResults = isShowAll;
    };

    // Select nothing. Only possible if not required
    public selectBlankOption = (): void => {
        this.selectedElement = null;
        this.tabIndex = -1;
        this.close();
    };

    // Set focus to the main input filter-field (searchbar)
    public focusFilterTextInputField = (): void => {
        void this.$timeout(() => {
            let textFieldEl = this.$element[0]?.querySelector('.dropdown-text');

            if (!textFieldEl) {
                textFieldEl = this.$element[0]?.querySelector('.hidden-focus-helper');
            }

            if (textFieldEl) {
                (textFieldEl as HTMLElement).focus();
            }
        });
    };

    // Scroll the `ul` to the currently selected `li`
    public scrollListToTabIndex = (): void => {
        void this.$timeout(() => {
            const optionsWrapperEl = document.querySelector('.dropdown-select');
            const selectedOptionEl = document.querySelector('.dropdown-option.focused');

            if (selectedOptionEl && optionsWrapperEl.clientHeight < optionsWrapperEl.scrollHeight) {
                selectedOptionEl.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
            }
        });
    };

    // Open the list of options
    public open = (): void => {
        if (this.options?.length === 0 && !this.isCustomisable) {
            return;
        }
        if (!this.isOpen) {
            this.filterValue = '';
            this.isOpen = true;
        }

        void this.$timeout(() => {
            this.focusFilterTextInputField();

            const optionsWrapperEl = document.querySelector('.molecule-form-drop-down-revised.open');
            const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
            if (optionsWrapperEl) {
                // Return if the total height is more than half the height of the object
                this.canOpenTowardsBottom = (viewportHeight / 2) > (
                    optionsWrapperEl.getBoundingClientRect().top
                    + (optionsWrapperEl.getBoundingClientRect().height / 2)
                );
            } else {
                this.canOpenTowardsBottom = true;
            }
        });
    };

    // Close the list of options
    public close = (): void => {
        this.isOpen = false;
        this.tabIndex = 0;
    };

    // Toggle opening and closing the options-list
    public toggle = (): void => {
        if (this.isOpen) {
            this.close();
            this.onLostFocus();
        } else {
            this.open();
        }
    };

    // Set back the tab-index to the top-most position based on being customizable
    // 0 = default, -1 = with custom option
    public resetTabIndexToTop = (): void => {
        this.tabIndex = (this.isCustomisable && this.filterValue !== '' ? -1 : 0);
    };

    // Update the model
    public updateModel = (): void => {
        const tmpModel = this.multiSelectedElements;

        // Set the correct string value from the properties
        if (
            Array.isArray(tmpModel)
            && tmpModel.length > 0
            && (tmpModel[0] as DropdownElementData).value
        ) {
            this._model = tmpModel.map((option: DropdownElementData) => option.value);
        } else {
            this._model = tmpModel;
        }
    };

    // Select an option
    public select = (optionIndex: number|undefined, selectedElement: unknown = undefined): void => {
        if (this.filterValue !== '' && this.filteredOptions.length === 0) {
            if (!this.isCustomisable) {
                return;
            }

            this.resetTabIndexToTop();
        }

        // Add new custom entry
        if (
            this.filterValue !== ''
            && this.tabIndex === -1
            && (
                optionIndex < 0
                || optionIndex === undefined
            )
        ) {
            if (this.isCustomisable) {
                this.addFilterAsCustomOption();
            }

            if (this.callbackOnChange) {
                this.callbackOnChange(this._model);
            }

            return;
        }

        this.tabIndex = optionIndex ? optionIndex : 0;

        // Set the selected element in the original list
        if (optionIndex === undefined && selectedElement !== undefined) {
            this.selectedElement = selectedElement;
        } else {
            this.selectedElement = (
                0 < this.filteredOptions.length
                && this.filteredOptions.length > optionIndex
            )
                ? this.filteredOptions[optionIndex]
                : this.options[0];
        }

        if ([undefined, null].indexOf(this.selectedElement) < 0) {
            // Append the element to multi selected list
            const multiSelectedElementIndex = this.multiSelectedElements.indexOf(this.selectedElement);

            if (multiSelectedElementIndex < 0) {
                this.multiSelectedElements.push(this.selectedElement);
            }

            // Close the dropdown as soon as all elements are added
            if (this.multiSelectedElements.length === this.options.length) {
                this.close();
            }
        }

        // Update tab-index to fit the new size of the filtered items
        this.tabIndex = this.tabIndex >= this.filteredOptions.length
            ? this.filteredOptions.length - 1
            : this.tabIndex;

        this.focusFilterTextInputField();
        this.updateModel();

        if (this.callbackOnChange) {
            this.callbackOnChange(this._model);
        }
    };

    // Select all options from the multi-select
    public selectAll = (): void => {
        this.multiSelectedElements = this.multiSelectedElements.concat(
            (this.filteredOptions as any[]).filter(
                (option) => this.multiSelectedElements.indexOf(option) < 0
            )
        );
        this.resetTabIndexToTop();
        this.updateModel();
        this.focusFilterTextInputField();
        this.close(); // Close the dropdown as soon as all elements are added
    };

    // user cleared selection
    public selectNone = (): void => {
        this.multiSelectedElements.length = 0;
        this.selectedElement = null;
        this.resetTabIndexToTop();
        this.updateModel();
        this.focusFilterTextInputField();
        if (this.callbackOnChange) {
            this.callbackOnChange(this._model);
        }
    };

    // Remove an option from the multi-select list
    public unselectMultiOptionElement = (optionElement: string | DropdownElementData): void => {
        this.multiSelectedElements.splice(
            this.multiSelectedElements.indexOf(optionElement), 1
        );

        if (this.multiSelectedElements.length <= 0) {
            this.selectedElement = null;
        }

        this.updateModel();

        if (this.callbackOnChange) {
            this.callbackOnChange(this._model);
        }
    };

    // Convert an option value to readable/printable format
    public optionValueToString = (option: string | DropdownElementData): string => {
        if (!option) {
            return '';
        }

        let resultString = '';

        if ((option as DropdownElementData).name !== undefined) {
            resultString = (option as DropdownElementData).name;
        } else if ((option as DropdownElementData).names !== undefined) {
            resultString = (option as DropdownElementData).names[0]
                + (
                    (option as DropdownElementData).value
                        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        ? ` (ID: ${(option as unknown as DropdownElementData).value})`
                        : ''
                );
        } else if ((option as DropdownElementData).value !== undefined) {
            resultString = (option as DropdownElementData).value;
        } else if (typeof option === 'string') {
            resultString = option;
        }

        return resultString ? resultString : '-';
    };

    // Convert an option value to readable/printable format
    public modelValueToString = (option: string | DropdownElementData | (string | DropdownElementData)[]): string => {
        if (!option) {
            return '';
        }

        if (Array.isArray(option)) {
            return option.map((opt: string | DropdownElementData) => this.optionValueToString(opt)).join(',');
        } else {
            return this.optionValueToString(option);
        }
    };

    // Returns the number of additional fields per option. Excludes defaults like `name` or `value`
    public get totalAdditionalFieldsPerOption(): number {
        if (!this.options[0]) {
            return 0;
        }

        let totalDefaultFields = 0;

        if (
            (this.options[0] as DropdownElementData).name !== undefined
            || (this.options[0] as DropdownElementData).names !== undefined
        ) {
            ++totalDefaultFields;
        }

        if ((this.options[0] as DropdownElementData).value !== undefined) {
            ++totalDefaultFields;
        }

        return totalDefaultFields;
    }

    // Select the next option
    public incrementTabIndex = (): void => {
        this.open();

        if (this.tabIndex >= this.filteredOptions.length - 1) {
            this.tabIndex = (this.filterValue !== '' && this.isCustomisable ? -1 : 0);
        } else {
            ++this.tabIndex;
        }
    };

    // Select the previous option
    public decrementTabIndex = (): void => {
        this.open();

        if (this.tabIndex <= (this.filterValue !== '' && this.isCustomisable ? -1 : 0)) {
            this.tabIndex = this.filteredOptions.length - 1;
        } else {
            --this.tabIndex;
        }
    };

    // Opening the component itself with keyboard
    public openWithKeyboard = (event: KeyboardEvent): void => {
        if (
            !this.isOpen
            && this.isFocused
            && (
                event.code === 'Enter'
                || event.code === 'Space'
            ) && !event.shiftKey
        ) {
            event.preventDefault();
            this.open();
        }
    };

    // Handle keyboard input
    public handleKeyboardInput = (event: KeyboardEvent): void => {
        switch (event.code) {
            // Close on tab
            case 'Tab':
                this.close();
                break;

            case 'ArrowDown':
                event.preventDefault();
                if (this.isOpen) {
                    this.incrementTabIndex();
                    this.scrollListToTabIndex();
                } else {
                    this.open();
                }
                break;

            case 'ArrowUp':
                event.preventDefault();
                if (this.isOpen) {
                    this.decrementTabIndex();
                    this.scrollListToTabIndex();
                } else {
                    this.open();
                }
                break;

            case 'Enter':
                if (this.isOpen === false) {
                    this.open();
                } else if (this.filterValue !== '' && this.filteredOptions.length === 0 && this.isCustomisable) {
                    this.addFilterAsCustomOption();
                } else if (this.tabIndex >= 0 || this.filterValue !== '') {
                    this.select(this.tabIndex);
                }
                break;

            case 'Escape':
                this.close();
                break;
        }
    };

    // Apply filter to the options
    public get filteredOptions(): (string | DropdownElementData)[] {
        if (!this.options) {
            return [];
        }

        // Filter by ID
        if (this.filterValue[0] === '#') {
            return this.options.filter(
                (option) => (option as DropdownElementData).value === this.filterValue.slice(1)
            );
        }

        // Filter for string-array
        if (typeof this.options[0] === 'string') {
            return (this.options as string[]).filter(
                (option) => {
                    const isContainFilter: boolean = option
                        .toLowerCase()
                        .indexOf(
                            this.filterValue.toString().toLowerCase()
                        ) >= 0;
                    const isEmptyFilter: boolean = this.filterValue === '';
                    const isSelectedElement: boolean = this.multiSelectedElements.indexOf(option) >= 0;

                    return !isSelectedElement
                        && (
                            isEmptyFilter
                            || (
                                !isEmptyFilter
                                && isContainFilter
                            )
                        );
                }
            ).filter(
                (_, optionIndex) => {
                    // Respect limit
                    if (this.showLimit && !this.isShowAllResults) {
                        return optionIndex < this.showLimit;
                    }
                    return true;
                }
            );
        }

        // Filter for `DropdownElementData`
        return (this.options as DropdownElementData[]).filter(
            (option) => {
                let isContainFilter = false;

                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                if (option.value && `${option.value}`.toLowerCase().indexOf(
                    this.filterValue.toString().toLowerCase()
                ) >= 0) {
                    isContainFilter = true;
                }

                if (
                    option.name
                    && option.name
                        .toString()
                        .toLowerCase()
                        .indexOf(this.filterValue.toString().toLowerCase()) >= 0
                ) {
                    isContainFilter = true;
                }

                if (option.names) {
                    option.names.forEach(
                        (optionName) => {
                            if (
                                typeof optionName === 'string'
                                && optionName
                                    .toLowerCase()
                                    .indexOf(this.filterValue.toString().toLowerCase()) >= 0
                            ) {
                                isContainFilter = true;
                            }
                        }
                    );
                }

                const isEmptyFilter: boolean = this.filterValue === '';
                const isSelectedElement: boolean = this.multiSelectedElements.indexOf(option) >= 0;

                return !isSelectedElement && (isEmptyFilter || (!isEmptyFilter && isContainFilter));
            }
        ).filter(
            (_, optionIndex) => {
                // Respect limit
                if (this.showLimit && !this.isShowAllResults) {
                    return optionIndex < this.showLimit;
                }
                return true;
            }
        );
    }

    // React to changes in filter
    public onFilterChange = (): void => {
        if (!this.isOpen && this.selectedElement != null) {
            this.filterValue = '';
        }

        this.open();

        this.tabIndex = (this.isCustomisable && this.filterValue !== '' ? -1 : 0);
    };

    public get optionsIsStringArray(): boolean {
        return this.options.length > 0 && typeof this.options[0] !== 'string';
    }

    // Create a new custom option and add it to the list
    public addFilterAsCustomOption = (): void => {
        const selectedElement = ng.copy(this.filterValue);

        if (this.options.indexOf(this.filterValue) < 0) {
            if (typeof this.options[0] === 'string') {
                this.options.push(this.filterValue);
                this.customOptions.push(this.filterValue);

                if (this.isTextMultiselect) {
                    this.filteredOptions.push(this.filterValue);
                }
            } else if (this.options.length > 0 && this.options[0].name) {
                this.options.push({ name: this.filterValue, value: this.filterValue});
                this.customOptions.push({ name: this.filterValue, value: this.filterValue});
            } else if (this.options.length > 0 && this.options[0].names) {
                this.options.push({ names: [this.filterValue], value: this.filterValue});
                this.customOptions.push({ names: [this.filterValue], value: this.filterValue});
            } else {
                this.options.push(this.filterValue);
                this.customOptions.push(this.filterValue);
            }

            this.filterValue = '';

            if (this.isTextMultiselect) {
                this.select(undefined, selectedElement);
            } else {
                this.select(this.options.length - 1);
            }
        } else {
            if (this.isTextMultiselect) {
                this.filterValue = '';
                this.select(undefined, selectedElement);
            }
        }
    };

    public removeCustomOption = (customOption: any): void => {
        this.customOptions.splice(this.customOptions.indexOf(customOption), 1);
    };

    // Handle losing focus by resetting the dropdown
    public onLostFocus = (): void => {
        this.close();
        this.filterValue = '';
    };
}

export class MoleculeFormDropDownRevisedMultiComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnChange: '<?',                 // Gets called when any selection changes internally
        displayRenderCallback: '<?',            // Filter that changes the output rendering of options
        isCustomisable: '<?customisable',       // Defines whether the chosen value must be in the options
        isDisabled: '<?disabled',               // When true the element is disabled
        isHideChips: '<?hideChips',             // Indicates whether chips should be shown, indicating selected options
        isHideFilterField: '<?hideFilterField', // Indicates whether the filter input-field is visible and active
        isShowSelected: '<?showSelected',       // Selected option should still be shown in the filtered list?
        isTextMultiselect: '<?textMultiselect', // Dropdown is used as a simple text multi select
        model: '=?',                            // Active data value(s)
        options: '<?',                          // Data to display inside the dropdown/multiselect
        placeholder: '@',                       // Visible when nothing is selected
        showLimit: '<?'                         // Maximum number of options shown
    };
    public controller: any =  MoleculeFormDropDownRevisedMultiController;
    public template = require('./drop-down-revised-multi.html');
}
