import { CountryListService } from '../services';

export class Country {
    public static $inject: string[] = ['countryList'];

    public static Factory(countryList: CountryListService) {
        const countries = countryList.getRawList();

        const filterFunction: any = (value: string) => {
            if ([undefined, null].indexOf(value) >= 0) {
                return undefined;
            }
            return countries[value.toLowerCase()];
        };

        filterFunction.$stateful = true;

        return filterFunction;
    }
}
