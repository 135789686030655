import * as ng from 'angular';

export class MoleculeServiceObjectStatusDeletingController implements ng.IController {
    public static $inject: string[] = [];
}

export class MoleculeServiceObjectStatusDeletingComponent implements ng.IComponentOptions {
    public bindings = {};
    public controller =  MoleculeServiceObjectStatusDeletingController;
    public template = require('./service-object-status-deleting.html');
}
