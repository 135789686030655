import ng from 'angular';
import { UiRights } from '../../../../configuration';
import { Breadcrumb, BreadcrumbItem } from '../breadcrumb';
import { BreadcrumbTopicModel, TopicCases } from './topic-model';

export class BreadcrumbAccountTopicModel extends BreadcrumbTopicModel {
    public static $inject: string[] = BreadcrumbTopicModel.$inject.concat([]);

    public readonly topicName = 'account';
    public readonly specialTopicCases: TopicCases;

    /**
     *   List of route parts, which are excluded from mail class generalRoutes methode
     */
    public readonly excludeGeneralRouteParts = ['edit'];

    private accountActionDropdownItems: BreadcrumbItem[] = [];

    constructor(protected $state: ng.ui.IStateService, protected $translate: ng.translate.ITranslateService) {
        super($state, $translate);

        this.accountActionDropdownItems = [
            {
                isGranted: UiRights.ACC_ACCOUNT_BASICDATA_LIST,
                name: this.$translate.instant('STATE.ACCOUNT.BASICDATA.PAGE-TITLE'),
                part: 'basicdata',
                route: 'account.basicdata'
            },
            {
                isGranted: UiRights.ACC_ACCOUNT_BASICDATA_LIST,
                name: this.$translate.instant('TR_080119-5c34b8_TR'),
                part: 'settings',
                route: 'account.settings'
            },
            {
                isGranted: UiRights.ACC_ACCOUNT_USER_LIST,
                name: this.$translate.instant('TR_130319-0cb933_TR'),
                part: 'users',
                route: 'account.users.overview'
            },
            {
                name: this.$translate.instant('MENU.MAIN.DATA-PROCESSING'),
                part: 'dataprocessing',
                route: 'account.dataprocessing'
            }
        ];

        this.specialTopicCases = {
            basicdata: this.editContactData,
            contactdata: this.editContactData,
            contactemail: this.editContactData,
            dataprocessing: () => this.getProductActionDropdown(this.accountActionDropdownItems),
            edit: this.getAccountEditRoutes,
            settings: this.editContactData,
            users: () => this.getProductActionDropdown(this.accountActionDropdownItems)
        };
    }

    public getFirstLevelData = (): Breadcrumb[] => {
        this.breadcrumbs.push({
            active: this.steps.length === 2,
            route: 'account.dashboard',
            routeParams: {},
            title: this.$translate.instant('TR_080119-5c34c0_TR'),
            type: 'text',
            value: this.$translate.instant('TR_140119-b3b187_TR')
        });

        return this.breadcrumbs;
    };

    public getAccountEditRoutes = (): Breadcrumb[] => {
        const breadcrumbs = [];
        let route = '';
        let routeParams = {};

        if (this.steps[this.index - 2] === 'users') {
            breadcrumbs.push({
                active: false,
                isGranted: UiRights.ACC_ACCOUNT_USER_LIST,
                route: 'account.users.overview',
                routeParams: {},
                type: 'text',
                value: this.getActiveDropdownValue({ part: 'overview' })
            });

            route = 'account.users.id.edit';
            routeParams = { userId: this.globals.userData.id };
            breadcrumbs.push({
                active: true,
                isGranted: UiRights.ACC_ACCOUNT_USER_EDIT,
                route: route,
                routeParams: routeParams,
                type: 'text',
                value: this.getActiveDropdownValue({ part: 'edit' })
            });
        }

        return breadcrumbs;
    };

    public editContactData = (): Breadcrumb[] => {
        switch (this.step) {
            case 'basicdata':
                this.breadcrumbs = this.getProductActionDropdown(this.accountActionDropdownItems, {}, 'basicdata');
                break;
            case 'contactdata':
                this.breadcrumbs = this.getProductActionDropdown(this.accountActionDropdownItems, {}, 'basicdata');
                break;
            case 'contactemail':
                this.breadcrumbs = this.getProductActionDropdown(this.accountActionDropdownItems, {}, 'basicdata');
                break;
            default:
                this.breadcrumbs = this.getProductActionDropdown(this.accountActionDropdownItems);
        }

        return this.breadcrumbs;
    };
}
