import { BillingHelperService } from '../helpers';
import { ModelHelper } from '../model-helper';
import { FileSaverService } from './file-saver';
import { BillingRobotService } from './robot';

import * as ng from 'angular';
import * as q from 'q';
import * as Types from '../../types';

export class DocumentModelService {
    public static $inject: string[] = [
        '$http',
        'billingHelper',
        'billingRobot',
        'fileSaver'
    ];

    public payment: any;

    constructor(
        private $http: ng.IHttpService,
        private billingHelper: BillingHelperService,
        private billingRobot: BillingRobotService,
        private fileSaver: FileSaverService
    ) {
        this.billingHelper.loadStripeScript();
        this.payment = {
            cancel: (paymentId) => {
                this.billingRobot.cancelDocumentPayment(paymentId);
            },
            commit: (paymentMethod: string, token: string, payerId: string, paymentIdOrToken?: string) => {
                let paymentCommitParameters: Types.BillingApi.AbstractPaymentCommitParameters;

                switch (paymentMethod) {
                    case 'paypal':
                        paymentCommitParameters = {
                            externalPaymentToken: token,
                            payerId: payerId,
                            type: 'PaypalPaymentCommitParameters'
                        } as Types.BillingApi.PaypalPaymentCommitParameters;

                        break;

                    case 'stripe':
                        paymentCommitParameters = {
                            paymentId: paymentIdOrToken,
                            type: 'StripePaymentCommitParameters'
                        } as Types.BillingApi.StripePaymentCommitParameters;

                        break;

                    default:
                        throw new Error(`Unknown payment provider "${paymentMethod}"`);
                }

                return this.billingRobot.paymentCommit(paymentCommitParameters);
            },
            start: (paymentMethod: string, documentIds: any) => {
                const baseUrl = `${window.location.protocol}//${window.location.host}/billing/invoices/`;
                let paymentParameters: Types.BillingApi.AbstractPaymentParameters;

                if (paymentMethod === 'paypal') {
                    paymentParameters = {
                        cancelUrl: baseUrl + 'cancel/paypal',
                        commitUrl: baseUrl + 'commit/paypal',
                        type: 'PaypalPaymentParameters'
                    } as Types.BillingApi.PaypalPaymentParameters;

                    return this.billingRobot.startDocumentPayment(documentIds, paymentParameters).then(
                        (result) => {
                            window.location.assign(result.response.externalPaymentSite);
                        }
                    );
                } else if (paymentMethod === 'stripe') {
                    paymentParameters = {
                        cancelUrl: baseUrl + 'cancel/stripe',
                        commitUrl: baseUrl + 'commit/stripe',
                        type: 'StripePaymentParameters'
                    } as Types.BillingApi.StripePaymentParameters;

                    return this.billingRobot.startDocumentPayment(documentIds, paymentParameters).then(
                        (result) => {
                            const stripe = Stripe(result.response.paymentPublicKey);
                            return stripe.redirectToCheckout({
                                sessionId: result.response.externalPaymentSession
                            }).then(
                                (rst) => {
                                    // If `redirectToCheckout` fails due to a browser or network
                                    // error, display the localized error message to your customer
                                    // using `result.error.message`.
                                    return rst;
                                }
                            );
                        }
                    );
                }
            }
        };
    }

    public listInvoices = (limit?, page?, filter?, sort?, cancel?) => {
        limit = limit || null;
        page = page || 1;
        filter = filter || '';
        sort = sort || {
            field: 'documentDate',
            order: 'DESC'
        };

        const filters: Types.Finding.Filter = {
            subFilter: [
                {
                    subFilter: [
                        {
                            field: 'type',
                            value: 'invoice'
                        },
                        {
                            field: 'type',
                            value: 'creditnote'
                        },
                        {
                            field: 'type',
                            value: 'returndebitnote'
                        }
                    ],
                    subFilterConnective: 'OR'
                }
            ],
            subFilterConnective: 'AND'
        };

        if (ng.isString(filter)) {
            filters.subFilter.push({
                field: 'documentNumber',
                value: '*' + filter + '*'
            });
        }

        if (ng.isObject(filter)) {
            filters.subFilter.push(filter);
        }

        return this.billingRobot.listDocuments(filters, limit, page, sort, 'httpnet', {timeout: cancel})
            .then(ModelHelper.returnListResponse);
    };

    public getUnpaidInvoices = (limit = 5, page = 1) => {
        const filter = {
            subFilter: [
                {
                    field: 'type',
                    value: 'invoice'
                },
                {
                    field: 'outstanding',
                    relation: 'greater',
                    value: '0'
                }
            ],
            subFilterConnective: 'AND'
        };
        return this.listDocuments(limit, page, filter);
    };

    public listDocuments = (limit, page, filter, sort?, cancel?) => {
        sort = sort || {
            field: 'documentDate',
            order: 'DESC'
        };
        page = page || 1;

        return this.billingRobot.listDocuments(filter, limit, page, sort, 'httpnet', {timeout: cancel})
        .then(ModelHelper.returnListResponse);
    };

    public getPdf = (invoiceNumber) => {
        return this.billingRobot.getDocumentPDF(invoiceNumber)
        .then((response) => {
            window.location.href = response.response;
        });
    };

    public getCsv = (invoiceNumber) => {
        return this.billingRobot.getDocumentCSV(invoiceNumber)
            .then((response) => {
                window.location.href = response.response;
            });
    };

    // Markus: Keep pls.
    private returnResponse = (result) => {
        return result.response;
    };

    private _getFileDataFromUrl = (url: string, fileType: string, filename: string) => {
        let contentType;

        switch (fileType) {
            case 'csv':
                contentType = 'text/csv';
                break;
            case 'pdf':
                contentType = 'application/pdf';
                break;
            default:
                contentType = 'text/plain';
                fileType = 'txt';
        }

        return this.$http({
            headers : { 'Content-type' : contentType },
            method : 'GET',
            responseType : 'arraybuffer',
            url : url
        })
        .then((fileData) => {
            if (fileData.status === 200) {
                const file = new Blob([fileData.data as ArrayBuffer], { type: contentType });
                this.fileSaver.saveAs(file, `${filename}.${fileType}`);
                return q.resolve(true);
            }

            return q.reject(false);
        });
    };
}
