import * as ng from 'angular';
import { AuthContextService, WebhostingUserModelService } from '@/services';
import * as Types from '@/types';
import { ViewTypes } from '@/types';

export class OrganismWebspaceWizardProductUserConfigController {
    public static $inject: string[] = [
        '$scope',
        '$timeout',
        '$translate',
        'webhostingUserModel'
    ];

    public metaData: ViewTypes.ProductConfigWebspaceObject;

    public formCompleted: Record<string, boolean> = {
        webspaceName: false,
        userData: false
    };

    public accessTypeButtons: any[] = [];
    public accessHeadings: string[];
    public lastClickedCheckbox: { value: any; field: any };
    public accessLevels: { type: string; label: string }[];
    public newWebspaceUserObject: any;
    public isValid: boolean;
    public newAccessObject: Record<string, boolean>;
    public accountId: string;
    public webspaceId: string;
    public _accessesCache: { comment: string; name: string; id: string}[];
    public accessSelectItems: any[];
    public availableAccesses: any;
    public onlyNewAccessAvailable: boolean;
    public removeFromList: any[];
    public _disabledAccessLevels: any[];

    get disabledAccessLevels(): any[] {
        return this._disabledAccessLevels;
    }
    set disabledAccessLevels(value: any[]) {
        this._disabledAccessLevels = value;
    }

    constructor(
        private $scope: ng.IScope,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private webhostingUserModel: WebhostingUserModelService
    ){}

    public $onInit = (): void => {
        this.accessHeadings = [
            this.$translate.instant('TR_110119-76b4ba_TR'),
            this.$translate.instant('TR_100119-e937e9_TR')
        ];

        if (this.lastClickedCheckbox == null) {
            this.lastClickedCheckbox = {
                value: undefined,
                field: undefined
            };
        }

        this.accessTypeButtons = [
            { value: 'new', label: this.$translate.instant('TR_110119-b61be5_TR') }
        ];

        void this.listWebspaceUsers().then((webspaceUsers) => {
            if (webspaceUsers.data?.length > 0) {
                this.accessTypeButtons.push({
                    value: 'existing',
                    label: this.$translate.instant('TR_110119-d043dc_TR')
                });
            }
            if (webspaceUsers.data?.length > 0) {
                this.onlyNewAccessAvailable = false;
                this.accessType = 'existing';
            } else {
                this.onlyNewAccessAvailable = true;
                this.accessType = 'new';
            }
        });

        this.accessLevels = [
            { type: 'ftpAccess', label: this.$translate.instant('TR_100119-ca0b1e_TR') },
            { type: 'sshAccess', label: this.$translate.instant('TR_100119-fecdf6_TR') },
            { type: 'statsAccess', label: this.$translate.instant('TR_100119-77f707_TR') },
            { type: 'ftpLimited', label: this.$translate.instant('TR_100119-54245d_TR') }
        ];

        this._disabledAccessLevels = [];
        this.$scope.$watchCollection(
            () => this.newAccessObject,
            (newAccessObjectNewer, newAccessObjectOlder) => {
                if (!newAccessObjectNewer || !newAccessObjectOlder) {
                    return;
                }
                for (const key in newAccessObjectNewer) {
                    if (Object.prototype.hasOwnProperty.call(newAccessObjectNewer, key)) {
                        if (newAccessObjectNewer[key] !== newAccessObjectOlder[key]) {
                            this.ftpLimitedAccess(key);
                            this.setMetaDataAccesses();
                        }
                    }
                }
            });
    };

    public setMetaDataAccesses = (): void => {
        this.metaData.user.accesses = [];
        for (const key in this.newAccessObject) {
            if (Object.prototype.hasOwnProperty.call(this.newAccessObject, key)) {
                if (this.newAccessObject[key]) {
                    this.metaData.user.accesses.push(key);
                }
            }
        }
    };

    public get accessType(): string {
        return this.metaData.user.userType;
    }

    public set accessType(value: string) {
        this.metaData.user.userType = (value as 'existing' | 'new');
    }

    public listWebspaceUsers = (
        limit?: number,
        page?: number,
        filters?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions,
        owner?: string
    ): PromiseLike<any> => {
        const filterObject: any = {
            subFilter: [{
                field: 'accountId',
                value: AuthContextService.account.id
            }],
            subFilterConnective: 'AND'
        };
        if ([undefined, null].indexOf(filters) >= 0) {
            filterObject.subFilter.push({
                field: 'userName',
                value: '*'
            });
        } else {
            filterObject.subFilter.push(filters);
        }
        return this.webhostingUserModel.list(limit, page, filterObject, sort, owner);
    };

    public checkIfFormIsValid = (): void => {
        let userCheck = true;
        let permissionCheck = true;
        userCheck = this.metaData.user !== undefined
            && (
                [undefined, null, ''].indexOf(this.metaData.user.id) < 0
                || (
                    [undefined, null, ''].indexOf(this.metaData.user.name) < 0
                    && [undefined, null, ''].indexOf(this.metaData.user.password) < 0
                )
            );
        if (this.newAccessObject) {
        permissionCheck = ['ftpAccess', 'sshAccess', 'statsAccess', 'ftpLimited']
            .some((key) => {
                return ([undefined, null, '', false].indexOf(this.newAccessObject[key]) < 0)
            });
        } else {
            permissionCheck = false;
        }
        this.isValid = userCheck && permissionCheck;
    };

    public ftpLimitedAccess = (accessType: string) => {
        if (accessType === 'ftpAccess') {
            this.disabledAccessLevels = [];

            if (this.newAccessObject.ftpAccess === true) {
                this.disabledAccessLevels.push('ftpLimited');
                this.newAccessObject.ftpLimited = false;
            }
        }

        if (accessType === 'ftpLimited') {
            this.disabledAccessLevels = [];

            if (this.newAccessObject.ftpLimited === true) {
                this.disabledAccessLevels.push('ftpAccess');
                this.disabledAccessLevels.push('sshAccess');
                this.newAccessObject.ftpAccess = false;
                this.newAccessObject.sshAccess = false;
            }
        }

        this.checkIfFormIsValid();
    };

    public isDisabledAccessType(accessType: string): boolean {
        return this.disabledAccessLevels.indexOf(accessType) >= 0;
    }

    public selectExistingUser = (value: string): void => {
        this.metaData.user.id = value;
        this.checkIfFormIsValid();
    };
}

export class OrganismWebspaceWizardProductUserConfigComponent implements ng.IComponentOptions {
    public bindings = {
        metaData: '='
    };

    public template = require('./webspace-wizard-products-user-config.html');
    public controller = OrganismWebspaceWizardProductUserConfigController;
}
