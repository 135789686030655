import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateWebhostingJobDetailsController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;
    public job: any;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'webhosting.webspaces.jobs',
            backwardText: this.$translate.instant('TR_210219-86c3ed_TR'),
            panelHeaderRoute: '',
            panelIcon: 'server',
            panelTitle: this.$translate.instant('TR_210219-60828a_TR', { jobName: this.job.displayName })
        };
    };
}

export class TemplateWebhostingJobDetailsComponent implements ng.IComponentOptions {
    public bindings = {
        job: '<'
    };
    public template = require('./webhosting-job-details-template.html');
    public controller = TemplateWebhostingJobDetailsController;
}
