import * as ng from 'angular';
import { AlertManagerService } from '../../../../../../services';
import { OrganismOverviewController } from '../../../../../organisms/overview/overview';

export class MoleculePanelOverviewHeaderMultiCancelDeleteActionController implements ng.IController {
    public static $inject: string[] = ['alertManager', '$translate'];

    public OrganismOverview: OrganismOverviewController;
    public confirmHint = false;
    private serviceName: string;

    constructor(
        private alertManager: AlertManagerService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.getTemplate();
    }

    public abort = () => {
        this.OrganismOverview.overviewModel.activeMassAction = false;
    };

    public submit = () => {
        this.OrganismOverview.submitMassAction({methode: 'cancelDeleteSubmit'})
            .then((result) => {
                if (result) {
                    this.alertManager.success(this.$translate.instant('TR_100119-b4c753_TR'));
                    this.abort();
                    this.OrganismOverview.onLoad();
                }
            }, (e) => {
                console.error('error', e);
            });
    };

    // tslint:disable-next-line:max-line-length
    public getTemplate = () => require('./multi-cancel-delete-' + this.serviceName + '-action.html?external');
}

export class MoleculePanelOverviewHeaderMultiCancelDeleteActionComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        serviceName: '<'
    };
    public controller =  MoleculePanelOverviewHeaderMultiCancelDeleteActionController;
    public template = require('./multi-cancel-delete-action.html');
}
