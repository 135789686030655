import * as ng from 'angular';

export class MoleculePanelShowTwoFAPasscodesContentController implements ng.IController {
    public twoFAData: any;

    public get otpList() {
        return this.twoFAData.passcodes.join('\n');
    }
}

export class MoleculePanelShowTwoFAPasscodesContentComponent implements ng.IComponentOptions {
    public bindings = {
        twoFAData: '='
    };

    public controller = MoleculePanelShowTwoFAPasscodesContentController;
    public template = require('./panel-show-passcodes-content.html');
}
