/* eslint-disable max-len */

import './scripts/app';

// new imports for atomic design components
import * as Atomic from './atomic-components';
import * as Components from './components/index';
import * as Filters from './filters/index';
import * as Development from './scripts/development/index';
import * as Services from './services/index';

import * as Models from './directives/models';
import * as Permissions from './directives/premissions';

import * as ng from 'angular';

// ===========
// SHOW ERRORS
// ===========

import { SheriffDirective, ShowErrorsDirective, TrackErrorDirective } from './directives/show-errors';

const showErrors = ng.module('ui.bootstrap.showErrors', []);

showErrors.directive('sherrif', SheriffDirective.Factory());
showErrors.directive('showErrors', ShowErrorsDirective.Factory());
showErrors.directive('trackError', TrackErrorDirective.Factory());

const app = ng.module('robotInterfaceApp');

// ========
// PACKAGES
// ========

app.component('faIcon', new Atomic.FaIconComponent());

// ===========
// PLATFORM UI
// ===========

//  █████╗ ████████╗ ██████╗ ███╗   ███╗███████╗
// ██╔══██╗╚══██╔══╝██╔═══██╗████╗ ████║██╔════╝
// ███████║   ██║   ██║   ██║██╔████╔██║███████╗
// ██╔══██║   ██║   ██║   ██║██║╚██╔╝██║╚════██║
// ██║  ██║   ██║   ╚██████╔╝██║ ╚═╝ ██║███████║
// ╚═╝  ╚═╝   ╚═╝    ╚═════╝ ╚═╝     ╚═╝╚══════╝

app.component('atomAccountName', new Atomic.AtomAccountNameComponent());
app.component('atomChartBar', new Atomic.AtomChartBarComponent());
app.component('atomCircle', new Atomic.AtomCircleComponent());
app.component('atomCircleValue', new Atomic.AtomCircleValueComponent());
app.component('atomFactSheet', new Atomic.AtomFactSheetComponent());
app.component('atomFormCheckboxToggle', new Atomic.AtomFormCheckboxToggleComponent());
app.component('atomFormInputNumber', new Atomic.AtomFormInputNumberComponent());
app.component('atomFormInputText', new Atomic.AtomFormInputTextComponent());
app.component('atomFormInputTextarea', new Atomic.AtomFormInputTextareaComponent());
app.component('atomFormInputTextVoucher', new Atomic.AtomFormInputTextVoucherComponent());
app.component('atomFormValidationError', new Atomic.AtomFormValidationErrorComponent());
app.component('atomHtml', new Atomic.AtomHtmlComponent());
app.component('atomLogoMain', new Atomic.AtomLogoMainComponent());
app.component('atomLogoutAs', new Atomic.AtomLogoutAsComponent());
app.component('atomPuiWithVersion', new Atomic.AtomPuiWithVersionComponent());
app.component('atomRequired', new Atomic.AtomRequiredComponent());
app.component('atomText', new Atomic.AtomTextComponent());
app.component('atomTextBox', new Atomic.AtomTextBoxComponent());
app.component('atomTextBubble', new Atomic.AtomTextBubbleComponent());
app.component('atomTextChip', new Atomic.AtomTextChipComponent());
app.component('atomTextEdit', new Atomic.AtomTextEditComponent());
app.component('atomTextSave', new Atomic.AtomTextSaveComponent());
app.component('atomTextShowAll', new Atomic.AtomTextShowAllComponent());
app.component('atomTextShowDetails', new Atomic.AtomTextShowDetailsComponent());

// ███╗   ███╗ ██████╗ ██╗     ███████╗ ██████╗██╗   ██╗██╗     ███████╗███████╗
// ████╗ ████║██╔═══██╗██║     ██╔════╝██╔════╝██║   ██║██║     ██╔════╝██╔════╝
// ██╔████╔██║██║   ██║██║     █████╗  ██║     ██║   ██║██║     █████╗  ███████╗
// ██║╚██╔╝██║██║   ██║██║     ██╔══╝  ██║     ██║   ██║██║     ██╔══╝  ╚════██║
// ██║ ╚═╝ ██║╚██████╔╝███████╗███████╗╚██████╗╚██████╔╝███████╗███████╗███████║
// ╚═╝     ╚═╝ ╚═════╝ ╚══════╝╚══════╝ ╚═════╝ ╚═════╝ ╚══════╝╚══════╝╚══════╝

app.component('moleculeAccordion', new Atomic.MoleculeAccordionComponent());
app.component('moleculeAsteriskNoteStarMarker', new Atomic.MoleculeAsteriskNoteStarMarkerComponent());
app.component('moleculeAsteriskOutput', new Atomic.MoleculeAsteriskOutputComponent());
app.component('moleculeBreadcrumb', new Atomic.MoleculeBreadcrumbComponent());
app.component('moleculeButton', new Atomic.MoleculeButtonComponent());
app.component('moleculeButtonAbort', new Atomic.MoleculeButtonAbortComponent());
app.component('moleculeButtonActivate', new Atomic.MoleculeButtonActivateComponent());
app.component('moleculeButtonAdd', new Atomic.MoleculeButtonAddComponent());
app.component('moleculeButtonBlue', new Atomic.MoleculeButtonBlueComponent());
app.component('moleculeButtonCancel', new Atomic.MoleculeButtonCancelComponent());
app.component('moleculeButtonCancelDeletion', new Atomic.MoleculeButtonCancelDeletionComponent());
app.component('moleculeButtonConfirm', new Atomic.MoleculeButtonConfirmComponent());
app.component('moleculeButtonCopy', new Atomic.MoleculeButtonCopyComponent());
app.component('moleculeButtonDeactivate', new Atomic.MoleculeButtonDeactivateComponent());
app.component('moleculeButtonDelete', new Atomic.MoleculeButtonDeleteComponent());
app.component('moleculeButtonDown', new Atomic.MoleculeButtonDownComponent());
app.component('moleculeButtonDownloadCsv', new Atomic.MoleculeButtonDownloadCsvComponent());
app.component('moleculeButtonDownloadPdf', new Atomic.MoleculeButtonDownloadPdfComponent());
app.component('moleculeButtonDropdown', new Atomic.MoleculeButtonDropdownComponent());
app.component('moleculeButtonEdit', new Atomic.MoleculeButtonEditComponent());
app.component('moleculeButtonFancyFilter', new Atomic.MoleculeButtonFancyFilterComponent());
app.component('moleculeButtonLock', new Atomic.MoleculeButtonLockComponent());
app.component('moleculeButtonLogin', new Atomic.MoleculeButtonLoginComponent());
app.component('moleculeButtonMenu', new Atomic.MoleculeButtonMenuComponent());
app.component('moleculeButtonNextStep', new Atomic.MoleculeButtonNextStepComponent());
app.component('moleculeButtonPayNow', new Atomic.MoleculeButtonPayNowComponent());
app.component('moleculeButtonReissue', new Atomic.MoleculeButtonReissueComponent());
app.component('moleculeButtonRemove', new Atomic.MoleculeButtonRemoveComponent());
app.component('moleculeButtonRestore', new Atomic.MoleculeButtonRestoreComponent());
app.component('moleculeButtonRevoke', new Atomic.MoleculeButtonRevokeComponent());
app.component('moleculeButtonSave', new Atomic.MoleculeButtonSaveComponent());
app.component('moleculeButtonSearch', new Atomic.MoleculeButtonSearchComponent());
app.component('moleculeButtonStatechange', new Atomic.MoleculeButtonStatechangeComponent());
app.component('moleculeButtonTextCopy', new Atomic.MoleculeButtonTextCopyComponent());
app.component('moleculeButtonTextDownload', new Atomic.MoleculeButtonTextDownloadComponent());
app.component('moleculeButtonUndo', new Atomic.MoleculeButtonUndoComponent());
app.component('moleculeButtonUnlock', new Atomic.MoleculeButtonUnlockComponent());
app.component('moleculeButtonUp', new Atomic.MoleculeButtonUpComponent());
app.component('moleculeButtonUserAction', new Atomic.MoleculeButtonUserActionComponent());
app.component('moleculeChartBundleRestrictionOverviewRow', new Atomic.MoleculeChartBundleRestrictionOverviewRowComponent());
app.component('moleculeChartDatabaseRestrictionOverviewRow', new Atomic.MoleculeChartDatabaseRestrictionOverviewRowComponent());
app.component('moleculeChartDomainRestrictionOverviewRow', new Atomic.MoleculeChartDomainRestrictionOverviewRowComponent());
app.component('moleculeChartEmailRestrictionOverviewRow', new Atomic.MoleculeChartEmailRestrictionOverviewRowComponent());
app.component('moleculeChartUtilityStatus', new Atomic.MoleculeChartUtilityStatusComponent());
app.component('moleculeChartWebspaceRestrictionOverviewRow', new Atomic.MoleculeChartWebspaceRestrictionOverviewRowComponent());
app.component('moleculeDisplayBundleContractDetails', new Atomic.OrganismDisplayBundleContractDetailsComponent());
app.component('moleculeDisplayOutstandingPriceInvoice', new Atomic.MoleculeDisplayOutstandingPriceInvoiceComponent());
app.component('moleculeDisplayPriceInvoice', new Atomic.MoleculeDisplayPriceInvoiceComponent());
app.component('moleculeDomainContactCreateEditModal', new Atomic.MoleculeDomainContactCreateEditModalComponent());
app.component('moleculeDomainContactInfoModal', new Atomic.MoleculeDomainContactInfoModalComponent());
app.component('moleculeDomainContactSearchModal', new Atomic.MoleculeDomainContactSearchModalComponent());
app.component('moleculeFancyFilterBar', new Atomic.MoleculeFancyFilterBarComponent());
app.component('moleculeFancyFilterFields', new Atomic.MoleculeFancyFilterFieldsComponent());
app.component('moleculeFormAccountSelect', new Atomic.MoleculeFormAccountSelectComponent());
app.component('moleculeFormAddTextToList', new Atomic.MoleculeFormAddTextToListComponent());
app.component('moleculeFormAppSelector', new Atomic.MoleculeFormAppSelectorComponent());
app.component('moleculeFormCheckbox', new Atomic.MoleculeFormCheckboxComponent());
app.component('moleculeFormCheckboxRequired', new Atomic.MoleculeFormCheckboxRequiredComponent());
app.component('moleculeFormContactSelect', new Atomic.MoleculeFormContactSelectComponent());
app.component('moleculeFormCronjobScript', new Atomic.MoleculeFormCronjobScriptComponent());
app.component('moleculeFormDateRangeSelect', new Atomic.MoleculeFormDateRangeSelectComponent());
app.component('moleculeFormDateRangeSelectRow', new Atomic.MoleculeFormDateRangeSelectRowComponent());
app.component('moleculeFormDateSelect', new Atomic.MoleculeFormDateSelectComponent());
app.component('moleculeFormDateSelectFancy', new Atomic.MoleculeFormDateSelectFancyComponent());
app.component('moleculeFormDdnsUserSelect', new Atomic.MoleculeFormDdnsUserSelectComponent());
app.component('moleculeFormDomainNameSelector', new Atomic.MoleculeFormDomainNameSelectorComponent());
app.component('moleculeFormDropDown', new Atomic.MoleculeFormDropDownComponent());
app.component('moleculeFormDropDownApiSearch', new Atomic.MoleculeFormDropDownApiSearchComponent());
app.component('moleculeFormDropDownBreadcrumb', new Atomic.MoleculeFormDropDownBreadcrumbComponent());
app.component('moleculeFormDropDownCountries', new Atomic.MoleculeFormDropDownCountriesComponent());
app.component('moleculeFormDropDownGroups', new Atomic.MoleculeFormDropDownGroupsComponent());
app.component('moleculeFormDropDownMenu', new Atomic.MoleculeFormDropDownMenuComponent());
app.component('moleculeFormDropDownRevised', new Atomic.MoleculeFormDropDownRevisedComponent());
app.component('moleculeFormDropDownRevisedEmail', new Atomic.MoleculeFormDropDownRevisedEmailComponent());
app.component('moleculeFormDropDownRevisedMulti', new Atomic.MoleculeFormDropDownRevisedMultiComponent());
app.component('moleculeFormDropDownRevisedSubdomain', new Atomic.MoleculeFormDropDownRevisedSubdomainComponent());
app.component('moleculeFormDropDownTextInput', new Atomic.MoleculeFormDropDownTextInputComponent());
app.component('moleculeFormDropDownWithFilter', new Atomic.MoleculeFormDropDownWithFilterComponent());
app.component('moleculeFormEdit', new Atomic.MoleculeFormEditComponent());
app.component('moleculeFormEditBridge', new Atomic.MoleculeFormEditBridgeComponent())
app.component('moleculeFormFamilyBox', new Atomic.MoleculeFormFamilyBoxComponent());
app.component('moleculeFormHomedir', new Atomic.MoleculeFormHomedirComponent());
app.component('moleculeFormItemBoxNewGeneric', new Atomic.MoleculeFormItemBoxNewGenericComponent());
app.component('moleculeFormItemSelectNewGeneric', new Atomic.MoleculeFormItemSelectNewGenericComponent());
app.component('moleculeFormOverlayOnSubmit', new Atomic.MoleculeFormOverlayOnSubmitComponent());
app.component('moleculeFormPhpBoostChanger', new Atomic.MoleculeFormPhpBoostChangerComponent());
app.component('moleculeFormProductBox', new Atomic.MoleculeFormProductBoxComponent());
app.component('moleculeFormProductBoxNew', new Atomic.MoleculeFormProductBoxNewComponent());
app.component('moleculeFormProductDomainPrice', new Atomic.MoleculeFormProductPriceDomainComponent());
app.component('moleculeFormProductPrice', new Atomic.MoleculeFormProductPriceComponent());
app.component('moleculeFormProductSelect', new Atomic.MoleculeFormProductSelectComponent());
app.component('moleculeFormProductSelectNew', new Atomic.MoleculeFormProductSelectNewComponent());
app.component('moleculeFormPromoProductBox', new Atomic.MoleculeFormPromoProductBoxComponent());
app.component('moleculeFormRadioInput', new Atomic.MoleculeFormRadioInputComponent());
app.component('moleculeFormRightsCheckbox', new Atomic.MoleculeFormRightsCheckboxComponent());
app.component('moleculeFormRightsToggle', new Atomic.MoleculeFormRightsToggleComponent());
app.component('moleculeFormSpamProtection', new Atomic.MoleculeFormSpamProtectionComponent());
app.component('moleculeFormStorageQuotaChanger', new Atomic.MoleculeFormStorageQuotaChangerComponent());
app.component('moleculeFormStorageQuotaDisplay', new Atomic.MoleculeFormStorageQuotaDisplayComponent());
app.component('moleculeGlobalSearchResult', new Atomic.MoleculeGlobalSearchResultComponent());
app.component('moleculeHeadlineBanner', new Atomic.MoleculeHeadlineBannerComponent());
app.component('moleculeHeadlineIconAndText', new Atomic.MoleculeHeadlineIconAndTextComponent());
app.component('moleculeHelpdeskSearch', new Atomic.MoleculeHelpdeskSearchComponent());
app.component('moleculeHelpdeskSearchResults', new Atomic.MoleculeHelpdeskSearchResultsComponent());
app.component('moleculeInformationDnsAdjustments', new Atomic.MoleculeInformationDnsAdjustmentsComponent());
app.component('moleculeInformationEmailAdjustments', new Atomic.MoleculeInformationEmailAdjustmentsComponent());
app.component('moleculeInformationNode', new Atomic.MoleculeInformationNodeComponent());
app.component('moleculeInformationRequiredRecordChangeList', new Atomic.MoleculeInformationRequiredRecordChangeListComponent());
app.component('moleculeInformationSslManualValidation', new Atomic.MoleculeInformationSslManualValidationComponent());
app.component('moleculeInput2faSeparated', new Atomic.MoleculeInput2faSeparatedComponent());
app.component('moleculeInputIpTextarea', new Atomic.MoleculeInputIpTextareaComponent());
app.component('moleculeInputNumber', new Atomic.MoleculeInputNumberComponent());
app.component('moleculeInputTextCaa', new Atomic.MoleculeInputTextCaaComponent());
app.component('moleculeInputTextCronjobScript', new Atomic.MoleculeInputTextCronjobScriptComponent());
app.component('moleculeInputTextDns', new Atomic.MoleculeInputTextDnsComponent());
app.component('moleculeInputTextDnsRecordContent', new Atomic.MoleculeInputTextDnsRecordContentComponent());
app.component('moleculeInputTextDnsRecordName', new Atomic.MoleculeInputTextDnsRecordNameComponent());
app.component('moleculeInputTextDnsRecordPriority', new Atomic.MoleculeInputTextDnsRecordPriorityComponent());
app.component('moleculeInputTextEmail', new Atomic.MoleculeInputTextEmailComponent());
app.component('moleculeInputTextFqdn', new Atomic.MoleculeInputTextFqdnComponent());
app.component('moleculeInputTextIban', new Atomic.MoleculeInputTextIbanComponent());
app.component('moleculeInputTextIp', new Atomic.MoleculeInputTextIpComponent());
app.component('moleculeInputTextIpv4', new Atomic.MoleculeInputTextIpv4Component());
app.component('moleculeInputTextIpv6', new Atomic.MoleculeInputTextIpv6Component());
app.component('moleculeInputTextNewPassword', new Atomic.MoleculeInputTextNewPasswordComponent());
app.component('moleculeInputTextNextcloudUser', new Atomic.MoleculeInputTextNextcloudUserComponent());
app.component('moleculeInputTextNotEmpty', new Atomic.MoleculeInputTextNotEmptyComponent());
app.component('moleculeInputTextNumber', new Atomic.MoleculeInputTextNumberComponent());
app.component('moleculeInputTextOldPassword', new Atomic.MoleculeInputTextOldPasswordComponent());
app.component('moleculeInputTextPasswordRepeat', new Atomic.MoleculeInputTextPasswordRepeatComponent());
app.component('moleculeInputTextPhoneNumber', new Atomic.MoleculeInputTextPhoneNumberComponent());
app.component('moleculeInputTextSrv', new Atomic.MoleculeInputTextSrvComponent());
app.component('moleculeInputTextSubdomain', new Atomic.MoleculeInputTextSubdomainComponent());
app.component('moleculeInputTextTlsa', new Atomic.MoleculeInputTextTlsaComponent());
app.component('moleculeInputTextUrl', new Atomic.MoleculeInputTextUrlComponent());
app.component('moleculeInputTextVoucher', new Atomic.MoleculeInputTextVoucherComponent());
app.component('moleculeInputTextWithValidation', new Atomic.MoleculeInputTextWithValidationComponent());
app.component('moleculeInputWithPrefixSelection', new Atomic.MoleculeInputWithPrefixSelectionComponent());
app.component('moleculeLightbox', new Atomic.MoleculeLightboxComponent());
app.component('moleculeLinkDashboardSearchResults', new Atomic.MoleculeLinkDashboardSearchResultsComponent());
app.component('moleculeLinkHtmlWithIcon', new Atomic.MoleculeLinkHtmlWithIconComponent());
app.component('moleculeLinkList', new Atomic.MoleculeLinkListComponent());
app.component('moleculeLinkListGroupsOverview', new Atomic.MoleculeLinkListGroupsOverviewComponent());
app.component('moleculeLinkListOverview', new Atomic.MoleculeLinkListOverviewComponent());
app.component('moleculeLinkRefresh', new Atomic.MoleculeLinkRefreshComponent());
app.component('moleculeLinkSubaccountLogin', new Atomic.MoleculeLinkSubaccountLoginComponent());
app.component('moleculeLinkWithIcon', new Atomic.MoleculeLinkWithIconComponent());
app.component('moleculeListsAvailableDomainsList', new Atomic.MoleculeListsAvailableDomainsListComponent());
app.component('moleculeListsDomainLocationsList', new Atomic.MoleculeListsDomainLocationsListComponent());
app.component('moleculeListsDomainLocationsListItem', new Atomic.MoleculeListsDomainLocationsListItemComponent());
app.component('moleculeListsDomainLocationsListItemOptions', new Atomic.MoleculeListsDomainLocationsListItemOptionsComponent());
app.component('moleculeListsErrorList', new Atomic.MoleculeListsErrorListComponent());
app.component('moleculeListsForwarderList', new Atomic.MoleculeListsForwarderListComponent());
app.component('moleculeListsValidationErrorList', new Atomic.MoleculeListsValidationErrorListComponent());
app.component('moleculeMenuMain', new Atomic.MoleculeMenuMainComponent());
app.component('moleculeMenuProductAction', new Atomic.MoleculeMenuProductActionComponent());
app.component('moleculeOverlayModal', new Atomic.MoleculeOverlayModalComponent());
app.component('moleculeOverviewHeaderSortable', new Atomic.MoleculeOverviewHeaderSortableComponent());
app.component('moleculeOverviewPricelistRowPanelChanges', new Atomic.MoleculeOverviewPricelistRowPanelChangesComponent());
app.component('moleculeOverviewPricelistRowPanelDatabases', new Atomic.MoleculeOverviewPricelistRowPanelDatabasesComponent());
app.component('moleculeOverviewPricelistRowPanelDns', new Atomic.MoleculeOverviewPricelistRowPanelDnsComponent());
app.component('moleculeOverviewPricelistRowPanelDomains', new Atomic.MoleculeOverviewPricelistRowPanelDomainsComponent());
app.component('moleculeOverviewPricelistRowPanelMachines', new Atomic.MoleculeOverviewPricelistRowPanelMachinesComponent());
app.component('moleculeOverviewPricelistRowPanelMailboxes', new Atomic.MoleculeOverviewPricelistRowPanelMailboxesComponent());
app.component('moleculeOverviewPricelistRowPanelPromotions', new Atomic.MoleculeOverviewPricelistRowPanelPromotionsComponent());
app.component('moleculeOverviewPricelistRowPanelSsl', new Atomic.MoleculeOverviewPricelistRowPanelSslComponent());
app.component('moleculeOverviewPricelistRowPanelStorageProduct', new Atomic.MoleculeOverviewPricelistRowPanelStorageProductComponent());
app.component('moleculeOverviewPricelistRowPanelWebhosting', new Atomic.MoleculeOverviewPricelistRowPanelWebhostingComponent());
app.component('moleculeOverviewRowPanelApiKeys', new Atomic.MoleculeOverviewRowPanelApiKeysComponent());
app.component('moleculeOverviewRowPanelApplication', new Atomic.MoleculeOverviewRowPanelApplicationComponent());
app.component('moleculeOverviewRowPanelBillingInvoices', new Atomic.MoleculeOverviewRowPanelBillingInvoiceComponent());
app.component('moleculeOverviewRowPanelBillingPayment', new Atomic.MoleculeOverviewRowPanelBillingPaymentComponent());
app.component('moleculeOverviewRowPanelBundle', new Atomic.MoleculeOverviewRowPanelBundleComponent());
app.component('moleculeOverviewRowPanelCronjob', new Atomic.MoleculeOverviewRowPanelCronjobComponent());
app.component('moleculeOverviewRowPanelDashboardBundles', new Atomic.MoleculeOverviewRowPanelDashboardBundlesComponent());
app.component('moleculeOverviewRowPanelDashboardDatabase', new Atomic.MoleculeOverviewRowPanelDashboardDatabaseComponent());
app.component('moleculeOverviewRowPanelDashboardDnsZones', new Atomic.MoleculeOverviewRowPanelDashboardDnsZonesComponent());
app.component('moleculeOverviewRowPanelDashboardDomainContactVerifications', new Atomic.MoleculeOverviewRowPanelDashboardContactsVerificationComponent());
app.component('moleculeOverviewRowPanelDashboardDomains', new Atomic.MoleculeOverviewRowPanelDashboardDomainsComponent());
app.component('moleculeOverviewRowPanelDashboardDomainTransfers', new Atomic.MoleculeOverviewRowPanelDashboardDomainTransferComponent());
app.component('moleculeOverviewRowPanelDashboardMachines', new Atomic.MoleculeOverviewRowPanelDashboardMachinesComponent());
app.component('moleculeOverviewRowPanelDashboardMailboxes', new Atomic.MoleculeOverviewRowPanelDashboardMailboxesComponent());
app.component('moleculeOverviewRowPanelDashboardManagedMachines', new Atomic.MoleculeOverviewRowPanelDashboardManagedMachinesComponent());
app.component('moleculeOverviewRowPanelDashboardNetworks', new Atomic.MoleculeOverviewRowPanelDashboardNetworksComponent());
app.component('moleculeOverviewRowPanelDashboardPanelWebspace', new Atomic.MoleculeOverviewRowPanelDashboardPanelWebspaceComponent());
app.component('moleculeOverviewRowPanelDashboardSslCertificates', new Atomic.MoleculeOverviewRowPanelDashboardSslCertificatesComponent());
app.component('moleculeOverviewRowPanelDashboardStorage', new Atomic.MoleculeOverviewRowPanelDashboardStorageComponent());
app.component('moleculeOverviewRowPanelDashboardVhosts', new Atomic.MoleculeOverviewRowPanelDashboardVhostsComponent());
app.component('moleculeOverviewRowPanelDashboardWebspaces', new Atomic.MoleculeOverviewRowPanelDashboardWebspacesComponent());
app.component('moleculeOverviewRowPanelDatabase', new Atomic.MoleculeOverviewRowPanelDatabaseComponent());
app.component('moleculeOverviewRowPanelDatabaseJob', new Atomic.MoleculeOverviewRowPanelDatabaseJobComponent());
app.component('moleculeOverviewRowPanelDatabaseUser', new Atomic.MoleculeOverviewRowPanelDatabaseUserComponent());
app.component('moleculeOverviewRowPanelDdnsCredentials', new Atomic.MoleculeOverviewRowPanelDdnsCredentialsComponent());
app.component('moleculeOverviewRowPanelDdnsHostnames', new Atomic.MoleculeOverviewRowPanelDdnsHostnamesComponent());
app.component('moleculeOverviewRowPanelDnsJob', new Atomic.MoleculeOverviewRowPanelDnsJobComponent());
app.component('moleculeOverviewRowPanelDnsTemplate', new Atomic.MoleculeOverviewRowPanelDnsTemplateComponent());
app.component('moleculeOverviewRowPanelDnsZones', new Atomic.MoleculeOverviewRowPanelDnsZonesComponent());
app.component('moleculeOverviewRowPanelDomain', new Atomic.MoleculeOverviewRowPanelDomainComponent());
app.component('moleculeOverviewRowPanelDomainContact', new Atomic.MoleculeOverviewRowPanelDomainContactComponent());
app.component('moleculeOverviewRowPanelDomainContactVerification', new Atomic.MoleculeOverviewRowPanelDomainContactVerificationComponent());
app.component('moleculeOverviewRowPanelDomainJob', new Atomic.MoleculeOverviewRowPanelDomainJobComponent());
app.component('moleculeOverviewRowPanelDomainProduct', new Atomic.MoleculeOverviewRowPanelDomainProductComponent());
app.component('moleculeOverviewRowPanelDomainTransfer', new Atomic.MoleculeOverviewRowPanelDomainTransferComponent());
app.component('moleculeOverviewRowPanelEmail', new Atomic.MoleculeOverviewRowPanelEmailComponent());
app.component('moleculeOverviewRowPanelEmailJob', new Atomic.MoleculeOverviewRowPanelEmailJobComponent());
app.component('moleculeOverviewRowPanelExchangeOrganization', new Atomic.MoleculeOverviewRowPanelExchangeOrganizationComponent());
app.component('moleculeOverviewRowPanelMachine', new Atomic.MoleculeOverviewRowPanelMachineComponent());
app.component('moleculeOverviewRowPanelMachineJob', new Atomic.MoleculeOverviewRowPanelMachineJobComponent());
app.component('moleculeOverviewRowPanelMachinePool', new Atomic.MoleculeOverviewRowPanelMachinePoolComponent());
app.component('moleculeOverviewRowPanelNameserverSet', new Atomic.MoleculeOverviewRowPanelNameserverSetComponent());
app.component('moleculeOverviewRowPanelNetwork', new Atomic.MoleculeOverviewRowPanelNetworkComponent());
app.component('moleculeOverviewRowPanelOverlay', new Atomic.MoleculeOverviewRowPanelOverlayComponent());
app.component('moleculeOverviewRowPanelRedirection', new Atomic.MoleculeOverviewRowPanelRedirectionComponent());
app.component('moleculeOverviewRowPanelRightsTemplate', new Atomic.MoleculeOverviewRowPanelRightsTemplateComponent());
app.component('moleculeOverviewRowPanelSslCertificate', new Atomic.MoleculeOverviewRowPanelSslCertificateComponent());
app.component('moleculeOverviewRowPanelSslJob', new Atomic.MoleculeOverviewRowPanelSslJobComponent());
app.component('moleculeOverviewRowPanelStorage', new Atomic.MoleculeOverviewRowPanelStorageComponent());
app.component('moleculeOverviewRowPanelStorageJob', new Atomic.MoleculeOverviewRowPanelStorageJobComponent());
app.component('moleculeOverviewRowPanelSubAccount', new Atomic.MoleculeOverviewRowPanelSubAccountComponent());
app.component('moleculeOverviewRowPanelUser', new Atomic.MoleculeOverviewRowPanelUserComponent());
app.component('moleculeOverviewRowPanelWebhostingJobs', new Atomic.MoleculeOverviewRowPanelWebhostingJobsComponent());
app.component('moleculeOverviewRowPanelWebhostingUser', new Atomic.MoleculeOverviewRowPanelWebhostingUserComponent());
app.component('moleculeOverviewRowPanelWebhostingVhosts', new Atomic.MoleculeOverviewRowPanelWebhostingVhostsComponent());
app.component('moleculeOverviewRowPanelWebhostingVhostsPhpLegacy', new Atomic.MoleculeOverviewRowPanelWebhostingVhostsPhpLegacyComponent());
app.component('moleculeOverviewRowPanelWebhostingWebspaces', new Atomic.MoleculeOverviewRowPanelWebhostingWebspacesComponent());
app.component('moleculePagination', new Atomic.MoleculePaginationComponent());
app.component('moleculePanelAction', new Atomic.MoleculePanelActionComponent());
app.component('moleculePanelAdditionalActions', new Atomic.MoleculePanelAdditionalActionsComponent());
app.component('moleculePanelAdditionalLinks', new Atomic.MoleculePanelAdditionalLinksComponent());
app.component('moleculePanelAppInstall', new Atomic.MoleculePanelAppInstallComponent());
app.component('moleculePanelAppInstallConfirmation', new Atomic.MoleculePanelAppInstallConfirmationComponent());
app.component('moleculePanelAvvPrivacyNotifier', new Atomic.MoleculePanelAvvPrivacyNotifierComponent());
app.component('moleculePanelBundleDashboard', new Atomic.MoleculePanelBundleDashboardComponent());
app.component('moleculePanelBundleDashboardDomainList', new Atomic.MoleculePanelBundleDashboardDomainListComponent());
app.component('moleculePanelBundleEmptyDashboard', new Atomic.MoleculePanelBundleEmptyDashboardComponent());
app.component('moleculePanelCallout', new Atomic.MoleculePanelCalloutComponent());
app.component('moleculePanelCreateBackup', new Atomic.MoleculePanelCreateBackupComponent());
app.component('moleculePanelCreateBundleBackup', new Atomic.MoleculePanelCreateBundleBackupComponent());
app.component('moleculePanelCreateDatabaseAccess', new Atomic.MoleculePanelCreateDatabaseAccessComponent());
app.component('moleculePanelCreateDnsRecord', new Atomic.MoleculePanelCreateDnsRecordComponent());
app.component('moleculePanelCreateLocation', new Atomic.MoleculePanelCreateLocationComponent());
app.component('moleculePanelCreateNextcloudGroupFolder', new Atomic.MoleculePanelCreateNextcloudGroupFolderComponent());
app.component('moleculePanelCreateTwoFA', new Atomic.MoleculePanelCreateTwoFAComponent());
app.component('moleculePanelCreateTwoFAContent', new Atomic.MoleculePanelCreateTwoFAContentComponent());
app.component('moleculePanelCreateUser', new Atomic.MoleculePanelCreateUserComponent());
app.component('moleculePanelCreateUserAccess', new Atomic.MoleculePanelCreateUserAccessComponent());
app.component('moleculePanelCreateWebspaceAccess', new Atomic.MoleculePanelCreateWebspaceAccessComponent());
app.component('moleculePanelDashboardMainHeader', new Atomic.MoleculePanelDashboardMainHeaderComponent());
app.component('moleculePanelDashboardOverviewHeader', new Atomic.MoleculePanelDashboardOverviewHeaderComponent());
app.component('moleculePanelDatabaseWipe', new Atomic.MoleculePanelDatabaseWipeComponent());
app.component('moleculePanelDebug', new Atomic.MoleculePanelDebugComponent());
app.component('moleculePanelDelete', new Atomic.MoleculePanelDeleteComponent());
app.component('moleculePanelDeleteOwnAccount', new Atomic.MoleculePanelDeleteOwnAccountComponent());
app.component('moleculePanelDeleteSubaccount', new Atomic.MoleculePanelDeleteSubaccountComponent());
app.component('moleculePanelDomainCheck', new Atomic.MoleculePanelDomainCheckComponent());
app.component('moleculePanelDomainCheckSimple', new Atomic.MoleculePanelDomainCheckSimpleComponent());
app.component('moleculePanelDomainEmailList', new Atomic.MoleculePanelDomainEmailListComponent());
app.component('moleculePanelEdit', new Atomic.MoleculePanelEditComponent());
app.component('moleculePanelEditRowAccountSelect', new Atomic.MoleculePanelEditRowAccountSelectComponent());
app.component('moleculePanelEditRowAddTextToList', new Atomic.MoleculePanelEditRowAddTextToListComponent());
app.component('moleculePanelEditRowApp', new Atomic.MoleculePanelEditRowAppComponent());
app.component('moleculePanelEditRowCheckbox', new Atomic.MoleculePanelEditRowCheckboxComponent());
app.component('moleculePanelEditRowColorRgb', new Atomic.MoleculePanelEditRowColorRGBComponent());
app.component('moleculePanelEditRowContact', new Atomic.MoleculePanelEditRowContactComponent());
app.component('moleculePanelEditRowContactMove', new Atomic.MoleculePanelEditRowContactMoveComponent());
app.component('moleculePanelEditRowCountries', new Atomic.MoleculePanelEditRowCountriesComponent());
app.component('moleculePanelEditRowDatabaseSelect', new Atomic.MoleculePanelEditRowDatabaseSelectComponent());
app.component('moleculePanelEditRowDate', new Atomic.MoleculePanelEditRowDateComponent());
app.component('moleculePanelEditRowDeleteDate', new Atomic.MoleculePanelEditRowDeleteDateComponent());
app.component('moleculePanelEditRowDnsRecord', new Atomic.MoleculePanelEditRowDnsRecordComponent());
app.component('moleculePanelEditRowDnsRecordWizard', new Atomic.MoleculePanelEditRowDnsRecordWizardComponent());
app.component('moleculePanelEditRowDnsTemplateReplacements', new Atomic.MoleculePanelEditRowDnsTemplateReplacementsComponent());
app.component('moleculePanelEditRowDomainDirectoryProtectionAddPath', new Atomic.MoleculePanelEditRowDomainDirectoryProtectionAddPathComponent());
app.component('moleculePanelEditRowDomainDirectoryProtectionEditPath', new Atomic.MoleculePanelEditRowDomainDirectoryProtectionEditPathComponent());
app.component('moleculePanelEditRowDomainDirectoryProtectionEditUser', new Atomic.MoleculePanelEditRowDomainDirectoryProtectionEditUserComponent());
app.component('moleculePanelEditRowDomainMulti', new Atomic.MoleculePanelEditRowDomainMultiComponent());
app.component('moleculePanelEditRowDropdown', new Atomic.MoleculePanelEditRowDropdownComponent());
app.component('moleculePanelEditRowDropdownAdd', new Atomic.MoleculePanelEditRowDropdownAddComponent());
app.component('moleculePanelEditRowDropdownGroups', new Atomic.MoleculePanelEditRowDropdownGroupsComponent());
app.component('moleculePanelEditRowDropdownWithFilter', new Atomic.MoleculePanelEditRowDropdownWithFilterComponent());
app.component('moleculePanelEditRowEmailAddressList', new Atomic.MoleculePanelEditRowEmailAddressListComponent());
app.component('moleculePanelEditRowEmailAddressListRevised', new Atomic.MoleculePanelEditRowEmailAddressListRevisedComponent());
app.component('moleculePanelEditRowEmailChecker', new Atomic.MoleculePanelEditRowEmailCheckerComponent());
app.component('moleculePanelEditRowEmailCheckerDns', new Atomic.MoleculePanelEditRowEmailCheckerDnsComponent());
app.component('moleculePanelEditRowFile', new Atomic.MoleculePanelEditRowFileComponent());
app.component('moleculePanelEditRowHeadline', new Atomic.MoleculePanelEditRowHeadlineComponent());
app.component('moleculePanelEditRowInfo', new Atomic.MoleculePanelEditRowInfoComponent());
app.component('moleculePanelEditRowMxRecords', new Atomic.MoleculePanelEditRowMxRecordsComponent());
app.component('moleculePanelEditRowNameserverAddressList', new Atomic.MoleculePanelEditRowNameserverAddressListComponent());
app.component('moleculePanelEditRowNameserverIpsRequired', new Atomic.MoleculePanelEditRowNameserverIpsRequiredComponent());
app.component('moleculePanelEditRowNameserverList', new Atomic.MoleculePanelEditRowNameserverListComponent());
app.component('moleculePanelEditRowNameserverSelect', new Atomic.MoleculePanelEditRowNameserverSelectComponent());
app.component('moleculePanelEditRowNumber', new Atomic.MoleculePanelEditRowNumberComponent());
app.component('moleculePanelEditRowPassword', new Atomic.MoleculePanelEditRowPasswordComponent());
app.component('moleculePanelEditRowPaymentMethodAvailability', new Atomic.MoleculePanelEditRowPaymentMethodAvailabilityComponent());
app.component('moleculePanelEditRowPermissionGroup', new Atomic.MoleculePanelEditRowPermissionGroupComponent());
app.component('moleculePanelEditRowPermissionMisc', new Atomic.MoleculePanelEditRowPermissionMiscComponent());
app.component('moleculePanelEditRowPermissionSubgroup', new Atomic.MoleculePanelEditRowPermissionSubgroupComponent());
app.component('moleculePanelEditRowPhpBoostChanger', new Atomic.MoleculePanelEditRowPhpBoostChangerComponent());
app.component('moleculePanelEditRowPrice', new Atomic.MoleculePanelEditRowPriceComponent());
app.component('moleculePanelEditRowRadio', new Atomic.MoleculePanelEditRowRadioComponent());
app.component('moleculePanelEditRowReadonly', new Atomic.MoleculePanelEditRowReadonlyComponent());
app.component('moleculePanelEditRowRestorePrice', new Atomic.MoleculePanelEditRowRestorePriceComponent());
app.component('moleculePanelEditRowSepa', new Atomic.MoleculePanelEditRowSepaComponent());
app.component('moleculePanelEditRowShowAll', new Atomic.MoleculePanelEditRowShowAllComponent());
app.component('moleculePanelEditRowSslHsts', new Atomic.MoleculePanelEditRowSslHstsComponent());
app.component('moleculePanelEditRowStorageQuotaChanger', new Atomic.MoleculePanelEditRowStorageQuotaChangerComponent());
app.component('moleculePanelEditRowStorageQuotaDisplay', new Atomic.MoleculePanelEditRowStorageQuotaDisplayComponent());
app.component('moleculePanelEditRowStreetZip', new Atomic.MoleculePanelEditRowStreetZipComponent());
app.component('moleculePanelEditRowString', new Atomic.MoleculePanelEditRowStringComponent());
app.component('moleculePanelEditRowSubdomainChecker', new Atomic.MoleculePanelEditRowSubdomainCheckerComponent());
app.component('moleculePanelEditRowTableBackups', new Atomic.MoleculePanelEditRowTableBackupsComponent());
app.component('moleculePanelEditRowTableBackupsConfirmRestoration', new Atomic.MoleculePanelEditRowTableBackupsConfirmRestorationComponent());
app.component('moleculePanelEditRowTableBundleBackups', new Atomic.MoleculePanelEditRowTableBundleBackupsComponent());
app.component('moleculePanelEditRowTableBundleBackupsConfirmDeletion', new Atomic.MoleculePanelEditRowTableBundleBackupsConfirmDeletionComponent());
app.component('moleculePanelEditRowTableBundleBackupsConfirmRestoration', new Atomic.MoleculePanelEditRowTableBundleBackupsConfirmRestorationComponent());
app.component('moleculePanelEditRowTableGeneric', new Atomic.MoleculePanelEditRowTableGenericComponent());
app.component('moleculePanelEditRowTableHeadline', new Atomic.MoleculePanelEditRowTableHeadlineComponent());
app.component('moleculePanelEditRowTableIpAddresses', new Atomic.MoleculePanelEditRowTableIpAddressesComponent());
app.component('moleculePanelEditRowTableNextcloudGroupFolders', new Atomic.MoleculePanelEditRowTableNextcloudGroupFoldersComponent());
app.component('moleculePanelEditRowTableNextcloudGroups', new Atomic.MoleculePanelEditRowTableNextcloudGroupsComponent());
app.component('moleculePanelEditRowTableNextcloudPlugins', new Atomic.MoleculePanelEditRowTableNextcloudPluginsComponent());
app.component('moleculePanelEditRowTableNextcloudUsers', new Atomic.MoleculePanelEditRowTableNextcloudUsersComponent());
app.component('moleculePanelEditRowTableUsers', new Atomic.MoleculePanelEditRowTableUsersComponent());
app.component('moleculePanelEditRowTableWebspaceUsers', new Atomic.MoleculePanelEditRowTableWebspaceUsersComponent());
app.component('moleculePanelEditRowTextarea', new Atomic.MoleculePanelEditRowTextareaComponent());
app.component('moleculePanelEditRowToggle', new Atomic.MoleculePanelEditRowToggleComponent());
app.component('moleculePanelEditTable', new Atomic.MoleculePanelEditTableComponent());
app.component('moleculePanelError', new Atomic.MoleculePanelErrorComponent());
app.component('moleculePanelFaq', new Atomic.MoleculePanelFaqComponent());
app.component('moleculePanelGrid', new Atomic.MoleculePanelGridComponent());
app.component('moleculePanelHeader', new Atomic.MoleculePanelHeaderComponent());
app.component('moleculePanelHeaderEdit', new Atomic.MoleculePanelHeaderEditComponent());
app.component('moleculePanelIncompleteUserProfile', new Atomic.MoleculePanelIncompleteUserProfileComponent());
app.component('moleculePanelInvoiceAddToPayTable', new Atomic.MoleculePanelInvoiceAddToPayTableComponent());
app.component('moleculePanelInvoiceInformationDetails', new Atomic.MoleculePanelInvoiceInformationDetailsComponent());
app.component('moleculePanelInvoicePaymentOptions', new Atomic.MoleculePanelInvoicePaymentOptionsComponent());
app.component('moleculePanelInvoiceSum', new Atomic.MoleculePanelInvoiceSumComponent());
app.component('moleculePanelLinkPermissionTester', new Atomic.MoleculePanelLinkPermissionTesterComponent());
app.component('moleculePanelMachineDashboard', new Atomic.MoleculePanelMachineDashboardComponent());
app.component('moleculePanelMachineEmptyDashboard', new Atomic.MoleculePanelMachineEmptyDashboardComponent());
app.component('moleculePanelManagedMachineDashboard', new Atomic.MoleculePanelManagedMachineDashboardComponent());
app.component('moleculePanelManagedNextcloudDashboard', new Atomic.MoleculePanelManagedNextcloudDashboardComponent());
app.component('moleculePanelNewProduct', new Atomic.MoleculePanelNewProductComponent());
app.component('moleculePanelNextcloudAccessHelp', new Atomic.MoleculePanelNextcloudAccessHelpComponent());
app.component('moleculePanelNextcloudDownloads', new Atomic.MoleculePanelNextcloudDownloadsComponent());
app.component('moleculePanelNextcloudStatus', new Atomic.MoleculePanelNextcloudStatusComponent());
app.component('moleculePanelOverviewHeader', new Atomic.MoleculePanelOverviewHeaderComponent());
app.component('moleculePanelOverviewHeaderMultiActivateAction', new Atomic.MoleculePanelOverviewHeaderMultiActivateActionComponent());
app.component('moleculePanelOverviewHeaderMultiCancelDeleteAction', new Atomic.MoleculePanelOverviewHeaderMultiCancelDeleteActionComponent());
app.component('moleculePanelOverviewHeaderMultiContactVerificationAction', new Atomic.MoleculePanelOverviewHeaderMultiContactVerificationActionComponent());
app.component('moleculePanelOverviewHeaderMultiDeactivateAction', new Atomic.MoleculePanelOverviewHeaderMultiDeactivateActionComponent());
app.component('moleculePanelOverviewHeaderMultiDeleteAction', new Atomic.MoleculePanelOverviewHeaderMultiDeleteActionComponent());
app.component('moleculePanelOverviewHeaderMultiDeleteRestorableAction', new Atomic.MoleculePanelOverviewHeaderMultiDeleteRestorableActionComponent());
app.component('moleculePanelOverviewHeaderMultiDomainAuthcodesAction', new Atomic.MoleculePanelOverviewHeaderMultiDomainAuthcodesActionComponent());
app.component('moleculePanelOverviewHeaderMultiDomainTransferAction', new Atomic.MoleculePanelOverviewHeaderMultiDomainTransferActionComponent());
app.component('moleculePanelOverviewHeaderMultiDownloadZoneCsvAction', new Atomic.MoleculePanelOverviewHeaderMultiDownloadZoneCsvActionComponent());
app.component('moleculePanelOverviewHeaderMultiDownloadZonefileAction', new Atomic.MoleculePanelOverviewHeaderMultiDownloadZonefileActionComponent());
app.component('moleculePanelOverviewHeaderMultiLockAction', new Atomic.MoleculePanelOverviewHeaderMultiLockActionComponent());
app.component('moleculePanelOverviewHeaderMultiMoveAction', new Atomic.MoleculePanelOverviewHeaderMultiMoveActionComponent());
app.component('moleculePanelOverviewHeaderMultiRestoreAction', new Atomic.MoleculePanelOverviewHeaderMultiRestoreActionComponent());
app.component('moleculePanelOverviewHeaderMultiTieToTemplateAction', new Atomic.MoleculePanelOverviewHeaderMultiTieToTemplateActionComponent());
app.component('moleculePanelOverviewHeaderMultiUnlockAction', new Atomic.MoleculePanelOverviewHeaderMultiUnlockActionComponent());
app.component('moleculePanelOverviewHeaderMultiUntieFromTemplateAction', new Atomic.MoleculePanelOverviewHeaderMultiUntieFromTemplateActionComponent());
app.component('moleculePanelOverviewHeaderMultiWipeAction', new Atomic.MoleculePanelOverviewHeaderMultiWipeActionComponent());
app.component('moleculePanelPrepaid', new Atomic.MoleculePanelPrepaidComponent());
app.component('moleculePanelPricelistOverviewHeader', new Atomic.MoleculePanelPricelistOverviewHeaderComponent());
app.component('moleculePanelProductSelect', new Atomic.MoleculePanelProductSelectComponent());
app.component('moleculePanelReissue', new Atomic.MoleculePanelReissueComponent());
app.component('moleculePanelRestore', new Atomic.MoleculePanelRestoreComponent());
app.component('moleculePanelRevoke', new Atomic.MoleculePanelRevokeComponent());
app.component('moleculePanelRowAccountDisplay', new Atomic.MoleculePanelRowAccountDisplayComponent());
app.component('moleculePanelRowDatabaseAccess', new Atomic.MoleculePanelRowDatabaseAccessComponent());
app.component('moleculePanelRowDatabaseAccessSettings', new Atomic.MoleculePanelRowDatabaseAccessSettingsComponent());
app.component('moleculePanelRowJobEvent', new Atomic.MoleculePanelRowJobEventComponent());
app.component('moleculePanelRowOrchestrationDatabaseAccessSettings', new Atomic.MoleculePanelRowOrchestrationDatabaseAccessSettingsComponent());
app.component('moleculePanelRowOrchestrationWebspaceAccessSettings', new Atomic.MoleculePanelRowOrchestrationWebspaceAccessSettingsComponent());
app.component('moleculePanelRowPriceSummary', new Atomic.MoleculePanelRowPriceSummaryComponent());
app.component('moleculePanelRowProductPrice', new Atomic.MoleculePanelRowProductPriceComponent());
app.component('moleculePanelRowWebspaceAccessSettings', new Atomic.MoleculePanelRowWebspaceAccessSettingsComponent());
app.component('moleculePanelSave', new Atomic.MoleculePanelSaveComponent());
app.component('moleculePanelSepaInfo', new Atomic.MoleculePanelSepaInfoComponent());
app.component('moleculePanelServiceInfo', new Atomic.MoleculePanelServiceInfoComponent());
app.component('moleculePanelServiceInfoAccount', new Atomic.MoleculePanelServiceInfoAccountComponent());
app.component('moleculePanelServiceInfoBillingInvoice', new Atomic.MoleculePanelServiceInfoBillingInvoiceComponent());
app.component('moleculePanelServiceInfoExternalRecords', new Atomic.MoleculePanelServiceInfoExternalRecordsComponent());
app.component('moleculePanelServiceInfoMailbox', new Atomic.MoleculePanelServiceInfoMailboxComponent());
app.component('moleculePanelServiceInfoZoneSystemNameserver', new Atomic.MoleculePanelServiceInfoZoneSystemNameserverComponent());
app.component('moleculePanelServiceObject', new Atomic.MoleculePanelServiceObjectComponent());
app.component('moleculePanelShowAll', new Atomic.MoleculePanelShowAllComponent());
app.component('moleculePanelShowTwoFAPasscodes', new Atomic.MoleculePanelShowTwoFAPasscodesComponent());
app.component('moleculePanelShowTwoFAPasscodesContent', new Atomic.MoleculePanelShowTwoFAPasscodesContentComponent());
app.component('moleculePanelSmsValidation', new Atomic.MoleculePanelSmsValidationComponent());
app.component('moleculePanelSupport', new Atomic.MoleculePanelSupportComponent());
app.component('moleculePanelTasks', new Atomic.MoleculePanelTasksComponent());
app.component('moleculePanelToggleStatus', new Atomic.MoleculePanelToggleStatusComponent());
app.component('moleculePanelView', new Atomic.MoleculePanelViewComponent());
app.component('moleculePanelWhois', new Atomic.MoleculePanelWhoisComponent());
app.component('moleculePanelWebspaceLegacyPhpWarning', new Atomic.MoleculePanelWebspaceLegacyPhpWarningComponent());
app.component('moleculePanelWizard', new Atomic.MoleculePanelWizardComponent());
app.component('moleculePanelWrapper', new Atomic.MoleculePanelWrapperComponent());
app.component('moleculePasswordKeyGen', new Atomic.MoleculePasswordKeyGenComponent());
app.component('moleculePasswordStrengthBar', new Atomic.MoleculePasswordStrengthBarComponent());
app.component('moleculePopupMultiActions', new Atomic.MoleculePopupMultiActionsComponent());
app.component('moleculePopupUserAction', new Atomic.MoleculePopupUserActionComponent());
app.component('moleculeRightsDropDownForm', new Atomic.MoleculeRightsDropDownFormComponent());
app.component('moleculeRightsTable', new Atomic.MoleculeRightsTableComponent());
app.component('moleculeRowSelectedProductFactsheet', new Atomic.MoleculeRowSelectedProductFactsheetComponent());
app.component('moleculeRowVhostGeneral', new Atomic.MoleculeRowVhostGeneralComponent());
app.component('moleculeRowWebspaceAccess', new Atomic.MoleculeRowWebspaceAccessComponent());
app.component('moleculeSearchBar', new Atomic.MoleculeSearchBarComponent());
app.component('moleculeServiceObjectStatusDeleting', new Atomic.MoleculeServiceObjectStatusDeletingComponent());
app.component('moleculeServiceObjectStatusDeletionScheduled', new Atomic.MoleculeServiceObjectStatusDeletionScheduledComponent());
app.component('moleculeServiceObjectStatusRestorable', new Atomic.MoleculeServiceObjectStatusRestorableComponent());
app.component('moleculeStatusBillingInvoice', new Atomic.MoleculeStatusBillingInvoiceComponent());
app.component('moleculeStatusBundle', new Atomic.MoleculeStatusBundleComponent());
app.component('moleculeStatusDatabase', new Atomic.MoleculeStatusDatabaseComponent());
app.component('moleculeStatusEmail', new Atomic.MoleculeStatusEmailComponent());
app.component('moleculeStatusGeneral', new Atomic.MoleculeStatusGeneralComponent());
app.component('moleculeStatusWebspace', new Atomic.MoleculeStatusWebspaceComponent());
app.component('moleculeSupporterBlock', new Atomic.MoleculeSupporterBlockComponent());
app.component('moleculeTaskList', new Atomic.MoleculeTaskListComponent());
app.component('moleculeTextCanBeEmpty', new Atomic.MoleculeTextCanBeEmptyComponent());
app.component('moleculeWizardBillingCycleSelect', new Atomic.MoleculeWizardBillingCycleSelectComponent());
app.component('moleculeWizardContactComplete', new Atomic.MoleculeWizardContactCompleteComponent());
app.component('moleculeWizardOrchestrationJobConfirmation', new Atomic.MoleculeWizardOrchestrationJobConfirmationComponent());
app.component('moleculeWizardOrderConfirmation', new Atomic.MoleculeWizardOrderConfirmationComponent());
app.component('moleculeWizardPoolBundleAvailability', new Atomic.MoleculeWizardPoolBundleAvailabilityComponent());
app.component('moleculeWizardProductSelect', new Atomic.MoleculeWizardProductSelectComponent());
app.component('moleculeWizardServiceObjectContainer', new Atomic.MoleculeWizardServiceObjectContainerComponent());
app.component('moleculeWizardSufficientCredit', new Atomic.MoleculeWizardSufficientCreditComponent());
app.component('moleculeWizardSummary', new Atomic.MoleculeWizardSummaryComponent());
app.component('moleculeWizardSummaryItemBundle', new Atomic.MoleculeWizardSummaryItemBundleComponent());
app.component('moleculeWizardSummaryItemCertificate', new Atomic.MoleculeWizardSummaryItemCertificateComponent());
app.component('moleculeWizardSummaryItemDatabase', new Atomic.MoleculeWizardSummaryItemDatabaseComponent());
app.component('moleculeWizardSummaryItemDomain', new Atomic.MoleculeWizardSummaryItemDomainComponent());
app.component('moleculeWizardSummaryItemMachine', new Atomic.MoleculeWizardSummaryItemMachineComponent());
app.component('moleculeWizardSummaryItemMailbox', new Atomic.MoleculeWizardSummaryItemMailboxComponent());
app.component('moleculeWizardSummaryItemRedirection', new Atomic.MoleculeWizardSummaryItemRedirectionComponent());
app.component('moleculeWizardSummaryItemVhost', new Atomic.MoleculeWizardSummaryItemVhostComponent());
app.component('moleculeWizardSummaryItemWebhosting', new Atomic.MoleculeWizardSummaryItemWebhostingComponent());
app.component('moleculeWizardSupervisor', new Atomic.MoleculeWizardSupervisorComponent());
app.component('moleculeWizardVerifiedHandler', new Atomic.MoleculeWizardVerifiedHandlerComponent());

//  ██████╗  ██████╗   ██████╗   █████╗  ███╗   ██╗ ███████╗
// ██╔═══██╗ ██╔══██╗ ██╔════╝  ██╔══██╗ ████╗  ██║ ██╔════╝
// ██║   ██║ ██████╔╝ ██║  ███╗ ███████║ ██╔██╗ ██║ ███████╗
// ██║   ██║ ██╔══██╗ ██║   ██║ ██╔══██║ ██║╚██╗██║ ╚════██║
// ╚██████╔╝ ██║  ██║ ╚██████╔╝ ██║  ██║ ██║ ╚████║ ███████║
//  ╚═════╝  ╚═╝  ╚═╝  ╚═════╝  ╚═╝  ╚═╝ ╚═╝  ╚═══╝ ╚══════╝

app.component('organCreateConfigurationAccessesDatabaseWebspace', new Atomic.OrganCreateConfigurationAccessesDatabaseWebspaceComponent());
app.component('organCreateConfigurationBundle', new Atomic.OrganCreateConfigurationBundleComponent());
app.component('organCreateConfigurationBundleDomainRegisterTransfer', new Atomic.OrganCreateConfigurationBundleDomainRegisterTransferComponent());
app.component('organCreateConfigurationDatabase', new Atomic.OrganCreateConfigurationDatabaseComponent());
app.component('organCreateConfigurationDnsMultiFqdnSelect', new Atomic.OrganCreateConfigurationDnsMultiFqdnSelectComponent());
app.component('organCreateConfigurationDomainRegisterTransfer', new Atomic.OrganCreateConfigurationDomainRegisterTransferComponent());
app.component('organCreateConfigurationGeneralContingents', new Atomic.OrganCreateConfigurationGeneralContingentsComponent());
app.component('organCreateConfigurationGeneralProjectname', new Atomic.OrganCreateConfigurationGeneralProjectnameComponent());
app.component('organCreateConfigurationGeneralWebspaceSelection', new Atomic.OrganCreateConfigurationGeneralWebspaceSelectionComponent());
app.component('organCreateConfigurationMachineBackup', new Atomic.OrganCreateConfigurationMachineBackupComponent());
app.component('organCreateConfigurationMachineOs', new Atomic.OrganCreateConfigurationMachineOsComponent());
app.component('organCreateConfigurationMachineOsUser', new Atomic.OrganCreateConfigurationMachineOsUserComponent());
app.component('organCreateConfigurationNextcloudAddonQuantitySlider', new Atomic.OrganCreateConfigurationNextcloudAddonQuantitySliderComponent());
app.component('organCreateConfigurationNextcloudBackup', new Atomic.OrganCreateConfigurationNextcloudBackupComponent());
app.component('organCreateConfigurationNextcloudConditions', new Atomic.OrganCreateConfigurationNextcloudConditionsComponent());
app.component('organCreateConfigurationUserStorageNextcloud', new Atomic.OrganCreateConfigurationUserStorageNextcloudComponent());
app.component('organCreateConfigurationVhost', new Atomic.OrganCreateConfigurationVhostComponent());
app.component('organCreateConfigurationVhostOrchestrator', new Atomic.OrganCreateConfigurationVhostOrchestratorComponent());
app.component('organCreateConfigurationWebspace', new Atomic.OrganCreateConfigurationWebspaceComponent());
app.component('organCreateConfirmationBundle', new Atomic.OrganCreateConfirmationBundleComponent());
app.component('organCreateConfirmationDatabaseAccess', new Atomic.OrganCreateConfirmationDatabaseAccessComponent());
app.component('organCreateConfirmationDomain', new Atomic.OrganCreateConfirmationDomainComponent());
app.component('organCreateConfirmationVhost', new Atomic.OrganCreateConfirmationVhostComponent());
app.component('organCreateConfirmationWebspaceAccess', new Atomic.OrganCreateConfirmationWebspaceAccessComponent());
app.component('organCreateDomainContacts', new Atomic.OrganCreateDomainContactsComponent());
app.component('organCreateDomainContingentWebspaceSelection', new Atomic.OrganCreateDomainContingentWebspaceSelectionComponent());
app.component('organCreateDomainNameservers', new Atomic.OrganCreateDomainNameserversComponent());
app.component('organCreateDomainRegistration', new Atomic.OrganCreateDomainRegistrationComponent());
app.component('organCreateDomainTypeSelection', new Atomic.OrganCreateDomainTypeSelectionComponent());
app.component('organCreateSummaryBundle', new Atomic.OrganCreateSummaryBundleComponent());
app.component('organCreateSummaryDomain', new Atomic.OrganCreateSummaryDomainComponent());
app.component('organCreateSummaryStorage', new Atomic.OrganCreateSummaryStorageComponent());
app.component('organCreateSummaryWebspace', new Atomic.OrganCreateSummaryWebspaceComponent());
app.component('organDashboardWidgetAccountBalance', new Atomic.OrganDashboardWidgetAccountBalanceComponent());
app.component('organDashboardWidgetAdditionalLinks', new Atomic.OrganDashboardWidgetAdditionalLinksComponent());
app.component('organDashboardWidgetAvvPrivacyNotifier', new Atomic.OrganDashboardWidgetAvvPrivacyNotifierComponent());
app.component('organDashboardWidgetDomainSearch', new Atomic.OrganDashboardWidgetDomainSearchComponent());
app.component('organDashboardWidgetEmptyHint', new Atomic.OrganDashboardWidgetEmptyHintComponent());
app.component('organDashboardWidgetFaq', new Atomic.OrganDashboardWidgetFaqComponent());
app.component('organDashboardWidgetFaqNextcloud', new Atomic.OrganDashboardWidgetFaqNextcloudComponent());
app.component('organDashboardWidgetIncompleteUserProfile', new Atomic.OrganDashboardWidgetIncompleteUserProfileComponent());
app.component('organDashboardWidgetOverviewContactVerification', new Atomic.OrganDashboardWidgetOverviewContactVerificationComponent());
app.component('organDashboardWidgetOverviewDomainTransfersOut', new Atomic.OrganDashboardWidgetOverviewDomainTransfersOutComponent());
app.component('organDashboardWidgetProductBundle', new Atomic.OrganDashboardWidgetProductBundleComponent());
app.component('organDashboardWidgetProductNextcloud', new Atomic.OrganDashboardWidgetProductNextcloudComponent());
app.component('organDashboardWidgetProductWebspace', new Atomic.OrganDashboardWidgetProductWebspaceComponent());
app.component('organDashboardWidgetSepaInfo', new Atomic.OrganDashboardWidgetSepaInfoComponent());
app.component('organDashboardWidgetSepaVerificationNotifier', new Atomic.OrganDashboardWidgetSepaVerificationNotifierComponent());
app.component('organDashboardWidgetSmsValidation', new Atomic.OrganDashboardWidgetSmsValidationComponent());
app.component('organDashboardWidgetSupport', new Atomic.OrganDashboardWidgetSupportComponent());
app.component('organDashboardWidgetWelcome', new Atomic.OrganDashboardWidgetWelcomeComponent());
app.component('organDeleteWebspace', new Atomic.OrganDeleteWebspaceComponent());
app.component('organEditPanelApiKeyInformation', new Atomic.OrganEditPanelApiKeyInformationComponent());
app.component('organEditPanelBackups', new Atomic.OrganEditPanelBackupsComponent());
app.component('organEditPanelBillingPaymentConfirmSepaVerification', new Atomic.OrganEditPanelBillingPaymentConfirmSepaVerificationComponent());
app.component('organEditPanelBillingPaymentMethod', new Atomic.OrganEditPanelBillingPaymentMethodComponent());
app.component('organEditPanelBillingPaymentRechargeDeposit', new Atomic.OrganEditPanelBillingPaymentRechargeDepositComponent());
app.component('organEditPanelBillingPaymentRedeemVoucher', new Atomic.OrganEditPanelBillingPaymentRedeemVoucherComponent());
app.component('organEditPanelBundleAddDomain', new Atomic.OrganEditPanelBundleAddDomainComponent());
app.component('organEditPanelBundleBackups', new Atomic.OrganEditPanelBundleBackupsComponent());
app.component('organEditPanelAdminBackups', new Atomic.OrganEditPanelAdminBackupsComponent());
app.component('organEditPanelBundleRename', new Atomic.OrganEditPanelBundleRenameComponent());
app.component('organEditPanelBundleStorage', new Atomic.OrganEditPanelBundleStorageComponent());
app.component('organEditPanelContactData', new Atomic.OrganEditPanelContactDataComponent());
app.component('organEditPanelContactEmail', new Atomic.OrganEditPanelContactEmailComponent());
app.component('organEditPanelCronjobGeneral', new Atomic.OrganEditPanelCronjobGeneralComponent());
app.component('organEditPanelCronjobSchedule', new Atomic.OrganEditPanelCronjobScheduleComponent());
app.component('organEditPanelDatabaseDelete', new Atomic.OrganEditPanelDatabaseProductDeleteComponent());
app.component('organEditPanelDatabaseGeneral', new Atomic.OrganEditPanelDatabaseGeneralComponent());
app.component('organEditPanelDatabaseStorage', new Atomic.OrganEditPanelDatabaseStorageComponent());
app.component('organEditPanelDatabaseUsers', new Atomic.OrganEditPanelDatabaseUsersComponent());
app.component('organEditPanelDatabaseUsersGeneral', new Atomic.OrganEditPanelDatabaseUsersGeneralComponent());
app.component('organEditPanelDdnsHostGeneral', new Atomic.OrganEditPanelDdnsHostGeneralComponent());
app.component('organEditPanelDdnsHostUser', new Atomic.OrganEditPanelDdnsHostUserComponent());
app.component('organEditPanelDisplaySettings', new Atomic.OrganEditPanelDisplaySettingsComponent());
app.component('organEditPanelDnsRecords', new Atomic.OrganEditPanelDnsRecordsComponent());
app.component('organEditPanelDnsRecordsUpdater', new Atomic.OrganEditPanelDnsRecordsUpdaterComponent());
app.component('organEditPanelDnsSettings', new Atomic.OrganEditPanelDnsSettingsComponent());
app.component('organEditPanelDnsZoneExpertSettings', new Atomic.OrganEditPanelDnsZoneExpertSettingsComponent());
app.component('organEditPanelDnsZoneSlave', new Atomic.OrganEditPanelDnsZoneSlaveComponent());
app.component('organEditPanelDnsZoneTemplate', new Atomic.OrganEditPanelDnsZoneTemplateComponent());
app.component('organEditPanelDomainAppInstall', new Atomic.OrganEditPanelDomainAppInstallComponent());
app.component('organEditPanelDomainBasicInfo', new Atomic.OrganEditPanelDomainBasicInfoComponent());
app.component('organEditPanelDomainContactAdvanced', new Atomic.OrganEditPanelDomainContactAdvancedComponent());
app.component('organEditPanelDomainContactData', new Atomic.OrganEditPanelDomainContactDataComponent());
app.component('organEditPanelDomainContactGeneral', new Atomic.OrganEditPanelDomainContactGeneralComponent());
app.component('organEditPanelDomainContactIdentityCard', new Atomic.OrganEditPanelDomainContactIdentityCardComponent());
app.component('organEditPanelDomainContacts', new Atomic.OrganEditPanelDomainContactsComponent());
app.component('organEditPanelDomainContactTrendmark', new Atomic.OrganEditPanelDomainContactTrendmarkComponent());
app.component('organEditPanelDomainDelete', new Atomic.OrganEditPanelDomainDeleteComponent());
app.component('organEditPanelDomainDnssec', new Atomic.OrganEditPanelDomainDnssecComponent());
app.component('organEditPanelDomainEditBulkContacts', new Atomic.OrganEditPanelDomainEditBulkContactsComponent());
app.component('organEditPanelDomainEditBulkDomainSelect', new Atomic.OrganEditPanelDomainEditBulkDomainSelectComponent());
app.component('organEditPanelDomainEditBulkNameserver', new Atomic.OrganEditPanelDomainEditBulkNameserverComponent());
app.component('organEditPanelDomainGeneral', new Atomic.OrganEditPanelDomainGeneralComponent());
app.component('organEditPanelDomainHttpUserDirectories', new Atomic.OrganEditPanelDomainHttpUserDirectoriesComponent());
app.component('organEditPanelDomainHttpUserEntries', new Atomic.OrganEditPanelDomainHttpUserEntriesComponent());
app.component('organEditPanelDomainLocations', new Atomic.OrganEditPanelDomainLocationsComponent());
app.component('organEditPanelDomainLocationsEdit', new Atomic.OrganEditPanelDomainLocationsEditComponent());
app.component('organEditPanelDomainMoveAccount', new Atomic.OrganEditPanelDomainMoveAccountComponent());
app.component('organEditPanelDomainMoveContacts', new Atomic.OrganEditPanelDomainMoveContactsComponent());
app.component('organEditPanelDomainNameservers', new Atomic.OrganEditPanelDomainNameserversComponent());
app.component('organEditPanelDomainPhpOptions', new Atomic.OrganEditPanelDomainPhpOptionsComponent());
app.component('organEditPanelDomainProductDelete', new Atomic.OrganEditPanelDomainProductDeleteComponent());
app.component('organEditPanelDomainProductRestore', new Atomic.OrganEditPanelDomainProductRestoreComponent());
app.component('organEditPanelDomainRestore', new Atomic.OrganEditPanelDomainRestoreComponent());
app.component('organEditPanelDomainSettings', new Atomic.OrganEditPanelDomainSettingsComponent());
app.component('organEditPanelDomainSsl', new Atomic.OrganEditPanelDomainSslComponent());
app.component('organEditPanelDomainTransfer', new Atomic.OrganEditPanelDomainTransferComponent());
app.component('organEditPanelDomainTransferUk', new Atomic.OrganEditPanelDomainTransferUkComponent());
app.component('organEditPanelDomainZoneEntries', new Atomic.OrganEditPanelDomainZoneEntriesComponent());
app.component('organEditPanelFloatingIps', new Atomic.OrganEditPanelFloatingIpsComponent());
app.component('organEditPanelMachineBackups', new Atomic.OrganEditPanelMachineBackupsComponent());
app.component('organEditPanelMachineGeneral', new Atomic.OrganEditPanelMachineGeneralComponent());
app.component('organEditPanelMachineNetwork', new Atomic.OrganEditPanelMachineNetworkComponent());
app.component('organEditPanelMachineNetworkInterfaces', new Atomic.OrganEditPanelMachineNetworkInterfacesComponent());
app.component('organEditPanelMachineOs', new Atomic.OrganEditPanelMachineOsComponent());
app.component('organEditPanelMachinePower', new Atomic.OrganEditPanelMachinePowerComponent());
app.component('organEditPanelMachineRescue', new Atomic.OrganEditPanelMachineRescueComponent());
app.component('organEditPanelMachineReset', new Atomic.OrganEditPanelMachineResetComponent());
app.component('organEditPanelMachineUpgrade', new Atomic.OrganEditPanelMachineUpgradeComponent());
app.component('organEditPanelMailboxAutoresponder', new Atomic.OrganEditPanelMailboxAutoresponderComponent());
app.component('organEditPanelMailboxDomainsettings', new Atomic.OrganEditPanelMailboxDomainsettingsComponent());
app.component('organEditPanelMailboxGeneral', new Atomic.OrganEditPanelMailboxGeneralComponent());
app.component('organEditPanelMailboxPermissions', new Atomic.OrganEditPanelMailboxPermissionsComponent());
app.component('organEditPanelMailboxSpam', new Atomic.OrganEditPanelMailboxSpamComponent());
app.component('organEditPanelMailboxStorage', new Atomic.OrganEditPanelMailboxStorageComponent());
app.component('organEditPanelMailingList', new Atomic.OrganEditPanelMailingListComponent());
app.component('organEditPanelNetworkConfig', new Atomic.OrganEditPanelConfigNetworksComponent());
app.component('organEditPanelNextcloudBackups', new Atomic.OrganEditPanelNextcloudBackupsComponent());
app.component('organEditPanelNextcloudBusinessUpgrade', new Atomic.OrganEditPanelNextcloudBusinessUpgradeComponent());
app.component('organEditPanelNextcloudDelete', new Atomic.OrganEditPanelNextcloudDeleteComponent());
app.component('organEditPanelNextcloudGroupFolders', new Atomic.OrganEditPanelNextcloudGroupFoldersComponent());
app.component('organEditPanelNextcloudGroups', new Atomic.OrganEditPanelNextcloudGroupsComponent());
app.component('organEditPanelNextcloudPlugins', new Atomic.OrganEditPanelNextcloudPluginsComponent());
app.component('organEditPanelNextcloudPurchaseApp', new Atomic.OrganEditPanelNextcloudPurchaseAppComponent());
app.component('organEditPanelNextcloudRestore', new Atomic.OrganEditPanelNextcloudRestoreComponent());
app.component('organEditPanelNextcloudUpgrade', new Atomic.OrganEditPanelNextcloudUpgradeComponent());
app.component('organEditPanelNextcloudUsers', new Atomic.OrganEditPanelNextcloudUsersComponent());
app.component('organEditPanelNotificationSettings', new Atomic.OrganEditPanelNotificationSettingsComponent());
app.component('organEditPanelPermissions', new Atomic.OrganEditPanelPermissionsComponent());
app.component('organEditPanelProfile', new Atomic.OrganEditPanelProfileComponent());
app.component('organEditPanelRecordChange', new Atomic.OrganEditPanelRecordChangeComponent());
app.component('organEditPanelRedirectionEdit', new Atomic.OrganEditPanelRedirectionEditComponent());
app.component('organEditPanelRestrictionsDisplay', new Atomic.OrganEditPanelRestrictionsDisplayComponent());
app.component('organEditPanelRestrictionsEdit', new Atomic.OrganEditPanelRestrictionsEditComponent());
app.component('organEditPanelRights', new Atomic.OrganEditPanelRightsComponent());
app.component('organEditPanelRightsTemplateDelete', new Atomic.OrganEditPanelRightsTemplateDeleteComponent());
app.component('organEditPanelRightsTemplateGeneral', new Atomic.OrganEditPanelRightsTemplateGeneralComponent());
app.component('organEditPanelRoutedNetworks', new Atomic.OrganEditPanelRoutedNetworksComponent());
app.component('organEditPanelSslCertificateReissue', new Atomic.OrganEditPanelSslCertificateReissueComponent());
app.component('organEditPanelSslCertificateRevoke', new Atomic.OrganEditPanelSslCertificateRevokeComponent());
app.component('organEditPanelSubAccountBasicData', new Atomic.OrganEditPanelSubAccountBasicDataComponent());
app.component('organEditPanelSubAccountBilling', new Atomic.OrganEditPanelSubAccountBillingComponent());
app.component('organEditPanelSubAccountContactData', new Atomic.OrganEditPanelSubAccountContactDataComponent());
app.component('organEditPanelTwoFactorAuthentication', new Atomic.OrganEditPanelTwoFactorAuthenticationComponent());
app.component('organEditPanelUiSettings', new Atomic.OrganEditPanelUiSettingsComponent());
app.component('organEditPanelUserAdmin', new Atomic.OrganEditPanelUserAdminComponent());
app.component('organEditPanelUserData', new Atomic.OrganEditPanelUserDataComponent());
app.component('organEditPanelUserLogin', new Atomic.OrganEditPanelUserLoginComponent());
app.component('organEditPanelUserPassword', new Atomic.OrganEditPanelUserPasswordComponent());
app.component('organEditPanelWebhostingUserGeneral', new Atomic.OrganEditPanelWebhostingUserGeneralComponent());
app.component('organEditPanelWebspaceAdminOptions', new Atomic.OrganEditPanelWebspaceAdminOptionsComponent());
app.component('organEditPanelWebspaceGeneral', new Atomic.OrganEditPanelWebspaceGeneralComponent());
app.component('organEditPanelWebspaceMinPhpVersion', new Atomic.OrganEditPanelWebspaceMinPhpVersionComponent());
app.component('organEditPanelWebspacePhpBoost', new Atomic.OrganEditPanelWebspacePhpBoostComponent());
app.component('organEditPanelWebspacePhpLegacy', new Atomic.OrganEditPanelWebspacePhpLegacyComponent());
app.component('organEditPanelWebspaceUsers', new Atomic.OrganEditPanelWebspaceUsersComponent());
app.component('organEditPanelWebspaceVhostSsl', new Atomic.OrganEditPanelWebspaceVhostSslComponent());
app.component('organManagedMachineProjectConfig', new Atomic.OrganManagedMachineProjectConfigComponent());
app.component('organManagedMachineProjectConfiguration', new Atomic.OrganManagedMachineProjectConfigurationComponent());
app.component('organManagedMachineProjectConfirmationDatabase', new Atomic.OrganManagedMachineProjectConfirmationDatabaseComponent());
app.component('organManagedMachineProjectConfirmationSubaccount', new Atomic.OrganManagedMachineProjectConfirmationSubaccountComponent());
app.component('organManagedMachineProjectConfirmationVhost', new Atomic.OrganManagedMachineProjectConfirmationVhostComponent());
app.component('organManagedMachineProjectConfirmationWebspace', new Atomic.OrganManagedMachineProjectConfirmationWebspaceComponent());
app.component('organManagedMachineProjectNew', new Atomic.OrganManagedMachineProjectNewComponent());
app.component('organManagedMachineProjectSubAccountSelection', new Atomic.OrganManagedMachineProjectSubAccountSelectionComponent());
app.component('organManagedServerAccountSelection', new Atomic.OrganManagedServerAccountSelectionComponent());
app.component('organNewPanelApiKeyInformation', new Atomic.OrganNewPanelApiKeyInformationComponent());
app.component('organNewPanelDatabaseUser', new Atomic.OrganNewPanelDatabaseUserComponent());
app.component('organNewPanelDdnsHostGeneral', new Atomic.OrganNewPanelDdnsHostGeneralComponent());
app.component('organNewPanelDdnsHostUser', new Atomic.OrganNewPanelDdnsHostUserComponent());
app.component('organNewPanelDnsTemplateGeneral', new Atomic.OrganNewPanelDnsTemplateGeneralComponent());
app.component('organNewPanelDomainContact', new Atomic.OrganNewPanelDomainContactComponent());
app.component('organNewPanelExchangeOrganization', new Atomic.OrganNewPanelExchangeOrganizationComponent());
app.component('organNewPanelMachinePoolNew', new Atomic.OrganNewPanelMachinePoolNewComponent());
app.component('organNewPanelMailingList', new Atomic.OrganNewPanelMailingListComponent());
app.component('organNewPanelWebhostingUserGeneral', new Atomic.OrganNewPanelWebhostingUserGeneralComponent());
app.component('organNewSubAccountBasicData', new Atomic.OrganNewSubAccountBasicDataComponent());
app.component('organNewSubAccountContactData', new Atomic.OrganNewSubAccountContactDataComponent());
app.component('organNewSubAccountNotifications', new Atomic.OrganNewSubAccountNotificationsComponent());
app.component('organNewSubAccountUser', new Atomic.OrganNewSubAccountUserComponent());
app.component('organNewSubAccountUserRights', new Atomic.OrganNewSubAccountUserRightsComponent());
app.component('organPanelApiKeyOverview', new Atomic.OrganPanelApiKeyOverviewComponent());
app.component('organPanelAppInstallDbSettings', new Atomic.OrganPanelAppInstallDbSettingsComponent());
app.component('organPanelAppInstallSettings', new Atomic.OrganPanelAppInstallSettingsComponent());
app.component('organPanelAppInstallVhostSettings', new Atomic.OrganPanelAppInstallVhostSettingsComponent());
app.component('organPanelDdnsHostnameOverview', new Atomic.OrganPanelDdnsHostnameOverviewComponent());
app.component('organPanelLogin', new Atomic.OrganPanelLoginComponent());
app.component('organPanelLoginEnableTwofa', new Atomic.OrganPanelLoginEnableTwofaComponent());
app.component('organPanelLoginTwofa', new Atomic.OrganPanelLoginTwofaComponent());
app.component('organPanelPasswordReset', new Atomic.OrganPanelPasswordResetComponent());
app.component('organPanelStorageProductAppConfigAdditionalUsers', new Atomic.OrganPanelStorageProductAppConfigAdditionalUsersComponent());
app.component('organSslWizardProductConfigCertificateAdditionalDomains', new Atomic.OrganSslWizardProductConfigCertificateAdditionalDomainsComponent());
app.component('organSslWizardProductConfigCertificateContactAdmin', new Atomic.OrganSslWizardProductConfigCertificateContactAdminComponent());
app.component('organSslWizardProductConfigCertificateContactOrganization', new Atomic.OrganSslWizardProductConfigCertificateContactOrganizationComponent());
app.component('organSslWizardProductConfigCertificateContacts', new Atomic.OrganSslWizardProductConfigCertificateContactsComponent());
app.component('organSslWizardProductConfigCertificateContactTech', new Atomic.OrganSslWizardProductConfigCertificateContactTechComponent());
app.component('organSslWizardProductConfigCertificateRequest', new Atomic.OrganSslWizardProductConfigCertificateRequestComponent());
app.component('organSslWizardProductConfigCertificateType', new Atomic.OrganSslWizardProductConfigCertificateTypeComponent());
app.component('organViewPanelAccessLinkedToDatabasesHint', new Atomic.OrganViewPanelAccessLinkedToDatabasesHintComponent());
app.component('organViewPanelAccessLinkedToWebspacesHint', new Atomic.OrganViewPanelAccessLinkedToWebspacesHintComponent());
app.component('organViewPanelApiKeyGenerated', new Atomic.OrganViewPanelApiKeyGeneratedComponent());
app.component('organViewPanelDataProcessingBottom', new Atomic.OrganViewPanelDataProcessingBottomComponent());
app.component('organViewPanelDataProcessingMain', new Atomic.OrganViewPanelDataProcessingMainComponent());
app.component('organViewPanelDataProcessingPreamble', new Atomic.OrganViewPanelDataProcessingPreambleComponent());
app.component('organViewPanelDataProcessingSuppliers', new Atomic.OrganViewPanelDataProcessingSuppliersComponent());
app.component('organViewPanelDataProcessingToms', new Atomic.OrganViewPanelDataProcessingTomsComponent());
app.component('organViewPanelDnssecSimple', new Atomic.OrganViewPanelDnssecSimpleComponent());
app.component('organViewPanelDnsTemplatePlaceholder', new Atomic.OrganViewPanelDnsTemplatePlaceholderComponent());
app.component('organViewPanelEditContactDetails', new Atomic.OrganViewPanelEditContactDetailsComponent());
app.component('organViewPanelEmailsOfDomain', new Atomic.OrganViewPanelEmailsOfDomainComponent());
app.component('organViewPanelEmailsOfOrganization', new Atomic.OrganViewPanelEmailsOfOrganizationComponent());
app.component('organViewPanelJobDetails', new Atomic.OrganViewPanelJobDetailsComponent());
app.component('organViewPanelNextcloudUrl', new Atomic.OrganViewPanelNextcloudUrlComponent());
app.component('organViewPanelPoolDetailsModal', new Atomic.OrganViewPanelPoolDetailsModalComponent());
app.component('organViewPanelRecordsExpert', new Atomic.OrganViewPanelRecordsExpertComponent());
app.component('organViewPanelRecordsSimple', new Atomic.OrganViewPanelRecordsSimpleComponent());
app.component('organViewPanelReferralLink', new Atomic.OrganViewPanelReferralLinkComponent());
app.component('organViewPanelSslCertificateDetail', new Atomic.OrganViewPanelSslCertificateDetailComponent());
app.component('organViewPanelSslCertificateGenerell', new Atomic.OrganViewPanelSslCertificateGenerellComponent());
app.component('organViewPanelSupportModal', new Atomic.OrganViewPanelSupportModalComponent());
app.component('organWizardDns', new Atomic.OrganWizardDnsComponent());
app.component('organWizardDnsSettingsRecords', new Atomic.OrganWizardDnsSettingsRecordsComponent());
app.component('organWizardWebspace', new Atomic.OrganWizardWebspaceComponent());
app.component('organWizardWebspaceAccesses', new Atomic.OrganWizardWebspaceAccessesComponent());
app.component('organWizardWebspaceVhost', new Atomic.OrganWizardWebspaceVhostComponent());

//  ██████╗ ██████╗  ██████╗  █████╗ ███╗   ██╗██╗███████╗███╗   ███╗███████╗
// ██╔═══██╗██╔══██╗██╔════╝ ██╔══██╗████╗  ██║██║██╔════╝████╗ ████║██╔════╝
// ██║   ██║██████╔╝██║  ███╗███████║██╔██╗ ██║██║███████╗██╔████╔██║███████╗
// ██║   ██║██╔══██╗██║   ██║██╔══██║██║╚██╗██║██║╚════██║██║╚██╔╝██║╚════██║
// ╚██████╔╝██║  ██║╚██████╔╝██║  ██║██║ ╚████║██║███████║██║ ╚═╝ ██║███████║
//  ╚═════╝ ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚══════╝╚═╝     ╚═╝╚══════╝

app.component('organismBillingPaymentOptions', new Atomic.OrganismBillingPaymentOptionsComponent());
app.component('organismBundleWizardConfigView', new Atomic.OrganismBundleWizardConfigViewComponent());
app.component('organismBundleWizardConfirmView', new Atomic.OrganismBundleWizardConfirmViewComponent());
app.component('organismBundleWizardProductConfigEmail', new Atomic.OrganismBundleWizardProductConfigEmailComponent());
app.component('organismBundleWizardProductConfigWebspace', new Atomic.OrganismBundleWizardProductConfigWebspaceComponent());
app.component('organismBundleWizardWrapper', new Atomic.OrganismBundleWizardWrapperComponent());
app.component('organismDnsWizardConfirmView', new Atomic.OrganismDnsWizardConfirmViewComponent());
app.component('organismSslWizardConfigView', new Atomic.OrganismSslWizardConfigViewComponent());
app.component('organismSslWizardConfirmView', new Atomic.OrganismSslWizardConfirmViewComponent());
app.component('organismSslWizardWrapper', new Atomic.OrganismSslWizardWrapperComponent());
app.component('organismCancelDeleteFormBundle', new Atomic.OrganismCancelDeleteFormBundleComponent());
app.component('organismCopyFormDnsTemplate', new Atomic.OrganismCopyFormDnsTemplateComponent());
app.component('organismCreateBundle', new Atomic.OrganismCreateBundleComponent());
app.component('organismCreateBundleConfig', new Atomic.OrganismCreateBundleConfigComponent());
app.component('organismCreateBundleOrder', new Atomic.OrganismCreateBundleOrderComponent());
app.component('organismCreateBundleSummary', new Atomic.OrganismCreateBundleSummaryComponent());
app.component('organismDashboardOverviews', new Atomic.OrganismDashboardOverviewsComponent());
app.component('organismDynamicDashboardAdd', new Atomic.OrganismDynamicDashboardAddComponent());
app.component('organismDynamicDashboard', new Atomic.OrganismDynamicDashboardComponent());
app.component('organismDatabaseWizardConfigView', new Atomic.OrganismDatabaseWizardConfigViewComponent());
app.component('organismDatabaseWizardConfirmView', new Atomic.OrganismDatabaseWizardConfirmViewComponent());
app.component('organismDatabaseWizardProductConfigDatabase', new Atomic.OrganismDatabaseWizardProductConfigDatabaseComponent());
app.component('organismDatabaseWizardWrapper', new Atomic.OrganismDatabaseWizardWrapperComponent());
app.component('organismDeleteFormBundle', new Atomic.OrganismDeleteFormBundleComponent());
app.component('organismDnsWizardConfigView', new Atomic.OrganismDnsWizardConfigViewComponent());
app.component('organismDnsWizardProductConfigDdnsHost', new Atomic.OrganismDnsWizardProductConfigDdnsHostComponent());
app.component('organismDnsWizardProductConfigZoneCopy', new Atomic.OrganismDnsWizardProductConfigZoneCopyComponent());
app.component('organismDnsWizardProductConfigZoneExpert', new Atomic.OrganismDnsWizardProductConfigZoneExpertComponent());
app.component('organismDnsWizardProductConfigZoneQuick', new Atomic.OrganismDnsWizardProductConfigZoneQuickComponent());
app.component('organismDnsWizardProductConfigZoneSlave', new Atomic.OrganismDnsWizardProductConfigZoneSlaveComponent());
app.component('organismDnsWizardProductConfigZoneTemplate', new Atomic.OrganismDnsWizardProductConfigZoneTemplateComponent());
app.component('organismDnsWizardWrapper', new Atomic.OrganismDnsWizardWrapperComponent());
app.component('organismDomainWizardConfigView', new Atomic.OrganismDomainWizardConfigViewComponent());
app.component('organismDomainWizardProductConfig', new Atomic.OrganismDomainWizardProductConfigComponent());
app.component('organismDomainWizardWrapper', new Atomic.OrganismDomainWizardWrapperComponent());
app.component('organismDomainWizardConfirmView', new Atomic.OrganismDomainWizardConfirmViewComponent());
app.component('organismDomainWizardExternalProductConfig', new Atomic.OrganismDomainWizardExternalProductConfigComponent());
app.component('organismDomainWizardSubdomainProductConfig', new Atomic.OrganismDomainWizardSubdomainProductConfigComponent());
app.component('organismEditFormAccountSettings', new Atomic.OrganismEditFormAccountSettingsComponent());
app.component('organismEditFormApiKey', new Atomic.OrganismEditFormApiKeyComponent());
app.component('organismEditFormBackups', new Atomic.OrganismEditFormBackupsComponent());
app.component('organismEditFormBasicData', new Atomic.OrganismEditFormBasicDataComponent());
app.component('organismEditFormBundleBackups', new Atomic.OrganismEditFormBundleBackupsComponent());
app.component('organismEditFormBundleDomainsTransfer', new Atomic.OrganismEditFormBundleDomainsTransferComponent());
app.component('organismEditFormBundleAddDomain', new Atomic.OrganismEditFormBundleAddDomainComponent());
app.component('organismEditFormBundleRename', new Atomic.OrganismEditFormBundleRenameComponent());
app.component('organismEditFormBundleStorage', new Atomic.OrganismEditFormBundleStorageComponent());
app.component('organismEditFormDatabase', new Atomic.OrganismEditFormDatabaseComponent());
app.component('organismEditFormDatabaseUser', new Atomic.OrganismEditFormDatabaseUserComponent());
app.component('organismEditFormDdnsHostnameEdit', new Atomic.OrganismEditFormDdnsHostnameEditComponent());
app.component('organismEditFormDnsRecords', new Atomic.OrganismEditFormDnsRecordsComponent());
app.component('organismEditFormDnsTemplate', new Atomic.OrganismEditFormDnsTemplateComponent());
app.component('organismEditFormDnsZone', new Atomic.OrganismEditFormDnsZoneComponent());
app.component('organismEditFormDomain', new Atomic.OrganismEditFormDomainComponent());
app.component('organismEditFormDomainAuthInfoTwo', new Atomic.OrganismEditFormDomainAuthInfoTwoComponent());
app.component('organismEditFormDomainBulk', new Atomic.OrganismEditFormDomainBulkComponent());
app.component('organismEditFormDomainContact', new Atomic.OrganismEditFormDomainContactComponent());
app.component('organismEditFormDomainDirectoryProtection', new Atomic.OrganismEditFormDomainDirectoryProtectionComponent());
app.component('organismEditFormDomainLocations', new Atomic.OrganismEditFormDomainLocationsComponent());
app.component('organismEditFormDomainMove', new Atomic.OrganismEditFormDomainMoveComponent());
app.component('organismEditFormDomainProduct', new Atomic.OrganismEditFormDomainProductComponent());
app.component('organismEditFormDomainRemoveFromBundle', new Atomic.OrganismEditFormDomainRemoveFromBundleComponent());
app.component('organismEditFormExchangeOrganization', new Atomic.OrganismEditFormExchangeOrganizationComponent());
app.component('organismEditFormMachine', new Atomic.OrganismEditFormMachineComponent());
app.component('organismEditFormMachineDelete', new Atomic.OrganismEditFormMachineDeleteComponent());
app.component('organismEditFormMachineManagedProjectNew', new Atomic.OrganismEditFormMachineManagedProjectNewComponent());
app.component('organismEditFormMachineOsInstall', new Atomic.OrganismEditFormMachineOsInstallComponent());
app.component('organismEditFormMachineUpgrade', new Atomic.OrganismEditFormMachineUpgradeComponent());
app.component('organismEditFormMailbox', new Atomic.OrganismEditFormMailboxComponent());
app.component('organismEditFormMailboxDomainsettings', new Atomic.OrganismEditFormMailboxDomainsettingsComponent());
app.component('organismEditFormNameserverSet', new Atomic.OrganismEditFormNameserverSetComponent());
app.component('organismEditFormNameserverSetNew', new Atomic.OrganismEditFormNameserverSetNewComponent());
app.component('organismEditFormNetwork', new Atomic.OrganismEditFormNetworkComponent());
app.component('organismEditFormNextcloud', new Atomic.OrganismEditFormNextcloudComponent());
app.component('organismEditFormNextcloudAdminAccess', new Atomic.OrganismEditFormNextcloudAdminAccessComponent());
app.component('organismEditFormNextcloudBusinessUpgrades', new Atomic.OrganismEditFormNextcloudBusinessUpgradesComponent());
app.component('organismEditFormNextcloudConsumerUpgrade', new Atomic.OrganismEditFormNextcloudConsumerUpgradeComponent());
app.component('organismEditFormNextcloudOrderBackup', new Atomic.OrganismEditFormNextcloudOrderBackupComponent());
app.component('organismEditFormNextcloudRename', new Atomic.OrganismEditFormNextcloudRenameComponent());
app.component('organismEditFormNextcloudUpgrade', new Atomic.OrganismEditFormNextcloudUpgradeComponent());
app.component('organismEditFormNextcloudUseOwnDomain', new Atomic.OrganismEditFormNextcloudUseOwnDomainComponent());
app.component('organismEditFormProfile', new Atomic.OrganismEditFormProfileComponent());
app.component('organismEditFormResellerData', new Atomic.OrganismEditFormResellerDataComponent());
app.component('organismEditFormRestrictions', new Atomic.OrganismEditFormRestrictionsComponent());
app.component('organismEditFormRightsTemplate', new Atomic.OrganismEditFormRightsTemplateComponent());
app.component('organismEditFormSslCertificate', new Atomic.OrganismEditFormSslCertificateComponent());
app.component('organismEditFormSubAccount', new Atomic.OrganismEditFormSubAccountComponent());
app.component('organismEditFormSubAccountCreate', new Atomic.OrganismEditFormSubAccountCreateComponent());
app.component('organismEditFormUser', new Atomic.OrganismEditFormUserComponent());
app.component('organismEditFormUserAdmin', new Atomic.OrganismEditFormUserAdminComponent());
app.component('organismEditFormUserCreate', new Atomic.OrganismEditFormUserCreateComponent());
app.component('organismEditFormWebhostingUser', new Atomic.OrganismEditFormWebhostingUserComponent());
app.component('organismEditFormWebspaceAdminOptions', new Atomic.OrganismEditFormWebspaceAdminOptionsComponent());
app.component('organismEditFormWebspacePhpBoost', new Atomic.OrganismEditFormWebspacePhpBoostComponent());
app.component('organismEditFormWebspacePhpMinVersion', new Atomic.OrganismEditFormWebspacePhpMinVersionComponent());
app.component('organismEditFormWebspaceUsers', new Atomic.OrganismEditFormWebspaceUsersComponent());
app.component('organismEditFormWebspaceVhostSsl', new Atomic.OrganismEditFormWebspaceVhostSslComponent());
app.component('organismEmailWizardConfigView', new Atomic.OrganismEmailWizardConfigViewComponent());
app.component('organismEmailWizardConfirmView', new Atomic.OrganismEmailWizardConfirmViewComponent());
app.component('organismEmailWizardProductConfigCatchall', new Atomic.OrganismEmailWizardProductConfigCatchallComponent());
app.component('organismEmailWizardProductConfigExchangeMailbox', new Atomic.OrganismEmailWizardProductConfigExchangeMailboxComponent());
app.component('organismEmailWizardProductConfigForwarder', new Atomic.OrganismEmailWizardProductConfigForwarderComponent());
app.component('organismEmailWizardProductConfigMailbox', new Atomic.OrganismEmailWizardProductConfigMailboxComponent());
app.component('organismEmailWizardProductConfigMailingList', new Atomic.OrganismEmailWizardProductConfigMailingListComponent());
app.component('organismEmailWizardProductConfigSmtpForwarder', new Atomic.OrganismEmailWizardProductConfigSmtpForwarderComponent());
app.component('organismEmailWizardWrapper', new Atomic.OrganismEmailWizardWrapperComponent());
app.component('organismFormAppInstall', new Atomic.OrganismFormAppInstallComponent());
app.component('organismFormDisplayDnsRecordsUpdater', new Atomic.OrganismFormDisplayDnsRecordsUpdaterComponent());
app.component('organismFormDomainReports', new Atomic.OrganismFormDomainReportsComponent());
app.component('organismGlobalSearch', new Atomic.OrganismGlobalSearchComponent());
app.component('organismGlobalTranslationIdList', new Atomic.OrganismGlobalTranslationIdListComponent());
app.component('organismHelpdeskArticle', new Atomic.OrganismHelpdeskArticleComponent());
app.component('organismHelpdeskCategory', new Atomic.OrganismHelpdeskCategoryComponent());
app.component('organismHelpdeskDashboard', new Atomic.OrganismHelpdeskDashboardComponent());
app.component('organismMessageCallout', new Atomic.OrganismMessageCalloutComponent());
app.component('organismMachineWizardConfigView', new Atomic.OrganismMachineWizardConfigViewComponent());
app.component('organismMachineWizardConfirmView', new Atomic.OrganismMachineWizardConfirmViewComponent());
app.component('organismMachineWizardWrapper', new Atomic.OrganismMachineWizardWrapperComponent());
app.component('organismMachineWizardProductConfigIndividualVirtualMachine', new Atomic.OrganismMachineWizardProductConfigIndividualVirtualMachineComponent());
app.component('organismMachineWizardProductConfigEcommerceVirtualMachine', new Atomic.OrganismMachineWizardProductConfigEcommerceVirtualMachineComponent());
app.component('organismMachineWizardProductConfigManagedVirtualMachine', new Atomic.OrganismMachineWizardProductConfigManagedVirtualMachineComponent());
app.component('organismMachineWizardProductConfigVirtualMachine', new Atomic.OrganismMachineWizardProductConfigVirtualMachineComponent());

/* Testing managed project. */
app.component('organismManagedProjectWizardWrapper', new Atomic.OrganismManagedProjectWizardWrapperComponent());
app.component('organismManagedProjectWizardConfigView', new Atomic.OrganismManagedProjectWizardConfigViewComponent());
app.component('organismManagedProjectWizardConfirmView', new Atomic.OrganismManagedProjectWizardConfirmViewComponent());
/* Testing managed project. */

app.component('organismNewEditFormCronjob', new Atomic.OrganismNewEditFormCronjobComponent());
app.component('organismNewFormApiKey', new Atomic.OrganismNewFormApiKeyComponent());
app.component('organismNewFormDatabaseUser', new Atomic.OrganismNewFormDatabaseUserComponent());
app.component('organismNewFormDnsTemplate', new Atomic.OrganismNewFormDnsTemplateComponent());
app.component('organismNewFormDomainContact', new Atomic.OrganismNewFormDomainContactComponent());
app.component('organismNewFormExchangeOrganization', new Atomic.OrganismNewFormExchangeOrganizationComponent());
app.component('organismNewFormPool', new Atomic.OrganismNewFormPoolComponent());
app.component('organismNewFormWebhostingUser', new Atomic.OrganismNewFormWebhostingUserComponent());
app.component('organismNextcloudWizardConfigView', new Atomic.OrganismNextcloudWizardConfigViewComponent());
app.component('organismNextcloudWizardConfirmView', new Atomic.OrganismNextcloudWizardConfirmViewComponent());
app.component('organismNextcloudWizardProductConfig', new Atomic.OrganismNextcloudWizardProductConfigComponent());
app.component('organismNextcloudWizardWrapper', new Atomic.OrganismNextcloudWizardWrapperComponent());
app.component('organismOverview', new Atomic.OrganismOverviewComponent());
app.component('organismPageHeaderPanel', new Atomic.OrganismPageHeaderPanelComponent());
app.component('organismPagePermissions', new Atomic.OrganismPagePermissionsComponent());
app.component('organismPricelistOverview', new Atomic.OrganismPricelistOverviewComponent());
app.component('organismRecordContentChange', new Atomic.OrganismRecordContentChangeComponent());
app.component('organismRecordContentSearch', new Atomic.OrganismRecordContentSearchComponent());
app.component('organismRedirectionWizardConfigView', new Atomic.OrganismRedirectionWizardConfigViewComponent());
app.component('organismRedirectionWizardConfirmView', new Atomic.OrganismRedirectionWizardConfirmViewComponent());
app.component('organismRedirectionWizardProductConfig', new Atomic.OrganismRedirectionWizardProductConfigComponent());
app.component('organismRedirectionWizardWrapper', new Atomic.OrganismRedirectionWizardWrapperComponent());
app.component('organismSidebar', new Atomic.OrganismSidebarComponent());
app.component('organismWebspaceWizardConfigView', new Atomic.OrganismWebspaceWizardConfigViewComponent());
app.component('organismWebspaceWizardConfirmView', new Atomic.OrganismWebspaceWizardConfirmViewComponent());
app.component('organismWebspaceWizardProductConfig', new Atomic.OrganismWebspaceWizardProductConfigComponent());
app.component('organismWebspaceWizardProductGeneralConfig', new Atomic.OrganismWebspaceWizardProductGeneralConfigComponent());
app.component('organismWebspaceWizardProductVhostConfig', new Atomic.OrganismWebspaceWizardProductVhostConfigComponent());
app.component('organismWebspaceWizardProductUserConfig', new Atomic.OrganismWebspaceWizardProductUserConfigComponent());
app.component('organismWebspaceWizardWrapper', new Atomic.OrganismWebspaceWizardWrapperComponent());
app.component('organismWizardAccount', new Atomic.OrganismWizardAccountComponent());
app.component('organismWizardConfirmView', new Atomic.OrganismWizardConfirmViewComponent());
app.component('organismWizardGlobalProductSelection', new Atomic.OrganismWizardGlobalProductSelectionComponent());
app.component('organismWizardProductSelection', new Atomic.OrganismWizardProductSelectionComponent());
app.component('organismWizardSufficientCredit', new Atomic.OrganismWizardSufficientCreditComponent());
app.component('organismWizardSummaryView', new Atomic.OrganismWizardSummaryViewComponent());
app.component('organismWizardVerifiedHandler', new Atomic.OrganismWizardVerifiedHandlerComponent());

// ████████╗███████╗███╗   ███╗██████╗ ██╗      █████╗ ████████╗███████╗███████╗
// ╚══██╔══╝██╔════╝████╗ ████║██╔══██╗██║     ██╔══██╗╚══██╔══╝██╔════╝██╔════╝
//    ██║   █████╗  ██╔████╔██║██████╔╝██║     ███████║   ██║   █████╗  ███████╗
//    ██║   ██╔══╝  ██║╚██╔╝██║██╔═══╝ ██║     ██╔══██║   ██║   ██╔══╝  ╚════██║
//    ██║   ███████╗██║ ╚═╝ ██║██║     ███████╗██║  ██║   ██║   ███████╗███████║
//    ╚═╝   ╚══════╝╚═╝     ╚═╝╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚══════╝

app.component('templateAccountDashboard', new Components.TemplateAccountDashboardComponent());
app.component('templateAccountSettings', new Components.TemplateAccountSettingsComponent());
app.component('templateApiKeyEdit', new Components.TemplateApiKeyEditComponent());
app.component('templateApiKeyNew', new Components.TemplateApiKeyNewComponent());
app.component('templateAvv', new Components.TemplateAvvComponent());
app.component('templateBasicData', new Components.TemplateBasicDataComponent());
app.component('templateBillingDashboard', new Components.TemplateBillingDashboardComponent());
app.component('templateBillingPaymentOptions', new Components.TemplateBillingPaymentOptionsComponent());
app.component('templateBillingPricelistChangesOverview', new Components.TemplateBillingPricelistChangesOverviewComponent());
app.component('templateBillingPricelistDatabasesOverview', new Components.TemplateBillingPricelistDatabasesOverviewComponent());
app.component('templateBillingPricelistDnsOverview', new Components.TemplateBillingPricelistDnsOverviewComponent());
app.component('templateBillingPricelistDomainsOverview', new Components.TemplateBillingPricelistDomainsOverviewComponent());
app.component('templateBillingPricelistMachinesOverview', new Components.TemplateBillingPricelistMachinesOverviewComponent());
app.component('templateBillingPricelistMailboxesOverview', new Components.TemplateBillingPricelistMailboxesOverviewComponent());
app.component('templateBillingPricelistPromotionsOverview', new Components.TemplateBillingPricelistPromotionsOverviewComponent());
app.component('templateBillingPricelists', new Components.TemplateBillingPricelistsComponent());
app.component('templateBillingPricelistSslOverview', new Components.TemplateBillingPricelistSslOverviewComponent());
app.component('templateBillingPricelistStorageProductOverview', new Components.TemplateBillingPricelistStorageProductOverviewComponent());
app.component('templateBillingPricelistWebhostingOverview', new Components.TemplateBillingPricelistWebhostingOverviewComponent());
app.component('templateBundleAddDomain', new Components.TemplateBundleAddDomainComponent());

app.component('templateBundleCancelDelete', new Components.TemplateBundleCancelDeleteComponent());
app.component('templateBundleContractDetails', new Components.TemplateBundleContractDetailsComponent());
app.component('templateBundleDashboard', new Components.TemplateBundleDashboardComponent());
app.component('templateBundleDelete', new Components.TemplateBundleDeleteComponent());
app.component('templateBundleDomainsTransfer', new Components.TemplateBundleDomainsTransferComponent());
app.component('templateBundleOverview', new Components.TemplateBundleOverviewComponent());
app.component('templateBundleOverviewList', new Components.TemplateBundleOverviewListComponent());
app.component('templateBundleRename', new Components.TemplateBundleRenameComponent());
app.component('templateBundleStorageEdit', new Components.TemplateBundleStorageEditComponent());
app.component('templateBundleWizard', new Components.TemplateBundleWizardComponent());
app.component('templateContactDetails', new Components.TemplateContactDetailsComponent());
app.component('templateCronjobEdit', new Components.TemplateCronjobEditComponent());
app.component('templateCronjobNew', new Components.TemplateCronjobNewComponent());
app.component('templateCronjobOverview', new Components.TemplateCronjobOverviewComponent());

app.component('templateDatabaseDashboard', new Components.TemplateDatabaseDashboardComponent());
app.component('templateDatabaseEdit', new Components.TemplateDatabaseEditComponent());
app.component('templateDatabaseJobDetails', new Components.TemplateDatabaseJobDetailsComponent());
app.component('templateDatabaseJobOverview', new Components.TemplateDatabaseJobOverviewComponent());
app.component('templateDatabaseOverview', new Components.TemplateDatabaseOverviewComponent());
app.component('templateDatabaseUserEdit', new Components.TemplateDatabaseUserEditComponent());
app.component('templateDatabaseUserNew', new Components.TemplateDatabaseUserNewComponent());
app.component('templateDatabaseUserOverview', new Components.TemplateDatabaseUserOverviewComponent());
app.component('templateDatabaseWizard', new Components.TemplateDatabaseWizardComponent());
app.component('templateDataProcessing', new Components.TemplateDataProcessingComponent());
app.component('templateDdnsCredentialsEdit', new Components.TemplateDdnsCredentialsEditComponent());
app.component('templateDdnsCredentialsNew', new Components.TemplateDdnsCredentialsNewComponent());
app.component('templateDdnsCredentialsOverview', new Components.TemplateDdnsCredentialsOverviewComponent());
app.component('templateDdnsHostnamesEdit', new Components.TemplateDdnsHostnamesEditComponent());
app.component('templateDdnsHostnamesOverview', new Components.TemplateDdnsHostnamesOverviewComponent());
app.component('templateDnsDashboard', new Components.TemplateDnsDashboardComponent());
app.component('templateDnsJobDetails', new Components.TemplateDnsJobDetailsComponent());
app.component('templateDnsJobOverview', new Components.TemplateDnsJobOverviewComponent());
app.component('templateDnsTemplateCopy', new Components.TemplateDnsTemplateCopyComponent());
app.component('templateDnsTemplateEdit', new Components.TemplateDnsTemplateEditComponent());
app.component('templateDnsTemplateNew', new Components.TemplateDnsTemplateNewComponent());
app.component('templateDnsTemplateOverview', new Components.TemplateDnsTemplateOverviewComponent());
app.component('templateDnsWizard', new Components.TemplateDnsWizardComponent());
app.component('templateDnsZoneEdit', new Components.TemplateDnsZoneEditComponent());
app.component('templateDnsZoneEditMove', new Components.TemplateDnsZoneEditMoveComponent());
app.component('templateDnsZoneOverview', new Components.TemplateDnsZoneOverviewComponent());
app.component('templateDnsZoneRecordsEdit', new Components.TemplateDnsZoneRecordsEditComponent());
app.component('templateDomainAppInstall', new Components.TemplateDomainAppInstallComponent());
app.component('templateDomainAuthInfoTwo', new Components.TemplateDomainAuthInfoTwoComponent());
app.component('templateDomainAvailabilityCheck', new Components.TemplateDomainAvailabilityCheckComponent());
app.component('templateDomainContactNew', new Components.TemplateDomainContactNewComponent());
app.component('templateDomainContactsEdit', new Components.TemplateDomainContactsEditComponent());
app.component('templateDomainContactsOverview', new Components.TemplateDomainContactsOverviewComponent());
app.component('templateDomainContactsVerification', new Components.TemplateDomainContactsVerificationComponent());
app.component('templateDomainDashboard', new Components.TemplateDomainDashboardComponent());
app.component('templateDomainEdit', new Components.TemplateDomainEditComponent());
app.component('templateDomainEditBulk', new Components.TemplateDomainEditBulkComponent());
app.component('templateDomainJobDetails', new Components.TemplateDomainJobDetailsComponent());
app.component('templateDomainJobOverview', new Components.TemplateDomainJobOverviewComponent());
app.component('templateDomainLocations', new Components.TemplateDomainLocationsComponent());
app.component('templateDomainMove', new Components.TemplateDomainMoveComponent());
app.component('templateDomainMoveBulk', new Components.TemplateDomainMoveBulkComponent());
app.component('templateDomainOverview', new Components.TemplateDomainOverviewComponent());
app.component('templateDomainProductEdit', new Components.TemplateDomainProductEditComponent());
app.component('templateDomainRecordsEdit', new Components.TemplateDomainRecordsEditComponent());
app.component('templateDomainRemoveFromBundle', new Components.TemplateDomainRemoveFromBundleComponent());
app.component('templateDomainSetup', new Components.TemplateDomainSetupComponent());
app.component('templateDomainReports', new Components.TemplateDomainReportsComponent());
app.component('templateDomainTransferOverview', new Components.TemplateDomainTransferOverviewComponent());
app.component('templateDomainWizard', new Components.TemplateDomainWizardComponent());
app.component('templateEmailJobOverview', new Components.TemplateEmailJobOverviewComponent());
app.component('templateEmailMailboxJobDetails', new Components.TemplateEmailMailboxJobDetailsComponent());
app.component('templateEmailOverview', new Components.TemplateEmailOverviewComponent());
app.component('templateEmailWizard', new Components.TemplateEmailWizardComponent());
app.component('templateExchangeOrganizationEdit', new Components.TemplateExchangeOrganizationEditComponent());
app.component('templateExchangeOrganizationNew', new Components.TemplateExchangeOrganizationNewComponent());
app.component('templateExchangeOrganizationOverview', new Components.TemplateOrganizationOverviewComponent());
app.component('templateFromCart', new Components.TemplateFromCartComponent());
app.component('templateHelpdesk', new Components.TemplateHelpdeskComponent());
app.component('templateInvoiceEdit', new Components.TemplateInvoiceEditComponent());
app.component('templateInvoiceOverview', new Components.TemplateInvoiceOverviewComponent());
app.component('templateInvoicePaymentCancel', new Components.TemplateInvoicePaymentCancelComponent());
app.component('templateInvoicePaymentCommit', new Components.TemplateInvoicePaymentCommitComponent());
app.component('templateLogin', new Components.TemplateLoginComponent());
app.component('templateMachineDashboard', new Components.TemplateMachineDashboardComponent());
app.component('templateMachineEdit', new Components.TemplateMachineEditComponent());
app.component('templateMachineInterfaces', new Components.TemplateMachineInterfacesComponent());
app.component('templateMachineJobDetails', new Components.TemplateMachineJobDetailsComponent());
app.component('templateMachineJobOverview', new Components.TemplateMachineJobOverviewComponent());
app.component('templateMachineManagedDashboard', new Components.TemplateMachineManagedDashboardComponent());
app.component('templateMachineManagedDelete', new Components.TemplateMachineManagedDeleteComponent());
app.component('templateMachineManagedOverview', new Components.TemplateMachineManagedOverviewComponent());
app.component('templateMachineManagedOverviewList', new Components.TemplateMachineManagedOverviewListComponent());
app.component('templateMachineManagedProjectNew', new Components.TemplateMachineManagedProjectNewComponent());
app.component('templateMachineManagedSetup', new Components.TemplateMachineManagedSetupComponent());
app.component('templateMachineOsInstall', new Components.TemplateMachineOsInstallComponent());
app.component('templateMachinePoolNew', new Components.TemplateMachinePoolNewComponent());
app.component('templateMachinePoolOverview', new Components.TemplateMachinePoolOverviewComponent());
app.component('templateMachineUpgrade', new Components.TemplateMachineUpgradeComponent());
app.component('templateMachineSetup', new Components.TemplateMachineSetupComponent());
app.component('templateMachineVnc', new Components.TemplateMachineVncComponent());
app.component('templateMachineWizard', new Components.TemplateMachineWizardComponent());
app.component('templateMailboxDashboard', new Components.TemplateMailboxDashboardComponent());
app.component('templateMailboxDomainsettings', new Components.TemplateMailboxDomainsettingsComponent());
app.component('templateMailboxEdit', new Components.TemplateMailboxEditComponent());
app.component('templateMainDashboard', new Components.TemplateMainDashboardComponent());
app.component('templateMainNewDashboard', new Components.TemplateMainNewDashboardComponent());
app.component('templateMainNewDashboardEdit', new Components.TemplateMainNewDashboardEditComponent());
app.component('templateManagedProjectWizardNew', new Components.TemplateManagedProjectWizardNewComponent());
app.component('templateNameserverSetEdit', new Components.TemplateNameserverSetEditComponent());
app.component('templateNameserverSetNew', new Components.TemplateNameserverSetNewComponent());
app.component('templateNameserverSetOverview', new Components.TemplateNameserverSetOverviewComponent());
app.component('templateNetworkDashboard', new Components.TemplateNetworkDashboardComponent());
app.component('templateNetworkEdit', new Components.TemplateNetworkEditComponent());
app.component('templateNetworkOverview', new Components.TemplateNetworkOverviewComponent());
app.component('templateNextcloudAddons', new Components.TemplateNextcloudAddonsComponent());
app.component('templateNextcloudAdminAccess', new Components.TemplateNextcloudAdminAccessComponent());
app.component('templateNextcloudDelete', new Components.TemplateNextcloudDeleteComponent());

app.component('templateNextcloudRename', new Components.TemplateNextcloudRenameComponent());
app.component('templateNextcloudRestore', new Components.TemplateNextcloudRestoreComponent());
app.component('templateNextcloudUpgrade', new Components.TemplateNextcloudUpgradeComponent());
app.component('templateNextcloudUseOwnDomain', new Components.TemplateNextcloudUseOwnDomainComponent());
app.component('templateNextcloudWizard', new Components.TemplateNextcloudWizardComponent());
app.component('templatePageLoadError', new Components.TemplatePageLoadErrorComponent());
app.component('templatePasswordReset', new Components.TemplatePasswordResetComponent());
app.component('templatePaymentOverview', new Components.TemplatePaymentOverviewComponent());
app.component('templateProfile', new Components.TemplateProfileComponent());
app.component('templateRecordContentChange', new Components.TemplateRecordContentChangeComponent());
app.component('templateRecordContentSearch', new Components.TemplateRecordContentSearchComponent());
app.component('templateRedirectionEdit', new Components.TemplateRedirectionEditComponent());
app.component('templateRedirectionOverview', new Components.TemplateRedirectionOverviewComponent());
app.component('templateRedirectionWizard', new Components.TemplateRedirectionWizardComponent());
app.component('templateResellerDashboard', new Components.TemplateResellerDashboardComponent());
app.component('templateResellerSettings', new Components.TemplateResellerSettingsComponent());
app.component('templateRestrictionsEdit', new Components.TemplateRestrictionsEditComponent());
app.component('templateRightsTemplateEdit', new Components.TemplateRightsTemplateEditComponent());
app.component('templateRightsTemplateNew', new Components.TemplateRightsTemplateNewComponent());
app.component('templateRightsTemplates', new Components.TemplateRightsTemplatesComponent());
app.component('templateSeeYouSoon', new Components.TemplateSeeYouSoonComponent());
app.component('templateSetupBundle', new Components.TemplateSetupBundleComponent());
app.component('templateSetupIdNotFound', new Components.TemplateSetupOrderIdNotFoundComponent());
app.component('templateSetupJobPanel', new Components.TemplateSetupJobPanelComponent());
app.component('templateSetupSepaPanel', new Components.TemplateSetupSepaPanelComponent());
app.component('templateSetupWelcomePanel', new Components.TemplateSetupWelcomePanelComponent());
app.component('templateSslCertificateDashboard', new Components.TemplateSslCertificateDashboardComponent());
app.component('templateSslCertificateEdit', new Components.TemplateSslCertificateEditComponent());
app.component('templateSslCertificateJobDetails', new Components.TemplateSslCertificateJobDetailsComponent());
app.component('templateSslCertificateJobOverview', new Components.TemplateSslCertificateJobOverviewComponent());
app.component('templateSslCertificateOverview', new Components.TemplateSslCertificateOverviewComponent());
app.component('templateSslCertificateWizard', new Components.TemplateSslCertificateWizardComponent());
app.component('templateStorageDashboard', new Components.TemplateStorageDashboardComponent());
app.component('templateStorageJobDetails', new Components.TemplateStorageJobDetailsComponent());
app.component('templateStorageJobOverview', new Components.TemplateStorageJobOverviewComponent());
app.component('templateStorageOverview', new Components.TemplateStorageOverviewComponent());
app.component('templateStorageProductAppCenter', new Components.TemplateStorageProductAppCenterComponent());
app.component('templateStorageProductAppConfig', new Components.TemplateStorageProductAppConfigComponent());
app.component('templateStorageProductConfig', new Components.TemplateStorageProductConfigComponent());
app.component('templateStorageProductEdit', new Components.TemplateStorageProductEditComponent());
app.component('templateStorageSetup', new Components.TemplateStorageSetupComponent());
app.component('templateSubAccountCreate', new Components.TemplateSubAccountCreateComponent());
app.component('templateSubAccountEdit', new Components.TemplateSubAccountEditComponent());
app.component('templateSubAccountOverview', new Components.TemplateSubAccountOverviewComponent());
app.component('templateSuppliers', new Components.TemplateSuppliersComponent());
app.component('templateToms', new Components.TemplateTomsComponent());
app.component('templateUserCreate', new Components.TemplateUserCreateComponent());
app.component('templateUserEdit', new Components.TemplateUserEditComponent());
app.component('templateUserEditAdmin', new Components.TemplateUserEditAdminComponent());
app.component('templateUserList', new Components.TemplateUserListComponent());
app.component('templateVirtualMachineOverview', new Components.TemplateVirtualMachineOverviewComponent());
app.component('templateWebhostingDashboard', new Components.TemplateWebhostingDashboardComponent());
app.component('templateWebhostingJobDetails', new Components.TemplateWebhostingJobDetailsComponent());
app.component('templateWebhostingJobOverview', new Components.TemplateWebhostingJobOverviewComponent());
app.component('templateWebhostingSetup', new Components.TemplateWebhostingSetupComponent());
app.component('templateWebhostingUserEdit', new Components.TemplateWebhostingUserEditComponent());
app.component('templateWebhostingUserNew', new Components.TemplateWebhostingUserNewComponent());
app.component('templateWebhostingVhostDirectoryProtection', new Components.TemplateWebhostingVhostDirectoryProtectionComponent());
app.component('templateWebhostingVhostOverview', new Components.TemplateWebhostingVhostOverviewComponent());
app.component('templateWebhostingVhostSslEdit', new Components.TemplateWebhostingVhostSslEditComponent());
app.component('templateWebspaceAccessAddUserOverview', new Components.TemplateWebspaceAccessAddUserOverviewComponent());
app.component('templateWebspaceAccessOverview', new Components.TemplateWebspaceAccessOverviewComponent());
app.component('templateWebspaceAdminOptions', new Components.TemplateWebspaceAdminOptionsComponent());
app.component('templateWebspaceEdit', new Components.TemplateWebspaceEditComponent());
app.component('templateWebspaceNew', new Components.TemplateWebspaceNewComponent());
app.component('templateWebspaceOverview', new Components.TemplateWebspaceOverviewComponent());
app.component('templateWebspacePhpBoost', new Components.TemplateWebspacePhpBoostComponent());
app.component('templateWebspacePhpMinVersion', new Components.TemplateWebspacePhpMinVersionComponent());
app.component('templateWebspaceStorage', new Components.TemplateWebspaceStorageComponent());
app.component('templateWebspaceUserOverview', new Components.TemplateWebspaceUserOverviewComponent());
app.component('templateWebspaceWizard', new Components.TemplateWebspaceWizardComponent());
app.component('templateWizardGlobal', new Components.TemplateWizardGlobalComponent());

app.component('templateBundleBackups', new Components.TemplateBundleBackupsComponent());
app.component('templateDatabaseBackups', new Components.TemplateDatabaseBackupsComponent());
app.component('templateNextcloudOrderBackup', new Components.TemplateNextcloudOrderBackupComponent());
app.component('templateWebspaceBackups', new Components.TemplateWebspaceBackupsComponent());
app.component('templateStorageBackup', new Components.TemplateStorageProductBackupComponent());

// ================================================================================================
// ██████╗ ███████╗██╗   ██╗███████╗██╗      ██████╗ ██████╗ ███╗   ███╗███████╗███╗   ██╗████████╗
// ██╔══██╗██╔════╝██║   ██║██╔════╝██║     ██╔═══██╗██╔══██╗████╗ ████║██╔════╝████╗  ██║╚══██╔══╝
// ██║  ██║█████╗  ██║   ██║█████╗  ██║     ██║   ██║██████╔╝██╔████╔██║█████╗  ██╔██╗ ██║   ██║
// ██║  ██║██╔══╝  ╚██╗ ██╔╝██╔══╝  ██║     ██║   ██║██╔═══╝ ██║╚██╔╝██║██╔══╝  ██║╚██╗██║   ██║
// ██████╔╝███████╗ ╚████╔╝ ███████╗███████╗╚██████╔╝██║     ██║ ╚═╝ ██║███████╗██║ ╚████║   ██║
// ╚═════╝ ╚══════╝  ╚═══╝  ╚══════╝╚══════╝ ╚═════╝ ╚═╝     ╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝   ╚═╝
// ================================================================================================

app.service('requestCounter', Development.RequestCounterService);

// =================================================
// ███████╗██╗██╗  ████████╗███████╗██████╗ ███████╗
// ██╔════╝██║██║  ╚══██╔══╝██╔════╝██╔══██╗██╔════╝
// █████╗  ██║██║     ██║   █████╗  ██████╔╝███████╗
// ██╔══╝  ██║██║     ██║   ██╔══╝  ██╔══██╗╚════██║
// ██║     ██║███████╗██║   ███████╗██║  ██║███████║
// ╚═╝     ╚═╝╚══════╝╚═╝   ╚══════╝╚═╝  ╚═╝╚══════╝
// =================================================

app.filter('accountName', Filters.AccountName.Factory);
app.filter('apiValue', Filters.ApiValue.Factory);
app.filter('articleDetails', Filters.ArticleDetails.Factory);
app.filter('bundleName', Filters.BundleName.Factory);
app.filter('bundleNameSync', Filters.BundleNameSync.Factory);
app.filter('calculatePriceTax', Filters.CalculatePriceTax.Factory);
app.filter('calculatePrice', Filters.CalculatePrice.Factory);
app.filter('contactName', Filters.ContactName.Factory);
app.filter('contactNameAdditionalInfo', Filters.ContactNameAdditionalInfo.Factory);
app.filter('convertAmount', Filters.ConvertAmount.Factory);
app.filter('country', Filters.Country.Factory);
app.filter('ddnsUserName', Filters.DdnsUserName.Factory);
app.filter('dnsTemplateName', Filters.DnsTemplateName.Factory);
app.filter('domainCheckStatus', Filters.DomainCheckStatus.Factory);
app.filter('domainDeletionType', Filters.DomainDeletionType.Factory);
app.filter('domainState', Filters.DomainState.Factory);
app.filter('floatToApi', Filters.FloatToApi.Factory);
app.filter('formatMegabyte', Filters.FormatMegabyte.Factory);
app.filter('handleProperties', Filters.HandleProperties.Factory);
app.filter('handleProperty', Filters.HandleProperty.Factory);
app.filter('jobAction', Filters.JobAction.Factory);
app.filter('jobExecutionDate', Filters.JobExecutionDate.Factory);
app.filter('jobState', Filters.JobState.Factory);
app.filter('join', Filters.Join.Factory);
app.filter('languageSetting', Filters.LanguageSetting.Factory);
app.filter('localDateTime', Filters.DateTimeFilter.Factory);
app.filter('machinePower', Filters.MachinePower.Factory);
app.filter('machineProduct', Filters.MachineProduct.Factory);
app.filter('machineTotalDiskSpace', Filters.MachineTotalDiskSpace.Factory);
app.filter('numberSeparation', Filters.NumberSeparation.Factory);
app.filter('objectStatus', Filters.ObjectStatus.Factory);
app.filter('objectType', Filters.ObjectType.Factory);
app.filter('organizationName', Filters.OrganizationName.Factory);
app.filter('shorten', Filters.Shorten.Factory);
app.filter('storageQuota', Filters.StorageQuota.Factory);
app.filter('stringReplace', Filters.StringReplace.Factory);
app.filter('translateContractPeriod', Filters.ContractPeriodTranslation.Factory);
app.filter('translateProductCode', Filters.TranslateProductCode.Factory);
app.filter('virtualmachineHost', Filters.VirtualMachineHost.Factory);
app.filter('webspaceIpAddress', Filters.WebspaceIpAddress.Factory);
app.filter('webspaceName', Filters.WebspaceName.Factory);
app.filter('zoneName', Filters.ZoneName.Factory);

// ============================================================
// ███████╗███████╗██████╗ ██╗   ██╗██╗ ██████╗███████╗███████╗
// ██╔════╝██╔════╝██╔══██╗██║   ██║██║██╔════╝██╔════╝██╔════╝
// ███████╗█████╗  ██████╔╝██║   ██║██║██║     █████╗  ███████╗
// ╚════██║██╔══╝  ██╔══██╗╚██╗ ██╔╝██║██║     ██╔══╝  ╚════██║
// ███████║███████╗██║  ██║ ╚████╔╝ ██║╚██████╗███████╗███████║
// ╚══════╝╚══════╝╚═╝  ╚═╝  ╚═══╝  ╚═╝ ╚═════╝╚══════╝╚══════╝
// ============================================================

app.provider('cancelLocalIntervals', Services.CancelLocalIntervalsProvider);
app.provider('localInterval', Services.LocalIntervalProvider);
app.provider('redirectOnNotFound', Services.RedirectOnNotFoundProvider);

app.service('accountClient', Services.AccountClientService);
app.service('accountHelper', Services.AccountHelperService);
app.service('accountModel', Services.AccountModelService);
app.service('accountTaskManager', Services.AccountTaskManagerService);
app.service('alertManager', Services.AlertManagerService);
app.service('apiKeyModel', Services.ApiKeyModelService);
app.service('apiKeyOverviewModel', Services.ApiKeyOverviewModelService);
app.service('apiObjectHelper', Services.ApiObjectHelperService);
app.service('appInstallerRobot', Services.AppInstallerRobotService);
app.service('articleSearchModel', Services.ArticleSearchModelService);
app.service('asteriskNote', Services.AsteriskNoteService);
app.service('asteriskNote', Services.AsteriskNoteService);
app.service('authContext', Services.AuthContextService);
app.service('authenticationClient', Services.AuthenticationClientService);
app.service('authenticationRobot', Services.AuthenticationRobotService);
app.service('backupClient', Services.BackupClientService);
app.service('backupModel', Services.BackupModelService);
app.service('backupRobot', Services.BackupRobotService);
app.service('billingClient', Services.BillingClientService);
app.service('billingHelper', Services.BillingHelperService);
app.service('billingRobot', Services.BillingRobotService);
app.service('breadcrumbAccountTopicModel', Atomic.BreadcrumbAccountTopicModel);
app.service('breadcrumbBillingTopicModel', Atomic.BreadcrumbBillingTopicModel);
app.service('breadcrumbBundleTopicModel', Atomic.BreadcrumbBundleTopicModel);
app.service('breadcrumbDashboardTopicModel', Atomic.BreadcrumbDashboardTopicModel);
app.service('breadcrumbDatabaseTopicModel', Atomic.BreadcrumbDatabaseTopicModel);
app.service('breadcrumbDnsTopicModel', Atomic.BreadcrumbDnsTopicModel);
app.service('breadcrumbDomainTopicModel', Atomic.BreadcrumbDomainTopicModel);
app.service('breadcrumbErrorTopicModel', Atomic.BreadcrumbErrorTopicModel);
app.service('breadcrumbHelpdeskTopicModel', Atomic.BreadcrumbHelpdeskTopicModel);
app.service('breadcrumbMachineTopicModel', Atomic.BreadcrumbMachineTopicModel);
app.service('breadcrumbMailboxTopicModel', Atomic.BreadcrumbMailboxTopicModel);
app.service('breadcrumbManagedMachineTopicModel', Atomic.BreadcrumbManagedMachineTopicModel);
app.service('breadcrumbNetworkTopicModel', Atomic.BreadcrumbNetworkTopicModel);
app.service('breadcrumbNextcloudTopicModel', Atomic.BreadcrumbNextcloudTopicModel);
app.service('breadcrumbProfileTopicModel', Atomic.BreadcrumbProfileTopicModel);
app.service('breadcrumbResellerTopicModel', Atomic.BreadcrumbResellerTopicModel);
app.service('breadcrumbSslTopicModel', Atomic.BreadcrumbSslTopicModel);
app.service('breadcrumbStorageTopicModel', Atomic.BreadcrumbStorageTopicModel);
app.service('breadcrumbTopicModel', Atomic.BreadcrumbTopicModel);
app.service('breadcrumbWebhostingTopicModel', Atomic.BreadcrumbWebhostingTopicModel);
app.service('bundleClient', Services.BundleClientService);
app.service('bundleComposer', Services.BundleComposerService);
app.service('bundleHelper', Services.BundleHelperService);
app.service('bundleModel', Services.BundleModelService);
app.service('bundleOverviewModel', Services.BundleOverviewModel);
app.service('bundleRobot', Services.BundleRobotService);
app.service('bundleWizardConfirmation', Atomic.BundleWizardConfirmation);
app.service('bundleWizardConfirmation', Atomic.BundleWizardConfirmation);
app.service('cache', Services.CacheService);
app.service('countryList', Services.CountryListService);
app.service('cronjobOverviewModel', Services.CronjobOverviewModel);
app.service('cronJobTimeDisplayRenderer', Services.CronJobTimeDisplayRenderer);
app.service('cryptoTools', Services.CryptoToolsService);
app.service('csrDecoder', Services.CsrDecoderService);
app.service('dashboardHelper', Services.DashboardHelperService);
app.service('dashboardWidgetAccountBalanceModel', Atomic.DashboardWidgetAccountBalanceModel);
app.service('dashboardWidgetAdditionalLinksModel', Atomic.DashboardWidgetAdditionalLinksModel);
app.service('dashboardWidgetDomainSearchModel', Atomic.DashboardWidgetDomainSearchModel);
app.service('dashboardWidgetEmptyHintModel', Atomic.DashboardWidgetEmptyHintModel);
app.service('dashboardWidgetIncompleteUserProfileModel', Atomic.DashboardWidgetIncompleteUserProfileModel);
app.service('dashboardWidgetModel', Atomic.DashboardWidgetModel);
app.service('dashboardWidgetModelOperator', Atomic.DashboardWidgetModelOperator);
app.service('dashboardWidgetOverviewContactVerificationModel', Atomic.DashboardWidgetOverviewContactVerificationModel);
app.service('dashboardWidgetOverviewDomainTransfersOutModel', Atomic.DashboardWidgetOverviewDomainTransfersOutModel);
app.service('dashboardWidgetPrivacyNotifierModel', Atomic.DashboardWidgetPrivacyNotifierModel);
app.service('dashboardWidgetProductBundleModel', Atomic.DashboardWidgetProductBundleModel);
app.service('dashboardWidgetProductNextcloudModel', Atomic.DashboardWidgetProductNextcloudModel);
app.service('dashboardWidgetProductWebspaceModel', Atomic.DashboardWidgetProductWebspaceModel);
app.service('dashboardWidgetSepaInfoModel', Atomic.DashboardWidgetSepaInfoModel);
app.service('dashboardWidgetSmsValidationModel', Atomic.DashboardWidgetSmsValidationModel);
app.service('databaseClient', Services.DatabaseClientService);
app.service('databaseComposer', Services.DatabaseComposerService);
app.service('databaseJobModel', Services.DatabaseJobModelService);
app.service('databaseJobOverviewModel', Services.DatabaseJobOverviewModel);
app.service('databaseModel', Services.DatabaseModelService);
app.service('databaseOverviewModel', Services.DatabaseOverviewModel);
app.service('databaseRobot', Services.DatabaseRobotService);
app.service('databaseUserModel', Services.DatabaseUserModelService);
app.service('databaseUserOverviewModel', Services.DatabaseUserOverviewModel);
app.service('databaseWizardConfirmation', Atomic.DatabaseWizardConfirmation);
app.service('ddnsCredentialsOverviewModel', Services.DdnsCredentialsOverviewModel);
app.service('ddnsHostnameOverviewModel', Services.DdnsHostnameOverviewModel);
app.service('ddnsModel', Services.DdnsModelService);
app.service('depositModel', Services.DepositModelService);
app.service('dnsClient', Services.DnsClientService);
app.service('dnsComposer', Services.DnsComposerService);
app.service('dnsHelper', Services.DnsHelperService);
app.service('dnsJobModel', Services.DnsJobModelService);
app.service('dnsJobOverviewModel', Services.DnsJobOverviewModel);
app.service('dnsRobot', Services.DnsRobotService);
app.service('dnsTemplateModel', Services.DnsTemplateModelService);
app.service('dnsTemplateOverviewModel', Services.DnsTemplateOverviewModel);
app.service('dnsWizardConfirmation', Atomic.DnsWizardConfirmation);
app.service('dnsZoneModel', Services.DnsZoneModelService);
app.service('dnsZoneOverviewModel', Services.DnsZoneOverviewModel);
app.service('documentHelper', Services.DocumentHelperService);
app.service('documentModel', Services.DocumentModelService);
app.service('domainChecker', Services.DomainCheckerService);
app.service('domainClient', Services.DomainClientService);
app.service('domainComposer', Services.DomainComposerService);
app.service('domainContactHelper', Services.DomainContactHelperService);
app.service('domainContactsOverviewModel', Services.DomainContactsOverviewModel);
app.service('domainContactsVerificationOverviewModel', Services.DomainContactsVerificationOverviewModel);
app.service('domainContactValidator', Services.DomainContactValidatorService);
app.service('domainHandleModel', Services.DomainHandleModelService);
app.service('domainHelper', Services.DomainHelperService);
app.service('domainInfoHelper', Services.DomainInfoHelperService);
app.service('domainJobModel', Services.DomainJobModelService);
app.service('domainJobOverviewModel', Services.DomainJobOverviewModel);
app.service('domainModel', Services.DomainModelService);
app.service('domainOverviewModel', Services.DomainOverviewModel);
app.service('domainReports', Services.DomainReportsService);
app.service('domainRobot', Services.DomainRobotService);
app.service('domainStatistics', Services.DomainStatisticsService);
app.service('domainTransferOverviewModel', Services.DomainTransferOverviewModel);
app.service('domainVhostOverviewModel', Services.DomainVhostOverviewModel);
app.service('domainWizardConfirmation', Atomic.DomainWizardConfirmation);
app.service('emailClient', Services.EmailClientService);
app.service('emailComposer', Services.EmailComposerService);
app.service('emailJobModel', Services.EmailJobModelService);
app.service('emailJobOverviewModel', Services.EmailJobOverviewModel);
app.service('emailRobot', Services.EmailRobotService);
app.service('emailWizardConfirmation', Atomic.EmailWizardConfirmation);
app.service('emailWizardConfirmation', Atomic.EmailWizardConfirmation);
app.service('errorMapper', Services.ErrorMapperService);
app.service('faqManager', Services.FaqManagerService);
app.service('fetchClient', Services.FetchClientService);
app.service('fileSaver', Services.FileSaverService);
app.service('fileServerClient', Services.FileServerClientService);
app.service('fileServerRobot', Services.FileServerRobotService);
app.service('filterCache', Services.FilterCacheService);
app.service('functionRelay', Services.FunctionRelayService);
app.service('generalCreate', Services.GeneralCreateService);
app.service('getEntryPoint', Services.GetEntryPointService);
app.service('globalSearchSources', Atomic.GlobalSearchSources);
app.service('helpdeskRobotService', Services.HelpdeskRobotService);
app.service('installerModel', Services.InstallerModelService);
app.service('integrationHelper', Services.IntegrationHelperService);
app.service('invoiceOverviewModel', Services.InvoiceOverviewModel);
app.service('localstorageHelper', Services.LocalstorageHelperService);
app.service('machineClient', Services.MachineClientService);
app.service('machineComposer', Services.MachineComposerService);
app.service('machineJobModel', Services.MachineJobModelService);
app.service('machineJobOverviewModel', Services.MachineJobOverviewModel);
app.service('machineModel', Services.MachineModelService);
app.service('machinePoolsOverviewModel', Services.MachinePoolsOverviewModel);
app.service('machineProducts', Services.MachineProductsService);
app.service('machineRobot', Services.MachineRobotService);
app.service('machineWizardConfirmation', Atomic.MachineWizardConfirmation);
app.service('mailboxModel', Services.MailboxModelService);
app.service('mailboxOverviewModel', Services.MailboxOverviewModel);
app.service('managedApplicationClient', Services.ManagedApplicationClientService);
app.service('managedApplicationComposer', Services.ManagedApplicationComposerService);
app.service('managedApplicationRobot', Services.ManagedApplicationRobotService);
app.service('managedMachineOverviewModel', Services.ManagedMachineOverviewModel);
app.service('managedServerProjectComposer', Services.ManagedServerProjectComposerService);
app.service('managedServerProjectWizardConfirmation', Atomic.ManagedServerProjectWizardConfirmation);
app.service('managedServerProjectWizardConfirmation', Atomic.ManagedServerProjectWizardConfirmation);
app.service('nameserverSetModel', Services.NameserverSetModelService);
app.service('nameserverSetOverviewModel', Services.NameserverSetOverviewModel);
app.service('navigation', Services.NavigationService);
app.service('routeFinder', Services.RouteFinderService);
app.service('networkModel', Services.NetworkModelService);
app.service('networkOverviewModel', Services.NetworkOverviewModel);
app.service('networkRobot', Services.NetworkRobotService);
app.service('nextcloudHelper', Services.NextcloudHelperService);
app.service('nextcloudAppHelper', Services.NextcloudAppHelperService);
app.service('nextcloudWizardConfirmation', Atomic.NextcloudWizardConfirmation);
app.service('nextcloudWizardConfirmation', Atomic.NextcloudWizardConfirmation);
app.service('nominetRegistrarList', Services.NominetRegistrarListService);
app.service('notificationsHelper', Services.NotificationsHelperService);
app.service('orchestratorClient', Services.OrchestratorClientService);
app.service('orchestratorModel', Services.OrchestratorModelService);
app.service('orchestratorRobot', Services.OrchestratorRobotService);
app.service('organizationModel', Services.OrganizationModelService);
app.service('organizationOverviewModel', Services.OrganizationOverviewModel);
app.service('osInstallerRobot', Services.OsInstallerRobotService);
app.service('overviewHelper', Services.OverviewHelperService);
app.service('paymentModel', Services.PaymentModelService);
app.service('paymentOverviewModel', Services.PaymentOverviewModel);
app.service('priceCache', Services.PriceCacheService);
app.service('priceHelper', Services.PriceHelperService);
app.service('pricelistHelper', Services.PricelistHelperService);
app.service('priceModel', Services.PriceModelService);
app.service('productHelper', Services.ProductHelperService);
app.service('productsClient', Services.ProductsClientService);
app.service('productsModel', Services.ProductsModelService);
app.service('productSpecs', Services.ProductSpecificationsService);
app.service('productsRobot', Services.ProductsRobotService);
app.service('promoManager', Services.PromoManagerService);
app.service('redirectionModel', Services.RedirectionModelService);
app.service('redirectionOverviewModel', Services.RedirectionOverviewModel);
app.service('redirectionWizardConfirmation', Atomic.RedirectionWizardConfirmation);
app.service('RequestCanceller', Services.RequestCancellerService);
app.service('resourceClient', Services.ResourceClientService);
app.service('resourceHelper', Services.ResourceHelperService);
app.service('resourceModel', Services.ResourceModelService);
app.service('resourceRobot', Services.ResourceRobotService);
app.service('restrictionHelper', Services.RestrictionHelperService);
app.service('rightModel', Services.RightModelService);
app.service('rightsTemplateOverviewModel', Services.RightsTemplateOverviewModelService);
app.service('rpcClient', Services.RpcClientService);
app.service('scrollToValidationError', Services.ScrollToValidationError);
app.service('selectBoxContentCreator', Services.SelectBoxContentCreator);
app.service('selectBoxContentCreatorNew', Services.SelectBoxContentCreatorNew);
app.service('spamProfileService', Services.SpamProfileService);
app.service('sslCertificateModel', Services.SslCertificateModelService);
app.service('sslClient', Services.SslClientService);
app.service('sslComposer', Services.SslComposerService);
app.service('sslJobModel', Services.SslJobModelService);
app.service('sslJobOverviewModel', Services.SslJobOverviewModel);
app.service('sslOrderModel', Services.SslOrderModelService);
app.service('sslOverviewModel', Services.SslOverviewModel);
app.service('sslRobot', Services.SslRobotService);
app.service('sslWizardConfirmation', Atomic.SslWizardConfirmation);
app.service('storageAppConfig', Services.StorageAppConfigService);
app.service('storageJobModel', Services.StorageJobModelService);
app.service('storageJobOverviewModel', Services.StorageJobOverviewModel);
app.service('storageModel', Services.StorageModelService);
app.service('storageOverviewModel', Services.StorageOverviewModel);
app.service('subAccountOverviewModel', Services.SubAccountOverviewModelService);
app.service('switchUserManager', Services.SwitchUserManagerService);
app.service('systemHelper', Services.SystemHelperService);
app.service('tldList', Services.TldListService);
app.service('twoFaHelper', Services.TwoFaHelperService);
app.service('userModel', Services.UserModelService);
app.service('userOverviewModel', Services.UserOverviewModelService);
app.service('userOverviewModel', Services.UserOverviewModelService);
app.service('userRobot', Services.UserRobotService);
app.service('userSettings', Services.UserSettingsService);
app.service('userSettingsManager', Services.UserSettingsManagerService);
app.service('userSettingsMigrator', Services.UserSettingsMigratorService);
app.service('userSettingsModel', Services.UserSettingsModelService);
app.service('vhostModel', Services.VhostModelService);
app.service('vhostOverviewModel', Services.VhostOverviewModel);
app.service('legacyPhpDomainsOverviewModel', Services.LegacyPhpDomainsOverviewModel);
app.service('legacyPhpVhostOverviewModel', Services.LegacyPhpVhostOverviewModel);
app.service('virtualMachineOverviewModel', Services.VirtualMachineOverviewModel);
app.service('webhostingClient', Services.WebhostingClientService);
app.service('webhostingHelper', Services.WebhostingHelperService);
app.service('webhostingJobModel', Services.WebhostingJobModelService);
app.service('webhostingJobOverviewModel', Services.WebhostingJobOverviewModel);
app.service('webhostingRobot', Services.WebhostingRobotService);
app.service('webhostingUserModel', Services.WebhostingUserModelService);
app.service('webspaceComposer', Services.WebspaceComposerService);
app.service('webspaceIntegrationHelper', Services.WebspaceIntegrationHelperService);
app.service('webspaceModel', Services.WebspaceModelService);
app.service('webspaceOverviewModel', Services.WebspaceOverviewModel);
app.service('webspaceUserOverviewModel', Services.WebspaceUserOverviewModel);
app.service('webspaceWizardConfirmation', Atomic.WebspaceWizardConfirmation);
app.service('wizardConfirmationBase', Atomic.WizardConfirmationBase);
app.service('backupAdminService', Services.BackupAdminService);

// Please delete 'old' wizardHelper and rename 'new' wizard helper when change to new wizard is completed
app.service('wizardHelper', Atomic.WizardHelperService); // old
app.service('wizardNewHelper', Services.WizardNewHelperService); // new
app.service('wizardServiceHelperDomain', Atomic.WizardServiceHelperDomain);
app.service('wizardServiceHelperRedirection', Atomic.WizardServiceHelperRedirection);
app.service('wizardServiceHelperWebhosting', Atomic.WizardServiceHelperWebhosting);
app.service('zoneFileSerializer', Services.ZoneFileSerializerService);

//  █████╗ ██████╗ ██╗    ███████╗██████╗ ██████╗  ██████╗ ██████╗
// ██╔══██╗██╔══██╗██║    ██╔════╝██╔══██╗██╔══██╗██╔═══██╗██╔══██╗
// ███████║██████╔╝██║    █████╗  ██████╔╝██████╔╝██║   ██║██████╔╝
// ██╔══██║██╔═══╝ ██║    ██╔══╝  ██╔══██╗██╔══██╗██║   ██║██╔══██╗
// ██║  ██║██║     ██║    ███████╗██║  ██║██║  ██║╚██████╔╝██║  ██║
// ╚═╝  ╚═╝╚═╝     ╚═╝    ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝

app.service('apiErrorModel', Services.ApiErrorModel);

// ████████╗ ██████╗  ██████╗ ██╗     ██████╗  █████╗ ██████╗
// ╚══██╔══╝██╔═══██╗██╔═══██╗██║     ██╔══██╗██╔══██╗██╔══██╗
//    ██║   ██║   ██║██║   ██║██║     ██████╔╝███████║██████╔╝
//    ██║   ██║   ██║██║   ██║██║     ██╔══██╗██╔══██║██╔══██╗
//    ██║   ╚██████╔╝╚██████╔╝███████╗██████╔╝██║  ██║██║  ██║
//    ╚═╝    ╚═════╝  ╚═════╝ ╚══════╝╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝

app.directive('developmentToolbar', Development.DevelopmentToolbarDirective.Factory());

// ROUTES
require('./routes');

// DynamicFormElements
app.component('dynamicForm', new Atomic.DynamicFormComponent());
app.component('formElementDisplayLine', new Atomic.FormElementDisplayLineComponent());
app.component('formElementDisplayText', new Atomic.FormElementDisplayTextComponent());
app.component('formElementInputBool', new Atomic.FormElementInputBoolComponent());
app.component('formElementInputFile', new Atomic.FormElementInputFileComponent());
app.component('formElementInputColorRgb', new Atomic.FormElementInputColorRGBComponent());
app.component('formElementInputNumber', new Atomic.FormElementInputNumberComponent());
app.component('formElementInputPassword', new Atomic.FormElementInputPasswordComponent());
app.component('formElementInputMultiSelect', new Atomic.FormElementInputMultiSelectComponent());
app.component('formElementInputSelect', new Atomic.FormElementInputSelectComponent());
app.component('formElementInputString', new Atomic.FormElementInputStringComponent());
app.component('formPanelDynamic', new Atomic.FormPanelDynamicComponent());
app.directive('fileModel', Models.FileModelDirective.Factory());

app.directive('a', Permissions.LinkPermissionsDirective.Factory());
