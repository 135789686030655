import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels';
import { RestrictionHelperService } from '../../../services/helpers';

export class TemplateRestrictionsEditController {
    public static $inject: string[] = ['$state', '$translate'];

    public pageHeaderData: PanelHeaderData;
    public apiObject;
    public restrictionHelper: RestrictionHelperService;
    public showObjectId: boolean;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = () => {
        const backLinkBaseRoute = this.restrictionHelper.getBaseRouteAndParams();
        let panelTitle = this.apiObject.name;

        if (this.restrictionHelper.service === 'email') {
            panelTitle = this.apiObject.emailAddressUnicode;
        }

        if (this.restrictionHelper.service === 'dns') {
            panelTitle = this.apiObject.nameUnicode;
        }

        this.pageHeaderData = {
            backwardLink: backLinkBaseRoute.route + 'edit',
            backwardText: this.$translate.instant(this.restrictionHelper.getBackLinkText()),
            panelHeaderRoute: '',
            panelIcon: backLinkBaseRoute.icon,
            panelIconPath: backLinkBaseRoute.iconPath,
            panelTitle: panelTitle
        };
    };
}

export class TemplateRestrictionsEditComponent implements ng.IComponentOptions {
    public bindings = {
        apiObject: '<',
        restrictionHelper: '<',
        showObjectId: '<'
    };
    public template = require('./restrictions-edit-template.html');
    public controller = TemplateRestrictionsEditController;
    public controllerAs = '$TemplateRestrictionsEditCtrl';
}
