import * as ng from 'angular';

export class MoleculeHeadlineIconAndTextController implements ng.IController {
    public static $inject: string[] = [];

    public modifier: string;
    public iconName: string;
    public iconNamePath: boolean;
    public fixedSize = false;

    public $onInit() {
        this.iconNamePath = this.iconNamePath || false;
    }
}

export class MoleculeHeadlineIconAndTextComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        iconName: '@',
        iconNamePath: '<?',
        modifier: '@?',
        fixedSize: '<?fixed'
    };
    public controller =  MoleculeHeadlineIconAndTextController;
    public template = require('./icon-and-text.html');
}
