import * as ng from 'angular';
import { LinkListItem } from '../../../atomic-components/molecules/links';
import { PageHeaderData } from '../../../atomic-components/organisms';
import { DisableFilter } from '../../../atomic-components/organisms/overview';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';

export class TemplateStorageOverviewController {
    public static $inject: string[] = ['$state', '$translate'];

    public disableFilter: DisableFilter;
    public expertView: boolean;
    public faqArticleIdList: string[] = [];
    public linkList: LinkListItem[] = [];
    public overviewModel;
    public overviewServiceData;
    public pageHeaderData: PageHeaderData;

    private extendedFilters;
    private overviewUiSettings;
    private simpleFilter;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        if ([undefined, null].indexOf(this.simpleFilter) === -1) {
            this.expertView = this.overviewUiSettings.expertView || this.simpleFilter.show;
        } else {
            this.expertView = this.overviewUiSettings.expertView || false;
        }

        this.pageHeaderData = {
            backwardLink: '',
            backwardText: '',
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: '/assets/images/logos/nextcloud-bw.svg',
            panelIconPath: true,
            panelTitle: this.$translate.instant('TR_150519-73b99f_TR') // storage: TR_150519-96ae60_TR
        };

        switch (this.$state.current.name) {
            default:
            case 'nextcloud.overview':
                this.pageHeaderData.panelTitle = this.$translate.instant('TR_150519-73b99f_TR');
                break;
            case 'storage.storage-products.overview':
                // storage: TR_150519-cfa086_TR
                this.pageHeaderData.backwardText = this.$translate.instant('TR_150519-73b99f_TR');
                this.pageHeaderData.backwardLink = 'storage.dashboard';
                this.pageHeaderData.panelHeaderRouteParams = null;
                break;
        }

        // Surpress backlink to dashboard on expert view because it auto-redirects to overview.
        if (this.overviewUiSettings.expertView) {
            this.pageHeaderData.backwardText = '';
            this.pageHeaderData.backwardLink = '';
            this.pageHeaderData.panelHeaderRouteParams = null;
        }

        this.overviewServiceData = {
            additionData: {
                disableFilter: this.disableFilter
            },
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter
        };

        if (AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_JOBS_LIST)) {
            this.linkList.push(
                {
                    route: 'storage.storage-products.jobs',
                    routeParams: {},
                    text: this.$translate.instant('MENU.CONTEXT.JOBS')
                }
            );
        }
    }

    public expandWrapper = (toggle) => {
        this.expertView = toggle.expand;
    };
}

export class TemplateStorageOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public controller = TemplateStorageOverviewController;
    public controllerAs = 'TemplateStorageOverviewCtrl';
    public template = require('./storage-overview-template.html');
}
