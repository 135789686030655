import * as ng from 'angular';
import { DashboardOverviewDataObject } from '../../../organisms/overview/dashboard-overviews/dashboard-overviews';
import { OrganismOverviewController } from '../../../organisms/overview/overview';
import { DisableFilter } from '../../../organisms/overview/overview';
import { UtilityStatusObject } from '../../charts/utility-status/utility-status';
import { LinkListItem } from '../../links/link-list/link-list';

export class MoleculePanelDashboardOverviewHeaderController implements ng.IController {
    public overviewData: DashboardOverviewDataObject;

    public $onInit() {
        if (this.overviewData.hidePanelHeaderData === undefined) {
            this.overviewData.hidePanelHeaderData = false;
        }
    }
}

export class MoleculePanelDashboardOverviewHeaderComponent implements ng.IComponentOptions {
    public bindings = {
        overviewData: '<'
    };
    public controller =  MoleculePanelDashboardOverviewHeaderController;
    public controllerAs = 'PanelDashboardOverviewHeader';
    public template = require('./panel-dashboard-overviews-header.html');
}
