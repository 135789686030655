import ng from 'angular';

import { UIRegExp, UiRights } from '@/configuration';
import { AccountModelService, AuthContextService } from '@/services';
import { AccountApi } from '@/types';

export class MoleculeFormAccountSelectController implements ng.IController {
    public static $inject: string[] = ['$timeout', 'accountModel'];

    public model: string;
    public property: string;
    public placeholder: string;
    public overviewLoad: (selected: any) => any;
    public disabled = false;
    public inputId: string;
    public hideOwnAccount = false;
    public noRootAccounts = false;
    public clearable = false;
    public excludeIdsFromSearch: string[] = [];
    public allowUndefined = false;
    public onlySubaccountsFirstLevel = false;
    public showId = false;

    private _hasSubAccounts = false;
    private _lastSelectedId = '';

    constructor(
        private $timeout: ng.ITimeoutService,
        private accountModel: AccountModelService
    ) {}

    public findAccount = (id: string) => {
        if (!this.hideOwnAccount && AuthContextService.account.id === id) {
            return Promise.resolve(AuthContextService.account);
        } else {
            return this.accountModel.findOne(id);
        }
    };

    public additionalFields = (replyData: any) => {
        const fields: { [fieldName: string]: any } = { customerNumber: replyData.customerNumber };
        if (this.showId && replyData.id) {
            fields.id = replyData.id;
        }
        return fields;
    };

    public findAccounts = (limit?: number, page?: number, filter?, sort?) => {
        if (!AuthContextService.isGranted(UiRights.ACC_SUBACCOUNT_LIST)) {
            return Promise.resolve({ data: [] });
        }

        if (this.onlySubaccountsFirstLevel) {
            // accountId Filter maps agains refIdParentAccout
            const firstLevelFilterSingle = { field: 'accountId', value: AuthContextService.account.id };
            const firstLevelFilterCompose = {
                subFilter: [
                    filter,
                    firstLevelFilterSingle
                ],
                subFilterConnective: 'AND'
            };

            filter = filter === undefined
                ? firstLevelFilterSingle
                : firstLevelFilterCompose
            ;
        }

        return this.accountModel.listSubaccountsWithoutPagination(limit, page, filter, sort).then(
            (reply) => {
                if (!this.hideOwnAccount) {
                    reply.data = [AuthContextService.account].concat(reply.data);
                }

                if (this.noRootAccounts) {
                    reply.data = reply.data.filter(
                       (account: AccountApi.Account) => {
                           return !UIRegExp.IsNumeric.test(account.id) || parseInt(account.id, 10) > 1000;
                       }
                   );
                }

                this.hasSubAccounts = reply?.data.length > 0;
                return reply;
            }
        );
    };

    public onChangeId = (accountId: string): void => {
        if ([undefined, null, ''].indexOf(this._lastSelectedId) < 0) {
            this.excludeIdsFromSearch = this.excludeIdsFromSearch.filter((id) => id !== this._lastSelectedId);
        }

        this.excludeIdsFromSearch.push(accountId);

        if (this.overviewLoad) {
            void this.$timeout(() => this.overviewLoad(accountId));
        }

        this._lastSelectedId = accountId;
    };

    public set hasSubAccounts(value) {
        this._hasSubAccounts = value;
    }
    public get hasSubAccounts(): boolean {
        return this._hasSubAccounts;
    }
}

export class MoleculeFormAccountSelectComponent implements ng.IComponentOptions {
    public bindings = {
        allowUndefined: '<?',           // No default value for required dropdowns.
        clearable: '<',
        disabled: '<',
        excludeIdsFromSearch: '<?',
        hasSubAccounts: '=?',
        hideOwnAccount: '<?',
        inputId: '@?',
        model: '=',
        noRootAccounts: '<?',
        onlySubaccountsFirstLevel: '<',
        overviewLoad: '<',
        placeholder: '@',
        showId: '<?'
    };

    public controller =  MoleculeFormAccountSelectController;
    public template = require('./account-select.html');
}
