/* eslint-disable */

import ng from 'angular';
import { SslOrderModelService } from '@/services';
import { WizardCreateObject } from '@/atomic-components/organisms/wizards/general';
import { SslWizardConfirmation } from '@/atomic-components/organisms/wizards/ssl/confirm';
import * as Types from '@/types';

export type SslCreateOrderResponse = {
    errors: [];
    metadata: { clientTransactionId: string; serverTransactionId: string };
    response: { certificateId: string; commonName: string; jobId: string };
    status: string;
    warnings: [];
};

export class OrganismSslWizardConfirmViewController {

    public static $inject: string[] = [
        '$translate',
        'sslOrderModel',
        'sslWizardConfirmation'
    ];

    public wizardCreateObjectList: WizardCreateObject[] = [];
    private apiProductObject: Types.ViewTypes.WizardComposerObject; // Required information for the products that need to be ordered.
    private createOrderResponse: SslCreateOrderResponse;

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private sslOrderModel: SslOrderModelService,
        private sslWizardConfirmation: SslWizardConfirmation
    ) { }

    public $onInit(): void {
        if (this.apiProductObject.apiObject.ssl) {
            if (this.apiProductObject.productFamily === 'email-certificates') {
                this.wizardCreateObjectList.push({
                    callback: () => {
                        let orderObject = {
                            autoRenew: false,
                            productCode: this.apiProductObject.apiObject.ssl.order.productCode,
                            type: 'EmailCertificateOrder',
                            validationType: 'validateViaEmail',
                            emailAddresses: [ this.apiProductObject.apiObject.ssl.order.approverEmailAddress ],
                            csr: this.apiProductObject.apiObject.ssl.order.csr
                        };
                        return this.sslOrderModel
                            .create(
                                orderObject,
                                this.apiProductObject.apiObject.ssl.ownerAccountId
                            ).then((response: SslCreateOrderResponse) => this.createStatusUpdate(response));
                    },
                    labelText: '',
                    objectType: 'Ssl'
                });
            } else {
                this.wizardCreateObjectList.push({
                    callback: () => {
                        return this.sslOrderModel
                            .create(
                                this.apiProductObject.apiObject.ssl.order,
                                this.apiProductObject.apiObject.ssl.ownerAccountId
                            ).then((response: SslCreateOrderResponse) => this.createStatusUpdate(response));
                    },
                    labelText: '',
                    objectType: 'Ssl'
                });
            }
        }
    }

    public createStatusUpdate = (response: SslCreateOrderResponse): SslCreateOrderResponse => {
        this.createOrderResponse = response;
        return response;
    };
}

export class OrganismSslWizardConfirmViewComponent implements ng.IComponentOptions {
    public bindings = {
        apiProductObject: '<'
    };
    public template = require('./ssl-wizard-confirm-view.html');
    public controller = OrganismSslWizardConfirmViewController;
}
