import * as ng from 'angular';
import {
    OrganismEditFormNextcloudController
} from '@/atomic-components/organisms/forms/edit-forms/nextcloud-edit/nextcloud-edit';
import { SHORT_TIMEOUT_REFRESH_INTERVAL } from '@/configuration/system';
import { StorageModelService } from '@/services';
import { ManagedApplicationApi } from '@/types';

export class OrganViewPanelNextcloudUrlController {
    public static $inject: string[] = ['$state', '$timeout', 'localInterval', 'storageModel'];

    public $editFormOrganism: OrganismEditFormNextcloudController;
    public url: string;

    private _isCreatingStatus = false;
    private _checkCreatingStatus: ng.IPromise<ng.IIntervalService>;
    private _maxNumberOfChecks = 5;
    private _checksPerformed = 0;
    private _interval: number = SHORT_TIMEOUT_REFRESH_INTERVAL;
    private _intervalMultiplier = 4;

    constructor(
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private localInterval: ng.IIntervalService,
        private storageModel: StorageModelService
    ) {}

    public $onInit(): void {
        this.url = this.$state.current.name.split('.').slice(0, -1).concat('use-own-domain').join('.');
        if (this.$editFormOrganism.nextcloud.status === 'creating') {
            this.isCreatingStatus = true;
            this._startCheckCreatingStatus();
        }
    }

    public get showCustomUrl(): boolean {
        return this.$editFormOrganism.nextcloud.individualDomainNameStatus === 'active';
    }

    public get showCurrentlyBeingSetupNotice(): boolean {
        return this.$editFormOrganism.nextcloud.individualDomainNameStatus === 'creating';
    }

    public get showSetupCustomDomainName(): boolean {
        return this.$editFormOrganism.nextcloud.status === 'active'
            && ['notConfigured', 'active'].includes(this.$editFormOrganism.nextcloud.individualDomainNameStatus);
    }

    public get isCreatingStatus(): boolean {
        return this._isCreatingStatus;
    }

    public set isCreatingStatus(value) {
        this._isCreatingStatus = value;
    }

    private _checkStatus = (): PromiseLike<ManagedApplicationApi.Nextcloud> => {
        if (this._checksPerformed >= this._maxNumberOfChecks) {
            this._interval = this._interval * this._intervalMultiplier;
            this._checksPerformed = 0;
            this._stopCheckCreatingStatus();
            this._startCheckCreatingStatus();
            return;
        }
        this._checksPerformed++;

        return this.storageModel.findOne(this.$editFormOrganism.nextcloud.id, (this._interval - 100)).then(
            (res: ManagedApplicationApi.Nextcloud) => {
                this.$editFormOrganism.nextcloud = res;
                if (res.status !== 'creating') {
                    this._stopCheckCreatingStatus();
                    void this.$timeout(() => {
                        this.$editFormOrganism.nextcloudHasUnfinishedJob = false;
                        this.isCreatingStatus = false;
                    });
                }
                return res;
            }
        );
    };

    private _startCheckCreatingStatus = (): void => {
        if (this._checkCreatingStatus !== undefined) {
            return;
        }

        this._checkCreatingStatus = this.localInterval(this._checkStatus, this._interval);
    };

    private _stopCheckCreatingStatus = (): void => {
        if (this._checkCreatingStatus !== undefined) {
            this.localInterval.cancel(this._checkCreatingStatus);
            this._checkCreatingStatus = undefined;
        }
    };
}

export class OrganViewPanelNextcloudUrlComponent implements ng.IComponentOptions {
    public require = {
        $editFormOrganism: '^organismEditFormNextcloud'
    };
    public controller = OrganViewPanelNextcloudUrlController;
    public controllerAs = '$viewPanelOrgan';
    public template = require('./nextcloud-url-view.html');
}
