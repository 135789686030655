import * as ng from 'angular';
import './panel-edit-row-street-zip.scss';

export class MoleculePanelEditRowStreetZipController implements ng.IController {
    public static $inject: string[] = [];
    public street: string;
    public zip: string;
    public placeholderStreet: string;
    public placeholderZip: string;
    public readonly: boolean;
    public disabled: boolean;
    public callbackOnChange: (arg0: any) => any;
    public customCallbackOnEnter: () => {};
    public doNotSaveOnFormEnter: boolean;
}

export class MoleculePanelEditRowStreetZipComponent implements ng.IComponentOptions {
    public bindings = {
        street: '=',
        zip: '=',
        placeholderStreet: '<',
        placeholderZip: '<',
        readonly: '<',
        disabled: '<',
        callbackOnChange: '<',
        customCallbackOnEnter: '<',
        doNotSaveOnFormEnter: '<'
    };
    public transclude = true;
    public controller = MoleculePanelEditRowStreetZipController;
    public template = require('./panel-edit-row-street-zip.html');
}
