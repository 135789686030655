import ng from 'angular';
import { BundleApi, WebhostingApi, ProductApi } from '@/types';
import { ProductsModelService, PriceHelperService } from '@/services';

export class MoleculePanelWebspaceLegacyPhpWarningController implements ng.IController {
    public static $inject: string[] = ['$state', 'dateFilter', 'priceHelper', 'productsModel'];

    public showLegacyPHPWarning = false;
    public legacyPHPVersions = '';
    public legacyPhpMigrationPeriod = '';
    public legacyPhpProductName = '';
    public legacyPhpProductBillingPeriode = '';
    public route: string;
    public routeParams: any;
    private bundle: BundleApi.Bundle;
    private webspace: WebhostingApi.Webspace;

    constructor (
        private $state: ng.ui.IStateService,
        private dateFilter: unknown,
        private priceHelper: PriceHelperService,
        private productsModel: ProductsModelService
    ) {}

    public $onInit(): void {
        this.bundle = this.$state.$current.locals.globals.bundle;
        if (this.webspace.legacyPhpAddonProductCode && this.webspace.legacyPhpMigrationPeriod) {
            this.route = 'webhosting.webspaces.id.vhosts.legacyphp';
            this.routeParams = { 'webspaceId': this.webspace.id };
            if (this.bundle) {
                this.route = 'bundle.id.domains.legacyphp';
                this.routeParams = { 'bundleId': this.bundle.id };
            }
            void this.productsModel.findOne(this.webspace.legacyPhpAddonProductCode, undefined, true)
                .then((reply) => reply.response)
                .then(
                    (legacyProduct: ProductApi.AbstractProduct) => {
                        this.legacyPhpMigrationPeriod = this.dateFilter(this.webspace.legacyPhpMigrationPeriod, 'dd.MM.yyyy');

                        const phpItems: ProductApi.SpecificationItem[] = legacyProduct.specificationItems.filter(
                            (specificationItem) => specificationItem.name === 'phpVersions'
                        );
                        if (phpItems.length >= 1) {
                            this.legacyPHPVersions = phpItems[0].value?.join(', ');
                        }
                        this.legacyPhpProductName = legacyProduct.name;
                        this.legacyPhpProductBillingPeriode = this.priceHelper.getBillingPeriod(
                            String(legacyProduct.billingCycle)
                        );

                        this.showLegacyPHPWarning = true;
                    }
                );
        }
    }
}

export class MoleculePanelWebspaceLegacyPhpWarningComponent implements ng.IComponentOptions {
    public bindings = {
        webspace: '<',
    };
    public controller =  MoleculePanelWebspaceLegacyPhpWarningController;
    public template = require('./panel-webspace-legacy-php-warning.html');
}
