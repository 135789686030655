import { AlphaNumericCaseInsensitive } from '@/configuration';
import * as ng from 'angular';
import {
    UserSettingsManagerService, ValidateAllowedCharacters,
    ValidateMaxLength,
    ValidateMinLength,
    ValidateNotEmpty
} from '../../../../../services';

export class OrganEditPanelMachineOsController {
    public static $inject: string[] = ['$translate', '$timeout', 'userSettingsManager'];

    public machine: any;
    public operatingSystems: any;
    public selectedApp: any;
    public language: string;
    public password: string;
    public sshKey: string;
    public extraUserName: string;
    public extraUserPass: string;
    public extraUserKeys: string;
    public dataLossConfirmed = false;
    public extraUserRequiredForInstallation = false;
    public userNameValidationInstructions: {}[];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private $timeout: ng.ITimeoutService,
        private userSettingsManager: UserSettingsManagerService
    ) {}

    public $onInit() {
        this.userSettingsManager.getUiSettings().then(
            (reply) => {
                this.language = reply;
            }
        );
        this.userNameValidationInstructions = [
            {
                settings: null,
                validator: new ValidateMinLength(this.$translate, 4)
            },
            {
                settings: null,
                validator: new ValidateMaxLength(this.$translate, 12)
            },
            {
                settings: null,
                validator: new ValidateNotEmpty(this.$translate)
            },
            {
                settings: null,
                validator: new ValidateAllowedCharacters(AlphaNumericCaseInsensitive, this.$translate)
            }
        ];
    }

    public checkExtraUserRequired = () => {
        this.$timeout(() => {
            if (this.selectedApp?.general !== undefined) {
                const match = /ubuntu/.exec(this.selectedApp.general.pkgName);
                this.extraUserRequiredForInstallation = match !== null;
            }
        });
    };

    public get showSavePanel() {
        return this.selectedApp !== undefined && this.password !== undefined && this.dataLossConfirmed;
    }
}

export class OrganEditPanelMachineOsComponent implements ng.IComponentOptions {
    public bindings = {
        operatingSystems: '<',
        selectedApp: '=',
        password: '=',
        sshKey: '=',
        extraUserName: '=',
        extraUserPass: '=',
        extraUserKeys: '='
    };
    public template = require('./machine-os.html');
    public controller = OrganEditPanelMachineOsController;
}
