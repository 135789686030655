export class BundleNameSync {
    public static $inject: string[] = ['$translate'];

    public static Factory(
        $translate: ng.translate.ITranslateService
    ): ng.IFilterFunction {

        const bundleNameFilter = (bundleName: string): string => {
            if ([undefined, null, ''].indexOf(bundleName) >= 0) {
                return $translate.instant('TR_230119-0ec6d6_TR');
            }
            return bundleName;
        };

        bundleNameFilter.$stateful = false;
        return bundleNameFilter;
    }
}
