import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateNameserverSetNewController {
    public static $inject: string[] = ['$translate'];

    public nameserverSet: any;
    public pageHeaderData: PanelHeaderData = {
        panelTitle: this.$translate.instant('TR_100119-ba1a94_TR'),
        panelIcon: 'location-arrow',
        panelHeaderRoute: '',
        backwardText: this.$translate.instant('TR_100119-47c6f2_TR'),
        backwardLink: 'dns.nameserversets.overview',
        panelHeaderRouteParams: {}
    };

    constructor(private $translate: ng.translate.ITranslateService) {}
}

export class TemplateNameserverSetNewComponent implements ng.IComponentOptions {
    public controller = TemplateNameserverSetNewController;
    public template = require('./nameserver-set-new-template.html');
}
