import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels';
import { UserSettingsManagerService } from '../../../services';

export class TemplateDatabaseOverviewController {
    public static $inject: string[] = ['$state', '$translate', 'userSettingsManager'];

    public modifier = '';
    public pageHeaderData: PanelHeaderData;
    public bundle: any;
    public overviewServiceData: any;
    public expertView: boolean;
    public overviewModel: any;
    public faqArticleIdList: string[] = [
        'database-access',
        'database-phpmyadmin',
        'database-extern-access'
    ];

    private simpleFilter: any;
    private extendedFilters: any;
    private overviewUiSettings: any;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private userSettingsManager: UserSettingsManagerService
    ) {}

    public $onInit() {
        const stateInBundle = this.$state.current.name.split('.')[0] === 'bundle';
        const stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';
        const stateInUser = this.$state.current.name.indexOf('users.id') >= 0;

        this.expertView = this.overviewUiSettings.expertView
            || (
                [undefined, null].indexOf(this.simpleFilter) === -1
                    ? this.simpleFilter.show
                    : false
            );
        let panelHeaderBackwardLink: string;
        let panelHeaderBackwardText: string;
        let panelHeaderRouteParams: any;

        switch (true) {
            case stateInBundle:
                panelHeaderBackwardLink = 'bundle.id.dashboard';
                panelHeaderBackwardText = this.$translate.instant('TR_090119-a32485_TR');
                panelHeaderRouteParams = { bundleId: this.bundle.id };
                break;
            case stateInMachine:
                panelHeaderBackwardLink = 'managed-servers.id.dashboard';
                panelHeaderBackwardText = this.$translate.instant('TR_220319-1ac2e3_TR');
                panelHeaderRouteParams = { machineId: this.$state.$current.locals.globals.machine.id };
                break;
            case stateInUser:
                panelHeaderBackwardLink = 'database.users.id.edit';
                panelHeaderBackwardText = this.$translate.instant('TR_270420-ba744d_TR');
                panelHeaderRouteParams = null;
                break;
            default:
                panelHeaderBackwardLink = 'database.dashboard';
                panelHeaderBackwardText = this.$translate.instant('TR_100119-ab6cc1_TR');
                panelHeaderRouteParams = null;
        }

        this.pageHeaderData = {
            backwardLink: panelHeaderBackwardLink,
            backwardText: panelHeaderBackwardText,
            panelHeaderRoute: '',
            panelHeaderRouteParams: panelHeaderRouteParams,
            panelIcon: 'database',
            panelTitle: this.$translate.instant('TR_100119-8e343a_TR')
        };

        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter
        };

        // Surpress backlink to dashboard on expert view because it auto-redirects to overview.
        this.userSettingsManager.getUiSettings().then(
            (result) => {
                if (result?.overviewSettings?.DatabaseOverviewCtrl?.expertView) {
                    this.pageHeaderData.backwardText = '';
                    this.pageHeaderData.backwardLink = '';
                    this.pageHeaderData.panelHeaderRouteParams = null;
                }
            }
        );
    }

    public expandWrapper = (toggle) => {
        this.expertView = toggle.expand;
    };
}

export class TemplateDatabaseOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<',
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public controller = TemplateDatabaseOverviewController;
    public controllerAs = 'TemplateDatabaseOverviewCtrl';
    public template = require('./database-overview-template.html');
}
