import * as ng from 'angular';

export class OrganEditPanelDomainEditBulkNameserverController {
    public static $inject: string[] = [];

    public selectedNameservers: any[];
    public ownNameServers: any[];
    public nameservers: any[];
    public defaultNameservers: any;
    public domainNames: string[];
    public enforceOwnNameservers = false;

    public reset = () => {
        this.selectedNameservers = this.enforceOwnNameservers ? null : undefined;
        this.ownNameServers = [];
    };
}

export class OrganEditPanelDomainEditBulkNameserverComponent implements ng.IComponentOptions {
    public bindings = {
        defaultNameservers: '<',
        domainNames: '<',
        enforceOwnNameservers: '<?',
        nameservers: '<',
        ownNameServers: '=',
        selectedNameservers: '='
    };
    public controller = OrganEditPanelDomainEditBulkNameserverController;
    public template = require('./nameserver.html');
}
