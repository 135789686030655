import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import {
    AlertManagerService, ApiErrorModel, ApiKeyOverviewModelService, AuthContextService,
    NavigationService, TwoFaHelperService, UserModelService
} from '../../../../../services';
import * as Types from '../../../../../types';
import { twoFAFormStatus } from '../../../../organs';

export class OrganismEditFormUserController implements ng.IController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'apiErrorModel',
        'apiKeyOverviewModel',
        'navigation',
        'twoFaHelper',
        'userModel'
    ];

    public userHasRightAccountApiKeysList = AuthContextService.isGranted(UiRights.ACC_ACCOUNT_APIKEYS_LIST);
    public panelRightSettings: any;
    public twoFAData: any = {
        canEdit: false,
        confirmationCode: '',
        formStatus: twoFAFormStatus.BASE,
        passcodes: [],
        password: '',
        qrUrlString: '',
        status: '',
        token: '',
        userId: ''
    };
    public userData: Types.AccountApi.User;
    public passwordOwn: string;
    public passwordNew: string;
    public passwordRepeat: string;
    public apiKeyOverviewServiceData: any;
    public overviewUiSettings: any;
    public apiKeyInternalPaginationLimit: number;
    public apiKeys: any;
    public deleteCheckboxStatus: boolean;
    public rightsTemplates: any[];
    public allRights: any[];
    public rightsInTemplate: string[];
    private originalUserData: Types.AccountApi.User;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        public apiKeyOverviewModel: ApiKeyOverviewModelService,
        private navigation: NavigationService,
        private twoFaHelper: TwoFaHelperService,
        private userModel: UserModelService
    ) {}

    public $onInit() {
        this.originalUserData = ng.copy(this.userData);
        this.setPanelRightSettings();
        this.resetUserData();
        this.resetUserLogin();
        this.resetUserPassword();

        this.apiKeyOverviewServiceData = {
            extendedFilters: null,
            objectId: this.userData.id,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: { value: '' }
        };

        this.twoFAData = {
            confirmationCode: '',
            formStatus: twoFAFormStatus.BASE,
            passcodes: [],
            password: '',
            qrUrlString: '',
            status: this.userData.twoFactorAuthStatus,
            token: '',
            userId: this.userData.id
        };

        if (AuthContextService.isGranted(UiRights.ACC_ACCOUNT_USER_EDIT)) {
            this.twoFAData.canEdit = true;
        }
    }

    public get isOwnUser() {
        return AuthContextService.isOwnUser(this.userData);
    }

    public get isOwnAccount() {
        return this.userData.accountId === AuthContextService.account.id;
    }

    public resetUserData = () => {
        this.userData.name = this.originalUserData.name;
        this.userData.language = this.originalUserData.language;
    };

    public resetUserLogin = () => {
        this.userData.emailAddress = this.originalUserData.emailAddress;
    };

    public resetUserPassword = () => {
        this.passwordNew = '';
        this.passwordOwn = '';
    };

    public deleteCallbackFunction = () => {
        this.apiErrorModel.destroyErrorList();
        this.deleteCheckboxStatus = false;

        return this.userModel.delete([this.userData]).then(
            (res) => {
                if (!this.apiErrorModel.apiResponseHasError(res)) {
                    return this.navigation.go('account.users.overview').then(
                        () => Promise.reject(false)
                    );
                }

                return Promise.reject(false);
            }
        );
    };

    public resetDeleteToggle = () => {
        this.deleteCheckboxStatus = false;
    };

    public setPanelRightSettings = () => {
        const rightsGranted = AuthContextService.isGranted(UiRights.ACC_ACCOUNT_USER_EDIT);

        if (rightsGranted) {
            this.panelRightSettings = {
                editPanelButton: true
            };
        } else {
            this.panelRightSettings = {
                editPanelButton: false
            };
        }
    };

    public save = () => {
        this.apiErrorModel.destroyErrorList();

        return this.userModel.update(this.userData, this.passwordOwn, this.passwordNew).then(
            (res) => {
                if (!this.apiErrorModel.apiResponseHasError(res)) {
                    this.passwordOwn = '';
                    this.passwordNew = '';

                    this.alertManager.success(this.$translate.instant('TR_100119-54df5d_TR'));
                    return res;
                }

                return Promise.reject(false);
            },
            (err) => {
                this.passwordOwn = '';
                this.passwordNew = '';

                return Promise.reject(err);
            }
        );
    };

    public get errorList() {
        return this.apiErrorModel.errorObjectList;
    }
}

export class OrganismEditFormUserComponent implements ng.IComponentOptions {
    public bindings = {
        allRights: '<',
        apiKeys: '<',
        overviewUiSettings: '<',
        rightsTemplates: '<',
        userData: '='
    };

    public controller = OrganismEditFormUserController;
    public template = require('./user-edit.html');
}
