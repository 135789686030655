import ng from 'angular';
import { AuthContextService } from '@/services';
import { NextcloudFreeProductCodeConst, UiRights } from '@/configuration';
import { Breadcrumb, BreadcrumbItem } from '../breadcrumb';
import { BreadcrumbTopicModel, TopicCases } from './topic-model';

export class BreadcrumbNextcloudTopicModel extends BreadcrumbTopicModel {
    public static $inject: string[] = BreadcrumbTopicModel.$inject.concat([]);

    public readonly topicName: string = 'nextcloud';
    public readonly specialTopicCases: TopicCases;

    /**
     * List of route parts, which are excluded from main class generalRoutes method
     */
    public readonly excludeGeneralRouteParts = ['edit', 'new', 'overview'];

    private storageActionDropdownItems: BreadcrumbItem[] = [];

    private nextcloudActionDropdownItems: BreadcrumbItem[] = [];
    private nextcloudOverviewDropdownItems: BreadcrumbItem[] = [];
    private hasOnlyOneInstance = false;

    constructor(protected $state: ng.ui.IStateService, protected $translate: ng.translate.ITranslateService) {
        super($state, $translate);

        this.nextcloudActionDropdownItems = [
            {
                name: this.$translate.instant('TR_100119-4669d2_TR'),
                part: 'edit',
                route: 'nextcloud.id.edit'
            },
            {
                name: this.$translate.instant('TR_230621-29daf9_TR'),
                part: 'config',
                route: 'nextcloud.id.config'
            },
            {
                name: this.$translate.instant('TR_030919-a153f2_TR'),
                part: 'upgrade',
                route: 'nextcloud.id.upgrade'
            },
            {
                name: this.$translate.instant('TR_180221-2542cc_TR'),
                part: 'addons',
                route: 'nextcloud.id.addons'
            },
            {
                name: this.$translate.instant('TR_121020-0e5df1_TR'),
                part: 'app-center',
                route: 'nextcloud.id.app-center'
            },
            // {
            //     name: this.$translate.instant('TR_120819-f55f41_TR'),
            //     part: 'use-own-domain',
            //     route: 'nextcloud.id.use-own-domain'
            // },
            // {
            //     name: this.$translate.instant('TR_100119-4669d2_TR'),
            //     part: 'rename',
            //     route: 'nextcloud.id.rename'
            // },
            // {
            //     name: this.$translate.instant('TR_020819-188cc3_TR'),
            //     part: 'cancel',
            //     route: 'nextcloud.id.cancel'
            // },
            {
                name: this.$translate.instant('TR_250519-7f279b_TR'),
                part: 'restrictions',
                route: 'nextcloud.id.restrictions'
            }
        ];

        if (
            AuthContextService.isGrantedAny([
                UiRights.ADMIN_SYSTEM_SUPER_USER_EDIT,
                UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN
            ])
        ) {
            this.nextcloudActionDropdownItems.push({
                title: this.$translate.instant('TR_070720-f5a487_TR'),
                part: 'admin-access',
                route: 'nextcloud.id.admin-access'
            });
        }

        this.specialTopicCases = {
            'app-center': this.getNextcloudAppCenterRoute,
            'addons': this.getNextcloudEditRoute,
            'cancel': this.getCancelRoute,
            'config': this.getNextcloudEditRoute,
            'edit': this.getNextcloudEditRoute,
            'id': this.getNextcloudName,
            'jobs': () => this.getProductActionDropdown(this.storageActionDropdownItems),
            'new': () => this.getProductActionDropdown(this.storageActionDropdownItems),
            'nextcloud': () => this.getProductActionDropdown(this.nextcloudActionDropdownItems),
            'overview': () => this.getProductActionDropdown(this.storageActionDropdownItems),
            'rename': this.getNextcloudEditRoute,
            'restrictions': this.getNextcloudEditRoute,
            'upgrade': this.getNextcloudUpgradeRoute,
            'use-own-domain': this.getNextcloudUseOwnDomainRoute
        };
    }

    public getFirstLevelData = (): Breadcrumb[] => {
        this.breadcrumbs.push({
            active: this.index === this.steps.length - 2,
            route: this.hasOnlyOneInstance ? 'nextcloud.id.edit' : 'nextcloud.overview',
            title: this.$translate.instant('TR_080119-5c3499_TR'),
            type: 'text',
            value: this.$translate.instant('TR_140519-4f0af6_TR')
        });

        return this.breadcrumbs;
    };

    public getNextcloudEditRoute = (): Breadcrumb[] => {
        this._buildData();

        return this.getProductActionDropdown(this.nextcloudActionDropdownItems, {}, this.step);
    };

    public getNextcloudUseOwnDomainRoute = (): Breadcrumb[] => {
        let linkText = this.$translate.instant('TR_120321-a7df90_TR');
        if (this.$state.$current.locals.globals.storageProduct !== 'active') {
            linkText = this.$translate.instant('TR_220519-9a4f92_TR');
        }
        return [
            {
                active: this.steps.length === 3 && this.steps[this.steps.length - 1] === 'use-own-domain',
                route: 'nextcloud.id.use-own-domain',
                routeParams: { storageProductId: this.$state.params.storageProductId },
                type: 'text',
                value: linkText
            }
        ];
    };

    public getNextcloudUpgradeRoute = (): Breadcrumb[] => [
        {
            active: this.steps.length === 3 && this.steps[this.steps.length - 1] === 'upgrade',
            route: 'nextcloud.id.upgrade',
            routeParams: { storageProductId: this.$state.params.storageProductId },
            type: 'text',
            value: this.$translate.instant('TR_240420-8179bc_TR')
        }
    ];

    public getNextcloudAppCenterRoute = (): Breadcrumb[] => [
        {
            active: this.steps.length === 3 && this.steps[this.steps.length - 1] === 'app-center',
            route: 'nextcloud.id.app-center',
            routeParams: { storageProductId: this.$state.params.storageProductId },
            type: 'text',
            value: this.$translate.instant('TR_121020-0e5df1_TR')
        }
    ];

    public getCancelRoute = (): Breadcrumb[] => {
        if (
            this.$state.$current.locals.globals.storageProduct !== null
            && this.$state.$current.locals.globals.storageProduct.productCode !== NextcloudFreeProductCodeConst
        ) {
            return this.getTerminateRoute();
        }

        return this.getDeleteRoute();
    };

    public getNextcloudName = (): Breadcrumb[] => [
        {
            active: this.steps.length === 3 && this.steps[this.steps.length - 1] === 'edit',
            route: 'nextcloud.id.edit',
            routeParams: { storageProductId: this.$state.params.storageProductId },
            type: 'text',
            value: this._getNextcludNameFromState()
        }
    ];

    private _getNextcludNameFromState = (): string => {
        if (this.globals === undefined) {
            return '';
        }

        switch (true) {
            case this.globals.storageProduct !== undefined:
                return this.globals.storageProduct.name;
            case this.globals.job !== undefined:
                return this.globals.job.displayName;
            default:
                return '';
        }
    };

    private _buildData = (): void => {
        if (
            [undefined, null].indexOf(this.$state.$current.locals) < 0
            && [undefined, null].indexOf(this.$state.$current.locals.globals) < 0
            && [undefined, null].indexOf(this.$state.$current.locals.globals.hasOnlyOneInstance) < 0
        ) {
            this.hasOnlyOneInstance = this.$state.$current.locals.globals.hasOnlyOneInstance;
        }

        this.nextcloudOverviewDropdownItems = [
            {
                part: 'jobs',
                // @todo: This is so, so wrong...
                route: 'nextcloud.jobs'
            }
        ];

        if (!this.hasOnlyOneInstance) {
            this.nextcloudOverviewDropdownItems.unshift({
                part: 'overview',
                route: 'nextcloud.overview'
            });
        }

        this.storageActionDropdownItems = [
            {
                isGranted: UiRights.MANAGED_APPLICATION_JOBS_LIST,
                part: 'jobs',
                route: 'nextcloud.jobs'
            },
            {
                isGranted: UiRights.MANAGED_APPLICATION_NEXTCLOUD_CREATE,
                part: 'new',
                route: 'nextcloud.new'
            }
        ];
        if (!this.hasOnlyOneInstance) {
            this.storageActionDropdownItems.unshift({
                isGranted: UiRights.MANAGED_APPLICATION_NEXTCLOUD_LIST,
                part: 'overview',
                route: 'nextcloud.overview'
            });
        }
    };
}
