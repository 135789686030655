import * as ng from 'angular';
import { WizardCreateObject, ManagedServerProjectWizardConfirmation } from '@/atomic-components';
import * as Configurations from '@/configuration';
import {
    AccountModelService,
    ApiErrorObject,
    AuthContextService,
    DatabaseModelService,
    DatabaseUserModelService,
    ManagedMachineProjectSubaccountTypes,
    VhostModelService,
    WebhostingHelperService,
    WebhostingUserModelService,
    WebspaceModelService
} from '@/services';
import * as Types from '@/types';

export class OrganismManagedProjectWizardConfirmViewController {
    public static $inject: string[] = [
        '$state',
        '$translate',
        'accountModel',
        'databaseUserModel',
        'databaseModel',
        'localInterval',
        'managedServerProjectWizardConfirmation',
        'vhostModel',
        'webhostingHelper',
        'webhostingUserModel',
        'webspaceModel'
    ];

    public apiErrorList: ApiErrorObject[] = [];
    public wizardCreateObjectList: WizardCreateObject[] = [];  // List of create request

    private _accountEmailInUse: boolean;                        // Do not delete. This is a template binding parameter
    private _apiProductObject;                                  // Required information for ordering.
    private _overrideViewtype: string;                          // Do not delete. This is a template binding parameter
    private newAccountId: string = null;

    public constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService,
        private databaseUserModel: DatabaseUserModelService,
        private databaseModel: DatabaseModelService,
        private localInterval: ng.IIntervalService,
        private managedServerProjectWizardConfirmation: ManagedServerProjectWizardConfirmation,
        private vhostModel: VhostModelService,
        private webhostingHelper: WebhostingHelperService,
        private webhostingUserModel: WebhostingUserModelService,
        private webspaceModel: WebspaceModelService
    ) {}

    public $onDestroy(): void {
        this.localInterval.cancel(this.managedServerProjectWizardConfirmation.checkJobStatusIntervalHandler);
    }

    public $onInit(): void {
        // Build the promise-chain. For nextcloud its just the create-call.
        const tmpJobs: WizardCreateObject[] = [];
        this.managedServerProjectWizardConfirmation.useOrchestrationService = false;
        this._accountEmailInUse = false;

        // Webspace
        if (this._apiProductObject.webspace !== null) {
            tmpJobs.push({
                callback: () => this._createWebspaceUser(),
                children: [
                    {
                        callback: (user: Types.WebhostingApi.User) => {
                            return this.webspaceModel.create(
                                {
                                    accountId: this._getAccountId(),
                                    name: this._apiProductObject.webspace.webspaceName,
                                    productCode: Configurations.ManagedMachineConst.contingentProductCodes.webspace
                                },
                                [{ ftpAccess: true, userId: user.id }],
                                AuthContextService.account.id, // this is the owner accountId
                                null,
                                this._apiProductObject.webspace.webserverId
                            );
                        },
                        children: [{
                            callback: (webspace: Types.WebhostingApi.Webspace) => {
                                return this._createVhost(webspace);
                            },
                            children: [],
                            labelText: this.$translate.instant('TR_240120-ea842b_TR'),
                            objectType: 'Vhost'
                        }],
                        labelText: this.$translate.instant('TR_210319-eeec82_TR'),
                        objectType: 'Webspace'
                    }
                ],
                labelText: this.$translate.instant('TR_221119-a44994_TR'),
                objectType: 'WebspaceUser'
            });
        }

        // Database
        if (this._apiProductObject.database !== null) {
            const databaseCreateObjects = [];
            for (let i = 1; i <= +this._apiProductObject.database.databaseCapacity; i++) {
                const databaseName = ng.copy(this._apiProductObject.projectName)
                    + (
                        +this._apiProductObject.database.databaseCapacity < 2
                            ? ''
                            : ' (' + i + ')'
                    );
                databaseCreateObjects.push({
                    callback: (databaseUser: Types.DatabaseApi.User) => {
                        if (
                            this._apiProductObject.database.databaseObject.accesses?.length > 0
                            && this._apiProductObject.database.databaseObject.accesses[0].userId === null
                        ) {
                            this._apiProductObject.database.databaseObject.accesses[0].userId = databaseUser.id;
                        }
                        return this._createDatabases(databaseName, i);
                    },
                    children: [],
                    labelText: this.$translate.instant('DATABASE.GENERAL.DATABASE') + ' ' + databaseName,
                    objectType: 'Database'
                });
            }

            tmpJobs.push({
                callback: () => this._createDatabaseUser(),
                children: databaseCreateObjects,
                labelText: this.$translate.instant('TR_221119-cc3297_TR'),
                objectType: 'DatabaseUser'
            });
        }

        if (this._apiProductObject.accountType === ManagedMachineProjectSubaccountTypes.NEW) {
            this.wizardCreateObjectList = [{
                callback: this._createSubaccount,
                children: tmpJobs,
                labelText: this.$translate.instant('TR_140119-5789c6_TR'),
                objectType: 'Subaccount'
            }];
        } else {
            this.wizardCreateObjectList = tmpJobs;
        }
    }

    private _createSubaccount = () => {
        return this.accountModel.createSubaccount(
            this._apiProductObject.newAccount,
            this._apiProductObject.adminUser,
            this._apiProductObject.newAccount.password
        )
            .then(
                (newSubaccount) => {
                    this.newAccountId = newSubaccount.response.id;
                    return newSubaccount.response;
                }
            );
    };

    private _getAccountId = () => {
        return this.newAccountId !== null
            ? this.newAccountId
            : this._apiProductObject.accountId;
    };

    private _createWebspaceUser = () => {
        this._apiProductObject.webspaceUser.id = this._getAccountId();

        return this.webhostingUserModel.createUser(
            {
                name: this._apiProductObject.webspaceUser.name,
                accountId: this._apiProductObject.accountId
            },
            this._apiProductObject.webspaceUser.password
        ).then(
            (webspaceUser) => webspaceUser,
            (err) => err
        );
    };

    private _createVhost = async (webspace: any) => {
        this._apiProductObject.vhost.vhost.webspaceId = webspace.id;
        this._apiProductObject.vhost.vhost.id = this._getAccountId();

        const phpVersion = await this.vhostModel.defaultPhpVersion();

        return this.webhostingHelper.getDefaultPhpIniSet(
            webspace.id,
            phpVersion,
            false
        )
            .then(
                (phpIniDefaults) => this.vhostModel.create(
                    this._apiProductObject.vhost.vhost,
                    phpIniDefaults,
                    '',
                    [],
                    AuthContextService.account.id // this is owner account id
                )
            );
    };

    private _createDatabaseUser = () => {
        this._apiProductObject.databaseUser.id = this._getAccountId();

        return this.databaseUserModel.createUser(
            this._apiProductObject.databaseUser.user,
            this._apiProductObject.databaseUser.password,
            this._apiProductObject.accountId
        );
    };

    private _createDatabases = (databaseName, i) => {
        return this.databaseModel.create(
            {
                accesses: this._apiProductObject.database.databaseObject.accesses,
                accountId: this._getAccountId(),
                name: databaseName,
                productCode: Configurations.ManagedMachineConst.contingentProductCodes.database,
                storageQuota: 0 // set default storage of product (productCode)
            },
            null,
            this._apiProductObject.database.poolId,
            this._apiProductObject.database.databaseServerId
        );
    };
}

export class OrganismManagedProjectWizardConfirmViewComponent implements ng.IComponentOptions {
    public bindings = {
        _apiProductObject: '<apiProductObject',
        _accountEmailInUse: '=accountEmailInUse',
        _overrideViewtype: '=overrideViewtype'
    };
    public template = require('./managed-project-wizard-confirm-view.html');
    public controller = OrganismManagedProjectWizardConfirmViewController;
}
