import ng from 'angular';

import { DatabaseAccessLevelsAllRightsConst, DatabasePasswordValidationOptions } from '@/configuration';
import { AuthContextService, DatabaseUserModelService } from '@/services';
import * as Types from '@/types';

export interface DatabaseUserObject {
    accountId: string;
    addDate?: string;
    comments: string;
    dbUserName?: string;
    id?: string;
    lastChangeDate?: string;
    name: string;
    password: string;
    status?: string;
}
export interface DatabaseAccessLevel {
    read: boolean;
    schema: boolean;
    write: boolean;
}
export interface DatabaseAccessObject {
    accessLevel: string[];
    addDate?: string;
    databaseId: string;
    dbLogin?: string;
    lastChangeDate?: string;
    name?: string;
    read?: boolean;
    schema?: boolean;
    userId?: string;
    write?: boolean;
}

export class MoleculePanelRowDatabaseAccessController {
    public static $inject: string[] = ['$timeout', '$translate', 'databaseUserModel'];

    public databaseUserObject: DatabaseUserObject;
    public accessObject: DatabaseAccessObject;
    public accessLevel: DatabaseAccessLevel;
    public accessLevels: any[];
    public accessAssignment = false;
    public removeFromList: any[] = [];

    public accessHeadings: string[] = [];
    public accessTypeButtons: any[] = [];
    public availableAccesses;
    public choosenAccessId: string;
    public accessSelectItems;
    public accessType: string;
    public onlyNewAccessAvailable = false;
    public rightsChangedCallback: (value: boolean, field: string) => void;
    public userAccessTypeChangeCallback: (accessType: string) => void;
    public usernameChangedCallback: (value: string) => void;
    public userPasswordChangedCallback: (value: string) => void;
    public userCommentChangedCallback: (comments: string) => void;
    public selectExistingUserChangeCallback: (choosenAccessid: string) => void;
    public passwordValidationOptions = Object.assign({}, DatabasePasswordValidationOptions);

    private _accountId: string;
    private databaseId: string;
    private isValidOuter = false;
    private resetAccessOutside = false;

    private originalNewUserObject;

    constructor(
        private $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        private databaseUserModel: DatabaseUserModelService
    ) {}

    public $onInit() {
        this.accessHeadings = [
            this.$translate.instant('TR_110119-76b4ba_TR'),
            this.$translate.instant('TR_100119-e937e9_TR')
        ];

        this.accessTypeButtons = [
            {value: '1', label: this.$translate.instant('TR_110119-d043dc_TR')},
            {value: '2', label: this.$translate.instant('TR_110119-b61be5_TR')}
        ];

        this.accessType = this.accessType || '2';
        this.originalNewUserObject = this.databaseUserObject;
        this.accountId = this.accountId || AuthContextService.account.id;
        this.databaseId = this.databaseId || null;
        this.accessAssignment = this.accessAssignment || this.accessType === '1';

        this.accessLevels = [
            {type: 'read', label: this.$translate.instant('TR_100119-bd2ddd_TR')},
            {type: 'write', label: this.$translate.instant('TR_100119-f68828_TR')},
            {type: 'schema', label: this.$translate.instant('TR_100119-799df6_TR')}
        ];

        if (this.availableAccesses === undefined) {
            this._getAvailabelAccesses();
        }

        this.resetAccess();
        this.isValid();
    }

    public $onChanges(changes) {
        if (changes.resetAccessOutside !== undefined && changes.resetAccessOutside.currentValue) {
            this.resetAccess();
        }

        if (changes.setAccessObjectOutside !== undefined && changes.setAccessObjectOutside.currentValue) {
            this.setAccessObject();
        }

        if (
            changes.removeFromList !== undefined
            && changes.removeFromList.currentValue !== changes.removeFromList.previousValue
        ) {
            this.$timeout(this._getAvailabelAccesses);
        }
    }

    public get accountId() {
        return this._accountId;
    }

    public set accountId(accountId: string) {
        this._accountId = accountId;

        if (this._accountId) {
            this.$timeout(this._getAvailabelAccesses);
        }
    }

    public listDatabaseUsers = (
        limit?: number,
        page?: number,
        filters?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions,
        timeout?: number
    ) => {
        const filterObject: any = {
            subFilter: [{
                field: 'accountId',
                value: AuthContextService.account.id
            }],
            subFilterConnective: 'AND'
        };

        if ([undefined, null].indexOf(filters) >= 0) {
            filterObject.subFilter.push({
                field: 'userName',
                value: '*'
            });
        } else {
            filterObject.subFilter.push(filters);
        }

        return this.databaseUserModel.list(limit, page, filterObject, sort, timeout);
    };

    public setAccessObject = () => {
        // this method called from outside to add (ex. panel-create-database-access)
        this.accessObject.name = this.databaseUserObject.name;
        this.accessObject.accessLevel = [];

        for (const level of this.accessLevels) {
            if (this.accessObject[level.type]) {
                this.accessObject.accessLevel.push(level.type);
            }
        }
    };

    public isValid = (): void => {
        this.$timeout(() => {
            let valid = true;

            if (this.accessType === '1') {
                // Existing access
                valid = [undefined, null, ''].indexOf(this.databaseUserObject.id) < 0;
            } else {
                // New access
                valid = [undefined, null, ''].indexOf(this.databaseUserObject.name) < 0
                    && [undefined, null, ''].indexOf(this.databaseUserObject.password) < 0;
            }

            if (this.accessObject) {
                valid = valid && (
                    this.accessObject.read
                    || this.accessObject.write
                    || this.accessObject.schema
                );
            }

            this.isValidOuter = valid;
        });
    };

    public resetAccess() {
        this.accessObject = {
            accessLevel: DatabaseAccessLevelsAllRightsConst,
            databaseId: this.databaseId,
            name: ''
        };

        for (const level of this.accessLevels) {
            this.accessObject[level.type] = true;
        }

        this.databaseUserObject = {
            accountId: this.accountId,
            comments: '',
            id: null,
            name: '',
            password: ''
        };

        this.accessLevel = {
            read: true,
            schema: true,
            write: true
        };
    }

    public checkboxClickRead = () => {
        this.isValid();

        if (this.rightsChangedCallback) {
            this.rightsChangedCallback(this.accessObject.read, 'read');
        }
    };

    public checkboxClickWrite = () => {
        this.isValid();

        if (this.rightsChangedCallback) {
            this.rightsChangedCallback(this.accessObject.write, 'write');
        }
    };

    public checkboxClickSchema = () => {
        this.isValid();

        if (this.rightsChangedCallback) {
            this.rightsChangedCallback(this.accessObject.schema, 'schema');
        }
    };

    public selectExistingUser = async (value: any) => {
        this.choosenAccessId = value;

        if ([undefined, null].indexOf(this.choosenAccessId) < 0) {
            this.databaseUserObject = await this.databaseUserModel.findOneById(this.choosenAccessId);

            if (this.selectExistingUserChangeCallback) {
                this.selectExistingUserChangeCallback(this.databaseUserObject.id);
            }
        } else {
            this.databaseUserObject = {
                accountId: this.accountId,
                comments: '',
                id: null,
                name: '',
                password: ''
            };
        }

        this.isValid();
    };

    public userAccessTypeChange = () => {
        this.isValid();

        if (this.userAccessTypeChangeCallback) {
            this.userAccessTypeChangeCallback(this.accessType);
        }
    };

    public usernameChanged = () => {
        this.isValid();

        if (this.usernameChangedCallback) {
            this.usernameChangedCallback(this.databaseUserObject.name);
        }
    };

    public userPasswordChanged = () => {
        this.isValid();

        if (this.userPasswordChangedCallback) {
            this.userPasswordChangedCallback(this.databaseUserObject.password);
        }
    };

    public userCommentChanged = () => {
        if (this.userCommentChangedCallback) {
            this.userCommentChangedCallback(this.databaseUserObject.comments);
        }
    };

    private _getAvailabelAccesses = async () => {
        const accesses = await this.databaseUserModel.list(
            null,
            null,
            {
                field: 'accountId',
                value: this.accountId
            },
            null,
            null
        );

        this._setAccesses(accesses.data);
    };

    private _setAccesses = (accesses) => {
        this.accessSelectItems = [];
        this.availableAccesses = accesses;

        for (const access of accesses) {
            if (!this._isAccessInRemoveFromList(access)) {
                let comments = access.comments;

                if (comments.length > 100) {
                    comments = comments.substring(0, 100) + '...';
                }

                this.accessSelectItems.push(
                    {
                        names: [access.name, comments],
                        value: access.id
                    }
                );
            }
        }

        if (this.accessSelectItems.length > 0) {
            this.onlyNewAccessAvailable = false;
            this.accessType = '1';
        } else {
            this.onlyNewAccessAvailable = true;
            this.accessType = '2';
        }
    };

    private _isAccessInRemoveFromList = (access) => {
        if (this.removeFromList === undefined) {
            return false;
        }

        return this.removeFromList.some((removeAccess) => {
            return access.id === removeAccess.userId || access.id === removeAccess.id;
        });
    };
}

export class MoleculePanelRowDatabaseAccessComponent implements ng.IComponentOptions {
    public bindings = {
        accessAssignment: '<',
        accessObject: '=?newAccessObject',
        accessType: '=?',
        accountId: '<',
        choosenAccessId: '=?',
        databaseId: '<',
        databaseUserObject: '=?newDatabaseUserObject',
        isValidOuter: '=isValid',
        removeFromList: '<',
        rightsChangedCallback: '<?',
        selectExistingUserChangeCallback: '<?',
        setAccessObjectOutside: '<',
        setAccessOutside: '<',
        userAccessTypeChangeCallback: '<?',
        userCommentChangedCallback: '<?',
        userPasswordChangedCallback: '<?',
        usernameChangedCallback: '<?'
    };

    public controller = MoleculePanelRowDatabaseAccessController;
    public template = require('./database-access.html');
}
