import * as ng from 'angular';

import {
    SeletionItem
} from '../../../atomic-components/molecules/forms/form-elements/product-select/item-box-new-generic';
import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import * as Types from '../../../types';

export class TemplateWizardGlobalController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;
    public selectedProduct: SeletionItem;
    public productSelection: SeletionItem[];
    public selectedAccount: Types.AccountApi.Account | Types.AccountApi.Subaccount;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.pageHeaderData = {
            backwardLink: 'dashboard',
            backwardText: this.$translate.instant('TR_220519-98c7e4_TR'), // storage: TR_150519-cfa086_TR
            panelHeaderRoute: '',
            panelHeaderRouteParams: {},
            panelIcon: 'server',
            panelTitle: 'Globaler Service Wizard'
        };
    }
}

export class TemplateWizardGlobalComponent implements ng.IComponentOptions {
    public bindings = {
    };
    public controller = TemplateWizardGlobalController;
    public template = require('./wizard-global-template.html');
}
