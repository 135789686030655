import * as ng from 'angular';

export class MoleculePanelEditRowNumberController implements ng.IController {
    public static $inject: string[] = [];

    public model: string;
    public readonly: boolean;
    public disabled: boolean;
    public callbackOnChange: (arg0: any) => any;
    public callbackOnChangeParams: any;
    public validationInstructions: any[]; // Used to pass custom validators if type 'custom' is used
    public min: number;
    public max: number;
    public placeholder: string;
    public step: number;
    public catchFocus: boolean;
}

export class MoleculePanelEditRowNumberComponent implements ng.IComponentOptions {
    public transclude = {
        label: 'rowLabel'
    };
    public bindings = {
        callbackOnChange: '<',
        callbackOnChangeParams: '<',
        catchFocus: '<',
        disabled: '<',
        max: '<',
        min: '<',
        model: '=',
        placeholder: '@?',
        readonly: '<',
        step: '<?',
        validationInstructions: '<'
    };
    public controller = MoleculePanelEditRowNumberController;
    public controllerAs = '$panelRow';
    public template = require('./panel-edit-row-number.html');
}
