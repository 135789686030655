import * as ng from 'angular';
import { LocationMetadata, LocationMetadataArray } from './../../../organisms/forms/edit-forms/domain-edit/domain-locations';

import './domain-locations-list-item.scss';

export class MoleculeListsDomainLocationsListItemController implements  ng.IController {
    public static $inject: string[] = ['$translate'];

    public allLocations: LocationMetadataArray;
    public domainLocation: LocationMetadata;
    public formIsEditable: boolean;
    public cleanUrlsEnabled = false;

    private originalLocation: any;

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {}

    $onInit() {
        this.originalLocation = ng.copy(this.domainLocation, this.originalLocation);
    }

    public toggleDelete = () => {
        this.domainLocation.markedForRemoval = !this.domainLocation.markedForRemoval;
    };

    public setEditMode = () => {
        this.domainLocation.editModel = true;
    };

    public cancelEditMode = () => {
        this.domainLocation.editModel = false;
        this.domainLocation = ng.copy(this.originalLocation, this.domainLocation);
    };

    public confirmEdit = () => {
        this.domainLocation.editModel = false;
    };

    public getInfoString = () => {
        switch (this.domainLocation.data.locationType) {
            case 'redirect': {
                return this.$translate.instant('TR_140119-8e4b60_TR')
                    + ': ' + this.domainLocation.data.redirectionStatus
                    + ', ' + this.$translate.instant('abb61689-d9e2-4ad9-a40d-8d7eeb18f078')
                    + ': ' + this.domainLocation.data.redirectionUrl;

            }
            case 'blockAccess': {
                return this.$translate.instant('87ac6f4c-94a6-4f4b-a66e-a723f6538ee1');
            }
            default: {
                let infoString = '';
                if (this.domainLocation.data.phpEnabled) {
                    infoString = infoString + 'PHP, ';
                }
                if (this.domainLocation.data.directoryListingEnabled) {
                    infoString = infoString + this.$translate.instant('TR_100119-32aa71_TR') + ', ';
                }
                if (this.domainLocation.data.blockDotfiles) {
                    infoString = infoString + this.$translate.instant('73754597-f507-4932-8df8-ab59f05e7c10') + ', ';
                }
                if (this.domainLocation.data.mapScript.length > 0) {
                    infoString = infoString
                        + this.$translate.instant('bd3b04dc-a14f-4858-b5ba-19966d8824d0')
                        + ' (' + this.domainLocation.data.mapScript + '), ';
                }
                if (infoString.length > 0) {
                    infoString = infoString.slice(0, -2);
                }
                return infoString;
            }
        }
    };

    public get showButtons() {
        return this.formIsEditable && !this.domainLocation.data.setByProfile;
    }

    public get showSetByProfileMessage() {
        return this.formIsEditable && this.domainLocation.data.setByProfile;
    }

    public get showArrowButtons() {
        return this.domainLocation.sortable;
    }

    public get showEditButton() {
        return !this.domainLocation.editModel;
    }

    public get showDeleteButton() {
        return !this.domainLocation.editModel;
    }

    public get showCancelButton() {
        return this.domainLocation.editModel;
    }

    public get disableUpButton() {
        return this.domainLocation.order === 0;
    }

    public get disableDownButton() {
        return !this.allLocations.locations.some((location) => location.order > this.domainLocation.order);
    }

    public moveDown = () => {
        if (!this.isLocationProfile().some((location) => location.order === this.domainLocation.order + 1)) {
            this.swapLocationOrder(this.domainLocation.order, this.domainLocation.order + 1);
        } else {
            // jump over setByProfile locations
            this.jumpOverSetByProfileLocation(this.domainLocation.order, 'down');
        }
    };

    public moveUp = () => {
        if (!this.isLocationProfile().some((location) => location.order === this.domainLocation.order - 1)) {
            this.swapLocationOrder(this.domainLocation.order, this.domainLocation.order - 1);
        } else {
            // jump over setByProfile locations
            this.jumpOverSetByProfileLocation(this.domainLocation.order, 'up');
        }
    };

    private jumpOverSetByProfileLocation = (order: number, direction: string) =>  {
        const nextLocation = this.allLocations.locations.filter((location) => {
            if (direction === 'up') {
                return location.order === order - 1;
            } else if (direction === 'down') {
                return location.order === order + 1;
            }
        }).shift();

        if (nextLocation) {
            const nextOrder = nextLocation.order;
            if (nextLocation.data.setByProfile) {
                this.swapLocationOrder(order, nextOrder);
                this.jumpOverSetByProfileLocation(nextOrder, direction);
            }
        }
    };

    private swapLocationOrder = (order1: number, order2: number) => {
        const swapLocations = this.allLocations.locations.filter((location) => {
            return [order1, order2].indexOf(location.order) >= 0;
        });
        if (swapLocations.length === 2) {
            [swapLocations[0].order, swapLocations[1].order] = [swapLocations[1].order, swapLocations[0].order];
        }
    };

    private isLocationProfile = () => {
        return this.allLocations.locations.filter((location) => {
            return location.data.setByProfile;
        });
    };

}

export class MoleculeListsDomainLocationsListItemComponent implements ng.IComponentOptions {
    bindings = {
        domainLocation: '=',
        formIsEditable: '<',
        allLocations: '<'
    };
    public controller = MoleculeListsDomainLocationsListItemController;
    public template = require('./domain-locations-list-item.html');
}
