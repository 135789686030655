import * as ng from 'angular';

import { PageHeaderData } from '../../../atomic-components/organisms';

export class TemplateUserCreateController {
    public static $inject: string[] = ['$state', '$translate'];

    public rightsTemplates: any[];
    public allRights: any[];

    public pageHeaderData: PageHeaderData = {
        backwardLink: this.$state.current.name.split('.').indexOf('subaccounts') < 0
            ? 'account.users.overview'
            : 'reseller.subaccounts.id.edit',
        backwardText: this.$state.current.name.split('.').indexOf('subaccounts') < 0
            ? this.$translate.instant('TR_090119-a089dd_TR')
            : this.$translate.instant('TR_280219-5dacf4_TR'),
        panelHeaderRoute: '',
        panelIcon: 'user',
        panelTitle: this.$translate.instant('TR_090119-f7a0fb_TR')
    };

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}
}

export class TemplateUserCreateComponent implements ng.IComponentOptions {
    public bindings = {
        allRights: '<',
        rightsTemplates: '<'
    };
    public template = require('./user-create.html');
    public controller = TemplateUserCreateController;
}
