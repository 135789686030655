import * as ng from 'angular';

import { CheckboxStatusChange } from '@/atomic-components/molecules/forms/form-elements/checkbox/checkbox';
import { MoleculePanelEditController } from '@/atomic-components/molecules/panels';
import * as Types from '@/types';

export class MoleculePanelEditRowPermissionMiscController implements ng.IController {
    public static $inject: string[] = [];

    public $editPanel: MoleculePanelEditController;

    public permission: Types.AccountApi.Right;
    public originalPermissions: string[];
    public selectedPermissions: string[];
    public templatePermissions: string[];

    public get editable(): boolean {
        return this.$editPanel.isEditable
            && this.templatePermissions.every(
                (permission) => this.permission.value !== permission
            );
    }

    public get selected(): boolean {
        return this.selectedPermissions.some(
            (permission) => this.permission.value === permission
        );
    }

    public set selected(selected: boolean) {
        if (selected) {
            this.selectedPermissions.push(this.permission.value);
        } else {
            this.selectedPermissions = this.selectedPermissions.filter(
                (permission) => this.permission.value !== permission
            );
        }
    }

    public get statusChange(): CheckboxStatusChange {
        if (!this.originalPermissions) {
            return 'none';
        }

        const selectedBefore = this.originalPermissions.indexOf(this.permission.value) >= 0;
        const selectedNow = this.selectedPermissions.indexOf(this.permission.value) >= 0;

        if (selectedBefore && !selectedNow) {
            return 'removed';
        }

        if (!selectedBefore && selectedNow) {
            return 'added';
        }

        return 'none';
    }
}

export class MoleculePanelEditRowPermissionMiscComponent implements ng.IComponentOptions {
    public bindings = {
        permission: '<',
        originalPermissions: '=',
        selectedPermissions: '=',
        templatePermissions: '<'
    };

    public require = {
        $editPanel: '?^moleculePanelEdit'
    };

    public template = require('./panel-edit-row-permission-misc.html');
    public controller = MoleculePanelEditRowPermissionMiscController;
}
