import { AlertManagerService } from '../alert-manager';
import { FunctionRelayService } from '../function-relay';
import { ModelHelper } from '../model-helper';
import { RequestStatus } from '../rpc-client';
import { DatabaseRobotService } from './robot';

import * as ng from 'angular';

export class DatabaseModelService {
    public static $inject: string[] = [
        '$timeout',
        'alertManager',
        'databaseRobot',
        '$rootScope',
        '$q',
        'functionRelay'
    ];

    constructor(
        private $timeout: ng.ITimeoutService,
        private alertManager: AlertManagerService,
        private databaseRobot: DatabaseRobotService,
        private $rootScope: ng.IRootScopeService,
        private $q: ng.IQService,
        private functionRelay: FunctionRelayService
    ) {}

    public list = (limit?, page?, filters?, sort?, cancel?) => {
        sort = sort || {
            field: 'databaseName',
            order: 'ASC'
        };
        page = page || 1;

        return this.databaseRobot.listDatabases(filters, limit, page, sort, {timeout: cancel})
            .then(ModelHelper.returnListResponse);
    };

    public listWithoutPagination = (limit?, page?, filters?, sort?, cancel?) => {
        sort = sort || {
            field: 'databaseName',
            order: 'ASC'
        };
        page = page || 1;

        return this.databaseRobot.listDatabasesWithoutPagination(filters, limit, page, sort, {timeout: cancel})
            .then(ModelHelper.returnListResponse);
    };

    public findOneById = (id) => {
        return this.databaseRobot.listDatabasesWithoutPagination({field: 'databaseId', value: id}, 10, 1)
            .then(ModelHelper.returnFindOneResponse);
    };

    public findById = (ids) => {
        const filter = {subFilterConnective: 'OR', subFilter: ids.map(this.idFilter)};
        return this.databaseRobot.listDatabasesWithoutPagination(filter, 0, 1)
            .then(ModelHelper.returnFindResponse);
    };

    public create = (database, owner?, poolId?, resourceId?) => {
        return this.databaseRobot.createDatabase(database, owner, poolId, resourceId)
            .then(this.returnResponse)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED))
            .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
    };

    public databaseModifyRestrictions = (apiObject, owner) => {
        return this.databaseRobot.databaseModifyRestrictions(apiObject, owner)
            .then(this.returnResponse)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public findOneByName = (name) => {
        return this.databaseRobot.listDatabasesWithoutPagination({field: 'databaseName', value: name}, 1, 1)
            .then(ModelHelper.returnFindOneResponse);
    };

    public update = (database, killClientConnections) => {
        return this.databaseRobot.updateDatabase(database, database.accesses, killClientConnections)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED))
            .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
    };

    public deleteOne = (database, execDate) => {
        if (execDate !== undefined && execDate !== null) {
            execDate.setUTCHours(0, 0, 0, 0);
        }
        return this.databaseRobot.deleteDatabase(database.id, execDate)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public cancelDeletion = (databaseId) => {
        return this.databaseRobot.databaseDeletionCancel(databaseId)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public deleteOnePermanently = (database) => {
        return this.databaseRobot.deletePermanentlyDatabase(database)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public restore = (databases) => {
        const promises = ModelHelper.sequentiallyRequestApi(
            databases,
            'id',
            this,
            'restoreOne',
            /* translationID */'TR_070819-6042e9_TR',
            this.$timeout,
            this.alertManager
        );

        return promises.then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public purgeRestorable = (databaseId) => {
        return this.databaseRobot.purgeRestorable(databaseId)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public wipeDatabase = (databaseId) => {
        return this.databaseRobot.wipeDatabase(databaseId)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    private refresh = (status: RequestStatus = RequestStatus.SUCCESSFUL) => (result) => {
        this.$rootScope.$broadcast('databases.refresh');
        if (status === RequestStatus.SUCCESSFUL) {
            return result;
        } else {
            return this.$q.reject(result);
        }
    };

    private refreshDeposit = (status: RequestStatus = RequestStatus.SUCCESSFUL) => (result) => {
        this.functionRelay.call('updateDeposit', {});
        if (status === RequestStatus.SUCCESSFUL) {
            return result;
        } else {
            return this.$q.reject(result);
        }
    };

    private idFilter = (id) => {
        return {field: 'databaseId', value: id};
    };

    private returnResponse = (result) => {
        return result.response;
    };

    private restoreOne = (database) => {
        return this.databaseRobot.restoreDatabase(database.id);
    };
}
