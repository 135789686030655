import { IBackupAdminService } from '@/services/backup/IBackupAdminService';
import { BackupApi } from '@/types';
import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import { AuthContextService } from '../../../../../services';

export class OrganEditPanelBackupsController {
    public static $inject: string[] = ['$translate', 'backupAdminService'];

    public $editFormOrganism: {backups?: {targetobjectType: string; type: string}[]; objectToBackup: {id: string}};
    public panelRight: any;
    public disableAddButton: boolean;
    public service: string;
    public adminData: BackupApi.BackupExtendedObject;
    constructor(
        protected $translate: ng.translate.ITranslateService,
        private backupAdminService: IBackupAdminService
    ) {}

    public $onInit() {
        if (this.$editFormOrganism.backups?.length > 0){
            this.backupAdminService.getAdminBackupData(this.service,
                this.$editFormOrganism.backups[0].targetobjectType,
                this.$editFormOrganism.objectToBackup.id)
                .then(data => {
                    this.adminData = data;
                })
                .then(() => {
                    this.setup();

                }).catch(() => {
                    this.setup();
                });
            }
        else {
            this.setup();
        }

    }

    private setup(): void{
        this.disableAddButton = this.$editFormOrganism.backups
        .filter((backup) => backup.type === 'manual')
        .length >= 2;
    }

    public get manualBackupRightGranted() {
        switch (this.service) {
            case 'webhosting':
                return AuthContextService.isGranted(UiRights.BACKUP_START_MANUAL_BACKUP_WEBHOSTING);
            case 'database':
                return AuthContextService.isGranted(UiRights.BACKUP_START_MANUAL_BACKUP_DATABASE);
            default:
                return AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_SUPER_USER_EDIT);
        }
    }

    public get addButtonText() {
        if ([undefined, false].indexOf(this.$editFormOrganism.backups) >= 0) {
            return this.$translate.instant('TR_110119-33267b_TR');
        }

        if (this.$editFormOrganism.backups.length === 0) {
            return this.$translate.instant('TR_110119-33267b_TR');
        } else {
            return this.$translate.instant('TR_110119-792b45_TR');
        }
    }
}

export class OrganEditPanelBackupsComponent implements ng.IComponentOptions {
    public bindings = {
        panelRight: '<',
        service: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormBackups'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./backups.html');
    public controller = OrganEditPanelBackupsController;
}
