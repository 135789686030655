import ng from 'angular';
import {MoleculeFormEditController,} from '../form-edit/form-edit'

export class MoleculeFormEditBridgeController {
    public static $inject = ['$scope'];
    public $editForm: MoleculeFormEditController;
    public formIsValid: boolean;

    constructor(
        private $scope: ng.IScope,
    ) {}

    public $onInit() {
        this.$scope.$watch("$ctrl.$editForm.$valid", () => {
            this.formIsValid = this.$editForm.$valid;
            }
        );
    }

}

export class MoleculeFormEditBridgeComponent implements ng.IComponentOptions {
    public bindings = {
        formIsValid: '=',
    };
    public require = {
        $editForm: '^moleculeFormEdit',
    };
    public controller = MoleculeFormEditBridgeController;
    public template = '';
}
