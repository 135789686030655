import * as ng from 'angular';
import { UiRights } from '../../../../../../configuration';
import { AuthContextService, BundleModelService } from '../../../../../../services';
import { DashboardOverviewDataObject, OrganismOverviewController } from '../../../../../organisms/overview';

export class MoleculeOverviewRowPanelWebhostingWebspacesController implements ng.IController {
    public static $inject: string[] = ['$state', 'authContext', 'bundleModel'];

    public overviewData: DashboardOverviewDataObject;
    public overviewLoad: () => any;
    public internalPaginationLimit: number;
    public OrganismOverview: OrganismOverviewController;
    public showBundles = false;
    public sideEntryPoint: string;
    public stateGlobals: any;

    constructor(
        public $state: ng.ui.IStateService,
        public authContext: AuthContextService,
        public bundleModel: BundleModelService
    ) {

        if (authContext.isGranted(UiRights.BIL_BUNDLE_LIST)) {
            bundleModel.listWithoutPagination(1).then(
                (result) => this.showBundles = result.data && result.data.length > 0
            );
        }
    }

    public $onInit() {
        this.sideEntryPoint = this.$state.current.name.split('.')[0];
        this.stateGlobals = this.$state.$current.locals.globals;
    }
    public get showAccounts() {
        return this.OrganismOverview.globals.showAccounts;
    }
}

export class MoleculeOverviewRowPanelWebhostingWebspacesComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelWebhostingWebspacesController;
    public controllerAs = 'OverviewWebhostingWebspacesCtrl';
    public template = require('./webhosting-webspace-row.html');
}
