import * as ng from 'angular';
import { DomainApi } from '../../../types';
import { UiRights } from './../../../configuration/rights';
import { AuthContextService } from './../../../services/auth-context';

import { PageHeaderData } from '../../../atomic-components/organisms';

export class TemplateDomainProductEditController {
    public static $inject: string[] = [
        '$state',
        '$translate'
    ];

    public pageHeaderData: PageHeaderData;

    public domain: DomainApi.Domain;
    public zoneConfig = {};
    public records: any[];
    public tags;
    public showObjectId: boolean;

    public faqArticleIdList: string[] = [
        'domain-authcode-definition',
        'domain-create-authcode',
        'domain-handle-definition'
    ];

    private zone: any;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = () => {
        const fromBundle = this.$state.current.name.indexOf('bundle') >= 0;

        this.zoneConfig = this.zone.length > 0 ? this.zone[0].zoneConfig : null;
        this.records = this.zone.length > 0 ? this.zone[0].records : [];
        this.pageHeaderData = {
            backwardLink: fromBundle ? 'bundle.id.domains.overview' : 'domain.domains.overview',
            backwardText: this.$translate.instant('TR_110119-7424eb_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: fromBundle ? { bundleId: this.domain.bundleId } : '',
            panelIcon: 'globe',
            panelTitle: this.$translate.instant('TR_110119-a42657_TR', { domainName: this.domain.nameUnicode }),
            productActions: [{
                route: 'domain.domains.id.move',
                stateParams: { domainId: this.domain.id },
                title: this.$translate.instant('83f251a8-7d02-4f5d-a45e-90fc3502ad67')
            }]
        };

        if (!!this.domain.bundleId && AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_DOMAIN_SUPPORT)) {
            this.pageHeaderData.productActions.push({
                route: 'domain.domains.id.remove-from-bundle',
                stateParams: { domainId: this.domain.id },
                title: this.$translate.instant('TR_061020-076f51_TR')
            });
        }
    };
}

export class TemplateDomainProductEditComponent implements ng.IComponentOptions {
    public bindings = {
        domain: '<',
        showObjectId: '<',
        tags: '<',
        zone: '<'
    };
    public template = require('./domain-product-edit-template.html');
    public controller = TemplateDomainProductEditController;
}
