import { IScope } from 'angular';

// triggers digest cycle. We like to use it in .then chains, because setTimeouts seem to break them.
// This also seems to be an antipattern, but we didn't find a better solution.
export const applyIfNeeded = ($rootScope: IScope, fn: () => void): void => {
    if ($rootScope.$$phase) {
        fn();
    } else {
        $rootScope.$applyAsync();
        fn();
    }
};
