import * as ng from 'angular';
import { UiRights, VhostDefaultLocationsObjectConst } from '../../../../../configuration';
import { AuthContextService } from '../../../../../services';
import { domainLocationsFormState, LocationMetadata, LocationMetadataArray } from '../../../../organisms/forms/edit-forms';

import './domain-locations-edit.scss';

export class OrganEditPanelDomainLocationsEditController {
    public static $inject: string[] = ['$timeout'];

    public $editFormMolecule;
    public $editFormOrganism;

    public defaultLocation: any;
    public generalSettingsEditMode = false;
    public newLocation: LocationMetadata;
    public newLocationType: string;
    public nonSortableLocations: LocationMetadataArray;
    public nonSortableLocationsEditMode = false;
    public showAddDropdown = false;
    public sortableLocations: LocationMetadataArray;
    public sortableLocationsEditMode = false;

    private cleanUrlsEnabled = false;

    constructor(
        private $timeout: ng.ITimeoutService
    ) {}

    public $onInit() {
        this.$editFormMolecule.saveCallback = this.save;
        this.newLocation = {
            data: this.getEmptyLocation(''),
            editModel: false,
            httpHeaderEnabled: false,
            httpHeaderInfo: [],
            markedForRemoval: false,
            order: null,
            sortable: null
        };
        this.$timeout(
            () => {
                this.cleanUrlsEnabled = this.defaultLocation.data.mapScript.length > 0;
            }
        );
    }

    public get showSaveButton() {
        return this.$editFormOrganism.formState === domainLocationsFormState.EDITABLE;
    }

    public get showAddButton() {
        return this.$editFormOrganism.formState === domainLocationsFormState.EDITABLE;
    }

    public get showEditButton() {
        return this.$editFormOrganism.formState === domainLocationsFormState.READONLY;
    }

    public get showCancelButton() {
        return this.$editFormOrganism.formState === domainLocationsFormState.EDITABLE;
    }

    public get showReadonlyContent() {
        return this.$editFormOrganism.formState === domainLocationsFormState.READONLY;
    }

    public get disableSaveButton() {
        return !this.$editFormMolecule.$valid;
    }

    public get formIsEditable() {
        return this.$editFormOrganism.formState === domainLocationsFormState.EDITABLE;
    }

    public isSuperUser = () => {
        return AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_WEBHOSTING_ADMIN);
    };

    public get isCleanUrlsEnabled() {
        return this.cleanUrlsEnabled;
    }

    public set isCleanUrlsEnabled(value: boolean) {
        this.cleanUrlsEnabled = value;
        if (!value) {
            this.defaultLocation.data.mapScript = '';
        }
    }

    public setEditMode = () => {
        this.$editFormOrganism.formState = domainLocationsFormState.EDITABLE;
    };

    public setEditableGeneral = () => {
        this.generalSettingsEditMode = true;
        this.nonSortableLocationsEditMode = true;
        this.sortableLocationsEditMode = true;
        this.setEditMode();
    };

    public save = () => {
        this.generalSettingsEditMode = false;
        this.nonSortableLocationsEditMode = false;
        this.sortableLocationsEditMode = false;
        return this.$editFormOrganism.saveDomainLocations();
    };

    public cancel = () => {
        this.$editFormOrganism.formState = domainLocationsFormState.READONLY;
        this.generalSettingsEditMode = false;
        this.nonSortableLocationsEditMode = false;
        this.sortableLocationsEditMode = false;
        this.$editFormOrganism.resetLocations();
        this.$onInit();
    };

    public cancelGeneral = () => {
        this.generalSettingsEditMode = false;
        this.nonSortableLocationsEditMode = false;
        this.sortableLocationsEditMode = false;
        this.$editFormOrganism.resetDefaultLocation();
        this.$onInit();
    };

    public toggleAddDropdown = () => {
        if (!this.generalSettingsEditMode) {
            this.setEditableGeneral();
        }
        this.showAddDropdown = !this.showAddDropdown;
    };

    public openAddDialog = (type: string) => {
        this.$editFormOrganism.newLocationType = type;
        this.$editFormOrganism.formState = domainLocationsFormState.ADD;
        this.showAddDropdown = false;
        const newLocationData = this.getEmptyLocation(type);
        this.newLocation = {
            data: newLocationData,
            editModel: false,
            httpHeaderEnabled: false,
            httpHeaderInfo: this.$editFormOrganism.createHttpHeaderData(newLocationData),
            markedForRemoval: false,
            order: null,
            sortable: null
        };
    };

    private getEmptyLocation = (type: string) => {
        const emptyLocation = VhostDefaultLocationsObjectConst[0];
        switch (type) {
            case 'blockAccess':
                emptyLocation.locationType = 'blockAccess';
                emptyLocation.matchType = 'directory';
                emptyLocation.redirectionStatus = '';
                break;
            case 'redirect':
                emptyLocation.locationType = 'redirect';
                emptyLocation.matchType = 'directory';
                emptyLocation.redirectionStatus = '302';
                break;
            case 'location':
                emptyLocation.locationType = 'generic';
                emptyLocation.matchType = 'directory';
                emptyLocation.redirectionStatus = '';
                emptyLocation.blockDotfiles = true;
                emptyLocation.mapStyle = null;
                break;
            default:
                break;
        }
        return emptyLocation;
    };
}

export class OrganEditPanelDomainLocationsEditComponent implements ng.IComponentOptions {
    public bindings = {
        defaultLocation: '=',
        formState: '=',
        nonSortableLocations: '=',
        sortableLocations: '='
    };
    public require = {
        $editFormOrganism: '^organismEditFormDomainLocations',
        $editFormMolecule: '^moleculeFormEdit'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./domain-locations-edit.html');
    public controller = OrganEditPanelDomainLocationsEditController;
}
