import * as ng from 'angular';

export class OrganDashboardWidgetSmsValidationController implements ng.IController {
    public static $inject: string[] = ['authContext'];
}

export class OrganDashboardWidgetSmsValidationComponent implements ng.IComponentOptions {
    public bindings = {};

    public template = require('./dashboard-widget-sms-validation.html');
    public controller =  OrganDashboardWidgetSmsValidationController;
}
