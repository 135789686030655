import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels';

export class TemplateOrganizationOverviewController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;
    public overviewServiceData;
    public expertView: boolean;
    public overviewModel;
    private simpleFilter;
    private extendedFilters;
    private overviewUiSettings;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit() {
        if ([undefined, null].indexOf(this.simpleFilter) === -1) {
            this.expertView = this.overviewUiSettings.expertView || this.simpleFilter.show;
        } else {
            this.expertView = this.overviewUiSettings.expertView || false;
        }

        this.pageHeaderData = {
            backwardLink: 'email.dashboard',
            backwardText: this.$translate.instant('9a606991-45b5-4ec6-a927-ccfbaf9b24ae'),
            panelHeaderRoute: '',
            panelIcon: 'envelope',
            panelTitle: this.$translate.instant('TR_110119-4db9fc_TR')
        };
        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter
        };
    }

    public expandWrapper = (toggle) => {
        this.expertView = toggle.expand;
    };
}

export class TemplateOrganizationOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public controller = TemplateOrganizationOverviewController;
    public controllerAs = 'TemplateOrganizationOverviewCtrl';
    public template = require('./organization-overview-template.html');
}
