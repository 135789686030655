import {
    DashboardWidgetModel,
    DashboardWidgetType
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model';
import {
    DashboardWidgetObjectDefinition
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model-operator';

export class DashboardWidgetProductBundleModel extends DashboardWidgetModel {
    public static $inject: string[] = DashboardWidgetModel.$inject.concat([]);

    public static dashboardWidgetDefinition: DashboardWidgetObjectDefinition = {
        demoData: {
            bundle: {
                name: 'Paket Demo',
                status: 'active',
                deletionScheduledFor: null
            },
            webspace: {
                name: 'Webspace Demo',
                status: 'active',
                storageQuota: 11264,
                storageQuotaIncluded: 10240,
                storageQuotaUsedRatio: 0,
                storageUsed: 0,
                webspaceName: 'webta5tdh'
            },
            vhosts: [
                {
                    domainName: 'demo-domain-1.de',
                    domainNameUnicode: 'demo-domain-1.de',
                    status: 'active'
                },
                {
                    domainName: 'demo-domain-2.de',
                    domainNameUnicode: 'demo-domain-2.de',
                    status: 'active'
                },
                {
                    domainName: 'demo-domain-3.de',
                    domainNameUnicode: 'demo-domain-3.de',
                    status: 'active'
                },
                {
                    domainName: 'demo-domain-4.de',
                    domainNameUnicode: 'demo-domain-4.de',
                    status: 'active'
                },
                {
                    domainName: 'demo-domain-5.de',
                    domainNameUnicode: 'demo-domain-5.de',
                    status: 'active'
                },
                {
                    domainName: 'demo-domain-6.de',
                    domainNameUnicode: 'demo-domain-6.de',
                    status: 'active'
                }
            ]
        },
        manualAddable: true,
        userPermissions: {
            isGranted: 'BIL_BUNDLE_LIST'
            // notRootOrCompanyAccount: true // disabled for testing
        },
        widgetDescription: [
            /* translationId */ 'TR_150321-9c0469_TR'
        ],
        widgetObject: {
            attribute: {
                id: 'organDashboardWidgetProductBundle',
                width: 2,
                height: 1,
                x: 0,
                y: 0,
                noResize: true,
                autoPosition: true
            },
            componentTagName: 'organ-dashboard-widget-product-bundle',
            widgetModel: 'dashboardWidgetProductBundleModel',
            version: '1.0'
        },
        widgetTitle: /* translationId */ 'TR_290121-db3b29_TR',
        widgetType: DashboardWidgetType.custom
    };

    public dashboardWidgetDefinition: DashboardWidgetObjectDefinition;

    constructor() {
        super();

        this.dashboardWidgetDefinition = DashboardWidgetProductBundleModel.dashboardWidgetDefinition;
    }
}
