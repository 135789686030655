import * as ng from 'angular';

import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';
import * as Types from '../../../types';

export class TemplateDatabaseUserEditController {
    public static $inject: string[] = ['$translate', 'authContext'];

    public modifier = '';
    public pageHeaderData: PanelHeaderData;

    public originalUser: Types.WebhostingApi.User;

    public userPanelRight;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'database.users.overview',
            backwardText: this.$translate.instant('TR_100119-32d822_TR'),
            panelHeaderRoute: '',
            panelIcon: 'user',
            panelTitle: this.$translate.instant('TR_100119-bee3b9_TR')
        };

        this.userPanelRight = {
            delete: {
                editPanelButton: this.authContext.isGranted(UiRights.DB_USER_DELETE)
            },
            general: {
                editPanelButton: this.authContext.isGranted(UiRights.DB_USER_EDIT)
            }
        };
    };
}

export class TemplateDatabaseUserEditComponent implements ng.IComponentOptions {
    public bindings = {
        originalUser: '<user'
    };
    public controller = TemplateDatabaseUserEditController;
    public controllerAs = '$TemplateDatabaseUserEditCtrl';
    public template = require('./database-user-edit-template.html');
}
