import * as ng from 'angular';

import { InputPlaceholderConst } from '@/configuration';
import {
    CleanableStringType,
    DomainCheckerService,
    DomainInfoHelperService,
    PriceCacheService,
    StringCleaner
} from '@/services';
import { ValidateDomainName } from '@/services/validators/validate-domain-name';
import * as Types from '@/types';

import './panel-edit-row-domain-multi.scss';

interface PriceBoxObject {
    tld: string;
    promotionalAvailable: boolean;
}
export class MoleculePanelEditRowDomainMultiController implements ng.IController {
    public static $inject: string[] = [
        '$state',
        '$stateParams',
        '$timeout',
        '$translate',
        'domainChecker',
        'domainInfoHelper',
        'priceCache'
    ];

    public domainInfo: {
        domain: Types.DomainApi.Domain;
        prices: Types.BillingApi.DomainPrice;
    }[];
    public authCodes: { domain: string; authCode: string}[] = [];
    public _extended: boolean;
    public loading = false;
    public textHasBeenPasted = false;
    public disableMultiSearch: boolean;
    public errorMessages: unknown[] = [];
    public domainSearch: string;
    public outerDomainCheck: (arg0: unknown) => void;
    public _accountId: string;
    public smallView: boolean;
    public showTldPrices: string[];
    public priceBoxes: PriceBoxObject[];

    protected inputPlaceholder = InputPlaceholderConst.domains;

    private domainNameValidator: ValidateDomainName;
    private inputText: string;
    private lastDomainCheck: string;
    private topDomainEndings: string[] = ['.de', '.eu', '.at', '.com', '.net', '.org'];
    private _endingsToUse: string[];
    private _isInitialized = false;
    private _domainNames: string[];

    constructor(
        private $state: ng.ui.IStateService,
        private $stateParams: ng.ui.IStateParamsService,
        private $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        private domainChecker: DomainCheckerService,
        private domainInfoHelper: DomainInfoHelperService,
        private priceCache: PriceCacheService
    ) {}

    public $onInit(): void {
        this._setPriceBoxes();
        this.disableMultiSearch = this.disableMultiSearch || false;
        this.extended = this.extended || false;
        this.smallView = this.smallView || false;

        if (this.domainSearch) {
            this.inputText = this.domainSearch;
        }

        if ([undefined, null, ''].indexOf(this.$stateParams.accountId) === -1) {
            this._accountId = this.$stateParams.accountId;
        }

        if ([undefined, null, ''].indexOf(this.$stateParams.domainSearchString) === -1) {
            this.inputText = this.$stateParams.domainSearchString;
        }

        if (
            [undefined, null, ''].indexOf(this.inputText) < 0
            || [undefined, null].indexOf(this.$stateParams.domainList) === -1
        ) {
            this.checkDomains();
        }

        this.domainNameValidator = new ValidateDomainName(this.$translate, this.domainInfoHelper);

        this._isInitialized = true;
    }

    public $onChanges(): void {
        if (this._isInitialized && !this.textHasBeenPasted) {
            this.checkDomains();
        }
    }

    public get extended(): boolean {
        if (this.domainNames.length > 1) {
            this._extended = true;
        }

        return this._extended;
    }

    public set extended(extended) {
        this._extended = extended;
    }

    public get endingsToUse(): string[] {
        return this._endingsToUse;
    }

    public set endingsToUse(endings: string[]) {
        this._endingsToUse = endings;
        this.checkDomains();
    }

    public get isSearchButtonDisabled(): boolean {
        const hasErrors = this.errorMessages.length > 0;
        const hasEmptyInput = this.domainNames.length === 0;
        const hasInvalidChars = this.domainNames.some(
            (domainName) => {
                return !/([^;]+)(;.+)?/i.test(domainName);
            }
        );

        return hasErrors || hasEmptyInput || hasInvalidChars;
    }

    public get accountId(): string {
        return this._accountId;
    }

    public set accountId(value) {
        const oldValue = this._accountId;
        this._accountId = value;

        if ([undefined, null, ''].indexOf(oldValue) < 0 || [undefined, null, ''].indexOf(value) < 0) {
            this.checkDomains();
        }
    }

    public get rows(): number {
        if (this.extended) {
            return this.domainNames.length >= 2 ? this.domainNames.length + 1 : 3;
        } else {
            return 1;
        }
    }

    public set domainNames(values) {
        this._domainNames = values ? values : [];
        this.inputText = this._domainNames.join('\n');
    }

    public get domainNames(): string[] {
        if ([undefined, null, ''].indexOf(this.inputText) >= 0) {
            this._domainNames = [];
            return [];
        }
        const domainRows = StringCleaner.clean(this.inputText).as(CleanableStringType.DomainNames).split(/\r?\n/);
        this._domainNames = domainRows.map((domainRow) => domainRow.split(';').shift());
        return this._domainNames;
    }

    public get domainWithAuthcode(): {domain: string; authcode: string}[] {
        if ([undefined, null, ''].indexOf(this.inputText) >= 0) {
            return [];
        }
        const domainRows = StringCleaner.clean(this.inputText).as(CleanableStringType.DomainNames).split(/\r?\n/);

        return domainRows.map((domainRow) => {
            const result = /([^;]+);?(.+)?/i.exec(domainRow);
            if ([null, undefined].indexOf(result) < 0) {
                return { domain: result[1], authcode: result[2] };
            }
            return { domain: '', authcode: '' };
        });
    }

    public get showTldPriceBoxes(): boolean {
        return Array.isArray(this.showTldPrices)
            && this.showTldPrices.length > 0;
    }

    public validateDomainInput = (): void => {
        this.errorMessages = [];
        (this.domainNames || []).forEach((domainPart) => {
            const validationResult = this.domainNameValidator.validate(domainPart.trim());

            if (validationResult && validationResult.length > 0) {
                const errorList = validationResult
                    .map((validationText) => validationText.text)
                    .join(', ');

                this.errorMessages = this.errorMessages.concat(
                    { text: `${domainPart}: ${errorList}` }
                );
            }
        });
    };

    public quickCheckDomains = (): void => {
        this.validateDomainInput();
    };

    public checkDomains = (): void => {
        if (this.isSearchButtonDisabled) {
            this.stopLoading();
            return;
        }
        this.startLoading();

        void Promise.resolve()
            .then(
                () => {
                    // update rows with domain endings if necessary
                    if (this.rowsHaveNoDomainEndings().some(result => result.noTld)) {
                        if (this.endingsToUse?.length > 0) {
                            this.updateDomainNamesWith(this.endingsToUse);
                        } else {
                            this.updateDomainNamesWith(this.topDomainEndings);
                        }
                    }
                }
            )
            .then(
                () => {
                    // validate all domain names
                    // and push authcodes to authCodes array
                    this.validateDomainInput();
                    for (const row of this.domainWithAuthcode) {
                        if (row.authcode) {
                            const foundElementIndex = this.authCodes.findIndex(
                                (authCode) => authCode.domain === row.domain
                            );

                            if (foundElementIndex >= 0) {
                                this.authCodes[foundElementIndex] = {
                                    domain: row.domain,
                                    authCode: row.authcode
                                };
                            } else {
                                this.authCodes.push({
                                    domain: row.domain,
                                    authCode: row.authcode
                                });
                            }
                        }
                    }
                    if (this.domainSearch !== undefined) {
                        this.domainSearch = this.domainNames.join('\n');
                    }
                }
            )
            .then(
                () => {
                    // update domain info if changes were detected
                    if (!this.stopUpdate()) {
                        if (this.outerDomainCheck) {
                            return this.outerDomainCheck(this.inputText);
                        } else {
                            this.updateDomainInfo();
                        }
                    }
                }
            );
    };

    public updateDomainInfo = (): void => {
        this.domainInfo = [];
        void this.domainChecker.check(this.domainNames, this.accountId).then(
            (reply) => {
                const promises: PromiseLike<void | {tld: string; accountId: string}>[] = [];
                for (let i = 0; i < reply.length; i++) {
                    const domain = reply[i];
                    void this.$timeout(() => {
                        promises[i] = this.priceCache.listDomainPrices(domain.extension, this.accountId)
                            .then((ret) => {
                                const authCode = this.authCodes?.find((ac) => {
                                    return ac.domain === domain.domainNameUnicode;
                                });

                                if ([undefined, null].indexOf(authCode) < 0) {
                                    domain.authCode = authCode.authCode;
                                }

                                this.domainInfo.push({
                                    domain: domain,
                                    prices: ret
                                });
                            });
                        if (i === reply.length - 1) {
                            Promise.all(promises).finally(
                                () => {
                                    this.inputText = this.domainNames.join('\n');
                                    void this.$timeout(() => this.stopLoading(), 500);
                                }
                            );
                        }
                    }, 30);
                }
            }
        );
    };

    public toggleMultiple = (): void => {
        this.errorMessages = [];

        if (this.extended && this.domainNames[0] !== undefined) {
            this.inputText = this.domainNames[0];
        }

        this.extended = !this.extended;
    };

    public onDashboard = (): boolean => {
        return this.$state.current.name === 'dashboard';
    };

    public onPasteDomains = (pastedText: string): void => {
        this.textHasBeenPasted = true;
        if (this.extended === false) {
            if (/\r?\n/.test(pastedText)) {
                this.extended = true;
                this.inputText = pastedText;
            }
        }
        this.checkDomains();
        this.textHasBeenPasted = false;
    };

    private stopLoading = (): void => {
        this.loading = false;
    };

    private startLoading = (): void => {
        this.loading = true;
    };

    private rowsHaveNoDomainEndings = (): {
        noTld: boolean;
        domain: string;
        authcode: string;
    }[] => {
        return this.domainWithAuthcode
            .map(({ domain, authcode }) => {
                return {
                    noTld: !this.domainInfoHelper.hasValidTld(domain),
                    domain: domain,
                    authcode: authcode
                };
            });
    };

    private updateDomainNamesWith = (endings: string[]): void => {
        const newDomainNames = [];
        const allRows = this.rowsHaveNoDomainEndings();
        for (const domainName of allRows) {
            if (domainName.noTld) {
                for (const ending of endings) {
                    newDomainNames.push(`${domainName.domain}${ending}`);
                }
            } else {
                newDomainNames.push(`${domainName.domain}${domainName.authcode ? `;${domainName.authcode}` : ''}`);
            }
        }
        this.domainNames = newDomainNames;
    };

    private stopUpdate = (): boolean => {
        const hash = JSON.stringify([this.domainNames, this.authCodes, this.accountId]);

        if (this.lastDomainCheck === hash || this.errorMessages.length > 0 || this.isSearchButtonDisabled) {
            this.stopLoading();
            return true;
        }

        this.lastDomainCheck = hash;
        return false;
    };

    private _setPriceBoxes = (): void => {
        if (this.showTldPriceBoxes) {
            this.priceBoxes = [];
            for (const tld of this.showTldPrices) {
                this.priceBoxes.push({
                    tld: tld,
                    promotionalAvailable: false
                });
            }
        }
    };
}

export class MoleculePanelEditRowDomainMultiComponent implements ng.IComponentOptions {
    public bindings = {
        accountId: '=',
        disableMultiSearch: '<',
        domainInfo: '=',
        domainSearch: '=',
        endingsToUse: '<endings',
        extended: '<',
        loading: '=?loadingData',
        outerDomainCheck: '<?',
        smallView: '<?',
        showTldPrices: '<?'
    };

    public controller = MoleculePanelEditRowDomainMultiController;

    /* tslint:disable-next-line:max-line-length */
    public template = require('./panel-edit-row-domain-multi.html');
}
