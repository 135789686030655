import * as ng from 'angular';

export class OrganEditPanelSubAccountContactDataController implements ng.IController {
    public subAccountContactData: any;
    public panelRightSettings: any;
    public cancelCallbackFunction: () => void;
    public saveCallbackFunction: (arg0: any) => void;
}

export class OrganEditPanelSubAccountContactDataComponent implements ng.IComponentOptions {
    public bindings = {
        subAccountContactData: '=',
        panelRightSettings: '<',
        cancelCallbackFunction: '<',
        saveCallbackFunction: '<',
        allValidationsSuccessful: '='
    };
    public template = require('./sub-account-contact-data.html');
    public controller = OrganEditPanelSubAccountContactDataController;
}
