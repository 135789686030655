import { UiRights } from '@/configuration';
import * as ng from 'angular';
import { WizardConfirmationBase } from '../../general';
import {
    ApiErrorModel,
    AuthContextService,
    BillingRobotService,
    DatabaseRobotService,
    DnsJobModelService,
    ErrorMapperService,
    NavigationService,
    OrchestratorRobotService
} from './../../../../../services/';

export class DnsWizardConfirmation extends WizardConfirmationBase {
    public static $inject: string[] = WizardConfirmationBase.$inject.concat([
        'billingRobot',
        'databaseRobot',
        'dnsJobModel',
        'navigation'
    ]);

    constructor(
        protected $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        protected apiErrorModel: ApiErrorModel,
        protected errorMapper: ErrorMapperService,
        protected localInterval: ng.IIntervalService,
        protected orchestratorRobot: OrchestratorRobotService,
        private billingRobot: BillingRobotService,
        private databaseRobot: DatabaseRobotService,
        private dnsJobModel: DnsJobModelService,
        private navigation: NavigationService
    ) {
        super(
            $timeout,
            $translate,
            apiErrorModel,
            errorMapper,
            localInterval,
            orchestratorRobot
        );

        if (
            this.selectedProductFamily === 'ddns-hostnames'
            && !AuthContextService.isGrantedAll([UiRights.DNS_DDNSHOSTNAME_LIST, UiRights.DNS_DDNSCREDENTIALS_LIST])
        ) {
            this.isJobDependendWizard = false;
            this.nextSteps.push({
                iconName: 'location-arrow',
                linkText: this.$translate.instant('TR_130319-9c1524_TR'),
                route: 'dns.ddns.hostnames.overview',
                routeOptions: { reload: true },
                routeParams: {}
            });
        }

        if (this.selectedProductFamily === 'dns-zone' && !AuthContextService.isGranted(UiRights.DNS_ZONES_LIST)) {
            this.isJobDependendWizard = false;
            this.fillNextSteps = () => {
                this.nextSteps.push({
                    iconName: 'location-arrow',
                    linkText: this.$translate.instant('TR_100119-abd89e_TR'),
                    route: 'dns.dashboard',
                    routeOptions: { reload: true },
                    routeParams: {}
                });
            };
        }
    }

    public getJobByFilter = (filter, cacheTimeout, objectType?: string) => {
        switch (objectType) {
            case 'Zone':
            case 'DynamicDnsHostname':
            case 'DynamicDnsCredentials':
                return this.dnsJobModel.list(null, null, filter)
                    .then(this.normalizeResponseObject);

            default:
                return undefined;
        }
    };

    public callbackAfterFinishedRequests = () => {
        if (this.selectedProductFamily === 'dns-zone') {
            for (const createdObject of this.createRequests) {
                if (
                    createdObject.objectType === 'Zone'
                    && this.isJobFinished(createdObject)
                ) {
                    if (
                        createdObject.additionalFlags?.isRecreate
                        && typeof createdObject.result?.zoneConfig?.id === 'string'
                    ) {
                        this.navigation.go(
                            'dns.zones.id.edit',
                            { zoneId: createdObject.result.zoneConfig.id },
                            { reload: true }
                        );
                    }
                    this.nextSteps.push({
                        iconName: 'location-arrow',
                        linkText: this.$translate.instant('TR_100119-abd89e_TR'),
                        route: 'dns.zones.overview',
                        routeOptions: { reload: true },
                        routeParams: {}
                    });
                    break;
                }
            }
        } else if (this.selectedProductFamily === 'ddns-hostnames') {
            let apiResponse;
            if (this.createRequests[0].children?.length === 1) {
                apiResponse = this.createRequests[0].children[0].result;
            } else {
                apiResponse = this.createRequests[0].result;
            }
            if (apiResponse.id?.length > 0) {
                this.$timeout(() => {
                    this.nextSteps.push({
                        iconName: 'location-arrow',
                        linkText: this.$translate.instant('TR_051020-2e22d7_TR'),
                        route: 'dns.ddns.hostnames.id.edit',
                        routeOptions: { reload: true },
                        routeParams: { ddnsHostnameId: apiResponse.id }
                    });
                });
            }
        }
    };
}
