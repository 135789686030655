import * as ng from 'angular';
import { NavigationService } from '../../../../services';

export class MoleculeLinkRefreshController implements ng.IController {
    public static $inject: string[] = ['navigation'];

    public callbackOnClick: () => any;

    constructor(private navigation: NavigationService) {}

    public refresh = () => {
        if (this.callbackOnClick) {
            this.callbackOnClick();
            return;
        }
        this.navigation.reloadCurrentState(true);
    };
}

export class MoleculeLinkRefreshComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnClick: '<?'
    };
    public controller =  MoleculeLinkRefreshController;
    public template = require('./link-refresh.html');
}
