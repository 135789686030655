import * as ng from 'angular';
import { EditPanelRight } from '@/atomic-components';
import { AuthContextService } from '@/services';

export class OrganEditPanelContactDataController implements ng.IController {
    public static $inject: string[] = ['countryFilter'];

    public initialStatus: unknown;
    public accountAllowedAdvertisingMethods: string[];
    public accountName: string;
    public accountNameAddition: string;
    public accountStreet: string;
    public accountCity: string;
    public accountZipCode: string;
    public accountPhoneNumber: string;
    public accountFaxNumber: string;
    public accountCustomerNumber: string;
    public panelRightSettings: EditPanelRight;
    public cancelCallbackFunction: () => void;
    public saveCallbackFunction: () => void;
    public accountCountryModel: string;

    constructor(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        private countryFilter: any
    ) {}

    public get allowedAdvertisingMethods(): boolean {
        return this.accountAllowedAdvertisingMethods?.length > 0;
    }

    public set allowedAdvertisingMethods(value: boolean) {
        this.accountAllowedAdvertisingMethods = value ? ['email'] : null;
    }

    public get accountCountryName(): string {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call
        return this.countryFilter(this.accountCountryModel);
    }

    public get showNewsletterToggle(): boolean {
        return AuthContextService.isDirectCustomer;
    }
}

export class OrganEditPanelContactDataComponent implements ng.IComponentOptions {
    public bindings = {
        accountAllowedAdvertisingMethods: '=',
        accountName: '=',
        accountNameAddition: '=',
        accountStreet: '=',
        accountCity: '=',
        accountZipCode: '=',
        accountCountryModel: '=',
        accountPhoneNumber: '=',
        accountFaxNumber: '=',
        accountCustomerNumber: '@',
        panelRightSettings: '<',
        cancelCallbackFunction: '<',
        saveCallbackFunction: '<',
        initialStatus: '<'
    };
    public template = require('./contact-data.html');
    public controller = OrganEditPanelContactDataController;
}
