import * as ng from 'angular';
import { CronjobPathPrefixConst } from '../../../../../configuration';
import { ValidateCronjobParameter } from '../../../../../services';
import './cronjob-script.scss';

export class MoleculeFormCronjobScriptController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public scriptTmp: string;
    public parameters: any[];
    public callbackOnBlur: () => void;
    public validationInstructions;
    public validationErrorList;
    public selectedScriptPathPrefix;
    public pathItems = CronjobPathPrefixConst;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {
        this.validationInstructions = [
            {
                instructions: null,
                validator: new ValidateCronjobParameter(this.$translate)
            }
        ];
    }

    public get script() {
        return this.selectedScriptPathPrefix + this.scriptTmp;
    }

    public set script(value: string) {
        if ([undefined, null, ''].indexOf(value) >= 0) {
            this.selectedScriptPathPrefix = CronjobPathPrefixConst[0].value;
            this.scriptTmp = '';
            return;
        }

        const found = CronjobPathPrefixConst
        .some(
            (item) => {
                if (value.indexOf(item.value) >= 0) {
                    this.selectedScriptPathPrefix = item.value;
                    this.scriptTmp = value.replace(item.value, '');
                    return true;
                }

                return false;
            }
        );

        if (!found) {
            this.selectedScriptPathPrefix = CronjobPathPrefixConst[0].value;
            this.scriptTmp = value;
        }
    }

    public addNewParameter = () => {
        this.parameters.push({ parameter: '', tmpStatus: '', newStatus: 'new' });
    };

    public addButtonDisabled = () => {
        return !this.parameters.every((parameter) => parameter.parameter.length > 0);
    };

    public removeParameter = (index: number) => {
        this.parameters = this.parameters.filter((param, i) => index !== i);
    };
}

export class MoleculeFormCronjobScriptComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnBlur: '<',
        parameters: '=',
        script: '=',
        validationErrorList: '<'
    };
    public controller =  MoleculeFormCronjobScriptController;
    public template = require('./cronjob-script.html');
}
