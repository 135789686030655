import * as ng from 'angular';
import { InputPlaceholderConst } from '../../../../../configuration';

export class OrganNewSubAccountContactDataController {
    public static $inject: string[] = [];

    public newSubAccount: any;
    public InputPlaceholder = InputPlaceholderConst;

}

export class OrganNewSubAccountContactDataComponent implements ng.IComponentOptions {
    public bindings = {
        newSubAccount: '='
    };
    public template = require('./sub-account-contact-data.html');
    public controller = OrganNewSubAccountContactDataController;
}
