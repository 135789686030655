import * as ng from 'angular';
import { PanelHeaderData } from '../../../../atomic-components/molecules/panels';
import { UiRights } from '../../../../configuration';
import { AuthContextService } from '../../../../services';
import * as Types from '../../../../types';

export class TemplateNextcloudRestoreController {
    public static $inject: string[] = ['$state', '$translate'];

    public nextcloud: Types.ManagedApplicationApi.Nextcloud;
    public pageHeaderData: PanelHeaderData;
    public panelRight;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: this.$state.current.name.indexOf('storage-products') >= 0
                ? 'storage.storage-products.id.edit'
                : 'nextcloud.id.edit',
            backwardText: this.$translate.instant('TR_140119-abce37_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: {
                nextcloudId: this.nextcloud.id
            },
            panelIcon: 'adjust',
            panelTitle: this.nextcloud.name
        };

        this.panelRight = {
            editPanelButton: AuthContextService.isGrantedAny([
                UiRights.MANAGED_APPLICATION_NEXTCLOUD_RESTORE,
                UiRights.MANAGED_APPLICATION_NEXTCLOUD_DELETE
            ])
        };
    };
}

export class TemplateNextcloudRestoreComponent implements ng.IComponentOptions {
    public bindings = {
        nextcloud: '<'
    };
    public controller = TemplateNextcloudRestoreController;
    public template = require('./nextcloud-restore-template.html');
}
