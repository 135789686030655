import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';

export class TemplateCronjobNewController {
    public static $inject: string[] = ['$state', '$translate', 'authContext'];

    public modifier = '';
    public pageHeaderData: PanelHeaderData;

    public cronjob;

    public userPanelRight;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit = () => {
        const bundle = this.$state.$current.locals.globals.bundle;
        const webspace = this.$state.$current.locals.globals.webspace;
        const stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';

        let panelHeaderBackwardLink;
        let panelHeaderRouteParams;
        switch (this.$state.current.name.split('.')[0]) {
            case 'bundle':
                panelHeaderBackwardLink = 'bundle.id.cronjobs.overview';
                break;
            case 'managed-servers':
                panelHeaderBackwardLink = 'managed-servers.id.webspaces.id.cronjobs.overview';
                panelHeaderRouteParams = {
                    machineId: this.$state.$current.locals.globals.machine.id,
                    webspaceId: this.$state.$current.locals.globals.webspace.id
                };
                break;
            default:
                panelHeaderBackwardLink = 'webhosting.webspaces.id.cronjobs.overview';
                panelHeaderRouteParams = {webspaceId: webspace.id};
        }

        this.pageHeaderData = {
            backwardLink: panelHeaderBackwardLink,
            backwardText: this.$translate.instant('TR_140119-fff82f_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: panelHeaderRouteParams,
            panelIcon: 'calendar-alt',
            panelTitle: this.$translate.instant('TR_160119-2002b5_TR')
        };

        this.userPanelRight = {
            general: {
                editPanelButton: this.authContext.isGranted(UiRights.WEB_WEBSPACE_EDIT)
            }
        };
    };
}

export class TemplateCronjobNewComponent implements ng.IComponentOptions {
    public bindings = {
        cronjob: '<'
    };
    public controller = TemplateCronjobNewController;
    public controllerAs = '$TemplateCronjobNewCtrl';
    public template = require('./cronjob-new-template.html');
}
