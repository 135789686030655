import ng from 'angular';

export class MoleculePanelEditRowCountriesController implements ng.IController {
    public static $inject: string[] = [];

    public disabled: boolean;
    public model: any;
    public required: boolean;
}

export class MoleculePanelEditRowCountriesComponent implements ng.IComponentOptions {
    public transclude = {
        label: 'rowLabel'
    };
    public bindings = {
        disabled: '<',
        model: '=',
        required: '<?',
    };
    public controller = MoleculePanelEditRowCountriesController;
    public controllerAs = '$panelRow';
    public template = require('./panel-edit-row-dropdown-countries.html');
}
