import * as ng from 'angular';
import './panel-edit-row-show-all.scss';

export class MoleculePanelEditRowShowAllController implements ng.IController {
    public static $inject: string[] = [];

    public _toggleIsActive = false;
    public toggleIsActiveAtStart = false;
    public textToOpen: string;
    public textToClose: string;
    private callbackOnToggle;

    public get toggleIsActive() {
        return this._toggleIsActive;
    }

    public set toggleIsActive(active: boolean) {
        this._toggleIsActive = active;
    }

    public $onInit() {
        this.toggleIsActive = this.toggleIsActiveAtStart || false;
    }

    public toggle = () => {
        if (this.callbackOnToggle) {
            this.callbackOnToggle(!this.toggleIsActive);
        }
        this.toggleIsActive = !this.toggleIsActive;
    };

    public onKeyup = (event: any) => {
        if (event.keyCode === 13) { // Enter
            this.toggle();
        }
    };
}

export class MoleculePanelEditRowShowAllComponent implements ng.IComponentOptions {
    public transclude = true;

    public bindings = {
        callbackOnToggle: '<?',
        textToClose: '@',
        textToOpen: '@',
        toggleIsActiveAtStart: '<?',
        toggleIsActive: '=?toggleIsActive'
    };

    public controller = MoleculePanelEditRowShowAllController;
    // tslint:disable-next-line: max-line-length
    public template = require('./panel-edit-row-show-all.html');
}
