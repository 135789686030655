import ng from 'angular';

import { AuthContextService, DatabaseUserModelService } from '@/services';
import * as Types from '@/types';
import { DatabasePasswordValidationOptions } from '@/configuration/database/database';

export class MoleculePanelRowOrchestrationDatabaseAccessSettingsController {
    public static $inject: string[] = ['$timeout', '$translate', 'databaseUserModel'];

    public onlyNewAccessAvailable = false;
    public databaseUserAccess: Types.ViewTypes.DatabaseUserAccessObject;
    public accessTypeButtons: { value: Types.ViewTypes.UserAccessType; label: string }[];
    public isValid = false;
    public formIsReady = false;
    public accountId: string;
    public accessHeadings: string[] = [];
    public viewTypes = Types.ViewTypes;

    private callbackOnChange;

    public passwordValidationOptions = Object.assign({}, DatabasePasswordValidationOptions);

    constructor(
        private $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        private databaseUserModel: DatabaseUserModelService
    ) {}

    public $onInit = () => {
        this.formIsReady = false;
        this.isValid = false;

        if (this.databaseUserAccess === null) {
            this.databaseUserAccess = {
                accessType: Types.ViewTypes.UserAccessType.new,
                userId: undefined,
                name: '',
                password: '',
                comments: '',   // currently not supported by the API
                accessLevel: { // currently not supported by the API
                    read: false,
                    write: false,
                    schema: false
                }
            };
        }

        this.accessHeadings = [
            this.$translate.instant('TR_110119-76b4ba_TR'),
            this.$translate.instant('TR_100119-e937e9_TR')
        ];

        this.accessTypeButtons = [
            { value: Types.ViewTypes.UserAccessType.existing, label: this.$translate.instant('TR_110119-d043dc_TR') },
            { value: Types.ViewTypes.UserAccessType.new, label: this.$translate.instant('TR_110119-b61be5_TR') }
        ];

        this._checkForAvailabelAccesses();
    };

    get choosenAccessId(): string {
        return this.databaseUserAccess.userId;
    }

    set choosenAccessId(value: string) {
        this.databaseUserAccess.userId = value;
    }

    public userAccessTypeChangeCallback = (accessType: Types.ViewTypes.UserAccessType) => {
        if (accessType === Types.ViewTypes.UserAccessType.new) {
            this.databaseUserAccess.userId = undefined;
        } else {
            this.databaseUserAccess.name = '';
            this.databaseUserAccess.password = '';
        }
        this.validateForm();
    };

    public validateForm = () => {
        this.$timeout( () => {
            this.isValid = [undefined, null, ''].indexOf(this.databaseUserAccess.userId) < 0
                || (
                    [undefined, null, ''].indexOf(this.databaseUserAccess.name) < 0
                    && [undefined, null, ''].indexOf(this.databaseUserAccess.password) < 0
                );

            if ([undefined, null, ''].indexOf(this.callbackOnChange)) {
                this.callbackOnChange();
            }
        });
    };

    public listDatabaseUsers = (
        limit?: number,
        page?: number,
        filters?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions,
        timeout?: number
    ) => {
        const filterObject: any = {
            subFilter: [{
                field: 'accountId',
                value: AuthContextService.account.id
            }],
            subFilterConnective: 'AND'
        };
        if ([undefined, null].indexOf(filters) >= 0) {
            filterObject.subFilter.push({
                field: 'userName',
                value: '*'
            });
        } else {
            filterObject.subFilter.push(filters);
        }
        return this.databaseUserModel.list(limit, page, filterObject, sort, timeout);
    };

    private _checkForAvailabelAccesses = () => {
        this.databaseUserModel.list(null, null, { field: 'accountId', value: this.accountId }, null, null)
            .then((accesses) => {
                if (accesses.data.length > 0) {
                    this.onlyNewAccessAvailable = false;
                    this.databaseUserAccess.accessType = Types.ViewTypes.UserAccessType.existing;
                } else {
                    this.onlyNewAccessAvailable = true;
                    this.databaseUserAccess.accessType = Types.ViewTypes.UserAccessType.new;
                }
                this.formIsReady = true;
            });
    };
}

export class MoleculePanelRowOrchestrationDatabaseAccessSettingsComponent implements ng.IComponentOptions {
    public bindings = {
        accountId: '<',
        callbackOnChange: '<?',
        databaseUserAccess: '=',
        isValid: '='
    };

    public template = require('./orchestration-database-access-settings.html');
    public controller = MoleculePanelRowOrchestrationDatabaseAccessSettingsController;
}
