import { ModelHelper } from '../model-helper';
import { RequestStatus} from '../rpc-client';
import { WebhostingRobotService } from './robot';

import * as ng from 'angular';
import * as q from 'q';
import * as Types from '../../types';

export class WebhostingUserModelService {
    public static $inject: string[] = ['webhostingRobot', '$rootScope'];

    constructor(private webhostingRobot: WebhostingRobotService, private $rootScope: ng.IRootScopeService) {}

    public list = (limit?, page?, filters?, sort?, owner?): PromiseLike<Types.ViewTypes.ApiListResponse> => {
        sort = sort || {
            field: 'userName',
            order: 'ASC'
        };
        page = page || 1;

        return this.webhostingRobot.listUsers(filters, limit, page, sort, owner)
        .then(ModelHelper.returnListResponse);
    };

    public listWithoutPagination = (limit?, page?, filters?, sort?, owner?) => {
        sort = sort || {
            field: 'userName',
            order: 'ASC'
        };
        page = page || 1;

        return this.webhostingRobot.listUsersWithoutPagination(filters, limit, page, sort, owner)
        .then(ModelHelper.returnListResponse);
    };

    public findOne: (id: string) => q.IPromise<any>
    = (id) => {
        return this.webhostingRobot.listUsersWithoutPagination({field: 'userId', value: id}, 1, 1)
        .then(ModelHelper.returnFindOneResponse);
    };

    public findOneById: (id: string) => q.IPromise<any>
    = (id) => {
        return this.webhostingRobot.listUsersWithoutPagination({field: 'userId', value: id}, 1, 1)
        .then(ModelHelper.returnFindOneResponse);
    };

    public findById: (ids: string[]) => q.IPromise<any>
    = (ids) => {
        const filter = {subFilterConnective: 'OR', subFilter: ids.map(this.idFilter)};
        return this.webhostingRobot.listUsersWithoutPagination(filter, 0, 1)
        .then(ModelHelper.returnFindResponse);
    };

    public createUser: (
        user: Types.WebhostingApi.User,
        password: string,
        // owner is deprecated: set accountId in the user object!
        owner?: string
    ) => q.IPromise<Types.WebhostingApi.User> = (user, password, owner?) => {
        return this.webhostingRobot.createUser(user, password, owner)
            .then(this.returnResponse)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public updateUser: (user: Types.WebhostingApi.User, password: string) => q.IPromise<Types.WebhostingApi.User>
    = (user, password) => {
        return this.webhostingRobot.updateUser(user, password)
        .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public deleteUsers: (users: Types.WebhostingApi.User[]) => q.IPromise<any>
    = (users) => {
        const promises = users.map(this.deleteOne);
        return q.all(promises)
        .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    private refresh = (status: RequestStatus = RequestStatus.SUCCESSFUL) => (result) => {
        this.$rootScope.$broadcast('webhosting.user.refresh');
        if (status === RequestStatus.SUCCESSFUL) {
            return result;
        } else {
            return q.reject(result);
        }
    };

    private idFilter = (id: string) => {
        return {field: 'userId', value: id};
    };

    private deleteOne = (user: Types.WebhostingApi.User) => {
        return this.webhostingRobot.deleteUser(user.id);
    };

    private returnResponse = (result) => {
        return result.response;
    };
}
