/* eslint-disable */

import ng from 'angular';
import { ApiErrorModel, ProductHelperService, SslOrderModelService, SslRobotService } from '@/services';
import { buttonInterface } from '@/atomic-components/molecules/panels/panel-rows/panel-edit-row-radio/panel-edit-row-radio';
import { CsrDecoderService } from '@/services/ssl/csr-decoder';
import { FormDropDownItems } from '@/atomic-components/molecules/forms/form-elements/drop-down/drop-down';
import { VerificationType } from './verification-types';
import { ViewTypes } from '@/types/view-types';
import { PriceHelperService } from '@/services/';
import { ProductSpecificationsService } from '@/services/products/product-specifications-service';
import { TemplateContactDetailsController } from '@/components';

export interface CertificateEditHostsTableHeader {
    columnName: string;
}

export interface CertificateEditHostsTableColumn {
    value?: any;
}

export enum ErrorClass {
    FATAL = 1,
    OPTIONAL
}

export interface RowDescriptor {
    rowType: string;
    rowData: CertificateEditHostsTableRow;
    errorText?: string;
    errorClass?: ErrorClass;
}

export interface CertificateCreateHintsResponse {
    errors: any;
    metadata: {
        clientTransactionId: string;
        serverTransactionId: string;
    };
    response: ViewTypes.CertificateCreateHintsResponsePayload;
    status: string;
    warnings: any;
}

export class CertificateEditHostsTableRow {

    /* A row represents a single hostname. Associated with the
       hostname is administrative data: Is the hostname deleted?
       Which verification method is chosen? Was it provided by
       the user via pasteing it in?
    */

    name: ViewTypes.CertificateCreateHintsName;
    deleted?: boolean; // deleted for "deletion"!
    verificationModel?: VerificationType;
    hostname?: string;
    autoVerifiable?: boolean;
    inEditMode?: boolean;
    showValidationHints?: boolean = false;
    descriptor?: RowDescriptor;

    public constructor(name: ViewTypes.CertificateCreateHintsName,
        deleted: boolean,
        verificationModel: VerificationType,
        hostname?: string) {
        this.name = name;
        if ([null, undefined].indexOf(name) == -1) {
            this.hostname = name.nameUnicode;
            if (name.autoValidationErrors.length == 0) {
                this.verificationModel = VerificationType.AUTOMATIC;
                this.autoVerifiable = true;
            } else {
                this.verificationModel = VerificationType.MANUAL;
                this.autoVerifiable = false;
            }
        } else { /* TODO: check if this is dead code */
            this.name = {
                approverList: [],
                autoValidationErrors: [],
                coveredBy: '',
                nameAce: hostname,
                nameUnicode: hostname,
                optional: true,
                productCode: '',
                type: ''
            } as ViewTypes.CertificateCreateHintsName;
        }
        this.inEditMode = false;
        this.deleted = deleted;
        this.verificationModel = verificationModel;
        if (typeof (hostname) !== 'undefined') {
            this.hostname = hostname;
        } else {
            this.hostname = null;
        }

    }

    public static getEmptyRow(): CertificateEditHostsTableRow {
        return {
            name: null,
            deleted: false,
            verificationModel: VerificationType.AUTOMATIC,
            hostname: null,
            autoVerifiable: true,
            inEditMode: false,
            showValidationHints: false,
            descriptor: null,
        } as CertificateEditHostsTableRow;
    }


    public updateName(arg0: any): void {
        const row = arg0[0] as CertificateEditHostsTableRow;
        const callback: () => void = arg0[1];
        if ((row as any).value === undefined) {
            /* This callback is called multiple times, dont know why
               If it is called with arg0[1] having a value property
               the call is not from me... */
            row.name.nameAce = row.name.nameUnicode;
            row.hostname = row.name.nameUnicode;
            row.inEditMode = false;
            callback();
        }
    }

    public autoVerifieable(): boolean {
        return this.verificationModel == VerificationType.AUTOMATIC;
    }

    public hasDifferentNames(): boolean {
        if (this.name?.nameAce && this.name?.nameUnicode) {
            return this.name?.nameAce != this.name?.nameUnicode;
        }
        else {
            return false;
        }
    }
}

export class CertificateEditHostsTable {
    public headers: CertificateEditHostsTableHeader[];
    public rows: CertificateEditHostsTableRow[];
    public deletedHostnames: string[] = [];

    public constructor() {
        this.headers = [];
        this.rows = [];
    }

    /* remove all rows */
    public reset() {
        this.rows.length = 0;
    }

    public addRowFromBackendResponseName(name: ViewTypes.CertificateCreateHintsName): boolean {
        const hostname = name.nameUnicode;
        const deleted = this.deletedHostnames.indexOf(hostname) != -1;
        const newRow = new CertificateEditHostsTableRow(name, deleted, VerificationType.AUTOMATIC);
        let responseHasNameErrors = false;
        newRow.descriptor = {
            rowType: 'name',
            rowData: null
        } as RowDescriptor;
        this.rows.push(newRow);
        if (name && name.nameErrors && name.nameErrors.length > 0)
            for (const nameError of name.nameErrors) {
                const nameErrorRow: CertificateEditHostsTableRow = CertificateEditHostsTableRow.getEmptyRow();
                nameErrorRow.showValidationHints = true;
                nameErrorRow.descriptor = {
                    rowType: 'nameError',
                    rowData: newRow,
                    errorText: nameError.text,
                    errorClass: ErrorClass.FATAL
                } as RowDescriptor;
                this.rows.push(nameErrorRow);
                responseHasNameErrors = true;
            }
        if (name && name.autoValidationErrors && name.autoValidationErrors.length > 0)
            for (const autoValidationError of name.autoValidationErrors) {
                const validationRow = CertificateEditHostsTableRow.getEmptyRow();
                validationRow.descriptor = {
                    rowType: 'autoValidationError',
                    rowData: newRow,
                    errorText: autoValidationError.text,
                    errorClass: ErrorClass.OPTIONAL
                } as RowDescriptor;
                this.rows.push(validationRow);
            }

        return responseHasNameErrors;
    }

    public getSelectedHostnames(): string[] {
        const result: string[] = this.rows
            .filter(row => !row.deleted && row.descriptor.rowType == 'name')
            .map(row => row.name.nameUnicode);
        return result;
    }

    public findRowByName(name: string): CertificateEditHostsTableRow {
        const result: CertificateEditHostsTableRow =
            this.rows.find( row => row.name && row.name.nameUnicode === name);
        return result;
    }
}

export class OrganSslWizardProductConfigCertificateRequestController {
    public static $inject: string[] = [
        'productSpecs',
        'apiErrorModel',
        'csrDecoder',
        'sslOrderModel',
        'sslRobot',
        '$timeout',
        '$translate',
        '$scope',
        'priceHelper'
    ];

    private _showEmailOriginatedFromDomainHint = false;
    private _csrSyntaxError?: boolean = false;

    public approverEmailAddresses: FormDropDownItems[] = [];
    public automaticVerificationName = 'Automatisch';
    public _autoValidationCapable = false;
    public _backendResponse: ViewTypes.CertificateCreateHintsResponsePayload = null;
    public errorCsrDomainNonWildcardExpected: boolean;
    public csrDomainHasToBeWildcard: boolean = false;
    public domainsWithNameError: string[] = [];
    public hostnamesFromCsr: string[] = [];
    public isAdditionalHostnameSansAllowed = false;
    public isWildcardsAllowed = false;
    public isWwwOnTldIncluded = false;
    public loading = false;
    public maxSans: number = 0;
    public manualVerificationName = 'Manuell';
    public metaData: ViewTypes.ProductConfigSslObject;
    public pastebinDefaultText = '';
    public pastebin = this.pastebinDefaultText;
    public sslProductCode: string;
    public sslValidationObject: ViewTypes.SslValidationObject;
    public table: CertificateEditHostsTable = new CertificateEditHostsTable();
    public _totalPrice: number = 0;
    public validationMethodList: buttonInterface[] = [];
    public advancedValidationMethodList: string[] = [];
    public productSpecifications: any;
    public tmpHostnamesFromTextarea: string[] = [];

    public constructor(
        private productSpecs: ProductSpecificationsService,
        private apiErrorModel: ApiErrorModel,
        private csrDecoder: CsrDecoderService,
        private sslOrderModel: SslOrderModelService,
        private sslRobot: SslRobotService,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private $scope: ng.IScope,
        private priceHelper: PriceHelperService
    ) {
        const columnTitleHostname: string = this.$translate.instant('TR_260723-b71f2e_TR');
        this.addHeader(columnTitleHostname);
        const columnTitleValidation: string = this.$translate.instant('TR_260723-8d08b1_TR');
        this.addHeader(columnTitleValidation);
        const columnTitlePrice: string = this.$translate.instant('TR_260723-7e883e_TR');
        this.addHeader(columnTitlePrice);
        const columnTitleButtons = '';
        this.addHeader(columnTitleButtons);
    }

    public $onInit(): void {
        this.validationMethodList = [
            {
                label: this.$translate.instant('TR_280319-e54f8d_TR'),
                value: 'email'
            },
            {
                label: this.$translate.instant('TR_040324-7c19cb_TR'),
                value: 'http'
            },
            {
                label: this.$translate.instant('TR_040324-f9d361_TR'),
                value: 'dns'
            }
        ];
        this.advancedValidationMethodList = [
            this.$translate.instant('TR_040324-7c19cb_TR'),
            this.$translate.instant('TR_040324-f9d361_TR'),
        ];
        if (this.$scope && this.$scope.$on) {
            this.$scope.$on('productSpecificationChangedBroadcast', (evt: ng.IAngularEvent, data: any) => {
                this.metaData.settings.productSpecification = ng.copy(data);
                this.updateProductSpecs();
            });
        }
        this.pastebinDefaultText = this.$translate.instant('TR_260723-e00896_TR');
    }

    public $onChanges(changes: ng.IOnChangesObject): void {
        if (changes.sslProductCode !== undefined) {
            this.sslValidationObject = {
                csrRequestEncoded: null,
                apiResponse: {} as ViewTypes.CsrDecodeApiResponse,
                isWildcard: false
            };
            this._backendResponse = null;
            this.metaData.additionalDomains.length = 0;
            this.metaData.domainsByProductCode = [];
        }
    }

    public updateProductSpecs() {
        this.setCsrSyntaxErrorVariable();
        const specs = this.metaData.settings.productSpecification;
        this.maxSans = parseInt(this.productSpecs.getProductSpecification(specs, 'maxSans', '0'));
        this.isAdditionalHostnameSansAllowed = (this.maxSans - this.hostnamesFromCsr.length) > 0;
        this.isWildcardsAllowed = this.productSpecs.isWildcardsAllowed(specs);
        this.isWwwOnTldIncluded = this.productSpecs.isWwwOnTldIncluded(specs);
        this.csrDomainHasToBeWildcard = this.productSpecs.isOnlyWildcardsAllowed(specs);
        this.checkErrorCsrDomainWildcardStatus();
    }

    public setCsrSyntaxErrorVariable() {
        if ([undefined, null, ''].indexOf(this.sslValidationObject.csrRequestEncoded) < 0 && !this.isCertificateSyntax(this.sslValidationObject.csrRequestEncoded)) {
            this._csrSyntaxError = true;
        } else {
            this._csrSyntaxError = false;
        }
    }

    public checkErrorCsrDomainWildcardStatus = (): void => {
        /* errorCsrDomainnonwildcardexpected */
        this.errorCsrDomainNonWildcardExpected = false;
        if (
            ProductHelperService.wrapSpecificationItems(
                this.metaData.settings?.productSpecification
            ).wildcardSupport?.isFalse
        ) {
            this.errorCsrDomainNonWildcardExpected = this.sslValidationObject.isWildcard === true;
        }
        /* csrDomainHasToBeWildcard */
    };

    public get showApprovalEmailAddress(): boolean {
        return this.metaData.settings.validationMethod === 'email'
            && !this.isProductFamilyEmailCertificate;
    }

    public get showValidatedCountry(): boolean {
        return this.sslValidationObject.apiResponse?.csr?.country?.length > 0
            && !this.isProductFamilyEmailCertificate;
    }

    public get showValidatedState(): boolean {
        return this.sslValidationObject.apiResponse?.csr?.state?.length > 0
            && !this.isProductFamilyEmailCertificate;
    }

    public get showValidatedLocalty(): boolean {
        return this.sslValidationObject.apiResponse?.csr?.locality?.length > 0
            && !this.isProductFamilyEmailCertificate;
    }

    public get showValidatedOrganizationUnit(): boolean {
        return this.sslValidationObject.apiResponse?.csr?.organizationUnit?.length > 0
            && !this.isProductFamilyEmailCertificate;
    }

    public get showValidatedOrganization(): boolean {
        return this.sslValidationObject.apiResponse?.csr?.organization?.length > 0
            && !this.isProductFamilyEmailCertificate;
    }

    public get showValidatedEmail(): boolean {
        return this.sslValidationObject.apiResponse?.csr?.emailAddress?.length > 0
            && !this.isProductFamilyEmailCertificate;
    }

    public get isProductFamilyEmailCertificate(): boolean {
        return this.metaData.sslProductFamily === 'email-certificates';
    }

    public get showEmailOriginatedFromDomainHint(): boolean {
        return this._showEmailOriginatedFromDomainHint;
    }

    /* One liner to check if none of the names has AutovalidationErrors */
    private namesHaveAutoValidationErrors( names:ViewTypes.CertificateCreateHintsName[] ): boolean {
        return !(names.some((name:ViewTypes.CertificateCreateHintsName)=>name.autoValidationErrors?.length > 0));
    }

    public set autoValidationCapable(value: boolean) {
        this._autoValidationCapable = value;
        const autoValidationOption: buttonInterface =  {
            label: this.$translate.instant('TR_280319-04edd4_TR'),
            value: 'auto'
        };
        this.validationMethodList = this.validationMethodList.filter((validationMethod) => validationMethod.value !== 'auto');

        if (this._autoValidationCapable) {
            this.validationMethodList = [autoValidationOption].concat(this.validationMethodList);
            this.validationMethodChanged('auto');
        } else if (this.metaData.settings.validationMethod === 'auto') {
            this.validationMethodChanged('email');
        }
    }

    public get autoValidationCapable(): boolean {
        return this._autoValidationCapable;
    }

    public checkCsr = async (): Promise<void> => {
        this.loading = true;
        this.table.reset();
        this.table.deletedHostnames.length = 0;
        this.domainsWithNameError.length = 0;
        this.metaData.hasErrors = false;

        if (this.isCertificateSyntax(this.sslValidationObject.csrRequestEncoded)) {
            await this.csrDecoder
                .decode(this.sslValidationObject.csrRequestEncoded)
                .then((result: ViewTypes.CsrDecodeApiResponse) => {
                    this.sslValidationObject.apiResponse = result;
                    this.sslValidationObject.isWildcard = result.csr?.domainName.indexOf('*.') === 0;
                    const domain = result.csr?.domainName.replace('*.', '');
                    this.sslRobot.certificateCreateHints(this.sslValidationObject.csrRequestEncoded, this.metaData.account.id, this.sslProductCode, [])
                        .then((response) => {
                            this.autoValidationCapable = this.namesHaveAutoValidationErrors(response.response.names as ViewTypes.CertificateCreateHintsName[]);
                            if (!this.isProductFamilyEmailCertificate) {
                                this.updateApproverEmails(domain);
                            } else {
                                this.showEmailOriginatedFromDomainHintCallback();
                            }
                            this.backendResponse = response.response;
                            this.hostnamesFromCsr = this.backendResponse.names.map((name: ViewTypes.CertificateCreateHintsName) => name.nameUnicode);
                            this.metaData.csrDomains = [...this.hostnamesFromCsr];
                            this.updateProductSpecs();
                            return response;
                        });
                }).catch(() => {
                    this.validationMethodChanged('email');
                });
            this.metaData.settings.emailCheckActive = true;
        } else {
            this.setCsrSyntaxErrorVariable();
            this.sslValidationObject = this.sslValidationObject || {
                csrRequestEncoded: null,
                apiResponse: {} as ViewTypes.CsrDecodeApiResponse,
                isWildcard: false
            };
            this.sslValidationObject.apiResponse = {} as ViewTypes.CsrDecodeApiResponse;
            this.sslValidationObject.isWildcard = false;
            this.errorCsrDomainNonWildcardExpected = false;
            this.csrDomainHasToBeWildcard = false;
            this.approverEmailAddresses = [];
            if (!this.isProductFamilyEmailCertificate) {
                this.metaData.settings.email = '';
                this.approverEmailAddresses = [];
            }
            this._backendResponse = null;
            this.metaData.additionalDomains.length = 0;
            this.metaData.domainsByProductCode = [];
        }

        this.loading = false;
    };

    public isCertificateSyntax = (csrRequestEncoded: string): boolean => {
        return (/-----BEGIN\s(NEW\s)?CERTIFICATE\sREQUEST-----/.test(csrRequestEncoded)
            && /-----END\s(NEW\s)?CERTIFICATE\sREQUEST-----/.test(csrRequestEncoded));
    };

    public validationMethodChanged = (selectedMethod: 'email' | 'auto'): void => {
        this.metaData.settings.validationMethod = selectedMethod;
    };

    public showEmailOriginatedFromDomainHintCallback = (): void => {
        this._showEmailOriginatedFromDomainHint = false;
        void this.$timeout(() => {
            const emailCommonName = this.metaData.settings.email.substring(this.metaData.settings.email.indexOf('@') + 1);
            this._showEmailOriginatedFromDomainHint = this.sslValidationObject?.apiResponse?.csr?.domainName && ![null, undefined, ''].includes(this.metaData.settings.email)
                ? this.sslValidationObject.apiResponse.csr.domainName !== emailCommonName
                : false;
        });
    };

    private updateApproverEmails = async (domain: string): Promise<void> => {
        await this.sslOrderModel.getDomainApproverList(domain)
            .then((domainApproverList) => domainApproverList, () => [])
            .then((addresses: string[]) => {
                this.metaData.settings.emailCheckActive = false;
                this.approverEmailAddresses = addresses.map((address) => ({ value: address, name: address }));
                const hostmasterAddresses = addresses
                    .filter((address) => address.indexOf('hostmaster@') === 0)
                    .sort((a, b) => a.length - b.length);
                if (hostmasterAddresses.length > 0) {
                    this.metaData.settings.email = hostmasterAddresses[0];
                } else {
                    this.metaData.settings.email = addresses[0];
                }
            });
    };

    public isDefined(param: any): boolean {
        return [null, undefined].indexOf(param) != -1;
    }

    public addHeader(header: string) {
        const newHeader: CertificateEditHostsTableHeader = {
            columnName: header
        };

        this.table.headers.push(newHeader);
    }

    public get backendResponse() {
        return this._backendResponse;
    }

    /* rebuild the hostnames-table with information from backend */
    public set backendResponse(response: ViewTypes.CertificateCreateHintsResponsePayload) {
        this._backendResponse = response;
        this.table.reset();
        this._totalPrice = 0;
        this.domainsWithNameError.length = 0;
        this.metaData.hasErrors = false;

        if (response && response.names) {
            response.names.forEach((name: ViewTypes.CertificateCreateHintsName) => {
                const hasErrors: boolean = this.table.addRowFromBackendResponseName(name);
                if (hasErrors) {
                    this.domainsWithNameError.push(name.nameUnicode);
                    this.metaData.hasErrors = true;
                }
            });
            this.setAdditionalHostnames(this.metaData, response.names);
            this.metaData.billingInfos = response.billingInfos;

            this.table.deletedHostnames.forEach( name => {

                const isNotInTable = this.table.findRowByName( name ) == undefined;
                if (isNotInTable) {
                    const newRow = CertificateEditHostsTableRow.getEmptyRow();
                    newRow.deleted = true;
                    newRow.name = {
                        approverList: [],
                        autoValidationError: [],
                        coveredBy: "",
                        nameAce: name,
                        nameUnicode: name,
                        optional: true,
                        productCode: "",
                        type: "undefined",
                        nameErrors: []
                    } as ViewTypes.CertificateCreateHintsName;
                    newRow.descriptor = {
                        rowType: "name",
                        rowData: null
                    } as RowDescriptor;
                    this.table.rows.push( newRow );
                }


            })
        }

    }

    public toggleRowEditable = (arg0: any): void => {
        /* const _ctrl = <OrganSslWizardProductConfigCertificateRequestController> arg0[0]; */
        const row = arg0[1] as CertificateEditHostsTableRow;
        row.inEditMode = !row.inEditMode;
    };

    public markAsDeleted(args: any) {
        const _ctrl: OrganSslWizardProductConfigCertificateRequestController = args[0];
        const row: CertificateEditHostsTableRow = args[1];
        const rowHostname = row.name.nameUnicode;
        if (row.name.optional) {
            const deletedHostnames = _ctrl.table.deletedHostnames;
            let indexOfHostname = -1;
            const domainNames = _ctrl.metaData.additionalDomains;
            if (row.deleted) {
                /* host/domain-name is already deleted. Undelete it. */
                row.deleted = false;
                indexOfHostname = deletedHostnames.indexOf(rowHostname);
                if (indexOfHostname !== -1) {
                    deletedHostnames.splice(indexOfHostname, 1);
                    domainNames.push(rowHostname);
                }
            } else {
                if (deletedHostnames.indexOf(rowHostname) === -1) {
                    deletedHostnames.push(rowHostname);
                    indexOfHostname = _ctrl.metaData.additionalDomains.indexOf(rowHostname);
                    if (indexOfHostname !== -1) {
                        domainNames.splice(indexOfHostname, 1);
                    }
                }
                row.deleted = true;
            }
            _ctrl.queryBackendAfterChange();
        }
    }

    public getSelectedHostnamesFromTable(): string[] {
        return this.table.getSelectedHostnames();
    }

    /* backend interaction with input from textarea */
    public updateAdditionalHostnames = (): void => {

        const textarea: string = this.pastebin;

        const maybeHostname = (hostname: string): boolean => {
            const hasPoint = hostname.indexOf('.') > -1;
            const hasSpace = hostname.indexOf(' ') > -1;
            const hasSlash = hostname.indexOf('/') > -1;
            const isWildcard = hostname.startsWith('*.');
            return hasPoint && !hasSpace && !hasSlash
                && (!isWildcard || this.isWildcardsAllowed);
        };

        const preprocess = (candidate: string): string => {
            let result = candidate;
            if (this.isWwwOnTldIncluded) {
                if (candidate.startsWith('www.')) {
                    result = candidate.substring(4);
                }
            }
            return result;
        };

        if (textarea && textarea.split) {

            const hostnamesFromTable: string[] = this.getSelectedHostnamesFromTable();
            const duplicateEntries: string[] = [];
            let tmp = textarea.split('\n');
            const tmp2: string[] = [];
            const candidateHostnames: string[] = [];

            tmp.forEach( (candidate) => {
                if (candidate.indexOf(',') != -1) {
                    const snippets = candidate.split(',');
                    snippets.forEach(s => tmp2.push(s));
                } else {
                    tmp2.push(candidate);
                }
            });
            tmp = tmp2;

            tmp.forEach( (candidate) => {
                const trimmedCandidate = candidate.trim();
                const preprocessedAndTrimmedCandidate = preprocess(trimmedCandidate);
                candidate = preprocessedAndTrimmedCandidate;
                // check if candidate is a duplicate
                if (candidateHostnames.indexOf(candidate) == -1) {
                    candidateHostnames.push(candidate);
                } else {
                    duplicateEntries.push(candidate);
                }
            });


            const pretestValid = candidateHostnames.filter(c => maybeHostname(c));
            const pretestInvalid = candidateHostnames.filter(c => !maybeHostname(c));

            this.pastebin = [...pretestInvalid, ...duplicateEntries].join('\r\n');

            if (pretestValid && pretestValid.length && pretestValid.length > 0) {
                this.tmpHostnamesFromTextarea = [...pretestValid];
            }

            this.tmpHostnamesFromTextarea.forEach( name => {
                const row = this.table.findRowByName(name);
                if (row != undefined) {
                    row.deleted = false;
                }

                const indexOfName = this.table.deletedHostnames.indexOf(name);
                if (indexOfName >= 0) {
                    this.table.deletedHostnames.splice(indexOfName, 1);
                }
            });

            this.queryBackendAfterChange();
        }
    };

    /* backend interaction */
    public queryBackendAfterChange() {
        const hostnamesFromTable: string[] = this.getSelectedHostnamesFromTable();
        let toBeProcessedHostnames: any[];
        if (this.tmpHostnamesFromTextarea.length > 0) {
            toBeProcessedHostnames = [...hostnamesFromTable, ...this.tmpHostnamesFromTextarea];
            this.tmpHostnamesFromTextarea.length = 0;
        } else {
            toBeProcessedHostnames = [...hostnamesFromTable];
        }

        this.sslRobot.certificateCreateHints(this.sslValidationObject.csrRequestEncoded, this.metaData.account.id, this.sslProductCode, toBeProcessedHostnames)
            .then((response: any) => {
                /* side-effect-alert: backendResponse hat einen set-Handler */
                this.backendResponse = response.response;
                const csr = this.sslValidationObject.apiResponse.csr;
                const domain = csr?.domainName.replace('*.', '');
                this.autoValidationCapable = this.namesHaveAutoValidationErrors(response.response.names);
                if (!this.isProductFamilyEmailCertificate) {
                    this.updateApproverEmails(domain);
                } else {
                    this.showEmailOriginatedFromDomainHintCallback();
                }
                this.backendResponse = response.response;
                this.updateProductSpecs();
                this.setAdditionalHostnames(this.metaData, response.response.names);
                return response;
            }, () => {
                this.validationMethodChanged('email');
            });
    }

    private setAdditionalHostnames(metaData: ViewTypes.ProductConfigSslObject, names: ViewTypes.CertificateCreateHintsName[]): void {
        metaData.additionalDomains.length = 0;
        metaData.domainsByProductCode = [];
        for (let i = 0; i < names.length; i++) {
            const name: ViewTypes.CertificateCreateHintsName = names[i];
            const isDeleted = this.table.deletedHostnames.indexOf(name.nameUnicode) !== -1;
            const hasProductCode = [null, undefined].indexOf(name.productCode) < 0;
            const alreadyStored = metaData.additionalDomains.find( dom => dom === name.nameUnicode ) !== undefined;

            if ( !isDeleted && !alreadyStored) {
                metaData.additionalDomains.push(name.nameUnicode);
            }

            const productCode = name.productCode;
            const nameUnicode = name.nameUnicode;
            const codeToDomainMap = metaData.domainsByProductCode;
            if (hasProductCode) {
                if (codeToDomainMap[productCode] == undefined) {
                    codeToDomainMap[productCode] = [];
                }
                codeToDomainMap[productCode].push( nameUnicode );
            }
        }
    }

    public rowIsMarkedAsDeleted(rowData: CertificateEditHostsTableRow): boolean {
        let result: boolean = null;
        result = this.table.deletedHostnames.indexOf(rowData.name.nameUnicode) != -1;
        return result;
    }

    public toggleValidationHints(row: CertificateEditHostsTableRow): void {
        row.showValidationHints = !row.showValidationHints;
    }

    public addNewPrice = (price: number): void => {
        this._totalPrice += price;
    }

    public get totalPrice(): number {
        return this._totalPrice;
    }
}

export class OrganSslWizardProductConfigCertificateRequestComponent {
    public bindings = {
        sslValidationObject: '=',
        sslProductCode: '<',
        metaData: '<'
    };
    public template = require('./config-certificate-request.html');
    public controller = OrganSslWizardProductConfigCertificateRequestController;
}
