import ng from 'angular';

import { UiRights } from '@/configuration';
import { AuthContextService, WebspaceModelService } from '@/services';

// eslint-disable-next-line no-shadow
export enum DomainTypes {
    REGISTER, // Domain
    EXTERNAL, // Domain configuration (vhost)
    SUBDOMAIN // Subdomain (vhost)
}

export interface DomainRegistrationType {
    description: string;
    name: string;
    value: DomainTypes;
}

export class OrganCreateDomainTypeSelectionController implements ng.IController {
    public static $inject: string[] = ['webspaceModel', '$translate'];

    public availableTypes: DomainRegistrationType[] = [];
    public selectedDomainType: DomainTypes = DomainTypes.REGISTER;

    private _hasDomainCreateRights: boolean;
    private _hasVhostCreateRights: boolean;

    constructor(
        private webspaceModel: WebspaceModelService,
        protected $translate: ng.translate.ITranslateService
    ) {
        this._hasDomainCreateRights = AuthContextService.isGrantedAny([
            UiRights.DOM_DOMAINS_RENEW,
            UiRights.DOM_DOMAINS_CREATE_WITH_CONTACTS,
            UiRights.DOM_DOMAINS_CREATE_WITH_DEFAULTS,
            UiRights.DOM_DOMAINS_CREATE_WITH_OWN_NS
        ]);
        this._hasVhostCreateRights = AuthContextService.isGrantedAll([
            UiRights.WEB_OBJECT_LIST,
            UiRights.WEB_VHOST_CREATE
        ]);
    }

    public async $onInit(): Promise<void> {
        if (this._hasDomainCreateRights) {
            this.availableTypes.push(
                {
                    description: this.$translate.instant('TR_110119-af39b9_TR'),
                    name: this.$translate.instant('TR_110119-771e9a_TR'),
                    value: DomainTypes.REGISTER
                }
            );
        }

        if (this._hasVhostCreateRights) {
            if (await this._hasAccountWebspace()) {
                // Add external type to available selection
                this.availableTypes.push(
                    {
                        description: this.$translate.instant('TR_110119-d92ddf_TR'),
                        name: this.$translate.instant('TR_110119-085aff_TR'),
                        value: DomainTypes.EXTERNAL
                    }
                );

                // Add subdomain type to available selection
                this.availableTypes.push(
                    {
                        description: this.$translate.instant('TR_110119-14dd13_TR'),
                        name: this.$translate.instant('TR_110119-e50984_TR'),
                        value: DomainTypes.SUBDOMAIN
                    }
                );
            }
        }

        // if only one type is available, select this one (the selection wil not shown, if only on option is available)
        if (this.availableTypes.length === 1) {
            this.selectedDomainType = this.availableTypes[0].value;
        }
    }

    public select = (item: DomainRegistrationType): void => {
        this.selectedDomainType = item.value;
    };

    public selectByEnter = ($event: KeyboardEvent, item: DomainRegistrationType): void => {
        if ($event.code === 'Enter') {
            this.select(item);
        }
    };

    private _hasAccountWebspace = async (): Promise<boolean> => {
        return this.webspaceModel
            .list(1)
            .then((res) => res.data.length > 0);
    };
}

export class OrganCreateDomainTypeSelectionComponent implements ng.IComponentOptions {
    public bindings = {
        selectedDomainType: '='
    };
    public controller = OrganCreateDomainTypeSelectionController;
    public template = require('./domain-type-selection.html');
}
