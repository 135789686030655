import * as ng from 'angular';
import { OrganizationModelService } from '../services/email/organization-model';

export class OrganizationName {
    public static $inject: string[] = ['organizationModel'];

    public static Factory(organizationModel: OrganizationModelService) {
        const cache = {};

        const organizationNameFilter: any = (id: string) => {
            if (cache[id]) {
                if (cache[id] !== 'waiting') {
                    return cache[id].name;
                }

                return undefined;
            }

            cache[id] = 'waiting';

            organizationModel.findOne(id).then(
                (result) => {
                    cache[result.id] = result;
                },
                () => {
                    cache[id] = '';
                }
            );

            return undefined;
        };

        organizationNameFilter.$stateful = true;

        return organizationNameFilter;
    }
}
