export interface JobStatusObject {
    accountId: string;
    action: string;
    addDate?: string;
    clientTransactionId?: string;
    displayName: string;
    id: string;
    lastChangeDate?: string;
    objectType: string;
    orderItemId: string;
    objectId: string;
    rootOrderItemId: string;
    serverTransactionId: string;
    status: string;
    triggeredBy: null | any;
}

export class GeneralCreateService {
    public static $inject: string[] = [];

    public getJobStatusForTemplate = ((
        job: JobStatusObject,
        someCreateJobInSupportStatus: boolean = false,
        apiOrderRequestError: boolean = false
    ): string => {
        if (apiOrderRequestError) {
            return 'failed';
        }

        if ([undefined, null].indexOf(job ) >= 0
            // && !someCreateJobInSupportStatus
        ) {
            return 'creating';
        }

        if (['support', 'failed'].indexOf(job.status) >= 0
            // && !someCreateJobInSupportStatus
            // && !apiOrderRequestError
        ) {
            return 'failed';
        }

        if (['done', 'successful'].indexOf(job.status) >= 0) {
            return 'done';
        }

        if (['abort'].indexOf(job.status) >= 0
            || someCreateJobInSupportStatus
            || apiOrderRequestError
        ) {
            return 'abort';
        }
        // status unknown
        return 'creating';
    });
}
