import {
    TemplateBillingPricelistAbstractOverviewComponent,
    TemplateBillingPricelistAbstractOverviewController
} from '../abstract/pricelist-abstract-template';

export class TemplateBillingPricelistMachinesOverviewController
    extends TemplateBillingPricelistAbstractOverviewController {
    protected options = {
        panelTitleTranslationId: /* translationId */ 'TR_090119-b99134_TR',
        simpleFilterField: 'productName'
    };
}

export class TemplateBillingPricelistMachinesOverviewComponent
    extends TemplateBillingPricelistAbstractOverviewComponent {
    public controller = TemplateBillingPricelistMachinesOverviewController;
    public template = require('./pricelist-machines-template.html');
}
