export const DoNotUpdateSlaveZoneConst = true;
export const DomainOrderProductCodeSuffixes = {
    // tslint:disable-next-line
    create: 'create', // not renew, because on articlePurchasePrice with renew productCode will not show promotion price - What a plague
    renew: 'renew',
    transfer: 'transfer'
};

export const DomainObjectConst = {
    accountId: '',
    authInfo: '',
    bundleId: null,
    contacts: [],
    dnsSecEntries: [],
    name: '',
    nameUnicode: '',
    nameservers: [],
    productCode: '',
    transferLockEnabled: false,
    transferLockedByOwnerChangeUntil: null,
    trusteeServiceEnabled: false
};
