/* eslint-disable */

import ng from 'angular';
import * as Types from '@/types';
import { ViewTypes } from '@/types/view-types';
import { AsteriskNoteService, ScrollToValidationError } from '@/services';

export class OrganismSslWizardConfigViewController {

    public static $inject: string[] = ['$timeout', 'asteriskNote', '$scope'];

    public callback: (metaData: ViewTypes.ProductConfigSslObject) => void;
    public outerProductCode: string;
    public outerAccount: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    public metaData: ViewTypes.ProductConfigSslObject;

    private _account: Types.AccountApi.Account | Types.AccountApi.Subaccount;

    public constructor(
        private $timeout: ng.ITimeoutService,
        private asteriskNote: AsteriskNoteService,
        private $scope: ng.IScope
    ) { }

    public $onInit = (): void => {
        this.asteriskNote.resetNodes();
        if ([undefined, null].indexOf(this.outerAccount) < 0) {
            this.account = this.outerAccount;
        }

        this.metaData = {
            account: this.account,
            shortDescription: null,
            sslProductCode: null,
            sslProductFamily: null,
            sslValidationObject: null,
            sslBillingCycle: null,
            contacts: null,
            csrDomains: [],
            additionalDomains: [],
            billingInfos: [],
            domainsByProductCode: [],
            settings: {
                email: '',
                emailCheckActive: null,
                validationMethod: 'email',
                productSpecification: []
            },
            hasErrors: false
        } as ViewTypes.ProductConfigSslObject;

        this.$scope.$on('productSpecificationChanged', (evt: ng.IAngularEvent, data: any) => {
            this.$scope.$broadcast('productSpecificationChangedBroadcast', data);
        })
    };

    get account(): Types.AccountApi.Account | Types.AccountApi.Subaccount {
        return this._account;
    }

    set account(value: Types.AccountApi.Account | Types.AccountApi.Subaccount) {
        if (
            [undefined, null].indexOf(value) < 0
            && value !== this._account
            && [undefined, null].indexOf(this.metaData) < 0
        ) {
            this.metaData.account = value;
        }
        this._account = value;
    }

    public unsavedChangesExist = (): boolean => {
        if (this.metaData?.sslValidationObject?.csrRequestEncoded?.length > 0
            || this.metaData?.settings?.email?.length > 0
            || this.metaData?.additionalDomains?.length > 0
            || this.unsavedContacts('administrationContact')
            || this.unsavedContacts('organizationContact')
            || this.unsavedContacts('technicalContact')
        ) {
            return true;
        }
        return false;
    };

    public unsavedContacts = (contact: 'administrationContact' | 'organizationContact' | 'technicalContact'): boolean => {
        for (const field in this.metaData.contacts?.[contact]?.fields) {
            if (Object.prototype.hasOwnProperty.call(this.metaData.contacts[contact].fields, field)) {
                const value = this.metaData.contacts[contact].fields[field].value;
                if (value?.length > 0) {
                    return true;
                }
            }
        }

        return false;
    };

    public scrollToError = (): void => {
        ScrollToValidationError.scrollToError(this.$timeout);
    };

    public get csrResponseWithoutErrors(): boolean {
        if ([undefined, null].indexOf(this.metaData.sslValidationObject?.apiResponse) < 0) {

            return this.metaData.sslValidationObject.apiResponse.errors?.length <= 0;
        }
        return false;
    }

    public get showSaveButton(): boolean {
        // check if user has sufficient credits available
        if (this.metaData.sufficientCreditAvailable === false) {
            return false;
        }

        // check if email is defined for validationMethod
        if (this.metaData.settings?.email?.length < 1) {
            return false;
        }

        // check if additional domains still have errors
        if (this.metaData.hasErrors) {
            return false;
        }

        // check if contacts are filled out correct
        return this.checkRequiredContactsFilledOut();
    }

    // #endregion getter setter

    public checkRequiredContactsFilledOut(): boolean {
        if ([null, undefined].indexOf(this.metaData.contacts) < 0) {
            // eslint-disable-next-line guard-for-in
            for (const contactKey in this.metaData.contacts) {
                const contact: ViewTypes.SslContact<any> = this.metaData.contacts[contactKey];
                // check only if contact form is visible and toggle is active
                if (contact.show === true && contact.flags?.checkFilledOut !== false) {
                    if (contact.filledOut() === false) {
                        return false;
                    }
                }
            }
        } else {
            return false;
        }
        return true;
    }

    public finishConfiguration = (): void => {
        if (this.callback !== undefined) {
            this.callback(this.metaData);
        }
    };

}

export class OrganismSslWizardConfigViewComponent implements ng.IComponentOptions {
    public bindings = {
        callback: '<',
        productFamily: '=',
        outerAccount: '<',
        outerProductCode: '<'
    };
    public template = require('./ssl-wizard-config-view.html');
    public controller = OrganismSslWizardConfigViewController;
}
