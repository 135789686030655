import { CharPoolsConst } from '@/configuration';
import * as ng from 'angular';
import { ValidatePhone } from '../../../../../services';
import { FormInputModelOptions } from '../../../../atoms/form-elements';

export class MoleculeInputTextPhoneNumberController implements ng.IController {
    public validationErrorList = ['$translate'];

    public value: string;
    public placeholder: string;
    public callbackOnChange: (arg0: any) => any;
    public callbackOnBlurParams: any;
    public customCallbackOnEnter: () => {};
    public doNotSaveOnFormEnter: boolean;
    public disabled: boolean;
    public disableRandomisedId: boolean;
    public modelOptions: FormInputModelOptions;
    public registerToForm: boolean;
    public options: any;
    public validationInstructions: any[];
    public inputId: string;
    public callbackOnClear: (arg0: any) => any;
    public callbackOnClearParams: any;
    public clearable: boolean;

    private _callbackOnBlur: (arg0: any) => any;

    constructor(
        public $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        let allowEmpty = true;
        let minLength = 0;
        let maxLength = 100;

        if (this.options != null && this.options !== undefined) {
            if (this.options.allowEmpty != null && this.options.allowEmpty !== undefined) {
                allowEmpty = this.options.allowEmpty;
            }
            if ([null, undefined].indexOf(this.options.minLength) < 0) {
                minLength = this.options.minLength;
            }
            if ([null, undefined].indexOf(this.options.maxLength) < 0) {
                maxLength = this.options.maxLength;
            }
        }
        this.validationInstructions = [
            {
                instructions: null,
                validator: new ValidatePhone(
                    minLength,
                    maxLength,
                    true,
                    CharPoolsConst.numerals.value.concat(' +()'),
                    allowEmpty,
                    this.$translate)
            }
        ];
    }

    public onPaste = (paste) => {
        // implement callback on paste here when needed!
    };

    // Build for PUI-5436
    public callbackOnBlur = (value) => {
        if (value === undefined) {
            return;
        }

        const localCut = 5;
        const areaCode = value.slice(0, 1);
        let phone = value.substring(1);

        // Format '123456789' to '1234 56789' (add space)
        if (phone.indexOf(' ') < 0) {
            phone = `${phone.substring(0, localCut)} ${phone.substring(localCut, phone.length - 1)}`;
        }

        // Format '01234 56789' to '+49 1234 56789'
        if (!isNaN(areaCode) && value.indexOf('0') === 0) {
            this.value = `+49 ${phone}`;
        }

        if (this._callbackOnBlur) {
            this._callbackOnBlur(this.callbackOnBlurParams);
        }
    };
}

export class MoleculeInputTextPhoneNumberComponent implements ng.IComponentOptions {
    public bindings = {
        _callbackOnBlur: '<callbackOnBlur',
        callbackOnBlurParams: '<',
        callbackOnChange: '<',
        callbackOnClear: '<',
        callbackOnClearParams: '<',
        clearable: '<',
        customCallbackOnEnter: '<',
        disabled: '<',
        disableRandomisedId: '<?',
        doNotSaveOnFormEnter: '<',
        inputId: '@?',
        modelOptions: '<',
        options: '<',
        placeholder: '@',
        registerToForm: '<',
        validationErrorList: '=?',
        value: '='
    };
    public controller = MoleculeInputTextPhoneNumberController;
    public template = require('./input-text-with-validation-wrapper.html');
}
