import {
    DashboardWidgetModel,
    DashboardWidgetType
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model';
import {
    DashboardWidgetObjectDefinition
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model-operator';

export class DashboardWidgetSepaVerificationNotifierModel extends DashboardWidgetModel {
    public static $inject: string[] = DashboardWidgetModel.$inject.concat([]);

    public static dashboardWidgetDefinition: DashboardWidgetObjectDefinition = {
        demoData: {},
        manualAddable: false,
        userPermissions: null,
        systemNotification: 'finishBankAccountVerification',
        widgetDescription: [],
        widgetObject: {
            attribute: {
                id: 'organDashboardWidgetSepaVerificationNotifier',
                width: 2,
                height: 1,
                locked: true,
                noResize: true,
                noMove: true,
                autoPosition: true
            },
            componentTagName: 'organ-dashboard-widget-sepa-verification-notifier',
            widgetModel: 'dashboardWidgetSepaVerificationNotifierModel',
            version: '1.0'
        },
        widgetTitle: /* translationId */ 'TR_290121-00e852_TR',
        widgetType: DashboardWidgetType.system
    };

    public dashboardWidgetDefinition: DashboardWidgetObjectDefinition;

    constructor() {
        super();

        this.dashboardWidgetDefinition = DashboardWidgetSepaVerificationNotifierModel.dashboardWidgetDefinition;
    }
}
