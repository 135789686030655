import * as ng from 'angular';
import './panel-row-job-event.scss';

export class MoleculePanelRowJobEventController implements ng.IController {
    public static $inject: string[] = [];
    public event: any;
}

export class MoleculePanelRowJobEventComponent implements ng.IComponentOptions {
    public bindings = {
        event: '<'
    };

    public controller = MoleculePanelRowJobEventController;
    public template = require('./panel-row-job-event.html');
}
