import * as ng from 'angular';
import { NavigationService } from '@/services';

export class MoleculePanelIncompleteUserProfileController implements ng.IController {
    public static $inject: string[] = ['navigation'];

    constructor(
        private navigation: NavigationService
    ) {}

    public goToContactDetailsPage = (): void => {
        void this.navigation.go('account.contactdetails');
    };
}

export class MoleculePanelIncompleteUserProfileComponent implements ng.IComponentOptions {
    public controller =  MoleculePanelIncompleteUserProfileController;
    public template = require('./panel-incomplete-user-profile.html');
}
