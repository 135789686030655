import * as ng from 'angular';
import { LinkListOverviewItem } from '../../atomic-components/molecules/links';
import {
    AccountFilterField,
    DdnsUserFilterField,
    SelectFilterField,
    TextFilterField
} from '../../atomic-components/molecules/search/fancy-filter-fields';
import { UiRights } from '../../configuration';
import { OverviewModel } from '../abstract';
import { AuthContextService } from '../auth-context';
import { RequestCancellerService } from '../request-canceller';
import { DdnsModelService } from './dns-ddns-model';

export class DdnsHostnameOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', 'authContext', 'ddnsModel']
    );

    public readonly service = 'dns';
    public readonly objectType = 'DynamicDnsHostname';
    public readonly idField = 'dynamicDnsHostnameId';
    public readonly defaultFilterFields = ['DynamicDnsHostnameHostname'];

    protected listCallbacks = [{callback: this.ddnsModel.listHostnames}];

    private _cachedAvailableFilters = null;
    private _useMinimalVersion = false;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private ddnsModel: DdnsModelService
    ) {
        super(RequestCanceller);
    }

    public showMinimalVersion = () => {
       this._useMinimalVersion = true;
    };

    public showNormalVersion = () => {
        this._useMinimalVersion = false;
    };

    // tslint:disable-next-line:member-ordering
    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('dynamicDnsHostnameHostname', this.$translate.instant('TR_120319-52df93_TR')),
                new SelectFilterField(
                    'dynamicDnsHostnameStatus',
                    this.$translate.instant('TR_140119-8e4b60_TR'),
                    [
                        {
                            name: this.$translate.instant('GENERAL.OBJECT.STATUS.CREATING'),
                            value: 'creating'
                        },
                        {
                            name: this.$translate.instant('35b2882a-2c6b-4291-b7de-963835933109'),
                            value: 'active'
                        },
                        {
                            name: this.$translate.instant('GENERAL.OBJECT.STATUS.RESTORABLE'),
                            value: 'restorable'
                        }
                    ]
                ),
                new DdnsUserFilterField('DynamicDnsHostnameCredentialsId',
                    this.$translate.instant('TR_200319-418d09_TR'))
            ];
            if (!this._useMinimalVersion) {
                this._cachedAvailableFilters.splice(1, 0,
                    new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR'))
                );
            }
        }

        return this._cachedAvailableFilters;
    }

    // tslint:disable-next-line:member-ordering
    public set availableFilters({}) {
        return;
    }

    public actionLinks = (): LinkListOverviewItem[] => {
        if (this._useMinimalVersion) {
            return [];
        }
        return [
            {
                isGranted: this.authContext.isGrantedAny([UiRights.DNS_DDNSHOSTNAME_CREATE]),
                route: 'dns.ddns.hostnames.new',
                text: this.$translate.instant('TR_130319-d18157_TR')
            }
        ] as LinkListOverviewItem[];
    };
}
