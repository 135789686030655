import * as ng from 'angular';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';
import { WebhostingApi } from '../../../types';

export class TemplateWebhostingVhostDirectoryProtectionController {
    public static $inject: string[] = ['$state', '$translate', 'authContext'];

    public pageHeaderData: any;
    public bundle: any;
    public bundleId: string;
    public vHost: WebhostingApi.VHost;
    public webspace: WebhostingApi.Webspace;
    public userPanelRight: any;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit() {
        let panelHeaderRoute: string;
        let panelHeaderRouteParams = {};
        let backwardText: string = this.$translate.instant('TR_100419-7d3515_TR');

        switch (this.$state.current.name.split('.')[0]) {
            case 'bundle':
                panelHeaderRoute = 'bundle.id.domains.overview';
                panelHeaderRouteParams = { bundleId: this.$state.$current.locals.globals.bundle.id };
                break;
            case 'managed-servers':
                panelHeaderRoute = 'managed-servers.id.webspaces.id.vhosts.overview';
                panelHeaderRouteParams = {
                    machineId: this.$state.$current.locals.globals.machine.id,
                    webspaceId: this.$state.$current.locals.globals.webspace.id
                };
                backwardText = this.$translate.instant('TR_110119-7424eb_TR');
                break;
            default:
                panelHeaderRoute = 'webhosting.webspaces.id.vhosts.id.edit';
        }

        // Apply data to header panel.
        this.pageHeaderData = {
            backwardLink: panelHeaderRoute,
            backwardText: backwardText,
            panelHeaderRoute: panelHeaderRoute,
            panelHeaderRouteParams: panelHeaderRouteParams,
            panelIcon: 'globe',
            panelTitle: this.$translate.instant(
                /* translationId */ 'TR_110119-bc84fb_TR',
                { domainNameUnicode: this.vHost.domainNameUnicode }
            )
        };

        // Set rights.
        this.userPanelRight = {
            editPanelButton: this.authContext.isGranted(UiRights.DOM_CONTACTS_EDIT)
        };
    }
}

export class TemplateWebhostingVhostDirectoryProtectionComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<',
        vHost: '<',
        webspace: '<'
    };
    public controller = TemplateWebhostingVhostDirectoryProtectionController;
    public template = require('./vhost-directory-protection-template.html');
}
