import * as ng from 'angular';
import { PageHeaderData } from '../../../atomic-components/organisms';
import { DnsApi } from '../../../types';

export class TemplateDdnsHostnamesEditController {
    public static $inject: string[] = ['$translate'];

    public ddnsHostname: DnsApi.DynamicDnsHostname;
    public availableDdnsUsers: DnsApi.DynamicDnsCredentials[];
    public showObjectId: boolean;
    public pageHeaderData: PageHeaderData = {
        backwardLink: 'dns.ddns.hostnames.overview',
        backwardText: this.$translate.instant('TR_130319-67ad15_TR'),
        panelHeaderRoute: '',
        panelIcon: 'location-arrow',
        panelTitle: ''
    };
    public faqArticleIdList: string[] = [
        'dynamic-dns',
        'dns-delete-entry',
        'dns-setup-office',
        'dns-srv-record'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.pageHeaderData.panelTitle = this.$translate.instant(
            /* translationId */ 'TR_180319-5593ea_TR',
            { hostName: this.ddnsHostname.hostname }
        );
    }
}

export class TemplateDdnsHostnamesEditComponent implements ng.IComponentOptions {
    public bindings = {
        availableDdnsUsers: '<',
        ddnsHostname: '<',
        showObjectId: '<'
    };
    public template = require('./ddns-hostnames-edit-template.html');
    public controller = TemplateDdnsHostnamesEditController;
}
