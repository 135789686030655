import { UiLanguagesConst, UIRegExp, UiRights } from '@/configuration';
import { RobotTranslate } from '@/services/translate';
import * as Types from '@/types';

const copy = <T>(original: T): T => JSON.parse(JSON.stringify(original)) as T;

type ContactDataKey = 'name' | 'street' | 'zipCode' | 'city' | 'country' | 'emailAddress' | 'phoneNumber';

const translateContactDataKey = (key: ContactDataKey): string => {
    const translate = new RobotTranslate();
    translate.use(UiLanguagesConst[AuthContextService.user.language as 'de' | 'en']);

    switch (key) {
        case 'city':            return translate.instant('d25a5fcf-9137-442b-9842-9d226a02409a');
        case 'country':         return translate.instant('0c29de61-bfdb-4f7b-9b5e-d8e99190b64c');
        case 'emailAddress':    return translate.instant('55dbf5a5-dd36-447e-804b-d88abf2abb06');
        case 'name':            return translate.instant('2fa3b5af-20e5-46e0-9e75-0845593d7a81');
        case 'phoneNumber':     return translate.instant('TR_010621-ae83fd_TR');
        case 'street':          return translate.instant('f50985f4-d9ae-47e4-ba9a-d703973b17a6');
        case 'zipCode':         return translate.instant('853ceb6f-0ef4-4c4b-83c4-525b2bb8ca12');
    }
};

export class AuthContextService {
    private static _authenticationDetails: Types.AccountApi.AuthenticationDetails;

    /* NEW STATIC METHODS - PREFER USING THESE WHERE POSSIBLE! */

    public static setContextFromResponse(response: Types.AccountApi.AuthenticationDetails): void {
        if (AuthContextService.authenticated) {
            const error = new Error('Error: Tried to set authentication details while already authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        AuthContextService.resetContextFromResponse(response);
    }

    public static resetContextFromResponse(response: Types.AccountApi.AuthenticationDetails): void {
        AuthContextService._authenticationDetails = copy(response);
    }

    public static clear(): void {
        AuthContextService._authenticationDetails = undefined;
    }

    public static get authenticated(): boolean {
        return [undefined, null].indexOf(AuthContextService._authenticationDetails) < 0;
    }

    public static get authenticationDetails(): Types.AccountApi.AuthenticationDetails {
        if (!this.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return copy(AuthContextService._authenticationDetails);
    }

    public static get user(): Types.AccountApi.User {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return copy(AuthContextService._authenticationDetails.user);
    }

    public static get account(): Types.AccountApi.Account {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return copy(AuthContextService._authenticationDetails.account);
    }

    public static get accountDefaults(): Types.AccountApi.AccountDefaults {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return copy(AuthContextService._authenticationDetails.accountDefaults);
    }

    public static get accountOrganizationInformation(): Types.AccountApi.AccountOrganizationInformation {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return copy(AuthContextService._authenticationDetails.accountOrganizationInformation);
    }

    public static get accountPaymentDetails(): Types.AccountApi.AccountPaymentDetails {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return copy(AuthContextService._authenticationDetails.accountPaymentDetails);
    }

    public static get billingSettings(): Types.AccountApi.AccountBillingSettings {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return copy(AuthContextService._authenticationDetails.account.billingSettings);
    }

    public static get isRoot(): boolean {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return AuthContextService._authenticationDetails.account.id === '1';
    }

    public static get isStaff(): boolean {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return AuthContextService.isRootOrCompanyAccount;
    }

    public static get isRootOrCompanyAccount(): boolean {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return UIRegExp.IsNumeric.test(AuthContextService._authenticationDetails.account.id)
            && parseInt(AuthContextService._authenticationDetails.account.id, 10) < 1000;
    }

    public static get token(): string {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return AuthContextService._authenticationDetails.csrfToken + '';
    }

    public static isGranted(right: string): boolean {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return AuthContextService._isGranted(right);
    }

    public static isGrantedAny(rights: string[]): boolean {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return rights.some((right) => AuthContextService._isGranted(right));
    }

    public static isGrantedAll(rights: string[]): boolean {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return rights.every((right) => AuthContextService._isGranted(right));
    }

    public static isOwnUser(user: Types.AccountApi.User): boolean {
        return user.accountId === this.account.id && user.id === this.user.id;
    }

    public static get isDirectCustomer(): boolean {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return ( // if the string is non numeric the id is above the 1k mark
                !UIRegExp.IsNumeric.test(AuthContextService._authenticationDetails.account.id)
                || parseInt(AuthContextService._authenticationDetails.account.id, 10) > 1000
            )
            && (
                UIRegExp.IsNumeric.test(AuthContextService._authenticationDetails.account.parentAccountId)
                && parseInt(AuthContextService._authenticationDetails.account.parentAccountId, 10) < 1000
            );
    }

    public static get isDirectHostingDeCustomer(): boolean {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return AuthContextService.account.parentAccountId === '4';
    }

    public static get isMainUser(): boolean {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return AuthContextService._authenticationDetails.user.rights
            .indexOf(UiRights.ACC_USER_LOGGED_IN_AS_SUBACCOUNT) < 0;
    }

    public static get isAdminUser(): boolean {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return AuthContextService._authenticationDetails.user.adminUser;
    }

    public static get canDeleteOwnAccount(): boolean {
        return !AuthContextService.isRootOrCompanyAccount
        && AuthContextService.user.adminUser
        && !AuthContextService.isGranted(UiRights.ACC_USER_LOGGED_IN_AS_SUBACCOUNT);
    }

    private static _isGranted(right: string): boolean {
        if (right === UiRights.UI_MISC_IS_DIRECT_CUSTOMER) {
            return AuthContextService.isDirectCustomer;
        }
        if (
            [undefined, null].indexOf(AuthContextService._authenticationDetails.user) >= 0
            || [undefined, null].indexOf(AuthContextService._authenticationDetails.user.rights) >= 0
        ) {
            return false;
        }

        return AuthContextService._authenticationDetails.user.rights.indexOf(right) >= 0;
    }

    public static get isCurrentUserAccountComplete(): boolean {
        return ['name', 'street', 'zipCode', 'city', 'country', 'emailAddress', 'phoneNumber']
            .every(
                (attribute: ContactDataKey) => {
                    return [undefined, null, ''].indexOf(AuthContextService.account[attribute]) < 0;
                }
            );
    }

    public static get missingAccountData(): string {
        return ['name', 'street', 'zipCode', 'city', 'country', 'emailAddress', 'phoneNumber']
            .filter(
                (attribute: ContactDataKey) => {
                    return [undefined, null, ''].indexOf(AuthContextService.account[attribute]) >= 0;
                }
            )
            .map(translateContactDataKey)
            .join(', ');
    }

    public static isLoggedAsSubaccount() {
        return this.isGranted(UiRights.ACC_USER_LOGGED_IN_AS_SUBACCOUNT);
    };

    /* OLD INSTANCED METHODS */

    public isLoggedAsSubaccount() {
        return this.isGranted(UiRights.ACC_USER_LOGGED_IN_AS_SUBACCOUNT);
    };

    public setContextFromResponse(response: Types.AccountApi.AuthenticationDetails): void {
        if (AuthContextService.authenticated) {
            const error = new Error('Error: Tried to set authentication details while already authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        AuthContextService.resetContextFromResponse(response);
    }

    public resetContextFromResponse(response: Types.AccountApi.AuthenticationDetails): void {
        AuthContextService._authenticationDetails = copy(response);
    }

    public clear(): void {
        AuthContextService._authenticationDetails = undefined;
    }

    public get authenticated(): boolean {
        return [undefined, null].indexOf(AuthContextService._authenticationDetails) < 0;
    }

    public get authenticationDetails(): Types.AccountApi.AuthenticationDetails {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return copy(AuthContextService._authenticationDetails);
    }

    public get user(): Types.AccountApi.User {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return copy(AuthContextService._authenticationDetails.user);
    }

    public get account(): Types.AccountApi.Account {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return copy(AuthContextService._authenticationDetails.account);
    }

    public get accountDefaults(): Types.AccountApi.AccountDefaults {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return copy(AuthContextService._authenticationDetails.accountDefaults);
    }

    public get accountOrganizationInformation(): Types.AccountApi.AccountOrganizationInformation {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return copy(AuthContextService._authenticationDetails.accountOrganizationInformation);
    }

    public get accountPaymentDetails(): Types.AccountApi.AccountPaymentDetails {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return copy(AuthContextService._authenticationDetails.accountPaymentDetails);
    }

    public get billingSettings(): Types.AccountApi.AccountBillingSettings {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return copy(AuthContextService._authenticationDetails.account.billingSettings);
    }

    public get isRoot(): boolean {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return AuthContextService._authenticationDetails.account.id === '1';
    }

    public get isStaff(): boolean {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return AuthContextService.isRootOrCompanyAccount;
    }

    public get isRootOrCompanyAccount(): boolean {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return UIRegExp.IsNumeric.test(AuthContextService._authenticationDetails.account.id)
            && parseInt(AuthContextService._authenticationDetails.account.id, 10) < 1000;
    }

    public get token(): string {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return AuthContextService._authenticationDetails.csrfToken + '';
    }

    public isGranted(right: string): boolean {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return AuthContextService._isGranted(right);
    }

    public isGrantedAny(rights: string[]): boolean {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return rights.some((right) => AuthContextService._isGranted(right));
    }

    public isGrantedAll(rights: string[]): boolean {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return rights.every((right) => AuthContextService._isGranted(right));
    }

    public get isDirectCustomer(): boolean {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return ( // if the string is non numeric the id is above the 1k mark
                !UIRegExp.IsNumeric.test(AuthContextService._authenticationDetails.account.id)
                || parseInt(AuthContextService._authenticationDetails.account.id, 10) > 1000
            )
            && (
                UIRegExp.IsNumeric.test(AuthContextService._authenticationDetails.account.parentAccountId)
                && parseInt(AuthContextService._authenticationDetails.account.parentAccountId, 10) < 1000
            );
    }

    public get isMainUser(): boolean {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return AuthContextService._authenticationDetails.user.rights
            .indexOf(UiRights.ACC_USER_LOGGED_IN_AS_SUBACCOUNT) < 0;
    }

    public get isAdminUser(): boolean {
        if (!AuthContextService.authenticated) {
            const error = new Error('Error: Tried accessing authentication details while not authenticated.');
            error.name = 'AuthContextError';

            throw error;
        }

        return AuthContextService._authenticationDetails.user.adminUser;
    }

    public get canDeleteOwnAccount(): boolean {
        return !AuthContextService.isRootOrCompanyAccount
        && AuthContextService.user.adminUser
        && !AuthContextService.isGranted(UiRights.ACC_USER_LOGGED_IN_AS_SUBACCOUNT);
    }

    public get isCurrentUserAccountComplete(): boolean {
        return ['name', 'street', 'zipCode', 'city', 'country', 'emailAddress', 'phoneNumber']
            .every(
                (attribute: ContactDataKey) => {
                    return [undefined, null, ''].indexOf(AuthContextService.account[attribute]) < 0;
                }
            );
    }

    public get missingAccountData(): string {
        return ['name', 'street', 'zipCode', 'city', 'country', 'emailAddress', 'phoneNumber']
            .filter(
                (attribute: ContactDataKey) => {
                    return [undefined, null, ''].indexOf(AuthContextService.account[attribute]) >= 0;
                }
            )
            .map(translateContactDataKey)
            .join(', ');
    }
}
