import * as ng from 'angular';
import { UiRights } from '../../../../configuration';
import { AuthContextService } from '../../../../services';
import { LinkListItem } from '../../links/link-list/link-list';
import './panel-new-product.scss';

export class MoleculePanelNewProductController implements ng.IController {
    public static $inject: string[] = ['$translate', 'authContext'];

    public newBundleLinkList: LinkListItem[] = [];
    public newProductLinkList: LinkListItem[] = [];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    $onInit() {
        if (this.authContext.isGrantedAll([UiRights.BIL_BUNDLE_CREATE, UiRights.WEB_OBJECT_LIST])) {
            this.newBundleLinkList = [
                {
                    text: this.$translate.instant('TR_160119-44bb57_TR'),
                    route: 'bundle.new',
                    isGranted: this.authContext.isGranted(UiRights.BIL_BUNDLE_CREATE)
                }
            ];
        }
        this.newProductLinkList = [{
            text: this.$translate.instant('TR_110119-8242f2_TR'),
            route: 'domain.domains.new',
            isGranted: this.authContext.isGrantedAny([
                UiRights.DOM_DOMAINS_CREATE_WITH_CONTACTS,
                UiRights.DOM_DOMAINS_CREATE_WITH_DEFAULTS,
                UiRights.DOM_DOMAINS_CREATE_WITH_OWN_NS
            ])
        }, {
            text: this.$translate.instant('TR_160119-c32892_TR'),
            route: 'mailbox.mailboxes.new',
            isGranted: this.authContext.isGrantedAny([
                UiRights.EMAIL_EXCHANGE_CREATE,
                UiRights.EMAIL_FORWARDER_CREATE,
                UiRights.EMAIL_IMAPMAILBOX_CREATE
            ])
        }, {
            text: this.$translate.instant('TR_160119-a4452c_TR'),
            route: 'webhosting.webspaces.new',
            isGranted: this.authContext.isGranted(UiRights.WEB_WEBSPACE_CREATE)
        }, {
            text: this.$translate.instant('DATABASE.NEW.TITLE'),
            route: 'database.databases.new',
            isGranted: this.authContext.isGranted(UiRights.DB_DATABASE_CREATE)
        }, {
            text: this.$translate.instant('TR_160119-a62ec7_TR'),
            route: 'machine.virtualmachines.new',
            isGranted: this.authContext.isGranted(UiRights.MACHINE_VM_CREATE)
        }];
    }

    public allRightsForBox(list) {
        return !list.every(item => {
            return !item.isGranted;
        });
    }
}

export class MoleculePanelNewProductComponent implements ng.IComponentOptions {
    public controller = MoleculePanelNewProductController;
    public template = require('./panel-new-product.html');
}
