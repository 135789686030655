import {
    DashboardWidgetModel,
    DashboardWidgetType
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model';
import {
    DashboardWidgetObjectDefinition
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model-operator';

export class DashboardWidgetProductNextcloudModel extends DashboardWidgetModel {
    public static $inject: string[] = DashboardWidgetModel.$inject.concat([]);

    public static dashboardWidgetDefinition: DashboardWidgetObjectDefinition = {
        demoData: {
            nextcloud: {
                backupEnabled: true,
                currentNumberOfUsers: 2,
                defaultDomainName: '201211353rvblb523ru.nextcloud.demo.zone',
                individualDomainNameStatus: 'notConfigured',
                maxNumberOfUsers: 10,
                name: 'Nextcloud Demo',
                productCode: 'managed-application-nextcloud-100-1m',
                status: 'active',
                storageQuota: 102400
            }
        },
        manualAddable: true,
        userPermissions: {
            isGrantedAll: [
                'MANAGED_APPLICATION_NEXTCLOUD_LIST',
                'MANAGED_APPLICATION_NEXTCLOUD_EDIT'
            ]
            // notRootOrCompanyAccount: true // disabled for testing
        },
        widgetDescription: [
            /* translationId */ 'TR_150321-75cffb_TR'
        ],
        widgetObject: {
            attribute: {
                id: 'organDashboardWidgetProductNextcloud',
                width: 2,
                height: 1,
                x: 0,
                y: 0,
                noResize: true,
                autoPosition: true
            },
            componentTagName: 'organ-dashboard-widget-product-nextcloud',
            widgetModel: 'dashboardWidgetProductNextcloudModel',
            version: '1.0'
        },
        widgetTitle: /* translationId */ 'TR_290121-388122_TR',
        widgetType: DashboardWidgetType.custom
    };

    public dashboardWidgetDefinition: DashboardWidgetObjectDefinition;

    constructor() {
        super();

        this.dashboardWidgetDefinition = DashboardWidgetProductNextcloudModel.dashboardWidgetDefinition;
    }
}
