export * from './content-change/content-change-template';
export * from './content-search/content-search-template';
export * from './dashboard/dashboard-template';
export * from './ddns-credentials-edit/ddns-credentials-edit-template';
export * from './ddns-credentials-new/ddns-credentials-new-template';
export * from './ddns-credentials-overview/ddns-credentials-overview-template';
export * from './ddns-hostnames-edit/ddns-hostnames-edit-template';
export * from './ddns-hostnames-overview/ddns-hostnames-overview-template';
export * from './dns-job-details/dns-job-details-template';
export * from './dns-job-overview/dns-job-overview-template';
export * from './dns-wizard/dns-wizard-template';
export * from './nameserver-set-edit/nameserver-set-edit-template';
export * from './nameserver-set-new/nameserver-set-new-template';
export * from './nameserver-set-overview/nameserver-set-overview-template';
export * from './records-edit/records-edit-template';
export * from './template-copy/template-copy-template';
export * from './template-edit/template-edit-template';
export * from './template-new/template-new-template';
export * from './template-overview/template-overview-template';
export * from './zone-edit-move/zone-edit-move-template';
export * from './zone-edit/zone-edit-template';
export * from './zone-overview/zone-overview-template';
