export class OrganDashboardWidgetAdditionalLinksController implements ng.IController {
    public static $inject: string[] = [];
}

export class OrganDashboardWidgetAdditionalLinksComponent implements ng.IComponentOptions {
    public bindings = {};

    public template = require('./dashboard-widget-additional-links.html');
    public controller = OrganDashboardWidgetAdditionalLinksController;
}
