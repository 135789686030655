import { DomainContactValidatorService, DomainHelper } from '../../../../../services';

import * as ng from 'angular';

import './panel-edit-row-contact-move.scss';

export class MoleculePanelEditRowContactMoveController implements ng.IController {
    public static $inject: string[] = ['$q', 'domainContactValidator', '$translate'];

    public contactData: any;
    public actionDropdownItems: any[];
    public contactDropdownItems: any[];
    public replacementContact: any;
    public contactDropdownHeadings: string[] = [];
    public selectedDomainNames: string[] = [];
    public domains: any[] = [];
    public verificationErrors: any[];
    public targetAccount: string | null;

    private lastReplacementContact: any;

    constructor(
        private $q: ng.IQService,
        private domainContactValidator: DomainContactValidatorService,
        protected $translate: ng.translate.ITranslateService
    ) {
        this.contactDropdownHeadings = [
            this.$translate.instant('GENERAL.CONTACT'),
            this.$translate.instant('TR_140119-796b28_TR'),
            this.$translate.instant('TR_100119-9b4d68_TR'),
            this.$translate.instant('TR_100119-77ee69_TR'),
            this.$translate.instant('TR_070519-16189c_TR')
        ];

    }

    public $onInit() {
        this.actionDropdownItems = [];
        this.contactData.availableOptions.map(
            (option) => {
                this.actionDropdownItems.push({
                    name: option,
                    value: option
                });
            }
        );
    }

    public replacementContactChanged = (replacementContact: any) => {
        if (
            (
                [null, undefined].indexOf(replacementContact) < 0
                && [undefined].indexOf(this.lastReplacementContact) >= 0
            ) || (
                [undefined, null].indexOf(this.lastReplacementContact) < 0
                && this.lastReplacementContact !== replacementContact
            )
        ) {
            this.lastReplacementContact = replacementContact;
            this.verificationErrors = [];
            this.contactData.replacement = replacementContact;
            const alreadyChecked = [];
            this.domains.map((domain) => {
                if (this.selectedDomainNames.indexOf(domain.name) < 0) {
                    return;
                }
                const promises = this.contactData.allocations.map(
                    (allocation) => {
                        const usableForCheckString = '' + replacementContact + allocation
                            + DomainHelper.getTld(domain.nameUnicode);
                        if (
                            alreadyChecked.indexOf(usableForCheckString) >= 0
                        ) {
                            return;
                        }
                        alreadyChecked.push(usableForCheckString);
                        return this.domainContactValidator.contactUsableFor(
                            replacementContact,
                            allocation,
                            [DomainHelper.getTld(domain.nameUnicode)],
                            this.targetAccount
                        )
                        .then(
                            (reply) => {
                                if (reply.missingProperties.length > 0 || reply.mustBeOfType.length > 0) {
                                    return {
                                        missingProperties: reply.missingProperties,
                                        mustBeOfType: reply.mustBeOfType,
                                        role: allocation
                                    };
                                }
                            }
                        );
                    }
                );

                this.$q.all(promises).then((properties) => {
                    const props = properties.filter((prop) => prop !== undefined);
                    if (props.length > 0) {
                        this.verificationErrors.push({
                            domain: domain.nameUnicode,
                            properties: props
                        });
                    }
                });
            });
        }
    };

    public actionOptionSelected = () => {
        if (this.contactData.selectedOption !== 'replace') {
            this.contactData.replacement = undefined;
        }
    };
}

export class MoleculePanelEditRowContactMoveComponent implements ng.IComponentOptions {
    public bindings = {
        contactData: '=',
        contactDropdownItems: '<',
        domains: '<',
        selectedDomainNames: '<',
        targetAccount: '<'
    };
    public controller = MoleculePanelEditRowContactMoveController;
    public template = require('./panel-edit-row-contact-move.html');
}
