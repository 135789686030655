import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import { AuthContextService } from '../../../../../services';
import { OrganismOverviewController, OverviewDataObject } from '../../../../organisms';

export class MoleculeOverviewRowPanelMachineController implements ng.IController {
    public static $inject: string[] = ['$state', 'authContext'];

    public overviewData: OverviewDataObject;
    public OrganismOverview: OrganismOverviewController;
    public overviewLoad: (selection: any) => any;
    public internalPaginationLimit: number;

    constructor(
        private $state: ng.ui.IStateService,
        private authContext: AuthContextService
    ) {}

    public terminalAllowed = (machine) => {
        if (!machine) {
            return false;
        }

        return machine.power === 'on'
            && machine.status === 'active'
            && (
                machine.managementType === 'none'
                || this.authContext.isGranted(UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN)
            );
    };

    public openTerminal = (machine, $event) => {
        if (!machine) {
            return false;
        }

        if ($event) {
            $event.stopPropagation();
        }

        window.open(
            this.$state.href('machine.virtualmachines.id.vnc', { machineId:machine.id }),
            `terminal_vm_${machine.id}`,
            'menubar=no,location=yes,resizable=no,scrollbars=no,status=yes,width=1024,height=928'
        );
    };

    public get showAccounts() {
        return this.OrganismOverview.globals.showAccounts;
    }

}

export class MoleculeOverviewRowPanelMachineComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelMachineController;
    public controllerAs = 'OverviewMachineCtrl';
    public template = require('./machine-row.html');
}
