import * as ng from 'angular';
import { UiRights } from '../../../../configuration';
import { AuthContextService, SelectedProduct } from '../../../../services';

export interface WizardPromoProduct {
    title?: string;
    description: string;
    headLine?: string;
    productFamilyName?: string;
    route: string;
    routeParams: any;
    buttonText: string;
}

export class MoleculePanelProductSelectController implements ng.IController {
    public static $inject: string[] = [];

    public selectedProductFamily: any;
    public selectedProduct: SelectedProduct;
    public selectedBox: any;
    public productFamilySelectTitle: string;
    public productSelectTitle: string;
    public displayMissingUserRightsError = false;
    public promoProductItems: WizardPromoProduct[] = [];
    public showFamilySelection = true;

    private _productBoxes: any[];
    private _serviceObject;

    get serviceObject() {
        return this._serviceObject;
    }

    set serviceObject(value) {
        this._serviceObject = value;
        this._checkServiceAdditionalPromoProduct();
    }

    public get productBoxes() {
        return this._productBoxes;
    }

    public set productBoxes(value) {
        this._productBoxes = value;

        if ([undefined, null].indexOf(value) >= 0) {
            return;
        }

        this.displayMissingUserRightsError = (value.length === 0);

        this.showSelectedItems(value);
        this._checkServiceAdditionalPromoProduct();
    }

    public get showProductSelect() {
        if (
            Array.isArray(this._productBoxes)
            && this._productBoxes.length === 1
            && this._productBoxes[0].family === 'managed-nextcloud'
        ) {
            if ([undefined, null].indexOf(this.selectedProductFamily) >= 0) {
                this.selectedProductFamily = this._productBoxes[0];
            }
            this.showFamilySelection = false;
        }
        return [undefined, null].indexOf(this.selectedProductFamily) < 0
            && this.selectedProductFamily.products !== undefined;
    }

    private _checkServiceAdditionalPromoProduct = () => {
        this.promoProductItems = [];

        if ([undefined, null].indexOf(this.serviceObject) >= 0) {
            return;
        }

        switch (this.serviceObject.service) {
            case 'webhosting':
                return this._getWebspacePromoProduct();
        }
    };

    private _getWebspacePromoProduct = () => {
        if ([undefined, null].indexOf(this.serviceObject.display.machine) < 0
            && [undefined, null].indexOf(this.serviceObject.display.pool) < 0
        ) {
            return;
        }
        this.promoProductItems = AuthContextService.isGrantedAll([UiRights.BIL_BUNDLE_CREATE, UiRights.WEB_OBJECT_LIST])
            ? [{
                buttonText: /* translationID */ 'TR_220119-e04f22_TR',
                description: /* translationID */ 'TR_220119-523645_TR',
                route: 'bundle.new',
                routeParams: {},
                title: /* translationID */ 'TR_220119-ea7aa5_TR'
            }]
            : [];
    };

    private showSelectedItems = (productBoxes) => {
        if ([undefined, null].indexOf(productBoxes) >= 0 || productBoxes.length < 1) {
            this._resetSelections();
            return;
        }

        if (
            [undefined, null].indexOf(this.selectedProduct) >= 0
            || [undefined, null].indexOf(this.selectedProduct.family) >= 0
        ) {
            this._resetSelections();
            return;
        }

        const boxesInFamily = this.productBoxes.filter((box) => box.family !== this.selectedProduct.family);

        for (const box of boxesInFamily) {
            if (!box.hasOwnProperty('products')) {
                this.selectedBox = box;

                continue;
            }

            this.selectedProductFamily = box;

            if ([undefined, null].indexOf(this.selectedProduct.selectedPrice) < 0) {
                continue;
            }

            box.products.some(
                (product) => product.prices.some(
                    (price) => {
                        if (
                            [undefined, null].indexOf(this.selectedProduct.selectedPrice) < 0
                            && price.productCode === this.selectedProduct.selectedPrice.productCode
                        ) {
                            this.selectedBox = product;
                            return true;
                        }

                        return false;
                    }
                )
            );
        }
    };

    private _resetSelections = () => {
        if (
            [undefined, null].indexOf(this.serviceObject) >= 0
            || (
                [undefined, null, false].indexOf(this.serviceObject.settings.comesFromSummaryPage) >= 0
                && [undefined, null, false].indexOf(this.serviceObject.settings.doNotResetSelectedProduct) >= 0
            )
        ) {
            this.selectedBox = null;

            if (this.productBoxes && this.productBoxes.length === 1) {
                this.selectedProductFamily = this.productBoxes[0];
            } else {
                this.selectedProductFamily = null;
            }
        }
    };
}

export class MoleculePanelProductSelectComponent implements ng.IComponentOptions {
    public bindings = {
        productBoxes: '<',
        productFamilySelectTitle: '@',
        productSelectTitle: '@',
        selectedProduct: '=',
        serviceObject: '<'
    };
    public template = require('./panel-product-select.html');
    public controller =  MoleculePanelProductSelectController;
}
