import * as ng from 'angular';

export class OrganNewPanelMachinePoolNewController {
    public static $inject: string[] = [];

    public cancel: any;
    public $newFormOrganism: any;
    public panelRight: any;
}

export class OrganNewPanelMachinePoolNewComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<'
    };
    public require = {
        $newFormOrganism: '^organismNewFormPool'
    };
    public controllerAs = '$newPanelOrgan';
    public template = require('./machine-pool-new.html');
    public controller = OrganNewPanelMachinePoolNewController;
}
