import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import { AccountModelService, AuthContextService } from '../../../../../services';

export class OrganNewPanelDdnsHostUserController {
    public static $inject: string[] = ['$timeout', '$translate', 'accountModel', 'authContext'];

    public user: any;
    public password: string;
    public availableUsers: any;
    public accessType = 'newUser';
    public chosenDdnsUserId: string;
    public hasSubaccounts = false;
    public showAccountSelector: boolean;
    public accessHeadings: string[] = [];
    public accessTypeButtons: any[] = [];

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService,
        private authContext: AuthContextService
    ) {
        if (this.authContext.isGranted(UiRights.ACC_SUBACCOUNT_LIST)) {
            this.accountModel.listSubaccountsWithoutPagination(1, 1,
                { field: 'AccountId', value: this.authContext.user.accountId }
            )
            .then(
                (result) => this.hasSubaccounts = result.data.length > 0
            );
        }
    }

    public $onInit() {
        this.showAccountSelector = this.showAccountSelector || false;
        this.accessHeadings = [
            this.$translate.instant('TR_110119-76b4ba_TR'),
            this.$translate.instant('TR_100119-e937e9_TR')
        ];
        this.accessTypeButtons = [
            {value: 'existingUser', label: this.$translate.instant('TR_110119-d043dc_TR')},
            {value: 'newUser', label: this.$translate.instant('TR_110119-b61be5_TR')}
        ];
        if (this.availableUsers.length > 0) {
            this.accessType = 'existingUser';
        }
    }

    public switchedFormMode = (newMode: string) => {
        if (newMode === 'newUser') {
            this.chosenDdnsUserId = '';
        }
    };
}

export class OrganNewPanelDdnsHostUserComponent implements ng.IComponentOptions {
    public bindings = {
        availableUsers: '=',
        chosenDdnsUserId: '=',
        password: '=',
        showAccountSelector: '<?',
        user: '='
    };
    public template = require('./ddns-host-user.html');
    public controller = OrganNewPanelDdnsHostUserController;
}
