import * as ng from 'angular';
import { Contingent } from '@/atomic-components/organs';
import { DatabaseUserModelService, ProductHelperService, ProductsModelService } from '@/services';
import { Reducer } from '@/services/helpers/property-reducer';
import * as Types from '@/types';
import { ViewTypes } from '@/types/view-types';

export class OrganismDatabaseWizardProductConfigDatabaseController {
    public static $inject: string[] = ['databaseUserModel', 'productsModel'];

    public metadata: ViewTypes.ProductConfigDatabaseObject;
    public webspaceExistingUsers: { value: string; name: string }[] = [];
    public databaseExistingUsers: { value: string; name: string }[] = [];
    public vhostValidationErrors: string[] = [];
    public service = 'database';
    public productCodeList: string[];

    public defaultDatabaseStorageQuota = 512;
    public storageStepSize = 512;

    public formCompleted = {
        databaseData: false,
        userData: false
    };

    private _account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    private _selectedContingent: Contingent;
    private _selectedProductCode: string;

    constructor(
        private databaseUserModel: DatabaseUserModelService,
        private productsModel: ProductsModelService
    ) {}

    public $onInit = () => {
        this._setMetadata();
    };

    get account(): Types.AccountApi.Account | Types.AccountApi.Subaccount {
        return this._account;
    }

    set account(value: Types.AccountApi.Account | Types.AccountApi.Subaccount) {
        if (value === this._account) {
            return;
        }

        if (value && this.metadata && this.metadata.account) {
            this.metadata.account = value;
        }

        this._account = value;

        if (!this._account) {
            return;
        }

        this.databaseUserModel.listWithoutPagination(
            null,
            null,
            { field: 'accountId', value: this._account.id }
        )
        .then((users) => {
            this.databaseExistingUsers = users.data.map(
                (user) => ({
                    name: user.name,
                    value: user.id
                })
            );
        });
    }

    public set configCompleted({}) {}   // tslint:disable-line:no-empty
    public get configCompleted() {
        return Object.keys(this.formCompleted).every((key) => this.formCompleted[key]);
    }

    public get selectedContingent() {
        return this._selectedContingent;
    }

    public set selectedContingent(contingent: Contingent) {
        this._selectedContingent = contingent;

        if (this.metadata) {
            this.metadata.contingent = contingent;
        }
    }

    public get selectedProductCode(): string {
        return this._selectedProductCode;
    }
    public set selectedProductCode(value: string) {
        if (this._selectedProductCode === value) {
            return;
        }

        this._selectedProductCode = value;

        if (this.metadata !== undefined) {
            this.metadata.productCode = value;
        }

        if ([undefined, null, ''].indexOf(value) >= 0) {
            return;
        }

        this.productsModel.findOne(value)
        .then(
            (result: Types.UI.SynchronousAPIResponse<Types.ProductApi.AbstractProduct>) => {
                if (this._selectedProductCode !== value) {
                    return;
                }

                const includedStorage = ProductHelperService.wrapSpecificationItems(
                    result.response.specificationItems
                ).includedStorage;

                if (includedStorage !== undefined) {
                    this.defaultDatabaseStorageQuota = includedStorage.intValue;
                }
            }
        );
    }

    public databaseDataChanged = () => {
        this.formCompleted.databaseData = Reducer.len(this.metadata.databaseName) > 0;
    };

    private _setCurrentProductCodeList = () => {
        this.productCodeList = [this.metadata.productCode]
        .concat(
            Array(this.metadata.additionalStorageItems).fill(this.metadata.storageProduct.productCode)
        );
    };

    private _setMetadata = () => {
        // Needed because 'this' behaves differently in getters/setters defined like below.
        const $ctrl = this;
        let storageProductCode: '';
        let storageProductValue: '512';
        let additionalStorageItems: 0;

        /**
         * Just in case somebody gets confused - like myself, for example:
         * This object is filled by the storage quota changer.
         *
         * ... Which is kinda stupid. Why do we define the step size from outside the
         * storage quota changer, if it's actually the component fetching the storage product?
         *
         * Meh. Philosophical question for another day.
         */
        const storageProduct: any = {
            get productCode() { return storageProductCode; },
            set productCode(value) {
                storageProductCode = value;
                $ctrl._setCurrentProductCodeList();
            },

            get value() { return storageProductValue; },
            set value(newValue) {
                storageProductValue = newValue;
                $ctrl.storageStepSize = parseInt(newValue, 10);
            }
        };

        this.metadata = {
            accesses: {
                password: '',
                useCustom: false,
                customAccesses: {
                    database: {
                        userId: '',
                        accessType: ViewTypes.UserAccessType.new,
                        name: '',
                        comment: '',
                        password: '',
                        accessLevel: {
                            read: true,
                            write: true,
                            schema: true
                        }
                    }
                }
            },
            account: this._account,
            get additionalStorageItems() { return additionalStorageItems; },
            set additionalStorageItems(value) {
                additionalStorageItems = value;
                $ctrl._setCurrentProductCodeList();
            },
            comment: '',
            contingent: this._selectedContingent,
            databaseName: '',
            productCode: this._selectedProductCode,
            productFamily: '',
            storageProduct: storageProduct,
            storageQuota: 512
        };
    };
}

export class OrganismDatabaseWizardProductConfigDatabaseComponent implements ng.IComponentOptions {
    public bindings = {
        account: '<account',
        configCompleted: '=configCompleted',
        metadata: '=',
        productCodeList: '=',
        selectedContingent: '<',
        selectedProductCode: '='
    };

    public template = require('./database-wizard-product-config-database.html');
    public controller = OrganismDatabaseWizardProductConfigDatabaseController;
}
