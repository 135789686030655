import * as ng from 'angular';
import * as q from 'q';

import {
    AccountModelService,
    AuthContextService,
    DomainCheckerService,
    DomainHelperService,
    PriceCacheService,
    ValidateDns
} from '../../../../../services';

import * as Types from '../../../../../types';

export interface WizardCheckDomainObject {
    check: Types.DomainApi.DomainStatusResult;
    status: string;
    authCode: string;
    domainNameUnicode: string;
}

export class OrganCreateConfigurationBundleDomainRegisterTransferController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'accountModel',
        'domainChecker'
    ];

    public domainObject: WizardCheckDomainObject;
    public disabled = false;
    public reset = false;
    public hasDefaultContacts = true;
    public validationErrors = [];
    public vHostOnly: boolean;

    private _accountId: string | null;
    private _authCode: string;
    private _reset = false;
    private _domainStatusLoading = false;
    private _domainName: string;
    private _possibleDomainStatus = ['available', 'registered'];
    private _registerOrTransfer = false;
    private _defaultContact = '';
    private _domainNameValidator;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService,
        private domainChecker: DomainCheckerService
    ) {
        this._domainNameValidator = new ValidateDns(this.$translate, false);
    }

    public $onInit() {
        this._reset = this.reset;

        if (this.reset) {
            this.disabled = false;
        }

        this.vHostOnly = true;

        if ([undefined, null].indexOf(this.domainObject) >= 0
            || Object.keys(this.domainObject).length === 0) {
            this._setDomainObject();
        } else if ([undefined, null].indexOf(this.domainObject) < 0) {
            if (this.domainObject.domainNameUnicode !== this._domainName) {
                this._resetDomainObject();
            } else if (this._possibleDomainStatus.indexOf(this.domainObject.status) >= 0) {
                this.registerOrTransfer = true;
            }
        }
    }

    public $onChanges(changes: any) {
        if (changes._accountId !== undefined) {
            this._checkDefaultContacts(changes._accountId.currentValue);
            this._checkDomainStatus();
        }
    }

    public $doCheck() {
        if (this.reset !== this._reset) {
            if (this.reset) {
                this._resetDomainObject();
            }

            this._reset = ng.copy(this.reset);
        }
    }

    get domainName(): string {
        return this._domainName;
    }

    set domainName(value: string) {
        if (value !== this._domainName) {
            this._resetDomainObject();
        }

        this._domainName = value;
    }

    get registerOrTransfer(): boolean {
        return this._registerOrTransfer;
    }

    set registerOrTransfer(value: boolean) {
        this.vHostOnly = !value;
        this._registerOrTransfer = value;
    }

    get authCode(): string {
        return this._authCode;
    }

    set authCode(value: string) {
        if (this._authCode !== value) {
            if (this.domainObject === undefined) {
                this._setDomainObject();
            }

            this.domainObject.authCode = value;
        }

        this._authCode = value;
    }

    public get completed() {
        if (
            [undefined, null].indexOf(this.domainObject) >= 0
            || this.registerOrTransfer === false
        ) {
            return true;
        }
        return !(
            this.authCodeNeeded
            && this.domainObject.authCode.length < 0
        );
    }

    public set completed({}) {} // tslint:disable-line:no-empty

    public get toggleDisabled() {
        const domainNameValidation = this._domainNameValidator.validate(this._domainName);
        if (domainNameValidation !== undefined
            && domainNameValidation.length > 0
        ) {
            return true;
        }

        return this.disabled
            || this._domainStatusLoading
            || !this.hasDefaultContacts
            || this.validationErrors.length > 0;
    }

    public set toggleDisabled({}) {} // tslint:disable-line:no-empty

    public get checkingDomainStatus() {
        return this._domainStatusLoading;
    }

    public set checkingDomainStatus({}) {} // tslint:disable-line:no-empty

    public get authCodeNeeded() {
        return this.registerOrTransfer
            && [undefined, null].indexOf(this.domainObject) < 0
            && this.domainObject.status === 'registered';
    }

    public get hasCheckedStatus() {
        return this.hasDefaultContacts
            && [undefined, null].indexOf(this.domainObject) < 0
            && this.domainObject.status !== '';
    }

    public set hasCheckedStatus({}) {} // tslint:disable-line:no-empty

    public changeActionToggle = () => {
        this.$timeout(() => {
            if (this.registerOrTransfer) {
                this._checkDomainStatus();
            } else {
                this._resetDomainObject();
            }
        });
    };

    private _checkDomainStatus = () => {
        if ([undefined, null, ''].indexOf(this._domainName) >= 0
            || [undefined, null].indexOf(this._accountId) >= 0
        ) {
            this._domainStatusLoading = false;
            return;
        }

        if ([undefined, null].indexOf(this.domainObject) < 0
            && this.domainObject.domainNameUnicode === this._domainName
        ) {
            // domainObject is already set and thus domain has already been checked
            this._domainStatusLoading = false;
            return;
        }

        if (this._domainStatusLoading) {
            return;
        }

        this._domainStatusLoading = true;
        // this.domainObject = null;
        this._setDomainObject();
        // this.validationErrors = [];

        this._checkDefaultContacts()
            .then(() => this.domainChecker.check([this._domainName], this._accountId))
            .then((domainList) => {
                this._domainStatusLoading = false;
                const domainStatus = domainList.length > 0
                    ? domainList[0]
                    : null;
                // is domain check unequal null
                if (domainStatus !== null) {
                    // domain check status is within possible domain status?
                    if (this._possibleDomainStatus.indexOf(domainStatus.status) >= 0) {
                        // set domainObject
                        this._buildDomainObject(domainStatus);
                        this.disabled = false;
                    } else {
                        this.registerOrTransfer = false;
                        this.disabled = true;
                        this.domainObject.check = ng.copy(domainStatus);
                        this.domainObject.status = ng.copy(domainStatus.status);
                    }
                }
            }
        );
    };

    private _checkDefaultContacts = (accountId?: string) => {
        accountId = accountId || this._accountId;
        if (accountId !== undefined) {
            return this._getAccount(accountId).then(
                (account) => {
                    const contactType = 'defaultContactOwnerId';

                    if ([undefined, null, ''].indexOf(account.domainSettings[contactType]) < 0
                        && typeof account.domainSettings[contactType] === 'string'
                        && account.domainSettings[contactType].length >= 10
                    ) {
                        this.hasDefaultContacts = true;
                        this._defaultContact = account.domainSettings[contactType];
                    } else {
                        this.hasDefaultContacts = false;
                        this._defaultContact = '';
                    }

                    if (!this.hasDefaultContacts) {
                        this.disabled = true;
                        this.registerOrTransfer = false;
                    }
                }
            ) as ng.IPromise<Types.AccountApi.Subaccount>;
        }
    };

    private _getAccount = (accountId: string): PromiseLike<any> => {
        const account = AuthContextService.account.id === accountId
            ?   AuthContextService.account
            : null;

        if (account === null) {
            return this.accountModel.findOne(accountId);
        }

        // It's actually Account, not Subaccount, but that's a TODO for another day...
        return q.resolve(account) as any as q.IPromise<Types.AccountApi.Subaccount>;
    };

    private _buildDomainObject = (domainStatus: any) => {
        this.$timeout(
            () => {
                this._setDomainObject({
                    check: ng.copy(domainStatus),
                    authCode: '',
                    domainNameUnicode: domainStatus.domainNameUnicode,
                    status: ng.copy(domainStatus.status)
                });
            }
        );
    };

    private _setDomainObject = (object?: WizardCheckDomainObject) => {
        this.domainObject = object || {
            check: undefined,
            authCode: '',
            domainNameUnicode: '',
            status: ''
        };
    };

    private _resetDomainObject = () => {
        this._reset = this.reset = false;
        this.disabled = false;
        this.hasCheckedStatus = false;
        this.registerOrTransfer = false;
        this._setDomainObject();
        this.authCode = '';
        this._checkDefaultContacts();
    };
}

export class OrganCreateConfigurationBundleDomainRegisterTransferComponent implements ng.IComponentOptions {
    public bindings = {
        _accountId: '<accountId',
        authCode: '=',
        completed: '=',
        disabled: '<',
        domainName: '<domainName',
        reset: '=?',
        validationErrors: '=',
        vHostOnly: '='
    };

    public template = require('./register-transfer-config.html');
    public controller =  OrganCreateConfigurationBundleDomainRegisterTransferController;
}
