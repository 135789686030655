import * as ng from 'angular';
import { AlertManagerService } from '../../../../../../services';
import { OrganismOverviewController } from '../../../../../organisms/overview';

export class MoleculePanelOverviewHeaderMultiRestoreActionController implements ng.IController {
    public static $inject: string[] = ['alertManager', '$timeout', '$translate'];

    public OrganismOverview: OrganismOverviewController;
    public confirmHint = false;
    public objectType: string;
    public serviceName: string;

    constructor(
        private alertManager: AlertManagerService,
        private $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public abort = () => {
        this.OrganismOverview.overviewModel.activeMassAction = false;
    };

    public submit = () => {
        this.OrganismOverview.submitMassAction(
            {
                methode: 'restoreSubmit'
            }
        // todo - show request message in view
        ).then(
            (result) => {
                if (result) {
                    this.alertManager.success(this.$translate.instant('TR_100119-ed446b_TR'));
                    this.abort();
                    this.$timeout(() => {
                        this.OrganismOverview.overviewLoad();
                    }, 1000);
                }
            },
            (e) => {
                console.error('error', e);
            }
        );
    };

    public getTemplate = () => {
        const templatePartName = (
                [null, undefined].indexOf(this.objectType) < 0
                && this.serviceName.toLowerCase() !== this.objectType.toLowerCase()
            )
            ? this.serviceName.toLowerCase() + '-' + this.objectType.toLowerCase()
            : this.serviceName.toLowerCase()
        ;

        return require('./multi-restore-' + templatePartName + '-action.html?external');
    };
}

export class MoleculePanelOverviewHeaderMultiRestoreActionComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        objectType: '<?',
        serviceName: '<'
    };
    public controller =  MoleculePanelOverviewHeaderMultiRestoreActionController;
    public template = require('./multi-restore-action.html');
}
