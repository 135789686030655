import * as ng from 'angular';

export class MoleculeInformationDnsAdjustmentsController implements ng.IController {
    public static $inject: string[] = [];

    public checkedValue: boolean;
    public hideCheckbox = false;
    public informationText: string;
    public showDetails: boolean;

    private _showDetailsOnStart = false;

    public $onInit() {
        this.showDetails = this._showDetailsOnStart !== undefined
            ? ng.copy(this._showDetailsOnStart)
            : false;
    }

    public toggleDetailsShowDetails = () => {
        this.showDetails = !this.showDetails;
    };

    public informationTextClick = () => {
        if (this.checkedValue !== undefined) {
            this.checkedValue = !this.checkedValue;
        }
    };
}
export class MoleculeInformationDnsAdjustmentsComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        _showDetailsOnStart: '<showDetailsOnStart',
        checkedValue: '=',
        hideCheckbox: '<?',
        informationText: '@'
    };
    public controller = MoleculeInformationDnsAdjustmentsController;
    public template = require('./dns-adjustments.html');
}
