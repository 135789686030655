import * as ng from 'angular';

import { UIRegExp } from '@/configuration';

import { ValidateIpv6 } from '@/services/index';
import { Validator } from '@/services/validators/abstract-validator';

export class ValidateHostname extends Validator {
    private allowEmpty: boolean;
    private validateIpv6: ValidateIpv6;

    constructor(
        private $translate: ng.translate.ITranslateService,
        allowEmpty?: boolean
    ) {
        super();
        if (allowEmpty === undefined || allowEmpty == null || allowEmpty) {
            this.allowEmpty = true;
        } else {
            this.allowEmpty = false;
        }
        this.validateIpv6 = new ValidateIpv6(this.$translate, this.allowEmpty);
    }

    public validate = (value: string): { text: string }[] => {
        const reply: { text: string }[] = [];

        if (this.allowEmpty && !value.length) {
            return reply;
        } else if (value.length === 0) {
            reply.push({ text: this.$translate.instant('TR_091019-8eb03e_TR') });
        // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
        } else if (value.match(UIRegExp.IsHostname) === null) {
            // ipv6 support
            if (value.indexOf('[') === 0 && value.indexOf(']') + 1 === value.length) {
                const sub = value.substring(value.indexOf('[') + 1, value.indexOf(']'));
                return this.validateIpv6.validate(sub);
            }
            reply.push({ text: this.$translate.instant('TR_091019-2c74a7_TR') });
        }
        return reply;
    };
}
