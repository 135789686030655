import * as ng from 'angular';
import { LinkListOverviewItem } from '../../atomic-components/molecules/links/link-list-overview/link-list-overview';
import {
    AccountFilterField, SelectFilterField,
    TextFilterField
} from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { UiRights } from '../../configuration';
import { AuthContextService, CacheService, StorageModelService } from '../../services';
import { OverviewModel } from '../abstract';
import { RequestCancellerService } from '../request-canceller';

export class StorageOverviewModel extends OverviewModel {

    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', '$state', 'cache', 'storageModel']
    );

    public readonly service = 'storage';
    public readonly objectType = 'Nextcloud';
    public readonly idField = 'nextcloudId';
    public readonly defaultFilterFields = ['NextcloudName'];

    public activeMassAction: string | boolean = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};

    protected listCallbacks = [];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private $state: ng.ui.IStateService,
        private cache: CacheService,
        private storageModel: StorageModelService
    ) {
        super(RequestCanceller);

        this.listCallbacks = [{
            callback: this.listNextclouds
        }];
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField(
                    'NextcloudName',
                    this.$translate.instant('b92f0fd3-676e-4fcc-a09d-2b6405cb97fe')
                ),
                new TextFilterField('NextcloudId', this.$translate.instant('TR_210219-b8d6cb_TR')),
                new SelectFilterField(
                    'NextcloudStatus',
                    this.$translate.instant('TR_140119-8e4b60_TR'),
                    [
                        {
                            name: this.$translate.instant('35b2882a-2c6b-4291-b7de-963835933109'),
                            value: 'active'
                        },
                        {
                            name: this.$translate.instant('VM.STATUS.CREATING'),
                            value: 'creating'
                        },
                        {
                            name: this.$translate.instant('VM.STATUS.DELETING'),
                            value: 'deleting'
                        },
                        {
                            name: this.$translate.instant('GENERAL.OBJECT.STATUS.RESTORABLE'),
                            value: 'restorable'
                        }
                    ]
                ),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR'))
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }

    public actionLinks = (): LinkListOverviewItem[] => {
        const stateInNextcloud = this.$state.current.name.split('.')[0] === 'nextcloud';
        return [
            {
                isGranted: AuthContextService.isGrantedAny([
                    UiRights.MANAGED_APPLICATION_JOBS_LIST
                ]),
                linkList: [
                    {
                        group: 'redirect',
                        isGranted: AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_JOBS_LIST),
                        route: stateInNextcloud ? 'nextcloud.jobs' : 'storage.storage-products.jobs',
                        text: this.$translate.instant('MENU.CONTEXT.JOBS')
                    }
                ],
                route: '',
                text: this.$translate.instant('TR_100119-a22157_TR'),
                type: 'multi'
            },
            {
                isGranted: AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_CREATE),
                route: stateInNextcloud ? 'nextcloud.new' : 'storage.storage-products.new',
                text: this.$translate.instant('TR_150519-4b823f_TR')
            }
        ] as LinkListOverviewItem[];
    };

    public listNextclouds = (limit?, page?, filters?, sort?, cancel?) => {
        this.cache.get('managedapplication::nextcloudsFind').clear();
        return this.storageModel.listNextclouds(limit, page, filters, sort, cancel);
    };
}
