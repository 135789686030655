import * as ng from 'angular';

import './drop-down.scss';

export interface FormDropDownItems {
    value: any;
    name?: string;
    names?: string[];
    translationId?: string;
}

export class MoleculeFormDropDownController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public showOptions = false;
    public items: FormDropDownItems[] = [];
    public disabled: boolean;
    public model: any;
    public popoverModifier: string;
    public overviewLoad: (selection: any) => any;
    public placeholder: string;
    public defaultOption: any;
    public callbackOnChange: (arg0: any) => void;
    public sizeModifier: string;
    public buttonModifier: string;
    public popoverPosition: string;
    public readonly = false;
    public inputId: string;
    public autofill: boolean;
    public clearable: boolean;

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.disabled = this.disabled || false;
        this.readonly = this.readonly || false;
        if (
            !this.selectionIsValid(this.model)
                && this.selectionIsValid(this.defaultOption)
                && this.defaultOption !== undefined
        ) {
            this.model = this.defaultOption;
        }
    }

/*     public onChange(): void {
        if (this.callbackOnChange !== undefined) {
            this.callbackOnChange(this.model);
        }
    } */

    public inputClick = () => {
        if (!this.showOptions) {
            this.toggleOptions();
        }
    };

    public escCallback = (event: KeyboardEvent) => {
        this.toggleOptions();
        (event.target as HTMLElement).blur();
    };

    public outerElementClick() {
        this.toggleOptions();
    }

    public clear = () => {
        this.model = undefined;
        this.showOptions = false;
        // this.onChange();
        if (this.overviewLoad !== undefined) {
            this.overviewLoad(undefined);
        }
    };

    public selectOption(item) {
        this.model = item.value;
        this.toggleOptions();
        // this.onChange();
        if (this.overviewLoad !== undefined) {
            this.overviewLoad(item.value);
        }
    }

    public toggleOptions() {
        if (!this.disabled && !this.readonly) {
            this.showOptions = !this.showOptions;
        }
    }

    private selectionIsValid = (value: any) => {
        if (this.items === null || this.items === undefined) {
            return false;
        }
        for (const item of this.items) {
            if (item.value === value) {
                return true;
            }
        }
        return false;
    };

    public get selectedOption() {
        if (this.items === undefined) {
            return false;
        }
        return this.items.filter((item) => String(item.value) === String(this.model))[0];
    }

    // empty and with no functionallity setter
    public set selectedOption(value) { return; }

    public get getPlaceholder() {
        return this.placeholder ? this.placeholder : this.$translate.instant('BUNDLE.DELETE.DOMAIN-ACTION.CHOOSE');
    }
}

export class MoleculeFormDropDownComponent implements ng.IComponentOptions {
    public bindings = {
        autofill: '<',
        buttonModifier: '@?',
        callbackOnChange: '<',
        clearable: '<?',
        defaultOption: '<',
        disabled: '<',
        inputId: '@?',
        items: '<',
        model: '=',
        overviewLoad: '<',
        placeholder: '@',
        popoverModifier: '@?',
        popoverPosition: '@',
        readonly: '<',
        sizeModifier: '@?'
    };
    public controller =  MoleculeFormDropDownController;
    public template = require('./drop-down.html');
}
