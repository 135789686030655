import * as ng from 'angular';
import './button-login.scss';

export class MoleculeButtonLoginController implements ng.IController {
    public static $inject: string[] = [];

    public inProgress: boolean;
    public onClickCallback: () => void;
    public loading = false;
    public disabled = false;
    public text: string;

    public $onChanges(changes) {
        if (changes.inProgress !== undefined) {
            this.loading = changes.inProgress.currentValue;
        }
    }

    public login = () => {
        if (this.onClickCallback !== undefined && !this.isDisabled) {
            this.onClickCallback();
        }
    };

    public get isDisabled() {
        if (this.disabled || this.loading) {
            return true;
        }

        return false;
    }
}

export class MoleculeButtonLoginComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        disabled: '<',
        inProgress: '<',
        onClickCallback: '<',
        text: '@?'
    };
    public controller =  MoleculeButtonLoginController;
    public template = require('./button-login.html');
}
