import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';

export class TemplateWebhostingUserNewController {
    public static $inject: string[] = ['$translate', 'authContext'];

    public modifier = '';
    public pageHeaderData: PanelHeaderData;

    public userPanelRight;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            panelHeaderRoute: '',
            panelIcon: 'user',
            panelTitle: this.$translate.instant('TR_140119-4eb211_TR'),
            backwardText: this.$translate.instant('TR_140119-f37dd1_TR'),
            backwardLink: 'webhosting.users.overview'
        };

        this.userPanelRight = {
            general: {
                editPanelButton: this.authContext.isGranted(UiRights.WEB_USER_CREATE)
            }
        };
    };
}

export class TemplateWebhostingUserNewComponent implements ng.IComponentOptions {
    public template = require('./webhosting-user-new-template.html');
    public controller = TemplateWebhostingUserNewController;
    public controllerAs = '$TemplateWebhostingUserNewCtrl';
}
