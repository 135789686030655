import * as ng from 'angular';

export class DomainDeletionType {
    public static $inject: string[] = ['$translate'];

    public static Factory(
        $translate: ng.translate.ITranslateService
    ) {
        return (value: any) => {
            switch (value) {
                case 'delete':
                    return $translate.instant('DOMAIN.CANCEL-DELETE.TYPE.DELETE');
                case 'withdraw':
                    return $translate.instant('DOMAIN.CANCEL-DELETE.TYPE.WITHDRAW');
                default:
                    return value;
            }
        };
    }
}
