import ng from 'angular';
import { transitEnabledTlds } from '@/configuration';
import {
    AlertManagerService,
    DateWrapper,
    DomainHelper,
    DomainModelService,
    NavigationService
} from '@/services';
import { EditPanelStatus } from '@/atomic-components/molecules/forms';

export class OrganEditPanelDomainProductDeleteController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'alertManager',
        'domainModel',
        'navigation'
    ];

    public domain: any;
    public $editFormMolecule;
    public initialStatus: EditPanelStatus;
    public deletionConfirmed = false;
    public deletionType = 'immediate';
    public deletionDate: DateWrapper;
    public deletionTypeItems: any[] = [];
    private _isTransitCancellation = false;
    private _disconnectOnTransit = false;
    private transitTlds: string[] = transitEnabledTlds;

    constructor(
        private $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private domainModel: DomainModelService,
        private navigation: NavigationService
    ) {}

    public $onInit(): void {
        this.deletionTypeItems = [{
            label: this.$translate.instant('8c45521c-005d-4867-bcf0-f0ef38b03e48'),
            value: 'immediate'
        }, {
            label: this.$translate.instant('TR_110119-0c8d3a_TR'),
            value: 'endOfContract'
        }, {
            label: this.$translate.instant('TR_110119-69fa60_TR'),
            value: 'date'
        }];

        this.$editFormMolecule.deleteCallback = this.delete;
        this.initialStatus = this.domain.deletionScheduledFor !== null
            ? EditPanelStatus.DELETIONSCHEDULED : EditPanelStatus.READONLY;
    }

    get isTransitCancellation(): boolean {
        return this._isTransitCancellation;
    }

    set isTransitCancellation(value: boolean) {
        this._isTransitCancellation = value;
        this.disconnectOnTransit = false;
        this.deletionConfirmed = value && !this.disconnectOnTransit;
    }

    get disconnectOnTransit(): boolean {
        return this._disconnectOnTransit;
    }

    set disconnectOnTransit(value: boolean) {
        this.deletionConfirmed = !value;
        this._disconnectOnTransit = value;
    }

    get isTransitTld(): boolean {
        if (DomainHelper.hasTld(this.domain.name)) {
            return this.transitTlds.indexOf(DomainHelper.getTld(this.domain.name)) >= 0;
        }
        return false;
    }

    public get enableDeletion() {
        return this.deletionConfirmed && (this.deletionType !== 'date' || this.deletionDate !== undefined);
    }

    public cancel = () => {
        this.deletionConfirmed = false;
        this._disconnectOnTransit = false;
        this._isTransitCancellation = false;
    };

    public delete = () => {
        if (this.isTransitCancellation) {
            this.domainModel.withdraw(
                this.domain,
                this.disconnectOnTransit,
                this.deletionDate.isToday() ? null : this.deletionDate.dateObj
            ).then(
                () => {
                    this.alertManager.success(this.$translate.instant('TR_100119-086ce0_TR'));
                    this.navigation.go('domain.domains.id.edit', {domainId: this.domain.id}, { reload: true });
                }
            );
        } else {
            this.domainModel.delete(
                this.domain,
                this.deletionDate.isToday() ? null : this.deletionDate.dateObj
            ).then(
                () => {
                    this.alertManager.success(this.$translate.instant('TR_100119-086ce0_TR'));
                    this.$timeout(() => {
                        this.navigation.go('domain.dashboard', {domainId: this.domain.id}, { reload: true });
                    }, 500);
                }
            );
        }
    };
}

export class OrganEditPanelDomainProductDeleteComponent implements ng.IComponentOptions {
    public bindings = {
        domain: '<',
        initialStatus: '<'
    };
    public require = {
        $editFormMolecule: '^moleculeFormEdit'
    };
    public controller = OrganEditPanelDomainProductDeleteController;
    public template = require('./domain-product-delete.html');
}
