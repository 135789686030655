import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { DisableFilter } from '../../../atomic-components/organisms/overview/overview';

export class TemplateEmailJobOverviewController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;
    public bundle;
    public overviewServiceData;
    public expertView: boolean;
    public disableFilter: DisableFilter;

    public overviewModel;
    private simpleFilter;
    private extendedFilters;
    private overviewUiSettings;
    private comeFrom;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit() {
        this.expertView = true; // only expertview on job overviews
        this.disableFilter = {
            fancyFilter: false,
            searchFilter: false
        };

        if (this.bundle === undefined) {
            this.bundle = null;
        }

        this.pageHeaderData = {
            backwardLink: this.comeFrom !== 'products'
                ? 'bundle.id.dashboard'
                : 'email.dashboard',
            backwardText: this.comeFrom !== 'products'
                ? this.$translate.instant('TR_090119-a32485_TR')
                : this.$translate.instant('TR_110119-c8ec44_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: this.comeFrom !== 'products'
                ? {bundleId: this.bundle.id}
                : null,
            panelIcon: 'envelope',
            panelTitle: this.$translate.instant('TR_110119-530cc2_TR')
        };

        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewService: 'emailJob',
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter,
            sort: {
                field: 'jobAddDate',
                order: 'DESC'
            }
        };
    }
}

export class TemplateEmailJobOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<',
        comeFrom: '<',
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public controller = TemplateEmailJobOverviewController;
    public controllerAs = 'TemplateEmailJobOverviewCtrl';
    public template = require('./email-mailbox-job-overview-template.html');
}
