import { AuthContextService } from './auth-context';

export class PanicMode {
    private static initialized = false;
    private static data = {
        isActive: false
    };

    /**
     * Says whether or not panic mode is currently active.
     *
     * It is active ONLY if both the flag is set and the current account is ROOT.
     */
    public static get active(): boolean {
        PanicMode.initialize();

        if (!PanicMode.data.isActive) {
            return false;
        }

        return PanicMode.data.isActive && AuthContextService.isRoot;
    }

    /**
     * Starts panic mode (sets the flag).
     */
    public static start = () => {
        PanicMode.initialize();
        PanicMode.data.isActive = true;
        PanicMode.update();
    };

    /**
     * Stops panic mode (unsets the flag).
     */
    public static stop = () => {
        PanicMode.initialize();
        PanicMode.data.isActive = false;
        PanicMode.cleanup();
    };

    private static initialize = () => {
        if (!PanicMode.initialized) {
            try {
                const fromLocalStorage = window.localStorage.getItem('PanicMode');

                if (!!fromLocalStorage && fromLocalStorage.length > 0) {
                    PanicMode.data = JSON.parse(fromLocalStorage);
                }
            }
            catch ({}) { /* tslint:disble-line:no-empty */ }

            PanicMode.initialized = true;
        }
    };

    private static update = () => {
        try {
            window.localStorage.setItem('PanicMode', JSON.stringify(PanicMode.data));
        }
        catch ({}) { /* tslint:disble-line:no-empty */ }
    };

    private static cleanup = () => {
        try {
            window.localStorage.removeItem('PanicMode');
        }
        catch ({}) { /* tslint:disble-line:no-empty */ }
    };
}
