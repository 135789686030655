import * as ng from 'angular';

import './translation-id-list.scss';

export class OrganismGlobalTranslationIdListController {
    public static $inject: string[] = ['$document', '$timeout'];

    public enablePannel = false;
    public foundTranslationIds: string[] = [];
    public showPanel = false;
    public search = '';

    constructor(private $document: ng.IDocumentService, private $timeout: ng.ITimeoutService) {
        this.enablePannel = window.location.hostname === 'ui.dev.platformadmin.de'
            || window.location.hostname.substring(window.location.hostname.length - 8) === '.keendev'
            || window.location.hostname === 'localhost'
        ;
    }

    public $onInit = () => {
        if (this.enablePannel) {
            this.$document.bind(
                'keydown',
                (event) => {
                    const keyCombinationPressed = (event.ctrlKey && event.shiftKey && event.keyCode === 76);

                    if (!keyCombinationPressed) {
                        return;
                    }

                    this.$timeout(() => {
                        this.showPanel = !this.showPanel;
                        if (this.showPanel) {
                            document.getElementById('translationIdSearch').focus();
                            Array.prototype.slice.call(document.querySelectorAll('*')).map(
                                (element) => {
                                    const match = element.innerText.match(/\u25BA.+?\u25C4/gi);
                                    if ([undefined, null, 0].indexOf(match) < 0) {
                                        match.map(
                                            (translationId) => {
                                                const plainTranslationId = translationId
                                                    .replace('\u25BA', '')
                                                    .replace('\u25C4', '');
                                                if (this.foundTranslationIds.indexOf(plainTranslationId) === -1) {
                                                    this.foundTranslationIds.push(plainTranslationId);
                                                }
                                            }
                                        );
                                    }
                                }
                            );
                        }
                    });
                }
            );
        }
    };

    public hide(): void {
        this.showPanel = false;
    }
}

export class OrganismGlobalTranslationIdListComponent {
    public controller = OrganismGlobalTranslationIdListController;

    public template = require('./translation-id-list.html');
}
