import * as Sentry from '@sentry/browser';
import * as ng from 'angular';
import * as q from 'q';
import { PanicMode } from './../../services/panic-mode';

let firstErrorLogged = false;

class RequestLogger {
    public static log = [];
    static readonly logLength = 50;
    public static recentlySeen = {};

    public static logRequest: (config: ng.IRequestConfig) => number
    = (config) => {
        try {
            const [{/* emptyString*/}, {/* apiPrefix*/}, service, {/* version*/}, {/* protocol*/}, method] =
            config.url.split('/');

            RequestLogger.log.push({
                service: service,
                method: method,
                data: config.data,
                url: window.location.href
            });

            if (RequestLogger.log.length > RequestLogger.logLength) {
                RequestLogger.log.splice(0, 1);
            }

            const hash = JSON.stringify({ url: config.url, data: config.data });

            if ([undefined, null].indexOf(RequestLogger.recentlySeen[hash]) >= 0) {
                RequestLogger.recentlySeen[hash] = 0;
            }

            RequestLogger.recentlySeen[hash] += 1;
            setTimeout(
                () => {
                    RequestLogger.recentlySeen[hash] -= 1;
                    if (RequestLogger.recentlySeen[hash] === 0) {
                        delete(RequestLogger.recentlySeen[hash]);
                    }
                },
                10000
            );

            return RequestLogger.recentlySeen[hash];
        } catch (error) {
            return -1; // Might do something useful at some later point...
        }
    };
}

export class HTTPInterceptors {
    public static RequestInterceptor: (string | ng.IHttpInterceptorFactory)[] = [
        () => ({
            request: (config: ng.IRequestConfig) => {
                if (
                    config.method === 'POST'
                    && config.url.indexOf('/api/') === 0
                    && config.url.indexOf('translater') < 0
                ) {
                    // I should really document stuff like this...
                    const deferred: q.Deferred<ng.IRequestConfig> = q.defer();

                    if (firstErrorLogged) {
                        return deferred.promise;
                    }

                    const requestRepetition = RequestLogger.logRequest(config);

                    // eslint-disable-next-line no-undef
                    if (requestRepetition >= 10 && __TEST_MODE__ !== true) {
                        if (!firstErrorLogged) {
                            console.warn('Stuck in request loop, logging to Sentry');
                            Sentry.captureMessage(
                                'A request seems to be looping',
                                {
                                    tags: {
                                        key: 'api',
                                        apiUrl: config.url
                                    },
                                    extra: {
                                        url: window.location.href,
                                        smith: 'Looping request',
                                        apiUrl: config.url,
                                        method: config.method,
                                        requestLog: RequestLogger.log,
                                        requestData: config.data
                                    }
                                }
                            );

                            setTimeout(
                                () => window.location.href = '/error/429.html',
                                1000
                            );

                            firstErrorLogged = true;
                        }

                        return deferred.promise;
                    }
                }

                return config;
            }
        })
    ];

    public static RequestErrorInterceptor: (string | ng.IHttpInterceptorFactory)[] = [
        () => ({ requestError: (rejection) => q.reject(rejection) })
    ];

    public static ResponseInterceptor: (string | ng.IHttpInterceptorFactory)[] = [
        () => ({
            response: (response) => {
                try {
                    HTTPInterceptorTools.redirectOnSmith(response);
                } catch (error) {} // tslint:disable-line:no-empty

                return response;
            }
        })
    ];

    public static ResponseErrorInterceptor: (string | ng.IHttpInterceptorFactory)[] = [
        () => ({
            responseError: (rejection) => {
                try {
                    HTTPInterceptorTools.redirectOnSmith(rejection);

                    if (PanicMode.active) {
                        return q.reject(rejection);
                    }

                    if (rejection.config.method === 'POST' && rejection.config.url.indexOf('/api/') === 0) {
                        if ([502, 504].indexOf(rejection.status) >= 0) {
                            window.location.href = '/error/502.html';
                        } else if (500 <= rejection.status && rejection.status <= 599) {
                            window.location.href = '/error/server-error.html';
                        }
                    }
                } catch (error) {} // tslint:disable-line:no-empty

                return q.reject(rejection);
            }
        })
    ];
}

export class HTTPInterceptorTools {
    public static redirectOnSmith(response) {
        const smithHeader = response.headers('x-pui-smith');

        if ([undefined, null, ''].indexOf(smithHeader) < 0) {
            window.location.href = '/error/502.html';
        }
    }
}
