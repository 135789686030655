import * as ng from 'angular';
import { DocumentHelperService } from '../../../../services';

export class MoleculeDisplayOutstandingPriceInvoiceController implements ng.IController {
    public static $inject: string[] = ['documentHelper'];

    public invoice;

    constructor(
        private documentHelper: DocumentHelperService
    ) {}

    public outstandingTotal = () => {
        return this.documentHelper.outstandingInvoicePriceTotal(this.invoice);
    };
}

export class MoleculeDisplayOutstandingPriceInvoiceComponent implements ng.IComponentOptions {
    public bindings = {
        invoice: '<'
    };
    public controller =  MoleculeDisplayOutstandingPriceInvoiceController;
    public controllerAs = '$DisplayPrice';
    public template = require('./display-outstanding-price-invoice.html');
}
