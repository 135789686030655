import * as ng from 'angular';

export class TemplateRightsTemplateNewController implements ng.IController {
    public static $inject: string[] = ['$translate'];
    public permissions: any;
    public pageHeaderData: any;

    public rightsTemplate = {
        accountId: null,
        comments: null,
        name: null,
        rights: [],
        usableForSubaccounts: true,
        usableForUsers: false
    };

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit() {
        this.pageHeaderData = {
            backwardLink: 'reseller.rightstemplates.overview',
            backwardText: this.$translate.instant('TR_090119-85645e_TR'),
            panelHeaderRoute: '',
            panelIcon: 'lock',
            panelTitle: this.$translate.instant('TR_090119-6f3355_TR')
        };
    }
}

export class TemplateRightsTemplateNewComponent implements ng.IComponentOptions {
    public bindings = {
        permissions: '<'
    };
    public template = require('./rights-template-new.html');
    public controller =  TemplateRightsTemplateNewController;
}
