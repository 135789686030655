import * as ng from 'angular';

export class MoleculeButtonBlueController implements ng.IController {
    public static $inject: string[] = [];

    public callback: () => void;
    public disabled: boolean;
    public buttonTitle: string;

    public $onInit() {
        this.buttonTitle = this.buttonTitle || '';
    }
}

export class MoleculeButtonBlueComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        buttonTitle: '@title',
        callback: '<',
        disabled: '<'
    };
    public controller =  MoleculeButtonBlueController;
    public template = require('./button-blue.html');
}
