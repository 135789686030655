import ng from 'angular';
import q from 'q';

import {
    AlertManagerService, ApiErrorModel, BundleHelperService, BundleModelService,
    CleanableStringType, DateWrapper, DomainModelService, NavigationService, StringCleaner
} from '@/services';
import { DeleteDateOptions, EditPanelRight, FormDropDownItems } from '@/atomic-components/molecules';
import { DomainApi, BundleApi, ViewTypes } from '@/types';

interface DomainDataObject {
    action: string;
    disconnect: boolean;
    domain: string;
    domainObject: DomainApi.Domain;
}

interface DomainAction {
    name: string;
    value: string;
}

export class OrganismDeleteFormBundleController {
    public static $inject: string[] = [
        '$filter',
        '$translate',
        'alertManager',
        'apiErrorModel',
        'bundleHelper',
        'bundleModel',
        'domainModel',
        'navigation'
    ];

    public originalBundle: BundleApi.Bundle;
    public bundle: BundleApi.Bundle;
    public userPanelRight: EditPanelRight;
    public deleteMode: DeleteDateOptions = DeleteDateOptions.IMMEDIATE;
    public deleteDate: DateWrapper;
    public hasAgreedToDeleteData = false;
    public hasAgreedToRestoreBundle = false;
    public selectedRestoreAction = '';

    public includedDomains: DomainDataObject[] = [];
    public additionalDomains: DomainDataObject[] = [];

    private _baseIncludedDomainActions: DomainAction[] = [];
    private _baseAdditionalDomainActions: DomainAction[] = [];

    private _domainActionsCache: FormDropDownItems[] = [];
    private _isPaidUntilExceeded: boolean;
    private _onDeletingProcess = false;
    private _maxAdditionalDomainsToDelete = 20;

    constructor(
        private $filter: ng.IFilterService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private bundleHelper: BundleHelperService,
        private bundleModel: BundleModelService,
        private domainModel: DomainModelService,
        private navigation: NavigationService
    ) {}

    public $onInit(): void {
        this.bundle = JSON.parse(JSON.stringify(this.originalBundle));
        this._getBundleIncludedDomains();
        this._baseIncludedDomainActions = [
            {
                name: this.$translate.instant('WEBHOSTING.VHOST.DELETE.TITLE'),
                value: 'delete'
            },
            {
                name: this.$translate.instant('TR_100119-c7dc88_TR'),
                value: 'detach'
            }
        ];
        this._baseAdditionalDomainActions = [
            {
                name: this.$translate.instant('TR_201119-9007ba_TR'),
                value: 'delete-at-end-of-contract'
            },
            {
                name: this.$translate.instant('TR_201119-65c8bd_TR'),
                value: 'keep'
            }
        ];
    }

    public set hasAgreedToDeleteEverything(_) {/* */}
    public get hasAgreedToDeleteEverything(): boolean {
        if (this._onDeletingProcess) {
            return false;
        }

        return this.hasAgreedToDeleteData
            && this._includedDomainsValid()
            && this._additionalDomainsValid()
            && this.deleteDate !== undefined;
    }

    public set showAdditionalDomains(_) {/* */}
    public get showAdditionalDomains(): boolean {
        return this.additionalDomains.length > 0
            && !this.showAdditionalDomainsHint
        ;
    }

    public set showAdditionalDomainsHint(_) {/* */}
    public get showAdditionalDomainsHint(): boolean {
        return this.additionalDomains.length > this._maxAdditionalDomainsToDelete;
    }

    public get showActionLegend(): boolean {
        let show = false;
        if (this._onDeletingProcess) {
            return show;
        }

        if (this.includedDomains.length > 0) {
            show = this.includedDomains.some(
                (domain) =>
                    domain.domainObject !== null
                    && domain.domainObject?.deletionScheduledFor === null
            );
        }

        if (!show && this.additionalDomains.length > 0) {
            show = this.additionalDomains.some(
                (domain) =>
                    domain.domainObject !== null
                    && domain.domainObject?.deletionScheduledFor === null
            );
        }

        return show;
    }

    public set isPaidUntilExceeded(value: boolean) {
        this._isPaidUntilExceeded = value;
    }

    public get isPaidUntilExceeded(): boolean {
        return this._isPaidUntilExceeded;
    }

    public save = (): Promise<void> => {
        return this.bundleModel.update(this.bundle)
        .then(
            () => {
                this.alertManager.success(this.$translate.instant('TR_100119-d6dbda_TR'));
                this.navigation.reloadCurrentState();
            }
        );
    };

    public delete = (): PromiseLike<{ response: unknown[] }> => {
        // make a copy of includedDomain list for error handling and than normalize list for request
        const tmpIncludedDomains = ng.copy(this.includedDomains);
        this._onDeletingProcess = true;
        const includedDomainsActions = this.includedDomains.filter((domain) => {
            if (domain.domainObject !== undefined) {
                if (domain.domainObject.status.toLowerCase() === 'restorable') {
                    /**
                     * SPECIAL CASE - TAKE CARE
                     *
                     * For the bundle delete, we have to pass a list of the inclusive domains
                     * with a deletionMode ('detach', etc) in the request with the parameter domainActions.
                     *
                     * If an inclusive domain has the status 'restorable', the bundle delete must be
                     * handled separately (!).
                     * All 'restorable' domains must NOT appear in the list 'domainActions', otherwise
                     * the API will throw an error.
                     *
                     * I just discussed this with Michi, I understood Michi to mean
                     * that the domain with status 'restorable' is no
                     * longer linked to the bundle (?!). BundleId reference is still available in the DomainObject.
                     *
                     * I don't find this approach entirely comprehensible,
                     * but I didn't want to continue the discussion because it
                     * was hopeless.
                     *
                     * Cheers
                     */
                    return false;
                } else if (['active', 'restricted'].indexOf(domain.domainObject.status.toLowerCase()) >= 0) {
                    if (domain.domainObject.deletionScheduledFor !== null) {
                        const domainDeleteDate = new Date(domain.domainObject.deletionScheduledFor);

                        domain.action = this.deleteDate.dateObj.getTime() - domainDeleteDate.getTime() < 0
                            ? 'detach'
                            : domain.domainObject.deletionType
                        ;
                    }
                }
                delete domain.domainObject;
            }
            return true;
        });

        return this.bundleModel.delete(
            [{
                domainActions: includedDomainsActions,
                id: this.bundle.id,
                paidUntil: this.bundle.paidUntil
            }],
            (this.deleteDate.isToday() ? null : this.deleteDate.dateObj)
        )
        .then(
            (res) => {
                if (this.additionalDomains.length > 0) {
                    // Treat additional domains
                    for (const domain of this.additionalDomains) {
                        if (domain.domainObject !== undefined && domain.domainObject.status === 'restorable') {
                            // nothing to do with restorabled domains
                            continue;
                        }

                        switch (domain.action) {
                            case 'delete-at-end-of-contract':
                                void this._deleteAdditionalDomain(domain, true);
                                break;
                            case 'withdraw':
                                void this._withdrawDomain(domain, true);
                                break;
                            // nothing to do on action 'keep' domain
                        }
                    }
                }

                this.alertManager.success(this.$translate.instant('TR_100119-086ce0_TR'));

                if (this.deleteDate.isToday()) {
                    void this.navigation.go('bundle.overview', {}, { reload: true });
                    this._onDeletingProcess = false;
                    return q.reject();
                }
                this.navigation.reloadCurrentState();
                return { response: res };
            },
            (err) => {
                // reset normalized includedDomains list
                this._onDeletingProcess = false;
                this.includedDomains = ng.copy(tmpIncludedDomains);
                return q.reject(err);
            }
        );
    };

    public restoreOrPurge = (): void => {
        this.apiErrorModel.destroyErrorList();
        if (this.selectedRestoreAction === 'restore') {
            void this.bundleModel.restore([this.bundle]).then(
                () => {
                    this.alertManager.success(this.$translate.instant('TR_100119-6a9a06_TR'));
                    void this.navigation.go('bundle.dashboard', {}, { reload: true });
                }
            );
        } else if (this.selectedRestoreAction === 'purge') {
            void this.bundleModel.purgeRestorable([this.bundle]).then(
                () => {
                    this.alertManager.success(this.$translate.instant('TR_100119-d6dbda_TR'));
                    void this.navigation.go('bundle.dashboard', {}, { reload: true });
                }
            );
        }
    };

    public cancelRestore = (): void => {
        this.hasAgreedToRestoreBundle = false;
    };

    public domainActionsFor(domain: string, additionalDomains?: boolean): FormDropDownItems[] {
        additionalDomains = additionalDomains || false;
        if (!(domain in this._domainActionsCache)) {
            const domainActions: FormDropDownItems[] = additionalDomains
                ? ng.copy(this._baseAdditionalDomainActions)
                : ng.copy(this._baseIncludedDomainActions)
            ;

            const transitTldMatch = ['de', 'at', 'uk', 'co.uk', 'me.uk', 'net.uk', 'org.uk']
                .some((tld) => {
                    return domain.split('.').slice(1).join('.') === tld;
                });
            if (transitTldMatch) {
                domainActions.push({
                    name: this.$translate.instant('TR_100119-52aa9e_TR'),
                    value: 'withdraw'
                });
            }
            this._domainActionsCache[domain] = domainActions;
            return domainActions;
        } else {
            return this._domainActionsCache[domain] as FormDropDownItems[];
        }
    }

    public cancelTerminate = (): void => {
        this.hasAgreedToDeleteData = false;
    };

    public readableDeletionDate = (deletionScheduledFor: string): string => {
        return this.$translate.instant(
            /* translationId */ 'TR_211119-977b4d_TR',
            { deletionDate: this.$filter('date')(deletionScheduledFor, 'dd.MM.yyyy') }
        );
    };

    public domainInDeletion = (domain: DomainApi.Domain): boolean => {
        if ([undefined, null].indexOf(domain) >= 0) {
            return;
        }

        if (['restorable'].indexOf(domain.status) >= 0) {
            return true;
        }

        return domain.deletionScheduledFor !== null
            || domain.deletionDate !== null
        ;
    };

    private _getBundleIncludedDomains = (): void => {
        let domainLookup;
        let vHostLookup = true;
        if (this.bundle.productCode.indexOf('emailpackage') >= 0) {
            vHostLookup = false;
            domainLookup = this.domainModel.listWithoutPagination(
                this._maxAdditionalDomainsToDelete + 1,
                1,
                { field: 'BundleId', value: this.bundle.id }
            );
        } else {
            domainLookup = this.bundleHelper.getBundleVhosts(this.bundle, this._maxAdditionalDomainsToDelete + 1);
        }
        void domainLookup.then(
            (res: ViewTypes.ApiListResponse) => {
                let bundleDomainList: (ViewTypes.BundleVhostFindObject | ViewTypes.BundleDomainFindObject)[];
                if (vHostLookup) {
                    bundleDomainList = this._setCleanDomainNamesFromVhost(res.data);
                } else {
                    bundleDomainList = this._setCleanDomainNamesFromDomainList(res.data);
                }
                const domainNameList = bundleDomainList.map(
                    (bundleDomain) => bundleDomain.domainNameUnicode
                );

                // Skip the `domainModel.findByName` when no domain is attached.
                // Otherwise the API throws an error (unknown filter field '').
                if (!domainNameList || domainNameList.length === 0) {
                    return;
                }
                return this.domainModel.findByName(domainNameList)
                .then((domainList) => {
                    for (const domainObj of domainList) {
                        bundleDomainList.some((bundleDomain) => {
                            if (domainObj.nameUnicode === bundleDomain.domainNameUnicode
                                && domainObj.bundleId === this.originalBundle.id
                            ) {
                                const domainDataObject = {
                                    action: '',
                                    disconnect: false,
                                    domain: bundleDomain.domainNameUnicode,
                                    domainObject: domainObj
                                };

                                if (!vHostLookup || (bundleDomain as ViewTypes.BundleVhostFindObject).type === 'inclusiv') {
                                    this.includedDomains.push(domainDataObject);
                                    return true;
                                }

                                domainDataObject.domainObject = domainObj;
                                this.additionalDomains.push(domainDataObject);

                                return true;
                            }
                            return false;
                        });
                    }
                });
            }
        );
    };

    private _setCleanDomainNamesFromVhost = (
        vhostList: ViewTypes.BundleVhostFindObject[]
    ): ViewTypes.BundleVhostFindObject[] => {
        vhostList = JSON.parse(JSON.stringify(vhostList));
        return vhostList.map(
            (bundleDomainVhost) => {
                bundleDomainVhost.mainNameUnicode = StringCleaner.clean(
                    bundleDomainVhost.domainNameUnicode
                ).as(CleanableStringType.DomainNames);
                bundleDomainVhost.domainNameUnicode = bundleDomainVhost.domainNameUnicode.replace('www.', '');
                return bundleDomainVhost;
            }
        );
    };

    private _setCleanDomainNamesFromDomainList = (
        domainList: DomainApi.Domain[]
    ): ViewTypes.BundleDomainFindObject[] => {
        const convertedDomainList: ViewTypes.BundleDomainFindObject[] = JSON.parse(JSON.stringify(domainList));

        return convertedDomainList.map(
            (bundleDomain) => {
                bundleDomain.mainNameUnicode = StringCleaner
                    .clean(bundleDomain.nameUnicode)
                    .as(CleanableStringType.DomainNames)
                ;
                bundleDomain.domainNameUnicode = bundleDomain.nameUnicode;
                return bundleDomain;
            }
        );
    };

    private _includedDomainsValid = (): boolean => {
        return this.includedDomains.every(
            (domain) => {
                if ([undefined, null].indexOf(domain.domainObject) < 0
                    && (
                        ['restorable'].indexOf(domain.domainObject.status) >= 0
                        || domain.domainObject.deletionDate !== null
                        || domain.domainObject.deletionScheduledFor !== null
                    )
                ) {
                    return true;
                }

                return ['delete', 'detach', 'withdraw'].indexOf(domain.action) >= 0;
            }
        );
    };

    private _additionalDomainsValid = (): boolean => {
        if (this.additionalDomains.length > this._maxAdditionalDomainsToDelete) {
            return true;
        }

        return this.additionalDomains.every(
            (domain) => {
                if ([undefined, null].indexOf(domain.domainObject) < 0
                    && (
                        ['restorable'].indexOf(domain.domainObject.status) >= 0
                        || domain.domainObject.deletionDate !== null
                        || domain.domainObject.deletionScheduledFor !== null
                    )
                ) {
                    return true;
                }
                return ['delete-at-end-of-contract', 'keep', 'withdraw'].indexOf(domain.action) >= 0;
            }
        );
    };

    private _deleteAdditionalDomain = (domain: DomainDataObject, endOfCotractPeriod?: boolean): void => {
        const execDate = endOfCotractPeriod
            ? new Date(domain.domainObject.currentContractPeriodEnd)
            : undefined
        ;

        return void this.domainModel.delete(domain.domainObject, execDate)
        .then(
            (deleteRes) => {
                if (deleteRes !== null && deleteRes.status !== 'error') {
                    this.alertManager.success(
                        this.$translate.instant(
                            /* translationId */ 'TR_201119-2c1079_TR',
                            { domainName: domain.domainObject.nameUnicode }
                        )
                    );
                }
            }
        );
    };

    private _withdrawDomain = (domain: DomainDataObject, endOfCotractPeriod?: boolean): void => {
        const execDate = endOfCotractPeriod
            ? new Date(domain.domainObject.currentContractPeriodEnd)
            : undefined
        ;

        return void this.domainModel.withdraw(domain.domainObject, false, execDate)
        .then(
            (deleteRes) => {
                if (deleteRes !== null && deleteRes.status !== 'error') {
                    this.alertManager.success(
                        this.$translate.instant(
                            /* translationId */ 'TR_201119-30fcef_TR',
                            { domainName: domain.domainObject.nameUnicode }
                        )
                    );
                }
            }
        );
    };
}

export class OrganismDeleteFormBundleComponent implements ng.IComponentOptions {
    public bindings = {
        originalBundle: '<bundle',
        userPanelRight: '='
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismDeleteFormBundleController;
    public template = require('./bundle-delete.html');
}
