import * as ng from 'angular';
import { EditPanelRight } from '@/atomic-components';
import { OrganismEditFormSubAccountComponent } from '@/atomic-components/organisms';
import { AuthContextService } from '@/services/auth-context';

export class OrganEditPanelSubAccountBasicDataController implements ng.IController {
    public static $inject: string[] = ['countryFilter', '$translate'];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public subAccountBasicData: any;
    public panelRightSettings: EditPanelRight;
    public cancelCallbackFunction: () => void;
    public saveCallbackFunction: (arg0: unknown) => void;
    public $editFormOrganism: OrganismEditFormSubAccountComponent;
    public customerTypes: unknown[] = [];
    public get allowedAdvertisingMethods(): boolean {
        return this.subAccountBasicData.allowedAdvertisingMethods?.length > 0;
    }
    public set allowedAdvertisingMethods(value: boolean) {
        this.subAccountBasicData.allowedAdvertisingMethods = value ? ['email'] : null;
    }

    public titleDropdownItems: { name: string; value: string }[] = [{
        name: this.$translate.instant('TR_110119-5eaaa9_TR'),
        value: 'Mr'
    }, {
        name: this.$translate.instant('TR_110119-f53bc7_TR'),
        value: 'Ms'
    }, {
        name: this.$translate.instant('TR_110119-548e63_TR'),
        value: 'Company'
    }, {
        name: this.$translate.instant('TR_111219-d7acc4_TR'),
        value: 'RegisteredAssociation'
    }];
    public companyTitleDropdownItems: { name: string; value: string }[] = [{
        name: this.$translate.instant('TR_110119-5eaaa9_TR'),
        value: 'Mr'
    }, {
        name: this.$translate.instant('TR_110119-f53bc7_TR'),
        value: 'Ms'
    }];

    constructor(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        private countryFilter: any,
        protected $translate: ng.translate.ITranslateService
    ) {
        this.customerTypes = [
            {
                name: this.$translate.instant('TR_110119-548e63_TR'),
                value: true
            }, {
                name: this.$translate.instant('TR_110119-e32d3f_TR'),
                value: false
            }
        ];
    }

    public $onInit(): void {
        // Use getter/setter magic to reset specific models based on a selected title.
        let tmpTitle = this.subAccountBasicData.title;
        Object.defineProperty(this.subAccountBasicData, 'title', {
            get: () => { return tmpTitle; },
            set: (newTitle) => {
                if (
                    ['Company', 'RegisteredAssociation'].indexOf(tmpTitle) >= 0
                    && ['Company', 'RegisteredAssociation'].indexOf(newTitle) < 0
                ) {
                    this.subAccountBasicData.contactTitle = undefined;
                    this.subAccountBasicData.contactName = undefined;
                }
                tmpTitle = newTitle;
            }
        });
    }

    public get disableSave(): boolean {
        const isCompany = ['Company', 'RegisteredAssociation'].indexOf(this.subAccountBasicData.title) >= 0;
        const hasMissingContact = (this.subAccountBasicData.contactTitle || '').length <= 0
            || (this.subAccountBasicData.contactName || '').length <= 0;
        return isCompany && hasMissingContact;
    }

    public get isDirectCustomer(): boolean {
        return AuthContextService.isDirectCustomer;
    }

    public getTitleName(titleValue: string): string {
        return ((this.titleDropdownItems.filter((item) => item.value === titleValue)[0] || {}).name || '');
    }

    public get customerType(): string {
        if (this.subAccountBasicData.isCommercialCustomer) {
            return this.$translate.instant('TR_110119-548e63_TR');
        } else {
            return this.$translate.instant('TR_110119-e32d3f_TR');
        }
    }

    public get countryName(): string {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call
        return this.countryFilter(this.subAccountBasicData.country);
    }

    public get showNewsletterToggle(): boolean {
        return AuthContextService.isRootOrCompanyAccount;
    }
}

export class OrganEditPanelSubAccountBasicDataComponent implements ng.IComponentOptions {
    public require = {
        $editFormOrganism: '^organismEditFormSubAccount'
    };
    public bindings = {
        accountPath: '<',
        cancelCallbackFunction: '<',
        panelRightSettings: '<',
        saveCallbackFunction: '<',
        subAccountBasicData: '='
    };
    public template = require('./sub-account-basic-data.html'); // tslint:disable-line
    public controller = OrganEditPanelSubAccountBasicDataController;
}
