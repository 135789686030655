import { PanelHeaderData } from '../../../atomic-components/molecules/panels';

import * as ng from 'angular';

export class TemplateBundleWizardController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.pageHeaderData = {
            backwardLink: 'bundle.dashboard',
            backwardText: this.$translate.instant('TR_080119-5c34c7_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: {},
            panelIcon: 'box-alt',
            panelTitle: this.$translate.instant('TR_090119-6b1a06_TR')
        };
    }
}

export class TemplateBundleWizardComponent implements ng.IComponentOptions {
    public bindings = {};
    public template = require('./bundle-wizard-template.html');
    public controller = TemplateBundleWizardController;
}
