import * as ng from 'angular';
import './product-select.scss';

import { WizardHelperService } from '@/atomic-components/molecules/wizard';
import { UiLanguagesConst } from '@/configuration/system';
import {
    AuthContextService,
    ManagedApplicationRobotService,
    ProductHelperService,
    ProductsRobotService,
    SelectedProduct
} from '@/services';
import * as Types from '@/types';

export class MoleculeFormProductSelectController implements ng.IController {
    public static $inject: string[] = [
        '$translate',
        'managedApplicationRobot',
        'productHelper',
        'productsRobot',
        'wizardHelper'
    ];

    public items: SelectedProduct[];
    public selectedBox: SelectedProduct;
    public changeCallback: (params: SelectedProduct) => void;
    public selectedProduct: SelectedProduct;
    public serviceObject: Record<string, unknown>;
    public promoProductItems: unknown[];
    public itemsHavePrices: boolean[] = [];

    public logos: {
        imgLink: string;
        productCodeIdentifier: string;
    }[] = [
        {
            imgLink: 'https://static.hosting.zone/images/ssl-logos/rapidssl.svg',
            productCodeIdentifier: 'ssl-geotrust-rapidssl'
        },
        {
            imgLink: 'https://static.hosting.zone/images/ssl-logos/geotrust.svg',
            productCodeIdentifier: 'ssl-geotrust-quicksslpremium'
        },
        {
            imgLink: 'https://static.hosting.zone/images/ssl-logos/geotrust.svg',
            productCodeIdentifier: 'ssl-geotrust-truebizid'
        }
    ];

    private ownsFreeNextcloud = false;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private managedApplicationRobot: ManagedApplicationRobotService,
        private productHelper: ProductHelperService,
        private productsRobot: ProductsRobotService,
        private wizardHelper: WizardHelperService
    ) {}

    public $onInit(): void {
        if (this.serviceObject.service === 'managedapplication') {
            this._checkManagedApplicationFreeProducts();
        }
    }

    public $onChanges(changes: ng.IOnChangesObject): void {
        if (
            Array.isArray(changes.items?.currentValue)
            && changes.items.currentValue.length > 0
            && (changes.items.currentValue[0] as SelectedProduct).productCodeTemplate !== undefined
        ) {
            this._setLogos(this.items);
        }
    }

    public get filteredItems(): SelectedProduct[] {
        if (this.items) {
            return this.items.filter((item) => {
                if (this.isFreeNextcloud(item)) {
                    const pickedAccount = (
                        (
                            (
                                this.serviceObject.settings as Record<string, unknown>
                            ).createData as Record<string, unknown>
                        ).nextcloud as Types.ManagedApplicationApi.Nextcloud
                    ).accountId;

                    if (this.ownsFreeNextcloud || AuthContextService.account.id !== pickedAccount) {
                        return false;
                    }
                }

                return true;
            });
        }

        return this.items;
    }

    public isSelectedFamily = (item: SelectedProduct): boolean => {
        return item === this.selectedBox;
    };

    public isSelectedProduct = (item: SelectedProduct): boolean => {
        if ([undefined, null].indexOf(this.selectedProduct) >= 0) {
            return undefined;
        }

        if ([undefined, null].indexOf(this.selectedBox) >= 0) {
            return item.name === this.selectedProduct.name && item.family === this.selectedProduct.family;
        }

        return ([undefined, null].indexOf(this.selectedBox) === -1 && item.name === this.selectedBox.name)
            && (item.name === this.selectedProduct.name && item.family === this.selectedProduct.family);
    };

    public get mwstInclusiveText(): string  {
        return AuthContextService.account.isCommercialCustomer
            ? this.$translate.instant('TR_231219-383755_TR')
            : this.$translate.instant('TR_231219-e28c9a_TR');
    }

    public get showMwstText(): boolean {
        return this.itemsHavePrices.some((item) => { return item; });
    }

    public select = (item: SelectedProduct): void => {
        if (this.isFamily(item) && this.selectedBox !== item) {
            this.selectedProduct = this.wizardHelper.resetSelectedProductObject(this.serviceObject) as SelectedProduct;
        } else {
            this.selectedProduct = this.wizardHelper.setSelectedProductObject(
                this.serviceObject,
                item
            ) as SelectedProduct;

            if (
                ([undefined, null, false] as unknown[]).indexOf(
                    (this.serviceObject.display as Record<string, unknown>
                ).inBundle) >= 0
                && [undefined, null].indexOf(this.selectedProduct.selectedPrice) < 0
            ) {
                const contingentPromise = this.productHelper.getContingentObjectId(
                    this.selectedProduct.selectedPrice.productCode,
                    (this.serviceObject.settings as Record<string, unknown>).accountId as string
                );

                if (contingentPromise !== null) {
                    void contingentPromise.then((contingentId) => {
                        (this.serviceObject.settings as Record<string, unknown>).selectedContingent = contingentId;
                    });
                }
            } else if (
                ([undefined, null, false] as unknown[]).indexOf(
                    (this.serviceObject.display as Record<string, unknown>).inBundle
                ) < 0
            ) {
                (
                    this.serviceObject.settings as Record<string, unknown>
                ).selectedContingent = (
                    (
                        this.serviceObject.display as Record<string, unknown>
                    ).inBundle as Types.BundleApi.Bundle
                ).id;
            }
        }

        this.selectedBox = item;

        if (this.changeCallback != null && this.changeCallback !== undefined) {
            this.changeCallback(item);
        }
    };

    public isFamily = (item: SelectedProduct): boolean => {
        return Object.prototype.hasOwnProperty.call(item, 'products') as boolean;
    };

    public get recommendAny(): boolean {
        return this.items.filter((el) => el.recommend).length > 0;
    }

    private isFreeNextcloud = (item: SelectedProduct): boolean => {
        return ProductHelperService.wrapSpecificationItems(item.specificationItems).isFreeProduct?.isTrue === true;
    };

    private _setLogos = (boxes: SelectedProduct[]): void => {
        for (const imageObj of this.logos) {
            boxes.filter(
                (element) => element.productCodeTemplate
                    && element.productCodeTemplate.indexOf(imageObj.productCodeIdentifier) >= 0
            ).forEach(
                (element) => element.productImage = imageObj.imgLink
            );
        }
    };

    private _checkManagedApplicationFreeProducts = (): void => {
        void this.productsRobot
            .productsFind(
                'managedapplication',
                (this.serviceObject.productFamilies as string[])[0],
                UiLanguagesConst[AuthContextService.account.language]
            ).then(
                ( familyResult ) => {
                    const familyResponse = familyResult.response;
                    familyResponse.data.forEach((product: Types.ProductApi.Product) => {
                        if (
                            ProductHelperService.wrapSpecificationItems(
                                product.specificationItems
                            ).isFreeProduct?.isTrue
                        ) {
                            if ([undefined, null, ''].indexOf(product.productCode) < 0) {
                                const productCodeFilter = {
                                    field: 'nextcloudProductCode',
                                    value: product.productCode
                                };

                                void this.managedApplicationRobot
                                    .nextcloudsListWithoutPagination(productCodeFilter, 1, null)
                                    .then((
                                        productResult: Types.UI.APIResponse<
                                            Types.ManagedApplicationApi.FindNextcloudsResult
                                        >
                                    ) => {
                                        const productResponse = productResult.response;
                                        if (productResponse.data[0] && productResponse.data[0].productCode) {
                                            if (productResponse.data[0].productCode === product.productCode) {
                                                this.ownsFreeNextcloud = true;
                                            }
                                        }
                                    });
                            }
                        }
                    });
                }
            );
    };
}

export class MoleculeFormProductSelectComponent implements ng.IComponentOptions {
    public controller =  MoleculeFormProductSelectController;
    public bindings = {
        changeCallback: '<',
        items: '<',
        promoProductItems: '<',
        selectedBox: '=',
        selectedProduct: '=',
        serviceObject: '<'
    };
    public template = require('./product-select.html'); // eslint-disable-line
}
