import * as ng from 'angular';
import { MoleculeFormEditController, PanelType } from '@/atomic-components/molecules/forms/form-edit/form-edit';
import {
    OrganismEditFormNextcloudController
} from '@/atomic-components/organisms/forms/edit-forms/nextcloud-edit/nextcloud-edit';
import { NavigationService } from '@/services/navigation';

export class OrganEditPanelNextcloudGroupFoldersController {
    public static $inject: string[] = ['navigation'];
    public $editFormMolecule: MoleculeFormEditController;
    public $editFormOrganism: OrganismEditFormNextcloudController;
    public typeOverride = PanelType.MISCELLANEOUS_ACTION;

    private validationId: number;

    constructor(
        private navigation: NavigationService
    ) {}

    public $onInit(): void {
        this.validationId = this.$editFormMolecule.registerValidator(this);
    }

    public $onDestroy(): void {
        this.$editFormMolecule.unregisterValidator(this.validationId);
    }

    public validate(): boolean {
        if (this.$editFormOrganism.groupFoldersToDelete.length > 0) {
            return this.$editFormOrganism.groupFoldersDeletionConfirmed;
        }

        return true;
    }

    public cancel = (): void => { // reset all the things!
        this.$editFormMolecule.validateAll();
        this.$editFormOrganism.groupFoldersToDelete = [];
        this.$editFormOrganism.groupFoldersToEdit = [];
        this.$editFormOrganism.aggregatedGroupDataCache = {};
        this.$editFormOrganism.groupFoldersDeletionConfirmed = false;
        this.$editFormOrganism.nextcloudGroupFolders = ng.copy(this.$editFormOrganism.originalNextcloudGroupFolders);
        this.navigation.reloadCurrentState();
    };
}

export class OrganEditPanelNextcloudGroupFoldersComponent implements ng.IComponentOptions {
    public require = {
        $editFormMolecule: '^moleculeFormEdit',
        $editFormOrganism: '^organismEditFormNextcloud'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./nextcloud-group-folders.html');
    public controller = OrganEditPanelNextcloudGroupFoldersController;
}
