import { UiRights } from '@/configuration';
import * as ng from 'angular';
import { WizardConfirmationBase } from '../../general/confirm';
import {
    ApiErrorModel, AuthContextService,
    ErrorMapperService,
    OrchestratorRobotService,
    WebhostingRobotService
} from '../../../../../services';

export class WebspaceWizardConfirmation extends WizardConfirmationBase {
    public static $inject: string[] = WizardConfirmationBase.$inject.concat(['webhostingRobot']);

    constructor(
        protected $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        protected apiErrorModel: ApiErrorModel,
        protected errorMapper: ErrorMapperService,
        protected localInterval: ng.IIntervalService,
        protected orchestratorRobot: OrchestratorRobotService,
        private webhostingRobot: WebhostingRobotService
    ) {
        super(
            $timeout,
            $translate,
            apiErrorModel,
            errorMapper,
            localInterval,
            orchestratorRobot
        );
        this.isJobDependendWizard = false;
        this.fillNextSteps = () => {
            this.nextSteps.push({
                iconName: 'webspace',
                linkText: this.$translate.instant('TR_290121-2050bd_TR'),
                route: 'webhosting.dashboard',
                routeOptions: { reload: true },
                routeParams: {}
            });
        };
    }

    public getJobByFilter = (filter, cacheTimeout, objectType?: string) => {
        switch (objectType) {
            case 'Webspace':
            case 'User':
            case 'Vhost':
                return this.webhostingRobot.listJobs(filter)
                    .then(this.normalizeResponseObject);
            default:
                return undefined;
        }
    };

    public callbackAfterFinishedRequests = () => {
        for (const createdObject of this.createRequests) {
            if (createdObject.objectType === 'Webspace' && this.isJobFinished(createdObject)) {
                this.nextSteps.push({
                    iconName: 'webspace',
                    linkText: this.$translate.instant('TR_240220-728bc2_TR'),
                    route: 'webhosting.webspaces.id.edit',
                    routeOptions: { reload: true },
                    routeParams: { webspaceId: createdObject.result.id }
                });
            }
        }
    };
}
