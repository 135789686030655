import * as ng from 'angular';
import { ResellerDataSection } from '../../../atomic-components/organisms/forms';

export class TemplateResellerSettingsController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public sections: any = [
        ResellerDataSection.PAYMENT,
        ResellerDataSection.DEFAULTS,
        ResellerDataSection.COMPANY,
        ResellerDataSection.WHITELABEL
    ];
    public rightsTemplates: any[];
    public dnsTemplates: any[];
    public nameserverSets: any[];
    public pageHeaderData = {
        backwardLink: 'reseller.dashboard',
        backwardText: this.$translate.instant('TR_090119-5b9243_TR'),
        panelIcon: 'users-cog',
        panelTitle: this.$translate.instant('TR_090119-382156_TR')
    };

    constructor(private $translate: ng.translate.ITranslateService) {}
}

export class TemplateResellerSettingsComponent implements ng.IComponentOptions {
    public bindings = {
        dnsTemplates: '<',
        nameserverSets: '<',
        rightsTemplates: '<'
    };
    public template = require('./reseller-settings.html');
    public controller = TemplateResellerSettingsController;
}
