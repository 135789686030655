import { ResourceApi } from '@/types/resource-api';
import * as ng from 'angular';

import { PanelHeaderData } from '@/atomic-components/molecules/panels/panel-header/panel-header';
import { AuthContextService } from '@/services/auth-context';

export class TemplateNetworkEditController {
    public static $inject: string[] = ['$translate', 'authContext'];

    public pageHeaderData: PanelHeaderData;
    public network: ResourceApi.Network;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'network.networks.overview',
            backwardText: this.$translate.instant('TR_090119-85645e_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: 'network-wired',
            panelTitle: `${this.$translate.instant('071a1517-b504-4288-b3f2-13149d9d5dc8')}: ${this.network.name}`
        };
    };
}

export class TemplateNetworkEditComponent implements ng.IComponentOptions {
    public bindings = {
        network: '<'
    };
    public controller = TemplateNetworkEditController;
    public controllerAs = '$TemplateNetworkEditCtrl';
    public template = require('./network-edit-template.html');
}
