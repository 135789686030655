import * as ng from 'angular';
import * as q from 'q';
import { MoleculeMenuMainController } from '@/atomic-components/molecules/menu';
import { WizardConfirmationBase } from '@/atomic-components/organisms/wizards';
import { MachineProductFamilies, UiRights } from '@/configuration';
import {
    ApiErrorModel,
    AuthContextService,
    ErrorMapperService,
    MachineRobotService,
    OrchestratorRobotService
} from '@/services/';

export class MachineWizardConfirmation extends WizardConfirmationBase {
    public static $inject: string[] = WizardConfirmationBase.$inject.concat(['machineRobot']);

    constructor(
        protected $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        protected apiErrorModel: ApiErrorModel,
        protected errorMapper: ErrorMapperService,
        protected localInterval: ng.IIntervalService,
        protected orchestratorRobot: OrchestratorRobotService,
        public machineRobot: MachineRobotService
    ) {
        super(
            $timeout,
            $translate,
            apiErrorModel,
            errorMapper,
            localInterval,
            orchestratorRobot
        );

        if (!AuthContextService.isGranted(UiRights.MACHINE_JOBS_LIST)) {
            this.isJobDependendWizard = false;
            this.fillNextSteps = () => {
                this.nextSteps.push({
                    iconName: 'server',
                    linkText: this.$translate.instant('TR_140119-c2926c_TR'),
                    route: 'machine.dashboard',
                    routeOptions: { reload: true },
                    routeParams: {}
                });
            };
        }
    }

    public getJobByFilter = (filter: unknown, cacheTimeout: number, objectType?: string) => {
        switch (this.selectedProductFamily) {
            case MachineProductFamilies.ecommerceServer:
                return q.resolve( // at this point the orchestration request went through with status pending...
                    { // just answer with an everything is fine response.
                        data: [
                            {
                                status: 'successful'
                            }
                        ]
                    }
                );

            default:
                return this.machineRobot
                    .jobsFind(
                        filter,
                        null,
                        null,
                        null,
                        true,
                        cacheTimeout
                    )
                    .then(this.normalizeResponseObject);
        }
    };

    public callbackAfterFinishedRequests = (): void => {
        this.nextSteps = [];
        switch (this.selectedProductFamily) {
            case MachineProductFamilies.cloudServer:
                for (const createdObject of this.createRequests) {
                    if (this.isJobFinished(createdObject)) {
                        this.nextSteps.push({
                            iconName: 'cloud',
                            linkText: this.$translate.instant('TR_130520-e8024e_TR'),
                            infoCalloutText: this.$translate.instant('TR_130520-e61100_TR'),
                            route: 'machine.virtualmachines.id.edit',
                            routeOptions: { reload: true },
                            routeParams: { machineId: createdObject.result.id }
                        });
                        this.nextSteps.push({
                            iconName: 'server',
                            labelText: this.$translate.instant('c3a5df93-4e01-44e5-aef2-51b6c23c65c6'),
                            linkText: this.$translate.instant('TR_130520-64e8fc_TR'),
                            route: 'machine.virtualmachines.jobs',
                            routeOptions: { reload: true },
                            routeParams: {}
                        });
                    }
                }
                break;

            case MachineProductFamilies.managedCloudServer:
                for (const createdObject of this.createRequests) {
                    if (this.isJobFinished(createdObject)) {
                        this.nextSteps.push({
                            iconName: 'cloud',
                            linkText: this.$translate.instant('TR_140520-f28cd2_TR'),
                            infoCalloutText: this.$translate.instant('TR_140520-96dcd3_TR'),
                            route: 'managed-servers.id.dashboard',
                            routeOptions: { reload: true },
                            routeParams: { machineId: createdObject.result.id }
                        });
                        this.nextSteps.push({
                            iconName: 'server',
                            labelText: this.$translate.instant('c3a5df93-4e01-44e5-aef2-51b6c23c65c6'),
                            linkText: this.$translate.instant('TR_130520-64e8fc_TR'),
                            route: 'machine.virtualmachines.jobs',
                            routeOptions: { reload: true },
                            routeParams: {}
                        });

                        // Reload main menu to add managed server menu entry
                        MoleculeMenuMainController.triggerRefresh('ManagedServer');
                    }
                }
                break;

            case MachineProductFamilies.ecommerceServer:
                for (const createdObject of this.createRequests) {
                    if (this.isJobFinished(createdObject)) {
                        this.nextSteps.push({
                            iconName: 'cloud',
                            linkText: this.$translate.instant('TR_280520-a48b92_TR'),
                            infoCalloutText: this.$translate.instant('TR_280520-83ffe6_TR'),
                            route: 'managed-servers.overview',
                            routeOptions: { reload: true },
                            routeParams: {}
                        });
                        this.nextSteps.push({
                            iconName: 'server',
                            labelText: this.$translate.instant('c3a5df93-4e01-44e5-aef2-51b6c23c65c6'),
                            linkText: this.$translate.instant('TR_130520-64e8fc_TR'),
                            route: 'machine.virtualmachines.jobs',
                            routeOptions: { reload: true },
                            routeParams: {}
                        });
                    }
                }
                break;

            case MachineProductFamilies.individualServer:
                for (const createdObject of this.createRequests) {
                    if (this.isJobFinished(createdObject)) {
                        this.nextSteps.push({
                            iconName: 'cloud',
                            linkText: this.$translate.instant('TR_090620-81bf85_TR'),
                            infoCalloutText: this.$translate.instant('TR_090620-c89bd8_TR'),
                            route: 'machine.virtualmachines.id.edit',
                            routeOptions: { reload: true },
                            routeParams: { machineId: createdObject.result.id }
                        });
                        this.nextSteps.push({
                            iconName: 'server',
                            labelText: this.$translate.instant('c3a5df93-4e01-44e5-aef2-51b6c23c65c6'),
                            linkText: this.$translate.instant('TR_130520-64e8fc_TR'),
                            route: 'machine.virtualmachines.jobs',
                            routeOptions: { reload: true },
                            routeParams: {}
                        });
                    }
                }
                break;
        }
    };
}
