import { FilterField } from '../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';

import * as Types from '../types';

type StringToStringFunction = (query: string) => string;
type StringToBooleanFunction = (query: string) => boolean;

export interface FilterSpecialCase {
    matches: StringToBooleanFunction;
    getValue: StringToStringFunction;
    getField: StringToStringFunction;
    type: string;
}

type FilterSpecialCaseBuilder = (field?: string, ...args: any[]) => FilterSpecialCase;

export class FilterUtils {
    public static buildSpecialCases: { [key: string]: FilterSpecialCaseBuilder }
    = {
        customField: () => ({
            getField: (query) => query.replace(/^%([a-zA-Z]+):.+$/, '$1'),
            getValue: (query) => '*' + query.replace(/^%[a-zA-Z]+:(.+)$/, '$1') + '*',
            matches: (query) => /^%[a-zA-Z]+:.+$/.test(query),
            type: 'customField'
        }),
        id: (field: string) => ({
            getField: () => field,
            getValue: (query) => query.replace(/^#([0-9a-zA-Z]+)$/, '$1'),
            matches: (query) => /^#[0-9a-zA-Z]+$/.test(query),
            type: 'id'
        }),
        prefix: (field: string, prefix: string) => ({
            getField: () => field,
            getValue: (query) => `${query}*`,
            matches: (query) => query.indexOf(prefix) === 0,
            type: 'prefix'
        }),
        regex: (field: string, regex: RegExp) => ({
            getField: () => field,
            getValue: (query) => `*${query}*`,
            matches: (query) => regex.test(query),
            type: 'regex'
        })
    };

    public static buildSimpleFilter: (
        filterQuery: string,
        simpleFilterFields: string[],
        specialCases: FilterSpecialCase[]
    ) => Types.Finding.Filter
    = (filterQuery, simpleFilterFields, specialCases) => {
        if (filterQuery.length < 1) {
            return undefined;
        }

        let subFilter = [];
        if ([undefined, null].indexOf(specialCases) < 0 && specialCases.length > 0) {
            subFilter = specialCases
            .filter((specialCase) => specialCase.matches(filterQuery))
            .map(
                (specialCase) => ({
                    field: specialCase.getField(filterQuery),
                    value: specialCase.getValue(filterQuery)
                })
            );
        }

        if (subFilter.length === 0) {
            subFilter = simpleFilterFields.map(
                (field) => {
                    return {
                        field: field,
                        value: '*' + filterQuery + '*'
                    };
                }
            );
        }

        switch (subFilter.length) {
            case 0: return undefined;
            case 1: return subFilter[0];
            default: return {
                subFilter: subFilter,
                subFilterConnective: 'OR'
            };
        }
    };

    public static buildDisjunctFilter: (
        filterQuery: string,
        simpleFilterFields: string[],
        specialCases: FilterSpecialCase[]
    ) => Types.Finding.Filter
    = (filterQuery, simpleFilterFields, specialCases) => {
        if ([undefined].indexOf(filterQuery) >= 0 || filterQuery.length < 1) {
            return undefined;
        }

        const subQueries =
        filterQuery.split(' ').filter((subQuery) => [].indexOf(subQuery) < 0)
        || [];
        let quotePair = FilterUtils.getQuotePair(subQueries);

        while (quotePair !== false) {
            const slice = subQueries
            .splice(quotePair.start, quotePair.length)
            .join(' ')
            .replace(/^"(.*)"$/, '$1');

            subQueries.splice(quotePair.start, 0, slice);

            quotePair = FilterUtils.getQuotePair(subQueries);
        }

        switch (subQueries.length) {
            case 0: return undefined;
            case 1: return FilterUtils.buildSimpleFilter(subQueries[0], simpleFilterFields, specialCases);
            default: return {
                subFilter: subQueries.map(
                    (subQuery) => FilterUtils.buildSimpleFilter(subQuery, simpleFilterFields, specialCases)
                ),
                subFilterConnective: 'AND'
            };
        }
    };

    /**
     * Builds a filter object.
     *
     * @param fancyFilterFields
     *      A list of available fancy filters. Supply empty array if fancy filters do not apply.
     *
     * @param simpleFilterQuery
     *      String containing the query string from the simple search field. Supply empty string if there is no search.
     *
     * @param simpleFilterFields
     *      List of fields to use for the simple search. Supply empty array if there is no search.
     *
     * @param simpleFilterSpecialCases
     *      List of special case specifications.
     *
     * @param additionalFilters
     *      List of additional filters to apply. Make sure this is an array or they will not be applied!
     */
    public static buildFullFilter: (
        fancyFilterFields: FilterField<any>[],
        simpleFilterQuery: string,
        simpleFilterFields: string[],
        simpleFilterSpecialCases: FilterSpecialCase[],
        additionalFilters?: Types.Finding.Filter[]
    ) => Types.Finding.Filter
    = (fancyFilterFields, simpleFilterQuery, simpleFilterFields, simpleFilterSpecialCases, additionalFilters?) => {
        let filterList: Types.Finding.Filter[] = FilterUtils.normalizeFancyFilters(fancyFilterFields);
        const simpleFilter = FilterUtils.buildDisjunctFilter(
            simpleFilterQuery,
            simpleFilterFields,
            simpleFilterSpecialCases
        );

        if ([undefined, null].indexOf(simpleFilter) < 0) {
            filterList.push(simpleFilter);
        }

        if (additionalFilters && (additionalFilters.length > 0)) {
            filterList = filterList.concat(additionalFilters);
        }

        switch (filterList.length) {
            case 0: return undefined;
            case 1: return filterList[0];
            default: return {
                subFilter: filterList,
                subFilterConnective: 'AND'
            };
        }
    };

    private static normalizeFancyFilters: (filterFields: FilterField<any>[]) => Types.Finding.Filter[]
    = (filterFields) => {
        if ([undefined, null].indexOf(filterFields) >= 0) {
            return [];
        }

        return filterFields.map((filterField) => filterField.filter).filter((filter) => filter !== null);
    };

    private static getQuotePair: (array: string[]) => { start: number; length: number } | false
    = (array) => {
        let start: false|number = false;
        let end: false|number = false;

        array.forEach((value, index) => {
            if (end !== false) {
                return;
            }

            if (start === false && (value.indexOf('"') === 0)) {
                start = index;
            }

            if (
                start !== false
                && (value.indexOf('"') === (value.length - 1))
                && (value.length > 1 || start !== index)
            ) {
                end = index;
            }
        });

        if (start !== false && end !== false) {
            return {
                length: (end - start) + 1,
                start: start
            };
        } else {
            return false;
        }
    };
}

/**
 * Utility class for building valid filter objects.
 */
export class FilterBuilder {
    private static tokenize = (input: string): string[] => {
        const result = [];
        let current = '';
        let inQuotes = false;

        for (const char of input) {
            if ([undefined, null].indexOf(char) >= 0) {
                continue;
            }

            if (char === '"') {
                inQuotes = !inQuotes;
                continue;
            }

            if (char === ' ' && !inQuotes) {
                if (current.length > 0) {
                    result.push(current);
                }
                current = '';
                continue;
            }

            current += char;
        }

        if (current.length > 0) {
            result.push(current);
        }

        return result;
    };

    private specialCases: FilterSpecialCase[] = [];
    private additionalFilters: Types.Finding.Filter[] = [];
    private fancyFilterFields: FilterField<any>[] = [];
    private exactSearchModifier = '!';

    /** @param simpleFilterFields Fields to search for tokens that aren't handled by special cases. */
    constructor(
        private simpleFilterFields: string[],
        private searchExactMatches: boolean = false
    ) {}

    /**
     * Allows searching other fields by writing <field>:<value> in the search string.
     */
    public allowCustomFields = () => {
        this.specialCases = this.specialCases.filter((specialCase) => specialCase.type !== 'customField');

        this.specialCases.push(
            {
                getField: (query) => query.replace(/^%([a-zA-Z]+):.+$/, '$1'),
                getValue: (query) => '*' + query.replace(/^%[a-zA-Z]+:(.+)$/, '$1') + '*',
                matches: (query) => /^%[a-zA-Z]+:.+$/.test(query),
                type: 'customField'
            }
        );

        return this;
    };

    /**
     * By setting an id field, entering #<id> searches for an object with the exact id.
     */
    public setIdField = (fieldName: string): FilterBuilder => {
        this.specialCases = this.specialCases.filter((specialCase) => specialCase.type !== 'id');

        this.specialCases.push(
            {
                getField: () => fieldName,
                getValue: (query) => query.replace(/^#([0-9a-zA-Z]+)$/, '$1'),
                matches: (query) => /^#[0-9a-zA-Z]+$/.test(query),
                type: 'id'
            }
        );

        return this;
    };

    /**
     * Specify a field to be searched for any tokens beginning with the specified prefix.
     *
     * @example
     *  (new FilterBuilder(['DomainNameUnicode']))
     *  .setFieldPrefix('DomainName', 'xn--')
     *  .buildFilter('xn--tst-qla.de')
     *
     *  will search in DomainName
     */
    public setFieldPrefix = (fieldName: string, prefix: string): FilterBuilder => {
        this.specialCases = this.specialCases.filter((specialCase) => specialCase.type !== `prefix(${prefix})`);

        this.specialCases.push(
            {
                getField: () => fieldName,
                getValue: (query) => `${query}*`,
                matches: (query) => query.indexOf(prefix) === 0,
                type: `prefix(${prefix})`
            }
        );

        return this;
    };

    /**
     * Specify a field to be searched for any tokens matching the specified RegEx.
     *
     * @example
     *  (new FilterBuilder(['DomainNameUnicode']))
     *  .setFieldPrefix('DomainName', /^.+\.xn--.+$/)
     *  .buildFilter('abc.xn--tst-qla.de')
     *
     *  will search in DomainName
     */
    public setFieldRegex = (fieldName: string, regex: RegExp): FilterBuilder => {
        this.specialCases = this.specialCases.filter((specialCase) => specialCase.type !== `regex(${regex})`);

        this.specialCases.push(
            {
                getField: () => fieldName,
                getValue: (query) => `*${query}*`,
                matches: (query) => regex.test(query),
                type: `regex(${regex})`
            }
        );

        return this;
    };

    /**
     * The filters you specify here will be added to every built filter object.
     */
    public setAdditionalFilters = (additionalFilters?: Types.Finding.Filter[]): FilterBuilder => {
        if ([undefined, null].indexOf(additionalFilters) >= 0) {
            this.additionalFilters = [];
        } else {
            this.additionalFilters = additionalFilters;
        }

        return this;
    };

    public setFancyFilterFields = (fancyFilterFields: FilterField<any>[]) => {
        if ([undefined, null].indexOf(fancyFilterFields) >= 0) {
            this.fancyFilterFields = [];
        } else {
            this.fancyFilterFields = fancyFilterFields;
        }

        return this;
    };

    /** String in, valid filter object out... Magic! */
    public buildFilter = (query: string): Types.Finding.Filter => {
        let filterList: Types.Finding.Filter[] = this.normalizedFancyFilters;
        const simpleFilter = this.buildDisjunctFilter(query);

        if ([undefined, null].indexOf(simpleFilter) < 0) {
            filterList.push(simpleFilter);
        }

        if (this.additionalFilters && (this.additionalFilters.length > 0)) {
            filterList = filterList.concat(this.additionalFilters);
        }

        switch (filterList.length) {
            case 0: return undefined;
            case 1: return filterList[0];
            default: return {
                subFilter: filterList,
                subFilterConnective: 'AND'
            };
        }
    };

    private get normalizedFancyFilters(): Types.Finding.Filter[] {
        if ([undefined, null].indexOf(this.fancyFilterFields) >= 0 || this.fancyFilterFields.length < 1) {
            return [];
        }

        return this.fancyFilterFields.map((filterField) => filterField.filter).filter((filter) => filter !== null);
    }

    private buildSimpleFilter = (subQuery: string): Types.Finding.Filter => {
        if (subQuery.length < 1) {
            return undefined;
        }

        let subFilter = [];
        if ([undefined, null].indexOf(this.specialCases) < 0 && this.specialCases.length > 0) {
            subFilter = this.specialCases
            .filter((specialCase) => specialCase.matches(subQuery))
            .map(
                (specialCase) => ({
                    field: specialCase.getField(subQuery),
                    value: specialCase.getValue(subQuery)
                })
            );
        }

        if (subFilter.length === 0) {
            let value = this.searchExactMatches ? subQuery : `*${subQuery}*`;

            if (!this.searchExactMatches && subQuery.indexOf(this.exactSearchModifier) === 0) {
                value = subQuery.slice(1);
            }

            subFilter = this.simpleFilterFields.map(
                (field) => {
                    return {
                        field: field,
                        value: value
                    };
                }
            );
        }

        switch (subFilter.length) {
            case 0: return undefined;
            case 1: return subFilter[0];
            default: return {
                subFilter: subFilter,
                subFilterConnective: 'OR'
            };
        }
    };

    private buildDisjunctFilter = (query: string): Types.Finding.Filter => {
        if ([undefined].indexOf(query) >= 0 || query.length < 1) {
            return undefined;
        }

        const subQueries = FilterBuilder.tokenize(query);

        switch (subQueries.length) {
            case 0: return undefined;
            case 1: return this.buildSimpleFilter(subQueries[0]);
            default: return {
                subFilter: subQueries.map(
                    (subQuery) => this.buildSimpleFilter(subQuery)
                ),
                subFilterConnective: 'AND'
            };
        }
    };
}
