import ng from 'angular';

import { FormDescriptionSpec } from '@/types/view-types/form-description/form-description';
import {
    ValidationErrorList
} from '@/atomic-components/molecules/lists/validation-error-list/validation-error-list.ts';
import { Validator } from './abstract-validator';

export class ValidateFile extends Validator {
    private validationOptions: FormDescriptionSpec.FieldObjectFileValidation;
    constructor(private $translate: ng.translate.ITranslateService) {
        super();
    }

    public validate = (
        value: File,
        validationOptions: FormDescriptionSpec.FieldObjectFileValidation
    ): ValidationErrorList => {
        const validationErrorList: ValidationErrorList = [];
        this.validationOptions = validationOptions;
        if (this.validationOptions?.fileMimeTypes?.indexOf(value.type) < 0) {
            validationErrorList.push({
                text: this.$translate.instant('TR_161120-643b5d_TR')
            });
        }

        if (!this.validationOptions?.fileExtensions?.some((ext) => value.name.endsWith(`.${ext}`))) {
            validationErrorList.push({
                text: this.$translate.instant('TR_161120-6d2f9a_TR')
            });
        }

        if (this.validationOptions?.maxSize <= value.size) {
            validationErrorList.push({
                text: this.$translate.instant('TR_161120-897850_TR')
            });
        }

        return validationErrorList;
    };
}
