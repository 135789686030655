import * as ng from 'angular';
import { AuthContextService } from '../';
import * as Types from '../../types';

type AccountType = Types.AccountApi.Account | Types.AccountApi.Subaccount;
export class AccountHelperService {

    public isRootAdmin(account?: AccountType) {
        account = account || AuthContextService.account;

        return AuthContextService.account.id === account.id
            && AuthContextService.isRootOrCompanyAccount;
    }

    public isCommercialCustomer(account: AccountType) {
        if ([undefined, null].indexOf(account) >= 0) {
            return false;
        }

        return AuthContextService.account.id !== account.id
            ? account.isCommercialCustomer
            : !this.isRootAdmin(account) && AuthContextService.account.isCommercialCustomer;
    }
}
