import { AlertManagerService, AuthContextService, DnsZoneModelService, NavigationService } from '../../../../services';

import * as ng from 'angular';
import * as Types from '../../../../types';

export class MoleculePanelServiceInfoMailboxController implements ng.IController {
    public static $inject: string[] = [
        '$state',
        '$timeout',
        '$translate',
        'alertManager',
        'authContext',
        'dnsZoneModel',
        'navigation'
    ];

    public serviceObject: any;
    public isExchangeMailbox = false;
    public mailServerSubDomain = 'mail';
    public smtpPort = '587 (STARTTLS)';
    public imapPort = '110 / 143 (STARTTLS)';
    public isRoot = false;
    public dnsZoneConfig: any;
    public dnsZoneRecords: {}[];
    public isExternalZone = true;
    public hasAutoconfigRecord = false;
    public autoconfigRecordIsValid = false;
    public actionClicked = false;
    public autoConfigRecord: any;

    constructor(
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private authContext: AuthContextService,
        private dnsZoneModel: DnsZoneModelService,
        private navigation: NavigationService
    ) {}

    public $onInit() {
        this.serviceObject = this.serviceObject || null;
        if (
            this.serviceObject !== null
            && ('type' in this.serviceObject)
            && this.serviceObject.type === 'ExchangeMailbox'
        ) {
            this.isExchangeMailbox = true;
            this.mailServerSubDomain = 'exchange';
            this.smtpPort = '465 (SSL)';
            this.imapPort = '993 (SSL)';
            this.getDnsZone();
        }
        if (this.authContext.isRootOrCompanyAccount) {
            this.isRoot = true;
        }
    }

    public get accountDefaults(): Types.AccountApi.AccountDefaults {
        return this.authContext.accountDefaults;
    }

    public get state() {
        return this.$state.current.name;
    }

    // used in template! Do not remove!
    public correctAutodiscoverRecord(mode: string = 'create') {
        this.actionClicked = true;
        const autoconfigurationRecord = [
            {
                content: 'autodiscover.' + this.accountDefaults.mailServer,
                name: 'autodiscover.' + this.serviceObject.domainName,
                ttl: 86400,
                type: 'CNAME',
                zoneConfigId: this.dnsZoneConfig.id
            }
        ];
        const recordsToDelete = [];
        if (mode === 'correct') {
            recordsToDelete.push(this.autoConfigRecord);
        }
        this.dnsZoneModel.updateZone(this.dnsZoneConfig, autoconfigurationRecord, recordsToDelete).then(
            (apiResponse) => {
                this.alertManager.success(this.$translate.instant('TR_100119-0cce92_TR'));
                this.$timeout(
                    () => {
                        this.navigation.reloadCurrentState();
                    },
                    500
                );
            }
        );
    }

    private getDnsZone = () => {
        this.dnsZoneModel.findZones(
            {field: 'zoneName', value: this.serviceObject.domainName},
            1,
            1,
            {field: 'zoneNameUnicode', order: 'ASC'},
            true
        ).then(
            (apiResponse) => {
                if (
                    [undefined].indexOf(apiResponse[0]) >= 0
                    || [undefined].indexOf(apiResponse[0].zoneConfig) >= 0
                    || [undefined].indexOf(apiResponse[0].zoneConfig.type) >= 0
                    || apiResponse[0].zoneConfig.type !== 'NATIVE'
                ) {
                    this.isExternalZone = true;
                } else {
                    this.isExternalZone = false;
                    this.dnsZoneConfig = apiResponse[0].zoneConfig;
                    this.dnsZoneRecords = apiResponse[0].records;
                    const autoconfig = this.dnsZoneRecords.filter((record: any) => {
                        return record.type === 'CNAME'
                            && record.name === 'autodiscover.' + this.serviceObject.domainName;
                    });
                    if (autoconfig.length === 1) {
                        this.hasAutoconfigRecord = true;
                        this.autoConfigRecord = autoconfig[0];
                        if (this.autoConfigRecord.content === 'autodiscover.' + this.accountDefaults.mailServer) {
                            this.autoconfigRecordIsValid = true;
                        }
                    }
                }
            }
        );
    };
}

export class MoleculePanelServiceInfoMailboxComponent implements ng.IComponentOptions {
    public bindings = {
        service: '@',
        serviceObject: '<'
    };
    public controller = MoleculePanelServiceInfoMailboxController;
    public controllerAs = '$panelServiceInfo';
    public template = require('./panel-service-info-mailbox.html');
}
