import * as ng from 'angular';

import { Table, TableRow } from '@/atomic-components';
import { MachineModelService, ResourceHelperService, ResourceModelService } from '@/services';
import { MachineApi, ResourceApi } from '@/types';

export class OrganEditPanelConfigNetworksController implements ng.IController {
    public static $inject: string[] = [
        '$translate',
        'resourceHelper',
        'resourceModel',
        'machineModel'
    ];

    public $editFormOrganism;
    public cancel;
    public panelRight;
    public network: ResourceApi.Network;

    private networkConfigSections: { header: string; table: Table}[];

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private resourceHelper: ResourceHelperService,
        private resourceModel: ResourceModelService,
        private machineModel: MachineModelService
    ) {}

    public $onInit = async () => {
        this.networkConfigSections = [];

        if (this.network.networkType === 'publicNetwork') {
            const publicIpAddressRanges = await this.resourceModel.listPublicIpAddressRanges({
                field: 'publicIpAddressRangeNetwork',
                value: this.network.id
            });

            this.initializeNetworkTables(publicIpAddressRanges.response.data);
        } else {
            const privateIpAddressRanges = await this.resourceModel.listPrivateIpAddressRanges({
                field: 'privateIpAddressRangeNetwork',
                value: this.network.id
            });

            this.initializeNetworkTables(privateIpAddressRanges.response.data);
        }
    };

    private initializeNetworkTables = async (ipAddressRanges: ResourceApi.AbstractIpAddressRange[]) => {
        for (const range of ipAddressRanges) {
            const rangeIPv6 = (range as any).ipVersion === 'v6';

            const bits = range.networkBits;

            const table = {
                headers: [
                    {
                        columnName: this.$translate.instant('TR_280420-6d3eb8_TR')
                    },
                    {
                        columnName: this.$translate.instant('7288ec11-c4ad-46f1-9e47-ba632e3560c2')
                    }
                ],
                rows: []
            };

            const filter = {
                field: 'NetworkId',
                value: range.networkId
            };

            const { data } = await this.machineModel.listWithoutPagination(100, 1, filter);

            const remapIpList = (addresses: MachineApi.IpAddress[], shortNotation?) => {
                return '<ul>'
                    + addresses.map((addr) => '<li>'
                    + (shortNotation ? shortNotation(addr.ip) : addr.ip)
                    + '</li>').join('')
                    + '</ul>';
            };

            const concatIpAddresses = (ipAddresses: MachineApi.IpAddress[], v6) => {
                if (v6) {
                    return remapIpList(ipAddresses, this.resourceHelper.getIPv6ShortNotation);
                } else {
                    return remapIpList(ipAddresses);
                }
            };

            for (const vm of (data as MachineApi.VirtualMachine[])) {
                const vmInterfaces = vm.networkInterfaces;

                if (vmInterfaces?.length > 0) {
                    for (const iface of vmInterfaces) {
                        if (iface.networkId === range.networkId) {
                            const row: TableRow = {
                                columns: [
                                    {
                                        html: concatIpAddresses(iface.ipAddresses, rangeIPv6)
                                    },
                                    {
                                        link: {
                                            route: 'machine.virtualmachines.id.edit',
                                            routeParams: {
                                                machineId: vm.id
                                            },
                                            value: vm.name
                                        }
                                    }
                                ]
                            };
                            table.rows.push(row);
                        }
                    }
                }
            }

            const address = rangeIPv6
                ? this.resourceHelper.getIPv6ShortNotation(range.minIpAddress)
                : range.minIpAddress;

            this.networkConfigSections.push({
                header: this.$translate.instant('TR_280420-f6970d_TR', { networkRange: `${address}/${bits}` }),
                table: table
            });
        }
    };
}

export class OrganEditPanelConfigNetworksComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<',
        network: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormNetwork'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./network-config.html');
    public controller = OrganEditPanelConfigNetworksController;
}
