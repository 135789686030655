import * as ng from 'angular';
import { MailboxModelService } from '../../../../../services';
import { UtilityStatusObject } from './../../../../molecules/charts/utility-status/utility-status';

export class OrganViewPanelEmailsOfDomainController implements ng.IController {
    public static $inject: string[] = ['$translate', 'mailboxModel'];

    public domainNameUnicode: string;
    public emailAddresses: any[] = [];
    public panelHeaderRoute: string;
    public panelHeaderRouteParams;
    public showAllLink = true;

    private _utilityStatus = null;

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private mailboxModel: MailboxModelService
    ) {}

    public $onInit() {
        this.panelHeaderRoute = 'email.mailboxes.overview';
        this.panelHeaderRouteParams = {
            fancyFilters: [
                { field: 'MailboxEmailAddressUnicode', value: this.domainNameUnicode }
            ]
        };
        this._checkEmailAddresses();
    }

    public _checkEmailAddresses = () => {
        this.mailboxModel.findMailAdressesByDomain(this.domainNameUnicode).then((res) => {
            this.emailAddresses = res;
        });
    };

    public set utilityStatus({}) {} // tslint:disable-line:no-empty
    public get utilityStatus() {
        if (this.emailAddresses.length === 0) {
            return;
        }

        if (this._utilityStatus !== null) {
            return this._utilityStatus;
        }

        this._utilityStatus = {
            infoText: this.$translate.instant('TR_061219-875ea8_TR'),
            inUse: this.emailAddresses.length,
            isStorage: false,
            showAbsolute: true
        };
        return this._utilityStatus;
    }

    public set showPanel({}) {} // tslint:disable-line:no-empty
    public get showPanel() {
        return this.emailAddresses.length > 0;
    }
}

export class OrganViewPanelEmailsOfDomainComponent implements ng.IComponentOptions {
    public bindings = {
        domainNameUnicode: '<'
    };
    public controller = OrganViewPanelEmailsOfDomainController;
    public template = require('./emails-of-domain.html');
}
