import * as ng from 'angular';
import { InputPlaceholderConst } from '@/configuration';
import './panel-login-twofa.scss';

export class OrganPanelLoginTwofaController {
    public static $inject: string[] = ['$timeout'];

    public credentials: unknown;
    public inProgress: boolean;
    public $editForm: { save: () => void };
    public inputPlaceholder = InputPlaceholderConst;
    public pattern: string;
    public invalid: unknown;
    public last2FaToken: string;
    public usePassword = false;

    constructor(
        private $timeout: ng.ITimeoutService
    ) {}

    public $onInit(): void {
        this.pattern = '[0-9]';
    }

    public $doCheck(): void {
        // @ts-ignore TS2339
        const code2Fa = (this.credentials.token as string);
        if (code2Fa?.length >= 6 && code2Fa !== this.last2FaToken) {
            this.last2FaToken = code2Fa;
            this.submit();
        }
    }

    public submit = (): void => {
        // @ts-ignore TS2339
        if (this.credentials.token.length > 5) {
            this.$editForm.save();
        } else {
            this.invalid = true;

            void this.$timeout(() => {
                this.invalid = false;
            }, 500);
        }
    };

    public get disableSubmit(): void {
        // @ts-ignore TS2339
        return this.credentials?.token?.length !== 6;
    }
}

export class OrganPanelLoginTwofaComponent implements ng.IComponentOptions {
    public bindings = {
        credentials: '=',
        inProgress: '<',
        invalid: '<'
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public template = require('./panel-login-twofa.html');
    public controller = OrganPanelLoginTwofaController;
}
