import * as ng from 'angular';
import { OrganismOverviewController } from '../../../../../organisms/overview/overview';

export class MoleculePanelOverviewHeaderMultiDownloadZonefileActionController implements ng.IController {
    public static $inject: string[] = [];

    public OrganismOverview: OrganismOverviewController;

    public abort = () => {
        this.OrganismOverview.overviewModel.activeMassAction = false;
    };

    public submit = () => {
        this.OrganismOverview.submitMassAction({methode: 'downloadZoneFile'})
            .then((result) => {
                if (result) {
                    this.abort();
                    this.OrganismOverview.overviewLoad();
                }
            }, (e) => {
                console.error('error', e);
            });
    };

}

export class MoleculePanelOverviewHeaderMultiDownloadZonefileActionComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public controller =  MoleculePanelOverviewHeaderMultiDownloadZonefileActionController;
    public template = require('./multi-download-zonefile-action.html');
}
