import * as ng from 'angular';
import * as q from 'q';
import { UiRights } from '@/configuration';
import { AccountModelService } from '@/services/account/account-model';
import { AuthContextService } from '@/services/auth-context';
import { BillingRobotService } from '@/services/billing/robot';
import { ProductHelperService } from '@/services/helpers/product-helper';
import * as Types from '@/types';

export class PriceCacheService {
    public static $inject: string[] = [
        '$cacheFactory',
        'accountModel',
        'billingRobot'
    ];

    private cache = this.$cacheFactory('priceCache');
    private monthlyPaymentEnabled = false;

    constructor(
        private $cacheFactory: ng.ICacheFactoryService,
        private accountModel: AccountModelService,
        private billingRobot: BillingRobotService
    ) {
        this.accountModel.loadSettings(undefined).then((settings) => {
            this.monthlyPaymentEnabled = settings.domainSettings.monthlyPaymentEnabled;
        });
    }

    public emptyCache = () => {
        this.cache.remove('articlePurchasePriceList');
    };

    public listBillingPrices = (accountId?) => {
        const key = 'bundle';
        const productCodes = [];

        return this.getPriceList(key, productCodes, accountId);
    };

    public listDatabasePrices = (accountId?) => {
        const key = 'database';
        const productCodes = [];

        return this.getPriceList(key, productCodes, accountId);
    };

    public listDomainPrices = (tld, accountId?) => {
        const key = 'domain-' + tld;
        const paymentEnabledString = (this.monthlyPaymentEnabled && tld === 'de') ? '-1' : '';

        const productCodes = [
            'domain-' + tld + paymentEnabledString + '-create',
            'domain-' + tld + paymentEnabledString + '-transfer',
            'domain-' + tld + paymentEnabledString + '-renew',
            'domain-' + tld + paymentEnabledString + '-owner-change',
            'domain-' + tld + paymentEnabledString + '-restore'
        ];

        if (tld === 'de') {
            productCodes.push('domain-' + tld + '-authinfo2');
        }

        return this.getPriceList(key, productCodes, accountId);
    };

    public getPriceByProductCode = (productCode, accountId?) => {
        return this.getPriceList(productCode, [productCode], accountId);
    };

    public listMailPrices = (accountId?) => {
        const key = 'email';
        const productCodes = [];

        return this.getPriceList(key, productCodes, accountId);
    };

    public listSslPrices = (accountId?) => {
        const key = 'ssl';
        const productCodes = [];

        return this.getPriceList(key, productCodes, accountId);
    };

    public listVmPrices = (accountId?) => {
        const key = 'machine';
        const productCodes = [];

        return this.getPriceList(key, productCodes, accountId);
    };

    public listWebhostingPrices = (accountId?) => {
        const key = 'webhosting';
        const productCodes = [];

        return this.getPriceList(key, productCodes, accountId);
    };

    public listManagedApplicationPrices = (accountId?) => {
        const key = 'managedapplication';
        const productCodes = [];

        return this.getPriceList(key, productCodes, accountId);
    };

    public listDatabasePriceChanges = (accountId?) => {
        return this.getPriceChangeList('database', accountId);
    };

    public listDomainPriceChanges = (accountId?) => {
        return this.getPriceChangeList('domain', accountId);
    };

    public listMailPriceChanges = (accountId?) => {
        return this.getPriceChangeList('email', accountId);
    };

    public listSslPriceChanges = (accountId?) => {
        return this.getPriceChangeList('ssl', accountId);
    };

    public listVmPriceChanges = (accountId?) => {
        return this.getPriceChangeList('machine', accountId);
    };

    public listWebhostingPriceChanges = (accountId?) => {
        return this.getPriceChangeList('webhosting', accountId);
    };

    public getStorageItemPrice = (
        productCode: string,
        productInfo: Types.ProductApi.TemplateProduct,  // Or Product, but this was easier to use here
        type: string
    ): unknown => {
        let storageProductCode: string = ProductHelperService.wrapSpecificationItems(
            productInfo.specificationItems
        ).storageProductCode?.value as string || null;

        if (productInfo.billingCycles !== null && productInfo.billingCycles !== undefined) {
            for (const cycle of productInfo.billingCycles) {
                const productCodeTemplate = productInfo.productCodeTemplate;
                const newProductCodeTemplate = productCodeTemplate.replace('##BILLING_CYCLE##', String(cycle));
                if (newProductCodeTemplate === productCode) {
                    storageProductCode = storageProductCode.replace('##BILLING_CYCLE##', String(cycle));
                }
            }
        }

        switch (type) {
            case 'database': return this.listDatabasePrices().then(
                (reply) => {
                    for (const price of reply) {
                        if (price.productCode === storageProductCode) {
                            return price;
                        }
                    }

                    return null;
                }
            );

            case 'mailbox': return this.listMailPrices().then(
                (reply) => {
                    for (const price of reply) {
                        if (price.productCode === storageProductCode) {
                            return price;
                        }
                    }
                    return null;

                }
            );

            case 'bundle':
            case 'webspace': return this.listWebhostingPrices().then(
                (reply) => {
                    for (const price of reply) {
                        if (price.productCode === storageProductCode) {
                            return price;
                        }
                    }
                    return null;

                }
            );

            default: return null;
        }
    };

    private getCache = (key?) => {
        let getKey = 'articlePurchasePriceList';
        if (key !== undefined) {
            getKey += '-' + key;
        }

        return this.cache.get(getKey);
    };

    private putCache = (value, key?) => {
        let getKey = 'articlePurchasePriceList';
        if (key !== undefined) {
            getKey += '-' + key;
        }

        return this.cache.put(getKey, value);
    };

    private isExpired = (cachedPrice) => {
        if (cachedPrice.state === 'error') {
            return true;
        }

        const now = (new Date()).getTime();
        const age = now - cachedPrice.created;

        return (age > (1000 * 60 * 15));
    };

    private getFilterForProductCodes = (productCodes) => {
        return {
            subFilter: productCodes.map(
                (productCode) => {
                    return {
                        field: 'productCode',
                        value: productCode
                    };
                }
            ),
            subFilterConnective: 'OR'
        };
    };

    private pushSuccess = (key, result) => {
        const tmp = this.getCache;
        if (result.errors === undefined
            || result.errors === null
            || !ng.isArray(result.errors)
            || result.errors.length === 0
        ) {
            tmp[key] = {
                created: (new Date()).getTime(),
                data: result.response.data,
                status: 'ok'
            };
        } else {
            tmp[key] = {
                created: (new Date()).getTime(),
                data: [],
                status: 'error'
            };
        }

        this.putCache(tmp, key);

        return tmp[key].data;
    };

    private pushFailure = (key) => {
        const tmp = this.getCache();
        tmp[key] = {
            created: (new Date()).getTime(),
            data: [],
            status: 'error'
        };

        this.putCache(tmp, key);

        return [];
    };

    private getPriceList = (key, productCodes, accountId) => {
        return this.getApiPriceList(key, productCodes, accountId);
    };

    private getApiPriceList = (key, productCodes, accountId): PromiseLike<any> => {
        let prices = this.getCache(accountId);
        let refresh = false;
        const sort = null;
        const owner = accountId;

        if (prices === undefined) {
            prices = {};
            refresh = true;

            this.putCache(prices, accountId);
        } else if (prices[key + '-' + accountId] === undefined || this.isExpired(prices[key + '-' + accountId])) {
            refresh = true;
        }

        if (refresh) {
            let filter;
            if ([undefined, null].indexOf(productCodes) >= 0 || productCodes.length === 0) {
                filter = {
                    field: 'service',
                    value: key
                };
            } else {
                filter = this.getFilterForProductCodes(productCodes);
            }

            return this.billingRobot
                .articlePurchasePriceList(filter, 100, 1, sort, owner)
                .then(
                    (result) => {
                        return this.pushSuccess(key + '-' + accountId, result);
                    },
                    () => {
                        return this.pushFailure(key + '-' + accountId);
                    }
                );
        } else {
            return q.when(prices[key + '-' + accountId].data);
        }
    };

    private getPriceChangeList = (key, accountId) => {
        if (!AuthContextService.isGranted(UiRights.BIL_LIST_ARTICLE_PRICES)) {
            return q.when([]);
        }

        let prices = this.getCache(accountId);
        let refresh = false;
        const sort = {
            field: 'startPIT',
            order: 'ASC'
        };
        const owner = accountId;

        if (prices === undefined) {
            prices = {};
            refresh = true;

            this.putCache(prices, accountId);
        } else if (prices[key + '-change-' + accountId] === undefined
            || this.isExpired(prices[key + '-change-' + accountId])
        ) {
            refresh = true;
        }

        if (refresh) {
            const from = new Date();
            const to = new Date(
                from.getFullYear(),
                from.getMonth(),
                from.getDate() + 84,
                from.getHours(),
                from.getMinutes()
            );

            return this.billingRobot
                .articlePurchasePriceChangeList(
                    key,
                    undefined,
                    from.toISOString(),
                    to.toISOString(),
                    500,
                    1,
                    sort,
                    owner
                ).then(
                    (result) => {
                        return this.pushSuccess(key + '-change-' + accountId, result);
                    },
                    () => {
                        return this.pushFailure(key + '-change-' + accountId);
                    }
                );
        } else {
            return q.when(prices[key + '-change-' + accountId].data);
        }
    };
}
