import * as ng from 'angular';

export class MoleculeStatusDatabaseController implements ng.IController {
    public static $inject: string[] = [];

    public database;

    public get deletionScheduledFor() {
        return this.database.deletionScheduledFor;
    }

    public get status() {
        return this.database.status;
    }
}

export class MoleculeStatusDatabaseComponent implements ng.IComponentOptions {
    public bindings = {
        database: '<'
    };

    public controller =  MoleculeStatusDatabaseController;
    public controllerAs = '$status';
    public template = require('./status-database.html');
}
