import * as ng from 'angular';

export class OrganEditPanelDatabaseStorageController {
    public static $inject: string[] = [];

    public cancel;
    public $editFormOrganism;
    public panelRight;

}

export class OrganEditPanelDatabaseStorageComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormDatabase'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./database-storage.html');
    public controller = OrganEditPanelDatabaseStorageController;
}
