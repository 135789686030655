import ng from 'angular';

import { DnsApi } from '@/types';
import { EditPanelRight } from '@/atomic-components/molecules';
import {
    AlertManagerService,
    AuthContextService,
    NameserverSetModelService,
    NavigationService
} from '@/services';

export class OrganismEditFormNameserverSetNewController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'nameserverSetModel',
        'authContext',
        'navigation'
    ];

    public panelRightSettings: EditPanelRight;
    public accountId: string;
    public nameserverItems: DnsApi.NameserverSet[] = [];
    public nameserverSet: DnsApi.NameserverSet;

    public typeOptions = [
        { name: this.$translate.instant('TR_070519-7748e6_TR'), value: 'system' },
        { name: this.$translate.instant('TR_070519-e3aaa1_TR'), value: 'external' }
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private nameserverSetModel: NameserverSetModelService,
        private authContext: AuthContextService,
        private navigation: NavigationService
    ) {}

    public $onInit(): void {
        this.accountId = this.authContext.account.id;

        this.panelRightSettings = {
            editPanelButton: true
        };

        this.nameserverSet = {
            defaultNameserverSet: false,
            name: '',
            nameservers: [],
            type: 'system'
        };
    }

    public save = (): Promise<boolean> => {
        this.nameserverSet.nameservers = this.nameserverItems.map((item) => item.name);

        return this.nameserverSetModel.create(this.nameserverSet, this.accountId)
            .then((res) => {
                if (res.response !== undefined) {
                    this.alertManager.success(this.$translate.instant('TR_100119-eb1586_TR'));
                    void this.navigation.go('dns.nameserversets.overview');
                }

                return Promise.reject(false);
            },
            () => Promise.reject(false)
        );
    };
}

export class OrganismEditFormNameserverSetNewComponent implements ng.IComponentOptions {
    public controller = OrganismEditFormNameserverSetNewController;
    public template = require('./nameserver-set-new.html');
}
