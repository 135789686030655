import {
    AccountFilterField,
    GroupedSelectFilterField,
    SelectFilterField,
    TextFilterField
} from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { JobOverviewModel, OverviewModel } from '../abstract';
import { RequestCancellerService } from '../request-canceller';

export class DomainJobOverviewModel extends JobOverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', 'domainJobModel']
    );
    public readonly service = 'domainJob';

    protected prefixFilters = [{ field: 'JobDomainNameAce', prefix: 'xn--' }];
    protected regexFilters = [{ field: 'JobDomainNameAce', regex: /^.+\.xn--.+$/ }];
    protected listCallbacks = [{callback: this.domainJobModel.list}];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private domainJobModel: any
    ) {
        super(RequestCanceller);
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('jobDisplayName', this.$translate.instant('TR_140119-796b28_TR')),
                new GroupedSelectFilterField(
                    'jobType',
                    this.$translate.instant('TR_070519-16189c_TR'),
                    [{
                        items: [{
                            name: this.$translate.instant('GENERAL.JOB.ACTION.DOMAINCREATE'),
                            value: 'domainCreate'
                        }, {
                            name: this.$translate.instant('DOMAIN.JOB.STATUS.DOMAINUPDATE'),
                            value: 'domainUpdate'
                        }, {
                            name: this.$translate.instant('DOMAIN.JOB.STATUS.DOMAINRESTORE'),
                            value: 'domainRestore'
                        }],
                        title: this.$translate.instant('GENERAL.GENERAL')
                    }, {
                        items: [{
                            name: this.$translate.instant('DOMAIN.JOB.STATUS.DOMAINDELETE'),
                            value: 'domainDelete'
                        }, {
                            name: this.$translate.instant('TR_140119-d46abf_TR'),
                            value: 'domainDeleteScheduled'
                        }, {
                            name: this.$translate.instant('GENERAL.JOB.ACTION.DOMAINDELETECANCELED'),
                            value: 'domainDeleteCanceled'
                        }, {
                            name: this.$translate.instant('TR_200721-86c153_TR'),
                            value: 'domainRegistryDelete'
                        }],
                        title: this.$translate.instant('DOMAIN.JOB.STATUS.DOMAINDELETE')
                    }, {
                        items: [{
                            name: this.$translate.instant('DOMAIN.DELETE.ACTION.TRANSIT'),
                            value: 'domainWithdraw'
                        }, {
                            name: this.$translate.instant('DOMAIN.DELETE.ACTION.TRANSIT'),
                            value: 'domainWithdrawScheduled'
                        }, {
                            name: this.$translate.instant('DOMAIN.DELETE.ACTION.TRANSIT'),
                            value: 'domainWithdrawCanceled'
                        }],
                        title: this.$translate.instant('DOMAIN.DELETE.ACTION.TRANSIT')
                    }, {
                        items: [{
                            name: this.$translate.instant('TR_140119-455230_TR'),
                            value: 'contactCreate'
                        }, {
                            name: this.$translate.instant('TR_140119-ae84c0_TR'),
                            value: 'contactDelete'
                        }, {
                            name: this.$translate.instant('TR_140119-e22d5c_TR'),
                            value: 'contactUpdate'
                        }, {
                            name: this.$translate.instant('TR_140119-9ae927_TR'),
                            value: 'contactOwnerVerification'
                        }],
                        title: this.$translate.instant('DOMAIN.GENERAL.HANDLES')
                    }, {
                        items: [{
                            name: this.$translate.instant('GENERAL.JOB.ACTION.DOMAINTRANSFERIN'),
                            value: 'domainTransferIn'
                        }, {
                            name: this.$translate.instant('GENERAL.JOB.ACTION.DOMAINTRANSFEROUT'),
                            value: 'domainTransferOut'
                        }, {
                            name: this.$translate.instant('GENERAL.JOB.ACTION.DOMAINTRANSFEROUTREQUEST'),
                            value: 'domainTransferOutDone'
                        }, {
                            name: this.$translate.instant('TR_140119-b1e480_TR'),
                            value: 'domainTransferOutRequest'
                        }, {
                            name: this.$translate.instant('GENERAL.JOB.ACTION.DOMAINTRANSFEROUTACK'),
                            value: 'domainTransferOutAck'
                        }, {
                            name: this.$translate.instant('TR_140119-193c59_TR'),
                            value: 'domainTransferOutNack'
                        }, {
                            name: this.$translate.instant('GENERAL.JOB.ACTION.DOMAINTRANSFEROUTFOA'),
                            value: 'domainTransferOutFoa'
                        }, {
                            name: this.$translate.instant('TR_140119-e09812_TR'),
                            value: 'domainTransferInPushAccept'
                        }, {
                            name: this.$translate.instant('DOMAIN.JOB.STATUS.DOMAINTRANSFERINPUSHREJECT'),
                            value: 'domainTransferInPushReject'
                        }, {
                            name: this.$translate.instant('GENERAL.JOB.ACTION.DOMAINTRANSFEROUTPUSH'),
                            value: 'domainTransferOutPush'
                        }],
                        title: this.$translate.instant('842ec85b-bffc-4138-86ca-a2b20e7ae6bd')
                    }, {
                        items: [{
                            name: this.$translate.instant('TR_140119-1fd6f5_TR'),
                            value: 'domainCreateAuthInfo2'
                        }, {
                            name: this.$translate.instant('TR_140119-837afd_TR'),
                            value: 'domainMovedIn'
                        }, {
                            name: this.$translate.instant('TR_140119-837afd_TR'),
                            value: 'domainMovedOut'
                        }],
                        title: this.$translate.instant('TR_140119-2d7a14_TR')
                    }]
                ),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR')),
                new SelectFilterField(
                    'jobState',
                    this.$translate.instant('TR_140119-8e4b60_TR'),
                        [{
                            name: this.$translate.instant('TR_140119-7200ff_TR'),
                            value: 'done'
                        }, {
                            name: this.$translate.instant('TR_140119-a235c9_TR'),
                            value: 'canceled'
                        }, {
                            name: this.$translate.instant('GENERAL.JOB.STATUS.DEFERRED'),
                            value: 'deferred'
                        }, {
                            name: this.$translate.instant('GENERAL.JOB.STATUS.FAILED'),
                            value: 'failed'
                        }, {
                            name: this.$translate.instant('GENERAL.JOB.STATUS.IN-PROGRESS'),
                            value: 'inProgress'
                        }, {
                            name: this.$translate.instant('GENERAL.JOB.STATUS.NEW'),
                            value: 'new'
                        }, {
                            name: this.$translate.instant('GENERAL.JOB.STATUS.ON-HOLD'),
                            value: 'onHold'
                        }, {
                            name: this.$translate.instant('GENERAL.JOB.STATUS.SUCCESSFUL'),
                            value: 'successful'
                        }, {
                            name: this.$translate.instant('GENERAL.JOB.STATUS.SUPPORT'),
                            value: 'support'
                        }, {
                            name: this.$translate.instant('GENERAL.JOB.STATUS.SCHEDULED'),
                            value: 'scheduled'
                        }, {
                            name: this.$translate.instant('GENERAL.JOB.STATUS.TRANSFER-DONE-BUT-UPDATE-FAILED'),
                            value: 'transferdonebutupdatefailed'
                        }, {
                            name: this.$translate.instant('GENERAL.JOB.STATUS.WAITING'),
                            value: 'waiting'
                        }
                    ]
                )
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }
}
