import * as ng from 'angular';

export class MoleculeWizardVerifiedHandlerController implements ng.IController {
    public storageProductData: null | any = null;
    public verification: string;
    public verified: boolean;

    public $onChanges(changes: ng.IOnChangesObject) {
        if (changes.storageProductData !== undefined && changes.storageProductData.currentValue !== null) {
            const verAlts = this.storageProductData.verificationAlternatives;

            if (verAlts.length > 0 && verAlts[0].requiredVerifications.length > 0) {
                this.verified = false;
                this.verification = verAlts[0].requiredVerifications[0];
            } else {
                this.verified = true;
                this.verification = '';
            }
        }
    }
}

export class MoleculeWizardVerifiedHandlerComponent implements ng.IComponentOptions {
    public bindings = {
        storageProductData: '<',
        verified: '='
    };
    public template = require('./wizard-verified-handler.html');
    public controller =  MoleculeWizardVerifiedHandlerController;
}
