import ng from 'angular';
import { IconName } from '@fortawesome/fontawesome-svg-core';

import './buttons.scss';

export class MoleculeButtonController implements ng.IController {
    public static $inject: string[] = ['$transclude', '$translate'];

    // Function
    public callback: () => void;
    public callbackParams: unknown;

    // Looks
    public icon: IconName;
    public color: 'green' | 'blue';

    // Modifier
    public disabled: boolean;
    public hideIcon: boolean;
    public modifier: string;
    // public modifierCustom: { [key: string]: boolean };

    // Attributes
    public attrTitle: string;
    public attrName: string;

    constructor(
        private $transclude: ng.ITranscludeFunction,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit(): void {
        this.hideIcon = this.hideIcon || false;
    }

    public get textFilled(): boolean {
        return this.$transclude.isSlotFilled('text');
    }
}

export class MoleculeButtonComponent implements ng.IComponentOptions {
    public transclude = {
        text: '?span' // Only span tags allowed ("<span translate="ID"></span>")
    };
    public bindings = {
        attrName: '@?',
        attrTitle: '@?',
        callback: '<?',
        callbackParams: '<?',
        color: '@?',
        disabled: '<?',
        hideIcon: '<?',
        icon: '@?',
        modifier: '@?'
    };
    public controller = MoleculeButtonController;
    public template = require('./button.html');
}
