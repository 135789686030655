import * as ng from 'angular';

export class MoleculeInputWithPrefixSelectionController implements ng.IController {
    public static $inject: string[] = ['$timeout'];

    public value: string;
    public selectionOptions: { value: string }[];
    public callbackOnChange: (arg0: string) => {};

    constructor(
        private $timeout: ng.ITimeoutService
    ) {}

    $onInit() {
        if ([undefined, null, ''].indexOf(this.value) >= 0) {
            this.value = this.selectionOptions[0].value;
        }
    }

    public changed = () => {
        if (this.callbackOnChange !== undefined) {
            this.$timeout(() => {
                this.callbackOnChange(this.value);
            });
        }
    };
}

export class MoleculeInputWithPrefixSelectionComponent implements ng.IComponentOptions {
    public bindings = {
        value: '=',
        selectionOptions: '<',
        callbackOnChange: '<'
    };
    public controller = MoleculeInputWithPrefixSelectionController;
    public controllerAs = '$input';
    public template = require('./input-with-prefix-selection.html');
}
