import ng from 'angular';

import { DnsZoneConst } from '../../../../../configuration';
import { DnsHelperService } from '../../../../../services';

export class MoleculePanelEditRowDnsRecordWizardController implements ng.IController {
    public static $inject: string[] = ['dnsHelper', '$timeout'];

    public record: any;
    public originalRecord: any = {};
    public editMode: boolean;
    public isEditable = false;
    public hideEditButton = false;
    public recordTypeDropdownItems: any[];
    public contentValidationErrors: any[];
    public nonEditableRecords: any[] = [];
    public ttlValidationErrors: any[];
    public recordList: any[];
    public placeholders: boolean;
    public index: number;
    public addOnDomainPlaceholder = '';
    public $editForm;
    public zoneName;
    public replacementIpv4;
    public replacementIpv6;
    public replacementMxv4;
    public replacementMxv6;
    public isDnsTemplateNew = false;

    constructor(
        private dnsHelper: DnsHelperService,
        private $timeout: ng.ITimeoutService
    ) {}

    public $onInit() {
        this.originalRecord = ng.copy(this.record);
        this._replacePlaceholder('domain');
        if (this.nonEditableRecords && this.nonEditableRecords.some((record) => ng.equals(record, this.record))) {
            this.hideEditButton = true;
        }
    }

    public $onChanges(changes) {
        if (
            changes.zoneName !== undefined
            && ng.isString(changes.zoneName.previousValue)
            && changes.zoneName.currentValue !== undefined
        ) {
            this._replacePlaceholder('domain');
        }

        if (changes.replacementIpv4 !== undefined && changes.replacementIpv4.currentValue !== undefined) {
            this._replacePlaceholder('ipv4');
        }

        if (changes.replacementIpv6 !== undefined && changes.replacementIpv6.currentValue !== undefined) {
            this._replacePlaceholder('ipv6');
        }

        if (changes.replacementMxv4 !== undefined && changes.replacementMxv4.currentValue !== undefined) {
            this._replacePlaceholder('mxipv4');
        }

        if (changes.replacementMxv6 !== undefined && changes.replacementMxv6.currentValue !== undefined) {
            this._replacePlaceholder('mxipv6');
        }
    }

    public get showEditable() {
        return this.editMode && (this.isEditable
            || (this.contentValidationErrors !== undefined && this.contentValidationErrors.length > 0)
            || (this.ttlValidationErrors !== undefined && this.ttlValidationErrors.length > 0));
    }

    public setEditable = () => {
        this.isEditable = true;
    };

    public toggleDelete = () => {
        this.recordList.splice(this.index, 1);
    };

    public cancel = () => {
        this.isEditable = false;
        this.record = ng.copy(this.originalRecord);
        this.contentValidationErrors = [];
        this.ttlValidationErrors = [];
        this.$timeout(
            () => {
                this.$editForm.validateAll();
            }, 50);
    };

    public save = () => {
        this.isEditable = false;
    };

    public get markedForDeletion() {
        return false;
    }

    public get wasEdited() {
        return false;
    }

    public get wasAdded() {
        return false;
    }

    public get contentValidationType() {
        return this.dnsHelper.getRecordValidationType(this.record.type);
    }

    public get disableCloseButton() {
        return (this.contentValidationErrors !== undefined && this.contentValidationErrors.length > 0)
        || (this.ttlValidationErrors !== undefined && this.ttlValidationErrors.length > 0);
    }

    private _replacePlaceholder = (placeholderType) => {
        if (this.originalRecord === undefined || this.record === undefined) {
            return;
        }

        const checkFields = ['content', 'name'];
        let replaceString;

        switch (placeholderType) {
            case 'domain':
                replaceString = this.zoneName;
                this.addOnDomainPlaceholder = this.zoneName;
                break;
            case 'ipv4':
                replaceString = this.replacementIpv4;
                break;
            case 'ipv6':
                replaceString = this.replacementIpv6;
                break;
            case 'mxipv4':
                replaceString = this.replacementMxv4;
                break;
            case 'mxipv6':
                replaceString = this.replacementMxv6;
                break;
            default:
                return;
        }

        checkFields.map(
            (recordType) => {
                const searchString = new RegExp(DnsZoneConst.TemplatePlaceholder[placeholderType], 'ig');
                if (
                    [undefined, null].indexOf(this.originalRecord[recordType]) < 0
                    && this.originalRecord[recordType].match(searchString) !== null
                ) {
                    this.record[recordType] = this.originalRecord[recordType].replace(searchString, replaceString);
                }
            }
        );
    };
}

export class MoleculePanelEditRowDnsRecordWizardComponent implements ng.IComponentOptions {
    public bindings = {
        editMode: '<',
        index: '<',
        isDnsTemplateNew: '<?',
        nonEditableRecords: '=?',
        placeholders: '<',
        record: '=',
        recordList: '=',
        recordTypeDropdownItems: '<',
        replacementIpv4: '<',
        replacementIpv6: '<',
        replacementMxv4: '<',
        replacementMxv6: '<',
        zoneName: '<'
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public controller = MoleculePanelEditRowDnsRecordWizardController;
    public template = require('./panel-edit-row-dns-record.html');
}
