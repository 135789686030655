import * as ng from 'angular';
import { OrganismOverviewController } from '../../organisms/overview/overview';
import './overview-header-sortable.scss';

export class MoleculeOverviewHeaderSortableController implements ng.IController {
    public OrganismOverview: OrganismOverviewController;
    public field: string;

    public get order(): 'none' | 'ascending' | 'descending' {
        if (
            [undefined, null].indexOf(this.OrganismOverview) >= 0
            || [undefined, null].indexOf(this.OrganismOverview.sortOrder) >= 0
            || this.OrganismOverview.sortOrder.field !== this.field
        ) {
            return 'none';
        }

        return this.OrganismOverview.sortOrder.order === 'ASC' ? 'ascending' : 'descending';
    }

    public changeOrder = () => {
        if (this.field.length === 0) {
            console.warn('sortable: field is not defined');
            return;
        }

        switch (this.order) {
            default:
            case 'none':
                this.OrganismOverview.sortOrder = {
                    field: this.field,
                    order: 'ASC'
                };
                break;

            case 'ascending':
                this.OrganismOverview.sortOrder = {
                    field: this.field,
                    order: 'DESC'
                };
                break;

            case 'descending':
                this.OrganismOverview.sortOrder = undefined;
                break;
        }

        this.OrganismOverview.overviewLoad();
    };
}

export class MoleculeOverviewHeaderSortableComponent implements ng.IComponentOptions {
    public bindings = {
        field: '@'
    };

    public require = {
        OrganismOverview: '^organismOverview'
    };

    public transclude = true;

    public template = require('./overview-header-sortable.html');
    public controller =  MoleculeOverviewHeaderSortableController;
}
