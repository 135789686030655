import { FormDropDownItems } from '../../atomic-components/molecules/forms/';
import { DomainContactPanelMode } from '../../atomic-components/molecules/panels';
import { HandleConst } from '../../configuration';

import * as ng from 'angular';

export class DomainContactHelperService {
    public static $inject: string[] = ['$translate'];

    private _contactTypeDropdownItems: FormDropDownItems[] = [];

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {
        this._setContactTypeDropdownItems();
    }

    public getContactTypeDropdownItems = () => {
        return this._contactTypeDropdownItems;
    };

    public getReadableContactType = (typeValue) => {
        let country;
        this._contactTypeDropdownItems.some((typeItem) => {
            if (typeItem.value === typeValue) {
                country = typeItem.name;
                return true;
            }
            return false;
        });
        return country;
    };

    public panelRowShown = (apiValue, panelMode?) => {
        if ([undefined, null].indexOf(panelMode) >= 0) {
            return true;
        }

        return (
            panelMode === DomainContactPanelMode.DISPLAY
            || panelMode === DomainContactPanelMode.EDIT
        ) && [undefined, null, ''].indexOf(apiValue) === -1;
    };

    public issetAnyParameter = (paramList, contact, panelMode) => {
        if (panelMode === DomainContactPanelMode.DISPLAY) {
            return paramList.some((param) => {
                return [undefined, null, ''].indexOf(contact[param]) === -1;
            });
        }
        return true;
    };

    private _setContactTypeDropdownItems = () => {
        for (const key in HandleConst.HandleTypes.typeItems) {
            if (HandleConst.HandleTypes.typeItems.hasOwnProperty(key)) {
                this._contactTypeDropdownItems.push({
                    name: this.$translate.instant(HandleConst.HandleTypes.typeItems[key]),
                    value: key
                });
            }
        }
    };
}
