import * as ng from 'angular';
import * as q from 'q';
import { UiRights } from '../../configuration';
import { AuthContextService } from '../auth-context';
import { AccountModelService } from './account-model';

export class UserSettingsService {
    public static $inject: string[] = ['$rootScope', 'accountModel'];

    private showAccountCache;

    constructor(
        private $rootScope: ng.IRootScopeService,
        private accountModel: AccountModelService
    ) {
        this.$rootScope.$on('logout', () => {
            this.showAccountCache = undefined;
        });
    }

    public showAccounts = () => {
        if (this.showAccountCache !== undefined) {
            return this.showAccountCache;
        }

        if (!AuthContextService.isGranted(UiRights.ACC_SUBACCOUNT_LIST)) {
            this.showAccountCache = q.when(false);
            return this.showAccountCache;
        }

        this.showAccountCache = this.accountModel.getTotal()
        .then((numberOfAccounts) => numberOfAccounts > 0);

        return this.showAccountCache;
    };

    public showExecuteAs = () => this.showAccounts();
}
