import * as ng from 'angular';
import { UiRights } from '../../configuration';
import {
    FilterCacheService,
    NavigationService,
    RedirectOnNotFoundCallback,
    SslCertificateModelService,
    SslJobModelService,
    SslJobOverviewModel,
    SslOverviewModel,
    UserSettingsManagerService
} from '../../services';
import * as stateUtilityFunctions from '../../services/state-utility-functions';

export const SSL_ROUTES = [
    {
        name: 'ssl',
        state: {
            abstract: true,
            parent: 'authenticated',
            url: '/ssl'
        }
    },
    {
        name: 'ssl.dashboard',
        state: {
            data: {
                isGrantedAny: [UiRights.SSL_CERTIFICATES_LIST, UiRights.SSL_JOBS_LIST]
            },
            onEnter: (navigation: NavigationService, userSettingsManager: UserSettingsManagerService) => {
                userSettingsManager.getUiSettings().then(
                    (result) => {
                        if (result?.overviewSettings?.SslCertificateOverviewCtrl?.expertView) {
                            navigation.go('ssl.certificates.overview');
                        }
                    }
                );
            },
            resolve: {},
            url: '/',
            views: {
                'content@authenticated': {
                    template: '<template-ssl-certificate-dashboard></template-ssl-certificate-dashboard>'
                }
            }
        }
    },
    {
        name: 'ssl.certificates',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.SSL_CERTIFICATES_LIST
            },
            url: '/certificates'
        }
    },
    {
        name: 'ssl.certificates.new',
        state: {
            data: {
                isGranted: UiRights.SSL_ORDER
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-ssl-certificate-wizard></template-ssl-certificate-wizard>'
                }
            }
        }
    },
    {
        name: 'ssl.certificates.overview',
        state: {
            data: {
                isGranted: UiRights.SSL_CERTIFICATES_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('SSLCertificates');
                },
                overviewModel: (sslOverviewModel: SslOverviewModel) => sslOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('SslCertificateOverviewCtrl');
                },
                showAccounts: stateUtilityFunctions.showAccounts,
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('SSLCertificates');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-ssl-certificate-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                ></template-ssl-certificate-overview>`
                }
            }
        }
    },
    {
        name: 'ssl.certificates.jobs',
        state: {
            data: {
                isGranted: UiRights.SSL_JOBS_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('SSLJobs');
                },
                overviewModel: (sslJobOverviewModel: SslJobOverviewModel) => sslJobOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('SslCertificateOverviewCtrl');
                },
                showAccounts: stateUtilityFunctions.showAccounts,
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('SSLJobs');
                }
            },
            url: '/jobs',
            views: {
                'content@authenticated': {
                    template: `<template-ssl-certificate-job-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                ></template-ssl-certificate-job-overview>`
                }
            }
        }
    },
    {
        name: 'ssl.certificates.jobs.id',
        state: {
            abstract: true,
            resolve: {
                job: (
                    $stateParams: ng.ui.IStateParamsService,
                    redirectOnNotFound: RedirectOnNotFoundCallback,
                    sslJobModel: SslJobModelService
                ) => {
                    return sslJobModel.findOne($stateParams.jobId).then(null, redirectOnNotFound);
                }
            },
            url: '/id/{jobId}'
        }
    },
    {
        name: 'ssl.certificates.jobs.id.details',
        state: {
            url: '/details',
            views: {
                'content@authenticated': {
                    // tslint:disable-next-line: max-line-length
                    template: `<template-ssl-certificate-job-details
                                    job="$resolve.job"
                                ></template-ssl-certificate-job-details>`
                }
            }
        }
    },
    {
        name: 'ssl.certificates.id',
        state: {
            abstract: true,
            resolve: {
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/id/{certificateId}'
        }
    },
    {
        name: 'ssl.certificates.id.edit', // Bei Änderungen auch `certificate-id` anpassen!
        state: {
            resolve: {
                certificate: (
                    $stateParams: ng.ui.IStateParamsService,
                    redirectOnNotFound: RedirectOnNotFoundCallback,
                    sslCertificateModel: SslCertificateModelService
                ) => {
                    return sslCertificateModel
                        .findOne($stateParams.certificateId)
                        .then(stateUtilityFunctions.check404('Certificate not found'))
                        .then(
                            (result) => {
                                return sslCertificateModel
                                    .addCertificateDetails([result])
                                    .then((certs) => certs[0]);
                            },
                            redirectOnNotFound
                        );
                }
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    // tslint:disable-next-line: max-line-length
                    template: `<template-ssl-certificate-edit
                                certificate="$resolve.certificate"
                                show-object-id="$resolve.showObjectId"
                                ></template-ssl-certificate-edit>`
                }
            }
        }
    }
];
