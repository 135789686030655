export enum UnavailableServiceBehavior {
    IGNORE,
    CATCH
}

export enum RequestStatus {
    SUCCESSFUL,
    FAILED
}

export enum RequestDebugFlag {
    NO_REQUEST,
    STACK_TRACE,
    LOG_DATA,
    DEBUG
}
