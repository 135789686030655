import * as ng from 'angular';
import { OrganismOverviewController, OverviewDataObject } from '../../../../../organisms/overview';

export class MoleculeOverviewRowPanelApplicationController implements ng.IController {
    public static $inject: string[] = [];

    public overviewData: OverviewDataObject;
    public overviewLoad: (selection: any) => any;
    public OrganismOverview: OrganismOverviewController;
    public minimalVersion = false;
    public internalPaginationLimit: number;

    public $onInit() {
        if ([null, undefined].indexOf(this.OrganismOverview.bundle) === -1
            || [null, undefined].indexOf(this.OrganismOverview.webspace) === -1 ) {
            this.minimalVersion = true;
        }
    }
}

export class MoleculeOverviewRowPanelApplicationComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelApplicationController;
    public template = require('./application-row.html');
}
