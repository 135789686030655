/* eslint-disable @typescript-eslint/no-floating-promises */
import ng from 'angular';
import './lightbox.css';

export interface ILightboxImage {
    alt: string;
    src: string;
    description?: string;
}

export class MoleculeLightboxController implements ng.IController {
    static $inject: string[] = ['$timeout'];

    images: ILightboxImage[];

    private _indexOnClick: number;
    private _indexModal: number;

    constructor(
        private $timeout: ng.ITimeoutService
    ) {}

    $onInit(): void {
        this._indexOnClick = this._indexOnClick || 0;
    }

    setIndexToCurrent = (): void => {
        this.indexModal = this._indexOnClick;
    };

    showImageModal = (): void => {
        document.addEventListener('keydown', this._onKeyDown);
        document.body.classList.add('overflow-hidden');
    };

    hideImageModal = (): void => {
        this.indexModal = undefined;
        document.removeEventListener('keydown', this._onKeyDown);
        document.body.classList.remove('overflow-hidden');
    };

    previousImage = (): void => {
        this.indexModal = this.imageIsFirst() ? this.images.length - 1 : this._indexModal - 1;
    };

    nextImage = (): void => {
        this.indexModal = this.imageIsLast() ? 0 : this._indexModal + 1;
    };

    imageIsFirst = (): boolean => {
        return this._indexModal === 0;
    };

    imageIsLast = (): boolean => {
        return this._indexModal === this.images.length - 1;
    };

    private _onKeyDown = (event: KeyboardEvent): void => {
        // Time is a lie 🍰

        switch (event.key) {
            case 'Escape':
                this.$timeout(this.hideImageModal);
                break;
            case 'ArrowLeft':
            case 'a':
            case 'w':
                this.$timeout(this.previousImage);
                break;
            case 'ArrowRight':
            case 'd':
            case 's':
                this.$timeout(this.nextImage);
                break;
            default:
                break;
        }
    };

    /**
     * If activeIndex is defined the modal opens
     */
    get indexModal(): number {
        return this._indexModal;
    }

    set indexModal(v) {
        if (this._indexModal === undefined && v !== undefined) {
            this.showImageModal();
        }

        this._indexModal = v;
    }
}

export class MoleculeLightboxComponent implements ng.IComponentOptions {
    transclude = true;
    bindings = {
        _indexOnClick: '<?indexOnClick',
        images: '<',
        indexModal: '=?'
    };
    controller = MoleculeLightboxController;
    template = require('./lightbox.html');
}
