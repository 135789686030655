import * as ng from 'angular';
import * as Types from '../../../types';

import { PanelHeaderData } from '../../../atomic-components/molecules/panels';
import { WizardObject } from '../../../atomic-components/molecules/wizard';

export class TemplateDomainWizardController {
    public static $inject: string[] = ['$state', '$translate'];

    public bundle: { id: any };
    public webspace: { id: any; bundleId: string; accountId: any };
    public machine: { id: any };
    public managedServerWebspaceItems: Types.WebhostingApi.Webspace[];
    public ownerAccount: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    public webserverRessource: any;

    public pageHeaderData: PanelHeaderData;
    public serviceObjects: WizardObject[];

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        const panelTitle = this.$translate.instant('TR_090119-fec2dd_TR');
        const stateInBundle = this.$state.current.name.split('.')[0] === 'bundle';
        const stateInWebhosting = this.$state.current.name.split('.')[0] === 'webhosting';
        const stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';

        let panelHeaderRoute = 'domain.dashboard';
        let panelHeaderRouteParams = null;
        let backwardText = this.$translate.instant('TR_110119-774118_TR');

        if (stateInBundle) {
            panelHeaderRoute = 'bundle.id.domains.overview';
            panelHeaderRouteParams = {bundleId: this.bundle.id};
            backwardText = this.$translate.instant('TR_110119-7424eb_TR');
        }

        if (stateInMachine) {
            panelHeaderRoute = 'managed-servers.id.dashboard';
            panelHeaderRouteParams = {machineId: this.machine.id};
            backwardText = this.$translate.instant('TR_220319-1ac2e3_TR');
        }

        const routeParts = this.$state.current.name.split('.');
        if (stateInWebhosting) {
            switch (routeParts[1]) {
                case 'vhosts':
                    panelHeaderRoute = 'webhosting.vhosts.overview';
                    panelHeaderRouteParams = null;
                    backwardText = this.$translate.instant('TR_110119-7424eb_TR');
                    break;
                case 'webspaces':
                    panelHeaderRoute = 'webhosting.webspaces.id.edit';
                    panelHeaderRouteParams = { webspace: this.webspace.id };
                    backwardText = this.$translate.instant('TR_240220-7de727_TR');
                    break;
                default:
                    break;
            }
        }

        this.pageHeaderData = {
            backwardLink: panelHeaderRoute,
            backwardText: backwardText,
            panelHeaderRoute: panelHeaderRoute,
            panelHeaderRouteParams: panelHeaderRouteParams,
            panelIcon: 'globe',
            panelTitle: panelTitle
        };
    }
}

export class TemplateDomainWizardComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<',
        machine: '<?',
        managedServerWebspaceItems: '<?',
        ownerAccount: '<?',
        webserverRessource: '<?',
        webspace: '<'
    };
    public template = require('./domain-wizard-template.html');
    public controller = TemplateDomainWizardController;
}
