import * as ng from 'angular';
import { ProductHelperService } from '@/services/helpers/product-helper';
import { ProductsRobotService } from '@/services/products/robot';
import * as Types from '@/types';

export class ProductsModelService {
    public static $inject: string[] = ['$translate', 'productsRobot'];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private productsRobot: ProductsRobotService
    ) {}

    public findOne = (
        productCode: string,
        language?: string,
        replaceTemplatePlaceholders = false
    ): PromiseLike<Types.UI.APISingleResponse<Types.ProductApi.AbstractProduct>> => {
        if (!language) {
            language = this.$translate.use();
        }

        return this.productsRobot.productFind(productCode, language).then(
            (reply) => {
                if (!replaceTemplatePlaceholders) {
                    return reply;
                }

                if (reply.response === undefined || reply.response.type !== 'TemplateProduct') {
                    return reply;
                }

                for (const cycle of reply.response.billingCycles) {
                    const replacedTemplateCopy = JSON.parse(JSON.stringify(reply).replace(/##BILLING_CYCLE##/g, cycle));
                    if (replacedTemplateCopy.response.productCodeTemplate === productCode) {
                        replacedTemplateCopy.response.productCode = productCode;
                        replacedTemplateCopy.response.billingCycle = cycle;
                        reply = replacedTemplateCopy;

                        break;
                    }
                }
                return reply;
            }
        );
    };

    public findStorageProduct = (
        productCode: string,
        productInfo: Types.ProductApi.TemplateProduct,
        language?: string
    ): PromiseLike<Types.UI.APIResponse<Types.ProductApi.AbstractProduct>> => {
        let storageProductCode: string = ProductHelperService.wrapSpecificationItems(
            productInfo.specificationItems
        ).storageProductCode?.value as string || '';

        if (productInfo.billingCycles !== null && productInfo.billingCycles !== undefined) {
            for (const cycle of productInfo.billingCycles) {
                const productCodeTemplate = productInfo.productCodeTemplate;
                const newProductCodeTemplate = productCodeTemplate.replace('##BILLING_CYCLE##', String(cycle));

                if (newProductCodeTemplate === productCode) {
                    storageProductCode = storageProductCode.replace('##BILLING_CYCLE##', String(cycle));
                }
            }
        }

        return this.findOne(storageProductCode, language, true);
    };

    public findProducts = (
        service: string,
        productFamily: string,
        language?: string,
        withRelatedProducts?: boolean
    ) => {
        return this.productsRobot.productsFind(service, productFamily, language, withRelatedProducts);
    };

    public findProduct = (productCode, language?, accountId?: string) => {
        return this.productsRobot.productFind(productCode, language, accountId);
    };

    public findProductFamilyDescription = (service: string, productFamily: string, language?: string) => {
        return this.productsRobot.productFamilyDescriptionFind(service, productFamily, language);
    };

    public findKeywordDescriptions = (keywords: any[], language?: string) => {
        return this.productsRobot.keywordDescriptionsFind(keywords, language);
    };

    public findKeywordCategoryDescriptions = (categories: string[], language?: string) => {
        return this.productsRobot.keywordCategoryDescriptionsFind(categories, language);
    };
}
