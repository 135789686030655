import { ModelHelper } from '../model-helper';
import { ResourceRobotService } from './robot';

export class ResourceModelService {
    public static $inject: string[] = ['resourceRobot'];

    constructor(
        private resourceRobot: ResourceRobotService
    ) {}

    public poolsList(limit?, page?, filters?, sort?, cancel?, owner?) {
        sort = sort || {
            field: 'poolId',
            order: 'ASC'
        };
        page = page || 1;
        limit = limit || 10;

        return this.resourceRobot.listPools(filters, limit, page, sort, {timeout: cancel}, owner)
            .then(ModelHelper.returnListResponse);
    }

    public databaseServersFind(filter?, limit?, page?, sort?) {
        return this.resourceRobot.databaseServersFind(filter, limit, page, sort)
            .then(ModelHelper.returnListResponse);
    }

    public webserversFind(filter?, limit?, page?, sort?) {
        return this.resourceRobot.webserversFind(filter, limit, page, sort)
            .then(ModelHelper.returnListResponse);
    }

    public findMachineHost(filter?, limit?, page?, sort?) {
        return this.resourceRobot.findMachineHost(filter, limit, page, sort)
            .then(ModelHelper.returnFindOneResponse);
    }

    public hasPoolWithResources(type, owner?) {
        const sort = {
            field: 'poolId',
            order: 'ASC'
        };
        const page = 1;
        const limit = 10;

        return this.resourceRobot.listPools(null, limit, page, sort, {}, owner)
            .then(ModelHelper.returnListResponse)
            .then(
                result => {
                    if ([undefined, null].indexOf(result.data) >= 0 || result.data.length === 0) {
                        return false;
                    }

                    const pools = result.data.filter(
                        (pool) => {
                            return pool.availableResources.indexOf(type) >= 0;
                        }
                    );

                    return !([undefined, null].indexOf(pools) >= 0 || pools.length === 0);
                }
            );
    }

    public vmHostList(limit?, page?, filters?, sort?, cancel?) {
        page = page || 1;
        limit = limit || 10;

        return this.resourceRobot.listVmHosts(filters, limit, page, sort, cancel)
            .then(ModelHelper.returnListResponse);
    }

    public resourceList(poolId, resourceType, limit?, page?, filters?, sort?, cancel?) {
        sort = sort || null;
        page = page || 1;
        limit = limit || 10;
        filters = filters || null;

        return this.resourceRobot.listResources(poolId, resourceType, filters, limit, page, sort, cancel)
            .then(ModelHelper.returnListResponse);
    }

    public findOne(type, id, limit?, page?, sort?): PromiseLike<any> {
        limit = limit || 0;
        page = page || 1;
        const filter = {field: type + 'Id', value: id};

        switch (type) {
            /*
            case 'IpNetwork':
                return this.resourceRobot.listIpNetwork(filter, limit, page, sort)
                    .then(ModelHelper.returnFindOneResponse);
            case 'Job':
                return this.resourceRobot.listJobs(filter, limit, page, sort).then(ModelHelper.returnFindOneResponse);
            case 'RipeAllocation':
                return this.resourceRobot.listRipeAllocations(
                    filter,
                    limit,
                    page,
                    sort).then(ModelHelper.returnFindOneResponse);
            case 'RoutingNetwork':
                return this.resourceRobot.listRoutingNetworks(
                    filter,
                    limit,
                    page,
                    sort).then(ModelHelper.returnFindOneResponse);
            case 'VLan':
                return this.resourceRobot.listVLans(filter, limit, page, sort).then(ModelHelper.returnFindOneResponse);
            case 'IpPool':
                return this.resourceRobot.listIpPools(filter, limit, page, sort)
                    .then(ModelHelper.returnFindOneResponse);
            */
            case 'Network':
                return this.resourceRobot.findNetworks(filter, limit, page, sort)
                    .then(ModelHelper.returnFindOneResponse);
            case 'Pool':
                return this.resourceRobot.listPools(filter, limit, page, sort).then(ModelHelper.returnFindOneResponse);
            /* case 'VirtualMachineHost':
                return resourceRobot.listVmHosts(filter, limit, page, sort).then(ModelHelper.returnFindOneResponse);*/
            default:
                return Promise.resolve([]);
        }
    }

    public listPrivateIpAddressRanges(filter, limit?, page?, sort?): PromiseLike<any> {
        limit = limit || 0;
        page = page || 1;

        return this.resourceRobot.listPrivateIpAddressRanges(filter, limit, page, sort);
    }

    public listPublicIpAddressRanges(filter, limit?, page?, sort?): PromiseLike<any> {
        limit = limit || 0;
        page = page || 1;

        return this.resourceRobot.listPublicIpAddressRanges(filter, limit, page, sort);
    }

    public createPool(pool) {
        return this.resourceRobot.createPool(pool);
    }
}
