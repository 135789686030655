import * as ng from 'angular';
import { UiRights } from '../../configuration';
import { AuthContextService } from '../auth-context';
import { UserSettingsMigratorService } from './user-settings-migrator';
import { UserSettingsModelService } from './user-settings-model';

export const UiSettingPaginationLimit = ['5', '10', '25', '50', '100', '250'];

type UISettings = any;

export class UserSettingsManagerService {
    public static $inject: string[] = [
        '$rootScope',
        'userSettingsMigrator',
        'userSettingsModel'
    ];

    private cachedUiSettings: Promise<UISettings> = null;
    private cachedResolvedSettings: any = {};

    constructor(
        private $rootScope: ng.IRootScopeService,
        private userSettingsMigrator: UserSettingsMigratorService,
        private userSettingsModel: UserSettingsModelService
    ) {
        this.$rootScope.$on('login', this.load);
        this.$rootScope.$on('logout', () => this.cachedUiSettings = null);
    }

    public get lastResolvedSettings() {
        return this.cachedResolvedSettings;
    }

    public getReadNews = async (): Promise<any> => {
        const uiSettings = await this.load();

        return uiSettings?.readNews;
    };

    public addReadNews = async (readNews): Promise<void> => {
        const uiSettings = await this.load();

        uiSettings.readNews = uiSettings.readNews.concat(readNews);

        await this.saveNewSettings(uiSettings);
        await this.refresh();
    };

    public isShowId = async (): Promise<boolean> => {
        const userUiSettings = await this.getUiSettings();

        return userUiSettings?.displaySettings?.showObjectIds === true;
    };

    public showAdvancedDnsSettings = async (): Promise<boolean> => {
        const userUiSettings = await this.getUiSettings();

        return userUiSettings?.displaySettings?.showAdvancedDnsSettings === true;
    };

    public showAdvancedPhpSettings = async (): Promise<boolean> => {
        const userUiSettings = await this.getUiSettings();

        return userUiSettings?.displaySettings?.showAdvancedPhpSettings === true;
    };

    public saveSettingsForOverview = async (settings): Promise<void> => {
        const uiSettings = await this.doLoad();

        for (const ctrl in settings) {
            if (settings.hasOwnProperty(ctrl)) {
                uiSettings.overviewSettings[ctrl] = settings[ctrl];
                this.setSettingsForOverview(ctrl, settings, true);
            }
        }

        await this.saveNewSettings(uiSettings);
    };

    public saveReadMessage = async (hasViewed: boolean, messageId: string): Promise<void> => {
        const uiSettings = await this.doLoad();

        const isset = uiSettings.messages.some(
            (message) => {
                if (message.id === messageId) {
                    message.viewed = hasViewed;

                    return true;
                }

                return false;
            }
        );

        if (!isset) {
            uiSettings.messages.push({ id: messageId, viewed: hasViewed });
        }

        await this.saveNewSettings(uiSettings);
        await this.refresh();
    };

    public saveDisplaySettings = async (settings): Promise<void> => {
        const uiSettings = await this.doLoad();

        for (const settingName in settings) {
            if (settings.hasOwnProperty(settingName)) {
                uiSettings.displaySettings[settingName] = settings[settingName];
            }
        }

        await this.saveNewSettings(uiSettings);
    };

    public setSettingsForOverview = async (ctrl, settings, doNotSave: boolean = false): Promise<void> => {
        const uiSettings = await this.load();

        if (AuthContextService.isGranted(UiRights.ACC_USER_LOGGED_IN_AS_SUBACCOUNT)) {
            localStorage.setItem(`${ctrl}_${AuthContextService.user.id}`, JSON.stringify(settings[ctrl]));
        } else {
            if (UiSettingPaginationLimit.indexOf(`${settings[ctrl].paginationLimit}`) < 0) {
                settings[ctrl].paginationLimit = 10;
            }

            uiSettings.overviewSettings[ctrl] = settings[ctrl];

            if (doNotSave) {
                this.refresh();

                return;
            }

            await this.saveNewSettings(uiSettings);
        }
    };

    public getSettingsForOverview = async (ctrl?) => {
        // get all service overview settings, if no ctrl is defined
        if (ctrl === undefined) {
            const promises = [
                'AccountOverviewCtrl',
                'ApiKeyOverviewCtrl',
                'BundleOverviewCtrl',
                'DatabaseOverviewCtrl',
                'DnsOverviewCtrl',
                'DomainOverviewCtrl',
                'EmailOverviewCtrl',
                'NetworkOverviewCtrl',
                'SslCertificateOverviewCtrl',
                'StorageOverviewCtrl',
                'VirtualMachineOverviewCtrl',
                'WebspaceOverviewCtrl',
                'NetworkOverviewCtrl'
            ]
            .map(
                async (serviceCtrl) => {
                    const serviceSettings = await this.getSettingsForOverview(serviceCtrl);

                    return {
                        overviewUiSettings: serviceSettings,
                        serviceCtrl: serviceCtrl
                    };
                }
            );

            const collectedSettings = await Promise.all(promises);

            const allUiSettings: any = {};

            for (const setting of collectedSettings) {
                allUiSettings[setting.serviceCtrl] = setting.overviewUiSettings;
            }

            return allUiSettings;
        }

        const uiSettings = await this.load();

        let settings = localStorage.getItem(`${ctrl}_${AuthContextService.user.id}`);
        settings = ['undefined', undefined, '[object Object]'].indexOf(settings) >= 0
            ? null
            : JSON.parse(settings);

        if (AuthContextService.isGranted(UiRights.ACC_USER_LOGGED_IN_AS_SUBACCOUNT)) {
            try {
                let overviewSettings;

                if (!settings) {
                    this.setSettingsForOverview(ctrl, this.getOverviewSettingsDefault());

                    return this.getOverviewSettingsDefault();
                }

                if (typeof(settings) !== 'number') {
                    return settings;
                }

                if (UiSettingPaginationLimit.indexOf(settings) < 0) {
                    settings = '10';
                }

                overviewSettings = this.getOverviewSettingsDefault();
                overviewSettings.paginationLimit = settings;
                this.setSettingsForOverview(ctrl, overviewSettings);

                return overviewSettings;
            } catch (err) {
                this.setSettingsForOverview(ctrl, this.getOverviewSettingsDefault());

                return this.getOverviewSettingsDefault();
            }
        } else {
            if (
                !uiSettings?.overviewSettings
                || uiSettings.overviewSettings[ctrl] === undefined
            ) {
                return this.getOverviewSettingsDefault();
            }

            if (
                UiSettingPaginationLimit.indexOf(String(uiSettings.overviewSettings[ctrl].paginationLimit)) < 0
            ) {
                uiSettings.overviewSettings[ctrl].paginationLimit = 10;
                this.saveNewSettings(uiSettings);
            }

            return uiSettings.overviewSettings[ctrl];
        }
    };

    public getProductDefaultView = async (product): Promise<any> => {
        const uiSettings = await this.load();

        if (uiSettings?.productDefaultView?.hasOwnProperty(product)) {
            return uiSettings.productDefaultView[product];
        }

        return product;
    };

    public getUiDisplaySettings = async (singleSetting?: string): Promise<any> => {
        const uiSettings = await this.load();

        if (uiSettings?.displaySettings?.hasOwnProperty(singleSetting)) {
            return uiSettings.displaySettings[singleSetting];
        } else {
            return uiSettings?.displaySettings;
        }
    };

    public setProductDefaultView = async (product, state): Promise<void> => {
        const uiSettings = await this.load();

        if (uiSettings?.productDefaultView !== undefined) {
            uiSettings.productDefaultView[product] = (state === uiSettings.productDefaultView[product])
                ? undefined
                : state;
        } else {
            uiSettings.productDefaultView = {};
            uiSettings.productDefaultView[product] = state;
        }

        await this.saveNewSettings(uiSettings);
    };

    public refreshCachedUiSettings = async (): Promise<UISettings> => this.refresh();

    public getUiSettings = async (): Promise<UISettings> => this.load();

    private saveNewSettings = async (uiSettings): Promise<void> => {
        await this.userSettingsModel.update(JSON.stringify(uiSettings));

        this.cachedUiSettings = Promise.resolve(uiSettings);
    };

    private doLoad = async (): Promise<UISettings> => {
        let uiSettings: any;

        try {
            uiSettings = await this.userSettingsModel.loadSettings();
            uiSettings = JSON.parse(uiSettings);
        } catch ({}) {
            // Obacht! Dieser leere Handler verhindert, das der state-change von der AVV-Seite zum
            // Dashboard schiefgeht mit der Meldung:
            // Possibly unhandled rejection: cancelled by state change using navigation service.
            // Sollte in Zukunft eine bessere Möglichkeit gefunden werden...
            // Kommt auch vor in `app/scripts/state.ts`.

            return;
        }

        if (!uiSettings) {
            // Since we have some Sentry messages saying uiSettings was undefined,
            // I'm setting it to an empty object for the rest of this method to fill.

            uiSettings = {};
        }

        let settingsHaveChanged = false;

        if (!this.userSettingsMigrator.isCurrentVersion(uiSettings)) {
            uiSettings = this.userSettingsMigrator.migrate(uiSettings);
            settingsHaveChanged = true;
        }

        if (uiSettings.readNews === undefined) {
            uiSettings.readNews = [];
            settingsHaveChanged = true;
        }

        if (uiSettings.messages === undefined) {
            uiSettings.messages = [];
            settingsHaveChanged = true;
        }

        if (uiSettings.overviewSettings === undefined) {
            uiSettings.overviewSettings = {
                global: {
                    loadDataOnInit: true,
                    paginationLimit: 10,
                    panelView: true
                }
            };
            settingsHaveChanged = true;
        }

        if (uiSettings.displaySettings === undefined) {
            uiSettings.displaySettings = {
                showAdvancedDnsSettings: false,
                showObjectIds: false
            };
            settingsHaveChanged = true;
        }

        if (uiSettings.productDefaultView === undefined) {
            // set default product views
            uiSettings.productDefaultView = {};
            settingsHaveChanged = true;
        }

        if (uiSettings.showProductDefaultView === undefined) {
            // set default show product default view buttons
            uiSettings.showProductDefaultView = false;
            settingsHaveChanged = true;
        }

        if (uiSettings.showLoadOverviewDataOnInit === undefined) {
            // set default show load instantly toggle on overviews
            uiSettings.showLoadOverviewDataOnInit = false;
            settingsHaveChanged = true;
        }

        if (uiSettings.showWelcomeMessage === undefined) {
            // set welcome message on first entry
            uiSettings.showWelcomeMessage = false;
            settingsHaveChanged = true;
        }

        if (settingsHaveChanged) {
            await this.saveNewSettings(uiSettings);
        }

        this.cachedResolvedSettings = uiSettings;

        return uiSettings;
    };

    private load = async (): Promise<UISettings> => {
        if (this.cachedUiSettings === null) {
            this.cachedUiSettings = this.doLoad();
        }

        return this.cachedUiSettings;
    };

    private refresh = async (): Promise<UISettings> => {
        this.cachedUiSettings = null;

        return this.load();
    };

    private getOverviewSettingsDefault = (): any => {
        return {
            expertView: false,
            loadDataOnInit: true,
            paginationLimit: 10
        };
    };
}
