import * as ng from 'angular';

export class AtomHtmlController implements ng.IController {
    public static $inject: string[] = ['$sce'];

    public html: string;

    constructor(
        private $sce: ng.ISCEService
    ) {}

    private trustAsHtml = () => {
        if (this.html !== undefined) {
            return this.$sce.trustAsHtml(this.html);
        }
        return '';
    };
}

export class AtomHtmlComponent implements ng.IComponentOptions {
    public bindings = {
        html: '@'
    };
    public template = '<span ng-bind-html="$ctrl.trustAsHtml()"></span>';
    public controller =  AtomHtmlController;
}
