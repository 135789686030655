import ng from 'angular';

import { PageHeaderData } from '@/atomic-components';
import { UiRights } from '@/configuration';
import {
    AuthContextService, PriceHelperService,
    ProductHelperService,
    ProductsModelService,
    VhostModelService,
    WebhostingHelperService,
    WebhostingJobModelService
} from '@/services';
import { WebhostingApi } from '@/types';

export class TemplateWebspaceEditController {
    public static $inject: string[] = [
        '$state',
        '$translate',
        'priceHelper',
        'productsModel',
        'vhostModel',
        'webhostingHelper',
        'webhostingJobModel'
    ];

    public modifier = '';
    public pageHeaderData: PageHeaderData;
    public viewPanelArea = [];
    public webspace: WebhostingApi.Webspace;
    public userPanelRight: any;
    public showObjectId: boolean;
    public webspaceHasUnfinishedJob = false;

    private vHosts: WebhostingApi.VHost[] = [];
    private domainPanelForDeletedWebspace = {
        includeShowAllLink: false,
        panelIcon: 'globe',
        panelList: [this.$translate.instant('TR_090119-b41503_TR')],
        panelTitle: this.$translate.instant('f8db83bf-9ba0-4984-9c93-5070616505fa'),
        panelType: 'domain'
    };

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        public priceHelper: PriceHelperService,
        private productsModel: ProductsModelService,
        private vhostModel: VhostModelService,
        private webhostingHelper: WebhostingHelperService,
        private webhostingJobModel: WebhostingJobModelService
    ) {}

    public $onInit = (): void => {
        const webspace = this.webspace;
        const stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';

        if (AuthContextService.isGranted(UiRights.WEB_JOBS_LIST)) {
            void this.webhostingJobModel.findRunningJobsForWebspace(this.webspace)
            .then(
                (result) => {
                    if (result.status === 'success') {
                        this.webspaceHasUnfinishedJob = result.response.data.length > 0;
                    }
                }
            );
        }

        void this.vhostModel.list(100, 1, { field: 'WebspaceId', value: webspace.id })
        .then(
            (result: Partial<WebhostingApi.FindVHostsResult>) => {
                this.vHosts = result.data;

                this.domainPanelForDeletedWebspace.panelList =
                    result.data.length === 0
                    ? [this.$translate.instant('TR_140119-babc81_TR')] as string[]
                    : result.data.map((vHost) => vHost.domainNameUnicode);
            }
        );

        this.pageHeaderData = {
            backwardLink: stateInMachine ? 'managed-servers.id.webspaces.overview' : 'webhosting.webspaces.overview',
            backwardText: this.$translate.instant('TR_140119-abce37_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: stateInMachine
                ? { machineId: this.$state.$current.locals.globals.machine.id }
                : null,
            panelIcon: 'server',
            panelTitle: webspace.name
        };

        if (AuthContextService.isGrantedAny([
            UiRights.WEB_ADMIN_EMAIL_SENDER,
            UiRights.WEB_ADMIN_WEBSPACE_ENABLE_DISABLE
        ])) {
            this.pageHeaderData.productActions = this.pageHeaderData.productActions || [];
            this.pageHeaderData.productActions.push({
                route: 'webhosting.webspaces.id.admin-options',
                title: this.$translate.instant('TR_140119-62f46e_TR')
            });
        }

        this.fillLeftPanelArea();

        this.userPanelRight = {
            deletion: {
                delete: AuthContextService.isGranted(UiRights.WEB_WEBSPACE_DELETE),
                editPanelButton: AuthContextService.isGranted(UiRights.WEB_WEBSPACE_DELETE)
            },
            general: {
                editPanelButton: AuthContextService.isGranted(UiRights.WEB_WEBSPACE_EDIT),
                editPassword: AuthContextService.isGranted(UiRights.WEB_WEBSPACE_EDIT)
            },
            redis: {
                editPanelButton: AuthContextService.isGranted(UiRights.WEB_WEBSPACE_EDIT),
                overrideStorageQuota: AuthContextService.isGranted(UiRights.WEB_WEBSPACE_EDIT)
            },
            storageQouta: {
                editPanelButton: AuthContextService.isGranted(UiRights.WEB_WEBSPACE_EDIT),
                overrideStorageQuota: AuthContextService.isGranted(UiRights.WEB_WEBSPACE_EDIT)
            }
        };
    };

    // eslint-disable-next-line complexity
    private fillLeftPanelArea = (): void => {
        this.viewPanelArea = [];
        const stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';

        const domainPanel = {
            includeShowAllLink: true,
            panelHeaderRoute: stateInMachine
                ? 'managed-servers.id.webspaces.id.vhosts.overview'
                : 'webhosting.webspaces.id.vhosts.overview',
            panelIcon: 'globe',
            panelItems: [
                {
                    route: stateInMachine
                        ? 'managed-servers.id.webspaces.id.vhosts.new'
                        : 'webhosting.webspaces.id.vhosts.new',
                    text: this.$translate.instant('TR_090119-fec2dd_TR')
                },
                {
                    route: stateInMachine
                        ? 'managed-servers.id.webspaces.id.vhosts.new'
                        : 'webhosting.webspaces.id.vhosts.new',
                    routeParams: { domainType: 'subdomain'},
                    text: this.$translate.instant('TR_090119-719dc6_TR')
                },
                {
                    route: stateInMachine
                        ? 'managed-servers.id.webspaces.id.vhosts.overview'
                        : 'webhosting.webspaces.id.vhosts.overview',
                    text: this.$translate.instant('WEBHOSTING.VHOST.ACTION.INSTALL')
                },
                {
                    route: stateInMachine
                        ? 'managed-servers.id.webspaces.id.vhosts.overview'
                        : 'webhosting.webspaces.id.vhosts.overview',
                    text: this.$translate.instant('TR_090119-a9f116_TR')
                },
                {
                    route: stateInMachine
                        ? 'managed-servers.id.webspaces.id.vhosts.overview'
                        : 'webhosting.webspaces.id.vhosts.overview',
                    text: this.$translate.instant('TR_090119-552567_TR')
                }
            ],
            panelTitle: this.$translate.instant('TR_140119-aeb48a_TR'),
            panelType: 'domain',
            utilityStatus: {
                infoText: this.$translate.instant('TR_140119-aeb48a_TR'),
                isStorage: false,
                showAbsolute: true
            }
        };

        Object.defineProperty(
            domainPanel.utilityStatus,
            'inUse',
            {
                get: () => this.vHosts.length
            }
        );

        this.viewPanelArea.push(domainPanel);
        this.viewPanelArea.push({
            includeShowAllLink: false,
            includeShowDetailsLink: true,
            panelHeaderRoute: stateInMachine
                ? 'managed-servers.id.webspaces.id.storage'
                : 'webhosting.webspaces.id.storage',
            panelIcon: 'save',
            panelItems: [
                {
                    route: stateInMachine
                        ? 'managed-servers.id.webspaces.id.storage'
                        : 'webhosting.webspaces.id.storage',
                    text: this.$translate.instant('TR_090119-cd1f87_TR')
                }
            ],
            panelTitle: this.$translate.instant('TR_STORAGE_TR'),
            panelType: 'webhosting-bundle-webspace-storage',
            utilityStatus: {
                inUse: this.webspace.storageUsed,
                infoText: this.$translate.instant('TR_090119-2fb308_TR'),
                isStorage: true,
                maxUse: this.webspace.storageQuota,
                showAbsolute: false
            }
        });

        this.viewPanelArea.push({
            includeShowAllLink: false,
            includeShowDetailsLink: false,
            panelIcon: 'battery-bolt',
            panelItems: [
                {
                    route: stateInMachine
                        ? 'managed-servers.id.webspaces.id.php-boost'
                        : 'webhosting.webspaces.id.php-boost',
                    text: this.$translate.instant('TR_090123-2a0169_TR')
                },
                {
                    route: stateInMachine
                        ? 'managed-servers.id.webspaces.id.php-boost'
                        : 'webhosting.webspaces.id.php-boost',
                    text: this.$translate.instant('TR_070619-026a9c_TR')
                },
                {
                    route: stateInMachine
                        ? 'managed-servers.id.webspaces.id.php-boost'
                        : 'webhosting.webspaces.id.php-boost',
                    text: this.$translate.instant('TR_070619-a0117f_TR')
                }
            ],
            panelTitle: this.$translate.instant('TR_070619-458016_TR'),
            panelType: 'php-boost'
        });

        this.viewPanelArea.push({
            includeShowAllLink: true,
            panelHeaderRoute: stateInMachine
                ? 'managed-servers.id.webspaces.id.ftp-ssh'
                : 'webhosting.webspaces.id.ftp-ssh',
            panelIcon: 'folder',
            panelItems: [
                {
                    route: stateInMachine
                        ? 'managed-servers.id.webspaces.id.ftp-ssh.new'
                        : 'webhosting.webspaces.id.ftp-ssh.new',
                    text: this.$translate.instant('879a34f5-b968-4abe-a9fb-f602abfed914')
                }
            ],
            panelTitle: this.$translate.instant('TR_080119-3313e9_TR'),
            panelType: 'webhosting-user',
            utilityStatus: null
        });

        if (AuthContextService.isGranted(UiRights.BACKUP_BACKUP_LIST)) {
            this.viewPanelArea.push({
                includeShowAllLink: true,
                panelHeaderRoute: stateInMachine
                    ? 'managed-servers.id.webspaces.id.backups.overview'
                    : 'webhosting.webspaces.id.backups.overview',
                panelIcon: 'hdd',
                panelItems: [
                    {
                        route: stateInMachine
                            ? 'managed-servers.id.webspaces.id.backups.overview'
                            : 'webhosting.webspaces.id.backups.overview',
                        text: this.$translate.instant('9f19ed27-8fb4-448f-bf72-6bd67d785c47')
                    },
                    {
                        route: stateInMachine
                            ? 'managed-servers.id.webspaces.id.backups.overview'
                            : 'webhosting.webspaces.id.backups.overview',
                        text: this.$translate.instant('BACKUP.ACTION.RESTORE.TITLE')
                    }
                ],
                panelTitle: this.$translate.instant('TR_080119-57a2c9_TR'),
                panelType: 'backups-bundle',
                utilityStatus: null
            });
        }

        this.viewPanelArea.push({
            includeShowAllLink: true,
            panelHeaderRoute: stateInMachine
                ? 'managed-servers.id.webspaces.id.cronjobs.overview'
                : 'webhosting.webspaces.id.cronjobs.overview',
            panelIcon: 'calendar-alt',
            panelItems: [
                {
                    route: stateInMachine
                        ? 'managed-servers.id.webspaces.id.cronjobs.new'
                        : 'webhosting.webspaces.id.cronjobs.new',
                    text: this.$translate.instant('TR_090119-f8a120_TR')
                }
            ],
            panelTitle: this.$translate.instant('STATE.WEBHOSTING.WEBSPACE.CRON.PAGE-TITLE'),
            panelType: 'cronjobs',
            utilityStatus: null
        });

        void this.productsModel.findOne(this.webspace.productCode).then((reply) => {
            const includedStorage = ProductHelperService.wrapSpecificationItems(
                reply.response.specificationItems
            ).includedStorage?.intValue;

            this.viewPanelArea
                .filter((panel: Record<string, unknown>) => panel.panelType === 'webhosting-bundle-webspace-storage')
                .forEach(
                    (panel: Record<string, unknown>) => {
                        Object.defineProperty(
                            panel.utilityStatus,
                            'addon',
                            {
                                get: () => Math.abs(
                                    ((panel.utilityStatus as Record<string, unknown>).maxUse as number)
                                    - includedStorage
                                )
                            }
                        );
                    }
                );
        });

        const webspaceRestrictionData = this.webhostingHelper.handleWebspaceRestriction(this.webspace);
        webspaceRestrictionData.map(
            (data) => {
                this.viewPanelArea.unshift({
                    callout: {
                        message: `<p>${data.message}</p><p>${data.comment}</p>`,
                        type: 'warning'
                    },
                    includeShowAllLink: false,
                    panelItems: [],
                    panelTitle: data.title,
                    panelType: 'disabled'
                });
            }
        );

        if (this.webspace.status === 'restorable') {
            this.viewPanelArea = [this.domainPanelForDeletedWebspace];
        }
    };
}

export class TemplateWebspaceEditComponent implements ng.IComponentOptions {
    public bindings = {
        showObjectId: '<',
        webspace: '<'
    };

    public controller = TemplateWebspaceEditController;
    public controllerAs = '$TemplateWebspaceEditCtrl';
    public template = require('./webspace-edit-template.html');
}
