import * as ng from 'angular';
import {
    AuthContextService,
    CleanableStringType,
    ProductHelperService,
    StringCleaner
} from '../';
import { UiLanguagesConst } from '../../configuration';
import * as Types from '../../types';
import { DomainApi, ProductApi } from '../../types';
import { ViewTypes } from '../../types/view-types';

export class DnsComposerService {
    public static $inject: string[] = [
        '$translate',
        'ddnsUserNameFilter',
        'productHelper'
    ];

    private _domainStatus: DomainApi.DomainStatusResult;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private ddnsUserNameFilter,
        private productHelper: ProductHelperService
    ) {}

    public buildDnsApiObject = async (metadata: ViewTypes.ProductConfigDnsObject) => {
        switch (metadata.productCode) {
            case 'copy':
                return await this._buildDnsComposerObjectModeCopy(metadata);

            case 'expert':
                return await this._buildDnsComposerObjectModeExpert(metadata);

            case 'quick':
                return await this._buildDnsComposerObjectModeQuick(metadata);

            case 'slave':
                return await this._buildDnsComposerObjectModeSlave(metadata);

            case 'template':
                return await this._buildDnsComposerObjectModeTemplate(metadata);
        }
    };

    public buildDdnsApiObject = async (metadata: ViewTypes.ProductConfigDdnsObject) => {
        const factSheetItems = [];
        const existingUser = [undefined, null].indexOf(metadata.existingUserId) < 0;
        const userName = existingUser ? await this.ddnsUserNameFilter(metadata.existingUserId, 'name')
            : metadata.newUser.name;
        const userLogin = existingUser ? await this.ddnsUserNameFilter(metadata.existingUserId, 'userName')
            : metadata.newUser.userName;

        factSheetItems.push({
            description: '',
            label: this.$translate.instant('TR_250920-84ea79_TR'),
            type: 'string',
            value: metadata.fqdn
        });

        factSheetItems.push({
            description: '',
            label: 'DDNS ' + this.$translate.instant('TR_130319-0cb933_TR'),
            type: 'string',
            value: `${userName} (${this.$translate.instant('TR_110119-db7b0b_TR')}: ${userLogin})`
        });

        const composerObject: Types.ViewTypes.WizardComposerObject = {
            apiObject: {
                account: metadata.account,
                existingUser: existingUser,
                existingUserId: metadata.existingUserId,
                fqdn: metadata.fqdn,
                newUser: metadata.newUser,
                password: metadata.password,
                comment: metadata.comment,
                productCode: metadata.productCode
            },
            summaryObject: [
                {
                    account: metadata.account,
                    productCode: metadata.productCode,
                    factSheets: factSheetItems,
                    product: {
                        name: metadata.productCode,
                        nameType: 'productCode',
                        shortDescription: this.$translate.instant('TR_150920-a3b8f0_TR')
                    }
                }
            ],
            productFamily: metadata.productFamily,
            productCode: metadata.productCode
        };

        return composerObject;
    };

    private _getZoneNamesFactSheetItem = (fqdnList: string[]) => {
        return {
            description: '',
            label: this.$translate.instant('0e491b58-06c0-418d-847e-b27613fa703e'),
            type: 'stringArray',
            value: fqdnList
        };
    };

    private _getSummaryObject = async (
        metadata: ViewTypes.ProductConfigDnsObject,
        factSheetItems: any,
        itemName: string,
        payed = false
    ) => {
        let product;

        if (payed) {
            product = await this.productHelper.getProductFromProductCode(metadata.productCode);
        }

        return {
            account: metadata.account,
            factSheets: factSheetItems,
            product: payed ? product : {
                addGracePeriod: 0,
                billingCycle: 0,
                description: '',
                factSheetItems: [],
                family: metadata.productFamily,
                featured: false,
                language: UiLanguagesConst[AuthContextService.user.language],
                name: itemName,
                productCode: payed ? metadata.productCode : null,
                productCodeTemplate: null,
                productType: '',
                requiredCreationRight: '',
                restoreGracePeriod: null,
                shortDescription: '',
                specificationItems: [],
                type: 'Product',
                verificationAlternatives: []
            } as ProductApi.Product,
            productCode: payed ? metadata.productCode : null,
            objectAction: 'create'
        };
    };

    private _getZoneRecords = (metadata: ViewTypes.ProductConfigDnsObject, zoneName: string) => {
        let zoneRecords = ng.copy(metadata.records);
        const firstZoneName = metadata.fqdnList[0];

        // NS template has been selected! remove apex ns records that are part of the NS set
        if (metadata.nameserver?.values?.id?.length > 0 && metadata.nameserver?.values?.default === false) {
            zoneRecords = zoneRecords.filter((rec) => !(
                rec.type === 'NS'
                && (rec.name === '' || rec.name === zoneName || rec.name === firstZoneName)
                && metadata.nameserver.values.nameservers.some((nsRec) => nsRec.name === rec.content)
            ));
        }

        // remove all apex NS records if the default nameservers are being used
        zoneRecords = zoneRecords.filter(
            (rec) => (
                !(rec.type === 'NS' && (rec.name === '' || rec.name === zoneName || rec.name === firstZoneName))
                || !metadata.nameserver.values.default
            )
        );

        // remove all template records (these records are added API side via the given template)
        zoneRecords = zoneRecords.filter(
            (record) => !(record.recordTemplateId?.length > 0)
        );

        zoneRecords = zoneRecords.map(
            (record) => {
                if ([null, undefined].indexOf(record.ttl) === -1) {
                    record.ttl = parseInt('' + record.ttl, 10);
                }

                if ([null, undefined].indexOf(record.priority) === -1) {
                    if ('' + record.priority === '') {
                        record.priority = 0;
                    } else {
                        record.priority = parseInt('' + record.priority, 10);
                    }
                }

                if (record.name.indexOf(firstZoneName) === -1) {
                    if (record.name.length > 0) {
                        record.name = record.name + '.' + zoneName;
                    } else {
                        record.name = zoneName;
                    }
                } else {
                    record.name.replace(metadata.fqdnList[0], zoneName);
                }

                record.content = record.content.replace(
                    firstZoneName,
                    zoneName
                );

                return record;
            }
        );

        return zoneRecords;
    };

    private _convertSoaValues = (values) => {
        for (const key of Object.keys(values)) {
            values[key] = typeof values[key] === 'number'
                ? values[key]
                : parseInt('' + values[key], 10);
        }

        return values;
    };

    private _buildDnsComposerObjectModeCopy = async (metadata) => {
        const composerObject: Types.ViewTypes.WizardComposerObject = this._getComposerBaseObject(metadata);
        const factSheetItems = [];

        factSheetItems.push(this._getZoneNamesFactSheetItem(metadata.fqdnList));

        for (const zoneName of metadata.fqdnList) {
            composerObject.apiObject.zoneConfig.push({
                name: zoneName,
                records: this._getZoneRecords(metadata, zoneName),
                options: metadata.copiedZoneConfig,
                nameserverSetId: metadata.nameserver.values.default
                    ? null
                    : (metadata.nameserver.values.id ? metadata.nameserver.values.id : -1),
                type: metadata.copiedZoneConfig.type,
                dnsSecOptions: {},
                owner: metadata.account.id
            });
        }

        composerObject.summaryObject.push(
            await this._getSummaryObject(
                metadata,
                factSheetItems,
                this.$translate.instant('97f4ed90-36d2-41fe-8e52-2455b38f7397')
            )
        );

        return composerObject;
    };

    private _buildDnsComposerObjectModeExpert = async (metadata) => {
        const composerObject: Types.ViewTypes.WizardComposerObject = this._getComposerBaseObject(metadata);
        const factSheetItems = [];

        factSheetItems.push(this._getZoneNamesFactSheetItem(metadata.fqdnList));

        for (const zoneName of metadata.fqdnList) {
            const hostmasterEmail = ng.copy(metadata.settings.emailAddress);
            const domainName = StringCleaner
                .clean(zoneName)
                .as(CleanableStringType.DomainName);

            composerObject.apiObject.zoneConfig.push({
                name: zoneName,
                records: this._getZoneRecords(metadata, zoneName),
                options: {
                    dnsSecMode: metadata.settings.dnsSecActive
                        ? 'AUTOMATIC'
                        : 'off',
                    emailAddress: hostmasterEmail.replace('##DOMAIN##', domainName),
                    soaValues: this._convertSoaValues(metadata.settings.soaValues),
                    zoneTransferWhitelist: metadata.settings.transferAllowed
                        ? metadata.settings.zoneTransferWhitelist
                        : []
                },
                nameserverSetId: metadata.nameserver.values.default
                    ? null
                    : (metadata.nameserver.values.id ? metadata.nameserver.values.id : -1),
                type: metadata.settings.transferAllowed && metadata.settings.zoneTransferWhitelist?.length > 0
                    ? 'MASTER'
                    : 'NATIVE',
                dnsSecOptions: metadata.settings.dnsSecActive ? metadata.settings.dnsSecOptions : {},
                owner: metadata.account.id
            });
        }

        composerObject.summaryObject.push(
            await this._getSummaryObject(
                metadata,
                factSheetItems,
                this.$translate.instant('97f4ed90-36d2-41fe-8e52-2455b38f7397')
            )
        );

        return composerObject;
    };

    private _buildDnsComposerObjectModeQuick = async (metadata) => {
        const composerObject: Types.ViewTypes.WizardComposerObject = this._getComposerBaseObject(metadata);
        const factSheetItems = [];

        factSheetItems.push(this._getZoneNamesFactSheetItem(metadata.fqdnList));

        for (const zoneName of metadata.fqdnList) {
            composerObject.apiObject.zoneConfig.push({
                name: zoneName,
                records: this._getZoneRecords(metadata, zoneName),
                options: {},
                nameserverSetId: metadata.nameserver.values.default
                    ? null
                    : (metadata.nameserver.values.id ? metadata.nameserver.values.id : -1),
                type: 'NATIVE',
                dnsSecOptions: {},
                owner: metadata.account.id
            });
        }

        composerObject.summaryObject.push(
            await this._getSummaryObject(
                metadata,
                factSheetItems,
                this.$translate.instant('97f4ed90-36d2-41fe-8e52-2455b38f7397')
            )
        );

        return composerObject;
    };

    private _buildDnsComposerObjectModeSlave = async (metadata) => {
        const composerObject: Types.ViewTypes.WizardComposerObject = this._getComposerBaseObject(metadata);
        const factSheetItems = [];

        factSheetItems.push(this._getZoneNamesFactSheetItem(metadata.fqdnList));

        factSheetItems.push({ // master IP
            description: '',
            label: this.$translate.instant('TR_110119-df5f10_TR'),
            type: 'string',
            value: metadata.masterIp
        });

        for (const zoneName of metadata.fqdnList) {
            composerObject.apiObject.zoneConfig.push({
                accountId: metadata.account.id,
                masterIp: metadata.masterIp,
                name: zoneName,
                type: 'SLAVE'
            });
        }

        composerObject.summaryObject.push(
            await this._getSummaryObject(
                metadata,
                factSheetItems,
                this.$translate.instant('TR_150920-aaebf8_TR')
            )
        );

        return composerObject;
    };

    private _buildDnsComposerObjectModeTemplate = async (metadata) => {
        const composerObject: Types.ViewTypes.WizardComposerObject = this._getComposerBaseObject(metadata);
        const factSheetItems = [];

        factSheetItems.push(this._getZoneNamesFactSheetItem(metadata.fqdnList));

        for (const zoneName of metadata.fqdnList) {
            const records = ng.copy(metadata.records);
            composerObject.apiObject.zoneConfig.push({
                name: zoneName,
                templateId: metadata.templateValues.templateId,
                tieTemplateToZone: metadata.templateValues.tieToTemplate,
                dnsTemplateReplacements: metadata.templateValues.templateReplacements,
                nameserverSetId: metadata.nameserver.values.default
                    ? null
                    : (metadata.nameserver.values.id ? metadata.nameserver.values.id : -1),
                account: metadata.account,
                records: (
                    !metadata.nameserver.values.default
                    && [null, undefined].indexOf(metadata.nameserver.values.id) >= 0
                    && metadata.nameserver.ownNameserversComplete
                )
                    ? records.filter((rec) => rec.type === 'NS').map(
                        (rec) => {
                            if (rec.name.indexOf(zoneName) < 0) {
                                rec.name = zoneName;
                            }

                            return rec;
                        }
                    )
                    : []
            });
        }

        composerObject.summaryObject.push(
            await this._getSummaryObject(
                metadata,
                factSheetItems,
                this.$translate.instant('97f4ed90-36d2-41fe-8e52-2455b38f7397')
            )
        );

        return composerObject;
    };

    private _getComposerBaseObject = (metadata) => {
        return {
            apiObject: {
                zoneConfig: []
            },
            summaryObject: [],
            productFamily: metadata.productFamily,
            productCode: metadata.productCode,
            isRecreate: metadata.isRecreate || false
        };
    };
}
