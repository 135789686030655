import * as ng from 'angular';
import { OrganSslWizardProductConfigCertificateContactsController } from '.';
import { ViewTypes } from '../../../../../../types/view-types';

export class OrganSslWizardProductConfigCertificateContactAdminController {
    public static $inject: string[] = [
        '$translate'
    ];

    public administrationContact: ViewTypes.SslContact<ViewTypes.AdministrationContactFields>;
    public metaData: ViewTypes.ProductConfigSslObject;
    public salutationsDropdown: { name: string; value: string }[] = [];

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.administrationContact.filledOut = this.filledOut;
    }

    public filledOut = () => {
        return OrganSslWizardProductConfigCertificateContactsController.filledOut(this.administrationContact);
    };
}

export class OrganSslWizardProductConfigCertificateContactAdminComponent implements ng.IComponentOptions {
    public bindings = {
        administrationContact: '=',
        metaData: '<',
        salutationsDropdown: '<'
    };
    public template = require('./config-certificate-contact-admin.html');
    public controller = OrganSslWizardProductConfigCertificateContactAdminController;
}
