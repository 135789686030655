import ng from 'angular';

import { BillingCycleToContractPeriodeConst, ContractPeriodNumber } from '@/configuration';
import { AuthContextService } from '@/services';
import { getVatAmount } from '@/services/get-vat-amount';

export type CalculatePriceFilter = (
    netPriceOrObj: number | CalculatePriceNew,
    vatRate?: number,
    quantity?: number,
    type?: string,
    exchangeRatio?: number,
    ignoreCommercial?: boolean,
    productCode?: string,
    billingPeriod?: number|string
) => number;

interface CalculatePriceOld {
    netPrice: number;
    vatRate: number;
    exchangeRatio: number;
    billingPeriod: number;
    quantity: number;
    productCode: string;
}

interface CalculatePriceNew {
    grossPrice: number;
    quantity?: number;
}

export class CalculatePrice {
    public static $inject: string[] = [];

    public static Factory(): CalculatePriceFilter {
        const isCommercialCustomer = AuthContextService.account.isCommercialCustomer;

        return (
            netPrice,
            vatRate,
            quantity,
            type,
            exchangeRatio,
            ignoreCommercial = false,
            productCode,
            billingPeriod
        ) => {
            const quantityIsOne = (quant: number): boolean => {
                return [undefined, null].indexOf(quant) >= 0 || !ng.isNumber(quant);
            };

            if (typeof netPrice === 'object') {
                const grossPrice = netPrice.grossPrice;
                const grossQuantity = quantityIsOne(netPrice.quantity) ? 1 : netPrice.quantity;
                return CalculatePrice.calculatePrice({
                    grossPrice: grossPrice,
                    quantity: grossQuantity
                });
            }

            if ([undefined, null, -1].indexOf(netPrice) >= 0 || !ng.isNumber(netPrice)) {
                return netPrice;
            }

            if (quantityIsOne(quantity)) {
                quantity = 1;
            }

            if ([undefined, null, 'net', 'gross'].indexOf(type) < 0) {
                type = null;
            }

            // sets the vat rate depending on whether we are gross or net!
            if ([undefined, null].indexOf(vatRate) >= 0 || !ng.isNumber(vatRate)) {
                if ([undefined, null, 'gross'].indexOf(type) >= 0) {
                    vatRate = 1900;
                } else {
                    vatRate = 0;
                }
            }

            if ([undefined, null, 'net'].indexOf(type) >= 0 && (isCommercialCustomer || ignoreCommercial)) {
                vatRate = 0;
            }

            let billingPeriodNum: ContractPeriodNumber;

            if (typeof billingPeriod === 'string') { // also accept strings
                billingPeriodNum = BillingCycleToContractPeriodeConst[billingPeriod];
            }

            return CalculatePrice.calculatePrice({
                netPrice: netPrice,
                vatRate: vatRate,
                quantity: quantity,
                exchangeRatio: exchangeRatio,
                productCode: productCode,
                billingPeriod: billingPeriodNum
            });
        };
    }

    public static roundDigits(value: number, startDigits: number = 4, endDigits: number = 2): number {
        if (startDigits >= endDigits) {
            return CalculatePrice.roundDigits(
                parseFloat(value.toFixed(startDigits)),
                --startDigits,
                endDigits
            );
        } else {
            return value;
        }
    }

    private static calculatePrice(params: CalculatePriceOld | CalculatePriceNew): number {
        const oldPar = params as CalculatePriceOld;
        const newPar = params as CalculatePriceNew;

        let totalPrice: number;

        if (newPar.grossPrice) {
            totalPrice = newPar.grossPrice * newPar.quantity / 10000.0;
        } else if (oldPar.netPrice && !isNaN(oldPar.vatRate)) {
            oldPar.exchangeRatio = this._getExchangeRatio(oldPar.exchangeRatio);

            oldPar.netPrice = oldPar.netPrice / oldPar.exchangeRatio;
            let vatAmount = 0;

            if (oldPar.vatRate !== 0) {
                vatAmount = getVatAmount(
                    new Date(),
                    oldPar.billingPeriod,
                    oldPar.netPrice,
                    AuthContextService.account.country,
                    oldPar.vatRate
                );
            }

            totalPrice = ((oldPar.netPrice + vatAmount) * oldPar.quantity) / 10000.0;
        } else {
            // TEST THIS
            totalPrice = (oldPar.netPrice  * oldPar.quantity) / 10000.0;
            // throw Error('calculatePrice missing params');
        }

        return CalculatePrice.roundDigits(totalPrice);
    }

    private static _getExchangeRatio(exchangeRatio: number): number {
        let modifiedExchange = exchangeRatio;
        if ([undefined, null].indexOf(exchangeRatio) >= 0) {
            modifiedExchange = 1.0;
        } else {
            modifiedExchange /= 10000.0;
        }
        return modifiedExchange;
    }
}
