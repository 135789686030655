import * as ng from 'angular';
import { ManagedMachineProjectObject } from '@/atomic-components/organisms';
import { UiRights } from '@/configuration';
import { AuthContextService } from '@/services';

export class OrganManagedMachineProjectConfigurationController implements ng.IController {
    public static $inject: string[] = ['authContext'];

    public userHasRightDbDatabaseCreate = AuthContextService.isGranted(UiRights.DB_DATABASE_CREATE);
    public userHasRightWebWebspaceCreate = AuthContextService.isGranted(UiRights.WEB_WEBSPACE_CREATE);
    public projectData: ManagedMachineProjectObject;
    public databaseValidationOptions = {
        minimum: 1
    };

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set complete({}) {}
    public get complete(): boolean {
        if (!this.projectData) {
            return false;
        }

        if (this.projectData.createDatabase && this.projectData.databasePassword?.length === 0) {
            return false;
        }

        if (this.projectData.createWebspace && this.projectData.webspacePassword?.length === 0) {
            return false;
        }

        return this.projectData.projectName.length > 0
            && (
                this.projectData.createWebspace
                || this.projectData.createDatabase
            )
            && (
                !this.projectData.createDatabase
                || (
                    this.projectData.createDatabase
                    && this.projectData.capacity > 0
                )
            );
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set accessCreateTextType({}) {}
    public get accessCreateTextType(): string {
        switch (true) {
            case this.projectData.createDatabase && this.projectData.createWebspace:
                return 'all';
            case this.projectData.createDatabase && !this.projectData.createWebspace:
                return 'database';
            case this.projectData.createWebspace && !this.projectData.createDatabase:
                return 'webspace';
            default:
                return 'none';
        }
    }
}

export class OrganManagedMachineProjectConfigurationComponent implements ng.IComponentOptions {
    public bindings = {
        complete: '=',
        projectData: '='
    };

    public template = require('./project-configuration.html');
    public controller =  OrganManagedMachineProjectConfigurationController;
}
