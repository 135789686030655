import * as ng from 'angular';

import { EditPanelStatus } from '@/atomic-components/molecules/forms/form-edit/form-edit';
import { FormDescriptionSpec } from '@/types/view-types/form-description/form-description';

export class FormPanelDynamicController implements ng.IComponentController {
    public static $inject: string[] = [];

    public keenInput: FormDescriptionSpec.UiLayoutElement;
    public keenData?: FormDescriptionSpec.FieldObject[];
    public keenOutput: (model: any, key: string) => any;
    public disabled = false;

    public initialStatus: EditPanelStatus;
    public panelRight: any;
    public heading: string;
    public description: string;
    public elements: FormDescriptionSpec.Element[];
    public modifier = 'subheadline';

    public $onInit = () => {
        this.initialStatus = EditPanelStatus.READONLY;
        this.panelRight = {
            editPanelButton: true
        };
        this.heading = this.keenInput.heading || '';
        this.description = this.keenInput.description || '';
        this.elements = this.keenInput.elements || [];
    };

    public formDataField = (element: FormDescriptionSpec.ElementField) => {
        if (Array.isArray(this.keenData)) {
            return this.keenData.find((field) => field.key === element.key);
        }
        return {
            type: ''
        };
    };

    public keenDataCallback = (value: any, key: string) => {
        if (this.keenOutput) {
            this.keenOutput(value, key);
        }
    };
}

export class FormPanelDynamicComponent implements ng.IComponentOptions {
    public bindings = {
        keenInput: '<',
        keenData: '<',
        keenOutput: '<?',
        disabled: '<?'
    };
    public controller = FormPanelDynamicController;
    public template = require('./dynamic-panel.html');
}
