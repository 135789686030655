import * as ng from 'angular';
import { LinkListItem } from '@/atomic-components/molecules/links/link-list/link-list';
import { UiLanguagesConst, UiRights } from '@/configuration';
import { AuthContextService, ProductHelperService, ProductsModelService } from '@/services';
import * as Types from '@/types';

export class MoleculePanelManagedNextcloudDashboardController implements ng.IController {
    public static $inject: string[] = ['$translate', 'productHelper', 'productsModel'];

    public nextcloud: Types.ManagedApplicationApi.Nextcloud;
    public instanceUrl = '';
    public isFreeProduct = false;
    public linkListFooter: LinkListItem[] = [];
    public OrganismDashboardOverview;
    public panelHeader = {
        includeShowDetailsLink: false,
        panelHeaderRoute: '',
        panelHeaderRouteParams: {}
    };

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private productHelper: ProductHelperService,
        private productModel: ProductsModelService
    ) {}

    public $onInit(): void {
        void this.productModel.findOne(
            this.nextcloud.productCode,
            UiLanguagesConst[AuthContextService.user.language],
            true
        )
            .then(
                (apiResponse) => {
                    this.isFreeProduct = ProductHelperService.wrapSpecificationItems(
                        apiResponse.response.specificationItems
                    ).isFreeProduct?.isTrue === true;
                }
            )
            .then(this.fillPanelLinks);

        this.instanceUrl = 'https://'
            + (
                this.nextcloud.individualDomainNameStatus === 'active'
                ? this.nextcloud.individualDomainNameUnicode
                : this.nextcloud.defaultDomainName
            );

        this.linkListFooter = [];

        if (AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT)) {
            this.panelHeader = {
                includeShowDetailsLink: true,
                panelHeaderRoute: 'nextcloud.id.edit',
                panelHeaderRouteParams: { storageProductId: this.nextcloud.id }
            };
        }
    }

    private fillPanelLinks = (promise) => {
        if (!this.isFreeProduct) {
            if (this.nextcloud.individualDomainNameStatus === 'notConfigured' && this.nextcloud.status === 'active') {
                this.linkListFooter.push(
                    {
                        isGranted: AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT),
                        route: 'nextcloud.id.use-own-domain',
                        routeParams: { storageProductId: this.nextcloud.id },
                        text: this.$translate.instant('TR_120819-f55f41_TR')
                    }
                );
            }
        }
        this.linkListFooter.push(
            {
                isGranted: AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT),
                route: 'nextcloud.id.upgrade',
                routeParams: { storageProductId: this.nextcloud.id },
                text: this.$translate.instant('TR_030919-a153f2_TR')
            }
        );
    };
}

export class MoleculePanelManagedNextcloudDashboardComponent implements ng.IComponentOptions {
    public bindings = {
        nextcloud: '<nextcloudItem'
    };
    public require = {
        OrganismDashboardOverview: '^?organismDashboardOverviews'
    };
    public controller =  MoleculePanelManagedNextcloudDashboardController;
    public template = require('./panel-managed-nextcloud-dashboard.html');
}
