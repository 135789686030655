import {
    DashboardWidgetModel,
    DashboardWidgetType
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model';
import {
    DashboardWidgetObjectDefinition
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model-operator';

export class DashboardWidgetDomainSearchModel extends DashboardWidgetModel {
    public static $inject: string[] = DashboardWidgetModel.$inject.concat([]);

    public static dashboardWidgetDefinition: DashboardWidgetObjectDefinition = {
        demoData: {},
        manualAddable: true,
        userPermissions: {
            isGrantedAny: [
                'DOM_DOMAINS_CREATE_WITH_CONTACTS',
                'DOM_DOMAINS_CREATE_WITH_DEFAULTS',
                'DOM_DOMAINS_CREATE_WITH_OWN_NS'
            ]
        },
        widgetDescription: [
            /* translationId */ 'TR_150321-d1e036_TR'
        ],
        widgetObject: {
            attribute: {
                id: 'organDashboardWidgetDomainSearch',
                width: 2,
                height: 1,
                x: 0,
                y: 0,
                noResize: true,
                autoPosition: true
            },
            componentTagName: 'organ-dashboard-widget-domain-search',
            widgetModel: 'dashboardWidgetDomainSearchModel',
            version: '1.0'
        },
        widgetTitle: /* translationId */ 'TR_290121-3e8ec1_TR',
        widgetType: DashboardWidgetType.custom
    };

    public dashboardWidgetDefinition: DashboardWidgetObjectDefinition;

    constructor() {
        super();

        this.dashboardWidgetDefinition = DashboardWidgetDomainSearchModel.dashboardWidgetDefinition;
    }
}
