/* eslint-disable */

import { ProductFamiliesConst, OldProductFamiliesConst, UiLanguagesConst, UiRights } from '@/configuration';
import { AuthContextService, ProductsModelService, SentryErrorEmitterService } from '@/services';
import * as Types from '@/types';
import * as Sentry from '@sentry/browser';

import { PriceCacheService, PriceModelService, PromoManagerService } from './';

export class PricelistHelperService {
    public static $inject: string[] = [
        'priceCache',
        'productsModel',
        'promoManager',
        'priceModel',
        'userSettingsModel',
        'calculatePriceFilter'
    ];

    constructor(
        private priceCache: PriceCacheService,
        private productsModel: ProductsModelService,
        private promoManager: PromoManagerService,
        private priceModel: PriceModelService,
    ) {}

    public get isCommercialCustomer() {
        return AuthContextService.account.isCommercialCustomer;
    }

    public getDnsPriceDetails = (): PromiseLike<any> => {
        const service = 'dns';
        return this._getProductsOfProductFamilies(service, this._getOldAndNewFamilies('dns'))
            .then((products) => {
                return this._setPricelist(service, products);
            });
    };

    public getDomainsPriceDetails = (): PromiseLike<any> => {
        // Domain products has no product families
        // So only get domaion service prices
        return this._getProductServicePrice('domains');
    };

    public getMachinePriceDetails = (): PromiseLike<any> => {
        const service = 'machine';
        return this._getProductsOfProductFamilies('machine', this._getOldAndNewFamilies('machines'), true)
            .then((products) => {
                let productsIncludingRelated: any[] = [];
                products.map((product) => {
                    productsIncludingRelated.push(product);
                })
                return this._setPricelist(service, productsIncludingRelated);
            });
    };

    public getSslPriceDetails = (): PromiseLike<any> => {
        const service = 'ssl';
        return this._getProductsOfProductFamilies(service,  this._getOldAndNewFamilies('ssl'), true)
            .then((products) => {
                return this._setPricelist(service, products);
            });
    };

    public getEmailPriceDetails = (): PromiseLike<any> => {
        const service = 'email';
        return this._getProductsOfProductFamilies(service, this._getOldAndNewFamilies('emailPriceList'))
            .then((products) => {
                return this._setPricelist(service, products);
            });
    };

    public getWebhostingPriceDetails = (): PromiseLike<any> => {
        const service = 'webhosting';
        return this._getProductsOfProductFamilies(service, this._getOldAndNewFamilies('webhosting'))
            .then((products) => {
                return this._setPricelist(service, products);
            });
    };

    public getDatabasesPriceDetails = (): PromiseLike<any> => {
        const service = 'database';
        return this._getProductsOfProductFamilies(service, this._getOldAndNewFamilies('database'))
            .then((products) => {
                return this._setPricelist(service, products);
            });
    };

    public getStorageProductPriceDetails = (): PromiseLike<any> => {
        const service = 'managedapplication';
        return this._getProductsOfProductFamilies(service, this._getOldAndNewFamilies('nextcloud'), true)
            .then((products) => {
                return this._setPricelist(service, products);
            });
    };

    public getPromoPriceDetails(): PromiseLike<any> {
        return this.promoManager.getPromotions();
    }

    public getChangedPriceDetails = (): Promise<any> => {
        const promises = [];

        if (AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_DOMAIN)) {
            promises.push(this.priceCache.listDomainPriceChanges());
        }
        if (AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_SSL)) {
            promises.push(this.priceCache.listSslPriceChanges());
        }
        if (AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_EMAIL)) {
            promises.push(this.priceCache.listMailPriceChanges());
        }
        if (AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_WEBHOSTING)) {
            promises.push(this.priceCache.listWebhostingPriceChanges());
        }
        if (AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_DATABASE)) {
            promises.push(this.priceCache.listDatabasePriceChanges());
        }
        if (AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_MACHINE)) {
            promises.push(this.priceCache.listVmPriceChanges());
        }

        return Promise.all(promises)
            .then((priceLists) => {
                return priceLists
                    .reduce(
                        (previous, current) => {
                            return previous.concat(current);
                        }
                    )
                    .sort(
                        (a: any, b: any) => {
                            if (a.startPIT < b.startPIT) {
                                return -1;
                            }
                            if (a.startPIT > b.startPIT) {
                                return 1;
                            }
                            return 0;
                        }
                    );
            });
    };

    /**
     *  Get all products of productFamilies
     */
    private _getProductsOfProductFamilies = (
        service: string,
        productFamilies: string[],
        withRelatedProducts?: boolean
    ): PromiseLike<Types.ProductApi.AbstractProduct[]> => {
        const language = UiLanguagesConst[AuthContextService.user.language];
        const productPromises = productFamilies.map(
            (productFamily) => {
                return this.productsModel.findProducts(service, productFamily, language, withRelatedProducts)
                    .then((productResponseData) => productResponseData.response.data)
                    .then((products) => products);
            }
        );

        return Promise.all(productPromises).then((products) => [].concat(...products));
    };

    /**
     *  List product with billing cycle prices
     */
    private _setPricelist = (service: string, products: any): Promise<any> => {
        return this._getProductServicePrice(service)
            .then((servicePrices) => {
                return products.map(
                    (serviceProduct: any) => this._setProductPrice(serviceProduct, servicePrices)
                )
            }
            );
    };

    private _setProductPrice = (serviceProduct: any, servicePrices: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        const product: {
            prices: any,
            productCode: string,
            productCodeTemplate: string,
            productName: string,
            productType: string,
            relatedProducts: any[],
            validationType?: string,
        } = {
            prices: {},
            productCode: serviceProduct.type === 'Product' ? serviceProduct.productCode : '',
            productCodeTemplate: serviceProduct.type === 'TemplateProduct'
            ? serviceProduct.productCodeTemplate
            : '',
            productName: serviceProduct.name,
            productType: serviceProduct.type,
            relatedProducts: []
        };

        // for ssl validationType
        const validationType = serviceProduct.specificationItems?.find(
            (item: {name: string, value: string}) => item.name === 'validationType'
        )?.value;
        if (validationType) {
            product.validationType = validationType;
        }

        if (product.productType === 'TemplateProduct') {
            serviceProduct.billingCycles.map((cycle: any) => {
                product.prices[cycle as string] = this._getProductPriceOfProductCode(
                    product.productCodeTemplate.replace('##BILLING_CYCLE##', cycle),
                    servicePrices
                );
            });
        } else if (product.productType === 'Product') {
            // First get product price...
            const productPrice = this._getProductPriceOfProductCode(product.productCode, servicePrices);
            // Secondly get billing cycle form price contract period
            const cycle = this._getCycleOfContractPeriod(productPrice as Types.BillingApi.ArticlePurchasePrice);
            if (cycle !== null) {
                product.prices[cycle as string] = productPrice;
            } else {
                // if there ist a unknown contract period wording, add price to product.prices
                product.prices['0'] = productPrice;
            }
        }

        if (serviceProduct?.relatedProducts && serviceProduct.relatedProducts.length > 0) {
            product.relatedProducts = serviceProduct.relatedProducts.map(
                (relatedProduct: any) => {
                    if (!(relatedProduct.productType === 'disk')) {
                        return this._setProductPrice(relatedProduct, servicePrices);
                    }
                }
            );
        }
        return product;
    };

    private _getProductPriceOfProductCode = (
        productCode: string,
        servicePrices: Types.BillingApi.ArticlePurchasePrice[]
    ) => {
        const cyclePrice = servicePrices.find((price: Types.BillingApi.ArticlePurchasePrice) => {
            return productCode === price.productCode;
        });

        if (cyclePrice !== undefined) {
            if (this.isCommercialCustomer) {
                cyclePrice.uiPrice = cyclePrice.netAmount / 10000;
            } else {
                cyclePrice.uiPrice = cyclePrice.grossAmount / 10000;
            }
            return cyclePrice;
        }

        SentryErrorEmitterService.sendSentryReport(
            'API pricelist error',
            { productCode: productCode, message: 'Missing price in billing servicePristList request' },
            { key: 'api', service: 'billing', apiMethod: 'servicePriceList' }
        );

        return {
            uiPrice: 'N/A'
        };
    };

    /**
     * ProductFind object with type: Product has no information about billingCycles.
     * So we get this information from servicePriceList contractPeriod
     * (ex. monthly, quarterly, semiannually, annually1, ....)
     */
    private _getCycleOfContractPeriod = (productPrice: Types.BillingApi.ArticlePurchasePrice): string => {
        const cycle = [
            {
                contractPeriod: 'monthly',
                cycle: '1'
            },
            {
                contractPeriod: 'quarterly',
                cycle: '3'
            },
            {
                contractPeriod: 'semiannually',
                cycle: '6'
            },
            {
                contractPeriod: 'annually',
                cycle: '12'
            }
        ].filter((val) => productPrice.contractPeriod === val.contractPeriod);
        return cycle.length === 1 ? cycle[0].cycle : null;
    };

    /**
     *  Get product service prices
     */
    private _getProductServicePrice = (service: string): Promise<any> => {
        switch (service) {
            case 'dns':
                return this.priceModel.getDnsPrices();

            case 'machine':
                return this.priceModel.getMachinePrices();

            case 'ssl':
                return this.priceModel.getSSLPrices();

            case 'email':
                return this.priceModel.getEmailPrices();

            case 'webhosting':
                return this.priceModel.getWebhostingPrices();

            case 'database':
                return this.priceModel.getDatabasePrices();

            case 'domains':
                return this.priceModel.getDomainPrices();

            case 'managedapplication':
                return this.priceModel.getManagedApplicationPrices();

            default:
                return Promise.resolve([]);
        }
    };

    private _getOldAndNewFamilies = (service: string): string[] => {
        let serviceFamilies: string[];

        const currentFamilies = ProductFamiliesConst[service];
        const oldFamilies = OldProductFamiliesConst[service];
        // if old products are in the OldProductFamiliesConst then show them in the pricelist
        if (oldFamilies && Array.isArray(oldFamilies)) {
            serviceFamilies = currentFamilies.concat(oldFamilies);
        } else {
            serviceFamilies = currentFamilies;
        }
        return serviceFamilies;
    }
}
