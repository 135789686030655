import * as ng from 'angular';
import * as q from 'q';
import { HelpdeskRobotService } from './robot';

export class ArticleSearchModelService {
    public static $inject: string[] = ['helpdeskRobotService'];

    constructor(private helpdeskRobotService: HelpdeskRobotService) {}

    public searchArticles = (search: string, amount?: number) => {
        return this.helpdeskRobotService.searchArticles(search, amount);
    };

    public getCurrentArticle = () => {
        return this.helpdeskRobotService.getCurrentArticle();
    };

    public getArticleMetaByIdList = (articleIdList: string[]) => {
        const idListString = articleIdList.join(',');
        return this.helpdeskRobotService.getArticleMetaByIdList(idListString);
    };

    public getArticleMetaByCategory = (category: string) => {
        return this.helpdeskRobotService.getArticleMetaByCategory(category);
    };

    public getRootCategories = () => {
        return this.helpdeskRobotService.getCategories('/');
    };

    public getArticlesByCategory = (path: string, amount: number = 5) => {
        return this.helpdeskRobotService.getArticlesByCategory(path, amount);
    };

    public getArticlesByCategoryIncludingSubcategories = (path: string, amount: number = 5) => {
        return this.helpdeskRobotService.getArticlesByCategoryIncludingSubcategories(path, amount);
    };

    public getCurrentCategory = (parent: string, currentPath: string) => {
        return this.getCategories(parent).then(
            (reply) => {
                if ('error' in (reply.data as any)) {
                    return [];
                }
                const parentCategories: {}[] = (reply.data as {}[]);
                for (const i in parentCategories) {
                    if (parentCategories[i].url === currentPath) {
                        return parentCategories[i];
                    }
                }
            }
        );
    };

    public getCategories = (parent: string) => {
        return this.helpdeskRobotService.getCategories(parent);
    };

    public reportError = (message: string) => {
        this.helpdeskRobotService.reportError(message);
    };

    public getBreadcrumbFromApi = (templateName: string, stateParams: ng.ui.IStateParamsService) => {
        const urlParts = [];
        const breadcrumbs = [];
        for (let i = 1; i <= 5; i++) {
            if ([null, undefined].indexOf(stateParams['level' + i]) < 0) {
                urlParts.push(stateParams['level' + i]);
            }
        }
        let parent = '';
        let current = '';
        const apiRequests = [];
        for (let i = 1; i <= urlParts.length; i++) {
            if (!(templateName === 'article' && i === urlParts.length)) {
                parent = '/' + ng.copy(urlParts).splice(0, i - 1).join('/') + '/';
                current = '/' + ng.copy(urlParts).splice(0, i).join('/') + '/';
                parent = parent === '//' ? '/' : parent;
                current = current === '//' ? '/' : current;
                apiRequests.push(
                    this.getCurrentCategory(parent, current).then(
                        (category: any) => {
                            return category;
                        }
                    )
                );
                apiRequests.push(
                    this.getCategories(parent).then(
                        (categories: any) => {
                            return categories.data !== undefined ? categories.data : [];
                        }
                    )
                );
            }
        }
        return q.all(apiRequests).then((responses) => {
            const elementCount = (responses.length / 2);
            for (let i = 1; i <= elementCount; i++) {
                const activeElement = responses.shift();
                const elementList = responses.shift();
                breadcrumbs.push({
                    active: false,
                    items: elementList.map((element) => {
                        return {
                            active: activeElement.url === element.url,
                            disabled: false,
                            name: element.title,
                            route: 'helpdesk',
                            routeParams: this.getRouteParamsByUrl(element.url)
                        };
                    }),
                    route: 'helpdesk',
                    routeParams: this.getRouteParamsByUrl(activeElement.url),
                    type: 'dropdown',
                    value: activeElement.title
                });
            }
            return breadcrumbs;
        });
    };

    private getRouteParamsByUrl = (relativeHelpdeskUrl) => {
        const urlParts = relativeHelpdeskUrl.split('/');
        let currentLevel = 0;
        const routeParams = {
            level1: null,
            level2: null,
            level3: null,
            level4: null,
            level5: null
        };
        urlParts.forEach((urlPart) => {
            if (urlPart !== '') {
                currentLevel++;
                routeParams['level' + currentLevel] = urlPart;
            }
        });
        return routeParams;
    };
}
