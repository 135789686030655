import ng from 'angular';
import { ValidateMax, ValidateMin, ValidateNotEmpty, ValidateNumber } from '@/services';
import { FormInputModelOptions } from '../../../../atoms/form-elements';

export class MoleculeInputTextDnsRecordPriorityController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public validationErrorList: any[] = [];
    public value: string;
    public placeholder: string;
    public outerCallbackOnChange: (arg0: any) => any;
    public callbackOnBlur: (arg0: any) => any;
    public callbackOnBlurParams: any;
    public customCallbackOnEnter: () => {};
    public doNotSaveOnFormEnter: boolean;
    public disabled: boolean;
    public modelOptions: FormInputModelOptions;
    public registerToForm: boolean;
    public validationInstructions: any[];
    public options: any;
    public recordType: string;
    public inputId: string;
    public callbackOnClear: (arg0: any) => any;
    public callbackOnClearParams: any;
    public clearable: boolean;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        if ([undefined, null].indexOf(this.value) >= 0) {
            this.value = '0';
        } else {
            this.value = this.value.toString();
        }
        this.validationInstructions = [];
        this._setValidator();
    }

    public $onChanges(changes: any) {
        if (changes.recordType !== undefined && changes.recordType.currentValue !== undefined) {
            this.recordType = changes.recordType.currentValue;
            this._setValidator(true);
        }
    }

    public callbackOnChange = () => {
        this._validateAll();
    };

    public onPaste = (paste: ClipboardEvent) => {
        // implement callback on paste here when needed!
    };

    private _setValidator = (onChange?: boolean) => {
        onChange = onChange || false;
        this.validationInstructions = [];

        if (this.recordType === undefined) {
            return;
        }

        switch (this.recordType.toUpperCase()) {
            case 'MX':
                this.validationInstructions = [{
                    instructions: null,
                    validator: new ValidateNotEmpty(this.$translate)
                }, {
                    instructions: null,
                    validator: new ValidateNumber(this.$translate, false, true)
                }, {
                    instructions: null,
                    validator: new ValidateMax(this.$translate, 65536)
                }, {
                    instructions: null,
                    validator: new ValidateMin(this.$translate, 0)
                }];
                break;
            default:
                this.validationInstructions = [{
                    instructions: null,
                    validator: new ValidateMax(this.$translate, 65536)
                }, {
                    instructions: null,
                    validator: new ValidateMin(this.$translate, 0)
                }];
                break;
        }

        if (onChange && [undefined, null].indexOf(this.value) === -1 && this.value.length > 0) {
            this._validateAll();
        }
    };

    private _validateAll = () => {
        this.validationInstructions.some((instruction) => {
            const validateRes = instruction.validator.validate(this.value);
            if (validateRes.length > 0) {
                this.validationErrorList = validateRes;
                return true;
            }
            return false;
        });
    };
}

export class MoleculeInputTextDnsRecordPriorityComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnBlur: '<',
        callbackOnBlurParams: '<',
        callbackOnClear: '<',
        callbackOnClearParams: '<',
        clearable: '<',
        customCallbackOnEnter: '<',
        disabled: '<',
        doNotSaveOnFormEnter: '<',
        inputId: '@?',
        modelOptions: '<',
        options: '<',
        outerCallbackOnChange: '<callbackOnChange',
        placeholder: '@',
        recordType: '<',
        registerToForm: '<',
        validationErrorList: '=?',
        value: '='
    };
    public controller = MoleculeInputTextDnsRecordPriorityController;
    public template = require('./input-text-with-validation-wrapper.html');
}
