import * as ng from 'angular';
import * as q from 'q';
import { UiRights } from '../../../../../configuration';
import {
    AlertManagerService,
    AuthContextService,
    NavigationService,
    WebhostingJobModelService,
    WebhostingUserModelService,
    WebspaceModelService
} from '../../../../../services';
import * as Types from '../../../../../types';
import {
    EditPanelStatus
} from '../../../../molecules/forms/form-edit/form-edit';

export class OrganismEditFormWebhostingUserController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'navigation',
        'webhostingJobModel',
        'webhostingUserModel',
        'webspaceModel'
    ];

    public originalUser: Types.WebhostingApi.User;
    public user: Types.WebhostingApi.User;
    public password: string;
    public userPanelRight: any;
    public initialStatus = EditPanelStatus.ACTIVE;
    public hasAgreedToDeleteUser = false;
    public userConnectedWebspaces;
    public userHasUnfinishedJob = false;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private navigation: NavigationService,
        private webhostingJobModel: WebhostingJobModelService,
        private webhostingUserModel: WebhostingUserModelService,
        private webspaceModel: WebspaceModelService
    ) {}

    public $onInit = () => {
        this.user = ng.copy(this.originalUser);

        if (AuthContextService.isGranted(UiRights.WEB_JOBS_LIST)) {
            this.webhostingJobModel.findRunningJobsForUser(this.originalUser)
            .then(
                (result) => {
                    this.userHasUnfinishedJob = result.status === 'success'
                    && result.response.data.length > 0;
                }
            );
        }

        this.webspaceModel.list(null, null, { field: 'webspaceAccessesUserId', value: this.user.id })
        .then((webspaceObject) => {
            this.userConnectedWebspaces = webspaceObject.data;
            return webspaceObject;
        });
    };

    public save = () => {
        return this.webhostingUserModel.updateUser(this.user, this.password).then(
            (user) => {
                if (user.status !== 'error') {
                    this.alertManager.success(this.$translate.instant('TR_100119-54df5d_TR'));
                    return user;
                }
                return q.reject(user);
            }
        );
    };

    public delete = () => {
        return this.webhostingUserModel.deleteUsers([this.user])
            .then(
                () => {
                    this.alertManager.success(this.$translate.instant('ACCOUNT.MESSAGE.DELETE.USER.SUCCESS'));
                    this.navigation.go('webhosting.users.overview', {}, {
                        reload: true
                    });
                    return q.reject();
                }
            );
    };

    public cancelGeneral = () => {
        this.user = ng.copy(this.originalUser);
    };
}

export class OrganismEditFormWebhostingUserComponent implements ng.IComponentOptions {
    public bindings = {
        originalUser: '<user',
        userPanelRight: '='
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormWebhostingUserController;
    public template = require('./webhosting-user-edit.html');
}
