import * as ng from 'angular';

import { DomainInfoHelperService } from '@/services/helpers';
import { DataObject, ValidateEmail, ValidateIllegalCharacters, ValidateRegularExpression } from '@/services/validators';
import { ValidateAllowedCharacters } from '@/services/validators/validate-allowed-characters';
import { ValidateFqdn } from '@/services/validators/validate-fqdn';
import { ValidateStringMax, ValidateStringMin } from '@/services/validators/validate-min-max';
import { FormDescriptionSpec } from '@/types/view-types/form-description/form-description';
import { ValidationErrorList } from '@/atomic-components/molecules/lists/validation-error-list/validation-error-list';

export class FormElementInputStringController implements ng.IComponentController {
    public static $inject: string[] = ['$translate'];

    public disabled: boolean;
    public validationInstructions: DataObject[]; // Used to pass custom validators if type 'custom' is used
    public validationInstructionsOperator: string | '&&' | '||';
    public keenInput: FormDescriptionSpec.FieldObjectString;
    public keenOutput: (model: string, key: string) => void;
    private _model: string;
    private key: string;
    private label: string;
    private validationErrorList: ValidationErrorList = [];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private domainInfoHelper: DomainInfoHelperService
    ) {}

    public $onInit = (): void => {
        this.disabled = this.disabled || false;
        this.key = this.keenInput.key || '';
        this.label = this.keenInput.ui.label ||  '';
        if (this.keenInput.validation) {
            this.validationOptions(this.keenInput.validation);
        }

        if ([undefined, null].indexOf(((this.keenInput as FormDescriptionSpec.FieldObject).value as string)) < 0) {
            this._model = ((this.keenInput as FormDescriptionSpec.FieldObject).value as string);
        } else if ([undefined, null].indexOf(((this.keenInput as FormDescriptionSpec.FieldObject).default as string)) < 0) {
            this._model = ((this.keenInput as FormDescriptionSpec.FieldObject).default as string);
        }
    };

    public get model(): string {
        return this._model;
    }

    public set model(value: string) {
        this._model = value;
        if (this.keenOutput) {
            this.keenOutput(this._model, this.key);
        }
    }

    public validationOptions(value: FormDescriptionSpec.FieldObjectStringValidation): void {
        this.validationInstructionsOperator = '&&';
        this.validationInstructions = [];

        if (value.regex !== undefined) {
            this.validationInstructions.push({
                validator: new ValidateRegularExpression(this.$translate, value.regex, 'gmi', true, true)
            });
        }

        if (value.validChars?.length > 0) {
            this.validationInstructions.push({
                validator: new ValidateAllowedCharacters(value.validChars, this.$translate)
            });
        }

        if (value.invalidChars?.length > 0) {
            this.validationInstructions.push({
                validator: new ValidateIllegalCharacters(value.invalidChars, this.$translate)
            });
        }

        if (value.maxLength !== undefined) {
            this.validationInstructions.push({
                validator: new ValidateStringMax(this.$translate, value.maxLength)
            });
        }

        if (value.minLength !== undefined) {
            this.validationInstructions.push({
                validator: new ValidateStringMin(this.$translate, value.minLength)
            });
        }

        if (value.extendedType === 'emailAddress') {
            this.validationInstructions.push({
                validator: new ValidateEmail(true, this.$translate, this.domainInfoHelper)
            });
        } else if (value.extendedType === 'fqdn') {
            this.validationInstructions.push({
                validator: new ValidateFqdn(this.$translate, false, false)
            });
        }
    }

    private onChangeModel = () => {
        if (this.keenOutput) {
            this.keenOutput(this._model, this.key);
        }
    };
}

export class FormElementInputStringComponent implements ng.IComponentOptions {
    public bindings = {
        keenInput: '<',
        keenOutput: '<?',
        model: '=?',
        disabled: '<?'
    };
    public controller = FormElementInputStringController;
    public template = require('./form-element-input-string.html');
}
