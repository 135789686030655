import ng from 'angular';
import q from 'q';

import * as Types from '@/types';
import {
    AlertManagerService,
    ApiErrorModel,
    AuthContextService,
    DnsZoneModelService,
    DomainHandleModelService,
    DomainModelService,
    NavigationService
} from '../../../../../services';

export class OrganismEditFormDomainMoveController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'apiErrorModel',
        'domainModel',
        'domainHandleModel',
        'dnsZoneModel',
        'navigation'
    ];

    public bundles: Types.BundleApi.Bundle[] = [];
    public domains: Types.DomainApi.Domain[] = [];
    public targetAccount = '';
    public contacts: Types.DomainApi.Contact[];
    public showEditPanels = true;
    public contactData: any;
    public moveIsPossible: boolean;
    public moveNotPossibleError = '';
    public subAccounts: Types.AccountApi.Subaccount[] = [];
    public comesFromSingleDomainEdit = false;
    public domainAccountIds: string[] = [];

    private _componentLoaded = false;
    private _contactsLoaded = false;
    private _availableSubaccountsCache: any[] = [];
    private _keepShowingDomainSelection = false;
    private _selectedDomains: Types.DomainApi.Domain[] = [];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private domainModel: DomainModelService,
        private domainHandleModel: DomainHandleModelService,
        private dnsZoneModel: DnsZoneModelService,
        private navigation: NavigationService
    ) {}

    public $onInit(): void {
        this.domains = this.domains || [];
        this.selectedDomains = this.selectedDomains || [];

        if (this.selectedDomains.length > 0) {
            this.domainAccountIds = this.selectedDomains.map((domain) => domain.accountId);
        }

        if (this.selectedDomains !== undefined && this.selectedDomains.length === 1) {
            this.comesFromSingleDomainEdit = true;
        }
        this._availableSubaccountsCache = this.subAccounts;
        this._reloadSubaccounts();
        this._componentLoaded = true;
    }

    get showDomainSelection(): boolean {
        if (!this._componentLoaded) {
            return false;
        }

        const showPanel = this.comesFromSingleDomainEdit
            || this.selectedDomains.length === 0
            || this.moveNotPossibleError.length > 0
            || this._keepShowingDomainSelection;

        if (showPanel) {
            this._keepShowingDomainSelection = true;
        }

        return showPanel;
    }

    get selectedDomains(): Types.DomainApi.Domain[] {
        return this._selectedDomains;
    }

    set selectedDomains(value: Types.DomainApi.Domain[]) {
        if (this._selectedDomains !== value && Array.isArray(value) && this.targetAccount) {
            this.callbackOnTargetAccountSelect(this.targetAccount);
        }

        this._selectedDomains = value;
    }

    public get showContactsPanel() {
        return this.targetAccount !== undefined && this._contactsLoaded;
    }

    public get completed() {
        return [undefined, null, ''].indexOf(this.targetAccount) === -1
            && this.moveIsPossible
            && this._contactActionsCompleted
            && this._noDomainInBundle
            && this.apiErrorModel.issetApiErrors() === 0;
    }

    public moveDomain = () => this.moveZoneAndDomain().then(
        (res) => {
            if (res.status === 'success') {
                this.alertManager.success(this.$translate.instant('TR_100119-c541f4_TR'));
                this.navigation.go('domain.domains.overview');
            } else {
                this.alertManager.error(this.$translate.instant('TR_100119-736eb8_TR'));
            }

            return q.reject(false);
        }
    );

    public callbackOnTargetAccountSelect = (selection: any) => {
        this._contactsLoaded = false;
        const filter = {
            subFilter: [
                {
                    field: 'UsableForDomainInAccount',
                    value: selection
                },
                {
                    field: 'ContactHidden',
                    value: '0'
                }
            ],
            subFilterConnective: 'AND'
        };
        this.domainHandleModel.listWithoutPagination(null, 1, filter).then(
            (res) => {
                this._contactsLoaded = true;
                this.contacts = res.data;
            }
        );
    };

    private moveZoneAndDomain = () => {
        const domainNameList = this.selectedDomains.map((domain) => domain.name);

        return this.dnsZoneModel.findByName(domainNameList).then(
            (res) => {
                const zoneIds = res.map((zone) => zone.id);

                if (zoneIds.length > 0) {
                    return this._moveZones(zoneIds).then(() => {
                        return this._moveDomains(domainNameList);
                    });
                }

                return this._moveDomains(domainNameList);
            }
        );
    };

    private _moveDomains = (domainNameList: string[]) => {
        const contactOperations = [];

        this.contactData.forEach(
            (contact) => {
                const operationData = {
                    action: contact.selectedOption,
                    replacementContact: null,
                    sourceContact: contact.contactId
                };
                if (contact.selectedOption === 'replace') {
                    operationData.replacementContact = contact.replacement;
                }
                contactOperations.push(operationData);
            }
        );

        return this.domainModel.domainMove(domainNameList, this.targetAccount, contactOperations);
    };

    private _moveZones = (zoneIdList: string[]) => {
        return this.dnsZoneModel.move(zoneIdList, this.targetAccount);
    };

    private _reloadSubaccounts = () => {
        const availableSubAccounts = [];
        const domainAccounts = [];
        this.selectedDomains.forEach((domain) => {
            if (domainAccounts.indexOf(domain.accountId) === -1) {
                domainAccounts.push(domain.accountId);
            }
        });

        this._availableSubaccountsCache.forEach((account) => {
            if (domainAccounts.indexOf(account.id) === -1) {
                availableSubAccounts.push(account);
            }
        });

        this.subAccounts = availableSubAccounts.filter(
            (el: Types.AccountApi.Subaccount) => el.parentAccountId === AuthContextService.account.parentAccountId
        );
    };

    private get _contactActionsCompleted() {
        if ([undefined, null].indexOf(this.contactData) >= 0) {
            return false;
        }
        return this.contactData.every((data) => {
            if ([undefined].indexOf(data.selectedOption) === -1) {
                if (data.selectedOption === 'replace') {
                    return [undefined, null, ''].indexOf(data.replacement) === -1;
                }
                return true;
            }
            return false;
        });
    }

    private get _noDomainInBundle() {
        const domainInBundle = this.selectedDomains.find(
            (el: Types.DomainApi.Domain) => el.bundleId.length > 0
        );
        return !domainInBundle;
    }
}

export class OrganismEditFormDomainMoveComponent implements ng.IComponentOptions {
    public bindings = {
        bundles: '<?',
        contacts: '<',
        domains: '<',
        selectedDomains: '<',
        subAccounts: '<'
    };
    public controller = OrganismEditFormDomainMoveController;
    public template = require('./domain-move.html');
}
