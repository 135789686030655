/* eslint-disable */
import * as ng from 'angular';

import { ShortDebounceInterval } from '@/configuration';
import { FilterBuilder } from '@/services/filter-utils';

import './drop-down-revised.scss';

// Base interface for option types
export interface DropdownElementData {
    name?: string;
    names?: string[];
    value?: any;
    id?: string; // Used for searching via `#`
}

export class MoleculeFormDropDownRevisedController implements ng.IController {
    public static $inject: string[] = ['$element', '$timeout', '$translate', '$window'];

    // Bindings
    public _options: (string | DropdownElementData)[];// The actual data to be displayed in the dropdown
    public additionalFieldsFunction: (...args: any[]) => any; // Additional properties that are visible
    public additionalFilters: any;                    // Add additional filters to restrict the options data
    public allowUndefined = false;                    // Do not preselect first option for required dropdowns
    public cacheLimit: number;                        // Maximum number of options to request from API for caching
    public callbackOnChange: (...args: any[]) => any; // Gets called when any selection changes internally
    public callbackOnChangeOutput: (...args: any[]) => any;
    // Applies a custom filter-function
    public callbackOnInput: (model: (string | DropdownElementData) | (string | DropdownElementData)[]) => any;
    public excludeIdsFromSearch: string[];            // List of ID's to exclude from search-return
    public filterFields: string[];                    // Properties that should be included in the search
    public findByIdFunction: (...args: any[]) => any; // Initial search to set model as preselected option
    public hideAdditionalFields: boolean;             // Do not show every object attribute in dropdown
    public isCustomisable: boolean;                   // Defines whether the chosen value must be in the options
    public isDisabled: boolean;                       // Defines whether this component is active (enabled) or not
    public isNoArrow: boolean;                        // Defines whether an arrow is shown next to the border
    public isRequired: boolean;                       // Defines whether a value has to be selected at all times
    public placeholder: string;                       // Visible when nothing is selected
    public preSelectedOption: string;                 // Lets the user select an option beforehand
    // Fetches options from the api
    public searchFunction: (...args: any[]) => Promise<{ data: any } | { data: any[] }>;
    public searchMapping: DropdownElementData | string; // Map the result of the search function to the options-data
    public shortDebounceInterval = ShortDebounceInterval;
    public showLimit: number;                         // Maximum number of options shown
    public tabIndex = -1;                             // Indicates the selected option. -1 = nothing selected

    private displayFilter: (...args: any[]) => string;

    // Properties
    protected canOpenTowardsBottom = true;
    protected customOptions: (string | DropdownElementData)[] = []; // Contains new, user-created options
    protected filterValue = '';                       // The current filter that gets applied to the list of options
    protected filters: FilterBuilder;                 // Helps with creating search filters
    protected isFocused = false;                      // Returns true when the component is in focus
    protected isLoading = false;                      // Is set to true while waiting for an API response
    protected isOpen = false;                         // Returns true when the list of options is open and visible
    protected isShowAllResults = false;               // When set to true, it ignores the `show-limit` binding
    protected lastTabIndex = -1;                      // Saves the last state from tab-index
    protected realTabIndex = -1;                      // For multiselect options that aren't removed
    protected selectedElement: string | DropdownElementData = null; // Reference to the currently selected element

    protected _model: (string | DropdownElementData) | (string | DropdownElementData)[]; // selected option value

    public get _filterValue(): string{
        return this.filterValue;
    }

    // Listen for changes on model and update selectedElement
    public get model(): any {
        return this._model;
    }
    public set model(value) {
        this._model = value;
        if ([undefined, null].indexOf(this._model) >= 0) {
            this.selectNone();
        } else if ([undefined, null].indexOf(this._options) < 0) {
            let hit = false;

            // Cope with outside model changes, lookup the option that fits the current model and select it
            for (const option of this._options) {
                if (
                    option === value
                    || (!!(option as DropdownElementData).value && (option as DropdownElementData).value === value)
                ) {
                    hit = true;
                    this.selectedElement = option;
                    break;
                }
            }

            // Hotfix 134.5 - PUI-6030 - Domain Contact Dropdown
            // on external model change search the corresponding contact
            if (!hit
                && this.searchFunction
                && typeof value === 'string'
                && Array.isArray(this.filterFields)
                && this.filterFields.includes('ContactId')
            ) {
                this.filterValue = value;
                void this.searchFunction(
                    this.cacheLimit,
                    1,
                    (!this.filters ? undefined : this.filters.buildFilter(this.filterValue)),
                    undefined
                ).then(
                    (reply) => {
                        if (
                            reply.data.some(
                                (option: any) =>
                                    option[(this.searchMapping as DropdownElementData).value] === this.model
                            )
                        ) {
                            const currentlySelected = reply.data.filter(
                                (option: any) =>
                                    option[(this.searchMapping as DropdownElementData).value] === this.model
                            );

                            if (
                                typeof this.searchMapping !== 'string'
                                && this.searchMapping.name
                                && this.searchMapping.value
                            ) {
                                const newEntry = {
                                    name: this.displayFilter
                                        ? this.displayFilter(currentlySelected[0])
                                        : currentlySelected[0][this.searchMapping.name],
                                    value: currentlySelected[0][this.searchMapping.value],
                                    ...this.additionalFieldsFunction(currentlySelected[0])
                                };

                                this.options.push(newEntry);
                                this.selectedElement = this.optionValueToString(newEntry);
                            }
                        }
                    }
                );
            }
        }
    }

    public get options(): (string | DropdownElementData)[] {
        return this._options;
    }
    public set options(value) {
        if ([undefined, null].indexOf(value) < 0) {
            this._options = value.filter(
                (entry: string | DropdownElementData) => {
                    if (this.filterValue.length === 0) {
                        return true;
                    }
                    if (typeof entry === 'string') {
                        if (this._model === entry) {
                            return false;
                        }
                    } else {
                        if (this._model === entry.value) {
                            return false;
                        }
                    }
                    return true;
                }
            );
        }

        if (
            [undefined, null, ''].indexOf(this.model as string) < 0
            && [undefined, null].indexOf(this.options) < 0
        ) {
            // Preselect model. Only works sometimes. Believe and have fun 🌞.
            // This destroys reloading from api. Maybe only execute this when no
            // search-function is defined... Better safe than sorry.
            const optionsFitModel = this.options.filter(
                (option: DropdownElementData) => {
                    return [undefined, null].indexOf(option?.value) >= 0
                        || option?.value.toString().toLowerCase() === this.model.toString().toLowerCase();
                }
            );

            if (optionsFitModel && optionsFitModel.length === 1) {
                this.selectedElement = optionsFitModel[0];
                this.updateModel();
            }
        }
    }

    // Only show the placeholder if no chips are using the space and no element is selected
    public get isShowPlaceholder(): boolean {
        if (this.isRequired) {
            return false;
        }

        return !(this.selectedElement && (this.selectedElement as string).length !== 0);
    }

    // Hide the searchbar when either the binding is set or the options array is
    // smaller than five and no API search-function is defined
    public get isHideSearchbar(): boolean {
        if (this.isCustomisable) {
            return false;
        }

        return !this.searchFunction
            && this.options
            && this.options.length <= 5;
    }

    public get newEntriesPlaceholder(): string {
        return this.options?.length === 0 && !this.isCustomisable
            ? this.$translate.instant('TR_010420-38aade_TR')
            : this.placeholder;
    }

    constructor(
        public $element: ng.IAugmentedJQuery,
        public $timeout: ng.ITimeoutService,
        public $translate: ng.translate.ITranslateService,
        public $window: ng.IWindowService
    ) {
        // Prevent body from scrolling on space
        document.addEventListener('keydown', (keyEvent: KeyboardEvent) => {
            if (['Space', 'ArrowUp', 'ArrowDown'].indexOf(keyEvent.code) >= 0) {
                if (this.isFocused && !this.isOpen && !keyEvent.shiftKey) {
                    keyEvent.preventDefault();
                    void this.$timeout(() => {
                        this.open();
                    });
                }
            }
        });
    }

    // eslint-disable-next-line complexity
    public $onInit(): void {
        // Init bindings
        this.options = this.options || [];
        this.hideAdditionalFields = this.hideAdditionalFields || false;
        this.isCustomisable = this.isCustomisable || false;
        this.isDisabled = this.isDisabled || false;
        this.isNoArrow = this.isNoArrow || false;
        this.isRequired = this.isRequired || false;
        this.cacheLimit = this.cacheLimit || 25;
        this.showLimit = this.showLimit || 25;
        this.preSelectedOption = this.preSelectedOption || null;
        this.canOpenTowardsBottom = true;
        this.additionalFieldsFunction = this.additionalFieldsFunction || (() => ({}));
        this.placeholder = (this.placeholder && this.placeholder !== '')
            ? this.placeholder
            : this.$translate.instant('BUNDLE.DELETE.DOMAIN-ACTION.CHOOSE');
        this.filters = new FilterBuilder(this.filterFields);
        this.filters.allowCustomFields();

        if (this.searchMapping && (this.searchMapping as DropdownElementData).id) {
            this.filters.setIdField((this.searchMapping as DropdownElementData).id);
        }

        if ([undefined, null, ''].indexOf(this.additionalFilters) < 0) {
            const additionalFilters = [undefined, null, ''].indexOf(this.additionalFilters.subFilter) < 0
                ? this.additionalFilters.subFilter
                : this.additionalFilters;
            this.filters.setAdditionalFilters(additionalFilters);
        }
        this.initTabIndexAndInitialModel();
        this.filterValue = '';
        // Get the options from the API if a search-function is defined
        this.fetchOptionsFromApi(true);
    }

    private initTabIndexAndInitialModel(): void{
        this.tabIndex = -1;
        if (this.isRequired && [undefined, null].indexOf(this.model) >= 0) {
            if (!this.searchFunction || !this.findByIdFunction) {
                this.select(0);
            }
        } else if (typeof this.model === 'string' || typeof this.model === 'number') {
            // Check for model being set
            this.filterValue = this.model.toString();
            this.tabIndex = this.findOptionIndexMatchingFilter;
            this.select(this.tabIndex);
        }

        if (
            [undefined, null].indexOf(this.preSelectedOption) < 0
            && [undefined, null, ''].indexOf(this.model) >= 0
        ) {
            this.filterValue = this.preSelectedOption;
            this.tabIndex = this.findOptionIndexMatchingFilter;
            this.select(this.tabIndex);
        }
    }

    // Ask the API to get the options-data if a search function is defined, makes use of caching, Alter at own risk
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    public fetchOptionsFromApi = (isInitial: boolean = false) => {
        if (this.searchFunction) {
            // Set state loading
            this.isLoading = true;

            // Send API request
            this.searchFunction(
                this.cacheLimit,
                1,
                (!this.filters ? undefined : this.filters.buildFilter(this.filterValue)),
                undefined
            ).then(
                (reply) => {
                    if (reply.data === undefined) {
                        reply = { data: reply };
                    }

                    if (
                        [undefined, null].indexOf(this.model) < 0
                        && this.searchMapping
                        && typeof this.searchMapping !== 'string'
                        && this.searchMapping.name
                        && this.searchMapping.value
                        && this.searchMapping.id
                    ) {
                        // check if the current models value is in the api response
                        if (
                            !reply.data.some(
                                (option: any) =>
                                    option[(this.searchMapping as DropdownElementData).value] === this.model
                            )
                        ) {
                            // if not request that one element by its object ID without any additional filters
                            // (Fix for PUI-5053)
                            return this.searchFunction(
                                1,
                                1,
                                { field: this.searchMapping.id, value: this.model },
                                undefined
                            ).then(
                                (apiResponse) => {
                                    if (apiResponse.data !== undefined && apiResponse.data.length === 1) {
                                        reply.data.unshift(apiResponse.data[0]);
                                    }

                                    return reply;
                                }
                            );
                        }
                    }

                    return reply;
                }
            ).then(
                (reply) => {
                    if (this.searchMapping) {
                        if (typeof this.searchMapping === 'string') {
                            this.options = reply.data.map(
                                (entry: any) => entry[this.searchMapping as string]
                            );
                        } else if (this.searchMapping.names && this.searchMapping.value) {
                            // Get the new options and convert them to their correct format
                            this.options = reply.data.map(
                                (entry: any) => {
                                    return  {
                                        name: entry[(this.searchMapping as DropdownElementData).names[0]],
                                        value: entry[(this.searchMapping as DropdownElementData).value],
                                        ...this.additionalFieldsFunction(entry)
                                    };
                                }
                            );
                        } else if (this.searchMapping.name && this.searchMapping.value) {
                            // Get the new options and convert them to their correct format
                            this.options = reply.data.map(
                                (entry: any) => {
                                    return {
                                        name: this.displayFilter
                                            ? this.displayFilter(entry)
                                            : entry[(this.searchMapping as DropdownElementData).name],

                                        value: entry[(this.searchMapping as DropdownElementData).value],
                                        ...this.additionalFieldsFunction(entry)
                                    };
                                }
                            );
                        } else if (this.searchMapping.value) {
                            this.options = reply.data.map(
                                (entry: any) => entry[(this.searchMapping as DropdownElementData).value]
                            );
                        }
                    }

                    // Filter the options by the ID's that should be excluded
                    if (this.excludeIdsFromSearch) {
                        this.options = this.options.filter((option) => {
                            if (option) {
                                if (
                                    (
                                        typeof option === 'string'
                                        && this.excludeIdsFromSearch.indexOf(option) >= 0
                                    ) || (
                                        (option as DropdownElementData).name
                                        && this.excludeIdsFromSearch.indexOf((option as DropdownElementData).name) >= 0
                                    ) || (
                                        (option as DropdownElementData).value
                                        && this.excludeIdsFromSearch.indexOf((option as DropdownElementData).value) >= 0
                                    )
                                ) {
                                    return false;
                                }
                            }
                            return true;
                        });
                    }

                    // Select the first option
                    if (isInitial && this.isRequired && [undefined, null].indexOf(this._model) >= 0) {
                        this.select(0);
                    }

                    if (isInitial && this.model) {
                        // Update the selected option to fit the model
                        this.filterValue = this.model.toString();
                        this.tabIndex = this.findOptionIndexMatchingFilter;
                        this.select(this.tabIndex);

                        if (isInitial && this.allowUndefined && this.isRequired) {
                            this.model = null;
                            this.selectedElement = null;
                        }
                    }

                    // Reset loading state
                    void this.$timeout(() => {
                        this.isLoading = false;
                        this.updateModel();
                        if (isInitial && this.allowUndefined && this.isRequired) {
                            this.model = null;
                            this.selectedElement = null;
                        }
                    });
                },
                // End loading state even when the request fails
                () => { this.isLoading = false; }
            );
        }
    };

    // Show all results
    public showAllResults = (isShowAll: boolean): void => {
        this.isShowAllResults = isShowAll;
    };

    // Select nothing. Only possible if not required
    public selectBlankOption = (): void => {
        if (!this.isRequired) {
            this.selectedElement = null;
            this.tabIndex = -1;
            this.close();
        }
    };

    // Set focus to the main input filter-field (searchbar)
    public focusFilterTextInputField = (): void => {
        void this.$timeout(() => {
            let textFieldEl = this.$element[0]?.querySelector('.dropdown-text');

            if (!textFieldEl) {
                textFieldEl = this.$element[0]?.querySelector('.hidden-focus-helper');
            }

            if (textFieldEl) {
                (textFieldEl as HTMLElement).focus();
            }
        });
    };

    // Scroll the `ul` to the currently selected `li`
    public scrollListToTabIndex = (): void => {
        void this.$timeout(() => {
            const optionsWrapperEl = document.querySelector('.dropdown-select');
            const selectedOptionEl = document.querySelector('.dropdown-option.focused');

            if (selectedOptionEl && optionsWrapperEl.clientHeight < optionsWrapperEl.scrollHeight) {
                selectedOptionEl.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
            }
        });
    };

    // Open the list of options
    public open = (): void => {
        if (this.options?.length === 0 && !this.isCustomisable && this.searchFunction === undefined) {
            return;
        }
        if (!this.isOpen) {
            this.filterValue = '';
            this.isOpen = true;
        }

        void this.$timeout(() => {
            this.focusFilterTextInputField();
            this.fetchOptionsFromApi();

            const optionsWrapperEl = document.querySelector('.molecule-form-drop-down-revised.open');
            const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
            if (optionsWrapperEl) {
                // Return if the total height is more than half the height of the object
                this.canOpenTowardsBottom = (viewportHeight / 2) > (
                    optionsWrapperEl.getBoundingClientRect().top
                    + (optionsWrapperEl.getBoundingClientRect().height / 2)
                );
            } else {
                this.canOpenTowardsBottom = true;
            }
        });
    };

    // Close the list of options
    public close = (): void => {
        this.isOpen = false;
        this.tabIndex = 0;

        // Force filter-value on close to show that the value is always selected
        if (this.isRequired) {
            if (this.selectedElement != null) {
                this.filterValue = this.optionValueToString(this.selectedElement);
            } else {
                this.filterValue = this.optionValueToString(this.options[0]);
            }
        }
    };

    // Toggle opening and closing the options-list
    public toggle = (): void => {
        if (this.isOpen) {
            this.close();
            this.onLostFocus();
        } else {
            this.open();
        }
    };

    // Set back the tab-index to the top-most position based on being customizable
    // 0 = default, -1 = with custom option
    public resetTabIndexToTop = (): void => {
        this.tabIndex = (this.isCustomisable && this.filterValue !== '' ? -1 : 0);
    };

    // Update the model
    public updateModel = (): void => {
        const tmpModel = this.selectedElement;

        // Set the correct string value from the properties
        if (
            Array.isArray(tmpModel)
            && tmpModel.length > 0
            && (tmpModel[0] as DropdownElementData).value
        ) {
            this._model = tmpModel.map((option: DropdownElementData) => option.value);
        } else if (
            [undefined, null].indexOf(tmpModel) < 0
            && (tmpModel as DropdownElementData).value !== undefined
        ) {
            this._model = (tmpModel as DropdownElementData).value;
        } else {
            this._model = tmpModel;
        }
    };

    // Select an option
    public select = (optionIndex: number|undefined, selectedElement: unknown = undefined): void => {
        if (this.filterValue !== '' && this.filteredOptions.length <= 0) {
            if (!this.isCustomisable) {
                return;
            }

            this.resetTabIndexToTop();
        }

        // Add new custom entry
        if (this.filterValue !== '' && this.tabIndex === -1) {
            if (this.isCustomisable) {
                this.addFilterAsCustomOption();
            }
            this._callbacks();

            return;
        }

        this.tabIndex = optionIndex ? optionIndex : 0;

        // Set the selected element in the original list
        if (optionIndex === undefined && selectedElement !== undefined) {
            this.selectedElement = selectedElement;
        } else {
            if (
                0 < this.filteredOptions.length
                && this.filteredOptions.length > optionIndex
            ) {
                this.selectedElement = this.filteredOptions[optionIndex];
            } else {
                this.selectedElement = this.options[0];
            }
        }

        this.close();

        // Update tab-index to fit the new size of the filtered items
        this.tabIndex = this.tabIndex >= this.filteredOptions.length
            ? this.filteredOptions.length - 1
            : this.tabIndex;
        this.focusFilterTextInputField();
        this.updateModel();

        this._callbacks();

    };

    // user cleared selection
    public selectNone = (): void => {
        if (!this.isRequired) {
            this.selectedElement = null;
            this.resetTabIndexToTop();
            this.updateModel();
            this.focusFilterTextInputField();
            this._callbacks();
        }
    };

    private _callbacks(): void {
        if (this.callbackOnChangeOutput) {
            this.callbackOnChangeOutput({data:this._model});
        }
        if (this.callbackOnChange) {
            this.callbackOnChange(this._model);
        }
    }

    // Convert an option value to readable/printable format
    public optionValueToString = (option: string | DropdownElementData): string => {
        if (!option) {
            return '';
        }
        let resultString = '';
        if ((option as DropdownElementData).name !== undefined) {
            resultString = (option as DropdownElementData).name;
        } else if ((option as DropdownElementData).names !== undefined) {
            resultString = (option as DropdownElementData).names[0]
                + (
                    (option as DropdownElementData).value
                        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        ? ` (ID: ${(option as unknown as DropdownElementData).value})`
                        : ''
                );
        } else if ((option as DropdownElementData).value !== undefined) {
            resultString = (option as DropdownElementData).value;
        } else if (typeof option === 'string') {
            resultString = option;
        }
        return resultString ? resultString : '-';
    };

    // Convert an option value to readable/printable format
    public modelValueToString = (option: string | DropdownElementData | (string | DropdownElementData)[]): string => {
        if (!option) {
            return '';
        }

        if (Array.isArray(option)) {
            return option.map((opt: string | DropdownElementData) => this.optionValueToString(opt)).join(',');
        } else {
            return this.optionValueToString(option);
        }
    };

    // Returns the number of additional fields per option. Excludes defaults like `name` or `value`
    public get totalAdditionalFieldsPerOption(): number {
        if (!this.options[0]) {
            return 0;
        }

        let totalDefaultFields = 0;

        if (
            (this.options[0] as DropdownElementData).name !== undefined
            || (this.options[0] as DropdownElementData).names !== undefined
        ) {
            ++totalDefaultFields;
        }

        if ((this.options[0] as DropdownElementData).value !== undefined) {
            ++totalDefaultFields;
        }

        return totalDefaultFields;
    }

    // Select the next option
    public incrementTabIndex = (): void => {
        this.open();

        if (this.tabIndex >= this.filteredOptions.length - 1) {
            this.tabIndex = (this.filterValue !== '' && this.isCustomisable ? -1 : 0);
        } else {
            ++this.tabIndex;
        }
    };

    // Select the previous option
    public decrementTabIndex = (): void => {
        this.open();

        if (this.tabIndex <= (this.filterValue !== '' && this.isCustomisable ? -1 : 0)) {
            this.tabIndex = this.filteredOptions.length - 1;
        } else {
            --this.tabIndex;
        }
    };

    // Opening the component itself with keyboard
    public openWithKeyboard = (event: KeyboardEvent): void => {
        if (
            !this.isOpen
            && this.isFocused
            && (
                event.code === 'Enter'
                || event.code === 'Space'
            ) && !event.shiftKey
        ) {
            event.preventDefault();
            this.open();
        }
    };

    // Handle keyboard input
    public handleKeyboardInput = (event: KeyboardEvent): void => {
        // Close on tab
        if (this.callbackOnInput) {
            this.callbackOnInput(this.filterValue);
        }

        switch (event.code) {
            case 'Tab':
                this.close();
                break;

            case 'ArrowDown':
                event.preventDefault();
                if (this.isOpen) {
                    this.incrementTabIndex();
                    this.scrollListToTabIndex();
                } else {
                    this.open();
                }
                break;

            case 'ArrowUp':
                event.preventDefault();
                if (this.isOpen) {
                    this.decrementTabIndex();
                    this.scrollListToTabIndex();
                } else {
                    this.open();
                }
                break;

            case 'Enter':
                if (this.isOpen === false) {
                    this.open();
                } else if (this.filterValue !== '' && this.filteredOptions.length === 0 && this.isCustomisable) {
                    this.addFilterAsCustomOption();
                } else if (this.tabIndex >= 0 || this.filterValue !== '') {
                    this.select(this.tabIndex);
                }
                break;

            case 'Escape':
                this.close();
                break;
        }
    };

    public filteredOptionsStringCase(): (string | DropdownElementData)[]{
        return (this.options as string[]).filter(
            (option) => {
                const isContainFilter: boolean = option.toString()
                    .toLowerCase()
                    .indexOf(
                        this.filterValue.toString().toLowerCase()
                    ) >= 0;
                const isEmptyFilter: boolean = this.filterValue === '';
                const isSelectedElement: boolean = option === this.selectedElement;

                return !isSelectedElement
                    && (
                        isEmptyFilter
                        || (
                            !isEmptyFilter
                            && isContainFilter
                        )
                    );
            }
        ).filter(
            (_, optionIndex) => {
                // Respect limit
                if (this.showLimit && !this.isShowAllResults) {
                    return optionIndex < this.showLimit;
                }
                return true;
            }
        );
    }

    public filteredOptionsDropdownElementDataCase(): (string | DropdownElementData)[]{
        return (this.options as DropdownElementData[]).filter(
            (option) => {
                let isContainFilter = false;

                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                if (option.value && option.value.toString().toLowerCase().indexOf(
                    this.filterValue.toString().toLowerCase()
                ) >= 0) {
                    isContainFilter = true;
                }

                if (
                    option.name
                    && option.name
                        .toString()
                        .toLowerCase()
                        .indexOf(this.filterValue.toString().toLowerCase()) >= 0
                ) {
                    isContainFilter = true;
                }

                if (option.names) {
                    option.names.forEach(
                        (optionName) => {
                            if (
                                typeof optionName === 'string'
                                && optionName
                                    .toString()
                                    .toLowerCase()
                                    .indexOf(this.filterValue.toString().toLowerCase()) >= 0
                            ) {
                                isContainFilter = true;
                            }
                        }
                    );
                }

                const isEmptyFilter: boolean = this.filterValue === '';
                const isSelectedElement: boolean = this.selectedElement
                    && (this.selectedElement as DropdownElementData).value ===  option.value;

                return !isSelectedElement && (isEmptyFilter || (!isEmptyFilter && isContainFilter));
            }
        ).filter(
            (_, optionIndex) => {
                // Respect limit
                if (this.showLimit && !this.isShowAllResults) {
                    return optionIndex < this.showLimit;
                }
                return true;
            }
        );
    }

    // Apply filter to the options
    public get filteredOptions(): (string | DropdownElementData)[] {
        if (!this.options) {
            return [];
        }

        // Skip filtering for now if a search-function is active
        if (this.searchFunction) {
            return this.options;
        }

        // Filter by ID
        if (this.filterValue[0] === '#') {
            return this.options.filter(
                (option) => (option as DropdownElementData).value === this.filterValue.slice(1)
            );
        }

        // Filter for string-array
        if (typeof this.options[0] === 'string') {
            return this.filteredOptionsStringCase();
        }

        // Filter for `DropdownElementData`
        return this.filteredOptionsDropdownElementDataCase();
    }

    // React to changes in filter
    public onFilterChange = (): void => {
        if (!this.isOpen && this.selectedElement != null) {
            this.filterValue = '';
        }

        this.open();

        if (
            this.isCustomisable
            && this.filterValue !== ''
            && this.filteredOptions.length <= 0
        ) {
            this.tabIndex = -1;
        }

        if (this.filteredOptions.length > 0) {
            this.tabIndex = (this.isCustomisable && this.filterValue !== '' ? -1 : 0);
        }

        this.fetchOptionsFromApi();
    };

    public get shouldDisplayAdditionalFields(): boolean{
        if (this.hideAdditionalFields){
            return false;
        }
        else {
            return this.optionsIsStringArray;
        }
    }

    public get optionsIsStringArray(): boolean {
        return this.options.length > 0 && typeof this.options[0] !== 'string';
    }

    // Returns a matching option to the current filter
    public get findOptionIndexMatchingFilter(): number {
        let foundIndex = -1;

        if (this.filteredOptions) {
            this.filteredOptions.forEach(
                (option, optionIndex) => {
                    const filterLower = this.filterValue.toString().toLowerCase();

                    if (
                        (typeof option === 'string' && option.toString().toLowerCase() === filterLower)
                        || (
                            [undefined, null].indexOf((option as DropdownElementData).value) < 0
                                ? ((option as DropdownElementData).value.toString().toLowerCase() === filterLower)
                                : false
                        )
                        || (
                            [undefined, null].indexOf((option as DropdownElementData).names) < 0
                                ? ((option as DropdownElementData).names[0].toString().toLowerCase() === filterLower)
                                : false
                        )
                        || (
                            [undefined, null].indexOf((option as DropdownElementData).name) < 0
                                ? ((option as DropdownElementData).name.toString().toLowerCase() === filterLower)
                                : false
                        )
                    ) {
                        foundIndex = optionIndex;
                    }
                }
            );
        }

        return foundIndex;
    }

    // Create a new custom option and add it to the list
    public addFilterAsCustomOption = (): void => {
        if (this.options.indexOf(this.filterValue) < 0) {
            if (typeof this.options[0] === 'string') {
                this.options.push(this.filterValue);
                this.customOptions.push(this.filterValue);
            } else if (this.options.length > 0 && this.options[0].name) {
                this.options.push({ name: this.filterValue, value: this.filterValue});
                this.customOptions.push({ name: this.filterValue, value: this.filterValue});
            } else if (this.options.length > 0 && this.options[0].names) {
                this.options.push({ names: [this.filterValue], value: this.filterValue});
                this.customOptions.push({ names: [this.filterValue], value: this.filterValue});
            } else {
                this.options.push(this.filterValue);
                this.customOptions.push(this.filterValue);
            }
            this.filterValue = '';

            this.select(this.filteredOptions.length - 1);
        }
    };

    // Handle losing focus by resetting the dropdown
    public onLostFocus = (): void => {
        this.close();
        this.filterValue = '';
    };
}

export class MoleculeFormDropDownRevisedComponent implements ng.IComponentOptions {
    public bindings = {
        additionalFieldsFunction: '<?',         // Additional properties that should be visible
        additionalFilters: '<?',                // Add additional filters to the search
        allowUndefined: '<?',                   // No default value for required dropdowns
        cacheLimit: '<?',                       // Maximum number of options to request from API for caching
        callbackOnChangeOutput: '&?',           // Gets called when any selection changes internally
        callbackOnChange: '<?',                 // Gets called when any selection changes internally
        callbackOnInput: '<?',                  // Gets called when any input changes
        displayFilter: '<?',                    // Filter that changes the user visible string
        excludeIdsFromSearch: '<?',             // List of ID's to exclude from search-return
        filterFields: '<?',                     // Defines properties that should be included in the search
        findByIdFunction: '<?',                 // Initial search to set model as preselected option
        hideAdditionalFields: '<?',             // Do not show every object attribute in dropdown
        isCustomisable: '<?customisable',       // Defines whether the chosen value must be in the options
        isDisabled: '<?disabled',               // When true the element is disabled
        isNoArrow: '<?noArrow',                 // When true, there is no arrow next to the searchbar
        isRequired: '<?required',               // When true, the dropdown always has a value
        model: '=?',                            // Active data value(s)
        options: '<?',                          // Data to display inside the dropdown/multiselect
        placeholder: '@',                       // Visible when nothing is selected
        preSelectedOption: '@',                 // Trying to match an existing option that gets preselected
        searchFunction: '<?',                   // Fetches options from the api
        searchMapping: '<?',                    // Map the result of the search function to the options-data
        showLimit: '<?'                         // Maximum number of options shown. #binding currently not used
    };
    public controller: any =  MoleculeFormDropDownRevisedController;
    public template = require('./drop-down-revised.html');
}
