import { UserModelService } from '../account/user-model';
import { AuthContextService } from '../auth-context';

import * as ng from 'angular';
import * as q from 'q';

export class TwoFaHelperService {
    public static $inject: string[] = ['userModel'];
    private twoFaObject: any = {};
    private tmpTwoFaObject: any = {
        digitCode: '',
        error: null,
        issetQrCode: false,
        oneTimePasswords: [],
        qrString: '',
        qrUrlString: '',
        regenerateToken: false,
        showToken: false,
        status: '',
        user: null,
        userPassword: ''
    };

    constructor(
        private userModel: UserModelService
    ) {}

    public resetAuthenticator = () => {
        this.initAuthenticator();
        return this.twoFaObject;
    };

    public enableGoogleAuthenticator = (user, password) => {
        this.initAuthenticator();

        if ([undefined, null].indexOf(user) >= 0) {
            return this.twoFaObject;
        }

        this.twoFaObject.user = user;
        this.twoFaObject.userPassword = password;

        return this.userModel.userEnableTwoFactorAuthentication(
            this.twoFaObject.user.id,
            'googleAuthenticator',
            this.twoFaObject.userPassword
        )
        .then(
            (qrCodeString) => {
                this.twoFaObject.error = null;
                this.twoFaObject.status = 'generateToken';
                this.twoFaObject.is2FAConfirmable = true;
                this.twoFaObject.showToken = true;
                this.twoFaObject.userPassword = '';

                const email = this.twoFaObject.user.emailAddress;

                this.twoFaObject.issetQrCode = true;
                this.twoFaObject.qrString = qrCodeString;

                const issuer = AuthContextService.accountOrganizationInformation !== null
                ? AuthContextService.accountOrganizationInformation.companyName
                : window.location.hostname;

                this.twoFaObject.qrUrlString =
                `otpauth://totp/${encodeURIComponent(email)}`
                + `?secret=${qrCodeString}`
                + `&issuer=${encodeURIComponent(issuer)}`;

                return this.twoFaObject;
            },
            (result) => {
                this.twoFaObject.error = result;
                return this.twoFaObject;
            }
        );
    };

    public activateGoogleAuthenticator = () => {
        return this.userModel.userActivateTwoFactorAuthentication(
            this.twoFaObject.user.id,
            this.twoFaObject.digitCode,
            undefined
        )
        .then(
            (result) => {
                this.twoFaObject.error = null;
                this.twoFaObject.issetQrCode = false;
                this.twoFaObject.qrString = '';
                this.twoFaObject.qrUrlString = '';
                this.twoFaObject.oneTimePasswords = result.responses;
                this.twoFaObject.userPassword = '';
                this.twoFaObject.status = 'active';

                return this.twoFaObject;
            },
            (result) => {
                this.twoFaObject.error = result;

                return q.reject(this.twoFaObject);
            }
        );
    };

    private initAuthenticator = () => {
        this.twoFaObject = ng.copy(this.tmpTwoFaObject);
    };
}
