import * as ng from 'angular';
import { ValidatePasswordMatch } from '../../../../../services';
import { FormInputModelOptions } from '../../../../atoms/form-elements';

export class MoleculeInputTextPasswordRepeatController implements ng.IController {
    public static $inject: string[] = ['$timeout', '$translate'];

    public validationErrorList = [];
    public value: string;
    public placeholder: string;
    public callbackOnChange: (arg0: any) => any;
    public callbackOnBlur: (arg0: any) => any;
    public callbackOnBlurParams: any;
    public customCallbackOnEnter: () => {};
    public doNotSaveOnFormEnter: boolean;
    public disabled: boolean;
    public disableRandomisedId: boolean;
    public modelOptions: FormInputModelOptions;
    public registerToForm: boolean;
    public options: any;
    public validationInstructions: any[];
    public inputId: string;
    public callbackOnClear: (arg0: any) => any;
    public callbackOnClearParams: any;
    public clearable: boolean;

    public revalidate: () => void; // set by molecule-input-text-with-validation

    private validator: ValidatePasswordMatch;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.validator = new ValidatePasswordMatch(this.$translate);
        this.validationInstructions = [
            {
                instructions: [this.options.compare],
                validator: this.validator
            }
        ];
    }

    public hideCharacters = () => {
        if (this.options == null || this.options === undefined) {
            return true;
        }
        if (this.options.hideCharacters != null && this.options.hideCharacters !== undefined) {
            return this.options.hideCharacters;
        }
        return true;
    };

    public $onChanges(changes) {
        if (changes.options != null && changes.options !== undefined) {
            this.validationInstructions = [{
                instructions : [this.options.compare],
                validator: this.validator
            }];

            if (this.revalidate && this.options.compare && this.value) {
                this.$timeout(() => this.revalidate());
            }
        }
    }

    public onPaste = (paste) => {
        // implement callback on paste here when needed!
    };
}

export class MoleculeInputTextPasswordRepeatComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnBlur: '<',
        callbackOnBlurParams: '<',
        callbackOnChange: '<',
        callbackOnClear: '<',
        callbackOnClearParams: '<',
        clearable: '<',
        customCallbackOnEnter: '<',
        disabled: '<',
        doNotSaveOnFormEnter: '<',
        disableRandomisedId: '<?',
        inputId: '@?',
        modelOptions: '<',
        options: '<',
        placeholder: '@',
        registerToForm: '<',
        validationErrorList: '=?',
        value: '='
    };
    public controller = MoleculeInputTextPasswordRepeatController;
    public template = require('./input-text-with-validation-wrapper.html');
}
