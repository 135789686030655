import * as ng from 'angular';

export class MoleculePanelEditRowTableHeadlineController implements ng.IController {
    public static $inject: string[] = [];
}

export class MoleculePanelEditRowTableHeadlineComponent implements ng.IComponentOptions {
    public transclude = true;
    public template = '<ng-transclude></transclude>';
    public controller =  MoleculePanelEditRowTableHeadlineController;
}
