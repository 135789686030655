import * as ng from 'angular';
import { WebhostingHelperService, WebspaceModelService, WebspaceRestrictionData } from '../../../../services';

export class MoleculeStatusBundleController implements ng.IController {
    public static $inject: string[] = ['webhostingHelper', 'webspaceModel'];

    public bundle;
    public status: string;
    public restrictions: WebspaceRestrictionData[];
    public deletionScheduledFor: any;
    public loadData = true;

    constructor(
        private webhostingHelper: WebhostingHelperService,
        private webspaceModel: WebspaceModelService
    ) {}

    public $onInit() {
        this.initValues();
    }

    public $onChanges(onChangesObj: angular.IOnChangesObject): void {
        if (onChangesObj.bundle !== undefined) {
            this.initValues();
        }
    }

    private initValues = () => {
        this.loadData = true;
        if ([undefined, null].indexOf(this.bundle) >= 0) {
            this.loadData = false;
            return;
        }

        this.deletionScheduledFor = this.bundle.deletionScheduledFor;
        this.restrictions = this.bundle.restrictions !== undefined ? this.bundle.restrictions : [];
        this.status = this.bundle.status;

        this.webspaceModel.findOneByBundleId(this.bundle.id).then(
            (webspace) => {
                this.loadData = false;
                if ([undefined, null].indexOf(webspace) < 0) {
                    this.status = webspace.status;
                    this.restrictions = this.webhostingHelper.handleWebspaceRestriction(webspace);
                }
            }
        );
    };
}

export class MoleculeStatusBundleComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<'
    };
    public controller =  MoleculeStatusBundleController;
    public controllerAs = '$status';
    public template = require('./status-bundle.html');
}
