import { LinkListOverviewItem } from '../../atomic-components/molecules/links/link-list-overview/link-list-overview';
import { AccountFilterField, SelectFilterField, TextFilterField } from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { UiRights } from '../../configuration';
import { AuthContextService } from '../../services';
import { OverviewModel } from '../abstract';
import { RequestCancellerService } from '../request-canceller';

export class VirtualMachineOverviewModel extends OverviewModel {

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('virtualMachineName', this.$translate.instant('TR_150119-f90338_TR')),
                new TextFilterField('virtualMachineId', this.$translate.instant('VM.VM.ID')),
                new TextFilterField('virtualMachineIpAddress', this.$translate.instant('VM.VM.IP')),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR')),
                new SelectFilterField(
                    'virtualMachineRescue',
                    this.$translate.instant('VM.GENERAL.OVERVIEW.ACTION.RESCUE'),
                    [
                        {
                            name: this.$translate.instant('VM.POWER.ON'),
                            value: 'on'
                        }, {
                            name: this.$translate.instant('VM.POWER.OFF'),
                            value: 'off'
                        }
                    ]
                ),
                new SelectFilterField(
                    'virtualMachinePower',
                    this.$translate.instant('VM.GENERAL.OVERVIEW.ACTION.POWER'),
                    [
                        {
                            name: this.$translate.instant('VM.POWER.ON'),
                            value: 'on'
                        }, {
                            name: this.$translate.instant('VM.POWER.OFF'),
                            value: 'off'
                        }
                    ]
                ),
                new SelectFilterField(
                    'virtualMachineStatus',
                    this.$translate.instant('TR_140119-8e4b60_TR'),
                    [
                        {
                            name: this.$translate.instant('35b2882a-2c6b-4291-b7de-963835933109'),
                            value: 'active'
                        }, {
                            name: this.$translate.instant('VM.STATUS.CREATING'),
                            value: 'creating'
                        }, {
                            name: this.$translate.instant('VM.STATUS.DELETING'),
                            value: 'deleting'
                        }, {
                            name: this.$translate.instant('GENERAL.OBJECT.STATUS.RESTORABLE'),
                            value: 'restorable'
                        }
                    ]
                )
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', '$state', 'authContext', 'machineModel']
    );

    readonly service = 'machine';
    readonly objectType = 'VirtualMachine';
    readonly idField = 'virtualMachineId';
    readonly defaultFilterFields = ['VirtualMachineName'];

    public activeMassAction: string | boolean = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};

    protected listCallbacks = [];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private $state: ng.ui.IStateService,
        private authContext: AuthContextService,
        private machineModel: any
    ) {
        super(RequestCanceller);

        this.listCallbacks = [{
            callback: this.listVirtualMachines
        }];
    }

    public actionLinks = (): LinkListOverviewItem[] => {
        return [
            {
                isGranted: this.authContext.isGrantedAny([
                    UiRights.MACHINE_JOBS_LIST,
                    UiRights.RES_POOL_LIST,
                    UiRights.ADMIN_SYSTEM_SUPER_USER_READ
                ]),
                linkList: [
                    {
                        group: 'redirect',
                        text: this.$translate.instant('MENU.CONTEXT.JOBS'),
                        route: 'machine.virtualmachines.jobs',
                        isGranted: this.authContext.isGranted(UiRights.MACHINE_JOBS_LIST)
                    },
                    {
                        group: 'redirect',
                        text: this.$translate.instant('TR_140119-1516a5_TR'),
                        route: 'machine.pools',
                        isGranted: this.authContext.isGrantedAll(
                            [UiRights.RES_POOL_LIST, UiRights.ADMIN_SYSTEM_SUPER_USER_READ]
                        )
                    }
                ],
                route: '',
                text: this.$translate.instant('TR_100119-a22157_TR'),
                type: 'multi'
            },
            {
                text: this.$translate.instant('TR_140119-9b5fa4_TR'),
                route: 'machine.virtualmachines.new'
            }
        ] as LinkListOverviewItem[];
    };

    public listVirtualMachines = (limit?, page?, filters?, sort?, cancel?) => {
        if (this.$state.current.name.split('.').indexOf('dashboard') >= 0) {
            return this.machineModel.listWithoutPagination(limit, page, filters, sort, cancel);
        } else {
            return this.machineModel.list(limit, page, filters, sort, cancel);
        }
    };
}
