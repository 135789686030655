import * as ng from 'angular';

import { LongDebounceInterval } from '../../../../../configuration/system';
import { DateWrapper, ValidateDate } from '../../../../../services';

import './date-select-fancy.scss';

type Day = { index: number; value: number; selected: boolean };
type Month = { name: string; value: number; selected: boolean };
type Year = { index: number; value: number; selected: boolean };

interface ValidateOptions {
    forbidPast: boolean;
    forbidFuture: boolean;
    allowEmpty?: boolean;
}

export class MoleculeFormDateSelectFancyController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public $formEdit;

    public calenderVisible = false;

    public yearItems: Year[] =  [];
    public monthItems: Month[] = [];
    public dayItems: Day[] = [];

    public year: Year;
    public month: Month;
    public day: Day;

    public yearInput: number | string;
    public monthInput: number | string;
    public dayInput: number | string;

    public showYears: boolean;
    public showMonths: boolean;
    public showDays: boolean;

    public date: DateWrapper;
    public dateFormat: string;
    public minYear?: number;
    public maxYear?: number;
    public registerToForm = false;
    public reverseYear?: boolean;
    public validationOptions: ValidateOptions;
    public buttonModifier: string;
    public longDebounceInterval = LongDebounceInterval;
    private registrationIndex: number;
    private validator: ValidateDate;
    private _allowEmpty: boolean;
    private dayValidationErrorList: { text: string }[];
    private monthValidationErrorList: { text: string }[];
    private yearValidationErrorList: { text: string }[];
    private dateValidationErrorList: { text: string }[];
    private _validationErrorList: { text: string }[];

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public callbackOnChange?: (date: DateWrapper) => void = () => {}; // tslint:disable-line:no-empty

    public callbackOnComplete?: (date: DateWrapper) => void = () => {}; // tslint:disable-line:no-empty

    public $onInit() {
        this.dayValidationErrorList = [];
        this.monthValidationErrorList = [];
        this.yearValidationErrorList = [];
        this.dateValidationErrorList = [];
        this._validationErrorList = [];

        if (this.dateFormat === undefined) {
            this.dateFormat = 'd.m.Y';
        }
        this.yearItems = Array(this.maxYear - this.minYear + 1)
            .fill({ value: null, index: null})
            .map((_value: number, index) => {
                // tslint:disable-next-line: radix
                const minYear = parseInt((this.minYear as unknown) as string);
                _value = index + minYear;
                return {
                    index: index,
                    selected: false,
                    value: _value
                };
            });

        if (this.reverseYear) {
            this.yearItems.reverse();
        }

        this.monthItems = [
            {
                name: this.$translate.instant('TR_090119-99a152_TR'),
                selected: false,
                value: 1
            },
            {
                name: this.$translate.instant('TR_090119-aa28f8_TR'),
                selected: false,
                value: 2
            },
            {
                name: this.$translate.instant('TR_090119-ba085d_TR'),
                selected: false,
                value: 3
            },
            {
                name: this.$translate.instant('TR_090119-de2991_TR'),
                selected: false,
                value: 4
            },
            {
                name: this.$translate.instant('TR_090119-2a0613_TR'),
                selected: false,
                value: 5
            },
            {
                name: this.$translate.instant('TR_090119-f9a01a_TR'),
                selected: false,
                value: 6
            },
            {
                name: this.$translate.instant('TR_090119-6356f4_TR'),
                selected: false,
                value: 7
            },
            {
                name: this.$translate.instant('TR_090119-bf8c2f_TR'),
                selected: false,
                value: 8
            },
            {
                name: this.$translate.instant('TR_090119-465792_TR'),
                selected: false,
                value: 9
            },
            {
                name: this.$translate.instant('TR_090119-4a93b5_TR'),
                selected: false,
                value: 10
            },
            {
                name: this.$translate.instant('TR_090119-ebf613_TR'),
                selected: false,
                value: 11
            },
            {
                name: this.$translate.instant('TR_090119-37ff07_TR'),
                selected: false,
                value: 12
            }
        ];

        this._daysOfMonth(31);
        this._setDateOnInit();

        this._allowEmpty = (this.validationOptions || {}).allowEmpty;
        if ([undefined, null].indexOf(this._allowEmpty) >= 0) {
            this._allowEmpty = true;
        }

        this.validator = new ValidateDate(
            this.$translate,
            (this.validationOptions || {}).forbidFuture || false,
            (this.validationOptions || {}).forbidPast || false,
            this._allowEmpty

        );

        if (this.registerToForm || [undefined, null].indexOf(this.registerToForm) >= 0) {
            this.registerToForm = true;
            if ([undefined, null].indexOf(this.$formEdit) < 0 && this.$formEdit !== null) {
                this.registrationIndex = this.$formEdit.registerValidator(this);
            }
        }
    }

    public $onDestroy() {
        if (this.registerToForm && [undefined, null].indexOf(this.$formEdit) < 0) {
            this.$formEdit.unregisterValidator(this.registrationIndex);
            this.$formEdit.validatorStatus[this.registrationIndex] = true;
        }
    }

    public toggleCalender() {
        if (this.calenderVisible) {
            this.showYears = false;
            this.showMonths = false;
            this.showDays = false;
        } else {
            this.showYears = true;
        }
        this.calenderVisible = !this.calenderVisible;
    }

    public hideCalender() {
        this.showYears = false;
        this.showMonths = false;
        this.showDays = false;
        this.calenderVisible = false;
    }

    public stepBack(step: string) {
        if (step === 'erase') {
            this.selectYear();
        } else if (step === 'close') {
            this.showYears = false;
            this.calenderVisible = false;
        } else if (step === 'year') {
            this.showYears = true;
            this.showMonths = false;
        } else if (step === 'month') {
            this.showMonths = true;
            this.showDays = false;
        }
    }

    public manualYearInput = (year?: Year) => {
        this._setYear = year;
        this._onDateChange({
            type: 'year',
            value: year.value
        });
    };

    public manualMonthInput = (month?: Month) => {
        this._setMonth = month;
        this._onDateChange({
            type: 'month',
            value: month.value
        });
    };

    public manualDayInput = (day?: Day) => {
        this._setDay = day;
        this._onDateChange({
            type: 'day',
            value: day.value
        });
    };

    public selectYear = (year?: Year | undefined) => {
        if (year) {
            this._daysOfMonth(this._getNumberOfDaysInMonth(
                this.month?.value || 0,
                year?.value || this.minYear
            ));
            this._setYear = year;
            this._setMonth = this.monthItems[0];
            this._setDay = this.dayItems[0];
            this._onDateChange({
                type: 'year',
                value: year.value
            });
            this.showYears = false;
            this.showMonths = true;
        } else {
            this._daysOfMonth(this._getNumberOfDaysInMonth(
                this.month?.value || 0,
                year?.value || this.minYear
            ));
            this._setYear = undefined;
            this._setMonth = undefined;
            this._setDay = undefined;
            this._onDateChange(undefined);
        }
    };

    public selectMonth = (month: Month) => {
        this._daysOfMonth(this._getNumberOfDaysInMonth(
            month?.value,
            this.year?.value || this.minYear
        ));
        this._setMonth = month;
        this._setDay = this.dayItems[0];
        this._onDateChange({
            type: 'month',
            value: month.value
        });
        this.showMonths = false;
        this.showDays = true;
    };

    public selectDay = (day: Day) => {
        this._setDay = day;
        this._onDateChange({
            type: 'day',
            value: day.value
        });
        this.showDays = false;
        this.calenderVisible = false;
    };

    public validate = () => {
        if (
            this._allowEmpty
            && (
                [undefined, null].indexOf(this.date?.day) >= 0
                || [undefined, null].indexOf(this.date?.month) >= 0
                || [undefined, null].indexOf(this.date?.year) >= 0
            )
        ) {
            return true;
        }

        this.dateValidationErrorList = this.validator.validate(this.date);
        return this.dateValidationErrorList.length === 0;
    };

    public inputChangeListener = (_e, inputType: 'day' | 'month' | 'year', inputValue: number) => {
        if ([undefined, null].indexOf(inputValue) >= 0) {
            return;
        }
        this._updateElement(inputType, inputValue);
    };

    private _updateElement = (inputType: 'day' | 'month' | 'year', inputValue: number) => {
        switch (inputType) {
            case 'day':
                // eslint-disable-next-line no-case-declarations
                const foundDay = this.dayItems.find((day) => day.value === inputValue);
                if (foundDay) {
                    this.manualDayInput(foundDay);
                    this.dayValidationErrorList = [];
                } else {
                    this.dayValidationErrorList = [{
                        text: 'Der eingegebene Tag ist nicht korrekt'
                    }];
                }
                break;
            case 'month':
                // eslint-disable-next-line no-case-declarations
                const foundMonth = this.monthItems.find((month) => month.value === inputValue);
                if (foundMonth) {
                    this.manualMonthInput(foundMonth);
                    this.monthValidationErrorList = [];
                } else {
                    this.monthValidationErrorList = [{
                        text: 'Der eingegebene Monat ist nicht korrekt'
                    }];
                }
                break;
            case 'year':
                // eslint-disable-next-line no-case-declarations
                const foundYear = this.yearItems.find((year) => year.value === inputValue);
                if (foundYear) {
                    this.manualYearInput(foundYear);
                    this.yearValidationErrorList = [];
                } else {
                    this.yearValidationErrorList = [{
                        text: 'Das eingegebene Jahr ist nicht korrekt'
                    }];
                }
                break;
        }

        this.validationErrorList = [].concat(
            this.monthValidationErrorList,
            this.yearValidationErrorList,
            this.dayValidationErrorList);
    };

    private _getNumberOfDaysInMonth = (month?: number, year?: number): number => {
        if ([undefined, null].indexOf(month) >= 0) {
            return 31; // If we do not know the month, we show 31 days as a default
        }

        switch (month) {
            case 4:
            case 6:
            case 9:
            case 11:
                return 30;
            case 2:
                return this._getNumberOfDaysInFebruary(year);
            default:
                return 31;
        }
    };

    private _getNumberOfDaysInFebruary = (year?: number): number => {
        if ([undefined, null].indexOf(year) >= 0) {
            return 28;
        }
        if (year % 400 === 0) {
            return 29;
        }
        if (year % 100 === 0) {
            return 28;
        }
        if (year % 4 === 0) {
            return 29;
        }
        return 28;
    };

    private _setDateOnInit() {
        if (typeof this.date === 'string') {
            this.date = new DateWrapper(new Date(String(this.date)));
        }

        if (this.date instanceof DateWrapper) {
            this._setYear = this.yearItems.filter((val) => val.value === this.date.year).shift();
            this._setMonth = this.monthItems.filter((val) => val.value === this.date.month).shift();
            this._setDay = this.dayItems.filter((val) => val.value === this.date.day).shift();
        }
    }

    private _onDateChange(change?: { type: string; value: number }) {
        if (change === undefined) {
            this.date = new DateWrapper();
            this.callbackOnComplete(this.date);
        } else if (change.type === 'year') {
            this.date = new DateWrapper({
                day: this.dayInput as number || 1,
                month: this.month?.value || 0,
                year: change?.value || parseInt(this.minYear as unknown as string, 10)
            });
        } else if (change.type === 'month') {
            this.date = new DateWrapper({
                day: this.dayInput as number || 1,
                month: change?.value || this.month?.value || 1,
                year: this.year?.value || parseInt(this.minYear as unknown as string, 10)
            });
        } else if (change.type === 'day') {
            this.date = new DateWrapper({
                day: change?.value || 1,
                month: this.month?.value || 0,
                year: this.year?.value || parseInt(this.minYear as unknown as string, 10)
            });
        }

        if (this.registrationIndex) {
            this.$formEdit.validate(this.registrationIndex);
        } else {
            this.validate();
        }

        this.callbackOnChange(this.date);

        if (this.yearInput && this.dayInput && this.monthInput) {
            this.callbackOnComplete(this.date);
        }
    }

    private _daysOfMonth(dayCount: number) {
        this.dayItems = Array(dayCount)
            .fill(null)
            .map((_value, index) => {
                return {
                    index: index,
                    selected: false,
                    value: index + 1 };
            });
    }

    private set _setYear(year: Year | undefined) {
        if (this.year) {
            this.year.selected = false;
        }
        if (year) {
            year.selected = true;
        }
        this.year = year;
        this.yearInput = this.year?.value || '';
    }

    private set _setMonth(month: Month | undefined) {
        if (this.month) {
            this.month.selected = false;
        }
        if (month) {
            month.selected = true;
        }
        this.month = month;
        this.monthInput = this.month?.value || '';
    }

    private set _setDay(day: Day | undefined) {
        if (this.day) {
            this.day.selected = false;
        }
        if (day) {
            day.selected = true;
        }
        this.day = day;
        this.dayInput = this.day?.value || '';
    }

    public set validationErrorList(value: { text: string }[]) {
        this._validationErrorList = value;
    }
    public get validationErrorList() {
        return this._validationErrorList;
    }
}

export class MoleculeFormDateSelectFancyComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnChange: '<?',
        callbackOnComplete: '<?',
        date: '=',
        dateFormat: '@?',
        maxYear: '@?',
        minYear: '@?',
        registerToForm: '<',
        reverseYear: '@?',
        validationErrorList: '=?',
        validationOptions: '<?'
    };
    public require = {
        $formEdit: '?^moleculeFormEdit'
    };
    public controller = MoleculeFormDateSelectFancyController;
    public template = require('./date-select-fancy.html');
}
