import * as ng from 'angular';
import { NavigationService } from '../navigation';

export class OverviewHelperService {
    public static $inject: string[] = ['$state', 'navigation'];

    private overviewsWithoutMassActions: any[] = [
        {
            objectType: 'Invoices',
            service: 'billing'
        },
        {
            objectType: 'DomainContact',
            service: 'domain-contacts'
        },
        {
            objectType: 'Domain',
            service: 'domain'
        },
        {
            objectType: 'VirtualMachine',
            service: 'machine'
        },
        {
            objectType: 'Redirection',
            service: 'redirections'
        },
        {
            objectType: 'Ssl-Certificate',
            service: 'ssl.certificates'
        },
        {
            objectType: 'VHost',
            service: 'webhostin'
        },
        {
            objectType: 'Webspace',
            service: 'webhosting'
        }
    ];

    constructor(
        private $state: ng.ui.IStateService,
        private navigation: NavigationService
    ) {}

    // webhosting
    // Application
    // tslint:disable:cyclomatic-complexity
    public handleRowClickRoutes = (
        service: string,
        objectType: string,
        item: any,
        selectedItems: any,
        bundle: any,
        expertView: boolean,
        itemEdit?: boolean
    ) => {
        itemEdit = itemEdit || false; // itemEdit isset true, if in all cases the edit route should be called!

        if (expertView && !itemEdit) {
            if (
                selectedItems !== undefined
                && !this.overviewsWithoutMassActions.some(
                    (overview) => overview.service === service && overview.objectType === objectType
                )
            ) {
                selectedItems[item.id] = !selectedItems[item.id];
            }
        } else {
            let route = '';
            const routeParams: any = {};

            if (bundle && [undefined, null].indexOf(bundle.id) < 0) {
                route = 'bundle.id';
                routeParams.bundleId = bundle.id;
            } else if (this.$state.current.name.split('.')[0] === 'managed-servers') {
                route = 'managed-servers.id';
                routeParams.machineId = this.$state.$current.locals.globals.machine.id;
            } else {
                route = service;
            }

            if (item.id !== null && service === 'user') {
                route = this.$state.current.name.split('.').indexOf('subaccounts') < 0
                ? 'account.users'
                : 'reseller.subaccounts.id.users';
            }

            switch (objectType) {
                default: break;

                case 'Application':
                    if (bundle && [undefined, null].indexOf(bundle.id) < 0) {
                        return;
                    } else {
                        route = 'webhosting.webspaces.id.edit';
                        routeParams.webspaceId = item.webspaceId;
                    }
                    break;

                case 'Bundle':
                    route = 'bundle.id.dashboard';
                    routeParams.bundleId = item.id;
                    break;

                case 'Cronjob':
                    if (route === 'bundle.id') {
                        route += '.cronjobs.id.edit';
                    } else {
                        route += '.webspaces.id.cronjobs.id.edit';
                    }

                    routeParams.cronjobId = item;
                    break;

                case 'Database':
                    route += '.databases.id.edit';
                    routeParams.databaseId = item.id;
                    break;

                case 'DatabaseUser':
                    route += '.users.id.edit';
                    routeParams.userId = item.id;
                    break;

                case 'Domain':
                    if (item.status === 'unconfigured'){
                        route = 'domain.domains.id.edit';
                        routeParams.domainId = item.id;

                    } else {
                        route += '.domains.id.edit';
                        routeParams.domainId = item.id;
                        routeParams.vhost = 'vhost';
                    }
                    break;

                case 'DomainProduct':
                    if ([undefined, null].indexOf(bundle) >= 0) {
                        route = 'domain.domains.id.edit';
                    } else {
                        route += '.domains.id.edit';
                    }
                    routeParams.domainId = item.id;
                    break;

                case 'DomainContact':
                    route = 'domain.contacts.id.edit';
                    routeParams.contactId = item.id;
                    break;

                case 'DomainTransfer':
                    if (selectedItems !== undefined) {
                        selectedItems[item.id] = !selectedItems[item.id];
                    }
                    return;

                case 'Invoices':
                    route = 'billing.invoices.id.edit';
                    // To-DO: Change filter to get invoice with id - after filter works
                    // routeParams['invoiceId'] = item.documentId;
                    routeParams.invoiceId = item.documentNumber;
                    break;

                case 'Mailbox':
                    route += '.mailboxes.id.edit';
                    routeParams.mailboxId = item.id;
                    break;

                case 'ExchangeOrganization':
                    route += '.organizations.id.edit';
                    routeParams.organizationId = item.id;
                    break;

                case 'SubAccount':
                    route = 'reseller.subaccounts.id.edit';
                    routeParams.subaccountId = item.id;
                    break;

                case 'Webspace':
                    const stateParts = this.$state.current.name;
                    if (stateParts[0] === 'bundle') {
                        // webspace, within a bundle
                        route = 'bundle.id.dashboard';
                        routeParams.bundleId = item.bundleId;
                    } else {
                            // webspace - not in a bundle
                            route += '.webspaces.id.edit';
                            if ([undefined, null].indexOf(item.webspaceId) >= 0) {
                                routeParams.webspaceId = item.id;
                            } else {
                                routeParams.webspaceId = item.webspaceId;
                            }
                    }

                    break;

                case 'VHost':
                    route += '.webspaces.id.vhosts.id.edit';
                    routeParams.vHostId = item.id;
                    routeParams.webspaceId = item.webspaceId;
                    break;

                case 'WebspaceUser':
                    route += '.users.id.edit';
                    routeParams.userId = item.id;
                    break;

                case 'User':
                    route += '.id.edit';
                    routeParams.userId = item.id;
                    break;

                case 'VirtualMachine':
                    route += '.virtualmachines.id.edit';
                    routeParams.machineId = item.id;
                    break;

                case 'Redirection':
                    route = 'domain.redirections.id.edit',
                    routeParams.redirectionId = item.id;
                    break;

                case 'RightsTemplate':
                    route = 'reseller.rightstemplates.id.edit';
                    routeParams.rightsTemplateId = item.id;
                    break;

                case 'Ssl-Certificate':
                    route += '.id.edit';
                    routeParams.certificateId = item.id;
                    break;

                case 'NameserverSet':
                    route = 'dns.nameserversets.id.edit';
                    routeParams.nameserverSetId = item.id;
                    break;

                case 'DnsZone':
                    route = 'dns.zones.id.edit';
                    routeParams.zoneId = item.id;
                    break;

                case 'DnsZoneTemplate':
                    route = 'dns.templates.id.edit';
                    routeParams.templateId = item.id;
                    break;
            }

            this.navigation.go(route, routeParams);
        }
    };

    public selectedAllIndeterminate = (itemList, selectedItemList) => {
        if (!Array.isArray(itemList)) {
            return false;
        }

        const selectedItems = Object
            .getOwnPropertyNames(selectedItemList)
            .filter((item) => selectedItemList[item])
            .length;

        return itemList?.length !== selectedItems
            && selectedItems > 0;
    };
}
