import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import {
    ApiErrorModel,
    CacheService,
    DocumentModelService,
    FunctionRelayService
} from '../../../services';

import * as ng from 'angular';

export class TemplateInvoicePaymentCommitController {
    public static $inject: string[] = [
        '$q',
        '$translate',
        'apiErrorModel',
        'cache',
        'documentModel',
        'functionRelay'
    ];

    public modifier = '';
    public pageHeaderData: PanelHeaderData = {
        backwardLink: 'billing.invoices.overview',
        backwardText: this.$translate.instant('TR_090119-af8a66_TR'),
        panelHeaderRoute: '',
        panelHeaderRouteParams: null,
        panelIcon: 'money-bill-alt',
        panelTitle: this.$translate.instant('TR_090119-0e86f1_TR')
    };

    public apiRequestFailed = false;
    public paymentServiceType: string;
    private token: string;
    private payerId: string;

    constructor(
        private $q: ng.IQService,
        private $translate: ng.translate.ITranslateService,
        private apiErrorModel: ApiErrorModel,
        private cache: CacheService,
        private documentModel: DocumentModelService,
        private functionRelay: FunctionRelayService
    ) {}

    public $onInit() {
        this.apiErrorModel.destroyErrorList();
        let token: string|undefined;
        let payerId: string|undefined;
        let paymentId: string|undefined;

        switch (this.paymentServiceType) {
            case 'paypal':
                token = this.token;
                payerId = this.payerId;
                break;
            case 'stripe':
                paymentId = this.token;
                break;
            default:
                this.apiRequestFailed = true;
                return;
        }
        this.documentModel.payment.commit(this.paymentServiceType, token, payerId, paymentId).then(
            (result: any) => {
                if (this.apiErrorModel.apiResponseHasError(result)) {
                    this.apiRequestFailed = true;
                    return this.$q.reject(result);
                }
                this.cache.get('billing::depositGet').clear();
                this.functionRelay.call('updateDeposit', {});
                return result;
            },
            (err: any) => {
                this.apiRequestFailed = true;
                return this.$q.reject(err);
            }
        );
    }
}

export class TemplateInvoicePaymentCommitComponent implements ng.IComponentOptions {
    public bindings = {
        payerId: '<',
        paymentServiceType: '@?',
        token: '<'
    };
    public controller = TemplateInvoicePaymentCommitController;
    public controllerAs = '$TemplateInvoicePaymentCommitCtrl';
    public template = require('./invoice-payment-commit-template.html');
}
