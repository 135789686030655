import ng from 'angular';
import { SepaData } from '../../setup';
import {
    DateWrapper,
    AuthenticationRobotService,
    AlertManagerService,
} from '@/services';

export class TemplateSetupSepaPanelController {
    public static $inject: string[] = [
        'authenticationRobot',
        'alertManager',
        '$translate',
    ]
    constructor(
        private authenticationRobot: AuthenticationRobotService,
        private alertManager: AlertManagerService,
        private $translate: ng.translate.ITranslateService,
    ){}

    public sepaStatus: string;
    public sepaFormIsValid: boolean = false;
    public sepaDataInput: SepaData = {iban: '', bic: '', accountHolder: '' };
    public sepaUsersDateOfBirth = new DateWrapper();
    public sepaUsersDateOfBirthIsSet = false;

    public paymentMethodIsUpdating: boolean;
    public saveSepaPaymentMethod: (
        sepaData: SepaData,
        usersDateOfBirth: DateWrapper
    ) => Promise<void>;

    public get getSepaStatus(): string {
        return this.sepaStatus;
    }

    // hacky validation. Existing validation doesn't check if input is empty without focusing it.
    // Edit: The Date Compontent is shit. If you enter one value, the gets initialized with 01.00.(thisYear-120).
    // We will propably delete the Date Component from Sepa => we are fine using validation below
    // Edit 2: Validations below are useless, after a working Date got entered.
    get disableSepaSaveButton(): boolean {
        if (this.paymentMethodIsUpdating) {
            return true;
        }
        const dayIsValid = (this.sepaUsersDateOfBirth?.day > 0 && this.sepaUsersDateOfBirth?.day < 32);
        const monthIsValid = (this.sepaUsersDateOfBirth?.month > 0 && this.sepaUsersDateOfBirth?.month < 13);
        const yearIsValid = (this.sepaUsersDateOfBirth?.year > (new DateWrapper(new Date()).year - 120)
                            && this.sepaUsersDateOfBirth?.year <= new DateWrapper(new Date()).year - 16);
        const dateIsValid = (dayIsValid && monthIsValid && yearIsValid);

        if (this.sepaDataInput?.iban === '' || this.sepaDataInput?.accountHolder === '' || !dateIsValid || !this.sepaFormIsValid) {
            return true;
        }
        return false;
    }

    public onSaveSepa = () => {
        this.saveSepaPaymentMethod(
            this.sepaDataInput,
            this.sepaUsersDateOfBirth,
        ).then(() => {
            this.authenticationRobot.refreshAccountData();
            void this.alertManager.success(this.$translate.instant('TR_090119-148f94_TR'));
        }).catch(() => this.paymentMethodIsUpdating = false);
    }

    public get sepaPanelTitle(): string {
        return this.$translate.instant("TR_080819-96f337_TR");
    }

    public get sepaHint(): string {
        return this.$translate.instant("TR_141024-132182_TR");
    }

    public get isActivatedOrPending(): boolean {
        return (this.getSepaStatus === 'pendingVerification' || this.getSepaStatus === 'active');
    }
}

export class TemplateSetupSepaPanelComponent implements ng.IComponentOptions {
    public bindings = {
        sepaStatus: '<',
        paymentMethodIsUpdating: '=',
        saveSepaPaymentMethod: '=',
    };
    public template = require('./sepa-panel.html');
    public controller = TemplateSetupSepaPanelController;
}
