import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';

export class TemplateBundleAddDomainController {
    public static $inject: string[] = ['$translate'];

    public modifier = '';
    public pageHeaderData: PanelHeaderData;
    public bundle: any;
    public faqArticleIdList: string[] = [
        'webspace-expand-memory',
        'webspace-ftp-folders'
    ];

    public userPanelRight;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'bundle.id.dashboard',
            backwardText: this.$translate.instant('TR_090119-a32485_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: { bundleId: this.bundle.id },
            panelIcon: 'box-alt',
            panelTitle: this.$translate.instant('a5a5763c-7678-4abf-b645-f0d19c9db6bb')
        };

        this.userPanelRight = {
            edit: {
                editPanelButton: AuthContextService.isGranted(UiRights.BIL_BUNDLE_EDIT),
                overrideStorageQuota: AuthContextService.isGranted(UiRights.BIL_BUNDLE_EDIT)
            }
        };
    };
}

export class TemplateBundleAddDomainComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<'
    };
    public controller = TemplateBundleAddDomainController;
    public controllerAs = '$TemplateBundleAddDomainCtrl';
    public template = require('./bundle-add-domain-template.html');
}
