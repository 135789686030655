export const DirectDebit = {
    allowed: [
        'AT',
        'BE',
        'DE',
        'DK',
        'ES',
        'FI',
        'FR',
        'LU',
        'NL',
        'PT',
        'SE'
    ]
};
