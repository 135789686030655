import { Validator } from './abstract-validator';
import { ValidateAllowedCharacters } from './validate-allowed-characters';
import * as lengthValidator from './validate-length';

import * as ng from 'angular';

export class ValidatePhone extends Validator {
    public static $inject: string[] = ['$translate'];

    private minLength: number;
    private maxLength: number;
    private demandE123: boolean;
    private allowedCharaters: string;
    private allowEmpty: boolean;

    constructor(
        minLength: number,
        maxLength: number,
        demandE123: boolean,
        allowedCharaters: string,
        allowEmpty: boolean,
        private $translate: ng.translate.ITranslateService
    ) {
        super();
        this.demandE123 = demandE123;
        this.allowedCharaters = allowedCharaters;
        this.allowEmpty = allowEmpty;
        this.minLength = minLength || null;
        this.maxLength = maxLength || null;
    }

    public validate = (value) => {
        let reply = [];

        if (value === '' || value === null || value === undefined) {
            if (!this.allowEmpty) {
                reply.push({ text: this.$translate.instant('DOMAIN.HANDLE.PHONE.VALIDATION.REQUIRED') });
            }

            return reply;
        }

        if (this.demandE123) {
            if (!(value[0] === '+') || value.split(' ').length !== 3) {
                reply.push({ text: this.$translate.instant('DOMAIN.HANDLE.PHONE.VALIDATION.E123') });
            }
        }

        const validateAllowedCharacters = new ValidateAllowedCharacters(this.allowedCharaters, this.$translate);
        this.checkMinLength(value, reply);
        this.checkMaxLength(value, reply);
        reply = reply.concat(validateAllowedCharacters.validate(value));

        return reply;
    };

    private checkMinLength = (value: string, reply: any[]) => {
        if (this.minLength) {
            const checkMinLength = new lengthValidator.ValidateMinLength (this.$translate, this.minLength);

            if (checkMinLength.validate(value).length > 0) {
                reply.push ({ text: this.$translate.instant('TR_211019-0be62a_TR', { minLength: this.minLength }) });
            }
        }
    };

    private checkMaxLength = (value: string, reply: any[]) => {
        if (this.maxLength) {
            const checkMaxLength = new lengthValidator.ValidateMaxLength (this.$translate, this.maxLength);

            if (checkMaxLength.validate(value).length > 0) {
                reply.push({ text: this.$translate.instant('TR_211019-872f33_TR', { maxLength: this.maxLength }) });
            }
        }
    };
}
