import { UiRights } from '@/configuration';
import { BundleApi, DomainApi, ViewTypes, WebhostingApi } from '@/types';
import { AuthContextService } from '../auth-context';
import { BundleModelService } from '../bundle';
import { VhostModelService, WebspaceModelService } from '../webhosting';

export interface DomainWithVHost extends DomainApi.Domain{
    bundleVhostMapId?: string;
}

export class DomainEnricher{
    private vhostModel: VhostModelService;
    private webspaceModel: WebspaceModelService;
    private bundleModel: BundleModelService;
    private authContext: AuthContextService;

    constructor(vhostModel: VhostModelService,
        webspaceModel: WebspaceModelService,
        bundleModel: BundleModelService,
        authContext: AuthContextService){
            this.authContext = authContext;
            this.vhostModel = vhostModel;
            this.webspaceModel = webspaceModel;
            this.bundleModel = bundleModel;
    }

    static vHostFilterFactory(domains: DomainApi.Domain[]):
    {subFilter: {field: string; value: string}[]; subFilterConnective: string}{
        return {
        subFilter: domains.map(domain => {
            return { field: 'vhostDomainNameUnicode', value: domain.nameUnicode };
        }),
        subFilterConnective:'OR'
        };
    }

    static webSpaceFilterFactory(vHosts: WebhostingApi.VHost[]):
    {subFilter: {field: string; value: string}[]; subFilterConnective: string}{
        return {
        subFilter:
            vHosts
            .filter(vhost => vhost.webspaceId)
            .map(vhost => {
                return {field:'webspaceId', value:vhost.webspaceId};
            }),
        subFilterConnective:'OR'
        };
    }

    static bundleFilterFactory(webspaces: (WebhostingApi.Webspace|DomainApi.Domain)[]):
    {subFilter: {field: string; value: string}[]; subFilterConnective: string}{
        return {
            subFilter: webspaces.map(webspace => {
                return { field: 'BundleId', value: webspace.bundleId };
            }),
            subFilterConnective:'OR'
            };
    }

    async assignBundleNamesWithVhosts( value: ViewTypes.EntityWithPagination<DomainApi.Domain>):
     Promise<ViewTypes.EntityWithPagination<DomainApi.Domain>>{
        const domainsWithoutBundleId = value.data.filter(domain => !domain.bundleId);
        const VhostFilter = DomainEnricher.vHostFilterFactory(domainsWithoutBundleId);
        let vHosts: WebhostingApi.VHost[];
        if (VhostFilter.subFilter.length > 0 && this.authContext.isGranted(UiRights.WEB_OBJECT_LIST)){
            vHosts = await this.vhostModel.listWithoutPagination(undefined, undefined, VhostFilter)
            .then((vhosts: ViewTypes.EntityWithPagination<WebhostingApi.VHost>) => {return vhosts.data;});
        } else {
            vHosts = [];
        }

        const WebSpaceFilter = DomainEnricher.webSpaceFilterFactory(vHosts);
        let webSpaces: WebhostingApi.Webspace[];
        if (WebSpaceFilter.subFilter.length > 0 && this.authContext.isGranted(UiRights.WEB_OBJECT_LIST)){
            webSpaces = await this.webspaceModel.listWithoutPagination(undefined, undefined, WebSpaceFilter)
            .then((webSpaces: ViewTypes.EntityWithPagination<WebhostingApi.Webspace>) => { return webSpaces.data; });
        } else {
            webSpaces = [];
        }

        const BundleFilter = DomainEnricher.bundleFilterFactory([...webSpaces, ...value.data.filter(domain => !!domain.bundleId)]);
        let bundles: BundleApi.Bundle[];
        if (BundleFilter.subFilter.length > 0 && this.authContext.isGranted(UiRights.BIL_BUNDLE_LIST)){
            bundles = await this.bundleModel.list(undefined, undefined, BundleFilter).
            then((bundles: ViewTypes.EntityWithPagination<BundleApi.Bundle>) => {return bundles.data;});
        } else {
            bundles = [];
        }

        return this.collectDomainsAndMapBundleNames(value, vHosts, domainsWithoutBundleId, webSpaces, bundles);

    }

    private collectDomainsAndMapBundleNames(
        originalInput: ViewTypes.EntityWithPagination<DomainApi.Domain>,
        vHosts: WebhostingApi.VHost[],
        domainsWithoutBundleId: DomainApi.Domain[],
        webSpaces: WebhostingApi.Webspace[],
        bundles: BundleApi.Bundle[]
            ): ViewTypes.EntityWithPagination<DomainApi.Domain> {
        const domainsWithVHostMapId = this.findDomainsWithVHostMapId(vHosts, domainsWithoutBundleId, webSpaces);
        const domainsWithBundle = this.filterDomainsWithBundle(originalInput);
        const ret = this.createEntityWithPagination([...domainsWithBundle, ...domainsWithVHostMapId], originalInput.pagination);
        this.mapBundleNameForDomains(ret, bundles);
        return ret;
    }

    private mapBundleNameForDomains(ret: { data: DomainWithVHost[];
        pagination: { currentPage: number; entries: number; limit: number }; },
        bundles: BundleApi.Bundle[]): void {
        // sets the bundle name only if there is a bundle with a corresponding id
        ret.data.map(domain => domain.bundleName = bundles.find(bundle => bundle.id === domain.bundleVhostMapId) !== undefined
            ? bundles.find(bundle => bundle.id === domain.bundleVhostMapId).name
            : undefined);
    }

    private createEntityWithPagination<T>(data: T[], pagination: any): ViewTypes.EntityWithPagination<T>{
        return {data:data, pagination:pagination};
    }

    private findDomainsWithVHostMapId(
        vHosts: WebhostingApi.VHost[],
        domainsWithoutBundleId: DomainApi.Domain[],
        webSpaces: WebhostingApi.Webspace[]
        ): DomainWithVHost[] {
        return domainsWithoutBundleId.map((domainWithVHost: DomainWithVHost) => {
            return this.addVHostMapId(webSpaces, vHosts, domainWithVHost);
        });
    }

    private filterDomainsWithBundle(value: ViewTypes.EntityWithPagination<DomainApi.Domain>): DomainApi.Domain[] {
        return value.data
            .filter(domain => !!domain.bundleId)
            .map((domain: DomainWithVHost) => {
                domain.bundleVhostMapId = domain.bundleId;
                return domain;
            });
    }

    private addVHostMapId(webSpaces: WebhostingApi.Webspace[], vHosts: WebhostingApi.VHost[], domainWithVHost: DomainWithVHost): DomainWithVHost{

        const foundWhost = vHosts.find(vHost => vHost.domainNameUnicode.includes(domainWithVHost.nameUnicode));
        if (foundWhost !== undefined){
            domainWithVHost.bundleVhostMapId = webSpaces
            .find(webspace => {return webspace.id === foundWhost.webspaceId;})
            .bundleId;
        }
        else {
            domainWithVHost.bundleVhostMapId = undefined;
        }
        return domainWithVHost;

    }

}
