import * as ng from 'angular';

export class MoleculeButtonUnlockController implements ng.IController {
    public static $inject: string[] = [];

    public unlock: () => void;
    public modifier = '';
    public disabled = false;
}

export class MoleculeButtonUnlockComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        disabled: '<',
        modifier: '@?',
        unlock: '<'
    };
    public controller =  MoleculeButtonUnlockController;
    public template = require('./button-unlock.html');
}
