import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { AuthContextService } from '../../../services';
import * as Types from '../../../types';

export class TemplateWebspaceAccessOverviewController {
    public static $inject: string[] = ['$state', '$translate', 'authContext'];

    public pageHeaderData: PanelHeaderData;
    public bundle: Types.BundleApi.Bundle;
    public webspace: Types.WebhostingApi.Webspace;
    public webspaceAccessUsers: Types.WebhostingApi.WebspaceAccess[];
    public webspaceUsers: Types.WebhostingApi.User[];
    public faqArticleIdList: string[] = [
        'webspace-ftp-access',
        'webspace-ftp-folders',
        'webspace-file-directory'
    ];

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit(): void {
        let bundleId: string;
        if (this.bundle !== undefined) {
            bundleId = this.bundle.id;
        } else if (this.webspace !== undefined) {
            bundleId = this.webspace.bundleId;
        }

        let panelHeaderBackwardLink: string;
        let panelHeaderBackwardText: string;
        let panelHeaderRouteParams: {/* */};
        switch (this.$state.current.name.split('.')[0]) {
            case 'bundle':
                panelHeaderBackwardText = this.$translate.instant('TR_090119-a32485_TR');
                panelHeaderBackwardLink = 'bundle.id.dashboard';
                panelHeaderRouteParams = {bundleId: bundleId};
                break;
            case 'managed-servers':
                panelHeaderBackwardLink = 'managed-servers.id.webspaces.id.edit';
                panelHeaderBackwardText = this.$translate.instant('TR_140119-e915b4_TR');
                panelHeaderRouteParams = {
                    machineId: this.$state.$current.locals.globals.machine.id,
                    webspaceId: this.$state.$current.locals.globals.webspace.id
                };
                break;
            default:
            panelHeaderBackwardLink = 'webhosting.webspaces.id.edit';
            panelHeaderBackwardText = this.$translate.instant('TR_140119-e915b4_TR');
            panelHeaderRouteParams = {webspaceId: this.webspace.id};
        }

        this.pageHeaderData = {
            backwardLink: panelHeaderBackwardLink,
            backwardText: panelHeaderBackwardText,
            panelHeaderRoute: '',
            panelHeaderRouteParams: panelHeaderRouteParams,
            panelIcon: 'folder',
            panelTitle: this.$translate.instant('TR_080119-3313e9_TR')
        };
    }
}

export class TemplateWebspaceAccessOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<',
        webspace: '<',
        webspaceAccessUsers: '<',
        webspaceUsers: '<'
    };
    public controller = TemplateWebspaceAccessOverviewController;
    public controllerAs = 'TemplateWebspaceAccessOverviewCtrl';
    public template = require('./access-overview-template.html');
}
