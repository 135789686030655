import ng from 'angular';

import { BreadcrumbTopicModel, TopicCases } from './topic-model';
import { Breadcrumb, BreadcrumbItem } from '@/atomic-components';
import { UiRights } from '@/configuration/rights';

export class BreadcrumbNetworkTopicModel extends BreadcrumbTopicModel {
    public static $inject: string[] = BreadcrumbTopicModel.$inject.concat([]);

    public readonly topicName: string = 'network';
    public readonly specialTopicCases: TopicCases;

    /**
     * List of route parts, which are excluded from main class generalRoutes method
     */
    public readonly excludeGeneralRouteParts = ['edit'];
    public networkActionDropdownItems: BreadcrumbItem[] = [];

    constructor(protected $state: ng.ui.IStateService, protected $translate: ng.translate.ITranslateService) {
        super($state, $translate);

        this.networkActionDropdownItems = [
            { active: false, part: 'dashboard', route: 'network.dashboard', isGranted: UiRights.RES_NETWORK_LIST }
        ];

        this.specialTopicCases = {
            dashboard: this.getDashboardRoute,
            edit: this.getNetworkEditRoutes
        };
    }

    public getDashboardRoute = (): Breadcrumb[] => {
        this.breadcrumbs.push({
            active: true,
            isGranted: UiRights.RES_NETWORK_LIST,
            route: 'network.dashboard',
            title: this.$translate.instant('TR_230420-d570e4_TR'),
            type: 'text',
            value: this.$translate.instant('TR_230420-d570e4_TR')
        });

        return this.breadcrumbs;
    };

    public getFirstLevelData = (): Breadcrumb[] => {
        this.breadcrumbs.push({
            active: false,
            title: this.$translate.instant('071a1517-b504-4288-b3f2-13149d9d5dc8'),
            type: 'text',
            value: this.$translate.instant('071a1517-b504-4288-b3f2-13149d9d5dc8')
        });

        return this.breadcrumbs;
    };

    public getNetworkEditRoutes = (): Breadcrumb[] => {
        let breadcrumbs: Breadcrumb[] = [];
        const route = this.steps.join('.');
        const routeParams = { networkId: this.globals.network.id };

        if (this.steps[this.index - 2] === 'networks' && [undefined].indexOf(this.globals.network) < 0) {
            breadcrumbs = this.getProductActionDropdown(this.networkActionDropdownItems, {}, 'overview');
            breadcrumbs.push({
                active: true,
                isGranted: UiRights.RES_NETWORK_EDIT,
                route: route,
                routeParams: routeParams,
                type: 'text',
                value: this.getActiveDropdownValue({ part: 'edit' })
            });
        }
        return breadcrumbs;
    };
}
