import * as ng from 'angular';
import * as q from 'q';
import {
    ManagedMachineConst,
    UiLanguagesReverseConst,
    VhostDefaultLocationsObjectConst
} from '../../../../../configuration';
import {
    AccountModelService,
    AlertManagerService,
    ApiError,
    AuthContextService,
    DatabaseModelService,
    DatabaseUserModelService,
    ManagedMachineProjectSubaccountTypes,
    VhostModelService,
    WebhostingHelperService,
    WebhostingUserModelService,
    WebspaceModelService
} from '../../../../../services';
import * as Types from '../../../../../types';
import { LinkListItem } from '../../../../molecules';

export interface ManagedMachineProjectObject {
    accountId: string;
    accountType: ManagedMachineProjectSubaccountTypes;
    projectName: string;
    databasePassword: string;
    webspacePassword: string;
    createdObjects: {
        webspace: any;
        databases: any[];
        vhost: any;
    };
    createWebspace: boolean;
    createDatabase: boolean;
    finishedCreating: {
        databaseUsers: number;
        databases: number;
        subaccounts: number;
        vhosts: number;
        webspaceUsers: number;
        webspaces: number;
    };
    errorCreating: {
        databaseUsers: number;
        databases: number;
        subaccounts: number;
        vhosts: number;
        webspaceUsers: number;
        webspaces: number;
    };
    capacity: number;
    domainName: string;
    webspaceAccess: {
        username: string;
    };
    databaseAccess: {
        username: string;
    };
    databaseQty: number;
    newSubAccount: any;
    newDatabaseUser: any;
    newWebspaceUser: any;
}
export class OrganismEditFormMachineManagedProjectNewController {
    public static $inject: string[] = [
        '$translate',
        'accountModel',
        'alertManager',
        'databaseModel',
        'databaseUserModel',
        'vhostModel',
        'webhostingHelper',
        'webhostingUserModel',
        'webspaceModel'
    ];

    public machine: any;
    public managedMachineProject: ManagedMachineProjectObject;
    public ressourceServers: any;
    public showOrderConfirmation = false;
    public projectCreateCompleted = false;
    public accountEmailInUse = false;
    public nextStepsItems: LinkListItem[] = [];

    public webspaceUserPromise: q.IPromise<any>;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService,
        private alertManager: AlertManagerService,
        private databaseModel: DatabaseModelService,
        private databaseUserModel: DatabaseUserModelService,
        private vhostModel: VhostModelService,
        private webhostingHelper: WebhostingHelperService,
        private webhostingUserModel: WebhostingUserModelService,
        private webspaceModel: WebspaceModelService
    ) {
        this.managedMachineProject = {
            accountId: '',
            accountType: null,
            projectName: '',
            databasePassword: '', // tslint:disable-line:object-literal-sort-keys
            webspacePassword: '', // tslint:disable-line:object-literal-sort-keys
            createdObjects: {
                webspace: null,
                databases: [], // tslint:disable-line:object-literal-sort-keys
                vhost: null
            },
            createWebspace: false,
            createDatabase: false,
            finishedCreating: {
                databaseUsers: 0,
                databases: 0,
                subaccounts: 0,
                vhosts: 0,
                webspaceUsers: 0,
                webspaces: 0
            },
            errorCreating: {
                databaseUsers: 0,
                databases: 0,
                subaccounts: 0,
                vhosts: 0,
                webspaceUsers: 0,
                webspaces: 0
            },
            capacity: 1,
            domainName: '',
            webspaceAccess: {
                username: ''
            },
            databaseQty: 0,
            databaseAccess: {
                username: ''
            },
            newSubAccount: {
                billingSettings: {
                    creditLimit: 0,
                    paymentType: 'external',
                    purchaseMarkup: 0,
                    renewalsReportEnabled: false,
                    retailMarkup: 10000
                },
                country: 'de',
                dnsSettings: {
                    defaultTemplateId: ''
                },
                domainSettings: {
                    defaultContactAdminId: '',
                    defaultContactOwnerId: '',
                    defaultContactTechId: '',
                    defaultContactZoneId: '',
                    monthlyPaymentEnabled: false
                },
                emailAddress: '',
                isCommercialCustomer: false,
                language: UiLanguagesReverseConst[this.$translate.use()],
                messageSettings: {
                    eventDeliveryMethods: [],
                    eventDocumentFormat: '',
                    eventHumanReadableEmailAddresses: [],
                    eventMachineReadableEmailAddresses: [],
                    eventWebhookUrl: '',
                    messageTemplateBehavior: 'skipToRoot',
                    receiveSubaccountEvents: false
                },
                name: '',
                parentAccountId: AuthContextService.account.id,
                password: '',
                resellerBillingSettings: {
                    activePaymentMethods: [],
                    availablePaymentMethods: [],
                    endUserCancellationPolicy: '',
                    payPalUser: '',
                    retailMarkup: 10000,
                    showEndUserCancellationPolicyAboveAmount: 0,
                    stripePublicApiKey: ''
                },
                rights: [],
                rightsTemplateId: ''
            },
            newDatabaseUser: {},
            newWebspaceUser: {}
        };
    }

    public backToConfiguration = () => {
        this.showOrderConfirmation = false;
    };

    public get newSubaccount() {
        return this.managedMachineProject.accountType === ManagedMachineProjectSubaccountTypes.NEW;
    }

    public save = () => {
        const allPromises = [];
        let subAccountObject: { service: string; status: null; response: null };
        this.showOrderConfirmation = true;
        this.accountEmailInUse = false;

        const projectPromise = this._setSubaccount()
        .then(
            (subaccount) => {
                subAccountObject = subaccount;
                allPromises.push(subaccount);
                return this._createWebspaceUser(subaccount);
            }
        )
        .then(
            (webspaceUser) => {
                allPromises.push(webspaceUser);
                return this._createWebspace(webspaceUser);
            }
        )
        .then(
            (webspace) => {
                allPromises.push(webspace);
                return this._createVhost(webspace);
            }
        )
        .then(
            (vHost) => {
                allPromises.push(vHost);
                return this._createDatabaseUser(subAccountObject);
            }
        )
        .then(
            (databaseUser) => {
                allPromises.push(databaseUser);
                return this._createDatabases(databaseUser);
            }
        )
        .then(
            (databases) => {
                allPromises.push(databases);
                return allPromises;
            }
        );

        projectPromise.then(
            (promises) => {
                promises.forEach(
                    (promise) => {
                        switch (promise.service) {
                            case 'webspaceUser':
                                break;
                        }
                    }
                );

                this.alertManager.success(
                    this.$translate.instant(
                        /* translationId */ 'TR_210319-fdc30a_TR',
                        { projectName: this.managedMachineProject.projectName }
                    )
                );

                this.projectCreateCompleted = true;
                this._setNextStepItems();
            }
        );
    };

    private _setSubaccount = (): PromiseLike<any> => {
        const response = { service: 'subaccount', status: null, response: null };
        switch (this.managedMachineProject.accountType) {
            case ManagedMachineProjectSubaccountTypes.NEW:
                const user = {
                    emailAddress: this.managedMachineProject.newSubAccount.emailAddress,
                    language: this.managedMachineProject.newSubAccount.language,
                    name: this.managedMachineProject.newSubAccount.name
                };

                return this.accountModel.createSubaccount(
                    this.managedMachineProject.newSubAccount,
                    user,
                    this.managedMachineProject.newSubAccount.password
                )
                .then(
                    (newSubaccount) => {
                        response.status = newSubaccount.status;
                        if (newSubaccount.status !== 'error') {
                            this.managedMachineProject.finishedCreating.subaccounts++;
                            this.managedMachineProject.accountId = newSubaccount.response.id;
                            response.status = newSubaccount.status;
                            response.response = newSubaccount.response;
                        } else {
                            if (newSubaccount.errors.some((error: ApiError) => error.code === 20305)) {
                                this.accountEmailInUse = true;
                            }
                        }

                        return response;
                    }
                );

            case ManagedMachineProjectSubaccountTypes.EXISTEND:
                return this.accountModel.findOne(this.managedMachineProject.accountId)
                .then(
                    (account) => {
                        response.response = account;
                        return response;
                    }
                );

            case ManagedMachineProjectSubaccountTypes.NONE:
                response.response = AuthContextService.account;
                return q.resolve(response);
        }
    };

    private _createWebspaceUser = (account) => {
        const response = { service: 'webspaceUser', status: null, response: null };

        if (this.managedMachineProject.createWebspace) {
            this.managedMachineProject.newWebspaceUser = {
                accountId: this.managedMachineProject.accountId,
                name: account.response.name.replace(' ', '_').toLowerCase(),
                password: this.managedMachineProject.webspacePassword
            };

            return this.webhostingUserModel.createUser(
                {
                    name: this.managedMachineProject.newWebspaceUser.name,
                    accountId: this.managedMachineProject.accountId
                },
                this.managedMachineProject.newWebspaceUser.password
            ).then(
                (user) => {
                    this.managedMachineProject.finishedCreating.webspaceUsers++;
                    response.status = user.status;
                    response.response = user;
                    return response;
                },
                (err) => {
                    this.managedMachineProject.errorCreating.webspaceUsers++;
                    response.status = 'error';
                    response.response = err;
                    return response;
                }
            );
        }

        response.response = false;

        return q.resolve(response);
    };

    private _createWebspace = (user): q.IPromise<{ service: string; status: string; response: any }> => {
        const response = { service: 'webspace', status: null, response: null };
        if ([undefined, null, false].indexOf(user.response) === -1
            && this.managedMachineProject.createWebspace
            && (
                user.status !== 'error'
                || [undefined, null, false].indexOf(user.reponse) < 0
            )
        ) {
            return this.webspaceModel.create(
                {
                    accountId: this.managedMachineProject.accountId,
                    name: this.managedMachineProject.projectName,
                    productCode: ManagedMachineConst.contingentProductCodes.webspace
                },
                [{ ftpAccess: true, userId: user.response.id }],
                AuthContextService.account.id,
                this.ressourceServers.webserver.poolId,
                this.ressourceServers.webserver.id
            ).then(
                (webspaceRes) => {
                    this.managedMachineProject.finishedCreating.webspaces++;
                    this.managedMachineProject.createdObjects.webspace = webspaceRes;

                    response.status = webspaceRes.status as string;
                    response.response = webspaceRes;

                    return response;
                },
                (err) => {
                    this.managedMachineProject.errorCreating.webspaces++;

                    response.status = 'error';
                    response.response = err;

                    return response;
                }
            );
        }

        response.response = false;

        return q.resolve(response);
    };

    private _createVhost = async (webspace: any) => {
        const response = { service: 'vhost', status: null, response: null };
        if ([undefined, null, false].indexOf(webspace.response) === -1
            && this.managedMachineProject.createWebspace
            && this.managedMachineProject.domainName.length > 0
            && (
                webspace.status !== 'error'
                || [undefined, null, false].indexOf(webspace.response) < 0
            )
        ) {
            const vhost = await this.vhostModel.defaultVhostObject();
            vhost.accountId = this.managedMachineProject.accountId;
            vhost.domainName = this.managedMachineProject.domainName;
            vhost.domainNameUnicode = this.managedMachineProject.domainName;
            vhost.locations = VhostDefaultLocationsObjectConst;
            vhost.webspaceId = webspace.response.id;
            vhost.webRoot = this.managedMachineProject.domainName;

            const phpVersion = await this.vhostModel.defaultPhpVersion();

            return this.webhostingHelper.getDefaultPhpIniSet(
                vhost.webspaceId,
                phpVersion,
                false
            )
            .then(
                (phpIniDefaults) => this.vhostModel.create(
                    vhost,
                    phpIniDefaults,
                    '',
                    [],
                    AuthContextService.account.id
                )
            )
            .then(
                (vhostObject) => {
                    this.managedMachineProject.finishedCreating.vhosts++;
                    this.managedMachineProject.createdObjects.vhost = vhostObject;

                    response.status = vhostObject.status;
                    response.response = vhostObject;

                    return response;
                },
                (err) => {
                    this.managedMachineProject.errorCreating.vhosts++;

                    response.status = 'error';
                    response.response = err;

                    return response;
                }
            );
        }

        response.response = false;

        return q.resolve(response);
    };

    private _createDatabaseUser = (account) => {
        const response = { service: 'databaseUser', status: null, response: null };
        if (this.managedMachineProject.createDatabase) {
            this.managedMachineProject.newDatabaseUser = {
                accountId: this.managedMachineProject.accountId,
                name: account.response.name.replace(' ', '_'),
                password: this.managedMachineProject.databasePassword
            };

            return this.databaseUserModel.createUser(
                this.managedMachineProject.newDatabaseUser,
                this.managedMachineProject.newDatabaseUser.password,
                AuthContextService.account.id
            ).then(
                (newDatabaseUser) => {
                    this.managedMachineProject.finishedCreating.databaseUsers++;
                    response.status = newDatabaseUser.status;
                    response.response = newDatabaseUser;
                    return response;
                },
                (err) => {
                    this.managedMachineProject.errorCreating.databaseUsers++;
                    response.status = 'error';
                    response.response = err;
                    return response;
                }
            );
        }

        response.response = false;

        return q.resolve(response);
    };

    private _createDatabases = (databaseUser) => {
        const response = { service: 'database', status: null, response: null };
        if (this.managedMachineProject.createDatabase
            && (
                databaseUser.status !== 'error'
                || [undefined, null, false].indexOf(databaseUser.response) < 0
            )
        ) {
            const databasePromises = [];

            for (let i = 1; i <= this.managedMachineProject.capacity; i++) {
                databasePromises.push(
                    this.databaseModel.create({
                            accesses: [{ accessLevel: ['read', 'write'], userId: databaseUser.response.id }],
                            accountId: this.managedMachineProject.accountId,

                            name: ng.copy(this.managedMachineProject.projectName)
                            + (
                                this.managedMachineProject.databaseQty < 2
                                ? ''
                                : ' (' + i + ')'
                            ),

                            productCode: ManagedMachineConst.contingentProductCodes.database,
                            storageQuota: 0 // set default storage of product (productCode)
                        },
                        null,
                        this.ressourceServers.database.poolId,
                        this.ressourceServers.database.id
                    )
                    .then(
                        (dbRes) => {
                            this.managedMachineProject.finishedCreating.databases++;
                            this.managedMachineProject.createdObjects.databases.push(dbRes);
                            return { service: 'database', status: dbRes.status, response:  dbRes };
                        },
                        (err) => {
                            this.managedMachineProject.errorCreating.databases++;
                            return { service: 'database', status: 'error', response: err };
                        }
                    )
                );
            }

            return q.all(databasePromises)
            .then(
                (allDatabases) => {
                    response.response = allDatabases;
                    return response;
                }
            );
        }

        response.response = false;

        return q.resolve(response);

    };

    private _setNextStepItems = () => {
        if (this.managedMachineProject.createWebspace
            && this.managedMachineProject.finishedCreating.webspaces === 1
        ) {
            // if created webspace
            this.nextStepsItems.push({
                route: 'managed-servers.id.webspaces.id.edit',
                routeParams: {
                    machineId: this.machine.id,
                    webspaceId: this.managedMachineProject.createdObjects.webspace.id
                },
                text: this.$translate.instant('TR_100119-220b0a_TR')
            });
            this.nextStepsItems.push({
                route: 'managed-servers.id.domains.new',
                routeParams: {
                    machineId: this.machine.id,
                    webspaceId: this.managedMachineProject.createdObjects.webspace.id
                },
                text: this.$translate.instant('TR_140119-58b9d3_TR')
            });
        }

        if (this.managedMachineProject.domainName.length > 0
            && this.managedMachineProject.finishedCreating.vhosts === 1
        ) {
            this.nextStepsItems.push({
                // check route and params
                route: 'managed-servers.id.webspaces.id.vhosts.id.app-install',
                routeParams: {
                    machineId: this.machine.id,
                    vHostId: this.managedMachineProject.createdObjects.vhost.id,
                    webspaceId: this.managedMachineProject.createdObjects.webspace.id
                },
                text: this.$translate.instant('TR_110119-cf62e6_TR')
            });
        }

        if (this.managedMachineProject.createDatabase
            && +this.managedMachineProject.finishedCreating.databases === +this.managedMachineProject.capacity
        ) {
            this.managedMachineProject.createdObjects.databases.map(
                (database: Types.DatabaseApi.Database, index: number) => {
                    this.nextStepsItems.push({
                        // check route and params
                        route: 'managed-servers.id.databases.id.edit',
                        routeParams: {
                            databaseId: database.id,
                            machineId: this.machine.id
                        },
                        // tslint:disable-next-line
                        text: (index + 1) + '. ' + this.$translate.instant('TR_210319-b8686e_TR') + ' (' + database.name + ')'
                    });
                }
            );
        }
    };
}

export class OrganismEditFormMachineManagedProjectNewComponent implements ng.IComponentOptions {
    public bindings = {
        machine: '<',
        ressourceServers: '<'
    };
    public controller = OrganismEditFormMachineManagedProjectNewController;
    public template = require('./machine-managed-project-new.html');
}
