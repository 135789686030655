import * as ng from 'angular';
import * as q from 'q';

import {
    ContractPeriodeToBillingCycleConst,
    CycleBillingTranslationConst,
    MailboxPoolTypes,
    PoolMailboxProductCodeProductFamilyMapping,
    PossiblePoolMailboxProductFamilies,
    UiLanguagesConst,
    UiRights
} from '../../../configuration';
import { CalculatePriceFilter } from '../../../filters';
import {
    AuthContextService,
    MachineModelService,
    PriceHelperService,
    ResourceModelService
} from '../../../services';
import * as Types from '../../../types';
import { WizardObject } from './wizard-supervisor/wizard-supervisor';

export class WizardHelperService {
    public static $inject: string[] = [
        '$translate',
        'calculatePriceFilter',
        'machineModel',
        'priceHelper',
        'resourceModel'
    ];

    private _serviceObject: WizardObject;
    private _language: null|string = null;
    private _productType;
    private _inPoolCache: any[] = [];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private calculatePriceFilter: CalculatePriceFilter,
        private machineModel: MachineModelService,
        private priceHelper: PriceHelperService,
        private resourceModel: ResourceModelService
    ) {}

    public inContingent = (serviceObject) => {
        this._serviceObject = serviceObject;

        return [undefined, null].indexOf(serviceObject.settings) === -1
            && [undefined, null].indexOf(serviceObject.settings.selectedContingent) === -1;
    };

    public getContingentType = (serviceObject) => {
        this._serviceObject = serviceObject;

        if ([undefined, null].indexOf(serviceObject.display) >= 0
            || [undefined, null, []].indexOf(serviceObject.display.availableContingentItems) >= 0
            || [undefined, null].indexOf(serviceObject.settings) >= 0
            || [undefined, null].indexOf(serviceObject.settings.selectedContingent) >= 0
        ) {
            return null;
        }

        return this._getContingentObject(serviceObject).type;
    };

    public resetSelectedProductObject = (serviceObject) => {
        this._serviceObject = serviceObject;

        if ([undefined, null, false].indexOf(serviceObject.settings.comesFromSummaryPage) >= 0
            && [undefined, null, false].indexOf(serviceObject.settings.doNotResetSelectedProduct) >= 0
        ) {
            serviceObject.selectedProduct = {
                family: null,
                name: null,
                prices: null,
                selectedPrice: null,
                shortDescription: null,
                specificationItems: null
            };
        }

        return serviceObject.selectedProduct;
    };

    public setSelectedProductObject = (serviceObject, item) => {
        this._serviceObject = serviceObject;

        serviceObject.selectedProduct = {
            description: item.description,
            factSheetItems: item.factSheetItems,
            family: item.family,
            keywordsDescriptions: item.keywordsDescriptions,
            name: item.name,
            prices: item.prices,
            selectedPrice: ([undefined, null].indexOf(item.prices) < 0 && item.prices.length === 1)
                ? item.prices[0]
                : null,
            shortDescription: item.shortDescription,
            specificationItems: item.specificationItems
        };

        return serviceObject.selectedProduct;
    };

    public getContingentIds = (serviceObject) => {
        this._serviceObject = serviceObject;
        const object = this._getContingentObject(serviceObject);

        if ([undefined, null].indexOf(object) >= 0) {
            return undefined;
        }

        return {
            contingentId: object.value,
            resourceId: object.resourceId,
            type: object.type
        };
    };

    public getBillingCycleTranslation = (cycle) => {
        return this.$translate.instant(
            CycleBillingTranslationConst[
                (typeof cycle === 'number') ? ContractPeriodeToBillingCycleConst[cycle] : cycle
            ]
        );
    };

    public getLanguage = () => {
        if (this._language !== null) {
            return this._language;
        }

        this._language = UiLanguagesConst[AuthContextService.user.language];

        return this._language;
    };

    public inPool = async (
        serviceObject?,
        accountId?: string,
        productCode?: string
    ): Promise<any> => {
        accountId = accountId || AuthContextService.account.id;

        if (+AuthContextService.account.id < 999) {
            return [];
        }

        this._serviceObject = serviceObject || this._serviceObject;

        if (this._serviceObject === undefined) {
            return [];
        }

        // Try to resolve from cache
        const fromCache = this._isInPoolCache(AuthContextService.account.id);

        if (fromCache !== null) {
            return fromCache;
        }

        // Resolve to empty if user lacks permissions
        if (!AuthContextService.isGranted(UiRights.RES_POOL_LIST)) {
            return [];
        }

        let pools = [];
        const filters = { field: 'accountId', value: this._serviceObject.settings.accountId };

        const poolData = await this.resourceModel.poolsList(
            undefined,
            undefined,
            filters,
            undefined,
            undefined,
            this._serviceObject.settings.ownAccountId
        );

        const availablePools = [];
        if (this._productType === undefined
            || productCode !== undefined
        ) {
            this.setProductType(undefined, productCode);
        }

        poolData.data.map(
            (pool) => {
                pool.defaultPool
                ? availablePools.splice(0, 0, pool) // defaultPool as first in list
                : availablePools.push(pool);
            }
        );

        if (MailboxPoolTypes.indexOf(this._productType) >= 0) {
            return availablePools.filter(this._getEmailResources);
        }

        pools = availablePools.filter((pool) => {
            return pool.availableResources.includes(this._productType);
        });

        // No pools available, resolve to empty
        if ([undefined, null, 0].indexOf(pools.length) >= 0) {
            return [];
        }

        const results = await q.all(
            pools.map(
                async (pool) => {
                    const result = await this.resourceModel.resourceList(pool.id, this._productType);

                    return result.data.map(
                        (resource) => {
                            resource.poolName = pool.name;

                            return resource;
                        }
                    );
                }
            )
        );

        const tmpResources = results
        .reduce((a, b) => a.concat(b))
        .filter((resource) => resource.resourceType === this._productType);

        const machines = await this.machineModel.findById(tmpResources.map((resource) => resource.virtualMachineId));

        const poolsRes = tmpResources.map(
            (resource) => {
                const machineCandidates = machines.filter(
                    (machine) => machine.id === resource.virtualMachineId
                );

                resource.virtualMachineName = (machineCandidates?.length === 1)
                    ? machineCandidates.pop().name
                    : '[VM not found]';

                return resource;
            }
        );

        this._inPoolCache.push({
            accountId: this._serviceObject.settings.accountId,
            pools: poolsRes,
            resourceType: this._productType
        });

        return poolsRes;
    };

    public poolHasContingents = (pool: Types.ResourceApi.Pool, productCode: string) => {
        this.setProductType(undefined, productCode);

        if (MailboxPoolTypes.indexOf(this._productType) >= 0) {
            return this._getEmailResources(pool);
        }

        return [undefined, null, ''].indexOf(this._productType) >= 0
            ? false
            : pool.availableResources.indexOf(this._productType) >= 0;
    };

    public setProductType = (serviceObject?, productCode?: string) => {
        serviceObject = serviceObject || this._serviceObject;

        switch (serviceObject.service.toLowerCase()) {
            case 'database':
                this._productType = 'Database';
                break;
            case 'webhosting':
                this._productType = 'Webserver';
                break;
            case 'email':
                this._productType = this._getMailboxType(undefined, productCode);
                break;
            default:
            // NOTHING TO DO HERE
        }
    };

    public getFactsheetLabelTranslation = (elementShown, raw?) => {
        raw = raw || false;

        [
            {
                name: this.$translate.instant('0bbaab10-cf54-4d19-84bb-261d877e6605'),
                replacement: this.$translate.instant('TR_STORAGE_TR')
            },
            {
                name: this.$translate.instant('TR_090119-f9c047_TR'),
                replacement: this.$translate.instant('TR_090119-2d59db_TR')
            },
            {
                name: this.$translate.instant('TR_090119-06de18_TR'),
                replacement: this.$translate.instant('TR_090119-eee13d_TR')
            }
        ]
        .forEach(
            (evil) => {
                if (elementShown.name === evil.name) {
                    elementShown.name = evil.replacement;
                }
            }
        );

        return raw ? elementShown : elementShown.name;
    };

    public getFactValueTranslation = (factDetail, property) => {
        switch (factDetail.type) {
            case 'bool':
                return property.value === '1';
            case 'mb':
            case 'gb':
                return property.value + ' ' + factDetail.type.toUpperCase();
            case 'string':
                return property.value;
            case 'sec':
                return property.value + ' sec';
            case 'day':
                return property.value + ' ' + this.$translate.instant('c9d4d439-d6be-4eda-93bb-66eb041b46be');
            case 'days':
                return property.value + ' ' + this.$translate.instant('db2881be-7da3-44c4-8b24-e7d353391e82');
            default:
                return property.value;
        }
    };

    public getFactsheetTranslatedDescription = (keywordsDescriptions, property) => {
        const keywordDescription = keywordsDescriptions.filter(
            (description) => description.category === property.category && description.keyword === property.keyword
        );

        return keywordDescription.length > 0 ? keywordDescription[0] : property;
    };

    // Bereits in den services/helpers/wizard-helpers Service umgezogen
    public calculateProductPrice(priceList, account?: Types.AccountApi.Account, productCode?: string) {
        let prices = [];

        if (productCode !== undefined) {
            prices = priceList.filter((priceObject) => {
                return priceObject.productCode === productCode;
            });
        } else {
            prices = priceList;
        }

        if (prices[0].price === null || prices[0].price.price === -1) {
            return;
        }

        const taxType = [undefined, null].indexOf(account) < 0
            && AuthContextService.account.id !== account.id
                ? account.isCommercialCustomer ? 'net' : 'gross'
                : AuthContextService.account.isCommercialCustomer ? 'net' : 'gross';

        if (prices[0].billingCycle === 1) {
            const largestBillingCycle = prices[prices.length - 1];
            if (largestBillingCycle.billingCycle === null || largestBillingCycle.billingCycle === undefined) {
                return this.calculatePriceFilter(
                    largestBillingCycle.price.price,
                    largestBillingCycle.price.vatRate,
                    1,
                    taxType,
                    undefined,
                    false,
                    prices[0].productCode ? prices[0].productCode : 'unknown',
                    prices[0].billingCycle
                );
            } else {
                return this.calculatePriceFilter(
                    (largestBillingCycle.price.price / largestBillingCycle.billingCycle),
                    largestBillingCycle.price.vatRate,
                    1,
                    taxType,
                    undefined,
                    false,
                    prices[0].productCode ? prices[0].productCode : 'unknown',
                    prices[0].billingCycle
                );
            }
        } else {
            return this.calculatePriceFilter(
                prices[0].price.price,
                prices[0].price.vatRate,
                1,
                taxType,
                undefined,
                false,
                prices[0].productCode ? prices[0].productCode : 'unknown',
                prices[0].billingCycle
            );
        }
    }

    public getProductCurrency(price) {
        return this.priceHelper.getProductCurrency(price);
    }

    /**
     *  SUMMARY PAGE HELPER
    **/
    public getTotalPrice = (
        serviceObject: WizardObject,
        showInEur?: boolean
    ): {
        currency: string;
        exchangeRatio: number;
        totalPrice: number;
        totalPriceInEuro;
    }[] => {
        showInEur = showInEur || false;
        const taxType = AuthContextService.account.isCommercialCustomer ? 'net' : 'gross';
        let vatRate: number;
        const total: {
            [currency: string]: {
                exchangeRatio: number;
                price: number;
                totalPrice: number;
                totalPriceInEuro: number;
            };
        } = {};

        if ([undefined, null].indexOf(serviceObject.selectedProduct) < 0) {
            if (
                [undefined, null].indexOf(serviceObject?.selectedProduct?.selectedPrice?.price) < 0
                && serviceObject.selectedProduct.selectedPrice.price.price !== -1
            ) {
                const price = serviceObject.selectedProduct.selectedPrice.price;

                vatRate = price.vatRate;

                const productPriceObj = {
                    exchangeRatio: price.exchangeRatio?.exchangeRatio,
                    price: 0,
                    totalPrice: 0,
                    totalPriceInEuro: 0
                };

                if ([undefined, null].indexOf(price.promotionalPrice) < 0) { // tslint:disable-line
                    productPriceObj.price = price.promotionalPrice;
                } else {
                    if ([undefined, null, false].indexOf(serviceObject.settings.selectedContingent) >= 0) {
                        productPriceObj.price = price.price;
                    }
                }

                productPriceObj.totalPrice = this.calculatePriceFilter(
                    productPriceObj.price,
                    vatRate,
                    1,
                    taxType,
                    showInEur ? productPriceObj.exchangeRatio : null,
                    false,
                    serviceObject.selectedProduct.selectedPrice?.productCode,
                    serviceObject.selectedProduct.selectedPrice?.billingCycle
                );

                productPriceObj.totalPriceInEuro = [undefined, null].indexOf(productPriceObj.exchangeRatio) < 0
                    ? this.calculatePriceFilter(
                        productPriceObj.price,
                        vatRate,
                        1,
                        taxType,
                        productPriceObj.exchangeRatio,
                        false,
                        serviceObject.selectedProduct.selectedPrice.productCode,
                        serviceObject.selectedProduct.selectedPrice.billingCycle
                    )
                    : 0;

                total[price.currency] = productPriceObj;
            }
        }

        if ([undefined, null].indexOf(serviceObject.children) < 0) {
            for (const child of serviceObject.children) {
                if ([undefined, null].indexOf(child.price) >= 0
                    || child.price.price === -1
                    || child.inBundle
                ) {
                    return;
                }

                const price = child.price;

                if ([undefined, null].indexOf(total[price.currency]) >= 0) {
                    total[price.currency] = {
                        exchangeRatio: price.exchangeRatio?.exchangeRatio,
                        price: 0,
                        totalPrice: 0,
                        totalPriceInEuro: 0
                    };
                }

                let netPrice = price.price;

                if ([undefined, null].indexOf(price.promotionalPrice) < 0) {
                    netPrice = price.promotionalPrice;
                }

                total[price.currency].price += netPrice * child.amount;

                total[price.currency].totalPrice += this.calculatePriceFilter(
                    netPrice,
                    vatRate,
                    child.amount,
                    taxType,
                    showInEur ? total[price.currency].exchangeRatio : null,
                    false,
                    serviceObject.selectedProduct?.selectedPrice?.productCode,
                    serviceObject.selectedProduct?.selectedPrice?.billingCycle
                );

                total[price.currency].totalPriceInEuro
                    += [undefined, null].indexOf(total[price.currency].exchangeRatio) < 0
                    ? this.calculatePriceFilter(
                        netPrice,
                        vatRate,
                        child.amount,
                        taxType,
                        total[price.currency].exchangeRatio,
                        false,
                        serviceObject.selectedProduct.selectedPrice.productCode,
                        serviceObject.selectedProduct.selectedPrice.billingCycle
                    )
                    : 0;
            }
        }

        return Object.keys(total).map(
            (key) => {
                return {
                    currency: key,
                    exchangeRatio: total[key].exchangeRatio,
                    totalPrice: total[key].totalPrice,
                    totalPriceInEuro: total[key].totalPriceInEuro
                };
            }
        );
    };
    /** Eof  Sammary page helper **/

    private _isInPoolCache = (accountId) => {
        let cached = null;

        if ([undefined, null].indexOf(this._serviceObject) >= 0) {
            return cached;
        }

        this._inPoolCache.some(
            (poolCache) => {
                if (poolCache.accountId !== this._serviceObject.settings.accountId) {
                    return false;
                }

                if (poolCache.resourceType !== this._productType) {
                    return false;
                }

                cached = poolCache.pools;

                return true;
            }
        );

        return cached;
    };

    private _getEmailResources = (pool) => {
        const mailboxLimit = this._productType + 'Limit';
        const mailboxAllocated = this._productType + 'Allocated';

        return pool[mailboxLimit] === -1
            || (pool[mailboxLimit] - pool[mailboxAllocated]) > 0;
    };

    private _getMailboxType = (serviceObject?: any, productCode?: string) => {
        serviceObject = serviceObject || this._serviceObject;
        const poolContingentProperty = this._getPoolContingentPropertyFromProductCodeProductFamilieMapping(productCode);

        for (const type of PossiblePoolMailboxProductFamilies) {
            if (poolContingentProperty === type.serviceType) {
                return type.serviceType;
            }
        }

        return '';
    };

    private _getPoolContingentPropertyFromProductCodeProductFamilieMapping = (productCode: string) => {
        if ([undefined, null, ''].indexOf(productCode) < 0) {
            for (const mappingObject of PoolMailboxProductCodeProductFamilyMapping) {
                if (productCode.indexOf(mappingObject.productCode) >= 0) {
                    return mappingObject.poolProperty;
                }
            }
        }

        return undefined;
    };

    private _getContingentObject = (serviceObject?) => {
        serviceObject = serviceObject || this._serviceObject;

        if ([undefined, null, false].indexOf(serviceObject.display.inMachine) < 0) {
            return serviceObject.display.contingent;
        }

        return serviceObject.display.availableContingentItems.filter((contingentItem) => {
            return contingentItem.value === serviceObject.settings.selectedContingent;
        })[0];
    };
}
