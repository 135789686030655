import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import { AuthContextService } from '../../../../../services';
import { OrganismOverviewController, OverviewDataObject } from '../../../../organisms/overview/overview';

export class MoleculeOverviewRowPanelDatabaseController implements ng.IController {
    public static $inject: string[] = ['$state'];

    public userHasRightBilBundleList = AuthContextService.isGranted(UiRights.BIL_BUNDLE_LIST); // used in template!
    public overviewData: OverviewDataObject;
    public OrganismOverview: OrganismOverviewController;
    public overviewLoad: (selection: any) => any;
    public internalPaginationLimit: number;
    public sideEntryPoint: string;
    public stateGlobals: any;

    constructor(
        public $state: ng.ui.IStateService
    ) {}

    public $onInit() {
        this.sideEntryPoint = this.$state.current.name.split('.')[0];
        this.stateGlobals = this.$state.$current.locals.globals;
    }

    public get showAccounts() {
        return this.OrganismOverview.globals.showAccounts;
    }

    public get isBundleContext() {
        return [undefined, null].indexOf(this.OrganismOverview.bundle) < 0;
    }
}

export class MoleculeOverviewRowPanelDatabaseComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelDatabaseController;
    public controllerAs = 'OverviewDatabaseCtrl';
    public template = require('./database-row.html');
}
