import * as ng from 'angular';
import { AlertManagerService, UserModelService } from '../../../../../services';
import {
    MoleculeFormEditController,
    PanelType
} from '../../../../molecules/forms/form-edit/form-edit';

export class OrganEditPanelUserLoginController implements ng.IController {
    public static $inject: string[] = ['alertManager', 'userModel', '$translate'];

    public $editForm: MoleculeFormEditController;
    public panelRightSettings: any;
    public cancelCallbackFunction: () => void;
    public password: string;
    public panelType: PanelType = PanelType.MISCELLANEOUS_ACTION;
    public user;

    constructor(
        private alertManager: AlertManagerService,
        private userModel: UserModelService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public changeOwnEmail = () => {
        return this.userModel.userChangeEmailAddress(this.user.id, this.user.emailAddress, this.password).then(
            (res) => {
                this.alertManager.success(this.$translate.instant('TR_140119-1748f0_TR'));
                return res;
            }
        );
    };
}

export class OrganEditPanelUserLoginComponent implements ng.IComponentOptions {
    public bindings = {
        user: '<',
        panelRightSettings: '<',
        validationInstructions: '<'
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public template = require('./edit-panel-user-login.html');
    public controller = OrganEditPanelUserLoginController;
}
