import ng from 'angular';

import { FormInputModelOptions } from '@/atomic-components';
import {
    DefaultPasswordGenerator,
    PasswordGenerator,
    MoleculePanelEditController,
    MoleculePanelEditTableController,
    MoleculeFormEditController,
} from '@/atomic-components/molecules';
import { ViewTypes } from '@/types';

import './panel-edit-row-password.scss';

export class MoleculePanelEditRowPasswordController implements ng.IController {
    public static $inject: string[] = ['$translate', '$transclude', '$timeout', '$scope'];

    public disabled: boolean;
    public name: string;
    public model: string;
    public modelOptions: FormInputModelOptions;
    public _password = '';
    public _passwordRepeat = '';
    public loadPasswortPhrase: boolean;
    public $editPanel: MoleculePanelEditController | MoleculePanelEditTableController;
    public validationOptions: ViewTypes.PasswordOptions;
    public doNotSaveOnFormEnter: boolean;
    public repeatPassword: boolean;
    public validationErrorListRepeat: string[] = [];
    public validationErrorListPassword: string[] = [];
    public allowSimplePasswords = false;
    public ddnsPassword = false;
    public nextcloudPassword = false;
    public labelLeft = false;
    public registerToForm = false;
    public registrationIndex?: number;

    private _generatedPassword: string;
    private $editPanelStd: MoleculePanelEditController;
    private $editPanelTable: MoleculePanelEditTableController;
    private $editForm: MoleculeFormEditController
    private callbackOnChange: () => void;

    private passwordGenerator: PasswordGenerator<any>;

    constructor(
        public $translate: ng.translate.ITranslateService,
        protected $transclude: unknown,
        private $timeout: ng.ITimeoutService,
        private $scope: ng.IScope
    ) {}

    public $onInit = (): void => {
        this.repeatPassword = this.repeatPassword === undefined ? true : this.repeatPassword;
        this.validationErrorListRepeat = [];
        this.validationErrorListPassword = [];
        this.passwordGenerator = new DefaultPasswordGenerator(
            this.$translate,
            this.allowSimplePasswords || this.ddnsPassword
        );
        this.loadPasswortPhrase = false;
        this.$editPanel = this.$editPanelStd || this.$editPanelTable;
        this.password = this.model;

        if (this.registerToForm) {
            this.registrationIndex = this.$editForm.registerValidator(this as any);
            this.$scope.$watch('$panelRow.isValid', () => {
                this.$editForm.validate(this.registrationIndex);
            });
        }
    };

    public $onDestroy() {
        if (this.registerToForm) {
            this.$editForm.unregisterValidator(this.registrationIndex);
            this.$editForm.validatorStatus[this.registrationIndex] = true;
        }
    }

    public get passwordRepeat(): string {
        return this._passwordRepeat;
    }

    public set passwordRepeat(value: string) {
        this._passwordRepeat = value;

        if (!this.passwordRepeat) {
            this.model = this._password;
        } else {
            this.model = this._password === this._passwordRepeat ? this._password : '';
        }

        if (this.callbackOnChange) {
            void this.$timeout(() => this.callbackOnChange());
        }
    }

    public get generatedPassword(): string {
        return this._generatedPassword;
    }

    public get password(): string {
        return this._password;
    }

    public set password(newPassword: string) {
        this._password = newPassword;

        if (!this.passwordRepeat) {
            this.model = this._password;
        } else {
            this.model = this._password === this._passwordRepeat ? this._password : '';
        }

        if (this.callbackOnChange) {
            void this.$timeout(() => this.callbackOnChange());
        }
    }

    public get isPasswordValid(): boolean {
        return [undefined, ''].indexOf(this._password) >= 0 || true;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set validationErrorList({}) {}
    public get validationErrorList(): string[] {
        return ng.copy(this.validationErrorListPassword).concat(this.validationErrorListRepeat);
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set isValid({}) {}
    public get isValid(): boolean {
        if (this.validationErrorList.length === 0 && !this.repeatPassword) {
            return true;
        }
        return this.validationErrorList.length === 0 && this._password === this._passwordRepeat;
    }

    public get isPasswordRepeatValid(): boolean {
        return this._passwordRepeat === this._password || this._passwordRepeat === '';
    }

    public validate = (): boolean => {
        return this.isValid;
    }

    public generate = (): void => {
        const generatedPassword = this.passwordGenerator.generate();
        this.loadPasswortPhrase = true;
        this._passwordRepeat = '';
        this._generatedPassword = generatedPassword;
        this._password = !this.repeatPassword && this.isValid
            ? generatedPassword
            : '';
        this.model = !this.repeatPassword && this.isValid
            ? generatedPassword
            : '';
    };
}

export class MoleculePanelEditRowPasswordComponent implements ng.IComponentOptions {
    public transclude = {
        labelNew: '?rowLabelNew',
        labelRepeat: '?rowLabelRepeat'
    };
    public require = {
        $editPanelStd: '?^moleculePanelEdit',
        $editPanelTable: '?^moleculePanelEditTable',
        $editForm: '?^moleculeFormEdit',
    };
    public bindings = {
        allowSimplePasswords: '<?',
        callbackOnChange: '<',
        ddnsPassword: '<?',
        disabled: '<',
        doNotSaveOnFormEnter: '<',
        isValid: '=?',
        labelLeft: '<?',
        model: '=',
        modelOptions: '<?',
        name: '@',
        nextcloudPassword: '<?',
        registerToForm: '<?',
        repeatPassword: '<?',
        validationOptions: '<?'
    };
    public controller = MoleculePanelEditRowPasswordController;
    public controllerAs = '$panelRow';
    public template = require('./panel-edit-row-password.html');
}
