import ng from 'angular';
import './link-list.scss';

export interface LinkListItem {
    disabled?: boolean;
    group?: string;
    groupName?: string;
    icon?: string;
    isClickable?: boolean | string;
    isGranted?: boolean;
    isSetLogoutAs?: boolean;
    onclickMethode?: string | void | ((item: any) => any); // This can be a reference to a method name (string) or a callback (method) function
    reloadState?: boolean;
    route?: string;
    routeParams?: { [key: string]: unknown };
    target?: string;
    text: string;
    type?: string;
    url?: string;
}

/**
 * Link could be:
 * - route (with or without route params)
 * - url
 * - onclickMethode
 *   - as reference to a method name (string - (ex. mass actions))
 *   - as method reference
 */

export class MoleculeLinkListController implements ng.IController {
    public static $inject: string[] = [];

    public linkList: LinkListItem[] = [];
    public linkListFullWidth: boolean;
    public flexDirection = '';
    public showListHorizontal: boolean;
    public mediumSpaceBottom = false;
    public smallSpaceBottom = false;

    public $onInit(): void {
        this.flexDirection = this.flexDirection || '';
        this.linkListFullWidth = this.linkListFullWidth || false;
        this.mediumSpaceBottom = this.mediumSpaceBottom || false;
        this.showListHorizontal = this.showListHorizontal || false;
        this.smallSpaceBottom = this.smallSpaceBottom || false;

        if (this.linkList !== undefined) {
            this.linkList.map((link) => {
                link.icon = link.icon || 'chevron-right';
                return link;
            });
        }
    }
}

export class MoleculeLinkListComponent implements ng.IComponentOptions {
    public bindings = {
        flexDirection: '@',
        linkList: '<',
        linkListFullWidth: '<?',
        mediumSpaceBottom: '<?',
        showListHorizontal: '<',
        smallSpaceBottom: '<?'
    };
    public controller =  MoleculeLinkListController;
    public template = require('./link-list.html');
}
