export interface SpamProfile {
    custom: boolean;
    desc: string;
    label: string;
    name: string;
    values: {
        bannedFilesChecks: boolean;
        deleteSpam: boolean;
        headerChecks: boolean;
        malwareChecks: boolean;
        modifySubjectOnSpam: boolean;
        spamChecks: boolean;
        spamLevel: 'low' | 'medium' | 'high';
        useGreylisting: boolean;
    };
}

/* tslint:disable:max-line-length */

export const SpamProfiles: SpamProfile[] = [
    {
        custom: true,
        desc: '',
        label: /* translationId */ 'TR_070619-8ee158_TR',
        name: 'individual',
        values: {
            bannedFilesChecks: false,
            deleteSpam: true,
            headerChecks: true,
            malwareChecks: true,
            modifySubjectOnSpam: true,
            spamChecks: true,
            spamLevel: 'high',
            useGreylisting: true
        }
    },
    {
        custom: false,
        desc: /* translationId */ 'TR_070619-f5c67f_TR',
        label: /* translationId */ 'EMAIL.SPAM.LEVEL.LOW',
        name: 'weak',
        values: {
            bannedFilesChecks: false,
            deleteSpam: false,
            headerChecks: false,
            malwareChecks: false,
            modifySubjectOnSpam: true,
            spamChecks: true,
            spamLevel: 'low',
            useGreylisting: false
        }
    },
    {
        custom: false,
        desc: /* translationId */ 'TR_070619-0a68fa_TR',
        label: /* translationId */ 'EMAIL.SPAM.LEVEL.MEDIUM',
        name: 'middle',
        values: {
            bannedFilesChecks: false,
            deleteSpam: false,
            headerChecks: true,
            malwareChecks: true,
            modifySubjectOnSpam: true,
            spamChecks: true,
            spamLevel: 'medium',
            useGreylisting: true
        }
    },
    {
        custom: false,
        desc: /* translationId */ 'TR_070619-382bca_TR',
        label: /* translationId */ 'EMAIL.SPAM.LEVEL.HIGH',
        name: 'strong',
        values: {
            bannedFilesChecks: false,
            deleteSpam: true,
            headerChecks: true,
            malwareChecks: true,
            modifySubjectOnSpam: true,
            spamChecks: true,
            spamLevel: 'high',
            useGreylisting: true
        }
    }
];
