import * as ng from 'angular';

import { UiRights } from '@/configuration';

import {
    ApiErrorModel, AuthContextService, OrchestratorRobotService, WebhostingJobModelService
} from '../../../../../services/';
import { DnsJobModelService } from '../../../../../services/dns/dns-job-model';
import { ErrorMapperService } from '../../../../../services/error-mapper';
import { WizardConfirmationBase } from '../../general/confirm';

export class RedirectionWizardConfirmation extends WizardConfirmationBase {
    public static $inject: string[] = WizardConfirmationBase.$inject.concat(['dnsJobModel', 'webhostingJobModel']);

    constructor(
        protected $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        protected apiErrorModel: ApiErrorModel,
        protected errorMapper: ErrorMapperService,
        protected localInterval: ng.IIntervalService,
        protected orchestratorRobot: OrchestratorRobotService,
        private dnsJobModel: DnsJobModelService,
        private webhostingJobModel: WebhostingJobModelService
    ) {
        super(
            $timeout,
            $translate,
            apiErrorModel,
            errorMapper,
            localInterval,
            orchestratorRobot
        );

        if (!AuthContextService.isGranted(UiRights.WEB_JOBS_LIST)) {
            this.isJobDependendWizard = false;
        }

        if (AuthContextService.isGranted(UiRights.DOM_DOMAINS_LIST)) {
            this.fillNextSteps = () => {
                this.nextSteps.push({
                    iconName: 'globe',
                    linkText: this.$translate.instant('TR_110119-4f9658_TR'),
                    route: 'domain.redirections.overview',
                    routeOptions: { reload: true },
                    routeParams: {}
                });
            };
        }
    }

    public getJobByFilter = (filter, cacheTimeout, objectType?: string) => {
        switch (objectType) {
            case 'Zone':
                return this.dnsJobModel.list(null, null, filter)
                    .then(this.normalizeResponseObject);
            case 'Redirection':
                return this.webhostingJobModel.list(null, null, filter, null)
                    .then(this.normalizeResponseObject);
            default:
                return undefined;
        }
    };
}
