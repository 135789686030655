// ohne die direktive wird bei dem input type=file das ng-model nicht mit der ausgewählten datei befüllt

import ng from 'angular';

/*
 * Map files property of input type file to ng-model directive if file selection changed
 */
export class FileModelDirective {
    public restrict = 'A';
    public require = 'ngModel';

    public static Factory(): any {
        const directive = () => new FileModelDirective();

        directive.$inject = [] as string[];

        return directive;
    }

    public link = (_scope, elem, _attrs, ngModel: ng.INgModelController): void => {
        elem.on('change', () => {
            const file = (elem[0] ).files;
            ngModel.$setViewValue(undefined);
            ngModel.$setViewValue(file);
        });
    };
}
