import ng from 'angular';
import './breadcrumb.scss';

import { NavigationService } from '../../../services';
import {
    BreadcrumbTopicModel,

    BreadcrumbAccountTopicModel,
    BreadcrumbBillingTopicModel,
    BreadcrumbBundleTopicModel,
    BreadcrumbDashboardTopicModel,
    BreadcrumbDatabaseTopicModel,
    BreadcrumbDnsTopicModel,
    BreadcrumbDomainTopicModel,
    BreadcrumbErrorTopicModel,
    BreadcrumbHelpdeskTopicModel,
    BreadcrumbMachineTopicModel,
    BreadcrumbMailboxTopicModel,
    BreadcrumbManagedMachineTopicModel,
    BreadcrumbNetworkTopicModel,
    BreadcrumbNextcloudTopicModel,
    BreadcrumbProfileTopicModel,
    BreadcrumbResellerTopicModel,
    BreadcrumbSslTopicModel,
    BreadcrumbWebhostingTopicModel,
    BreadcrumbStorageTopicModel
} from './';

export interface BreadcrumbItem {
    active?: boolean;
    disabled?: boolean;
    isGranted?: string;
    isGrantedAll?: string[];
    isGrantedAny?: string[];
    name?: string;
    part?: string;
    route: string;
    routeParams?: { [key: string]: unknown };
    step?: string;
    title?: string;
    translationId?: string;
}

export interface Breadcrumb {
    active?: boolean;
    isGranted?: string;
    isGrantedAll?: string[];
    isGrantedAny?: string[];
    items?: BreadcrumbItem[];
    part?: string;
    route?: string;
    routeParams?: { [key: string]: unknown };
    title?: string;
    type: 'text' | 'dropdown' | string;
    value: string;
}

export class MoleculeBreadcrumbController implements ng.IController {
    public static $inject: string[] = [
        '$state',
        'breadcrumbTopicModel',
        'breadcrumbAccountTopicModel',
        'breadcrumbBillingTopicModel',
        'breadcrumbBundleTopicModel',
        'breadcrumbDashboardTopicModel',
        'breadcrumbDatabaseTopicModel',
        'breadcrumbDnsTopicModel',
        'breadcrumbDomainTopicModel',
        'breadcrumbErrorTopicModel',
        'breadcrumbHelpdeskTopicModel',
        'breadcrumbMachineTopicModel',
        'breadcrumbMailboxTopicModel',
        'breadcrumbManagedMachineTopicModel',
        'breadcrumbNetworkTopicModel',
        'breadcrumbNextcloudTopicModel',
        'breadcrumbProfileTopicModel',
        'breadcrumbResellerTopicModel',
        'breadcrumbSslTopicModel',
        'breadcrumbStorageTopicModel',
        'breadcrumbWebhostingTopicModel',
        'navigation'
    ];

    public breadcrumbs: Breadcrumb[] = [];

    private lastState = '';
    private lastBreadcrumbs: Breadcrumb[] = [];
    private routeParts: string[];

    private topic = '';

    private topicModel;

    private regularAreas = [
        'dashboard',
        'overview',
        'jobs'
    ];

    private regularActions = [
        'edit',
        'delete',
        'restore',
        'terminate'
    ];

    private topicModels = {
        'account': this.breadcrumbAccountTopicModel,
        'billing': this.breadcrumbBillingTopicModel,
        'bundle': this.breadcrumbBundleTopicModel,
        'dashboard': this.breadcrumbDashboardTopicModel,
        'database': this.breadcrumbDatabaseTopicModel,
        'dns': this.breadcrumbDnsTopicModel,
        'domain': this.breadcrumbDomainTopicModel,
        'email': this.breadcrumbMailboxTopicModel,
        'helpdesk': this.breadcrumbHelpdeskTopicModel,
        'machine': this.breadcrumbMachineTopicModel,
        'managed-servers': this.breadcrumbManagedMachineTopicModel,
        'network': this.breadcrumbNetworkTopicModel,
        'nextcloud': this.breadcrumbNextcloudTopicModel,
        'pageloaderror': this.breadcrumbErrorTopicModel,
        'profile': this.breadcrumbProfileTopicModel,
        'reseller': this.breadcrumbResellerTopicModel,
        'ssl': this.breadcrumbSslTopicModel,
        'storage': this.breadcrumbStorageTopicModel,
        'webhosting': this.breadcrumbWebhostingTopicModel
    };

    constructor(
        private $state: ng.ui.IStateService,
        private breadcrumbTopicModel: BreadcrumbTopicModel,
        private breadcrumbAccountTopicModel: BreadcrumbAccountTopicModel,
        private breadcrumbBillingTopicModel: BreadcrumbBillingTopicModel,
        private breadcrumbBundleTopicModel: BreadcrumbBundleTopicModel,
        private breadcrumbDashboardTopicModel: BreadcrumbDashboardTopicModel,
        private breadcrumbDatabaseTopicModel: BreadcrumbDatabaseTopicModel,
        private breadcrumbDnsTopicModel: BreadcrumbDnsTopicModel,
        private breadcrumbDomainTopicModel: BreadcrumbDomainTopicModel,
        private breadcrumbErrorTopicModel: BreadcrumbErrorTopicModel,
        private breadcrumbHelpdeskTopicModel: BreadcrumbHelpdeskTopicModel,
        private breadcrumbMachineTopicModel: BreadcrumbMachineTopicModel,
        private breadcrumbMailboxTopicModel: BreadcrumbMailboxTopicModel,
        private breadcrumbManagedMachineTopicModel: BreadcrumbManagedMachineTopicModel,
        private breadcrumbNetworkTopicModel: BreadcrumbNetworkTopicModel,
        private breadcrumbNextcloudTopicModel: BreadcrumbNextcloudTopicModel,
        private breadcrumbProfileTopicModel: BreadcrumbProfileTopicModel,
        private breadcrumbResellerTopicModel: BreadcrumbResellerTopicModel,
        private breadcrumbSslTopicModel: BreadcrumbSslTopicModel,
        private breadcrumbStorageTopicModel: BreadcrumbStorageTopicModel,
        private breadcrumbWebhostingTopicModel: BreadcrumbWebhostingTopicModel,

        private navigation: NavigationService
    ) {}

    public $onInit(): void {
        this.routeParts = this.$state.current.name.split('.');
        this._setTopicModel();
        this.topicModel.steps = this.routeParts;
        this.breadcrumbs = this._loadBreadcrumb();
    }

    public showArrowIcon(breadcrumb: Breadcrumb, index: number): boolean {
        return !(index === 0 || (breadcrumb.type === 'dropdown' && !this.showDropdown(breadcrumb)));
    }

    public showDropdown(breadcrumb: Breadcrumb): boolean {
        return !(
            [undefined, null].indexOf(breadcrumb) >= 0
            || (breadcrumb.type !== 'dropdown' || breadcrumb.items === undefined)
            || ([undefined, null, []].indexOf(breadcrumb.items) >= 0
                && breadcrumb.items[0].type !== undefined
                && breadcrumb.items[0].type === 'overview'
                && breadcrumb.items.length <= 1)
        );
    }

    public isDisabled = (item: BreadcrumbItem): boolean => {
        if ([undefined, null].indexOf(item) >= 0 || [undefined, null].indexOf(item.disabled) >= 0) {
            return false;
        }

        return item.disabled;
    };

    public issetRoute = (breadcrumb: Breadcrumb, index?: number): boolean => {
        return !(breadcrumb === undefined
            || breadcrumb.route === undefined
            || breadcrumb.route === ''
            || (breadcrumb.active !== undefined && breadcrumb.active));
    };

    private _loadBreadcrumb = (): Breadcrumb[] => {
        if (this.$state.current.name === this.lastState) {
            return this.lastBreadcrumbs;
        }

        this.lastState = this.$state.current.name;
        this.lastBreadcrumbs = this.routeParts
            .map(this._getBreadcrumb)
            .reduce((a, b) => a.concat(b), [])
            .filter((item) => item !== undefined);
        return this.lastBreadcrumbs;
    };

    private _getBreadcrumb: (step: string, index: number, steps: string[]) => Breadcrumb[]
    = (step: string, index: number, steps: string[]) => {
        this.topicModel.breadcrumbs = [];
        this.topicModel.step = step;
        this.topicModel.index = index;
        index === 0 ? this.topicModel.getFirstLevelData() : this.topicModel.getBreadcrumb();
        // Clear breadcrumbs with no matched user rights
        this.topicModel.checkIsGranted();

        /**
         * Debugging Breadcrumbs
         *
         * @wtf If working here you can outcomment the next row.
         */
        // console.log(steps, step, index); // Development

        return this.topicModel.breadcrumbs;
    };

    private _setTopicModel = (): void => {
        // temporaly fix of dashboard breadcrumb
        const topicModel = ['nextcloud-dashboard', 'old-dashboard'].indexOf(this.routeParts[0]) >= 0
            ? 'dashboard'
            : this.routeParts[0];
        this.topicModel = this.topicModels[topicModel] !== undefined
            ? this.topicModels[topicModel]
            : this.breadcrumbTopicModel;

        if (this.topicModel.onInit !== undefined) {
            this.topicModel.onInit();
        }
    };
}

export class MoleculeBreadcrumbComponent implements ng.IComponentOptions {
    public bindings = {};
    public controller =  MoleculeBreadcrumbController;
    public template = require('./breadcrumb.html');
}
