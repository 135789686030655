import * as ng from 'angular';
import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateFromCartController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.pageHeaderData = {
            panelHeaderRoute: '',
            panelIcon: 'th',
            panelTitle: this.$translate.instant('TR_110119-d5d874_TR')
        };
    }
}

export class TemplateFromCartComponent implements ng.IComponentOptions {
    public controller = TemplateFromCartController;
    public template = require('./template-from-cart.html');
}
