import * as ng from 'angular';
import { FormDropDownItems } from '../drop-down';

import './drop-down-groups.scss';

export interface FormDropDownGroupsItems {
    title: string;
    items: FormDropDownItems[];
}

export class MoleculeFormDropDownGroupsController implements ng.IController {
    public static $inject: string[] = [];

    public showOptions = false;
    public items: FormDropDownGroupsItems[] = [];
    public disabled: boolean;
    public disableRandomisedId: boolean;
    public model: any;
    public popoverModifier: string;
    public overviewLoad: (selection: any) => any;
    public placeholder: string;
    public defaultOption: any;
    public sizeModifier: string;
    public onChangeCallback: (selection: any) => any;
    public popoverPosition: string;
    public inputId: string;
    public clearable: boolean;
    public readonly = false;

    private _dropDownType = '';

    public $onInit() {
        this.disabled = this.disabled || false;
    }

    public toggleOptions = () => {
        if (!this.disabled && !this.readonly) {
            const oldShowOptions = this.showOptions;

            this.inputClick();
            this.showOptions = !oldShowOptions;
        }
    };

    public inputClick = () => {
        if (!this.showOptions && !this.disabled) {
            this.showOptions = true;
        }
    };

    public clear = () => {
        this.model = undefined;
        this.showOptions = false;
        if (this.onChangeCallback !== undefined) {
            this.onChangeCallback(undefined);
        }
        if (this.overviewLoad !== undefined) {
            this.overviewLoad(undefined);
        }
    };

    public selectOption(item) {
        this.model = item.value;
        if (this.overviewLoad !== undefined) {
            this.overviewLoad(item.value);
        }
        if (this.onChangeCallback !== undefined) {
            this.onChangeCallback(item.value);
        }
        this.toggleOptions();
    }

    public outerElementClick() {
        if (this.showOptions) {
            this.showOptions = false;
        }
    }

    public get selectedOption() {
        for (const option of this.items) {
            for (const subOption of option.items) {
                if (this._dropDownType === 'nameservers') {
                    if (this._nameserversAreEquivalent(subOption.value, this.model)) {
                        return subOption.name;
                    }
                } else {
                    if (subOption.value === this.model) {
                        return subOption.name;
                    }
                }
            }
        }

        return null;
    }

    public set selectedOption(value) {
        // empty and with no functionallity setter
    }

    public escCallback = (event: KeyboardEvent) => {
        this.toggleOptions();
        (event.target as HTMLElement).blur();
    };

    private _nameserversAreEquivalent = (objectOne, objectTwo) => {
        if (objectOne.default !== objectTwo.default) {
            return false;
        }

        if (objectOne.id !== objectTwo.id) {
            return false;
        }

        if (objectOne.default === false && objectOne.id === null) {
            // custome nameserver is selected
            return true;
        }

        if (objectOne.nameservers !== objectTwo.nameservers) {
            return false;
        }

        return true;
    };
}

export class MoleculeFormDropDownGroupsComponent implements ng.IComponentOptions {
    public bindings = {
        _dropDownType: '@dropDownType',
        clearable: '<?',
        defaultOption: '<',
        disabled: '<',
        disableRandomisedId: '<?',
        inputId: '@?',
        items: '<',
        model: '=',
        onChangeCallback: '<',
        overviewLoad: '<',
        placeholder: '@',
        popoverModifier: '@',
        popoverPosition: '@',
        readonly: '<',
        sizeModifier: '@'
    };
    public controller =  MoleculeFormDropDownGroupsController;
    // tslint:disable-next-line: max-line-length
    public template = require('./drop-down-groups.html');
}
