import { ValidationErrorList } from '@/atomic-components/molecules';
import { ValidateFile } from '@/services/validators/validate-file';
import { FormDescriptionSpec } from '@/types/view-types/form-description/form-description';
import { FileSizes } from '@/configuration';
import ng from 'angular';

export class MoleculePanelEditRowFileController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public value: FileList | string |'keep' | null;
    public placeholder: string;
    public callbackOnChange: (arg: unknown) => unknown;
    public callbackOnChangeParams: unknown;
    public validationOptions: FormDescriptionSpec.FieldObjectFileValidation;
    public validationErrorList: ValidationErrorList;
    public validationErrorListOuter: ValidationErrorList;
    public filePreview: string[];
    private validator: ValidateFile;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {
        this.validator = new ValidateFile(this.$translate);
    }

    public $onInit(): void {
        this.filePreview = [];
        this.validationErrorList = [];
        this.fileList = this.value;
    }

    public returnFileSize = (num: number): string => {
        if (num < FileSizes.kbyte) {
            return `${num} bytes`;
        } else if (num >= FileSizes.kbyte && num < FileSizes.maxkbinbyte) {
            return (num / FileSizes.kbyte).toFixed(1) + ' KB';
        } else if (num >= FileSizes.maxkbinbyte) {
            return (num / FileSizes.maxkbinbyte).toFixed(1) + ' MB';
        }
    };

    public get fileSelected(): boolean {
        if ([null, undefined].indexOf(this.value) < 0) {
            return true;
        }
        return false;
    }

    public removeFile = (): void => {
        this.value = null;
        this.validationErrorList = [];
    };

    public get fileList(): FileList | string | null {
        if (([undefined, null].indexOf(this.value) >= 0) || this.value === 'keep') {
            return null;
        }
        return this.value;
    }
    public set fileList(value: FileList | string | null) {
        this.validationErrorList = [];
        if (value === 'keep') {
            this.filePreview = [];
        } else if ([undefined, null].indexOf(value) >= 0) {
            this.filePreview.forEach((preview) => {
                URL.revokeObjectURL(preview);
            });
            this.filePreview = [];
        } else {
            const fileList = (value as FileList);
            for (let index = 0; index < fileList.length; index++) {
                const file = fileList.item(index);
                this.validationErrorList.push(...this.validator.validate(file, this.validationOptions));
                if (this.validationErrorList.length <= 0) {
                    this.filePreview.push(URL.createObjectURL(file));
                } else {
                    value = null;
                }
            }
        }
        this.value = value;
    }

    public get acceptedFileType(): string {
        if (this.validationOptions.fileMimeTypes.length <= 0) {
            return '';
        }
        return this.validationOptions.fileMimeTypes.join(',');
    }
}

export class MoleculePanelEditRowFileComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnChange: '<',
        callbackOnChangeParams: '<',
        placeholder: '@',
        validationErrorList: '=?',
        validationErrorListOuter: '=?',
        validationInstructions: '<',
        validationInstructionsOperator: '@?',
        validationOptions: '<',
        value: '='
    };

    public transclude = {
        buttons: '?rowButtons',
        label: '?rowLabel'
    };

    public controller = MoleculePanelEditRowFileController;
    public template = require('./panel-edit-row-file.html');
}
