import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';

export class TemplateSslCertificateEditController {
    public static $inject: string[] = ['$translate', 'authContext'];

    public pageHeaderData: PanelHeaderData;
    public certificate;
    public userPanelRight;
    public showObjectId: boolean;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit = () => {
        const certificate = this.certificate;

        this.pageHeaderData = {
            backwardLink: 'ssl.certificates.overview',
            backwardText: this.$translate.instant('TR_140119-fc067d_TR'),
            panelHeaderRoute: 'ssl.certificates.overview',
            panelHeaderRouteParams: null,
            panelIcon: 'shield',
            panelTitle: this.$translate.instant('TR_140119-5054a2_TR',  { certificateName: certificate.commonName })
        };

        this.userPanelRight = {
            reissuePanel: this.authContext.isGranted(UiRights.SSL_ORDER),
            renewPanel:  this.authContext.isGranted(UiRights.SSL_RENEW),
            revokePanel: this.authContext.isGranted(UiRights.SSL_REVOKE)
        };
    };
}

export class TemplateSslCertificateEditComponent implements ng.IComponentOptions {
    public bindings = {
        certificate: '<',
        showObjectId: '<'
    };
    public controller = TemplateSslCertificateEditController;
    public controllerAs = '$TemplateSslCertificateEditCtrl';
    public template = require('./ssl-certificate-edit-template.html');
}
