/* eslint-disable */

import * as ng from 'angular';
import * as q from 'q';

import { NextcloudUpgradeSelectedProduct } from '@/atomic-components';
import { UiLanguageDefaultSettingsConst, UiLanguagesConst, UIRegExp } from '@/configuration';
import {
    AuthContextService,
    BillingHelperService,
    BundleModelService,
    FactSheetItems,
    KeywordsDescriptions,
    PriceCacheService,
    ProductHelperService,
    ProductsModelService
} from '@/services';
import * as Types from '@/types';

// export interface FactSheetItems {
//     category: string;
//     keyword: string;
//     productCode: string;
//     value: number;
// }

// export interface KeywordsDescriptions {
//     category: string;
//     description: string;
//     keyword: string;
//     language: string;
//     name: string;
//     type: string;
// }
export interface ProductBoxObject {
    billingCycles?: number[];
    description?: string;
    factSheetItems?: FactSheetItems[];
    family: string;
    keywordsDescriptions?: KeywordsDescriptions[];
    name: string;
    prices?: NextcloudUpgradeSelectedProduct[];
    productCodeTemplate: string;
    productCode: string;
    productImage?: string;
    recommend?: boolean;
    service: string;
    shortDescription: string;
    specificationItems?: Types.ProductApi.SpecificationItem[];
    summaryKeywords: Types.ProductApi.FactSheetKeyword[];
    verificationAlternatives?: [][];
    type: 'ProductBoxObject';
    additionalProductCodesforPriceCalculation: string[];
}
export interface FamilyProductBoxObject {
    description: string;
    family: string;
    name: string;
    hidePrice?: boolean;
    products: ProductBoxObject[];
    shortDescription: string;
    service: string;
    specificationItems: Types.ProductApi.SpecificationItem[];
    summaryKeywords: Types.ProductApi.FactSheetKeyword[];
    type: 'FamilyProductBoxObject';
}

export class SelectBoxContentCreatorNew {
    public static $inject: string[] = [
        'billingHelper',
        'bundleModel',
        'productsModel',
        'priceCache',
        '$translate'
    ];

    private _service: string;
    private _language: string;
    private _productFamilies: any[] = [];
    private _familyResults: any[] = [];

    constructor(
        private billingHelper: BillingHelperService,
        private bundleModel: BundleModelService,
        private productsModel: ProductsModelService,
        private priceCache: PriceCacheService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this._language = UiLanguageDefaultSettingsConst.languageUiIso;
    }

    public createSelectBoxes = (
        service: string,
        productFamilies: string[],
        ignoreProductCodes?: string[]
    ) => {
        this._service = service;
        this._productFamilies = productFamilies;
        this._language = UiLanguagesConst[AuthContextService.user.language];
        return this._getProductFamiliesDescription()
            .then(
                (productPromises: any) => this._getProducts(productPromises, ignoreProductCodes)
            ) as ng.IPromise<FamilyProductBoxObject[]>;
    };

    private _getKeywordDetailsByFactSheetItems = (factSheetItems: any, language: string) => {
        const factSheetItemKeywords = factSheetItems
            .map((factSheetItem: any) => {
                return { category: factSheetItem.category, keyword: factSheetItem.keyword };
            });
        return this.productsModel.findKeywordDescriptions(factSheetItemKeywords, language);
    };

    private _getFactSheetItemFormContingents = (
        contingents: any,
        category: string,
        productCodeSearchString: string,
        summaryKeywords: any
    ) => {
        const sheets = [];
        contingents.map((contingent: any) => {
            contingent.productCodes.map((productCode: string) => {
                if (category !== 'webspace') {
                    summaryKeywords.push({
                        category: category, // tslint:disable-line
                        keyword: productCode
                    });
                }
                if (productCode.indexOf(productCodeSearchString) === 0) {
                    sheets.push({
                        category: category, // tslint:disable-line
                        keyword: productCode,
                        productCode: productCode,  // tslint:disable-line
                        // Special case on category storage - default storage on all bundles are 1 GB
                        // This default has to added to capacity.
                        value: (category !== 'storage' ? contingent.capacity : (contingent.capacity + 1))
                    });
                }
            });
        });

        // Special case on category storage
        // If no storage in contingents given, it has to set the default value of 1GB manually.
        if (category === 'storage' && sheets.length === 0) {
            sheets.push({
                category: category, // tslint:disable-line
                keyword: productCodeSearchString,
                value: 1
            });
            summaryKeywords.push({
                category: category, // tslint:disable-line
                keyword: productCodeSearchString
            });
        }

        return sheets;
    };

    private _getProductFamiliesDescription = () => {
        const familyPromises = [];
        for (const familyName of this._productFamilies) {
            familyPromises.push(
                this.productsModel.findProductFamilyDescription(this._service, familyName, this._language)
            );
        }
        return q.all(familyPromises).then((familyResults) => {
            const productPromises: any[] = [];
            this._familyResults = familyResults;
            this._familyResults.map((result) => {
                productPromises.push(
                    this.productsModel.findProducts(this._service, result.response.familyId, this._language)
                );
            });
            return productPromises;
        });
    };

    private _getProducts = (
        productPromises: PromiseLike<Types.UI.APIResponse<Types.ProductApi.ProductsFindResult>>[],
        ignoreProductCodes?: string[]
    ): PromiseLike<unknown[]> => {
        return Promise.all(productPromises)
        .then(
            (productResults) => {
                ignoreProductCodes = ignoreProductCodes || [];
                const keywordDetailPromises = [];
                const familiyBoxContent: FamilyProductBoxObject[] = [];

                /* eslint-disable @typescript-eslint/no-for-in-array *//* eslint-disable guard-for-in */
                for (const i in productResults) {
                    const productObjects: ProductBoxObject[] = [];
                    for (const product of productResults[i].response.data) { // tslint:disable-line
                        if (('requiredCreationRight' in product)
                            && !AuthContextService.isGranted(product.requiredCreationRight)
                        ) {
                            continue;
                        }

                        if (
                            product.productType === 'webspace'
                            && (product as Types.ProductApi.TemplateProduct).productCodeTemplate.indexOf('bundle') >= 1
                        ) {
                            // Bundle webspace products are not orderable over webhosting wizard!
                            continue;
                        }

                        if (
                            product.productType === 'managed-application-nextcloud'
                            && (
                                !UIRegExp.IsNumeric.test(AuthContextService.account.parentAccountId)
                                || parseInt(AuthContextService.account.parentAccountId, 10) > 1000
                            )
                            && ProductHelperService.wrapSpecificationItems(
                                product.specificationItems
                            ).isFreeProduct?.isTrue
                        ) {
                            // Do not show nextcloud free to subaccounts.
                            continue;
                        }

                        if (this._checkIgnoreProductCode(ignoreProductCodes, product)) {
                            continue;
                        }

                        const productAggregatedData: ProductBoxObject = {
                            additionalProductCodesforPriceCalculation:
                                product.additionalProductCodesForPriceCalculation || [],
                            billingCycles: (product as Types.ProductApi.TemplateProduct).billingCycles
                                ? this.billingHelper.sortBillingCyclesAsc(
                                    (product as Types.ProductApi.TemplateProduct).billingCycles
                                )
                                : null,
                            description: product.description,
                            factSheetItems: product.factSheetItems,
                            family: this._familyResults[i].response.familyId,
                            keywordsDescriptions: [],
                            name: product.name,
                            productCode: (
                                product as Types.ProductApi.Product | Types.ProductApi.TemplateProduct
                            ).type.toLowerCase() === 'product'
                                ? (product as Types.ProductApi.Product).productCode
                                : null,
                            productCodeTemplate: (
                                product as Types.ProductApi.Product | Types.ProductApi.TemplateProduct
                            ).type.toLowerCase() === 'templateproduct'
                                ? (product as Types.ProductApi.TemplateProduct).productCodeTemplate
                                : null,
                            recommend: ([undefined, null, false].indexOf(product.featured) < 0)
                                ? product.featured
                                : false,
                            service: this._service,
                            shortDescription: product.shortDescription,
                            specificationItems: product.specificationItems,
                            summaryKeywords: this._familyResults[i].response.summaryKeywords,
                            type: 'ProductBoxObject'
                        };

                        keywordDetailPromises.push(
                            this._getKeywordDetailsByFactSheetItems(
                                product.factSheetItems,
                                this._language
                            ).then((data) => {
                                productAggregatedData.keywordsDescriptions = data.responses;
                                return data.responses;
                            })
                        );

                        productObjects.push(productAggregatedData);
                    }

                    familiyBoxContent.push({
                        description: this._familyResults[i].response.description,
                        family: this._familyResults[i].response.familyId,
                        name: this._familyResults[i].response.name,
                        products: productObjects,
                        service: this._service,
                        shortDescription: this._familyResults[i].response.shortDescription,
                        specificationItems: this._familyResults[i].response.specificationItems,
                        summaryKeywords: this._familyResults[i].response.summaryKeywords,
                        type: 'FamilyProductBoxObject'
                    });
                }
                /* eslint-enable @typescript-eslint/no-for-in-array *//* eslint-enable guard-for-in */

                return q.all(keywordDetailPromises).then(() => {
                    return familiyBoxContent;
                });
            }
        );
    };

    private _checkIgnoreProductCode = (ignoreProductCodeList: string[], product) => {
        if (ignoreProductCodeList.length > 0) {
            switch (product.type.toLowerCase()) {
                case 'product':
                    return ignoreProductCodeList.indexOf(product.productCode) >= 0;
                case 'templateproduct':
                    return ignoreProductCodeList.some((productCode: string) => {
                        // check, if productCodeTemplate string starts with given productCode to ignore
                        return product.productCodeTemplate.indexOf(productCode) === 0;
                    });
            }
        }

        return false;
    };
}
