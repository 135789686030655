import { ITextRender } from '@/services';
import { RobotTranslate } from '@/services';
import { TimeSpecificationsConst } from '@/configuration';
import { WebhostingApi } from '@/types';

export class CronJobTimeDisplayRenderer implements ITextRender<WebhostingApi.CronJob, string>{
    public static $inject: string[] = ['$translate'];

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public renderText(item: WebhostingApi.CronJob): string {
        let returnValue = '';
        returnValue = this.schedule_part(item, returnValue);
        return returnValue;
    }

    private schedule_part(item: WebhostingApi.CronJob, returnValue: string): string {
        switch (true) {
            case item.schedule === '1hour':
                return this.$translate.instant('TR_090119-04371b_TR');
            case /hour$/.test(item.schedule):
                return this.$translate.instant(/* translationId */ 'TR_240921-153129_TR',
                    { hours: item.schedule.slice(0, item.schedule.length - 4) }
                );
            case /min$/.test(item.schedule):
                return this.$translate.instant(/* translationId */ 'TR_240921-748f78_TR',
                    { minutes: item.schedule.slice(0, item.schedule.length - 3) }
                );
            case item.schedule === 'daily':
                return this.dailySchedule(item, returnValue);
            case item.schedule === 'weekly':
                return this.weeklySchedule(item, returnValue);
            case item.schedule === 'monthly':
                return this.monthlySchedule(item, returnValue);
        }
    }

    private monthlySchedule(item: WebhostingApi.CronJob, returnValue: string): string {
        if (item.daypart !== '' && item.hour === 0) {
            const timeOfDay = this.$translate.instant('TR_240921-213746_TR',
                { part1: item.daypart.split('-')[0], part2: item.daypart.split('-')[1] }
            );

            returnValue = this.$translate.instant('TR_240921-0cfc53_TR',
                { dayOfMonth: item.dayOfMonth.toString(), timeOfDay: timeOfDay }
            );
        } else if (item.daypart === '') {
            const hour = TimeSpecificationsConst.hours
                .find(hour => hour.value === item.hour).name.padStart(2, '0');

            const minute = TimeSpecificationsConst.minutes()
                .find(minute => minute.value === item.minute).name.padStart(2, '0');

            returnValue = this.$translate.instant('TR_240921-ec022e_TR',
                { dayOfMonth: item.dayOfMonth.toString(), timeOfDay: `${hour}:${minute}` }
            );
        }
        return returnValue;
    }

    private weeklySchedule(item: WebhostingApi.CronJob, returnValue: string): string {
        if (item.daypart !== '' && item.hour === 0) {
            const timeOfDay = this.$translate.instant('TR_240921-213746_TR',
                { part1: item.daypart.split('-')[0], part2: item.daypart.split('-')[1] }
            );

            const dayOfWeek = this.$translate.instant(
                TimeSpecificationsConst.weekdays.find(s => s.value === item.weekday).translationId
            );
            returnValue = this.$translate.instant('TR_240921-992be7_TR',
                { dayOfWeek: dayOfWeek, timeOfDay: timeOfDay }
            );
        } else if (item.daypart === '') {
            const hour = TimeSpecificationsConst.hours
                .find(hour => hour.value === item.hour)
                .name
                .padStart(2, '0');

            const minute = TimeSpecificationsConst.minutes()
                .find(minute => minute.value === item.minute)
                .name
                .padStart(2, '0');

            const dayOfWeek = this.$translate.instant(
                TimeSpecificationsConst.weekdays
                    .find(s => s.value === item.weekday).translationId
            );

            returnValue = this.$translate.instant('TR_240921-ff7554_TR',
                { dayOfWeek: dayOfWeek, timeOfDay: `${hour}:${minute}` }
            );
        }
        return returnValue;
    }

    private dailySchedule(item: WebhostingApi.CronJob, returnValue: string): string {
        if (item.daypart !== '' && item.hour === 0) {

            const timeOfDay = this.$translate.instant('TR_240921-213746_TR',
                { part1: item.daypart.split('-')[0], part2: item.daypart.split('-')[1] }
            );

            returnValue = this.$translate.instant('TR_240921-618de5_TR',
                { timeOfDay: timeOfDay }
            );

        } else if (item.daypart === '') {
            const hour = TimeSpecificationsConst.hours
                .find(hour => hour.value === item.hour)
                .name
                .padStart(2, '0');

            const minute = TimeSpecificationsConst.minutes()
                .find(minute => minute.value === item.minute)
                .name
                .padStart(2, '0');

            returnValue = this.$translate.instant('TR_240921-ecc016_TR', { timeOfDay: `${hour}:${minute}` });
        }
        return returnValue;
    }
}
