import * as ng from 'angular';
import { CalculatePriceFilter } from '../../../../../filters';
import {
    AuthContextService,
    DomainHelper,
    DomainModelService,
    PriceCacheService,
    PriceHelperService,
    ValidateDns
} from '../../../../../services';

import './auth-info-two.scss';

export class OrganismEditFormDomainAuthInfoTwoController {
    public static $inject: string[] = [
        '$translate', 'priceCache', 'priceHelper', '$timeout', 'authContext', 'calculatePriceFilter', 'domainModel'
    ];

    public account: string;
    public domainInput: string;
    public price: any;
    public currentPriceShown = false;
    public domainsChecked = false;
    public domainsAlreadyOwned: any[] = [];
    public domainValidator: ValidateDns;
    public invalidDomains: string[] = [];
    public showTldError = false;
    public authInfoCreated = false;
    public hasAgreedToRequestAuthInfo = false;
    public showVerifyButton: boolean;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private priceCache: PriceCacheService,
        private priceHelper: PriceHelperService,
        private $timeout: ng.ITimeoutService,
        private authContext: AuthContextService,
        private calculatePriceFilter: CalculatePriceFilter,
        private domainModel: DomainModelService
    ) {}

    public $onInit() {
        this.account = this.authContext.account.id;
        this.domainValidator = new ValidateDns(this.$translate);
        this.showVerifyButton = false;
    }

    public checkInput = () => {
        if (this.amount > 0) {
            this.getPrices();
            this.checkDomains();
        }
    };

    public getPrices = () => {
        this.currentPriceShown = false;
        this.$timeout(
            () => {
                this.priceCache.listDomainPrices('de', this.account).then(
                    (reply) => {
                        reply.map(
                            (price) => {
                                if (price.productCode === 'domain-de-authinfo2') {
                                    this.price = price;
                                    this.currentPriceShown = true;
                                }
                            }
                        );
                    }
                );
            },
            100
        );
    };

    public checkDomains = () => {
        if (this.domainInput === undefined || this.domainInput.length === 0) {
            return;
        }

        this.invalidDomains = [];

        this.domainInput.split(/\r?\n/).map(
            (domain) => {
                if (this.domainValidator.validate(domain).length > 0) {
                    this.invalidDomains.push(domain);
                }
            }
        );

        this.showTldError = DomainHelper.getTlds(this.domainInput.split(/\r?\n/))
        .some((tld) => tld !== 'de');

        this.domainModel.findByName(this.domainInput.split(/\r?\n/))
        .then(
            (reply) => {
                this.domainsAlreadyOwned = reply.map(
                    (domain) => {
                        return {
                            name: domain.name,
                            routeParams: {
                                domainId: domain.id
                            }
                        };
                    }
                );
            }
        );
    };

    public get amount() {
        if (this.domainInput === undefined || this.domainInput.length === 0) {
            return 0;
        }
        return  this.domainInput.split(/\r?\n/).length;
    }

    public get totalPrice() {
        if (this.domainInput === undefined || this.domainInput.length === 0) {
            return '';
        }

        const domains = this.domainInput.split(/\r?\n/);
        const type = this.authContext.account.isCommercialCustomer ? 'net' : 'gross';

        return this.calculatePriceFilter(
            this.price.price,
            this.price.vatRate,
            domains.length,
            type,
            null,
            false,
            this.price.productCode,
            this.price.contractPeriod);
    }

    public get singlePrice() {
        if (this.domainInput === undefined || this.domainInput.length === 0) {
            return '';
        }

        // let domains = this.domainInput.split(/\r?\n/)
        const type = this.authContext.account.isCommercialCustomer ? 'net' : 'gross';
        return this.calculatePriceFilter(
            this.price.price,
            this.price.vatRate,
            1,
            type,
            null,
            false,
            this.price.productCode,
            this.price.contractPeriod);
    }

    public get currency() {
        return this.priceHelper.getProductCurrency(this.price);
    }

    public domainInputChange = () => {
        this.currentPriceShown = false;
        this.domainsChecked = false;
    };

    public sendAuthInfoTwoRequest = () => {
        this.domainModel.createAuthInfo2(this.domainInput.split(/\r?\n/), this.account)
        .then(() => this.authInfoCreated = true);
    };

    public get disableSendButton() {
        if (this.hasAgreedToRequestAuthInfo && this.inputIsValid) {
            return false;
        } else {
            return true;
        }
    }

    public get inputIsValid() {
        if (this.domainsAlreadyOwned.length === 0 && this.invalidDomains.length === 0 && !this.showTldError) {
            return true;
        } else {
            return false;
        }
    }

    public get showResults() {
        if (this.currentPriceShown && !this.authInfoCreated) {
            if (this.inputIsValid) {
                this.showVerifyButton = true;
            }

            return true;
        } else {
            return false;
        }
    }

}

export class OrganismEditFormDomainAuthInfoTwoComponent implements ng.IComponentOptions {
    public controller = OrganismEditFormDomainAuthInfoTwoController;
    public template = require('./auth-info-two.html');
}
