import ng from 'angular';

import { EditPanelRight, EditPanelStatus } from '@/atomic-components';
import { AlertManagerService, AuthContextService, DnsTemplateModelService, NavigationService } from '@/services';
import { DnsApi } from '@/types';

export class OrganismCopyFormDnsTemplateController {
    public static $inject: string[] = ['$translate', 'alertManager', 'authContext', 'dnsTemplateModel', 'navigation'];

    public panelRightSettings: EditPanelRight;
    public initialStatus = EditPanelStatus.ACTIVE;
    public template: DnsApi.Template;
    public ownerAccountId: string;
    public records: DnsApi.Record[];
    public zone: DnsApi.Zone;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private authContext: AuthContextService,
        private dnsTemplateModel: DnsTemplateModelService,
        private navigation: NavigationService
    ) {}

    public $onInit(): void {
        this.zone = {};
        this.ownerAccountId = this.template.accountId;
        this.template = {
            accountId: this.template.accountId,
            name: ''
        };
        this.panelRightSettings = {
            editPanelButton: false
        };
    }

    public save = (): Promise<boolean> => {
        const records = this.records.map((record) => this._normalizeRecord(record));
        return this.dnsTemplateModel.create(this.template, records, this.ownerAccountId).then(
            (result) => {
                if (result.status === 'success') {
                    this.alertManager.success(this.$translate.instant('DNS.MESSAGE.TEMPLATE.NEW.SUCCESS'));
                    void this.navigation.go('dns.templates.overview');
                }
                return Promise.reject(false);
            },
            () => {
                return Promise.reject(false);
            }
        );
    };

    private _normalizeRecord = (record: DnsApi.Record): DnsApi.Record => {
        if ([undefined, null].indexOf(record.id) < 0 && record.id.substring(0, 8) === 'template') {
            delete record.id;
        }
        record.ttl = +record.ttl;
        if (record.name.lastIndexOf('.') === record.name.length - 1) {
            record.name = record.name.substring(0, record.name.length - 1);
        }

        if (['mx', 'srv'].indexOf(record.type.toLowerCase()) === -1) {
            delete record.priority;
        } else {
            record.priority = +record.priority;
        }
        return record;
    };
}

export class OrganismCopyFormDnsTemplateComponent implements ng.IComponentOptions {
    public bindings = {
        records: '<',
        template: '<'
    };
    public controller = OrganismCopyFormDnsTemplateController;
    public template = require('./dns-template-copy.html');
}
