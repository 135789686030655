import * as ng from 'angular';

import { WizardHelperService, WizardPromoProduct } from '@/atomic-components';
import {
    ContractPeriodNumber,
    ContractPeriodeToBillingCycleConst,
    UiLanguagesConst,
    ProductFamiliesConst
} from '@/configuration';
import {
    AccountModelService,
    AlertManagerService,
    ApiErrorModel,
    AuthContextService,
    FamilyProductBoxObject,
    ManagedApplicationRobotService,
    NavigationService,
    PriceHelperService,
    ProductBoxObject,
    ProductHelperService,
    ProductsModelService,
    SelectBoxContentCreatorNew
} from '@/services';
import { AccountApi, ManagedApplicationApi, ProductApi, UI } from '@/types';

export type NextcloudUpgradeProductMetaData = {
    name: string;
    productCode: string;
    productCodeTemplate?: string;
    backupProductCodeTemplate: string;
    backupProductCode?: string;
    billingCycle?: number;
};

export class OrganEditPanelNextcloudUpgradeController {
    public static $inject: string[] = [
        '$state',
        '$timeout',
        'accountModel',
        'alertManager',
        'apiErrorModel',
        'managedApplicationRobot',
        'navigation',
        'priceHelper',
        'productsModel',
        'selectBoxContentCreatorNew',
        'wizardHelper',
        '$translate'
    ];

    public allowProductDowngrades = false;
    public contractPeriodItems: unknown[] = [];
    public componentInitialized = false;
    public currentProductBox: ProductBoxObject;
    public displayCreditCheck = false;
    public nextcloud: ManagedApplicationApi.Nextcloud;
    public nextcloudOwnerAccount: AccountApi.Account | AccountApi.Subaccount;
    public nextcloudSelectedProductMetaData: NextcloudUpgradeProductMetaData;
    public productCodeList: string[];
    public productFamilies: FamilyProductBoxObject[] = [];
    public promoProductItems: WizardPromoProduct[] = [];
    public selectedProductFamily = '';
    public sufficientCreditAvailable = false;
    public targetProductBoxes: FamilyProductBoxObject[] = [];
    public upgradeConfirmed = false;

    private contractPeriod = '12';
    private currentFamilyUpgradeableProductsMetaData: NextcloudUpgradeProductMetaData[] = [];
    private currentProductDetails: ProductApi.TemplateProduct;
    private language: string;
    private selectedProduct: ProductBoxObject;
    private selectedProductCode = '';
    private updateInProgress = false;
    private _orderBackup = false;
    private _requestingUpgradeProduct = {
        user: false,
        talk: false
    };
    private _userUpgradesCache: Record<string, {
        productCode: string;
        amount: number;
    }> = {};

    constructor(
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private accountModel: AccountModelService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private managedApplicationRobot: ManagedApplicationRobotService,
        private navigation: NavigationService,
        private priceHelper: PriceHelperService,
        private productModel: ProductsModelService,
        private selectBoxContentCreatorNew: SelectBoxContentCreatorNew,
        private wizardHelper: WizardHelperService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public async $onInit(): Promise<void> {
        // admins may perform product downgrades
        this.allowProductDowngrades = AuthContextService.isRootOrCompanyAccount;
        this.componentInitialized = false;
        this.contractPeriodItems = [];
        this.language = UiLanguagesConst[AuthContextService.user.language];
        let nextcloudOwnerAccount = AuthContextService.account;

        if (AuthContextService.account.id !== this.nextcloud.accountId) {
            nextcloudOwnerAccount = await this.accountModel.findOneSubaccount(this.nextcloud.accountId);
        }

        this.nextcloudOwnerAccount = nextcloudOwnerAccount;
        await this._updateProductBoxes();
        this.promoProductItems = [{
            buttonText: /* translationID */ 'TR_170620-e998a6_TR',
            description: /* translationID */ 'TR_170620-5cf01c_TR',
            headLine: /* translationID */ 'TR_170620-ef7de7_TR',
            productFamilyName: 'Managed Nextcloud',
            route: 'show.contact.form',
            routeParams: {}
        }];
    }

    public async $onChanges(changes: ng.IOnChangesObject): Promise<void> {
        if (changes.nextcloud !== undefined && changes.nextcloud.currentValue !== undefined) {
            this.selectedProduct = undefined;
            this.currentProductBox = undefined;
            this.nextcloudSelectedProductMetaData = undefined;
            this.orderBackup = false;
            this.upgradeConfirmed = false;
            await this._updateProductBoxes();
        }
    }

    public get contractPeriodText(): string {
        return (
            ContractPeriodeToBillingCycleConst[
                (parseInt(this.contractPeriod, 10) as ContractPeriodNumber)
                ] as string
        );
    }

    public get backupCustomLabel(): string {
        return this.nextcloud.backupEnabled
            ? this.$translate.instant('491b1e35-c58a-4177-95ad-996af0bf7508')
            : this.$translate.instant('9225f520-bce1-42b7-9077-0e3be8d06d7a');
    }

    public get showSavePanel(): boolean {
        return this.upgradeConfirmed && this.sufficientCreditAvailable;
    }

    public get isAdmin(): boolean {
        return AuthContextService.isRootOrCompanyAccount === true;
    }

    get orderBackup(): boolean {
        return this._orderBackup;
    }

    set orderBackup(value: boolean) {
        if (value !== this._orderBackup) {
            this.updateProductCodeList();
        }

        this._orderBackup = value;
    }

    public updateProductCodeList = (): void => {
        this.displayCreditCheck = false;

        void this.$timeout(() => {
            if (this.nextcloudSelectedProductMetaData === undefined) {
                return;
            }
            const productCode = this.nextcloudSelectedProductMetaData.productCode;
            const productCodeList: string[] = [];

            if (productCode) {
                productCodeList.push(productCode);

                const backupProductCode = this.nextcloudSelectedProductMetaData.backupProductCode;

                if ((this.nextcloud.backupEnabled || this.orderBackup) && backupProductCode) {
                    productCodeList.push(backupProductCode);
                }
            }

            this.productCodeList = ng.copy(productCodeList);
            this.displayCreditCheck = true;
        });
    };

    get newContractPeriod(): string {
        return this.contractPeriod;
    }

    set newContractPeriod(value: string) {
        if (value !== this.contractPeriod) {
            this.contractPeriod = value;
            this.selectedProductCode = this.priceHelper.replaceBillingCycleInProductCodeTemplate(
                this.selectedProduct.productCodeTemplate, this.contractPeriod
            );
            this.nextcloudSelectedProductMetaData.billingCycle = parseInt(this.contractPeriod, 10);
            this.nextcloudSelectedProductMetaData.productCode = this.priceHelper
                .replaceBillingCycleInProductCodeTemplate(
                    this.selectedProduct.productCodeTemplate, this.contractPeriod
                );
            this.nextcloudSelectedProductMetaData.backupProductCode = this.priceHelper
                .replaceBillingCycleInProductCodeTemplate(
                    this.nextcloudSelectedProductMetaData.backupProductCodeTemplate, this.contractPeriod
                );
            this.updateProductCodeList();
        }
    }

    public isFreeProduct = (): boolean => {
        return ProductHelperService.wrapSpecificationItems(
            this.currentProductDetails?.specificationItems
        ).isFreeProduct?.isTrue === true;
    };

    public isSelectedFamily = (item: FamilyProductBoxObject): boolean => {
        return item.family === this.selectedProductFamily;
    };

    public selectFamily = (item: FamilyProductBoxObject): void => {
        this.promoProductItems[0].productFamilyName = item.family;
        this.selectedProductFamily = item.family;
        this.selectedProductCode = '';
        this.selectedProduct = undefined;
        this.nextcloudSelectedProductMetaData = undefined;
    };

    public selectTarget = (NextcloudProduct: ProductBoxObject): void => {
        this.orderBackup = false;
        this.selectedProduct = NextcloudProduct;
        void this.$timeout(() => {
            this._updateProductMetadata(NextcloudProduct);
            if (this.isFreeProduct()) {
                this.newContractPeriod = '12';
            }
        });
    };

    private _updateProductMetadata = (NextcloudProduct: ProductBoxObject): void => {
        let selectedProductArray;

        if (NextcloudProduct.productCodeTemplate) {
            this.selectedProductCode = this.priceHelper.replaceBillingCycleInProductCodeTemplate(
                NextcloudProduct.productCodeTemplate, this.contractPeriod
            );
            selectedProductArray = this.currentFamilyUpgradeableProductsMetaData.filter(
                (product) => product.productCodeTemplate === NextcloudProduct.productCodeTemplate
            );
        } else {
            this.selectedProductCode = NextcloudProduct.productCode;
            selectedProductArray = this.currentFamilyUpgradeableProductsMetaData.filter(
                (product) => product.productCode === this.selectedProductCode
            );
        }

        if (this.isFreeProduct() && this.contractPeriodItems.length === 0) {
            for (const billingCycles of NextcloudProduct.billingCycles) {
                this.contractPeriodItems.push({
                    label: this.wizardHelper.getBillingCycleTranslation(billingCycles),
                    value: `${billingCycles}`
                });
            }
        }

        this.nextcloudSelectedProductMetaData = selectedProductArray[0];
        this.nextcloudSelectedProductMetaData.backupProductCode = this.priceHelper
            .replaceBillingCycleInProductCodeTemplate(
                this.nextcloudSelectedProductMetaData.backupProductCodeTemplate, this.contractPeriod
            );
        this.nextcloudSelectedProductMetaData.billingCycle = parseInt(this.contractPeriod, 10);
        this.updateProductCodeList();
    };

    public isSelectedProduct = (NextcloudProduct: ProductBoxObject): boolean => {
        return this.priceHelper.replaceBillingCycleInProductCodeTemplate(
            NextcloudProduct.productCodeTemplate, this.contractPeriod
        ) === this.selectedProductCode;
    };

    public upgradeNextcloudInstance = (): PromiseLike<ManagedApplicationApi.Nextcloud> => {
        this.apiErrorModel.destroyErrorList();
        const newProductCode = this.nextcloudSelectedProductMetaData.productCode;
        const enableBackup = this.nextcloud.backupEnabled || this.orderBackup;

        return this.managedApplicationRobot.nextcloudChangeProduct(
            this.nextcloud.id, newProductCode, enableBackup
        ).then(
            (response: ManagedApplicationApi.Nextcloud) => {
                if (!this.apiErrorModel.apiResponseHasError(response)) {
                    void this.alertManager.success(this.$translate.instant('TR_060919-8667c0_TR'));
                    void this.navigation.go(
                        this._getBackToEditPageRoute(),
                        null,
                        { reload: true }
                    );
                    return response;
                }
                return Promise.reject(false);
            },
            (error) => {
                void this.alertManager.error(this.$translate.instant('TR_060919-36d5e7_TR'));
                return Promise.reject(error);
            }
        );
    };

    private _getAutomaticAddonObject = (upgradeType: 'user'|'talk'): {
        productCode: string;
        amount: number;
    } => {
        const returnObject = {
            productCode: '',
            amount: 0
        };

        let productCodeField = '';
        let currentUserAmountField: 'currentNumberOfUsers'|'talkUsers';
        let additionalUsersSpecField = '';

        switch (upgradeType) {
            case 'user':
                productCodeField = 'additionalUsersProductCode';
                currentUserAmountField = 'currentNumberOfUsers';
                additionalUsersSpecField = 'additionalUsers';
                break;

            case 'talk':
                productCodeField = 'additionalTalkUsersProductCode';
                currentUserAmountField = 'talkUsers';
                additionalUsersSpecField = 'additionalTalkUsers';
                break;

            default:
                return returnObject;
        }

        if (
            !!this.selectedProduct === false
            || this._requestingUpgradeProduct[upgradeType]
            || !!this.contractPeriod === false
        ) {
            return returnObject;
        }

        const wrappedSpecTarget = ProductHelperService.wrapSpecificationItems(this.selectedProduct.specificationItems);
        const productCode =  this.priceHelper.replaceBillingCycleInProductCodeTemplate(
            (wrappedSpecTarget[productCodeField]?.value as string),
            this.contractPeriod
        );
        let includedUsers = 0;

        if (upgradeType === 'user') {
            includedUsers = wrappedSpecTarget.nextcloudUsersIncluded.intValue;
        }

        if (productCode === '') {
            return returnObject;
        }

        returnObject.productCode = productCode;

        if (!!this._userUpgradesCache[`${this.selectedProductCode}_${upgradeType}`] === true) {
            return this._userUpgradesCache[`${this.selectedProductCode}_${upgradeType}`];
        }

        if (this.nextcloud[currentUserAmountField] >= includedUsers) {
            this._requestingUpgradeProduct[upgradeType] = true;
            void this.productModel.findOne(productCode, this.language, true)
                .then(
                    (ApiResponse: UI.APIResponse<ProductApi.TemplateProduct>) => {
                        const productData = ApiResponse.response;
                        const userSteps = ProductHelperService.wrapSpecificationItems(
                            productData.specificationItems
                        )[additionalUsersSpecField].intValue;

                        returnObject.amount = Math.ceil(
                            (this.nextcloud[currentUserAmountField] - includedUsers) / userSteps
                        );

                        this._userUpgradesCache[`${this.selectedProductCode}_${upgradeType}`] = returnObject;
                        this._requestingUpgradeProduct[upgradeType] = false;
                    }
                );
        } else {
            returnObject.amount = 0;
        }

        return returnObject;
    };

    public get isSameProductFamilyProductUpgrade(): boolean {
        return this.selectedProduct?.family === this.currentProductDetails?.family;
    }

    public get automaticUserUpgrades(): {
        productCode: string;
        amount: number;
    } {
        return this._getAutomaticAddonObject('user');
    }

    public get automaticTalkUserUpgrades(): {
        productCode: string;
        amount: number;
    } {
        return this._getAutomaticAddonObject('talk');
    }

    private _updateProductBoxes = async(): Promise<unknown> => {
        if (this.updateInProgress) {
            return Promise.resolve(true);
        }

        this.updateInProgress = true;
        this.currentProductBox = undefined;

        return this.productModel.findOne(this.nextcloud.productCode, this.language, true)
            .then(
                (ApiResponse) => {
                    const productData = ApiResponse.response;
                    this.currentProductDetails = (productData as ProductApi.TemplateProduct);
                    this.contractPeriod = productData.billingCycle === undefined
                        ? '12'
                        : `${productData.billingCycle}`;
                    return ApiResponse.response;
                }
            )
            .then(this._setProductFamilyData)
            .then(this._generateProductFamilySelectBoxContent)
            .then(() => {
                this.updateInProgress = false;
                this.componentInitialized = true;
            });
    };

    private _setProductFamilyData = async (
        selectedNextcloudProduct: ProductApi.TemplateProduct
    ): Promise<NextcloudUpgradeProductMetaData[]> => {
        this.currentFamilyUpgradeableProductsMetaData = [];
        let productFamilies = [selectedNextcloudProduct.family];

        if (this.allowProductDowngrades) {
            productFamilies = ProductFamiliesConst.nextcloud;
        } else {
            for (const additionalFamily of selectedNextcloudProduct.productChangePossibleToFamilies) {
                if (selectedNextcloudProduct.family.indexOf('business') < 0) {
                    productFamilies.push(additionalFamily);
                }
            }
        }

        if (productFamilies.length === 1) {
            this.selectedProductFamily = selectedNextcloudProduct.family;
        }

        let currentProductTierHit = false;

        if (this.allowProductDowngrades) {
            currentProductTierHit = true;
        }

        for (const productFamily of productFamilies) {
            const ApiResponse = await this.productModel.findProducts(
                'managedapplication', productFamily, this.language
            );

            const products = (ApiResponse.response.data as ProductApi.TemplateProduct[]);
            for (const productData of products) {
                if (
                    this.allowProductDowngrades
                    && (
                        /free/.test((productData as unknown as ProductApi.Product)?.productCode)
                        || selectedNextcloudProduct.name === productData.name
                    )
                ) {
                    continue;
                }

                if (productData.name === this.currentProductDetails.name) {
                    currentProductTierHit = true;
                    continue;
                }

                if (currentProductTierHit !== true && productData.family === this.currentProductDetails.family) {
                    continue;
                }

                const drillDownProductInfo: NextcloudUpgradeProductMetaData = {
                    name: '',
                    productCode: '',
                    productCodeTemplate: productData.productCodeTemplate,
                    backupProductCode: '',
                    backupProductCodeTemplate: '',
                    billingCycle: parseInt(this.contractPeriod, 10)
                };
                drillDownProductInfo.name = productData.name;
                drillDownProductInfo.productCode = this.priceHelper.replaceBillingCycleInProductCodeTemplate(
                    productData.productCodeTemplate, this.contractPeriod
                );
                const backupProduct = productData.specificationItems
                    .find((item) => item.name === 'backupProductCode');
                drillDownProductInfo.backupProductCodeTemplate = backupProduct?.value;
                this.currentFamilyUpgradeableProductsMetaData.push(drillDownProductInfo);
            }
        }

        return this.currentFamilyUpgradeableProductsMetaData;
    };

    private _generateProductFamilySelectBoxContent = (
        productFamilyDetails: NextcloudUpgradeProductMetaData[]
    ): PromiseLike<unknown> => {
        let productFamilies = [this.currentProductDetails.family];

        if (this.allowProductDowngrades) {
            productFamilies = ProductFamiliesConst.nextcloud;
        } else {
            for (const additionalFamily of this.currentProductDetails.productChangePossibleToFamilies) {
                if (this.currentProductDetails.family.indexOf('business') < 0) {
                    productFamilies.push(additionalFamily);
                }
            }
        }

        return this.selectBoxContentCreatorNew.createSelectBoxes(
            'managedapplication', productFamilies, []
        ).then( (boxContent) => {
            void this.$timeout(() => {
                this.targetProductBoxes = ng.copy(boxContent);
                this.productFamilies = ng.copy(boxContent);
                this._setCurrentProduct(this.targetProductBoxes[0].products);

                for (let i = 1; i < productFamilies.length; i++) {
                    this._setCurrentProduct(this.targetProductBoxes[i].products);

                    for (const productDetails of this.targetProductBoxes[i].products) {
                        this.targetProductBoxes[0].products.push(productDetails);
                    }
                }

                this.targetProductBoxes[0].products = this.targetProductBoxes[0].products.filter(
                    (product) => {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                        return productFamilyDetails.some( (family) => {
                            return family.productCode === this.priceHelper.replaceBillingCycleInProductCodeTemplate(
                                product.productCodeTemplate, this.contractPeriod
                            );
                        });
                    }
                );
            });
        });
    };

    private _setCurrentProduct = (productList: ProductBoxObject[]): void => {
        const currentProduct = productList.filter(
            (product) => {
                return this.nextcloud.productCode === (
                    product.productCode
                        ? product.productCode
                        : this.priceHelper.replaceBillingCycleInProductCodeTemplate(
                            product.productCodeTemplate, this.contractPeriod
                        )
                );
            }
        );

        if (currentProduct.length >= 1) {
            this.currentProductBox = ng.copy(currentProduct[0]);
        }
    };

    private _getBackToEditPageRoute = (): string => {
        if (this.$state.current.name.split('.')[0] === 'nextcloud') {
            return 'nextcloud.id.edit';
        } else {
            return 'storage.storage-products.id.edit';
        }
    };
}

export class OrganEditPanelNextcloudUpgradeComponent implements ng.IComponentOptions {
    public bindings = {
        nextcloud: '<'
    };
    public template = require('./nextcloud-upgrade.html');
    public controller = OrganEditPanelNextcloudUpgradeController;
}
