import * as ng from 'angular';

export class MoleculeButtonUpController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public disabled: boolean;
    public up: () => void;
    public title: string;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit = () => {
        this.title = this.title || this.$translate.instant('TR_090119-004ac8_TR');
    };
}

export class MoleculeButtonUpComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        disabled: '<',
        title: '@?',
        up: '<'
    };
    public controller =  MoleculeButtonUpController;
    public template = require('./button-up.html');
}
