import * as ng from 'angular';
import './panel-edit-row-mx-records.scss';

export class MoleculePanelEditRowMxRecordsController implements ng.IController {
    public static $inject: string[] = [];

    public records: any[];
    public newRecordContent: string;
    public newRecordPriority: string;
    public $formEdit;

    $onInit() {
        if (this.records === undefined) {
            this.records = [];
        }
    }

    public addRecord = () => {
        if (!this.$formEdit.validateAll()) {
            return;
        }
        this.records.push({
            content: this.newRecordContent,
            priority: Number(this.newRecordPriority)
        });
        this.newRecordContent = '';
        this.newRecordPriority = '';
    };

    public removeRecord = (index: number) => {
        this.records.splice(index, 1);
    };

    public get disableAddButton() {
        return this.$formEdit.$invalid;
    }
}

export class MoleculePanelEditRowMxRecordsComponent implements ng.IComponentOptions {
    public bindings = {
        records: '='
    };

    public require = {
        $formEdit: '^moleculeFormEdit'
    };

    public controller = MoleculePanelEditRowMxRecordsController;
    public template = require('./panel-edit-row-mx-records.html');
}
