import * as ng from 'angular';
import { NavigationService } from '../../../../../services';
import { OrganismOverviewController, OverviewDataObject } from '../../../../organisms/overview/overview';

import './domain-row.scss';

export class MoleculeOverviewRowPanelDomainController implements ng.IController {
    public static $inject: string[] = ['navigation'];

    public overviewData: OverviewDataObject;
    public OrganismOverview: OrganismOverviewController;
    public overviewLoad: (selection: any) => any;
    public internalPaginationLimit: number;
    public itemRegistrationParams = [];

    constructor(private navigation: NavigationService) {}

    public $onInit() {
        this.overviewData.serviceItemList.map((item, index) => {
            if (this.linkToDomainRegistration(item)) {
                const domainInfo = [];
                const selectedDomains = [];

                this.itemRegistrationParams[index] = {
                    bundleId: item.bundelId,
                    domainList: domainInfo,
                    selectedDomains: selectedDomains
                };
            }
        });
    }

    public linkToEditPage = (item) => {
        return !(item.domainId === null && item.vHostId === null);
    };

    public linkToDomainRegistration = (item) => {
        return item.domainId === null && item.vHostId === null;
    };

    public clickRow = (item, itemEditOrIndex?, event?) => {
        if (this.linkToEditPage(item)) {
            this.OrganismOverview.clickRow(item, itemEditOrIndex, event);
        } else if (itemEditOrIndex !== undefined && this.linkToDomainRegistration(item)) {
            this.navigation.go('domain.domains.new', this.itemRegistrationParams[itemEditOrIndex]);
        }

        return false;
    };
}

export class MoleculeOverviewRowPanelDomainComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller = MoleculeOverviewRowPanelDomainController;
    public controllerAs = 'OverviewDomainCtrl';
    public template = require('./domain-row.html');
}
