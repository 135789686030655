import * as ng from 'angular';
import { UiRights } from '../../../configuration';
import { AuthContextService, DnsZoneModelService } from '../../../services';

export class TemplateDnsZoneEditController {
    public static $inject: string[] = ['$state', '$translate', 'dnsZoneModel'];

    public zone: any;
    public records: any[];
    public defaultNameserver: any[];
    public pageHeaderData = {
        backwardLink: this.$state.current.name.replace('.id.edit', '.overview'),
        backwardText: this.$translate.instant('TR_100119-24c031_TR'),
        panelHeaderRoute: '',
        panelHeaderRouteParams: {},
        panelIcon: 'location-arrow',
        panelTitle: '',
        productActions: []
    };
    public recordsAreStillLoading = true;
    public isSlaveZone = false;

    public faqArticleIdList: string[] = [
        'dns-delete-entry',
        'dns-setup-office',
        'dns-srv-record'
    ];

    public showAdvancedSettings: boolean;
    public showObjectId: boolean;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private dnsZoneModel: DnsZoneModelService
    ) {}

    public $onInit() {
        this.recordsAreStillLoading = true;

        if (this.zone.type === 'SLAVE') {
            this.isSlaveZone = true;
            this.records = [];
            this.recordsAreStillLoading = false;
        } else {
            this.isSlaveZone = false;
            this.dnsZoneModel.findRecords(this.zone.id, null, 201).then(
                (records) => {
                    this.records = records;
                    this.recordsAreStillLoading = false;
                }
            );
        }

        this.pageHeaderData.panelTitle = this.zone.nameUnicode;
        if (this.zone.status !== 'restorable') {
            if (AuthContextService.isGranted(UiRights.DNS_ZONES_EDIT)) {
                this.pageHeaderData.productActions.push({
                    route: this.$state.current.name.replace('.id.edit', '.id.edit.recreate'),
                    stateParams: { zoneId: this.zone.id },
                    title: this.zone.type === 'SLAVE'
                        ? this.$translate.instant('TR_080219-93a965_TR')
                        : this.$translate.instant('TR_080219-775818_TR')
                });
                if (
                    AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_SUPER_USER_READ)
                    || this.zone.accountId !== AuthContextService.account.id
                ) {
                    this.pageHeaderData.productActions.push({
                        route: this.$state.current.name.replace('.id.edit', '.id.restrictions'),
                        title: this.$translate.instant('TR_250519-7f279b_TR')
                    });
                }
            }
            if (AuthContextService.isGranted(UiRights.DNS_ZONES_MOVE)) {
                this.pageHeaderData.productActions.push({
                    route: this.$state.current.name.replace('.id.edit', '.id.edit.move'),
                    stateParams: { zoneId: this.zone.id },
                    title: this.$translate.instant('DNS.GENERAL.ACTION.MOVE')
                });
            }
        }
        if ([undefined].indexOf(this.showAdvancedSettings) >= 0) {
            this.showAdvancedSettings = false;
        }
    }
}

export class TemplateDnsZoneEditComponent implements ng.IComponentOptions {
    public bindings = {
        defaultNameserver: '<',
        dnsSecOptions: '<',
        records: '<',
        showAdvancedSettings: '<',
        showObjectId: '<',
        zone: '<'
    };
    public template = require('./zone-edit-template.html');
    public controller = TemplateDnsZoneEditController;
}
