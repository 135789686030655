import ng from 'angular';
import { Contingent, ContingentType } from '@/atomic-components/organs';
import { UiRights } from '@/configuration';
import {
    AuthContextService,
    BundleModelService,
    VhostModelService,
    WebspaceModelService
} from '@/services';
import { BundleApi, Finding, ProductApi, ViewTypes, WebhostingApi } from '@/types';

export class OrganCreateDomainContingentWebspaceSelectionController implements ng.IController {
    public static $inject: string[] = [
        '$state',
        '$timeout',
        'bundleModel',
        'vhostModel',
        'webspaceModel'
    ];

    public metadata: ViewTypes.ProductConfigDomainDomainObject;
    public noActiveManagedServerWebspaces: boolean;
    public outerContingent: Contingent;
    public showContent = {
        contingent: false,
        webspace: false
    };
    public selectedDomainList: ViewTypes.WizardSelectedDomainObject[] = [];
    public webserverIdDoesNotMatch: boolean;

    private _contingentProducts: ProductApi.Product[] = [];
    private _differentVhostWebspaceIds: boolean;
    private _existingsVhosts: WebhostingApi.VHost[] = [];
    private _referenceCheckActiv: boolean;
    private _selectedDomainList: ViewTypes.WizardSelectedDomainObject[] = [];
    private _stateParts: string[] = [];
    private _vhostWebspaceLinkedToAnotherWebspace: boolean;
    private _vhostWebspaceReference: WebhostingApi.Webspace;
    private _webspaceBundleReference: BundleApi.Bundle;
    private _webspaceOrig: WebhostingApi.Webspace;
    private _tmpOuterContingent: Contingent;
    private _orderWihtinBundleDirectly = false;
    private _orderWihtinManagedServerDirectly = false;
    private _orderWihtinWebhostingDirectly = false;
    private _orderWihtinWebspaceDirectly = false;
    private _outerContingent: Contingent;

    constructor(
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private bundleModel: BundleModelService,
        private vhostModel: VhostModelService,
        private webspaceModel: WebspaceModelService
    ) {
        this._referenceCheckActiv = false;
    }

    public $onInit(): void {
        this._stateParts = this.$state.current.name.split('.');
        this._orderWihtinBundleDirectly = this._stateParts[0].toLowerCase() === 'bundle';
        this._orderWihtinManagedServerDirectly = this._stateParts[0].toLowerCase() === 'managed-servers';
        if (this._stateParts[0].toLowerCase() === 'webhosting') {
            this._orderWihtinWebhostingDirectly = true;
            this._orderWihtinWebspaceDirectly = this._stateParts[1].toLowerCase() === 'webspaces';
        }

        this._tmpOuterContingent = ng.copy(this.outerContingent);
        this._selectedDomainList = ng.copy(this.selectedDomainList);
        this._webspaceOrig = ng.copy(this.metadata.webspace);
        this._checkContingentReference();
    }

    public $doCheck(): void {
        if (JSON.stringify(this._selectedDomainList) !== JSON.stringify(this.selectedDomainList)) {
            const domainSelectionHasChanged = this._domainSelectionHasChanged();
            this._selectedDomainList = ng.copy(this.selectedDomainList);
            if (domainSelectionHasChanged) {
                this._checkContingentReference();
            }
        }
        if (JSON.stringify(this.outerContingent) !== JSON.stringify(this._outerContingent)) {
            this._outerContingent = ng.copy(this.outerContingent);
            if (!this.showContingentPanel) {
                /**
                 * Set selectedContingent if the contingentpanel is not displayed (e.g. if only
                 * contingent is available) and only the bundle readonly panel is displayed.
                 * In this case the selectedContingent is not set by outerContingent.
                 */
                this.selectedContingent = this.outerContingent;
            }
        }
    }

    public get showLinkInformation(): boolean {
        if (
            this._orderWihtinBundleDirectly
            || this._orderWihtinWebspaceDirectly
            || this._orderWihtinManagedServerDirectly
        ) {
            return false;
        }

        return this.showContingentPanel
            && !this.hideContingentPanel;
    }

    public get isMultipleExistendVhostHintViewable(): boolean {
        return this.vhostWebspaceLinkedToAnotherWebspace
            && !this.differentVhostWebspaceIds
            && this.listOfExistingVhosts.length > 0;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set contingentProducts({}) {}
    public get contingentProducts(): ProductApi.Product[] {
        return this._contingentProducts;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set hideContingentWebspacePanel({}) {}
    public get hideContingentWebspacePanel(): boolean {
        if (
            !this.metadata.account.id
            || !this.hasDomainsInList
        ) {
            return true;
        }

        if (
            !this.differentVhostWebspaceIds
            && !this._orderWihtinBundleDirectly
            && !this._orderWihtinManagedServerDirectly
            && !this._orderWihtinWebspaceDirectly
        ) {
            return !this.showContent.contingent
                && !this.showContent.webspace;
        }

        return false;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set hideContingentPanel({}) {}
    public get hideContingentPanel(): boolean {
        if (!this.hasDomainsInList) {
            return false;
        }
        if (this._differentVhostWebspaceIds || this._vhostWebspaceLinkedToAnotherWebspace) {
            return true;
        }

        return this._orderWihtinBundleDirectly
            || this._orderWihtinManagedServerDirectly
            || this._orderWihtinWebhostingDirectly
            || this._orderWihtinWebspaceDirectly;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set hideWebspacePanel({}) {}
    public get hideWebspacePanel(): boolean {
        if (!this.hasDomainsInList) {
            return false;
        }

        if (this.metadata.selectedContingent?.type !== ContingentType.standalone) {
            return true;
        }

        return !this.showContent.webspace;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set hasDomainsInList({}) {}
    public get hasDomainsInList(): boolean {
        return this.selectedDomainList?.length > 0;
    }

    public set selectedContingent(contingent: Contingent) {
        this.metadata.selectedContingent = contingent;
    }
    public get selectedContingent(): Contingent {
        return this.metadata.selectedContingent;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set completed({}) {}
    public get completed(): boolean {
        if (this.differentVhostWebspaceIds || this.vhostWebspaceLinkedToAnotherWebspace) {
            return false;
        }

        if (this._orderWihtinBundleDirectly) {
            return !!this.metadata.bundle;
        }

        if (this._orderWihtinWebspaceDirectly) {
            return !!this.metadata.webspace;
        }

        if (this.$state.current.name.split('.')[0] === 'domain') {
            return true;
        }

        if (this._orderWihtinManagedServerDirectly) {
            if (this.noActiveManagedServerWebspaces) {
                return false;
            }

            if (!this.webserverIdDoesNotMatch) {
                return false;
            }

            return !!this.metadata.webspace;
        }

        return this._orderWihtinWebhostingDirectly;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set vhostWebspaceLinkedToAnotherWebspace({}) {}
    public get vhostWebspaceLinkedToAnotherWebspace(): boolean {
        return this._vhostWebspaceLinkedToAnotherWebspace;
    }

    public set differentVhostWebspaceIds(value) {
        void this.$timeout(() => this._differentVhostWebspaceIds = value);
    }

    public get differentVhostWebspaceIds(): boolean {
        return this._differentVhostWebspaceIds;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set referenceCheckLoading({}) {}
    public get referenceCheckLoading(): boolean {
        return this._referenceCheckActiv;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set listOfExistingVhosts({}) {}
    public get listOfExistingVhosts(): string[] {
        return this._existingsVhosts.map((vhost) => vhost.domainNameUnicode);
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set hasWebspaceObjectListRight({}) {}
    public get hasWebspaceObjectListRight(): boolean {
        return AuthContextService.isGranted(UiRights.WEB_OBJECT_LIST);
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set hasBundleObjectListRight({}) {}
    public get hasBundleObjectListRight(): boolean {
        return AuthContextService.isGranted(UiRights.BIL_BUNDLE_LIST);
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set showContingentHint({}) {}
    public get showContingentHint(): boolean {
        return this.differentVhostWebspaceIds
            || this.vhostWebspaceLinkedToAnotherWebspace;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set bundleReadOnly({}) {}
    public get bundleReadOnly(): boolean {
        if (this._orderWihtinBundleDirectly) {
            // Always show bundle readonly in bundle state
            return true;
        }

        if (this._orderWihtinWebhostingDirectly) {
            // Hide always in wehosting state
            return false;
        }

        if (this._orderWihtinManagedServerDirectly) {
            // Hide always in managed server state
            return false;
        }

        if (this._webspaceBundleReference) {
            // Show bundlereadOnly if webspaceBundleReference is set
            return true;
        }

        if (this.showContingentPanel && !this.hideContingentPanel) {
            // Contingent selection is visible
            return false;
        }

        return [undefined, null].indexOf(this.metadata.bundle) < 0;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public get showContingentPanel(): boolean {
        if (
            !this._hasSelectedDomains()
            || this._referenceCheckActiv
            || this._orderWihtinManagedServerDirectly
            || this._orderWihtinWebhostingDirectly
            || !!this._webspaceOrig
        ) {
            return false;
        }

        if (this._vhostWebspaceReference && [undefined, null, ''].indexOf(this._vhostWebspaceReference.bundleId) >= 0) {
            return false;
        }

        return [undefined, null].indexOf(this._webspaceBundleReference) >= 0;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set webspaceReadOnly({}) {}
    public get webspaceReadOnly(): boolean {
        if (
            this._orderWihtinBundleDirectly
            || !this.hasWebspaceObjectListRight
        ) {
            // In bundle state webspace selection is not need
            // or user has no web_object_list right
            return false;
        }

        if (this._orderWihtinWebspaceDirectly) {
            // hide in webspace state
            return true;
        }

        if (
            this.noActiveManagedServerWebspaces
            && this._orderWihtinManagedServerDirectly
        ) {
            // hide in managed server state and selected account has no active webspace
            return false;
        }

        if (this.webserverIdDoesNotMatch) {
            // webspace webserverId does not match with selected managed server
            return false;
        }

        if (this.bundleReadOnly && this.metadata.bundle) {
            // hide webspaceReadOnly if contingent selection is readonly and bundle is set
            return false;
        }

        if (this.selectedContingent && this.selectedContingent.type !== ContingentType.standalone) {
            // hide webspaceReadOnly if contingent selection is visible and standalone contingent is not selected
            return false;
        }

        return !this.showWebspacePanel || this.hideWebspacePanel;
    }

    public get showWebspacePanel(): boolean {
        if (
            !this.hasWebspaceObjectListRight
            || this.webserverIdDoesNotMatch
            || this._orderWihtinBundleDirectly
            || this._orderWihtinWebspaceDirectly
        ) {
            return false;
        }

        return [undefined, null].indexOf(this._vhostWebspaceReference) >= 0;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set vhostWebspaceReferenceName({}) {}
    public get vhostWebspaceReferenceName(): string {
        return this._vhostWebspaceReference?.name;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set webspaceOrig(webspace) {
        this._webspaceOrig = webspace;
    }
    public get webspaceOrig(): WebhostingApi.Webspace {
        return this._webspaceOrig;
    }

    private _checkContingentReference = (): void => {
        if (this._referenceCheckActiv) {
            // check is already running
            return;
        }

        this._existingsVhosts = [];
        if (
            !this._hasSelectedDomains()
            || !this.hasWebspaceObjectListRight
        ) {
            // do not check, when no domains are selected
            // or user has no web_object_list right
            this.differentVhostWebspaceIds = false;
            this._referenceCheckActiv = false;
            this._vhostWebspaceReference = null;
            this._webspaceBundleReference = null;
            return;
        }

        this._referenceCheckActiv = true;
        const vhostFilter = {
            subFilter: this.selectedDomainList.map(
                (domain) => ({ field: 'vhostDomainNameUnicode', value: domain.domainName })
            ) as Finding.Filter,
            subFilterConnective: 'OR'
        };

        void this.vhostModel.listWithoutPagination(null, 1, vhostFilter)
            .then(
                (vhosts) => {
                    this._existingsVhosts = ng.copy(vhosts.data);
                    this._vhostWebspaceLinkedToAnotherWebspace = false;
                    this._vhostWebspaceReference = null;

                    if (vhosts.data.length === 0) {
                        this.differentVhostWebspaceIds = false;
                        return null;
                    }

                    return this._checkVhostInDomainState(ng.copy(vhosts.data));
                }
            )
            .then(
                (vhostWebspace) => {
                    if (vhostWebspace) {
                        this._vhostWebspaceReference = vhostWebspace;
                        this.metadata.webspace = this._orderWihtinBundleDirectly
                            || this._stateParts.indexOf('webspaces') >= 1
                            ? ng.copy(this._webspaceOrig)
                            : vhostWebspace;

                        if (
                            [undefined, null, ''].indexOf(vhostWebspace.bundleId) < 0
                            && this.hasBundleObjectListRight
                        ) {
                            return this.bundleModel.listWithoutPagination(
                                null, null, { field: 'bundleId', value: vhostWebspace.bundleId }
                            );
                        }

                        // vhostWebspace is not linked to a bundle
                        return null;
                    }

                    this.metadata.webspace = ng.copy(this._webspaceOrig);
                    return null;
                }
            )
            .then((bundleResponse) => {
                if (!this._orderWihtinBundleDirectly) {
                    if (bundleResponse && bundleResponse.data.length > 0) {
                        const bundle = bundleResponse.data[0];
                        this._webspaceBundleReference = bundle;
                        const bundleHasAvailableDomainContingent = (bundle as BundleApi.Bundle)
                            .effectiveContingentUsage.some(
                                (contingentUsage) => {
                                    return (contingentUsage.productCodes).some((productCode) => {
                                        return contingentUsage.availableCapacity >= 1
                                            && productCode === this.selectedDomainList[0].domainObject.productCode;
                                    });
                                }
                            );

                        if (bundleHasAvailableDomainContingent && !this._differentVhostWebspaceIds) {
                            this.metadata.bundle = ng.copy(bundle);
                            this.outerContingent = new Contingent(bundle.id, bundle.name, ContingentType.bundle);
                            this.outerContingent.addMisc('bundle', bundle);
                            this.outerContingent.addMisc('webspace', this._vhostWebspaceReference);
                        } else {
                            this.metadata.bundle = null;
                            this.outerContingent = ng.copy(this._tmpOuterContingent);
                        }
                    } else {
                        this._webspaceBundleReference = null;
                        this.metadata.bundle = null;
                        this.outerContingent = ng.copy(this._tmpOuterContingent);
                    }
                }

                if (this._orderWihtinManagedServerDirectly) {
                    this.webserverIdDoesNotMatch = this.metadata.webspace
                        && this.metadata.webspace.webserverId !== this.metadata?.webserverRessource?.id;
                }

                this._referenceCheckActiv = false;
            });
    };

    private _checkVhostInDomainState = (vhosts: WebhostingApi.VHost[]): Promise<WebhostingApi.Webspace> => {
        switch (true) {
            case this._existingsVhosts.length === 1:
                this.differentVhostWebspaceIds = false;
                this._vhostWebspaceLinkedToAnotherWebspace = this._webspaceOrig
                    && this._webspaceOrig.id !== vhosts[0].webspaceId;
                return this._getVhostWebspace(vhosts[0].webspaceId);
            case this._existingsVhosts.length > 1:
                void this._checkVhostsLinkedToAnotherWebspace(vhosts);

                // Check if the Vhosts are linked to different webspaces
                if (this._checkVhostsHaveDifferentWebspaceIds(vhosts).length === 1) {
                    this.differentVhostWebspaceIds = false;
                    return this._getVhostWebspace(vhosts[0].webspaceId);
                }

                this.differentVhostWebspaceIds = true;
                return null;
        }
    };

    private _checkVhostsLinkedToAnotherWebspace = (
        vhosts: WebhostingApi.VHost[]
    ): Promise<WebhostingApi.Webspace> => {
        let i = 0;
        let vhostWebspaceId = null;

        do {
            const vhost = vhosts[i];
            if (this.metadata.webspace?.id !== vhost.webspaceId) {
                this._vhostWebspaceLinkedToAnotherWebspace = true;
                vhostWebspaceId = vhost.webspaceId;
            }
            i++;
        } while (
            i <= (vhosts.length - 1)
            && !this._vhostWebspaceLinkedToAnotherWebspace
        );

        if (vhostWebspaceId) {
            return this._getVhostWebspace(vhostWebspaceId)
                .then((vhostWebspace) => {
                    /**
                     * If we are in state bundle/webhosting, we must not return the vhostWebspace here,
                     * the metadata webspace reference must not be overwritten in the bundle/webhosting state
                     */
                    this._vhostWebspaceReference = vhostWebspace;
                    return this._orderWihtinBundleDirectly || this._orderWihtinWebspaceDirectly
                        ? null
                        : vhostWebspace;
                });
        }

        return null;
    };

    private _checkVhostsHaveDifferentWebspaceIds(
        vhosts: WebhostingApi.VHost[]
    ): string[] {
        const webspaceIds: string[] = [];
        for (const vhost  of vhosts) {
            if (!webspaceIds.includes(vhost.webspaceId)) {
                webspaceIds.push(vhost.webspaceId);
            }
        }

        return webspaceIds;
    }

    private _hasSelectedDomains = (): boolean => {
        return [undefined, null].indexOf(this.selectedDomainList) < 0
            && this.selectedDomainList.length > 0;
    };

    private _getVhostWebspace = (webspaceId: string): Promise<WebhostingApi.Webspace> => {
        return this.hasWebspaceObjectListRight
            ? this.webspaceModel.findOne(webspaceId)
            : null;
    };

    private _domainSelectionHasChanged = (): boolean => {
        let domainSelectionHasChanged = false;

        if (this.selectedDomainList.length !== this._selectedDomainList.length) {
            domainSelectionHasChanged = true;
        } else {
            domainSelectionHasChanged = this.selectedDomainList.some(
                (domain, i: number) => {
                    return domain.domainNameAce !== this._selectedDomainList[i].domainNameAce;
                }
            );
        }

        return domainSelectionHasChanged;
    };
}

export class OrganCreateDomainContingentWebspaceSelectionComponent implements ng.IComponentOptions {
    public bindings = {
        _contingentProducts: '<?contingentProducts',
        completed: '=',
        metadata: '=',
        selectedDomainList: '<',
        outerContingent: '<?'
    };
    public template = require('./domain-contingent-webspace-selection.html');
    public controller =  OrganCreateDomainContingentWebspaceSelectionController;
}
