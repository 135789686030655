import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import { AccountModelService, AuthContextService } from '../../../../../services';
import { DdnsModelService, DnsZoneModelService } from '../../../../../services/dns';
import * as Types from '../../../../../types';

export class OrganNewPanelDdnsHostGeneralController {
    public static $inject: string[] = ['$translate', 'accountModel', 'authContext', 'dnsZoneModel', 'ddnsModel'];

    public hostname: string;
    public comment: string;
    public accountId: string;
    public hasSubaccounts = false;
    public disableAccountSelector = false;
    public hostnameDropdownItems: any[];
    public domainSearchString = '';
    public callbackOnChange: (model: string) => void;

    public existingDdnsHostnames: any[];
    public validationErrorList: any[] = [];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService,
        private authContext: AuthContextService,
        private dnsZoneModel: DnsZoneModelService,
        private ddnsModel: DdnsModelService
    ) {
        if (this.authContext.isGranted(UiRights.ACC_SUBACCOUNT_LIST)) {
            accountModel.listSubaccountsWithoutPagination(1, 1,
                { field: 'AccountId', value: this.authContext.user.accountId }
                )
                .then(
                    (result) => this.hasSubaccounts = result.data.length > 0
                );
        } else {
            this.hasSubaccounts = false;
        }
    }

    public async $onInit() {
        this.createHostnameDropdownItems(this.accountId);
        this.existingDdnsHostnames = await this.ddnsModel.getExistingDdnsNames();
    }

    public createHostnameDropdownItems = (accountId: string) => {
        this.onHostSelected(this.hostname);
        const subFilterParts = [
            {
                field: 'accountId',
                value: accountId
            },
            {
                field: 'zoneStatus',
                value: 'active'
            }
        ] as Types.Finding.Filter[];

        if ([undefined, null, ''].indexOf(this.domainSearchString) < 0) {
            subFilterParts.push(
                {
                    field: 'zoneName',
                    value: `*${this.domainSearchString}*`
                }
            );
        }

        const filter: Types.Finding.Filter = {
            subFilter: subFilterParts,
            subFilterConnective: 'AND'
        };

        this.dnsZoneModel.list(undefined, undefined, filter).then(
            (reply) => {
                this.hostnameDropdownItems = reply.data.map(
                    (zone) => {
                        return {
                            name: zone.nameUnicode,
                            value: zone.name
                        };
                    }
                );
            }
        );
    };

    public onHostSelected = (model: string) => {
        this.validationErrorList = [];
        if ([undefined, null].indexOf(this.callbackOnChange) < 0) {
            this.callbackOnChange(model);
        }
        if (this.existingDdnsHostnames?.indexOf(model) >= 0) {
            this.validationErrorList.push({
                text: this.$translate.instant('TR_190220-9c1436_TR')
            });
        }
    };
}

export class OrganNewPanelDdnsHostGeneralComponent implements ng.IComponentOptions {
    public bindings = {
        accountId: '=',
        hostname: '=',
        comment: '=',
        callbackOnChange: '<',
        validationErrorList: '=?',
        disableAccountSelector: '<?'
    };
    public template = require('./ddns-host-general.html');
    public controller = OrganNewPanelDdnsHostGeneralController;
}
