import * as ng from 'angular';
import {
    ArticleSearchModelService, NavigationService
} from '../../../../../services';

import './helpdesk-search-results.scss';

export class MoleculeHelpdeskSearchResultsController implements ng.IController {
    public static $inject: string[] = [
        'articleSearchModel',
        'navigation'
    ];

    public searchResults: any;
    public searchValue: string;
    public idList: string[] = [];

    constructor(
        private articleSearchModel: ArticleSearchModelService,
        private navigation: NavigationService
    ) {}

    public $onInit() {
        this._search();
    }

    public goToSearch = () => {
        if (this.searchValue === '') {
            this.searchValue = null;
        }

        this.navigation.go('helpdesk.dashboard', {
            q: this.searchValue
        });
    };

    public highlightTitle = (title: string) => {
        const search = this.searchValue.toString();
        const match = title.search(new RegExp(search, 'i'));

        if (match === -1) {
            return title;
        }

        const matchString = title.slice(match, (match + search.length));

        const newTitle = title.slice(0, match)
            + '<b>' + matchString + '</b>' + title.slice((match + search.length), title.length);

        return newTitle;
    };

    public searchMore = () => {
        this._search(10);
    };

    private _search = (results?: number) => {
        results = results || 5;
        if (this.searchValue.length > 0) {
            this.searchResults = this.articleSearchModel.searchArticles(this.searchValue.toString(), results)
            .then((reply) => {
                this.searchResults = reply.data;

                if (this.searchResults.error === undefined) {
                    this.searchResults.forEach((article) => {
                        article.title = this.highlightTitle(article.title);
                    });
                }
            });
        } else {
            this.searchResults = {};
        }
    };
}

export class MoleculeHelpdeskSearchResultsComponent implements ng.IComponentOptions {
    public bindings = {
        searchValue: '<'
    };
    public controller = MoleculeHelpdeskSearchResultsController;
    public template = require('./helpdesk-search-results.html');
}
