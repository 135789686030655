import * as ng from 'angular';
import * as q from 'q';

function b64toBlob(
    b64Data: string,
    contentType: string = 'application/octet-stream',
    sliceSize: number = 512
) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
}

export class FileSaverService {
    public static $inject: string[] = ['$timeout'];

    constructor(
        private $timeout: ng.ITimeoutService
    ) {}

    public saveAs(blob, name): ng.IPromise<boolean> {
        const defer: q.Deferred<boolean> = q.defer();

        if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, name);

            this.$timeout(
                () => defer.resolve(true),
                500
            );

            return defer.promise;
        }

        const link = document.createElement('a');
        if ('download' in link) {
            const url = URL.createObjectURL(blob);

            link.setAttribute('href', url);
            link.setAttribute('target', '_blank');
            link.setAttribute('download', name);

            link.dispatchEvent(new MouseEvent('click'));

            // In Mozilla Firefox wird die URL Freigegeben bevor der Download initiert wird. Uncool.
            this.$timeout(
                () => {
                    // Dies ist notwendig, dasmit der Blob aufgeräumt werden kann und nicht weiter Speicher verbraucht
                    URL.revokeObjectURL(url);

                    this.$timeout(
                        () => defer.resolve(true),
                        500
                    );
                },
                50
            );

            return defer.promise;
        }
    }

    public saveBase64AsPdf: (b64Data: any, name: string) => ng.IPromise<boolean>
    = (b64Data, name) => this.saveAs(b64toBlob(b64Data, 'application/pdf'), name);
}
