import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';

export class TemplateBundleDeleteController {
    public static $inject: string[] = ['$translate', 'authContext'];

    public modifier = '';
    public pageHeaderData: PanelHeaderData;
    public bundle;
    public currentAction;
    public userPanelRight;

    public faqArticleIdList: string[] = [
        'webspace-backup-create',
        'webspace-backup-restore'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink:  'bundle.id.dashboard',
            backwardText: this.$translate.instant('TR_090119-a32485_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: {},
            panelIcon: 'box-alt',
            panelTitle: this.$translate.instant('TR_090119-4aa7dc_TR')
        };

        if ([undefined, null].indexOf(this.currentAction) === -1 && this.currentAction === 'restore') {
            this.pageHeaderData.panelTitle = this.$translate.instant('BUNDLE.RESTORE.TITLE');
        }

        this.userPanelRight = {
            deletion: {
                delete: this.authContext.isGranted(UiRights.BIL_BUNDLE_DELETE),
                editPanelButton: this.authContext.isGranted(UiRights.BIL_BUNDLE_DELETE)
            }
        };
    };
}

export class TemplateBundleDeleteComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<',
        currentAction: '@'
    };
    public controller = TemplateBundleDeleteController;
    public controllerAs = '$TemplateBundleDeleteCtrl';
    public template = require('./bundle-delete-template.html');
}
