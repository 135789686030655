import ng from 'angular';
import { PanelHeaderData } from '@/atomic-components/molecules/panels/panel-header/panel-header';
import { SHORT_TIMEOUT_REFRESH_INTERVAL } from '@/configuration';
import { MachineModelService } from '@/services';

export class TemplateMachineManagedDeleteController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'machineModel',
    ];

    public pageHeaderData: PanelHeaderData;
    public machine: any;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private machineModel: MachineModelService,
    ) {}

    public $onInit() {
        this.pageHeaderData = {
            backwardLink: 'managed-servers.id.dashboard',
            backwardText: this.$translate.instant('TR_220319-1ac2e3_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: { machineId: this.machine.id},
            panelIcon: 'adjust',
            panelTitle: this.machine.name
        };
    }

    public cancelMachineChanges = async () => {
        try {
            if ([undefined, null].indexOf(this.machine) < 0) {
                this.machine = await this.machineModel.findOneById(this.machine.id);
            }
        } catch (error) {
            this.$timeout(this.cancelMachineChanges, SHORT_TIMEOUT_REFRESH_INTERVAL);
        }
    };

}

export class TemplateMachineManagedDeleteComponent implements ng.IComponentOptions {
    public bindings = {
        machine: '<'
    };
    public controller = TemplateMachineManagedDeleteController;
    public template = require('./machine-managed-delete-template.html');
}
