import { OrchestratorRobotService } from '@/services';
import { sleep } from '@/services/helpers/helpers-without-framework';

// Todo typing
type OrchestrationJob = any;


interface JobUpdater {
    updateOrchestrationJobs(jobs: OrchestrationJob[]): void;
}

export class OrchestrationPoll {
    public finalStatus: string[] = ['successful', 'failed', 'canceled']

    constructor(
        private orchestratorRobot: OrchestratorRobotService,
        private jobUpdater: JobUpdater,
    ) {}

    public poll = async (orderId: string) => {
        const jobFilter = { field: 'jobOrderId', value: orderId };
        let pollAgain = false;
        do {
            const jobs = await this.orchestratorRobot.listJobs(jobFilter);
            this.jobUpdater.updateOrchestrationJobs(jobs.response.data);
            pollAgain = false;
            for (const order of jobs.response.data) {
                if (this.finalStatus.indexOf(order.status) === -1) {
                    pollAgain = true;
                }
            }
            await sleep(2000);
        } while (pollAgain)
    }
}
