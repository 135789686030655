import ng from 'angular';

import { DnsApi } from '@/types';
import { EditPanelRight, EditPanelStatus } from '@/atomic-components';
import { AlertManagerService, AuthContextService, DnsTemplateModelService, NavigationService } from '@/services';

export class OrganismNewFormDnsTemplateController {
    public static $inject: string[] = ['$translate', 'alertManager', 'dnsTemplateModel', 'navigation'];

    public panelRightSettings: EditPanelRight;
    public initialStatus = EditPanelStatus.ACTIVE;
    public dnsTemplate: { name: string };
    public ownerAccountId: string;
    public recordTemplate: DnsApi.RecordTemplate[];
    public zone: DnsApi.Zone;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private dnsTemplateModel: DnsTemplateModelService,
        private navigation: NavigationService
    ) {}

    public $onInit(): void {
        this.zone = {};
        this.dnsTemplate = {
            name: ''
        };
        this.ownerAccountId = AuthContextService.account.id;
        this.recordTemplate = [
            { id: 'template1', type: 'A', ttl: 86400, name: '', content: '##IPV4##' },
            { id: 'template2', type: 'MX', ttl: 86400, name: '', content: 'mail.##DOMAIN##', priority: 0 }
        ];
        this.panelRightSettings = {
            editPanelButton: false
        };
    }

    public save = (): Promise<boolean> => {
        const records = this.recordTemplate.map((record) => this._normalizeRecord(record));
        return this.dnsTemplateModel.create(this.dnsTemplate, records, this.ownerAccountId).then(
            (result) => {
                if (result.status === 'success') {
                    this.alertManager.success(this.$translate.instant('DNS.MESSAGE.TEMPLATE.NEW.SUCCESS'));
                    void this.navigation.go('dns.templates.overview');
                }

                return Promise.reject(false);
            },
            () => Promise.reject(false)
        );
    };

    private _normalizeRecord = (record: DnsApi.Record): DnsApi.Record => {
        if ([undefined, null].indexOf(record.id) < 0 && record.id.substring(0, 8) === 'template') {
            delete record.id;
        }
        record.ttl = +record.ttl;
        if (record.name.lastIndexOf('.') === record.name.length - 1) {
            record.name = record.name.substring(0, record.name.length - 1);
        }

        if (['mx', 'srv'].indexOf(record.type.toLowerCase()) === -1) {
            delete record.priority;
        } else {
            record.priority = +record.priority;
        }

        return record;
    };
}

export class OrganismNewFormDnsTemplateComponent implements ng.IComponentOptions {
    public bindings = {};
    public controllerAs = '$newFormOrganism';
    public controller = OrganismNewFormDnsTemplateController;
    public template = require('./dns-template-new.html');
}
