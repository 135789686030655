import * as ng from 'angular';
import * as Types from '../../types';
import { ModelHelper } from './../model-helper';
import { BillingRobotService } from './robot';

export class PaymentModelService {
    public static $inject: string[] = ['billingRobot'];

    constructor(
        private billingRobot: BillingRobotService
    ) {}

    public list = (limit?: number, page?: number, filter?: Types.Finding.Filter, sort?: Types.Finding.SortOptions) => {
        if ([undefined, null].indexOf(sort) >= 0) {
            sort = {field: 'addDate', order: 'DESC'};
        }

        return this.billingRobot.listPayments(filter, limit, page, sort)
            .then(ModelHelper.returnListResponse) as ng.IPromise<Types.BillingApi.FindPaymentsResult>;
    };
}
