import * as ng from 'angular';
import * as q from 'q';

import { AlertManagerService } from '../../../../../services/alert-manager';
import { ApiErrorModel } from '../../../../../services/errors/api-error-model';
import { ManagedApplicationRobotService } from '../../../../../services/managed-application';
import { NavigationService } from '../../../../../services/navigation';
import * as Types from '../../../../../types';
import { EditPanelStatus } from '../../../../molecules';

export class OrganismEditFormNextcloudRenameController {
    public static $inject: string[] = [
        '$state',
        '$translate',
        'apiErrorModel',
        'managedApplicationRobot',
        'navigation'
    ];

    public nextcloud: Types.ManagedApplicationApi.Nextcloud;
    public newNextcloud: Types.ManagedApplicationApi.Nextcloud;
    public initialStatus: EditPanelStatus = EditPanelStatus.ACTIVE;

    private _currentlySendingRequest = false;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private managedApplicationRobot: ManagedApplicationRobotService,
        private navigation: NavigationService
    ) {}

    public $onInit() {
        this.newNextcloud = ng.copy(this.nextcloud);
    }

    public save = () => {
        this._currentlySendingRequest = true;

        this.managedApplicationRobot.nextcloudUpdate(this.newNextcloud).then(
            (res) => {
                this._currentlySendingRequest = false;
                this.navigation.go(
                    this.$state.current.name.replace('rename', 'edit'),
                    null,
                    { reload: true }
                );
                this.alertManager.success(this.$translate.instant('TR_171019-e2271d_TR'));
            },
            (error) => {
                this._currentlySendingRequest = false;
                this.alertManager.error(this.$translate.instant('TR_171019-caf1c7_TR'));
                return q.reject(error);
            }
        );
    };

    public get disableSaveButton() {
        return this.nextcloud.name === this.newNextcloud.name || this._currentlySendingRequest;
    }
}

export class OrganismEditFormNextcloudRenameComponent implements ng.IComponentOptions {
    public bindings = {
        nextcloud: '<'
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormNextcloudRenameController;
    public template = require('./nextcloud-rename.html'); // tslint:disable-line:max-line-length
}
