import { AuthContextService, DnsTemplateModelService } from '../../../../../services';

import * as ng from 'angular';
import * as Types from '../../../../../types';

export class OrganEditPanelDnsSettingsController implements ng.IController {
    public static $inject: string[] = ['$translate', 'authContext', 'dnsTemplateModel'];

    public saveCallbackFunction: () => void;
    public panelRightSettings;
    public subAccountData: Types.AccountApi.Account;

    private templates: { [id: string]: Types.DnsApi.Template } = {};

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        public dnsTemplateModel: DnsTemplateModelService
    ) {}

    public get templateName() {
        if ([undefined, null, ''].indexOf(this.subAccountData.dnsSettings.defaultTemplateId) >= 0) {
            return '';
        }

        if ([undefined, null].indexOf(this.templates[this.subAccountData.dnsSettings.defaultTemplateId]) < 0) {
            return this.templates[this.subAccountData.dnsSettings.defaultTemplateId].name;
        }

        this.dnsTemplateModel.findOne(this.subAccountData.dnsSettings.defaultTemplateId)
        .then(
            (result) => {
                this.templates[this.subAccountData.dnsSettings.defaultTemplateId] = result;
            }
        );
    }

    /* tslint:disable-next-line:no-empty */
    public cancelCallbackFunction: () => void = () => {};

    public cancel = () => {
        if ([undefined, null].indexOf(this.cancelCallbackFunction) === -1) {
            this.cancelCallbackFunction();
        }
    };

    public listTemplates = (
        limit?: number,
        page?: number,
        filters?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions,
        timeout?: number
    ) => {
        const filterObject: any = {
            subFilter: [{
                field: 'accountId',
                value: this.authContext.account.id
            }],
            subFilterConnective: 'AND'
        };

        if ([undefined, null].indexOf(filters) >= 0) {
            filterObject.subFilter.push({
                field: 'templateName',
                value: '*'
            });
        } else {
            filterObject.subFilter.push(filters);
        }

        return this.dnsTemplateModel.list(limit, page, filterObject, sort, timeout)
        .then(
            (res) => {
                if (res.data.every((template) => [undefined, null, ''].indexOf(template.id) < 0)) {
                    res.data.unshift(
                        {
                            id: '',
                            name: this.$translate.instant('TR_190319-e0b486_TR')
                        }
                    );
                }

                return res;
            }
        );
    };
}

export class OrganEditPanelDnsSettingsComponent implements ng.IComponentOptions {
    public bindings = {
        cancelCallbackFunction: '<',
        panelRightSettings: '<',
        saveCallbackFunction: '<',
        subAccountData: '<'
    };

    public template = require('./dns-settings-panel.html');
    public controller = OrganEditPanelDnsSettingsController;
}
