import * as ng from 'angular';
import { AlertManagerService } from '../../../../../../services';
import { OrganismOverviewController } from '../../../../../organisms/overview/overview';

export class MoleculePanelOverviewHeaderMultiUntieFromTemplateActionController implements ng.IController {
    public static $inject: string[] = ['alertManager', '$translate'];

    public OrganismOverview: OrganismOverviewController;
    public confirmHint = false;

    private serviceName: string;

    constructor(
        private alertManager: AlertManagerService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public abort = () => {
        this.OrganismOverview.overviewModel.activeMassAction = false;
    };

    public untie = () => {
        this.OrganismOverview.submitMassAction({methode: 'untieFromTemplateSubmit'})
            // todo - show request message in view
            .then(result => {
                if (result) {
                    this.alertManager.success(this.$translate.instant('TR_100119-139c51_TR'));
                    this.abort();
                    this.OrganismOverview.onLoad();
                }
            }, (e) => {
                console.error('error', e);
            });
    };

}

export class MoleculePanelOverviewHeaderMultiUntieFromTemplateActionComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        serviceName: '<'
    };
    public controller =  MoleculePanelOverviewHeaderMultiUntieFromTemplateActionController;
    public template = require('./multi-untie-from-template-action.html');
}
