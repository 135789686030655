import * as ng from 'angular';

import { DatabaseModelService } from '../../../../../services/database/database-model';
import * as Types from '../../../../../types';

export class OrganViewPanelAccessLinkedToDatabasesHintController {
    public static $inject: string[] = ['databaseModel'];

    public access;
    public minLinkedObjectsToDisplay = 2;

    private _accessDatabaseListLoading = true;
    private _accessDatabaseList: Types.DatabaseApi.Database[] = [];

    constructor(
        private databaseModel: DatabaseModelService
    ) {}

    public $onInit() {
        this._getAccessDatabaseList();
    }

    public get showAccessDatabaseHint() {
        return this._accessDatabaseList.length >= this.minLinkedObjectsToDisplay;
    }

    public set accessDatabaseList({}) {} // tslint:disable-line:no-empty
    public get accessDatabaseList() {
        if (this._accessDatabaseList?.length === 0) {
            return false;
        }

        return this._accessDatabaseList.join(', ');
    }

    public set accessDatabaseListLoading({}) {} // tslint:disable-line:no-empty
    public get accessDatabaseListLoading() {
        return this._accessDatabaseListLoading;
    }

    private _getAccessDatabaseList = () => {
        let accessId: string;
        if (this.access?.userId) {
            accessId = this.access.userId;
        } else if (this.access?.id) {
            accessId = this.access.id;
        } else {
            return;
        }

        this._accessDatabaseListLoading = true;
        this.databaseModel.listWithoutPagination(
            null,
            1,
            { field: 'databaseAccessesUserId', value: accessId }
        ).then(
            (databases) => {
                this._accessDatabaseList = databases.data.map(
                    (database: Types.DatabaseApi.Database) => database.name);
                this._accessDatabaseListLoading = false;
            }
        );
    };
}

export class OrganViewPanelAccessLinkedToDatabasesHintComponent implements ng.IComponentOptions {
    public bindings = {
        access: '<',
        minLinkedObjectsToDisplay: '<?'
    };
    public controller = OrganViewPanelAccessLinkedToDatabasesHintController;
    public template = require('./access-linked-to-databases-hint.html');
}
