export class ResourceHelperService {

    public static $inject: string[] = [];

    public getIPv6ShortNotation = (input: string) => {
        if ([undefined, null, ''].indexOf(input) >= 0) {
            return '';
        }
        return input.replace(/\b(?:0+:){2,}/, ':');
    };
}
