import * as ng from 'angular';

type DateType = { day: number; month: number; year: number};
export class DateWrapper {
    private _day: number;
    private _month: number;
    private _year: number;
    private _dateObj: Date;

    constructor(date?: DateType | Date) {
        if (date) {
            this._dateObj = new Date();
            if (date instanceof Date) {
                this._day = date.getUTCDate();
                this._month = date.getUTCMonth() + 1;
                this._year = date.getUTCFullYear();
                this._dateObj.setUTCFullYear(this._year);
                this._dateObj.setUTCMonth(this._month - 1);
                this._dateObj.setUTCDate(this._day);
            } else {
                this._day = date.day;
                this._month = date.month;
                this._year = date.year;
                this._dateObj.setUTCFullYear(this._year);
                this._dateObj.setUTCMonth(this._month - 1);
                this._dateObj.setUTCDate(this._day);
            }
            this._dateObj.setUTCHours(0, 0, 0, 0);
        }
    }

    public get year() {
        return this._year;
    }
    public set year(year: number) {
        this._year = year;
    }

    public get month() {
        return this._month;
    }
    public set month(month: number) {
        this._month = month;
    }

    public get day() {
        return this._day;
    }
    public set day(day: number) {
        this._day = day;
    }

    public get date() {
        return {
            day: this._day,
            month: this._month,
            year: this._year
        };
    }
    public set date(date: any) {
        this._day = date.day;
        this._month = date.month;
        this._year = date.year;
    }

    public get dateString() {
        if (
            [undefined, null].indexOf(this._day) >= 0
            || [undefined, null].indexOf(this._month) >= 0
            || [undefined, null].indexOf(this._year) >= 0
        ) {
            return undefined;
        }
        return `${this._year}-${this._month < 10 ? '0' : ''}${this._month}-${this._day < 10 ? '0' : ''}${this._day}`;
    }
    public set dateString(value: string) {
        const parts = value.split('-');
        if (parts.length !== 3) {
            return;
        }
        if (parts[2].indexOf('T') >= 0) {
            const dayParts = parts[2].split('T');
            if (dayParts.length !== 2) {
                return;
            }
            parts[2] = dayParts[0];
        }
        this._year = Number(parts[0]);
        this._month = Number(parts[1]);
        this._day = Number(parts[2]);
    }

    public dateStringDMY = () => {
        if (
            [undefined, null].indexOf(this._day) >= 0
            || [undefined, null].indexOf(this._month) >= 0
            || [undefined, null].indexOf(this._year) >= 0
        ) {
            return undefined;
        }
        return `${this._day < 10 ? '0' : ''}${this._day}.${this._month < 10 ? '0' : ''}${this._month}.${this._year}`;
    };

    public get dateObj() {
        if (
            [undefined, null].indexOf(this._day) >= 0
            || [undefined, null].indexOf(this._month) >= 0
            || [undefined, null].indexOf(this._year) >= 0
        ) {
            return undefined;
        }
        this._dateObj.setUTCFullYear(this._year);
        this._dateObj.setUTCMonth(this._month - 1);
        this._dateObj.setUTCDate(this._day);
        return this._dateObj;
    }
    public set dateObj(value: Date) {
        this._dateObj = value;
        this._day = value.getUTCDate();
        this._month = value.getUTCMonth() + 1;
        this._year = value.getUTCFullYear();
    }

    public setToday = () => {
        this.dateObj = new Date();
    };

    public isToday = () => {
        const today = new DateWrapper();
        today.setToday();
        return this.day === today.day && this.month === today.month && this.year === today.year;
    };

    public toUnixTimestamp = () => {
        return Date.parse(this.dateObj.toISOString());
    };
}
