import * as ng from 'angular';
import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { ArticleSearchModelService } from '../../../services/helpdesk';

export interface SearchResultsObject {
    errorMessage: string;
    foundType: 'any' | 'error' | 'none' | 'empty';
    resultCount: number;
    input: string;
}

export class TemplateHelpdeskController implements ng.IController {
    public static $inject: string[] = [
        '$stateParams',
        '$timeout',
        '$translate',
        'articleSearchModel'
    ];

    public templateName: string;
    public pageHeaderData: PanelHeaderData;

    public searchValue: string;
    public results: object;
    public updatedResults: object;
    public query: null|string;

    constructor(
        protected $stateParams: ng.ui.IStateParamsService,
        protected $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private articleSearchModel: ArticleSearchModelService
    ) {}

    public $onInit() {
        this.pageHeaderData = {
            backwardLink: null,
            backwardText: null,
            panelHeaderRoute: 'helpdesk.dashboard',
            panelHeaderRouteParams: null,
            panelIcon: 'life-ring',
            panelTitle: this.$translate.instant('MENU.MAIN.HELPDESK'),
            reloadState: true
        };

        if (this.query !== null) {
            this.searchValue = this.query;
        }

        this._setHelpdeskBacklink();
        this.updateResults();
    }

    public updateResults = () => {
        this.updatedResults = this.results;
    };

    public get queryExists() {
        this.query = this.$stateParams.q;

        return this.query !== null;
    }

    private _setHelpdeskBacklink = () => {
        if ([null, undefined].indexOf(this.$stateParams.level1) >= 0) { // dashboard
            return;
        } else if ([null, undefined].indexOf(this.$stateParams.level2) >= 0) { // top level category
            this.pageHeaderData.backwardLink = 'helpdesk.dashboard';
            this.pageHeaderData.backwardText = this.$translate.instant('TR_110119-453eaa_TR');
            return;
        } else {
            let parent = '';
            let parentsParent = '';
            let lastLevel = null;
            for (let i = 1; i <= 6; i++) {
                if (this.$stateParams['level' + i] !== null) {
                    if (i > 1) {
                        parentsParent = ng.copy(parent);
                        parent = parent + '/' + this.$stateParams['level' + (i - 1)];
                    }
                    lastLevel = i;
                }
            }
            parentsParent = parentsParent + '/';
            parent = parent + '/';
            this.articleSearchModel.getCurrentCategory(parentsParent, parent).then((category: any) => {
                this.pageHeaderData.backwardLink = 'helpdesk';
                this.pageHeaderData.backwardText = this.$translate.instant('TR_080719-00533a_TR') + ' '
                    + category.title;
                const stateParams = ng.copy(this.$stateParams);
                stateParams['level' + lastLevel] = null;
                this.pageHeaderData.panelHeaderRouteParams = stateParams;
            });
        }
    };
}

export class TemplateHelpdeskComponent implements ng.IComponentOptions {
    public bindings = {
        templateName: '@'
    };
    public controller = TemplateHelpdeskController;
    public template = require('./helpdesk.html');
}
