import {
    TemplateBillingPricelistAbstractOverviewComponent,
    TemplateBillingPricelistAbstractOverviewController
} from '../abstract/pricelist-abstract-template';

export class TemplateBillingPricelistPromotionsOverviewController
    extends TemplateBillingPricelistAbstractOverviewController {
    protected options = {
        panelTitleTranslationId: /* translationId */ 'TR_090119-7df7fd_TR',
        simpleFilterField: 'name'
    };
}

export class TemplateBillingPricelistPromotionsOverviewComponent
    extends TemplateBillingPricelistAbstractOverviewComponent {
    public controller = TemplateBillingPricelistPromotionsOverviewController;
    public template = require('./pricelist-promotions-template.html');
}
