import { Validator } from './abstract-validator';

export class ValidateNumber extends Validator {
    private allowComma: boolean;
    private requireInteger: boolean;
    private minPrecision: number;
    private maxPrecision: number;

    constructor(
        private $translate: ng.translate.ITranslateService,
        allowComma: boolean,
        requireInteger?: boolean,
        minPrecision?: number,
        maxPrecision?: number
    ) {
        super();
        this.allowComma = allowComma;
        this.requireInteger = requireInteger || false;
        this.minPrecision = minPrecision || null;
        this.maxPrecision = maxPrecision || null;

    }

    public validate = (value) => {
        if (this.allowComma && typeof value === 'string' && (value.indexOf(',') >= 0)) {
            value = value.replace(/,/gi, '.');
        }

        const valueString = value;
        value = Number(value);

        // Make sure that the typecast worked. Returns no error, since this validation is supposed to be done elsewhere
        if (isNaN(value)) {
            return [{ text: this.$translate.instant('TR_170119-643579_TR') }];
        }

        if (this.requireInteger && (value % 1 !== 0)) {
            return [{ text: this.$translate.instant('TR_170119-6f7dc8_TR') }];
        }

        if (this.minPrecision !== null && valueString.split('.')[1].length < this.minPrecision) {
            return [
                {
                    text: this.$translate.instant(
                        /* translationId */ 'TR_170119-f3d7ab_TR',
                        { minPrecision: String(this.minPrecision) }
                    )
                }
            ];
        }

        if (this.maxPrecision !== null && valueString.split('.')[1].length > this.maxPrecision) {
            return [
                {
                    text: this.$translate.instant(
                        /* translationId */ 'TR_170119-20999f_TR',
                        { maxPrecision: String(this.maxPrecision) }
                    )
                }
            ];
        }

        return [];
    };
}
