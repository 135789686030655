import * as ng from 'angular';
import { LinkListOverviewItem } from '../../atomic-components/molecules/links';
import {
    AccountFilterField,
    TextFilterField
} from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { UiRights } from '../../configuration';
import { OverviewModel } from '../abstract';
import { AuthContextService } from '../auth-context';
import { ModelHelper } from '../model-helper';
import { RequestCancellerService } from '../request-canceller';
import { ResourceRobotService } from '../resource';

export class MachinePoolsOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', 'authContext', 'resourceRobot']
    );

    public defaultFilterFields: string[] = ['poolName'];
    public objectType = 'resourcePool';
    public service = 'resourcePool';
    public idField = 'id';

    protected listCallbacks = [];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private resourceRobot: ResourceRobotService
    ) {
        super(RequestCanceller);

        this.listCallbacks = [{callback: this.listPools}];
    }

    public idFilter = (id: string) => ({ field: 'id', value: id });

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('poolName', this.$translate.instant('f1f4cbdd-fe9d-4d45-b653-2f0f757400f4')),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR'))
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }

    public actionLinks = (params: { [param: string]: any }): LinkListOverviewItem[] => {
        return [
            {
                text: this.$translate.instant('TR_150119-e4c469_TR'),
                route: 'machine.pools.new',
                isGranted: this.authContext.isGranted(UiRights.RES_POOL_CREATE)
            }
        ];
    };

    public listPools = (limit?, page?, filters?, sort?) => {
        sort = sort || {
            field: 'lastChangeDate',
            order: 'DESC'
        };
        page = page || 1;

        return this.resourceRobot.listPools(filters, limit, page, sort, {}, this.authContext.account.id)
            .then(ModelHelper.returnListResponse)
            .then((result) => {
                return result;
            });
    };
}
