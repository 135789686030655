import * as ng from 'angular';
import { ProductHelperService, ProductsRobotService } from '@/services';

export class OrganEditPanelMailboxStorageController {
    public static $inject: string[] = ['productsRobot'];

    public cancel;
    public $editFormOrganism;
    public panelRight;
    public maxStorage: number;

    constructor(
        private productsRobot: ProductsRobotService
    ){}

    public $onInit(){
        this.productsRobot
            .productFind(this.$editFormOrganism.mailbox.productCode)
            .then(
                (result) => {
                    const response = result.response;

                    this.maxStorage = ProductHelperService.wrapSpecificationItems(
                        response.specificationItems
                    ).maxStorage?.intValue;
                }
            );
    }
}

export class OrganEditPanelMailboxStorageComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormMailbox'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./mailbox-storage.html');
    public controller = OrganEditPanelMailboxStorageController;
}
