import * as ng from 'angular';
import { ValidateNotEmpty } from '../../../../../services';
import { FormInputModelOptions } from '../../../../atoms/form-elements';

export class MoleculeInputTextNotEmptyController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public validationErrorList = [];
    public value: string;
    public placeholder: string;
    public callbackOnChange: (arg0: any) => any;
    public callbackOnBlur: (arg0: any) => any;
    public callbackOnPaste: (paste: any) => any;
    public callbackOnEsc: (event?: {[key: string]: any }) => any;
    public callbackOnBlurParams: any;
    public disabled: boolean;
    public disableRandomisedId: boolean;
    public modelOptions: FormInputModelOptions;
    public registerToForm: boolean;
    public validationInstructions: any[];
    public customCallbackOnEnter: () => {};
    public doNotSaveOnFormEnter: boolean;
    public inputId: string;
    public callbackOnClear: (arg0: any) => any;
    public callbackOnClearParams: any;
    public clearable: boolean;
    public catchFocus: boolean;

    private validator;
    private validationErrorListOuter: any[] = [];
    private _validationErrorListOuter: any[] = [];

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this._validationErrorListOuter = ng.copy(this.validationErrorListOuter);
        this.validator = new ValidateNotEmpty(this.$translate);

        this.validationInstructions = [
            {
                instructions: null,
                validator: this.validator
            }
        ];
    }

    public $doCheck() {
        if (!ng.equals(this.validationErrorListOuter, this._validationErrorListOuter)) {
            this.validator.updateValidatorErrors(this.validationErrorListOuter);
            this.validationErrorList = this.validator.validate(this.value);
            this._validationErrorListOuter = ng.copy(this.validationErrorListOuter);
        }
    }

    public onPaste = (paste) => {
        if (this.callbackOnPaste !== undefined) { // If paste
            this.callbackOnPaste(paste);
        }
    };
}

export class MoleculeInputTextNotEmptyComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnBlur: '<',
        callbackOnBlurParams: '<',
        callbackOnChange: '<',
        callbackOnPaste: '<?',
        callbackOnClear: '<',
        callbackOnClearParams: '<',
        callbackOnEsc: '<?',
        catchFocus: '<',
        clearable: '<',
        customCallbackOnEnter: '<',
        disabled: '<',
        disableRandomisedId: '<?',
        doNotSaveOnFormEnter: '<',
        inputId: '@?',
        modelOptions: '<',
        placeholder: '@',
        registerToForm: '<',
        validationErrorList: '=?',
        validationErrorListOuter: '=?',
        value: '='
    };
    public controller = MoleculeInputTextNotEmptyController;
    public template = require('./input-text-with-validation-wrapper.html');
}
