import { CharPoolsConst } from '@/configuration';
import ng from 'angular';

import * as abstractValidator from './abstract-validator';
import * as lengthValidator from './validate-length';

export class ValidatePassword extends abstractValidator.Validator {
    public static $inject: string[] = ['$translate'];

    private minLength: number;
    private maxLength: number;
    private illegalCharacters: string;
    private illegalValues: string[];
    private characterPools: string[];
    private combinedCharacterPools: string;
    private minCharacterPoolsUsed: number;
    private allowOtherCharacters: boolean;
    private requireOtherCharacters: boolean;
    private allowEmpty: boolean;

    constructor(
        private $translate: ng.translate.ITranslateService,
        illegalCharacters: string,
        characterPools: string[],
        minCharacterPoolsUsed: number,
        allowOtherCharacters: boolean,
        requireOtherCharacters: boolean,
        minLength?: number,
        maxLength?: number,
        allowEmpty?: boolean,
        illegalValues?: string[]
    ) {
        super();

        this.minLength = null;
        this.maxLength = null;

        if (minLength) {
            this.minLength = minLength;
        }

        if (maxLength) {
            this.maxLength = maxLength;
        }

        if ([null, undefined].indexOf(allowEmpty) < 0) {
            this.allowEmpty = allowEmpty;
        } else {
            this.allowEmpty = false;
        }

        this.illegalCharacters = illegalCharacters;
        this.illegalValues = illegalValues;
        this.characterPools = characterPools;
        this.combinedCharacterPools = this.characterPools.join('');

        this.minCharacterPoolsUsed = minCharacterPoolsUsed;

        if (this.minCharacterPoolsUsed > characterPools.length) {
            console.error('minCharacterPoolsUsed ist größer als die Anzahl der CharacterPools.');
        }

        this.allowOtherCharacters = allowOtherCharacters;
        this.requireOtherCharacters = requireOtherCharacters;
    }

    public validate = (value: string) => {
        const reply = [];

        // prüfe ob Passwort lang genug ist.
        if (!value || value === '') {
            return this.allowEmpty
                ? []
                : [{ text: this.$translate.instant('TR_170419-e416eb_TR') }];
        }

        this.checkMinLength(value, reply);
        this.checkMaxLength(value, reply);
        this.checkCharacterPools(value, reply);
        this.checkIllegalCharacters(value, reply);
        this.checkIllegalValues(value, reply);

        return reply;
    };

    private checkMinLength = (value: string, reply: any[]) => {
        if (this.minLength) {
            const checkMinLength = new lengthValidator.ValidateMinLength(this.$translate, this.minLength);

            if (checkMinLength.validate(value).length > 0) {
                reply.push({ text: this.$translate.instant('TR_290819-6296ab_TR', { minLength: this.minLength }) });
            }
        }
    };

    private checkMaxLength = (value: string, reply: any[]) => {
        if (this.maxLength) {
            const checkMaxLength = new lengthValidator.ValidateMaxLength(this.$translate, this.maxLength);

            if (checkMaxLength.validate(value).length > 0) {
                reply.push({ text: this.$translate.instant('TR_290819-c83027_TR', { maxLength: this.maxLength }) });
            }
        }
    };

    private checkCharacterPools = (value: string, reply: any[]) => {
        // prüfe ob genug CharacterPools verwendet wurden
        let nCharacterPoolsUsed = 0;
        let poolWasUsed = false;

        for (const characterPool of this.characterPools) {
            poolWasUsed = false;

            for (const char of value) {
                if (characterPool.indexOf(char) >= 0) {
                    poolWasUsed = true;
                }
            }

            if (poolWasUsed) {
                nCharacterPoolsUsed++;
            }
        }

        if (nCharacterPoolsUsed < this.minCharacterPoolsUsed) {
            reply.push({ text: this.generateComplexityErrorMessage() });
        } else if (this.requireOtherCharacters) {
            let specialCharacterFound = false;

            for (const char of value) {
                if (this.combinedCharacterPools.indexOf(char) < 0) {
                    specialCharacterFound = true;
                }
            }

            if (!specialCharacterFound) {
                reply.push(
                    {
                        text: this.$translate.instant(
                            /* translationId */ 'TR_290819-0989b7_TR',
                            { value: this.characterPools[(this.characterPools.length - 1)]}
                        )
                    }
                );
            }
        }
    };

    private checkIllegalCharacters = (value: string, reply: any[]) => {
        // prüfe ob verbotene Zeichen vorhanden sind
        let illegalCharactersFound = '';

        for (const character of value) {
            if (this.illegalCharacters.indexOf(character) >= 0 && illegalCharactersFound.indexOf(character) < 0) {
                illegalCharactersFound += character;
            }

            if (
                !this.combinedCharacterPools.includes(character)
                && !this.allowOtherCharacters
                && illegalCharactersFound.indexOf(character) < 0
            ) {
                illegalCharactersFound += character;
            }
        }

        if (illegalCharactersFound.length > 0) {
            reply.push({
                text: this.$translate.instant(/* translationId */ 'TR_290819-9ac746_TR', {
                    illegalCharacterList: illegalCharactersFound
                })
            });
        }
    };

    private checkIllegalValues = (value: string, reply: any[]) => {
        if ([undefined, null].indexOf(this.illegalValues) >= 0 || this.illegalValues.length < 1) {
            return;
        }

        if (this.illegalValues.indexOf(value) >= 0) {
            reply.push({ text: this.$translate.instant('TR_130919-9758b1_TR') });
        }
    };

    private generateComplexityErrorMessage = () => {
        const numberNames = [
            /* translationId */ 'TR_290819-b3b7d3_TR',
            /* translationId */ 'TR_290819-f39f36_TR',
            /* translationId */ 'TR_290819-aa5770_TR',
            /* translationId */ 'TR_290819-6eb4f2_TR',
            /* translationId */ 'TR_290819-abe13a_TR',
            /* translationId */ 'TR_290819-8bc090_TR'
        ];

        let nRequiredPools = this.minCharacterPoolsUsed;
        let nCharacterPools = this.characterPools.length;
        let poolNames = '';

        if (this.requireOtherCharacters) {
            nCharacterPools++;
            nRequiredPools++;
        }

        for (const pool of this.characterPools) {
            poolNames += (poolNames.length > 0 ? ', ' : '') + this.getCharacterPoolName(pool);
        }

        return this.$translate.instant(/* translationId */ 'TR_290819-1ecadf_TR', {
            numberOfAvailableCharacterPools: this.$translate.instant(numberNames[nCharacterPools]),
            numberOfRequiredCharacterPools: this.$translate.instant(numberNames[nRequiredPools]),
            poolNames: poolNames
        });
    };

    private getCharacterPoolName = (pool: string): string => {
        let poolName = pool;

        for (const key of Object.keys(CharPoolsConst)) {
            if (CharPoolsConst[key as keyof typeof CharPoolsConst].value === pool) {
                const translationId = CharPoolsConst[key as keyof typeof CharPoolsConst].translationId;

                if (key.indexOf('specialSigns') >= 0) {
                    poolName = this.$translate.instant(translationId, { value: pool });
                } else {
                    poolName = this.$translate.instant(translationId);
                }

                return poolName;
            }
        }

        return poolName;
    };
}
