import * as ng from 'angular';
import './panel-wizard.scss';

export class MoleculePanelWizardController implements ng.IController {
    public static $inject: string[] = ['$transclude'];

    public panelIcon: string;
    public panelTitle: string;
    public modifier: string;

    constructor(
        private $transclude
    ) {}

    public get buttonsTranscludeFilled() {
        return this.$transclude.isSlotFilled('buttonsPanelBody');
    }

}

export class MoleculePanelWizardComponent implements ng.IComponentOptions {
    public bindings = {
        modifier: '@',
        panelIcon: '@?',
        panelTitle: '@?'
    };
    public transclude = {
        buttonsPanelBody : '?buttonsPanelBody',
        buttonsPanelHead : '?buttonsPanelHead',
        panelBody : 'panelBody'
    };
    public controller =  MoleculePanelWizardController;
    public template = require('./panel-wizard.html');
}
