/* eslint-disable */

import * as ng from 'angular';
import './panel-save.scss';
import { MoleculeFormEditController } from '@/atomic-components';

export class MoleculePanelSaveController implements ng.IController {
    public static $inject: string[] = [];

    public $editForm: MoleculeFormEditController;
    public buttonText: string;
    public callbackOnCancel: () => void;
    public disabled = false;
    public triggerSaveFunction = true;
    public name: string;

    private callbackOnSave: () => void;

    public get disableSaveButton(): boolean {
        return !this.$editForm.$valid || this.disabled;
    }

    public callSaveFunction = (): void => {
        if (this.callbackOnSave !== undefined) {
            this.callbackOnSave();
        }
        if (this.triggerSaveFunction) {
            this.$editForm.save();
        }
    };

    public get showErrorMessage(): boolean {
        return this.$editForm.$invalid;
    }
}

export class MoleculePanelSaveComponent implements ng.IComponentOptions {
    public bindings = {
        buttonText: '@',
        callbackOnSave: '<?',
        callbackOnCancel: '<?',
        disabled: '<',
        name: '@?',
        triggerSaveFunction: '<?'
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public controller =  MoleculePanelSaveController;
    public template = require('./panel-save.html');
}
