import { Validator } from './abstract-validator';
import { ValidateIllegalCharacters } from './validate-illegal-characters';

import * as ng from 'angular';

export class ValidateDns extends Validator {
    constructor(
        private $translate: ng.translate.ITranslateService,
        private allowEmpty?: boolean
    ) {
        super();
    }

    public validate = (value: string) => {
        if (value === undefined || value.length === 0) {
            if (this.allowEmpty) {
                return [];
            }

            return [{ text: this.$translate.instant('TR_090119-2705b2_TR') }];
        }

        if (value.indexOf('.') < 0) {
            return [{ text: this.$translate.instant('TR_170119-6d9ca1_TR') }];
        }

        const illegalCharacterValidator = new ValidateIllegalCharacters('@ /#', this.$translate);
        const reply = illegalCharacterValidator.validate(value);
        const parts = value.split('.');

        if (parts[parts.length - 1].length < 2) {
            // text: 'Keine gültige Top Level Domain'
            reply.push({ text: this.$translate.instant('TR_170119-b75d4d_TR') });
        }

        if (value.indexOf('..') >= 0) {
            reply.push({ text: this.$translate.instant('TR_170119-c6cfbd_TR') });
        }

        for (const part of parts) {
            if (part.length > 63) {
                reply.push({ text: this.$translate.instant('TR_170119-37bde6_TR') });
            }

            if (part[0] === '-' || part[part.length - 1] === '-') {
                reply.push({ text: this.$translate.instant('TR_170119-430873_TR') });
            }
        }

        return reply;
    };
}
