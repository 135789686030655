import { AuthContextService, CountryListService } from '../';
import { FormDropDownItems } from '../../atomic-components/molecules/forms/';
import { UiGenderConst, UiLanguageSwitchConst } from '../../configuration';

import * as ng from 'angular';

export class SystemHelperService {
    public static $inject: string[] = ['$filter', '$translate', 'countryList'];

    private _countryListCache: any[] = [];
    private _languageDropdownItems: FormDropDownItems[] = [];
    private _genderDropdownItems: FormDropDownItems[] = [];

    constructor(
        private $filter: ng.IFilterService,
        private $translate: ng.translate.ITranslateService,
        private countryList: CountryListService
    ) {
        this._setLanguageDropdownItems();
        this._setGenderDropdownItems();
        this.getCountryRawList();
    }

    public getGenderDropdownItems = () => {
        return this._genderDropdownItems;
    };

    public getLanguageDropdownItems = () => {
        return this._languageDropdownItems;
    };

    public getReadableGender = (value: string) => {
        let gender = '-';
        this._genderDropdownItems.some((item) => {
            if (item.value === value) {
                gender = item.name;
                return true;
            }

            return false;
        });
        return gender;
    };

    public getReadableLanguage = (languageValue: string) => {
        let language = '';
        this._languageDropdownItems.some((item) => {
            if (item.value === languageValue) {
                language = item.name;
                return true;
            }
            return false;
        });

        return language;
    };

    public getReadableCountry = (countryValue: string) => {
        if (!countryValue) return '';
        let country;
        this._countryListCache.some((countryItem) => {
            if (countryItem.value.toLowerCase() === countryValue.toLowerCase()) {
                country = countryItem.name;
                return true;
            }
            return false;
        });
        return country;
    };

    public getReadableDate = (dateValue: any) =>  {
        if ([undefined, null, ''].indexOf(dateValue) === -1) {
            let dateString;
            if (typeof dateValue === 'string') {
                dateString = dateValue;
            } else {
                dateString = dateValue._year + '-' + dateValue._month + '-' + dateValue._day;
            }

            return this.$filter('date')(dateString, 'dd.MM.yyyy');
        }
        return '-';
    };

    public getCountryRawList = () => {
        const countries = this.countryList.getRawList();
        this._countryListCache = [];
        for (const key of Object.keys(countries)) {
            this._countryListCache.push({
                name: countries[key],
                value: key
            });
        }
        return this._countryListCache;
    };

    public getPropertyOfObject<T, K extends keyof T>(o: T, propertyName: K): T[K] {
        return o[propertyName]; // o[propertyName] is of type T[K]
    }

    private _setGenderDropdownItems = () => {
        for (const key in UiGenderConst) {
            if (UiGenderConst.hasOwnProperty(key)) {
                this._genderDropdownItems.push({
                    name: this.$translate.instant(UiGenderConst[key].translationId),
                    value: key
                });
            }
        }
    };

    private _setLanguageDropdownItems = () => {
        for (const key in UiLanguageSwitchConst) {
            if (UiLanguageSwitchConst.hasOwnProperty(key)) {
                this._languageDropdownItems.push({
                    name: this.$translate.instant(UiLanguageSwitchConst[key].translationId),
                    value: key
                });
            }
        }
    };
}
