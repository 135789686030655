import * as ng from 'angular';
import { UserSettingsManagerService } from '../../../../../services';
import { PanelType } from '../../../../molecules';

export class OrganEditPanelDisplaySettingsController implements ng.IController {
    public static $inject: string[] = ['$q', 'userSettingsManager'];
    public overviewUiDisplaySettings: any;
    private _origDisplaySettings: any;

    constructor(
        private $q: ng.IQService,
        private userSettingsManager: UserSettingsManagerService
    ) {}

    public $onInit = () => {
        this._origDisplaySettings = ng.copy(this.overviewUiDisplaySettings);
    };

    public get panelType() {
        return PanelType.MISCELLANEOUS_ACTION;
    }

    public cancelDisplaySettings = () => {
        this.overviewUiDisplaySettings = ng.copy(this._origDisplaySettings);
    };

    public saveDisplaySettings = () => {
        return this.userSettingsManager.saveDisplaySettings(this.overviewUiDisplaySettings);
    };
}

export class OrganEditPanelDisplaySettingsComponent implements ng.IComponentOptions {
    public bindings = {
        overviewUiDisplaySettings: '<'
    };
    public template = require('./display-settings.html');
    public controller = OrganEditPanelDisplaySettingsController;
}
