import * as ng from 'angular';
import { SslRobotService } from './robot';
import { SslOrderModelService } from './ssl-order-model'
;

export class CsrDecoderService {
    public static $inject: string[] = ['sslRobot'];

    private loadPromise;
    constructor(
        private sslRobot: SslRobotService
    ) {}

    public decode = (csrKey, productCode?) => {
        return this.sslRobot.csrDecode(csrKey, productCode)
        .then(result => result.response);
    };
}
