import * as ng from 'angular';
import { JobStatusObject } from '../../../../../services';
import { OrchestratorRobotService } from '../../../../../services/orchestrator/robot';
import { WizardObject } from '../../../../molecules/wizard/wizard-supervisor/wizard-supervisor';
import { OrganismCreateBundleController } from './';
import { SHORT_TIMEOUT_REFRESH_INTERVAL } from './../../../../../configuration/system';

export class OrganismCreateBundleOrderController {
    // tslint:disable:no-console
    public static $inject: string[] = ['localInterval', 'orchestratorRobot' ];

    public serviceObject: WizardObject;
    public apiOrderRequestError = false;
    public someCreateJobInSupportStatus: boolean;
    public someCreateJobInErrorStatus: boolean;
    public allJobsDone: boolean;
    public jobStatus = {
        bundle: null,
        databases: null,
        domain: null,
        vhost: null,
        webspace: null,
        zone: null
    };
    public $createOrganism: OrganismCreateBundleController;
    public jobOrderId: string;
    public faqArticleIdList: string[] = [
        'webspace-ftp-access',
        'domain-register-new',
        'ssl-activate'
    ];

    private _checkingJobStatus: ng.IPromise<any>;
    private _maxiNumberOfJobChecks = 30;
    private _jobChecksPerformed = 0;
    private _interval: number = SHORT_TIMEOUT_REFRESH_INTERVAL;

    constructor(
        private localInterval: ng.IIntervalService,
        private orchestratorRobot: OrchestratorRobotService
    ) {}

    public $onChanges(changes: any) {
        if (changes.jobOrderId !== undefined && [undefined, null].indexOf(changes.jobOrderId.currentValue) < 0) {
            this._startCheckingJobStatus();
        }
    }

    public $onDestroy() {
        this._stopCheckingJobStatus();
    }

    public get showErrorHint() {
        return this.apiOrderRequestError
            || this.someCreateJobInSupportStatus
            || this.someCreateJobInErrorStatus;
    }

    private _startCheckingJobStatus = () => {
        if (this._checkingJobStatus !== undefined
            || this.apiOrderRequestError // to not start interval, because of api order request failed
        ) {
            return;
        }
        this._setServiceStartStatus();
        this._checkingJobStatus = this.localInterval(this._checkJobStatus, this._interval);
    };

    private _stopCheckingJobStatus = (jobs?: JobStatusObject[]) => {
        if (this._checkingJobStatus !== undefined) {
            this.localInterval.cancel(this._checkingJobStatus);
            this._checkingJobStatus = undefined;

            if (jobs !== undefined && this._jobChecksPerformed >= this._maxiNumberOfJobChecks) {
                /**
                 *  Re-check all job statuses if the job status check was reached because of the maximum status check.
                 *  This is necessary, for example, to recognize statuses that are still in progress and display the
                 *  completion note (successful or error).
                **/
                this.allJobsDone = jobs.every(
                    (job: JobStatusObject) => ['successful', 'done', 'inProgress'].indexOf(job.status) >= 0
                );
            }

            for (const job in this.jobStatus) {
                if (job === 'databases') {
                    /**
                     *  jobStatus of databases must be specially processed
                     *  since more one nutr an object can be created
                     **/
                    if ([undefined, null].indexOf(this.jobStatus.databases) < 0) {
                        this.jobStatus.databases.map((databaseJob: JobStatusObject) => {
                            this._checkJobHasToAborted(databaseJob);
                        });
                    }
                } else {
                    this._checkJobHasToAborted(this.jobStatus[job]);
                }
            }
            this._jobChecksPerformed = 0;
        }
    };

    private _checkJobHasToAborted = (jobStatus: JobStatusObject) => {
        /**
         *  Run through all jobs and if a job does not have one
         *  of the below listed statuses, set the status to Cancelled
         **/
        if ([undefined, null].indexOf(jobStatus) < 0
            && [undefined, null].indexOf(jobStatus) < 0
            && ['done', 'successful', 'error', 'support', 'inProgess'].indexOf(jobStatus.status) < 0
        ) {
            jobStatus.status = 'abort';
        }
    };

    private _checkJobStatus = () => {
        const filter = { field: 'jobOrderId', value: this.jobOrderId };
        return this.orchestratorRobot.listJobs(filter).then(
            (jobList) => {
                const jobs = jobList.response.data;
                this._jobChecksPerformed++;
                this.someCreateJobInSupportStatus = jobs.some((job: JobStatusObject) => job.status === 'support');
                this.someCreateJobInErrorStatus = jobs.some(
                    (job: JobStatusObject) => ['support', 'error'].indexOf(job.status) >= 0
                );

                this.allJobsDone = jobs.every(
                    (job: JobStatusObject) => ['successful', 'done'].indexOf(job.status) >= 0
                );

                // set all job status
                if (this.serviceObject.settings.createData.database.createDatabase) {
                    // clear/set database jobStatus list
                    this.jobStatus.databases = [];
                }
                jobs.map((job: JobStatusObject) => {
                    if (job.objectType.toLowerCase() === 'database') {
                        // jobType = database |
                        this.jobStatus.databases.push(job);
                    } else {
                        // all other jobTypes
                        this.jobStatus[job.objectType.toLowerCase()] = job;
                    }
                });

                if (this._jobChecksPerformed >= this._maxiNumberOfJobChecks
                    || this.someCreateJobInErrorStatus
                    || this.someCreateJobInSupportStatus
                    || this.allJobsDone
                ) {
                    this._stopCheckingJobStatus(jobs);
                }
            }
        );
    };

    private _setServiceStartStatus = () => {
        this.jobStatus = {
            bundle: null,
            databases: this.serviceObject.settings.createData.database.createDatabase ? null : undefined,
            domain: this.serviceObject.settings.createData.domain !== null ? null : undefined,
            vhost: null,
            webspace: null,
            zone: null
        };
    };
}

export class OrganismCreateBundleOrderComponent implements ng.IComponentOptions {
    public require = {
        $createOrganism: '^organismCreateBundle'
    };
    public bindings = {
        apiOrderRequestError: '<',
        jobOrderId: '<',
        serviceObject: '='
    };
    public template = require('./bundle-order.html');
    public controller = OrganismCreateBundleOrderController;
}
