import ng from 'angular';
import { OrganismOverviewController, OverviewDataObject } from '@/atomic-components/organisms';
import { WebhostingApi } from '@/types';

export class MoleculeOverviewRowPanelWebhostingVhostsPhpLegacyController implements ng.IController {
    public static $inject: string[] = ['$state'];

    public overviewData: OverviewDataObject;
    public OrganismOverview: OrganismOverviewController;
    public overviewLoad: (selection: unknown) => unknown;
    public internalPaginationLimit: number;
    public sidePartEntry: string;
    public stateGlobals: unknown;
    public service: string;

    constructor(
        private $state: ng.ui.IStateService
    ) {}

    public $onInit(): void {
        this.sidePartEntry = this.$state.current.name.split('.')[0];
        this.stateGlobals = this.$state.$current.locals.globals;
    }

    public isWebsite = (item: WebhostingApi.VHost): boolean => {
        return item.locations.some(
            (location) => location.matchType === 'default' && location.locationType === 'generic'
        );
    };

    public get showAccounts(): boolean {
        return this.OrganismOverview.globals.showAccounts as boolean;
    }
}

export class MoleculeOverviewRowPanelWebhostingVhostsPhpLegacyComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelWebhostingVhostsPhpLegacyController;
    public controllerAs = 'OverviewWebhostingVhostsCtrl';
    public template = require('./webhosting-vhost-php-legacy-row.html');
}
