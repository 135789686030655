import { AlertManagerService, DomainModelService } from '@/services';
import { FormDropDownItems, MoleculeFormEditController, PanelType } from '../../../../molecules';

import * as ng from 'angular';
import * as Types from '@/types';

import './domain-dnssec.scss';

export class OrganEditPanelDomainDnssecController {
    public static $inject: string[] = ['$translate', 'alertManager', 'domainModel'];

    public types: FormDropDownItems[] = [
        { name: 'ZSK (256)', value: 256 },
        { name: 'KSK (257)', value: 257 },
    ];

    public algorithms: FormDropDownItems[] = [
        { name: 'RSA/SHA-1 (5)', value: 5 },
        { name: 'DSA-NSEC3-SHA1 (6)', value: 6 },
        { name: 'RSASHA1-NSEC3-SHA1 (7)', value: 7 },
        { name: 'RSA/SHA-256 (8)', value: 8 },
        { name: 'RSA/SHA-512 (10)', value: 10 },
        { name: 'ECDSA Curve P-256 with SHA-256 (13)', value: 13 },
        { name: 'ECDSA Curve P-384 with SHA-384 (14)', value: 14 },
    ];

    public panelRightSettings: any;
    public panelTypeOverride: PanelType = PanelType.MISCELLANEOUS_ACTION;
    public originalDomain: Types.DomainApi.Domain;
    public domain: Types.DomainApi.Domain;
    public entryAccessors: any[] = [];

    private $editFormMolecule: MoleculeFormEditController;
    private validatorIndex: number;
    private originalEntries: any;

    public userCanEdit: boolean = false;

    private _removeKeyCallbacks: any = {};

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private domainModel: DomainModelService
    ) {}

    public $onInit = () => {
        this.originalEntries = JSON.parse(JSON.stringify(this.domain.dnsSecEntries));
        this.validatorIndex = this.$editFormMolecule.registerValidator(this);
        this.resetDnssec();
    };

    public $onDestroy = () => {
        this.$editFormMolecule.unregisterValidator(this.validatorIndex);
    };

    public get getPanelTitle() {
        const pre = 'DNSSEC';

        if (this.domain.dnsSecEntries.length > 0) {
            return pre + ` (${this.domain.dnsSecEntries.length})`;
        } else {
            return pre;
        }
    }

    public validate = () => {
        return !this.domain.dnsSecEntries.some(
            (dnssecEntry) => {
                return ['flags', 'algorithm', 'publicKey'].some(
                    (key) => [undefined, null, ''].indexOf(dnssecEntry.keyData[key]) >= 0
                );
            }
        );
    };

    public addKey = () => {
        const entry = {
            comment: '',
            keyData: {
                algorithm: null,
                flags: null,
                protocol: 3,
                publicKey: '',
            },
        };

        const accessor = {};

        ['flags', 'algorithm', 'publicKey']
        .forEach(
            (key) => Object.defineProperty(
                accessor,
                key,
                {
                    get: () => entry.keyData[key],
                    set: (value) => {
                        entry.keyData[key] = value;
                        this.$editFormMolecule.validate(this.validatorIndex);
                    },
                }
            )
        );

        this.domain.dnsSecEntries.push(entry);
        this.entryAccessors.push(accessor);

        this.$editFormMolecule.validate(this.validatorIndex);
    };

    public removeKey = (index: number) => {
        if ([undefined, null].indexOf(this._removeKeyCallbacks[index]) >= 0) {
            this._removeKeyCallbacks[index] = () => {
                this.domain.dnsSecEntries.splice(index, 1);
                this.entryAccessors.splice(index, 1);
                this.$editFormMolecule.validate(this.validatorIndex);
            };
        }

        return this._removeKeyCallbacks[index];
    };

    public resetDnssec = () => {
        this.domain.dnsSecEntries = JSON.parse(JSON.stringify(this.originalEntries));

        this.entryAccessors = this.domain.dnsSecEntries
        .map(
            (entry) => {
                const accessor = {};

                ['flags', 'algorithm', 'publicKey']
                .forEach(
                    (key) => Object.defineProperty(
                        accessor,
                        key,
                        {
                            get: () => entry.keyData[key],
                            set: (value) => {
                                entry.keyData[key] = value;
                                this.$editFormMolecule.validate(this.validatorIndex);
                            },
                        }
                    )
                );

                return accessor;
            }
        );
    };

    public saveCallback = () => {
        const removedKeys = this.originalDomain.dnsSecEntries
        .filter(
            (originalEntry) => this.domain.dnsSecEntries.every(
                (currentEntry) => originalEntry.comment !== currentEntry.comment
                || originalEntry.keyTag !== currentEntry.keyTag
                || ['algorithm', 'flags', 'protocol', 'publicKey']
                .some(
                    (attribute) => originalEntry.keyData[attribute] !== currentEntry.keyData[attribute]
                )
            )
        );

        const newKeys = this.domain.dnsSecEntries
        .filter(
            (currentEntry) => this.originalDomain.dnsSecEntries.every(
                (originalEntry) => originalEntry.comment !== currentEntry.comment
                || originalEntry.keyTag !== currentEntry.keyTag
                || ['algorithm', 'flags', 'protocol', 'publicKey']
                .some(
                    (attribute) => originalEntry.keyData[attribute] !== currentEntry.keyData[attribute]
                )
            )
        );

        return this.domainModel.dnsSecUpdate(this.domain.name, newKeys, removedKeys).then(
            (response) => {
                this.alertManager.success(this.$translate.instant('TR_050219-15457a_TR'));
                return response;
            }
        );
    };

    public get disableEditButton() {
        return !this.userCanEdit;
    }
}

export class OrganEditPanelDomainDnssecComponent implements ng.IComponentOptions {
    public bindings = {
        domain: '<',
        originalDomain: '<',
        panelRightSettings: '<panelRight',
        userCanEdit: '<?'
    };
    public require = {
        $editFormMolecule: '^moleculeFormEdit'
    };
    public controller = OrganEditPanelDomainDnssecController;
    public template = require('./domain-dnssec.html');
}
