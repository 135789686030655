import ng from 'angular';
import { UiRights } from '@/configuration';
import { AuthContextService } from '@/services';
import { Breadcrumb, BreadcrumbItem } from '../breadcrumb';
import { BreadcrumbTopicModel, TopicCases } from './topic-model';

export class BreadcrumbSslTopicModel extends BreadcrumbTopicModel {
    public static $inject: string[] = BreadcrumbTopicModel.$inject.concat([]);

    public readonly topicName = 'ssl';
    public readonly specialTopicCases: TopicCases;

    /**
     *   List of route parts, which are excluded from mail class generalRoutes methode
     */
    public readonly excludeGeneralRouteParts = ['overview', 'edit', 'new'];

    private sslActionDropdownItems: BreadcrumbItem[] = [];

    constructor(protected $state: ng.ui.IStateService, protected $translate: ng.translate.ITranslateService) {
        super($state, $translate);

        this.sslActionDropdownItems = [
            { part: 'overview', route: 'ssl.certificates.overview', isGranted: UiRights.SSL_CERTIFICATES_LIST },
            { part: 'jobs', route: 'ssl.certificates.jobs', isGranted: UiRights.SSL_JOBS_LIST },
            { part: 'new', route: 'ssl.certificates.new', isGranted: UiRights.SSL_ORDER }
        ];

        this.specialTopicCases = {
            details: this.jobDetailsRoute,
            jobs: () => this.getProductActionDropdown(this.sslActionDropdownItems),
            new: () => this.getProductActionDropdown(this.sslActionDropdownItems),
            overview: () => this.getProductActionDropdown(this.sslActionDropdownItems)
        };
    }

    public getFirstLevelData = (): Breadcrumb[] => {
        this.breadcrumbs.push({
            active: false,
            type: 'text',
            value: this.$translate.instant('MENU.MAIN.PRODUCTS.LABEL')
        });

        if (AuthContextService.isGranted(UiRights.SSL_CERTIFICATES_LIST)) {
            this.breadcrumbs = this.breadcrumbs.concat(
                this.getServiceListDropdownBox({
                    active: this.steps.length === 0,
                    isGranted: UiRights.SSL_CERTIFICATES_LIST,
                    name: this.$translate.instant('TR_090119-6aba62_TR'),
                    route: 'ssl.dashboard',
                    routeParams: {},
                    step: 'ssl'
                })
            );
        }

        return this.breadcrumbs;
    };

    public getSslAction = (): Breadcrumb[] => {
        const bundleId = this.globals.bundle !== undefined ? this.globals.bundle.id : null;
        const vHostId = this.globals.vHost !== undefined ? this.globals.vHost.id : null;
        this.breadcrumbs = [
            {
                active: true,
                disabled: false,
                isGranted: UiRights.SSL_CERTIFICATES_LIST,
                route: 'bundle.id.domains.id.ssl',
                routeParams: { bundleId: bundleId, domainId: vHostId, vhost: 'vhost' },
                title: this.$translate.instant('TR_080119-c057db_TR'),
                type: 'text',
                value: this.$translate.instant('TR_080119-c057db_TR')
            }
        ];

        return this.breadcrumbs;
    };
}
