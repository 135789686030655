import * as ng from 'angular';
import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { UiRights } from '../../../configuration';
import { AuthContextService, ProductsModelService } from '../../../services';

export class TemplateWebspaceStorageController {
    public static $inject: string[] = ['$state', '$translate', 'authContext', 'productsModel'];

    public minimumStorage = 1024;
    public modifier = '';
    public pageHeaderData: PanelHeaderData;
    public webspace: any;

    public userPanelRight;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private productsModel: ProductsModelService
    ) {}

    public $onInit = () => {
        const stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';

        this.pageHeaderData = {
            backwardLink: stateInMachine
                ? 'managed-servers.id.webspaces.id.edit'
                : 'webhosting.webspaces.id.edit',
            backwardText: this.$translate.instant('TR_140119-e915b4_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: stateInMachine
                ?  {
                    machineId: this.$state.$current.locals.globals.machine.id,
                    webspaceId: this.$state.$current.locals.globals.webspace.id
                }
                : null,
            panelIcon: 'save',
            panelTitle: this.$translate.instant('TR_STORAGE_TR')
        };

        this.userPanelRight = {
            storageQouta: {
                editPanelButton: this.authContext.isGranted(UiRights.WEB_WEBSPACE_EDIT),
                overrideStorageQuota: this.authContext.isGranted(UiRights.WEB_WEBSPACE_EDIT)
            }
        };

        this.minimumStorage = parseInt(this.webspace.storageQuotaIncluded + '', 10);
    };
}

export class TemplateWebspaceStorageComponent implements ng.IComponentOptions {
    public bindings = {
        webspace: '<'
    };
    public controller = TemplateWebspaceStorageController;
    public template = require('./webspace-storage-template.html');
}
