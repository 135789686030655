import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels';

export class TemplateDdnsCredentialsOverviewController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;
    public bundle: any;
    public overviewServiceData: any;
    public expertView: boolean;
    public overviewModel: any;
    public faqArticleIdList: string[] = [
        'dynamic-dns'
    ];

    private simpleFilter: any;
    private extendedFilters: any;
    private overviewUiSettings: any;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        if ([undefined, null].indexOf(this.simpleFilter) === -1) {
            this.expertView = this.overviewUiSettings.expertView || this.simpleFilter.show;
        } else {
            this.expertView = this.overviewUiSettings.expertView || false;
        }
        this.pageHeaderData = {
            backwardLink: 'dns.dashboard',
            backwardText: this.$translate.instant('TR_100119-a3c01f_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: 'location-arrow',
            panelTitle: this.$translate.instant('TR_200319-418d09_TR')
        };
        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter
        };
    }

    public expandWrapper = (toggle: any) => {
        this.expertView = toggle.expand;
    };
}

export class TemplateDdnsCredentialsOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public controller = TemplateDdnsCredentialsOverviewController;
    public controllerAs = 'DdnsCredentialsCtrl';
    public template = require('./ddns-credentials-overview-template.html');
}
