import * as ng from 'angular';
import * as q from 'q';
import { UiRights } from '../../configuration';
import {
    AuthContextService,
    CacheService,
    DdnsCredentialsOverviewModel,
    DdnsHostnameOverviewModel,
    DdnsModelService,
    FilterCacheService,
    NavigationService,
    RedirectOnNotFoundCallback,
    RestrictionHelperService,
    UserSettingsManagerService
} from '../../services';
import {
    DnsJobModelService,
    DnsJobOverviewModel,
    DnsTemplateModelService,
    DnsTemplateOverviewModel,
    DnsZoneModelService,
    DnsZoneOverviewModel,
    NameserverSetModelService,
    NameserverSetOverviewModel
} from '../../services/dns';
import * as stateUtilityFunctions from '../../services/state-utility-functions';

export const DNS_ROUTES = [
    {
        name: 'dns',
        state: {
            abstract: true,
            parent: 'authenticated',
            url: '/dns'
        }
    },
    {
        name: 'dns.ddns',
        state: {
            abstract: true,
            url: '/ddns'
        }
    },
    {
        name: 'dns.ddns.hostnames',
        state: {
            abstract: true,
            url: '/hostnames'
        }
    },
    {
        name: 'dns.ddns.hostnames.id',
        state: {
            abstract: true,
            resolve: {
                ddnsHostname: (ddnsModel: DdnsModelService, $stateParams: ng.ui.IStateParamsService) => {
                    return ddnsModel.getHostnameById($stateParams.ddnsHostnameId)
                        .then(stateUtilityFunctions.check404('DDNS Host not found', 'ddns-host'));
                }
            },
            url: '/id/{ddnsHostnameId}'
        }
    },
    {
        name: 'dns.ddns.hostnames.id.edit',
        state: {
            data: {
                isGrantedAny: [
                    UiRights.DNS_DDNSHOSTNAME_EDIT,
                    UiRights.DNS_DDNSCREDENTIALS_EDIT,
                    UiRights.DNS_DDNSCREDENTIALS_DELETE
                ]
            },
            resolve: {
                availableDdnsUsers: (ddnsModel: DdnsModelService, ddnsHostname) => {
                    return ddnsModel.credentialsListForAccount(ddnsHostname.accountId);
                },
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-ddns-hostnames-edit
                                    ddns-hostname="$resolve.ddnsHostname"
                                    show-object-id="$resolve.showObjectId"
                                    available-ddns-users="$resolve.availableDdnsUsers"
                                ></template-ddns-hostnames-edit>`
                }
            }
        }
    },
    {
        name: 'dns.ddns.hostnames.overview',
        state: {
            data: {
                isGranted: UiRights.DNS_DDNSHOSTNAME_LIST
            },
            resolve: {
                extendedFilters: ($stateParams: ng.ui.IStateParamsService, filterCache: FilterCacheService) => {
                    if ($stateParams.fancyFilter !== undefined && $stateParams.fancyFilter !== '') {
                        return JSON.parse($stateParams.fancyFilter);
                    }
                    return filterCache.resolveExtendedFilters('DDNSHostnames');
                },
                overviewModel: (ddnsHostnameOverviewModel: DdnsHostnameOverviewModel) => {
                    ddnsHostnameOverviewModel.showNormalVersion();
                    return ddnsHostnameOverviewModel;
                },
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('DnsOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('DDNSHostnames');
                }
            },
            url: '/overview/{fancyFilter}',
            views: {
                'content@authenticated': {
                    template: `<template-ddns-hostnames-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                ></template-ddns-hostnames-overview>`
                }
            }
        }
    },
    {
        name: 'dns.ddns.hostnames.new',
        state: {
            data: {
                isGranted: UiRights.DNS_DDNSHOSTNAME_CREATE
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: `<template-dns-wizard
                                   product-family="ddns-hostnames"
                                   product-code="dns-ddns-hostname-12m"
                               ></template-dns-wizard>`
                }
            }
        }
    },
    {
        name: 'dns.ddns.credentials',
        state: {
            abstract: true,
            url: '/credentials'
        }
    },
    {
        name: 'dns.ddns.credentials.new',
        state: {
            data: {
                isGranted: UiRights.DNS_DDNSCREDENTIALS_CREATE
            },
            resolve: {
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: `<template-ddns-credentials-new
                                    show-object-id="$resolve.showObjectId"
                                ></template-ddns-credentials-new>`
                }
            }
        }
    },
    {
        name: 'dns.ddns.credentials.id',
        state: {
            abstract: true,
            resolve: {
                ddnsCredentials: (ddnsModel: DdnsModelService, $stateParams: ng.ui.IStateParamsService) => {
                    return ddnsModel.getCredentialsById($stateParams.ddnsCredentialsId)
                        .then(stateUtilityFunctions.check404('DDNS User not found', 'ddns-user'));
                }
            },
            url: '/id/{ddnsCredentialsId}'
        }
    },
    {
        name: 'dns.ddns.credentials.id.edit',
        state: {
            data: {
                isGrantedAny: [
                    UiRights.DNS_DDNSCREDENTIALS_EDIT,
                    UiRights.DNS_DDNSCREDENTIALS_DELETE
                ]
            },
            resolve: {
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-ddns-credentials-edit
                                    show-object-id="$resolve.showObjectId"
                                    ddns-credentials="$resolve.ddnsCredentials"
                                ></template-ddns-credentials-edit>`
                }
            }
        }
    },
    {
        name: 'dns.ddns.credentials.overview',
        state: {
            data: {
                isGranted: UiRights.DNS_DDNSCREDENTIALS_LIST
            },
            resolve: {
                extendedFilters: ($stateParams: ng.ui.IStateParamsService, filterCache: FilterCacheService) => {
                    if ($stateParams.fancyFilter !== undefined && $stateParams.fancyFilter !== '') {
                        return JSON.parse($stateParams.fancyFilter);
                    }
                    return filterCache.resolveExtendedFilters('DDNSCredentials');
                },
                overviewModel: (ddnsCredentialsOverviewModel: DdnsCredentialsOverviewModel) => {
                    return ddnsCredentialsOverviewModel;
                },
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('DnsOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('DDNSCredentials');
                }
            },
            url: '/overview/{fancyFilter}',
            views: {
                'content@authenticated': {
                    template: `<template-ddns-credentials-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                ></template-ddns-credentials-overview>`
                }
            }
        }
    },
    {
        name: 'dns.dashboard',
        state: {
            data: {
                isGranted: UiRights.DNS_ZONES_LIST
            },
            onEnter: (navigation: NavigationService, userSettingsManager: UserSettingsManagerService) => {
                userSettingsManager.getUiSettings().then(
                    (result) => {
                        if (result?.overviewSettings?.DnsOverviewCtrl?.expertView) {
                            navigation.go('dns.zones.overview');
                        }
                    }
                );
            },
            url: '/',
            views: {
                'content@authenticated': {
                    template: '<template-dns-dashboard></template-dns-dashboard>'
                }
            }
        }
    },
    {
        name: 'dns.zones',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.DNS_ZONES_LIST
            },
            url: '/zones'
        }
    },
    {
        name: 'dns.zones.overview',
        state: {
            data: {
                isGranted: UiRights.DNS_ZONES_LIST
            },
            resolve: {
                extendedFilters: ($stateParams: ng.ui.IStateParamsService, filterCache: FilterCacheService) => {
                    if ($stateParams.fancyFilter !== undefined && $stateParams.fancyFilter !== '') {
                        return JSON.parse($stateParams.fancyFilter);
                    }
                    return filterCache.resolveExtendedFilters('DNSZones');
                },
                overviewModel: (dnsZoneOverviewModel: DnsZoneOverviewModel) => dnsZoneOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('DnsOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('DNSZones');
                }
            },
            url: '/overview/{fancyFilter}',
            views: {
                'content@authenticated': {
                    template: `<template-dns-zone-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                ></template-dns-zone-overview>`
                }
            }
        }
    },
    {
        name: 'dns.zones.id',
        state: {
            abstract: true,
            resolve: {
                defaultNameserver: (nameserverSetModel: NameserverSetModelService, authContext: AuthContextService) => {
                    return nameserverSetModel.getDefaultNameserver(authContext.account.id);
                },
                zone: (
                    $stateParams: ng.ui.IStateParamsService,
                    cache: CacheService,
                    dnsZoneModel: DnsZoneModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    cache.get('dns::zoneConfigsFind').clear();
                    return dnsZoneModel.findOne($stateParams.zoneId).then(null, redirectOnNotFound);
                }
            },
            url: '/id/{zoneId}'
        }
    },
    {
        name: 'dns.zones.id.edit',
        state: {
            data: {
                isGranted: UiRights.DNS_ZONES_EDIT
            },
            resolve: {
                dnsSecOptions: (
                    dnsZoneModel: DnsZoneModelService,
                    zone
                ) => {
                    return dnsZoneModel.findOneDnsSecOptions(zone.id).then(null, () => undefined);
                },
                showAdvancedDnsSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.showAdvancedDnsSettings();
                },
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-dns-zone-edit
                                    zone="$resolve.zone"
                                    default-nameserver="$resolve.defaultNameserver"
                                    dns-sec-options="$resolve.dnsSecOptions"
                                    show-object-id="$resolve.showObjectId"
                                    show-advanced-settings="$resolve.showAdvancedDnsSettings"
                                ></template-dns-zone-edit>`
                }
            }
        }
    },
    {
        name: 'dns.zones.id.edit.move',
        state: {
            data: {
                isGranted: UiRights.DNS_ZONES_MOVE
            },
            url: '/move',
            views: {
                'content@authenticated': {
                    template: `<template-dns-zone-edit-move
                                    zone="$resolve.zone"
                                ></template-dns-zone-edit-move>`
                }
            }
        }
    },
    {
        name: 'dns.zones.id.edit.recreate',
        state: {
            data: {
                isGranted: UiRights.DNS_ZONES_CREATE
            },
            url: '/recreate',
            views: {
                'content@authenticated': {
                    template: `<template-dns-wizard
                                   product-family="dns-zone"
                               ></template-dns-wizard>`
                }
            }
        }
    },
    {
        name: 'dns.zones.id.records-edit',
        state: {
            data: {
                isGranted: UiRights.DNS_ZONES_EDIT
            },
            resolve: {
                records: (dnsZoneModel: DnsZoneModelService, $stateParams: ng.ui.IStateParamsService) => {
                    return dnsZoneModel.findRecords($stateParams.zoneId);
                }
            },
            params: {
                fromZoneEdit: { value: null, squash: true }
            },
            url: '/records-edit',
            views: {
                'content@authenticated': {
                    template: `<template-dns-zone-records-edit
                                    zone="$resolve.zone"
                                    default-nameserver="$resolve.defaultNameserver"
                                    records="$resolve.records">
                                </template-dns-zone-records-edit>`
                }
            }
        }
    },
    {
        name: 'dns.zones.id.restrictions',
        state: {
            data: {
                isGranted: UiRights.DNS_ZONES_EDIT
            },
            resolve: {
                restrictionHelper: (
                    zone,
                    restrictionHelper: RestrictionHelperService,
                    dnsZoneModel: DnsZoneModelService
                ) => {
                    restrictionHelper.init(zone, 'dns', dnsZoneModel, 'zoneModifyRestrictions');
                    return restrictionHelper;
                },
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/restrictions',
            views: {
                'content@authenticated': {
                    template: `<template-restrictions-edit
                        api-object="$resolve.zone"
                        restriction-helper="$resolve.restrictionHelper"
                        show-object-id="$resolve.showObjectId"
                    ></template-restrictions-edit>`
                }
            }
        }
    },
    {
        name: 'dns.zones.new',
        state: {
            data: {
                isGranted: UiRights.DNS_ZONES_CREATE
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-dns-wizard></template-dns-wizard>'
                }
            }
        }
    },
    {
        name: 'dns.zones.jobs',
        state: {
            data: {
                isGranted: UiRights.DNS_ZONES_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('DNSJobs');
                },
                overviewModel: (dnsJobOverviewModel: DnsJobOverviewModel) => dnsJobOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('DnsOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('DNSJobs');
                }
            },
            url: '/jobs',
            views: {
                'content@authenticated': {
                    template: `<template-dns-job-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                ></template-dns-job-overview>`
                }
            }
        }
    },
    {
        name: 'dns.zones.jobs.id',
        state: {
            abstract: true,
            resolve: {
                job: (
                    $stateParams: ng.ui.IStateParamsService,
                    dnsJobModel: DnsJobModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return dnsJobModel.findOne($stateParams.jobId).then(null, redirectOnNotFound);
                }
            },
            url: '/id/{jobId}'
        }
    },
    {
        name: 'dns.zones.jobs.id.details',
        state: {
            url: '/details',
            views: {
                'content@authenticated': {
                    template: `<template-dns-job-details
                                    job="$resolve.job"
                                ></template-dns-job-details>`
                }
            }
        }
    },
    {
        name: 'dns.zones.content-search',
        state: {
            data: {
                isGranted: UiRights.DNS_ZONES_LIST
            },
            url: '/content-search',
            views: {
                'content@authenticated': {
                    template: '<template-record-content-search></template-record-content-search>'
                }
            }
        }
    }, {
        name: 'dns.zones.content-change',
        state: {
            data: {
                isGranted: UiRights.DNS_ZONES_EDIT
            },
            url: '/content-change',
            views: {
                'content@authenticated': {
                    template: '<template-record-content-change></template-record-content-change>'
                }
            }
        }
    }, {
        name: 'dns.templates',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.DNS_TEMPLATES_LIST
            },
            resolve: {
                templates: (dnsTemplateModel: DnsTemplateModelService) => {
                    return dnsTemplateModel.list().then((templates) => templates.data);
                }
            },
            url: '/templates'
        }
    },
    {
        name: 'dns.templates.overview',
        state: {
            data: {
                isGranted: UiRights.DNS_TEMPLATES_LIST
            },
            resolve: {
                overviewModel: (dnsTemplateOverviewModel: DnsTemplateOverviewModel) => dnsTemplateOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('DnsOverviewCtrl');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-dns-template-overview
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                ></template-dns-template-overview>`
                }
            }
        }
    },
    {
        name: 'dns.templates.new',
        state: {
            data: {
                isGranted: UiRights.DNS_TEMPLATES_CREATE
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-dns-template-new></template-dns-template-new>'
                }
            }
        }
    },
    {
        name: 'dns.templates.id',
        state: {
            abstract: true,
            resolve: {
                defaultNameserver: (nameserverSetModel: NameserverSetModelService, authContext: AuthContextService) => {
                    return nameserverSetModel.getDefaultNameserver(authContext.account.id);
                },
                records: (dnsTemplateModel: DnsZoneModelService, $stateParams: ng.ui.IStateParamsService) => {
                    return dnsTemplateModel.findRecords($stateParams.templateId);
                },
                template: (
                    $stateParams: ng.ui.IStateParamsService,
                    dnsTemplateModel: DnsZoneModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return dnsTemplateModel.findOne($stateParams.templateId).then(null, redirectOnNotFound);
                }
            },
            url: '/id/{templateId}'
        }
    },
    {
        name: 'dns.templates.id.edit',
        state: {
            data: {
                isGranted: UiRights.DNS_TEMPLATES_EDIT
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-dns-template-edit
                                    template="$resolve.template"
                                    records="$resolve.records"
                                    default-nameserver="$resolve.defaultNameserver"
                                    dns-sec-options="$resolve.dnsSecOptions">
                                </template-dns-template-edit>`
                }
            }
        }
    },
    {
        name: 'dns.templates.id.copy',
        state: {
            data: {
                isGranted: UiRights.DNS_TEMPLATES_CREATE
            },
            url: '/copy',
            views: {
                'content@authenticated': {
                    template: `<template-dns-template-copy
                                template="$resolve.template"
                                records="$resolve.records"
                               >
                               </template-dns-template-copy>`
                }
            }
        }
    },
    {
        name: 'dns.templates.id.linked-zones',
        state: {
            abstract: true,
            url: '/linked-zones'
        }
    },
    {
        name: 'dns.templates.id.linked-zones.overview',
        state: {
            data: {
                isGranted: UiRights.DNS_ZONES_LIST
            },
            resolve: {
                extendedFilters: ($stateParams: ng.ui.IStateParamsService, filterCache: FilterCacheService) => {
                    if ($stateParams.fancyFilter !== undefined && $stateParams.fancyFilter !== '') {
                        return JSON.parse($stateParams.fancyFilter);
                    }
                    return filterCache.resolveExtendedFilters('DNSZones');
                },
                overviewModel: (dnsZoneOverviewModel: DnsZoneOverviewModel) => dnsZoneOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('DnsOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('DNSZones');
                }
            },
            url: '/overview/{fancyFilter}',
            views: {
                'content@authenticated': {
                    template: `<template-dns-zone-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                ></template-dns-zone-overview>`
                }
            }
        }
    },
    {
        name: 'dns.templates.id.linked-zones.id',
        state: {
            abstract: true,
            resolve: {
                defaultNameserver: (nameserverSetModel: NameserverSetModelService, authContext: AuthContextService) => {
                    return nameserverSetModel.getDefaultNameserver(authContext.account.id);
                },
                records: (dnsZoneModel: DnsZoneModelService, $stateParams: ng.ui.IStateParamsService) => {
                    return dnsZoneModel.findRecords($stateParams.zoneId);
                },
                zone: (
                    $stateParams: ng.ui.IStateParamsService,
                    cache: CacheService,
                    dnsZoneModel: DnsZoneModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    cache.get('dns::zoneConfigsFind').clear();
                    return dnsZoneModel.findOne($stateParams.zoneId).then(null, redirectOnNotFound);
                }
            },
            url: '/id/{zoneId}'
        }
    },
    {
        name: 'dns.templates.id.linked-zones.id.edit',
        state: {
            data: {
                isGranted: UiRights.DNS_ZONES_EDIT
            },
            resolve: {
                dnsSecOptions: (
                    dnsZoneModel: DnsZoneModelService,
                    zone
                ) => {
                    return dnsZoneModel.findOneDnsSecOptions(zone.id).then(null, () => undefined);
                },
                showAdvancedDnsSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.showAdvancedDnsSettings();
                },
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-dns-zone-edit
                                    zone="$resolve.zone"
                                    default-nameserver="$resolve.defaultNameserver"
                                    dns-sec-options="$resolve.dnsSecOptions"
                                    show-object-id="$resolve.showObjectId"
                                    show-advanced-settings="$resolve.showAdvancedDnsSettings"
                                ></template-dns-zone-edit>`
                }
            }
        }
    },
    {
        name: 'dns.templates.id.linked-zones.id.edit.move',
        state: {
            data: {
                isGranted: UiRights.DNS_ZONES_MOVE
            },
            url: '/move',
            views: {
                'content@authenticated': {
                    template: `<template-dns-zone-edit-move
                                    zone="$resolve.zone"
                                ></template-dns-zone-edit-move>`
                }
            }
        }
    },
    {
        name: 'dns.templates.id.linked-zones.id.edit.recreate',
        state: {
            data: {
                isGranted: UiRights.DNS_ZONES_CREATE
            },
            url: '/recreate',
            views: {
                'content@authenticated': {
                    template: `<template-dns-wizard
                                   product-family="dns-zone"
                               ></template-dns-wizard>`
                }
            }
        }
    },
    {
        name: 'dns.templates.id.linked-zones.id.records-edit',
        state: {
            data: {
                isGranted: UiRights.DNS_ZONES_EDIT
            },
            params: {
                fromZoneEdit: { value: null, squash: true }
            },
            url: '/records-edit',
            views: {
                'content@authenticated': {
                    template: `<template-dns-zone-records-edit
                                    zone="$resolve.zone"
                                    default-nameserver="$resolve.defaultNameserver"
                                    records="$resolve.records">
                                </template-dns-zone-records-edit>`
                }
            }
        }
    },
    {
        name: 'dns.templates.id.linked-zones.id.restrictions',
        state: {
            data: {
                isGranted: UiRights.DNS_ZONES_EDIT
            },
            resolve: {
                restrictionHelper: (
                    zone,
                    restrictionHelper: RestrictionHelperService,
                    dnsZoneModel: DnsZoneModelService
                ) => {
                    restrictionHelper.init(zone, 'dns', dnsZoneModel, 'zoneModifyRestrictions');
                    return restrictionHelper;
                },
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/restrictions',
            views: {
                'content@authenticated': {
                    template: `<template-restrictions-edit
                        api-object="$resolve.zone"
                        restriction-helper="$resolve.restrictionHelper"
                        show-object-id="$resolve.showObjectId"
                    ></template-restrictions-edit>`
                }
            }
        }
    },
    {
        name: 'dns.nameserversets',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.DNS_NSSETS_LIST
            },
            url: '/nameserversets'
        }
    },
    {
        name: 'dns.nameserversets.overview',
        state: {
            data: {
                isGranted: UiRights.DNS_NSSETS_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('DNSServerSets');
                },
                overviewModel: (nameserverSetOverviewModel: NameserverSetOverviewModel) => nameserverSetOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('DnsOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('DNSServerSets');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-nameserver-set-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                ></template-nameserver-set-overview>`
                }
            }
        }
    },
    {
        name: 'dns.nameserversets.new',
        state: {
            data: {
                isGranted: UiRights.DNS_NSSETS_CREATE
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-nameserver-set-new></template-nameserver-set-new>'
                }
            }
        }
    },
    {
        name: 'dns.nameserversets.id',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.DNS_NSSETS_EDIT
            },
            resolve: {
                nameserverSet: (
                    $stateParams: ng.ui.IStateParamsService,
                    nameserverSetModel: NameserverSetModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return nameserverSetModel.findOne($stateParams.nameserverSetId).then(null, redirectOnNotFound);
                }
            },
            url: '/id/{nameserverSetId}'
        }
    },
    {
        name: 'dns.nameserversets.id.edit',
        state: {
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-nameserver-set-edit
                                    nameserver-set="$resolve.nameserverSet"
                                ></template-nameserver-set-edit>`
                }
            }
        }
    }
];
