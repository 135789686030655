import * as ng from 'angular';
import { DomainInfoHelperService } from './../helpers/domain-info-helper';
import { Validator } from './abstract-validator';
import { ValidateHostname } from './index';
import { ValidateEmail } from './validate-email';

export class ValidateURL extends Validator {
    private allowEmpty: boolean;
    private protocols: string[];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private domainInfoHelper: DomainInfoHelperService,
        allowEmpty?: boolean,
        protocols?: string[]
    ) {
        super();
        if (allowEmpty === undefined || allowEmpty == null || allowEmpty) {
            this.allowEmpty = true;
        } else {
            this.allowEmpty = false;
        }
        this.protocols = protocols;
    }

    public validate = (value: string): { text: string }[] => {
        const split = value.split('://');
        const reply: { text: string }[] = [];
        if (value == null || value === undefined || value === '') {
            if (!this.allowEmpty) {
                reply.push({
                    text: this.$translate.instant('TR_090119-2705b2_TR')
                });
            } else {
                return reply;
            }
        }

        const scheme = value.split(':');

        if (this.protocols) {
            if ((scheme.length > 1 && !this.protocols.includes(scheme[0])) || scheme.length === 1) {
                reply.push({
                    text: this.$translate.instant(
                        /* translationId */ 'TR_060821-49330e_TR',
                        { protocols: this.protocols.map(p => `${p}://`).join(' ') }
                    )
                });
            }
            reply.push(...this._validateHostname(split));
            return reply;
        } else {
            // mailto scheme support validate email
            if (scheme.length > 1 && scheme[0] === 'mailto') {
                return new ValidateEmail(false, this.$translate, this.domainInfoHelper)
                    .validate(scheme[1]);
            }

            if (split.length !== 2) {
                reply.push({
                    text: this.$translate.instant('TR_091019-926c3f_TR')
                });
            } else if (split[0] !== 'file') {
                reply.push(...this._validateHostname(split));
            }

            return reply;
        }

    };

    private _validateHostname = (split: string[] | undefined): { text: string }[] => {
        if (split && split.length > 1) {
            const hostpart = split[1].split('/')[0];
            const validHostnameResponse = new ValidateHostname(this.$translate, false).validate(hostpart);
            if (validHostnameResponse.length > 0) {
                return [
                    validHostnameResponse[0]
                ];
            }
        }
        return [];
    };
}
