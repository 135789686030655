import ng from 'angular';

import { Contingent, ContingentType } from '@/atomic-components/organs';
import { AsteriskNoteService } from '@/services/billing/asterisk-note-service';
import * as Types from '@/types';
import { ViewTypes } from '@/types/view-types';

export class OrganismDatabaseWizardConfigViewController {
    public static $inject: string[] = ['$state', 'asteriskNote'];

    public callback: (metaData: ViewTypes.ProductConfigDatabaseObject) => {};
    public outerAccount: Types.AccountApi.Account;
    public outerProductCode: string;
    public productCodeList: string[] = [];
    public productFamilies: string[] = ['mariadb-single-databases'];
    public productFamily: string;
    public service = 'database';
    public configCompleted = false;
    public sufficientCreditAvailable = true;
    public selectedContingent: Contingent;  // set in product selection
    public selectedProductCode: string = null;
    public metaData: ViewTypes.ProductConfigDatabaseObject;

    private _account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    private _selectedProductCode: string = null;

    public constructor(
        private $state: ng.ui.IStateService,
        private asteriskNote: AsteriskNoteService
    ) {
        const stateInBundle = this.$state.current.name.split('.')[0] === 'bundle';
        const stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';

        if (stateInMachine) {
            this.outerAccount = this.$state.$current.locals.globals.machineAccount;
            this.account = this.$state.$current.locals.globals.machineAccount;

            /*
             * Only id and type are important, the contingent dropdown
             * creates and matches the real object on its own.
             */
            this.selectedContingent = new Contingent(
                this.$state.$current.locals.globals.databaseRessource.id,
                '',
                ContingentType.databaseServer,
                []
            );
        }

        if (stateInBundle) {
            this.outerAccount = this.$state.$current.locals.globals.bundleAccount;
            this.account = this.$state.$current.locals.globals.bundleAccount;

            /*
             * Only id and type are important, the contingent dropdown
             * creates and matches the real object on its own.
             */
            this.selectedContingent = new Contingent(
                this.$state.$current.locals.globals.bundle.id,
                '',
                ContingentType.bundle,
                []
            );
        }
    }

    public $onInit = () => {
        this.asteriskNote.resetNodes();
        if ([undefined, null].indexOf(this.outerAccount) < 0) {
            this.account = this.outerAccount;
        }
    };

    public $doCheck() {
        if (this.selectedProductCode !== this._selectedProductCode) {
            this.productCodeList = this.productCodeList.filter((element) => element !== this._selectedProductCode);

            if ([undefined, null].indexOf(this.selectedProductCode) < 0) {
                this.productCodeList.push(this.selectedProductCode);
            }

            this._selectedProductCode = ng.copy(this.selectedProductCode);
        }
    }

    public unsavedChangesExist = () => {
        if (this.metaData?.databaseName?.length > 0
        || this.metaData?.comments?.length > 0
        || this.metaData?.accesses?.password?.length > 0
        ) {
            return true;
        }
        return false;
    };

    get account(): Types.AccountApi.Account | Types.AccountApi.Subaccount {
        return this._account;
    }

    set account(value: Types.AccountApi.Account | Types.AccountApi.Subaccount) {
        if (
            [undefined, null].indexOf(value) < 0
            && value !== this._account
            && [undefined, null].indexOf(this.metaData) < 0
        ) {
            this.metaData.account = value;
        }

        this._account = value;
    }

    public finishConfiguration = () => {
        if (this.callback !== undefined) {
            this.metaData.productCode = this.selectedProductCode;
            this.callback(this.metaData);
        }
    };
}

export class OrganismDatabaseWizardConfigViewComponent implements ng.IComponentOptions {
    public bindings = {
        callback: '<',
        productFamily: '=',
        outerAccount: '<',
        outerProductCode: '<'
    };

    public template = require('./database-wizard-config-view.html');
    public controller = OrganismDatabaseWizardConfigViewController;
}
