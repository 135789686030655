import * as ng from 'angular';

export class MoleculeButtonReissueController implements ng.IController {
    public static $inject: string[] = [];

    public reissue: () => void;
    public modifier = '';
    public disabled = false;
}

export class MoleculeButtonReissueComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        disabled: '<',
        modifier: '@?',
        reissue: '<'
    };
    public controller =  MoleculeButtonReissueController;
    public template = require('./button-reissue.html');
}
