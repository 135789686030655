import { CsvGenerator } from '../csv-generator';

export class DnsCsvGenerator {
    public static generateZoneCsv(zone, records) {
        const lines = records.map(
            (record) => {
                return [
                    zone.name,
                    zone.nameUnicode,
                    record.name,
                    record.type,
                    record.content,
                    record.ttl,
                    record.priority
                ];
            }
        );

        lines.unshift(
            ['Domain Name ACE', 'Domain Name Unicode', 'Record Name', 'Record Type', 'Content', 'TTL', 'Priority']
        );

        return CsvGenerator.generate(lines);
    }
}
