import * as ng from 'angular';
import { app } from './scripts/app';
import { routesArray } from '@/components/all-routes'

app.config(['$stateProvider', function(
        $stateProvider: ng.ui.IStateProvider
    ) {
        for (const route of routesArray) {
            $stateProvider.state(route.name, route.state);
        }
    }
]);

export default app;
