export class NextcloudAppHelperService {
    public static $inject: string[] = [];

    public initSliderTranslations = (
        appId: string
    ): any => {
        const translations: any = {};
        switch (appId) {
            case 'collabora':
                translations.info = /* translationId */ 'TR_120221-19272b_TR';
                translations.isUserIncluded = /* translationId */ 'TR_240821-6a67d7_TR';
                translations.checkboxText = /* translationId */ 'TR_120122-5990de_TR';
                break;

            case 'talk_hpb':
                translations.info = /* translationId */ 'TR_221220-524365_TR';
                translations.isUserIncluded = /* translationId */ 'TR_240821-6a67d7_TR';
                translations.checkboxText = /* translationId */ 'TR_120122-29bd34_TR';
                break;
        }
        translations.lableText = /* translationId */ 'TR_130319-0cb933_TR';
        translations.confirmationText = /* translationId */ 'TR_231220-b40a76_TR';
        return translations;
    };
}
