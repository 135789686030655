import ng from 'angular';
import { WizardServiceHelperDomain } from '@/atomic-components/molecules';
import { ContingentType } from '@/atomic-components/organs';
import {
    ContractPeriodeToBillingCycleConst, CycleBillingTranslationConst, UiLanguagesConst, UiRights
} from '@/configuration';
import { DomainModelService, TldListService } from '@/services';
import { DomainHelperObjectCache } from '@/atomic-components/molecules';
import { AccountApi, BundleApi, DnsApi, ProductApi, ViewTypes, WebhostingApi, OrchestrationApi } from '@/types';

import {
    ApiObject, AuthContextService, BundleModelService, DnsRobotService,
    DomainCheckerService, NameserverSetModelService,
    ProductsModelService, VhostModelService, WebspaceModelService
} from '@/services';

export type BundleDomainCheck = {
    showPleaseSelectTheCorrectProduct: boolean;
    correctBundleId: string;
    translation: string;
    bundleName?: string;
};

type IBundleContingent = BundleApi.BundleContingent & {
    service: string;
    capacity: unknown;
    productCodes: string[];
    codeStartsWith: string;
};

export class WizardNewHelperService {
    public static $inject: string[] = [
        '$translate',
        'bundleModel',
        'domainModel',
        'dnsRobot',
        'domainChecker',
        'nameserverSetModel',
        'productsModel',
        'tldList',
        'vhostModel',
        'webspaceModel',
        'wizardServiceHelperDomain'
    ];

    public objectCache: DomainHelperObjectCache = {
        nameserSets: [],
        systemNameserver: {},
        zoneNameservers: [],
        zones: []
    };

    private _language: null|string = null;
    private _nameserverSets: ViewTypes.DomainWizardNameserverSets[] = [];
    private _bundleContingents: IBundleContingent[]  = [
        { service: 'database', codeStartsWith: 'database', productCodes: [], capacity: 0},
        { service: 'domain', codeStartsWith: 'domain', productCodes: [], capacity: 0},
        { service: 'webhosting', codeStartsWith: 'webhosting-bundle-webspace-', productCodes: [], capacity: 0},
        { service: 'webspace-storage', codeStartsWith: 'webhosting-bundle-webspace-storage', productCodes: [], capacity: 0}, // tslint:disable-line
        { service: 'email', codeStartsWith: 'email', productCodes: [], capacity: 0}
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private bundleModel: BundleModelService,
        private domainModel: DomainModelService,
        private dnsRobot: DnsRobotService,
        private domainChecker: DomainCheckerService,
        private nameserverSetModel: NameserverSetModelService,
        private productsModel: ProductsModelService,
        private tldList: TldListService,
        private vhostModel: VhostModelService,
        private webspaceModel: WebspaceModelService,
        private wizardServiceHelperDomain: WizardServiceHelperDomain
    ) {}

    public checkDomainCorrectBundle = async (
        metadata: ViewTypes.ProductConfigExternalForwarderObject | ViewTypes.ProductConfigEmailObject,
        correctBundleId: string
    ): Promise<BundleDomainCheck> => {
        let showPleaseSelectTheCorrectProduct = false;
        let translation = '';
        let bundleName;
        if (!metadata?.mailbox?.emailAddress?.includes('@')) {
            return {
                showPleaseSelectTheCorrectProduct: showPleaseSelectTheCorrectProduct,
                correctBundleId: correctBundleId,
                translation: ''
            };
        }

        /* eslint-disable no-case-declarations */
        switch (String(metadata.contingent.type).toLowerCase()) {
            case 'bundle':
                let vhostsFind = [];
                if (AuthContextService.isGranted(UiRights.WEB_OBJECT_LIST)) {
                    vhostsFind = await this.vhostModel.findByNames([metadata.mailbox?.emailAddress.split('@')[1]]);
                }
                let domainsFind = [];
                if (AuthContextService.isGranted(UiRights.DOM_DOMAINS_LIST)) {
                    domainsFind = await this.domainModel.findByName([metadata.mailbox?.emailAddress.split('@')[1]]);
                }
                if (domainsFind.length <= 0 && vhostsFind.length <= 0) {
                    translation = /* translationId */ 'TR_150719-514e92_TR';
                    return {
                        showPleaseSelectTheCorrectProduct: showPleaseSelectTheCorrectProduct,
                        correctBundleId: correctBundleId,
                        translation: translation
                    };
                } else if (domainsFind.length > 0){
                    correctBundleId = domainsFind[0].bundleId;
                } else {
                    const webspace = await this.webspaceModel.findOne(vhostsFind[0].webspaceId as string);
                    correctBundleId = webspace.bundleId;
                }
                if ([undefined, null, ''].indexOf(correctBundleId) < 0) {
                    showPleaseSelectTheCorrectProduct = metadata.contingent.id !== correctBundleId;
                    const bundlesFind = await this.bundleModel.findOne(correctBundleId);
                    translation = /* translationId */ 'TR_150719-514e92_TR';
                    const bundleEffectiveContingent = bundlesFind
                        .effectiveContingentUsage
                        .find((contingent) => contingent.productCodes.includes(metadata.productCode));
                    if (bundleEffectiveContingent.availableCapacity <= 0) {
                        if (correctBundleId !== metadata.contingent.id) {
                            translation = /* translationId */ 'TR_240621-004bed_TR';
                            correctBundleId = null;
                            bundleName = bundlesFind.name;
                        } else {
                            translation = /* translationId */ 'TR_190820-6641b9_TR';
                            correctBundleId = null;
                        }
                    }
                } else if (metadata.contingent.type !== ContingentType.standalone) {
                    showPleaseSelectTheCorrectProduct = false;
                }

                break;
            case 'pool':

                break;
            default:
                break;
        }
        /* eslint-enable no-case-declarations */
        return {
            showPleaseSelectTheCorrectProduct: showPleaseSelectTheCorrectProduct,
            correctBundleId: correctBundleId,
            translation: translation,
            bundleName: bundleName
        };
    };

    public getBillingCycleTranslation = (cycle: number | string): string => {
        return this.$translate.instant(
            (CycleBillingTranslationConst as Record<string, string>)[
                (typeof cycle === 'number') ? (ContractPeriodeToBillingCycleConst as Record<number, string>)[cycle] : cycle
            ]
        );
    };

    public factSheetConditioner = async (productCode: string): Promise<ProductApi.FactSheetKeywordDescription[]> => {
        let productRes;

        try {
            productRes = await this.productsModel.findProduct(productCode, this._getLanguage);
        } catch (_error: unknown) {
            // on error return empty array and do not show error in error panel .....
            return [];
        }

        const factSheetItems = productRes.status === 'success'
            ? (productRes.response as ProductApi.AbstractProduct).factSheetItems
            : null;

        if (factSheetItems === null) {
            return [];
        }

        const keywordRes = await this.productsModel.findKeywordDescriptions(factSheetItems, this._getLanguage());
        const conditionSheetList = [];

        for (const sheetItem of factSheetItems) {
            for (const descItem of keywordRes.responses) {
                if (sheetItem.category === descItem.category && sheetItem.keyword === descItem.keyword) {
                    conditionSheetList.push({
                        description: descItem.description,
                        label: descItem.name,
                        type: descItem.type,
                        value: sheetItem.value,
                        keyword: descItem.keyword,  // | used to filter the list in a later step!
                        category: descItem.category // |----------> DO NOT REMOVE!
                    });

                    break;
                }
            }
        }

        return conditionSheetList;
    };

    public getFactValueTranslation = (factDetail: { type: string }, property: { value: string}): string | boolean => {
        switch (factDetail.type) {
            case 'bool':
                return property.value === '1';

            case 'mb':
            case 'gb':
                return property.value + ' ' + factDetail.type.toUpperCase();

            case 'string':
                return property.value;

            case 'sec':
                return property.value + ' sec';

            case 'day':
                return property.value + ' ' + this.$translate.instant('c9d4d439-d6be-4eda-93bb-66eb041b46be');

            case 'days':
                return property.value + ' ' + this.$translate.instant('db2881be-7da3-44c4-8b24-e7d353391e82');

            default:
                return property.value;
        }
    };

    public getFactsheetTranslatedDescription = (
        keywordsDescriptions: { category: string; keyword: string}[],
        property: { category: string; keyword: string}
    ): { category: string; keyword: string} => {
        const keywordDescription = keywordsDescriptions.filter(
            (description) => description.category === property.category && description.keyword === property.keyword
        );

        return keywordDescription.length > 0 ? keywordDescription[0] : property;
    };

    public getFactsheetLabelTranslation = (
        elementShown: ProductApi.FactSheetKeywordDescription,
        raw?: boolean
    ): ProductApi.FactSheetKeywordDescription | string => {
        raw = raw || false;

        [
            {
                name: this.$translate.instant('0bbaab10-cf54-4d19-84bb-261d877e6605'),
                replacement: this.$translate.instant('TR_STORAGE_TR')
            },
            {
                name: this.$translate.instant('TR_090119-f9c047_TR'),
                replacement: this.$translate.instant('TR_090119-2d59db_TR')
            },
            {
                name: this.$translate.instant('TR_090119-06de18_TR'),
                replacement: this.$translate.instant('TR_090119-eee13d_TR')
            }
        ]
        .forEach(
            (evil) => {
                if (elementShown.name === evil.name) {
                    elementShown.name = evil.replacement;
                }
            }
        );

        return raw ? elementShown : elementShown.name;
    };

    public getBundleContingents = async (productCode: string, accountId: string): Promise<any[]> => {
        const filter = { field: 'BundleProductProductCode', value: productCode };
        const bundleContingents = ng.copy(this._bundleContingents);

        const bundleProduct: ViewTypes.EntityWithPagination<BundleApi.FindBundleProductsResult> =
            await this.bundleModel.productsList(
                filter,
                null,
                1,
                null,
                null,
                accountId
            );

        const bundleDetails = bundleProduct.data[0] as BundleApi.BundleProduct;

        return bundleContingents.map(
            (contingentTemplate) => {
                for (const data of bundleDetails.contingents) {
                    if (data.productCodes[0].indexOf(contingentTemplate.codeStartsWith) === 0) {
                        contingentTemplate.productCodes = data.productCodes;
                        contingentTemplate.capacity = data.capacity;

                        break;
                    }
                }

                return contingentTemplate;
            }
        );
    };

    public getDatabaseOrchestrationObject = (
        composerMetadataObject: any,
        quantityOfDbs: number,
        databaseProductCode?: string,
        bundleContingents?: IBundleContingent[]
    ): any => {
        const useCustomData = composerMetadataObject.accesses.useCustom;
        const customData = composerMetadataObject.accesses.customAccesses;
        const isExistingUser = useCustomData && [undefined, null, ''].indexOf(customData.database.userId as string) < 0;
        const databaseObject = {
            billingMode: 'bundle',
            productCode: databaseProductCode
                || this._getProductCodeFromBundleContingentsByService(bundleContingents, 'database'),
            storage: 0,
            type: 'Database'
        } as any;

        if (isExistingUser) {
            databaseObject.userId = customData.database.userId;
        } else if (useCustomData) {
            databaseObject.userName = customData.database.name;
            databaseObject.password = customData.database.password;
        } else {
            const user = this.$translate.instant('TR_130319-0cb933_TR');
            const bundle: string = composerMetadataObject.bundleName;
            databaseObject.userName = `${bundle} ${user} ${quantityOfDbs.toString()}`;
            databaseObject.password = composerMetadataObject.accesses.password;
        }

        return databaseObject;
    };

    public getEmailBundleComposerBaseObject = (
        metadata: ViewTypes.ProductConfigBundleObject,
        domainOrderProductCode: string,
        bundleContingents: IBundleContingent[],
        defaultNameserverSet: ViewTypes.DnsDefaultNameserverSet[]
    ): ViewTypes.WizardComposerObject => {
        const fallbackHandleId = this._getFallbackHandleId(
            metadata.account.domainSettings as AccountApi.AccountDomainSettings
        );
        const domainContacts = {
            admin: metadata.account.domainSettings.defaultContactAdminId || fallbackHandleId,
            owner: metadata.account.domainSettings.defaultContactOwnerId || fallbackHandleId,
            tech: metadata.account.domainSettings.defaultContactTechId || fallbackHandleId,
            zone: metadata.account.domainSettings.defaultContactZoneId || fallbackHandleId
        };
        const composerObject: ViewTypes.WizardComposerObject = {
            apiObject: {
                bundle: {
                    accountId: metadata.account.id,
                    name: metadata.bundleName,
                    productCode: metadata.productCode,
                    purchasedContingentExtensions: []
                },
                domain: null
            },
            summaryObject: [],
            productFamily: metadata.productFamily
        };

        if (!metadata.domain.vHostOnly) {
            composerObject.apiObject.domain = {
                bundleId: '',
                contacts: this.wizardServiceHelperDomain.buildRequestContactObject(domainContacts),
                name: metadata.domain.nameUnicode,
                nameserverSetId: null,
                nameservers: defaultNameserverSet,
                transferLockEnabled: true,
                authCode: metadata.domain.authCode.length > 0 ? metadata.domain.authCode : null,
                registrationMode: this._getBundleOrchestrationDomainBillingMode(
                    domainOrderProductCode,
                    bundleContingents
                )
            };
        }

        return composerObject;
    };

    public getBillableDomainProductCode = async (
        domainNameUnicode: string,
        bundleProductCode: string,
        accountId: string
    ): Promise<string> => {
        const promises = [
            this.domainChecker.check([domainNameUnicode], accountId), // domainProductCode
            this.getBundleContingents(bundleProductCode, accountId)
        ];

        const promiseResolves = await Promise.all(promises);
        const domain: { extension: string; status: string } = promiseResolves[0][0];
        const action = domain.status === 'registered' ? 'transfer' : 'create';
        const bundleDomainContingents = (promiseResolves[1] as IBundleContingent[]).filter(
            (contingent) => contingent.service === 'domain'
        );

        const domainProductCode = `domain-${domain.extension}-${action}`;

        if (
            bundleDomainContingents[0].productCodes.indexOf(domainProductCode) >= 0
        ||  !this._isExistingDomainProductCode(domainProductCode)
        ) {
            return '';
        }

        return domainProductCode;
    };

    public getWebhostingBundleComposerBaseObject = (
        metadata: ViewTypes.ProductConfigBundleObject,
        domainOrderProductCode: string,
        bundleContingents: IBundleContingent[]
    ): ViewTypes.WizardComposerObject => {
        const composerObject: ViewTypes.WizardComposerObject = {
            apiObject: {
                orderItem: {
                    items: [
                        this.getWebspaceOrchestrationObject(metadata, undefined, bundleContingents)
                    ],
                    name: metadata.bundleName,
                    productCode: metadata.productCode,
                    type: 'Bundle'
                },
                ownerAccountId: metadata.account.id
            },
            summaryObject: [],
            productFamily: metadata.productFamily
        };

        // add domain orchestration object
        (composerObject.apiObject.orderItem.items[0].items as any[]).push(
            this._getDomainOrchestrationObject(metadata, domainOrderProductCode, bundleContingents)
        );

        // add database orchestration objects
        const databaseAmount = parseInt(`${metadata.databaseAmount}`);
        for (let i = 1; i < databaseAmount + 1; i++)
        {
            composerObject.apiObject.orderItem.items.push(
                this.getDatabaseOrchestrationObject(
                    metadata,
                    i,
                    undefined,
                    bundleContingents
                )
            );
        }

        return composerObject;
    };

    public osNeedsExtraUser = (osImageId: string): boolean => {
        return /ubuntu/.test(osImageId);
    };

    public getWebspaceOrchestrationObject = (
        metadata: any,
        webspaceProductCode?: string,
        bundleContingents?: IBundleContingent[]
    ): OrchestrationApi.Webspace => {
        const useCustomData = metadata.accesses.useCustom;
        const customData = metadata.accesses.customAccesses;
        const isExistingUser = useCustomData && [undefined, null, ''].indexOf(customData.webspace.userId as string) < 0;
        const webspaceObject: OrchestrationApi.Webspace = {
            name: metadata.bundleName,
            items: [],
            productCode: webspaceProductCode || this._getProductCodeFromBundleContingentsByService(
                bundleContingents,
                'webhosting'
            ),
            storage: 0,
            type: 'Webspace'
        };

        if (isExistingUser) {
            webspaceObject.userId = customData.webspace.userId;
        } else if (useCustomData) {
            webspaceObject.userName = customData.webspace.name;
            webspaceObject.password = customData.webspace.password;
        } else {
            webspaceObject.userName = `${metadata.bundleName as string} ${this.$translate.instant('TR_130319-0cb933_TR')}`;
            webspaceObject.password = metadata.accesses.password;
        }

        return webspaceObject;
    };

    public setDnsSettings = (
        metadata: ViewTypes.ProductConfigDomainDomainObject | ViewTypes.ProductConfigDomainVhostObject
    ): ViewTypes.DomainWizardDnsSettingsObject | ViewTypes.ProductConfigDomainVhostObjectDnsValue => {
        // extend dns on webpsace selection
        let dns: ViewTypes.DomainWizardDnsSettingsObject = ([undefined, null].indexOf(metadata.dns.values) >= 0)
            ? this.setDnsSettings(metadata)
            : JSON.parse(JSON.stringify(metadata.dns.values));

        if ([undefined, null, ''].indexOf(dns.templateChain as string) >= 0) {
            dns.templateChain = [undefined, null, ''].indexOf(dns.templateId) === -1;
        }

        if ([undefined, null].indexOf(dns.replacements) >= 0) {
            dns.replacements = {
                ipv4Replacement: null,
                ipv6Replacement: null,
                mxipv4Replacement: null,
                mxipv6Replacement: null
            };
        }

        if ([undefined, null].indexOf(metadata.webspace) < 0) {
            dns.addCompanyMx = false;

            if ([undefined, null, ''].indexOf(metadata.webspace.serverIpv4) < 0) {
                // set DNS ip settings from webspace serverIpv4
                dns.ip = metadata.webspace.serverIpv4;
            }

            // manually DNS settings
            if ([undefined, null, ''].indexOf(metadata.webspace.bundleId) === -1) {
                dns.addCompanyMx = true;
            }
        } else {
            if ([undefined, null].indexOf(metadata.nameserver) < 0
                && metadata.nameserver.values.type === 'external'
            ) {
                metadata.dns.type = 'extern';
            }

            switch (metadata.dns.type) {
                case 'empty':
                    dns = {
                        ip: '',
                        mxServer: [],
                        dnsSecOptions:  null,
                        replacements: null,
                        templateChain: null,
                        templateId: null
                    };
                    break;
                case 'extern':
                    dns = null;
                    break;
            }
        }

        if (metadata.bundle) {
            const bundleHandler = ApiObject.bundle(metadata.bundle);

            if (!bundleHandler?.hasWebspaceContingent) {
                dns.addCompanyMx = true;
            }
        }

        return dns;
    };

    public ownNameserverSelected = (
        metadata: ViewTypes.ProductConfigDomainDomainObject
    ): boolean => {
        return [undefined, null].indexOf(metadata.nameserver) >= 0
        || (
            [undefined, null].indexOf(metadata.nameserver) < 0
            && !metadata.nameserver.values.default
            && metadata.nameserver.values.id === null
            && metadata.nameserver.values.nameservers === null
        );
    };

    public getNameserverSets = async (accountId: string): Promise<ViewTypes.DomainWizardNameserverSets[]> => {
        const ssFilters = { field: 'accountId', value: accountId };
        const ssSort = {
            field: 'nameserverSetName',
            order: 'ASC'
        };

        this._resetNameserverSets();
        return this.dnsRobot.listNameserverSet(ssFilters, 100, 1, ssSort, accountId)
            .then(
                (result: { response: ViewTypes.ApiListResponse}) => {
                    return this.nameserverSetModel.getDefault(accountId)
                        .then(
                            (defaultServer: DnsApi.NameserverSet) => {
                                const systemNameserverSet: ViewTypes.DomainWizardNameserverSets = {
                                    items: [{
                                        name: this._getNameserverSetString(defaultServer.nameservers),
                                        value: {
                                            default: true,
                                            id: defaultServer.id,
                                            nameservers: defaultServer.nameservers.map(this._setNameserverObject),
                                            type: [undefined, null].indexOf(defaultServer.type) >= 0
                                                ? 'system'
                                                : defaultServer.type
                                        }
                                    }],
                                    title: this.$translate.instant('TR_100119-7c5288_TR')
                                };

                                this._nameserverSets[0] = systemNameserverSet;
                                this.objectCache.nameserSets[0] = systemNameserverSet;
                                Object.defineProperty(
                                    this.objectCache.systemNameserver,
                                    accountId, {
                                        value: systemNameserverSet,
                                        writable: true
                                    }
                                );

                                if (result.response.data.length > 0) {
                                    result.response.data.forEach(
                                        (nameserverSet: DnsApi.NameserverSet) => {
                                            const nsSet = {
                                                name: `${nameserverSet.name} (${this._getNameserverSetString(nameserverSet.nameservers)})`,
                                                value: {
                                                    default: nameserverSet.defaultNameserverSet,
                                                    id: nameserverSet.id,
                                                    nameservers: nameserverSet.nameservers.map(
                                                        this._setNameserverObject
                                                    ),
                                                    type: nameserverSet.type
                                                }
                                            };

                                            Object.defineProperty(
                                                this.objectCache.nameserSets,
                                                nameserverSet.id,
                                                {
                                                    value: nsSet,
                                                    writable: true
                                                }
                                            );

                                            if (nameserverSet.defaultNameserverSet === true) {
                                                this._nameserverSets[0].items[0] = nsSet;
                                            } else {
                                                this._setNameserverSetListObject();
                                                this._nameserverSets[2].items.push(nsSet);
                                            }
                                        }
                                    );
                                }

                                return this._nameserverSets;
                            }
                        );
                }
            );
    };

    public getDefaultLocation = (vhost: WebhostingApi.VHost): WebhostingApi.Location => {
        if (vhost === undefined || vhost.locations === undefined) {
            return;
        }

        const defaultLocation = vhost.locations.filter((location) => {
            return location.matchType === 'default';
        });

        return defaultLocation[0];
    };

    private _getLanguage = (): string => {
        if (this._language !== null) {
            return this._language;
        }

        this._language = UiLanguagesConst[AuthContextService.user.language];

        return this._language;
    };

    private _isExistingDomainProductCode = (productCode: string): boolean => {
        return this.tldList.getList().some(entry => productCode.includes('-' + entry.unicode + '-'));
    };

    private _setNameserverObject = (ns: string): ViewTypes.Nameservers => {
        return { name: ns, ips: [], ipsRequired: false };
    };

    private _getNameserverSetString = (nameservers: string[]): string => {
        if ([undefined, null].indexOf(nameservers) >= 0) {
            return '';
        }

        return nameservers.join(', ');
    };

    private _resetNameserverSets = (): void => {
        this._nameserverSets = [
            {
                items: [{
                    name: '',
                    value: {
                        default: true,
                        id: '',
                        nameservers: [],
                        type: 'system'
                    }
                }],
                title: this.$translate.instant('TR_100119-7c5288_TR')
            },
            {
                items: [{
                    name: this.$translate.instant('TR_100119-3d31fe_TR'),
                    value: {
                        default: false,
                        id: null,
                        nameservers: null,
                        type: 'external'
                    }
                }],
                title: this.$translate.instant('TR_100119-7afa7b_TR')
            }
        ];
    };

    private _getProductCodeFromBundleContingentsByService = (
        bundleContingents: IBundleContingent[],
        service: string
    ): string => {

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        let result = null;
        if (bundleContingents && bundleContingents.length > 0) {
            result = bundleContingents.filter(
                (contingentRow) => {
                    return contingentRow.service === service;
                }
            )[0].productCodes[0];
        }
        return result;
    };

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    private _getDomainOrchestrationObject = (
        composerMetadataObject: ViewTypes.ProductConfigBundleObject,
        domainOrderProductCode: string,
        bundleContingents: IBundleContingent[]
    ) => {
        return {
            authCode: composerMetadataObject.domain.authCode.length > 0 ? composerMetadataObject.domain.authCode : null,
            billingMode: this._getBundleOrchestrationDomainBillingMode(domainOrderProductCode, bundleContingents),
            createMode: this._getBundleOrchestrationDomainCreateMode(composerMetadataObject.domain),
            domainHandle: composerMetadataObject.account.domainSettings.defaultContactOwnerId,
            domainNameUnicode: composerMetadataObject.domain.nameUnicode,
            type: 'Domain'
        };
    };

    private _getBundleOrchestrationDomainBillingMode = (
        domainOrderProductCode: string,
        bundleContingents: IBundleContingent[]
    ): string => {
        if (
            bundleContingents.some(
                (contingentRow) => {
                    return contingentRow.productCodes[0] !== undefined
                        && (contingentRow.productCodes).some((productCode: string) => {
                            return productCode === domainOrderProductCode;
                        })
                        && contingentRow.capacity >= 1;
                }
            )
        ) {
            return 'bundle';
        }

        return 'standalone';
    };

    private _getBundleOrchestrationDomainCreateMode = (
        domainMetadata: ViewTypes.ProductConfigBundleDomainObject
    ): string => {
        if (domainMetadata.vHostOnly) {
            return 'vHostOnly';
        } else if ([undefined, null, ''].indexOf(domainMetadata.authCode) < 0 && domainMetadata.authCode.length > 0) {
            return 'transfer';
        }

        return 'register';
    };

    private _getFallbackHandleId = (
        domainSettings: AccountApi.AccountDomainSettings
    ): string => {
        return domainSettings.defaultContactOwnerId
            || domainSettings.defaultContactAdminId
            || domainSettings.defaultContactTechId
            || domainSettings.defaultContactZoneId;
    };

    private _setNameserverSetListObject = (): void => {
        if (this._nameserverSets.length === 2) {
            // List object of saved nameserver sets is not defined
            this._nameserverSets.push({
                items: [],
                title: this.$translate.instant('TR_100119-ff3f23_TR')
            });
        }
    };
}
