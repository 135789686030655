import * as ng from 'angular';

export class MoleculeServiceObjectStatusDeletionScheduledController implements ng.IController {
    public static $inject: string[] = [];
    public deletionScheduled: string;
    public deletionType: string;
    public status: string;
}

export class MoleculeServiceObjectStatusDeletionScheduledComponent implements ng.IComponentOptions {
    public bindings = {
        deletionScheduled: '<',
        deletionType: '<',
        status: '<'
    };
    public controller =  MoleculeServiceObjectStatusDeletionScheduledController;
    public template = require('./service-object-status-deletion-scheduled.html');
}
