import * as ng from 'angular';
import { MachineRobotService } from './robot';

export class MachineProductsService {
    public static $inject: string[] = ['$rootScope', 'machineRobot'];

    private cachedProducts = null;
    constructor(
        private $rootScope: ng.IRootScopeService,
        private machineRobot: MachineRobotService
    ) {
        this.$rootScope.$on('login', this.load);
        this.$rootScope.$on('logout', () => {
            this.cachedProducts = null;
        });
    }

    public refresh = () => {
        this.cachedProducts = null;
        return this.load();
    };

    public get = () => {
        return this.load();
    };

    private doLoad = () => {
        return this.machineRobot.getProducts().then(res => {
            return res;
        });
    };

    private load = () => {
        if (this.cachedProducts === null) {
            this.cachedProducts = this.doLoad();
        }

        return this.cachedProducts;
    };
}
