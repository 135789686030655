import * as ng from 'angular';

export class OrganNewSubAccountUserController {
    public static $inject: string[] = ['$translate'];

    public newUser: any;
    public newSubAccount: any;
    public password: string;
    public languageDropdownItems: { name: string; value: string }[];

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.languageDropdownItems = [{
            name: this.$translate.instant('ACCOUNT.PROFILE.LANGUAGE.OPTIONS.GERMAN'),
            value: 'de'
        }, {
            name: this.$translate.instant('ACCOUNT.PROFILE.LANGUAGE.OPTIONS.ENGLISH'),
            value: 'en'
        }];
    }

    public setEmailAdress = (email) => {
        // Also set email address for the account
        if ([undefined, null].indexOf(this.newSubAccount) < 0) {
            this.newSubAccount.emailAddress = email;
        }
    };

    public languageSelected = (language) => {
        // Also set the language for the account
        if ([undefined, null].indexOf(this.newSubAccount) < 0) {
            this.newSubAccount.language = language;
        }
    };

}

export class OrganNewSubAccountUserComponent implements ng.IComponentOptions {
    public bindings = {
        newSubAccount: '=',
        newUser: '=',
        password: '='
    };
    public template = require('./sub-account-user.html');
    public controller = OrganNewSubAccountUserController;
}
