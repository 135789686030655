import * as ng from 'angular';
import { LinkListItem, PanelHeaderData } from '@/atomic-components';
import { UiRights } from '@/configuration';
import {
    AuthContextService,
    VhostModelService,
    WebspaceModelService
} from '@/services';
import * as Types from '@/types';

export interface WidgetProductWebspaceDemoObject {
    webspace: Types.WebhostingApi.Webspace;
    vhosts: Types.WebhostingApi.VHost[];
}

export class OrganDashboardWidgetProductWebspaceController implements ng.IController {
    public static $inject: string[] = ['$translate', 'vhostModel', 'webspaceModel'];

    public domainList: unknown[] = [];
    public demoData: WidgetProductWebspaceDemoObject;
    public loadingDomainList: boolean;
    public linkListDomains: LinkListItem[] = [];
    public linkListFooter: LinkListItem[] = [];
    public panelHeader: PanelHeaderData = {
        allProductsQuantity: 0,
        includeShowDetailsLink: false,
        panelHeaderRoute: '',
        panelHeaderRouteParams: {},
        panelHeaderAllRoute: '',
        panelHeaderAllRouteParams: {},
        panelIcon: 'box-alt',
        panelTitle: ''
    };
    public productQuantity = 0;
    public webspace: Types.WebhostingApi.Webspace;

    private _productExists: boolean;
    private _productLoaded: boolean;

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private vhostModel: VhostModelService,
        private webspaceModel: WebspaceModelService
    ) {
        this.loadingDomainList = true;
        this._productExists = false;
        this._productLoaded = false;
    }

    public $onInit(): void {
        if (
            !AuthContextService.isGranted(UiRights.WEB_OBJECT_LIST)
            || AuthContextService.isRootOrCompanyAccount
        ) {
            this._productLoaded = true;
            return;
        }

        void this._getLastModifiedProduct()
            .then(this._getVhosts)
            .then(this._setLinkList)
            .then(this._finishLoadingData);
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set productLoaded({}) {}
    public get productLoaded(): boolean {
        return this._productLoaded;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set productName({}) {}
    public get productName(): string {
        if (!this.productExists) {
            return this.$translate.instant('TR_290121-91d2a2_TR');
        }

        return this.webspace.name;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set productExists({}) {}
    public get productExists(): boolean {
        return this._productExists;
    }

    private _getLastModifiedProduct = (): PromiseLike<Types.WebhostingApi.Webspace> => {
        const filter = { field: 'accountId', value: AuthContextService.account.id };
        const sort = { field: 'webspaceLastChangeDate', order: 'DESC' };

        return this.webspaceModel
            .list(null, 1, filter, sort)
            .then(
                (productRes) => {
                    const productData = productRes.data as Types.WebhostingApi.Webspace[];
                    if (productData.length > 0) {
                        this.webspace = productData[0];
                        this._productExists =  productData.length > 0;
                        this.productQuantity = productData.length;
                    } else if (this.demoData) {
                        this._productExists = true;
                        this.productQuantity = 4;
                        this.webspace = this.demoData.webspace;
                    }
                    return this.webspace;
                }
            );
    };

    private _getVhosts = (): Promise<void> | Types.WebhostingApi.VHost[] => {
        if (!this.webspace) {
            return Promise.resolve();
        }

        this.panelHeader.includeShowDetailsLink = true;
        this.panelHeader.includeShowAllLink = true;
        this.panelHeader.panelHeaderRoute = 'webhosting.webspaces.id.edit';
        this.panelHeader.panelHeaderRouteParams = { webspaceId: this.webspace.id };
        this.panelHeader.panelHeaderAllRoute = 'webhosting.webspaces.overview';

        if (this.productQuantity > 0) {
            this.panelHeader.allProductsQuantity = this.productQuantity;
        }

        const sort = { field: 'vhostLastChangeDate', order: 'DESC' };
        const filters = { field: 'WebspaceId', value: this.webspace.id };
        return void this.vhostModel
            .listWithoutPagination(6, 1, filters, sort)
            .then(
                (vhostResponse) => vhostResponse.data as Types.WebhostingApi.VHost[]
            );
    };

    private _setLinkList = (
        vhosts:  void | Types.WebhostingApi.VHost[]
    ): Promise<void> => {
        if (vhosts) {
            this.linkListDomains = vhosts
                .filter(
                    (
                        domain: Types.WebhostingApi.VHost,
                        index: number,
                        array: unknown[]
                    ) => !array.some(
                        (
                            otherDomain: Types.WebhostingApi.VHost,
                            otherIndex: number
                        ) => otherIndex !== index
                            && otherDomain.domainNameUnicode.indexOf(domain.domainNameUnicode) > 0
                    )
                )
                .map(
                    (domain: Types.WebhostingApi.VHost) => ({
                        route: 'webhosting.webspaces.id.vhosts.id.edit',
                        routeParams: { webspaceId: this.webspace.id, vHostId: domain.id },
                        text: domain.domainNameUnicode
                    })
                );

            if (this.linkListDomains.length > 5) {
                this.linkListDomains = this.linkListDomains.slice(0, 5);
                this.linkListDomains.push({
                    route: 'webhosting.webspaces.id.vhosts.overview',
                    routeParams: { webspaceId: this.webspace.id },
                    text: this.$translate.instant('TR_100119-800d0e_TR')
                });
            }

            this.linkListFooter = [
                {
                    route: 'webhosting.webspaces.id.vhosts.overview',
                    routeParams: { webspaceId: this.webspace.id },
                    text: this.$translate.instant('TR_080119-acb35a_TR')
                },
                {
                    route: 'webhosting.webspaces.id.vhosts.new',
                    routeParams: { webspaceId: this.webspace.id },
                    text: this.$translate.instant('TR_100119-4a31ea_TR')
                }
            ];
        }

        this.loadingDomainList = false;
        return Promise.resolve();
    };

    private _finishLoadingData = (): void => {
        this._productLoaded = true;
    };
}

export class OrganDashboardWidgetProductWebspaceComponent implements ng.IComponentOptions {
    public bindings = {
        demoData: '<?'
    };
    public controller =  OrganDashboardWidgetProductWebspaceController;
    public template = require('./dashboard-widget-product-webspace.html');
}
