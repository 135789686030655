import * as ng from 'angular';

export class MoleculeFormRightsToggleController implements ng.IController {
    public rightNames: string[] = [];
    public rightsList: string[];
    public editableRights: string[];
    public inalienableRights: string[];
    public forceDisable = false;
    public isEditable = false;
    public keys: string;
    public items: any[];
    public exists = true;

    public $onInit() {
        if (this.keys != null && this.items != null) {
            this.setRightNames();
        } else {
            if (!this.forceDisable && this.exists) {
                this.setEditable();
            }
        }
    }

    public get checked()  {
        return this.exists && this.rightNames.every(this.isChecked);
    }

    public set checked(check) {
        for (const rightName of this.rightNames) {
            if (this.editableRights.indexOf(rightName) >= 0) {
                if (check) {
                    if (this.rightsList.indexOf(rightName) < 0) {
                        this.rightsList.push(rightName);
                    }
                } else {
                    while (this.rightsList.indexOf(rightName) >= 0
                    && (this.inalienableRights === undefined || this.inalienableRights.indexOf(rightName) < 0)) {
                        this.rightsList.splice(this.rightsList.indexOf(rightName), 1);
                    }
                }
            }
        }
    }

    private isChecked = (name: string) => {
        return this.rightsList.indexOf(name) >= 0;
    };

    private setRightNames = () => {
        for (const key of this.keys) {
            if (this.items[key] != null && this.items[key] !== undefined) {
                this.rightNames.push(this.items[key].value);
            }
        }
        if (!this.forceDisable && this.exists) {
            this.setEditable();
        }
    };

    private setEditable = () => {
        const editableRightNames = [];
        for (const right of this.rightNames) {
            if (this.editableRights.indexOf(right) >= 0) {
                editableRightNames.push(right);
                this.isEditable = true;
            }
        }
        if (editableRightNames.length > 0) {
            this.rightNames = editableRightNames;
        }
        if (!this.rightNames.some(
            (right) => {
                return this.inalienableRights === undefined || this.inalienableRights.indexOf(right) < 0;
            }
        )) {
            this.isEditable = false;
        }
    };
}

export class MoleculeFormRightsToggleComponent implements ng.IComponentOptions {
    public bindings = {
        editableRights: '<',
        forceDisable: '<',
        inalienableRights: '<',
        items: '<',
        keys: '<',
        rightNames: '<?',
        rightsList: '='
    };
    public controller = MoleculeFormRightsToggleController;
    public template = require('./rights-toggle.html');
}
