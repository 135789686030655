import ng from 'angular';

import { WebspaceRestrictionData } from '@/services';

export class MoleculeChartBundleRestrictionOverviewRowController implements ng.IController {
    public static $inject: string[] = [];

    public restrictions: WebspaceRestrictionData[];
    public restrictionLabel: string;
    public restrictionComment: string;

    public $onInit(): void {
        if ([undefined, null].indexOf(this.restrictions) >= 0 || this.restrictions.length === 0) {
            return;
        }

        this.restrictionLabel = this.restrictions[0].title;
        this.restrictionComment =  this.restrictions[0].comment;
    }
}

export class MoleculeChartBundleRestrictionOverviewRowComponent implements ng.IComponentOptions {
    public bindings = {
        restrictions: '<'
    };
    public controller =  MoleculeChartBundleRestrictionOverviewRowController;
    public template = require('./restriction-overview-bundle-row.html');
}
