import * as ng from 'angular';
import { IStateOptions } from 'angular-ui-router';

import { AuthContextService } from '@/services';
import { AdditionalFancyFilterFieldRouteObject } from '../../';

import './link-with-icon.scss';

export type HTMLAnchorElementTarget = '_blank' | '_self' | '_parent' | '_top' | string; // Route or url
export interface LinkProperties {
    additionalInfo?: string; // Title of link
    clickMethod?: any; // Custom callback use route/routeParams/routeOptions instead (when no callback applies)
    disabled?: boolean | false;
    fancyFilters?: AdditionalFancyFilterFieldRouteObject[];
    iconName?: string;
    iconNamePath?: boolean;
    inline?: boolean | false; // No icon, inline link
    isSetLogoutAs?: any; // Subaccount logout
    route: string; // Route is prioritized
    routeParams?: {};
    routeOptions?: IStateOptions | { reload: false };
    routeTarget?: HTMLAnchorElementTarget;
    reloadState?: boolean | false; // Use `routeOptions="{ reload: true }"` instead
    textModifier?: string;
    url?: string;
    value?: any;
}

export class MoleculeLinkWithIconController implements ng.IController, LinkProperties {
    public static $inject: string[] = ['$state'];

    // Hey, listen!
    // 🔸 🔸 🔸 🔸 🔸
    // You can put in a link with: [route, url, clickMethod].
    // Only use clickMethod when nessesary!
    // You can solve a lot of things with routeParams (and routeOptions might come in handy).

    public route: string; // Route is prioritized
    public url: string;

    public routeParams: {};
    public routeOptions: IStateOptions = { reload: false };

    // Settings
    public additionalInfo: string; // Title of link
    public disabled = false;
    public iconName: string;
    public iconNamePath: boolean;
    public inline = false; // No icon, inline link
    public routeTarget: HTMLAnchorElementTarget;
    public textModifier: string;

    public reloadState = false; // Use `routeOptions="{ reload: true }"` instead

    // Other
    public clickMethod: any; // Custom callback use route/routeParams/routeOptions instead (when no callback applies)
    public isSetLogoutAs: any; // Subaccount logout
    public fancyFilters: AdditionalFancyFilterFieldRouteObject[];

    constructor(
        public $state: ng.ui.IStateService,
    ) {}

    public $onInit() {
        // Default icon
        if ([undefined, null, ''].indexOf(this.iconName) >= 0) {
            this.iconName = 'chevron-right';
        }

        // Support for reloadState
        if (this.reloadState) {
            this.routeOptions.reload = true;
        }
    }

    public clickMethodDefined = () => {
        return this.clickMethod !== undefined;
    };

    public get gotRights() {
        if (this.route.length > 0) {
            const state = this.$state.get(this.route);

            if ([undefined, null].indexOf(state) >= 0 || [undefined, null].indexOf(state.data) >= 0) {
                return true;
            }
            const rightsMissing = ['isGranted', 'isGrantedAny', 'isGrantedAll'].some(
                (method) => {
                    return [undefined, null].indexOf(state.data[method]) < 0
                        && !AuthContextService[method](state.data[method]);
                },
            );

            return !rightsMissing;
        }

        return false; // No route = no check
    }

    public get routeTargetRel() {
        return [undefined, null, '', '_self', '_parent', '_top'].includes(this.routeTarget)
            ? ''
            : 'noopener noreferrer';
    }
}

export class MoleculeLinkWithIconComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        additionalInfo: '@?',
        clickMethod: '&?',
        disabled: '<?',
        iconName: '@?',
        iconNamePath: '<?',
        inline: '<?',
        isSetLogoutAs: '<?',
        reloadState: '<?', // Deprecated
        route: '@',
        routeOptions: '<?',
        routeParams: '<?',
        routeTarget: '@?target',
        textModifier: '@?modifier',
        url: '@',
    };
    public controller =  MoleculeLinkWithIconController;
    public template = require('./link-with-icon.html');
}
