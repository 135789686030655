import * as ng from 'angular';
import * as Types from '@/types/view-types/view-types';
import { EditPanelStatus } from '@/atomic-components';

export class OrganEditPanelWebspaceMinPhpVersionController {
    public static $inject: string[] = [
        '$state',
        '$timeout',
        '$translate'
    ];

    public phpVersions: Types.WebhostingApi.PhpVersion[] = [];
    public cancel;
    public $editForm;
    public $editFormOrganism;
    public panelRight;
    public panelTitle: string;
    public phpVersionsDropdown: { name: string; value: string }[] = [];
    private registrationIndex: number;
    public initialStatus = EditPanelStatus.ACTIVE;

    constructor(
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public minPhpVersionDropdownSelected = () => {
        this.$timeout(
            () => {
                this.setValidationStatus();
            }
        );
    };

    public cancelEdit = (): void => {
        this.$editFormOrganism.minPhpVersion = null;
        this.$editFormOrganism.vHostUpdateAccepted = false;
        this.cancel();
    };

    public $onInit = (): void => {
        this.phpVersionsDropdown = [{ name: this.$translate.instant('TR_090123-e3436f_TR'), value: null }];
        this.phpVersions.forEach((versionInfo) => this.phpVersionsDropdown.push(
            { name: versionInfo.version, value: versionInfo.version }
        ));
        this.registrationIndex = this.$editForm.registerValidator(this);
        this.panelTitle = this.$translate.instant('TR_300123-aaf14c_TR');
    };

    public $onDestroy = () => {
        this.$editForm.unregisterValidator(this.registrationIndex);
        this.$editForm.validatorStatus[this.registrationIndex] = true;
    };

    private setValidationStatus = () => {
        this.$timeout(
            () => {
                this.$editForm.validatorStatus[this.registrationIndex] = this.$editFormOrganism.minPhpVersion === null
                    || this.$editFormOrganism.vHostUpdateAccepted
                ;
            }
        );
    };
}

export class OrganEditPanelWebspaceMinPhpVersionComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<',
        phpVersions: '<'
    };
    public require = {
        $editForm: '^moleculeFormEdit',
        $editFormOrganism: '^organismEditFormWebspacePhpMinVersion'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./webspace-min-php-version.html');
    public controller = OrganEditPanelWebspaceMinPhpVersionController;
}
