import {
    RpcClient,
    RpcClientService
} from '../rpc-client';
import { Finding } from './../../types/finding';

import * as Types from '../../types';

export const ResourceClientService = [
    'rpcClient',
    (platformRpcClient: RpcClientService) => platformRpcClient.Factory('/api/resource/v1/json/', 'resource')
];

export class ResourceRobotService {
    public static $inject: string[] = ['resourceClient'];

    constructor(
        private resourceClient: RpcClient
    ) {}

    public listPools(filter, limit, page, sort?, timeout?, owner?) {
        return this.resourceClient.request('poolsFind')
        .useCache()
        .cacheTimeout(1000 * 60 * 5)
        .timeout(timeout)
        .ownerAccountId(owner)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public listPoolsWithoutPagination = (filter, limit, page, sort?, timeout?, owner?) => {
        return this.resourceClient.request('poolsFind')
        .skipPagination()
        .timeout(timeout)
        .ownerAccountId(owner)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public listVmHosts(filter, limit, page, sort?, timeout?) {
        return this.resourceClient.request('virtualMachineHostsFind')
        .timeout(timeout)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public findMachineHost(filter, limit, page, sort) {
        return this.resourceClient.request('virtualMachineHostsFind')
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public findNetworks(filter, limit, page, sort) {
        return this.resourceClient.request('networksFind')
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public listResources(poolId, resourceType, filter, limit, page, sort?, timeout?) {
        return this.resourceClient.request('resourcesFind')
        .timeout(timeout)
        .execute({ poolId: poolId, resourceType: resourceType, filter: filter, limit: limit, page: page, sort: sort });
    }

    public listPrivateIpAddressRanges(filter?: Finding.Filter, limit?, page?, sort?, timeout?) {
        return this.resourceClient.request('privateIpAddressRangesFind')
        .timeout(timeout)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public listPublicIpAddressRanges(filter?: Finding.Filter, limit?: number, page?: number, sort?, timeout?) {
        return this.resourceClient.request('publicIpAddressRangesFind')
        .timeout(timeout)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public createPool(pool, owner?) {
        return this.resourceClient.request('poolCreate')
        .bustCache('resource', 'poolsFind')
        .ownerAccountId(owner)
        .execute({ pool: pool });
    }

    public databaseServersFind(
        filter: Types.Finding.Filter = null,
        limit: number = 25,
        page: number = 1,
        sort: Types.Finding.SortOptions = null,
        skipPagination: boolean = false
    ) {
        return this.resourceClient.request('databaseServersFind')
        .useCache()
        .cacheTimeout(1000 * 60 * 5)
        .skipPagination(skipPagination)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public webserversFind(
        filter: Types.Finding.Filter = null,
        limit: number = 25,
        page: number = 1,
        sort: Types.Finding.SortOptions = null,
        skipPagination: boolean = false
    ) {
        return this.resourceClient.request('webserversFind')
        .useCache()
        .cacheTimeout(1000 * 60 * 5)
        .skipPagination(skipPagination)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }
}
