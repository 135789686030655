import * as ng from 'angular';
import { OrganismOverviewController, OverviewDataObject } from '../../../../organisms';

export class MoleculeOverviewRowPanelBundleController implements ng.IController {
    public static $inject: string[] = [];

    public overviewData: OverviewDataObject;
    public OrganismOverview: OrganismOverviewController;
    public overviewLoad: (selection: any) => any;
    public internalPaginationLimit: number;

    public get showAccounts() {
        if (this.OrganismOverview !== undefined
            && this.OrganismOverview.globals !== undefined
            && this.OrganismOverview.globals.showAccounts !== undefined
        ) {
            return this.OrganismOverview.globals.showAccounts;
        }

        return false;
    }
}

export class MoleculeOverviewRowPanelBundleComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelBundleController;
    public template = require('./bundle-row.html');
}
