import ng from 'angular';
import '@/assets/styles/main.scss';
import { UiLanguageDefaultSettingsConst, UiLanguagesConst, UiLanguagesReverseConst } from '@/configuration';
import {
    AlertManagerService,
    AuthContextService,
    AuthenticationRobotService,
    ClientTransactionIdContext,
    NavigationService,
    RobotTranslate
} from '@/services';
import { IgnoredApiCodes } from '@/services/errors/ignored-api-codes.enum';
import { HTTPInterceptors } from './development/http-interceptors';
import { IUrlRouterProvider } from 'angular-ui-router';
import * as Sentry from '@sentry/browser';

import { GridStack } from 'gridstack/dist/gridstack';

window.GridStack = GridStack;

// order matters
import 'angular-i18n/angular-locale_de-de';
import 'angular-ui-router';
import 'angular-ui-bootstrap';
import 'ui-select';
import 'angular-sanitize';
import 'angular-animate';
import 'clipboard';
import 'ngclipboard';
import 'angular-toastr';
import 'angulartics';
import 'angulartics/src/angulartics-piwik';
import 'angular-chart.js';
import 'chart.js';
import 'md-progress-circular/src/mdProgressCircular';
import 'angular-load';
import 'angular-cache';
import '@/services/translate-ng';
import 'angular-qr';
import 'angularjs-slider';
import '../../env/sentryConfig';

export const MODEL_REFRESH_DEBOUNCE_WAIT = 300;

const angularPlugin = [
    'ui.router',
    'ui.bootstrap',
    'ui.select',
    'ngSanitize',
    'ngAnimate',
    'ngclipboard',
    'ui.bootstrap.showErrors',
    'toastr',
    'ngSentry',
    'angulartics.piwik',
    'chart.js',
    'material.components.progressCircular',
    'angularLoad',
    'angular-cache',
    'robot.translate',
    // 'keenCsr',
    'ja.qr',
    'rzSlider'
];

if (__TEST_MODE__ === true) {
    angularPlugin.push('ngMockE2E');
}

export const showErrors = ng.module('ui.bootstrap.showErrors', []);

export const app = ng.module(
    'robotInterfaceApp',
    angularPlugin
);

app
    .config(($locationProvider: ng.ILocationProvider) => {
        $locationProvider.html5Mode(true);
    })

    .config(($urlRouterProvider: IUrlRouterProvider) => {
    /**
     * Workaround für https://github.com/angular-ui/ui-router/issues/600 rip angular - never fixed.
     *
     * Dies sollte eigentlich wie folgt aussehen:
     * $urlRouterProvider.otherwise('/404');
     */
        $urlRouterProvider.otherwise(
            ($injector: ng.auto.IInjectorService, $location: ng.ILocationService) => {
                const $state: ng.ui.IStateService = $injector.get('$state');
                $state.go('404').then(
                    undefined,
                    (e) => NavigationService.handleTransitionErrors(
                        e,
                        "navigation app.config go('404')",
                    ),
                );
                return $location.path();
            }
        );
    })

    .config((uibPaginationConfig: any) => {
        uibPaginationConfig.boundaryLinks = true;
        uibPaginationConfig.rotate = false;
        uibPaginationConfig.firstText = '«';
        uibPaginationConfig.previousText = '‹';
        uibPaginationConfig.nextText = '›';
        uibPaginationConfig.lastText = '»';
        uibPaginationConfig.maxSize = 10;
    })

    .config((toastrConfig: any) => {
        toastrConfig.extendedTimeOut = 2500;
        toastrConfig.maxToastr = 5;
        toastrConfig.positionClass = 'toast-bottom-right';
        toastrConfig.preventOpenDuplicates = true;
        toastrConfig.timeOut = 6000;
    })

    .config(($compileProvider: any) => {
        $compileProvider.debugInfoEnabled(false);
    })

    .run(($rootScope: ng.IRootScopeService, $window: ng.IWindowService) => {
        $rootScope.$on(
            '$stateChangeStart',
            () => {
                $window.scrollTo(0, 0);
            }
        );
    })

    .run(($rootScope: ng.IRootScopeService, authContext: AuthContextService) => {
    // Wir machen den authContext global Verfügbar, damit wir ihn in Template Expressions nutzen können
        ($rootScope as any).authContext = authContext;
    })

    .run(($rootScope: ng.IRootScopeService, $document: ng.IDocumentService) => {
        const body = $document[0].body;

        $rootScope.$on(
            'login',
            ({}, {}, account) => {
                if (account.id === '1') {
                    body.classList.add('root-user');
                }
            }
        );

        $rootScope.$on(
            'logout',
            ({}, {}, account) => {
                if (account.id === '1') {
                    body.classList.remove('root-user');
                }
            }
        );
    })

    .config(($provide: ng.auto.IProvideService) => {
        $provide.decorator(
            '$q',
            ($delegate: ng.IQService) => {
                const $q = $delegate;

                ($q as any).allSettled = (promises: any) => {
                    const wrappedPromises = ng.isArray(promises)
                        ? promises.slice(0)
                        : {};

                    ng.forEach(
                        promises,
                        (promise, index: number) => {
                            wrappedPromises[index] = promise.then(
                                (value: any) => ({ state: 'fulfilled', value: value }),
                                (reason: any) => ({ state: 'rejected', reason: reason })
                            );
                        }
                    );

                    return $q.all(wrappedPromises);
                };

                return $q;
            }
        );
    })

    .config(
        ($translateProvider: RobotTranslate) => {
            let prefLanguage = UiLanguageDefaultSettingsConst.languageUiIso;
            const knownLanguages = [];
            const knownShorts = [];
            for (const lang in UiLanguagesReverseConst) {
                if (Object.prototype.hasOwnProperty.call(UiLanguagesReverseConst, lang)) {
                    knownLanguages.push(lang);
                }
            }

            for (const lang in UiLanguagesConst) {
                if (Object.prototype.hasOwnProperty.call(UiLanguagesConst, lang)) {
                    knownShorts.push(lang);
                }
            }

            const navigatorLanguage = navigator.language;
            if (navigatorLanguage && navigatorLanguage.length) {
                const langIndex = knownShorts.indexOf(navigator.language.substr(0, 2));
                if (langIndex > -1) {
                    prefLanguage = knownLanguages[langIndex];
                }
            }

            $translateProvider
                .setDevMode(__TEST_MODE__ === true)
                .use(prefLanguage);
        }
    )

    .config([
        '$httpProvider',
        ($httpProvider: ng.IHttpProvider) => {
            $httpProvider.interceptors.push(HTTPInterceptors.RequestInterceptor);
            $httpProvider.interceptors.push(HTTPInterceptors.RequestErrorInterceptor);
            $httpProvider.interceptors.push(HTTPInterceptors.ResponseInterceptor);
            $httpProvider.interceptors.push(HTTPInterceptors.ResponseErrorInterceptor);
        }
    ])

    .run(
        ($window: ng.IWindowService, $rootScope: ng.IRootScopeService) => {
            ClientTransactionIdContext.setVersion(__VERSION__);

            $rootScope.$on(
                '$stateChangeStart',
                // eslint-disable-next-line no-empty-pattern
                ({}, toState, {}, fromState) => {

                    try {
                        $window.localStorage.setItem('lastRouterState', JSON.stringify(fromState));
                    } catch (e) {
                        // safari private browser tab (let's feed this error to the cookie-monster)
                        return;
                    }

                    ClientTransactionIdContext.setState(toState.name);
                    ClientTransactionIdContext.startResolving();
                }
            );

            $rootScope.$on(
                '$stateChangeSuccess',
                () => {
                    ClientTransactionIdContext.finishResolving();
                }
            );

            $rootScope.$on(
                '$stateChangeError',
                () => {
                    ClientTransactionIdContext.finishResolving();
                }
            );
        }
    )

    .run(
        ($rootScope: ng.IRootScopeService, cancelLocalIntervals: any) => {
            $rootScope.$on('$stateChangeStart', cancelLocalIntervals);
        }
    )

    .run(
        (
            $rootScope: ng.IRootScopeService,
            navigation: NavigationService,
            authenticationRobot: AuthenticationRobotService,
            $state: ng.ui.IStateService,
            alertManager: AlertManagerService
        ) => {
            let authenticationStatusChecked = false;

            authenticationRobot.getAuthenticationDetails().then(
                () => {
                    authenticationStatusChecked = true;
                    if (NavigationService.lastTarget.state) {
                        $state.go(NavigationService.lastTarget.state.name, NavigationService.lastTarget.params).then(
                            undefined,
                            (e) => NavigationService.handleTransitionErrors(
                                e,
                                'navigation app.run go resolve',
                                {
                                    lastTargetName: NavigationService.lastTarget.state.name,
                                    lastTargetParams: NavigationService.lastTarget.params,
                                },
                            ),
                        );
                    }
                },
                () => {
                    authenticationStatusChecked = true;
                    $state.go('login', {expired: true}).then(
                        undefined,
                        (e) => NavigationService.handleTransitionErrors(
                            e,
                            'navigation app.run go reject',
                            {
                                expired: true,
                            },
                        ),
                    );
                }
            );

            $rootScope.$on(
                '$stateChangeError',
                (event, toState, toParams, fromState, fromParams, err) => {
                    if ([IgnoredApiCodes.SESSION_INVALID, IgnoredApiCodes.SESSION_EXPIRED].indexOf(err.code) >= 0) {
                        navigation.onSessionExpired();
                    }

                    event.preventDefault();

                    if (err.name === 'NotFoundError') {
                        return $state.go('404', { err: err, toState: toState.name, params: toParams}).then(
                            undefined,
                            (e) => NavigationService.handleTransitionErrors(
                                e,
                                "navigation $rootScope.$on('$stateChangeError') go('404')",
                                {
                                    error: err,
                                    toStateName: toState.name,
                                    params: toParams,
                                },
                            ),
                        );
                    }

                    const fromParamsString = JSON.stringify(fromParams);
                    const toParamsString = JSON.stringify(toParams);

                    if (
                        [undefined, null, ''].indexOf(err.message) < 0
                    && err.message.indexOf('[$compile:tpload]') >= 0
                    ) {
                        window.location.href = '/error/429.html';
                    }

                    console.groupCollapsed('Error while changing state');
                    console.info(`Coming from state '${fromState.name}' with parameters ${fromParamsString}`);
                    console.info(`Trying to transition to state '${toState.name}' with parameters ${toParamsString}`);
                    console.error(err);
                    console.groupEnd();

                    if (['dashboard'].indexOf(toState.name) >= 0) {
                        return $state.go('pageloaderror').then(
                            undefined,
                            (e) => NavigationService.handleTransitionErrors(
                                e,
                                "navigation $rootScope.$on('$stateChangeError') go('pageloaderror')",
                            ),
                        );
                    } else if (['pageloaderror'].indexOf(toState.name) < 0) {
                        return $state.go('dashboard')
                            .then(
                                () => {
                                    const extra = {
                                        fromState: fromState,
                                        toState: toState,
                                        fromParamsString: fromParamsString,
                                        toParamsString: toParamsString
                                    };
                                    console.error(/* translationId */ 'GENERAL.STATE-CHANGE-ERROR', extra);
                                    if (AuthContextService.isAdminUser) {
                                        alertManager.error(/* translationId */ 'GENERAL.STATE-CHANGE-ERROR', err, err.code);
                                    }
                                    Sentry.captureMessage(/* translationId */ 'GENERAL.STATE-CHANGE-ERROR', {
                                        extra: extra,
                                        tags: { key: 'state' }
                                    });
                                },
                                (e) => NavigationService.handleTransitionErrors(
                                    e,
                                    "navigation $rootScope.$on('$stateChangeError') go('dashboard')",
                                ),
                            );
                    }
                }
            );

            $rootScope.$on('$stateChangeStart', (event, toState, toParams) => {
                if (!authenticationStatusChecked) {
                    event.preventDefault();
                    NavigationService.lastTarget = {state: toState, params: toParams};

                    return;
                }
                if (
                    (
                        toState.data === undefined
                    || toState.data.authenticate === undefined
                    || toState.data.authenticate
                    )
                && !AuthContextService.authenticated
                ) {
                // User isn’t authenticated. Redirect to authentication
                    event.preventDefault();
                    $state.go('login').then(
                        undefined,
                        (e) => NavigationService.handleTransitionErrors(
                            e,
                            "navigation $rootScope.$on('$stateChangeStart') go('login')",
                        ),
                    );
                } else if (AuthContextService.authenticated && toState.name === 'login') {
                    event.preventDefault();
                    $state.go('dashboard').then(
                        undefined,
                        (e) => NavigationService.handleTransitionErrors(
                            e,
                            "navigation $rootScope.$on('$stateChangeStart') go('dashboard')",
                        ),
                    );
                } else if (toState.data !== undefined) {
                    if (toState.data.isGranted !== undefined) {
                        if (Array.isArray(toState.data.isGranted)) {
                            if (!AuthContextService.isGrantedAny(toState.data.isGranted)) {
                                event.preventDefault();
                            // @todo Zeige dem Benutzer eine Fehlermeldung
                            }
                        } else {
                            if (!AuthContextService.isGranted(toState.data.isGranted)) {
                                event.preventDefault();
                            // @todo Zeige dem Benutzer eine Fehlermeldung
                            }
                        }
                    } else if (toState.data.isGrantedAny !== undefined) {
                        if (Array.isArray(toState.data.isGrantedAny)) {
                            if (!AuthContextService.isGrantedAny(toState.data.isGrantedAny)) {
                                event.preventDefault();
                                // @todo Zeige dem Benutzer eine Fehlermeldung
                            }
                        }
                    } else if (toState.data.isGrantedAll !== undefined) {
                        if (Array.isArray(toState.data.isGrantedAll)) {
                            if (!AuthContextService.isGrantedAll(toState.data.isGrantedAll)) {
                                event.preventDefault();
                            // @todo Zeige dem Benutzer eine Fehlermeldung
                            }
                        }
                    }
                }

                /**
             * Gewisse Sachen funktionieren für Root Benutzer nicht,
             * weil Root nicht mit einem STS-Matchcode versehen ist
             *
             * @todo Evtl. mal mit dem Backend klären, ob man da bei denen noch etas machen kann.
             */
                if (toState.data !== undefined
                && toState.data.noRoot !== undefined
                && toState.data.noRoot === true
                && AuthContextService.account.id === '1') {
                    event.preventDefault();
                // @todo Zeige dem Benutzer eine Fehlermeldung
                }
            });
        }
    )

    .config([
        '$httpProvider',
        ($httpProvider: ng.IHttpProvider) => {
            if ((window as any).puiVersionPrefix !== undefined) {
                $httpProvider.interceptors.push(
                    () => (
                        {
                            request: (config: ng.IRequestConfig) => {
                                if (
                                    config.url.indexOf('http') !== 0
                                && config.url.indexOf('/api/') !== 0
                                && config.url.indexOf('/utils/') !== 0
                                && (
                                    [undefined, null, false, true].indexOf(config.cache) >= 0
                                     || config.cache.get(config.url) === undefined
                                )
                                ) {
                                    config.url = (window as any).puiVersionPrefix + '/' + config.url;
                                }

                                return config;
                            }
                        }
                    )
                );
            }
        }
    ]);
