import { UserRobotService } from './robot';

export class UserSettingsModelService {
    public static $inject: string[] = ['userRobot'];

    constructor(private userRobot: UserRobotService) {}

    public loadSettings = () => {
        return this.userRobot.getUserOwnUiSettings().then((result) => result.response);
    };

    public update = (settings) => {
        return this.userRobot.setUserOwnUiSettings(settings).then((result) => result.response);
    };
}
