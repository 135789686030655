import * as ng from 'angular';
import { MoleculeFormEditController } from '@/atomic-components/molecules/forms/form-edit/form-edit';
import {
    MoleculePanelEditTableController
} from '@/atomic-components/molecules/panels/panel-edit/table/panel-edit-table';
import {
    OrganismEditFormNextcloudController
} from '@/atomic-components/organisms/forms/edit-forms/nextcloud-edit/nextcloud-edit';
import { NextcloudUnlimitedStorageValue } from '@/configuration';
import { ManagedApplicationRobotService } from '@/services';
import { Finding, ManagedApplicationApi, UI } from '@/types';

export class MoleculePanelEditRowTableNextcloudGroupFoldersController implements ng.IController {
    public static $inject: string[] = ['$timeout', '$translate', 'managedApplicationRobot'];

    public $editForm: MoleculeFormEditController;
    public $organismEditForm: OrganismEditFormNextcloudController;
    public $panelEditTable: MoleculePanelEditTableController;
    public hideGroupSelection = false;
    public groupFolderBeingEdited: boolean|string = false;
    public selectedGroupToAdd = '';
    public hiddenGroups: string[] = [];

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private managedApplicationRobot: ManagedApplicationRobotService
    ) {}

    public inEditList = (groupFolder: ManagedApplicationApi.NextcloudGroupFolder): boolean => {
        return this.$organismEditForm.groupFoldersToEdit.some(
            (editedFolder) => editedFolder === groupFolder.id
        );
    };

    public isBeingEdited = (groupFolder: ManagedApplicationApi.NextcloudGroupFolder): boolean => {
        return this.$organismEditForm.groupFoldersToEdit.some(
            (editedFolder) => editedFolder === groupFolder.id
        ) && groupFolder.id === this.groupFolderBeingEdited;
    };

    public cancelGroupFolderEdit = (groupFolder: ManagedApplicationApi.NextcloudGroupFolder): void => {
        this.$organismEditForm.aggregatedGroupDataCache[groupFolder.id] = undefined;
        this.$organismEditForm.groupFoldersToEdit = this.$organismEditForm.groupFoldersToEdit.filter(
            (folderId) => folderId !== groupFolder.id
        );
        this.groupFolderBeingEdited = false;
    };

    public listCallback = (
        limit?: number,
        page?: number,
        filters?: Finding.Filter,
        sort?: Finding.SortOptions
    ): PromiseLike<ManagedApplicationApi.FindNextcloudGroupsResult> => {
        const tmpFilters = {
            subFilter: [
                {
                    field: 'NextcloudId',
                    value: this.$organismEditForm.nextcloud.id,
                    relation: 'equal'
                }
            ],
            subFilterConnective: 'AND'
        };

        if (this.groupFolderBeingEdited !== false) {
            const currentGroupFolder = this.$organismEditForm
                .aggregatedGroupDataCache[(this.groupFolderBeingEdited as string)];

            for (const existingGroup of currentGroupFolder.groupPermissions) {
                tmpFilters.subFilter.push(
                    {
                        field: 'NextcloudGroupName',
                        value: existingGroup.groupName,
                        relation: 'unequal'
                    }
                );
            }
        }

        for (const hiddenGroup of this.hiddenGroups) {
            tmpFilters.subFilter.push(
                {
                    field: 'NextcloudGroupName',
                    value: hiddenGroup,
                    relation: 'unequal'
                }
            );
        }

        if ([undefined, null].indexOf(filters) < 0) {
            tmpFilters.subFilter.push(filters as { field: string; value: string; relation: string });
        }

        return this.managedApplicationRobot.nextcloudGroupsFind(
            tmpFilters,
            limit,
            page,
            sort ?? {
                field: 'NextcloudGroupName',
                order: 'ASC'
            }
        ).then((apiResponse: UI.SynchronousAPIResponse<ManagedApplicationApi.FindNextcloudGroupsResult>) => {
            return apiResponse.response;
        });
    };

    public addGroupToFolder = (groupFolderId: string): void => {
        const newGroupList = this.$organismEditForm.aggregatedGroupDataCache[groupFolderId].groupNames;
        newGroupList.push(this.selectedGroupToAdd);
        this.$organismEditForm.aggregatedGroupDataCache[groupFolderId].callback(newGroupList);

        void this.$timeout(() => {
            this.selectedGroupToAdd = null;
        });
    };

    public checkQuota = (change: {value: number|undefined; params: { index: number }}): void => {
        if (change !== undefined && change.value === undefined) {
            this.$organismEditForm.nextcloudGroupFolders[change.params.index].quotaInGb = 1;
        }
    };

    public groupSelectionChanged = (groupFolderId: string): { callback: (newGroupNames: string[]) => void } => {
        return {
            callback: (newGroupNames: string[]) => {
                const groupPermissions: ManagedApplicationApi.NextcloudGroupFolderPermissionsCache[] = [];

                newGroupNames.forEach(
                    (groupName) => {
                        const alreadyExisted = this.$organismEditForm.aggregatedGroupDataCache[groupFolderId]
                            .groupPermissions.filter(
                                (existingGroup) => existingGroup.groupName === groupName
                            )
                        ;

                        if (alreadyExisted.length === 1) {
                            groupPermissions.push(alreadyExisted[0]);
                        } else {
                            groupPermissions.push(
                                {
                                    groupName: groupName,
                                    permissions: {
                                        write: false,
                                        remove: false,
                                        share: false
                                    }
                                }
                            );
                        }
                    }
                );

                this.$organismEditForm.aggregatedGroupDataCache[groupFolderId].groupPermissions = groupPermissions;
            }
        };
    };

    public commitGroupFolderChange = (
        groupData: { groupFolder: ManagedApplicationApi.NextcloudGroupFolder }
    ): void => {
        if ([null, undefined].indexOf(this.selectedGroupToAdd) < 0) {
            this.addGroupToFolder(groupData.groupFolder.id);
        }

        this.groupFolderBeingEdited = false;
    };

    public enableEditMode = (groupFolder: ManagedApplicationApi.NextcloudGroupFolder): void => {
        this.groupFolderBeingEdited = groupFolder.id;
        this.selectedGroupToAdd = null;

        if (this.$organismEditForm.aggregatedGroupDataCache[groupFolder.id] === undefined) {
            this.$organismEditForm.aggregatedGroupDataCache[groupFolder.id] = {
                groupNames: groupFolder.groupPermissions.map((group) => group.groupName),
                groupPermissions: [],
                callback: (newGroupNames: string[]) => {
                    const groupPermissions: ManagedApplicationApi.NextcloudGroupFolderPermissionsCache[] = [];

                    newGroupNames.forEach(
                        (groupName: string) => {
                            const alreadyExisted = this.$organismEditForm.aggregatedGroupDataCache[groupFolder.id]
                                .groupPermissions.filter(
                                    (existingGroup: ManagedApplicationApi.NextcloudGroupFolderPermissionsCache) => {
                                        return existingGroup.groupName === groupName;
                                    }
                                )
                            ;

                            if (alreadyExisted.length === 1) {
                                groupPermissions.push(alreadyExisted[0]);
                            } else {
                                groupPermissions.push(
                                    {
                                        groupName: groupName,
                                        permissions: {
                                            write: false,
                                            remove: false,
                                            share: false
                                        }
                                    }
                                );
                            }
                        }
                    );

                    this.$organismEditForm.aggregatedGroupDataCache[groupFolder.id].groupPermissions = groupPermissions;
                }
            };

            groupFolder.groupPermissions.forEach(
                (existingGroup: ManagedApplicationApi.NextcloudGroupFolderPermissions) => {
                    this.$organismEditForm.aggregatedGroupDataCache[groupFolder.id].groupPermissions.push({
                        groupName: existingGroup.groupName,
                        permissions: {
                            write: existingGroup.permissions.indexOf('write') >= 0,
                            remove: existingGroup.permissions.indexOf('remove') >= 0,
                            share: existingGroup.permissions.indexOf('share') >= 0
                        }
                    });
                }
            );
        }

        groupFolder.unlimitedStorage = (groupFolder.quota === NextcloudUnlimitedStorageValue);
        groupFolder.quotaInGb = (groupFolder.quota === NextcloudUnlimitedStorageValue)
            ? 1
            : (groupFolder.quota / 1024);
        this.$organismEditForm.groupFoldersToEdit.push(groupFolder.id);
    };

    public groupFolderRemoveGroup = (groupId: { groupFolderId: string; groupName: string }): void => {
        let currentGroupNames = this.$organismEditForm.aggregatedGroupDataCache[groupId.groupFolderId].groupNames;

        currentGroupNames = currentGroupNames.filter(
            (selectedGroupName) => selectedGroupName !== groupId.groupName
        );

        this.$organismEditForm.aggregatedGroupDataCache[groupId.groupFolderId].groupNames = currentGroupNames;
        this.groupSelectionChanged(groupId.groupFolderId).callback(currentGroupNames);
        this.refreshGroupSelectDropdown();
    };

    public refreshGroupSelectDropdown = (): void => {
        this.hideGroupSelection = true;

        void this.$timeout(() => {
            this.hideGroupSelection = false;
        });
    };

    public inDeleteList = (groupFolder: ManagedApplicationApi.NextcloudGroupFolder): boolean => {
        return this.$organismEditForm.groupFoldersToDelete.some(
            (folderId) => folderId === groupFolder.id
        );
    };

    public toggleDelete = (groupFolder: ManagedApplicationApi.NextcloudGroupFolder): void => {
        if (this.inDeleteList(groupFolder)) {
            this.$organismEditForm.groupFoldersToDelete = this.$organismEditForm.groupFoldersToDelete.filter(
                (folderId) => folderId !== groupFolder.id
            );
        } else {
            this.$organismEditForm.groupFoldersToDelete.push(groupFolder.id);
        }

        this.$editForm.validateAll();
    };

    public toggleDeleteConfirmation = (): void => {
        this.$organismEditForm.groupFoldersDeletionConfirmed = !this.$organismEditForm.groupFoldersDeletionConfirmed;
        this.$editForm.validateAll();
    };

    public addNewFolder = (returnObject: ManagedApplicationApi.NextcloudGroupFolder): void => {
        this.$organismEditForm.nextcloudGroupFolders.unshift(returnObject);
    };

    public isNewFolder = (folderId: string): boolean => {
        return /^generated/.test(folderId);
    };

    public getGroupListText = (
        groupFolderId: string,
        groupFolderPermissions: ManagedApplicationApi.NextcloudGroupFolderPermissions[]
    ): string => {
        const groupListOverlay: ManagedApplicationApi.NextcloudGroupFolderPermissionsCache[] =
            this.$organismEditForm.aggregatedGroupDataCache[groupFolderId]?.groupPermissions;

        if (
            [null, undefined].indexOf(groupListOverlay) >= 0
            || (Array.isArray(groupListOverlay) && groupListOverlay.length === 0)
        ) {
            if (!Array.isArray(groupFolderPermissions)) {
                return '';
            }

            return groupFolderPermissions.map((group) => group.groupName).join(', ');
        } else {
            return groupListOverlay.map((group) => group.groupName).join(', ');
        }
    };

    public getShowChangedQuotaText = (groupFolder: ManagedApplicationApi.NextcloudGroupFolder): boolean => {
        return !!groupFolder.quotaInGb && !groupFolder.unlimitedStorage;
    };

    public getShowInitialQuotaText = (groupFolder: ManagedApplicationApi.NextcloudGroupFolder): boolean => {
        return groupFolder.quota > 0 && !!groupFolder.quotaInGb === false;
    };

    public getShowGroupRightsEdit = (groupFolder: ManagedApplicationApi.NextcloudGroupFolder): boolean => {
        return this.isBeingEdited(groupFolder)
            && this.$organismEditForm.aggregatedGroupDataCache[groupFolder.id].groupPermissions.length > 0;
    };

    public getEditButtonDisabled = (groupFolder: ManagedApplicationApi.NextcloudGroupFolder): boolean => {
        return this.inDeleteList(groupFolder)
            || this.groupFolderBeingEdited !== false
            || this.$panelEditTable.createNewObject;
    };

    public getShowUnlimitedStorageText = (groupFolder: ManagedApplicationApi.NextcloudGroupFolder): boolean => {
        return groupFolder.unlimitedStorage
            || (
                !!groupFolder.quotaInGb === false
                && groupFolder.quota === NextcloudUnlimitedStorageValue
            );
    };
}

export class MoleculePanelEditRowTableNextcloudGroupFoldersComponent implements ng.IComponentOptions {
    public require = {
        $editForm: '^moleculeFormEdit',
        $organismEditForm: '^organismEditFormNextcloud',
        $panelEditTable: '^moleculePanelEditTable'
    };

    public controller = MoleculePanelEditRowTableNextcloudGroupFoldersController;
    public controllerAs = '$panelEditRowTable';
    public template = require('./nextcloud-group-folders.html');
}
