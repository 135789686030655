import * as ng from 'angular';

export class DomainCheckStatus {
    public static $inject: string[] = ['$translate'];

    public static Factory($translate: ng.translate.ITranslateService) {
        const domainCheckStatusList = {
            alreadyRegistered: $translate.instant('DOMAIN.DOMAINCHECK.STATUS.ALREADY-REGISTERED'),
            apiError: $translate.instant('DOMAIN.DOMAINCHECK.STATUS.API-ERROR'),
            available: $translate.instant('DOMAIN.DOMAINCHECK.STATUS.AVAILABLE'),
            canNotCheck: $translate.instant('DOMAIN.DOMAINCHECK.STATUS.CAN-NOT-CHECK'),
            checking: $translate.instant('DOMAIN.DOMAINCHECK.STATUS.CHECKING'),
            extensionCannotBeRegistered: $translate.instant('DOMAIN.DOMAINCHECK.STATUS.EXTENSION-CANNOT-BE-REGISTERED'),
            extensionDoesNotExist: $translate.instant('DOMAIN.DOMAINCHECK.STATUS.EXTENSION-DOES-NOT-EXIST'),
            extensionNotAllowed: $translate.instant('DOMAIN.DOMAINCHECK.STATUS.EXTENSION-NOT-ALLOWED'),
            nameContainsForbiddenCharacter:
                $translate.instant('DOMAIN.DOMAINCHECK.STATUS.NAME-CONTAINS-FORBIDDEN-CHARACTER'),
            nameTooShort: $translate.instant('TR_080319-790fcd_TR'),
            redemptionPeriod: $translate.instant('DOMAIN.DOMAINCHECK.STATUS.REDEMPTION-PERIOD'),
            registered: $translate.instant('DOMAIN.DOMAINCHECK.STATUS.REGISTERED'),
            restorable: $translate.instant('DOMAIN.DOMAINCHECK.STATUS.RESTORABLE'),
            suffixCannotBeRegistered: $translate.instant('DOMAIN.DOMAINCHECK.STATUS.SUFFIX-CANNOT-REGISTERED')
        };

        return (value) => value in domainCheckStatusList ? domainCheckStatusList[value] : value;
    }
}
