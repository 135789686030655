import { ModelHelper } from '../model-helper';
import { DatabaseRobotService } from './robot';

import * as Types from '../../types';

export class DatabaseJobModelService {
    public static $inject: string[] = ['databaseRobot'];

    constructor(
        private databaseRobot: DatabaseRobotService
    ) {}

    public list = (limit, page, filters, sort) => {
        sort = sort || {
            field: 'jobDatabaseId',
            order: 'DESC'
        };
        page = page || 1;

        return this.databaseRobot.listJobs(filters, limit, page, sort).then(ModelHelper.returnListResponse);
    };

    public findRunningJobsForDatabase = (database: Types.DatabaseApi.Database) => {
        const filter = {
            subFilter: [
                { field: 'jobObjectType', value: 'Database' },
                { field: 'jobObjectId', value: database.id },
                { field: 'jobStatus', relation: 'unequal', value: 'successful' },
                { field: 'jobStatus', relation: 'unequal', value: 'failed' },
                { field: 'jobStatus', relation: 'unequal', value: 'canceled' }
            ],
            subFilterConnective: 'AND'
        };

        return this.databaseRobot.listJobs(filter, 25, 1, null);
    };

    public findRunningJobsForUser = (user: Types.DatabaseApi.User) => {
        const filter = {
            subFilter: [
                { field: 'jobObjectType', value: 'DatabaseUser' },
                { field: 'jobObjectId', value: user.id },
                { field: 'jobStatus', relation: 'unequal', value: 'successful' },
                { field: 'jobStatus', relation: 'unequal', value: 'failed' },
                { field: 'jobStatus', relation: 'unequal', value: 'canceled' }
            ],
            subFilterConnective: 'AND'
        };

        return this.databaseRobot.listJobs(filter, 25, 1, null);
    };

    public findOneById = (id) => {
        return this.databaseRobot.listJobs({field: 'jobId', value: id}, 10, 1).then(ModelHelper.returnFindOneResponse);
    };

    public findOne = (id: string) => {
        return this.databaseRobot.listJobs({field: 'jobId', value: id}, 1, 1).then(ModelHelper.returnFindOneResponse);
    };
}
