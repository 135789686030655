import * as q from 'q';
import { UiRights } from '../../../../configuration';
import {
    AccountModelService,
    AuthContextService,
    BundleModelService,
    DatabaseModelService,
    DnsZoneModelService,
    DomainHandleModelService,
    DomainModelService,
    MachineModelService,
    MailboxModelService,
    ManagedApplicationRobotService,
    RedirectionModelService,
    SslCertificateModelService,
    UserModelService,
    VhostModelService,
    WebspaceModelService
} from '../../../../services';
import * as Types from '../../../../types';

export interface SearchSource {
    model: {
        list: (
            limit?: number,
            page?: number,
            filter?: Types.Finding.Filter,
            sort?: Types.Finding.SortOptions
        ) => q.IPromise<any>;
        [key: string]: any;
    };
    type: string;
    filterProperty: (field: string, value: string) => Types.Finding.Filter;
    filter: (query: string) => Types.Finding.Filter;
    filterId: (id: string) => Types.Finding.Filter;
    right: string;
}

export class GlobalSearchSources {
    public static $inject: string[] = [
        'accountModel',
        'bundleModel',
        'databaseModel',
        'dnsZoneModel',
        'domainHandleModel',
        'domainModel',
        'machineModel',
        'mailboxModel',
        'managedApplicationRobot',
        'redirectionModel',
        'sslCertificateModel',
        'userModel',
        'vhostModel',
        'webspaceModel'
    ];

    constructor(
        private accountModel: AccountModelService,
        private bundleModel: BundleModelService,
        private databaseModel: DatabaseModelService,
        private dnsZoneModel: DnsZoneModelService,
        private domainHandleModel: DomainHandleModelService,
        private domainModel: DomainModelService,
        private machineModel: MachineModelService,
        private mailboxModel: MailboxModelService,
        private managedApplicationRobot: ManagedApplicationRobotService,
        private redirectionModel: RedirectionModelService,
        private sslCertificateModel: SslCertificateModelService,
        private userModel: UserModelService,
        private vhostModel: VhostModelService,
        private webspaceModel: WebspaceModelService
    ) {}

    public get sources(): SearchSource[] {
        const availableSources: SearchSource[] = [
            this.accountSearchSource,
            this.bundleSearchSource,
            this.vHostSearchSource,
            this.domainSearchSource,
            this.mailboxSearchSource,
            this.databaseSearchSource,
            this.machineSearchSource,
            this.nextcloudSearchSource,
            this.webspaceSearchSource,
            this.dnsSearchSource,
            this.domainHandleSearchSource,
            this.sslCertificateSearchSource,
            this.userSearchSource,
            this.redirectionSearchSource
        ];

        return availableSources.filter(
            (source: SearchSource) => AuthContextService.isGranted(source.right)
        );
    }

    private _filterProperty = (field: string, value: string): Types.Finding.Filter => {
        return { field: field, value: value };
    };

    private get accountSearchSource(): SearchSource {
        return {
            filterProperty: this._filterProperty,
            filter: (query) => {
                return {
                    subFilter: [
                        { field: 'subaccountComments', value: '*' + query + '*' },
                        { field: 'subaccountCustomerNumber', value: '*' + query + '*' },
                        { field: 'subaccountEmailAddress', value: '*' + query + '*' },
                        { field: 'subaccountName', value: '*' + query + '*' },
                        { field: 'subaccountId', value: '*' + query + '*' }
                    ],
                    subFilterConnective: 'OR'
                };
            },
            filterId: (id) => {
                return { field: 'subaccountId', value: id };
            },
            model: this.accountModel,
            right: UiRights.ACC_SUBACCOUNT_LIST,
            type: 'account'
        };
    }

    private get bundleSearchSource(): SearchSource {
        return {
            filterProperty: this._filterProperty,
            filter: (query) => {
                return {
                    subFilter: [
                        { field: 'BundleName', value: '*' + query + '*' },
                        { field: 'BundleId', value: query },
                        { field: 'AccountId', value: query }
                    ],
                    subFilterConnective: 'OR'
                };
            },
            filterId: (id) => {
                return { field: 'BundleId', value: id };
            },
            model: this.bundleModel,
            right: UiRights.BIL_BUNDLE_LIST,
            type: 'bundle'
        };
    }

    private get vHostSearchSource(): SearchSource {
        return {
            filterProperty: this._filterProperty,
            filter: (query) => {
                return {
                    subFilter: [
                        { field: 'vhostDomainNameUnicode', value: '*' + query + '*' },
                        { field: 'webspaceId', value: query },
                        { field: 'vhostId', value: query },
                        { field: 'accountId', value: query }
                    ],
                    subFilterConnective: 'OR'
                };
            },
            filterId: (id) => {
                return { field: 'vhostId', value: id };
            },
            model: this.vhostModel,
            right: UiRights.WEB_OBJECT_LIST,
            type: 'vhost'
        };
    }

    private get domainSearchSource(): SearchSource {
        return {
            filterProperty: this._filterProperty,
            filter: (query) => {
                return {
                    subFilter: [
                        { field: 'domainNameUnicode', value: '*' + query + '*' },
                        { field: 'domainId', value: query },
                        { field: 'accountId', value: query },
                        { field: 'bundleId', value: query }
                    ],
                    subFilterConnective: 'OR'
                };
            },
            filterId: (id) => {
                return { field: 'domainId', value: id };
            },
            model: this.domainModel,
            right: UiRights.DOM_DOMAINS_LIST,
            type: 'domain'
        };
    }

    private get mailboxSearchSource(): SearchSource {
        return {
            filterProperty: this._filterProperty,
            filter: (query) => {
                return {
                    subFilter: [
                        { field: 'mailboxEmailAddressUnicode', value: '*' + query + '*' },
                        { field: 'mailboxId', value: query },
                        { field: 'accountId', value: query }
                    ],
                    subFilterConnective: 'OR'
                };
            },
            filterId: (query) => {
                return { field: 'mailboxId', value: query };
            },
            model: this.mailboxModel,
            right: UiRights.EMAIL_MAILBOX_LIST,
            type: 'mail'
        };
    }

    private get databaseSearchSource(): SearchSource {
        return {
            filterProperty: this._filterProperty,
            filter: (query) => {
                return {
                    subFilter: [
                        { field: 'databaseName', value: '*' + query + '*' },
                        { field: 'databaseDbName', value: '*' + query + '*' },
                        { field: 'databaseId', value: query },
                        { field: 'bundleId', value: query },
                        { field: 'accountId', value: query }
                    ],
                    subFilterConnective: 'OR'
                };
            },
            filterId: (id) => {
                return { field: 'databaseId', value: id };
            },
            model: this.databaseModel,
            right: UiRights.DB_DATABASE_LIST,
            type: 'database'
        };
    }

    private get machineSearchSource(): SearchSource {
        return {
            filterProperty: this._filterProperty,
            filter: (query) => {
                return {
                    subFilter: [
                        { field: 'virtualMachineName', value: '*' + query + '*' },
                        { field: 'virtualMachineIpAddress', value: query },
                        { field: 'virtualMachineId', value: query },
                        { field: 'accountId', value: query }
                    ],
                    subFilterConnective: 'OR'
                };
            },
            filterId: (id) => {
                return { field: 'virtualMachineId', value: id };
            },
            model: this.machineModel,
            right: UiRights.MACHINE_VM_LIST,
            type: 'machine'
        };
    }

    private get nextcloudSearchSource(): SearchSource {
        return {
            filterProperty: this._filterProperty,
            filter: (query) => {
                return {
                    subFilter: [
                        { field: 'nextcloudName', value: '*' + query + '*' },
                        { field: 'nextcloudId', value: query },
                        { field: 'accountId', value: query }
                    ],
                    subFilterConnective: 'OR'
                };
            },
            filterId: (query) => {
                return { field: 'nextcloudId', value: query };
            },
            model: {
                list: (
                    limit?: number,
                    page?: number,
                    filter?: Types.Finding.Filter,
                    sort?: Types.Finding.SortOptions
                ): q.IPromise<any> => {
                    return this.managedApplicationRobot.nextcloudsList(filter, limit, page, sort);
                }
            },
            right: UiRights.MANAGED_APPLICATION_NEXTCLOUD_LIST,
            type: 'managedApplication'
        };
    }

    private get webspaceSearchSource(): SearchSource {
        return {
            filterProperty: this._filterProperty,
            filter: (query) => {
                return {
                    subFilter: [
                        { field: 'webspaceName', value: '*' + query + '*' },
                        { field: 'webspaceWebspaceName', value: query },
                        { field: 'webspaceId', value: query },
                        { field: 'bundleId', value: query },
                        { field: 'accountId', value: query }
                    ],
                    subFilterConnective: 'OR'
                };
            },
            filterId: (query) => {
                return { field: 'webspaceId', value: query };
            },
            model: this.webspaceModel,
            right: UiRights.WEB_OBJECT_LIST,
            type: 'webspace'
        };
    }

    private get dnsSearchSource(): SearchSource {
        return {
            filterProperty: this._filterProperty,
            filter: (query) => {
                return {
                    subFilter: [
                        { field: 'zoneEmailAddress', value: '*' + query + '*' },
                        { field: 'zoneNameUnicode', value: '*' + query + '*' },
                        { field: 'zoneMasterIp', value: query },
                        { field: 'accountId', value: query }
                    ],
                    subFilterConnective: 'OR'
                };
            },
            filterId: (id) => {
                return { field: 'ZoneConfigId', value: id };
            },
            model: this.dnsZoneModel,
            right: UiRights.DNS_ZONES_LIST,
            type: 'zone'
        };
    }

    private get domainHandleSearchSource(): SearchSource {
        return {
            filterProperty: this._filterProperty,
            filter: (query) => {
                return {
                    subFilter: [
                        { field: 'contactName', value: '*' + query + '*' },
                        { field: 'contactEmailAddress', value: '*' + query + '*' },
                        { field: 'contactPhoneNumber', value: '*' + query + '*' },
                        { field: 'contactId', value: query },
                        { field: 'accountId', value: query }
                    ],
                    subFilterConnective: 'OR'
                };
            },
            filterId: (id) => {
                return { field: 'contactId', value: id };
            },
            model: this.domainHandleModel,
            right: UiRights.DOM_CONTACTS_LIST,
            type: 'handle'
        };
    }

    private get sslCertificateSearchSource(): SearchSource {
        return {
            filterProperty: this._filterProperty,
            filter: (query) => {
                return {
                    subFilter: [
                        { field: 'certificateCommonName', value: '*' + query + '*' },
                        { field: 'certificateSerialNumber', value: query },
                        { field: 'certificateId', value: query },
                        { field: 'accountId', value: query }
                    ],
                    subFilterConnective: 'OR'
                };
            },
            filterId: (id) => {
                return { field: 'certificateId', value: id };
            },
            model: this.sslCertificateModel,
            right: UiRights.SSL_CERTIFICATES_LIST,
            type: 'certificate'
        };
    }

    private get redirectionSearchSource(): SearchSource {
        return {
            filterProperty: this._filterProperty,
            filter: (query) => {
                return {
                    subFilter: [
                        { field: 'redirectionDomainNameUnicode', value: '*' + query + '*' },
                        { field: 'redirectionRedirectionUrl', value: '*' + query + '*' },
                        { field: 'redirectionId', value: query },
                        { field: 'accountId', value: query }
                    ],
                    subFilterConnective: 'OR'
                };
            },
            filterId: (id) => {
                return { field: 'redirectionId', value: id };
            },
            model: this.redirectionModel,
            right: UiRights.WEB_REDIRECTIONS_LIST,
            type: 'redirection'
        };
    }

    private get userSearchSource(): SearchSource {
        return {
            filterProperty: this._filterProperty,
            filter: (query) => {
                return {
                    subFilter: [
                        { field: 'userName', value: '*' + query + '*' },
                        { field: 'userEmailAddress', value: '*' + query + '*' },
                        { field: 'userId', value: '*' + query + '*' }
                    ],
                    subFilterConnective: 'OR'
                };
            },
            filterId: (id) => {
                return { field: 'userId', value: id};
            },
            model: this.userModel,
            right: UiRights.ACC_SUBACCOUNT_USER_LIST,
            type: 'user'
        };
    }
}
