import './storage-quota-changer.scss';

import ng from 'angular';

import {
    ContractPeriodWord,
    CycleBillingTranslationConst,
    UiLanguageDefaultSettingsConst,
    UiLanguagesConst,
    UiRights,
} from '@/configuration';
import { CalculatePriceFilter } from '@/filters';
import {
    AccountHelperService, AuthContextService, PriceCacheService, ProductHelperService, ProductsModelService
} from '@/services';
import * as Types from '@/types';

export interface IStorageItem {
    label: string;
    productCode: string;
    value: any;
    price: Types.BillingApi.ArticlePurchasePrice;
}

export class MoleculeFormStorageQuotaChangerController implements ng.IController {
    public static $inject: string[] = [
        'accountHelper',
        'calculatePriceFilter',
        'productsModel',
        'priceCache',
        '$translate'
    ];

    public oldTotal: number;
    public used: number;
    public unit: string;
    public step: number;
    public min: number;
    public max: number;
    public additionalStorageItems: number;
    public storageProductInfo: Types.ProductApi.Product;
    public storagePriceInfo: Types.BillingApi.ArticlePurchasePrice;
    public productCode: string;
    public productType: string;
    public inPool = false;
    public hideUsedSpace = false;
    public storageItem: IStorageItem;
    public currency = 'EUR';
    public pricePostfixText: string;

    public onChangeCallback: () => {};

    private _language: string = UiLanguageDefaultSettingsConst.languageUiIso;
    private contractPeriodDisplays: Record<string, string> = {};

    private cache: Record<number, number> = {};

    constructor(
        public accountHelper: AccountHelperService,
        private calculatePriceFilter: CalculatePriceFilter,
        private productsModel: ProductsModelService,
        private priceCache: PriceCacheService,
        protected $translate: ng.translate.ITranslateService
    ) {
        this._language = UiLanguagesConst[AuthContextService.user.language as 'de' | 'en'];
        this.contractPeriodDisplays = {
            annually: this.$translate.instant('TR_090119-c59c72_TR'),
            monthly: this.$translate.instant('TR_090119-04522d_TR')
        };
    }

    public $onInit(): void {
        this.additionalStorageItems = this.additionalStorageItems || 0;
    }

    public $onChanges(change: ng.IOnChangesObject): void {
        if (change.productCode !== undefined && typeof change.productCode.currentValue === 'string') {
            this.setStorageItem(change.productCode.currentValue);
        }
    }

    public increase = (): void => {
        if (this.model > (this.max - this.step)) {
            return;
        }

        this.additionalStorageItems ++;

        if (this.onChangeCallback) {
            this.onChangeCallback();
        }
    };

    public get model(): number {
        if (this.additionalStorageItems === 0 || this.step === undefined) {
            return ng.copy(this.oldTotal);
        }

        if ([undefined, null].indexOf(this.cache[this.additionalStorageItems]) >= 0) {
            this.cache[this.additionalStorageItems] = ng.copy(this.oldTotal)
                + this.step * this.additionalStorageItems;
        }

        return this.cache[this.additionalStorageItems];
    }

    public set model(value: number) {
        if (this.step === undefined) {
            return;
        }

        this.additionalStorageItems = (value - this.oldTotal) / this.step;
    }

    public decrease = (): void => {
        if (this.model < (this.min + this.step)) {
            return;
        }

        this.additionalStorageItems --;

        if (this.onChangeCallback) {
            this.onChangeCallback();
        }
    };

    public get change(): number {
        return this.model - this.oldTotal;
    }

    public get costs(): number | string {
        if (!AuthContextService.isGranted(UiRights.BIL_LIST_ARTICLE_PRICES)) {
            return 0;
        }

        if (this.inPool) {
            return 0;
        }

        if (this.storagePriceInfo === undefined || this.storagePriceInfo === null) {
            return undefined;
        }

        if (this.storagePriceInfo.currency) {
            this.currency = this.storagePriceInfo.currency.toUpperCase();
        }

        if (this.additionalStorageItems < 0) {
            return 0;
        } else {
            return this.calculatePriceFilter(
                this.storagePriceInfo.netAmount,
                this.storagePriceInfo.amounts[0].vatRate,
                this.additionalStorageItems,
                'gross',
                null,
                false,
                this.storagePriceInfo.productCode,
                this.storagePriceInfo.contractPeriod
            ).toFixed(2);
        }
    }

    public get tax(): number | string {
        if (this.storagePriceInfo === undefined) {
            return undefined;
        }
        if (this.additionalStorageItems < 0) {
            return 0;
        } else {
            return (
                this.calculatePriceFilter(
                    this.storagePriceInfo.netAmount,
                    this.storagePriceInfo.amounts[0].vatRate,
                    this.additionalStorageItems,
                    'gross',
                    null,
                    false,
                    this.storagePriceInfo.productCode,
                    this.storagePriceInfo.contractPeriod
                )
                - this.calculatePriceFilter(
                    this.storagePriceInfo.netAmount,
                    this.storagePriceInfo.amounts[0].vatRate,
                    this.additionalStorageItems,
                    'net',
                    null,
                    true,
                    this.storagePriceInfo.productCode,
                    this.storagePriceInfo.contractPeriod
                )
            ).toFixed(2);
        }
    }

    public get contractPeriod(): string {
        return this.contractPeriodDisplays[this.storagePriceInfo.contractPeriod];
    }

    public setStorageItem = (productCode: string): void => {
        if (this.storageItem?.productCode) {
            void this.priceCache.getPriceByProductCode(this.storageItem?.productCode).then(
                (response: Types.BillingApi.ArticlePurchasePrice[]) => {
                    if (response.length === 1) {
                        this.storageItem.label = response[0].name;
                        this.storageItem.price = response[0];
                        this.storagePriceInfo = response[0];
                    }
                }
            ).then(this._setPricePostfixText);
        } else {
            void this.productsModel.findOne(productCode).then(
                (reply) => {
                    void this.productsModel.findStorageProduct(
                        productCode,
                        reply.response as Types.ProductApi.TemplateProduct,
                        this._language
                    ).then(
                        (ret: Types.UI.APIResponse<Types.ProductApi.Product>) => {
                            this.storageProductInfo = ret.response;

                            const storageSpec = ProductHelperService.wrapSpecificationItems(
                                this.storageProductInfo.specificationItems
                            ).storage;

                            if (storageSpec !== undefined) {
                                if (this.storageItem !== undefined) {
                                    this.storageItem.value = storageSpec.intValue;
                                    this.storageItem.label = ret.response.name;
                                    this.storageItem.productCode = ret.response.productCode;
                                }

                                this.step = Number(storageSpec.intValue);
                            }
                        }
                    );

                    void (this.priceCache.getStorageItemPrice(
                        productCode,
                        reply.response as Types.ProductApi.TemplateProduct,
                        this.productType
                    ) as Promise<any>).then(
                        (ret: Types.BillingApi.ArticlePurchasePrice) => {
                            if (this.storageItem !== undefined) {
                                this.storageItem.price = ret;
                            }

                            this.storagePriceInfo = ret;
                        }
                    ).then(this._setPricePostfixText);
                }
            );
        }
    };

    private _setPricePostfixText = (): void => {
        switch (this.storagePriceInfo?.contractPeriod as ContractPeriodWord) {
            case 'monthly':
                this.pricePostfixText = `/ ${this.$translate.instant(CycleBillingTranslationConst[this.storagePriceInfo.contractPeriod as ContractPeriodWord])}`;
                break;
            case 'quarterly':
                this.pricePostfixText = `/ ${this.$translate.instant(CycleBillingTranslationConst[this.storagePriceInfo.contractPeriod as ContractPeriodWord])}`;
                break;
            case 'semiannually':
                this.pricePostfixText = `/ ${this.$translate.instant(CycleBillingTranslationConst[this.storagePriceInfo.contractPeriod as ContractPeriodWord])}`;
                break;
            case 'annually':
                this.pricePostfixText = `/ ${this.$translate.instant(CycleBillingTranslationConst[this.storagePriceInfo.contractPeriod as ContractPeriodWord])}`;
                break;
            case 'biennially':
                this.pricePostfixText = `/ ${this.$translate.instant(CycleBillingTranslationConst[this.storagePriceInfo.contractPeriod as ContractPeriodWord])}`;
                break;
            case 'triennially':
                this.pricePostfixText = `/ ${this.$translate.instant(CycleBillingTranslationConst[this.storagePriceInfo.contractPeriod as ContractPeriodWord])}`;
                break;
            case 'quadriennally':
                this.pricePostfixText = `/ ${this.$translate.instant(CycleBillingTranslationConst[this.storagePriceInfo.contractPeriod as ContractPeriodWord])}`;
                break;
            case 'fiveyearly':
                this.pricePostfixText = `/ ${this.$translate.instant(CycleBillingTranslationConst[this.storagePriceInfo.contractPeriod as ContractPeriodWord])}`;
                break;
            case 'tenyearly':
                this.pricePostfixText = `/ ${this.$translate.instant(CycleBillingTranslationConst[this.storagePriceInfo.contractPeriod as ContractPeriodWord])}`;
                break;
            case 'none':
            default:
                this.pricePostfixText = undefined;
                break;
        }
    };
}

export class MoleculeFormStorageQuotaChangerComponent implements ng.IComponentOptions {
    public bindings = {
        additionalStorageItems: '=?',
        hideUsedSpace: '<?',
        inPool: '<',
        max: '<',
        min: '<',
        model: '=?',
        oldTotal: '<',
        onChangeCallback: '<',
        productCode: '<',
        productType: '@',
        step: '<',
        storageItem: '=',
        unit: '@',
        used: '<'
    };
    public controllerAs = '$storageQuotaChanger';
    public controller =  MoleculeFormStorageQuotaChangerController;
    public template = require('./storage-quota-changer.html');
}
