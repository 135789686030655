import * as ng from 'angular';
import { PanelHeaderData } from '@/atomic-components/molecules/panels';
import { NextcloudFreeProductCodeConst, UiRights } from '@/configuration';
import { AuthContextService } from '@/services/auth-context';
import { NextcloudHelperService } from '@/services/helpers/nextcloud-helper';
import { BackupApi, ManagedApplicationApi } from '@/types';
import { IBackupAdminService } from '@/services/backup/IBackupAdminService';

export class TemplateStorageProductBackupController {
    public static $inject: string[] = ['$state', '$translate', 'nextcloudHelper', 'backupAdminService'];

    public faqArticleIdList: string[] = [];
    public pageHeaderData: PanelHeaderData = {
        backwardLink: 'storage.storage-products.overview',
        backwardText: this.$translate.instant('TR_071020-722106_TR'),
        panelHeaderRoute: '',
        panelIcon: '/assets/images/logos/nextcloud-bw.svg',
        panelIconPath: true,
        panelTitle: '',
        productActions: []
    };

    public storageProduct: ManagedApplicationApi.Nextcloud;
    public storageUsers: ManagedApplicationApi.NextcloudUser[];
    public storageGroupFolders: ManagedApplicationApi.NextcloudGroupFolder[];
    public adminData: BackupApi.BackupExtendedObject;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private nextcloudHelper: NextcloudHelperService,
        private backupAdminService: IBackupAdminService
    ) {}

    public $onInit(): void {

        this.backupAdminService.getAdminBackupData(
            'managedapplication',
            'Nextcloud',
            this.storageProduct.id)
            .then(data => {
                this.adminData = data;
            }).then(() => {

            const route = this.$state.current.name.split('.id.')[0];
            const routeName =  this.$state.current.name.split('.id.')[1];
            // based on the routeName we could show different actions

            void this.nextcloudHelper.checkNextcloudJobRunning(this.storageProduct.id).then((jobRunning) => {
                // 1. Rename
                if (AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT)) {
                    this.pageHeaderData.productActions.push({
                        disabled: jobRunning,
                        route: `${route}.id.rename`,
                        title: this.$translate.instant('TR_171019-47b3be_TR')
                    });
                }

                // 2. Upgrade
                if (AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT)) {
                    this.pageHeaderData.productActions.push({
                        disabled: jobRunning,
                        route: `${route}.id.upgrade`,
                        title: this.$translate.instant('TR_030919-a153f2_TR')
                    });
                }

                // 4. Cancel
                if (
                    this.storageProduct.status !== 'restorable'
                    && AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_DELETE)
                ) {
                    this.pageHeaderData.productActions.push({
                        disabled: jobRunning,
                        route: `${route}.id.cancel`,
                        title: this.storageProduct.deletionScheduledFor !== null
                            ? this.$translate.instant('TR_110320-b4a598_TR')
                            : this.storageProduct.productCode === NextcloudFreeProductCodeConst
                                ? this.$translate.instant('TR_130120-11e249_TR')
                                : this.$translate.instant('TR_020819-188cc3_TR')
                    });
                }

                // 5. Restrictions
                if (AuthContextService.isGrantedAll([
                    UiRights.ADMIN_SYSTEM_SUPER_USER_READ,
                    UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT
                ])) {
                    this.pageHeaderData.productActions.push({
                        route: `${route}.id.restrictions`,
                        stateParams: { storageProductId: this.storageProduct.id },
                        title: this.$translate.instant('TR_250519-7f279b_TR')
                    });
                }

                // 6. Neu
                if (AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_CREATE)) {
                    this.pageHeaderData.productActions.push({
                        route: `${route}.new`,
                        title: this.$translate.instant('TR_110919-dcde08_TR')
                    });
                }

                if (AuthContextService
                    .isGrantedAny(
                        [ UiRights.ADMIN_SYSTEM_SUPER_USER_EDIT, UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN ]
                    )
                ) {
                    this.pageHeaderData.productActions.push(
                        {
                            title: this.$translate.instant('TR_070720-f5a487_TR'),
                            route: `${route}.id.admin-access`
                        }
                    );
                }
            });
    }).catch(() => {

    });

    }

    public get storageProductIsNextcloud(): boolean {
        return this.storageProduct.productCode.split('-').indexOf('nextcloud') >= 0;
    }

    private get _originalStorageProduct(): ManagedApplicationApi.Nextcloud {
        return (ng.copy(this.$state.$current.locals.globals.storageProduct) as ManagedApplicationApi.Nextcloud);
    }

    private get _originalGroupFolders(): ManagedApplicationApi.NextcloudGroupFolder[] {
        return (ng.copy(
            this.$state.$current.locals.globals.storageGroupFolders
        ) as ManagedApplicationApi.NextcloudGroupFolder[]);
    }
}

export class TemplateStorageProductBackupComponent implements ng.IComponentOptions {
    public bindings = {
        backups: '<',
        storageProduct: '<'
    };
    public controller = TemplateStorageProductBackupController;
    public controllerAs = '$ctrl';
    public template = require('./storage-product-backup-template.html');
}
