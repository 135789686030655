import * as ng from 'angular';

export class OrganEditPanelContactEmailController implements ng.IController {
    public initialStatus;
    public contactEmail: string;
    public invoiceEmail: string;
    public newsletterEmail: string;
    public technicalContactEmail: string;
    public cancelCallbackFunction: () => void;
    public saveCallbackFunction: () => void;
    public panelRightSettings: any;

}

export class OrganEditPanelContactEmailComponent implements ng.IComponentOptions {
    public bindings = {
        contactEmail: '=',
        invoiceEmail: '=',
        newsletterEmail: '=',
        technicalContactEmail: '=',
        panelRightSettings: '<',
        cancelCallbackFunction: '<',
        saveCallbackFunction: '<',
        initialStatus: '<'
    };
    public template = require('./contact-email.html');
    public controller = OrganEditPanelContactEmailController;
}
