import { ModelHelper } from '../model-helper';
import { MachineRobotService } from './robot';

import * as Types from '../../types';

export class MachineJobModelService {
    public static $inject: string[] = ['machineRobot'];

    constructor(
        private machineRobot: MachineRobotService
    ) {}

    public list = (limit?, page?, filters?, sort?) => {
        sort = sort || {
                field: 'jobDisplayName',
                order: 'ASC'
            };
        page = page || 1;

        return this.machineRobot.listJobs(filters, limit, page, sort)
            .then(ModelHelper.returnListResponse)
            .then(
                (result) => {
                    // @todo API Workaround
                    result.data.forEach((job) => job.errors = this.parseJobError(job.errors));

                    return result;
                }
            );
    };

    public findRunningJobsForMachine = (machine: Types.MachineApi.VirtualMachine) => {
        const filter = {
            subFilter: [
                { field: 'jobObjectType', value: 'VirtualMachine' },
                { field: 'jobObjectId', value: machine.id },
                { field: 'jobStatus', relation: 'unequal', value: 'successful' },
                { field: 'jobStatus', relation: 'unequal', value: 'failed' },
                { field: 'jobStatus', relation: 'unequal', value: 'canceled' }
            ],
            subFilterConnective: 'AND'
        };

        return this.machineRobot.listJobs(filter, 25, 1, null);
    };

    public findOne = (id: string) => {
        return this.machineRobot.listJobs({ field: 'jobId', value: id }, 1, 1)
            .then(ModelHelper.returnFindOneResponse)
            .then(
                (job) => {
                    // @todo API Workaround
                    job.errors = this.parseJobError(job.errors);

                    return job;
                }
            );
    };

    public findById = (ids: string[]) => {
        const filter = { subFilterConnective: 'OR', subFilter: ids.map(this.idFilter) };

        return this.machineRobot.listJobs(filter, 0, 1)
            .then(this.returnResponseDate)
            .then(
                (result) => {
                    // @todo API Workaround
                    for (const job of result) {
                        job.errors = this.parseJobError(job.errors);
                    }

                    return result;
                }
            );
    };

    private parseJobError = (error) => {
        if (error === '' || !error) {
            return [];
        }

        return JSON.parse(error);
    };

    private idFilter = (id: string) => ({ field: 'jobId', value: id });

    private returnResponseDate = (result) => result.response.data;
}
