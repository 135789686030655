import * as ng from 'angular';

import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import * as Types from '../../../types';

export class TemplateDatabaseBackupsController {
    public static $inject: string[] = ['$state', '$translate'];

    public pageHeaderData: PanelHeaderData;

    public backups: Types.BackupApi.Backup[];
    public database: Types.DatabaseApi.Database;
    public userPanelRight = {};

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: this.$state.current.name.replace(/\.backups.overview$/, '.edit'),
            backwardText: this.$translate.instant('TR_250519-7274a2_TR'),
            panelHeaderRoute: '',
            panelIcon: 'hdd',
            panelTitle: this.$translate.instant('TR_170719-13af1c_TR')
        };
    };
}

export class TemplateDatabaseBackupsComponent implements ng.IComponentOptions {
    public bindings = {
        backups: '<',
        database: '<'
    };
    public controller = TemplateDatabaseBackupsController;
    public controllerAs = '$TemplateDatabaseBackupsCtrl';
    public template = require('./database-backups-template.html');
}
