import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import {
    DashboardOverviewObject
} from '../../../atomic-components/organisms/overview/dashboard-overviews/dashboard-overviews';
import { UiRights } from '../../../configuration';
import { AuthContextService, DatabaseOverviewModel, FilterCacheService } from '../../../services';

export class TemplateDatabaseDashboardController {
    public static $inject: string[] = ['$translate', 'authContext', 'databaseOverviewModel', 'filterCache'];

    public userHasRightDbDatabaseList = AuthContextService.isGranted(UiRights.DB_DATABASE_LIST); // used in template!
    public pageHeaderData: PanelHeaderData;
    public dashboardOverview: DashboardOverviewObject;
    public faqArticleIdList: string[] = [
        'database-access',
        'database-phpmyadmin',
        'database-extern-access'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private databaseOverviewModel: DatabaseOverviewModel,
        private filterCache: FilterCacheService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            panelHeaderRoute: '',
            panelIcon: 'database',
            panelTitle: this.$translate.instant('TR_100119-63000e_TR')
        };

        this.dashboardOverview = {
            // List of table service data
            overviewsData: [
                {
                    listOptions: {
                        limit: 6,
                        sort: {
                            field: 'DatabaseLastChangeDate',
                            order: 'DESC'
                        }
                    },
                    overviewModel: this.databaseOverviewModel,
                    panelHeaderData: {
                        includeShowAllLink: true,
                        includeShowDetailsLink: false,
                        panelHeaderRoute: 'database.databases.overview',
                        panelHeaderRouteParams: {},
                        panelIcon: 'database',
                        panelTitle: this.$translate.instant('TR_090119-f0ccfb_TR')
                    },
                    serviceItemList: []
                }
            ],
            // Dashboard main menu panel
            panelData: {
                addtionalActionData: {
                    // Addition action panel
                    linkList: [
                        {
                            isGranted: this.authContext.isGranted(UiRights.DB_USER_LIST),
                            route: 'database.users.overview',
                            routeParams: {},
                            text: this.$translate.instant('TR_100119-3176b5_TR')
                        },
                        {
                            isGranted: this.authContext.isGranted(UiRights.DB_JOBS_LIST),
                            route: 'database.jobs',
                            routeParams: {},
                            text: this.$translate.instant('MENU.CONTEXT.JOBS')
                        }
                    ]
                },
                mainHeader: {
                    icon: 'database',
                    isGranted: this.authContext.isGranted(UiRights.DB_DATABASE_CREATE),
                    route: 'database.databases.new',
                    routeParams: {},
                    text: this.$translate.instant('TR_100119-dfe5ab_TR')
                }
            },
            simpleFilter: this.filterCache.resolveSimpleFilter('Databases')
        };
    };
}

export class TemplateDatabaseDashboardComponent implements ng.IComponentOptions {
    public bindings = {};
    public controller = TemplateDatabaseDashboardController;
    public controllerAs = '$TemplateDatabaseDashboardCtrl';
    public template = require('./dashboard-template.html');
}
