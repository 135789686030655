import * as ng from 'angular';
import { SubAccountOverviewModelService } from '../../../services';

export class TemplateSubAccountOverviewController implements ng.IController {
    public static $inject: string[] = ['$translate', 'subAccountOverviewModel'];

    public expertView: boolean;
    public overviewServiceData: any;
    public pageHeaderData = {
        backwardLink: 'reseller.dashboard',
        backwardText: this.$translate.instant('TR_090119-5b9243_TR'),
        panelHeaderRoute: 'reseller.subaccounts.overview',
        panelIcon: 'address-book',
        panelTitle: this.$translate.instant('STATE.ACCOUNT.SUBACCOUNTS.PAGE-TITLE')
    };

    private extendedFilters: any = null;
    private overviewUiSettings: any;

    constructor(
        private $translate: ng.translate.ITranslateService,
        public subAccountOverviewModel: SubAccountOverviewModelService
    ) {}

    public $onInit() {
        this.expertView = this.overviewUiSettings.expertView || false;
        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: { value: '' }
        };
    }

    public expandWrapper = (v) => {
        this.expertView = v.expand;
    };
}

export class TemplateSubAccountOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        overviewUiSettings: '<'
    };
    public controller = TemplateSubAccountOverviewController;
    public template = require('./sub-account-overview.html');
}
