import * as ng from 'angular';

export class MoleculePanelCreateTwoFAContentController implements ng.IController {
    public twoFAData: any;
    public confirmCode: () => any;
    public altMethod = false;

    public toggleMethod = () => {
        this.altMethod = !this.altMethod;
    };
}

export class MoleculePanelCreateTwoFAContentComponent implements ng.IComponentOptions {
    public bindings = {
        twoFAData: '='
    };
    public controller = MoleculePanelCreateTwoFAContentController;
    public template = require('./panel-create-two-f-a-content.html');
}
