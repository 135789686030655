import ng from 'angular';

import {
    AccessLevels,
    CreateDatabaseAccessObject,
    DatabaseAccessObject,
    DatabaseUserObject,
    EditPanelRight,
    EditPanelStatus
} from '@/atomic-components/molecules';
import { UiRights } from '@/configuration';
import {
    AlertManagerService,
    ApiErrorModel,
    ApiErrorObject,
    AuthContextService,
    BackupModelService,
    BundleModelService,
    DatabaseJobModelService,
    DatabaseModelService,
    DatabaseUserModelService,
    DateWrapper,
    NavigationService
} from '@/services';
import { BackupApi, BundleApi, DatabaseApi, Finding, ViewTypes } from '@/types';

export class OrganismEditFormDatabaseController {
    public static $inject: string[] = [
        '$q',
        '$state',
        '$timeout',
        '$translate',
        'alertManager',
        'apiErrorModel',
        'backupModel',
        'bundleModel',
        'databaseJobModel',
        'databaseModel',
        'databaseUserModel',
        'localInterval',
        'navigation'
    ];

    public userHasRightBackupBackupList = AuthContextService.isGranted(UiRights.BACKUP_BACKUP_LIST);
    public userPanelRight: EditPanelRight;
    public database: DatabaseApi.Database;
    public originalDatabase: DatabaseApi.Database;
    public originalDatabaseUsers: DatabaseApi.User;
    public originalBackups: BackupApi.Backup[];
    public databaseUsers: DatabaseApi.Database;
    public backups: BackupApi.Backup;

    public informationText: string;
    public newAccessList: DatabaseAccessObject[] = [];
    public newUserList: DatabaseUserObject[] = [];
    public deleteUserList = [];
    public editUserList = [];
    public additionalStorageItems = 0;
    public deleteDate: DateWrapper;
    public hasAgreedToDeleteData = false;
    public selectedRestoreAction = '';
    public initialStatus: EditPanelStatus;
    public cancelationInfoPannelTitle = '';
    public bundle: BundleApi.Bundle;
    public restorePanelRight: EditPanelRight = {};
    public showObjectId: boolean;
    public databaseHasUnfinishedJob = false;
    public backupPanelItems: {route: string; text: string}[] = [];

    public _sendingSaveRequest = false;

    private accessLevels: AccessLevels[];
    private _isPaidUntilExceeded: boolean;
    private _saveAccesses: DatabaseApi.DatabaseAccess[] = [];

    private _checkingJobStatus: Promise<void>;
    private _maxNumberOfJobChecks = 20;
    private _jobChecksPerformed = 0;
    private _interval = 1200;

    private _accessIndexToUpdate = 0;
    private _accessUpdatePromises = [];

    constructor(
        private $q: ng.IQService,
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private backupModel: BackupModelService,
        private bundleModel: BundleModelService,
        private databaseJobModel: DatabaseJobModelService,
        private databaseModel: DatabaseModelService,
        private databaseUserModel: DatabaseUserModelService,
        private localInterval: ng.IIntervalService,
        private navigation: NavigationService
    ) {
        Object.defineProperty(
            this.restorePanelRight,
            'editPanelButton',
            {
                get: () => {
                    return this.userPanelRight.restore.editPanelButton && !this.bundleIsDeleted;
                }
            }
        );
    }

    public $onInit = (): void => {
        if (AuthContextService.isGranted(UiRights.DB_JOBS_LIST)) {
            void this.databaseJobModel.findRunningJobsForDatabase(this.originalDatabase)
                .then(
                    (result) => {
                        this.databaseHasUnfinishedJob = result.status === 'success'
                        && result.response.data.length > 0;
                    }
                );
        }

        this.database = JSON.parse(JSON.stringify(this.originalDatabase));
        this.databaseUsers = JSON.parse(JSON.stringify(this.originalDatabaseUsers));
        if ([undefined, null].indexOf(this.originalBackups) < 0) {
            this.backups = JSON.parse(JSON.stringify(this.originalBackups));
        }
        this.newAccessList = [];
        this.newUserList = [];
        this.deleteUserList = [];
        this.editUserList = [];

        this.accessLevels = [
            { type: 'read', label: this.$translate.instant('DATABASE.USER.GENERAL.USER-RIGHT.READ') },
            { type: 'write', label: this.$translate.instant('DATABASE.USER.GENERAL.USER-RIGHT.WRITE') },
            { type: 'schema', label: this.$translate.instant('DATABASE.USER.GENERAL.USER-RIGHT.SCHEMA') }
        ];

        if ([undefined, null, ''].indexOf(this.database.bundleId) < 0) {
            void this.bundleModel.findOneById(this.database.bundleId)
                .then((bundles: BundleApi.Bundle[]) => this.bundle = bundles[0]);
        }

        if (this.database.deletionScheduledFor !== null) {
            this.initialStatus = EditPanelStatus.DELETIONSCHEDULED;
        }

        this.cancelationInfoPannelTitle = this.$translate.instant(
            (this.showBundleHint ? /* translationId */ 'TR_140119-d46abf_TR'
                                   /* translationId */ : 'TR_230119-ff56c8_TR')
        );

        this.backupPanelItems = [
            {
                route: this.backupRoute,
                text: this.$translate.instant('9f19ed27-8fb4-448f-bf72-6bd67d785c47')
            },
            {
                route: this.backupRoute,
                text: this.$translate.instant('BACKUP.ACTION.RESTORE.TITLE')
            }
        ];
    };

    public get displayDeletePanel(): boolean {
        return ['active', 'restricted'].indexOf(this.database.status) >= 0
            && [undefined, null, ''].indexOf(this.database.deletionScheduledFor) >= 0;
    }

    public get displayDeletionScheduledInfoPanel(): boolean {
        return ['active', 'restricted'].indexOf(this.database.status) >= 0
            && [undefined, null, ''].indexOf(this.database.deletionScheduledFor) < 0;
    }

    public get backupRoute(): string {
        // Dirty, I know.
        return this.$state.current.name.replace(/\.edit$/, '.backups.overview');
    }

    public get showBundleHint(): boolean {
        return [undefined, null, ''].indexOf(this.database.bundleId) < 0
            && this.$state.current.name.split('.')[0] !== 'bundle';
    }

    public get bundleIsDeleted(): boolean {
        return [undefined, null].indexOf(this.bundle) < 0 && this.bundle.status === 'restorable';
    }

    public get errorList(): ApiErrorObject {
        return this.apiErrorModel.errorObjectList;
    }

    public get enableDeletion(): boolean {
        return this.hasAgreedToDeleteData && this.deleteDate !== undefined;
    }

    public delete = (): void => {
        // not used.
        // See app/atomic-components/organs/panels/edit-panels/database-delete/database-product-delete.ts
    };

    public cancelDeletion = (): void => {
        if (this.database.deletionScheduledFor !== null) {
            void this.databaseModel.cancelDeletion(this.database.id).then (
                (reply: unknown) => {
                    if (!this.showBundleHint) {
                        this.alertManager.success(this.$translate.instant('TR_230119-105bfd_TR'));
                    } else {
                        this.alertManager.success(this.$translate.instant('TR_230119-0bde30_TR'));
                    }
                    return reply;
                }
            );
        }
    };

    public databaseWipe = (): Promise<unknown> => {
        return this.databaseModel.wipeDatabase(this.database.id).then(
            (res: unknown) => {
                if (!this.apiErrorModel.apiResponseHasError(res)) {
                    return res;
                }

                return this.$q.reject(false);
            },
            (err) => {
                return this.$q.reject(err);
            }
        );
    };

    public restoreOrPurge = (): void => {
        this.apiErrorModel.destroyErrorList();

        if (this.selectedRestoreAction === 'restore') {
            this.databaseModel.restore([this.database])
                .then(
                    (reply: unknown) => {
                        this.alertManager.success(this.$translate.instant('TR_100119-6a9a06_TR'));
                        void this.$timeout(() => {
                            this.navigation.reloadCurrentState();
                        }, 300);
                        return reply;
                    },
                    (error: Error) => {
                        return this.$q.reject(error);
                    }
                );
        } else if (this.selectedRestoreAction === 'purge') {
            this.databaseModel.purgeRestorable(this.database.id).then(
                () => {
                    this.alertManager.success(this.$translate.instant('TR_100119-d6dbda_TR'));
                    void this.navigation.go('database.databases.overview', undefined, { reload: true });
                    return this.$q.reject(false);
                },
                (error) => {
                    return this.$q.reject(error);
                }
            );
        }
    };

    public setAccess(access) {
        const tmp = ng.copy(access);

        const user = this.originalDatabaseUsers.filter((listUser) => {
            return listUser.id === access.userId;
        })[0];

        if (user !== undefined) {
            tmp.dbUserName = user.dbUserName;
            tmp.name = user.name;
            tmp.comments = user.comments;
        }

        this.accessLevels.forEach((level) => {
            tmp[level.type] = this.accessLevelIsset(tmp, level.type);
        });

        return tmp;
    }

    public getAccessRights = (access): string => {
        const rights: string[] = [];
        this.accessLevels.map((level) => {
            if (access.accessLevel.indexOf(level.type) >= 0) {
                rights.push(level.label);
            }
        });

        return rights.join(', ');
    };

    public accessLevelIsset = (access, level): boolean => {
        return access.accessLevel.indexOf(level) >= 0;
    };

    public cancelGeneral = (): void => {
        const tmp = ng.copy(this.originalDatabase);
        this.database.comments = tmp.comments;
        this.database.name = tmp.name;
    };

    public cancelUsers = (): void => {
        this.deleteUserList = [];
        this.editUserList = [];
        this.newAccessList = [];
        this.newUserList = [];
        this.databaseUsers = JSON.parse(JSON.stringify(this.originalDatabaseUsers));
    };

    public cancelBackups = (): void => {
        this.backups = JSON.parse(JSON.stringify(this.originalBackups));
    };

    public restoreBackups = (backup: BackupApi.Backup): Promise<unknown> => {
        this.apiErrorModel.destroyErrorList();
        return this.backupModel.restore(backup.targetService, backup.targetobjectType, backup.targetobjectId, backup.id)
            .then(
                (res: unknown) => {
                    if (!this.apiErrorModel.apiResponseHasError(res)) {
                        this.alertManager.success(this.$translate.instant('TR_160119-8fdda7_TR'));
                        return res;
                    }
                    return this.$q.reject(false);
                },
                (err) => {
                    return this.$q.reject(err);
                }
            );
    };

    public addNewBackup = (backup: CreateDatabaseAccessObject): Promise<unknown> => {
        const manualBackup: BackupApi.Backup[] = this.originalBackups.filter((singleBackup) => {
            return singleBackup.type === 'manual';
        });
        let oldBackupId = null;
        if (manualBackup.length > 0) {
            const oldBackup = manualBackup.pop();
            oldBackupId = oldBackup.id;
        }
        this.apiErrorModel.destroyErrorList();

        return this.backupModel.start(
            backup.service,
            backup.objectType,
            backup.objectId,
            backup.backupName,
            oldBackupId
        ).then(
            (res: unknown) => {
                if (!this.apiErrorModel.apiResponseHasError(res)) {
                    this.alertManager.success(this.$translate.instant('BACKUP.ACTION.REPLACE.SUCCESS'));
                    return res;
                }
                return this.$q.reject(false);
            },
            (err) => {
                return this.$q.reject(err);
            }
        );
    };

    public set isPaidUntilExceeded(value: boolean) {
        this._isPaidUntilExceeded = value;
    }

    public get isPaidUntilExceeded(): boolean {
        return this._isPaidUntilExceeded;
    }

    public save = (): Promise<unknown> => {
        this._sendingSaveRequest = true;
        this.apiErrorModel.destroyErrorList();
        this._saveAccesses = ng.copy(this.database.accesses);
        this._saveAccesses.map((access) => {
            access.accessLevel = [];
            this.accessLevels.map((level) => {
                if (access[level.type]) {
                    access.accessLevel.push(level.type);
                }
            });
        });

        this._saveAccesses = this._saveAccesses.filter((access) => {
            // remove user from db access list, if user is deleted
            return this.userInList(access, this.deleteUserList) < 0;
        });

        if (this.newUserList.length > 0) {
            // create new users
            const promises: Promise<DatabaseUserObject>[] = [];
            this.newUserList.map(
                (user: DatabaseUserObject) => {
                    if ([undefined, null, ''].indexOf(user.id) >= 0) {
                        promises.push(
                            this.databaseUserModel.createUser(user, user.password, this.originalDatabase.accountId)
                        );
                    } else {
                        promises.push(Promise.resolve(user));
                    }
                }
            );

            return void this.$q.all(promises).then(
                (res) => {
                    if (!this.apiErrorModel.apiResponseHasError(res)) {
                        let newAccess: DatabaseApi.DatabaseAccess;
                        res.map((user: DatabaseUserObject, index: number) => {
                            newAccess = {
                                accessLevel: this.newAccessList[index].accessLevel,
                                databaseId: this.newAccessList[index].databaseId,
                                userId: user.id
                            };
                            this._saveAccesses.push(newAccess);
                        });

                        return this.updateAccessUsers();
                    }

                    this._sendingSaveRequest = false;
                    return Promise.reject();
                },
                (err) => {
                    this._sendingSaveRequest = false;
                    return Promise.reject(err);
                }
            );
        }

        return this.updateAccessUsers();
    };

    public userInList(access, list): number {
        let listIndex = -1;

        list.some((listItem, index) => {
            if (listItem.userId === access.userId) {
                listIndex = index;
                return true;
            }
            return false;
        });

        return listIndex;
    }

    public cancelStorage = (): void => {
        this.additionalStorageItems = 0;
        this.database.storageQuota = this.originalDatabase.storageQuota;
    };

    public cancelDelete = (): void => {
        this.hasAgreedToDeleteData = false;
    };

    public cancelWipe = (): void => {
        this.hasAgreedToDeleteData = false;
    };

    private updateAccessUsers = (): Promise<void> => {
        if (this.editUserList.length > 0) {
            void this._accessesUpdate();
            return;
        }

        return this._updateDatabase();
    };

    private _accessesUpdate = (): Promise<void|unknown> => {
        if (this._accessIndexToUpdate < this.editUserList.length) {
            const updateUser = ng.copy(this.editUserList[this._accessIndexToUpdate]);
            updateUser.id = updateUser.userId;

            return this.databaseModel.list(
                null,
                1,
                { field: 'DatabaseAccessesUserId', value: updateUser.id}
            ).then((databases: ViewTypes.ApiListResponse) => {
                this._accessUpdatePromises.push(
                    this.databaseUserModel.updateUser(updateUser, updateUser.password)
                    .then((res: unknown) => {
                        this._accessIndexToUpdate++;
                        this._startCheckingJobStatus(
                            'access',
                            databases.data.map((database: DatabaseApi.Database) => database.id)
                        );
                        return res;
                    })
                );
            });
        }

        this._stopCheckingJobStatus();

        return void Promise.all(this._accessUpdatePromises).then((res) => {
            if (!this.apiErrorModel.apiResponseHasError(res)) {
                this.alertManager.success(this.$translate.instant('TR_100119-54df5d_TR'));
                return this.apiSaveRequest();
            }

            return Promise.reject();
            },
            (err) => {
                void Promise.reject(err);
            }
        );
    };

    private apiSaveRequest = (): void => {
        const database = ng.copy(this.database);
        database.accesses = ng.copy(this._saveAccesses);

        this._startCheckingJobStatus('database');
    };

    private _startCheckingJobStatus = (requestType: string, idList?: string[]): void => {
        if (this._checkingJobStatus !== undefined) {
            return;
        }
        this._checkingJobStatus = this.localInterval(() => {
            void this._checkJobStatus(requestType, idList);
        }, this._interval);
    };

    private _checkJobStatus = (requestType: string, idList?: string[]): Promise<void> => {
        if (this._jobChecksPerformed >= this._maxNumberOfJobChecks) {
            // Maximum number of job checks reached, cancel edit completely
            this._stopCheckingJobStatus();
        }

        let idSubFilters = [];
        if (idList !== undefined) {
            idSubFilters = idList.map((id) => this._idFilter(id));
        } else {
            idSubFilters = [{ field: 'jobObjectId', value: this.database.id }];
        }
        const filters = {
            subFilter: [
                {
                    subFilter: [
                        { field: 'jobStatus', value: 'successful', relation: 'unequal' },
                        { field: 'jobStatus', value: 'failed', relation: 'unequal' },
                        { field: 'jobStatus', value: 'canceled', relation: 'unequal' }
                    ],
                    subFilterConnective: 'AND'
                },
                {
                    subFilter: idSubFilters,
                    subFilterConnective: 'OR'
                }
            ],
            subFilterConnective: 'AND'
        };

        this._jobChecksPerformed++;
        return this.databaseJobModel.list(null, 1, filters, null).then(
            (jobList) => {
                if (jobList.data.length === 0) {
                    this._stopCheckingJobStatus();
                    switch (requestType) {
                        case 'database':
                            return this._updateDatabase();
                        case 'access':
                            void this._accessesUpdate();
                            break;
                        default:
                            // nothing to do here
                    }
                }
            }
        );
    };

    private _idFilter = (id: string): Finding.Filter => {
        return {field: 'jobObjectId', value: id};
    };

    private _stopCheckingJobStatus = (): void => {
        if (this._jobChecksPerformed >= this._maxNumberOfJobChecks) {
            this.alertManager.error(this.$translate.instant('TR_210519-3368ac_TR'));
            this._sendingSaveRequest = false;
        }

        if (this._checkingJobStatus !== undefined) {
            this.localInterval.cancel(this._checkingJobStatus);
            this._checkingJobStatus = undefined;
            this._jobChecksPerformed = 0;
        }
    };

    private _updateDatabase = (): Promise<void> => {
        const database = ng.copy(this.database);
        database.accesses = ng.copy(this._saveAccesses);

        return void this.databaseModel.update(database, false)
        .then(
            (res: unknown) => {
                if (!this.apiErrorModel.apiResponseHasError(res)) {
                    this.alertManager.success(this.$translate.instant('TR_100119-152f7f_TR'));
                    this.navigation.reloadCurrentState();
                    return res;
                }

                this._sendingSaveRequest = false;
                return this.$q.reject(false);
            },
            (err) => {
                this._sendingSaveRequest = false;
                return this.$q.reject(err);
            }
        );
    };
}

export class OrganismEditFormDatabaseComponent implements ng.IComponentOptions {
    public bindings = {
        originalBackups: '<backups',
        originalDatabase: '<database',
        originalDatabaseUsers: '<databaseUsers',
        showObjectId: '<',
        userPanelRight: '='
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormDatabaseController;
    public template = require('./database-edit.html');
}
