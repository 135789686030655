import * as ng from 'angular';
import './status-invoice.scss';

export class MoleculeStatusBillingInvoiceController implements ng.IController {
    public static $inject: string[] = [];

    public invoice;

    public getStatus = () => {
        if (this.invoice.outstanding === 0) {
            return 'paid';
        }

        if (this.invoice.reminderLevels.length > 0) {
            const lastReminder = this.invoice.reminderLevels.slice(-1).pop();
            if (lastReminder.level > 3) {
                return 'reminder-many';
            }
            return 'reminder-' + lastReminder.level.toString();
        }

        if (this.invoice.total === this.invoice.outstanding) {
            return 'notPaid';
        }

        if (this.invoice.total > this.invoice.outstanding) {
            return 'partiallyPaid';
        }
    };
}

export class MoleculeStatusBillingInvoiceComponent implements ng.IComponentOptions {
    public bindings = {
        invoice: '<'
    };
    public controller =  MoleculeStatusBillingInvoiceController;
    public controllerAs = '$Status';
    public template = require('./status-invoice.html');
}
