import * as ng from 'angular';
import {
    AlertManagerService,
    ApiErrorModel,
    DateWrapper,
    NavigationService,
    WebspaceModelService
} from '../../../../../services';
import * as Types from '../../../../../types';

export class OrganDeleteWebspaceController {
    public static $inject: string[] = [
        '$q',
        '$state',
        '$translate',
        'alertManager',
        'apiErrorModel',
        'navigation',
        'webspaceModel'
    ];

    public webspace: Types.WebhostingApi.Webspace;
    public deletionConfirmed = false;
    public deleteDate: DateWrapper;
    public selectedRestoreAction = '';
    public hasAgreedToRestoreWebspace = false;

    private _isPaidUntilExceeded: boolean;

    constructor(
        private $q: ng.IQService,
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private navigation: NavigationService,
        private webspaceModel: WebspaceModelService
    ) {}

    public $onInit() {
        this.deletionConfirmed = this.isFreeProduct();
    }

    public get isWebspaceDeletable() {
        // The webspace can only be restored, when it is not part of a bundle and no date to restore is set.
        const isArrangedInBundle = [undefined, null].indexOf(this.webspace) < 0
            && [undefined, null, ''].indexOf((this.webspace as any).bundleId) < 0;
        const isHavingRestoreDate = [undefined, null].indexOf((this.webspace as any).restorableUntil) >= 0;
        return !isArrangedInBundle && isHavingRestoreDate;
    }

    public set isPaidUntilExceeded(value: boolean) {
        this._isPaidUntilExceeded = value;
    }

    public get isPaidUntilExceeded(): boolean {
        return this._isPaidUntilExceeded;
    }

    public restoreOrPurge = () => {
        this.apiErrorModel.destroyErrorList();
        const stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';
        const route = stateInMachine
            ? 'managed-servers.id.webspaces.overview'
            : 'webhosting.webspaces.overview';
        const routeParams = stateInMachine
            ? {
                machineId: this.$state.$current.locals.globals.machine.id,
                webspaceId: this.$state.$current.locals.globals.webspace.id
            }
            : undefined;
        if (this.selectedRestoreAction === 'restore') {
            this.webspaceModel.restore([this.webspace]).then(
                (apiResponse) => {
                    this.alertManager.success(this.$translate.instant('TR_100119-6a9a06_TR'));
                    this.navigation.go(route, routeParams, { reload: true });
                    return apiResponse;
                },
                (error) => {
                    return this.$q.reject(error);
                }
            );
        } else if (this.selectedRestoreAction === 'purge') {
            this.webspaceModel.purgeRestorable(this.webspace.id).then(
                (apiResponse) => {
                    this.alertManager.success(this.$translate.instant('TR_100119-d6dbda_TR'));
                    this.navigation.go(route, routeParams, { reload: true });
                    return apiResponse;
                },
                (error) => {
                    return this.$q.reject(error);
                }
            );
        }
    };

    public get hasAgreedToDeleteEverything() {
        return this.deletionConfirmed && this.deleteDate !== undefined;
    }

    private _getRouteParams = (): Record<string, any> => {
        const stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';
        const route = stateInMachine
            ? 'managed-servers.id.webspaces.overview'
            : 'webhosting.webspaces.overview';
        const routeParams = stateInMachine
            ? {
                machineId: this.$state.$current.locals.globals.machine.id,
                webspaceId: this.$state.$current.locals.globals.webspace.id
            }
            : undefined;
        return {
            route: route,
            routeParams: routeParams,
            stateInMachine: stateInMachine
        };
    };

    public delete = () => {
        const {
            route,
            routeParams
        } = this._getRouteParams();
        return this.webspaceModel.deleteOne(
            this.webspace.id,
            this.deleteDate.isToday() ? null : this.deleteDate.dateObj
        )
        .then(
            (apiResponse: any) => {
                this.alertManager.success(this.$translate.instant('TR_100119-086ce0_TR'));
                this.navigation.go(route, routeParams, undefined);
                // return reject of promise, otherwise the form-edit molecule will reload a page
                return this.$q.reject(false);
            }
        );
    };

    public cancelDelete = (): void => {
        void this.webspaceModel.cancelDeleteOnce(this.webspace)
            .then((apiResponse: any) => {
                this.alertManager.success(this.$translate.instant('TR_100119-086ce0_TR'));
                this.navigation.reloadCurrentState(true, true);
            });
    };

    public cancelTerminate = () => {
        this.deletionConfirmed = false;
    };

    public webspaceDeleted = () => {
        if (this.webspace.status === 'deleted') {
            return true;
        }

        return this.webspace.status === 'active'
            && [undefined, null, ''].indexOf(this.webspace.deletionScheduledFor) < 0
        ;
    };

    public webspaceRestorable = () => {
        return this.webspace.status === 'restorable';
    };

    public isFreeProduct = () => {
        return [undefined, null, ''].indexOf(this.webspace.bundleId) < 0
            || [undefined, null, ''].indexOf(this.webspace.poolId) < 0;
    };
}

export class OrganDeleteWebspaceComponent implements ng.IComponentOptions {
    public bindings = {
        panelRight: '=',
        webspace: '<'
    };
    public controller = OrganDeleteWebspaceController;
    public template = require('./webspace-delete.html');
}
