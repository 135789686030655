import * as ng from 'angular';

import { BillingCurrencyConst, CharPoolsConst, UIRegExp } from '../../../../../configuration';
import { ValidateAllowedCharacters, ValidateMin, ValidateNumber } from '../../../../../services';
import * as Types from '../../../../../types';

export class OrganEditPanelSubAccountBillingController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public BillingCurrencyConst = BillingCurrencyConst;
    public subAccountBillingData: Types.AccountApi.AccountBillingSettings;
    public subAccountData: Types.AccountApi.Subaccount;
    public panelRightSettings: any;
    public cancelCallbackFunction: () => void;
    public saveCallbackFunction: () => void;
    public paymentMethodDropdownItems: any[] = [];
    public creditLimitValidationInstructions: any[] = [];
    public creditLimitValidationSuccessful = true;
    public purchaseMarkupValidationInstructions: any[] = [];

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {}

    $onInit() {
        if (
            UIRegExp.IsNumeric.test(this.subAccountData.id)
            && parseInt(this.subAccountData.id, 10) < 1000
        ) {
            this.paymentMethodDropdownItems = [
                {
                    value: 'company',
                    name: this.$translate.instant('TR_130320-ea4866_TR')
                }
            ];
        } else if (
            UIRegExp.IsNumeric.test(this.subAccountData.parentAccountId)
            && parseInt(this.subAccountData.parentAccountId, 10) < 1000
        ) {
            this.paymentMethodDropdownItems = [
                {
                    value: 'prepaid',
                    name: this.$translate.instant('TR_110119-f11620_TR')
                },
                {
                    value: 'postpaid',
                    name: this.$translate.instant('TR_110119-a782a9_TR')
                }
            ];
        } else {
            this.paymentMethodDropdownItems = [
                {
                    value: 'external',
                    name: this.$translate.instant('TR_110119-bf497a_TR')
                }
            ];
        }

        this.creditLimitValidationInstructions = [
            {
                validator: new ValidateMin(this.$translate, 0),
                instructions: null
            },
            {
                validator: new ValidateAllowedCharacters(CharPoolsConst.numerals.value.concat('-.,'), this.$translate),
                instructions: null
            },
            {
                validator: new ValidateNumber(this.$translate, true),
                instructions: null
            }
        ];

        this.purchaseMarkupValidationInstructions = [
            {
                validator: new ValidateMin(this.$translate, -100),
                instructions: null
            },
            {
                validator: new ValidateAllowedCharacters(CharPoolsConst.numerals.value.concat('-.,'), this.$translate),
                instructions: null
            },
            {
                validator: new ValidateNumber(this.$translate, true),
                instructions: null
            }
        ];
    }

    public get paymentMethod() {
        switch (this.subAccountBillingData.paymentType) {
            case 'company': return this.$translate.instant('TR_130320-ea4866_TR');
            case 'external': return this.$translate.instant('TR_110119-bf497a_TR');
            case 'prepaid': return this.$translate.instant('TR_110119-f11620_TR');
            case 'postpaid': return this.$translate.instant('TR_110119-a782a9_TR');
            default: return this.subAccountBillingData.paymentType;
        }
    }
}

export class OrganEditPanelSubAccountBillingComponent implements ng.IComponentOptions {
    public bindings = {
        allValidationsSuccessful: '=',
        cancelCallbackFunction: '<',
        panelRightSettings: '<',
        saveCallbackFunction: '<',
        subAccountBillingData: '=',
        subAccountData: '='
    };
    public template = require('./sub-account-billing.html');
    public controller = OrganEditPanelSubAccountBillingController;
}
