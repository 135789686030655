import * as ng from 'angular';

import * as Types from '@/types';
import { pageHeaderSettings } from '@/services/account/api-key-links';

export class TemplateApiKeyEditController implements ng.IController {
    public static $inject: string[] = [
        '$state',
        '$translate'
    ];
    public apiKeyData: any;
    public pageHeaderData: any;
    public allRights: any[];
    public userData: Types.AccountApi.User;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = (): void => {
        const {
            backwardLink,
            backwardText,
            panelHeaderRoute
        } = pageHeaderSettings(
            this.$state.current.name,
            this.$translate
        );
        this.pageHeaderData = {
            backwardLink: backwardLink,
            backwardText: backwardText,
            panelHeaderRoute: panelHeaderRoute,
            panelHeaderRouteParams: { userId: this.userData.id, apiKeyId: this.apiKeyData.id },
            panelIcon: 'briefcase',
            panelTitle: this.$translate.instant('TR_080119-8f897a_TR')
        };
    };
}

export class TemplateApiKeyEditComponent implements ng.IComponentOptions {
    public bindings = {
        allRights: '<',
        apiKeyData: '<',
        overviewUiSettings: '<',
        userData: '<'
    };
    public template = require('./api-key-edit.html');
    public controller =  TemplateApiKeyEditController;
}
