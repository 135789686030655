import * as ng from 'angular';
import { AccountTaskManagerService } from '../../../../services';
import { TaskListItem } from '../../links';
import './panel-tasks.scss';

export class MoleculePanelTasksController implements ng.IController {
    public static $inject: string[] = ['$sce', 'accountTaskManager'];

    public taskList: TaskListItem[];

    constructor(
        private $sce: ng.ISCEService,
        private accountTaskManager: AccountTaskManagerService
    ) {}

    public $onInit() {
        this.taskList = this.accountTaskManager.taskItemList;
    }
}

export class MoleculePanelTasksComponent implements ng.IComponentOptions {
    public bindings = {
        taskList: '<'
    };
    public controller =  MoleculePanelTasksController;
    public controllerAs = 'PanelTasksCtrl';
    public template = require('./panel-tasks.html');
}
