import * as ng from 'angular';

import './button-copy.scss';

export class MoleculeButtonCopyController implements ng.IController {
    public static $inject: string[] = ['$translate', '$timeout'];

    public text: string;
    public helpText: string;
    public copied = false;
    public minimalVersion = true;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private $timeout: ng.ITimeoutService
    ) {}

    public $onChanges(changes: ng.IOnChangesObject) {
        if (changes.text !== undefined) {
            this.copied = false;
        }
    }

    public copy($event: ng.IAngularEvent) {
        $event.preventDefault();
        $event.stopPropagation();

        const textarea = document.createElement('textarea');
        textarea.style.position = 'absolute';
        textarea.style.top = '-100';
        textarea.style.left = '0';
        textarea.value = this.text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        this._setCopied();
        document.body.removeChild(textarea);

        this._focusToSrc($event);
    }

    public onKeyup = (event: any) => {
        if (event.key === 'Enter') {
            this.copy(event);
        }
    };

    public get tooltipText() {
        if ([undefined, null, ''].indexOf(this.helpText) >= 0) {
            return this.$translate.instant('03507e6a-b9e8-4662-ada1-818ee6e1d0a0');
        }
        return this.helpText;
    }

    private _focusToSrc = (event) => {
        event.target.focus();
    };

    private _setCopied = () => {
        this.copied = true;

        this.$timeout(() => {
            this.copied = false;
        }, 1000);
    };
}

export class MoleculeButtonCopyComponent implements ng.IComponentOptions {
    public bindings = {
        helpText: '@?',
        minimalVersion: '<?',
        text: '@'
    };
    public controller =  MoleculeButtonCopyController;
    public template = require('./button-copy.html');
}
