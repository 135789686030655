import * as ng from 'angular';
import { GeneralCreateService, JobStatusObject, WebspaceModelService } from '../../../../../services';
import * as Types from '../../../../../types';
// import { WebspaceCreateAccessObject } from '../../../../organs/create';

export class OrganCreateConfirmationWebspaceAccessController implements ng.IController {
    public static $inject: string[] = ['generalCreate', 'webspaceModel'];

    public accessObject: any; // WebspaceCreateAccessObject;
    public jobStatusWebspace: null | JobStatusObject;
    public someCreateJobInSupportStatus = false;
    public apiOrderRequestError = false;
    public webspaceObject: Types.WebhostingApi.Webspace;

    private _gettingWebspaceObject = false;

    constructor(
        private generalCreate: GeneralCreateService,
        private webspaceModel: WebspaceModelService
    ) {}

    public get webspaceCreatingStatus() {
        const jobStatus = this.generalCreate.getJobStatusForTemplate(
            this.jobStatusWebspace,
            this.someCreateJobInSupportStatus,
            this.apiOrderRequestError
        );
        if (['done', 'successfull'].indexOf(jobStatus) >= 0
            && !this._gettingWebspaceObject
        ) {
            return this._getWebspaceObjectData(jobStatus);
        }

        return jobStatus;
    }

    public set webspaceCreatingStatus({}) {} // tslint:disable-line:no-empty

    private _getWebspaceObjectData = (jobStatus: string) => {
        this._gettingWebspaceObject = true;
        return this.webspaceModel.findOne(this.jobStatusWebspace.objectId).then((webspaceObject: any) => {
            if (webspaceObject !== undefined) {
                this.webspaceObject = webspaceObject;
            }
            return jobStatus;
        }) as ng.IPromise<string>;
    };
}

export class OrganCreateConfirmationWebspaceAccessComponent implements ng.IComponentOptions {
    public bindings = {
        apiOrderRequestError: '<?',
        jobStatusWebspace: '<',
        someCreateJobInSupportStatus: '<?'
    };
    public template = require('./webspace-access-confirmation.html');
    public controller =  OrganCreateConfirmationWebspaceAccessController;
}
