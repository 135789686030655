import * as ng from 'angular';
import './nameserver-address-list.scss';

export class MoleculePanelEditRowNameserverAddressListRevisedController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    // Indices for edit and removal.
    public editingAddressIndex = -1;
    public indicesMarkedForRemoval: number[] = [];

    public newNameserverValue = '';
    public placeholder: string;
    public removeInstantly: boolean;
    public platformMailValidation: boolean;
    public showRequired: boolean;
    public validationInstructions: any;
    public validationErrors: any[] = [];
    public onChangeCallback: any;

    // Internal.
    private _isOpen = false;
    private _nameservers: any;
    private _addressesInternal: string[] = [];
    private _selectedProductFamily: string;

    public get isOpen() {
        return this._isOpen;
    }
    public set isOpen(value) {
        this._isOpen = value;
    }

    public get nameservers() {
        return this._nameservers;
    }
    public set nameservers(value) {
        this._nameservers = value;
    }

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.placeholder = this.placeholder || this.$translate.instant('TR_110119-594d9a_TR');
        if ([undefined, null].indexOf(this.removeInstantly) > -1) {
            this.removeInstantly = true;
        }
        if ([undefined].indexOf(this.showRequired) >= 0) {
            this.showRequired = false;
        }
    }

    public openInputField = () => {
        this.isOpen = true;
        this.newNameserverValue = '';
    };

    public acceptInputAndClose = () => {
        if (this.editingAddressIndex > -1) {
            const editedValue = this.nameservers.nameservers[this.editingAddressIndex];
            const duplicates = this.nameservers.nameservers.filter((ns) =>
                ns === editedValue
            );

            if (duplicates.length > 1) {
                this.nameservers.nameservers.splice(this.editingAddressIndex, 1);
            }
            if (this.onChangeCallback) {
                this.onChangeCallback();
            }

        } else if (
            this.newNameserverValue
            && this.newNameserverValue.length > 0
            && this.nameservers.nameservers.indexOf(this.newNameserverValue) < 0
        ) {
            this.nameservers.nameservers.push(this.newNameserverValue);
            if (this.onChangeCallback) {
                this.onChangeCallback();
            }
        }
        this.cancelInputAndClose();
    };

    public cancelInputAndClose = () => {
        this.isOpen = false;
        this.newNameserverValue = '';
        this.editingAddressIndex = -1;
    };

    public deleteNameserver = (name) => {
        if (this.removeInstantly) {
            this.nameservers.nameservers = this.nameservers.nameservers.filter(
                (ns) => ns !== name
            );
            this._addressesInternal = this._addressesInternal.filter(
                (internalAddress) => internalAddress !== name
            );
        } else {
            const indexOfAddress = this.nameservers.nameservers.indexOf(name);
            const indexInRemovalList = this.indicesMarkedForRemoval.indexOf(indexOfAddress);
            const indexInInternalForwaderList = this._addressesInternal.indexOf(name);
            if (indexInInternalForwaderList >= 0) {
                this._addressesInternal = this._addressesInternal.filter(
                    (internalAddress) => internalAddress !== name
                );
            }
            if (indexOfAddress > -1) {
                if (indexInRemovalList < 0) {
                    this.indicesMarkedForRemoval.push(indexOfAddress);
                } else {
                    this.indicesMarkedForRemoval = this.indicesMarkedForRemoval.filter(
                        (removalIndex) => removalIndex !== this.indicesMarkedForRemoval[indexInRemovalList]
                    );
                }
            }
        }
        if (this.onChangeCallback) {
            this.onChangeCallback();
        }
    };

    public editNameserver = (name) => {
        this.editingAddressIndex = this.nameservers.nameservers.indexOf(name);
        this._addressesInternal = this._addressesInternal.filter(
            (internalAddress) => internalAddress !== this.nameservers.nameservers[this.editingAddressIndex]
        );
    };

    public get targetsAllInternal() {
        if (this._selectedProductFamily !== 'external-forwarders' || this.nameservers.nameservers.length === 0) {
            return false;
        }

        return this._addressesInternal.length === this.nameservers.nameservers.length;
    }

    public set targetsAllInternal({}) {} // tslint:disable-line:no-empty
}

export class MoleculePanelEditRowNameserverAddressListComponent implements ng.IComponentOptions {
    public bindings = {
        _selectedProductFamily: '<selectedProductFamily',
        nameservers: '=',
        removeInstantly: '<?',
        newForwarderValue: '=?',
        placeholder: '@?',
        platformMailValidation: '<?',
        showRequired: '<?',
        validationErrors: '=',
        targetsAllInternal: '=?',
        onChangeCallback: '<?'
    };

    public transclude = {
        label: '?rowLabel'
    };

    /* tslint:disable-next-line:max-line-length */
    public template = require('./nameserver-address-list.html');
    public controller = MoleculePanelEditRowNameserverAddressListRevisedController;
}
