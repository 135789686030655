import ng from 'angular';

import { CronjobTypes } from '../../../../../configuration';
import { EditPanelStatus, FormDropDownItems } from '../../../../molecules';
import { OrganismNewEditFormCronjobController } from '../../../../organisms';

export class OrganEditPanelCronjobGeneralController {
    public static $inject: string[] = ['$translate'];

    public cancel;
    public $editFormOrganism: OrganismNewEditFormCronjobController;
    public panelRight;
    public initialStatus: EditPanelStatus;
    public options: { [key: string]: FormDropDownItems[] } = { type: [] };

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {
        CronjobTypes.map(type => this.options.type.push({
            name: this.$translate.instant(type.translationId),
            value: type.value
        }));
    }

    public $onInit(): void {
        this.initialStatus = this.$editFormOrganism.create ? EditPanelStatus.CREATE : EditPanelStatus.READONLY;
    }

    public getCronjobType = (): string => {
        let cronjobType: string;
        CronjobTypes.some(type => {
            if (type.value === this.$editFormOrganism.cronjob.type) {
                cronjobType = this.$translate.instant(type.translationId);
                return true;
            }
            return false;
        });

        return cronjobType;
    };
}

export class OrganEditPanelCronjobGeneralComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismNewEditFormCronjob'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./cronjob-general.html');
    public controller = OrganEditPanelCronjobGeneralController;
}
