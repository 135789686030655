import * as ng from 'angular';

import {
    EditPanelStatus,
    MoleculePanelEditTableController,
    OrganEditPanelNextcloudGroupsController
} from '@/atomic-components';
import {
    OrganismEditFormNextcloudController
} from '../../../../../organisms/forms/edit-forms/nextcloud-edit/nextcloud-edit';

export class MoleculePanelEditRowTableNextcloudPluginsController implements ng.IController {
    public $editFormOrganism: OrganismEditFormNextcloudController;
    public $editPanelOrgan: OrganEditPanelNextcloudGroupsController;
    public $editTableOrganism: MoleculePanelEditTableController;
    public nextcloudUsersDropdownOptions;

    public get pluginsExist() {
        return this.$editPanelOrgan?.groups?.length !== 0;
    }

    public get isEditMode() {
        return this.$editTableOrganism.status === EditPanelStatus.ACTIVE;
    }
}

export class MoleculePanelEditRowTableNextcloudPluginsComponent implements ng.IComponentOptions {
    public require = {
        $editFormOrganism: '^organismEditFormNextcloud',
        $editPanelOrgan: '^organEditPanelNextcloudPlugins',
        $editTableOrganism: '^moleculePanelEditTable'
    };

    public controller = MoleculePanelEditRowTableNextcloudPluginsController;
    public controllerAs = '$panelEditRowTable';
    public template = require('./nextcloud-plugins.html');
}
