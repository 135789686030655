import * as ng from 'angular';
import { PanelHeaderData } from '@/atomic-components/molecules/panels/panel-header/panel-header';
import { UiLanguagesConst } from '@/configuration';
import { AuthContextService, ProductHelperService, ProductsModelService } from '@/services';
import * as Types from '@/types';

export class TemplateNextcloudUseOwnDomainController {
    public static $inject: string[] = ['$state', '$translate', 'productsModel'];

    public pageHeaderData: PanelHeaderData;
    public nextcloud: Types.ManagedApplicationApi.Nextcloud;
    public isFreeProduct = false;
    public productUpgradeLink = '';

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private productModel: ProductsModelService
    ) {}

    public $onInit(): void {
        this.productUpgradeLink = this.$state.current.name.indexOf('storage-products') >= 0
            ? 'storage.storage-products.id.upgrade'
            : 'nextcloud.id.upgrade';

        void this.productModel.findOne(
            this.nextcloud.productCode,
            UiLanguagesConst[AuthContextService.user.language],
            true
        )
            .then(
                (apiResponse) => {
                    this.isFreeProduct = ProductHelperService.wrapSpecificationItems(
                        apiResponse.response.specificationItems
                    ).isFreeProduct?.isTrue === true;
                }
            );

        this.pageHeaderData = {
            backwardLink:
                this.$state.current.name.indexOf('storage-products') >= 0
                ? 'storage.storage-products.id.edit'
                : 'nextcloud.id.edit',
            backwardText: this.$translate.instant('TR_120819-1528d4_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: { storageProductId: this.nextcloud.id },
            panelIcon: '/assets/images/logos/nextcloud-bw.svg',
            panelIconPath: true,
            panelTitle: this.$translate.instant('TR_040919-df83f7_TR', { nextcloudName: this.nextcloud.name })
        };
    }
}

export class TemplateNextcloudUseOwnDomainComponent implements ng.IComponentOptions {
    public bindings = {
        nextcloud: '<'
    };
    public controller = TemplateNextcloudUseOwnDomainController;
    public template = require('./nextcloud-use-own-domain-template.html');
}
