import * as ng from 'angular';
import { ManagedMachineProjectObject } from '../../../organisms';

export class OrganManagedMachineProjectConfirmationDatabaseController implements ng.IController {
    public static $inject: string[] = [];

    public projectData: ManagedMachineProjectObject;

    public get userCreatingStatus() {
        let status = 'creating';

        if (this.projectData.errorCreating.databaseUsers === 1) {
            status = 'failed';
        }  else if (this.projectData.finishedCreating.databaseUsers === 1) {
            status = 'installed';
        }

        return status;
    }

    public get databaseCreatingStatus() {
        let status = 'creating';

        if (!this._hasFinishedCreatingDatabases()) {

            return status;
        }

        if (this.projectData.errorCreating.databaseUsers === 1) {
            status = 'abort';
        }  else if (this.projectData.finishedCreating.databases === +this.projectData.capacity) {
            status = 'installed';
        } else {
            status = this.projectData.finishedCreating.databases === 0
                || this.projectData.errorCreating.databases === +this.projectData.capacity
                ? 'failed'
                : 'some-objects-failed';
        }

        return status;
    }

    private _hasFinishedCreatingDatabases = () => {
        return this.projectData.finishedCreating.databases === +this.projectData.capacity
            || (
                this.projectData.finishedCreating.databases + this.projectData.errorCreating.databases
            ) === +this.projectData.capacity;
    };
}

export class OrganManagedMachineProjectConfirmationDatabaseComponent implements ng.IComponentOptions {
    public bindings = {
        projectData: '='
    };

    public template = require('./database.html');
    public controller =  OrganManagedMachineProjectConfirmationDatabaseController;
}
