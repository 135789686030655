import { Validator } from './abstract-validator';

import * as ng from 'angular';

export class ValidateNotEmpty extends Validator {
    private validationErrors: null|any[];

    constructor(private $translate: ng.translate.ITranslateService, validationErrors?: any[]) {
        super();
        this.validationErrors = validationErrors;
    }

    public validate = (value: string) => {
        if ([undefined, null].indexOf(this.validationErrors) < 0 && this.validationErrors.length > 0) {
            return this.validationErrors;
        }

        if (value === null || value === '' || value === undefined) {
            return [{ text: this.$translate.instant('TR_090119-2705b2_TR') }];
        }

        return [];
    };

    public updateValidatorErrors = (errors: any[]) => {
        this.validationErrors = errors;
    };
}
