export class FetchClient {

    constructor(
        private baseUrl: string,
        private robot: string
    ) {}

    public request = async (url: string, request?: RequestInit): Promise<Response> => {
        const endpoint = this.baseUrl + url;
        try {
            const result = await fetch(endpoint, request);
            return result;
        } catch (error) {
            return Promise.reject(error);
        }
    };
}

export class FetchClientService {
    public Factory(baseUrl: string, robot: string): FetchClient {
        return new FetchClient(baseUrl, robot);
    }
}
