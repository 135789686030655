import ng from 'angular';
import * as Types from '@/types';
import { NextcloudHelperService } from '@/services';
import { NextcloudAppHelperService } from '@/services/helpers/nextcloud-app-helper';

export class OrganPanelStorageProductAppConfigAdditionalUsersController {
    public static $inject: string[] = [
        'nextcloudHelper',
        'nextcloudAppHelper',
        '$translate',
        '$timeout'
    ];

    public addUsersCallback: (value: { attribute: string; addedUsers: number }) => void;
    public confirmationCallback: (value: boolean, attribute: string) => void;
    public clearUserCallback: (value: boolean) => void;

    public app: Types.ManagedApplicationApi.NextcloudApp;
    public translationParams = {
        maxOfficeUsers: 0,
        maxTalkUsers: 0,
        productName: ''
    };
    public translations = {
        info: '',
        isUserIncluded: '',
        confirmationText: '',
        lableText: '',
        checkboxText: ''
    };

    public get isAppEnabled(): boolean {
        if (this.storageProduct && this.app) {
            return this.storageProduct.enabledApps.includes(this.app.id);
        }
        return false;
    }

    public attributeData: {
        max: number;
        min: number;
        step: number;
        unit: string;
        used: number;
    };

    // Data the quota changer needs but nothing else uses...
    public isBusinessProduct: boolean;
    public isFreeProduct: boolean;

    public attribute: 'officeUsers' | 'talkUsers';

    private _toggleClearUsersConfirm = false;
    public nextcloudHasUnfinishedJob = false;
    private _showUserConfiguration = false;
    public showClearUsersConfirm = false;
    public aggregateProductAddonData: Types.UI.nextcloudAggregatedProductAddonsData;
    public addonProduct: Types.ProductApi.Product;
    public storageProduct: Types.ManagedApplicationApi.Nextcloud;
    public originalNextcloud: Types.ManagedApplicationApi.Nextcloud;
    private _quotaChangeConfirmed = false;
    private _addedUsers: number;

    public constructor(
        private nextcloudHelper: NextcloudHelperService,
        private nextcloudAppHelper: NextcloudAppHelperService,
        private $translate: ng.translate.ITranslateService,
        private $timeout: ng.ITimeoutService
    ) {}

    public $onInit = (): void => {
        this.isBusinessProduct = this.storageProduct.productCode.includes('business');
        this.isFreeProduct = this.storageProduct.productCode.includes('free');
        this.originalNextcloud = ng.copy(this.storageProduct);

        this.translations = this.nextcloudAppHelper.initSliderTranslations(this.app.id);
        void this.nextcloudHelper.aggregateProductAddonsData(this.storageProduct.productCode)
            .then(this._initSlider);
    };

    private _initSlider = (value: Types.UI.nextcloudAggregatedProductAddonsData): void => {
        void this.$timeout(() => {
            this.aggregateProductAddonData = value;
            if (['collabora'].includes(this.app.id) && value.officeUsers.isExtensible) {
                this.addonProduct = value.officeUsers.productData;
                this.attribute = 'officeUsers';
                this.attributeData = {
                    max: value.officeUsers.max ? Number(value.officeUsers.max) : 0,
                    min: Number(value.officeUsers.included),
                    step: value.officeUsers.step ? Number(value.officeUsers.step) : 1,
                    unit: this.$translate.instant('TR_220519-49de95_TR'),
                    used: this.originalNextcloud[this.attribute]
                };
                this.addedUsers = ng.copy(this.attributeData.used);
                this.translationParams.maxOfficeUsers = Number(value.officeUsers.max);
                this.translationParams.productName = value.productData.name;
            }
            if (['talk_hpb'].includes(this.app.id) && value.talkUsers.isExtensible) {
                this.addonProduct = value.talkUsers.productData;
                this.attribute = 'talkUsers';
                this.attributeData = {
                    max: value.talkUsers.max ? Number(value.talkUsers.max) : 0,
                    min: value.talkUsers?.included ? Number(value.talkUsers.included) : 0,
                    step: value.talkUsers.step ? Number(value.talkUsers.step) : 1,
                    unit: this.$translate.instant('TR_220519-49de95_TR'),
                    used: this.originalNextcloud[this.attribute]
                };
                this.addedUsers = ng.copy(this.attributeData.used);
                this.translationParams.maxTalkUsers = Number(value.talkUsers.max);
                this.translationParams.productName = value.productData.name;
            }
        });
    };

    public get showUserConfiguration(): boolean {
        return this._showUserConfiguration && typeof this.attributeData === 'object';
    }
    public set showUserConfiguration(value) {
        this._showUserConfiguration = value;
    }

    public get isUserIncluded(): boolean {
        return this.attributeData.min > 0;
    }

    public get usersHaveBeenAdded(): boolean {
        return this.addedUsers > this.originalNextcloud[this.attribute];
    }

    public get addedUsers(): number {
        return this._addedUsers;
    }

    public set addedUsers(value: number) {
        this.addUsersCallback({ addedUsers: value, attribute: this.attribute });
        this._addedUsers = value;
    }

    public get quotaChangeConfirmed(): boolean {
        return this._quotaChangeConfirmed;
    }

    public set quotaChangeConfirmed(value) {
        this.confirmationCallback(value, this.attribute);
        this._quotaChangeConfirmed = value;
    }

    public get toggleClearUsersConfirm(): boolean {
        return this._toggleClearUsersConfirm;
    }
    public set toggleClearUsersConfirm(value) {
        this.clearUserCallback(value);
        this._toggleClearUsersConfirm = value;
    }

}

export class OrganPanelStorageProductAppConfigAdditionalUsersComponent implements ng.IComponentOptions {
    public transclude = {
        postSlider: '?postSlider'
    };
    public bindings = {
        app: '<',
        showUserConfiguration: '<',
        showClearUsersConfirm: '<',
        storageProduct: '<',
        addUsersCallback: '<',
        confirmationCallback: '<',
        clearUserCallback: '<'
    };
    public controller = OrganPanelStorageProductAppConfigAdditionalUsersController;
    public template = require('./storage-product-app-config-additional-users.html');
}
