import * as ng from 'angular';
import { DnsZoneModelService } from '../services/dns/zone-model';

export class ZoneName {
    public static $inject: string[] = ['dnsZoneModel'];

    public static Factory(dnsZoneModel: DnsZoneModelService) {
        const cache = {};

        const zoneNameFilter: any = (id: string | number) => {
            if (cache[id]) {
                if (cache[id] !== 'waiting') {
                    return cache[id].name;
                }
                return undefined;
            }

            cache[id] = 'waiting';

            return dnsZoneModel.findOne(id).then(
                (result) => {
                    cache[result.id] = result;
                }
            );
        };

        zoneNameFilter.$stateful = true;
        return zoneNameFilter;
    }
}
