/* eslint-disable */

import ng from 'angular';

import { Contingent, ContingentType } from '@/atomic-components';
import { BillingCycleToContractPeriodeConst, UiRights } from '@/configuration';
import {
    AccountHelperService, AsteriskNoteService, AsteriskNoteType, AuthContextService,
    BillingHelperService, PriceHelperService, ResourceModelService
} from '@/services/';
import { AccountApi, BillingApi } from '@/types';
import * as Types from '@/types';

export type TotalPriceTaxObject = {
    tax: number;
    vatRate: number;
};

export type TotalPriceObject = {
    currency: string;
    exchangeRatio: BillingApi.ExchangeRatio;
    index: string;
    rawPrice: number;
    rawBasisConverted: number;
    rawTax: null | TotalPriceTaxObject;
    amount: number;
};
export class MoleculeFormProductPriceController implements ng.IController {
    public static $inject: string[] = [
        '$translate',
        '$timeout',
        'accountHelper',
        'asteriskNote',
        'billingHelper',
        'resourceModel',
        'priceHelper'
    ];

    public account: AccountApi.Account | AccountApi.Subaccount;
    public contingent: Contingent;
    public noSpaceLeft = false;
    public priceInNewLine = false;
    public pricePrefixText: string = null;
    public vatAsteriskNoteType: AsteriskNoteType;
    public pricePostfixText: string;
    public priceStroke: boolean;
    public annualBillingAsteriskNoteType = AsteriskNoteType.ANNUALLY_CONTRACT_PERIOD;
    public callbackWhenPriceIsKnown: (price: number) => void;
    public voucher: Types.ViewTypes.ProductConfigVoucher;
    public voucherPrice: BillingApi.ArticlePurchasePrice;
    public hideAsterisks: boolean = false;

    private _loadingPrice = true;
    private _price: BillingApi.ArticlePurchasePrice;
    private _prices: BillingApi.ArticlePurchasePrice[];
    private _priceMultiplicator: number;
    private _productCode: string;
    private _totalPriceId: string;
    private _totalPriceObject: TotalPriceObject[] = [];
    private _priceText: string;
    private _rawPrice: number;
    private _additionalProductCodes: string[];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private $timeout: ng.ITimeoutService,
        public accountHelper: AccountHelperService,
        private asteriskNote: AsteriskNoteService,
        private billingHelper: BillingHelperService,
        private resourceModel: ResourceModelService,
        private priceHelper: PriceHelperService
    ) {}

    public $onInit(): void {
        this.account = this.account || AuthContextService.account;
        if ([undefined, null].indexOf(this._totalPriceId) >= 0) {
            this._totalPriceId = this.priceHelper.setTotalPriceIndex();
        }

        if (this.showVatAsteriskNote) {
            this.vatAsteriskNoteType = this.account.isCommercialCustomer
                ? AsteriskNoteType.VAT_EXCLUDED
                : AsteriskNoteType.VAT_INCLUDED;
        }

        this.callbackWhenPriceIsKnown = this.callbackWhenPriceIsKnown || undefined;

        if (this._productCode) {
            this._getProductPrice();
        }
    }

    public $onChanges(changes: ng.IOnChangesObject): void {
        if (
            (changes._productCode !== undefined && !changes._productCode.isFirstChange())
            || (
                changes._priceMultiplicator?.currentValue !== changes._priceMultiplicator?.previousValue
                && !changes._priceMultiplicator.isFirstChange()
                && !changes._priceMultiplicator !== undefined
            )
        ) {
            // Q: ToDo: BENÖTIGEN WIR DAS HIER? Eigentlich sollte sich der productCode doch nicht verändern, oder?
            // Q: Falls doch, haben wir das Problem, dass das totalPriceObjekt erneut dem
            // Q: totalPriceObject Array hinzugefügt wird !!!
            // A: Wird für's wechseln von Vertragslaufzeit gebraucht
            // A: @fixme anders machen (PUI-6968)
            this._getProductPrice();
        }
    }

    public get isAdmin(): boolean {
        return this.accountHelper.isRootAdmin(this.account);
    }

    public get maySeePrices(): boolean {
        return !(this._price?.netAmount === -1)
    }

    public get showPrefixText(): boolean {
        return [undefined, null, ''].indexOf(this.pricePrefixText) < 0 && !this.isAdmin && this.maySeePrices;
    }

    public get showPostfixText(): boolean {
        return [undefined, null, ''].indexOf(this.pricePostfixText) < 0 && !this.isAdmin && this.maySeePrices;
    }

    public get showAnnualBillingAsteriskNote(): boolean {
        if (this.voucher) return false;
        return this.billingHelper.getBillingCycle(this._price) === BillingCycleToContractPeriodeConst.annually;
    }

    /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    public set loadingPrice(_) {}
    public get loadingPrice(): boolean {
        return this._loadingPrice;
    }

    /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    public set priceText(_) {}
    public get priceText(): string {
        return this._priceText;
    }

    public get showVatAsteriskNote(): boolean {
        if (this._price?.netAmount === -1) {
            return false;
        }

        return !this.freeOfChargeProduct
            && !AuthContextService.isRootOrCompanyAccount;
    }

    /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    public set freeOfChargeProduct(_) {}
    public get freeOfChargeProduct(): boolean {
        return this._rawPrice === 0;
    }

    public get showVatAsterisk(): boolean {
        let condition = !this.hideAsterisks && this.showVatAsteriskNote;
        return condition;
    }

    public get showAnnualBillingAsterisk(): boolean {
        let condition = !this.hideAsterisks && this.showAnnualBillingAsteriskNote;
        return condition;
    }

    /*
    This method is intended to replace the old setPrice(...).
    In contrary to the old method, this method always takes in
    an array and tries to calculate a price based on that.
    */
    private setMultiPrice = (priceObjects: BillingApi.ArticlePurchasePrice[]): boolean | void => {
        let priceSum = 0;
        let priceText = '';

        for (let index = 0; index < priceObjects.length; index++) {
            const priceObject = priceObjects[index];
            let amount = this._priceMultiplicator ? this._priceMultiplicator : 1;
            amount = isNaN(parseInt(`${amount}`, 10)) ? 1 : parseInt(`${amount}`, 10);

            const totalPriceObject: TotalPriceObject = {
                currency: priceObject.currency,
                exchangeRatio: priceObject.exchangeRatio,
                index: this._totalPriceId,
                rawPrice: 0,
                rawBasisConverted: 0,
                rawTax: null,
                amount,
            };
            const emptyPriceObject = JSON.parse(JSON.stringify(totalPriceObject));

            if ([undefined, null].indexOf(priceObject) >= 0 // Price is not set.
                || this.loadingPrice                        // Price is currently being determined or loaded.
            ) {
                this._priceText = '';
                this._totalPriceObject.push(totalPriceObject);
                return false;
            }

            if (!this.freeOfChargeProduct) {
                this.asteriskNote.addNote(this.vatAsteriskNoteType);
            }

            const ignoreCommercial = false;
            const showPromotionPrice = [undefined, null].indexOf(priceObject.promotionalNetAmount) < 0;
            const showBasicCurrency = [undefined, null].indexOf(priceObject.exchangeRatio) < 0;
            let exchangeRatio = showBasicCurrency
                ? priceObject.exchangeRatio?.exchangeRatio
                : null;

            let calculatedPrice = this.priceHelper.calculatePrice(
                priceObject,
                this.account.isCommercialCustomer,
                ignoreCommercial,
                showPromotionPrice,
                exchangeRatio,
                showBasicCurrency
            );
            let scaledCalculatedPrice = calculatedPrice * amount;

            if (index === 0) {
                this._rawPrice = calculatedPrice;
            }
            totalPriceObject.rawPrice = calculatedPrice;

            if (this.callbackWhenPriceIsKnown) {
                this.callbackWhenPriceIsKnown( this._rawPrice );
            }

            totalPriceObject.rawBasisConverted = showBasicCurrency
                ? scaledCalculatedPrice
                : 0;

            if(typeof scaledCalculatedPrice == 'number') {
                priceSum += scaledCalculatedPrice;
            }

            totalPriceObject.rawTax = ng.copy(this.priceHelper.calculatePriceTax(
                priceObject,
                this.account
            ));

            if (this.voucher && !this.voucher.eligibleProductCodes.includes(this._productCode)) {
                this._totalPriceObject.push(emptyPriceObject);
                continue;
            }
            this._totalPriceObject.push(totalPriceObject);
        }

        // Account has no right to see prices
        if (this.isAdmin) {
            priceText = this.$translate.instant('d32cbe5d-f0c9-4c61-bfbd-b96446e0a774');
        } else if (
            priceObjects.some((priceObject) => priceObject.netAmount === -1) ||
                !AuthContextService.isGranted(UiRights.BIL_LIST_ARTICLE_PRICES)
        ) {
            priceText = this.$translate.instant('TR_301019-2f4ae4_TR');
        } else {
            /*
            assumption is that different items will (hopefully) never have different currencies
            */
            const firstItem = this._price;
            const currency =
                (firstItem.currency && firstItem.exchangeRatio)
                    ? firstItem.exchangeRatio?.baseCurrency?.toLocaleUpperCase()
                    : firstItem.currency?.toLocaleUpperCase();

            priceText = this.priceHelper.convertPriceToText(
                1,
                priceSum,
                currency,
                true,
                this.account,
            );
        }

        this._priceText = priceText;
    };

    // -----------

    public get currency(): string {
        if ([undefined, null].indexOf(this._price) >= 0
            || this.loadingPrice
        ) {
            return '';
        }
        return this.priceHelper.getProductCurrency(this._price);
    }

    private _getProductPrice = (): void => {
        const additionalCodes = this._additionalProductCodes;

        this._loadingPrice = true;
        const subAccount = AuthContextService?.account?.id !== this.account?.id
            ? this.account?.id
            : null
        ;

        const promises : PromiseLike<any>[] = [];
        promises.push(this.billingHelper.getPriceByProductCode(this._productCode, subAccount));
        if (this.contingent?.type === ContingentType.pool) {
            promises.push(this.resourceModel.findOne(this.contingent.type, this.contingent.id));
        }
        if(additionalCodes) {
            additionalCodes.forEach((additionalProductCode) => {
                promises.push(this.billingHelper.getPriceByProductCode(additionalProductCode, subAccount));
            })
        }

        void Promise.all(promises).then((promiseResolve) => {
            let isInContingent = false;
            let priceObjects: BillingApi.ArticlePurchasePrice[] = [];

            // Falls additional product codes vorhanden sind werden Kontingente nicht beachtet.
            if(!(additionalCodes && additionalCodes.length > 0)) {
                const simplePriceObject: BillingApi.ArticlePurchasePrice = promiseResolve[0];
                const poolObject = promiseResolve[1];

                // nur nötig bei contingents
                if (this.contingent?.type === ContingentType.bundle) {
                    const effectiveContingent = this.contingent.misc.bundle.effectiveContingentUsage.filter(
                        (contingent: any) => contingent.productCodes.some(
                            (includedProductCode: string) => includedProductCode === this._productCode
                        )
                    );

                    isInContingent = effectiveContingent.length > 0 && effectiveContingent[0].availableCapacity > 0;
                } else if (
                    [
                        ContingentType.databaseServer,
                        ContingentType.webserver
                    ].indexOf(this.contingent?.type) >= 0
                ) {
                    isInContingent = true;
                } else if (poolObject) {
                    if (this._productCode.indexOf('email-forwarder') >= 0) {
                        isInContingent = (poolObject.forwarderMailboxesLimit - poolObject.forwarderMailboxesAllocated) > 0;
                    } else if (this._productCode.indexOf('exchange') >= 0) {
                        isInContingent = (poolObject.exchangeMailboxesLimit - poolObject.exchangeMailboxesAllocated) > 0;
                    } else if (this._productCode.indexOf('imap') >= 0) {
                        isInContingent = (poolObject.imapMailboxesLimit - poolObject.imapMailboxesAllocated) > 0;
                    }
                }
                if (
                    isInContingent === true
                    && !!simplePriceObject?.netAmount
                    && AuthContextService.isGranted(UiRights.BIL_LIST_ARTICLE_PRICES)
                ) {
                    simplePriceObject.netAmount = 0;
                }

                priceObjects = [simplePriceObject];
            } else {
                priceObjects = promiseResolve;
            }

            // it has a reason, which is not known to man kind (lost hour timer: 3)
            void this.$timeout(() => {
                if (this.voucher) {
                    this.voucherPrice = this.priceHelper.getPriceFromVoucherAndPriceObject(
                        this.voucher, priceObjects[0]
                    )
                }

                if (this.voucherPrice) {
                    this._price = this.voucherPrice;
                    priceObjects[0] = this.voucherPrice;
                } else {
                    this._price = priceObjects[0];
                }

                if (priceObjects.some(priceObject => priceObject === -1)) {
                    this._price = {
                        netAmount: priceObjects[0],
                    };
                }

                this._loadingPrice = false;
                this._prices = priceObjects;
                this.setMultiPrice(priceObjects);
            });
        });
    };
}

export class MoleculeFormProductPriceComponent implements ng.IComponentOptions {
    public bindings = {
        _additionalProductCodes: '<?additionalProductCodes',
        _priceMultiplicator: '<?priceMultiplicator',
        _productCode: '<productCode',
        _totalPriceId: '<?totalPriceId',
        _totalPriceObject: '=?totalPriceObject',
        account: '<?',
        contingent: '<?',
        noSpaceLeft: '<?',
        priceStroke: '<?',
        priceInNewLine: '<?',
        pricePostfixText: '@?',
        pricePrefixText: '@?',
        voucher: '<?',
        hideAsterisks: '<?',
    };
    public template = require('./product-price.html');
    public controller =  MoleculeFormProductPriceController;
}
