import { LinkListOverviewItem, TextFilterField } from '../../atomic-components/molecules';
import { OverviewModel } from '../abstract';
import { RequestCancellerService } from '../request-canceller';
import {UserModelService } from './user-model';

import * as ng from 'angular';

export class UserOverviewModelService extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$state', '$translate', 'userModel']
    );

    public readonly service = 'user';
    public readonly objectType = 'User';
    public readonly idField = 'id';
    public readonly defaultFilterFields = ['username'];

    public activeMassAction = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};

    protected listCallbacks = [{callback: this.userModel.list}];

    private _cachedAvailableFilters = [];

    constructor(
        RequestCanceller: RequestCancellerService,
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private userModel: UserModelService
    ) {
        super(RequestCanceller);
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('userName', this.$translate.instant('TR_140119-796b28_TR')),
                new TextFilterField('userEmailAddress', this.$translate.instant('TR_140119-62adc5_TR'))
            ];
        }

        return this._cachedAvailableFilters;
    }

    public actionLinks = (params: { [param: string]: any }): LinkListOverviewItem[] => {
        return [
            {
                route: this.$state.current.name.split('.').indexOf('subaccounts') < 0
                ? 'account.users.new'
                : 'reseller.subaccounts.id.users.new',
                text: this.$translate.instant('TR_140119-db47e4_TR')
            }
        ] as LinkListOverviewItem[];
    };

    public getDisableFilter = () => {
        return {
            fancyFilter: true,
            searchFilter: false
        };
    };
}
