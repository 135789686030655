import * as ng from 'angular';

export class MoleculeButtonCancelDeletionController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public cancelDeletion: () => void;
    public disabled: boolean;
    public paid: boolean;
    public buttonTitle = '';

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = () => {
        this.buttonTitle = this.$translate.instant(
            (this.paid
                ? /* translationId */ '8aeea36b-2cb6-47f1-83d3-e652a68d74f6'
                : /* translationId */ 'TR_230119-3f6dbf_TR')
        );
    };
}

export class MoleculeButtonCancelDeletionComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        cancelDeletion: '<',
        disabled: '<',
        paid: '<'
    };
    public controller =  MoleculeButtonCancelDeletionController;
    public template = require('./button-cancel-deletion.html');
}
