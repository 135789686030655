import ng from 'angular';

import { WizardVhostDomainObject } from '@/atomic-components';
import { CalculatePriceFilter, ConvertAmountFilter } from '@/filters';
import * as Types from '@/types';

import {
    AuthContextService, DomainModelService, DomainOverviewModel, DomainVhostOverviewObject,
    PriceCacheService, PriceHelperService, TldListService, VhostModelService, VhostOverviewModel,
    WebspaceModelService
} from '../';

export class DomainHelper {
    public static hasTld(domainName: string): boolean {
        return domainName.length > 0
        && domainName.indexOf('.') >= 0;
    }

    public static getTld(domainName: string): string {
        const dotIndex = domainName.indexOf('.');

        if (dotIndex === -1) {
            return undefined;
        }

        const tld = domainName.slice(dotIndex + 1);

        if (tld.length < 2) {
            return undefined;
        }

        return tld.toLocaleLowerCase();
    }

    public static getTlds(domainNames: string[]): string[] {
        return domainNames
        .filter(DomainHelper.hasTld)
        .map(DomainHelper.getTld)
        .filter((tld) => tld !== undefined)
        .filter((value, index, array) => array.indexOf(value) === index)
        .sort();
    }

    public static isPremiumDomain(productCode: string): boolean {
        const productCodeParts = productCode.split('-');
        const suffix = productCodeParts.pop();

        return suffix === 'premium';
    }

    public static isNameserverInDomain(nameserverName: string, domainName: string): boolean {
        if (!nameserverName || !domainName || domainName.length === 0) {
            return false;
        }
        const endWithDomainName = nameserverName.indexOf(domainName, nameserverName.length - domainName.length) !== -1;

        return domainName === nameserverName
            || endWithDomainName;
    }
}

export class DomainHelperService {
    public static $inject: string[] = [
        '$translate',
        'calculatePriceFilter',
        'convertAmountFilter',
        'domainModel',
        'domainOverviewModel',
        'priceCache',
        'priceHelper',
        'tldList',
        'vhostModel',
        'vhostOverviewModel',
        'webspaceModel'
    ];

    private static $translate: ng.translate.ITranslateService;
    private static _tldList: any[] = [];

    public static setVhostOverviewObject(
        name: { name: string; nameUnicode: string },
        bundleId?: string,
        domain?: Types.BundleApi.DomainDetails,
        vhost?: Types.BundleApi.VHostDetails,
        child?: boolean,
        raw?: boolean
    ): DomainVhostOverviewObject {
        let id = [undefined, null].indexOf(domain) < 0 ? domain.id : null;
        id = [undefined, null].indexOf(vhost) < 0 ? vhost.id : id;
        raw = raw || false;

        const domainVhostObject = DomainHelperService.getTmpVhostObject(bundleId);

        domainVhostObject.id = id;
        domainVhostObject.domainName = name.name;
        domainVhostObject.domainNameUnicode = name.nameUnicode;
        domainVhostObject.type = DomainHelperService.getDomainType(domain, bundleId, child, raw);
        domainVhostObject.rawType = DomainHelperService.getDomainType(domain, bundleId, child, true);
        domainVhostObject.child = child || false;
        domainVhostObject.status = DomainHelperService.getVHostStatus(domain, vhost);

        if ([undefined, null].indexOf(vhost) < 0) {
            domainVhostObject.vHostId = vhost.id;
            domainVhostObject.phpVersion = vhost.phpVersion;
            domainVhostObject.isRedirect = false;

            if (vhost.type === 'redirection') {
                domainVhostObject.isRedirect = true;
                domainVhostObject.path = vhost.redirectionUrl;
            } else {
                domainVhostObject.path = vhost.webRoot;
            }
        }

        if ([undefined, null].indexOf(domain) < 0) {
            domainVhostObject.domainId = domain.id;
        }

        return domainVhostObject;
    }

    /** Return readable name of domain type **/
    public static getReadableNameOfDomainType = (type: string, raw?: boolean) => {
        if (raw) {
            return type;
        }

        switch (type) {
            case 'inclusiv':
                return (DomainHelperService.$translate === undefined)
                    ? 'Inklusivdomain'
                    : DomainHelperService.$translate.instant('TR_170419-63e11d_TR');
            case 'subdomain':
                return (DomainHelperService.$translate === undefined)
                    ? 'Subdomain'
                    : DomainHelperService.$translate.instant('TR_170419-7db878_TR');
            case 'external':
                return (DomainHelperService.$translate === undefined)
                    ? 'Externe Domain'
                    : DomainHelperService.$translate.instant('TR_170419-7bfdf1_TR');
            case 'additional':
                return (DomainHelperService.$translate === undefined)
                    ? 'Zusatzdomain'
                    : DomainHelperService.$translate.instant('TR_170419-2ed6f2_TR');
            default:
                // nothing to do here..
        }
    };

    /**
     * Returns the domain type.
     *
     * @param domain Domain... thing to return the domain status for
     * @param bundleId If this matches the domain's bundleId, return 'included domain'
     *
     * @returns The domain's type. Obviously.
     *
     * @todo There are some missing translations in here...
     */
    public static getDomainType(
        domain,
        bundleId: string,
        child?: boolean,
        raw?: boolean
    ): string {
        raw = raw || false;
        if ([undefined, null, false].indexOf(domain) >= 0) {
            if (child) {
                return this.getReadableNameOfDomainType('subdomain', raw);
            }

            return this.getReadableNameOfDomainType('external', raw);
        }

        const domainName = domain.domainNameUnicode !== undefined
            ? domain.domainNameUnicode
            : domain.nameUnicode;

        if (domain.bundleId === bundleId) {
            return this.getReadableNameOfDomainType('inclusiv', raw);
        }

        if (
            domainName.split('.').length > 2
            && DomainHelperService._tldList.indexOf('.' + domainName.split('.').slice(1).join('.')) < 0
        ) {
            return this.getReadableNameOfDomainType('subdomain', raw);
        }

        return this.getReadableNameOfDomainType('additional', raw);
    }

    /** Returns an empty DomainVhostOverviewObject */
    private static getTmpVhostObject(bundleId: string): DomainVhostOverviewObject {
        return {
            bundleId: [undefined, null, ''].indexOf(bundleId) === -1
                ? bundleId
                : null,
            domainId: null,
            domainName: '',
            domainNameUnicode: '',
            id: '',
            path: '',
            phpVersion: '',
            status: '',
            type: '',
            rawType: '',
            vHostId: null
        };
    }

    /** Returns... the vHost status of a domain? */
    private static getVHostStatus(
        domain: Types.BundleApi.DomainDetails,
        vhost: Types.BundleApi.VHostDetails
    ): string {
        if ([undefined, null].indexOf(vhost) < 0) {
            return vhost.status;
        }

        if ([undefined, null].indexOf(domain) < 0) {
            return 'unconfigured';
        }

        // I'm pretty certain that this won't ever happen...
        return '';
    }

    private _bundleVhostItems: any[] = [];
    private _apiRequest = 0; // cache count of api calls (pagination entries)
    private _tmpEntriesOnFirstCall = 0; // cache first result entries for pagination

    constructor(
        private $translate: ng.translate.ITranslateService,
        private calculatePriceFilter: CalculatePriceFilter,
        private convertAmountFilter: ConvertAmountFilter,
        private domainModel: DomainModelService,
        private domainOverviewModel: DomainOverviewModel,
        private priceCache: PriceCacheService,
        private priceHelper: PriceHelperService,
        private tldList: TldListService,
        private vhostModel: VhostModelService,
        private vhostOverviewModel: VhostOverviewModel,
        private webspaceModel: WebspaceModelService
    ) {
        DomainHelperService.$translate = $translate;
        DomainHelperService._tldList = tldList.getTlds(true, true);
    }

    public getDomainInfoObject = (
        domainStatus: any,
        productCode: string,
        defaultContact: any,
        contingents: any,
        OverlayAccountId?: string
    ) => {
        // if root account is logged in, use _accountId of selected account
        const accountId = AuthContextService.account.id === '1'
            ? OverlayAccountId
            : AuthContextService.account.id;
        return this.priceCache.listDomainPrices(
            domainStatus.extension,
            accountId
        ).then(
            (priceList) => {
                let priceObject: any;
                priceList.some((price: any) => {
                    if (price.productCode === productCode) {
                        if ([undefined, null].indexOf(contingents) < 0
                            && contingents.some((contingent: any) => {
                                return contingent.productCodes.some((code: string) => {
                                    return code === productCode;
                                }) && contingent.capacity >= 1;
                            })
                        ) {
                            price.price = null;
                        }
                        priceObject = price;
                        return true;
                    }
                    return false;
                });

                return ({
                    check: ng.copy(domainStatus),
                    orchestratorObject: {
                        authCode: '',
                        billingMode: priceObject.price === null
                            ? 'bundle'
                            : 'standalone',
                        createMode: domainStatus.status === 'available'
                            ? 'register'
                            : 'transfer',
                        domainHandle: defaultContact,
                        domainNameUnicode: domainStatus.domainNameUnicode,
                        type: 'Domain'
                    },
                    prices: [{
                        // billingCyle: 12,
                        contractPeriod: null,
                        currency: '',
                        platformId: '',
                        price: priceObject,
                        productCode: productCode,
                        service: '',
                        startPIT: '',
                        vatRate: 0
                    }]
                } as WizardVhostDomainObject);
            }
        );
    };

    // tslint:disable-next-line:cyclomatic-complexity
    public getPrice = (
        domain: any,
        promo?: boolean,
        premium?: boolean,
        priceField: string = null,
        alternativeCurrency?: boolean,
        taxType?: string
    ) => {
        // ToDo: PLEASE USE PRICE COMPONENTS!
        alternativeCurrency = alternativeCurrency || false;
        taxType = taxType || 'gross';

        // Type might be wrong? This is just a guess that gets rid of linter erors...
        let price: Types.BillingApi.ArticlePurchasePrice;
        let exchangeRatio = null;

        if (domain.domain.status !== 'available' && domain.domain.status !== 'registered') {
            return undefined;
        }

        if (premium) {
            if (domain.domain.premiumPrices === undefined || domain.domain.premiumPrices === null) {
                return undefined;
            }

            if (priceField !== null) {
                if ([undefined, null].indexOf(domain.domain.premiumPrices[priceField]) >= 0) {
                    price = this.getPriceObject(domain.domain, domain.prices, true);
                } else {
                    price = domain.domain.premiumPrices[priceField];
                }
            } else if (domain.domain.status === 'available') {
                price = domain.domain.premiumPrices.create;
            } else if (domain.domain.status === 'registered') {
                if ([undefined, null].indexOf(domain.domain.premiumPrices.transfer) >= 0) {
                    price = this.getPriceObject(domain.domain, domain.prices);
                } else {
                    price = domain.domain.premiumPrices.transfer;
                }
            } else {
                return undefined;
            }

            if (alternativeCurrency && price !== undefined && price.exchangeRatio !== null) {
                exchangeRatio = price.exchangeRatio.exchangeRatio;
            }
        } else {
            price = this.getPriceObject(domain.domain, domain.prices);

            if (alternativeCurrency && price !== undefined && price.exchangeRatio !== null) {
                exchangeRatio = price.exchangeRatio.exchangeRatio;
            }

            if (price === undefined) {
                return undefined;
            } else if (promo) {
                const viewCurrency = this.getCurrency(domain, exchangeRatio !== null);
                const viewFormatedPrice = this.calculatePriceFilter(
                    price.promotionalNetAmount,
                    price.amounts[0].vatRate,
                    1,
                    taxType,
                    exchangeRatio,
                    false,
                    price.productCode,
                    0
                );

                return this.convertAmountFilter(viewFormatedPrice) + ' ' + viewCurrency;
            }
        }

        if (price.netAmount === undefined) {
            return undefined;
        }

        if (price.netAmount === -1) {
            return this.$translate.instant('TR_220520-c522c8_TR');
        }

        const currency = this.getCurrency(domain, exchangeRatio !== null);
        const formatedPrice = this.calculatePriceFilter(
            price.netAmount,
            price.amounts[0].vatRate,
            1,
            taxType,
            exchangeRatio,
            false,
            price.productCode,
            0
        );

        return this.convertAmountFilter(formatedPrice) + ' ' + currency;
    };

    public getCurrency = (domain: any, showEur: boolean) => {
        if (domain.domain.status !== 'available' && domain.domain.status !== 'registered') {
            return undefined;
        }

        const price = this.getPriceObject(domain.domain, domain.prices);

        return this.priceHelper.getProductCurrency(price, showEur);
    };

    public getPriceObject = (
        domain: any,
        prices: Types.BillingApi.ArticlePurchasePrice[],
        renew: boolean = false
    ) => {
        if (domain.premiumPrices) {
            if (domain.status === 'available') {
                return domain.premiumPrices.create;
            }

            if (
                domain.status === 'registered'
                && domain.premiumPrices.transfer
            ) {
                return domain.premiumPrices.transfer;
            }
            // If no premium transfer price is set, then we take the 'normal' transfer price
        }

        if ([undefined, null].indexOf(prices) >= 0 || prices.length === 0) {
            return undefined;
        } else if (renew) {
            for (const price of prices) {
                if (price.productCode.indexOf('renew') >= 0) {
                    return price;
                }
            }
        }

        switch (domain.status) {
            case 'available':
                for (const price of prices) {
                    if (price.productCode.indexOf('create') >= 0) {
                        return price;
                    }
                }

                break;

            case 'registered':
                for (const price of prices) {
                    if (price.productCode.indexOf('transfer') >= 0) {
                        return price;
                    }
                }

                break;

            default: break;
        }
    };

    /**
     *  Returns a list of domains that can be linked in the bundle as Inclusive Domain.
     * (No domain that is already linked as an inclusive domain or as an additional domain in the bundle
     */
    public getPossibleBundlesIncludingDomains = (bundle: Types.BundleApi.Bundle, tlds?: string[]) => {
        let domainItems = [];
        const accountFilter = { field: 'accountId', value: bundle.accountId };
        tlds = tlds || [];
        const filters = {
            subFilter: [
                accountFilter,
                {
                    subFilter: tlds.map((contingentTld) => { // Not so nice when tlds is an empty array
                        return { field: 'DomainExtension', value: contingentTld };
                    }),
                    subFilterConnective: 'OR'
                }
            ],
            subFilterConnective: 'AND'
        };

        return this.domainModel.listWithoutPagination(100, undefined, filters)
            .then(
                (domainList) => {
                    return domainList.data.filter((domain: Types.DomainApi.Domain) => {
                        return [undefined, null, ''].indexOf(domain.bundleId) >= 0;
                    });
                }
            )
            .then(
                (domainList) => {
                    domainItems = domainList.map((domain) => ({ name: domain.name, value: domain.id }));
                    // get all webspaces from account without this bundle webspace
                    const webspaceFilter = {
                        subFilter: [
                            accountFilter,
                            { field: 'bundleId', value: bundle.id, relation: 'unequal'}
                        ],
                        subFilterConnective: 'AND'
                    };
                    return this.webspaceModel.listWithoutPagination(100, undefined, webspaceFilter);
                }
            )
            .then((webspaceRes) => webspaceRes.data)
            .then(
                (webspaces) => {
                    if (webspaces.length === 0) {
                        return [];
                    }
                    const vhostFilters = {
                        subFilter: [],
                        subFilterConnective: 'OR'
                    };
                    for (const webspace of webspaces) {
                        vhostFilters.subFilter.push({
                            field: 'webspaceId',
                            value: webspace.id
                        });
                    }

                    return this.vhostModel.listWithoutPagination(100, null, vhostFilters).then((res) => res.data);
                }
            )
            .then(
                (vhosts) => {
                    if (vhosts.length === 0) {
                        return [];
                    }

                    const domainFilters = {
                        subFilter: [],
                        subFilterConnective: 'OR'
                    };

                    for (const vhost of vhosts) {
                        domainFilters.subFilter.push({
                            field: 'domainNameUnicode',
                            value: vhost.domainNameUnicode
                        });
                    }

                    return this.domainModel.listWithoutPagination(100, null, domainFilters).then((res) => res.data);
                }
            )
            .then(
                (additionalBundleDomains) => {
                    if (additionalBundleDomains.length === 0) {
                        return domainItems;
                    }

                    for (const additionalDomain of additionalBundleDomains) {
                        domainItems = domainItems.filter(
                            (domain) => {
                                return domain.name !== additionalDomain.name;
                            }
                        );
                    }

                    return domainItems;
                }
            );
    };

    /**
     ************************************
     *  Bundle vhost list helpers
     ************************************
    **/

    public normalizeVhostTree = (
        object: Types.BundleApi.BundleVHostTreeVertex,
        bundleId?: string,
        level?: number
    ) => {
        if ([undefined, null].indexOf(level) >= 0) {
            level = 0;
        }

        if (level > 0) {
            this._bundleVhostItems.push(
                DomainHelperService.setVhostOverviewObject(
                    {
                        name: object.name,
                        nameUnicode: object.nameUnicode
                    },
                    bundleId,
                    object.domainDetails,
                    object.vHostDetails,
                    true
                )
            );
        }

        if (object.hasOwnProperty('children')) {
            object.children
            .map(
                (child) => this.normalizeVhostTree(child, bundleId, level + 1)
            );
        }

        if (level === 0) {
            const copy = ng.copy(this._bundleVhostItems);
            this._bundleVhostItems = [];

            return copy;
        }
    };

    public stdOverviewOnLoadMethod = (returnData, bundleId: string, overviewData?, raw?: boolean, limit?: number) => {
        const tmpDomainVhostObject = DomainHelperService.getTmpVhostObject(bundleId);
        const promises = [];
        let domainVhostObject: DomainVhostOverviewObject;

        if (overviewData === undefined) {
            overviewData = {
                additionalFilters: [],
                filters: {
                    simpleFilter: {
                        value: ''
                    }
                },
                pagination: {
                    currentPage: 1,
                    limit: limit || 10
                }
            };
        }

        this._apiRequest++;
        this._tmpEntriesOnFirstCall = 0;
        promises.push(this._getOverviewDomains(overviewData, bundleId));
        promises.push(this._getVhosts(overviewData));
        return Promise.all(promises).then(
            (res) => {
                res.map(
                    (promise, index) => {
                        return promise.data.map((domain) => [domain, index === 0]);
                    }
                )
                .reduce((domainsA, domainsB) => domainsA.concat(domainsB), [])
                .map(
                    ([domain, firstPromise]) => {
                        if (firstPromise) {
                            // handle domain promise object
                            domainVhostObject = {...tmpDomainVhostObject};
                            domainVhostObject.id = domain.id;
                            domainVhostObject.domainId = domain.id;
                            domainVhostObject.domainName = domain.name;
                            domainVhostObject.domainNameUnicode = domain.nameUnicode;
                            domainVhostObject.type = 'inclusiv';

                            returnData.data.push(domainVhostObject);
                            return;
                        }

                        // handle vHost promise object
                        const tmpDomain = this._getDomainOfVhost(domain, returnData);

                        if (!tmpDomain) {
                            // no domain match with this vHost
                            domainVhostObject = {...tmpDomainVhostObject};
                            domainVhostObject.id = domain.id;
                            domainVhostObject.domainName = domain.domainName;
                            domainVhostObject.domainNameUnicode = domain.domainNameUnicode;
                            domainVhostObject.vHostId = domain.id;
                            domainVhostObject.phpVersion = domain.phpVersion;
                            const redirectLocation = domain.locations.filter(
                                (location) => location.locationType === 'redirect' && location.matchType === 'default'
                            );
                            if (redirectLocation?.length >= 1) {
                                domainVhostObject.isRedirect = true;
                                domainVhostObject.path = redirectLocation[0].redirectionUrl;
                            } else {
                                domainVhostObject.isRedirect = false;
                                domainVhostObject.path = domain.webRoot;
                            }

                            returnData.data.push(domainVhostObject);
                        } else {
                            // domain of vhost is given - toDo
                            domainVhostObject = tmpDomain;
                            domainVhostObject.vHostId = domain.id;
                            domainVhostObject.phpVersion = domain.phpVersion;
                            domainVhostObject.path = domain.webRoot;
                        }

                        domainVhostObject.type = DomainHelperService.getDomainType(tmpDomain, bundleId, false, raw);
                        domainVhostObject.status = domain.status;

                        if (domainVhostObject.path.lastIndexOf('/') !== (domainVhostObject.path.length - 1)) {
                            domainVhostObject.path += '/';
                        }
                    }
                );

                if (returnData.pagination !== undefined) {
                    res.forEach((apiRequest) => {
                        this._tmpEntriesOnFirstCall += apiRequest.pagination.entries;
                    });

                    returnData.pagination.entries = this._tmpEntriesOnFirstCall;
                }

                return returnData;
            },
            () => [] // something went wrong
        );
    };

    /* Used in stdOverviewOnLoadMethod */
    private _getOverviewDomains = (overviewDataInital, bundleId?: string) => {
        const overviewData = ng.copy(overviewDataInital);
        if (bundleId !== undefined) {
            const filter = {
                field: 'bundleId',
                value: bundleId
            };
            if (overviewData.additionalFilters !== undefined) {
                overviewData.additionalFilters.push(filter);
            } else {
                overviewData.additionalFilters = [filter];
            }
        }
        overviewData.index = this._apiRequest - 1;

        return this.domainOverviewModel.callDomainOverviewList(overviewData);
    };

    /* Used in stdOverviewOnLoadMethod */
    private _getVhosts = (overviewDataInital) => {
        const overviewData = ng.copy(overviewDataInital);
        if (overviewData === undefined
            || overviewData.additionData === undefined
            || overviewData.additionData.webspace === undefined
        ) {
            return Promise.resolve({ data: [] });
        }
        overviewData.additionalFilters = [{
            field: 'webspaceId',
            value: overviewData.additionData.webspace.id
        }];
        overviewData.index = this._apiRequest - 1;
        return this.vhostOverviewModel.callVhostOverviewList(overviewData);
    };

    /* Used in stdOverviewOnLoadMethod */
    private _getDomainOfVhost = (vhost: Types.WebhostingApi.VHost, returnData) => {
        const domainList = returnData.data.filter(
            (domain) => domain.domainNameUnicode === vhost.domainNameUnicode
        );

        return domainList.length > 0 ? domainList[0] : false;
    };
}
