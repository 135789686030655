import { HostingIp } from '@/configuration';
import * as ng from 'angular';

import { DnsZoneModelService } from '../../../../../services/dns/zone-model';
import { DomainModelService } from '../../../../../services/domain/domain-model';
import { RedirectionModelService } from '../../../../../services/domain/redirection-model';
import * as Types from '../../../../../types';
import { WizardCreateObject } from '../../general';
import { RedirectionWizardConfirmation } from './redirection-wizard-confirmation';

export class OrganismRedirectionWizardConfirmViewController {
    public static $inject: string[] = [
        '$translate',
        'dnsZoneModel',
        'domainModel',
        'redirectionWizardConfirmation',
        'redirectionModel'
    ];

    public bundleId = '';
    public wizardCreateObjectList: WizardCreateObject[];
    public nextSteps: any[] = [];

    private _apiProductObject: any; // Required information for the products that need to be ordered.
    private _startRequests = false;

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private dnsZoneModel: DnsZoneModelService,
        private domainModel: DomainModelService,
        public redirectionWizardConfirmation: RedirectionWizardConfirmation,
        private redirectionModel: RedirectionModelService
    ) {}

    public $onInit() {
        this.wizardCreateObjectList = [];
        this._orderRedirection(this._apiProductObject.apiObject);
    }

    public set startRequests({}) {} // tslint:disable-line:no-empty
    public get startRequests() {
        return this._startRequests;
    }

    private _orderRedirection = async (apiObject) => {
        const domains = apiObject.redirection.domainName
            .split(/\r?\n/)
            .filter((line) => line.trim().length > 0)
            .filter((line, index, lines) => lines.indexOf(line) === index)
            .map((line: string) => line.split(',')[0]);

        // Build requests to create redirections.
        for (const domain of domains) {
            const redirection = ng.copy(apiObject.redirection);
            redirection.domainName = ng.copy(domain);

            this.wizardCreateObjectList.push({
                callback: () => {
                    return this.redirectionModel.create(redirection, apiObject.ownerAccountId);
                },
                children: [],
                labelText: this.$translate.instant('TR_231020-ad5f39_TR', { domain: redirection.domainName }),
                objectType: 'Redirection'
            });

            if (apiObject.adjustZone) {
                const domainFindResult = await this.domainModel.findByName([redirection.domainName]);
                const zoneFindResult = await this.dnsZoneModel.findOneByName(redirection.domainName);

                if (domainFindResult.length === 0 || zoneFindResult === 0) {
                    continue;
                }

                const recordsFindResult: Types.DnsApi.Record[] = await this.dnsZoneModel.findRecords(zoneFindResult.id);
                const dnsData =  {
                    existingRecords: recordsFindResult,
                    zone: zoneFindResult,
                    nameservers: domainFindResult[0].nameservers
                };

                const updateZones = await this._getZoneRecordsToUpdate(
                    recordsFindResult, redirection.domainName
                );

                if (
                    updateZones.addRecords.length > 0
                    || updateZones.deleteRecords.length > 0
                    || updateZones.modifyRecords.length > 0
                ) {
                    this.wizardCreateObjectList.push({
                        callback: () => this.dnsZoneModel.updateZone(
                            dnsData.zone,
                            updateZones.addRecords,
                            updateZones.deleteRecords,
                            null,
                            updateZones.modifyRecords),
                        children: [],
                        labelText: this.$translate.instant('TR_231020-3ace43_TR', {
                            domain: redirection.domainName }),
                        objectType: 'Zone'
                    });
                }
            }
        }

        this._startRequests = true;
    };

    private _getZoneRecordsToUpdate = (existingRecords: Types.DnsApi.Record[], domainName: string) => {
        const ip = HostingIp;
        const ttl = 86400;
        const addRecords: Types.DnsApi.Record[] = [];
        const modifyRecords: Types.DnsApi.Record[] = [];
        let foundCorrectEntry = false;
        let foundCorrectEntryWww = false;

        // Find A and AAAA records in the current zone.
        const recordsA = existingRecords.filter((record) => record.type === 'A');
        let deleteRecords = existingRecords.filter((record) => {
            return record.name === domainName && record.type === 'AAAA';
        }); // Remove AAAA.

        // Loop through A-records to find entries to delete.
        for (const recordA of recordsA) {
            if (recordA.name === domainName) {
                if (recordA.content !== ip) {
                    deleteRecords.push(recordA);
                } else {
                    foundCorrectEntry = true;
                }
            }
            if (recordA.name === `www.${domainName}`) {
                if (recordA.content !== ip) {
                    deleteRecords.push(recordA);
                } else {
                    foundCorrectEntryWww = true;
                }
            }
        }

        // When no entry was found with the correct ip, create it.
        if (!foundCorrectEntry || !foundCorrectEntryWww) {
            // Search for entries to modify.
            const foundDuplicateInDelete = deleteRecords.find(
                (delRecord) => {
                    return delRecord.type === 'A' && delRecord.name === domainName;
                }
            );
            const foundDuplicateWwwInDelete = deleteRecords.find(
                (delRecord) => {
                    return delRecord.type === 'A' && delRecord.name === `www.${domainName}`;
                }
            );

            // Modify existing record.
            if (foundDuplicateInDelete) {
                deleteRecords = deleteRecords.filter((delRecord) => delRecord !== foundDuplicateInDelete);
                foundDuplicateInDelete.content = ip;
                modifyRecords.push(foundDuplicateInDelete);
            } else {
                addRecords.push({
                    content: ip,
                    name: domainName,
                    ttl: ttl,
                    type: 'A'
                });
            }

            // Modify existing www-record.
            if (foundDuplicateWwwInDelete) {
                deleteRecords = deleteRecords.filter((delRecord) => delRecord !== foundDuplicateWwwInDelete);
                foundDuplicateWwwInDelete.content = ip;
                modifyRecords.push(foundDuplicateWwwInDelete);
            } else {
                addRecords.push({
                    content: ip,
                    name: `www.${domainName}`,
                    ttl: ttl,
                    type: 'A'
                });
            }
        }

        return {
            addRecords: addRecords,
            deleteRecords: deleteRecords,
            modifyRecords: modifyRecords
        };
    };
}

export class OrganismRedirectionWizardConfirmViewComponent implements ng.IComponentOptions {
    public bindings = {
        _apiProductObject: '<apiProductObject',
        wizardCreateObjectList: '<'
    };
    public template = require('./redirection-wizard-confirm-view.html');
    public controller = OrganismRedirectionWizardConfirmViewController;
}
