import * as ng from 'angular';
import {
    AlertManagerService,
    ApiErrorModel,
    MailboxModelService
} from '../../../../../services';

export class OrganismEditFormMailboxDomainsettingsController {
    public static $inject: string[] = [
        '$q',
        '$translate',
        'alertManager',
        'apiErrorModel',
        'mailboxModel'
    ];

    public originalMailbox: any;
    public initialDomainSettings: any;
    public domainSettings: string;
    public userPanelRight: any;
    public bundle: any = undefined;

    constructor(
        private $q: ng.IQService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private mailboxModel: MailboxModelService
    ) {}

    public $onInit = () => {
        this.domainSettings = ng.copy(this.initialDomainSettings);
    };

    public save = () => {
        return this.mailboxModel.updateDomainsettings(this.domainSettings).then(
            (res) => {
                if (!this.apiErrorModel.apiResponseHasError(res)) {
                    this.initialDomainSettings = ng.copy(this.domainSettings);
                    this.alertManager.success(this.$translate.instant('TR_100119-79c77f_TR'));
                    return res;
                }
                return this.$q.reject(false);
            },
            (error) => {
                return this.$q.reject(error);
            }
        );
    };

    public cancel = () => {
        this.domainSettings = ng.copy(this.initialDomainSettings);
    };

    public get errorList() {
        return this.apiErrorModel.errorObjectList;
    }

}

export class OrganismEditFormMailboxDomainsettingsComponent implements ng.IComponentOptions {
    public bindings = {
        initialDomainSettings: '<domainSettings',
        userPanelRight: '='
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormMailboxDomainsettingsController;
    public template = require('./mailbox-domainsettings.html');
}
