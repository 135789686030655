export type FormatMegabyteFilter = (value: string|number) => string;

export class FormatMegabyte {
    public static $inject: string[] = [];

    public static Factory() {
        return (value: string|number) => {
            if ([undefined, null].indexOf(value) >= 0) {
                return '-';
            }

            if (typeof value === 'string') {
                value = parseInt(value, 10);
            }
            if (value === 0) {
                return '0 MB';
            }
            if (value > 1023 || value < -1023) {
                const gbValue = FormatMegabyte.round(value / 1024, 2);
                return gbValue.toString() + ' GB';
            } else {
                return value.toString() + ' MB';
            }
        };
    }

    public static round(wert: string|number, dez: string|number): number {
        wert = parseFloat((wert as string));
        if (!wert) { return 0; }

        dez = parseInt((dez as string), 10);
        if (!dez) { dez = 0; }

        const umrechnungsfaktor = Math.pow(10, dez);

        return Math.round(wert * umrechnungsfaktor) / umrechnungsfaktor;
    }
}
