import * as Sentry from '@sentry/browser';
import * as ng from 'angular';
import { EditPanelStatus } from '@/atomic-components/molecules/forms';
import { DeletePanelButtonType } from '@/atomic-components/molecules/panels';
import {
    AlertManagerService,
    ApiErrorModel,
    DateWrapper,
    ManagedApplicationRobotService,
    NavigationService,
    ProductHelperService,
    ProductsRobotService,
    DataObject
} from '@/services';
import { ValidateMaxLength } from '@/services/validators';
import * as Types from '@/types';

type CancellationReasonAnswers = {
    inputValue: string;
    labelText: string;
};
export class OrganEditPanelNextcloudDeleteController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'alertManager',
        'apiErrorModel',
        'managedApplicationRobot',
        'navigation',
        'productsRobot'
    ];

    public cancellationReason: string;
    public cancellationReasonAnswers: CancellationReasonAnswers[] = [];
    public cancellationReasonDetail = '';
    public nextcloud: Types.ManagedApplicationApi.Nextcloud;
    public initialStatus: EditPanelStatus;
    public deletePanelButtonType = DeletePanelButtonType.NEXT_STEP;
    public deletionConfirmed = false;
    public deletionType = 'immediate';
    public deletionDate: DateWrapper;
    public deletionTypeItems: { label: string; value: string }[] = [];
    public finishIsFreeProductCheck = false;
    public validationInstructions: DataObject[];
    public validationDetailTextErrorList: { text: string }[] = [];
    private _isFreeProduct: boolean = null;
    private _cancellationHasSent = false;
    private _cancellationReasonDetailHide: boolean;
    private _cancellationReasonDefault: string;

    constructor(
        private $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private managedApplicationRobot: ManagedApplicationRobotService,
        private navigation: NavigationService,
        private productsRobot: ProductsRobotService
    ) {}

    public $onInit(): void {
        this._setCancellationReasonAnswers();
        this._checkFreeProduct();

        this.validationInstructions = [
            {
                instructions: null,
                validator: new ValidateMaxLength(this.$translate, 500)
            }
        ];

        this.deletionTypeItems = [{
            label: this.$translate.instant('8c45521c-005d-4867-bcf0-f0ef38b03e48'),
            value: 'immediate'
        }, {
            label: this.$translate.instant('TR_110119-0c8d3a_TR'),
            value: 'endOfContract'
        }, {
            label: this.$translate.instant('TR_110119-69fa60_TR'),
            value: 'date'
        }];

        this.initialStatus = this.nextcloud.deletionScheduledFor !== null
            ? EditPanelStatus.DELETIONSCHEDULED
            : EditPanelStatus.READONLY;
    }

    public delete = (): void => {
        try {
            this._cancellationHasSent = true;
            let cancellationReason = this.cancellationReason;
            if (
                !this._cancellationReasonDetailHide
                && this.cancellationReasonDetail.length > 0
            ) {
                cancellationReason += ' | Details: ' + this.cancellationReasonDetail;
            }

            void this.managedApplicationRobot.nextcloudDelete(
                this.nextcloud.id,
                (this.deletionDate.isToday() ? null : this.deletionDate.dateObj) as unknown as string,
                cancellationReason
            ).then(
                (apiResponse) => {
                    if (!this.apiErrorModel.apiResponseHasError(apiResponse)) {
                        if (this.isFreeProduct) {
                            this.alertManager.success(this.$translate.instant('TR_140120-b23ff9_TR'));
                        } else {
                            this.alertManager.success(this.$translate.instant('TR_140120-7cda2f_TR'));
                        }

                        void this.navigation.go(
                            'storage.storage-products.overview',
                            {},
                            { reload: true }
                        );
                    } else {
                        this._cancellationHasSent = false;
                    }
                },
                () => this._cancellationHasSent = false
            );
        } catch (error) {
            this._cancellationHasSent = false;
            // Throw console.error for development
            console.error(error);
            // Throw sentry error to track in live system
            Sentry.captureMessage(error);
        }
    };

    public cancel = (): void => {
        this.deletionConfirmed = false;
    };

    public get cancellationHasSent(): boolean {
        return this._cancellationHasSent;
    }

    public get enableDeletion(): boolean {
        return this.deletionConfirmed && (this.deletionType !== 'date' || this.deletionDate !== undefined);
    }

    public get cancellationReasonDetailHide(): boolean {
        return this._cancellationReasonDetailHide;
    }

    public cancelDeletion = (): void => {
        // Cancel deletion here:
        this._cancellationHasSent = true;
        void this.managedApplicationRobot
            .nextcloudDeletionCancel(this.nextcloud.id)
            .then(
                (apiResponse) => {
                    if (!this.apiErrorModel.apiResponseHasError(apiResponse)) {
                        this.alertManager.success(this.$translate.instant('TR_231019-4b9d05_TR'));
                        void this.navigation.go('storage.storage-products.overview', {}, { reload: true });
                    }
                }
            );
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function,no-empty-pattern
    public set isFreeProduct({}) {}
    public get isFreeProduct(): boolean {
        return [undefined, null].indexOf(this._isFreeProduct) < 0
            && this._isFreeProduct;
    }

    public cancellationAnswerChanged = (selectedOption: string): void => {
        this._cancellationReasonDetailHide = selectedOption === this._cancellationReasonDefault;
    };

    private  _checkFreeProduct = (): void => {
        void this.productsRobot
            .productFind(this.nextcloud.productCode)
            .then(
                (result) => {
                    this._isFreeProduct = ProductHelperService.wrapSpecificationItems(
                        result?.response?.specificationItems
                    ).isFreeProduct?.isTrue === true;

                    void this.$timeout(() => {
                        this.deletionConfirmed = this._isFreeProduct;
                        this.finishIsFreeProductCheck = true;
                    });
                }
            );
    };

    private _setCancellationReasonAnswers = (): void => {
        this._cancellationReasonDefault = this.$translate.instant('TR_250821-46ae89_TR');
        this.cancellationReasonAnswers = [
            {
                labelText: this._cancellationReasonDefault,
                inputValue: this._cancellationReasonDefault
            },
            {
                labelText: this.$translate.instant('TR_250821-e58a35_TR'),
                inputValue: this.$translate.instant('TR_250821-e58a35_TR')
            },
            {
                labelText: this.$translate.instant('TR_250821-464ea7_TR'),
                inputValue: this.$translate.instant('TR_250821-464ea7_TR')
            },
            {
                labelText: this.$translate.instant('TR_250821-0d7663_TR'),
                inputValue: this.$translate.instant('TR_250821-0d7663_TR')
            },
            {
                labelText: this.$translate.instant('TR_250821-df272e_TR'),
                inputValue: this.$translate.instant('TR_250821-df272e_TR')
            },
            {
                labelText: this.$translate.instant('TR_250821-bb4ed7_TR'),
                inputValue: this.$translate.instant('TR_250821-bb4ed7_TR')
            },
            {
                labelText: this.$translate.instant('TR_250821-f05156_TR'),
                inputValue: this.$translate.instant('TR_250821-f05156_TR')
            }
        ];

        if (!this.isFreeProduct) {
            // Add product costs too much option if product is a free one
            this.cancellationReasonAnswers.splice(3, 0, {
                labelText: this.$translate.instant('TR_250821-071f88_TR'),
                inputValue: this.$translate.instant('TR_250821-071f88_TR')
            });
        }

        // Set reason default
        this.cancellationReason = this.cancellationReasonAnswers[0].inputValue;
        this.cancellationAnswerChanged(this._cancellationReasonDefault);
    };
}

export class OrganEditPanelNextcloudDeleteComponent implements ng.IComponentOptions {
    public bindings = {
        initialStatus: '<',
        nextcloud: '='
    };

    public controller = OrganEditPanelNextcloudDeleteController;
    public template = require('./nextcloud-delete.html');
}
