export const BundleContingentProductCodesConst = {
    database: ['database-mariadb-single'],
    domain: ['renew']
};

export const BundleProductFamilies = {
    email: 'email-packages',
    webspace: 'webhosting-packages'
};

export const BundleObjectConst = {
    accountId: '',
    name: '',
    productCode: '',
    purchasedContingentExtensions: []
};
