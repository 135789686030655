import * as ng from 'angular';

import { FormDescriptionSpec } from '@/types/view-types/form-description/form-description';

export class FormElementInputBoolController implements ng.IComponentController {
    public static $inject: string[] = [];

    public disabled: boolean;
    public keenInput: FormDescriptionSpec.FieldObjectBool;
    public keenOutput: (model: boolean, key: string) => void;
    private _model: boolean;
    private key: string;
    private label: string;

    public $onInit = (): void => {
        this.disabled = this.disabled || false;
        this.key = this.keenInput.key || '';
        this.label = this.keenInput.ui.label || '';
        if ([undefined, null].indexOf(((this.keenInput as FormDescriptionSpec.FieldObject).value as boolean)) < 0) {
            this._model = ((this.keenInput as FormDescriptionSpec.FieldObject).value as boolean);
        } else if ([undefined, null].indexOf(((this.keenInput as FormDescriptionSpec.FieldObject).default as boolean)) < 0) {
            this._model = ((this.keenInput as FormDescriptionSpec.FieldObject).default as boolean);
        }
    };

    public get model(): boolean {
        return this._model;
    }

    public set model(value: boolean) {
        if (value !== this._model) {
            this._model = value;
            this.onChangeModel();
        }
    }

    private onChangeModel = () => {
        if (this.keenOutput) {
            this.keenOutput(this._model, this.key);
        }
    };
}

export class FormElementInputBoolComponent implements ng.IComponentOptions {
    public bindings = {
        keenInput: '<',
        keenOutput: '<',
        disabled: '<?'
    };
    public controller = FormElementInputBoolController;
    public template = require('./form-element-input-bool.html');
}
