import * as ng from 'angular';
import { TaskListItem } from '../../atomic-components/molecules/links/task-list/task-list';

export class AccountTaskManagerService {
    public static $inject: string[] = [];

    public get taskItemList(): TaskListItem[] {
        // return empty array. This task items will come from API in a while .....
        return [];
    }
}

/**
 *  TaskListItem example
 *  {
         title: "Datenbank <span class=\"marker\">Wordpress</span> ist voll", // html allowed
         status: "error",
         route: "",
         description: "Meatball ground rump boudin corned"
     }
 *
 */
