import * as ng from 'angular';
import {
    PanelHeaderData
} from '@/atomic-components/molecules/panels/panel-header/panel-header';
import * as Types from '@/types';

export class TemplateDomainMoveBulkController {
    public static $inject: string[] = ['$translate'];

    public domains: Types.DomainApi.Domain[] = [];
    public bundles: Types.BundleApi.Bundle[] = [];
    public subAccounts: any[];
    public selectedDomains: Types.DomainApi.Domain[] = [];
    public pageHeaderData: PanelHeaderData = {
        backwardLink: 'domain.domains.overview',
        backwardText: this.$translate.instant('TR_110119-7424eb_TR'),
        panelHeaderRoute: '',
        panelHeaderRouteParams: '',
        panelIcon: 'globe',
        panelTitle: this.$translate.instant('DOMAIN.MOVE.TITLE')
    };

    constructor(private $translate: ng.translate.ITranslateService) {}
}

export class TemplateDomainMoveBulkComponent implements ng.IComponentOptions {
    public bindings = {
        bundles: '<',
        domains: '<',
        selectedDomains: '<',
        subAccounts: '<'
    };
    public template = require('./domain-move-bulk-template.html');
    public controller = TemplateDomainMoveBulkController;
}
