import * as ng from 'angular';

export class AtomTextSaveController implements ng.IController {
    public static $inject: string[] = [];

    public modifier: string;

    $onInit() {
        this.modifier = this.modifier || '';
    }
}

export class AtomTextSaveComponent implements ng.IComponentOptions {
    public bindings = {
        modifier: '@?'
    };
    public controller =  AtomTextSaveController;
    public template = require('./text-save.html');
}
