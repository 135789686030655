import * as ng from 'angular';
import { OverviewDataObject } from '../../../../../../organisms';
import {
    AsteriskNoteType,
    AuthContextService,
} from '@/services/';

export class MoleculeOverviewPricelistRowPanelPromotionsController implements ng.IController {
    public static $inject: string[] = [];

    public overviewData: OverviewDataObject;
    public vatAsteriskNoteType: AsteriskNoteType = AsteriskNoteType.VAT_NONUNIFORM;

    public getVatHint(product: any) {
        if (
            product.amounts?.length > 1
            && product.grossAmount > 0
            && !AuthContextService.account.isCommercialCustomer
        ) {
            return true;
        }
        return false;
    }
}

export class MoleculeOverviewPricelistRowPanelPromotionsComponent implements ng.IComponentOptions {
    public require = {
        organismPricelistOverview: '^organismPricelistOverview'
    };
    public bindings = {
        overviewData: '<'
    };
    public controller =  MoleculeOverviewPricelistRowPanelPromotionsController;
    public controllerAs = 'OverviewPricelistCtrl';
    public template = require('./pricelist-promotions-row.html');
}
