import * as ng from 'angular';
import { keenRobotsCommon } from '../../../../../types';

export class MoleculeChartDatabaseRestrictionOverviewRowController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public restrictions: keenRobotsCommon.Restriction[];
    public restrictionLabel: string;
    public restrictionComment: string;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit() {
        if ([undefined, null].indexOf(this.restrictions) >= 0 || this.restrictions.length === 0) {
            return;
        }

        switch (this.restrictions[0].type) {
            default:
                const isUserAccessibleRestriction = this.restrictions.some(
                    (restriction) => restriction.accountId !== null
                );
                if (isUserAccessibleRestriction) {
                    this.restrictionLabel = this.$translate.instant('GENERAL.OBJECT.STATUS.RESTRICTED');
                } else {
                    this.restrictionLabel = this.$translate.instant('TR_280619-fc2564_TR');
                }
                this.restrictionComment =  this.$translate.instant('TR_310119-4767e1_TR');
                break;

            case 'overquota':
            case 'overQuota':
                this.restrictionLabel = this.$translate.instant('TR_310119-274ec6_TR');
                this.restrictionComment =  this.$translate.instant('TR_310119-ec69aa_TR');
                break;
        }
    }
}

export class MoleculeChartDatabaseRestrictionOverviewRowComponent implements ng.IComponentOptions {
    public bindings = {
        restrictions: '<'
    };
    public controller =  MoleculeChartDatabaseRestrictionOverviewRowController;
    public template = require('./restriction-overview-database-row.html');
}
