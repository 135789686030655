import * as ng from 'angular';

export class UserSettingsMigratorService {
    private currentVersion = 1;

    private migrationToVersionFns = {
        // Migrates v0 -> v1
        1: settings => {
            const showProductDefaultView = settings.showProductDefaultView ? settings.showProductDefaultView : false;
            const showLoadOverviewDataOnInit = settings.showLoadOverviewDataOnInit
                ? settings.showLoadOverviewDataOnInit
                : false;
            const productDefaultView = settings.productDefaultView ? settings.productDefaultView : {};
            const readNews = settings.readNews ? settings.readNews : [];
            const overviewSettings = {};

            if (settings.paginationLimits) {
                Object.keys(settings.paginationLimits).forEach(overviewCtrlName => {
                    if (!settings.paginationLimits.hasOwnProperty(overviewCtrlName)) {
                        return;
                    }

                    overviewSettings[overviewCtrlName] = {
                        paginationLimit: settings.paginationLimits[overviewCtrlName],
                        loadDataOnInit: true
                    };
                });
            }

            return {
                version: 1,
                readNews: readNews,
                showLoadOverviewDataOnInit: showLoadOverviewDataOnInit,
                showProductDefaultView: showProductDefaultView,
                productDefaultView: productDefaultView,
                overviewSettings: overviewSettings
            };
        }
    };

    public migrate = (settings) => {
        if (!settings.version) {
            settings.version = 0;
        }

        // !Maximum loop is set at 10 to avoid infinite loops
        for (let i = 0; settings.version < this.currentVersion && i < 10; i++) {
            console.info('Migrating UI settings to version ' + (settings.version + 1));
            const migrationFn = this.migrationToVersionFns[settings.version + 1];
            settings = migrationFn(settings); // settings.version has now been increased by 1
        }

        return settings;
    };

    public isCurrentVersion = (settings) => {
        return settings?.version === this.currentVersion;
    };
}
