import * as ng from 'angular';
import { MoleculePanelEditTableController } from '../../../panel-edit/table/panel-edit-table';
import { MoleculeRowWebspaceAccessController, WebspaceAccessObject, WebspaceUserObject } from '../../../panel-rows/panel-row-access/webhosting/webspace-access';

export class MoleculePanelCreateWebspaceAccessController implements ng.IController {
    public static $inject: string[] = ['$timeout'];

    public onAdd: (CreatedatabaseAccessObject) => any;
    public $panelEditTable: MoleculePanelEditTableController;
    public newWebspaceUserObject: WebspaceUserObject;
    public newAccessObject: WebspaceAccessObject;
    public setPageOverlay: boolean;
    public $moleculeFormEdit;
    public isValid = false;
    public accountId: string;
    public webspaceId: string;
    public resetAccess = false;
    public removeFromList: any[] = [];

    constructor(
        private $timeout: ng.ITimeoutService
    ) {}

    $onInit() {
        this.resetAccess = false;
        this.setPageOverlay = this.setPageOverlay || false;
        this.$moleculeFormEdit.saveCallback = this.addAccess;
    }

    public addAccess = (): void => {
        const returnObject = {
            access: this.newAccessObject,
            user: this.newWebspaceUserObject
        };
        this.onAdd(returnObject);
        this.$panelEditTable.createNewObject = false;

        this._resetAccess();
    };

    private _resetAccess = () => {
        this.resetAccess = true;
        this.$timeout(() => {
            this.resetAccess = false;
        }, 10);
    };
}

export class MoleculePanelCreateWebspaceAccessComponent implements ng.IComponentOptions {
    public bindings = {
        accountId: '<',
        webspaceId: '<',
        onAdd: '<',
        setPageOverlay: '<',
        removeFromList: '<'
    };
    public require = {
        $panelEditTable: '^moleculePanelEditTable',
        $moleculeFormEdit: '^moleculeFormEdit'
    };
    public controller =  MoleculePanelCreateWebspaceAccessController;
    public controllerAs = '$createWebspaceAccessCtrl';
    public template = require('./panel-create-webspace-access.html');
}
