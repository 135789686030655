import * as ng from 'angular';

import { AuthContextService } from '../../../../../services';

export class OrganViewPanelReferralLinkController {
    public referralSearch: string;
    public referralURL: string;

    constructor() {
        if (AuthContextService.account.referralCode?.length > 0) {
            this.referralSearch = '?partnerId=' + AuthContextService.account.referralCode;
            this.referralURL = 'https://hosting.de/' + this.referralSearch;
        }
    }

    public get showReferalLink () {
        return AuthContextService.isDirectHostingDeCustomer && this.referralSearch;
    }
}

export class OrganViewPanelReferralLinkComponent implements ng.IComponentOptions {
    public require = {};
    public controller = OrganViewPanelReferralLinkController;
    public template = require('./referral-link.html');
}
