import { JobOverviewModel, OverviewModel } from '../abstract';
import { RequestCancellerService } from '../request-canceller';

import {
    AccountFilterField,
    SelectFilterField,
    TextFilterField
} from '../../atomic-components/molecules/search/fancy-filter-fields';

export class DnsJobOverviewModel extends JobOverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', 'dnsJobModel']
    );

    public readonly service = 'dnsJob';
    protected listCallbacks = [{callback: this.dnsJobModel.list}];
    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private dnsJobModel: any
    ) {
        super(RequestCanceller);
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('jobDisplayName', this.$translate.instant('TR_140119-796b28_TR')),
                new SelectFilterField(
                    'jobObjectType',
                    this.$translate.instant('TR_070519-16189c_TR'),
                    [
                        {
                            name: this.$translate.instant('TR_140119-5549a1_TR'),
                            value: 'Zone'
                        },
                        {
                            name: this.$translate.instant('TR_140119-33a619_TR'),
                            value: 'Template'
                        },
                        {
                            name: this.$translate.instant('TR_140119-cd9e43_TR'),
                            value: 'NameserverSet'
                        }
                    ]
                ),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR')),
                new SelectFilterField(
                    'jobAction',
                    this.$translate.instant('TR_140119-3b64a8_TR'),
                    [
                        {
                            name: this.$translate.instant('TR_140119-79e1fd_TR'),
                            value: 'enable'
                        },
                        {
                            name: this.$translate.instant('TR_140119-6eac2e_TR'),
                            value: 'disable'
                        },
                        {
                            name: this.$translate.instant('TR_140119-5739fa_TR'),
                            value: 'create'
                        },
                        {
                            name: this.$translate.instant('TR_140119-1ab0d1_TR'),
                            value: 'recreate'
                        },
                        {
                            name: this.$translate.instant('TR_140119-aee71e_TR'),
                            value: 'delete'
                        },
                        {
                            name: this.$translate.instant('TR_140119-a1847d_TR'),
                            value: 'update'
                        },
                        {
                            name: this.$translate.instant('TR_140119-f9bd9e_TR'),
                            value: 'move'
                        },
                        {
                            name: this.$translate.instant('TR_140119-efffcc_TR'),
                            value: 'sign'
                        },
                        {
                            name: this.$translate.instant('TR_140119-37b10e_TR'),
                            value: 'publishKsk'
                        },
                        {
                            name: this.$translate.instant('TR_140119-33593c_TR'),
                            value: 'changeContent'
                        },
                        {
                            name: this.$translate.instant('TR_070621-b220a0_TR'),
                            value: 'applyChangeContentChanges'
                        },
                        {
                            name: this.$translate.instant('TR_140119-a904fe_TR'),
                            value: 'tieToTemplate'
                        },
                        {
                            name: this.$translate.instant('TR_140119-7e6cb0_TR'),
                            value: 'untieFromTemplate'
                        },
                        {
                            name: this.$translate.instant('TR_260220-d39038_TR'),
                            value: 'applyTemplateChanges'
                        },
                        {
                            name: this.$translate.instant('TR_140119-0bb06b_TR'),
                            value: 'resourceRecordSetUpdate'
                        }
                    ]
                ),
                new SelectFilterField(
                    'jobStatus',
                    this.$translate.instant('TR_140119-8e4b60_TR'),
                    [
                        {
                            name: this.$translate.instant('TR_140119-b8c5ea_TR'),
                            value: 'timed'
                        },
                        {
                            name: this.$translate.instant('TR_140119-202f1d_TR'),
                            value: 'new'
                        },
                        {
                            name: this.$translate.instant('eb9086cb-f0d5-47cf-924e-ab8eefcbf4f2'),
                            value: 'inProgress'
                        },
                        {
                            name: this.$translate.instant('TR_140119-bea3f9_TR'),
                            value: 'successful'
                        },
                        {
                            name: this.$translate.instant('TR_140119-521fce_TR'),
                            value: 'failed'
                        },
                        {
                            name: this.$translate.instant('TR_140119-e5071e_TR'),
                            value: 'support'
                        },
                        {
                            name: this.$translate.instant('TR_140119-a06687_TR'),
                            value: 'canceled'
                        },
                        {
                            name: this.$translate.instant('TR_140119-9948a6_TR'),
                            value: 'done'
                        },
                        {
                            name: this.$translate.instant('TR_140119-b2db6f_TR'),
                            value: 'running'
                        },
                        {
                            name: this.$translate.instant('TR_140119-0cb356_TR'),
                            value: 'onHold'
                        },
                        {
                            name: this.$translate.instant('TR_140119-4f2e9d_TR'),
                            value: 'deferred'
                        },
                        {
                            name: this.$translate.instant('TR_140119-fb6b6d_TR'),
                            value: 'waiting'
                        }
                    ]
                )
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters({}) {
        return;
    }
}
