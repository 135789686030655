import ng from 'angular';

import { ViewTypes } from '@/types/view-types';

export interface WebspaceNewAccessObject {
    ftpAccess: boolean;
    ftpLimited: boolean;
    homeDir: string;
    sshAccess: boolean;
    statsAccess: boolean;
    userId: string;
    webspaceId: string;
}

export interface WebspaceNewUserObject {
    accountId: string;
    comments: string;
    name: string;
    password: string;
    sshKey: string;
}

export class MoleculePanelRowWebspaceAccessSettingsController {
    public static $inject: string[] = ['$translate'];

    public webspaceUserAccess: ViewTypes.WebspaceUserAccessObject;
    public webspaceExistingUsers: { value: string; name: string }[];
    public accountId: string;
    public isValid = false;
    public resetAccess = false;
    public webspaceId = '';

    private newWebspaceUserObject: WebspaceNewUserObject;
    private newAccessObject: WebspaceNewAccessObject;

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = () => {
        if (this.webspaceUserAccess === null) {
            this.webspaceUserAccess = {
                userId: '',
                userName: '',
                accessType: ViewTypes.UserAccessType.new,
                name: '',
                comments: '',
                password: '',
                sshKey: '',
                homeDir: '',
                status: '',
                accessLevel: {
                    sshAccess: false,
                    ftpAccess: false,
                    statsAccess: false,
                    ftpLimited: false
                }
            };

            if (this.newWebspaceUserObject === null) {
                this.newWebspaceUserObject = {
                    accountId: this.webspaceUserAccess.userId,
                    comments: this.webspaceUserAccess.comments,
                    name: this.webspaceUserAccess.name,
                    password: this.webspaceUserAccess.password,
                    sshKey: this.webspaceUserAccess.sshKey
                };
            }

            if (this.newAccessObject === null) {
                this.newAccessObject = {
                    userId: this.webspaceUserAccess.userId,
                    webspaceId:'',
                    homeDir: this.webspaceUserAccess.homeDir,
                    ftpAccess: this.webspaceUserAccess.accessLevel.ftpAccess,
                    ftpLimited: this.webspaceUserAccess.accessLevel.ftpLimited,
                    statsAccess: this.webspaceUserAccess.accessLevel.statsAccess,
                    sshAccess: this.webspaceUserAccess.accessLevel.sshAccess
                };
            }
        }

        if (this.webspaceExistingUsers === null) {
            this.webspaceExistingUsers = [];
        }

        this.resetAccess = true;
    };

    public rightsChangedCallback = (value: boolean, field: string) => {
        if (this.webspaceUserAccess.accessLevel[field] !== value) {
            this.webspaceUserAccess.accessLevel[field] = value;
        }
    };

    public userAccessTypeChangeCallback = (accessType: ViewTypes.UserAccessType) => {
        if (accessType === ViewTypes.UserAccessType.new) {
            this.webspaceUserAccess.userId = '';
        }

        if (this.webspaceUserAccess.accessType !== accessType) {
            this.webspaceUserAccess.accessType = accessType;
        }
    };

    public usernameChangedCallback = (name: string) => {
        if (this.webspaceUserAccess.name  !== name) {
            this.webspaceUserAccess.name = name;
        }
    };

    public userPasswordChangedCallback = (password: string) => {
        if (this.webspaceUserAccess.password  !== password) {
            this.webspaceUserAccess.password = password;
        }
    };

    public sshKeyChangedCallback = (sshKey: string) => {
        if (this.webspaceUserAccess.sshKey  !== sshKey) {
            this.webspaceUserAccess.sshKey = sshKey;
        }
    };

    public userCommentChangedCallback = (comment: string) => {
        if (this.webspaceUserAccess.comments  !== comment) {
            this.webspaceUserAccess.comments = comment;
        }
    };

    public selectExistingUserChangeCallback = (choosenAccessId: string) => {
        if (this.webspaceUserAccess.userId  !== choosenAccessId) {
            this.webspaceUserAccess.userId = choosenAccessId;
        }
    };

    public userHomeDirChangedCallback = (homeDir: string) => {
        if (this.webspaceUserAccess.homeDir  !== homeDir) {
            this.webspaceUserAccess.homeDir = homeDir;
        }
    };

}

export class MoleculePanelRowWebspaceAccessSettingsComponent implements ng.IComponentOptions {
    public bindings = {
        accountId: '<',
        webspaceUserAccess: '=',
        webspaceExistingUsers: '=?',
        isValid: '='
    };
    public template = require('./webspace-access-settings.html');
    public controller = MoleculePanelRowWebspaceAccessSettingsController;
}
