import { ViewTypes, DnsApi } from '@/types';
import ng from 'angular';
import {
    ApiErrorModel,
    DnsZoneModelService,
    DomainModelService,
    ModelHelper,
    NavigationService
} from '@/services';
import * as Types from '@/types';
import { HandleTypes } from '@/configuration';

import './domain-edit-bulk.scss';

export class OrganismEditFormDomainBulkController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'apiErrorModel',
        'dnsZoneModel',
        'domainModel',
        'navigation'
    ];

    public domains: Types.DomainApi.Domain[];
    public selectedDomains: Types.DomainApi.Domain[];
    public subAccounts: Types.AccountApi.Subaccount;
    public selectedDomainNames: string[];
    public contacts: Types.DomainApi.Contact[];
    public selectedContacts: ViewTypes.DomainContactObject[] = [];
    public nameservers: Types.DnsApi.NameserverSet[];
    public selectedNameservers: any[];
    public defaultNameservers: Types.DnsApi.NameserverSet;
    public ownNameServers: any[] = [];
    public contactDataIsValid: boolean;
    public calloutType = 'information';
    public statusText = '';
    public failedElementList: string[] = [];
    public updateInProgress = false;

    private _domainsAreInSingleAccountCachedValue = true;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private apiErrorModel: ApiErrorModel,
        private dnsZoneModel: DnsZoneModelService,
        private domainModel: DomainModelService,
        private navigation: NavigationService
    ) {}

    public get domainsAreInSingleAccount(): boolean {
        const value = this.selectedDomains.every(
            (domain) => domain.accountId === this.selectedDomains[0].accountId
        );

        if (value !== this._domainsAreInSingleAccountCachedValue) {
            this._domainsAreInSingleAccountCachedValue = value;

            if (!value) {
                this.selectedContacts = [];
            }
        }

        return value;
    }

    public domainMassEditStatusUpdate = (
        doneElements: number,
        failedElements: number,
        queuedElements: number,
        failedElementList: any[]
    ): void => {
        const sum = doneElements + failedElements + queuedElements;

        if (failedElements > 0) {
            this.calloutType = 'error';
            this.failedElementList = failedElementList;
        }

        if (queuedElements === 0) {
            if (failedElements === 0) {
                this.calloutType = 'success';

                void this.$timeout(() => {
                    void this.navigation.go('domain.domains.overview', {}, { reload: true });
                }, 2000);
            } else {
                void this.$timeout(() => {
                    this.updateInProgress = false;
                }, 2000);
            }
        }

        void this.$timeout(() => {
            this.statusText = failedElements > 0
                ? this.$translate.instant('TR_180820-c12e85_TR', {
                    statusText: this.statusText,
                    failedNum: failedElements
                })
                : this.$translate.instant('TR_180820-938e33_TR', { doneElements: doneElements, sum: sum });
        });
    };

    public save = (): void => {
        this.updateInProgress = true;
        this.failedElementList = [];
        this.calloutType = 'information';

        ModelHelper.sequentiallyRequestApi(
            this.selectedDomains,
            'id',
            this,
            'saveDomain',
            undefined,
            undefined,
            undefined,
            this.domainMassEditStatusUpdate,
            this.apiErrorModel
        );
    };

    public saveDomain = (domain: Types.DomainApi.Domain) => {
        let domainChangesResponse = null;
        let domainHasBeenAltered = false;
        let newNameservers = [];

        if (this.selectedContacts.every((handle) => [undefined, null, '', '0'].includes(handle.contactId))
            && this.selectedNameservers === undefined
        ) {
            return; // nothing has been changed
        }

        // update domain contacts if they were changed
        for (const contactType of HandleTypes) {
            const selectedContact = this.selectedContacts.filter(
                (handle) => handle.contactType === contactType.toLowerCase()
            )[0];

            if (![undefined, null, '', '0'].includes(selectedContact.contactId)) {
                domain.contacts.some(
                    (domainContact) => {
                        if (domainContact.type === contactType.toLowerCase()) {
                            domainContact.contact = selectedContact.contactId;
                            domainHasBeenAltered = true;

                            return true;
                        }

                        return false;
                    }
                );
            }
        }

        // check for NS changes
        if (this.selectedNameservers !== undefined) { // user has selected new Nameservers (set or added custom NS)
            if (this.selectedNameservers === null) { // custom NS were selected
                newNameservers = this.ownNameServers;
            } else { // NS set was selected
                this.selectedNameservers.map(
                    (server) => {
                        newNameservers.push(
                            {
                                ips: [],
                                name: server
                            }
                        );
                    }
                );
            }

            if (newNameservers.length > 0) {
                domainHasBeenAltered = true;
                domain.nameservers = ng.copy(newNameservers);
            }
        }

        if (domainHasBeenAltered) {
            domainChangesResponse = this.domainModel.update(domain);
        }

        if (newNameservers.length > 0) {
            return this.dnsZoneModel.findOneByName(domain.name).then(
                (zoneData) => {
                    // if zone does not exist
                    if (zoneData === undefined) {
                        if (domainChangesResponse !== null) { // domain was updated
                            return domainChangesResponse; // return domain update promise
                        } else {
                            return; // something went terribly wrong!
                        }
                    }

                    return this.dnsZoneModel.findRecords(zoneData.id).then(
                        (records) => {
                            const recordsToRemove: Types.DnsApi.Record[] = [];

                            records.map(
                                (record) => {
                                    if (record.type === 'NS') {
                                        this._pushIfNotExists( // only push NS Servers that are not about to be added
                                            recordsToRemove,
                                            record,
                                            newNameservers,
                                            'name'
                                        );
                                    }
                                }
                            );

                            const recordsToAdd: Types.DnsApi.Record[] = [];

                            newNameservers.map(
                                (nameserver) => {
                                    this._pushIfNotExists( // only push NS Records that don't already exist in the zone
                                        recordsToAdd,
                                        {
                                            content: nameserver.name,
                                            name: domain.nameUnicode,
                                            ttl: 86400,
                                            type: 'NS'
                                        },
                                        records,
                                        'content'
                                    );
                                }
                            );

                            if (recordsToAdd.length > 0 || recordsToRemove.length > 0) {
                                return this.dnsZoneModel.updateZone(zoneData, recordsToAdd, recordsToRemove, null);
                            } else if (domainChangesResponse !== null) { // domain was updated
                                return domainChangesResponse; // return domain update promise
                            } else {
                                return; // something went terribly wrong!
                            }
                        }
                    );
                }
            );
        } else if (domainHasBeenAltered) { // domain was updated
            return domainChangesResponse;
        } else { // form has been submitted without any user changes
            return;
        }
    };

    // Haystack can be a list of Nameservers or a list of DNS-Records, so the lookupField has to be dynamic
    private _pushIfNotExists = (
        targetArrayOfRecords: DnsApi.Record[],
        newRecord: DnsApi.Record,
        haystack: unknown[],
        haystackLookupField: string
    ): void => {
        if (!haystack.some((existingElement) => existingElement[haystackLookupField] === newRecord.content)) {
            targetArrayOfRecords.push(newRecord);
        }
    };

    public get disableSaveButton(): boolean {
        if (
            this.selectedContacts.every((handle) => [undefined, null, '', '0'].includes(handle.contactId))
            && this.selectedNameservers === undefined
        ) {
            return true;
        }

        return (this.domainsAreInSingleAccount && !this.contactDataIsValid)
            || (
                this.selectedNameservers === null
                && this.ownNameServers.filter((ns) => ns.name.length > 0).length < 2
            );
    }
}

export class OrganismEditFormDomainBulkComponent implements ng.IComponentOptions {
    public bindings = {
        contacts: '<',
        defaultNameservers: '<',
        domains: '<',
        nameservers: '<',
        selectedDomains: '<',
        subAccounts: '<'
    };
    public controller = OrganismEditFormDomainBulkController;
    public template = require('./domain-edit-bulk.html');
}
