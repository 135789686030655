import * as ng from 'angular';
import { transitEnabledTlds } from '../../../../../../configuration';
import { AlertManagerService, DateWrapper } from '../../../../../../services';
import { OrganismOverviewController } from '../../../../../organisms/overview';

export class MoleculePanelOverviewHeaderMultiDeleteActionController implements ng.IController {
    public static $inject: string[] = ['alertManager', '$timeout', '$translate'];

    public OrganismOverview: OrganismOverviewController;
    public confirmHint = false;
    public currentDate: Date;
    public deleteType: string;
    public deleteDate: DateWrapper;
    public dateValidationErrors: any[];
    public disconnect = false;

    private serviceName: string;

    constructor(
        private alertManager: AlertManagerService,
        private $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.deleteType = 'instant';
        this.getTemplate();
        this.deleteDate = new DateWrapper(new Date());
        this.currentDate = new Date();
    }

    public abort = () => {
        this.OrganismOverview.overviewModel.activeMassAction = false;
    };

    public get mode() {
        switch (this.OrganismOverview.overviewModel.activeMassAction) {
            default: return 'delete';
            case 'transit': return 'transit';
        }
    }

    public actionPossibleFor = (item: any) => {
        switch (this.mode) {
            default:
            case 'delete':
                return true;

            case 'transit':
                return transitEnabledTlds.some(
                    (tld) => [item.name, item.nameUnicode].some(
                        (name) => name.lastIndexOf(tld) === (name.length - tld.length)
                    )
                );
        }
    };

    public submit = () => {
        this.OrganismOverview.submitMassAction(
            {
                actiontype: this.deleteType,
                deleteDate: this.deleteDate,
                disconnect: this.disconnect,
                methode: 'deleteSubmit'
            }
        // todo - show request message in view
        ).then(
                (result) => {
                    if (result) {
                        if (this.mode === 'transit') {
                            this.alertManager.success(this.$translate.instant('TR_100119-ae04ab_TR'));
                        } else {
                            this.alertManager.success(this.$translate.instant('TR_240119-bc5748_TR'));
                        }
                        this.abort();
                        this.$timeout(() => {
                            this.OrganismOverview.overviewLoad();
                        }, 1000);
                    }
                },
                (e) => {
                    console.error('error', e);
                }
            );
    };

    public getTemplate = () => require('./multi-delete-' + this.serviceName + '-action.html?external');

    public get deletionDateValid() {
        return this.deleteType !== 'date' || this.dateValidationErrors === undefined
            || this.dateValidationErrors.length === 0;
    }
}

export class MoleculePanelOverviewHeaderMultiDeleteActionComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        serviceName: '<'
    };
    public controller =  MoleculePanelOverviewHeaderMultiDeleteActionController;
    public template = require('./multi-delete-action.html');
}
