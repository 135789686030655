import * as ng from 'angular';
import './creating-overlay.scss';

export class MoleculeOverlayCreatingController implements ng.IController {
    public static $inject: string[] = [];
    public text: string;
    public visible: boolean;

}

export class MoleculeOverlayCreatingComponent implements ng.IComponentOptions {
    public bindings = {
        text: '@',
        visible: '<'
    };

    public controller =  MoleculeOverlayCreatingController;
    public template = require('./creating-overlay.html');
}
