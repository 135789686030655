import * as ng from 'angular';
import { DateWrapper, ValidateDate } from '../../../../../services';
import './date-select.scss';

export interface ValidateOptions {
    forbidPast: boolean;
    forbidFuture: boolean;
}

/**
*
*  Deprecated 11.11.19
*
**/
export class MoleculeFormDateSelectController implements ng.IController {
    public static $inject: string[] = ['$translate', '$timeout'];

    public yearDropdownItems: any[];
    public dayDropdownOptions: { febLong: any; febShort: any; long: any; short: any };
    public minYear: number;
    public maxYear: number;
    public date: DateWrapper;
    public $formEdit;
    public registerToForm: boolean;
    public validationOptions: ValidateOptions;
    public buttonModifier: string;
    public validationErrorList: any[] | { text: string }[];

    public monthDropdownItems: { name: string; value: number }[] = [];
    public onChangeCallback: (arg0: DateWrapper) => any;
    public callbackOnlyOnCompleteDate: boolean;
    private registrationIndex: number;
    private validator: ValidateDate;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private $timeout: ng.ITimeoutService
    ) {
        this.monthDropdownItems = [{
            name: this.$translate.instant('TR_090119-99a152_TR'),
            value: 1
        }, {
            name: this.$translate.instant('TR_090119-aa28f8_TR'),
            value: 2
        }, {
            name: this.$translate.instant('TR_090119-ba085d_TR'),
            value: 3
        }, {
            name: this.$translate.instant('TR_090119-de2991_TR'),
            value: 4
        }, {
            name: this.$translate.instant('TR_090119-2a0613_TR'),
            value: 5
        }, {
            name: this.$translate.instant('TR_090119-f9a01a_TR'),
            value: 6
        }, {
            name: this.$translate.instant('TR_090119-6356f4_TR'),
            value: 7
        }, {
            name: this.$translate.instant('TR_090119-bf8c2f_TR'),
            value: 8
        }, {
            name: this.$translate.instant('TR_090119-465792_TR'),
            value: 9
        }, {
            name: this.$translate.instant('TR_090119-4a93b5_TR'),
            value: 10
        }, {
            name: this.$translate.instant('TR_090119-ebf613_TR'),
            value: 11
        }, {
            name: this.$translate.instant('TR_090119-37ff07_TR'),
            value: 12
        }];

        this.dayDropdownOptions = {
            febLong: (Array (29) as any).fill(1).map((v, i) => {
                return {
                    name: i + 1,
                    value: i + 1
                };
            }),
            febShort: (Array (28) as any).fill(1).map((v, i) => {
                return {
                    name: i + 1,
                    value: i + 1
                };
            }),
            long: (Array (31) as any).fill(1).map((v, i) => {
                return {
                    name: i + 1,
                    value: i + 1
                };
            }),
            short: (Array (30) as any).fill(1).map((v, i) => {
                return {
                    name: i + 1,
                    value: i + 1
                };
            })
        };
    }

    public $onInit() {
        if ([undefined, null].indexOf(this.date) >= 0) {
            this.date = new DateWrapper();
            this.date.setToday();
        }
        this.yearDropdownItems = (Array (this.maxYear - this.minYear + 1) as any).fill(1).map((v, i) => {
            return {
                name: this.maxYear - i,
                value: this.maxYear - i
            };
        });
        if (this.registerToForm || this.registerToForm == null || [undefined, null].indexOf(this.registerToForm) >= 0) {
            this.registerToForm = true;
            if ([undefined, null].indexOf(this.$formEdit) < 0 && this.$formEdit !== null) {
                this.registrationIndex = this.$formEdit.registerValidator(this);
            }
        }
        let forbidPast: boolean;
        let forbidFuture: boolean;
        if ([undefined, null].indexOf(this.validationOptions) < 0) {
            forbidPast = this.validationOptions.forbidPast;
            forbidFuture = this.validationOptions.forbidFuture;
        }
        this.validator = new ValidateDate(this.$translate, forbidFuture, forbidPast);
        this.validationErrorList = [];
    }

    public $onDestroy() {
        if (this.registerToForm && [undefined, null].indexOf(this.$formEdit) < 0) {
            this.$formEdit.unregisterValidator(this.registrationIndex);
            this.$formEdit.validatorStatus[this.registrationIndex] = true;
        }
    }

    public getNumberOfDaysInMonth = (month?: number, year?: number) => {
        if ([undefined, null].indexOf(month) >= 0) {
            return 31;  // If we do not know the month, we show 31 days as a default
        }
        switch (month) {
            case 1:
            case 3:
            case 5:
            case 7:
            case 8:
            case 10:
            case 12:
                return 31;
            case 4:
            case 6:
            case 9:
            case 11:
                return 30;
            case 2:
                return this.getNumberOfDaysInFebruary(year);
            default:
                return 31;
        }
    };

    public getNumberOfDaysInFebruary = (year?: number) => {
        if ([undefined, null].indexOf(year) >= 0) {
            return 28;
        }
        if (year % 400 === 0) {
            return 29;
        }
        if (year % 100 === 0) {
            return 28;
        }
        if (year % 4 === 0) {
            return 29;
        } else {
            return 28;
        }
    };

    public yearChanged = (year: number) => {
        if ([undefined, null].indexOf(this.date) < 0) {
            if (this.date.day > this.getNumberOfDaysInMonth(this.date.month, year)) {
                this.date.day = this.getNumberOfDaysInMonth(this.date.month, year);
            }
        }
        if ([undefined, null].indexOf(this.$formEdit) < 0 && this.$formEdit !== null) {
            this.$timeout(() => {
                this.$formEdit.validate(this.registrationIndex);
            });
        }
        this.executeCallback();
    };

    public monthChanged = (month: number) => {
        if ([undefined, null].indexOf(this.date) < 0) {
            if (this.date.day > this.getNumberOfDaysInMonth(month, this.date.year)) {
                this.date.day = this.getNumberOfDaysInMonth(month, this.date.year);
            }
        }
        if ([undefined, null].indexOf(this.$formEdit) < 0 && this.$formEdit !== null) {
            this.$timeout(() => {
                this.$formEdit.validate(this.registrationIndex);
            });
        }
        this.executeCallback();
    };

    public dayChanged = () => {
        if ([undefined, null].indexOf(this.$formEdit) < 0 && this.$formEdit !== null) {
            this.$timeout(() => {
                this.$formEdit.validate(this.registrationIndex);
            });
        }
        this.executeCallback();
    };

    public validate = () => {
        if (
            [undefined, null].indexOf(this.date.day) >= 0
            || [undefined, null].indexOf(this.date.month) >= 0
            || [undefined, null].indexOf(this.date.year) >= 0
        ) {
            return true;
        }
        this.validationErrorList = this.validator.validate(this.date);
        return this.validationErrorList.length === 0;
    };

    public get dayDropdownItems() {
        let date = this.date;

        if ([undefined, null].indexOf(date) >= 0) {
            date = new DateWrapper(new Date());
        }

        switch (this.getNumberOfDaysInMonth(date.month, date.year)) {
            case 30: return this.dayDropdownOptions.short;
            case 31: return this.dayDropdownOptions.long;
            case 28: return this.dayDropdownOptions.febShort;
            case 29: return this.dayDropdownOptions.febLong;
            default: break;
        }
    }

    public get dateCompleted() {
        return [undefined, null].indexOf(this.date) < 0
            && [undefined, null].indexOf(this.date.day) < 0
            && [undefined, null].indexOf(this.date.month) < 0
            && [undefined, null].indexOf(this.date.year) < 0;
    }

    private executeCallback = () => {
        this.$timeout(
            () => {
                if (
                    [undefined, null].indexOf(this.onChangeCallback) < 0
                    && (this.dateCompleted || !this.callbackOnlyOnCompleteDate)
                ) {
                    this.onChangeCallback(this.date);
                }
            },
            50
        );
    };
}

export class MoleculeFormDateSelectComponent implements ng.IComponentOptions {
    public bindings = {
        buttonModifier: '@?',
        callbackOnlyOnCompleteDate: '<',
        date: '=',
        maxYear: '@',
        minYear: '@',
        onChangeCallback: '<',
        registerToForm: '<',
        validationErrorList: '=?',
        validationOptions: '<'
    };
    public require = {
        $formEdit: '?^moleculeFormEdit'
    };
    public controller =  MoleculeFormDateSelectController;
    public template = require('./date-select.html');
}
