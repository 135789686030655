import * as ng from 'angular';
import { EditPanelStatus } from '../../../atomic-components';
import { AlertManagerService, DnsZoneModelService, NavigationService } from '../../../services';

export class TemplateDnsZoneEditMoveController {
    public static $inject: string[] = ['$state', '$translate', 'alertManager', 'dnsZoneModel', 'navigation'];

    public zone: any;
    public userConfirmedMove = false;
    public icon = 'arrow-circle-right';
    public targetAccountId = '';
    public initialStatus = EditPanelStatus.ACTIVE;
    public pageHeaderData = {
        backwardLink: 'dns.zones.id.edit',
        backwardText: this.$translate.instant('TR_100119-4e4e17_TR'),
        panelHeaderRoute: '',
        panelHeaderRouteParams: {zoneId: ''},
        panelIcon: 'location-arrow',
        panelTitle: ''
    };

    public faqArticleIdList: string[] = [
        'dns-delete-entry',
        'dns-setup-office',
        'dns-srv-record'
    ];

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private dnsZoneModel: DnsZoneModelService,
        private navigation: NavigationService
    ) {
        this.pageHeaderData.panelHeaderRouteParams.zoneId = $state.params.zoneId;
    }

    public $onInit() {
        this.pageHeaderData.panelTitle = this.$translate.instant('TR_100119-5f4e92_TR', { zoneName: this.zone.name });
        this.targetAccountId = this.zone.accountId;
    }

    public moveZone = () => {
        this.dnsZoneModel.move([this.zone.id], this.targetAccountId).then(
            () => {
                this.alertManager.success(this.$translate.instant('TR_100119-232d26_TR'));
                this.navigation.go(this.$state.current.name.indexOf('.linked-zones') < 0 ? 'dns.zones.overview' : 'dns.templates.id.linked-zones.overview');
            },
            () => {
                this.alertManager.error(this.$translate.instant('TR_100119-d22c30_TR'));
            }
        );
    };
}

export class TemplateDnsZoneEditMoveComponent implements ng.IComponentOptions {
    public bindings = {
        zone: '<'
    };
    public template = require('./zone-edit-move-template.html');
    public controller = TemplateDnsZoneEditMoveController;
}
