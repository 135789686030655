import * as ng from 'angular';
import { AuthContextService, GetEntryPointService } from '../../../services';

import './sidebar.scss';

export class OrganismSidebarController implements ng.IController {
    public static $inject: string[] = ['authContext', 'getEntryPoint'];

    public menuIsOpen: boolean;
    public hasManagedServer: boolean;
    public hasNextcloudServer: boolean;
    public readonly showSupportButton: boolean;
    public displaySupportModal: {
        promise: boolean;
        contact: boolean;
    } = {
        contact: false,
        promise: false
    };

    constructor(
        authContext: AuthContextService,
        getEntryPoint: GetEntryPointService
    ) {
        this.showSupportButton = getEntryPoint.showSupportOptions
            && (authContext.isDirectCustomer || authContext.isStaff);
    }

    public $onInit(): void {
        this.menuIsOpen = false;
    }

    public openSupportPanel = (): void => {
        this.displaySupportModal.contact = true;
    };

    public toggleMenu = (): void => {
        this.menuIsOpen = !this.menuIsOpen;
    };
}

export class OrganismSidebarComponent implements ng.IComponentOptions {
    public bindings = {
        hasManagedServer: '<',
        hasNextcloudServer: '<'
    };
    public controller =  OrganismSidebarController;
    public template = require('./sidebar.html');
}
