import * as ng from 'angular';
import * as q from 'q';
import {
    AlertManagerService,
    ApiErrorModel,
    BackupModelService,
    NavigationService
} from '../../../../../../services';

export class OrganismEditFormBundleBackupsController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'apiErrorModel',
        'backupModel',
        'navigation'
    ];

    public originalBackups: any;
    public objectToBackup: any;
    public databaseBackups: any;
    public webspaceBackups: any;
    public userPanelRight: any;
    public service: string;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private backupModel: BackupModelService,
        private navigation: NavigationService
    ) {}

    public $onInit = () => {
        this.webspaceBackups = JSON.parse(JSON.stringify(this.webspaceBackups));
        this.databaseBackups = JSON.parse(JSON.stringify(this.databaseBackups));
    };

    public restoreBackups = (backup) => {
        this.apiErrorModel.destroyErrorList();

        return this.backupModel.restore(backup.targetService, backup.targetobjectType, backup.targetobjectId, backup.id)
        .then(
            (res) => {
                if (!this.apiErrorModel.apiResponseHasError(res)) {
                    this.alertManager.success(this.$translate.instant('BACKUP.ACTION.RESTORE.SUCCESS'));
                    this.navigation.reloadCurrentState();

                    return res;
                }

                return q.reject(false);
            }
        );
    };

    public deleteBackup = (backup) => {
        this.apiErrorModel.destroyErrorList();

        return this.backupModel.delete(backup.id)
        .then(
            (res) => {
                if (!this.apiErrorModel.apiResponseHasError(res)) {
                    this.alertManager.success(this.$translate.instant('TR_060420-16a2a4_TR'));
                    this.navigation.reloadCurrentState();

                    return res;
                }

                return q.reject(false);
            }
        );
    };

    public addNewBackup = (backup) => {
        const manualWebspaceBackups: any[] = this.webspaceBackups.filter((singleBackup) => singleBackup.type === 'manual');
        const manualDatabaseBackups: any[] = this.databaseBackups.filter((singleBackup) => singleBackup.type === 'manual');

        this.apiErrorModel.destroyErrorList();

        const orderPromises = [];

        if (backup.webspaceTarget) {
            let oldWebspaceBackupId = null;
            if (manualWebspaceBackups.length > 0) {
                const existingWebspaceBackup = manualWebspaceBackups.find(
                    (val) => val.targetobjectId === backup.webspaceTarget
                );
                oldWebspaceBackupId = existingWebspaceBackup.id || null;
            }
            orderPromises.push(
                this.orderBackupFromApi(
                    'webhosting',
                    'Webspace',
                    backup.webspaceTarget,
                    backup.backupName,
                    oldWebspaceBackupId
                )
            );
        }
        if (backup.databaseTargets.length > 0) {
            backup.databaseTargets.forEach((dbTarget) => {
            let oldDatabaseBackupId = null;

                if (manualDatabaseBackups.length > 0) {
                    const existingDatabaseBackup = manualDatabaseBackups.find(
                        (val) => backup.databaseTargets.indexOf(val.targetobjectId) >= 0
                    );
                    oldDatabaseBackupId = existingDatabaseBackup.id || null;
                }

                orderPromises.push(
                    this.orderBackupFromApi('database', 'Database', dbTarget, backup.backupName, oldDatabaseBackupId)
                );
            });
        }

        return q.all(orderPromises)
            .then(
                (_) => {
                    this.navigation.reloadCurrentState();
                }
            );
    };

    public orderBackupFromApi = (
        service: string,
        objectType: string,
        objectId: string,
        backupName: string,
        oldBackupId: string
    ) => {
        return this.backupModel.start(
                service,
                objectType,
                objectId,
                backupName,
                oldBackupId
            )
            .then(
                (res) => {
                    if (!this.apiErrorModel.apiResponseHasError(res)) {
                        this.alertManager.success(this.$translate.instant('BACKUP.ACTION.START.SUCCESS'));

                        return res;
                    }

                    return q.reject(false);
                }
            );
    };

    public get errorList() {
        return this.apiErrorModel.errorObjectList;
    }
}

export class OrganismEditFormBundleBackupsComponent implements ng.IComponentOptions {
    public bindings = {
        databaseId: '<',
        objectToBackup: '<',
        databaseBackups: '<',
        webspaceBackups: '<',
        service: '<',
        userPanelRight: '=',
        webspaceId: '<'
    };

    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormBundleBackupsController;
    /* tslint:disable-next-line:max-line-length */
    public template = require('./bundle-backups.html');
}
