import ng from 'angular';

import { EditPanelRight, EditPanelStatus } from '@/atomic-components/molecules';
import {
    AlertManagerService,
    NavigationService,
    OrganizationModelService
} from '@/services';

export class OrganismNewFormExchangeOrganizationController {
    public static $inject: string[] = ['$translate', 'alertManager', 'navigation', 'organizationModel'];

    public initialStatus = EditPanelStatus.ACTIVE;
    public panelRightSettings: EditPanelRight;
    public organization = {
        comment: '',
        name: ''
    };

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private navigation: NavigationService,
        private organizationModel: OrganizationModelService
    ) {}

    public $onInit(): void {
        this.panelRightSettings = {
            editPanelButton: false
        };
    }

    public save = (): void => {
        void this.organizationModel.create(this.organization).then(
            () => {
                this.alertManager.success(this.$translate.instant('TR_100119-7bc4c2_TR'));
                void this.navigation.go('email.organizations.overview');
            }
        );
    };
}

export class OrganismNewFormExchangeOrganizationComponent implements ng.IComponentOptions {
    public controllerAs = '$editFormOrganism';
    public controller = OrganismNewFormExchangeOrganizationController;
    public template = require('./exchange-organization-new.html');
}
