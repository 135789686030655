import * as ng from 'angular';

export class OrganEditPanelWebhostingUserGeneralController {
    public static $inject: string[] = [];

    public cancel: any;
    public $editFormOrganism: any;

    public get shortenedSshKey() { // used in template
        const sshKey = this.$editFormOrganism.user.sshKey;
        if ([undefined, null, ''].indexOf(sshKey) < 0 && sshKey.length > 60) {
            return sshKey.substring(0, 16) + '[...]' + sshKey.substring(sshKey.length - 40);
        }
        return sshKey;
    }

    public isChangeUserPassword = (password) => {
        return password?.length > 0;
    };
}

export class OrganEditPanelWebhostingUserGeneralComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormWebhostingUser'
    };
    public controllerAs = '$editPanelOrgan';
    // tslint:disable-next-line: max-line-length
    public template = require('./webhosting-user-general.html');
    public controller = OrganEditPanelWebhostingUserGeneralController;
}
