import * as ng from 'angular';
import { RouteFinderService } from '@/services/route-finder';
import { AuthContextService } from '@/services/auth-context';

export class OrganismPagePermissionsController {
    public static $inject: string[] = [
        '$rootScope',
        '$timeout',
        'routeFinder'
    ];
    public collapsed: boolean;
    public show: boolean;
    public permissions: { optionalRights: string[]; requiredRights: string[]; };

    constructor(
        private $rootScope: ng.IRootScopeService,
        private $timeout: ng.ITimeoutService,
        private routeFinder: RouteFinderService
    ) {}

    public $onInit = () => {
        if (this.show == undefined) {
            this.show = true;
        }
        this.collapsed = true;
        this.$timeout(() => {
            if (AuthContextService.isRoot) {
                this.show = true;
                this.$rootScope.$on('$stateChangeSuccess', () => {
                    this.$timeout(() => {
                        this.permissions = this.routeFinder.getPermissionsTable(location.pathname);
                    }, 500);
                });
                this.permissions = this.routeFinder.getPermissionsTable(location.pathname);
            }
        }, 1000);
    }

    public get toggleIcon() {
        return this.collapsed ? 'chevron-up' : 'chevron-down';
    }

    public toggleCollapsed = function() {
        this.collapsed = !this.collapsed;
    };
}

export class OrganismPagePermissionsComponent {
    public bindings = {
        show: '<?'
    };

    public controller = OrganismPagePermissionsController;
    public template = require('./page-permissions.html');
}
