import * as ng from 'angular';

export class TemplateDnsTemplateNewController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData = {
        backwardLink: 'dns.templates.overview',
        backwardText: this.$translate.instant('TR_100119-787b1a_TR'),
        panelHeaderRoute: 'dns.templates.overview',
        panelIcon: 'adjust',
        panelTitle: this.$translate.instant('DNS.TEMPLATE.NEW.HEADLINE')
    };

    constructor(private $translate: ng.translate.ITranslateService) {}
}

export class TemplateDnsTemplateNewComponent implements ng.IComponentOptions {
    public bindings = {};
    public controller = TemplateDnsTemplateNewController;
    public template = require('./template-new-template.html');
}
