import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateEmailMailboxJobDetailsController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;
    public job: any;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'email.mailboxes.jobs',
            backwardText: this.$translate.instant('TR_210219-434fee_TR'),
            panelHeaderRoute: '',
            panelIcon: 'envelope',
            panelTitle: this.$translate.instant('TR_210219-76879d_TR', { jobName: this.job.emailAddress })
        };
    };
}

export class TemplateEmailMailboxJobDetailsComponent implements ng.IComponentOptions {
    public bindings = {
        job: '<'
    };
    public controller = TemplateEmailMailboxJobDetailsController;
    public template = require('./email-mailbox-job-details-template.html');
}
