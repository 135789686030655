import * as ng from 'angular';

import * as Types from '../../../../../types';
import { EditPanelStatus } from '../../../../molecules/forms';

import './rights.scss';

export class OrganEditPanelRightsController implements ng.IController {
    public static $inject: string[] = ['$timeout', '$translate'];

    public $editForm;
    public allRights: any; // all rights that exist (according to what the backend says)
    public groupingSettings: any[];
    public groupings: any[] = [];
    public inalienableRights: string[]; // list of rights, can't be taken away (eg because they are set by a template)
    public initialStatus: EditPanelStatus;
    public initialUserData: any = [];
    public minNumberOfRights: number;
    public panelRightSettings: any;
    public rightsToEdit: string []; // list of rights that is changed by the user
    public rightsInTemplate: string [] = [];
    public showValidationError = false;
    public userChangesFound = false;
    public displayTemplateSelector = true;
    public templateDropdownItems: any[] = [];
    public templates: any = [];
    public userData: Types.AccountApi.User;

    private _editableRights: string[]; // list of rights that the user has permission to change
    private registrationIndex: number;
    private rightsBackup: string[];
    private subGroupingCounter = 0;

    constructor(
        private $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        if (this.groupingSettings === undefined) {
            this.createGroupingSettings();
        }
        this.createRightGroupings();
        this.rightsBackup = ng.copy(this.rightsToEdit, this.rightsBackup);
        if (this.minNumberOfRights !== undefined && this.minNumberOfRights > 0 && this.$editForm !== undefined) {
            this.registrationIndex = this.$editForm.registerValidator(this);
        }
        if ([undefined, null, []].indexOf(this.templates) === -1) {
            this.templateDropdownItems.push(
                {
                    name: 'Individuell',
                    value: null
                }
            );
            for (const template of this.templates) {
                this.templateDropdownItems.push(
                    {
                        name: template.name,
                        value: template.id
                    }
                );

                if (template.id === this.userData.rightsTemplateId) {
                    this.rightsInTemplate = template.rights;
                    this.inalienableRights = template.rights;
                }
            }
        } else {
            this.displayTemplateSelector = false;
        }

        if ([undefined].indexOf(this.userData) < 0 && ['', undefined].indexOf(this.userData.rightsTemplateId) !== -1) {
            this.userData.rightsTemplateId = null;
        }

        this.initialUserData = ng.copy(this.userData);
    }

    public $onDestroy() {
        if (this.minNumberOfRights !== undefined && this.minNumberOfRights > 0 && this.$editForm !== undefined) {
            this.$editForm.unregisterValidator(this.registrationIndex);
            this.$editForm.validatorStatus[this.registrationIndex] = true;
        }
    }

    public get editableRights() {
        return this._editableRights;
    }

    public set editableRights(newValue) {
        this._editableRights = newValue;
        if ([undefined, null].indexOf(newValue) < 0) {
            this.createRightGroupings();
        }
    }

    public get templateName() {
        if (this.templates !== undefined) {
            for (const template of this.templates) {
                if (template.id === this.userData.rightsTemplateId) {
                    return template.name;
                }
            }
        }
        return this.$translate.instant('TR_110119-0d63a6_TR');
    }

    public templateSelected = (value: any) => {
        if (value === null) {
            this.userChangesFound = false;
            this.userData.rightsTemplateId = null;
            this.rightsInTemplate = [];
            this.inalienableRights = [];
        } else {
            for (const template of this.templates) {
                if (template.id === value) {
                    this.userChangesFound = false;
                    this.rightsToEdit = ng.copy(template.rights);
                    this.userData.rights = ng.copy(template.rights);
                    this.userData.rightsTemplateId = ng.copy(template.id);
                    this.rightsInTemplate = ng.copy(template.rights);
                    this.inalienableRights = ng.copy(template.rights);
                }
            }
        }
    };

    public cancel = () => {
        this.userData = ng.copy(this.initialUserData);
        this.rightsToEdit = ng.copy(this.rightsBackup, this.rightsToEdit);
    };

    public toggleOptionsDisplay = (grouping: any) => {
        grouping.extended = !grouping.extended;
    };

    public validate = () => {
        if (this.minNumberOfRights !== undefined) {
            this.showValidationError = this.minNumberOfRights >= this.rightsToEdit.length;
            return this.minNumberOfRights <= this.rightsToEdit.length;
        }
        return false;
    };

    public checkNumberOfRights = () => {
        this.$timeout(
            () => {
                if (this.minNumberOfRights <= this.rightsToEdit.length) {
                    this.showValidationError = false;
                    if (this.$editForm !== undefined) {
                        this.$editForm.validatorStatus[this.registrationIndex] = true;
                    }
                }
            },
            10
        );
    };

    public getSelectedInfo = (rightsInGroup: any[]) => {
        const count = this.getCountRights(rightsInGroup);

        return `(${count.selected.toString()} ${this.$translate.instant('TR_110119-e3b6d8_TR')} `
        + `${count.sum} ${this.$translate.instant('TR_110119-28a32f_TR')})`;
    };

    public getIndeterminateState = (rightsInGroup: any[]) => {
        const count = this.getCountRights(rightsInGroup);
        return ( count.selected < count.sum && count.selected !== 0 );
    };

    public hasUserChanges = (rightsInGroup: any[]) => {
        if (
            [undefined].indexOf(this.userData) >= 0
            || [undefined, null, ''].indexOf(this.userData.rightsTemplateId) !== -1) {
            return false;
        }

        let userChanges = false;

        rightsInGroup.forEach(
            (right) => {
                if (this.rightsToEdit.indexOf(right) >= 0) {
                    if (this.rightsInTemplate.indexOf(right) === -1) {
                        userChanges = true;
                    }
                }
            }
        );

        this.userChangesFound = userChanges;

        return userChanges;
    };

    public showUserChangesNotice = () => {
        if ([undefined].indexOf(this.userData) !== -1) {
            return false;
        }
        return this.userData.rightsTemplateId !== null;
    };

    private getCountRights = (rightsInGroup: any[]) => {
        let cntRights = 0;
        let cntSelectedRights = 0;

        rightsInGroup.forEach(
            (right) => {
                cntRights++;
                if (this.rightsToEdit.indexOf(right) >= 0) {
                    cntSelectedRights++;
                }
            }
        );

        return { sum: cntRights, selected: cntSelectedRights };
    };

    public get validationMessage() {
        if (this.minNumberOfRights === undefined) {
            return '';
        }
        if (this.minNumberOfRights === 1) {
            return this.$translate.instant('TR_110119-30ab95_TR');
        } else {
            return this.$translate.instant('TR_110119-12d9c4_TR', this.minNumberOfRights);
        }
    }

    private createGroupingSettings = () => {
        const services: any = {};

        for (const right of this.allRights) {
            if (services[right.service] === undefined) {
                services[right.service] = [right.grouping];
            } else {
                if (services[right.service].indexOf(right.grouping) < 0) {
                    services[right.service].push(right.grouping);
                }
            }
        }

        this.groupingSettings = [];

        for (const key in services) {
            if (services.hasOwnProperty(key)) {
                for (const grouping of services[key]) {
                    this.groupingSettings.push(
                        {
                            name: grouping,
                            service: key,
                            subGroups: null,
                            title: grouping
                        }
                    );
                }
            }
        }
    };

    private createRightGroupings = () => {
        this.groupings = [];

        if ([undefined, null].indexOf(this.groupingSettings) >= 0) {
            return;
        }

        for (const groupingSetting of this.groupingSettings) {
            const newGrouping = {
                extended: false,
                masterCheckboxEditableRights: [],
                masterCheckboxRights: [],
                service: groupingSetting.service,
                subGroupings: this.createRightSubGroupings(groupingSetting.name, groupingSetting.subGroups),
                title: groupingSetting.title
            };

            if (newGrouping.subGroupings.length > 0) {
                this.setMasterCheckboxRights(newGrouping);
                this.groupings.push(newGrouping);
            }
        }
    };

    private createRightSubGroupings = (group: string, subGroups?: any[]) => {
        const foundSubGroupings: string[] = [];
        let subGroupings: any[] = [];
        for (const right of this.allRights) {
            if (
                right.grouping === group
                && foundSubGroupings.indexOf(right.subgrouping) < 0
            ) {
                foundSubGroupings.push(right.subgrouping);

                if (subGroups == null || subGroups === undefined) {
                    const subGrouping = this.createRightSubGrouping(right.subgrouping, right.grouping);

                    if (subGrouping.l != null || subGrouping.c != null
                        || subGrouping.e || subGrouping.d != null || subGrouping.o != null) {
                        subGroupings.push(
                            {
                                index: this.subGroupingCounter,
                                isCrudGroup: right.isCrudGroup,
                                items: subGrouping,
                                showHelp: false,
                                title: right.subgrouping
                            }
                        );

                        this.subGroupingCounter++;
                    }
                    subGroupings = subGroupings.concat(this.getOtherRights(right.subgrouping, right.grouping));
                } else {
                    const isInGrouping = this.isInGrouping(right.subgrouping, subGroups);

                    if ( isInGrouping != null) {
                        const subGrouping = this.createRightSubGrouping(right.subgrouping, right.grouping);

                        if (subGrouping.l != null || subGrouping.c != null
                            || subGrouping.e || subGrouping.d != null || subGrouping.o != null) {
                            subGroupings.push(
                                {
                                    index: this.subGroupingCounter,
                                    items: subGrouping,
                                    showHelp: false,
                                    title: isInGrouping
                                }
                            );

                            this.subGroupingCounter ++;
                        }

                        subGroupings = subGroupings.concat(this.getOtherRights(right.subgrouping, right.grouping));
                    }
                }
            }
        }

        return subGroupings;
    };

    private createRightSubGrouping = (subGroup: string, group: string) => {
        const subGroupingMembers: any[] = [];

        for (const right of this.allRights) {
            if (right.grouping === group && right.subgrouping === subGroup) {
                subGroupingMembers.push(right);
            }
        }

        const keyedSubGroupingMembers = {
            c: this.getRightBySuffix('CREATE', subGroupingMembers),
            d: this.getRightBySuffix('DELETE', subGroupingMembers),
            e: this.getRightBySuffix('EDIT', subGroupingMembers),
            l: this.getRightBySuffix('LIST', subGroupingMembers),
            o: null
        };

        return keyedSubGroupingMembers;
    };

    private getRightBySuffix = (suffix: string, items: any[]) => {
        for (const item of items) {
            const splits = item.value.split('_');

            if (splits[splits.length - 1] === suffix) {
                return item;
            }
        }

        return null;
    };

    private getOtherRights = (subGroup: string, group: string) => {
        const otherRights: any[] = [];

        for (const right of this.allRights) {
            if (right.grouping === group && right.subgrouping === subGroup) {
                const splits = right.value.split('_');
                const suffix: string = splits[splits.length - 1];
                if (suffix !== 'LIST'
                    && suffix !== 'CREATE'
                    && suffix !== 'EDIT'
                    && suffix !== 'DELETE') {
                        otherRights.push({
                            index: this.subGroupingCounter,
                            items: {
                                c: null,
                                d: null,
                                e: null,
                                l: null,
                                o: right
                            },
                            showHelp: false,
                            title: right.description
                        });
                        this.subGroupingCounter ++;
                    }
                }
            }
        return otherRights;
    };

    private isInGrouping(groupingName: string, groupings: any[]) {
        for (const item of groupings) {
            if (item.name === groupingName) {
                return item.title;
            }
        }

        return null;
    }

    private setMasterCheckboxRights = (grouping: any) => {
        for (const subGrouping of grouping.subGroupings) {
            for (const key of 'lcedo') {
                if (subGrouping.items[key] != null) {
                    grouping.masterCheckboxRights.push(subGrouping.items[key].value);
                    if (
                        [undefined, null].indexOf(this.editableRights) >= 0
                        || this.editableRights.lastIndexOf(subGrouping.items[key].value) >= 0
                    ) {
                        grouping.masterCheckboxEditableRights.push(subGrouping.items[key].value);
                    }
                }
            }
        }
    };
}

export class OrganEditPanelRightsComponent implements ng.IComponentOptions {
    public bindings = {
        allRights: '<',
        displayTemplateSelector: '<',
        editableRights: '<',
        groupingSettings: '<',
        inalienableRights: '<',
        initialStatus: '<',
        minNumberOfRights: '<',
        panelRightSettings: '<',
        rightsToEdit: '=',
        templates: '<',
        userData: '='
    };

    public require = {
        $editForm: '?^moleculeFormEdit'
    };

    public template = require('./rights.html');
    public controller = OrganEditPanelRightsController;
}
