import * as ng from 'angular';
import { AuthContextService } from '../../../../../services';
import { WizardHelperService } from '../../../../molecules/wizard/';
import { WizardVhostDomainObject } from './../../';

export class OrganCreateSummaryDomainController implements ng.IController {
    public static $inject: string[] = ['authContext', 'wizardHelper'];

    public contingent: any;
    public domainObject: WizardVhostDomainObject;
    public inContingent = false;
    public serviceObject;

    constructor(
        private authContext: AuthContextService,
        private wizardHelper: WizardHelperService
    ) {}

    public getContractPeriod = () => {
        return this.wizardHelper.getBillingCycleTranslation(
            this.domainObject.prices[0].price.contractPeriod
        );
    };

    public get cost() {
        if ([undefined, null].indexOf(this.domainObject) >= 0
            || [undefined, null].indexOf(this.domainObject.prices[0].price) >= 0
        ) {
            return false;
        }

        if (this.domainObject.prices[0].price.price === null) {
            this.inContingent = true;
            return;
        }

        if (this.domainObject.prices[0].price.price === -1) {
            this.inContingent = false;
            return false;
        }

        const account = this.authContext.account.id === '1'
            ? this.serviceObject.settings.account
            : this.authContext.account;
        this.inContingent = false;
        return this.wizardHelper.calculateProductPrice(this.domainObject.prices, account);
    }

    public set cost({}) {} // tslint:disable-line:no-empty

    public get currency() {
        if ([undefined, null].indexOf(this.domainObject) >= 0
            || [undefined, null].indexOf(this.domainObject.prices[0].price) >= 0
            || [null, -1].indexOf(this.domainObject.prices[0].price.price) >= 0
        ) {
            return;
        }

        return this.wizardHelper.getProductCurrency(this.domainObject.prices[0].price);
    }

    public set currency({}) {} // tslint:disable-line:no-empty
}

export class OrganCreateSummaryDomainComponent implements ng.IComponentOptions {
    public bindings = {
        contingent: '<',
        domainObject: '<',
        serviceObject: '<'
    };
    public template = require('./domain-summary.html');
    public controller =  OrganCreateSummaryDomainController;
}
