import ng from 'angular';

import { PanelHeaderData } from '@/atomic-components';
import { UiRights } from '@/configuration';
import { AuthContextService } from '@/services';

interface PanelHeaderDataGranted extends PanelHeaderData {
    isGrantedAny?: string[];
    isGrantedAll?: string[];
    isGranted?: string;
}

export class TemplateAccountDashboardController implements ng.IController {
    public static $inject: string[] = ['$translate', 'authContext'];

    public pageHeaderData = {
        panelIcon: 'briefcase',
        panelTitle: this.$translate.instant('TR_140119-b3b187_TR')
    };

    public leftPanelArea: PanelHeaderDataGranted[] = [
        {
            includeShowDetailsLink: true,
            isGrantedAny: [UiRights.ACC_ACCOUNT_BASICDATA_LIST],
            panelHeaderRoute: 'account.basicdata',
            panelIcon: 'id-card-alt',
            panelItems: [
                {
                    route: 'account.contactdata',
                    text: this.$translate.instant('TR_080119-5c34b6_TR')
                },
                {
                    route: 'account.contactemail',
                    text: this.$translate.instant('TR_080119-5c34b7_TR')
                }
            ],
            panelTitle: this.$translate.instant('STATE.ACCOUNT.BASICDATA.PAGE-TITLE')
        }, {
            includeShowDetailsLink: true,
            isGrantedAny: [UiRights.ACC_ACCOUNT_BASICDATA_LIST],
            panelHeaderRoute: 'account.settings',
            panelIcon: 'sliders-h',
            panelItems: [
                {
                    route: 'account.settings',
                    text: this.$translate.instant('TR_080119-5c3401_TR')
                }
            ],
            panelTitle: this.$translate.instant('TR_080119-5c34b8_TR')
        }, {
            includeShowDetailsLink: true,
            isGrantedAny: [UiRights.ACC_ACCOUNT_USER_LIST],
            panelHeaderRoute: 'account.users.overview',
            panelIcon: 'user',
            panelItems: [
                {
                    route: 'account.users.new',
                    text: this.$translate.instant('879a34f5-b968-4abe-a9fb-f602abfed914')
                }
            ],
            panelTitle: this.$translate.instant('TR_130319-0cb933_TR')
        },
        {
            includeShowDetailsLink: true,
            isGrantedAll: [
                UiRights.UI_MISC_IS_DIRECT_CUSTOMER,
                UiRights.BIL_LIST_DOCUMENTS
            ],
            panelHeaderRoute: 'account.dataprocessing',
            panelIcon: 'sync-alt',
            panelItems: [
                {
                    route: 'account.dataprocessing',
                    text: this.$translate.instant('TR_080119-5c34bc_TR')
                }
            ],
            panelTitle: this.$translate.instant('MENU.MAIN.DATA-PROCESSING')
        }
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        authContext: AuthContextService
    ) {
        if (authContext.canDeleteOwnAccount) {
            this.leftPanelArea[0].panelItems.push({
                route: 'account.basicdata',
                text: this.$translate.instant('TR_230119-940112_TR')
            });
        }
        this.leftPanelArea = this.leftPanelArea.filter(
            (panel) => {
                if ([undefined].indexOf(panel.isGrantedAny) < 0) {
                    if (!authContext.isGrantedAny(panel.isGrantedAny)) {
                        return false;
                    }
                }
                if ([undefined].indexOf(panel.isGrantedAll) < 0) {
                    if (!authContext.isGrantedAll(panel.isGrantedAll)) {
                        return false;
                    }
                }
                if ([undefined].indexOf(panel.isGranted) < 0) {
                    if (!authContext.isGranted(panel.isGranted)) {
                        return false;
                    }
                }
                return true;
            }
        );
    }
}

export class TemplateAccountDashboardComponent implements ng.IComponentOptions {
    public bindings = {};
    public template = require('./account-dashboard-template.html');
    public controller = TemplateAccountDashboardController;
}
