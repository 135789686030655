import { AuthContextService } from '../../../../../../services';

import * as ng from 'angular';

export class OrganEditPanelBundleBackupsController {
    public static $inject: string[] = ['$translate'];

    public $editFormOrganism: any;
    public panelRight: any;
    public disableAddButton: boolean;
    public service: string;

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {}

    public get manualBackupRightGranted() {
        switch (this.service) {
            case 'webhosting':
                return AuthContextService.isGranted('BACKUP_START_MANUAL_BACKUP_WEBHOSTING');
            case 'database':
                return AuthContextService.isGranted('BACKUP_START_MANUAL_BACKUP_DATABASE');
            default:
                return AuthContextService.isGranted('ADMIN_SYSTEM_SUPER_USER_EDIT');
        }
    }

    public get addButtonText() {
        if ([undefined, false].indexOf(this.$editFormOrganism.backups) >= 0) {
            return this.$translate.instant('TR_110119-33267b_TR');
        }

        if (this.$editFormOrganism.backups.length === 0) {
            return this.$translate.instant('TR_110119-33267b_TR');
        } else {
            return this.$translate.instant('TR_110119-792b45_TR');
        }
    }
}

export class OrganEditPanelBundleBackupsComponent implements ng.IComponentOptions {
    public bindings = {
        panelRight: '<',
        service: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormBundleBackups'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./bundle-backups.html');
    public controller = OrganEditPanelBundleBackupsController;
}
