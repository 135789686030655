import ng from 'angular';
import { LinkListOverviewItem } from '@/atomic-components/molecules/links/link-list-overview/link-list-overview';
import {
    AccountFilterField,
    SelectFilterField,
    TextFilterField
} from '@/atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { UiRights } from '@/configuration';
import { AuthContextService, OverviewModel, RequestCancellerService, VhostModelService } from '@/services';

export class LegacyPhpDomainsOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$state', 'vhostModel', 'authContext', 'objectStatusFilter', '$translate']
    );

    public readonly service = 'bundle';
    public readonly objectType = 'LegacyPHPVHost';
    public readonly idField = 'vhostId';
    public readonly defaultFilterFields = ['VHostDomainNameUnicode'];

    public activeMassAction: string | boolean = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};

    protected prefixFilters = [{ field: 'VHostDomainNameAce', prefix: 'xn--' }];
    protected regexFilters = [{ field: 'VHostDomainNameAce', regex: /^.+\.xn--.+$/ }];

    protected listCallbacks = [];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $state: ng.ui.IStateService,
        private vhostModel: VhostModelService,
        private authContext: AuthContextService,
        private objectStatusFilter,
        protected $translate: ng.translate.ITranslateService
    ) {
        super(RequestCanceller);

        // declare multi action availably methods
        this.actionLinksAvailabilities = {
            deletableDomains: this.deletableDomains,
            deletePermantlyDomains: this.deletePermantlyDomains,
            restorableDomains: this.restorableDomains
        };

        this.listCallbacks = [{
            callback: this.listVhosts
        }];
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('VhostDomainNameUnicode', this.$translate.instant('TR_140119-617fff_TR')),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR')),
                new SelectFilterField(
                    'VhostStatus',
                    this.$translate.instant('TR_140119-8e4b60_TR'),
                    [
                        {
                            name: this.objectStatusFilter('active'),
                            value: 'active'
                        },
                        {
                            name: this.objectStatusFilter('restorable'),
                            value: 'restorable'
                        }
                    ]
                )
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }

    public listVhosts = (limit?, page?, filters?, sort?, webspaceId?) => {
        const filter = { field: 'VHostUsesLegacyPhpVersion', value: '1' };

        if (filters) {
            filters = {
                subFilter: [filters, filter],
                subFilterConnective: 'AND'
            };
        } else {
            filters = filter;
        }

        return this.vhostModel.listWithoutPagination(limit, page, filters, sort, webspaceId);
    };

    public actionLinks = (): LinkListOverviewItem[] => {
        return [
            {
                isGranted: this.authContext.isGranted(UiRights.WEB_VHOST_EDIT),
                route: 'bundle.id.domains.minphpversion',
                routeParams: {
                    machineId: null,
                    bundleId: this.$state.params.bundleId
                },
                text: this.$translate.instant('TR_300123-fdf071_TR')
            }
        ] as LinkListOverviewItem[];
    };

    public callVhostOverviewList = (overviewData): PromiseLike<any> => {
        const limit = overviewData.pagination !== undefined && overviewData.pagination.limit !== undefined
            ? overviewData.pagination.limit
            : 10;
        const currentPage = overviewData.pagination !== undefined && overviewData.pagination.currentPage !== undefined
            ? overviewData.pagination.currentPage
            : 1;
        const simpleFilterValue = overviewData.filters !== undefined
            && overviewData.filters.simpleFilter !== undefined
            && overviewData.filters.simpleFilter.value !== undefined
                ? overviewData.filters.simpleFilter.value
                : '';
        const additionalFilters = overviewData.additionalFilters !== undefined
            ? overviewData.additionalFilters
            : [];
        const objectId = overviewData.objectId ? overviewData.object : undefined;
        const index = [undefined, null].indexOf(overviewData.index) === -1  ? overviewData.index : undefined;
        const sort = overviewData.sort ? overviewData : undefined;

        return this.list(
            limit,
            currentPage,
            simpleFilterValue,
            additionalFilters,
            objectId,
            index,
            sort
        );
    };
}
