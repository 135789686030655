import { LinkListOverviewItem } from '../../atomic-components/molecules/links/link-list-overview/link-list-overview';
import { TextFilterField } from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { UiRights } from '../../configuration';
import { OverviewModel } from '../abstract';
import { AuthContextService } from '../auth-context';
import { RequestCancellerService } from '../request-canceller';
import { DatabaseUserModelService } from './';

export class DatabaseUserOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', 'databaseUserModel', 'authContext']
    );

    public readonly service = 'database';
    public readonly objectType = 'DatabaseUser';
    public readonly idField = 'userId';
    public readonly defaultFilterFields = ['UserName'];

    public activeMassAction: string | boolean = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};

    protected listCallbacks = [{callback: this.databaseUserModel.list}];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private databaseUserModel: DatabaseUserModelService,
        private authContext: AuthContextService
    ) {
        super(RequestCanceller);

        // declare multi action availably methods
        this.actionLinksAvailabilities = {
            deletableUsers: this.deletableUsers
        };
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('userName', this.$translate.instant('TR_140119-680508_TR')),
                new TextFilterField('userDbName', this.$translate.instant('8ac333ba-a1ae-4340-9882-95c40c6ff0b7'))
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters({}) {} // tslint:disable-line:no-empty

    public actionLinks: () => LinkListOverviewItem[] = () => {
        return [
            {
                isGranted: this.authContext.isGranted(UiRights.DB_USER_DELETE),
                linkList: [{
                    group: 'delete',
                    groupName: this.$translate.instant('TR_140119-aee71e_TR'),
                    isClickable: 'deletableUsers',
                    isGranted: this.authContext.isGranted(UiRights.DB_USER_DELETE),
                    onclickMethode: () => this.activeMassAction = 'delete',
                    route: '',
                    text: this.$translate.instant('TR_140119-9c299f_TR')
                }],
                onlyOnExperView: true,
                route: '',
                text: this.$translate.instant('DOMAIN.BULK-EDIT.TITLE'),
                type: 'multi'
            },
            {
                route: 'database.users.new',
                routeParams: null,
                text: this.$translate.instant('TR_090119-f7a0fb_TR')
            }
        ] as LinkListOverviewItem[];
    };

    /**
     *  Submit methods
     */
    public deleteSubmit(users) {
        const deleteUsers = Object.keys(this.selected)
        .filter((id) => this.selected[id])
        .map((id) => this.getObjectFromId(users, id));

        return this.databaseUserModel.deleteUsers(deleteUsers);
    }

    /**
     *  Action availability definitions
     */
    public deletableUsers = (users) => {
        if (users.length === 0) {
            return false;
        }

        const selected = Object.keys(this.selected)
        .filter((id) => this.selected[id]);

        const deleteable = selected
        .filter((id) => ['active'].indexOf(this.getObjectFromId(users, id).status) >= 0);

        return deleteable.length > 0 && deleteable.length === selected.length;
    };

    private getObjectFromId = (objects, objectId) => {
        return objects.filter((object) => {
            return object.id === objectId;
        })[0];
    };
}
