import * as ng from 'angular';
import { BundleProductFamilies, UiRights } from '../../../../../configuration';
import { WizardConfirmationBase } from '../../general/confirm';
import {
    ApiErrorModel,
    AuthContextService,
    BillingRobotService,
    DatabaseRobotService,
    DnsJobModelService,
    DomainJobModelService,
    ErrorMapperService,
    OrchestratorRobotService,
    WebhostingRobotService
} from './../../../../../services/';

export class BundleWizardConfirmation extends WizardConfirmationBase {
    public static $inject: string[] = WizardConfirmationBase.$inject.concat([
        'billingRobot',
        'databaseRobot',
        'dnsJobModel',
        'domainJobModel',
        'webhostingRobot'
    ]);

    constructor(
        protected $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        protected apiErrorModel: ApiErrorModel,
        protected errorMapper: ErrorMapperService,
        protected localInterval: ng.IIntervalService,
        protected orchestratorRobot: OrchestratorRobotService,
        private billingRobot: BillingRobotService,
        private databaseRobot: DatabaseRobotService,
        private dnsJobModel: DnsJobModelService,
        private domainJobModel: DomainJobModelService,
        private webhostingRobot: WebhostingRobotService
    ) {
        super(
            $timeout,
            $translate,
            apiErrorModel,
            errorMapper,
            localInterval,
            orchestratorRobot
        );

        if (!AuthContextService.isGrantedAll(
            [
                UiRights.DB_JOBS_LIST,
                UiRights.DOM_JOBS_LIST,
                UiRights.DNS_ZONES_LIST,
                UiRights.WEB_JOBS_LIST
            ]
        )) {
            this.isJobDependendWizard = false;
            this.fillNextSteps = () => {
                this.nextSteps.push({
                    iconName: 'boxes-alt',
                    linkText: this.$translate.instant('TR_100119-aed0a3_TR'),
                    route: 'bundle.dashboard',
                    routeOptions: { reload: true },
                    routeParams: {}
                });
            };
        }
    }

    public getJobByFilter = (filter, cacheTimeout, objectType?: string) => {
        switch (objectType) {
            case 'Database':
                return this.databaseRobot.listJobs(filter)
                    .then(this.normalizeResponseObject);

            case 'Domain':
                return this.domainJobModel.list(null, null, filter)
                    .then(this.normalizeResponseObject);

            case 'Zone':
                return this.dnsJobModel.list(null, null, filter)
                    .then(this.normalizeResponseObject);

            case 'Bundle':
                return this.billingRobot.jobsFind(filter, null, null, null, true)
                    .then(this.normalizeResponseObject);

            case 'Webspace':
                return this.webhostingRobot.listJobs(filter)
                    .then(this.normalizeResponseObject);

            default:
                return undefined;
        }
    };

    public callbackAfterFinishedRequests = () => {
        if (this.selectedProductFamily === BundleProductFamilies.email) {
            for (const createdObject of this.createRequests) {
                if (createdObject.objectType === 'Bundle' && this.isJobFinished(createdObject)) {
                    this.nextSteps.push({
                        iconName: 'envelope',
                        linkText: this.$translate.instant('TR_110119-85a51b_TR'),
                        route: 'bundle.id.mailboxes.new',
                        routeOptions: { reload: true },
                        routeParams: { bundleId: createdObject.result.id }
                    });
                }
            }
        }
    };
}
