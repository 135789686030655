import ng from 'angular';
import { Breadcrumb } from '../breadcrumb';
import { BreadcrumbTopicModel, TopicCases } from './topic-model';

export class BreadcrumbHelpdeskTopicModel extends BreadcrumbTopicModel {
    public static $inject: string[] = BreadcrumbTopicModel.$inject.concat(['$stateParams']);
    public readonly topicName = 'helpdesk';
    public readonly specialTopicCases: TopicCases;

    /**
     *   List of route parts, which are excluded from mail class generalRoutes methode
     */
    public readonly excludeGeneralRouteParts = ['overview'];

    private state: ng.ui.IStateService;

    constructor(
        protected $state: ng.ui.IStateService,
        protected $translate: ng.translate.ITranslateService,
        protected $stateParams: ng.ui.IStateParamsService
    ) {
        super($state, $translate);
        this.state = $state;
        this.specialTopicCases = {};
    }

    public getFirstLevelData = (): Breadcrumb[] => {
        this.breadcrumbs.push({
            active: false,
            route: 'helpdesk',
            routeParams: { level1: null, level2: null, level3: null, level4: null, level5: null },
            title: this.$translate.instant('TR_080119-ef6771_TR'),
            type: 'text',
            value: this.$translate.instant('MENU.MAIN.HELPDESK')
        });

        this.getBreadcrumb();

        return this.breadcrumbs;
    };

    public getBreadcrumb = (): Breadcrumb[] => {
        const urlParts = [];
        const templateName = this.state.$current.locals.globals.getTemplateName;
        for (let i = 1; i <= 5; i++) {
            if ([null, undefined].indexOf(this.$stateParams[`level${i}`]) < 0) {
                urlParts.push(this.$stateParams[`level${i}`]);
            }
        }
        if (urlParts.length === 0) {
            this.breadcrumbs.push({
                active: true,
                route: 'helpdesk',
                routeParams: {},
                title:
                    this.$translate.instant('MENU.MAIN.HELPDESK')
                        + ' '
                        + this.$translate.instant('b1b21080-012f-41a9-b11e-7dd7237de1df'),
                type: 'text',
                value: this.$translate.instant('b1b21080-012f-41a9-b11e-7dd7237de1df')
            });
        } else {
            const getBreadcrumb = this.state.$current.locals.globals.getBreadcrumb;
            this.breadcrumbs = this.breadcrumbs.concat(getBreadcrumb);
            if (templateName === 'article') {
                this.breadcrumbs.push({
                    active: true,
                    route: 'helpdesk',
                    routeParams: {},
                    title: this.$translate.instant('TR_090719-97c0eb_TR'),
                    type: 'text',
                    value: this.$translate.instant('TR_090719-97c0eb_TR')
                });
            } else {
                this.breadcrumbs.push({
                    active: true,
                    route: 'helpdesk',
                    routeParams: {},
                    title: this.$translate.instant('MENU.CONTEXT.OVERVIEW'),
                    type: 'text',
                    value: this.$translate.instant('MENU.CONTEXT.OVERVIEW')
                });
            }
        }

        return this.breadcrumbs;
    };
}
