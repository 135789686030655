import * as ng from 'angular';
import { AlertManagerService, DnsTemplateModelService, NavigationService } from '../../../../../services';
import { PanelType } from '../../../../molecules/forms/form-edit/form-edit';

export class OrganismEditFormDnsTemplateController {
    public static $inject: string[] = ['$q', '$translate', 'alertManager', 'dnsTemplateModel', 'navigation'];

    public template: any;
    public records: any[];
    public dnsSecOptions: any;
    public dnsSecActive: boolean;
    public deletionConfirmed = false;

    public recordsMarkedForDeletion = [];
    public addedRecords = [];
    public changedRecords = [];
    public replacements: any;

    public miscPanel: PanelType = PanelType.MISCELLANEOUS_ACTION;

    constructor(
        private $q: ng.IQService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private dnsTemplateModel: DnsTemplateModelService,
        private navigation: NavigationService
    ) {
        this.replacements = {
            ipv4Replacement: undefined,
            ipv6Replacement: undefined,
            mailIpv4Replacement: undefined,
            mailIpv6Replacement: undefined
        };
    }

    public saveTemplate = () => {
        const records = this.records
            .map(this._normalizeRecord)
            .filter((record) => this.recordsMarkedForDeletion.indexOf(record.id) < 0);

        const replacements = ng.copy(this.replacements);
        Object.keys(replacements).forEach((key) => {
            if (replacements[key] === '') {
                delete replacements[key];
            }
        });

        return this.dnsTemplateModel.update(this.template, records, replacements)
        .then(
            (res) => {
                if (['pending', 'success'].indexOf(res.status) >= 0) {
                    this.alertManager.success(this.$translate.instant('TR_050319-cefb59_TR'));
                    return res;
                }

                return this.$q.reject(false);
            }
        );
    };

    public renameTemplate = () => {
        return this.dnsTemplateModel.rename(this.template)
        .then(
            (res) => {
                if (['pending', 'success'].indexOf(res.status) >= 0) {
                    this.alertManager.success(this.$translate.instant('TR_050319-cefb59_TR'));
                    return res;
                }

                return this.$q.reject(false);
            }
        );
    };

    public deleteTemplate = () => {
        this.dnsTemplateModel.delete([this.template]).then(
            () => {
                this.alertManager.success(this.$translate.instant('TR_100119-39b934_TR'));
                this.navigation.go('dns.templates.overview');
            }
        );
    };

    private _normalizeRecord = (record) => {
        if (record.name.lastIndexOf('.') === record.name.length - 1) {
            record.name = record.name.substring(0, record.name.length - 1);
        }

        record.ttl = +record.ttl;

        if (['mx', 'srv'].indexOf(record.type.toLowerCase()) === -1) {
            delete record.priority;
        } else {
            record.priority = +record.priority;
        }

        return record;
    };
}

export class OrganismEditFormDnsTemplateComponent implements ng.IComponentOptions {
    public bindings = {
        records: '<',
        template: '<'
    };

    public controller = OrganismEditFormDnsTemplateController;
    public template = require('./dns-template-edit.html');
}
