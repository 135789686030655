import * as ng from 'angular';
import * as q from 'q';
import { WizardHelperService } from '../';
import { UiRights } from '../../../../configuration';
import { ApiValue } from '../../../../filters';
import {
    AuthContextService,
    DepositModelService
} from '../../../../services';
import { MoleculeWizardSupervisorController } from '../wizard-supervisor/wizard-supervisor';

import './wizard-summary.scss';

export class MoleculeWizardSummaryController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        'depositModel',
        'wizardHelper'
    ];
    public serviceObjects: any[];
    public orderButtonClicked = false;
    public $wizardSupervisor: MoleculeWizardSupervisorController;
    public displaySummaryPage: boolean;

    private _orderForSubAccountWithoutShowPriceRight = false;
    private depositInfo: any;
    private cachedDisableOrderButtonResult;
    private cachedShowRechargeButtonResult;
    private cachedTotalCost: null | number = null;
    private inPool = [];
    private _chargeable: null | boolean = null;
    private _isConfirmationLocked = false;

    constructor(
        private $timeout: ng.ITimeoutService,
        private depositModel: DepositModelService,
        private wizardHelper: WizardHelperService
    ) {}

    public $onInit() {
        this.getShowChargeableStatus();
        this.getDepositInfo();
        this.cachedTotalCost = undefined;
        this.cachedDisableOrderButtonResult = undefined;
        this.cachedShowRechargeButtonResult = undefined;

        q.all(
            this.serviceObjects.map(
                (serviceObject) => this.wizardHelper.inPool(serviceObject)
                .then(
                    (res) => [undefined, null].indexOf(res) < 0 && res.length > 0
                )
            )
        )
        .then((results) => this.inPool = results);
    }

    public $onDestroy() {
        this.cachedTotalCost = undefined;
        this.cachedDisableOrderButtonResult = undefined;
        this.cachedShowRechargeButtonResult = undefined;
    }

    public checkServiceOfBacklink = (object: any) => {
        // This will only be used until all wizards are adjusted.
        // ['domain', 'email', 'ssl'].indexOf(object.service) >= 0;
        return false;
    };

    public openWizard = (object: any) => {
        object.active = true;
        object.completed = false;
    };

    public confirmOrder = () => {
        if (this.orderButtonClicked) {
            return;
        }

        this._isConfirmationLocked = true;
        this.$timeout(() => { this._isConfirmationLocked = false; }, 500);

        this.orderButtonClicked = true;
        this.$wizardSupervisor.orderObjects()
        .finally(() => this.orderButtonClicked = false);
    };

    public get isPrepaid() {
        if (AuthContextService.isRootOrCompanyAccount || this._orderForSubaccount()) {
            return false;
        }

        if (!AuthContextService.isGranted(UiRights.BIL_GET_DEPOSIT)) {
            return false;
        }

        if ([undefined, null].indexOf(this.depositInfo) >= 0) {
            return false;
        }

        return this.depositInfo.paymentType === 'prepaid';
    }

    public get balance() {
        if (this.depositInfo === null || this.depositInfo === undefined) {
            return null;
        }
        return ApiValue.parseApiValue(this.depositInfo.balance
            + this.depositInfo.voucherBalance - this.depositInfo.reserved) - this.totalCost();
    }

    public get disableOrderButton() {
        if (this.orderButtonClicked || this._isConfirmationLocked) {
            return true;
        }

        if (this._orderForSubaccount()) {
            return false;
        }

        if ([undefined, null].indexOf(this.cachedDisableOrderButtonResult) < 0) {
            return this.cachedDisableOrderButtonResult;
        }

        if (
            AuthContextService.account.billingSettings.paymentType === 'external'
            || !AuthContextService.isGranted(UiRights.BIL_GET_DEPOSIT)
        ) {
            this.cachedDisableOrderButtonResult = false;
            return false;
        }

        if (this.depositInfo === null || this.depositInfo === undefined) {
            return true;
        }

        const result = ApiValue.parseApiValue(
            this.depositInfo.balance
            + this.depositInfo.voucherBalance
            + this.depositInfo.creditLimit
            - this.depositInfo.reserved
        ) - this.totalCost() < 0;

        this.cachedDisableOrderButtonResult = result;

        return result;
    }

    public get showRechargeButton() {
        if (this._orderForSubaccount()) {
            return false;
        }

        if ([undefined, null].indexOf(this.cachedShowRechargeButtonResult) < 0) {
            return this.cachedShowRechargeButtonResult;
        }

        if (AuthContextService.account.billingSettings.paymentType === 'external'
            || !AuthContextService.isGranted(UiRights.BIL_GET_DEPOSIT)) {
            this.cachedShowRechargeButtonResult = false;
            return false;
        }

        if (this.depositInfo === null || this.depositInfo === undefined) {
            this.cachedShowRechargeButtonResult = false;
            return false;
        }

        const result = ApiValue.parseApiValue(
            this.depositInfo.balance
            + this.depositInfo.voucherBalance
            + this.depositInfo.creditLimit
            - this.depositInfo.reserved
        ) - this.totalCost() < 0;

        this.cachedShowRechargeButtonResult = result;

        return result;
    }

    public getShowChargeableStatus() {

        if (this.totalCost() > 0) {
            this._chargeable = true;
            return;
        }

        this._chargeable = false;
    }

    public get chargeableButton()  {
        const isInternalForwader = this.serviceObjects.every(
            (object, index) => this.isInternalForwader(index)
        );

        if (isInternalForwader) {
            return false;
        }

        // Scheinbar wird die komplette Komponente zweckentfremdet für redirects.
        // Diese Zeile fixt, das es wieder einen Button zum Bestellen gibt, der Rest
        // ist ohne Gewährleistung. Top.
        if (this.serviceObjects && this.serviceObjects[0]?.children[0]?.price) {
            return true;
        }

        return this._chargeable !== null
            ? this._chargeable
            : false;
    }

    public get freeOfChargeButton() {
        if (this._orderForSubAccountWithoutShowPriceRight) {
            return false;
        }

        const isInternalForwader = this.serviceObjects.every(
            (object, index) => this.isInternalForwader(index)
        );

        if (isInternalForwader) {
            return true;
        }

        return this._chargeable !== null
            ? !this._chargeable
            : false;
    }

    public showDomainPrice = (serviceObject: any) => {
        return serviceObject.display.domainPrice !== undefined;
    };

    public switchToConfiguration = (object: any) => {
        object.settings.comesFromSummaryPage = true;
        object.active = true;
        object.completed = false;
    };

    public get subaccountWithoutShowPriceRight() {
        if (this._orderForSubaccount()
            || !this.serviceObjects[0].settings.account.rights.some(
                (right: string) => right === UiRights.BIL_LIST_ARTICLE_PRICES
            )
        ) {
                this._orderForSubAccountWithoutShowPriceRight = true;
                this._chargeable = false;
                return true;
        }

        this._orderForSubAccountWithoutShowPriceRight = false;
        return false;
    }
    public isInternalForwader = (index: number) => {
        return this.serviceObjects[index] !== undefined
            && this.serviceObjects[index].display !== undefined
            && [undefined, false].indexOf(this.serviceObjects[index].display.internalForwarder) < 0;
    };

    private getDepositInfo = () => {
        if (AuthContextService.isGranted(UiRights.BIL_GET_DEPOSIT)) {
            this.depositModel.getDeposit().then(
                (reply) => this.depositInfo = reply
            );
        }
    };

    private totalCost() {
        if ([undefined, null].indexOf(this.cachedTotalCost) < 0) {
            return this.cachedTotalCost;
        }
        let cost = 0;
        this.serviceObjects.forEach(
            (product: any) => {
                const currentcyPriceObject = this.wizardHelper.getTotalPrice(product, true);
                currentcyPriceObject.map((obj: any) => {
                    cost += obj.totalPrice;
                });
            }
        );

        this.cachedTotalCost = cost;
        return this.cachedTotalCost;
    }

    private _orderForSubaccount = () => {
        return AuthContextService.account.id !== this.serviceObjects[0].settings.accountId;
    };
}

export class MoleculeWizardSummaryComponent implements ng.IComponentOptions {
    public bindings = {
        displaySummaryPage: '=',
        serviceObjects: '='
    };
    public require = {
        $wizardSupervisor: '^moleculeWizardSupervisor'
    };
    public controller =  MoleculeWizardSummaryController;
    public template = require('./wizard-summary.html');
}
