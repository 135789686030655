import * as ng from 'angular';

export class MoleculeButtonSearchController implements ng.IController {
    public static $inject: string[] = [];

    public disabled: boolean;
    public searchParams: any;
    public search: (param?: any) => void;

    public $onInit() {
        this.disabled = this.disabled || false;
    }

    public fireSearch() {
        if (!this.disabled) {
            this.search(this.searchParams);
        }
    }
}

export class MoleculeButtonSearchComponent implements ng.IComponentOptions {
    public bindings = {
        disabled: '<',
        search: '<',
        searchParams: '<?'
    };
    public transclude = true;
    public controller =  MoleculeButtonSearchController;
    public template = require('./button-search.html');
}
