import * as ng from 'angular';

import { UiRights } from '../../../../configuration';
import { ApiValue } from '../../../../filters/api-value';
import { AuthContextService, DepositModelService } from '../../../../services';

export class MoleculePanelBundleEmptyDashboardController implements ng.IController {
    public static $inject: string[] = ['$translate', 'depositModel'];

    public linkListFooter = [
        {
            isGranted: AuthContextService.isGranted(UiRights.BIL_BUNDLE_CREATE),
            route: 'bundle.new',
            text: this.$translate.instant('TR_100119-1478eb_TR')
        },
        {
            isGranted: AuthContextService.isGrantedAny([
                UiRights.DOM_DOMAINS_CREATE_WITH_CONTACTS,
                UiRights.DOM_DOMAINS_CREATE_WITH_DEFAULTS,
                UiRights.DOM_DOMAINS_CREATE_WITH_OWN_NS]
            ),
            route: 'domain.domains.new',
            text: this.$translate.instant('9e7c3a49-73df-4f43-988f-02fe8bf56a67')
        }
    ];
    public headerData = {
        icon: '',
        title: ''
    };
    public searchValue = '';
    private _depositInfo: number;

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private depositModel: DepositModelService
     ) {}

    public $onInit() {
        this.searchValue = this.searchValue || '';
        this.headerData.icon = this.searchValue.length === 0
            ? 'birthday-cake'
            : 'info';
        this.headerData.title = this.searchValue.length === 0
            ? this.$translate.instant('TR_100119-76ca4a_TR')
            : this.$translate.instant('TR_190719-e34771_TR');
    }

    public get hasBalance() {
        return this.depositModel.sufficientCreditForOrder();
    }
}

export class MoleculePanelBundleEmptyDashboardComponent implements ng.IComponentOptions {
    public bindings = {
        searchValue: '<?'
    };
    public controller =  MoleculePanelBundleEmptyDashboardController;
    public template = require('./panel-bundle-empty-dashboard.html'); // tslint:disable-line
}
