import { translate } from 'angular';
import { Validator } from './abstract-validator';

/**
    RFCs:
    https://tools.ietf.org/html/rfc2538
    https://tools.ietf.org/html/rfc4398

    Validierung:
    * `name` muss in der Zone liegen (BE)
    * `content` besteht aus vier oder mehr Teilen, getrennt durch ' ' (Leerzeichen)
        ** Erster Teil ist der Typ des Zertifikats und entweder eine Zahl oder die Entsprechende Textdarstellung.
        Siehe https://www.iana.org/assignments/cert-rr-types/cert-rr-types.xhtml#cert-rr-types-2
        ** Zweiter Teil ist der "key tag", eine Prüfsumme über den öffentlichen Schlüssel im Zertifikat, in [0, 65535]
        ** Dritter Teil ist der verwendete Signaturalgorithmus und entweder eine Zahl oder die entsprechende
            Textdarstellung (_mnemonic_).
        Siehe https://www.iana.org/assignments/dns-sec-alg-numbers/dns-sec-alg-numbers.xhtml#dns-sec-alg-numbers-1
        ** Die restlichen Teile sind das Zertifikat in Base64-Kodierung, getrennt durch beliebig viele Leerzeichen.
    * Beispiel für validen `content`: `PKIX 1 RSASHA256 KR1L0GbocaIOOim1+qdHtOSrDcOsGiI2NCcxuX2/Tqc=`
*/

export class ValidateCert extends Validator {
    constructor(private $translate: translate.ITranslateService) {
        super();
    }

    public validate = (value: string) => {
        const parts = value.split(' ');

        if (parts.length < 4 || parts.some((val) => val.length === 0)) {
            return [
                {
                    text: this.$translate.instant('TR_131020-e1c297_TR')
                }
            ];
        }

        const checksum = parseInt(parts[1], 10);
        if (isNaN(checksum) || checksum < 0 || checksum > 65535) {
            return [
                {
                    text: this.$translate.instant('TR_131020-0bfd49_TR')
                }
            ];
        }

        try {
            window.atob(parts[3]);
        } catch (e) {
            return [
                {
                    text: this.$translate.instant('TR_231020-f89eee_TR')
                }
            ];
        }

        return [];
    };
}
