import * as ng from 'angular';
import * as q from 'q';
import { UiRights } from '../../../../../configuration';
import {
    AlertManagerService,
    AuthContextService,
    NameserverSetModelService
} from '../../../../../services';
import * as Types from '../../../../../types';

export class OrganismEditFormNameserverSetController {
    public static $inject: string[] = ['$translate', 'alertManager', 'nameserverSetModel'];

    public nameserverSet: Types.DnsApi.NameserverSet;
    public originalNameserverSet: any;
    public panelRightSettings: any;
    public nameserverItems: any[];
    public selectedNameserverSetType: string;

    public typeOptions = [
        { name: this.$translate.instant('TR_070519-7748e6_TR'), value: 'system' },
        { name: this.$translate.instant('TR_070519-e3aaa1_TR'), value: 'external' }
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private nameserverSetModel: NameserverSetModelService
    ) {}

    public $onInit() {
        this.originalNameserverSet = ng.copy(this.nameserverSet);
        this._setSelectedNameServerSetType();

        if (AuthContextService.isGranted(UiRights.DNS_NSSETS_EDIT)) {
            this.panelRightSettings = {editPanelButton: true};
        } else {
            this.panelRightSettings = {editPanelButton: false};
        }
        this.createNameserverItems();
    }

    public save = () => {
        this.nameserverSet.nameservers = this.nameserverItems.map(
            (nameserver) => {
                return nameserver.name;
            }
        );

        return this.nameserverSetModel.update(this.nameserverSet)
        .then(
            (res) => {
                if (res.status !== 'error') {
                    this._setSelectedNameServerSetType();
                    this.alertManager.success(this.$translate.instant('TR_100119-56f958_TR'));
                    return res;
                }

                return q.reject(false);
            }
        );
    };

    public cancel = () => {
        this.nameserverSet = ng.copy(this.originalNameserverSet);
        this.createNameserverItems();
    };

    private createNameserverItems = () => {
        this.nameserverItems = [];
        for (const nameserver of this.nameserverSet.nameservers) {
            this.nameserverItems.push({
                ips: [],
                name: nameserver
            });
        }
    };

    private _setSelectedNameServerSetType = () => {
        this.typeOptions.some((typeOption) => {
            if (typeOption.value === this.nameserverSet.type) {
                this.selectedNameserverSetType = typeOption.name;
                return true;
            }

            return false;
        });
    };
}

export class OrganismEditFormNameserverSetComponent implements ng.IComponentOptions {
    public bindings = {
        nameserverSet: '<'
    };

    public controller = OrganismEditFormNameserverSetController;
    public template = require('./nameserver-set-edit.html');
}
