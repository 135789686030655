import * as ng from 'angular';
import { ProductHelperService } from '../../../../../services/helpers/product-helper';

export class OrganismWizardVerifiedHandlerController implements ng.IController {
    public productCodeList: string[] = ['productHelper'];
    public verification: string;
    public verified: boolean;

    constructor(
        private productHelper: ProductHelperService
    ) {}

    public $onChanges(changes: ng.IOnChangesObject) {
        if (changes.productCodeList !== undefined
            && !ng.equals(
                changes.productCodeList.currentValue,
                changes.productCodeList.previousValue
            )
        ) {
            if (changes.productCodeList.currentValue.length === 0) {
                this._resetVerificationSettings();
                return;
            }
            this._checkProductVerification(changes.productCodeList.currentValue);
        }
    }

    private _checkProductVerification = (productCodeList?: string[]) => {
        productCodeList = productCodeList || this.productCodeList;
        if (productCodeList.length === 0 || productCodeList.includes(undefined)) {
            return;
        }
        productCodeList.some((productCode: string) => {
            return this.productHelper.getProductFromProductCode(productCode)
            .then(
                (product) => {
                    return this._checkVerificationAlternatives(product.verificationAlternatives);
                }
            );
        });
    };

    private _checkVerificationAlternatives = (verAlts) => {
        if ([undefined, null].indexOf(verAlts) < 0
            && Array.isArray(verAlts)
            && verAlts.length > 0
            && verAlts[0].requiredVerifications.length > 0
        ) {
            this.verified = false;
            this.verification = verAlts[0].requiredVerifications[0];
            return true;
        } else {
            this._resetVerificationSettings();
            return false;
        }
    };

    private _resetVerificationSettings = () => {
        this.verified = true;
        this.verification = '';
    };
}

export class OrganismWizardVerifiedHandlerComponent implements ng.IComponentOptions {
    public bindings = {
        productCodeList: '<',
        verified: '='
    };
    public template = require('./wizard-verified-handler.html');
    public controller =  OrganismWizardVerifiedHandlerController;
}
