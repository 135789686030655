import * as ng from 'angular';
import {
    AlertManagerService,
    AuthContextService,
    BillingRobotService,
    DateWrapper,
    GetEntryPointService
} from '../../../../../services';

export class OrganViewPanelDataProcessingBottomController {
    public static $inject: string[] = ['$translate', 'alertManager', 'authContext', 'billingRobot', 'getEntryPoint'];

    public dateOfBirth: DateWrapper;
    public dateOfBirthHasSet = false;
    public allowSigning: boolean;

    public advMail: string;
    public showSupport: boolean;

    private minDateOfBirth: Date;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private authContext: AuthContextService,
        private billingRobot: BillingRobotService,
        private getEntryPoint: GetEntryPointService
    ) {}

    public $onInit() {
        this.advMail = 'datenschutz@' + this.getEntryPoint.domain;
        this.showSupport = this.getEntryPoint.showSupportOptions;
        this.minDateOfBirth = new Date();
        this.minDateOfBirth.setUTCFullYear(this.minDateOfBirth.getUTCFullYear() - 16);
        this.dateOfBirth = new DateWrapper(this.minDateOfBirth);
        if ([undefined].indexOf(this.allowSigning) >= 0) {
            this.allowSigning = true;
        }
    }

    public get alreadySigned() {
        return this.authContext.account.billingSettings.dataProcessingAgreementSignedCurrentVersion;
    }

    public download = () => this.billingRobot.downloadAdv();

    public confirm = () => {
        if (this.disableConfirmButton) {
            return;
        }

        if (this.requireBirthday) {
            this.billingRobot.createAdv(this.dateOfBirth.dateString)
                .then(this._handleResponseSuccess, this._handleResponseError);
        } else {
            this.billingRobot.createAdv().then(this._handleResponseSuccess, this._handleResponseError);
        }
    };

    public get requireBirthday() {
        return (this.authContext.user.dateOfBirth === undefined || this.authContext.user.dateOfBirth === null);
    }

    public get olderThanSixteen() {
        return this.dateOfBirth.dateObj.getTime() < this.minDateOfBirth.getTime();
    }

    public get disableConfirmButton() {
        return (this.requireBirthday && !this.olderThanSixteen);
    }

    public callbackOnCompleteDate = () => {
        this.dateOfBirthHasSet = true;
    };

    private _handleResponseSuccess = (res) => {
        this.alertManager.success(this.$translate.instant('TR_110119-3f7212_TR'));
    };

    private _handleResponseError = (err) => {
        this.alertManager.error(err);
    };
}

export class OrganViewPanelDataProcessingBottomComponent implements ng.IComponentOptions {
    public bindings = {
        allowSigning: '<'
    };
    public controller = OrganViewPanelDataProcessingBottomController;
    public template = require('./data-processing-bottom.html');
}
