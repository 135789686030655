import { ValidateNotEmpty } from '../../../../../services';

import * as ng from 'angular';

export class MoleculePanelEditRowDomainDirectoryProtectionAddPathController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public newProtections: any[];
    public newProtectionKey: number;
    public userList: any[];
    public httpUserList: any[];
    public selectedDropdownUserIndex: number = null;

    // Validate path.
    public pathValidationErrorList: any[];
    public pathValidationInstructions: any[] = [{
        settings: null,
        validator: new ValidateNotEmpty(this.$translate)
    }];

    public constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this._setUserDropdown();
        this.pathValidationErrorList = [];
    }

    // Create a new index for the specified location.
    public findNewProtectionIndex() {
        let foundIndex = -1;
        this.newProtections.forEach((newProt, idx) => {
            if (newProt.key === this.newProtectionKey) {
                foundIndex = idx;
            }
        });
        return foundIndex;
    }

    public delete = () => {
        this.newProtections.splice(this.findNewProtectionIndex(), 1);
    };

    private _setUserDropdown = () => {
        this.selectedDropdownUserIndex = null;
        const protectionIndex = this.findNewProtectionIndex();
        if (protectionIndex >= 0) {
            this.httpUserList = this.userList.map(
                (httpUser, index) => {
                    return httpUser;
                }
            );
        }
    };
}

export class MoleculePanelEditRowDomainDirectoryProtectionAddPathComponent implements ng.IComponentOptions {
    public bindings = {
        newProtectionKey: '<',
        newProtections: '=',
        userList: '<'
    };

    public controller = MoleculePanelEditRowDomainDirectoryProtectionAddPathController;
    public template = require('./panel-edit-row-domain-directory-protection-add-path.html');
}
