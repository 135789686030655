import * as ng from 'angular';

import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateDomainContactsOverviewController {
    public static $inject: string[] = ['$translate'];

    public expertView: boolean;
    public overviewServiceData: any;
    public pageHeaderData: PanelHeaderData = {
        backwardLink: 'domain.dashboard',
        backwardText: this.$translate.instant('TR_110119-774118_TR'),
        panelHeaderRoute: '',
        panelIcon: 'globe',
        panelTitle: this.$translate.instant('DOMAIN.GENERAL.HANDLES')
    };
    public faqArticleIdList: string[] = [
        'domain-confirm-email',
        'domain-handle-change'
    ];

    public overviewModel;
    private simpleFilter;
    private extendedFilters;
    private overviewUiSettings;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit() {
        if ([undefined, null].indexOf(this.simpleFilter) === -1) {
            this.expertView = this.overviewUiSettings.expertView || this.simpleFilter.show;
        } else {
            this.expertView = this.overviewUiSettings.expertView || false;
        }
        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter
        };
    }

    public expandWrapper = (toggle) => {
        this.expertView = toggle.expand;
    };
}

export class TemplateDomainContactsOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public controller = TemplateDomainContactsOverviewController;
    public template = require('./domain-contacts-overview-template.html');
}
