import * as ng from 'angular';
import { DomainRobotService } from './robot';

export class DomainStatisticsService {
    public static $inject: string[] = ['domainRobot'];

    constructor(
        private domainRobot: DomainRobotService
    ) {}

    public portfolio(withSubAccounts, owner?) {
        return this.domainRobot.statisticDomainPortfolio(withSubAccounts, owner)
            .then(this.getResponses);
    }

    public count(withSubAccounts, owner?) {
        return this.domainRobot.statisticDomainCount(withSubAccounts, owner)
            .then(this.getResponses);
    }

    public jobs(withSubAccounts, start, end, owner?) {
        return this.domainRobot.statisticJobs(withSubAccounts, start, end, owner)
            .then(this.getResponses);
    }

    public turnover(widthSubAccounts, start, end, owner?) {
        return this.domainRobot.statisticDomainTurnover(start, end, widthSubAccounts, owner)
            .then(this.getResponses);
    }

    public subAccountTurnover(start, end, minInflow, minOutflow, owner?) {
        return this.domainRobot.statisticSubAccountDomainTurnover(start, end, minInflow, minOutflow, owner)
            .then(this.getResponses);
    }

    private getResponses(data) {
        return data.responses;
    }
}
