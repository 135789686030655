import * as ng from 'angular';
import { RightsTemplateOverviewModelService } from '../../../services';

export class TemplateRightsTemplatesController implements ng.IController {
    public static $inject: string[] = ['$translate', 'rightsTemplateOverviewModel'];

    public overviewServiceData: any;
    public expertView = false;

    public pageHeaderData = {
        backwardLink: 'reseller.dashboard',
        backwardText: this.$translate.instant('TR_090119-5b9243_TR'),
        panelHeaderRoute: 'reseller.rightstemplates.overview',
        panelIcon: 'lock',
        panelTitle: this.$translate.instant('STATE.ACCOUNT.RIGHTS-TEMPLATES.PAGE-TITLE')
    };

    private extendedFilters: any = null;
    private overviewUiSettings: any;

    constructor(
        private $translate: ng.translate.ITranslateService,

        public rightsTemplateOverviewModel: RightsTemplateOverviewModelService
    ) {}

    public $onInit() {
        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: { value: '' }
        };
    }
}

export class TemplateRightsTemplatesComponent implements ng.IComponentOptions {
    public bindings = {
        overviewUiSettings: '<'
    };
    public template = require('./rights-template-overview.html');
    public controller =  TemplateRightsTemplatesController;
}
