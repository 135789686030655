import { RightModelService } from '../../../../../services';
import { EditPanelStatus } from '../../../../molecules/forms';

import * as ng from 'angular';

export class OrganNewSubAccountUserRightsController {
    public static $inject: string[] = ['$state', 'rightModel'];

    public newUser: { rights: any[] };
    public rightsTemplates: any[];
    public rightsTemplatesDropdownItems: any[];
    public parentAccount: string;
    public editableRights: string[];
    public allRights: any[];
    public rightsFromTemplate: string[];
    public initialStatus = EditPanelStatus.ACTIVE;

    constructor(
        private $state: ng.ui.IStateService,
        private rightModel: RightModelService
    ) {}

    public $onChanges(changes) {
        if (changes.parentAccount !== undefined) {
            this.updateEditableRights(changes.parentAccount.currentValue);
        }
    }

    public templateSelected = (templateId: string) => {
        if (templateId !== '') {
            for (const template of this.rightsTemplates) {
                if (template.id === templateId) {
                    this.newUser.rights = template.rights;
                    this.rightsFromTemplate = template.rights;
                    return;
                }
            }
        } else {
            this.rightsFromTemplate = [];
        }
    };

    private updateEditableRights = (account: string) => {
        if (account === undefined) {
            return;
        }

        if (this.$state.current.name.indexOf('users.new') < 0) {
            // Probably subaccount create
            this.rightModel.getAvailableRightsSubaccount(account).then(
                (reply) => {
                    this.editableRights = reply;
                    const newList = [];
                    for (const right of this.newUser.rights) {
                        if (this.editableRights.indexOf(right) >= 0) {
                            newList.push(right);
                        }
                    }
                    this.newUser.rights = newList;
                }
            );
        } else {
            // Probably some user create state
            this.rightModel.getAvailableRightsUser(account).then(
                (reply) => {
                    this.editableRights = reply;
                    const newList = [];
                    for (const right of this.newUser.rights) {
                        if (this.editableRights.indexOf(right) >= 0) {
                            newList.push(right);
                        }
                    }
                    this.newUser.rights = newList;
                }
            );
        }
    };
}

export class OrganNewSubAccountUserRightsComponent implements ng.IComponentOptions {
    public bindings = {
        allRights: '<',
        newUser: '=',
        parentAccount: '<',
        rightsTemplates: '<',
        rightsTemplatesDropdownItems: '<'
    };

    public template = require('./sub-account-user-rights.html');
    public controller = OrganNewSubAccountUserRightsController;
}
