import * as ng from 'angular';
import './backups-confirm-restoration.scss';

export class MoleculePanelEditRowTableBackupsConfirmRestorationController implements ng.IController {
    public static $inject: string[] = [];
    public backup: any;
    public opened: boolean;
    public confirmed = false;
    private $backupPanel;

    public restoreBackup = () => {
        this.$backupPanel.closeRestoreConfirmation();
        this.$backupPanel.restoreBackups(this.backup);
    };

    public cancel = () => {
        this.confirmed = false;
        this.$backupPanel.closeRestoreConfirmation();
    };
}

export class MoleculePanelEditRowTableBackupsConfirmRestorationComponent implements ng.IComponentOptions {
    public require = {
        $backupPanel: '^moleculePanelEditRowTableBackups'
    };
    public bindings = {
        backup: '<',
        opened: '<'
    };
    public controller = MoleculePanelEditRowTableBackupsConfirmRestorationController;
    // tslint:disable-next-line: max-line-length
    public template = require('./backups-confirm-restoration.html');
}
