import * as ng from 'angular';

import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateSuppliersController {
    public static $inject: string[] = ['$translate'];

    public nearBottom: boolean;
    public pageHeaderData: PanelHeaderData;

    constructor(private $translate: ng.translate.ITranslateService) {
        this.pageHeaderData = {
            backwardLink: 'account.dataprocessing',
            backwardText: this.$translate.instant('TR_080119-8f744e_TR'),
            panelHeaderRoute: '',
            panelIcon: 'sync-alt',
            panelTitle: this.$translate.instant('MENU.MAIN.SUPPLIERS')
        };
    }

}

export class TemplateSuppliersComponent implements ng.IComponentOptions {
    public template = require('./suppliers-template.html');
    public controller = TemplateSuppliersController;
}
