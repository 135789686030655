import { UiLanguagesConst } from '@/configuration';
import { AuthContextService } from '@/services';
import { AccountApi, ViewTypes } from '@/types';
import * as ng from 'angular';

export class OrganismMachineWizardProductConfigEcommerceVirtualMachineController {
    public static $inject: string[] = ['$state'];

    public metadata: ViewTypes.ProductConfigMachineObject;
    public _selectedProductCode: string = null;
    public service = 'machine';
    public availableApps;
    public language: string;
    public appConfigComplete = false;
    public domainConfigComplete = false;
    public vhostValidationErrors: string[] = [];
    public hostNameValidationOptions = {
        allowEmpty: true
    };
    public formCompleted = {
        // tslint:disable:no-empty
        name:      () => this.metadata?.virtualMachine?.name?.length > 0,
        domain:    () => this.domainConfigComplete,
        appConfig: () => this.metadata?.installApp === false || this.appConfigComplete
        // tslint:enable:no-empty
    };

    private _account: AccountApi.Account | AccountApi.Subaccount;

    public constructor(
        private $state: ng.ui.IStateService
    ) {}

    public $onInit = () => {
        this._setMetadata();
        this.availableApps = [];

        for (const app of this.$state.$current.locals.globals.appList.applications) {
            if (app.data.provides.info.tags.indexOf('E-Commerce') >= 0) {
                this.availableApps.push(app);
            }
        }

        this.language = UiLanguagesConst[AuthContextService.user.language];
    };

    get account(): AccountApi.Account | AccountApi.Subaccount {
        return this._account;
    }

    set account(value: AccountApi.Account | AccountApi.Subaccount) {
        // this check looks strange, but it is correct! Do not change it ;)
        if (value && this.metadata && this.metadata.account) {
            this.metadata.account = value;
        }

        this._account = value;
    }

    public get selectedProductCode(): string {
        return this._selectedProductCode;
    }
    public set selectedProductCode(value: string) {
        if (this._selectedProductCode !== value) {
            this._selectedProductCode = value;

            if ([undefined, null, ''].indexOf(value)
                && this.metadata !== undefined
            ) {
                this.metadata.productCode = value;
            }
        }
    }

    public set configCompleted({}) {} /* tslint:disable-line:no-empty */ // used by parent
    public get configCompleted() { // used by parent
        for (const part in this.formCompleted) {
            if (this.formCompleted.hasOwnProperty(part)) {
                if (!this.formCompleted[part]()) {
                    return false;
                }
            }
        }

        return true;
    }

    private _setMetadata = () => {
        this.metadata = {
            account: this._account,
            productCode: this.selectedProductCode,
            productFamily: undefined,
            installApp: false,
            domain: {
                authCode: '',
                nameUnicode: '',
                vHostOnly: true
            },
            applicationSettings: {},
            virtualMachine: {
                name: ''
            }
        };
    };
}

export class OrganismMachineWizardProductConfigEcommerceVirtualMachineComponent implements ng.IComponentOptions {
    public bindings = {
        account: '<',
        configCompleted: '=',
        metadata: '=',
        selectedProductCode: '='
    };

    public template = require('./machine-wizard-product-config-ecommerce-virtual-machine.html');
    public controller = OrganismMachineWizardProductConfigEcommerceVirtualMachineController;
}
