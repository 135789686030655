export class ScrollToValidationError {
    public static scrollToError = ($timeout: ng.ITimeoutService) => {
        // Timeout necessary because the error check happens afterwards.
        $timeout(() => {
            const errorField = document.getElementsByClassName('has-validation-error')[0];
            if (errorField) {
                errorField.scrollIntoView();
            }
        });
    };
}
