import * as ng from 'angular';
import { AlertManagerService, DnsTemplateModelService } from '../../../../../../services';
import * as Types  from '../../../../../../types';
import { OrganismOverviewController } from '../../../../../organisms/overview/overview';
import { AuthContextService } from './../../../../../../services/auth-context';

import './multi-tie-to-template-action.scss';

export class MoleculePanelOverviewHeaderMultiTieToTemplateActionController implements ng.IController {
    public static $inject: string[] = ['$translate', 'alertManager', 'dnsTemplateModel'];

    public OrganismOverview: OrganismOverviewController;
    public confirmHint = false;
    public template: string;
    public templateDropdownItems: any[];
    public records: any[];
    public recordReplacements: any;
    public overwriteRecords = false;
    public editForm;
    public loadingRecords = false;

    private serviceName: string;

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private dnsTemplateModel: DnsTemplateModelService
    ) {}

    public $onInit() {
        this.editForm.saveCallback = this.tieToTemplate;
        this.dnsTemplateModel.list(
            null,
            null,
            {
                field: 'accountId',
                value: this.OrganismOverview.selectedItems[0].accountId
            }
        ).then(
            (reply) => {
                this.templateDropdownItems = [];
                for (const template of reply.data) {
                    this.templateDropdownItems.push({
                        name: template.name,
                        value: template.id
                    });
                }
            }
        );
    }

    public getTemplateRecords = (template: string) => {
        if ([undefined, null].indexOf(template) >= 0) {
            template = '';
        }

        this.loadingRecords = true;
        this.records = [];
        this.dnsTemplateModel.findRecords(template).then(
            (records) => {
                this.records = records;
                this.loadingRecords = false;
            }
        );
    };

    public abort = () => {
        this.OrganismOverview.overviewModel.activeMassAction = false;
    };

    public submit = () => {
        this.editForm.save();
    };

    public tieToTemplate = () => {
        this.OrganismOverview.submitMassAction({
            methode: 'tieToTemplateSubmit',
            overwriteRecords: this.overwriteRecords,
            replacements: this.recordReplacements,
            template: this.template
        }).then(
            (result) => {
                if (result) {
                    const success = result.every((r) => {
                        return r.status !== 'error';
                    });

                    if (success) {
                        this.alertManager.success(this.$translate.instant('TR_100119-139c51_TR'));
                    } else {
                        this.alertManager.success(this.$translate.instant('TR_040220-3a7d16_TR'));
                    }

                    this.abort();
                    this.OrganismOverview.onLoad();
                }
            }, (e) => {
                console.error('error', e);
            });
    };

    public get disableSubmitButton() {
        if (this.template !== undefined && !this.templateLoaded) {
            return true;
        }
        return !this.confirmHint || this.template === undefined || this.editForm.$invalid;
    }

    public get templateLoaded() {
        return this.records !== undefined;
    }

    public listTemplates = (
        limit?: number,
        page?: number,
        filters?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions,
        timeout?: number
    ) => {
        const filterObject: Types.Finding.Filter = {
            subFilter: [],
            subFilterConnective: 'AND'
        };

        if ([undefined, null].indexOf(filters) < 0) {
            filterObject.subFilter.push(filters);
        }

        filterObject.subFilter.push({
            field: 'accountId',
            value: AuthContextService.account.id.toString()
        });

        return this.dnsTemplateModel.list(limit, page, filterObject, sort, timeout);
    };
}

export class MoleculePanelOverviewHeaderMultiTieToTemplateActionComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview',
        editForm: '^moleculeFormEdit'
    };
    public bindings = {
        serviceName: '<'
    };
    public controller =  MoleculePanelOverviewHeaderMultiTieToTemplateActionController;
    public template = require('./multi-tie-to-template-action.html');
}
