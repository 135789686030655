import {
    DashboardWidgetModel,
    DashboardWidgetType
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model';
import {
    DashboardWidgetObjectDefinition
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model-operator';

export class DashboardWidgetSmsValidationModel extends DashboardWidgetModel {
    public static $inject: string[] = DashboardWidgetModel.$inject.concat([]);

    public static dashboardWidgetDefinition: DashboardWidgetObjectDefinition = {
        demoData: {},
        manualAddable: false,
        userPermissions: null,
        systemNotification: 'missingSmsValidation',
        widgetDescription: [],
        widgetObject: {
            attribute: {
                id: 'organDashboardWidgetSmsValidation',
                width: 2,
                height: 1,
                locked: true,
                noResize: true,
                noMove: true,
                autoPosition: true
            },
            componentTagName: 'organ-dashboard-widget-sms-validation',
            widgetModel: 'dashboardWidgetSmsValidationModel',
            version: '1.0'
        },
        widgetTitle: /* translationId */ 'TR_010221-e131bc_TR',
        widgetType: DashboardWidgetType.system
    };

    public dashboardWidgetDefinition: DashboardWidgetObjectDefinition;

    constructor() {
        super();

        this.dashboardWidgetDefinition = DashboardWidgetSmsValidationModel.dashboardWidgetDefinition;
    }
}
