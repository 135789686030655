import * as ng from 'angular';
import * as q from 'q';
import { AuthContextService, DdnsModelService } from '../../../../../services';

export class MoleculeFormDdnsUserSelectController implements ng.IController {
    public static $inject: string[] = [
        '$translate',
        'authContext',
        'ddnsModel'
    ];

    public model: string;
    public overviewLoad: (selection: any) => any;
    public disabled = false;
    public inputId: string;
    public placeholder: string;
    public clearable = false;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private ddnsModel: DdnsModelService
    ) {}

    public $onInit() {
        this.placeholder = this.$translate.instant('TR_200319-418d09_TR');
    }

    public findUser = (id: string) => {
        return this.ddnsModel.getCredentialsById(id);
    };

    public findUsers = (limit?, page?, filter?, sort?) => {
        return this.ddnsModel.listCredentials(limit, page, filter, sort).then(
            (reply) => {
                if ([undefined, null].indexOf(reply.data) === -1) {
                    reply.data.map( (user) => {
                        if (user.name.substr(-1) !== ')') {
                            user.name = user.name + ' ('
                                + this.$translate.instant('TR_140119-680508_TR')
                                + ': ' + user.userName + ')';
                        }
                    });
                }
                return reply;
            }
        );
    };
}

export class MoleculeFormDdnsUserSelectComponent implements ng.IComponentOptions {
    public bindings = {
        clearable: '<',
        inputId: '@?',
        model: '=',
        overviewLoad: '<'
    };
    public controller =  MoleculeFormDdnsUserSelectController;
    public template = require('./ddns-user-select.html');
}
