import { DnsZoneConst } from '../../../../configuration';

import * as ng from 'angular';

import './dns-records.scss';

export class OrganWizardDnsSettingsRecordsController implements ng.IController {
    public static $inject: string[] = [];
    public editMode: boolean;
    public records: any[];
    public showCreatePanel = false;
    public placeholders: boolean;
    public addedRecords: any[] = [];
    public nonEditableRecords: any[] = [];
    public title: string;
    public recordTypeDropdownItems = DnsZoneConst.RecordTypes;
    public zoneNames;
    public replacements;
    public addRecord = () => this.showCreatePanel = true;
}

export class OrganWizardDnsSettingsRecordsComponent implements ng.IComponentOptions {
    public bindings = {
        editMode: '<',
        placeholders: '<',
        records: '=',
        replacements: '<',
        title: '@',
        zoneNames: '<',
        nonEditableRecords: '=?'
    };

    public controller =  OrganWizardDnsSettingsRecordsController;
    public template = require('./dns-records.html');
}
