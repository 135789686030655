import * as ng from 'angular';
import './error-list.scss';

export class MoleculeListsErrorListController implements  ng.IController {
    public errorList: any;
}

export class MoleculeListsErrorListComponent implements ng.IComponentOptions {
    bindings = {
        errorList: '<'
    };
    public controller = MoleculeListsErrorListController;
    public template = require('./error-list.html');
}
