import * as ng from 'angular';

import { UserSettingsManagerService } from '../../../../../services';

export class OrganEditPanelDatabaseGeneralController {
    public cancel;
    public $editFormOrganism;
    public panelRight;
    public showObjectId: boolean;
}

export class OrganEditPanelDatabaseGeneralComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<',
        showObjectId: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormDatabase'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./database-general.html');
    public controller = OrganEditPanelDatabaseGeneralController;
}
