import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateInvoiceOverviewController {
    public static $inject: string[] = ['$translate'];

    public rightPanelArea = [];
    public modifier = '';
    public pageHeaderData: PanelHeaderData;
    public overviewServiceData: any;
    public expertView: boolean;
    public overviewModel: any;

    private simpleFilter;
    private overviewUiSettings;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit() {
        if ([undefined, null].indexOf(this.simpleFilter) === -1) {
            this.expertView = this.overviewUiSettings.expertView || this.simpleFilter.show;
        } else {
            this.expertView = this.overviewUiSettings.expertView || false;
        }

        this.pageHeaderData = {
            backwardLink: 'billing.dashboard',
            backwardText: this.$translate.instant('TR_090119-ebc8d5_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: 'paperclip',
            panelTitle: this.$translate.instant('TR_080119-5c34c1_TR')
        };
        this.overviewServiceData = {
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter
        };
    }

    public expandWrapper = (toggle) => {
        this.expertView = toggle.expand;
    };
}

export class TemplateInvoiceOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public controller = TemplateInvoiceOverviewController;
    public controllerAs = 'TemplateInvoiceOverviewCtrl';
    public template = require('./invoice-overview-template.html');
}
