import * as ng from 'angular';
import {
    AccountModelService,
    AlertManagerService,
    ApiErrorModel,
    BillingRobotService,
    NavigationService
} from '@/services';
import * as Types from '@/types';

export class OrganEditPanelBillingPaymentConfirmSepaVerificationController {
    public static $inject: string[] = [
        '$translate',
        'accountModel',
        'alertManager',
        'apiErrorModel',
        'billingRobot',
        'navigation'
    ];

    public bankAccount: Types.BillingApi.BankAccount;
    public validationErrors: unknown[] = [];
    public verificationCode = '';

    private _loadingBankdata = true;
    private _ignoreErrorCodeList = [10112, 62070, 62071];
    private _maxAttemptsExhausted = false;
    private _sendingCode = false;
    private _showInWidget = false;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private billingRobot: BillingRobotService,
        private navigation: NavigationService
    ) {
        for (const ignoreErrorCode of this._ignoreErrorCodeList) {
            this.apiErrorModel.addErrorToIgnoreList(ignoreErrorCode);
        }
    }

    public $onInit(): void {
        if (!this.bankAccount) {
            this._loadBankData();
        } else {
            this._loadingBankdata = false;
        }
    }

    public get showInWidget(): boolean {
        return this._showInWidget;
    }

    public get maxAttemptsExhausted(): boolean {
        return this._maxAttemptsExhausted;
    }

    // eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function
    public set disableCodeInput({}) {}
    public get disableCodeInput(): boolean {
        return this.sendingCode
            || this.maxAttemptsExhausted;
    }

    // eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function
    public set disableSaveButton({}) {}
    public get disableSaveButton(): boolean {
        return this.sendingCode
        || this.verificationCode.length === 0
        || this.validationErrors.length > 0
        || this.maxAttemptsExhausted;
    }

    // eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function
    public set sendingCode({}) {}
    public get sendingCode(): boolean {
        return this._sendingCode;
    }

    // eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function
    public set loadingBankdata({}) {}
    public get loadingBankdata(): boolean {
        return this._loadingBankdata;
    }

    public sendVerificationCode = (): void => {
        this._sendingCode = true;
        this.validationErrors = [];
        this.billingRobot.bankAccountVerify(this.bankAccount.id, this.verificationCode)
            .then(
                (res) => {
                    if (res.errors && res.errors.length > 0) {
                        for (const error of res.errors) {
                            this._errorHandling(error.code);
                        }

                        this._sendingCode = false;
                    }

                    void this.alertManager.success(
                        void this.$translate.instant('TR_260221-76ecea_TR')
                    );
                    this.navigation.reloadCurrentState(true, true);
                },
                (err) => {
                    this._errorHandling(err.code);
                    this._sendingCode = false;
                }
            );
    };

    private _errorHandling = (errorCode: number): void => {
        switch (errorCode) {
            case 10112:
                this.validationErrors.push({text: this.$translate.instant('TR_API-ERROR_10112_TR')});
            break;
            case 62070:
                this.validationErrors.push({text: this.$translate.instant('TR_260221-252839_TR')});
                break;
            case 62071:
                this.validationErrors.push({text: this.$translate.instant('TR_270820-891ff1_TR')});
                this._maxAttemptsExhausted = true;
                break;
        }
    };

    private _loadBankData = (): void => {
        this._loadingBankdata = true;
        void this.accountModel.ownBankAccountsFind()
            .then(
                (res) => {
                    this.bankAccount = res[res.length - 1];
                    this._loadingBankdata = false;
                },
                () => this._loadingBankdata = false
            );
        };
}

export class OrganEditPanelBillingPaymentConfirmSepaVerificationComponent implements ng.IComponentOptions {
    public bindings = {
        _showInWidget: '<?showInWidget',
        bankAccount: '<'
    };
    public controller = OrganEditPanelBillingPaymentConfirmSepaVerificationController;
    public template = require('./payment-confirm-sepa-verification.html'); // tslint:disable-line:max-line-length
}
