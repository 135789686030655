/**
 * This should now only be a fallback value that SHOULD never be used,
 * because the default version is now returned by the API.
 */
export const VhostPhpDefaultVersionConst = '7.1';

export const VhostDefaultLocationsObjectConst = [
    {
        blockDotfiles: true,
        directoryListingEnabled: false,
        locationType: 'generic',
        mapScript: '',
        mapStyle: '',
        matchString: '',
        matchType: 'default',
        phpEnabled: true,
        redirectionStatus: '',
        redirectionUrl: '',
        restrictToHttpUsers: [],
        setByProfile: false,
        superUserConfiguration: ''
    }
];

/**
 * Because this uses the "fallback" default PHP version,
 * the vHost model now has a method to return a copy with the
 * version defined by the API. Please do not use this directly anymore.
 */
export const VhostDefaultObjectConst = {
    accountId: null,
    domainName: '',
    domainNameUnicode: '',
    enableAlias: true,
    enableSystemAlias: false,
    httpUsers: [],
    locations: VhostDefaultLocationsObjectConst,
    phpVersion: VhostPhpDefaultVersionConst,
    profile: '',
    redirectToPrimaryName: true,
    serverType: 'apache',
    sslSettings: null,
    systemAlias: '',
    webRoot: '',
    webspaceId: null
};

export const VhostProductCodesConst = {
    appInstall: {
        database: ['database-mariadb-single-v1-1m']
    }
};

export const VhostPhpIniDefaultObjectConst = {
    values: [
        {
            key: 'memory_limit',
            value: '128M'
        },
        {
            key: 'max_execution_time',
            value: '60'
        },
        {
            key: 'error_reporting',
            value: '0'
        },
        {
            key: 'display_errors',
            value: 'false'
        },
        {
            key: 'post_max_size',
            value: '64M'
        },
        {
            key: 'opcache.enable',
            value: 'true'
        },
        {
            key: 'allow_url_fopen',
            value: 'true'
        },
        {
            key: 'fpm:master_idle_timeout',
            value: '10m'
        },
        {
            key: 'upload_max_filesize',
            value: '64M'
        },
        {
            key: 'allow_url_include',
            value: 'false'
        }
    ]
};
