import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels';

export class TemplateDnsTemplateCopyController {
    public static $inject: string[] = ['$translate'];

    public template: any;
    public records: any[];
    public pageHeaderData: PanelHeaderData = {
        backwardLink: 'dns.templates.id.edit',
        backwardText: this.$translate.instant('TR_180419-fcea9d_TR'),
        panelHeaderRoute: '',
        panelHeaderRouteParams: {},
        panelIcon: 'location-arrow',
        panelTitle: ''
    };

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.pageHeaderData.panelTitle = this.$translate.instant('TR_180419-9eb5d6_TR',
            { templateName: this.template.name }
        );
    }
}

export class TemplateDnsTemplateCopyComponent implements ng.IComponentOptions {
    public bindings = {
        records: '<',
        template: '<'
    };
    public template = require('./template-copy-template.html');
    public controller = TemplateDnsTemplateCopyController;
}
