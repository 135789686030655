import * as ng from 'angular';
import { AuthContextService } from '../../services';

export class RequestCounterService {
    public static $inject: string[] = ['$timeout'];
    private static counter = 0;

    private updateFn: () => void;

    constructor($timeout: ng.ITimeoutService) {
        this.updateFn = () => {
            void $timeout(
                () => this.onIncreaseCallback(RequestCounterService.counter),
                200
            );
        };
    }

    /* tslint:disable-next-line:no-empty */
    public init = () => {};

    public increase = () => {
        RequestCounterService.counter++;
        this.updateFn();
    };

    public onIncrease = (cb: (counter?: number) => void) => {
        this.onIncreaseCallback = cb;
    };

    /* tslint:disable-next-line:no-empty */
    private onIncreaseCallback: (counter?: number) => void = () => {};
}

export class DevelopmentToolbarDirective {
    private static addedToBody = false;

    public static Factory() {
        const directive = (
            $rootScope: ng.IRootScopeService,
            $state: ng.ui.IStateService,
            requestCounter: RequestCounterService
        ) => {
            return new DevelopmentToolbarDirective($rootScope, $state, requestCounter);
        };

        directive.$inject = ['$rootScope', '$state', 'requestCounter'];

        // Add development toolbar exactly once.
        if (!DevelopmentToolbarDirective.addedToBody && location.port === '3000') {
            if (location.port === '3000') {
                const counterElement = document.createElement('development-toolbar');
                const body = document.getElementsByTagName('body')[0];
                counterElement.className = 'development-toolbar';
                body.appendChild(counterElement);
            }

            // Set this either way. Shouldn't change much, performance-wise, but let's just avoid unnecessary checks.
            DevelopmentToolbarDirective.addedToBody = true;
        }

        return directive;
    }

    public restrict = 'E';
    public template: string = [
        'Current state: {{ state }}',
        'Requests: {{ counter }} ({{ counter - counterOffset }} since state change)',
        'Current language: {{ language }}'
    ]
    .join('&emsp;|&emsp;');

    public link: (
        scope: angular.IScope,
        element: angular.IAugmentedJQuery,
        attrs: angular.IAttributes,
        ctrl?: any,
        $transclude?: ng.ITranscludeFunction
    ) => void;

    constructor(
        $rootScope: ng.IRootScopeService,
        $state: ng.ui.IStateService,
        requestCounter: RequestCounterService
    ) {
        this.link = ($scope: ng.IScope|any) => {
            $scope.counter = 0;
            $scope.counterOffset = 0;
            $scope.state = $state.current.name;

            Object.defineProperty(
                $scope,
                'language',
                {
                    get: () => {
                        if (AuthContextService.authenticated) {
                            return AuthContextService.user.language;
                        }

                        return 'de';
                    },
                    /* tslint:disable-next-line:no-empty */
                    set: () => {}
                }
            );

            $rootScope.$on(
                '$stateChangeStart',
                () => {
                    $scope.counterOffset = $scope.counter;
                }
            );

            $rootScope.$on(
                '$stateChangeSuccess',
                ({}, toState) => {
                    $scope.state = toState.name;
                }
            );

            requestCounter.onIncrease((amount) => {
                $scope.counter = amount;
            });
        };
    }
}
