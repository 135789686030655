import * as ng from 'angular';
import { WizardNewHelperService } from '@/services';
import { AccountApi, ViewTypes, WebhostingApi } from '@/types';
import { DomainTypes } from '@/atomic-components/organs';

export class OrganismDomainWizardSubdomainProductConfigController {
    public static $inject: string[] = ['$timeout', 'wizardNewHelper'];

    public metadata: ViewTypes.ProductConfigDomainVhostObject;
    public profiles: unknown;
    public webspace: WebhostingApi.Webspace;
    public domainType: DomainTypes;
    public formCompleted = {
        dnsRecordsUpdater: false,
        vhost: false
    };

    private _account: AccountApi.Account | AccountApi.Subaccount;
    private _domainList: unknown;
    private _vhost: WebhostingApi.VHost;
    private _webspaceOrig: WebhostingApi.Webspace;
    private _reloadDnsRecordPanel: boolean;

    constructor(
        private $timeout: ng.ITimeoutService,
        private wizardNewHelper: WizardNewHelperService
    ) {}

    public $onInit(): void {
        this._webspaceOrig = ng.copy(this.webspace);
        this._setMetadata();
    }

    public $doCheck(): void {
        if (this.metadata.vhost
            && this.metadata.vhost?.domainName !== this._vhost?.domainName
        ) {
            void this.$timeout(() => this._reloadDnsRecordPanel = false);
            this._vhost = ng.copy(this.metadata.vhost);
            this._domainList = [
                {
                    domainName: this._vhost.domainName,
                    domainNameUnicode: this._vhost.domainNameUnicode
                }
            ];
            void this.$timeout(() => this._reloadDnsRecordPanel = true);
        }
    }

    public get showWebspaceSelection(): boolean {
        return !this._webspaceOrig;
    }

    public get reloadDnsRecordPanel(): boolean {
        return this._reloadDnsRecordPanel;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set configCompleted({}) {}
    public get configCompleted(): boolean {
        let completed = true;
        for (const part in this.formCompleted) {
            if (!this.formCompleted[part]) {
                completed = false;
            }
        }
        return completed;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set enableDnsSettingPanel({}) {}
    public get enableDnsSettingPanel(): boolean {
        return this.metadata.webspace !== undefined
            && this.formCompleted.vhost
            && this.metadata.vhost !== undefined;
    }

    public get domainList(): unknown {
        if (!this.metadata?.vhost) {
            return undefined;
        }

        return this._domainList;
    }

    private _setMetadata = (): void => {
        this.metadata = {
            account: this._account,
            defaultLocation: null,
            domainType: DomainTypes.SUBDOMAIN,
            dns: {
                type: 'webspace',
                values:  {
                    ip: null,
                    masterIp: null,
                    mxServer: null,
                    records: [],
                    replacements: {
                        ipv4Replacement: undefined,
                        ipv6Replacement: undefined,
                        mxipv4Replacement: undefined,
                        mxipv6Replacement: undefined
                    },
                    templateChain: false,
                    templateId: null
                }
            },
            phpIniDefaults: null,
            profiles: this.profiles,
            records: null,
            vhost: null,
            webspace: this.webspace,
            zoneUpdateData: null
        };

        this.metadata.dns.values = this.wizardNewHelper.setDnsSettings(
            this.metadata
        )  as ViewTypes.ProductConfigDomainVhostObjectDnsValue;
    };
}

export class OrganismDomainWizardSubdomainProductConfigComponent implements ng.IComponentOptions {
    public bindings = {
        _account: '<account',
        configCompleted: '=',
        metadata: '=',
        profiles: '<?',
        domainType: '<',
        webspace: '<?'
    };
    public template = require('./domain-wizard-subdomain-config.html');
    public controller = OrganismDomainWizardSubdomainProductConfigController;
}
