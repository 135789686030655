import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';

import * as ng from 'angular';

enum EditFormStatus {
    EDITABLE,
    ERROR,
    READONLY,
    SAVING
}

export class TemplateBasicDataController implements ng.IController {
    public static $inject: string[] = ['$translate', 'authContext'];
    public basicData;
    public pageHeaderData;
    public contactDataInitialStatus: EditFormStatus;
    public contactEmailInitialStatus: EditFormStatus;
    public mode: string;
    private panelIcon = 'id-card-alt';
    private backwardLink = 'account.dashboard';
    private backwardText: string;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {
        this.backwardText = this.$translate.instant('TR_080119-b2bbbd_TR');
    }

    public $onInit() {
        this.contactDataInitialStatus = EditFormStatus.READONLY;
        this.contactEmailInitialStatus = EditFormStatus.READONLY;

        switch (this.mode) {
            case 'overview': {
                this.pageHeaderData = {
                    backwardLink: this.backwardLink,
                    backwardText: this.backwardText,
                    panelHeaderRoute: 'account.basicdata',
                    panelIcon: this.panelIcon,
                    panelTitle: this.$translate.instant('STATE.ACCOUNT.BASICDATA.PAGE-TITLE')
                };
                break;
            }
            case 'contactdata': {
                this.pageHeaderData = {
                    backwardLink: this.backwardLink,
                    backwardText: this.backwardText,
                    panelHeaderRoute: 'account.contactdata',
                    panelIcon: this.panelIcon,
                    panelTitle: this.$translate.instant('TR_080119-d577bd_TR')
                };

                if (this.authContext.isGranted(UiRights.ACC_ACCOUNT_BASICDATA_EDIT)) {
                    this.contactDataInitialStatus = EditFormStatus.EDITABLE;
                }

                break;
            }
            case 'contactemail': {
                this.pageHeaderData = {
                    backwardLink: this.backwardLink,
                    backwardText: this.backwardText,
                    panelHeaderRoute: 'account.contactemails',
                    panelIcon: this.panelIcon,
                    panelTitle: this.$translate.instant('TR_080119-1aea61_TR')
                };

                if (this.authContext.isGranted(UiRights.ACC_ACCOUNT_BASICDATA_EDIT)) {
                    this.contactEmailInitialStatus = EditFormStatus.EDITABLE;
                }

                break;
            }
        }
    }
}

export class TemplateBasicDataComponent implements ng.IComponentOptions {
    public bindings = {
        basicData: '<',
        mode: '@'
    };
    public template = require('./basic-data.html');
    public controller = TemplateBasicDataController;
}
