import ng from 'angular';

import {
    AccountModelService, AuthContextService, BillingRobotService, DomainCheckerService,
    PriceHelperService, PriceModelService, TldListService
} from '@/services';
import * as Types from '@/types';

import { Reducer } from './property-reducer';

export type DomainInfo = {
    domainNameUnicode: string;
    domainStatus?: Types.DomainApi.DomainStatusResult;
    orderAction?: string;
    productCodes: {
        authinfo2: string;
        create: string;
        ownerChange: string;
        renew: string;
        restore: string;
        transfer: string;
    };
    tld: {
        ace: string;
        unicode: string;
    };
};

export type EncodedTld = {
    ace: string;
    unicode: string;
};

type BundleContingent = {
    capacity: number;
    codeStartsWith: string;
    productCodes: string[];
    service: string;
};

export type DomainPriceAggregated = {
    domainInfo: DomainInfo;
    orderPrice: Types.BillingApi.ArticlePurchasePrice;
    orderPriceText: string;
    premiumPrice: unknown;
    rawPrice: number;
    rawBasisConverted: number | null;
    rawTax: {
        tax: number;
        vatRate: number;
    };
    regularPriceText: string;
    renewPrice: Types.BillingApi.ArticlePurchasePrice;
    renewPriceText: string;
    type: {
        isFreeOfCharge: boolean;
        isPremium: boolean;
        isPromotion: boolean;
        isRegular: boolean;
    };
};

export class DomainInfoHelperService {
    public static $inject: string[] = [
        '$translate',
        'accountModel',
        'billingRobot',
        'domainChecker',
        'priceHelper',
        'priceModel',
        'tldList'
    ];

    private _lastTldPart = -1;
    private _tldList: {unicode: string; ace: string}[];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService,
        private billingRobot: BillingRobotService,
        private domainChecker: DomainCheckerService,
        private priceHelper: PriceHelperService,
        private priceModel: PriceModelService,
        private tldListService: TldListService
    ) {
        this._tldList = this.tldListService.getList();
    }

    public getDomainPriceOfDomainName = (
        domainName: string,
        targetAccount = AuthContextService.account,
        bundleContingents?: BundleContingent[],
        hideVatHint = false,
        showBasisCurrency = false,
        hidePremiumPriceLabeling = false
    ): PromiseLike<DomainPriceAggregated> => {
        return this.getDomainInfo(domainName, true, parseInt(targetAccount.id, 10)).then(
            (domainInfo: DomainInfo) => {
                const DomainPriceObject: DomainPriceAggregated = this.generateDomainPriceObject(domainInfo);

                if ([undefined, null].indexOf(Reducer.get(domainInfo, ['domainStatus', 'premiumPrices'])) < 0) {
                    // Is Domain Premium -> response Price
                    DomainPriceObject.type.isPremium = true;
                    const premiumPrices = domainInfo.domainStatus.premiumPrices;
                    DomainPriceObject.orderPrice = premiumPrices[domainInfo.orderAction];
                    if (!hidePremiumPriceLabeling) {
                        DomainPriceObject.orderPriceText = this.$translate.instant('TR_160120-ceeab8_TR') + ' ';
                    }
                    DomainPriceObject.orderPriceText += this.priceHelper.calculateAndShowPrice(
                            DomainPriceObject.orderPrice,
                            targetAccount,
                            false,
                            false,
                            hideVatHint,
                            showBasisCurrency
                        );
                    DomainPriceObject.rawPrice = this.priceHelper.calculatePrice(
                        DomainPriceObject.orderPrice,
                        targetAccount.isCommercialCustomer
                    );
                    DomainPriceObject.rawTax = this.priceHelper.calculatePriceTax(
                        DomainPriceObject.orderPrice,
                        targetAccount,
                        false
                    );

                    DomainPriceObject.renewPrice = premiumPrices.renew as Types.BillingApi.ArticlePurchasePrice;
                    if (DomainPriceObject.renewPrice) {
                        const domainPrice = this.priceHelper.calculateAndShowPrice(
                            premiumPrices.renew as Types.BillingApi.ArticlePurchasePrice,
                            targetAccount,
                            false,
                            false,
                            hideVatHint,
                            showBasisCurrency
                        );
                        DomainPriceObject.renewPriceText = this.$translate.instant(
                            /* translationId */ 'TR_150620-8a90c1_TR',
                            { domainPrice: domainPrice}
                        );
                    }
                    return DomainPriceObject;
                } else if (this._domainIsInBundleContingent(domainInfo, bundleContingents)) {
                    // Check Domain is in Bundle Contingent -> response domain is free of charge
                    DomainPriceObject.type.isFreeOfCharge = true;
                    DomainPriceObject.orderPriceText = this.$translate.instant('1410789b-658c-4638-929c-599fa8181313');
                    DomainPriceObject.rawPrice = 0;
                    DomainPriceObject.rawBasisConverted = 0;
                    DomainPriceObject.rawTax = { tax: 0, vatRate: 0 };
                    return DomainPriceObject;
                } else {
                    return this.checkDomainPromotionalPrice(
                        domainInfo.productCodes[domainInfo.orderAction] as string,
                        DomainPriceObject,
                        targetAccount,
                        hideVatHint,
                        showBasisCurrency
                    );
                }
            }
        );
    };

    public getDomainInfo = (
        domain: string,
        preformDomainStatusLookup: boolean = false,
        accountId: number = 0
    ): PromiseLike<DomainInfo> => {
        this._lastTldPart = -1;
        const promises = [];

        if (preformDomainStatusLookup) {
            promises.push(
                 this.domainChecker.check(
                    [domain],
                     accountId
                )
            );
        }
        return Promise.all(promises).then((promiseResolves) => {
            let domainStatusObject: Types.DomainApi.DomainStatusResult;
            if (preformDomainStatusLookup) {
                domainStatusObject = promiseResolves[0][0];
            }
            return this.accountModel.loadSettings().then(
                (settings) => {
                    return this._aggregateDomainInfo(
                        domain,
                        domainStatusObject,
                        settings.domainSettings.monthlyPaymentEnabled
                    );
                }
            );
        });
    };

    public checkDomainPromotionalPrice = (
        productCode: string,
        DomainPriceObject = this.generateDomainPriceObject(),
        targetAccount = AuthContextService.account,
        hideVatHint = false,
        showBasisCurrency = false
    ): PromiseLike<DomainPriceAggregated> => {
        DomainPriceObject = DomainPriceObject || this.generateDomainPriceObject();
        const filter = {
            field: 'productCode',
            value: productCode
        };
        const owner = targetAccount.id === AuthContextService.account.id
            ? null
            : targetAccount.id;

        return this.billingRobot.articlePurchasePriceList(filter, null, null, null, owner)
            .then(
                (orderPrice) => {
                    let ignoreCommercial: boolean;
                    let showPromotionPrice: boolean;
                    DomainPriceObject.orderPrice = Reducer.get(orderPrice, ['response', 'data', '0']);

                    if (DomainPriceObject.orderPrice) {
                        if (
                            [undefined, null].indexOf(DomainPriceObject.orderPrice.promotionalNetAmount) < 0
                            && DomainPriceObject.orderPrice.promotionalNetAmount
                                !== DomainPriceObject.orderPrice.netAmount
                        ) {
                            // Promotion Price
                            ignoreCommercial = false;
                            showPromotionPrice = true;
                            DomainPriceObject.type.isPromotion = true;
                            DomainPriceObject.orderPriceText = this.priceHelper.calculateAndShowPrice(
                                    DomainPriceObject.orderPrice,
                                    targetAccount,
                                    ignoreCommercial,
                                    showPromotionPrice,
                                    hideVatHint,
                                    showBasisCurrency
                                );
                            DomainPriceObject.regularPriceText = this.priceHelper.calculateAndShowPrice(
                                    DomainPriceObject.orderPrice,
                                    targetAccount,
                                    ignoreCommercial,
                                    false,
                                    hideVatHint,
                                    showBasisCurrency
                                );
                        }
                        else {
                            // Regular Price
                            ignoreCommercial = false;
                            showPromotionPrice = false;
                            DomainPriceObject.type.isRegular = true;
                            DomainPriceObject.orderPriceText = this.priceHelper.calculateAndShowPrice(
                                    DomainPriceObject.orderPrice,
                                    targetAccount,
                                    ignoreCommercial,
                                    showPromotionPrice,
                                    hideVatHint,
                                    showBasisCurrency
                                );
                        }

                        DomainPriceObject.rawPrice = this.priceHelper.calculatePrice(
                            DomainPriceObject.orderPrice,
                            targetAccount.isCommercialCustomer,
                            ignoreCommercial,
                            showPromotionPrice,
                            null,
                            showBasisCurrency
                        );

                        // tslint:disable-next-line
                        DomainPriceObject.rawBasisConverted = DomainPriceObject.orderPrice.exchangeRatio?.exchangeRatio
                            ? this.priceHelper.calculatePrice(
                                DomainPriceObject.orderPrice,
                                targetAccount.isCommercialCustomer,
                                ignoreCommercial,
                                showPromotionPrice,
                                DomainPriceObject.orderPrice.exchangeRatio.exchangeRatio,
                                showBasisCurrency
                            )
                            : 0;

                        DomainPriceObject.rawTax = this.priceHelper.calculatePriceTax(
                            DomainPriceObject.orderPrice,
                            targetAccount,
                            showPromotionPrice
                        );
                    } else {
                        DomainPriceObject.orderPrice = {
                            currency: null,
                            netAmount: -1,
                            productCode: '',
                            service: 'domain',
                            startPIT: ''
                        };
                        DomainPriceObject.type.isRegular = true;
                        DomainPriceObject.type.isFreeOfCharge = false;
                    }

                    return DomainPriceObject;
                }
            );
    };

    public generateDomainPriceObject = (domainInfo?: DomainInfo): DomainPriceAggregated => {
        return {
            type: {
                isRegular: false,
                isPremium: false,
                isPromotion: false,
                isFreeOfCharge: false
            },
            orderPrice: undefined,
            orderPriceText: '',
            rawPrice: 0,
            rawBasisConverted: 0,
            rawTax: {
                tax: 0,
                vatRate: 0
            },
            renewPrice: undefined,
            renewPriceText: '',
            regularPriceText: '',
            domainInfo: domainInfo,
            premiumPrice: null
        };
    };

    public getDomainTld = (
        domainParts: string[],
        position = 1
    ): EncodedTld => {
        if (position === domainParts.length) {
            return { ace: 'invalid', unicode: 'invalid' };
        }

        const tldLookup = domainParts.slice(position).join('.');
        const knownTldHit = this._tldList.filter((tldObject) => tldObject.unicode === tldLookup);

        if (knownTldHit.length > 0) {
            this._lastTldPart = position;
            return knownTldHit[0];
        } else {
            return this.getDomainTld(domainParts, position + 1);
        }
    };

    public hasValidTld = (value: string): boolean => {
        const parts = value.split('.');
        const lookupTld = this.getDomainTld(parts);

        return lookupTld.unicode !== 'invalid';
    };

    private _aggregateDomainInfo = (
        domain: string,
        domainStatusObject?: Types.DomainApi.DomainStatusResult,
        monthlyPaymentEnabled: boolean = false
    ): PromiseLike<DomainInfo> => {
        const domainParts = domain.split('.');
        const tld = this.getDomainTld(domainParts);
        const prefix = 'domain-';
        // monthly billing is only possible for .de domains!
        const monthlyPaymentEnabledPart = monthlyPaymentEnabled && tld.ace === 'de'
            ? '-1'
            : '';

        const domainInfo: DomainInfo = {
            domainNameUnicode: domainParts[this._lastTldPart - 1] + '.' + tld.unicode,
            tld: tld,
            productCodes: {
                authinfo2:      prefix + tld.ace + monthlyPaymentEnabledPart + '-authinfo2',
                create:         prefix + tld.ace + monthlyPaymentEnabledPart + '-create',
                renew:          prefix + tld.ace + monthlyPaymentEnabledPart + '-renew',
                restore:        prefix + tld.ace + monthlyPaymentEnabledPart + '-restore',
                transfer:       prefix + tld.ace + monthlyPaymentEnabledPart + '-transfer',
                ownerChange:    prefix + tld.ace + monthlyPaymentEnabledPart + '-owner-change'
            },
            orderAction: undefined,
            domainStatus: undefined
        };

        if (domainStatusObject !== undefined) {
            domainInfo.orderAction = this._getDomainOrderActionByStatus(domainStatusObject.status);
            domainInfo.domainStatus = domainStatusObject;

            /**
             * The domainStatus Object has a parameter PremiumPrices. If it is not zero, the domain is a premium domain.
             * The premiumPricesObject again has the parameters create, renew and transfer.
             *
             * - create should actually always contain a PriceObject
             * - if renew or transfer is null, this means, that the 'normal' price is valid.
             *
             * In this case we have to get the 'normal' price from an articlePurchasePricesFind.
             */
            if (
                domainInfo?.domainStatus?.premiumPrices
            &&  !domainInfo?.domainStatus?.premiumPrices?.[domainInfo.orderAction]
            &&  typeof domainInfo?.productCodes?.[domainInfo.orderAction] === 'string'
            ) {
                const productCode: string = domainInfo.productCodes[domainInfo.orderAction];
                return this.priceModel.getPriceByProductCode(productCode)
                    .then((res: Types.BillingApi.ArticlePurchasePrice) => {
                        domainInfo.domainStatus.premiumPrices[domainInfo.orderAction] = res;
                        return domainInfo;
                    }
                );
            }
        }

        return Promise.resolve(domainInfo);
    };

    private _getDomainOrderActionByStatus = (domainStatus: string): string => {
        switch (domainStatus) {
            case 'available':
                return 'create';
            case 'registered':
                return 'transfer';
            default:
                return 'not possible';
        }
    };

    private _domainIsInBundleContingent(domainInfo: DomainInfo, bundleContingent: BundleContingent[]): boolean {
        if ([undefined, null].indexOf(bundleContingent) >= 0) {
            return false;
        }

        const domainContingents = bundleContingent.filter(
            (contingent) => contingent.service === 'domain'
        );

        if (domainContingents.length === 0 || domainContingents[0].capacity < 1) {
            return false;
        }

        return domainContingents[0].productCodes.some(
            (contingentProductCode) =>
                contingentProductCode === domainInfo.productCodes[domainInfo.orderAction]
        );
    }
}
