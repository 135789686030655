import * as ng from 'angular';

export class MoleculePanelEditRowStorageQuotaDisplayController implements ng.IController {
    public static $inject: string[] = [];

    public model: number;
    public unit: string;
    public used: number;
}

export class MoleculePanelEditRowStorageQuotaDisplayComponent implements ng.IComponentOptions {
    public transclude = {
        label: 'rowLabel'
    };
    public bindings = {
        model: '<',
        unit: '@',
        used: '<'
    };
    public controller = MoleculePanelEditRowStorageQuotaDisplayController;
    public controllerAs = '$panelRow';
    public template = require('./panel-edit-row-storage-quota-display.html');
}
