import { ModelHelper } from '../model-helper';
import { SslRobotService } from './robot';

export class SslJobModelService {
    public static $inject: string[] = ['sslRobot'];

    constructor(
        private sslRobot: SslRobotService
    ) {}

    public list = (limit?, page?, filters?, sort?) => {
        sort = sort || {
                field: 'jobDisplayName',
                order: 'ASC'
            };
        page = page || 1;

        return this.sslRobot.listJobs(filters, limit, page, sort)
            .then(ModelHelper.returnListResponse)
            .then(
                (result) => {
                    // @todo API Workaround
                    for (const job of result.data) {
                        job.errors = this.parseJobError(job.errors);
                    }

                    return result;
                }
            );
    };

    public findOne(id) {
        return this.sslRobot.listJobs({field: 'jobId', value: id}, 1, 1)
            .then(ModelHelper.returnFindOneResponse)
            .then(
                (job) => {
                    // @todo API Workaround
                    job.errors = this.parseJobError(job.errors);

                    return job;
                }
            );
    }

    public findById(ids) {
        const filter = {subFilterConnective: 'OR', subFilter: ids.map(this.idFilter)};

        return this.sslRobot.listJobs(filter, 0, 1)
            .then(this.returnResponseDate)
            .then(
                (result) => {
                    // @todo API Workaround
                    for (const job of result) {
                        job.errors = this.parseJobError(job.errors);
                    }

                    return result;
                }
            );
    }

    private parseJobError(error) {
        if (error === '' || !error) {
            return [];
        }

        return JSON.parse(error);
    }

    private idFilter(id) {
        return {field: 'jobId', value: id};
    }

    private returnResponseDate(result) {
        return result.response.data;
    }
}
