import * as Types from '@/types';
import * as ng from 'angular';

export class TemplateContactDetailsController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {
        this.pageHeaderData = {
            backwardLink: 'account.dashboard',
            backwardText: this.$translate.instant('TR_080119-b2bbbd_TR'),
            panelIcon: 'id-card-alt',
            panelTitle: this.$translate.instant('TR_200919-2286ad_TR')
        };
    }
}

export class TemplateContactDetailsComponent implements ng.IComponentOptions {
    public template = require('./contact-details.html');
    public controller = TemplateContactDetailsController;
}
