import * as ng from 'angular';
import { DashboardWidgetObject } from '@/atomic-components/organs/dashboard-widgets';
import { NextcloudDashboardStartingDateConst, UiRights } from '@/configuration';
import {
    AuthContextService,
    ManagedApplicationRobotService,
    UserModelService
} from '@/services';
import * as Types from '@/types';

export class DashboardHelperService {
    public static $inject: string[] = [
        '$compile',
        '$timeout',
        'managedApplicationRobot',
        'userModel'
    ];

    public demoData: Record<string, unknown>;

    constructor(
        private $compile: ng.ICompileService,
        private $timeout: ng.ITimeoutService,
        private managedApplicationRobot: ManagedApplicationRobotService,
        private userModel: UserModelService
    ) {}

    public hasNextloudDashboardTemplate = async (): Promise<boolean> => {
        return await this.userModel.getUsersUiMainDashboardConfiguration()
            .then(
                (widgetSettingResponse) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    const storedDashboardWidgets = [undefined, null, '{}', '']
                        .indexOf(widgetSettingResponse) < 0
                        ? JSON.parse(widgetSettingResponse)
                        : null;

                    if (storedDashboardWidgets !== null) {
                        return true;
                    } else if (AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_LIST)) {
                        return this.managedApplicationRobot
                            .nextcloudsListWithoutPagination(null, 1, 1)
                            .then(
                                (apiResponse) => {
                                    const responseData = apiResponse.response.data;
                                    return responseData.length > 0
                                        && (responseData as Types.ManagedApplicationApi.Nextcloud[])
                                        .some(
                                            (item: Types.ManagedApplicationApi.Nextcloud) => {
                                                return item.productCode.indexOf('nextcloud-free') >= 0;
                                            }
                                        );
                                }
                            );
                    } else {
                        return false;
                    }
                }
            );
    };

    public addOutsideWidget = (
        widget: DashboardWidgetObject,
        containerElementId: string,
        $scope: ng.IScope
    ): void => {
        const containerElelement = document.getElementById(containerElementId);
        const gridStackItem = this.buildGridStackItem(widget, $scope, true);

        // This awesome (cough) angularjs helpless timeout methode, i don't like it.
        void this.$timeout(() => containerElelement.append(gridStackItem));
    };

    public buildGridStackItem = (
        widget: DashboardWidgetObject,
        $scope: ng.IScope,
        outsideOfGridStak?: boolean
    ): HTMLDivElement => {
        outsideOfGridStak = outsideOfGridStak || false;
        // Set gridstack item wrapper HTMLElement
        const gridStackItem = document.createElement('div');
        gridStackItem.className = 'grid-stack-item new-widget ui-draggable ui-draggable-handle';

        // Set widget attributes
        gridStackItem.setAttribute('tag-name', widget.componentTagName);
        for (const [key, value] of Object.entries(widget.attribute)) {
            if (key === 'id') {
                gridStackItem.setAttribute(key, value);
            } else {
                gridStackItem.setAttribute(`data-gs-${key}`, value);
            }
        }

        let widgetElement = `<div><${widget.componentTagName}`;
        if ($scope.$ctrl.demoData) {
            widgetElement += ' demo-data="$ctrl.demoData"';
        }
        if (outsideOfGridStak) {
            widgetElement += ' style="width: 500px;"';
        }
        widgetElement += `></${widget.componentTagName}>`;

        /* eslint-disable max-len */
        if (widget.attribute?.locked) {
            gridStackItem.classList.add('grid-stack-item--locked');
            widgetElement += '<span ng-if="$ctrl.editMode" class="grid-stack-item__sign-lock"><fa-icon icon="lock"></fa-icon></span>';
        } else {
            widgetElement += '<span ng-if="!$ctrl.editMode" ng-click="$ctrl.changeToEditMode()" class="grid-stack-item__edit-button"><fa-icon icon="cog"></fa-icon></span>'
                + `<span ng-if="$ctrl.editMode" ng-click="$ctrl.removeWidget('${widget.attribute.id}')" class="grid-stack-item__remove-button"><fa-icon icon="calendar-times"></fa-icon></span>`;
        }
        /* eslint-enable max-len */

        widgetElement += '</div>';
        // Set gridstack item content widget component as HTMLElement
        // $compile to start start digist cycle process
        const widgetNode = this.$compile(widgetElement)($scope)[0];

        // compare wrapper and widget component
        gridStackItem.append(widgetNode);
        return gridStackItem;
    };
}
