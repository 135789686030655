import './panel-row-price-summary.scss';

import ng from 'angular';

import { UiRights } from '@/configuration';
import { CalculatePriceFilter } from '@/filters';
import { AuthContextService, PriceHelperService } from '@/services';
import { BillingApi } from '@/types';

import { WizardHelperService, WizardObject } from '@/atomic-components/molecules/wizard';

export class MoleculePanelRowPriceSummaryController implements ng.IController {
    public static $inject: string[] = ['authContext', 'calculatePriceFilter', 'priceHelper', 'wizardHelper'];

    public serviceObject: WizardObject;
    public totalPrices: any[];

    private _taxType: string;

    constructor(
        private authContext: AuthContextService,
        private calculatePriceFilter: CalculatePriceFilter,
        public priceHelper: PriceHelperService,
        public wizardHelper: WizardHelperService
    ) {}

    public $onInit() {
        this._taxType = this.serviceObject.settings.account.isCommercialCustomer ? 'net' : 'gross';
        this.totalPrices = this.getTotalPrice();
    }

    public getPrice = (productObj: any, showInEur?: boolean) => {
        showInEur = showInEur || false;
        const priceObj: BillingApi.ArticlePurchasePrice = productObj.price;

        if ([undefined, null].indexOf(productObj) === -1
            && [undefined, null].indexOf(priceObj) === -1
            && priceObj.netAmount !== -1
        ) {
            const exchangeRatio = priceObj.exchangeRatio === null || showInEur
            ? null
            : priceObj.exchangeRatio.exchangeRatio;

            return this.calculatePriceFilter(
                [undefined, null].indexOf(priceObj.promotionalNetAmount) < 0
                    ? priceObj.promotionalNetAmount
                    : priceObj.netAmount,
                priceObj.netAmount,
                1,
                this._taxType,
                exchangeRatio,
                false,
                priceObj.productCode,
                priceObj.contractPeriod
            );
        }
    };

    public isInclusive = () => {
        if (this.authContext.account.id !== this.serviceObject.settings.accountId
            && !this.serviceObject.settings.account.rights.some(
                (right: string) => right === UiRights.BIL_LIST_ARTICLE_PRICES
            )
        ) {
            return false;
        }

        return [undefined, null, false].indexOf(this.serviceObject.settings.selectedContingent) < 0;
    };

    public isChargeFree = () => {
        // check if serve == mailbox, product = forwarder and forwarding target email is already created in the system
        return [undefined, false].indexOf(this.serviceObject.display.internalForwarder) < 0;
    };

    public showPriceInfo = () => {
        return [undefined, false].indexOf(this.serviceObject.display.internalForwarder) >= 0
            && [undefined, null].indexOf(this.serviceObject.selectedProduct.selectedPrice) === -1
            && [undefined, null].indexOf(this.serviceObject.selectedProduct.selectedPrice.price) === -1
            && this.serviceObject.selectedProduct.selectedPrice.price.price !== -1;
    };

    public currency = (productObj: any, showEur?: false) => {
        if ([undefined, null].indexOf(productObj) >= 0) {
            return '';
        }

        return this.priceHelper.getProductCurrency(productObj.price, showEur);
    };

    public getChildPrice = (child: any, alternativeCurrency?: boolean) => {
        if ([undefined, null].indexOf(child.price) >= 0) {
            return undefined;
        }

        const exchangeRatio = [undefined, null].indexOf(alternativeCurrency)
        ? null
        : child.price.exchangeRatio;

        return this.calculatePriceFilter(
            [undefined, null].indexOf(child.price.promotionalPrice) < 0
            ? child.price.promotionalPrice
            : child.price.price,

            child.price.vatRate,
            child.amount,
            this._taxType,
            exchangeRatio,
            false,
            child.price.productCode,
            child.price.contractPeriod
        );
    };

    public getChildCurrency = (child: any) => {
        return this.priceHelper.getProductCurrency(child.price);
    };

    public getAmount = (child: any) => {
        if (child.amount === 1) {
            return null;
        }
        return '  ( ×' + String(child.amount) + ')';
    };

    public get hasParent() {
        return [undefined, null].indexOf(this.serviceObject.selectedProduct) >= 0
        || [undefined, null].indexOf(this.serviceObject.selectedProduct.selectedPrice) < 0;
    }

    public showTotalSum = () => {
        if ([undefined, null].indexOf(this.serviceObject.selectedProduct.selectedPrice) === -1
            && [undefined, null].indexOf(this.serviceObject.selectedProduct.selectedPrice.price) === -1
            && this.serviceObject.selectedProduct.selectedPrice.price.price === -1
        ) {
            return false;
        }

        const NumberOfChildren = ([null, undefined].indexOf(this.serviceObject.children) < 0)
            ? this.serviceObject.children.length
            : 0;

        return (NumberOfChildren + (this.hasParent ? 1 : 0)) > 1;
    };

    public getTotalPrice = (showInEur?: boolean) => {
        return this.wizardHelper.getTotalPrice(this.serviceObject, showInEur);
    };
}

export class MoleculePanelRowPriceSummaryComponent implements ng.IComponentOptions {
    public bindings = {
        serviceObject: '<'
    };

    public controller = MoleculePanelRowPriceSummaryController;

    /* tslint:disable-next-line:max-line-length */
    public template = require('./panel-row-price-summary.html');
}
