import * as ng from 'angular';
import { DomainInfoHelperService, TldListService, ValidateSubDomain } from '../../../../../services';
import { FormInputModelOptions } from '../../../../atoms/form-elements';

export class MoleculeInputTextSubdomainController implements ng.IController {
    public static $inject: string[] = ['$translate', 'domainInfoHelper', 'tldList'];

    public validationErrorList = [];
    public value: string;
    public placeholder: string;
    public callbackOnChange: (arg0: any) => any;
    public callbackOnBlur: (arg0: any) => any;
    public callbackOnBlurParams: any;
    public customCallbackOnEnter: () => {};
    public doNotSaveOnFormEnter: boolean;
    public disabled: boolean;
    public modelOptions: FormInputModelOptions;
    public registerToForm: boolean;
    public validationInstructions: any[];
    public options: any;
    public inputId: string;
    public callbackOnClear: (arg0: any) => any;
    public callbackOnClearParams: any;
    public clearable: boolean;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private domainInfoHelper: DomainInfoHelperService,
        private tldList: TldListService
    ) {}

    public $onInit() {
        this.validationInstructions = [{
            instructions: null,
            validator: new ValidateSubDomain(this.$translate, this.domainInfoHelper, this.tldList)
        }];
    }

    public onPaste = (paste) => {
        // implement callback on paste here when needed!
    };
}

export class MoleculeInputTextSubdomainComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnBlur: '<',
        callbackOnBlurParams: '<',
        callbackOnChange: '<',
        callbackOnClear: '<',
        callbackOnClearParams: '<',
        clearable: '<',
        customCallbackOnEnter: '<',
        disabled: '<',
        doNotSaveOnFormEnter: '<',
        inputId: '@?',
        modelOptions: '<',
        options: '<',
        placeholder: '@',
        registerToForm: '<',
        validationErrorList: '=?',
        value: '='
    };
    public controller = MoleculeInputTextSubdomainController;
    public template = require('./input-text-with-validation-wrapper.html');
}
