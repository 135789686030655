import ng from 'angular';
import { UiLanguagesReverseConst, UiRights } from '@/configuration';
import {
    DomainContactPanelMode,
    EditPanelRight,
    EditPanelStatus,
    MoleculeFormEditController,
    MoleculeDomainContactCreateEditModalController
 } from '@/atomic-components/molecules';
import { FormDropDownItems } from '@/atomic-components/molecules/forms';
import {
    AuthContextService,
    debounce,
    DomainHandleModelService,
    DomainInfoHelperService,
    ValidateEmail
} from '@/services';
import { DomainContactHelperService, SystemHelperService } from '@/services/helpers';

import { DomainApi } from '@/types';

export class OrganEditPanelDomainContactGeneralController {
    public static $inject: string[] = [
        '$translate',
        'domainContactHelper',
        'domainHandleModel',
        'domainInfoHelper',
        'systemHelper'
    ];

    public userHasRightAccSubaccountList = AuthContextService.isGranted(
        UiRights.ACC_SUBACCOUNT_LIST
    );
    public $editFormMolecule: MoleculeFormEditController;
    public $organismCtrl: MoleculeDomainContactCreateEditModalController;
    public panelRight: EditPanelRight;
    public initialStatus: EditPanelStatus;

    public contact: DomainApi.Contact;
    public contactTypes: FormDropDownItems[] = [];
    public modelOptions: {
        debounce: 800;
    };
    public panelMode: DomainContactPanelMode;
    public emailChangeDelayed: (email: string) => void;

    private validatorIndex: number;
    private _contactOrig: DomainApi.Contact;
    private _emailValidator: ValidateEmail;
    private _accountBinding: string;
    private _checkingIcannOwnerChange = false;
    private _contactPartParams = [
        'name',
        'organization',
        'type',
        'street',
        'postalCode',
        'city',
        'country',
        'state',
        'emailAddress',
        'phoneNumber',
        'faxNumber',
        'usableBySubAccount',
        'hidden'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        public domainContactHelper: DomainContactHelperService,
        private domainHandleModel: DomainHandleModelService,
        private domainInfoHelper: DomainInfoHelperService,
        public systemHelper: SystemHelperService
    ) {}

    public $onInit(): void {
        this.emailChangeDelayed = debounce(this.emailChange, 800);
        this._contactOrig = ng.copy(this.contact);
        this.panelMode = this.$organismCtrl.panelMode || null;
        this._emailValidator =  new ValidateEmail(false, this.$translate, this.domainInfoHelper);

        if ([undefined, null].indexOf(this.contact) >= 0) {
            this.contact = {
                city: undefined,
                country: undefined,
                street: [''],
                type: undefined
            };
        }
        if ([undefined, null, ''].indexOf(this.contact.country) >= 0) {
            this.contact.country = UiLanguagesReverseConst.de_DE;
        }

        this.validatorIndex = this.$editFormMolecule.registerValidator(this);
    }

    public $onChanges(
        changes: { initialStatus: { currentValue: EditPanelStatus } }
    ): void {
        if (changes.initialStatus !== undefined) {
            this.initialStatus = changes.initialStatus.currentValue;
        }
    }

    public $onDestroy(): void {
        this.$editFormMolecule.unregisterValidator(this.validatorIndex);
        this.cancel();
    }

    public validate = (): boolean => {
        if (this._contactOrig.emailAddress === this.contact.emailAddress) {
            return true;
        }

        if ([undefined, null].indexOf(this.$organismCtrl.editSettings) >= 0) {
            return true;
        }

        return this.$organismCtrl.editSettings.changedEmailIsIcannOwnerChange === false
        || (
            this.$organismCtrl.editSettings.changedEmailIsIcannOwnerChange === true
            && this.$organismCtrl.editSettings.designateAgent === true
        );
    };

    public designateAgentChanged = (selection: { status: boolean }): void => {
        this.$organismCtrl.editSettings.designateAgent = selection.status;
        // trigger validate in formMoleculeComponent
        this.$editFormMolecule.validate(this.validatorIndex);
    };

    public cancel = (): void => {
        if ([undefined, null].indexOf(this._contactOrig) < 0) {
            this._contactPartParams.map(
                (param) => this.contact[param] = ng.copy(this._contactOrig[param])
            );
        }
    };

    public get isAccountSelectionViewable(): boolean {
        return !this.hasSetContact
            && this.userHasRightAccSubaccountList;
    }

    public get hasSetContact(): boolean {
        return [undefined, null].indexOf(this.contact) < 0
            && [undefined, null, ''].indexOf(this.contact.id) < 0;
    }

    public get accountBinding(): boolean {
        if ([undefined, null].indexOf(this._accountBinding) === -1) {
            this.contact.accountId = this._accountBinding;
            return true;
        }

        return false;
    }

    public emailChange = (): Promise<void> => {
        if ([EditPanelStatus.CREATE].indexOf(this.$organismCtrl.initialStatus) >= 0) {
            return;
        }

        if (this._contactOrig.emailAddress === this.contact.emailAddress) {
            // Email has not changed - original value
            this.$organismCtrl.editSettings.isIcannOwnerChange = false;
        } else if (!this._checkingIcannOwnerChange) {
            const validator = new ValidateEmail(false, this.$translate, this.domainInfoHelper);
            const emailValidationErrors = validator.validate(this.contact.emailAddress);

            if (emailValidationErrors.length === 0) {
                this._checkingIcannOwnerChange = true;
                // Email has changed - check icann ownerchange
                return this.domainHandleModel.contactUpdateIsIcannOwnerChange(this.contact).then((response) => {
                    this.$organismCtrl.editSettings.changedEmailIsIcannOwnerChange = response;
                    this.$organismCtrl.editSettings.isIcannOwnerChange = response;
                    this.$editFormMolecule.validateAll();
                    this._checkingIcannOwnerChange = false;
                });
            }

        }

        if (this.$organismCtrl.editSettings !== undefined) {
            if (this.$organismCtrl.editSettings.isIcannOwnerChange) {
                // trigger validate in formMoleculeComponent
                this.$editFormMolecule.validate(this.validatorIndex);
            } else {
                this.$organismCtrl.editSettings.designateAgent = false;
            }
        }

        this.$editFormMolecule.validateAll();
    };
}

export class OrganEditPanelDomainContactGeneralComponent implements ng.IComponentOptions {
    public bindings = {
        $organismCtrl: '<organismCtrl',
        _accountBinding: '<accountBinding',
        contact: '=',
        initialStatus: '<?',
        panelRight: '<'
    };

    public require = {
        $editFormMolecule: '^moleculeFormEdit'
    };

    public template = require('./domain-contact-general.html');
    public controller = OrganEditPanelDomainContactGeneralController;
    public controllerAs = '$editPanelOrgan';
}
