import * as ng from 'angular';
import {
    AlertManagerService,
    BundleModelService,
    NavigationService
} from '../../../../../services';

export class OrganismEditFormBundleRenameController {
    public static $inject: string[] = ['$translate', 'bundleModel', 'alertManager', 'navigation'];

    public originalBundle: any;
    public bundle: any = {};
    public userPanelRight: any;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private bundleModel: BundleModelService,
        private alertManager: AlertManagerService,
        private navigation: NavigationService
    ) {}

    public save = () => {
        this.bundleModel.update(this.bundle)
        .then(
            () => {
                this.alertManager.success(this.$translate.instant('TR_100119-4218ce_TR'));
                return this.navigation.go('bundle.id.dashboard', {}, { reload: true });
            }
        );
        this.originalBundle = ng.copy(this.bundle);
    };

    public $onInit = () => {
        this.bundle = ng.copy(this.originalBundle);
    };

    public cancelRename = () => {
        this.bundle.name = this.originalBundle.name;
        this.navigation.go('bundle.id.dashboard');
    };
}

export class OrganismEditFormBundleRenameComponent implements ng.IComponentOptions {
    public bindings = {
        originalBundle: '<bundle',
        userPanelRight: '='
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormBundleRenameController;
    public template = require('./bundle-rename.html');
}
