import ng from 'angular';

import {
    BundleContingentProductCodesConst, UiRights, VhostProductCodesConst
} from '@/configuration';
import { CalculatePriceFilter } from '@/filters';
import {
    AuthContextService, BundleModelService, DatabaseModelService, PriceCacheService,
    PriceHelperService
} from '@/services';
import * as Types from '@/types';

enum DatabaseType {
    NEW = 1,
    EXISTING = 2
}

interface SelectedDatabaseObject {
    id: string;
    createNew: boolean;
    bundleId: string | null;
    bundleDatabaseProduct: string | null;
}

export class MoleculePanelEditRowDatabaseSelectController implements ng.IController {
    public static $inject: string[] = [
        'authContext',
        'bundleModel',
        'calculatePriceFilter',
        'databaseModel',
        'priceCache',
        'priceHelper',
        '$translate'
    ];

    public optionData: any;
    public dataLossConfirmed = false;
    public costsConfirmed = false;
    public selectedDatabase: any;
    public bundleId: string;
    public databasePrice: any;
    public loadingDropdownItems: boolean;
    public dropdownItems: any[] = [];
    public databaseType: number = DatabaseType.NEW;
    public databaseTypeButtons: any[] = [];
    public databaseRessource;

    private _canCreateDatabaseInBundle = false;
    private _bundleDatabaseProduct: string | null = null;
    private selectedDbObject: SelectedDatabaseObject;

    constructor(
        private authContext: AuthContextService,
        private bundleModel: BundleModelService,
        private calculatePriceFilter: CalculatePriceFilter,
        private databaseModel: DatabaseModelService,
        private priceCache: PriceCacheService,
        private priceHelper: PriceHelperService,
        protected $translate: ng.translate.ITranslateService
    ) {
        this.databaseTypeButtons = [
            { value: DatabaseType.EXISTING, label: this.$translate.instant('TR_100119-df779c_TR') },
            { value: DatabaseType.NEW, label: this.$translate.instant('TR_100119-fb14b8_TR') }
        ];
    }

    public $onInit() {
        this.setDatabasePrice();
        this.loadingDropdownItems = this.loadingDropdownItems || false;
        this.bundleId = this.bundleId || null;
        this.selectedDatabase = null;
        this._canCreateDatabaseInBundle = false;
        this.selectedDbObject = {
            bundleDatabaseProduct: this._bundleDatabaseProduct,
            bundleId: null,
            createNew: true,
            id: this.selectedDatabase
        };

        if ([undefined, null].indexOf(this.bundleId) === -1) {
            this.bundleModel.findOne(this.bundleId).then((bundle) => {
                if (bundle.effectiveContingentUsage !== undefined) {
                    this._canCreateDatabaseInBundle = bundle.effectiveContingentUsage
                    .some(
                        (contingent) => {
                            if (contingent.availableCapacity < 1) {
                                return false;
                            }

                            return contingent.productCodes.some(
                                (code) => {
                                    if (
                                        code.indexOf(BundleContingentProductCodesConst.database) >= 0
                                        && code.indexOf('storage') < 0
                                    ) {
                                        this.selectedDatabase = bundle.id;
                                        this._bundleDatabaseProduct = code;
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                            );
                        }
                    );
                }

                if (this._canCreateDatabaseInBundle) {
                    this.databaseType = DatabaseType.NEW;
                    this.selectedDbObject.bundleId = this.selectedDatabase;
                    this.selectedDbObject.bundleDatabaseProduct = this._bundleDatabaseProduct;
                }
            });
        }
    }

    public $onChanges(changes) {
        if (changes.loadingDropdownItems !== undefined) {
            this.databaseType = this.dropdownItems.length === 0 ? DatabaseType.NEW : DatabaseType.EXISTING;
        }
    }

    public get showDatabaseTypeSelection() {
        if (!this.authContext.isGranted(UiRights.DB_DATABASE_CREATE)) {
            // Account has no database create right
            if (this.dropdownItems.length > 0) {
                // But, database/s are available
                this.databaseType = DatabaseType.EXISTING;
            }
            return false;
        }
        if (this.dropdownItems.length === 0) {
            // No databases available but account has database create right
            this.databaseType = DatabaseType.NEW;
            return false;
        }

        return true;
    }

    public get showDataLossWarning() {
        return this.databaseType === DatabaseType.EXISTING
            && this.optionData.willEraseExistingData
            && [undefined, null].indexOf(this.selectedDatabase) === -1;
    }

    public get showCostsWarning() {
        if (this.createInManagedServerContingent) {
            return false;
        }
        return this.databaseType === DatabaseType.NEW && !this._canCreateDatabaseInBundle;
    }

    public get showCreateDatabaseInBundle() {
        return this.databaseType === DatabaseType.NEW && this._canCreateDatabaseInBundle;
    }

    public get createInManagedServerContingent() {
        return [undefined, null].indexOf(this.databaseRessource) < 0;
    }

    public set createInManagedServerContingent(value) {
        return;
    }

    public get databaseSelectionPossible() {
        return this.dropdownItems.length > 0
            || this.authContext.isGranted(UiRights.DB_DATABASE_CREATE);
    }

    public set databaseSelectionPossible(value) {
        return;
    }

    // We only return the selected database when the user has confirmed that he is okay with the data loss
    public get value() {
        if (!this.databaseSelectionPossible) {
            return undefined;
        }

        switch (this.databaseType) {
            case DatabaseType.NEW:
                if (this.createInManagedServerContingent
                    || this.databasePriceDisplay === -1
                ) {
                    return this.selectedDbObject;
                }
                if (this.showCostsWarning && !this.costsConfirmed) {
                    return undefined;
                }
                return this.selectedDbObject;
            case DatabaseType.EXISTING:
                if (
                    [undefined, null, ''].indexOf(this.selectedDatabase) >= 0
                    || (
                        this.showDataLossWarning && !this.dataLossConfirmed
                    )
                ) {
                    return undefined;
                }
                return this.selectedDbObject;
            default:
                return undefined;
        }
    }

    public set value(value) {
        this.selectedDatabase = value;
    }

    public onDatabaseSelection = (selection: string) => {
        if ([undefined, null].indexOf(selection) >= 0) {
            this.selectedDbObject.id = null;
            this.selectedDbObject.createNew = true;
            this.selectedDatabase = null;
        } else {
            this.selectedDbObject.id = selection;
            this.selectedDbObject.createNew = false;
            this.selectedDatabase = selection;
        }
    };

    public get warningText() {
        if (this.showCostsWarning) {
            return this.$translate.instant('TR_100119-32e03d_TR');
        }
        if (this.showDataLossWarning) {
            return this.$translate.instant('TR_100119-12cc42_TR');
        }
    }

    public setDatabasePrice = () => {
        this.priceCache.listDatabasePrices().then(
            (reply) => {
                reply.map(
                    (price) => {
                        if (
                            this._matchProductCode(price.productCode, VhostProductCodesConst.appInstall.database)
                            && price.productCode.indexOf('storage') === -1
                        ) {
                            this.databasePrice = price;
                        }
                    }
                );
            }
        );
    };

    public databaseTypeHasChanged = (databaseType) => {
        this.selectedDbObject.createNew = databaseType === DatabaseType.NEW;
    };

    public get databasePriceDisplay() {
        const priceObject = { netAmount: null, amounts: null } as Types.BillingApi.ArticlePurchasePrice;
        if (this.databasePrice !== undefined) {
            priceObject.netAmount = this.databasePrice.price;
            priceObject.amounts = [{ vatRate: this.databasePrice.amounts[0].vatRate }];
            priceObject.productCode = this.databasePrice.productCode;
            priceObject.contractPeriod = this.databasePrice.contractPeriod;
        } else {
            return;
        }
        const taxType = this.authContext.account.isCommercialCustomer ? 'net' : 'gross';

        return this.calculatePriceFilter(
            priceObject.netAmount,
            priceObject.amounts[0].vatRate,
            1,
            taxType,
            null,
            false,
            priceObject.productCode,
            priceObject.contractPeriod);
    }

    public get databasePriceCurrency() {
        return this.priceHelper.getProductCurrency(this.databasePrice);
    }

    private _matchProductCode(productCode: string, productCodeList: string[]) {
        return productCodeList.some((pCode) => productCode.indexOf(pCode) >= 0);

    }
}

export class MoleculePanelEditRowDatabaseSelectComponent implements ng.IComponentOptions {
    public bindings = {
        bundleId: '<',
        databaseRessource: '<?',
        databaseSelectionPossible: '<',
        dropdownItems: '<',
        loadingDropdownItems: '<',
        optionData: '<',
        value: '='
    };
    public transclude = {
        label: 'rowLabel'
    };
    public controller =  MoleculePanelEditRowDatabaseSelectController;
    public template = require('./panel-edit-row-database-select.html');
}
