import * as ng from 'angular';
import * as Types from '../../../../../types';

export class MoleculeChartDomainRestrictionOverviewRowController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public restrictions: Types.keenRobotsCommon.Restriction[];
    public isDisabled = false;
    public restrictionLabel: string;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    $onInit() {
        const restrictionTypes = this.restrictions
            .map(restriction => restriction.type)
            .filter((type, index, types) => types.indexOf(type) === index);
        this.restrictionLabel = this.isDisabled
            ? this.$translate.instant('TR_090119-dd43af_TR')
            : this.$translate.instant('GENERAL.OBJECT.STATUS.RESTRICTED');

        this.isDisabled = restrictionTypes.indexOf('disabled') >= 0;
    }
}

export class MoleculeChartDomainRestrictionOverviewRowComponent implements ng.IComponentOptions {
    public bindings = {
        restrictions: '<'
    };
    public controller =  MoleculeChartDomainRestrictionOverviewRowController;
    public template = require('./restriction-overview-domain-row.html');
}
