import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { ApiErrorModel } from '../../../services';

export class TemplateInvoiceEditController {
    public static $inject: string[] = ['$translate', 'apiErrorModel', 'objectTypeFilter'];

    public modifier = '';
    public pageHeaderData: PanelHeaderData;

    public invoice: any;
    public openInvoices: any;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private apiErrorModel: ApiErrorModel,
        private objectTypeFilter
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'billing.invoices.overview',
            backwardText: this.$translate.instant('TR_090119-af8a66_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: 'paperclip',
            panelTitle: this.objectTypeFilter(this.invoice.type) + ' ' + this.invoice.documentNumber
        };
    };

    public $onDestroy = () => {
        this.apiErrorModel.destroyErrorList();
    };

    public get errorList() {
        return this.apiErrorModel.errorObjectList;
    }
}

export class TemplateInvoiceEditComponent implements ng.IComponentOptions {
    public bindings = {
        invoice: '<',
        openInvoices: '<'
    };
    public controller = TemplateInvoiceEditController;
    public controllerAs = '$TemplateInvoiceEditCtrl';
    public template = require('./invoice-edit-template.html');
}
