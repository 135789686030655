import {
    CleanableStringType,
    DnsTemplateModelService,
    DomainModelService,
    StringCleaner,
    WizardNewHelperService
} from '@/services';
import * as ng from 'angular';
import * as Types from '../../../../../types';
import { AccountApi, DomainApi, ViewTypes } from '../../../../../types';

export class OrganismDnsWizardProductConfigZoneExpertController {
    public static $inject: string[] = [
        '$timeout',
        'domainModel',
        'wizardNewHelper'
    ];

    public metadata: ViewTypes.ProductConfigDnsObject;
    public service = 'dns';
    public nameserverDropdownItems: any[];
    public dnsRecordsPreview: any[];
    public nonEditableRecords: any[];
    public nameserversComplete = false;
    public dnsSecButtons: any[] = [
        {
            label: 'NSEC3',
            value: 'nsec3'
        },
        {
            label: 'NSEC',
            value: 'nsec'
        }
    ];
    public formCompleted = {
        // tslint:disable:no-empty
        fqdn: () => [null, undefined].indexOf(this.metadata.fqdnList) < 0 && this.metadata.fqdnList.length > 0,
        nsSettings: () => {
            return this.metadata.nameserver.values.default
                || (
                    this.metadata.nameserver.ownNameserversComplete
                    && this.metadata.nameserver.ownNameservers.length >= 2
                )
                || [undefined, null, ''].indexOf(this.metadata.nameserver.values.id) < 0;
        }
        // tslint:enable:no-empty
    };

    private _account: AccountApi.Account | AccountApi.Subaccount;
    private _checkedDomains: DomainApi.Domain[] = [];
    private _kskAvailabilityChecked: string[] = [];
    private _addedNsRecords = [];

    constructor(
        private $timeout: ng.ITimeoutService,
        private domainModel: DomainModelService,
        private wizardNewHelper: WizardNewHelperService
    ) {}

    public $onInit = (): void => {
        this._setMetadata();
        this._setNameserverSets();
        this._setHostmasterEmailAddress();
    };

    public get ownNameserversComplete(): boolean {
        return this.metadata.nameserver.ownNameserversComplete;
    }
    public set ownNameserversComplete(value: boolean) {
        if (
            this.metadata.nameserver.ownNameserversComplete !== value
            && value
        ) {
            this.setRecords();
        }

        this.metadata.nameserver.ownNameserversComplete = value;
    }

    get fqdnList(): string[] {
        return this.metadata.fqdnList;
    }
    set fqdnList(value: string[]) {
        if (!ng.equals(this.metadata.fqdnList, value)) {
            this.$timeout(() => {
                this._setHostmasterEmailAddress();
                this.setRecords();
            });
        }
        this.metadata.fqdnList = value;
    }

    public get account(): AccountApi.Account | AccountApi.Subaccount {
        return this._account;
    }
    public set account(value: AccountApi.Account | AccountApi.Subaccount) {
        if (value && this.metadata && this.metadata.account) {
            this.metadata.account = value;
        }

        this._account = value;
    }

    public set configCompleted({}) {} // tslint:disable-line:no-empty
    public get configCompleted() {
        let completed = true;

        for (const part in this.formCompleted) {
            if (!this.formCompleted[part]()) {
                completed = false;
            }
        }

        return completed;
    }

    public get publishKskAvailable() {
        if (this.metadata?.fqdnList?.length > 1) {
            this.metadata.settings.dnsSecActive = false;
            return false;
        }

        if (this._kskAvailabilityChecked.some((domain) => domain === this.metadata.fqdnList[0])) {
            const domain = this._checkedDomains.filter(
                (domainData) => domainData.nameUnicode === this.metadata.fqdnList[0]
            );

            if (domain.length > 0) {
                return domain.some((domainData) => domainData.accountId === this.metadata.account.id);
            }
        } else {
            const domainName = StringCleaner
                .clean(this.metadata.fqdnList[0])
                .as(CleanableStringType.DomainName);

            this._kskAvailabilityChecked.push(domainName);
            void this.domainModel.findByName([domainName]).then(
                (result: DomainApi.Domain[]) => {
                    for (const domain of result) {
                        this._checkedDomains.push(domain);
                    }
                }
            );
        }

        return false;
    }

    public onNameserverOptionChange = (selection: any) => {
        void this.$timeout(() => {
            this.setRecords();
        });
    };

    public setRecords = () => {
        void this.$timeout(() => {
            this.metadata.records = this.metadata.records.filter(
                (record) => !this._addedNsRecords.some(
                    (addedRecord) => record.name === ''
                        && addedRecord === record.content
                        && record.type === 'NS'
                )
            );

            let nsList = [];

            if ([null, undefined].indexOf(this.metadata.nameserver.values.nameservers) < 0) {
                nsList = this.metadata.nameserver.values.nameservers;
            } else if ([null, undefined].indexOf(this.metadata.nameserver.ownNameservers) < 0) {
                nsList = this.metadata.nameserver.ownNameservers;
            }

            this.nonEditableRecords = [];
            for (const record of nsList) {
                const nsRecord = {
                    content: record.name,
                    name: '',
                    ttl: 86400,
                    type: 'NS'
                };
                this.nonEditableRecords.push(nsRecord);
                this.metadata.records.push(nsRecord);
                this._addedNsRecords.push(record.name);
            }
        });
    };

    public dnsSecStateChanged = () => {
        void this.$timeout(() => {
            if (this.metadata.settings.dnsSecActive) {
                this.metadata.settings.dnsSecOptions.nsecMode = 'nsec3';
            }
        });
    };

    private _setNameserverSets = () => {
        void this.wizardNewHelper.getNameserverSets(this.metadata.account.id)
            .then((nameserverSets) => {
                this.metadata.nameserver.values = this.wizardNewHelper.objectCache.nameserSets[0].items[0].value;
                this.nameserverDropdownItems = nameserverSets;
            });
    };

    private _setMetadata = () => {
        this.metadata = {
            account: this._account,
            fqdnList: [],
            productCode: undefined,
            productFamily: undefined,
            records: [],
            nameserver: {
                ownNameservers: [],
                ownNameserversComplete: false,
                values: {
                    default: false,
                    id: null,
                    nameservers: null,
                    type: null
                }
            },
            settings: {
                dnsSecActive: false,
                transferAllowed: false,
                dnsSecOptions: {
                    nsecMode: 'nsec3',
                    publishKsk: false
                },
                soaValues: {
                    expire: 2419200,
                    negativeTtl: 900,
                    refresh: 86400,
                    retry: 7200,
                    ttl: 86400
                },
                zoneTransferWhitelist: [],
                emailAddress: null
            }
        };
    };

    private _setHostmasterEmailAddress = () => {
        if (this.metadata?.fqdnList?.length < 1) {
            return;
        }

        if ([undefined, null, ''].indexOf(this.metadata.account.dnsSettings.defaultHostmasterEmailAddress) < 0) {
            this.metadata.settings.emailAddress = this.metadata.account.dnsSettings.defaultHostmasterEmailAddress;
        } else {
            this.metadata.settings.emailAddress = 'hostmaster@';

            if (this.metadata.fqdnList.length === 1) {
                this.metadata.settings.emailAddress += this.metadata.fqdnList[0];
            } else {
                this.metadata.settings.emailAddress += '##DOMAIN##';
            }
        }
    };
}

export class OrganismDnsWizardProductConfigZoneExpertComponent implements ng.IComponentOptions {
    public bindings = {
        account: '<account',
        configCompleted: '=configCompleted',
        metadata: '='
    };
    public template = require('./dns-wizard-product-config-zone-expert.html');
    public controller = OrganismDnsWizardProductConfigZoneExpertController;
}
