import { ShortDebounceInterval } from '@/configuration';
import * as ng from 'angular';
import { ValidateMaxLength } from './../../../../../services/validators/validate-length';

export class OrganCreateConfigurationGeneralProjectnameController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public projectName: string;
    public maxLength: number;

    public shortDebounceInterval = ShortDebounceInterval;
    public validationInstructions: any  = [];
    public validationErrorList: any = [];

    public constructor(private $translate: ng.translate.ITranslateService){}

    public $onInit() {
        if (this.maxLength !== undefined) {
            this.validationInstructions.push({
                instructions: {},
                validator: new ValidateMaxLength(this.$translate, this.maxLength)
            });
        }
    }

    public get completed() {
        return this.projectName !== '';
    }

    public set completed({}) {} // tslint:disable-line:no-empty
}

export class OrganCreateConfigurationGeneralProjectnameComponent implements ng.IComponentOptions {
    public bindings = {
        completed: '=',
        projectName: '=',
        maxLength: '<'
    };

    public template = require('./projectname.html');
    public controller =  OrganCreateConfigurationGeneralProjectnameController;
}
