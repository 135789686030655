import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import { AuthContextService } from '../../../../../services';
import { OrganismOverviewController, OverviewDataObject } from '../../../../organisms';

export class MoleculeOverviewRowPanelDomainProductController implements ng.IController {
    public userHasRightBilBundleList = AuthContextService.isGranted(UiRights.BIL_BUNDLE_LIST); // used in template!
    public overviewData: OverviewDataObject;
    public OrganismOverview: OrganismOverviewController;
    public overviewLoad: (selection: any) => any;
    public internalPaginationLimit: number;

    public deletionScheduled = (item: any) => {
        if (typeof(item) === 'undefined') {
            return false;
        }
        return item.deletionScheduledFor !== undefined
            && item.deletionScheduledFor !== null
            && item.status !== 'restorable';
    };

    public get showAccounts() {
        return this.OrganismOverview.globals.showAccounts;
    }
}

export class MoleculeOverviewRowPanelDomainProductComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelDomainProductController;
    public controllerAs = 'OverviewDomainCtrl';
    public template = require('./domain-product-row.html');
}
