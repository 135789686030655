import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { UserSettingsManagerService } from '../../../services';

export class TemplateSslCertificateOverviewController {
    public static $inject: string[] = ['$translate', 'userSettingsManager'];

    public pageHeaderData: PanelHeaderData;
    public bundle;
    public overviewServiceData;
    public expertView: boolean;
    public overviewModel: any;
    public faqArticleIdList: string[] = [
        'ssl-activate',
        'ssl-create'
    ];
    private simpleFilter;
    private extendedFilters;
    private overviewUiSettings;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private userSettingsManager: UserSettingsManagerService
    ) {}

    public $onInit() {
        if ([undefined, null].indexOf(this.simpleFilter) === -1) {
            this.expertView = this.overviewUiSettings.expertView || this.simpleFilter.show;
        } else {
            this.expertView = this.overviewUiSettings.expertView || false;
        }

        if (this.bundle === undefined) {
            this.bundle = null;
        }

        this.pageHeaderData = {
            backwardLink: 'ssl.dashboard',
            backwardText: this.$translate.instant('TR_140119-5574f2_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: 'shield',
            panelTitle: this.$translate.instant('TR_140119-e14775_TR')
        };
        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter
        };

        // Surpress backlink to dashboard on expert view because it auto-redirects to overview.
        this.userSettingsManager.getUiSettings().then(
            (result) => {
                if (result?.overviewSettings?.SslCertificateOverviewCtrl?.expertView) {
                    this.pageHeaderData.backwardText = '';
                    this.pageHeaderData.backwardLink = '';
                    this.pageHeaderData.panelHeaderRouteParams = null;
                }
            }
        );
    }

    public expandWrapper = (toggle) => {
        this.expertView = toggle.expand;
    };
}

export class TemplateSslCertificateOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public controller = TemplateSslCertificateOverviewController;
    public controllerAs = '$TemplateSslCertificateOverviewCtrl';
    public template = require('./ssl-certificate-overview-template.html');
}
