import * as ng from 'angular';
import { InputPlaceholderConst } from '../../../../../configuration';
import {
    AlertManagerService,
    ApiErrorModel,
    DateWrapper,
    NavigationService,
    RedirectionModelService
} from '../../../../../services';

export class OrganEditPanelRedirectionEditController {
    public static $inject: string[] = [
        '$q',
        '$translate',
        'apiErrorModel',
        'alertManager',
        'navigation',
        'redirectionModel'
    ];

    public redirection: any;
    public enableDeletion = false;
    public deleteDate: DateWrapper;
    public paidUntil = '';
    public originalRedirection: any;
    public metaTagAuthor: string;
    public metaTagKeywords: string;
    public metaTagDescription: string;
    public panelRight = {
        editPanelButton: true
    };
    public typeDropdownItems: any[] = [];
    public inputPlaceholder = InputPlaceholderConst;

    constructor(
        private $q: ng.IQService,
        protected $translate: ng.translate.ITranslateService,
        private apiErrorModel: ApiErrorModel,
        private alertManager: AlertManagerService,
        private navigation: NavigationService,
        private redirectionModel: RedirectionModelService
    ) {}

    public $onInit() {
        this.typeDropdownItems = [{
            name: this.$translate.instant('TR_110119-c2483c_TR'),
            value: '301'
        }, {
            name: this.$translate.instant('TR_110119-0712c5_TR'),
            value: '302'
        }, {
            name: this.$translate.instant('TR_110119-07fe21_TR'),
            value: 'frame'
        }];

        this.originalRedirection = ng.copy(this.redirection, this.originalRedirection);
        this.paidUntil = this.originalRedirection.paidUntil;
        this.resetRedirection();
    }

    public save = () => {
        const metaTags = [];
        if (this.redirection.type === 'frame') {
            if (this.metaTagAuthor !== undefined && this.metaTagAuthor.length > 0) {
                metaTags.push({
                    content: this.metaTagAuthor,
                    name: 'author'
                });
            }
            if (this.metaTagKeywords !== undefined && this.metaTagKeywords.length > 0) {
                metaTags.push({
                    content: this.metaTagKeywords,
                    name: 'keywords'
                });
            }
            if (this.metaTagDescription !== undefined && this.metaTagDescription.length > 0) {
                metaTags.push({
                    content: this.metaTagDescription,
                    name: 'description'
                });
            }
        }
        this.redirection.metaTags = metaTags;
        this.redirectionModel.update(this.redirection).then(
            // tslint:disable-next-line:no-empty
            () => {},
            () => {
                this.resetRedirection();
            }
        );
    };

    public delete = () => {
        this.apiErrorModel.destroyErrorList();
        this.redirectionModel.deleteOnePlaned(this.redirection, this.deleteDate).then(
            (response) => {
                if (!this.apiErrorModel.apiResponseHasError(response)) {
                    this.alertManager.success(this.$translate.instant('TR_110119-2eaba4_TR'));
                    this.navigation.go('domain.redirections.overview', {}, { reload: true });
                    return response;
                }
                return this.$q.reject(false);
            },
            (error) => {
                this.alertManager.error(this.$translate.instant('TR_110119-c79451_TR'));
                return this.$q.reject(error);
            }
        );
    };

    private cancelDelete = () => {
        this.deleteDate = new DateWrapper();
        this.enableDeletion = false;
    };

    private resetRedirection = () => {
        this.redirection = ng.copy(this.originalRedirection, this.redirection);
        if (this.redirection.metaTags !== undefined) {
            this.redirection.metaTags.map(
                (tag) => {
                    if (tag.name === 'author') {
                        this.metaTagAuthor = tag.content;
                    }
                    if (tag.name === 'keywords') {
                        this.metaTagKeywords = tag.content;
                    }
                    if (tag.name === 'description') {
                        this.metaTagDescription = tag.content;
                    }
                }
            );
        }
    };
}

export class OrganEditPanelRedirectionEditComponent implements ng.IComponentOptions {
    public bindings = {
        redirection: '<'
    };
    public template = require('./redirection-edit.html');
    public controller = OrganEditPanelRedirectionEditController;
}
