import * as ng from 'angular';

export class MoleculeButtonFancyFilterController implements ng.IController {
    public static $inject: string[] = [];

    public onClickEvent: () => {};
    public disabled: boolean;

    public $onInit() {
        this.disabled = this.disabled || false;
    }

    public toggleFilterExpand() {
        if (!this.disabled) {
            this.onClickEvent();
        }
    }
}

export class MoleculeButtonFancyFilterComponent implements ng.IComponentOptions {
    public bindings = {
        disabled: '<',
        onClickEvent: '<'
    };
    public controller =  MoleculeButtonFancyFilterController;
    public template = require('./fancy-filter.html');
}
