import { DnsZoneConst } from '../../../../../configuration';
import { NavigationService } from '../../../../../services';

import ng from 'angular';

import './dns-records.scss';

export class OrganEditPanelDnsRecordsController {
    public static $inject: string[] = ['$state', '$timeout', 'navigation'];

    public $editForm;
    public acceptPlaceholders: boolean;
    public activeContentSearch = '';
    public activeNameSearch = '';
    public activeTypeSearch = '';
    public addedRecords: string[];
    public changedRecords: string[];
    public confirmTemplateEditHint = false;
    public editMode: boolean;
    public newRecordsDialogOpened: boolean;
    public originalRecords: any[];
    public records: any[];
    public recordsHaveBeenSplit = false;
    public recordsInEditMode = 0;
    public recordsMarkedForDeletion: string[];
    public recordTypeDropdownItems = DnsZoneConst.RecordTypes;
    public replacements;
    public pagination = { currentPage: 1, entries: 0, limit: 25 };
    public templateCreator: boolean;
    public templateEdit: boolean;
    public temporaryContentSearch = '';
    public temporaryNameSearch = '';
    public temporaryTypeSearch = '';
    public zone: any;
    public isDnsTemplateNew = false;
    private _showSearchPanel = false;

    constructor(
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private navigation: NavigationService
    ) {}

    public $onInit() {
        // when editing or creating a template filter out NS type (PUI-5412)
        this.recordTypeDropdownItems = this.targetIsDnsTemplate
            ? DnsZoneConst.RecordTypes.filter((record) => record.value !== 'NS')
            : DnsZoneConst.RecordTypes;
        this.originalRecords = ng.copy(this.records);
        this.newRecordsDialogOpened = false;
        this.templateCreator = this.templateCreator || false;
        this.templateEdit = this.templateEdit || false;
        if (this.templateCreator) {
            this.goToEditMode();
        }
        this.pagination.entries = this.records.length;
        this.isDnsTemplateNew = this.$state.current.name === 'dns.templates.new';
    }

    public set targetIsDnsTemplate({}) {} // tslint:disable-line:no-empty
    public get targetIsDnsTemplate() {
        return this.templateCreator || this.templateEdit;
    }

    public get pageOfRecords() {
        let changedRecords;
        let unChangedRecords;
        const currentPage = this.pagination.currentPage - 1;
        unChangedRecords = this.getUnChangedRecords().filter(this.recordIsVisible);
        this.pagination.entries = unChangedRecords.length;
        changedRecords = this.getChangedRecords();
        if (unChangedRecords.length <= (currentPage * this.pagination.limit)) {
            this.pagination.currentPage = 0;
        }
        unChangedRecords = unChangedRecords.slice(
            currentPage > 0 ? (currentPage * this.pagination.limit) : 0,
            currentPage > 0 ? ((currentPage + 1) * this.pagination.limit) : this.pagination.limit
        );
        return changedRecords.concat(unChangedRecords);
    }

    public nextPage = () => { // is used in template
        this.pagination.currentPage++;
    };

    public prevPage = () => { // is used in template
        this.pagination.currentPage--;
    };

    public isLastPage = () => {
        const unChangedRecords = this.getUnChangedRecords().filter(this.recordIsVisible);
        return ((this.pagination.currentPage) * this.pagination.limit) >= unChangedRecords.length;
    };

    public cancel = () => {
        if ([null, undefined, ''].indexOf(this.$state.params.fromZoneEdit) === -1) {
            this.navigation.go(this.$state.params.fromZoneEdit, {zoneId: this.zone.id}, {reload: true});
        }
        this.editMode = false;
        this.recordsHaveBeenSplit = false;
        this.records = ng.copy(this.originalRecords);
        this.recordsMarkedForDeletion = [];
        this.changedRecords = [];
    };

    public get showSearchPanel() {
        return this._showSearchPanel;
    }

    public recordIsVisible = (record: any) => {
        if (!this.showSearchPanel) {
            return true;
        }
        if (this.changedRecords.indexOf(record.id) >= 0 || this.addedRecords.indexOf(record.id) >= 0) {
            return true;
        }
        const recordName = record.name + '.' + this.zone.nameUnicode;

        // Skip entries that are being edited.
        if (record.isBeingEdited !== undefined) {
            return true;
        }

        // Filter by name.
        if (
            [undefined, null, ''].indexOf(this.activeNameSearch) < 0
            && recordName.indexOf(this.activeNameSearch) < 0
        ) {
            return false;
        }

        // Filter by content.
        if (
            [undefined, null, ''].indexOf(this.activeContentSearch) < 0
            && record.content.indexOf(this.activeContentSearch) < 0
        ) {
            return false;
        }

        // Filter by type.
        if ([undefined, null, ''].indexOf(this.activeTypeSearch) < 0 && this.activeTypeSearch !== record.type) {
            return false;
        }

        return true;
    };

    public goToEditMode = () => {
        this.editMode = true;
    };

    public saveButtonClick = () => {
        this.$editForm.save();
    };

    public searchButtonClick = () => {
        this.$timeout( () => {
                if (!this.showSearchPanel) {
                    this._showSearchPanel = true;
                } else {
                    this.activeNameSearch = this.temporaryNameSearch;
                    this.activeContentSearch = this.temporaryContentSearch;
                    this.activeTypeSearch = this.temporaryTypeSearch;
                }
            }
        );
    };

    public resetSearch = () => {
        this.$timeout( () => {
            this.activeNameSearch = '';
            this.activeTypeSearch = '';
            this.activeContentSearch = '';
            this.temporaryNameSearch = '';
            this.temporaryTypeSearch = undefined;
            this.temporaryContentSearch = '';
        });
    };

    public get disableSaveButton() {
        return this.$editForm.$invalid || this.recordsInEditMode > 0;
    }

    public addEmptyRecord = () => {
        let ttl = '86400';
        if ([undefined, null].indexOf(this.zone) < 0
            && [undefined, null].indexOf(this.zone.soaValues) < 0
            && [undefined, null].indexOf(this.zone.soaValues.ttl) < 0) {
            ttl = `${this.zone.soaValues.ttl}`;
        }

        const id = 'newRecord_' + this.addedRecords.length;
        const emptyRecord = {
            content: '',
            id: id,
            name: '',
            priority: '',
            ttl: ttl,
            type: 'A'
        };
        this.addedRecords.push(id);
        this.records = [emptyRecord].concat(this.records);
    };

    public showReplacements = () => {
        return this.editMode && this.templateEdit;
    };

    private getChangedRecords = () => {
        return this.records.filter(this.recordHasChanged);
    };

    private getUnChangedRecords = () => {
        return this.records.filter((record) => {
            return !this.recordHasChanged(record);
        });
    };

    private recordHasChanged = (record: any) => {
        return this.changedRecords.indexOf(record.id) >= 0
            || this.recordsMarkedForDeletion.indexOf(record.id) >= 0
            || this.addedRecords.indexOf(record.id) >= 0;
    };
}

export class OrganEditPanelDnsRecordsComponent implements ng.IComponentOptions {
    public bindings = {
        acceptPlaceholders: '<',
        addedRecords: '=',
        changedRecords: '=',
        editMode: '=',
        records: '=',
        recordsMarkedForDeletion: '=',
        replacements: '<',
        templateCreator: '<',
        templateEdit: '<',
        zone: '<'
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public template = require('./dns-records.html');
    public controller = OrganEditPanelDnsRecordsController;
}
