import * as Sentry from '@sentry/browser';
import {
    RpcClient,
    RpcClientService
} from '../rpc-client';
import * as Types from '../../types';
import { WebhostingApi } from '../../types';

export const WebhostingClientService = [
    'rpcClient',
    (platformRpcClient: RpcClientService) => platformRpcClient.Factory('/api/webhosting/v1/json/', 'webhosting')
];

export class WebhostingRobotService {
    public static $inject: string[] = ['webhostingClient'];

    constructor(private webhostingClient: RpcClient) {}

    public appInstallerTemp(vhostId, data) {
        return this.webhostingClient.request('appInstallerTemp')
        .execute({vhostId: vhostId, json: JSON.stringify(data)});
    }

    public listWebspaces(filter?, limit?, page?, sort?) {
        return this.webhostingClient.request('webspacesFind')
        .execute({filter: filter, limit: limit, page: page, sort: sort});
    }

    public listWebspacesWithoutPagination(filter?, limit?, page?, sort?) {
        return this.webhostingClient.request('webspacesFind')
        .skipPagination()
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public createWebspace(webspace, accesses, owner, poolId, webserverId) {
        if ([undefined, null].indexOf(webspace.accountId) < 0) {
            if ([undefined, null].indexOf(owner) < 0 && webspace.accountId === owner) {
                // bug fix: create webspace for user that does not have the rights to create a webspace
                owner = undefined;
            }
        }
        return this.webhostingClient.request('webspaceCreate')
        .bustCache('billing', 'depositGet')
        .ownerAccountId(owner)
        .execute({ webspace: webspace, accesses: accesses, poolId: poolId, webserverId: webserverId });
    }

    public updateWebspace(webspace, accesses, expectedCost: boolean|number = false) {
        if (expectedCost !== false) {
            return this.webhostingClient.request('webspaceUpdate')
                .execute({ webspace: webspace, accesses: accesses, expectedCost: expectedCost });
        }
        return this.webhostingClient.request('webspaceUpdate')
        .execute({ webspace: webspace, accesses: accesses });
    }

    public setMinPhpVersion(webspaceId: string, phpVersion: string) {
        return this.webhostingClient.request('webspaceRaisePhpVersion')
        .execute({ webspaceId: webspaceId, minimumPhpVersion: phpVersion });
    }

    public deleteWebspace(webspaceId, execDate) {
        if (execDate === null) {
            return this.webhostingClient.request('webspaceDelete')
            .execute({ webspaceId: webspaceId });
        }
        return this.webhostingClient.request('webspaceDelete')
        .execute({ webspaceId: webspaceId, execDate: execDate });
    }

    public cancelDeleteWebspace(webspaceId: string): PromiseLike<any> {
        return this.webhostingClient.request('webspaceDeletionCancel')
            .bustCache('webspace', 'webspaceFind')
            .execute({ webspaceId: webspaceId });
    }

    public listVHosts(filter?, limit?, page?, sort?) {
        return this.webhostingClient.request('vhostsFind')
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public listVHostsWithoutPagination(filter?, limit?, page?, sort?) {
        return this.webhostingClient.request('vhostsFind')
        .skipPagination()
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public createVHost(vhost, phpIni, sslPrivateKey, httpUsers, owner?) {
        if ([undefined, null].indexOf(vhost.accountId) < 0) {
            if ([undefined, null].indexOf(owner) < 0 && vhost.accountId === owner) {
                // bug fix: create webspace for user that does not have the rights to create a webspace
                owner = undefined;
            }
        }
        return this.webhostingClient.request('vhostCreate')
        .ownerAccountId(owner)
        .execute({ vhost: vhost, phpIni: phpIni, sslPrivateKey: sslPrivateKey, setHttpUserPasswords: httpUsers });
    }

    public updateVHost(vhost, phpIni, sslPrivateKey, httpUsers, expectedCosts) {
        return this.webhostingClient.request('vhostUpdate')
        .execute({ vhost: vhost, phpIni: phpIni, sslPrivateKey: sslPrivateKey, setHttpUserPasswords: httpUsers, expectedCosts: expectedCosts });
    }

    public deleteVHost(vhostId) {
        return this.webhostingClient.request('vhostDelete')
        .execute({ vhostId: vhostId });
    }

    public restoreVHost(vhostId) {
        return this.webhostingClient.request('vhostRestore')
        .execute({ vhostId: vhostId });
    }

    public purgeRestorableVHost(vhostId) {
        return this.webhostingClient.request('vhostPurgeRestorable')
        .execute({ vhostId: vhostId });
    }

    public phpIniDefaultVHost(webspaceId, phpVersion) {
        return this.webhostingClient.request('vhostPhpIniDefault')
        .useCache()
        .cacheTimeout(1000 * 60 * 15)
        .execute({ webspaceId: webspaceId, phpVersion: phpVersion });
    }

    public phpIniListVHost(vhostId) {
        return this.webhostingClient.request('vhostPhpIniList')
        .execute({ vhostId: vhostId });
    }

    public phpConfigurationMetadata() {
        return this.webhostingClient.request('phpConfigurationMetadata')
        .execute({ all: true });
    }

    public listProfiles(webspaceId, productCode) {
        return this.webhostingClient.request('profilesList')
        .execute({ webspaceId: webspaceId, productCode: productCode });
    }

    public listUsers(filter?, limit?, page?, sort?, owner?) {
        return this.webhostingClient.request('usersFind')
        .ownerAccountId(owner)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public listUsersWithoutPagination(filter?, limit?, page?, sort?, owner?) {
        return this.webhostingClient.request('usersFind')
        .skipPagination()
        .ownerAccountId(owner)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public createUser(
        user: WebhostingApi.User,
        password: string,
        // owner is deprecated: set accountId in the user object!
        owner?: string
    ) {
        // PUI-6398 user can now be set in the user object, this is a less favorable hack for
        // backwards compatibility so make sure nothing will break
        if (owner !== undefined && owner !== user.accountId) {
            user.accountId = owner;

            Sentry.captureMessage(
                'deprecated way to call webhostingApi::userCreate',
                {
                    extra: { user: user },
                    tags: { key: 'webhosting' }
                }
            );
        }

        return this.webhostingClient.request('userCreate')
            .execute({ user: user, password: password });
    }

    public updateUser(user: WebhostingApi.User, password: string) {
        return this.webhostingClient.request('userUpdate')
            .execute({ user: user, password: password });
    }

    public deleteUser(userId) {
        return this.webhostingClient.request('userDelete')
        .execute({ userId: userId });
    }

    public listJobs(filter?, limit?, page?, sort?) {
        return this.webhostingClient.request('jobsFind')
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public listRedirections = (filter?, limit?, page?, sort?) => {
        return this.webhostingClient.request('redirectionsFind')
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public listRedirectionsWithoutPagination = (filter?, limit?, page?, sort?) => {
        return this.webhostingClient.request('redirectionsFind')
        .skipPagination()
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public createRedirection(redirection, owner) {
        return this.webhostingClient.request('redirectionCreate')
        .bustCache('billing', 'depositGet')
        .ownerAccountId(owner)
        .execute({ redirection: redirection });
    }

    public updateRedirection(redirection) {
        return this.webhostingClient.request('redirectionUpdate')
        .execute({ redirection: redirection });
    }

    public deleteRedirection(redirection, domainName, execDate) {
        if (execDate === null) {
            return this.webhostingClient.request('redirectionDelete')
            .execute({ redirectionId: redirection, domainName: domainName });
        }
        return this.webhostingClient.request('redirectionDelete')
        .execute({ redirectionId: redirection, domainName: domainName, execDate: execDate });
    }

    public purgeRestorable(webspaceId) {
        return this.webhostingClient.request('webspacePurgeRestorable')
        .execute({ webspaceId: webspaceId });
    }

    public restoreWebspace(webspaceId) {
        return this.webhostingClient.request('webspaceRestore')
        .bustCache('billing', 'depositGet')
        .execute({ webspaceId: webspaceId });
    }

    public disableWebspace({}, webspaceId, restriction) {
        return this.webhostingClient.request('webspaceCreateRestriction')
        .execute({ webspaceId: webspaceId, restriction: restriction });
    }

    public webspaceUpdateRestriction(webspaceId, restriction) {
        return this.webhostingClient.request('webspaceUpdateRestriction')
        .execute({ webspaceId: webspaceId, restriction: restriction });
    }

    public enableWebspace(webspaceId, restrictionId) {
        return this.webhostingClient.request('webspaceDeleteRestriction')
        .execute({ webspaceId: webspaceId, restrictionId: restrictionId });
    }

    public emailSenderActivate(webspaceId) {
        return this.webhostingClient.request('webspaceEmailSenderActivate')
        .execute({ webspaceId: webspaceId });
    }

    public emailSenderDefer(webspaceId) {
        return this.webhostingClient.request('webspaceEmailSenderDefer')
        .execute({ webspaceId: webspaceId });
    }

    public emailSenderDisable(webspaceId) {
        return this.webhostingClient.request('webspaceEmailSenderDisable')
        .execute({ webspaceId: webspaceId });
    }

    public availablePhpVersions() {
        return this.webhostingClient.request('phpVersions')
        .execute({});
    }

    public vhostNormalize = (
        vhost: Types.WebhostingApi.VHost,
        phpIni?: Types.WebhostingApi.PhpIni,
        changePhpVersion?: string
    ) => {
        return this.webhostingClient.request('vhostNormalize')
        .execute({vhost: vhost, phpIni: phpIni, changePhpVersion: changePhpVersion});
    };
}
