import { LinkListOverviewItem } from '../../atomic-components/molecules/links/link-list-overview/link-list-overview';
import {
    AccountFilterField,
    SelectFilterField,
    TextFilterField
} from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { OverviewModel } from '../abstract';
import { RequestCancellerService } from '../request-canceller';

export class ManagedMachineOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', '$state', 'machineModel']
    );

    public readonly service = 'machine-managed';
    public readonly objectType = 'ManagedMachine';
    public readonly idField = 'virtualMachineId';
    public readonly defaultFilterFields = ['VirtualMachineName'];

    public actionLinksAvailabilities = {};
    public activeMassAction: string | boolean = false;
    public selected = {};
    public selectedAll = false;

    protected listCallbacks = [];
    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private $state: ng.ui.IStateService,
        private machineModel: any
    ) {
        super(RequestCanceller);

        this.listCallbacks = [{
            callback: this.listManagedMachines
        }];
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
            //     new TextFilterField('virtualMachineName', this.$translate.instant('TR_150119-f90338_TR')),
            //     new TextFilterField('virtualMachineId', this.$translate.instant('VM.VM.ID')),
            //     new TextFilterField('virtualMachineIpAddress', this.$translate.instant('VM.VM.IP')),
            //     new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR')),
            //     new SelectFilterField(
            //         'virtualMachineRescue',
            //         this.$translate.instant('VM.GENERAL.OVERVIEW.ACTION.RESCUE'),
            //         [
            //             {
            //                 name: this.$translate.instant('VM.POWER.ON'),
            //                 value: 'on'
            //             }, {
            //                 name: this.$translate.instant('VM.POWER.OFF'),
            //                 value: 'off'
            //             }
            //         ]
            //     ),
            //     new SelectFilterField(
            //         'virtualMachinePower',
            //         this.$translate.instant('VM.GENERAL.OVERVIEW.ACTION.POWER'),
            //         [
            //             {
            //                 name: this.$translate.instant('VM.POWER.ON'),
            //                 value: 'on'
            //             }, {
            //                 name: this.$translate.instant('VM.POWER.OFF'),
            //                 value: 'off'
            //             }
            //         ]
            //     ),
            //     new SelectFilterField(
            //         'virtualMachineStatus',
            //         this.$translate.instant('TR_140119-8e4b60_TR'),
            //         [
            //             {
            //                 name: this.$translate.instant('35b2882a-2c6b-4291-b7de-963835933109'),
            //                 value: 'active'
            //             }, {
            //                 name: this.$translate.instant('VM.STATUS.CREATING'),
            //                 value: 'creating'
            //             }, {
            //                 name: this.$translate.instant('VM.STATUS.DELETING'),
            //                 value: 'deleting'
            //             }, {
            //                 name: this.$translate.instant('GENERAL.OBJECT.STATUS.RESTORABLE'),
            //                 value: 'restorable'
            //             }
            //         ]
            //     )
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }

    public actionLinks = (): LinkListOverviewItem[] => {
        return [
            {
                route: 'machine.virtualmachines.new',
                text: this.$translate.instant('TR_140119-9b5fa4_TR')
            }
        ] as LinkListOverviewItem[];
    };

    public listManagedMachines = (limit?, page?, filters?, sort?, cancel?) => {
        const filter = {
            field: 'virtualMachineManagementType',
            value: 'platform'
        };
        if ([undefined, null].indexOf(filters) < 0) {
            filters = {
                subFilter: [
                    filters,
                    filter
                ],
                subFilterConnective: 'AND'
            };

        } else {
            filters = filter;
        }

        if (this.$state.current.name.split('.').indexOf('dashboard') >= 0) {
            return this.machineModel.listWithoutPagination(limit, page, filters, sort, cancel);
        } else {
            return this.machineModel.list(limit, page, filters, sort, cancel);
        }
    };
}
