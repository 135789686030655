import ng from 'angular';

import { BillingCycleToContractPeriodeConst } from '@/configuration/system';
import { getVatAmount } from '@/services/get-vat-amount';

import { CalculatePrice } from '../filters';
import { AccountHelperService, AuthContextService } from '../services';
import * as Types from '../types';

export type CalculatePriceTaxType = {
    tax: number;
    vatRate: number;
};
export type CalculatePriceTaxFilter = (
    priceObject: Types.BillingApi.ArticlePurchasePrice,
    account?: Types.AccountApi.Account | Types.AccountApi.Subaccount,
    quantity?: number,
    showPromotionPrice?: boolean
 ) => CalculatePriceTaxType;

export class CalculatePriceTax {
    public static $inject: string[] = ['accountHelper'];

    private static _taxObject: CalculatePriceTaxType = {
        tax: 0,
        vatRate: 0
    };

    public static Factory(accountHelper: AccountHelperService): CalculatePriceTaxFilter {
        return (priceObject, account, quantity, showPromotionPrice) => {
            account = account || AuthContextService.account;
            quantity = quantity || 1;

            if (
                accountHelper.isRootAdmin(account) // logged in as root admin user
                || priceObject.netAmount === -1 // Account does not have the necessary right to see prices.
                || !ng.isNumber(priceObject.netAmount)
            ) {
                return CalculatePriceTax._taxObject;
            }
            return CalculatePriceTax.calculateTax(
                priceObject,
                quantity,
                showPromotionPrice
            );
        };
    }

    public static calculateTax(priceObject: Types.BillingApi.ArticlePurchasePrice, quantity, showPromotionPrice) {
        let netPriceTotal: number;
        const exchangeRatio: number = priceObject.exchangeRatio
            ? priceObject.exchangeRatio.exchangeRatio
            : 10000.0;

        netPriceTotal = showPromotionPrice && priceObject.promotionalNetAmount
            ? priceObject.promotionalNetAmount * quantity
            : netPriceTotal = priceObject.netAmount * quantity;

        netPriceTotal = netPriceTotal / (exchangeRatio / 10000.0);

        const totalPriceTax = getVatAmount(
            new Date(),
            BillingCycleToContractPeriodeConst[priceObject.contractPeriod],
            netPriceTotal,
            AuthContextService.account.country,
            priceObject.amounts[0].vatRate
        );

        CalculatePriceTax._taxObject.tax = CalculatePrice.roundDigits(totalPriceTax / 10000, 4, 2);
        CalculatePriceTax._taxObject.vatRate = priceObject.amounts[0].vatRate / 100;

        return CalculatePriceTax._taxObject;
    }

    public static _calculateTax(priceObject, quantity, showPromotionPrice) {
        const vatRate = priceObject.vatRate;
        let netPriceTotal: number;
        const exchangeRatio: number = priceObject.exchangeRatio
            ? priceObject.exchangeRatio.exchangeRatio
            : 10000.0;

        netPriceTotal = showPromotionPrice && priceObject.promotionalPrice
            ? priceObject.promotionalPrice * quantity
            : netPriceTotal = priceObject.price * quantity;

        /**
         * The API returns the net price, multiplied with 10000 (to include 4 digits after decimal point),
         * as an Integer. This is done to avoid floating point errors during transmissions.
         */

        netPriceTotal = CalculatePrice.roundDigits(netPriceTotal / exchangeRatio, 4, 2);

        const vatMultiplier = vatRate / 10000.0;
        const totalPriceTax = (netPriceTotal * vatMultiplier);

        /**
         * Recursively round from four to two digits after decimal point again. Return result.
         */
        CalculatePriceTax._taxObject.tax = CalculatePrice.roundDigits(totalPriceTax, 4, 2);
        CalculatePriceTax._taxObject.vatRate = vatMultiplier * 100;

        return CalculatePriceTax._taxObject;
    }
}
