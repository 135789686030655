import * as ng from 'angular';
import { WizardObject } from '../../wizard-supervisor/wizard-supervisor';

export class MoleculeWizardSummaryItemDatabaseController implements ng.IController {
    public static $inject: string[] = ['$translate'];
    public serviceObject: WizardObject;
    private accessLevels: any[] = [];

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {
        this.accessLevels = [
            {
                label: this.$translate.instant('TR_100119-bd2ddd_TR'),
                value: 'read'
            }, {
                label: this.$translate.instant('TR_100119-f68828_TR'),
                value: 'write'
            }, {
                label: this.$translate.instant('TR_100119-799df6_TR'),
                value: 'schema'
            }
        ];
    }

    public getAccessName() {
        return this.serviceObject.display.newDatabaseUserObject.name;
    }

    public getAccessRights() {
        const rights = [];

        this.accessLevels.map(level => {
            if (this.serviceObject.display.newAccessObject[level.value]) {
                rights.push(level.label);
            }
        });

        return rights.join(', ');
    }
}

export class MoleculeWizardSummaryItemDatabaseComponent implements ng.IComponentOptions {
    public bindings = {
        serviceObject: '='
    };
    public controller =  MoleculeWizardSummaryItemDatabaseController;
    public template = require('./wizard-summary-database.html');
}
