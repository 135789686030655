import * as ng from 'angular';
import { LinkListOverviewItem } from '../../atomic-components/molecules/links/link-list-overview/link-list-overview';
import {
    AccountFilterField,
    DateFilterField,
    SelectFilterField,
    TextFilterField
} from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { UiRights } from '../../configuration';
import { AuthContextService } from '../../services';
import { OverviewModel } from '../abstract';
import { RequestCancellerService } from '../request-canceller';
import { SslCertificateModelService } from './ssl-certificate-model';

export class SslOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', 'sslCertificateModel', '$state', 'authContext', 'objectStatusFilter']
    );

    public readonly service = 'ssl.certificates';
    public readonly objectType = 'Ssl-Certificate';
    public readonly idField = 'certificateId';
    public readonly defaultFilterFields = ['certificateCommonName'];

    public activeMassAction: string | boolean = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};

    protected listCallbacks = [];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private sslCertificateModel: SslCertificateModelService,
        private $state: ng.ui.IStateService,
        private authContext: AuthContextService,
        private objectStatusFilter
    ) {
        super(RequestCanceller);

        // declare multi action availably methods
        this.actionLinksAvailabilities = {};

        this.listCallbacks = [{callback: this.listCertificates}];
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('CertificateCommonName', this.$translate.instant('TR_150119-5f873a_TR')),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR')),
                new SelectFilterField('CertificateStatus', this.$translate.instant('TR_140119-8e4b60_TR'), [
                    {
                        name: this.objectStatusFilter('active'),
                        value: 'active'
                    },
                    {
                        name: this.objectStatusFilter('creating'),
                        value: 'creating'
                    },
                    {
                        name: this.objectStatusFilter('pendingReissue'),
                        value: 'pendingReissue'
                    },
                    {
                        name: this.objectStatusFilter('pendingRevoke'),
                        value: 'pendingRevoke'
                    },
                    {
                        name: this.objectStatusFilter('revoked'),
                        value: 'revoked'
                    }
                ]),
                new DateFilterField(
                    'CertificateStart',
                    this.$translate.instant('f7eaa5b6-341d-415a-823c-df97c1651050')
                ),
                new DateFilterField(
                    'CertificateEnd',
                    this.$translate.instant('a02a518a-7423-4e06-9ac4-08a917d76517')
                )
            ];
        }

        return  this._cachedAvailableFilters;
    }

    // tslint:disable-next-line:no-empty
    public set availableFilters({}) {}

    public actionLinks = (): LinkListOverviewItem[] => {
        return [
            {
                isGranted: this.authContext.isGrantedAny([UiRights.SSL_JOBS_LIST]),
                linkList: [
                    {
                        group: 'redirect',
                        text: this.$translate.instant('MENU.CONTEXT.JOBS'),
                        route: 'ssl.certificates.jobs',
                        isGranted: this.authContext.isGranted(UiRights.SSL_JOBS_LIST)
                    }
                ],
                route: '',
                text: this.$translate.instant('TR_100119-a22157_TR'),
                type: 'multi'
            },
            {
                route: 'ssl.certificates.new',
                text: this.$translate.instant('TR_150119-714407_TR')
            }
        ] as LinkListOverviewItem[];
    };

    public listCertificates = (limit?, page?, filters?, sort?) => {
        if (sort === undefined || sort === null) {
            sort = {
                field: 'certificateAddDate',
                order: 'DESC'
            };
        }

        return this.$state.current.name.split('.').indexOf('dashboard') >= 0
        ? this.sslCertificateModel.listWithoutPagination(limit, page, filters, sort)
        : this.sslCertificateModel.list(limit, page, filters, sort);
    };
}
