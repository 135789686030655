import * as ng from 'angular';
import { WizardObject } from '../../wizard-supervisor/wizard-supervisor';

export class MoleculeWizardSummaryItemVhostController implements ng.IController {
    public static $inject: string[] = ['$translate'];
    public serviceObject: WizardObject;
    private _vhostType: string | null = null;

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {}

    public get isTypeWebsite() {
        return this._vhostType === this.$translate.instant('TR_100119-e6d165_TR');
    }

    public getProfileName() {
        if ([undefined, null, ''].indexOf(this.serviceObject.settings.vhost.profile) >= 0) {
            return this.$translate.instant('TR_100119-d642b2_TR');
        }

        const profile = this.serviceObject.display.profiles.filter(
            (currentProfile) => currentProfile.profileId === this.serviceObject.settings.vhost.profile
        );

        return (profile[0] || {}).name;
    }

    public get vhostType() {
        if (this._vhostType !== null) {
            return this._vhostType;
        }

        const defaultLocation = this.serviceObject.settings.vhost.locations.filter(
            (location) => location.matchType === 'default'
        );

        if (defaultLocation.length === 0) {
            return '';
        }

        if (defaultLocation[0].locationType === 'generic') {
            this._vhostType = this.$translate.instant('TR_100119-e6d165_TR');
        } else {
            this._vhostType = this.$translate.instant('TR_100119-688348_TR');
        }

        return this._vhostType;
    }

    public get domainType() {
        if (this.serviceObject.display.domainType !== undefined) {
            return this.serviceObject.display.domainType;
        }

        return this.$translate.instant('TR_100119-a9707e_TR');
    }
}

export class MoleculeWizardSummaryItemVhostComponent implements ng.IComponentOptions {
    public bindings = {
        serviceObject: '='
    };
    public controller =  MoleculeWizardSummaryItemVhostController;
    public template = require('./wizard-summary-vhost.html');
}
