import { PanelHeaderData } from '../../../atomic-components/molecules';

import * as ng from 'angular';

export class TemplateSslCertificateWizardController {
    public static $inject: string[] = ['$translate'];

    public modifier = '';
    public pageHeaderData: PanelHeaderData = {
        backwardLink: 'ssl.dashboard',
        backwardText: this.$translate.instant('TR_140119-5574f2_TR'),
        panelHeaderRoute: '',
        panelHeaderRouteParams: null,
        panelIcon: 'shield',
        panelTitle: this.$translate.instant('TR_140119-473d8e_TR')
    };

    constructor(private $translate: ng.translate.ITranslateService) {}
}

export class TemplateSslCertificateWizardComponent implements ng.IComponentOptions {
    public template = require('./ssl-certificate-wizard-template.html');
    public controller = TemplateSslCertificateWizardController;
}
