import * as ng from 'angular';

import { ILightboxImage } from '@/atomic-components/molecules/overlay/lightbox/lightbox';
import { ContractPeriodWord, CycleBillingTranslationConst, UiRights } from '@/configuration';
import { AuthContextService, NextcloudHelperService, PriceCacheService, ProductsModelService } from '@/services';
import * as Types from '@/types';

export class DynamicFormController implements ng.IComponentController {
    public static $inject: string[] = [
        'nextcloudHelper',
        'priceCache',
        'productsModel',
        '$translate'
    ];

    public saveCallback: (
        formData: Types.FormDescriptionSpec.FieldObject[],
        enabled: boolean
    ) => void;
    public toggleAppInstallationCallback: (state: boolean) => void;
    public cancelCallback: () => void;
    public keenInput: Types.FormDescriptionSpec.FormDescriptionLayout;
    public uiLayout: Types.FormDescriptionSpec.UiLayout;
    public keenData: Types.FormDescriptionSpec.FieldObject[];
    public descriptionTitle: string;
    public appCurrentConfig: Record<string, unknown>;
    public disabledSaveButton = false;
    public app: Types.ManagedApplicationApi.NextcloudApp;
    public appHasUserConfig = false;
    public requiresConfiguration: boolean;
    public priceSuffixText = '';

    public userHasRightAdminSystemSuperUserRead = AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_SUPER_USER_READ);
    public nextcloud: Types.ManagedApplicationApi.Nextcloud;
    public appConfig: Types.ManagedApplicationApi.NextcloudAppConfigInput[];
    public originalNextcloud: Types.ManagedApplicationApi.Nextcloud;
    public nextcloudProduct: Types.ProductApi.AbstractProduct;

    public componentInitialized = false;

    public nextcloudHasUnfinishedJob = false;
    public nextcloudHasSupportJob = false;

    public isAppEnabled = false;
    public lightboxImages: ILightboxImage[] = [];
    public quotaChangeConfirmed = false;

    private isPaid = false;
    private _toggleAppInstallationState = false;
    private _bookingConfirmation = false;

    public addonProduct: Types.ProductApi.Product;

    public panelRight = {
        editPanelButton: AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT)
    };

    public isBusinessProduct = false;
    public isFreeProduct = false;

    public constructor(
        public nextcloudHelper: NextcloudHelperService,
        private priceCache: PriceCacheService,
        private productsModel: ProductsModelService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = (): void => {
        this.descriptionTitle = this.$translate.instant('9f5a890f-3448-416a-9052-7e624c7be78a');
        this.isPaid = this.app?.productCode?.length > 0;

        if (this.isPaid) {
            void this.priceCache.getPriceByProductCode(this.app.productCode).then(
                (response: Types.BillingApi.ArticlePurchasePrice[]) => {
                    if (response.length === 1) {
                        this.priceSuffixText = `/ ${this.$translate.instant(
                            CycleBillingTranslationConst[response[0].contractPeriod as ContractPeriodWord]
                        )}`;
                    }
                }
            );
        }

        this.isBusinessProduct = this.nextcloud.productCode.includes('business');
        this.isFreeProduct = this.nextcloud.productCode.includes('free');
        this.originalNextcloud = ng.copy(this.nextcloud);
        this.isAppEnabled = this.nextcloud.enabledApps.includes(this.app.id);
        this.lightboxImages = this.app.images.map((image, i) => {
            const altImageText = this.$translate.instant('TR_010621-a206e5_TR', {
                appName: this.app.name,
                index: i.toString()
            });
            return { src: '/staticfiles/' + image, alt: altImageText };
        });

        /**
         * WARNING: Return stops execution...
         */
        if (!this.keenInput) {
            return;
        }

        this.uiLayout = this.keenInput.uiLayout || [];
        this.keenData = this.keenInput.data.fields || [];
        this.keenData.forEach((dataField) => {
            if (this.appCurrentConfig && undefined !== this.appCurrentConfig[dataField.key]) {
                dataField.value = this.appCurrentConfig[dataField.key];
            } else if (undefined === dataField.value && undefined !== dataField.default) {
                dataField.value = dataField.default as unknown;
            }
        });
    };

    public keenDataCallback = (value: unknown, key: string): void => {
        if (Array.isArray(this.keenData)) {
            this.keenData.forEach((field) => {
                if (field.key === key) {
                    field.value = value;
                }
            });
        }
    };

    public save = (): void => {
        if (this.saveCallback) {
            this.saveCallback(
                this.keenData,
                this.isAppEnabled
                    ? !this._toggleAppInstallationState
                    : this._toggleAppInstallationState
            );
        }
    };

    public cancel = (): void => {
        if (this.cancelCallback) {
            this.cancelCallback();
        }
    };

    public get toggleAppInstallationState(): boolean {
        return this._toggleAppInstallationState;
    }

    public set toggleAppInstallationState(value: boolean) {
        this._toggleAppInstallationState = value;
        this.toggleAppInstallationCallback(value);
    }

    public get bookingConfirmation(): boolean {
        if (this.app?.productCode) {
            return this._bookingConfirmation;
        }
        return true;
    }

    public set bookingConfirmation(value) {
        this._bookingConfirmation = value;
    }

    public get isSaveButtonDisabled(): boolean {
        if (this.isAppEnabled && this._toggleAppInstallationState) {
            return false;
        }
        if ((this.app.requiresConfiguration || this.appHasUserConfig) && this.appEnabledAfterUserChange) {
            return false;
        }
        return this.disabledSaveButton;
    }

    public get appEnabledAfterUserChange(): boolean {
        return this.isAppEnabled !== this.toggleAppInstallationState;
    }
}

export class DynamicFormComponent implements ng.IComponentOptions {
    public transclude = {
        postAppEnable: '?postAppEnable'
    };
    public bindings = {
        app: '<',
        appCurrentConfig: '<',
        cancelCallback: '<',
        disabledSaveButton: '<',
        keenInput: '<',
        nextcloud: '<',
        saveCallback: '<',
        toggleAppInstallationCallback: '<',
        quotaChangeConfirmed: '<'
    };
    public controller = DynamicFormController;
    public template = require('./dynamic-form.html');
}
