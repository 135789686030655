import * as ng from 'angular';

import { FormDescriptionSpec } from '@/types/view-types/form-description/form-description';

export class FormElementDisplayTextController implements ng.IComponentController {
    public static $inject: string[] = [];

    public keenInput: FormDescriptionSpec.ElementSimpleText | FormDescriptionSpec.ElementCalloutText;

    public heading: string;
    public content: string;
    public displayType?: 'information' | 'success' | 'warning' | 'error' | string;

    public $onInit = () => {
        this.heading = this.keenInput.heading || '';
        this.content = this.keenInput.content || '';
        this.displayType = (this.keenInput as FormDescriptionSpec.ElementCalloutText).displayType || '';
    };
}

export class FormElementDisplayTextComponent implements ng.IComponentOptions {
    public bindings = {
        keenInput: '<'
    };
    public controller = FormElementDisplayTextController;
    public template = require('./form-element-text.html');
}
