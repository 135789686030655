import ng from 'angular';
import { UiRights } from '../../../../configuration';
import { AuthContextService } from '../../../../services';
import { Breadcrumb, BreadcrumbItem } from '../breadcrumb';
import { BreadcrumbTopicModel, TopicCases } from './topic-model';

export class BreadcrumbDnsTopicModel extends BreadcrumbTopicModel {
    public static $inject: string[] = BreadcrumbTopicModel.$inject.concat([]);

    public readonly topicName = 'dns';
    public readonly specialTopicCases: TopicCases;

    /**
     *   List of route parts, which are excluded from mail class generalRoutes methode
     */
    public readonly excludeGeneralRouteParts = ['overview', 'edit', 'new'];

    private dnsActionDropdownItems: BreadcrumbItem[] = [];

    constructor(protected $state: ng.ui.IStateService, protected $translate: ng.translate.ITranslateService) {
        super($state, $translate);

        this.dnsActionDropdownItems = [
            { part: 'overview', route: 'dns.zones.overview', isGranted: UiRights.DNS_ZONES_LIST },
            { part: 'jobs', route: 'dns.zones.jobs', isGranted: UiRights.DNS_ZONES_LIST },
            { part: 'new', route: 'dns.zones.new', isGranted: UiRights.DNS_ZONES_CREATE },
            {
                isGranted: UiRights.DNS_NSSETS_LIST,
                name: this.$translate.instant('DOMAIN.NAMESERVER.SELECT-TYPES.NAMESERVER-SETS'),
                part: 'nameserversets',
                route: 'dns.nameserversets.overview'
            },
            {
                isGranted: UiRights.DNS_TEMPLATES_LIST,
                name: this.$translate.instant('MENU.CONTEXT.DNS.TEMPLATES'),
                part: 'templates',
                route: 'dns.templates.overview'
            },
            {
                isGranted: UiRights.DNS_DDNSHOSTNAME_LIST,
                name: this.$translate.instant('TR_130319-9c1524_TR'),
                part: 'hostnames',
                route: 'dns.ddns.hostnames.overview'
            },
            {
                isGranted: UiRights.DNS_DDNSCREDENTIALS_LIST,
                name: this.$translate.instant('TR_200319-418d09_TR'),
                part: 'credentials',
                route: 'dns.ddns.credentials.overview'
            },
            {
                isGranted: UiRights.DNS_ZONES_LIST,
                name: this.$translate.instant('MENU.CONTEXT.DNS.CONTENT-SEARCH'),
                part: 'content-search',
                route: 'dns.zones.content-search'
            },
            {
                isGranted: UiRights.DNS_ZONES_EDIT,
                name: this.$translate.instant('MENU.CONTEXT.DNS.CONTENT-CHANGER'),
                part: 'content-change',
                route: 'dns.zones.content-change'
            }
        ];

        this.specialTopicCases = {
            'content-change': () => this.getProductActionDropdown(this.dnsActionDropdownItems),
            'content-search': () => this.getProductActionDropdown(this.dnsActionDropdownItems),
            'copy': () => this.getCopyZoneRoute(),
            'credentials': () => this.getProductActionDropdown(this.dnsActionDropdownItems),
            'details': this.jobDetailsRoute,
            'edit': this.getEditRoute,
            'hostnames': () => this.getProductActionDropdown(this.dnsActionDropdownItems),
            'id': this.getIdRoute,
            'jobs': () => this.getProductActionDropdown(this.dnsActionDropdownItems),
            'nameserversets': () => this.getProductActionDropdown(this.dnsActionDropdownItems),
            'new': this.getNewDnsRoute,
            'overview': this.getOverviewsRoute,
            'records-edit': this.getRecordsEditRoute,
            'templates': () => this.getProductActionDropdown(this.dnsActionDropdownItems)
        };
    }

    public getIdRoute = () => {
        let breadcrumbs = [];
        if (this.steps[this.index + 1] === 'restrictions') {
            breadcrumbs = this.getProductActionDropdown(this.dnsActionDropdownItems, {}, 'overview');
            breadcrumbs.push({
                active: false,
                isGranted: UiRights.DNS_ZONES_EDIT,
                route: 'dns.zones.id.edit',
                routeParams: { domainId: this.globals.zone.id },
                type: 'text',
                value: this.getActiveDropdownValue({ part: 'edit' })
            });
            return breadcrumbs;
        }
    };

    public getFirstLevelData = (): Breadcrumb[] => {
        this.breadcrumbs.push({
            active: false,
            type: 'text',
            value: this.$translate.instant('MENU.MAIN.PRODUCTS.LABEL')
        });

        if (AuthContextService.isGranted(UiRights.DNS_ZONES_LIST)) {
            this.breadcrumbs = this.breadcrumbs.concat(
                this.getServiceListDropdownBox({
                    active: this.step === 'dns',
                    name: this.$translate.instant('STATE.DNS.ZONE.BREADCRUMB'),
                    route: 'dns.dashboard',
                    routeParams: {},
                    step: 'dns'
                })
            );
        }

        return this.breadcrumbs;
    };

    public getOverviewsRoute = (): Breadcrumb[] => {
        switch (this.steps[this.index - 1]) {
            case 'zones':
                return this.getProductActionDropdown(this.dnsActionDropdownItems);
            default:
                break;
        }
    };

    public getEditRoute = (): Breadcrumb[] => {
        let breadcrumbs: Breadcrumb[] = [];
        let route = '';
        let routeParams = {};

        if (this.steps[this.index - 2] === 'zones') {
            route = 'dns.zones.id.edit';
            routeParams = { domainId: this.globals.zone.id };
            breadcrumbs = this.getProductActionDropdown(this.dnsActionDropdownItems, routeParams, 'overview');
        }

        breadcrumbs.push({
            active: true,
            isGranted: UiRights.DNS_ZONES_EDIT,
            route: route,
            routeParams: routeParams,
            type: 'text',
            value: this.getActiveDropdownValue({ part: 'edit' })
        });
        return breadcrumbs;
    };

    public getCopyZoneRoute = (): Breadcrumb[] => [
        {
            active: true,
            isGranted: UiRights.DNS_ZONES_EDIT,
            route: '',
            routeParams: {},
            type: 'text',
            value: this.getActiveDropdownValue({ part: 'copy' })
        }
    ];

    public getRecordsEditRoute = (): Breadcrumb[] => {
        const breadcrumbs = [];
        const route = '';
        const routeParams = {};

        breadcrumbs.push({
            active: false,
            isGranted: UiRights.DNS_ZONES_EDIT,
            route: 'dns.zones.id.edit',
            routeParams: { domainId: this.globals.zone.id },
            type: 'text',
            value: this.globals.zone.name
        });

        breadcrumbs.push({
            active: true,
            isGranted: UiRights.DNS_ZONES_EDIT,
            route: route,
            routeParams: routeParams,
            type: 'text',
            value: this.$translate.instant('TR_080119-5c34c8_TR')
        });

        return breadcrumbs;
    };

    public getNewDnsRoute = (): Breadcrumb[] => {
        let breadcrumbs: Breadcrumb[] = [];
        const routeParams = {};

        switch (this.steps[this.index - 1]) {
            case 'zones':
                breadcrumbs = this.getProductActionDropdown(this.dnsActionDropdownItems, routeParams, 'new');
                break;
            case 'nameserversets':
            case 'templates':
                breadcrumbs = breadcrumbs.concat(this.getNewRoute());
                break;
            default:
                break;
        }

        return breadcrumbs;
    };
}
