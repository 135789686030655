/* eslint-disable */
import { LinkListOverviewItem } from '../../atomic-components/molecules/links/link-list-overview/link-list-overview';
import {
    AccountFilterField,
    SelectFilterField,
    TextFilterField
} from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { OverviewDataObject } from '../../atomic-components/organisms/overview/overview';
import { UiRights } from '../../configuration';
import { ListCallbacks, OverviewModel } from '../abstract';
import { AuthContextService } from '../auth-context';
import { RequestCancellerService } from '../request-canceller';
import { BundleModelService } from './';

export class BundleOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$state', '$translate', 'authContext', 'bundleModel', 'objectStatusFilter']
    );

    readonly service = 'bundles';
    readonly objectType = 'Bundle';
    readonly idField = 'bundleId';
    readonly defaultFilterFields = ['BundleName'];

    public activeMassAction: string | boolean = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};
    public overviewData: OverviewDataObject;
    public overviewUtilityChart = [];
    protected listCallbacks: ListCallbacks[] = [];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private bundleModel: BundleModelService,
        private objectStatusFilter
    ) {
        super(RequestCanceller);

        // declare multi action availably methods
        this.actionLinksAvailabilities = {};

        // declare multi action availably methods
        this.cutomizedModelListMethods = false;

        // Define listCallbacks
        if(this.listCallbacks === undefined) {
            this.listCallbacks = [];
        }
        this.listCallbacks.push({
            callback: this.listBundle
        });
    }

    public get availableFilters(): unknown {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('BundleName', this.$translate.instant('TR_140119-796b28_TR')),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR')),
                new SelectFilterField('BundleStatus', this.$translate.instant('TR_140119-8e4b60_TR'), [
                    {
                        name: this.objectStatusFilter('active'),
                        value: 'active'
                    },
                    {
                        name: this.objectStatusFilter('restorable'),
                        value: 'restorable'
                    },
                    {
                        name: this.objectStatusFilter('inProgress'),
                        value: 'inProgress'
                    }
                ]),
                new SelectFilterField('BundleDeletionScheduledFor', this.$translate.instant('TR_140119-c54036_TR'), [
                    {
                        name: this.$translate.instant('TR_140119-02e684_TR'),
                        value: '*'
                    },
                    {
                        name: this.$translate.instant('TR_140119-cc3e12_TR'),
                        value: ''
                    }
                ])
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }

    public listBundle = (limit?, page?, filters?, sort?, cancel?): unknown => {
        if (this.$state.current.name.split('.').indexOf('dashboard') >= 0) {
            return this.bundleModel.listWithoutPagination(limit, page, filters, sort, cancel);
        } else {
            return this.bundleModel.list(limit, page, filters, sort, cancel);
        }
    };

    public actionLinks = (): LinkListOverviewItem[] => {
        return [
            {
                isGranted: this.authContext.isGranted(UiRights.BIL_BUNDLE_CREATE),
                route: 'bundle.new',
                text: this.$translate.instant('TR_090119-6b1a06_TR')
            }
        ] as LinkListOverviewItem[];
    };
}
/* eslint-enable */
