import * as ng from 'angular';

import { DatabaseModelService } from '../../../../../../services/database/database-model';
import { DatabaseApi } from '../../../../../../types/database-api';
import { MoleculePanelEditTableController } from '../../../panel-edit/table/panel-edit-table';

export interface CreateBundleBackupAccessObject {
    backupToReplaceId: string;
    backupName: string;
    webspaceTarget: string;
    databaseTargets: string[];
}

export class MoleculePanelCreateBundleBackupController implements ng.IController {
    public static $inject: string[] = ['$state', '$translate', 'databaseModel'];

    public onAdd: (CreateBackupAccessObject) => any;
    public newBackupObject: CreateBundleBackupAccessObject;
    public $panelEditTable: MoleculePanelEditTableController;
    public setPageOverlay: boolean;
    public $moleculeFormEdit;

    public webspaceBackupChecked = false;
    public bundleId: string;
    public listOfDatabasesInBundle = [];
    public isConfirmOverride: boolean;

    public constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private databaseModel: DatabaseModelService
    ) {}

    public $onInit() {
        this.isConfirmOverride = false;
        this.resetBackupObject();
        this.setPageOverlay = this.setPageOverlay || false;
        this.$moleculeFormEdit.saveCallback = this.saveNewBackup;

        this.bundleId = this.$state.$current.locals.globals.bundle.id;
        this.databaseModel.list(100, 1, {field: 'bundleId', value: this.bundleId})
            .then(
                (databasesResult) => {
                    if (databasesResult && databasesResult.data) {
                        this.listOfDatabasesInBundle = databasesResult.data
                            .map(
                                (dbInstance: DatabaseApi.Database) => ({ name: dbInstance.name, value: dbInstance.id })
                            );
                    }
                }
            );
    }

    public toggleWebspaceBackup = () => {
        this.newBackupObject.webspaceTarget = this.webspaceBackupChecked
            ? undefined
            : this.$state.$current.locals.globals.webspace[0].id;
    };

    public resetBackupObject = () => {
        const bundleName = this.$state.$current.locals.globals.bundle.name;

        this.newBackupObject = {
            backupName: this.$translate.instant(
                /* translationId */ 'TR_130220-1cf130_TR',
                {
                    name: bundleName,
                    date: new Date().toLocaleDateString()
                }
            ),
            backupToReplaceId: '',
            webspaceTarget: undefined,
            databaseTargets: []
        };
    };

    public saveNewBackup = () => {
        this.onAdd(ng.copy(this.newBackupObject));
        this.closeCreate();
    };

    public closeCreate = () => {
        this.resetBackupObject();
        this.$panelEditTable.closeCreate();
    };

    public get disableSaveButton()  {
        return (
            !this.isConfirmOverride
            || this.newBackupObject.backupName.length === 0
            || (
                this.newBackupObject.databaseTargets.length === 0
                && !this.webspaceBackupChecked
            )
        );
    }
}

export class MoleculePanelCreateBundleBackupComponent implements ng.IComponentOptions {
    public bindings = {
        accountId: '<',
        onAdd: '<',
        setPageOverlay: '<'
    };
    public require = {
        $moleculeFormEdit: '^moleculeFormEdit',
        $panelEditTable: '^moleculePanelEditTable'
    };
    public controller =  MoleculePanelCreateBundleBackupController;
    public controllerAs = '$createBundleBackupCtrl';
    public template = require('./panel-create-bundle-backup.html');
}
