import * as ng from 'angular';
import { MoleculePanelEditTableController } from '../../panel-edit/table/panel-edit-table';

import './panel-create-backup.scss';

export interface CreateDatabaseAccessObject {
    service: string;
    objectType: string;
    objectId: string;
    backupToReplaceId: string;
    backupName: string;
}

export class MoleculePanelCreateBackupController implements ng.IController {
    public static $inject: string[] = [];

    public onAdd: (CreateDatabaseAccessObject) => any;
    public newBackupObject: CreateDatabaseAccessObject;
    public $panelEditTable: MoleculePanelEditTableController;
    public setPageOverlay: boolean;
    public $moleculeFormEdit;

    private objectId: string;
    private service: string;

    public $onInit() {
        this.newBackupObject = {
            backupName: '',
            backupToReplaceId: '',
            objectId: this.objectId,
            objectType: this.objectType,
            service: this.service
        };
        this.setPageOverlay = this.setPageOverlay || false;

        this.$moleculeFormEdit.saveCallback = this.saveNewBackup;
    }

    public saveNewBackup = () => {
        this.onAdd(ng.copy(this.newBackupObject));
        this.closeCreate();
    };

    public closeCreate = () => {
        this.newBackupObject = {
            backupName: '',
            backupToReplaceId: '',
            objectId: this.objectId,
            objectType: this.objectType,
            service: this.service
        };
        this.$panelEditTable.closeCreate();
    };

    private get objectType() {
        switch (this.service) {
            case 'database':
                return 'Database';
            case 'webhosting':
                return 'Webspace';
            default:
                return '';
        }
    }
}

export class MoleculePanelCreateBackupComponent implements ng.IComponentOptions {
    public bindings = {
        accountId: '<',
        objectId: '<',
        onAdd: '<',
        service: '<',
        setPageOverlay: '<'
    };
    public require = {
        $moleculeFormEdit: '^moleculeFormEdit',
        $panelEditTable: '^moleculePanelEditTable'
    };
    public controller =  MoleculePanelCreateBackupController;
    public controllerAs = '$createBundleCtrl';
    public template = require('./panel-create-backup.html');
}
