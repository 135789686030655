import * as ng from 'angular';
import {
    AlertManagerService,
    BundleModelService,
    NavigationService
} from '../../../../../services';

export class OrganismCancelDeleteFormBundleController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'articleDetailsFilter',
        'bundleModel',
        'navigation'
    ];

    public originalBundle: any;
    public bundle: any;
    public bundlePrice: any = {};
    public userPanelRight: any;
    public hasAgreedToRevokeTermination = false;
    public domains = [];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private articleDetailsFilter,
        private bundleModel: BundleModelService,
        private navigation: NavigationService
    ) {}

    public $onInit = () => {
        this.bundle = JSON.parse(JSON.stringify(this.originalBundle));
        // get bundle price
        this.articleDetailsFilter({
            owner: this.bundle.accountId,
            productCode: this.bundle.productCode,
            quantity: 1
        }).then(
            (priceObject: any) => {
                this.bundlePrice = priceObject;
            }
        );
    };

    public save = () => {
        return this.bundleModel.update(this.bundle)
        .then(
            () => this.navigation.reloadCurrentState()
        );
    };

    public revoke = () => {
        return this.bundleModel.deletionCancel([this.bundle])
        .then(
            () => {
                this.alertManager.success(this.$translate.instant('TR_090119-7fd6dc_TR'));
                this.navigation.go('bundle.id.dashboard', {}, { reload: true });
            }
        );
    };

    public cancelRevoke = () => {
        this.hasAgreedToRevokeTermination = false;
    };
}

export class OrganismCancelDeleteFormBundleComponent implements ng.IComponentOptions {
    public bindings = {
        originalBundle: '<bundle',
        userPanelRight: '='
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismCancelDeleteFormBundleController;
    public template = require('./bundle-cancel-delete.html');
}
