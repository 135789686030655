import { WebhostingApi } from '../../../../../types';
import {
    AlertManagerService,
    NavigationService,
    ValidateMaxLength,
    ValidateMinLength,
    ValidateNotEmpty,
    ValidateRegularExpression,
    VhostModelService
} from './../../../../../services';

import * as ng from 'angular';

export class OrganEditPanelDomainHttpUserEntriesController {
    public static $inject: string[] = ['$translate', 'alertManager', 'navigation', 'vhostModel'];

    // Bindings.
    public editFormState: string;       // State of the current form-edit.
    public httpUserList: any[];         // List of all current http users.
    public newUsers: any[];             // List of new created users.
    public deletedUsers: any[];         // Each username on this list gets removed on save.
    public changedUsers: any[];         // List of all changes made to users.
    public vHost: WebhostingApi.VHost;  // VHost.

    public isEditMode = false; // Indicates wether in edit-mode.

    // Validate username.
    public userNameValidationErrorList: any[][] = [];
    public userNameValidationInstructions: any[] = [
        {
            settings: null,
            validator: new ValidateMinLength(this.$translate, 4)
        },
        {
            settings: null,
            validator: new ValidateMaxLength(this.$translate, 32)
        },
        {
            settings: null,
            validator: new ValidateNotEmpty(this.$translate)
        },
        {
            settings: null,
            validator: new ValidateRegularExpression(this.$translate, '^([a-zA-Z0-9\-@\._])+$', 'ig', false, true)
        }
    ];

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private navigation: NavigationService,
        private vhostModel: VhostModelService
    ) {}

    public $onInit() {
        this.userNameValidationErrorList = [];
    }

    public resetPanel = (isReload = true) => {
        this.navigation.reloadCurrentState();
    };

    public addEmptyUser = () => {
        this.newUsers.push({name: '', password: ''});
    };

    public removeNewUser = (index) => {
        if ([undefined, null, ''].indexOf(index) < 0) {
            this.newUsers.splice(index, 1);
        }
    };

    public get canSave() {
        // Check if the inner logic of the current settings is legit.
        const tmpDoubleCheck = [];
        let isLegit = true;
        this.newUsers.forEach((newUser) => {
            if (
                tmpDoubleCheck.indexOf(newUser.name) >= 0
                || this.httpUserList.indexOf(newUser.name) >= 0
            ) {
                isLegit = false;
            }
            tmpDoubleCheck.push(newUser.name);
        });
        isLegit = this.userNameValidationErrorList.filter(
            (errorList) => {
                return errorList && errorList.length > 0;
            }
        ).length > 0 ? false : isLegit;
        return isLegit;
    }

    public hasDuplicatesInList = (list) => {
        // Returns true if there are duplicate entries in 'list'.
        const storeDuplicates = [];
        let isFailed = false;
        list.forEach((listItem) => {
            if (storeDuplicates.indexOf(listItem) >= 0) {
                isFailed = true;
            }
            storeDuplicates.push(listItem);
        });
        return isFailed;
    };

    public saveChanges = () => {
        // Skip if unable to save.
        if (!this.canSave) {
            return false;
        }

        // Updated users.
        const updatedUserlist = this.httpUserList;
        this.newUsers.forEach((newUser) => { // New
            if (updatedUserlist.indexOf(newUser.name) < 0) {
                updatedUserlist.push(newUser.name);
            }
        });
        this.changedUsers.forEach((changedUser) => { // Changed
            updatedUserlist[updatedUserlist.indexOf(changedUser.old)] = changedUser.new.name;
        });
        this.deletedUsers.forEach((deletedUser) => { // Deleted
            updatedUserlist.splice(updatedUserlist.indexOf(deletedUser), 1);
        });

        // Updated passwords.
        const updatedPasswordList = [];
        this.newUsers.forEach((newUser) => {
            if (
                newUser.password.length > 0
                && updatedUserlist.indexOf(newUser.name) >= 0
            ) {
                updatedPasswordList.push(newUser);
            }
        });
        this.changedUsers.forEach((newUser) => {
            updatedPasswordList.push(newUser.new);
        });

        // Throw error, if any of the two lists have duplicate entries.
        if (this.hasDuplicatesInList(updatedUserlist)) {
            this.alertManager.error(this.$translate.instant('TR_280220-9309ac_TR'));
            this.resetPanel();
            return;
        }

        // Write new users into vhost.
        this.vHost.httpUsers = updatedUserlist;

        // Write user-updates to API including updated passwords.
        this.vhostModel.update(this.vHost, undefined, '', updatedPasswordList).then(
            (reply) => {
                this.alertManager.success(this.$translate.instant('TR_110119-1532bf_TR'));
                this.resetPanel();
            },
            (err) => {
                this.resetPanel();
            }
        );
    };

    public get canEdit() {
        return this.editFormState.length <= 0;
    }

    public startEdit = () => {
        this.isEditMode = true;
        this.editFormState = 'user';
    };

    public cancelEdit = () => {
        this.isEditMode = false;
        this.editFormState = '';
    };
}

export class OrganEditPanelDomainHttpUserEntriesComponent implements ng.IComponentOptions {
    public bindings = {
        changedUsers: '=',
        deletedUsers: '=',
        editFormState: '=',
        httpUserList: '<',
        newUsers: '=',
        vHost: '<'
    };
    public controller = OrganEditPanelDomainHttpUserEntriesController;
    public template = require('./domain-http-user-entries.html');
}
