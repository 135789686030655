import { Validator } from './abstract-validator';

export class ValidateCheckboxRequired extends Validator {
    constructor(private $translate: ng.translate.ITranslateService) {
        super();
    }

    public validate = (value: boolean) => {
        const reply = [];

        if (!value) {
            reply.push({ text: this.$translate.instant('TR_170119-7f744a_TR') });
        }

        return reply;
    };
}
