import * as ng from 'angular';
import { AuthContextService } from '../auth-context';
import { DateTimeWrapper } from '../dateTime';
import { FilterCacheService } from '@/services';

interface OverviewFilterLocalStorage {
    filters: any;
    fancyFilters?: any;
    lastChange: number;
}

export class LocalstorageHelperService {
    public static $inject: string[] = [
        '$state',
        '$window',
        'filterCache'
    ];

    private readonly lsKeyUserSearch = 'SearchSettingsCache';
    private readonly lastStateObject = 'lastRouterState';

    constructor(
        private $state: ng.ui.IStateService,
        private $window: ng.IWindowService,
        private filterCache: FilterCacheService
    ) {}

    public setSearchFiltersForCurrentRoute = (filterSettings, fancyFilters) => {
        let searchSettingsData = this._getDataFromLocalStorageByKey(this.lsKeyUserSearch);
        const currentUserId = AuthContextService.account.id;
        const currentRouteId = this.$state.current.name;

        // for now only save search data for overviews and job lists!
        if (
            !currentRouteId.endsWith('.overview')
            && !currentRouteId.endsWith('.jobs')
        ) {
            return;
        }

        if (searchSettingsData === null) {
            searchSettingsData = {};
        }

        if (searchSettingsData[currentUserId] === undefined) {
            searchSettingsData[currentUserId] = {};
        }

        if (!Array.isArray(fancyFilters)) {
            fancyFilters = [];
        }

        searchSettingsData[currentUserId][currentRouteId] = {
            filters: filterSettings,
            fancyFilters: fancyFilters,
            lastChange: this._getCurrentTimestamp()
        };

        this._updateLocalstorage(this.lsKeyUserSearch, searchSettingsData);
    };

    public getLastSearchSettingsForCurrentUser() {
        const currentUserId = AuthContextService.account.id;
        const currentRouteId = this.$state.current.name;
        const fourHoursAgo = this._getCurrentTimestamp(4 * 60 * 60 * 1000 /* 4 hours in ms */);
        const searchSettingsData = this._getDataFromLocalStorageByKey(this.lsKeyUserSearch);
        const userCameFrom = this.getLastRouterState();

        // ignore localstorage When user came fron webhosting dashboard and a WebspacesAndVhosts filter is set
        if (userCameFrom?.name === 'webhosting.dashboard') {
            const userFilter = this.filterCache.resolveSimpleFilter('WebspacesAndVhosts');

            if (!!userFilter?.value !== false) {
                return null;
            }
        }

        // no cached searches found or invalid json
        if (searchSettingsData === null) {
            return searchSettingsData;
        }

        // no cached data for the current page
        if (
            [null, undefined].indexOf(searchSettingsData[currentUserId]) >= 0
            || [null, undefined].indexOf(searchSettingsData[currentUserId][currentRouteId]) >= 0
        ) {
            return null;
        }

        const lastSearch = searchSettingsData[currentUserId][currentRouteId] as OverviewFilterLocalStorage;

        // unset cached search if it is too old
        if (lastSearch.lastChange < fourHoursAgo) {
            searchSettingsData[currentUserId][currentRouteId] = null;
            this._updateLocalstorage(this.lsKeyUserSearch, searchSettingsData);
            return null;
        }

        // make sure fancyFilters is an array to mitigate JS errors
        if ([null, undefined].indexOf(lastSearch.fancyFilters) >= 0) {
            lastSearch.fancyFilters = [];
        }

        return lastSearch;
    }

    public getLastRouterState() {
        const lastRouterState = this._getDataFromLocalStorageByKey(this.lastStateObject);

        // no cached searches found or invalid json
        if (!!lastRouterState !== false) {
            return lastRouterState;
        } else {
            return {};
        }
    }

    public removeUsersSearchCache = () => {
        this._removeKeyFromLocalstorage(this.lsKeyUserSearch);
    };

    private _getDataFromLocalStorageByKey = (key: string) => {
        const localstorageDataRaw = this.$window.localStorage.getItem(key);

        if (
            [null, undefined].indexOf(localstorageDataRaw) >= 0
            || !this._isValidJson(localstorageDataRaw)
        ) {
            return null;
        }

        return JSON.parse(localstorageDataRaw);
    };

    private _updateLocalstorage = (localstorageKey: string, localStorageValue: object) => {
        try {
            this.$window.localStorage.setItem(localstorageKey, JSON.stringify(localStorageValue));
        } catch (e) {
            // safari private browser tab (let's feed this error to the cookie-monster)
            return;
        }
    };

    private _removeKeyFromLocalstorage = (localstorageKey: string) => {
        try {
            this.$window.localStorage.removeItem(localstorageKey);
        } catch (e) {
            // safari private browser tab (let's feed this error to the cookie-monster)
            return;
        }
    };

    private _isValidJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    private _getCurrentTimestamp = (offsetInMs?: number) => {
        const now = new DateTimeWrapper();
        now.setToday();
        const timestampNow = now.toUnixTimestamp();

        if (offsetInMs === undefined) {
            return timestampNow;
        }

        return timestampNow - offsetInMs;
    };
}
