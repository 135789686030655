import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import {
    DashboardOverviewObject
} from '../../../atomic-components/organisms/overview/dashboard-overviews/dashboard-overviews';
import { UiRights } from '../../../configuration';
import { AuthContextService, FilterCacheService, SslOverviewModel } from '../../../services';

export class TemplateSslCertificateDashboardController {
    public static $inject: string[] = ['$translate', 'authContext', 'sslOverviewModel', 'filterCache'];

    public userHasRightSslCertificatesList = AuthContextService.isGranted(
        UiRights.SSL_CERTIFICATES_LIST
    );
    public pageHeaderData: PanelHeaderData;
    public dashboardOverview: DashboardOverviewObject;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private sslOverviewModel: SslOverviewModel,
        private filterCache: FilterCacheService
    ) {}

    public $onInit = () => {
        const backwardRoute = '';
        this.pageHeaderData = {
            backwardLink: backwardRoute,
            backwardText: '',
            panelHeaderRoute: backwardRoute,
            panelHeaderRouteParams: null,
            panelIcon: 'shield',
            panelTitle: this.$translate.instant('TR_140119-06269e_TR')
        };

        this.dashboardOverview = {
            // List of table service data
            overviewsData: [
                {
                    listOptions: {
                        limit: 6,
                        sort: {
                            field: 'CertificateLastChangeDate',
                            order: 'DESC'
                        }
                    },
                    overviewModel: this.sslOverviewModel,
                    panelHeaderData: {
                        includeShowAllLink: true,
                        includeShowDetailsLink: false,
                        panelHeaderRoute: 'ssl.certificates.overview',
                        panelHeaderRouteParams: {},
                        panelIcon: 'shield',
                        panelTitle: this.$translate.instant('TR_090119-6aba62_TR')
                    },
                    serviceItemList: []
                }
            ],
            // Dashboard main menu panel
            panelData: {
                addtionalActionData: {
                    // Addition action panel
                    linkList: [
                        {
                            isGranted: this.authContext.isGranted(UiRights.SSL_JOBS_LIST),
                            route: 'ssl.certificates.jobs',
                            routeParams: {},
                            text: this.$translate.instant('MENU.CONTEXT.JOBS')
                        }
                    ]
                },
                mainHeader: {
                    icon: 'shield',
                    isGranted: this.authContext.isGranted(UiRights.SSL_ORDER),
                    route: 'ssl.certificates.new',
                    routeParams: {},
                    text: this.$translate.instant('TR_140119-a4f2be_TR')
                }
            },
            simpleFilter: this.filterCache.resolveSimpleFilter('SSLCertificates')
        };
    };
}

export class TemplateSslCertificateDashboardComponent implements ng.IComponentOptions {
    public bindings = {};
    public controller = TemplateSslCertificateDashboardController;
    public controllerAs = '$TemplateSslCertificateDashboardCtrl';
    public template = require('./ssl-certificate-dashboard-template.html');
}
