import ng from 'angular';
import { PanelHeaderData } from '@/atomic-components';

export class TemplateResellerDashboardController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData = {
        panelHeaderRoute: '',
        panelIcon: 'users',
        panelTitle: this.$translate.instant('TR_080619-60ff9c_TR')
    };

    public leftPanelArea: PanelHeaderData[] = [
        {
            includeShowAllLink: true,
            includeShowDetailsLink: false,
            panelHeaderRoute: 'reseller.settings',
            panelIcon: 'users-cog',
            panelItems: [
                {
                    route: 'reseller.settings',
                    text: this.$translate.instant('TR_090119-d3ceca_TR')
                },
                {
                    route: 'reseller.settings',
                    text: this.$translate.instant('TR_090119-7ca174_TR')
                },
                {
                    route: 'reseller.settings',
                    text: this.$translate.instant('TR_090119-8a57dc_TR')
                }
            ],
            panelTitle: this.$translate.instant('ACCOUNT.SETTINGS.TITLE')
        },
        {
            includeShowAllLink: true,
            includeShowDetailsLink: false,
            panelHeaderRoute: 'reseller.rightstemplates.overview',
            panelIcon: 'lock',
            panelItems: [
                {
                    route: 'reseller.rightstemplates.new',
                    text: this.$translate.instant('TR_090119-2e7aeb_TR')
                }
            ],
            panelTitle: this.$translate.instant('STATE.ACCOUNT.RIGHTS-TEMPLATES.PAGE-TITLE')
        },
        {
            includeShowAllLink: true,
            includeShowDetailsLink: false,
            panelHeaderRoute: 'reseller.subaccounts.overview',
            panelIcon: 'address-book',
            panelItems: [
                {
                    route: 'reseller.subaccounts.new',
                    text: this.$translate.instant('TR_090119-8857bc_TR')
                }
            ],
            panelTitle: this.$translate.instant('STATE.ACCOUNT.SUBACCOUNTS.PAGE-TITLE')
        }
    ];

    constructor(private $translate: ng.translate.ITranslateService) {}
}

export class TemplateResellerDashboardComponent implements ng.IComponentOptions {
    public bindings = {};
    public template = require('./reseller-dashboard.html');
    public controller = TemplateResellerDashboardController;
}
