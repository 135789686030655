import * as ng from 'angular';
import { DomainCheckerService, PriceCacheService } from '../../../../../services';
import { WizardObject } from '../../wizard-supervisor/wizard-supervisor';

export class MoleculeWizardSummaryItemMachineController implements ng.IController {
    public static $inject: string[] = ['domainChecker', 'priceCache'];
    public serviceObject: WizardObject;

    constructor(
        private domainChecker: DomainCheckerService,
        private priceCache: PriceCacheService
    ) {}

    $onInit() {
        if (
            this.serviceObject.selectedProduct.family === 'ecommerce-virtual-machines'
            && this.serviceObject.settings.domainAction === 'register'
        ) {
            this.domainChecker.checkOne(this.serviceObject.settings.domain).then(
                (reply) => {
                    if (reply.status === 'available') {
                        this.getDomainPrice(reply.extension).then(
                            (ret) => {
                                this.serviceObject.children = [{
                                    label: this.serviceObject.settings.domain,
                                    price: ret,
                                    amount: 1
                                }];
                            }
                        );
                    }
                }
            );
        } else {
            this.serviceObject.children = [];
        }
    }

    public getDomainPrice = (tld: string) => {
        return this.priceCache.listDomainPrices(tld).then(
            (reply) => {
                for (const price of reply) {
                    if (price.productCode.indexOf('create') >= 0) {
                        return price;
                    }
                }
            }
        );
    };
}

export class MoleculeWizardSummaryItemMachineComponent implements ng.IComponentOptions {
    public bindings = {
        serviceObject: '='
    };
    public template = require('./wizard-summary-machine.html');
    public controller =  MoleculeWizardSummaryItemMachineController;
}
