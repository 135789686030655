import * as ng from 'angular';
import { LinkListItem, PanelHeaderData } from '@/atomic-components/molecules/';
import { FaqManagerService } from '@/services';

export class OrganDashboardWidgetFaqNextcloudController implements ng.IController {
    public static $inject: string[] = ['faqManager'];

    public panelHeader: PanelHeaderData = {
        includeShowAllLink: true,
        panelHeaderAllRoute: 'helpdesk',
        panelHeaderAllRouteParams: {
            level1: 'produkte',
            level2: 'nextcloud'
        },
        panelHeaderRoute: 'helpdesk',
        panelHeaderRouteParams: {
            level1: 'produkte',
            level2: 'nextcloud'
        },
        panelIcon: 'question',
        panelTitle: 'Nextcloud FAQ'
    };

    private _loadingFaqArticles = true;
    private _faqItems: LinkListItem[] = [];

    constructor(
        private faqManager: FaqManagerService
    ) {

    }

    public $onInit(): void {
        const faqArticles = [
            'nextcloud-setup-ios',
            'nextcloud-setup-android',
            'nextcloud-setup-windows',
            'nextcloud-setup-desktop-macos',
            'nextcloud-setup-outlook'
        ];

        void this.faqManager
            .faqItemList(faqArticles)
            .then(
                (items) => {
                    this._faqItems = items.map(
                        (item) => {
                            item.text = [undefined, null, ''].indexOf(item.alternativeTitle) < 0
                                ? item.alternativeTitle
                                : item.text;
                            return item;
                        }
                    );

                    this._loadingFaqArticles = false;
                }
            );
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set faqItems({}) {}
    public get faqItems(): LinkListItem[] {
        return this._faqItems;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set loadingFaqArticles({}) {}
    public get loadingFaqArticles(): boolean {
        return this._loadingFaqArticles;
    }
}

export class OrganDashboardWidgetFaqNextcloudComponent implements ng.IComponentOptions {
    public template = require('./dashboard-widget-faq-nextcloud.html');
    public controller = OrganDashboardWidgetFaqNextcloudController;
}
