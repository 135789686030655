import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { DisableFilter } from '../../../atomic-components/organisms/overview/overview';

export class TemplateStorageJobOverviewController {
    public static $inject: string[] = ['$state', '$translate'];

    public pageHeaderData: PanelHeaderData;
    public overviewServiceData;
    public expertView: boolean;
    public disableFilter: DisableFilter;
    public overviewModel;

    private simpleFilter;
    private extendedFilters;
    private overviewUiSettings;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        const stateInNextcloud = this.$state.current.name.split('.')[0] === 'nextcloud';
        this.expertView = true;  // only expertview on job overviews

        this.disableFilter = {
            fancyFilter: false,
            searchFilter: false
        };

        this.pageHeaderData = {
            backwardLink: stateInNextcloud
                ? 'nextcloud.overview'
                : 'storage.dashboard',
            backwardText: stateInNextcloud
                ? this.$translate.instant('TR_220519-98c7e4_TR')
                : this.$translate.instant('TR_220519-98c7e4_TR'), // storage: TR_150519-cfa086_TR
            panelHeaderRoute: '',
            panelIcon: 'save',
            panelTitle: this.$translate.instant('TR_160919-c3c2f3_TR') // storage: TR_160519-570c9b_TR
        };

        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewService: 'storageJob',
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter,
            sort: {
                field: 'jobAddDate',
                order: 'DESC'
            }
        };
    }

    public expandWrapper = (toggle) => {
        this.expertView = toggle.expand;
    };
}

export class TemplateStorageJobOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public template = require('./storage-job-overview-template.html');
    public controller = TemplateStorageJobOverviewController;
    public controllerAs = 'TemplateStorageJobOverviewCtrl';
}
