import * as ng from 'angular';

import { AlphaNumericCaseInsensitive, ShortDebounceInterval } from '@/configuration';

import * as Types from '../../../../../types';
import { ValidateMaxLength, ValidateNotEmpty } from '@/services';

export class OrganCreateConfigurationUserStorageNextcloudController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public shortDebounceInterval = ShortDebounceInterval;
    public userObject: Types.ManagedApplicationApi.NextcloudUserWithPassword;
    public accessPassword: string | null = null;
    public userNameValidationInstructions: {}[];

    constructor(
        private $translate: ng.translate.ITranslateService,
    ) {}

    public $onInit(): void {
        this.userNameValidationInstructions = [
            {
                settings: null,
                validator: new ValidateMaxLength(this.$translate, 64)
            },
            {
                settings: null,
                validator: new ValidateNotEmpty(this.$translate)
            },
        ];
    }

    public get completed() {
        return this.userObject !== undefined
            && [undefined, null, ''].indexOf(this.userObject.password) < 0
            && [undefined, null, ''].indexOf(this.userObject.user.emailAddress) < 0
            && [undefined, null, ''].indexOf(this.userObject.user.name) < 0
            && [undefined, null, '', this.userObject.password].indexOf(this.userObject.user.username) < 0;
    }

    public set completed({}) {} // tslint:disable-line:no-empty
}

export class OrganCreateConfigurationUserStorageNextcloudComponent implements ng.IComponentOptions {
    public bindings = {
        completed: '=',
        userObject: '='
    };

    public template = require('./storage-nextcloud-config.html');
    public controller =  OrganCreateConfigurationUserStorageNextcloudController;
}
