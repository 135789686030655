/* eslint-disable */

import ng from 'angular';

import { UiLanguagesConst, UIRegExp, UiRights } from '@/configuration';
import { CalculatePriceFilter, ConvertAmountFilter } from '@/filters';
import {
    AlertManagerService, ApiErrorModel, AuthContextService, MachineModelService, NavigationService,
    PriceModelService, ProductsModelService, SelectBoxContentCreator
} from '@/services';

export class OrganEditPanelMachineUpgradeController {
    public static $inject: string[] = [
        '$q',
        'alertManager',
        'apiErrorModel',
        'authContext',
        'calculatePriceFilter',
        'convertAmountFilter',
        'machineModel',
        'navigation',
        'priceModel',
        'productsModel',
        'selectBoxContentCreator',
        '$translate'
    ];

    public machine: any;
    public language: string;
    public restartVm = false;
    public upgradeConfirmed = false;
    public productFamilies: any = [];
    public currentProductName = '';
    public currentProductCode = '';
    public contractPeriod = '6';
    public currentProductFamily: string|null = null;
    public currentProductBox: any = [];
    public targetProductBoxes: any = [];
    public selectedProductCode = '';
    public selectedProductName = '';
    private _changeType = '';

    public currentMachineProduct: any;
    public selectedMachineProduct: any;
    public currentBackupProductCode: string;
    public selectedBackupProductCode: string;
    public account: any;
    public addBackup: boolean;

    constructor(
        private $q: ng.IQService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private authContext: AuthContextService,
        private calculatePriceFilter: CalculatePriceFilter,
        private convertAmountFilter: ConvertAmountFilter,
        private machineModel: MachineModelService,
        private navigation: NavigationService,
        private priceModel: PriceModelService,
        private productModel: ProductsModelService,
        private selectBoxContentCreator: SelectBoxContentCreator,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this._changeType = this.$translate.instant('TR_110119-d5119c_TR');
        this.language = UiLanguagesConst[this.authContext.user.language];
        this.currentProductCode = this.machine.productCode;

        // parse current contracts contractual period from the product code
        this.contractPeriod = UIRegExp.ProductCodeToContractPeriod.exec(this.machine.productCode)[1];

        // set local product family information via API
        const productFamilyDetails = this.setProductFamilyData();

        this.generateProductFamilySelectBoxContent(productFamilyDetails);

        this.account = this.authContext.account;

        this.requestProductBackup(this.currentProductCode, this.language).then(
            (resolve) => {
                this.currentMachineProduct = resolve?.product;
                this.currentBackupProductCode = resolve?.backupProductCode;
            }
        )
    }

    public get orderBackup(): boolean {
        return (this.machine.backupEnabled || this.addBackup);
    }

    public get showBackupOption(): boolean {
        if (this.selectedProductCode && (this.currentBackupProductCode || this.selectedBackupProductCode)) {
            return true;
        }
        return false;
    }

    public get backupCustomLabel(): string {
        return this.machine.backupEnabled
            ? this.$translate.instant('491b1e35-c58a-4177-95ad-996af0bf7508')
            : this.$translate.instant('9225f520-bce1-42b7-9077-0e3be8d06d7a');
    }

    public async requestProductBackup(productCode: string, language: string): Promise<{
        product: any,
        backupProductCode: string
    }> {
        let product: any;
        let backupProductCode: string;
        return this.productModel.findOne(productCode, language).then(
            (productResponse) => {
                product = productResponse?.response;
                product?.specificationItems?.map((item: any) => {
                    if (item?.name === 'backupProductCode') {
                        backupProductCode = this.replaceBillingCycleInProductCodeTemplate(
                            item?.value,
                        );
                    }
                });
                return {
                    product,
                    backupProductCode
                };
            }
        );
    }

    public get newBackupProductCode(): string {
        return this.selectedBackupProductCode;
    }

    get contractPeriodText(): string {
        switch (this.contractPeriod) {
            case '1':
                return 'monthly';
            case '3':
                return 'quarterly';
            case '6':
                return 'semiannually';
            case '12':
                return 'annually';
            default:
                return this.contractPeriod + ' ' + this.$translate.instant('GENERAL.MONTHS');
        }
    }

    get changeType(): string {
        if  ([null, undefined, ''].indexOf(this.selectedProductCode) === -1) {
            const selectedProductFamily = this.productFamilies.filter(
                (family: any) => {
                    return this.selectedProductCode === family.productCode;
                }
            );
            if (selectedProductFamily[0].changeMode === 'downgrade') {
                return this.$translate.instant('TR_110119-6754fe_TR');
            } else {
                return this.$translate.instant('TR_110119-d5119c_TR');
            }
        }
        return this._changeType;
    }

    public get showSavePanel() {
        return this.upgradeConfirmed;
    }

    public isAdmin = () => {
        return this.authContext.isRootOrCompanyAccount === true;
    };

    public selectTarget = (item: any) => {
        this.selectedProductCode = this.replaceBillingCycleInProductCodeTemplate(item.productCodeTemplate);
        this.selectedProductName = item.name;
        this.requestProductBackup(this.selectedProductCode, this.language).then(
            (resolve) => {
                this.selectedMachineProduct = resolve?.product;
                this.selectedBackupProductCode = resolve?.backupProductCode;
            }
        )
    };

    public isSelectedProduct = (item: any) => {
        return this.replaceBillingCycleInProductCodeTemplate(item.productCodeTemplate) === this.selectedProductCode;
    };

    public upgradeMachine = () => {
        this.apiErrorModel.destroyErrorList();
        this.machineModel.changeProduct(this.machine.id, this.selectedProductCode, this.restartVm, this.orderBackup).then(
            (response) => {
                if (!this.apiErrorModel.apiResponseHasError(response)) {
                    this.alertManager.success(this.$translate.instant('TR_110119-a4fe2a_TR'));
                    this.navigation.go(
                        'machine.virtualmachines.id.edit',
                        { machineId: this.machine.id },
                        { reload: true }
                    );
                    return response;
                }
                return this.$q.reject(false);
            },
            (error) => {
                this.alertManager.error(this.$translate.instant('TR_110119-467607_TR'));
                return this.$q.reject(error);
            }
        );
    };

    private replaceBillingCycleInProductCodeTemplate = (productCodeTemplate: any) => {
        return productCodeTemplate.replace(UIRegExp.BillingCyclePlaceholder, this.contractPeriod);
    };

    private setProductFamilyData() {
        return this.productModel.findOne(this.machine.productCode, this.language).then(
            (productDetails) => {
                this.currentProductName = productDetails.response.name;
                return this.productModel.findProducts('machine', productDetails.response.family, this.language);
            }
        ).then(
            (productFamilyDetails) => {
                let currentServerTierHit = false;
                this.productFamilies = productFamilyDetails.response.data.map(
                    (productFamily: any) => {
                        if (this.currentProductFamily === null) {
                            this.currentProductFamily = productFamily.family;
                        }
                        if (productFamily.name === this.currentProductName) {
                            currentServerTierHit = true;
                            return false;
                        }
                        const drilldownProductFalilyInfo: any = {};
                        if (currentServerTierHit === true) {
                            drilldownProductFalilyInfo.changeMode = 'upgrade';
                        } else {
                            // currently downgrades are not possible #api
                            return false;
                            // drilldownProductFalilyInfo.changeMode = 'downgrade';
                        }
                        drilldownProductFalilyInfo.productCode = this.replaceBillingCycleInProductCodeTemplate(
                            productFamily.productCodeTemplate
                        );
                        return drilldownProductFalilyInfo;
                    }
                ).filter((productFamily) => productFamily !== false);
            }
        );
    }

    private generateProductFamilySelectBoxContent = (productFamilyDetails: any) => {
        productFamilyDetails.then(
            (response: any) => {
                const boxItems = this.selectBoxContentCreator.createSelectBoxes(
                    'machine', [this.currentProductFamily], false
                );
                boxItems.then((boxContent) => {
                    this.targetProductBoxes = ng.copy(boxContent);
                    this.currentProductBox = ng.copy(boxContent);
                    this.currentProductBox[0].products = this.currentProductBox[0].products.filter((product: any) => {
                        return this.currentProductCode === this.replaceBillingCycleInProductCodeTemplate(
                            product.productCodeTemplate
                        );
                    });
                    this.targetProductBoxes[0].products = this.targetProductBoxes[0].products.filter((product: any) => {
                        return this.productFamilies.some((family: any) => {
                            return family.productCode === this.replaceBillingCycleInProductCodeTemplate(
                                product.productCodeTemplate
                            );
                        });
                    });
                });
            }
        );
    };
}

export class OrganEditPanelMachineUpgradeComponent implements ng.IComponentOptions {
    public bindings = {
        machine: '<'
    };
    public template = require('./machine-upgrade.html');
    public controller = OrganEditPanelMachineUpgradeController;
}
