import { IRouteRights, IRoutesArray, routesArray } from '@/components/all-routes';
import { UIRouter, MatchResult } from '@uirouter/core';


export interface RouteRightsOptionalRequired {
    optionalRights: string[];
    requiredRights: string[];
}

export type RouteRightsListLine = { name: string; route: RouteRightsOptionalRequired };
export type RouteRightsList = RouteRightsListLine[];

export class RouteFinderService {
    public static $inject: string[] = [];

    private static _uiRouter: UIRouter;

    private static _initPseudoRouter() {
        RouteFinderService._uiRouter = new UIRouter();
        routesArray.forEach(route => {
            RouteFinderService._uiRouter.stateRegistry.register({
                name: route.name,
                url: route.state.url as string,
                data: route.state.data,
            });
        });
    }

    public constructor() {
        if (!RouteFinderService._uiRouter) {
            RouteFinderService._initPseudoRouter();
        }
    }

    private _findBestMatch(path: string): MatchResult {
        return RouteFinderService._uiRouter.urlService.match({
            path: path
        })
    }

    private _isRelative = (link: string) => {
        // https://stackoverflow.com/a/57047786/3927474
        return new URL(document.baseURI).origin === new URL(link, document.baseURI).origin;
    }

    public findRoutesByLink = (link: string): IRoutesArray[] => {
        const routes: IRoutesArray[] = [];
        const url = this._isRelative(link)
            ? new URL(link, document.baseURI)
            : new URL(link);
        const match = this._findBestMatch(url.pathname);
        if ((match?.rule as any)?.state) {
            let state = (match?.rule as any)?.state;
            routes.push({
                name: state.name,
                state: state
            })
            while (state.parent) {
                state = state.parent
                routes.push({
                    name: state.name,
                    state: state
                })
            }
        }
        return routes;
    };

    public getRoutePermissions = (link: string) => {
        return this.findRoutesByLink(link)
            .map((route) => {
                return route.state.data
            }).filter(route => {
                if ([null, undefined].includes(route)) {
                    return false;
                }
                if (Object.entries(route).length < 1) {
                    return false;
                }
                return true;
            });
    }

    public getPermissionsTable = (link: string) => {
        const routesChain = this.findRoutesByLink(link);
        const optionalRights = new Set<string>();
        const requiredRights = new Set<string>();
        for (const iterator of routesChain) {
            const data = (iterator.state?.data as IRouteRights);
            if (data?.isGranted) {
                requiredRights.add(data?.isGranted);
            }
            if (data?.isGrantedAll) {
                data?.isGrantedAll.forEach(granted => requiredRights.add(granted));
            }
            if (data?.isGrantedAny) {
                data?.isGrantedAny.forEach(granted => optionalRights.add(granted));
            }
        }
        return {
            optionalRights: Array.from(optionalRights),
            requiredRights: Array.from(requiredRights)
        }
    }

    public getPermissionRouteTable = (link: string) => {
        let routesChain = [];
        const routeRights = [];
        if (link.length > 0) {
            routesChain = this.findRoutesByLink(link);
        } else {
            routesChain = routesArray;
        }
        for (const iterator of routesChain) {
            const line = {
                name: iterator.name,
                route: {
                    requiredRights: [],
                    optionalRights: []
                }
            } as RouteRightsListLine;
            routeRights.push(line);
            const data = (iterator.state?.data as IRouteRights);
            if (data?.isGranted) {
                line.route.requiredRights.push(data?.isGranted);
            }
            if (data?.isGrantedAll) {
                line.route.requiredRights.push(...data?.isGrantedAll);
            }
            if (data?.isGrantedAny) {
                line.route.optionalRights.push(...data?.isGrantedAny);
            }
        }
        return routeRights;
    }
}
