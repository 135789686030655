import * as ng from 'angular';
import { MoleculeFormEditController, PanelType } from '@/atomic-components/molecules/forms/form-edit/form-edit';
import {
    OrganismEditFormNextcloudController
} from '@/atomic-components/organisms/forms/edit-forms/nextcloud-edit/nextcloud-edit';
import { NextcloudUnlimitedStorageValue } from '@/configuration';
import { NavigationService } from '@/services';

export class OrganEditPanelNextcloudUsersController {
    public static $inject: string[] = ['navigation'];
    public $editFormMolecule: MoleculeFormEditController;
    public $editFormOrganism: OrganismEditFormNextcloudController;
    public typeOverride = PanelType.MISCELLANEOUS_ACTION;
    public searchString = '';
    public noUsersFound: boolean;

    public constructor(
        private navigation: NavigationService
    ) {}

    public get disableSaveButton(): boolean {
        const hasUsersMarkedForDeletionButIsMissingConfirmation = this.$editFormOrganism.nextcloudUsersNew.some(
            (user) => user.props.isMarkedForRemoval // all users that have been marked for deletion
                && !user.props.isNewUser // ignore newly created users
                && !user.props.deleteUserFiles
                && !!user.props.usernameForFileTransfer === false
        );

        return this.disableAddButton || hasUsersMarkedForDeletionButIsMissingConfirmation;
    }

    public get disableAddButton(): boolean {
        return this.$editFormOrganism.nextcloudUsersNew.some((user) => user.props.isBeingEdited === true);
    }

    public cancel = (): void => {
        this.navigation.reloadCurrentState();
    };

    public addNewUser = (): void => {
        this.$editFormOrganism.nextcloudUsersNew.unshift({
            emailAddress: '',
            enabled: true,
            groups: [],
            name: '',
            nextcloudId: this.$editFormOrganism.nextcloud.id,
            props: {
                isMarkedForRemoval: false,
                isNewUser: true,
                isBeingEdited: true,
                isHidden: false,
                changePassword: true,
                showUsernameAlreadyTakenError: false,
                storageInGb: this.$editFormOrganism.nextcloud.storageQuota
                    ? this.$editFormOrganism.nextcloud.storageQuota / 1024
                    : 1,
                type: 'new',
                usernameForFileTransfer: '',
                deleteUserFiles: undefined,
                validationErrorList: {
                    name: [],
                    username: [],
                    emailAddress: []
                },
                passwordValidationOptions: { minLength: 10 }
            },
            storageQuota: this.$editFormOrganism.nextcloud.storageQuota
                ? this.$editFormOrganism.nextcloud.storageQuota
                : NextcloudUnlimitedStorageValue,
            unlimitedStorage: false,
            username: '',
            status: 'active'
        });
    };
}

export class OrganEditPanelNextcloudUsersComponent implements ng.IComponentOptions {
    public require = {
        $editFormMolecule: '^moleculeFormEdit',
        $editFormOrganism: '^organismEditFormNextcloud'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./nextcloud-users.html');
    public controller = OrganEditPanelNextcloudUsersController;
}
