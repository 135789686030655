import ng from 'angular';

import { UiRights } from '@/configuration';
import { AuthContextService, VhostModelService } from '@/services';

export interface WebspaceRestrictionData {
    title: string;
    message: string;
    comment: string;
    type: string;
    priority: number; // the higher the value, the more important is the prioity
}

export class WebhostingHelperService {
    public static $inject: string[] = [
        '$translate',
        'localDateTimeFilter',
        'vhostModel'
    ];

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private localDateTimeFilter,
        private vhostModel: VhostModelService
    ) {}

    public getDefaultPhpIniSet = async (
        webspaceId: string,
        phpVersion?: string|undefined,
        rawData?: boolean|undefined
    ) => {
        const defaultPhpVersion = await this.vhostModel.defaultPhpVersion();
        phpVersion = phpVersion || defaultPhpVersion;
        rawData = [undefined, null].indexOf(rawData) === -1 ? rawData : true;

        return this.vhostModel.vhostPhpIniDefault(webspaceId, phpVersion).then((res) => {
            if (!rawData) {
                res.values = res.values.map((data) => {
                    return {
                        key: data.key,
                        value: data.value
                    };
                });

                delete res.vhostId;
            }

            return res;
        });
    };

    public handleWebspaceRestriction = (webspace) => {
        const webspaceRestriction: WebspaceRestrictionData[] = [];

        if (webspace.restrictions !== undefined) {
            webspace.restrictions.forEach(
                (restriction) => {
                    let comment = this.$translate.instant('TR_140119-c4899e_TR');

                    if (AuthContextService.isGranted(UiRights.WEB_ADMIN_WEBSPACE_ENABLE_DISABLE)) {
                        comment = this.$translate.instant('TR_140119-95f0f2_TR')
                            + ' ' + restriction.internalComment
                            + ` (${this.localDateTimeFilter(restriction.addDate, 'date')})`;
                    }

                    switch (restriction.type) {
                        case 'disabled':
                            webspaceRestriction.push({
                                comment: comment,
                                message: this.$translate.instant('TR_140119-3f6a1e_TR'),
                                priority: 0,
                                title: this.$translate.instant('TR_140119-1185e0_TR'),
                                type: 'error'
                            });

                            break;
                        case 'mailSendingDisabled':
                            webspaceRestriction.push({
                                comment: comment,
                                message: this.$translate.instant('TR_140119-105d6b_TR'),
                                priority: 2,
                                title: this.$translate.instant('TR_140119-a72eb5_TR'),
                                type: 'error'
                            });

                            break;
                        case 'mailSendingDeferred':
                            webspaceRestriction.push({
                                comment: comment,
                                message: this.$translate.instant('TR_090119-ed29ae_TR',
                                    { message: restriction.information }
                                ),
                                priority: 4,
                                title: this.$translate.instant('TR_140119-950f2d_TR'),
                                type: 'warning'
                            });

                            break;
                        default:
                            break;
                    }
                }
            );
        }

        webspaceRestriction.sort((a, b) => {
            return b.priority - a.priority;
        });

        return webspaceRestriction;
    };
}
