import * as ng from 'angular';
import { domainLocationsFormState, LocationMetadataArray, OrganismEditFormDomainLocationsController } from '../../../../organisms/forms/edit-forms/domain-edit/domain-locations';
import { MoleculeFormEditController } from '../../../forms/form-edit/form-edit';
import { LocationMetadata } from './../../../../organisms/forms/edit-forms/domain-edit/domain-locations';

import './panel-create-location.scss';

export class MoleculePanelCreateLocationController implements ng.IController {
    public $editFormOrganism: OrganismEditFormDomainLocationsController;
    public $editFormMolecule: MoleculeFormEditController;
    public newLocation: LocationMetadata;
    public sortableLocations: LocationMetadataArray;
    public nonSortableLocations: LocationMetadataArray;
    public cleanUrlsEnabled = false;

    public $onInit() {
        this.$editFormMolecule.saveCallback = this.saveNewLocation;
        this.$editFormMolecule.cancelCallback = this.close;
    }

    public close = () => {
        this.$editFormOrganism.formState = domainLocationsFormState.EDITABLE;
        this.$editFormMolecule.resetValidations();
        this.cleanUrlsEnabled = false;
    };

    public opened = () => {
        return this.$editFormOrganism.formState === domainLocationsFormState.ADD;
    };

    public get disableConfirmButton() {
        return !this.$editFormMolecule.$valid;
    }

    public saveNewLocation = () => {
        const copyNewLocation = ng.copy(this.newLocation);

        if (copyNewLocation.data.matchType === 'directory') {
            if (copyNewLocation.data.matchString[copyNewLocation.data.matchString.length - 1] !== '/') {
                copyNewLocation.data.matchString += '/';
            }
        }
        if (copyNewLocation.data.matchType === 'directory' || copyNewLocation.data.matchType === 'placeholderMatch') {
            if (copyNewLocation.data.matchString[0] !== '/') {
                copyNewLocation.data.matchString = '/' + copyNewLocation.data.matchString;
            }
            copyNewLocation.sortable = false;
            copyNewLocation.order = this.nonSortableLocations.locations.length;
            this.nonSortableLocations.locations.push(copyNewLocation);
        } else {
            copyNewLocation.sortable = true;
            copyNewLocation.order = this.sortableLocations.locations.length;
            this.sortableLocations.locations.push(copyNewLocation);
        }
        this.$editFormOrganism.formState = domainLocationsFormState.EDITABLE;
    };
}

export class MoleculePanelCreateLocationComponent implements ng.IComponentOptions {
    public bindings = {
        newLocation: '=',
        nonSortableLocations: '=',
        sortableLocations: '='
    };
    public require = {
        $editFormMolecule: '^moleculeFormEdit',
        $editFormOrganism: '^organismEditFormDomainLocations'
    };
    public controller = MoleculePanelCreateLocationController;
    public template = require('./panel-create-location.html');
}
