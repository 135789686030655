import * as ng from 'angular';

import * as Types from '@/types';
import { pageHeaderSettings } from '@/services/account/api-key-links';

export class TemplateApiKeyNewController implements ng.IController {
    public static $inject: string[] = [
        '$state',
        '$translate'
    ];

    public allRights: any[];
    public userData: Types.AccountApi.User;
    public pageHeaderData: any;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = (): void => {
        const {
            backwardLink,
            backwardText,
            panelHeaderRoute
        } = pageHeaderSettings(
            this.$state.current.name,
            this.$translate
        );
        this.pageHeaderData = {
            backwardLink: backwardLink,
            backwardText: backwardText,
            panelHeaderRoute: panelHeaderRoute,
            panelIcon: 'adjust',
            panelTitle: this.$translate.instant('STATE.ACCOUNT.USER.APIKEY.NEW.PAGE-TITLE')
        };
    };
}

export class TemplateApiKeyNewComponent implements ng.IComponentOptions {
    public bindings = {
        allRights: '<',
        overviewUiSettings: '<',
        userData: '<'
    };
    public controller = TemplateApiKeyNewController;
    public template = require('./api-key-new-template.html');
}
