export class Reducer {
    // ex. obj.property.subProperty
    // get(obj, ['property', 'subProperty'])
    // Reduce object to subproperty or return null if property path doesnt exist
    public static get = (object, subPropertyArray): any =>
        subPropertyArray.reduce((propertyArray, property) =>
            (propertyArray && propertyArray[property]) ? propertyArray[property] : null, object);

    public static len = (object: any, subPropertyArray?: any[]): number => {

        if ([undefined, null].indexOf(object) >= 0) {
            return 0;
        }

        if (typeof object === 'string') {
            return (object ).length;
        }

        if (typeof object === 'number') {
            return Math.abs(object );
        }

        if (typeof object === 'boolean') {
            return (object  ? 1 : 0);
        }

        if (subPropertyArray == null) {
            subPropertyArray = [];
        }
        subPropertyArray.push('length');
        return (Reducer.get(object, subPropertyArray) || 0);
    };

    public static setReplace = (array: any[], object: any, property: string[]) => {
        // object not yet in array => add
        if (array.every((element) => Reducer.get(element, property) !== Reducer.get(object, property))) {
            array.push(object);
        } // object already exists => replace
        else {
            // replace element that matches object
            array = array.filter((element) => Reducer.get(element, property) !== Reducer.get(object, property));
            array.push(object);
        }
    };

}
