import * as ng from 'angular';

import { AuthContextService } from '../../../services';
import * as Types from '../../../types';

export class TemplateUserEditAdminController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: any;
    public userData: Types.AccountApi.User;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.pageHeaderData = {
            backwardLink: AuthContextService.isOwnUser(this.userData)
                ? 'account.users.id.edit'
                : 'reseller.subaccounts.id.users.id.edit',
            backwardText: this.$translate.instant('TR_080119-da12bd_TR'),
            panelHeaderRoute: '',
            panelIcon: 'user',
            panelTitle: this.userData.name + ' - ' + this.$translate.instant('TR_091019-a26695_TR')
        };
    }
}

export class TemplateUserEditAdminComponent implements ng.IComponentOptions {
    public bindings = {
        userData: '<'
    };
    public template = require('./user-edit-admin.html');
    public controller =  TemplateUserEditAdminController;
}
