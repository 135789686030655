import * as ng from 'angular';
import { PanelHeaderData } from '@/atomic-components';
import * as Types from '@/types';

export class TemplateNextcloudUpgradeController {
    public static $inject: string[] = ['$state', '$translate'];

    public faqArticleIdList: string[] = [];
    public nextcloud: Types.ManagedApplicationApi.Nextcloud;

    public pageHeaderData: PanelHeaderData = {
        backwardLink: 'nextcloud.id.edit',
        backwardText: this.$translate.instant('TR_010819-62941c_TR'),
        panelHeaderRoute: '',
        panelIcon: '/assets/images/logos/nextcloud-bw.svg',
        panelIconPath: true,
        panelTitle: '',
        productActions: null
    };

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit(): void {
        this.pageHeaderData.panelTitle = this.$translate.instant('TR_100919-321ab7_TR', { name: this.nextcloud.name });
        this.setHeaderBackRoute();
    }

    private setHeaderBackRoute = (): void => {
        if (this.$state.current.name.split('.')[0] === 'nextcloud') {
            this.pageHeaderData.backwardLink = 'nextcloud.id.edit';
        } else {
            this.pageHeaderData.backwardLink = 'storage.storage-products.id.edit';
        }
    };
}

export class TemplateNextcloudUpgradeComponent implements ng.IComponentOptions {
    public bindings = {
        nextcloud: '<'
    };
    public controller = TemplateNextcloudUpgradeController;
    public controllerAs = '$TemplateNextcloudUpgradeCtrl';
    public template = require('./nextcloud-upgrade-template.html');
}
