import * as Sentry from '@sentry/browser';
import * as ng from 'angular';
import { TotalPriceObject } from '@/atomic-components/molecules/forms/form-elements/';
import {
    AsteriskNoteService,
    AsteriskNoteType,
    AuthContextService,
    DomainInfoHelperService,
    DomainPriceAggregated,
    PriceHelperService
} from '@/services';
import * as Types from '@/types';

import './product-price-domain.scss';

export enum ProductPriceType {
    DOMAIN,
    TLD
}

export type DomainPromotionPriceObject = {
    addGracePeriod: string;
    addGracePeriodQuantity: number;
    contractPeriod: string;
    currency: string;
    exchangeRatio: null;
    name: string;
    noticePeriod: number;
    platformId: string;
    price: number;
    productCode: string;
    promotionEndPIT: string;
    promotionStartPIT: string;
    promotionalPrice: number;
    startPIT: string;
    vatRate: number;
};

export type DomainCheck = {
    domainName: string;
    domainNameUnicode: string;
    domainSuffix: string;
    earlyAccessStart: null;
    extension: string;
    generalAvailabilityStart: null;
    landrushStart: null;
    launchPhase: null;
    premiumPrices: null;
    registrarTag: null;
    status: string;
    sunriseStart: null;
    transferMethod: null;
};

type BundleContingent = {
    capacity: number;
    codeStartsWith: string;
    productCodes: string[];
    service: string;
};

export class MoleculeFormProductPriceDomainController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        'asteriskNote',
        'domainInfoHelper',
        'priceHelper'
    ];

    public account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    public asteriskConvertedForeignCurrencyType: AsteriskNoteType = AsteriskNoteType.CONVERTED_FOREIGN_CURRENCY;
    public asteriskAnnuallContractPeriodType: AsteriskNoteType = AsteriskNoteType.ANNUALLY_CONTRACT_PERIOD;
    public asteriskPremiumPriceHint: AsteriskNoteType = AsteriskNoteType.DYNAMIC_NOTE;
    public bundleContingents: BundleContingent[];
    public domainName: string;
    public isPremiumPrice = false;
    public pricePrefixTextWithoutSpace: false;
    public premimuRenewText = '';
    public price: string;
    public priceCss: string;
    public pricePrefixText: string;
    public promotionPrice: string;
    public promotionPriceCss: string;
    public showBasisCurrency = false;
    public tld: string;
    public vatAsteriskNoteType: AsteriskNoteType;

    private _contractPeriod: string;
    private _hideAsteriskNotes = false;
    private _hidePremiumPriceLabeling = false;
    private _loadingPrice = true;
    private _priceHasForeignCurrency = false;
    private _productPriceType: ProductPriceType;
    private _rawPrice: number;
    private _showOnlyPromotionalPriceIfAvailable = false;
    private _totalPriceId: string;
    private _totalPriceObject: TotalPriceObject[] = [];

    constructor(
        private $timeout: ng.ITimeoutService,
        public asteriskNote: AsteriskNoteService,
        private domainInfoHelper: DomainInfoHelperService,
        private priceHelper: PriceHelperService
    ) {}

    public $onInit(): void {
        this._checkProductPriceType();
        this._totalPriceObject = this._totalPriceObject || [];
        if ([undefined, null].indexOf(this.account) >= 0) {
            this.account = AuthContextService.account;
        }

        if ([undefined, null].indexOf(this._totalPriceId) >= 0) {
            this._totalPriceId = this.priceHelper.setTotalPriceIndex();
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public $onChanges(): void {
        if ([undefined, null].indexOf(this.account) >= 0) {
            this.account = AuthContextService.account;
        }

        this._checkProductPriceType();

        if (this._productPriceType === ProductPriceType.DOMAIN) {
            this._getDomainPrice();
        }
        if (this._productPriceType === ProductPriceType.TLD) {
            void this._getTldPrice();
        }
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set freeOfChargeProduct({}) {}
    public get freeOfChargeProduct(): boolean {
        return this._rawPrice === 0;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set isAnnuallContractPeriod({}) {}
    public get isAnnuallContractPeriod(): boolean {
        return !this.freeOfChargeProduct
            && this._contractPeriod === 'annually';
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set showAsteriskConvertedForeignCurrencyType({}) {}
    public get showAsteriskConvertedForeignCurrencyType(): boolean {
        return !this.hideAsteriskNotes
            && this.showBasisCurrency
            && this._priceHasForeignCurrency;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set domainContingents(domainContingent) {}
    public get domainContingents(): BundleContingent[] {
        if ([undefined, null, []].indexOf(this.bundleContingents) < 0) {
            return this.bundleContingents.filter((contingent) => contingent.service === 'domain');
        }
        return undefined;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set promotionalPriceAvailable({}) {}
    public get promotionalPriceAvailable(): boolean {
        if (this.loadingPrice) {
            return false;
        }

        return !!this.promotionPrice;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set showOnlyPromotionalPriceIfAvailable({}) {}
    public get showOnlyPromotionalPriceIfAvailable(): boolean {
        return this._showOnlyPromotionalPriceIfAvailable
            && this.promotionalPriceAvailable;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set hideAsteriskNotes({}) {}
    public get hideAsteriskNotes(): boolean {
        return this._hideAsteriskNotes;
    }

    public set loadingPrice(loadingPrice) {
        this._loadingPrice = loadingPrice;
    }

    public get loadingPrice(): boolean {
        return this._loadingPrice;
    }

    private _checkProductPriceType = (): void => {
        switch (true) {
            case [undefined, null, ''].indexOf(this.domainName) < 0 && [undefined, null, ''].indexOf(this.tld) < 0:
                this._productPriceType = null;
                break;
            case [undefined, null, ''].indexOf(this.domainName) < 0:
                this._productPriceType = ProductPriceType.DOMAIN;
                break;
            case [undefined, null, ''].indexOf(this.tld) < 0:
                this._productPriceType = ProductPriceType.TLD;
                break;
            default:
                this._productPriceType = null;
        }

        if (this._productPriceType === null) {
            let consoleErrorMessage = 'Product domain price error - ProductPriceType could not defined correctly: ';
            consoleErrorMessage += [undefined, null, ''].indexOf(this.domainName) < 0
                    ? this.domainName
                    : this.tld;

            // Throw console.error for development
            console.error(consoleErrorMessage);
            // Throw sentry error to track in live system
            Sentry.captureMessage(consoleErrorMessage);
        }

    };

    private _getTldPrice = (): void => {
        void this.domainInfoHelper.checkDomainPromotionalPrice(
            `domain-${this.tld}-create`
        ).then(this._definePrice);
    };

    private _getDomainPrice = (): void => {
        void this.domainInfoHelper.getDomainPriceOfDomainName(
            this.domainName,
            this.account,
            this.domainContingents,
            true,
            this.showBasisCurrency,
            this._hidePremiumPriceLabeling
        ).then(this._definePrice);
    };

    private _definePrice = (domainPrice: DomainPriceAggregated): void => {
        if (domainPrice.orderPrice) {
            this._contractPeriod = domainPrice.orderPrice.contractPeriod;
        }

        switch (true) {
            case domainPrice.type.isFreeOfCharge:
                this.price = domainPrice.orderPriceText;
                break;

            case domainPrice.type.isRegular:
                this.price = domainPrice.orderPriceText;
                break;

            case domainPrice.type.isPromotion:
                this.priceCss = 'marked-for-removal';
                this.price = domainPrice.regularPriceText;
                this.promotionPrice = domainPrice.orderPriceText;
                break;

            case domainPrice.type.isPremium:
                this.price = domainPrice.orderPriceText;
                this.isPremiumPrice = true;
                this.premimuRenewText = domainPrice.renewPriceText;
                break;
        }

        void this.$timeout(() => {
            this._priceHasForeignCurrency = [undefined, null].indexOf(
                domainPrice?.orderPrice?.exchangeRatio
            ) < 0;
            this._totalPriceObject.push({
                amount: null,
                currency: domainPrice.orderPrice ? domainPrice.orderPrice.currency : '',
                exchangeRatio: this._priceHasForeignCurrency
                    ? domainPrice.orderPrice.exchangeRatio
                    : null,
                index: this._totalPriceId,
                rawPrice: domainPrice.rawPrice,
                rawBasisConverted: domainPrice.rawBasisConverted,
                rawTax: !domainPrice.type.isFreeOfCharge
                    ? ng.copy(domainPrice.rawTax)
                    : { tax: 0, vatRate: 0 }
            });

            this._rawPrice = domainPrice.rawPrice;
            if (!this.freeOfChargeProduct && !this.hideAsteriskNotes) {
                this.vatAsteriskNoteType = this.account.isCommercialCustomer
                    ? AsteriskNoteType.VAT_EXCLUDED
                    : AsteriskNoteType.VAT_INCLUDED;
                this.asteriskNote.addNote(this.vatAsteriskNoteType);

                if (this._priceHasForeignCurrency) {
                    this.asteriskNote.addNote(this.asteriskConvertedForeignCurrencyType);
                }

                if (this.isAnnuallContractPeriod) {
                    this.asteriskNote.addNote(this.asteriskAnnuallContractPeriodType);
                }
            }

            this.loadingPrice = false;
        }, 500);
    };
}

export class MoleculeFormProductPriceDomainComponent implements ng.IComponentOptions {
    public bindings = {
        _hidePremiumPriceLabeling: '<?hidePremiumPriceLabeling',
        _showOnlyPromotionalPriceIfAvailable: '<?showOnlyPromotionalPriceIfAvailable',
        _totalPriceId: '<?totalPriceId',
        _totalPriceObject: '=?totalPriceObject',
        _hideAsteriskNotes: '<?hideAsteriskNotes',
        account: '<?',
        bundleContingents: '<?',
        domainName: '<domainName',
        pricePrefixText: '@?',
        pricePrefixTextWithoutSpace: '<?',
        promotionalPriceAvailable: '=?',
        showBasisCurrency: '<?',
        tld: '<'
    };

    public template = require('./product-price-domain.html');
    public controller = MoleculeFormProductPriceDomainController;
}
