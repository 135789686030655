import {
    DashboardWidgetModel,
    DashboardWidgetType
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model';
import {
    DashboardWidgetObjectDefinition
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model-operator';

export class DashboardWidgetOverviewContactVerificationModel extends DashboardWidgetModel {
    public static $inject: string[] = DashboardWidgetModel.$inject.concat([]);

    public static dashboardWidgetDefinition: DashboardWidgetObjectDefinition = {
        demoData: [
            {
                id: '4657667676767675',
                name: 'Max Mustermann',
                organization: 'Mustermann GmbH',
                accountId: '',
                emailAddress: 'max.mustermann@example.com'
            }, {
                id: '4657667676767676',
                name: 'Lieschen Müller',
                organization: 'Müller GmbH',
                accountId: '',
                emailAddress: 'lieschen@mueller.com'
            }
        ],
        manualAddable: true,
        userPermissions: {
            isGranted: 'DOM_CONTACTS_LIST'
        },
        widgetDescription: [
            /* translationId */ 'TR_150321-8137f0_TR'
        ],
        widgetObject: {
            attribute: {
                id: 'organDashboardWidgetOverviewContactVerification',
                width: 2,
                height: 1,
                x: 0,
                y: 0,
                noResize: true,
                autoPosition: true
            },
            componentTagName: 'organ-dashboard-widget-overview-contact-verification',
            widgetModel: 'dashboardWidgetOverviewContactVerificationModel',
            version: '1.0'
        },
        widgetTitle: /* translationId */ 'TR_290121-488b91_TR',
        widgetType: DashboardWidgetType.custom
    };

    public dashboardWidgetDefinition: DashboardWidgetObjectDefinition;

    constructor() {
        super();

        this.dashboardWidgetDefinition = DashboardWidgetOverviewContactVerificationModel.dashboardWidgetDefinition;
    }
}
