import { UTF8TextEncoder } from './utf8encoder';

export class CsvGenerator {
    public static generate = (data) => {
        const csv = CsvGenerator.dataToCsv(data);

        const array = (new UTF8TextEncoder()).encode(csv);
        return new Blob([array], {type: 'text/csv;charset=UTF-8'});
    };

    private static processValue(value) {
        return ([undefined, null].indexOf(value) >= 0)
        ? ''
        : value.toString();
    }

    private static processLine = (entry) => {
        return entry.map(CsvGenerator.processValue).join(';');
    };

    private static dataToCsv = (data) => {
        return data.map(CsvGenerator.processLine).join('\n');
    };
}
