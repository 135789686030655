import * as ng from 'angular';

import { PanelHeaderData } from '../../../atomic-components/molecules/panels';
import { DashboardOverviewObject } from '../../../atomic-components/organisms/overview';
import { UiRights } from '../../../configuration';
import {
    AuthContextService, FilterCacheService, VirtualMachineOverviewModel
} from '../../../services';

export class TemplateMachineDashboardController {
    public static $inject: string[] = ['$translate', 'authContext', 'filterCache', 'virtualMachineOverviewModel'];

    public userHasRightMachineVmList = AuthContextService.isGranted(UiRights.MACHINE_VM_LIST); // used in template!
    public pageHeaderData: PanelHeaderData;
    public dashboardOverview: DashboardOverviewObject;
    public linkList: any[] = [
        {
            text: this.$translate.instant('MENU.CONTEXT.JOBS'),
            route: 'machine.virtualmachines.jobs',
            isGranted: this.authContext.isGranted(UiRights.MACHINE_JOBS_LIST),
            routeParams: {}
        },
        {
            text: this.$translate.instant('TR_140119-1516a5_TR'),
            route: 'machine.pools',
            isGranted: this.authContext.isGrantedAll([UiRights.RES_POOL_LIST, UiRights.ADMIN_SYSTEM_SUPER_USER_READ]),
            routeParams: {}
        },
        {
            text: this.$translate.instant('071a1517-b504-4288-b3f2-13149d9d5dc8'),
            route: 'network.networks.overview',
            isGranted: this.authContext.isGrantedAll([UiRights.RES_POOL_LIST, UiRights.ADMIN_SYSTEM_SUPER_USER_READ]),
            routeParams: {}
        }
    ];
    public faqArticleIdList: string[] = [
        'machine-managed-vs-cloud'
    ];
    public showObjectId: boolean;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private filterCache: FilterCacheService,
        private virtualMachineOverviewModel: VirtualMachineOverviewModel
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: '',
            backwardText: '',
            panelHeaderRoute: 'machine.dashboard',
            panelHeaderRouteParams: null,
            panelIcon: 'cloud',
            panelTitle: this.$translate.instant('TR_140119-c2926c_TR')
        };

        this.dashboardOverview = {
            // List of table service data
            overviewsData: [
                {
                    hidePanelHeaderData: true,
                    listOptions: {
                        limit: 6,
                        sort: {
                            field: 'VirtualMachineLastChangeDate',
                            order: 'DESC'
                        }
                    },
                    overviewModel: this.virtualMachineOverviewModel,
                    panelHeaderData: {
                        includeShowAllLink: true,
                        includeShowDetailsLink: false,
                        panelHeaderRoute: 'machine.virtualmachines.overview',
                        panelHeaderRouteParams: {},
                        panelIcon: 'cloud',
                        panelTitle: this.$translate.instant('9fd32096-6a1f-43c6-a467-4ef75d7593e7')
                    },
                    serviceItemList: []
                }
            ],
            // Dashboard main menu panel
            panelData: {
                mainHeader: {
                    icon: 'cloud',
                    isGranted: this.authContext.isGranted(UiRights.MACHINE_VM_CREATE),
                    route: 'machine.virtualmachines.new',
                    routeParams: {},
                    text: this.$translate.instant('TR_140119-9b5fa4_TR')
                }
            },
            simpleFilter: this.filterCache.resolveSimpleFilter('VirtualMachines')
        };
    };
}

export class TemplateMachineDashboardComponent implements ng.IComponentOptions {
    public bindings = {
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        showObjectId: '<',
        simpleFilter: '<'
    };
    public controller = TemplateMachineDashboardController;
    public controllerAs = '$TemplateMachineDashboardCtrl';
    public template = require('./dashboard-template.html');
}
