import * as ng from 'angular';

export class TemplateProfileController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public overviewUiSettings: any;
    public overviewUiDisplaySettings: any;

    public pageHeaderData;

    constructor(private $translate: ng.translate.ITranslateService) {
        this.pageHeaderData = {
            backwardLink: 'account.users.overview',
            backwardText: this.$translate.instant('TR_090119-9aec59_TR'),
            panelHeaderRoute: 'account.users.overview',
            panelIcon: 'user',
            panelTitle: this.$translate.instant('STATE.PROFILE.PAGE-TITLE')
        };
    }
}

export class TemplateProfileComponent implements ng.IComponentOptions {
    public bindings = {
        overviewUiDisplaySettings: '<',
        overviewUiSettings: '<'
    };
    public controller = TemplateProfileController;
    public template = require('./profile.html');
}
