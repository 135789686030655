import * as ng from 'angular';

import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { DisableFilter } from '../../../atomic-components/organisms/overview/overview';
import { DnsTemplateOverviewModel, FilterCacheService } from '../../../services';

export class TemplateDnsTemplateOverviewController {
    public static $inject: string[] = ['$translate', 'dnsTemplateOverviewModel', 'filterCache'];

    public pageHeaderData: PanelHeaderData = {
        backwardLink: 'dns.dashboard',
        backwardText: this.$translate.instant('TR_100119-a3c01f_TR'),
        panelHeaderRoute: '',
        panelIcon: 'globe',
        panelTitle: this.$translate.instant('STATE.DNS.TEMPLATE.PAGE-TITLE')
    };

    public disableFilter: DisableFilter = {
        fancyFilter: true,
        searchFilter: false
    };

    public overviewServiceData;

    private overviewUiSettings;

    constructor(
        private $translate: ng.translate.ITranslateService,

        /**
         * IMPORTANT
         * This is deliberately renamed from the 'DnsTemplateOverviewModel' injection
         * because other parts rely on this name!
         */
        public overviewModel: DnsTemplateOverviewModel,

        /* The other injections are normal. */
        private filterCache: FilterCacheService
    ) {}

    public $onInit() {
        this.overviewServiceData = {
            extendedFilters: this.filterCache.resolveExtendedFilters('dns.templates'),
            fancyFilterExpand: this.expertView,
            overviewService: 'dnsTemplate',
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.filterCache.resolveSimpleFilter('dns.templates')
        };
    }

    /* expertView: Only allow expert view */

    public get expertView() {
        return true;
    }
}

export class TemplateDnsTemplateOverviewComponent implements ng.IComponentOptions {
    public controller = TemplateDnsTemplateOverviewController;
    public controllerAs = 'TemplateDnsTemplateOverviewCtrl';
    public bindings = {
        overviewUiSettings: '<'
    };

    public template = require('./template-overview-template.html');
}
