import * as ng from 'angular';
import { PanelHeaderData } from '@/atomic-components/molecules';
import { AuthContextService, NavigationService } from '@/services';
import * as Types from '@/types';

import './page-header-panel.scss';

export interface ProductAction {
    actionFunction?: (arg0: unknown) => unknown;
    disabled?: boolean;
    route?: string;
    stateParams?: unknown;
    title: string;
}

export interface PageHeaderData extends PanelHeaderData {
    productActions?: ProductAction[];
    showSystemNotificationBell?: boolean;
    systemNotificationCallback?: (arg0?: unknown) => unknown;
}

interface Globals {
    bundle?: Types.BundleApi.Bundle;
    showAccounts?: boolean;
}

export class OrganismPageHeaderPanelController {
    public static $inject: string[] = ['$state', 'authContext', 'navigation'];

    public pageHeaderData: PageHeaderData;

    private _systemNotificationsVisible = false;

    constructor(
        private $state: ng.ui.IStateService,
        private authContext: AuthContextService,
        private navigation: NavigationService
    ) {}

    public $onInit(): void {
        this.pageHeaderData = this.pageHeaderData || null;
    }

    public isSetPageHeaderData(): boolean {
        return this.pageHeaderData !== null;
    }

    public get globals(): Globals {
        return this.$state.$current.locals.globals;
    }

    public get showSystemNotificationBell(): boolean {
        return this.pageHeaderData.showSystemNotificationBell;
    }

    private get routePath(): string[] {
        return this.$state.current.name.split('.');
    }

    public get currentSubaccountId(): string | undefined {
        if (!this.globals.showAccounts) {
            // Current account lacks permission or has no subaccounts.
            return undefined;
        }

        const indexOfFirstIdState = this.routePath.indexOf('id');

        if (indexOfFirstIdState < 0) {
            // Current state not associated with an product.
            return undefined;
        }

        let objectAccountId: string;

        /* If we commit to naming the product resolve exactly the same as the state step, we can skip this switch
         * and write "objectAccountId = this.globals[this.routePath[indexOfFirstIdStep - 1]].accountId;" instead.
         */
        switch (this.routePath[indexOfFirstIdState - 1]) {
            default: return undefined;

            case 'bundle':
                objectAccountId = this.globals.bundle.accountId;
                break;
        }

        switch (objectAccountId) {
            case this.authContext.account.id:
                 // Product owner is current account, not a subaccount;
                return undefined;

            case this.authContext.account.parentAccountId:
                 // Product owner is parent account, not a subaccount;
                return undefined;

            default: return objectAccountId;
        }
    }

    public gotToRoute = (route: string, routeParams: unknown, active: boolean): void => {
        if (active || route === undefined) {
            return;
        }

        routeParams = routeParams || {};
        void this.navigation.go(route, routeParams);
    };

    public get showProductActions(): boolean {
        return this.pageHeaderData.productActions !== undefined && this.pageHeaderData.productActions !== null;
    }

    // eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function
    public set systemNotificationsVisible({}) {}
    public get systemNotificationsVisible(): boolean {
        return this._systemNotificationsVisible;
    }

    public systemNotificationCallback = (): void => {
        this._systemNotificationsVisible = !this._systemNotificationsVisible;
        if (this.pageHeaderData.systemNotificationCallback) {
            this.pageHeaderData.systemNotificationCallback();
        }
    };
}

export class OrganismPageHeaderPanelComponent implements ng.IComponentOptions {
    public bindings = {
        pageHeaderData: '<'
    };
    public controller = OrganismPageHeaderPanelController;
    public template = require('./page-header-panel.html');
}
