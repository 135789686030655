import * as ng from 'angular';

import { CheckboxStatusChange } from '@/atomic-components/molecules/forms/form-elements/checkbox/checkbox';
import { MoleculePanelEditController } from '@/atomic-components/molecules/panels';
import {
    PermissionGroupSelectFunctions
} from '@/atomic-components/molecules/panels/panel-rows/panel-edit-row-permission/group-select-functions';
import * as Types from '@/types';

interface CheckboxData {
    exists: boolean;
    inTemplate?: boolean;
    permission?: Types.AccountApi.Right;
    selected?: boolean;
    statusChange?: CheckboxStatusChange;
}

type RegularPermission = 'list' | 'create' | 'edit' | 'delete' | 'restore';

export class MoleculePanelEditRowPermissionSubgroupController implements ng.IController {
    public static $inject: string[] = [];

    public $editPanel: MoleculePanelEditController;

    public parentName: string;
    public originalPermissions: string[];
    public selectedPermissions: string[];
    public templatePermissions: string[];
    public selectAllCheckboxTitle: string;
    public checkboxes: Record<RegularPermission, CheckboxData> = {
        list: { exists: false },
        create: { exists: false },
        edit: { exists: false },
        delete: { exists: false },
        restore: { exists: false }
    };

    private _permissionGroup: Types.AccountApi.RightGroup;

    public get permissionGroup(): Types.AccountApi.RightGroup {
        return this._permissionGroup;
    }

    public set permissionGroup(group: Types.AccountApi.RightGroup) {
        this._permissionGroup = group;

        this.checkboxes = {
            list: { exists: false },
            create: { exists: false },
            edit: { exists: false },
            delete: { exists: false },
            restore: { exists: false }
        };

        for (const type of Object.keys(this.checkboxes)) {
            const permission = this.permissionGroup.rights
                .filter((perm) => perm.type === type.toUpperCase())
                .pop();

            if (permission !== undefined) {
                this.checkboxes[type as RegularPermission].exists = true;
                this.checkboxes[type as RegularPermission].permission = permission;

                Object.defineProperty(
                    this.checkboxes[type as RegularPermission],
                    'inTemplate',
                    {
                        get: () => this.templatePermissions.indexOf(permission.value) >= 0
                    }
                );

                Object.defineProperty(
                    this.checkboxes[type as RegularPermission],
                    'selected',
                    {
                        get: () => this.selectedPermissions.indexOf(permission.value) >= 0,
                        set: (selected) => {
                            if (selected) {
                                this.selectedPermissions.push(permission.value);
                            } else {
                                this.selectedPermissions = this.selectedPermissions
                                    .filter((perm) => perm !== permission.value);
                            }
                        }
                    }
                );

                Object.defineProperty(
                    this.checkboxes[type as RegularPermission],
                    'statusChange',
                    {
                        get: () => {
                            if (!this.originalPermissions) {
                                return 'none';
                            }

                            const selectedBefore = this.originalPermissions.indexOf(permission.value) >= 0;
                            const selectedNow = this.selectedPermissions.indexOf(permission.value) >= 0;

                            if (selectedBefore && !selectedNow) {
                                return 'removed';
                            }

                            if (!selectedBefore && selectedNow) {
                                return 'added';
                            }

                            return 'none';
                        }
                    }
                );
            }
        }

        this.selectAllCheckboxTitle = PermissionGroupSelectFunctions
            .getPermissionsInGroup(this.permissionGroup)
            .join(', ');
    }

    public get editable(): boolean {
        return this.$editPanel.isEditable;
    }

    public get selectAllIndeterminate(): boolean {
        return PermissionGroupSelectFunctions.isGroupPartiallySelected(this.permissionGroup, this.selectedPermissions);
    }

    public get selectAllDisabled(): boolean {
        return PermissionGroupSelectFunctions.isGroupInTemplate(this.permissionGroup, this.templatePermissions);
    }

    public get selectAllStatusChange(): CheckboxStatusChange {
        if (!this.originalPermissions) {
            return 'none';
        }

        const removed = this.originalPermissions
        .filter(
            (permission) => PermissionGroupSelectFunctions.getPermissionsInGroup(this.permissionGroup)
            .indexOf(permission) >= 0
        )
        .filter(
            (permission) => this.selectedPermissions.indexOf(permission) < 0
        )
        .length;

        const added = this.selectedPermissions
        .filter(
            (permission) => PermissionGroupSelectFunctions.getPermissionsInGroup(this.permissionGroup)
            .indexOf(permission) >= 0
        )
        .filter(
            (permission) => this.originalPermissions.indexOf(permission) < 0
        )
        .length;

        if (added > 0 && removed > 0) {
            return 'modified';
        } else if (added > 0) {
            return 'added';
        } else if (removed > 0) {
            return 'removed';
        } else {
            return 'none';
        }
    }

    public get selectAllSelected(): boolean {
        return PermissionGroupSelectFunctions.isGroupSelected(this.permissionGroup, this.selectedPermissions);
    }

    public set selectAllSelected(selected: boolean) {
        this.selectedPermissions = PermissionGroupSelectFunctions.setSelection(
            this.permissionGroup,
            this.selectedPermissions,
            this.templatePermissions,
            selected
        );
    }

}

export class MoleculePanelEditRowPermissionSubgroupComponent implements ng.IComponentOptions {
    public bindings = {
        parentName: '@?',
        permissionGroup: '<group',
        originalPermissions: '<',
        selectedPermissions: '=',
        templatePermissions: '<'
    };

    public require = {
        $editPanel: '?^moleculePanelEdit'
    };

    public template = require('./panel-edit-row-permission-subgroup.html');
    public controller = MoleculePanelEditRowPermissionSubgroupController;
}
