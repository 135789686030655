/* eslint-disable */
import * as ng from 'angular';
import * as q from 'q';

type ModelCallback = (...args: any[]) => q.IPromise<any>;

export class RequestCanceller {
    private index = -1;
    private cancelPrevious: q.Deferred<void> = null;

    constructor(
        private model,
        private callback: ModelCallback,
        private timeout: number = 0
    ) {}

    public request(...args: any[]): q.IPromise<any> {
        if (this.cancelPrevious) {
            this.cancelPrevious.resolve();
        }
        this.cancelPrevious = q.defer();

        const requestIndex = ++this.index;
        const canceller = this;
        const cancellerResult = q.defer();

        setTimeout(
            () => {
                if (requestIndex !== canceller.index) {
                    cancellerResult.reject('Request has been cancelled.');
                } else {
                    cancellerResult.resolve('Timeout done.');
                }
            },
            this.timeout
        );

        return cancellerResult.promise
        .then(
            () => {
                return canceller.callback
                .apply(canceller.model, args.concat([canceller.cancelPrevious.promise]))
                .then(
                    (result) => {
                        if (requestIndex === canceller.index) {
                            return result;
                        } else {
                            return q.reject('Request has been cancelled.');
                        }
                    }
                );
            }
        );
    }
}

export class RequestCancellerService {

    /* START DEPRECATED ATTRIBUTES */
    private callback;
    private service;
    private cancel;
    private index;
    private timeout;
    /* END DEPRECATED ATTRIBUTES */

    public wrap(callback: ModelCallback, model, timeout?: number) {
        return new RequestCanceller(model, callback, timeout);
    }

    /* START DEPRECATED METHODS */
    public request = function() {
        const _len = arguments.length;
        const args = new Array(_len);

        for (let _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
        }

        if (this.cancel) {
            this.cancel.resolve();
        }
        this.cancel = q.defer();

        const requestIndex = ++this.index;
        const canceller = this;
        const cancellerResult = q.defer();

        setTimeout(
            () => {
                if (requestIndex !== canceller.index) {
                    cancellerResult.reject('Request has been cancelled.');
                } else {
                    cancellerResult.resolve('Timeout done.');
                }
            },
            this.timeout
        );

        return cancellerResult.promise
        .then(() => {
            return canceller.callback
            .apply(this.service, args.concat([canceller.cancel.promise]))
            .then((result) => {
                if (requestIndex === canceller.index) {
                    return result;
                } else {
                    return q.reject('Request has been cancelled.');
                }
            });
        });
    };

    public init(callback, service, timeout?) {
        this.callback = callback;
        this.service = service;
        this.cancel = null;
        this.index = -1;

        if ([undefined, null].indexOf(timeout) < 0 && ng.isNumber(timeout) && timeout >= 0) {
            this.timeout = timeout;
        } else {
            // Standardtimeout: Halbe Sekunde, bevor der Request abgeschickt wird.
            this.timeout = 800;
        }

        const copy: any = {};
        for (const key in this) {
            if (Object.prototype.hasOwnProperty.call(this, key)) {
                copy[key] = this[key];
            }
        }

        return copy;
    }
    /* END DEPRECATED METHODS */
}
/* eslint-enable */
