/**
 * Rough reimplementation of q.Deferred<T> from the q library.
 */
 export class Deferred<T> {
    private _promise: Promise<T>;
    private _resolve: (value: T | PromiseLike<T>) => void;
    private _reject: (reason?: unknown) => void;

    constructor() {
        this._promise = new Promise<T>(
            (resolve, reject) => {
                this._resolve = resolve;
                this._reject = reject;
            }
        );
    }

    public get promise(): Promise<T> {
        return this._promise;
    }

    public get resolve(): (value: T | PromiseLike<T>) => void {
        return this._resolve;
    }

    public reject(): (reason?: unknown) => void {
        return this._reject;
    }
}
