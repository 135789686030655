import * as ng from 'angular';

export interface FormInputModelOptions {
    debounce?: number;
}

export class AtomFormInputTextController implements ng.IController {
    public static $inject: string[] = ['$timeout'];

    public value: string;
    public placeholder: string;
    public inputId: string; // Has to be unique
    public inputName: string;
    public inputType: string;
    public autofill: string | boolean;
    public readonly: boolean;
    public disabled: boolean;
    public callbackOnChange: (arg0: any) => any;
    public callbackOnBlur: (callbackOnBlurParams: any) => any;
    public callbackOnBlurParams: any;
    public callbackOnFocus: (callbackOnFocusParams: any) => any;
    public callbackOnFocusParams: any;
    public hideCharacters: boolean;
    public repeatPassword: boolean;
    public showPwdHint: boolean;
    public modelOptions: FormInputModelOptions;
    public callbackOnEnter: () => {};
    public catchFocus: boolean;
    public callbackOnEsc: (callbackOnEscParams: any) => any;
    public callbackOnEscParams: any;
    public callbackOnPaste: (paste: any) => any;
    public callbackOnKeyUp: (event: KeyboardEvent) => any;
    public callbackOnClear: (callbackOnClearParams: any) => any;
    public callbackOnClearParams: any;
    public clearable: boolean;
    public clearableWhenReadonly: boolean;
    public maxlength: number;
    public pattern: string;
    public disableRandomisedId = false;
    public number;
    public step: string;

    private _inputObject: NodeListOf<HTMLInputElement>;
    private _focusSet: boolean;
    private doNotSaveOnFormEnter: boolean;
    private _issetInputId = false;

    constructor(
        private $timeout: ng.ITimeoutService
    ) {}

    public $onInit(): void {
        this.catchFocus = this.catchFocus || undefined;
        this.disabled = this.disabled || false;
        this.doNotSaveOnFormEnter = this.doNotSaveOnFormEnter || false;
        this.readonly = this.readonly || false;
        this.clearable = this.clearable || false;
        this.number = this.number || false;
        this.showPwdHint = this.showPwdHint || false;
        this.repeatPassword = this.repeatPassword === undefined ? true : this.repeatPassword;
        this.placeholder = this.placeholder || '';
        this.value = this.value || '';
        this.modelOptions = this.modelOptions || {};

        // Force disable with `autofill="'forceDisabled'"` in tempalte
        if (ng.isString(this.autofill) && ['on', 'true'].indexOf(this.autofill) < 0) {
            this.autofill = this.autofill.toString();
        } else if (this.autofill || this.autofill === 'true' || this.autofill === 'on') {
            this.autofill = 'on';
        } else {
            this.autofill = 'off';
        }

        this.$timeout(() => {
            // Run over input fields and look that obj has attribute autofocus = true
            this._inputObject = document.querySelectorAll('input');

            for (const input in this._inputObject) {
                if (ng.isElement(this._inputObject[input])) {
                    const inputObject = this._inputObject[input];

                    if (inputObject.getAttribute('autofocus') && !this._focusSet) {
                        this._focusSet = true;
                        this._inputObject[input].focus();
                    }
                }
            }
        });
    }

    public onChange(): void {
        if (this.callbackOnChange !== undefined) {
            this.$timeout(() => {
                this.callbackOnChange({value: this.value});
            }, 50);
        }
    }

    public onBlur(): void {
        if (this.callbackOnBlur !== undefined) {
            if (this.callbackOnBlurParams?.value !== undefined) {
                this.callbackOnBlurParams.value = this.value;
            }
            this.callbackOnBlur(this.callbackOnBlurParams);
        }
    }

    public onFocus(): void {
        if (this.callbackOnFocus !== undefined) {
            this.callbackOnFocus(this.callbackOnFocusParams);
        }
    }

    [s: string]: any;

    public clearValue = ($event: MouseEvent) => {
        if ($event !== undefined) {
            $event.stopPropagation();
        }

        if (this.clearable && (!this.readonly || this.clearableWhenReadonly)) {
            this.value = '';

            if (this.inputId !== undefined) {
                document.getElementById(this.inputId).focus();
            }

            if (this.callbackOnClear !== undefined) {
                this.callbackOnClear(this.callbackOnClearParams);
            }
        }
    };

    public onKeyUp = (event) => {
        if (this.callbackOnKeyUp !== undefined) {
            if (this.pattern !== undefined && ['$ctrl'].indexOf(this.pattern) === -1) {
                const numDef = new RegExp('' + this.pattern);

                if (event.key.match(numDef)) {
                    this.callbackOnKeyUp(event);
                }
            } else {
                this.callbackOnKeyUp(event);
            }
        }

        if (this.callbackOnEnter !== undefined && event.keyCode === 13 && !this.doNotSaveOnFormEnter) { // ENTER
            this.callbackOnEnter();
        }

        if (this.callbackOnEsc !== undefined && event.keyCode === 27 && !this.doNotSaveOnFormEnter) { // ESC
            if ([undefined, null].indexOf(this.callbackOnEscParams) >= 0) {
                this.callbackOnEsc(event);
            } else {
                this.callbackOnEsc(this.callbackOnEscParams);
            }
        }

        if (this.callbackOnArrowDown !== undefined && event.keyCode === 40 && !this.doNotSaveOnFormEnter) { // ArrowDown
            this.callbackOnArrowDown(this.callbackOnArrowDownParams);
        }
    };

    public onPaste = (paste) => {
        if (this.callbackOnPaste !== undefined) { // If paste
            this.callbackOnPaste(paste);
        }
    };

    public get callInputId() {
        // If an InputId is set, it must be given a unique id so that DOM does not throw an error.
        if (!this._issetInputId) {
            this.inputName = this.inputId; // inputName is inputId without unique identifier

            if (!this.disableRandomisedId) {
                const uniqueId = (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();

                if ([undefined, null, ''].indexOf(this.inputId) >= 0) {
                    this.inputId = uniqueId;
                } else {
                    this.inputId += ':' + uniqueId;
                }
            }

            this._issetInputId = true;
        }
        return this.inputId;
    }

    public showCharacters = () => {
        if (this.hideCharacters == null || this.hideCharacters === undefined) {
            return true;
        }

        return !this.hideCharacters;
    };

    public togglePwdHint = () => {
        this.showPwdHint = !this.showPwdHint;
    };

    public get showAutofocus() {
        if (this.catchFocus === true) {
            return true;
        } else {
            return undefined;
        }
    }

    public get tabIndex() {
        if (!this.disabled && !this.disabledTab) {
            return undefined;
        } else {
            return '-1';
        }
    }

    public get isClearable() {
        return [undefined, null].indexOf(this.value) < 0 && this.value.length > 0 && this.clearable;
    }
}

export class AtomFormInputTextComponent implements ng.IComponentOptions {
    public bindings = {
        autofill: '<?',
        callbackOnArrowDown: '<?',
        callbackOnArrowDownParams: '<?',
        callbackOnBlur: '<?',
        callbackOnBlurParams: '<?',
        callbackOnChange: '<?',
        callbackOnClear: '<?',
        callbackOnClearParams: '<?',
        callbackOnEnter: '<?',
        callbackOnEsc: '<?',
        callbackOnEscParams: '<?',
        callbackOnFocus: '<?',
        callbackOnFocusParams: '<?',
        callbackOnKeyUp: '<?',
        callbackOnNumberPress: '<?',
        callbackOnPaste: '<?',
        catchFocus: '<?',
        clearable: '<?',
        clearableWhenReadonly: '<?',
        disableRandomisedId: '<?',
        disabled: '<',
        disabledTab: '<',
        doNotSaveOnFormEnter: '<',
        hideCharacters: '<',
        inputId: '@',
        inputType: '<',
        maxlength: '<?',
        modelOptions: '<',
        pattern: '@?',
        placeholder: '@?',
        readonly: '<',
        repeatPassword: '<?',
        step: '@?',
        value: '='
    };
    public controller = AtomFormInputTextController;
    public template = require('./input-text.html');
}
