import * as ng from 'angular';
import { UiRights } from '../../configuration';
import {
    FilterCacheService,
    InstallerModelService,
    MachineJobModelService,
    MachineJobOverviewModel,
    MachineModelService,
    MachinePoolsOverviewModel,
    NavigationService,
    RedirectOnNotFoundCallback,
    UserSettingsManagerService,
    VirtualMachineOverviewModel
} from '../../services';

export const MACHINE_ROUTES = [
    {
        name: 'machine',
        state: {
            abstract: true,
            parent: 'authenticated',
            url: '/machine'
        }
    },
    {
        name: 'machine.dashboard',
        state: {
            data: {
                isGrantedAny: [UiRights.MACHINE_VM_LIST, UiRights.MACHINE_PM_LIST]
            },
            onEnter: (navigation: NavigationService, userSettingsManager: UserSettingsManagerService) => {
                userSettingsManager.getUiSettings().then(
                    (result) => {
                        if (result?.overviewSettings?.VirtualMachineOverviewCtrl?.expertView) {
                            navigation.go('machine.virtualmachines.overview');
                        }
                    }
                );
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('VirtualMachines');
                },
                overviewModel: (virtualMachineOverviewModel: VirtualMachineOverviewModel) => {
                    return virtualMachineOverviewModel;
                },
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('VirtualMachineOverviewCtrl');
                },
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('VirtualMachines');
                }
            },
            url: '/',
            views: {
                'content@authenticated': {
                    template: `<template-machine-dashboard
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                    show-object-id="$resolve.showObjectId"
                                ></template-machine-dashboard>`
                }
            }
        }
    },
    {
        name: 'machine.setup',
        state: {
            abstract: true,
            url: '/setup',
        }
    },
    {
        name: 'machine.setup.orderId',
        state: {
            resolve: {
                orderId: (
                    $stateParams: ng.ui.IStateParamsService,
                ) => {
                    return $stateParams.orderId;
                },
            },
            url: '/{orderId}',
            views: {
                'content@authenticated': {
                    template: '<template-machine-setup order-id="$resolve.orderId"></template-machine-setup>'
                }
            }
        }
    },
    {
        name: 'machine.pools',
        state: {
            data: {
                isGrantedAll: [UiRights.RES_POOL_LIST, UiRights.ADMIN_SYSTEM_SUPER_USER_READ]
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('MachinePools');
                },
                overviewModel: (machinePoolsOverviewModel: MachinePoolsOverviewModel) => machinePoolsOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('VirtualMachineOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('MachinePools');
                }
            },
            url: '/pools',
            views: {
                'content@authenticated': {
                    template: `<template-machine-pool-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                ></template-machine-pool-overview>`
                }
            }
        }
    },
    {
        name: 'machine.pools.new',
        state: {
            data: {
                isGranted: UiRights.RES_POOL_CREATE
            },
            resolve: {},
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-machine-pool-new></template-machine-pool-new>'
                }
            }
        }
    },
    {
        name: 'machine.virtualmachines',
        state: {
            abstract: true,
            url: '/virtualmachines'
        }
    },
    {
        name: 'machine.virtualmachines.jobs',
        state: {
            data: {
                isGranted: UiRights.MACHINE_JOBS_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('MachineJobs');
                },
                overviewModel: (machineJobOverviewModel: MachineJobOverviewModel) => machineJobOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('VirtualMachineOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('MachineJobs');
                }
            },
            url: '/jobs',
            views: {
                'content@authenticated': {
                    template: `<template-machine-job-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                ></template-machine-job-overview>`
                }
            }
        }
    },
    {
        name: 'machine.virtualmachines.jobs.id',
        state: {
            abstract: true,
            resolve: {
                job: (
                    $stateParams: ng.ui.IStateParamsService,
                    machineJobModel: MachineJobModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return machineJobModel.findOne($stateParams.jobId).then(null, redirectOnNotFound);
                }
            },
            url: '/id/{jobId}'
        }
    },
    {
        name: 'machine.virtualmachines.jobs.id.details',
        state: {
            url: '/details',
            views: {
                'content@authenticated': {
                    // tslint:disable-next-line: max-line-length
                    template: `<template-machine-job-details
                                    job="$resolve.job"
                                ></template-machine-job-details>`
                }
            }
        }
    },
    {
        name: 'machine.virtualmachines.overview',
        state: {
            data: {
                isGranted: UiRights.MACHINE_VM_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('VirtualMachines');
                },
                overviewModel: (virtualMachineOverviewModel: VirtualMachineOverviewModel) => {
                    return virtualMachineOverviewModel;
                },
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('VirtualMachineOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('VirtualMachines');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-virtual-machine-overview
                        simple-filter="$resolve.simpleFilter"
                        extended-filters="$resolve.extendedFilters"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        overview-model="$resolve.overviewModel"
                    ></template-virtual-machine-overview>`
                }
            }
        }
    },
    {
        name: 'machine.virtualmachines.id',
        state: {
            abstract: true,
            resolve: {
                machine: (
                    $stateParams: ng.ui.IStateParamsService,
                    machineModel: MachineModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return machineModel.findOneById($stateParams.machineId).then(null, redirectOnNotFound);
                }
            },
            url: '/id/{machineId}'
        }
    },
    {
        name: 'machine.virtualmachines.id.edit',
        state: {
            data: {
                isGranted: UiRights.MACHINE_VM_EDIT
            },
            resolve: {
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId().then((response) => {
                        return response;
                    });
                }
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-machine-edit
                        machine="$resolve.machine"
                        show-object-id="$resolve.showObjectId"
                    ></template-machine-edit>`
                }
            }
        }
    },
    {
        name: 'machine.virtualmachines.id.upgrade',
        state: {
            data: {
                isGranted: UiRights.MACHINE_VM_CHANGE_PRODUCT
            },
            url: '/product-change',
            views: {
                'content@authenticated': {
                    template: '<template-machine-upgrade machine="$resolve.machine"></template-machine-upgrade>'
                }
            }
        }
    },
    {
        name: 'machine.virtualmachines.id.vnc',
        state: {
            data: {
                isGranted: UiRights.MACHINE_VM_CHANGE_PRODUCT
            },
            url: '/vnc',
            views: {
                'sidemenu@': {
                    template: ''
                },
                'wrapper@': {
                    template: '<template-machine-vnc machine="$resolve.machine"></template-machine-vnc>'
                }
            }
        }
    },
    {
        name: 'machine.virtualmachines.id.edit.os',
        state: {
            resolve: {
                operatingSystems: (installerModel: InstallerModelService, machine) => {
                    return installerModel.list('os', machine.id);
                }
            },
            url: '/os',
            views: {
                'content@authenticated': {
                    template: `<template-machine-os-install
                        machine="$resolve.machine"
                        operating-systems="$resolve.operatingSystems"
                    ></template-machine-os-install>`
                }
            }
        }
    },
    {
        name: 'machine.virtualmachines.id.edit.interfaces',
        state: {
            url: '/interfaces',
            views: {
                'content@authenticated': {
                    template: `<template-machine-interfaces
                        machine="$resolve.machine"
                    ></template-machine-interfaces>`
                }
            }
        }
    },
    {
        name: 'machine.virtualmachines.new',
        state: {
            resolve: {
                appList: (installerModel: InstallerModelService) => {
                    return installerModel.list('app', {});
                }
            },
            data: {
                isGranted: UiRights.MACHINE_VM_CREATE
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-machine-wizard></template-machine-wizard>'
                }
            }
        }
    }
];
