import * as ng from 'angular';

export class MoleculeButtonRestoreController implements ng.IController {
    public static $inject: string[] = [];

    public restore: () => void;
    public modifier = '';
    public disabled = false;
}

export class MoleculeButtonRestoreComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        disabled: '<',
        modifier: '@?',
        restore: '<'
    };
    public controller =  MoleculeButtonRestoreController;
    public template = require('./button-restore.html');
}
