import * as ng from 'angular';

import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';

export class TemplateWebspaceAdminOptionsController {
    public static $inject: string[] = ['$state', '$translate', 'authContext'];

    public modifier = '';
    public pageHeaderData: PanelHeaderData;
    public webspace: any;

    public userPanelRight;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit = () => {
        const inBundle = this.$state.current.name.split('.').indexOf('bundle') >= 0;

        this.pageHeaderData = {
            backwardLink: inBundle
                ? 'bundle.id.dashboard'
                : 'webhosting.webspaces.id.edit',
            backwardText: inBundle
                ? this.$translate.instant('TR_090119-a32485_TR')
                : this.$translate.instant('TR_140119-e915b4_TR'),
            panelHeaderRoute: '',
            panelIcon: 'cogs',
            panelTitle: this.$translate.instant('TR_140119-57df70_TR')
        };

        this.userPanelRight = {
            admin: {
                editPanelButton: this.authContext.isGrantedAny([
                    UiRights.WEB_ADMIN_EMAIL_SENDER,
                    UiRights.WEB_ADMIN_WEBSPACE_ENABLE_DISABLE
                ])
            },
            email: {
                editPanelButton: this.authContext.isGranted(UiRights.WEB_ADMIN_EMAIL_SENDER)
            },
            webspace: {
                editPanelButton: this.authContext.isGranted(UiRights.WEB_ADMIN_WEBSPACE_ENABLE_DISABLE)
            }
        };
    };
}

export class TemplateWebspaceAdminOptionsComponent implements ng.IComponentOptions {
    public bindings = {
        webspace: '<'
    };
    public controller = TemplateWebspaceAdminOptionsController;
    public controllerAs = '$TemplateAdminOptionsCtrl';
    public template = require('./admin-options-template.html');
}
