import ng from 'angular';

import { OrganismEditFormNextcloudController } from '@/atomic-components';
import { EditPanelRight } from '@/atomic-components/molecules/panels/panel-edit/panel-edit.ts';
import { UiRights } from '@/configuration';
import {
    AuthContextService,
    NavigationService,
    PriceModelService,
    StorageModelService,
    PriceHelperService
} from '@/services';
import { ManagedApplicationApi, BillingApi } from '@/types';

export class OrganEditPanelNextcloudPurchaseAppController {
    public static $inject: string[] = ['$state', 'navigation', 'priceHelper', 'priceModel', 'storageModel'];
    public $editFormOrganism: OrganismEditFormNextcloudController;

    public panelRight: EditPanelRight = {
        editPanelButton: AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT)
    };

    public nextcloud: ManagedApplicationApi.Nextcloud;
    public appList: ManagedApplicationApi.NextcloudApp[];

    public nextcloudArticlePrice: BillingApi.ArticlePurchasePrice;

    public themingAppConfirm = false;
    public themeApp: {
        enabled?: boolean;
        app: ManagedApplicationApi.NextcloudApp;
        form?: {
            checkbox: boolean;
            pricingText: string;
        };
        articlePrice?: BillingApi.ArticlePurchasePrice;
    };

    public hidePrice = false;
    public isRoot = AuthContextService.isRootOrCompanyAccount;

    private _isNextcloudNotStorage: boolean;

    constructor(
        private $state: ng.ui.IStateService,
        private navigation: NavigationService,
        private priceHelper: PriceHelperService,
        private priceModel: PriceModelService,
        private storageModel: StorageModelService
    ) {}

    public $onInit(): void {
        this._isNextcloudNotStorage = this.$state.current.name.split('.')[0] === 'nextcloud';

        if (!this.appList) {
            return this.reset('no app list found');
        }

        this.themeApp = {
            app: this.appList.find((app) => app.id === 'theming')
        };

        if (!this.themeApp.app) {
            return this.reset('Theming app not found');
        }

        this.themeApp.enabled = this.nextcloud.enabledApps.indexOf(this.themeApp.app.id) > -1;

        void this.priceModel.getPriceByProductCode(this.themeApp.app.productCode).then(
            (price) => {
                this.themeApp.articlePrice = price;

                this.themeApp.form = {
                    checkbox: this.themeApp.enabled,
                    pricingText: this._priceString(this.themeApp.articlePrice)
                };
            },
            (error) => {
                return this.reset(error);
            }
        );
    }

    public reset = (error?: string): void => {
        if (error) {
            console.error(error);
        }
        this.hidePrice = true;

        this.nextcloud = ng.copy(this.$state.$current.locals.globals.storageProduct);
    };

    public save = (): PromiseLike<boolean> | PromiseLike<unknown> => {
        if (this.businessNextcloud || !this.themeApp.form.checkbox) {
            return;
        }

        const nextcloudCopy = ng.copy(this.nextcloud);
        nextcloudCopy.enabledApps.push(this.themeApp.app.id);

        const themingAppConfig = {
            appId: this.themeApp.app.id,
            configuration: JSON.stringify({
                nextcloudName: null,
                webLink: null,
                imprintLink: null,
                privacyLink: null,
                slogan: null,
                color: null,
                logoFile: null,
                backgroundFile: null,
                faviconFile: null,
                loginImageFile: null
            })
        };

        return this._save(nextcloudCopy, [themingAppConfig]);
    };

    public saveRemoveApp = (): PromiseLike<boolean> | PromiseLike<unknown> => {
        const nextcloudCopy = ng.copy(this.nextcloud);

        nextcloudCopy.enabledApps = nextcloudCopy.enabledApps.filter((app) => app !== this.themeApp.app.id);

        return this._save(nextcloudCopy);
    };

    public get businessNextcloud(): boolean {
        return this.nextcloud.productCode.indexOf('business') > -1;
    }

    public get appCenterRoute(): string {
        return this._isNextcloudNotStorage ? 'nextcloud.id.app' : 'storage.storage-products.id.app';
    }

    private _save = (
        nextcloud: ManagedApplicationApi.Nextcloud,
        themingAppConfigs?: ManagedApplicationApi.NextcloudAppConfigInput[]
    ): PromiseLike<unknown> => {
        return this.storageModel.nextcloudUpdate(nextcloud, themingAppConfigs).then(
            (response: { status: string }) => {
                if (['successful', 'pending'].indexOf(response.status) >= 0) {
                    this.navigation.reloadCurrentState(true);
                }
            },
            (error) => {
                this.reset(error);
            }
        );
    };

    private _priceString = (articlePrice: BillingApi.ArticlePurchasePrice): string => {
        if (!articlePrice) {
            return;
        }

        const price = this.priceHelper.calculateAndShowPrice(
            articlePrice,
            AuthContextService.account,
            null,
            null,
            false
        );
        const period = this.priceHelper.getBillingPeriod(articlePrice.contractPeriod);

        return `${price}, ${period}`;
    };
}

export class OrganEditPanelNextcloudPurchaseAppComponent implements ng.IComponentOptions {
    public bindings = {
        nextcloud: '<',
        appList: '<'
    };
    public template = require('./nextcloud-purchase-app.html');
    public controller = OrganEditPanelNextcloudPurchaseAppController;
}
