import { MachineProductsService } from '../services/machine/machine-products';

export class MachineProduct {
    public static $inject: string[] = [];

    private static cache = {};

    public static Factory(machineProducts: MachineProductsService) {
        const machineProduct: any = (productCode: string) => {
            if (MachineProduct.cache[productCode]) {
               return MachineProduct.cache[productCode];
            }

            MachineProduct.cache[productCode] = productCode;

            machineProducts.get()
            .then(
                (products) => {
                    const product = products.filter((productEl: { productCode: string }) => {
                        return (productEl.productCode === productCode);
                    })[0];

                    if ([undefined, null].indexOf(product) < 0 && [undefined, null].indexOf(product.name) < 0) {
                        MachineProduct.cache[productCode] = product.name;
                    }
                }
            );

            return MachineProduct.cache[productCode];
        };

        machineProduct.$stateful = true;

        return machineProduct;
    }
}
