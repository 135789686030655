import ng from 'angular';
import { UiRights } from '@/configuration';
import { AuthContextService } from '@/services';
import { Breadcrumb, BreadcrumbItem } from '../breadcrumb';

export interface TopicCases {
    [key: string]: () => Breadcrumb[];
}

export abstract class BreadcrumbTopicModel {
    public static $inject: string[] = ['$state', '$translate'];

    // Breadcrumb response object
    public breadcrumbs: Breadcrumb[] = [];

    // TopicName isset in subclass
    public abstract readonly topicName: string;

    // set some state parts for class (isset in breadcrum molecule - step and index in loop)
    public index: undefined | number;
    public step = '';
    public steps: string[] = [];

    /**
     *  excludeGeneralRouteParts
     *  An exclude list with state parts can be defined in a subclass.
     *  These state parts can then be taken into account when calling the generalRoute method (main class).
     */
    public abstract readonly excludeGeneralRouteParts: string[] = [];

    /**
     *  specialTopicCases
     *  In the subclass, specialTopiasCases can be used to define separate
     *  methods for state parts for the respective topic
     */
    public abstract readonly specialTopicCases: TopicCases = {};

    /**
     *  generalCases
     *  In the main class, standard method is defined for standard state parts.
     *  These can be overwritten or extended into a subclass and then executed by the generalRoutes method (main class).
     */
    protected generalCases: TopicCases = {};

    constructor(protected $state: ng.ui.IStateService, protected $translate: ng.translate.ITranslateService) {
        this.generalCases = {
            delete: this.getDeleteRoute,
            edit: this.getEditRoute,
            new: this.getNewRoute,
            overview: this.getOverviewRoute,
            rename: this.getRenameRoute,
            restore: this.getRestoreRoute,
            restrictions: this.getRestrictionsRoute
        };
    }

    public getFirstLevelData = (): Breadcrumb[] => this.breadcrumbs;

    public generalRoutes = (): Breadcrumb[] => {
        if (Object.prototype.hasOwnProperty.call(this.generalCases, this.step)) {
            return this.generalCases[this.step]();
        }
        return [];
    };

    public getBreadcrumb = (): Breadcrumb[] => {
        let generalRoutes: Breadcrumb[] = [];

        if (this.excludeGeneralRouteParts.indexOf(this.step) === -1) {
            // get general breadcrumb of main class only if state part is not as excluded defined
            generalRoutes = this.generalRoutes();
        }

        if (generalRoutes.length > 0) {
            this.breadcrumbs = this.breadcrumbs.concat(generalRoutes);
        } else {
            // special topic cases;
            if (Object.prototype.hasOwnProperty.call(this.specialTopicCases, this.step)) {
                this.breadcrumbs = this.specialTopicCases[this.step]();
            }
        }

        return this.breadcrumbs;
    };

    public getActiveDropdownValue = (item: string | BreadcrumbItem): string => {
        const stepname = item.name || item.part || item;
        switch (stepname) {
            case 'cancel':
                return this.$translate.instant('TR_140119-aee71e_TR');
            case 'dashboard':
                return this.$translate.instant('b1b21080-012f-41a9-b11e-7dd7237de1df');
            case 'databases':
                return this.$translate.instant('TR_090119-f0ccfb_TR');
            case 'edit':
                return this.$translate.instant('STATE.ACCOUNT.USER.APIKEY.EDIT.PAGE-TITLE');
            case 'id':
            case 'overview':
                return this.$translate.instant('MENU.CONTEXT.OVERVIEW');
            case 'mailboxes':
                return this.$translate.instant('c78b82a7-e70c-4ea5-a1da-52a99244c3b1');
            case 'nextcloud':
                return this.$translate.instant('TR_140519-4f0af6_TR');
            case 'order-backup':
                return this.$translate.instant('TR_020919-d8b187_TR');
            case 'jobs':
                return this.$translate.instant('c3a5df93-4e01-44e5-aef2-51b6c23c65c6');
            case 'create':
            case 'new':
                return this.$translate.instant('TR_080119-91316f_TR');
            case 'copy':
                return this.$translate.instant('GENERAL.ACTION.COPY');
            case 'webspaces':
                return this.$translate.instant('WEBHOSTING.GENERAL.WEBSPACES');
            case 'storage':
                return this.$translate.instant('TR_140519-4f0af6_TR'); // storage: TR_140519-4ff911_TR
            default:
                return item.translationId !== undefined ? this.$translate.instant(item.translationId) : stepname;
        }
    };

    public isActive = (activeStep: string): boolean => {
        const activeIndex = this._getActiveIndex(activeStep);
        let stepsLength = this.steps.length - 1;

        if (activeIndex < stepsLength && ['overview', 'dashboard'].indexOf(this.steps[stepsLength]) >= 0) {
            stepsLength--;
        }

        return activeIndex === stepsLength;
    };

    public ignoreStatePart = (): void => {
        return;
    };

    public getGrantedObject = (items: BreadcrumbItem[], part: string) => {
        const granted = {
            isGranted: undefined,
            isGrantedAll: undefined,
            isGrantedAny: undefined
        };
        items.some((item) => {
            if (item.part === part) {
                granted.isGranted = item.isGranted;
                granted.isGrantedAny = item.isGrantedAny;
                granted.isGrantedAll = item.isGrantedAll;

                return true;
            }
            return false;
        });

        return granted;
    };

    public checkIsGranted = (): boolean => {
        if ([undefined, null].indexOf(this.breadcrumbs) >= 0 || this.breadcrumbs.length === 0) {
            return;
        }
        this.breadcrumbs = this.breadcrumbs.filter((breadcrumb) => {
            // First level
            if (!this._isGranted(breadcrumb)) {
                return false;
            }

            if (breadcrumb.items !== undefined) {
                // Second level
                breadcrumb.items = breadcrumb.items.filter(this._isGranted);
            }

            return true;
        });
    };

    public get globals(): { [key: string]: any } {
        return this.$state.$current.locals.globals;
    }

    protected inBundle = (): boolean => {
        return [undefined, null].indexOf(this.globals.bundle) === -1;
    };

    protected getEditRoute = (): Breadcrumb[] => [
        {
            active: true,
            route: '',
            type: 'text',
            value: this.$translate.instant('GENERAL.ACTION.EDIT')
        }
    ];

    protected getNewRoute = (): Breadcrumb[] => [
        {
            active: true,
            route: '',
            type: 'text',
            value: this.$translate.instant('TR_080119-91316f_TR')
        }
    ];

    protected getOverviewRoute = (): Breadcrumb[] => [
        {
            active: true,
            route: '',
            type: 'text',
            value: this.$translate.instant('MENU.CONTEXT.OVERVIEW')
        }
    ];

    protected getTerminateRoute = (): Breadcrumb[] => [
        {
            active: true,
            route: '',
            type: 'text',
            value: this.$translate.instant('32420761-b4c3-4d5f-8450-985d2dd7e43f')
        }
    ];

    protected getDeleteRoute = (): Breadcrumb[] => [
        {
            active: true,
            route: '',
            type: 'text',
            value: this.$translate.instant('TR_140119-aee71e_TR')
        }
    ];

    protected getRenameRoute = (): Breadcrumb[] => [
        {
            active: true,
            isGranted: undefined,
            isGrantedAll: undefined,
            isGrantedAny: undefined,
            route: '',
            type: 'text',
            value: this.$translate.instant('TR_130819-53f92c_TR')
        }
    ];

    protected getRestoreRoute = (): Breadcrumb[] => [
        {
            active: true,
            route: '',
            type: 'text',
            value: this.$translate.instant('TR_140119-d1b7a4_TR')
        }
    ];

    protected getRestrictionsRoute = (): Breadcrumb[] => [
        {
            active: true,
            route: '',
            type: 'text',
            value: this.$translate.instant('TR_250519-7f279b_TR')
        }
    ];

    protected getIdRoute = (): Breadcrumb[] => [];

    protected getProductActionDropdown = (
        items: BreadcrumbItem[],
        routeParams?: { [key: string]: any },
        step?: string
    ): Breadcrumb[] => {
        const actionDropdownItems: BreadcrumbItem[] = [];
        const activeServiceObject: BreadcrumbItem = {
            active: true,
            isGranted: undefined,
            isGrantedAll: undefined,
            isGrantedAny: undefined,
            name: '',
            route: '',
            routeParams: {}
        };
        step = step || this.step;
        routeParams = routeParams || {};

        items.map((item) => {
            let active = false;
            if (item.part === step) {
                active = true;
                activeServiceObject.name = this.getActiveDropdownValue(item);
                activeServiceObject.route = item.route;
                activeServiceObject.routeParams = item.routeParams || routeParams;
                activeServiceObject.isGranted = item.isGranted;
                activeServiceObject.isGrantedAny = item.isGrantedAny;
                activeServiceObject.isGrantedAll = item.isGrantedAll;
            }

            actionDropdownItems.push({
                active: active,
                disabled: false,
                isGranted: item.isGranted,
                isGrantedAll: item.isGrantedAll,
                isGrantedAny: item.isGrantedAny,
                name: this.getActiveDropdownValue(item),
                route: item.route,
                routeParams: item.routeParams || routeParams
            });
        });

        return [
            {
                active: this.isActive(step),
                isGranted: activeServiceObject.isGranted,
                isGrantedAll: activeServiceObject.isGrantedAll,
                isGrantedAny: activeServiceObject.isGrantedAny,
                items: actionDropdownItems,
                route: this.isActive(step) ? '' : activeServiceObject.route,
                routeParams: this.isActive(step) ? {} : activeServiceObject.routeParams,
                type: 'dropdown',
                value: activeServiceObject.name
            }
        ];
    };

    /**
     *  Set dropdown list of service in product and bundle area
     */
    protected getServiceListDropdownBox = (activeServiceObject?: BreadcrumbItem): Breadcrumb[] => {
        let dropdownItems;
        const activeStep = activeServiceObject.step;

        if (this.inBundle()) {
            /**
             *  Dropdown in bundle
             */
            dropdownItems = [
                {
                    active: activeStep === 'mailboxes',
                    disabled: false,
                    isGranted: UiRights.EMAIL_MAILBOX_LIST,
                    name: this.$translate.instant('MENU.CONTEXT.EMAIL.ADDRESSES'),
                    route: 'bundle.id.mailboxes.overview',
                    routeParams: { bundleId: this.globals.bundle.id }
                },
                {
                    active: activeStep === 'domains',
                    disabled: false,
                    isGranted: UiRights.DOM_DOMAINS_LIST,
                    name: this.$translate.instant('TR_140119-aeb48a_TR'),
                    route: 'bundle.id.domains.overview',
                    routeParams: { bundleId: this.globals.bundle.id }
                },
                {
                    active: activeStep === 'databases',
                    disabled: false,
                    isGranted: UiRights.DB_DATABASE_LIST,
                    name: this.$translate.instant('TR_090119-f0ccfb_TR'),
                    route: 'bundle.id.databases.overview',
                    routeParams: { bundleId: this.globals.bundle.id }
                },
                {
                    active: activeStep === 'storage',
                    disabled: false,
                    name: this.$translate.instant('TR_STORAGE_TR'),
                    route: 'bundle.id.webspaces.id.storage',
                    routeParams: {
                        bundleId: this.globals.bundle.id,
                        webspaceId:
                            [undefined, null].indexOf(this.globals.webspace) >= 0 ? undefined : this.globals.webspace.id
                    }
                },
                {
                    active: activeStep === 'ftp-ssh',
                    disabled: false,
                    isGranted: UiRights.WEB_WEBSPACE_EDIT,
                    name: this.$translate.instant('TR_080119-3313e9_TR'),
                    route: 'bundle.id.webspaces.ftp-ssh',
                    routeParams: { bundleId: this.globals.bundle.id }
                },
                {
                    active: activeStep === 'backups',
                    disabled: false,
                    isGranted: UiRights.BACKUP_BACKUP_LIST,
                    name: this.$translate.instant('TR_080119-57a2c9_TR'),
                    route: 'bundle.id.backups.overview',
                    routeParams: { bundleId: this.globals.bundle.id }
                },
                {
                    active: activeStep === 'cronjobs',
                    disabled: false,
                    name: this.$translate.instant('e2a342f1-432b-41d5-b5de-68e07820d023'),
                    route: 'bundle.id.cronjobs.overview',
                    routeParams: { bundleId: this.globals.bundle.id }
                }
            ];
        } else {
            /**
             *  Dropdown in products
             */
            dropdownItems = [
                {
                    active: activeStep === 'domain',
                    disabled: false,
                    isGranted: UiRights.DOM_DOMAINS_LIST,
                    name: this.$translate.instant('TR_140119-aeb48a_TR'),
                    route: 'domain.dashboard',
                    routeParams: {}
                },
                {
                    active: activeStep === 'dns',
                    disabled: false,
                    isGranted: UiRights.DNS_ZONES_LIST,
                    name: this.$translate.instant('STATE.DNS.ZONE.BREADCRUMB'),
                    route: 'dns.dashboard',
                    routeParams: {}
                },
                {
                    active: activeStep === 'ssl',
                    disabled: false,
                    isGranted: UiRights.SSL_CERTIFICATES_LIST,
                    name: this.$translate.instant('cd85269f-9db9-4c6d-8d07-4b2a5fc31e6b'),
                    route: 'ssl.dashboard',
                    routeParams: {}
                },
                {
                    active: activeStep === 'mailboxes',
                    disabled: false,
                    isGranted: UiRights.EMAIL_MAILBOX_LIST,
                    name: this.$translate.instant('TR_080119-8dd4a1_TR'),
                    route: 'email.dashboard',
                    routeParams: {}
                },
                {
                    active: activeStep === 'webhosting',
                    disabled: false,
                    isGranted: UiRights.WEB_JOBS_LIST,
                    name: this.$translate.instant('f3636a70-980d-4267-b164-4746b4b314aa'),
                    route: 'webhosting.dashboard',
                    routeParams: {}
                },
                {
                    active: activeStep === 'database',
                    disabled: false,
                    isGranted: UiRights.DB_DATABASE_LIST,
                    name: this.$translate.instant('TR_090119-f0ccfb_TR'),
                    route: 'database.dashboard',
                    routeParams: {}
                },
                {
                    active: activeStep === 'machine',
                    disabled: false,
                    isGrantedAny: [UiRights.MACHINE_VM_LIST, UiRights.MACHINE_PM_LIST],
                    name: this.$translate.instant('9fd32096-6a1f-43c6-a467-4ef75d7593e7'),
                    route: 'machine.dashboard',
                    routeParams: {}
                },
                {
                    active: activeStep === 'storage',
                    disabled: false,
                    isGrantedAny: [UiRights.MANAGED_APPLICATION_NEXTCLOUD_LIST, UiRights.MANAGED_APPLICATION_JOBS_LIST],
                    name: this.$translate.instant('TR_140519-4f0af6_TR'), // storage: TR_140519-4ff911_TR
                    route: 'storage.dashboard',
                    routeParams: {}
                }
            ];
        }

        return [
            {
                active: this.isActive(activeStep),
                items: dropdownItems,
                route: this.isActive(activeStep) ? '' : activeServiceObject.route,
                routeParams: this.isActive(activeStep) ? {} : activeServiceObject.routeParams,
                type: 'dropdown',
                value: activeServiceObject.name
            }
        ];
    };

    protected jobDetailsRoute = (): Breadcrumb[] => {
        if (this.steps[this.index - 2] === 'jobs') {
            return [
                {
                    active: true,
                    type: 'text',
                    value: this.globals.job.displayName || this.globals.job.emailAddress
                }
            ];
        }
    };

    private _getActiveIndex = (activeStep: string): number => {
        let activeIndex = 0;
        this.steps = this.$state.current.name.split('.');
        this.steps.map((step, index) => {
            if (activeStep === step) {
                activeIndex = index;
            }
        });

        return activeIndex;
    };

    private _isGranted = (item: BreadcrumbItem): boolean => {
        if (item.isGranted !== undefined && !AuthContextService.isGranted(item.isGranted)) {
            return false;
        }
        if (item.isGrantedAny !== undefined && ng.isArray(item.isGrantedAny)) {
            return item.isGrantedAny.some((isGranted) => AuthContextService.isGranted(isGranted));
        }
        if (item.isGrantedAll !== undefined && ng.isArray(item.isGrantedAll)) {
            return item.isGrantedAll.every((isGranted) => AuthContextService.isGranted(isGranted));
        }
        return true;
    };
}
