import { DataObject } from '@/services/validators';
import { InputOptionsType } from '@/types/input-types/input-text';
import * as ng from 'angular';
import { FormInputModelOptions } from '../../../../atoms/form-elements';
import { ValidateOptions } from '../../../forms/form-elements';
import './panel-edit-row-string.scss';

export enum EditRowStringType {
    CUSTOM = 'custom',
    DEFAULT = 'default',
    DNS = 'dns',
    EMAIL = 'email',
    NOTEMPTY = 'notEmpty',
    NUMBER = 'number',
    PASSWORD_NEW = 'newPassword',
    PASSWORD_OLD = 'oldPassword',
    PASSWORD_REPEAT = 'passwordRepeat',
    PHONE = 'phoneNumber',
    URL = 'url'
}

export class MoleculePanelEditRowStringController implements ng.IController {
    public static $inject: string[] = [];

    public value: string;
    public placeholder: string;
    public readonly: boolean;
    public disabled: boolean;
    public callbackOnChange: (arg0: any) => any;
    public callbackOnChangeParams: any;
    public callbackOnBlur: (arg0: any) => any;
    public callbackOnBlurParams: any;
    public customCallbackOnEnter: () => {};
    public validationInstructions: DataObject[]; // Used to pass custom validators if type 'custom' is used
    public validationInstructionsOperator: string | '&&' | '||';
    public validationOptions: InputOptionsType; // Used to pass specific options if any other type is used
    public validationErrorList: any[];
    public validationErrorListOuter: any[];
    public type: EditRowStringType;
    public buttonRightIcon: string;
    public buttonRightText: string;
    public buttonLeftIcon: string;
    public buttonLeftText: string;
    public doNotSaveOnFormEnter: boolean;
    public disableRandomisedId: boolean;
    public recordType: false|string = false;
    public addOn: false|string;
    public modelOptions: FormInputModelOptions;
    public catchFocus: boolean;
    public inputIdRowString: string;
    public additionalObject: any;
    public callbackOnClear: (arg0: any) => any;
    public callbackOnClearParams: any;
    public clearable: boolean;
    public autofill: any;
    public onlyValidateOnChange = false;
    public step: string;
    public labelLeft = false;

    public $onInit() {
        if ([undefined, null].indexOf(this.type) >= 0) {
            this.type = EditRowStringType.DEFAULT;
        }

        this.addOn = this.addOn || false;
    }

    public showButtonRight = () => {
        return [undefined, null].indexOf(this.buttonRightIcon) < 0
        || [undefined, null].indexOf(this.buttonRightText) < 0;
    };

    public showButtonLeft = () => {
        return [undefined, null].indexOf(this.buttonLeftIcon) < 0
            || [undefined, null].indexOf(this.buttonLeftText) < 0;
    };
}

export class MoleculePanelEditRowStringComponent implements ng.IComponentOptions {
    public bindings = {
        additionalObject: '=',
        addOn: '@?',
        autofill: '<',
        buttonLeftIcon: '<',
        buttonLeftText: '@',
        buttonRightIcon: '@',
        buttonRightText: '@',
        callbackOnBlur: '<',
        callbackOnBlurParams: '<',
        callbackOnChange: '<',
        callbackOnChangeParams: '<',
        callbackOnClear: '<',
        callbackOnClearParams: '<',
        catchFocus: '<',
        clearable: '<',
        customCallbackOnEnter: '<',
        disabled: '<',
        disableRandomisedId: '<?',
        doNotSaveOnFormEnter: '<',
        inputIdRowString: '@?inputId',
        labelLeft: '<?',
        modelOptions: '<',
        onlyValidateOnChange: '<?',
        placeholder: '@',
        readonly: '<',
        recordType: '=',
        step: '@',
        type: '@',
        validationErrorList: '=?',
        validationErrorListOuter: '=?',
        validationInstructions: '<',
        validationInstructionsOperator: '@?',
        validationOptions: '<',
        value: '='
    };

    public transclude = {
        buttons: '?rowButtons',
        label: '?rowLabel'
    };

    public controller = MoleculePanelEditRowStringController;
    public template = require('./panel-edit-row-string.html');
}
