// if this Lists needs updating, ask Michi
export const UiRights = {
    ACC_ACCOUNT_APIKEYS_LIST: 'ACC_ACCOUNT_APIKEYS_LIST',
    ACC_ACCOUNT_APIKEYS_MANAGE: 'ACC_ACCOUNT_APIKEYS_MANAGE',
    ACC_ACCOUNT_BASICDATA_EDIT: 'ACC_ACCOUNT_BASICDATA_EDIT',
    ACC_ACCOUNT_BASICDATA_LIST: 'ACC_ACCOUNT_BASICDATA_LIST',
    ACC_ACCOUNT_EDIT_WHITE_LABEL: 'ACC_ACCOUNT_EDIT_WHITE_LABEL',
    ACC_ACCOUNT_EMAILMESSAGETEMPLATE_CREATE: 'ACC_ACCOUNT_EMAILMESSAGETEMPLATE_CREATE',
    ACC_ACCOUNT_EMAILMESSAGETEMPLATE_DELETE: 'ACC_ACCOUNT_EMAILMESSAGETEMPLATE_DELETE',
    ACC_ACCOUNT_EMAILMESSAGETEMPLATE_EDIT: 'ACC_ACCOUNT_EMAILMESSAGETEMPLATE_EDIT',
    ACC_ACCOUNT_EMAILMESSAGETEMPLATE_LIST: 'ACC_ACCOUNT_EMAILMESSAGETEMPLATE_LIST',
    ACC_ACCOUNT_LOGIN_AS_SUBACCOUNT: 'ACC_ACCOUNT_LOGIN_AS_SUBACCOUNT',
    ACC_ACCOUNT_RIGHTSTEMPLATE_CREATE: 'ACC_ACCOUNT_RIGHTSTEMPLATE_CREATE',
    ACC_ACCOUNT_RIGHTSTEMPLATE_DELETE: 'ACC_ACCOUNT_RIGHTSTEMPLATE_DELETE',
    ACC_ACCOUNT_RIGHTSTEMPLATE_EDIT: 'ACC_ACCOUNT_RIGHTSTEMPLATE_EDIT',
    ACC_ACCOUNT_RIGHTSTEMPLATE_LIST: 'ACC_ACCOUNT_RIGHTSTEMPLATE_LIST',
    ACC_ACCOUNT_USER_CREATE: 'ACC_ACCOUNT_USER_CREATE',
    ACC_ACCOUNT_USER_DELETE: 'ACC_ACCOUNT_USER_DELETE',
    ACC_ACCOUNT_USER_EDIT: 'ACC_ACCOUNT_USER_EDIT',
    ACC_ACCOUNT_USER_LIST: 'ACC_ACCOUNT_USER_LIST',
    ACC_JOBS_LIST: 'ACC_JOBS_LIST',
    ACC_SUBACCOUNT_APIKEYS_LIST: 'ACC_SUBACCOUNT_APIKEYS_LIST',
    ACC_SUBACCOUNT_APIKEYS_MANAGE: 'ACC_SUBACCOUNT_APIKEYS_MANAGE',
    ACC_SUBACCOUNT_CREATE: 'ACC_SUBACCOUNT_CREATE',
    ACC_SUBACCOUNT_DELETE: 'ACC_SUBACCOUNT_DELETE',
    ACC_SUBACCOUNT_EDIT: 'ACC_SUBACCOUNT_EDIT',
    ACC_SUBACCOUNT_EMAILMESSAGETEMPLATE_CREATE: 'ACC_SUBACCOUNT_EMAILMESSAGETEMPLATE_CREATE',
    ACC_SUBACCOUNT_EMAILMESSAGETEMPLATE_DELETE: 'ACC_SUBACCOUNT_EMAILMESSAGETEMPLATE_DELETE',
    ACC_SUBACCOUNT_EMAILMESSAGETEMPLATE_EDIT: 'ACC_SUBACCOUNT_EMAILMESSAGETEMPLATE_EDIT',
    ACC_SUBACCOUNT_EMAILMESSAGETEMPLATE_LIST: 'ACC_SUBACCOUNT_EMAILMESSAGETEMPLATE_LIST',
    ACC_SUBACCOUNT_LIST: 'ACC_SUBACCOUNT_LIST',
    ACC_SUBACCOUNT_LOCK: 'ACC_SUBACCOUNT_LOCK',
    ACC_SUBACCOUNT_RIGHTSTEMPLATE_CREATE: 'ACC_SUBACCOUNT_RIGHTSTEMPLATE_CREATE',
    ACC_SUBACCOUNT_RIGHTSTEMPLATE_DELETE: 'ACC_SUBACCOUNT_RIGHTSTEMPLATE_DELETE',
    ACC_SUBACCOUNT_RIGHTSTEMPLATE_EDIT: 'ACC_SUBACCOUNT_RIGHTSTEMPLATE_EDIT',
    ACC_SUBACCOUNT_RIGHTSTEMPLATE_LIST: 'ACC_SUBACCOUNT_RIGHTSTEMPLATE_LIST',
    ACC_SUBACCOUNT_SET_NON_DEFAULT_CREDIT_LIMIT: 'ACC_SUBACCOUNT_SET_NON_DEFAULT_CREDIT_LIMIT',
    ACC_SUBACCOUNT_SET_NON_DEFAULT_RIGHTS: 'ACC_SUBACCOUNT_SET_NON_DEFAULT_RIGHTS',
    ACC_SUBACCOUNT_UNLOCK: 'ACC_SUBACCOUNT_UNLOCK',
    ACC_SUBACCOUNT_USER_CREATE: 'ACC_SUBACCOUNT_USER_CREATE',
    ACC_SUBACCOUNT_USER_DELETE: 'ACC_SUBACCOUNT_USER_DELETE',
    ACC_SUBACCOUNT_USER_EDIT: 'ACC_SUBACCOUNT_USER_EDIT',
    ACC_SUBACCOUNT_USER_LIST: 'ACC_SUBACCOUNT_USER_LIST',
    ACC_SUBACCOUNT_USER_PROMOTE_TO_ADMIN: 'ACC_SUBACCOUNT_USER_PROMOTE_TO_ADMIN',
    ACC_USER_LOGGED_IN_AS_SUBACCOUNT: 'ACC_USER_LOGGED_IN_AS_SUBACCOUNT',
    ADMIN_HAND_DOWN_PROTECTED_RIGHTS: 'ADMIN_HAND_DOWN_PROTECTED_RIGHTS',
    ADMIN_PRV_ADV_PRINT: 'ADMIN_PRV_ADV_PRINT',
    ADMIN_SYSTEM_DOMAIN_DEVOPS: 'ADMIN_SYSTEM_DOMAIN_DEVOPS',
    ADMIN_SYSTEM_DOMAIN_SUPPORT: 'ADMIN_SYSTEM_DOMAIN_SUPPORT',
    ADMIN_SYSTEM_GENERAL_SUPPORT: 'ADMIN_SYSTEM_GENERAL_SUPPORT',
    ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN: 'ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN',
    ADMIN_SYSTEM_ISC_PRIVATE_METHODS: 'ADMIN_SYSTEM_ISC_PRIVATE_METHODS',
    ADMIN_SYSTEM_JOBS_LIST: 'ADMIN_SYSTEM_JOBS_LIST',
    ADMIN_SYSTEM_MANAGED_WEBSERVER_REINSTALL: 'ADMIN_SYSTEM_MANAGED_WEBSERVER_REINSTALL',
    ADMIN_SYSTEM_MIGRATION: 'ADMIN_SYSTEM_MIGRATION',
    ADMIN_SYSTEM_SUPER_USER_EDIT: 'ADMIN_SYSTEM_SUPER_USER_EDIT',
    ADMIN_SYSTEM_SUPER_USER_READ: 'ADMIN_SYSTEM_SUPER_USER_READ',
    ADMIN_SYSTEM_WEBHOSTING_ADMIN: 'ADMIN_SYSTEM_WEBHOSTING_ADMIN',
    ADMIN_SYSTEM_WEBHOSTING_SUPPORT: 'ADMIN_SYSTEM_WEBHOSTING_SUPPORT',
    BACKUP_BACKUP_LIST: 'BACKUP_BACKUP_LIST',
    BACKUP_BACKUP_OBJECT_CREATE: 'BACKUP_BACKUP_OBJECT_CREATE',
    BACKUP_BACKUP_OBJECT_DELETE: 'BACKUP_BACKUP_OBJECT_DELETE',
    BACKUP_BACKUP_OBJECT_EDIT: 'BACKUP_BACKUP_OBJECT_EDIT',
    BACKUP_BACKUP_OBJECT_LIST: 'BACKUP_BACKUP_OBJECT_LIST',
    BACKUP_CHANGE_CONCURRENCY_LIMITS: 'BACKUP_CHANGE_CONCURRENCY_LIMITS',
    BACKUP_DELETE_BACKUP_DATABASE: 'BACKUP_DELETE_BACKUP_DATABASE',
    BACKUP_DELETE_BACKUP_VM: 'BACKUP_DELETE_BACKUP_VM',
    BACKUP_DELETE_BACKUP_WEBSPACE: 'BACKUP_DELETE_BACKUP_WEBSPACE',
    BACKUP_JOBS_LIST: 'BACKUP_JOBS_LIST',
    BACKUP_RESTORE_DATABASE: 'BACKUP_RESTORE_DATABASE',
    BACKUP_RESTORE_VM: 'BACKUP_RESTORE_VM',
    BACKUP_RESTORE_WEBSPACE: 'BACKUP_RESTORE_WEBSPACE',
    BACKUP_START_MANUAL_BACKUP_DATABASE: 'BACKUP_START_MANUAL_BACKUP_DATABASE',
    BACKUP_START_MANUAL_BACKUP_VM: 'BACKUP_START_MANUAL_BACKUP_VM',
    BACKUP_START_MANUAL_BACKUP_WEBHOSTING: 'BACKUP_START_MANUAL_BACKUP_WEBHOSTING',
    BIL_ARTICLE_PRICES_DELETE: 'BIL_ARTICLE_PRICES_DELETE',
    BIL_ARTICLE_RETAIL_PRICES_CREATE: 'BIL_ARTICLE_RETAIL_PRICES_CREATE',
    BIL_ARTICLE_RETAIL_PRICES_EDIT: 'BIL_ARTICLE_RETAIL_PRICES_EDIT',
    BIL_ARTICLE_RETAIL_PRICES_LIST: 'BIL_ARTICLE_RETAIL_PRICES_LIST',
    BIL_BUNDLE_CREATE: 'BIL_BUNDLE_CREATE',
    BIL_BUNDLE_DELETE: 'BIL_BUNDLE_DELETE',
    BIL_BUNDLE_EDIT: 'BIL_BUNDLE_EDIT',
    BIL_BUNDLE_LIST: 'BIL_BUNDLE_LIST',
    BIL_BUNDLE_RESTORE: 'BIL_BUNDLE_RESTORE',
    BIL_DEPOSIT_FUND: 'BIL_DEPOSIT_FUND',
    BIL_GET_DEPOSIT: 'BIL_GET_DEPOSIT',
    BIL_LIST_ARTICLE_PRICES: 'BIL_LIST_ARTICLE_PRICES',
    BIL_LIST_BOOKINGS: 'BIL_LIST_BOOKINGS',
    BIL_LIST_DOCUMENTS: 'BIL_LIST_DOCUMENTS',
    BIL_LIST_JOBS: 'BIL_LIST_JOBS',
    BIL_LIST_PAYMENTS: 'BIL_LIST_PAYMENTS',
    BIL_LIST_PRICES_DATABASE: 'BIL_LIST_PRICES_DATABASE',
    BIL_LIST_PRICES_DNS: 'BIL_LIST_PRICES_DNS',
    BIL_LIST_PRICES_DOMAIN: 'BIL_LIST_PRICES_DOMAIN',
    BIL_LIST_PRICES_EMAIL: 'BIL_LIST_PRICES_EMAIL',
    BIL_LIST_PRICES_MACHINE: 'BIL_LIST_PRICES_MACHINE',
    BIL_LIST_PRICES_SSL: 'BIL_LIST_PRICES_SSL',
    BIL_LIST_PRICES_WEBHOSTING: 'BIL_LIST_PRICES_WEBHOSTING',
    BIL_LIST_PRICES_MANAGEDAPPLICATION: 'BIL_LIST_PRICES_MANAGEDAPPLICATION',
    BIL_LIST_RENEWABLE_OBJECTS: 'BIL_LIST_RENEWABLE_OBJECTS',
    DB_DATABASE_CREATE: 'DB_DATABASE_CREATE',
    DB_DATABASE_DELETE: 'DB_DATABASE_DELETE',
    DB_DATABASE_EDIT: 'DB_DATABASE_EDIT',
    DB_DATABASE_EDIT_ACCESS: 'DB_DATABASE_EDIT_ACCESS',
    DB_DATABASE_LIST: 'DB_DATABASE_LIST',
    DB_DATABASE_RESTORE: 'DB_DATABASE_RESTORE',
    DB_DATABASE_SET_DBNAME: 'DB_DATABASE_SET_DBNAME',
    DB_JOBS_LIST: 'DB_JOBS_LIST',
    DB_USER_CREATE: 'DB_USER_CREATE',
    DB_USER_DELETE: 'DB_USER_DELETE',
    DB_USER_EDIT: 'DB_USER_EDIT',
    DB_USER_LIST: 'DB_USER_LIST',
    DB_USER_SET_DBNAME: 'DB_USER_SET_DBNAME',
    DEMO_ACC_PRV_ACCOUNT_APIKEY_CREATE: 'DEMO_ACC_PRV_ACCOUNT_APIKEY_CREATE',
    DNS_DDNSCREDENTIALS_CREATE: 'DNS_DDNSCREDENTIALS_CREATE',
    DNS_DDNSCREDENTIALS_DELETE: 'DNS_DDNSCREDENTIALS_DELETE',
    DNS_DDNSCREDENTIALS_EDIT: 'DNS_DDNSCREDENTIALS_EDIT',
    DNS_DDNSCREDENTIALS_LIST: 'DNS_DDNSCREDENTIALS_LIST',
    DNS_DDNSHOSTNAME_CREATE: 'DNS_DDNSHOSTNAME_CREATE',
    DNS_DDNSHOSTNAME_DELETE: 'DNS_DDNSHOSTNAME_DELETE',
    DNS_DDNSHOSTNAME_EDIT: 'DNS_DDNSHOSTNAME_EDIT',
    DNS_DDNSHOSTNAME_LIST: 'DNS_DDNSHOSTNAME_LIST',
    DNS_DDNSHOSTNAME_RESTORE: 'DNS_DDNSHOSTNAME_RESTORE',
    DNS_NSSETS_CREATE: 'DNS_NSSETS_CREATE',
    DNS_NSSETS_DELETE: 'DNS_NSSETS_DELETE',
    DNS_NSSETS_EDIT: 'DNS_NSSETS_EDIT',
    DNS_NSSETS_LIST: 'DNS_NSSETS_LIST',
    DNS_TEMPLATES_CREATE: 'DNS_TEMPLATES_CREATE',
    DNS_TEMPLATES_DELETE: 'DNS_TEMPLATES_DELETE',
    DNS_TEMPLATES_EDIT: 'DNS_TEMPLATES_EDIT',
    DNS_TEMPLATES_LIST: 'DNS_TEMPLATES_LIST',
    DNS_ZONES_CREATE: 'DNS_ZONES_CREATE',
    DNS_ZONES_DELETE: 'DNS_ZONES_DELETE',
    DNS_ZONES_DISABLE: 'DNS_ZONES_DISABLE',
    DNS_ZONES_DNSSEC_EDIT: 'DNS_ZONES_DNSSEC_EDIT',
    DNS_ZONES_EDIT: 'DNS_ZONES_EDIT',
    DNS_ZONES_LIST: 'DNS_ZONES_LIST',
    DNS_ZONES_MOVE: 'DNS_ZONES_MOVE',
    DOM_CONTACTS_CREATE: 'DOM_CONTACTS_CREATE',
    DOM_CONTACTS_DELETE: 'DOM_CONTACTS_DELETE',
    DOM_CONTACTS_EDIT: 'DOM_CONTACTS_EDIT',
    DOM_CONTACTS_LIST: 'DOM_CONTACTS_LIST',
    DOM_DOMAINS_AUTHINFO_CREATE2: 'DOM_DOMAINS_AUTHINFO_CREATE2',
    DOM_DOMAINS_AUTHINFO_CREATE: 'DOM_DOMAINS_AUTHINFO_CREATE',
    DOM_DOMAINS_AUTHINFO_DELETE: 'DOM_DOMAINS_AUTHINFO_DELETE',
    DOM_DOMAINS_AUTHINFO_LIST: 'DOM_DOMAINS_AUTHINFO_LIST',
    DOM_DOMAINS_CREATE_WITH_CONTACTS: 'DOM_DOMAINS_CREATE_WITH_CONTACTS',
    DOM_DOMAINS_CREATE_WITH_DEFAULTS: 'DOM_DOMAINS_CREATE_WITH_DEFAULTS',
    DOM_DOMAINS_CREATE_WITH_OWN_NS: 'DOM_DOMAINS_CREATE_WITH_OWN_NS',
    DOM_DOMAINS_DELETE: 'DOM_DOMAINS_DELETE',
    DOM_DOMAINS_EDIT: 'DOM_DOMAINS_EDIT',
    DOM_DOMAINS_EDIT_DNSSEC_DATA: 'DOM_DOMAINS_EDIT_DNSSEC_DATA',
    DOM_DOMAINS_EDIT_NS_ENTRIES: 'DOM_DOMAINS_EDIT_NS_ENTRIES',
    DOM_DOMAINS_EDIT_OWNERCHANGE: 'DOM_DOMAINS_EDIT_OWNERCHANGE',
    DOM_DOMAINS_EDIT_STATUS: 'DOM_DOMAINS_EDIT_STATUS',
    DOM_DOMAINS_LIST: 'DOM_DOMAINS_LIST',
    DOM_DOMAINS_MOVE: 'DOM_DOMAINS_MOVE',
    DOM_DOMAINS_RENEW: 'DOM_DOMAINS_RENEW',
    DOM_DOMAINS_RESTORE: 'DOM_DOMAINS_RESTORE',
    DOM_DOMAINS_SUPPLIERCHANGE: 'DOM_DOMAINS_SUPPLIERCHANGE',
    DOM_DOMAINS_TRANSFER_OUT_PROCESS_REQUESTS: 'DOM_DOMAINS_TRANSFER_OUT_PROCESS_REQUESTS',
    DOM_JOBS_CANCEL: 'DOM_JOBS_CANCEL',
    DOM_JOBS_LIST: 'DOM_JOBS_LIST',
    EMAIL_CATCHALL_CREATE: 'EMAIL_CATCHALL_CREATE',
    EMAIL_CATCHALL_DELETE: 'EMAIL_CATCHALL_DELETE',
    EMAIL_CATCHALL_EDIT: 'EMAIL_CATCHALL_EDIT',
    EMAIL_DOMAINSETTINGS_EDIT: 'EMAIL_DOMAINSETTINGS_EDIT',
    EMAIL_DOMAINSETTINGS_LIST: 'EMAIL_DOMAINSETTINGS_LIST',
    EMAIL_EXCHANGE_CREATE: 'EMAIL_EXCHANGE_CREATE',
    EMAIL_FORWARDER_CREATE: 'EMAIL_FORWARDER_CREATE',
    EMAIL_IMAPMAILBOX_CREATE: 'EMAIL_IMAPMAILBOX_CREATE',
    EMAIL_IMAPMAILBOX_EDIT_PASSWORD: 'EMAIL_IMAPMAILBOX_EDIT_PASSWORD',
    EMAIL_IMAPMAILBOX_EDIT_STORAGE_QUOTA: 'EMAIL_IMAPMAILBOX_EDIT_STORAGE_QUOTA',
    EMAIL_IMAPMAILBOX_OVERRIDE_QUOTA: 'EMAIL_IMAPMAILBOX_OVERRIDE_QUOTA',
    EMAIL_JOBS_LIST: 'EMAIL_JOBS_LIST',
    EMAIL_MAILBOX_DELETE: 'EMAIL_MAILBOX_DELETE',
    EMAIL_MAILBOX_EDIT: 'EMAIL_MAILBOX_EDIT',
    EMAIL_MAILBOX_LIST: 'EMAIL_MAILBOX_LIST',
    EMAIL_MAILBOX_RESTORE: 'EMAIL_MAILBOX_RESTORE',
    EMAIL_MAILINGLIST_CREATE: 'EMAIL_MAILINGLIST_CREATE',
    EMAIL_ORGANIZATION_CREATE: 'EMAIL_ORGANIZATION_CREATE',
    EMAIL_ORGANIZATION_DELETE: 'EMAIL_ORGANIZATION_DELETE',
    EMAIL_ORGANIZATION_EDIT: 'EMAIL_ORGANIZATION_EDIT',
    EMAIL_ORGANIZATION_LIST: 'EMAIL_ORGANIZATION_LIST',
    EMAIL_SMTP_FORWARDER_CREATE: 'EMAIL_SMTP_FORWARDER_CREATE',
    MACHINE_JOBS_LIST: 'MACHINE_JOBS_LIST',
    MACHINE_MANAGED_SERVER_CREATE: 'MACHINE_MANAGED_SERVER_CREATE',
    MACHINE_PM_CREATE: 'MACHINE_PM_CREATE',
    MACHINE_PM_LIST: 'MACHINE_PM_LIST',
    MACHINE_VM_CHANGE_PRODUCT: 'MACHINE_VM_CHANGE_PRODUCT',
    MACHINE_VM_CREATE: 'MACHINE_VM_CREATE',
    MACHINE_VM_DELETE: 'MACHINE_VM_DELETE',
    MACHINE_VM_EDIT: 'MACHINE_VM_EDIT',
    MACHINE_VM_LIST: 'MACHINE_VM_LIST',
    MACHINE_VM_RESTORE: 'MACHINE_VM_RESTORE',
    MANAGED_APPLICATION_JOBS_LIST: 'MANAGED_APPLICATION_JOBS_LIST',
    MANAGED_APPLICATION_NEXTCLOUD_CREATE: 'MANAGED_APPLICATION_NEXTCLOUD_CREATE',
    MANAGED_APPLICATION_NEXTCLOUD_DELETE: 'MANAGED_APPLICATION_NEXTCLOUD_DELETE',
    MANAGED_APPLICATION_NEXTCLOUD_EDIT: 'MANAGED_APPLICATION_NEXTCLOUD_EDIT',
    MANAGED_APPLICATION_NEXTCLOUD_LIST: 'MANAGED_APPLICATION_NEXTCLOUD_LIST',
    MANAGED_APPLICATION_NEXTCLOUD_RESTORE: 'MANAGED_APPLICATION_NEXTCLOUD_RESTORE',
    RES_IPPOOL_CREATE: 'RES_IPPOOL_CREATE',
    RES_IPPOOL_EDIT: 'RES_IPPOOL_EDIT',
    RES_IPPOOL_LIST: 'RES_IPPOOL_LIST',
    RES_JOBS_LIST: 'RES_JOBS_LIST',
    RES_NETWORK_DELETE: 'RES_NETWORK_DELETE',
    RES_NETWORK_EDIT: 'RES_NETWORK_EDIT',
    RES_NETWORK_LIST: 'RES_NETWORK_LIST',
    RES_NETWORK_RESTORE: 'RES_NETWORK_RESTORE',
    RES_POOL_CREATE: 'RES_POOL_CREATE',
    RES_POOL_EDIT: 'RES_POOL_EDIT',
    RES_POOL_LIST: 'RES_POOL_LIST',
    RES_PRIVATE_IP_ADDRESS_RANGE_CREATE: 'RES_PRIVATE_IP_ADDRESS_RANGE_CREATE',
    RES_PRIVATE_IP_ADDRESS_RANGE_DELETE: 'RES_PRIVATE_IP_ADDRESS_RANGE_DELETE',
    RES_PRIVATE_IP_ADDRESS_RANGE_EDIT: 'RES_PRIVATE_IP_ADDRESS_RANGE_EDIT',
    RES_PRIVATE_IP_ADDRESS_RANGE_LIST: 'RES_PRIVATE_IP_ADDRESS_RANGE_LIST',
    RES_PRIVATE_NETWORK_CREATE: 'RES_PRIVATE_NETWORK_CREATE',
    RES_PUBLIC_IP_ADDRESS_RANGE_CREATE: 'RES_PUBLIC_IP_ADDRESS_RANGE_CREATE',
    RES_PUBLIC_IP_ADDRESS_RANGE_DELETE: 'RES_PUBLIC_IP_ADDRESS_RANGE_DELETE',
    RES_PUBLIC_IP_ADDRESS_RANGE_EDIT: 'RES_PUBLIC_IP_ADDRESS_RANGE_EDIT',
    RES_PUBLIC_IP_ADDRESS_RANGE_LIST: 'RES_PUBLIC_IP_ADDRESS_RANGE_LIST',
    RES_PUBLIC_NETWORK_CREATE: 'RES_PUBLIC_NETWORK_CREATE',
    RES_VMHOST_CREATE: 'RES_VMHOST_CREATE',
    RES_VMHOST_LIST: 'RES_VMHOST_LIST',
    SSL_CANCEL: 'SSL_CANCEL',
    SSL_CERTIFICATES_LIST: 'SSL_CERTIFICATES_LIST',
    SSL_CERTIFICATES_MOVE: 'SSL_CERTIFICATES_MOVE',
    SSL_JOBS_LIST: 'SSL_JOBS_LIST',
    SSL_ORDER: 'SSL_ORDER',
    SSL_RENEW: 'SSL_RENEW',
    SSL_REVOKE: 'SSL_REVOKE',
    SYSTEM_BACKUP_PRV_DELETE_BACKUP_OBJECT: 'SYSTEM_BACKUP_PRV_DELETE_BACKUP_OBJECT',
    SYSTEM_BACKUP_PRV_ENSURE_BACKUP_OBJECT: 'SYSTEM_BACKUP_PRV_ENSURE_BACKUP_OBJECT',
    SYSTEM_DATABASE_PRV_DATABASE_CREATE_FOR_MANAGED_APPLICATION: 'SYSTEM_DATABASE_PRV_DATABASE_CREATE_FOR_MANAGED_APPLICATION',
    SYSTEM_SSL_PRV_MANAGED_CERTIFICATE_ASSOCIATE: 'SYSTEM_SSL_PRV_MANAGED_CERTIFICATE_ASSOCIATE',
    SYSTEM_TWT_INTEGRATION: 'SYSTEM_TWT_INTEGRATION',
    SYSTEM_WEBHOSTING_PRV_WEBSPACE_CREATE_FOR_MANAGED_APPLICATION: 'SYSTEM_WEBHOSTING_PRV_WEBSPACE_CREATE_FOR_MANAGED_APPLICATION',
    UI_MISC_IS_DIRECT_CUSTOMER: 'UI_MISC_IS_DIRECT_CUSTOMER',
    WEB_ADMIN_EMAIL_SENDER: 'WEB_ADMIN_EMAIL_SENDER',
    WEB_ADMIN_WEBSPACE_ENABLE_DISABLE: 'WEB_ADMIN_WEBSPACE_ENABLE_DISABLE',
    WEB_JOBS_LIST: 'WEB_JOBS_LIST',
    WEB_OBJECT_LIST: 'WEB_OBJECT_LIST',
    WEB_REDIRECTIONS_CREATE: 'WEB_REDIRECTIONS_CREATE',
    WEB_REDIRECTIONS_DELETE: 'WEB_REDIRECTIONS_DELETE',
    WEB_REDIRECTIONS_EDIT: 'WEB_REDIRECTIONS_EDIT',
    WEB_REDIRECTIONS_LIST: 'WEB_REDIRECTIONS_LIST',
    WEB_REDIRECTIONS_RESTORE: 'WEB_REDIRECTIONS_RESTORE',
    WEB_USER_CREATE: 'WEB_USER_CREATE',
    WEB_USER_DELETE: 'WEB_USER_DELETE',
    WEB_USER_EDIT: 'WEB_USER_EDIT',
    WEB_USER_LIST: 'WEB_USER_LIST',
    WEB_VHOST_CREATE: 'WEB_VHOST_CREATE',
    WEB_VHOST_DELETE: 'WEB_VHOST_DELETE',
    WEB_VHOST_EDIT: 'WEB_VHOST_EDIT',
    /** DO NOT USE!!! OBSOLETE RIGHT */WEB_VHOST_LIST: 'WEB_VHOST_LIST',
    WEB_VHOST_RESTORE: 'WEB_VHOST_RESTORE',
    WEB_WEBSPACE_CREATE: 'WEB_WEBSPACE_CREATE',
    WEB_WEBSPACE_DELETE: 'WEB_WEBSPACE_DELETE',
    WEB_WEBSPACE_EDIT: 'WEB_WEBSPACE_EDIT',
    /** DO NOT USE!!! OBSOLETE RIGHT */WEB_WEBSPACE_LIST: 'WEB_WEBSPACE_LIST',
    WEB_WEBSPACE_RESTORE: 'WEB_WEBSPACE_RESTORE'
};
