import ng from 'angular';

import {
    FileServerRobotService,
    ManagedApplicationRobotService,
    StorageModelService
} from '@/services';
import { ValidationErrorList } from '@/atomic-components/molecules';
import { FormDescriptionSpec } from '@/types/view-types/form-description/form-description';
import { ManagedApplicationApi } from '@/types/managed-application-api';

type InternModel = FileList | string | null;

export class FormElementInputFileController implements ng.IComponentController {
    public static $inject: string[] = [
        'fileServerRobot',
        'managedApplicationRobot',
        'storageModel',
        '$state'
    ];

    public keenInput: FormDescriptionSpec.FieldObjectFile;
    public keenOutput: (model: InternModel, key: string) => unknown;
    private _model: InternModel;
    private key: string;
    private label: string;
    private validationErrorList: ValidationErrorList = [];
    private validationNoFileSelected: ValidationErrorList;
    private _validationOptions: FormDescriptionSpec.FieldObjectFileValidation;

    constructor(
        private fileServerRobot: FileServerRobotService,
        private managedApplicationRobot: ManagedApplicationRobotService,
        private storageModel: StorageModelService,
        private $state: ng.ui.IStateService
    ) {}

    public $onInit = (): void => {
        this.key = this.keenInput.key || '';
        this.label = this.keenInput.ui.label || '';
        if (this.keenInput.validation) {
            this._validationOptions = this.keenInput.validation;
        }
        if ([undefined, null].indexOf(((this.keenInput as unknown as FormDescriptionSpec.FieldObject).value as InternModel)) < 0) {
            this.model = ((this.keenInput as unknown as FormDescriptionSpec.FieldObject).value as InternModel);
        } else if ([undefined, null].indexOf(((this.keenInput as unknown as FormDescriptionSpec.FieldObject).default as InternModel)) < 0) {
            this.model = ((this.keenInput as unknown as FormDescriptionSpec.FieldObject).default as InternModel);
        }
    };

    public handleFileUploadError = (reason: any) => {
        this.validationNoFileSelected = [{
            text: this.keenInput.ui.error
        }];
    };

    public fileSelected(): void {
        if (this._model !== 'keep' && [null, undefined].indexOf(this._model) >= 0 || this._model?.length <= 0) {
            this.validationNoFileSelected = [{
                text: this.keenInput.ui.error
            }];
        }
    }

    public get model(): InternModel {
        return this._model;
    }

    public set model(value: FileList  | string | null) {
        this._model = value;
        this.validationNoFileSelected = [];
        this.fileSelected();
        if (['keep', null, undefined].indexOf(value as string) >= 0) {
            this.keenOutput(value, this.key);
        } else {
            this.storageModel.findOne(this.$state.params.storageProductId)
                .then((storageProduct: ManagedApplicationApi.Nextcloud) => {
                    this.managedApplicationRobot
                        .nextcloudCreateFileUploadToken(this.$state.params.appId, storageProduct.accountId, this.key)
                        .then((response) => {
                            if (response?.response) {
                                this.fileServerRobot.postFiles(response.response, value as FileList)
                                    .then((fileUploadResponse) => {
                                        if (fileUploadResponse.status.code === 201 && this.keenOutput) {
                                            this.keenOutput(fileUploadResponse.reply.credentials, this.key);
                                        }
                                    }, this.handleFileUploadError);
                            }
                        }, this.handleFileUploadError);
            }, this.handleFileUploadError);
        }
    }
}

export class FormElementInputFileComponent implements ng.IComponentOptions {
    public bindings = {
        accountId: '<',
        appId: '<?',
        keenInput: '<',
        keenOutput: '<',
        model: '=?',
        disabled: '<?'
    };
    public controller = FormElementInputFileController;
    public template = require('./form-element-input-file.html');
}
