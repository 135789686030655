import { LinkListOverviewItem } from '../../atomic-components/molecules/links/link-list-overview/link-list-overview';
import { AccountFilterField, TextFilterField } from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { OverviewModel } from '../abstract';
import { RequestCancellerService } from '../request-canceller';
import { NetworkModelService } from './network-model';

export class NetworkOverviewModel extends OverviewModel {

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('networkName', this.$translate.instant('TR_150119-f90338_TR')),
                new TextFilterField('networkId', this.$translate.instant('VM.VM.ID')),
                new TextFilterField('networkIpAddress', this.$translate.instant('VM.VM.IP')),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR'))
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', '$state', 'networkModel']
    );

    readonly service = 'resourceNetwork';
    readonly objectType = 'network';
    readonly idField = 'networkId';
    readonly defaultFilterFields = ['networkName'];

    public activeMassAction: string | boolean = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};

    protected listCallbacks = [];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private $state: ng.ui.IStateService,
        private networkModel: NetworkModelService
    ) {
        super(RequestCanceller);

        this.listCallbacks = [{
            callback: this.listnetworks
        }];
    }

    public actionLinks = (): LinkListOverviewItem[] => {
        return [
            {
                text: this.$translate.instant('TR_050520-590e18_TR'),
                route: 'network.networks.new',
                isClickable: false,
                disabled: true
            }
        ] as LinkListOverviewItem[];
    };

    public listnetworks = (limit?, page?, filters?, sort?) => {
        if (this.$state.current.name.split('.').indexOf('dashboard') >= 0) {
            return this.networkModel.listWithoutPagination(limit, page, filters, sort);
        } else {
            return this.networkModel.list(limit, page, filters, sort);
        }
    };
}
