import {
    DashboardWidgetModel,
    DashboardWidgetType
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model';
import {
    DashboardWidgetObjectDefinition
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model-operator';
import { DepositModelService } from '@/services';

export class DashboardWidgetAccountBalanceModel extends DashboardWidgetModel {
    public static $inject: string[] = DashboardWidgetModel.$inject.concat([
        'depositModel'
    ]);

    public static dashboardWidgetDefinition: DashboardWidgetObjectDefinition = {
        demoData: {},
        manualAddable: true,
        userPermissions: {
            isGranted: 'BIL_GET_DEPOSIT',
            notRootOrCompanyAccount: true
        },
        widgetDescription: [
            /* translationId */ 'TR_150321-1d8f9a_TR'
        ],
        widgetObject: {
            attribute: {
                id: 'organDashboardWidgetAccountBalance',
                width: 1,
                height: 1,
                x: 0,
                y: 0,
                noResize: true,
                autoPosition: true
            },
            componentTagName: 'organ-dashboard-widget-account-balance',
            widgetModel: 'dashboardWidgetAccountBalanceModel',
            version: '1.0'
        },
        widgetTitle: /* translationId */ 'TR_290121-37b464_TR',
        widgetType: DashboardWidgetType.custom
    };

    public dashboardWidgetDefinition: DashboardWidgetObjectDefinition;

    constructor(
        private depositModel: DepositModelService
    ) {
        super();

        this.dashboardWidgetDefinition = DashboardWidgetAccountBalanceModel.dashboardWidgetDefinition;
    }

    public additionalAvaiableConditionsFullfilled = (): PromiseLike<boolean> => {
        return this.depositModel
            .getDeposit()
            .then((result) => result.paymentType === 'prepaid');
    };
}
