import * as ng from 'angular';
import { EditPanelRight, PanelHeaderData } from '@/atomic-components';
import { NextcloudFreeProductCodeConst, NextcloudUnlimitedStorageValue, UiRights } from '@/configuration';
import {
    AlertManagerService,
    AuthContextService,
    ManagedApplicationRobotService,
    NavigationService,
    NextcloudHelperService
} from '@/services';
import { ManagedApplicationApi, UI } from '@/types';
import NextcloudUserWithPassword = ManagedApplicationApi.NextcloudUserWithPassword;
import NextcloudUserToDelete = ManagedApplicationApi.NextcloudUserToDelete;

export class OrganismEditFormNextcloudController {
    public static $inject: string[] = [
        '$state',
        '$timeout',
        '$translate',
        'alertManager',
        'managedApplicationRobot',
        'navigation',
        'nextcloudHelper'
    ];

    public userHasRightAdminSystemSuperUserRead = AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_SUPER_USER_READ);
    public availableGroups: string[] = [];
    public nextcloud: ManagedApplicationApi.Nextcloud;
    public originalNextcloud: ManagedApplicationApi.Nextcloud;

    // Group Folders
    public groupFoldersToDelete: string[] = [];
    public groupFoldersToEdit: string[] = [];
    public aggregatedGroupDataCache: Record<string, ManagedApplicationApi.NextcloudGroupFolderCache> = {};
    public groupFoldersDeletionConfirmed = false;
    public nextcloudGroupFolders: ManagedApplicationApi.NextcloudGroupFolder[];
    public originalNextcloudGroupFolders: ManagedApplicationApi.NextcloudGroupFolder[];
    public groupsDoneLoading = false;

    // Groups.
    public groupsToDelete: string[] = [];
    public nextcloudUsersGroups: ManagedApplicationApi.NextcloudUser[] = [];

    // Users
    public nextcloudUsersNew: ManagedApplicationApi.NextcloudUser[] = [];
    public componentInitialized = false;

    public nextcloudHasUnfinishedJob = false;
    public nextcloudHasSupportJob = false;

    public userPanelRight: Record<string, EditPanelRight> = {
        groupfolders: {
            editPanelButton: false
        },
        plugins: {
            editPanelButton: false
        },
        users: {
            editPanelButton: false
        }
    };

    public deletePanel: PanelHeaderData;

    constructor(
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private managedApplicationRobot: ManagedApplicationRobotService,
        private navigation: NavigationService,
        private nextcloudHelper: NextcloudHelperService
    ) {}

    public $onInit(): void {
        this.originalNextcloud = ng.copy(this.nextcloud);
        this.originalNextcloudGroupFolders = ng.copy(this.nextcloudGroupFolders);

        void this.managedApplicationRobot.nextcloudGroupsFind(
            {
                field: 'NextcloudId',
                value: this.nextcloud.id
            },
            200
        ).then(
            (result: UI.SynchronousAPIResponse<ManagedApplicationApi.FindNextcloudGroupsResult>) => {
                this.availableGroups = result.response.data.map( (group) => group.name );
                void this.$timeout(() => this.groupsDoneLoading = true );
            }
        );

        void this.nextcloudHelper.checkNextcloudJobRunning(this.nextcloud.id).then((res) => {
            this.nextcloudHasUnfinishedJob = res;
        });
        void this.nextcloudHelper.checkNextcloudJobSupport(this.nextcloud.id).then((res) => {
            this.nextcloudHasSupportJob = res;
        });

        ['plugins', 'users'].forEach(
            (key) => {
                Object.defineProperty(
                    this.userPanelRight[key],
                    'editPanelButton',
                    {
                        get: () => AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT)
                            && !this.nextcloudHasUnfinishedJob
                    }
                );
            }
        );

        Object.defineProperty(
            this.userPanelRight.groupfolders,
            'editPanelButton',
            {
                get: () => AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT)
                    && !this.nextcloudHasUnfinishedJob
                    && this.availableGroups.length > 0
                    && !this.isFreeProduct()
            }
        );

        this.deletePanel = {
            includeShowAllLink: false,
            panelHeaderRoute: this.$state.current.name.indexOf('storage-products') >= 0
                ? 'storage.storage-products.id.restore'
                : 'nextcloud.id.restore',
            panelItems: [
                {
                    route: this.$state.current.name.indexOf('storage-products') >= 0
                        ? 'storage.storage-products.id.restore'
                        : 'nextcloud.id.restore',
                    text: this.nextcloud.status === 'restorable'
                        ? this.$translate.instant('TR_040220-111c86_TR')
                        : this.$translate.instant('TR_020819-188cc3_TR')
                }
            ],

            panelIcon: this.nextcloud.status === 'restorable'
                ? 'recycle'
                : undefined,

            panelTitle: this.nextcloud.status === 'restorable'
                ? this.$translate.instant('TR_140119-a548eb_TR')
                : this.$translate.instant('GENERAL.ACTION.TERMINATE'),

            panelType: 'nextcloud-delete',
            utilityStatus: null
        };

        this.componentInitialized = true;
    }

    public delete = (): Promise<Error>  => {
        return Promise.reject(new Error('Delete function not yet implemented!'));
    };

    public save = (): Promise<unknown> => {
        const promises: Promise<unknown>[] = [];
        if (
            this.nextcloudUsersNew.some(
                (user) => user.props.type === 'edit'
                    || (user.props.isNewUser && !user.props.isMarkedForRemoval)
                    || (user.props.isMarkedForRemoval && !user.props.isNewUser)
            ) || this.groupsToDelete.length > 0
            || this.nextcloudUsersGroups.length > 0
        ) {
            promises.push(this._updateNextcloudUsers());
        }

        if (this._groupFoldersChanged()) {
            promises.push(this._updateNextcloudGroupFolders());
        }

        return Promise.all(promises).then(() => {
            this.navigation.reloadCurrentState(true);
        });
    };

    public checkUniqueFolderName = (folderName: unknown ): { text: string }[] => {
        folderName = (folderName as { value: string }).value !== undefined
            ? (folderName as { value: string }).value
            : (folderName as string);

        return this.nextcloudGroupFolders.some((groupFolder) => groupFolder.name === folderName)
            ? [{ text: this.$translate.instant('TR_100320-5b79fa_TR') }]
            : [];
    };

    // eslint-disable-next-line no-empty-pattern,@typescript-eslint/no-empty-function
    public set showGroupFolderConfig({}) {}
    public get showGroupFolderConfig(): boolean {
        if (this.nextcloud && Array.isArray(this.nextcloud.enabledApps)) {
            return this.nextcloud.enabledApps.some(
                (app) => app === 'groupfolders'
            );
        }

        return false;
    }

    public isFreeProduct = (): boolean => {
        return this.nextcloud.productCode === NextcloudFreeProductCodeConst;
    };

    private _updateNextcloudUsers = (): Promise<unknown> => {
        const usersToAdd = this._prepareUserListForApi(
            [
                { field: 'isNewUser', expectedValue: true },
                { field: 'isMarkedForRemoval', expectedValue: false }
            ]
        );

        const usersToDelete = this._prepareUserListForApi(
            [
                { field: 'isNewUser', expectedValue: false },
                { field: 'isMarkedForRemoval', expectedValue: true }
            ],
            true
        );

        let usersToEdit = this._prepareUserListForApi(
            [
                { field: 'isNewUser', expectedValue: false },
                { field: 'type', expectedValue: 'edit' }
            ]
        );

        const changedGroupUsers = [];

        for (const ncUser of this.nextcloudUsersGroups) {
            if (
                ncUser.groups.some((activeGroup) => this.groupsToDelete.includes(activeGroup))
                || ncUser.props?.isBeingEdited === true
            ) {
                ncUser.groups = ncUser.groups.filter(
                    (activeGroup) => !this.groupsToDelete.includes(activeGroup)
                );
                changedGroupUsers.push(ncUser);
            }
        }

        if (changedGroupUsers.length > 0) {
            usersToEdit = this._prepareUserListForApi([], false, changedGroupUsers);
        }

        return this.managedApplicationRobot.nextcloudModifyUsers(
            this.nextcloud.id,
            (usersToAdd as NextcloudUserWithPassword[]),
            (usersToEdit as NextcloudUserWithPassword[]),
            (usersToDelete as NextcloudUserToDelete[]),
            this.groupsToDelete.length > 0 ? this.groupsToDelete : null
        ).then(
            (res) => {
                if ((res.status as string) !== 'error') {
                    this.alertManager.success(this.$translate.instant('TR_100919-c351c7_TR'));
                    return (res as unknown);
                }

                return Promise.reject(false);
            }
        );
    };

    private _prepareUserListForApi = (
        filters: { field: string; expectedValue: unknown }[],
        returnUserDeleteObject = false,
        userList?: ManagedApplicationApi.NextcloudUser[]
    ): NextcloudUserWithPassword[] | NextcloudUserToDelete[] => {
        const nextcloudUsers = userList === undefined
            ? ng.copy(this.nextcloudUsersNew)
            : ng.copy(userList);

        return nextcloudUsers
            .filter(
                (user) => {
                    const performedChecks = [];

                    if (filters.length === 0) {
                        return true;
                    }

                    for (const filter of filters) {
                        // @ts-ignore TS7053
                        performedChecks.push(user.props[filter.field] === filter.expectedValue);
                    }

                    return performedChecks.every((checkResult) => checkResult === true);
                }
            )
            .map(
                (user) => {
                    if (returnUserDeleteObject) {
                        return {
                            username: user.username,
                            usernameForFileTransfer: !!user.props.usernameForFileTransfer !== false
                                ? user.props.usernameForFileTransfer
                                : null
                        } as NextcloudUserToDelete;
                    }

                    const password = user.password ? user.password : '';
                    delete user.props;
                    delete user.password;

                    return ({ password: password, user: user } as NextcloudUserWithPassword);
                }
            ) as NextcloudUserWithPassword[] | NextcloudUserToDelete[];
    };

    private _updateNextcloudGroupFolders = (): Promise<unknown> => {
        const groupFoldersToDeleteIdList: string[] = [];

        this.groupFoldersToDelete.forEach(
            (groupFolderId) => {
                const existingFolder = this.originalNextcloudGroupFolders.filter(
                    (groupFolder) => groupFolder.id === groupFolderId
                );

                if (existingFolder.length === 1) {
                    groupFoldersToDeleteIdList.push(existingFolder[0].id);
                }
            }
        );

        return this.managedApplicationRobot.nextcloudModifyGroupFolders(
            this.nextcloud.id,
            this.nextcloudGroupFolders,
            groupFoldersToDeleteIdList
        ).then(
            (res) => {
                if (res.status !== 'error') {
                    this.alertManager.success(this.$translate.instant('TR_100320-3cff82_TR'));
                    return (res as unknown);
                }

                return Promise.reject(false);
            }
        );
    };

    private _groupFoldersChanged = (): boolean => {
        // remove deleted folders
        if (this.groupFoldersToDelete.length > 0) {
            this.nextcloudGroupFolders = this.nextcloudGroupFolders.filter(
                (existingFolder) => !this.groupFoldersToDelete.some(
                    (deletedFolderId) => deletedFolderId === existingFolder.id
                )
            );
        }

        // cleanup new and changed entries
        this.nextcloudGroupFolders = this.nextcloudGroupFolders.map(
            (groupFolder: ManagedApplicationApi.NextcloudGroupFolder) => {
                const overlayData = this.aggregatedGroupDataCache[groupFolder.id];

                if (overlayData && overlayData.groupPermissions) {
                    groupFolder.groupPermissions = overlayData.groupPermissions.map(
                        (groupPermission: ManagedApplicationApi.NextcloudGroupFolderPermissionsCache): ManagedApplicationApi.NextcloudGroupFolderPermissions => {
                            return {
                                groupName: groupPermission.groupName,
                                permissions: Object.keys(groupPermission.permissions).filter(
                                    (singleRight: keyof typeof groupPermission.permissions) => {
                                        return groupPermission.permissions[singleRight] === true;
                                    }
                                )
                            };
                        }
                    );
                }

                // new entries have a fake ID, this has to be removed before saving
                if (/^generated_/.test(groupFolder.id)) {
                    delete groupFolder.id;
                }

                if (groupFolder.unlimitedStorage === true) {
                    groupFolder.quota = NextcloudUnlimitedStorageValue;
                } else if (groupFolder.quotaInGb) {
                    const maxStorage = this.nextcloud.storageQuota / 1024;
                    const quotaGb = groupFolder.quotaInGb > maxStorage ? maxStorage : groupFolder.quotaInGb;
                    groupFolder.quota = quotaGb * 1024;
                }

                delete groupFolder.unlimitedStorage;
                delete groupFolder.quotaInGb;

                return groupFolder;
            }
        );

        return !ng.equals(this.nextcloudGroupFolders, this.originalNextcloudGroupFolders);
    };
}

export class OrganismEditFormNextcloudComponent implements ng.IComponentOptions {
    public bindings = {
        nextcloud: '<',
        nextcloudGroupFolders: '<'
    };

    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormNextcloudController;
    public template = require('./nextcloud-edit.html');
}
