import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { DisableFilter } from '../../../atomic-components/organisms/overview/overview';

export class TemplateNameserverSetOverviewController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;
    public bundle;
    public overviewServiceData;
    public expertView: boolean;
    public disableFilter: DisableFilter;

    public overviewModel;
    private simpleFilter;
    private extendedFilters;
    private overviewUiSettings;

    constructor(private $translate: ng.translate.ITranslateService) { }

    public $onInit() {
        this.expertView = true; // only expertview on job overviews
        this.disableFilter = {
            fancyFilter: true,
            searchFilter: false
        };

        this.pageHeaderData = {
            backwardLink: 'dns.dashboard',
            backwardText: this.$translate.instant('TR_100119-a3c01f_TR'),
            panelHeaderRoute: '',
            panelIcon: 'globe',
            panelTitle: this.$translate.instant('DOMAIN.NAMESERVER.SELECT-TYPES.NAMESERVER-SETS')
        };

        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter
        };
    }
}

export class TemplateNameserverSetOverviewComponent implements ng.IComponentOptions {

    public bindings = {
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public controller = TemplateNameserverSetOverviewController;
    public template = require('./nameserver-set-overview-template.html');
}
