import * as ng from 'angular';
import {
    UiLanguagesConst,
    UiRights
} from '@/configuration';
import {
    AuthContextService,
    ManagedApplicationRobotService,
    NavigationService,
    NextcloudHelperService,
    ProductsModelService
} from '@/services';
import * as Types from '@/types';

export class OrganismEditFormNextcloudBusinessUpgradesController {
    public static $inject: string[] = [
        '$state',
        'managedApplicationRobot',
        'navigation',
        'nextcloudHelper',
        'productsModel'
    ];

    public userHasRightAdminSystemSuperUserRead = AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_SUPER_USER_READ);
    public nextcloud: Types.ManagedApplicationApi.Nextcloud;
    public appConfig: Types.ManagedApplicationApi.NextcloudAppConfigInput[];
    public originalNextcloud: Types.ManagedApplicationApi.Nextcloud;
    public nextcloudProduct: Types.ProductApi.AbstractProduct;

    public componentInitialized = false;

    public nextcloudHasUnfinishedJob = false;
    public nextcloudHasSupportJob = false;

    public userPanelRight = {
        edit: {
            editPanelButton: false
        }
    };

    public testAddonDescription = 'Produkt: {{ productCode | translateProductCode }}';

    public translationParams = {
        maxOfficeUsers: 0,
        maxStorage: 0,
        maxTalkUsers: 0,
        maxUsers: 0,
        productName: ''
    };

    constructor(
        private $state: ng.ui.IStateService,
        private managedApplicationRobot: ManagedApplicationRobotService,
        private navigation: NavigationService,
        public nextcloudHelper: NextcloudHelperService,
        private productsModel: ProductsModelService
    ) {}

    public $onInit = async(): Promise<void> => {
        this.originalNextcloud = ng.copy(this.nextcloud);

        Object.defineProperty(
            this.userPanelRight.edit,
            'editPanelButton',
            {
                get: () => AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT)
                    && !this.nextcloudHasUnfinishedJob
            }
        );

        const productResponse = await this.productsModel.findOne(
            this.nextcloud.productCode,
            UiLanguagesConst[AuthContextService.user.language as 'de'|'en'],
            true
        );

        this.nextcloudProduct = productResponse.response;

        this.translationParams.productName = this.nextcloudProduct.name;

        this.translationParams.maxStorage = this.nextcloudHelper.getValueFromProductSpecification(
            'storageQuotaMaximum',
            this.nextcloudProduct
        ) as number / 1024000;

        this.translationParams.maxUsers = this.nextcloudHelper.getValueFromProductSpecification(
            'nextcloudUsersMaximum',
            this.nextcloudProduct
        ) as number;

        this.translationParams.maxOfficeUsers = this.nextcloudHelper.getValueFromProductSpecification(
            'officeUsersMaximum',
            this.nextcloudProduct
        ) as number;
    };

    public save = (): void => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        void this.managedApplicationRobot.nextcloudUpdate(this.nextcloud, this.appConfig).then(
            () => {
                this.navigation.reloadCurrentState(true);
            }
        );
    };

    public get nextcloudUpgradeRoute(): string {
        return this.$state.current.name.includes('storage-products')
            ? 'storage.storage-products.id.upgrade'
            : 'nextcloud.id.upgrade';
    }
}

export class OrganismEditFormNextcloudBusinessUpgradesComponent implements ng.IComponentOptions {
    public bindings = {
        appConfig: '=',
        nextcloud: '<',
        nextcloudHasUnfinishedJob: '<',
        nextcloudHasSupportJob: '<'
    };

    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormNextcloudBusinessUpgradesController;
    public template = require('./nextcloud-business-upgrades.html');
}
