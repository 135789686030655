import * as ng from 'angular';

export class DateTimeWrapper {

    public get year() {
        this.refreshDateTime();
        return this._dateObj.getFullYear();
    }

    public get month() {
        this.refreshDateTime();
        return this._dateObj.getMonth() + 1;
    }

    public get day() {
        this.refreshDateTime();
        return this._dateObj.getDate();
    }

    public get hour() {
        this.refreshDateTime();
        return this._dateObj.getHours();
    }

    public get minute() {
        this.refreshDateTime();
        return this._dateObj.getMinutes();
    }

    public get second() {
        this.refreshDateTime();
        return this._dateObj.getSeconds();
    }

    public get date() {
        this.refreshDateTime();
        return {
            year: this._dateObj.getFullYear(),
            month: this._dateObj.getMonth() + 1,
            day: this._dateObj.getDate()
        };
    }

    public set utcDateTimeString(value: string) {
        const parts = value.split('-');
        if (parts.length !== 3) {
            return;
        }
        if (parts[2].indexOf('T') >= 0) {
            const dayParts = parts[2].split('T');
            if (dayParts.length !== 2) {
                return;
            }
            parts[2] = dayParts[0];
            let hourMinuteSecond = value.split('T');
            hourMinuteSecond = hourMinuteSecond[1].split('.');
            hourMinuteSecond = hourMinuteSecond[0].split(':');
            if (hourMinuteSecond[2].indexOf('Z') >= 0) {
                hourMinuteSecond[2] = hourMinuteSecond[2].split('Z')[0];
            }
            this._hour = parseInt(hourMinuteSecond[0], 10);
            this._minute = parseInt(hourMinuteSecond[1], 10);
            this._second = parseInt(hourMinuteSecond[2], 10);
        }
        this._year = Number(parts[0]);
        this._month = (Number(parts[1]) - 1);
        this._day = Number(parts[2]);
    }

    public get dateObj() {
        this.refreshDateTime();
        return this._dateObj;
    }

    public set dateObj(value: Date) {
        this._day = value.getUTCDate();
        this._month = value.getUTCMonth();
        this._year = value.getUTCFullYear();
        this._hour = value.getUTCHours();
        this._minute = value.getUTCMinutes();
        this._second = value.getUTCSeconds();
    }
    private _day: number;
    private _month: number;
    private _year: number;
    private _hour = 0;
    private _minute = 0;
    private _second = 0;
    private _dateObj: Date;
    private _monthNames: string[] = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    constructor(date?: any) {
        if (date !== undefined) {
            if (typeof date === 'string') {
                this.utcDateTimeString = date;
            } else {
                this._year = date.getFullYear();
                this._month = date.getMonth();
                this._day = date.getDate();
                this._hour = date.getHours();
                this._minute = date.getMinutes();
                this._second = date.getSeconds();
            }
        }
        this.refreshDateTime();
    }

    public getYearUtc() {
        this.refreshDateTime();
        return this._year;
    }

    public getMonthUtc() {
        this.refreshDateTime();
        return this._month + 1;
    }

    public getDayUtc() {
        this.refreshDateTime();
        return this._day;
    }

    public getHourUtc() {
        this.refreshDateTime();
        return this._hour;
    }

    public getMinuteUtc() {
        this.refreshDateTime();
        return this._minute;
    }

    public getSecondUtc() {
        this.refreshDateTime();
        return this._second;
    }

    public getUtcDate() {
        this.refreshDateTime();
        return {
            year: this._year,
            month: this._month + 1,
            day: this._day
        };
    }

    public getLocalDateString(locale = 'de', minimalVersion = false) {
        const date = this.getTranslationTime(minimalVersion);
        if (locale === 'de') {
            return date.day + '.' + date.month + '.' + date.year;
        } else {
            return date.day + date.ukDevider + date.ukMonth + date.ukDevider + date.year;
        }
    }

    public getLocalDayTimeString(locale = 'de', minimalVersion = false) {
        const date = this.getTranslationTime(minimalVersion);
        if (locale === 'de') {
            return date.day + '.' + date.month + '.' + date.year + ' ' + date.hour + ':' + date.minute + date.deSuffix;
        } else {
            return date.day
                + date.ukDevider
                + date.ukMonth
                + date.ukDevider
                + date.year
                + ' '
                + date.ukHour
                + ':'
                + date.minute
                + date.ukSuffix;
        }
    }

    public getLocalDayTimeStringWithSeconds(locale = 'de', minimalVersion = false) {
        const date = this.getTranslationTime(minimalVersion);
        if (locale === 'de') {
            return date.day
                + '.'
                + date.month
                + '.'
                + date.year
                + ' '
                + date.hour
                + ':'
                + date.minute
                + ':'
                + date.second
                + date.deSuffix;
        } else {
            return date.day
                + date.ukDevider
                + date.ukMonth
                + date.ukDevider
                + date.year
                + ' '
                + date.ukHour
                + ':'
                + date.minute
                + ':'
                + date.second
                + date.ukSuffix;
        }
    }

    public setToday = () => {
        this.dateObj = new Date();
    };

    public toUnixTimestamp = () => {
        return Date.parse(this.dateObj.toISOString());
    };

    public getIsoString = () => {
        return this.dateObj.toISOString();
    };

    private refreshDateTime() {
        if (this._dateObj === undefined) {
            this._dateObj = new Date();
        }
        if (this._day === undefined || this._month === undefined || this._year === undefined) {
            this._year = this._dateObj.getUTCFullYear();
            this._month = this._dateObj.getUTCMonth();
            this._day = this._dateObj.getUTCDate();
        }
        this._dateObj.setUTCFullYear(this._year, this._month, this._day);
        this._dateObj.setUTCHours(this._hour, this._minute, this._second, 0);
    }

    private addLeadingZero = (value: number) => {
        return value < 10 ? '0' + value.toString() : value.toString();
    };

    private getTranslationTime = (minimalVersion: boolean) => {
        this.refreshDateTime();
        const year = this._dateObj.getFullYear().toString();
        let month: string | number = this._dateObj.getMonth();
        let day: string | number = this._dateObj.getDate();
        let hour: string | number = this._dateObj.getHours();
        let minute: string | number = this._dateObj.getMinutes();
        let second: string | number = this._dateObj.getSeconds();

        const ukSuffix = hour > 12 ? ' p.m.' : ' a.m.';
        let ukHour: string | number = hour > 12 ? (hour - 12) : hour;
        const ukMonth: string = this._monthNames[month];

        month = this.addLeadingZero((month + 1));
        day = this.addLeadingZero(day);
        hour = this.addLeadingZero(hour);
        minute = this.addLeadingZero(minute);
        second = this.addLeadingZero(second);
        ukHour = this.addLeadingZero(ukHour);

        return {
            year: year,
            month: month,
            day: day,
            hour: hour,
            minute: minute,
            second: second,
            ukSuffix: ukSuffix,
            deSuffix: minimalVersion ? '' : ' Uhr',
            ukHour: ukHour,
            ukMonth: minimalVersion ? month : ukMonth,
            ukDevider: minimalVersion ? '/' : ' '
        };
    };
}
