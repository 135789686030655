import * as ng from 'angular';

import { AlertManagerService } from '../../../../../../services';
import { OrganismOverviewController } from '../../../../../organisms/overview/overview';

export class MoleculePanelOverviewHeaderMultiLockActionController implements ng.IController {
    public static $inject: string[] = ['alertManager', '$translate'];

    public OrganismOverview: OrganismOverviewController;
    private serviceName: string;

    constructor(
        private alertManager: AlertManagerService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public getTemplate = () => require('./multi-lock-' + this.serviceName + '-action.html?external');

    public abort = () => {
        this.OrganismOverview.overviewModel.activeMassAction = false;
    };

    public lock = () => {
        this.OrganismOverview.submitMassAction({methode: 'lockSubmit'})
            .then((result) => {
                if (result) {
                    this.alertManager.success(this.$translate.instant('TR_100119-139c51_TR'));
                    this.abort();
                    this.OrganismOverview.onLoad();
                }
            }, (e) => {
                console.error('error', e);
                this.abort();
            });
    };
}

export class MoleculePanelOverviewHeaderMultiLockActionComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        serviceName: '<'
    };
    public controller =  MoleculePanelOverviewHeaderMultiLockActionController;
    public template = require('./multi-lock-action.html');
}
