import { ManagedApplicationRobotService } from './../../../../../../.history/app/services/managed-application/robot_20210208170815';
import ng from 'angular';

import { ManagedApplicationApi } from '@/types';
import { AuthContextService, NavigationService, NextcloudHelperService, ProductsModelService } from '@/services';
import { UiLanguagesConst, UiRights } from '@/configuration';
import * as Types from '@/types';

export class OrganismEditFormNextcloudConsumerUpgradeController {
    public static $inject: string[] = [
        '$timeout',
        'managedApplicationRobot',
        'navigation',
        'nextcloudHelper',
        'productsModel'
    ];

    public nextcloud: ManagedApplicationApi.Nextcloud;
    public appConfig: Types.ManagedApplicationApi.NextcloudAppConfigInput[];
    public appList: ManagedApplicationApi.NextcloudApp[];
    public translationParams = {
        maxOfficeUsers: 0,
        maxStorage: 0,
        maxTalkUsers: 0,
        maxUsers: 0,
        productName: ''
    };
    public nextcloudProduct: Types.ProductApi.AbstractProduct;
    public originalNextcloud: ManagedApplicationApi.Nextcloud;
    public nextcloudHasUnfinishedJob = false;
    public userPanelRight = {
        edit: {
            editPanelButton: false
        }
    };

    constructor(
        private $timeout: ng.ITimeoutService,
        private managedApplicationRobot: ManagedApplicationRobotService,
        private navigation: NavigationService,
        private nextcloudHelper: NextcloudHelperService,
        private productsModel: ProductsModelService
    ) {}

    public $onInit = (): void => {

        this.originalNextcloud = ng.copy(this.nextcloud);

        Object.defineProperty(
            this.userPanelRight.edit,
            'editPanelButton',
            {
                get: () => AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT)
                    && !this.nextcloudHasUnfinishedJob
            }
        );

        void this.productsModel.findOne(
            this.nextcloud.productCode,
            UiLanguagesConst[AuthContextService.user.language as 'de'|'en'],
            true
        ).then((productResponse) => {
            this.nextcloudProduct = productResponse.response;

            this.translationParams.productName = this.nextcloudProduct.name;

            this.translationParams.maxTalkUsers = this.nextcloudHelper.getValueFromProductSpecification(
                'talkUsersMaximum',
                this.nextcloudProduct
            ) as number;
        });
    };

    public save = (): void => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        void this.managedApplicationRobot.nextcloudUpdate(this.nextcloud, this.appConfig).then(
            () => {
                this.navigation.reloadCurrentState(true);
            }
        );
    };
}

export class OrganismEditFormNextcloudConsumerUpgradeComponent implements ng.IComponentOptions {
    public bindings = {
        appConfig: '=',
        appList: '<',
        nextcloud: '<',
        nextcloudHasSupportJob: '<',
        nextcloudHasUnfinishedJob: '<'
    };

    public controller = OrganismEditFormNextcloudConsumerUpgradeController;
    public template = require('./nextcloud-consumer-upgrade.html');
}
