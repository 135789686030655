import { NavigationService } from '@/services/navigation';
import { StorageModelService } from '@/services/storage/storage-model';
import ng from 'angular';

import { PanelHeaderData } from '@/atomic-components';
import { ManagedApplicationApi } from '@/types';
import { NextcloudHelperService } from '@/services/helpers/nextcloud-helper';
import * as Types from '@/types';

interface NextcloudAppMetaData {
    isExtensible?: boolean;
    translationParams?: { productName?: string; maxOfficeUsers?: string | number; maxTalkUsers?: string | number; };
    info?: string;
    user?: {
        unit?: string;
        max?: string | number;
        used?: number;
        included: string | number;
    };
    confirmation?: boolean;
}

type ModifiedApp = (ManagedApplicationApi.NextcloudApp & { appMetaData: NextcloudAppMetaData });

export class TemplateNextcloudAddonsController {
    public static $inject: string[] = [
        '$state',
        '$translate',
        '$timeout',
        'nextcloudHelper',
        'storageModel'
    ];

    public pageHeaderData: PanelHeaderData;
    public nextcloud: ManagedApplicationApi.Nextcloud;
    public appConfig: Types.ManagedApplicationApi.NextcloudAppConfigInput[];
    public appList: ManagedApplicationApi.NextcloudApp[];
    public isFreeProduct = false;
    public productUpgradeLink = '';
    public toggleAppInstallationState = false;
    public panelRight = {
        editPanelButton: true
    };

    public nextcloudHasUnfinishedJob = false;
    public nextcloudHasSupportJob = false;
    public addedUsers: Record<string, number> = {};
    public additionalUsersApps: ModifiedApp[];
    public appMetaData: Record<string, any>;
    private isBusinessProduct: boolean;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private $timeout: ng.ITimeoutService,
        private nextcloudHelper: NextcloudHelperService,
        private storageModel: StorageModelService
    ) {}

    public $onInit(): void {
        this.appConfig = [];
        void this.nextcloudHelper.checkNextcloudJobRunning(this.nextcloud.id).then((res) => {
            this.nextcloudHasUnfinishedJob = res;
        });
        void this.nextcloudHelper.checkNextcloudJobSupport(this.nextcloud.id).then((res) => {
            this.nextcloudHasSupportJob = res;
        });
        this.pageHeaderData = {
            backwardLink:
                this.$state.current.name.includes('storage-products')
                    ? 'storage.storage-products.id.edit'
                    : 'nextcloud.id.edit',
            backwardText: this.$translate.instant('TR_010819-62941c_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: { storageProductId: this.nextcloud.id },
            panelIcon: '/assets/images/logos/nextcloud-bw.svg',
            panelIconPath: true,
            panelTitle: this.$translate.instant('TR_180221-2542cc_TR')
        };

        this.productUpgradeLink = this.$state.current.name.includes('storage-products')
            ? 'storage.storage-products.id.upgrade'
            : 'nextcloud.id.upgrade';

        this.isFreeProduct = this.nextcloud.productCode.includes('free');

        this.isBusinessProduct = this.nextcloud.productCode.includes('business');
        void this.nextcloudHelper.aggregateProductAddonsData(this.nextcloud.productCode)
            .then(this._updateAppInfo);
    }

    public _updateAppInfo = (value: Types.UI.nextcloudAggregatedProductAddonsData): void => {
        this.additionalUsersApps = this.appList.filter(app => {
            return [
                'collabora',
                'talk_hpb'
            ].includes(app.id);
        }).map((app: any) => {
            app.appMetaData = {};
            return app;
        });
        void this.$timeout(() => {
            this.additionalUsersApps.forEach(app => {
                app.appMetaData = {
                    confirmation: false
                };
                switch (app.id) {
                    case 'collabora':
                        app.appMetaData.isExtensible = value.officeUsers.isExtensible;
                            app.appMetaData.translationParams = {
                                productName: value.productData.name,
                                maxOfficeUsers: value.officeUsers.max
                            };
                            app.appMetaData.info = /* translationId */ 'TR_120221-19272b_TR';
                            app.appMetaData.user = {
                                unit: this.$translate.instant('TR_220519-49de95_TR'),
                                max: value.officeUsers.max,
                                included: value.officeUsers.included,
                                used: this.nextcloud.officeUsers
                            };
                        break;
                    case 'talk_hpb':
                        app.appMetaData.isExtensible = value.talkUsers.isExtensible;
                            app.appMetaData.translationParams = {
                                productName: value.productData.name,
                                maxTalkUsers: value.talkUsers.max
                            };
                            app.appMetaData.info = /* translationId */ 'TR_221220-524365_TR';
                            app.appMetaData.user = {
                                unit: this.$translate.instant('TR_220519-49de95_TR'),
                                max: value.talkUsers.max,
                                included: value.talkUsers.included,
                                used: this.nextcloud.talkUsers
                            };
                        break;
                }
            });
        }, 200);
    };

    public save = (): void => {
        for (const attribute of Object.keys(this.addedUsers)) {
            (this.nextcloud as unknown as Record<string, unknown>)[attribute] = this.addedUsers[attribute];
        }
        void this.storageModel
            .nextcloudUpdate(this.nextcloud)
            .then((response: { status: string }) => {
                if (['successful', 'pending'].indexOf(response.status) >= 0) {
                    void this.nextcloudHelper.aggregateProductAddonsData(this.nextcloud.productCode)
                        .then(this._updateAppInfo);
                }
            });
    };

    public confirmationCallback = (value: boolean, attribute: string) => {
        this.additionalUsersApps.forEach((app) => {
            switch (attribute) {
                case 'talkUsers':
                    app.appMetaData.confirmation = value;
                    break;
                case 'officeUsers':
                    app.appMetaData.confirmation = value;
                    break;
            }
        })
    };

    public addUsersCallback = (value: any) => {
        this.addedUsers[value.attribute] = value.addedUsers;
    };

    public saveButtonDisabled = (app: ModifiedApp): boolean => {
        let userType = '';
        if (app.id === 'talk_hpb') {
            userType = 'talkUsers';
        } else if (app.id === 'collabora') {
            userType = 'officeUsers';
        }
        const usersIncreased = this.addedUsers[userType] > (this.nextcloud as unknown as Record<string, number>)[userType];
        const usersUnchanged = this.addedUsers[userType] === (this.nextcloud as unknown as Record<string, number>)[userType];
        const confirmationDeclined = !app.appMetaData.confirmation;

        return (usersIncreased && confirmationDeclined) || usersUnchanged
    }

    public showAppPanel = (app: ModifiedApp): boolean => {
        return app?.appMetaData?.isExtensible
            && (
                this.nextcloud.enabledApps.includes(app.id) ||
                app?.appMetaData?.user?.used > app?.appMetaData?.user?.included
            );
    }
}

export class TemplateNextcloudAddonsComponent implements ng.IComponentOptions {
    public bindings = {
        nextcloud: '<',
        appList: '<'
    };
    public controller = TemplateNextcloudAddonsController;
    public template = require('./nextcloud-addons.html');
}
