import * as ng from 'angular';
import { UiLanguagesConst } from '../../../../../configuration';

import { EmailApi } from '@/types/email-api';
import { UiLanguageDefaultSettingsConst } from '../../../../../configuration';
import { SpamProfile } from '../../../../../configuration';
import { AuthContextService, SpamProfileService, UserSettingsModelService } from '../../../../../services';
import { EmailHelper } from '../../../../../services/email/email-helper';
import { FormDropDownItems } from '../../../../molecules';

export class MoleculeFormSpamProtectionController {
    public static $inject: string[] = ['$timeout', '$translate', 'spamProfileService', 'userSettingsModel'];

    public cancel: any;
    public panelRight: any;
    public spamProtectionProfiles: FormDropDownItems[] = [];
    public spamFilter: EmailApi.SpamFilter;
    public spamProtectionLevels: any[]  = [];

    private _spamProtectionProfile = 'individual';
    private _spamProfile: SpamProfile;
    private _userLanguage: string;
    private _spamProfileList: SpamProfile[];

    constructor(
        private $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        private spamProfileService: SpamProfileService,
        private userSettingsModel: UserSettingsModelService
    ) {
        this.spamProtectionLevels = [
            {
                label: this.$translate.instant('EMAIL.SPAM.LEVEL.LOW'),
                value: 'low'
            },
            {
                label: this.$translate.instant('EMAIL.SPAM.LEVEL.MEDIUM'),
                value: 'medium'
            },
            {
                label: this.$translate.instant('EMAIL.SPAM.LEVEL.HIGH'),
                value: 'high'
            }
        ];

        this._userLanguage = UiLanguagesConst[AuthContextService.user.language];
    }

    public $onInit() {
        this._spamProfileList = this.spamProfileService.getList();
        this._spamProfile = EmailHelper.getSelectedSpamProfile(this._spamProfileList, this.spamFilter);

        this._spamProtectionProfile = this._spamProfile.name;
        this.spamProtectionProfiles = this._spamProfileList.map(
            (profile) => {
                return {
                    name: profile.label,
                    value: profile.name
                };
            }
        );
    }

    public get spamProfileHelpText() {
        if (typeof this._spamProfile === 'undefined') {
            return '';
        }
        return this._spamProfile.desc;
    }

    /** Returns true if the custom spam profile is selected */
    public get spamProtectionIsIndividual() {
        return this._spamProtectionProfile === 'individual';
    }

    /** Just returns the currently selected profile's name attribute. */
    public get spamProtectionProfile() {
        return this._spamProtectionProfile;
    }

    /**
     * When setting the spam profile, set the spam settings accordingly.
     */
    public set spamProtectionProfile(value: string) {
        this._spamProtectionProfile = value;

        const selectedProfile = this._spamProfileList
        .filter((profile) => profile.name === value)
        [0];

        Object.keys(selectedProfile.values)
        .forEach(
            (key: string) => this.spamFilter[key] = selectedProfile.values[key]
        );
    }

    public updateSpamProtectionProfileHelpText = () => {
        this.$timeout(
            () => {
                this._spamProfile = EmailHelper.getSelectedSpamProfile(this._spamProfileList, this.spamFilter);
            }
        );
    };
}

export class MoleculeFormSpamProtectionComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<',
        spamFilter: '='
    };
    public template = require('./spam-protection.html');
    public controller = MoleculeFormSpamProtectionController;
}
