import * as ng from 'angular';
import {
    AlertManagerService,
    DepositModelService,
    FunctionRelayService,
    NavigationService,
    ValidateNotEmpty,
    ValidateStringMax,
    ValidateStringMin
} from '../../../../../services';

export class OrganEditPanelBillingPaymentRedeemVoucherController {
    public static $inject: string[] = ['$translate', 'alertManager', 'depositModel', 'functionRelay', 'navigation'];

    public $editFormOrganism;
    public vouchercode = '';
    public validationErrorList: any[] = [];
    private validationInstructions: any[];
    private tryRedeemCode = false;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private depositModel: DepositModelService,
        private functionRelay: FunctionRelayService,
        private navigation: NavigationService
    ) {
        this.validationInstructions = [
            {validator: new ValidateNotEmpty(this.$translate), instructions: null},
            {validator: new ValidateStringMin(this.$translate, 16), instructions: null},
            {validator: new ValidateStringMax(this.$translate, 16), instructions: null}
        ];
    }

    public callbackOnEnter = () => {
        if (!this._validRedeemCode())  {
            return;
        }
        this.tryRedeemCode = true;

        this.depositModel.redeemVoucher(this.vouchercode)
            .then(
                (res) => {
                    this.tryRedeemCode = false;
                    if (res.status === 'success') {
                        this.functionRelay.call('updateDeposit', undefined);
                        this.vouchercode = '';
                        this.alertManager.success(this.$translate.instant('BILLING.BALANCE.MESSAGE.VOUCHER.SUCCESS'));
                        this.navigation.go('billing.payment-options', {}, {reload: true});
                    }
                },
                () => {
                    this.tryRedeemCode = false;
                }
            );
    };

    private _validRedeemCode = () => {
        this.validationErrorList = [];
        this.validationInstructions.some((instruction) => {
            const erroList = instruction.validator.validate(this.vouchercode);
            if (erroList.length > 0) {
                this.validationErrorList = erroList;
                return true;
            }
            return false;
        });
        return this.validationErrorList.length === 0;
    };
}

export class OrganEditPanelBillingPaymentRedeemVoucherComponent implements ng.IComponentOptions {
    public bindings = {
    };
    public require = {
        $editFormOrganism: '^organismBillingPaymentOptions'
    };
    public controllerAs = '$viewPanelOrgan';
    public controller = OrganEditPanelBillingPaymentRedeemVoucherController;
    public template = require('./payment-redeem-voucher.html');
}
