import { AuthContextService } from '../auth-context';
import { AuthenticationRobotService } from '../authentication';
import { FileSaverService } from './file-saver';

import {
    RpcClient,
    RpcClientService
} from '../rpc-client';

import * as q from 'q';
import { UiRights } from '../../configuration';
import * as Types from '../../types';
import { NavigationService } from '../navigation';

export const BillingClientService = [
    'rpcClient',
    (platformRpcClient: RpcClientService) => platformRpcClient.Factory('/api/billing/v1/json/', 'billing')
];

export class BillingRobotService {
    public static $inject: string[] = [
        'authenticationRobot',
        'billingClient',
        'fileSaver',
        'navigation'
    ];

    constructor(
        private authenticationRobot: AuthenticationRobotService,
        private billingClient: RpcClient,
        private fileSaver: FileSaverService,
        private navigation: NavigationService
    ) {}

    public bankAccountVerify = (
        bankAccountId: string,
        verificationCode: string
    ) => {
        return this.billingClient
            .request('bankAccountVerify')
            .execute({
                bankAccountId: bankAccountId,
                verificationCode: verificationCode
            });
    };

    public listDocuments = (filter, limit, page, sort, companyName, config) => {
        return this.billingClient.request('documentsFind')
            .timeout(config)
            .execute(
                {
                    companyName: companyName,
                    filter: filter,
                    limit: limit,
                    page: page,
                    sort: sort
                }
            );
    };

    public getDocumentPDF = (documentId) => {
        return this.billingClient.request('accountingDocumentPdfGet')
            .execute({ id: documentId });
    };

    public getDocumentCSV = (documentId) => {
        return this.billingClient.request('accountingDocumentCsvGet')
            .execute({ id: documentId });
    };

    public startDocumentPayment = (documentIds, paymentParameters: Types.BillingApi.AbstractPaymentParameters) => {
        return this.billingClient.request('documentPayStart')
            .execute(
                {
                    documentIds: documentIds,
                    paymentParameters: paymentParameters
                }
            );
    };

    public cancelDocumentPayment = (paymentIdOrToken) => {
        return this.billingClient.request('paymentCancel').execute({ paymentIdOrToken: paymentIdOrToken });
    };

    public listInvoices = (filter, limit, page, sort, companyName, config) => {
        return this.billingClient.request('invoicesFind')
            .timeout(config)
            .execute(
                {
                    companyName: companyName,
                    filter: filter,
                    limit: limit,
                    page: page,
                    sort: sort
                }
            );
    };

    public invoicePdf = (invoiceNumber, companyName) => {
        return this.billingClient.request('invoicePdf')
            .execute({ invoiceNumber: invoiceNumber, companyName: companyName });
    };

    public invoicePositionsCSV = (invoiceNumber, companyName) => {
        return this.billingClient.request('invoicePositionsCSV')
            .execute({ invoiceNumber: invoiceNumber, companyName: companyName });
    };

    public priceListDomains = () => {
        return this.billingClient.request('priceListDomains').execute({});
    };

    public listServicePrices = (service) => {
        return this.billingClient.request('servicePricesList').execute({ service: service });
    };

    public articlePurchasePriceList = (filter?, limit?, page?, sort?, owner?) => {
        if (owner && owner.id !== undefined) { // hotfix 108.2
            owner = owner.id;
        }

        return this.billingClient.request('articlePurchasePricesFind')
            .useCache()
            .cacheTimeout(1000 * 60 * 5)
            .ownerAccountId(owner)
            .execute(
                {
                    filter: filter,
                    limit: limit,
                    page: page,
                    sort: sort
                }
            );
    };

    public articlePurchasePriceChangeList = (service, productCodes, from, to, limit, page, sort?, owner?) => {
        return this.billingClient.request('articlePurchasePricesChangesFind')
            .ownerAccountId(owner)
            .execute(
                {
                    from: from,
                    limit: limit,
                    page: page,
                    productCodes: productCodes,
                    service: service,
                    sort: sort,
                    to: to
                }
            );
    };

    public listPayments = (filter, limit, page, sort) => {
        return this.billingClient.request('paymentsFind')
            .execute(
                {
                    filter: filter,
                    limit: limit,
                    page: page,
                    sort: sort
                }
            );
    };

    public getDeposit = (subAccount?: Types.AccountApi.Subaccount) => {
        const owner = subAccount !== undefined
            ? subAccount.id
            : null;

        return this.billingClient.request('depositGet')
            .useCache()
            .cacheTimeout(1000 * 60 * 5)
            .ownerAccountId(owner)
            .execute({});
    };

    public redeemVoucher = (voucherCode) => {
        return this.billingClient.request('voucherRedeem')
            .bustCache('billing', 'depositGet')
            .execute({voucherCode: voucherCode});
    };

    public getVoucherDetails = (voucherCode: string, accountId: string) => {
        return this.billingClient.request('voucherDetailsGet')
            .execute(
                {
                    voucherCode: voucherCode,
                    accountId: accountId
                }
            );
    };

    public depositCreditStart = (amount: number, paymentParameters: Types.BillingApi.AbstractPaymentParameters) => {
        return this.billingClient.request('depositCreditStart')
            .execute(
                {
                    paymentParameters: paymentParameters,
                    value: amount
                }
            );
    };

    public paymentCommit = (paymentCommitParameters) => {
        return this.billingClient.request('paymentCommit')
            .bustCache('billing', 'depositGet')
            .execute({ paymentCommitParameters: paymentCommitParameters });
    };

    public paymentCancel = (paymentIdOrToken: string) => {
        return this.billingClient.request('paymentCancel')
            .execute({ paymentIdOrToken: paymentIdOrToken });
    };

    public articlePromotionalPricesFind = (to, limit, page, sort) => {
        return this.billingClient.request('articlePromotionalPricesFind')
            .execute(
                {
                    limit: limit,
                    page: page,
                    sort: sort,
                    to: to
                }
            );
    };

    // This is the old method - use insteed bankAccountCreate
    public generateDirectDebitForm = (accountHolder, iban, bic, signatory) => {
        return this.billingClient.request('generateDirectDebitForm')
            .execute(
                {
                    accountHolder: accountHolder,
                    bic: bic,
                    iban: iban,
                    signatory: signatory
                }
            );
    };

    public bankAccountCreate = (
        iban,
        accountHolder,
        bic?,
        signatory?,
        dateOfBirth?,
        changePaymentMethod = true
    ) => {
        const request = this.billingClient.request('bankAccountCreate');

        request.ignoreError(62066); // Direct debit not available for account

        return request.execute(
            {
                bankAccountCreateData: {
                    accountHolder: accountHolder,
                    bic: bic,
                    dateOfBirth: dateOfBirth,
                    iban: iban,
                    signatory: signatory
                },
                changePaymentMethod: changePaymentMethod
            }
        );
    };

    public paymentMethodChange = (paymentType, paymentSubType, paymentSourceId) => {
        return this.billingClient.request('paymentMethodChange')
            .execute(
                {
                    paymentOptions: {
                        paymentSourceId: paymentSourceId,
                        paymentSubType: paymentSubType,
                        paymentType: paymentType
                    }
                }
            );
    };

    public createAdv = (dateOfBirth?): q.IPromise<any> => {
        if (
            !AuthContextService.user.adminUser
            || AuthContextService.user.rights.indexOf(UiRights.ACC_USER_LOGGED_IN_AS_SUBACCOUNT) >= 0
        ) {
            return Promise.reject('Diese Aktion kann nur vom Hauptbenutzer des Kontos durchgeführt werden.');
        }

        return this.billingClient.request('signDataProcessingAgreement').execute({ dateOfBirth: dateOfBirth })
            .then(
                (result) => {
                    return this.fileSaver.saveBase64AsPdf(result.response, 'Auftragsverarbeitung.pdf')
                        .then(this.authenticationRobot.refreshAccountData)
                        .then(() => this.navigation.reloadCurrentState());
                }
            );
    };

    public downloadAdv = () => {
        if ([undefined, null, ''].indexOf(
            AuthContextService.account.billingSettings.dataProcessingAgreementSignedOn
        ) >= 0) {
            return Promise.reject(
                'Der Download ist erst möglich, wenn der Vereinbarung zur Auftragsdatenverarbeitung zugestimmt wurde.'
            );
        }

        const filterBy = { field: 'Type', value: 'av' };
        const sortBy = { field: 'AddDate', order: 'desc' };

        return this.billingClient.request('generalDocumentsFind')
            .execute({ filter: filterBy, sort: sortBy })
            .then(
                (result): any => {
                    if (!result.response || !result.response.data || !result.response.data[0]) {
                        return Promise.reject(
                            'Das Dokument wurde nicht gefunden. Bitte versuchen Sie es später erneut.'
                        );
                    }

                    return this.billingClient.request('generalDocumentPdfGet')
                        .execute({ documentId: result.response.data[0].documentId })
                        .then(
                            (documentResponse) => {
                                this.fileSaver.saveBase64AsPdf(documentResponse.response, 'Auftragsverarbeitung.pdf');

                                return Promise.resolve(true);
                            }
                        );
                }
            );
    };

    public jobsFind = (
        filter: Types.Finding.Filter = null,
        limit: number = 25,
        page: number = 1,
        sort: Types.Finding.SortOptions = null,
        skipPagination: boolean = false
    ) => {
        return this.billingClient.request('jobsFind')
            .skipPagination(skipPagination)
            .execute(
                {
                    filter: filter,
                    limit: limit,
                    page: page,
                    sort: sort
                }
            );
    };

    public ownBankAccountsFind = () => {
        return this.billingClient.request('ownBankAccountsFind').execute({});
    };

    public findRenewableObjects = (
        filter: Types.Finding.Filter = null,
        limit: number = 25,
        page: number = 1,
        sort: Types.Finding.SortOptions = null
    ) => {
        return this.billingClient.request('renewableObjectsFind')
            .execute(
                {
                    filter: filter,
                    limit: limit,
                    page: page,
                    sort: sort
                }
            );
    };

    public directDebitCountriesFind = () => {
        return this.billingClient.request('directDebitCountriesFind').execute({});
    };

    public availablePaymentProviders = () => {
        return this.billingClient.request('availablePaymentProviders')
            .useCache()
            .cacheTimeout(1000 * 60 * 5)
            .execute({});
    };

    public potentiallyAvailablePaymentProviders = () => {
        return this.billingClient.request('potentiallyAvailablePaymentProviders')
            .useCache()
            .cacheTimeout(1000 * 60 * 5)
            .execute({});
    };
}
