import * as ng from 'angular';
import * as Types from '../../../../../types';
import { AccountApi, ViewTypes } from '../../../../../types';

export class OrganismDnsWizardProductConfigZoneSlaveController {
    public metadata: ViewTypes.ProductConfigDnsObject;
    public service = 'dns';
    public formCompleted = {
        // tslint:disable:no-empty
        fqdn: () => [null, undefined].indexOf(this.metadata.fqdnList) < 0 && this.metadata.fqdnList.length > 0,
        masterIp: () => [null, undefined].indexOf(this.metadata.masterIp) < 0
        // tslint:enable:no-empty
    };

    private _account: Types.AccountApi.Account | Types.AccountApi.Subaccount;

    public $onInit = () => {
        this._setMetadata();
    };

    public get account(): AccountApi.Account | AccountApi.Subaccount {
        return this._account;
    }

    public set account(value: AccountApi.Account | AccountApi.Subaccount) {
        if (value && this.metadata && this.metadata.account) {
            this.metadata.account = value;
        }

        this._account = value;
    }

    public set configCompleted({}) {} // tslint:disable-line:no-empty
    public get configCompleted() {
        let completed = true;

        for (const part in this.formCompleted) {
            if (!this.formCompleted[part]()) {
                completed = false;
            }
        }

        return completed;
    }

    private _setMetadata = () => {
        this.metadata = {
            account: this._account,
            fqdnList: [],
            masterIp: undefined,
            productCode: undefined,
            productFamily: undefined
        };
    };
}

export class OrganismDnsWizardProductConfigZoneSlaveComponent implements ng.IComponentOptions {
    public bindings = {
        account: '<account',
        configCompleted: '=configCompleted',
        metadata: '='
    };
    public template = require('./dns-wizard-product-config-zone-slave.html');
    public controller = OrganismDnsWizardProductConfigZoneSlaveController;
}
