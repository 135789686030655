import * as ng from 'angular';

export class MoleculePanelServiceObjectController implements ng.IController {
    public static $inject: string[] = [];

    public serviceObject: any;
    public serviceName: string;

    $onInit() {
        this.serviceObject = this.serviceObject || null;
        this.serviceName = this.serviceName || 'domain';
    }

    /* tslint:disable-next-line:max-line-length */
    public getTemplate = () => require('./panel-service-object-' + this.serviceName + '.html?external');
}

export class MoleculePanelServiceObjectComponent implements ng.IComponentOptions {
    public bindings = {
        serviceObject: '<',
        serviceName: '<'
    };
    public controller = MoleculePanelServiceObjectController;
    public controllerAs = '$panelServiceObject';
    public template = require('./panel-service-object.html');
}
