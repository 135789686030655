import { NavigationService } from '@/services';
import * as ng from 'angular';

export class OrganDashboardWidgetAvvPrivacyNotifierController implements ng.IController {
    public static $inject: string[] = ['$translate', 'navigation'];

    public panelHeader = {
        panelIcon: 'sync-alt',
        panelTitle: ''
    };

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private navigation: NavigationService
    ) {
        this.panelHeader.panelTitle = this.$translate.instant('TR_260719-a34198_TR');
    }

    public goRouteDataprocessing = () => {
        this.navigation.go('account.dataprocessing', {});
    };
}

export class OrganDashboardWidgetAvvPrivacyNotifierComponent implements ng.IComponentOptions {
    public template = require('./dashboard-widget-avv-privacy-notifier.html');
    public controller = OrganDashboardWidgetAvvPrivacyNotifierController;
}
