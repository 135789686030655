import * as ng from 'angular';
import {
    AlertManagerService,
    SentryErrorEmitterService
} from '@/services';
import { OrganismOverviewController } from '@/atomic-components/organisms/overview/overview';

export class MoleculePanelOverviewHeaderMultiWipeActionController implements ng.IController {
    public static $inject: string[] = ['$timeout', 'alertManager', '$translate'];

    public OrganismOverview: OrganismOverviewController;
    public confirmHint = false;
    public deleteType: string;
    public serviceName: string;

    constructor(
        private $timeout: ng.ITimeoutService,
        private alertManager: AlertManagerService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    $onInit() {
        this.deleteType = 'permanently';
    }

    public abort = () => {
        this.OrganismOverview.overviewModel.activeMassAction = false;
    };

    public submit = () => {
        const promise = this.OrganismOverview.submitMassAction('wipeSubmit');
        const type = this.serviceName;

        promise.then(
            (result: any) => {
                if (result) {
                    let translation = '';
                    switch (type) {
                        case 'dns-zone':
                            translation = this.$translate.instant('TR_301122-f1d2e8_TR');
                            break;
                        case 'database':
                            translation = this.$translate.instant('TR_071123-81be5f_TR');
                            break;
                        default:
                            translation = this.$translate.instant('TR_100119-139c51_TR');
                    }
                    this.alertManager.success(this.$translate.instant(translation));
                    this.abort();
                    this.$timeout(() => {
                        this.OrganismOverview.overviewLoad();
                    }, 1000);
                }
            }, (error: Error) => {
                let apiMethod = '';
                switch (type) {
                    case 'dns-zone':
                        apiMethod = 'zonePurgeRestorable';
                        break;
                    case 'database':
                        apiMethod = 'databaseWipe';
                        break;
                    default:
                        apiMethod = 'unkown'
                }
                SentryErrorEmitterService.sendSentryReport(
                    error,
                    {error: error},
                    { key: 'api', service: 'multi-wipe-action', apiMethod }
                );
            });

    };

    public getTemplate = () => require('./multi-wipe-' + this.serviceName + '-action.html?external');
}

export class MoleculePanelOverviewHeaderMultiWipeActionComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        serviceName: '<'
    };
    public controller =  MoleculePanelOverviewHeaderMultiWipeActionController;
    public template = require('./multi-wipe-action.html');
}
