import { LinkListOverviewItem } from '../../atomic-components/molecules/links';
import {
    AccountFilterField,
    TextFilterField
} from '../../atomic-components/molecules/search/fancy-filter-fields';
import { UiRights } from '../../configuration';
import { OverviewModel } from '../abstract';
import { AuthContextService } from '../auth-context';
import { RequestCancellerService } from '../request-canceller';
import { DdnsModelService } from './dns-ddns-model';

export class DdnsCredentialsOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', 'authContext', 'ddnsModel']
    );

    public readonly service = 'dns';
    public readonly objectType = 'DynamicDnsCredentials';
    public readonly idField = 'dynamicDnsCredentialsId';
    public readonly defaultFilterFields = ['DynamicDnsCredentialsName', 'DynamicDnsCredentialsUserName'];

    protected listCallbacks = [{callback: this.ddnsModel.listCredentials}];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private ddnsModel: DdnsModelService
    ) {
        super(RequestCanceller);
    }

    // tslint:disable-next-line:member-ordering
    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField(
                    'dynamicDnsCredentialsName',
                    this.$translate.instant('TR_140119-796b28_TR')
                ),
                new TextFilterField(
                    'DynamicDnsCredentialsUserName',
                    this.$translate.instant('TR_140119-680508_TR')
                ),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR'))
            ];
        }

        return this._cachedAvailableFilters;
    }

    // tslint:disable-next-line:member-ordering
    public set availableFilters({}) {
        return;
    }

    public actionLinks = (): LinkListOverviewItem[] => {
        return [
            {
                isGranted: this.authContext.isGrantedAny([UiRights.DNS_DDNSCREDENTIALS_CREATE]),
                route: 'dns.ddns.credentials.new',
                text: this.$translate.instant('TR_200319-b4b55a_TR')
            }
        ] as LinkListOverviewItem[];
    };
}
