import { OrganismNewEditFormCronjobController } from '@/atomic-components';
import { CronJobTimeDisplayRenderer } from '@/services';
import * as ng from 'angular';

import { CronjobScheduleConst, TimeSpecificationsConst } from '../../../../../configuration';
import { EditPanelStatus, FormDropDownItems } from '../../../../molecules';

export class OrganEditPanelCronjobScheduleController {
    public static $inject: string[] = ['$translate', 'cronJobTimeDisplayRenderer'];
    public cancel;
    public $editFormOrganism: OrganismNewEditFormCronjobController;
    public panelRight;
    public initialStatus: EditPanelStatus;
    public options: { [key: string]: FormDropDownItems[] } = {
        schedule: [],
        weekday: [],
        dayOfMonth: [],
        daypart: [],
        hour: [],
        minute: []
    };
    minuteOptions: {
        withoutDefault: {
            name: string;
            value: number;
        }[];
    };

    constructor(
        private $translate: ng.translate.ITranslateService,
        private textRenderer: CronJobTimeDisplayRenderer
    ) {
        this.options = this.createOptions();
        this.minuteOptions = this.createMinuteOptions();

    }

    $onInit(): void {
        this.initialStatus = this.$editFormOrganism.create ? EditPanelStatus.CREATE : EditPanelStatus.READONLY;

    }

    public chooseMinuteOptions(): {
        name: string;
        value: number;
    }[] {
        // Automatic is not supported by dropdown
        return this.minuteOptions.withoutDefault;
    }

    public shouldDisplayWeekDay(): boolean{
        return this.$editFormOrganism.hasWeeklySchedule();
    }

    public shouldDisplayMinute(): boolean {
        // WEBHOSTING-135:
        // show minute if 'hourly' or together with Hour
        return (
                this.$editFormOrganism.hasCustomDaypart()
                && !this.$editFormOrganism.hasMinutelySchedule()
            )
            || this.$editFormOrganism.hasHourlySchedule();
    }

    public shouldDisplayHour(): boolean {
        // FIXME SOMETHING OTHER THAN TRUE
        // WEBHOSTING-135

        return this.$editFormOrganism.hasCustomDaypart()
            && !(
                this.$editFormOrganism.hasHourlySchedule()
                || this.$editFormOrganism.hasMinutelySchedule()
            );
    }

    public shouldDisplayWeekdays(): boolean{
        return this.$editFormOrganism.hasMonthlySchedule();
    }

    shouldDisplayDaypart(): boolean{
        return this.$editFormOrganism.hasWeeklySchedule()
            || this.$editFormOrganism.hasDailySchedule()
            || this.$editFormOrganism.hasMonthlySchedule();
    }

    public translateWeekday = (abbreviation: ''|'mon'|'tue'|'wed'|'thu'|'fri'|'sat'|'sun'): string => {
        let translatedWeekday: string;

        TimeSpecificationsConst.weekdays.some(weekday => {
            if (weekday.value === abbreviation) {
                translatedWeekday = this.$translate.instant(weekday.translationId);
                return true;
            }

            return false;
        });

        return translatedWeekday;
    };

    private splitDaypart = (daypart: string): string[] => daypart.split('-');

    private translateDaypart = (daypart: string): string => {
        const part = this.splitDaypart(daypart);
        return this.$translate.instant('TR_090119-f9586f_TR', { partOne: part[0], partTwo: part[1] });
    };

    private translateDaypartWithSentinel(daypart: string): string {
        if (daypart.includes('-')) {
            // current default behaviour
            return this.translateDaypart(daypart);
        } else {
            // sentinel value
            return this.$translate.instant('TR_051021-778608_TR');
        }
    }

    private createOptions(): { [key: string]: FormDropDownItems[] } {
        const options: { [key: string]: FormDropDownItems[] } = {};
        options.schedule = CronjobScheduleConst.executionCycle.map(schedule => {
            const dropdownItem: FormDropDownItems = schedule;
            dropdownItem.name = this.$translate.instant(schedule.translationId);
            return dropdownItem;
        });

        options.weekday = TimeSpecificationsConst.weekdays.map(weekday => {
            const dropdownItem: FormDropDownItems = weekday;
            dropdownItem.name = this.$translate.instant(weekday.translationId);
            return dropdownItem;
        });

        // region setup correct option order for hours and minutes
        options.hour = TimeSpecificationsConst.hours;

        options.dayOfMonth = (Array(28)).fill(1).map((v: number, index: number) => ({
            name: `${index + 1}`,
            value: index + 1
        }));
        options.daypart = CronjobScheduleConst.dayParts.map(value => ({
            name: this.translateDaypartWithSentinel(value),
            value: value
        }));
        return options;
    }

    private createMinuteOptions(): {
        withoutDefault: {
            name: string;
            value: number;
        }[];
    }{
        // create from
        const returnValue = {
            withoutDefault:TimeSpecificationsConst.minutes()
        };
        // this is per backend spec expecting 24h and 60m respectively to represent 0h / 0m
        // we want 0 to be the first non "automatic" value to choose
        // for default, we also provide "*" as a

        // here we just remove the first element and then insert the last element as first item
        // [5,1,2,3,4]

        returnValue.withoutDefault.shift();
        returnValue.withoutDefault.unshift(returnValue.withoutDefault.pop());

        return returnValue;
    }
}

export class OrganEditPanelCronjobScheduleComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismNewEditFormCronjob'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./cronjob-schedule.html');
    public controller = OrganEditPanelCronjobScheduleController;
}
