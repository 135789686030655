import * as ng from 'angular';
import { keenRobotsCommon } from '../../types';
import { AuthContextService } from '../auth-context';

export interface RestrictionHelperData {
    helpTexts: {
        comment: string;
        contactSupport: string;
        note: string;
    };
    restrictions: { [restrictionType: string]: keenRobotsCommon.Restriction[] };
}

export class RestrictionHelperService {
    public static $inject: string[] = [
        '$state',
        '$translate'
    ];

    public apiMethod: any;
    public model: any;
    public apiObject: any;
    public objectType: string;

    private transDisabled = {
        database:  /* translationId */ 'TR_240519-f02e22_TR',
        dns:       /* translationId */ 'TR_240519-849b07_TR',
        email:     /* translationId */ 'TR_240519-cdc2d9_TR',
        nextcloud: /* translationId */ 'TR_010819-223fce_TR',
        webspace:  /* translationId */ 'TR_140119-3f6a1e_TR'
    };

    private identFields = { // First in array should always be the id field!
        database: [
            { apiField: 'databaseId', objectField: 'id' }
        ],
        dns: [
            { apiField: 'zoneConfigId', objectField: 'id' },
            { apiField: 'zoneName', objectField: 'nameUnicode' }
        ],
        email: [
            { apiField: 'mailboxId', objectField: 'id' },
            { apiField: 'emailAddress', objectField: 'emailAddress' }
        ],
        nextcloud: [
            { apiField: 'nextcloudId', objectField: 'id' }
        ],
        webspace: [] // todo
    };

    private knownTypes = {
        disabled:     /* translationId */ 'TR_250519-bceb33_TR',
        noTargets:    /* translationId */ 'TR_250519-70bcbd_TR',
        overQuota:    /* translationId */ 'TR_250519-5bfc8b_TR',
        smtpDisabled: /* translationId */ 'TR_250519-5c2141_TR'
    };

    private availableTypesByService =  {
        database: ['disabled', 'overQuota'],
        dns: ['disabled'],
        email: ['smtpDisabled', 'overQuota', 'noTargets'],
        nextcloud: ['disabled'],
        webspace: ['disabled']
    };

    constructor(
        private $state: ng.ui.IStateService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public init = (apiObject, objectType, model?, apiMethod?) => {
        this.apiObject = ng.copy(apiObject);
        this.objectType = objectType;
        this.model = model;
        this.apiMethod = apiMethod;
    };

    public get service() {
        return this.objectType;
    }

    public set service(type) {
        return;
    }

    public getIdIdentField = () => {
        return this.identFields[this.service][0];
    };

    public hasRestrictions = () => {
        return [null, undefined].indexOf(this.apiObject.restrictions) < 0 && this.apiObject.restrictions.length > 0;
    };

    public hasRestrictionsByCurrentUser = () => {
        if ([null, undefined].indexOf(this.apiObject.restrictions) >= 0 || this.apiObject.restrictions.length === 0) {
            return false;
        }
        return this.apiObject.restrictions.some(
            (restriction) => restriction.accountId === AuthContextService.account.id
        );
    };

    public getPanelTitle = () => {
        const transPanelTitles = {
            database:  /* translationId */ 'TR_240519-8260ae_TR',
            dns:       /* translationId */ 'TR_270519-309fa6_TR',
            email:     /* translationId */ 'TR_270519-ea0506_TR',
            nextcloud: /* translationId */ 'TR_010819-b44e10_TR',
            webspace:  /* translationId */ '!! TODO TRANSLATION MISSING !!'
        };
        return transPanelTitles[this.objectType];
    };

    public getDefaultType = () => {
        return this.availableTypesByService[this.service][0];
    };

    public getBackLinkText = () => {
        const transPanelTitles = {
            database:  /* translationId */ 'TR_250519-7274a2_TR',
            dns:       /* translationId */ 'TR_100119-4e4e17_TR',
            email:     /* translationId */ 'TR_110119-f1b5d4_TR',
            nextcloud: /* translationId */ 'TR_010819-62941c_TR',
            webspace:  /* translationId */ '!! TODO TRANSLATION MISSING !!'
        };
        return transPanelTitles[this.objectType];
    };

    public getBaseRouteAndParams = () => {
        const stateInBundle = this.$state.current.name.split('.').indexOf('bundle') >= 0;
        const stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';
        const stateInProducts = this.$state.current.name.split('.')[0] === 'storage';
        let restrictionsEditLink;
        let routeParams;

        switch (this.objectType) {

            case 'database':
                switch (true) {
                    case stateInBundle:
                        restrictionsEditLink = 'bundle.id.databases.id.';
                        routeParams = {
                            bundleId: this.$state.$current.locals.globals.bundle.id,
                            databaseId: this.apiObject.id
                        };
                        break;
                    case stateInMachine:
                        restrictionsEditLink = 'managed-servers.id.databases.id.';
                        routeParams = {
                            databaseId: this.apiObject.id,
                            machineId: this.$state.$current.locals.globals.machine.id
                        };
                        break;
                    default:
                        restrictionsEditLink = 'database.databases.id.';
                        routeParams = {
                            databaseId: this.apiObject.id
                        };
                }

                return {
                    icon: 'database',
                    route: restrictionsEditLink,
                    routeParams: routeParams
                };

            case 'dns':
                return {
                    icon: 'location-arrow',
                    route: 'dns.zones.id.',
                    routeParams: { zoneId: this.apiObject.id }
                };

            case 'email':
                switch (true) {
                    case stateInBundle:
                        restrictionsEditLink = 'bundle.id.mailboxes.id.';
                        routeParams = {
                            bundleId: this.$state.$current.locals.globals.bundle.id,
                            mailboxId: this.apiObject.id
                        };
                        break;
                    case stateInMachine:
                        restrictionsEditLink = 'managed-servers.id.mailboxes.id.';
                        routeParams = {
                            machineId: this.$state.$current.locals.globals.machine.id,
                            mailboxId: this.apiObject.id
                        };
                        break;
                    default:
                        restrictionsEditLink = 'email.mailboxes.id.';
                        routeParams = {
                            mailboxId: this.apiObject.id
                        };
                }

                return {
                    icon: 'envelope',
                    route: restrictionsEditLink,
                    routeParams: routeParams
                };

            case 'nextcloud':
                if (stateInProducts) {
                    return {
                        icon: 'save',
                        route: 'storage.storage-products.id.',
                        routeParams: { storageProductId: this.apiObject.id }
                    };
                } else {
                    return {
                        icon: '/assets/images/logos/nextcloud-bw.svg',
                        iconPath: true,
                        route: 'nextcloud.id.',
                        routeParams: { storageProductId: this.apiObject.id }
                    };
                }

            case 'webspace':
                return {
                    icon: 'database',
                    route: 'todo.todo.todo', // TODO: Add route
                    routeParams: { todo: this.apiObject.id }
                };

            default:
                return;
        }
    };

    public getCalloutType = (restrictionCategory: string) => {
        return ['disabled'].indexOf(restrictionCategory) >= 0 ? 'error' : 'warning';
    };

    public userCanEditRestrictions = () => {
        if ([null, undefined, []].indexOf(this.apiObject.restrictions) >= 0) {
            return false;
        }
        return this.apiObject.restrictions.some(
            (restriction) => {
                return restriction.accountId !== null;
            }
        );
    };

    public getRestrictionTypeName = (type) => {
        if (this.knownTypes[type] !== undefined) {
            return this.knownTypes[type];
        }
        return type;
    };

    public getAvailableRestrictionTypes = () => {
        const availableServices = [];

        if (this.availableTypesByService[this.service] !== undefined) {
            this.availableTypesByService[this.service].map(
                (restriction) => {
                    availableServices.push(
                        {
                            name: this.knownTypes[restriction],
                            restriction: restriction
                        }
                    );
                }
            );
        }

        return availableServices;
    };

    public isUserEditableType = (restrictionType: string): boolean => {
        switch (restrictionType) {
            case 'disabled':
                return true;
            case 'smtpDisabled':
                return true;
            case 'overQuota':
                return false;
            case 'noTargets':
                return false;
            default:
                return false;
        }
    };

    public getRestrictionList = (newRestrictions?) => {
        if (newRestrictions !== undefined) {
            this.apiObject.restrictions = newRestrictions;
        }
        const aggregatedList: RestrictionHelperData = {
            helpTexts: {
                comment: this.$translate.instant('TR_280519-ca6299_TR'), // public
                contactSupport: this.$translate.instant('TR_140119-c4899e_TR'),
                note: this.$translate.instant('TR_280519-ea3440_TR') // internal
            },
            restrictions: {}
        };
        if ([null, undefined, []].indexOf(this.apiObject.restrictions) >= 0) {
            return aggregatedList;
        }
        const restrictions: keenRobotsCommon.Restriction[] = this.apiObject.restrictions;
        const tempAggregatedList = [];
        const restrictionsOrder = ['disabled', 'smtpDisabled', 'overQuota', 'noTargets'];

        restrictions.forEach(
            (restriction) => {
                if (
                    this.transDisabled[this.objectType] !== undefined
                    && ([null, undefined].indexOf(restriction.information) >= 0 || restriction.information.length === 0)
                ) {
                    restriction.information = this.$translate.instant(this.transDisabled[this.objectType]);
                }
                if (tempAggregatedList[restriction.type] === undefined) {
                    tempAggregatedList[restriction.type] = [];
                }
                tempAggregatedList[restriction.type].push(restriction);
            }
        );

        restrictionsOrder.forEach((restrictionType) => {
            if (tempAggregatedList[restrictionType] !== undefined) {
                aggregatedList.restrictions[restrictionType] = tempAggregatedList[restrictionType];
                tempAggregatedList[restrictionType] = null;
            }
        });
        tempAggregatedList.forEach((restrictionList, restrictionType) => {
            if (restrictionList !== null) {
                aggregatedList.restrictions[restrictionType] = restrictionList;
            }
        });

        return aggregatedList;
    };

    public getRestrictionForUser = (userId: string) => {
        const emptyRestrictionObject = {
            accountId: userId,
            id: null,
            information: this.transDisabled[this.objectType] !== undefined
                ? this.$translate.instant(this.transDisabled[this.objectType])
                : '',
            internalComment: '',
            type: 'disabled'
        };

        if ([null, undefined].indexOf(this.apiObject.restrictions) >= 0) {
            return emptyRestrictionObject;
        }
        const foundRestriction = this.apiObject.restrictions.filter(
            (restriction) => {
                return restriction.accountId === userId;
            }
        );
        if (foundRestriction.length === 0) {
            return emptyRestrictionObject;
        }
        return foundRestriction[0];
    };

    public removeAllRestrictions = (onlyRemoveRestrictionsOwnedBy: number|null = null) => {
        const restrictions = this.apiObject.restrictions.filter(
            (restriction) => {
                if (onlyRemoveRestrictionsOwnedBy === null) {
                    return restriction.accountId !== null;
                } else {
                    return restriction.accountId === onlyRemoveRestrictionsOwnedBy;
                }
            }
        );

        const identField = this.getIdIdentField();

        const apiObject = {
            restrictionIdsToDelete: restrictions.map( (restriction) => restriction.id ),
            restrictionsToAdd: [],
            restrictionsToUpdate: []
        };

        apiObject[identField.apiField] = this.apiObject[identField.objectField];

        return this.model[this.apiMethod](apiObject);
    };

    public restrictObject = () => {
        const newRestrictionObject = {
            accountId: AuthContextService.account.id,
            id: null,
            information: this.transDisabled[this.objectType] !== undefined
                ? this.$translate.instant(this.transDisabled[this.objectType])
                : this.$translate.instant('TR_010719-d0efab_TR'),
            internalComment: this.$translate.instant('TR_010719-bf650e_TR'),
            type: this.availableTypesByService[this.service][0]
        };
        const identField = this.getIdIdentField();

        const apiObject = {
            restrictionIdsToDelete: [],
            restrictionsToAdd: [newRestrictionObject],
            restrictionsToUpdate: []
        };

        apiObject[identField.apiField] = this.apiObject[identField.objectField];

        return this.model[this.apiMethod](apiObject);
    };
}
