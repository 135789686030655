import * as Sentry from '@sentry/browser';
import ng from 'angular';
import { UiLanguageDefaultSettingsConst, UiRights } from '@/configuration';
import { STATES_ROUTES } from '@/scripts/states';
import { AlertManagerService } from '../alert-manager';
import { AuthContextService } from '../auth-context';
import { IgnoredApiCodes } from '../errors/ignored-api-codes.enum';
import { LocalstorageHelperService } from '../helpers';
import { NavigationService } from '../navigation';
import { PanicMode } from '../panic-mode';
import { RpcClient, RpcClientService } from '../rpc-client';
import { SentryErrorEmitterService } from '@/services';

export const AuthenticationClientService = [
    'rpcClient',
    (platformRpcClient: RpcClientService) => platformRpcClient.Factory('/api/account/v1/json/', 'account')
];

export class AuthenticationRobotService {
    public static $inject: string[] = [
        '$rootScope',
        'alertManager',
        'authenticationClient',
        'localstorageHelper',
        'navigation'
    ];

    constructor(
        private $rootScope: ng.IRootScopeService|any,
        private alertManager: AlertManagerService,
        private authenticationClient: RpcClient,
        private localstorageHelper: LocalstorageHelperService,
        private navigation: NavigationService
    ) {}

    public getAuthenticationDetails = async () => {
        const request = this.authenticationClient.request('getAuthenticationDetails');
        request.unauthenticated();

        try {
            const result = await request.execute({authToken: ''});
            if (
                (
                    result.response.account !== undefined
                    && result.response.account.enabled === undefined
                )
                || result.response.account.enabled
            ) {
                if ([undefined, null].indexOf(result.response.user) >= 0) {
                    this.navigation.toLogin();
                    return;
                }

                AuthContextService.setContextFromResponse(result.response);

                this.$rootScope.uiDefaultLanguage = UiLanguageDefaultSettingsConst.languageUiIso;

                if ([undefined, ''].indexOf(result.response.user.addition) >= 0) {
                    this.$rootScope.username = result.response.user.name;
                } else {
                    this.$rootScope.username = result.response.user.addition;
                }

                SentryErrorEmitterService.setUser(
                    result.response.user.emailAddress,
                    result.response.user.id,
                    result.response.account.id,
                    AuthContextService.isLoggedAsSubaccount(),
                );

                if (['required', 'waitingForActivation'].indexOf(result.response.user.twoFactorAuthStatus) >= 0) {
                    this.navigation.go('enableTwoFaOnLogin', {user: result.response.user, password: ''});
                    return;
                } else if (['active'].indexOf(result.response.user.twoFactorAuthStatus) >= 0) {
                    this.navigation.goToLastTarget();
                    return;
                }

                this.$rootScope.$emit('login', result.response.user, result.response.account);
            } else {
                this.alertManager.warning(/* translationID */'ACCOUNT.MESSAGE.LOGOUT-DUE-TO-DISABLED-ACCOUNT');
                AuthContextService.clear();
            }
        }

        catch (reason) {
            if (reason.code in IgnoredApiCodes || ['HttpError', 'AuthContextError'].indexOf(reason.name) >= 0) {
                AuthContextService.clear();
            } else if (reason.code === undefined) {
                SentryErrorEmitterService.sendSentryReport(
                    `Unexpected ${reason.name} when attempting to receive CRSF token`,
                    { name: reason.name, message: reason.message},
                    { key: 'crsf'}
                )
            } else {
                SentryErrorEmitterService.sendSentryReport(
                    `Unexpected error code ${reason.code} when attempting to receive CRSF token`,
                    { extra: { name: reason.name, code: reason.code, reason: JSON.stringify(reason) }},
                    { key: 'crsf' },
                    Sentry.Severity.Info
                );
            }
        }
    };

    public refreshAccountData = async () => {
        const request = this.authenticationClient.request('getAuthenticationDetails');
        request.unauthenticated();
        const result = await request.execute({authToken: ''});

        if (
            result.response.account?.enabled === undefined
            || result.response.account.enabled
        ) {
            if ([undefined, null].indexOf(result.response.user) >= 0) {
                this.navigation.toLogin();
                return;
            }

            AuthContextService.resetContextFromResponse(result.response);
        }
    };

    public login = async (emailAddress: string, password: string, twoFactorAuthToken?: string) => {
        PanicMode.stop();

        const request = this.authenticationClient.request('login');
        request.unauthenticated();

        try {
            const result = await request.execute(
                {
                    authToken: '',
                    emailAddress: emailAddress,
                    password: password,
                    twoFactorAuthToken: twoFactorAuthToken
                }
            );

            AuthContextService.setContextFromResponse(result.response);

            this.$rootScope.uiDefaultLanguage = UiLanguageDefaultSettingsConst.languageUiIso;

            if ([undefined, ''].indexOf(result.response.user.addition) >= 0) {
                this.$rootScope.username = result.response.user.name;
            } else {
                this.$rootScope.username = result.response.user.addition;
            }

            SentryErrorEmitterService.setUser(
                result.response.user.emailAddress,
                result.response.user.id,
                result.response.account.id,
                AuthContextService.isLoggedAsSubaccount(),
            );

            this.$rootScope.$emit('login', result.response.user, result.response.account);

            if (
                ['required', 'waitingForActivation'].indexOf(result.response.user.twoFactorAuthStatus) >= 0
                && !(
                    result.response.user.rights
                    && result.response.user.rights.includes(UiRights.ACC_USER_LOGGED_IN_AS_SUBACCOUNT)
                )
            ) {
                return result;
            }

            return result;
        }

        catch (error) {
            return {
                errorCode: error.code,
                errorContext: error.context,
                errorName: error.name,
                errorText: error.message
            };
        }
    };

    public logout = async () => {
        // unset users search history in localstorage
        this.localstorageHelper.removeUsersSearchCache();

        PanicMode.stop();

        const request = this.authenticationClient.request('logout');

        try {
            await request.execute({});

            const routeExists = STATES_ROUTES.filter((route) => route.name === 'see-you-soon');
            let url = '';

            if (routeExists.length > 0) {
                url = '/' + routeExists[0].name + '?lang=' + AuthContextService.user.language;
            }

            window.location.href = window.location.protocol + '//' + window.location.host + url;
        }

        catch ({}) {
            AuthContextService.clear();
            window.location.href = window.location.protocol + '//' + window.location.host;
        }
    };

    public requestPasswordReset = (emailAddress: string) => {
        const request = this.authenticationClient.request('userRequestPasswordReset');

        request.unauthenticated();

        return request.execute({emailAddress: emailAddress, authToken: ''});
    };

    public resetPassword = (emailAddress: string, resetCode: string, password: string) => {
        const request = this.authenticationClient.request('userResetPassword');

        request.unauthenticated();

        return request.execute(
            {
                authToken: '',
                emailAddress: emailAddress,
                newPassword: password,
                resetCode: resetCode
            }
        );
    };
}
