import * as ng from 'angular';
import { PanelHeaderData } from '@/atomic-components/molecules/panels';
import { AuthContextService } from '@/services';

export class TemplateMainNewDashboardEditController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;

    public constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit(): void {
        const hour = new Date().getHours();
        let greeting = /* translationId */ 'TR_110119-922c4c_TR';

        switch (true) {
            case hour >= 5 && hour < 11: // morning
                greeting = /* translationId */ 'TR_010719-fce241_TR';
                break;
            case hour >= 12 && hour < 18: // day
                greeting = /* translationId */ 'TR_010719-a39171_TR';
                break;
            case hour >= 18 && hour < 22: // evening
                greeting = /* translationId */ 'TR_010719-a5afb3_TR';
                break;
            case (hour >= 22 && hour < 24) || hour < 5: // night
                greeting = /* translationId */ 'TR_010719-fe20d4_TR';
                break;
        }

        this.pageHeaderData = {
            panelHeaderRoute: '',
            panelIcon: 'home-lg-alt',
            panelTitle: this.$translate.instant(greeting, { userName: AuthContextService.user.name })
        };
    }
}

export class TemplateMainNewDashboardEditComponent implements ng.IComponentOptions {
    public bindings = {
        _isAdmin: '<isAdmin'
    };
    public controller = TemplateMainNewDashboardEditController;
    public template = require('./main-dashboard-edit-template.html');
}
