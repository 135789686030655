import { AuthContextService } from '../auth-context';
import { BillingRobotService } from '../billing';
import { ModelHelper } from '../model-helper';
import { UserRobotService } from './robot';

import * as ng from 'angular';
import * as q from 'q';
import * as Types from '../../types';

export class AccountModelService {
    public static $inject: string[] = [
        '$cacheFactory',
        'billingRobot',
        'userRobot'
    ];
    private accountCache: any;

    constructor(
        private $cacheFactory: ng.ICacheFactoryService,
        private billingRobot: BillingRobotService,
        private userRobot: UserRobotService
    ) {
        this.accountCache = this.$cacheFactory('accountCache');
    }

    public getAll = () => {
        return this.userRobot.listAccounts(undefined, 0, 1)
        .then(this.returnResponseData);
    };

    public clearSubAccountCache = () => {
        this.userRobot.clearCache();
        return this;
    };

    public list = (
        limit?: number,
        page?: number,
        filters?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions
    ) => {
        sort = sort || {
            field: 'subaccountName',
            order: 'ASC'
        };
        page = page || 1;

        if ([undefined, null].indexOf(this.getCache()) >= 0) {
            this.putCache({});
        }

        return this.userRobot.listAccounts(filters, limit, page, sort)
        .then(ModelHelper.returnListResponse)
        .then(
            (accountList) => {
                accountList.data.forEach(
                    (account: Types.AccountApi.Account) => this.pushSuccess(account.id, account)
                );

                return accountList;
            }
        );
    };

    public listSubaccounts = (
        limit?: number,
        page?: number,
        filters?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions
    ) => {
        sort = sort || {
            field: 'subaccountName',
            order: 'ASC'
        };
        page = page || 1;

        if ([undefined, null].indexOf(this.getCache()) >= 0) {
            this.putCache({});
        }

        return this.userRobot.listSubaccounts(filters, limit, page, sort)
        .then(ModelHelper.returnListResponse)
        .then(
            (subaccountList) => {
                subaccountList.data.forEach(
                    (subaccount: Types.AccountApi.Subaccount) => this.pushSuccess(subaccount.id, subaccount)
                );

                return subaccountList;
            }
        );
    };

    public listSubaccountsWithoutPagination = (
        limit?: number,
        page?: number,
        filters?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions
    ) => {
        sort = sort || {
            field: 'subaccountName',
            order: 'ASC'
        };
        page = page || 1;

        if ([undefined, null].indexOf(this.getCache()) >= 0) {
            this.putCache({});
        }

        return this.userRobot.listSubaccountsWithoutPagination(filters, limit, page, sort)
        .then(ModelHelper.returnListResponse)
        .then(
            (subaccountList) => {
                subaccountList.data.forEach(
                    (subaccount: Types.AccountApi.Subaccount) => this.pushSuccess(subaccount.id, subaccount)
                );

                return subaccountList;
            }
        );
    };

    public getTotal = () => {
        return this.userRobot.listSubaccounts(undefined, 1, 1)
            .then(ModelHelper.returnTotalEntries);
    };

    // Find account by id
    public findOne = (id: string): q.IPromise<Types.AccountApi.Subaccount> => {
        let accounts = this.getCache();
        let refresh = false;

        if (accounts === undefined) {
            accounts = {};
            refresh = true;

            this.putCache(accounts);
        } else if (accounts[id] === undefined || this.isExpired(accounts[id])) {
            refresh = true;
        }

        if (refresh) {
            return this.userRobot.listSubaccountsWithoutPagination(
                { field: 'subaccountId', value: id.toString() },
                1,
                1
            )
            .then(ModelHelper.returnFindOneResponse)
            .then(
                (result) => {
                    return this.pushSuccess(id, result);
                },
                () => {
                    return this.pushFailure(id);
                }
            );
        } else {
            return q.when(accounts[id].data);
        }
    };

    public findOneSubaccount = (id: string): PromiseLike<any> => {
        let subaccounts = this.getCache();
        let refresh = false;

        if (subaccounts === undefined) {
            subaccounts = {};
            refresh = true;

            this.putCache(subaccounts);
        } else if (subaccounts[id] === undefined || this.isExpired(subaccounts[id])) {
            refresh = true;
        }

        if (refresh) {
            return this.userRobot.listSubaccountsWithoutPagination(
                { field: 'subaccountId', value: id.toString() },
                1,
                1
            )
            .then((result) => ModelHelper.returnFindOneResponse(result))
            .then(
                (result) => this.pushSuccess(id, result),
                () => this.pushFailure(id)
            );
        } else {
            return q.when(subaccounts[id].data);
        }
    };

    public getPath = (id: string) => {
        let z = 0;

        return this.getAccountBasicData()
        .then(
            (rootAccount: Types.AccountApi.Account) => {
                const accounts = [];
                const addToPath = (account: Types.AccountApi.Subaccount) => {
                    z++;

                    if (z >= 10) {
                        return;
                    }

                    accounts.push(account);
                    const parentId = account.parentAccountId;

                    if (parentId === rootAccount.id || (parentId as any) === 0) {
                        accounts.push(rootAccount);
                        return accounts;
                    }

                    return this.findOne(parentId).then(addToPath);
                };

                return this.findOne(id).then(addToPath);
            }
        );
    };

    public loadSettings = (owner?: string) => {
        return this.userRobot.getAccountSettings(owner);
    };

    // Find all subaccounts from the id's
    public findSubaccountsByIds = (ids: string[]) => {
        const filter: Types.Finding.Filter = { subFilterConnective: 'OR', subFilter: ids.map(this.idFilter) };
        return this.userRobot.listSubaccountsWithoutPagination(filter, 0, 1)
        .then(ModelHelper.returnFindResponse);
    };

    public getAccountBasicData = (): q.IPromise<Types.AccountApi.Account> => {
        return this.userRobot.getAccountBasicData()
        .then(this.returnResponse);
    };

    public updateAccount = (accountTmp: Types.AccountApi.Account) => {
        const account = ng.copy(accountTmp);

        if (
            [null, undefined].indexOf(account.whiteLabelSettings) < 0
            && account.whiteLabelSettings
            && account.whiteLabelSettings.whiteLabelSettingsEnabled === false
        ) {
            account.whiteLabelSettings = null;
        }

        const defaultContactTypes = [
            'defaultContactAdminId',
            'defaultContactOwnerId',
            'defaultContactTechId',
            'defaultContactZoneId'
        ];

        account.domainSettings.defaultContactAdminId = account.domainSettings.defaultContactAdminId || '0';
        account.domainSettings.defaultContactOwnerId = account.domainSettings.defaultContactOwnerId || '0';
        account.domainSettings.defaultContactTechId = account.domainSettings.defaultContactTechId || '0';
        account.domainSettings.defaultContactZoneId = account.domainSettings.defaultContactZoneId || '0';

        return this.userRobot.updateAccount(account);
    };

    public deleteAccount = () => {
        return this.userRobot.deleteAccount()
        .then(
            () => {
                // This SHOULD bring the user to the login page.
                window.location.href = window.location.origin;
            }
        );
    };

    public getOwnUserFlags = (filterKey?: string) => {
        return this.userRobot.getOwnUserFlags()
        .then((response) => response.responses)
        .then(
            (response) => {
                if ([undefined].indexOf(filterKey) < 0) {
                    let returnValue = null;
                    response.some(
                        (flag: any) => {
                            if (flag.key === filterKey) {
                                returnValue = flag.value;
                                return true;
                            }
                            return false;
                        }
                    );

                    return returnValue;
                }

                return response;
            }
        );
    };

    public setOwnUserFlag = (key: string, value: string) => {
        return this.userRobot.setOwnUserFlag(key, value).then(this.returnResponse);
    };

    public createSubaccount = (
        accountTmp: Types.AccountApi.Subaccount,
        user: Types.AccountApi.User,
        password: string
    ) => {
        const account = ng.copy(accountTmp);

        if (
            [null, undefined].indexOf(account.whiteLabelSettings) < 0
            && account.whiteLabelSettings.whiteLabelSettingsEnabled === false
        ) {
            account.whiteLabelSettings = null;
        }

        return this.userRobot.createSubaccount(account, user, password);
    };

    public updateSubaccount = (accountTmp: Types.AccountApi.Subaccount) => {
        const account = ng.copy(accountTmp);

        if (
            [null, undefined].indexOf(account.whiteLabelSettings) < 0
            && account.whiteLabelSettings.whiteLabelSettingsEnabled === false
        ) {
            account.whiteLabelSettings = null;
        }

        return this.userRobot.updateSubaccount(account);
    };

    public deleteSubaccount = (subaccountId: string) => {
        return this.userRobot.deleteSubaccount(subaccountId);
    };

    public enableSubaccount = (accountId: string) => {
        if ([undefined, null].indexOf(accountId) < 0) {
            return this.userRobot.enableSubaccount(accountId);
        } else {
            return q.reject(false);
        }
    };

    public disableSubaccount = (accountId: string) => {
        if ([undefined, null].indexOf(accountId) < 0) {
            return this.userRobot.disableSubaccount(accountId);
        } else {
            return q.reject(false);
        }
    };

    public ownBankAccountsFind = () => {
        return this.billingRobot.ownBankAccountsFind().then(this.returnResponseData);
    };

    public accountGetNotifications = () => {
        return this.userRobot.accountGetNotifications().then(this.returnResponses);
    };

    public accountAckNotification = (notificationId: string) => {
        return this.userRobot.accountAckNotification(notificationId);
    };

    private getCache = () => {
        return this.accountCache.get('accounts');
    };

    private putCache = (value: any) => {
        return this.accountCache.put('accounts', value);
    };

    private isExpired = (cachedAccount: any) => {
        if (cachedAccount.state === 'error') {
            return true;
        }

        const now = (new Date()).getTime();
        const age = now - cachedAccount.created;

        return (age > (1000 * 60 * 15));
    };

    private pushSuccess = (key: string, result: any) => {
        const tmp = this.getCache();

        if (result === undefined) {
            tmp[key] = {
                created: (new Date()).getTime(),
                data: result,
                status: 'ok'
            };

            this.putCache(tmp);

            return tmp[key].data;
        }

        if (
            [undefined, null].indexOf(result.errors) >= 0
            || !ng.isArray(result.errors)
            || result.errors.length === 0
        ) {
            tmp[key] = {
                created: (new Date()).getTime(),
                data: result,
                status: 'ok'
            };
        } else {
            tmp[key] = {
                created: (new Date()).getTime(),
                data: {},
                status: 'error'
            };
        }

        this.putCache(tmp);

        return tmp[key].data;
    };

    private pushFailure = (key: string) => {
        const tmp = this.getCache();

        tmp[key] = {
            created: (new Date()).getTime(),
            data: {},
            status: 'error'
        };

        this.putCache(tmp);

        return q.reject([]);
    };

    private idFilter = (id: string): Types.Finding.Filter => {
        return { field: 'accountId', value: id };
    };

    private returnResponse = (result: any) => {
        return result.response;
    };

    private returnResponses = (result: any) => {
        return result.responses;
    };

    private returnResponseData = (result: any) => {
        return result.response.data;
    };
}
