import * as ng from 'angular';

import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateDomainAvailabilityCheckController {
    public static $inject: string[] = ['$translate'];

    public faqArticleIdList: string[] = [
        'domain-owner-registration',
        'domain-confirm-email'
    ];

    public pageHeaderData: PanelHeaderData;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit = () => {
        const backwardRoute = '';
        this.pageHeaderData = {
            backwardLink: 'domain.dashboard',
            backwardText: this.$translate.instant('TR_110119-774118_TR'),
            panelHeaderRoute: backwardRoute,
            panelIcon: 'globe',
            panelTitle: this.$translate.instant('TR_100119-940a58_TR')
        };
    };
}

export class TemplateDomainAvailabilityCheckComponent implements ng.IComponentOptions {
    public bindings = {};
    public controller = TemplateDomainAvailabilityCheckController;
    public template = require('./availability-check-template.html');
}
