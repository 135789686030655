export const ApiErrors = {
    GENERAL: {
        ROBOT_IN_MAINTENANCE: 10001,
        ROBOT_UNAVAILABLE: 10002,
        SESSION_INVALID: 10101,
        SESSION_EXPIRED: 10102,
        API_KEY_INVALID: 10109
    },
    ACCOUNT: {
        TWO_FA_REQUIRED: 20312
    }
};
