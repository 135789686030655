import * as ng from 'angular';
import { DnsZoneModelService } from '../../../../../services';

export class WizardServiceHelperWebhosting {
    public static $inject: string[] = ['$translate', 'dnsZoneModel'];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private dnsZoneModel: DnsZoneModelService
    ) {}

    public getDefaultLocation = (vhost) => {
        if (vhost === undefined
            || vhost.locations === undefined
        ) {
            return;
        }
        const defaultLocation = vhost.locations.filter((location) => {
            return location.matchType === 'default';
        });

        return defaultLocation[0];
    };

    public vhostOfDomainRequired = (serviceObject, domain, vhost) => {
        // return values descrption
        // -1: do not create or update vHost
        // 0: create vHost
        // 1: update vHost - set enableAlias = true
        if (serviceObject === undefined
            || serviceObject.display === undefined
            || serviceObject.display.domainVhostStatus === undefined
            || serviceObject.settings === undefined
            || [undefined, null, ''].indexOf(serviceObject.settings.webspaceId) > 0
            || domain === undefined
            || domain.nameUnicode === undefined
            || [undefined, null, ''].indexOf(vhost.webspaceId) >= 0
        ) {
            return -1;
        }

        let enabledAlias = false;

        const aliasExists = serviceObject.display.domainVhostStatus.filter((vhostCached) => {
            if (vhostCached.domain === domain.nameUnicode && vhostCached.isset) {
                if (vhostCached.enableAlias !== undefined && vhostCached.enableAlias) {
                    enabledAlias = true;
                }

                return true;
            }

            return false;
        });

        if (aliasExists.length > 0) {
            if (serviceObject.children.length === 1) {
                return enabledAlias
                    ? -1     // vhost exists, alias is true
                    : 1;    // vhost exists, but alias is false
            }

            return -1;
        }

        return 0;
    };

    public getARecordsNameOfVhost = (vhost, domainName = '') => {
        let vhostName = '';
        if (domainName.length > 0) {
            vhostName = domainName;
        } else {
            vhostName = this._getVhostName(vhost);
        }
        let aRecordNames = [vhostName];
        if (vhost.enableAlias) {
            aRecordNames.push(['www'].indexOf(vhostName) === -1 ? 'www.' + vhostName : vhostName);
        }

        if ([undefined, null].indexOf(vhost.additionalDomainNames) === -1) {
            aRecordNames = aRecordNames.concat(vhost.additionalDomainNames);
        }

        return aRecordNames;
    };

    public updateARecordsOfNewVhost = (zone, webspace, vhost, records) => {
        const changAbleRecords = this.getRecordsToUpdateOfVhost(vhost, records, webspace);
        return this.dnsZoneModel.updateZone(zone, changAbleRecords.add, changAbleRecords.remove).then((res) => {
            this.$translate.instant('TR_080219-bf7eaf_TR');
            return res;
        });
    };

    public getRecordsToUpdateOfVhost = (vhost, records, webspace) => {
        const addRecords = [];
        const removeRecords = [];
        const recordsToUpdate = this.getARecordsNameOfVhost(vhost);
        const vhostName = this._getVhostName(vhost);

        recordsToUpdate.map((recordUpdate) => {
            let foundVVour = false;
            records.map((record) => {
                if (record.name === recordUpdate) {
                    if (record.type.toUpperCase() === 'A') {
                        foundVVour = true;
                        if (record.content !== webspace.serverIpv4) {
                            const tmpRecord = ng.copy(record);
                            tmpRecord.content = webspace.serverIpv4;
                            addRecords.push(tmpRecord);
                            removeRecords.push(record);
                        }
                    }

                    if (record.type.toUpperCase() === 'AAAA') {
                        if ([undefined, null, ''].indexOf(webspace.serverIpv6) >= 0) {
                            // no webserver ipv6 given - delete dns aaaa record
                            removeRecords.push(record);
                        } else if (record.content !== webspace.serverIpv6) {
                            // dns aaaa record has not same ipv6 as webserver, update record
                            const tmpRecord = ng.copy(record);
                            tmpRecord.content = webspace.serverIpv4;
                            addRecords.push(tmpRecord);
                            removeRecords.push(record);
                        }
                    }
                }
                return false;
            });

            if (!foundVVour) {
                // record is not set in dns zone - so create new record
                addRecords.push({
                    content: webspace.serverIpv4,
                    name: recordUpdate,
                    priority: 0,
                    ttl: 86400,
                    type: 'A'
                });
            }
        });

        return {
            add: addRecords,
            remove: removeRecords
        };
    };

    public addARecord = (zone, name, content) => {
        const add = [{
            content: content,
            name: name,
            priority: 0,
            ttl: 86400,
            type: 'A'
        }];

        return this.dnsZoneModel.updateZone(zone, add, []);
    };

    private _getVhostName = (vhost) => {
        return [undefined, null, ''].indexOf(vhost.domainName) === -1
            ? vhost.domainName
            : vhost.domainNameUnicode;
    };
}
