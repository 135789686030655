import ng from 'angular';
import { UiRights } from '@/configuration';
import {
    AccountModelService,
    AlertManagerService,
    AuthContextService,
    CountryListService,
    DomainHandleModelService,
    ErrorMapperService,
    NavigationService
} from '@/services';
import { AccountApi, DomainApi, ViewTypes } from '@/types';
import { EditPanelRight, IDomainContactNameList } from '@/atomic-components';

interface IEventHumanReadableEmail {
    markedForRemoval: boolean;
    value: string;
}
export class OrganismEditFormAccountSettingsController implements ng.IController {
    public static $inject: string[] = [
        '$translate',
        'accountModel',
        'alertManager',
        'authContext',
        'countryList',
        'domainHandleModel',
        'errorMapper',
        'navigation'
    ];

    public basicData: AccountApi.Account;
    public domainContactList: IDomainContactNameList[] = [];
    public eventDocumentFormat = '';
    public panelRightSettings: EditPanelRight;
    public readableEmail: boolean;
    public machineReadableEmail: boolean;
    public subaccountNotifications: boolean;
    public eventHumanReadableEmailAddresses: IEventHumanReadableEmail[];
    public eventMachineReadableEmailAddresses: IEventHumanReadableEmail[];
    public countryList: { [key: string]: string};
    public subAccountDomainHandles: ViewTypes.DomainContactObject[] = [];
    public domainHandleTypes: string[] = ['Owner', 'Admin', 'Tech', 'Zone'];
    public handleLabels: { contactType: string; label: string}[] = [
        { contactType: 'owner', label: /* translationId */ '79134e90-56a4-4816-bf30-f97c09642428' },
        { contactType: 'admin', label: /* translationId */ 'd18a253e-7318-4484-a57d-8c15dc660f56' },
        { contactType: 'tech', label: /* translationId */'3167f6f0-c06f-404d-a46a-0844f88bf408' },
        { contactType: 'zone', label: /* translationId */ '49cff6be-32f9-4167-b2f2-1fad856c2fb5' }
    ];

    private originalAccount: string;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService,
        private alertManager: AlertManagerService,
        private authContext: AuthContextService,
        private countryListService: CountryListService,
        private domainHandleModel: DomainHandleModelService,
        private errorMapper: ErrorMapperService,
        private navigation: NavigationService
    ) {}

    public $onInit = async (): Promise<void> => {
        this.originalAccount = JSON.stringify(this.basicData);
        this.countryList = this.countryListService.getRawList();
        this.setPanelRightSettings();
        this.resetNotificationSettings();
        await this.resetDomainSettings();
    };

    public resetNotificationSettings = (): void => {
        this.readableEmail = this.basicData.messageSettings.eventDeliveryMethods.indexOf('humanReadableEmail') >= 0;
        this.machineReadableEmail = this.basicData.messageSettings.eventDeliveryMethods.indexOf('machineReadableEmail') >= 0;
        this.eventDocumentFormat = this.basicData.messageSettings.eventDocumentFormat;
        this.subaccountNotifications = this.basicData.messageSettings.receiveSubaccountEvents;
        this.eventHumanReadableEmailAddresses = [];
        this.eventMachineReadableEmailAddresses = [];

        for (const address of this.basicData.messageSettings.eventHumanReadableEmailAddresses) {
            this.eventHumanReadableEmailAddresses.push({
                markedForRemoval: false,
                value: address
            });
        }

        for (const address of this.basicData.messageSettings.eventMachineReadableEmailAddresses) {
            this.eventMachineReadableEmailAddresses.push({
                markedForRemoval: false,
                value: address
            });
        }
    };

    public saveNotificationSettings = (): void => {
        const SettingsBackup = {
            eventDeliveryMethods: this.basicData.messageSettings.eventDeliveryMethods.slice(),
            eventHumanReadableEmailAddresses: this.basicData.messageSettings.eventHumanReadableEmailAddresses.slice(),
            eventMachineReadableEmailAddresses:
                this.basicData.messageSettings.eventMachineReadableEmailAddresses.slice(),
            subaccountNotifications: this.basicData.messageSettings.receiveSubaccountEvents
        };

        if (
            this.readableEmail
            && this.basicData.messageSettings.eventDeliveryMethods.indexOf('humanReadableEmail') < 0
        ) {
            this.basicData.messageSettings.eventDeliveryMethods.push('humanReadableEmail');
        } else if (
            !this.readableEmail
            && this.basicData.messageSettings.eventDeliveryMethods.indexOf('humanReadableEmail') >= 0
        ) {
            this.basicData.messageSettings.eventDeliveryMethods.splice(
                this.basicData.messageSettings.eventDeliveryMethods.indexOf('humanReadableEmail'),
                1
            );
        }

        if (this.readableEmail) {
            const newEventHumanReadableEmailAddresses = [];

            for (const item of this.eventHumanReadableEmailAddresses) {
                if (!item.markedForRemoval) {
                    newEventHumanReadableEmailAddresses.push(item.value);
                }
            }

            this.basicData.messageSettings.eventHumanReadableEmailAddresses = newEventHumanReadableEmailAddresses;
        }

        if (
            this.machineReadableEmail
            && this.basicData.messageSettings.eventDeliveryMethods.indexOf('machineReadableEmail') < 0
        ) {
            this.basicData.messageSettings.eventDeliveryMethods.push('machineReadableEmail');
        } else if (
            !this.machineReadableEmail
            && this.basicData.messageSettings.eventDeliveryMethods.indexOf('machineReadableEmail') >= 0
        ) {
            this.basicData.messageSettings.eventHumanReadableEmailAddresses = [];
            this.basicData.messageSettings.eventDocumentFormat = '';
            this.basicData.messageSettings.eventDeliveryMethods
                .splice(this.basicData.messageSettings.eventDeliveryMethods.indexOf('machineReadableEmail'), 1);
        }

        if (this.machineReadableEmail) {
            this.basicData.messageSettings.eventDocumentFormat = this.eventDocumentFormat;
            const newEventMachineReadableEmailAddresses = [];

            for (const item of this.eventMachineReadableEmailAddresses) {
                if (!item.markedForRemoval) {
                    newEventMachineReadableEmailAddresses.push(item.value);
                }
            }

            this.basicData.messageSettings.eventMachineReadableEmailAddresses = newEventMachineReadableEmailAddresses;
        }

        this.basicData.messageSettings.receiveSubaccountEvents = this.subaccountNotifications;
        this.accountModel.updateAccount(this.basicData).then(
            () => {
                this.alertManager.success(this.$translate.instant('TR_060219-0bf76a_TR'));
                this.resetNotificationSettings();
            },
            (reason) => {
                this.alertManager.error(this.errorMapper.map(reason));
                const msgSettings = this.basicData.messageSettings;
                msgSettings.eventDeliveryMethods = SettingsBackup.eventDeliveryMethods.slice();
                msgSettings.eventHumanReadableEmailAddresses = SettingsBackup.eventHumanReadableEmailAddresses.slice();
                msgSettings.receiveSubaccountEvents = SettingsBackup.subaccountNotifications;
                this.resetNotificationSettings();
            }
        );
    };

    public resetDomainSettings = async (): Promise<void> => {
        this.subAccountDomainHandles = [];
        const labelPrefix = `${this.$translate.instant('55e57c03-21be-437e-8fb6-9833641f9791')} `;

        this.domainHandleTypes.map(
            (handleType) => {
                const handleId = (this.basicData.domainSettings as unknown as Record<string, string>)[`defaultContact${handleType}Id`];
                this.subAccountDomainHandles.push({
                    contactType: handleType.toLocaleLowerCase() as DomainApi.DomainContactType,
                    contactId: handleId,
                    label: labelPrefix + this._getHandleLabelFromType(handleType.toLocaleLowerCase())
                });
            }
        );

        await this.reloadDomainContactList();
    };

    public resetDnsSettings = (): void => {
        this.basicData.dnsSettings = JSON.parse(this.originalAccount).dnsSettings;
    };

    public reloadDomainContactList = async (): Promise<void> => {
        await this.domainHandleModel.findHandle(
            [{ field: 'UsableForDomainInAccount', value: this.basicData.id }],
            100
        )
        .then(
            (response: DomainApi.Contact[]) => {
                this.domainContactList = response.map(
                    (contact) => ({
                        names: [
                            contact.handle,
                            contact.name,
                            this.getAddress(contact),
                            contact.organization
                        ],
                        value: contact.id
                    })
                );
            }
        );
    };

    public saveDomainContacts = (): void => {
        const defaultContactsBackup = {
            admin: this.basicData.domainSettings.defaultContactAdminId,
            owner: this.basicData.domainSettings.defaultContactOwnerId,
            tech: this.basicData.domainSettings.defaultContactTechId,
            zone: this.basicData.domainSettings.defaultContactZoneId
        };

        this.domainHandleTypes.map(
            (handleType) => {
                let handleTypeId;
                this.subAccountDomainHandles.some(
                    (handle) => {
                        if (handle.contactType.toLocaleLowerCase() === handleType.toLocaleLowerCase()) {
                            handleTypeId = handle.contactId;
                            return true;
                        }

                        return false;
                    }
                );
                (this.basicData.domainSettings as unknown as Record<string, string>)[`defaultContact${handleType}Id`] = handleTypeId;
            }
        );

        this.accountModel.updateAccount(this.basicData).then(
            () => {
                this.alertManager.success(this.$translate.instant('TR_060219-91de76_TR'));
                this.navigation.reloadCurrentState(true, true);
            },
            async (reason) => {
                this.alertManager.error(this.errorMapper.map(reason));
                this.basicData.domainSettings.defaultContactAdminId = defaultContactsBackup.admin;
                this.basicData.domainSettings.defaultContactOwnerId = defaultContactsBackup.owner;
                this.basicData.domainSettings.defaultContactTechId = defaultContactsBackup.tech;
                this.basicData.domainSettings.defaultContactZoneId = defaultContactsBackup.zone;
                await this.resetDomainSettings();
            }
        );
    };

    public setPanelRightSettings = (): void => {
        this.panelRightSettings = {
            editPanelButton: this.authContext.isGranted(UiRights.ACC_ACCOUNT_BASICDATA_EDIT)
        };
    };

    private getAddress = (contact: DomainApi.Contact): string => {
        const addressParts = [];

        if ([null, undefined].indexOf(contact.street) < 0) {
            addressParts.push(contact.street);
        }

        if ([null, undefined, ''].indexOf(contact.city) < 0) {
            addressParts.push(contact.city);
        }

        if ([null, undefined].indexOf(contact.country) < 0) {
            addressParts.push(this.countryList[contact.country.toUpperCase()]);
        }

        return addressParts.join(', ');
    };

    private _getHandleLabelFromType = (handleType: string): string => {
        let label: string;
        this.handleLabels.some(
            (handle) => {
                if (handle.contactType === handleType) {
                    label = this.$translate.instant(handle.label);
                    return true;
                }

                return false;
            }
        );

        return label;
    };
}

export class OrganismEditFormAccountSettingsComponent implements ng.IComponentOptions {
    public bindings = {
        basicData: '<'
    };

    public controller = OrganismEditFormAccountSettingsController;
    public template = require('./account-settings-edit.html');
}
