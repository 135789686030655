import * as ng from 'angular';
import {
    BundleModelService,
    DatabaseModelService,
    MachineModelService,
    MailboxModelService,
    SslCertificateModelService,
    WebspaceModelService
} from '../../../../../../services';
import * as Types from '../../../../../../types';
import { OverviewDataObject } from '../../../../../organisms/overview/overview';

import './payment-row.scss';

interface ListOfSubitems {
    object: Types.BillingApi.Payment;
    objectDisplayName: null | string;
    opened: boolean;
}

export class MoleculeOverviewRowPanelBillingPaymentController implements ng.IController {
    public static $inject: string[] = ['bundleModel', 'databaseModel', 'machineModel', 'mailboxModel', 'sslCertificateModel', 'webspaceModel'];

    public overviewData: OverviewDataObject;
    public overviewLoad: () => any;
    public internalPaginationLimit: number;

    private _openedSubItems: ListOfSubitems[] = [];

    constructor(
        private bundleModel: BundleModelService,
        private databaseModel: DatabaseModelService,
        private machineModel: MachineModelService,
        private mailboxModel: MailboxModelService,
        private sslCertificateModel: SslCertificateModelService,
        private webspaceModel: WebspaceModelService
    ) {}

    public toggleSubItems = (object: Types.BillingApi.Payment) => {
        const subItemInList = this._openedSubItems.some((item: ListOfSubitems) => {
            if (item.object.objectId === object.objectId) {
                item.opened = !item.opened;
                return true;
            }

            return false;
        });

        if (!subItemInList) {
            this._openedSubItems.push({ object: object, objectDisplayName: null, opened: true });
            switch (object.service.toLowerCase()) {
                case 'bundle':
                    this.bundleModel.findOne(object.objectId).then((res) => {
                        this._setObjectDisplayName(object, res.name);
                    });
                    break;
                case 'database':
                    this.databaseModel.findOneById(object.objectId).then((res) => {
                        this._setObjectDisplayName(object, res.name);
                    });
                    break;
                case 'email':
                    this.mailboxModel.findOne(object.objectId).then((res) => {
                        this._setObjectDisplayName(object, res.emailAddressUnicode);
                    });
                    break;
                case 'machine':
                    this.machineModel.findOneById(object.objectId).then((res) => {
                        this._setObjectDisplayName(object, res.name);
                    });
                    break;
                case 'ssl':
                    this.sslCertificateModel.findOne(object.objectId).then((res) => {
                        this._setObjectDisplayName(object, res.commonName);
                    });
                    break;
                case 'webhosting':
                    this.webspaceModel.findOne(object.objectId).then((res) => {
                        this._setObjectDisplayName(object, res.name);
                    });
                    break;
                default:
                    this._setObjectDisplayName(object, '-');
            }
        }
    };

    public isOpenedSubItenms = (object: Types.BillingApi.Payment) => {
        let subItem: ListOfSubitems;
        const subItemInList: boolean = this._openedSubItems.some((item: ListOfSubitems) => {
            if (item.object.objectId === object.objectId) {
                subItem = item;
                return true;
            }

            return false;
        });
        return subItemInList ? subItem.opened : false;
    };

    public getObjectDisplayName = (object: Types.BillingApi.Payment) => {
        let displayName: string;
        this._openedSubItems.some((item: ListOfSubitems) => {
            if (item.object.objectId === object.objectId && item.objectDisplayName !== null) {
                displayName = item.objectDisplayName;
                return true;
            }

            return false;
        });

        return displayName || null;
    };

    private _setObjectDisplayName = (object: Types.BillingApi.Payment, displayName: string) => {
        this._openedSubItems.some((item: ListOfSubitems) => {
            if (item.object.objectId === object.objectId) {
                item.objectDisplayName = displayName;
                return true;
            }

            return false;
        });

        // this._openedSubItems.push({ object: object, opened: true });
    };
}

export class MoleculeOverviewRowPanelBillingPaymentComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelBillingPaymentController;
    public controllerAs = 'OverviewPaymentCtrl';
    public template = require('./payment-row.html');
}
