import * as Sentry from '@sentry/browser';
import * as ng from 'angular';

import { StaffConst } from '../../../../../configuration';
import {
    AlertManagerService, AuthContextService, ValidateMinLength, ValidateNotEmpty
} from '../../../../../services';

import './support-modal.scss';

export class OrganViewPanelSupportModalController implements ng.IController {
    public static $inject: string[] = [
        '$http',
        '$translate',
        'alertManager'
    ];

    public companyName = 'hosting.de';
    public displayPanel: { promise: boolean; contact: boolean };
    public formSubmitDisabled = false;
    public contactFormData: any;
    public isIndividualProductRequest = false;
    public productGroup = '';
    public contactFormTypes: { name: string; value: string }[];
    public contactFormCategories: any;
    public commentValidationInstructions: any[] = [];

    protected staff: string = StaffConst.kahrs.name;

    private lastSelectedFormType = 'support';

    constructor(
        private $http: ng.IHttpService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService
    ) {
        this.companyName = [undefined, null].indexOf(AuthContextService.accountOrganizationInformation) === -1
            ? AuthContextService.accountOrganizationInformation.companyName
            : '';
    }

    public $onInit() {
        this.commentValidationInstructions = [
            {
                instructions: null,
                validator: new ValidateMinLength(this.$translate, 5)
            }, {
                instructions: null,
                validator: new ValidateNotEmpty(this.$translate)
            }
        ];

        this.contactFormData = {
            category: this.isIndividualProductRequest ? 'Technical' : null,
            comment: '',
            name: '',
            type: this.isIndividualProductRequest ? 'support' : 'feedback'
        };

        this.contactFormTypes = [
            {
                name: this.$translate.instant('TR_100119-3c921f_TR'),
                value: 'support'
            }, {
                name: this.$translate.instant('2e19b85f-d60b-4d98-98c3-725395f8a5ec'),
                value: 'feedback'
            }
        ];

        this.contactFormCategories = {
            feedback: [
                {
                    name: this.$translate.instant('TR_110119-d5d874_TR'),
                    value: 'Produkte'
                }, {
                    name: this.$translate.instant('CONTACT.FEEDBACK.CATEGORY.OPTIONS.USABILITY'),
                    value: 'Usability'
                }, {
                    name: this.$translate.instant('CONTACT.FEEDBACK.CATEGORY.OPTIONS.API'),
                    value: 'API'
                }, {
                    name: this.$translate.instant('CONTACT.FEEDBACK.CATEGORY.OPTIONS.DOCUMENTATION'),
                    value: 'Dokumentation'
                }, {
                    name: this.$translate.instant('CONTACT.FEEDBACK.CATEGORY.OPTIONS.OTHERS'),
                    value: 'Sonstiges'
                }
            ],
            support: [
                {
                    name: this.$translate.instant('TR_110119-912054_TR'),
                    value: 'Technical'
                }, {
                    name: this.$translate.instant('TR_110119-fe163a_TR'),
                    value: 'Billing'
                }
            ]
        };
    }

    public sendContactRequest = () => {
        const user = AuthContextService.user;
        const account = AuthContextService.account;
        const formData = {
            accountId: account.id,
            accountName: account.name,
            data: ng.copy(this.contactFormData),
            location: window.location.hostname,
            mail: user.emailAddress,
            name: user.name
        };

        if (this.isIndividualProductRequest && this.productGroup.length > 0) {
            // Dieser Text muss nicht übersetzt werden, da er nur für unseren Support gedacht ist ;)
            formData.data.comment = `Kundenanfrage zu einer individuellen "${this.productGroup}"-Lösung:\n`
                + formData.data.comment;
        }

        this.formSubmitDisabled = true;

        const promise = this.$http.post(
            '/utils/contact',
            formData,
            { headers: { 'Content-Type': 'application/json' } }
        );

        promise.then(
            () => {
                this.alertManager.success(this.$translate.instant('TR_110119-ceaca6_TR'));
                this.formSubmitDisabled = false;
                this.contactFormData = {
                    category: 'Technical',
                    comment: '',
                    name: '',
                    type: 'support'
                };

                // Close the panel.
                this.displayPanel.contact = false;
            },
            (result) => {
                this.alertManager.error(this.$translate.instant('TR_110119-eaea05_TR'));
                Sentry.captureMessage(
                    this.$translate.instant('TR_110119-15f3c9_TR'),
                    { tags: { key: 'feedback' }, extra: { requestData: formData, response: result } }
                );
                this.formSubmitDisabled = false;
            }
        );
    };

    public formTypeChanged = () => {
        if (this.lastSelectedFormType !== this.contactFormData.type) {
            this.contactFormData.category = this.contactFormCategories[this.contactFormData.type][0].value;
            this.lastSelectedFormType = this.contactFormData.type;
        }
    };
}

export class OrganViewPanelSupportModalComponent implements ng.IComponentOptions {
    public bindings = {
        displayPanel: '=',
        isIndividualProductRequest: '<?',
        productGroup: '<?'
    };
    public controller = OrganViewPanelSupportModalController;
    public template = require('./support-modal.html');
}
