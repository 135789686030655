import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateMachineJobDetailsController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;
    public job: any;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'machine.virtualmachines.jobs',
            backwardText: this.$translate.instant('TR_220219-3468ef_TR'),
            panelHeaderRoute: '',
            panelIcon: 'server',
            panelTitle: this.$translate.instant('TR_220219-a4e467_TR', { jobName: this.job.displayName })
        };
    };
}

export class TemplateMachineJobDetailsComponent implements ng.IComponentOptions {
    public bindings = {
        job: '<'
    };
    public template = require('./machine-job-details-template.html');
    public controller = TemplateMachineJobDetailsController;
}
