import ng from 'angular';
import { ILightboxImage } from '@/atomic-components/molecules';
import { ArticleSearchModelService, NavigationService } from '@/services';

interface ArticleImage {
    src: string;
    alt: string;
}

export class OrganismHelpdeskArticleController implements ng.IController {
    public static $inject: string[] = ['$stateParams', '$timeout', 'articleSearchModel', 'navigation'];

    public article: {};
    public currentCategory: string;
    public currentCategoryData: object = {};
    public pageHeaderData = '';
    public rootCategories: {};
    public rootCategoryPath: string;
    public helpdeskArticleImageList: ArticleImage[] = [];

    public currentIndex: number;

    constructor(
        private $stateParams: ng.ui.IUrlRouterService,
        private $timeout: ng.ITimeoutService,
        private articleSearchModel: ArticleSearchModelService,
        private navigation: NavigationService
    ) {}

    public $onInit() {
        const stateParts: string[] = []; // Helpdesk src
        let maxStateLevel = 0;
        for (let i = 1; i <= 6; i++) {
            if (this.$stateParams['level' + i] !== null) {
                stateParts.push(this.$stateParams['level' + i]);
                maxStateLevel = i;
            }
        }
        stateParts.pop();

        const currentCategoryPath = '/' + stateParts.join('/') + '/';
        stateParts.pop();

        const parentCategoryPath = stateParts.length === 0 ? '/' : ('/' + stateParts.join('/') + '/');
        this.articleSearchModel.getCurrentCategory(parentCategoryPath, currentCategoryPath).then(
            (res) => {
                this.currentCategoryData = res;
            }
        );
        this.rootCategoryPath = '/' + this.$stateParams.level1 + '/';
        this.article = this.articleSearchModel.getCurrentArticle();
        this.articleSearchModel.getRootCategories().then(
            (reply) => {
                this.rootCategories = reply.data;
                this.currentCategory = '/' + this.$stateParams.level1 + '/';
            }
        );

        this.$timeout(this._getImageObjects, 300);
    }

    public handleImageClick = (img: HTMLImageElement): void => {
        img.onclick = () => {
            this.$timeout(() => {
                this.currentIndex = JSON.parse(img.dataset.index);
            });
        };

        img.classList.add('cursor-pointer');
    };

    // Find <img>
    private _getImageObjects = (): void => {
        const articleImages = document.querySelectorAll('.helpdesk__article * img');
        // Select all <img>'s inside helpdesk articles

        articleImages.forEach((img: HTMLImageElement, index) => {
            const imageObject: ILightboxImage = {
                alt: img.alt,
                src: img.src
            };
            this.helpdeskArticleImageList.push(imageObject);

            img.dataset.index = JSON.stringify(index);
            this.handleImageClick(img);
        });
    };
}

export class OrganismHelpdeskArticleComponent implements ng.IComponentOptions {
    public bindings = {};
    public controller = OrganismHelpdeskArticleController;
    public template = require('./article.html');
}
