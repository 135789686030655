import * as ng from 'angular';

export class MoleculePanelInvoiceSumController implements ng.IController {
    public static $inject: string[] = [];

    public invoice;
}

export class MoleculePanelInvoiceSumComponent implements ng.IComponentOptions {
    public bindings = {
        invoice: '<'
    };
    public controller = MoleculePanelInvoiceSumController;
    public template = require('./panel-invoice-sum.html');
}
