import ng from 'angular';
import q from 'q';
import {
    UiRights,
    HandleTypes,
    HandleDefaultLabels,
    HandleMissingContactHintTexts
} from '@/configuration';
import {
    AccountModelService,
    AuthContextService,
    DomainContactValidatorService
} from '@/services';
import { AccountApi, DomainApi, ViewTypes, UI } from '@/types';

export class OrganCreateDomainContactsController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'accountModel',
        'authContext',
        'domainContactValidator'
    ];

    public subAccountDropdownItems: {name: string; value: string}[];
    public contactSetValidationErrors: DomainApi.ContactSetUsableProblem[] = [];
    public numberOfSelectedDomains: number;
    public metadata: ViewTypes.ProductConfigDomainDomainObject;
    public showAccountSelection = false;
    public modalOpen = false;
    public onChangeDeferred: ng.IDeferred<unknown>;
    public handleDefaultLabelsConst = HandleDefaultLabels;
    public handleTypes = HandleTypes.filter((type) => type.toLowerCase());
    public showAllOpen: boolean;

    private _initialLoading = true;
    private _validContacts: {[key: string]: string} = {
        admin: null,
        owner: null,
        tech: null,
        zone: null
    };
    private _hiddenContacts = {
        owner: false,
        admin: false,
        tech: false,
        zone: false
    };

    constructor(
        private $timeout: ng.ITimeoutService,
        public $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService,
        private authContext: AuthContextService,
        private domainContactValidator: DomainContactValidatorService
    ) {
        this.onChangeDeferred = q.defer();
    }

    public async $onInit(): Promise<void> {
        const account = this.authContext.account;
        this.subAccountDropdownItems = [{
            name: account.name,
            value: account.id
        }];

        if (this.authContext.isGranted(UiRights.ACC_SUBACCOUNT_LIST)) {
            await this.accountModel.listSubaccounts().then(
                (reply: UI.Response<AccountApi.Subaccount>) => {
                    this.showAccountSelection = (reply.data && reply.data.length > 0);
                    reply.data.map(
                        (subAccount) => {
                            this.subAccountDropdownItems.push({
                                name: subAccount.name,
                                value: subAccount.id
                            });
                        }
                    );
                }
            );
        }

        void this._checkContacts();
    }

    public $onChanges(changes: ng.IOnChangesObject): void  {
        if (changes._owner !== undefined && !changes._owner.isFirstChange()) {
            return void this.$timeout(() => {
                this._resetContacts();
                this._checkTransferOwnerHandling();
            });
        }
        if (changes.numberOfSelectedDomains) {
            void this.$timeout(() => {
                this._checkTransferOwnerHandling();
            });
        }
    }

    public get initialLoadingFinished(): boolean {
        return !this._initialLoading;
    }

    public getContact = (handleType: string): ViewTypes.DomainContactObject => {
        let defaultHandleLabelOfType: string;
        HandleDefaultLabels.some(
            (defaultHandleLabel) => {
                if (defaultHandleLabel.contactType === handleType.toLowerCase()) {
                    defaultHandleLabelOfType = this.$translate.instant(defaultHandleLabel.label);
                }
            }
        );
        let contactObject: ViewTypes.DomainContactObject = {
            contactType: handleType as DomainApi.DomainContactType,
            contactId: null,
            label: defaultHandleLabelOfType
        };

        this.metadata.contacts.some(
            (contact) => {
                if (contact.contactType.toLocaleLowerCase() === handleType.toLocaleLowerCase()) {
                    contactObject = contact;
                }
            }
        );

        return contactObject;
    };

    public get complete(): boolean {
        return this.contactSetValidationErrors.length === 0
            && Object.values(this.metadata.contacts).every(
                (contact) =>
                    !([undefined, null, '', '0'].includes(contact.contactId))
                    || !([undefined, null, ''].includes(this._validContacts[contact.contactType])));
    }

    public set complete(_) {/* */}

    public issetHandle = (handleType: string): boolean => {
        return this.metadata.contacts.some(
            (handle) => {
                return handle.contactType === handleType.toLocaleLowerCase();
            }
        );
    };

    public validateContactSet = async (): Promise<void> => {
        const notAllHandlesAreSet = this.metadata.contacts.some(
            (handle) => [undefined, null, '', '0'].includes(handle.contactId)
        );

        if (notAllHandlesAreSet) {
            this.contactSetValidationErrors = [];
            return;
        }

        const contacts = this.metadata.contacts
            .map(
                (handle) => ({
                    contact: handle.contactId,
                    type: handle.contactType
                })
            );

        const tlds = [];

        for (const domain of this.selectedDomains) {
            tlds.push(domain.tld);
        }

        await this.domainContactValidator.contactSetUsableFor(
            contacts,
            tlds,
            false,
            this.metadata.account.id
        ).then(
            (problems: DomainApi.ContactSetUsableProblem[]) => {
                void this.$timeout(() => {
                    this.contactSetValidationErrors = problems;
                    for (const handle of this.metadata.contacts) {
                        const handleWithProblem = problems.some(
                            (problem) => {
                                if (problem.text.toLowerCase().indexOf(handle.contactType) >= 0) {
                                    this._validContacts[handle.contactType] = null;
                                    return true;
                                }

                                return false;
                            }
                        );

                        if (!handleWithProblem) {
                            this._validContacts[handle.contactType] = handle.contactId;
                        }
                    }
                });
            }
        );
    };

    public getErrorDomainText = (error: DomainApi.ContactSetUsableProblem): string => {
        return error.domainSuffix
            // Make suffixes unique
            .filter((tld, index) => error.domainSuffix.indexOf(tld) === index)
            .map((tld) => `.${tld}`)
            .join(', ');
    };

    public get showManualContactsCheckbox(): boolean {
        const domainSettings = this.authContext.account.domainSettings;
        return domainSettings.defaultContactOwnerId !== ''
            && domainSettings.defaultContactAdminId !== ''
            && domainSettings.defaultContactTechId !== ''
            && domainSettings.defaultContactZoneId !== '';
    }

    public set selectedDomains(_) {/* */}
    public get selectedDomains(): ViewTypes.WizardSelectedDomainObject[] {
        if (!this.metadata?.selectedDomains) {
            return [];
        }

        return this.metadata.selectedDomains;
    }

    public setShowAllOpen = (): void => {
        if (this.metadata.contacts?.length === 1) {
            this.showAllOpen = true;
            return;
        }

        this.showAllOpen = this.metadata.contacts.some(
            (handle, index: number) => {
                if (index === 0) {
                    return false;
                }

                return [undefined, null, '', '0'].includes(handle.contactId)
                    || [undefined, null, '', '0'].includes(this._validContacts[handle.contactType]);
            }
        );
    };

    public getDefaultHandleLabel = (handleType: string): string => {
        let label = '';
        HandleDefaultLabels.some(
            (handle) => {
                if (handle.contactType.toLocaleLowerCase() === handleType.toLocaleLowerCase()) {
                    label = this.$translate.instant(handle.label);

                    return true;
                }

                return false;
            }
        );

        return label;
    };

    public getDefaultHandleMissingHintText = (handleType: string): string => {
        let hintText = 'm';
        HandleMissingContactHintTexts.some(
            (contact) => {
                if (contact.contactType.toLocaleLowerCase() === handleType.toLocaleLowerCase()) {
                    hintText = this.$translate.instant(contact.label);

                    return true;
                }

                return false;
            }
        );

        return hintText;
    };

    private _checkContacts = async (): Promise<void> => {
        const issetOneContact = this.metadata.contacts.some(
            (contact) => {
                return ![undefined, null, '', '0'].includes(contact.contactId);
            }
        );

        if (issetOneContact) {
            await this.validateContactSet();
        } else {
            this.contactSetValidationErrors = [];
        }
        this.setShowAllOpen();
        this._initialLoading = false;
    };

    private _checkTransferOwnerHandling = (): void => {
        this._hiddenContacts.owner = this.metadata.selectedDomains.every(
            (domain) =>
                domain.domainObject.domain.transferOwnerHandling === 'sync'
        );

        this._checkHiddenContacts();
        this._checkMissingContacts();
    };

    private _checkHiddenContacts = (): void => {
        Object.entries(this._hiddenContacts).find(([key, hidden]) => {
            if (hidden) {
                this.metadata.contacts = this.metadata.contacts.filter(
                    (contact) => !(contact.contactType.toLocaleLowerCase() === key.toLocaleLowerCase())
                );
            }
        });
    };

    private _checkMissingContacts = (): void => {
        HandleDefaultLabels.forEach(
            (handleDefault, handleIndex) => {
                const isContactHidden = this._hiddenContacts[handleDefault.contactType.toLowerCase()];
                const isContactAvailable = this.metadata.contacts.some(
                    (handle) => handle.contactType === handleDefault.contactType.toLowerCase()
                );
                if (!isContactHidden && !isContactAvailable) {
                    this.metadata.contacts.splice(+handleIndex, 0, {
                        contactType: handleDefault.contactType.toLowerCase() as DomainApi.DomainContactType,
                        contactId: this.metadata.account.domainSettings[this._getDefaultContactName(handleDefault.contactType.toLowerCase())] || '',
                        label: this.$translate.instant(handleDefault.label)
                    });
                }
            }
        );
    };

    private _resetContacts = (): void => {
        this._setDefaultContacts();
        void this._checkContacts();
    };

    private _setDefaultContacts = (): void => {
        for (const handle of this.metadata.contacts) {
            handle.contactId = this.metadata.account.domainSettings[this._getDefaultContactName(handle.contactType)];
        }
    };

    private _getDefaultContactName = (contactType: string): string => {
        return `defaultContact${contactType.charAt(0).toUpperCase() + contactType.slice(1)}Id`;
    };
}

export class OrganCreateDomainContactsComponent implements ng.IComponentOptions {
    public bindings = {
        _owner: '<owner',
        complete: '=',
        modalOpen: '=?',
        numberOfSelectedDomains: '<',
        metadata: '='
    };

    public controller =  OrganCreateDomainContactsController;
    public template = require('./domain-contacts-config.html');
}
