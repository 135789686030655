import * as ng from 'angular';

export class MachinePower {
    public static $inject: string[] = ['$translate'];

    private static jobPower = {
        off: 'VM.POWER.OFF',
        on: 'VM.POWER.ON'
    };

    public static Factory($translate: ng.translate.ITranslateService) {
        return (value: string | number) => {
            if ([undefined, null, ''].indexOf(MachinePower.jobPower[value]) >= 0) {
                return value;
            }
            return $translate.instant(MachinePower.jobPower[value]);
        };
    }

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

}
