import { HandleConst } from '../configuration';
import { DomainHandleModelService } from '../services';

export class ContactName {
    public static $inject: string[] = ['$translate', 'domainHandleModel'];
    private static cache = {};

    public static Factory(
        $translate: ng.translate.ITranslateService,
        domainHandleModel: DomainHandleModelService
    ) {
        const contactNameFilter: any = (handleId: string) => {
            if ([undefined, null, ''].indexOf(handleId) >= 0) {
                return undefined;
            }
            if (!ContactName.cache[handleId]) {
                ContactName.cache[handleId] = $translate.instant('TR_140119-51e11e_TR') + '...';
                domainHandleModel.findOne(handleId)
                .then(
                    (handle) => {
                        let handleName: string;
                        if (['org', 'role'].indexOf(handle.type) >= 0) {
                            handleName = handle.organization
                            + ' (' + $translate.instant(HandleConst.HandleTypes.labels[handle.type]) + ')';
                        } else {
                            handleName = handle.name
                            + ' (' + $translate.instant(HandleConst.HandleTypes.labels[handle.type]) + ')';

                            if (handle.organization.length > 0) {
                                handleName += ' | ' + handle.organization;
                            }
                        }
                        ContactName.cache[handleId] = handleName;
                    }
                );
            }
            return ContactName.cache[handleId];
        };

        contactNameFilter.$stateful = true;

        return contactNameFilter;

    }
}

export class ContactNameAdditionalInfo {
    public static $inject: string[] = ['$translate', 'domainHandleModel'];
    private static cache = {};

    public static Factory(
        $translate: ng.translate.ITranslateService,
        domainHandleModel: DomainHandleModelService
    ) {
        const contactNameAdditionalInfoFilter: any = (handleId: string) => {
            if ([undefined, null, ''].indexOf(handleId) >= 0) {
                return undefined;
            }

            if (!ContactNameAdditionalInfo.cache[handleId]) {
                ContactNameAdditionalInfo.cache[handleId] = 'loading...';
                domainHandleModel.findOne(handleId)
                .then(
                    (handle) => {
                        const handleType = $translate.instant(HandleConst.HandleTypes.labels[handle.type]);
                        ContactNameAdditionalInfo.cache[handleId] =
                            `${handle.street[0]},
                                ${handle.postalCode} ${handle.city}
                                | ${$translate.instant('TR_150419-49d45c_TR')}: ${handleType}
                                | ${$translate.instant('dcca5e1c-3bdc-4090-a588-240f81a0e7f8')}: ${handle.handle}`;
                    }
                );
            }
            return ContactNameAdditionalInfo.cache[handleId];
        };

        contactNameAdditionalInfoFilter.$stateful = true;

        return contactNameAdditionalInfoFilter;
    }
}
