import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { UserSettingsManagerService } from '../../../services';

export class TemplateEmailOverviewController {
    public static $inject: string[] = ['$state', '$stateParams', '$translate', 'userSettingsManager'];

    public pageHeaderData: PanelHeaderData;
    public bundle;
    public overviewServiceData;
    public expertView: boolean;
    public faqArticleIdList: string[] = [
        'email-mailbox-create',
        'email-outgoing-debug',
        'email-mailbox-storage-full'
    ];

    public overviewModel;
    private simpleFilter;
    private extendedFilters;
    private overviewUiSettings;

    constructor(
        private $state: ng.ui.IStateService,
        private $stateParams: ng.ui.IStateParamsService,
        private $translate: ng.translate.ITranslateService,
        private userSettingsManager: UserSettingsManagerService
    ) {}

    public $onInit() {
        const bundle = this.bundle || null;
        const stateInBundle = this.$state.current.name.split('.')[0] === 'bundle';
        const stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';
        const stateOrganizationFilteredList = this.$state.params
            && this.$state.params.fancyFilters
            && [undefined, null, []].indexOf(this.$state.params.fancyFilters) < 0
            && (
                (
                    this.$state.params.fancyFilters[0].field
                    && this.$state.params.fancyFilters[0].field === 'OrganizationId'
                )
                ||                (
                    [undefined, null, []].indexOf(this.$state.params.fancyFilters.subFilter) < 0
                    && this.$state.params.fancyFilters[0].subFilter.length > 0
                    && this.$state.params.fancyFilters[0].subFilter.some(
                        (filter) => filter.field === 'OrganizationId'
                    )
                )
            );

        if ([undefined, null].indexOf(this.simpleFilter) === -1) {
            this.expertView = this.overviewUiSettings.expertView || this.simpleFilter.show;
        } else {
            this.expertView = this.overviewUiSettings.expertView || false;
        }

        let panelHeaderBackwardLink;
        let panelHeaderBackwardText;
        let panelHeaderRouteParams;
        let panelHeaderTitleText = this.$translate.instant('TR_110119-c1856d_TR');

        switch (true) {
            case stateInBundle:
                panelHeaderBackwardLink = 'bundle.id.dashboard';
                panelHeaderBackwardText = this.$translate.instant('TR_090119-a32485_TR');
                panelHeaderRouteParams = { bundleId: this.bundle.id };
                break;
            case stateInMachine:
                panelHeaderBackwardLink = 'managed-servers.id.dashboard';
                panelHeaderBackwardText = this.$translate.instant('TR_220319-1ac2e3_TR');
                panelHeaderRouteParams = { machineId: this.$state.$current.locals.globals.machine.id };
                break;
            case stateOrganizationFilteredList:
                let organizationIdFilter;
                if (
                    this.$state.params.fancyFilters[0].field
                ) {
                    organizationIdFilter = this.$state.params.fancyFilters[0];
                } else {
                    organizationIdFilter = this.$state.params.fancyFilters[0].subFilter.filter(
                        (filter) => filter.field === 'OrganizationId'
                    )[0];
                }
                panelHeaderBackwardLink = 'email.organizations.id.edit';
                panelHeaderBackwardText = this.$translate.instant('TR_100120-aedc62_TR');
                panelHeaderRouteParams = { organizationId: organizationIdFilter.value };
                panelHeaderTitleText = this.$translate.instant('TR_100120-26252a_TR');
                break;
            default:
                panelHeaderBackwardLink = 'email.dashboard';
                panelHeaderBackwardText = this.$translate.instant('TR_110119-c8ec44_TR');
                panelHeaderRouteParams = null;
        }

        this.pageHeaderData = {
            backwardLink: panelHeaderBackwardLink,
            backwardText: panelHeaderBackwardText,
            panelHeaderRoute: '',
            panelHeaderRouteParams: panelHeaderRouteParams,
            panelIcon: 'envelope',
            panelTitle: panelHeaderTitleText
        };

        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter
        };

        // Surpress backlink to dashboard on expert view because it auto-redirects to overview.
        this.userSettingsManager.getUiSettings().then(
            (result) => {
                if (result?.overviewSettings?.EmailOverviewCtrl?.expertView) {
                    this.pageHeaderData.backwardText = '';
                    this.pageHeaderData.backwardLink = '';
                    this.pageHeaderData.panelHeaderRouteParams = null;
                }
            }
        );
    }

    public expandWrapper = (toggle) => {
        this.expertView = toggle.expand;
    };
}

export class TemplateEmailOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<',
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public controller = TemplateEmailOverviewController;
    public controllerAs = 'TemplateEmailOverviewCtrl';
    public template = require('./mailbox-overview-template.html');
}
