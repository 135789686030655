
export type PageHeaderSettingsReturnValue = {
    backwardLink: string;
    backwardText: string;
    panelHeaderRoute: string;
};

export const pageHeaderSettings = (
    name: string,
    $translate: ng.translate.ITranslateService
): PageHeaderSettingsReturnValue => {

    let backwardLink = '';
    let backwardText = '';
    let panelHeaderRoute = '';
    const profilePrefix = 'profile';
    if (name === `${profilePrefix}.apikeys.new`) {
        backwardLink = `${profilePrefix}`;
        backwardText = $translate.instant('TR_080119-104cba_TR');
        panelHeaderRoute = `${profilePrefix}.apikeys.new`;
    }
    if (name === `${profilePrefix}.apikeys.id.edit`) {
        backwardLink = `${profilePrefix}`;
        backwardText = $translate.instant('TR_080119-104cba_TR');
        panelHeaderRoute = `${profilePrefix}.apikeys.id.edit`;
    }
    const resellerPrefix = 'reseller.subaccounts.id.users.id';
    if (name === `${resellerPrefix}.apikeys.new`) {
        backwardLink = `${resellerPrefix}.edit`;
        backwardText = $translate.instant('TR_080119-104cba_TR');
        panelHeaderRoute = `${resellerPrefix}.apikeys.new`;
    }
    if (name === `${resellerPrefix}.apikeys.id.edit`) {
        backwardLink = `${resellerPrefix}.edit`;
        backwardText = $translate.instant('TR_080119-da12bd_TR');
        panelHeaderRoute = `${resellerPrefix}.apikeys.id.edit`;
    }
    return {
        backwardLink: backwardLink,
        backwardText: backwardText,
        panelHeaderRoute: panelHeaderRoute
    };
};
