import ng from 'angular';

import q from 'q';
import { FormDropDownItems } from '@/atomic-components/molecules';
import {
    AlertManagerService,
    ApiErrorModel,
    NavigationService,
    VhostModelService,
    WebspaceModelService
} from '@/services';
import * as Types from '@/types';

export class OrganismNewEditFormCronjobController {
    public static $inject: string[] = [
        '$state',
        '$translate',
        'alertManager',
        'apiErrorModel',
        'navigation',
        'vhostModel',
        'webspaceModel'
    ];

    public originalCronjob: Types.WebhostingApi.CronJob;
    public cronjob: Types.WebhostingApi.CronJob;
    public userPanelRight: any;
    public create = false;
    public deletionConfirmed = false;
    public interpreterVersion: string;
    public interpreterVersions: { [key: string]: FormDropDownItems[] } = { type: [] };
    private _cronjobId: string | number;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private navigation: NavigationService,
        private vhostModel: VhostModelService,
        private webspaceModel: WebspaceModelService
    ) {
        void this.vhostModel.availablePhpVersions().then(
            (res) => {
                res.forEach((phpVersion: Types.WebhostingApi.PhpVersion) => {
                    const interpreterVersionObject = {
                        name: [
                            'PHP Version',
                            phpVersion.version,
                            (phpVersion.isDefault ? '(Default)' : '')
                        ].join(' '),
                        value: phpVersion.version
                    };
                    if ((phpVersion.isDefault === true && !this.interpreterVersion)
                        || this.originalCronjob.interpreterVersion === phpVersion.version) {
                        this.interpreterVersion = interpreterVersionObject.value;
                    }
                    this.interpreterVersions.type.unshift(interpreterVersionObject);
                });
            }
        );
    }

    public $onInit = (): void => {
        this.cronjob = ng.copy(this.originalCronjob);
        this._setCronjobId();
        this.cronjob.parameters = this._formatParameters(this.cronjob.parameters);
    };

    public hasWeeklySchedule= (schedule?: string): boolean => {
        if (schedule){
            return schedule === 'weekly';
        }
        return this.cronjob.schedule === 'weekly';
    };
    public hasMonthlySchedule= (schedule?: string): boolean => {
        if (schedule){
            return schedule === 'monthly';
        }
        return this.cronjob.schedule === 'monthly';
    };
    public hasDailySchedule= (schedule?: string): boolean => {
        if (schedule){
            return schedule === 'daily';
        }
        return this.cronjob.schedule === 'daily';
    };

    public hasHourlySchedule= (schedule?: string): boolean => {
        if (schedule){
            return schedule.endsWith('hour');
        }
        return this.cronjob.schedule.endsWith('hour');
    };
    public hasMinutelySchedule= (schedule?: string): boolean => {
        if (schedule){
            return schedule.endsWith('min');
        }
        return this.cronjob.schedule.endsWith('min');
    };

    public hasCustomDaypart= (): boolean => {
        return this.cronjob.daypart === '';
    };

    public DayPartNeedsNoMinutesOrHours = (): boolean => {
        return this.cronjob.schedule.endsWith('min');
    };

    public changeSchedule(data: any): void{
        if (!(
            this.hasWeeklySchedule(data)
            || this.hasDailySchedule(data)
            || this.hasMonthlySchedule(data)
            || this.DayPartNeedsNoMinutesOrHours())){
            // set sentinel values for daypart, hour and minute
            this.cronjob.daypart = '';
            this.cronjob.hour = 24;
            this.cronjob.minute = 60;
        } else if (this.hasHourlySchedule()){
            // set sentinel value for hour and default value for minute
            this.cronjob.hour = 24;
            this.cronjob.minute = 0;
        }
    }

    public errorType = () => {
        return this.create ? 'create' : 'update';
    };

    public get webspace() {
        return this.$state.$current.locals.globals.webspace;
    }

    public get modifiedWebspace() {
        const webspace = ng.copy(this.webspace);

        if (this.cronjob.type.toLowerCase().indexOf('php') >= 0
            && [undefined, null].indexOf(this.interpreterVersion) < 0) {
            this.cronjob.interpreterVersion = this.interpreterVersion;
        }

        webspace.cronJobs[this._cronjobId] = ng.copy(this.cronjob);

        if (webspace.cronJobs[this._cronjobId].type === 'url') {
            webspace.cronJobs[this._cronjobId].parameters = [];
        } else {
            webspace.cronJobs[this._cronjobId].parameters = this.cronjob.parameters.map( (param) => {
                return (param as any).parameter;
            });
        }

        return webspace;
    }

    public cancelDeletion = () => {
        this.deletionConfirmed = false;
    };

    public deleteCronjob = () => {
        const defer = q.defer();
        const webspace = ng.copy(this.webspace);
        webspace.cronJobs.splice(this._cronjobId, 1);

        this.webspaceModel.update(webspace)
            .then(
                (res) => {
                    if (!this.apiErrorModel.apiResponseHasError(res)) {
                        this.alertManager.success(this.$translate.instant('TR_180119-3145bf_TR'));
                        defer.reject(false);

                        void this.navigation.go(
                            'webhosting.webspaces.id.edit',
                            { webspaceId: this.webspace.id },
                            { reload: true }
                        );
                    } else {
                        defer.reject(false);
                    }
                },
                (err) => {
                    defer.reject(err);
                }
            );

        return defer.promise;
    };

    public save = () => {
        const successMessage = this.create
            ? this.$translate.instant('TR_100119-9ec73a_TR')
            : this.$translate.instant('TR_100119-95c70e_TR');

        return this.webspaceModel.update(this.modifiedWebspace)
            .then(
                (res) => {
                    if (!this.apiErrorModel.apiResponseHasError(res)) {
                        this.alertManager.success(successMessage);

                        if ([undefined, null, ''].indexOf(this.webspace.bundleId) === -1) {
                            void this.navigation.go(
                                'bundle.id.cronjobs.overview',
                                { bundleId: this.webspace.bundleId },
                                { reload: true }
                            );
                        } else {
                            void this.navigation.go(
                                'webhosting.webspaces.id.cronjobs.overview',
                                { webspaceId: this.webspace.id },
                                { reload: true }
                            );
                        }
                    }

                    return q.reject(false);
                },
                (err) => q.reject(err)
            );
    };

    public cancelGeneral = () => {
        this.cronjob.comments = ng.copy(this.originalCronjob.comments);
        this.cronjob.script = ng.copy(this.originalCronjob.script);
        this.cronjob.url = ng.copy(this.originalCronjob.url);
        this.cronjob.parameters = this._formatParameters(ng.copy(this.originalCronjob.parameters));
    };

    public cancelParameters = () => {
        this.cronjob.parameters = ng.copy(this.originalCronjob.parameters);
    };

    public cancelSchedule = () => {
        (
            [
                'schedule',
                'dayOfMonth',
                'daypart',
                'weekday'
            ] as string[]
        )
            .map(
                (key: string) => this.cronjob[key] = this.originalCronjob[key]
            );
    };

    private _setCronjobId = () => {
        this._cronjobId = this.$state.$current.locals.globals.cronjobId
            ? this.$state.$current.locals.globals.cronjobId
            : this.webspace.cronJobs.length;
    };

    private _formatParameters = (parameters) => {
        if (parameters === undefined) {
            return [];
        }
        return parameters.map((target: string) => {
            return {parameter: target, tmpStatus: 'registered', newStatus: ''};
        });
    };
}

export class OrganismNewEditFormCronjobComponent implements ng.IComponentOptions {
    public bindings = {
        create: '<?',
        originalCronjob: '<cronjob',
        userPanelRight: '='
    };

    public controllerAs = '$editFormOrganism';
    public controller = OrganismNewEditFormCronjobController;
    public template = require('./cronjob-new-edit.html');
}
