import * as ng from 'angular';
import { ProductAction } from '../../../organisms/page-header-panel/page-header-panel';
import './menu-product-action.scss';

export class MoleculeMenuProductActionController implements ng.IController {
    public static $inject: string[] = [];

    public items: ProductAction[];
}

export class MoleculeMenuProductActionComponent implements ng.IComponentOptions {
    public bindings = {
        items: '<'
    };
    public controller =  MoleculeMenuProductActionController;
    public template = require('./menu-product-action.html');
}
