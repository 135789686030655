import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import { AuthContextService } from '../../../../../services';
import * as Types from '../../../../../types';

export class OrganCreateConfigurationAccessesDatabaseWebspaceController implements ng.IController {
    public static $inject: string[] = ['authContext'];

    public databaseObject: Types.OrchestrationApi.Database;
    public webspaceObject: Types.OrchestrationApi.Webspace;
    public accessPassword: string | null = null;

    private _projectName: string | null;
    private _hasDatabaseUserCreateRight = false;
    private _hasWebspaceUserCreateRight = false;

    constructor(
        private authContext: AuthContextService
    ) {}

    public $onInit() {
        this._hasDatabaseUserCreateRight = this.authContext.isGranted(UiRights.DB_USER_CREATE);
        this._hasWebspaceUserCreateRight = this.authContext.isGranted(UiRights.WEB_USER_CREATE);
        this._setAccessObjects();
    }

    public $onChanges(changes: any) {
        if (changes._projectName !== undefined) {
            if (this._hasDatabaseUserCreateRight) {
                this.databaseObject.userName = changes._projectName.currentValue;
            }
            if (this._hasWebspaceUserCreateRight) {
                this.webspaceObject.userName = changes._projectName.currentValue;
            }
        }
    }

    public get showAccessPanel() {
        return this.authContext.isGrantedAny([UiRights.DB_USER_CREATE, UiRights.WEB_USER_CREATE]);
    }

    public get completed() {
        if (!this.showAccessPanel) {
            return true;
        }

        return [undefined, null, ''].indexOf(this.accessPassword) < 0;
    }

    public set completed({}) {} // tslint:disable-line:no-empty

    public passwordChanged = () => {
        if ([undefined, null, ''].indexOf(this.accessPassword) >= 0) {
            this._setAccessObjects(true);
            return;
        }
        if (this.databaseObject !== null) {
            this.databaseObject.password = this.accessPassword;
        }
        if (this.webspaceObject !== null) {
            this.webspaceObject.password = this.accessPassword;
        }
    };

    public getAccessCreateTextType = () => {
        switch (true) {
            case this._hasDatabaseUserCreateRight && !this._hasWebspaceUserCreateRight:
                return 'database';
            case this._hasWebspaceUserCreateRight && !this._hasDatabaseUserCreateRight:
                return 'webspace';
            default:
                return 'all';
        }
    };

    private _setAccessObjects = (reset?: boolean) => {
        if (reset) {
            this.databaseObject.password = '';
            this.webspaceObject.password = '';
            return;
        }
        if (this._hasDatabaseUserCreateRight) {
            this.databaseObject.password = this.accessPassword;
        }

        if (this._hasWebspaceUserCreateRight) {
            this.webspaceObject.password = this.accessPassword;
        }
    };
}

export class OrganCreateConfigurationAccessesDatabaseWebspaceComponent implements ng.IComponentOptions {
    public bindings = {
        _projectName: '<projectName',
        completed: '=',
        databaseObject: '=',
        webspaceObject: '='
    };

    public template = require('./database-webspace-access-config.html');
    public controller =  OrganCreateConfigurationAccessesDatabaseWebspaceController;
}
