import { ModelHelper } from '../model-helper';
import { DnsRobotService } from './robot';

import * as ng from 'angular';
import * as Types from '../../types';
import { DnsApi } from '../../types';

export class DdnsModelService {
    public static $inject: string[] = ['$q', 'dnsRobot'];

    constructor(
        private $q: ng.IQService,
        private dnsRobot: DnsRobotService
    ) {}

    public listHostnames = (
        limit: number,
        page: number,
        filters: Types.Finding.Filter,
        sort: Types.Finding.SortOptions
    ) => {
        sort = sort || {
            field: 'dynamicDnsHostnameHostname',
            order: 'ASC'
        };
        page = page || 1;

        return this.dnsRobot.listDdnsHostnames(filters, limit, page, sort)
            .then(ModelHelper.returnListResponse);
    };

    public listCredentials = (
        limit: number,
        page: number,
        filters: Types.Finding.Filter,
        sort: Types.Finding.SortOptions
    ) => {
        sort = sort || {
            field: 'dynamicDnsCredentialsName',
            order: 'ASC'
        };
        page = page || 1;

        return this.dnsRobot.listDdnsCredentials(filters, limit, page, sort)
            .then(ModelHelper.returnListResponse);
    };

    public credentialsListForAccount = (accountId: string) => {
        return this.dnsRobot.listDdnsCredentialsWithoutPagination(
            { field: 'accountId', value: accountId },
            null,
            null,
            { field: 'dynamicDnsCredentialsName', order: 'ASC'}
            )
            .then((result) => {
                return result.response.data;
            });
    };

    public getCredentialsById = (credentialsId: string) => {
        return this.dnsRobot.listDdnsCredentialsWithoutPagination(
            { field: 'dynamicDnsCredentialsId', value: credentialsId }
            )
            .then((result) => {
                if (result.response.data.length >= 1) {
                    return result.response.data[0];
                }
                return {};
            });
    };

    public getHostnameById = (hostnameId: string) => {
        return this.dnsRobot.listDdnsHostnames(
                { field: 'dynamicDnsHostnameId', value: hostnameId }
            )
            .then((result) => {
                if (result.response.data.length >= 1) {
                    return result.response.data[0];
                }
                return {};
            });
    };

    public createDdnsUser = (newUser: Types.DnsApi.DynamicDnsCredentials, password: string) => {
        return this.dnsRobot.createDdnsCredentials(newUser, password);
    };

    public deleteDdnsUser = (credentialsId: string) => {
        return this.dnsRobot.deleteDdnsCredentials(credentialsId);
    };

    public updateDdnsUser = (user: Types.DnsApi.DynamicDnsCredentials, password: string) => {
        return this.dnsRobot.updateDdnsCredentials(user, password);
    };

    public createDdnsHost = (
        credentialsId: string,
        hostname: string,
        comment: string,
        productCode: string,
        accountId: string
    ) => {
        return this.dnsRobot.dynamicDnsHostnameCreate(credentialsId, hostname, comment, productCode, accountId);
    };

    public updateDdnsHost = (hostname: DnsApi.DynamicDnsHostname) => {
        return this.dnsRobot.dynamicDnsHostnameUpdate(hostname);
    };

    public deleteDdnsHost = (hostnameId: string, execDate) => {
        return this.dnsRobot.dynamicDnsHostnameDelete(hostnameId, execDate);
    };

    public restoreDdnsHost = (hostnameId: string) => {
        return this.dnsRobot.restoreDdnsHost(hostnameId);
    };

    public purgeRestorableDdnsHost = (hostnameId: string) => {
        return this.dnsRobot.purgeRestorableDdnsHost(hostnameId);
    };

    public ddnsHostCancelDeletion = (hostnameId: string) => {
        return this.dnsRobot.dynamicDnsHostnameCancelDeletion(hostnameId);
    };

    public getExistingDdnsNames = async () => {
        const listDdnsHostnames =  await this.dnsRobot.listDdnsHostnames();
        return (listDdnsHostnames.response.data || []).map((ddns) => {
            return ([null, undefined, ''].indexOf(ddns.hostnameUnicode) < 0 ? ddns.hostnameUnicode : ddns.hostname);
        });
    };
}
