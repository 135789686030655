import * as ng from 'angular';

import { DomainContactPanelMode } from '../../../atomic-components/molecules';
import { PageHeaderData } from '../../../atomic-components/organisms';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';

export class TemplateDomainContactsEditController {
    public static $inject: string[] = ['$translate', 'authContext'];

    public contact: any;
    public contactIsExtended: boolean;
    public initalEditMode = false;
    public userPanelRight;
    public pageHeaderData: PageHeaderData;
    public panelMode: DomainContactPanelMode = DomainContactPanelMode.EDIT;
    public faqArticleIdList: string[] = [
        'domain-handle-definition',
        'domain-owner-registration'
    ];

    private _mode: string;
    private parentId = '';

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit() {
        let headerTitleString = '';
        if (this._mode !== undefined && this._mode === 'copy') {
            this.panelMode = DomainContactPanelMode.ADD;
            this.contact.id = null;
        }

        if (this.contact.name === '') {
            headerTitleString = this.contact.organization + '(' + this.contact.handle + ')';
        } else {
            headerTitleString = this.contact.name + '(' + this.contact.handle + ')';
            if (this.contact.organization !== '') {
                headerTitleString += ' ' + this.contact.organization;
            }
        }

        this.pageHeaderData = {
            backwardLink: 'domain.contacts.overview',
            backwardText: this.$translate.instant('TR_110119-9adcd4_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: {},
            panelIcon: 'globe',
            panelTitle: this.isEditMode
            ? this.$translate.instant('TR_280119-c0f32a_TR', { titleString: headerTitleString }, '', '', null)
            : this.$translate.instant('DOMAIN.HANDLE.COPY.TITLE', { handle: headerTitleString}, '', '', null),
            productActions: []
        };

        this.pageHeaderData.productActions = [
            {
                route: 'domain.contacts.id.domains.overview',
                title: this.$translate.instant('TR_110119-ed9917_TR')
            },
            {
                route: 'domain.contacts.id.copy',
                title: this.$translate.instant('DOMAIN.DIRECTIVES.HANDLE-CHOOSER.BUTTONS.COPY.TITLE')
            }
        ];

        this.userPanelRight = {
            editPanelButton: !this.contactIsExtended && this.authContext.isGranted(UiRights.DOM_CONTACTS_EDIT)
        };

    }

    public get isEditMode() {
        return this.panelMode === DomainContactPanelMode.EDIT;
    }

    public get isCopyMode() {
        return this.panelMode === DomainContactPanelMode.ADD;
    }
}

export class TemplateDomainContactsEditComponent implements ng.IComponentOptions {
    public bindings = {
        _mode: '<mode',
        contact: '<',
        contactIsExtended: '<'
    };
    public template = require('./domain-contacts-edit-template.html');
    public controller = TemplateDomainContactsEditController;
    public controllerAs = '$TemplateDomainEditCtrl';
}
