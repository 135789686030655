import * as ng from 'angular';
import { UiRights } from '../../../../configuration';
import { AccountModelService, AuthContextService, CleanableStringType, StringCleaner } from '../../../../services';
import { WizardObject } from '../../../molecules/wizard/wizard-supervisor/wizard-supervisor';

export class OrganWizardDnsController implements ng.IController {
    public static $inject: string[] = ['accountModel', 'authContext'];

    public serviceObject: WizardObject;
    public showAccountSelection = false;
    public extended = false;

    private _initializedStringCleanerInstance;

    constructor(
        accountModel: AccountModelService,
        authContext: AuthContextService
    ) {
        if (authContext.isGranted(UiRights.ACC_SUBACCOUNT_LIST)) {
            accountModel.listSubaccountsWithoutPagination(1).then(
                (reply) => this.showAccountSelection = (reply.data && reply.data.length > 0)
            );
        }
    }

    public convertDomainName = () => {
        this.serviceObject.settings.domainname = StringCleaner
        .clean(this.serviceObject.settings.domainname)
        .as(CleanableStringType.FQDNs);
    };
}

export class OrganWizardDnsComponent implements ng.IComponentOptions {
    public bindings = {
        serviceObject: '='
    };

    public template = require('./wizard-dns.html');
    public controller =  OrganWizardDnsController;
}
