import * as ng from 'angular';
import * as q from 'q';

import { UiRights } from '@/configuration';
import { AuthContextService, DateTimeWrapper } from '@/services';
import { AlertManagerService } from '../../../../../services/alert-manager';
import { ManagedApplicationRobotService } from '../../../../../services/managed-application';
import { NavigationService } from '../../../../../services/navigation';
import * as Types from '../../../../../types';
import { EditPanelStatus } from '../../../../molecules';

export class OrganismEditFormNextcloudAdminAccessController {
    public static $inject: string[] = [
        '$state',
        '$timeout',
        '$translate',
        'alertManager',
        'managedApplicationRobot',
        'navigation'
    ];

    public adminDurationOptionList = [1, 3, 5, 8, 12, 24].map(
        (v) => ({ value: v, name: `${v}` })
    );
    public adminImpersonate = false;
    public adminPassword: string;
    public adminUsername: string;
    public currentlySendingRequest = false;
    public enableAdminAccess = true;
    public nextcloud: Types.ManagedApplicationApi.Nextcloud;
    public initialStatus: EditPanelStatus = EditPanelStatus.ACTIVE;
    public showAdminLogin = false;
    public showDurationNotInAllowedRangeWarning = false;
    public userCanImpersonate = false;
    public validUntil: DateTimeWrapper;

    private _adminDuration: number|null = 3;
    private _adminEmail: string|boolean = false;

    constructor(
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private managedApplicationRobot: ManagedApplicationRobotService,
        private navigation: NavigationService
    ) {
        this.userCanImpersonate = AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_SUPER_USER_EDIT);
    }

    public get adminDuration(): string|number {
        return this._adminDuration;
    }

    public set adminDuration(value: string|number) {
        const intValue = parseInt('' + value, 10);
        this.showDurationNotInAllowedRangeWarning = false;
        this._adminDuration = value === null ? null : intValue;

        // user input sanity checks
        if (isNaN(intValue) && value !== null) {
            this.showDurationNotInAllowedRangeWarning = true;
            this._adminDuration = 1;
        }

        if (this._adminDuration !== null) {
            if (this._adminDuration > 24) {
                this.showDurationNotInAllowedRangeWarning = true;
                this._adminDuration = 24;
            }

            if (this._adminDuration <= 0) {
                this.showDurationNotInAllowedRangeWarning = true;
                this._adminDuration = 1;
            }
        }

        if (this.showDurationNotInAllowedRangeWarning) {
            this.adminDurationOptionList = this.adminDurationOptionList.filter(
                (dropdownElement) => dropdownElement.value !== intValue
            );
        }
    }

    public get adminEmail(): string | boolean {
        return this._adminEmail === false ? '' : this._adminEmail;
    }

    public set adminEmail(value: string | boolean) {
        this._adminEmail = ('' + value).trim() === '' ? false : value;
    }

    public get showCustomUrl() {
        return this.nextcloud.individualDomainNameStatus === 'active';
    }

    public save = () => {
        this.currentlySendingRequest = true;
        this.adminUsername = '';
        this.adminPassword = '';
        this.validUntil = undefined;
        this.showAdminLogin = false;

        if (this.enableAdminAccess) {
            this.managedApplicationRobot.nextcloudAdminAccessEnable(
                this.nextcloud,
                this._adminDuration * 3600,
                this.adminImpersonate,
                this._adminEmail
            ).then(
                (res) => {
                    this.currentlySendingRequest = false;
                    this.adminUsername = res.response.username;
                    this.adminPassword = res.response.password;
                    this.validUntil = res.response.expiryDate;
                    this.showAdminLogin = true;
                    this.alertManager.success(this.$translate.instant('TR_070720-525209_TR'));
                },
                (error) => {
                    this.currentlySendingRequest = false;
                    this.alertManager.error(this.$translate.instant('TR_070720-562a85_TR'));
                }
            );
        } else { // disable admin access
            this.managedApplicationRobot.nextcloudAdminAccessDisable(this.nextcloud).then(
                (res) => {
                    this.currentlySendingRequest = false;
                    this.alertManager.success(this.$translate.instant('TR_070720-2f6c5d_TR'));
                    this.navigation.go(
                        this.$state.current.name.replace('admin-access', 'edit'),
                        null,
                        { reload: true }
                    );
                },
                (error) => {
                    this.currentlySendingRequest = false;
                    this.alertManager.error(this.$translate.instant('TR_070720-562a85_TR'));
                }
            );
        }
    };

    public get disableSaveButton() {
        return this._adminDuration === null
            || this.currentlySendingRequest;
    }
}

export class OrganismEditFormNextcloudAdminAccessComponent implements ng.IComponentOptions {
    public bindings = {
        nextcloud: '<'
    };
    public controller = OrganismEditFormNextcloudAdminAccessController;
    public template = require('./nextcloud-admin-access.html');
}
