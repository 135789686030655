import * as ng from 'angular';

import { BundleModelService } from '../services';

class BundleCache {
    private static cache: any[] = [];
    private static requests: string[] = [];

    public static bust: () => void
        = () => {
            BundleCache.cache = [];
            BundleCache.requests = [];
        };

    public static has: (bundleId: string) => boolean
        = (bundleId) => BundleCache.cache.some((bundle) => bundle.id === bundleId);

    public static requested: (bundleId: string) => boolean
        = (bundleId) => BundleCache.requests.indexOf(bundleId) >= 0;

    public static request: (bundleId: string) => void
        = (bundleId) => BundleCache.requested(bundleId) ? undefined : BundleCache.requests.push(bundleId);

    public static get: (bundleId: string) => { [key: string]: any } | undefined
        = (bundleId) => BundleCache.has(bundleId)
            ? BundleCache.cache.filter((entry) => entry.id === bundleId)[0]
            : undefined;

    public static add: (bundle: any) => void
        = (bundle) => {
            if (!BundleCache.has(bundle.id)) {
                BundleCache.cache.push(bundle);
                BundleCache.requests = BundleCache.requests.filter((bundleId) => bundleId !== bundle.id);
            }
        };
}

export class BundleName {
    public static $inject: string[] = ['bundleModel', '$rootScope', '$translate'];

    public static Factory(
        bundleModel: BundleModelService,
        $rootScope: ng.IRootScopeService|any,
        $translate: ng.translate.ITranslateService
    ) {
        $rootScope.$on('login', () => BundleCache.bust);
        $rootScope.$on('logout', () => BundleCache.bust);

        const bundleNameFilter: any = (bundleId: string) => {
            if ([undefined, null, ''].indexOf(bundleId) >= 0) {
                return $translate.instant('TR_230119-0ec6d6_TR');
            }

            if (BundleCache.has(bundleId)) {
                return BundleCache.get(bundleId).name.replace(new RegExp('["\']', 'g'), '');
            }

            if (BundleCache.requested(bundleId)) {
                return;
            }

            BundleCache.request(bundleId);

            bundleModel.findOne(bundleId)
                .then((bundle) => BundleCache.add(bundle));

            return;
        };

        bundleNameFilter.$stateful = true;

        return bundleNameFilter;
    }
}
