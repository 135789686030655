import ng from 'angular';

import { BundleApi } from '@/types';
import { PageHeaderData } from '@/atomic-components';
import { UiRights } from '@/configuration';
import {
    ApiObject,
    AuthContextService,
    BundleModelService,
    DomainModelService,
    SwitchUserManagerService,
    VhostModelService
} from '@/services';

export class TemplateBundleDashboardController {
    public static $inject: string[] = [
        '$state',
        '$translate',
        'authContext',
        'bundleModel',
        'dateFilter',
        'domainModel',
        'switchUserManager',
        'vhostModel'
    ];

    public leftPanelArea: PageHeaderData[] = [];
    public modifier = '';
    public pageHeaderData: PageHeaderData;
    public bundle: BundleApi.Bundle;
    public showObjectId: boolean;
    public bundleHasUnfinishedJob = false;

    public faqArticleIdList: string[] = [
        'webspace-ftp-access',
        'domain-register-new',
        'ssl-activate'
    ];

    private domainPanelForDeletedBundle = {
        includeShowAllLink: false,
        panelIcon: 'globe',
        panelList: [this.$translate.instant('TR_090119-b41503_TR')],
        panelTitle: this.$translate.instant('1cf7e2bf-fdc4-4262-9a4b-b6be74541b29'),
        panelType: 'domain'
    };

    private webspace: BundleApi.Bundle;
    private addonDomains;
    private _multiPartTlds: string[] = [];

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private bundleModel: BundleModelService,
        private dateFilter,
        private domainModel: DomainModelService,
        private switchUserManager: SwitchUserManagerService,
        private vhostModel: VhostModelService
    ) {}

    public $onInit = () => {
        if ([undefined, null].indexOf(this.bundle) >= 0) {
            return;
        }

        const bundleHandler = ApiObject.bundle(this.bundle);

        if (bundleHandler.hasWebspaceContingent) {
            // Get all vHosts in this Bundle
            this.vhostModel.list(1000, 1, { field: 'WebspaceId', value: this.webspace.id })
            .then(
                (result) => {
                    // Get domain names, not subdomains
                    const domainNames = result.data.map((vHost) => vHost.domainNameUnicode)
                    .map(
                        (domainName) => {
                            const domainParts: string[] = domainName.split('.');

                            while (
                                domainParts.length > 2
                                && this._multiPartTlds.indexOf('.' + domainParts.slice(1).join('.')) < 0
                            ) {
                                domainParts.splice(0, 1);
                            }

                            return domainParts.join('.');
                        }
                    )
                    .filter((name, index, names) => names.indexOf(name) === index);

                    if (domainNames.length === 0) {
                        return [];
                    }

                    // Fetch associated domains
                    return this.domainModel.findByName(domainNames);
                }
            )
            .then(
                (result) => {
                    // Addon domains are registered, but not part of this bundle
                    const addonDomains = result.filter(
                        (domain) => domain.bundleId !== this.bundle.id
                    );

                    if (addonDomains.length > 0) {
                        this.addonDomains = addonDomains.length;
                    } else {
                        this.addonDomains = undefined;
                    }
                }
            );
        }

        // Define Page Header Data
        this.pageHeaderData = {
            backwardLink: 'bundle.dashboard',
            backwardText: this.$translate.instant('TR_090119-ec1393_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: 'box-alt',
            panelTitle: this.bundle.name,
            productActions: [
                {
                    route: 'bundle.id.rename',
                    title: this.$translate.instant('TR_090119-12d626_TR')
                },
                {
                    route: 'bundle.id.contract-details',
                    title: this.$translate.instant('TR_130219-d4e51d_TR')
                }
            ],
            reloadState: true
        };

        if (bundleHandler.domainContingentCount > 0) {
            this.pageHeaderData.productActions.push(
                {
                    route: 'bundle.id.add-domain',
                    title: this.$translate.instant('a5a5763c-7678-4abf-b645-f0d19c9db6bb')
                });
        }

        if (this.ownedBySubaccount) {
            this.pageHeaderData.productActions.push(
                {
                    actionFunction: this.loginAsSubaccount,
                    title: this.$translate.instant('TR_090119-c96c3d_TR')
                }
            );
        }

        if (bundleHandler.hasWebspaceContingent) {
            this.vhostModel.list(100, 1, { field: 'WebspaceId', value: this.webspace.id })
            .then(
                (result) => this.domainPanelForDeletedBundle.panelList =
                result.data.length === 0
                ? [this.$translate.instant('TR_090119-f0c5fb_TR')]
                : result.data.map((vHost) => vHost.domainNameUnicode)
            );

            if (this.authContext.isGrantedAny(
                [UiRights.WEB_ADMIN_EMAIL_SENDER, UiRights.WEB_ADMIN_WEBSPACE_ENABLE_DISABLE])
            ) {
                const adminOptionRoute = this.$state.current.name.split('.')[0]
                    ? 'bundle.id.admin-options'
                    : 'webhosting.webspaces.id.admin-options';
                const adminOptionRouteParams = this.$state.current.name.split('.')[0]
                    ? { webspaceId: this.webspace.id, bundleId: this.$state.$current.locals.globals.bundle.id}
                    : { webspaceId: this.webspace.id };

                this.pageHeaderData.productActions.push({
                    route: adminOptionRoute,
                    stateParams: adminOptionRouteParams,
                    title: this.$translate.instant('TR_090119-b3d894_TR')
                });
            }
        }

        if (this.pageHeaderData.productActions.length < 1) {
            this.pageHeaderData.productActions = undefined;
        }

        this.leftPanelArea = [];

        if (bundleHandler.hasEmailContingent) {
            this.leftPanelArea.push({
                includeShowAllLink: true,
                panelHeaderRoute: 'bundle.id.mailboxes.overview',
                panelIcon: 'envelope',
                panelItems: [
                    {
                        route: 'bundle.id.mailboxes.new',
                        text: this.$translate.instant('TR_090119-7a60e9_TR')
                    }
                ],
                panelTitle: this.$translate.instant('TR_080119-8dd4a1_TR'),
                panelType: 'email-imap-mailbox',
                utilityStatus:  {
                    infoText: this.$translate.instant('EMAIL.MAILBOXES'),
                    isStorage: false,
                    showAbsolute: true
                }
            });
        }

        if (bundleHandler.hasDomainContingent) {
            const domainPanel = {
                includeShowAllLink: true,
                panelHeaderRoute: 'bundle.id.domains.overview',
                panelIcon: 'globe',
                panelItems: [
                    {
                        route: 'bundle.id.domains.new',
                        text: this.$translate.instant('TR_090119-fec2dd_TR')
                    },
                    {
                        route: 'bundle.id.domains.transfer-out',
                        text: this.$translate.instant('TR_090119-86f4b3_TR')
                    }
                ] as any[],
                panelTitle: this.$translate.instant('TR_140119-aeb48a_TR'),
                panelType: 'domain',
                utilityStatus: {
                    infoText: this.$translate.instant('TR_090119-9ca7f3_TR'),
                    isStorage: false,
                    showAbsolute: true
                }
            };

            if (bundleHandler.hasWebspaceContingent) {
                domainPanel.panelItems.push({
                    route: 'bundle.id.domains.overview',
                    text: this.$translate.instant('TR_090119-a9f116_TR')
                });
                domainPanel.panelItems.push({
                    route: 'bundle.id.domains.overview',
                    text: this.$translate.instant('TR_090119-552567_TR')
                });
                domainPanel.panelItems.push({
                    route: 'bundle.id.domains.new',
                    routeParams: {domainType: 'subdomain'},
                    text: this.$translate.instant('TR_090119-719dc6_TR')
                });
                domainPanel.panelItems.push({
                    route: 'bundle.id.domains.new',
                    routeParams: {domainType: 'external'},
                    text: this.$translate.instant('TR_090119-91145a_TR')
                });
                domainPanel.panelItems.push({
                    route: 'bundle.id.domains.overview',
                    text: this.$translate.instant('WEBHOSTING.VHOST.ACTION.INSTALL')
                });
            }

            this.leftPanelArea.push(domainPanel);
        }

        if (bundleHandler.hasWebspaceContingent) {
            this.leftPanelArea.push({
                includeShowAllLink: false,
                includeShowDetailsLink: true,
                panelHeaderRoute: 'bundle.id.webspaces.id.storage',
                panelIcon: 'save',
                panelItems: [
                    {
                        route: 'bundle.id.webspaces.id.storage',
                        text: this.$translate.instant('TR_090119-cd1f87_TR')
                    }
                ],
                panelTitle: this.$translate.instant('TR_STORAGE_TR'),
                panelType: 'webhosting-bundle-webspace-storage',
                utilityStatus: {
                    infoText: this.$translate.instant('TR_090119-2fb308_TR'),
                    isStorage: true,
                    showAbsolute: false
                }
            });

            // tslint:disable-next-line:cyclomatic-complexity
            this.leftPanelArea.push({
                includeShowAllLink: false,
                includeShowDetailsLink: false,
                panelHeaderRoute: 'bundle.id.webspaces.id.php-boost',
                panelIcon: 'battery-bolt',
                panelItems: [
                    {
                        route: 'bundle.id.webspaces.id.php-boost',
                        routeParams: { webspaceId: this.webspace.id },
                        text: this.$translate.instant('TR_090123-2a0169_TR')
                    },
                    {
                        route: 'bundle.id.webspaces.id.php-boost',
                        routeParams: { webspaceId: this.webspace.id },
                        text: this.$translate.instant('TR_070619-026a9c_TR')
                    },
                    {
                        route: 'bundle.id.webspaces.id.php-boost',
                        routeParams: { webspaceId: this.webspace.id },
                        text: this.$translate.instant('TR_070619-a0117f_TR')
                    }
                ],
                panelTitle: this.$translate.instant('TR_070619-458016_TR'),
                panelType: 'php-boost'
            });

            this.leftPanelArea.push({
                includeShowAllLink: true,
                panelHeaderRoute: 'bundle.id.webspaces.ftp-ssh',
                panelIcon: 'folder',
                panelItems: [
                    {
                        route: 'bundle.id.webspaces.ftp-ssh',
                        text: this.$translate.instant('879a34f5-b968-4abe-a9fb-f602abfed914')
                    }
                ],
                panelTitle: this.$translate.instant('TR_080119-3313e9_TR'),
                panelType: 'webhosting-user',
                utilityStatus:  {
                    infoText: this.$translate.instant('TR_090119-7c1c03_TR'),
                    isStorage: false,
                    showAbsolute: true
                }
            });
        }

        if (bundleHandler.hasDatabaseContingent) {
            this.leftPanelArea.push({
                includeShowAllLink: true,
                panelHeaderRoute: 'bundle.id.databases.overview',
                panelIcon: 'database',
                panelItems: [
                    {
                        route: 'bundle.id.databases.new',
                        text: this.$translate.instant('TR_090119-a6b125_TR')
                    },
                    {
                        text: this.$translate.instant('TR_090119-c3165d_TR')
                    }
                ],
                panelTitle: this.$translate.instant('TR_090119-f0ccfb_TR'),
                panelType: 'database',
                utilityStatus: {
                    infoText: this.$translate.instant('TR_090119-1201bd_TR'),
                    isStorage: false,
                    showAbsolute: true
                }
            });
        }

        if (bundleHandler.hasWebspaceContingent) {
            const backupPanel = {
                includeShowAllLink: true,
                panelHeaderRoute: 'bundle.id.backups.overview',
                panelIcon: 'hdd',
                panelItems: [
                    {
                        route: 'bundle.id.backups.overview',
                        text: this.$translate.instant('TR_090119-348a07_TR')
                    },
                    {
                        route: 'bundle.id.backups.overview',
                        text: this.$translate.instant('TR_090119-15ffd3_TR')
                    }
                ],
                panelTitle: this.$translate.instant('TR_080119-57a2c9_TR'),
                panelType: 'backups-bundle',
                utilityStatus: null
            };

            if (bundleHandler.hasDatabaseContingent) {
                backupPanel.panelItems.push({
                    route: 'bundle.id.databases.overview',
                    text: this.$translate.instant('TR_090119-8ed8d6_TR')
                });
            }

            if (this.authContext.isGranted(UiRights.BACKUP_BACKUP_LIST)) {
                this.leftPanelArea.push(backupPanel);
            }

            this.leftPanelArea.push({
                includeShowAllLink: true,
                panelHeaderRoute: 'bundle.id.cronjobs.overview',
                panelIcon: 'calendar-alt',
                panelItems: [
                    {
                        route: 'bundle.id.cronjobs.new',
                        text: this.$translate.instant('TR_090119-f8a120_TR')
                    }
                ],
                panelTitle: this.$translate.instant('STATE.WEBHOSTING.WEBSPACE.CRON.PAGE-TITLE'),
                panelType: 'cronjobs',
                utilityStatus: {
                    infoText: this.$translate.instant('TR_020519-8e7ba5_TR'),
                    isStorage: false,
                    showAbsolute: true
                }
            });

            if (this.webspace.restrictions !== undefined) {
                this.webspace.restrictions.forEach(
                    (restriction) => {
                        let message = this.$translate.instant('TR_090119-d5c2e6_TR');

                        switch (restriction.type) {
                            case 'disabled':
                                if (this.authContext.isGranted(UiRights.WEB_ADMIN_WEBSPACE_ENABLE_DISABLE)) {
                                    message = this.$translate.instant('TR_100119-e937e9_TR')
                                    + `: ${restriction.internalComment}`
                                    + ` (${this.dateFilter(restriction.addDate, 'dd.MM.yyyy')})`;
                                }

                                this.leftPanelArea.unshift({
                                    callout: {
                                        message: this.$translate.instant('TR_090119-bac87f_TR', { message: message }),
                                        type: 'warning'
                                    },
                                    includeShowAllLink: false,
                                    panelItems: [],
                                    panelTitle: this.$translate.instant('TR_090119-e69976_TR'),
                                    panelType: 'disabled'
                                });
                                break;
                            case 'mailSendingDisabled':
                                if (this.authContext.isGranted(UiRights.WEB_ADMIN_EMAIL_SENDER)) {
                                    message = this.$translate.instant('TR_100119-e937e9_TR')
                                    + `: ${restriction.internalComment}`
                                    + ` (${this.dateFilter(restriction.addDate, 'dd.MM.yyyy')})`;
                                }

                                this.leftPanelArea.unshift({
                                    callout: {
                                        message: this.$translate.instant('TR_090119-196ecc_TR', { message: message }),
                                        type: 'warning'
                                    },
                                    includeShowAllLink: false,
                                    panelItems: [],
                                    panelTitle: this.$translate.instant('TR_090119-9d517a_TR'),
                                    panelType: 'disabled'
                                });
                                break;
                            case 'mailSendingDeferred':
                                if (this.authContext.isGranted(UiRights.WEB_ADMIN_EMAIL_SENDER)) {
                                    message = this.$translate.instant('TR_100119-e937e9_TR')
                                    + `: ${restriction.internalComment}`
                                    + ` (${this.dateFilter(restriction.addDate, 'dd.MM.yyyy')})`;
                                }

                                this.leftPanelArea.unshift({
                                    callout: {
                                        message: this.$translate.instant('TR_090119-ed29ae_TR', { message: message }),
                                        type: 'warning'
                                    },
                                    includeShowAllLink: false,
                                    panelItems: [],
                                    panelTitle: this.$translate.instant('TR_140119-950f2d_TR'),
                                    panelType: 'disabled'
                                });
                                break;
                            default:
                                break;
                        }
                    }
                );
            }
        }

        this.leftPanelArea = this.getPanelData(
            ng.copy(this.leftPanelArea).map(
                (view) => {
                    view.panelTitle = this.$translate.instant(view.panelTitle);

                    if (
                        [undefined, null].indexOf(view.utilityStatus) < 0
                        && [undefined, null, ''].indexOf(view.utilityStatus.infoText) < 0
                    ) {
                        view.utilityStatus.infoText = this.$translate.instant(view.utilityStatus.infoText);
                    }

                    if ([undefined, null].indexOf(view.panelItems) < 0) {
                        view.panelItems = view.panelItems.map(
                            (item) => {
                                item.text = this.$translate.instant(item.text);

                                return item;
                            }
                        );
                    }

                    return view;
                }
            )
        );

        if (AuthContextService.isGranted(UiRights.BIL_LIST_JOBS)) {
            this.bundleModel.findRunningJobsForBundle(this.bundle)
            .then(
                (result) => {
                    if (result.status === 'success' && result.response.data.length > 0) {
                        this.bundleHasUnfinishedJob = true;
                    }
                }
            );
        }
    };

    public loginAsSubaccount = () => {
        this.switchUserManager.switchTo(this.bundle.accountId);
    };

    public domainNameHandlingOnDeletion = (panelItem) => {
        if (!panelItem) {
            return false;
        }

        return panelItem.panelType === 'bundle-cancel-delete'
            && this.bundle.domainNameHandlingOnDeletion !== undefined
            && Array.isArray(this.bundle.domainNameHandlingOnDeletion)
            && this.bundle.domainNameHandlingOnDeletion.length > 0;
    };

    private getPanelData(panels) {
        panels = this.setContingentUsages(panels);

        if (this.bundle.status === 'restorable') {
            panels = [{
                includeShowAllLink: false,
                panelHeaderRoute: 'bundle.id.restore',
                panelIcon: 'recycle',
                panelItems: [
                    {
                        route: 'bundle.id.restore',
                        text: this.$translate.instant('BUNDLE.RESTORE.TITLE')
                    }
                ],
                panelTitle: this.$translate.instant('TR_140119-d1b7a4_TR'),
                panelType: 'bundle-delete',
                utilityStatus: null
            }];
        } else {
            if ([undefined, null, ''].indexOf(this.bundle.deletionScheduledFor) < 0) {
                const deletionDate = this.dateFilter(this.bundle.deletionScheduledFor, 'dd.MM.yyyy');
                panels.unshift({
                    callout: {
                        calloutDisplayTitle: false,
                        message: this.$translate.instant('TR_090119-ce8c9c_TR', { deletionDate: deletionDate }),
                        type: 'information'
                    },
                    includeShowAllLink: false,
                    panelHeaderRoute: 'bundle.id.cancel-delete',
                    panelItems: [
                        {
                            route: 'bundle.id.cancel-delete',
                            text: this.$translate.instant('TR_090119-a8d192_TR')
                        }
                    ],
                    panelTitle: this.$translate.instant('TR_090119-431b23_TR'),
                    panelType: 'bundle-cancel-delete',
                    utilityStatus: null
                });
            } else if (this.authContext.isGranted(UiRights.BIL_BUNDLE_DELETE)) {
                panels.push({
                    includeShowAllLink: false,
                    panelHeaderRoute: 'bundle.id.delete',
                    panelItems: [
                        {
                            route: 'bundle.id.delete',
                            text: this.$translate.instant('TR_090119-4aa7dc_TR')
                        }
                    ],
                    panelTitle: this.$translate.instant('GENERAL.ACTION.TERMINATE'),
                    panelType: 'bundle-delete',
                    utilityStatus: null
                });
            }
        }

        return this.setBundleServiceOverviewRoute(panels);
    }

    private getContingentUsage(view) {
        return this.bundle.effectiveContingentUsage.filter(
            (usage) => usage.productCodes.some((code) => code.indexOf(view.panelType) >= 0)
        );
    }

    private sumCapacity(usage: BundleApi.BundleContingentUsage[]) {
        return usage.reduce((prev, curr) => {
            return {
                capacity:           prev.capacity + curr.capacity,
                capacityUsed:       prev.capacityUsed + curr.capacityUsed,
                usedCapacity:       prev.usedCapacity + curr.usedCapacity,
                totalCapacity:      prev.totalCapacity + curr.totalCapacity,
                availableCapacity:  prev.availableCapacity + curr.availableCapacity
            };
        });
    }

    private setContingentUsages(panels) {
        let contingentUsage: BundleApi.BundleContingentUsage[];

        return panels.map(
            (panel) => {
                if (panel.utilityStatus === null) {
                    return panel;
                }

                // webspace storage is a special case
                if (panel.panelType === 'webhosting-bundle-webspace-storage') {
                    panel.utilityStatus.inUse = this.webspace.storageUsed;
                    panel.utilityStatus.maxUse = this.webspace.storageQuota;
                    return panel;
                }

                // webspace user is a special case
                if (panel.panelType === 'webhosting-user') {
                    panel.utilityStatus.inUse = this.webspace.accesses.length;
                    panel.utilityStatus.maxUse = null;
                    return panel;
                }

                // cronjobs is a special case
                if (panel.panelType === 'cronjobs') {
                    panel.utilityStatus.inUse = this.webspace.cronJobs.length;
                    panel.utilityStatus.maxUse = null;
                    return panel;
                }

                contingentUsage = this.getContingentUsage(panel);

                if (contingentUsage.length !== 0) {
                    switch (panel.panelType) {
                        case 'domain':
                            panel.utilityStatus.inUse = this.sumCapacity(contingentUsage).usedCapacity;
                            panel.utilityStatus.maxUse = this.sumCapacity(contingentUsage).totalCapacity;

                            if (!(
                                parseInt(panel.utilityStatus.maxUse, 10) > 0
                                && parseInt(panel.utilityStatus.inUse, 10) < parseInt(panel.utilityStatus.maxUse, 10)
                            )) {
                                panel.utilityStatus.infoText = this.$translate.instant('TR_140119-aeb48a_TR');
                            }

                            Object.defineProperty(
                                panel.utilityStatus,
                                'addon',
                                {
                                    get: () => this.addonDomains
                                }
                            );
                            break;

                        case 'email-imap-mailbox':
                        case 'database':
                            panel.utilityStatus.inUse = contingentUsage[0].usedCapacity;
                            panel.utilityStatus.maxUse = contingentUsage[0].totalCapacity;
                            break;
                        default:
                            break;
                    }
                }

                return panel;
            }
        );
    }

    private get ownedBySubaccount() {
        return this.bundle.accountId !== this.authContext.account.id;
    }

    private setBundleServiceOverviewRoute(panels) {
        return panels.map(
            (panel) => {
                const mailServer = this.authContext.accountDefaults
                ? this.authContext.accountDefaults.mailServer
                : 'hosting.de';

                switch (panel.panelType) {
                    case 'email-imap-mailbox':
                        panel.panelItems = [
                            {
                                route: 'bundle.id.mailboxes.new',
                                text: this.$translate.instant('TR_090119-7a60e9_TR')
                            },
                            {
                                text: this.$translate.instant('TR_090119-c056ce_TR'),
                                url: `https://webmail.${mailServer}`,
                                target: '_blank'
                            },
                            {
                                text: this.$translate.instant('TR_090119-daa91b_TR'),
                                url: `https://exchange.${mailServer}`
                            }
                        ];

                        break;
                    case 'database':
                        panel.panelItems[1].url = `https://phpMyAdmin.${mailServer}`;
                        break;
                    case 'webhosting-bundle-webspace-storage':
                        panel.panelHeaderRouteParams = {
                            bundleId: this.bundle.id,
                            webspaceId: this.webspace.id
                        };

                        panel.utilityStatus.inUse = this.webspace.storageUsed;
                        panel.utilityStatus.maxUse = this.webspace.storageQuota;

                        panel.panelItems[0].route = 'bundle.id.webspaces.id.storage';
                        panel.panelItems[0].routeParams = { bundleId: this.bundle.id, webspaceId: this.webspace.id };

                        break;
                    default:
                        break;
                }
                return panel;
            }
        );
    }
}

export class TemplateBundleDashboardComponent implements ng.IComponentOptions {
    public bindings = {
        _multiPartTlds: '<multiPartTlds',
        bundle: '<',
        showObjectId: '<',
        webspace: '<'
    };
    public controller = TemplateBundleDashboardController;
    public template = require('./bundle-dashboard-template.html');
}
