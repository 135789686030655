import * as ng from 'angular';
import { EditPanelStatus } from '../../../../';

export class OrganEditPanelBundleRenameController {
    public static $inject: string[] = [];

    public readonly initialStatus = EditPanelStatus.ACTIVE;

    public cancel;
    public $editFormOrganism;
    public panelRight;
}

export class OrganEditPanelBundleRenameComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormBundleRename'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./bundle-rename.html');
    public controller = OrganEditPanelBundleRenameController;
}
