import * as ng from 'angular';
import { LinkListItem } from '@/atomic-components';
import { UiRights } from '@/configuration';
import { AuthContextService, BundleModelService, VhostModelService, WebspaceModelService } from '@/services';
import * as Types from '@/types';

export class OrganDashboardWidgetProductBundleController implements ng.IController {
    public static $inject: string[] = ['$translate', 'bundleModel', 'vhostModel', 'webspaceModel'];

    public bundle: Types.BundleApi.Bundle;
    public demoData: unknown;
    public domainList: unknown[] = [];
    public linkListDomains: LinkListItem[] = [];
    public linkListFooter: LinkListItem[] = [];
    public loadingDomainList = true;
    public panelHeader = {
        allProductsQuantity: 0,
        includeShowAllLink: false,
        includeShowDetailsLink: false,
        panelHeaderAllRoute: '',
        panelHeaderAllRouteParams: {},
        panelHeaderRoute: '',
        panelHeaderRouteParams: {},
        panelIcon: 'box-alt'
    };
    public productQuantity = 0;
    public webspace: Types.WebhostingApi.Webspace;

    private _productExists: boolean;
    private _productLoaded: boolean;
    private _hasWebObjectListRight: boolean;

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private bundleModel: BundleModelService,
        private vhostModel: VhostModelService,
        private webspaceModel: WebspaceModelService
    ) {
        this._productExists = false;
        this._productLoaded = false;
    }

    public $onInit(): void {
        this._hasWebObjectListRight = AuthContextService.isGranted(UiRights.WEB_OBJECT_LIST);

        void this._getLastModifiedProduct()
            .then(this._getBundleWebspace)
            .then(this._getWebspaceVhosts)
            .then(this._setLinkList)
            .then(this._finishLoadingData);
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set productLoaded({}) {}
    public get productLoaded(): boolean {
        return this._productLoaded;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set productExists({}) {}
    public get productExists(): boolean {
        return this._productExists;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set productName({}) {}
    public get productName(): string {
        if (!this.productLoaded) {
            return '';
        }

        if (!this.bundle) {
            return this.$translate.instant('TR_290121-4be1d9_TR');
        }

        return this.bundle.name;
    }

    private _getLastModifiedProduct = (): PromiseLike<unknown> => {
        const filter = { field: 'accountId', value: AuthContextService.account.id };
        const sort = { field: 'bundleLastChangeDate', order: 'DESC' };

        return this.bundleModel
            .listWithoutPagination(null, 1, filter, sort)
            .then(
                (productRes) => {
                    const data = productRes.data as Types.BundleApi.Bundle[];
                    this.productQuantity = data.length;
                    if (this.productQuantity > 0) {
                        this.bundle = data[0];
                        this._productExists =  true;
                    } else if (this.demoData) {
                        this._productExists =  true;
                        this.productQuantity = 4;
                        this.bundle = this.demoData.bundle as Types.BundleApi.Bundle;
                    }
                }
            );
    };

    private _getBundleWebspace = (): PromiseLike<Types.WebhostingApi.Webspace> | Promise<null> => {
        if (!this.bundle || !this._hasWebObjectListRight) {
            return Promise.resolve(null);
        }

        const filter = { field: 'bundleId', value: this.bundle.id };

        return this.webspaceModel
            .listWithoutPagination(1, 1, filter)
            .then(
                (productRes) => {
                    if (productRes.data.length > 0) {
                        return productRes.data[0];
                    }

                    return null;
                }
            );
    };

    private _getWebspaceVhosts = (
        webspace: Types.WebhostingApi.Webspace
    ): PromiseLike<Types.WebhostingApi.VHost[]> | Promise<null> => {
        this.linkListDomains = this.linkListDomains || [];

        if (!webspace) {
            this.loadingDomainList = false;
            return Promise.resolve(null);
        }

        this.webspace = webspace;
        this.panelHeader.includeShowDetailsLink = true;
        this.panelHeader.includeShowAllLink = true;
        this.panelHeader.panelHeaderRoute = 'bundle.id.edit';
        this.panelHeader.panelHeaderRouteParams = { id: this.bundle.id };
        this.panelHeader.panelHeaderAllRoute = 'bundle.overview';
        if (this.productQuantity > 0) {
            this.panelHeader.allProductsQuantity = this.productQuantity;
        }

        const vhostFilter = { field: 'WebspaceId', value: webspace.id };
        const vhostSort = { field: 'vhostLastChangeDate', order: 'DESC' };

        return this.vhostModel
            .listWithoutPagination(6, 1, vhostFilter, vhostSort)
            .then(
                (productRes) => {
                    return productRes.data;
                }
            );
    };

    private _setLinkList = (vhosts: Types.WebhostingApi.VHost[]): Promise<unknown> => {
        this.linkListDomains = this.linkListDomains || [];

        if (!vhosts) {
            this.loadingDomainList = false;
            return Promise.resolve(null);
        }

        this.linkListDomains = vhosts
            .filter(
                (domain: any, index: number, array: any[]) => !array.some(
                    (otherDomain: any, otherIndex: number) => otherIndex !== index
                        && otherDomain.domainNameUnicode.indexOf(domain.domainNameUnicode) > 0
                )
            )
            .map(
                (domain: any) => ({
                    route: 'webhosting.webspaces.id.vhosts.id.edit',
                    routeParams: { webspaceId: this.webspace.id, vHostId: domain.id },
                    text: domain.domainNameUnicode
                })
            );

        if (this.linkListDomains.length > 5) {
            this.linkListDomains = this.linkListDomains.slice(0, 5);
            this.linkListDomains.push({
                route: 'webhosting.webspaces.id.vhosts.overview',
                routeParams: { webspaceId: this.webspace.id },
                text: this.$translate.instant('TR_100119-800d0e_TR')
            });
        }

        this.linkListFooter.push({
            route: 'webhosting.webspaces.id.vhosts.overview',
            routeParams: { webspaceId: this.webspace.id },
            text: this.$translate.instant('TR_080119-acb35a_TR')
        });

        this.linkListFooter.push({
            route: 'webhosting.webspaces.id.vhosts.new',
            routeParams: { webspaceId: this.webspace },
            text: this.$translate.instant('TR_100119-4a31ea_TR')
        });

        this.loadingDomainList = false;

        return Promise.resolve();
    };

    private _finishLoadingData = (): void => {
        this._productLoaded = true;
    };
}

export class OrganDashboardWidgetProductBundleComponent implements ng.IComponentOptions {
    public bindings = {
        demoData: '<?'
    };
    public controller =  OrganDashboardWidgetProductBundleController;
    public template = require('./dashboard-widget-product-bundle.html');
}
