import * as ng from 'angular';

export class CryptoToolsService {
    public static $inject: string[] = [];

    private typeMap = {
        '2.5.4.6': 'C',
        '2.5.4.10': 'O',
        '2.5.4.11': 'OU',
        '2.5.4.3': 'CN',
        '2.5.4.7': 'L',
        '2.5.4.8': 'S',
        '2.5.4.12': 'T',
        '2.5.4.42': 'GN',
        '2.5.4.43': 'I',
        '2.5.4.4': 'SN',
        '1.2.840.113549.1.9.1': 'E-mail'
    };

    private fieldMap = {
        'OU': 'organizationUnit',
        'O': 'organization',
        'CN': 'domainName',
        'E-mail': 'emailAddress',
        'C': 'country',
        'L': 'locality',
        'ST': 'state'
    };

    public decodeCertificate = (certificate) => {
        if (certificate === undefined || certificate === '') {
            return;
        }
        const certPem = certificate.replace(/(-----(BEGIN|END) CERTIFICATE-----|\n)/g, '');

        const buffer = this.base64ToBuffer(certPem);

        if (buffer === false) {
            return buffer;
        }

        // to do - just for testing
        return true;

        /*
        //let asn1 = org.pkijs.fromBER(buffer);
        let cert;

        try {
            //cert = new org.pkijs.simpl.CERT({schema: asn1.result});
        } catch (e) {
            return false;
        }

        let result = {
            domainName: '',
            country: '',
            emailAddress: '',
            locality: '',
            organization: '',
            organizationUnit: '',
            state: '',
            serial: '',
            notBefore: '',
            notAfter: ''
        };

        result.serial = this.getSerialNumber(cert);

        for (let i = 0; i < cert.subject.types_and_values.length; i++) {
            let typeval = this.typeMap[cert.subject.types_and_values[i].type];
            if (typeval === undefined) {
                typeval = cert.subject.types_and_values[i].type;
            }

            if (typeval in this.fieldMap) {
                let subjval = cert.subject.types_and_values[i].value.value_block.value;
                let field = this.fieldMap[typeval];
                result[field] = subjval;
            }
        }

        result.notBefore = cert.notBefore.value;
        result.notAfter = cert.notAfter.value;

        return result;*/
    };

    private toHexCodes = (input) => {
        let result = '';
        const buffer = new Uint8Array(input);

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < buffer.length; i++) {
            const str = buffer[i].toString(16).toUpperCase();
            result = result + ((str.length === 1) ? ' 0' : ' ') + str;
        }
        return result;
    };

    private getSerialNumber = (cert) => {
        if (cert.serialNumber.value_block.is_hex_only === true) {
            return this.toHexCodes(cert.serialNumber.value_block.value_hex);
        }

        return cert.serialNumber.value_block.value_dec;
    };

    private stringToArrayBuffer = (str) => {
        const stringLength = str.length;

        const resultBuffer = new ArrayBuffer(stringLength);
        const resultView = new Uint8Array(resultBuffer);

        for (let i = 0; i < stringLength; i++) {
            resultView[i] = str.charCodeAt(i);
        }

        return resultBuffer;
    };

    private base64ToBuffer = (str) => {
        let buffer;
        try {
            str = window.atob(str);
        } catch (e) {
            buffer = false;
        }

        if (buffer !== false) {
            buffer = this.stringToArrayBuffer(str);
        }

        return buffer;
    };
}
/* jshint ignore:end */
