import * as ng from 'angular';
import { PageHeaderData } from '../../../atomic-components/organisms/page-header-panel/page-header-panel';

export class TemplateRecordContentSearchController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PageHeaderData;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'dns.dashboard',
            backwardText: this.$translate.instant('TR_100119-528437_TR'),
            panelHeaderRoute: '',
            panelIcon: 'location-arrow',
            panelTitle: this.$translate.instant('TR_100119-9716e6_TR')
        };
    };
}

export class TemplateRecordContentSearchComponent implements ng.IComponentOptions {
    public template = require('./content-search-template.html');
    public controller = TemplateRecordContentSearchController;
}
