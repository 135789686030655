import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateDatabaseJobDetailsController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;
    public job: any;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'database.jobs',
            backwardText: this.$translate.instant('TR_210219-573ba4_TR'),
            panelHeaderRoute: '',
            panelIcon: 'database',
            panelTitle: this.$translate.instant('TR_210219-ce8296_TR', { jobName: this.job.displayName })
        };
    };
}

export class TemplateDatabaseJobDetailsComponent implements ng.IComponentOptions {
    public bindings = {
        job: '<'
    };
    public controller = TemplateDatabaseJobDetailsController;
    public template = require('./database-job-details-template.html');
}
