import * as ng from 'angular';

import { UIRegExp } from '@/configuration';

import { Validator } from './abstract-validator';

export class ValidateIpv4 extends Validator {
    private allowEmpty: boolean;
    private acceptPlaceholders: boolean;

    constructor(
        private $translate: ng.translate.ITranslateService,
        allowEmpty: boolean,
        acceptPlaceholders?: boolean
    ) {
        super();
        this.allowEmpty = allowEmpty;
        this.acceptPlaceholders = acceptPlaceholders;
    }

    public validate = (value: string, instructions?: any) => {
        if (this.acceptPlaceholders && (value === '##IPV4##' || value === '##MX_IPV4##')) {
            return [];
        }

        if (value === undefined || value.length === 0) {
            if (this.allowEmpty
                || (
                    instructions !== undefined
                    && instructions.allowEmpty !== undefined
                    && instructions.allowEmpty
                )
            ) {
                return [];
            } else {
                return [{ text: this.$translate.instant('TR_090119-2705b2_TR') }];
            }
        }

        const parts = value.split('.');

        if (parts.length !== 4) {
            return [{ text: this.$translate.instant('TR_170119-18e8c4_TR') }];
        }

        if (parts.every(this.validateIPv4Part)) {
            return [];
        } else {
            return [{ text: this.$translate.instant('TR_170119-18e8c4_TR') }];
        }
    };

    public setAllowEmpty = (allowEmpty) => {
        this.allowEmpty = allowEmpty;
    };

    private validateIPv4Part(part) {
        if (part === '0') {
            return true;
        }

        if (part.length > 3) {
            return false;
        }
        if (!UIRegExp.Ipv4Part.test(part)) {
            return false;
        }

        if (part.startsWith('0')) {
            return false;
        }

        return parseInt(part, 10) <= 255;
    }
}

export class ValidateIpv6 extends Validator {
    private allowEmpty: boolean;
    private acceptPlaceholders: boolean;

    constructor(
        private $translate: ng.translate.ITranslateService,
        allowEmpty: boolean,
        acceptPlaceholders?: boolean
    ) {
        super();
        this.allowEmpty = allowEmpty;
        this.acceptPlaceholders = acceptPlaceholders;
    }

    public validate = (value: string, instructions?: any) => {
        if (this.acceptPlaceholders && (value === '##IPV6##' || value === '##MX_IPV6##')) {
            return [];
        }

        if (value === undefined || value.length === 0) {
            if (this.allowEmpty
                || (
                    instructions !== undefined
                    && instructions.allowEmpty !== undefined
                    && instructions.allowEmpty
                )
            ) {
                return [];
            } else {
                return [{ text: this.$translate.instant('TR_090119-2705b2_TR') }];
            }
        }

        if (value === '::') {
            return [];
        }

        value = value.toUpperCase();

        if (UIRegExp.Ipv6.test(value)) {
            return [];
        } else {
            return [{ text: this.$translate.instant('TR_170119-06d547_TR') }];
        }
    };
}

export class ValidateIp extends Validator {
    private ipv4Validator: ValidateIpv4;
    private ipv6Validator: ValidateIpv6;
    private allowEmpty: boolean;

    constructor(
        private $translate: ng.translate.ITranslateService,
        allowEmpty: boolean
    ) {
        super();
        this.ipv4Validator = new ValidateIpv4($translate, allowEmpty);
        this.ipv6Validator = new ValidateIpv6($translate, allowEmpty);
        this.allowEmpty = allowEmpty;
    }

    public validate = (value: string) => {
        const ipv4Validation = this.ipv4Validator.validate(value, { allowEmpty: this.allowEmpty });
        const ipv6Validation = this.ipv6Validator.validate(value, { allowEmpty: this.allowEmpty });

        if (ipv4Validation.length === 0 || ipv6Validation.length === 0) {
            return [];
        } else {
            return [{ text: this.$translate.instant('TR_170119-aec7f9_TR') }];
        }
    };
}

export class ValidateMixedIp extends Validator {
    private ipv4Validator: ValidateIpv4;
    private ipv6Validator: ValidateIpv6;

    constructor(
        private $translate: ng.translate.ITranslateService,
        allowEmpty: boolean
    ) {
        super();
        this.ipv4Validator = new ValidateIpv4($translate, allowEmpty);
        this.ipv6Validator = new ValidateIpv6($translate, allowEmpty);
    }

    public validate = (value: string) => {
        if (value.indexOf(':') >= 0) {
            return this.ipv6Validator.validate(value).length === 0
                ? []
                : [{ text: this.$translate.instant('TR_170119-354b02_TR') }];
        }

        return this.ipv4Validator.validate(value).length === 0
            ? []
            : [{ text: this.$translate.instant('TR_170119-719a7a_TR') }];
    };
}

export class ValidateMixedIpList extends Validator {
    private validateMixedIp: ValidateMixedIp;

    constructor(
        private $translate: ng.translate.ITranslateService,
        allowEmpty: boolean
    ) {
        super();
        this.validateMixedIp = new ValidateMixedIp($translate, allowEmpty);
    }

    public validate = (list: string|string[]) => {
        if (typeof list === 'string') {
            list = list.split('\n');
        }

        return list.every((ip) => this.validateMixedIp.validate(ip).length === 0)
            ? []
            : [{ text: this.$translate.instant('TR_170119-d13296_TR') }];
    };
}
