import * as ng from 'angular';

import { TimeSpecificationsConst } from '../../../../../../../configuration/time-specifications';
import { AuthContextService } from '../../../../../../../services';
import {
    OrganismEditFormBundleBackupsController
} from '../../../../../../organisms/forms/edit-forms/backups/bundle/bundle-backups';
import { MoleculeFormEditController } from '../../../../../forms/form-edit/form-edit';
import { MoleculePanelEditTableController } from '../../../../panel-edit/table/panel-edit-table';

export class MoleculePanelEditRowTableBundleBackupsController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public $panelEditTable: MoleculePanelEditTableController; // speicher button aktivieren
    public $organismEditForm: any;
    public $editForm: MoleculeFormEditController;
    public name: string;
    public showRestorationConfirmation = false;
    public showDeletionConfirmation = false;
    public hideAdditionalBackups = true;
    public backupSelectedForRestoration: any;
    public backupSelectedForDeletion: any;

    private $organismEditFormBundleBackups: OrganismEditFormBundleBackupsController;
    private backupType: string;
    private service: string;
    private weekDayList: string[];

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {
        this.weekDayList = TimeSpecificationsConst.weekdays.map((entry) =>
            this.$translate.instant(entry.translationId)
        );
    }

    public $onInit() {
        this.getTemplate();
    }

    public get showDeleteButton() {
        switch (this.service) {
            case 'database':
                return AuthContextService.isGranted('BACKUP_DELETE_BACKUP_DATABASE');
            case 'webspace':
                return AuthContextService.isGranted('BACKUP_DELETE_BACKUP_WEBSPACE');
            default:
                return true;
        }
    }

    public get showRestoreButton() {
        switch (this.service) {
            case 'database':
                return AuthContextService.isGranted('BACKUP_RESTORE_DATABASE');
            case 'webhosting':
                return AuthContextService.isGranted('BACKUP_RESTORE_WEBSPACE');
            default:
                return true;
        }
    }

    public get backupList() {
        return this.$organismEditFormBundleBackups.webspaceBackups
            .filter((backup) => backup.type === this.backupType)
            .concat(
                this.$organismEditFormBundleBackups.databaseBackups
                .filter((backup) => backup.type === this.backupType)
            );
    }

    public openRestoreConfirmation = (backup: any) => {
        this.backupSelectedForRestoration = backup;
        this.showRestorationConfirmation = true;
    };

    public closeRestoreConfirmation = () => {
        this.backupSelectedForRestoration = undefined;
        this.showRestorationConfirmation = false;
    };

    public openDeleteConfirmation = (backup: any) => {
        this.backupSelectedForDeletion = backup;
        this.showDeletionConfirmation = true;
    };

    public closeDeleteConfirmation = (backup: any) => {
        this.backupSelectedForDeletion = undefined;
        this.showDeletionConfirmation = false;
    };

    public showAllBackupsToggle = () => {
        this.hideAdditionalBackups = !this.hideAdditionalBackups;
    };

    public restoreBackups = (backup: any) => {
        this.$organismEditFormBundleBackups.restoreBackups(backup);
    };

    public deleteBackup = (backup: any) => {
        this.$organismEditFormBundleBackups.deleteBackup(backup);
    };

    public getTemplate = () => require(`./bundle-backups-${this.backupType}.html?external`);

    public showWeekday(date) {
        return this.$translate.instant('TR_170719-cdf3cd_TR', { dayOfWeek: this.weekDayList[new Date(date).getDay()] });
    }
}

export class MoleculePanelEditRowTableBundleBackupsComponent implements ng.IComponentOptions {
    public require = {
        $editForm: '^moleculeFormEdit',
        $organismEditFormBundleBackups: '^organismEditFormBundleBackups',
        $panelEditTable: '^moleculePanelEditTable'
    };

    public bindings = {
        backupType: '@type',
        name: '@',
        service: '@'
    };

    public controller = MoleculePanelEditRowTableBundleBackupsController;
    public controllerAs = '$PanelEditRowTableBackup';
    public template = require('./bundle-backups.html');
}
