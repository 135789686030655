export * from './nextcloud-admin-access/nextcloud-admin-access-template';
export * from './nextcloud-addons/nextcloud-addons';
export * from './nextcloud-delete';
export * from './nextcloud-order-backup';
export * from './nextcloud-rename/nextcloud-rename-template';
export * from './nextcloud-restore';
export * from './nextcloud-upgrade';
export * from './nextcloud-use-own-domain/nextcloud-use-own-domain-template';
export * from './nextcloud-wizard-template';
export * from './routes';
