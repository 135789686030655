import { RecordNameDomainPrefixes } from '@/configuration/dns/dns-zone';
import * as ng from 'angular';
import { DnsHelperService } from '../../../../../services';

export class MoleculePanelCreateDnsRecordController implements ng.IController {
    public static $inject: string[] = ['dnsHelper'];

    public $editFormMolecule;
    public addedRecords: string[];
    public records: any[];
    public recordTypeDropdownItems: any[];
    public opened: boolean;
    public templateCreator: boolean;
    public recordNameAddon: string;
    public isDnsTemplateNew = false;

    private _newRecord: any;

    constructor(private dnsHelper: DnsHelperService) {}

    public $onInit() {
        this.templateCreator = this.templateCreator || false;
        this.resetRecord();
        this.$editFormMolecule.saveCallback = this.addRecord;
    }

    public save = () => {
        this.$editFormMolecule.save();
    };

    public close = () => {
        this.resetRecord();
        this.opened = false;
    };

    public get disableConfirmButton() {
        return this.$editFormMolecule.$invalid;
    }

    public addRecord = () => {
        if (this.newRecord.type === undefined) {
            return;
        }
        if (this.recordNameAddon) {
            this.newRecord.name = this.newRecord.name + '.' + this.recordNameAddon;
        }
        this.newRecord.id = 'newRecord_' + this.addedRecords.length;
        this.records.push(ng.copy(this.newRecord));
        this.addedRecords.push(this.newRecord.id);
        this.resetRecord();
        this.opened = false;
    };

    public resetRecord = () => {
        this.newRecord = {
            ttl: '86400',
            type: 'A'
        };
    };

    public get newRecord() {
        const prefixMatch = Object.keys(RecordNameDomainPrefixes).filter((el) => el === this._newRecord?.type);
        if (prefixMatch.length > 0) {
            this.recordNameAddon = RecordNameDomainPrefixes[prefixMatch[0]];
        }

        return this._newRecord;
    }
    public set newRecord(value) {
        this._newRecord = value;
    }

    public get contentValidationType() {
        return this.dnsHelper.getRecordValidationType(this.newRecord.type);
    }
}

export class MoleculePanelCreateDnsRecordComponent implements ng.IComponentOptions {
    public bindings = {
        addedRecords: '=',
        isDnsTemplateNew: '<',
        opened: '=',
        records: '=',
        recordTypeDropdownItems: '<',
        templateCreator: '<'
    };
    public require = {
        $editFormMolecule: '^moleculeFormEdit'
    };
    public controller = MoleculePanelCreateDnsRecordController;
    public template = require('./panel-create-dns-record.html');
}
