import * as ng from 'angular';
import { AlertManagerService, VhostModelService } from '../../../../../services';
import { WebhostingApi } from '../../../../../types';

export enum domainLocationsFormState  {
    READONLY,
    EDITABLE,
    ADD
}

export interface LocationMetadataArray {
    locations: LocationMetadata[];
    info: any;
}

export interface LocationMetadata {
    sortable: boolean;
    order?: number;
    markedForRemoval: any;
    httpHeaderEnabled: any;
    httpHeaderInfo: any;
    data: WebhostingApi.Location;
    editModel?: boolean;
}

export class OrganismEditFormDomainLocationsController {
    public static $inject: string[] = ['$translate', 'alertManager', 'vhostModel'];

    public vHost: { locations: any };
    public origLocations: any[];
    public phpIni: any;
    public sortableLocations: LocationMetadataArray;
    public nonSortableLocations: LocationMetadataArray;
    public formState: domainLocationsFormState;
    public showNewLocation = false;
    public defaultLocation: any;

    private sortableLocationsList: any[] = [];
    private nonSortableLocationsList: any[] = [];
    private httpHeaderPropertyNames: string[] = [
        'Cache-Control',
        'Content-Security-Policy',
        'Expires',
        'X-Frame-Options',
        'X-UA-Compatible',
        'Content-Type'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private vhostModel: VhostModelService
    ) {}

    public $onInit() {
        this.origLocations = JSON.parse(JSON.stringify(this.vHost.locations));
        this.resetLocations();
    }

    public resetLocations = () => {
        this.formState = domainLocationsFormState.READONLY;
        this.resetAllLocations();
    };

    public resetSortableLocations = () => {
        this.sortableLocationsList = [];

        for (const location of this.origLocations) {
            if (['default', 'directory'].indexOf(location.matchType) === -1) {
                const locationInfo = this.getLocationInfoObject(location);
                this.sortableLocationsList.push(locationInfo);
            }
        }

        this.sortableLocations = {
            info: {
                locationsFromProfile: this.getNumberOfLocationsFromProfile(this.sortableLocationsList)
            },
            locations: this.sortableLocationsList
        };
    };

    public resetNonSortableLocations = () => {
        this.nonSortableLocationsList = [];
        for (const location of this.origLocations) {
            if (['directory'].indexOf(location.matchType) >= 0) {
                const locationInfo = this.getLocationInfoObject(location);
                this.nonSortableLocationsList.push(locationInfo);
            }
        }

        this.nonSortableLocations = {
            info: {
                locationsFromProfile: this.getNumberOfLocationsFromProfile(this.nonSortableLocationsList)
            },
            locations: this.nonSortableLocationsList
        };
    };

    public resetDefaultLocation = () => {
        for (const location of this.origLocations) {
            if (location.matchType === 'default') {
                this.defaultLocation = this.getLocationInfoObject(location);
            }
        }
    };

    public createHttpHeaderData = (location: any) => {
        const httpHeaderInfo = [];
        for (const propertyName of this.httpHeaderPropertyNames) {
            const i = this.findPropertyInHttpHeaderData(location, propertyName);
            if (i === null) {
                httpHeaderInfo.push({
                    content: '',
                    name: propertyName,
                    enabled: false
                });
            } else {
                httpHeaderInfo.push({
                    content: location.httpHeader[i].content,
                    name: propertyName,
                    enabled: true
                });
            }
        }
        return httpHeaderInfo;
    };

    public saveDomainLocations = () => {
        this.sortableLocations.locations
        .sort((obj1, obj2) => obj1.order - obj2.order);

        const locationsToBeSaved = [];
        locationsToBeSaved.push(this.defaultLocation.data);

        for (const location of this.sortableLocations.locations) {
            if (!location.markedForRemoval) {
                const httpHeaderOptions = [];
                if (location.httpHeaderEnabled) {
                    for (const option of location.httpHeaderInfo) {
                        if (option.enabled) {
                            httpHeaderOptions.push({
                                name: option.name,
                                content: option.content
                            });
                        }
                    }
                }

                if (location.data.matchType === 'placeholderMatch' && location.data.matchString[0] !== '/') {
                    location.data.matchString = '/' + location.data.matchString;
                }

                location.data.httpHeader = httpHeaderOptions;
                locationsToBeSaved.push(location.data);
            }
        }

        for (const location of this.nonSortableLocations.locations) {
            if (!location.markedForRemoval) {
                const httpHeaderOptions = [];

                if (location.httpHeaderEnabled) {
                    for (const option of location.httpHeaderInfo) {
                        if (option.enabled) {
                            httpHeaderOptions.push({
                                name: option.name,
                                content: option.content
                            });
                        }
                    }
                }

                location.data.httpHeader = httpHeaderOptions;

                if (location.data.matchString[location.data.matchString.length - 1] !== '/') {
                    location.data.matchString += '/';
                }

                if (location.data.matchString[0] !== '/') {
                    location.data.matchString = '/' + location.data.matchString;
                }

                locationsToBeSaved.push(location.data);
            }
        }

        const vhost = JSON.parse(JSON.stringify(this.vHost));
        vhost.locations = locationsToBeSaved;

        return this.vhostModel.update(vhost, this.phpIni, '', null)
            .then(
                (reply) => {
                    this.vHost = reply.response;
                    this.origLocations = locationsToBeSaved;
                    this.alertManager.success(this.$translate.instant('TR_100119-ba9939_TR'));
                    this.formState = domainLocationsFormState.READONLY;
                    this.resetLocations();
                    return reply;
                }
            );
    };

    private resetAllLocations = () => {
        this.resetSortableLocations();
        this.resetNonSortableLocations();
        this.resetDefaultLocation();
    };

    private getLocationInfoObject = (location) => {
        let locationOrder: number;

        if (location.matchType === 'directory') {
            locationOrder = this.nonSortableLocationsList.length;
        } else {
            locationOrder = this.sortableLocationsList.length;
        }

        const httpHeaderInfo = this.createHttpHeaderData(location);

        return {
            data: ng.copy(location),
            editModel: false,
            httpHeaderEnabled: location.httpHeader.length > 0,
            httpHeaderInfo: httpHeaderInfo,
            markedForRemoval: false,
            order: locationOrder,
            sortable: location.matchType !== 'directory'
        };
    };

    private getNumberOfLocationsFromProfile = (locationsList: any[]) => {
        let i = 0;
        for (const location of locationsList) {
            if (location.data.setByProfile) {
                i ++;
            }
        }
        return i;
    };

    private findPropertyInHttpHeaderData = (location: any, propertyName: string) => {
        for (const i in location.httpHeader) {
            if (location.httpHeader[i].name === propertyName) {
                return i;
            }
        }
        return null;
    };
}

export class OrganismEditFormDomainLocationsComponent implements ng.IComponentOptions {
    public bindings = {
        phpIni: '<',
        vHost: '<'
    };

    public controller = OrganismEditFormDomainLocationsController;
    public template = require('./domain-locations.html');
}
