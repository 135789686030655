import * as ng from 'angular';

export class MoleculeStatusEmailController implements ng.IController {
    public static $inject: string[] = [];

    public email;
}

export class MoleculeStatusEmailComponent implements ng.IComponentOptions {
    public bindings = {
        email: '<'
    };

    public controller =  MoleculeStatusEmailController;
    public controllerAs = '$status';
    public template = require('./status-email.html');
}
