import * as ng from 'angular';
import { UiRights } from '../../../../configuration';
import { AccountModelService, AuthContextService, BillingRobotService } from '../../../../services';
import {
    EditFormMessage,
    EditFormSavingStatus,
    EditFormStatus,
    EditPanelStatus,
    MoleculeFormEditController,
    PanelType
} from '../../forms';

type Validator = () => true|any;

export class MoleculePanelDeleteOwnAccountController implements ng.IController {
    public static $inject: string[] = ['$translate', 'accountModel', 'authContext', 'billingRobot'];

    // Bindings
    public name = 'deleteOwnAccount';
    public panelIcon: string;
    public agreedToDeleteAccount = false;
    public initialStatus: EditPanelStatus;
    public deleteButtonText: string = this.$translate.instant('GENERAL.FORM.DELETE');
    public panelTitle: string = this.$translate.instant('TR_230119-940112_TR');
    public readonly type = PanelType.MISCELLANEOUS_ACTION;

    // Required parent controller
    public $editForm: MoleculeFormEditController;

    public $validators: { [key: string]: Validator } = {};

    public canCheckRenewableObjects = false;
    public hasRenewableObjects: boolean;
    public hasSubaccounts: boolean;

    private status: EditPanelStatus = EditPanelStatus.READONLY;

    constructor(
        private $translate,
        private accountModel: AccountModelService,
        private authContext: AuthContextService,
        billingRobot: BillingRobotService
    ) {
        this.canCheckRenewableObjects = this.authContext.isGranted(UiRights.BIL_LIST_RENEWABLE_OBJECTS);

        if (this.canCheckRenewableObjects) {
            billingRobot.findRenewableObjects({ field: 'AccountId', value: this.authContext.account.id }, 1)
            .then(
                (result) => this.hasRenewableObjects = [undefined, null].indexOf(result.response) < 0
                && [undefined, null].indexOf(result.response.data) < 0
                && result.response.data.length > 0
            );
        }

        if (this.authContext.isGranted(UiRights.ACC_SUBACCOUNT_LIST)) {
            accountModel.listSubaccountsWithoutPagination(1)
            .then(
                (result) => this.hasSubaccounts = result.data.length > 0
            );
        }
    }

    public $onInit = () => {
        this.$editForm.register(this);

        if ([undefined, null].indexOf(this.initialStatus) < 0) {
            this.status = this.initialStatus;
        }
    };

    public $onDestroy = () => {
        this.$editForm.unregister(this);
    };

    public get showPanel() {
        return this.authContext.canDeleteOwnAccount;
    }

    public get panelDisabled() {
        return !this.canCheckRenewableObjects
            || this.hasRenewableObjects
            || this.hasSubaccounts;
    }

    public deleteButtonIsActive(): boolean {
        return this.agreedToDeleteAccount;
    }

    public get $valid() {
        return Object.keys(this.$validators)
            .every((key) => this.$validators[key]() === true);
    }

    public get $invalid() {
        return Object.keys(this.$validators)
            .some((key) => this.$validators[key]() !== true);
    }

    public get $errors() {
        const errors = {};
        for (const key of Object.keys(this.$validators)) {
            const result = this.$validators[key]();
            if (result !== true) {
                errors[key] = result;
            }
        }
        return errors;
    }

    public get isEditable() {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showCancelButton() {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showEditButton() {
        return [
            EditPanelStatus.READONLY
        ].indexOf(this.status) >= 0;
    }

    public get showSaveButton() {
        if (EditPanelStatus.DELETIONSCHEDULED === this.status) {
            return false;
        }
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showEditableContent() {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showReadonlyContent() {
        return [
            EditPanelStatus.READONLY
        ].indexOf(this.status) >= 0;
    }

    public get showSavingContent() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get saveButtonLoading() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get disableCancelButton() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get disableEditButton() {
        return !this.$editForm.canEnablePanel(this.name);
    }

    public get disableSaveButton() {
        return this.$editForm.$invalid
            || (this.$editForm.$status === EditFormStatus.SAVING)
            || (this.agreedToDeleteAccount !== true)
            || this.panelDisabled;
    }

    public get disableInputElements() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public onMessage = (message: EditFormMessage<any>) => {
        switch (message.type) {
            default: return;

            case 'statusUpdate':
                switch (message.message) {
                    default: return;

                    case EditFormSavingStatus.ERROR:
                        if (this.status !== EditPanelStatus.READONLY) {
                            this.status = EditPanelStatus.ACTIVE;
                        }

                        return;

                    case EditFormSavingStatus.READY:
                        this.status = EditPanelStatus.READONLY;
                        return;
                }
        }
    };

    public activate = () => {
        this.status = EditPanelStatus.ACTIVE;
    };

    public delete = () => {
        if (this.$editForm.$invalid) {
            return;
        }

        this.$editForm.processApiCallback(
            () => this.accountModel.deleteAccount()
        );
    };

    public cancel = () => {
        this.status = EditPanelStatus.READONLY;
        this.agreedToDeleteAccount = false;
    };

    public cancelDeletion = () => {
        this.status = EditPanelStatus.READONLY;
        this.$editForm.cancelDeletion();
    };

    public successfullySaved = () => {
        this.status = EditPanelStatus.READONLY;
    };

    public errorWhileSaving = () => {
        this.status = EditPanelStatus.ACTIVE;
    };
}

export class MoleculePanelDeleteOwnAccountComponent implements ng.IComponentOptions {
    public bindings = {
        initialStatus: '<'
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public controller =  MoleculePanelDeleteOwnAccountController;
    public controllerAs = '$deletePanel';
    public template = require('./panel-delete-own-account.html');
}
