import { IconName, IconPrefix, SizeProp } from '@fortawesome/fontawesome-svg-core';
import * as ng from 'angular';

import './fa-icon.scss';

export class FaIconController implements ng.IController {
    public icon: IconName;
    public spin: boolean;
    public size: SizeProp;
    public prefix: IconPrefix;
    public fixedSize = false;

    private _color: string;

    public $onInit(): void {
        this.prefix = this.prefix || 'fas';
    }

    public get color(): string {
        return this._color ? `fa-${this._color}` : '';
    }

}

export class FaIconComponent implements ng.IComponentOptions {
    public bindings = {
        _color: '@color',
        icon: '@',
        prefix: '@?',
        size: '@?',
        spin: '<?',
        fixedSize: '<?fixed'
    };
    public controller =  FaIconController;
    public template = require('./fa-icon.html');
}
