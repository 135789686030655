import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { ApiErrorModel, BillingRobotService } from '../../../services';

export class TemplateInvoicePaymentCancelController {
    public static $inject: string[] =  ['$translate', 'apiErrorModel', 'billingRobot'];

    public modifier = '';
    public pageHeaderData: PanelHeaderData = {
        backwardLink: 'billing.invoices.overview',
        backwardText: this.$translate.instant('TR_090119-af8a66_TR'),
        panelHeaderRoute: '',
        panelHeaderRouteParams: null,
        panelIcon: 'money-bill-alt',
        panelTitle: this.$translate.instant('TR_090119-5c429a_TR')
    };
    private _token: string;
    private _cancelComplete = false;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private apiErrorModel: ApiErrorModel,
        private billingRobot: BillingRobotService
    ) {}

    public $onInit() {
        this.billingRobot.paymentCancel(this._token).then(() => {
            this._cancelComplete = true;
        },
        () => {
            this._cancelComplete = true;
        });
    }

    public get showConfirm() {
        return this._cancelComplete
            && !this.hasError;
    }

    public set showConfirm({}) {} // tslint:disable-line:no-empty

    public get hasError() {
        return this._cancelComplete
            && this.apiErrorModel.errorObjectList.length > 0;
    }

    public set hasError({}) {} // tslint:disable-line:no-empty
}

export class TemplateInvoicePaymentCancelComponent implements ng.IComponentOptions {
    public bindings = {
        _token: '<?token'
    };
    public controller = TemplateInvoicePaymentCancelController;
    public controllerAs = '$TemplateInvoicePaymentCancelCtrl';
    public template = require('./invoice-payment-cancel-template.html');
}
