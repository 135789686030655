import * as ng from 'angular';
import { PageHeaderData } from '../../../atomic-components/organisms';
import { AlertManagerService, AuthContextService, NavigationService } from '../../../services';
import { DdnsModelService } from '../../../services/dns';

export class TemplateDdnsCredentialsNewController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'authContext',
        'ddnsModel',
        'navigation'
    ];

    public newUser = {
        accountId: '',
        comments: '',
        name: '',
        userName: ''
    };
    public chosenDdnsUserId = '';
    public password = '';
    public pageHeaderData: PageHeaderData = {
        backwardLink: 'dns.ddns.credentials.overview',
        backwardText: this.$translate.instant('TR_200319-5c7d68_TR'),
        panelHeaderRoute: '',
        panelIcon: 'location-arrow',
        panelTitle: ''
    };
    public faqArticleIdList: string[] = [
        'dynamic-dns',
        'dns-delete-entry',
        'dns-setup-office',
        'dns-srv-record'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private authContext: AuthContextService,
        private ddnsModel: DdnsModelService,
        private navigation: NavigationService
    ) {}

    public $onInit() {
        this.newUser.accountId = this.authContext.user.accountId;
        this.pageHeaderData.panelTitle = this.$translate.instant('TR_210319-370f2d_TR');
    }

    public save = () => {
        this.ddnsModel.createDdnsUser(this.newUser, this.password).then(
            (apiResponse) => {
                this.alertManager.success(this.$translate.instant('TR_210319-1d63b7_TR'));
                this.navigation.go('dns.ddns.credentials.overview', {}, { reload: true });
            }
        );
    };
}

export class TemplateDdnsCredentialsNewComponent implements ng.IComponentOptions {
    public bindings = {
        showObjectId: '<'
    };
    public template = require('./ddns-credentials-new-template.html');
    public controller = TemplateDdnsCredentialsNewController;
}
