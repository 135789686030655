import { ModelHelper } from '../model-helper';
import { RequestStatus } from '../rpc-client';
import { SslRobotService } from './robot';

import * as ng from 'angular';
import * as q from 'q';
import * as Types from '../../types';

export class SslCertificateModelService {
    public static $inject: string[] = ['sslRobot', '$rootScope'];

    constructor(private sslRobot: SslRobotService, private $rootScope: ng.IRootScopeService) {}

    public list = (limit?, page?, filters?, sort?) => {
        limit = limit || 1;
        page = page || 1;

        return this.sslRobot.certificatesFind(filters, limit, page, sort)
            .then(ModelHelper.returnListResponse);
    };

    public listWithoutPagination = (limit?, page?, filters?, sort?) => {
        limit = limit || 1;
        page = page || 1;

        return this.sslRobot.certificatesFindWithoutPagination(filters, limit, page, sort)
            .then(ModelHelper.returnListResponse);
    };

    public findOne: (id: string) => q.IPromise<Types.SslApi.Certificate>
    = (id) => {
        return this.sslRobot.certificateGet(id)
            .then(this.returnResponse);
    };

    public findById(ids) {
        return Promise.all(ids.map(this.sslRobot.certificateGet))
            .then(this.mapToResponses);
    }

    public addCertificateDetails: (certificates: Types.SslApi.Certificate[]) => q.IPromise<any>
    = (certificates) => {
        return Promise.all(
            certificates.map(
                (certificate) => this.sslRobot.certificateDetailsGet(certificate.id)
                    .then(this.returnResponse)
                    .then(
                        (details) => {
                            const keys = [
                                'adminContact',
                                'approverEmailAddress',
                                'csr',
                                'organization',
                                'techContact',
                                'validationKey',
                                'validationType',
                                'validationValue'
                            ];

                            for (const key of keys) {
                                certificate[key] = details[key];
                            }

                            return certificate;
                        }
                    )
            )
        );
    };

    public revoke(certificates) {
        const promises = certificates.map(this.revokeOne);
        return Promise.all(promises)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    }

    public resendApproverEmails(orders) {
        const promises = orders.map(this.resendApproverEmail);
        return Promise.all(promises)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    }

    public reissueCertificate = (certificateId: string, csr?: string) => {
        return this.sslRobot.certificateReissue(certificateId, csr);
    };

    private refresh = (status: RequestStatus = RequestStatus.SUCCESSFUL) => (result) => {
        this.$rootScope.$broadcast('certificates.refresh');

        if (status === RequestStatus.SUCCESSFUL) {
            return result;
        } else {
            return Promise.reject(result);
        }
    };

    private returnResponse(result) {
        return result.response;
    }

    private mapToResponses = (results) => {
        return results.map(this.returnResponse);
    };

    private revokeOne = (certificate) => {
        return this.sslRobot.certificateRevoke(certificate.id);
    };

    private resendApproverEmail = (order) => {
        return this.sslRobot.orderResendApproverEmail(order.id);
    };
}
