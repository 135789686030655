import * as ng from 'angular';
import './checkbox-toggle.scss';

export class AtomFormCheckboxToggleController implements ng.IController {
    public static $inject: string[] = [];
    public disabled = false;
    public toggle: boolean;
    public toggleTextOn: string;
    public toggleTextOff: string;
    public displayText = true;

    private callback: (param: any) => {};
    private callbackParams: any;

    public toggleValue() {
        if (!this.disabled) {
            this.toggle = !this.toggle;
            if (this.callback !== undefined) {
                this.callback({ status: this.toggle, params: this.callbackParams });
            }
        }
    }

    public onKeyDown = ($event: KeyboardEvent) => {
        if (['Enter', 'Space', 'NumpadEnter'].indexOf($event.code) >= 0) {
            $event.preventDefault();
            this.toggleValue();
        }
    };
}

export class AtomFormCheckboxToggleComponent implements ng.IComponentOptions {
    public bindings = {
        callback: '<',
        callbackParams: '<?',
        disabled: '<',
        toggle: '=',
        toggleTextOff: '@?',
        toggleTextOn: '@?',
        displayText: '<?'
    };
    public controller =  AtomFormCheckboxToggleController;
    public controllerAs = '$formcheckboxToggle';
    public template = require('./checkbox-toggle.html');
}
