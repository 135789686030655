import { DnsTemplateModelService, DnsZoneModelService, WizardNewHelperService } from '@/services';
import * as ng from 'angular';
import * as Types from '../../../../../types';
import { AccountApi, DnsApi, ViewTypes } from '../../../../../types';

export class OrganismDnsWizardProductConfigZoneCopyController {
    public static $inject: string[] = [
        '$timeout',
        'dnsTemplateModel',
        'dnsZoneModel',
        'wizardNewHelper'
    ];

    public metadata: ViewTypes.ProductConfigDnsObject;
    public service = 'dns';
    public displayNoZonesFoundWarning = false;
    public sourceHasApexNsRecords = false;
    public showTemplateNotAvailableInTargetAccountWarning = false;
    public defaultNameservers;
    public currentNsList = [];
    public formCompleted = {
        // tslint:disable:no-empty
        records: () => ([null, undefined].indexOf(this.metadata.records) < 0 && this.metadata.records.length > 0)
            || this.metadata.copiedZoneConfig.type === 'SLAVE'
        // tslint:enable:no-empty
    };

    private _account: Types.AccountApi.Account | Types.AccountApi.Subaccount;

    constructor(
        private $timeout: ng.ITimeoutService,
        public dnsTemplateModel: DnsTemplateModelService,
        public dnsZoneModel: DnsZoneModelService,
        private wizardNewHelper: WizardNewHelperService
    ) {}

    public $onInit = () => {
        this._setMetadata();
        this._getDefaultNameserverSet();

        this.dnsZoneModel.findZones(undefined, 1, 1).then(
            (apiResponse) => {
                this.displayNoZonesFoundWarning = apiResponse.length === 0;
            }
        );
    };

    public get showZonePreview(): boolean {
        return [null, undefined].indexOf(this.metadata.records) < 0
            && this.metadata.records.length > 0;
    }

    public get account(): AccountApi.Account | AccountApi.Subaccount {
        return this._account;
    }
    public set account(value: AccountApi.Account | AccountApi.Subaccount) {
        if (value && this.metadata && this.metadata.account) {
            this.metadata.account = value;
        }

        this._account = value;
    }

    public set configCompleted({}) {} // tslint:disable-line:no-empty
    public get configCompleted() {
        let completed = true;

        for (const part in this.formCompleted) {
            if (!this.formCompleted[part]()) {
                completed = false;
            }
        }

        return completed;
    }

    public copyZone = async (selectedZone) => {
        if (!selectedZone) {
            return;
        }

        this.showTemplateNotAvailableInTargetAccountWarning = false;
        const zoneRecordList: DnsApi.Record[] = await this.dnsZoneModel.findRecords(selectedZone);
        const selectedZoneConfig: DnsApi.ZoneConfig = await this.dnsZoneModel.findOne(selectedZone);

        this.metadata.records = zoneRecordList
            .filter(
                (record) => {
                    return ['SOA'].indexOf(record.type) < 0;
                }
            )
            .map(
                (record) => {
                    const tmpRecord = ng.copy(record);

                    tmpRecord.name = record.name.replace(
                        new RegExp(`.?${selectedZoneConfig.name}$`),
                        ''
                    );

                    tmpRecord.content = record.content.replace(
                        selectedZoneConfig.name,
                        this.metadata.fqdnList[0]
                    );

                    return tmpRecord;
                }
            );

        const nonDefaultApexNsRecords = this.metadata.records
            .filter((rec) => rec.name === '' && rec.type === 'NS') // only apex NS records
            .filter((rec) => !this.defaultNameservers.nameservers.some(
                (defaultRec) => defaultRec.name === rec.content
            ));

        this.metadata.nameserver.values.default = nonDefaultApexNsRecords.length === 0;

        this.metadata.copiedZoneConfig = ng.copy(selectedZoneConfig);

        if (
            this.metadata.copiedZoneConfig.templateValues?.templateId?.length > 0
        ) { // user tries to copy a zone that is bound to a template
            const templateIsAvailable = await this._isTemplateAvailableInTargetAccount(
                this.metadata.account.id,
                this.metadata.copiedZoneConfig.templateValues.templateId
            );

            /**
             * When the template is not available in the target account display a warning to the user
             * and remove the template link from the zone.
             **/
            if (!templateIsAvailable) {
                this.showTemplateNotAvailableInTargetAccountWarning = true;
                delete this.metadata.copiedZoneConfig.templateValues;

                this.metadata.records = this.metadata.records.map(
                    (rec) => {
                        if (rec.recordTemplateId) {
                            delete rec.recordTemplateId;
                        }

                        return rec;
                    }
                );
            }
        }

        delete this.metadata.copiedZoneConfig.id;
        delete this.metadata.copiedZoneConfig.name;
        delete this.metadata.copiedZoneConfig.nameUnicode;
        delete this.metadata.copiedZoneConfig.status;
        delete this.metadata.copiedZoneConfig.addDate;
        delete this.metadata.copiedZoneConfig.lastChangeDate;
        delete this.metadata.copiedZoneConfig.accountId;
        delete this.metadata.copiedZoneConfig.dnsServerGroupId;
        this.metadata.copiedZoneConfig.dnsSecMode = 'off';

        if (this.metadata.copiedZoneConfig.type === 'SLAVE') {
            this.metadata.nameserver.values.default = false;
            this.metadata.nameserver.values.id = null;
        }

        this.$timeout();
    };

    private _getDefaultNameserverSet = () => {
        this.wizardNewHelper.getNameserverSets(this.metadata.account.id)
            .then((nameserverSets) => {
                this.defaultNameservers = this.wizardNewHelper.objectCache.nameserSets[0].items[0].value;
            });
    };

    private _setMetadata = () => {
        this.metadata = {
            account: this._account,
            fqdnList: [],
            productCode: undefined,
            productFamily: undefined,
            records: [],
            copiedZoneConfig: {},
            nameserver: {
                ownNameservers: [],
                ownNameserversComplete: false,
                values: {
                    default: false,
                    id: null,
                    nameservers: null,
                    type: null
                }
            }
        };
    };

    private _isTemplateAvailableInTargetAccount = async (
        targetAccountId: string,
        templateId: string
    ) => {
        const filterObject: any = {
            subFilter: [
                {
                    field: 'accountId',
                    value: targetAccountId
                },
                {
                    field: 'templateId',
                    value: templateId
                }
            ],
            subFilterConnective: 'AND'
        };

        const foundTemplate = await this.dnsTemplateModel.list(1, 1, filterObject);

        return foundTemplate.data.length > 0;
    };
}

export class OrganismDnsWizardProductConfigZoneCopyComponent implements ng.IComponentOptions {
    public bindings = {
        account: '<account',
        configCompleted: '=configCompleted',
        metadata: '='
    };
    public template = require('./dns-wizard-product-config-zone-copy.html');
    public controller = OrganismDnsWizardProductConfigZoneCopyController;
}
