import * as ng from 'angular';
import './overlay-on-submit.scss';

export class MoleculeFormOverlayOnSubmitController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public inProcess = false;
    public processText = '';

    constructor(
        public $translate: ng.translate.ITranslateService
    ) {}

    public get showOverlay() {
        return this.inProcess;
    }

    public set showOverlay({}) {} // tslint:disable-line:no-empty
}

export class MoleculeFormOverlayOnSubmitComponent implements ng.IComponentOptions {
    public bindings = {
        inProcess: '<',
        processText: '@'
    };
    public controller = MoleculeFormOverlayOnSubmitController;
    public template = require('./overlay-on-submit.html');
}
