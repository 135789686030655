import * as ng from 'angular';

export class NominetRegistrarListService {
    public static $inject: string[] = ['$http'];

    constructor(private $http: ng.IHttpService) {}

    public getList = () => {
        return this.$http.get('/assets/data/nominetRegistrars.json', {cache: true})
            .then(this.returnDataRegistrars);
    };

    private returnDataRegistrars = (result) => {
        return result.data.registrars;
    };
}
