import * as ng from 'angular';
import { ManagedMachineProjectObject } from '../../../organisms';

export class OrganManagedMachineProjectConfirmationVhostController implements ng.IController {
    public static $inject: string[] = [];

    public projectData: ManagedMachineProjectObject;

    public get vhostCreatingStatus() {
        let status = 'creating';

        if (this.projectData.errorCreating.webspaceUsers === 1
            || this.projectData.errorCreating.webspaces === 1
        ) {
            status = 'abort';
        } else if (this.projectData.errorCreating.vhosts === 1) {
            status = 'failed';
        }  else if (this.projectData.finishedCreating.vhosts === 1) {
            status = 'installed';
        }

        return status;
    }
}

export class OrganManagedMachineProjectConfirmationVhostComponent implements ng.IComponentOptions {
    public bindings = {
        projectData: '='
    };

    public template = require('./vhost.html');
    public controller =  OrganManagedMachineProjectConfirmationVhostController;
}
