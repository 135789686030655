import * as ng from 'angular';
import { PanelType } from '../../../../molecules';

export class OrganEditPanelDatabaseUsersController {
    public static $inject: string[] = [];
    public readonly panelTypeOverride = PanelType.MISCELLANEOUS_ACTION;
    public panelDisabled = false;

    public $editFormOrganism;
    public panelRight;
}

export class OrganEditPanelDatabaseUsersComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelDisabled: '<?',
        panelRight: '<',
        validationInstructions: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormDatabase'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./database-users.html');
    public controller = OrganEditPanelDatabaseUsersController;
}
