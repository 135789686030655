import * as ng from 'angular';
import {
    MoleculeFormEditController,
    MoleculePanelEditRowTableNextcloudGroupFoldersController,
    OrganismEditFormNextcloudController
} from '@/atomic-components';
import {
    MoleculePanelEditTableController
} from '@/atomic-components/molecules/panels/panel-edit/table/panel-edit-table';
import { NextcloudUnlimitedStorageValue } from '@/configuration';
import { ManagedApplicationApi } from '@/types';

export class MoleculePanelCreateNextcloudGroupFolderController implements ng.IController {
    public onAdd: (value: ManagedApplicationApi.NextcloudGroupFolder) => void;
    public newGroupFolder: ManagedApplicationApi.NextcloudGroupFolder;
    public $panelEditTable: MoleculePanelEditTableController;
    public setPageOverlay: boolean;
    public $moleculeFormEdit: MoleculeFormEditController;
    public $panelRowTable: MoleculePanelEditRowTableNextcloudGroupFoldersController;
    public $organismEditForm: OrganismEditFormNextcloudController;
    public groupPermissionsRaw: ManagedApplicationApi.NextcloudGroupFolderPermissionsCache[] = [];
    public selectedGroups: string[] = [];
    public groupFolderNameValidationErrors: string[] = [];
    public maxStorage = 0;

    public $onInit(): void {
        this.resetGroupFolderObject(false);
        this.setPageOverlay = this.setPageOverlay || false;
        this.$moleculeFormEdit.saveCallback = this.saveNewGroupFolder;
        this.maxStorage = this.$organismEditForm.nextcloud.storageQuota / 1024;
    }

    get unlimitedStorage(): boolean {
        return this.newGroupFolder.quota === NextcloudUnlimitedStorageValue;
    }

    set unlimitedStorage(value: boolean) {
        this.newGroupFolder.quota = value === true ? NextcloudUnlimitedStorageValue : 1;
    }

    public checkQuota = (): void => {
        if (this.newGroupFolder.quota === undefined) {
            this.newGroupFolder.quota = 1;
        }
    };

    public removeGroup = (groupName: string): void => {
        this.selectedGroups = this.selectedGroups.filter(
            (selectedGroupName) => selectedGroupName !== groupName
        );
        this.$panelRowTable.hiddenGroups = this.selectedGroups;
        this.groupSelectionChanged(this.selectedGroups);
        this.$panelRowTable.refreshGroupSelectDropdown();
    };

    public addGroup = (): void => {
        this.selectedGroups.push(this.$panelRowTable.selectedGroupToAdd);
        this.groupSelectionChanged(this.selectedGroups);
        this.$panelRowTable.hiddenGroups = this.selectedGroups;
        this.$panelRowTable.selectedGroupToAdd = null;
        this.$panelRowTable.refreshGroupSelectDropdown();
    };

    public groupSelectionChanged = (value: string[]): void => {
        const groupPermissions: ManagedApplicationApi.NextcloudGroupFolderPermissionsCache[] = [];

        value.forEach(
            (groupName) => {
                const alreadyExisted = this.groupPermissionsRaw.filter(
                    (existingGroup) => existingGroup.groupName === groupName
                );

                if (alreadyExisted.length === 1) {
                    groupPermissions.push(alreadyExisted[0]);
                } else {
                    groupPermissions.push(
                        {
                            groupName: groupName,
                            permissions: {
                                write: false,
                                remove: false,
                                share: false
                            }
                        }
                    );
                }
            }
        );

        this.groupPermissionsRaw = groupPermissions;
    };

    public saveNewGroupFolder = (): void => {
        if ([undefined, null].indexOf(this.$panelRowTable.selectedGroupToAdd) < 0) {
            this.addGroup();
        }

        const newGroupFolder = ng.copy(this.newGroupFolder);
        newGroupFolder.id = 'generated_' + newGroupFolder.name;
        newGroupFolder.groupPermissions = this.groupPermissionsRaw.map(
            (groupPermission: ManagedApplicationApi.NextcloudGroupFolderPermissionsCache) => {
                return {
                    groupName: groupPermission.groupName,
                    permissions: Object.keys(groupPermission.permissions).filter(
                        // @ts-ignore: TS7053
                        (singleRight) => groupPermission.permissions[singleRight] === true
                    )
                };
            }
        );

        if (newGroupFolder.quota === undefined) { // user choose a too high value ... use the max value
            newGroupFolder.quota = this.maxStorage;
        }

        newGroupFolder.quota = this.unlimitedStorage
            ? NextcloudUnlimitedStorageValue
            : parseInt(`${newGroupFolder.quota}`, 10) * 1024;
        this.onAdd(newGroupFolder);
        this.resetGroupFolderObject();
    };

    public resetGroupFolderObject = (alsoCloseWindow = true): void => {
        this.newGroupFolder = {
            name: '',
            groupPermissions: [],
            quota: NextcloudUnlimitedStorageValue
        };

        this.groupPermissionsRaw = [];
        this.selectedGroups = [];
        this.$panelRowTable.hiddenGroups = [];
        this.$panelRowTable.selectedGroupToAdd = null;

        if (alsoCloseWindow) {
            this.$panelEditTable.closeCreate();
        }
    };

    public checkUniqueGroupFolderName = (folderName: string): void => {
        this.groupFolderNameValidationErrors = this.$organismEditForm.checkUniqueFolderName(folderName);
    };
}

export class MoleculePanelCreateNextcloudGroupFolderComponent implements ng.IComponentOptions {
    public bindings = {
        onAdd: '<',
        setPageOverlay: '<'
    };

    public require = {
        $moleculeFormEdit: '^moleculeFormEdit',
        $organismEditForm: '^organismEditFormNextcloud',
        $panelEditTable: '^moleculePanelEditTable',
        $panelRowTable: '^moleculePanelEditRowTableNextcloudGroupFolders'
    };

    public controller =  MoleculePanelCreateNextcloudGroupFolderController;
    public template = require('./panel-create-nextcloud-group-folder.html'); // tslint:disable-line:max-line-length
}
