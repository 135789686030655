import * as ng from 'angular';
import { TopLevelDomains, UiRights } from '@/configuration';
import {
    AuthContextService,
    DomainCheckerService,
    DomainHelperService,
    NavigationService,
    PriceCacheService
} from '@/services';

export class MoleculePanelDomainCheckSimpleController implements ng.IController {
    public static $inject: string[] = [
        'domainChecker',
        'domainHelper',
        'navigation',
        'priceCache'
    ];

    public domainSearchString = '';
    public dropdownSelection = TopLevelDomains.groups[0].name;
    public numberOfSelectedDomains: number;
    public panelVisible = false;
    public selectedDomains: any[] = [];
    public tldPriceList: string[];

    private _domainSearchString: string;
    private _smallView: boolean;

    public constructor(
        private domainChecker: DomainCheckerService,
        private domainHelper: DomainHelperService,
        private navigation: NavigationService,
        private priceCache: PriceCacheService
    ) {}

    public $onInit(): void {
        this._domainSearchString = ng.copy(this.domainSearchString);
        this._smallView = this._smallView || false;
        this.panelVisible = AuthContextService.isGrantedAny([
            UiRights.DOM_DOMAINS_CREATE_WITH_CONTACTS,
            UiRights.DOM_DOMAINS_CREATE_WITH_DEFAULTS,
            UiRights.DOM_DOMAINS_CREATE_WITH_OWN_NS
        ]);
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set smallView({}) {}
    public get smallView(): boolean {
        return this._smallView;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set selectedEndings({}) {}
    public get selectedEndings(): string[] {
        return TopLevelDomains.groups.filter(
            (group) => group.name === this.dropdownSelection
        )[0].tlds;
    }

    public domainCheck = (inputString: string): void => {
        let selectedDomains: any[];
        this.domainSearchString = inputString;
        const domainSearchString = inputString
            .split('\n')
            .filter((domain) => domain.indexOf('.') >= 1);

        if (domainSearchString.length === 0) {
            console.error('No domains given!');
            return;
        }

        void this.updateDomainInfo(domainSearchString)
            .then(
                async (domainsInfo) => {
                    for (const domain of domainSearchString) {
                        selectedDomains = (await domainsInfo).map(
                            (domainInfo) => {
                                return {
                                    amount: 1,
                                    authCode: '',
                                    domainName: domainInfo.domain.domainNameUnicode,
                                    domainNameAce: domainInfo.domain.domainName,
                                    domainObject: domainInfo.domain,
                                    inBundle: domainInfo.inBundle,
                                    label: domainInfo.domain.domainName,
                                    price: this.domainHelper.getPriceObject(domainInfo.domain, domainInfo.prices),
                                    status: domainInfo.domain.status,
                                    tld: domainInfo.domain.domainSuffix
                                };
                            }
                        );
                    }

                    this._goToWizard(inputString, domainsInfo);
                }
            );
    };

    public updateDomainInfo = (domainNames) => {
        const domainInfo = [];
        return this.domainChecker.check(domainNames, AuthContextService.account.id).then(
            (reply) => {
                const promises = [];
                for (const domain of reply) {
                    promises.push(this._listDomainPrices(domain));
                }

                return Promise.all(promises).then(
                    (domainsInfo) => {
                        return domainsInfo;
                    }
                );
            }
        );
    };

    private _listDomainPrices = (domain) => {
        return this.priceCache
            .listDomainPrices(domain.extension, AuthContextService.account.id)
            .then(
                (ret) => {
                    return {
                        domain: domain,
                        prices: ret
                    };
                }
            );
    };

    private _goToWizard = (inputString: string, domainsInfo: { domain }): void => {
        void this.navigation.go(
            'domain.domains.new',
            {
                domainSearchString: inputString,
                domainList: domainsInfo,
                selectedDomains: this.selectedDomains
            }
        );
    };
}

export class MoleculePanelDomainCheckSimpleComponent implements ng.IComponentOptions {
    public bindings = {
        _smallView: '<?smallView',
        tldPriceList: '<?'
    };
    public controller =  MoleculePanelDomainCheckSimpleController;
    public template = require('./panel-domain-check-simple.html');
}
