import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';

export class TemplateDnsTemplateEditController {
    public static $inject: string[] = ['$translate', 'authContext'];

    public template: any;
    public records: any[];
    public defaultNameserver: any[];
    public pageHeaderData: PanelHeaderData = {
        backwardLink: 'dns.templates.overview',
        backwardText: this.$translate.instant('TR_100119-d7db35_TR'),
        panelHeaderRoute: '',
        panelHeaderRouteParams: {},
        panelIcon: 'location-arrow',
        panelTitle: '',
        productActions: []
    };

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit() {
        this.pageHeaderData.panelTitle = this.$translate.instant('TR_100119-80266b_TR',
            { templateName: this.template.name });

        if (this.authContext.isGranted(UiRights.DNS_TEMPLATES_CREATE)) {
            this.pageHeaderData.productActions.push({
                route: 'dns.templates.id.copy',
                stateParams: { templateId: this.template.id },
                title: this.$translate.instant('TR_180419-25cee1_TR')
            });
        }
    }
}

export class TemplateDnsTemplateEditComponent implements ng.IComponentOptions {
    public bindings = {
        defaultNameserver: '<',
        dnsSecOptions: '<',
        records: '<',
        template: '<'
    };
    public template = require('./template-edit-template.html');
    public controller = TemplateDnsTemplateEditController;
}
