/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty-pattern */
import * as ng from 'angular';

import {
    IStorageItem
} from '@/atomic-components/molecules/forms/form-elements/storage-quota-changer/storage-quota-changer';
import { debounce, ProductHelperService, ProductsModelService, WizardNewHelperService } from '@/services';
import { ViewTypes } from '@/types';

export class OrganismEmailWizardProductConfigMailboxController implements ng.IController {
    public static $inject: string[] = [
        '$scope',
        '$translate',
        'productsModel',
        'wizardNewHelper'
    ];

    public metadata: ViewTypes.ProductConfigEmailObject;

    public selectedProductCode: string = null;
    public vhostValidationErrors: string[] = []; // used in template
    public service = 'email';

    public currentForwarderTarget: string;
    public forwarderListErrors: any[] = [];
    public emailValidationErrorList: any[] = [];
    public internalForwarder: any;
    public showPleaseSelectTheCorrectProduct = false;
    public correctBundleId: string;
    public includedStorage: number;
    public maxStorage: number;
    public additionalStorageItems = 0;
    public inPool = false;
    public storageItem: IStorageItem;
    public spamFilter: any;
    public dnsValidationMessage: string;
    public selectTheCorrectProductTranslation: string;

    public emailAddressInputChanged: any;

    public formCompleted = {
        emailAddress: () => [undefined, null, ''].indexOf(this.metadata?.mailbox?.emailAddress) < 0
            && this.emailValidationErrorList.length === 0,

        password: () => [undefined, null, ''].indexOf(this.metadata?.password) < 0,

        forwardTarget: () => this.forwarderListErrors.length === 0
            || this.forwarderListErrors.every((errorArray) => errorArray.length === 0),

        showPleaseSelectTheCorrectProduct: () => this.showPleaseSelectTheCorrectProduct === false
    };

    private _additionalStorageList: string[] = [];

    constructor(
        private $scope: ng.IScope,
        private $translate: ng.translate.ITranslateService,
        private productsModel: ProductsModelService,
        private wizardNewHelper: WizardNewHelperService
    ) {}

    public set configCompleted({}) {}
    public get configCompleted(): boolean { // used by parent
        for (const part in this.formCompleted) {
            if (!(this.formCompleted as Record<string, () => boolean>)[part]()) {
                return false;
            }
        }

        return true;
    }

    public $onInit = (): void => {
        this.emailValidationErrorList = [];
        this.metadata.mailbox.emailAddress = '';
        this.metadata.password = '';
        this.metadata.mailbox.forwarderTargets = [];
        this.metadata.storageQuota = 1024;
        this.metadata.isAdmin = false;
        this.storageItem = {} as IStorageItem;
        this.$scope.$watch(
            () => this.metadata.contingent.id,
            () => this.checkForCorrectBundle(),
            true
        );
        this.emailAddressInputChanged = debounce(this.checkForCorrectBundle, 500);
        this._checkProductSecificationItems();
    };

    public get additionalStorageList(): number { return this._additionalStorageList.length; }
    public set additionalStorageList(value: number) {
        const selectedStorageItems = Array.apply(null, Array(value)).map(() => this.storageItem) as IStorageItem[];
        this._additionalStorageList = selectedStorageItems.map((item) => item.productCode);
        this.metadata.additionalStorageInfo = {
            amount: value,
            storageItem: this.storageItem
        };
    }

    public get showRedirects(): boolean {
        return this.formCompleted.showPleaseSelectTheCorrectProduct()
            && this.formCompleted.emailAddress()
            && this.formCompleted.password();
    }

    public get showStorageQuota(): boolean {
        return this.showRedirects
            && this.formCompleted.forwardTarget();
    }

    public get showSpamProtection(): boolean {
        return this.showStorageQuota;
    }

    public checkForCorrectBundle = (): void => {
        void this.wizardNewHelper.checkDomainCorrectBundle(this.metadata, this.correctBundleId)
            .then((result) => {
                this.showPleaseSelectTheCorrectProduct = result.showPleaseSelectTheCorrectProduct;
                this.selectTheCorrectProductTranslation = this.$translate
                    .instant(result.translation, { bundleName: result.bundleName });
                this.correctBundleId = result.correctBundleId;
            });
    };

    private _checkProductSecificationItems = (): void => {
        if ([undefined, null, ''].indexOf(this.metadata.productCode) >= 0) {
            return;
        }
        void this.productsModel.findOne(this.metadata.productCode).then(
            (reply) => {
                if (reply.response.specificationItems !== undefined) {
                    const specification = ProductHelperService.wrapSpecificationItems(
                        reply.response.specificationItems
                    );

                    this.includedStorage = specification.includedStorage?.intValue;
                    this.maxStorage = specification.maxStorage?.intValue;

                    // Fallback for docker which might not serve any maxStorage-value.
                    if (!this.maxStorage) {
                        this.maxStorage = this.includedStorage * 20;
                    }
                }
            }
        );
    };
}

export class OrganismEmailWizardProductConfigMailboxComponent implements ng.IComponentOptions {
    public bindings = {
        _additionalStorageList: '=additionalStorageList',
        configCompleted: '=configCompleted',
        metadata: '='
    };

    public template = require('./email-wizard-product-config-mailbox.html');
    public controller = OrganismEmailWizardProductConfigMailboxController;
}
