import * as ng from 'angular';
import * as q from 'q';
import { LinkListOverviewItem } from '../../atomic-components/molecules/links';
import { TextFilterField } from '../../atomic-components/molecules/search/fancy-filter-fields';
import { UiRights } from '../../configuration';
import { OverviewModel } from '../abstract';
import { AuthContextService } from '../auth-context';
import { RequestCancellerService } from '../request-canceller';
import { AccountModelService } from './account-model';

export class SubAccountOverviewModelService extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', 'accountModel']
    );
    public readonly service = 'subaccount';
    public readonly objectType = 'SubAccount';
    public readonly idField = 'subAccountId';
    public readonly defaultFilterFields = [
        'SubAccountName',
        'SubAccountCustomerNumber',
        'SubAccountEmailAddress',
        'SubAccountId'
    ];

    public activeMassAction: string | boolean = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};
    public ownAccountId: string;

    protected listCallbacks = [
        {
            callback: (...args: any[]) => this.accountModel
            .clearSubAccountCache()
            .listSubaccounts
            .apply(this.accountModel, args)
        }
    ];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService
    ) {
        super(RequestCanceller);
        this.actionLinksAvailabilities = {
            lockableSubAccount: this.lockableSubAccount,
            unlockableSubAccount: this.unlockableSubAccount
        };
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('SubAccountName', this.$translate.instant('TR_140119-0b23a4_TR')),
                new TextFilterField('SubAccountCustomerNumber', this.$translate.instant('TR_140119-4ce1de_TR')),
                new TextFilterField('SubAccountEmailAddress', this.$translate.instant('TR_110119-7cb083_TR')),
                new TextFilterField('SubAccountComments', this.$translate.instant('TR_100119-e937e9_TR')),
                new TextFilterField('SubAccountId', this.$translate.instant('TR_140119-9f068c_TR'))
            ];
        }

        return this._cachedAvailableFilters;
    }

    /* tslint:disable-next-line:no-empty */
    public set availableFilters({}) {}

    public actionLinks = (): LinkListOverviewItem[] => {
        return [
            {
                isGranted: AuthContextService.isGrantedAny([UiRights.ACC_SUBACCOUNT_LOCK]),
                linkList: [
                    {
                        group: 'lock',
                        groupName: '',
                        isClickable: 'lockableSubAccount',
                        isGranted: AuthContextService.isGranted(UiRights.ACC_SUBACCOUNT_LOCK),
                        onclickMethode: () => this.activeMassAction = 'lock',
                        route: '',
                        text: this.$translate.instant('TR_140119-6b7809_TR')
                    },
                    {
                        group: 'lock',
                        groupName: '',
                        isClickable: 'unlockableSubAccount',
                        isGranted: AuthContextService.isGranted(UiRights.ACC_SUBACCOUNT_UNLOCK),
                        onclickMethode: () => this.activeMassAction = 'unlock',
                        route: '',
                        text: this.$translate.instant('TR_140119-28a631_TR')
                    }
                ],
                onlyOnExperView: true,
                route: '',
                text: this.$translate.instant('TR_140119-080106_TR'),
                type: 'multi'
            },
            {
                route: 'reseller.subaccounts.new',
                text: this.$translate.instant('TR_140119-5d80fd_TR')
            }
        ] as LinkListOverviewItem[];
    };

    public lockSubmit = (subAccounts) => {
        const lockSubAccounts = Object.keys(this.selected)
        .map(
            (elem) => {
                if (!this.selected[elem]) {
                    return false;
                }

                return this.getObjectFromId(subAccounts, elem);
            }
        );

        return q.all(lockSubAccounts.map((subAccount) => this.accountModel.disableSubaccount(subAccount.id)));
    };

    public lockableSubAccount = (subAccounts) => {
        if (subAccounts.length === 0) {
            return false;
        }
        return subAccounts.some(this.isLockable);
    };

    public unlockSubmit = (subAccounts) => {
        const unlockSubAccounts = Object.keys(this.selected)
        .map(
            (elem) => {
                if (!this.selected[elem]) {
                    return false;
                }

                return this.getObjectFromId(subAccounts, elem);
            }
        );

        return q.all(unlockSubAccounts.map((subAccount) => this.accountModel.enableSubaccount(subAccount.id)));
    };

    public unlockableSubAccount = (subAccounts) => {
        if (subAccounts.length === 0) {
            return false;
        }
        return subAccounts.some(this.isUnlockable);
    };

    private isLockable = (subAccount) => {
        return subAccount.enabled
            && this.selected[subAccount.id] !== undefined
            && this.selected[subAccount.id] !== false;
    };

    private isUnlockable = (subAccount) => {
        return !subAccount.enabled
            && this.selected[subAccount.id] !== undefined
            && this.selected[subAccount.id] !== false;
    };

    private getObjectFromId = (objects, objectId) => {
        return objects.filter((object) => object.id === objectId)[0];
    };
}
