import * as ng from 'angular';
import {
    EditFormMessage,
    EditFormSavingStatus,
    EditFormStatus,
    EditPanelStatus,
    MoleculeFormEditController,
    PanelType
} from '../../../../molecules/forms/form-edit/form-edit';

export class OrganEditPanelUserPasswordController implements ng.IController {
    public panelRightSettings;
    public cancelCallbackFunction: () => void;
    public ownPassword: string;
    public newPassword: string;
}

export class OrganEditPanelUserPasswordComponent implements ng.IComponentOptions {
    public bindings = {
        ownPassword: '=',
        newPassword: '=',
        panelRightSettings: '<',
        cancelCallbackFunction: '<',
        saveCallbackFunction: '<',
        passwordValidationInstructions: '<',
        ownPasswordValidationInstructions: '<'
    };
    public template = require('./edit-panel-user-password.html');
    public controller = OrganEditPanelUserPasswordController;
}
