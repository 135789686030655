import * as ng from 'angular';
import {DateWrapper} from '../date';
import { FunctionRelayService } from '../function-relay';
import { ModelHelper } from '../model-helper';
import { WebhostingRobotService } from '../webhosting/robot';

export class RedirectionModelService {
    public static $inject: string[] = ['webhostingRobot', '$q', '$rootScope', 'functionRelay'];

    constructor(
        private webhostingRobot: WebhostingRobotService,
        private $q: ng.IQService,
        private $rootScope: ng.IRootScopeService,
        private functionRelay: FunctionRelayService
    ) {}

    public list = (limit, page, filters, sort?) => {
        sort = sort || null;
        page = page || 1;

        return this.webhostingRobot.listRedirections(filters, limit, page, sort)
            .then(ModelHelper.returnListResponse);
    };

    public findOne(id) {
        return this.webhostingRobot.listRedirections({field: 'redirectionId', value: id}, 1, 1)
            .then(ModelHelper.returnFindOneResponse);
    }

    public findById(ids) {
        const filter = {subFilterConnective: 'OR', subFilter: ids.map(this.idFilter)};
        return this.webhostingRobot.listRedirections(filter, 0, 1)
            .then(ModelHelper.returnFindResponse);
    }

    public create(redirection, owner) {
        return this.webhostingRobot.createRedirection(redirection, owner)
            .then(this.refresh, this.refresh)
            .then(this.refreshDeposit, this.refreshDeposit);
    }

    public update(redirection) {
        return this.webhostingRobot.updateRedirection(redirection)
            .then(this.refresh, this.refresh);
    }

    public deleteOnePlaned = (redirection, execDate: DateWrapper) => {
        if (execDate.isToday()) {
            return this.webhostingRobot.deleteRedirection(redirection.id, redirection.domainName, null);
        }
        return this.webhostingRobot.deleteRedirection(redirection.id, redirection.domainName, execDate.dateObj);
    };

    public delete(redirections) {
        const promises = redirections.map(this.deleteOne);
        return this.$q.all(promises)
            .then(this.refresh, this.refresh);
    }

    private refresh = (result) => {
        this.$rootScope.$broadcast('redirections.refresh');
        return result;
    };

    private refreshDeposit = (result) => {
        this.functionRelay.call('updateDeposit', {});
        return result;
    };

    private idFilter(id) {
        return {field: 'redirectionId', value: id};
    }

    private deleteOne = (redirection) => {
        return this.webhostingRobot.deleteRedirection(redirection.id, redirection.domainName, null);
    };
}
