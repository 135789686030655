import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { UserSettingsManagerService } from '../../../services';

export class TemplateDnsZoneOverviewController {
    public static $inject: string[] = ['$state', '$translate', 'userSettingsManager'];

    public pageHeaderData: PanelHeaderData;
    public bundle: any;
    public overviewServiceData: any;
    public expertView: boolean;
    public overviewModel: any;

    private simpleFilter: any;
    private extendedFilters: any;
    private overviewUiSettings: any;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private userSettingsManager: UserSettingsManagerService
    ) {}

    public $onInit() {
        if ([undefined, null].indexOf(this.simpleFilter) === -1) {
            this.expertView = this.overviewUiSettings.expertView || this.simpleFilter.show;
        } else {
            this.expertView = this.overviewUiSettings.expertView || false;
        }

        this.pageHeaderData = {
            backwardLink: this.$state.current.name.indexOf('.linked-zones') < 0 ? 'dns.dashboard' : 'dns.templates.id.edit',
            backwardText: this.$translate.instant('TR_100119-a3c01f_TR'),
            panelHeaderRoute: '',
            panelIcon: 'location-arrow',
            panelTitle: this.$translate.instant('TR_100119-abd89e_TR')
        };
        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter
        };

        // Surpress backlink to dashboard on expert view because it auto-redirects to overview.
        this.userSettingsManager.getUiSettings().then(
            (result) => {
                if (result?.overviewSettings?.DnsOverviewCtrl?.expertView) {
                    this.pageHeaderData.backwardText = '';
                    this.pageHeaderData.backwardLink = '';
                    this.pageHeaderData.panelHeaderRouteParams = null;
                }
            }
        );
    }

    public expandWrapper = (toggle: any) => {
        this.expertView = toggle.expand;
    };
}

export class TemplateDnsZoneOverviewComponent implements ng.IComponentOptions {

    public bindings = {
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public controller = TemplateDnsZoneOverviewController;
    public controllerAs = 'TemplateDnsZoneOverviewCtrl';
    public template = require('./zone-overview-template.html');
}
