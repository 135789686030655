import * as ng from 'angular';

import { AuthContextService, FilterUtils } from '../../../../../../services';
import { DropdownElementData } from '../../drop-down-revised';

import './drop-down-api-search.scss';

export class MoleculeFormDropDownApiSearchController implements ng.IController {
    public static $inject: string[] = [];

    public allowUndefined = false;
    public listFunction: (limit: any, page: any, filters: any, sort?: any) => any;
    public findByIdFunction: (id: string) => any;
    public numberOfItems: number;
    public itemLabel: string;
    public callbackOnChange: (value: any) => any;
    public disabled: boolean;
    public placeholder: string;
    public filterFields: string[];
    public additionalFilters: any;
    public selectedItem: any;
    public selectedItemName: string;
    public dropdownItems: any[];
    public dropdownItemObjects: any[];
    public items: any[];
    public objectIdField: string;
    public excludeIdsFromSearch: string[];
    public owner: string;
    public showOptions = false;
    public searchTerm = '';
    public validationInstructions: any[];
    public isCustomisable = false;
    public isRequired = false;
    public searchMapping: DropdownElementData | string;
    public onChangeDeferred: ng.IDeferred<any>;
    public type: string;
    public valueField = 'id';
    public additionalFieldsFunction: (...args: any[]) => any;
    public value: string;

    public $onInit() {
        this.owner = this.owner || AuthContextService.account.id;
        if ([undefined, null, ''].indexOf(this.valueField) >= 0) {
            this.valueField = 'id';
        }
        let idFilter = FilterUtils.buildSpecialCases.id('ContactId');
        if ([undefined, null, ''].indexOf(this.objectIdField) < 0) {
            idFilter = FilterUtils.buildSpecialCases.id(this.objectIdField);
        }
        this.numberOfItems = this.numberOfItems || 5;
        this.showOptions = this.showOptions || false;

        if (this.onChangeDeferred) {
            this.onChangeDeferred.promise
            .then(
                (res) => {/* Ich putz hier nur! */},
                (err) => {/* Ich putz hier nur Teil 2! */},
                (data: any) => {
                    if (
                        this.type === data.handleType
                        && [undefined, null, ''].indexOf(data) < 0
                        && ng.isObject(data)
                        && [undefined, null, ''].indexOf(data.handleId) < 0
                    ) {
                        this.value = data.handleId;

                        this.findByIdFunction(this.value).then(
                            (reply: { [x: string]: any }) => {
                                this.selectedItem = reply;
                                this.selectedItemName = this.displayFilter
                                    ? this.displayFilter(reply)
                                    : reply[this.itemLabel];
                                this.value = reply[this.valueField];
                            }
                        );
                    }
                }
            );
        }
    }

    public displayFilter: (value: any) => string = (value) => {
        return value[this.itemLabel];
    };
}

export class MoleculeFormDropDownApiSearchComponent implements ng.IComponentOptions {
    public bindings = {
        additionalFieldsFunction: '<?',
        additionalFilters: '<',
        allowUndefined: '<?',
        callbackOnChange: '<?',
        disabled: '<',
        displayFilter: '<?',
        excludeIdsFromSearch: '<?',
        filterFields: '<',
        findByIdFunction: '<',
        isCustomisable: '<?customisable',
        isRequired: '<?',
        itemLabel: '@',
        listFunction: '<',
        numberOfItems: '@',
        objectIdField: '@?',
        onChangeDeferred: '<',
        owner: '<',
        placeholder: '@',
        searchMapping: '<?',
        type: '@',
        value: '=',
        valueField: '@?'
    };
    public controller =  MoleculeFormDropDownApiSearchController;
    public template = require('./drop-down-api-search.html');
}
