import ng from 'angular';

import { IGridArray, PanelHeaderData } from '@/atomic-components/molecules/panels';
import { UiLanguagesConst } from '@/configuration';
import { AuthContextService } from '@/services/auth-context';
import { NextcloudHelperService, UtilHelperService } from '@/services/helpers';
import { NavigationService } from '@/services/navigation';
import { StorageModelService } from '@/services/storage/storage-model';
import { ManagedApplicationApi } from '@/types';
import './storage-product-app-center.scss';
import { AsteriskNoteService, AsteriskNoteType } from '@/services/billing/asterisk-note-service';
import { AccountModelService } from '@/services';

export class TemplateStorageProductAppCenterController implements ng.IController {
    public static $inject: string[] = [
        '$state',
        '$translate',
        'asteriskNote',
        'navigation',
        'nextcloudHelper',
        'storageModel'
    ];

    public faqArticleIdList: string[] = [];
    public pageHeaderData: PanelHeaderData;

    public storageProduct: ManagedApplicationApi.Nextcloud;
    public _apps: ManagedApplicationApi.NextcloudApp[];

    public searchValue: string;
    public filterCategories: string[];
    public fitlerGrid: IGridArray;
    public fitlerTitle: string;

    public nextcloudHasUnfinishedJob = false;
    public nextcloudHasSupportJob = false;
    public nextcloudHasErrorJob = false;
    public showAsteriskText = false;
    public AsteriskNoteType: AsteriskNoteType;

    private _statePrefix: string;
    private enabledApps: string[];

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        public asteriskNote: AsteriskNoteService,
        private navigation: NavigationService,
        private nextcloudHelper: NextcloudHelperService,
        private storageModel: StorageModelService
    ) {}

    public $onInit = (): void => {
        this.AsteriskNoteType = AuthContextService.account.isCommercialCustomer
        ? AsteriskNoteType.VAT_EXCLUDED
        : AsteriskNoteType.VAT_INCLUDED;
        this.fitlerTitle = this.$translate.instant('TR_230321-ab1657_TR');
        void this.nextcloudHelper.checkNextcloudJobRunning(this.storageProduct.id).then((jobRunning: boolean) => {
            this.nextcloudHasUnfinishedJob = jobRunning;
        });
        const stateName = 'id.edit';
        if (this.$state.current.name.startsWith('nextcloud')) {
            this._statePrefix = 'nextcloud.';
        } else {
            this._statePrefix = 'storage.storage-products.';
        }
        this.pageHeaderData = {
            backwardLink: this._statePrefix + stateName,
            backwardText: this.$translate.instant('TR_271020-afb5d3_TR'),
            reloadState: true,
            panelHeaderRoute: '',
            panelIcon: '/assets/images/logos/nextcloud-bw.svg',
            panelIconPath: true,
            panelTitle: this.storageProduct.name
        };
        void this._initAppList().then(this._initCategoryGrid);
    };

    public cancel = (): void => {
        const stateName = 'id.edit';
        void this.navigation.go(this._statePrefix + stateName, null, {
            reload: true
        });
    };

    public productUpdateRequired = (app: ManagedApplicationApi.NextcloudApp): 'payed' | 'business' => {
        if (app.availableInNextcloudProducts && !app.availableInNextcloudProducts.includes(this.storageProduct.productCode)) {
            const onlyBusiness = app.availableInNextcloudProducts.every(el => el.indexOf('business') > -1);
            if (onlyBusiness) {
                return 'business';
            }
            return 'payed';
        }
        return;
    };

    public openApp = (appInfo: ManagedApplicationApi.NextcloudApp): void => {
        let stateName = 'id.app';
        if (appInfo.availableInNextcloudProducts) {
            stateName = 'id.upgrade';
        }
        void this.navigation.go(this._statePrefix + stateName, {
            appId: appInfo.id
        });
    };

    public showProductConfigMessage = (app: ManagedApplicationApi.NextcloudApp): boolean => {
        return this._isPaidApp(app);
    };

    public showInsufficientUserMessage = (app: ManagedApplicationApi.NextcloudApp): boolean => {
        return this._isUserDependentApp(app)
            && !this._isUserAdded(app);
    };

    public showConfigButton = (app: ManagedApplicationApi.NextcloudApp): boolean => {
        const additionalAppsWithConfigButton = ['talk_hpb'];
        return app.enabled && (app.requiresConfiguration || additionalAppsWithConfigButton.indexOf(app.id) >= 0);
    };

    public get apps(): ManagedApplicationApi.NextcloudApp[] {
        if (this._apps) {
            return this._apps
                .filter(this._searchFilter)
                .filter(this._categoryFilter)
                .sort((a, b) => UtilHelperService.sortByProperty(a, b, 'name'));
        }
        return [];
    }
    public set apps(value: ManagedApplicationApi.NextcloudApp[]) {
        this._apps = value;
    }

    private _initCategoryGrid = (): void => {
        this.fitlerGrid = [];
        this.apps?.forEach((app) => {
            app.categories?.forEach((category) => {
                const hasCategory = this.fitlerGrid.find((entry) => {
                    return entry.filterValue === category;
                });
                if (!hasCategory) {
                    this.fitlerGrid.push({
                        enabled: false,
                        filterValue: category,
                        text: category
                    });
                }
            });
        });
        this.fitlerGrid.push({
            enabled: false,
            filterValue: 'virtual_installed',
            text: this.$translate.instant('TR_230321-fed8d1_TR')
        });
        this.fitlerGrid.push({
            enabled: false,
            filterValue: 'virtual_all',
            text: this.$translate.instant('TR_260321-a1d191_TR')
        });
    };

    private _searchFilter = (app: ManagedApplicationApi.NextcloudApp): boolean =>
        app.name.toLocaleLowerCase().includes(this.searchValue.toLocaleLowerCase());

    private _categoryFilter = (app: ManagedApplicationApi.NextcloudApp): boolean => {
        if (!this.filterCategories) {
            return true;
        }
        if (this.filterCategories.length < 1 || this.filterCategories.includes('virtual_all')) {
            return true;
        }
        if (this.filterCategories.includes('virtual_installed')) {
            return this.enabledApps.includes(app.id);
        }
        if (this.filterCategories.every((category) => !app.categories?.includes(category))) {
            return false;
        }
        return true;
    };

    private _isPaidApp = (app: ManagedApplicationApi.NextcloudApp): boolean => {
        return app.productCode !== null;
    };

    private _isUserDependentApp = (app: ManagedApplicationApi.NextcloudApp): boolean => {
        if ([undefined, null].includes(this.storageProduct)) {
            return false;
        }
        switch (app.id) {
            case 'collabora':
                return true;
            case 'talk_hpb':
                return true;
        }
        return false;
    };

    private _isUserAdded = (app: ManagedApplicationApi.NextcloudApp): boolean => {
        if ([undefined, null].includes(this.storageProduct)) {
            return false;
        }
        switch (app.id) {
            case 'collabora':
                return this.storageProduct.officeUsers > 0;
            case 'talk_hpb':
                return this.storageProduct.talkUsers > 0;
        }
        return false;
    };

    private _initAppList = (): PromiseLike<void | ManagedApplicationApi.NextcloudApp> => {
        return this.storageModel
            .nextcloudAppList(this.storageProduct.id, UiLanguagesConst[AuthContextService.user.language])
            .then((res: { response: { data: ManagedApplicationApi.NextcloudApp[] } }) => {
                this.apps = res.response.data.map((app) => {
                    this.enabledApps = this.storageProduct.enabledApps;
                    app.enabled = this.enabledApps.includes(app.id);
                    return app;
                });

                this.showAsteriskText = this.apps.some((app) => app.productCode);
            });
    };
}

export class TemplateStorageProductAppCenterComponent implements ng.IComponentOptions {
    public bindings = {
        storageProduct: '<'
    };
    public controller = TemplateStorageProductAppCenterController;
    public template = require('./storage-product-app-center-template.html');
}
