import { ArticleSearchModelService } from './';

export interface UiFaqObject {
    articleId: string;
    alternativeTitle: string;
    isGranted: boolean;
    text: string;
    url: string;
}

export class FaqManagerService {
    public static $inject: string[] = ['articleSearchModel'];

    private static onResultCallback = (reply: any): UiFaqObject[] => {
        if ('error' in reply.data) {
            return [];
        }

        const articles: { [key: string]: any }[] = (reply.data as { [key: string]: any }[]);

        return articles.map(
            (article) => ({
                articleId: article.article_id,
                alternativeTitle: article.alternative_title,
                isGranted: true,
                text: article.title,
                url:`/helpdesk${article.article_url as string}`
            } as UiFaqObject)
        );
    };

    constructor(
        private articleSearchModel: ArticleSearchModelService
    ) {}

    public faqItemList(articleIdList: string[]): PromiseLike<UiFaqObject[]> {
        if (typeof(articleIdList) === 'undefined' || articleIdList.length === 0) {
            return this.articleSearchModel.getArticleMetaByCategory('/produkte/webhosting/')
            .then(FaqManagerService.onResultCallback);
        } else {
            return this.articleSearchModel.getArticleMetaByIdList(articleIdList)
            .then(FaqManagerService.onResultCallback);
        }
    }
}
