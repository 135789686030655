import * as ng from 'angular';
import { OrganismOverviewController, OverviewDataObject } from '../../../../../organisms/overview/overview';

export class MoleculeOverviewRowPanelDnsJobController implements ng.IController {
    public static $inject: string[] = [];

    public overviewData: OverviewDataObject;
    public OrganismOverview: OrganismOverviewController;
    public overviewLoad: (selection: any) => any;
    public internalPaginationLimit: number;
}

export class MoleculeOverviewRowPanelDnsJobComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelDnsJobController;
    public controllerAs = 'OverviewDnsCtrl';
    public template = require('./dns-job-row.html');
}
