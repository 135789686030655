import * as ng from 'angular';
import { UiSettingPaginationLimit } from '@/services';
import { FormDropDownItems } from '../forms/form-elements/drop-down/drop-down';

import './pagination.scss';

export class MoleculePaginationController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public paginationData: any;
    public limit: number;
    public limitItems: FormDropDownItems[];
    public overviewLoad: (selection: any) => any;
    public minimalVersion = false;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.limitItems = UiSettingPaginationLimit.map((limit) => {
            return { value: +limit, name: `${limit} / ${this.$translate.instant('TR_040419-e476cd_TR')}`};
        });
    }

    public paginationChangeCallback = (limit: number) => {
        this.paginationData.limit = limit;
        while ((this.paginationData.currentPage - 1) * limit >= this.paginationData.entries) {
            this.paginationData.currentPage --;
        }
        if (this.overviewLoad !== undefined) {
            this.overviewLoad(limit);
        }
    };
}

export class MoleculePaginationComponent implements ng.IComponentOptions {
    public bindings = {
        limit: '<',
        minimalVersion: '<?',
        overviewLoad: '<',
        paginationData: '<'
    };
    public controller =  MoleculePaginationController;
    public controllerAs = 'PaginationCtrl';
    public template = require('./pagination.html');
}
