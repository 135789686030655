import * as ng from 'angular';

export class MoleculePanelEditRowReadonlyController implements ng.IController {
    public col = false;
    public labelVCenter = false;
    public labelLeft = false;
    public modifier = '';
    public mediumSpaceBottom = false;
    public smallSpaceBottom = false;
}

export class MoleculePanelEditRowReadonlyComponent implements ng.IComponentOptions {
    public transclude = {
        label: '?rowLabel',
        value: 'rowValue'
    };
    public bindings = {
        col: '<?',
        labelVCenter: '<?',
        labelLeft: '<?',
        modifier: '@?',
        mediumSpaceBottom: '<?',
        smallSpaceBottom: '<?'
    };
    public controller = MoleculePanelEditRowReadonlyController;
    public template = require('./panel-edit-row-readonly.html');
}
