import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import { AuthContextService } from '../../../../../services';

export class OrganEditPanelDdnsHostUserController {
    public static $inject: string[] = ['$timeout', '$translate', 'authContext'];

    public user: any;
    public initalUser: any;
    public password: string;
    public availableUsers: any;
    public accessType = 'newUser';
    public chosenDdnsUserId: string;
    public editExistingUser = false;
    public showObjectId: boolean;
    public displayPasswordFormElements = true;
    public initalChosenDdnsUserId: string;
    public accessHeadings: string[] = [];
    public accessTypeButtons: any[] = [];
    public callbackOnCancel: () => void;
    public panelRight = {
        editPanelButton: false
    };
    public hasTheRightToCreateNewUsers = false;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {
        this.panelRight.editPanelButton = this.authContext.isGranted(UiRights.DNS_DDNSHOSTNAME_EDIT);
        this.hasTheRightToCreateNewUsers = this.authContext.isGranted(UiRights.DNS_DDNSCREDENTIALS_CREATE);
    }

    public $onInit() {
        if ([undefined].indexOf(this.user.id) < 0) {
            this.editExistingUser = true;
            this.displayPasswordFormElements = false;
            this.initalUser = ng.copy(this.user);
        }
        this.showObjectId = this.showObjectId || false;
        this.initalChosenDdnsUserId = ng.copy(this.chosenDdnsUserId);
        this.accessHeadings = [
            this.$translate.instant('TR_110119-76b4ba_TR'),
            this.$translate.instant('TR_100119-e937e9_TR')
        ];
        this.accessTypeButtons = [
            {value: 'existingUser', label: this.$translate.instant('TR_110119-d043dc_TR')},
            {value: 'newUser', label: this.$translate.instant('TR_110119-b61be5_TR')}
        ];
        if (this.availableUsers.length > 0) {
            this.accessType = 'existingUser';
        }
    }

    public cancel = () => {
        this.chosenDdnsUserId = ng.copy(this.initalChosenDdnsUserId);
        if (this.editExistingUser) {
            this.user = ng.copy(this.initalUser);
        }
    };

    public switchedFormMode = (newMode: string) => {
        if (newMode === 'newUser') {
            this.chosenDdnsUserId = '';
        } else {
            this.chosenDdnsUserId = ng.copy(this.initalChosenDdnsUserId);
        }
    };

    public clearPasswordField = (model: boolean) => {
        if (model === false) {
            this.password = '';
        }
    };
}

export class OrganEditPanelDdnsHostUserComponent implements ng.IComponentOptions {
    public bindings = {
        availableUsers: '=',
        showObjectId: '<?',
        chosenDdnsUserId: '=',
        password: '=',
        user: '='
    };
    public template = require('./ddns-host-user.html');
    public controller = OrganEditPanelDdnsHostUserController;
}
