import * as Types from '@/types';
import * as ng from 'angular';
import {
    AlertManagerService,
    ApiErrorModel,
    AuthContextService,
    TwoFaHelperService,
    UserModelService
} from '../../../../../services';
import {
    EditFormStatus,
    EditPanelStatus,
    MoleculeFormEditController,
    PanelType
} from '../../../../molecules/forms';

export enum twoFAFormStatus {
    BASE,
    REQUIRE,
    ENTER_PASSWORD_ACTIVATE,
    ENTER_PASSWORD_DEACTIVATE,
    ENTER_PASSWORD_OTP,
    CONFIRM_CODE,
    SHOW_PASSCODES
}

export class OrganEditPanelTwoFactorAuthenticationController implements ng.IController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'apiErrorModel',
        'twoFaHelper',
        'userModel'
    ];

    public $editForm: MoleculeFormEditController;
    public targetUser: Types.AccountApi.User;
    public panelRightSettings: any;
    public name = 'userTwoFA';
    public twoFAData: any;
    public readonly type = PanelType.USER_TWO_FA;

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private twoFaHelper: TwoFaHelperService,
        private userModel: UserModelService
    ) {}

    public $onInit() {
        this.targetUser = this.targetUser ? this.targetUser : AuthContextService.user;
        this.$editForm.register(this);
        this.deactivate();
    }

    public get status() {
        if (this.twoFAData.formStatus === twoFAFormStatus.BASE) {
            return EditPanelStatus.READONLY;
        } else {
            return EditFormStatus.EDITABLE;
        }
    }

    public get disableConfirmButton() {
        return this.$editForm.$invalid;
    }

    public deactivate = () => {
        this.twoFAData.formStatus = twoFAFormStatus.BASE;
    };

    public isActive = () => {
        return this.twoFAData.formStatus !== twoFAFormStatus.BASE;
    };

    public get disableButtons() {
        return !this.$editForm.canEnablePanel(this.name) || !this.twoFAData.canEdit;
    }

    public getTwoFAStatus = () => {
        switch (this.twoFAData.status) {
            case 'disabled':
                return this.$translate.instant('GENERAL.TWO-FA.STATUS.DISABLED');
            case 'active':
                return this.$translate.instant('GENERAL.TWO-FA.STATUS.ACTIVATED');
            case 'waitingForActivation':
            case 'required':
                return this.$translate.instant('TR_140119-23fb95_TR');
            default:
                break;
        }
    };

    public showTwoFAActivateButton = () => {
        return this.twoFAData.userId === AuthContextService.user.id
            && this.twoFAData.formStatus === twoFAFormStatus.BASE
            && this.twoFAData.status === 'disabled'
            && !this.disableButtons;
    };

    public showTwoFARequireButton = () => {
        return this.twoFAData.userId !== AuthContextService.user.id
            && this.twoFAData.formStatus === twoFAFormStatus.BASE
            && this.twoFAData.status === 'disabled'
            && !this.disableButtons;
    };

    public showTwoFADeactivateButton = () => {
        return this.twoFAData.formStatus === twoFAFormStatus.BASE
            && (['active', 'waitingForActivation', 'required'].indexOf(this.twoFAData.status) >= 0)
            && !this.disableButtons;
    };

    public showTwoFAConfirmButton = () => {
        return this.twoFAData.userId === AuthContextService.user.id
            && this.twoFAData.formStatus === twoFAFormStatus.BASE
            && this.twoFAData.status === 'waitingForActivation'
            && !this.disableButtons;
    };

    public isOtpGenerationRequest = () => {
        return this.twoFAData.formStatus === twoFAFormStatus.ENTER_PASSWORD_OTP;
    };

    public showOtpConfirmButton = () => {
        return this.twoFAData.formStatus === twoFAFormStatus.ENTER_PASSWORD_OTP
            && !this.disableButtons;
    };

    public showTwoFACancelButton = () => {
        return this.twoFAData.formStatus === twoFAFormStatus.ENTER_PASSWORD_ACTIVATE
            || this.twoFAData.formStatus === twoFAFormStatus.ENTER_PASSWORD_DEACTIVATE
            || this.twoFAData.formStatus === twoFAFormStatus.ENTER_PASSWORD_OTP;
    };

    public startActivatingTwoFA = () => {
        this.twoFAData.formStatus = twoFAFormStatus.ENTER_PASSWORD_ACTIVATE;
    };

    public startRequiringTwoFA = () => {
        this.twoFAData.formStatus = twoFAFormStatus.REQUIRE;
    };

    public startDeactivatingTwoFA = () => {
        this.twoFAData.formStatus = twoFAFormStatus.ENTER_PASSWORD_DEACTIVATE;
    };

    public startRequestingNewOtp = () => {
        this.twoFAData.formStatus = twoFAFormStatus.ENTER_PASSWORD_OTP;
    };

    public requestNewOtp = () => {
        this.userModel.userRegenerateTwoFactorAuthenticationOneTimePasswords(this.twoFAData.password)
            .then(
                (result) => {
                    this.twoFAData.password = '';
                    this.twoFAData.passcodes = result.responses;
                    this.twoFAData.formStatus = twoFAFormStatus.SHOW_PASSCODES;
                }
            );
    };

    public cancelTwoFA = () => {
        this.$editForm.resetValidations();
        this.twoFAData.formStatus = twoFAFormStatus.BASE;
        this.twoFAData.password = '';
    };

    public showTwoFAPasswordInput = () => {
        return this.twoFAData.formStatus === twoFAFormStatus.ENTER_PASSWORD_ACTIVATE
            || this.twoFAData.formStatus === twoFAFormStatus.ENTER_PASSWORD_DEACTIVATE
            || this.twoFAData.formStatus === twoFAFormStatus.ENTER_PASSWORD_OTP;
    };

    public get showRequireHint() {
        return this.twoFAData.formStatus === twoFAFormStatus.REQUIRE;
    }

    public confirmForm = () => {
        switch (this.twoFAData.formStatus) {
            case twoFAFormStatus.ENTER_PASSWORD_ACTIVATE:
                return this._enableTwoFactorAuthentication();

            case twoFAFormStatus.ENTER_PASSWORD_DEACTIVATE:
                return this._disableTwoFactorAuthentication();

            case twoFAFormStatus.ENTER_PASSWORD_OTP:
                return this.requestNewOtp();

            case twoFAFormStatus.REQUIRE:
                return this._requireTwoFactorAuthentication();

            default: break;
        }
    };

    public confirmTwoFACode = () => {
        this.apiErrorModel.destroyErrorList();

        return this.userModel.userActivateTwoFactorAuthentication(
            this.targetUser.id, this.twoFAData.confirmationCode
        ).then(
            (res) => {
                if (this.apiErrorModel.apiResponseHasError(res)) {
                    return Promise.reject(false);
                }

                this.twoFAData.passcodes = res.responses;
                this.twoFAData.formStatus = twoFAFormStatus.SHOW_PASSCODES;
                this.twoFAData.status = 'active';
                this.twoFAData.token = '';
                this.twoFAData.qrUrlString = '';
                this.twoFAData.confirmationCode = '';
                this.twoFAData.password = '';
                this.alertManager.success(
                    this.$translate.instant('TR_080619-a4ba7f_TR')
                );
                return res;
            },
            (err) => {
                this.twoFAData.confirmationCode = '';
                return Promise.reject(err);
            }
        );
    };

    private _enableTwoFactorAuthentication = () => {
        this.apiErrorModel.destroyErrorList();

        return this.twoFaHelper.enableGoogleAuthenticator(AuthContextService.user, this.twoFAData.password).then(
            (res) => {
                if (this.apiErrorModel.apiResponseHasError(res) || !res.issetQrCode || res.error != null) {
                    this.twoFAData.password = '';
                    return Promise.reject(res.error != null ? res.error : false);
                }

                this.twoFAData.password = '';
                this.twoFAData.token = res.qrString;
                this.twoFAData.formStatus = twoFAFormStatus.CONFIRM_CODE;
                this.twoFAData.qrUrlString = res.qrUrlString;
                this.twoFAData.status = 'waitingForActivation';
                return res;
            },
            (err) => {
                this.twoFAData.password = '';
                return Promise.reject(err);
            }
        );
    };

    private _disableTwoFactorAuthentication = () => {
        this.apiErrorModel.destroyErrorList();
        return this.userModel.userDisableTwoFactorAuthentication(
            this.targetUser.id, this.twoFAData.password
        ).then(
            (res) => {
                if (this.apiErrorModel.apiResponseHasError(res)) {
                    return Promise.reject(false);
                }

                this.twoFAData.formStatus = twoFAFormStatus.BASE;
                this.twoFAData.password = '';
                this.twoFAData.token = '';
                this.twoFAData.qrUrlString = '';
                this.twoFAData.status = 'disabled';
                this.alertManager.success(
                    this.$translate.instant('TR_080619-5e3a2c_TR')
                );
                return res;
            },
            (err) => {
                this.twoFAData.password = '';
                return Promise.reject(err);
            }
        );
    };

    private _requireTwoFactorAuthentication = () => {
        this.apiErrorModel.destroyErrorList();
        return this.userModel.userRequire2FAAuth(this.targetUser.id)
            .then(
                (res) => {
                    this.twoFAData.formStatus = twoFAFormStatus.BASE;
                    this.twoFAData.status = 'required';
                    this.alertManager.success(
                        this.$translate.instant('TR_080619-5e3a2c_TR')
                    );
                    return res;
                },
                (err) => {
                    this.twoFAData.formStatus = twoFAFormStatus.BASE;
                    return Promise.reject(err);
                }
            );
    };
}

export class OrganEditPanelTwoFactorAuthenticationComponent implements ng.IComponentOptions {
    public bindings = {
        panelRightSettings: '<',
        twoFAData: '=',
        targetUser: '<?'
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public template = require('./edit-panel-two-f-a.html');
    public controller = OrganEditPanelTwoFactorAuthenticationController;
}
