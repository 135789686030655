import * as ng from 'angular';
import * as Configurations from '../../../../../configuration';
import { UiRights } from '../../../../../configuration';
import {
    AuthContextService,
    DateWrapper,
    DomainReportsService,
    FileSaverService
} from '../../../../../services';

import './domain-reports.scss';

export class OrganismFormDomainReportsController implements ng.IController {
    public static $inject: string[] = ['$translate', 'authContext', 'domainReports', 'fileSaver'];

    public account: any;
    public currentDate: Date;
    public displayAccountDropdown = false;
    public dateValidationErrors: any[];
    public endDate: string;
    public endDateWrapper: DateWrapper;
    public includeSubaccounts = false;
    public minInflow = 0;
    public minOutflow = 0;
    public reportDropdownItems: any[];
    public reportType: string;
    public startDate: string;
    public startDateWrapper: DateWrapper;
    public configurations = Configurations;

    private fileNames = {
        count: 'domainCount.txt',
        jobs: 'domainJobs.txt',
        portfolio: 'domainPortfolio.txt',
        subAccountTurnover: 'subAccountDomainTurnover.txt',
        turnover: 'domainTurnover.txt'
    };

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private domainReports: DomainReportsService,
        private fileSaver: FileSaverService
    ) {
        this.currentDate = new Date();
        this.startDateWrapper = new DateWrapper(this.currentDate);
        this.endDateWrapper = new DateWrapper(this.currentDate);

        this.reportDropdownItems = [{
            name: this.$translate.instant('DOMAIN.REPORTS.TYPE.OPTIONS.PORTFOLIO'),
            value: 'portfolio'
        }, {
            name: this.$translate.instant('DOMAIN.REPORTS.TYPE.OPTIONS.COUNT'),
            value: 'count'
        }, {
            name: this.$translate.instant('DOMAIN.REPORTS.TYPE.OPTIONS.JOBS'),
            value: 'jobs'
        }, {
            name: this.$translate.instant('DOMAIN.REPORTS.TYPE.OPTIONS.TURNOVER'),
            value: 'turnover'
        }, {
            name: this.$translate.instant('DOMAIN.REPORTS.TYPE.OPTIONS.SUBACCOUNT-TURNOVER'),
            value: 'subAccountTurnover'
        }];

        this.setStartDate();
        this.setEndDate();
    }

    public $onInit = () => {
        this.displayAccountDropdown = this.authContext.isGranted(UiRights.ACC_SUBACCOUNT_LIST);
        if (this.displayAccountDropdown === false) {
            this.account = this.authContext.account.id;
        }
    };

    public setStartDate = () => {
        this.startDate = `${this.startDateWrapper.year}-${this.startDateWrapper.month}-${this.startDateWrapper.day}`;
    };

    public setEndDate = () => {
        this.endDate = `${this.endDateWrapper.year}-${this.endDateWrapper.month}-${this.endDateWrapper.day}`;
    };

    public get disableConfirm() {
        return this.account === undefined || this.account === null
        || this.reportType === undefined || this.reportType === null;
    }

    public get showDateSelect() {
        return ['jobs', 'turnover', 'subAccountTurnover'].indexOf(this.reportType) >= 0;
    }

    public confirm = () => {
        let promise;
        let startDate;
        let endDate;
        if (this.startDate !== undefined && this.endDate !== undefined) {
                startDate = new Date(this.startDate);
                endDate = new Date(this.endDate);
        }
        switch (this.reportType) {
            case 'portfolio':
                promise = this.domainReports.domainPortfolio(this.includeSubaccounts, this.account);
                break;
            case 'count':
                promise = this.domainReports.domainCount(this.includeSubaccounts, this.account);
                break;
            case 'jobs':
                promise = this.domainReports.domainJobs(this.includeSubaccounts, startDate, endDate, this.account);
                break;
            case 'turnover':
                promise = this.domainReports.domainTurnover(this.includeSubaccounts, startDate, endDate, this.account);
                break;
            case 'subAccountTurnover':
                promise = this.domainReports.subAccountDomainTurnover(
                    startDate,
                    endDate,
                    Number(this.minInflow),
                    Number(this.minOutflow),
                    this.account
                );
                break;
            default:
                return;
        }
        promise.then(
            (reply) => {
                this.fileSaver.saveAs(reply, this.fileNames[this.reportType]);
            }
        );
    };
}

export class OrganismFormDomainReportsComponent implements ng.IComponentOptions {
    public bindings = {};
    public controller = OrganismFormDomainReportsController;
    public template = require('./domain-reports.html');
}
