import { LinkListOverviewItem } from '../../atomic-components/molecules/links/link-list-overview/link-list-overview';
import {
    SelectFilterField,
    TextFilterField
} from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { UiRights } from '../../configuration';
import { OverviewModel } from '../abstract';
import { AuthContextService } from '../auth-context';
import { RequestCancellerService } from '../request-canceller';
import { WebhostingUserModelService } from './webhosting-user-model';

export class WebspaceUserOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        [
        'webhostingUserModel',
        'objectTypeFilter',
        'objectStatusFilter',
        '$translate'
        ]
    );

    public readonly service = 'webhosting';
    public readonly objectType = 'WebspaceUser';
    public readonly idField = 'userId';
    public readonly defaultFilterFields = ['UserName'];

    public activeMassAction: string | boolean = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};

    protected listCallbacks = [{callback: this.webhostingUserModel.list}];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private webhostingUserModel: WebhostingUserModelService,
        private objectTypeFilter,
        private objectStatusFilter,
        protected $translate: ng.translate.ITranslateService
    ) {
        super(RequestCanceller);

        // declare multi action availably methods
        this.actionLinksAvailabilities = {
            deletableUsers: this.deletableUsers
        };
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('userName', this.$translate.instant('TR_140119-680508_TR'))
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }

    public actionLinks = (params: { [param: string]: any }): LinkListOverviewItem[] => {
        return [
            {
                isGranted: AuthContextService.isGrantedAny([
                    UiRights.WEB_JOBS_LIST,
                    UiRights.WEB_OBJECT_LIST
                ]),
                linkList: [
                    {
                        group: 'redirect',
                        isGranted: AuthContextService.isGranted(UiRights.WEB_JOBS_LIST),
                        route: 'webhosting.webspaces.jobs',
                        text: this.$translate.instant('MENU.CONTEXT.JOBS')
                    },
                    {
                        group: 'redirect',
                        isGranted: AuthContextService.isGranted(UiRights.WEB_OBJECT_LIST),
                        route: 'webhosting.webspaces.overview',
                        text: this.$translate.instant('TR_080119-f4ef2a_TR')
                    },
                    {
                        group: 'redirect',
                        isGranted: AuthContextService.isGranted(UiRights.WEB_OBJECT_LIST),
                        route: 'webhosting.vhosts.overview',
                        text: this.$translate.instant('TR_080119-acb35a_TR')
                    }
                ],
                route: '',
                text: this.$translate.instant('TR_100119-a22157_TR'),
                type: 'multi'
            },
            {
                isGranted: AuthContextService.isGranted(UiRights.WEB_USER_DELETE),
                linkList: [{
                    group: 'delete',
                    groupName: this.$translate.instant('TR_140119-aee71e_TR'),
                    isClickable: 'deletableUsers',
                    isGranted: AuthContextService.isGranted(UiRights.WEB_USER_DELETE),
                    onclickMethode: () => this.activeMassAction = 'delete',
                    route: '',
                    text: this.$translate.instant('TR_140119-9787a9_TR')
                }],
                onlyOnExperView: true,
                route: '',
                text: this.$translate.instant('TR_140119-30a364_TR'),
                type: 'multi'
            },
            {
                isGranted: AuthContextService.isGranted(UiRights.WEB_USER_CREATE),
                route: params.bundle ? 'bundle.id.webspace-users.new' : 'webhosting.users.new',
                text: this.$translate.instant('TR_140119-9892db_TR')
            }
        ] as LinkListOverviewItem[];
    };

    /**
     *  Submit methods
     */
    public deleteSubmit(users) {
        const deleteUsers = Object.keys(this.selected)
        .map(
            (id) => {
                if (!this.selected[id]) {
                    return false;
                }
                return this.getObjectFromId(users, id);
            }
        )
        .filter((entry) => entry !== false);

        return this.webhostingUserModel.deleteUsers(deleteUsers);
    }

    /**
     *  Action availability definitions
     */
    public deletableUsers = (users) => {
        if (users.length === 0) {
            return false;
        }

        let selected = 0;
        const deleteable = Object.keys(this.selected).filter((elem) => {
            if (!this.selected[elem]) {
                return false;
            }
            selected += 1;

            return ['active'].indexOf(this.getObjectFromId(users, elem).status) >= 0;
        });

        return deleteable.length > 0 && deleteable.length === selected;
    };

    private getObjectFromId = (objects, objectId) => {
        return objects.filter((object) => {
            return object.id === objectId;
        })[0];
    };
}
