import * as ng from 'angular';
import { DateWrapper } from '../../../../../services';

export class MoleculeFormDateRangeSelectRowController implements ng.IController {
    public static $inject: string[] = ['$timeout', '$translate'];

    public maxYearsInFuture = '5';
    public minYear = (new Date()).getFullYear();
    public maxYear = (new Date()).getFullYear() + parseInt(this.maxYearsInFuture, 10);
    public onChangeCallback: () => any;
    public onCompleteCallback: () => any;
    public buttonModifier: string;
    public label: string;
    public dateValidationErrors: any[] = [];
    public endDateValidationOptions = {
        forbidPast: true
    };

    private _startDate: any = new DateWrapper();
    private _endDate: any = new DateWrapper();

    constructor(
        private $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public onChangeValidate = () => {
        this.$timeout(
            () => {
                this.validateDateRange();
                if (typeof (this.onChangeCallback) === 'function') {
                    this.onChangeCallback();
                }
            }
        );
    };

    private validateDateRange = () => {
        this.dateValidationErrors = this.dateValidationErrors.filter(
            (element) => {
                return element.text !== this.$translate.instant('TR_090119-9beabf_TR');
            }
        );
        if (this._startDate instanceof DateWrapper && this._endDate instanceof DateWrapper) {
            if (this._startDate.toUnixTimestamp() > this._endDate.toUnixTimestamp()) {
                this.dateValidationErrors.push({text: this.$translate.instant('TR_090119-9beabf_TR')});
            }
        }
    };

    get startDate(): any {
        return this._startDate;
    }

    set startDate(value: any) {
        if ([null, undefined].indexOf(value) >= 0) {
            this._startDate = new DateWrapper();
            this._startDate.setToday();
        } else if (value instanceof DateWrapper) {
            this._startDate = value;
        } else {
            this._startDate = new DateWrapper();
            this._startDate.dateObj = value;
        }
        this.onChangeValidate();
    }

    get endDate(): any {
        return this._endDate;
    }

    set endDate(value: any) {
        if ([null, undefined].indexOf(value) >= 0) {
            this._endDate = new DateWrapper();
            this._endDate.setToday();
        } else if (value instanceof DateWrapper) {
            this._endDate = value;
        } else {
            this._endDate = new DateWrapper(value);
        }
        this.onChangeValidate();
    }

}

export class MoleculeFormDateRangeSelectRowComponent implements ng.IComponentOptions {
    public bindings = {
        buttonModifier: '@',
        endDate: '=',
        label: '@',
        maxYearsInFuture: '@',
        onChangeCallback: '<',
        onCompleteCallback: '<',
        startDate: '='
    };
    public require = {
        $formEdit: '?^moleculeFormEdit'
    };
    public controller = MoleculeFormDateRangeSelectRowController;
    public template = require('./date-range-select-row.html');
}
