import * as ng from 'angular';

import { NavigationService, RestrictionHelperService } from '../../../../../services';

export class OrganEditPanelRestrictionsDisplayController {
    public static $inject: string[] = [
        '$translate',
        'restrictionHelper',
        'navigation'
    ];

    public apiObject: any;
    public objectType: any;
    public restrictionList: any;
    public hasRestrictions = false;
    public hasEditableRestriction = false;
    public hideEditButton = false;

    constructor(
        private $translate: ng.translate.ITranslateService,
        public restrictionHelper: RestrictionHelperService,
        private navigation: NavigationService
    ) {}

    public $onInit = () => {
        this.restrictionHelper.init(this.apiObject, this.objectType);
        this.restrictionList = this.restrictionHelper.getRestrictionList();
        this.hasRestrictions = this.restrictionHelper.hasRestrictions();
        this.hasEditableRestriction = this.restrictionHelper.userCanEditRestrictions();
        this._checkForMailboxOverQuota(this.apiObject);
    };

    public goToRestrictionsEdit = () => { // used in template
        const target = this.restrictionHelper.getBaseRouteAndParams();
        this.navigation.go(target.route + 'restrictions', target.routeParams);
    };

    private _checkForMailboxOverQuota = (apiObject) => {
        if (
            this.hasRestrictions
            && this.objectType === 'email'
            && this.apiObject.restrictions.length === 1
            && [null, undefined].indexOf(this.restrictionList.restrictions.overQuota) < 0
            && this.restrictionList.restrictions.overQuota[0].id === '1'
        ) {
            this.restrictionList.helpTexts.contactSupport = this.$translate.instant('TR_040220-6cff70_TR');
        }
    };
}

export class OrganEditPanelRestrictionsDisplayComponent implements ng.IComponentOptions {
    public bindings = {
        apiObject: '<',
        hideEditButton: '<',
        objectType: '@'
    };
    public template = require('./restrictions-display.html');
    public controller = OrganEditPanelRestrictionsDisplayController;
}
