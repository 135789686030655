import * as ng from 'angular';
import { ValidateMixedIpList } from '../../../../../services';

export class OrganNewPanelApiKeyInformationController {
    public static $inject: string[] = ['$translate'];

    public $newFormOrganism: any;
    public validationInstructions: { validator: ValidateMixedIpList; instructions: any }[];

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    $onInit() {
        this.validationInstructions = [{
            validator: new ValidateMixedIpList(this.$translate, true),
            instructions: null
        }];
    }
}

export class OrganNewPanelApiKeyInformationComponent implements ng.IComponentOptions {
    public bindings = {
        formOrganism: '='
    };
    public controllerAs = '$newPanelOrgan';
    public controller = OrganNewPanelApiKeyInformationController;
    public template = require('./api-key-informations.html');
}
