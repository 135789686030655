export const TemplateWidgetItemMapper = {
    nextcloud: [
        {
            attribute: {
                x: 0,
                y: 0
            },
            widgetModel: 'dashboardWidgetWelcomeModel'
        }, {
            attribute: {
                x: 3,
                y: 0
            },
            widgetModel: 'dashboardWidgetDomainSearchModel'
        }, {
            attribute: {
                x: 0,
                y: 1
            },
            widgetModel: 'dashboardWidgetProductNextcloudModel'
        }, {
            attribute: {
                x: 3,
                y: 1
            },
            widgetModel: 'dashboardWidgetSupportModel'
        },
        {
            attribute: {
                x: 2,
                y: 1
            },
            widgetModel: 'dashboardWidgetFaqNextcloudModel'
        },
        {
            attribute: {
                x: 0,
                y: 2
            },
            widgetModel: 'dashboardWidgetAdditionalLinksModel'
        },
        {
            attribute: {
                x: 2,
                y: 2
            },
            widgetModel: 'dashboardWidgetEmptyHintModel'
        }
    ]
};
