import { ModelHelper, UserRobotService } from '../';

import * as q from 'q';
import * as Types from '../../types';

export class ApiKeyModelService {
    public static $inject: string[] = ['userRobot'];

    constructor(
        private userRobot: UserRobotService
    ) {}

    public list = (
        limit?: number,
        page?: number,
        filter?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions
    ) => {
        sort = sort || {
            field: 'apiKeyName',
            order: 'ASC'
        };
        page = page || 1;
        return this.userRobot.listApiKeys(filter, limit, page, sort)
            .then(ModelHelper.returnListResponse);
    };

    public findOne = (id: string) => {
        return this.userRobot.listApiKeysWithoutPagination({field: 'apiKeyId', value: id.toString()}, 1, 1)
        .then(ModelHelper.returnFindOneResponse);
    };

    public findById = (ids: string[]) => {
        const filter: Types.Finding.Filter = {subFilterConnective: 'OR', subFilter: ids.map(this.idFilter)};
        return this.userRobot.listApiKeysWithoutPagination(filter, 0, 1)
        .then(ModelHelper.returnFindResponse);
    };

    public create = (apiKey: Types.AccountApi.ApiKey) => {
        this.filterEmptyIps(apiKey);
        return this.userRobot.createApiKey(apiKey).then((result) => result.response);
    };

    public update = (apiKey: Types.AccountApi.ApiKey) => {
        this.filterEmptyIps(apiKey);
        return this.userRobot.updateApiKey(apiKey).then((result) => result.response);
    };

    public delete(apiKeys: Types.AccountApi.ApiKey[]) {
        const promises = apiKeys.map(this.deleteOne);
        return q.all(promises);
    }

    private idFilter = (id: string): Types.Finding.Filter => {
        return { field: 'apiKeyId', value: id };
    };

    private filterEmptyIps = (key: Types.AccountApi.ApiKey) => {
        key.allowedIps = key.allowedIps.filter((value: string) => value !== '');
    };

    private deleteOne = (apiKey: Types.AccountApi.ApiKey) => {
        return this.userRobot.deleteApiKey(apiKey.id);
    };
}
