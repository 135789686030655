import { DnsTemplateModelService } from '../services';

export class DnsTemplateName {
    public static $inject: string[] = ['dnsTemplateModel'];

    public static Factory(dnsTemplateModel: DnsTemplateModelService) {
        const cache = {};

        const dnsTemplateNameFilter: any = (value) => {
            if ([undefined, null, ''].indexOf(value) >= 0) {
                return undefined;
            }

            if (cache[value]) {
                if (cache[value] !== 'waiting') {
                    return cache[value].name;
                }
                return undefined;
            }

            cache[value] = 'waiting';

            dnsTemplateModel.findOne(value)
            .then(
                (result) => {
                    cache[result.id] = result;
                },
                () => {
                    // Wenn der Request fehlschägt, versuchen wir es nicht erneut
                    cache[value] = 'x';
                }
            );

            return undefined;
        };

        dnsTemplateNameFilter.$stateful = true;

        return dnsTemplateNameFilter;
    }
}
