import * as ng from 'angular';
import { ProductHelperService, ProductsModelService } from '@/services';
import * as Types from '@/types';
import { InputOptionsType } from '@/types/input-types';
import { ViewTypes } from '@/types/view-types';

import { Reducer } from '@/services/helpers/property-reducer';

export class OrganismBundleWizardProductConfigWebspaceController {
    public static $inject: string[] = ['productsModel'];

    public metadata: ViewTypes.ProductConfigBundleObject;
    public vhostValidationErrors: string[] = [];
    public service = 'bundle';
    public databaseValidation: InputOptionsType = {
        maximum: 1,
        minimum: 0,
        allowEmpty: false
    };
    public databaseValidationChanged = false;
    public formCompleted = {
        // tslint:disable:no-empty
        bundleData:         false,
        webspace:           ()   => {},
        database:           ()   => {}
        // tslint:enable:no-empty
    };

    private _account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    private _createDatabase = true;
    private _selectedProductCode: string;

    constructor(
        private productsModel: ProductsModelService
    ) {}

    public get account(): Types.AccountApi.Account | Types.AccountApi.Subaccount {
        return this._account;
    }

    public set account(value: Types.AccountApi.Account | Types.AccountApi.Subaccount) {
        if (value && this.metadata && this.metadata.account) {
            this.metadata.account = value;
        }

        this._account = value;
    }

    public get createDatabase(): boolean {
        return this._createDatabase;
    }

    public set createDatabase(value: boolean) {
        if (value) {
            this.metadata.databaseAmount = 1;
        } else {
            this.metadata.databaseAmount = 0;
        }

        this.bundleDataChanged();
        this._createDatabase = value;
    }

    public set configCompleted(value) {
        return;
    }

    public get configCompleted() {
        let completed = true;

        for (const part in this.formCompleted) {
            if (!this.formCompleted[part]) {
                completed = false;
            }
        }

        return completed;
    }

    public get selectedProductCode(): string {
        return this._selectedProductCode;
    }
    public set selectedProductCode(value: string) {
        if (this._selectedProductCode !== value) {
            this._selectedProductCode = value;

            if ([undefined, null, ''].indexOf(value)
                && this.metadata !== undefined
            ) {
                this.metadata.productCode = value;
            }

            if (
                [undefined, null, ''].indexOf(this._selectedProductCode) < 0
            ) {
                // Database validation hack!
                this.databaseValidationChanged = true;
                this.getDatabaseCapacity();
            }
        }
    }

    public $onInit = () => {
        this._setMetadata();
    };

    public expertSettingsAreShown = (toggleStatus: boolean): void => {
        if (!toggleStatus) {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            this.formCompleted.database = () => {};
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            this.formCompleted.webspace = () => {};
        }

        this.bundleDataChanged();
    };

    public bundleDataChanged = () => {
        const dbAmount = parseInt('' + this.metadata.databaseAmount, 10);

        this.formCompleted.bundleData =
            Reducer.len(this.metadata.bundleName) > 0
            &&  dbAmount <= this.databaseValidation.maximum
            &&  dbAmount >= this.databaseValidation.minimum
            &&  Reducer.len(this.metadata.domain.nameUnicode) > 0
            &&  (Reducer.len(this.metadata.accesses.password) > 0 || this.metadata.accesses.useCustom);
    };

    public getDatabaseCapacity = () => {
        this.productsModel.findProduct(this._selectedProductCode).then((productResult) => {
            const specificationitems = Reducer.get(productResult, ['response', 'specificationItems']);

            const maxNumberOfDatabasesObject = ProductHelperService.wrapSpecificationItems(
                specificationitems
            ).maxNumberOfDatabases;

            if (this.databaseValidation) {
                this.databaseValidation.maximum = maxNumberOfDatabasesObject.intValue;

                if (parseInt('' + this.metadata.databaseAmount, 10) > this.databaseValidation.maximum) {
                    this.metadata.databaseAmount = this.databaseValidation.maximum;
                }
            }

            this.databaseValidationChanged = false;
        });
    };

    private _setMetadata = () => {
        this.metadata = {
            account: this._account,
            bundleName: '',
            productCode: undefined,
            productFamily: '',
            domain: {
                nameUnicode: '',
                authCode: '',
                vHostOnly: false
            },
            databaseAmount: 1,
            accesses: {
                password: '',
                useCustom: false,
                customAccesses: {
                    webspace: {
                        userId: '',
                        accessType: ViewTypes.UserAccessType.new,
                        name: '',
                        comments: '',
                        password: '',
                        sshKey: '',
                        homeDir: '',
                        accessLevel: {
                            sshAccess: false,
                            ftpAccess: false,
                            statsAccess: false,
                            ftpLimited: false
                        }
                    },
                    database: {
                        userId: '',
                        accessType: ViewTypes.UserAccessType.new,
                        name: '',
                        comments: '',
                        password: '',
                        accessLevel: {
                            read: false,
                            write: false,
                            schema: false
                        }
                    }
                }
            }
        };
    };
}

export class OrganismBundleWizardProductConfigWebspaceComponent implements ng.IComponentOptions {
    public bindings = {
        account: '<account',
        configCompleted: '=configCompleted',
        metadata: '=',
        selectedProductCode: '='
    };
    public template = require('./bundle-wizard-product-config-webspace.html');
    public controller = OrganismBundleWizardProductConfigWebspaceController;
}
