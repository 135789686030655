import * as Types from '@/types';
export const getDefaultCronjob = (): Types.WebhostingApi.CronJob => {
    return {
        dayOfMonth: 0,
        daypart: '5-9',
        parameters: [],
        schedule: 'daily',
        script: '',
        type: 'bash',
        weekday: '',
        hour:24,
        minute:60
    };
};