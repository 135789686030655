import * as ng from 'angular';

export class MoleculeButtonPayNowController implements ng.IController {
    public static $inject: string[] = [];

    public payNow: () => void;
    public loading: boolean;
    public disabled: boolean;
}

export class MoleculeButtonPayNowComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        disabled: '<',
        loading: '<',
        payNow: '<'
    };
    public controller =  MoleculeButtonPayNowController;
    public template = require('./button-pay-now.html');
}
