import * as ng from 'angular';

import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';

export class TemplateBundleRenameController {
    public static $inject: string[] = ['$translate', 'authContext'];

    public modifier = '';
    public pageHeaderData: PanelHeaderData;
    public bundle: any;
    public faqArticleIdList: string[] = [
        'webspace-expand-memory',
        'webspace-ftp-folders'
    ];

    public userPanelRight;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'bundle.id.dashboard',
            backwardText: this.$translate.instant('TR_090119-a32485_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: { bundleId: this.bundle.id },
            panelIcon: 'box-alt',
            panelTitle: this.$translate.instant('TR_090119-12d626_TR')
        };

        this.userPanelRight = {
            edit: {
                editPanelButton: this.authContext.isGranted(UiRights.BIL_BUNDLE_EDIT),
                overrideStorageQuota: this.authContext.isGranted(UiRights.BIL_BUNDLE_EDIT)
            }
        };
    };
}

export class TemplateBundleRenameComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<'
    };
    public controller = TemplateBundleRenameController;
    public controllerAs = '$TemplateBundleRenameCtrl';
    public template = require('./bundle-rename-template.html');
}
