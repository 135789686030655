import * as ng from 'angular';
import { ProductHelperService, ProductsModelService } from '@/services';
import { ViewTypes } from '@/types/view-types';

export class OrganismEmailWizardProductConfigMailingListController {
    public static $inject: string[] = [
        '$translate',
        'productsModel'
    ];

    public metadata: ViewTypes.ProductConfigMailingListObject;
    public accessModeComments: { owner: string; members: string; everyone: string };
    public replyToModeComments: { list: string; self: string; specifiedAddress: string };
    public maxMailingListMembers: number;
    public accessModeDropdownItems: { name: string; value: string }[];
    public replyToModeDropdownItems: { name: string; value: string }[];
    public dnsValidationMessage: string;

    private emailValidationErrors: any[] = [];
    private replyEmailValidationErrorList: any[] = [];
    private ownerEmailErrorList: any[] = [];
    private memberEmailErrorList: any[] = [];
    private formCompleted = {
        accountId: () => [undefined, null, ''].indexOf(this.metadata?.account.id) < 0,

        productCode: () => [undefined, null, ''].indexOf(this.metadata?.productCode) < 0,

        emailAddress: () => [undefined, null, ''].indexOf(this.metadata?.mailbox.emailAddress) < 0
            && this.emailValidationErrors.length === 0,

        mailboxName: () => [undefined, null, ''].indexOf(this.metadata?.mailbox.name) < 0,

        accessMode: () => [undefined, null].indexOf(this.metadata?.mailbox.accessMode) < 0,

        replyToMode: () => [undefined, null].indexOf(this.metadata?.mailbox.replyToMode) < 0,

        replyToEmailAddress: () => {
            if (this.metadata?.mailbox.replyToMode !== 'specifiedAddress') {
                return true;
            }

            return [undefined, null, ''].indexOf(this.metadata?.mailbox.replyToEmailAddress) < 0
                && this.replyEmailValidationErrorList.length === 0;
        },

        owners: () => false, // set in onInit

        members: () => false // set in onInit
    };

    public set configCompleted({}) {} /* tslint:disable-line:no-empty */ // used by parent
    public get configCompleted() { // used by parent
        for (const part in this.formCompleted) {
            if (!this.formCompleted[part]()) {
                return false;
            }
        }
        return true;
    }

    public get showMailinglistPanel() {
        return this.formCompleted.emailAddress();
    }

    constructor(
        private $translate: ng.translate.ITranslateService,
        private productsModel: ProductsModelService
    ) {}

    $onInit() {
        this.ownerEmailErrorList = [];
        this.formCompleted.owners = () => {
            return this.metadata?.mailbox?.owners?.length > 0
                && this.ownerEmailErrorList?.length === 0;
        };
        this.formCompleted.members = () => {
            return this.memberEmailErrorList?.length === 0;
        };
        this.metadata.mailbox = {
            emailAddress: '',
            spamFilter: {
                spamLevel: 'medium'
            },
            type: 'MailingList',
            allowHtmlMails: false,
            members: [],
            name: '',
            owners: [],
            subjectPrefix: ''
        };

        this.accessModeDropdownItems = [{
            name: this.$translate.instant('e23e4e3f-030f-4e2c-b826-ca73686abb06'),
            value: 'owner'
        }, {
            name: this.$translate.instant('69e776dd-a8bd-44fe-88f9-aa135410f470'),
            value: 'members'
        }, {
            name: this.$translate.instant('TR_110119-b212cd_TR'),
            value: 'everyone'
        }];
        this.replyToModeDropdownItems = [{
            name: this.$translate.instant('TR_110119-7b3b04_TR'),
            value: 'list'
        }, {
            name: this.$translate.instant('TR_110119-595d94_TR'),
            value: 'self'
        }, {
            name: this.$translate.instant('TR_110119-725707_TR'),
            value: 'specifiedAddress'
        }];
        this.accessModeComments = {
            owner: this.$translate.instant('TR_110119-9ebcac_TR'),
            members: this.$translate.instant('TR_110119-a349cd_TR'),
            everyone: this.$translate.instant('TR_110119-0007ee_TR')
        };
        this.replyToModeComments = {
            list: this.$translate.instant('TR_110119-52f6d6_TR'),
            self: this.$translate.instant('TR_110119-e2a108_TR'),
            specifiedAddress: this.$translate.instant('TR_110119-2bfc9a_TR')
        };
        this._checkProductSecificationItems();
    }

    private _checkProductSecificationItems = () => {
        if ([undefined, null, ''].indexOf(this.metadata.productCode) >= 0) {
            return;
        }
        this.productsModel.findOne(this.metadata.productCode).then(
            (reply) => {
                this.maxMailingListMembers = ProductHelperService.wrapSpecificationItems(
                    reply?.response?.specificationItems
                ).members?.intValue;
            }
        );
    };

    public get accessIsDefined() {
        return this.metadata.mailbox.accessMode !== undefined;
    }

    public get accesModeComment() {
        if (this.metadata.mailbox.accessMode === undefined) {
            return '';
        }
        return this.accessModeComments[this.metadata.mailbox.accessMode];
    }

    public get replyToModeIsDefined() {
        return this.metadata.mailbox.replyToMode !== undefined;
    }

    public get replyToModeComment() {
        if (this.metadata.mailbox.replyToMode === undefined) {
            return '';
        }
        return this.replyToModeComments[this.metadata.mailbox.replyToMode];
    }
}

export class OrganismEmailWizardProductConfigMailingListComponent implements ng.IComponentOptions {
    public bindings = {
        configCompleted: '=configCompleted',
        metadata: '='
    };

    public template = require('./email-wizard-product-config-mailing-list.html');
    public controller = OrganismEmailWizardProductConfigMailingListController;
}
