import {
    DashboardWidgetAccountBalanceModel,
    DashboardWidgetAdditionalLinksModel,
    DashboardWidgetDomainSearchModel,
    DashboardWidgetEmptyHintModel,
    DashboardWidgetFaqNextcloudModel,
    DashboardWidgetIncompleteUserProfileModel,
    DashboardWidgetOverviewContactVerificationModel,
    DashboardWidgetOverviewDomainTransfersOutModel,
    DashboardWidgetPrivacyNotifierModel,
    DashboardWidgetProductBundleModel,
    DashboardWidgetProductNextcloudModel,
    DashboardWidgetProductWebspaceModel,
    DashboardWidgetSmsValidationModel,
    DashboardWidgetSepaInfoModel,
    DashboardWidgetSepaVerificationNotifierModel,
    DashboardWidgetSupportModel,
    DashboardWidgetWelcomeModel
} from '@/atomic-components/organs/dashboard-widgets/';
import {
    DashboardWidgetModel,
    DashboardWidgetObject,
    DashboardWidgetType
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model';
import * as Configurations from '@/configuration';
import {
    AccountModelService,
    AlertManagerService,
    AuthContextService,
    DashboardHelperService,
    DepositModelService,
    FaqManagerService,
    NotificationsHelperService,
    SentryErrorEmitterService,
    UserModelService
} from '@/services';

export interface DashboardWidgetAttributObject {
    id: string;
    width: number;
    height: number;
    x?: number;
    y?: number;
    maxH?: number;
    maxW?: number;
    minH?: number;
    minW?: number;
    locked?: boolean | string;
    noResize?: boolean | string;
    noMove?: boolean | string;
    resizeHandle?: boolean;
    autoPosition?: boolean | string;
}

export interface DashboardWidgetGridStackNode extends DashboardWidgetAttributObject {
    el: HTMLDivElement;
}

export interface DashboardWidgetContainer {
    addWidget: (
        arg1: HTMLDivElement,
        arg2: DashboardWidgetAttributObject
    ) => void;
    batchUpdate: () => void;
    commit: () => void;
    compact: () => void;
    engine: {
        nodes: DashboardWidgetGridStackNode[];
    };
    removeAll: () => void;
    removeWidget: (arg1: string) => void;
}

export interface DashboardWidgetStorageData {
    templateId?: string;
    customerWidgets?: DashboardWidgetObject[];
    version?: string;
}

export interface DashboardWidgetObjectDefinition {
    demoData?: Record<string, unknown> | unknown[];
    manualAddable: boolean;
    userPermissions?: {
        isGranted?: string;
        isGrantedAll?: string[];
        isGrantedAny?: string[];
        notRootOrCompanyAccount?: boolean;
        checkCurrentUserAccountComplete?: boolean;
    };
    widgetDescription: string | string[];
    widgetObject: DashboardWidgetObject;
    widgetTitle: string;
    widgetType: DashboardWidgetType;
    systemNotification?: string;
}

export type DashboardWidgetModelStoreObject = {
    model: unknown;
    dependencies: unknown[];
};

export class DashboardWidgetModelOperator {
    public static $inject: string[] =  [
        '$translate',
        'accountModel',
        'alertManager',
        'dashboardHelper',
        'depositModel',
        'faqManager',
        'notificationsHelper',
        'userModel'
    ];

    public static dashboardWidgetModelStore: Record<string, DashboardWidgetModelStoreObject>;
    public static dashboardWidgetViewOptions = {
        column: 4,
        float: true,
        minRow: 1,
        disableDrag: true,
        disableResize: true,
        removable: false,
        staticGrid: true,
        cellHeight: 250,
        verticalMargin: 10
    };
    public static dashboardCustomWidgetEditOptions = {
        column: DashboardWidgetModelOperator.dashboardWidgetViewOptions.column,
        float: true,
        animate: true,
        cellHeight: DashboardWidgetModelOperator.dashboardWidgetViewOptions.cellHeight,
        placeholderClass: 'widget-placeholder-class'
    };
    public static dashboardSystemWidgetEditOptions = {
        column: DashboardWidgetModelOperator.dashboardWidgetViewOptions.column,
        animate: true,
        cellHeight: DashboardWidgetModelOperator.dashboardWidgetViewOptions.cellHeight,
        float: true
    };
    public dashboardWidgetModelVersion = '1.0'; // ??
    public dashboardWidgetsList: DashboardWidgetObjectDefinition[] = [];

    constructor(
        protected $translate: ng.translate.ITranslateService,
        protected accountModel: AccountModelService,
        protected alertManager: AlertManagerService,
        protected dashboardHelper: DashboardHelperService,
        protected depositModel: DepositModelService,
        protected faqManager: FaqManagerService,
        protected notificationsHelper: NotificationsHelperService,
        protected userModel: UserModelService
    ) {
        DashboardWidgetModelOperator.dashboardWidgetModelStore = {
            dashboardWidgetAccountBalanceModel: {
                model: DashboardWidgetAccountBalanceModel,
                dependencies: [this.depositModel]
            },
            dashboardWidgetAdditionalLinksModel: {
                model: DashboardWidgetAdditionalLinksModel,
                dependencies: []
            },
            dashboardWidgetDomainSearchModel: {
                model: DashboardWidgetDomainSearchModel,
                dependencies: []
            },
            dashboardWidgetEmptyHintModel: {
                model: DashboardWidgetEmptyHintModel,
                dependencies: []
            },
            dashboardWidgetOverviewContactVerificationModel: {
                model: DashboardWidgetOverviewContactVerificationModel,
                dependencies: []
            },
            dashboardWidgetOverviewDomainTransfersOutModel: {
                model: DashboardWidgetOverviewDomainTransfersOutModel,
                dependencies: []
            },
            dashboardWidgetProductBundleModel: {
                model: DashboardWidgetProductBundleModel,
                dependencies: []
            },
            dashboardWidgetProductNextcloudModel: {
                model: DashboardWidgetProductNextcloudModel,
                dependencies: []
            },
            dashboardWidgetFaqNextcloudModel: {
                model: DashboardWidgetFaqNextcloudModel,
                dependencies: [this.faqManager]
            },
            dashboardWidgetProductWebspaceModel: {
                model: DashboardWidgetProductWebspaceModel,
                dependencies: []
            },
            dashboardWidgetSmsValidationModel: {
                model: DashboardWidgetSmsValidationModel,
                dependencies: []
            },
            dashboardWidgetPrivacyNotifierModel: {
                model: DashboardWidgetPrivacyNotifierModel,
                dependencies: []
            },
            dashboardWidgetIncompleteUserProfileModel: {
                model: DashboardWidgetIncompleteUserProfileModel,
                dependencies: []
            },
            dashboardWidgetSepaInfoModel: {
                model: DashboardWidgetSepaInfoModel,
                dependencies: [this.accountModel, this.depositModel]
            },
            dashboardWidgetSepaVerificationNotifierModel: {
                model: DashboardWidgetSepaVerificationNotifierModel,
                dependencies: []
            },
            dashboardWidgetSupportModel: {
                model: DashboardWidgetSupportModel,
                dependencies: []
            },
            dashboardWidgetWelcomeModel: {
                model: DashboardWidgetWelcomeModel,
                dependencies: []
            }
        };
    }

    public generateWidgetModel = (
        widgetModelName: string
    ): Record<string, unknown> | null => {
        try {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const classFn = DashboardWidgetModelOperator.dashboardWidgetModelStore[widgetModelName].model;
            const constructorArgs = DashboardWidgetModelOperator
                .dashboardWidgetModelStore[widgetModelName]
                .dependencies;
            /* eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call */
            return new classFn(...constructorArgs);
        } catch (e) {
            SentryErrorEmitterService.sendSentryReport(
                'Dashboard widget class type of \''
                + widgetModelName
                + '\' is not in the dashboardWidgetModelStore',
                {
                    name: 'Method: DashboardWidgetOperator.generateWidgetModel',
                    code: 'Classname: ' + widgetModelName,
                    reason: e
                },
                { key: 'Dynamic dashboard' }
            )
            return null;
        }
    };

    public customStorageWidgetsInDashboard = (): DashboardWidgetStorageData => {
        const widgetObjects: DashboardWidgetObject[] = [];
        for (const widgetObject of this.dashboardWidgetsList) {
            widgetObjects.push(widgetObject.widgetObject);
        }

        return {
            customerWidgets: widgetObjects,
            version: this.dashboardWidgetModelVersion
        };
    };

    public saveCustomWidgetStorageDataToApi = (
        storageData?: DashboardWidgetStorageData,
        suppressToasterMessage?: boolean
    ): void => {
        suppressToasterMessage = suppressToasterMessage  || false;
        const widgetStorageDataToSave: DashboardWidgetStorageData = storageData
            ? storageData
            : this.customStorageWidgetsInDashboard();

        void this.userModel
            .userSetDashboardConfiguration(JSON.stringify(widgetStorageDataToSave))
            .then(
                (result: Record<string, unknown>) => {
                    if (suppressToasterMessage) {
                        return;
                    }
                    if (result.status as string !== 'error') {
                        void this.alertManager.success(
                            this.$translate.instant('TR_290121-d1c284_TR')
                        );
                    } else {
                        void this.alertManager.error(
                            this.$translate.instant('TR_290121-330085_TR')
                        );
                    }
                }
            );
    };

    public getCustomWidgetsFromApi = async (): Promise<DashboardWidgetObjectDefinition[]> => {
        const hasNextcloudTemplateId = await this.dashboardHelper.hasNextloudDashboardTemplate();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this.userModel.getUsersUiMainDashboardConfiguration()
            .then(
                (widgetSettingResponse: string) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    let storedDashboardWidgets = [undefined, null, '{}', ''].indexOf(widgetSettingResponse) < 0
                        ? JSON.parse(widgetSettingResponse)
                        : null;

                    // special case of (free) nextcloud template
                    if (!storedDashboardWidgets && hasNextcloudTemplateId) {
                        storedDashboardWidgets = {
                            templateId: 'nextcloud'
                        };
                    }

                    if (!storedDashboardWidgets) {
                        this.dashboardWidgetsList = [];
                    } else {
                        this.dashboardWidgetsList = this.getWidgetObjectDefinitions(storedDashboardWidgets);
                    }

                    return this.dashboardWidgetsList;
                }
            );
    };

    public getWidgetsFromTemplate = (templateId: string): DashboardWidgetObjectDefinition[] => {
        // set widgets from dashboard template
        const templateWidgets = {
            customerWidgets: Configurations.TemplateWidgetItemMapper[templateId],
            version: this.dashboardWidgetModelVersion
        };

        // Save template widgets in API
        this.saveCustomWidgetStorageDataToApi(templateWidgets, true);

        return this.convertWidgetStorageToDefinition(templateWidgets);
    };

    public getSystemWidgetsFromApi = (): PromiseLike<DashboardWidgetObjectDefinition[]> => {
        return this.notificationsHelper.getSystemNotifications()
            .then(
                (systemNotifications: string[]) => this.getSystemWidgetObjectDefinitions(systemNotifications)
            );
    };

    /**
     * Generates a list of DashboardWidgetObjectDefinitions from a list of widgetStoremItems that are passed in
     */
    public getWidgetObjectDefinitions = (
        widgetStorageItems: DashboardWidgetStorageData
    ): DashboardWidgetObjectDefinition[] => {
        return Object.prototype.hasOwnProperty.call(widgetStorageItems, 'templateId')
            ? this.getWidgetsFromTemplate(widgetStorageItems.templateId)
            : this.convertWidgetStorageToDefinition(widgetStorageItems);
    };

    public convertWidgetStorageToDefinition = (
        widgetStorageItems: DashboardWidgetStorageData
    ): DashboardWidgetObjectDefinition[] => {
        const customWidgetDefinitions: DashboardWidgetObjectDefinition[] = [];
        for (const widget of widgetStorageItems.customerWidgets) {
            const dashboardWidgetModel = this.getDashboardWidgetModelFromStore(widget?.widgetModel);
            if (dashboardWidgetModel) {
                // Overwrite the attribute definitions for the positioning of x and y using
                // the values stored in the API.
                dashboardWidgetModel.dashboardWidgetDefinition.widgetObject.attribute.x = widget.attribute.x;
                dashboardWidgetModel.dashboardWidgetDefinition.widgetObject.attribute.y = widget.attribute.y;
                customWidgetDefinitions.push(dashboardWidgetModel.dashboardWidgetDefinition);
            }
        }

        return customWidgetDefinitions;
    };

    /**
     * Generates a list of DashboardWidgetObjectDefinitions from a list of widgetStoremItems that are passed in
     */
    public getSystemWidgetObjectDefinitions = (
        systemNotifications: string[]
    ): DashboardWidgetObjectDefinition[] => {
        const systemWidgetDefinitions: DashboardWidgetObjectDefinition[] = [];
        for (const notification of systemNotifications) {
            const dashboardWidgetModel = this.getDashboardSystemWidgetModelFromStore(notification);
            if (dashboardWidgetModel) {
                systemWidgetDefinitions.push(dashboardWidgetModel.dashboardWidgetDefinition);
            }
        }

        return systemWidgetDefinitions;
    };

    /**
     * When the arrangement of the widgets changes or a new widget is added or removed,
     * the positions (attributes x and y) in the dashboardWigdestList are updated
     */
    public updateWidgetPositions = (items: DashboardWidgetAttributObject[]): void => {
        for (const item of items) {
            this.dashboardWidgetsList.some(
                (widget: DashboardWidgetObjectDefinition) => {
                    if (widget.widgetObject.attribute.id === item.id) {
                        widget.widgetObject.attribute.x = item.x;
                        widget.widgetObject.attribute.y = item.y;
                        return true;
                    }

                    return false;
                }
            );
        }
    };

    /**
     * This function add an object: DashboardWidgetObjectDefinition
     * to the dashboard list
     */
    public addWidgetToList = (widgetModel: DashboardWidgetObjectDefinition): void => {
        if (!widgetModel) {
            return;
        }

        this.dashboardWidgetsList.push(widgetModel);
    };

    /**
     * This function removes an object: DashboardWidgetObjectDefinition
     * from the dashboard list using a widgetId: DashboardWidgetObject (attribute.id)
     */
    public removeWidgetById = (widgetId: string): boolean => {
        this.dashboardWidgetsList = this.dashboardWidgetsList.filter(
            (widgetDefinition: DashboardWidgetObjectDefinition) => {
                return widgetDefinition.widgetObject.attribute.id !== widgetId;
            }
        );

        // Check if the widget was really removed
        const removedSuccessful = this.dashboardWidgetsList.every(
            (widgetDefinition: DashboardWidgetObjectDefinition) => {
                return widgetDefinition.widgetObject.attribute.id !== widgetId;
            }
        );

        if (!removedSuccessful) {
            void this.alertManager.error('Widget wurde nicht entfernt');
        }

        return removedSuccessful;
    };

    /**
     * This method checks the availability of a widget. The rights stored in the object
     * under dashboardWidgetDefinition.userPermissions are checked.
     * In addition, the 'additionalAvaiableConditionsFullfilled' method is executed (if defined),
     * which in turn returns a true or false.
     * This method is asyncron:
     */
    public checkAvailabilityOfWidgetModel = async (widgetModel: string): Promise<boolean> => {
        if ([undefined, null, ''].indexOf(widgetModel) >= 0) {
            return false;
        }

        const widgetModelObject = this.generateWidgetModel(widgetModel);
        if (!widgetModelObject) {
            return false;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        if (!DashboardWidgetModel.checkWidgetUserPermissions(widgetModelObject)) {
            return Promise.resolve(false);
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        return await widgetModelObject.additionalAvaiableConditionsFullfilled() as PromiseLike<boolean>;
    };

    /**
     * This method returns the corresponding class from the DashboardWidgetModelStore
     * based on the dashboardWidgetModelName
     */
    public getDashboardWidgetModelFromStore = (widgetModelName: string): DashboardWidgetModel => {
        let dashboardWidgetModel: DashboardWidgetModel;

        Object.values(DashboardWidgetModelOperator.dashboardWidgetModelStore).some(
            (widgetModelStore: DashboardWidgetModelStoreObject): boolean => {
                const storeWidgetModel = (widgetModelStore.model as DashboardWidgetModel)
                    .dashboardWidgetDefinition.widgetObject.widgetModel;
                if (storeWidgetModel === widgetModelName) {
                    dashboardWidgetModel = widgetModelStore.model as DashboardWidgetModel;
                    return true;
                }

                return false;
            }
        );

        return dashboardWidgetModel;
    };

    /**
     * This method returns the corresponding class from the DashboardWidgetModelStore
     * based on the dashboardWidgetSystemNotification (system widgets)
     */
    public getDashboardSystemWidgetModelFromStore = (systemNotification: string): DashboardWidgetModel => {
        let dashboardWidgetModel: DashboardWidgetModel;

        Object.values(DashboardWidgetModelOperator.dashboardWidgetModelStore).some(
            (widgetModelStore: DashboardWidgetModelStoreObject): boolean => {
                const storeWidgetDefinition = (widgetModelStore.model as DashboardWidgetModel)
                    .dashboardWidgetDefinition;
                if (storeWidgetDefinition.systemNotification === systemNotification) {
                    dashboardWidgetModel = widgetModelStore.model as DashboardWidgetModel;
                    return true;
                }

                return false;
            }
        );

        return dashboardWidgetModel;
    };
}
