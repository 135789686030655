import { IStorageItem } from '@/atomic-components/molecules/forms/form-elements/storage-quota-changer/storage-quota-changer';
import {
    AuthContextService,
    OrganizationModelService,
    ProductHelperService,
    ProductsModelService
} from '@/services';
import * as Types from '@/types';
import { ViewTypes } from '@/types/view-types';
import * as ng from 'angular';

export class OrganismEmailWizardProductConfigExchangeMailboxController {
    public static $inject: string[] = [
        '$translate',
        'organizationModel',
        'productsModel'
    ];

    public metadata: ViewTypes.ProductConfigExchangeMailboxObject;

    public formCompleted = {
        accountId:                  () => [undefined, null, ''].indexOf(this.metadata?.account.id) < 0,
        productCode:                () => [undefined, null, ''].indexOf(this.metadata?.productCode) < 0,
        emailAddress:               () => [undefined, null, ''].indexOf(this.metadata?.mailbox.emailAddress) < 0
            && this.emailValidationErrorList.length === 0,

        password:               () => [undefined, null, ''].indexOf(this.metadata?.password) < 0
            && this.isPasswordValid,
        firstName:                  () => [undefined, null, ''].indexOf(this.metadata?.mailbox.firstName) < 0,
        lastName:                   () => [undefined, null, ''].indexOf(this.metadata?.mailbox.lastName) < 0,
        organizationId:             () => [undefined, null, ''].indexOf(this.metadata?.organization.id) < 0
            || [undefined, null, ''].indexOf(this.metadata?.organization.name) < 0,

        organizationName:           () => [undefined, null, ''].indexOf(this.metadata?.organization.name) < 0
            || [undefined, null, ''].indexOf(this.metadata?.organization.id) < 0
    };
    public dnsValidationMessage: string;
    public includedStorage: number;
    public maxStorage: number;
    public additionalStorageItems = 0;
    public inPool = false;
    public isPasswordValid = false;
    public storageItem: IStorageItem;
    public emailValidationErrorList: any[] = [];

    private _additionalStorageList: string[] = [];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private organizationModel: OrganizationModelService,
        private productsModel: ProductsModelService
    ) {}

    public $onInit = () => {
        this.metadata.mailbox = {
            emailAddress: '',
            spamFilter: {
                spamLevel: 'medium'
            },
            type: 'ExchangeMailbox',
            firstName: '',
            lastName: '',
            storageQuota: 10240
        };
        this.metadata.organization = {
            id: null,
            name: '',
            comment: ''
        };
        this.storageItem = {} as IStorageItem;
        this._checkProductSecificationItems();
    };

    public get showStorageQuota() {
        return this.formCompleted.emailAddress() && this.formCompleted.password();
    }

    public get showOrganizationInfo() {
        return this.showStorageQuota;
    }

    public get adminToggleText() {
        if (this.metadata.isAdmin) {
            return this.$translate.instant('TR_110119-fd60df_TR');
        }

        return '';
    }

    public get additionalStorageList() { return this._additionalStorageList.length; }
    public set additionalStorageList(value: number) {
        const selectedStorageItems = Array.apply(null, Array(value)).map(() => this.storageItem) as IStorageItem[];
        this._additionalStorageList = selectedStorageItems.map((item) => item.productCode);
        this.metadata.additionalStorageInfo = {
            amount: value,
            storageItem: this.storageItem
        };
    }

    public set configCompleted({}) {} /* tslint:disable-line:no-empty */ // used by parent
    public get configCompleted() { // used by parent
        for (const part in this.formCompleted) {
            if (!this.formCompleted[part]()) {
                return false;
            }
        }
        return true;
    }

    public findOrganizations = async (
        limit?: number,
        page?: number,
        filter?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions
    ): Promise<{ data: any } | { data: any[] }> => {
        const completeFilter: Types.Finding.Filter = {
            subFilter: [],
            subFilterConnective: 'AND'
        };
        const accountIdFilter: Types.Finding.Filter = {
            field: 'AccountId',
            value: [undefined, null, AuthContextService.account.id].indexOf(this.metadata.account.id) >= 0
                ? AuthContextService.account.id
                : this.metadata.account.id
        };
        completeFilter.subFilter.push(accountIdFilter);
        if ([undefined, null].indexOf(filter) < 0) {
            completeFilter.subFilter.push(filter);
        }

        try {
            const organizationModelResponse = await this.organizationModel.list(limit, page, completeFilter, sort);
            const organizationDropdownItems = [];
            if ([undefined, null].indexOf(organizationModelResponse.data) === -1) {
                organizationModelResponse.data.map(
                    (organisation) => {
                        organizationDropdownItems.push({
                            id: organisation.id,
                            name: organisation.name
                        });
                    }
                );
            }
            if ([undefined, null].indexOf(filter) >= 0 || [undefined, null].indexOf(filter.value) >= 0) {
                organizationDropdownItems.push({
                    id: null,
                    name: this.$translate.instant('TR_110119-6f5901_TR')
                });
            }
            return Promise.resolve({ data: organizationDropdownItems });
        } catch (error) {
            return Promise.reject({ data: [] });
        }
    };

    public findOrganization = (id: string) => {
        return this.organizationModel.list(5, 1, { field: 'OrganizationId', value: id })
            .then((apiResponse) => apiResponse?.data ? apiResponse.data[0] : undefined);
    };

    private _checkProductSecificationItems = () => {
        this.productsModel.findOne(this.metadata.productCode).then(
            (reply) => {
                if (reply.response.specificationItems !== undefined) {
                    const specification = ProductHelperService.wrapSpecificationItems(
                        reply.response.specificationItems
                    );

                    this.includedStorage = specification.includedStorage?.intValue;
                    this.maxStorage = specification.maxStorage?.intValue;
                }
            }
        );
    };
}

export class OrganismEmailWizardProductConfigExchangeMailboxComponent implements ng.IComponentOptions {
    public bindings = {
        _additionalStorageList: '=additionalStorageList',
        configCompleted: '=configCompleted',
        metadata: '='
    };

    public template = require('./email-wizard-product-config-exchange-mailbox.html');
    public controller = OrganismEmailWizardProductConfigExchangeMailboxController;
}
