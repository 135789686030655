import * as ng from 'angular';

export class OrganPanelAppInstallDbSettingsController {
    public dbSettings: any[] = [];
    public serviceObject: any;
    public language: string;
    public bundleId: string;
    public accountId: string;
    public databaseRessource;

    public get panelCompleted() {
        return this.dbSettings.every((settings) => {
            return this.serviceObject[settings.key] !== undefined
                || this.serviceObject.appSettings[settings.key] !== undefined;
        });
    }

    public set panelCompleted(value: any) {
        return;
    }
}

export class OrganPanelAppInstallDbSettingsComponent implements ng.IComponentOptions {
    public bindings = {
        accountId: '<',
        bundleId: '<',
        databaseRessource: '<?',
        dbSettings: '<',
        language: '<',
        panelCompleted: '=?',
        serviceObject: '='
    };
    public controller = OrganPanelAppInstallDbSettingsController;
    public template = require('./database-settings.html');
}
