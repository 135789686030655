import * as ng from 'angular';

import { HandleConst } from '@/configuration/domain/handle';
import { addHiddenContactsFilter, DomainHandleModelService } from '../../../../../services';

export class MoleculeFormContactSelectController implements ng.IController {
    public static $inject: string[] = [
        '$translate',
        'domainHandleModel'
    ];

    public model: string;
    public overviewLoad: (selection: any) => any;
    public disabled = false;
    public inputId: string;
    public placeholder: string = this.$translate.instant('GENERAL.CONTACT');
    public clearable = false;

    private handleTypeMapping = {
        Account: 'Account',
        org: 'Firma',
        person: 'Person'
    };

    constructor(
        private $translate: ng.translate.ITranslateService,
        private domainHandleModel: DomainHandleModelService
    ) {}

    public findContact = (id: string) => {
        return this.domainHandleModel.findOne(id);
    };

    public handleDisplayFilter = (item) => {
        let handleName = item.handle + ' | ';

        if (['org', 'role'].indexOf(item.type) >= 0) {
            handleName += item.organization
            + ' ('
            + this.$translate.instant(HandleConst.HandleTypes.labels[item.type])
            + ')';
        } else {
            handleName += item.name
            + ' ('
            + this.$translate.instant(HandleConst.HandleTypes.labels[item.type])
            + ')';

            if (item.organization.length > 0) {
                handleName += ' | ' + item.organization;
            }
        }

        return handleName;
    };

    public findContacts = (limit?, page?, filter?, sort?) => {
        return this.domainHandleModel.list(limit, page, addHiddenContactsFilter(filter), sort).then(
            (reply) => {
                if ([undefined, null].indexOf(reply.data) === -1) {
                    reply.data.map(
                        (handle) => {
                            if (handle.name.substr(-1) !== ')') {
                                if ([undefined].indexOf(this.handleTypeMapping[handle.type]) === -1) {
                                    handle.name = handle.name + ' (' + this.handleTypeMapping[handle.type] + ')';
                                } else {
                                    handle.name = handle.name + ' (' + handle.type + ')';
                                }
                            }
                        }
                    );
                }

                return reply;
            }
        );
    };
}

export class MoleculeFormContactSelectComponent implements ng.IComponentOptions {
    public bindings = {
        clearable: '<',
        inputId: '@?',
        model: '=',
        overviewLoad: '<'
    };
    public controller =  MoleculeFormContactSelectController;
    public template = require('./contact-select.html');
}
