import * as ng from 'angular';
import * as Types from '../../../../../types';
import { ViewTypes } from '../../../../../types/view-types';

import { EmailComposerService } from '../../../../../services';

export class OrganismEmailWizardWrapperController {
    public static $inject: string[] = ['$timeout', 'emailComposer'];

    public outerAccount: Types.AccountApi.Account;
    public outerProductCode: string; // used in template
    public productFamily: string;
    public account: Types.AccountApi.Account;
    public productCode: string;

    public wizardComposerObject: Types.ViewTypes.WizardComposerObject = {
        apiObject: {},
        summaryObject: []
    };
    public viewType = 'config';

    constructor(
        private $timeout: ng.ITimeoutService,
        private emailComposer: EmailComposerService
    ) {}

    public loadSummaryView = (metadata: ViewTypes.ProductConfigEmailObjectGeneric) => { // used in template
        this.wizardComposerObject.summaryObject = [];
        switch (metadata.productFamily) {
            case 'imap-mailboxes':
                this.emailComposer
                    .buildEmailImapApiObject(metadata as ViewTypes.ProductConfigEmailObject)
                    .then((composerObject) => {
                        this.$timeout(() => {
                            this.viewType = 'summary';
                            this.wizardComposerObject = composerObject;
                        });
                    });
                break;

            case 'catchalls':
                this.emailComposer
                    .buildEmailCatchallApiObject(metadata as ViewTypes.ProductConfigCatchallObject)
                    .then((composerObject) => {
                        this.$timeout(() => {
                            this.viewType = 'summary';
                            this.wizardComposerObject = composerObject;
                        });
                    });
                break;

            case 'mailing-lists':
                this.emailComposer
                    .buildEmailMailingListApiObject(metadata as ViewTypes.ProductConfigMailingListObject)
                    .then((composerObject) => {
                        this.$timeout(() => {
                            this.viewType = 'summary';
                            this.wizardComposerObject = composerObject;
                        });
                    });
                break;

            case 'smtp-forwarders':
                this.emailComposer
                    .buildEmailSmtpForwarderApiObject(metadata as ViewTypes.ProductConfigSmtpForwarderObject)
                    .then((composerObject) => {
                        this.$timeout(() => {
                            this.viewType = 'summary';
                            this.wizardComposerObject = composerObject;
                        });
                    });
                break;

            case 'external-forwarders':
                this.emailComposer
                    .buildEmailExternalForwarderApiObject(metadata as ViewTypes.ProductConfigExternalForwarderObject)
                    .then((composerObject) => {
                        this.$timeout(() => {
                            this.viewType = 'summary';
                            this.wizardComposerObject = composerObject;
                        });
                    });
                break;

            case 'msexchange-mailboxes':
                this.emailComposer
                    .buildEmailExchangeMailboxApiObject(metadata as ViewTypes.ProductConfigExchangeMailboxObject)
                    .then((composerObject) => {
                        this.$timeout(() => {
                            this.viewType = 'summary';
                            this.wizardComposerObject = composerObject;
                        });
                    });
                break;

            default:
                throw new Error(`${metadata.productFamily} unknown productFamily!`);
        }
    };

    public loadConfirmView = () => { // used in template
        this.viewType = 'confirm';
    };
}

export class OrganismEmailWizardWrapperComponent implements ng.IComponentOptions {
    public bindings = {
        outerAccount: '<?account',
        outerProductCode: '<?productCode'
    };
    public template = require('./email-wizard-wrapper.html');
    public controller = OrganismEmailWizardWrapperController;
}
