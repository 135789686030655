import * as ng from 'angular';
import { OrganismOverviewController, OverviewDataObject } from '../../../../../organisms';

export class MoleculeOverviewRowPanelWebhostingVhostsController implements ng.IController {
    public static $inject: string[] = ['$state'];

    public overviewData: OverviewDataObject;
    public OrganismOverview: OrganismOverviewController;
    public overviewLoad: (selection: any) => any;
    public internalPaginationLimit: number;
    public sidePartEntry: string;
    public stateGlabals: any;

    constructor(
        private $state: ng.ui.IStateService
    ) {}

    public $onInit() {
        this.sidePartEntry = this.$state.current.name.split('.')[0];
        this.stateGlabals = this.$state.$current.locals.globals;
    }

    public isWebsite = (item: any) => {
        return item.locations.some(
            (location: any) => location.matchType === 'default' && location.locationType === 'generic'
        );
    };

    public get showAccounts() {
        return this.OrganismOverview.globals.showAccounts;
    }
}

export class MoleculeOverviewRowPanelWebhostingVhostsComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelWebhostingVhostsController;
    public controllerAs = 'OverviewWebhostingVhostsCtrl';
    public template = require('./webhosting-vhost-row.html');
}
