import { DnsTypes } from '@/configuration/dns/dns-zone';
import {
    ValidateCaa,
    ValidateCert,
    ValidateFqdn,
    ValidateIpv4,
    ValidateIpv6,
    ValidateNotEmpty,
    ValidateOpenpgpkey,
    ValidateSrv,
    ValidateSshfp,
    ValidateTlsa
} from '../../../../../services';
import { FormInputModelOptions } from '../../../../atoms/form-elements';

import ng from 'angular';

export class MoleculeInputTextDnsRecordContentController implements ng.IController {
    public static $inject: string[] = ['$timeout', '$translate'];

    public validationErrorList = [];
    public value: string;
    public placeholder: string;
    public callbackOnChange: (arg0: any) => any;
    public callbackOnBlur: (arg0: any) => any;
    public callbackOnBlurParams: any;
    public customCallbackOnEnter: () => {};
    public doNotSaveOnFormEnter: boolean;
    public disabled: boolean;
    public modelOptions: FormInputModelOptions;
    public registerToForm: boolean;
    public validationInstructions: any[];
    public options: any;
    public recordType: string;
    public inputId: string;
    public callbackOnClear: (arg0: any) => any;
    public callbackOnClearParams: any;
    public clearable: boolean;

    constructor(private $timeout: ng.ITimeoutService, private $translate: ng.translate.ITranslateService) {}

    public $onInit() {
        this.validationInstructions = [];
        this._setValidator();
    }

    public $onChanges(changes) {
        if (changes.recordType !== undefined && changes.recordType.currentValue !== undefined) {
            this.recordType = changes.recordType.currentValue;
            this._setValidator(true);
        }
    }

    public onPaste = (paste) => {
        if (this.recordType === DnsTypes.OPENPGPKEY) {
            this.$timeout(() => {
                this.value = paste.replace(/ /g, '');
            }, 50);
        }
    };

    private _setValidator = (onChange?: boolean) => {
        const allowEmpty = false;
        let validator = null;
        onChange = onChange || false;
        this.validationInstructions = [];

        const allowPlaceholder =
            this.options !== undefined && this.options.allowPlaceholder !== undefined
                ? this.options.allowPlaceholder
                : false;

        if (this.recordType === undefined) {
            return;
        }

        switch (this.recordType.toUpperCase()) {
            case 'A':
                validator = {
                    instructions: null,
                    validator: new ValidateIpv4(this.$translate, allowEmpty, allowPlaceholder)
                };
                break;

            case 'AAAA':
                validator = {
                    instructions: null,
                    validator: new ValidateIpv6(this.$translate, allowEmpty, allowPlaceholder)
                };
                break;

            case 'ALIAS':
            case 'CNAME':
            case 'MX':
            case 'NS':
            case 'PTR':
                validator = {
                    instructions: null,
                    validator: new ValidateFqdn(this.$translate, allowPlaceholder)
                };
                break;

            case 'CAA':
                validator = {
                    instructions: null,
                    validator: new ValidateCaa(this.$translate)
                };
                break;

            case 'CERT':
                validator = {
                    instructions: null,
                    validator: new ValidateCert(this.$translate)
                };
                break;

            case 'OPENPGPKEY':
                validator = {
                    instructions: null,
                    validator: new ValidateOpenpgpkey(this.$translate)
                };
                break;

            case 'SVR':
                validator = {
                    instructions: null,
                    validator: new ValidateSrv(this.$translate)
                };
                break;

            case 'SSHFP':
                validator = {
                    instructions: null,
                    validator: new ValidateSshfp(this.$translate)
                };
                break;

            case 'TLSA':
                validator = {
                    instructions: null,
                    validator: new ValidateTlsa(this.$translate)
                };
                break;

            case 'DS':
            case 'TXT':
                validator = {
                    instructions: null,
                    validator: new ValidateNotEmpty(this.$translate)
                };
                break;

            default:
                break;
        }

        if (validator !== null) {
            this.validationInstructions.push(validator);

            if (onChange && this.value?.length > 0) {
                this.validationErrorList = validator.validator.validate(this.value);
            }
        }
    };
}

export class MoleculeInputTextDnsRecordContentComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnBlur: '<',
        callbackOnBlurParams: '<',
        callbackOnChange: '<',
        callbackOnClear: '<',
        callbackOnClearParams: '<',
        clearable: '<',
        customCallbackOnEnter: '<',
        disabled: '<',
        doNotSaveOnFormEnter: '<',
        inputId: '@?',
        modelOptions: '<',
        options: '<',
        placeholder: '@',
        recordType: '<',
        registerToForm: '<',
        validationErrorList: '=?',
        value: '='
    };

    public controller = MoleculeInputTextDnsRecordContentController;
    public template = require('./input-text-with-validation-wrapper.html');
}
