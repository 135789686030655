import * as ng from 'angular';

export class MoleculeServiceObjectStatusRestorableController implements ng.IController {
    public static $inject: string[] = [];
}

export class MoleculeServiceObjectStatusRestorableComponent implements ng.IComponentOptions {
    public controller =  MoleculeServiceObjectStatusRestorableController;
    public template = require('./service-object-status-restorable.html');
}
