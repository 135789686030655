export const ErrorMapperGeneralConst = {
    // Allgemein
    // tslint:disable-next-line:max-line-length
    10104: /* translationId */ 'ERRORS.10104', // @todo bei Benutzeraktionen, bei denen eine Passwortangabe benötigt wird, gibt die API (bei falschem) Passwort, den gleichen Fehlercode, wie beim Loginfehler zurück. Also Benutzername oder Passwort nicht korrekt. Beim Login macht dies Sinn, da wir aufgrund der Fehlermeldung nicht wollen, dass man sich erschließen kann ob Passwort oder Benutzername nicht korrekt ist. Allerdings soll bei der Benutzeraktion klra sein, dass der benutzer sein Passwort falsch angegeben hat. Bis von der API kein anderer Fehlercode zurück kommt, geben wir an diesen Stellen eine eigene Fehlermeldung aus.
    10105: /* translationId */ 'ERRORS.10105',
    10204: /* translationId */ 'ERRORS.10204', // @todo entfernen wenn ACR-46 gefixt ist
    10901: /* translationId */ 'ERRORS.10901',
    10902: /* translationId */ 'ERRORS.10902',
    19999: /* translationId */ 'ERRORS.19999'
};
