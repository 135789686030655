export abstract class ApiObjectHandler<T> {
    constructor(
        protected wrappedObject: T
    ) {}

    public copy: () => T
    = () => this.wrappedObject = JSON.parse(JSON.stringify(this.wrappedObject));

    public get object(): T {
        return JSON.parse(JSON.stringify(this.wrappedObject));
    }
}
