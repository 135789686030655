import * as ng from 'angular';
import { AlertManagerService } from '../../../../../../services';
import { OrganismOverviewController } from '../../../../../organisms/overview/overview';

import './multi-contact-verification-action.scss';

export class MoleculePanelOverviewHeaderMultiContactVerificationActionController implements ng.IController {
    public static $inject: string[] = ['alertManager', '$translate'];

    public OrganismOverview: OrganismOverviewController;
    public serviceName: string;

    constructor(
        private alertManager: AlertManagerService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public abort = () => {
        this.OrganismOverview.overviewModel.activeMassAction = false;
    };

    public submit = () => {
        this.OrganismOverview.submitMassAction('resendEmailSubmit').then((result) => {
                if (result) {
                    this.alertManager.success(this.$translate.instant('TR_260219-15b66c_TR'));
                    this.abort();
                    this.OrganismOverview.overviewLoad();
                }
            }, (e) => {
                console.error('error', e);
            });
        };
}

export class MoleculePanelOverviewHeaderMultiContactVerificationActionComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public controller =  MoleculePanelOverviewHeaderMultiContactVerificationActionController;
    public template = require('./multi-contact-verification-action.html');
}
