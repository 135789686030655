import * as ng from 'angular';

export class MoleculeButtonDownloadPdfController implements ng.IController {
    public static $inject: string[] = [];

    public disabled: boolean;
    public downloadMethod: (arg0: any) => void;
    private item;

    public onClick = () => {
        this.downloadMethod(this.item);
    };
}

export class MoleculeButtonDownloadPdfComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        disabled: '<',
        downloadMethod: '<',
        item: '<'
    };
    public controller =  MoleculeButtonDownloadPdfController;
    public template = require('./button-download-pdf.html');
}
