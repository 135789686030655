import * as ng from 'angular';

export class OrganEditPanelBundleStorageController {
    public static $inject: string[] = ['$translate'];

    public cancel;
    public $editFormOrganism;
    public panelRight;
    public oldTotal: number;
    public panelTitle: string;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = () => {
        this.panelTitle = this.$editFormOrganism.webspace.bundleId
            ? this.$translate.instant('TR_090119-dfdbad_TR')
            : this.$translate.instant('TR_090119-917e77_TR');
        this.oldTotal = ng.copy(this.$editFormOrganism.webspace.storageQuota);
    };
}

export class OrganEditPanelBundleStorageComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormBundleStorage'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./bundle-storage.html');
    public controller = OrganEditPanelBundleStorageController;
}
