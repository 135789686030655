import * as ng from 'angular';
import './panel-show-all.scss';

export class MoleculePanelShowAllController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public route: string;
    public linkText: string;
    public iconName: string;
    public iconNamePath: boolean;

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.iconName = this.iconName || 'chevron-right';
        this.linkText = this.linkText || this.$translate.instant('TR_100119-f14f14_TR');
    }
}

export class MoleculePanelShowAllComponent implements ng.IComponentOptions {
    public bindings = {
        iconName: '@?',
        iconNamePath: '<?',
        linkText: '@',
        route: '@'
    };
    public transclude = true;
    public controller =  MoleculePanelShowAllController;
    public template = require('./panel-show-all.html');
}
