import * as ng from 'angular';
import './link-list-groups.scss';

import { NavigationService } from '../../../../services';
import { OrganismOverviewController } from '../../../organisms/overview';
import { LinkListItem } from '../link-list/link-list';

export class MoleculeLinkListGroupsOverviewController implements ng.IController {
    public static $inject: string[] = ['navigation'];

    public linkList: LinkListItem[];
    public linkListGroups = [];
    public OrganismOverview: OrganismOverviewController;
    private popupClose;

    constructor(
        private navigation: NavigationService
    ) {}

    public $onInit() {
        if (this.linkList !== undefined) {
            this.linkList.map((link: LinkListItem) => {
                link.icon = link.icon || 'chevron-right';
                return link;
            });
            this.setListGroups();
        }
    }

    public keyUpEventCheck = (event: KeyboardEvent, linkItem: any) => {
        if (event.key === 'Enter') {
            this.linkClick(linkItem);
        }
    };

    public linkClick(link) {
        if (!this.OrganismOverview.actionLinkClickable(link.isClickable)) {
            return;
        }

        if (link.route.length > 0) {
            if ([undefined, null].indexOf(link.routeParams) >= 0) {
                this.navigation.go(link.route);
            } else {
                this.navigation.go(link.route, link.routeParams);
            }
        } else if (typeof link.onclickMethode === 'function') {
            link.onclickMethode();
        }

        // has parent component popupClose?
        if (this.popupClose !== undefined) {
            this.popupClose();
        }
    }

    private setListGroups() {
        const others = [];
        this.linkList.map((link) => {
            if (link.group === undefined) {
                others.push(link);
            } else {
                this.addLink(link);
            }
            if (others.length > 0) {
                this.linkListGroups.push({
                    group: 'others',
                    groupName: 'Sonstiges',
                    items: others
                });
            }
        });
    }

    private addLink(link) {
        let groupIndex = this.existsGroup(link.group);
        if (groupIndex < 0) {
            this.linkListGroups.push({
                group: link.group,
                groupName: link.groupName,
                items: []
            });
            groupIndex = this.linkListGroups.length - 1;
            this.linkListGroups[groupIndex].items.push(link);
        } else {
            this.linkListGroups[groupIndex].items.push(link);
        }
    }

    private existsGroup(group) {
        let index = -1;

        if (this.linkListGroups !== undefined) {
            this.linkListGroups.some((listGroup, i) => {
                if (listGroup.group === group)  {
                    index = i;
                    return true;
                }
                return false;
            });
        }

        return index;
    }

    public checkGranted(item?: {isGranted?: boolean}) {
        return item?.isGranted === undefined || item?.isGranted;
    }

}

export class MoleculeLinkListGroupsOverviewComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        linkList: '<',
        popupClose: '&'
    };
    public controller =  MoleculeLinkListGroupsOverviewController;
    public template = require('./link-list-groups.html');
}
