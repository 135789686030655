import ng from 'angular';

export type ConvertAmountFilter = (amount: number, currency?: string) => string;

export class ConvertAmount {
    public static $inject: string[] = ['$translate'];

    public static Factory($translate: ng.translate.ITranslateService): ConvertAmountFilter {
        return (amount: number, currency?: string) => {
            const locale = ($translate.use() as string).replace('_', '-');

            if (currency) {
                return Intl.NumberFormat(locale, {
                    style: 'currency',
                    currency: currency,
                    currencyDisplay:'code'
                }).format(amount);
            }

            return Intl.NumberFormat(locale, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(amount);
        };
    }
}
