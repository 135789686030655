import * as ng from 'angular';
import { NavigationService } from '../../../../services';

export class MoleculeLinkHtmlWithIconController implements ng.IController {
    public static $inject: string[] = ['navigation'];

    public text: string;
    public iconName: string;
    public route = 'root';
    public modifier = '';
    public html = '';

    constructor(
        private navigation: NavigationService
    ) {}

    public keyUpEventCheck = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            this.gotoRoute();
        }
    };

    public gotoRoute = () => {
        this.navigation.go(this.route);
    };
}

export class MoleculeLinkHtmlWithIconComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        html: '@',
        iconName: '@',
        modifier: '@',
        route: '@'
    };
    public controller =  MoleculeLinkHtmlWithIconController;
    public template = require('./link-html-with-icon.html');
}
