import * as ng from 'angular';
import { LinkListItem } from '../../../atomic-components/molecules/links';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels';
import { DashboardOverviewObject } from '../../../atomic-components/organisms/overview';
import { UiRights } from '../../../configuration';
import { AuthContextService, FilterCacheService, StorageOverviewModel } from '../../../services';

export class TemplateStorageDashboardController {
    public static $inject: string[] = ['$translate', 'storageOverviewModel', 'filterCache'];

    public userHasRightManagedApplicationNextcloudList = AuthContextService.isGranted(
        UiRights.MANAGED_APPLICATION_NEXTCLOUD_LIST
    );
    public pageHeaderData: PanelHeaderData;
    public dashboardOverview: DashboardOverviewObject;
    public faqArticleIdList: string[] = [];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private storageOverviewModel: StorageOverviewModel,
        private filterCache: FilterCacheService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: '',
            backwardText: '',
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: '/assets/images/logos/nextcloud-bw.svg',
            panelIconPath: true,
            panelTitle: this.$translate.instant('TR_150519-73b99f_TR') // storage: TR_140519-724a96_TR
        };

        this.dashboardOverview = {
            overviewsData: [
                {
                    listOptions: {
                        limit: 6,
                        sort: {
                            field: 'NextcloudLastChangeDate',
                            order: 'DESC'
                        }
                    },
                    overviewModel: this.storageOverviewModel,
                    panelHeaderData: {
                        includeShowAllLink: true,
                        includeShowDetailsLink: false,
                        panelHeaderRoute: 'storage.storage-products.overview',
                        panelHeaderRouteParams: {},
                        panelIcon: '/assets/images/logos/nextcloud-bw.svg', // storage: save
                        panelIconPath: true, // storage: false
                        panelTitle: this.$translate.instant('TR_140519-4f0af6_TR') // storage: TR_140519-4ff911_TR
                    },
                    serviceItemList: []
                }
            ],
            panelData: {
                addtionalActionData: {
                    linkList: []
                },
                mainHeader: []
            },
            simpleFilter: this.filterCache.resolveSimpleFilter('Storage')
        };
        if (AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_JOBS_LIST)) {
            (this.dashboardOverview.panelData.addtionalActionData.linkList as LinkListItem[]).push(
                {
                    route: 'storage.storage-products.jobs',
                    routeParams: {},
                    text: this.$translate.instant('MENU.CONTEXT.JOBS')
                }
            );
        }
        if (AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_CREATE)) {
            (this.dashboardOverview.panelData.mainHeader as LinkListItem[]).push({
                route: 'storage.storage-products.new',
                text: this.$translate.instant('TR_150519-4b823f_TR')
            });
        }
    };
}

export class TemplateStorageDashboardComponent implements ng.IComponentOptions {
    public bindings = {};
    public controller = TemplateStorageDashboardController;
    public template = require('./storage-dashboard.html');
}
