import {
    DashboardWidgetModel,
    DashboardWidgetType
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model';
import {
    DashboardWidgetObjectDefinition
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model-operator';

export class DashboardWidgetWelcomeModel extends DashboardWidgetModel {
    public static $inject: string[] = DashboardWidgetModel.$inject.concat([]);

    public static dashboardWidgetDefinition: DashboardWidgetObjectDefinition = {
        demoData: {},
        manualAddable: false,
        userPermissions: null,
        widgetDescription: [
            /* translationId */ 'TR_010221-8a5711_TR'
        ],
        widgetObject: {
            attribute: {
                id: 'organDashboardWidgetWelcome',
                width: 2,
                height: 1,
                x: 0,
                y: 0,
                noResize: true,
                autoPosition: true
            },
            componentTagName: 'organ-dashboard-widget-welcome',
            widgetModel: 'dashboardWidgetWelcomeModel',
            version: '1.0'
        },
        widgetTitle: /* translationId */ 'TR_310321-159231_TR',
        widgetType: DashboardWidgetType.custom
    };

    public dashboardWidgetDefinition: DashboardWidgetObjectDefinition;

    constructor() {
        super();

        this.dashboardWidgetDefinition = DashboardWidgetWelcomeModel.dashboardWidgetDefinition;
    }
}
