import * as ng from 'angular';
import { CountryListService } from '../../../../../../services';
import * as Types from '../../../../../../types';
import { OrganismOverviewController, OverviewDataObject } from '../../../../../organisms';

export class MoleculeOverviewRowPanelSubAccountController implements ng.IController {
    public static $inject: string[] = ['countryList'];

    public overviewData: OverviewDataObject;
    public overviewLoad: (selection: any) => any;
    public internalPaginationLimit: number;
    public OrganismOverview: OrganismOverviewController;

    private countryList = null;

    constructor(private countryListService: CountryListService) {
        this.countryList = countryListService.getRawList();
    }

    public formatAddress = (subAccount: Types.AccountApi.Subaccount) => {
        return this.getStreet(subAccount) + this.getCity(subAccount) + this.getCountryName(subAccount.country);
    };

    private getCountryName = (id: string) => {
        if (id === null || id === undefined || this.countryList === null || this.countryList === undefined) {
            return '';
        }
        return this.countryList[id.toLowerCase()];
    };

    private getCity = (subAccount: Types.AccountApi.Subaccount) => {
        if (subAccount.city === null || subAccount.city === undefined || subAccount.city === '') {
            return '';
        }
        return subAccount.city + ', ';
    };

    private getStreet = (subAccount: Types.AccountApi.Subaccount) => {
        if (subAccount.street === null || subAccount.street === undefined || subAccount.street === '') {
            return '';
        }
        return subAccount.street + ', ';
    };

}

export class MoleculeOverviewRowPanelSubAccountComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelSubAccountController;
    public template = require('./sub-accounts-row.html');
}
