import * as ng from 'angular';
import {
    AuthContextService,
    MachineModelService,
    ResourceRobotService,
    WebspaceModelService
} from '../../../../../services';
import * as Types from '../../../../../types';
import { DomainTypes } from '../../../../organs';
import { UiRights } from '@/configuration';

export class OrganCreateConfigurationGeneralWebspaceSelectionController implements ng.IController {
    public static $inject: string[] = ['$state', '$translate', 'machineModel', 'resourceRobot', 'webspaceModel'];

    public accountId: string;
    public createWebspaceForDomain = false;
    public disabled = false;
    public internalSelectedWebspace: string| number;
    public noWebspacesAvailable = false;
    public placeholder: string;
    public selectedWebspace: Types.WebhostingApi.Webspace;
    public webspace: Types.WebhostingApi.Webspace;
    public webspaceOptions: any[] = [];
    public webspaceItems: any[]= [];

    private _bundle: Types.BundleApi.Bundle;
    private _domainType: DomainTypes;
    private _hasManageServers = false;
    private _selectedWebspace: Types.WebhostingApi.Webspace;
    private _webspaceRequired = false;
    private _webserverRessource: Types.ResourceApi.Webserver;
    private _tmpWebserverRessource: Types.ResourceApi.Webserver;
    private _hasManageServersWebserverResource: Types.ResourceApi.Webserver;
    private _webspaceOrig: Types.WebhostingApi.Webspace;
    private _loadingWebspaceItems = true;

    public constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private machineModel: MachineModelService,
        private resourceRobot: ResourceRobotService,
        private webspaceModel: WebspaceModelService
    ) {
        this.placeholder = this.$translate.instant('TR_141020-a5d2a8_TR');
    }

    public $onInit() {
        this._webspaceOrig = ng.copy(this.webspace);
        this._tmpWebserverRessource = ng.copy(this._webserverRessource);
        this._selectedWebspace = ng.copy(this.selectedWebspace);
        this._setRequiredOption();
        this.getWebspaceItems();
    }

    public $onChanges(changes) {
        if (changes.accountId && !changes.accountId.isFirstChange()) {
            this.selectedWebspace = null;
            this.webspace = null;
            this.getWebspaceItems();
        }
    }

    public $doCheck() {
        if (JSON.stringify(this.selectedWebspace) !== JSON.stringify(this._selectedWebspace)) {
            if (this.webspaceOptions.length === 0) {
                this.internalSelectedWebspace = null;
            } else if ([undefined, null].indexOf(this.selectedWebspace) >= 0) {
                this.internalSelectedWebspace = -1;
            } else {
                this.internalSelectedWebspace = this.selectedWebspace.id;
            }

            this.onChange(this.internalSelectedWebspace);
            this._selectedWebspace = this.selectedWebspace;
        }
    }

    public set loadingWebspaceItems({}) {} // tslint:disable-line:no-empty
    public get loadingWebspaceItems() {
        return this._loadingWebspaceItems;
    }

    public set noActiveManagedServerWebspaces({}) {} // tslint:disable-line:no-empty
    public get noActiveManagedServerWebspaces() {
        return this.$state.current.name.split('.')[0] === 'managed-servers'
            && !this.loadingWebspaceItems
            && this.noWebspacesAvailable;
    }

    public set getCurrentService({}) {} // tslint:disable-line:no-empty
    public get getCurrentService() {
        return this.$state.current.name.split('.')[0];
    }

    public set webspaceRequired({}) {} // tslint:disable-line:no-empty
    public get webspaceRequired() {
        return this._webspaceRequired;
    }

    public set showSingleDomainHint({}) {} // tslint:disable-line:no-empty
    public get showSingleDomainHint() {
        if (this.loadingWebspaceItems
            || ['bundle', 'domain'].indexOf(this.getCurrentService) >= 0
        ) {
            return false;
        }

        if ([DomainTypes.EXTERNAL, DomainTypes.SUBDOMAIN].indexOf(this._domainType) >= 0) {
            return false;
        }

        if (this.enableCreateWebspace && this.createWebspaceForDomain) {
            return false;
        }

        return [undefined, null].indexOf(this.internalSelectedWebspace) >= 0;
    }

    public set showHintOnVhostCreateWithoutWebspaces({}) {} // tslint:disable-line:no-empty
    public get showHintOnVhostCreateWithoutWebspaces() {
        if ([DomainTypes.EXTERNAL, DomainTypes.SUBDOMAIN].indexOf(this._domainType) < 0) {
            return false;
        }

        if (this._webspaceOrig) {
            return false;
        }

        return !this._webspaceAvailable();
    }

    public set enableWebspaceSelection({}) {} // tslint:disable-line:no-empty
    public get enableWebspaceSelection() {
        const minWebspaces = this._webspaceRequired
            ? 1
            : 2;

        return !this.loadingWebspaceItems
            && this.webspaceOptions?.length >= minWebspaces;
    }

    public set enableCreateWebspace({}) {} // tslint:disable-line:no-empty
    public get enableCreateWebspace() {
        return this._domainType === DomainTypes.REGISTER
            && !this.webspace
            && this.webspaceItems?.length === 0
            && this._hasManageServers;
    }

    public set activeWebspaceSelection({}) {} // tslint:disable-line:no-empty
    public get activeWebspaceSelection() {
        if (['bundle'].indexOf(this.getCurrentService) >= 0) {
            return false;
        }

        if (this._webspaceOrig || this.webspaceOptions?.length <= 1) {
            return false;
        }

        return true;
    }

    public set showPanel({}) {} // tslint:disable-line:no-empty
    public get showPanel() {
        return this.enableWebspaceSelection
            || this.enableCreateWebspace
            || this.noActiveManagedServerWebspaces
            || this.showSingleDomainHint;
    }

    public set domainTypeExternal({}) {} // tslint:disable-line:no-empty
    public get domainTypeExternal() {
        return this._domainType === DomainTypes.EXTERNAL;
    }

    public set domainTypeSubdomain({}) {} // tslint:disable-line:no-empty
    public get domainTypeSubdomain() {
        return this._domainType === DomainTypes.SUBDOMAIN;
    }

    public set showVhostHintNoWebspace({}) {} // tslint:disable-line:no-empty
    public get showVhostHintNoWebspace() {
        return (this.domainTypeExternal || this.domainTypeSubdomain)
            && !this.loadingWebspaceItems
            && this.noWebspacesAvailable;
    }

    public createWebspaceForDomainChanged = (value) => {
        this._webserverRessource = value
            ? this._hasManageServersWebserverResource
            : this._tmpWebserverRessource;
    };

    public onChange = (value: number|string) => {
        if (value !== -1) {
            const foundWebspace = this.webspaceItems.some((webspace) => {
                if (webspace.id === value) {
                    this.selectedWebspace = webspace;
                    return true;
                }
                return false;
            });

            if (!foundWebspace) {
                this.selectedWebspace = undefined;
            }
        } else {
            this.selectedWebspace = undefined;
        }
    };

    public getWebspaceItems = () => {
        if (this._webspaceOrig) {
            this._loadingWebspaceItems = false;
            this.webspaceOptions = this.webspaceItems = [this._webspaceOrig];
            this.selectedWebspace = this._webspaceOrig;
            this.internalSelectedWebspace = this._webspaceOrig.id;
            return;
        }

        this._loadingWebspaceItems = true;
        const filters = {
            subFilter: [
                { field: 'AccountId', value: this.accountId },
                { field: 'WebspaceStatus', value: 'active' }
            ],
            subFilterConnective: 'AND'
        };

        if (this.getCurrentService === 'managed-servers' && this._webserverRessource) {
            // Special filter for managed-servers area!
            filters.subFilter = [
                { field: 'AccountId', value: this.accountId },
                { field: 'webserverId', value: this._webserverRessource.id },
                { field: 'WebspaceStatus', value: 'active' }
            ];
        }

        this.webspaceModel.listWithoutPagination(100, 1, filters)
            .then((webspaceResult) => webspaceResult.data)
            .then(
                (webspaces: Types.WebhostingApi.Webspace[]) => {
                    this.noWebspacesAvailable = webspaces?.length === 0;
                    if (!this.noWebspacesAvailable) {
                        this._setWebspaceItems(webspaces);
                        if (!AuthContextService.isGranted(UiRights.MACHINE_VM_LIST)) {
                            return null;
                        }
                        return this.machineModel.listWithoutPagination(
                            1,
                            null,
                            {
                                subFilter: [
                                    { field: 'virtualMachineManagementType', value: 'platform' },
                                    { field: 'accountId', value: this.accountId }
                                ],
                                subFilterConnective: 'AND'
                            }
                        ).then((apiResponse) => apiResponse?.data?.length > 0 ? apiResponse.data[0] : null);
                    }

                    this.webspaceOptions = this.webspaceItems = [];
                    return false;
                }
            )
            .then(
                (managedServers) => {
                    if (managedServers) {
                        return this.resourceRobot.webserversFind(
                            { field: 'virtualMachineId', value: managedServers.id },
                            1,
                            null,
                            null,
                            true
                        ).then((res) => {
                            return res.response?.data?.length > 0 ? res.response.data[0] : null;
                        });
                    }

                    return managedServers;
                }
            )
            .then(
                (resourceRes) => {
                    if (resourceRes) {
                        this._hasManageServersWebserverResource = resourceRes ? resourceRes : null;
                        this._hasManageServers = resourceRes !== null;
                    } else {
                        this._hasManageServersWebserverResource = null;
                        this._hasManageServers = false;
                    }
                    this._loadingWebspaceItems = false;
                }
            );
    };

    private _webspaceAvailable = () => {
        if (!this.webspaceOptions) {
            return false;
        }

        return this.webspaceOptions.filter(
            (webspace) => {
                // -1 as number is the default value of 'no allocate webspace
                return typeof(webspace.value) !== 'number';
            }
        ).length > 0;
    };

    private _setWebspaceItems = (items) => {
        this.webspaceItems = [];

        if (this.getCurrentService === 'domains') {
            return;
        }

        if (this.getCurrentService === 'bundle')  {
            items.some((item) => {
                if (item.bundleId === this._bundle?.id) {
                    this.internalSelectedWebspace = item.id as string;
                    this.selectedWebspace = item;
                    return true;
                }

                return false;
            });
            return;
        }

        if (this.getCurrentService === 'managed-servers' && items.length === 1) {
            this._webspaceOrig = items[0];
            this.selectedWebspace = this._webspaceOrig;
        }

        this.webspaceOptions =  items
            .map(
                (webspace) => {
                    this.webspaceItems.push(webspace);
                    return {
                        name: webspace.name,
                        value: webspace.id
                    };
                }
            );

        if (!this._webspaceRequired) {
            this.webspaceOptions.unshift({ name: this.$translate.instant('TR_260220-2fffcf_TR'), value: -1});
            this.internalSelectedWebspace = this.webspaceOptions?.length === 1
                ? this.webspaceOptions[0].value as string
                : this.webspaceOptions[1].value as string;
        } else {
            this.internalSelectedWebspace = this.webspaceOptions?.length > 0
                ? this.webspaceOptions[0].value as string
                : null;
        }

        switch (this.getCurrentService) {
            case 'domain':
                this.internalSelectedWebspace = this._webspaceRequired
                    ? this.webspaceOptions[0].value as string
                    : null;
                break;
        }

        this.onChange(this.internalSelectedWebspace);
    };

    private _setRequiredOption = () => {
        if ([DomainTypes.EXTERNAL, DomainTypes.SUBDOMAIN].indexOf(this._domainType) >= 0) {
            this._webspaceRequired = true;
        }
        const routeParts = this.$state.current.name.split('.');
        if (!this._webspaceRequired) {
            switch (this.getCurrentService) {
                case 'bundle':
                case 'managed-servers':
                    this._webspaceRequired = true;
                    break;
                case 'domains':
                    this._webspaceRequired = false;
                    break;
                case 'webhosting':
                    if (routeParts[1] === 'webspaces' && routeParts?.length >= 3) {
                        this._webspaceRequired = routeParts[2] === 'id';
                    } else {
                        this._webspaceRequired = routeParts[1] === 'id';
                    }
                    break;
                default:
                    // nothing to do here
            }
        }
    };
}

export class OrganCreateConfigurationGeneralWebspaceSelectionComponent implements ng.IComponentOptions {
    public bindings = {
        _bundle: '<?bundle',
        _domainType: '<?domainType',
        _webspaceRequired: '<?',
        _webserverRessource: '=?webserverRessource',
        accountId: '<?',
        _webspaceOrig: '=?webspaceOrig',
        createWebspaceForDomain: '=',
        disabled: '<?',
        noActiveManagedServerWebspaces: '=?',
        selectedWebspace: '=?',
        showPanel: '=?',
        webserverId: '=',
        webspace: '<'
    };

    public template = require('./webspace-selection.html');
    public controller =  OrganCreateConfigurationGeneralWebspaceSelectionController;
}
