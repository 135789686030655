import { CryptoToolsService } from '../ssl/crypto-tools';
import { Validator } from './abstract-validator';

export class SslCertValidator extends Validator {
    public static $inject: string[] = ['$translate', 'cryptoTools'];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private cryptoTools: CryptoToolsService
    ) {
        super();
    }

    public validate = (value: string) => {
        const validCert = this.cryptoTools.decodeCertificate(value);
        if (!validCert) {
            // to do --
            return [{ text: this.$translate.instant('TR_170119-e61057_TR') }];
        }

        return [];
    };
}
