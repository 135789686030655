export class MachineTotalDiskSpace {
    public static $inject: string[] = [];

    public static Factory() {
        const machineTotalDiskSpace: any = (machine: any) => machine.disks
            .map((disk: { size: any }) => disk.size)
            .reduce((a: number, b: number) => a + b, 0) / 1024;

        return machineTotalDiskSpace;
    }
}
