import * as ng from 'angular';
import { InformationNodeObject, OrganismBillingPaymentOptionsController } from '@/atomic-components';
import { AuthContextService, DepositModelService } from '@/services';
import * as Types from '@/types';

export interface PaymentMethodOption {
    type: string;
    label: string;
}
export class OrganEditPanelBillingPaymentMethodController {
    public static $inject: string[] = ['$translate', 'depositModel'];

    public cancel: () => void;
    public $editFormOrganism: OrganismBillingPaymentOptionsController;
    public panelRight: unknown;
    public paymentMethods: PaymentMethodOption[] = [];
    public blockPaymentChange: boolean;
    public initialPaymentType: string;
    public latestBankAccount: Types.BillingApi.BankAccount;
    public parentInformation: Types.AccountApi.AccountOrganizationInformation;
    public prepaidIsAvailable = false;
    public paymentNoticeForGovernmentInstitutions: InformationNodeObject;

    private _pendingSepaVerification = false;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private depositModel: DepositModelService
    ) {
        this.paymentNoticeForGovernmentInstitutions = {
            headline: this.$translate.instant('TR_030321-523a0f_TR'),
            text: [this.$translate.instant('TR_030321-5f427b_TR')]
        };
    }

    public $onInit(): void {
        this.parentInformation = AuthContextService.accountOrganizationInformation;

        void this.depositModel.paymentMethodAvailability().then(
            (paymentMethodAvailability: {paymentMethod: string; available: boolean}[]) => {
                const availableMethods = paymentMethodAvailability
                    .filter((method) => method.available)
                    .map((method) => method.paymentMethod);

                if (availableMethods.some(
                    (method) => ['stripe', 'paypal'].indexOf(method.toLowerCase()) >= 0
                )) {
                    this.paymentMethods = [{type: 'prepaid', label: this.$translate.instant('TR_090119-296e1a_TR')}];
                    this.prepaidIsAvailable = true;
                }

                if (availableMethods.some(
                    (method) => ['directdebit'].indexOf(method.toLowerCase()) >= 0
                )) {
                    this.paymentMethods.push({type: 'postpaid', label: this.$translate.instant('TR_090119-e5076a_TR')});
                }

                if (this.$editFormOrganism.paymentType === 'invoice') {
                    this.paymentMethods.push({type: 'invoice', label: this.$translate.instant('TR_060819-c8af3e_TR')});
                }
            }
        );

        if ([undefined].indexOf(this.blockPaymentChange) >= 0) {
            this.blockPaymentChange = false;
        }

        this.initialPaymentType = ng.copy(this.$editFormOrganism.paymentType);
    }

    public get selectedPaymentMethod(): string {
        if (this.$editFormOrganism.paymentType === 'postpaid') {
            return this.$translate.instant('TR_090119-e5076a_TR');
        } else if (this.$editFormOrganism.paymentType === 'invoice') {
            return this.$translate.instant('TR_060819-c8af3e_TR');
        } else if (this.$editFormOrganism.paymentType === 'prepaid') {
            return this.$translate.instant('TR_090119-296e1a_TR');
        } else {
            return this.$editFormOrganism.paymentType;
        }
    }

    public get showSwitchFromPostToPrepaidWarning(): boolean {
        return this.initialPaymentType === 'postpaid'
            && this.$editFormOrganism.paymentType === 'prepaid'
            && this.prepaidIsAvailable;
    }

    public get showPrePaidInfoPanel(): boolean {
        return this.$editFormOrganism.paymentType === 'prepaid'
            && this.parentInformation !== undefined
            && [undefined, null, ''].indexOf(AuthContextService.account.customerNumber) < 0;
    }

    public set pendingSepaVerification({}) {}
    public get pendingSepaVerification(): boolean {
        return this._pendingSepaVerification;
    }
}

export class OrganEditPanelBillingPaymentMethodComponent implements ng.IComponentOptions {
    public bindings = {
        _pendingSepaVerification: '<?pendingSepaVerification',
        blockPaymentChange: '<?',
        cancel: '<',
        latestBankAccount: '<',
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismBillingPaymentOptions'
    };
    public controllerAs = '$editPanelOrgan';
    public controller = OrganEditPanelBillingPaymentMethodController;
    public template = require('./payment-method.html');
}
