import * as ng from 'angular';
import * as q from 'q';
import { LinkListOverviewItem } from '../../atomic-components/molecules/links/link-list-overview/link-list-overview';
import {
    AccountFilterField,
    ApiSelectFilterField,
    SelectFilterField,
    TextFilterField
} from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { UiRights } from '../../configuration';
import { OverviewModel } from '../abstract';
import { AuthContextService } from '../auth-context';
import { RequestCancellerService } from '../request-canceller';
import { WebhostingUserModelService } from './webhosting-user-model';
import { WebspaceModelService } from './webspace-model';

export class WebspaceOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        [
            '$state',
            '$translate',
            'objectStatusFilter',
            'webhostingUserModel',
            'webspaceModel'
        ]
    );

    public readonly service = 'webhosting';
    public readonly objectType = 'Webspace';
    public readonly idField = 'webspaceId';
    public readonly defaultFilterFields = ['WebspaceName', 'WebspaceWebspaceName'];

    public activeMassAction: string | boolean = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};

    protected listCallbacks = [];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $state: ng.ui.IStateService,
        protected $translate: ng.translate.ITranslateService,
        private objectStatusFilter,
        private webhostingUserModel: WebhostingUserModelService,
        private webspaceModel: WebspaceModelService
    ) {
        super(RequestCanceller);

        // declare multi action availably methods
        this.actionLinksAvailabilities = {
            deletableWebspaces: this.deletableWebspaces,
            restorableWebspaces: this.restorableWebspaces
        };

        this.listCallbacks = [{
            callback: this.listWebspaces
        }];
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField(['WebspaceName', 'WebspaceWebspaceName'], this.$translate.instant('TR_140119-796b28_TR')),
                new TextFilterField('WebspaceId', this.$translate.instant('TR_140119-7e63b7_TR')),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR')),
                new SelectFilterField(
                    'WebspaceStatus',
                    this.$translate.instant('TR_140119-8e4b60_TR'),
                    [
                        {
                            name: this.objectStatusFilter('active'),
                            value: 'active'
                        },
                        {
                            name: this.objectStatusFilter('restorable'),
                            value: 'restorable'
                        }
                    ]
                )
            ];

            if (this.$state.current.name.indexOf('.users.id') < 0) {
                this._cachedAvailableFilters.push(
                    new ApiSelectFilterField(
                        'WebspaceAccessesUserId',
                        this.$translate.instant('TR_080119-3313e9_TR'),
                        ['UserName', 'UserUserName'],
                        this.webhostingUserModel.findOneById,
                        this.webhostingUserModel.listWithoutPagination,
                        'UserId',
                        {
                            id: 'UserId',
                            name: 'name',
                            value: 'id'
                        }
                    )
                );
            }
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }

    public listWebspaces = (limit?, page?, filters?, sort?) => {
        if (this.$state.current.name.split('.').indexOf('dashboard') >= 0) {
            return this.webspaceModel.listWithoutPagination(limit, page, filters, sort);
        } else {
            return this.webspaceModel.list(limit, page, filters, sort);
        }
    };

    public actionLinks = (): LinkListOverviewItem[] => {
        const inManagedServersState = this.$state.current.name.split('.')[0] === 'managed-servers';
        const createRoute = inManagedServersState
            ? 'managed-servers.id.webspaces.new'
            : 'webhosting.webspaces.new';
        const createRouteParams = inManagedServersState
            ? { machineId: this.$state.$current.locals.globals.machine.id }
            : null;

        const actionLinks = [
            {
                isGranted: AuthContextService.isGranted(UiRights.WEB_WEBSPACE_DELETE),
                linkList: [
                    {
                        group: 'delete',
                        groupName: this.$translate.instant('TR_140119-aee71e_TR'),
                        isClickable: 'deletableWebspaces',
                        isGranted: AuthContextService.isGranted(UiRights.WEB_WEBSPACE_DELETE),
                        onclickMethode: () => this.activeMassAction = 'delete',
                        route: '',
                        text: this.$translate.instant('TR_140119-f80a98_TR')
                    },
                    {
                        group: 'delete',
                        groupName: this.$translate.instant('TR_140119-aee71e_TR'),
                        isClickable: 'restorableWebspaces',
                        isGranted: AuthContextService.isGrantedAny([UiRights.WEB_WEBSPACE_DELETE]),
                        onclickMethode: () => this.activeMassAction = 'deleteRestorable',
                        route: '',
                        text: this.$translate.instant('TR_140119-91597a_TR')
                    },
                    {
                        group: 'restore',
                        groupName: this.$translate.instant('TR_140119-d1b7a4_TR'),
                        isClickable: 'restorableWebspaces',
                        isGranted: AuthContextService.isGranted(UiRights.WEB_WEBSPACE_RESTORE),
                        onclickMethode:  () => this.activeMassAction = 'restore',
                        route: '',
                        text: this.$translate.instant('TR_170820-e11db5_TR')
                    }
                ],
                onlyOnExperView: true,
                route: '',
                text: this.$translate.instant('TR_140119-30a364_TR'),
                type: 'multi'
            },
            {
                isGranted: AuthContextService.isGranted(UiRights.WEB_WEBSPACE_CREATE),
                route: createRoute,
                routeParams: createRouteParams,
                text: this.$translate.instant('TR_140119-daec1c_TR')
            }
        ] as LinkListOverviewItem[];

        if (!inManagedServersState) {
            actionLinks.unshift({
                isGranted: AuthContextService.isGrantedAny([
                    UiRights.WEB_USER_EDIT,
                    UiRights.WEB_USER_DELETE,
                    UiRights.WEB_USER_CREATE,
                    UiRights.WEB_JOBS_LIST,
                    UiRights.WEB_OBJECT_LIST
                ]),
                linkList: [
                    {
                        group: 'redirect',
                        isGranted: AuthContextService.isGrantedAny([
                            UiRights.WEB_USER_EDIT,
                            UiRights.WEB_USER_DELETE,
                            UiRights.WEB_USER_CREATE
                        ]),
                        route: this.$state.current.name.split('.')[0] === 'managed-servers'
                            ? 'managed-servers.webhosting.users.overview'
                            : 'webhosting.users.overview',
                        text: this.$translate.instant('TR_140119-9183af_TR')
                    },
                    {
                        group: 'redirect',
                        isGranted: AuthContextService.isGranted(UiRights.WEB_JOBS_LIST),
                        route: this.$state.current.name.split('.')[0] === 'managed-servers'
                            ? 'managed-servers.webhosting.webspaces.jobs'
                            : 'webhosting.webspaces.jobs',
                        text: this.$translate.instant('MENU.CONTEXT.JOBS')
                    },
                    {
                        group: 'redirect',
                        isGranted: AuthContextService.isGranted(UiRights.WEB_OBJECT_LIST),
                        route: this.$state.current.name.split('.')[0] === 'managed-servers'
                            ? 'managed-servers.webhosting.vhosts.overview'
                            : 'webhosting.vhosts.overview',
                        text: this.$translate.instant('TR_080119-acb35a_TR')
                    }
                ],
                route: '',
                text: this.$translate.instant('TR_100119-a22157_TR'),
                type: 'multi'
            });
        }

        return actionLinks;
    };

    public deletableWebspaces = (webspaces: any[]) => {
        const selectedWebspaces = webspaces.filter((webspace) => this.selected[webspace.id]);

        if (selectedWebspaces.length === 0) {
            return false;
        }

        return selectedWebspaces.every(
            (webspace) => [undefined, null, ''].indexOf(webspace.bundleId) >= 0
            && ['restorable'].indexOf(webspace.status) < 0
            && (webspace.deletionScheduledFor === undefined || webspace.deletionScheduledFor === null)
        );
    };

    public restorableWebspaces = (webspaces: any[]) => {
        const selectedWebspaces = webspaces.filter((webspace) => this.selected[webspace.id]);

        if (selectedWebspaces.length === 0) {
            return false;
        }

        return selectedWebspaces.every((webspace) => webspace.status === 'restorable');
    };

    public deleteSubmit = (webspaces: any[], settings: any) => {
        const deletableWebspaces = webspaces
        .filter((webspace) => [undefined, null, false].indexOf(this.selected[webspace.id]) < 0)
        .filter((webspace) => ['restorable'].indexOf(webspace.status) < 0)
        .filter((webspace) => [undefined, null, ''].indexOf(webspace.deletionScheduledFor) >= 0);

        const promises = deletableWebspaces.map(
            (webspace) => {
                const execDate = this.getDeletionDate(webspace, settings);
                return this.webspaceModel.deleteOne(webspace.id, execDate);
            }
        );

        return q.all(promises);
    };

    public deleteRestoreSubmit = (webspaces) => {
        const webspacesToBeDeleted = Object.keys(this.selected).map(
            (elem) => this.selected[elem] ? this.getObjectFromId(webspaces, elem) : false
        );

        return q.all(webspacesToBeDeleted.map((webspace) => webspace.id).map(this.webspaceModel.purgeRestorable));
    };

    public restoreSubmit = (webspaces: any[]) => {
        return this.webspaceModel.restore(
            webspaces.filter((webspace) => this.selected[webspace.id] && webspace.status === 'restorable')
        );
    };

    public getObjectFromId = (objects, objectId) => {
        return objects.filter((object) => {
            return object.id === objectId;
        })[0];
    };

    private getDeletionDate = (webspace: any, settings: any) => {
        if (settings.actiontype === 'sofort') {
            return null;
        }
        if (settings.actiontype === 'endOfContract') {
            return new Date(webspace.paidUntil);
        }
        if (settings.actiontype === 'date') {
            return settings.deleteDate.dateObj;
        }
    };
}
