import * as ng from 'angular';

/// //                     /////
// A COMMENT ON THE COMMENTS //
/// //                     /////
//
// Code was commented out to disable the filter cache.
// ToDo: Actually remove it everywhere. Takes to long for the scope of a quick fix.

export class FilterCacheService {
    public static $inject: string[] = ['$rootScope'];

    private cache = {};
    constructor(private $rootScope: ng.IRootScopeService) {
        this.$rootScope.$on('logout', () => {
            this.cache = {};
        });
    }

    public getFilter(/* stateName*/) {
        // return this.cache[stateName];
        return undefined;
    }

    public setFilter(stateName: string, simpleFilter, extendedFilters) {
        this.cache[stateName] = {
            extendedFilters: extendedFilters,
            simpleFilter: simpleFilter
        };
    }

    public resolveExtendedFilters({/* stateName */}) {
        // if (stateName in this.cache) {
        //     return this.cache[stateName].extendedFilters;
        // }

        return [];
    }

    public resolveSimpleFilter(stateName: string) {
        let simpleFilter = {
            show: false,
            value: ''
        };

        if (this.cache[stateName] && this.cache[stateName].simpleFilter) {
            simpleFilter = this.cache[stateName].simpleFilter;
        } else {
            this.setFilter(stateName, simpleFilter, []);
        }

        return simpleFilter;
    }
}
