import * as ng from 'angular';

export class OrganPanelApiKeyOverviewController implements ng.IController {
    public static $inject: string[] = [];

    public overviewServiceData: any;
    public overviewUiSettings: any;
    public overviewModel: any;
}

export class OrganPanelApiKeyOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        overviewServiceData: '<',
        overviewUiSettings: '<',
        overviewModel: '<'
    };
    public template = require('./api-key-overview.html');
    public controller = OrganPanelApiKeyOverviewController;
}
