import * as ng from 'angular';

export class MoleculeButtonUndoController implements ng.IController {
    public static $inject: string[] = [];

    public undo: () => void;
    public disabled: boolean;
}

export class MoleculeButtonUndoComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        disabled: '<',
        undo: '<'
    };
    public controller =  MoleculeButtonUndoController;
    public template = require('./button-undo.html');
}
