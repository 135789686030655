import * as ng from 'angular';
import { UserSettingsService } from '../../../../../services';

export class MoleculePanelRowAccountDisplayController implements ng.IController {
    public static $inject: string[] = ['userSettings'];

    public subaccountId: string;
    public showAccount: boolean;

    constructor(
        private userSettings: UserSettingsService
    ) {}

    $onInit() {
        this.userSettings.showAccounts().then(
            reply => {
                this.showAccount = reply;
            }
        );
    }

}

export class MoleculePanelRowAccountDisplayComponent implements ng.IComponentOptions {
    public bindings = {
        subaccountId: '<'
    };
    public controller = MoleculePanelRowAccountDisplayController;
    public template = require('./panel-row-account-display.html');
}
