/* eslint-disable */

import ng from 'angular';

import { UiRights } from '@/configuration';
import { ConvertAmountFilter } from '@/filters';
import {
    AsteriskNoteService, AsteriskNoteType, AuthContextService, DepositModelService,
    PriceHelperService, ProductHelperService
} from '@/services';
import { DepositInfoObject } from '@/services/billing/deposit-model';
import * as Types from '@/types';

export class OrganismWizardSummaryViewController {
    public static $inject: string[] = [
        '$translate',
        'asteriskNote',
        'convertAmountFilter',
        'depositModel',
        'productHelper',
        'priceHelper',
    ];

    public callback: () => void;
    public productData: Types.ViewTypes.WizardSummaryProductViewPanelObject[];
    public apiObject: {
        orderItem?: {
            items: any[];
            name: string;
            productCode: string;
            type: string;
        };
        ownerAccountId?: string;
        osId?: any;
        osInstallerData?: any;
        accountId?: any;
        poolId?: any;
        virtualMachineHostId?: any;
        virtualMachine?: any;
        virtualMachineSpecification?: any;
        diskSpecification?: any;
        retailPrice?: any;
        managementType?: any;
        voucher?: Types.ViewTypes.ProductConfigVoucher;
    };
    public viewType: string;
    public totalPriceObject: Types.ViewTypes.PriceObject[] = [];
    public totalPricesLoaded = false;
    public showTaxArea = false;
    public vatAsteriskNoteType: AsteriskNoteType;
    public totalConvertedForeignCurrencyTotal = AsteriskNoteType.CONVERTED_FOREIGN_CURRENCY_TOTAL;
    public totalConvertedForeignCurrencyTotalTax = AsteriskNoteType.CONVERTED_FOREIGN_CURRENCY_TOTAL_TAX;
    public voucherPriceString: string;
    public priceString: string;
    public pricesDisabled: boolean[];
    public usualPrices: string[];

    private _balanceList: Types.ViewTypes.BalanceList[] = [];
    private _taxList: Types.ViewTypes.TaxList[] = [];
    private _depositInfo: DepositInfoObject; // Do not remove - comming soon™ // what?
    private _totalPriceObject: Types.ViewTypes.PriceObject[] = [];
    private _totalHasConvertedPrice = false;
    private _totalHasConvertedPriceAsteriskNotSet = false;

    constructor(
        private $translate: ng.translate.ITranslateService,
        public asteriskNote: AsteriskNoteService,
        private convertAmountFilter: ConvertAmountFilter,
        private depositModel: DepositModelService,
        public productHelper: ProductHelperService,
        public priceHelper: PriceHelperService,
    ) {}

    public $onInit(): void {
        this.asteriskNote.resetNodes();
        if (this.isCommercialCustomer) {
            this.vatAsteriskNoteType = AsteriskNoteType.VAT_EXCLUDED;
            this.asteriskNote.addNote(AsteriskNoteType.VAT_EXCLUDED);
        } else {
            this.vatAsteriskNoteType = AsteriskNoteType.VAT_INCLUDED;
            this.asteriskNote.addNote(AsteriskNoteType.VAT_INCLUDED);
        }

        this._getDepositInfo();
        this._totalPriceObject = ng.copy(this.totalPriceObject);
        this.setPricesDisabled();
        this.updatePrices();
    }

    public $doCheck(): void {
        if (JSON.stringify(this.totalPriceObject) !== JSON.stringify(this._totalPriceObject)) {
            this._calculateTotalBalance();
            this._totalPriceObject = ng.copy(this.totalPriceObject);
        }
    }

    // ToDo: Do not remove - comming soon
    // Ich habe die Anzeige für restliches Guthaben ert einaml wieder raus genommen
    // Da wir bei http.net Kunden die Situation haben, dass sie auch Domains in anderer Währung kaufen können wird etwas
    // umständlicher, die Balnce auszurechen.
    // Wir könnten anhand der ratio vom Preis-Objekt den geschätzes Eurowert ausrechnen und das als Grundlage nehmen.
    // MUSS NOCH ABGEKLÄRT WERDEN
    // public get balance() {
    //     if (this._depositInfo === null || this._depositInfo === undefined) {
    //         return null;
    //     }
    //     return ApiValue.parseApiValue(this._depositInfo.balance
    //         + this._depositInfo.voucherBalance - this._depositInfo.reserved) - this.totalCost();
    // }

    public get voucher(): Types.ViewTypes.ProductConfigVoucher {
        return this.apiObject?.voucher;
    }

    public get productCode(): string {
        return this.productData[0]?.productCode;
    }

    public updatePrices(): void {
        this.updatePrice();
        this.updateVoucherPrice();
    }

    public updatePrice(): void {
        if (!this.productCode || !this.voucher) {
            this.priceString = '';
            return;
        }
        this.priceHelper.getShowPriceFromProductCode(
            this.productCode,
        ).then((price) => {
            this.priceString = price;
        });
    }

    public updateVoucherPrice(): void {
        if (!this.productCode || !this.voucher) {
            this.voucherPriceString = '';
            return;
        }
        this.priceHelper.getShowPriceFromVoucherAndProductCode(
            this.voucher,
            this.productCode,
        ).then((voucherPrice) => {
            this.voucherPriceString = voucherPrice;
        });
    }

    /*
     * This function is called in the init, it creates an array paralleling that
     * of productData, showing if a price is disabled or not.
     */
    public async setPricesDisabled(): Promise<void> {
        let pricesDisabled = new Array(this.productData.length).fill(false);
        let usualPrices = new Array(this.productData.length).fill('');
        let arrayIntersection = this.arrayIntersection(
            this.voucher?.eligibleProductCodes,
            this.productData.map((product) => product.productCode)
        );

        if (!this.canSeePrices) {
            this.productData.map((product, index) => {
                pricesDisabled[index] = false;
            });
            return;
        }

        if (
            this.voucher &&
            arrayIntersection.length > 0
        ) {
            this.productData.map((product, index) => {
                if (!arrayIntersection.includes(product.productCode)) {
                    pricesDisabled[index] = true;
                }
                this.priceHelper.getShowPriceFromProductCode(
                    product.productCode,
                    this.productData[0]?.account,
                ).then((price) => {
                    usualPrices[index] = price;
                });
            })
        }
        //

        this.pricesDisabled = pricesDisabled;
        this.usualPrices = usualPrices;
    }

    /*
     *  checks two arrays if they include the same string
     */
    private arrayIntersection(array1: string[], array2: string[]): string[] {
        if(!array1 || array1.length === 0 || !array2 || array2.length === 0) {
            return [];
        }
        const filteredArray = array1.filter(value => array2.includes(value));
        return filteredArray;
    }

    /*
     *  Get text for products where the price is disabled
     */
    public getPriceDisabledText(index: number): string {
        return this.$translate.instant(
            /* translationID */ 'TR_190224-b0e5b3_TR',
            {
                price: this.usualPrices[index],
            }
        );
    }

    public get voucherStyling(): string {
        if (this.voucher && this.canSeePrices) return "font-weight: bold;"
        return "font-weight: normal;"
    }

    private get additionalInfoText() {
        return this.$translate.instant(
            /* translationID */ 'TR_140224-514630_TR',
            {
                date: this.priceHelper.getVoucherEndDate(this.voucher),
                price: this.priceString,
            }
        );
    }

    private get voucherText(): string {
        const voucherInfo = this.$translate.instant(
            /* translationID */ 'TR_140224-1d1b18_TR',
            {
                periodInDays: this.voucher.periodInDays.toString(),
                price: this.voucherPriceString,
            },
        );
        return voucherInfo + ' ' + this.additionalInfoText;
    }

    private get freeVoucherText(): string {
        const voucherInfo = this.$translate.instant(
            /* translationID */ 'TR_150621-6c947f_TR',
            { costFreePeriodInDays: String(this.apiObject.voucher.periodInDays) },
        );
        return voucherInfo + ' ' + this.additionalInfoText;
    }

    public get canSeePrices() {
        return AuthContextService.isGranted(UiRights.BIL_LIST_ARTICLE_PRICES);
    }

    public get priceAlteration(): string | undefined {
        if (this.canSeePrices && this.apiObject?.voucher) {
            if (this.isFree) {
                return this.freeVoucherText;
            } else {
                return this.voucherText;
            }
        }
        return '';
    }

    public getAdditionalVatText = (tax: any): string | undefined => {
        if (this.apiObject?.voucher) {
            return this.$translate.instant(
                /* translationID */ 'TR_160224-f7773c_TR',
                {
                    date: this.priceHelper.getVoucherEndDate(this.voucher),
                    vat: this.formatPriceSum(tax),
                }
            )
        }
        return '';
    }

    public get isFree(): boolean {
        if (this.apiObject?.voucher?.grossAmount === 0) {
            return true;
        }
        return false;
    }

    public set isCommercialCustomer(_) {/* */}
    public get isCommercialCustomer(): boolean {
        return AuthContextService.account.isCommercialCustomer;
    }

    public set totalBalance(_) {/* */}
    public get totalBalance(): Types.ViewTypes.BalanceList[] {
        return this._balanceList;
    }

    public set totalTax(_) {/* */}
    public get totalTax(): Types.ViewTypes.TaxList[] {
        return this._taxList;
    }

    public set hidePriceInfo(_) {/* */}
    public get hidePriceInfo(): boolean {
        return this.productData
            && ['individual-virtual-machines', 'dns-zone'].indexOf(this.productData[0]?.productFamily) >= 0;
    }

    public set isDomainService(_) {/* */}
    public get isDomainService(): boolean {
        return true;
    }

    public set chargeableProducts(_) {/* */}
    public get chargeableProducts(): boolean {
        if (this.isFree) {
            return false;
        }
        let costs = 0;
        this._totalHasConvertedPrice = false;
        for (const priceObject of this._totalPriceObject) {
            if (priceObject.rawPrice > 0 || priceObject.rawBasisConverted > 0) {
                let price: number = +priceObject.rawPrice;
                if (priceObject.exchangeRatio) {
                    price = +priceObject.rawBasisConverted;

                    this._totalHasConvertedPrice = true;
                }
                costs += price;
            }
        }

        return costs > 0;
    }

    public set totalHasConvertedPrice(_) {/* */}
    public get totalHasConvertedPrice(): boolean {
        if (!this._totalHasConvertedPriceAsteriskNotSet && this._totalHasConvertedPrice) {
            // only set asterisk node only once
            this._totalHasConvertedPriceAsteriskNotSet = true;
            this.asteriskNote.addNote(this.totalConvertedForeignCurrencyTotal);
            if (this.isCommercialCustomer) {
                this.asteriskNote.addNote(this.totalConvertedForeignCurrencyTotalTax);
            }
        }

        return this._totalHasConvertedPrice;
    }

    public set showChargeableButton(_) {/* */}
    public get showChargeableButton(): boolean {
        if (this.showSubaccountWithoutShowPriceRightButton) {
            return false;
        }

        return this.chargeableProducts;
    }

    public set showFreeOfChargeButton(_) {/* */}
    public get showFreeOfChargeButton(): boolean {
        if (this.showSubaccountWithoutShowPriceRightButton) {
            return false;
        }

        return !this.chargeableProducts;
    }

    public set showSubaccountWithoutShowPriceRightButton(_) {/* */}
    public get showSubaccountWithoutShowPriceRightButton(): boolean {
        const targetAccount = this.productData[0].account;
        if (AuthContextService.account.id !== targetAccount.id
            || !targetAccount.rights.some(
                (right: string) => right === UiRights.BIL_LIST_ARTICLE_PRICES
            )
        ) {
            return true;
        }

        return false;
    }

    public switchToConfiguration = (): void => {
        this.viewType = 'config';
    };

    public orderForSubaccount = (account: Types.AccountApi.Account): boolean => {
        return AuthContextService.account.id !== account.id;
    };

    public submitCallback = (): void => {
        if (this.callback) {
            this.callback();
        }
    };

    public formatPriceSum = (priceSum: { currency: string; sum: number }): string | undefined => {
        if (!priceSum || !priceSum.currency) {
            return;
        }

        return `${this.convertAmountFilter(priceSum.sum)} ${priceSum.currency.toLocaleUpperCase()}`;
    };

    private _calculateTotalBalance = (): void => {
        // this.totalPriceObject is pushed to from price subcomponents.
        if (!Array.isArray(this.totalPriceObject) || this.totalPriceObject.length === 0) {
            return;
        }

        let loops = 0;
        let taxToAddAtCustomerAccount: number;
        this._balanceList = [];
        this._taxList = [];

        for (const priceObject of this.totalPriceObject) {
            loops++;
            taxToAddAtCustomerAccount = AuthContextService.account.isCommercialCustomer
                ? priceObject?.rawTax?.tax
                : 0;

            if (priceObject.rawPrice === 0) {
                continue;
            }

            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            priceObject.amount = isNaN(parseInt('' + priceObject.amount, 10))
                ? 1
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                : parseInt('' + priceObject.amount, 10);

            const currencyExists = this._balanceList.some((balance) => {
                const hasExchangeRatio: boolean = [null, undefined].indexOf(priceObject.exchangeRatio) < 0;
                if (
                    balance.currency !== undefined
                    && (
                        balance.currency?.toLowerCase() === priceObject.currency.toLowerCase()
                        || (
                            hasExchangeRatio
                            && balance.currency?.toLowerCase() === priceObject.exchangeRatio.baseCurrency.toLowerCase()
                        )
                    )
                ) {
                    balance.sum += hasExchangeRatio
                        ? (priceObject.rawBasisConverted + taxToAddAtCustomerAccount) * priceObject.amount
                        : (priceObject.rawPrice + taxToAddAtCustomerAccount) * priceObject.amount;

                    return true;
                }

                return false;
            });
            const currencyTaxExists = this._taxList.some((tax) => {
                const hasExchangeRatio = !!priceObject.exchangeRatio;
                if (
                    tax.currency?.toLowerCase() === priceObject.currency?.toLowerCase()
                    || (
                        hasExchangeRatio
                        && tax.currency?.toLowerCase() === priceObject.exchangeRatio?.baseCurrency?.toLowerCase()
                    )
                ) {
                    tax.sum += priceObject.rawTax.tax * priceObject.amount;
                    return true;
                }

                return false;
            });

            if (!currencyExists) {
                this._balanceList.push({
                    currency: priceObject.exchangeRatio
                        ? priceObject.exchangeRatio.baseCurrency
                        : priceObject.currency,
                    sum: priceObject.exchangeRatio
                        ? (priceObject.rawBasisConverted + taxToAddAtCustomerAccount) * priceObject.amount
                        : (priceObject.rawPrice + taxToAddAtCustomerAccount) * priceObject.amount
                });
            }
            if (!currencyTaxExists) {
                this._taxList.push({
                    currency: priceObject.exchangeRatio
                        ? priceObject.exchangeRatio.baseCurrency
                        : priceObject.currency,
                    sum: (priceObject.rawTax.tax) * priceObject.amount
                });
            }
        }

        if (loops === this.productData.length) {
            this.showTaxArea = this._taxList.length > 0;
            this.totalPricesLoaded = true;
        }
    };

    // ToDo: Display (html) is not yet implemented
    private _getDepositInfo = (): void => {
        if (AuthContextService.isGranted(UiRights.BIL_GET_DEPOSIT)) {
            void this.depositModel.getDeposit().then(
                (reply) => this._depositInfo = reply
            );
        }
    };
}

export class OrganismWizardSummaryViewComponent implements ng.IComponentOptions {
    public bindings = {
        apiObject: '<?',
        callback: '<',
        productData: '<',
        viewType: '='
    };
    public template = require('./wizard-summary-view.html');
    public controller = OrganismWizardSummaryViewController;
}
