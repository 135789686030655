import * as ng from 'angular';
import './validation-error.scss';

export class AtomFormValidationErrorController implements ng.IController {
    public static $inject: string[] = [];
}

export class AtomFormValidationErrorComponent implements ng.IComponentOptions {
    public transclude = true;
    public controller =  AtomFormValidationErrorController;
    public template = require('./validation-error.html');
}
