import ng from 'angular';

import { MoleculeFormEditController } from '@/atomic-components/molecules/forms';
import { UiRights } from '@/configuration';
import { CalculatePriceFilter } from '@/filters';
import { AuthContextService } from '@/services';
import { UI } from '@/types';

export class MoleculePanelEditRowPhpBoostChangerController implements ng.IController {
    public static $inject: string[] = ['$timeout', '$translate', 'calculatePriceFilter'];

    public userHasRightBilListArticlePrices = AuthContextService.isGranted(
        UiRights.BIL_LIST_ARTICLE_PRICES
    );
    public $editForm: MoleculeFormEditController;
    public boostData: UI.phpBoostOptionsInterface;
    public _orderConfirmed: boolean;
    public onChange: (value) => {};
    public skipConfirmation: boolean;
    public webspace: any = {};
    public contractPeriodDisplays: any = {};
    public isManagedServerWebspace = false;

    private registrationIndex: number;

    constructor(
        private $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        private calculatePriceFilter: CalculatePriceFilter
    ) {}

    public $onInit = () => {
        if ([null, undefined].indexOf(this.webspace.poolId) < 0) {
            this.isManagedServerWebspace = true;
        }
        this.contractPeriodDisplays = {
            annually: this.$translate.instant('TR_090119-c59c72_TR'),
            monthly: this.$translate.instant('TR_090119-04522d_TR')
        };
        this._orderConfirmed = false;
        this.registrationIndex = this.$editForm.registerValidator(this);
        this.$editForm.validatorStatus[this.registrationIndex] = true;
    };

    public get orderConfirmed() {
        if ([undefined, null, false].indexOf(this.skipConfirmation) < 0) {
            return true;
        }

        return this._orderConfirmed;
    }

    public set orderConfirmed(value: boolean) {
        this._orderConfirmed = value;
        this.setValidationStatus();
    }

    public get confirmationCheckboxText() {
        if (!this.userHasRightBilListArticlePrices || this.costs.net > 0) {
            return this.$translate.instant('ba0c1c71-d25d-4b29-8141-859acf9ee860');
        }
        return this.$translate.instant('TR_210420-518908_TR');
    }

    public $onDestroy = () => {
        this.$editForm.unregisterValidator(this.registrationIndex);
        this.$editForm.validatorStatus[this.registrationIndex] = true;
    };

    public onChangeCallback = () => {
        this.setValidationStatus();

        if (this.$editForm.validatorStatus[this.registrationIndex] && this.onChange !== undefined) {
            this.$timeout(() => {
                this.onChange(this.boostData);
            });
        }
    };

    public toggle = () => {
        this.orderConfirmed = !this.orderConfirmed;
        this.onChangeCallback();
    };

    public get showPaymentConfirmationCheckbox() {
        return !this.isManagedServerWebspace
            && (
                this.boostData.phpExecutionTime.orderAmount !== 0
                || this.boostData.phpMemoryLimit.orderAmount !== 0
            );
    }

    private setValidationStatus = () => {
        this.$timeout(
            () => {
                this.$editForm.validatorStatus[this.registrationIndex] = this.isManagedServerWebspace
                || ([undefined, null, false].indexOf(this.skipConfirmation) < 0)
                || this._orderConfirmed
                || (
                    this.boostData.phpExecutionTime.orderAmount === 0
                    && this.boostData.phpMemoryLimit.orderAmount === 0
                );
            }
        );
    };

    public get costs(): { gross: number; net: number } {
        if (this.isManagedServerWebspace) {
            return { gross: 0, net: 0 };
        }
        if ([undefined, null].indexOf(this.boostData) >= 0) {
            return undefined;
        }
        return {
            gross: this.calculatePriceFilter(
                this.boostData.phpExecutionTime.price.price,
                this.boostData.phpExecutionTime.price.vatRate,
                this.boostData.phpExecutionTime.orderAmount,
                'gross',
                null,
                false,
                this.boostData.phpExecutionTime.price.productCode,
                this.boostData.phpExecutionTime.price.contractPeriod
            ) + this.calculatePriceFilter(
                this.boostData.phpMemoryLimit.price.price,
                this.boostData.phpMemoryLimit.price.vatRate,
                this.boostData.phpMemoryLimit.orderAmount,
                'gross',
                null,
                false,
                this.boostData.phpMemoryLimit.price.productCode,
                this.boostData.phpMemoryLimit.price.contractPeriod
            ),
            net: this.calculatePriceFilter(
                this.boostData.phpExecutionTime.price.price,
                this.boostData.phpExecutionTime.price.vatRate,
                this.boostData.phpExecutionTime.orderAmount,
                'net',
                null,
                false,
                this.boostData.phpExecutionTime.price.productCode,
                this.boostData.phpExecutionTime.price.contractPeriod
            ) + this.calculatePriceFilter(
                this.boostData.phpMemoryLimit.price.price,
                this.boostData.phpMemoryLimit.price.vatRate,
                this.boostData.phpMemoryLimit.orderAmount,
                'net',
                null,
                false,
                this.boostData.phpMemoryLimit.price.productCode,
                this.boostData.phpMemoryLimit.price.contractPeriod
            )
        };
    }
}

export class MoleculePanelEditRowPhpBoostChangerComponent implements ng.IComponentOptions {
    public transclude = {
        labelExecutionTime: 'rowLabelExecutionTime',
        labelMemoryLimit: 'rowLabelMemoryLimit'
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public bindings = {
        boostData: '=',
        onChange: '<',
        webspace: '='
    };
    public controller = MoleculePanelEditRowPhpBoostChangerController;
    public controllerAs = '$panelRow';
    public template = require('./panel-edit-row-php-boost-changer.html');
}
