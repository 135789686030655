import * as ng from 'angular';

export class OrganNewPanelExchangeOrganizationController {
    public static $inject: string[] = [];

    public $newFormOrganism: any;
}

export class OrganNewPanelExchangeOrganizationComponent implements ng.IComponentOptions {
    public require = {
        $newFormOrganism: '^organismNewFormExchangeOrganization'
    };
    public controllerAs = '$newPanelOrgan';
    public controller = OrganNewPanelExchangeOrganizationController;
    public template = require('./exchange-organization.html');
}
