import * as ng from 'angular';

export class MoleculePanelHeaderEditController implements ng.IController {
    public static $inject: string[] = ['$transclude'];

    public panelIcon: string;
    public panelTitle: string;
    public panelSubTitle?: string;
    public modifier: string;

    constructor(
        private $transclude: ng.ITranscludeFunction
    ) {}

    public $onInit = () => {
        this.modifier = this.modifier || 'headline';
    };

    public transcludeContent = (transcludeAttr) => {
        if (['', null, undefined].indexOf(transcludeAttr) >= 0) {
            return false;
        }
        return this.$transclude.isSlotFilled(transcludeAttr);
    };
}

/**
  * Notice:
  * There are two ways to display content in this component:
  * - PanelTitle:
  * PanelTitle will always display
  *
  * - Transclude content
  * If content is stored via the optional tag 'transcludeContent',
  * it will be add behind (if available, see above) the panel title
  * If you will display transcluded content in new line, so you have set (for example) a p, dic etc. tag
 */

export class MoleculePanelHeaderEditComponent implements ng.IComponentOptions {
    public bindings = {
        modifier: '@',
        panelIcon: '@',
        panelTitle: '@',
        panelSubTitle: '@?'
    };
    public transclude = {
        transcludedViewContent: '?transcludeContent'
    };
    public controller =  MoleculePanelHeaderEditController;
    public template = require('./panel-header-edit.html');
}
