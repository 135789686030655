import * as ng from 'angular';
import { ProductConfigNextcloudObject } from '../';
import { ManagedApplicationComposerService } from '../../../../../services';
import * as Types from '../../../../../types';

export class OrganismNextcloudWizardWrapperController {
    public static $inject: string[] = ['managedApplicationComposer'];

    public outerAccount: Types.AccountApi.Account;
    public outerProductCode: string;

    public wizardComposerObject: Types.ViewTypes.WizardComposerObject = {
        apiObject: {},
        summaryObject: []
    };
    public viewType = 'config';

    constructor(
        private managedApplicationComposer: ManagedApplicationComposerService
    ) {}

    public finishConfigCallback = (metadata: ProductConfigNextcloudObject): void => {
        void this.managedApplicationComposer.buildNextcloudApiObject(metadata).then((res) => {
            this.viewType = 'summary';
            this.wizardComposerObject = res;
        });
    };

    public loadConfirmView = (): void => {
        this.viewType = 'confirm';
    };
}

export class OrganismNextcloudWizardWrapperComponent implements ng.IComponentOptions {
    public bindings = {
        outerAccount: '<?account',
        outerProductCode: '<?productCode'
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    public template = require('./nextcloud-wizard-wrapper.html');
    public controller = OrganismNextcloudWizardWrapperController;
}
