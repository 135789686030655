import * as ng from 'angular';
import { twoFAFormStatus } from '../../../../organs/panels/edit-panels/user-login/edit-panel-two-f-a';

import './panel-create-two-f-a.scss';

export class MoleculePanelCreateTwoFAController implements ng.IController {
    public static $inject: string[] = ['$element', '$timeout'];

    public twoFAData: any;
    public confirmCode: () => void;
    public confirmationCodeValidationSuccessful = true;
    public $formEdit;
    public last2FaConfirmationCode: string;

    public constructor(
        public $element: ng.IAugmentedJQuery,
        public $timeout: ng.ITimeoutService
    ){}

    public $onInit() {
        this.createHandlerToScrollIntoView();
    }

    public $doCheck() {
        const code2Fa = this.twoFAData.confirmationCode;
        if (code2Fa.length === 6 && code2Fa !== this.last2FaConfirmationCode) {
            this.last2FaConfirmationCode = code2Fa;
            this.confirm();
        }
    }

    // I couldnt find another way of listening to changes in nested properties.
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy
    public createHandlerToScrollIntoView = () => {
        let scrollViewHandler = (obj, prop, value) => {
            if (prop === 'formStatus' && value === twoFAFormStatus.CONFIRM_CODE) {
                // At this point we know that the `formStatus`-propety changed to `confirm_code`.
                this.$timeout(() => {
                    this.$element[0].scrollIntoView();
                }, 200);
            }

            // Default behaviour.
            obj[prop] = value;

            // Signal success.
            return true;
        };

        // Dont forget the context.
        scrollViewHandler = scrollViewHandler.bind(this);

        // Set the actual proxy with the defined handler.
        this.twoFAData = new Proxy(this.twoFAData, {
            set: scrollViewHandler
        });
    };

    public opened = () => {
        return this.twoFAData.formStatus === twoFAFormStatus.CONFIRM_CODE;
    };

    public close = () => {
        this.twoFAData.formStatus = twoFAFormStatus.BASE;
        this.twoFAData.password = '';
        this.twoFAData.token = '';
        this.twoFAData.qrUrlString = '';
    };

    public confirm = () => {
        if (this.$formEdit.$invalid) {
            return;
        }
        this.confirmCode();
    };

    public get disableSubmitButton(): boolean {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this.$formEdit.$invalid || this.twoFAData?.confirmationCode?.length < 6;
    }

    public confirmOnEnter = (event: KeyboardEvent) => {
        if (event.code === 'Enter') {
            this.confirm();
        } else if (event.code === 'Escape') {
            this.close();
        }
    };
}

export class MoleculePanelCreateTwoFAComponent implements ng.IComponentOptions {
    public bindings = {
        twoFAData: '=',
        confirmCode: '<'
    };
    require = {
        $formEdit: '^moleculeFormEdit'
    };
    public controller = MoleculePanelCreateTwoFAController;
    public template = require('./panel-create-two-f-a.html');
}
