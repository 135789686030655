import * as ng from 'angular';
import { ValidateCheckboxRequired } from '../../../../../services';

export class MoleculeFormCheckboxRequiredController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public value: string;
    public outerCallbackOnChange: (arg0: any) => any;
    public disabled: boolean;
    public registerToForm: boolean;
    public validationInstructions: any[];
    public validationErrorList = [];
    public inputType = 'checkbox-required';
    public labelText: string;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.labelText = this.labelText || '';
        this.validationInstructions = [
            {
                validator: new ValidateCheckboxRequired(this.$translate),
                instructions: null
            }
        ];
    }

    public callbackOnChange = () => {
        if (this.outerCallbackOnChange !== undefined) {
            this.outerCallbackOnChange(this.value);
        }
    };

    public onPaste = (paste) => {
        // implement callback on paste here when needed!
    };
}

export class MoleculeFormCheckboxRequiredComponent implements ng.IComponentOptions {
    public bindings = {
        value: '=',
        labelText: '@',
        outerCallbackOnChange: '<callbackOnChange',
        disabled: '<',
        validationErrorList: '=?',
        registerToForm: '<'
    };
    public controller = MoleculeFormCheckboxRequiredController;
    public template = require('./input-text-with-validation-wrapper.html');
}
