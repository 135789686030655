import { ExternalForwarderProductFamily } from '../../../../../configuration';
import {
    AuthContextService,
    PriceCacheService,
    ProductsModelService,
    SpamProfileService
} from '../../../../../services';
import { EmailHelper } from '../../../../../services/email/email-helper';
import { WizardObject } from '../../wizard-supervisor/wizard-supervisor';

import * as ng from 'angular';

export class MoleculeWizardSummaryItemMailboxController implements ng.IController {
    /* tslint:disable-next-line:max-line-length */
    public static $inject: string[] = ['$timeout', '$translate', 'priceCache', 'productsModel', 'spamProfileService'];
    public serviceObject: WizardObject;
    public spamProfileName: string;
    private internalPrice: any;
    private externalPrice: any;

    constructor(
        private $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        private priceCache: PriceCacheService,
        private productsModel: ProductsModelService,
        private spamProfileService: SpamProfileService
    ) {}

    public $onInit() {
        this.setSpamProtectionProfileName();
        if ([ExternalForwarderProductFamily].indexOf(this.serviceObject.selectedProduct.family) >= 0) {
            this.priceCache.listMailPrices().then(
                (res) => {
                    res.map(
                        (price) => {
                            if (this.serviceObject.display !== undefined
                                && this.serviceObject.display.internalForwarder !== undefined
                                && this.serviceObject.display.internalForwarder
                            ) {
                                this.$timeout(() => this.serviceObject.selectedProduct.name = this.$translate.instant('TR_100119-644069_TR')); // tslint:disable-line
                            } else {
                                this.$timeout(() => this.serviceObject.selectedProduct.name = this.$translate.instant('TR_100119-0118c0_TR')); // tslint:disable-line
                            }
                            this.serviceObject.selectedProduct.selectedPrice.price = price;
                        }
                    );
                }
            );
        }

        if ((
            this.serviceObject.selectedProduct.family === 'imap-mailboxes'
            || this.serviceObject.selectedProduct.family === 'msexchange-mailboxes')
            && this.serviceObject.display.storageProducts > 0
        ) {
            this.productsModel.findOne(this.serviceObject.selectedProduct.selectedPrice.productCode).then(
                (reply) => {
                    this.priceCache.getStorageItemPrice(
                        this.serviceObject.selectedProduct.selectedPrice.productCode,
                        reply.response, 'mailbox'
                    ).then(
                        (ret) => {
                            this.serviceObject.children = [{
                                amount: this.serviceObject.display.storageProducts,
                                label: ret.name,
                                price: ret
                            }];
                        }
                    );
                }
            );
        } else {
            this.serviceObject.children = [];
        }
    }

    public get productName() {
        return this.serviceObject.selectedProduct.name;
    }

    public set productName({}) {} // tslint:disable-line:no-empty

    public setSpamProtectionProfileName = () => {
        const profile = EmailHelper.getSelectedSpamProfile(
            this.spamProfileService.getList(),
            this.serviceObject.settings.spamFilter
        );

        if (profile === undefined) {
            this.spamProfileName = '';
        }
        this.spamProfileName = profile.label;
    };

    public get showstorageQuota() {
        return ['imap-mailboxes', 'msexchange-mailboxes'].indexOf(this.serviceObject.selectedProduct.family) >= 0;
    }

    public get addressDisplay() {
        if (this.serviceObject.selectedProduct.family !== 'catchalls') {
            return this.serviceObject.settings.emailAddress;
        } else {
            return this.serviceObject.settings.catchallDomain;
        }
    }

    public get showAccount() {
        return this.serviceObject.settings.accountId !== AuthContextService.account.id;
    }
}

export class MoleculeWizardSummaryItemMailboxComponent implements ng.IComponentOptions {
    public bindings = {
        serviceObject: '='
    };
    public template = require('./wizard-summary-mailbox.html');
    public controller =  MoleculeWizardSummaryItemMailboxController;
}
