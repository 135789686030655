import * as ng from 'angular';

export class Shorten {
    public static $inject: string[] = [];

    public static Factory() {
        return (input: string, hardLimit = 50, softLimit: number) => {
            if ([undefined, null, ''].indexOf(input) >= 0 || !ng.isString(input)) {
                return input;
            }

            if ([undefined, null].indexOf(softLimit) >= 0) {
                softLimit = Math.max(
                    hardLimit / 2,
                    hardLimit - Math.sqrt(hardLimit)
                );
            }

            let result: string = input.slice(0, hardLimit);

            if (input.length >= hardLimit) {
                result = input.slice(0, hardLimit - 3) + '...';
            }

            while (result.length > softLimit) {
                const parts = result.split(' ');

                if (parts.length < 2) {
                    break;
                }

                result = parts.slice(0, -1).join(' ') + '...';
            }

            return result;
        };
    }
}
