import * as ng from 'angular';
import { DomainInfoHelperService, ValidateEmail } from '../../../../../services';

import './notification-settings-panel.scss';
import { EditPanelRight } from '@/atomic-components';

export class OrganEditPanelNotificationSettingsController implements ng.IController {
    public static $inject: string[] = ['$timeout', '$translate', 'domainInfoHelper'];

    public readableEmail: boolean;
    public initialReadableEmail: boolean;
    public machineReadableEmail: boolean;
    public initialMachineReadableEmail: boolean;
    public eventDocumentFormat: 'json'|'xml'|'' = 'json';
    public initialEventDocumentFormat: 'json'|'xml'|'';
    public eventDocumentFormatOptions = [
        { value: 'json', name: 'JSON' },
        { value: 'xml', name: 'XML' }
    ];
    public subaccountNotifications: boolean;
    public initialSubaccountNotifications: boolean;
    public cancelCallbackFunction: () => void;
    public saveCallbackFunction: () => void;
    public newEmailValue: string;
    public newMachineEmailValue: string;
    public eventHumanReadableEmailAddresses: any[];
    public initialEventHumanReadableEmailAddresses: any[];
    public eventMachineReadableEmailAddresses: any[];
    public initialEventMachineReadableEmailAddresses: any[];
    public validationInstructions: any[];
    public panelTitle: string;
    public addTextToListPlaceholder: string;
    public panelRightSettings: EditPanelRight;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private domainInfoHelper: DomainInfoHelperService
    ) {}

    public $onInit(): void {
        this.initialEventHumanReadableEmailAddresses = ng.copy(this.eventHumanReadableEmailAddresses);
        this.initialReadableEmail = ng.copy(this.readableEmail);
        this.initialEventMachineReadableEmailAddresses = ng.copy(this.eventMachineReadableEmailAddresses);
        this.initialMachineReadableEmail = ng.copy(this.machineReadableEmail);
        this.initialSubaccountNotifications = ng.copy(this.subaccountNotifications);
        this.initialEventDocumentFormat = ng.copy(this.eventDocumentFormat);
        this.validationInstructions = [{
            instructions: null,
            validator: new ValidateEmail(true, this.$translate, this.domainInfoHelper)
        }];
    }

    public addEventEmailAddress = () => {
        if ([undefined, null, ''].indexOf(this.newEmailValue) < 0) {
            this.eventHumanReadableEmailAddresses.push({
                markedForRemoval: false,
                value: this.newEmailValue
            });
            this.newEmailValue = null;
        }
    };

    public machineReadableEmailChanged = (): void => {
        void this.$timeout(() => {
            if (this.machineReadableEmail === true && [undefined, null, ''].indexOf(this.eventDocumentFormat) >= 0) {
                this.eventDocumentFormat = 'json';
            }
        });
    };

    public addEventMachineEmailAddress = () => {
        if ([undefined, null, ''].indexOf(this.newMachineEmailValue) < 0) {
            this.eventMachineReadableEmailAddresses.push({
                markedForRemoval: false,
                value: this.newMachineEmailValue
            });
            this.newMachineEmailValue = null;
        }
    };

    public removeHumanReadableEmailAddress = (index) => {
        this.eventHumanReadableEmailAddresses.splice(index, 1);
    };

    public toggleMarkedForRemoval = (item) => {
        item.markedForRemoval = !item.markedForRemoval;
    };

    public get emailAddressOutput(): string {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this.eventHumanReadableEmailAddresses.map((email) => email.value).join(', ');
    }

    public get machineEmailAddressOutput(): string {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this.eventMachineReadableEmailAddresses.map((email) => email.value).join(', ');
    }

    public get saveButtonDisabled(): boolean {
        const defaultNotificationsComplete = !this.readableEmail
            || this.eventHumanReadableEmailAddresses.some(email => email.markedForRemoval === false);
        const machineNotificationsComplete = !this.machineReadableEmail
            || (
                this.eventMachineReadableEmailAddresses.some(email => email.markedForRemoval === false)
                && !!this.eventDocumentFormat
            )
        ;
        return !(defaultNotificationsComplete && machineNotificationsComplete);
    }

    public cancel = () => {
        this.newEmailValue = '';
        this.newMachineEmailValue = '';
        this.eventHumanReadableEmailAddresses = ng.copy(this.initialEventHumanReadableEmailAddresses);
        this.eventMachineReadableEmailAddresses = ng.copy(this.initialEventMachineReadableEmailAddresses);
        this.eventDocumentFormat = ng.copy(this.initialEventDocumentFormat);
        this.readableEmail = ng.copy(this.initialReadableEmail);
        this.machineReadableEmail = ng.copy(this.initialMachineReadableEmail);
        this.subaccountNotifications = ng.copy(this.initialSubaccountNotifications);
    };
}

export class OrganEditPanelNotificationSettingsComponent implements ng.IComponentOptions {
    public bindings = {
        cancelCallbackFunction: '<',
        eventHumanReadableEmailAddresses: '=',
        eventMachineReadableEmailAddresses: '=',
        panelRightSettings: '<',
        readableEmail: '=',
        machineReadableEmail: '=',
        eventDocumentFormat: '=',
        saveCallbackFunction: '<',
        subaccountNotifications: '='
    };
    // tslint:disable-next-line: max-line-length
    public template = require('./notification-settings-panel.html');
    public controller = OrganEditPanelNotificationSettingsController;
}
