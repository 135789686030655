import { DashboardOverviewObject, PanelHeaderData } from '@/atomic-components';
import { UiRights } from '@/configuration';
import { AuthContextService, FilterCacheService } from '@/services';
import { NetworkOverviewModel } from '@/services/network/network-overview-model';
import * as ng from 'angular';

export class TemplateNetworkDashboardController {

    public static $inject: string[] = ['$translate', 'filterCache', 'networkOverviewModel'];

    public userHasRightNetworkList = AuthContextService.isGranted(UiRights.RES_NETWORK_LIST); // used in template!
    public pageHeaderData: PanelHeaderData;
    public dashboardOverview: DashboardOverviewObject;
    public faqArticleIdList: string[] = [
        'machine-managed-vs-cloud'
    ];
    public showObjectId: boolean;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private filterCache: FilterCacheService,
        private networkOverviewModel: NetworkOverviewModel
    ) { }

    public $onInit = () => {
        this.pageHeaderData = {
            panelHeaderRoute: 'network.networks.overview',
            panelHeaderRouteParams: null,
            panelIcon: 'network-wired',
            panelTitle: this.$translate.instant('TR_230420-d570e4_TR')
        };

        this.dashboardOverview = {
            // List of table service data
            overviewsData: [
                {
                    hidePanelHeaderData: false,
                    listOptions: {
                        limit: 6,
                        sort: null
                    },
                    overviewModel: this.networkOverviewModel,
                    panelHeaderData: {
                        includeShowAllLink: true,
                        includeShowDetailsLink: false,
                        panelHeaderRoute: 'network.networks.overview',
                        panelHeaderRouteParams: {},
                        panelIcon: 'network-wired',
                        panelTitle: this.$translate.instant('TR_230420-d570e4_TR')
                    },
                    serviceItemList: []
                }
            ],
            // Dashboard main menu panel
            panelData: {
                mainHeader: {
                    icon: 'network-wired',
                    isGranted: AuthContextService.isGranted(UiRights.RES_NETWORK_EDIT),
                    route: 'network.networks.new',
                    routeParams: {},
                    text: this.$translate.instant('TR_050520-590e18_TR'),
                    disabled: true
                }
            },
            simpleFilter: this.filterCache.resolveSimpleFilter('VirtualMachines')
        };
    };
}

export class TemplateNetworkDashboardComponent implements ng.IComponentOptions {
    public bindings = {
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        showObjectId: '<',
        simpleFilter: '<'
    };
    public controller = TemplateNetworkDashboardController;
    public controllerAs = '$TemplateNetworkDashboardCtrl';
    public template = require('./dashboard-template.html');
}
