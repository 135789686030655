import * as ng from 'angular';
import {
    AlertManagerService,
    NavigationService,
    VhostModelService,
    WebspaceModelService
} from '../../../../../services';
import * as Types from '../../../../../types';

export class OrganismEditFormWebspacePhpMinVersionController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'navigation',
        'vhostModel',
        'webspaceModel'
    ];

    public originalWebspace: any;
    public webspace: Types.WebhostingApi.Webspace;
    public userPanelRight: any;
    public phpVersions: Types.WebhostingApi.PhpVersion[];
    public minPhpVersion: string|null = null;
    public vHostUpdateAccepted = false;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private navigation: NavigationService,
        private vhostModel: VhostModelService,
        private webspaceModel: WebspaceModelService
    ) {}

    public $onInit = () => {
        this.webspace = ng.copy(this.originalWebspace);
    };

    public save = () => {
        if (!this.vHostUpdateAccepted) {
            return;
        }

        this.webspaceModel.setMinPhpVersion(this.webspace.id, this.minPhpVersion)
        .then(
            () => {
                this.alertManager.success(this.$translate.instant('TR_300123-3f04c0_TR'));
                this.vHostUpdateAccepted = false;
            }
        );
    };

    public cancel = () => {
        this.vHostUpdateAccepted = false;
    };
}

export class OrganismEditFormWebspacePhpMinVersionComponent implements ng.IComponentOptions {
    public bindings = {
        originalWebspace: '<webspace',
        userPanelRight: '=',
        phpVersions: '<'
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormWebspacePhpMinVersionController;
    public template = require('./webspace-php-min-version-edit.html');
}
