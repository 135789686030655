import * as ng from 'angular';
import { WizardVhostDomainObject } from '..';
import { ValidateDns, VhostModelService } from '../../../../../services';

export class OrganCreateConfigurationVhostOrchestratorController implements ng.IController {
    public static $inject: string[] = ['$translate', 'vhostModel'];

    public contingent: any;
    public disabled = false;
    public vhostObject: WizardVhostDomainObject;
    public callbackOnChange: () => {};
    public validationErrors = [];

    private _required = true;
    private domainNameValidator;
    private _domainNameChangeMethodeIsActiv = false;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private vhostModel: VhostModelService
    ) {
        this.domainNameValidator = new ValidateDns(this.$translate, false);
    }

    public $onInit() {
        if (this.vhostObject !== undefined) {
            this._setVhostObject();
        }

    }

    public get completed() {
        if (!this._required) {
            return true;
        }

        return this.vhostObject.orchestratorObject.domainNameUnicode !== ''
            && this.vhostObject.orchestratorObject.domainNameUnicode.split('.').length >= 2
            && this.validationErrors.length === 0;
    }

    public set completed({}) {} // tslint:disable-line:no-empty

    public get isVhostNameRequired() {
        if (this.disabled) {
            return false;
        }
        return this._required;
    }

    public set isVhostNameRequired({}) {} // tslint:disable-line:no-empty

    public domainNameChanged = () => {
        if (this._domainNameChangeMethodeIsActiv) {
            return;
        }

        // set flag so that the request (and the error message, if any) is not executed/set more than once
        this._domainNameChangeMethodeIsActiv = true;
        const domainNameValidation = this.domainNameValidator.validate(
            this.vhostObject.orchestratorObject.domainNameUnicode
        );

        if (domainNameValidation === undefined
            || domainNameValidation.length === 0
        ) {
            const filter = {
                field: 'VHostDomainNameUnicode',
                value: ng.copy(this.vhostObject.orchestratorObject.domainNameUnicode)
            };
            this.vhostModel.listWithoutPagination(null, 1, filter).then((res) => {
                if (res.data.length > 0) {
                    if (this.validationErrors.length === 0) {
                        this.validationErrors.push({ text: this.$translate.instant('TR_180419-8c5fbd_TR') });
                    }
                    if (this.callbackOnChange !== undefined) {
                        this.callbackOnChange();
                    }
                } else {
                    this.vhostObject.orchestratorObject.domainNameUnicode = ng.copy(
                        this.vhostObject.orchestratorObject.domainNameUnicode
                    );
                    if (this.callbackOnChange !== undefined) {
                        this.callbackOnChange();
                    }
                }
                this._domainNameChangeMethodeIsActiv = false;
            });
        } else {
            this._domainNameChangeMethodeIsActiv = false;
        }
    };

    private _setVhostObject = () => {
        if ([undefined, null].indexOf(this.vhostObject.orchestratorObject) >= 0
            || Object.keys(this.vhostObject.orchestratorObject).length === 0
        ) {
            this.vhostObject.orchestratorObject = {
                authCode: '',
                billingMode: 'bundle',
                createMode: 'vHostOnly',
                domainNameUnicode: '',
                type: 'Domain'
            };
        }
    };
}

export class OrganCreateConfigurationVhostOrchestratorComponent implements ng.IComponentOptions {
    public bindings = {
        _required: '<?required',
        callbackOnChange: '<?',
        completed: '=',
        contingent: '<?',
        disabled: '<',
        validationErrors: '=',
        vhostObject: '='
    };

    public template = require('./orchestrator-vhost-config.html');
    public controller =  OrganCreateConfigurationVhostOrchestratorController;
}
