import * as ng from 'angular';
import { ApiErrorModel, DocumentHelperService } from '../../../../../../services';
import { OverviewDataObject } from '../../../../../organisms/overview/overview';

import './invoice-row.scss';

export class MoleculeOverviewRowPanelBillingInvoiceController implements ng.IController {
    public static $inject: string[] = ['apiErrorModel', 'documentHelper'];

    public overviewData: OverviewDataObject;
    public overviewLoad: () => any;
    public internalPaginationLimit: number;

    constructor(
        private apiErrorModel: ApiErrorModel,
        private documentHelper: DocumentHelperService
    ) {}

    public $onDestroy = () => {
        this.apiErrorModel.destroyErrorList();
    };

    public downloadCsv = (invoice) => {
        this.documentHelper.csvDownloadInvoice(invoice);
    };

    public downloadPdf = (invoice) => {
        this.documentHelper.pdfDownloadInvoice(invoice);
    };

    public get errorList() {
        return this.apiErrorModel.errorObjectList;
    }
}

export class MoleculeOverviewRowPanelBillingInvoiceComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelBillingInvoiceController;
    public controllerAs = 'OverviewInvoiceCtrl';
    public template = require('./invoice-row.html');
}
