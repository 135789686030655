import { TldListService } from '../../services';

export enum CleanableStringType {
    DomainName = 'DomainName',
    DomainNames = 'DomainNames',
    FQDN = 'FQDN',
    FQDNs = 'FQDNs'
}

type CleanupCallback = (inputValue: string) => string;

/**
 * HelperClass to keep string attributes "clean".
 * Usage: StringCleaner.clean('https://www.example.com').as('DomainName');
 */
export class StringCleaner {
    public static clean: (inputValue: string) => { as: (type: CleanableStringType) => string }
    = (inputValue) => ({
        as: (type) => {
            let cleanupCallback: CleanupCallback;
            switch (type) {
                case CleanableStringType.DomainName:
                    cleanupCallback = domainNameCleanup;
                    break;

                case CleanableStringType.DomainNames:
                    cleanupCallback = domainNamesCleanup;
                    break;

                case CleanableStringType.FQDN:
                    cleanupCallback = fqdnCleanup;
                    break;

                case CleanableStringType.FQDNs:
                    cleanupCallback = fqdnsCleanup;
                    break;

                default:
                    cleanupCallback = (value: string) => value;
                    break;
            }

            return cleanupCallback(inputValue);
        }
    });
}

/** Cleanup callback for domain names. */
const domainNameCleanup = (inputValue: string) => {
    const indexAuthCode = inputValue.indexOf(';'); // First index of ';'
    let authCode; // Initialise authCode with undefined

    const tldListHelperService = new TldListService();
    const multiTldParts: string[] = [].concat(tldListHelperService.getMultiPartTlds(), tldListHelperService.getTlds());

    if (indexAuthCode >= 0) {
        authCode = inputValue.slice(indexAuthCode + 1, inputValue.length);
    }

    let domainName = inputValue
        // Cut off auth-code for sanitization.
        .split(';')[0]
        // Remove protocol, 'www.'' and trailing slashes
        .replace(/^(?:http(?:s)?:\/\/)?(?:www\.)?([^\/]*)(?:\/.*)?$/, '$1')
        // Replace spaces with dashes
        .replace(/ /g, '-');

    // Remove subdomain. Might be buggy, so I'll mark this as ToDo: Potentially FUBAR!
    const parts = domainName.split('.');
    if (parts.length > 2) {
        const tldCandidates = multiTldParts.filter((tld) => domainName.endsWith(tld));

        if (tldCandidates.length > 0) {
            const tldCandidate = tldCandidates.reduce((a: string, b: string) => a.length > b.length ? a : b);
            const startOffset = - (tldCandidate.split('.').filter((tldPart) => tldPart.length > 0).length + 1);
            domainName = parts.slice(startOffset).join('.');
        }
    }

    // Return domain with authcode.
    if (authCode !== undefined) {
        return domainName + ';' + authCode;
    }

    return domainName;
};

/** Cleanup callback for domain names. */
const domainNamesCleanup = (inputValue: string) => {
    inputValue = inputValue || '';

    return inputValue
        .split(/\r?\n/)                                     // Split into lines,
        .filter((line) => line.trim().length > 0)           // Discard empty lines
        .map(domainNameCleanup)                             // treat each line as a domain name to be cleaned,
        .filter((line, index, lines) => lines.indexOf(line) === index)  // Discard duplicate entries
        .join('\n');                                        // then join the lines back together.
};

/** Cleanup callback for FQDNs. */
const fqdnCleanup = (inputValue: string) => {
    inputValue = inputValue || '';

    inputValue = inputValue

    // Remove protocol, 'www.'' and trailing slashes
    .replace(/^(?:http(?:s)?:\/\/)?(?:www\.)?([^\/]*)(?:\/.*)?$/, '$1')

    // Replace spaces with dashes
    .replace(/ /g, '-');

    return (
        inputValue.indexOf('.') >= 0
        && inputValue.indexOf('.') !== (inputValue.length - 1)
        && inputValue.indexOf('.') !== 0
    )
        ? inputValue.trim()
        : '';
};

/** Cleanup callback for domain names. */
const fqdnsCleanup = (inputValue: any) => {
    inputValue = inputValue || '';

    return inputValue
        .split(/\r?\n/) // Split into lines,
        .map(fqdnCleanup) // treat each line as a domain name to be cleaned,
        .filter((line) => line.length > 0) // Discard empty lines
        .filter((line, index, lines) => lines.indexOf(line) === index) // Discard duplicate entries
        .join('\n'); // then join the lines back together.
};
