import * as ng from 'angular';
import './validation-error-list.scss';

export interface ValidationError {
    text: string;
}
export type ValidationErrorList = ValidationError[];

export class MoleculeListsValidationErrorListController implements  ng.IController {
    public static $inject: string[] = [];

    public showSingleError = false;
    public validationErrorList: ValidationErrorList;
}

export class MoleculeListsValidationErrorListComponent implements ng.IComponentOptions {
    bindings = {
        validationErrorList: '<',
        showSingleError: '<?'
    };
    public controller = MoleculeListsValidationErrorListController;
    public template = require('./validation-error-list.html');
}

