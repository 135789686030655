import * as ng from 'angular';

import './accordion.scss';

export class MoleculeAccordionController implements ng.IController {
    // Settings
    public accordionId: string;
    public currentOpenedPanel = "";
    public text: string;

    public handleOnClick(e: Event): void {
        e.preventDefault()
        if (this.currentOpenedPanel === this.accordionId) {
            this.currentOpenedPanel = '';
        } else {
            this.currentOpenedPanel = this.accordionId;
        }
    }

    public get isOpen(): boolean {
        return this.currentOpenedPanel === this.accordionId;
    }
}

export class MoleculeAccordionComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        accordionId: '@',
        currentOpenedPanel: '=',
        text: '@',
    };
    public controller =  MoleculeAccordionController;
    public template = require('./accordion.html');
}
