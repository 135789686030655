import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';

export class TemplateBundleStorageEditController {
    public static $inject: string[] = ['$translate', 'authContext'];

    public modifier = '';
    public pageHeaderData: PanelHeaderData;
    public webspace: any;
    public minimumStorage = 1024;
    public faqArticleIdList: string[] = [
        'webspace-expand-memory',
        'webspace-ftp-folders'
    ];

    public userPanelRight;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'bundle.id.dashboard',
            backwardText: this.$translate.instant('TR_090119-a32485_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: { bundleId: this.webspace.bundleId },
            panelIcon: 'save',
            panelTitle: this.$translate.instant('TR_STORAGE_TR'),
            productActions: []
        };

        this.userPanelRight = {
            storageQouta: {
                editPanelButton: this.authContext.isGranted(UiRights.WEB_WEBSPACE_EDIT),
                overrideStorageQuota: this.authContext.isGranted(UiRights.WEB_WEBSPACE_EDIT)
            }
        };
    };
}

export class TemplateBundleStorageEditComponent implements ng.IComponentOptions {
    public bindings = {
        minimumStorage: '<',
        webspace: '<'
    };
    public controller = TemplateBundleStorageEditController;
    public controllerAs = '$TemplateBundleStorageEditCtrl';
    public template = require('./storage-edit-template.html');
}
