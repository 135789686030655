import * as ng from 'angular';

export interface SeletionItem {
    name: string;
    description: string;
    value?: any;
}

export class MoleculeFormItemBoxNewGenericController implements ng.IController {
    public static $inject: string[] = [
        '$translate'
    ];
    public content: SeletionItem;
    public isSelected: boolean;

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {}

}

export class MoleculeFormItemBoxNewGenericComponent implements ng.IComponentOptions {
    public bindings = {
        content: '<',
        isSelected: '<'
    };
    public template = require('./item-box-new-generic.html');
    public controller =  MoleculeFormItemBoxNewGenericController;
}
