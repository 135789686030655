import * as ng from 'angular';
import {
    ErrorMapperAccountConst,
    ErrorMapperBillingConst,
    ErrorMapperDnsConst,
    ErrorMapperDomainConst,
    ErrorMapperEmailConst,
    ErrorMapperGeneralConst,
    ErrorMapperSslConst,
    ErrorMapperWebhostingConst
} from '../configuration';

export class ErrorMapperService {
    public static $inject: string[] = ['$translate'];
    public static map: any;
    public isTranslated = false;

    private errorParamObject: {[key: string]: any};

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {
        ErrorMapperService.map = {
            ...ErrorMapperAccountConst,
            ...ErrorMapperBillingConst,
            ...ErrorMapperDnsConst,
            ...ErrorMapperDomainConst,
            ...ErrorMapperEmailConst,
            ...ErrorMapperGeneralConst,
            ...ErrorMapperSslConst,
            ...ErrorMapperWebhostingConst
        };
    }

    public has(code) {
        return code in ErrorMapperService.map;
    }

    public map(error) {
        if (!(error.code in ErrorMapperService.map)) {
            // ErrorCode was not found in errorMapper
            return error.message + ' (' + error.code + ')';
        }

        /**
         *  In the Api Error object text parameters (placeholders/values which are replaced in the error message)
         *  are delivered, which are delivered as value parameters and details parameters (key value array).
         *  Why this is delivered as string (value-parameter) and as kex/value array (details-parameter) is not
         *  really clear to me.
         *
         *  According to Michi and Martin there is a system behind it, which I unfortunately couldn't follow.
         *  (10.01.2019)
         **/

        this.errorParamObject = {};
        if ([undefined, null].indexOf(error.value) < 0) {
            this._setErrorParam('value', error.value);
        }
        if ([undefined, null].indexOf(error.details) < 0) {
            error.details.map((errorDetail: {key: string; value: string}) => {
                this._setErrorParam(errorDetail.key, errorDetail.value);
            });
        }

        let errorTranslation = this.$translate.instant(ErrorMapperService.map[error.code], this.errorParamObject);

        if (error.code === 10901 && error.details?.length > 0 && error.details[0].value?.includes('Requested funds')) {
            errorTranslation = errorTranslation + ' ' + this.$translate.instant('TR_261020-6fb2b8_TR') + ' '
                + error.details[0].value
                    .replace('Requested funds: ', '')
                    .replace('.', ',')
                    .replace(/.*([A-Z]{3})(.*)/, '$2 $1');
        }

        return errorTranslation;
    }

    private _setErrorParam = (paramKey: string, paramValue: string) => {
        this.errorParamObject[paramKey] = paramValue;
    };
}
