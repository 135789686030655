import * as ng from 'angular';
import { ApiErrorModel, ApiErrorObject, ApiExpectedErrorList } from '../../../../services';
import './panel-error.scss';

export class MoleculePanelErrorController implements ng.IController {
    public static $inject: string[] = ['$timeout', 'apiErrorModel', '$translate'];

    public errorType: string;
    public errorBoxType: string;
    public errorBoxTitleTopic: string;
    public hideErrorBox: boolean;
    public expectedErrorList: ApiExpectedErrorList[] = [];
    private _errorListOuter: ApiErrorObject[];

    constructor(
        private $timeout: ng.ITimeoutService,
        private apiErrorModel: ApiErrorModel,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.errorType = this.errorType || 'fixed';
        this.errorBoxType = this.errorBoxType || 'general';
        this.hideErrorBox = false;
        this.apiErrorModel.expectedErrorList = this.expectedErrorList;
    }

    public $onChanges(changes: ng.IOnChangesObject) {
        if (changes._errorListOuter !== undefined) {
            const currentErrorList = changes._errorListOuter.currentValue;
            if ([undefined, null].indexOf(currentErrorList) === -1 && currentErrorList.length > 0) {
                currentErrorList.map((error: ApiErrorObject) => this.apiErrorModel.addErrorToList(error));
            }
        }
    }

    public $onDestroy = () => {
        this.apiErrorModel.destroyErrorList();
        this._errorListOuter = [];
    };

    public get errorList() {
        return this.apiErrorModel.errorObjectList;
    }

    public get visible() {
        return  this.errorList.length > 0 && !this.hideErrorBox;
    }

    public close = () => {
        this.hideErrorBox = true;
        this.$timeout(() => {
            this.apiErrorModel.destroyErrorList();
            this.hideErrorBox = false;
        }, 250); // css transition time => remove error messages after box is faded out
    };

    private setTitleTopic = () => {
        switch (this.errorType) {
            case 'update':
                this.errorBoxTitleTopic = this.$translate.instant('TR_100119-f9a83b_TR');
                break;
            case 'download':
                this.errorBoxTitleTopic = this.$translate.instant('TR_100119-f914cb_TR');
                break;
            case 'delete':
                this.errorBoxTitleTopic = this.$translate.instant('TR_100119-57e165_TR');
                break;
            case 'create':
                this.errorBoxTitleTopic = this.$translate.instant('TR_100119-2b724d_TR');
                break;
            case 'depositRechargeCancel':
                this.errorBoxTitleTopic = this.$translate.instant('TR_100119-b17de5_TR');
                break;
            default:
                this.errorBoxTitleTopic = this.$translate.instant('TR_100119-19be69_TR');
        }
    };

    public get errorBoxTitle() {
        this.setTitleTopic();

        return this.errorList.length === 1
            ? this.$translate.instant('TR_100119-c7c48f_TR') + ' ' + this.errorBoxTitleTopic
            : this.$translate.instant('TR_100119-4b9a1a_TR') + ' ' + this.errorBoxTitleTopic;
    }
}

export class MoleculePanelErrorComponent implements ng.IComponentOptions {
    public bindings = {
        _errorListOuter: '<?errorList',
        errorBoxType: '@?',
        errorType: '@?',
        expectedErrorList: '<?'
    };
    public controller =  MoleculePanelErrorController;
    public controllerAs = '$panelError';
    public template = require('./panel-error.html');
}
