import ng from 'angular';

import { DatabaseAccessObject } from '@/atomic-components/molecules/panels/panel-rows/panel-row-access';
import { DatabaseAccessLevelsAllRightsConst, DatabaseAccessLevelsAllRightsType } from '@/configuration';
import { ViewTypes } from '@/types/view-types';

export interface DatabaseNewAccessObject {
    accessLevel: string[];
    addDate?: string;
    databaseId: string;
    dbLogin?: string;
    lastChangeDate?: string;
    name?: string;
    read?: boolean;
    schema?: boolean;
    userId?: string;
    write?: boolean;
}

export interface DatabaseNewUserObject {
    accountId: string;
    addDate?: string;
    comments: string;
    dbUserName?: string;
    id?: string;
    lastChangeDate?: string;
    name: string;
    password: string;
    status?: string;
}

export class MoleculePanelRowDatabaseAccessSettingsController {
    public static $inject: string[] = ['$translate'];

    public onlyNewAccessAvailable = false;
    public databaseUserAccess: ViewTypes.DatabaseUserAccessObject;
    public databaseExistingUsers: { value: string; name: string }[];
    public accessTypeButtons: { value: ViewTypes.UserAccessType; label: string }[];
    public isValid = false;
    public accountId: string;
    public databaseId: string;

    private newDatabaseUserObject: DatabaseNewUserObject;
    private _newAccessObject: DatabaseAccessObject;

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = (): void => {
        if (this.databaseUserAccess === null) {
            this.databaseUserAccess = {
                userId: '',
                accessType: ViewTypes.UserAccessType.new,
                name: '',
                comments: '',
                password: '',
                accessLevel: {
                    read: false,
                    write: false,
                    schema: false
                }
            };
        }

        // ist nur pseudo object das für database-access.ts benötigt wird
        if (this.newDatabaseUserObject === null) {
            this.newDatabaseUserObject = {
                accountId: '',
                comments: '',
                name: '',
                password: ''
            };
        }

        if (this.databaseExistingUsers === null) {
            this.databaseExistingUsers = [];
        }

        this.accessTypeButtons = [
            { value: ViewTypes.UserAccessType.existing, label: this.$translate.instant('TR_110119-d043dc_TR') },
            { value: ViewTypes.UserAccessType.new, label: this.$translate.instant('TR_110119-b61be5_TR') }
        ];
    };

    public get newAccessObject(): DatabaseAccessObject {
        return this._newAccessObject;
    }

    public set newAccessObject(value: DatabaseAccessObject) {
        if (!value) {
            this._newAccessObject = value;
            return;
        }

        for (const right of DatabaseAccessLevelsAllRightsConst) {
            this.databaseUserAccess.accessLevel[
                (right as DatabaseAccessLevelsAllRightsType)
            ] = value[(right as DatabaseAccessLevelsAllRightsType)];

            delete(value[(right as DatabaseAccessLevelsAllRightsType)]);
            Object.defineProperty(
                value,
                right,
                {
                    get: () => this.databaseUserAccess.accessLevel[(right as DatabaseAccessLevelsAllRightsType)],
                    set: (newRightValue) => {
                        this.databaseUserAccess.accessLevel[
                            (right as DatabaseAccessLevelsAllRightsType)
                        ] = newRightValue;
                    }
                }
            );
        }

        this._newAccessObject = value;
    }

    public usernameChangedCallback = (name: string): void => {
        if (this.databaseUserAccess.name !== name) {
            this.databaseUserAccess.name = name;
        }
    };

    public userPasswordChangedCallback = (password: string): void => {
        if (this.databaseUserAccess.password !== password) {
            this.databaseUserAccess.password = password;
        }
    };

    public userCommentChangedCallback = (comment: string): void => {
        if (this.databaseUserAccess.comments !== comment) {
            this.databaseUserAccess.comments = comment;
        }
    };
}

export class MoleculePanelRowDatabaseAccessSettingsComponent implements ng.IComponentOptions {
    public bindings = {
        accountId: '<',
        databaseUserAccess: '=',
        databaseExistingUsers: '=?',
        isValid: '=',
        onlyNewAccessAvailable: '<?'
    };
    public template = require('./database-access-settings.html');
    public controller = MoleculePanelRowDatabaseAccessSettingsController;
}
