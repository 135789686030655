import * as ng from 'angular';
import { InputPlaceholderConst } from '../../../../../../configuration';
import {
    DomainInfoHelperService,
    DomainModelService,
    TldListService,
    ValidateSubDomain,
    VhostModelService
} from '../../../../../../services';
import * as Types from '../../../../../../types';
import {
    MoleculeFormDropDownRevisedComponent,
    MoleculeFormDropDownRevisedController
} from '../drop-down-revised';

type ListResult = { data: any; pagination: { currentPage: any; entries: any; limit: any } };

export class MoleculeFormDropDownRevisedSubdomainController extends MoleculeFormDropDownRevisedController {
    public static $inject: string[] = [
        '$element',
        '$timeout',
        '$translate',
        '$window',
        'domainInfoHelper',
        'domainModel',
        'tldList',
        'vhostModel'
    ];

    // Checks & validation.
    public checkedDomainResponse: Types.DomainApi.Domain;
    public accountId: string;
    public webspaceId: string;
    public inputPlaceholder = InputPlaceholderConst;
    public filterValue: string;

    public callbackOnBlur: (...args: any[]) => any;

    /**
     * Validator.
     */
    public validationErrorList: any[] = [];
    public subdomainValidator: ValidateSubDomain;
    public domainList: ListResult;
    public fuzzySearch = true;

    /**
     * Construct.
     */
    constructor(
        public $element: ng.IAugmentedJQuery,
        public $timeout: ng.ITimeoutService,
        public $translate: ng.translate.ITranslateService,
        public $window: ng.IWindowService,
        private domainInfoHelper: DomainInfoHelperService,
        public domainModel: DomainModelService,
        public tldList: TldListService,
        public vhostModel: VhostModelService
    ) {
        super($element, $timeout, $translate, $window);
    }

    /**
     * Initialize.
     */
    public $onInit() {
        if ([undefined, null].indexOf(this.subdomainValidator) >= 0) {
            this.subdomainValidator = new ValidateSubDomain(
                this.$translate,
                this.domainInfoHelper,
                this.tldList
            );
        }
    }

    public $onChanges(changes: ng.IOnChangesObject) {
        if (changes.webspaceId && changes.webspaceId.currentValue) {
            this._getVhostOptions(changes.webspaceId.currentValue);
        }
    }

    public mapVhostDomains = (vhost: {domainNameUnicode?: string, domainName?: string}) => {
        return vhost.domainNameUnicode ? vhost.domainNameUnicode : vhost.domainName;
    };

    public onlyDomains = (domain: string) => {
        return domain.split('.').length <= 2;
    };

    public get completionOptions(): any[] {
        if ([null, undefined].indexOf(this.options) < 0) {
            return this.options
                .filter(this.filterMatches)
                .map(this.transformMatches);
        }
        return [];
    }

    public filterMatches = (option: any) => {
        const optionString = this.optionValueToString(option);
        const modelString = this.modelValueToString(this.model);
        const searchableParts = modelString.split('.');
        searchableParts.shift();
        if (this.fuzzySearch) {
            return optionString.includes(searchableParts.join('.'));
        } else {
            return optionString.startsWith(searchableParts.join('.'));
        }
    };

    public transformMatches = (option: string) => {
        return `${this.modelValueToString(this.model).split('.').shift()}.${option}`;
    };

    public validate = () => {
        if (this.modelValueToString(this.model).length > 0) {
            this.validationErrorList = this.subdomainValidator.validate(this.modelValueToString(this.model));
        }
    };

    public onBlur = () => {
        // Unclear why this timeout of 200ms is needed; PUI-7288
        this.$timeout(() => {
            this.close();
        }, 200).then(() => {
            this.validate();
            // The following timeout is necessary to call calback in next frame, so that this.validationErrorList is already updated when callback happens.
            this.$timeout(() => {
                if (this.callbackOnBlur) {
                    this.callbackOnBlur(this.model);
                }
            });
        })
        return;
    };

    public lostFocus = () => {
        this.onBlur();
    };

    public onBackgroundClick = () => {
        this.onBlur();
    };

    public onFocus = () => {
        this.open();
    };

    /**
     * Select an option.
     */
    public select = (optionIndex: number): boolean => {
        if (this.filterValue !== '' && this.filteredOptions.length <= 0) {
            this.resetTabIndexToTop();
        }

        const optionFiltered = this.optionValueToString(this.completionOptions[optionIndex]).split('.');
        optionFiltered.shift();

        if (this.modelValueToString(this.model).includes('.')) {
            this.model = this.modelValueToString(this.model).split('.').shift();
        } else if ([undefined, null].indexOf(this.model) >= 0) {
            this.model = '';
        }
        this.model = `${this.model}.${optionFiltered.join('.')}`;

        this.close();
        return true;
    };

    public handleKeyboardInputSubdomain = (event: KeyboardEvent) => {
        if (event.code === 'Period') {
            this.open();
        }

        this.handleKeyboardInput(event);
    };

    private _getVhostOptions = async (webspaceId: string) => {
        const domainParts = this.modelValueToString(this.model).split('.');
        if (domainParts.length > 2) {
            const tmpSubdomainParts = domainParts.slice(0, domainParts.length - 2);
            this.model = tmpSubdomainParts.join('.');
        } else {
            this.model = '';
        }

        const vhosts = await this.vhostModel.listWithoutPagination(null, null, null, null, webspaceId);
        this.options = vhosts.data.map(this.mapVhostDomains).filter(this.onlyDomains);
    };
}

export class MoleculeFormDropDownRevisedSubdomainComponent extends MoleculeFormDropDownRevisedComponent {
    public transclude = {
        label: 'rowLabel'
    };

    public bindings: any = {
        ...this.bindings,
        accountId: '<',
        webspaceId: '<',
        validationErrorList: '=',
        callbackOnBlur: '<'
    };

    public controller = MoleculeFormDropDownRevisedSubdomainController; public template = require('./drop-down-revised-subdomain.html');
}
