import { AuthContextService } from '../../../services';

import * as ng from 'angular';

const suppliers = {
    'hosting.de': [
        {
            address: '<b>moving internet GmbH</b><br />Franzstr. 51<br />52064 Aachen<br />Deutschland',
            services: 'Management'
        },
        {
            address: '<b>http.net Internet GmbH</b><br />Franzstr. 51<br />52064 Aachen<br />Deutschland',
            services: 'Domainregistrierungen, SSL-Zertifikate'
        },
        {
            address: '<b>keenlogics GmbH</b><br />Franzstr. 51<br />52064 Aachen<br />Deutschland',
            services: 'Softwareentwicklung'
        },
        {
            address: '<b>A&O Fischer GmbH & Co. KG</b><br />Maybachstraße 9<br />21423 Winsen (Luhe)<br />Deutschland',
            services: 'Postversand'
        },
        {
            address: '<b>City Inkasso GmbH</b><br />Königsteiner Str. 22<br />65929 Frankfurt am Main<br />Deutschland',
            services: 'Inkassodienstleistungen'
        }
    ],
    'http.net': [
        {
            address: '<b>moving internet GmbH</b><br />Franzstr. 51<br />52064 Aachen<br />Deutschland',
            services: 'Management'
        },
        {
            address: '<b>Moving Domains GmbH</b><br />Franzstr. 51<br />52064 Aachen<br />Deutschland',
            services: 'Domainregistrierungen, SSL-Zertifikate'
        },
        {
            address: '<b>keenlogics GmbH</b><br />Franzstr. 51<br />52064 Aachen<br />Deutschland',
            services: 'Softwareentwicklung'
        },
        {
            address: '<b>hosting.de GmbH</b><br />Franzstr. 51<br />52064 Aachen<br />Deutschland',
            services: 'Serverdienstleistungen'
        },
        {
            address: '<b>A&O Fischer GmbH & Co. KG</b><br />Maybachstraße 9<br />21423 Winsen (Luhe)<br />Deutschland',
            services: 'Postversand'
        },
        {
            address: '<b>City Inkasso GmbH</b><br />Königsteiner Str. 22<br />65929 Frankfurt am Main<br />Deutschland',
            services: 'Inkassodienstleistungen'
        },
        {
            address: '<b>ABCDomain LLC.</b><br />4 Hrachya Kochar str, "Barekamutyun" Business Center, 2nd floor<br />Yerevan<br />Armenia',
            services: 'Domaindienstleistungen'
        },
        {
            address: '<b>Ascio Technologies, Inc. Danmark</b><br />&Oslash;restads Boulevard 108, 10. Sal.<br />Copenhagen S<br />Denmark',
            services: 'Domaindienstleistungen'
        },
        {
            address: '<b>ELB Tunisie</b><br />Espace Tunis Bloc C app 4-1<br />Tunis<br />TN.',
            services: 'Domaindienstleistungen'
        },
        {
            address: '<b>EPAG Domainservices GmbH</b><br />Niebuhrstr. 16b<br />53133 Bonn',
            services: 'Domaindienstleistungen'
        },
        {
            address: '<b>Internet Invest Ltd (Imena.ua)</b><br />Gaidara St. 50<br />01033 Kiev<br />Ukraine',
            services: 'Domaindienstleistungen'
        },
        {
            address: '<b>JSC &laquo;RU-CENTER&raquo;</b><br />2/1, 3d Khoroshevskaya str.<br />123308 Moscow<br />Russian Federation',
            services: 'Domaindienstleistungen'
        },
        {
            address: '<b>Open Contact Ltd.</b><br />17 Kalvariyskaya St., office 610a<br />Minsk<br />Belarus',
            services: 'Domaindienstleistungen'
        },
        {
            address: '<b>PAPAKI INTERNET SERVICES LTD</b><br />INDUSTRIAL AREA - S STREET<br />P.C. 71601 HERACLION CRETE<br />Greece',
            services: 'Domaindienstleistungen'
        },
        {
            address: '<b>REGica.NET d.o.o</b><br />Garićgradska 18<br />10000 Zagreb<br />Croatia',
            services: 'Domaindienstleistungen'
        },
        {
            address: '<b>Security Database BV</b><br />Engelandlaan 41<br />2034 PG Haarlem<br />The Netherlands',
            services: 'Domaindienstleistungen'
        },
        {
            address: '<b>Silicium Network Informatikai Kft.</b><br />1137 Budapest<br />Radnóti Miklós utca 11. Fsz. 2.<br />Hungary',
            services: 'Domaindienstleistungen'
        },
        {
            address: '<b>Toweb Sarl</b><br />11-13 Boulevard de la Foire<br />L-1528 Luxembourg',
            services: 'Domaindienstleistungen'
        },
        {
            address: '<b>Webglobe &ndash; Yegon, s.r.o.</b><br />Stará Prievozská 2<br />821 09 Bratislava<br />Slovakia',
            services: 'Domaindienstleistungen'
        }
    ],
    'other': [
        {
            address: '<b>moving internet GmbH</b><br />Franzstr. 51<br />52064 Aachen<br />Deutschland',
            services: 'Management'
        },
        {
            address: '<b>http.net Internet GmbH</b><br />Franzstr. 51<br />52064 Aachen<br />Deutschland',
            services: 'Domainregistrierungen, SSL-Zertifikate'
        },
        {
            address: '<b>keenlogics GmbH</b><br />Franzstr. 51<br />52064 Aachen<br />Deutschland',
            services: 'Softwareentwicklung'
        },
        {
            address: '<b>hosting.de GmbH</b><br />Franzstr. 51<br />52064 Aachen<br />Deutschland',
            services: 'Serverdienstleistungen'
        },
        {
            address: '<b>A&O Fischer GmbH & Co. KG</b><br />Maybachstraße 9<br />21423 Winsen (Luhe)<br />Deutschland',
            services: 'Postversand'
        },
        {
            address: '<b>City Inkasso GmbH</b><br />Königsteiner Str. 22<br />65929 Frankfurt am Main<br />Deutschland',
            services: 'Inkassodienstleistungen'
        }
    ]
};

export class SuppliersController {
    public static $inject: string[] = ['authContext'];

    constructor(
        private authContext: AuthContextService
    ) {}

    public get company() {
        switch (this.authContext.account.parentAccountId) {
            case '3': return 'http.net';
            case '4': return 'hosting.de';
            default: return 'other';
        }
    }

    public get suppliers() {
        return suppliers[this.company];
    }
}

export class SuppliersComponent implements ng.IComponentOptions {
    public template = require('./suppliers.html');
    public controller = SuppliersController;
    public controllerAs = '$suppliers';
}
