import * as ng from 'angular';

export class OrganEditPanelUserAdminController implements ng.IController {
    public adminUser: boolean;
    public cancelCallbackFunction: () => void;
    public ownPassword: string;
    public panelRightSettings;
    public validationInstructions: any[];
}

export class OrganEditPanelUserAdminComponent implements ng.IComponentOptions {
    public bindings = {
        adminUser: '=',
        cancelCallbackFunction: '<',
        ownPassword: '=',
        panelRightSettings: '<'
    };
    public template = require('./edit-panel-user-admin.html');
    public controller = OrganEditPanelUserAdminController;
}
