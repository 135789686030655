import { AccountFilterField, ContactFilterField, GroupedSelectFilterField, SelectFilterField, TextFilterField } from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { JobOverviewModel, OverviewModel } from '../abstract';
import { RequestCancellerService } from '../request-canceller';

export class DatabaseJobOverviewModel extends JobOverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', 'databaseJobModel']
    );

    readonly service = 'databaseJob';

    protected listCallbacks = [{callback: this.databaseJobModel.list}];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private databaseJobModel: any
    ) {
        super(RequestCanceller);
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('jobDisplayName', this.$translate.instant('TR_140119-796b28_TR')),
                new SelectFilterField('jobObjectType', this.$translate.instant('TR_070519-16189c_TR'), [{
                    name: this.$translate.instant('TR_140119-f11303_TR'),
                    value: 'Database'
                }, {
                    name: this.$translate.instant('TR_130319-0cb933_TR'),
                    value: 'User'
                }]),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR')),
                new SelectFilterField('jobAction', this.$translate.instant('TR_140119-3b64a8_TR'), [{
                    name: this.$translate.instant('TR_140119-50d91c_TR'),
                    value: 'create'
                }, {
                    name: this.$translate.instant('TR_140119-aee71e_TR'),
                    value: 'delete'
                }, {
                    name: this.$translate.instant('TR_140119-2e74fb_TR'),
                    value: 'update'
                }, {
                    name: this.$translate.instant('TR_140119-447f57_TR'),
                    value: 'purgeRestorable'
                }, {
                    name: this.$translate.instant('TR_140119-d46abf_TR'),
                    value: 'deleteScheduled'
                }, {
                    name: this.$translate.instant('TR_140119-2c5206_TR'),
                    value: 'deletionCancel'
                }, {
                    name: this.$translate.instant('TR_140119-d1b7a4_TR'),
                    value: 'restore'
                }, {
                    name: this.$translate.instant('TR_140119-19b280_TR'),
                    value: 'blockWriteAccess'
                }, {
                    name: this.$translate.instant('TR_140119-b41b28_TR'),
                    value: 'unblockWriteAccess'
                }, {
                    name: this.$translate.instant('TR_140119-224dfc_TR'),
                    value: 'wipe'
                }]),
                new SelectFilterField('jobStatus', this.$translate.instant('TR_140119-8e4b60_TR'), [{
                    name: this.$translate.instant('TR_140119-51059b_TR'),
                    value: 'timed'
                }, {
                    name: this.$translate.instant('TR_140119-26e9f2_TR'),
                    value: 'new'
                }, {
                    name: this.$translate.instant('TR_140119-10445a_TR'),
                    value: 'inProgress'
                }, {
                    name: this.$translate.instant('TR_140119-8a7a61_TR'),
                    value: 'successful'
                }, {
                    name: this.$translate.instant('TR_140119-e55687_TR'),
                    value: 'failed'
                }, {
                    name: this.$translate.instant('TR_140119-41c7bb_TR'),
                    value: 'support'
                }, {
                    name: this.$translate.instant('TR_140119-a1c5c1_TR'),
                    value: 'canceled'
                }, {
                    name: this.$translate.instant('TR_140119-8ff740_TR'),
                    value: 'done'
                }])
            ];

        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }

}
