import { UiRights } from '@/configuration';
import * as ng from 'angular';
import * as q from 'q';
import {
    AlertManagerService, ApiErrorModel, AuthContextService, CacheService, DateWrapper, NavigationService
} from '../../../../../services';
import { ManagedApplicationRobotService } from '../../../../../services/managed-application/robot';
import * as Types from '../../../../../types';

export class OrganEditPanelNextcloudRestoreController {
    public static $inject: string[] = [
        '$state',
        '$translate',
        'alertManager',
        'apiErrorModel',
        'cache',
        'managedApplicationRobot',
        'navigation'
    ];

    public deleteDate: DateWrapper;
    public deletionConfirmed = false;
    public disableConfirmAction = false;
    public hasAgreedToRestoreWebspace = false;
    public nextcloud: Types.ManagedApplicationApi.Nextcloud;
    public panelRight;
    public selectedRestoreAction = '';
    public userHasRightDelete = false;
    public userHasRightRestore = false;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private cache: CacheService,
        private managedApplicationRobot: ManagedApplicationRobotService,
        private navigation: NavigationService
    ) {}

    public $onInit = () => {
        this.disableConfirmAction = false;
        this.userHasRightDelete = AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_DELETE);
        this.userHasRightRestore = AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_RESTORE);
    };

    public restoreOrPurge = () => {
        this.disableConfirmAction = true;
        this.apiErrorModel.destroyErrorList();
        if (this.selectedRestoreAction === 'restore') {
            this.managedApplicationRobot
                .nextcloudRestore(this.nextcloud.id)
                .then(
                    (apiResponse) => {
                        this.cache.get('managedapplication::nextcloudsFind').clear();
                        this.alertManager.success(this.$translate.instant('TR_100119-6a9a06_TR'));
                        this.navigation.go(
                            this.$state.current.name.indexOf('storage-products') >= 0
                                ? 'storage.storage-products.overview'
                                : 'nextcloud.overview',
                            null,
                            { reload: true }
                        );
                    },
                    (error) => {
                        this.disableConfirmAction = true;
                        return q.reject(error);
                    }
                );
        } else if (this.selectedRestoreAction === 'purge') {
            this.managedApplicationRobot
                .nextcloudPurgeRestorable(this.nextcloud.id)
                .then(
                    (apiResponse) => {
                        this.cache.get('managedapplication::nextcloudsFind').clear();
                        this.alertManager.success(this.$translate.instant('TR_100119-d6dbda_TR'));
                        this.navigation.go(
                            this.$state.current.name.indexOf('storage-products') >= 0
                                ? 'storage.storage-products.overview'
                                : 'nextcloud.overview',
                            {},
                            { reload: true }
                        );
                    },
                    (error) => {
                        this.disableConfirmAction = true;
                        return q.reject(error);
                    }
                );
        }
    };

    public get hasAgreedToDeleteEverything() {
        return this.deletionConfirmed && this.deleteDate !== undefined;
    }

    public cancelTerminate = () => {
        this.deletionConfirmed = false;
    };
}

export class OrganEditPanelNextcloudRestoreComponent implements ng.IComponentOptions {
    public bindings = {
        nextcloud: '<',
        panelRight: '='
    };
    public controller = OrganEditPanelNextcloudRestoreController;
    public template = require('./nextcloud-restore.html');
}
