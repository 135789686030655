import * as ng from 'angular';
import { ResourceModelService } from '../../../../services';
import { LinkListItem } from '../../links/link-list/link-list';

export interface ManagedMachineDashboardDataObject {
    linkList: {
        top: LinkListItem[];
        Footer: LinkListItem[];
    };
}

export class MoleculePanelManagedMachineDashboardController implements ng.IController {
    public static $inject: string[] = ['$translate', '$q', 'resourceModel'];

    public machineItem;
    public machineStatus = 'creating';
    public serverRessorce = null;
    public showTechInfo: boolean;
    public linkListFooter: LinkListItem[] = [];
    public OrganismDashboardOverview;
    public panelHeader = {
        includeShowDetailsLink: false,
        panelHeaderRoute: '',
        panelHeaderRouteParams: {}
    };

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private $q: ng.IQService,
        private resourceModel: ResourceModelService
    ) {}

    public $onInit() {
        this.showTechInfo = this.showTechInfo || false;
        const machine = this.machineItem;
        const machineId = machine.id;

        this.linkListFooter = [];

        this._installStatus();
    }

    public get showAccounts() {
        return (
            [undefined, null].indexOf(this.OrganismDashboardOverview) >= 0
            || [undefined, null].indexOf(this.OrganismDashboardOverview.globals) >= 0
        )
        ? false
        : this.OrganismDashboardOverview.globals.showAccounts;
    }

    private _installStatus = () => {
        const promises = [];

        promises.push(this._getDatabaseRessource());
        promises.push(this._getWebserverRessource());

        this.$q.all(promises).then((ressources) => {
            ressources.some((ressource) => {
                if (ressource === null) {
                    return false;
                }

                this.serverRessorce = ressource;
                return true;
            });

            if (this.serverRessorce !== null) {
                this.machineStatus = 'installed';
                this.linkListFooter = [{
                    route: 'managed-servers.id.new-project',
                    routeParams:  {machineId: this.machineItem.id},
                    text: this.$translate.instant('TR_140319-90e2cb_TR')
                }];
                this.panelHeader = {
                    includeShowDetailsLink: true,
                    panelHeaderRoute: 'managed-servers.id.dashboard',
                    panelHeaderRouteParams: {machineId: this.machineItem.id}
                };
            }
        });
    };

    private _getDatabaseRessource = () => {
        return this.resourceModel.resourceList(
            null,
            'Database',
            null,
            null,
            { field: 'virtualMachineId', value: this.machineItem.id }
        ).then((res) => {
            return res.data.length !== 0
                ? res.data[0]
                : null;
        });
    };

    private _getWebserverRessource = () => {
        return this.resourceModel.resourceList(
            null,
            'Webserver',
            null,
            null,
            { field: 'virtualMachineId', value: this.machineItem.id }
        ).then((res) => {
            return res.data.length !== 0
                ? res.data[0]
                : null;
        });
    };
}

export class MoleculePanelManagedMachineDashboardComponent implements ng.IComponentOptions {
    public bindings = {
        linkListBottom: '<',
        linkListTop: '<',
        machineItem: '<',
        showTechInfo: '<'
    };
    public require = {
        OrganismDashboardOverview: '^?organismDashboardOverviews'
    };
    public controller =  MoleculePanelManagedMachineDashboardController;
    public template = require('./panel-managed-machine-dashboard.html');
}
