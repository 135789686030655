import * as ng from 'angular';

import { AuthContextService } from '../../../../../../services';
import { OrganismDashboardOverviewsController } from '../../../../../organisms/overview';
import { LinkListItem } from '../../../../links';

export class MoleculeOverviewRowPanelDashboardNetworksController implements ng.IController {

    public get showLinkToOverview() {
        return this.serviceItemList.length > 0;
    }

    public get showAccounts() {
        return this.OrganismDashboardOverview.globals.showAccounts;
    }
    public static $inject: string[] = ['$translate'];

    public serviceItemList;
    public OrganismDashboardOverview: OrganismDashboardOverviewsController;
    public showObjectId: boolean;

    private actionLinkLists: { [key: string]: LinkListItem[] } = {};

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {}

    public actionLinkList: (item: any) => LinkListItem[]
    = (item) => {
        if (!this.actionLinkLists[item.id]) {
            this.actionLinkLists[item.id] = [];
        }

        return this.actionLinkLists[item.id];
    };

    public noNetworksOnDashboard = () => {
        return this.serviceItemList.length === 0;
    };
}

export class MoleculeOverviewRowPanelDashboardNetworksComponent implements ng.IComponentOptions {
    public require = {
        OrganismDashboardOverview: '^organismDashboardOverviews'
    };
    public bindings = {
        serviceItemList: '<',
        showObjectId: '<'
    };
    public controller =  MoleculeOverviewRowPanelDashboardNetworksController;
    public controllerAs = 'DashboardOverviewCtrl';
    public template = require('./dashboard-networks.html');
}
