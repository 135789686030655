import * as ng from 'angular';
import { InputPlaceholderConst } from '../../../../configuration';

import './panel-password-reset.scss';

export class OrganPanelPasswordResetController {
    public static $inject: string[] = [];

    public InputPlaceholder = InputPlaceholderConst;
    public email: string;
    public requestSent: boolean;
    public password: string;
    public code: any; // Boolean?

    private $editForm: { save: () => void };

    public submit = () => {
        this.$editForm.save();
    };
}

export class OrganPanelPasswordResetComponent implements ng.IComponentOptions {
    public bindings = {
        code: '<',
        email: '=',
        password: '=',
        requestSent: '<'
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public template = require('./panel-password-reset.html');
    public controller = OrganPanelPasswordResetController;
}
