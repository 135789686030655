import * as ng from 'angular';
import * as q from 'q';
import {
    UiLanguageDefaultSettingsConst,
    UiLanguagesConst,
    UiLanguageSwitchConst,
    UiRights
} from '../../../../../configuration';
import {
    AlertManagerService,
    ApiErrorModel,
    ApiKeyOverviewModelService,
    AuthContextService,
    AuthenticationRobotService,
    LanguageTypes,
    NavigationService,
    TwoFaHelperService,
    UserModelService,
    UserSettingsManagerService
} from '../../../../../services';
import { FormDropDownItems, PanelType } from '../../../../molecules';
import { twoFAFormStatus } from '../../../../organs';
import * as Types from '@/types';

export class OrganEditPanelProfileController implements ng.IController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'apiErrorModel',
        'authenticationRobot',
        'twoFaHelper',
        'userModel',
        'apiKeyOverviewModel',
        'navigation',
        'userSettingsManager'
    ];

    public userHasRightAccAccountApikeysList = AuthContextService.isGranted(
        UiRights.ACC_ACCOUNT_APIKEYS_LIST
    );
    public userHasRightAccSubaccountApikeysList = AuthContextService.isGranted(
        UiRights.ACC_SUBACCOUNT_APIKEYS_LIST
    );
    public tmpUser: any;
    public password = '';
    public currentPassword = '';
    public uiLanguages: FormDropDownItems[] = [];

    public changeEmailAddress = false;
    public changePassword = false;

    public apiKeyOverviewServiceData: any;
    public overviewUiSettings: any;
    public overviewUiDisplaySettings: any;

    public twoFAData: any = {
        canEdit: true,
        confirmationCode: '',
        formStatus: twoFAFormStatus.BASE,
        passcodes: [],
        password: '',
        qrUrlString: '',
        status: '',
        token: '',
        userId: ''
    };

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private authenticationRobot: AuthenticationRobotService,
        private twoFaHelper: TwoFaHelperService,
        private userModel: UserModelService,
        public apiKeyOverviewModel: ApiKeyOverviewModelService,
        private navigation: NavigationService,
        private userSettingsManager: UserSettingsManagerService
    ) {
        this.resetTmpUser();

        for (const languageIso in UiLanguageSwitchConst) {
            // eslint-disable-next-line no-prototype-builtins
            if (UiLanguageSwitchConst.hasOwnProperty(languageIso)) {
                this.uiLanguages.push(
                    {
                        name: this.$translate.instant(UiLanguageSwitchConst[languageIso].translationId),
                        value: languageIso
                    }
                );
            }
        }

        this.twoFAData.userId = AuthContextService.user.id;
        this.twoFAData.status = AuthContextService.user.twoFactorAuthStatus;
    }

    public $onInit = (): void => {
        this.apiKeyOverviewServiceData = {
            extendedFilters: null,
            objectId: AuthContextService.user.id,
            overviewUiDisplaySettings: this.overviewUiDisplaySettings,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: {value: ''}
        };
    };

    public get readableLanguage(): string {
        let language = this.$translate.instant(UiLanguageDefaultSettingsConst.translationId);
        for (const languageIso in UiLanguageSwitchConst) {
            if (languageIso === this.user.language) {
                language = this.$translate.instant(UiLanguageSwitchConst[languageIso].translationId);
            }
        }
        return language;
    }

    public get panelType(): number {
        return PanelType.MISCELLANEOUS_ACTION;
    }

    public get changeAccessData(): boolean {
        return this.changePassword || this.changeEmailAddress;
    }

    public get user(): Types.AccountApi.User {
        return AuthContextService.user;
    }

    public resetTmpUser = (): void => {
        this.tmpUser = ng.copy(AuthContextService.user);
    };

    public resetLogin = (): void => {
        this.tmpUser = ng.copy(AuthContextService.user);
        this.password = '';
        this.currentPassword = '';
        this.changePassword = false;
        this.changeEmailAddress = false;
    };

    public saveGeneral = (): PromiseLike<void> => {
        const language: LanguageTypes = UiLanguagesConst[this.tmpUser.language];
        return this.updateOwnProfile(this.tmpUser).then(
            (response) => {
                this.$translate.use(language);
                this.alertManager.success(this.$translate.instant('TR_210119-978204_TR'));
                return response;
            }
        );
    };

    public saveAuthentication = (): PromiseLike<void> => {
        if (!this.changeEmailAddress && !this.changePassword) {
            return this.updateOwnProfile(this.tmpUser);
        }
        if (
            [undefined, null, ''].indexOf(this.currentPassword) >= 0
        ) {
            return Promise.reject();
        }

        const promises = [];

        if (this.changeEmailAddress && this.user.emailAddress !== this.tmpUser.emailAddress) {
            promises.push(
                this.userModel.userChangeOwnEmailAddress(this.tmpUser.emailAddress, this.currentPassword)
                .then(this.authenticationRobot.refreshAccountData)
            );
        }

        if (this.changePassword && [undefined, null, ''].indexOf(this.password) < 0) {
            promises.push(
                this.userModel.setOwnSecret(this.currentPassword, this.password)
            );
        }

        return q.all(promises).then((res) => {
            return this.updateOwnProfile(this.tmpUser);
        });
    };

    public get showApiKeyListPanel(): boolean {
        if (AuthContextService.isOwnUser(this.user)) {
            return this.userHasRightAccAccountApikeysList;
        } else {
            return this.userHasRightAccSubaccountApikeysList;
        }
    }

    private updateOwnProfile = (userProfile: any): PromiseLike<void> => {
        return this.userModel.updateOwnProfile(
            {
                language: userProfile.language,
                name: userProfile.name
            }
        )
            .then(() => this.authenticationRobot.refreshAccountData())
            .then((refreshResponse) => {
                this.alertManager.success(
                    this.$translate.instant('TR_080619-464988_TR')
                );
                this.navigation.reloadCurrentState();
                return refreshResponse;
            });
    };
}

export class OrganEditPanelProfileComponent implements ng.IComponentOptions {
    public bindings = {
        overviewUiDisplaySettings: '<',
        overviewUiSettings: '<'
    };

    public template = require('./profile.html');
    public controller = OrganEditPanelProfileController;
}
