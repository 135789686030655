import * as ng from 'angular';
import { WizardObject } from '../../wizard-supervisor/wizard-supervisor';

export class MoleculeWizardSummaryItemDomainController implements ng.IController {
    public static $inject: string[] = [];
    public serviceObject: WizardObject;

}

export class MoleculeWizardSummaryItemDomainComponent implements ng.IComponentOptions {
    public bindings = {
        serviceObject: '='
    };
    public template = require('./wizard-summary-domain.html');
    public controller =  MoleculeWizardSummaryItemDomainController;
}
