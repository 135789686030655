import * as ng from 'angular';
import * as q from 'q';

import {
    BundleContingentProductCodesConst, UiLanguageDefaultSettingsConst, UiLanguagesConst, UIRegExp
} from '@/configuration';
import { AuthContextService } from '@/services/auth-context';
import { PriceCacheService } from '@/services/billing/price-cache';
import { BundleModelService } from '@/services/bundle';
import { BillingHelperService, ProductHelperService } from '@/services/helpers';
import { ProductsModelService } from '@/services/products/products-model';
import * as Types from '@/types';

export interface SelectBoxContent {
    name: string;
    family: string;
    description: string;
    shortDescription: string;
    summaryKeywords: any;
    specificationItems: any[];
}

export interface FamilyBoxContent extends SelectBoxContent {
    products: ProductBoxContent[];
}

export interface ProductBoxContent extends SelectBoxContent {
    billingCycles?: number[];
    productCodeTemplate: string;
    prices: PriceObject[];
    factSheetItems: any;
    keywordsDescriptions?: any;
}

export interface PriceObject {
    productCode: string;
    price: any;
    billingCycle: number;
}

export interface SelectedProduct {
    description?: string;
    factSheetItems?: FactSheetItems[];
    family: string;
    keywordsDescriptions?: KeywordsDescriptions[];
    name: string;
    prices: PriceObject[];
    productCodeTemplate?: string;
    productImage?: string;
    recommend?: boolean;
    selectedPrice: PriceObject;
    service?: string; // after deploying all wizards, this is non optional
    shortDescription: string;
    specificationItems?: any[];
    verificationAlternatives?: [][];
}

export interface FactSheetItems {
    category: string;
    keyword: string;
    productCode: string;
    value: number;
}

export interface KeywordsDescriptions {
    category: string;
    description: string;
    keyword: string;
    language: string;
    name: string;
    type: string;
}

export class SelectBoxContentCreator {
    public static $inject: string[] = [
        'billingHelper',
        'bundleModel',
        'productsModel',
        'priceCache',
        '$translate'
    ];

    private _service: string;
    private _language: string;
    private _productFamilies: any[] = [];
    private _prices: any[] = [];
    private _familyResults: any[] = [];
    private _showFamiliesWithSingleObject = false;

    constructor(
        private billingHelper: BillingHelperService,
        private bundleModel: BundleModelService,
        private productsModel: ProductsModelService,
        private priceCache: PriceCacheService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this._language = UiLanguageDefaultSettingsConst.languageUiIso;
    }

    public createSelectBoxes = (
        service: string,
        productFamilies: string[],
        showFamiliesWithSingleObject: boolean,
        accountId?: string
    ) => {
        this._service = service;
        this._productFamilies = productFamilies;
        this._showFamiliesWithSingleObject = showFamiliesWithSingleObject;
        this._language = UiLanguagesConst[AuthContextService.user.language];
        return this._getPrices(accountId)
            .then(this._getProductFamiliesDescription)
            .then((productPromises: any) => this._getProducts(productPromises));
    };

    public createBundleSelectBoxes(accountId: string) {
        this._language = UiLanguagesConst[AuthContextService.user.language];
        const productsListAccountId = accountId;
        let priceAccountId = accountId; // default: get prices from selected account
        if (AuthContextService.account.id !== '1'
            && accountId !== AuthContextService.account.id
        ) {
            // if account is not root user and is selected a subaccount
            // call prices from logged in account
            priceAccountId = AuthContextService.account.id;
        }

        return this.bundleModel.productsList(null, null, null, null, null, productsListAccountId)
            .then((bundleProductListResponse) => bundleProductListResponse.data)
            .then((productList) => {
                return this.priceCache.listBillingPrices(priceAccountId)
                    .then((prices) => {
                        const keywordDetailPromises = [];
                        const boxContents = [];
                        for (const i in productList) {
                            /* tslint:disable:forin */
                            const bundlePrice = prices.filter((price: any) => {
                                 return price.productCode === productList[i].productCode;
                             });

                            if (bundlePrice.length >= 1) {
                                const summaryKeywords = [];
                                const factSheetItems = this._getBundleFactSheetItems(
                                    productList[i].contingents,
                                    summaryKeywords
                                );
                                const bundleProductAggregatedData = {
                                    description: '',
                                    factSheetItems: factSheetItems, // tslint:disable-line
                                    family: 'webspace-bundle',
                                    keywordsDescriptions: [],
                                    name: bundlePrice[0].name,
                                    prices: this.assignPrices(prices, null, null, bundlePrice[0].productCode),
                                    productCodeTemplate: bundlePrice[0].productCode,
                                    shortDescription: '',
                                    summaryKeywords: summaryKeywords // tslint:disable-line
                                };

                                factSheetItems.map((item) => {
                                    const specialKeywordIndex = [
                                        'domain-de-renew',
                                        'webhosting-bundle-webspace-storage'
                                    ].indexOf(item.keyword);
                                    let type = 'string';
                                    if (specialKeywordIndex >= 0) {
                                        let keyDescriptionTranslation = '';

                                        switch (specialKeywordIndex) {
                                            case 0:
                                                keyDescriptionTranslation = this.$translate.instant(
                                                    /* translationId */ 'TR_140119-8db9f9_TR'
                                                );
                                                break;
                                            case 1:
                                                keyDescriptionTranslation = this.$translate.instant(
                                                    /* translationId */ 'TR_STORAGE_TR'
                                                );
                                                type = 'gb';
                                                break;
                                            default:
                                                keyDescriptionTranslation = '-';
                                        }

                                        bundleProductAggregatedData.keywordsDescriptions.push({
                                            category: item.category,
                                            description: '',
                                            keyword: item.keyword,
                                            language: this._language,
                                            name: keyDescriptionTranslation,
                                            type: type // tslint:disable-line
                                        });

                                        keywordDetailPromises.push(q.resolve(true));
                                    } else {

                                        if (
                                            !( // don't react on domain transfer/create
                                                item.keyword.indexOf('domain-') >= 0
                                                && ( item.keyword.indexOf('-transfer') >= 0
                                                    || item.keyword.indexOf('-create') >= 0 )
                                            )
                                        ) {
                                            keywordDetailPromises.push(this.productsModel.findOne(
                                                item.keyword,
                                                this._language
                                            ).then((data) => {
                                                type = 'string';
                                                if (item.category === 'storage') {
                                                    type = 'gb';
                                                }
                                                bundleProductAggregatedData.keywordsDescriptions.push({
                                                    category: item.category,
                                                    description: '',
                                                    keyword: item.keyword,
                                                    language: this._language,
                                                    name: data.response.name,
                                                    type: type
                                                });
                                                return data;
                                            }));
                                        }
                                    }
                                });
                                boxContents.push(bundleProductAggregatedData);
                            }
                        }
                        return q.all(keywordDetailPromises).then(() => {
                            return boxContents.sort((a, b) => {
                                return a.prices[0].price.price - b.prices[0].price.price;
                            });
                        });
                    });
            });
    }

    private _getKeywordDetailsByFactSheetItems = (factSheetItems: any, language: string) => {
        const factSheetItemKeywords = factSheetItems
            .map((factSheetItem: any) => {
                return { category: factSheetItem.category, keyword: factSheetItem.keyword };
            });
        return this.productsModel.findKeywordDescriptions(factSheetItemKeywords, language);
    };

    private _getBundleFactSheetItems = (bundleContingents: any, summaryKeywords: any) => {
        // domain contingents
        const domainFactSheetItems = this._getFactSheetItemFormContingents(
            bundleContingents, 'domains', 'domain', summaryKeywords
        );

        // webspace storage contingents
        const storageFactSheetItems = this._getFactSheetItemFormContingents(
            bundleContingents, 'storage', 'webhosting-bundle-webspace-storage', summaryKeywords
        );

        // mailbox contingents
        const mailboxFactSheetItems = this._getFactSheetItemFormContingents(
            bundleContingents, 'mailboxes', 'email-imap-mailbox', summaryKeywords
        );

        // database contingents
        const databaseFactSheetItems = this._getFactSheetItemFormContingents(
            bundleContingents, 'databases', 'database-mariadb', summaryKeywords
        );

        // database contingents
        const webspaceFactSheetItems = this._getFactSheetItemFormContingents(
            bundleContingents, 'webspace', 'webhosting-bundle-webspace-v', summaryKeywords
        );

        return [
            ...domainFactSheetItems,
            ...storageFactSheetItems,
            ...mailboxFactSheetItems,
            ...databaseFactSheetItems,
            ...webspaceFactSheetItems
        ];
    };

    private _getFactSheetItemFormContingents = (
        contingents: any,
        category: string,
        productCodeSearchString: string,
        summaryKeywords: any
    ) => {
        const sheets = [];
        contingents.map((contingent: any) => {
            contingent.productCodes.map((productCode: string) => {
                if (category !== 'webspace') {
                    summaryKeywords.push({
                        category: category, // tslint:disable-line
                        keyword: productCode
                    });
                }
                if (productCode.indexOf(productCodeSearchString) === 0) {
                    sheets.push({
                        category: category, // tslint:disable-line
                        keyword: productCode,
                        productCode: productCode,  // tslint:disable-line
                        // Special case on category storage - default storage on all bundles are 1 GB
                        // This default has to added to capacity.
                        value: (category !== 'storage' ? contingent.capacity : (contingent.capacity + 1))
                    });
                }
            });
        });

        // Special case on category storage
        // If no storage in contingents given, it has to set the default value of 1GB manually.
        if (category === 'storage' && sheets.length === 0) {
            sheets.push({
                category: category, // tslint:disable-line
                keyword: productCodeSearchString,
                value: 1
            });
            summaryKeywords.push({
                category: category, // tslint:disable-line
                keyword: productCodeSearchString
            });
        }

        return sheets;
    };

    private getPrices = (service: string, accountId: string) => {
        switch (service) {
            case 'machine': return this.priceCache.listVmPrices(accountId).then(
                (ret) => {
                    return ret;
                }
            );
            case 'email': return this.priceCache.listMailPrices(accountId);
            case 'bundle': return this.priceCache.listWebhostingPrices(accountId);
            case 'ssl': return this.priceCache.listSslPrices(accountId);
            case 'database': return this.priceCache.listDatabasePrices(accountId);
            case 'webhosting': return this.priceCache.listWebhostingPrices(accountId);
            case 'managedapplication': return this.priceCache.listManagedApplicationPrices(accountId);
        }
    };

    private assignPrices = (
        prices: any[], productCodeTemplate: string, billingCycles: number[], productCode?: string
    ) => {
        const priceObjects: PriceObject[] = [];
        if (billingCycles !== null && billingCycles !== undefined && productCodeTemplate !== undefined) {
            for (const cycle of billingCycles) {
                const productCode = productCodeTemplate.replace('##BILLING_CYCLE##', String(cycle)); // tslint:disable-line
                if (prices.length > 0) {
                    for (const price of prices) {
                        if (price.productCode === productCode) {
                            const priceObject = {
                                billingCycle: cycle,
                                price: price,  // tslint:disable-line
                                productCode: productCode // tslint:disable-line
                            };
                            /**
                             *  The following query refers to the creation of a database.
                             *  In order to find out whether a database can be created in a bundle
                             *  free of charge (quotas) and whether the bundles are settled annually,
                             *  the price for the annual settlement must be at the top of the price
                             *  array (i.e. unshift instead of push!).
                            **/
                            if (productCode.indexOf(BundleContingentProductCodesConst.database[0]) === 0
                                && cycle === 12
                            ) {
                                priceObjects.unshift(priceObject);
                            } else {
                                priceObjects.push(priceObject);
                            }
                        }
                    }
                } else {
                    priceObjects.push({
                        billingCycle: cycle,
                        price: null,
                        productCode: productCode
                    });
                }
            }
        } else {
            if (prices.length > 0) {
                for (const price of prices) {
                    if (price.productCode === productCode) {
                        priceObjects.push({
                            billingCycle: this.billingHelper.getBillingCycle(price),
                            price: price,
                            productCode: productCode
                        });
                    }
                }
            } else {
                priceObjects.push({
                    billingCycle: null,
                    price: null,
                    productCode: productCode
                });
            }
        }
        return priceObjects;
    };

    private _getPrices = (accountId: string) => {
        return this.getPrices(this._service, accountId).then((prices) => {
            this._prices = prices;
            return prices;
        });
    };

    private _getBillingCycle = (price: any) => {
        const contractPeriodWordMapper = {
            annually: 12,
            monthly: 1,
            quarterly: 3,
            semiannually: 6
        };

        return price.contractPeriod !== undefined && contractPeriodWordMapper[price.contractPeriod] !== undefined
            ? contractPeriodWordMapper[price.contractPeriod]
            : null;
    };

    private _getProductFamiliesDescription = (prices: any) => {
        const familyPromises = [];
        for (const familyName of this._productFamilies) {
            familyPromises.push(
                this.productsModel.findProductFamilyDescription(this._service, familyName, this._language)
            );
        }
        return q.all(familyPromises).then((familyResults) => {
            const productPromises = [];
            this._familyResults = familyResults;
            this._familyResults.map((result) => {
                productPromises.push(
                    this.productsModel.findProducts(this._service, result.response.familyId, this._language)
                );
            });
            return productPromises;
        });
    };

    private _getProducts = (
        productPromises: PromiseLike<Types.UI.APIResponse<Types.ProductApi.ProductsFindResult>>[]
    ): PromiseLike<unknown[]> => {
        return Promise.all(productPromises).then(
            (productResults) => {
                const keywordDetailPromises = [];
                const boxContents = [];

                /* eslint-disable @typescript-eslint/no-for-in-array *//* eslint-disable guard-for-in */
                for (const i in productResults) {
                    const productObjects: ProductBoxContent[] = [];
                    for (const product of productResults[i].response.data) { // tslint:disable-line
                        if (('requiredCreationRight' in product)
                            && !AuthContextService.isGranted(product.requiredCreationRight)
                        ) {
                            continue;
                        }

                        if (product.productType === 'webspace' && product.productCodeTemplate.indexOf('bundle') >= 1) {
                            // Bundle webspace products are not orderable over webhosting wizard!
                            continue;
                        }

                        if (
                            product.productType === 'managed-application-nextcloud'
                            && (
                                !UIRegExp.IsNumeric.test(AuthContextService.account.parentAccountId)
                                || parseInt(AuthContextService.account.parentAccountId, 10) > 1000
                            )
                            && ProductHelperService.wrapSpecificationItems(
                                product.specificationItems
                            ).isFreeProduct?.isTrue
                        ) {
                            // Do not show nextcloud free to subaccounts.
                            continue;
                        }

                        const productAggregatedData = {
                            description: product.description,
                            factSheetItems: product.factSheetItems,
                            family: this._familyResults[i].response.familyId,
                            keywordsDescriptions: [],
                            name: product.name,
                            prices: this.assignPrices(
                                this._prices,
                                product.productCodeTemplate,
                                product.billingCycles,
                                product.productCode
                            ),
                            productCodeTemplate: (product.productCodeTemplate === undefined)
                                ? product.productCode
                                : product.productCodeTemplate,
                            recommend: ([undefined, null, false].indexOf(product.featured) < 0)
                                ? product.featured
                                : false,
                            shortDescription: product.shortDescription,
                            specificationItems: product.specificationItems,
                            summaryKeywords: this._familyResults[i].response.summaryKeywords
                        };

                        keywordDetailPromises.push(
                            this._getKeywordDetailsByFactSheetItems(
                                product.factSheetItems,
                                this._language
                            ).then((data) => {
                                productAggregatedData.keywordsDescriptions = data.responses;
                                return data.responses;
                            })
                        );

                        productObjects.push(productAggregatedData);
                    }

                    if (productObjects.length === 1 && !this._showFamiliesWithSingleObject) {
                        boxContents.push(productObjects[0]);
                    } else if (productObjects.length > 0) {
                        boxContents.push({
                            description: this._familyResults[i].response.description,
                            family: this._familyResults[i].response.familyId,
                            name: this._familyResults[i].response.name,
                            products: productObjects,
                            shortDescription: this._familyResults[i].response.shortDescription,
                            summaryKeywords: this._familyResults[i].response.summaryKeywords
                        });
                    }
                }
                /* eslint-enable @typescript-eslint/no-for-in-array *//* eslint-enable guard-for-in */

                return Promise.all(keywordDetailPromises).then(() => {
                    return boxContents;
                });
            }
        );
    };
}
