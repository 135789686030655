import * as ng from 'angular';
import { UiRights } from '../../../../configuration';
import { AuthContextService, DepositModelService } from '../../../../services';

export class MoleculePanelPrepaidController implements ng.IController {
    public static $inject: string[] = ['depositModel', '$translate'];

    public deposit: any = {
        balance: 0,
        voucherBalance: 0,
        reserved: 0,
        creditLimit: 0,
        paymentType: ''
    };
    public depositBalanceModifier = 'display';
    public showPanel: boolean;

    public linkItems: any[] = [];
    public asWidget: boolean;

    constructor(
        private depositModel: DepositModelService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.asWidget = this.asWidget || false;

        this.showPanel = false;
        this.linkItems = [
            {
                text: this.$translate.instant('TR_090119-93d160_TR'),
                route: 'billing.payment-options'
            }
        ];

        this.depositModel.paymentMethodAvailability().then(
            (paymentMethodAvailability: {paymentMethod: string; available: boolean}[]) => {
                const availableMethods = paymentMethodAvailability
                    .filter((method) => method.available)
                    .map((method) => method.paymentMethod);

                if (availableMethods.some(
                    (method) => ['directdebit'].indexOf(method.toLowerCase()) >= 0
                )) {
                    if (!this.asWidget) {
                        this.linkItems.push({
                            text: this.$translate.instant('TR_100119-12d8ca_TR'),
                            route: 'billing.payment-options'
                        });
                    }
                }
            }
        );


        if (
            !AuthContextService.isRootOrCompanyAccount
            && AuthContextService.isGranted(UiRights.BIL_GET_DEPOSIT)
        ) {
            this.depositModel.getDeposit()
                .then(
                    result => {
                        this.deposit = result;
                        this.showPanel = this.deposit.paymentType === 'prepaid';
                    }
                );
        }
    }
}

export class MoleculePanelPrepaidComponent implements ng.IComponentOptions {
    public bindings = {
        asWidget: '<'
    };
    public controller = MoleculePanelPrepaidController;
    public template = require('./panel-prepaid.html');
}
