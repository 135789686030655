import * as ng from 'angular';
import { OverviewDataObject } from '../../../../../../organisms';
import {
    AsteriskNoteType,
    AuthContextService,
} from '@/services/';

export class MoleculeOverviewPricelistRowPanelDatabasesController implements ng.IController {
    public static $inject: string[] = [];

    public overviewData: OverviewDataObject;
    public vatAsteriskNoteType: AsteriskNoteType = AsteriskNoteType.VAT_NONUNIFORM;

    public getVatHint(product: any, cycle: string) {
        if (
            product.prices[cycle]?.amounts?.length > 1
            && product.prices[cycle]?.grossAmount > 0
            && !AuthContextService.account.isCommercialCustomer
        ) {
            return true;
        }
        return false;
    }
}

export class MoleculeOverviewPricelistRowPanelDatabasesComponent implements ng.IComponentOptions {
    public require = {
        organismPricelistOverview: '^organismPricelistOverview'
    };
    public bindings = {
        overviewData: '<'
    };
    public controller =  MoleculeOverviewPricelistRowPanelDatabasesController;
    public controllerAs = 'OverviewPricelistCtrl';
    public template = require('./pricelist-databases-row.html');
}
