import ng, { IScope } from 'angular';
import * as Types from '@/types';
import { TemplateSetupController } from '@/components/setup';
import {
    AccountModelService,
    DepositModelService,
    OrchestratorRobotService,
    MachineModelService,
} from '@/services';
import {
    MachineManagedSetup,
    MachineManagedAdditionalInjectedRequests,
} from './machine-managed-setup';
import { applyIfNeeded } from '@/services/helpers/angular-helpers';

type APISingleResponse<T> = Types.UI.APISingleResponse<T>;
type ManagedVM = Types.MachineApi.VirtualMachine;

export class TemplateMachineManagedSetupController extends TemplateSetupController {
    public static $inject: string[] = [
        'accountModel',
        'depositModel',
        'orchestratorRobot',
        '$rootScope',
        '$translate',
        'machineModel',
    ];
    public machineManagedSetup: MachineManagedSetup;

    constructor(
        accountModel: AccountModelService,
        depositModel: DepositModelService,
        orchestratorRobot: OrchestratorRobotService,
        $rootScope: IScope,
        $translate: ng.translate.ITranslateService,
        private machineModel: MachineModelService,
    ){
        super(
            accountModel,
            depositModel,
            orchestratorRobot,
            $rootScope,
            $translate,
        );
    }

    public $onInit(): void {
        this.pageHeaderData = {
            backwardLink: 'machine.dashboard',
            backwardText: this.$translate.instant('TR_140119-a258ef_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: {},
            panelIcon: 'box-alt',
            panelTitle: this.$translate.instant('18249206-2af0-4e97-abe6-51184efa0ce2'),
        };
        const additionalInjectionRequests: MachineManagedAdditionalInjectedRequests = {
            loadManagedVM: (id: string): Promise<ManagedVM> => {
                return this.machineModel.findOneById(id);
            },
            updateManagedVM: (managedVM: ManagedVM): Promise<APISingleResponse<ManagedVM>> => {
                return this.machineModel.updateMachine(managedVM);
            },
        }
        this.machineManagedSetup = new MachineManagedSetup(
            {...this.genericInjectionRequests, ...additionalInjectionRequests},
            (fn: () => void) => {
                applyIfNeeded(this.$rootScope, fn);
            });
        super.$onInitGeneric(this.machineManagedSetup);
    }
}

export class TemplateMachineManagedSetupComponent implements ng.IComponentOptions {
    public bindings = {
        orderId: '<',
    };
    public template = require('./machine-managed-setup-template.html');
    public controller = TemplateMachineManagedSetupController;
}
