import * as ng from 'angular';

import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateRedirectionEditController {
    public static $inject: string[] = ['$translate'];

    public redirection: any;
    public pageHeaderData: PanelHeaderData = {
        backwardLink: 'domain.redirections.overview',
        backwardText: this.$translate.instant('TR_110119-4f9658_TR'),
        panelHeaderRoute: '',
        panelIcon: 'globe',
        panelTitle: ''
    };

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit() {
        this.pageHeaderData.panelTitle = this.redirection.domainNameUnicode;
    }
}

export class TemplateRedirectionEditComponent implements ng.IComponentOptions {
    public bindings = {
        redirection: '<'
    };
    public template = require('./redirection-edit-template.html');
    public controller = TemplateRedirectionEditController;
}
