import { AlphaNumericCaseInsensitive } from '@/configuration';
import * as ng from 'angular';
import { GeneralValidator, ValidateAllowedCharacters, ValidateNotEmpty } from '../../../../../../services';
import { FormInputModelOptions } from '../../../../../atoms/form-elements';
import { MoleculeFormEditController } from '../../../form-edit/form-edit';
import { ValidateOptions } from '../../date-select/date-select';
import { FormDropDownItems } from '../drop-down';

import './drop-down-text-input.scss';

export class MoleculeFormDropDownTextInputController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public showOptions = false;
    public items: FormDropDownItems[] = [];
    public disabled: boolean;
    public callbackOnBlur: (param: any) => any;
    public callbackOnBlurParams: any;
    public callbackOnChange: (model: any) => void;
    public callbackOnFocus: (param: any) => any;
    public callbackOnFocusParams: any;
    public customCallbackOnEnter: () => {};
    public callbackOnSelect: (model: any) => any;
    public model: any;
    public popoverModifier: string;
    public overviewLoad: (selected: any) => any;
    public placeholder: string;
    public inputValidationIndex: number;
    public inputValidationErrors: string[];
    public validationInstructions: any[];
    public registerToForm: boolean;
    public inputType: string;
    public validationOptions: ValidateOptions;
    public validationErrorList: string[] = [];
    public validationSuccessful = true;
    public orderBy: string;
    public $editForm: MoleculeFormEditController;
    public doNotSaveOnFormEnter: boolean;
    public clearable: boolean;
    public modelOptions: FormInputModelOptions;

    private registrationIndex;
    private validator;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {
        this.validator = new GeneralValidator(this.$translate);
    }

    public $onInit() {
        this.disabled = this.disabled || false;
        this.orderBy = this.orderBy || null;
        this.inputValidationErrors = [];
        this.doNotSaveOnFormEnter = this.doNotSaveOnFormEnter || false;

        if (this.inputType === 'default') {
            this.validationInstructions = [];
        }

        if (this.inputType === 'changeTag') {
            this.validationInstructions = [{
                validator: new ValidateAllowedCharacters(
                    AlphaNumericCaseInsensitive.concat('-'),
                    this.$translate
                )
            }];
        }

        if (this.inputType === 'notEmpty') {
            this.validationInstructions = [{
                instructions: null,
                validator: new ValidateNotEmpty(this.$translate)
            }];
        }

        if (this.inputType === 'httpUserName') {
            this.validationInstructions = [{
                validator: new ValidateAllowedCharacters(
                    AlphaNumericCaseInsensitive.concat('-@._'),
                    this.$translate
                )
            }];
        }

        if (this.registerToForm || this.registerToForm === null || this.registerToForm === undefined) {
            this.registerToForm = true;
            this.registrationIndex = this.$editForm.registerValidator(this);
        }
    }

    public get selectedOption() {
        if (this.items === undefined) {
            return false;
        }
        return this.items.filter((item) => item.value === this.model)[0];
    }

    // empty and with no functionallity setter
    public set selectedOption(value) {
        return;
    }

    public get showDropdown() {
        if (this.items === undefined) {
            return false;
        }
        return this.showOptions && this.items.some(this.search);
    }

    public inputClick() {
        if (!this.showOptions) {
            this.showOptions = true;
        }
    }

    public selectOption = (item, $event) => {
        this.model = item.value;
        if (this.overviewLoad !== undefined) {
            this.overviewLoad(item.value);
        }
        this.startValidation();
        if (this.callbackOnSelect !== undefined) {
            this.callbackOnSelect(this.model);
        }
    };

    public onChange = () => {
        if ([undefined, null].indexOf(this.callbackOnChange) < 0) {
            this.callbackOnChange(this.model);
        }
    };

    public startValidation() {
        if (this.registerToForm) {
            this.$editForm.validate(this.registrationIndex);
        } else {
            this.validate();
        }
    }

    public outerElementClick = () => {
        this.startValidation();
    };

    public openOptions = () => {
        this.showOptions = true;
        if (this.callbackOnFocus !== undefined) {
            this.callbackOnFocus(this.callbackOnFocusParams);
        }
    };

    public closeOptions = () => {
        this.showOptions = false;
    };

    public callbackOnInputBlur = () => {
        this.closeOptions();
        if (['changeTag'].indexOf(this.inputType) >= 0) {
            this.validate();
        }
        if (this.callbackOnBlur) {
            this.callbackOnBlur(this.callbackOnBlurParams);
        }
    };

    public showValidationErrors = () => {
        return this.validationErrorList.length > 0;
    };

    public validate = () => {
        let value = '';
        if (this.model) {
            value = this.model;
        }
        if (this.validationInstructions) {
            this.validationErrorList = this.validator.validate(value, this.validationInstructions);
            this.validationSuccessful = (this.validationErrorList.length === 0);
            return this.validationSuccessful;
        } else {
            return true;
        }
    };

    public search = (item) => {
        if ([null, undefined, ''].indexOf(this.model) >= 0) {
            return true;
        }
        return item.name.toLowerCase().indexOf(this.model.toLowerCase()) >= 0;
    };

    public enterKeyUp = () => {
        if (this.customCallbackOnEnter !== undefined) {
            this.customCallbackOnEnter();
            return;
        }
        this.$editForm.save();
    };

    public escCallback = (event: KeyboardEvent) => {
        this.closeOptions();
        (event.target as HTMLElement).blur();
    };
}

export class MoleculeFormDropDownTextInputComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnBlur: '<',
        callbackOnBlurParams: '<',
        callbackOnChange: '<',
        callbackOnFocus: '<',
        callbackOnFocusParams: '<',
        callbackOnSelect: '<',
        clearable: '<?',
        customCallbackOnEnter: '<',
        disabled: '<',
        doNotSaveOnFormEnter: '<',
        inputType: '@',
        items: '<',
        model: '=',
        modelOptions: '<',
        orderBy: '@',
        overviewLoad: '<',
        placeholder: '@',
        popoverModifier: '@',
        registerToForm: '<',
        sizeModifier: '@',
        validationErrorList: '=?',
        validationOptions: '<'
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public controller =  MoleculeFormDropDownTextInputController;
    public template = require('./drop-down-text-input.html');
}
