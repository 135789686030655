/* eslint-disable */

import * as ng from 'angular';
import './panel-edit-row-radio.scss';

// tslint:disable-next-line:class-name
export interface buttonInterface {
    value: string|number|boolean;
    label: string;
}

export class MoleculePanelEditRowRadioController implements ng.IController {
    public static $inject: string[] = [];
    public value: string|number|boolean;
    public arrangement: string;
    public buttons: buttonInterface;
    public disabled: boolean;
    public onChangeCallback: (selection: unknown) => {/* */};
    public onChangeCallbackParams: unknown;
    public advancedRadioButtons: string[] = [];
    public showAdvancedOptionsButton = false;

    public $onInit(): void {
        this.arrangement = this.arrangement || 'vertical';
        this.showAdvancedOptionsButton = this.advancedRadioButtons?.length > 0;
    }

    public isButtonShown(radioButton: buttonInterface): boolean {
        return !this.showAdvancedOptionsButton || !this.advancedRadioButtons.includes(radioButton.label);
    }

    public showAdvancedOptions(): void {
        this.showAdvancedOptionsButton = false;
    }
}

export class MoleculePanelEditRowRadioComponent implements ng.IComponentOptions {
    public bindings = {
        arrangement: '@',
        buttons: '<',
        disabled: '<',
        onChangeCallback: '<?',
        onChangeCallbackParams: '<?',
        value: '=',
        advancedRadioButtons: '<?',
    };
    public transclude = {
        label: '?rowLabel'
    };
    public controller = MoleculePanelEditRowRadioController;
    public template = require('./panel-edit-row-radio.html');
}
