import * as ng from 'angular';

export class MoleculeButtonDropdownController implements ng.IController {
    public static $inject: string[] = [];

    public disabled: boolean;
    public dropdown: () => void;
    public disabledTab: boolean;
    public modifier: string;

    public $onInit() {
        this.modifier = this.modifier || 'medium';
        this.disabled = this.disabled || false;
        this.disabledTab = this.disabledTab || false;
    }

    public keyDownEvent = (event) => {
        if (!this.disabled && event.keyCode === 13) { // ENTER
            this.dropdown();
        }
    };

    public get tabIndex() {
        if (!this.disabled && !this.disabledTab) {
            return undefined;
        } else {
            return '-1';
        }
    }

}

export class MoleculeButtonDropdownComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        disabled: '<',
        disabledTab: '<',
        dropdown: '<',
        modifier: '@?'
    };
    public controller =  MoleculeButtonDropdownController;
    public template = require('./button-dropdown.html');
}
