export const ErrorMapperBillingConst = {
    62030: /* translationId */ 'ERRORS.62030',
    62044: /* translationId */ 'ERRORS.62044',
    62055: /* translationId */ 'TR_230119-81c8a2_TR',
    62056: /* translationId */ 'TR_230119-cfe94a_TR',
    62057: /* translationId */ 'TR_230119-a19bab_TR',
    62058: /* translationId */ 'TR_230119-58aa69_TR',
    62060: /* translationId */ 'TR_230119-aa192d_TR',
    62061: /* translationId */ 'TR_230119-1a2625_TR',
    62062: /* translationId */ 'TR_230119-d6f3a0_TR',
    62063: /* translationId */ 'TR_230119-922dc6_TR'
};
