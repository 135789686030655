import {
    TemplateBillingPricelistAbstractOverviewComponent,
    TemplateBillingPricelistAbstractOverviewController
} from '../abstract/pricelist-abstract-template';

export class TemplateBillingPricelistWebhostingOverviewController
    extends TemplateBillingPricelistAbstractOverviewController {
    protected options = {
        panelTitleTranslationId: /* translationId */ 'TR_090119-f96e2f_TR',
        simpleFilterField: 'productName'
    };
}

export class TemplateBillingPricelistWebhostingOverviewComponent
    extends TemplateBillingPricelistAbstractOverviewComponent {
    public controller = TemplateBillingPricelistWebhostingOverviewController;
    public template = require('./pricelist-webhosting-template.html');
}
