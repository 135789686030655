import * as ng from 'angular';
import { InputPlaceholderConst } from '../../../../configuration';
import { LocationMetadata } from './../../../organisms/forms/edit-forms/domain-edit/domain-locations';

import './domain-locations-list-item-options.scss';

export class MoleculeListsDomainLocationsListItemOptionsController implements  ng.IController {
    public static $inject: string[] = ['$translate'];

    public locationData: LocationMetadata;
    public cleanUrlsEnabled: boolean;
    public httpHeaderEnabled: any;

    public redirectionButtons: { value: string; label: string }[] = [];
    public matchTypeButtonsGeneric: { value: string; label: string }[] = [];
    public matchTypeButtonsSpecial: { value: string; label: string }[] = [];

    protected inputPlaceholder = InputPlaceholderConst.domains;

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {
        this.redirectionButtons = [{
            label: this.$translate.instant('TR_100119-305436_TR'),
            value: '301'
        }, {
            label: this.$translate.instant('TR_100119-d3c6a1_TR'),
            value: '302'
        }, {
            label: this.$translate.instant('TR_100119-b09e0e_TR'),
            value: '307'
        }];
        this.matchTypeButtonsGeneric = [{
            label: this.$translate.instant('35661560-7460-4aa6-bab7-49bb6e87d873'),
            value: 'directory'
        }, {
            label: this.$translate.instant('WEBHOSTING.VHOST.REDIRECT.MATCH-EXACT.OPTION'),
            value: 'exact'
        }, {
            label: this.$translate.instant('WEBHOSTING.VHOST.REDIRECT.MATCH-REGEX.OPTION'),
            value: 'regex'
        }];
        this.matchTypeButtonsSpecial = [{
            label: this.$translate.instant('35661560-7460-4aa6-bab7-49bb6e87d873'),
            value: 'directory'
        }, {
            label: this.$translate.instant('WEBHOSTING.VHOST.REDIRECT.MATCH-EXACT.OPTION'),
            value: 'exact'
        }, {
            label: this.$translate.instant('WEBHOSTING.VHOST.REDIRECT.MATCH-REGEX.OPTION'),
            value: 'regex'
        }, {
            label: this.$translate.instant('WEBHOSTING.VHOST.DENY.MATCH-SIMPLE-EXPRESSION.OPTION'),
            value: 'placeholderMatch'
        }];
    }

    public $onInit() {
        this.cleanUrlsEnabled = this.locationData.data.mapScript.length > 0;
        this.httpHeaderEnabled = this.locationData.httpHeaderEnabled;
    }

    public get matchTypeButtonSettings() {
        if (this.locationData.data.locationType === 'generic') {
            return this.matchTypeButtonsGeneric;
        } else {
            return this.matchTypeButtonsSpecial;
        }
    }

    public get isCleanUrlsEnabled() {
        return this.cleanUrlsEnabled;
    }

    public set isCleanUrlsEnabled(value: boolean) {
        this.cleanUrlsEnabled = value;
        if (!value) {
            this.locationData.data.mapScript = '';
        }
    }
    public get showDirProtectionWarning() {
        return this.locationData.data.matchType !== 'directory'
            && this.locationData.data.restrictToHttpUsers.length > 0;
    }

}

export class MoleculeListsDomainLocationsListItemOptionsComponent implements ng.IComponentOptions {
    public bindings = {
        cleanUrlsEnabled: '=',
        locationData: '='
    };
    public controller = MoleculeListsDomainLocationsListItemOptionsController;
    public template = require('./domain-locations-list-item-options.html');
}
