import * as ng from 'angular';
import * as q from 'q';
import {
    AlertManagerService,
    ApiErrorModel,
    BackupModelService,
    NavigationService
} from '../../../../../services';

export class OrganismEditFormBackupsController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'apiErrorModel',
        'backupModel',
        'navigation'
    ];

    public originalBackups: any;
    public objectToBackup: any;
    public backups: any;
    public userPanelRight: any;
    public service: string;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private backupModel: BackupModelService,
        private navigation: NavigationService
    ) {}

    public $onInit = () => {
        this.backups = JSON.parse(JSON.stringify(this.originalBackups));
    };

    public restoreBackups = (backup) => {
        this.apiErrorModel.destroyErrorList();

        return this.backupModel.restore(backup.targetService, backup.targetobjectType, backup.targetobjectId, backup.id)
        .then(
            (res) => {
                if (!this.apiErrorModel.apiResponseHasError(res)) {
                    this.alertManager.success(this.$translate.instant('BACKUP.ACTION.RESTORE.SUCCESS'));
                    this.navigation.reloadCurrentState();

                    return res;
                }

                return q.reject(false);
            }
        );
    };

    public addNewBackup = (backup) => {
        const manualBackup = this.backups.filter((singleBackup) => singleBackup.type === 'manual');
        let oldBackupId = null;

        if (manualBackup.length > 0) {
            const oldBackup = manualBackup.pop();
            oldBackupId = oldBackup.id;
        }

        this.apiErrorModel.destroyErrorList();

        return this.backupModel.start(
            backup.service,
            backup.objectType,
            backup.objectId,
            backup.backupName,
            oldBackupId
        )
        .then(
            (res) => {
                if (!this.apiErrorModel.apiResponseHasError(res)) {
                    this.alertManager.success(this.$translate.instant('BACKUP.ACTION.START.SUCCESS'));

                    return res;
                }

                return q.reject(false);
            }
        );
    };

    public get errorList() {
        return this.apiErrorModel.errorObjectList;
    }
}

export class OrganismEditFormBackupsComponent implements ng.IComponentOptions {
    public bindings = {
        objectToBackup: '<',
        originalBackups: '<backups',
        service: '<',
        userPanelRight: '='
    };

    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormBackupsController;
    /* tslint:disable-next-line:max-line-length */
    public template = require('./backups.html');
}
