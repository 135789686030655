import * as ng from 'angular';
import { Validator } from './abstract-validator';

export class ValidateNumberStep extends Validator {
    private step: number;

    constructor(
        private $translate: ng.translate.ITranslateService,
        step: number
    ) {
        super();
        this.step = step;
    }

    public validate = (value: string|number) => {
        let userInput = ng.copy(value);
        userInput = parseFloat(`${userInput}`);
        const step = parseFloat(`${this.step}`);

        if (!isNaN(userInput) && userInput % step !== 0) {
            return [{ text: this.$translate.instant('TR_151220-b257c1_TR', { step: this.step }) }];
        }

        return [];
    };
}
