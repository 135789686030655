import { CacheService } from '@/services';
import * as ng from 'angular';
import * as q from 'q';
import * as Types from '../../types';
import { RestrictionHelperService } from '../helpers';
import { ModelHelper } from '../model-helper';
import { RequestStatus } from '../rpc-client';
import { DnsRobotService } from './robot';

export class DnsZoneModelService {
    public static $inject: string[] = ['dnsRobot', '$rootScope', 'cache', 'restrictionHelper'];

    constructor(
        private dnsRobot: DnsRobotService,
        private $rootScope: ng.IRootScopeService,
        private cache: CacheService,
        private restrictionHelper: RestrictionHelperService
    ) {}

    public deleteOne = (zone) => {
        return this.dnsRobot.deleteZone(zone.id);
    };

    public list = (
        limit?: number,
        page?: number,
        filters?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions,
        cancel?
    ) => {
        sort = sort || {
            field: 'zoneNameUnicode',
            order: 'ASC'
        };
        page = page || 1;

        return this.dnsRobot.listZones(filters, limit, page, sort, cancel)
        .then(ModelHelper.returnListResponse);
    };

    public findZones = (
        filter?: Types.Finding.Filter,
        limit?: number,
        page?: number,
        sort?: Types.Finding.SortOptions,
        skipPagination?
    ) => {
        filter = filter || null;
        limit = limit || null;
        page = page || 1;
        sort = sort || null;
        skipPagination = skipPagination || true;

        return this.dnsRobot.findZones(filter, limit, page, sort, skipPagination)
        .then(ModelHelper.returnFindResponse);
    };

    public listWithoutPagination = (
        limit?: number,
        page?: number,
        filters?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions,
        cancel?
    ) => {
        sort = sort || {
            field: 'zoneNameUnicode',
            order: 'ASC'
        };
        page = page || 1;

        return this.dnsRobot.listZonesWithoutPagination(filters, limit, page, sort, cancel)
        .then(ModelHelper.returnListResponse);
    };

    public recordsList = (
        filters?: Types.Finding.Filter,
        limit?: number,
        page?: number,
        sort?: Types.Finding.SortOptions,
        owner?: string
    ) => {
        sort = sort || {
            field: 'recordType',
            order: 'ASC'
        };
        page = page || 1;

        return this.dnsRobot.listRecords(filters, limit, page, sort, owner)
        .then(ModelHelper.returnListResponse);
    };

    public findOne = (id) => {
        return this.dnsRobot.listZonesWithoutPagination({field: 'zoneConfigId', value: id}, 1, 1)
            .then(ModelHelper.returnFindOneResponse);
    };

    public findById = (ids: string[]) => {
        const filter: Types.Finding.Filter = {
            subFilter: ids.map((id) => ({field: 'zoneConfigId', value: id})),
            subFilterConnective: 'OR'
        };

        return this.dnsRobot.listZonesWithoutPagination(filter, 0, 1)
        .then(this.returnResponseData);
    };

    public findByName = (names: string[]) => {
        const filter: Types.Finding.Filter = {
            subFilter: names.map((name) => ({ field: 'zoneName', value: name })),
            subFilterConnective: 'OR'
        };

        return this.dnsRobot.listZonesWithoutPagination(filter, 0, 1)
        .then(this.returnResponseData);
    };

    public findOneByName = (name: string, unicodeOnly?: boolean) => {
        const filter: Types.Finding.Filter = unicodeOnly
        ? { field: 'zoneNameUnicode', value: name }
        : {
            subFilter: [
                { field: 'zoneName', value: name },
                { field: 'zoneNameUnicode', value: name }
            ],
            subFilterConnective: 'OR'
        };

        return this.dnsRobot.listZonesWithoutPagination(filter, 1, 1)
        .then(
            (result) => {
                if (result.response.data.length) {
                    return result.response.data[0];
                }
            }
        );
    };

    public findRecords = (zoneId: string|number, additionalFilters?, limit = 0) => {
        let filter: any = {
            field: 'zoneConfigId',

            value: (typeof zoneId === 'number') ? zoneId.toString() : zoneId
        };

        if (additionalFilters !== undefined && additionalFilters !== null) {
            filter = {
                subFilter: [
                    filter,
                    additionalFilters
                ],
                subFilterConnective: 'AND'
            };
        }

        return this.dnsRobot.listRecordsWithoutPagination(filter, limit, 1, { field: 'recordType', order: 'ASC' })
        .then(this.returnResponseData);
    };

    public createNative = (
        name: string,
        records: Types.DnsApi.Record[],
        options,
        nameserverSetId: string|-1,
        type: string,
        dnsSecOptions: Types.DnsApi.DnsSecOptions,
        owner: string
    ) => {
        options = options || {};
        type = type || 'NATIVE';
        let useDefaultNameserverSet = false;
        let submitNameserverSetId = '';

        const data = {
            accountId: owner,
            name: name,
            type: type
        };

        switch (nameserverSetId) {
            case null:
                submitNameserverSetId = null;
                useDefaultNameserverSet = true;
                break;
            case -1:
                submitNameserverSetId = null;
                /* falls through */
            default:
                if (nameserverSetId !== -1) {
                    // this if is just so Typescript knows the -1 special case doesn't get to the next line.
                    submitNameserverSetId = nameserverSetId;
                }
                useDefaultNameserverSet = false;
        }

        ng.extend(data, options);

        return this.dnsRobot.createZone(
            data,
            records,
            submitNameserverSetId,
            useDefaultNameserverSet,
            null,
            dnsSecOptions
        );
    };

    public checkZoneCreate = (
        name: string,
        records: Types.DnsApi.Record[],
        options,
        nameserverSetId: string|-1,
        type: string,
        dnsSecOptions: Types.DnsApi.DnsSecOptions,
        owner: string,
        isRecreate: boolean = false
    ) => {
        options = options || {};
        type = type || 'NATIVE';
        let useDefaultNameserverSet = false;
        let submitNameserverSetId = '';

        const data = {
            accountId: owner,
            name: name,
            type: type
        };

        ng.extend(data, options);

        switch (nameserverSetId) {
            case null:
                submitNameserverSetId = null;
                useDefaultNameserverSet = true;
                break;
            case -1:
                submitNameserverSetId = null;
                /* falls through */
            default:
                if (nameserverSetId !== -1) {
                    // this if is just so Typescript knows the -1 special case doesn't get to the next line.
                    submitNameserverSetId = nameserverSetId;
                }
                useDefaultNameserverSet = false;
        }

        if (type === 'SLAVE') {
            submitNameserverSetId = undefined;
            useDefaultNameserverSet = undefined;
        }

        return this.dnsRobot.checkZone(
            data,
            records,
            submitNameserverSetId,
            useDefaultNameserverSet,
            null,
            dnsSecOptions,
            isRecreate
        );
    };

    public reCreateNative = (
        zone: Types.DnsApi.ZoneConfig,
        records: Types.DnsApi.Record[],
        nameserverSetId: string|-1,
        dnsSecOptions: Types.DnsApi.DnsSecOptions,
        options = {},
        type = 'NATIVE'
    ) => {
        let useDefaultNameserverSet = false;
        zone.type = type;
        zone.masterIp = null;
        let submitNameserverSetId = '';

        if (nameserverSetId !== undefined) {
            switch (nameserverSetId) {
                case null:
                    submitNameserverSetId = null;
                    useDefaultNameserverSet = true;
                    break;
                case -1:
                    submitNameserverSetId = null;
                    /* falls through */
                default:
                    if (nameserverSetId !== -1) {
                        // this if is just so Typescript knows the -1 special case doesn't get to the next line.
                        submitNameserverSetId = nameserverSetId;
                    }
                    useDefaultNameserverSet = false;
            }
        } else {
            submitNameserverSetId = undefined;
            useDefaultNameserverSet = undefined;
        }

        ng.extend(zone, options);

        return this.dnsRobot.recreateZone(zone, records, submitNameserverSetId, useDefaultNameserverSet, dnsSecOptions);
    };

    public reCreateAsSlave = (zone: Types.DnsApi.ZoneConfig, masterIp: string) => {
        const data = {
            dnsSecMode: 'off',
            id: zone.id,
            masterIp: masterIp,
            name: zone.name,
            type: 'SLAVE',
            zoneTransferWhitelist: []
        };

        return this.dnsRobot.recreateZone(data, [], null, false, {})
        .then(this.refresh, this.refresh);
    };

    public createSlave = (
        name: string,
        masterIp: string,
        dnsSecOptions: Types.DnsApi.DnsSecOptions,
        owner: string
    ) => {
        const data = {
            accountId: owner,
            masterIp: masterIp,
            name: name,
            type: 'SLAVE'
        };

        return this.dnsRobot.createZone(data, [], null, false, null, dnsSecOptions)
            .then(this.refresh, this.refresh);
    };

    public create = (
        data: Types.DnsApi.ZoneConfig,
        records: Types.DnsApi.Record[],
        dnsSecOptions: Types.DnsApi.DnsSecOptions,
        owner: string
    ) => {
        return this.dnsRobot.createZone(data, records, null, undefined, owner, dnsSecOptions)
        .then(this.refresh, this.refresh);
    };

    public zoneModifyRestrictions = (apiObject, owner) => {
        return this.dnsRobot.zoneModifyRestrictions(apiObject, owner)
            .then(this.returnResponse)
            .then(this.refresh, this.refresh(RequestStatus.FAILED));
    };

    public createWithTemplate = (
        name: string,
        templateId: string,
        tieToTemplate: boolean,
        replacements,
        nameserverSetId: string|-1,
        dnsSecOptions: Types.DnsApi.DnsSecOptions,
        owner: string,
        NSrecords: { content: string; name: string; ttl: number; type: 'NS' }[] = [],
        reCreateZone = false
    ) => {
        let useDefaultNameserverSet = false;
        let submittedNameserverSetId = '';
        const data = {
            accountId: owner,
            name: name,
            templateValues: {
                templateId: templateId,
                templateReplacements: replacements,
                tieToTemplate: tieToTemplate
            },
            type: 'NATIVE'
        };

        switch (nameserverSetId) {
            case null:
                submittedNameserverSetId = undefined;
                useDefaultNameserverSet = true;
                break;
            case -1:
                submittedNameserverSetId = undefined;
            /* falls through */
            default:
                if (nameserverSetId !== -1) {
                    submittedNameserverSetId = nameserverSetId;
                }
                useDefaultNameserverSet = false;
        }

        if (reCreateZone) {
            return this.dnsRobot
                .recreateZone(
                    data,
                    NSrecords,
                    submittedNameserverSetId,
                    useDefaultNameserverSet,
                    dnsSecOptions
                )
                .then(this.refresh, this.refresh);
        }

        return this.dnsRobot
            .createZone(
                data,
                NSrecords,
                submittedNameserverSetId,
                useDefaultNameserverSet,
                null,
                dnsSecOptions
            )
            .then(this.refresh, this.refresh);
    };

    public updateSlave = (zone) => {
        return this.dnsRobot.recreateZone(zone, [])
        .then(this.refresh, this.refresh);
    };

    public updateNative = (
        zone: Types.DnsApi.ZoneConfig,
        records: Types.DnsApi.Record[],
        nameserverSetId?,
        useDefaultNameserverSet?,
        dnsSecOptions?: Types.DnsApi.DnsSecOptions
    ) => {
        const filteredRecords = records.filter(this.filterRecords);

        return this.dnsRobot.recreateZone(
            zone,
            filteredRecords,
            nameserverSetId,
            useDefaultNameserverSet,
            dnsSecOptions
        )
        .then(this.refresh, this.refresh);
    };

    public updateZone = (
        zoneConfig: Types.DnsApi.ZoneConfig,
        addRecordList: Types.DnsApi.Record[],
        deleteRecordList: Types.DnsApi.Record[],
        dnsSecOptions?: Types.DnsApi.DnsSecOptions,
        modifiedRecordList?: Types.DnsApi.Record[]
    ) => {
        addRecordList = addRecordList.length > 0 ? addRecordList.filter(this.filterRecords) : [];
        deleteRecordList = deleteRecordList.length > 0 ? deleteRecordList.filter(this.filterRecords) : [];

        // Hotfix 126.4 - DNS Zone Update modify list is not used by the UI (PUI-5865)
        if (modifiedRecordList === undefined) {
            modifiedRecordList = addRecordList.filter(
                (addRecord) => deleteRecordList.some(
                    (delRecord) => addRecord.id && delRecord.id === addRecord.id
                )
            );
            addRecordList = addRecordList.filter(
                (addRecord) => !modifiedRecordList.some(
                    (modRecord) => modRecord.id === addRecord.id
                )
            );
            deleteRecordList = deleteRecordList.filter(
                (delRecord) => !modifiedRecordList.some(
                    (modRecord) => modRecord.id === delRecord.id
                )
            );
        }

        dnsSecOptions = dnsSecOptions || null;

        return this.dnsRobot.updateZone(zoneConfig, addRecordList, deleteRecordList, modifiedRecordList, dnsSecOptions)
            .then(this.refresh, this.refresh);
    };

    public delete = (zones) => {
        const promises = zones.map(this.deleteOne);

        return q.all(promises).then(this.refresh, this.refresh);
    };

    public untie = (zones) => {
        const promises = zones.map(this.untieOne);

        return q.all(promises).then(this.refresh, this.refresh);
    };

    public block = (zones) => {
        const promises =  zones.map(
            (zone) => {
                this.restrictionHelper.init(zone, 'dns', this, 'zoneModifyRestrictions');
                if (!this.restrictionHelper.hasRestrictionsByCurrentUser()) {
                    return this.restrictionHelper.restrictObject();
                } else {
                    return false;
                }
            }
        ).filter( (promise) => promise !== false );

        return q.all(promises).then(this.refresh, this.refresh);
    };

    public unblock = (zones) => {
        const promises =  zones.map(
            (zone) => {
                this.restrictionHelper.init(zone, 'dns', this, 'zoneModifyRestrictions');
                if (this.restrictionHelper.hasRestrictions() && this.restrictionHelper.userCanEditRestrictions()) {
                    return this.restrictionHelper.removeAllRestrictions();
                } else {
                    return false;
                }
            }
        ).filter( (promise) => promise !== false );

        return q.all(promises).then(this.refresh, this.refresh);
    };

    public move = (zoneIds, toAccountId) => {
        return this.dnsRobot.zoneMove(zoneIds, toAccountId).then(this.refresh, this.refresh);
    };

    public moveByName = (zoneNames, toAccountId) => {
        return this.dnsRobot.zoneMoveByName(zoneNames, toAccountId).then(this.refresh, this.refresh);
    };

    public tieToTemplate  = (zones, templateId, replacements, overrideRecords) => {
        const promises = zones.map(
            (zone) => this.tieToTemplateOne(zone, templateId, replacements, overrideRecords)
        );

        return q.all(promises).then(this.refresh, this.refresh);
    };

    public findOneDnsSecOptions: (id: string) => q.IPromise<Types.DnsApi.DnsSecOptions>
    = (id) => {
        return this.dnsRobot.listDnsOptions({field: 'zoneConfigId', value: id}, 1, 1)
        .then(ModelHelper.returnFindOneResponse);
    };

    public changeContent = (content: any) => {
        return this.dnsRobot.contentChanger(content);
    };

    public restoreDnsZone = (zoneId: string) => {
        return this.dnsRobot.restoreDnsZone(zoneId);
    };

    public purgeRestorableDnsZone = (zoneId: string) => {
        return this.dnsRobot.purgeRestorableDnsZone(zoneId);
    };

    private refresh = (result) => {
        this.cache.get('dns::zoneConfigsFind').clear();
        this.cache.get('dns::recordsFind').clear();
        this.$rootScope.$broadcast('zones.refresh');
        return result;
    };

    private returnResponseData = (result) => {
        return result.response.data;
    };

    private filterRecords = (record) => {
        return record.type !== 'SOA'
        && (
            !record.hasOwnProperty('recordTemplateId')
            || record.recordTemplateId === null
        );
    };

    private untieOne = (zone) => {
        if (zone.templateValues === null || zone.templateValues.templateId === 0) {
            return q.when(zone);
        }

        return this.dnsRobot.untieZoneFromTemplate(zone.id);
    };

    private tieToTemplateOne = (zone, templateId: string, replacements, overrideRecords) => {
        let templateReplacements;
        if ([undefined, null].indexOf(replacements) >= 0) {
            templateReplacements = {};
        } else if (
            [undefined, null].indexOf(replacements.ipv4Replacement) >= 0
            && [undefined, null].indexOf(replacements.ipv6Replacement) >= 0
            && [undefined, null].indexOf(replacements.mailIpv4Replacement) >= 0
            && [undefined, null].indexOf(replacements.mailIpv6Replacement) >= 0
        ) {
            templateReplacements = {
                ipv4Replacement: replacements.ipv4,
                ipv6Replacement: replacements.ipv6,
                mailIpv4Replacement: replacements.mxv4,
                mailIpv6Replacement: replacements.mxv6
            };
        } else {
            templateReplacements = {
                ipv4Replacement: replacements.ipv4Replacement,
                ipv6Replacement: replacements.ipv6Replacement,
                mailIpv4Replacement: replacements.mailIpv4Replacement,
                mailIpv6Replacement: replacements.mailIpv6Replacement
            };
        }

        zone.templateValues = {
            templateId: templateId,
            templateReplacements: templateReplacements,
            tieToTemplate: true
        };

        let recordFilter;

        if (overrideRecords) {
            recordFilter = {
                subFilter: [
                    {field: 'recordType', value: 'NS'},
                    {field: 'recordName', value: zone.name}
                ],
                subFilterConnective: 'AND'
            };
        } else {
            // SOA Records can't be persisted with the API, as it's always recreated. See DNS-170
            recordFilter = {field: 'recordType', value: 'SOA', relation: 'UNEQUAL'};
        }

        return this.findRecords(zone.id, recordFilter)
        .then((records) => this.dnsRobot.recreateZone(zone, records));
    };

    private returnResponse = (result) => {
        return result.response;
    };
}
