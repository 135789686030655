import * as ng from 'angular';
import { AuthContextService, NavigationService } from '../../../../services';

export class OrganPanelLoginEnableTwofaController {
    public static $inject: string[] = ['navigation'];

    public credentials: any;
    public twofaData: any;
    public showPasscodes: boolean;
    public $editForm: { save: () => void };

    constructor(
        private navigation: NavigationService
    ) {}

    public get disableSubmitButton() {
        return this.twofaData.confirmationCode === undefined || this.twofaData.confirmationCode.length !== 6;
    }

    public submit = () => {
        this.$editForm.save();
    };

    public goToDashboard = () => {
        if (
            AuthContextService.authenticated
            && AuthContextService?.account?.billingSettings?.dataProcessingAgreementSignedCurrentVersion === false
            && AuthContextService?.isDirectCustomer
        ) {
            this.navigation.go('avv');
        } else {
            this.navigation.go('dashboard');
        }
    };

}

export class OrganPanelLoginEnableTwofaComponent implements ng.IComponentOptions {
    public bindings = {
        credentials: '=',
        invalid: '<',
        showPasscodes: '<',
        twofaData: '='
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public template = require('./panel-login-enable-twofa.html');
    public controller = OrganPanelLoginEnableTwofaController;
}
