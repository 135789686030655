export const CharPoolsConst = {
    germanUmlauts: { translationId: 'TR_061120-0d803a_TR', value: 'öäüÖÄÜ' },
    letters: { translationId: 'TR_110521-0c68a0_TR', value: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ' },
    lettersLower: { translationId: 'TR_290819-9511bc_TR', value: 'abcdefghijklmnopqrstuvwxyz' },
    lettersUpper: { translationId: 'TR_290819-7b1205_TR', value: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' },
    numerals: { translationId: 'TR_290819-67a2b1_TR', value: '0123456789' },
    specialSigns: { translationId: 'TR_090119-02a62f_TR', value: '+-=?%()$*' },
    specialSignsDatabaseAccess: { translationId: 'TR_090119-02a62f_TR', value: '!@#$%^&*()_+-,' },
    specialSignsNextcloud: { translationId: 'TR_090119-02a62f_TR', value: '+-=.,:;_!?$#%&~^*|/()@{}[]<>' },
    specialSignsWebSpaceAccess: { translationId: 'TR_090119-02a62f_TR', value: '\\§/"\'´`.,:;[]{}?~!@#$%^&*()_+-' },
    nonAlphaNumericalChars: { translationId: 'TR_090119-02a62f_TR', value: '!"§$%&/()=?*+~#><|;:,.-_\'' }
};

export const AlphaNumericCaseInsensitive = CharPoolsConst.lettersLower.value
    .concat(CharPoolsConst.lettersUpper.value)
    .concat(CharPoolsConst.numerals.value);

export const AlphaNumericCaseInsensitivePlusUmlauts = CharPoolsConst.lettersLower.value
    .concat(CharPoolsConst.lettersUpper.value)
    .concat(CharPoolsConst.germanUmlauts.value)
    .concat(CharPoolsConst.numerals.value);

export const AlphaNumericLowerCase = CharPoolsConst.lettersLower.value
    .concat(CharPoolsConst.numerals.value);

export const NextcloudUserPasswordCharacterPoolsConst = [
    CharPoolsConst.letters.value,
    CharPoolsConst.numerals.value,
    CharPoolsConst.specialSignsNextcloud.value
];

export const DatabaseAccessPasswordCharacterPoolsConst = [
    CharPoolsConst.lettersLower.value,
    CharPoolsConst.lettersUpper.value,
    CharPoolsConst.numerals.value,
    CharPoolsConst.specialSignsDatabaseAccess.value
];

export const WebspaceAccessPasswordCharacterPoolsConst = [
    CharPoolsConst.lettersLower.value,
    CharPoolsConst.lettersUpper.value,
    CharPoolsConst.numerals.value,
    CharPoolsConst.specialSignsWebSpaceAccess.value
];

export const DefaultPasswordCharacterPoolsConst = [
    CharPoolsConst.lettersLower.value,
    CharPoolsConst.lettersUpper.value,
    CharPoolsConst.numerals.value,
    CharPoolsConst.nonAlphaNumericalChars.value
];
