import { AsterikNoteObject, AsteriskNoteService, AsteriskNoteType } from './../../../services/billing/asterisk-note-service';

export class MoleculeAsteriskNoteStarMarkerController implements ng.IController {
    public static $inject: string[] = ['asteriskNote'];

    public noteType: AsteriskNoteType;
    public noteValue: string;

    constructor(
        public asteriskNote: AsteriskNoteService
    ) {}

    public set asteriskNoteExists({}) {} // tslint:disable-line:no-empty
    public get asteriskNoteExists() {
        if (!this.noteType) {
            return false;
        }

        return this.asteriskNote.addNote(this.noteType, this.noteValue) > 0;
    }
}

export class MoleculeAsteriskNoteStarMarkerComponent implements ng.IComponentOptions {
    public bindings = {
        noteType: '<',
        noteValue: '<?'
    };
    public controller =  MoleculeAsteriskNoteStarMarkerController;
    public template = require('./asterisk-note-star-marker.html');
}
