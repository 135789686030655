import * as ng from 'angular';
import { DomainStateFilter, ObjectStatusFilter } from '../filters';
import { AuthContextService } from './auth-context';
import { NavigationService } from './navigation';

export interface ApiObjectHelper {
    getEndDate: (product: any) => string;
    getName: (product: any) => string;
    getStatus: (product: any) => string;
    goToEditPage: (product: any) => void;
    isOfType: (product: any) => boolean;
    name: string;
    type: string;
}

export class ApiObjectHelperService {
    public static $inject: string[] = [
        '$translate',
        'objectStatusFilter',
        'domainStateFilter',
        'navigation'
    ];

    private types: ApiObjectHelper[];

    constructor(
        $translate: ng.translate.ITranslateService,
        objectStatusFilter: ObjectStatusFilter,
        domainStateFilter: DomainStateFilter,
        navigation: NavigationService
    ) {
        this.types = [
            /* EMAIL */
            {
                // Forwarder
                getEndDate: (product) => product.paidUntil || '--',
                getName: (product) => product.emailAddressUnicode,
                getStatus: (product) => objectStatusFilter(product.status),
                goToEditPage: (product) => product.bundleId
                    ? navigation.go(
                        'bundle.id.mailboxes.id.edit',
                        { bundleId: product.bundleId, mailboxId: product.id }
                    )
                    : navigation.go('email.mailboxes.id.edit', { mailboxId: product.id }),
                isOfType: (product) => product.type && product.type === 'Forwarder',
                name: $translate.instant('EMAIL.MAILBOX-TYPE.FORWARDER'),
                type: 'forwarder'
            },
            {
                // IMAP mailbox
                getEndDate: (product) => product.paidUntil || '--',
                getName: (product) => product.emailAddressUnicode,
                getStatus: (product) => objectStatusFilter(product.status),
                goToEditPage: (product) => product.bundleId
                    ? navigation.go(
                        'bundle.id.mailboxes.id.edit',
                        { bundleId: product.bundleId, mailboxId: product.id }
                    )
                    : navigation.go('email.mailboxes.id.edit', { mailboxId: product.id }),
                isOfType: (product) => product.type && product.type === 'ImapMailbox',
                name: $translate.instant('EMAIL.MAILBOX-TYPE.IMAP'),
                type: 'imap-mailbox'
            },
            {
                // Exchange Mailbox
                getEndDate: (product) => product.paidUntil || '--',
                getName: (product) => product.emailAddressUnicode,
                getStatus: (product) => objectStatusFilter(product.status),
                goToEditPage: (product) => product.bundleId
                    ? navigation.go(
                        'bundle.id.mailboxes.id.edit',
                        { bundleId: product.bundleId, mailboxId: product.id }
                    )
                    : navigation.go('email.mailboxes.id.edit', { mailboxId: product.id }),
                isOfType: (product) => product.type && product.type === 'ExchangeMailbox',
                name: $translate.instant('EMAIL.MAILBOX-TYPE.EXCHANGE'),
                type: 'exchange-mailbox'
            },
            {
                // Catchall.
                getEndDate: (product) => product.paidUntil || '--',
                getName: (product) => product.emailAddressUnicode,
                getStatus: (product) => objectStatusFilter(product.status),
                goToEditPage: (product) => product.bundleId
                    ? navigation.go(
                        'bundle.id.mailboxes.id.edit',
                        { bundleId: product.bundleId, mailboxId: product.id }
                    )
                    : navigation.go('email.mailboxes.id.edit', { mailboxId: product.id }),
                isOfType: (product) => product?.type === 'CatchAll',
                name: $translate.instant('GENERAL.OBJECT.TYPE.EMAIL.CATCHALL'),
                type: 'catch-all'
            },
            {
                // List
                getEndDate: (product) => product.paidUntil || '--',
                getName: (product) => product.emailAddressUnicode,
                getStatus: (product) => objectStatusFilter(product.status),
                goToEditPage: (product) => product.bundleId
                    ? navigation.go(
                        'bundle.id.mailboxes.id.edit',
                        { bundleId: product.bundleId, mailboxId: product.id }
                    )
                    : navigation.go('email.mailboxes.id.edit', { mailboxId: product.id }),
                isOfType: (product) => product.type && product.type === 'MailingList',
                name: $translate.instant('GENERAL.OBJECT.TYPE.EMAIL.MAILING-LIST'),
                type: 'mailing-list'
            },

            /* DOMAIN */
            {
                getEndDate: (product) => product.paidUntil || '--',
                getName: (product) => product.nameUnicode,
                getStatus: (product) => domainStateFilter(product.status, product.deletionType),
                goToEditPage: (product) => product.bundleId
                    ? navigation.go(
                        'bundle.id.domains.id.edit',
                        { bundleId: product.bundleId, domainId: product.id, vhost: 'domain' }
                    )
                    : navigation.go('domain.domains.id.edit', { domainId: product.id }),
                isOfType: (product) => product.authInfo !== undefined && product.contacts !== undefined,
                name: $translate.instant('TR_140119-3834d8_TR'),
                type: 'domain'
            },
            {
                // Domain redirection
                getEndDate: () => '',
                getName: (product) => product.domainNameUnicode,
                getStatus: (product) => {
                    product.status = 'active';
                    return objectStatusFilter(product.status);
                },
                goToEditPage: (product) => navigation.go('domain.redirections.id.edit', { redirectionId: product.id }),
                isOfType: (product) => product.domainNameUnicode !== undefined && product.redirectionUrl !== undefined,
                name: $translate.instant('DOMAIN.GENERAL.REDIRECTION'),
                type: 'redirection'
            },
            {
                // Domain contact
                getEndDate: () => '',
                getName: (product) => product.handle,
                getStatus: () => '',
                goToEditPage: (product) => navigation.go('domain.contacts.id.edit', { contactId: product.id }),
                isOfType: (product) => product.handle !== undefined && product.name !== undefined,
                name: $translate.instant('DOMAIN.GENERAL.HANDLE'),
                type: 'handle'
            },

            /* DNS */
            {
                getEndDate: () => '',
                getName: (product) => product.nameUnicode,
                getStatus: (product) => objectStatusFilter(product.status),
                goToEditPage: (product) => navigation.go('dns.zones.id.edit', { zoneId: product.id }),
                isOfType: (product) => product.soaValues !== undefined && product.type !== undefined,
                name: $translate.instant('DNS.GENERAL.ZONE'),
                type: 'zone'
            },

            /* SSL */
            {
                getEndDate: (product) => product.endDate,
                getName: (product) => product.commonName,
                getStatus: (product) => objectStatusFilter(product.status),
                goToEditPage: (product) => navigation.go('ssl.certificates.id.edit', { certificateId: product.id }),
                isOfType: (product) => product.rootCert !== undefined,
                name: $translate.instant('SSL.GENERAL.CERTIFICATE'),
                type: 'certificate'
            },

            /* Databases */
            {
                getEndDate: (product) => product.paidUntil || '--',
                getName: (product) => product.name,
                getStatus: (product) => objectStatusFilter(product.status),
                goToEditPage: (product) => product.bundleId
                    ? navigation.go(
                        'bundle.id.databases.id.edit',
                        { bundleId: product.bundleId, databaseId: product.id }
                    )
                    : navigation.go('database.databases.id.edit', { databaseId: product.id }),
                isOfType: (product) => product.dbName !== undefined,
                name: $translate.instant('DATABASE.GENERAL.DATABASE'),
                type: 'database'
            },

            /* VM */
            {
                getEndDate: (product) => product.paidUntil || '--',
                getName: (product) => product.name,
                getStatus: (product) => objectStatusFilter(product.status),
                goToEditPage: (product) => navigation.go('machine.virtualmachines.id.edit', { machineId: product.id }),
                isOfType: (product) => product.rescue !== undefined,
                name: $translate.instant('9fd32096-6a1f-43c6-a467-4ef75d7593e7'),
                type: 'machine'
            },

            /* Webhosting */
            {
                getEndDate: (product) => product.paidUntil || '--',
                getName: (product) => product.name,
                getStatus: (product) => objectStatusFilter(product.status),
                goToEditPage: (product) => product.bundleId
                    ? navigation.go('bundle.id.dashboard', { bundleId: product.bundleId })
                    : navigation.go('webhosting.webspaces.id.edit', { webspaceId: product.id }),
                isOfType: (product) => product.webspaceName !== undefined,
                name: $translate.instant('TR_210319-eeec82_TR'),
                type: 'webspace'
            },
            {
                getEndDate: (product) => product.paidUntil || '--',
                getName: (product) => product.domainNameUnicode,
                getStatus: (product) => objectStatusFilter(product.status),
                goToEditPage: (product) => navigation.go(
                    'webhosting.webspaces.id.vhosts.id.edit',
                    { webspaceId: product.webspaceId, vHostId: product.id }
                ),
                isOfType: (product) => product.webRoot !== undefined,
                name: $translate.instant('WEBHOSTING.GENERAL.VHOST'),
                type: 'vhost'
            },
            // Bundle
            {
                getEndDate: (product) => product.paidUntil || '--',
                getName: (product) => product.name,
                getStatus: (product) => objectStatusFilter(product.status),
                goToEditPage: (product) => navigation.go('bundle.id.dashboard', { bundleId: product.id }),
                isOfType: (product) => product.productCode && product.productCode.startsWith('bundle'),
                name: $translate.instant('BILLING.BUNDLE.BUNDLE'),
                type: 'bundle'
            },
            // Account
            {
                getEndDate: (product) => product.paidUntil || '--',
                getName: (product) => product.name,
                getStatus: () => '',
                goToEditPage: (product) => AuthContextService.account.id === product.id
                    ? navigation.go('account.basicdata')
                    : navigation.go('reseller.subaccounts.id.edit', { subaccountId: product.id }),
                isOfType: (product) => product.rights && product.parentAccountId !== undefined,
                name: $translate.instant('TR_140119-b3b187_TR'),
                type: 'account'
            },
            // Managed Application
            {
                getEndDate: (product) => product.paidUntil || '--',
                getName: (product) => product.name,
                getStatus: (product) => objectStatusFilter(product.status),
                goToEditPage: (product) => navigation.go('nextcloud.id.edit', { storageProductId: product.id }),
                isOfType: (product) => product?.productCode?.includes('nextcloud'),
                name: $translate.instant('TR_140519-4f0af6_TR'),
                type: 'managedApplication'
            },
            // User
            {
                getEndDate: (product) => product.paidUntil || '--',
                getName: (product) => product.name,
                getStatus: () => '',
                goToEditPage: (product) => navigation.go('account.users.id.edit', { userId: product.id }),
                isOfType: (product) => product.rights && 'adminUser' in product,
                name: $translate.instant('TR_220519-49de95_TR'),
                type: 'user'
            }
        ];
    }

    public getType: (product: any) => ApiObjectHelper =
    (product) => {
        for (const type of this.types) {
            if (type.isOfType(product)) {
                return type;
            }
        }
    };
}
