import ng from 'angular';
import * as Types from '@/types';
import { DomainTypes } from '@/atomic-components/organs';

export class OrganismRedirectionWizardConfigViewController {
    public static $inject: string[] = [];

    public bundle: Types.BundleApi.Bundle;
    public callback: (metaData: Types.ViewTypes.ProductConfigDomainRedirectionObject) => {/* */};
    public configCompleted = false;
    public outerAccount: Types.AccountApi.Account;
    public ownerAccount:  Types.AccountApi.Account;
    public metadata: Types.ViewTypes.ProductConfigDomainRedirectionObject;
    public selectedDomainType: DomainTypes;
    public service = 'domain';
    public sufficientCreditAvailable = true;

    private _account: Types.AccountApi.Account | Types.AccountApi.Subaccount;

public constructor() {/* */}

    public $onInit = (): void => {
        this.metadata = {
            account: undefined,
            domainName: '',
            type: '',
            title: '',
            redirectionUrl: '',
            adjustZone: false,
            metaTagAuthor: '',
            metaTagKeywords: '',
            metaTagDescription: '',
            price: [],
            adjustRecords: false,
            zone: undefined
        };
    };

    public finishConfiguration = (): void => {
        if (this.callback !== undefined) {
            this.callback(this.metadata);
        }
    };

    get account(): Types.AccountApi.Account | Types.AccountApi.Subaccount {
        return this._account;
    }

    set account(value: Types.AccountApi.Account | Types.AccountApi.Subaccount) {
        if (
            [undefined, null].indexOf(value) < 0
            && value !== this._account
            && [undefined, null].indexOf(this.metadata) < 0
        ) {
            this.metadata.account = ng.copy(value);
        }

        this._account = value;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set configurationIsCompleted({}) {}
    public get configurationIsCompleted(): boolean {
        if (this.metadata.type === 'frame' && (
            this.metadata.metaTagAuthor?.length === 0
            || this.metadata.metaTagDescription?.length === 0
            || this.metadata.metaTagKeywords?.length === 0
            || this.metadata?.title?.length === 0)
        ) {
            return false;
        }

        return !!this.metadata.account
            && !!this.metadata.domainName
            && !!this.metadata.type
            && !!this.metadata.redirectionUrl;
    }
}

export class OrganismRedirectionWizardConfigViewComponent implements ng.IComponentOptions {
    public bindings = {
        callback: '<'
    };

    public template = require('./redirection-wizard-config-view.html');
    public controller = OrganismRedirectionWizardConfigViewController;
}
