import * as ng from 'angular';
import { NavigationService } from '../../../../../services';
import { WizardPromoProduct } from '../../../panels/';

export class MoleculeFormPromoProductBoxController implements ng.IController {
    public static $inject: string[] = ['$translate', 'navigation'];

    public boxContent: WizardPromoProduct;

    public headLine: string;
    public description: string;
    public buttonText: string;
    public displaySupportModal = { promise: false, contact: false };

    constructor(
        private $translate: ng.translate.ITranslateService,
        private navigation: NavigationService
    ) {}

    public $onInit() {
        if (this.boxContent.headLine) {
            this.headLine = this.$translate.instant(this.boxContent.headLine);
        }
        this.description = this.$translate.instant(this.boxContent.description);
        this.buttonText = this.$translate.instant(this.boxContent.buttonText);
    }

    public callItemRoute = () => {
        if (this.boxContent.route === 'show.contact.form') {
            this.displaySupportModal.contact = true;
        } else {
            this.navigation.go(this.boxContent.route, this.boxContent.routeParams);
        }
    };
}

export class MoleculeFormPromoProductBoxComponent implements ng.IComponentOptions {
    public bindings = {
        boxContent: '<'
    };
    public template = require('./promo-product-box.html');
    public controller =  MoleculeFormPromoProductBoxController;
}
