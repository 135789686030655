import * as ng from 'angular';

export class OrganCreateConfigurationNextcloudConditionsController implements ng.IController {
    public modelConditionsChecked = false;
    public modalOpen = false;

    public openModal() {
        this.modalOpen = true;
    }

    public get completed(): boolean {
        return this.modelConditionsChecked;
    }

    public set completed({}) {} // tslint:disable-line:no-empty
}

export class OrganCreateConfigurationNextcloudConditionsComponent implements ng.IComponentOptions {
    public bindings = {
        completed: '='
    };
    public template = require('./conditions.html');
    public controller =  OrganCreateConfigurationNextcloudConditionsController;
}
