import * as ng from 'angular';

import { DateWrapper, ValidateNotEmpty } from '../../../../../services';
import {
    OrganismEditFormMailboxController
} from '../../../../organisms/forms/edit-forms/mailbox-edit/mailbox-edit';

export class OrganEditPanelMailboxAutoresponderController {
    public static $inject: string[] = ['$timeout', '$translate'];

    public cancel: () => any;
    public $editFormOrganism: OrganismEditFormMailboxController;
    public panelRight: any;
    public responderStatus: boolean;
    public validationInstructions: any[];
    public activeDateRange = false;
    public autoResponderData = {
        enabled: true,
        subject: '',
        body: '',
        start: null as any,
        end: null as any
    };
    private initalDateRangeToggleState: boolean;
    private initalAutoResponderData: any;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService
    ) {}

    $onInit() {
        this.$timeout(
            () => {
                this.responderStatus = this.$editFormOrganism.mailbox.autoResponder.enabled;
                this.autoResponderData = ng.copy(this.$editFormOrganism.mailbox.autoResponder);
                this.validationInstructions = [{
                    validator: new ValidateNotEmpty(this.$translate)
                }];

                if (this.autoResponderData.start !== null) {
                    this.activeDateRange = true;
                    this.autoResponderData.start = new DateWrapper(new Date(String(this.autoResponderData.start)));
                }

                if (this.autoResponderData.end !== null) {
                    this.autoResponderData.end = new DateWrapper(new Date(String(this.autoResponderData.end)));
                }

                this.initalDateRangeToggleState = this.activeDateRange;
                this.initalAutoResponderData = this.autoResponderData;
            });
    }

    public get dateRangeWarning() {
        if (
            !this.autoResponderData.enabled
            || this.autoResponderData.start === null
            || this.autoResponderData.end === null
        ) {
            return '';
        }

        const currentDate = new Date();
        const startDate = new Date(this.autoResponderData.start.dateString);
        const endDate = new Date(this.autoResponderData.end.dateString);

        if (
            currentDate.getTime() < startDate.getTime()
            || currentDate.getTime() > endDate.getTime()
        ) {
            return this.$translate.instant('TR_120220-aacd3e_TR');
        }

        return '';
    }

    public cancelEdit = () => {
        this.activeDateRange = this.initalDateRangeToggleState;
        this.autoResponderData = this.initalAutoResponderData;
        if (typeof (this.cancel) === 'function') {
            this.cancel();
        }
        this.$timeout(
            () => {
                this.$onInit();
            }
        );
    };

    public toggleDateRange = () => {
        this.$timeout(
            () => {
                if (!this.activeDateRange) {
                    this.autoResponderData.start = null;
                    this.autoResponderData.end = null;
                }
                if (!this.$editFormOrganism.mailbox.autoResponder.enabled) {
                    this.autoResponderData.start = null;
                    this.autoResponderData.end = null;
                }
                this.onChangeResponderData();
            }
        );
    };

    public toggleAutoResponder = () => {
        this.$timeout(
            () => {
                if (!this.$editFormOrganism.mailbox.autoResponder.enabled) {
                    this.$editFormOrganism.mailbox.autoResponder.subject = null;
                    this.$editFormOrganism.mailbox.autoResponder.body = null;
                    this.autoResponderData.start = null;
                    this.autoResponderData.end = null;
                }
                this.onChangeResponderData();
            }
        );
    };

    public getTimeframeAsString = () => {
        if (this.activeDateRange) {
            if (this.autoResponderData.start === null) {
                this.$onInit();
                return '';
            }
            return (this.autoResponderData.start.dateStringDMY() + ' - ' + this.autoResponderData.end.dateStringDMY());
        }
        return '';
    };

    public onChangeResponderData = () => {
        this.$timeout(
            () => {
                this.$editFormOrganism.mailbox.autoResponder.start = null;
                this.$editFormOrganism.mailbox.autoResponder.end = null;
                if (this.$editFormOrganism.mailbox.autoResponder.enabled) {
                    if (this.autoResponderData.start instanceof DateWrapper) {
                        this.$editFormOrganism.mailbox.autoResponder.start = this.autoResponderData.start.dateObj;
                        this.autoResponderData.end.dateObj.setUTCHours(23, 59, 59, 999);
                        this.$editFormOrganism.mailbox.autoResponder.end = this.autoResponderData.end.dateObj;
                    }
                }
            }
        );
    };

    public readableBody = () => {
        if (this.$editFormOrganism.mailbox.autoResponder.body.length > 250) {
            return this.$editFormOrganism.mailbox.autoResponder.body.substring(0, 250) + '...';
        }
        return this.$editFormOrganism.mailbox.autoResponder.body;
    };
}

export class OrganEditPanelMailboxAutoresponderComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormMailbox'
    };
    public controllerAs = '$editPanelOrgan';
    public controller = OrganEditPanelMailboxAutoresponderController;
    public template = require('./mailbox-autoresponder.html');
}
