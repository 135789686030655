import * as ng from 'angular';
import {
    AlertManagerService,
    WebspaceModelService
} from '../../../../../services';

export class OrganismEditFormBundleStorageController {
    public static $inject: string[] = ['$translate', 'webspaceModel', 'alertManager'];

    public originalWebspace: any;
    public webspace: any = {};
    public userPanelRight: any;
    public storageProductInfo: any;
    public storagePriceInfo: any;
    public additionalStorageItems = 0;
    public minimumStorage = 1024;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private webspaceModel: WebspaceModelService,
        private alertManager: AlertManagerService
    ) {}

    public save = () => {
        this.webspaceModel.update(this.webspace).then(
            () => {
                this.alertManager.success(this.$translate.instant('TR_100119-a00d85_TR'));
            }
        );
        this.originalWebspace = ng.copy(this.webspace);
    };

    public $onInit = () => {
        this.additionalStorageItems = 0;
        this.webspace = ng.copy(this.originalWebspace);
    };

    public cancelStorage = () => {
        this.additionalStorageItems = 0;
        this.webspace.storageQuota = this.originalWebspace.storageQuota;
    };
}

export class OrganismEditFormBundleStorageComponent implements ng.IComponentOptions {
    public bindings = {
        originalWebspace: '<webspace',
        userPanelRight: '=',
        minimumStorage: '<'
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormBundleStorageController;
    public template = require('./bundle-storage-edit.html');
}
