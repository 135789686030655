import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels';

export class TemplateStorageJobDetailsController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;
    public job: any;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'storage.storage-products.jobs',
            backwardText: this.$translate.instant('TR_160519-9ee62f_TR'),
            panelHeaderRoute: '',
            panelIcon: 'save',
            panelTitle: this.$translate.instant('TR_160519-b06391_TR', { jobName: this.job.displayName })
        };
    };
}

export class TemplateStorageJobDetailsComponent implements ng.IComponentOptions {
    public bindings = {
        job: '<'
    };
    public template = require('./storage-job-details-template.html');
    public controller = TemplateStorageJobDetailsController;
}
