import * as q from 'q';

import { UIRegExp } from '@/configuration';

import * as Types from '../../types';
import { ModelHelper } from '../model-helper';
import { UserRobotService } from './robot';

export class UserModelService {
    public static $inject: string[] = ['userRobot'];

    public service = 'user';

    constructor(
        private userRobot: UserRobotService
    ) {}

    public list = (
        limit?: number,
        page?: number,
        filters?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions
    ) => {
        if (filters === undefined) {
            filters = null;
        }
        if (sort === undefined) {
            filters = null;
        }
        sort = sort || {
            field: 'userName',
            order: 'ASC'
        };
        page = page || 1;

        return this.userRobot.listUsers(filters, limit, page, null)
        .then(ModelHelper.returnListResponse);
    };

    public findOne = (id: string) => {
        return this.userRobot.listUsersWithoutPagination({ field: 'userId', value: id.toString() }, 1, 1)
        .then(ModelHelper.returnFindOneResponse);
    };

    public create = (user: Types.AccountApi.User, password: string, owner?: string) => {
        return this.userRobot.createUser(user, password, owner);
    };

    public findById = (ids: string[]) => {
        const filter: Types.Finding.Filter = { subFilterConnective: 'OR', subFilter: ids.map(this.idFilter) };
        return this.userRobot.listUsersWithoutPagination(filter, 0, 1)
        .then(ModelHelper.returnFindResponse);
    };

    public update = (user: Types.AccountApi.User, oldPassword?: string, newPassword?: string) => {
        return this.userRobot.updateUser(user, oldPassword, newPassword);
    };

    public updateOwnProfile = (profile: Types.AccountApi.UserProfile) => {
        return this.userRobot.updateOwnProfile(profile);
    };

    public setOwnSecret = (oldPassword: string, newPassword: string) => {
        return this.userRobot.userSetOwnPassword(oldPassword, newPassword);
    };

    public delete = (users: Types.AccountApi.User[]) => {
        const promises = users.map((user) => this.deleteOne(user));
        return q.all(promises);
    };

    public userActivateTwoFactorAuthentication = (userId: string, token: string, password?: string) => {
        return this.userRobot.userActivateTwoFactorAuthentication(userId, token, password);
    };

    public userEnableTwoFactorAuthentication = (userId: string, type: string, userPassword: string) => {
        return this.userRobot.userEnableTwoFactorAuthentication(userId, type, userPassword)
        .then(this.returnResponse);
    };

    public userDisableTwoFactorAuthentication = (userId: string, password: string) => {
        return this.userRobot.userDisableTwoFactorAuthentication(userId, password);
    };

    public userRequire2FAAuth = (userId: string) => {
        return this.userRobot.userRequire2FAAuth(userId);
    };

    public userRegenerateTwoFactorAuthenticationOneTimePasswords = (password: string) => {
        return this.userRobot.userRegenerateTwoFactorAuthenticationOneTimePasswords(password);
    };

    public isCustomer = (accountId: string) => {
        return !UIRegExp.IsNumeric.test('' + accountId)
            || parseInt(accountId, 10) > 1000;
    };

    public userChangeOwnEmailAddress = (email: string, password: string) => {
        return this.userRobot.userChangeOwnEmailAddress(email, password);
    };

    public userChangeEmailAddress = (userId: string, emailAddress: string, password: string) => {
        return this.userRobot.userChangeEmailAddress(userId, emailAddress, password);
    };

    public userPromoteToAdmin = (userId: string, oldAdminPassword: string) => {
        return this.userRobot.userPromoteToAdmin(userId, oldAdminPassword);
    };

    public subaccountUserPromoteToAdmin = (userId: string, executingUserPassword: string) => {
        return this.userRobot.subaccountUserPromoteToAdmin(userId, executingUserPassword);
    };

    public userSetDashboardConfiguration = (uiMainDashboardConfiguration: string) => {
        return this.userRobot
            .userSetDashboardConfiguration(
                uiMainDashboardConfiguration
            );
    };

    public getUsersUiMainDashboardConfiguration = () =>  {
        return this.userRobot
            .userGetDashboardConfiguration()
            .then(this.returnResponse);
    };

    private returnResponse = (result: any) => {
        return result.response;
    };

    private idFilter = (id: string) => {
        return { field: 'userId', value: id };
    };

    private deleteOne = (user: Types.AccountApi.User) => {
        return this.userRobot.deleteUser(user.id.toString());
    };
}
