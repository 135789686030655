import * as ng from 'angular';

import { PanelHeaderData } from '@/atomic-components';
import { AuthContextService, NavigationService } from '@/services';

import './avv.scss';

export class TemplateAvvController {
    public static $inject: string[] = ['authContext', 'navigation'];

    public nearBottom: boolean;
    public isAllowedToSignAv = false;
    public pageHeaderData: PanelHeaderData;

    constructor(
        private authContext: AuthContextService,
        private navigation: NavigationService
    ) {}

    public $onInit = () => {
        if (!this.authContext.account) {
            this.navigation.go('login');
        }

        this.isAllowedToSignAv = (
            this.authContext.isDirectCustomer
            && this.authContext.isAdminUser
            && this.authContext.isMainUser
        );
    };

    public get alreadySigned() {
        return this.authContext?.account?.billingSettings?.dataProcessingAgreementSignedCurrentVersion;
    }

    public redirectToDashboard = () => {
        this.navigation.go('dashboard');
    };
}

export class TemplateAvvComponent implements ng.IComponentOptions {
    public template = require('./avv.html');
    public controller = TemplateAvvController;
}

