import * as ng from 'angular';
import { DomainRobotService } from './robot';

export class DomainCheckerService {
    public static $inject: string[] = ['domainRobot', 'CacheFactory', '$q'];

    private cache;

    constructor(
        private domainRobot: DomainRobotService,
        CacheFactory,
        private $q: ng.IQService
    ) {
        this.cache = CacheFactory('domainStatusCache', {
            capacity: 100,
            maxAge: 300000
        });
    }

    public check = (domains, accountId?) => {
        return this.domainRobot.domainStatus(domains, accountId)
            .then(
                (result) => {
                    if (result.errors !== undefined && ng.isArray(result.errors) && result.errors.length > 0) {
                        domains.forEach((domain) => {
                            if (this.cache.get(domain !== undefined)) {
                                this.cache.remove(domain);
                            }
                        });
                    }

                    return this.returnResponses(result);
                }
            );
    };

    public checkOne(domain) {
        if (domain === undefined) {
            return this.$q.when(undefined);
        }

        const cacheValue = this.cache.get(domain);
        if (cacheValue) {
            return this.$q.when(cacheValue);
        }

        const promise = this.check([domain])
            .then(
                this.returnFirstResponse,
                () => {
                    if (this.cache.get(domain !== undefined)) {
                        this.cache.remove(domain);
                    }
                }
            )
            .then(this.addToCache);

        this.cache.put(domain, promise);

        return this.$q.when(promise);
    }

    private returnResponses = (result) => {
        return result.responses;
    };

    private returnFirstResponse = (responses) => {
        return responses[0];
    };

    private addToCache = (response) => {
        if (response === undefined) {
            return response;
        }

        this.cache.put(response.domainNameUnicode, response);
        this.cache.put(response.domainName, response);

        return response;
    };
}
