import {
    LinkListOverviewItem,
    SelectFilterField
} from '../../atomic-components';
import { UiRights } from '../../configuration';
import { OverviewModel } from '../abstract';
import { AuthContextService } from '../auth-context';
import { RequestCancellerService } from '../request-canceller';
import { PaymentModelService } from './payment-model';

export class PaymentOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(['$translate', 'paymentModel']);

    public readonly service: string = 'billing';
    public readonly objectType: string = 'Payments';
    public readonly idField: string = 'objectNameUnicode';
    public readonly defaultFilterFields = ['ObjectNameUnicode'];

    public activeMassAction: string | boolean = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};
    public cutomizedModelListMethods: boolean;

    protected listCallbacks = [{ callback: this.paymentModel.list }];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private paymentModel: PaymentModelService
    ) {
        super(RequestCanceller);

        // declare multi action availably methods
        this.actionLinksAvailabilities = {};

        // declare multi action availably methods
        this.cutomizedModelListMethods = false;
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            const filterValues = [];

            if (AuthContextService.isGranted(UiRights.BIL_BUNDLE_LIST)) {
                filterValues.push({
                    name: this.$translate.instant('BILLING.BUNDLE.BUNDLE'),
                    value: 'bundle'
                });
            }

            if (AuthContextService.isGranted(UiRights.DOM_DOMAINS_LIST)) {
                filterValues.push({
                    name: this.$translate.instant('TR_140119-3834d8_TR'),
                    value: 'domain'
                });
            }

            if (AuthContextService.isGranted(UiRights.DB_DATABASE_LIST)) {
                filterValues.push({
                    name: this.$translate.instant('DATABASE.GENERAL.DATABASE'),
                    value: 'database'
                });
            }

            if (AuthContextService.isGranted(UiRights.WEB_OBJECT_LIST)) {
                filterValues.push({
                    name: this.$translate.instant('TR_210319-eeec82_TR'),
                    value: 'webhosting'
                });
            }

            if (AuthContextService.isGranted(UiRights.MACHINE_VM_LIST)) {
                filterValues.push({
                    name: this.$translate.instant('9fd32096-6a1f-43c6-a467-4ef75d7593e7'),
                    value: 'machine'
                });
            }

            if (AuthContextService.isGranted(UiRights.EMAIL_MAILBOX_LIST)) {
                filterValues.push({
                    name: this.$translate.instant('EMAIL.MAILBOX'),
                    value: 'email'
                });
            }

            this._cachedAvailableFilters = [
                new SelectFilterField(
                    'service',
                    this.$translate.instant('GENERAL.SERVICE'),
                    filterValues
                )
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters({}) {} // tslint:disable-line:no-empty

    public actionLinks = (): LinkListOverviewItem[] => {
        return [] as LinkListOverviewItem[];
    };
}
