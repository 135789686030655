import * as ng from 'angular';
import * as q from 'q';
import {
    AlertManagerService,
    AuthContextService,
    DnsZoneModelService
} from '../../../../../services';

export interface RecordContentChangeObj {
    accountId: string;
    type: string;
    oldValue: string;
    newValue: string;
    includeTemplates: boolean;
    includeSubAccounts: boolean;
}
export class OrganismRecordContentChangeController implements ng.IController {
    public static $inject: string[] = ['$translate', 'alertManager', 'dnsZoneModel'];

    public apiObject: RecordContentChangeObj;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private dnsZoneModel: DnsZoneModelService
    ) {}

    $onInit() {
        this.apiObject = {
            accountId: AuthContextService.account.id,
            type: 'A',
            oldValue: '',
            newValue: '',
            includeTemplates: false,
            includeSubAccounts: false
        };
    }

    public replaceContents = () => {
        return this.dnsZoneModel.changeContent(this.apiObject).then(
            (res) => {
                if (res.statis === 'error') {
                    return q.reject(res.errors);
                }

                this.alertManager.success(this.$translate.instant('TR_100119-93fbba_TR'));

                return { response: res };
            }
        );
    };
}

export class OrganismRecordContentChangeComponent implements ng.IComponentOptions {
    public controller = OrganismRecordContentChangeController;
    public template = require('./record-content-change.html');
}
