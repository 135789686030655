import * as ng from 'angular';
import { ApiValue } from '@/filters';
import {
    AlertManagerService,
    AuthContextService,
    BillingHelperService,
    DepositInfoObject,
    DepositModelService,
    NavigationService
} from '@/services/';

import './payment-recharge-deposit.scss';

declare const Stripe: unknown;

export class OrganEditPanelBillingPaymentRechargeDepositController {
    public static $inject: string[] = [
        '$filter',
        '$state',
        '$translate',
        'alertManager',
        'billingHelper',
        'depositModel',
        'navigation'
    ];

    public cancel;
    public $editFormOrganism;
    public panelRight;
    public account: any;
    public isSubmitting = false;
    public amount = '20';
    public customAmount = '';
    public amountValidationErrors: any[] = [];
    public paymentMethod: string|null;
    public paymentMethodIsAvailable: boolean;
    public successfullRecharged = false;
    public updateDepositLoading = false;
    public paymentType: string;
    public availablePaymentMethods: string[] = [];
    public amountItems: any[] = [];
    public deposit = {
        balance: 0,
        creditLimit: 0,
        creditRemaining: 0,
        depositAmount: 0,
        effectiveBalance: 0,
        effectiveVoucherBalance: 0,
        minRechargeSum: 0,
        positiveBalance: 0,
        reserved: 0,
        voucherBalance: 0
    };

    public parentInformation;
    public paymentServiceType: string;
    public paymentMethodItems: {label: string; value: string}[];
    public pendingBankAccountVerification: boolean;
    public loadingMinRechargeSumForPendingBankAccountVerification = false;

    private token: string|undefined;
    private payerId: string|undefined;
    private paymentId: string|undefined;
    private action: string|undefined;

    constructor(
        private $filter: ng.IFilterService,
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private billingHelper: BillingHelperService,
        private depositModel: DepositModelService,
        private navigation: NavigationService
    ) {
        this.account = AuthContextService.account;
        this.parentInformation = AuthContextService.accountOrganizationInformation;
        this.pendingBankAccountVerification = AuthContextService.accountPaymentDetails.pendingBankAccountVerification;

        void this.depositModel.paymentMethodAvailability().then(
            (paymentMethodAvailability: {paymentMethod: string; available: boolean}[]) => {
                this.availablePaymentMethods = paymentMethodAvailability
                    .map(
                        (method) => method.paymentMethod.toLowerCase()
                    );

                if (this.availablePaymentMethods.indexOf('stripe') >= 0) {
                    this.billingHelper.loadStripeScript();
                }
            }
        );

        this.paymentType = AuthContextService.account.billingSettings.paymentType.toLowerCase();
        this.paymentMethod = null;
        this.amountItems = [
            {value: '10', name: '10,- EUR'},
            {value: '20', name: '20,- EUR'},
            {value: '50', name: '50,- EUR'},
            {value: '100', name: '100,- EUR'},
            {value: '200', name: '200,- EUR'},
            {value: 'custom', name: this.$translate.instant('9bb7b676-fbeb-43fe-9872-a37971114a92')}
        ];
    }

    public $onInit(): void {
        if (this.pendingBankAccountVerification) {
            void this._updateDeposit();
        }

        this._successfullyRecharged();
        this.paymentMethodItems = [
            {
                label: this.$translate.instant('TR_090119-efbad6_TR'),
                value: 'paypal'
            },
            {
                label: this.$translate.instant('TR_051119-e75577_TR'),
                value: 'googlepay'
            },
            {
                label: this.$translate.instant('TR_090119-aa221e_TR'),
                value: 'stripe'
            }
        ];

        if (this.showBankTransferOption) {
            this.paymentMethodItems.push(
                {
                    label: this.$translate.instant('TR_241019-176eca_TR'),
                    value: 'banktransfer'
                }
            );
        }
    }

    public get contactDataComplete(): boolean {
        return ['name', 'street', 'zipCode', 'city', 'country', 'emailAddress', 'phoneNumber']
            .every((attribute) => {
                return [undefined, null, ''].indexOf(AuthContextService.account[attribute]) < 0;
            });
    }

    public payNow = (): void => {
        const amountToDeposit: number = this.amount !== 'custom'
            ? (+this.amount * 10000)
            : parseFloat((+this.customAmount * 10000).toFixed(2));

        if (
            this.showPendingBankAccountVerificationHint
            && (this.deposit.minRechargeSum * -1) > amountToDeposit
        ) {
            void this.alertManager.error(
                this.$translate.instant(
                    /* translationId */ 'TR_260221-25c0ff_TR',
                    {
                        minRechargeSum: this._getMinRechargeSumViewable()
                    }
                )
            );
            return;
        }

        this.isSubmitting = true;

        if (this.paymentMethod === 'paypal') {
            this.depositModel.depositCreditStart('paypal', amountToDeposit)
                .then(
                    (result) => {
                        window.location.assign(result.response.externalPaymentSite);
                    },
                    () => {
                        this.isSubmitting = false;
                    }
                );
        } else if (['stripe', 'googlepay'].indexOf(this.paymentMethod) >= 0) {
            void this.depositModel.depositCreditStart('stripe', amountToDeposit)
                .then(
                    (result) => {
                        if (result.response.paymentPublicKey.length > 0) {
                            this.isSubmitting = false;
                            const stripe = Stripe(result.response.paymentPublicKey); // eslint-disable-line new-cap

                            return void stripe.redirectToCheckout({
                                sessionId: result.response.externalPaymentSession
                            })
                                .then(
                                    (rst) => {
                                        // If `redirectToCheckout` fails due to a browser or network
                                        // error, display the localized error message to your customer
                                        // using `result.error.message`.
                                        return rst;
                                    }
                            );
                        }
                    }
                );
        }
    };

    public get showPendingBankAccountVerificationHint(): boolean {
        return this.pendingBankAccountVerification
            && this.deposit.minRechargeSum < -100000;
    }

    public get disableSubmitButton(): boolean {
        return this.paymentMethod === null
            || this.amount === null
            || (this.amount === 'custom' && this.amountValidationErrors.length !== 0)
            || this.isSubmitting
            || !this.contactDataComplete
            || this.paymentMethodIsAvailable === false;
    }

    public get paymentCodeURI(): string {
        if (this.parentInformation === undefined) {
            return undefined;
        }

        const depositAmount: number = this.amount !== 'custom'
            ? + this.amount
            : + this.customAmount;

        const reason = encodeURI(
            AuthContextService.account.customerNumber + ' / '
            + this.$translate.instant('TR_100119-b17de5_TR')
        );

        return 'bank://singlepaymentsepa'
            + '?name=' + encodeURI(this.parentInformation.companyName)
            + '&reason=' + reason
            + '&iban=' + this.parentInformation.companyBankAccount.accountNumber
            + '&bic=' + this.parentInformation.companyBankAccount.bic
            + '&amount=' + encodeURIComponent(depositAmount);
    }

    public closeRechargeHintBox = (): void => {
        this.updateDepositLoading = false;
        this.successfullRecharged = false;
        void this.navigation.go(this._getRefreshState(), {}, {reload: true});
    };

    public get showBankTransferOption(): string {
        return AuthContextService.account.customerNumber;
    }

    private _getMinRechargeSumViewable = (): string => {
        return this.$filter('currency')(
            (ApiValue.parseApiValue(this.deposit.minRechargeSum) * -1),
            AuthContextService.accountPaymentDetails.baseCurrency.replace(/&/g, '&nbsp;')
        ).replace(/\u00a0/g, ' ').toUpperCase(); // waht the f**k htmlentities!
    };

    private _successfullyRecharged = (): void => {
        if (['cancel', 'commit'].indexOf(this.action) < 0) {
            return;
        }

        switch (this.paymentServiceType) {
            case 'paypal':
                if (this.action === 'commit' && this.token !== undefined && this.payerId !== undefined) {
                    this.updateDepositLoading = true;
                    this.depositModel.paymentCommit('paypal', this.token, this.payerId)
                        .then(
                            (result) => {
                                if (result.code !== undefined) {
                                    return this._commitError();
                                }

                                void this._updateDeposit(result.response.value, true);
                            },
                            this._commitError
                        );
                }
                if (this.action === 'cancel' && this.token !== undefined) {
                    void this.depositModel.depositPaymentCancel(this.token);
                }
                break;
            case 'stripe':
            case 'googlepay':
                if (this.action === 'commit' && this.paymentId !== undefined) {
                    this.updateDepositLoading = true;
                    this.depositModel.paymentCommit('stripe', undefined, undefined, this.paymentId)
                        .then(
                            (result) => {
                                if (result.code !== undefined) {
                                    return this._commitError();
                                }

                                void this._updateDeposit(result.response.value, true);
                            },
                            this._commitError
                        );
                }

                if (this.action === 'cancel' && this.paymentId !== undefined) {
                    void this.depositModel.depositPaymentCancel(this.paymentId);
                }
                break;
            default:
                // nothing to do here;
        }
    };

    private getMinRechargeSumForPendingBankAccountVerification = (deposit: DepositInfoObject): number => {
        return (
            deposit.balance
            + deposit.voucherBalance
            + deposit.creditLimit
            - deposit.reserved
        );
    };

    private _updateDeposit = (depositAmount?: number, recharged?: boolean): Promise<void> => {
        recharged = recharged || false;
        depositAmount = depositAmount || 0;
        this.updateDepositLoading = true;
        this.deposit.depositAmount = depositAmount;

        return void this.depositModel.getDeposit().then(
            (deposit: DepositInfoObject) => {
                // minRechargeSum
                this.deposit.balance = deposit.balance;
                this.deposit.voucherBalance = deposit.voucherBalance;
                this.deposit.reserved = deposit.reserved;
                this.deposit.creditLimit = deposit.creditLimit;
                this.deposit.minRechargeSum = this.getMinRechargeSumForPendingBankAccountVerification(deposit);
                this.deposit.positiveBalance = deposit.balance + deposit.voucherBalance;
                this.deposit.effectiveVoucherBalance = ((deposit.voucherBalance - deposit.reserved) > 0)
                    ? (deposit.voucherBalance - deposit.reserved)
                    : 0;
                this.deposit.effectiveBalance = deposit.balance + deposit.voucherBalance - deposit.reserved;
                this.deposit.creditRemaining = deposit.balance + deposit.voucherBalance + deposit.creditLimit
                    - deposit.reserved;

                this.updateDepositLoading = false;
                if (recharged) {
                    this.successfullRecharged = true;
                }
            }
        );
    };

    private _commitError = (): void => {
        this.updateDepositLoading = false;
        this.successfullRecharged = false;
    };

    private _getRefreshState = (): string => {
        let refreshStateName = String(ng.copy(this.$state.current.name));
        const responseIndex = refreshStateName.indexOf('.commit');

        if (responseIndex >= 0) {
            refreshStateName = String(refreshStateName.substring(0, responseIndex));
        }

        return refreshStateName;
    };
}

export class OrganEditPanelBillingPaymentRechargeDepositComponent implements ng.IComponentOptions {
    public bindings = {
        action: '<?',
        cancel: '<',
        panelRight: '<',
        payerId: '<?',
        paymentId: '<?',
        paymentServiceType: '@',
        token: '<?'
    };
    public require = {
        $editFormOrganism: '^organismBillingPaymentOptions'
    };
    public controllerAs = '$viewPanelOrgan';
    public controller = OrganEditPanelBillingPaymentRechargeDepositController;
    public template = require('./payment-recharge-deposit.html'); // tslint:disable-line:max-line-length
}
