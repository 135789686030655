import ng from 'angular';
import * as Types from '@/types';

export class ProductSpecificationsService {

    public getProductSpecification = (productSpecification: Types.ProductApi.SpecificationItem[], name: string, defaultValue: any): any => {
        let result: any = defaultValue;
        if (productSpecification) {
            const productSpec: Types.ProductApi.SpecificationItem = productSpecification.find(ps => ps.name === name);
            if (productSpec && productSpec.value && !ng.isArray(productSpec.value)) {
                result = productSpec.value;
            }
        }
        return result;
    }

    public getMaxHostnameSans = (productSpecification: Types.ProductApi.SpecificationItem[]): number => {
        return Number(this.getProductSpecification(productSpecification, 'maxHostnameSans', '0'));
    }

    public isWildcardsAllowed = (productSpecification: Types.ProductApi.SpecificationItem[]): boolean => {
        let result = false;
        let maxAllowedWildecardSans: string|number = this.getProductSpecification(productSpecification, 'maxWildcardSans', '0') as string;
        maxAllowedWildecardSans = Number(maxAllowedWildecardSans);
        if (maxAllowedWildecardSans > 0) {
            result = true;
        }
        return result;
    }

    public isWwwOnTldIncluded = (productSpecification: Types.ProductApi.SpecificationItem[]): boolean => {
        let result = false;
        result = this.getProductSpecification(productSpecification, 'wwwOnTopLevelDomainIncluded', false ) as boolean;
        return result;
    }

    public isOnlyWildcardsAllowed = (productSpecification: Types.ProductApi.SpecificationItem[]): boolean => {

        let result = this.isWildcardsAllowed(productSpecification);

        if (result) {
            const maxHostnameSans = this.getMaxHostnameSans(productSpecification);
            const maxWildcardSans = Number(this.getProductSpecification(productSpecification, 'maxWildcardSans', '0') as string);
            result = maxHostnameSans == 0 && maxWildcardSans > 0;
        }

        return result;
    }

}
