import * as ng from 'angular';
import { PageHeaderData } from '@/atomic-components/organisms/page-header-panel/page-header-panel';
import { AuthContextService, NavigationService} from '@/services';

export class TemplateMainNewDashboardController {
    public static $inject: string[] = ['$translate', 'navigation'];

    public pageHeaderData: PageHeaderData;
    public showSystemNotification: boolean;
    public systemNotificationCallback: () => void;

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private navigation: NavigationService
    ) {}

    public $onInit(): void {
        const hour = new Date().getHours();
        let greeting = /* translationId */ 'TR_110119-922c4c_TR';

        switch (true) {
            case hour >= 5 && hour < 11: // morning
                greeting = /* translationId */ 'TR_010719-fce241_TR';
                break;
            case hour >= 12 && hour < 18: // day
                greeting = /* translationId */ 'TR_010719-a39171_TR';
                break;
            case hour >= 18 && hour < 22: // evening
                greeting = /* translationId */ 'TR_010719-a5afb3_TR';
                break;
            case (hour >= 22 && hour < 24) || hour < 5: // night
                greeting = /* translationId */ 'TR_010719-fe20d4_TR';
                break;
        }

        this.pageHeaderData = {
            panelHeaderRoute: '',
            panelIcon: 'home-lg-alt',
            panelTitle: this.$translate.instant(greeting, { userName: AuthContextService.user.name }),
            showSystemNotificationBell: this.showSystemNotification,
            systemNotificationCallback: null,
            productActions: [{
                actionFunction: this.changeToEditMode,
                title: 'Dashboard bearbeiten'
            }]
        };
    }

    public changeToEditMode = (): void => {
        void this.navigation.go('nextcloud-dashboard.edit', null, {reload: true});
    };
}

export class TemplateMainNewDashboardComponent implements ng.IComponentOptions {
    public bindings = {
        _isAdmin: '<isAdmin'
    };
    public controller = TemplateMainNewDashboardController;
    public template = require('./main-dashboard-template.html');
}
