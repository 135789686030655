import * as ng from 'angular';
import { UiRights } from '../../../../configuration';
import { AccountModelService } from '../../../../services';
import { AuthContextService } from '../../../../services/auth-context';
import {
    OrganEditPanelBillingPaymentRechargeDepositController
} from '../../../organs/panels/edit-panels/billing-payment-options/payment-recharge-deposit';
import { WizardObject } from '../wizard-supervisor/wizard-supervisor';

export class MoleculeWizardContactCompleteController implements ng.IController {
    public static $inject: string[] = ['accountModel'];

    public serviceObject: WizardObject;
    public contactDataComplete: boolean;
    public selectedAccountId: string;

    // Used to translate keys in  (translation pipe is in the template).
    public contactDataTranslation = {
        city: 'd25a5fcf-9137-442b-9842-9d226a02409a',
        country: '0c29de61-bfdb-4f7b-9b5e-d8e99190b64c',
        emailAddress: 'TR_150119-547c32_TR',
        name: 'TR_140119-796b28_TR',
        phoneNumber: 'TR_010621-ae83fd_TR',
        street: 'f50985f4-d9ae-47e4-ba9a-d703973b17a6',
        zipCode: 'c9a7709e-6ee3-430f-a865-9c627429d35b'
    };

    // Used for showing in template.
    private contactDataMissing = {
        city: false,
        country: false,
        emailAddress: false,
        name: false,
        phoneNumber: false,
        street: false,
        zipCode: false
    };

    private $recharge = OrganEditPanelBillingPaymentRechargeDepositController;

    public constructor(
        private accountModel: AccountModelService
    ) {}

    public $onInit() {
        this.checkContactComplete();
    }

    public $onChanges(changes: ng.IOnChangesObject) {
        if (changes.selectedAccountId) {
            this.checkContactComplete();
        }
    }

    public checkContactComplete = () => {
        this.contactDataComplete = this.$recharge.prototype.contactDataComplete;
        if (AuthContextService.isGranted(UiRights.ACC_SUBACCOUNT_LIST)) {
            this.accountModel.findOne(this.selectedAccountId).then(
                (result) => {
                    const usedAccount = (result === undefined) ? AuthContextService.account : result;
                    for (const key in this.contactDataMissing) {
                        if (this.contactDataMissing.hasOwnProperty(key)) {
                            this.contactDataMissing[key] = [undefined, null, ''].indexOf(usedAccount[key]) > -1;
                        }
                    }
                }
            );
        }
    };

    public get showProceedVerifiedPanel() {
        this.contactDataComplete = true;

        if (
            AuthContextService.isRootOrCompanyAccount
            || !AuthContextService.isMainUser
            || AuthContextService.account.id !== this.selectedAccountId
            || AuthContextService.account.billingSettings.paymentType === 'external'
        ) {
            this.contactDataComplete = true;
            return !this.contactDataComplete;
        }

        for (const data in this.contactDataMissing) {
            if (this.contactDataMissing.hasOwnProperty(data) && this.contactDataMissing[data] === true) {
                this.contactDataComplete = false;
            }
        }

        return !this.contactDataComplete;
    }

}

export class MoleculeWizardContactCompleteComponent implements ng.IComponentOptions {
    public bindings = {
        contactDataComplete: '=',
        selectedAccountId: '<'
    };
    public template = require('./wizard-contact-complete.html');
    public controller =  MoleculeWizardContactCompleteController;
}
