import ng from 'angular';

import { InputPlaceholderConst } from '@/configuration';
import { AuthContextService, UserRobotService } from '@/services';

export class MoleculePanelSmsValidationController implements ng.IController {
    public static $inject: string[] = ['localInterval', 'userRobot'];

    public showPanelSmsValidation = false;

    public smsSendLoading = false;
    public smsSendCooldown = 0;
    public smsSendCounter = 0;

    public codeSendLoading = false;
    public codePositive = false;
    public phoneNumberPlaceholder: string = InputPlaceholderConst.contact.tel;
    public smsSendCooldownList: number[] = [30, 60, 300];

    public phoneNumberModel: string;
    public codeModel: string;
    public isWaitingForAccountCheck = true;
    public showPhoneModalModel = false;
    public showSmsCodeModalModel = false;

    constructor(
        private localInterval: ng.IIntervalService,
        private userRobot: UserRobotService
    ) {}

    public $onInit(): void {
        this.resetModels();

        if (AuthContextService.isDirectCustomer) {
            void this.userRobot.getAccountBasicData().then(
                (res) => {
                    // Hide panel when the account is already SMS-verified.
                    this.showPanelSmsValidation = !res.response.verifications.includes('sms');
                    this.isWaitingForAccountCheck = false;
                }
            );
        }
    }

    // Reset model-values.
    public resetModels = (): void => {
        this.phoneNumberModel = '';
        this.codeModel = '';
    };

    // Open phone modal and reset models from previous attempts.
    public openPhoneNumberModal = (): void => {
        this.resetModels();
        this.showSmsCodeModalModel = false;
        this.showPhoneModalModel = true;
    };

    public sendSmsAgain = (): void => {
        if ([undefined, null, ''].indexOf(this.phoneNumberModel) < 0) {
            this.sendSms();
        } else {
            this.openPhoneNumberModal();
        }
    };

    // Send a submit code to the entered phone number.
    public sendSms = (): void => {
        this.smsSendLoading = true;
        if (this.phoneNumberModel.length >= 8) {
            // Submit phone number.
            this.userRobot.accountStartSmsVerification(this.spaceFree(this.phoneNumberModel)).then(
                () => {
                    this.smsSendLoading = false;
                    this.smsSendCounter++;

                    if (this.smsSendCounter <= 3) {
                        this.smsSendCooldown = this.smsSendCooldownList[this.smsSendCounter - 1];
                    }

                    const intervalPromise = this.localInterval(
                        () => {
                            this.smsSendCooldown--;

                            if (this.smsSendCooldown === 0) {
                                this.localInterval.cancel(intervalPromise);
                            }
                        },
                        1000
                    );

                    this.openSmsCodeModal();
                },
                () => { this.smsSendLoading = false; }
            );
        }
    };

    // Send the verification code.
    public submitCode = (): void => {
        this.codeSendLoading = true;
        this.userRobot.accountFinishSmsVerification(this.codeModel).then(
            (res) => {
                this.codeSendLoading = false;

                if (res.status === 'success') {
                    this.codePositive = true;
                }
            },
            () => this.codeSendLoading = false
        );
    };

    /**
     * Remove whitespace from the phonenumber input.
     *
     * @return cleaned value
     */
    public spaceFree = (value: string): string => {
        if (value.length > 0) {
            return value.split('').filter((letter) => letter !== ' ').join('');
        }

        return value;
    };

    // Open SMS code modal.
    public openSmsCodeModal = (): void => {
        this.showPhoneModalModel = false;
        this.showSmsCodeModalModel = true;
    };

    public skipPhoneInput = (): void => {
        this.phoneNumberModel = '';
        this.openSmsCodeModal();
    };
}

export class MoleculePanelSmsValidationComponent implements ng.IComponentOptions {
    public controller = MoleculePanelSmsValidationController;
    public template = require('./panel-sms-verification.html'); // tslint:disable-line:max-line-length
}
