import * as ng from 'angular';

import { DirectDebit, UIRegExp } from '../../configuration';
import { Validator } from './abstract-validator';

export class ValidateNextcloudUser extends Validator {

    private validationErrors: null|any[];

    constructor(private $translate: ng.translate.ITranslateService, validationErrors?: any[]) {
        super();
        this.validationErrors = validationErrors;
    }

    public validate = (value: string) => {
        let errorList = [];

        if ([undefined, null].indexOf(this.validationErrors) < 0 && this.validationErrors.length > 0) {
            return this.validationErrors;
        }

        if ([undefined, null, ''].indexOf(value) >= 0) {
            return [{ text: this.$translate.instant('TR_090119-2705b2_TR') }];
        }

        // Check for illegal characters.
        value.split('').forEach(
            (valueChar) => {
                if (errorList.length <= 0 && !UIRegExp.IsValidNextcloudUsersCharacters.test(valueChar)) {
                    errorList = [{
                        text: this.$translate.instant(
                            /* translationId */ 'TR_300819-7cea68_TR',
                            { legalCharactersMessage: 'A-Za-z0-9_\\-.@' }
                        )
                    }];
                }
            }
        );

        // Check max. length.
        if (value.length > 64) {
            errorList.push({ text: this.$translate.instant('TR_300819-245710_TR') });
        }

        return errorList;
    };

    public updateValidatorErrors = (errors: any[]) => {
        this.validationErrors = errors;
    };
}
