import * as ng from 'angular';

export class TemplateDomainAuthInfoTwoController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData = {
        backwardLink: 'domain.dashboard',
        backwardText: this.$translate.instant('TR_100119-28bdd0_TR'),
        panelHeaderRoute: '',
        panelIcon: 'globe',
        panelTitle: this.$translate.instant('TR_100119-86d0fc_TR')
    };

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}
}

export class TemplateDomainAuthInfoTwoComponent implements ng.IComponentOptions {
    public template = require('./auth-info-two-template.html');
    public controller = TemplateDomainAuthInfoTwoController;
}
