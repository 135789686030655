import * as ng from 'angular';

export class StringReplace {
    public static $inject: string[] = ['sce'];

    public static Factory($sce: ng.ISCEService) {
        return (input: string, search: string, replace: string = '') => {
            input = input || '';
            search = search || '';
            replace = replace || '';
            return $sce.trustAsHtml(input.replace(new RegExp(search, 'g'), replace));
        };
    }
}
