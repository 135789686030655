import * as ng from 'angular';
import { OrganSslWizardProductConfigCertificateContactsController } from '.';
import { ViewTypes } from '../../../../../../types/view-types';

export class OrganSslWizardProductConfigCertificateContactTechController {
    public static $inject: string[] = [
        '$translate'
    ];

    public technicalContact: ViewTypes.SslContact<ViewTypes.TechnicalContactFields>;
    public metaData: ViewTypes.ProductConfigSslObject;
    public salutationsDropdown: { name: string; value: string }[] = [];

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.technicalContact.filledOut = this.filledOut;
    }

    public filledOut = () => {
        return OrganSslWizardProductConfigCertificateContactsController.filledOut(this.technicalContact);
    };
}

export class OrganSslWizardProductConfigCertificateContactTechComponent implements ng.IComponentOptions {
    public bindings = {
        technicalContact: '=',
        metaData: '<',
        salutationsDropdown: '<'
    };
    public template = require('./config-certificate-contact-tech.html');
    public controller = OrganSslWizardProductConfigCertificateContactTechController;
}
