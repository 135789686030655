import * as ng from 'angular';
import { EditPanelRight } from '@/atomic-components';
import { WebhostingApi } from '@/types';
import { AccountHelperService } from '@/services';
export class MoleculePanelRestoreController {
    public static $inject: string[] = [
        '$translate',
        'accountHelper',
        'localDateTimeFilter',
        'alertManager',
        'apiErrorModel',
        'navigation'
    ];

    public disableConfirmAction = false;
    public editFormOrganism: unknown;
    public panelRight: EditPanelRight;
    public restorableUntil: string;
    public restorableUntilLocalDate: string;
    public restoreUserActions: { label: string; value: string }[] = [];
    public userHasPurgeRights = true;
    public userHasRestoreRights = true;
    public selectedRestoreAction = '';

    private displayRestoreCostWarning = true;
    private _isPaidUntilExceeded: boolean;

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private accountHelper: AccountHelperService,
        private localDateTimeFilter: (apiDateTimeString: string, mode: string) => string,
    ) {}

    public $onInit = (): void => {
        if (this.userHasRestoreRights === true) {
            this.restoreUserActions.push({
                label: this.$translate.instant('TR_110119-a53724_TR'),
                value: 'restore'
            });
        }

        if (this.userHasPurgeRights === true) {
            this.restoreUserActions.push({
                label: this.$translate.instant('TR_110119-820c2b_TR'),
                value: 'purge'
            });
        }

        this.restorableUntilLocalDate = this.localDateTimeFilter(this.restorableUntil, 'dateTimeMax');
    };

    public get isRedirect(): boolean {
        // @ts-ignore: TS2339
        return (this.editFormOrganism?.defaultLocation as WebhostingApi.Location).locationType === 'redirect';
    }

    public get saveButtonDisabled(): boolean {
        return !this.userHasConfirmedRestoreAction || this.disableConfirmAction;
    }

    public get restoreCostHintViewable(): boolean {
        return this.userHasRestoreRights
            && this.userHasPurgeRights
            && this.displayRestoreCostWarning
            && this.isPaidUntilExceeded
            && !this.accountHelper.isRootAdmin();
    }

    public get userHasConfirmedRestoreAction(): boolean {
        // @ts-ignore: TS2339
        return [undefined, null, ''].indexOf(this.editFormOrganism.selectedRestoreAction) < 0;
    }

    public get isPaidUntilExceeded(): boolean {
        if (this._isPaidUntilExceeded === undefined) {
            return true;
        }

        return this._isPaidUntilExceeded;
    }

    public cancelRestore = (): void => {
        // @ts-ignore: TS2339
        this.editFormOrganism.selectedRestoreAction = '';
    };
}

export class MoleculePanelRestoreComponent implements ng.IComponentOptions {
    public transclude = true;

    public bindings = {
        _isPaidUntilExceeded: '<?isPaidUntilExceeded',
        disableConfirmAction: '=?',
        displayRestoreCostWarning: '<?',
        editFormOrganism: '=',
        panelRight: '<',
        restorableUntil: '<',
        userHasPurgeRights: '<?',
        userHasRestoreRights: '<?',
    };

    public controllerAs = '$editPanelOrgan';
    public template = require('./panel-restore.html');
    public controller = MoleculePanelRestoreController;
}
