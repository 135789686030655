import * as ng from 'angular';
import { DataObject, GeneralValidator, ValidateMax, ValidateMin, ValidateNumberStep } from '../../../../../services';
import { FormInputModelOptions } from '../../../../atoms/form-elements';
import { MoleculeFormEditController } from '../../../forms/form-edit/form-edit';

export class MoleculeInputNumberController implements ng.IController {
    public $editForm: MoleculeFormEditController;
    public callbackOnChange: (arg0: any) => any;
    public callbackOnChangeParams: any;
    public callbackOnBlur: (arg0: any) => any;
    public callbackOnBlurParams: any;
    public customCallbackOnEnter: () => {};
    public doNotSaveOnFormEnter: boolean;
    public disabled: boolean;
    public modelOptions: FormInputModelOptions;
    public min: number;
    public max: number;
    public placeholder: string;
    public step: number;
    public readonly: boolean;
    public registerToForm = true;
    public validationErrorList: string[] = [];
    public validationInstructions: DataObject[];
    public validationSuccessful: boolean;
    public model: string;

    private validator;
    private registrationIndex: number;

    constructor(private $translate: ng.translate.ITranslateService) {
        this.validator = new GeneralValidator(this.$translate);
    }

    public $onInit() {
        this.disabled = this.disabled || false;
        this.readonly = this.readonly || false;

        if (this.validationInstructions === undefined) {
            this.validationInstructions = [];
        }

        if (this.step !== undefined) {
            this.validationInstructions.push({
                instructions: null,
                validator: new ValidateNumberStep(this.$translate, this.step)
            });
        }

        if (this.min !== undefined) {
            this.validationInstructions.push({
                instructions: null,
                validator: new ValidateMin(this.$translate, this.min)
            });
        }

        if (this.max !== undefined) {
            this.validationInstructions.push({
                instructions: null,
                validator: new ValidateMax(this.$translate, this.max)
            });
        }

        if (this.registerToForm || this.registerToForm == null || this.registerToForm === undefined) {
            this.registerToForm = true;
            this.registrationIndex = this.$editForm.registerValidator(this);
        }
    }

    public $onDestroy() {
        if (this.registerToForm) {
            this.$editForm.unregisterValidator(this.registrationIndex);
            this.$editForm.validatorStatus[this.registrationIndex] = true;
        }
    }

    public $onChanges(changes) {
        if (changes.disabled != null && changes.disabled !== undefined) {
            if (changes.disabled.currentValue === true) {
                this.$editForm.validatorStatus[this.registrationIndex] = true;
                this.validationErrorList = [];
            }
        }
    }

    public callbackOnInputBlur = (callbackOnBlurParams: unknown): void => {
        if (this.registerToForm) {
            this.$editForm.validate(this.registrationIndex);
        } else {
            this.validate();
        }

        if (this.callbackOnBlur !== undefined) {
            this.callbackOnBlur(callbackOnBlurParams);
        }
    };

    public validate = () => {
        let model = '';

        if (this.model) {
            model = this.model;
        }

        if (this.validationInstructions && !this.disabled) {
            this.validationErrorList = this.validator.validate(model, this.validationInstructions);
            this.validationSuccessful = (this.validationErrorList.length === 0);
            return this.validationSuccessful;
        } else {
            return true;
        }
    };

    public showValidationErrors = () => {
        return this.validationErrorList.length > 0;
    };

    public enterKeyUp = () => {
        if (this.customCallbackOnEnter !== undefined) {
            this.customCallbackOnEnter();
            return;
        }

        this.$editForm.save();
    };
}

export class MoleculeInputNumberComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnBlur: '<',
        callbackOnBlurParams: '<',
        callbackOnChange: '<',
        callbackOnChangeParams: '<',
        customCallbackOnEnter: '<',
        disabled: '<',
        doNotSaveOnFormEnter: '<',
        max: '<?',
        min: '<?',
        model: '=',
        modelOptions: '<',
        placeholder: '@?',
        readonly: '<',
        registerToForm: '<',
        step: '<?',
        validationErrorList: '=?',
        validationInstructions: '<'
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public controller = MoleculeInputNumberController;
    public controllerAs = '$input';
    public template = require('./input-number.html');
}
