import * as ng from 'angular';

export class OrganDashboardWidgetIncompleteUserProfileController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public constructor(
        private $translate: ng.translate.ITranslateService
    ) {}
}

export class OrganDashboardWidgetIncompleteUserProfileComponent implements ng.IComponentOptions {
    public bindings = {};

    public template = require('./dashboard-widget-incomplete-user-profile.html');
    public controller = OrganDashboardWidgetIncompleteUserProfileController;
}
