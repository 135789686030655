import * as ng from 'angular';
import { OrganismOverviewController, OverviewDataObject } from '../../../../organisms';

export class MoleculeOverviewRowPanelStorageController implements ng.IController {
    public static $inject: string[] = ['$state'];

    public overviewData: OverviewDataObject;
    public OrganismOverview: OrganismOverviewController;
    public overviewLoad: (selection: any) => any;
    public internalPaginationLimit: number;
    public editStorageProductRoute: string;

    constructor(
        private $state: ng.ui.IStateService
    ) {}

    public $onInit(): void {
        if (this.$state.current.name === 'storage.storage-products.overview') {
            this.editStorageProductRoute = 'storage.storage-products.id.edit';
        } else {
            this.editStorageProductRoute = 'nextcloud.id.edit';
        }
    }

    public get showAccounts() {
        return this.OrganismOverview.globals.showAccounts;
    }
}

export class MoleculeOverviewRowPanelStorageComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelStorageController;
    public controllerAs = 'OverviewStorageCtrl';
    public template = require('./storage-row.html');
}
