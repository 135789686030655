import * as ng from 'angular';
import { NavigationService } from '../../../../../services';

import './api-key-new-generated.scss';

export class OrganViewPanelApiKeyGeneratedController {
    public static $inject: string[] = ['navigation'];

    public apiKey: any;
    public resetFormCallback: () => {};
    public routeOnConfirm: string;
    public routeOnConfirmParams: {} = {};

    constructor(
        private navigation: NavigationService
    ) {}

    public newApiKey() {
        if (this.resetFormCallback !== undefined) {
            this.resetFormCallback();
        }
    }

    public apiKeyOverview() {
        this.navigation.go(this.routeOnConfirm, this.routeOnConfirmParams);
    }
}

export class OrganViewPanelApiKeyGeneratedComponent implements ng.IComponentOptions {
    public bindings = {
        apiKey: '<',
        resetFormCallback: '<',
        routeOnConfirm: '@',
        routeOnConfirmParams: '<'
    };
    public controller = OrganViewPanelApiKeyGeneratedController;
    public template = require('./api-key-new-generated.html');
}
