import * as ng from 'angular';
import { OrganEditPanelNextcloudGroupsController } from '@/atomic-components';
import {
    MoleculePanelEditTableController
} from '@/atomic-components/molecules/panels/panel-edit/table/panel-edit-table';
import {
    OrganismEditFormNextcloudController
} from '@/atomic-components/organisms/forms/edit-forms/nextcloud-edit/nextcloud-edit';
import { ManagedApplicationRobotService } from '@/services';
import { Finding, ManagedApplicationApi, UI } from '@/types';
import NextcloudUserGroup = ManagedApplicationApi.NextcloudUserGroup;

export class MoleculePanelEditRowTableNextcloudGroupsController implements ng.IController {
    public static $inject: string[] = ['$timeout', 'managedApplicationRobot'];

    public $editFormOrganism: OrganismEditFormNextcloudController;
    public $editPanelOrgan: OrganEditPanelNextcloudGroupsController;
    public $organismEditForm: OrganismEditFormNextcloudController;
    public $panelEditTable: MoleculePanelEditTableController;
    public displayGroupNameAlreadyTakenError = false;
    public groupBeingEdited: boolean|number = false;
    public hideUserDropdown = false;
    public nextcloudUserCache: ManagedApplicationApi.NextcloudUser[] = [];
    public nextcloudUsersToAdd: string[] = [];

    constructor(
        private $timeout: ng.ITimeoutService,
        private managedApplicationRobot: ManagedApplicationRobotService
    ) {}

    public cancelGroupEdit = (index: number): void => {
        this.groupBeingEdited = false;

        if (this.$editPanelOrgan.groups[index].props.isNewGroup) {
            this.$editPanelOrgan.groups.splice(index, 1);
        } else {
            this.$editPanelOrgan.availableUsers = ng.copy(
                this.$editPanelOrgan.groups[index].props.initialState.userList
            );
            this.$editPanelOrgan.groups[index] = ng.copy(
                this.$editPanelOrgan.groups[index].props.initialState.currentGroup
            );
        }
    };

    public editGroup = (index: number): void => {
        this.groupBeingEdited = index;
        this.nextcloudUsersToAdd[index] = null;
        this.$editPanelOrgan.groups[index].props.initialState = undefined;
        this.$editPanelOrgan.groups[index].props.initialState = {
            currentGroup: ng.copy(this.$editPanelOrgan.groups[index]),
            userList: ng.copy(this.$editPanelOrgan.availableUsers)
        };
        this.$editPanelOrgan.groups[index].props.isBeingEdited = true;
    };

    public toggleGroupDelete = (group: NextcloudUserGroup): void => {
        group.props.isMarkedForRemoval = !group.props.isMarkedForRemoval;

        if (group.props.isMarkedForRemoval) {
            this.$editFormOrganism.groupsToDelete.push(group.name);
        } else {
            this.$editFormOrganism.groupsToDelete.splice(
                this.$editFormOrganism.groupsToDelete.indexOf(group.name),
                1
            );
        }
    };

    public commitGroupChange = (groupData: { index: number; group: NextcloudUserGroup }): void => {
        const group = groupData.group;

        group.props.displayEmptyGroupsError = group.members.length === 0
            && [null, undefined].indexOf(this.nextcloudUsersToAdd[groupData.index]) >= 0;

        if (group.props.displayEmptyGroupsError || this.displayGroupNameAlreadyTakenError || group.name.length === 0) {
            return;
        }

        if ([null, undefined].indexOf(this.nextcloudUsersToAdd[groupData.index]) < 0) {
            this.addUserToGroup(groupData.index);
        }

        if (group.props.isNewGroup) {
            group.props.isNewGroup = false;
        }

        this.$editFormOrganism.nextcloudUsersGroups = this.$editPanelOrgan.availableUsers
            .filter((nextcloudUser) => nextcloudUser.props?.isBeingEdited === true);

        group.props.hasBeenChanged = true;
        group.props.isBeingEdited = false;
        this.groupBeingEdited = false;
    };

    // Returns true if at least one group exists.
    public get groupsExist(): boolean {
        return this.$editPanelOrgan?.groups?.length !== 0;
    }

    public removeUserFromGroup = (ncUser: ManagedApplicationApi.NextcloudUser): void => {
        this.hideUserDropdown = true;

        const targetUser = this.$editPanelOrgan.availableUsers.filter(
            (user) => user.username === ncUser.username
        );
        const groupName = this.$editPanelOrgan.groups[(this.groupBeingEdited as number)].name;
        targetUser[0].groups = targetUser[0].groups.filter(
            (existingGroupName) => existingGroupName !== groupName
        );
        targetUser[0].props = {
            isBeingEdited: true
        };
        this.$editPanelOrgan.groups[(this.groupBeingEdited as number)].members = this.$editPanelOrgan
            .groups[(this.groupBeingEdited as number)].members
            .filter(
                (member) => member.username !== ncUser.username
            );

        void this.$timeout(() => {
            this.hideUserDropdown = false;
        });
    };

    public addUserToGroup = (index: number): void => {
        if (
            !this.$editPanelOrgan.groups[index].members.some(
                (ncUser: ManagedApplicationApi.NextcloudUser) => ncUser.username === this.nextcloudUsersToAdd[index]
            )
        ) {
            const newUser = this.$editPanelOrgan.availableUsers.filter(
                (user: ManagedApplicationApi.NextcloudUser) => {
                    return user.username === this.nextcloudUsersToAdd[index];
                }
            );
            newUser[0].props = {
                isBeingEdited: true
            };
            if (
                newUser[0].groups
                    .filter((groupName: string) => groupName === this.$editPanelOrgan.groups[index].name)
                    .length === 0
            ) {
                newUser[0].groups.unshift(this.$editPanelOrgan.groups[index].name);
            }
            this.$editPanelOrgan.groups[index].members.unshift(newUser[0]);
        }
        this.nextcloudUsersToAdd[index] = null;
    };

    public userDisplayFilter = (ncUser: ManagedApplicationApi.NextcloudUser): string => {
        return ncUser.name + ' (' + ncUser.username + ')';
    };

    public listCallback = (
        limit?: number,
        page?: number,
        filters?: Finding.Filter,
        sort?: Finding.SortOptions
    ): PromiseLike<ManagedApplicationApi.FindNextcloudUsersResult> => {
        const tmpFilters = {
            subFilter: [
                {
                    field: 'NextcloudId',
                    value: this.$organismEditForm.nextcloud.id,
                    relation: 'equal'
                }
            ],
            subFilterConnective: 'AND'
        };

        if (Number.isInteger(this.groupBeingEdited)) {
            for (const existingUser of this.$editPanelOrgan.groups[(this.groupBeingEdited as number)].members) {
                tmpFilters.subFilter.push(
                    {
                        field: 'NextcloudUserUsername',
                        value: existingUser.username,
                        relation: 'unequal'
                    }
                );
            }
        }

        if ([undefined, null].indexOf(filters) < 0) {
            tmpFilters.subFilter.push(filters as { field: string; value: string; relation: string });
        }

        return this.managedApplicationRobot.nextcloudUsersFind(
            tmpFilters,
            limit,
            page,
            sort ?? {
                field: 'NextcloudUserName',
                order: 'ASC'
            }
        ).then((apiResponse: UI.SynchronousAPIResponse<ManagedApplicationApi.FindNextcloudUsersResult>) => {
            for (const ncUser of apiResponse.response.data) {
                if (!this.$editPanelOrgan.availableUsers.some((user) => user.username === ncUser.username)) {
                    this.$editPanelOrgan.availableUsers.push(ncUser);
                }
            }

            return apiResponse.response;
        });
    };

    public checkUniqueGroupName = (groupName: { value: string } | string): void => {
        const userInput = (groupName as { value: string }).value === undefined
            ? groupName
            : (groupName as { value: string }).value;

        this.displayGroupNameAlreadyTakenError = this.$editPanelOrgan.groups.filter(
            (group) => group.name === userInput
        ).length > 1;
    };
}

export class MoleculePanelEditRowTableNextcloudGroupsComponent implements ng.IComponentOptions {
    public require = {
        $editFormOrganism: '^organismEditFormNextcloud',
        $editPanelOrgan: '^organEditPanelNextcloudGroups',
        $organismEditForm: '^organismEditFormNextcloud',
        $panelEditTable: '^moleculePanelEditTable'
    };

    public bindings = {
        groupBeingEdited: '='
    };

    public controller = MoleculePanelEditRowTableNextcloudGroupsController;
    public controllerAs = '$panelEditRowTable';
    public template = require('./nextcloud-groups.html');
}
