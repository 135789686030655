import ng from 'angular';
import { UiRights } from '@/configuration';
import { Breadcrumb, BreadcrumbItem } from '../breadcrumb';
import { BreadcrumbMailboxTopicModel, BreadcrumbSslTopicModel, BreadcrumbWebhostingTopicModel } from './';
import { BreadcrumbTopicModel, TopicCases } from './topic-model';

export class BreadcrumbBundleTopicModel extends BreadcrumbTopicModel {
    public static $inject: string[] = BreadcrumbTopicModel.$inject.concat([
        'breadcrumbMailboxTopicModel',
        'breadcrumbSslTopicModel',
        'breadcrumbWebhostingTopicModel'
    ]);
    public readonly topicName = 'bundle';
    public readonly specialTopicCases: TopicCases;

    /**
     *   List of route parts, which are excluded from mail class generalRoutes methode
     */
    public readonly excludeGeneralRouteParts = ['edit', 'overview'];
    private actionBundleDropdownItems: BreadcrumbItem[] = [];

    constructor(
        protected $state: ng.ui.IStateService,
        protected $translate: ng.translate.ITranslateService,
        private breadcrumbMailboxTopicModel: BreadcrumbMailboxTopicModel,
        private breadcrumbSslTopicModel: BreadcrumbSslTopicModel,
        private breadcrumbWebhostingTopicModel: BreadcrumbWebhostingTopicModel
    ) {
        super($state, $translate);

        this.actionBundleDropdownItems = [
            {
                isGranted: UiRights.WEB_VHOST_EDIT,
                name: this.$translate.instant('GENERAL.ACTION.EDIT'),
                part: 'edit',
                route: 'bundle.id.domains.id.edit'
            },
            {
                isGranted: UiRights.WEB_VHOST_EDIT,
                name: this.$translate.instant('TR_090119-4e1393_TR'),
                part: 'app-install',
                route: 'bundle.id.domains.id.app-install'
            },
            {
                isGranted: UiRights.WEB_VHOST_EDIT,
                name: this.$translate.instant('TR_080119-dd59e7_TR'),
                part: 'locations-edit',
                route: 'bundle.id.domains.id.locations-edit'
            },
            {
                isGranted: UiRights.WEB_VHOST_EDIT,
                name: this.$translate.instant('TR_100419-cc6ac5_TR'),
                part: 'directory-protection',
                route: 'bundle.id.domains.id.directory-protection'
            }
        ];

        this.specialTopicCases = {
            'admin-options': this.getAdminOptionsRoute,
            'app-install': this.getActionDropdown,
            'backups': this.getBackupServiceRoute,
            'cronjobs': this.getCronjobServiceRoute,
            'databases': this.getDatabaseServiceRoutes,
            'directory-protection': this.getActionDropdown,
            'domains': this.getDomainServiceRoute,
            'domainsettings': this.breadcrumbMailboxTopicModel.getDomainSettingRoute,
            'edit': this.getActionDropdown,
            'ftp-ssh': this.breadcrumbWebhostingTopicModel.getFtpSshServiceRoute,
            'id': this._getIdRoute,
            'locations-edit': this.getActionDropdown,
            'mailboxes': this.getMailboxServiceRoutes,
            'ssl': this.breadcrumbSslTopicModel.getSslAction,
            'storage': this.getStorageServiceRoute,
            'transfer-out': this.getTransferOutRoute,
            'orderId': this.getOrderIdRoute,
        };
    }

    public getFirstLevelData = (): Breadcrumb[] => {
        this.breadcrumbs.push({
            active: this.index === this.steps.length - 2,
            route: 'bundle.dashboard',
            title: this.$translate.instant('TR_080119-5c3499_TR'),
            type: 'text',
            value: this.$translate.instant('TR_090119-ecb4b9_TR')
        });

        return this.breadcrumbs;
    };

    public getActionDropdown = (): Breadcrumb[] => {
        switch (this.steps[this.index - 2]) {
            case 'domains':
            case 'app-install':
            case 'directory-protection':
            case 'location-edit':
                return this.getBundleActionDropdown(this.step);
            case 'databases':
            case 'mailboxes':
            case 'cronjobs':
                return this.getEditRoute();
            default:
                return [];
        }
    };

    public getOrderIdRoute = (): Breadcrumb[] => {
        return [
            {
                active: true,
                route: 'bundle.setup',
                routeParams: { orderId: this.globals.orderId },
                type: 'text',
                value: this.$translate.instant('18249206-2af0-4e97-abe6-51184efa0ce2'),
            }
        ];
    }

    public _getIdRoute = (): Breadcrumb[] => {
        // show product name only on second level
        if (this.index > 2) {
            return;
        }

        return [
            {
                active: this.steps.length === 3,
                route: 'bundle.id.dashboard',
                routeParams: { bundleId: this.globals.bundle.id },
                type: 'text',
                value: this.globals.bundle.name
            }
        ];
    };

    public getAdminOptionsRoute = (): Breadcrumb[] => [
        {
            active: true,
            isGranted: UiRights.WEB_OBJECT_LIST,
            route: 'bundle.id.admin-options',
            routeParams: { webspaceId: this.globals.webspace.id, bundleId: this.globals.bundle.id },
            type: 'text',
            value: this.$translate.instant('TR_090119-9c9bb6_TR')
        }
    ];

    public getBundleActionDropdown = (step: string): Breadcrumb[] => {
        let activeItem: BreadcrumbItem = {
            isGranted: undefined,
            isGrantedAny: undefined,
            name: '',
            route: '',
            routeParams: {}
        };

        let domainId: string;
        let vhost: string;
        let onlyEditAction = false;
        if ([undefined, null].indexOf(this.globals.vHost) === -1) {
            domainId = this.globals.vHost.id;
            vhost = 'vhost';
        } else {
            domainId = this.globals.domain.id;
            vhost = 'domain';
            // disable app-install and location
            onlyEditAction = true;
        }
        const routeParams = { bundleId: this.globals.bundle.id, domainId: domainId, vhost: vhost };

        // we can not use getProductActionDropdown method at this point
        // - because if vhost is not configurated app install and location edit is not possible and must disabled!
        const actionDropdownItems = this.actionBundleDropdownItems.map((actionItem) => {
            if (actionItem.part === step) {
                activeItem = {
                    isGranted: actionItem.isGranted,
                    isGrantedAny: actionItem.isGrantedAny,
                    name: actionItem.name,
                    route: actionItem.route,
                    routeParams: {}
                };
            }
            return {
                active: step === actionItem.part,
                disabled: onlyEditAction,
                isGranted: actionItem.isGranted,
                isGrantedAny: actionItem.isGrantedAny,
                name: actionItem.name,
                route: actionItem.route,
                routeParams: routeParams,
                title: ''
            };
        });

        this.breadcrumbs = [
            {
                active: this.isActive(step),
                isGranted: activeItem.isGranted,
                isGrantedAny: activeItem.isGrantedAny,
                items: actionDropdownItems,
                route: this.isActive(step) ? '' : activeItem.route,
                routeParams: this.isActive(step) ? {} : activeItem.routeParams,
                type: 'dropdown',
                value: activeItem.name
            }
        ];

        return this.breadcrumbs;
    };

    public getTransferOutRoute = (): Breadcrumb[] => {
        this.breadcrumbs = [
            {
                active: true,
                type: 'text',
                value: this.$translate.instant('TR_090119-86f4b3_TR')
            }
        ];

        return this.breadcrumbs;
    };

    public getDomainServiceRoute = (): Breadcrumb[] =>
        this.getServiceListDropdownBox({
            active: this.step === 'domains',
            isGranted: UiRights.DOM_DOMAINS_LIST,
            name: this.$translate.instant('TR_090119-5ebc34_TR'),
            route: 'bundle.id.domains.overview',
            routeParams: { bundleId: this.globals.bundle.id },
            step: 'domains'
        });

    public getDatabaseServiceRoutes = (): Breadcrumb[] =>
        this.getServiceListDropdownBox({
            active: this.step === 'databases',
            isGranted: UiRights.DB_DATABASE_LIST,
            name: this.$translate.instant('TR_090119-f0ccfb_TR'),
            route: 'bundle.id.databases.overview',
            routeParams: { bundleId: this.globals.bundle.id },
            step: 'databases'
        });

    public getMailboxServiceRoutes = (): Breadcrumb[] =>
        this.getServiceListDropdownBox({
            active: this.step === 'mailboxes',
            isGranted: UiRights.EMAIL_MAILBOX_LIST,
            name: this.$translate.instant('TR_080119-8dd4a1_TR'),
            route: 'bundle.id.mailboxes.overview',
            routeParams: { bundleId: this.globals.bundle.id },
            step: 'mailboxes'
        });

    public getStorageServiceRoute = (): Breadcrumb[] =>
        this.getServiceListDropdownBox({
            active: this.step === 'storage',
            isGranted: UiRights.WEB_WEBSPACE_EDIT,
            name: this.$translate.instant('TR_STORAGE_TR'),
            route: 'bundle.id.webspaces.id.storage',
            routeParams: { bundleId: this.globals.bundle.id, webspaceId: this.globals.webspace.id },
            step: 'storage'
        });

    public getBackupServiceRoute = (): Breadcrumb[] =>
        this.getServiceListDropdownBox({
            active: this.step === 'backups',
            isGranted: UiRights.BACKUP_BACKUP_LIST,
            name: this.$translate.instant('TR_080119-57a2c9_TR'),
            route: 'bundle.id.backups.overview',
            routeParams: { bundleId: this.globals.bundle.id },
            step: 'backups'
        });

    public getCronjobServiceRoute = (): Breadcrumb[] =>
        this.getServiceListDropdownBox({
            active: this.step === 'cronjobs',
            name: this.$translate.instant('e2a342f1-432b-41d5-b5de-68e07820d023'),
            route: 'bundle.id.cronjobs.overview',
            routeParams: { bundleId: this.globals.bundle.id },
            step: 'cronjobs'
        });
}
