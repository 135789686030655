import * as ng from 'angular';
import {
    AlertManagerService,
    ApiErrorModel,
    NavigationService,
    VhostModelService
} from '../../../../../../../services';

export class OrganismEditFormWebspaceVhostSslController {
    public static $inject: string[] = [
        '$q',
        '$state',
        '$translate',
        'alertManager',
        'apiErrorModel',
        'navigation',
        'vhostModel'
    ];

    public vHost: any;
    public ssl = {
        certificateList: [],
        sslPrivateKey: ''
    };
    public userPanelRight: any;

    private phpIni: any;
    private backRoute: { route: string; routeParams: {} };

    constructor(
        private $q: ng.IQService,
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private navigation: NavigationService,
        private vhostModel: VhostModelService
    ) {}

    public save = () => {
        this.apiErrorModel.destroyErrorList();

        this.vHost.sslSettings.certificates = this.ssl.certificateList
            .map((certificate) => certificate.certificate)
            .join('\n');

        // If the hstsMaxAge is passed as a string, convert it to a number. Keep null.
        if (typeof this.vHost.sslSettings.hstsMaxAge === 'string') {
            this.vHost.sslSettings.hstsMaxAge = +this.vHost.sslSettings.hstsMaxAge;
        }

        // If sslSettings.managedSslProductCode is set, reset data
        if ([undefined, null, ''].indexOf(this.vHost.sslSettings?.managedSslProductCode) < 0) {
            this.vHost.sslSettings.managedSslProductCode = '';
        }

        return this.vhostModel.update(this.vHost, this.phpIni, this.ssl.sslPrivateKey, null).then(
            (res) => {
                if (!this.apiErrorModel.apiResponseHasError(res)) {
                    this.alertManager.success(this.$translate.instant('TR_110119-a3a25a_TR'));

                    // Go back to edit-page.
                    const currentRoute = this.$state.current.name;
                    const targetBackRoute = currentRoute.split('.').slice(0, -1).join('.') + '.edit';
                    this.navigation.go(targetBackRoute);
                }
                return this.$q.reject(false);
            },
            (err) => {
                return this.$q.reject(err);
            });
    };

    public cancel = () => {
        this.navigation.reloadCurrentState();
    };

    public removeCertificate = (index) => {
        this.ssl.certificateList = this.ssl.certificateList.filter((c, i) => {
            return index !== i;
        });
    };

    public addCertificate = () => {
        this.ssl.certificateList.push({
            additionalInfo: [],
            certificate: '',
            modified: true,
            valid: true
        });
    };

    public get errorList() {
        return this.apiErrorModel.errorObjectList;
    }

    public separateSSLCertificate = () => {
        let apiCertificates = this.vHost.sslSettings.certificates
            .split('-----END CERTIFICATE-----')
            .filter((a) => [undefined, null, ''].indexOf(a) < 0)
            .map((a) =>  `${a}-----END CERTIFICATE-----`)
            .map((certificate) => {
                const ret = {
                    additionalInfo: [],
                    certificate: certificate,
                    modified: false
                };

                const startCert = certificate.indexOf('-----BEGIN CERTIFICATE-----');
                if (startCert >= 0) {
                    ret.additionalInfo = this.extractCertInfo(certificate.substr(0, startCert));
                    ret.certificate = certificate.substr(startCert);
                }

                return ret;
            });

        if ([undefined, null].indexOf(apiCertificates) >= 0 || apiCertificates.length < 1) {
            apiCertificates = [{
                additionalInfo: [],
                certificate: ''
            }];
        }

        this.ssl.certificateList = apiCertificates;
    };

    private extractCertInfo = (info) => {
        const separated = info.split('\n');
        const infoList = [];

        separated.forEach((elm) => {
            if (elm !== '') {
                const z = elm.split(': ');

                if (z.length === 2) {
                    const key = z[0];
                    const value = z[1];

                    infoList.push({key: key, value: value});
                }
            }
        });

        return infoList;
    };
}

export class OrganismEditFormWebspaceVhostSslComponent implements ng.IComponentOptions {
    public bindings = {
        backRoute: '<',
        phpIni: '<',
        userPanelRight: '<',
        vHost: '<'
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormWebspaceVhostSslController;
    public template = require('./webspace-edit-vhost-ssl.html');
}
