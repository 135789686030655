import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateDnsJobDetailsController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;
    public job: any;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'dns.zones.jobs',
            backwardText: this.$translate.instant('TR_210219-a77bd9_TR'),
            panelHeaderRoute: '',
            panelIcon: 'location-arrow',
            panelTitle: this.$translate.instant('TR_210219-fe3a28_TR', { jobName: this.job.displayName })
        };
    };
}

export class TemplateDnsJobDetailsComponent implements ng.IComponentOptions {
    public bindings = {
        job: '<'
    };
    public controller = TemplateDnsJobDetailsController;
    public template = require('./dns-job-details-template.html');
}
