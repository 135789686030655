import { ModelHelper } from '../model-helper';
import { EmailRobotService } from './robot';

import * as Types from '../../types';

export class EmailJobModelService {
    public static $inject: string[] = ['emailRobot'];

    constructor(
        private emailRobot: EmailRobotService
    ) {}

    public list = (limit, page, filters, sort) => {
        sort = sort || {
            field: 'JobAddDate',
            order: 'ASC'
        };
        page = page || 1;

        return this.emailRobot.listJobs(filters, limit, page, sort)
            .then(ModelHelper.returnListResponse);
    };

    public findRunningJobsForMailbox = (mailbox: Types.EmailApi.Mailbox) => {
        const filter = {
            subFilter: [
                { field: 'jobObjectId', value: mailbox.id },
                { field: 'jobStatus', relation: 'unequal', value: 'successful' },
                { field: 'jobStatus', relation: 'unequal', value: 'failed' },
                { field: 'jobStatus', relation: 'unequal', value: 'canceled' }
            ],
            subFilterConnective: 'AND'
        };

        return this.emailRobot.listJobs(filter, 25, 1, null);
    };

    public findOne = (id: string) => {
        return this.emailRobot.listJobs({ field: 'jobId', value: id }, 1, 1)
            .then(ModelHelper.returnFindOneResponse)
            .then(
                (job) => {
                    // @todo API Workaround
                    job.errors = this.parseJobError(job.errors);

                    return job;
                }
            );
    };

    private parseJobError = (error) => {
        if (error === '' || !error) {
            return [];
        }

        return JSON.parse(error);
    };
}
