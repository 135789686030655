import * as ng from 'angular';
import { AlertManagerService } from '../../../../../../services';
import { OrganismOverviewController } from '../../../../../organisms/overview/overview';

export class MoleculePanelOverviewHeaderMultiDeactivateActionController implements ng.IController {
    public static $inject: string[] = ['alertManager', '$translate'];

    public OrganismOverview: OrganismOverviewController;
    public confirmHint = false;
    private serviceName: string;

    constructor(
        private alertManager: AlertManagerService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.getTemplate();
    }

    public abort = () => {
        this.OrganismOverview.overviewModel.activeMassAction = false;
    };

    public deactivate = () => {
        this.OrganismOverview.submitMassAction({methode: 'deactivateSubmit'})
            .then((result) => {
                if (result) {
                    this.alertManager.success(this.$translate.instant('TR_100119-139c51_TR'));
                    this.abort();
                    this.OrganismOverview.onLoad();
                }
            }, (e) => {
                console.error('error', e);
            });
    };

    // tslint:disable-next-line:max-line-length
    public getTemplate = () => require('./multi-deactivate-' + this.serviceName + '-action.html?external');
}

export class MoleculePanelOverviewHeaderMultiDeactivateActionComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        serviceName: '<'
    };
    public controller =  MoleculePanelOverviewHeaderMultiDeactivateActionController;
    public template = require('./multi-deactivate-action.html');
}
