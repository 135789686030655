import * as ng from 'angular';
import { DateWrapper } from '../../../../../services';
import './date-range-select.scss';

export class MoleculeFormDateRangeSelectController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public startDate: DateWrapper;
    public endDate: DateWrapper;
    public minYear: string;
    public maxYear: string;
    public onChangeCallback: (arg0: Date) => any;
    public callbackOnlyOnCompleteDate: boolean;
    public buttonModifier: string;
    public label: string;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public get startDateDisplay() {
        if (
            [undefined, null].indexOf(this.startDate) >= 0
            || [undefined, null].indexOf(this.startDate.day) >= 0
            || [undefined, null].indexOf(this.startDate.month) >= 0
            || [undefined, null].indexOf(this.startDate.year) >= 0
        ) {
            return this.$translate.instant('TR_150119-9129d1_TR');
        }
        return this.startDate.day + '.' + this.startDate.month + '.' + this.startDate.year;
    }

    public get endDateDisplay() {
        if (
            [undefined, null].indexOf(this.endDate) >= 0
            || [undefined, null].indexOf(this.endDate.day) >= 0
            || [undefined, null].indexOf(this.endDate.month) >= 0
            || [undefined, null].indexOf(this.endDate.year) >= 0
        ) {
            return this.$translate.instant('TR_150119-9129d1_TR');
        }
        return this.endDate.day + '.' + this.endDate.month + '.' + this.endDate.year;
    }
}

export class MoleculeFormDateRangeSelectComponent implements ng.IComponentOptions {
    public bindings = {
        buttonModifier: '@',
        callbackOnlyOnCompleteDate: '<',
        endDate: '=',
        label: '@',
        maxYear: '@',
        minYear: '@',
        onChangeCallback: '<',
        startDate: '='
    };
    public require = {
        $formEdit: '?^moleculeFormEdit'
    };
    public controller =  MoleculeFormDateRangeSelectController;
    public template = require('./date-range-select.html');
}
