import {
    TemplateBillingPricelistAbstractOverviewComponent,
    TemplateBillingPricelistAbstractOverviewController
} from '../abstract/pricelist-abstract-template';

export class TemplateBillingPricelistStorageProductOverviewController
    extends TemplateBillingPricelistAbstractOverviewController {
    protected options = {
        panelTitleTranslationId: /* translationId */ 'TR_140519-4f0af6_TR',
        simpleFilterField: 'productName'
    };
    public disableFilter = true;
}

export class TemplateBillingPricelistStorageProductOverviewComponent
    extends TemplateBillingPricelistAbstractOverviewComponent {
    public controller = TemplateBillingPricelistStorageProductOverviewController;
    public template = require('./pricelist-storage-product-template.html');
}
