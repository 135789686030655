import ng from 'angular';

import { AccessLevels, WizardObject, FormDropDownItems } from '@/atomic-components/molecules';
import { WebspaceAccessPasswordCharacterPoolsConst } from '@/configuration';
import { AuthContextService, WebhostingUserModelService } from '@/services';
import * as Types from '@/types';

export interface WebspaceUserObject {
    accountId: string;
    name: string;
    sshKey: string;
    comments: string;
    id?: string;
    userName?: string;
    password?: string;
    status?: string;
    addDate?: string;
    lastChangeDate?: string;
}

export interface WebspaceAccessObject {
    webspaceId: string;
    userId: string;
    ftpAccess: boolean;
    sshAccess: boolean;
    statsAccess: boolean;
    ftpLimited?: boolean;
    name?: string;
    userName?: string;
    comments?: string;
    homeDir?: string;
    addDate?: string;
    lastChangeDate?: string;
}

export class MoleculeRowWebspaceAccessController {

    public get accessHomeDir() {
        return this.newAccessObject.homeDir;
    }

    public set accessHomeDir (value: string) {
        if (value !== this.newAccessObject.homeDir) {
            this.newAccessObject.homeDir = value;
            if (this.userHomeDirChangedCallback) {
                this.userHomeDirChangedCallback(value);
            }
        }
    }
    public static $inject: string[] = ['$timeout', 'authContext', 'webhostingUserModel', '$translate'];

    public newWebspaceUserObject: WebspaceUserObject;
    public newAccessObject: WebspaceAccessObject;
    public accessLevel: AccessLevels;
    public userAssignment = false;
    public removeFromList: any[] = [];
    public accessLevels: any[] = [];
    public disabledAccessLevels: any[] = [];

    public accessHeadings: string[] = [];
    public accessTypeButtons: any[] = [];
    public availableAccesses: any[];
    public accessSelectItems: any[];
    public accessType: string;
    public onlyNewAccessAvailable = false;
    public serviceObject: WizardObject;
    public rightsChangedCallback: (value: boolean, field: string) => void;
    public userAccessTypeChangeCallback: (accessType: string) => void;
    public usernameChangedCallback: (value: string) => void;
    public userPasswordChangedCallback: (value: string) => void;
    public userCommentChangedCallback: (comment: string) => void;
    public selectExistingUserChangeCallback: (choosenAccessid: string) => void;
    public sshKeyChangedCallback: (sshKey: string) => void;
    public userHomeDirChangedCallback: (homeDir: string) => void;
    public passwordValidationOptions = {
        characterPools: WebspaceAccessPasswordCharacterPoolsConst,
        maxLength: 128,
        minCharacterPoolsUsed: 3
    };

    public homedirPaths: FormDropDownItems[] = [
        { value: '/html/', name: '/html/' },
        { value: '/data/', name: '/data/' }
    ];

    public choosenAccessId: string;

    private lastClickedCheckbox: { value: boolean; field: string };
    private accountId: string;
    private webspaceId: string;
    private isValidOuter = false;
    private originalNewUserObject: WebspaceUserObject;
    private _accessesCache: any[] = [];

    constructor(
        private $timeout: ng.ITimeoutService,
        private authContext: AuthContextService,
        private webhostingUserModel: WebhostingUserModelService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit(): void {
        this.accessHeadings = [
            this.$translate.instant('TR_110119-76b4ba_TR'),
            this.$translate.instant('TR_100119-e937e9_TR')
        ];

        if (this.lastClickedCheckbox == null) {
            this.lastClickedCheckbox = {
                value: undefined,
                field: undefined
            };
        }

        this.accessTypeButtons = [
            { value: '1', label: this.$translate.instant('TR_110119-d043dc_TR') },
            { value: '2', label: this.$translate.instant('TR_110119-b61be5_TR') }
        ];

        this.accessLevels = [
            { type: 'ftpAccess', label: this.$translate.instant('TR_100119-ca0b1e_TR') },
            { type: 'sshAccess', label: this.$translate.instant('TR_100119-fecdf6_TR') },
            { type: 'statsAccess', label: this.$translate.instant('TR_100119-77f707_TR') },
            { type: 'ftpLimited', label: this.$translate.instant('TR_100119-54245d_TR') }
        ];

        this.accessType = this.accessType || '2';
        this.accountId = this.accountId || this.authContext.account.id;
        this.originalNewUserObject = this.newWebspaceUserObject;
        this.resetAccess();

        if (this.availableAccesses === undefined) {
            this._getAvailabelAccesses();
        }
    }

    public $onChanges(changes: ng.IOnChangesObject): void {
        this.checkIfFormIsValid();

        if (changes.resetAccessOutside !== undefined && changes.resetAccessOutside.currentValue) {
            this.resetAccess();
        }

        if (
            changes.removeFromList !== undefined
            && changes.removeFromList.currentValue !== changes.removeFromList.previousValue
        ) {
            void this.$timeout(this._getAvailabelAccesses);
        }
    }

    public listWebspaceUsers = (
        limit?: number,
        page?: number,
        filters?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions,
        timeout?: number
    ) => {
        const filterObject: any = {
            subFilter: [{
                field: 'accountId',
                value: AuthContextService.account.id
            }],
            subFilterConnective: 'AND'
        };
        if ([undefined, null].indexOf(filters) >= 0) {
            filterObject.subFilter.push({
                field: 'userName',
                value: '*'
            });
        } else {
            filterObject.subFilter.push(filters);
        }
        return this.webhostingUserModel.list(limit, page, filterObject, sort, timeout);
    };

    public checkIfFormIsValid = () => {
        let userCheck = true;
        this.$timeout(
            () => {
                if (!this.userAssignment) {

                    userCheck = this.newWebspaceUserObject !== undefined
                    && (
                        [undefined, null, ''].indexOf(this.newWebspaceUserObject.id) < 0
                        || (
                            [undefined, null, ''].indexOf(this.newWebspaceUserObject.name) < 0
                            && [undefined, null, ''].indexOf(this.newWebspaceUserObject.password) < 0
                        )
                    );
                }

                this.isValidOuter = userCheck
                && ['ftpAccess', 'sshAccess', 'statsAccess', 'ftpLimited']
                .some((key) => [undefined, null, '', false].indexOf(this.newAccessObject[key]) < 0);
            }
        );

        return this.isValidOuter;
    };

    public ftpLimitedAccess = (accessType: string) => {
        this.$timeout(
            () => {
                if (accessType === 'ftpAccess') {
                    this.disabledAccessLevels = [];

                    if (this.newAccessObject.ftpAccess === true) {
                        this.disabledAccessLevels.push('ftpLimited');
                        this.newAccessObject.ftpLimited = false;
                    }
                }

                if (accessType === 'ftpLimited') {
                    this.disabledAccessLevels = [];

                    if (this.newAccessObject.ftpLimited === true) {
                        this.disabledAccessLevels.push('ftpAccess');
                        this.disabledAccessLevels.push('sshAccess');
                        this.newAccessObject.ftpAccess = false;
                        this.newAccessObject.sshAccess = false;
                    }
                }

                this.checkIfFormIsValid();
            }
        );
    };

    public isDisabledAccessType(accessType: string) {
        return this.disabledAccessLevels.indexOf(accessType) >= 0;
    }

    public resetAccess = () => {
        this.newWebspaceUserObject = {
            accountId: this.accountId,
            comments: '',
            name: '',
            password: '',
            sshKey: ''
        };

        this.newAccessObject = {
            ftpAccess: false,
            ftpLimited: false,
            homeDir: '',
            sshAccess: false,
            statsAccess: false,
            userId: '',
            webspaceId: this.webspaceId
        };

        this.checkIfFormIsValid();
    };

    private _getAvailabelAccesses = () => {
        if (this._accessesCache.length === 0) {
            this.webhostingUserModel.list(null, null, { field: 'accountId', value: this.accountId })
            .then(
                (accesses) => {
                    if (this.serviceObject !== undefined) {
                        this.serviceObject.display.availableAccesses = accesses.data;
                    }
                    this._accessesCache = accesses.data;
                    this._setAccesses();
                }
            );
        } else {
            this._setAccesses();
        }
    };

    private _setAccesses = () => {
        const accesses = this._accessesCache;
        this.accessSelectItems = [];
        this.availableAccesses = accesses;
        accesses.filter(
            (access) => {
                if (!this._isAccessInRemoveFromList(access)) {
                    let comments = access.comments;

                    if (comments.length > 100) {
                        comments = comments.substring(0, 100) + '...';
                    }

                    this.accessSelectItems.push(
                        {
                            names: [access.name, comments],
                            value: access.id
                        }
                    );
                }
            }
        );

        if (this.accessSelectItems.length > 0) {
            this.onlyNewAccessAvailable = false;
            this.accessType = '1';
        } else {
            this.onlyNewAccessAvailable = true;
            this.accessType = '2';
        }
    };

    private _isAccessInRemoveFromList = (access) => {
        if (this.removeFromList === undefined) {
            return false;
        }

        return this.removeFromList.some(
            (removeAccess) => access.id === removeAccess.userId || access.id === removeAccess.id
        );
    };

    private checkboxClick = (field: string) => {
        this.ftpLimitedAccess(field);

        if (this.rightsChangedCallback) {
            this.rightsChangedCallback(this.newAccessObject[field], field);
        }
    };

    private selectExistingUser = async (value: string) => {
        this.choosenAccessId = value;
        this.checkIfFormIsValid();
        if ([undefined, null].indexOf(this.choosenAccessId) < 0) {
            this.newWebspaceUserObject = await this.webhostingUserModel.findOneById(this.choosenAccessId);
            if (this.selectExistingUserChangeCallback) {
                this.selectExistingUserChangeCallback(this.choosenAccessId);
            }
        }
    };

    private userAccessTypeChange = () => {
        if (this.userAccessTypeChangeCallback) {
            this.userAccessTypeChangeCallback(this.accessType);
        }
    };

    private usernameChanged = () => {
        this.checkIfFormIsValid();

        if (this.usernameChangedCallback) {
            this.usernameChangedCallback(this.newWebspaceUserObject.name);
        }
    };

    private userPasswordChanged = () => {
        this.checkIfFormIsValid();

        if (this.userPasswordChangedCallback) {
            this.userPasswordChangedCallback(this.newWebspaceUserObject.password);
        }
    };

    private sshKeyChanged = () => {
        if (this.sshKeyChangedCallback) {
            this.sshKeyChangedCallback(this.newWebspaceUserObject.sshKey);
        }
    };

    private userCommentChanged = () => {
        if (this.userCommentChangedCallback) {
            this.userCommentChangedCallback(this.newWebspaceUserObject.comments);
        }
    };
}

export class MoleculeRowWebspaceAccessComponent implements ng.IComponentOptions {
    public bindings = {
        accessType: '=?',
        accountId: '<',
        choosenAccessId: '=?',
        isValidOuter: '=isValid',
        newAccessObject: '=',
        newWebspaceUserObject: '=',
        removeFromList: '<',
        rightsChangedCallback: '<?',
        selectExistingUserChangeCallback: '<?',
        serviceObject: '=',
        sshKeyChangedCallback: '<?',
        userAccessTypeChangeCallback: '<?',
        userAssignment: '<',
        userCommentChangedCallback: '<?',
        userHomeDirChangedCallback: '<?',
        usernameChangedCallback: '<?',
        userPasswordChangedCallback: '<?',
        webspaceId: '<'
    };

    /* tslint:disable-next-line:max-line-length */
    public template = require('./webspace-access.html');
    public controller = MoleculeRowWebspaceAccessController;
}
