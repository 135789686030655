import ng from 'angular';

import q from 'q';
import { ContingentType } from '@/atomic-components';
import {
    BillingHelperService,
    ProductHelperService,
    ProductsModelService,
    WizardNewHelperService
} from '@/services';
import * as Types from '@/types';
import { ViewTypes } from '@/types/view-types';

export class DatabaseComposerService {
    public static $inject: string[] = [
        '$translate',
        'billingHelper',
        'productHelper',
        'productsModel',
        'wizardNewHelper'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private billingHelper: BillingHelperService,
        private productHelper: ProductHelperService,
        private productsModel: ProductsModelService,
        private wizardNewHelper: WizardNewHelperService
    ) {}

    public buildDatabaseApiObject = (metadata: ViewTypes.ProductConfigDatabaseObject) => {
        let databaseProduct: Types.ProductApi.Product;
        let contractPeriod: string;
        let databaseFactSheets = [];

        let storageFactSheets = [];
        let storageProduct: Types.ProductApi.Product;

        const promises = [
            this.billingHelper.getPriceByProductCode(metadata.productCode)
            .then((result) => contractPeriod = result.contractPeriod),

            this.productHelper.getProductFromProductCode(metadata.productCode)
            .then(
                (databaseProductResult: Types.ProductApi.Product) => {
                    databaseProduct = databaseProductResult;

                    return this.productsModel.findStorageProduct(metadata.productCode, databaseProductResult);
                }
            )
            .then(
                (storageProductResult: Types.UI.APIResponse<Types.ProductApi.Product>) => {
                    storageProduct = storageProductResult.response;

                    storageFactSheets = [
                        {
                            description: '',
                            label: this.$translate.instant('dc44bf44-77e8-4da8-9e0c-36bf36a945f1'),
                            type: 'integer',
                            value: metadata.additionalStorageItems
                        },
                        {
                            description: '',
                            label: this.$translate.instant('TR_STORAGE_TR'),
                            type: 'mb',
                            value: ProductHelperService.wrapSpecificationItems(
                                storageProduct.specificationItems
                            ).storage?.value
                        }
                    ];
                }
            ),

            this.wizardNewHelper.factSheetConditioner(metadata.productCode)
            .then(
                (result) => {
                    databaseFactSheets = [
                        {
                            description: '',
                            label: this.$translate.instant('TR_110419-f5d08d_TR'),
                            type: 'string',
                            value: this.$translate.instant(
                                this.productHelper.getReadableBillingCycleMonthsFromWording(contractPeriod)
                            )
                        }
                    ]
                    .concat(result);
                }
            )
        ];

        return q.all(promises).then(
            () => {
                const summaryObject: Types.ViewTypes.WizardSummaryProductViewPanelObject[] = [
                    {
                        account: metadata.account,
                        contingent: metadata.contingent,
                        factSheets: databaseFactSheets,
                        product: databaseProduct,
                        productCode: metadata.productCode
                    }
                ];

                if (metadata.contingent?.type === ContingentType.bundle) {
                    summaryObject[0].bundleContingents = metadata.contingent?.misc?.bundle?.effectiveContingentUsage;
                }

                if (metadata.additionalStorageItems > 0) {
                    summaryObject.push({
                        account: metadata.account,
                        contingent: metadata.contingent,
                        factSheets: storageFactSheets,
                        product: storageProduct,
                        productCode: storageProduct.productCode,
                        priceMultiplication: metadata.additionalStorageItems
                    });
                }

                return {
                    apiObject: {
                        database: {
                            accesses: [
                                {
                                    accessLevel: Object.keys(metadata.accesses.customAccesses.database.accessLevel)
                                    .filter((key) => metadata.accesses.customAccesses.database.accessLevel[key]),

                                    userId: metadata.accesses.customAccesses.database.accessType
                                    === ViewTypes.UserAccessType.new
                                    ? undefined
                                    : metadata.accesses.customAccesses.database.userId
                                }
                            ],
                            accountId: metadata.account.id,
                            bundleId: metadata.contingent?.type === ContingentType.bundle
                            ? metadata.contingent?.id
                            : undefined,
                            comments: metadata.comments,
                            name: metadata.databaseName,
                            productCode: metadata.productCode,
                            storageQuota: metadata.storageQuota
                        } as Types.DatabaseApi.Database,
                        databaseServerId: metadata.contingent?.type === ContingentType.databaseServer
                        ? metadata.contingent?.id
                        : undefined,
                        user: {
                            accountId: metadata.account.id,
                            comments: metadata.accesses.customAccesses.database.comments,
                            name: metadata.accesses.customAccesses.database.name,
                            password: metadata.accesses.customAccesses.database.password
                        } as Types.DatabaseApi.User
                    },
                    summaryObject: summaryObject
                } as Types.ViewTypes.WizardComposerObject;
            }
        );
    };
}
