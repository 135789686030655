import ng from 'angular';
import { UtilityStatusObject } from '@/atomic-components';
import { LinkListItem } from '@/atomic-components/molecules/links';
import './panel-view.scss';

export class MoleculePanelViewController implements ng.IController {
    public static $inject: string[] = ['$transclude'];

    public allProductsQuantity: number;
    public panelHeaderAllRoute: string;
    public panelHeaderAllRouteParams: { [key: string]: unknown };
    public panelHeaderRoute = '';
    public panelHeaderRouteParams: { [key: string]: unknown } = {};
    public panelIcon: string;
    public panelIconPath: boolean;
    public panelTitle: string;
    public panelType = '';
    public panelItems: LinkListItem[];
    public panelItemsHorizontal: boolean;
    public panelItemsFullWidth: boolean;
    public includeShowAllLink: boolean;
    public includeShowDetailsLink: boolean;
    public inUse: string;
    public maxUse: string;
    public mediumSpaceBottom: boolean;
    public isStorage = false;
    public showAbsolute = false;
    public infoText = 'Domains';
    public callout: { [key: string]: unknown };
    public titleImage: string;
    public titleImageAlt: string;
    public utilityStatus: UtilityStatusObject;
    public showDetailsText: string;
    public smallSpace: boolean;
    public smallSpaceBottom: boolean;
    public subHeadline: string;

    constructor(
        private $transclude: ng.ITranscludeFunction
    ) {}

    public $onInit(): void {
        this.includeShowDetailsLink = this.includeShowDetailsLink || false;
        this.includeShowAllLink = this.includeShowAllLink || false;
        this.mediumSpaceBottom = this.mediumSpaceBottom || false;
        this.panelItems = this.panelItems || [];
        this.panelItemsHorizontal = this.panelItemsHorizontal || false;
        this.panelItemsFullWidth = this.panelItemsFullWidth || false;
        this.smallSpace = this.smallSpace || false;
        this.smallSpaceBottom = this.smallSpaceBottom || false;

        if (
            [undefined, null].indexOf(this.callout) === -1
            && [undefined, null].indexOf(this.callout.calloutDisplayTitle) !== -1
        ) {
            this.callout.calloutDisplayTitle = true;
        }
    }

    public get panelItemList(): LinkListItem[] {
        return this.panelItems;
    }

    public get showAllLink(): boolean {
        return this.includeShowAllLink;
    }

    public get showDetailsLink(): boolean {
        return this.includeShowDetailsLink;
    }

    public showUtilityStatus(): boolean {
        return !!this.utilityStatus !== false;
    }

    public transcludeContent = (transcludeAttr: string): boolean => {
        if (['', null, undefined].indexOf(transcludeAttr) >= 0) {
            return false;
        }

        return this.$transclude.isSlotFilled(transcludeAttr);
    };
}

/**
 * Notice:
 * There are four ways to display content in this component (can be combined):
 *
 * - PanelItems:
 * When a panel items list is submitted, it will be included as link listmolecule.
 *
 * - Transclude content
 * If content is stored via the optional tag 'transcludeContent',
 * it will be included below (if available, see above) the panel item list
 *
 * - Transclude content above item list
 * If content is stored via the optional tag 'transcludeContentAboveItemList',
 * it will be included above the panel item list and a horizontal line will be
 * drawn for you.
 *
 * - Callout
 * When a callout object is submitted, it will be included as callout.
 *
 * Usage guide: You can use both or all four methods mentioned above together
 * and do not to have to write your custom panel that looks the exact same as
 * this component.
 */

export class MoleculePanelViewComponent implements ng.IComponentOptions {
    public transclude = {
        transcludedPanelHeaderRight: '?transcludeHeaderRight',
        transcludeViewContentAboveItemList: '?transcludeContentAboveItemList',
        transcludedViewContent: '?transcludeContent'
    };
    public bindings = {
        allProductsQuantity: '<?',
        callout: '<',
        includeShowAllLink: '<',
        includeShowDetailsLink: '<',
        mediumSpaceBottom: '<',
        panelHeaderAllRoute: '@?',
        panelHeaderAllRouteParams: '<?',
        panelHeaderRoute: '@',
        panelHeaderRouteParams: '<',
        panelIcon: '@',
        panelIconPath: '<?',
        panelItems: '<?',
        panelItemsFullWidth: '<?',
        panelItemsHorizontal: '<?',
        panelTitle: '@',
        panelType: '@',
        showDetailsText: '@',
        smallSpace: '<?',
        smallSpaceBottom: '<?',
        subHeadline: '@?',
        titleImage: '@',
        titleImageAlt: '@',
        utilityStatus: '<'
    };
    public controller =  MoleculePanelViewController;
    public template = require('./panel-view.html');
}
