import * as ng from 'angular';

import { PanelHeaderData } from '@/atomic-components/molecules/panels';
import { AlertManagerService, MachineModelService } from '@/services';
import { ApiErrorModel } from '@/services/errors/api-error-model';
import { MachineApi } from '@/types/machine-api';

export class TemplateMachineInterfacesController {
    public static $inject: string[] = ['$translate', 'alertManager', 'apiErrorModel', 'machineModel'];

    public pageHeaderData: PanelHeaderData;
    public machine: MachineApi.VirtualMachine;
    public panelRightSettings;
    public selectedNetwork: string;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private machineModel: MachineModelService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'machine.virtualmachines.id.edit',
            backwardText: this.$translate.instant('TR_140119-0389cf_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: 'server',
            panelTitle: this.machine.name
        };

        this.panelRightSettings = {
            editPanelButton: true
        };
    };

    public resetMachineData = async () => {
        this.machine = await this.machineModel.findOneById(this.machine.id);
    };

    public updateInterfaceRdns = () => {

        this.machine.networkInterfaces
        .filter(networkIface => networkIface.id === this.selectedNetwork)
        .forEach((iface) => {
            iface.ipAddresses.forEach((address) => {
                if ([undefined, null, ''].indexOf(address.rdns) < 0) {
                    this.machineModel.setRDNS(address.ip, address.rdns).then(
                        (res) => {
                            if (!this.apiErrorModel.apiResponseHasError(res)) {
                                return res;
                            }
                            return Promise.reject(false);
                        }
                    );
                }
            });
        });
        this.alertManager.success(this.$translate.instant('TR_100119-139c51_TR'));
    };
}

export class TemplateMachineInterfacesComponent implements ng.IComponentOptions {
    public bindings = {
        machine: '<'
    };
    public template = require('./machine-interfaces.html');
    public controller = TemplateMachineInterfacesController;
}
