import * as ng from 'angular';
import { UiRights } from '../../../configuration';
import { AuthContextService, ManagedMachineProjectSubaccountTypes } from '../../../services';
import { ManagedMachineProjectObject } from '../../organisms';

export class OrganManagedMachineProjectNewController implements ng.IController {
    public static $inject: string[] = ['authContext'];

    public projectData: ManagedMachineProjectObject;
    public accountSelectionComplete = false;
    public projectConfigComplete = false;

    public constructor(
        private authContext: AuthContextService
    ) {}

    public get complete() {
        return this.accountSelectionComplete
            && this.projectConfigComplete;
    }

    /* tslint:disable-next-line:no-empty */
    public set complete({}) {}

    public showSubaccountSelection = () => {
        if (this.authContext.isGrantedAll([UiRights.ACC_SUBACCOUNT_LIST, UiRights.ACC_SUBACCOUNT_CREATE])) {
            return true;
        }
        this.accountSelectionComplete = true;
        this.projectData.accountId = this.authContext.account.id;
        this.projectData.accountType = ManagedMachineProjectSubaccountTypes.NONE;
        return false;
    };

}

export class OrganManagedMachineProjectNewComponent implements ng.IComponentOptions {
    public bindings = {
        complete: '=',
        projectData: '='
    };

    public template = require('./new-project.html');
    public controller =  OrganManagedMachineProjectNewController;
}
