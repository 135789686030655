import {
    RpcClient,
    RpcClientService
} from '@/services/rpc-client';
import { Finding } from '@/types/finding';
import { EmailApi } from '@/types/email-api';

export const EmailClientService = [
    'rpcClient',
    (platformRpcClient: RpcClientService) => platformRpcClient.Factory('/api/email/v1/json/', 'email')
];

export class EmailRobotService {
    public static $inject: string[] = ['emailClient'];

    constructor(private emailClient: RpcClient) {}

    public listMailboxes(filter?: Finding.Filter, limit?: number, page?: number, sort?: Finding.SortOptions) {
        return this.emailClient.request('mailboxesFind')
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public listMailboxesWithoutPagination(filter?: Finding.Filter, limit?: number, page?: number, sort?: Finding.SortOptions) {
        return this.emailClient.request('mailboxesFind')
        .skipPagination()
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public listOrganizationMailboxesWithoutPagination(filter?: Finding.Filter, limit?: number, page?: number, sort?: Finding.SortOptions) {
        return this.emailClient.request('organizationMailboxesFind')
        .skipPagination()
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public listOrganizationMailboxes(filter?: Finding.Filter, limit?: number, page?: number, sort?: Finding.SortOptions) {
        return this.emailClient.request('organizationMailboxesFind')
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public createMailbox(mailbox: EmailApi.Mailbox, password: string, owner: string, poolId: string, autoconfigureDns?: boolean, skipDnsCheck?: boolean) {
        autoconfigureDns = autoconfigureDns || false;
        return this.emailClient.request('mailboxCreate')
        .bustCache('billing', 'depositGet')
        .bustCache('bundle', 'bundlesFind')
        .ownerAccountId(owner)
        .execute({
            autoconfigureDns: autoconfigureDns,
            mailbox: mailbox,
            password: password,
            poolId: poolId,
            skipDnsCheck: skipDnsCheck
        });
    }

    public mailboxModifyRestrictions = (apiObject: { [key: string]: any }, owner: string) => {
        return this.emailClient.request('mailboxModifyRestrictions')
            .ownerAccountId(owner)
            .execute(apiObject);
    };

    public checkEmailAddress(email: string, owner: string, bundleId: string, productCode: string, skipDnsCheck?: boolean) {
        return this.emailClient.request('checkEmailAddress')
        .execute({
            emailAddress: email,
            productCode: productCode,
            accountId: owner,
            bundleId: bundleId,
            skipDnsCheck: skipDnsCheck
        });
    }

    public updateMailbox(mailbox: EmailApi.Mailbox, password: string) {
        return this.emailClient.request('mailboxUpdate')
        .execute({ mailbox: mailbox, password: password });
    }

    public deleteMailbox(mailbox: EmailApi.Mailbox, date?: Date) {
        return this.emailClient.request('mailboxDelete')
        .bustCache('billing', 'depositGet')
        .bustCache('bundle', 'bundlesFind')
        .execute({ emailAddress: mailbox.emailAddress, execDate: date });
    }

    // todo check if delete permanently necessary

    public deletePermanentlyMailbox(mailbox: EmailApi.Mailbox, date?: Date) {
        return this.emailClient.request('mailboxDelete')
        .execute({ emailAddress: mailbox.emailAddress, execDate: date });
    }

    public oneDomainSettings(domainName: string) {
        return this.emailClient
            .request('domainSettingsFindOne')
            .skipPagination()
            .execute({ domainName: domainName });
    }

    public domainSettingsUpdate(domainSettings: EmailApi.DomainSettings) {
        return this.emailClient.request('domainSettingsUpdate')
        .execute({ domainSettings: domainSettings });
    }

    public listJobs(filter?: Finding.Filter, limit?: number, page?: number, sort?: Finding.SortOptions) {
        return this.emailClient.request('jobsFind')
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public listOrganizations(filter?: Finding.Filter, limit?: number, page?: number, sort?: Finding.SortOptions) {
        return this.emailClient.request('organizationsFind')
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public listOrganizationsWithoutPagination(filter?: Finding.Filter, limit?: number, page?: number, sort?: Finding.SortOptions) {
        return this.emailClient.request('organizationsFind')
        .skipPagination()
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public createOrganization(organization: EmailApi.Organization, owner: string) {
        return this.emailClient.request('organizationCreate')
        .bustCache('billing', 'depositGet')
        .ownerAccountId(owner)
        .execute({ organization: organization });
    }

    public updateOrganization(organization: EmailApi.Organization) {
        return this.emailClient.request('organizationUpdate')
        .execute({ organization: organization });
    }

    public deleteOrganization(organizationId: string) {
        return this.emailClient.request('organizationDelete')
        .execute({ organizationId: organizationId });
    }

    public restoreMailbox(mailboxId: string) {
        return this.emailClient.request('mailboxRestore')
        .bustCache('billing', 'depositGet')
        .execute({ mailboxId: mailboxId });
    }

    public purgeRestorableMailbox(mailboxId: string) {
        return this.emailClient.request('mailboxPurgeRestorable')
        .execute({ mailboxId: mailboxId });
    }

    public mailboxDeletionCancel = (mailboxId: string) => {
        return this.emailClient.request('mailboxDeletionCancel')
            .bustCache('email', 'mailboxesFind')
            .execute({ mailboxId: mailboxId });
    };
}
