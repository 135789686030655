import { UiRights } from '@/configuration';
import * as ng from 'angular';
import { WizardConfirmationBase } from '../../general/confirm';
import {
    ApiErrorModel,
    AuthContextService,
    EmailRobotService,
    ErrorMapperService,
    OrchestratorRobotService
} from './../../../../../services/';

export class EmailWizardConfirmation extends WizardConfirmationBase {
    public static $inject: string[] = WizardConfirmationBase.$inject.concat(['emailRobot']);

    constructor(
        protected $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        protected apiErrorModel: ApiErrorModel,
        protected errorMapper: ErrorMapperService,
        protected localInterval: ng.IIntervalService,
        protected orchestratorRobot: OrchestratorRobotService,
        protected emailRobot: EmailRobotService
    ) {
        super(
            $timeout,
            $translate,
            apiErrorModel,
            errorMapper,
            localInterval,
            orchestratorRobot
        );

        if (!AuthContextService.isGranted(UiRights.EMAIL_JOBS_LIST)) {
            this.isJobDependendWizard = false;
            this.fillNextSteps = () => {
                this.nextSteps.push({
                    iconName: 'envelope',
                    linkText: this.$translate.instant('TR_110119-c1856d_TR'),
                    route: 'email.mailboxes.overview',
                    routeOptions: { reload: true },
                    routeParams: {}
                });
            };
        }
    }

    public getJobByFilter = (filter, cacheTimeout, objectType?) => {
        switch (this.selectedProductFamily) {
            default:
                return this.emailRobot
                    .listJobs(
                        filter,
                        null,
                        null,
                        null
                    )
                    .then(this.normalizeResponseObject);
        }
    };

    // tslint:disable-next-line: cyclomatic-complexity
    public callbackAfterFinishedRequests = () => {
        this.nextSteps = [];
        if (this.selectedProductFamily === 'imap-mailboxes') {
            for (const createdObject of this.createRequests) {
                if (createdObject.objectType === 'ImapMailbox'
                    && this.isJobFinished(createdObject)
                ) {
                    if ([undefined, null, ''].indexOf(createdObject.result.bundleId) < 0) {
                        this.nextSteps.push({
                            iconName: 'envelope',
                            linkText: this.$translate.instant('TR_170519-1243da_TR'),
                            route: 'bundle.id.mailboxes.id.edit',
                            routeOptions: { reload: true },
                            routeParams: { bundleId: createdObject.result.bundleId, mailboxId: createdObject.result.id }
                        });
                        this.nextSteps.push({
                            iconName: 'envelope',
                            linkText: this.$translate.instant('TR_110119-85a51b_TR'),
                            route: 'bundle.id.mailboxes.new',
                            routeOptions: { reload: true },
                            routeParams: { bundleId: createdObject.result.bundleId }
                        });
                    } else {
                        this.nextSteps.push({
                            iconName: 'envelope',
                            linkText: this.$translate.instant('TR_170519-1243da_TR'),
                            route: 'email.mailboxes.id.edit',
                            routeOptions: { reload: true },
                            routeParams: { mailboxId: createdObject.result.id }
                        });
                        this.nextSteps.push({
                            iconName: 'envelope',
                            linkText: this.$translate.instant('TR_110119-85a51b_TR'),
                            route: 'email.mailboxes.new',
                            routeOptions: { reload: true },
                            routeParams: { }
                        });
                    }
                }
            }
        } else if (this.selectedProductFamily === 'catchalls') {
            for (const createdObject of this.createRequests) {
                if (createdObject.objectType === 'CatchAll'
                    && this.isJobFinished(createdObject)
                ) {
                    this.nextSteps.push({
                        iconName: 'envelope',
                        linkText: this.$translate.instant('TR_170519-1243da_TR'),
                        route: 'email.mailboxes.id.edit',
                        routeOptions: { reload: true },
                        routeParams: { mailboxId: createdObject.result.id }
                    });
                    this.nextSteps.push({
                        iconName: 'envelope',
                        linkText: this.$translate.instant('TR_110119-85a51b_TR'),
                        route: 'email.mailboxes.new',
                        routeOptions: { reload: true },
                        routeParams: { }
                    });
                }
            }
        } else if (this.selectedProductFamily === 'mailing-lists') {
            for (const createdObject of this.createRequests) {
                if (createdObject.objectType === 'MailingList'
                    && this.isJobFinished(createdObject)
                ) {
                    this.nextSteps.push({
                        iconName: 'envelope',
                        linkText: this.$translate.instant('TR_170519-1243da_TR'),
                        route: 'email.mailboxes.id.edit',
                        routeOptions: { reload: true },
                        routeParams: { mailboxId: createdObject.result.id }
                    });
                    this.nextSteps.push({
                        iconName: 'envelope',
                        linkText: this.$translate.instant('TR_110119-85a51b_TR'),
                        route: 'email.mailboxes.new',
                        routeOptions: { reload: true },
                        routeParams: { }
                    });
                }
            }
        } else if (this.selectedProductFamily === 'smtp-forwarders') {
            for (const createdObject of this.createRequests) {
                if (createdObject.objectType === 'SmtpForwarder'
                    && this.isJobFinished(createdObject)
                ) {
                    this.nextSteps.push({
                        iconName: 'envelope',
                        linkText: this.$translate.instant('TR_170519-1243da_TR'),
                        route: 'email.mailboxes.id.edit',
                        routeOptions: { reload: true },
                        routeParams: { mailboxId: createdObject.result.id }
                    });
                    this.nextSteps.push({
                        iconName: 'envelope',
                        linkText: this.$translate.instant('TR_110119-85a51b_TR'),
                        route: 'email.mailboxes.new',
                        routeOptions: { reload: true },
                        routeParams: { }
                    });
                }
            }
        } else if (this.selectedProductFamily === 'msexchange-mailboxes') {
            for (const createdObject of this.createRequests) {
                if (createdObject.objectType === 'ExchangeMailbox'
                    && this.isJobFinished(createdObject)
                ) {
                    this.nextSteps.push({
                        iconName: 'envelope',
                        linkText: this.$translate.instant('TR_170519-1243da_TR'),
                        route: 'email.mailboxes.id.edit',
                        routeOptions: { reload: true },
                        routeParams: { mailboxId: createdObject.result.id }
                    });
                    this.nextSteps.push({
                        iconName: 'envelope',
                        linkText: this.$translate.instant('TR_110119-85a51b_TR'),
                        route: 'email.mailboxes.new',
                        routeOptions: { reload: true },
                        routeParams: { }
                    });
                }
                if (createdObject.objectType === 'Organization'
                    && this.isJobFinished(createdObject)
                ) {
                    this.nextSteps.push({
                        iconName: 'envelope',
                        linkText: this.$translate.instant('TR_230321-4b0a1e_TR'),
                        route: 'email.organizations.id.edit',
                        routeOptions: { reload: true },
                        routeParams: { organizationId: createdObject.result.id }
                        // das muss noch getestet werden hier muss vielleicht ein anderes
                        // objectType verwendet werden oder die id aus der api bezogen werden
                    });
                }
            }
        } else if (['external-forwarders', 'internal-forwarders'].indexOf(this.selectedProductFamily) >= 0) {
            for (const createdObject of this.createRequests) {
                if (createdObject.objectType === 'Forwarder'
                    && this.isJobFinished(createdObject)
                ) {
                    this.nextSteps.push({
                        iconName: 'envelope',
                        linkText: this.$translate.instant('TR_170519-1243da_TR'),
                        route: 'email.mailboxes.id.edit',
                        routeOptions: { reload: true },
                        routeParams: { mailboxId: createdObject.result.id }
                    });
                    this.nextSteps.push({
                        iconName: 'envelope',
                        linkText: this.$translate.instant('TR_110119-85a51b_TR'),
                        route: 'email.mailboxes.new',
                        routeOptions: { reload: true },
                        routeParams: { }
                    });
                }
            }
        }
    };
}
