import * as ng from 'angular';
import * as q from 'q';
import * as Types from '../../types';

import * as dnsZonefileGenerator from 'dns-zonefile';

export class ZoneFileSerializerService {
    public serialize(zoneConfig: Types.DnsApi.ZoneConfig, _records: Types.DnsApi.Record[]) {
        const records = ng.copy(_records);

        const options = {
            $origin: zoneConfig.name + '.',
            $ttl: '86400',
            a: [],
            aaaa: [],
            cname: [],
            mx: [],
            ns: [],
            ptr: [],
            soa: {
                expire: zoneConfig.soaValues.expire,
                minimum: zoneConfig.soaValues.ttl,
                mname: '',
                refresh: zoneConfig.soaValues.refresh,
                retry: zoneConfig.soaValues.retry,
                rname: this.formatHostmasterEmail(zoneConfig.emailAddress),
                serial: 1
            },
            srv: [],
            txt: []
        };

        records.forEach(
            (record) => {
                record.name = record.name.slice(0, -1 * zoneConfig.name.length);

                if (record.name.indexOf('.') === (record.name.length - 1)) {
                    record.name = record.name.slice(0, -1);
                }
            }
        );

        // records.forEach(function processRecord(record) {
        records.forEach(
            (record) => {
                const type = record.type.toLowerCase();

                if (type === 'soa') {
                    options.soa.mname = this.getPrimaryNS(record.content);
                    return;
                }

                const entry = this.entryFromRecord(record, type);

                /*
                 * Quickfix for PUI-4284
                 * This only ensures that no errors are thrown when exporting a zone
                 * with other record types - they won't appear in the zonefile.
                 */
                if ([undefined, null].indexOf(options[type]) >= 0) {
                    options[type] = [];
                }

                options[type].push(entry);
            }
        );
        return q.when(dnsZonefileGenerator.generate(options));
    }

    private formatHostmasterEmail(email: string) {
        const parts = email.split('@');
        parts[0].replace('.', '\\.');

        return parts.join('.') + '.';
    }

    private parseSRVContent(recordContent: string) {
        const rrTokens = recordContent.split(/\s+/g);
        const l = rrTokens.length;
        const result = {
            port: parseInt(rrTokens[l - 2], 10),
            target: rrTokens[l - 1],
            weight: parseInt(rrTokens[l - 3], 10)
        };
        return result;
    }

    private getPrimaryNS(recordContent: string) {
        const rrTokens = recordContent.trim().split(/\s+/g);

        return rrTokens[0];
    }

    private entryFromRecord(record: Types.DnsApi.Record, type: string) {
        const entry: any = {
            name: record.name,
            ttl: record.ttl
        };

        const content = record.content.trim();
        switch (type) {
            case 'srv':
                const parsedContent = this.parseSRVContent(content);

                entry.target = parsedContent.target + '.';
                entry.priority = record.priority;
                entry.weight = parsedContent.weight;
                entry.port = parsedContent.port;

                break;
            case 'mx':
                entry.preference = record.priority;
            /* falls through*/
            case 'ptr':
            case 'ns':
                entry.host = record.content + '.';
                break;
            case 'a':
            case 'aaaa':
                entry.ip = record.content;
                break;
            case 'txt':
                entry.txt = content.slice(1, content.length - 1);
                break;
            case 'cname':
                entry.alias = record.content + '.';
                break;
        }

        return entry;
    }
}
