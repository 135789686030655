import * as ng from 'angular';

export class OrganViewPanelDataProcessingPreambleController {
    public static $inject: string[] = [];
}

export class OrganViewPanelDataProcessingPreambleComponent implements ng.IComponentOptions {
    public controller = OrganViewPanelDataProcessingPreambleController;
    public template = require('./data-processing-preamble.html');
}
