import * as ng from 'angular';
import { PageHeaderData } from '../../../atomic-components/organisms';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';

export class TemplateMailboxDomainsetingsController {
    public static $inject: string[] = ['$translate', 'authContext'];

    public pageHeaderData: PageHeaderData;
    public mailbox;
    public domainSettings;
    public faqArticleIdList: string[] = [];
    public userPanelRight;

    private comeFrom;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: this.comeFrom !== 'products'
                ? 'bundle.id.mailboxes.id.edit'
                : 'email.mailboxes.id.edit',
            backwardText: this.$translate.instant('TR_110119-f1b5d4_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: this.comeFrom !== 'products'
                ? { bundleId: this.mailbox.bundleId, mailboxId: this.mailbox.id }
                : { mailboxId: this.mailbox.id },
            panelIcon: 'envelope',
            panelTitle: this.$translate.instant(
                /* translationId */'TR_110119-91408f_TR',
                { emailAddress: this.domainSettings.domainNameUnicode }
            )
        };

        this.userPanelRight = {
            domainsettings: {
                editPanelButton: this.authContext.isGranted(UiRights.EMAIL_DOMAINSETTINGS_EDIT)
            }
        };
    };
}

export class TemplateMailboxDomainsettingsComponent implements ng.IComponentOptions {
    public bindings = {
        comeFrom: '<',
        domainSettings: '<',
        mailbox: '<'
    };
    public controller = TemplateMailboxDomainsetingsController;
    public controllerAs = '$TemplateMailboxEditCtrl';
    public template = require('./mailbox-domainsettings-template.html');
}
