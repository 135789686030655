import * as ng from 'angular';
import {
    AccountModelService,
    AlertManagerService,
    AuthContextService,
    AuthenticationRobotService,
    DateTimeWrapper,
    DateWrapper,
    DepositModelService,
    NotificationsHelperService
} from '../../../../../services';

export class OrganViewPanelEditContactDetailsController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        '$window',
        'accountModel',
        'alertManager',
        'authenticationRobot',
        'depositModel',
        'notificationsHelper'
    ];

    public accountData: any;
    public contactFormData: any;
    public sepaValidationErrorList = [];
    public displayInReviewWarning: boolean;
    public displaySepaProhibited: boolean;
    public contactFormTypes: { name: string; value: string }[];
    public commentValidationInstructions: any[] = [];
    public contactDetails: {
        addition: string;
        city: string;
        country: string;
        faxNumber: string;
        phoneNumber: string;
        street: string;
        zipCode: string;
    };
    public sepaData = {
        accountHolder: '',
        bic: '',
        iban: ''
    };
    public usersDateOfBirth: DateWrapper;
    public usersDateOfBirthIsSet: boolean;
    public addBankAccount: boolean;

    private _isPrepaid: boolean;
    private _formSubmitDisabled: boolean;
    private _loadingAccountData: boolean;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private $window: ng.IWindowService,
        private accountModel: AccountModelService,
        private alertManager: AlertManagerService,
        private authenticationRobot: AuthenticationRobotService,
        private depositModel: DepositModelService,
        private notificationsHelper: NotificationsHelperService
    ) {
        this.displayInReviewWarning = false;
        this.displaySepaProhibited = false;
        this.usersDateOfBirthIsSet = false;
        this._isPrepaid = false;
        this._formSubmitDisabled = false;
        this._loadingAccountData = true;
    }

    public async $onInit() {
        this.accountData = await this.accountModel.getAccountBasicData();
        this.$timeout(() => {
            this.contactDetails = {
                addition: this.accountData.addition,
                city: this.accountData.city,
                country: this.accountData.country,
                faxNumber: this.accountData.faxNumber,
                phoneNumber: this.accountData.phoneNumber,
                street: this.accountData.street,
                zipCode: this.accountData.zipCode
            };
            this._loadingAccountData = false;
        });

        this._isPrepaid = await this.notificationsHelper.missingSepaData();
    }

    public set showAccountDataPanel({}) {} // tslint:disable-line:no-empty
    public get showAccountDataPanel() {
        return !AuthContextService.isCurrentUserAccountComplete
            && !this.displayInReviewWarning
            && !this.displaySepaProhibited;
    }

    public set showSepaForm({}) {} // tslint:disable-line:no-empty
    public get showSepaForm() {
        return this._isPrepaid;
    }

    public set loadingAccountData({}) {} // tslint:disable-line:no-empty
    public get loadingAccountData() {
        return this._loadingAccountData;
    }

    public set saveButtonDisabled(value) {
        this._formSubmitDisabled = value;
    }
    public get saveButtonDisabled() {
        if (
            this.displayInReviewWarning
            || this.displaySepaProhibited
        ) {
            return true;
        }

        if (
            this._isPrepaid
            && this.addBankAccount
        ) {
            const isIbanGermanCode = this.sepaData.iban.toUpperCase().startsWith('DE');
            switch (true) {
                case !this.usersDateOfBirth:
                case this.sepaData.accountHolder?.length === 0:
                case (
                    this.sepaData.iban?.length < 15
                    || this.sepaData.iban?.length >  32
                ):
                case (
                    !isIbanGermanCode
                    && (
                        this.sepaData.bic?.length < 8
                        || this.sepaData.bic?.length > 11
                    )
                ):
                    return true;
                default:
                    return false;
            }
        }

        if (
            !this.showAccountDataPanel
            && !this.addBankAccount
        ) {
            // Neither account data nor bank details are changed
            return true;
        }

        return this._formSubmitDisabled;
    }

    public updateContactDetails = async () => { // used in template
        if (this.showAccountDataPanel) {
            // Sace account data
            const accountData = ng.copy(this.accountData);

            for (const key of Object.keys(this.contactDetails)) {
                accountData[key] = this.contactDetails[key];
            }

            try {
                await this.accountModel.updateAccount(accountData);
            } catch ({}) { // eslint-disable-line no-empty-pattern
                return 0;
            }

            this.accountData = accountData;
            this.alertManager.success(this.$translate.instant('TR_060219-4befa0_TR'));
        }

        if (!this._isPrepaid || !this.addBankAccount) {
            return this.authenticationRobot.refreshAccountData()
                .then(() => 1);
        }

        if (this.showSepaForm && this._isPrepaid && this.addBankAccount) {
            const iban = this.sepaData.iban.toUpperCase();
            if (iban.startsWith('DE')) {
                delete this.sepaData.bic;
            }

            let billingApiResponse;
            try {
                if (!this.usersDateOfBirthIsSet) {
                    billingApiResponse = await this.depositModel.bankAccountCreate(
                        this.sepaData.iban,
                        this.sepaData.accountHolder,
                        this.sepaData.bic,
                        this.usersDateOfBirth.dateObj
                    );
                } else {
                    billingApiResponse = await this.depositModel.bankAccountCreate(
                        this.sepaData.iban,
                        this.sepaData.accountHolder,
                        this.sepaData.bic
                    );
                }

                if (billingApiResponse.errors.length > 0) {
                    this.displaySepaProhibited = true;

                    return 0;
                }
            } catch ({}) { // eslint-disable-line no-empty-pattern
                return 0;
            }

            const currentTime =  new DateTimeWrapper();
            currentTime.setToday();

            this.$window.localStorage.setItem(
                'paymentMethodHasChanged',
                '' + currentTime.toUnixTimestamp()
            );

            await this.authenticationRobot.refreshAccountData();

            this.displayInReviewWarning = false;

            switch (billingApiResponse.response.status) {
                case undefined:
                    return 0;

                case 'toBeProcessed':
                case 'active':
                    this.alertManager.success(
                        this.$translate.instant('TR_090119-148f94_TR')
                    );

                    return 1;

                case 'rejected':
                    this.alertManager.error(
                        this.$translate.instant('TR_060819-a51ecc_TR')
                    );

                    return 0;

                case 'inReview':
                    this.displayInReviewWarning = true;

                    return 0;

                default:
                    this.alertManager.success(
                        this.$translate.instant('TR_090119-148f94_TR')
                    );

                    return 1;
            }
        }
    };
}

export class OrganViewPanelEditContactDetailsComponent implements ng.IComponentOptions {
    public controller = OrganViewPanelEditContactDetailsController;
    public template = require('./edit-contact-details.html');
}
