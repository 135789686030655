export const Countries: { countryCode: string; tId: string }[] =
[
    { countryCode: 'AC', tId: /* translationId */ 'TR_COUNTRY-CODE-AC_TR' },
    { countryCode: 'AD', tId: /* translationId */ 'TR_COUNTRY-CODE-AD_TR' },
    { countryCode: 'AE', tId: /* translationId */ 'TR_COUNTRY-CODE-AE_TR' },
    { countryCode: 'AF', tId: /* translationId */ 'TR_COUNTRY-CODE-AF_TR' },
    { countryCode: 'AG', tId: /* translationId */ 'TR_COUNTRY-CODE-AG_TR' },
    { countryCode: 'AI', tId: /* translationId */ 'TR_COUNTRY-CODE-AI_TR' },
    { countryCode: 'AL', tId: /* translationId */ 'TR_COUNTRY-CODE-AL_TR' },
    { countryCode: 'AM', tId: /* translationId */ 'TR_COUNTRY-CODE-AM_TR' },
    { countryCode: 'AN', tId: /* translationId */ 'TR_COUNTRY-CODE-AN_TR' },
    { countryCode: 'AO', tId: /* translationId */ 'TR_COUNTRY-CODE-AO_TR' },
    { countryCode: 'AQ', tId: /* translationId */ 'TR_COUNTRY-CODE-AQ_TR' },
    { countryCode: 'AR', tId: /* translationId */ 'TR_COUNTRY-CODE-AR_TR' },
    { countryCode: 'AS', tId: /* translationId */ 'TR_COUNTRY-CODE-AS_TR' },
    { countryCode: 'AT', tId: /* translationId */ 'TR_COUNTRY-CODE-AT_TR' },
    { countryCode: 'AU', tId: /* translationId */ 'TR_COUNTRY-CODE-AU_TR' },
    { countryCode: 'AW', tId: /* translationId */ 'TR_COUNTRY-CODE-AW_TR' },
    { countryCode: 'AX', tId: /* translationId */ 'TR_COUNTRY-CODE-AX_TR' },
    { countryCode: 'AZ', tId: /* translationId */ 'TR_COUNTRY-CODE-AZ_TR' },
    { countryCode: 'BA', tId: /* translationId */ 'TR_COUNTRY-CODE-BA_TR' },
    { countryCode: 'BB', tId: /* translationId */ 'TR_COUNTRY-CODE-BB_TR' },
    { countryCode: 'BD', tId: /* translationId */ 'TR_COUNTRY-CODE-BD_TR' },
    { countryCode: 'BE', tId: /* translationId */ 'TR_COUNTRY-CODE-BE_TR' },
    { countryCode: 'BF', tId: /* translationId */ 'TR_COUNTRY-CODE-BF_TR' },
    { countryCode: 'BG', tId: /* translationId */ 'TR_COUNTRY-CODE-BG_TR' },
    { countryCode: 'BH', tId: /* translationId */ 'TR_COUNTRY-CODE-BH_TR' },
    { countryCode: 'BI', tId: /* translationId */ 'TR_COUNTRY-CODE-BI_TR' },
    { countryCode: 'BJ', tId: /* translationId */ 'TR_COUNTRY-CODE-BJ_TR' },
    { countryCode: 'BL', tId: /* translationId */ 'TR_COUNTRY-CODE-BL_TR' },
    { countryCode: 'BM', tId: /* translationId */ 'TR_COUNTRY-CODE-BM_TR' },
    { countryCode: 'BN', tId: /* translationId */ 'TR_COUNTRY-CODE-BN_TR' },
    { countryCode: 'BO', tId: /* translationId */ 'TR_COUNTRY-CODE-BO_TR' },
    { countryCode: 'BQ', tId: /* translationId */ 'TR_COUNTRY-CODE-BQ_TR' },
    { countryCode: 'BR', tId: /* translationId */ 'TR_COUNTRY-CODE-BR_TR' },
    { countryCode: 'BS', tId: /* translationId */ 'TR_COUNTRY-CODE-BS_TR' },
    { countryCode: 'BT', tId: /* translationId */ 'TR_COUNTRY-CODE-BT_TR' },
    { countryCode: 'BV', tId: /* translationId */ 'TR_COUNTRY-CODE-BV_TR' },
    { countryCode: 'BW', tId: /* translationId */ 'TR_COUNTRY-CODE-BW_TR' },
    { countryCode: 'BY', tId: /* translationId */ 'TR_COUNTRY-CODE-BY_TR' },
    { countryCode: 'BZ', tId: /* translationId */ 'TR_COUNTRY-CODE-BZ_TR' },
    { countryCode: 'CA', tId: /* translationId */ 'TR_COUNTRY-CODE-CA_TR' },
    { countryCode: 'CC', tId: /* translationId */ 'TR_COUNTRY-CODE-CC_TR' },
    { countryCode: 'CD', tId: /* translationId */ 'TR_COUNTRY-CODE-CD_TR' },
    { countryCode: 'CF', tId: /* translationId */ 'TR_COUNTRY-CODE-CF_TR' },
    { countryCode: 'CG', tId: /* translationId */ 'TR_COUNTRY-CODE-CG_TR' },
    { countryCode: 'CH', tId: /* translationId */ 'TR_COUNTRY-CODE-CH_TR' },
    { countryCode: 'CI', tId: /* translationId */ 'TR_COUNTRY-CODE-CI_TR' },
    { countryCode: 'CK', tId: /* translationId */ 'TR_COUNTRY-CODE-CK_TR' },
    { countryCode: 'CL', tId: /* translationId */ 'TR_COUNTRY-CODE-CL_TR' },
    { countryCode: 'CM', tId: /* translationId */ 'TR_COUNTRY-CODE-CM_TR' },
    { countryCode: 'CN', tId: /* translationId */ 'TR_COUNTRY-CODE-CN_TR' },
    { countryCode: 'CO', tId: /* translationId */ 'TR_COUNTRY-CODE-CO_TR' },
    { countryCode: 'CP', tId: /* translationId */ 'TR_COUNTRY-CODE-CP_TR' },
    { countryCode: 'CR', tId: /* translationId */ 'TR_COUNTRY-CODE-CR_TR' },
    { countryCode: 'CS', tId: /* translationId */ 'TR_COUNTRY-CODE-CS_TR' },
    { countryCode: 'CU', tId: /* translationId */ 'TR_COUNTRY-CODE-CU_TR' },
    { countryCode: 'CV', tId: /* translationId */ 'TR_COUNTRY-CODE-CV_TR' },
    { countryCode: 'CW', tId: /* translationId */ 'TR_COUNTRY-CODE-CW_TR' },
    { countryCode: 'CX', tId: /* translationId */ 'TR_COUNTRY-CODE-CX_TR' },
    { countryCode: 'CY', tId: /* translationId */ 'TR_COUNTRY-CODE-CY_TR' },
    { countryCode: 'CZ', tId: /* translationId */ 'TR_COUNTRY-CODE-CZ_TR' },
    { countryCode: 'DE', tId: /* translationId */ 'TR_COUNTRY-CODE-DE_TR' },
    { countryCode: 'DG', tId: /* translationId */ 'TR_COUNTRY-CODE-DG_TR' },
    { countryCode: 'DJ', tId: /* translationId */ 'TR_COUNTRY-CODE-DJ_TR' },
    { countryCode: 'DK', tId: /* translationId */ 'TR_COUNTRY-CODE-DK_TR' },
    { countryCode: 'DM', tId: /* translationId */ 'TR_COUNTRY-CODE-DM_TR' },
    { countryCode: 'DO', tId: /* translationId */ 'TR_COUNTRY-CODE-DO_TR' },
    { countryCode: 'DZ', tId: /* translationId */ 'TR_COUNTRY-CODE-DZ_TR' },
    { countryCode: 'EA', tId: /* translationId */ 'TR_COUNTRY-CODE-EA_TR' },
    { countryCode: 'EC', tId: /* translationId */ 'TR_COUNTRY-CODE-EC_TR' },
    { countryCode: 'EE', tId: /* translationId */ 'TR_COUNTRY-CODE-EE_TR' },
    { countryCode: 'EG', tId: /* translationId */ 'TR_COUNTRY-CODE-EG_TR' },
    { countryCode: 'EH', tId: /* translationId */ 'TR_COUNTRY-CODE-EH_TR' },
    { countryCode: 'ER', tId: /* translationId */ 'TR_COUNTRY-CODE-ER_TR' },
    { countryCode: 'ES', tId: /* translationId */ 'TR_COUNTRY-CODE-ES_TR' },
    { countryCode: 'ET', tId: /* translationId */ 'TR_COUNTRY-CODE-ET_TR' },
    { countryCode: 'EU', tId: /* translationId */ 'TR_COUNTRY-CODE-EU_TR' },
    { countryCode: 'FI', tId: /* translationId */ 'TR_COUNTRY-CODE-FI_TR' },
    { countryCode: 'FJ', tId: /* translationId */ 'TR_COUNTRY-CODE-FJ_TR' },
    { countryCode: 'FK', tId: /* translationId */ 'TR_COUNTRY-CODE-FK_TR' },
    { countryCode: 'FM', tId: /* translationId */ 'TR_COUNTRY-CODE-FM_TR' },
    { countryCode: 'FO', tId: /* translationId */ 'TR_COUNTRY-CODE-FO_TR' },
    { countryCode: 'FR', tId: /* translationId */ 'TR_COUNTRY-CODE-FR_TR' },
    { countryCode: 'GA', tId: /* translationId */ 'TR_COUNTRY-CODE-GA_TR' },
    { countryCode: 'GB', tId: /* translationId */ 'TR_COUNTRY-CODE-GB_TR' },
    { countryCode: 'GD', tId: /* translationId */ 'TR_COUNTRY-CODE-GD_TR' },
    { countryCode: 'GE', tId: /* translationId */ 'TR_COUNTRY-CODE-GE_TR' },
    { countryCode: 'GF', tId: /* translationId */ 'TR_COUNTRY-CODE-GF_TR' },
    { countryCode: 'GG', tId: /* translationId */ 'TR_COUNTRY-CODE-GG_TR' },
    { countryCode: 'GH', tId: /* translationId */ 'TR_COUNTRY-CODE-GH_TR' },
    { countryCode: 'GI', tId: /* translationId */ 'TR_COUNTRY-CODE-GI_TR' },
    { countryCode: 'GL', tId: /* translationId */ 'TR_COUNTRY-CODE-GL_TR' },
    { countryCode: 'GM', tId: /* translationId */ 'TR_COUNTRY-CODE-GM_TR' },
    { countryCode: 'GN', tId: /* translationId */ 'TR_COUNTRY-CODE-GN_TR' },
    { countryCode: 'GP', tId: /* translationId */ 'TR_COUNTRY-CODE-GP_TR' },
    { countryCode: 'GQ', tId: /* translationId */ 'TR_COUNTRY-CODE-GQ_TR' },
    { countryCode: 'GR', tId: /* translationId */ 'TR_COUNTRY-CODE-GR_TR' },
    { countryCode: 'GS', tId: /* translationId */ 'TR_COUNTRY-CODE-GS_TR' },
    { countryCode: 'GT', tId: /* translationId */ 'TR_COUNTRY-CODE-GT_TR' },
    { countryCode: 'GU', tId: /* translationId */ 'TR_COUNTRY-CODE-GU_TR' },
    { countryCode: 'GW', tId: /* translationId */ 'TR_COUNTRY-CODE-GW_TR' },
    { countryCode: 'GY', tId: /* translationId */ 'TR_COUNTRY-CODE-GY_TR' },
    { countryCode: 'HK', tId: /* translationId */ 'TR_COUNTRY-CODE-HK_TR' },
    { countryCode: 'HM', tId: /* translationId */ 'TR_COUNTRY-CODE-HM_TR' },
    { countryCode: 'HN', tId: /* translationId */ 'TR_COUNTRY-CODE-HN_TR' },
    { countryCode: 'HR', tId: /* translationId */ 'TR_COUNTRY-CODE-HR_TR' },
    { countryCode: 'HT', tId: /* translationId */ 'TR_COUNTRY-CODE-HT_TR' },
    { countryCode: 'HU', tId: /* translationId */ 'TR_COUNTRY-CODE-HU_TR' },
    { countryCode: 'IC', tId: /* translationId */ 'TR_COUNTRY-CODE-IC_TR' },
    { countryCode: 'ID', tId: /* translationId */ 'TR_COUNTRY-CODE-ID_TR' },
    { countryCode: 'IE', tId: /* translationId */ 'TR_COUNTRY-CODE-IE_TR' },
    { countryCode: 'IL', tId: /* translationId */ 'TR_COUNTRY-CODE-IL_TR' },
    { countryCode: 'IM', tId: /* translationId */ 'TR_COUNTRY-CODE-IM_TR' },
    { countryCode: 'IN', tId: /* translationId */ 'TR_COUNTRY-CODE-IN_TR' },
    { countryCode: 'IO', tId: /* translationId */ 'TR_COUNTRY-CODE-IO_TR' },
    { countryCode: 'IQ', tId: /* translationId */ 'TR_COUNTRY-CODE-IQ_TR' },
    { countryCode: 'IR', tId: /* translationId */ 'TR_COUNTRY-CODE-IR_TR' },
    { countryCode: 'IS', tId: /* translationId */ 'TR_COUNTRY-CODE-IS_TR' },
    { countryCode: 'IT', tId: /* translationId */ 'TR_COUNTRY-CODE-IT_TR' },
    { countryCode: 'JE', tId: /* translationId */ 'TR_COUNTRY-CODE-JE_TR' },
    { countryCode: 'JM', tId: /* translationId */ 'TR_COUNTRY-CODE-JM_TR' },
    { countryCode: 'JO', tId: /* translationId */ 'TR_COUNTRY-CODE-JO_TR' },
    { countryCode: 'JP', tId: /* translationId */ 'TR_COUNTRY-CODE-JP_TR' },
    { countryCode: 'KE', tId: /* translationId */ 'TR_COUNTRY-CODE-KE_TR' },
    { countryCode: 'KG', tId: /* translationId */ 'TR_COUNTRY-CODE-KG_TR' },
    { countryCode: 'KH', tId: /* translationId */ 'TR_COUNTRY-CODE-KH_TR' },
    { countryCode: 'KI', tId: /* translationId */ 'TR_COUNTRY-CODE-KI_TR' },
    { countryCode: 'KM', tId: /* translationId */ 'TR_COUNTRY-CODE-KM_TR' },
    { countryCode: 'KN', tId: /* translationId */ 'TR_COUNTRY-CODE-KN_TR' },
    { countryCode: 'KP', tId: /* translationId */ 'TR_COUNTRY-CODE-KP_TR' },
    { countryCode: 'KR', tId: /* translationId */ 'TR_COUNTRY-CODE-KR_TR' },
    { countryCode: 'KW', tId: /* translationId */ 'TR_COUNTRY-CODE-KW_TR' },
    { countryCode: 'KY', tId: /* translationId */ 'TR_COUNTRY-CODE-KY_TR' },
    { countryCode: 'KZ', tId: /* translationId */ 'TR_COUNTRY-CODE-KZ_TR' },
    { countryCode: 'LA', tId: /* translationId */ 'TR_COUNTRY-CODE-LA_TR' },
    { countryCode: 'LB', tId: /* translationId */ 'TR_COUNTRY-CODE-LB_TR' },
    { countryCode: 'LC', tId: /* translationId */ 'TR_COUNTRY-CODE-LC_TR' },
    { countryCode: 'LI', tId: /* translationId */ 'TR_COUNTRY-CODE-LI_TR' },
    { countryCode: 'LK', tId: /* translationId */ 'TR_COUNTRY-CODE-LK_TR' },
    { countryCode: 'LR', tId: /* translationId */ 'TR_COUNTRY-CODE-LR_TR' },
    { countryCode: 'LS', tId: /* translationId */ 'TR_COUNTRY-CODE-LS_TR' },
    { countryCode: 'LT', tId: /* translationId */ 'TR_COUNTRY-CODE-LT_TR' },
    { countryCode: 'LU', tId: /* translationId */ 'TR_COUNTRY-CODE-LU_TR' },
    { countryCode: 'LV', tId: /* translationId */ 'TR_COUNTRY-CODE-LV_TR' },
    { countryCode: 'LY', tId: /* translationId */ 'TR_COUNTRY-CODE-LY_TR' },
    { countryCode: 'MA', tId: /* translationId */ 'TR_COUNTRY-CODE-MA_TR' },
    { countryCode: 'MC', tId: /* translationId */ 'TR_COUNTRY-CODE-MC_TR' },
    { countryCode: 'MD', tId: /* translationId */ 'TR_COUNTRY-CODE-MD_TR' },
    { countryCode: 'ME', tId: /* translationId */ 'TR_COUNTRY-CODE-ME_TR' },
    { countryCode: 'MF', tId: /* translationId */ 'TR_COUNTRY-CODE-MF_TR' },
    { countryCode: 'MG', tId: /* translationId */ 'TR_COUNTRY-CODE-MG_TR' },
    { countryCode: 'MH', tId: /* translationId */ 'TR_COUNTRY-CODE-MH_TR' },
    { countryCode: 'MK', tId: /* translationId */ 'TR_COUNTRY-CODE-MK_TR' },
    { countryCode: 'ML', tId: /* translationId */ 'TR_COUNTRY-CODE-ML_TR' },
    { countryCode: 'MM', tId: /* translationId */ 'TR_COUNTRY-CODE-MM_TR' },
    { countryCode: 'MN', tId: /* translationId */ 'TR_COUNTRY-CODE-MN_TR' },
    { countryCode: 'MO', tId: /* translationId */ 'TR_COUNTRY-CODE-MO_TR' },
    { countryCode: 'MP', tId: /* translationId */ 'TR_COUNTRY-CODE-MP_TR' },
    { countryCode: 'MQ', tId: /* translationId */ 'TR_COUNTRY-CODE-MQ_TR' },
    { countryCode: 'MR', tId: /* translationId */ 'TR_COUNTRY-CODE-MR_TR' },
    { countryCode: 'MS', tId: /* translationId */ 'TR_COUNTRY-CODE-MS_TR' },
    { countryCode: 'MT', tId: /* translationId */ 'TR_COUNTRY-CODE-MT_TR' },
    { countryCode: 'MU', tId: /* translationId */ 'TR_COUNTRY-CODE-MU_TR' },
    { countryCode: 'MV', tId: /* translationId */ 'TR_COUNTRY-CODE-MV_TR' },
    { countryCode: 'MW', tId: /* translationId */ 'TR_COUNTRY-CODE-MW_TR' },
    { countryCode: 'MX', tId: /* translationId */ 'TR_COUNTRY-CODE-MX_TR' },
    { countryCode: 'MY', tId: /* translationId */ 'TR_COUNTRY-CODE-MY_TR' },
    { countryCode: 'MZ', tId: /* translationId */ 'TR_COUNTRY-CODE-MZ_TR' },
    { countryCode: 'NA', tId: /* translationId */ 'TR_COUNTRY-CODE-NA_TR' },
    { countryCode: 'NC', tId: /* translationId */ 'TR_COUNTRY-CODE-NC_TR' },
    { countryCode: 'NE', tId: /* translationId */ 'TR_COUNTRY-CODE-NE_TR' },
    { countryCode: 'NF', tId: /* translationId */ 'TR_COUNTRY-CODE-NF_TR' },
    { countryCode: 'NG', tId: /* translationId */ 'TR_COUNTRY-CODE-NG_TR' },
    { countryCode: 'NI', tId: /* translationId */ 'TR_COUNTRY-CODE-NI_TR' },
    { countryCode: 'NL', tId: /* translationId */ 'TR_COUNTRY-CODE-NL_TR' },
    { countryCode: 'NO', tId: /* translationId */ 'TR_COUNTRY-CODE-NO_TR' },
    { countryCode: 'NP', tId: /* translationId */ 'TR_COUNTRY-CODE-NP_TR' },
    { countryCode: 'NR', tId: /* translationId */ 'TR_COUNTRY-CODE-NR_TR' },
    { countryCode: 'NU', tId: /* translationId */ 'TR_COUNTRY-CODE-NU_TR' },
    { countryCode: 'NZ', tId: /* translationId */ 'TR_COUNTRY-CODE-NZ_TR' },
    { countryCode: 'OM', tId: /* translationId */ 'TR_COUNTRY-CODE-OM_TR' },
    { countryCode: 'PA', tId: /* translationId */ 'TR_COUNTRY-CODE-PA_TR' },
    { countryCode: 'PE', tId: /* translationId */ 'TR_COUNTRY-CODE-PE_TR' },
    { countryCode: 'PF', tId: /* translationId */ 'TR_COUNTRY-CODE-PF_TR' },
    { countryCode: 'PG', tId: /* translationId */ 'TR_COUNTRY-CODE-PG_TR' },
    { countryCode: 'PH', tId: /* translationId */ 'TR_COUNTRY-CODE-PH_TR' },
    { countryCode: 'PK', tId: /* translationId */ 'TR_COUNTRY-CODE-PK_TR' },
    { countryCode: 'PL', tId: /* translationId */ 'TR_COUNTRY-CODE-PL_TR' },
    { countryCode: 'PM', tId: /* translationId */ 'TR_COUNTRY-CODE-PM_TR' },
    { countryCode: 'PN', tId: /* translationId */ 'TR_COUNTRY-CODE-PN_TR' },
    { countryCode: 'PR', tId: /* translationId */ 'TR_COUNTRY-CODE-PR_TR' },
    { countryCode: 'PS', tId: /* translationId */ 'TR_COUNTRY-CODE-PS_TR' },
    { countryCode: 'PT', tId: /* translationId */ 'TR_COUNTRY-CODE-PT_TR' },
    { countryCode: 'PW', tId: /* translationId */ 'TR_COUNTRY-CODE-PW_TR' },
    { countryCode: 'PY', tId: /* translationId */ 'TR_COUNTRY-CODE-PY_TR' },
    { countryCode: 'QA', tId: /* translationId */ 'TR_COUNTRY-CODE-QA_TR' },
    { countryCode: 'QO', tId: /* translationId */ 'TR_COUNTRY-CODE-QO_TR' },
    { countryCode: 'RE', tId: /* translationId */ 'TR_COUNTRY-CODE-RE_TR' },
    { countryCode: 'RO', tId: /* translationId */ 'TR_COUNTRY-CODE-RO_TR' },
    { countryCode: 'RS', tId: /* translationId */ 'TR_COUNTRY-CODE-RS_TR' },
    { countryCode: 'RU', tId: /* translationId */ 'TR_COUNTRY-CODE-RU_TR' },
    { countryCode: 'RW', tId: /* translationId */ 'TR_COUNTRY-CODE-RW_TR' },
    { countryCode: 'SA', tId: /* translationId */ 'TR_COUNTRY-CODE-SA_TR' },
    { countryCode: 'SB', tId: /* translationId */ 'TR_COUNTRY-CODE-SB_TR' },
    { countryCode: 'SC', tId: /* translationId */ 'TR_COUNTRY-CODE-SC_TR' },
    { countryCode: 'SD', tId: /* translationId */ 'TR_COUNTRY-CODE-SD_TR' },
    { countryCode: 'SE', tId: /* translationId */ 'TR_COUNTRY-CODE-SE_TR' },
    { countryCode: 'SG', tId: /* translationId */ 'TR_COUNTRY-CODE-SG_TR' },
    { countryCode: 'SH', tId: /* translationId */ 'TR_COUNTRY-CODE-SH_TR' },
    { countryCode: 'SI', tId: /* translationId */ 'TR_COUNTRY-CODE-SI_TR' },
    { countryCode: 'SJ', tId: /* translationId */ 'TR_COUNTRY-CODE-SJ_TR' },
    { countryCode: 'SK', tId: /* translationId */ 'TR_COUNTRY-CODE-SK_TR' },
    { countryCode: 'SL', tId: /* translationId */ 'TR_COUNTRY-CODE-SL_TR' },
    { countryCode: 'SM', tId: /* translationId */ 'TR_COUNTRY-CODE-SM_TR' },
    { countryCode: 'SN', tId: /* translationId */ 'TR_COUNTRY-CODE-SN_TR' },
    { countryCode: 'SO', tId: /* translationId */ 'TR_COUNTRY-CODE-SO_TR' },
    { countryCode: 'SR', tId: /* translationId */ 'TR_COUNTRY-CODE-SR_TR' },
    { countryCode: 'SS', tId: /* translationId */ 'TR_COUNTRY-CODE-SS_TR' },
    { countryCode: 'ST', tId: /* translationId */ 'TR_COUNTRY-CODE-ST_TR' },
    { countryCode: 'SV', tId: /* translationId */ 'TR_COUNTRY-CODE-SV_TR' },
    { countryCode: 'SX', tId: /* translationId */ 'TR_COUNTRY-CODE-SX_TR' },
    { countryCode: 'SY', tId: /* translationId */ 'TR_COUNTRY-CODE-SY_TR' },
    { countryCode: 'SZ', tId: /* translationId */ 'TR_COUNTRY-CODE-SZ_TR' },
    { countryCode: 'TA', tId: /* translationId */ 'TR_COUNTRY-CODE-TA_TR' },
    { countryCode: 'TC', tId: /* translationId */ 'TR_COUNTRY-CODE-TC_TR' },
    { countryCode: 'TD', tId: /* translationId */ 'TR_COUNTRY-CODE-TD_TR' },
    { countryCode: 'TF', tId: /* translationId */ 'TR_COUNTRY-CODE-TF_TR' },
    { countryCode: 'TG', tId: /* translationId */ 'TR_COUNTRY-CODE-TG_TR' },
    { countryCode: 'TH', tId: /* translationId */ 'TR_COUNTRY-CODE-TH_TR' },
    { countryCode: 'TJ', tId: /* translationId */ 'TR_COUNTRY-CODE-TJ_TR' },
    { countryCode: 'TK', tId: /* translationId */ 'TR_COUNTRY-CODE-TK_TR' },
    { countryCode: 'TL', tId: /* translationId */ 'TR_COUNTRY-CODE-TL_TR' },
    { countryCode: 'TM', tId: /* translationId */ 'TR_COUNTRY-CODE-TM_TR' },
    { countryCode: 'TN', tId: /* translationId */ 'TR_COUNTRY-CODE-TN_TR' },
    { countryCode: 'TO', tId: /* translationId */ 'TR_COUNTRY-CODE-TO_TR' },
    { countryCode: 'TR', tId: /* translationId */ 'TR_COUNTRY-CODE-TR_TR' },
    { countryCode: 'TT', tId: /* translationId */ 'TR_COUNTRY-CODE-TT_TR' },
    { countryCode: 'TV', tId: /* translationId */ 'TR_COUNTRY-CODE-TV_TR' },
    { countryCode: 'TW', tId: /* translationId */ 'TR_COUNTRY-CODE-TW_TR' },
    { countryCode: 'TZ', tId: /* translationId */ 'TR_COUNTRY-CODE-TZ_TR' },
    { countryCode: 'UA', tId: /* translationId */ 'TR_COUNTRY-CODE-UA_TR' },
    { countryCode: 'UG', tId: /* translationId */ 'TR_COUNTRY-CODE-UG_TR' },
    { countryCode: 'UM', tId: /* translationId */ 'TR_COUNTRY-CODE-UM_TR' },
    { countryCode: 'US', tId: /* translationId */ 'TR_COUNTRY-CODE-US_TR' },
    { countryCode: 'UY', tId: /* translationId */ 'TR_COUNTRY-CODE-UY_TR' },
    { countryCode: 'UZ', tId: /* translationId */ 'TR_COUNTRY-CODE-UZ_TR' },
    { countryCode: 'VA', tId: /* translationId */ 'TR_COUNTRY-CODE-VA_TR' },
    { countryCode: 'VC', tId: /* translationId */ 'TR_COUNTRY-CODE-VC_TR' },
    { countryCode: 'VE', tId: /* translationId */ 'TR_COUNTRY-CODE-VE_TR' },
    { countryCode: 'VG', tId: /* translationId */ 'TR_COUNTRY-CODE-VG_TR' },
    { countryCode: 'VI', tId: /* translationId */ 'TR_COUNTRY-CODE-VI_TR' },
    { countryCode: 'VN', tId: /* translationId */ 'TR_COUNTRY-CODE-VN_TR' },
    { countryCode: 'VU', tId: /* translationId */ 'TR_COUNTRY-CODE-VU_TR' },
    { countryCode: 'WF', tId: /* translationId */ 'TR_COUNTRY-CODE-WF_TR' },
    { countryCode: 'WS', tId: /* translationId */ 'TR_COUNTRY-CODE-WS_TR' },
    { countryCode: 'XK', tId: /* translationId */ 'TR_COUNTRY-CODE-XK_TR' },
    { countryCode: 'YE', tId: /* translationId */ 'TR_COUNTRY-CODE-YE_TR' },
    { countryCode: 'YT', tId: /* translationId */ 'TR_COUNTRY-CODE-YT_TR' },
    { countryCode: 'ZA', tId: /* translationId */ 'TR_COUNTRY-CODE-ZA_TR' },
    { countryCode: 'ZM', tId: /* translationId */ 'TR_COUNTRY-CODE-ZM_TR' },
    { countryCode: 'ZW', tId: /* translationId */ 'TR_COUNTRY-CODE-ZW_TR' }
];
