import * as ng from 'angular';
import { SelectedProduct } from '../../../../../services';
import { WizardHelperService } from '../../../wizard';

export class MoleculeRowSelectedProductFactsheetController {
    public static $inject: string[] = ['wizardHelper'];

    public productFactsheetTitle: null | string = null;
    public productFactsheetValue: null | string = null;
    public factsheetExists = false;

    private _category: string;
    private _selectedProduct: SelectedProduct;

    constructor(
        private wizardHelper: WizardHelperService
    ) {}

    public $onInit() {
        this.getProductFactTitle();
        this.getProductFactValue();

        this.factsheetExists = this.productFactsheetTitle !== null
            && this.productFactsheetValue !== null;
    }

    public getProductFactTitle = () => {
        const productLabelObject = this._getFactSheetDescription();

        if (productLabelObject === null) {
            return null;
        }

        const productFactsheetLabelObject = this.wizardHelper.getFactsheetTranslatedDescription(
            this._selectedProduct.keywordsDescriptions,
            productLabelObject
        );

        this.productFactsheetTitle = productFactsheetLabelObject.name;
        return productFactsheetLabelObject;
    };

    public getProductFactValue = () => {
        const productFactsheetObject = this._getFactSheet();
        const productLabelObject = this._getFactSheetDescription();

        if (productFactsheetObject === null
            || productLabelObject === null
        ) {
            return null;
        }

        const productFactsheetLabelObject = this.wizardHelper.getFactsheetTranslatedDescription(
            this._selectedProduct.keywordsDescriptions,
            this._getFactSheetDescription()
        );

        this.productFactsheetValue = this.wizardHelper.getFactValueTranslation(
            productFactsheetLabelObject,
            productFactsheetObject
        );

        return this.productFactsheetValue;
    };

    private _getFactSheet = () => {
        let factsheet = null;
        this._selectedProduct.factSheetItems.some((item) => {
            if (item.category === this._category) {
                factsheet = item;
                return true;
            }

            return false;
        });

        return factsheet;
    };

    private _getFactSheetDescription = () => {
        let titleDescription = null;
        this._selectedProduct.keywordsDescriptions.some((description) => {
            if (description.category === this._category) {
                titleDescription = description;
                return true;
            }

            return false;
        });

        return titleDescription;
    };
}

export class MoleculeRowSelectedProductFactsheetComponent implements ng.IComponentOptions {
    public bindings = {
        _category: '@category',
        _selectedProduct: '<selectedProduct'
    };

    public controller = MoleculeRowSelectedProductFactsheetController;
    // tslint:disable-next-line
    public template = require('./product-factsheet.html');
}
