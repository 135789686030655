import * as ng from 'angular';
import { InstallerModelService } from '../../../../services';

export interface DefaultRedirectionParams {
    webspaceId: string;
    vHostId: string;
}

export interface BundleRedirectionParams {
    bundleId: string;
    domainId: string;
    vhost: string;
}

export class MoleculePanelAppInstallConfirmationController implements ng.IController {
    public static $inject: string[] = ['$state', 'installerModel'];

    public title: string;
    public redirectionTarget: string;
    public redirectionParams: DefaultRedirectionParams | BundleRedirectionParams;
    public calloutType: string;
    public selectedApp: any;
    public installedApp: string;
    public source: string;

    constructor(
        private $state: ng.ui.IStateService,
        public installerModel: InstallerModelService
    ) {}

    public $onInit() {
        const stateParams = this.$state.$current.locals.globals.$stateParams;
        if (stateParams.bundleId) {
            this.redirectionParams = {
                bundleId: stateParams.bundleId,
                domainId: stateParams.domainId,
                vhost: stateParams.vhost
            };
        } else {
            this.redirectionParams = {
                webspaceId: stateParams.webspaceId,
                vHostId: stateParams.vHostId
            };
        }

        this.installedApp = typeof(this.selectedApp.data.provides.info.name) === 'string'
            ? this.selectedApp.data.provides.info.name
            : '';
    }
}

export class MoleculePanelAppInstallConfirmationComponent implements ng.IComponentOptions {
    public bindings = {
        calloutType: '@',
        redirectionTarget: '<',
        selectedApp: '<',
        title: '@'
    };
    public controller =  MoleculePanelAppInstallConfirmationController;
    public template = require('./panel-app-install-confirmation.html');
}
