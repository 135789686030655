/**
 * @constructor
 * @param message Description of the error.
 */
function EncodingError(message: string) {
    this.name = 'EncodingError';
    this.message = message;
    this.code = 0;
}
EncodingError.prototype = Error.prototype;

/**
 * @param codePoint The code point that could not be encoded.
 * @return Always throws, no value is actually returned.
 */
function encoderError(codePoint: number): number {
    throw new EncodingError(`The code point ${codePoint} could not be encoded.`);
}

const EOFByte = -1;
const EOFCodePoint = -1;

/**
 * @param a The number to test.
 * @param min The minimum value in the range, inclusive.
 * @param max The maximum value in the range, inclusive.
 * @return True if a >= min and a <= max.
 */
function inRange(a: number, min: number, max: number): boolean {
    return min <= a && a <= max;
}

/**
 * @param {number} numerator The numerator.
 * @param {number} denominator The denominator.
 * @return {number} The result of the integer division of n by d.
 */
function div(numerator: number, denominator: number): number {
    return Math.floor(numerator / denominator);
}

function UTF8Encoder() {
    /**
     * @param output_byte_stream Output byte stream.
     * @param code_point_pointer Input stream.
     * @return The last byte emitted.
     */
    this.encode = (outputByteStream: ByteOutputStream, codePointPointer: CodePointInputStream): number => {
        const codePoint: number = codePointPointer.get();

        if (codePoint === EOFCodePoint) {
            return EOFByte;
        }

        codePointPointer.offset(1);

        if (inRange(codePoint, 0xD800, 0xDFFF)) {
            return encoderError(codePoint);
        }

        if (inRange(codePoint, 0x0000, 0x007f)) {
            return outputByteStream.emit(codePoint);
        }

        let count;
        let offset;

        if (inRange(codePoint, 0x0080, 0x07FF)) {
            count = 1;
            offset = 0xC0;
        } else if (inRange(codePoint, 0x0800, 0xFFFF)) {
            count = 2;
            offset = 0xE0;
        } else if (inRange(codePoint, 0x10000, 0x10FFFF)) {
            count = 3;
            offset = 0xF0;
        }

        let result = outputByteStream.emit(
            div(codePoint, Math.pow(64, count)) + offset);

        while (count > 0) {
            const temp = div(codePoint, Math.pow(64, count - 1));
            result = outputByteStream.emit(0x80 + (temp % 64));
            count -= 1;
        }

        return result;
    };
}

const utf8Encoding = {
    getEncoder: () => new UTF8Encoder(),
    name: 'utf-8'
};

class ByteOutputStream {
    private pos = 0;

    constructor(private bytes) {}

    public emit = (...args: any[]) => {
        let last: number = EOFByte;
        let i;

        for (i = 0; i < args.length; ++i) {
            last = Number(args[i]);
            this.bytes[this.pos++] = last;
        }

        return last;
    };
}

class CodePointInputStream {
    private static stringToCodePoints(input) {
        const cps: number[] = [];

        // Based on http://www.w3.org/TR/WebIDL/#idl-DOMString
        let i = 0;
        const n = input.length;

        while (i < input.length) {
            /* tslint:disable:no-bitwise */

            const c = input.charCodeAt(i);
            if (!inRange(c, 0xD800, 0xDFFF)) {
                cps.push(c);
            } else if (inRange(c, 0xDC00, 0xDFFF)) {
                cps.push(0xFFFD);
            } else { // (inRange(cu, 0xD800, 0xDBFF))
                if (i === n - 1) {
                    cps.push(0xFFFD);
                } else {
                    const d = input.charCodeAt(i + 1);
                    if (inRange(d, 0xDC00, 0xDFFF)) {
                        const a = c & 0x3FF;
                        const b = d & 0x3FF;
                        i += 1;
                        cps.push(0x10000 + (a << 10) + b);
                    } else {
                        cps.push(0xFFFD);
                    }
                }
            }

            i += 1;

            /* tslint:enable:no-bitwise */
        }
        return cps;
    }

    private pos = 0;
    private cps: number[];

    /**
     * @param {string} string The source of code units for the stream.
     */
    constructor(input) {
        this.cps = CodePointInputStream.stringToCodePoints(input);
    }

    /**
     * @param n The number of bytes (positive or negative)to advance the code point pointer by.
     */
    public offset = (n: number) => {
        this.pos += n;

        if (this.pos < 0) {
            throw new Error('Seeking past start of the buffer');
        }

        if (this.pos > this.cps.length) {
            throw new Error('Seeking past EOF');
        }
    };

    public get = (): number => {
        if (this.pos >= this.cps.length) {
            return EOFCodePoint;
        }

        return this.cps[this.pos];
    };
}

export class UTF8TextEncoder {
    private _encoding = utf8Encoding;
    private _streaming = false;
    private _encoder = null;

    public get encoding() {
        return 'utf-8';
    }

    public encode(optString, options?) {
        optString = optString ? String(optString) : '';
        options = Object(options);

        this._encoder = this._encoding.getEncoder();

        this._streaming = Boolean(options.stream);

        const bytes = [];
        const outputStream = new ByteOutputStream(bytes);
        const inputStream = new CodePointInputStream(optString);

        while (inputStream.get() !== EOFCodePoint) {
            this._encoder.encode(outputStream, inputStream);
        }

        if (!this._streaming) {
            let lastByte: number;

            do {
                lastByte = this._encoder.encode(outputStream, inputStream);
            } while (lastByte !== EOFByte);

            this._encoder = null;
        }

        return new Uint8Array(bytes);
    }
}
