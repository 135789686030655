import ng from 'angular';

import { PageHeaderData } from '@/atomic-components/organisms';
import { DisableFilter } from '@/atomic-components/organisms/overview';
import { UserSettingsManagerService } from '@/services';
import * as Types from '@/types';

export class TemplateDomainOverviewController {
    public static $inject: string[] = ['$state', '$translate', 'userSettingsManager'];

    public pageHeaderData: PageHeaderData;
    public disableFilter: DisableFilter;
    public bundle;
    public webspace;
    public overviewServiceData;
    public expertView: boolean;
    public isLegacyPhpOverview = false;
    public faqArticleIdList: string[] = [
        'domain-handle-definition',
        'domain-confirm-email',
        'domain-handle-change'
    ];

    public overviewModel;
    public contact: Types.DomainApi.Contact = null;

    private simpleFilter;
    private extendedFilters;
    private overviewUiSettings;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private userSettingsManager: UserSettingsManagerService
    ) {}

    public $onInit(): void {
        if ([undefined, null].indexOf(this.simpleFilter) === -1) {
            this.expertView = this.overviewUiSettings.expertView || this.simpleFilter.show;
        } else {
            this.expertView = this.overviewUiSettings.expertView || false;
        }

        const bundle = this.bundle === undefined ? null : this.bundle;

        this.pageHeaderData = {
            backwardLink: '',
            backwardText: '',
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: 'globe',
            panelTitle: [undefined, null].indexOf(this.contact) >= 0
                ? this.$translate.instant('TR_080119-acb35a_TR')
                : this.$translate.instant(
                    /* translationId */ 'TR_250619-048d23_TR',
                    { handleName: this.contact.name, handleId: this.contact.handle }
                )
        };

        switch (this.$state.current.name) {
            default:
            case 'domain.domains.overview':
                this.pageHeaderData.backwardText = this.$translate.instant('TR_110119-774118_TR');
                this.pageHeaderData.backwardLink = 'domain.dashboard';
                this.pageHeaderData.panelHeaderRouteParams = null;
                break;
            case 'bundle.id.domains.overview':
                this.pageHeaderData.backwardText = this.$translate.instant('TR_090119-a32485_TR');
                this.pageHeaderData.backwardLink = 'bundle.id.dashboard';
                this.pageHeaderData.panelHeaderRouteParams = { bundleId: bundle.id };
                break;
            case 'domain.contacts.id.domains.overview':
                this.pageHeaderData.backwardText = this.$translate.instant('TR_110119-a67974_TR');
                this.pageHeaderData.backwardLink = 'domain.contacts.id.edit';
                this.pageHeaderData.panelHeaderRouteParams = null;
                break;
        }

        // Surpress backlink to dashboard on expert view because it auto-redirects to overview.
        void this.userSettingsManager.getUiSettings().then(
            (result) => {
                if (result?.overviewSettings?.DomainOverviewCtrl?.expertView) {
                    this.pageHeaderData.backwardText = '';
                    this.pageHeaderData.backwardLink = '';
                    this.pageHeaderData.panelHeaderRouteParams = null;
                }
            }
        );

        if (this.isLegacyPhpOverview) {
            this.pageHeaderData.panelTitle = this.$translate.instant('TR_060123-d86d08_TR');
        }

        this.overviewServiceData = {
            additionData: {
                bundle: this.bundle,
                disableFilter: this.disableFilter,
                webspace: this.webspace
            },
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter
        };
    }

    public expandWrapper = (toggle: { expand: boolean }): void => {
        this.expertView = toggle.expand;
    };
}

export class TemplateDomainOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<?',
        contact: '<?',
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<',
        webspace: '<?',
        isLegacyPhpOverview: '<?'
    };
    public controller = TemplateDomainOverviewController;
    public controllerAs = 'TemplateDomainOverviewCtrl';
    public template = require('./domain-overview-template.html');
}
