import * as ng from 'angular';
import * as q from 'q';

import { DEFAULT_TIMEOUT_REFRESH_INTERVAL, JobStatusCategories } from '@/configuration';
import {
    ApiError,
    ApiErrorModel,
    ApiErrorObject,
    ApiExpectedErrorList,
    ErrorMapperService,
    OrchestratorRobotService,
    SentryErrorEmitterService,
} from '@/services';
import { Reducer } from '@/services/helpers/property-reducer';
import { ViewTypes } from '@/types/view-types';

export type WizardObjectType = 'Bundle'
                            |  'CatchAll'
                            |  'Database'
                            |  'DatabaseUser'
                            |  'Domain'
                            |  'ExchangeMailbox'
                            |  'Forwarder'
                            |  'ImapMailbox'
                            |  'MailingList'
                            |  'DynamicDnsHostname'
                            |  'DynamicDnsCredentials'
                            |  'Nextcloud'
                            |  'Organization'
                            |  'Subaccount'
                            |  'SmtpForwarder'
                            |  'Redirection'
                            |  'User'
                            |  'Vhost'
                            |  'VirtualMachine'
                            |  'Webspace'
                            |  'WebspaceUser'
                            |  'Zone';

export interface WizardCreateObject {
    callback: any;                      /* PromiseLike<any>; */ // Order request.
    serverTransactionId?: any;          // should be string, or?
    objectType: string;                 // Nextcloud, Database etc.
    objectSubType?: string;             // CloudServer, ManagedServer, eCommerceServer (optional)
    labelText: string;                  // Overview description.
    children?: WizardCreateObject[];    // Requests that need to wait.
    result?: any;                       // Resulting response.
    error?: ApiError;                   // Possible errors.
    additionalFlags?: any;
}

export interface NextStepsObject {
    iconName: string;
    labelText?: string;
    linkText: string;
    route: string;
    routeOptions: {
        reload: boolean;
    };
    routeParams: {};
    infoCalloutText?: string;
}

export abstract class WizardConfirmationBase {
    public static $inject: string[] =  [
        '$timeout',
        '$translate',
        'apiErrorModel',
        'errorMapper',
        'localInterval',
        'orchestratorRobot'
    ];

    public apiErrorList: ApiErrorObject[] = [];
    public callbackAfterFinishedRequests: () => any;
    public createRequestResults: any;
    public createRequests: WizardCreateObject[] = null;
    public expectedErrorList: ApiExpectedErrorList[] = [];
    public isLoading = false;
    public knownJobType = [
        'Bundle',
        'CatchAll',
        'Database',
        'DatabaseUser',
        'Domain',
        'DynamicDnsHostname',
        'DynamicDnsCredentials',
        'ExchangeMailbox',
        'Forwarder',
        'ImapMailbox',
        'MailingList',
        'Nextcloud',
        'Organization',
        'Redirection',
        'SmtpForwarder',
        'Subaccount',
        'User',
        'Vhost',
        'VirtualMachine',
        'Webspace',
        'WebspaceUser',
        'Zone'
    ];
    public nextSteps: NextStepsObject[] = [];
    public checkJobStatusIntervalHandler: ng.IPromise<any>; // Reference to job updater interval.
    public useOrchestrationService = false;
    public RequestIntervalMs: number = DEFAULT_TIMEOUT_REFRESH_INTERVAL; // Time between updates.
    public selectedProductFamily: string;
    public orchestrationJobId: string;
    public orchestrationJobs: ViewTypes.OrchestrationJob[] = [];
    public orchestrationJobsGroups: { GroupName: string; GroupJobs: ViewTypes.OrchestrationJob[]}[];
    public isJobDependendWizard = true;
    public _intervalIsActiv = false;
    public ignoreErrorCodesInErrorPanel: any[] = [];
    public fillNextSteps: () => any;

    constructor(
        protected $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        protected apiErrorModel: ApiErrorModel,
        protected errorMapper: ErrorMapperService,
        protected localInterval: ng.IIntervalService,
        protected orchestratorRobot: OrchestratorRobotService
    ) {}

    public startOrderProcess = (jobRequests: WizardCreateObject[]) => {
        // reset class order data
        this._resetInternalState();
        // set createRequest
        this.createRequests = jobRequests;
        // run creating process
        this.runCreateRequest(jobRequests);

        if (this.isJobDependendWizard) {
            this._startJobCheckInterval();
        }

        if (this.fillNextSteps !== undefined) {
            this.fillNextSteps();
        }
    };

    public _startJobCheckInterval = () => {
        // start job check interval
        this._intervalIsActiv = true;
        if (this.useOrchestrationService) {
            // Is orchestration-object.
            this._updateOrchestrationJobs();
            this.checkJobStatusIntervalHandler = this.localInterval(
                this._updateOrchestrationJobs,
                this.RequestIntervalMs
            );
        } else {
            this.checkJobStatus();
            this.checkJobStatusIntervalHandler = this.localInterval(
                this.checkJobStatus,
                this.RequestIntervalMs
            );
        }
    };

    // Handle the topmost entry in the jobs-list and walk its children.
    public runCreateRequest = (jobRequests: WizardCreateObject[], parentArgs?: any) => {
        for (const job of jobRequests) {
            if (job.result && this.jobAborted(job)) {
                continue;
            }
            try {
                job.callback(parentArgs).then(
                    (jobResponse: any) => {
                        if (jobResponse?.code) {
                            // Something has failed on request
                            this.abortAllChildrenJobs(job);
                            job.error = jobResponse;
                            job.result = { status: 'error' };

                            if (jobRequests.length === 1) {
                                this.checkJobStatus();
                            }
                            return;
                        }

                        job.result = jobResponse.response || jobResponse;
                        job.serverTransactionId = jobResponse?.metadata?.serverTransactionId;
                        if (job.objectType === 'Subaccount') {
                            /**
                             *  Created subaccount object has not parameter status.
                             *  So, let set this manual to 'pending'
                             *  Subaccount object status will be set in function checkJobStatus
                             *  to 'successfull'
                             *  We could actually set the status 'successfull' because creating a
                             *  new subaccount is syncron, but I think it is nicer if the status
                             *  changes optically in the confirm view from being 'created' to 'successfully' created.
                             */
                            job.result.status = 'pending';
                        }

                        if (job.objectSubType && job.objectSubType === 'eCommerceServer') {
                            // eCommerce Servers take way to long to be created so as soon as the Orchestration API
                            // checks are finished we flag the job as successful, and tell the user to wait ;)
                            if (jobResponse.status === 'pending') {
                                job.result = {
                                    status: 'successful'
                                };
                            }
                        }

                        if (job.children?.length > 0) {
                            return this.runCreateRequest(job.children, jobResponse);
                        }

                        if (this.createRequests.length === 1
                            && (
                                this.createRequests[0].children === undefined
                                || this.createRequests[0].children.length === 0
                            )

                        ) {
                            // only one request without children objects, start checkJobStatus manual
                            this.checkJobStatus();
                        }
                    },
                    (error: ApiError) => {
                        this.abortAllChildrenJobs(job);
                        job.error = error;
                        job.result = { status: 'error' };

                        if (jobRequests.length === 1) {
                            this.checkJobStatus();
                        }
                    }
                );
            }
            catch (error) {
                SentryErrorEmitterService.sendSentryReport(
                    error,
                    { error: error },
                    { service: "confirmation wizard", file: "wizard-confirmation-base.ts"}
                );
                this.abortAllChildrenJobs(job);

                job.error = error;
                job.result = {
                    status: 'error',
                    errorObject: error
                };

                if (jobRequests.length === 1) {
                    this.checkJobStatus();
                }

            }
        }
    };

    public hasRunningJobs = (createResponses: any) => {
        if (createResponses === undefined) {
            return;
        }
        return createResponses.some((response: any) => {
            if (response === undefined || response.result === undefined) {
                return false;
            }
            if (Array.isArray(response.result)) {
                return response.result.some((result: any) => {
                    return this.jobStatusIsRunning(result);
                });
            }

            return this.jobStatusIsRunning(response.result);
        });
    };

    public checkJobStatus = () => {
        if (this.isJobDependendWizard) {
            const jobStatusFinished = [];
            const flatJobs = this.flattenedJobs(this.createRequests);

            for (const job of flatJobs) {
                jobStatusFinished.push(this.checkJobResult(job));
            }

            void q.all(jobStatusFinished).then((allfinished) => {
                if (allfinished.every((status => status))) {
                    this.cancelJobInterval();
                }
            });
        }
    };

    public checkJobResult = (job: WizardCreateObject) => {
        // Cancel the request interval, if API error on request
        // happened or as soon as one job is inSupport and show Api Error (Panel)
        if (job.error || this.someJobInSupport(job)) {
            const issetGeneralErrorHint = this.apiErrorList.some((error) => {
                return error.code === 0;
            });

            if (!issetGeneralErrorHint) {
                const errObj = this.someJobInSupport(job)
                    ? 'Objectstatus: support' // no server transaction id given here
                    : `Error Code ${Reducer.get(this.createRequestResults, [0, 'serverTransactionId'])}`;
                // should be done in the instancing class
                this.apiErrorList = [{
                    value: '',
                    code: 0,
                    contextObject: errObj,
                    contextPath: '',
                    details: [],
                    text: this.$translate.instant('71270781-9f80-4ec1-a763-b3959e134a54')
                }];
            }

            return q.resolve(true);
        }

        if (this.isJobFinished(job)) {
            return q.resolve(true);
        }

        // Check which findJobs function is needed.
        const isKnownJobType = this.knownJobType.indexOf(job.objectType) >= 0;
        if (!isKnownJobType) {
            /**
             *  This should or may not happen, because the localInterval will never be finished.
             *  If it should happen, the corresponding programmer has forgotten to define the objectType of the request.
             *  We are throwing a Sentry mistake here.
             */
            // todo: throw sentry error

            throw new Error(`${job.objectType} unknown job type!`);
        }

        // Send job-status requests as long as its in status `inProgress` or `creating`.
        return this.findJobsByObjectType(
            job.objectType,
            this._getObjectIdForJobLookup(job),
            (this.RequestIntervalMs / 2)
        ).then(
            (apiResponse: any) => {
                if (job.objectType === 'Subaccount') {
                    /**
                     *  Subaccount is a synchronous process. Since it seems (Docker) that the right
                     *  for account jobs is not generally assigned for most accounts (BackEnd couldn't
                     *  give me an answer to this question), we set the status manual to active at this
                     *  point, since the response is already available here - the account is already created.
                     */
                    job.result.status = 'successful';
                    return true;
                } else {
                    // Normalize job response. Depending on the service, different responses can come back here
                    const jobResponse = apiResponse.data === undefined
                        ? apiResponse
                        : {
                            response: {
                                data: apiResponse.data
                            }
                        };

                    if (
                        jobResponse.response
                        && jobResponse.response.data
                        && jobResponse.response.data.length > 0
                    ) {
                        // Find all jobs running for this object type.
                        if (Array.isArray(job.result)) {
                            // Note: jobResult can also be an array of results
                            const allFinished = job.result.every((jobResult) => {
                                jobResponse.response.data.some((jobRes: any) => {
                                    if (jobRes.objectId === jobResult.id) {
                                        jobResult.status = jobRes.status;
                                        return true;
                                    }

                                    return false;
                                });
                                this.isJobFinished(jobResult);
                            });

                            return allFinished;
                        } else {
                            if (job.result === undefined) {
                                job.result = {};
                            }
                            job.result.status = jobResponse.response.data[0].status;
                            if (jobResponse.response.data[0].errors?.length > 0) {
                                job.error = jobResponse.response.data[0].errors[0];
                                job.error.message = this.errorMapper.has(job.error.code)
                                    ? this.errorMapper.map(job.error)
                                    : jobResponse.response.data[0].errors[0].text;
                            }

                            return this.isJobFinished(job);
                        }
                    }

                    // No response data available
                    return false;
                }
            }
        );
    };

    public abortAllChildrenJobs = (job: any) => {
        for (const childrenJob of job.children) {
            if (!childrenJob.result) {
                if (Array.isArray(childrenJob.result)) {
                    childrenJob.result.map((result: any, i: number) => childrenJob.result[i] = { status: 'abort' });
                } else {
                    childrenJob.result = { status: 'abort' };
                }
            } else {
                // ToDo: What happens, if request has a response with pending, creating etc. result .... ??
            }
        }
    };

    public isJobFinished = (job: any) => {
        if (job.result === undefined) {
            return false;
        }

        if (Array.isArray(job.result)) {
            // if more than one object is creating
            return job.result.every(
                (jobStatus: any) => {
                    return this.jobStatusIsSuccessful(jobStatus)
                        || this.jobStatusIsFailed(jobStatus)
                        || this.jobAborted(jobStatus);
                }
            );
        } else {
            return this.jobStatusIsSuccessful(job.result)
                || this.jobStatusIsFailed(job.result)
                || this.jobAborted(job.result);
        }
    };

    public someJobInSupport = (job: any) => {
        if (Array.isArray(job)) {
            return job.some((jobStatus: any) => {
                return JobStatusCategories.support.indexOf(jobStatus.result.status) >= 0;
            });
        } else if (job.result !== undefined && Array.isArray(job.result)) {
            return job.result.some((jobStatus: any) => JobStatusCategories.support.indexOf(jobStatus.status) >= 0);
        } else if (job?.result?.status) {
            return JobStatusCategories.support.indexOf(job.result.status) >= 0;
        }
        return false;
    };

    public jobStatusIsSuccessful = (jobObject: any) => {
        return JobStatusCategories.successful.indexOf(jobObject.status) >= 0;
    };

    public jobStatusIsRunning = (jobObject: any) => {
        return (jobObject.status === undefined && this._intervalIsActiv)
            || JobStatusCategories.running.indexOf(jobObject.status) >= 0;
    };

    public jobAborted = (jobObject: any) => {
        return (
            JobStatusCategories.abort.indexOf(jobObject.status) >= 0
            || jobObject.status === 'error'
        ) && !this.hasExpectedError(jobObject);
    };

    public jobFailed = (jobObject: any) => {
        return JobStatusCategories.failed.indexOf(jobObject.status) >= 0;
    };

    public jobStatusIsFailed = (jobObject: any) => {
        return this.jobFailed(jobObject)
            || JobStatusCategories.support.indexOf(jobObject.status) >= 0;
    };

    // Flatten the job-tree for easier 'in-order'-iterations.
    public flattenedJobs = (jobs: any, filterOrchestrationJobs = false) => {
        let flattened: any = [];
        if (jobs === undefined) {
            return flattened;
        }

        jobs.forEach(
            (job: WizardCreateObject) => {
                flattened.push(job);
                if (job.children === undefined) {
                    return;
                }
                if (job.children && job.children.length > 0) {
                    flattened = flattened.concat(this.flattenedJobs(job.children));
                }
            }
        );

        return filterOrchestrationJobs
            ? flattened.filter((job: any) => typeof job.result !== 'string')
            : flattened;
    };

    public findJobsByObjectType = (objectType: string, objectId: string | string[], cacheTimeout?: any) => {
        let filter;
        if (objectId === '') {
            return q.resolve(true);
        }
        if (Array.isArray(objectId)) {
            const idFilters = objectId.map((id: string) => {
                return { field: 'jobObjectId', value: id };
            });

            filter = {
                subFilter: [
                    { field: 'jobObjectType', value: objectType },
                    {
                        subFilter: idFilters,
                        subFilterConnective: 'OR'
                    }
                ],
                subFilterConnective: 'AND'
            };
        } else {
            filter = {
                subFilter: [
                    { field: 'jobObjectType', value: objectType },
                    { field: 'jobObjectId', value: objectId }
                ],
                subFilterConnective: 'AND'
            };
        }
        return this.getJobByFilter(filter, cacheTimeout, objectType);
    };

    public hasExpectedError = (jobObject: any, getUserNotice = false) => {
        if (jobObject.error?.code && this.expectedErrorList.length > 0 && !Array.isArray(jobObject.error?.code)) {
            for (const expectedError of this.expectedErrorList) {
                let allAttributesFound = true;

                for (const attribute of Object.keys(expectedError.lookup)) {
                    if (jobObject.error?.apiResponse[attribute] !== expectedError.lookup[attribute]) {
                        allAttributesFound = false;
                    }
                }

                if (allAttributesFound) {
                    return getUserNotice
                        ? (
                            typeof expectedError.userNotice === 'object'
                                ? expectedError.userNotice
                                : false
                        )
                        : true;
                }
            }
        }
        return false;
    };

    public _updateOrchestrationJobs = () => {
        const orchestrationJobId = this.createRequests[0]?.result;
        if (orchestrationJobId === undefined) {
            return;
        }

        this.updateOrchestrationJobs(
            orchestrationJobId,
            this.createRequests,
            this.apiErrorList
        ).then(
            (response) => {
                if (response.apiErrorList.length > 0) {
                    this.apiErrorList = response.apiErrorList;
                }

                if (response.orchestrationJobs.length > 0) {
                    this.orchestrationJobs = response.orchestrationJobs;
                }

                if (response.orchestrationJobsGroups.length > 0) {
                    this.orchestrationJobsGroups = response.orchestrationJobsGroups;
                }
            }
        );
    };

    public updateOrchestrationJobs = (
        orchestrationJobId: string,
        jobList: WizardCreateObject[],
        apiErrorList?: ApiErrorObject[]
    ) => {
        const jobFilter = { field: 'jobOrderId', value: orchestrationJobId };
        return this.orchestratorRobot.listJobs(jobFilter).then(
            (apiListJobsResponse) => {
                if (apiErrorList === null) {
                    apiErrorList = [];
                }

                const orchestrationJobs: any[] = apiListJobsResponse.response.data;
                const orchestrationJobsGroups: any[] = [];

                orchestrationJobs.forEach((orchestrationJob) => {
                    if (
                        this.jobStatusIsFailed(orchestrationJob)
                        && orchestrationJob.orderItemId === orchestrationJob.rootOrderItemId
                    ) {
                        // Change the status for children of failed root-ids to `error`.
                        orchestrationJobs.forEach((subOrchestrationJob) => {
                            if (
                                subOrchestrationJob.orderItemId !== subOrchestrationJob.rootOrderItemId
                                && orchestrationJob.orderItemId === subOrchestrationJob.rootOrderItemId
                            ) {
                                subOrchestrationJob.status = 'error';
                            }
                        });
                    }
                });

                [
                    {
                        objectType: ['Bundle'],
                        displayedName: this.$translate.instant('TR_170419-08d047_TR')
                    },
                    {
                        objectType: ['Domain', 'Zone'],
                        displayedName: this.$translate.instant('5cffb074-33c6-41b4-95d5-3069d0bb7115')
                    },
                    {
                        objectType: ['VHost', 'Webspace'],
                        displayedName: this.$translate.instant('TR_170419-963ad8_TR')
                    },
                    {
                        objectType: ['Database'],
                        displayedName: this.$translate.instant('TR_180419-db05e1_TR')
                    }
                ].forEach((panelSection) => {
                    const jobsFilterd = orchestrationJobs.filter(
                        (orchestrationJob) => panelSection.objectType.indexOf(orchestrationJob.objectType) >= 0);

                    Reducer.setReplace(
                        orchestrationJobsGroups,
                        { GroupName: panelSection.displayedName, GroupJobs: jobsFilterd },
                        ['GroupName']);
                });

                // Stop update interval if every succeeded or some failed.
                if (
                    orchestrationJobs.every(
                        (orchestrationJob) =>  this.jobStatusIsSuccessful(orchestrationJob)
                    )
                ) {
                    this.cancelJobInterval();
                } else if (
                    orchestrationJobs.some(
                        (orchestrationJob) =>  this.jobStatusIsFailed(orchestrationJob)
                    )
                ) {
                    apiErrorList.push(
                        {
                            value: '',
                            code: 0,
                            contextObject: `Error Code ${Reducer.get(jobList, [0, 'serverTransactionId'])}`,
                            contextPath: '',
                            details: [],
                            text: this.$translate.instant('71270781-9f80-4ec1-a763-b3959e134a54')
                        }
                    );
                    this.cancelJobInterval();
                }

                return {
                    apiErrorList: apiErrorList,
                    orchestrationJobs: orchestrationJobs,
                    orchestrationJobsGroups: orchestrationJobsGroups
                };
            }
        );
    };

    public singleResult = (job: any) => {
        return job.result !== undefined
            && !Array.isArray(job.result);
    };

    public multipleResults = (job: any) => {
        return job.result !== undefined
            && Array.isArray(job.result);
    };

    public showObjectName = (job: any, result: any) => {
        // Perhaps, to be continued in future ??
        switch (job.objectType) {
            case 'Database':
                return result.name;
            default:
        }
    };

    public normalizeResponseObject = (responseObject: any) => {
        switch (true) {
            case responseObject.data !== undefined:
                return responseObject;
            case responseObject.response:
            default:
                return responseObject.response;
        }
    };

    public cancelJobInterval = () => {
        this.localInterval.cancel(this.checkJobStatusIntervalHandler);
        if (this.callbackAfterFinishedRequests !== undefined) {
            this.callbackAfterFinishedRequests();
        }
        this._intervalIsActiv = false;
    };

    public set hasNextSteps({}) {} // tslint:disable-line:no-empty
    public get hasNextSteps() {
        return this.nextSteps !== undefined
            && this.nextSteps.length > 0;
    }

    private _abortChildrenOnError = (job: any) => {
        return job.map((child: any) => {
            if (job.children.length > 0) {
                job.children = this._abortChildrenOnError(job.children);
            }
            child.result = { status: 'abort' };
        });
    };

    private _getObjectIdForJobLookup = (job: any) => {
        if (
            ['Zone'].indexOf(job.objectType) >= 0
            && job.result && job.result.zoneConfig && job.result.zoneConfig.id
        ) {
            return job.result.zoneConfig.id;
        } else if (
            ['DynamicDnsCredentials'].indexOf(job.objectType) >= 0
            && job.result && job.result.credentialsId
        ) {
            return job.result.credentialsId;
        } else if (Array.isArray(job.result)) {
            return job.result.map(result => result.id);
        } else if (job.result && job.result.id) {
            return job.result.id;
        } else if (job.result?.response) {
            return job.result.response.id;
        }
        return '';
    };

    private _resetInternalState = () => {
        this.apiErrorModel.destroyErrorList();
        for (const ignoreErrorCode of this.ignoreErrorCodesInErrorPanel) {
            this.apiErrorModel.addErrorToIgnoreList(ignoreErrorCode);
        }
        this.orchestrationJobsGroups = undefined;
        this.checkJobStatusIntervalHandler = undefined;
        this.isLoading = false;
        this.createRequestResults = undefined;
        this.apiErrorList = [];
        this.orchestrationJobId = undefined;
        this.orchestrationJobs = [];
        this.orchestrationJobsGroups = undefined;
        this.createRequests = null;
        this.nextSteps = [];
    };

    public abstract getJobByFilter = (filter, cacheTimeout, objectType?) => {
        /**
         * This is only a function declaration.
         * The actual function is defined in the respective Service Instand of this abstract class
         * Example:
                return this.managedApplicationRobot.jobsFind(
                   filter,
                    null,
                    null,
                    null,
                    true,
                    cacheTimeout
                );
        */
        return undefined;
    };
}
