import {
    TemplateBillingPricelistAbstractOverviewComponent,
    TemplateBillingPricelistAbstractOverviewController
} from '../abstract/pricelist-abstract-template';

export class TemplateBillingPricelistSslOverviewController extends TemplateBillingPricelistAbstractOverviewController {
    protected options = {
        panelTitleTranslationId: /* translationId */ 'TR_090119-9718dc_TR',
        simpleFilterField: 'productName'
    };
}

export class TemplateBillingPricelistSslOverviewComponent extends TemplateBillingPricelistAbstractOverviewComponent {
    public controller = TemplateBillingPricelistSslOverviewController;
    public template = require('./pricelist-ssl-template.html');
}
