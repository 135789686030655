import * as ng from 'angular';
import { ValidateFqdn } from '../../../../../services/validators';
import { FormInputModelOptions } from '../../../../atoms/form-elements';

export class MoleculeInputTextFqdnController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public validationErrorList = [];
    public value: string;
    public placeholder: string;
    public callbackOnChange: (arg0: any) => any;
    public callbackOnBlur: (arg0: any) => any;
    public callbackOnBlurParams: any;
    public customCallbackOnEnter: () => {};
    public doNotSaveOnFormEnter: boolean;
    public disabled: boolean;
    public modelOptions: FormInputModelOptions;
    public registerToForm: boolean;
    public validationInstructions: any[];
    public options: any;
    public inputId: string;
    public callbackOnClear: (arg0: any) => any;
    public callbackOnClearParams: any;
    public clearable: boolean;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        let allowEmpty = true;
        if (this.options != null && this.options !== undefined) {
            if (this.options.allowEmpty != null && this.options.allowEmpty !== undefined) {
                allowEmpty = this.options.allowEmpty;
            }
        }
        this.validationInstructions = [{
            instructions: null,
            validator: new ValidateFqdn(this.$translate, allowEmpty)
        }];
    }

    public onPaste = (paste) => {
        // implement callback on paste here when needed!
    };
}

export class MoleculeInputTextFqdnComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnBlur: '<',
        callbackOnBlurParams: '<',
        callbackOnChange: '<',
        callbackOnClear: '<',
        callbackOnClearParams: '<',
        clearable: '<',
        customCallbackOnEnter: '<',
        disabled: '<',
        doNotSaveOnFormEnter: '<',
        inputId: '@?',
        modelOptions: '<',
        options: '<',
        placeholder: '@',
        registerToForm: '<',
        validationErrorList: '=?',
        value: '='
    };
    public controller = MoleculeInputTextFqdnController;
    public template = require('./input-text-with-validation-wrapper.html');
}
