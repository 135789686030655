import {
    TemplateBillingPricelistAbstractOverviewComponent,
    TemplateBillingPricelistAbstractOverviewController
} from '../abstract/pricelist-abstract-template';

export class TemplateBillingPricelistMailboxesOverviewController
    extends TemplateBillingPricelistAbstractOverviewController {
    protected options = {
        panelTitleTranslationId: /* translationId */ 'TR_090119-f08206_TR',
        simpleFilterField: 'productName'
    };
}

export class TemplateBillingPricelistMailboxesOverviewComponent
    extends TemplateBillingPricelistAbstractOverviewComponent {
    public controller = TemplateBillingPricelistMailboxesOverviewController;
    public template = require('./pricelist-mailboxes-template.html');
}
