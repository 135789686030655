/* eslint-disable no-console */
import ng from 'angular';

import { Deferred } from '@/services';

const debugCache: boolean | string = false;

export class Cache<CachedObjectType> {
    private values: { [id: string]: CachedObjectType } = {};
    private requests: { [id: string]: Promise<CachedObjectType> } = {};
    private clearCacheDeferred: Deferred<void>;

    private _timeout: number = undefined;
    private _flags: string[] = [];

    constructor(
        private $timeout: ng.ITimeoutService,
        private name: string
    ) {
        this.clearCacheDeferred = new Deferred<void>();

        if (debugCache === true || debugCache === this.name) {
            console.debug(`Cache ${this.name}: Cache created.`);
        }
    }

    public clear: () => void
    = () => {
        // Make sure no more values from unresolved requests are written to the cache
        this.clearCacheDeferred.resolve();
        this.clearCacheDeferred = new Deferred<void>();

        // Clear current cache values and requests
        this.values = {};
        this.requests = {};

        if (debugCache === true || debugCache === this.name) {
            console.debug(`Cache ${this.name}: Cache cleared.`);
        }
    };

    public timeout: (timeout: number | undefined) => Cache<CachedObjectType>
    = (timeout) => {
        if (timeout === undefined || timeout >= 0) {
            this._timeout = timeout;

            if (debugCache === true || debugCache === this.name) {
                console.debug(`Cache ${this.name}: Set timeout to ${timeout}.`);
            }
        }

        return this;
    };

    public flag: (flag: string) => Cache<CachedObjectType>
    = (flag) => {
        if (this._flags.indexOf(flag) < 0) {
            this._flags.push(flag);
        }

        return this;
    };

    public get flags(): string[] {
        return this._flags.slice();
    }

    public has: (id: string) => boolean
    = (id) => {
        const result = this.values[id] !== undefined || this.requests[id] !== undefined;

        if (debugCache === true || debugCache === this.name) {
            console.debug(`Cache ${this.name}: Key ${id} ` + (result ? 'exists.' : 'does not exist.'));
        }

        return result;
    };

    public add: (id: string, value: CachedObjectType) => void
    = (id, value) => {
        let cacheCleared = false;
        void this.clearCacheDeferred.promise.then(() => cacheCleared = true);

        this.requests[id] = Promise.resolve(value)
            .then((resolvedValue) => {
                if (cacheCleared) {
                    if (debugCache === true || debugCache === this.name) {
                        console.debug(
                            `Cache ${this.name}: Request for key ${id} finished, but ignored due to cleared cache.`
                        );
                    }

                    return resolvedValue;
                }

                this.values[id] = resolvedValue;
                this.requests[id] = undefined;

                if (debugCache === true || debugCache === this.name) {
                    console.debug(`Cache ${this.name}: Request for key ${id} finished, value added to cache.`);
                }

                if (this._timeout !== undefined) {
                    void this.$timeout(() => {
                        this.values[id] = undefined;

                        if (debugCache === true || debugCache === this.name) {
                            console.debug(`Cache ${this.name}: Key ${id} has timed out, value removed from cache.`);
                        }
                    },
                    this._timeout);

                    if (debugCache === true || debugCache === this.name) {
                        console.debug(`Cache ${this.name}: Key ${id} will timeout in ${this._timeout} milliseconds.`);
                    }
                }

                return JSON.parse(JSON.stringify(resolvedValue));
            });

        if (debugCache === true || debugCache === this.name) {
            console.debug(`Cache ${this.name}: Key ${id} added.`);
        }
    };

    public get: (id: string) => Promise<CachedObjectType | undefined>
    = (id) => {
        if (this.values[id] !== undefined) {
            if (debugCache === true || debugCache === this.name) {
                console.debug(`Cache ${this.name}: Retrieved resolved result for key ${id}.`);
            }

            return Promise.resolve(JSON.parse(JSON.stringify(this.values[id])));
        } else if (this.requests[id] !== undefined) {
            if (debugCache === true || debugCache === this.name) {
                console.debug(`Cache ${this.name}: Retrieved unresolved request for key ${id}.`);
            }

            return Promise.resolve(this.requests[id])
            .then((result) => JSON.parse(JSON.stringify(result)));
        }

        if (debugCache === true || debugCache === this.name) {
            console.debug(`Cache ${this.name}: Tried to access key ${id}, which does not exist.`);
        }

        return Promise.resolve(undefined);
    };
}

export class CacheService {
    public static $inject: string[] = ['$timeout'];
    private static caches: { [name: string]: Cache<any> } = {};

    constructor(
        private $timeout: ng.ITimeoutService
    ) {}

    public get: (name: string) => Cache<any>
    = (name) => {
        if ([undefined, null].indexOf(CacheService.caches[name]) >= 0) {
            CacheService.caches[name] = new Cache<any>(this.$timeout, name);
        }

        return CacheService.caches[name];
    };

    public clearAll: () => void
    = () => Object.keys(CacheService.caches).forEach((name: string) => CacheService.caches[name].clear());

    public clearFlagged: (flag: string) => void
    = (flag) => Object.keys(CacheService.caches).forEach(
        (name: string) => {
            if (CacheService.caches[name].flags.indexOf(flag) >= 0) {
                CacheService.caches[name].clear();
            }
        }
    );
}
