import * as ng from 'angular';
import { FormInputModelOptions } from '@/atomic-components/atoms/form-elements';
import { ValidationErrorList } from '@/atomic-components/molecules';
import { ValidateDns } from '@/services';

export class MoleculeInputTextDnsController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public validationErrorList: ValidationErrorList = [];
    public value: string;
    public placeholder: string;
    public callbackOnChange: (arg0: any) => any;
    public callbackOnBlur: (arg0: any) => any;
    public callbackOnBlurParams: any;
    public customCallbackOnEnter: () => {};
    public doNotSaveOnFormEnter: boolean;
    public disabled: boolean;
    public disableRandomisedId: boolean;
    public modelOptions: FormInputModelOptions;
    public registerToForm: boolean;
    public validationInstructions: any[];
    public options: any;
    public inputId: string;
    public callbackOnClear: (arg0: any) => any;
    public callbackOnClearParams: any;
    public clearable: boolean;
    public onlyValidateOnChange = false;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.validationInstructions = [];
        let allowEmpty = false;
        if (this.options != null && this.options !== undefined) {
            if (this.options.allowEmpty != null && this.options.allowEmpty !== undefined) {
                if (this.options.allowEmpty) {
                    allowEmpty = true;
                }
            }
        }
        this.validationInstructions.push ({
            instructions: null,
            validator: new ValidateDns(this.$translate, allowEmpty)
        });
    }

    public onPaste = (paste) => {
        // implement callback on paste here when needed!
    };
}

export class MoleculeInputTextDnsComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnBlur: '<',
        callbackOnBlurParams: '<',
        callbackOnChange: '<',
        callbackOnClear: '<',
        callbackOnClearParams: '<',
        clearable: '<',
        customCallbackOnEnter: '<',
        disabled: '<',
        disableRandomisedId: '<?',
        doNotSaveOnFormEnter: '<',
        inputId: '@?',
        modelOptions: '<',
        onlyValidateOnChange: '<?',
        options: '<',
        placeholder: '@',
        registerToForm: '<',
        validationErrorList: '=?',
        value: '='
    };
    public controller = MoleculeInputTextDnsController;
    public template = require('./input-text-with-validation-wrapper.html');
}
