import * as ng from 'angular';
import {
    PanelHeaderData
} from '@/atomic-components/molecules/panels/panel-header/panel-header';
import { UiLanguagesConst, UiRights } from '@/configuration';
import { AuthContextService, PriceCacheService, ProductHelperService, ProductsModelService } from '@/services';
import * as Types from '@/types';

export class TemplateWebspacePhpBoostController {
    public static $inject: string[] = [
        '$state',
        '$translate',
        'productsModel',
        'priceCache'
    ];

    public pageHeaderData: PanelHeaderData;
    public webspace: Types.WebhostingApi.Webspace;
    public isFreeOfCharge = false;
    public phpBoostOptions: Types.UI.phpBoostOptionsInterface = {
        phpExecutionTime: { included: 0, max: 0, step: 0, current: 0, productCode: '', orderAmount: 0, price: {}},
        phpMemoryLimit: { included: 0, max: 0, step: 0, current: 0, productCode: '', orderAmount: 0, price: {} }
    };
    public billingCycle: number;
    public userPanelRight;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private productsModel: ProductsModelService,
        private priceCache: PriceCacheService
    ) {}

    public $onInit = (): void => {
        const routeState = this.$state.current.name.split('.')[0];
        this.isFreeOfCharge = routeState === 'managed-servers';

        this.pageHeaderData = {
            backwardLink: routeState === 'webhosting' ? 'webhosting.webspaces.id.edit'
                : routeState === 'managed-servers' ? 'managed-servers.id.webspaces.id.edit'
                : 'bundle.id.dashboard',
            backwardText:
                routeState === 'bundle'
                ? this.$translate.instant('TR_090119-a32485_TR')
                : this.$translate.instant('TR_140119-e915b4_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: routeState === 'managed-servers'
                ?  {
                    machineId: (this.$state.$current.locals.globals.machine as Types.MachineApi.VirtualMachine).id,
                    webspaceId: (this.$state.$current.locals.globals.webspace as Types.WebhostingApi.Webspace).id
                } : routeState === 'bundle'
                ? { bundleId: this.webspace.bundleId }
                : null,
            panelIcon: 'battery-bolt',
            panelTitle: this.$translate.instant('TR_070619-458016_TR')
        };

        this.userPanelRight = {
            storageQouta: {
                editPanelButton: AuthContextService.isGranted(UiRights.WEB_WEBSPACE_EDIT),
                overrideStorageQuota: AuthContextService.isGranted(UiRights.WEB_WEBSPACE_EDIT)
            },
            legacyPHP: {
                editPanelButton: AuthContextService.isGranted(UiRights.WEB_WEBSPACE_EDIT)
            }
        };

        this.phpBoostOptions.phpExecutionTime.current = ng.copy(this.webspace.phpExecutionTime);
        this.phpBoostOptions.phpMemoryLimit.current = ng.copy(this.webspace.phpMemory);

        void this.productsModel.findOne(this.webspace.productCode, undefined, true).then(
            (reply) => {
                const specification = ProductHelperService.wrapSpecificationItems(reply.response.specificationItems);

                this.phpBoostOptions.phpExecutionTime.included = this.webspace.includedPhpExecutionTime;
                this.phpBoostOptions.phpExecutionTime.max = specification.maxPhpExecutionTime?.intValue || 0;
                this.phpBoostOptions.phpExecutionTime.productCode = (
                    specification.phpExecutionTimeProductCode?.value as string
                ) || '';

                this.phpBoostOptions.phpMemoryLimit.included = this.webspace.includedPhpMemory;
                this.phpBoostOptions.phpMemoryLimit.max = specification.maxPhpMemoryLimit?.intValue || 0;
                this.phpBoostOptions.phpMemoryLimit.productCode = (
                    specification.phpMemoryLimitProductCode?.value as string
                ) || '';

                Object.keys(this.phpBoostOptions).forEach(
                    (boostName: 'phpExecutionTime' | 'phpMemoryLimit') => {
                        void this.productsModel.findProduct(
                            this.phpBoostOptions[boostName].productCode,
                            this.$translate.use()
                        ).then(
                            (res) => {
                                this.phpBoostOptions[boostName].step = ProductHelperService.wrapSpecificationItems(
                                    res?.response?.specificationItems
                                )[boostName]?.intValue || 0;
                            }
                        );

                        void this.priceCache.getPriceByProductCode(
                            this.phpBoostOptions[boostName].productCode,
                            this.webspace.accountId
                        ).then(
                            (res) => {
                                if (res?.length > 0) {
                                    this.phpBoostOptions[boostName].price = res[0];
                                }
                            }
                        );
                    }
                );
            }
        );
    };
}

export class TemplateWebspacePhpBoostComponent implements ng.IComponentOptions {
    public bindings = {
        webspace: '<',
        billingCycle: '<'
    };
    public controller = TemplateWebspacePhpBoostController;
    public template = require('./webspace-php-boost-template.html');
}
