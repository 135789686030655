import ng from 'angular';

import { CalculatePriceFilter } from '@/filters';
import { AuthContextService, PriceHelperService, PriceObject } from '@/services';

import { WizardHelperService } from '../wizard-helper';

export class MoleculeWizardBillingCycleSelectController implements ng.IController {
    public static $inject: string[] = [
        '$state',
        '$stateParams',
        '$timeout',
        '$translate',
        'authContext',
        'calculatePriceFilter',
        'priceHelper',
        'wizardHelper'
    ];
    public prices: PriceObject[];
    public priceSelectItems: any[] = [];
    public productName: string;
    public serviceObject;
    public showNotInContingentNotice = false;
    public billingCycleHasChanged = false;
    private _selectedBillingCycle: number;

    constructor(
        private $state: ng.ui.IStateService,
        private $stateParams: ng.ui.IStateParamsService,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private calculatePriceFilter: CalculatePriceFilter,
        private priceHelper: PriceHelperService,
        public wizardHelper: WizardHelperService
    ) {
        if (this.$state.current.name === 'ssl.certificates.new') {
            this.selectedBillingCycle = 12;
        }
    }

    public $onInit() {
        this.updateShowContingentNotice();
        this.wizardHelper.getLanguage();
    }

    public $onChanges(changes) {
        this.updateShowContingentNotice();
        if (changes.prices !== undefined) {
            this.createSelectItems(changes.prices.currentValue);
        }
        if (changes.selectedContingent !== undefined) {
            this.createSelectItems();
        }
    }

    get showBackupPrice(): boolean {
        return this.showPrice
            && this.nextcloudBackupOptionAvailable
            && this.serviceObject.settings.createData.nextcloud.backupEnabled;
    }

    get selectedBillingCycle(): number {
        return this._selectedBillingCycle;
    }

    set selectedBillingCycle(value: number) {
        if (value !== this._selectedBillingCycle) {
            this.billingCycleHasChanged = true;
            this.updateBillingCycle(value);
            this.$timeout(() => {
                this.billingCycleHasChanged = false;
            }, 300);
        }
        this._selectedBillingCycle = value;
    }

    public updateBillingCycle = (newBillingCycle: number) => {
        if (
            this.serviceObject !== undefined
            && this.serviceObject.settings !== undefined
            && this.serviceObject.settings.createData !== undefined
            && this.serviceObject.settings.createData.backup !== undefined
            && this.serviceObject.settings.createData.backup.billingCycle !== undefined
        ) {
            this.serviceObject.settings.createData.backup.billingCycle = (newBillingCycle + '');
        }
    };

    public get nextcloudBackupOptionAvailable() {
        return this.serviceObject.settings !== undefined
            && this.serviceObject.settings.createData !== undefined
            && this.serviceObject.settings.createData.backup !== undefined
            && this.serviceObject.settings.createData.backup.price !== null;
    }

    public get bruttoNettoTextBackup() { // used in template
        return this.priceHelper.bruttoNettoText(this.serviceObject.settings.createData.backup.price);
    }

    public get calculatePriceBackup() { // used in template
        return this.priceHelper.calculatePrice(this.serviceObject.settings.createData.backup.price);
    }

    public updateShowContingentNotice = () => {
        this.showNotInContingentNotice = false;
        if ([undefined, null, ''].indexOf(this.$stateParams.bundleId) < 0) {
            if ([undefined, null].indexOf(this.serviceObject) < 0
                && [undefined, null].indexOf(this.serviceObject.selectedProduct) < 0
                && ['external-forwarders', 'imap-mailboxes'].indexOf(this.serviceObject.selectedProduct.family) >= 0) {
                // email contingent user notice
                this.showNotInContingentNotice = !this.wizardHelper.inContingent(this.serviceObject);
            } else if ([undefined, null].indexOf(this.serviceObject) < 0
                && [undefined, null].indexOf(this.serviceObject.selectedProduct) < 0
                && ['mariadb-single'].indexOf(this.serviceObject.selectedProduct.family)) {
                // database contingent user notice
                this.showNotInContingentNotice = !this.wizardHelper.inContingent(this.serviceObject);
            }
        }
    };

    public get pricesExist() {
        if (this.isRootAdmin && this.serviceObject.settings.selectedContingent !== undefined) {
            return true;
        }

        if ([undefined, null].indexOf(this.prices) >= 0) {
            return false;
        }
        return this.prices.every(
            (price) => {
                return [undefined, null].indexOf(price.price) === -1 && price.price.price !== -1;
            }
        );
    }

    public get isRootAdmin() {
        return this.authContext.isRootOrCompanyAccount
            && (this.serviceObject.settings.accountId === this.authContext.account.id);
    }

    public showBillingCycleSelectContainer = () => {
        if ([undefined, ''].indexOf(this.productName) >= 0) {
            return false;
        }
        return this.showBillingCycleSelect()
            || this.showPrice
            || this.showNotInContingentNotice;
    };

    public showBillingCycleSelect = () => {
        if ([undefined, null].indexOf(this.prices) >= 0 || this.prices.length === 1) {
            return false;
        }

        if ([undefined, false].indexOf(this.serviceObject.display.contingentAvailable) === -1) {
            return [undefined, null].indexOf(this.serviceObject.settings.selectedContingent) >= 0;
        }

        return true;
    };

    public get isInternalForwader() {
        return [undefined, false].indexOf(this.serviceObject.display.internalForwarder) < 0;
    }

    public set isInternalForwader({}) {}    // tslint:disable-line:no-empty

    private createSelectItems = (prices?: any[]) => {
        prices = prices || this.prices;
        if ([undefined, null].indexOf(this.prices)  >= 0) {
            return;
        }

        prices = prices.sort((a, b) => a.billingCycle - b.billingCycle);

        if (prices.length === 1) {
            this.selectedPrice = prices[0];
        }

        this.priceSelectItems = prices.map(
            (price) => ({
                label: price.price !== null
                    ? this.wizardHelper.getBillingCycleTranslation(price.price.contractPeriod)
                    : price.billingCycle + ' ' + this.$translate.instant('GENERAL.MONTHS'),
                value: price.billingCycle
            })
        );
    };

    public get selectedPrice() {
        if ([undefined, null].indexOf(this.prices) >= 0) {
            return null;
        }
        for (const item of this.prices) {
            if (item.billingCycle === this.selectedBillingCycle) {
                return item;
            }
        }
        return null;
    }

    public set selectedPrice(price: PriceObject) {
        if ([undefined, null].indexOf(price) >= 0) {
            return;
        }
        if ([undefined, null].indexOf(this.prices) === -1) {
            this.selectedBillingCycle = price.billingCycle;
        }
    }

    public get costs() {
        if (this.selectedPrice === null || this.isRootAdmin) {
            return '';
        }
        const price = this.selectedPrice.price;
        return this.calculatePriceFilter(
            price.price,
            price.vatRate,
            1,
            this.authContext.account.isCommercialCustomer ? 'net' : 'gross',
            null,
            false,
            price.productCode,
            price.contractPeriod
        );
    }

    public get showPrice() {
        return this.pricesExist && this.selectedPrice !== null;
    }

    public get currency() {
        return this.priceHelper.getProductCurrency(this.selectedPrice.price);
    }
}

export class MoleculeWizardBillingCycleSelectComponent implements ng.IComponentOptions {
    public controller =  MoleculeWizardBillingCycleSelectController;
    public bindings = {
        prices: '<',
        productName: '@',
        selectedContingent: '<',
        selectedPrice: '=',
        serviceObject: '='
    };
    public template = require('./wizard-billing-cycle-select.html');
}
