import * as ng from 'angular';

import { DnsZoneModelService } from '../../../../../services/dns/zone-model';
import { CleanableStringType, StringCleaner } from '../../../../../services/helpers/string-cleaner';
import * as Types from '../../../../../types';

export class OrganismRedirectionWizardProductConfigController {
    public static $inject: string[] = ['$translate', 'dnsZoneModel'];

    public metadata: Types.ViewTypes.ProductConfigDomainRedirectionObject;
    public typeDropdownItems: any[];
    public zoneExists: boolean;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private dnsZoneModel: DnsZoneModelService
    ) {}

    public $onInit() {
        this.typeDropdownItems = [{
            name: this.$translate.instant('TR_110119-c2483c_TR'),
            value: '301'
        }, {
            name: this.$translate.instant('TR_110119-0712c5_TR'),
            value: '302'
        }, {
            name: this.$translate.instant('TR_110119-07fe21_TR'),
            value: 'frame'
        }];

        this._setMetadata();
    }

    public sanitizeUserDomainInput = () => { // used in template
        this.metadata.domainName = StringCleaner
            .clean(this.metadata.domainName)
            .as(CleanableStringType.FQDNs);
        this.checkZones(this.metadata.domainName);
    };

    public checkZones = (domainName: string) => {
        const domains = this.numberOfDomains(domainName, true);
        this.dnsZoneModel.findByName(domains).then(
            (reply) => {
                this.zoneExists = reply.length > 0;
                this.metadata.zone = reply;
                if (this.metadata.adjustRecords === undefined) {
                    this.metadata.adjustZone = true;
                }
            }
        );
    };

    public set configCompleted({}) {} /* tslint:disable-line:no-empty */ // used by parent
    public get configCompleted() { // used by parent
        return true;
    }

    private _setMetadata = () => {
        this.metadata = {
            account: undefined,
            domainName: '',
            type: '',
            title: '',
            redirectionUrl: '',
            adjustZone: false,
            metaTagAuthor: '',
            metaTagKeywords: '',
            metaTagDescription: '',
            price: [],
            adjustRecords: false,
            zone: undefined
        };
    };

    private numberOfDomains = (domainNames: string, getDomainNames = false): any => {
        if ([undefined, null, ''].indexOf(domainNames) >= 0) {
            if (getDomainNames) {
                return [''];
            }
            return 0;
        }

        const domains = domainNames
            .split(/\r?\n/)
            .filter((line) => line.trim().length > 0)
            .filter((line, index, lines) => lines.indexOf(line) === index);

        if (getDomainNames) {
            return domains;
        }
        return domains.length;
    };
}

export class OrganismRedirectionWizardProductConfigComponent implements ng.IComponentOptions {
    public bindings = {
        metadata: '=',
        configCompleted: '='
    };
    public template = require('./redirection-wizard-redirection-config.html');
    public controller = OrganismRedirectionWizardProductConfigController;
}

