import * as ng from 'angular';
import { LocationMetadataArray } from '../../../organisms/forms/edit-forms/domain-edit/domain-locations';

export class MoleculeListsDomainLocationsListController implements  ng.IController {
    public domainLocations: LocationMetadataArray;
    public formIsEditable: boolean;
}

export class MoleculeListsDomainLocationsListComponent implements ng.IComponentOptions {
    bindings = {
        domainLocations: '=',
        formIsEditable: '<'
    };
    public controller = MoleculeListsDomainLocationsListController;
    public template = require('./domain-locations-list.html');
}
