import { UIRegExp } from '@/configuration';
import { translate } from 'angular';
import { Validator } from './abstract-validator';

/**
    https://tools.ietf.org/html/rfc7929

    Validierung:
    * Der Name muss in der Zone liegen
    * Aufteilung des Namens nach Punkten ('.')
        ** der erste Teil muss aus 56 Hex-Ziffern (0-9 oder a-f) bestehen
        ** der zweite Teil muss _openpgpkey sein
    * der Content muss ein gültiger Base64-String nach RFC 4648 sein:
        ** Alphabet: A-Z, a-z, 0-9, +, /
        ** Korrekte Menge Padding (=) am Ende
        ** kodierte Bits die beim Dekodieren verworfen werden sind null:
            *** AA==: korrekt
            *** AB==: inkorrekt
        ** Validierungs-Regex: bitbucket/projects/KEEN/repos/keenrobotscommon/browse/src/common/commonnamespace.h#48
*/

export class ValidateOpenpgpkey extends Validator {
    constructor(private $translate: translate.ITranslateService) {
        super();
    }

    public validate = (value: string) => {
        const parts = value.split('.');

        if (parts.length > 1 || parts.some((val) => val.length === 0)) {
            return [
                {
                    text: this.$translate.instant('TR_131020-50eee9_TR')
                }
            ];
        }

        if (!UIRegExp.Opengpgkey.test(value)) {
            return [
                {
                    text: this.$translate.instant('TR_131020-50eee9_TR')
                }
            ];
        }

        return [];
    };
}
