import * as ng from 'angular';
import * as q from 'q';

import {
    AlertManagerService, ApiErrorModel, NavigationService, OrganizationModelService
} from '../../../../../services';

export class OrganismEditFormExchangeOrganizationController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'apiErrorModel',
        'navigation',
        'organizationModel'
    ];

    public originalOrganization: any;
    public organization: any;
    public userPanelRight: any;
    public hasAgreedToDeleteOrganization = false;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private navigation: NavigationService,
        private organizationModel: OrganizationModelService
    ) {}

    public $onInit = () => {
        this.organization = JSON.parse(JSON.stringify(this.originalOrganization));
    };

    public save = () => {
        return this.organizationModel.update(this.organization)
        .then(
            (res) => {
                if (!this.apiErrorModel.apiResponseHasError(res)) {
                    this.originalOrganization = JSON.parse(JSON.stringify(this.organization));
                    this.alertManager.success(this.$translate.instant('TR_100119-341bc0_TR'));
                    return res;
                }

                return q.reject(false);
            },
            (error) => {

                return q.reject(error);
            }
        );
    };

    public delete = () => {
        this.apiErrorModel.destroyErrorList();
        this.organizationModel.delete([this.organization]).then(
            (apiResponse) => {
                this.alertManager.success(this.$translate.instant('TR_110119-2eaba4_TR'));
                this.navigation.go('email.organizations.overview', {}, {reload: true});
                return apiResponse;
            },
            (error) => {
                return q.reject(error);
            }
        );
    };

    public cancelGeneral = () => {
        this.organization.name = this.originalOrganization.name;
        this.organization.comment = this.originalOrganization.comment;
    };

    public cancelDelete = () => {
        this.hasAgreedToDeleteOrganization = false;
    };

    public get errorList() {
        return this.apiErrorModel.errorObjectList;
    }

    public get isActive() {
        return this.organization.status === 'active' || this.organization.status === 'creating';
    }

}

export class OrganismEditFormExchangeOrganizationComponent implements ng.IComponentOptions {
    public bindings = {
        originalOrganization: '<organization',
        userPanelRight: '='
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormExchangeOrganizationController;
    public template = require('./exchange-organization-edit.html');
}
