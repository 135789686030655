import * as ng from 'angular';
import { ProductBoxContent } from '../../../../../services';

export class MoleculeFormFamilyBoxController implements ng.IController {
    public static $inject: string[] = [];

    public boxContent: ProductBoxContent;
    public isSelected: boolean;
}

export class MoleculeFormFamilyBoxComponent implements ng.IComponentOptions {
    public bindings = {
        boxContent: '<',
        isSelected: '<'
    };
    public template = require('./family-box.html');
    public controller =  MoleculeFormFamilyBoxController;
}
