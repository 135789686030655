import * as Sentry from '@sentry/browser';

export type EntryPoint = 'secure.domainpartner.net'
| 'partner.http.net'
| 'partner.routing.net'
| 'secure.hosting.de'
| 'secure.routing.net'
| 'secure.ownweb.com'
| 'secure.fresh-internet.net'
| 'secure.online-bestellsystem.de';

export class GetEntryPointService {
    // Defaults
    private _entryPoint: EntryPoint = 'secure.domainpartner.net';
    private _logoPath = '/assets/images/logos/partner.svg';
    private _registrationUrl: string = undefined;
    private _name = 'Platform';
    private _domain: string;

    constructor() {
        switch (window.location.hostname) {
            case 'ci-testing.platform.keenlogics.dev':
            case 'ui-testing.platform.keenlogics.dev':
            case 'partner.http.net':
            case 'partner.http.net.keendev':
            case 'frontend.platformadmin.de':
            case 'ui.dev.platformadmin.de':
            case 'localhost': // Development
            case '0.0.0.0': // Development
                this._domain = 'http.net';
                this._entryPoint = 'partner.http.net';
                this._logoPath = '/assets/images/logos/http.svg';
                this._name = 'http.net';
                this._registrationUrl = undefined;
                break;

            case 'secure.hosting.de':
            case 'secure.hosting.de.keendev':
            case 'demo.hosting.de':
                this._domain = 'hosting.de';
                this._entryPoint = 'secure.hosting.de';
                this._logoPath = '/assets/images/logos/hosting.svg';
                this._name = 'hosting.de';
                this._registrationUrl = 'https://secure.hosting.de/registration/';
                break;

            case 'secure.fresh-internet.net':
            case 'secure.fresh-internet.net.keendev':
                this._domain = 'fresh-internet.net';
                this._entryPoint = 'secure.fresh-internet.net';
                this._logoPath = '/assets/images/logos/fresh.svg';
                this._name = 'FRESH Internet';
                this._registrationUrl = undefined;
                break;

            case 'demo.routing.net':
                this._domain = 'routing.net';
                this._entryPoint = 'partner.routing.net';
                this._logoPath = '/assets/images/logos/partner.svg';
                this._name = 'routing.net';
                this._registrationUrl = 'https://demo.routing.net/signup/';
                break;
            case 'partner.routing.net':
            case 'partner.routing.net.keendev':
                this._domain = 'routing.net';
                this._entryPoint = 'partner.routing.net';
                this._logoPath = '/assets/images/logos/partner.svg';
                this._name = 'routing.net';
                this._registrationUrl = undefined;
                break;

            case 'secure.routing.net':
            case 'secure.routing.net.keendev':
                this._domain = 'routing.net';
                this._entryPoint = 'secure.routing.net';
                this._logoPath = '/assets/images/logos/partner.svg';
                this._name = 'routing.net';
                this._registrationUrl = undefined;
                break;

            case 'secure.ownweb.com':
            case 'secure.ownweb.com.keendev':
                this._domain = 'ownweb.com';
                this._entryPoint = 'secure.ownweb.com';
                this._logoPath = '/assets/images/logos/partner.svg';
                this._name = 'ownweb.com';
                this._registrationUrl = undefined;
                break;

            case 'secure.domainpartner.net':
            case 'secure.domainpartner.net.keendev':
                this._domain = 'domainpartner.net';
                this._entryPoint = 'secure.domainpartner.net';
                this._logoPath = '/assets/images/logos/partner.svg';
                this._name = 'domainpartner.net';
                this._registrationUrl = undefined;
                break;

            case 'secure.online-bestellsystem.de':
                this._domain = 'online-bestellsystem.de';
                this._entryPoint = 'secure.online-bestellsystem.de';
                this._logoPath = '/assets/images/logos/partner.svg';
                this._name = 'online-bestellsystem.de';
                this._registrationUrl = undefined;
                break;

            default:
                Sentry.captureMessage('EntryPointInformation \'' + window.location.hostname + '\' could not be found');
                break;
        }
    }

    public get entryPoint(): EntryPoint {
        return this._entryPoint;
    }

    public get logoPath(): string {
        return this._logoPath;
    }

    public get registrationUrl(): string {
        return this._registrationUrl;
    }

    public get name(): string {
        return this._name;
    }

    public get domain(): string {
        return this._domain;
    }

    public get isHosting() {
        return this._entryPoint === 'secure.hosting.de';
    }

    public get showSupportOptions() {
        switch (this._entryPoint) {
            case 'partner.routing.net':
            case 'secure.routing.net':
            case 'secure.domainpartner.net':
            case 'secure.ownweb.com':
            case 'secure.online-bestellsystem.de':
                return false;
            default:
                return true;
        }
    }
}
