export const CronjobPathPrefixConst = [
    {value: '/html/', name: '/html/'},
    {value: '/data/', name: '/data/'}
];

export const CronjobTypes = [
    { translationId: 'WEBHOSTING.CRON.OPTION.TYPE.BASH', value: 'bash'},    // 'shellscript'
    { translationId: 'WEBHOSTING.CRON.OPTION.TYPE.PHP', value: 'php'},      // 'php-script'
    { translationId: 'WEBHOSTING.CRON.OPTION.TYPE.URL', value: 'url'}       // 'url-call'
];

export const CronjobScheduleConst = {
    // Schedule day parts - ex.: 1-5 => between 1 and 5 o'clock
    dayParts: ['1-5', '5-9', '9-13', '13-17', '17-21', '21-1', ''],
    executionCycle: [
        { translationId: 'TR_090119-1c92c7_TR', name: 'Jede Minute', value: '1min' },
        { translationId: 'TR_090119-c62151_TR', name: 'Alle 5 Minuten', value: '5min' },
        { translationId: 'TR_090119-478487_TR', name: 'Alle 10 Minuten', value: '10min' },
        { translationId: 'TR_090119-6adff6_TR', name: 'Alle 15 Minuten', value: '15min' },
        { translationId: 'TR_090119-0d7bde_TR', name: 'Alle 30 Minuten', value: '30min' },
        { translationId: 'TR_090119-04371b_TR', name: 'Jede Stunde', value: '1hour' },
        { translationId: 'TR_090119-e245c8_TR', name: 'Alle 2 Stunden', value: '2hour' },
        { translationId: 'TR_090119-e84e04_TR', name: 'Alle 3 Stunden', value: '3hour' },
        { translationId: 'TR_090119-e918ce_TR', name: 'Alle 4 Stunden', value: '4hour' },
        { translationId: 'TR_090119-5286e7_TR', name: 'Alle 6 Stunden', value: '6hour' },
        { translationId: 'TR_090119-90bcea_TR', name: 'Alle 12 Stunden', value: '12hour' },
        { translationId: 'TR_090119-b22c00_TR', name: 'Täglich', value: 'daily' },
        { translationId: 'TR_090119-bca8f5_TR', name: 'Wöchentlich', value: 'weekly' },
        { translationId: 'TR_090119-8f7027_TR', name: 'Monatlich', value: 'monthly' }
    ]
};

export const WebspaceAccessObjectConst = {
    ftpAccess: true,
    homeDir: '',
    sshAccess: true,
    statsAccess: true,
    userId: '',
    userName: '',
    webspaceId: ''
};

export const WebspaceUserObjectConst = {
    accountId: '',
    comments: '',
    id: '',
    name: '',
    sshKey: ''
};

export const WebspaceObjectConst = {
    accesses: [],
    accountId: '',
    bundleId: '',
    name: '',
    storageQuota: 0
};
