import { LinkListOverviewItem } from '../../atomic-components/molecules/links/link-list-overview/link-list-overview';
import { AccountFilterField, SelectFilterField, TextFilterField } from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { OverviewModel } from '../abstract';
import {DomainHandleModelService } from '../domain/domain-handle-model';
import { RequestCancellerService } from '../request-canceller';

export class DomainContactsVerificationOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', 'domainHandleModel', '$q', '$state']
    );

    readonly service = 'domain-contacts-verification';
    readonly objectType = 'DomainContactVerification';
    readonly idField = 'contactId';
    readonly defaultFilterFields = ['contactVerificationName', 'contactVerificationOrganization', 'contactVerificationEmailAddress'];

    public activeMassAction: boolean | string = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};

    protected listCallbacks = [];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private domainHandleModel: DomainHandleModelService,
        private $q: ng.IQService,
        $state: ng.ui.IStateService
    ) {
        super(RequestCanceller);

        this.actionLinksAvailabilities = {
            verificationsSelected: this.verificationsSelected
        };

        this.listCallbacks = [];
        this.listCallbacks.push(
            {
                get callback() {
                    return $state.current.name.split('.').indexOf('dashboard') >= 0
                    ? domainHandleModel.listVerificationsWithoutPagination
                    : domainHandleModel.listVerifications;
                }
            }
        );
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('contactVerificationName', this.$translate.instant('TR_140119-796b28_TR')),
                new TextFilterField('contactVerificationOrganization', this.$translate.instant('TR_090119-9044b3_TR')),
                new TextFilterField('contactVerificationEmailAddress', this.$translate.instant('TR_080119-8dd4a1_TR')),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR'))
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }

    actionLinks = (params: { [param: string]: any }): LinkListOverviewItem[] => {
        return [
            {
                text: this.$translate.instant('TR_140119-30a364_TR'),
                route: '',
                type: 'multi',
                isGranted: true,
                onlyOnExperView: true,
                linkList: [{
                    text: this.$translate.instant('DOMAIN.HANDLE.ACTION.VERIFICATION.RESEND-EMAIL'),
                    route: '',
                    isClickable: 'verificationsSelected',
                    onclickMethode: () => {this.activeMassAction = 'contactVerification'; },
                    group: 'email',
                    groupName: this.$translate.instant('TR_080119-8dd4a1_TR')
                }]
            }
        ] as LinkListOverviewItem[];
    };

    public verificationsSelected = (verifications) => {
        return verifications.some(
            verification => {
                return this.selected[verification.id];
            }
        );
    };

    public resendEmailSubmit = (contacts: any[]) => {
        const promises = [];
        for (const contact of contacts) {
            if (this.selected[contact.id]) {
                promises.push(this.domainHandleModel.resendVerificationEmail(contact.id));
            }
        }
        return this.$q.all(promises);
    };
}
