import { AuthContextService } from '../';
import { BillingRobotService } from '../billing/robot';
import { FunctionRelayService } from '../function-relay';
import { ModelHelper } from '../model-helper';
import { RequestStatus } from '../rpc-client';
import { BundleRobotService } from './robot';

import * as q from 'q';
import * as Types from '../../types';
import { BundleApi } from '../../types';

export class BundleModelService {
    public static $inject: string[] = [
        'billingRobot',
        'bundleRobot',
        'functionRelay'
    ];

    constructor(
        private billingRobot: BillingRobotService,
        private bundleRobot: BundleRobotService,
        private functionRelay: FunctionRelayService
    ) {}

    public list = (limit?, page?, filters?, sort?, cancel?) => {
        sort = sort || {
            field: 'BundleName',
            order: 'ASC'
        };
        page = page || 1;

        return this.bundleRobot.bundlesFind(filters, limit, page, sort, cancel)
        .then(ModelHelper.returnListResponse);
    };

    public listWithoutPagination = (limit?, page?, filters?, sort?, cancel?) => {
        sort = sort || {
            field: 'BundleName',
            order: 'ASC'
        };
        page = page || 1;

        return this.bundleRobot.bundlesFindWithoutPagination(filters, limit, page, sort, cancel)
        .then(ModelHelper.returnListResponse);
    };

    public productsList = (filters?, limit?, page?, sort?, cancel?, owner?) => {
        sort = sort || {
            field: 'BundleProductProductCode',
            order: 'ASC'
        };
        page = page || 1;

        return this.bundleRobot.bundleProductsFind(filters, limit, page, sort, cancel, owner)
        .then(ModelHelper.returnListResponse)
        .then(this.addMissingBundleContingents);
    };

    public findOne = (id: string, returnEmptyResponse?: boolean) => {
        return this.bundleRobot.bundlesFindWithoutPagination({ field: 'BundleId', value: id }, 1, 1)
        .then(
            (result) => {
                if (result.response.data.length > 0) {
                    return result.response.data[0];
                } else if (returnEmptyResponse) {
                    return result.response.data;
                }

                throw new Error('Bundle not found');
            }
        ) as Promise<BundleApi.Bundle>;
    };

    public findById = (ids: string[]) => {
        const filter = {
            subFilter: ids.map((id) => ({ field: 'BundleId', value: id })),
            subFilterConnective: 'OR'
        };

        return this.bundleRobot.bundlesFindWithoutPagination(filter, 0, 1)
        .then((result) => result.response.data);
    };

    public findOneById = (id: string) => {
        return this.bundleRobot.bundlesFindWithoutPagination({ field: 'bundleId', value: id }, 10, 1)
        .then((result) => result.response.data);
    };

    public create = (bundle, owner?) => {
        owner = owner || AuthContextService.account.id;
        return this.bundleRobot.bundleCreate(bundle, owner)
        .then((result) => result.response)
        .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
    };

    public delete = (bundles, execDate) => {
        const promises = bundles.map(
            (bundle) => this.bundleRobot.bundleDelete(bundle.id, bundle.domainActions, execDate)
        );

        return q.all(promises);
    };

    public deletionCancel = (bundles: Types.BundleApi.Bundle[]) => {
        const promises = bundles.map(
            (bundle) => this.bundleRobot.bundleDeletionCancel(bundle.id)
        );

        return q.all(promises);
    };

    public update = (bundle) => {
        return this.bundleRobot.bundleUpdate(bundle);
    };

    public restore = (bundles: Types.BundleApi.Bundle[]) => {
        const promises = bundles.map((bundle) => this.bundleRobot.restoreBundle(bundle.id));

        return q.all(promises);
    };

    public purgeRestorable = (bundles: Types.BundleApi.Bundle[]) => {
        const promises = bundles.map((bundle) => this.bundleRobot.bundlePurgeRestorable(bundle.id));

        return q.all(promises);
    };

    public bundleVhostFind = (bundleId: string) => {
        return this.bundleRobot.bundleVhostFind(bundleId).then((res) => res.response);
    };

    public findRunningJobsForBundle = (bundle: Types.BundleApi.Bundle) => {
        const filter = {
            subFilter: [
                { field: 'jobObjectType', value: 'Bundle' },
                { field: 'jobObjectId', value: bundle.id },
                { field: 'jobStatus', relation: 'unequal', value: 'successful' },
                { field: 'jobStatus', relation: 'unequal', value: 'failed' },
                { field: 'jobStatus', relation: 'unequal', value: 'canceled' }
            ],
            subFilterConnective: 'AND'
        };

        return this.billingRobot.jobsFind(filter, 25, 1, null, true);
    };

    private addMissingBundleContingents = (result) => {
        // this has to stay here until the API adds the needed elements and we have made sure we don't just access
        // the contingent arrays first element! (dirty backwards compatibility hack)
        result.data.forEach((entry: { contingents: any[] }) => {
            entry.contingents.forEach((contingent: { productCodes: string[] }) => {
                if (contingent.productCodes.some((productCode) => productCode.indexOf('domain-') >= 0)) {
                    let productCodesNew = [];
                    productCodesNew = contingent.productCodes.filter((code) => code.indexOf('-renew') >= 0);
                    if (productCodesNew.length === 1) { // domain renew product code found
                        if (contingent.productCodes.length === 1) { // if array only contains renew code
                            // add the missing transfer and create code
                            productCodesNew.push(productCodesNew[0].replace('-renew', '-transfer'));
                            productCodesNew.push(productCodesNew[0].replace('-renew', '-create'));
                        } else { // seems like codes are there ... just make sure renew is the first!
                            contingent.productCodes.forEach((productCode) => {
                                if (productCode.indexOf('-renew') < 0) {
                                    productCodesNew.push(productCode);
                                }
                            });
                        }
                        contingent.productCodes = productCodesNew;
                    }
                }
            });
        });
        return result;
    };

    private refreshDeposit = (status: RequestStatus = RequestStatus.SUCCESSFUL) => (result) => {
        this.functionRelay.call('updateDeposit', {});

        if (status === RequestStatus.SUCCESSFUL) {
            return result;
        } else {
            return q.reject(result);
        }
    };
}
