import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateDatabaseWizardController {
    public static $inject: string[] = ['$state', '$translate'];

    public pageHeaderData: PanelHeaderData;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {
        const stateInBundle = this.$state.current.name.split('.')[0] === 'bundle';
        const stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';

        let panelHeaderBackwardLink: string;
        let panelHeaderBackwardText: string;

        switch (true) {
            case stateInBundle:
                panelHeaderBackwardLink = 'bundle.id.databases.overview';
                panelHeaderBackwardText = this.$translate.instant('TR_100119-ab6cc1_TR');
                break;
            case stateInMachine:
                panelHeaderBackwardLink = 'managed-servers.id.dashboard';
                panelHeaderBackwardText = this.$translate.instant('TR_220319-1ac2e3_TR');
                break;
            default:
                panelHeaderBackwardLink = 'database.dashboard';
                panelHeaderBackwardText = this.$translate.instant('TR_100119-ab6cc1_TR');
        }

        this.pageHeaderData = {
            backwardLink: panelHeaderBackwardLink,
            backwardText: panelHeaderBackwardText,
            panelHeaderRoute: '',
            panelHeaderRouteParams: {},
            panelIcon: 'database',
            panelTitle: this.$translate.instant('TR_100119-fb14b8_TR')
        };
    }
}

export class TemplateDatabaseWizardComponent implements ng.IComponentOptions {
    public template = require('./database-wizard-template.html');
    public controller = TemplateDatabaseWizardController;
}
