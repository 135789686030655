import { LinkListOverviewItem } from '../../atomic-components/molecules/links/link-list-overview/link-list-overview';
import { AccountFilterField, SelectFilterField, TextFilterField } from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { UiRights } from '../../configuration';
import { OverviewModel } from '../abstract';
import { AuthContextService } from '../auth-context';
import { RequestCancellerService } from '../request-canceller';
import { NameserverSetModelService } from './nameserver-set-model';

export class NameserverSetOverviewModel extends OverviewModel {

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = null;
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', 'nameserverSetModel', 'authContext', '$q', 'objectStatusFilter']
    );

    readonly service = 'nameserverset';
    readonly objectType = 'NameserverSet';
    readonly idField = 'nameserverSetId';
    readonly defaultFilterFields = ['nameserverSetName'];

    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};

    protected listCallbacks = [{callback: this.nameserverSetModel.list}];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private nameserverSetModel: NameserverSetModelService,
        private authContext: AuthContextService,
        private $q: ng.IQService,
        objectStatusFilter,
        objectTypeFilter
    ) {
        super(RequestCanceller);
        this.actionLinksAvailabilities = {
            deletableNameserverSets: this.deletableNameserverSets
        };
    }

    public actionLinks = (params: { [param: string]: any }): LinkListOverviewItem[] => {
        return [
            {
                text: this.$translate.instant('TR_140119-080106_TR'),
                route: '',
                type: 'multi',
                isGranted: this.authContext.isGrantedAny([UiRights.DOM_DOMAINS_DELETE]),
                isClickableMethod: true,
                onlyOnExperView: true,
                linkList: [{
                    text: this.$translate.instant('TR_140119-aee71e_TR'),
                    route: '',
                    onclickMethode: () => this.activeMassAction = 'delete',
                    isClickable: 'deletableNameserverSets',
                    group: 'delete',
                    groupName: this.$translate.instant('TR_140119-aee71e_TR'),
                    dataE2eElementId: 'mass-action-delete-nameserver-set'
                }]
            }, {
                text: this.$translate.instant('TR_140119-f391d0_TR'),
                route: 'dns.nameserversets.new',
                isGranted: this.authContext.isGranted(UiRights.DNS_NSSETS_CREATE)
            }
        ] as LinkListOverviewItem[];
    };

    public deletableNameserverSets = (nameserverSets: any[]) => {
        for (const nameserverSet of nameserverSets) {
            if (this.selected[nameserverSet.id]) {
                return true;
            }
        }
        return false;
    };

    public deleteSubmit = (nameserverSets: any[]) => {
        const setsToDelete: any[] = [];
        for (const nameserverSet of nameserverSets) {
            if (this.selected[nameserverSet.id]) {
                setsToDelete.push(nameserverSet);
            }
        }
        return this.nameserverSetModel.delete(setsToDelete);
    };

}
