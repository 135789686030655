import * as ng from 'angular';
import {
    EditFormMessage,
    EditFormSavingStatus,
    EditFormStatus,
    EditPanelStatus,
    MoleculeFormEditController,
    PanelType
} from '../../forms/form-edit/form-edit';

type Validator = () => true|any;

export class MoleculePanelReissueController implements ng.IController {

    public get reissueButtonModifier() {
        return this.agreements ? 'active' : 'inactive';
    }

    /**
     * Returns true if all input elements within the panel are free of errors,
     * false otherwise.
     * Warning: potentially slow, because it has to check ALL input fields!
     */
    public get $valid() {
        return Object.keys(this.$validators)
        .every(key => this.$validators[key]() === true);
    }

    /**
     * Returns true if some input element within the panel has an error,
     * false otherwise.
     * Faster than $valid if there are errors, because the check is finished as soon as
     * the first error is found.
     */
    public get $invalid() {
        return Object.keys(this.$validators)
        .some(key => this.$validators[key]() !== true);
    }

    public get isEditable() {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showCancelButton() {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showEditButton() {
        return [
            EditPanelStatus.READONLY
        ].indexOf(this.status) >= 0;
    }

    public get showReissueButton() {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showEditableContent() {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showReadonlyContent() {
        return [
            EditPanelStatus.READONLY
        ].indexOf(this.status) >= 0;
    }

    public get showSavingContent() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get reissueButtonLoading() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get disableCancelButton() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get disableEditButton() {
        return !this.$editForm.canEnablePanel(this.name);
    }

    public get disableReissueButton() {
        return this.$editForm.$invalid
            || (this.$editForm.$status === EditFormStatus.SAVING)
            || (this.agreements !== true);
    }

    public get disableInputElements() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get readonlyTranscludeFilled() {
        return this.$transclude.isSlotFilled('readonly');
    }

    public get editableTranscludeFilled() {
        return this.$transclude.isSlotFilled('editable');
    }

    public get revokingTranscludeFilled() {
        return this.$transclude.isSlotFilled('revoking');
    }
    public static $inject: string[] = ['$transclude', '$translate'];
    public name = 'reissue';
    public panelIcon: string;
    public changesAffectPayment: boolean;
    public agreements = false;
    public panelTitle: string;
    readonly type = PanelType.MISCELLANEOUS_ACTION;

    // Required parent controller
    public $editForm: MoleculeFormEditController;

    public $validators: { [key: string]: Validator } = {};

    private status: EditPanelStatus = EditPanelStatus.READONLY;

    constructor(
        private $transclude,
        protected $translate: ng.translate.ITranslateService
    ) {}

    /* tslint:disable-next-line:no-empty */
    public cancelCallback: () => void = () => {};

    public $onInit = () => {
        this.$editForm.register(this);
        this.panelTitle = this.panelTitle || this.$translate.instant('TR_100119-bb1cd1_TR');
    };

    public $onDestroy = () => {
        this.$editForm.unregister(this);
    };

    public onMessage = (message: EditFormMessage<any>) => {
        switch (message.type) {
            default: return;

            case 'statusUpdate':
                switch (message.message) {
                    default: return;

                    case EditFormSavingStatus.SAVING:
                        // Currently doesn't have an effect here,
                        // all logic using "saving" status already uses the form's status.
                        return;

                    case EditFormSavingStatus.ERROR:
                        // TODO: Use logic. Error status if there is an error in this box, editable otherwise.
                        if (this.status !== EditPanelStatus.READONLY) {
                            this.status = EditPanelStatus.ACTIVE;
                        }
                        return;

                    case EditFormSavingStatus.READY:
                        // After successfully saving, return all panels to readonly status.
                        this.status = EditPanelStatus.READONLY;
                        return;
                }
        }
    };

    public activate = () => {
        this.status = EditPanelStatus.ACTIVE;
    };

    public reissue = () => {
        if (this.$editForm.$invalid) {
            return;
        }

        this.$editForm.reissue();
    };

    public cancel = () => {
        this.status = EditPanelStatus.READONLY;
        this.cancelCallback();
    };

    public successfullySaved = () => {
        this.status = EditPanelStatus.READONLY;
    };
}

export class MoleculePanelReissueComponent implements ng.IComponentOptions {
    public bindings = {
        cancelCallback: '<cancel',
        name: '@',
        panelTitle: '@title',
        panelIcon: '@icon',
        changesAffectPayment: '<paid',
        panelRight: '<',
        agreements: '<'
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public transclude = {
        editable: '?panelContentEditable',
        error: '?panelContentError',
        readonly: '?panelContentReadonly',
        revoking: '?panelContentRevoking'
    };
    public controller =  MoleculePanelReissueController;
    public controllerAs = '$reissuePanel';
    public template = require('./panel-reissue.html');
}
