import ng from 'angular';
import { DomainHelper } from '@/services';
import './panel-edit-row-nameserver-list.scss';
import { ViewTypes } from '@/types';

export class MoleculePanelEditRowNameserverListController implements ng.IController {
    public static $inject: string[] = ['$timeout', '$translate'];

    public callbackOnChange: () => void;
    public domains: string[];
    public glueRecord = false;
    public nameserverList: ViewTypes.OwnNameserver[];
    public nameserversComplete = false;

    public constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit(): void {
        this.glueRecord = this.glueRecord || false;
        if (this.nameserverList.length === 0) {
            for (let i = 0; i < 2; i++) { this.addServer(); }
        } else {
            this.checkNameserverItemsCallback();
        }
    }

    public get showGlueCallout(): boolean {
        return this.nameserverList.some(
            (nameserver: ViewTypes.Nameservers) => this.showIps(nameserver)
        );
    }

    public checkNameserverItemsCallback = (): void => {
        void this.$timeout(() => {
            this.nameserversComplete = this.nameserverList.every((nameserver) => {
                if (
                    (nameserver.ipsRequired && nameserver.ips?.length === 0)
                    || nameserver.nameserverErrors?.length > 0
                ) {
                    return false;
                }

                // Check for duplicates.
                const nameserverNames = this.nameserverList.map(
                    (nameserverFromList) => nameserverFromList.name
                );
                for (const nameserverName of nameserverNames) {
                    if (nameserverName === '') {
                        continue;
                    }

                    const nameserverNameEntries = this.nameserverList.filter(
                        (nameserverFromList) => {
                            if (nameserverFromList.name === nameserverName) {
                                return true;
                            }

                            // reset error list
                            nameserverFromList.nameserverErrors = [];
                            return false;
                        }
                    );
                    if (nameserverNameEntries.length > 1) {
                        for (const nameserverNameEntry of nameserverNameEntries) {
                            nameserverNameEntry.nameserverErrors = [{
                                text: this.$translate.instant('TR_110221-4e5f2d_TR')
                            }];
                        }
                        return false;
                    }
                }

                return [undefined, null, ''].indexOf(nameserver.name) < 0;
            });

            if (this.callbackOnChange) {
                this.callbackOnChange();
            }
        });
    };

    public addServer = (): void => {
        this.nameserverList.push({
            ips: [],
            name: '',
            ipsRequired: false,
            nameserverErrors: []
        });

        if (this.callbackOnChange) {
            this.callbackOnChange();
        }
    };

    public showIps = (server: ViewTypes.Nameservers): boolean => {
        if ([undefined, null, ''].indexOf(server.name) >= 0 || this.domains === undefined) {
            server.ipsRequired = false;
        } else {
            server.ipsRequired = this.domains.some(
                (domain) => {
                    return DomainHelper.isNameserverInDomain(server.name, domain);
                }
            );
        }

        if (!server.ipsRequired) {
            server.ips = [];
        }

        return server.ipsRequired;
    };

    public removeNameserverFromList = (index: number): void => {
        this.nameserverList.splice(index, 1);

        if (this.callbackOnChange) {
            this.callbackOnChange();
        }
    };

    public hideOnGlueRecord = (server: ViewTypes.Nameservers): boolean => {
        if (!this.glueRecord || !server) {
            return false;
        }

        return !this.showIps(server);
    };
}

export class MoleculePanelEditRowNameserverListComponent implements ng.IComponentOptions {
    public bindings = {
        domains: '<',
        glueRecord: '<',
        nameserverList: '=nameservers',
        nameserversComplete: '=?',
        callbackOnChange: '<?'
    };

    /* tslint:disable-next-line:max-line-length */
    public template = require('./panel-edit-row-nameserver-list.html');
    public controller = MoleculePanelEditRowNameserverListController;
}
