export const TopLevelDomains = {
    groups: [
        {
            name: /* translationId */ 'TR_TLD_GROUPS_TOP15_TR',
            tlds: [
                '.de',
                '.at',
                '.ch',
                '.eu',
                '.nl',
                '.com',
                '.net',
                '.org',
                '.biz',
                '.info',
                '.berlin',
                '.club',
                '.guru',
                '.photography',
                '.ninja'
            ]
        },
        {
            name: /* translationId */ 'TR_TLD_GROUPS_EUROPE_TR',
            tlds: [
                '.at',
                '.be',
                '.bg',
                '.ch',
                '.de',
                '.dk',
                '.es',
                '.eu',
                '.fi',
                '.fr',
                '.gr',
                '.hr',
                '.hu',
                '.ie',
                '.it',
                '.lt',
                '.lv',
                '.me',
                '.nl',
                '.pl',
                '.pt',
                '.ro',
                '.se',
                '.si',
                '.sk',
                '.uk'
            ]
        },
        {
            name: /* translationId */ 'TR_TLD_GROUPS_ASIA_TR',
            tlds: [
                '.ae',
                '.af',
                '.cn',
                '.hk',
                '.in',
                '.io',
                '.ir',
                '.jp',
                '.kg',
                '.kr',
                '.mn',
                '.ps',
                '.qa',
                '.sa',
                '.sg',
                '.tl',
                '.tw',
                '.vn'
            ]
        },
        {
            name: /* translationId */ 'TR_TLD_GROUPS_AMERICA_TR',
            tlds: [
                '.ag',
                '.ai',
                '.bo',
                '.bz',
                '.ca',
                '.cl',
                '.co',
                '.cr',
                '.dm',
                '.do',
                '.ec',
                '.gd',
                '.gl',
                '.gs',
                '.gy',
                '.hn',
                '.ht',
                '.lc',
                '.ms',
                '.mx',
                '.ni',
                '.com.pa',
                '.pe',
                '.pm',
                '.com.sv',
                '.sx',
                '.tc',
                '.us',
                '.uy',
                '.com.uy',
                '.vc',
                '.vg'
            ]
        },
        {
            name: /* translationId */ 'TR_TLD_GROUPS_GENERIC_TR',
            tlds: [
                '.com',
                '.net',
                '.org',
                '.biz',
                '.info',
                '.name',
                '.mobi',
                '.pro',
                '.aero',
                '.asia'
            ]
        },
        {
            name: /* translationId */ 'TR_TLD_GROUPS_NEW_ART_TR',
            tlds: [
                '.actor',
                '.audio',
                '.band',
                '.country',
                '.dance',
                '.design',
                '.events',
                '.gallery',
                '.hiphop',
                '.movie',
                '.news',
                '.photo',
                '.photography',
                '.photos',
                '.pictures',
                '.show',
                '.theater',
                '.video'
            ]
        },
        {
            name: /* translationId */ 'TR_TLD_GROUPS_NEW_BUSINESS_TR',
            tlds: [
                '.associates',
                '.bio',
                '.business',
                '.ceo',
                '.company',
                '.enterprises',
                '.foundation',
                '.industries',
                '.limited',
                '.ltda',
                '.partners',
                '.press',
                '.sarl',
                '.solutions',
                '.trade',
                '.ventures'
            ]
        },
        {
            name: /* translationId */ 'TR_TLD_GROUPS_NEW_CITY_TR',
            tlds: [
                '.berlin',
                '.brussels',
                '.bzh',
                '.capetown',
                '.city',
                '.cologne',
                '.durban',
                '.hamburg',
                '.joburg',
                '.koeln',
                '.london',
                '.melbourne',
                '.miami',
                '.nagoya',
                '.nyc',
                '.paris',
                '.tokyo',
                '.town',
                '.wien',
                '.yokohama'
            ]
        },
        {
            name: /* translationId */ 'TR_TLD_GROUPS_NEW_EDUCATION_TR',
            tlds: [
                '.academy',
                '.college',
                '.degree',
                '.education',
                '.esq',
                '.institute',
                '.mba',
                '.phd',
                '.prof',
                '.school',
                '.schule',
                '.science',
                '.shiksha',
                '.training',
                '.university'
            ]
        },
        {
            name: /* translationId */ 'TR_TLD_GROUPS_NEW_FOOD_TR',
            tlds: [
                '.bar',
                '.beer',
                '.cafe',
                '.coffee',
                '.kitchen',
                '.menu',
                '.pizza',
                '.recipes',
                '.restaurant',
                '.vodka'
            ]
        },
        {
            name: /* translationId */ 'TR_TLD_GROUPS_NEW_CULTURE_TR',
            tlds: [
                '.bayern',
                '.cymru',
                '.global',
                '.international',
                '.nrw',
                '.okinawa',
                '.place',
                '.quebec',
                '.ruhr',
                '.ryukyu',
                '.saarland',
                '.tirol',
                '.vlaanderen',
                '.wales',
                '.world'
            ]
        },
        {
            name: /* translationId */ 'TR_TLD_GROUPS_NEW_LIFESTYLE_TR',
            tlds: [
                '.church',
                '.community',
                '.date',
                '.dating',
                '.democrat',
                '.diet',
                '.exposed',
                '.express',
                '.faith',
                '.hiv',
                '.jetzt',
                '.kiwi',
                '.lgbt',
                '.life',
                '.love',
                '.republican',
                '.scot',
                '.singles',
                '.style',
                '.uno',
                '.vision',
                '.voting',
                '.voto',
                '.wang',
                '.wedding'
            ]
        },
        {
            name: /* translationId */ 'TR_TLD_GROUPS_NEW_INTERESTS_TR',
            tlds: [
                '.camera',
                '.cards',
                '.care',
                '.cooking',
                '.dog',
                '.earth',
                '.garden',
                '.gives',
                '.guitars',
                '.ink',
                '.juegos',
                '.organic',
                '.pics',
                '.report',
                '.review',
                '.reviews',
                '.solar',
                '.soy',
                '.space',
                '.wiki'
            ]
        },
        {
            name: /* translationId */ 'TR_TLD_GROUPS_NEW_MONEY_TR',
            tlds: [
                '.capital',
                '.cash',
                '.credit',
                '.creditcard',
                '.estate',
                '.fund',
                '.gold',
                '.holdings',
                '.loans',
                '.money',
                '.tax'
            ]
        },
        {
            name: /* translationId */ 'TR_TLD_GROUPS_NEW_NOVELTY_TR',
            tlds: [
                '.best',
                '.black',
                '.blue',
                '.buzz',
                '.cool',
                '.fail',
                '.foo',
                '.fyi',
                '.gratis',
                '.gripe',
                '.kim',
                '.lol',
                '.moe',
                '.mov',
                '.nexus',
                '.ninja',
                '.pink',
                '.plus',
                '.red',
                '.rip',
                '.rocks',
                '.today',
                '.wtf',
                '.xyz',
                '.zip',
                '.zone'
            ]
        },
        {
            name: /* translationId */ 'TR_TLD_GROUPS_NEW_REAL_ESTATE_TR',
            tlds: [
                '.casa',
                '.condos',
                '.haus',
                '.house',
                '.homes',
                '.immo',
                '.immobilien',
                '.land',
                '.lease',
                '.maison',
                '.properties',
                '.property',
                '.villas'
            ]
        },
        {
            name: /* translationId */ 'TR_TLD_GROUPS_NEW_RECREATION_TR',
            tlds: [
                '.bingo',
                '.camp',
                '.casino',
                '.club',
                '.cruises',
                '.fish',
                '.fishing',
                '.holiday',
                '.horse',
                '.party',
                '.poker',
                '.pub',
                '.reise',
                '.reisen',
                '.social',
                '.surf',
                '.vacations',
                '.vegas',
                '.viajes',
                '.voyage'
            ]
        },
        {
            name: /* translationId */ 'TR_TLD_GROUPS_NEW_SERVICES_TR',
            tlds: [
                '.accountants',
                '.auto',
                '.autos',
                '.agency',
                '.apartments',
                '.archi',
                '.attorney',
                '.build',
                '.builders',
                '.cab',
                '.career',
                '.careers',
                '.catering',
                '.claims',
                '.cleaning',
                '.clinic',
                '.construction',
                '.consulting',
                '.contractors',
                '.delivery',
                '.dental',
                '.dentist',
                '.direct',
                '.directory',
                '.domains',
                '.energy',
                '.engineer',
                '.engineering',
                '.expert',
                '.farm',
                '.finance',
                '.financial',
                '.fit',
                '.fitness',
                '.flights',
                '.florist',
                '.flowers',
                '.glass',
                '.guide',
                '.guru',
                '.healthcare',
                '.help',
                '.hockey',
                '.insure',
                '.investments',
                '.lawyer',
                '.legal',
                '.limo',
                '.management',
                '.marketing',
                '.memorial',
                '.mortgage',
                '.physio',
                '.plumbing',
                '.productions',
                '.rehab',
                '.rentals',
                '.repair',
                '.rest',
                '.services',
                '.support',
                '.surgery',
                '.tattoo',
                '.taxi',
                '.tips',
                '.tours',
                '.versicherung',
                '.vet',
                '.work',
                '.works'
            ]
        },
        {
            name: /* translationId */ 'TR_TLD_GROUPS_NEW_SHOPPING_TR',
            tlds: [
                '.auction',
                '.bargains',
                '.bid',
                '.blackfriday',
                '.boutique',
                '.center',
                '.cheap',
                '.christmas',
                '.clothing',
                '.coupons',
                '.deals',
                '.diamonds',
                '.discount',
                '.equipment',
                '.exchange',
                '.fashion',
                '.forsale',
                '.furniture',
                '.gift',
                '.gifts',
                '.jewelry',
                '.kaufen',
                '.lighting',
                '.luxury',
                '.market',
                '.moda',
                '.parts',
                '.sale',
                '.shoes',
                '.supplies',
                '.supply',
                '.tickets',
                '.tienda',
                '.tires',
                '.tools',
                '.toys',
                '.watch'
            ]
        },
        {
            name: /* translationId */ 'TR_TLD_GROUPS_NEW_SPORTS_TR',
            tlds: [
                '.bike',
                '.coach',
                '.cricket',
                '.football',
                '.futbol',
                '.golf',
                '.rodeo',
                '.run',
                '.ski',
                '.soccer',
                '.team',
                '.tennis',
                '.yoga'
            ]
        },
        {
            name: /* translationId */ 'TR_TLD_GROUPS_NEW_TECH_TR',
            tlds: [
                '.chat',
                '.click',
                '.codes',
                '.computer',
                '.digital',
                '.email',
                '.graphics',
                '.host',
                '.hosting',
                '.link',
                '.media',
                '.network',
                '.online',
                '.site',
                '.software',
                '.systems',
                '.tech',
                '.technology',
                '.webcam',
                '.website'
            ]
        }
    ]
};
