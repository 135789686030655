import * as ng from 'angular';
import {
    AlertManagerService,
    DomainModelService,
    NavigationService
} from '../../../../../services';

export class OrganismEditFormBundleDomainsTransferontroller {
    public static $inject: string[] = ['$q', '$translate', 'alertManager', 'domainModel', 'navigation'];

    public originalDomains: any;
    public domains: any = {};
    public userPanelRight: any;
    public selected: any = {};

    private selectedIds: (string | number | symbol)[] = [];

    constructor(
        private $q: ng.IQService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private domainModel: DomainModelService,
        private navigation: NavigationService
    ) {}

    public save = () => {
        const promises = this.domains
        .filter((domain) => this.selectedIds.indexOf(domain.id) >= 0)
        .map(
            (domain: { transferLockEnabled: boolean }) => {
                domain.transferLockEnabled = false;
                return this.domainModel.update(domain);
            }
        );

        if (promises.length > 0) {
            this.$q.all(promises)
            .then(
                () => {
                    this.alertManager.success(this.$translate.instant('TR_100119-9575b9_TR'));
                    this.navigation.reloadCurrentState();
                }
            );
        }
    };

    public $onInit = () => {
        this.domains = ng.copy(this.originalDomains);

        this.domains.map((domain) => Object.defineProperty(
            this.selected,
            domain.id,
            {
                get: () => this.selectedIds.indexOf(domain.id) >= 0,
                set: (value: boolean) => {
                    if (value) {
                        this.selectedIds.push(domain.id);
                    } else {
                        this.selectedIds = this.selectedIds.filter( (id) => id !== domain.id );
                    }
                }
            }
        ));
    };

    public cancelTransfer = () => {
        this.domains.storageQuota = this.originalDomains.storageQuota;
    };
}

export class OrganismEditFormBundleDomainsTransferComponent implements ng.IComponentOptions {
    public bindings = {
        originalDomains: '<domains',
        userPanelRight: '='
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormBundleDomainsTransferontroller;
    public template = require('./bundle-domains-transfer.html');
}
