import * as ng from 'angular';
import * as q from 'q';

import { EditPanelStatus } from '@/atomic-components/molecules';
import { ManagedApplicationRobotService, NavigationService } from '@/services';
import * as Types from '@/types';

import './nextcloud-use-own-domain.scss';

export class OrganismEditFormNextcloudUseOwnDomainController {
    public static $inject: string[] = [
        '$state',
        '$timeout',
        'managedApplicationRobot',
        'navigation'
    ];

    public autoAdjustRecords = false;
    public initialDomain = '';
    public initialStatus: EditPanelStatus = EditPanelStatus.ACTIVE;
    public domainChecking = false;
    public nextcloud: Types.ManagedApplicationApi.Nextcloud;
    public removeExistingDomain = false;
    public showDnsAutoConfigDetails = false;
    public vhostExists = false;

    private _domainName = '';
    private _debounceCounter = 0;
    private _checkResponse: Types.ManagedApplicationApi.NextcloudIndividualDomainNameStatus = null;
    private _currentlySendingRequest = false;
    private _replaceExistingVHost = false;

    constructor(
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private managedApplicationRobot: ManagedApplicationRobotService,
        private navigation: NavigationService
    ) { }

    public $onInit = (): void => {
        if (this.nextcloud.individualDomainNameStatus === 'active') {
            this._domainName = this.nextcloud.individualDomainNameUnicode;
        }
    };

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set vhostMustBeDeleted({ }) { }
    public get vhostMustBeDeleted(): boolean {
        return this.domainChecking === false
            && this.checkResponse !== null
            && this.checkResponse.vHostStatus === 'mustBeDeleted';
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set dnsAdjustment({ }) { }
    public get dnsAdjustment(): boolean {
        return this.domainChecking === false
            && this.checkResponse !== null
            && this.checkResponse.status !== 'ready'
            && ['autoConfigurationAvailable', 'manualAdjustmentRequired'].indexOf(this.checkResponse.dnsStatus) >= 0;
    }

    public set replaceExistingVHost(replaceExistingVHost: boolean) {
        this._replaceExistingVHost = replaceExistingVHost;
    }
    public get replaceExistingVHost(): boolean {
        return this._replaceExistingVHost;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set dnsManualAdjustment({ }) { }
    public get dnsManualAdjustment(): boolean {
        return this.domainChecking === false
            && this.checkResponse !== null
            && this.checkResponse.status !== 'ready'
            && this.checkResponse.vHostStatus === 'ready'
            && this.checkResponse.dnsStatus === 'manualAdjustmentRequired';
    }

    public set domainName(domainName: string) {
        this._domainName = domainName;

        this._debounceCounter++;
        const currentCounter = this._debounceCounter;

        void this.$timeout(
            () => {
                if (this._debounceCounter !== currentCounter) {
                    return;
                }

                this.checkDomainNameStatus();
            },
            800
        );
    }
    public get domainName(): string {
        return this._domainName;
    }

    public get showDnsPanel(): boolean {
        return this.checkResponse !== null
            && this.dnsAdjustment;
    }

    public get showDomainPanel(): boolean {
        return this.checkResponse !== null
            && (
                this.isDomainNameAlreadyUsed
                || this.showVhostMustBeDeletedInfo
            );
    }

    public get showVhostMustBeDeletedInfo(): boolean {
        if (
            this.dnsAdjustment
            && this.checkResponse.dnsStatus === 'manualAdjustmentRequired'
        ) {
            return false;
        }

        return this.vhostMustBeDeleted;
    }

    public checkDomainNameStatus = (): void => {
        this.checkResponse = null;
        if (!this._checkDomainNameIsValid(this._domainName)) {
            return;
        }

        this.domainChecking = true;
        void this._nextcloudCheckIndividualDomainNameStatus(this._domainName).then((nextcloudResult) => {
            this.checkResponse = nextcloudResult.response;
            this.domainChecking = false;
        });

    };

    public get isDomainNameAlreadyUsed(): boolean {
        return this.status === 'nameAlreadyInUse';
    }

    public get status(): string | null {
        if ([undefined, null].indexOf(this.checkResponse) >= 0) {
            return null;
        }

        return this.checkResponse.status;
    }

    public get disableSaveButton(): boolean {
        if (this.nextcloud.individualDomainNameStatus === 'active'
            && ['', undefined, null].includes(this.domainName)
        ) {
            return false;
        }

        if (
            [undefined, null].indexOf(this.checkResponse) >= 0
            || this._currentlySendingRequest
        ) {
            return true;
        }

        switch (true) {
            case this.checkResponse.status === 'ready':
                return false;
            case this.isDomainNameAlreadyUsed:
            case this.checkResponse.dnsStatus === 'manualAdjustmentRequired':
            case this.checkResponse.dnsStatus === 'autoConfigurationAvailable'
                && !this.autoAdjustRecords:
            case this.vhostMustBeDeleted
                && !this.replaceExistingVHost:
                return true;
            default:
                return false;
        }
    }

    public set checkResponse(value) {
        this._checkResponse = value;
    }
    public get checkResponse(): Types.ManagedApplicationApi.NextcloudIndividualDomainNameStatus {
        return this._checkResponse;
    }

    public toggleDnsAutoConfigDetailsShowDetails = (): void => {
        this.showDnsAutoConfigDetails = !this.showDnsAutoConfigDetails;
    };

    public replaceExistingVHostTextClicked = (): void => {
        this._replaceExistingVHost = !this._replaceExistingVHost;
    };

    public save = (): unknown => {
        if (this.disableSaveButton) {
            return;
        }
        if (this.nextcloud.individualDomainNameStatus === 'active'
            && ['', undefined, null].includes(this.domainName)
        ) {
            this._currentlySendingRequest = true;
            return this.managedApplicationRobot.nextcloudRemoveIndividualDomainName(
                this.nextcloud.id
            )
                .then(
                    (response) => {
                        if (['pending', 'success'].includes(response.status)) {
                            void this.navigation.go(
                                this.$state.current.name.replace('use-own-domain', 'edit'),
                                {},
                                { reload: true }
                            );
                        }
                    },
                    (apiError) => {
                        this._currentlySendingRequest = false;
                        return Promise.reject(apiError);
                    }
                );
        } else {
            this._currentlySendingRequest = true;
            return this.managedApplicationRobot.nextcloudSetupIndividualDomainName(
                this.nextcloud.id,
                this._domainName,
                this.autoAdjustRecords && this.checkResponse.dnsStatus === 'autoConfigurationAvailable',
                this.replaceExistingVHost
            )
                .then(
                    (response) => {
                        if (['pending', 'success'].includes(response.status)) {
                            void this.navigation.go(
                                this.$state.current.name.replace('use-own-domain', 'edit'),
                                {},
                                { reload: true }
                            );
                        }
                    },
                    (apiError) => {
                        this._currentlySendingRequest = false;
                        return q.reject(apiError);
                    }
                );
        }
    };

    public toggleRemoveExistingDomain = (): void => {
        void this.$timeout(() => {
            if (this.removeExistingDomain) {
                this.initialDomain = ng.copy(this.domainName);
                this.domainName = '';
            } else {
                this._domainName = ng.copy(this.initialDomain);
            }
        });
    };

    private _nextcloudCheckIndividualDomainNameStatus = (
        domainName: string
    ): PromiseLike<Types.ManagedApplicationApi.NextcloudIndividualDomainNameStatus> => {
        return this.managedApplicationRobot.nextcloudCheckIndividualDomainNameStatus(
            this.nextcloud.id,
            domainName
        ) as PromiseLike<Types.ManagedApplicationApi.NextcloudIndividualDomainNameStatus>;
    };

    private _checkDomainNameIsValid = (domainName: string): boolean => {
        return !(
            domainName.split('.').length < 2
            || domainName.split('.').pop().length < 2
        );
    };
}

export class OrganismEditFormNextcloudUseOwnDomainComponent implements ng.IComponentOptions {
    public bindings = {
        nextcloud: '<'
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormNextcloudUseOwnDomainController;
    public template = require('./nextcloud-use-own-domain.html'); // tslint:disable-line:max-line-length
}
