import * as ng from 'angular';
import { UiLanguagesConst } from '../../../../configuration';
import { AlertManagerService, AuthContextService, InstallerModelService } from '../../../../services';

export class OrganismFormAppInstallController implements ng.IController {
    public static $inject: string[] = ['$translate', 'alertManager', 'authContext', 'installerModel'];

    public apps: any[];
    public selectedApp: any;
    public serviceObject: any;
    public language: string;
    public appSettingsCompleted: boolean;
    public databaseSettingsCompleted: boolean;
    public vHostSettingsCompleted: boolean;
    public bundleId: string | null;
    public showOverlay = false;
    public vHost: any;
    public inWizard: boolean;
    public completed: boolean;
    public databaseRessource: any;

    private _showDatabaseSelect: boolean;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private authContext: AuthContextService,
        public installerModel: InstallerModelService
    ) {}

    public $onInit() {
        this.serviceObject = this.serviceObject || {};
        this.inWizard = this.inWizard || false;
        this.installerModel.installFinished = false;
        this.installerModel.installFailed = false;
        this.bundleId = this.bundleId || null;
        this._showDatabaseSelect = this._showDatabaseSelect === undefined ? true : this._showDatabaseSelect;
        this.language = UiLanguagesConst[this.authContext.user.language];
    }

    public get appSelected() {
        return this.selectedApp !== undefined && this.selectedApp !== null;
    }

    public get showAppSelector() {
        // call formCompleted getter to get complete status
        // if component called from wizard (in wizard is no install button included)
        this.formCompleted;  // tslint:disable-line
        return this.apps !== undefined;
    }

    public get showAppSettingsPanel() {
        return this.selectedApp.data.requires.appSettings !== undefined;
    }

    public get showDatabaseSettingsPanel() {
        return this.selectedApp.data.requires.dbSettings !== undefined && this.appSettingsCompleted;
    }

    public get showVhostSettingsPanel() {
        return this.selectedApp.data.requires.vHostSettings !== undefined
        && (this.selectedApp.data.requires.appSettings === undefined || this.appSettingsCompleted)
        && (this.selectedApp.data.requires.dbSettings === undefined || this.databaseSettingsCompleted);
    }

    public get showDbSettingPanel() {
        return this.selectedApp !== undefined
            && [undefined, null].indexOf(this.selectedApp.data.requires.dbSettings) === -1
            && this.appSettingsCompleted
            && this._showDatabaseSelect;
    }

    public get formCompleted() {
        if (!this.appSelected) {
            this.completed = false;
            return false;
        }

        this.completed = (this.selectedApp.data.requires.vHostSettings === undefined || this.vHostSettingsCompleted)
            && (this.selectedApp.data.requires === undefined || this.appSettingsCompleted)
            && (this.inWizard || this.selectedApp.data.requires.dbSettings === undefined
            || this.databaseSettingsCompleted);

        return this.completed;
    }

    public set formCompleted(value) {    // dummy function we need to use data binding
        return;
    }

    public get appId() {
        if (!this.appSelected) {
            return undefined;
        }

        return this.selectedApp.general.pkgName;
    }

    public set appId(value: string) {
        if (this.apps === undefined) {
            return;
        }
        for (const app of this.apps) {
            if (app.general.pkgName === value) {
                this.selectedApp = app;
            }
        }
    }

    public callbackOnAppChange = (selection: any) => {
        this.serviceObject = {
            appId: selection,
            appSettings: {},
            database: {}
        };
    };

    public installApp = () => {
        this.showOverlay = true;

        this.installerModel.installApp(this.vHost, this.appId, this.serviceObject).then(
            (reply) => {
                this.showOverlay = false;
                if (reply) {
                    this.installerModel.installFinished = true;

                    if ([undefined, null].indexOf(this.bundleId) === -1) {
                        this.installerModel.redirectionTargetPath = 'bundle.id.domains.id.edit';
                    } else {
                        this.installerModel.redirectionTargetPath = 'webhosting.webspaces.id.vhosts.id.edit';
                    }
                } else {
                    this.alertManager.error(this.$translate.instant('TR_140119-0e72a4_TR') );
                    this.installerModel.installFailed = true;
                }

            },
            (reply) => {
                this.showOverlay = false;
                this.installerModel.installFailed = true;
            }
        );
    };
}

export class OrganismFormAppInstallComponent implements ng.IComponentOptions {
    public bindings = {
        _showDatabaseSelect: '<showDatabaseSelect',
        apps: '<',
        bundleId: '<',
        completed: '=?',
        databaseRessource: '<?',
        inWizard: '<',
        // The naming 'serviceObject' is a bit confusing here.
        // Actually it's only a part of the wizarf serviceObject (= settings.appSettings)
        serviceObject: '=?',
        vHost: '<'
    };
    public controller = OrganismFormAppInstallController;
    public template = require('./app-install.html');
}
