import * as ng from 'angular';
import { MoleculeFormEditController } from '@/atomic-components/molecules';
import { ManagedMachineProjectObject } from '@/atomic-components/organisms';
import {
    AccountModelService,
    AuthContextService,
    ManagedMachineProjectSubaccountTypes
} from '@/services';
import * as Types from '@/types';

export class OrganManagedServerAccountSelectionController implements ng.IController {
    public static $inject: string[] = ['$state', 'accountModel'];

    public $editForm: MoleculeFormEditController;
    public projectData: ManagedMachineProjectObject;
    public selectedAccountId: string = null;
    public subaccounts: Types.AccountApi.Subaccount[] = [];
    public accountTypes = {
        existend: ManagedMachineProjectSubaccountTypes.EXISTEND,
        new: ManagedMachineProjectSubaccountTypes.NEW,
        none: ManagedMachineProjectSubaccountTypes.NONE
    };
    private _accountEmailInUse = false;
    private _hasSubAccountRight = false;
    private _lastValidSetEmailAdress = '';
    private _loadSubaccountItems = true;

    constructor(
        private $state: ng.ui.IStateService,
        private accountModel: AccountModelService
    ) {}

    public $onInit(): void {
        this._hasSubAccountRight = AuthContextService.isGranted('ACC_SUBACCOUNT_LIST');
        this._loadSubaccounts();
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set complete({}) {}
    public get complete(): boolean {
        if (this.projectData === undefined) {
            return false;
        }

        switch (this.projectData.accountType) {
            case ManagedMachineProjectSubaccountTypes.EXISTEND:
                this.projectData.accountId = this.selectedAccountId;
                return [undefined, null, ''].indexOf(this.selectedAccountId) === -1;
            case ManagedMachineProjectSubaccountTypes.NONE:
                this.projectData.accountId = this.$state.$current.locals.globals.machine.accountId;
                return true;
            case ManagedMachineProjectSubaccountTypes.NEW:
                this.projectData.accountId = null;

                return !this.accountEmailInUse
                    && this.projectData.newSubAccount?.name?.length > 0
                    && this.projectData.newSubAccount?.country?.length > 0
                    && this.projectData.newSubAccount?.emailAddress?.length > 0
                    && this.projectData.newSubAccount?.password?.length > 0;
            default:
                return false;
        }
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set hasSubaccountListRight({}) {}
    public get hasSubaccountListRight(): boolean {
        return this._hasSubAccountRight;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set loadSubaccountItems({}) {}
    public get loadSubaccountItems(): boolean {
        return this._loadSubaccountItems;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set tmpEmailAdress({}) {}
    public get tmpEmailAdress(): string {
        return this._lastValidSetEmailAdress;
    }

    public set accountEmailInUse(value) {
        this._accountEmailInUse = value;
    }

    public get accountEmailInUse(): boolean {
        return this._accountEmailInUse;
    }

    public selectAccountType = (type: ManagedMachineProjectSubaccountTypes): void => {
        this.projectData.accountType = type;
    };

    public checkEmailIsAlreadyInSystem = (updatedEmail: string): void => {
        if (!this.hasSubaccountListRight) {
            return;
        }

        if (this.$editForm.$valid) {
            if (updatedEmail === AuthContextService.account.emailAddress) {
                this._accountEmailInUse = true;
                this._lastValidSetEmailAdress = updatedEmail;
                return;
            }

            void this.accountModel.listSubaccountsWithoutPagination(
                1,
                1,
                { field: 'SubAccountEmailAddress', value: updatedEmail }
            ).then(
                (accountWithSearchedEmail) => {
                    this._accountEmailInUse = accountWithSearchedEmail.data.length > 0;
                    this._lastValidSetEmailAdress = updatedEmail;
                }
            );
        }
    };

    private _loadSubaccounts = (): void => {
        if (this._hasSubAccountRight) {
            void this.accountModel.listSubaccounts().then((subAccounts) => {
                this.subaccounts = subAccounts.data;
                this._loadSubaccountItems = false;
            });
        } else {
            this.selectAccountType(ManagedMachineProjectSubaccountTypes.NONE);
            this._loadSubaccountItems = false;
        }
    };
}

export class OrganManagedServerAccountSelectionComponent implements ng.IComponentOptions {
    public bindings = {
        accountEmailInUse: '=',
        complete: '=',
        projectData: '='
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public template = require('./managed-server-account-selection-config.html');
    public controller =  OrganManagedServerAccountSelectionController;
}
