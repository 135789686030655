import { DomainInfoHelperService } from './../helpers/domain-info-helper';
import { Validator } from './abstract-validator';
import { ValidateDomainName } from './validate-domain-name';
import { ValidateSpoofCharacters } from './validate-spoof-characters';

import * as ng from 'angular';

export class ValidateEmail extends Validator {
    private maxAddressLength = 254;
    private maxLocalPartLength = 64;
    private isPlatformMailbox = false;
    private forbiddenCharactersInLocalPart = '"';
    private forbiddenCharactersInLocalPartForOwnMailboxes: string[] = [
        '*', '+', '#', '\'', '"', '/', '\\',
        '(', ')', '[', ']', '{', '}', '?', '&',
        '%', '$', '!', '^', ';', ',', '|', '~', '`',
        'ä', 'ö', 'ü', 'Ä', 'Ö', 'Ü', 'ß'
    ];
    private allowEmpty: boolean;
    private validationErrors: null|any[];

    constructor(
        allowEmpty: boolean,
        private $translate: ng.translate.ITranslateService,
        private domainInfoHelper: DomainInfoHelperService,
        isPlatformMailbox = false,
        validationErrors?: any[]
    ) {
        super();
        this.allowEmpty = allowEmpty;
        this.isPlatformMailbox = isPlatformMailbox;
        this.validationErrors = validationErrors;
    }

    /* eslint-disable complexity*/
    public validate = (value: string): { text: string }[] => {
        let reply: { text: string }[] = [];

        if ([undefined, null].indexOf(this.validationErrors) < 0 && this.validationErrors.length > 0) {
            return this.validationErrors;
        }

        // prüfen ob etwas eingegeben wurde
        if (value === undefined || value === null || value === '') {
            if (!this.allowEmpty) {
                reply.push({ text: this.$translate.instant('TR_170119-5f2e34_TR') });
            }

            return reply;
        }

        // prüfe maximale länge
        if (value.length > this.maxAddressLength) {
            reply.push(
                {
                    text: this.$translate.instant(
                        /* translationId */ 'TR_170119-b7628a_TR',
                        { maxAddressLength: this.maxAddressLength + '' })
                }
            );
        }

        // prüfe ob adresse spoof characters enthält
        const validateSpoofCharacters = new ValidateSpoofCharacters(this.$translate);
        const spoofCharacterValidation = validateSpoofCharacters.validate(value);

        if (spoofCharacterValidation) {
            reply = reply.concat(spoofCharacterValidation);
        }

        if (value.indexOf('..') >= 0) {
            reply.push({ text: this.$translate.instant('TR_170119-6ce4ce_TR') });
        }

        // prüfe ob adresse @ enthält
        if (value.indexOf('@') < 0) {
            reply.push({ text: this.$translate.instant('TR_170119-7fe779_TR') });
        } else {
            // prüfe ob adresse zu viele @ enthält
            if (value.match(/@/gi).length > 1) {
                reply.push({ text: this.$translate.instant('TR_170119-e6f084_TR') });
            } else {
                const parts = value.split('@');
                const localPart = parts[0];
                const domainPart = parts[1];

                // prüfe ob lokaler Teil existiert
                if (value[0] === '@') {
                    reply.push({ text: this.$translate.instant('TR_170119-86d2df_TR') });
                } else {
                    // prüfe Länge des lokalen Teils
                    if (localPart.length > this.maxLocalPartLength) {
                        reply.push(
                            {
                                text: this.$translate.instant(
                                    /* translationId */ 'TR_170119-06f688_TR',
                                    { maxLocalPartLength: this.maxLocalPartLength + '' }
                                )
                            }
                        );
                    }

                    let foundIllegalCharctersInLocalPart = '';

                    // prüfe ob lokaler Teil verbotene Zeichen enthält
                    for (const char of this.forbiddenCharactersInLocalPart) {
                        if (localPart.indexOf(char) >= 0
                            && foundIllegalCharctersInLocalPart.indexOf(char) <= 0
                        ) {
                            foundIllegalCharctersInLocalPart += char;
                        }
                    }

                    // prüfe die eigenen mailboxen ob lokaler Teil verbotene Zeichen enthält
                    if (this.isPlatformMailbox) {
                        for (const char of this.forbiddenCharactersInLocalPartForOwnMailboxes) {
                            if (localPart.indexOf(char) >= 0
                                && foundIllegalCharctersInLocalPart.indexOf(char) <= 0
                            ) {
                                foundIllegalCharctersInLocalPart += char;
                            }
                        }
                    }

                    const foundIllegalCharctersInLocalNoDuplicates = Array.from(new Set(foundIllegalCharctersInLocalPart.split(''))).join(',');

                    if (foundIllegalCharctersInLocalNoDuplicates.length > 0) {
                        reply.push(
                            {
                                text: this.$translate.instant(
                                    /* translationId */ 'TR_170119-e139ca_TR',
                                    { foundIllegalCharctersInLocalPart: foundIllegalCharctersInLocalNoDuplicates }
                                )
                            }
                        );
                    }
                }

                // prüfe ob domain existiert
                if (value[value.length - 1] === '@') {
                    reply.push({ text: this.$translate.instant('TR_170119-2e193c_TR') });
                } else {
                    // überprüfe domain
                    if (domainPart.split('.').length < 2 && domainPart !== '##DOMAIN##') {
                        reply.push({ text: this.$translate.instant('TR_170119-8acc25_TR') });
                    } else if (domainPart !== '##DOMAIN##') {
                        const maxDomainPartLength = Math.max(0, this.maxAddressLength - domainPart.length - 2);
                        const validateDomain = new ValidateDomainName(
                            this.$translate,
                            this.domainInfoHelper,
                            maxDomainPartLength
                        );
                        reply = reply.concat(validateDomain.validate(domainPart.split('.')[0]));
                    }
                }
            }
        }

        return reply;
    };

    public updateValidatorErrors = (errors: any[]) => {
        this.validationErrors = errors;
    };
}
