import ng from 'angular';
import { UiRights } from '../../../../configuration';
import { Breadcrumb, BreadcrumbItem } from '../breadcrumb';
import { BreadcrumbWebhostingTopicModel } from './';
import { BreadcrumbTopicModel, TopicCases } from './topic-model';

export class BreadcrumbManagedMachineTopicModel extends BreadcrumbTopicModel {
    public static $inject: string[] = BreadcrumbTopicModel.$inject.concat(['breadcrumbWebhostingTopicModel']);

    public readonly topicName: string = 'managed-machine';
    public readonly specialTopicCases: TopicCases;

    /**
     *   List of route parts, which are excluded from mail class generalRoutes methode
     */
    public readonly excludeGeneralRouteParts = ['overview', 'new', 'edit'];

    private machineWebspacesActionDropdownItems: BreadcrumbItem[] = [];
    private machineDatabasesActionDropdownItems: BreadcrumbItem[] = [];
    private machineMailboxesActionDropdownItems: BreadcrumbItem[] = [];
    private manageMachineTopicDropdownItems: BreadcrumbItem[] = [];
    private machineWebspaceActionDropdownItems: BreadcrumbItem[] = [];

    constructor(
        protected $state: ng.ui.IStateService,
        protected $translate: ng.translate.ITranslateService,
        private breadcrumbWebhostingTopicModel: BreadcrumbWebhostingTopicModel
    ) {
        super($state, $translate);

        // tslint:disable-next-line:max-line-length
        const webspaceRouteItems = ng.copy(this.breadcrumbWebhostingTopicModel.webspaceActionDropdownItems);
        this.machineWebspaceActionDropdownItems = webspaceRouteItems.map((item) => {
            item.route = item.route.replace('webhosting', 'managed-servers.id');
            return item;
        });

        this.manageMachineTopicDropdownItems = [
            { part: 'webspaces', route: 'managed-servers.id.webspaces.overview', isGranted: UiRights.WEB_OBJECT_LIST },
            { part: 'databases', route: 'managed-servers.id.databases.overview', isGranted: UiRights.DB_DATABASE_LIST },
            {
                part: 'mailboxes',
                route: 'managed-servers.id.mailboxes.overview',
                isGranted: UiRights.EMAIL_MAILBOX_LIST
            }
        ];

        this.machineWebspacesActionDropdownItems = [
            { part: 'overview', route: 'managed-servers.id.webspaces.overview', isGranted: UiRights.DB_DATABASE_LIST },
            { part: 'new', route: 'managed-servers.id.webspaces.new', isGranted: UiRights.WEB_WEBSPACE_CREATE }
        ];

        this.machineMailboxesActionDropdownItems = [
            {
                part: 'overview',
                route: 'managed-servers.id.mailboxes.overview',
                isGranted: UiRights.EMAIL_MAILBOX_LIST
            },
            {
                part: 'new',
                route: 'managed-servers.id.mailboxes.new',
                isGrantedAny: [
                    UiRights.EMAIL_IMAPMAILBOX_CREATE,
                    UiRights.EMAIL_FORWARDER_CREATE,
                    UiRights.EMAIL_EXCHANGE_CREATE,
                    UiRights.EMAIL_SMTP_FORWARDER_CREATE
                ]
            }
        ];

        this.machineDatabasesActionDropdownItems = [
            { part: 'overview', route: 'managed-servers.id.databases.overview', isGranted: UiRights.WEB_OBJECT_LIST },
            { part: 'new', route: 'managed-servers.id.databases.new', isGranted: UiRights.DB_DATABASE_CREATE }
        ];

        this.specialTopicCases = {
            'app-install': this.getVhostsActionsRoutes,
            'backups': () => this.getProductActionDropdown(this.machineWebspaceActionDropdownItems, null, 'backups'),
            'cronjobs': () => this.getProductActionDropdown(this.machineWebspaceActionDropdownItems, null, 'cronjobs'),
            'databases': () =>
                this.getProductActionDropdown(this.manageMachineTopicDropdownItems, $state.params, 'databases'),
            'directory-protection': this.getVhostsActionsRoutes,
            'edit': this.getEditRoute,
            'ftp-ssh': () => this.getProductActionDropdown(this.machineWebspaceActionDropdownItems, null, 'ftp-ssh'),
            'id': this._getIdRoute,
            'locations-edit': this.getVhostsActionsRoutes,
            'mailboxes': () =>
                this.getProductActionDropdown(this.manageMachineTopicDropdownItems, $state.params, 'mailboxes'),
            'new': this.getNewRoute,
            'new-project': this.getNewProjectRoute,
            'orderId': this.getOrderIdRoute,
            'overview': this.getOverviewRoute,
            'storage': () => this.getProductActionDropdown(this.machineWebspaceActionDropdownItems, null, 'storage'),
            'vhosts': this.getVhostsRoute,
            'webspaces': () =>
                this.getProductActionDropdown(this.manageMachineTopicDropdownItems, $state.params, 'webspaces')
        };
    }

    public getFirstLevelData = (): Breadcrumb[] => {
        this.breadcrumbs.push({
            active: false,
            route: this.steps.length > 2 ? 'managed-servers.overview' : '',
            type: 'text',
            value: 'Managed Server' // this.$translate.instant('MENU.MAIN.PRODUCTS.LABEL')
        });

        return this.breadcrumbs;
    };

    public getFtpUserRoute = (): Breadcrumb[] => {
        return this.breadcrumbWebhostingTopicModel.getWebspaceActionRoutes();
    };

    public getOverviewRoute = (): Breadcrumb[] => {
        let actionDropdownItems;
        switch (this.steps[this.index - 1]) {
            case 'webspaces':
                actionDropdownItems = this.machineWebspacesActionDropdownItems;
                break;
            case 'databases':
                actionDropdownItems = this.machineDatabasesActionDropdownItems;
                break;
            case 'mailboxes':
                actionDropdownItems = this.machineMailboxesActionDropdownItems;
                break;
            default:
                return;
        }

        return this.getProductActionDropdown(actionDropdownItems, null, 'overview');
    };

    public getNewRoute = (): Breadcrumb[] => {
        let routeParams = {};
        let actionDropdownItems;

        switch (this.steps[this.index - 1]) {
            case 'domains':
                routeParams = { machineId: this.globals.machine.id };
                actionDropdownItems = [
                    {
                        isGranted: UiRights.WEB_WEBSPACE_CREATE,
                        part: 'new',
                        route: 'managed-servers.id.domains.new'
                    }
                ];
                break;
            case 'webspaces':
                actionDropdownItems = this.machineWebspacesActionDropdownItems;
                break;
            case 'databases':
                actionDropdownItems = this.machineDatabasesActionDropdownItems;
                break;
            case 'mailboxes':
                actionDropdownItems = this.machineMailboxesActionDropdownItems;
                break;
            default:
                return;
        }

        return this.getProductActionDropdown(actionDropdownItems, routeParams, 'new');
    };

    public _getIdRoute = (): Breadcrumb[] => {
        const machine = this.globals.machine;
        const webspace = this.globals.webspace;

        if (this.steps[this.index - 1] === 'managed-servers') {
            return [
                {
                    active: this.steps.length === 2,
                    route: 'managed-servers.id.dashboard',
                    routeParams: { machineId: machine.id },
                    type: 'text',
                    value: machine.name
                }
            ];
        } else if (this.steps[this.index - 1] === 'webspaces') {
            return [
                {
                    active: this.steps.length === 4,
                    route: 'managed-servers.id.webspaces.overview',
                    routeParams: { machineId: machine.id, webspaceId: webspace.id },
                    type: 'text',
                    value: webspace.name
                }
            ];
        }
    };

    public getNewProjectRoute = (): Breadcrumb[] => {
        return [
            {
                active: true,
                route: '',
                routeParams: null,
                type: 'text',
                value: this.$translate.instant('TR_140319-9b2952_TR')
            }
        ];
    };

    public getEditRoute = (): Breadcrumb[] => {
        const breadcrumbs = [];
        let route = '';
        let routeParams = {};

        const service = this.steps[this.index - 2];

        switch (this.steps[this.index - 2]) {
            case 'webspaces':
            case 'databases':
            case 'mailboxes':
                route = 'managed-servers.id.' + service + '.id.edit';
                if (service === 'webspaces') {
                    routeParams = {
                        machineId: this.globals.machine.id,
                        webspaceId: this.globals.webspace.id
                    };
                } else if (service === 'databases') {
                    routeParams = {
                        databaseId: this.globals.database.id,
                        machineId: this.globals.machine.id
                    };
                } else if (service === 'mailboxes') {
                    routeParams = {
                        machineId: this.globals.machine.id,
                        mailboxId: this.globals.mailbox.id
                    };
                }
                breadcrumbs.push({
                    active: true,
                    route: route,
                    routeParams: routeParams,
                    type: 'text',
                    value: this.getActiveDropdownValue('dashboard')
                });
                break;
            case 'vhosts':
                return this.getVhostsActionsRoutes();
        }

        return breadcrumbs;
    };

    private getVhostsRoute = (): Breadcrumb[] =>
        this.steps[this.index] === 'vhosts'
            ? this.getProductActionDropdown(this.machineWebspaceActionDropdownItems, null, 'vhosts')
            : this.getVhostsActionsRoutes();

    private getVhostsActionsRoutes = (): Breadcrumb[] => [
        {
            active: this.steps.length === 5 || this.steps.length === 7,
            route: 'managed-servers.id.webspaces.id.vhosts.id.edit',
            routeParams: {
                machineId: this.globals.machine.id,
                vHostId: this.globals.vHost.id,
                webspaceId: this.globals.webspace.id
            },
            type: 'text',
            value: this.globals.vHost.domainNameUnicode
        },
        ...this.getProductActionDropdown(
            this.breadcrumbWebhostingTopicModel.vhostActionDropdownItems.map((item: BreadcrumbItem) => {
                item.route = item.route.replace('webhosting', 'managed-servers.id');
                return item;
            }),
            {
                machineId: this.globals.machine.id,
                vHostId: this.globals.vHost.id,
                webspaceId: this.globals.webspace.id
            },
            this.step
        )
    ];

    public getOrderIdRoute = (): Breadcrumb[] => {
        return [
            {
                active: true,
                route: 'managed-servers.setup',
                routeParams: { orderId: this.globals.orderId },
                type: 'text',
                value: this.$translate.instant('18249206-2af0-4e97-abe6-51184efa0ce2'),
            }
        ];
    }
}
