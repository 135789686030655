import * as ng from 'angular';
import { AuthContextService } from '../../../../../services';
import { WizardHelperService, WizardObject } from '../../../../molecules/wizard/';

export class OrganCreateSummaryBundleController implements ng.IController {
    public static $inject: string[] = ['authContext', 'wizardHelper'];

    public contingent: any;
    public serviceObject: WizardObject;

    constructor(
        private authContext: AuthContextService,
        private wizardHelper: WizardHelperService
    ) {}

    public getContractPeriod = () => {
        return this.wizardHelper.getBillingCycleTranslation(
            this.serviceObject.selectedProduct.prices[0].price.contractPeriod
        );
    };

    public get cost() {
        const account = this.authContext.account.id === '1'
            ? this.serviceObject.settings.account
            : this.authContext.account;
        return this.wizardHelper.calculateProductPrice(
            this.serviceObject.selectedProduct.prices,
            account
        );
    }

    public set cost({}) {} // tslint:disable-line:no-empty

    public get currency() {
        return this.wizardHelper.getProductCurrency(this.serviceObject.selectedProduct.prices[0].price);
    }

    public set currency({}) {} // tslint:disable-line:no-empty
}

export class OrganCreateSummaryBundleComponent implements ng.IComponentOptions {
    public bindings = {
        contingent: '<',
        serviceObject: '<'
    };
    public template = require('./bundle-summary.html');
    public controller =  OrganCreateSummaryBundleController;
}
