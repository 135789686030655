/* eslint-disable */

// TODO merge this class and /nextcloud/backup into one

import * as ng from 'angular';
import {
    BillingHelperService,
    ProductHelperService,
    ProductsModelService,
    PriceHelperService,
    AuthContextService,
} from '@/services';
import * as Types from '@/types';
import { UiRights } from '@/configuration';
import * as Sentry from '@sentry/browser';

export class OrganCreateConfigurationMachineBackupController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'billingHelper',
        'productsModel',
        'priceHelper',
    ];

    public backupEnabled = false;
    public account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    public loadBackupPrice = false;
    public voucher: Types.ViewTypes.ProductConfigVoucher;

    private _backupProductCode: string = null;
    private _backupPrice: Types.BillingApi.ArticlePurchasePrice | number = null;
    private _isFreemium: boolean;
    private _selectedProductCode: string;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private billingHelper: BillingHelperService,
        private productsModel: ProductsModelService,
        private priceHelper: PriceHelperService,
    ) {}

    public $onInit() {
        this._setBackupProductObject();
    }

    public $onChanges(changes: any) {
        if (changes._selectedProductCode !== undefined) {
            this._getBackupProductData();
        }
    }

    public get machineBackupOptionAvailable() {
        return [undefined, null, ''].indexOf(this._selectedProductCode) < 0
            && [undefined, null].indexOf(this.backupPrice) < 0;
    }

    public set backupProductCode({}) {} // tslint:disable-line:no-empty
    public get backupProductCode() {
        let res = this.backupEnabled
            ? this._backupProductCode
            : null;
        return res;
    }

    public set showBackupPrice({}) {} // tslint:disable-line:no-empty
    public get showBackupPrice(): boolean {
        return this.machineBackupOptionAvailable
            && this.backupEnabled;
    }

    public set backupPrice({}) {} // tslint:disable-line:no-empty
    public get backupPrice() {
        return this._backupPrice;
    }

    private _getBackupProductData = () => {
        if ([undefined, null, ''].indexOf(this._selectedProductCode) >= 0) {
            return;
        }

        this.loadBackupPrice = true;
        this._setBackupProductObject();
        this.productsModel.findOne(
            this._selectedProductCode,
            this.$translate.use() as string,
            true
        ).then((machineProductRes) => {
            if (this._isFreeProduct(machineProductRes.response)) {
                this.$timeout(() => {
                    this._backupPrice = null;
                    this.loadBackupPrice = false;
                });
            } else {
                // Get backup productCode template string from machine specificationItems
                this._backupProductCode = this._getProductCodeTemplate(machineProductRes.response) as string;
                if (this._backupProductCode !== null) {
                    this.billingHelper.getPriceByProductCode(this._backupProductCode).then((priceResponse) => {
                        this._backupPrice = priceResponse;
                        this.loadBackupPrice = false;
                    });
                } else {
                    this.$timeout(() => {
                        this._backupPrice = null;
                        this.loadBackupPrice = false;
                    });
                }
            }
        });
    };

    public get priceAlteration() {
        if (AuthContextService.isRootOrCompanyAccount || !AuthContextService.isGranted(UiRights.BIL_LIST_ARTICLE_PRICES)) return '';
        if (this.voucher) {
            let res = this.priceHelper.calculateAndShowPrice(
                this._backupPrice as Types.BillingApi.ArticlePurchasePrice,
                this.account,
            );
            return this.$translate.instant(
                /* translationId */ 'TR_190224-b0e5b3_TR',
                {
                    price: res,
                },
            );
        }
        return '';
    }

    private _isFreeProduct = (product: Types.ProductApi.AbstractProduct) => {
        const isFreemium = ProductHelperService.wrapSpecificationItems(
            product.specificationItems
        ).isFreeProduct?.isTrue === true;

        // set for machineBackupOptionAvailable getter
        this.$timeout(() => this._isFreemium = isFreemium);

        return isFreemium;
    };

    private _setBackupProductObject = () => {
        this._backupProductCode = null;
    };

    private _getProductCodeTemplate = (product: Types.ProductApi.AbstractProduct) => {
        let res = ProductHelperService.wrapSpecificationItems(
            product.specificationItems
        ).backupProductCode?.value || null;
        return res;
    };
}

export class OrganCreateConfigurationMachineBackupComponent implements ng.IComponentOptions {
    public bindings = {
        _selectedProductCode: '<selectedProductCode',
        account: '<account',
        backupProductCode: '=',
        voucher: '<?',
    };
    public template = require('./backup.html');
    public controller =  OrganCreateConfigurationMachineBackupController;
}
