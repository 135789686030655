import { UiRights } from '@/configuration/rights';
import { BackupApi, UI } from '@/types';
import { AuthContextService } from '../auth-context';
import { FilterUtils } from '../filter-utils';
import { IBackupAdminService } from './IBackupAdminService';
import { BackupRobotService } from './robot';

export class BackupAdminService implements IBackupAdminService{
    public static $inject: string[] = ['backupRobot'];
    constructor(private backupRobot: BackupRobotService){

    }

    /**
     * @param service
     * @param objType
     *
     * @param id
     * id of the object to be restored
     * @returns
     * Promise : BackupApi.BackupExtendedObject ! IF THERE IS NO SUITABLE RETURN IT WILL REJECT!
     */
    getAdminBackupData(service: string, objType: string, id: string): Promise<BackupApi.BackupExtendedObject> {
        return new Promise<BackupApi.BackupExtendedObject>((res, rej) => {
            if (AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_GENERAL_SUPPORT)){
                const filters = FilterUtils.buildFullFilter(
                    [],
                    '',
                    [],
                    [],
                    [{field:'BackupObjectObjectService', value:service},
                    {field:'BackupObjectObjectType', value:objType},
                    {field:'BackupObjectObjectId', value:id}]
                );
                this.backupRobot.backupObjectsFind(filters).then(data => {
                    res((data as UI.APIResponse<BackupApi.BackupExtendedObject>).response.data[0]);
                }).catch(() => {
                    rej();
                });
            } else {
                rej();
            }
        });

    }

    getAdminBackups(service: string, type: string, id: string): Promise<unknown>{
        return new Promise((res, rej) => {
            this.backupRobot.backupsFind(service, type, id, undefined, undefined).then(data => {
                res(data);
            });

        });
    }

}
