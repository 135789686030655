import { LinkListOverviewItem } from '../../atomic-components/molecules/links/link-list-overview/link-list-overview';
import { AccountFilterField, SelectFilterField, ShowHiddenObjectsFilterField, TextFilterField } from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { UiRights } from '../../configuration';
import { OverviewModel } from '../abstract';
import { AuthContextService } from '../auth-context';
import { DomainHandleModelService } from '../domain/domain-handle-model';
import { RequestCancellerService } from '../request-canceller';

export class DomainContactsOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', 'authContext', 'domainHandleModel', 'objectTypeFilter']
    );

    readonly service = 'domain-contacts';
    readonly objectType = 'DomainContact';
    readonly idField = 'contactId';
    readonly defaultFilterFields = ['contactId', 'contactName', 'contactOrganization', 'contactHandle'];

    public activeMassAction = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};

    protected listCallbacks = [{callback: this.domainHandleModel.list}];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private domainHandleModel: DomainHandleModelService,
        private objectTypeFilter
    ) {
        super(RequestCanceller);
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('contactHandle', this.$translate.instant('GENERAL.CONTACT')),
                new SelectFilterField(
                    'contactType',
                    this.$translate.instant('TR_070519-16189c_TR'),
                    [
                        {
                            name: this.objectTypeFilter(/* translationID */ 'TR_090119-9044b3_TR'),
                            value: 'org'
                        },
                        {
                            name: this.objectTypeFilter(/* translationID */ 'DOMAIN.HANDLE.TYPE.OPTIONS.PERSON'),
                            value: 'person'
                        }
                    ]
                ),
                new AccountFilterField('contactAccountId', this.$translate.instant('TR_140119-b3b187_TR')),
                new TextFilterField('contactName', this.$translate.instant('TR_140119-796b28_TR')),
                new TextFilterField('contactOrganization', this.$translate.instant('TR_090119-9044b3_TR')),
                new ShowHiddenObjectsFilterField('contactHidden', this.$translate.instant('TR_230620-6e73bd_TR'))
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }

    actionLinks = (): LinkListOverviewItem[] => [
        {
            text: this.$translate.instant('TR_100119-ff20c0_TR'),
            route: 'domain.contacts.new',
            isGranted: this.authContext.isGranted(UiRights.DOM_CONTACTS_CREATE)
        }
    ];
}
