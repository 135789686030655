import * as ng from 'angular';
import { OrganismOverviewController } from '../../../organisms/overview/overview';
import { LinkListItem } from '../link-list/link-list';
import './link-list-overview.scss';

export interface LinkListOverviewItem {
    disabled?: boolean;
    icon?: string;
    isClickable?: any;
    isGranted?: boolean;
    linkList?: LinkListItem[];
    onlyOnExperView?: boolean;
    route: string;
    routeParams?: { [param: string]: any };
    text: string;
    type?: string;
}

export class MoleculeLinkListOverviewController implements ng.IController {
    public static $inject: string[] = [];

    public linkListOverview: LinkListOverviewItem[];
    public OrganismOverview: OrganismOverviewController;

    public $onInit() {
        if (this.linkListOverview !== undefined) {
            this.linkListOverview.map((link) => {
                link.icon = link.icon || 'chevron-right';
                return link;
            });
        }
    }
}

export class MoleculeLinkListOverviewComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        linkListOverview: '<'
    };
    public controller =  MoleculeLinkListOverviewController;
    public template = require('./link-list-overview.html');
}
