import {
    RpcClient,
    RpcClientService
} from '../rpc-client';
import { DatabaseUserObject } from './../../atomic-components/molecules/panels/panel-rows/panel-row-access/database/database-access';

import * as ng from 'angular';

export const DatabaseClientService = [
    'rpcClient',
    (platformRpcClient: RpcClientService) => platformRpcClient.Factory('/api/database/v1/json/', 'database')
];

export class DatabaseRobotService {
    public static $inject: string[] = ['databaseClient'];

    constructor(private databaseClient: RpcClient) {}

    public listDatabases = (filter?, limit?, page?, sort?, config?) => {
        return this.databaseClient.request('databasesFind')
        .useCache()
        .cacheTimeout(1000 * 60 * 15)
        .timeout(config)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public listDatabasesWithoutPagination = (filter?, limit?, page?, sort?, config?) => {
        return this.databaseClient.request('databasesFind')
        .useCache()
        .cacheTimeout(1000 * 60 * 15)
        .timeout(config)
        .skipPagination()
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public listDatabaseUsers = (filter?, limit?, page?, sort?, config?, owner?) => {
        return this.databaseClient.request('usersFind')
        .useCache()
        .cacheTimeout(1000 * 60 * 15)
        .timeout(config)
        .ownerAccountId(owner)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public listDatabaseUsersWithoutPagination = (filter?, limit?, page?, sort?, config?) => {
        return this.databaseClient.request('usersFind')
        .useCache()
        .cacheTimeout(1000 * 60 * 15)
        .timeout(config)
        .skipPagination()
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public createDatabase = (database, owner?, poolId?, databaseServerId?) => {
        return this.databaseClient.request('databaseCreate')
        .ownerAccountId(owner)
        .bustCache('billing', 'depositGet')
        .bustCache('bundle', 'bundlesFind')
        .bustCache('database', 'databasesFind')
        .execute(
            {
                accesses: database.accesses,
                database: database,
                databaseServerId: databaseServerId,
                poolId: poolId
            }
        );
    };

    public databaseModifyRestrictions = (apiObject, owner) => {
        return this.databaseClient.request('databaseModifyRestrictions')
        .ownerAccountId(owner)
        .bustCache('bundle', 'bundlesFind')
        .bustCache('database', 'databasesFind')
        .execute(apiObject);
    };

    public updateDatabase = (database, accesses, killClientConnections) => {
        if (killClientConnections !== undefined) {
            killClientConnections = killClientConnections === true;
        } else {
            killClientConnections = false;
        }

        return this.databaseClient.request('databaseUpdate')
        .bustCache('database', 'databasesFind')
        .execute({ accesses: accesses, database: database, killClientConnections: killClientConnections });
    };

    public wipeDatabase = (databaseId) => {
        return this.databaseClient.request('databaseWipe')
        .execute({ databaseId: databaseId });
    };

    public userCreate = (user: DatabaseUserObject, password: string, owner?) => {
        return this.databaseClient.request('userCreate')
        .ownerAccountId(owner)
        .bustCache('database', 'databasesFind')
        .bustCache('database', 'usersFind')
        .execute({ user: user, password: password });
    };

    public userUpdate = (user: DatabaseUserObject, password: string) => {
        return this.databaseClient.request('userUpdate')
        .bustCache('database', 'databasesFind')
        .bustCache('database', 'usersFind')
        .execute({ user: user, password: password });
    };

    public userDelete = (userId) => {
        return this.databaseClient.request('userDelete')
        .bustCache('database', 'usersFind')
        .execute({ userId: userId });
    };

    public deleteDatabase = (databaseId, execDate) => {
        if (execDate === null) {
            return this.databaseClient.request('databaseDelete')
            .execute({ databaseId: databaseId });
        }

        return this.databaseClient.request('databaseDelete')
        .bustCache('database', 'databasesFind')
        .bustCache('bundle', 'bundlesFind')
        .execute({ databaseId: databaseId, execDate: execDate });
    };

    public databaseDeletionCancel = (databaseId) => {
        return this.databaseClient.request('databaseDeletionCancel')
        .bustCache('database', 'databasesFind')
        .execute({ databaseId: databaseId });
    };

    public deletePermanentlyDatabase = (databaseId) => {
        return this.databaseClient.request('databaseDeletePermanently')
        .bustCache('database', 'databasesFind')
        .execute({ databaseId: databaseId });
    };

    public purgeRestorable = (databaseId) => {
        return this.databaseClient.request('databasePurgeRestorable')
        .bustCache('database', 'databasesFind')
        .execute({ databaseId: databaseId });
    };

    public restoreDatabase = (databaseId) => {
        return this.databaseClient.request('databaseRestore')
        .bustCache('billing', 'depositGet')
        .bustCache('database', 'databasesFind')
        .execute({ databaseId: databaseId });
    };

    public listJobs = (filter?, limit?, page?, sort?) => {
        return this.databaseClient.request('jobsFind')
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };
}
