import * as ng from 'angular';

export class MoleculeButtonStatechangeController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public delete: () => void;
    public modifier = '';
    public icon = 'pencil';
    public disabled = false;
    public buttonTitle: string;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.buttonTitle = this.buttonTitle || this.$translate.instant('GENERAL.ACTION.EDIT');
    }
}

export class MoleculeButtonStatechangeComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        buttonTitle: '@?',
        delete: '<',
        disabled: '<',
        icon: '@',
        modifier: '@?'
    };
    public controller =  MoleculeButtonStatechangeController;
    public template = require('./button-statechange.html');
}
