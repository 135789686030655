import * as ng from 'angular';
import * as Configurations from '../../../../../configuration';
import { DateWrapper } from '../../../../../services';
import { DomainContactHelperService, SystemHelperService } from '../../../../../services/helpers';

export class OrganEditPanelDomainContactTrendmarkController {
    public static $inject: string[] = ['domainContactHelper', 'systemHelper'];

    public $editFormMolecule;
    public $organismCtrl;
    public contact;
    public panelRight;
    public currentDate: Date;
    public extTradeMarkDateOfApplication: DateWrapper;
    public extTradeMarkDateOfRegistration: DateWrapper;
    public configurations = Configurations;

    private _contactOrig;
    private _contactPartParams = [
        'extTradingName',
        'extTradeMarkRegistrationAuthority',
        'extTradeMarkRegisterNumber',
        'extTradeMarkCountry',
        'extTradeMarkDateOfApplication',
        'extTradeMarkDateOfRegistration'
    ];

    constructor(
        public domainContactHelper: DomainContactHelperService,
        public systemHelper: SystemHelperService
    ) {}

    public $onInit() {
        this._contactOrig = ng.copy(this.contact);
        this.currentDate = new Date();
        if ([undefined, null].indexOf(this.contact) >= 0) {
            return;
        }
        if ([undefined, null, ''].indexOf(this.contact.extTradeMarkDateOfApplication) === -1) {
            this.extTradeMarkDateOfApplication = new DateWrapper();
            this.extTradeMarkDateOfApplication.dateString = this.contact.extTradeMarkDateOfApplication;
        }
        if ([undefined, null, ''].indexOf(this.contact.extTradeMarkDateOfRegistration) === -1) {
            this.extTradeMarkDateOfRegistration = new DateWrapper();
            this.extTradeMarkDateOfRegistration.dateString = this.contact.extTradeMarkDateOfRegistration;
        }
    }

    public $onDestroy() {
        this.cancel();
    }

    public cancel = () => {
        this._contactPartParams.map((param) => this.contact[param] = ng.copy(this._contactOrig[param]));
    };

    public setExtTradeMarkDateOfApplication = (selection) => {
        this.contact.extTradeMarkDateOfApplication = selection.dateString;
    };

    public setExtTradeMarkDateOfRegistration = (selection) => {
        this.contact.extTradeMarkDateOfRegistration = selection.dateString;
    };
}
export class OrganEditPanelDomainContactTrendmarkComponent implements ng.IComponentOptions {
    public bindings = {
        $organismCtrl: '<organismCtrl',
        contact: '=',
        panelRight: '<'
    };
    public require = {
        $editFormMolecule: '^moleculeFormEdit'
    };
    public template = require('./domain-contact-trendmark.html');
    public controller = OrganEditPanelDomainContactTrendmarkController;
    public controllerAs = '$editPanelOrgan';
}
