import * as ng from 'angular';
import {
    OrganismDashboardOverviewsController
} from '../../../../../organisms/overview/dashboard-overviews/dashboard-overviews';

export class MoleculeOverviewRowPanelDashboardSslCertificatesController implements ng.IController {
    public static $inject: string[] = [];

    public serviceItemList: any;
    public OrganismDashboardOverview: OrganismDashboardOverviewsController;
    public service: string;
    public objectType: string;

    public get showAccounts() {
        return this.OrganismDashboardOverview.globals.showAccounts;
    }

}

export class MoleculeOverviewRowPanelDashboardSslCertificatesComponent implements ng.IComponentOptions {
    public require = {
        OrganismDashboardOverview: '^organismDashboardOverviews'
    };
    public bindings = {
        objectType: '@',
        service: '@',
        serviceItemList: '<'
    };
    public controller =  MoleculeOverviewRowPanelDashboardSslCertificatesController;
    public controllerAs = 'DashboardOverviewCtrl';
    public template = require('./dashboard-ssl-certificates.html');
}
