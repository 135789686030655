import * as ng from 'angular';
import { DatabaseModelService, GeneralCreateService, JobStatusObject } from '../../../../../services';
import * as Types from '../../../../../types';

export class OrganCreateConfirmationDatabaseAccessController implements ng.IController {
    public static $inject: string[] = ['databaseModel', 'generalCreate'];

    public apiOrderRequestError = false;
    public databaseObject: Types.DatabaseApi.Database;
    public jobStatusDatabases: null | JobStatusObject[] = null;
    public someCreateJobInSupportStatus = false;

    private _gettingDatabaseObject = false;

    constructor(
        private databaseModel: DatabaseModelService,
        private generalCreate: GeneralCreateService
    ) {}

    public get databaseCreatingStatus() {
        const jobStatus = this.generalCreate.getJobStatusForTemplate(
            [undefined, null].indexOf(this.jobStatusDatabases) >= 0
                ? undefined
                : this.jobStatusDatabases[0],
            this.someCreateJobInSupportStatus,
            this.apiOrderRequestError
        );

        if (['done', 'successful'].indexOf(jobStatus) >= 0
            && !this._gettingDatabaseObject
        ) {
            return this._getDatabaseObjectData(jobStatus);
        }

        return jobStatus;
    }

    // tslint:disable-next-line:no-empty
    public set databaseCreatingStatus({}) {}

    private _getDatabaseObjectData = (jobStatus: string) => {
        this._gettingDatabaseObject = true;
        return this.databaseModel.findOneById(this.jobStatusDatabases[0].objectId).then((databaseObject: any) => {
            if (databaseObject !== undefined) {
                this.databaseObject = databaseObject;
            }
            return jobStatus;
        }) as ng.IPromise<string>;
    };
}

export class OrganCreateConfirmationDatabaseAccessComponent implements ng.IComponentOptions {
    public bindings = {
        apiOrderRequestError: '<?',
        jobStatusDatabases: '<',
        someCreateJobInSupportStatus: '<?'
    };
    public template = require('./database-access-confirmation.html');
    public controller =  OrganCreateConfirmationDatabaseAccessController;
}
