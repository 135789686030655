import { ModelHelper } from '../model-helper';
import { DnsRobotService } from './robot';

import * as ng from 'angular';
import * as Types from '../../types';

export class NameserverSetModelService {
    public static $inject: string[] = ['dnsRobot', '$q', '$rootScope'];

    constructor(
        private dnsRobot: DnsRobotService,
        private $q: ng.IQService,
        private $rootScope: ng.IRootScopeService
    ) {}

    public getAll = (accountId: string) => {
        return this.dnsRobot.listNameserverSet(
            {
                field: 'accountId',
                value: accountId.toString()
            },
            0,
            1,
            {
                field: 'nameserverSetName',
                order: 'ASC'
            }
        )
        .then(this.returnResponseDate);
    };

    public list = (
        limit?: number,
        page?: number,
        filter?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions
    ) => {
        sort = sort || {
            field: 'nameserverSetName',
            order: 'ASC'
        };
        page = page || 1;
        return this.dnsRobot.listNameserverSet(filter, limit, page, sort)
        .then(ModelHelper.returnListResponse);
    };

    public listWithoutPagination = (
        limit?: number,
        page?: number,
        filter?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions,
        owner?: string
    ) => {
        sort = sort || {
            field: 'nameserverSetName',
            order: 'ASC'
        };
        page = page || 1;

        return this.dnsRobot.listNameserverSetWithoutPagination(filter, limit, page, sort, owner)
        .then(ModelHelper.returnListResponse);
    };

    public findById(ids: string[]) {
        return this.dnsRobot.listNameserverSetWithoutPagination(
            {
                subFilter: ids.map(this.idFilter),
                subFilterConnective: 'OR'
            },
            0,
            1
        )
        .then(ModelHelper.returnFindResponse);
    }

    public findOne(id: string) {
        return this.dnsRobot.listNameserverSetWithoutPagination({field: 'nameserverSetId', value: id}, 10, 1)
        .then(ModelHelper.returnFindOneResponse);
    }

    public getDefault(owner: string) {
        return this.dnsRobot.nameserverSetGetDefault(owner)
        .then(this.returnResponse);
    }

    public formatNameServerAsArray(nameServerSet) {
        return nameServerSet.nameservers.map((nameserver) => ({name: nameserver}));
    }

    public getDefaultNameserver(owner: string) {
        return this.getDefault(owner)
        .then(this.formatNameServerAsArray);
    }

    public create(nameserverSet, owner: string) {
        return this.dnsRobot.createNameserverSet(nameserverSet, owner)
        .then(this.refresh, this.refresh);
    }

    public update(nameserverSet) {
        return this.dnsRobot.updateNameserverSet(nameserverSet)
        .then(this.refresh, this.refresh);
    }

    public delete(nameserverSet) {
        const promises = nameserverSet.map(this.deleteOne);
        return this.$q.all(promises)
        .then(this.refresh, this.refresh);
    }

    private refresh = (result) => {
        this.$rootScope.$broadcast('nameserverset.refresh');
        return result;
    };

    private idFilter(id: string) {
        return { field: 'nameserverSetId', value: id };
    }

    private returnResponse(result) {
        return result.response;
    }

    private returnResponseDate(result) {
        return result.response.data;
    }

    private deleteOne = (nameserverSet) => {
        return this.dnsRobot.deleteNameserverSet(nameserverSet.id);
    };
}
