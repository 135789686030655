import * as ng from 'angular';

import { UiRights, LongDebounceInterval } from '@/configuration';
import {
    ApiErrorObject,
    AuthContextService,
    DomainInfo,
    DomainInfoHelperService,
    ValidateDomainName,
    ValidateEmail
} from '@/services';
import { MailboxModelService } from '@/services/email/mailbox-model';
import { debounce } from '@/services/helpers/debounce';
import * as Types from '@/types';

export class MoleculeInformationEmailAdjustmentsController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'domainInfoHelper',
        'mailboxModel'
    ];

    public availableDnsValidationTargets: string[] = [];
    public checkedEmailResponse: Types.EmailApi.CheckEmailAddressResult;
    public dnsValidation = false;
    public dnsValidationCode: string;
    public emailCheckStatus: 'done' | 'progress' | 'default';
    public setAutoConfigureDns = true;
    public skipDnsCheck = false;
    public userHasRightAdminSystemSuperUserEdit =
        AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_SUPER_USER_EDIT);
    public validationErrors: { text: string }[] = [];

    private _checkSuccessful: boolean;
    private _emailAddress: string;
    private accountId: string;
    private domainValidator: ValidateDomainName;
    private ignoreEmailAddressTaken = false;
    private message: string;
    private productCode: string;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private domainInfoHelper: DomainInfoHelperService,
        private mailboxModel: MailboxModelService
    ) {}

    private validateEmail: ValidateEmail;

    public $onInit = (): void => {
        this.checkEmailAddress = debounce(this.checkEmailAddress, LongDebounceInterval);
        this.checkEmailAddress();
    };

    public get emailAddress(): string {
        return this._emailAddress;
    }

    public set emailAddress(emailAddress: string) {
        this._emailAddress = emailAddress;
        this.checkEmailAddress();
    }

    public set checkSuccessful(checkSuccessful: boolean) {
        this._checkSuccessful = checkSuccessful;
        this.validationErrors = [];
    }

    public get checkSuccessful(): boolean {
        return this.validationErrors?.length === 0 && this._checkSuccessful;
    }

    /**
     * Validate the current email address.
     */
    public checkEmailAddress = (): void => {
        this.availableDnsValidationTargets = [];

        if (!this.validateEmail) {
            this.validateEmail = new ValidateEmail(
                true,
                this.$translate,
                this.domainInfoHelper,
                true,
                []
            );
        }

        const validationReply = this.validateEmail.validate(this.emailAddress);
        if (validationReply && validationReply.length > 0) {
            this.validationErrors = validationReply;
            this.emailCheckStatus = 'default';
            return;
        }

        if (!this.domainValidator) {
            this.domainValidator = new ValidateDomainName(this.$translate, this.domainInfoHelper);
        }

        if ([undefined, null, ''].indexOf(this.emailAddress) >= 0) {
            this.validationErrors = [];
            this.emailCheckStatus = 'default';
            return;
        }
        if ('progress' === this.emailCheckStatus) {
            return;
        }

        this.dnsValidation = false;
        this.dnsValidationCode = '';
        this.emailCheckStatus = 'progress';
        const [ emailAddress, emailName, emailFqdn ] = /([^@]+)@?(.+)?/i.exec(this.emailAddress);

        if (
            [undefined, null].indexOf(emailAddress) >= 0
            || [undefined, null, ''].indexOf(emailName) >= 0
            || [undefined, null, ''].indexOf(emailFqdn) >= 0
        ) {
            this.validationErrors = [{ text: this.$translate.instant('TR_030919-376dfb_TR')}];
            this.emailCheckStatus = 'default';
            return;
        }

        const validationErrors = this.domainValidator.validate(emailFqdn);
        if (validationErrors.length > 0) {
            this.validationErrors = validationErrors;
            this.emailCheckStatus = 'default';
            return;
        }

        if (!this.domainInfoHelper.hasValidTld(emailFqdn)) {
            this.validationErrors = [
                { text: this.$translate.instant('DOMAIN.DOMAINCHECK.STATUS.EXTENSION-DOES-NOT-EXIST')}
            ];
            this.emailCheckStatus = 'default';
            return;
        }

        if (!this.domainValidator.isComplete(emailFqdn)) {
            this.validationErrors = [{ text: this.$translate.instant('TR_020720-fc2101_TR')}];
            this.emailCheckStatus = 'default';
            return;
        }

        // get emailDomain Subdomain parts
        void this.domainInfoHelper.getDomainInfo(emailFqdn, false).then(
            (domainInfo: DomainInfo) => {
                if (emailFqdn.indexOf(domainInfo.domainNameUnicode) > 0) {
                    const subdomainParts = emailFqdn
                        .substr(0, (emailFqdn.indexOf(domainInfo.domainNameUnicode) - 1))
                        .split('.');

                    if (subdomainParts.length > 0) {
                        this.availableDnsValidationTargets = subdomainParts.map(
                            (part, position) => {
                                let fqdn = part;

                                for (let i = position + 1; i < subdomainParts.length; i++) {
                                    fqdn = `${fqdn}.${subdomainParts[i]}`;
                                }

                                fqdn = `${fqdn}.${domainInfo.domainNameUnicode}`;

                                return fqdn;
                            }
                        );

                        this.availableDnsValidationTargets.push(domainInfo.domainNameUnicode);

                        void this.$timeout(() => {
                            // the following line is a hack so angular realizes the member has changed!
                            // eslint-disable-next-line
                            this.availableDnsValidationTargets = this.availableDnsValidationTargets;
                        });
                    }
                }
            }
        );

        this.checkedEmailResponse = undefined;
        this.message = '';
        this.mailboxModel.emailCheck(this.emailAddress, this.accountId, null, this.productCode).then(
            (reply) => {
                this.checkedEmailResponse = reply;
                switch (reply.status) {
                    case 'verificationRequired':
                        this.dnsValidation = true;
                        this.dnsValidationCode = reply.verificationCode;
                        this.message = `${this.$translate.instant('TR_110119-f654f6_TR')} ${this.dnsValidationCode}`;
                        this.checkSuccessful = false;
                        break;
                    case 'emailAddressTaken':
                        if (this.ignoreEmailAddressTaken) {
                            this.message = '';
                            this.checkSuccessful = true;
                        } else {
                            this.message = this.$translate.instant('TR_110119-e03806_TR');
                            this.checkSuccessful = false;
                        }
                        break;
                    case 'emailAddressAvailable':
                        this.message = '';
                        this.checkSuccessful = true;
                        this.validationErrors = [];
                        break;
                    case 'emailAddressInvalid':
                        if (reply.errors.length > 0) {
                            this.message = this._getApiErrorMessage(reply.errors);
                        } else {
                            this.message = this.$translate.instant('TR_110119-0a213b_TR');
                        }
                        this.checkSuccessful = false;
                        break;
                    default:
                        this.checkSuccessful = false;
                }
                if (this.message.length > 0 && !this.dnsValidation) {
                    if (this.validationErrors?.length > 0) {
                        this.validationErrors.push({ text: this.message });
                    } else {
                        this.validationErrors = [{ text: this.message }];
                    }
                }
                this.emailCheckStatus = 'done';
            },
            (err) => {
                this.validationErrors = [
                    { text: this._getApiErrorMessage([{
                        code: err.code,
                        text: err.message
                    }])
                }];
                this.emailCheckStatus = 'done';
            }
        );
    };

    private _getApiErrorMessage = (errors: ApiErrorObject[]): string => {
        let message;
        const translationId = `TR_API-ERROR_${errors[0].code}_TR`;
        const translationParams: Record<string, string> = {};

        if ([undefined, null, ''].indexOf(errors[0].value) < 0) {
            const paramValueKey = 'value';
            translationParams[paramValueKey] = errors[0].value;
        }

        if (errors[0].details !== undefined) {
            errors[0].details.map((detail) => {
                translationParams[detail.key] = detail.value;
            });
        }

        message = this.$translate.instant(
            translationId,
            translationParams
        );

        if ([undefined, null, '', translationId].indexOf(message) >= 0) {
            message = errors[0].text;
        }

        return message;
    };

}

export class MoleculeInformationEmailAdjustmentsComponent implements ng.IComponentOptions {
    public bindings = {
        accountId: '<?',
        checkedEmailResponse: '=?',
        checkSuccessful: '=?',
        dnsValidationCode: '=?',
        emailAddress: '<',
        ignoreEmailAddressTaken: '<?',
        message: '=?',
        productCode: '<?',
        setAutoConfigureDns: '=?',
        skipDnsCheck: '=?',
        validationErrors: '=?'
    };
    public controller = MoleculeInformationEmailAdjustmentsController;
    public template = require('./email-adjustments.html');
}
