import ng from 'angular';
import { AccountModelService, AuthContextService, SwitchUserManagerService } from '@/services';
import * as Types from '@/types';

export class AtomAccountNameController implements ng.IController {
    public static $inject: string[] = ['$translate', 'accountModel', 'switchUserManager'];

    public accountName: string;
    public account: Types.AccountApi.AbstractAccount;

    public accountId: string;

    public isParentAccount: boolean;
    public isSubAccount: boolean;
    public isOwnAccount: boolean;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService,
        private switchUserManager: SwitchUserManagerService
    ) {
    }

    public $onInit = (): void => {
        void (this.accountModel.findOne(this.accountId) as Promise<Types.AccountApi.Subaccount>)
            .then((account: Types.AccountApi.Subaccount) => {
                this.account = account;
                this.isOwnAccount = this.accountId === AuthContextService.account.id;
                if (this.isOwnAccount) {
                    this.accountName = AuthContextService.account.name;
                    return;
                }
                this.isSubAccount = this.account?.parentAccountId === AuthContextService.account.id;
                if (this.isSubAccount) {
                    this.accountName = this.account.name;
                    return;
                }
                this.isParentAccount = this.accountId === AuthContextService.account.parentAccountId;
                if (this.isParentAccount) {
                    this.accountName = this.$translate.instant('ACCOUNT.GENERAL.PARENT-ACCOUNT');
                    return;
                }
            }).catch(err => {});
    };

    public openAccount = (): void => {
        if (this.switchUserManager.allowedToSwitchTo(this.accountId)) {
            this.switchUserManager.switchTo(this.accountId);
        }
    };
}

export class AtomAccountNameComponent implements ng.IComponentOptions {
    public bindings = {
        accountId: '<'
    };
    public template = require('./account-name.html');
    public controller =  AtomAccountNameController;
}
