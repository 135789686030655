import { BundleApi, UI } from '@/types';
import {
    RpcClient,
    RpcClientService
} from '../rpc-client';

export const BundleClientService = [
    'rpcClient',
    (platformRpcClient: RpcClientService) => platformRpcClient.Factory('/api/bundle/v1/json/', 'bundle')
];

export class BundleRobotService {
    public static $inject: string[] = ['bundleClient'];

    constructor(
        private bundleClient: RpcClient
    ) {}

    public bundleCreate = (bundle, owner) => {
        return this.bundleClient.request('bundleCreate')
        .ownerAccountId(owner)
        .bustCache('bundle', 'bundlesFind')
        .bustCache('billing', 'depositGet')
        .execute({bundle: bundle});
    };

    public bundleDelete = (bundleId, domainActions, execDate) => {
        return this.bundleClient.request('bundleDelete')
        .bustCache('bundle', 'bundlesFind')
        .execute(
            {
                bundleId: bundleId,
                domainActions: domainActions,
                execDate: execDate
            }
        );
    };

    public bundleDeletionCancel = (bundleId) => {
        return this.bundleClient.request('bundleDeletionCancel')
        .bustCache('bundle', 'bundlesFind')
        .execute({ bundleId: bundleId });
    };

    public bundleDetails = (bundleId) => {
        return this.bundleClient.request('bundleDetails')
        .execute({ bundleId: bundleId });
    };

    public bundleProductDetails = (productCode) => {
        return this.bundleClient.request('bundleProductDetails')
        .execute({ productCode: productCode });
    };

    public bundleProductsFind = (filter, limit, page, sort, config, owner) => {
        return this.bundleClient.request('bundleProductsFind')
        .timeout(config)
        .ownerAccountId(owner)
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public bundleUpdate = (bundle) => {
        return this.bundleClient.request('bundleUpdate')
        .bustCache('bundle', 'bundlesFind')
        .execute({ bundle: bundle });
    };

    public bundlesFind = (filter?, limit?, page?, sort?, config?):
    Promise<UI.APIResponse<UI.Response<BundleApi.Bundle>>> =>
    {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this.bundleClient.request('bundlesFind')
        .useCache()
        .cacheTimeout(1000 * 60 * 5)
        .timeout(config)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public bundlesFindWithoutPagination = (filter?, limit?, page?, sort?, config?):
    Promise<UI.APIResponse<UI.Response<BundleApi.Bundle>>> => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this.bundleClient.request('bundlesFind')
        .useCache()
        .cacheTimeout(1000 * 60 * 5)
        .skipPagination()
        .timeout(config)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public restoreBundle = (bundleId) => {
        return this.bundleClient.request('bundleRestore')
        .bustCache('bundle', 'bundlesFind')
        .bustCache('billing', 'depositGet')
        .execute({ bundleId: bundleId });
    };

    public bundlePurgeRestorable = (bundleId: string) => {
        return this.bundleClient.request('bundlePurgeRestorable')
        .bustCache('bundle', 'bundlesFind')
        .execute({ bundleId: bundleId });
    };

    public bundleVhostFind = (bundleId) => {
        return this.bundleClient.request('bundleVHostsFind')
        .execute({ bundleId: bundleId });
    };
}
