import * as ng from 'angular';
import { AuthContextService } from '../../../../../services';

export class MoleculePanelEditRowAccountSelectController implements ng.IController {
    public model: string;
    public onChangeCallback: (parameter) => any;
    public disabled = false;
    public doNotSetOwnAccountAsDefault = false;
    public noRootAccounts = false;
    public excludeIdsFromSearch: any[] = [];
    public allowUndefined = false;

    public $onInit() {
        // set logged user as default selection in drop down
        if ([undefined, null, false].indexOf(this.doNotSetOwnAccountAsDefault) >= 0) {
            this.model = this.model || AuthContextService.account.id;
        }
    }
}

export class MoleculePanelEditRowAccountSelectComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        disabled: '<',
        doNotSetOwnAccountAsDefault: '<?',
        excludeIdsFromSearch: '<?',
        model: '=',
        noRootAccounts: '<?',
        onChangeCallback: '<',
        allowUndefined: '<?'
    };
    public controller = MoleculePanelEditRowAccountSelectController;
    public template = require('./panel-edit-row-account-select.html');
}
