import * as ng from 'angular';
import * as Types from '../../../../../types';

import { DomainComposerService, WebhostingHelperService } from '../../../../../services';

export class OrganismRedirectionWizardWrapperController {
    public static $inject: string[] = ['domainComposer'];

    public account: Types.AccountApi.Account;
    public bundle: Types.BundleApi.Bundle;
    public managedServerWebspaceItems: Types.WebhostingApi.Webspace[];
    public outerAccount: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    public ownerAccount: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    public webspace: Types.WebhostingApi.Webspace;
    public webserverRessource: Types.ResourceApi.Webserver;

    public wizardComposerObject: Types.ViewTypes.WizardComposerObject = {
        apiObject: {},
        summaryObject: []
    };
    public viewType = 'config';

    constructor(
        private domainComposer: DomainComposerService
    ) {}

    public loadSummaryView = (metadata: Types.ViewTypes.ProductConfigDomainRedirectionObject) => { // used in template
        this.wizardComposerObject.summaryObject = [];
        this.wizardComposerObject = this.domainComposer.buildRedirectionApiObject(metadata);
        this.viewType = 'summary';
    };

    public loadConfirmView = () => { // used in template
        this.viewType = 'confirm';
    };
}

export class OrganismRedirectionWizardWrapperComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<?',
        managedServerWebspaceItems: '<?',
        outerAccount: '<?account',
        ownerAccount: '<?',
        webspace: '<?',
        webserverRessource: '<?'
    };
    public template = require('./redirection-wizard-wrapper.html');
    public controller = OrganismRedirectionWizardWrapperController;
}
