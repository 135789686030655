import * as ng from 'angular';

import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateDomainReportsController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData = {
        backwardLink: 'domain.dashboard',
        backwardText: this.$translate.instant('TR_110119-774118_TR'),
        panelHeaderRoute: '',
        panelIcon: 'globe',
        panelTitle: this.$translate.instant('MENU.CONTEXT.DOMAIN.REPORTS')
    };

    constructor(private $translate: ng.translate.ITranslateService) {}
}

export class TemplateDomainReportsComponent implements ng.IComponentOptions {
    public bindings = {
    };
    public template = require('./reports-template.html');
    public controller = TemplateDomainReportsController;
}
