import * as ng from 'angular';
import { PanelHeaderData } from '@/atomic-components/molecules/panels';
import * as Types from '@/types';

export class TemplateDomainMoveController {
    public static $inject: string[] = ['$translate'];

    public domains: Types.DomainApi.Domain[] = [];
    public bundles: Types.BundleApi.Bundle[] = [];
    public selectedDomains: Types.DomainApi.Domain[] = [];
    public subAccounts: any[];
    public pageHeaderData: PanelHeaderData;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit(): void {
        this.pageHeaderData = {
            backwardLink: 'domain.domains.id.edit',
            backwardText: this.$translate.instant('TR_110119-c3e68c_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: { domainId: this.selectedDomains[0].id },
            panelIcon: 'globe',
            panelTitle: this.$translate.instant('TR_110119-742b3b_TR', { domainName: this.selectedDomains[0].name })
        };
    }
}

export class TemplateDomainMoveComponent implements ng.IComponentOptions {
    public bindings = {
        bundles: '<',
        domains: '<',
        selectedDomains: '<',
        subAccounts: '<'
    };
    public template = require('./domain-move-template.html');
    public controller = TemplateDomainMoveController;
}
