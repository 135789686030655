import * as ng from 'angular';
import { LinkListOverviewItem } from '../../atomic-components/molecules/links';
import {
    AccountFilterField,
    SelectFilterField,
    TextFilterField
} from '../../atomic-components/molecules/search/fancy-filter-fields';
import { UiRights } from '../../configuration';
import { OverviewModel } from '../abstract';
import { AuthContextService } from '../auth-context';
import { FileSaverService } from '../billing/';
import { RestrictionHelperService } from '../helpers';
import { RequestCancellerService } from '../request-canceller';
import { UTF8TextEncoder } from '../utf8encoder';
import { DnsCsvGenerator } from './dns-csv-generator';
import { ZoneFileSerializerService } from './zone-file-serializer';
import { DnsZoneModelService } from './zone-model';
import * as q from 'q';

export class DnsZoneOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat([
        '$q',
        '$state',
        '$translate',
        'authContext',
        'dnsZoneModel',
        'fileSaver',
        'objectStatusFilter',
        'objectTypeFilter',
        'restrictionHelper',
        'zoneFileSerializer'
    ]);

    public readonly service = 'dns-zone';
    public readonly objectType = 'DnsZone';
    public readonly idField = 'zoneConfigId';
    public readonly defaultFilterFields = ['ZoneNameUnicode'];
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};

    protected prefixFilters = [{ field: 'ZoneName', prefix: 'xn--' }];
    protected regexFilters = [{ field: 'ZoneName', regex: /^.+\.xn--.+$/ }];
    protected listCallbacks = [];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $q: ng.IQService,
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private dnsZoneModel: DnsZoneModelService,
        private fileSaver: FileSaverService,
        private objectStatusFilter,
        private objectTypeFilter,
        private restrictionHelper: RestrictionHelperService,
        private zoneFileSerializer: ZoneFileSerializerService
    ) {
        super(RequestCanceller);

        // declare multi action availably methods
        this.actionLinksAvailabilities = {
            activeZones: this.activeZones,
            deletableZones: this.deletableZones,
            restorableAndPurgeableZones: this.restorableAndPurgeableZones,
            inactiveZones: this.inactiveZones,
            movableZones: this.movableZones,
            selectedZones: this.selectedZones,
            zonesWithTemplate: this.zonesWithTemplate,
            zonesWithoutTemplate: this.zonesWithoutTemplate
        };

        this.listCallbacks = [{
            callback: this.listZones
        }];
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('zoneName', this.$translate.instant('TR_140119-e93e68_TR')),
                new SelectFilterField(
                    'zoneType',
                    this.$translate.instant('TR_070519-16189c_TR'),
                    [{
                        name: this.objectTypeFilter('NATIVE'),
                        value: 'NATIVE'
                    }, {
                        name: this.objectTypeFilter('SLAVE'),
                        value: 'SLAVE'
                    }]
                ),
                new SelectFilterField(
                    'zoneStatus',
                    this.$translate.instant('TR_140119-8e4b60_TR'),
                    [{
                        name: this.objectStatusFilter('active'),
                        value: 'active'
                    }, {
                        name: this.objectStatusFilter('blocked'),
                        value: 'blocked'
                    }, {
                        name: this.objectStatusFilter('restorable'),
                        value: 'restorable'
                    }, {
                        name: this.objectStatusFilter('disabled'),
                        value: 'disabled'
                    }]
                ),
                new AccountFilterField('accountId', this.$translate.instant('TR_140119-b3b187_TR')),
                new TextFilterField('zoneMasterIp', this.$translate.instant('TR_140119-4a3f36_TR'))
            ];
        }

        return this._cachedAvailableFilters;
    }

    /* tslint:disable-next-line:no-empty */
    public set availableFilters({}) {}

    public listZones = (limit?, page?, filters?, sort?, cancel?) => {
        if (this.$state.current.name.split('.').indexOf('dashboard') >= 0) {
            return this.dnsZoneModel.listWithoutPagination(limit, page, filters, sort, cancel);
        } else {
            return this.dnsZoneModel.list(limit, page, filters, sort, cancel);
        }
    };

    public actionLinks = (): LinkListOverviewItem[] => {
        return [
            {
                isGranted: this.authContext.isGrantedAny([
                    UiRights.DNS_NSSETS_LIST,
                    UiRights.DNS_TEMPLATES_LIST,
                    UiRights.DNS_ZONES_LIST,
                    UiRights.DNS_DDNSCREDENTIALS_LIST,
                    UiRights.DNS_ZONES_EDIT,
                    UiRights.DNS_DDNSHOSTNAME_LIST
                ]),
                linkList: [
                    {
                        group: 'redirect',
                        isGranted: this.authContext.isGranted(UiRights.DNS_NSSETS_LIST),
                        route: 'dns.nameserversets.overview',
                        text: this.$translate.instant('DOMAIN.NAMESERVER.SELECT-TYPES.NAMESERVER-SETS')
                    },
                    {
                        group: 'redirect',
                        isGranted: this.authContext.isGranted(UiRights.DNS_TEMPLATES_LIST),
                        route: 'dns.templates.overview',
                        text: this.$translate.instant('MENU.CONTEXT.DNS.TEMPLATES')
                    },
                    {
                        group: 'redirect',
                        isGranted: this.authContext.isGranted(UiRights.DNS_ZONES_LIST),
                        route: 'dns.zones.jobs',
                        text: this.$translate.instant('MENU.CONTEXT.JOBS')
                    },
                    {
                        group: 'redirect',
                        isGranted: this.authContext.isGranted(UiRights.DNS_DDNSCREDENTIALS_LIST),
                        route: 'dns.ddns.credentials.overview',
                        text: this.$translate.instant('TR_200319-418d09_TR')
                    },
                    {
                        group: 'redirect',
                        isGranted: this.authContext.isGranted(UiRights.DNS_ZONES_LIST),
                        route: 'dns.zones.content-search',
                        text: this.$translate.instant('MENU.CONTEXT.DNS.CONTENT-SEARCH')
                    },
                    {
                        group: 'redirect',
                        isGranted: this.authContext.isGranted(UiRights.DNS_ZONES_EDIT),
                        route: 'dns.zones.content-change',
                        text: this.$translate.instant('MENU.CONTEXT.DNS.CONTENT-CHANGER')
                    },
                    {
                        group: 'redirect',
                        isGranted: this.authContext.isGranted(UiRights.DNS_DDNSHOSTNAME_LIST),
                        route: 'dns.ddns.hostnames.overview',
                        text: this.$translate.instant('TR_130319-9c1524_TR')
                    }
                ],
                route: '',
                text: this.$translate.instant('TR_100119-a22157_TR'),
                type: 'multi'
            },
            {
                isGranted: this.authContext.isGrantedAny([UiRights.DNS_ZONES_DELETE, UiRights.DNS_ZONES_MOVE]),
                linkList: [
                    {
                        group: 'delete',
                        groupName: this.$translate.instant('TR_140119-aee71e_TR'),
                        isClickable: 'deletableZones',
                        isGranted: this.authContext.isGranted(UiRights.DNS_ZONES_DELETE),
                        onclickMethode: () => this.activeMassAction = 'delete',
                        route: '',
                        text: this.$translate.instant('TR_140119-c74d48_TR')
                    },
                    {
                        group: 'delete',
                        groupName: this.$translate.instant('TR_140119-aee71e_TR'),
                        isClickable: 'restorableAndPurgeableZones',
                        isGranted: this.authContext.isGranted(UiRights.DNS_ZONES_DELETE),
                        onclickMethode: () => this.activeMassAction = 'wipe',
                        route: '',
                        text: this.$translate.instant('TR_281122-73499a_TR')
                    },
                    {
                        group: 'delete',
                        groupName: this.$translate.instant('TR_140119-aee71e_TR'),
                        isClickable: 'restorableAndPurgeableZones',
                        isGranted: this.authContext.isGranted(UiRights.DNS_ZONES_CREATE),
                        onclickMethode: () => this.activeMassAction = 'restore',
                        route: '',
                        text: this.$translate.instant('TR_281122-75083f_TR')
                    },
                    {
                        group: 'move',
                        groupName: this.$translate.instant('TR_140119-3bf198_TR'),
                        isClickable: 'movableZones',
                        isGranted: this.authContext.isGranted(UiRights.DNS_ZONES_MOVE),
                        onclickMethode: () => this.activeMassAction = 'move',
                        route: '',
                        text: this.$translate.instant('TR_140119-c0f52b_TR')
                    },
                    {
                        group: 'template',
                        groupName: this.$translate.instant('TR_140119-3a6452_TR'),
                        isClickable: 'zonesWithoutTemplate',
                        onclickMethode: () => this.activeMassAction = 'tieToTemplate',
                        route: '',
                        text: this.$translate.instant('TR_140119-06848c_TR')
                    },
                    {
                        group: 'template',
                        isClickable: 'zonesWithTemplate',
                        onclickMethode: () => this.activeMassAction = 'untieFromTemplate',
                        route: '',
                        text: this.$translate.instant('TR_140119-0122ec_TR')
                    },
                    {
                        group: 'status',
                        groupName: this.$translate.instant('TR_140119-8e4b60_TR'),
                        isClickable: 'inactiveZones',
                        onclickMethode: () => this.activeMassAction = 'activate',
                        route: '',
                        text: this.$translate.instant('TR_140119-0360b0_TR')
                    },
                    {
                        group: 'status',
                        isClickable: 'activeZones',
                        onclickMethode: () => this.activeMassAction = 'deactivate',
                        route: '',
                        text: this.$translate.instant('TR_140119-5e3116_TR')
                    },
                    {
                        group: 'download',
                        groupName: this.$translate.instant('TR_140119-a28d04_TR'),
                        isClickable: 'selectedZones',
                        onclickMethode: () => this.activeMassAction = 'downloadZoneFile',
                        route: '',
                        text: this.$translate.instant('TR_140119-ed717b_TR')
                    },
                    {
                        group: 'download',
                        isClickable: 'selectedZones',
                        onclickMethode: () => this.activeMassAction = 'downloadZoneCsv',
                        route: '',
                        text: this.$translate.instant('TR_140119-5ad66b_TR')
                    }
                ],
                onlyOnExperView: true,
                route: '',
                text: this.$translate.instant('TR_140119-080106_TR'),
                type: 'multi'
            },
            {
                isGranted: this.authContext.isGranted(UiRights.DNS_ZONES_CREATE)
                    && this.$state.current.name.indexOf('.linked-zones') < 0,
                route: 'dns.zones.new',
                text: this.$translate.instant('TR_140119-843ff2_TR')
            }
        ] as LinkListOverviewItem[];
    };

    /**
     *  Submit methods
     */

    public deleteSubmit = (zones: any[]) => {
        const promises = [];
        for (const zone of zones) {
            if (this.selected[zone.id]) {
                promises.push(
                    this.dnsZoneModel.deleteOne(zone)
                );
            }
        }
        return this.$q.all(promises);
    };

    public wipeSubmit(zones: any[]) {
        const promises = [];

        for (const zone of zones) {
            if (this.selected[zone.id]) {
                promises.push(
                    this.dnsZoneModel.purgeRestorableDnsZone(zone.id)
                );
            }
        }

        return this.$q.all(promises);
    }

    public restoreSubmit(zones: any[]) {
        const promises = [];

        for (const zone of zones) {
            if (this.selected[zone.id]) {
                promises.push(
                    this.dnsZoneModel.restoreDnsZone(zone.id)
                );
            }
        }

        return this.$q.all(promises);
    }

    public moveSubmit = (zones: any[], settings: any) => {
        const zoneNames: string[] = [];
        for (const zone of zones) {
            if (this.selected[zone.id]) {
                zoneNames.push(zone.name);
            }
        }
        return this.dnsZoneModel.moveByName(zoneNames, settings.targetAccount);
    };

    public tieToTemplateSubmit = (zones: any[], settings: any) => {
        const submitZones: any[] = [];
        for (const zone of zones) {
            if (this.selected[zone.id]) {
                submitZones.push(zone);
            }
        }

        return this.dnsZoneModel.tieToTemplate(
            submitZones,
            settings.template,
            settings.replacements,
            settings.overwriteRecords
        );
    };

    public untieFromTemplateSubmit = (zones: any[]) => {
        const submitZones: any[] = [];
        for (const zone of zones) {
            if (this.selected[zone.id]) {
                submitZones.push(zone);
            }
        }
        return this.dnsZoneModel.untie(submitZones);
    };

    public activateSubmit = (zones: any[]) => {
        const submitZones: any[] = [];
        for (const zone of zones) {
            if (this.selected[zone.id]) {
                submitZones.push(zone);
            }
        }
        return this.dnsZoneModel.unblock(submitZones);
    };

    public deactivateSubmit = (zones: any[]) => {
        const submitZones: any[] = [];
        for (const zone of zones) {
            if (this.selected[zone.id]) {
                submitZones.push(zone);
            }
        }
        return this.dnsZoneModel.block(submitZones);
    };

    public downloadZoneFile = (zones: any[]) => {
        const promises = [];

        for (const zone of zones) {
            if (this.selected[zone.id]) {
                promises.push(
                    this.dnsZoneModel.findRecords(zone.id)
                    .then((records) => this.zoneFileSerializer.serialize(zone, records))
                    .then(
                        (zoneFile) => {
                            const array = (new UTF8TextEncoder()).encode(zoneFile);
                            return this.fileSaver.saveAs(new Blob([array], {type: 'text/txt;charset=UTF-8'}), `${zone.name}.zone`);
                        }
                    )
                );
            }
        }

        return this.$q.all(promises);
    };

    public downloadZoneCsv = (zones: any[]) => {
        const promises = [];

        for (const zone of zones) {
            if (this.selected[zone.id]) {
                promises.push(
                    this.dnsZoneModel.findRecords(zone.id)
                    .then(
                        (records) => this.fileSaver.saveAs(
                            DnsCsvGenerator.generateZoneCsv(zone, records),
                            this._getZoneName(zone, '.csv')
                        )
                    )
                );
            }
        }

        return this.$q.all(promises);
    };

    /**
     *  Action availabbility definitions
     */
    public deletableZones = (zones: any[]) => {
        let noZoneSelected = true;

        if (zones.length && zones.length === 0) {
            return false;
        }

        for (const zone of zones) {
            if (this.selected[zone.id]) {
                noZoneSelected = false;

                if (zone.status !== 'active' && zone.status !== 'restricted') {
                    return false;
                }
            }
        }

        return !noZoneSelected;
    };

    public restorableAndPurgeableZones = (zones: any[]) => {
        let noZoneSelected = true;

        if (zones.length && zones.length === 0) {
            return false;
        }

        for (const zone of zones) {
            if (this.selected[zone.id]) {
                noZoneSelected = false;

                if (zone.status !== 'restorable') {
                    return false;
                }
            }
        }

        return !noZoneSelected;
    };

    public movableZones = (zones: any[]) => {
        for (const zone of zones) {
            if (this.selected[zone.id]) {
                return true;
            }
        }
        return false;
    };

    public zonesWithoutTemplate = (zones: any[]) => {
        const selectedZones = zones.filter(
            (zone) => [undefined, null, false].indexOf(this.selected[zone.id]) < 0
        );

        if (selectedZones.length < 1) {
            return false;
        }

        if (selectedZones.some((zone) => zone.accountId !== selectedZones[0].accountId)) {
            // Can add template to zone if they belong to the same account
            return false;
        }

        return selectedZones.every((zone) => {
            return [undefined, null].indexOf(zone.templateValues) >= 0
                && ['blocked', 'disabled'].indexOf(zone.status) < 0;
        });
    };

    public zonesWithTemplate = (zones: any[]) => {
        let zonesSelected = false;
        for (const zone of zones) {
            if (this.selected[zone.id]) {
                zonesSelected = true;
                if ((
                    zone.templateValues === undefined
                    || zone.templateValues == null)
                    || ['blocked', 'disabled'].indexOf(zone.status) >= 0
                ) {
                    return false;
                }
            }
        }
        return zonesSelected;
    };

    public activeZones = (zones: any[]) => {
        let zonesSelected = false;
        for (const zone of zones) {
            this.restrictionHelper.init(zone, 'dns');
            if (this.selected[zone.id]) {
                if (!this.restrictionHelper.hasRestrictionsByCurrentUser()) {
                    zonesSelected = true;
                } else {
                    return false;
                }
            }
        }
        return zonesSelected;
    };

    public inactiveZones = (zones: any[]) => {
        let zonesSelected = false;
        for (const zone of zones) {
            this.restrictionHelper.init(zone, 'dns');
            if (this.selected[zone.id]) {
                if (this.restrictionHelper.hasRestrictions() && this.restrictionHelper.userCanEditRestrictions()) {
                    zonesSelected = true;
                } else {
                    return false;
                }
            }
        }
        return zonesSelected;
    };

    public selectedZones = (zones: any[]) => {
        let zonesSelected = false;
        for (const zone of zones) {
            if (this.selected[zone.id]) {
                zonesSelected = true;
            }
        }
        return zonesSelected;
    };

    private _getZoneName = (zone, ending?) => {
        ending = ending || '';
        const zoneNameSplitted = zone.name.split('.');
        return zoneNameSplitted[0] + ending;
    };
}
