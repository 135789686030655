import * as ng from 'angular';
import { InputPlaceholderConst } from '@/configuration';
import { AuthContextService } from '@/services';

export class OrganNewSubAccountNotificationsController {
    public static $inject: string[] = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public newSubAccount: any;
    public humanReadableEmails: boolean;
    public InputPlaceholder = InputPlaceholderConst;

    public $onInit(): void {
        if (this.newSubAccount.messageSettings === undefined) {
            this.newSubAccount.messageSettings = {
                eventHumanReadableEmailAddresses: [],
                eventMachineReadableEmailAddresses: [],
                receiveSubaccountEvents: false
            };
        }
        if (this.humanReadableEmails === undefined) {
            this.humanReadableEmails = false;
        }
    }

    public get showNewsletterToggle(): boolean {
        return AuthContextService.isRootOrCompanyAccount;
    }
}

export class OrganNewSubAccountNotificationsComponent implements ng.IComponentOptions {
    public bindings = {
        newSubAccount: '=',
        humanReadableEmails: '='
    };
    public template = require('./sub-account-notifications.html');
    public controller = OrganNewSubAccountNotificationsController;
}
