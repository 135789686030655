import * as ng from 'angular';
import {
    DashboardWidgetContainer,
    DashboardWidgetModel,
    DashboardWidgetModelOperator,
    DashboardWidgetObjectDefinition,
    DashboardWidgetStorageData,
    DashboardWidgetType
} from '@/atomic-components/organs/dashboard-widgets';
import {
    AccountModelService,
    AlertManagerService,
    DashboardHelperService,
    DepositModelService,
    FaqManagerService,
    NavigationService,
    UserModelService
} from '@/services';
import { NotificationsHelperService } from '@/services/helpers/notifications-helper';
import './dynamic-dashboard.scss';

export class OrganismDynamicDashboardController implements ng.IController {
    public static $inject: string[] = [
        '$compile',
        '$scope',
        '$timeout',
        '$translate',
        'accountModel',
        'alertManager',
        'dashboardHelper',
        'depositModel',
        'faqManager',
        'navigation',
        'notificationsHelper',
        'userModel'
    ];

    public addableWidgets: DashboardWidgetObjectDefinition[] = [];
    public systemWidgetContainer: DashboardWidgetContainer;
    public customerWidgetContainer: DashboardWidgetContainer;
    public editMode = false;
    public widgetsToAdd: DashboardWidgetObjectDefinition[] = [];
    public dashboardOperator: DashboardWidgetModelOperator;
    public showSystemNotification = false;
    public systemNotificationCallback: (arg0?: unknown) => unknown;

    private _customWidgetOptions = DashboardWidgetModelOperator.dashboardCustomWidgetEditOptions;
    private _loadingCustomeWidgets = true;
    private _globalWindow: Window;
    private _showWidgetAddPopup: boolean;
    private _systemWidgetItems: DashboardWidgetObjectDefinition[] = [];
    private _systemWidgetOptions = DashboardWidgetModelOperator.dashboardSystemWidgetEditOptions;
    private _widgetDashboardOptions = DashboardWidgetModelOperator.dashboardWidgetViewOptions;
    private _showSystemDashboard = false;

    constructor(
        private $compile: ng.ICompileService,
        private $scope: ng.IScope,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService,
        private alertManager: AlertManagerService,
        private dashboardHelper: DashboardHelperService,
        private depositModel: DepositModelService,
        private faqManager: FaqManagerService,
        private navigation: NavigationService,
        private notificationsHelper: NotificationsHelperService,
        private userModel: UserModelService
    ) {
        this._globalWindow = window;
        this._showWidgetAddPopup = false;
        this.dashboardOperator = new DashboardWidgetModelOperator(
            this.$translate,
            this.accountModel,
            this.alertManager,
            this.dashboardHelper,
            this.depositModel,
            this.faqManager,
            this.notificationsHelper,
            this.userModel
        );
    }

    public $onInit = (): void => {
        this._loadUserWidgetData();
        // Overload outer systemNotificationCallback
        this.systemNotificationCallback = this._systemNotificationCallback;
    };

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set systemDashboardVisible({}) {}
    public get systemDashboardVisible(): boolean {
        return this._showSystemDashboard;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set loadingCustomeWidgets({}) {}
    public get loadingCustomeWidgets(): boolean {
        return this._loadingCustomeWidgets;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set systemWidgetsAvailable({}) {}
    public get systemWidgetsAvailable(): boolean {
        return this._systemWidgetItems.length > 0;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set showCompactButton({}) {}
    public get showCompactButton(): boolean {
        return this.editMode
            && this.dashboardOperator.dashboardWidgetsList.length > 1;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set customStorageWidgetsInDashboard({}) {}
    public get customStorageWidgetsInDashboard(): DashboardWidgetStorageData {
        return this.dashboardOperator.customStorageWidgetsInDashboard();
    }

    public compactCustomerWidgets = (): void => {
        this.customerWidgetContainer.compact();
        this._widgetArrangementHasChanged();
    };

    public changeToEditMode = (): void => {
        void this.navigation.go('nextcloud-dashboard.edit', null, {reload: true});
    };

    public removeWidget = (widgetId: string): void => {
        const removedSuccessfully = this.dashboardOperator.removeWidgetById(widgetId);
        if (removedSuccessfully) {
            this.customerWidgetContainer.removeWidget(widgetId);
            this.customerWidgetContainer.commit();
            this.compactCustomerWidgets();
            this._widgetArrangementHasChanged();
            this.dashboardOperator.saveCustomWidgetStorageDataToApi();
        }

        this.setAddableWidgets();
    };

    public onChange = (): void => {
        this._widgetArrangementHasChanged();
        this.dashboardOperator.saveCustomWidgetStorageDataToApi();
    };

    public set showWidgetAddPopup(value) {
        this._showWidgetAddPopup = value;
    }
    public get showWidgetAddPopup(): boolean {
        return this._showWidgetAddPopup;
    }

    public openAddWidgetPopup = (): void => {
        this._showWidgetAddPopup = true;
    };

    public abortEdit = (): void => {
        void this.navigation.go('dashboard');
    };

    public setAddableWidgets = (): void => {
        this.addableWidgets = [];
        const widgetCheckPromises: Promise<boolean>[] = [];
        const widgetModelNameList: string[] = [];

        /**
         * Loop through all defined dashboard widgets, check availability and user permissions.
         * If everything fits, add the respective widget to the list
         *
         * We have to store the asynchronous return of the method 'checkAvailabilityOfWidgetModel'
         * as a Promise and can only add the available widgets when all asynchronous responses are
         * available.
         */
        Object
            .entries(DashboardWidgetModelOperator.dashboardWidgetModelStore)
            .forEach(
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                ([widgetModelName, widgetModelStoreObject]) => {
                    const widgetDefinition = (widgetModelStoreObject.model as DashboardWidgetModel)
                        .dashboardWidgetDefinition;
                    if (
                        widgetDefinition.widgetType === DashboardWidgetType.custom
                        && widgetDefinition.manualAddable
                    ) {
                        widgetModelNameList.push(widgetModelName);
                        widgetCheckPromises.push(
                            this.dashboardOperator.checkAvailabilityOfWidgetModel(widgetModelName)
                        );
                    }
                }
            );

        let widgetIndex = 0;
        void Promise.all(widgetCheckPromises).then(
            (promises) => {
                for (const widgetAvailable of promises) {
                    if (widgetAvailable) {
                        const widgetModel = this.dashboardOperator.getDashboardWidgetModelFromStore(
                            widgetModelNameList[widgetIndex]
                        );

                        this.addableWidgets.push(widgetModel.dashboardWidgetDefinition);
                    }
                    widgetIndex++;
                }
            }
        );
    };

    public addWidgetsToDashboard = async (): Promise<void> => {
        if (this.widgetsToAdd.length === 0) {
            return;
        }

        const widgetsToAddList = [];
        const customWidgetStorageData: DashboardWidgetStorageData = this.dashboardOperator
            .customStorageWidgetsInDashboard();

        // add to storage data object
        for (const addWidget of this.widgetsToAdd) {
            // check widget has rights to display
            if (await this.dashboardOperator.checkAvailabilityOfWidgetModel(addWidget.widgetObject.widgetModel)) {
                // add widget to widget to add list
                widgetsToAddList.push(addWidget);
                // add widget to storage data object
                customWidgetStorageData.customerWidgets.push(addWidget.widgetObject);
            }
        }

        // add widgets to grid stack container
        for (const widget of widgetsToAddList) {
            this.dashboardOperator.addWidgetToList(widget);
            this.customerWidgetContainer.addWidget(
                this._generateWidgetHtmlItem(widget),
                widget.widgetObject.attribute
            );
        }

        // Save widget storage data to Api
        this.compactCustomerWidgets();
        this.dashboardOperator.saveCustomWidgetStorageDataToApi(customWidgetStorageData);
        this.widgetsToAdd = [];
        this.setAddableWidgets();

        void this.$timeout(() => {
            // I know, pageheight is bigger then we can scroll
            const pageHeight = document.getElementsByClassName('flexWrapper__page')[0].scrollHeight;
            window.scrollTo({
                top: pageHeight,
                behavior: 'smooth'
            });
        }, 500);
    };

    private _systemNotificationCallback = (): void => {
        this._showSystemDashboard = !this._showSystemDashboard;
    };

    private _widgetArrangementHasChanged = (): void => {
        this.dashboardOperator.updateWidgetPositions(
            this.customerWidgetContainer.engine.nodes
        );
    };

    private _initSystemWidgetContainer = (systemWidgetItems: DashboardWidgetObjectDefinition[]): void => {
        this.showSystemNotification = systemWidgetItems.length > 0;
        if (this.showSystemNotification) {
            const systemWidgetElement = document.getElementById('system-widgets');
            const gridStackOptions = this.editMode
                ? this._systemWidgetOptions
                : this._widgetDashboardOptions;
            /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call */
            this.systemWidgetContainer = (this._globalWindow as any).GridStack.init(
                gridStackOptions,
                systemWidgetElement
            );
            /* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call */

            this._systemWidgetItems = systemWidgetItems;
            void this._setWidgetContainer(this.systemWidgetContainer, systemWidgetItems);
            this.systemWidgetContainer.compact();
        }
    };

    private _initCustomerWidgetContainer = (customWidgetItems: DashboardWidgetObjectDefinition[]): void => {
        const customWidgetElement = document.getElementById('customer-widgets');
        const gridStackOptions = this.editMode
            ? this._customWidgetOptions
            : this._widgetDashboardOptions;

        /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call */
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.customerWidgetContainer = (this._globalWindow as any).GridStack.init(
            gridStackOptions,
            customWidgetElement
        );
        /* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call */

        if (this.editMode) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            this.customerWidgetContainer
                .on('change', this.onChange);
        }

        void this._setWidgetContainer(this.customerWidgetContainer, customWidgetItems);
        this.setAddableWidgets();
        this._loadingCustomeWidgets = false;
    };

    private _loadUserWidgetData = (): void => {
        this._loadingCustomeWidgets = true;
        // load system Widgets
        void this.dashboardOperator.getSystemWidgetsFromApi()
            .then(
                (systemWidgetItems: DashboardWidgetObjectDefinition[]) => {
                    this._initSystemWidgetContainer(systemWidgetItems);
                }
            );

        // load custom Widgets
        void this.dashboardOperator.getCustomWidgetsFromApi()
            .then(
                (customWidgetItems: DashboardWidgetObjectDefinition[]) => {
                    this._initCustomerWidgetContainer(customWidgetItems);
                }
            );
    };

    private _setWidgetContainer = async (
        widgetContainer: DashboardWidgetContainer,
        widgetItems: DashboardWidgetObjectDefinition[]
    ): Promise<void> => {
        if (widgetContainer) {
            widgetItems = widgetItems || [];
            widgetContainer.removeAll();
            widgetContainer.batchUpdate();

            for (const widget of widgetItems) {
                // Check avaiability of widget
                if (await this.dashboardOperator.checkAvailabilityOfWidgetModel(widget.widgetObject.widgetModel)) {
                    widget.widgetObject.attribute.autoPosition = widget.widgetType === DashboardWidgetType.custom
                        ? false
                        : true;
                    widgetContainer.addWidget(
                        this._generateWidgetHtmlItem(widget),
                        widget.widgetObject.attribute
                    );
                }

            }

            widgetContainer.commit();
        }
    };

    private _generateWidgetHtmlItem = (widget: DashboardWidgetObjectDefinition): HTMLDivElement => {
        // Set gridstack item wrapper HTMLElement
        const widgetWrapperNode = document.createElement('div');
        widgetWrapperNode.className = 'grid-stack-item ui-draggable ui-draggable-handle';

        // Set widget attributes
        widgetWrapperNode.setAttribute('tag-name', widget.widgetObject.componentTagName);
        for (const [key, value] of Object.entries(widget.widgetObject.attribute)) {
            if (key === 'id') {
                widgetWrapperNode.setAttribute(key, value as string);
            } else {
                widgetWrapperNode.setAttribute(`data-gs-${key}`, value as string);
            }
        }

        let widgetElement = `<div class="grid-stack-item-inner">
            <${widget.widgetObject.componentTagName}></${widget.widgetObject.componentTagName}>`;
        if (widget.widgetObject.attribute?.locked) {
            widgetWrapperNode.classList.add('grid-stack-item--locked');
            // eslint-disable-next-line max-len
            widgetElement += '<span ng-if="$ctrl.editMode" class="grid-stack-item__sign-lock"><fa-icon icon="lock"></fa-icon></span>';
        } else {
            if (widget.widgetObject.widgetModel !== 'dashboardWidgetEmptyHintModel') {
                // eslint-disable-next-line max-len
                widgetElement += '<span ng-if="!$ctrl.editMode" ng-click="$ctrl.changeToEditMode()" class="grid-stack-item__edit-button"><fa-icon icon="cog"></fa-icon></span>';
            }
            // eslint-disable-next-line max-len
            widgetElement += `<span ng-if="$ctrl.editMode" ng-click="$ctrl.removeWidget('${widget.widgetObject.attribute.id}')" class="grid-stack-item__remove-button"><fa-icon icon="calendar-times"></fa-icon></span>`;
        }

        widgetElement += '</div>';
        // Set gridstack item content widget component as HTMLElement
        // $compile to start digist cycle process
        // Remember: this will return a JQLite array - we need first HTMLElement ...[0]
        const widgetNode = this.$compile(widgetElement)(this.$scope)[0];

        // compare wrapper and widget component
        widgetWrapperNode.append(widgetNode);
        return widgetWrapperNode;
    };
}

export class OrganismDynamicDashboardComponent implements ng.IComponentOptions {
    public bindings = {
        editMode: '<',
        showSystemNotification: '=?',
        systemNotificationCallback: '=?'
    };
    public controller = OrganismDynamicDashboardController;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    public template = require('./dynamic-dashboard.html');
}
