import * as ng from 'angular';
import { AuthContextService } from '../../../../../services';
import { EditPanelStatus } from '../../../forms/form-edit/form-edit';
import { DomainContactPanelMode } from '../../../panels/panel-rows/';

export class MoleculeDomainContactInfoModalController implements ng.IController {
    public static $inject: string[] = ['$timeout', '$translate', 'authContext'];

    public showModal: boolean;
    public contact;
    public confirmCallback;
    public cancelCallback;
    public modalTitle: string;
    public buttonText: string;
    public hideButton = false;
    public doNotCloseOnApply: boolean;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}
}

export class MoleculeDomainContactInfoModalComponent implements ng.IComponentOptions {
    public bindings = {
        cancelCallback: '<',
        confirmCallback: '<',
        contact: '=',
        doNotCloseOnApply: '<?',
        panelMode: '<',
        showModal: '='
    };
    public controller = MoleculeDomainContactInfoModalController;
    public template = require('./domain-contact-info-modal.html');
}
