import * as ng from 'angular';

export class OrganEditPanelMailboxDomainsettingsController {

    get mailboxQuota() {
        return this.$editFormOrganism.domainSettings.mailboxQuota === -1;
    }

    set mailboxQuota(value) {
        this.$editFormOrganism.domainSettings.mailboxQuota = (value === true) ? -1 : this.mailboxQuotaLimit;
    }

    get mailboxQuotaLimit() {
        return (this.$editFormOrganism.domainSettings.mailboxQuota === -1)
            ? 1 : this.$editFormOrganism.domainSettings.mailboxQuota;
    }

    set mailboxQuotaLimit(value) {
        this.$editFormOrganism.domainSettings.mailboxQuota = parseInt(value, 10);
    }

    get mailboxStorage() {
        return this.$editFormOrganism.domainSettings.storageQuota === -1;
    }

    set mailboxStorage(value) {
        this.$editFormOrganism.domainSettings.storageQuota = (value === true) ? -1 : this.mailboxStorageLimit;
    }

    get mailboxStorageLimit() {
        return (this.$editFormOrganism.domainSettings.storageQuota === -1)
            ? 1 : this.$editFormOrganism.domainSettings.storageQuota;
    }

    set mailboxStorageLimit(value) {
        this.$editFormOrganism.domainSettings.storageQuota = parseInt(value, 10);
    }

    public $editFormOrganism;
    public panelRight;
    public cancelCallback: () => void = () => {}; // tslint:disable-line:no-empty

    public cancel = () => {
        if ([undefined, null].indexOf(this.cancelCallback) === -1) {
            this.cancelCallback();
        }
    };
}

export class OrganEditPanelMailboxDomainsettingsComponent implements ng.IComponentOptions {
    public bindings = {
        cancelCallback: '<cancel',
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormMailboxDomainsettings'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./mailbox-domainsettings.html');
    public controller = OrganEditPanelMailboxDomainsettingsController;
}
