import * as ng from 'angular';
import {
    IStorageItem
} from '@/atomic-components/molecules/forms/form-elements/storage-quota-changer/storage-quota-changer';
import { ViewTypes } from '@/types/view-types';

export class OrganismWebspaceWizardProductGeneralConfigController {
    public static $inject: string[] = [
        '$scope',
        '$timeout'
    ];

    public metaData: ViewTypes.ProductConfigWebspaceObject;
    public service = 'webspace';
    public storageItem: IStorageItem;
    public displayStorageQuotaCharger: boolean;

    public defaultWebpaceStorageQuota = 10240;
    private _additionalStorageItems: number;
    public get additionalStorageItems(): number {
        return this._additionalStorageItems;
    }
    public set additionalStorageItems(value: number) {
        this._additionalStorageItems = value;
        this.metaData.additionalStorageItems = Array.from(Array(value).keys()).map(() => this.storageItem.productCode);
    }

    constructor(
        private $scope: ng.IScope,
        private $timeout: ng.ITimeoutService
    ) {}

    public $onInit = (): void => {
        this.storageItem = {} as IStorageItem;
        this.displayStorageQuotaCharger = true;
        this.$scope.$watch(() => this.metaData.productCode, (newCode: string, oldCode: string) => {
            if ([null, undefined].includes(newCode)) {
                return;
            }
            if (newCode !== oldCode) {
                this.displayStorageQuotaCharger = false;
                void this.$timeout(() => {
                    this.storageItem = {} as IStorageItem;
                    this.displayStorageQuotaCharger = true;
                }, 100);
            }
        });
    };
}

export class OrganismWebspaceWizardProductGeneralConfigComponent implements ng.IComponentOptions {
    public bindings = {
        metaData: '='
    };

    public template = require('./webspace-wizard-products-general-config.html');
    public controller = OrganismWebspaceWizardProductGeneralConfigController;
}
