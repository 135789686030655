import * as ng from 'angular';

import { PageHeaderData } from '../../../atomic-components/organisms';

export class TemplateSubAccountCreateController {
    public static $inject: string[] = ['$translate'];

    public rightsTemplates: any[];
    public allRights: any[];

    public pageHeaderData: PageHeaderData = {
        backwardLink: 'reseller.subaccounts.overview',
        backwardText: this.$translate.instant('TR_090119-cb312f_TR'),
        panelHeaderRoute: '',
        panelIcon: 'globe',
        panelTitle: this.$translate.instant('TR_090119-a65475_TR')
    };

    constructor(private $translate: ng.translate.ITranslateService) {}
}

export class TemplateSubAccountCreateComponent implements ng.IComponentOptions {
    public bindings = {
        allRights: '<',
        rightsTemplates: '<'
    };
    public template = require('./sub-account-create-template.html');
    public controller = TemplateSubAccountCreateController;
}
