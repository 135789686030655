import ng from 'angular';
import { DomainRobotService } from './robot';
import { DomainApi, UI } from '@/types';

export class DomainContactValidatorService {
    public static $inject: string[] = ['$translate', '$rootScope', 'domainRobot'];

    private cache: {[key: string]: DomainApi.ContactSetUsableProblem[]} = {};

    constructor(
        private $translate: ng.translate.ITranslateService,
        private $rootScope: ng.IRootScopeService,
        private domainRobot: DomainRobotService
    ) {
        this.$rootScope.$on('handles.refresh', () => {
            this.cache = {};
        });
    }

    public contactUsableFor = (
        contactId: string,
        allocation: unknown,
        tldList: string[],
        owner?: string
    ): Promise<DomainApi.ContactUsableResult> => {
        tldList = tldList || [];
        if ([undefined, null, ''].indexOf(contactId) >= 0 || contactId === '0' || tldList.length === 0) {
            return Promise.resolve({ missingProperties: [], mustBeOfType: [] });
        }

        return this.domainRobot.contactUsableFor(contactId, allocation, tldList,  owner)
            .then((result: UI.APISingleResponse<DomainApi.ContactUsableResult>) => {
                return result.response;
            });
    };

    public contactSetUsableFor = async (
        contacts: DomainApi.DomainContact[],
        domainSuffixes: string[],
        useTrustee: boolean,
        owner: string
    ): Promise<DomainApi.ContactSetUsableProblem[]> => {
        if (domainSuffixes.length === 0) {
            return Promise.resolve([] as DomainApi.ContactSetUsableProblem[]);
        }

        const allHaveContact = contacts.every(this.allocationHasContact);
        if (!allHaveContact) {
            return Promise.resolve([] as DomainApi.ContactSetUsableProblem[]);
        }

        const cacheKey = JSON.stringify(
            {
                contacts: contacts,
                domainSuffixes: domainSuffixes,
                owner: owner,
                useTrustee: useTrustee
            }
        );
        if (cacheKey in this.cache) {
            return Promise.resolve(this.cache[cacheKey]);
        }

        const result = await this.domainRobot.contactSetUsableFor(contacts, domainSuffixes, useTrustee, owner)
            .then(this.onContactSetUsableForResult);

        this.cache[cacheKey] = result;
        return result;
    };

    private allocationHasContact = (contact: DomainApi.DomainContact): boolean => {
        return contact.contact !== undefined;
    };

    private onContactSetUsableForResult = (
        result: UI.APISingleResponse<DomainApi.ContactSetUsableResult>
    ): DomainApi.ContactSetUsableProblem[] => {
        if ([undefined].indexOf(result.response.problems) === -1) {
            result.response.problems.map(
                (problem: DomainApi.ContactSetUsableProblem) => {
                    if (problem.value === '0') {
                        problem.text = this.$translate.instant('TR_140119-3d43b6_TR');
                    }
                }
            );
        }
        return result.response.problems;
    };
}
