import * as ng from 'angular';

import { MoleculeFormEditController } from '@/atomic-components/molecules';
import {
    ValidationErrorList
} from '@/atomic-components/molecules/lists/validation-error-list/validation-error-list';
import {
    AlertManagerService,
    NavigationService,
    RightModelService
} from '@/services';

import { AccountApi } from '../../../../../types';

export class OrganEditPanelRightsTemplateDeleteController implements ng.IController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'navigation',
        'rightModel'
    ];

    public $moleculeFormEdit: MoleculeFormEditController;
    public rightsTemplate: AccountApi.RightsTemplate;
    public deletionConfirmed = false;

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private navigation: NavigationService,
        private rightModel: RightModelService
    ) {}

    public $onInit() {
        this.$moleculeFormEdit.deleteCallback = this.deleteRightsTemplate;
    }

    public cancelDelete = () => {
        this.deletionConfirmed = false;
    };

    public deleteRightsTemplate = () => {
        this.rightModel
            .deleteRightsTemplates([this.rightsTemplate])
            .then(() => {
                this.alertManager.success(this.$translate.instant('TR_250221-b1bbc9_TR') );
                this.navigation.go('reseller.rightstemplates.overview');
            });
    };
}

export class OrganEditPanelRightsTemplateDeleteComponent implements ng.IComponentOptions {
    public bindings = {
      rightsTemplate: '<'
    };

    public require = {
        $moleculeFormEdit: '^moleculeFormEdit'
    };

    public template = require('./rights-template-delete.html');
    public controller = OrganEditPanelRightsTemplateDeleteController;
}
