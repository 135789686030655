import * as ng from 'angular';
import * as Types from '../../../../../types';
import { DomainModelService } from './../../../../../services/domain/domain-model';
import { AuthContextService } from '@/services';
import { UiRights } from '@/configuration';

export class OrganEditPanelDomainDeleteController {
    public static $inject: string[] = ['$filter', '$translate', 'domainModel'];

    public $editFormOrganism;
    public panelRight;
    public deletionConfirm = false;
    public hidePanel = false;
    public checkedLinkedToBundle = false;
    public domainObject: Types.DomainApi.Domain;
    public checkboxDomainDetachHint: string;

    constructor(
        private $filter: ng.IFilterService,
        private $translate: ng.translate.ITranslateService,
        private domainModel: DomainModelService
    ) {}

    public $onInit() {
        // if the user does not have the DOM_DOMAINS_LIST right we can't check if the domain is part of a bundle
        // in absprache mit STSO und Michi blenden wir das löschen panel dann aus.
        if (!AuthContextService.isGranted(UiRights.DOM_DOMAINS_LIST)) {
            this.hidePanel = true;
        } else {
            this._checkVhostBundleDomain();
        }
    }

    public get enableDeletion() {
        if (this.$editFormOrganism.linkedToBundle) {
            return this.deletionConfirm && this.$editFormOrganism.deletionDomainTooConfirm;
        }

        return this.deletionConfirm;
    }

    public cancel = () => {
        this.deletionConfirm = false;
    };

    private _checkVhostBundleDomain = () => {
        if ([undefined, null, ''].indexOf(this.$editFormOrganism.webspace.bundleId) >= 0) {
            this.checkedLinkedToBundle = true;
            return;
        }

        this.domainModel.findByName([this.$editFormOrganism.vHost.domainNameUnicode]).then(
            (domainRes) => {
                if (domainRes.length > 0
                    && [undefined, null, ''].indexOf(domainRes[0].bundleId) < 0
                ) {
                    this.$editFormOrganism.vhostBundleDomain = domainRes[0];
                    this.checkboxDomainDetachHint = this.$translate.instant(
                        /* translationId */ 'TR_201219-83586e_TR',
                        { paidUntil: this.$filter('date')(this.$editFormOrganism.vhostBundleDomain.paidUntil, 'dd.MM.yyyy') }
                    );
                    this.$editFormOrganism.linkedToBundle = true;
                }

                this.checkedLinkedToBundle = true;
            }
        );
    };
}

export class OrganEditPanelDomainDeleteComponent implements ng.IComponentOptions {
    public bindings = {
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormDomain'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./domain-delete.html');
    public controller = OrganEditPanelDomainDeleteController;
}
