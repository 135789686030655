import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { AuthContextService } from '../../../services';

export class TemplateDataProcessingController {
    public static $inject: string[] = ['$translate', 'authContext'];

    public nearBottom: boolean;
    public isAllowedToSignAv = false;
    public pageHeaderData: PanelHeaderData;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {
        this.pageHeaderData = {
            backwardLink: 'account.dashboard',
            backwardText: this.$translate.instant('TR_080119-b2bbbd_TR'),
            panelHeaderRoute: '',
            panelIcon: 'sync-alt',
            panelTitle: this.$translate.instant('MENU.MAIN.DATA-PROCESSING')
        };
    }

    public $onInit = () => {
        this.isAllowedToSignAv = (
            this.authContext.isDirectCustomer
            && this.authContext.isAdminUser
            && this.authContext.isMainUser
        );
    };

    public get alreadySigned() {
        return this.authContext.account.billingSettings.dataProcessingAgreementSignedCurrentVersion;
    }
}

export class TemplateDataProcessingComponent implements ng.IComponentOptions {
    public template = require('./data-processing-template.html');
    public controller = TemplateDataProcessingController;
}
