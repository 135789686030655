import * as ng from 'angular';

export class MoleculeButtonEditController implements ng.IController {
    public static $inject: string[] = [];

    public disabled: boolean;
    public edit: (arg0: any) => any;
    public editParam: any;
    public external: boolean;
    public title: string;

    public $onInit() {
        this.external = this.external || false;
    }
}

export class MoleculeButtonEditComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        disabled: '<',
        edit: '<',
        editParam: '<?',
        external: '<?',
        title: '@?'
    };
    public controller =  MoleculeButtonEditController;
    public template = require('./button-edit.html');
}
