import './fact-sheet.scss';

import ng from 'angular';

import { ConvertAmountFilter } from '@/filters/convert-amount';
import { ViewTypes } from '@/types';

export class AtomFactSheetController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public property: ViewTypes.WizardSummaryViewDataObject;
    public descriptionToggle = false;

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private convertAmountFilter: ConvertAmountFilter
    ) {}

    public getDateTranslation = (): string => {
        if (this.property.type !== 'days') {
            return;
        }
        const days = +this.property.value;

        return days > 1
            ? this.$translate.instant('TR_291019-5f21e4_TR', { days: days.toString() })
            : this.$translate.instant('TR_291019-55cdaf_TR', { days: days.toString() });
    };

    public set visiblePrice(_) {} /* tslint:disable-line:no-empty */
    public get visiblePrice(): string {
        return this.convertAmountFilter(parseFloat(this.property.value as string || '0'));
    }

    public get descriptionIsArray(): boolean {
        return Array.isArray(this.property.description);
    }
}

export class AtomFactSheetComponent implements ng.IComponentOptions {
    public bindings = {
        property: '<'
    };
    public controller =  AtomFactSheetController;
    public template = require('./fact-sheet.html');
}
