import { FetchClient, FetchClientService } from './fetch-client';

export const FileServerClientService = [
    'fetchClient',
    (platformFetchClient: FetchClientService): FetchClient => platformFetchClient.Factory(
        '/upload/',
        'fileserver'
    )
];

export class FileServerRobotService {
    public static $inject: string[] = ['fileServerClient'];

    constructor(private fileserverClient: FetchClient) {}

    public postFiles = async (token: string, file: FileList): Promise<any> => {
        const formData = new FormData();
        formData.append('file', file.item(0));

        const headerInit = new Headers();
        headerInit.append('pragma', 'no-cache');
        headerInit.append('cache-control', 'no-cache');
        headerInit.append('cache-control', 'no-store');
        headerInit.append('X-Fileupload-Token', token);

        const requestInit = {
            headers: headerInit,
            method: 'POST',
            body: formData
        } as RequestInit;
        return this.fileserverClient.request('files', requestInit)
            .then((response) => {
                return response.json();
            });
    };
}
