import * as ng from 'angular';
import * as q from 'q';
import { WizardConfirmationBase, WizardCreateObject } from '@/atomic-components/organisms/wizards';
import { UiRights } from '@/configuration';
import {
    ApiErrorModel,
    AuthContextService,
    DatabaseRobotService,
    ErrorMapperService,
    OrchestratorRobotService,
    WebhostingRobotService
} from '@/services/';

export class ManagedServerProjectWizardConfirmation extends WizardConfirmationBase {
    public static $inject: string[] = WizardConfirmationBase.$inject.concat([
        '$state',
        'databaseRobot',
        'webhostingRobot'
    ]);

    private _machineId: string;
    private _webspaceId: string;

    constructor(
        protected $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        protected apiErrorModel: ApiErrorModel,
        protected errorMapper: ErrorMapperService,
        protected localInterval: ng.IIntervalService,
        protected orchestratorRobot: OrchestratorRobotService,
        private $state: ng.ui.IStateService,
        private databaseRobot: DatabaseRobotService,
        private webhostingRobot: WebhostingRobotService
    ) {
        super(
            $timeout,
            $translate,
            apiErrorModel,
            errorMapper,
            localInterval,
            orchestratorRobot
        );

        if (!AuthContextService.isGrantedAll(
            [
                UiRights.DB_JOBS_LIST,
                UiRights.WEB_JOBS_LIST
            ]
        )) {
            this.isJobDependendWizard = false;
        }
    }

    public getJobByFilter = (
        filter: unknown,
        cacheTimeout: number,
        objectType?: string
    ): PromiseLike<unknown> => {
        switch (objectType) {
            case 'Database':
            case 'DatabaseUser':
                return this.databaseRobot.listJobs(filter)
                    .then(this.normalizeResponseObject);

            case 'Webspace':
            case 'WebspaceUser':
            case 'Vhost':
                return this.webhostingRobot.listJobs(filter)
                    .then(this.normalizeResponseObject);

            default:
                return q.resolve();
        }
    };

    public callbackAfterFinishedRequests = (): void => {
        if (this.$state.$current.locals.globals.machine) {
            /**
             * Do this stuff only if we are in the machine project state.
             * Don't do this stuff if we switch the state and request has not finished yet!
            **/
            this._machineId = this.$state.$current.locals.globals.machine.id;
            this.nextSteps = [];
            this.nextSteps.push({
                iconName: 'cloud-upload  ',
                linkText: this.$translate.instant('TR_210319-db468e_TR'),
                route: 'managed-servers.overview',
                routeOptions: { reload: true },
                routeParams: {}
            });

            this._checkNextStepOfCreatedObjectList(this.createRequests);
        }
    };

    private _checkNextStepOfCreatedObjectList = (createdObjectList: WizardCreateObject[]): void => {
        for (const createdObject of createdObjectList) {
            this._checkCreatedObjectNextStep(createdObject);
            if (createdObject.children.length > 0) {
                this._checkNextStepOfCreatedObjectList(createdObject.children);
            }
        }
    };

    private _checkCreatedObjectNextStep = (createdObject: WizardCreateObject): void => {
        if (this.isJobFinished(createdObject)) {
            /* eslint-disable @typescript-eslint/restrict-template-expressions */
            switch (createdObject.objectType) {
                case 'Webspace':
                    this._webspaceId = createdObject.result.id;
                    this.nextSteps.push({
                        iconName: 'server',
                        linkText: this.$translate.instant('TR_100119-220b0a_TR'),
                        route: 'managed-servers.id.webspaces.id.edit',
                        routeParams: {
                            machineId: this._machineId,
                            webspaceId: createdObject.result.id
                        },
                        routeOptions: { reload: true }
                    });
                    this.nextSteps.push({
                        iconName: 'globe',
                        linkText: this.$translate.instant('TR_140119-58b9d3_TR'),
                        route: 'managed-servers.id.webspaces.id.vhosts.new',
                        routeParams: {
                            machineId: this._machineId,
                            webspaceId: this._webspaceId
                        },
                        routeOptions: { reload: true }
                    });
                    break;
                case 'Vhost':
                    if ([undefined, null, ''].indexOf(this._webspaceId)) {
                        this.nextSteps.push({
                            iconName: 'th',
                            linkText: `${this.$translate.instant('TR_110119-cf62e6_TR')}
                                (Domain ${createdObject.result.domainNameUnicode})`,
                            route: 'managed-servers.id.webspaces.id.vhosts.id.app-install',
                            routeParams: {
                                machineId: this._machineId,
                                vHostId: createdObject.result.id,
                                webspaceId: this._webspaceId
                            },
                            routeOptions: { reload: true }
                        });
                    }
                    break;
                case 'Database':
                    this.nextSteps.push({
                        iconName: 'database',
                        linkText: `${this.$translate.instant('TR_210319-b8686e_TR')}
                            : ${createdObject.result.name}`,
                        route: 'managed-servers.id.databases.id.edit',
                        routeParams: {
                            databaseId: createdObject.result.id,
                            machineId: this._machineId
                        },
                        routeOptions: { reload: true }
                    });
                    break;
            }
            /* eslint-enable @typescript-eslint/restrict-template-expressions */
        }
    };
}
