import * as ng from 'angular';
import { WizardObject } from '../../wizard-supervisor/wizard-supervisor';

export class MoleculeWizardSummaryItemWebhostingController implements ng.IController {
    public static $inject: string[] = ['$translate'];
    public serviceObject: WizardObject;
    public additionalStorageQuota = 0;
    public accessLevels: any[] = [];

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {
        this.accessLevels = [
            {type: 'ftpAccess', label: this.$translate.instant('TR_100119-ca0b1e_TR')},
            {type: 'sshAccess', label: this.$translate.instant('TR_100119-fecdf6_TR')},
            {type: 'statsAccess', label: this.$translate.instant('TR_100119-77f707_TR')},
            {type: 'ftpLimited', label: this.$translate.instant('TR_100119-54245d_TR')}
        ];
    }

    $onInit() {
        if (this.serviceObject.children !== undefined && this.serviceObject.children.length > 0) {
            this.additionalStorageQuota = this.serviceObject.children[0].unit * this.serviceObject.children[0].amount;
        }
    }

    public getAccessName() {
        let accessName = this.serviceObject.display.newWebspaceUserObject.name;

        if (this.serviceObject.display.accessType === '1') {
            this.serviceObject.display.availableAccesses.some((access) => {
                if (access.value === this.serviceObject.settings.choosenAccessId) {
                    accessName = access.names[0];
                    return true;
                }
                return false;
            });
        }
        return accessName;

    }

    public getAccessRights() {
        if (this.serviceObject.display.newAccessObject.ftpLimited) {
            return this.accessLevels[3].label;
        }

        const rights = [];
        this.accessLevels.forEach(level => {
            if (this.serviceObject.display.newAccessObject[level.type]) {
                rights.push(level.label);
            }
        });
        return rights.join(', ');
    }
}

export class MoleculeWizardSummaryItemWebhostingComponent implements ng.IComponentOptions {
    public bindings = {
        serviceObject: '='
    };
    public template = require('./wizard-summary-webhosting.html');
    public controller =  MoleculeWizardSummaryItemWebhostingController;
}
