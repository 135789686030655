import * as ng from 'angular';
import { DomainInfoHelperService, ValidateEmail } from '../../../../../services';
import { FormInputModelOptions } from '../../../../atoms/form-elements';

export class MoleculeInputTextEmailController implements ng.IController {
    public static $inject: string[] = ['$translate', 'domainInfoHelper'];

    public validationErrorList = [];
    public value: string;
    public placeholder: string;
    public callbackOnChange: (arg0: any) => any;
    public callbackOnChangeParams: any;
    public callbackOnBlur: (arg0: any) => any;
    public callbackOnBlurParams: any;
    public disabled: boolean;
    public modelOptions: FormInputModelOptions;
    public registerToForm: boolean;
    public options: any;
    public validationInstructions: any[];
    public inputId: string;
    public callbackOnClear: (arg0: any) => any;
    public callbackOnClearParams: any;
    public clearable: boolean;

    private validator;
    private validationErrorListOuter: any[] = [];
    private _validationErrorListOuter: any[] = [];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private domainInfoHelper: DomainInfoHelperService
    ) {}

    public $onInit() {
        let allowEmpty = true;
        if (this.options != null && this.options !== undefined) {
            if (this.options.allowEmpty != null && this.options.allowEmpty !== undefined) {
                allowEmpty = this.options.allowEmpty;
            }
        }

        this.validator = new ValidateEmail(allowEmpty, this.$translate, this.domainInfoHelper);
        this._validationErrorListOuter = ng.copy(this.validationErrorListOuter);
        this.validationInstructions = [
            {
                instructions: null,
                validator: this.validator
            }
        ];
    }

    public $doCheck() {
        if (!ng.equals(this.validationErrorListOuter, this._validationErrorListOuter)) {
            // Holy shit, creepy hack - but there's no other way, is there?
            this.validator.updateValidatorErrors(this.validationErrorListOuter);
            this.validationErrorList = this.validator.validate(this.value);
            this._validationErrorListOuter = ng.copy(this.validationErrorListOuter);
        }
    }

    public onPaste = (paste) => {
        // implement callback on paste here when needed!
    };
}

export class MoleculeInputTextEmailComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnBlur: '<',
        callbackOnBlurParams: '<',
        callbackOnChange: '<',
        callbackOnChangeParams: '<',
        callbackOnClear: '<',
        callbackOnClearParams: '<',
        clearable: '<',
        customCallbackOnEnter: '<',
        disabled: '<',
        doNotSaveOnFormEnter: '<',
        inputId: '@?',
        modelOptions: '<',
        options: '<',
        placeholder: '@',
        registerToForm: '<',
        validationErrorList: '=?',
        validationErrorListOuter: '=?',
        value: '='
    };
    public controller = MoleculeInputTextEmailController;
    public template = require('./input-text-with-validation-wrapper.html');
}
