import * as ng from 'angular';
import { AlertManagerService } from '../../../../../../services';
import { OrganismOverviewController } from '../../../../../organisms/overview';

export class MoleculePanelOverviewHeaderMultiMoveActionController implements ng.IController {
    public static $inject: string[] = ['alertManager', '$translate'];

    public OrganismOverview: OrganismOverviewController;
    public confirmHint = false;
    public targetAccount: string;
    public serviceName: string;

    constructor(
        private alertManager: AlertManagerService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public abort = () => {
        this.OrganismOverview.overviewModel.activeMassAction = false;
    };

    public submit = () => {
        const promise = this.OrganismOverview.submitMassAction(
            { methode: 'moveSubmit', targetAccount: this.targetAccount }
        );
        promise.then(
            (result) => {
                if (result) {
                    this.alertManager.success(this.$translate.instant('TR_100119-9e1acf_TR'));
                    this.abort();
                    this.OrganismOverview.overviewLoad();
                }
            }, (e) => {
                console.error('error', e);
            });

    };

    public get disableSubmitButton() {
        return !this.confirmHint || this.targetAccount === undefined;
    }

    public getTemplate = () => {
        return require('./multi-move-' + this.serviceName + '-action.html?external');
    };
}

export class MoleculePanelOverviewHeaderMultiMoveActionComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        serviceName: '<'
    };
    public controller =  MoleculePanelOverviewHeaderMultiMoveActionController;
    public template = require('./multi-move-action.html');
}
