import ng from 'angular';

import { UiRights } from '@/configuration';
import {
    AuthContextService,
    Deferred,
    MachineModelService,
    StorageModelService
} from '@/services';

import './menu-main.scss';

export type MenuEntryOptionsType = {
    icon?: string;
    isActive?: boolean;
    isOpen?: boolean;
    link: string;
    linkAliases?: string[];
    linkOptions?: unknown;
    logoPath?: string;
    matchAdditionalRoutesOutsideThisState?: string[];
    subLevels?: MenuEntry[];
    title: string;
    visible?: boolean;
};
class MenuEntry {
    public icon: MenuEntryOptionsType['icon'] = '';
    public isActive: MenuEntryOptionsType['isActive'] = false;
    public isOpen: MenuEntryOptionsType['isOpen'] = false;
    public link: MenuEntryOptionsType['link'] = '';
    public linkAliases: MenuEntryOptionsType['linkAliases'] = [];
    public linkOptions: MenuEntryOptionsType['linkOptions'] = {};
    public logoPath: MenuEntryOptionsType['logoPath'] = '';
    public matchAdditionalRoutesOutsideThisState: MenuEntryOptionsType['matchAdditionalRoutesOutsideThisState'] = [];
    public title: MenuEntryOptionsType['title'] = '';
    public subLevels: MenuEntryOptionsType['subLevels'] = [];
    public visible: MenuEntryOptionsType['visible'] = true;

    constructor(
        public options?: MenuEntryOptionsType
    ) {
        for (const [key, values] of Object.entries(options)) {
            Object.defineProperty(
                this,
                key,
                { value: values, writable: true}
            );
        }
    }

    public addSubLevel: (options?: MenuEntryOptionsType) => MenuEntry = (options?) => {
        this.subLevels.push(new MenuEntry(options));

        return this;
    };
}

/**
 * The 'setCurrentMenuEntryStatus' method sets the corresponding MenuEntry status
 * (isActive, isOpen) so that the menu is displayed according to the state route.
 *
 * The method is called only in three places:
 *
 * 1. when loading directly in the browser (via URL or F5) using the _rebuildMenu() method.
 * 2. when changing the state (in the constructor: $stateChangeSuccess).
 * 3. when clicking on a menu item (via the main menu component), if it is a MenuEntryItem
 *    without a route (for example: 'products').
 */
export class MoleculeMenuMainController implements ng.IController {
    public static $inject: string[] = [
        '$rootScope',
        '$state',
        '$translate',
        'machineModel',
        'storageModel'
    ];

    private static refreshDeferred: Deferred<unknown>;

    public menu: MenuEntry[];
    public hasManagedServer = false;
    public hasNextcloudServer = false;

    private _menuEntries = {
        dashboard: {} as MenuEntry,
        bundles: {} as MenuEntry,
        helpdesk: {} as MenuEntry,
        managedServers: {} as MenuEntry,
        nextcloud: {} as MenuEntry,
        ownAccount: {} as MenuEntry,
        products: {} as MenuEntry,
        reseller: {} as MenuEntry
    };
    private _menuItemsWithoutRoute = ['products'];
    private _loadingMenu = true;

    // Refresh trigger (_rebuildMenu: used in wizard to extend menu with addional menu entry
    public static triggerRefresh = (service: unknown): void => {
        if ([undefined, null].indexOf(MoleculeMenuMainController.refreshDeferred) < 0) {
            MoleculeMenuMainController.refreshDeferred.resolve(service);
        }
    };

    public constructor(
        private $rootScope: ng.IRootScopeService,
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private machineModel: MachineModelService,
        private storageModel: StorageModelService
    ) {
        // trigger state change to update current menu entries
        this.$rootScope.$on('$stateChangeSuccess', () => {
            this._setCurrentMenuEntryStatus();
        });
    }

    public $onInit(): void {
        void this._rebuildMenu();
    }

    public get loadingMenu(): boolean {
        return this._loadingMenu;
    }

    public clickedMenuEntry = (item: MenuEntry): void => {
        this._setCurrentMenuEntryStatus(item);
    };

    public useRealLink = (item: MenuEntry): boolean => {
        return !this._isStatePartInStatePartList(item.link, this._menuItemsWithoutRoute);
    };

    private _setCurrentMenuEntryStatus = (clickedItem?: MenuEntry): void => {
        if (clickedItem && this._isStatePartInStatePartList(clickedItem.link, this._menuItemsWithoutRoute)) {
            /** Special case - item without route:
             *  if a menu item was clicked and this item was defined without a route, we must always set
             *  the status isOpen against it (open or close).
             *  For example: 'products' (defined in list this._menuItemsWithoutRoute)
             */
            const hasActiveProductSublevels = this._checkActiveSubLevels(
                clickedItem,
                this.$state.current.name.split('.'),
                true
            );
            clickedItem.isOpen = !clickedItem.isOpen;
            clickedItem.isActive = !clickedItem.isOpen && hasActiveProductSublevels;
            return;
        }

        // If a item was clicked, set current state item parts according to the clicked item.
        // Otherwise according to current state name parts
        const currentStateRoute = clickedItem
            ? clickedItem.link
            : this.$state.current.name;
        const currentStateParts = currentStateRoute.split('.');

        // Check bundle state and if necessary set another menu item for the called bundle
        // with the title of the bundle named
        this._checkBundleStateAndHandleBundleNameAsSubLevel(currentStateRoute);

        for (const serviceMenuEntry of Object.values(this.menu)) {
            const menuItemStateParts = serviceMenuEntry.link.split('.');
            const menuItemStateAliasesParts = serviceMenuEntry.linkAliases ? serviceMenuEntry.linkAliases : [];
            let itemLinkAliasMatch = false;

            // Check state alias parts (if exists) of first level
            if (menuItemStateAliasesParts.length > 0) {
                itemLinkAliasMatch = menuItemStateAliasesParts.some(
                    (linkAlias: string) => linkAlias === currentStateParts[0]
                );
            }

            // Start to check first level of service menu entry
            if (
                itemLinkAliasMatch
                || this._isStatePartInStatePartList(serviceMenuEntry.link, this._menuItemsWithoutRoute)
                || currentStateParts[0] === menuItemStateParts[0]
            ) {
                if (currentStateParts.length === 0) {
                    // Current state route (parts) has is one level deep
                    serviceMenuEntry.isActive = serviceMenuEntry.subLevels.length === 0;
                    serviceMenuEntry.isOpen =  serviceMenuEntry.subLevels.length > 0;
                } else {
                    // Current state route has more than one route parts
                    // So check sublevels of service menu entry
                    const hasActiveSublevels = this._checkActiveSubLevels(
                        serviceMenuEntry,
                        currentStateParts,
                        this._isStatePartInStatePartList(serviceMenuEntry.link, this._menuItemsWithoutRoute)
                    );

                    if (this._isStatePartInStatePartList(serviceMenuEntry.link, this._menuItemsWithoutRoute)) {
                        // Set status of menu entry without route and no active children menu entries
                        serviceMenuEntry.isActive = false;
                        serviceMenuEntry.isOpen = hasActiveSublevels;
                    } else {
                        // Normal case
                        serviceMenuEntry.isActive  = !hasActiveSublevels;
                        serviceMenuEntry.isOpen = serviceMenuEntry.subLevels.length > 0 || hasActiveSublevels;
                    }
                }

                continue;
            } else if (serviceMenuEntry.matchAdditionalRoutesOutsideThisState.length > 0) {
                // Check if matchAdditionalRouteOutsideThisState match to current state
                serviceMenuEntry.isActive = serviceMenuEntry.matchAdditionalRoutesOutsideThisState.some(
                    (additionlRoute: string) => additionlRoute === currentStateRoute
                );
                serviceMenuEntry.isOpen = false;

                // check menu entry subLevels, to possible disable active subLevel menu entries
                this._checkActiveSubLevels(
                    serviceMenuEntry,
                    currentStateParts,
                    this._isStatePartInStatePartList(serviceMenuEntry.link, this._menuItemsWithoutRoute)
                );

                continue;
            }
            // reset active and open status of menu entry
            serviceMenuEntry.isActive = false;
            serviceMenuEntry.isOpen = false;
        }
    };

    private _checkActiveSubLevels = (
        menuEntry: MenuEntry,
        currentStateParts: string[],
        stateWithoutRoute: boolean
    ): boolean => {
        let subLevelHasActiveRoute = false;
        for (const subLevel of menuEntry.subLevels) {
            /**
             * The current menu has a maximum of 2 levels.
             * Only for menu items that have no route in the first level (for example products)
             * we have to check one level deeper.
             */
            const hasActiveSublevelStateRoute = stateWithoutRoute
                ? subLevel.link.split('.')[0] === currentStateParts[0]
                : this._checkEqualityOfLinkParts(subLevel.link.split('.'), currentStateParts);

            if (hasActiveSublevelStateRoute) {
                subLevelHasActiveRoute = true;
                subLevel.isActive = true;
                subLevel.isOpen = subLevel.subLevels.length > 0;
            } else {
                subLevel.isActive = false;
            }
        }

        return subLevelHasActiveRoute;
    };

    /** Please be advised, this is a recusive method */
    private _checkEqualityOfLinkParts = (
        routePartsOne: string[],
        routePartsTwo: string[],
        checkIndex?: number
    ): boolean => {
            checkIndex = checkIndex || 0;
            /**
             * The current menu has a maximum of 2 levels.
             * So at the moment we need to check the maximum depth up to the 2nd route part (=> maxDeepIndesx = 1).
             * Should we later have more levels, we can adjust the maximum depth here.
             * If we need to check the whole red parts, we can do it here
             * with maxDeeppIndex = (routePartsOne.lenght - 1).
             */
            const maxDeepIndex = 1;

            if (routePartsOne[checkIndex] !== routePartsTwo[checkIndex]) {
                // No match - part list length is different
                return false;
            }

            if (maxDeepIndex === checkIndex) {
                // Matched! - we have reached the deepest part in the part lists and have had agreements so far
                return true;
            }

            /**
             * So far we have had agreements, however we have not yet reached the lowest point in the part list.
             * Call this recusive method again and search one level deeper
             */
            const nextIndexLevelMatch = this._checkEqualityOfLinkParts(routePartsOne, routePartsTwo, (checkIndex + 1));
            return nextIndexLevelMatch;

    };

    private _rebuildMenu = async (reCheckServers?: string): Promise<void> => {
        this._loadingMenu = true;
        reCheckServers = reCheckServers || '';

        MoleculeMenuMainController.refreshDeferred = new Deferred<any>();
        void MoleculeMenuMainController.refreshDeferred.promise.then((service: string) => {
            void this._rebuildMenu(service);
        });

        this._menuEntries = {
            dashboard: new MenuEntry({
                icon: 'home-lg-alt',
                title: this.$translate.instant('b1b21080-012f-41a9-b11e-7dd7237de1df'),
                link: 'dashboard',
                linkAliases: ['nextcloud-dashboard', 'old-dashboard']
            }),
            bundles: new MenuEntry({
                icon: 'boxes-alt',
                title: this.$translate.instant('ad87088f-c79a-47ef-b86c-8bbced243db4'),
                link: 'bundle.dashboard'
            }),
            managedServers: new MenuEntry({
                icon: 'cloud-upload',
                title: this.$translate.instant('TR_130319-63b110_TR'),
                link: 'managed-servers.overview'
            }),
            nextcloud: new MenuEntry({
                logoPath: '/assets/images/logos/nextcloud-w.svg',
                title: this.$translate.instant('TR_140519-4f0af6_TR'),
                link: 'nextcloud.overview'
            }),
            products: new MenuEntry({
                icon: 'th',
                title: this.$translate.instant('MENU.MAIN.PRODUCTS.LABEL'),
                link: 'products'
            })
                .addSubLevel({
                    icon: 'globe',
                    title: this.$translate.instant('TR_140119-aeb48a_TR'),
                    link: 'domain.dashboard'
                })
                .addSubLevel({
                    icon: 'location-arrow',
                    title: this.$translate.instant('03d67c8e-683a-4982-ac59-5637dfe27b71'),
                    link: 'dns.dashboard'
                })
                .addSubLevel({
                    icon: 'shield',
                    title: this.$translate.instant('TR_090119-c1e94f_TR'),
                    link: 'ssl.dashboard'
                })
                .addSubLevel({
                    icon: 'envelope',
                    title: this.$translate.instant('TR_080119-8dd4a1_TR'),
                    link: 'email.dashboard'
                })
                .addSubLevel({
                    icon: 'server',
                    title: this.$translate.instant('TR_090119-12c8cf_TR'),
                    link: 'webhosting.dashboard'
                })
                .addSubLevel({
                    icon: 'database',
                    title: this.$translate.instant('TR_090119-f0ccfb_TR'),
                    link: 'database.dashboard'
                })
                .addSubLevel({
                    icon: 'cloud',
                    title: this.$translate.instant('TR_090119-ad5c6b_TR'),
                    link: 'machine.dashboard'
                })
                .addSubLevel({
                    title: this.$translate.instant('TR_140519-4f0af6_TR'),
                    link: 'storage.dashboard',
                    logoPath: '/assets/images/logos/nextcloud-w.svg'
                }),
            ownAccount: new MenuEntry({
                icon: 'briefcase',
                title: this.$translate.instant('TR_140119-b3b187_TR'),
                link: 'account.dashboard',
                matchAdditionalRoutesOutsideThisState: [
                    'profile',
                    'profile.apikeys',
                    'profile.apikeys.new',
                    'profile.apikeys.id',
                    'profile.apikeys.id.edit'
                ]
            })
                .addSubLevel({
                    icon: 'id-card-alt',
                    title: this.$translate.instant('61832ca0-80be-4570-b401-8a68bb9f29e0'),
                    link: 'account.basicdata'
                })
                .addSubLevel({
                    icon: 'sliders-h',
                    title: this.$translate.instant('TR_080119-5c34b8_TR'),
                    link: 'account.settings'
                })
                .addSubLevel({
                    icon: 'user',
                    title: this.$translate.instant('TR_130319-0cb933_TR'),
                    link: 'account.users.overview'
                })
                .addSubLevel({
                    icon: 'sync-alt',
                    title: this.$translate.instant('MENU.MAIN.DATA-PROCESSING'),
                    link: 'account.dataprocessing'
                }),
            reseller: new MenuEntry({
                icon: 'users',
                title: this.$translate.instant('TR_090119-10d3f0_TR'),
                link: 'reseller.dashboard'
            })
                .addSubLevel({
                    icon: 'users-cog',
                    title: this.$translate.instant('TR_080119-5c34b8_TR'),
                    link: 'reseller.settings'
                })
                .addSubLevel({
                    icon: 'lock',
                    title: this.$translate.instant('TR_100119-cf5c57_TR'),
                    link: 'reseller.rightstemplates.overview'
                })
                .addSubLevel({
                    icon: 'address-book',
                    title: this.$translate.instant('MENU.MAIN.ACCOUNT.SUBACCOUNT'),
                    link: 'reseller.subaccounts.overview'
                }),
            helpdesk: new MenuEntry({
                icon: 'life-ring',
                title: this.$translate.instant('MENU.MAIN.HELPDESK'),
                link: 'helpdesk',
                linkOptions: {
                    level1: null,
                    level2: null,
                    level3: null,
                    level4: null,
                    level5: null,
                    q: null
                }
            })
        };

        this.menu = [
            this._menuEntries.dashboard,
            this._menuEntries.bundles,
            this._menuEntries.products,
            this._menuEntries.ownAccount,
            this._menuEntries.reseller
        ];

        // Billing MenuEntry
        this._setBillingMenuEntry();

        // Helpdesk MenuEntry
        if (AuthContextService.isStaff || AuthContextService.isDirectCustomer) {
            this.menu.push(this._menuEntries.helpdesk);
        }

        // Check bundle state and if necessary set another menu item for the called bundle
        // with the title of the bundle named
        this._checkBundleStateAndHandleBundleNameAsSubLevel();

        // Managed server menuEntry
        await this._setManagedMachineMenuEntry(reCheckServers);

        // Nextcloud menyEntry
        await this._setNextcloudMenuEntry(reCheckServers);

        this._checkPermissions();
        this._setCurrentMenuEntryStatus();
        this._loadingMenu = false;
    };

    /**
     * Here comes helper stuff
     */
    private _setBillingMenuEntry = (): void => {
        const isBillable = ['prepaid, postpaid'].indexOf(AuthContextService.billingSettings.paymentType) >= 0;
        const showPricelist = !AuthContextService.isRootOrCompanyAccount
            && AuthContextService.isGrantedAny([
                UiRights.BIL_LIST_PRICES_DATABASE,
                UiRights.BIL_LIST_PRICES_DNS,
                UiRights.BIL_LIST_PRICES_DOMAIN,
                UiRights.BIL_LIST_PRICES_EMAIL,
                UiRights.BIL_LIST_PRICES_MACHINE,
                UiRights.BIL_LIST_PRICES_SSL,
                UiRights.BIL_LIST_PRICES_WEBHOSTING
            ]);

        if (
            AuthContextService.isDirectCustomer
            || isBillable
            || showPricelist
        ) {
            const billingEntry = new MenuEntry({
                icon: 'money-bill-alt',
                title: this.$translate.instant('MENU.MAIN.BILLING.LABEL'),
                link: 'billing.dashboard'
            });

            if (AuthContextService.isDirectCustomer) {
                billingEntry.addSubLevel({
                    icon: 'paperclip',
                    title: this.$translate.instant('MENU.MAIN.BILLING.INVOICE'),
                    link: 'billing.invoices.overview'
                });
            }

            if (AuthContextService.isDirectCustomer || isBillable) {
                billingEntry.addSubLevel({
                    icon: 'tasks-alt',
                    title: this.$translate.instant('TR_090119-a8b028_TR'),
                    link: 'billing.payment-options'
                });
            }

            if (AuthContextService.isDirectCustomer) {
                billingEntry.addSubLevel({
                    icon: 'history',
                    title: this.$translate.instant('TR_040619-4ca9a1_TR'),
                    link: 'billing.payments-overview'
                });
            }

            if (showPricelist) {
                billingEntry.addSubLevel({
                    icon: 'funnel-dollar',
                    title: this.$translate.instant('TR_040419-4aa553_TR'),
                    link: 'billing.pricelists'
                });
            }

            if (billingEntry.subLevels.length === 1) {
                this.menu.push(billingEntry.subLevels[0]);
            } else {
                this.menu.push(billingEntry);
            }
        }
    };

    private _setManagedMachineMenuEntry = async (reCheckServers?: string): Promise<void> => {
        const productMenuPosition = this._getProductMenuEntryIndexInMenu();
        if (this.hasManagedServer) {
            this.menu.splice(productMenuPosition, 0, this._menuEntries.managedServers);
        } else if (
            reCheckServers === 'ManagedServer'
            && AuthContextService.isGranted(UiRights.MACHINE_VM_LIST)
        ) {
            const filter = {
                field: 'virtualMachineManagementType',
                value: 'platform'
            };
            await this.machineModel.listWithoutPagination(1, 1, filter)
                .then(
                    (res) => {
                        if (res.data.length > 0) {
                            this.menu.splice(productMenuPosition, 0, this._menuEntries.managedServers);
                            return;
                        }
                    }
                );
        }
    };

    private _setNextcloudMenuEntry = async (reCheckServers?: string): Promise<void> => {
        const productMenuPosition = this._getProductMenuEntryIndexInMenu();

        if (this.hasNextcloudServer) {
            this.menu.splice(productMenuPosition, 0, this._menuEntries.nextcloud);
        } else if (
            reCheckServers === 'NextcloudServer'
            && AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_LIST)
        ) {
            void await this.storageModel.listNextcloudsWithoutPagination(1)
                .then(
                    (res) => {
                        if (res.data.length > 0) {
                            this.menu.splice(productMenuPosition, 0, this._menuEntries.nextcloud);
                            return;
                        }
                    }
                );
        }
    };

    private _checkBundleStateAndHandleBundleNameAsSubLevel = (currentStateRoute?: string): void => {
        // Check if current route has bundle id and add or remove bundle name as sublevel menu entry
        let bundleMenuEntry: MenuEntry;
        this.menu.some(
            (menuEntry) => {
                if (menuEntry.link === 'bundle.dashboard') {
                    bundleMenuEntry = menuEntry;
                    return true;
                }
                return false;
            }
        );

        if (bundleMenuEntry) {
            bundleMenuEntry.subLevels = [];
            currentStateRoute = currentStateRoute || this.$state.current.name;

            if (currentStateRoute.indexOf('bundle.id') === 0) {
                bundleMenuEntry.addSubLevel({
                    icon: 'box-alt',
                    title: this.$state.$current.locals.globals.bundle.name,
                    link: 'bundle.id.dashboard',
                    isOpen: true,
                    visible: true
                });
            }
        }
    };

    private _checkPermissions = (): void => {
        this.menu.forEach(
            (category) => {
                if (category.subLevels !== undefined) {
                    category.subLevels.forEach((subLevel) => {
                        this._checkPermissionForLevel(subLevel);
                    });
                }
                this._checkPermissionForLevel(category);
            }
        );
    };

    private _checkPermissionForLevel = (level: MenuEntry): void => {
        const state = this.$state.get(level.link);

        if (!state || !state?.data) {
            level.visible = true;
            return;
        }
        if (state.data.isGrantedAny !== undefined) {
            level.visible = AuthContextService.isGrantedAny(state.data.isGrantedAny);
            return;
        }
        if (state.data.isGrantedAll !== undefined) {
            level.visible = AuthContextService.isGrantedAll(state.data.isGrantedAll);
            return;
        }
        if (state.data.isGranted !== undefined) {
            level.visible = AuthContextService.isGranted(state.data.isGranted);
            return;
        }
        level.visible = true;
    };

    private _getProductMenuEntryIndexInMenu = (): number => {
        let productIndex: number;
        this.menu.some((menuEntry, index) => {
            if (menuEntry.link === 'products') {
                productIndex = index;
                return true;
            }
            return false;
        });

        return productIndex;
    };

    private _isStatePartInStatePartList = (statePart: string, statePartList: string[]): boolean => {
        return statePartList.some(
            (statePartFromList: string) => statePartFromList === statePart
        );
    };
}

export class MoleculeMenuMainComponent implements ng.IComponentOptions {
    public bindings = {
        hasManagedServer: '<',
        hasNextcloudServer: '<'
    };
    public controller = MoleculeMenuMainController;
    public template = require('./menu-main.html');
}
