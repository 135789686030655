import * as ng from 'angular';
import { AlertManagerService } from '../../../../../../services';
import { OrganismOverviewController } from '../../../../../organisms/overview';

export class MoleculePanelOverviewHeaderMultiDeleteRestorableActionController implements ng.IController {
    public static $inject: string[] = ['alertManager', '$timeout', '$translate'];

    public OrganismOverview: OrganismOverviewController;
    public confirmHint = false;
    public deleteType: string;
    public serviceName: string;

    constructor(
        private alertManager: AlertManagerService,
        private $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.deleteType = 'permanently';
    }

    public abort = () => {
        this.OrganismOverview.overviewModel.activeMassAction = false;
    };

    public submit = () => {
        this.OrganismOverview.submitMassAction('deleteRestoreSubmit')
        // todo - show request message in view
            .then(
                (result) => {
                    this.alertManager.success(this.$translate.instant('TR_100119-64b68b_TR'));
                    this.abort();
                    this.$timeout(() => {
                        this.OrganismOverview.overviewLoad();
                    }, 1000);
                }, (e) => {
                    console.error('error', e);
                }
            );
    };

    // tslint:disable-next-line:max-line-length
    public getTemplate = () => require('./multi-delete-restorable-' + this.serviceName + '-action.html?external');
}

export class MoleculePanelOverviewHeaderMultiDeleteRestorableActionComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        serviceName: '<'
    };
    public controller =  MoleculePanelOverviewHeaderMultiDeleteRestorableActionController;
    public template = require('./multi-delete-restorable-action.html');
}
