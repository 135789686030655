import { Validator } from './abstract-validator';

export class ValidateIllegalCharacters extends Validator {
    private illegalCharacters: string;

    constructor(illegalCharacters: string, private $translate: ng.translate.ITranslateService) {
        super();
        this.illegalCharacters = illegalCharacters;
    }

    public validate = (value) => {
        let illegalCharactersFound = '';
        let illegalCharactersMessage = '';

        for (const char of value) {
            if (this.illegalCharacters.indexOf(char) >= 0
                && illegalCharactersFound.indexOf(char) < 0
            ) {
                if (char !== ' ') {
                    illegalCharactersMessage += char;
                } else {
                    illegalCharactersMessage += ' ' + this.$translate.instant('TR_170119-11fb1a_TR') + ' ';
                }

                illegalCharactersFound += char;
            }
        }

        if (illegalCharactersFound.length > 0) {
            return [
                {
                    text: this.$translate.instant(
                        /* translationId */ 'TR_160119-5e8caf_TR',
                        { illegalCharactersMessage: illegalCharactersMessage }
                    )
                }
            ];
        }

        return [];
    };
}
