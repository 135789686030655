import * as ng from 'angular';
import { OrganismOverviewController, OverviewDataObject } from '../../../../organisms';

export class MoleculeOverviewRowPanelDnsZonesController implements ng.IController {
    public static $inject: string[] = ['$state'];

    public overviewData: OverviewDataObject;
    public OrganismOverview: OrganismOverviewController;
    public overviewLoad: (selection: any) => any;
    public internalPaginationLimit: number;

    constructor(private $state: ng.ui.IStateService) {}

    public get showAccounts() {
        return this.OrganismOverview.globals.showAccounts;
    }

    public get editRoute() {
        return this.$state.current.name.replace('.overview', '.id.edit');
    }
}

export class MoleculeOverviewRowPanelDnsZonesComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelDnsZonesController;
    public controllerAs = 'OverviewDnsZonesCtrl';
    public template = require('./dns-zones-row.html');
}
