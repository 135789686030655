import * as ng from 'angular';
import { AccountModelService, ManagedMachineProjectSubaccountTypes } from '../../../services';
import { ManagedMachineProjectObject } from '../../organisms';

export class OrganManagedMachineProjectSubAccountSelectionController implements ng.IController {
    public static $inject: string[] = ['$state', 'accountModel'];

    public projectData: ManagedMachineProjectObject;
    public selectedAccountId = null;
    public subaccounts = [];
    public accountTypes = {
        existend: ManagedMachineProjectSubaccountTypes.EXISTEND,
        new: ManagedMachineProjectSubaccountTypes.NEW,
        none: ManagedMachineProjectSubaccountTypes.NONE
    };

    public _loadSubaccountItems = true;

    constructor(
        private $state: ng.ui.IStateService,
        private accountModel: AccountModelService
    ) {}

    public $onInit() {
        this._loadSubaccounts();
    }

    public get complete() {
        switch (this.projectData.accountType) {
            case ManagedMachineProjectSubaccountTypes.EXISTEND:
                this.projectData.accountId = this.selectedAccountId;
                return [undefined, null, ''].indexOf(this.selectedAccountId) === -1;
            case ManagedMachineProjectSubaccountTypes.NONE:
                this.projectData.accountId = this.$state.$current.locals.globals.machine.accountId;
                return true;
            case ManagedMachineProjectSubaccountTypes.NEW:
                this.projectData.accountId = null;

                return this.projectData.newSubAccount.name.length > 0
                    && this.projectData.newSubAccount.country.length > 0
                    && this.projectData.newSubAccount.emailAddress.length > 0
                    && this.projectData.newSubAccount.password.length > 0;
            default:
                return false;
        }
    }

    /* tslint:disable-next-line:no-empty */
    public set complete({}) {}

    public get loadSubaccountItems() {
        return this._loadSubaccountItems;
    }

    /* tslint:disable-next-line:no-empty */
    public set loadSubaccountItems({}) {}

    public selectAccountType = (type: ManagedMachineProjectSubaccountTypes) => {
        this.projectData.accountType = type;
    };

    private _loadSubaccounts = () => {
        this.accountModel.listSubaccounts().then((subAccounts) => {
            this.subaccounts = subAccounts.data;
            this._loadSubaccountItems = false;
        });
    };
}

export class OrganManagedMachineProjectSubAccountSelectionComponent implements ng.IComponentOptions {
    public bindings = {
        complete: '=',
        projectData: '='
    };

    public template = require('./sub-account-selection.html');
    public controller =  OrganManagedMachineProjectSubAccountSelectionController;
}
