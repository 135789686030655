import * as ng from 'angular';
import { DocumentModelService, OverviewHelperService } from '../../../../services';
import { DEFAULT_TIMEOUT_REFRESH_INTERVAL } from './../../../../configuration/system';
import './panel-invoice-add-to-pay-table.scss';

export class MoleculePanelInvoiceAddToPayTableController implements ng.IController {
    public static $inject: string[] = ['$timeout', 'documentModel', 'overviewHelper'];

    public selected: { [documentId: string]: boolean };
    public invoice;
    public invoiceList: any[] = [];
    public isSubmitting = false;
    public paymentMethod = '';
    public directDebitHintChecked = false;
    public paymentMethodAvailable = true;

    constructor(
        private $timeout: ng.ITimeoutService,
        private documentModel: DocumentModelService,
        public overviewHelper: OverviewHelperService
    ) {}

    public $onInit() {
        this.selected = {};

        for (const invoice of this.invoiceList) {
            this.selected[invoice.documentId] = invoice.documentId === this.invoice.documentId;
        }
    }

    public get sumTotal() {
        return this.invoiceList
            .filter((invoice) => [undefined, null, false].indexOf(this.selected[invoice.documentId]) < 0)
            .map((invoice) => this._getInvoiceTotalSum(invoice))
            .reduce((a, b) => a + b, 0);
    }

    public get disableSubmitButton() {
        return this.isSubmitting || !this.directDebitHintChecked || !this.paymentMethodAvailable;
    }

    public get selectedTotal() {
        let selected = 0;

        for (const invoice of this.invoiceList) {
            if (this.selected[invoice.documentId]) {
                selected++;
            }
        }

        return selected;
    }

    /* tslint:disable-next-line:no-empty */
    public set selectedAllIndeterminate({}) {} // eslint-disable-line no-empty-pattern
    public get selectedAllIndeterminate() {
        return this.overviewHelper.selectedAllIndeterminate(
            this.invoiceList,
            this.selected
        );
    }

    public payNow = () => {
        const invoiceIds = [this.invoice.documentId];

        for (const id in this.selected) {
            if (this.invoice.documentId !== id && this.selected[id]) {
                invoiceIds.push(id);
            }
        }

        this.isSubmitting = true;
        this.documentModel.payment.start(this.paymentMethod, invoiceIds);

        this.$timeout(
            () => {
                // reset button loader and disable after 5 seconds
                this.isSubmitting = false;
            },
            DEFAULT_TIMEOUT_REFRESH_INTERVAL
        );
    };

    private _getInvoiceTotalSum = (invoice) => {
        let sum = invoice.outstanding;
        const reminderLevels = invoice.reminderLevels.length;

        if (reminderLevels > 0) {
            for (const level of invoice.reminderLevels) {
                sum += level.outstanding;
            }
        }

        return sum;
    };

    public get selectedAll(): boolean {
        return !this.invoiceList.some((item) => [undefined, null, false].indexOf(this.selected[item.documentId]) >= 0);
    }

    public set selectedAll(value: boolean) {
        if (value) {
            this.invoiceList.map((item) => this.selected[item.documentId] = true);
        } else {
            this.selected = {};
            this.selected[this.invoice.documentId] = true;
        }
    }
}

export class MoleculePanelInvoiceAddToPayTableComponent implements ng.IComponentOptions {
    public bindings = {
        invoice: '<',
        invoiceList: '<openInvoiceList'
    };
    public controller = MoleculePanelInvoiceAddToPayTableController;
    public template = require('./panel-invoice-add-to-pay-table.html');
}
