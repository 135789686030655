import * as ng from 'angular';

export class MoleculePanelEditRowTableBundleBackupsConfirmRestorationController implements ng.IController {
    public static $inject: string[] = [];
    public backup: any;
    public opened: boolean;
    public confirmed = false;
    private $backupPanel;

    public restoreBackup = () => {
        this.$backupPanel.closeRestoreConfirmation();
        this.$backupPanel.restoreBackups(this.backup);
    };

    public cancel = () => {
        this.confirmed = false;
        this.$backupPanel.closeRestoreConfirmation();
    };
}

export class MoleculePanelEditRowTableBundleBackupsConfirmRestorationComponent implements ng.IComponentOptions {
    public require = {
        $backupPanel: '^moleculePanelEditRowTableBundleBackups'
    };
    public bindings = {
        backup: '<',
        opened: '<'
    };
    public controller = MoleculePanelEditRowTableBundleBackupsConfirmRestorationController;
    // tslint:disable-next-line: max-line-length
    public template = require('./bundle-backups-confirm-restoration.html');
}
