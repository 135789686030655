import * as ng from 'angular';

export class MoleculePanelEditRowNameserverIpsRequiredController implements ng.IController {
    public static $inject: string[] = [];

    public nameservers: any[] = [];
    public domainNames: any[] = [];
}

export class MoleculePanelEditRowNameserverIpsRequiredComponent implements ng.IComponentOptions {
    public bindings = {
        nameserverList: '=nameservers',
        domains: '<'
    };
    public controller = MoleculePanelEditRowNameserverIpsRequiredController;
    public template = require('./panel-edit-row-nameserve-ips-required.html');
}
