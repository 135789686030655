/* eslint-disable */

import ng from 'angular';

import { OrganismEditFormMachineController } from '@/atomic-components/organisms';
import { UiRights, UIRegExp } from '@/configuration';
import {
    AlertManagerService,
    AuthContextService,
    MachineRobotService,
    PriceHelperService,
    ProductHelperService,
    ProductsModelService,
} from '@/services';

import * as Types from '@/types';

export class OrganEditPanelMachineBackupsController {
    public static $inject: string[] = [
        '$state',
        '$translate',
        'alertManager',
        'machineRobot',
        'priceHelper',
        'productsModel'
    ];

    public account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    public $editFormOrganism: OrganismEditFormMachineController;

    public machine: Types.MachineApi.VirtualMachine;
    public machinePrice: Types.BillingApi.ArticlePurchasePrice;

    public modelConfirmation: boolean;
    public isAdmin = false;

    public canHaveBackups: boolean = false;
    public machineBackupPrice: Types.BillingApi.ArticlePurchasePrice;
    public backupProductCode: string;
    public postfixText: string = '';

    public panelRight = {
        editPanelButton: AuthContextService.isGranted(UiRights.MACHINE_VM_EDIT)
    };

    private _modelActivation: boolean;
    public get modelActivation() {
        return this._modelActivation;
    }
    public set modelActivation(newValue) {
        this._modelActivation = newValue;
        this.modelConfirmation = false;
    }

    public get showConfirmationDialog() {
        return this.machine.backupEnabled !== this.modelActivation;
    }

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        public machineRobot: MachineRobotService,
        public priceHelper: PriceHelperService,
        private productModel: ProductsModelService
    ) {}

    public $onInit(): void {
        this.account = this.account || AuthContextService.account;
        this.modelActivation = !!this.machine.backupEnabled;
        this.isAdmin = AuthContextService.isRootOrCompanyAccount;

        this.productModel.findProduct(this.machine.productCode, this.$translate.use()).then(
            (machineProduct) => {
                const specification = ProductHelperService.wrapSpecificationItems(
                    machineProduct?.response?.specificationItems
                );

                const backupProductCodeTemplate = specification?.backupProductCode?.value as string;
                if (!backupProductCodeTemplate) {
                    this.canHaveBackups = false;
                    return;
                } else {
                    this.canHaveBackups = true;
                }

                let contractPeriod = this.getBillingPeriod(this.machine.productCode);

                const backupProductCode: string = this.priceHelper.replaceBillingCycleInProductCodeTemplate(
                    backupProductCodeTemplate,
                    `${contractPeriod}`
                );

                this.backupProductCode = backupProductCode;

                this.setPostFixText(this.backupProductCode);
            }
        );
    }

    public getBillingPeriodText(productCode: string): string {
        return this.priceHelper.getBillingPeriod(this.getBillingPeriod(productCode));
    }

    public getBillingPeriod(productCode: string) {
        UIRegExp.ProductCodeToContractPeriod.lastIndex = 0;
        return UIRegExp.ProductCodeToContractPeriod.exec(productCode)[1];
    }

    public get bruttoNettoText(): string {
        if (this.account.isCommercialCustomer) {
            return this.$translate.instant('BILLING.LABEL.NET');
        }
        return this.$translate.instant('BILLING.LABEL.GROSS');
    }

    public setPostFixText(backupProductCode: string): void {
        if (AuthContextService.isRootOrCompanyAccount) {
            this.postfixText = '';
        } else {
            this.postfixText = this.bruttoNettoText + ' ' + this.getBillingPeriodText(backupProductCode);
        }
    }

    public reset = () => {
        this.machine = ng.copy(this.$state.$current.locals.globals.machine);
        this.modelActivation = this.machine.backupEnabled;
    };

    public cancel = () => {
        this.reset();
    };

    public save = () => {
        if (
            (!this.modelConfirmation && !this.machine.backupEnabled)
            || this.machine.backupEnabled === this.modelActivation
        ) {
            return;
        }

        const machineCopy = ng.copy(this.machine);
        machineCopy.backupEnabled = this.modelActivation;

        // Create backup request.
        return this.machineRobot
            .updateMachine(machineCopy, false)
            .then(
                (response: any) => {
                    if (response.status !== 'error') {
                        this.alertManager.success(this.$translate.instant('BACKUP.ACTION.START.SUCCESS'));
                        return response;
                    }

                    return Promise.reject(false);
                },
                () => {
                    return Promise.reject(false);
                }
            );
    };
}

export class OrganEditPanelMachineBackupsComponent implements ng.IComponentOptions {
    public bindings = {
        machine: '<'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./machine-backups.html');
    public controller = OrganEditPanelMachineBackupsController;
}
