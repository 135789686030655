import * as ng from 'angular';

export class OrganDashboardWidgetSepaInfoController implements ng.IController {
    public static $inject: string[] = ['authContext'];
}

export class OrganDashboardWidgetSepaInfoComponent implements ng.IComponentOptions {
    public bindings = {};

    public template = require('./dashboard-widget-sepa-info.html');
    public controller = OrganDashboardWidgetSepaInfoController;
}
