import ng from 'angular';
import q from 'q';
import { AccessLevels } from '@/atomic-components/molecules';
import {
    AlertManagerService,
    ApiErrorModel,
    NavigationService,
    WebhostingUserModelService,
    WebspaceModelService
} from '@/services';
import { ViewTypes, WebhostingApi } from '@/types';

export class OrganismEditFormWebspaceUsersController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'apiErrorModel',
        'navigation',
        'webhostingUserModel',
        'webspaceModel'
    ];

    public searchString = '';

    public webspace: WebhostingApi.Webspace;
    public webspaceUsers: WebhostingApi.User[];
    public userAccessList: ViewTypes.WebspaceUserAccessObject[] = [];

    private accessLevels: AccessLevels[];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private navigation: NavigationService,
        private webhostingUserModel: WebhostingUserModelService,
        private webspaceModel: WebspaceModelService
    ) {}

    public $onInit = (): void => {
        this.accessLevels = [
            {
                label: this.$translate.instant('TR_100119-ca0b1e_TR'),
                shortLabel: this.$translate.instant('TR_110119-e995c5_TR'),
                type: 'ftpAccess'
            }, {
                label: this.$translate.instant('TR_100119-fecdf6_TR'),
                shortLabel: this.$translate.instant('TR_110119-a5f4d5_TR'),
                type: 'sshAccess'
            }, {
                label: this.$translate.instant('TR_110119-7e190b_TR'),
                shortLabel: this.$translate.instant('TR_110119-f76ab2_TR'),
                type: 'statsAccess'
            }, {
                label: this.$translate.instant('TR_110119-2a58bb_TR'),
                shortLabel: this.$translate.instant('TR_110119-c08a32_TR'),
                type: 'ftpLimited'
            }
        ];
    };

    public get disableSaveButton(): boolean {
        const hasUsersMarkedForDeletionButIsMissingConfirmation = this.userAccessList.some(
            (user) => user.props.isMarkedForRemoval // all users that have been marked for deletion
                && !user.props.isNewUser // ignore newly created users
        );

        return this.disableAddButton || hasUsersMarkedForDeletionButIsMissingConfirmation;
    }

    public get disableAddButton(): boolean {
        return this.userAccessList.some(
            (user: ViewTypes.WebspaceUserAccessObject) => user.props.isBeingEdited === true
        );
    }

    public save = (): Promise<void> => {
        return this._createNewWebhostingUser().then(
            () => this._updateWebspaceAccesses().then(
                () => this.navigation.reloadCurrentState()
            )
        );
    };

    private _createNewWebhostingUser = async (): Promise<void> => {
        const promises: PromiseLike<WebhostingApi.User>[] = [];
        let noNewAccesses = true;

        this.userAccessList.forEach((access) => {
            if (
                access.props.isNewUser
                && access.props.newAccessCreateType === ViewTypes.UserAccessType.new
            ) {
                noNewAccesses = false;
                promises.push(
                    this.webhostingUserModel.createUser(
                        {
                            name: access.name,
                            accountId: this.webspace.accountId,
                            comments: access.comments,
                            sshKey: access.sshKey
                        },
                        access.password
                    )
                );
            }
        });

        if (noNewAccesses) {
            return Promise.resolve();
        }

        return q.all(promises).then(
            (accesses: WebhostingApi.User[]) => {
                if (this.apiErrorModel.apiResponseHasError(accesses)) {
                    return Promise.reject();
                }

                // If we have created a new webspace user, we still need to store the userId
                // information from the create response in access
                accesses.map(
                    (user: WebhostingApi.User, index) => {
                        if (
                            this.userAccessList[index].props.isNewUser
                            && this.userAccessList[index].props.newAccessCreateType === ViewTypes.UserAccessType.new
                        ) {
                            this.userAccessList[index].userId = user.id;
                        }
                    }
                );

                this.alertManager.success(this.$translate.instant('TR_050219-1fa65e_TR'));
            }
        );
    };

    private _updateWebspaceAccesses = async (): Promise<boolean> => {
        const webspace: WebhostingApi.Webspace = JSON.parse(JSON.stringify(this.webspace));
        // delete accesses wich are marked for removal
        const acessesWithoutRemovals = this._deleteAccessesMarkedForRemoval();
        // update webspace access list
        webspace.accesses = this._updateWebspaceAccessList(acessesWithoutRemovals);

        return this.webspaceModel
            .update(webspace)
            .then(
                (res) => {
                    if (this.apiErrorModel.apiResponseHasError(res)) {
                        return Promise.reject();
                    }
                    this._resetIsNewFlagAfterSuccessfulWebspaceUpdate();
                    this.alertManager.success(this.$translate.instant('TR_100119-54df5d_TR'));
                }
            );
    };

    private _resetIsNewFlagAfterSuccessfulWebspaceUpdate = (): void => {
        for ( const access of this.userAccessList) {
            if (access.props.isNewUser) {
                access.props.isNewUser = false;
            }
        }
    };

    private _updateWebspaceAccessList = (
        accesses: ViewTypes.WebspaceUserAccessObject[]
    ): WebhostingApi.WebspaceAccess[] => {
        return accesses.map(
            (access: ViewTypes.WebspaceUserAccessObject) => {
                const ftpAccess = access.accessLevel.ftpAccess || access.accessLevel.ftpLimited;
                const homeDir = access.accessLevel.ftpLimited ? access.homeDir : '';
                const userAccess =  {
                    ftpAccess: ftpAccess,
                    homeDir: homeDir,
                    sshAccess: access.accessLevel.sshAccess,
                    statsAccess: access.accessLevel.statsAccess,
                    userId: access.userId,
                    userName: access.userName,
                    webspaceId: this.webspace.id
                };

                return userAccess;
            }
        );
    };

    private _deleteAccessesMarkedForRemoval = (): ViewTypes.WebspaceUserAccessObject[] => {
        return this.userAccessList.filter(
            (access) => !(access.props.isMarkedForRemoval)
        );
    };
}

export class OrganismEditFormWebspaceUsersComponent implements ng.IComponentOptions {
    public bindings = {
        webspace: '<',
        webspaceUsers: '<'
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormWebspaceUsersController;
    public template = require('./webspace-edit-users.html');
}
