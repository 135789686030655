import * as ng from 'angular';
import {
    ValidationErrorList
} from '@/atomic-components/molecules/lists/validation-error-list/validation-error-list';

import {
    AccountModelService,
    AlertManagerService,
    AuthContextService,
    NavigationService,
    RightModelService,
    UserModelService
} from '../../../../../services';
import { EditPanelStatus } from '../../../../molecules/forms/form-edit/form-edit';

export class OrganismEditFormRightsTemplateController implements ng.IController {
    public static $inject: string[] = [
        '$translate',
        'accountModel',
        'alertManager',
        'authContext',
        'navigation',
        'rightModel',
        'userModel'
    ];
    public rightsTemplate: any;
    public permissions: any;
    public createNew: boolean;
    public accounts: any;
    public users: any[];
    public panelRightSettings: any;

    private rightsTemplateBackup: any;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService,
        private alertManager: AlertManagerService,
        private authContext: AuthContextService,
        private navigation: NavigationService,
        private rightModel: RightModelService,
        private userModel: UserModelService
    ) {}

    public $onInit() {
        if (this.createNew) {
            this.panelRightSettings = {};
        } else {
            this.panelRightSettings = {
                editPanelButton: true
            };
        }
        this.rightsTemplateBackup = ng.copy(this.rightsTemplate, this.rightsTemplateBackup);
        this.accounts = [];
        this.accounts.push(this.authContext.account);
        this.accountModel.listSubaccounts().then(
            (reply) => {
                this.accounts = this.accounts.concat(reply.data);
            }
        );
        this.userModel.list(null, null, null).then(
            (reply) => {
                this.users = reply.data;
            }
        );
    }

    public get rightsPanelInitialStatus() {
        if (this.createNew) {
            return EditPanelStatus.ACTIVE;
        } else {
            return EditPanelStatus.READONLY;
        }
    }

    public saveSettings = () => {
        if (this.createNew) {
            this.rightModel.createRightsTemplate(this.rightsTemplate).then(
                () => {
                    this.alertManager.success(this.$translate.instant('TR_100119-110880_TR'));
                    this.navigation.go('reseller.rightstemplates.overview');
                },
                () => {
                    this.navigation.go('reseller.rightstemplates.overview');
                }
            );
        } else {
            this.rightModel.updateRightsTemplate(this.rightsTemplate).then(() => {
                this.alertManager.success(this.$translate.instant('ACCOUNT.MESSAGE.EDIT.RIGHTS-TEMPLATE.SUCCESS'));
            });
        }
    };
}

export class OrganismEditFormRightsTemplateComponent implements ng.IComponentOptions {
    public bindings = {
        createNew: '<',
        permissions: '<',
        rightsTemplate: '<'
    };
    public controller = OrganismEditFormRightsTemplateController;
    public template = require('./rights-template-edit.html');
}
