import * as ng from 'angular';

export class MoleculeButtonSaveController implements ng.IController {
    public static $inject: string[] = [];

    public disabled: boolean;
    public loading: boolean;
    public save: () => void;
    public modifier: string;

    public $onInit() {
        this.modifier = this.modifier || 'medium';
    }
}

export class MoleculeButtonSaveComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        disabled: '<',
        loading: '<',
        modifier: '@?',
        save: '<'
    };
    public controller =  MoleculeButtonSaveController;
    public template = require('./button-save.html');
}
