import * as ng from 'angular';
import { AuthContextService } from '../auth-context';
import { UserRobotService } from './robot';

export class SwitchUserManagerService {
    public static $inject: string[] = ['$window', 'userRobot'];

    constructor(
        private $window: ng.IWindowService,
        private userRobot: UserRobotService
    ) {}

    public switchTo = (accountId: string) => {
        void this.userRobot.loginAsSubAccount(accountId)
            .then(this.reload);
    };

    public leave = () => {
        void this.userRobot.logoutOfSubAccount()
            .then(this.reload);
    };

    public allowedToSwitchTo = (accountId: string) => {
        return AuthContextService.account.parentAccountId !== accountId
        && accountId !== AuthContextService.account.id;
    };

    private reload = () => {
        this.$window.location.assign('/');
    };
}
