import * as ng from 'angular';
import { ValidateMixedIpList } from '../../../../../services';

export class OrganEditPanelApiKeyInformationController {
    public static $inject: string[] = ['$translate'];

    public $newFormOrganism;
    public validationInstructions;
    public formOrganism;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    $onInit() {
        this.validationInstructions = [{
            validator: new ValidateMixedIpList(this.$translate, true),
            instructions: null
        }];
    }
}

export class OrganEditPanelApiKeyInformationComponent implements ng.IComponentOptions {
    public bindings = {
        formOrganism: '='
    };
    public controllerAs = '$editPanelOrgan';
    public controller = OrganEditPanelApiKeyInformationController;
    public template = require('./api-key-informations.html');
}
