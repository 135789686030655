import * as ng from 'angular';

import {
    PanelHeaderData
} from '@/atomic-components/molecules/panels/panel-header/panel-header';
import { UiLanguagesConst, UiRights } from '@/configuration';
import {
    AuthContextService,
    NextcloudHelperService,
    ProductHelperService,
    ProductsModelService
} from '@/services';
import * as Types from '@/types';

export class TemplateNextcloudOrderBackupController {
    public static $inject: string[] = ['$state', '$translate', 'nextcloudHelper', 'productsModel'];

    public pageHeaderData: PanelHeaderData;
    public nextcloud: Types.ManagedApplicationApi.Nextcloud;
    public isFreeProduct = false;
    public productUpgradeLink = '';

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private nextcloudHelper: NextcloudHelperService,
        private productModel: ProductsModelService
    ) {}

    public $onInit(): void {
        this.pageHeaderData = {
            backwardLink:
                this.$state.current.name.indexOf('storage-products') >= 0
                ? 'storage.storage-products.id.edit'
                : 'nextcloud.id.edit',
            backwardText: this.$translate.instant('TR_010819-62941c_TR'),
            panelHeaderRoute: '',
            panelIcon: '/assets/images/logos/nextcloud-bw.svg',
            panelIconPath: true,
            panelTitle: this.$translate.instant('TR_100919-c1eee0_TR', { name: this.nextcloud.name }),
            productActions: []
        };

        void this.nextcloudHelper.checkNextcloudJobRunning(this.nextcloud.id).then((jobRunning) => {
            // Rename
            if (AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT)) {
                this.pageHeaderData.productActions.push({
                    disabled: jobRunning,
                    route: 'nextcloud.id.rename',
                    title: this.$translate.instant('TR_171019-47b3be_TR')
                });
            }
            // Upgrade
            if (AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT)) {
                this.pageHeaderData.productActions.push({
                    disabled: jobRunning,
                    route: 'nextcloud.id.upgrade',
                    title: this.$translate.instant('TR_030919-a153f2_TR')
                });
            }
            // Cancel
            if (
                this.nextcloud.status !== 'restorable'
                && AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_DELETE)
            ) {
                this.pageHeaderData.productActions.push({
                    disabled: jobRunning,
                    route: 'nextcloud.id.cancel',
                    title: this.$translate.instant('TR_020819-188cc3_TR')
                });
            }
            // Restrictions
            if (AuthContextService.isGrantedAll([
                UiRights.ADMIN_SYSTEM_SUPER_USER_READ,
                UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT
            ])) {
                this.pageHeaderData.productActions.push({
                    route: 'nextcloud.id.restrictions',
                    stateParams: { storageProductId: this.nextcloud.id },
                    title: this.$translate.instant('TR_250519-7f279b_TR')
                });
            }
            // Neu
            if (AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_CREATE)) {
                this.pageHeaderData.productActions.push({
                    route: 'nextcloud.new',
                    title: this.$translate.instant('TR_110919-dcde08_TR')
                });
            }
        });

        this.productUpgradeLink = this.$state.current.name.indexOf('storage-products') >= 0
            ? 'storage.storage-products.id.upgrade'
            : 'nextcloud.id.upgrade';

        void this.productModel.findOne(
            this.nextcloud.productCode,
            UiLanguagesConst[AuthContextService.user.language],
            true
        )
            .then(
                (apiResponse) => {
                    this.isFreeProduct = ProductHelperService.wrapSpecificationItems(
                        apiResponse.response.specificationItems
                    ).isFreeProduct?.isTrue === true;
                }
            );
    }
}

export class TemplateNextcloudOrderBackupComponent implements ng.IComponentOptions {
    public bindings = {
        nextcloud: '<'
    };
    public controllerAs = '$TemplateNextcloudBackupCtrl';
    public controller = TemplateNextcloudOrderBackupController;
    public template = require('./nextcloud-order-backup-template.html');
}
