import ng from 'angular';

import { Contingent } from '@/atomic-components/organs/create/configuration/general/contingents';
import { UiLanguagesConst } from '@/configuration/system';
import {
    AuthContextService,
    ProductsModelService
} from '@/services';
import { ViewTypes, WebhostingApi, UI, ProductApi } from '@/types';

export class WebspaceComposerService {
    public static $inject: string[] = [
        '$translate',
        'productsModel'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private productsModel: ProductsModelService
    ) {}

    public buildWebspaceApiObject = async (
        metadata: ViewTypes.ProductConfigWebspaceObject
    ): Promise<ViewTypes.WizardComposerObject> => {
        const productFamilyInfo = await (this.productsModel
            .findProductFamilyDescription(
                'webhosting',
                metadata.productFamily,
                UiLanguagesConst[AuthContextService.user.language]
            ) as Promise<UI.APIResponse<ProductApi.ProductFamilyDescription>>)
            .then((res) => res.response);

        const productCodeInfo = await (this.productsModel
            .findOne(
                metadata.productCode,
                UiLanguagesConst[AuthContextService.user.language],
                true
            ) as Promise<UI.APIResponse<ProductApi.AbstractProduct>>)
            .then((res) => res.response);

        const composerObject: ViewTypes.WizardComposerObject = {
            productFamily: metadata.productFamily,
            apiObject: {
                user: this._createUserApiObject(metadata),
                vhost: this._createVhostApiObject(metadata),
                webspace: this._createWebspaceApiObject(metadata)
            },
            summaryObject: [
                {
                    account: metadata.account,
                    productCode: metadata.productCode,
                    factSheets: [
                        {
                            label: this.$translate.instant('TR_070519-16189c_TR'),
                            value: this.$translate.instant('TR_100119-e6d165_TR'),
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('TR_110219-fc0ae3_TR'),
                            value: productCodeInfo.name,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('TR_110119-683bf8_TR'),
                            value: metadata.webspaceName,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('TR_050719-f6b51d_TR'),
                            value: metadata.vhostSettings.domainExtern
                                ? this.$translate.instant('TR_110119-bf497a_TR')
                                : this.$translate.instant('TR_050221-16e256_TR'),
                            description: null,
                            type: 'string'
                        }
                    ],
                    product: {
                        name: metadata.productCode,
                        nameType: 'productCode',
                        shortDescription: productFamilyInfo.shortDescription
                    }
                }
            ]
        };

        if (metadata.vhostSettings.assignDomain) {
            composerObject.summaryObject[0].factSheets.push({
                label: this.$translate.instant('TR_140119-e93e68_TR'),
                value: metadata.vhostSettings.domainName,
                description: null,
                type: 'string'
            });
            if (metadata.vhostSettings.configTyp === 'website') {
                composerObject.summaryObject[0].factSheets.push({
                    label: this.$translate.instant('f1974e98-f95f-4565-befd-6c91661c0e93'),
                    value: metadata.vhostSettings.configPath,
                    description: null,
                    type: 'string'
                });
            }
        }

        if (metadata.additionalStorageItems?.length > 0) {
            composerObject.apiObject.webspace.storageQuota = metadata.storageQuota;
            const storageProductCodeInfo = await (this.productsModel
                .findOne(
                    metadata.additionalStorageItems[0],
                    UiLanguagesConst[AuthContextService.user.language],
                    true
                ) as Promise<UI.APIResponse<ProductApi.AbstractProduct>>)
                .then((res) => res.response);
            composerObject.summaryObject.push({
                account: metadata.account,
                productCode: metadata.additionalStorageItems[0],
                factSheets: [
                    {
                        description: '',
                        label: this.$translate.instant('dc44bf44-77e8-4da8-9e0c-36bf36a945f1'),
                        type: 'integer',
                        value: metadata.additionalStorageItems.length
                    },
                    {
                        description: '',
                        label: this.$translate.instant('TR_STORAGE_TR'),
                        type: 'gb',
                        value: 1
                    }
                ],
                priceMultiplication: metadata.additionalStorageItems.length,
                product: storageProductCodeInfo
            });
        }

        return Promise.resolve(this._addContingent(composerObject, metadata.contingent));
    };

    private _createUserApiObject(metadata: ViewTypes.ProductConfigWebspaceObject) {
        if (metadata.user.userType === 'existing') {
            return {
                user: {
                    userType: metadata.user.userType,
                    id: metadata.user.id
                }
            };
        }
        const newUser: any = {
            userType: metadata.user.userType,
            user: {
                name: metadata.user.name,
                accountId: metadata.account.id,
                comments: metadata.user.comments
            },
            password: metadata.user.password
        };
        if (metadata.user.accesses.includes('sshAccess')) {
            newUser.user.sshKey = metadata.user.publicKey;
        }
        if (metadata.user.accesses.includes('ftpLimited')) {
            newUser.user.comments = metadata.user.comments;
        }
        return newUser;
    }

    private _createWebspaceApiObject(metadata: ViewTypes.ProductConfigWebspaceObject) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const webspaceApiObject: any = {
            webspace: {
                accesses: [] as unknown[],
                accountId: metadata.account.id,
                name: metadata.webspaceName,
                productCode: metadata.productCode,
                storageQuota: metadata.storageQuota
            },
            accesses: [],
            poolId: metadata.poolId,
            webserverId: metadata.webserverId
        };
        const webspaceApiObejctAccess: any = {
            ftpAccess: metadata.user.accesses.includes('ftpAccess'),
            sshAccess: metadata.user.accesses.includes('sshAccess'),
            statsAccess: metadata.user.accesses.includes('statsAccess'),
            ftpLimited: metadata.user.accesses.includes('ftpLimited')
        };
        if (metadata.user.accesses.includes('ftpLimited')) {
            webspaceApiObejctAccess.homeDir = metadata.user.accessHomeDir;
        }
        if (metadata.user.userType === 'existing') {
            webspaceApiObejctAccess.userId = metadata.user.id;
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        webspaceApiObject.accesses.push(webspaceApiObejctAccess);
        return webspaceApiObject;
    }

    private _createVhostApiObject(metadata: ViewTypes.ProductConfigWebspaceObject) {
        const location = {
            blockDotfiles: true,
            directoryListingEnabled: metadata.vhostSettings.enableDirectoryListing,
            locationType: 'generic',
            mapScript: '',
            mapStyle: '',
            matchString: '',
            matchType: 'default',
            phpEnabled: true,
            redirectionStatus: '',
            redirectionUrl: '',
            restrictToHttpUsers: [] as any[],
            setByProfile: false,
            superUserConfiguration: ''
        };
        if (metadata.vhostSettings.configTyp === 'redirect') {
            location.locationType = metadata.vhostSettings.configTyp;
            location.redirectionStatus = String(metadata.vhostSettings.redirectStatus);
            location.redirectionUrl = metadata.vhostSettings.redirectTarget;
        }

        const vhostApiObject: any = {
            vhost: {
                accountId: metadata.account.id,
                enableAlias: metadata.vhostSettings.enableRedirect,
                enableSystemAlias: metadata.vhostSettings.enableSystemname,
                locations: [
                    location
                ],
                phpVersion: '8.1',
                profile: metadata.vhostSettings.domainProfile?.profileId,
                redirectToPrimaryName: metadata.vhostSettings.enablePrefix,
                serverType: metadata.vhostSettings.webserverType,
                sslSettings: null,
                systemAlias: metadata.vhostSettings.systemAlias,
                webRoot: metadata.vhostSettings.configPath,
                webspaceId: undefined,
                webspace: {
                    accesses: [],
                    accountId: metadata.account.id,
                    name: metadata.webspaceName,
                    productCode: metadata.productCode,
                    storageQuota: metadata.storageQuota
                }
            },
            phpIni: metadata.vhostSettings.phpIni,
            sslPrivateKey: '',
            httpUsers: undefined,
            assignDomain: metadata.vhostSettings.assignDomain
        };

        if (metadata.vhostSettings.assignDomain) {
            vhostApiObject.vhost.domainName = metadata.vhostSettings.domainName;
            vhostApiObject.vhost.domainNameUnicode = metadata.vhostSettings.domainName;
        }

        return vhostApiObject;
    }

    private _addContingent = (composerObject: ViewTypes.WizardComposerObject, contingent: Contingent) => {
        if (!contingent || contingent.type.toLocaleLowerCase() === 'standalone') {
            return composerObject;
        }
        switch (contingent.type.toLocaleLowerCase()) {
            case 'pool':
                composerObject.apiObject.poolId = contingent.id;
                composerObject.summaryObject[0].contingent = contingent;
                break;
            case 'webserver':
                composerObject.apiObject.webserverId = contingent.id;
                composerObject.summaryObject[0].contingent = contingent;
                break;
        }
        return composerObject;
    };
}
