import * as ng from 'angular';
import { SpamProfileService } from '../../../../../services';
import { EmailHelper } from '../../../../../services/email/email-helper';
import { FormDropDownItems } from '../../../../molecules';
import { OrganismEditFormMailboxController } from '../../../../organisms/forms/edit-forms/mailbox-edit/mailbox-edit';

export class OrganEditPanelMailboxSpamController {
    public static $inject: string[] = ['$translate', 'spamProfileService'];

    public cancel: any;
    public panelRight: any;
    public $editFormOrganism: OrganismEditFormMailboxController;
    public spamProtectionLevels: FormDropDownItems[] = [];

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private spamProfileService: SpamProfileService
    ) {
        this.spamProtectionLevels = [
            {
                name: this.$translate.instant('EMAIL.SPAM.LEVEL.LOW'),
                value: 'low'
            },
            {
                name: this.$translate.instant('EMAIL.SPAM.LEVEL.MEDIUM'),
                value: 'medium'
            },
            {
                name: this.$translate.instant('EMAIL.SPAM.LEVEL.HIGH'),
                value: 'high'
            }
        ];
    }

    public get spamProtectionProfileName() {
        const profile = EmailHelper.getSelectedSpamProfile(
            this.spamProfileService.getList(),
            this.$editFormOrganism?.mailbox?.spamFilter
        );

        if (profile === undefined) {
            return undefined;
        }

        return profile.label;
    }
}

export class OrganEditPanelMailboxSpamComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormMailbox'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./mailbox-spam.html');
    public controller = OrganEditPanelMailboxSpamController;
}
