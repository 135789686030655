import { AuthContextService } from '../../../../../services';
import { MoleculeFormEditController } from '../../../../molecules';

import * as ng from 'angular';
import * as Types from '../../../../../types';

export class OrganNewPanelDomainContactController implements ng.IController {

    // tslint:disable-next-line: no-empty
    public set disableSaveButton({}) {}
    public get disableSaveButton() {
        return this.$formEdit.$invalid
        || [undefined, null, ''].indexOf(this.contact.type) >= 0
        || [undefined, null, ''].indexOf(this.contact.country) >= 0;
    }
    public static $inject: string[] = ['authContext'];
    public contact: Types.DomainApi.Contact;

    public owner: string;

    private $formEdit: MoleculeFormEditController;

    constructor(
        private authContext: AuthContextService
    ) {
        this.owner = this.authContext.account.id;
    }

    public confirm = () => {
        if (!this.$formEdit.validateAll()) {
            return;
        }

        this.$formEdit.save();
    };
}

export class OrganNewPanelDomainContactComponent implements ng.IComponentOptions {
    public bindings = {
        contact: '=',
        disableSaveButton: '='
    };
    public require = {
        $formEdit: '^moleculeFormEdit'
    };
    public controller = OrganNewPanelDomainContactController;
    public template = require('./create-domain-contact.html');
}
