import * as ng from 'angular';
import './panel-callout.scss';

export class MoleculePanelCalloutController implements ng.IController {
    public calloutDisplayBorder: boolean;

    private calloutType: 'information' | 'success' | 'warning' | 'error'; // Default: 'information'
    private calloutDisplayTitle: boolean;
    private calloutTitle: string;

    public $onInit() {
        this.calloutType = this.calloutType || 'information';
        this.calloutDisplayTitle = (typeof(this.calloutDisplayTitle) === 'boolean') ? this.calloutDisplayTitle : true;
        this.calloutTitle = this.calloutTitle || '';
        this.calloutDisplayBorder = this.calloutDisplayBorder === undefined ? true : false;
    }
}

export class MoleculePanelCalloutComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        calloutDisplayBorder: '<displayBorder',
        calloutDisplayTitle: '<displayTitle',
        calloutTitle: '@',
        calloutType: '@type'
    };
    public controller = MoleculePanelCalloutController;
    public controllerAs = '$PanelCallout';
    public template = require('./panel-callout.html');
}
