import * as ng from 'angular';
import { FormInputModelOptions } from '../input-text/input-text';

export class AtomFormInputNumberController implements ng.IController {
    public static $inject: string[] = [];

    public model: number;
    public callbackOnChange: (arg0: any) => any;
    public callbackOnChangeParams: any;
    public callbackOnBlur: (arg0: any) => any;
    public callbackOnBlurParams: any;
    public callbackOnFocus: (arg0: any) => any;
    public callbackOnFocusParams: any;
    public readonly: boolean;
    public disabled: boolean;
    public min: number;
    public max: number;
    public modelOptions: FormInputModelOptions;
    public placeholder: string;
    public step: number;
    public callbackOnEnter: () => {};

    private doNotSaveOnFormEnter: boolean;

    public $onInit(): void {
        this.readonly = this.readonly || false;
        this.disabled = this.disabled || false;
        this.modelOptions = this.modelOptions || {};
        this.doNotSaveOnFormEnter = this.doNotSaveOnFormEnter || false;
    }

    public onChange(): void {
        if (this.callbackOnChange !== undefined) {
            if (this.callbackOnChangeParams) {
                this.callbackOnChange({ value: this.model, params: this.callbackOnChangeParams });
            } else {
                this.callbackOnChange({ value: this.model });
            }
        }
    }

    public onBlur(): void {
        if (this.callbackOnBlur !== undefined) {
            this.callbackOnBlur({ value: this.model, params: this.callbackOnBlurParams });
        }
    }

    public onFocus(): void {
        if (this.callbackOnFocus !== undefined) {
            this.callbackOnFocus(this.callbackOnFocusParams);
        }
    }

    public onKeyUp = (event: KeyboardEvent) => {
        if (this.callbackOnEnter !== undefined && event.keyCode === 13 && !this.doNotSaveOnFormEnter) {
            this.callbackOnEnter();
        }
    };
}

export class AtomFormInputNumberComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnBlur: '<',
        callbackOnBlurParams: '<?',
        callbackOnChange: '<',
        callbackOnChangeParams: '<?',
        callbackOnEnter: '<',
        callbackOnFocus: '<',
        callbackOnFocusParams: '<',
        disabled: '<',
        doNotSaveOnFormEnter: '<',
        max: '<?',
        min: '<?',
        model: '=',
        modelOptions: '<',
        placeholder: '@?',
        readonly: '<',
        step: '<?'
    };
    public controller =  AtomFormInputNumberController;
    public template = require('./input-number.html');
}
