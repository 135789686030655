import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import { AuthContextService } from '../../../../../services';
import { ProductConfigManagedServerProjectObject } from '../../../../organisms';

export class OrganManagedMachineProjectConfigController implements ng.IController {
    public static $inject: string[] = ['authContext'];

    public domainValidationErrors = [];
    public userHasRightDbDatabaseCreate = AuthContextService.isGranted(UiRights.DB_DATABASE_CREATE);
    public userHasRightWebWebspaceCreate = AuthContextService.isGranted(UiRights.WEB_WEBSPACE_CREATE);
    public projectData: ProductConfigManagedServerProjectObject;
    public databaseValidationOptions = {
        minimum: 1
    };

    public set complete({}) {} // tslint:disable-line:no-empty
    public get complete() {
        if (this.projectData === undefined) {
            return false;
        }

        if (!this.projectData.createWebspace && !this.projectData.createDatabase) {
            return false;
        }

        if (this.projectData.createWebspace) {
            if (this.projectData.domainName?.length === 0 || this.domainValidationErrors.length > 0) {
                return false;
            }

            if ([undefined, null, ''].indexOf(this.projectData.webspacePassword) >= 0) {
                return false;
            }
        }

        if (
            this.projectData.createDatabase
            && [undefined, null, ''].indexOf(this.projectData.databasePassword) >= 0
        ) {
            return false;
        }

        return this.projectData.projectName.length > 0;
    }
}

export class OrganManagedMachineProjectConfigComponent implements ng.IComponentOptions {
    public bindings = {
        complete: '=',
        projectData: '='
    };

    public template = require('./project-config.html');
    public controller =  OrganManagedMachineProjectConfigController;
}
