import * as ng from 'angular';
import * as q from 'q';
import { UiRights } from '../../configuration';
import { AuthContextService } from '../auth-context';
import { BillingRobotService } from './robot';

export class PromoManagerService {
    public static $inject: string[] = ['$rootScope', 'billingRobot'];
    public getPromotions = this.load;
    public reloadPromotions = this.reload;

    private cachedPromotions = null;

    constructor(
        private $rootScope: ng.IRootScopeService,
        private billingRobot: BillingRobotService
    ) {
        this.$rootScope.$on('login', this.load);
        this.$rootScope.$on('logout', () => {
            this.cachedPromotions = null;
        });
    }

    private doLoad() {
        if (
            !AuthContextService.isGranted(UiRights.BIL_LIST_ARTICLE_PRICES)
            || AuthContextService.isRootOrCompanyAccount
        ) {
            return q.when([]);
        }

        return this.billingRobot.articlePromotionalPricesFind(new Date().toISOString(), 10000, 1, null)
            .then((result) => {
                return result.response.data;
            });
    }

    private load() {
        if (!this.cachedPromotions) {
            this.cachedPromotions = this.doLoad();
        }

        return this.cachedPromotions;
    }

    private reload() {
        this.cachedPromotions = null;
        this.load();
    }
}
