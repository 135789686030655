import * as ng from 'angular';

import { EditPanel } from '@/atomic-components';
import {
    ApiErrorModel
} from '@/services';

export class OrganismEditFormNetworkController implements ng.IController {
    public static $inject: string[] = [
        'apiErrorModel'
    ];

    public networkStatusSupport = false;
    public editPanels: { [name: string]: EditPanel };
    public deletionConfirmed = false;
    public network;

    constructor(
        private apiErrorModel: ApiErrorModel
    ) {}

    public get errorList() {
        return this.apiErrorModel.errorObjectList;
    }

    public cancelDeletion = () => {
        this.deletionConfirmed = false;
    };

    public delete = () => {
         // delete not implemented
    };
}

export class OrganismEditFormNetworkComponent implements ng.IComponentOptions {
    public bindings = {
        network: '<'
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormNetworkController;
    public template = require('./network-edit.html');
}
