export const HandleTypes = ['Owner', 'Admin', 'Tech', 'Zone'];
export const HandleDefaultLabels = [
    { contactType: 'owner', label: /* translationId */ '79134e90-56a4-4816-bf30-f97c09642428' },
    { contactType: 'admin', label: /* translationId */ 'd18a253e-7318-4484-a57d-8c15dc660f56' },
    { contactType: 'tech', label: /* translationId */'3167f6f0-c06f-404d-a46a-0844f88bf408' },
    { contactType: 'zone', label: /* translationId */ '49cff6be-32f9-4167-b2f2-1fad856c2fb5' }
];
export const HandleMissingContactHintTexts = [
    { contactType: 'owner', label: /* translationId */ 'TR_170822-71c6db_TR' },
    { contactType: 'admin', label: /* translationId */ 'TR_170822-fcb7a1_TR' },
    { contactType: 'tech', label: /* translationId */ 'TR_170822-280c8c_TR' },
    { contactType: 'zone', label: /* translationId */ 'TR_170822-f78b88_TR' }
];
export const HandleConst = {
    HandleTypes: {
        labels: {
            person: /* translationId */ 'DOMAIN.HANDLE.TYPE.OPTIONS.PERSON',
            org: /* translationId */ 'TR_090119-9044b3_TR',
            role: /* translationId */ 'DOMAIN.HANDLE.TYPE.OPTIONS.ROLE'
        },
        typeItems: {
            person: /* translationId */ 'TR_100119-74543b_TR',
            org: /* translationId */ 'TR_100119-77ee69_TR'
        }
    }
};
