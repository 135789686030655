import * as ng from 'angular';
import { ValidateIp, ValidateIpv4, ValidateIpv6 } from '../../../../../services';
import { GeneralValidator } from '../../../../../services';
import { MoleculeFormEditController } from '../../../forms/form-edit/form-edit';

export class MoleculeInputIpTextareaController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public $editForm: MoleculeFormEditController;
    public disabled: boolean;
    public disableRandomisedId: boolean;
    public readonly: boolean;
    public validationErrorList = [];
    public textareaContent: string;
    public model: string[];
    public callbackOnBlur: () => any;
    public ipType: string;
    public invalidIps: any[];
    public registerToForm: boolean;
    public inputId: string;

    private registrationIndex: number;
    private validator;
    private validationInstructions;

    constructor(private $translate: ng.translate.ITranslateService) {
        this.validator = new GeneralValidator(this.$translate);
    }

    public $onInit() {
        if (this.registerToForm) {
            this.registrationIndex = this.$editForm.registerValidator(this);
        }
        if (this.model !== undefined) {
            this.textareaContent = '';
            for (const item of this.model) {
                this.textareaContent = this.textareaContent + item + '\n';
            }
        }
        switch (this.ipType) {
            case 'ip': {
                this.validationInstructions = [{
                    settings: null,
                    validator: new ValidateIp(this.$translate, false)
                }];
                break;
            }
            case 'ipv4': {
                this.validationInstructions = [{
                    settings: null,
                    validator: new ValidateIpv4(this.$translate, false)
                }];
                break;
            }
            case 'ipv6': {
                this.validationInstructions = [{
                    settings: null,
                    validator: new ValidateIpv6(this.$translate, false)
                }];
                break;
            }
            default: {
                this.validationInstructions = [{
                    settings: null,
                    validator: new ValidateIp(this.$translate, false)
                }];
                break;
            }

        }
    }

    public $onDestroy() {
        if (this.registerToForm) {
            this.$editForm.unregisterValidator(this.registrationIndex);
            this.$editForm.validatorStatus[this.registrationIndex] = true;
        }
    }

    public $onChanges(changes) {
        if (changes.disabled != null && changes.disabled !== undefined) {
            if (changes.disabled.currentValue === true) {
                if (this.registerToForm) {
                    this.$editForm.validatorStatus[this.registrationIndex] = true;
                    this.validationErrorList = [];
                }
            }
        }
    }

    public validate = () => {
        return this.processContent();
    };

    public processContent = () => {
        this.invalidIps = [];
        if (this.textareaContent === undefined || this.textareaContent.length === 0) {
            this.invalidIps = [{
                text: this.$translate.instant('TR_090119-6c37e1_TR')
            }];
            return false;
        }
        this.model = this.textareaContent.split(/\r?\n/);
        this.model = this.model.filter((line) => {
            return line.length > 0;
        });
        this.model.map(
            (ip) => {
                if (ip.length > 0) {
                    const val = this.validator.validate(ip, this.validationInstructions);
                    if (val.length > 0) {
                        this.invalidIps.push({text: this.$translate.instant('TR_090119-f918a7_TR') + ' ' + ip});
                    }
                }
            }
        );
        if (this.registerToForm) {
            this.$editForm.validatorStatus[this.registrationIndex] = this.invalidIps.length === 0;
        }

        if (this.invalidIps.length === 0) {
            if (this.callbackOnBlur !== undefined) {
                this.callbackOnBlur();
            }
            return true;
        }

        return false;
    };
}

export class MoleculeInputIpTextareaComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnBlur: '<',
        disabled: '<',
        disableRandomisedId: '<?',
        inputId: '@?',
        invalidIps: '=?',
        ipType: '@',
        model: '=',
        readonly: '<',
        registerToForm: '<'
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public controller = MoleculeInputIpTextareaController;
    public template = require('./ip-textarea.html');
}
