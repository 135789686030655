/* eslint-disable */
/*//
    ██     ██      █████      ██████      ███    ██     ██     ███    ██      ██████      ██     ██     ██
    ██     ██     ██   ██     ██   ██     ████   ██     ██     ████   ██     ██           ██     ██     ██
    ██  █  ██     ███████     ██████      ██ ██  ██     ██     ██ ██  ██     ██   ███     ██     ██     ██
    ██ ███ ██     ██   ██     ██   ██     ██  ██ ██     ██     ██  ██ ██     ██    ██
     ███ ███      ██   ██     ██   ██     ██   ████     ██     ██   ████      ██████      ██     ██     ██

    This file includes some class inheritance magic. Please refrain from changing anything unless you know
    the consequences of what you are doing.

    In most cases, what you actually need when you're looking at this file is information on why specific
    implementations of an overview controller might not work. Please consider the implementation example,
    found at the end of this file. It demonstrates everything you should need for most overviews.
//*/

import * as q from 'q';
import { OverviewModelUtilityChart } from '../../atomic-components/molecules/charts/utility-status/utility-status';
import { LinkListOverviewItem } from '../../atomic-components/molecules/links/link-list-overview/link-list-overview';
import { FilterField } from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { OverviewDataObject } from '../../atomic-components/organisms/overview/overview';
import { FilterUtils } from '../filter-utils';
import { RequestCanceller, RequestCancellerService } from '../request-canceller';

export interface ListCallbacks {
    callback: (limit?: number, page?: number, filter?: any, sort?: any, ...args: any[]) => any;
}

export abstract class OverviewModel {
    public static $inject: string[] = ['RequestCanceller'];

    /**
     * Definition of fields for the fancy filter.
     * This attribute has to be set by child classes.
     */
    public availableFilters: FilterField<any>[];

    public readonly abstract service: string;
    public readonly abstract objectType: string;
    public readonly abstract idField: string;
    public readonly abstract defaultFilterFields: string[];

    /**
     *  Flag of activeMassAction Dropdown
     */
    public activeMassAction: string | boolean = false;

    /**
     *  UtilityObject - when service object is in bundle - show utilityStatusChart
     */
    public bundleUtilityChart: () => OverviewModelUtilityChart;

    /**
     * Customized overviewOnLoad methode - Optional
     */
    public cutomizedModelListMethods: { [key: string]: (arg0: any) => q.IPromise<any> } | boolean = false;

    /**
     * Overview Service data for list methode and pagination
     */
    public overviewData: OverviewDataObject | null = null;

    /**
     *  List of selected object items
     */
    public selected = {};

    /**
     *  List of action links dependencies, if action link is clickable or not (ex. object isDeletable e.t.c)
     */
    public actionLinksAvailabilities = {};

    /**
     * Function to be called to get overview action links
     */
    public abstract actionLinks: (params: { [param: string]: any }) => LinkListOverviewItem[];

    /**
     * Protected filter attributes
     */
    protected prefixFilters: { field: string, prefix: string }[] = [];
    protected regexFilters: { field: string, regex: RegExp }[] = [];

    /**
     * Functions to be called to retrieve whatever objects the specific overview lists.
     */
    protected abstract listCallbacks: ListCallbacks[] = [];

    private _simpleFilterSpecialCases: any[];

    private canceller: RequestCanceller[] = [];

    constructor(
        private RequestCanceller: RequestCancellerService // tslint:disable-line:no-shadowed-variable
    ) {}

    private get simpleFilterSpecialCases() {
        if (!this._simpleFilterSpecialCases) {
            // Build the special cases for the simple filter once and save them.
            this._simpleFilterSpecialCases = [
                FilterUtils.buildSpecialCases.id(this.idField),
                FilterUtils.buildSpecialCases.customField()
            ];

            if ([undefined, null].indexOf(this.prefixFilters) < 0 && this.prefixFilters.length > 0) {
                this.prefixFilters.forEach(
                    (definition) => this._simpleFilterSpecialCases.push(
                        FilterUtils.buildSpecialCases.prefix(definition.field, definition.prefix)
                    )
                );
            }

            if ([undefined, null].indexOf(this.regexFilters) < 0 && this.regexFilters.length > 0) {
                this.regexFilters.forEach(
                    (definition) => this._simpleFilterSpecialCases.push(
                        FilterUtils.buildSpecialCases.regex(definition.field, definition.regex)
                    )
                );
            }
        }

        return this._simpleFilterSpecialCases;
    }

    protected get normalizeFilters(): (simpleFilterValue: string, additionalFilters?: any[]) => any {
        return (simpleFilterValue, additionalFilters?) => {
            return FilterUtils.buildFullFilter(
                this.availableFilters,
                simpleFilterValue,
                this.defaultFilterFields,
                this.simpleFilterSpecialCases,
                additionalFilters
            );
        };
    }

    /**
     * Option to manipulate disableFilter in service overview modelHelper
     **/
    public getDisableFilter = () => null;

    public list: (
        paginationLimit: number,
        currentPage: number,
        simpleFilterValue: string,
        additionalFilters?: any[],
        objectId?: string,
        index?: number,
        sort?: {},
        callbackIndex?: number
    ) => q.IPromise<any>
    = (
        paginationLimit,
        currentPage,
        simpleFilterValue,
        additionalFilters?,
        objectId?,
        index?,
        sort?,
        callbackIndex?
    ) => {
        index = index || 0;
        sort = sort || null;
        callbackIndex = callbackIndex || 0;
        if (this.canceller.length === 0 || !this.canceller[index]) {
            /*
             * Initialize request canceller
             *
             * The list callback is set by the subclass, which means it is set after the
             * constructor for the abstract class is done. Therefore, we initialize the
             * canceller the first time a request is sent, which is definitely after all
             * constructors are done.
             */
            this.canceller[index] = this.RequestCanceller.wrap(this.listCallbacks[callbackIndex].callback, this);
        }
        return this.canceller[index].request(
            paginationLimit,
            currentPage,
            this.normalizeFilters(simpleFilterValue, additionalFilters),
            sort,
            objectId
        );
    };
}

/**
 * Job overviews have some things in common, so this extended abstract class sets those defaults.
 * They can still be overwritten, of course, but most of the time, it won't be necessary.
 */
export abstract class JobOverviewModel extends OverviewModel {
    public readonly objectType = 'Job';
    public readonly idField = 'jobId';

    public availableFilters = [];

    public readonly defaultFilterFields = ['JobDisplayName'];

    public actionLinks = (params: { [param: string]: any }) => [];

}

/*//
    ██ ███    ███ ██████  ██      ███████ ███    ███ ███████ ███    ██ ████████  █████  ████████ ██  ██████  ███    ██
    ██ ████  ████ ██   ██ ██      ██      ████  ████ ██      ████   ██    ██    ██   ██    ██    ██ ██    ██ ████   ██
    ██ ██ ████ ██ ██████  ██      █████   ██ ████ ██ █████   ██ ██  ██    ██    ███████    ██    ██ ██    ██ ██ ██  ██
    ██ ██  ██  ██ ██      ██      ██      ██  ██  ██ ██      ██  ██ ██    ██    ██   ██    ██    ██ ██    ██ ██  ██ ██
    ██ ██      ██ ██      ███████ ███████ ██      ██ ███████ ██   ████    ██    ██   ██    ██    ██  ██████  ██   ████

    ███████ ██   ██  █████  ███    ███ ██████  ██      ███████
    ██       ██ ██  ██   ██ ████  ████ ██   ██ ██      ██
    █████     ███   ███████ ██ ████ ██ ██████  ██      █████
    ██       ██ ██  ██   ██ ██  ██  ██ ██      ██      ██
    ███████ ██   ██ ██   ██ ██      ██ ██      ███████ ███████

export class MailboxOverviewModel extends OverviewModel {
    // Add all injections for the parent classes.
    public static $inject: string[] = OverviewModel.$inject.concat(
        // Put any injections for the specific overview model in this array.
        ['mailboxModel']
    );

    // Configuration for fancy filter
    protected availableFilters = [];

    readonly service = 'email';
    readonly objectType = 'Mailbox';
    readonly idField = 'mailboxId';
    readonly defaultFilterFields = ['MailboxEmailAddressUnicode'];

    constructor(
        // Parent injections. They're first in the injection array, so they have to be first in the constructor
        // arguments, too. If you need to use them in the subclass, feel free to add private/protected/public.
        RequestCanceller: RequestCancellerService,

        // Injections for this class.
        private mailboxModel: any
    ) {
        // Call parent constructor.
        super(filterUtils, RequestCanceller);
    }

    protected listCallback = this.mailboxModel.list;

    public actionLinks = (params: { [param: string]: any }) => {
        return [
            {
                text: 'Postfach anlegen',
                route: params.bundle ? 'bundle.id.mailboxes.new' : 'email.mailboxes.new'
            }
        ];
    }
}
//*/
/* eslint-enable */
