import { AlertManagerService, DomainModelService } from '../../../../../../services';
import { OrganismOverviewController } from '../../../../../organisms/overview/overview';

import * as ng from 'angular';
import * as q from 'q';

export class MoleculePanelOverviewHeaderMultiDomainAuthcodesActionController implements ng.IController {
    public static $inject: string[] = ['alertManager', 'domainModel', '$translate'];

    public OrganismOverview: OrganismOverviewController;
    public confirmed = false;
    public loading = false;

    public results: any[];

    constructor(
        private alertManager: AlertManagerService,
        private domainModel: DomainModelService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public abort = () => {
        this.OrganismOverview.overviewModel.activeMassAction = false;

        if ([undefined, null].indexOf(this.results) < 0) {
            this.results = undefined;
            this.OrganismOverview.onLoad();
        }
    };

    public getAuthInfo = (result) => {
        return [undefined, null, ''].indexOf(result.response.authInfo) < 0
            ? result.response.authInfo
            : this.$translate.instant('TR_150420-9a29ff_TR');
    };

    public getAllAuthInfo = () => {
        return this.results ? this.results.reduce(this._convertResultsToCSV) : this.$translate.instant('TR_150420-9a29ff_TR');
    };

    public confirm = () => {
        this.loading = true;

        q.all(
            this.OrganismOverview.selectedItems.map(
                (domain) => {
                    domain.transferLockEnabled = false;
                    return this.domainModel.update(domain);
                }
            )
        ).then(
            (results) => {
                this.alertManager.success(this.$translate.instant('TR_100119-65e907_TR'));
                this.results = results;
                this.loading = false;
            }
        );
    };

    private _convertResultsToCSV = (prev, el, i) => {
        if (typeof prev === 'object') {
            prev = `${prev.response.name} ${prev.response.authInfo}\n`;
        }

        let text = prev + `${el.response.name} ${el.response.authInfo}\n`;

        if (i === 1) {
            text = 'domain code\n' + text;
        }

        return text;
    };
}

export class MoleculePanelOverviewHeaderMultiDomainAuthcodesActionComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public controller =  MoleculePanelOverviewHeaderMultiDomainAuthcodesActionController;
    public template = require('./multi-domain-authcodes-action.html');
}
