import * as ng from 'angular';

import { MoleculeFormEditController, EditPanelRight, EditPanelStatus } from '@/atomic-components/molecules';
import * as Types from '@/types';

import './permissions.scss';

export class OrganEditPanelPermissionsController implements ng.IController {
    public static $inject: string[] = ['$timeout', '$translate'];

    public $editForm: MoleculeFormEditController;

    public initialStatus: EditPanelStatus;
    public originalSelectedPermissions: string[];
    public panelRight: EditPanelRight;
    public selectedPermissions: string[];
    public selectedTemplateId: string;
    public templatePermissions: string[] = [];
    public templateName: string = this.$translate.instant('725e6c37-40c5-4745-9a3a-9bda64c6cddc');
    public templateDropdownItems: { name: string; value: string }[];

    private _permissions: Types.AccountApi.RightGroup[];
    private _templates: Types.AccountApi.RightsTemplate[] = [];
    private originalSelectedTemplateId: string = null;

    private _display = true; // Used to redraw panel when values change from outside
    private _noTemplate: { name: string; value: string } = {
        name: this.$translate.instant('725e6c37-40c5-4745-9a3a-9bda64c6cddc'),
        value: null
    };

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService
    ) {
        this.templateDropdownItems = [this._noTemplate];
    }

    public get displayPanel(): boolean {
        return this._display
            && this._permissions?.length > 0;
    }

    public get permissions(): Types.AccountApi.RightGroup[] {
        return this._permissions;
    }

    public set permissions(permissions: Types.AccountApi.RightGroup[]) {
        this._display = false;
        this._permissions = permissions;

        void this.$timeout(
            () => this._display = true
        );
    }

    public get templates(): Types.AccountApi.RightsTemplate[] {
        return this._templates;
    }

    public set templates(templates: Types.AccountApi.RightsTemplate[]) {
        this._display = false;
        this.templateDropdownItems = [this._noTemplate];

        if (!templates) {
            this._templates = [];
        } else {
            this._templates = templates;
            this.templateDropdownItems = this.templateDropdownItems.concat(
                templates.map(
                    (template) => ({
                        name: template.name,
                        value: template.id
                    })
                )
            );
        }

        const templateNameCandidates = this.templateDropdownItems.filter(
            (item) => item.value === this.selectedTemplateId
        );

        if (templateNameCandidates.length === 1) {
            this.templateName = templateNameCandidates.pop().name;
        }

        this.resetTemplatePermissions();

        void this.$timeout(
            () => this._display = true
        );
    }

    public get externalSelectedPermissions(): string[] {
        return this.selectedPermissions;
    }

    public set externalSelectedPermissions(selectedPermissions: string[]) {
        this.originalSelectedPermissions = ng.copy(selectedPermissions);
        this.selectedPermissions = selectedPermissions;
    }

    public get externalSelectedTemplateId(): string {
        return this.selectedTemplateId;
    }

    public set externalSelectedTemplateId(selectedTemplateId: string) {
        this.originalSelectedTemplateId = ng.copy(selectedTemplateId || null);
        this.selectedTemplateId = selectedTemplateId || null;

        const templateNameCandidates = this.templateDropdownItems.filter(
            (item) => item.value === this.selectedTemplateId
        );

        if (templateNameCandidates.length === 1) {
            this.templateName = templateNameCandidates.pop().name;
        }

        this.resetTemplatePermissions();
    }

    public get internalSelectedTemplateId(): string {
        return this.selectedTemplateId;
    }

    public set internalSelectedTemplateId(selectedTemplateId: string) {
        this.selectedTemplateId = selectedTemplateId || null;

        const templateNameCandidates = this.templateDropdownItems.filter(
            (item) => item.value === this.selectedTemplateId
        );

        if (templateNameCandidates.length === 1) {
            this.templateName = templateNameCandidates.pop().name;
        }

        this.resetTemplatePermissions();

        if (!this.selectedTemplateId) {
            return;
        }

        for (const permission of this.templatePermissions) {
            if (this.selectedPermissions.indexOf(permission) < 0) {
                this.selectedPermissions.push(permission);
            }
        }

        if (!this.originalSelectedTemplateId) {
            return;
        }

        const originalTemplatePermissions = this.templates
            .filter((template) => template.id === this.originalSelectedTemplateId)
            .pop()?.rights;

        if (!originalTemplatePermissions) {
            return;
        }

        for (const permission of originalTemplatePermissions) {
            if (this.templatePermissions.indexOf(permission) < 0) {
                this.selectedPermissions = this.selectedPermissions
                    .filter((selectedPermission) => selectedPermission !== permission);
            }
        }
    }

    public cancel = (): void => {
        this.selectedTemplateId = ng.copy(this.originalSelectedTemplateId);
        this.selectedPermissions = ng.copy(this.originalSelectedPermissions);
    };

    private resetTemplatePermissions = (): void => {
        if (!this.selectedTemplateId) {
            this.templatePermissions = [];
        } else {
            this.templatePermissions = this.templates
                .filter((template) => template.id === this.selectedTemplateId)
                .pop()?.rights || [];
        }
    };
}

export class OrganEditPanelPermissionsComponent implements ng.IComponentOptions {
    public bindings = {
        externalSelectedPermissions: '=?selectedPermissions',
        externalSelectedTemplateId: '=?selectedTemplateId',
        initialStatus: '<',
        panelRight: '<',
        permissions: '<',
        templates: '<'
    };

    public require = {
        $editForm: '?^moleculeFormEdit'
    };

    public template = require('./permissions.html');
    public controller = OrganEditPanelPermissionsController;
}
