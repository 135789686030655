import { FunctionRelayService } from '../function-relay';
import { ModelHelper } from '../model-helper';

import * as ng from 'angular';
import { NetworkRobotService } from './robot';

export class NetworkModelService {
    public static $inject: string[] = [
        'networkRobot'
    ];

    constructor(
        private networkRobot: NetworkRobotService
    ) {}

    public list = (limit?, page?, filters?, sort?) => {
        return this.networkRobot.list(filters, limit, page, sort)
        .then(ModelHelper.returnListResponse);
    };

    public listWithoutPagination = (limit?, page?, filters?, sort?) => {
        return this.networkRobot.listWithoutPagination(filters, limit, page, sort)
        .then(ModelHelper.returnListResponse);
    };
}
