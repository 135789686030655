import * as ng from 'angular';
import * as Types from '../../../types';

import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateDomainEditBulkController {
    public static $inject: string[] = ['$translate'];

    public domains: Types.DomainApi.Domain[];
    public selectedDomains: Types.DomainApi.Domain[];
    public subAccounts: Types.AccountApi.Subaccount[];
    public contacts: Types.DomainApi.Contact[];
    public nameservers: Types.DnsApi.NameserverSet[];
    public defaultNameservers: Types.DnsApi.NameserverSet;
    public pageHeaderData: PanelHeaderData = {
        backwardLink: 'domain.domains.overview',
        backwardText: this.$translate.instant('TR_110119-7424eb_TR'),
        panelHeaderRoute: '',
        panelHeaderRouteParams: '',
        panelIcon: 'globe',
        panelTitle: this.$translate.instant('TR_110119-2056f9_TR')
    };

    constructor(private $translate: ng.translate.ITranslateService) {}
}

export class TemplateDomainEditBulkComponent implements ng.IComponentOptions {
    public bindings = {
        contacts: '<',
        defaultNameservers: '<',
        domains: '<',
        nameservers: '<',
        selectedDomains: '<',
        subAccounts: '<'
    };
    public template = require('./domain-edit-bulk-template.html');
    public controller = TemplateDomainEditBulkController;
}
