import * as ng from 'angular';

export class StorageQuota {
    public static $inject: string[] = [];

    public static Factory() {
        return (value) => {
            let unit = 'MB';

            if (value > 999) {
                unit = 'GB';
                value = String(Math.round(value / 1024));
            }

            return value + ' ' + unit;
        };
    }
}
