import { Validator } from './abstract-validator';

export class ValidateMinLength extends Validator {
    private minLength: number;

    constructor(
        private $translate: ng.translate.ITranslateService,
        minLength: number
    ) {
        super();
        this.minLength = minLength;
    }

    public validate = (value: string) => {
        if (this.minLength > value.length) {
            return [
                {
                    text: this.$translate.instant('TR_170119-9a9bbf_TR', { minLength: this.minLength })
                }
            ];
        }

        return [];
    };
}

export class ValidateMaxLength extends Validator {
    private maxLength: number;

    constructor(
        private $translate: ng.translate.ITranslateService,
        maxLength: number
    ) {
        super();
        this.maxLength = maxLength;
    }

    public validate = (value: string) => {
        if (this.maxLength < value.length) {
            return [
                {
                    text: this.$translate.instant('TR_170119-c14cad_TR', { maxLength: this.maxLength })
                }
            ];
        }

        return [];
    };
}
