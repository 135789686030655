import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import {
    AuthContextService,
    UiSettingPaginationLimit,
    UserSettingsManagerService
} from '../../../../../services';
import { FormDropDownItems, PanelType } from '../../../../molecules';

import './ui-settings.scss';

export class OrganEditPanelUiSettingsController implements ng.IController {
    public static $inject: string[] = ['userSettingsManager'];

    public userHasRight = { // used in template!
        bilBundleList: AuthContextService.isGranted(UiRights.BIL_BUNDLE_LIST),
        dbDatabaseList: AuthContextService.isGranted(UiRights.DB_DATABASE_LIST),
        dnsZonesList: AuthContextService.isGranted(UiRights.DNS_ZONES_LIST),
        domDomainsList: AuthContextService.isGranted(UiRights.DOM_DOMAINS_LIST),
        emailMailboxList: AuthContextService.isGranted(UiRights.EMAIL_MAILBOX_LIST),
        machineVmList: AuthContextService.isGranted(UiRights.MACHINE_VM_LIST),
        managedApplicationNextcloudList: AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_LIST),
        sslCertificatesList: AuthContextService.isGranted(UiRights.SSL_CERTIFICATES_LIST),
        subaccountList: AuthContextService.isGranted(UiRights.ACC_SUBACCOUNT_LIST),
        webObjectList: AuthContextService.isGranted(UiRights.WEB_OBJECT_LIST)
    };
    public overviewUiSettings: any;
    public limitItems: FormDropDownItems[];

    private _origOverviewUiSettings: any;

    constructor(
        private userSettingsManager: UserSettingsManagerService
    ) {}

    public $onInit = () => {
        this._origOverviewUiSettings = ng.copy(this.overviewUiSettings);
        this.limitItems = UiSettingPaginationLimit.map(
            (limit) => {
                return { value: +limit, name: limit};
            }
        );
    };

    public get panelType() {
        return PanelType.MISCELLANEOUS_ACTION;
    }

    public isGranted: (right: string) => boolean = (right) => {
        return AuthContextService.isGranted(right);
    };

    public cancelUiSettings = () => {
        this.overviewUiSettings = ng.copy(this._origOverviewUiSettings);
    };

    public saveUiSettings = () => {
        return this.userSettingsManager.saveSettingsForOverview(this.overviewUiSettings);
    };
}

export class OrganEditPanelUiSettingsComponent implements ng.IComponentOptions {
    public bindings = {
        overviewUiSettings: '<'
    };
    public template = require('./ui-settings.html');
    public controller = OrganEditPanelUiSettingsController;
}
