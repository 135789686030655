import { UiRights } from '@/configuration';
import {
    AccountModelService,
    AuthContextService,
    DepositModelService,
    UserRobotService
} from '@/services';

export class NotificationsHelperService {
    public static $inject: string[] = [
        'accountModel',
        'depositModel',
        'userRobot'
    ];

    constructor(
        private accountModel: AccountModelService,
        private depositModel: DepositModelService,
        private userRobot: UserRobotService
    ) {}

    public getSystemNotifications = () => {
        const promises: (PromiseLike<string[]> | PromiseLike<string>)[] = [];

        promises.push(this.getApiSystemNotifications());
        promises.push(this.missingSmsVerificationNotification());
        promises.push(this.missingAvvPrivacyNotification());

        return Promise
            .all(promises)
            .then(
                (notificationReminders) => {
                    const dashboardSystemNotifications = [];
                    for (const reminder of notificationReminders) {
                        switch (true) {
                            case Array.isArray(reminder):
                                (reminder as string[]).map(
                                    (notification) => dashboardSystemNotifications.push(notification)
                                );
                                break;
                            case reminder !== null:
                                dashboardSystemNotifications.push(reminder);
                                break;
                        }
                    }

                    return dashboardSystemNotifications;
                }
            );
    };

    public getApiSystemNotifications = (): PromiseLike<string[]> => {
        // Possible responses
        // 'completeBasicData', 'switchToDirectDebit'
        return this.accountModel.accountGetNotifications()
            .then(
                (notifications) => {
                    return notifications
                        .filter(
                            (notification) => notification.type as string === 'AccountNotificationReminder'
                        )
                        .map(
                            (accountNotificationReminder: unknown) => accountNotificationReminder.subType as string
                        ) as string[];
                }
            );
    };

    public missingSepaData = () => {
        if (
            AuthContextService.isRootOrCompanyAccount
            || !AuthContextService.isDirectCustomer
            || !AuthContextService.isGranted(UiRights.BIL_GET_DEPOSIT)
        ) {
            return Promise.resolve(false);
        }

        return this.depositModel.paymentMethodAvailability().then(
            (paymentMethodAvailability) => {
                const availablePaymentMethods = paymentMethodAvailability
                    .map((method) => method.paymentMethod);

                return AuthContextService.billingSettings.paymentType === 'prepaid'
                    && availablePaymentMethods.indexOf('directDebit') >= 0;
            }
        );
    };

    public missingSmsVerificationNotification = () => {
        if (AuthContextService.isDirectCustomer) {
            return this.accountModel.getAccountBasicData().then(
                (res) => {
                    return !res.verifications.includes('sms')
                        ? 'missingSmsValidation'
                        : null;
                }
            );
        }

        return Promise.resolve(null);
    };

    public missingAvvPrivacyNotification = () => {
        const avv = AuthContextService.isDirectCustomer
            && AuthContextService.isAdminUser
            && AuthContextService.isMainUser
            && [undefined, null]
                .indexOf(AuthContextService.billingSettings.dataProcessingAgreementSignedOn) >= 0;

        return Promise.resolve(avv ? 'missingPrivacyNotifier' : null);
    };
}
