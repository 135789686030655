export const MailboxPoolTypes = ['exchangeMailboxes', 'forwarderMailboxes', 'imapMailboxes'];
export const PossiblePoolMailboxProductFamilies = [
    {productFamilies: 'imap-mailboxes', serviceType: 'imapMailboxes'},
    {productFamilies: 'msexchange-mailboxes', serviceType: 'exchangeMailboxes'},
    {productFamilies: 'external-forwarders', serviceType: 'forwarderMailboxes'}
];
export const InternalForwarderProductFamilyMapper = 'external-forwarders';
export const InternalForwarderProductCode = 'email-forwarder-internal';
export const InternalForwarderProductFamily = 'internal-forwarders';
export const ExternalForwarderProductFamily = 'external-forwarders';
export const PoolMailboxProductCodeProductFamilyMapping = [
    { productCode: 'forwarder-external', poolProperty: 'forwarderMailboxes' },
    { productCode: 'imap-mailbox', poolProperty: 'imapMailboxes' },
    { productCode: 'msexchange-mailbox', poolProperty:  'exchangeMailboxes' }
];
export const EmailProductFamilies = [
    {
        type: 'imapMailbox',
        productFamily: 'imap-mailboxes'
    },
    {
        type: 'externalForwarder',
        productFamily: 'external-forwarders'
    },
    {
        type: 'catchall',
        productFamily: 'catchalls'
    },
    {
        type: 'exchangeMailbox',
        productFamily: 'msexchange-mailboxes'
    },
    {
        type: 'mailingList',
        productFamily: 'mailing-lists'
    },
    {
        type: 'smtpForwarder',
        productFamily: 'smtp-forwarders'
    }
];
