import ng from 'angular';

import { PanelHeaderData } from '@/atomic-components/molecules';
import { DashboardOverviewObject } from '@/atomic-components/organisms';
import { UiRights } from '@/configuration';
import { AuthContextService, BundleOverviewModel, FilterCacheService } from '@/services';

export class TemplateBundleOverviewController {
    public static $inject: string[] = ['$translate', 'authContext', 'bundleOverviewModel', 'filterCache'];

    public leftPanelArea: PanelHeaderData[];
    public modifier = '';
    public dashboardOverview: DashboardOverviewObject;
    public pageHeaderData: PanelHeaderData = {
        panelHeaderRoute: '',
        panelIcon: 'boxes-alt',
        panelTitle: this.$translate.instant('TR_100119-aed0a3_TR')
    };
    public faqArticleIdList: string[] = [
        'webspace-ftp-access',
        'domain-register-new',
        'ssl-activate'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private bundleOverviewModel: BundleOverviewModel,
        private filterCache: FilterCacheService
    ) {}

    public $onInit() {
        this.dashboardOverview = {
            // List of table service data
            overviewsData: [
                {
                    listOptions: {
                        limit: 5,
                        sort: { field: 'BundleLastChangeDate', order: 'DESC' }
                    },
                    overviewModel: this.bundleOverviewModel,
                    panelHeaderData: {
                        includeShowAllLink: false,
                        includeShowDetailsLink: false,
                        panelHeaderRoute: 'bundle.overview',
                        panelHeaderRouteParams: {},
                        panelIcon: 'box-alt',
                        panelTitle: this.$translate.instant('BUNDLE.GENERAL.BUNDLES')
                    },
                    serviceItemList: []
                }
            ],
            // Dashboard main menu panel
            panelData: {
                mainHeader: this.authContext.isGrantedAll([UiRights.BIL_BUNDLE_CREATE, UiRights.WEB_OBJECT_LIST])
                    ? {
                        isGranted: this.authContext.isGrantedAll(
                            [UiRights.BIL_BUNDLE_CREATE, UiRights.WEB_OBJECT_LIST]
                        ),
                        route: 'bundle.new',
                        routeParams: {},
                        text: this.$translate.instant('TR_090119-6b1a06_TR')
                    }
                    : null
            },
            simpleFilter: this.filterCache.resolveSimpleFilter('Bundle')
        };
    }
}

export class TemplateBundleOverviewComponent implements ng.IComponentOptions {
    public controller = TemplateBundleOverviewController;
    public controllerAs = 'TemplateBundleOverviewCtrl';
    public template = require('./bundle-overview-template.html');
}
