import * as ng from 'angular';

import { DashboardOverviewDataObject, OrganismOverviewController } from '@/atomic-components/organisms';

export class MoleculeOverviewRowPanelNetworkController implements ng.IController {
    public static $inject: string[] = [];

    public overviewData: DashboardOverviewDataObject;
    public overviewLoad: (selection: any) => any;
    public internalPaginationLimit: number;
    public OrganismOverview: OrganismOverviewController;
    public displayPoolDetailsModal = false;

    public get showAccounts() {
        return this.OrganismOverview.globals.showAccounts;
    }
}

export class MoleculeOverviewRowPanelNetworkComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelNetworkController;
    public controllerAs = 'OverviewNetworkCtrl';
    public template = require('./network-row.html');
}
