export const ErrorMapperDomainConst = {
    30001: /* translationId */ 'ERRORS.30001',
    30003: /* translationId */ 'TR_140420-8a5644_TR',
    30018: /* translationId */ 'TR_090420-e64fc2_TR',
    30090: /* translationId */ 'ERRORS.30090', // @todo entfernen wenn ACR-46 gefixt ist
    30093: /* translationId */ 'ERRORS.30093',
    32050: /* translationId */ 'ERRORS.32050',
    // tslint:disable-next-line:max-line-length
    34101: /* translationId */ 'ERRORS.34101' // @todo Sollten wir irgendwie handeln ohne das die API diesen Fehler auslöst
};
