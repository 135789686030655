import * as ng from 'angular';

export class MoleculeOverviewRowPanelDashboardManagedMachinesController implements ng.IController {
    public static $inject: string[] = [];

    public serviceItemList: { length: number };

    public noBundlesOnDashboard = () => {
        return this.serviceItemList.length === 0;
    };

    public get showLinkToOverview() {
        return this.serviceItemList.length > 0;
    }
}

export class MoleculeOverviewRowPanelDashboardManagedMachinesComponent implements ng.IComponentOptions {
    public bindings = {
        serviceItemList: '<'
    };
    public controller =  MoleculeOverviewRowPanelDashboardManagedMachinesController;
    public controllerAs = 'DashboardOverviewCtrl';
    public template = require('./dashboard-managed-machines.html');
}
