import * as ng from 'angular';

import { ProductsModelService } from '../../../../../services';
import * as Types from '../../../../../types';
import { SeletionItem } from '../../../../molecules';

export class OrganismWizardGlobalProductSelectionController {
    public static $inject: string[] = ['$timeout'];

    public selectedAccount: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    public selectedProduct: SeletionItem;
    public wizardSelection: SeletionItem[];

    constructor(
        private $timeout: ng.ITimeoutService,
        private productModel: ProductsModelService
    ) {}

    public $onInit() {
        this.wizardSelection = [
            {
                description: 'Konfigurieren Sie Ihren Nextcloud Server nach Ihren Vorstellungen. Einfach und unkompliziert in drei Schritten...',
                name: 'Nextcloud Wizard',
                value: {
                    productCode: 'nextcloud' /* Nextcloud Product Code for outerProductCode  */
                }
            }
        ];
    }

}

export class OrganismWizardGlobalProductSelectionComponent implements ng.IComponentOptions {
    public bindings = {
        selectedAccount: '<',
        selectedProduct: '='
    };
    public template = require('./wizard-global-product-selection.html');
    public controller = OrganismWizardGlobalProductSelectionController;
}
