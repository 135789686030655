import { RobotTranslate } from '@/services';

export const TimeSpecificationsConst = {
    weekdays: [
        { translationId: 'TR_090119-742493_TR', value: 'mon' },
        { translationId: 'TR_090119-44258f_TR', value: 'tue' },
        { translationId: 'TR_090119-3239bd_TR', value: 'wed' },
        { translationId: 'TR_090119-d36c66_TR', value: 'thu' },
        { translationId: 'TR_090119-65e966_TR', value: 'fri' },
        { translationId: 'TR_090119-5628fc_TR', value: 'sat' },
        { translationId: 'TR_090119-95868e_TR', value: 'sun' }
    ],

    hours : Array.from({ length: 24 }, (_, i) => {
        // This mapping is required by WEBHOSTING-135
        // designates 0 as sentinel value
        return i;
    }).map(n => {
        if (n === 0){
            return { name: '0', value: 24 };
        }
        else {
            return { name: n.toString(), value: n };
        }
    }),

    minutes : () => Array.from({ length: 13 }, (_, i) => {
        // This mapping is required by WEBHOSTING-135
        // designates 0 as sentinel value
        return i === 0 ? 'default' : i * 5;
    }).map(n => {
        if (n === 'default'){
            return { name: RobotTranslate.translate('TR_051021-99e860_TR'), value:0};
        }
        else if (n === 60){
            return { name: '0', value: 60};
        }
        else {
            return { name: n.toString(), value: n };
        }
    })
};

// Easter Egg Helper
const now = new Date();
const day = now.getDate();
const month = now.getMonth() + 1;

export const EasterEggDays = {
    aprilFoolsDay: day === 1 && month === 4,
    halloween: day === 31 && month === 10
};
