import * as ng from 'angular';
import * as Types from '../../../../../types';

export class MoleculePanelEditRowDnsTemplateReplacementsController implements ng.IController {
    public static $inject: string[] = ['$timeout'];

    public replacements: any = {};
    public records: Types.DnsApi.RecordTemplate[];
    public validationErrorList: any[] = [];

    public fields = [
        {
            attribute: 'ipv4Replacement',
            label: '##IPV4##',
            type: 'ipv4',
            value: '',
            validationErrors: []
        },
        {
            attribute: 'ipv6Replacement',
            label: '##IPV6##',
            type: 'ipv6',
            value: '',
            validationErrors: []
        },
        {
            attribute: 'mailIpv4Replacement',
            label: '##MX_IPV4##',
            type: 'ipv4',
            validationErrors: []
        },
        {
            attribute: 'mailIpv6Replacement',
            label: '##MX_IPV6##',
            type: 'ipv6',
            value: '',
            validationErrors: []
        }
    ];

    public replacementValues = {
        ipv4Replacement: '',
        ipv6Replacement: '',
        mailIpv4Replacement: '',
        mailIpv6Replacement: ''
    };

    public optionalReplacementTypes = {
        ipv4Replacement: false,
        ipv6Replacement: false,
        mailIpv4Replacement: false,
        mailIpv6Replacement: false
    };

    private _lastCheckedRecordsHash = '';
    private _replacementTypes = {
        ipv4Replacement: false,
        ipv6Replacement: false,
        mailIpv4Replacement: false,
        mailIpv6Replacement: false
    };

    constructor(
        private $timeout: ng.ITimeoutService
    ) {}

    public get replacementTypes() {
        if ([undefined].indexOf(this.records) >= 0) {
            return this._replacementTypes;
        }

        if (this._lastCheckedRecordsHash === JSON.stringify(this.records)) {
            return this._replacementTypes;
        }

        this._replacementTypes.ipv4Replacement = this._replacementTypes.ipv4Replacement
            || this.records.some((recordTemplate) => recordTemplate.content === '##IPV4##');

        this._replacementTypes.ipv6Replacement = this._replacementTypes.ipv6Replacement
            || this.records.some((recordTemplate) => recordTemplate.content === '##IPV6##');

        this._replacementTypes.mailIpv4Replacement = this._replacementTypes.mailIpv4Replacement
            || this.records.some((recordTemplate) => recordTemplate.content === '##MX_IPV4##');

        this._replacementTypes.mailIpv6Replacement = this._replacementTypes.mailIpv6Replacement
            || this.records.some((recordTemplate) => recordTemplate.content === '##MX_IPV6##');

        if (this._lastCheckedRecordsHash === '') {
            this.optionalReplacementTypes = ng.copy(this._replacementTypes);
        }

        this._lastCheckedRecordsHash = JSON.stringify(this.records);

        return this._replacementTypes;
    }

    public set requiredReplacementTypes({}) {} // tslint:disable-line:no-empty
    public get requiredReplacementTypes() {
        return this._replacementTypes;
    }

    public $onChanges = (changes) => {
        if (changes.records !== undefined && changes.records.currentValue !== undefined) {
            if (this.replacements === undefined) {
                this.replacements = {};
            }

            for (const key in this.replacementTypes) {
                if (!this.replacementTypes[key]) {
                    this.replacements[key] = undefined;
                }
            }
        }
    };

    public changedReplacement = (changedReplacent) => {
        this.$timeout(() => {
            if (this.validationErrorList[changedReplacent.type].length === 0) {
                this.replacements[changedReplacent.type] = changedReplacent.value;
            } else {
                this.replacements[changedReplacent.type] = '';
            }
        });
    };

    /* tslint:disable-next-line:no-empty */
    public doNothing = () => {};
}

export class MoleculePanelEditRowDnsTemplateReplacementsComponent implements ng.IComponentOptions {
    public bindings = {
        records: '<',
        replacements: '=',
        requiredReplacementTypes: '=?'
    };

    public controller = MoleculePanelEditRowDnsTemplateReplacementsController;
    public template = require('./panel-edit-row-dns-template-replacements.html'); /* tslint:disable-line:max-line-length */
}
