import ng from 'angular';
import { ContractPeriodWord, CycleBillingTranslationConst } from '@/configuration';
import { PriceCacheService, PriceHelperService } from '@/services';
import { BillingApi, UI, ViewTypes } from '@/types';
import { FormatMegabyteFilter } from '@/filters';

export class OrganCreateConfigurationNextcloudAddonQuantitySliderController implements ng.IController {
    public static $inject: string[] = [
        '$translate',
        'formatMegabyteFilter',
        'priceCache',
        'priceHelper',
    ];

    public confirmationText: string;
    public hideBillingPeriode = false;
    public lableText: string;
    public max: number;
    public min: number;
    public model: number;
    public onChangeCallback: () => unknown;
    public diffAmount: number;
    public orderConfirmed: boolean;
    public step: number;
    public unit: string;
    public voucher: ViewTypes.ProductConfigVoucher;
    protected sliderOptions: UI.RzOptions;

    private _priceSuffixText = '';
    private _productCode: string;
    private _currentModel: number;

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private formatMegabyte: FormatMegabyteFilter,
        private priceCache: PriceCacheService,
        private priceHelper: PriceHelperService,
    ) {}

    $onInit(): void {
        this._currentModel = ng.copy(this.model);
        this._setDiffAmount();
        const dynamicSliderColor = (value: number): string => {
            if (value <= ((this.min + this.max) / 3)) {
                return '#eb2f3b'; // red
            }
            if (value <= ((this.min + this.max) / 3 * 2)) {
                return '#f99a1c'; // orange
            }
            return '#10bd78'; // green
        };

        this.sliderOptions = {
            // Values
            ceil: this.max,
            disableAnimation: true,
            floor: this.min,
            showTicks: true,
            step: this.step,

            // Functions
            getPointerColor: dynamicSliderColor,
            getTickColor: dynamicSliderColor,
            onChange: () => {
                this._setDiffAmount();
                if (this.onChangeCallback) {
                    this.onChangeCallback();
                }
            },
            translate: (value: number|string) => {
                if (this.unit === 'MB') {
                    return this.formatMegabyte(value);
                }
                return (value as string) + ' ' + this.unit;
            },
        };

    }

    $onChanges(onChangesObj: ng.IOnChangesObject): void {
        if (onChangesObj?.productCode) {
            this._setDiffAmount();
        }
    }

    get priceSuffixText(): string {
        return this.hideBillingPeriode ? '' : this._priceSuffixText;
    }

    get productCode(): string {
        return this._productCode;
    }
    set productCode(value: string) {
        if (!!value !== false && value !== this._productCode) {
            void this.priceCache.getPriceByProductCode(value).then(
                (response: BillingApi.ArticlePurchasePrice[]) => {
                    if (response.length === 1) {
                        this._priceSuffixText = `/ ${this.$translate.instant(
                            CycleBillingTranslationConst[response[0].contractPeriod as ContractPeriodWord],
                        )}`;
                    }
                },
            );
        }

        this._productCode = value;
    }

    private _setDiffAmount = (): void => {
        this.diffAmount = (this.model - this._currentModel) / this.step;
    };

    public getPriceSuffixWithVoucher = (): string => {
        if (this.voucher) {
            return this.$translate.instant(
                /* translationId */ 'TR_050324-963dc9_TR',
            );
        }
        return this.priceSuffixText;
    };
}

export class OrganCreateConfigurationNextcloudAddonQuantitySliderComponent implements ng.IComponentOptions {
    public bindings = {
        confirmationText: '@?',
        hideBillingPeriode: '<?',
        labelText: '@',
        max: '<?',
        min: '<?',
        model: '=?',
        onChangeCallback: '<?',
        orderConfirmed: '=?',
        productCode: '<?',
        step: '<?',
        unit: '@',
        diffAmount: '=?',
        voucher: '<?',
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    public template = require('./addon-quantity-slider.html');
    public controller =  OrganCreateConfigurationNextcloudAddonQuantitySliderController;
}
