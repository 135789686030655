import * as ng from 'angular';
import { ViewTypes } from '@/types';
import './panel-edit-row-add-text-to-list.scss';

export class MoleculePanelEditRowAddTextToListController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public list: (string | number)[];
    public newItemValue: string;
    public itemList: { value: any, tmpStatus: string, newStatus: string }[] = [];
    public errorMessage = '';
    public validationInstructions: any[];
    public placeholder: string;
    public isNumberList = false;
    public max: number;
    public onChangeCallback: () => any;
    public callbackOnPaste: (pasteEvent: ClipboardEvent) => any;
    public addOnPaste: (pasteEvent: ClipboardEvent) => { entryList: string[], validationError: string };
    public getItemsToAdd: (input: string) => { entryList: string[], validationError: string };
    public listOrder: ViewTypes.ListOrder = ViewTypes.ListOrder.NewItemFirst;
    public formatItem: (value: any) => string = (value) => value;
    public callbackOnBlur: (input: string) => {};

    public allowTextArea = false;
    public forceTextArea = false;

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.list = this.list || [];
        this.list.forEach((target) => {
            this.itemList.push({value: target, tmpStatus: 'registered', newStatus: ''});
        });
    }

    public onPaste = (pasteEvent: ClipboardEvent) => {
        if (this.callbackOnPaste !== undefined) {
            this.callbackOnPaste(pasteEvent);
        }
    };

    public addToList = (newItem: any) => {
        this.errorMessage = '';

        // Convert newItem to number if `isNumberList` property is set.
        if (this.isNumberList) {
            newItem.value = parseInt(newItem.value, 10);
        }

        if ([undefined, null, ''].indexOf(newItem.value) >= 0) {
            this.errorMessage = this.$translate.instant('TR_100119-97498a_TR');
            return;
        }
        if (this.itemExistsInList(newItem.value)) {
            this.errorMessage = this.$translate.instant('TR_040624-41f273_TR');
            return;
        }

        const newListItem: any = {value: newItem.value, tmpStatus: '', newStatus: 'new'};
        if (this.listOrder === ViewTypes.ListOrder.NewItemLast) {
            this.itemList.push(newListItem);
        } else {
            this.itemList.unshift(newListItem);
        }
        this.newItemValue = '';
        this.list.push(newItem.value);
        if (this.onChangeCallback !== undefined) {
            this.onChangeCallback();
        }
    };

    public buttonItemModifier(item: any) {
        return item.newStatus === 'delete' ? 'active' : 'inactive';
    }

    public buttonItemIsActive(item: any): boolean  {
        return !(item.newStatus === 'delete');
    }

    public toggleStatus = (item: any) => {
        if (item.newStatus === 'delete') {
            item.newStatus = item.tmpStatus === 'registered' ? item.tmpStatus : 'new';
            this.list.push(item.value);
        } else {
            item.newStatus = 'delete';
            const index = this.list.indexOf(item.value);
            this.list.splice(index, 1);
        }
        if (this.onChangeCallback !== undefined) {
            this.onChangeCallback();
        }
    };

    private itemExistsInList = (item: any) => {
        const itemAlreadyInList = this.itemList.some((itemInList: { value: any, tmpStatus: string, newStatus: string }) => {
            return itemInList.value === item;
        });
        const itemDuplicated = Array.from(new Set(this.itemList)).length != this.itemList.length;
        return itemAlreadyInList || itemDuplicated;
    };

    public get disableAddButton() {
        if (this.max === undefined) {
            return false;
        }
        return this.list.length >= this.max;
    }

    public get showQuotaMessage() {
        return this.max !== undefined;
    }

    public get quotaMessage() {
        return this.list.length + '/' + this.max;
    }

}

export class MoleculePanelEditRowAddTextToListComponent implements ng.IComponentOptions {
    public bindings = {
        allowTextArea: '<?',
        forceTextArea: '<?',
        list: '=',
        onChangeCallback: '<',
        placeholder: '@?',
        isNumberList: '<?',
        validationInstructions: '<?',
        callbackOnPaste: '<',
        addOnPaste: '<',
        listOrder: '<',
        errorMessage: '=?',
        getItemsToAdd: '<?',
        formatItem: '<?',
        callbackOnBlur: '<?',
    };
    public transclude = {
        label: '?rowLabel'
    };
    public controller = MoleculePanelEditRowAddTextToListController;
    public template = require('./panel-edit-row-add-text-to-list.html');
}
