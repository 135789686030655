import ng from 'angular';

import { ContingentType } from '@/atomic-components/organs';
import {
    AuthContextService, BillingHelperService, BundleModelService, CacheService, DepositModelService,
    PriceHelperService, ResourceModelService,
} from '@/services';
import * as Types from '@/types';

export class OrganismWizardSufficientCreditController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'bundleModel',
        'billingHelper',
        'cache',
        'depositModel',
        'priceHelper',
        'resourceModel',
    ];

    public account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    public productCodeList: string[];
    public contingentType: ContingentType;
    public contingentId: string;
    public missingCredits = '';
    public voucher: Types.ViewTypes.ProductConfigVoucher;

    private _sufficientCreditAvailable: boolean;
    private _checkingCreditAvailable: boolean;
    private _productCodeList: string[];

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private bundleModel: BundleModelService,
        private billingHelper: BillingHelperService,
        private cache: CacheService,
        private depositModel: DepositModelService,
        private priceHelper: PriceHelperService,
        private resourceModel: ResourceModelService,
    ) {}

    public $onInit(): void {
        this._productCodeList = ng.copy(this.productCodeList);
        this._sufficientCreditAvailable = true;
        this._checkingCreditAvailable = false;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public $onChanges(changes: any): void {
        if (
            changes.account
            || changes.contingentType
            || changes.contingentId
            || changes.productCode
            || changes.voucher
            || changes.productCodeList
        ) {
            this._checkDeposit();
        }
    }

    public $doCheck(): void {
        if (JSON.stringify(this.productCodeList) !== JSON.stringify(this._productCodeList)) {
            this._productCodeList = ng.copy(this.productCodeList);
            this._checkDeposit();
        }
    }

    public set sufficientCreditAvailable(value) {
        this._sufficientCreditAvailable = value;
    }

    public get sufficientCreditAvailable(): boolean {
        return this._sufficientCreditAvailable;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public set hideSufficientCreditHint(_value: boolean) {}
    public get hideSufficientCreditHint(): boolean {
        return AuthContextService.isRootOrCompanyAccount || this.sufficientCreditAvailable;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public set checkingCreditAvailable(_value: boolean) {}
    public get checkingCreditAvailable(): boolean {
        return this._checkingCreditAvailable;
    }

    public callbackCheckDeposit = (): void => {
        this.cache.clearAll();
        void this._calculateRemainingDeposit();
    };

    private _checkDeposit = (): void => {
        if (
            [undefined, null].indexOf(this.productCodeList) < 0
            && Array.isArray(this.productCodeList)
            && this.productCodeList.length > 0
            && this.productCodeList.every((pc) => [undefined, null, ''].indexOf(pc) < 0)
        ) {
            void this._calculateRemainingDeposit().then((hasRemainingDeposit) => {
                if (!hasRemainingDeposit
                    && [undefined, null].indexOf(this.contingentType) < 0
                    && [undefined, null, ''].indexOf(this.contingentId) < 0
                ) {
                    void this._checkRemainingContingent(this.productCodeList);
                }
            });
        } else {
            this._sufficientCreditAvailable = true;
            this._checkingCreditAvailable = false;
        }
    };

    private _calculateRemainingDeposit = (): PromiseLike<boolean> => {
        this._checkingCreditAvailable = true;
        /** Only check deposit of (logedIn) Account
        /*  OTon Stephan: derzeit gibt es keine subaccounts, die prepaid sind
        /*  das betrifft also nur die konstellation: root- -> subaccount
        /*  hab michi gefragt
        **/
        // Check credit of account
        return this._checkAccountDeposit().then(
            (accountCreditAfterPurchase: boolean) => this._setSufficientCreditAvailable([accountCreditAfterPurchase]),
        );
    };

    private _checkRemainingContingent = async (productCodeList: string[]): Promise<void> => {
        if (
            [undefined, null].indexOf(this.contingentType) < 0
            && [undefined, null, ''].indexOf(this.contingentId) < 0
        ) {
            /* eslint-disable no-case-declarations */
            switch (this.contingentType) {
                case ContingentType.bundle:
                    const contingentBundle = await this.bundleModel.findOne(this.contingentId)
                        .then((bundle: Types.BundleApi.Bundle) => {
                            return bundle.effectiveContingentUsage.find(
                                (ec) => ec.productCodes.some(
                                    (pc) => productCodeList.some(
                                        (pcl) => pcl === pc,
                                    ),
                                ),
                            );
                        });
                    void this._setSufficientCreditAvailable([contingentBundle.availableCapacity > 0]);
                    break;

                case ContingentType.pool:
                    const contingentPool = await this.resourceModel.findOne(this.contingentType, this.contingentId)
                        .then((pool: Types.ResourceApi.Pool) => {
                            if (productCodeList.some((pcl) => pcl.startsWith('email-imap-mailbox'))) {
                                return pool.imapMailboxesAllocated <= pool.imapMailboxesLimit;
                            }
                            if (productCodeList.some((pcl) => pcl.startsWith('email-forwarder'))) {
                                return pool.forwarderMailboxesAllocated <= pool.forwarderMailboxesLimit;
                            }
                            if (productCodeList.some((pcl) => pcl.startsWith('email-exchange'))) {
                                return pool.exchangeMailboxesAllocated <= pool.exchangeMailboxesLimit;
                            }
                        });
                    void this._setSufficientCreditAvailable([contingentPool]);
                    break;

                case ContingentType.databaseServer:
                    const contingentDatabase = true;
                    void this._setSufficientCreditAvailable([contingentDatabase]);
                    break;

                case ContingentType.webserver:
                    const contingentWebserver = true;
                    void this._setSufficientCreditAvailable([contingentWebserver]);
                    break;

                case ContingentType.webspace:
                    const contingentWebspace = true;
                    void this._setSufficientCreditAvailable([contingentWebspace]);
                    break;

                case ContingentType.standalone:
                case undefined:
                    break;
            }
            /* eslint-enable no-case-declarations */
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _checkAccountDeposit(subAccount?: Types.AccountApi.Subaccount): Promise<any> {
        const promises = [];
        if ([undefined, null].indexOf(this.productCodeList) >= 0 || this.productCodeList?.length === 0) {
            return Promise.resolve([]);
        }

        for (const productCode of this.productCodeList) {
            if (
                this.voucher
                && !this.voucher.eligibleProductCodes.includes(productCode)
            ) {
                continue;
            }
            promises.push(
                this.billingHelper.getPriceByProductCode(
                    productCode,
                    subAccount !== undefined ? subAccount.id : null,
                ),
            );
        }

        return Promise.all(promises).then(
            (priceResolves) => {
                const costs: number[] = [];

                priceResolves.map(
                    (price: Types.BillingApi.ArticlePurchasePrice) => {
                        let voucherPrice: Types.BillingApi.ArticlePurchasePrice;
                        if (this.voucher) {
                            if (this.voucher.eligibleProductCodes?.includes(price.productCode)) {
                                voucherPrice = this.priceHelper.getPriceFromVoucherAndPriceObject(
                                    this.voucher,
                                    price,
                                );
                            } else {
                                voucherPrice = {
                                    productCode: price.productCode,
                                    netAmount: 0,
                                    grossAmount: 0,
                                    netAmountInBaseCurrency: 0,
                                    grossAmountInBaseCurrency: 0,
                                    currency: price.currency,
                                    service: price.service,
                                    startPIT: price.startPIT,
                                };
                            }
                        }
                        const exchangeRatio = (price.exchangeRatio?.exchangeRatio || 10000) / 10000;
                        costs.push(
                            this.priceHelper.calculatePrice(
                                this.voucher ? voucherPrice : price,
                                false, // always calculate gross price
                                false,
                                [undefined, null].indexOf(price.promotionalNetAmount) < 0,
                            ) / exchangeRatio,
                        );
                    },
                );

                return costs;
            },
        ).then(
            (costs: number[]) => {
                const costsResult = [0, ...costs].reduce((prev, current) => prev + current);
                return this.depositModel
                    .reloadDepositInfo(subAccount)
                    .then(() => this.depositModel.sufficientCreditForOrder(+(costsResult * 10000).toFixed(0)));
            },
        ).then(
            (creditAfterPurchase) => {
                if (creditAfterPurchase === null && [undefined, null].indexOf(subAccount) >= 0) {
                    // Costs are 0 (rootAmin or in contingent) or no deposit data available
                    return true;
                }
                if (creditAfterPurchase < 0) {
                    this.missingCredits = (Math.abs(creditAfterPurchase) / 10000)
                        .toFixed(2)
                        .replace('.', ',')
                        .concat(' ' + AuthContextService.accountPaymentDetails.baseCurrency.toUpperCase());
                } else {
                    this.missingCredits = '';
                }
                return creditAfterPurchase >= 0;
            },
        );
    }

    public get missingCreditsInfo(): string {
        return this.$translate.instant(
            'TR_221020-269d1d_TR',
            {
                missingAmmount: this.missingCredits,
            },
        );
    }

    private _setSufficientCreditAvailable = (resolves: boolean[]): PromiseLike<boolean> => {
        return this.$timeout(() => {
            this.sufficientCreditAvailable = resolves.every((hasEnoughtCredit) => hasEnoughtCredit);
            this._checkingCreditAvailable = false;
            return this.sufficientCreditAvailable;
        });
    };
}

export class OrganismWizardSufficientCreditComponent implements ng.IComponentOptions {
    public bindings = {
        account: '<',
        contingentType: '<?',
        contingentId: '<?',
        productCodeList: '<',
        sufficientCreditAvailable: '=?',
        voucher: '<?',
    };
    public controller =  OrganismWizardSufficientCreditController;
    public template = require('./wizard-sufficient-credit.html');
}
