import * as ng from 'angular';

export class TemplateRightsTemplateEditController implements ng.IController {
    public static $inject: string[] = ['$translate'];
    public rightsTemplate: any;
    public permissions: any;
    public pageHeaderData: any;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit() {
        this.pageHeaderData = {
            backwardLink: 'reseller.rightstemplates.overview',
            backwardText: this.$translate.instant('TR_090119-85645e_TR'),
            panelHeaderRoute: '',
            panelIcon: 'lock',
            panelTitle: this.rightsTemplate.name
        };
    }
}

export class TemplateRightsTemplateEditComponent implements ng.IComponentOptions {
    public bindings = {
        permissions: '<',
        rightsTemplate: '<'
    };
    public template = require('./rights-template-edit.html');
    public controller =  TemplateRightsTemplateEditController;
}
