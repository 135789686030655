import * as ng from 'angular';
import { FormDropDownItems } from '../../../../forms/form-elements/drop-down/drop-down';

export class MoleculePanelEditRowDropdownAddController implements ng.IController {
    public static $inject: string[] = [];

    public items: FormDropDownItems[];
    public disabled: boolean;
    public model: any[];
    public selected: any;
    public identifyDuplicatesBy: string;
    public json = false;
    public addItemCallback: any = null;
    public enableOnAdd = true;

    public disableAddButton = () => {
        if (this.identifyDuplicatesBy == null || this.identifyDuplicatesBy === undefined) {
            return this.selected == null
                || this.selected === undefined
                || this.json
                    ? this.model.map(
                        (data) => JSON.stringify(data)
                    ).indexOf(this.selected) >= 0
                    : (this.model.indexOf(this.selected) >= 0);
        } else {
            return this.selected == null || this.selected === undefined || this.isDuplicateInModel();
        }
    };

    public addItem = () => {
        if ([null, undefined].indexOf(this.selected) < 0) {
            const selectedItem = this.json ? JSON.parse(this.selected) : this.selected;
            if (this.enableOnAdd && selectedItem.value === false) {
                selectedItem.value = true;
            }
            this.model.push(selectedItem);
            if ([undefined, null].indexOf(this.addItemCallback) === -1) {
                this.addItemCallback(selectedItem);
            }
        }
    };

    private isDuplicateInModel = () => {
        for (const item of this.model) {
            if (this.json && JSON.stringify(item) === this.selected) {
                return true;
            } else if (!this.json && item[this.identifyDuplicatesBy] === this.selected[this.identifyDuplicatesBy]) {
                return true;
            }
        }
        return false;
    };
}

export class MoleculePanelEditRowDropdownAddComponent implements ng.IComponentOptions {
    public transclude = {
        label: 'rowLabel'
    };
    public bindings = {
        addItemCallback: '<',
        disabled: '<',
        enableOnAdd: '<?',
        identifyDuplicatesBy: '@',
        items: '<',
        json: '<',
        model: '='
    };
    public controller = MoleculePanelEditRowDropdownAddController;
    public template = require('./panel-edit-row-dropdown-add.html');
}
