import tldListRaw from '@/assets/data/tld-list-complete.json';

export class TldListService {
    public getList = (): typeof tldListRaw => {
        return tldListRaw;
    };

    /**
    *  @param unicode default true
    *  @param addDotPrefix default true
    *  @returns tlds
    **/
    public getTlds = (unicode?: boolean, addDotPrefix?: boolean): string[] => {
        unicode = unicode === undefined ? true : unicode;
        addDotPrefix = addDotPrefix === undefined  ? true : addDotPrefix;

        return this.getList().map((tld) => {
            return unicode
                ? (addDotPrefix ? tld.unicode.replace (/^/, '.') : tld.unicode)
                : (addDotPrefix ? tld.ace.replace (/^/, '.') : tld.ace);
        });
    };

    /**
    *  @param unicode default true
    *  @param addDotPrefix default true
    *  @returns multiPartTlds
    **/
    public getMultiPartTlds = (unicode?: boolean, addDotPrefix?: boolean): string[] => {
        unicode = unicode === undefined ? true : unicode;
        addDotPrefix = addDotPrefix === undefined  ? true : addDotPrefix;
        const dotFromIndex = addDotPrefix ? 1 : 0;

        const allMultiPartsTlds = this.getList().map((tld) => {
            return unicode
                ? (addDotPrefix ? tld.unicode.replace (/^/, '.') : tld.unicode)
                : (addDotPrefix ? tld.ace.replace (/^/, '.') : tld.ace);
        });

        return allMultiPartsTlds.filter((tld) => tld.indexOf('.', dotFromIndex) > 0);
    };
}
