import * as ng from 'angular';
import './panel-wrapper.scss';

export class MoleculePanelWrapperController implements ng.IController {
    public static $inject: string[] = [];
    public title: string;
    public icon: string;
    public hideHeader: boolean;
    public addMargin: boolean = true;
}

export class MoleculePanelWrapperComponent implements ng.IComponentOptions {
    public bindings = {
        hideHeader: '<',
        icon: '@',
        title: '@',
        addMargin: '<'
    };
    public transclude = true;
    public controller =  MoleculePanelWrapperController;
    public template = require('./panel-wrapper.html');
}
