/* eslint @typescript-eslint/no-unsafe-member-access: 0 */
/* eslint @typescript-eslint/no-unsafe-call: 0 */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import * as ng from 'angular';
// @ts-expect-error: Could not find a declaration file for module '@novnc/novnc/lib/rfb'.
import RFB from '@novnc/novnc/lib/rfb';
// @ts-expect-error: Could not find a declaration file for module '@novnc/novnc/lib/rfb'.
import * as keyCodes from '@novnc/novnc/lib/input/keysym';
import { MachineModelService } from '@/services';
import { MachineApi } from '@/types';
import VirtualMachine = MachineApi.VirtualMachine;

export class TemplateMachineVncController {
    public static $inject: string[] = ['$timeout', '$translate', 'machineModel'];

    public isNotConnected = true;
    public isTryingToConnect = false;
    public connectionLost = false;
    public pasteModeOn = false;
    public fullScreenMode = false;
    public disableScreen = false;
    public pasteModeFeatureDisabledForNow = true; // copy and past needs server side updates -> disabled for now
    public clipboardText = '';
    public machine: VirtualMachine;

    // this is the RFB-object. For more information about it see:
    // https://novnc.com/noVNC/docs/API.html
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private activeConnection: any;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private machineModel: MachineModelService
    ) {}

    public $onInit = (): void => {
        this.establishVncConnection();
    };

    public establishVncConnection = (): void => {
        void this.$timeout(() => {
            this.isNotConnected = true;
            this.isTryingToConnect = true;
        });

        if (this.activeConnection) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            this.activeConnection.disconnect();
        }

        void this.machineModel.vncSessionCreate(this.machine.id).then(
            (sessionUrl) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                this.activeConnection = new RFB(document.getElementById('noVNC_canvas'), sessionUrl, {});

                // register event handlers for a complete list of available handlers check:
                // https://novnc.com/noVNC/docs/API.html#events
                this.activeConnection.addEventListener('connect', this.connectedToServerCallback);
                this.activeConnection.addEventListener('disconnect', this.connectionToServerLost);
                this.activeConnection.addEventListener('credentialsrequired', this.connectionToServerLost);
                this.activeConnection.addEventListener('securityfailure', this.connectionToServerLost);
            }
        );
    };

    public get primaryIpv4Address(): string {
        return this.machine.networkInterfaces[0].ipv4PrimaryAddress;
    }

    public get primaryIpv6Address(): string {
        return this.machine.networkInterfaces[0].ipv6PrimaryAddress;
    }

    public connectedToServerCallback = (): void => {
        void this.$timeout(() => {
            this.connectionLost = false;
            this.isNotConnected = false;
            this.isTryingToConnect = false;
        });

        void this.$timeout(() => {
            this.activeConnection.scaleViewport = true;
            this.activeConnection.resizeSession = true;
            this.activeConnection.focus();
        }, 300);
    };

    public toggleFullScreenMode = (): void => {
        if (
            document.fullscreenElement
            // @ts-ignore TS2339
            || document.mozFullScreenElement
            // @ts-ignore TS2339
            || document.webkitFullscreenElement
            // @ts-ignore TS2339
            || document.msFullscreenElement
        ) {
            if (document.exitFullscreen) {
                void document.exitFullscreen();
                // @ts-ignore TS2339
            } else if (document.mozCancelFullScreen) {
                // @ts-ignore TS2339
                void document.mozCancelFullScreen();
                // @ts-ignore TS2339
            } else if (document.webkitExitFullscreen) {
                // @ts-ignore TS2339
                void document.webkitExitFullscreen();
                // @ts-ignore TS2339
            } else if (document.msExitFullscreen) {
                // @ts-ignore TS2339
                document.msExitFullscreen();
            }

            this.fullScreenMode = false;
        } else {
            if (document.documentElement.requestFullscreen) {
                void document.documentElement.requestFullscreen();
                // @ts-ignore TS2339
            } else if (document.documentElement.mozRequestFullScreen) {
                // @ts-ignore TS2339
                void document.documentElement.mozRequestFullScreen();
                // @ts-ignore TS2339
            } else if (document.documentElement.webkitRequestFullscreen) {
                // @ts-ignore TS2339
                void document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                // @ts-ignore TS2339
            } else if (document.body.msRequestFullscreen) {
                // @ts-ignore TS2339
                void document.body.msRequestFullscreen();
            }

            this.fullScreenMode = true;
        }
        this.activeConnection.focus();
    };

    public connectionToServerLost = (): void => {
        void this.$timeout(() => {
            this.isNotConnected = true;
            this.connectionLost = true;
            this.isTryingToConnect = false;
        });
    };

    public sendCtrlAltDelKeyEventToServer = (): void => {
        this.activeConnection.sendCtrlAltDel();
        this.activeConnection.focus();
    };

    public sendEscKeyEventToServer = (): void => {
        this.activeConnection.sendKey(keyCodes.default.XK_Escape, 'Escape');
        this.activeConnection.focus();
    };

    public togglePasteMode = (): void => {
        void this.$timeout(() => {
            this.pasteModeOn = !this.pasteModeOn;
        });
    };

    public pasteText = (): void => {
        this.activeConnection.clipboardPasteFrom(this.clipboardText);
        void this.$timeout(() => {
            this.pasteModeOn = false;
            this.clipboardText = '';
        });
    };
}

export class TemplateMachineVncComponent implements ng.IComponentOptions {
    public bindings = {
        machine: '<'
    };
    public controller = TemplateMachineVncController;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    public template = require('./machine-vnc-template.html');
}
