import { AlphaNumericLowerCase } from '@/configuration';

export class ClientTransactionIdContext {
    private static currentState: string;
    private static resolving = false;
    private static requestCounter = 0;
    private static version = '';

    /**
     * Martin wanted a way to identify all requests made by one continuous
     * instance of the UI.
     */
    private static tabIdentifier: string;

    public static getNextId() {
        if (!ClientTransactionIdContext.tabIdentifier) {
            ClientTransactionIdContext.generateTabIdentifier();
        }

        return [
            'UI',
            ClientTransactionIdContext.version,
            ClientTransactionIdContext.tabIdentifier,
            ClientTransactionIdContext.currentState
            + (ClientTransactionIdContext.resolving ? '-resolve' : ''),
            `${++ClientTransactionIdContext.requestCounter}`
        ].join('-');
    }

    public static setVersion(version: string) {
        ClientTransactionIdContext.version = version;
    }

    public static getVersion() {
        return ClientTransactionIdContext.version;
    }

    public static setState(state: string) {
        ClientTransactionIdContext.currentState = state
            .split('.')
            .map(
                (part) => part
                    .split('-')
                    .map(
                        (subPart) => subPart.slice(0, 3)
                    )
                    .join('-')
            )
            .join('.');
        ClientTransactionIdContext.requestCounter = 0;
    }

    public static getState() {
        return this.currentState;
    }

    public static getRequestCounter() {
        return this.requestCounter;
    }

    public static startResolving() {
        ClientTransactionIdContext.resolving = true;
        ClientTransactionIdContext.requestCounter = 0;
    }

    public static finishResolving() {
        ClientTransactionIdContext.resolving = false;
        ClientTransactionIdContext.requestCounter = 0;
    }

    private static generateTabIdentifier() {
        const characters = AlphaNumericLowerCase;
        const length = 12;

        let id = '';

        while (id.length < length) {
            id += characters.charAt(Math.floor(Math.random() * characters.length));
        }

        ClientTransactionIdContext.tabIdentifier = id;
    }

    static {
        ClientTransactionIdContext.setState('authentication');
    }
}
