import * as ng from 'angular';
import { WizardPromoProduct } from '@/atomic-components';
import { FamilyProductBoxObject, ProductBoxObject } from '../../../../../services/products';
import { BillingHelperService } from '@/services';

import * as Types from '../../../../../types';

export class MoleculeFormProductSelectNewController implements ng.IController {
    public static $inject: string[] = ['billingHelper'];

    public account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    public items: (FamilyProductBoxObject | ProductBoxObject)[];
    public changeCallback: (param: any) => void;
    public promoProductItems: WizardPromoProduct[] = [];
    public preSelectedItem: string;
    public hidePriceInfo = false;
    public doPreselectRecommendedProduct = false;

    private _selectedBox: FamilyProductBoxObject | ProductBoxObject;

    constructor(
        public billingHelper: BillingHelperService
    ){}

    public $onInit() {

        if (this.doPreselectRecommendedProduct) {
            const recommendedBox = this.items.find( (item) => {
                if (item.type === 'ProductBoxObject') {
                    return item.recommend;
                }
            });
            if (recommendedBox) {
                this.boxSelected(recommendedBox);
            }
        }

        if ([undefined, null, ''].indexOf(this.preSelectedItem) < 0) {
            const tmpSelectedBox = this.items.find((item) => {
                if (item.type === 'FamilyProductBoxObject') {
                    return (item ).family === this.preSelectedItem;
                } else {
                    return (item ).productCode === this.preSelectedItem;
                }
            });
            if ( tmpSelectedBox && (this._selectedBox === undefined
                || tmpSelectedBox.type === 'FamilyProductBoxObject') ) {
                this.boxSelected(tmpSelectedBox);
            }
        }

    }

    public boxSelected = (box: FamilyProductBoxObject | ProductBoxObject) => {
        this._selectedBox = box;
        if (this.changeCallback) {
            this.changeCallback(box);
        }
    };

    public isSelectedFamily = (item: FamilyProductBoxObject) => {
        return item === this._selectedBox;
    };

    public isSelectedProduct = (item: ProductBoxObject) => {
        if ([undefined, null].indexOf(this._selectedBox) >= 0) {
            return false;
        }

        return ([undefined, null].indexOf(this._selectedBox) === -1 && item.name === this._selectedBox.name)
            && (item.name === this._selectedBox.name && item.family === this._selectedBox.family);
    };
}

export class MoleculeFormProductSelectNewComponent implements ng.IComponentOptions {
    public controller =  MoleculeFormProductSelectNewController;
    public bindings = {
        account: '<',
        changeCallback: '<?',
        hidePriceInfo: '<?',
        items: '<items',
        promoProductItems: '<',
        preSelectedItem: '<?',
        type: '@',
        doPreselectRecommendedProduct: '<?'
    };
    public template = require('./product-select-new.html');
}
