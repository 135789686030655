import * as ng from 'angular';
import * as q from 'q';
import {
    AccountModelService,
    AuthContextService,
    DomainCheckerService,
    DomainHelperService,
    PriceCacheService,
    ValidateDns
} from '../../../../../services';

import * as Types from '../../../../../types';

export interface WizardVhostDomainObject {
    check: Types.DomainApi.DomainStatusResult;
    orchestratorObject?: Types.OrchestrationApi.Domain;
    prices?: any[];
}

export class OrganCreateConfigurationDomainRegisterTransferController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'accountModel',
        'domainChecker',
        'domainHelper',
        'priceCache'
    ];

    public domainObject: WizardVhostDomainObject;
    public disabled = false;
    public registerOrTransfer = false;
    public reset = false;
    public hasDefaultContacts = true;
    public validationErrors = [];

    private _authCode: string;
    private _accountId: string | null;
    private _reset = false;
    private _contingents: any[];
    private _domainStatusLoading = false;
    private _domainName: string;
    private _possibleDomainStatus = ['available', 'registered'];
    private _defaultContact = '';
    private _domainNameValidator;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService,
        private domainChecker: DomainCheckerService,
        private domainHelper: DomainHelperService,
        private priceCache: PriceCacheService
    ) {
        this._domainNameValidator = new ValidateDns(this.$translate, false);
    }

    public $onInit() {
        this._reset = this.reset;
        if (this.reset) {
            this.disabled = false;
        }
        if ([undefined, null].indexOf(this.domainObject) >= 0
            || Object.keys(this.domainObject).length === 0) {
            this._setDomainObject();
        } else {
            if ([undefined, null].indexOf(this.domainObject) < 0) {
                if (this.domainObject.orchestratorObject.domainNameUnicode !== this._domainName) {
                    this._resetDomainObject();
                } else if (this._possibleDomainStatus.indexOf(this.domainObject.check.status) >= 0) {
                    this.registerOrTransfer = true;
                }
            }
        }
    }

    public $onChanges(changes: any) {
        if (changes._accountId !== undefined) {
            this._checkDefaultContacts(changes._accountId.currentValue);
            this._checkDomainStatus();
        }
    }

    public $doCheck() {
        if (this.reset !== this._reset) {
            if (this.reset) {
                this._resetDomainObject();
            }
            this._reset = ng.copy(this.reset);
        }
    }

    get authCode(): string {
        return this._authCode;
    }

    set authCode(value: string) {
        if (this._authCode !== value) {
            this.domainObject.orchestratorObject.authCode = value;
        }
        this._authCode = value;
    }

    public get completed() {
        if ([undefined, null].indexOf(this.domainObject) >= 0
            || this.registerOrTransfer === false) {
            return true;
        }
        return !(
            this.authCodeNeeded
            && this.domainObject.orchestratorObject.authCode.length < 0
        );
    }

    public set completed({}) {} // tslint:disable-line:no-empty

    public get toggleDisabled() {
        const domainNameValidation = this._domainNameValidator.validate(this._domainName);
        if (domainNameValidation !== undefined
            && domainNameValidation.length > 0
        ) {
            return true;
        }

        return this.disabled
            || this._domainStatusLoading
            || !this.hasDefaultContacts
            || this.validationErrors.length > 0;
    }

    public set toggleDisabled({}) {} // tslint:disable-line:no-empty

    public get checkingDomainStatus() {
        return this._domainStatusLoading;
    }

    public set checkingDomainStatus({}) {} // tslint:disable-line:no-empty

    public get authCodeNeeded() {
        return this.registerOrTransfer
            && [undefined, null].indexOf(this.domainObject) < 0
            && [undefined, null].indexOf(this.domainObject.check) < 0
            && this.domainObject.check.status === 'registered';
    }

    public get hasCheckedStatus() {
        return this.hasDefaultContacts
            && [undefined, null].indexOf(this.domainObject) < 0
            && [undefined, null].indexOf(this.domainObject.check) < 0
            && this.domainObject.check.status !== '';
    }

    public set hasCheckedStatus({}) {} // tslint:disable-line:no-empty

    public changeActionToggle = () => {
        this.$timeout(() => {
            if (this.registerOrTransfer) {
                this._checkDomainStatus();
            } else {
                this._resetDomainObject();
            }
        });
    };

    private _checkDomainStatus = () => {
        if ([undefined, null, ''].indexOf(this._domainName) >= 0
            || [undefined, null].indexOf(this._accountId) >= 0
        ) {
            this._domainStatusLoading = false;
            return;
        }

        if ([undefined, null].indexOf(this.domainObject) < 0
            && [undefined, null].indexOf(this.domainObject.orchestratorObject) < 0
            && this.domainObject.orchestratorObject.domainNameUnicode === this._domainName
        ) {
            // domainObject ist already set and domain was checked
            // (f.e. on click return from summary to configuration page)
            this._domainStatusLoading = false;
            return;
        }

        if (this._domainStatusLoading) {
            return;
        }

        this._domainStatusLoading = true;
        this.domainObject = null;
        // this.validationErrors = [];
        let productCode = 'domain-renew-';
        this._checkDefaultContacts()
            .then(() => this.domainChecker.check([this._domainName], this._accountId))
            .then((domainList) => {
                this._domainStatusLoading = false;
                const domainStatus = domainList.length > 0
                    ? domainList[0]
                    : null;
                // is domain check unequal null
                if (domainStatus !== null) {
                    // domain check status is within possible domain status?
                    if (this._possibleDomainStatus.indexOf(domainStatus.status) >= 0) {
                        productCode = domainStatus.status === 'registered'
                            ? 'domain-' + domainStatus.extension  + '-transfer'
                            : 'domain-' + domainStatus.extension  + '-renew';

                        // set domainObject
                        this._buildDomainObject(domainStatus, productCode);
                        this.disabled = false;
                    } else {
                        this.registerOrTransfer = false;
                        this.disabled = true;
                        this.domainObject = {
                            check: ng.copy(domainStatus)
                        };
                    }
                }
            }
        );
    };

    private _checkDefaultContacts = (accountId?: string) => {
        accountId = accountId || this._accountId;
        // TODO: account chaching ....
        if (accountId !== undefined) {
            return this._getAccount(accountId).then((account) => {
                const contactType = 'defaultContactOwnerId';
                if ([undefined, null, ''].indexOf(account.domainSettings[contactType]) < 0
                    && typeof account.domainSettings[contactType] === 'string'
                    && account.domainSettings[contactType].length >= 10
                ) {
                    this.hasDefaultContacts = true;
                    this._defaultContact = account.domainSettings[contactType];
                } else {
                    this.hasDefaultContacts = false;
                    this._defaultContact = '';
                }

                if (!this.hasDefaultContacts) {
                    this.disabled = true;
                    this.registerOrTransfer = false;
                }
            }) as ng.IPromise<Types.AccountApi.Subaccount>;
        }
    };

    private _getAccount = (accountId: string): PromiseLike<any> => {
        const account = AuthContextService.account.id === accountId
            ?   AuthContextService.account
            : null;

        if (account === null) {
            return this.accountModel.findOne(accountId);
        }

        // Actually, it's Account, not Subaccount... but that's a TODO for another day.
        return q.resolve(account) as any as q.IPromise<Types.AccountApi.Subaccount>;
    };

    private withInContingent = (productCode: string) => {
        if ([undefined, null].indexOf(this._contingents) >= 0) {
            return false;
        }
        return this._contingents.some((contingent: any) => {
            const productCodeFound = contingent.productCodes.some((code: string) => {
                return code === productCode;
            });

            return productCodeFound && contingent.capacity >= 1;
        });
    };

    private _buildDomainObject = (domainStatus: any, productCode: string) => {
        // if root account is logged in, use _accountId of selected account
        const accountId = AuthContextService.account.id === '1'
            ? this._accountId
            : AuthContextService.account.id;
        this.priceCache.listDomainPrices(
            domainStatus.extension,
            accountId
        ).then(
            (priceList) => {
                this.$timeout(
                    () => {
                        const price = this._normalizePrice(priceList, productCode);
                        this._setDomainObject({
                            check: ng.copy(domainStatus),
                            orchestratorObject: {
                                authCode: '',
                                billingMode: price.price === null
                                    ? 'bundle'
                                    : 'standalone',
                                createMode: domainStatus.status === 'available'
                                    ? 'register'
                                    : 'transfer',
                                domainHandle: this._defaultContact,
                                domainNameUnicode: domainStatus.domainNameUnicode,
                                type: 'Domain'
                            },
                            prices: [{
                                // billingCyle: 12,
                                contractPeriod: null,
                                currency: '',
                                platformId: '',
                                price: price,
                                productCode: productCode,
                                service: '',
                                startPIT: '',
                                vatRate: 0
                            }]
                        });
                    }
                );
            }
        );
    };

    private _normalizePrice = (priceList: any, productCode: string) => {
        let priceObject: any;
        priceList.some((price: any) => {
            if (price.productCode === productCode) {
                if ([undefined, null].indexOf(this._contingents)
                    && this.withInContingent(productCode)
                ) {
                    price.price = null;
                }
                priceObject = price;
                return true;
            }

            return false;
        });

        return priceObject;
    };

    private _setDomainObject = (object?: WizardVhostDomainObject) => {
        object = object || {
            check: {
                domainName: '',
                domainNameUnicode: '',
                domainSuffix: '',
                extension: '',
                status: ''
            },
            orchestratorObject: {
                authCode: '',
                billingMode: 'standalone',
                createMode: 'register',
                domainHandle: null,
                domainNameUnicode: '',
                type: 'Domain'
            },
            prices: []
        };
        this.domainObject = object;
    };

    private _resetDomainObject = () => {
        this._reset = this.reset = false;
        this.hasCheckedStatus = false;
        this.registerOrTransfer = false;
        this.domainObject = null;
        this._checkDefaultContacts();
    };
}

export class OrganCreateConfigurationDomainRegisterTransferComponent implements ng.IComponentOptions {
    public bindings = {
        _accountId: '<accountId',
        _contingents: '<?contingents',
        _domainName: '<domainName',
        authCode: '=?',
        completed: '=',
        disabled: '<',
        domainObject: '=',
        reset: '=?',
        validationErrors: '='
    };

    public template = require('./register-transfer-config.html');
    public controller =  OrganCreateConfigurationDomainRegisterTransferController;
}
