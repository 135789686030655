import * as ng from 'angular';

export class Join {
    public static $inject: string[] = [];

    public static Factory() {
        return (input: any, separator: string) => {
            if (!Array.isArray(input)) {
                return input;
            }
            separator = separator || ', ';
            return input.join(separator);
        };
    }
}
