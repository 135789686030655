import * as ng from 'angular';
import { LinkListItem, PanelHeaderData } from '@/atomic-components';
import { UiLanguagesConst } from '@/configuration';
import { UiRights } from '@/configuration';
import {
    AuthContextService,
    ManagedApplicationRobotService,
    ProductsModelService
} from '@/services';
import * as Types from '@/types';

export class OrganDashboardWidgetProductNextcloudController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'managedApplicationRobot',
        'productsModel'
    ];

    public demoData: Record<string, unknown>;
    public instanceUrl = '';
    public isFreeProduct: boolean;
    public linkListFooter: LinkListItem[] = [];
    public nextcloud: Types.ManagedApplicationApi.Nextcloud;
    public panelHeader: PanelHeaderData = {
        allProductsQuantity: 0,
        includeShowAllLink: false,
        includeShowDetailsLink: false,
        panelHeaderAllRoute: '',
        panelHeaderAllRouteParams: {},
        panelHeaderRoute: '',
        panelHeaderRouteParams: {},
        panelIcon: '/assets/images/logos/nextcloud-bw.svg',
        panelIconPath: true,
        panelTitle: ''
    };
    public productQuantity = 0;

    private _productExists: boolean;
    private _productLoaded: boolean;

    constructor(
        protected $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        private managedApplicationRobot: ManagedApplicationRobotService,
        private productModel: ProductsModelService
    ) {
        this.isFreeProduct = false;
        this._productLoaded = false;
        this._productExists = false;
    }

    public $onInit(): void {

        if (
            !AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_LIST)
            || AuthContextService.isRootOrCompanyAccount
        ) {
            this._productLoaded = true;
            return;
        }

        void this._getLastModifiedProducts()
            .then(this._getProductInfo)
            .then(this._setLinkList)
            .then(this._finishLoadingData);
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set productLoaded({}) {}
    public get productLoaded(): boolean {
        return this._productLoaded;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set productExists({}) {}
    public get productExists(): boolean {
        return this._productExists;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set productName({}) {}
    public get productName(): string {
        if (!this.productLoaded) {
            return '';
        }

        if (!this.productExists) {
            return this.$translate.instant('TR_290121-809b91_TR');
        }

        return this.nextcloud.name;
    }

    private _getLastModifiedProducts = (): PromiseLike<Types.ManagedApplicationApi.Nextcloud> => {
        const filter = { field: 'accountId', value: AuthContextService.account.id };
        const sort = { field: 'NextcloudLastChangeDate', order: 'DESC' };

        return this.managedApplicationRobot
            .nextcloudsList(filter, null, 1, sort)
            .then(
                (productRes) => {
                    this.productQuantity = productRes.response.data.length;
                    if (this.productQuantity > 0) {
                        this.nextcloud = productRes.response.data[0];
                        this._productExists =  true;
                    } else if (this.demoData) {
                        this.nextcloud = this.demoData.nextcloud as Types.ManagedApplicationApi.Nextcloud;
                        this.productQuantity = 4;
                        this._productExists =  true;
                    }

                    return this.nextcloud;
                }
            );
    };

    private _getProductInfo = (): Promise<boolean> | PromiseLike<boolean> => {
        if (!this.nextcloud) {
            return Promise.resolve(false);
        }

        this.panelHeader.includeShowDetailsLink = true;
        this.panelHeader.includeShowAllLink = true;
        this.panelHeader.panelHeaderRoute = 'nextcloud.id.edit';
        this.panelHeader.panelHeaderRouteParams = { storageProductId: this.nextcloud.id };
        this.panelHeader.panelHeaderAllRoute = 'storage.storage-products.overview';
        if (this.productQuantity > 0) {
            this.panelHeader.allProductsQuantity = this.productQuantity;
        }

        return this.productModel.findOne(
            this.nextcloud.productCode,
            UiLanguagesConst[AuthContextService.user.language],
            true
        )
            .then(
                (ApiResponse) => {
                    this.isFreeProduct = ApiResponse.response.specificationItems.some(
                        (specItem) => {
                            return specItem.name === 'isFreeProduct'
                                && specItem.value;
                        }
                    );

                    return true;
                }
            );
    };

    private _setLinkList = (hasProductInfo: boolean): Promise<boolean | void> => {
        if (!hasProductInfo) {
            return Promise.resolve(false);
        }

        this.instanceUrl = 'https://'
            + (
                this.nextcloud.individualDomainNameStatus === 'active'
                    ? this.nextcloud.individualDomainNameUnicode
                    : this.nextcloud.defaultDomainName
            );

        return Promise.resolve();
    };

    private _finishLoadingData = (): void => {
        void this.$timeout(() => {
            this._productLoaded = true;
        });
    };
}

export class OrganDashboardWidgetProductNextcloudComponent implements ng.IComponentOptions {
    public bindings = {
        demoData: '<?'
    };
    public controller =  OrganDashboardWidgetProductNextcloudController;
    public template = require('./dashboard-widget-product-nextcloud.html');
}
