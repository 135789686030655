import { LanguageTypes } from '@/services/translate';

export const UiLanguagesConst: Record<string, LanguageTypes> = {
    de: 'de_DE',
    en: 'en_US'
};

export const UiLanguagesReverseConst: Record<'de_DE' | 'en_US' | string, string> = {
    de_DE: 'de',
    en_US: 'en'
};

export const UiLanguageSwitchConst: Record<'de' | 'en' | string, { translationId: string }> = {
    de: { translationId: 'ACCOUNT.PROFILE.LANGUAGE.OPTIONS.GERMAN' },
    en: { translationId: 'ACCOUNT.PROFILE.LANGUAGE.OPTIONS.ENGLISH' }
};

export const UiLanguageDefaultSettingsConst = {
    languageApiIso: 'de',
    languageUiIso: 'de_DE',
    translationId: 'ACCOUNT.PROFILE.LANGUAGE.OPTIONS.GERMAN'
};

export const SHORT_TIMEOUT_REFRESH_INTERVAL = 2000;
export const DEFAULT_TIMEOUT_REFRESH_INTERVAL = 5000;

export const ShortDebounceInterval = 300;
export const LongDebounceInterval = 700;

export type ContractPeriodWord = 'none'
    | 'monthly'
    | 'quarterly'
    | 'semiannually'
    | 'annually'
    | 'biennially'
    | 'triennially'
    | 'quadriennally'
    | 'fiveyearly'
    | 'tenyearly';

export type ContractPeriodNumber = 0 | 1 | 3 | 6 | 12 | 24 | 36 | 48 | 60 | 120;

export const CycleBillingTranslationConst = {
    none: '',
    monthly: /* translationId */ 'TR_240119-4877d1_TR',
    quarterly: /* translationId */ 'TR_240119-aa038c_TR',
    semiannually: /* translationId */ 'TR_240119-46930d_TR',
    annually: /* translationId */ 'TR_240119-455e41_TR',
    biennially: /* translationId */ 'TR_240119-a59a1a_TR',
    triennially: /* translationId */ 'TR_300620-a73c5f_TR',
    quadriennally: /* translationId */ 'TR_300620-0b4b74_TR',
    fiveyearly: /* translationId */ 'TR_300620-e2892e_TR',
    tenyearly: /* translationId */ 'TR_300620-6553b3_TR'
};

export const ContractPeriodeToBillingCycleConst: { [key: number]: ContractPeriodWord } = {
    0: 'none',
    1: 'monthly',
    3: 'quarterly',
    6: 'semiannually',
    12: 'annually',
    24: 'biennially',
    36: 'triennially',
    48: 'quadriennally',
    60: 'fiveyearly',
    120: 'tenyearly'
};

export const BillingCycleToContractPeriodeConst: { [key: string]: ContractPeriodNumber } = {
    none: 0,
    monthly: 1,
    quarterly: 3,
    semiannually: 6,
    annually: 12,
    biennially: 24,
    triennially: 36,
    quadriennally: 48,
    fiveyearly: 60,
    tenyearly: 120
};

export const InputPlaceholderConst = {
    address: {
        city: 'Musterstadt',
        country: 'Musterland',
        street: 'Musterstraße 1',
        zipCode: '12345'
    },
    basic: {
        Org: 'Example Organisation',
        name: 'Max Mustermann'
    },
    contact: {
        email: 'user@example.com',
        fax: '+49-123-456789',
        tel: '+49 1234 56789'
    },
    date: 'DD/MM/YYYY',
    domains: {
        domain: 'example.com',
        domainMX: 'example.com / imap.example.com',
        protocol: 'http(s)://example.com/',
        subdomain: 'server.example.com',
        subdomainProtocol: 'https://server.example.com/'
    },
    ip: {
        v4: '93.184.216.34',
        v6: '2606:2800:220:1:248:1893:25c8:1946'
    },
    login: {
        account: 'Admin',
        password: 'Min. 8 Zeichen (Aa/09/?&#)',
        twoFa: '012345'
    },
    nameserver: 'NS1.example.com, NS2.example.com',
    payment: {
        IBAN: 'DE00 0000 0000 0000 0000 00'
    },
    ssh: {
        certificate: '-----BEGIN CERTIFICATE-----\n[...]\n-----END CERTIFICATE-----',
        rsaKey: 'ssh-rsa [...]',
        rsaPrivateKey: '-----BEGIN RSA PRIVATE KEY-----\n[...]\n-----END RSA PRIVATE KEY-----'
    }
};

export const StaffConst = {
    kahrs: {
        name: 'Jean Kahrs',
        title: 'DevOps'
    }
};

export const UiGenderConst: any = {
    female: { translationId: 'TR_100119-e70c8f_TR' },
    male: { translationId: 'TR_100119-27a1b5_TR' }
};

export const BillingCurrencyConst = 'EUR';

export const ProductContingentsObject = {
    serviceList: ['bundle', 'database', 'email', 'webhosting']
};

export const HostingIp = '213.160.69.3';

export const SystemDateMinYearConst = 99;
export const SystemDateDomainReportMinYearConst = 1996;
export const SystemDateDomainContactIdentityCartMinYearConst = 20;
