import * as ng from 'angular';
import { UiRights } from '../../../../configuration';
import { AuthContextService } from '../../../../services';

export class MoleculePanelMachineEmptyDashboardController implements ng.IController {
    public static $inject: string[] = ['authContext', '$translate'];

    public linkListFooter = [
        {
            text: this.$translate.instant('TR_140119-9b5fa4_TR'),
            route: 'machine.virtualmachines.new',
            isGranted: this.authContext.isGranted(UiRights.BIL_BUNDLE_CREATE)
        }
    ];

    constructor(
        private authContext: AuthContextService,
        protected $translate: ng.translate.ITranslateService
     ) {}
}

export class MoleculePanelMachineEmptyDashboardComponent implements ng.IComponentOptions {
    public controller =  MoleculePanelMachineEmptyDashboardController;
    public template = require('./panel-machine-empty-dashboard.html');
}
