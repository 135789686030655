import ng from 'angular';
import q from 'q';
import {
    DomainTypes
} from '@/atomic-components/organs/create/configuration/domain/domain-type-selection';
import * as Types from '@/types';
import { DnsTemplateModelService } from './template-model';

export interface TemplateInformationObject {
    records: Types.DnsApi.Record[];
    availableReplacements: TemplateAvailableReplacementsObject;
}

export interface TemplateAvailableReplacementsObject {
    ipv4: boolean;
    ipv6: boolean;
    mxv4: boolean;
    mxv6: boolean;
}
export interface DnsUpdateRecordsObject {
    addRecords: Types.DnsApi.Record[];
    deleteRecords: Types.DnsApi.Record[];
    modifyRecords: Types.DnsApi.Record[];
}
export interface DnsUpdateDataObject {
    existendRecords: Types.DnsApi.Record[];
    zone: Types.DnsApi.ZoneConfig;
    nameservers: DnsNameserverInfoObject[];
    dnsSetting: {
        type: string;
        values: Types.ViewTypes.DomainWizardDnsSettingsObject;
    };
}
export interface DnsNameserverInfoObject {
    name: string;
    ips: string[];
    ipsRequired: boolean;
}

interface DnsSettingObject {
    serverIpv4: string;
    serverIpv6: string;
}

export class DnsHelperService {
    public static $inject: string[] = ['dnsTemplateModel'];

    constructor(
        private dnsTemplateModel: DnsTemplateModelService
    ) {}

    public getAvailableReplacements = (templateRecords: Types.DnsApi.Record[]): TemplateAvailableReplacementsObject => {
        const availableReplacements = {ipv4: false, ipv6: false, mxv4: false, mxv6: false};
        // eslint-disable-next-line @typescript-eslint/unbound-method
        return templateRecords.reduce(this.findRequiredReplacements, availableReplacements);
    };

    public getTemplateInformation = (templateId: string): PromiseLike<TemplateInformationObject> => {
        if (!templateId) {
            const result = {
                records: [] as Types.DnsApi.Record[],
                availableReplacements: {ipv4: false, ipv6: false, mxv4: false, mxv6: false}
            };

            return q.resolve(result);
        }

        return void this.dnsTemplateModel.findRecords(templateId)
            .then(this.onTemplateRecordData);
    };

    public getRecordValidationType = (recordType: string): string => {
        switch (recordType) {
            case 'A':       return 'ipv4';
            case 'AAAA':    return 'ipv6';
            case 'CAA':     return 'caa';
            case 'SRV':     return 'srv';
            case 'TLSA':    return 'tlsa';
            case 'MX':
            case 'NS':
            case 'CNAME':
            case 'ALIAS':
            case 'PTR':     return 'dns';
            default:        return 'notEmpty';
        }
    };

    public getZoneRecordsToUpdate = (
        dnsData: DnsUpdateDataObject,
        webspace: Types.WebhostingApi.Webspace,
        vhostName: string,
        domainType?: DomainTypes,
        enableAlias?: boolean
    ): DnsUpdateRecordsObject => {
        let addRecords: Types.DnsApi.Record[] = [];
        let deleteRecords: Types.DnsApi.Record[] = [];
        let modifyRecords: Types.DnsApi.Record[] = [];
        domainType = domainType || DomainTypes.REGISTER;

        if (['quick', 'slave', 'template', 'webspace'].includes(dnsData?.dnsSetting?.type)) {
            const dnsSetting: DnsSettingObject = {
                serverIpv4: null,
                serverIpv6: null
            };

            switch (dnsData?.dnsSetting?.type) {
                case 'quick':
                    dnsSetting.serverIpv4 = dnsData.dnsSetting.values.ip;
                    break;
                case 'slave':
                    dnsSetting.serverIpv4 = dnsData.dnsSetting.values.masterIp;
                    break;
                case 'template':
                    dnsSetting.serverIpv4 = this.getIpv4FromTemplateReplacements(dnsData.dnsSetting.values);
                    dnsSetting.serverIpv6 = this.getIpv6FromTemplateReplacements(dnsData.dnsSetting.values);
                    break;
                case 'webspace':
                    dnsSetting.serverIpv4 = webspace.serverIpv4;
                    dnsSetting.serverIpv6 = webspace.serverIpv6;
            }

            // check zone a records
            const aRecords = this.updateZoneARecordsForWebspace(
                dnsData.existendRecords,
                dnsData.zone,
                dnsSetting,
                vhostName,
                enableAlias
            );

            addRecords = addRecords.concat(aRecords.addRecords);
            deleteRecords = deleteRecords.concat(aRecords.deleteRecords);
            modifyRecords = modifyRecords.concat(aRecords.modifyRecords);
        }

        if (domainType === DomainTypes.REGISTER && dnsData.nameservers) {
            // check ns records
            const nsRecords = this.compareZoneNsRecords(
                dnsData.existendRecords.filter((record) => record.type === 'NS'),
                dnsData.nameservers,
                dnsData.zone
            );
            addRecords = addRecords.concat(nsRecords.addRecords);
            deleteRecords = deleteRecords.concat(nsRecords.deleteRecords);
            modifyRecords = modifyRecords.concat(nsRecords.modifyRecords);
        }

        return {
            addRecords: addRecords,
            deleteRecords: deleteRecords,
            modifyRecords: modifyRecords
        };
    };

    public recordsAvailableForUpdate = (zoneRecordsToUpdate: DnsUpdateRecordsObject): boolean => {
        return zoneRecordsToUpdate.addRecords.length > 0
            || zoneRecordsToUpdate.deleteRecords.length > 0
            || zoneRecordsToUpdate.modifyRecords.length > 0;
    };

    /* eslint-disable complexity */
    public updateZoneARecordsForWebspace = (
        existendRecords: Types.DnsApi.Record[],
        zone: Types.DnsApi.ZoneConfig,
        dnsSetting: DnsSettingObject,
        vhostName: string,
        enableAlias: boolean = false
    ): DnsUpdateRecordsObject => {
        const addRecords: Types.DnsApi.Record[] = [];
        const modifyRecords: Types.DnsApi.Record[] = [];
        const deleteRecords: Types.DnsApi.Record[] = [];

        if (dnsSetting && zone) {
            const startWithWww: boolean = vhostName.indexOf('www.') === 0;
            const recordName = vhostName; // zone.name;
            const issetWebspaceServerIpv4 = [undefined, null, ''].indexOf(dnsSetting.serverIpv4) < 0;
            const issetWebspaceServerIpv6 = [undefined, null, ''].indexOf(dnsSetting.serverIpv6) < 0;

            let foundARecord = false;
            let foundARecordWWW = false;
            let foundAAAARecord = false;
            let foundAAAARecordWWW = false;

            for (const record of existendRecords) {
                let recordModified = false;

                switch (record.type) {
                    case 'A':
                        if (record.name === recordName) {
                            if (!startWithWww) {
                                foundARecord = true;
                            } else {
                                foundARecordWWW = true;
                            }

                            if (
                                issetWebspaceServerIpv4
                                && record.content !== dnsSetting.serverIpv4
                            ) {
                                record.content = dnsSetting.serverIpv4;
                                recordModified = true;
                            }
                        }

                        if (
                            record.name.indexOf('www.') === 0
                            && enableAlias
                            && record.name.replace('www.', '') === recordName
                        ) {
                            // Update a record with starting www. only if enabledAlias
                            // Otherwise, the a record is left as it is
                            foundARecordWWW = true;
                            if (
                                issetWebspaceServerIpv4
                                && record.content !== dnsSetting.serverIpv4
                                && enableAlias
                            ) {
                                record.content = dnsSetting.serverIpv4;
                                recordModified = true;
                            }
                        }
                        break;
                    case 'AAAA':
                        if (record.name === recordName) {
                            if (!startWithWww) {
                                foundAAAARecord = true;
                            } else {
                                foundAAAARecordWWW = true;
                            }
                            if (
                                issetWebspaceServerIpv6
                                && record.content !== dnsSetting.serverIpv6
                            ) {
                                record.content = dnsSetting.serverIpv6;
                                recordModified = true;
                            }
                        }

                        if (
                            record.name.indexOf('www.') === 0
                            && enableAlias
                            && record.name.replace('www.', '') === recordName
                        ) {
                            // Update a record with starting www. only if enabledAlias
                            // Otherwise, the a record is left as it is
                            foundAAAARecordWWW = true;
                            if (issetWebspaceServerIpv6
                                && record.content !== dnsSetting.serverIpv6
                                && enableAlias
                            ) {
                                record.content = dnsSetting.serverIpv6;
                                recordModified = true;
                            }
                        }
                        break;
                }

                if (recordModified) {
                    modifyRecords.push(record);
                }
            }

            if (
                !foundARecord
                && issetWebspaceServerIpv4
            ) {
                // set required a records
                addRecords.push({
                    accountId: zone.accountId,
                    content: dnsSetting.serverIpv4,
                    name: recordName,
                    ttl: 86400,
                    type: 'A',
                    zoneConfigId: zone.id
                });
            }

            if (
                !foundARecordWWW
                && issetWebspaceServerIpv4
                && enableAlias
            ) {
                // set required a records
                addRecords.push({
                    accountId: zone.accountId,
                    content: dnsSetting.serverIpv4,
                    name: 'www.' + recordName,
                    ttl: 86400,
                    type: 'A',
                    zoneConfigId: zone.id
                });
            }

            if (
                !foundAAAARecord
                && issetWebspaceServerIpv6
            ) {
                // set required aaaa records
                addRecords.push({
                    accountId: zone.accountId,
                    content: dnsSetting.serverIpv6,
                    name: recordName,
                    ttl: 86400,
                    type: 'AAAA',
                    zoneConfigId: zone.id
                });
            }

            if (
                !foundAAAARecordWWW
                && issetWebspaceServerIpv6
                && enableAlias
            ) {
                // set required aaaa records
                addRecords.push({
                    accountId: zone.accountId,
                    content: dnsSetting.serverIpv6,
                    name: 'www.' + recordName,
                    ttl: 86400,
                    type: 'AAAA',
                    zoneConfigId: zone.id
                });
            }
        }

        return {
            addRecords: addRecords,
            deleteRecords: deleteRecords,
            modifyRecords: modifyRecords
        };
    };

    public getIpv4FromTemplateReplacements = (
        dnsSettingValues: Types.ViewTypes.DomainWizardDnsSettingsObject
    ): string => {
        if (!dnsSettingValues.replacements) {
            return null;
        }

        return !dnsSettingValues.replacements.ipv4Replacement
            ? null
            : dnsSettingValues.replacements.ipv4Replacement;
    };

    public getIpv6FromTemplateReplacements = (
        dnsSettingValues: Types.ViewTypes.DomainWizardDnsSettingsObject
    ): string => {
        if (!dnsSettingValues.replacements) {
            return null;
        }

        return !dnsSettingValues.replacements.ipv6Replacement
            ? null
            : dnsSettingValues.replacements.ipv6Replacement;
    };

    /* eslint-enable complexity */
    public compareZoneNsRecords = (
        oldNsRecords: Types.DnsApi.Record[],
        nameservers: DnsNameserverInfoObject[],
        zone: Types.DnsApi.ZoneConfig
    ): DnsUpdateRecordsObject => {
        /**
         * Here we need to compare the 'old' nameserver records in the zone
         * with the domain's nameservers and accordingly determine which existing
         * entries need to be midified, added or deleted.
         */
        const addRecords = [];
        const modifyRecords = [];
        const deleteRecords  = [];

        let priority = null;
        let ttl = 86400;
        let recordTemplateId = null;

        // Checking the existing ns records of the existing zone that need to be modified or deleted
        for (const [index, record] of Object.entries(oldNsRecords)) {
            if (+index === 0) {
                // Set default values for any new ns records from the first existing entry
                priority = ng.copy(record.priority);
                ttl = ng.copy(record.ttl);
                recordTemplateId = ng.copy(record.recordTemplateId);
            }

            // Check if existing ns record is present in domain nameserver
            if (!nameservers.some((nameserver) => record.content === nameserver.name)) {
                if ((+index + 1) <= nameservers.length) {
                    // As long as the set of existing ns records is smaller than the domain nameserver,
                    // we modify the existing ns record.
                    record.content = nameservers[+index].name;
                    modifyRecords.push(ng.copy(record));
                } else {
                    // If there are more existing ns records than domain nameservers, we have to remove the record.
                    deleteRecords.push(record);
                }
            }
        }

        if (nameservers.length > oldNsRecords.length) {
            // If there are more domain nameservers than existing ns records,
            // we need check and add these ns records to the zone.
            for (const nameserver of nameservers) {
                const recordPresentInModifiedList = modifyRecords.some((record) => record.content === nameserver.name);
                const recordPresentInDeleteList = deleteRecords.some((record) => record.content === nameserver.name);
                const recordPresentInOldRecordsList = oldNsRecords.some((record) => record.content === nameserver.name);

                if (
                    !recordPresentInModifiedList
                    && !recordPresentInDeleteList
                    && !recordPresentInOldRecordsList
                ) {
                    addRecords.push({
                        content: nameserver.name,
                        name: zone.name,
                        priority: priority,
                        recordTemplateId: recordTemplateId,
                        ttl: ttl,
                        type: 'NS',
                        zoneConfigId: zone.id
                    });
                }
            }
        }

        return {
            addRecords: addRecords,
            deleteRecords: deleteRecords,
            modifyRecords: modifyRecords
        };
    };

    private findRequiredReplacements (
        replacements: TemplateAvailableReplacementsObject,
        elem: {content: string }
    ): TemplateAvailableReplacementsObject {
        const content = elem.content;

        if (elem.content === undefined) {
            return replacements;
        }

        /* eslint-disable no-bitwise */
        replacements.ipv4 |= content.includes('##IPV4##');
        replacements.ipv6 |= content.includes('##IPV6##');
        replacements.mxv4 |= content.includes('##MX_IPV4##');
        replacements.mxv6 |= content.includes('##MX_IPV6##');
        /* eslint-enable no-bitwise */

        return replacements;
    }

    private onTemplateRecordData = (
        templateRecords: Types.DnsApi.Record[]
    ): TemplateInformationObject => {
        const availableReplacements = this.getAvailableReplacements(templateRecords);
        return {
            records: templateRecords,
            availableReplacements: availableReplacements
        };
    };
}
