import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels';

export class TemplateMachineOsInstallController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;
    public machine: any;
    public operatingSystems: any[];

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'machine.virtualmachines.id.edit',
            backwardText: this.$translate.instant('TR_140119-0389cf_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: 'server',
            panelTitle: this.machine.name
        };
    };
}

export class TemplateMachineOsInstallComponent implements ng.IComponentOptions {
    public bindings = {
        machine: '<',
        operatingSystems: '<'
    };
    public template = require('./machine-os-install-template.html');
    public controller = TemplateMachineOsInstallController;
}
