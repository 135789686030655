import * as ng from 'angular';

export class MoleculeInputTextVoucherController implements ng.IController {
    public value: string;
    public customCallbackOnEnter: () => {};
    public inputType = 'voucher';
    public validationErrorList: any[];
    public inputId: string;
    public callbackOnClear: (arg0: any) => any;
    public callbackOnClearParams: any;
    public clearable: boolean;

    public onPaste = (paste) => {
        // implement callback on paste here when needed!
    };
}

export class MoleculeInputTextVoucherComponent implements ng.IComponentOptions {
    public bindings = {
        value: '=',
        customCallbackOnEnter: '<',
        validationErrorList: '=?',
        inputId: '@?',
        callbackOnClear: '<',
        callbackOnClearParams: '<',
        clearable: '<'
    };
    public controller = MoleculeInputTextVoucherController;
    public template = require('./input-text-with-validation-wrapper.html');
}
