/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty-pattern */
import * as ng from 'angular';

import { UiRights } from '@/configuration';
import {
    AccountModelService,
    AuthContextService,
    BundleModelService,
    DomainHelperService,
    NavigationService
} from '@/services';
import * as Types from '@/types';

import './available-domains-list.scss';

export class MoleculeListsAvailableDomainsListController implements  ng.IController {
    public static $inject: string[] = [
        '$scope',
        '$state',
        '$stateParams',
        '$timeout',
        'accountModel',
        'bundleModel',
        'domainHelper',
        'navigation'
    ];

    public accountId = '';
    public domains: Types.ViewTypes.WizardDomainObject[];
    public selectedDomains: Types.ViewTypes.WizardSelectedDomainObject[];
    public numberOfSelectedDomains: number;
    public bundle: Types.BundleApi.Bundle;
    public bundleTlds: string[];
    public whoisDomainName: null|string = null;
    public isCommercialCustomer = false;
    public contingentItems: Types.ViewTypes.WizardDomainContingentObject[] = [];
    public authCodeDummy: any = {};
    public selectedContingent: any;

    /* tslint:disable-next-line:variable-name */
    private onListChangedCallback: (arg0: any) => void;
    private _bundle: Types.BundleApi.Bundle;
    private _domainSearch: string;
    private _initialiaztionCompleted = false;
    private _subAccount: Types.AccountApi.Subaccount = null;
    private _lastShowPriceColumnValue = false;
    private _loadingData = false;
    private _loadingSubAccountObject = false;

    private _domains: any[] = [];
    private _selectedDomains: any[] = [];
    private _domainsHasDeselected: string[] = [];

    constructor(
        public $scope: ng.IScope,
        public $state: ng.ui.IStateService, // do not remove! Used in template!
        private $stateParams: ng.ui.IStateParamsService,
        private $timeout: ng.ITimeoutService,
        private accountModel: AccountModelService,
        private bundleModel: BundleModelService,
        private domainHelper: DomainHelperService,
        private navigation: NavigationService
    ) {}

    public $onInit(): void {
        this.$scope.$watch(() => this.selectedContingent?.type, this._setDomainInBundleStatus);
        void this.$timeout(
            () => {
                if (this._bundle) {
                    void this.bundleModel.findOne(this._bundle.id)
                        .then(
                            (bundle) => {
                                this.bundle = bundle;
                                this._initComponent();
                            }
                        );
                }

                this._initComponent();
            },
            100
        );
    }

    public $onChanges(changes: ng.IOnChangesObject): void {
        this._checkDomainSearch();

        if (changes.accountId !== undefined && !changes.accountId.isFirstChange()) {
            if (AuthContextService.isGranted(UiRights.ACC_SUBACCOUNT_LIST)) {
                this._setSelectedSubAccountObject(changes.accountId.currentValue);
            }
        }

        if (changes.selectedContingent) {
            if (changes.selectedContingent.currentValue?.misc?.bundle) {
                this.bundle = changes.selectedContingent.currentValue.misc.bundle;
                this._setDomainInBundleStatus();
            } else {
                this._setDomainInBundleStatus();
            }
        }
    }

    public set loadingData({}) {}
    public get loadingData(): boolean {
        return !this._initialiaztionCompleted
            && !this._loadingData;
    }

    public get showTransferTagHint(): boolean {
        if ([undefined, null].indexOf(this.domains) >= 0) {
            return false;
        }

        return this.domains.some(
            (domain) => domain.domain.transferMethod === 'pushTag'
        );
    }

    // ToDo: Use instead domain price component
    public getPrice = (
        domain: any,
        promo?: boolean,
        premium?: boolean,
        priceField: any = null,
        alternativeCurrency?: boolean
    ): string => {
        return this.domainHelper.getPrice(
            domain,
            promo,
            premium,
            priceField,
            alternativeCurrency,
            this.isCommercialCustomer ? 'net' : 'gross'
        );
    };

    public alternativeCurrency = (domain: any): boolean => {
        const price = this.domainHelper.getPriceObject(domain, domain.prices);
        if ([undefined, null].indexOf(price) >= 0) {
            return false;
        }

        return price.exchangeRatio !== null;
    };

    public isSelected = (domain: any): boolean => {
        for (const selectedDomain of this.selectedDomains) {
            if (selectedDomain.domainName === domain.domain.domainNameUnicode) {
                return true;
            }
        }

        if (this._domainsHasDeselected.indexOf(domain.domain.domainNameUnicode) < 0
            && domain.domain.authCode !== undefined && domain.domain.authCode.length > 0
        ) {
            this.select(domain);
            return true;
        }

        return false;
    };

    public callbackOnBlurAuthcode = (params: any): void => {
        this.selectedDomains.some(
            (domain) => {
                if (domain.domainName === params.domain.domainName) {
                    domain.domainObject.domain.authCode = params.value;
                    return true;
                }
                return false;
            }
        );

        params.domain.authCode = params.value;
    };

    public select = (domain: any): void => {
        // Only add domain when domain is not alreadySelected and status = available or authcode is given
        if (
            this._domainAlredySelected(domain)
            || ['alreadyRegistered', 'extensionDoesNotExist'].indexOf(domain.domain.status) >= 0
            || domain.domain.status === 'nameTooShort'
            || domain.domain.status === 'extensionCannotBeRegistered'
            || (
                domain.domain.status === 'registered'
                && [undefined, null, 'pushTag'].indexOf(domain.domain.transferMethod) < 0
                && (
                    [undefined, null, ''].indexOf(domain.domain.authCode) >= 0
                    && [undefined, ''].indexOf(this.authCodeDummy[domain.domain.domainNameUnicode]) >= 0
                )
            )
        ) {
            return;
        }

        this._domainsHasDeselected = this._domainsHasDeselected.filter((domainName) => {
            return domainName !== domain.domain.domainNameUnicode;
        });

        this.selectedDomains.push({
            amount: 1,
            authCode: domain.domain.authCode,
            domainName: domain.domain.domainNameUnicode,
            domainNameAce: domain.domain.domainName,
            domainObject: domain,
            inBundle: domain.inBundle,
            label: domain.domain.domainName,
            price: this.domainHelper.getPriceObject(domain.domain, domain.prices),
            status: domain.domain.status,
            tld: domain.domain.domainSuffix
        });
        this.numberOfSelectedDomains ++;
        this._selectedListHasChanged();
    };

    public disableAddButton = (domain: any): boolean => {
        return !(
            domain.domain.status === 'available'
            || domain.domain.status === 'canNotCheck'
            || (
                domain.domain.status === 'registered'
                && (
                    [undefined, null, 'pushTag'].indexOf(domain.domain.transferMethod) >= 0
                    || (
                        domain.domain.authCode !== undefined
                        && (
                            domain.domain.authCode.length > 0
                            || this.authCodeDummy[domain.domain.domainNameUnicode]?.length > 0
                        )
                    )
                )
            )
        );
    };

    public deselect = (domain: any): void => {
        for (let i = 0; i < this.selectedDomains.length; i++) {
            if (this.selectedDomains[i].domainName === domain.domain.domainNameUnicode) {
                this._domainsHasDeselected.push(domain.domain.domainNameUnicode);
                this.selectedDomains.splice(Number(i), 1);
            }
        }

        this.numberOfSelectedDomains --;
        this._selectedListHasChanged();
    };

    public hasPromo = (domain: any): boolean => {
        const price = this.domainHelper.getPriceObject(domain, domain.prices);

        return price !== undefined
        && price.promotionalPrice !== undefined
        && price.promotionalPrice !== null;
    };

    public hasPremium = (domain: any): boolean => {
        return [undefined, null].indexOf(domain.domain.premiumPrices) < 0;
    };

    public get showBundleOptions(): boolean {
        return [undefined, null].indexOf(this.bundle) === -1;
    }

    public get showPriceColumn(): boolean {
        if (AuthContextService.isRootOrCompanyAccount) {
            return this._lastShowPriceColumnValue;
        }
        this._lastShowPriceColumnValue = AuthContextService.isGranted(UiRights.BIL_LIST_ARTICLE_PRICES);
        return this._lastShowPriceColumnValue;
    }

    public set showPriceColumn({}) {} // tslint:disable-line:no-empty

    public showWhois = (domain: any): boolean => {
        return domain.domain.status === 'registered' && ['de'].indexOf(domain.domain.extension) >= 0;
    };

    public getWhois = (domain: any): void => {
        this.whoisDomainName = null;
        void this.$timeout(() => this.whoisDomainName = domain.domain.domainName);
    };

    public gotoEditPage = (domain: any): void => {
        void this.navigation.go('domain.domains.id.edit', { domainId: domain.domain.id });
    };

    public domainIsAvailable = (domain: any): boolean => {
        return ['available', 'registered', 'restorable'].indexOf(domain.domain.status) >= 0;
    };

    private _initComponent = (): void => {
        this.isCommercialCustomer = AuthContextService.account.isCommercialCustomer;
        if ([undefined, null].indexOf(this.$stateParams.domainList) < 0) {
            this.domains = this.$stateParams.domainList;
        }

        if ([undefined, null].indexOf(this.$stateParams.selectedDomains) < 0) {
            this.selectedDomains = this.$stateParams.selectedDomains;
            this.numberOfSelectedDomains = this.$stateParams.selectedDomains.length;

            for (const domain of this.selectedDomains) {
                if (domain.authCode && domain.authCode.length > 0) {
                    this.authCodeDummy[domain.domainName] = domain.authCode;
                }
            }

            this._selectedListHasChanged();
            this._initialiaztionCompleted = true;
            return;
        }

        this._domains = ng.copy(this.domains);
        for (const domain of this.domains) {
            this.authCodeDummy[domain.domain.domainNameUnicode] = domain.domain.authCode;
        }
        this._selectedDomains = ng.copy(this.selectedDomains);

        this.numberOfSelectedDomains = this.numberOfSelectedDomains || 0;
        this.selectedDomains = this.selectedDomains || [];

        if (this.selectedDomains.length > 0) {
            this._combineSelectedAndSearchResultDomains();
        }

        this._checkDomainSearch();
        this._initialiaztionCompleted = true;
        this._setDomainInBundleStatus();
    };

    private _isProductCodeInList = (list: any[], productCode: string): boolean => {
        return list.some((item) => {
            return item === productCode;
        });
    };

    private _setSelectedSubAccountObject = (accountId?: string): boolean|void => {
        if (this._loadingSubAccountObject) {
            return false;
        }

        accountId = accountId || this.accountId;
        this.accountId = accountId;
        this._loadingSubAccountObject = true;

        void this.accountModel.findOne(accountId).then((subAccResult) => {
            this._subAccount = subAccResult;
            this._loadingSubAccountObject = false;
            return true;
        });
    };

    private _setDomainInBundleStatus = (): void => {
        /**
         *  If a bundle with contingents was selected, all matching domains (with corresponding TLD)
         *  should be displayed free of charge until the available contingent capacity is used up.
         *
         *  Maybe we should display the available capacities in the Ui later.
         */
        this._setContingentItem();

        if (this.contingentItems?.length <= 0
        || this.selectedContingent?.type === 'standalone') {
            // No bundle contingent selected - reset inBundle status in domain lists
            this.domains = this._resetBundleStatusInList(this.domains);
            this.selectedDomains = this._resetBundleStatusInList(this.selectedDomains);
        } else {
            for (const contingentItem of this.contingentItems) {
                contingentItem.usedQuotas = 0;

                for (const selectedDomain of this.selectedDomains) {
                    if (contingentItem.productCodes.some((code) => {
                        return code.startsWith(`domain-${selectedDomain.tld}-`);
                    })) {
                        selectedDomain.inBundle = selectedDomain.domainObject.prices.some(
                            (price: any) => {
                                return (
                                    contingentItem.usedQuotas < contingentItem.availableCapacity
                                    && this._isProductCodeInList(contingentItem.productCodes, price.productCode)
                                );
                            }
                        );

                        if (selectedDomain.inBundle) {
                            contingentItem.usedQuotas++;
                        }
                    }
                }

                for (const domain of this.domains) {
                    if (contingentItem.productCodes.some(
                        (code) => code.startsWith(`domain-${domain.domain.extension}-`))
                    ) {
                        const remainingQuotasAvailable = contingentItem.availableCapacity - contingentItem.usedQuotas;
                        const availableContingent = domain.prices.some(
                            (price) => {
                                return contingentItem.usedQuotas < contingentItem.availableCapacity
                                    && this._isProductCodeInList(contingentItem.productCodes, price.productCode);
                            }
                        );
                        let domainIsSelected = false;
                        this.selectedDomains.some(
                            (selectedDomain) => {
                                if (selectedDomain.domainName === domain.domain.domainNameUnicode) {
                                    domainIsSelected = true;
                                    domain.inBundle = selectedDomain.inBundle;
                                    return true;
                                }
                                return false;
                            }
                        );

                        if (!domainIsSelected) {
                            domain.inBundle = remainingQuotasAvailable >= 0
                                ? availableContingent
                                : false;
                        }
                    }
                }
            }
        }
    };

    private _resetBundleStatusInList = (list) => {
        return list.map((item) => {
            item.inBundle = false;
            return item;
        });
    };

    private _selectedListHasChanged = () => {
        if (this.onListChangedCallback !== undefined) {
            this.onListChangedCallback({ list: this.selectedDomains });
        }
    };

    private _checkDomainSearch = () => {
        if (this._domainSearch !== undefined) {
            const domainSearch = this._domainSearch.split('\n').filter((domain) => domain.indexOf('.') >= 1);
            // Select the domain after search as long as there is only a single result.
            if (domainSearch.length === 1) {
                this.domains.map(
                    (domain) => {
                        const domainIsDeselected = this._domainsHasDeselected.some(
                            (deselectedDomain) => deselectedDomain === domain.domain.domainName
                        );
                        if (!domainIsDeselected && domainSearch.indexOf(domain.domain.domainName) >= 0) {
                            this.select(domain);
                        }
                    }
                );
            }
        }
    };

    private _combineSelectedAndSearchResultDomains = () => {
        let domainList = [];

        if (this.selectedDomains.length > 0) {
            domainList = this.selectedDomains.map(
                (domain) => {
                    const indexOfExistingElement = this.domains.findIndex(
                        (domainInfo) => domainInfo.domain?.domainName === domain.domainName
                    );

                    if (indexOfExistingElement >= 0 && domain.domainObject?.domain) {
                        domain.domainObject.domain.authCode = this.domains[indexOfExistingElement].domain.authCode;
                    }

                    if (domain.domainObject?.domain?.authCode?.length > 0) {
                        // tslint:disable-next-line
                        this.authCodeDummy[domain.domainObject.domain.domainNameUnicode] = domain.domainObject.domain.authCode;
                    }

                    return domain.domainObject;
                }
            );
        }

        this.domains = this.domains.filter(
            (domain) => !this._domainAlredySelected(domain)
        );

        this.domains = domainList.concat(this.domains);
    };

    private _domainAlredySelected = (domain) => {
        if ([undefined, null].indexOf(this.selectedDomains) >= 0) {
            return false;
        }
        return this.selectedDomains.some(
            (domainInList) => domainInList.domainName === domain.domain.domainNameUnicode
        );
    };

    private _setContingentItem = () => {
        if (this.bundle) {
            for (const contingentUsage of this.bundle.effectiveContingentUsage) {
                const domainContingent = contingentUsage.productCodes.some(
                    (usageProductCode) => usageProductCode.indexOf('domain') === 0
                );
                if (domainContingent) {
                    this.contingentItems.push({
                        availableCapacity: contingentUsage.availableCapacity,
                        usedQuotas: 0,
                        productCodes: contingentUsage.productCodes
                    });
                }
            }
        }
    };
}

export class MoleculeListsAvailableDomainsListComponent implements ng.IComponentOptions {
    public bindings = {
        _bundle: '<bundle',
        _domainSearch: '=domainSearch',
        _loadingData: '<loadingData',
        selectedContingent: '<',
        accountId: '<',
        domains: '=',
        numberOfSelectedDomains: '=',
        onListChangedCallback: '<',
        selectedDomains: '='
    };
    public controller = MoleculeListsAvailableDomainsListController;
    public template = require('./available-domains-list.html');
}
