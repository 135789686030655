import { WebhostingApi } from '../../../../../types';

import * as ng from 'angular';

export class OrganismEditFormDomainDirectoryProtectionController {
    public static $inject: string[] = ['$state'];
    public bundleId: string;
    public vHost: WebhostingApi.VHost;
    public webspace: WebhostingApi.Webspace;
    public userPanelRight: any;
    public editFormState = '';

    public newUsers: any[];       // List of new users to create.
    public changedUsers: any[];   // List of users that got changed.
    public deletedUsers: any[];   // List of current users to remove.

    public newPaths: any[];       // List of newly created protections.
    public changedPaths: any[];   // List of locations that got changed.
    public deletedPaths: any[];   // List of deleted paths.

    public $onInit() {
        this.resetPanel();
    }

    public resetPanel = () => {
        // Reset users.
        this.newUsers = [];
        this.changedUsers = [];
        this.deletedUsers = [];

        // Reset paths.
        this.newPaths = [];
        this.changedPaths = [];
        this.deletedPaths = [];
    };
}

export class OrganismEditFormDomainDirectoryProtectionComponent implements ng.IComponentOptions {
    public bindings = {
        bundleId: '<',
        userPanelRight: '<',
        vHost: '<',
        webspace: '<'
    };

    public controller = OrganismEditFormDomainDirectoryProtectionController;
    public template = require('./domain-directory-protection.html');
}
