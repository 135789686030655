import ng from 'angular';
import { UiRights } from '../../../../configuration';
import { Breadcrumb, BreadcrumbItem } from '../breadcrumb';
import { BreadcrumbTopicModel, TopicCases } from './topic-model';

export class BreadcrumbBillingTopicModel extends BreadcrumbTopicModel {
    public static $inject: string[] = BreadcrumbTopicModel.$inject.concat([]);

    public readonly topicName = 'billing';
    public readonly specialTopicCases: TopicCases;

    /**
     *   List of route parts, which are excluded from mail class generalRoutes methode
     */
    public readonly excludeGeneralRouteParts = ['overview'];
    private billingActionDropdownItems: BreadcrumbItem[] = [];
    private priceActionDropdownItems: BreadcrumbItem[] = [];

    constructor(protected $state: ng.ui.IStateService, protected $translate: ng.translate.ITranslateService) {
        super($state, $translate);

        this.billingActionDropdownItems = [
            {
                isGranted: UiRights.BIL_LIST_DOCUMENTS,
                name: this.$translate.instant('TR_080119-5c34c1_TR'),
                part: 'invoices',
                route: 'billing.invoices.overview'
            },
            {
                name: this.$translate.instant('TR_080119-5c34c3_TR'),
                part: 'payment-options',
                route: 'billing.payment-options'
            },
            {
                isGrantedAny: [
                    UiRights.BIL_LIST_ARTICLE_PRICES,
                    UiRights.BIL_LIST_BOOKINGS,
                    UiRights.BIL_LIST_DOCUMENTS,
                    UiRights.BIL_LIST_PAYMENTS,
                    UiRights.BIL_LIST_PRICES_DATABASE,
                    UiRights.BIL_LIST_PRICES_DNS,
                    UiRights.BIL_LIST_PRICES_DOMAIN,
                    UiRights.BIL_LIST_PRICES_EMAIL,
                    UiRights.BIL_LIST_PRICES_MACHINE,
                    UiRights.BIL_LIST_PRICES_SSL,
                    UiRights.BIL_LIST_PRICES_WEBHOSTING
                ],
                name: this.$translate.instant('TR_080119-5c3488_TR'),
                part: 'pricelists',
                route: 'billing.pricelists'
            }
        ];

        this.priceActionDropdownItems = [
            {
                isGranted: UiRights.BIL_LIST_PRICES_DATABASE,
                name: this.$translate.instant('TR_090119-6e01db_TR'),
                part: 'promotions',
                route: 'billing.pricelists.promotions'
            },
            {
                isGrantedAny: [
                    UiRights.BIL_LIST_ARTICLE_PRICES,
                    UiRights.BIL_LIST_BOOKINGS,
                    UiRights.BIL_LIST_DOCUMENTS,
                    UiRights.BIL_LIST_PAYMENTS,
                    UiRights.BIL_LIST_PRICES_DATABASE,
                    UiRights.BIL_LIST_PRICES_DNS,
                    UiRights.BIL_LIST_PRICES_DOMAIN,
                    UiRights.BIL_LIST_PRICES_EMAIL,
                    UiRights.BIL_LIST_PRICES_MACHINE,
                    UiRights.BIL_LIST_PRICES_SSL,
                    UiRights.BIL_LIST_PRICES_WEBHOSTING
                ],
                name: this.$translate.instant('ACCOUNT.PRICES.TABS.CHANGES'),
                part: 'changes',
                route: 'billing.pricelists.changes'
            },
            {
                isGranted: UiRights.BIL_LIST_PRICES_MACHINE,
                name: this.$translate.instant('TR_090119-ad5c6b_TR'),
                part: 'machines',
                route: 'billing.pricelists.machines'
            },
            {
                isGranted: UiRights.BIL_LIST_PRICES_DNS,
                name: this.$translate.instant('03d67c8e-683a-4982-ac59-5637dfe27b71'),
                part: 'dns',
                route: 'billing.pricelists.dns'
            },
            {
                isGranted: UiRights.BIL_LIST_PRICES_DOMAIN,
                name: this.$translate.instant('TR_140119-aeb48a_TR'),
                part: 'domains',
                route: 'billing.pricelists.domains'
            },
            {
                isGranted: UiRights.BIL_LIST_PRICES_SSL,
                name: this.$translate.instant('cd85269f-9db9-4c6d-8d07-4b2a5fc31e6b'),
                part: 'ssl',
                route: 'billing.pricelists.ssl'
            },
            {
                isGranted: UiRights.BIL_LIST_PRICES_EMAIL,
                name: this.$translate.instant('c78b82a7-e70c-4ea5-a1da-52a99244c3b1'),
                part: 'mailboxes',
                route: 'billing.pricelists.mailboxes'
            },
            {
                isGranted: UiRights.BIL_LIST_PRICES_WEBHOSTING,
                name: this.$translate.instant('MENU.MAIN.PRODUCTS.WEBHOSTING'),
                part: 'webhosting',
                route: 'billing.pricelists.webhosting'
            },
            {
                isGranted: UiRights.BIL_LIST_PRICES_DATABASE,
                name: this.$translate.instant('TR_090119-f0ccfb_TR'),
                part: 'databases',
                route: 'billing.pricelists.databases'
            },
            {
                isGranted: UiRights.BIL_LIST_PRICES_MANAGEDAPPLICATION,
                name: this.$translate.instant('TR_140519-4f0af6_TR'),
                part: 'storage-product',
                route: 'billing.pricelists.storage-product'
            }
        ];

        this.specialTopicCases = {
            'invoices': () => this.getProductActionDropdown(this.billingActionDropdownItems),
            'payment-options': () => this.getProductActionDropdown(this.billingActionDropdownItems),
            'pricelists': this.getPricelistRoutes
        };
    }

    public getFirstLevelData = (): Breadcrumb[] => {
        this.breadcrumbs.push({
            active: this.steps.length === 2,
            route: 'billing.dashboard',
            routeParams: {},
            title: this.$translate.instant('TR_080119-5c34c6_TR'),
            type: 'text',
            value: this.$translate.instant('MENU.MAIN.BILLING.LABEL')
        });

        return this.breadcrumbs;
    };

    public getPricelistRoutes = (): Breadcrumb[] => {
        // let grantedObject = this.getGrantedObject(this.billingActionDropdownItems, 'pricelists');

        this.breadcrumbs = this.getProductActionDropdown(this.billingActionDropdownItems);

        if (this.index < this.steps.length - 1) {
            const nextStep = this.steps[this.index + 1];
            this.breadcrumbs = this.breadcrumbs.concat(
                this.getProductActionDropdown(
                    this.priceActionDropdownItems,
                    { route: 'billing.pricelists.' + nextStep },
                    nextStep
                )
            );
        }

        return this.breadcrumbs;
    };
}
