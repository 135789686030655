import { Validator } from './abstract-validator';

export interface DataObject {
    validator: Validator;
    instructions?: {
        [key: string]: any;
    };
}

export class GeneralValidator extends Validator {
    constructor(private $translate: ng.translate.ITranslateService) {
        super();
    }

    public validate = (
            value: string,
            data: DataObject[],
            logicOperator?: string | '&&' | '||'
    ): { text: string }[] => {
        logicOperator = logicOperator || '&&';

        switch (logicOperator.trim()) {
            case '||':
                return this.orLogicOperatorValidation(value, data);
            case '&&':
            default:
                return this.andLogicOperatorValidation(value, data);
        }
    };

    private andLogicOperatorValidation = (value: string, data: DataObject[]): { text: string }[] => {
        const reply = [];

        for (const element of data) {
            const currentValidator = element.validator;
            let validationResult = [];

            if (element.instructions) {
                validationResult = currentValidator.validate(value, element.instructions);
            } else {
                validationResult = currentValidator.validate(value);
            }

            for (const item of validationResult) {
                reply.push(item);
            }
        }

        return reply;
    };

    private orLogicOperatorValidation = (value: string, data: DataObject[]): { text: string }[] => {
        let reply = [];

        for (const element of data) {
            const currentValidator = element.validator;
            let validationResult = [];

            if (value === '' && reply.length === 1) {
                // tslint:disable
                /**
                 *  If value === '' and exactly one validation error is present, we assume at this point that the error is the reference to a
                 *  mandatory field.
                 *  Then we are not allowed to perform any further validations, otherwise the mandatory field error will be displayed several
                 *  times. I know that this is not an unambiguous identification of whether this is a mandatory error or not. But I don't see
                 *  any other meaningful check at the moment...
                **/
                // tslint:enable
                break;
            }

            if (element.instructions) {
                validationResult = currentValidator.validate(value, element.instructions);
            } else {
                validationResult = currentValidator.validate(value);
            }

            if (validationResult.length > 0) {
                for (const item of validationResult) {
                    if (reply.length > 0) {
                        reply.push({ text: this.$translate.instant('TR_160819-aa4f8f_TR')});
                    }
                    reply.push(item);
                }
            } else {
                reply = [];
                break;
            }
        }
        return reply;
    };
}
