import * as ng from 'angular';

import { FormDescriptionSpec } from '@/types/view-types/form-description/form-description';
import { ValidationErrorList } from '@/atomic-components/molecules/lists/validation-error-list/validation-error-list';

export class FormElementInputSelectController implements ng.IComponentController {
    public static $inject: string[] = [];

    public disabled: boolean;
    public keenInput: FormDescriptionSpec.FieldObjectSelect & { disabled: boolean };
    public keenOutput: (outputValue: unknown, key: string) => void;
    public options: { value: string; name: string }[];
    private _model: unknown;
    private key: string;  // Used in controller.
    private label: string; // Used in controller.
    private validationErrorList: ValidationErrorList = []; // Used in controller.

    public $onInit(): void {
        this.disabled = this.keenInput?.disabled || false;
        this.label = this.keenInput?.ui?.label || '';
        this.options = this.keenInput?.ui?.options || [];
        this.key = this.keenInput?.key;
        if ([undefined, null].indexOf(((this.keenInput as FormDescriptionSpec.FieldObject).value as unknown)) < 0) {
            this._model = ((this.keenInput as FormDescriptionSpec.FieldObject).value as unknown);
        } else if ([undefined, null].indexOf(((this.keenInput as FormDescriptionSpec.FieldObject).default as unknown)) < 0) {
            this._model = ((this.keenInput as FormDescriptionSpec.FieldObject).default as unknown);
        }
    }

    public get model(): unknown {
        return this._model;
    }

    public set model(value: unknown) {
        this._model = value;
        this.validationErrorList = (!value) ? [{ text: this.keenInput?.ui?.error || '' }] : [];
        this.onChangeModel();
    }

    private onChangeModel = () => {
        if (this.keenOutput) {
            this.keenOutput(this.model, this.key);
        }
    };
}

export class FormElementInputSelectComponent implements ng.IComponentOptions {
    public bindings = {
        keenInput: '<',
        keenOutput: '<'
    };
    public controller = FormElementInputSelectController;
    public template = require('./form-element-input-select.html');
}
