import * as ng from 'angular';

/*
 * Just in case anybody asks why I'm using angular Types here when I'm avoiding them
 * as much as possible everywhere else: It's because I'm trying to wrap actual
 * AngularJS functionality as transparently as possible.
 *
 * For the $$intervalId stuff... refer to https://github.com/angular/angular.js/blob/master/src/ng/interval.js#L158
 * if you have any questions.
 */

/**
 * Array of promises generated by the localInterval service and not yet canceled.
 */
let intervalPromises: ng.IPromise<any>[] = [];

/**
 * Wraps the AngularJS $interval service in a layer that saves all generated promises
 * in the intervalPromises array so they can later be canceled in bulk.
 */
export class LocalIntervalProvider {
    public $get = [
        '$interval',
        ($interval: ng.IIntervalService): ng.IIntervalService => {
            const localInterval = (func, delay, count?, invokeApply?, ...args) => {
                const promise = $interval(func, delay, count, invokeApply, ...args);
                intervalPromises.push(promise);
                return promise;
            };

            localInterval.cancel = (promise: ng.IPromise<any>) => {
                if (promise?.hasOwnProperty('$$intervalId')) {
                    intervalPromises = intervalPromises.filter(
                        (intervalPromise) => (promise as any).$$intervalId !== (intervalPromise as any).$$intervalId
                    );
                }

                return $interval.cancel(promise);
            };

            localInterval.flush = (millis: number): number => $interval.flush(millis);

            return localInterval;
        }
    ];
}

/**
 * Pretty straightforward: Provides a function to cancel all uncanceled intervals.
 */
export class CancelLocalIntervalsProvider {
    public $get = [
        '$interval',
        ($interval: ng.IIntervalService) => () => {
            for (const promise of intervalPromises) {
                if (promise.hasOwnProperty('$$intervalId')) {
                    intervalPromises = intervalPromises.filter(
                        (intervalPromise) => (promise as any).$$intervalId !== (intervalPromise as any).$$intervalId
                    );
                }

                return $interval.cancel(promise);
            }
        }
    ];
}
