import * as Sentry from '@sentry/browser';
import { Angular as AngularIntegration } from '@sentry/integrations';

const dsn = "https:" + __SENTRY_SERVICE_URL__;

Sentry.init(
    {
        denyUrls: [/.+\.keendev.*/, /.*10\.10\.10\.[0-9]{1,3}.*/],
        dsn: dsn,
        integrations: [
            new AngularIntegration()
        ],
        release: `robotinterface@${__VERSION__}`,
    }
);
