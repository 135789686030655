import * as ng from 'angular';

import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateBundleOverviewListController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;
    public overviewServiceData;
    public expertView: boolean;
    public overviewModel;

    private simpleFilter;
    private extendedFilters;
    private overviewUiSettings;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit() {
        if ([undefined, null].indexOf(this.simpleFilter) === -1) {
            this.expertView = this.overviewUiSettings.expertView || this.simpleFilter.show;
        } else {
            this.expertView = this.overviewUiSettings.expertView || false;
        }

        this.pageHeaderData = {
            backwardLink: 'bundle.dashboard',
            backwardText: this.$translate.instant('TR_090119-a32485_TR'),
            panelHeaderRoute: '',
            panelIcon: 'boxes-alt',
            panelTitle: this.$translate.instant('TR_090119-ecb4b9_TR')
        };
        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter
        };
    }

    public expandWrapper = (toggle) => {
        this.expertView = toggle.expand;
    };
}

export class TemplateBundleOverviewListComponent implements ng.IComponentOptions {

    public bindings = {
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public controller = TemplateBundleOverviewListController;
    public template = require('./bundle-overview-list-template.html');
}
