import * as ng from 'angular';

import { CacheService, GeneralCreateService, JobStatusObject } from '../../../../../services';

export class OrganCreateConfirmationBundleController implements ng.IController {
    public static $inject: string[] = ['cache', 'generalCreate', 'wizardHelper'];

    public panelHidden = false;
    public apiOrderRequestError = false;
    public jobStatusBundle: null | JobStatusObject;
    public jobStatusWebspace: null | JobStatusObject;
    public someCreateJobInSupportStatus = false;

    constructor(
        private cache: CacheService,
        private generalCreate: GeneralCreateService
    ) {}

    public $onInit() {
        this.cache.clearAll();
    }

    public get bundleCreatingStatus() {
        return this.generalCreate.getJobStatusForTemplate(
            this.jobStatusBundle,
            this.someCreateJobInSupportStatus,
            this.apiOrderRequestError
        );
    }

    public set bundleCreatingStatus({}) {} // tslint:disable-line:no-empty

    public get webspaceCreatingStatus() {
        return this.generalCreate.getJobStatusForTemplate(
            this.jobStatusWebspace,
            this.someCreateJobInSupportStatus,
            this.apiOrderRequestError
        );
    }

    public set webspaceCreatingStatus({}) {} // tslint:disable-line:no-empty
}

export class OrganCreateConfirmationBundleComponent implements ng.IComponentOptions {
    public bindings = {
        apiOrderRequestError: '<',
        jobStatusBundle: '<',
        jobStatusWebspace: '<',
        panelHidden: '<?',
        someCreateJobInSupportStatus: '<?'
    };
    public template = require('./bundle-confirmation.html');
    public controller =  OrganCreateConfirmationBundleController;
}
