import { DomainCsvGenerator } from './domain-csv-generator';
import { DomainStatisticsService } from './domain-statistics';

export class DomainReportsService {
    public static $inject: string[] = ['domainStatistics'];

    constructor(
        private domainStatistics: DomainStatisticsService
    ) {}

    public domainPortfolio(withSubAccounts, owner) {
        return this.domainStatistics.portfolio(withSubAccounts, owner)
            .then(DomainCsvGenerator.generateDomainPortfolioCsv);
    }

    public domainCount(withSubAccounts, owner) {
        return this.domainStatistics.count(withSubAccounts, owner)
            .then(DomainCsvGenerator.generateDomainCountCsv);
    }

    public domainJobs(withSubAccounts, start, end, owner) {
        return this.domainStatistics.jobs(withSubAccounts, start, end, owner)
            .then(DomainCsvGenerator.generateDomainJobsCsv);
    }

    public domainTurnover(widthSubAccounts, start, end, owner) {
        return this.domainStatistics.turnover(widthSubAccounts, start, end, owner)
            .then(DomainCsvGenerator.generateDomainTurnoverCsv);
    }

    public subAccountDomainTurnover(start, end, minInflow, minOutflow, owner) {
        return this.domainStatistics.subAccountTurnover(start, end, minInflow, minOutflow, owner)
            .then(DomainCsvGenerator.generateDomainSubAccountTurnoverCsv);
    }
}
