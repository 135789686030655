import * as ng from 'angular';

import { ResourceHelperService } from '@/services';
import { MoleculeFormEditController } from '../../../../forms/form-edit/form-edit';
import { MoleculePanelEditTableController } from '../../../panel-edit/table/panel-edit-table';

export class MoleculePanelEditRowTableIpAddressesController implements ng.IController {
    public static $inject: string[] = ['$translate', 'resourceHelper'];

    public service;
    public $panelEditTable: MoleculePanelEditTableController;
    public $editForm: MoleculeFormEditController;
    public ipAddresses: any[] = [];
    public version: string;

    public networkType: string;

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private resourceHelper: ResourceHelperService
    ) {}

    public get versionString() {
        return `v${this.version}`;
    }

    public get firstColumnHeader() {
        return `IPv${this.version} ` + this.$translate.instant('TR_100119-9b4d68_TR');
    }

    public get rdnsEditable() {
        return this.$panelEditTable.showEditableContent && this.networkType === 'publicNetwork';
    }

    public get ipAddressesVersionFiltered() {
        return this.ipAddresses.filter((address) => address.type === this.versionString);
    }
}

export class MoleculePanelEditRowTableIpAddressesComponent implements ng.IComponentOptions {
    public require = {
        $editForm: '^moleculeFormEdit',
        $panelEditTable: '^moleculePanelEditTable'
    };
    public bindings = {
        version: '@',
        ipAddresses: '<',
        networkType: '<'
    };
    public controller = MoleculePanelEditRowTableIpAddressesController;
    public controllerAs = '$panelEditRowTable';
    public template = require('./ip-addresses.html');
}
