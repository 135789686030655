import { ModelHelper } from '../model-helper';
import { WebhostingRobotService } from './robot';

import * as Types from '../../types';

export class WebhostingJobModelService {
    public static $inject: string[] = ['webhostingRobot'];

    constructor(
        private webhostingRobot: WebhostingRobotService
    ) {}

    public list = (limit, page, filters, sort) => {
        sort = sort || {
            field: 'accountId',
            order: 'ASC'
        };
        page = page || 1;

        return this.webhostingRobot.listJobs(filters, limit, page, sort)
        .then(ModelHelper.returnListResponse);
    };

    public findRunningJobsForWebspace = (webspace: Types.WebhostingApi.Webspace) => {
        const filter = {
            subFilter: [
                { field: 'jobObjectType', value: 'Webspace' },
                { field: 'jobObjectId', value: webspace.id },
                { field: 'jobStatus', relation: 'unequal', value: 'successful' },
                { field: 'jobStatus', relation: 'unequal', value: 'failed' },
                { field: 'jobStatus', relation: 'unequal', value: 'canceled' }
            ],
            subFilterConnective: 'AND'
        };

        return this.webhostingRobot.listJobs(filter, 25, 1, null);
    };

    public findRunningJobsForWebspaceId = (webspaceId: string) => {
        const filter = {
            subFilter: [
                { field: 'jobObjectType', value: 'Webspace' },
                { field: 'jobObjectId', value: webspaceId },
                { field: 'jobStatus', relation: 'unequal', value: 'successful' },
                { field: 'jobStatus', relation: 'unequal', value: 'failed' },
                { field: 'jobStatus', relation: 'unequal', value: 'canceled' }
            ],
            subFilterConnective: 'AND'
        };

        return this.webhostingRobot.listJobs(filter, 25, 1, null);
    };

    public findRunningJobsForUser = (user: Types.WebhostingApi.User) => {
        const filter = {
            subFilter: [
                { field: 'jobObjectType', value: 'User' },
                { field: 'jobObjectId', value: user.id },
                { field: 'jobStatus', relation: 'unequal', value: 'successful' },
                { field: 'jobStatus', relation: 'unequal', value: 'failed' },
                { field: 'jobStatus', relation: 'unequal', value: 'canceled' }
            ],
            subFilterConnective: 'AND'
        };

        return this.webhostingRobot.listJobs(filter, 25, 1, null);
    };

    public findRunningJobsForVHost = (vHost: Types.WebhostingApi.VHost) => {
        const filter = {
            subFilter: [
                { field: 'jobObjectType', value: 'VHost' },
                { field: 'jobObjectId', value: vHost.id },
                { field: 'jobStatus', relation: 'unequal', value: 'successful' },
                { field: 'jobStatus', relation: 'unequal', value: 'failed' },
                { field: 'jobStatus', relation: 'unequal', value: 'canceled' }
            ],
            subFilterConnective: 'AND'
        };

        return this.webhostingRobot.listJobs(filter, 25, 1, null);
    };

    public findOne = (id) => {
        return this.webhostingRobot.listJobs({ field: 'jobId', value: id }, 1, 1)
        .then(ModelHelper.returnFindOneResponse);
    };

    public findById = (ids) => {
        const filter = { subFilterConnective: 'OR', subFilter: ids.map(this.idFilter) };
        return this.webhostingRobot.listJobs(filter, 0, 1)
        .then(ModelHelper.returnFindResponse);
    };

    private idFilter = (id) => {
        return { field: 'jobId', value: id };
    };
}
