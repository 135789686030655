import { FormInputModelOptions } from '@/atomic-components';
import * as ng from 'angular';
import { ValidateOptions } from '../date-select/date-select';

export class MoleculePasswordKeyGenController implements ng.IController {
    public static $inject: string[] = [];

    public allowSimplePasswords = false;
    public ddnsPassword = false;
    public nextcloudPassword = false;
    public modelOptions: FormInputModelOptions;
    public generate: () => void;
    public value: string;
    public readonly: boolean;
    public repeatPassword: boolean;
    public validationErrorList: string[];
    public disabled: boolean;
    public loadPasswortPhrase: boolean;
    public validationOptions: ValidateOptions;
    public doNotSaveOnFormEnter: boolean;
    public callbackOnChange: any;

    public onKeyUp = (event) => {
        if (event.key === 'Enter') {
            this.generate();
        }
    };
}

export class MoleculePasswordKeyGenComponent implements ng.IComponentOptions {
    public bindings = {
        allowSimplePasswords: '<?',
        callbackOnChange: '<',
        ddnsPassword: '<?',
        disabled: '=',
        doNotSaveOnFormEnter: '<',
        generate: '<',
        loadPasswortPhrase: '=',
        nextcloudPassword: '<?',
        modelOptions: '<?',
        placeholder: '@',
        readonly: '=',
        repeatPassword: '<?',
        validationOptions: '<?',
        validationErrorList: '=?',
        value: '='
    };
    public controller = MoleculePasswordKeyGenController;
    public template = require('./password-key-gen.html');
}
