import { AlertManagerService } from '../';
import { ApiErrorModel } from '../errors';
import { DocumentModelService } from './';

import * as Types from '../../types';

export class DocumentHelperService {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'apiErrorModel',
        'documentModel'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private documentModel: DocumentModelService
    ) {}

    public pdfDownloadInvoice = (invoice: Types.BillingApi.AccountingDocument) => {
        this.apiErrorModel.destroyErrorList();
        return this.documentModel.getPdf(invoice.documentId)
            .then(this.dataCompleted, this.onError);
    };

    public csvDownloadInvoice = (invoice: Types.BillingApi.AccountingDocument) => {
        this.apiErrorModel.destroyErrorList();
        this.documentModel.getCsv(invoice.documentId)
            .then(this.dataCompleted, this.onError);
    };

    public outstandingInvoicePriceTotal = (invoice: Types.BillingApi.AccountingDocument) => {
        let outstanding = 0;
        if (invoice.paymentReceived) {
            return outstanding;
        }

        outstanding += invoice.outstanding;
        outstanding += this._getInvoicePriceReminderOutstandings(invoice);

        return outstanding;
    };

    private _getInvoicePriceReminderOutstandings = (invoice: Types.BillingApi.AccountingDocument): number => {
        const reminderLevels = invoice.reminderLevels.length;
        let sum = 0;
        if (reminderLevels > 0) {
            invoice.reminderLevels.map((level) => {
                sum += level.outstanding;
            });
        }

        return sum;
    };

    private dataCompleted = () => {
        this.alertManager.success(this.$translate.instant('TR_140119-459daf_TR'));
    };

    private onError = (reason) => {
        this.alertManager.error(reason);
    };
}
