import * as ng from 'angular';
import * as Types from '../../../../../types';

import { WebspaceComposerService } from '../../../../../services/webhosting/webspace-composer';
import { ViewTypes } from '../../../../../types/view-types';

export class OrganismWebspaceWizardWrapperController {
    public static $inject: string[] = ['$timeout', 'webspaceComposer'];

    public outerAccount: Types.AccountApi.Account;
    public outerProductCode: string;
    public productFamily: string;
    public account: Types.AccountApi.Account;
    public productCode: string;

    public wizardComposerObject: Types.ViewTypes.WizardComposerObject = {
        apiObject: {},
        summaryObject: []
    };
    public viewType = 'config';

    constructor(
        private $timeout: ng.ITimeoutService,
        private webspaceComposer: WebspaceComposerService
    ) {}

    public loadSummaryView = (metadata: ViewTypes.ProductConfigWebspaceObject): void => {
        this.wizardComposerObject.summaryObject = [];
        void this.webspaceComposer.buildWebspaceApiObject(metadata)
            .then(
                (composerObject) => {
                    void this.$timeout(
                        () => {
                            this.viewType = 'summary';
                            this.wizardComposerObject = composerObject;
                        }
                    );
                }
            );
    };

    public loadConfirmView = (): void => {
        this.viewType = 'confirm';
    };
}

export class OrganismWebspaceWizardWrapperComponent implements ng.IComponentOptions {
    public bindings = {
        outerAccount: '<?account',
        outerProductCode: '<?productCode'
    };
    public template = require('./webspace-wizard-wrapper.html');
    public controller = OrganismWebspaceWizardWrapperController;
}
