import * as ng from 'angular';

export class OrganEditPanelMachineResetController {
    public static $inject: string[] = [];

    public dataLossConfirmed = false;

    public get showSavePanel() {
        return this.dataLossConfirmed;
    }
}

export class OrganEditPanelMachineResetComponent implements ng.IComponentOptions {
    public bindings = {};
    public template = require('./machine-reset.html');
    public controller = OrganEditPanelMachineResetController;
}
