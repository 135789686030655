import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels';
import { BundleApi, WebhostingApi } from '../../../types';

export class TemplateBundleContractDetailsController {
    public static $inject: string[] = ['$translate'];

    public bundle: BundleApi.Bundle;
    public faqArticleIdList: string[] = [
        'webspace-expand-memory',
        'webspace-ftp-folders'
    ];
    public modifier = '';
    public pageHeaderData: PanelHeaderData;
    public userPanelRight;
    public webspace: WebhostingApi.Webspace;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'bundle.id.dashboard',
            backwardText: this.$translate.instant('TR_090119-a32485_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: { bundleId: this.bundle.id },
            panelIcon: 'box-alt',
            panelTitle: this.bundle.name + ' - '
                + this.$translate.instant('TR_130219-d4e51d_TR')
        };
    };
}

export class TemplateBundleContractDetailsComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<',
        webspace: '<'
    };
    public controller = TemplateBundleContractDetailsController;
    public template = require('./bundle-contract-details-template.html');
}
