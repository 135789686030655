import {
    TemplateBillingPricelistAbstractOverviewComponent,
    TemplateBillingPricelistAbstractOverviewController
} from '../abstract/pricelist-abstract-template';

export class TemplateBillingPricelistDomainsOverviewController
    extends TemplateBillingPricelistAbstractOverviewController {
    protected options = {
        panelTitleTranslationId: /* translationId */ 'TR_090119-835ab2_TR',
        simpleFilterField: 'domainSuffix'
    };

    public expandWrapper = (toggle) => {
        this.expertView = toggle.expand;
    };
}

export class TemplateBillingPricelistDomainsOverviewComponent
    extends TemplateBillingPricelistAbstractOverviewComponent {
    public controller = TemplateBillingPricelistDomainsOverviewController;
    public template = require('./pricelist-domains-template.html');
}
