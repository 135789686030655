import * as ng from 'angular';
import { AuthContextService, GetEntryPointService } from '../../../../services';
import { LinkListItem } from '../../links/link-list/link-list';
import './panel-support.scss';

export class MoleculePanelSupportController implements ng.IController {
    public static $inject: string[] = ['authContext', 'getEntryPoint', '$translate'];

    public displayContent = false;
    public displaySupportModal: any = {
        contact: false,
        promise: false
    };
    public panelItemList: LinkListItem[];

    private lastEntryPoint = '';

    constructor(
        private authContext: AuthContextService,
        private getEntryPoint: GetEntryPointService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.setVisibilityOptionByEntryPoint();

        this.panelItemList = [
            {
                isGranted: true,
                onclickMethode: this.showSupportPromise,
                text: this.$translate.instant('TR_100119-c7dc72_TR')
            }, {
                isGranted: true,
                onclickMethode: this.showContactForm,
                text: this.$translate.instant('TR_100119-e49b56_TR')
            }
        ];
    }

    public showSupportPromise = () => {
        this.displaySupportModal.promise = true;
    };

    public showContactForm = () => {
        this.displaySupportModal.contact = true;
    };

    public setVisibilityOptionByEntryPoint = (): void => {
        if (this.lastEntryPoint !== this.getEntryPoint.entryPoint) {
            this.lastEntryPoint = this.getEntryPoint.entryPoint;
            this.displayContent = this.getEntryPoint.showSupportOptions;
        }
    };

    public showOnlyOnResellerAccounts = () => {
        return +this.authContext.account.parentAccountId <= 1000;
    };
}

export class MoleculePanelSupportComponent implements ng.IComponentOptions {
    public controller =  MoleculePanelSupportController;
    public template = require('./panel-support.html');
}
