import * as ng from 'angular';

export class OrganDashboardWidgetSepaVerificationNotifierController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public panelHeader = {
        panelIcon: 'money-check-alt',
        panelTitle: 'Bankkonto Verifizierung'
    };

    public constructor(
        private $translate: ng.translate.ITranslateService
    ) {}
}

export class OrganDashboardWidgetSepaVerificationNotifierComponent implements ng.IComponentOptions {
    public template = require('./dashbaord-widget-sepa-verification-notifier.html');
    public controller = OrganDashboardWidgetSepaVerificationNotifierController;
}
