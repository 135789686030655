export class NotFoundError extends Error {
    public name = 'NotFoundError';

    constructor(
        message?: string
    ) {
        super(message ||  '');

        this.stack = (new Error()).stack;
    }
}
