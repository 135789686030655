import * as ng from 'angular';
import {
    PanelHeaderData
} from '@/atomic-components/molecules/panels/panel-header/panel-header';
import { NextcloudFreeProductCodeConst } from '@/configuration';
import * as Types from '@/types';

export class TemplateNextcloudDeleteController {
    public static $inject: string[] = ['$state', '$translate'];

    public pageHeaderData: PanelHeaderData;
    public nextcloud: Types.ManagedApplicationApi.Nextcloud;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit(): void {
        this.pageHeaderData = {
            backwardLink:
                this.$state.current.name.indexOf('storage-products') >= 0
                    ? 'storage.storage-products.overview'
                    : 'nextcloud.overview',

            backwardText: this.$translate.instant('TR_010819-62941c_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: { storageProductId: this.nextcloud.id },
            panelIcon: '/assets/images/logos/nextcloud-bw.svg',
            panelIconPath: true,

            panelTitle: this.nextcloud.productCode === NextcloudFreeProductCodeConst
                ? this.$translate.instant('TR_130120-0bc560_TR', { name: this.nextcloud.name })
                : this.$translate.instant('TR_100919-ecb06f_TR', { name: this.nextcloud.name })
        };
    }
}

export class TemplateNextcloudDeleteComponent implements ng.IComponentOptions {
    public bindings = {
        nextcloud: '<'
    };
    public controller = TemplateNextcloudDeleteController;
    public template = require('./nextcloud-delete-template.html');
}
