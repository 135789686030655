import { PanelHeaderData } from '@/atomic-components';
import * as ng from 'angular';

export class TemplateMachineWizardController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.pageHeaderData = {
            backwardLink: 'machine.dashboard',
            backwardText: this.$translate.instant('TR_140119-a258ef_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: {},
            panelIcon: 'server',
            panelTitle: this.$translate.instant('TR_140119-9b5fa4_TR')
        };
    }
}

export class TemplateMachineWizardComponent implements ng.IComponentOptions {
    public bindings = {};
    public template = require('./machine-wizard-template.html');
    public controller = TemplateMachineWizardController;
}
