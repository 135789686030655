import * as ng from 'angular';

import { PanelHeaderData } from '../../../atomic-components/molecules/panels';

export class TemplateMachineUpgradeController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData = {
        backwardLink: 'machine.virtualmachines.id.edit',
        backwardText: this.$translate.instant('TR_140119-0389cf_TR'),
        panelHeaderRoute: '',
        panelHeaderRouteParams: { machineId: '' },
        panelIcon: 'cloud',
        panelTitle: this.$translate.instant('TR_140119-f50be5_TR')
    };

    public machine;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit = () => {
        this.pageHeaderData.panelHeaderRouteParams = { machineId: this.machine.id };
    };
}

export class TemplateMachineUpgradeComponent implements ng.IComponentOptions {
    public bindings = {
        machine: '<'
    };
    public controller = TemplateMachineUpgradeController;
    public controllerAs = '$TemplateMachineUpgradeCtrl';
    public template = require('./machine-upgrade-template.html');
}
