import * as ng from 'angular';

export class OrganNewPanelDnsTemplateGeneralController {
    public static $inject: string[] = [];

    public ownerAccountId: string;
    public dnsTemplate: any;
}

export class OrganNewPanelDnsTemplateGeneralComponent implements ng.IComponentOptions {
    public bindings = {
        ownerAccountId: '=',
        dnsTemplate: '='
    };
    public controllerAs = '$newPanelOrgan';
    public controller = OrganNewPanelDnsTemplateGeneralController;
    public template = require('./dns-template-general.html');
}
