import { NavigationService, VhostModelService } from '../../../../../services';
import { WebhostingApi } from '../../../../../types';

import * as ng from 'angular';

export class OrganEditPanelDomainHttpUserDirectoriesController {
    public static $inject: string[] = ['navigation', 'vhostModel'];

    // Bindings.
    public editFormState: string;           // State of the current form-edit.
    public newPaths: any[];                 // List of all newly created paths.
    public changedPaths: any[];             // List of all changed paths.
    public deletedPaths: any[];             // List of all deleted paths.
    public httpUserList: any[];             // List of all current http users.
    public protectedPathList: any[];        // List of all protected paths.
    public newProtections: any[];           // List of new created protections.
    public newProtectionKey = 0;    // Increment to get unique keys for newly created protections.
    public vHost: WebhostingApi.VHost;      // VHost.

    public isEditMode = false;     // Indicates wether in edit-mode.
    public httpUserDropDown: any[];         // Convert http-users to dropdown list view.

    public constructor(
        private navigation: NavigationService,
        private vhostModel: VhostModelService
    ) {}

    public resetPanel = () => {
        this.navigation.reloadCurrentState();
    };

    // Create a new empty protection.
    public addEmptyProtection = () => {
        const newIncrementedIndex = ++(this.newProtectionKey);
        this.newPaths.push({ key: newIncrementedIndex, pathName: '', addedUsers: [] });
    };

    // Returns true if the current state is not blocked.
    public get canEdit() {
        return this.editFormState.length <= 0;
    }

    public startEdit = () => {
        this.isEditMode = true;
        this.editFormState = 'location';
    };

    public cancelEdit = () => {
        this.isEditMode = false;
        this.editFormState = '';
    };

    // Write changes to API after checking for validation errors.
    public saveChanges = () => {
        const modifiedVhost = ng.copy(this.vHost);
        modifiedVhost.locations = this._mergeLocationsInVhost(modifiedVhost.locations);
        this.vhostModel
        .update(modifiedVhost)
        .then(
            () => {
                this.resetPanel();
            },
            () => {
                this.resetPanel();
            }
        );
        this.resetPanel();
    };

    // Modify path to preserve slashes at start and end.
    private _addSlashesToPath = (path) => {
        if (path.length <= 0) {
            return path;
        }
        if (!(path[0] === '/')) {
            path  = '/' + path;
        }
        if (!(path[path.length - 1] === '/')) {
            path += '/';
        }
        return path;
    };

    // Create the new locations by merging the current ones with the updated ones.
    private _mergeLocationsInVhost = (vHostLocations) => {
        let mergedLocations = vHostLocations;

        // Remove locations that are on the `deletedPaths` list.
        mergedLocations = mergedLocations.filter(
            (loc) => this.deletedPaths.indexOf(loc.matchString) < 0
        );

        // Update locations that are on the `changedLocations` list.
        mergedLocations = mergedLocations.map(
            (location) => {
                const changeLocationTo = this.changedPaths.filter(
                    (changedPath) => changedPath.old === location.matchString
                )[0];
                if (changeLocationTo === undefined) {
                    return location;
                }
                return {
                    matchString: changeLocationTo.new.path,
                    restrictToHttpUsers: changeLocationTo.new.addedUsers
                };
            }
        );

        // Insert new locations
        this.newPaths.forEach((newLocation) => {
            if (mergedLocations.filter(
                (mergedLocation) => mergedLocation.matchString === newLocation.pathName).length < 0) {
                return; // Skip if the location already exists.
            }
            mergedLocations.push({
                matchString: newLocation.pathName,
                restrictToHttpUsers: newLocation.addedUsers
            });
        });

        // Get default location as reference.
        const defaultLoc = this.vHost.locations.filter(
            (e) => e.matchType === 'default'
                && e.matchString.length <= 0
        )[0];

        // Merge and expand.
        mergedLocations.forEach((location, index, originalArray) => {
            originalArray[index] = {
                blockDotfiles: location.blockDotfiles || defaultLoc.blockDotfiles,
                directoryListingEnabled: location.directoryListingEnabled || defaultLoc.directoryListingEnabled,
                httpHeader: location.httpHeader || defaultLoc.httpHeader,
                locationType: location.locationType || defaultLoc.locationType,
                mapScript: location.mapScript || defaultLoc.mapScript,
                mapStyle: location.mapStyle || defaultLoc.mapStyle,
                matchString: this._addSlashesToPath(location.matchString),
                matchType: location.matchType || 'directory',
                phpEnabled: location.phpEnabled || defaultLoc.phpEnabled,
                redirectionStatus: location.redirectionStatus || defaultLoc.redirectionStatus,
                redirectionUrl: location.redirectionUrl || defaultLoc.redirectionUrl,
                restrictToHttpUsers: location.restrictToHttpUsers,
                setByProfile: location.setByProfile || false,
                superUserConfiguration: location.superUserConfiguration || defaultLoc.superUserConfiguration
            };
        });

        return mergedLocations;
    };

    public get canSave() {
        const modifiedVhost = ng.copy(this.vHost);
        modifiedVhost.locations = this._mergeLocationsInVhost(modifiedVhost.locations);

        // Check for missing matchStrings with type default.
        const missingPaths = modifiedVhost.locations.filter(
            (location) => location.matchString.length <= 0
            && location.matchType !== 'default'
        ).length > 0;

        // Check for duplicate matchStrings in the new locations.
        let foundDuplicates = false;
        const duplicates = modifiedVhost.locations.map(
            (location) => location.matchString
        );
        duplicates.forEach(
            (duplicate) => {
                if (
                    duplicates.filter(
                        (duplicateFilter) => duplicateFilter === duplicate
                    ).length > 1
                ) {
                    foundDuplicates = true;
                }
            }
        );

        // Check for users that arent registered in the vhost.
        let unregisteredUsers = false;
        modifiedVhost.locations
        .map(
            (location) => location.restrictToHttpUsers
        ).forEach(
            (users) => {
                if (
                    users.filter(
                        (user) => modifiedVhost.httpUsers.indexOf(user) < 0
                    ).length > 0
                ) {
                    unregisteredUsers = true;
                }
            }
        );

        // Return true if no errors were detected.
        return !foundDuplicates
            && !unregisteredUsers
            && !missingPaths;
    }

    public get showNoEntriesNotice() {
        return !this.vHost.locations.some(
            (location) => {
                return location.matchString.length > 0;
            }
        ) && this.newPaths.length === 0;
    }
}

export class OrganEditPanelDomainHttpUserDirectoriesComponent implements ng.IComponentOptions {
    public bindings = {
        changedPaths: '=',
        deletedPaths: '=',
        editFormState: '=',
        httpUserList: '<',
        newPaths: '=',
        newProtections: '=',
        protectedPathList: '<',
        vHost: '<'
    };
    public controller = OrganEditPanelDomainHttpUserDirectoriesController;
    public template = require('./domain-http-user-directories.html');
}
