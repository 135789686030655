import * as ng from 'angular';
import { ResourceModelService } from '../services/resource/resource-model';

export class VirtualMachineHost {
    public static $inject: string[] = ['resourceModel'];

    public static Factory(resourceModel: ResourceModelService) {
        const cache = {};

        const virtualMachineHostFilter: any = (value) => {
            if (cache[value]) {
                if (cache[value] !== 'waiting') {
                    return cache[value].hostName;
                }
                return undefined;
            }
            cache[value] = 'waiting';

            const filter = {
                field: 'id',
                value: value
            };

            resourceModel.findMachineHost(filter, 1, 1)
            .then(
                (result) => {
                    cache[result.id] = result;
                }, () => {
                    // Wenn der Request fehlschägt, versuchen wir es nicht erneut
                    cache[value] = 'x';
                }
            );

            return undefined;
        };

        virtualMachineHostFilter.$stateful = true;

        return virtualMachineHostFilter;
    }
}
