import * as ng from 'angular';
import { ResourceRobotService } from '../../../../../../services';
import { DashboardOverviewDataObject, OrganismOverviewController } from '../../../../../organisms';

export class MoleculeOverviewRowPanelMachinePoolController implements ng.IController {
    public static $inject: string[] = ['resourceRobot'];

    public overviewData: DashboardOverviewDataObject;
    public overviewLoad: (selection: any) => any;
    public internalPaginationLimit: number;
    public OrganismOverview: OrganismOverviewController;
    public poolResources: any = { DB: [], WH: [] };
    public displayPoolDetailsModal = false;
    public selectedPool: any = {};

    constructor(
        private resourceRobot: ResourceRobotService
    ) {}

    public get showAccounts() {
        return this.OrganismOverview.globals.showAccounts;
    }

    public displayPoolDetails = (pool: any) => {
        this.selectedPool = pool;
        this.poolResources = { DB: [], WH: [] };
        this.displayPoolDetailsModal = true;
        this.resourceRobot.listResources(pool.id, 'Database', null, 10, 1, null).then(
            (api) => {
                this.poolResources.DB = api.response.data;
            }
        );
        this.resourceRobot.listResources(pool.id, 'Webserver', null, 10, 1, null).then(
            (api) => {
                this.poolResources.WH = api.response.data;
            }
        );
    };
}

export class MoleculeOverviewRowPanelMachinePoolComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelMachinePoolController;
    public controllerAs = 'OverviewMachineCtrl';
    public template = require('./machine-pool-row.html');
}
