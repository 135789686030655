import { ValidateNotEmpty } from '../../../../../services';

import * as ng from 'angular';

import './panel-edit-row-domain-directory-protection-edit-path.scss';

export class MoleculePanelEditRowDomainDirectoryProtectionEditPathController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public isMarkedRemoval = false;
    public isReadonly: boolean;
    public isEditing = false;
    public originalPathName: string;
    public pathName: any;
    public originalUserList: any[];
    public userList: any[];
    public httpUserList: any[];
    public originalAddedUsers: any[];
    public addedUsers: any[];
    public selectedDropdownUserIndex: number = null;

    public deletedPaths: string[];
    public changedPaths: any[];

    // Validate path.
    public pathValidationErrorList: any[];
    public pathValidationInstructions: any[] = [{
        settings: null,
        validator: new ValidateNotEmpty(this.$translate)
    }];

    public constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.originalPathName = this.pathName;
        this.originalUserList = ng.copy(this.userList);
        this.originalAddedUsers = ng.copy(this.addedUsers);
        this._setUserDropdown();
    }

    public toggleEdit = () => {
        if (!this.isReadonly) {
            this.isEditing = !this.isEditing;
        }
    };

    public confirmChanges = () => {
        // End edit mode.
        this.isEditing = false;

        // Find existing change.
        let foundAt = -1;
        this.changedPaths.forEach((changedPath, index) => {
            if (changedPath.old === this.originalPathName) {
                foundAt = index;
            }
        });

        // Insert new change or override any existing one.
        const changedEntry = {
            new: {
                addedUsers: this.addedUsers,
                path: this.pathName
            },
            old: this.originalPathName
        };
        if (foundAt >= 0) {
            this.changedPaths[foundAt] = changedEntry;
        } else {
            this.changedPaths.push(changedEntry);
        }
    };

    public cancelChanges = () => {
        this.pathName = this.originalPathName;
        this.addedUsers = ng.copy(this.originalAddedUsers);
        this.isEditing = false;
    };

    public toggleDelete = () => {
        if (this.deletedPaths.indexOf(this.originalPathName) < 0) {
            // Mark the user for removal.
            this.deletedPaths.push(this.originalPathName);
            this.isMarkedRemoval = true;
        } else {
            this.deletedPaths.splice(this.deletedPaths.indexOf(this.originalPathName), 1);
            this.isMarkedRemoval = false;
        }
    };

    // Update the current items in the user dropdown select, filtering those that already got picked.
    private _setUserDropdown = () => {
        this.selectedDropdownUserIndex = null;

        this.httpUserList = this.userList.map(
            (httpUser, index) => {
                return httpUser;
            }
        );
    };
}

export class MoleculePanelEditRowDomainDirectoryProtectionEditPathComponent implements ng.IComponentOptions {
    public bindings = {
        addedUsers: '<',
        changedPaths: '=',
        deletedPaths: '=',
        isReadonly: '<',
        pathName: '<',
        userList: '<'
    };

    public controller = MoleculePanelEditRowDomainDirectoryProtectionEditPathController;
    public template = require('./panel-edit-row-domain-directory-protection-edit-path.html');
}
