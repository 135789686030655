export class FloatToApi {
    public static Factory() {
        return FloatToApi.convertNumericToApiInt;
    }

    public static convertNumericToApiInt = (value: number | string): number => {
        if (typeof value === 'number') {
            return FloatToApi.convertToInt(value);
        } else if (typeof value === 'string') {
            if (value.indexOf(',') >= 0) {
                const newValue = value.replace(/,/gi, '.');
                return FloatToApi.convertToInt(Number(newValue));
            } else {
                return FloatToApi.convertToInt(Number(value));
            }
        }
    };

    private static convertToInt = (value: number): number => {
        return Number((Number(value.toFixed(2)) * 10000.0).toFixed());
    };
}
