import { Validator } from './abstract-validator';

export class ValidateAllowedCharacters extends Validator {
    private allowedCharacters;

    constructor(allowedCharacters: string, private $translate: ng.translate.ITranslateService) {
        super();
        this.allowedCharacters = allowedCharacters;
    }

    public validate = (value) => {
        let illegalCharactersFound = '';
        let illegalCharactersMessage = '';

        for (const char of value) {
            if (this.allowedCharacters.indexOf(char) < 0
                && illegalCharactersFound.indexOf(char) < 0
            ) {
                if (char !== ' ') {
                    illegalCharactersMessage += char;
                } else {
                    illegalCharactersMessage += ' ' + this.$translate.instant('TR_160119-c1f1a8_TR') + ' ';
                }

                illegalCharactersFound += char;
            }
        }

        if (illegalCharactersFound.length > 0) {
            return [
                {
                    text: this.$translate.instant(
                        /* translationId */ 'TR_160119-5e8caf_TR',
                        { illegalCharactersMessage: illegalCharactersMessage }
                    )
                }
            ];
        }

        return [];
    };
}
