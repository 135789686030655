import { DnsApi } from '@/types';
import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels';

export class TemplateDnsWizardController {
    public static $inject: string[] = ['$state', '$translate'];

    public pageHeaderData: PanelHeaderData;
    public productCode: string;
    public productFamily: string;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        const preselectedZone: DnsApi.ZoneConfig = this.$state.$current.locals.globals.zone;

        if (this.productFamily === 'ddns-hostnames') {
            this.pageHeaderData = {
                backwardLink: 'dns.ddns.hostnames.overview',
                backwardText: this.$translate.instant('TR_130319-67ad15_TR'),
                panelHeaderRoute: '',
                panelHeaderRouteParams: {},
                panelIcon: 'location-arrow',
                panelTitle: this.$translate.instant('TR_130319-bb7b6b_TR')
            };
        } else {
            this.pageHeaderData = {
                backwardLink: 'dns.dashboard',
                backwardText: this.$translate.instant('TR_100119-a3c01f_TR'),
                panelHeaderRoute: '',
                panelHeaderRouteParams: {},
                panelIcon: 'location-arrow',
                panelTitle: this.$translate.instant('e7791f8c-44a9-45f6-b38c-4b2d7f3ae22a')
            };
        }

        if (preselectedZone !== undefined) {
            if (preselectedZone.type === 'SLAVE') {
                this.pageHeaderData.panelTitle = preselectedZone.name + ' '
                    + this.$translate.instant('TR_080219-93a965_TR');
            } else {
                this.pageHeaderData.panelTitle = preselectedZone.name + ' '
                    + this.$translate.instant('TR_080219-775818_TR');
            }
            this.pageHeaderData.backwardText = this.$translate.instant('TR_100119-4e4e17_TR');
            this.pageHeaderData.backwardLink = 'dns.zones.id.edit';
            this.pageHeaderData.panelHeaderRouteParams = { zoneId: preselectedZone.id };
        }
    }
}

export class TemplateDnsWizardComponent implements ng.IComponentOptions {
    public bindings = {
        productCode: '@?',
        productFamily: '@?'
    };
    public template = require('./dns-wizard-template.html');
    public controller = TemplateDnsWizardController;
}
