import * as ng from 'angular';

import { PanelHeaderData } from '../../../atomic-components/molecules/panels';
import { UiLanguagesReverseConst } from '../../../configuration';
import { AuthContextService } from '../../../services';
import * as Types from '../../../types';

export class TemplateDomainContactNewController {
    public static $inject: string[] = ['$translate', 'authContext'];

    public newContact: Types.DomainApi.Contact;
    public pageHeaderData: PanelHeaderData;
    public userPanelRight;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'domain.contacts.overview',
            backwardText: this.$translate.instant('TR_100119-851cd6_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: 'globe',
            panelTitle: this.$translate.instant('DOMAIN.DIRECTIVES.HANDLE-CHOOSER.BUTTONS.ADD.TITLE')
        };

        this.userPanelRight = {
            editPanelButton: false
        };

        // Creating new 'empty' default contact.
        this.newContact = {
            city: '',
            country: UiLanguagesReverseConst.de_DE,
            street: [''],
            type: 'person'
        };
    };
}

export class TemplateDomainContactNewComponent implements ng.IComponentOptions {
    public controller = TemplateDomainContactNewController;
    public controllerAs = '$TemplateDomainContactNewCtrl';
    public template = require('./domain-contacts-new-template.html');
}
