import * as ng from 'angular';
import { LinkListItem } from '../../../../atomic-components/molecules/links';
import { PanelHeaderData } from '../../../../atomic-components/molecules/panels';
import { DashboardOverviewObject } from '../../../../atomic-components/organisms/overview';
import { UiRights } from '../../../../configuration';
import {
    AuthContextService,
    DatabaseOverviewModel,
    MailboxOverviewModel,
    WebspaceOverviewModel
} from '../../../../services';

export class TemplateMachineManagedDashboardController {
    public static $inject: string[] = [
        '$state',
        '$translate',
        'databaseOverviewModel',
        'mailboxOverviewModel',
        'webspaceOverviewModel'
    ];

    public userHasRightWebObjectList = AuthContextService.isGranted(UiRights.WEB_OBJECT_LIST); // used in template!
    public pageHeaderData: PanelHeaderData;
    public machine;
    public subaccounts = 1;
    public dashboardOverview: DashboardOverviewObject;
    public panelItems: LinkListItem[];
    public databaseRessource;
    public webserverRessource;
    public pool;
    public hasOnlyOneServer = false;

    public faqArticleIdList: string[] = [
        'machine-managed-vs-cloud'
    ];

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private databaseOverviewModel: DatabaseOverviewModel,
        private mailboxOverviewModel: MailboxOverviewModel,
        private webspaceOverviewModel: WebspaceOverviewModel
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: this.hasOnlyOneServer ? null : 'managed-servers.overview',
            backwardText: this.hasOnlyOneServer ? null : this.$translate.instant('TR_140319-1f06b7_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: 'cloud-upload',
            panelTitle: this.machine.name,
            productActions: []
        };

        if (AuthContextService.isGranted(UiRights.MACHINE_MANAGED_SERVER_CREATE)) {
            this.pageHeaderData.productActions.push({
                route: 'managed-servers.new',
                title: this.$translate.instant('TR_210319-ecef81_TR')
            });
        }

        if (AuthContextService.isGranted(UiRights.MACHINE_VM_DELETE)) {
            this.pageHeaderData.productActions.push({
                route: 'managed-servers.id.cancel',
                stateParams: { machineId: this.$state.$current.locals.globals.machine.id },
                title: this.$translate.instant('TR_110419-08d991_TR')
            });
        }

        this.panelItems = [
            {
                isGranted: AuthContextService.isGrantedAny([
                    UiRights.WEB_OBJECT_LIST,
                    UiRights.DB_DATABASE_LIST
                ]),
                route: 'managed-servers.id.new-project',
                routeParams: {},
                text: this.$translate.instant('TR_140319-90e2cb_TR')
            },
            {
                isGranted: AuthContextService.isGranted(UiRights.WEB_WEBSPACE_CREATE),
                route: 'managed-servers.id.webspaces.new',
                routeParams: {},
                text: this.$translate.instant('TR_140119-4563cf_TR')
            },
            {
                isGranted: AuthContextService.isGranted(UiRights.DB_DATABASE_CREATE),
                route: 'managed-servers.id.databases.new',
                routeParams: {},
                text: this.$translate.instant('TR_100119-dfe5ab_TR')
            },
            {
                isGranted: AuthContextService.isGrantedAny([
                    UiRights.EMAIL_EXCHANGE_CREATE,
                    UiRights.EMAIL_FORWARDER_CREATE,
                    UiRights.EMAIL_IMAPMAILBOX_CREATE,
                    UiRights.EMAIL_SMTP_FORWARDER_CREATE // ???
                ]),
                route: 'managed-servers.id.mailboxes.new',
                routeParams: {},
                text: this.$translate.instant('TR_110119-85a51b_TR')
            }
        ];

        if (this.pool !== undefined) {
            const $ctrl = this;
            let webspaces = [];
            let added = false;

            this.dashboardOverview = {
                // List of table service data
                overviewsData: [
                    // Webspaces
                    {
                        listOptions: { // optional
                            additionalFilters: [
                                {
                                    field: 'webserverId',
                                    value: this.webserverRessource !== null ? this.webserverRessource.id : null
                                }
                            ],
                            limit: 4,
                            objectId: null,
                            sort: {
                                field: 'WebspaceLastChangeDate',
                                order: 'DESC'
                            }
                        },
                        overviewModel: this.webspaceOverviewModel,
                        panelHeaderData: {
                            includeShowAllLink: true,
                            includeShowDetailsLink: false,
                            panelHeaderRoute: 'managed-servers.id.webspaces.overview',
                            panelHeaderRouteParams: { machineId: this.machine.id },
                            panelIcon: 'server',
                            panelTitle: this.$translate.instant('WEBHOSTING.GENERAL.WEBSPACES')
                        },
                        // serviceItemList: []
                        get serviceItemList() { return webspaces; },
                        set serviceItemList(value) {
                            webspaces = value;

                            if (!added && webspaces.length > 0) {
                                added = true;
                                $ctrl.panelItems.push(
                                    {
                                        isGranted: AuthContextService.isGranted(UiRights.WEB_VHOST_CREATE),
                                        route: 'managed-servers.id.domains.new',
                                        routeParams: {},
                                        text: $ctrl.$translate.instant('TR_100119-4a31ea_TR')
                                    }
                                );
                            }
                        }
                    },
                    {
                        listOptions: {
                            additionalFilters: [
                                {
                                    field: 'DatabaseServerId',
                                    value: this.databaseRessource !== null ? this.databaseRessource.id : null
                                }
                            ],
                            limit: 4,
                            sort: {
                                field: 'DatabaseLastChangeDate',
                                order: 'DESC'
                            }
                        },
                        overviewModel: this.databaseOverviewModel,
                        panelHeaderData: {
                            includeShowAllLink: true,
                            includeShowDetailsLink: false,
                            panelHeaderRoute: 'managed-servers.id.databases.overview',
                            panelHeaderRouteParams: { machineId: this.machine.id },
                            panelIcon: 'database',
                            panelTitle: this.$translate.instant('TR_090119-f0ccfb_TR')
                        },
                        serviceItemList: []
                    },
                    {
                        listOptions: {
                            additionalFilters: [{
                                field: 'poolId',
                                value: this.pool !== null ? this.pool.id : null
                            }],
                            limit: 6,
                            sort: {
                                field: 'MailboxLastChangeDate',
                                order: 'DESC'
                            }
                        },
                        overviewModel: this.mailboxOverviewModel,
                        panelHeaderData: {
                            includeShowAllLink: true,
                            includeShowDetailsLink: false,
                            panelHeaderRoute: 'managed-servers.id.mailboxes.overview',
                            panelHeaderRouteParams: { machineId: this.machine.id },
                            panelIcon: 'envelope',
                            panelTitle: this.$translate.instant('MENU.CONTEXT.EMAIL.ADDRESSES')
                        },
                        serviceItemList: []
                    }
                ],
                // Dashboard main menu panel
                panelData: {
                    addtionalActionData: {
                        // Addition action panel
                        linkList: []
                    },
                    mainHeader: {
                        route: 'managed-servers.id.new-project',
                        text: this.$translate.instant('TR_140319-90e2cb_TR')
                    }
                }
            };
        }
    };
}

export class TemplateMachineManagedDashboardComponent implements ng.IComponentOptions {
    public bindings = {
        databaseRessource: '<',
        hasOnlyOneServer: '<?',
        machine: '<',
        pool: '<?',
        subaccounts: '<',
        webserverRessource: '<'
    };
    public controller = TemplateMachineManagedDashboardController;
    public controllerAs = '$TemplateMachineDashboardCtrl';
    public template = require('./machine-managed-dashboard-template.html');
}
