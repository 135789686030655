import * as ng from 'angular';

import { LinkProperties } from '@/atomic-components';

export interface TableHeader {
    columnName: string;
}

export interface TableColumn {
    value?: any;
    link?: LinkProperties;
    html?: any;
}

export interface TableRow {
    columns: TableColumn[];
}

export interface Table {
    headers: TableHeader[];
    rows: TableRow[];
}

export class MoleculePanelEditRowTableGenericController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public table: Table = { headers: [], rows: []};
    private emptyTableMessage: string;
    private tableEntryName: { names: string };

    public constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = () => {
        this.emptyTableMessage = this.$translate.instant('TR_220420-cc570e_TR', this.tableEntryName);
    };

}

export class MoleculePanelEditRowTableGenericComponent implements ng.IComponentOptions {
    public bindings = {
        table: '<?',
        tableEntryName: '<?'
    };
    public controller = MoleculePanelEditRowTableGenericController;
    public controllerAs = '$panelEditRowTable';
    public template = require('./generic.html');
}
