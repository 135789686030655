import { GeneralValidator } from '@/services';
import * as ng from 'angular';
import './add-text-to-list.scss';

export class MoleculeFormAddTextToListController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public callbackOnChange: (arg0: any) => any;
    public callbackOnPaste: (pasteEvent: ClipboardEvent) => any;
    public newTextValueModel: string;
    public placeholder: string;
    public addCallback: (param: any) => any;
    public validationInstructions: any[];
    public validationSuccessful = true;
    public validationErrorList: any[] = [];
    public disabled: boolean;

    public addOnPaste: (pasteEvent: ClipboardEvent) => { entryList: string[], validationError: string };
    public getItemsToAdd: (input: string) => { entryList: string[], validationError: string };
    public callbackOnBlur: (input: string) => {};

    public allowTextArea = false;
    public forceTextArea = false;
    public isTextArea = false;

    private oldTextValueModel: string;

    private validator;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {
        this.validator = new GeneralValidator(this.$translate);
    }

    public $onInit() {
        if (this.oldTextValueModel !== this.newTextValueModel) {
            this.oldTextValueModel = this.newTextValueModel;
        }
        if (this.forceTextArea) {
            this.isTextArea = true;
        }
    }

    public onPaste = (pasteEvent: ClipboardEvent) => {
        if (this.addOnPaste) {
            const itemsToAdd = this.addOnPaste(pasteEvent);
            if (itemsToAdd.validationError === '') {
                itemsToAdd.entryList.map(entry => {
                    this.addCallback({ value: entry });
                });
            }
        }
        if (this.callbackOnPaste !== undefined) {
            this.callbackOnPaste(pasteEvent);
        }
    };

    public toggleTextArea = () => {
        this.isTextArea = !this.isTextArea;
    };

    public get showToggleTextAreaButton() {
        return !this.forceTextArea && this.allowTextArea;
    }

    public addTextToList = (text?: string): void =>  {
        if (this.disableAddButton && !text) {
            return;
        }

        if (this.addCallback === undefined || !this.validate()) {
            return;
        }

        const input = text || this.newTextValueModel;
        if (this.getItemsToAdd) {
            const itemsToAdd = this.getItemsToAdd(input);
            if (itemsToAdd.validationError === '') {
                itemsToAdd.entryList.map(entry => {
                    this.addCallback({ value: entry });
                });
            }
        } else {
            this.addCallback({ value: input });
        }
    };

    public onBlur = () => {
        if (this.callbackOnBlur) {
            this.callbackOnBlur(this.newTextValueModel);
        }
        this.validate();
    }

    public validate = () => {
        if (this.callbackOnChange !== undefined) {
            this.callbackOnChange(this.newTextValueModel);
        }

        if (this.oldTextValueModel === this.newTextValueModel) {
            return true;
        }

        if (this.validationErrorList.length > 0) {
            this.validationErrorList = [];
        }

        if (!this.validationInstructions) {
            return true;
        }

        // this validation is executed for mail adresses, especially within the email-adress-list component. Should be handled there not here.
        if (this.isTextArea) {
            this.validationErrorList = this.newTextValueModel.split('\n')
            .map((address) => address.trim())
            .filter((address) => [undefined, null, ''].indexOf(address) < 0)
            .map((address) => this.validator.validate(address, this.validationInstructions))
            .reduce(
                (errorList: any[], nextErrors: any[]) => {
                    nextErrors.forEach(
                        (error) => {
                            if (errorList.every((otherError) => error.text !== otherError.text)) {
                                errorList.push(error);
                            }
                        }
                    );

                    return errorList;
                },
                []
            );
        } else {
            this.validationErrorList = this.validator.validate(this.newTextValueModel, this.validationInstructions);
        }

        if (this.validationErrorList.length === 0) {
            this.validationSuccessful = true;
        }

        if (this.oldTextValueModel !== this.newTextValueModel) {
            this.oldTextValueModel = this.newTextValueModel;
        }

        return this.validationSuccessful;
    };

    public get disableAddButton() {
        return this.validationErrorList.length > 0
        || this.newTextValueModel === undefined
        || this.newTextValueModel === ''
        || this.disabled;
    }
}

export class MoleculeFormAddTextToListComponent implements ng.IComponentOptions {
    public bindings = {
        addCallback: '<',
        allowTextArea: '<?',
        forceTextArea: '<?',
        callbackOnChange: '<',
        callbackOnPaste: '<?',
        disabled: '<',
        newTextValueModel: '=',
        placeholder: '@',
        validationInstructions: '<?',
        validationErrorList: '=?',
        addOnPaste: '<?',
        getItemsToAdd: '<?',
        callbackOnBlur: '<?',
    };
    public controller =  MoleculeFormAddTextToListController;
    public template = require('./add-text-to-list.html');
}
