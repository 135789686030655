import * as ng from 'angular';
import { DateWrapper } from '../../../../../services';
import { DomainContactHelperService, SystemHelperService } from '../../../../../services/helpers';

export class OrganEditPanelDomainContactIdentityCardController {
    public static $inject: string[] = ['domainContactHelper', 'systemHelper'];

    public $editFormMolecule;
    public $organismCtrl;
    public contact;
    public panelRight;
    public extIdentificationCardIssueDate: DateWrapper;
    public extIdentificationCardValidUntil: DateWrapper;
    public currentDate: Date;

    private _contactOrig;
    private _contactPartParams = [
        'extIdentificationCardNumber',
        'extIdentificationCardCountry',
        'extIdentificationCardIssuingAuthority',
        'extIdentificationCardIssueDate',
        'extIdentificationCardValidUntil'
    ];

    constructor(
        public domainContactHelper: DomainContactHelperService,
        public systemHelper: SystemHelperService
    ) {}

    public $onInit() {
        this._contactOrig = ng.copy(this.contact);
        this.currentDate = new Date();
        if ([undefined, null].indexOf(this.contact) >= 0) {
            return;
        }
        if ([undefined, null, ''].indexOf(this.contact.extIdentificationCardIssueDate) === -1) {
            this.extIdentificationCardIssueDate = new DateWrapper();
            this.extIdentificationCardIssueDate.dateString = this.contact.extIdentificationCardIssueDate;
        }
        if ([undefined, null, ''].indexOf(this.contact.extIdentificationCardValidUntil) === -1) {
            this.extIdentificationCardValidUntil = new DateWrapper();
            this.extIdentificationCardValidUntil.dateString = this.contact.extIdentificationCardValidUntil;
        }
    }

    public $onDestroy() {
        this.cancel();
    }

    public cancel = () => {
        this._contactPartParams.map((param) => this.contact[param] = ng.copy(this._contactOrig[param]));
    };

    public setDateObj = () => {
        this.contact.extIdentificationCardIssueDate = this.extIdentificationCardIssueDate;
    };

    public setExtIdentificationCardIssueDate = (selection) => {
        this.contact.extIdentificationCardIssueDate = selection.dateString;
    };

    public setExtIdentificationCardValidUntil = (selection) => {
        this.contact.extIdentificationCardValidUntil = selection.dateString;
    };
}

export class OrganEditPanelDomainContactIdentityCardComponent implements ng.IComponentOptions {
    public bindings = {
        $organismCtrl: '<organismCtrl',
        contact: '=',
        panelRight: '<'
    };
    public require = {
        $editFormMolecule: '^moleculeFormEdit'
    };
    public template = require('./domain-contact-identity-card.html');
    public controller = OrganEditPanelDomainContactIdentityCardController;
    public controllerAs = '$editPanelOrgan';
}
