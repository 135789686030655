import ng from 'angular';
import { Breadcrumb } from '../breadcrumb';
import { BreadcrumbTopicModel, TopicCases } from './topic-model';

export class BreadcrumbErrorTopicModel extends BreadcrumbTopicModel {
    public static $inject: string[] = BreadcrumbTopicModel.$inject.concat([]);

    readonly topicName = 'error';
    readonly specialTopicCases: TopicCases;

    /**
     *   List of route parts, which are excluded from mail class generalRoutes methode
     */
    readonly excludeGeneralRouteParts = [];

    constructor(protected $state: ng.ui.IStateService, protected $translate: ng.translate.ITranslateService) {
        super($state, $translate);
    }

    public getFirstLevelData = (): Breadcrumb[] => {
        this.breadcrumbs = [
            {
                type: 'text',
                value: this.$translate.instant('GENERAL.TOASTR.ERROR-TITLE'),
                active: false
            },
            {
                type: 'text',
                value: this.$translate.instant('TR_090119-cc9d25_TR'),
                active: true
            }
        ];

        return this.breadcrumbs;
    };
}
