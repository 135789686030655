import * as ng from 'angular';
import { OrganismOverviewController, OverviewDataObject } from '../../../../../organisms';

export class MoleculeOverviewRowPanelDnsTemplateController implements ng.IController {
    public static $inject: string[] = [];

    public overviewData: OverviewDataObject;
    public OrganismOverview: OrganismOverviewController;
    public overviewLoad: (selection: any) => any;
    public internalPaginationLimit: number;

    public get showAccounts() {
        return this.OrganismOverview.globals.showAccounts;
    }

    public get hasEntries(): boolean {
        return this.OrganismOverview.overviewLoaded
        && this.overviewData.serviceItemList.length > 0
        && !this.OrganismOverview.dataNotLoadedOnInit;
    }
}

export class MoleculeOverviewRowPanelDnsTemplateComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller = MoleculeOverviewRowPanelDnsTemplateController;
    public controllerAs = 'OverviewDnsTemplateCtrl';
    public template = require('./dns-template-row.html');
}
