import { CacheService } from '../cache';
import {
    RpcClient,
    RpcClientService
} from '../rpc-client';

export const OrchestratorClientService = [
    'rpcClient',
    (platformRpcClient: RpcClientService) => platformRpcClient.Factory('/api/orchestration/v1/json/', 'product')
];

export class OrchestratorRobotService {
    public static $inject: string[] = ['cache', 'orchestratorClient'];

    constructor(
        private cache: CacheService,
        private orchestratorClient: RpcClient
    ) {}

    public order(orderItem, owner?) {
        return this.orchestratorClient.request('order')
        .ownerAccountId(owner)
        .execute({orderItem: orderItem})
        .then(
            (res) => {
                this.cache.clearAll();
                return res;
            }
        );
    }

    public listJobs = (filter, limit?: number, page?: number, sort?) => {
        page = page || 1;

        return this.orchestratorClient.request('jobsFind')
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };
}
