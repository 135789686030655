import * as ng from 'angular';
import {
    ArticleSearchModelService,
    HelpdeskRobotService
} from '../../../services/helpdesk';

export const HELPDESK_ROUTES = [{
        name: 'helpdesk',
        state: {
            params: {
                level1: { value: null, squash: true },
                level2: { value: null, squash: true },
                level3: { value: null, squash: true },
                level4: { value: null, squash: true },
                level5: { value: null, squash: true },
                level6: { value: null, squash: true },
                q: null
            },
            parent: 'authenticated',
            reloadOnSearch: false,
            resolve: {
                apiIsDown: (
                    helpdeskRobotService: HelpdeskRobotService
                ) => {
                    return helpdeskRobotService.getCategories('/').then(
                        (reply) => {
                            return 'error' in (reply.data as any);
                        }
                    );
                },
                getBreadcrumb: (
                    $stateParams: ng.ui.IStateParamsService,
                    articleSearchModel: ArticleSearchModelService,
                    getTemplateName,
                    apiIsDown
                ) => {
                    if ($stateParams.level1 === null || apiIsDown) {
                        return [];
                    } else {
                        return articleSearchModel.getBreadcrumbFromApi(getTemplateName, $stateParams);
                    }
                },
                getTemplateName: (
                    $stateParams: ng.ui.IStateParamsService,
                    helpdeskRobotService: HelpdeskRobotService,
                    apiIsDown
                ) => {
                    if ($stateParams.level1 === null || apiIsDown) {
                        return 'dashboard';
                    } else {
                        return helpdeskRobotService.getTemplateName($stateParams);
                    }
                }
            },
            url: '/helpdesk/:level1/:level2/:level3/:level4/:level5/:level6?q',
            views: {
                'content@authenticated': {
                    templateProvider: (getTemplateName: any, $stateParams: ng.ui.IStateParamsService) => {
                        switch (getTemplateName) {
                            case 'article':
                                return '<template-helpdesk template-name="article"></template-helpdesk>';
                            case 'category':
                                return '<template-helpdesk template-name="category"></template-helpdesk>';
                            default:
                            case 'dashboard':
                                return '<template-helpdesk template-name="dashboard"></template-helpdesk>';
                        }
                    }
                }
            }
        }
    },
    {
        name: 'helpdesk.dashboard',
        state: {
            params: {
                q: null
            },
            parent: 'authenticated',
            reloadOnSearch: false,
            url: '/helpdesk?q',
            views: {
                'content@authenticated': {
                    template: '<template-helpdesk template-name="dashboard"></template-helpdesk>'
                }
            }
        }
    }
];

// Breadcrumb
// URL: helpdesk/category/[category/.../]article/
