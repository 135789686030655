import ng from 'angular';

import q from 'q';
import { DatabaseUserObject, EditPanelStatus } from '@/atomic-components/molecules';
import {
    AlertManagerService,
    AuthContextService,
    DatabaseUserModelService,
    NavigationService
} from '@/services';
import * as Types from '@/types';

export class OrganismNewFormDatabaseUserController {
    public static $inject: string[] = ['$translate', 'alertManager', 'databaseUserModel', 'navigation'];

    public userPanelRight: any;
    public initialStatus = EditPanelStatus.ACTIVE;
    public newUserObject: DatabaseUserObject = {
        accountId: '',
        comments: '',
        name: '',
        password: ''
    };

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private databaseUserModel: DatabaseUserModelService,
        private navigation: NavigationService
    ) {}

    public get showSaveButton() {
        return this.newUserObject.name.length > 0
            && [undefined, null, ''].indexOf(this.newUserObject.password) < 0;
    }

    public set showSaveButton({}) {} // tslint:disable-line:no-empty

    public save = () => {
        return this.databaseUserModel.createUser(
            this.newUserObject,
            this.newUserObject.password,
            AuthContextService.account.id
        ).then(
            (user) => {
                if (user.status !== 'error') {
                    this.alertManager.success(this.$translate.instant('TR_010719-661c63_TR'));
                    this.navigation.go('database.users.id.edit', { userId: user.id });
                    return user;
                }

                return q.reject(user);
            }
        ) as ng.IPromise<Types.DatabaseApi.Database>;
    };
}

export class OrganismNewFormDatabaseUserComponent implements ng.IComponentOptions {
    public controllerAs = '$editFormOrganism';
    public controller = OrganismNewFormDatabaseUserController;
    public template = require('./database-user-new.html');
}
