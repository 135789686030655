import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';

export class TemplateMachinePoolNewController {
    public static $inject: string[] = ['$translate', 'authContext'];

    public modifier = '';
    public pageHeaderData: PanelHeaderData;

    public userPanelRight;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'machine.pools',
            backwardText: this.$translate.instant('TR_140119-ff0b91_TR'),
            panelHeaderRoute: '',
            panelIcon: 'server',
            panelTitle: this.$translate.instant('RESOURCE.POOL.NEW.TITLE')
        };

        this.userPanelRight = {
            general: {
                editPanelButton: this.authContext.isGranted(UiRights.RES_POOL_CREATE)
            }
        };
    };
}

export class TemplateMachinePoolNewComponent implements ng.IComponentOptions {
    public bindings = {};
    public template = require('./machine-pool-new-template.html');
    public controller = TemplateMachinePoolNewController;
}
