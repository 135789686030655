import * as ng from 'angular';
import { InputPlaceholderConst, UiRights } from '../../../../../configuration';
import { AuthContextService, DnsZoneModelService } from '../../../../../services';

export class OrganEditPanelDnsZoneSlaveController {
    public static $inject: string[] = ['authContext', 'dnsZoneModel'];

    public inputPlaceholder = InputPlaceholderConst;
    public zone: any;
    public originalZone: any;
    public hideEditButton: boolean;
    public panelRight = {
        editPanelButton: true
    };

    constructor(
        private authContext: AuthContextService,
        private dnsZoneModel: DnsZoneModelService
    ) {}

    $onInit() {
        this.panelRight.editPanelButton = this.zone.status !== 'active'
            || this.authContext.isGranted(UiRights.DNS_ZONES_EDIT);
        this.originalZone = ng.copy(this.zone);
        if (undefined !== this.hideEditButton) {
            this.panelRight.editPanelButton = !this.hideEditButton;
        }
    }

    public cancel = () => {
        this.zone = ng.copy(this.originalZone);
    };
}

export class OrganEditPanelDnsZoneSlaveComponent implements ng.IComponentOptions {
    public bindings = {
        zone: '=',
        hideEditButton: '=?'
    };
    public controller = OrganEditPanelDnsZoneSlaveController;
    public template = require('./dns-zone-slave.html');
}
