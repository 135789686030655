import { UiRights } from '@/configuration/rights';
import {
    FilterCacheService,
    NavigationService,
    RedirectOnNotFoundCallback,
    UserSettingsManagerService
} from '@/services';
import { NetworkOverviewModel } from '@/services/network/network-overview-model';
import { ResourceModelService } from '@/services/resource/resource-model';

export const NETWORK_ROUTES = [
    {
        name: 'network',
        state: {
            abstract: true,
            parent: 'authenticated',
            url: '/network'
        }
    },
    {
        name: 'network.dashboard',
        state: {
            data: {
                isGrantedAny: [UiRights.RES_NETWORK_LIST]
            },
            onEnter: (navigation: NavigationService, userSettingsManager: UserSettingsManagerService) => {
                userSettingsManager.getUiSettings().then(
                    (result) => {
                        if (result?.overviewSettings?.VirtualMachineOverviewCtrl?.expertView) {
                            navigation.go('network.networks.overview');
                        }
                    }
                );
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('Networks');
                },
                overviewModel: (networkOverviewModel: NetworkOverviewModel) => {
                    return networkOverviewModel;
                },
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('NetworkOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('Networks');
                }
            },
            url: '/',
            views: {
                'content@authenticated': {
                    template: `<template-network-dashboard
                            simple-filter="$resolve.simpleFilter"
                            extended-filters="$resolve.extendedFilters"
                            overview-ui-settings="$resolve.overviewUiSettings"
                            overview-model="$resolve.overviewModel"
                        ></template-network-dashboard>`
                }
            }
        }
    },
    {
        name: 'network.networks',
        state: {
            abstract: true,
            url: '/networks'
        }
    },
    {
        name: 'network.networks.overview',
        state: {
            data: {
                isGranted: UiRights.MACHINE_VM_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('Networks');
                },
                overviewModel: (networkOverviewModel: NetworkOverviewModel) => {
                    return networkOverviewModel;
                },
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('NetworkOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('Networks');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-network-overview
                        simple-filter="$resolve.simpleFilter"
                        extended-filters="$resolve.extendedFilters"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        overview-model="$resolve.overviewModel"
                    ></template-network-overview>`
                }
            }
        }
    },
    {
        name: 'network.networks.id',
        state: {
            abstract: true,
            resolve: {
                network: (
                    $stateParams: ng.ui.IStateParamsService,
                    redirectOnNotFound: RedirectOnNotFoundCallback,
                    resourceModel: ResourceModelService
                ) => {
                    return resourceModel.findOne('Network', $stateParams.networkId).then(null, redirectOnNotFound);
                }
            },
            url: '/id/{networkId}'
        }
    },
    {
        name: 'network.networks.id.edit',
        state: {
            data: {
            },
            resolve: {
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-network-edit
                        network="$resolve.network"
                    ></template-network-edit>`
                }
            }
        }
    }
];
