import * as ng from 'angular';
import './search-bar.scss';

import { LongDebounceInterval } from '@/configuration';

export class MoleculeSearchBarController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public longDebounceInterval = LongDebounceInterval;
    public inputPlaceholder = '';
    public searchValue: string;
    public toggleFancyFilter: () => void;
    private searchCallback: () => {};

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {
        this.inputPlaceholder = this.$translate.instant('GENERAL.SEARCH');
    }

    public $onInit() {
        this.searchValue = this.searchValue || '';
    }

    public search = () => {
        if (this.searchCallback) {
            this.searchCallback();
        }
    };
}

export class MoleculeSearchBarComponent implements ng.IComponentOptions {
    public bindings = {
        disableExpertView: '<',
        disableFilter: '<',
        searchCallback: '<',
        searchValue: '=model',
        toggleFancyFilter: '<'
    };
    public controller =  MoleculeSearchBarController;
    public template = require('./search-bar.html');
}
