import * as ng from 'angular';
import {
    OrganismDashboardOverviewsController
} from '../../../../../organisms/overview/dashboard-overviews/dashboard-overviews';

export class MoleculeOverviewRowPanelDashboardDomainTransferController implements ng.IController {
    public static $inject: string[] = [];

    public serviceItemList;
    public OrganismDashboardOverview: OrganismDashboardOverviewsController;
    public service: string;
    public objectType: string;
}

export class MoleculeOverviewRowPanelDashboardDomainTransferComponent implements ng.IComponentOptions {
    public require = {
        OrganismDashboardOverview: '^organismDashboardOverviews'
    };
    public bindings = {
        objectType: '@',
        service: '@',
        serviceItemList: '<'
    };
    public controller =  MoleculeOverviewRowPanelDashboardDomainTransferController;
    public controllerAs = 'DashboardOverviewCtrl';
    public template = require('./dashboard-domain-transfer.html');
}
