import * as ng from 'angular';

import * as Types from '@/types';

import {
    AuthContextService,
    CleanableStringType,
    DomainRobotService,
    StringCleaner
} from '../../../../../services';

export class OrganEditPanelDomainEditBulkDomainSelectController {
    public static $inject: string[] = ['accountNameFilter', '$timeout', 'domainRobot'];

    public domains: any[];
    public textareaDomains: string;
    public selectedDomains: Types.DomainApi.Domain[] = [];
    public allDomains: Types.DomainApi.Domain[] = [];
    public moveableDomains: any[];
    public selectedDomainNames: string[];
    public subAccounts: Types.AccountApi.Subaccount[];
    public singleDomainMove = false;
    public sameAccountDomains: boolean;
    public showNoDomainsFoundError = false;
    public loading = false;
    public filterAccountId: string;

    private _accountNames: { [id: string]: string };

    constructor(
        public accountNameFilter: any,
        private $timeout: ng.ITimeoutService,
        private domainRobot: DomainRobotService
    ) {}

    public $onInit() {
        this.singleDomainMove = this.singleDomainMove || false;
        this.sameAccountDomains = this.sameAccountDomains ? this.sameAccountDomains : false;
        this.domainRobot.listDomains().then((res) => {
            res.response.data.forEach((domainResponse: any) => {
                this.allDomains.push(domainResponse);
            });
        });

        this._accountNames = {};
        this._accountNames[AuthContextService.account.id] = AuthContextService.account.name;
        for (const account of this.subAccounts) {
            this._accountNames[account.id] = account.name;
        }
        this.selectedDomainNames = this.selectedDomains.map(domain => domain.name);

        if (this.sameAccountDomains) {
            this.filterAccountId = this.selectedDomains[0].accountId;
        }

        this.moveableDomains = [];

        this.domains.forEach(
            (domain) => {
                if (domain.status !== 'ordered') {
                    this.moveableDomains.push ({
                        names: [domain.name, this._accountNames[domain.accountId]],
                        value: domain.id
                    });
                }
            }
        );
    }

    public addDomains = () => {
        this.loading = true;
        const domainRows = StringCleaner
            .clean(this.textareaDomains)
            .as(CleanableStringType.DomainNames)
            .split(/\r?\n/);

        const filters: Types.Finding.Filter = {
            subFilter: [
                {
                    subFilter: [],
                    subFilterConnective: 'OR'
                }
            ],
            subFilterConnective: 'AND'
        };

        if (domainRows.length && domainRows.length === 1 && domainRows[0] === '') {
            this.showNoDomainsFoundError = true;
            this.loading = false;
            return; // no search
        }

        for (const domainName of domainRows) {
            // Use wildcard search when the domain is incomplete (contains no dot).
            const domainSearchValue = domainName.includes('.') ? domainName : `*${domainName}*`;
            filters.subFilter[0].subFilter.push(
                {
                    field: 'DomainNameUnicode',
                    value: domainSearchValue
                }
            );
        }

        if (this.sameAccountDomains) {
            filters.subFilter.push({
                field: 'AccountId',
                value: this.filterAccountId
            });
        }

        this.domainRobot.listDomainsWithoutPagination(filters).then(
            (apiResult) => {
                this.showNoDomainsFoundError = true;
                if (apiResult.response?.data?.length > 0) {
                    for (const domain of apiResult.response.data) {
                        if (domain.status !== 'ordered') {
                            if (!this.allDomains.some(
                                (existingDomain) => domain.nameUnicode === existingDomain.nameUnicode
                            )) {
                                this.allDomains.push(domain);

                                this.moveableDomains.push ({
                                    names: [domain.name, this._accountNames[domain.accountId]],
                                    value: domain.id
                                });
                            }

                            if (!this.selectedDomains.some(
                                (existingDomain) => domain.nameUnicode === existingDomain.nameUnicode
                            )) {
                                this.textareaDomains = '';
                                this.showNoDomainsFoundError = false;
                                // has to be assigned so the setter in OrganismEditFormDomainMoveController triggers!
                                // please don't rewrite to .push, or stuff's gonna break ;)
                                this.selectedDomains = this.selectedDomains.concat(domain);
                                this.selectedDomainNames = this.selectedDomains.map(domain => domain.name);
                            }
                        }
                    }
                }
                this.loading = false;
                this.$timeout();
            },
            () => {
                this.loading = false;
                this.$timeout();
            }
        );
    };

    public removeDomain = (tableIndex: number) => {
        this.selectedDomains = this.selectedDomains.filter(({}, i) => tableIndex !== i);
        this.textareaDomains = this.selectedDomains.map(e => e.name).join('\n');
        this.selectedDomainNames = this.selectedDomains.map(domain => domain.name);
    };

    public domainInBundleCheck = () => {
        return !!this.selectedDomains.filter(e => e.bundleId.length > 0);
    };

    public showRemoveButton = (rowIndex: number, bundleId: string) => {
        return (rowIndex > 0 || bundleId) && !this.singleDomainMove;
    };
}

export class OrganEditPanelDomainEditBulkDomainSelectComponent implements ng.IComponentOptions {
    public bindings = {
        domains: '<',
        sameAccountDomains: '<?',
        selectedDomainNames: '=',
        selectedDomains: '=',
        singleDomainMove: '<',
        subAccounts: '<'
    };
    public controller = OrganEditPanelDomainEditBulkDomainSelectController;
    public template = require('./domain-select.html');
}
