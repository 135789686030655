import * as ng from 'angular';

import orderBy from 'lodash.orderby';

import {
    DropdownElementData,
    PanelType
} from '@/atomic-components/molecules';
import { UiRights } from '@/configuration';
import {
    AuthContextService,
    ResourceHelperService,
    ResourceModelService
} from '@/services';
import { MachineApi } from '@/types';

export class OrganEditPanelMachineNetworkController {
    public get getInterface() {
        return this.networkInterfaces.find((iface) => this.selectedNetwork === iface.id);
    }

    public get showNetworkName() {
        return this.userHasRightNetworkList
            && [undefined, null, ''].indexOf(this.getInterface.networkId) < 0;
    }
    public static $inject: string[] = ['$translate', 'resourceHelper', 'resourceModel'];

    public panelRight: PanelType;
    public networkInterfaces: MachineApi.NetworkInterface[] | any[];
    public selectedNetwork: string;
    public networkInterfaceItems: DropdownElementData[] = [];
    public userHasRightNetworkList: boolean;
    public disabledDropDown: boolean;
    public placeholderDropDown: string = this.$translate.instant('TR_110119-7444b4_TR');

    public cancelCallback: () => Promise<any>;
    public saveCallback: () => void;

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private resourceHelper: ResourceHelperService,
        private resourceModel: ResourceModelService
    ) {}

    public $onChanges = () => {

        this.userHasRightNetworkList = AuthContextService.isGranted(UiRights.RES_NETWORK_LIST);

        this.setupNetworkInterfaces();
    };

    public setupNetworkInterfaces = async () => {

        for ( let index = 0; index < (this.networkInterfaces || []).length; index++ ) {
            if (
                [undefined, null, ''].indexOf(this.networkInterfaces[index].networkId) < 0
                && this.userHasRightNetworkList === true
            ) {
                this.networkInterfaces[index].network = await this.resourceModel
                    .findOne( 'Network', this.networkInterfaces[index].networkId );
            }
        }

        this.networkInterfacesDropdownInit();
    };

    public networkInterfacesDropdownInit = async () => {
        this.networkInterfaceItems = [];
        const addDropdownItems = (item) => {
            this.networkInterfaceItems.push(item);
        };

        let publicIndex = 0;
        let privateIndex = 0;
        this.networkInterfaces = orderBy(this.networkInterfaces,
            ['networkType', 'ipv4PrimaryAddress', 'networkId'],
            ['desc', 'asc', 'desc']);
        for ( const iface of this.networkInterfaces ) {
            const index = privateIndex + publicIndex;
            if (index === 0) {
                this.selectedNetwork = iface.id;
            }
            let networkName = '';
            if (this.userHasRightNetworkList === true && iface.network) {
                networkName = iface.network.name;
            }
            const networkIdentity = `${networkName} (${iface.ipv4PrimaryAddress})`;

            if (iface.networkType === 'publicNetwork') {
                publicIndex++;
                iface.interfaceName = `${this.$translate.instant('TR_170420-611ec4_TR')} ${publicIndex}`;
                addDropdownItems({
                    id: index,
                    name: `${iface.interfaceName}: ${networkIdentity}`,
                    value: iface.id
                });
            } else if (iface.networkType === 'privateNetwork') {
                privateIndex++;
                iface.interfaceName = `${this.$translate.instant('TR_170420-677abc_TR')} ${privateIndex}`;
                addDropdownItems({
                    id: index,
                    name: `${iface.interfaceName}: ${networkIdentity}`,
                    value: iface.id
                });
            }
        }
    };

    public save = () => {
        if (this.saveCallback) {
            this.saveCallback();
        }
    };

    public cancel = async () => {
        if (this.cancelCallback) {
            await this.cancelCallback();
        }
    };

}

export class OrganEditPanelMachineNetworkComponent implements ng.IComponentOptions {
    public bindings = {
        panelRight: '<',
        networkInterfaces: '<',
        cancelCallback: '<?',
        saveCallback: '<?',
        selectedNetwork: '=?'
    };
    public require = {
        $formEdit: '?^moleculeFormEdit'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./machine-network.html');
    public controller = OrganEditPanelMachineNetworkController;
}
