import { TimeSpecificationsConst } from '@/configuration';
import * as ng from 'angular';
import * as Types from '@/types';
import { OrganismOverviewController, OverviewDataObject } from '../../../../../organisms/overview/overview';
import { ITextRender } from '@/services/textRender/ITextRender';
import { CronJobTimeDisplayRenderer } from '@/services/textRender/cronjobTimeDisplayRender';

export class MoleculeOverviewRowPanelCronjobController implements ng.IController {
    public static $inject: string[] = ['$state'];
    private textRenderer: ITextRender<Types.WebhostingApi.CronJob, string>;
    public overviewData: OverviewDataObject;
    public overviewLoad: (selection: any) => any;
    public OrganismOverview: OrganismOverviewController;

    constructor(
        private $state: ng.ui.IStateService
    ) {
    }

    public getComment = (comment: string) => {
        if (comment.length > 100) {
            return comment.substring(0, 100) + '...';
        }

        return comment;
    };

    public get showAccounts() {
        return this.OrganismOverview.globals.showAccounts;
    }

    public get isBundle() {
        return this.$state.current.name.indexOf('bundle') >= 0;
    }

    public get webspaceId() {
        return this.$state.$current.locals.globals.webspace.id;
    }
}

export class MoleculeOverviewRowPanelCronjobComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelCronjobController;
    public controllerAs = 'OverviewCronjobCtrl';
    public template = require('./cronjob-row.html');
}
