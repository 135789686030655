import * as ng from 'angular';
import * as Configurations from '../../../../../configuration';
import { AsteriskNoteService } from './../../../../../services/billing/asterisk-note-service';

export interface ProductConfigManagedServerProjectObject {
    accountId: string;
    accountType: number;
    createDatabase: false;
    createWebspace: false;
    databaseCapacity: number;
    databasePassword: string;
    databasePoolId: string;
    databaseServerId: string;
    domainName: string;
    projectName: string;
    newSubAccount: {
        addition: string;
        city: string;
        country: string;
        emailAddress: string;
        formvalid: boolean;
        name: string;
        password: string;
        phoneNumber: string;
        street: string;
        zipCode: string;
    };
    webserverId: string;
    webserverPoolId: string;
    webspacePassword: string;
}

export class OrganismManagedProjectWizardConfigViewController  {
    public static $inject: string[] = ['$translate'];

    public callback: (metaData: ProductConfigManagedServerProjectObject) => {};
    public configCompleted = false;
    public metadata: ProductConfigManagedServerProjectObject;
    public service = 'managedserver'; // still needed??
    public accountSelectionComplete: boolean;
    public accountEmailInUse: boolean;

    public ressourceServers;
    public machine;
    public serverIsNotInstalledAtLeast = false;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        if ([this.ressourceServers.database, this.ressourceServers.webserver].indexOf(null) >= 0) {
            this.serverIsNotInstalledAtLeast = true;
        } else {
            this.serverIsNotInstalledAtLeast = false;
            this.metadata = {
                accountId: null,
                accountType: null,
                createDatabase: false,
                createWebspace: false,
                databaseCapacity: 1,
                databasePassword: '',
                databasePoolId: this.ressourceServers.database.poolId,
                databaseServerId: this.ressourceServers.database.id,
                domainName: '',
                projectName: '',
                newSubAccount: {
                    addition: '',
                    city: '',
                    country: Configurations.UiLanguagesReverseConst[this.$translate.use()],
                    emailAddress: '',
                    formvalid: false,
                    name: '',
                    password: '',
                    phoneNumber: '',
                    street: '',
                    zipCode: ''
                },
                webserverId: this.ressourceServers.webserver.id,
                webserverPoolId: this.ressourceServers.webserver.poolId,
                webspacePassword: ''
            };
        }
    }

    public finishConfiguration = () => {
        if (this.callback !== undefined) {
            this.callback(this.metadata);
        } else {
            // pherhaps, throw sentry error - callback is not set
        }
    };

    public set showProductConfiguration({}) {} // tslint:disable-line:no-empty
    public get showProductConfiguration() {
        return this.accountSelectionComplete;
    }

    public set configurationIsCompleted({}) {} // tslint:disable-line:no-empty
    public get configurationIsCompleted() {
        return this.configCompleted;
    }
}

export class OrganismManagedProjectWizardConfigViewComponent implements ng.IComponentOptions {
    public bindings = {
        callback: '<',
        accountEmailInUse: '=',
        machine: '<',
        ressourceServers: '<'
    };
    public template = require('./managed-project-wizard-config-view.html');
    public controller = OrganismManagedProjectWizardConfigViewController;
}
