import * as ng from 'angular';
import './rights-drop-down-form.scss';

export class MoleculeRightsDropDownFormController implements ng.IController {
    public rightsGroup: any;
    public rightsList: string[];
    public editableRights: string[];
    public masterCheckboxRights: string[] = [];
    public hasLoaded = false;
}

export class MoleculeRightsDropDownFormComponent implements ng.IComponentOptions {
    public bindings = {
        rightsGroup: '<',
        rightsList: '=',
        groupings: '<'
    };

    public controller = MoleculeRightsDropDownFormController;
    public template = require('./rights-drop-down-form.html');
}

