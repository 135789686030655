/* eslint-disable */

import * as ng from 'angular';
import {
    BillingHelperService,
    ProductHelperService,
    ProductsModelService,
    PriceHelperService
} from '@/services';
import * as Types from '@/types';

export class OrganCreateConfigurationNextcloudBackupController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'billingHelper',
        'productsModel',
        'priceHelper',
    ];

    public backupEnabled = false;
    public account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    public loadBackupPrice = false;
    public voucher?: Types.ViewTypes.ProductConfigVoucher;

    private _backupProductCode: string = null;
    private _backupPrice: Types.BillingApi.ArticlePurchasePrice | number = null;
    private _isFreemium: boolean;
    private _selectedProductCode: string;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private billingHelper: BillingHelperService,
        private productsModel: ProductsModelService,
        private priceHelper: PriceHelperService,
    ) {}

    public $onInit() {
        this._setBackupProductObject();
    }

    public $onChanges(changes: any) {
        if (changes._selectedProductCode !== undefined) {
            this._getStorageProductData();
        }
    }

    public get nextcloudBackupOptionAvailable() {
        return [undefined, null, ''].indexOf(this._selectedProductCode) < 0
            && this.backupPrice !== null
            && !this._isFreemium;
    }

    public set backupProductCode({}) {} // tslint:disable-line:no-empty
    public get backupProductCode() {
        return this.backupEnabled
            ? this._backupProductCode
            : null;
    }

    public set showBackupPrice({}) {} // tslint:disable-line:no-empty
    public get showBackupPrice(): boolean {
        return this.nextcloudBackupOptionAvailable
            && this.backupEnabled;
    }

    public set backupPrice({}) {} // tslint:disable-line:no-empty
    public get backupPrice() {
        return this._backupPrice;
    }

    private _getStorageProductData = () => {
        if ([undefined, null, ''].indexOf(this._selectedProductCode) >= 0) {
            // Perhaps throw sentry error, because productCodce has to be given here!
            return;
        }

        this.loadBackupPrice = true;
        this._setBackupProductObject();
        this.productsModel.findOne(
            this._selectedProductCode,
            this.$translate.use() as string,
            true
        ).then((nextcloudProductRes) => {
            if (this._isFreeProduct(nextcloudProductRes.response)) {
                this.$timeout(() => {
                    this._backupPrice = null;
                    this.loadBackupPrice = false;
                });
            } else {
                // Get backup productCode template string from nextcloud specificationItems
                this._backupProductCode = this._getProductCodeTemplate(nextcloudProductRes.response);
                if (this._backupProductCode !== null) {
                    this.billingHelper.getPriceByProductCode(this._backupProductCode).then((priceResponse) => {
                        this._backupPrice = priceResponse;
                        this.loadBackupPrice = false;
                    });
                } else {
                    this.$timeout(() => {
                        this._backupPrice = null;
                        this.loadBackupPrice = false;
                    });
                }
            }
        });
    };

    private _isFreeProduct = (storageProduct: Types.ProductApi.AbstractProduct) => {
        const isFreemium = ProductHelperService.wrapSpecificationItems(
            storageProduct.specificationItems
        ).isFreeProduct?.isTrue === true;

        // set for nextcloudBackupOptionAvailable getter
        this.$timeout(() => this._isFreemium = isFreemium);

        return isFreemium;
    };

    private _setBackupProductObject = () => {
        this._backupProductCode = null;
    };

    private _getProductCodeTemplate = (storageProduct: Types.ProductApi.AbstractProduct): string => {
        return ProductHelperService.wrapSpecificationItems(
            storageProduct.specificationItems
        ).backupProductCode?.value as string || null;
    };

    public get priceAlteration() {
        if (this.voucher) {
            return this.$translate.instant(
                /* translationId */ 'TR_050324-963dc9_TR',
            );
        }
        return '';
    }
}

export class OrganCreateConfigurationNextcloudBackupComponent implements ng.IComponentOptions {
    public bindings = {
        _selectedProductCode: '<selectedProductCode',
        account: '<account',
        backupProductCode: '=',
        voucher: '<?',
    };
    public template = require('./backup.html');
    public controller =  OrganCreateConfigurationNextcloudBackupController;
}
