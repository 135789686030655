import * as ng from 'angular';
import { UserSettingsManagerService } from '../../../services';

import './message-callout.scss';

export interface MessageCalloutImage {
    src: string;
    alt: string;
    title?: string;
    background?: string;
    cssClassModifier?: string;
}

export class OrganismMessageCalloutController implements ng.IController {
    public static $inject: string[] = ['userSettingsManager'];

    public messageId: string;
    public onlyDev: boolean;
    public onlyStable: boolean;
    public showMessage: boolean;

    public image: MessageCalloutImage;
    public headline: string;

    constructor(
        private userSettingsManager: UserSettingsManagerService
    ) {}

    public $onInit() {
        this.userSettingsManager.getUiSettings().then((uiSettings) => {
            this.showMessage = false;

            uiSettings.messages.some((message) => {
                if (message.id === this.messageId && message.viewed) {
                    this.showMessage = !message.viewed;
                    return true;
                }

                this.showMessage = true;
                return false;
            });
        });
        this.onlyStable = this.onlyStable || true; // Default
        this.image = this.image || { src: '', alt: '' };
        this.image.background = this.image.background || 'roobotics/bg-cloud.svg';
        this.onlyDev = this.onlyDev || false;
    }

    public hide = () => {
        this.userSettingsManager.saveReadMessage(true, this.messageId).then(() => {
            this.showMessage = false;
        });
    };

    public debugShowMessage = () => {
        this.userSettingsManager.saveReadMessage(false, this.messageId).then(() => {
            this.showMessage = true;
        });
    };

    public get local() {
        return window.location.hostname.indexOf('localhost') >= 0;
    }

    private get _dev() {
        return window.location.hostname.indexOf('dev') >= 0;
    }

    private get _entry() {
        if (this.onlyDev && this._dev) { // When on beta
            return true;
        } else if (this.onlyStable && !this._dev) { // When on stable
            return true;
        }
    }

    public get calloutModel() {
        if (this.showMessage !== undefined) {
            return this.showMessage && this._entry;
        }

        return false;
    }

}

export class OrganismMessageCalloutComponent implements ng.IComponentOptions {
    public bindings = {
        headline: '@?',
        image: '<',
        messageId: '@message',
        onlyDev: '<?',
        onlyStable: '<?'
    };
    public transclude = true;
    public controller = OrganismMessageCalloutController;
    public template = require('./message-callout.html');
}
