import * as ng from 'angular';
import { EditPanelStatus } from '../../../atomic-components/molecules/forms';
import { PageHeaderData } from '../../../atomic-components/organisms';
import { UiRights } from '../../../configuration';
import { AlertManagerService, AuthContextService, NavigationService } from '../../../services';
import { DdnsModelService } from '../../../services/dns';
import { DnsApi } from '../../../types';

export class TemplateDdnsCredentialsEditController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'ddnsModel',
        'navigation'
    ];

    public userHasRightDnsDdnscredentialsDelete = AuthContextService.isGranted(UiRights.DNS_DDNSCREDENTIALS_DELETE);
    public ddnsCredentials: DnsApi.DynamicDnsCredentials;
    public initalDdnsCredentials: DnsApi.DynamicDnsCredentials;
    public showObjectId: boolean;
    public userConfirmedDeletion: boolean;
    public deletionInitalStatus: EditPanelStatus = EditPanelStatus.READONLY;
    public password = '';
    public chosenDdnsUserId: string;
    public pageHeaderData: PageHeaderData = {
        backwardLink: 'dns.ddns.credentials.overview',
        backwardText: this.$translate.instant('TR_200319-5c7d68_TR'),
        panelHeaderRoute: '',
        panelIcon: 'location-arrow',
        panelTitle: ''
    };
    public faqArticleIdList: string[] = [
        'dynamic-dns',
        'dns-delete-entry',
        'dns-setup-office',
        'dns-srv-record'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private ddnsModel: DdnsModelService,
        private navigation: NavigationService
    ) {}

    public $onInit() {
        this.initalDdnsCredentials = ng.copy(this.ddnsCredentials);
        this.chosenDdnsUserId = this.ddnsCredentials.id;
        this.pageHeaderData.panelTitle = this.$translate.instant(
            /* translationId */ 'TR_200319-d46d4c_TR',
            { userName: this.ddnsCredentials.name }
        );
    }

    public save = () => {
        this.ddnsModel.updateDdnsUser(this.ddnsCredentials, this.password).then(
            (apiResponse) => {
                this.ddnsCredentials = apiResponse.response;
                this.initalDdnsCredentials = ng.copy(this.ddnsCredentials);
                this.password = '';
                this.alertManager.success(this.$translate.instant('TR_210319-ea8852_TR'));
            }
        );
    };

    public delete = () => {
        this.ddnsModel.deleteDdnsUser(this.ddnsCredentials.id).then(
            (apiResponse) => {
                this.alertManager.success(this.$translate.instant('TR_200319-b9cba8_TR'));
                this.navigation.go('dns.ddns.credentials.overview', {}, { reload: true });
            }
        );
    };

    public cancel = () => {
        this.ddnsCredentials = ng.copy(this.initalDdnsCredentials);
    };
}

export class TemplateDdnsCredentialsEditComponent implements ng.IComponentOptions {
    public bindings = {
        ddnsCredentials: '<',
        showObjectId: '<'
    };
    public template = require('./ddns-credentials-edit-template.html');
    public controller = TemplateDdnsCredentialsEditController;
}
