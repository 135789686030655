import * as ng from 'angular';
import { ValidateTlsa } from '../../../../../services';
import { FormInputModelOptions } from '../../../../atoms/form-elements';

export class MoleculeInputTextTlsaController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public validationErrorList = [];
    public value: string;
    public placeholder: string;
    public callbackOnChange: (arg0: any) => any;
    public callbackOnBlur: (arg0: any) => any;
    public callbackOnBlurParams: any;
    public disabled: boolean;
    public modelOptions: FormInputModelOptions;
    public registerToForm: boolean;
    public options: any;
    public validationInstructions: any[];
    public inputId: string;
    public callbackOnClear: (arg0: any) => any;
    public callbackOnClearParams: any;
    public clearable: boolean;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        let allowEmpty = true;
        let acceptPlaceholders: false;

        if (this.options != null && this.options !== undefined) {
            if (this.options.allowEmpty != null && this.options.allowEmpty !== undefined) {
                allowEmpty = this.options.allowEmpty;
            }
            if (this.options.acceptPlaceholders != null && this.options.acceptPlaceholders !== undefined) {
                acceptPlaceholders = this.options.acceptPlaceholders;
            }
        }

        this.validationInstructions = [
            {
                instructions: null,
                validator: new ValidateTlsa(this.$translate)
            }
        ];
    }

    public onPaste = (paste) => {
        // implement callback on paste here when needed!
    };
}

export class MoleculeInputTextTlsaComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnBlur: '<',
        callbackOnBlurParams: '<',
        callbackOnChange: '<',
        callbackOnClear: '<',
        callbackOnClearParams: '<',
        clearable: '<',
        customCallbackOnEnter: '<',
        disabled: '<',
        doNotSaveOnFormEnter: '<',
        inputId: '@?',
        modelOptions: '<',
        options: '<',
        placeholder: '@',
        registerToForm: '<',
        validationErrorList: '=?',
        value: '='
    };
    public controller = MoleculeInputTextTlsaController;
    public template = require('./input-text-with-validation-wrapper.html');
}
