import * as ng from 'angular';

import { FormDescriptionSpec } from '@/types/view-types/form-description/form-description';

export class FormElementDisplayLineController implements ng.IComponentController {
    public static $inject: string[] = [];

    public keenInput: FormDescriptionSpec.ElementLine;
}

export class FormElementDisplayLineComponent implements ng.IComponentOptions {
    public bindings = {
        keenInput: '<'
    };
    public controller = FormElementDisplayLineController;
    public template = require('./form-element-line.html');
}
