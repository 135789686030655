import ng from 'angular';
import { twoFAFormStatus } from '../../../../organs/panels/edit-panels/user-login/edit-panel-two-f-a';

export class MoleculePanelShowTwoFAPasscodesController implements ng.IController {
    public static $inject: string[] = ['$anchorScroll', '$location', '$timeout'];
    public twoFAData: any;
    public scrolledIntoView = false;

    constructor(
        public $anchorScroll: ng.IAnchorScrollService,
        public $location: ng.ILocationService,
        private $timeout: ng.ITimeoutService
    ) {}

    public close = (): void => {
        this.twoFAData.formStatus = twoFAFormStatus.BASE;
        this.twoFAData.passcodes = [];
    };

    public opened = (): boolean => {
        if (!this.scrolledIntoView && this.twoFAData.formStatus === twoFAFormStatus.SHOW_PASSCODES) {
            this.scrolledIntoView = true;
            void this.$timeout(() => {
                this.$location.hash('oneTimeCodeList');
                this.$anchorScroll();
            });
        }
        return this.twoFAData.formStatus === twoFAFormStatus.SHOW_PASSCODES;
    };
}

export class MoleculePanelShowTwoFAPasscodesComponent implements ng.IComponentOptions {
    public bindings = {
        twoFAData: '='
    };

    public controller = MoleculePanelShowTwoFAPasscodesController;
    public template = require('./panel-show-passcodes.html');
}
