import ng from 'angular';
import { Finding } from '@/types';
import { ModelHelper } from '../model-helper';
import { DomainRobotService } from './robot';

export const addHiddenContactsFilter = (filters: Finding.Filter, exemptFromFilterContactId = ''): Finding.Filter => {
    let returnFilter;

    if (filters) {
        returnFilter = {
            subFilterConnective: 'AND',
            subFilter: [
                filters
            ]
        };

        if (exemptFromFilterContactId?.length && exemptFromFilterContactId.length > 0) {
            returnFilter.subFilter.push(
                {
                    subFilterConnective: 'OR',
                    subFilter: [
                        {
                            field: 'ContactHidden',
                            value: '0'
                        },
                        {
                            field: 'ContactId',
                            value: exemptFromFilterContactId
                        }
                    ]
                }
            );
        } else {
            returnFilter.subFilter.push(
                {
                    field: 'ContactHidden',
                    value: '0'
                }
            );
        }
    } else {
        returnFilter =  {
            field: 'ContactHidden',
            value: '0'
        };

        if (exemptFromFilterContactId?.length && exemptFromFilterContactId.length > 0) {
            returnFilter = {
                subFilterConnective: 'OR',
                subFilter: [
                    {
                        field: 'ContactHidden',
                        value: '0'
                    },
                    {
                        field: 'ContactId',
                        value: exemptFromFilterContactId
                    }
                ]
            };
        }
    }

    return returnFilter;
};

export class DomainHandleModelService {
    public static $inject: string[] = ['domainRobot', '$rootScope'];

    constructor(
        private domainRobot: DomainRobotService,
        private $rootScope: ng.IRootScopeService
    ) {}

    public list = (limit?, page?, filters?, sort?, cancel?, owner?) => {
        sort = sort || {
            field: 'contactName',
            order: 'ASC'
        };
        page = page || 1;

        return this.domainRobot.listContacts(filters, limit, page, sort, {timeout: cancel}, owner)
            .then(ModelHelper.returnListResponse);
    };

    public listWithoutPagination = (limit?, page?, filters?, sort?, cancel?) => {
        sort = sort || {
            field: 'contactName',
            order: 'ASC'
        };
        page = page || 1;

        return this.domainRobot.listContactsWithoutPagination(filters, limit, page, sort, {timeout: cancel})
            .then(ModelHelper.returnListResponse);
    };

    public listVerifications = (limit?, page?, filters?, sort?, cancel?) => {
        sort = sort || {
            field: 'ContactVerificationName',
            order: 'ASC'
        };
        page = page || 1;

        return this.domainRobot.listContactVerfications(filters, limit, page, sort, {timeout: cancel})
            .then(ModelHelper.returnListResponse);
    };

    public listVerificationsWithoutPagination = (limit?, page?, filters?, sort?, cancel?) => {
        sort = sort || {
            field: 'ContactVerificationName',
            order: 'ASC'
        };
        page = page || 1;

        return this.domainRobot.listContactVerficationsWithoutPagination(filters, limit, page, sort, {timeout: cancel})
            .then(ModelHelper.returnListResponse);
    };

    public findOne = (id) => {
        return this.domainRobot.listContactsWithoutPagination({field: 'contactId', value: id.toString()}, 1, 1)
            .then(ModelHelper.returnFindOneResponse);
    };

    public findById(ids) {
        const filter = {subFilterConnective: 'OR', subFilter: ids.map(this.idFilter)};

        return this.domainRobot.listContactsWithoutPagination(filter, 0, 1).then(
            (result) => {
                return result.response.data;
            }
        );
    }

    public findContactVerificationById(ids) {
        const filter = {subFilterConnective: 'OR', subFilter: ids.map(this.idVerificationFilter)};

        return this.domainRobot.listContactVerficationsWithoutPagination(filter, 0, 1).then(
            (result) => {
                return result.response.data;
            }
        );
    }

    public findHandle(filter, limit, page?) {
        if (limit === undefined) {
            limit = 1;
        }

        if (page === undefined) {
            page = 1;
        }

        const filters = {
            subFilter: filter,
            subFilterConnective: 'AND'
        };

        const result = this.domainRobot.listContactsWithoutPagination(
            filters,
            limit,
            page,
            {field: 'contactHandle', order: 'ASC'}
        );

        return result.then(ModelHelper.returnFindResponse);
    }

    public update(handle, designateAgent?) {
        const actingAs = [undefined, null, false, ''].indexOf(designateAgent) >= 0 ? undefined : 'designatedAgent';

        return this.domainRobot.updateContact(handle, actingAs)
            .then(this.refresh, this.refresh);
    }

    public create(handle) {
        return this.domainRobot.createContact(handle)
            .then(this.refresh, this.refresh);
    }

    public getLinkedDomainsByHandleId(handleId, page, limit, sort, filters) {
        sort = sort || {field: 'domainNameUnicode', order: 'ASC'};
        page = page || 1;
        limit = limit || 10;
        filters = filters || {field: 'contactId', value: handleId};

        return this.domainRobot.listDomains(filters, limit, page, sort).then((result) => {
            result.response.data.forEach (
                (domain) => {
                    domain.handleTypes = domain.contacts.filter(
                        (handle) => {
                            return handle.contact === handleId;
                        }
                    )
                    .map(
                        (handle) => {
                            return handle.type;
                        }
                    );
                }
            );

            return ModelHelper.returnListResponse(result);
        });
    }

    public resendVerificationEmail(verificationId) {
        return this.domainRobot.resendVerificationEmail(verificationId)
            .then(this.returnResponse);
    }

    public contactUpdateIsIcannOwnerChange(contact) {
        return this.domainRobot.contactUpdateIsIcannOwnerChange(contact).then(this.returnResponse);
    }

    public contactUpdateIsRestrictedOwnerChange(contact) {
        return this.domainRobot.contactUpdateIsRestrictedOwnerChange(contact)
            .then(this.returnResponse);
    }

    private refresh = (result) => {
        this.$rootScope.$broadcast('handles.refresh');

        return result;
    };

    private returnResponse = (result) => {
        return result.response;
    };

    private idFilter = (id) => {
        return {field: 'contactId', value: id};
    };

    private idVerificationFilter = (id) => {
        return {field: 'ContactVerificationId', value: id};
    };
}
