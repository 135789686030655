/* eslint-disable */

import * as ng from 'angular';

import { OrganSslWizardProductConfigCertificateContactsController } from '.';
import { ViewTypes } from '../../../../../../types/view-types';

export class OrganSslWizardProductConfigCertificateContactOrganizationController {
    public static $inject: string[] = [
        '$translate'
    ];

    public organizationContact: ViewTypes.SslContact<ViewTypes.OrganizationContactFields>;
    public metaData: ViewTypes.ProductConfigSslObject;
    public salutationsDropdown: { name: string; value: string }[] = [];
    public dropDownItems: any[] =[];

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.organizationContact.filledOut = this.filledOut;
        if (this.needsBusinessCategory) {
            this.organizationContact.fields.businessCategory = {value: '', required: true};
            this.setBusinessCategoryOptions();
        }
    }

    public filledOut = () => {
        return OrganSslWizardProductConfigCertificateContactsController.filledOut(this.organizationContact);
    };

    public set organizationCountry(country: string) {
        this.organizationContact.fields.country.value = country.toUpperCase();
    }
    public get organizationCountry(): string {
        return this.organizationContact.fields.country.value;
    }

    public set businessCategory(businessCategory: string) {
        if (!businessCategory) businessCategory = '';
        this.organizationContact.fields.businessCategory.value = businessCategory.toUpperCase();
    }
    public get businessCategory(): string {
        return this.organizationContact.fields.businessCategory.value;
    }

    public get needsBusinessCategory(): boolean {
        const specificationItems = this.metaData.settings.productSpecification;
        const validationType = specificationItems.find((item) => item.name === 'validationType')?.value;
        const certificateAuthority = specificationItems.find((item) => item.name === 'certificateAuthority')?.value;
        return validationType === 'EV' && certificateAuthority === 'Sectigo';
    }

    public setBusinessCategoryOptions(): void {
        this.dropDownItems = [
            { name: this.$translate.instant('TR_220224-d577a0_TR'), value: 'GOVERNMENT_ENTITY'},
            { name: this.$translate.instant('TR_220224-205025_TR'), value: 'BUSINESS_ENTITY'},
            { name: this.$translate.instant('TR_220224-0022bc_TR'), value: 'PRIVATE_ORGANISATION'},
        ];
    }
}

export class OrganSslWizardProductConfigCertificateContactOrganizationComponent implements ng.IComponentOptions {
    public bindings = {
        metaData: '<',
        organizationContact: '=',
        salutationsDropdown: '<'
    };
    public template = require('./config-certificate-contact-organization.html');
    public controller = OrganSslWizardProductConfigCertificateContactOrganizationController;
}
