import * as ng from 'angular';
import { FormDropDownItems } from '../../../forms/form-elements/drop-down/drop-down';

export class MoleculePanelEditRowDropdownController implements ng.IController {
    public static $inject: string[] = [];

    public items: FormDropDownItems[];
    public disabled: boolean;
    public model: any;
    public defaultOption: any;
    public hideAdditionalFields: boolean;
    public sizeModifier: string;
    public readonly = false;
    public required = false;
    public popoverPosition: string;
    public placeholder: string;
    public onChangeCallback: (selection: any) => any;
    public onChangeCallbackOutput: (selection: any) => any;
}

export class MoleculePanelEditRowDropdownComponent implements ng.IComponentOptions {
    public transclude = {
        label: 'rowLabel'
    };
    public bindings = {
        defaultOption: '<',
        disabled: '<',
        hideAdditionalFields: '<',
        items: '<',
        model: '=',
        onChangeCallback: '<',
        onChangeCallbackOutput: '&',
        placeholder: '@',
        popoverPosition: '@',
        readonly: '<',
        required: '<?',
        sizeModifier: '@?'
    };
    public controller = MoleculePanelEditRowDropdownController;
    public controllerAs = '$panelRow';
    // tslint:disable-next-line: max-line-length
    public template = require('./panel-edit-row-dropdown.html');
}
