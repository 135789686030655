import { CharPoolsConst } from '@/configuration';
import * as ng from 'angular';

export interface PasswordCharacterClass {
    name?: string;
    chance: number;
    characters: string;
}

export class PasswordConfiguration {
    public readonly characterClasses: PasswordCharacterClass[];
    public minCharacterClasses = 3;
    public readonly minLength: number = 10;
    public readonly maxLength: number = 10;

    constructor(
        protected $translate: ng.translate.ITranslateService,
        protected allowTrivialPasswords: boolean = false
    ) {
        this.characterClasses = [
            {
                name: this.$translate.instant(CharPoolsConst.lettersLower.translationId),
                chance: 5,
                characters: CharPoolsConst.lettersLower.value
            },
            {
                name: this.$translate.instant(CharPoolsConst.lettersUpper.translationId),
                chance: 4,
                characters: CharPoolsConst.lettersUpper.value
            },
            {
                name: this.$translate.instant(CharPoolsConst.numerals.translationId),
                chance: 3,
                characters: CharPoolsConst.numerals.value
            }
        ];
        if (!this.allowTrivialPasswords) {
            this.characterClasses.push(
                {
                    name: this.$translate.instant(CharPoolsConst.specialSigns.translationId),
                    chance: 2,
                    characters: CharPoolsConst.specialSigns.value
                }
            );
        } else {
            this.minCharacterClasses = 2;
        }
    }
}

export class DefaultPassword extends PasswordConfiguration {
}

export class EmailPassword extends PasswordConfiguration {
}

export class PasswordGenerator<Configuration extends PasswordConfiguration> {
    public static $inject: string[] = ['$translate'];

    constructor(
        private config: Configuration,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public generate: () => string = () => {
        let password = '';
        let classes = ng.copy(this.config.characterClasses);

        do {
            let rn = Math.random() * this.totalChance(classes);
            let done = false;

            classes.forEach(
                (characterClass: PasswordCharacterClass) => {
                    if (done) {
                        return;
                    }

                    if (rn <= characterClass.chance) {
                        password += characterClass.characters.charAt(
                            Math.floor(Math.random() * characterClass.characters.length)
                        );
                        characterClass.chance--;
                        done = true;
                    } else {
                        rn -= characterClass.chance;
                    }
                }
            );

            if (password.length > this.config.maxLength) {
                password = '';
                classes = ng.copy(this.config.characterClasses);
            }
        }
        while (password.length < this.config.minLength || !this.isComplexEnough(password));

        return password;
    };

    private totalChance(classes: PasswordCharacterClass[]): number {
        return classes
        .map(characterClass => characterClass.chance)
        .reduce((a: number, b: number) => a + b, 0);
    }

    private isComplexEnough: (password: string) => boolean
    = (password) => {
        return this.config.minCharacterClasses
        < this.config.characterClasses.filter(
            characterClass => characterClass.characters.split('').some(
                character => password.indexOf(character) >= 0
            )
        )
        .length;
    };
}

export class DefaultPasswordGenerator extends PasswordGenerator<DefaultPassword> {
    public static $inject: string[] = ['$translate'];

    constructor(
        protected $translate: ng.translate.ITranslateService,
        protected allowTrivialPasswords: boolean = false
    ) {
        super(new DefaultPassword(
            $translate,
            allowTrivialPasswords
        ), $translate);
    }
}

export class EmailPasswordGenerator extends PasswordGenerator<EmailPassword> {
    public static $inject: string[] = ['$translate'];

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {
        super(new EmailPassword($translate), $translate);
    }
}
