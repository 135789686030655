import * as ng from 'angular';
import './display-price-invoice.scss';

export class MoleculeDisplayPriceInvoiceController implements ng.IController {
    public static $inject: string[] = [];

    public invoice;

    public invoiceTotal = () => {
        let totalSum = this.invoice.total;

        if (this.invoice.paymentReceived) {
            totalSum += this._getreminderOutstandings();
        }

        return totalSum;
    };

    public getReminderFee = (): boolean|number => {
        if (this.invoice.paymentReceived) {
            return false;
        }

        return this._getreminderOutstandings();
    };

    private _getreminderOutstandings = () => {
        const reminderLevels = this.invoice.reminderLevels.length;
        let sum = 0;
        if (reminderLevels > 0) {
            this.invoice.reminderLevels.map(level => {
                sum += level.outstanding;
            });
        }

        return sum > 0 ? sum : false;
    };
}

export class MoleculeDisplayPriceInvoiceComponent implements ng.IComponentOptions {
    public bindings = {
        invoice: '<'
    };
    public controller =  MoleculeDisplayPriceInvoiceController;
    public controllerAs = '$DisplayPrice';
    public template = require('./display-price-invoice.html');
}
