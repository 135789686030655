import * as ng from 'angular';
import './rights-checkbox.scss';

export class MoleculeFormRightsCheckboxController implements ng.IController {
    public rightNames: string[] = [];
    public rightsList: string[];
    public editableRights: string[];
    public inalienableRights: string[];
    public forceDisable = false;
    public keys: string;
    public items: any[];
    public exists = true;
    public callbackOnClick: () => any;
    public indeterminate = false;

    public title: string;

    public $onInit() {
        if (this.keys != null && this.items != null) {
            this.setRightNames();
        }

        if ([undefined, null].indexOf(this.inalienableRights) >= 0) {
            this.inalienableRights = [];
        }

        this.title = this.rightNames.join(', ');
    }

    public get checked() {
        return this.exists && this.rightNames.every(this.isChecked);
    }

    public set checked(check) {
        for (const rightName of this.rightNames) {
            if ([undefined, null].indexOf(this.editableRights) >= 0 || this.editableRights.indexOf(rightName) >= 0) {
                if (check) {
                    if (
                        this.rightsList.indexOf(rightName) < 0
                            && ([undefined, null].indexOf(this.editableRights) >= 0
                            || this.editableRights.indexOf(rightName) >= 0)
                    ) {
                        this.rightsList.push(rightName);
                    }
                } else {
                    if (
                        this.rightsList.indexOf(rightName) >= 0
                            && this.inalienableRights === undefined
                            || this.inalienableRights.indexOf(rightName) < 0
                            || this.editableRights.indexOf(rightName) < 0
                    ) {
                        this.rightsList.splice(this.rightsList.indexOf(rightName), 1);
                    }
                }
            }
        }
    }

    private isChecked = (name: string) => {
        return this.rightsList.indexOf(name) >= 0;
    };

    private setRightNames = () => {
        for (const key of this.keys) {
            if (this.items[key] != null && this.items[key] !== undefined) {
                this.rightNames.push(this.items[key].value);
            }
        }
    };

    public get hasUneditableItems() {
        let rightNames = this.rightNames;

        if ([undefined, null, ''].indexOf(this.keys) < 0) {
            rightNames = this.keys.split('')
            .filter((key) => [undefined, null].indexOf(this.items[key]) < 0)
            .map((key) => this.items[key].value)
            .filter((right) => rightNames.indexOf(right) < 0)
            .concat(rightNames);
        }

        if (rightNames.length === 0) {
            return false;
        }

        if (this.inalienableRights === undefined) {
            return rightNames.some(
                (right) => this.editableRights.indexOf(right) < 0
            );
        }
        return rightNames.some(
            (right) => this.inalienableRights.indexOf(right) >= 0
            || this.editableRights.indexOf(right) < 0
        );
    }

    public get isDisabled() {
        if (this.forceDisable) {
            return true;
        }

        if ([undefined, null].indexOf(this.editableRights) >= 0) {
            return false;
        }

        if (this.isRowSelect()) {
            const rightKeys = this.keys.split('')
            .filter((key) => [undefined, null].indexOf(this.items[key]) < 0);

            return rightKeys.some(
                (key) => this.inalienableRights.indexOf(this.items[key].value) >= 0
                || this.editableRights.indexOf(this.items[key].value) < 0
            );
        }

        if (this.isSingleSelect()) {
            return this.hasUneditableItems;
        }

        if (this.isGroupSelect()) {
            const editableRights = ng.copy(this.editableRights);

            return editableRights.every((right) => {
                return this.inalienableRights.indexOf(right) >= 0;
            });
        }

        return false;
    }

    private isRowSelect = () => {
        return [undefined, null, ''].indexOf(this.keys) < 0 && this.keys.length > 1;
    };

    private isGroupSelect = () => {
        return [null, undefined, []].indexOf(this.inalienableRights) < 0;
    };

    private isSingleSelect = () => {
        return [undefined, null].indexOf(this.items) < 0;
    };
}

export class MoleculeFormRightsCheckboxComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnClick: '<',
        editableRights: '<',
        exists: '<',
        forceDisable: '<',
        inalienableRights: '<',
        indeterminate: '<',
        items: '<',
        keys: '<',
        rightNames: '<?',
        rightsList: '='
    };
    public controller = MoleculeFormRightsCheckboxController;
    public template = require('./rights-checkbox.html');
}
