import * as ng from 'angular';
import { ViewTypes } from '../../../../../types/view-types';

export class OrganismWebspaceWizardProductConfigController {
    public static $inject: string[] = [];

    public metaData: ViewTypes.ProductConfigWebspaceObject;
    public configCompleted: boolean;

    public get webspacePanelComplete(): boolean {
        return this.metaData.webspaceName?.length > 0;
    }

    public get vhostPanelComplete(): boolean {
        return this.metaData.webspaceName?.length > 0;
    }
}

export class OrganismWebspaceWizardProductConfigComponent implements ng.IComponentOptions {
    public bindings = {
        configCompleted: '=configCompleted',
        metaData: '='
    };

    public template = require('./webspace-wizard-product-config.html');
    public controller = OrganismWebspaceWizardProductConfigController;
}
