import { ApiObjectHandler } from './abstract-handler';

import * as Types from '../../../types';

export class MailboxHandler extends ApiObjectHandler<Types.EmailApi.Mailbox> {
    public asCatchAll: () => CatchAllHandler
    = () => new CatchAllHandler(this.wrappedObject as Types.EmailApi.CatchAll);

    public asExchangeMailbox: () => ExchangeMailboxHandler
    = () => new ExchangeMailboxHandler(this.wrappedObject as Types.EmailApi.ExchangeMailbox);

    public asForwarder: () => ForwarderHandler
    = () => new ForwarderHandler(this.wrappedObject as Types.EmailApi.Forwarder);

    public asImapMailbox: () => ImapMailboxHandler
    = () => new ImapMailboxHandler(this.wrappedObject as Types.EmailApi.ImapMailbox);

    public asMailingList: () => MailingListHandler
    = () => new MailingListHandler(this.wrappedObject as Types.EmailApi.MailingList);

    public asSmtpForwarder: () => SmtpForwarderHandler
    = () => new SmtpForwarderHandler(this.wrappedObject as Types.EmailApi.SmtpForwarder);

    public asMailbox: () => MailboxHandler
    = () => new MailboxHandler(this.wrappedObject);
}

export class CatchAllHandler extends MailboxHandler {
    constructor(mailbox: Types.EmailApi.CatchAll) {
        super(mailbox);
    }
}

export class ExchangeMailboxHandler extends MailboxHandler {
    constructor(mailbox: Types.EmailApi.ExchangeMailbox) {
        super(mailbox);
    }
}

export class ForwarderHandler extends MailboxHandler {
    constructor(mailbox: Types.EmailApi.Forwarder) {
        super(mailbox);
    }
}

export class ImapMailboxHandler extends MailboxHandler {
    constructor(mailbox: Types.EmailApi.ImapMailbox) {
        super(mailbox);
    }
}

export class MailingListHandler extends MailboxHandler {
    constructor(mailbox: Types.EmailApi.MailingList) {
        super(mailbox);
    }
}

export class SmtpForwarderHandler extends MailboxHandler {
    constructor(mailbox: Types.EmailApi.SmtpForwarder) {
        super(mailbox);
    }
}
