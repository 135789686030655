import * as ng from 'angular';
import { LinkListItem, PanelHeaderData } from '@/atomic-components/molecules/';

export class OrganDashboardWidgetSupportController implements ng.IController {
    public static $inject: string[] = [];

    public linkItems: LinkListItem[] = [];
    public panelHeader: PanelHeaderData = {
        panelIcon: 'life-ring',
        panelTitle: 'Support',
        panelHeaderRoute: ''
    };
}

export class OrganDashboardWidgetSupportComponent implements ng.IComponentOptions {
    public template = require('./dashboard-widget-support.html');
    public controller = OrganDashboardWidgetSupportController;
}
