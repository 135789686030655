import { DnsApi } from '@/types';
import * as ng from 'angular';
import {
    CleanableStringType,
    StringCleaner
} from '../../../../../services';

export class OrganCreateConfigurationDnsMultiFqdnSelectController implements ng.IController {
    public static $inject: string[] = [
        '$state',
        '$timeout',
        '$translate'
    ];

    public buttonText: string;
    public panelTitle: string;
    public fqdnListRaw = '';
    public fqdnListOuter = [];
    public domainInputExtended = false;
    public hideFqdnSelection = false;

    private _preselectedZone: DnsApi.ZoneConfig;
    private _fqdnList: string[];

    constructor(
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = () => {
        this._preselectedZone = this.$state.$current.locals.globals.zone;

        if (this._preselectedZone?.nameUnicode) {
            this.fqdnList = [this._preselectedZone.nameUnicode];
            this.hideFqdnSelection = true;
        }

        this.buttonText = this.buttonText
            ? this.buttonText
            : this.$translate.instant('TR_110119-03fcd2_TR');

        this.panelTitle = this.panelTitle
            ? this.panelTitle
            : this.$translate.instant('53aa71f0-8512-4480-b04d-cdd0c4d7f835');
    };

    public get fqdnList(): string[] {
        return this._fqdnList;
    }

    public set fqdnList(value: string[]) {
        this.$timeout(() => this.fqdnListOuter = value);
        this._fqdnList = value;
    }

    public sanitizeUserDomainInput = async () => {
        const newFqdnList = await StringCleaner
            .clean(this.fqdnListRaw)
            .as(CleanableStringType.FQDNs)
            .split('\n');

        this.fqdnList = newFqdnList.length > 0 && !(newFqdnList.length === 1 && newFqdnList[0] === '')
            ? newFqdnList
            : [];

        if (newFqdnList.length > 1 && !this.domainInputExtended) {
            this.domainInputExtended = true;
        }

        this.fqdnListRaw = this.fqdnList.join('\n');
    };

    public onPaste = (pastedText: string) => {
        this.fqdnListRaw = pastedText;
        this.sanitizeUserDomainInput();
    };

    public reactToKeyEvents = (event: KeyboardEvent) => {
        if (event.code === 'Enter') {
            if (this.domainInputExtended && event.shiftKey) {
                this.sanitizeUserDomainInput();
            } else if (!this.domainInputExtended) {
                this.sanitizeUserDomainInput();

                if (event.shiftKey) {
                    this.domainInputExtended = true;
                    this.$timeout(() => this.fqdnListRaw = this.fqdnListRaw + '\n');
                    this.$timeout(() => document.querySelectorAll('textarea')[0].focus(), 50);
                }
            }
        } else if (event.code === 'Escape') {
            this.sanitizeUserDomainInput();
        }
    };
}

export class OrganCreateConfigurationDnsMultiFqdnSelectComponent implements ng.IComponentOptions {
    public bindings = {
        fqdnListOuter: '=fqdnList',
        buttonText: '@?',
        panelTitle: '@?'
    };

    public template = require('./multi-fqdn-select.html');
    public controller =  OrganCreateConfigurationDnsMultiFqdnSelectController;
}
