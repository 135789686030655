import ng from 'angular';
import {
    EditFormMessage,
    EditFormSavingStatus,
    EditFormStatus,
    EditPanelStatus,
    MoleculeFormEditController,
    PanelType
} from '../../forms/form-edit/form-edit';

import './panel-edit.scss';

export type Validator = () => true | unknown;
export type EditPanelRight = { editPanelButton: boolean };

export class MoleculePanelEditController implements ng.IController {
    public static $inject: string[] = ['$transclude'];

    public name: string;
    public panelIcon: string;
    public panelTitle: string;
    public panelSubTitle?: string;
    public changesAffectPayment: boolean;
    public editable; // still needed?
    public panelRight: EditPanelRight;
    public initialStatus: EditPanelStatus;
    public hideEditButton: boolean;
    public hideCancelButton: boolean;
    public hideSaveButton: boolean;
    public typeOverride: PanelType;
    public $editForm: MoleculeFormEditController;
    public $validators: { [key: string]: Validator } = {};
    public saveCallback: () => void = null;
    public activateCallback: () => void = null;
    public hasExternalEditButton = false;

    private _status: EditPanelStatus;
    private startWithEditStatus: boolean;
    private setDisableEditButton: boolean;
    private setDisableSaveButton: boolean;

    constructor(
        private $transclude: ng.ITranscludeFunction
    ) {}

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public cancelCallback: () => void = () => {};

    public get type(): PanelType {
        if ([undefined, null].indexOf(this.typeOverride) < 0) {
            return this.typeOverride;
        }
        // wenn paid = true dann wird panelType zu cost_effective gestez
        return this.changesAffectPayment ? PanelType.COST_EFFECTIVE : PanelType.COST_NEUTRAL;
    }

    public $onInit = (): void => {
        this.hideEditButton = this.hideEditButton || false;
        this.hideCancelButton = this.hideCancelButton || false;
        this.hideSaveButton = this.hideSaveButton || false;
        this.setDisableEditButton = this.setDisableEditButton || false;
        this.setDisableSaveButton = this.setDisableSaveButton || false;

        if (this.startWithEditStatus !== undefined && this.startWithEditStatus) {
            this.initialStatus = EditPanelStatus.ACTIVE;
        }

        if ([undefined, null].includes(this.initialStatus)) {
            this.initialStatus = EditPanelStatus.READONLY;
        }

        this.status = this.initialStatus;

        this.$editForm.register(this);
    };

    public $onChanges(onChangesObj: ng.IOnChangesObject): void {
        if (onChangesObj.initialStatus) {
            this.status = onChangesObj.initialStatus.currentValue as EditPanelStatus;
        }
    }

    public $onDestroy = (): void => {
        this.$editForm.unregister(this);
    };

    public onMessage = (message: EditFormMessage<EditFormSavingStatus>): void => {
        switch (message.type) {
            default: return;

            case 'statusUpdate':
                switch (message.message) {
                    default: return;

                    case EditFormSavingStatus.SAVING:
                        // Currently doesn't have an effect here,
                        // all logic using "saving" status already uses the form's status.
                        return;

                    case EditFormSavingStatus.ERROR:
                        // TODO: Use logic. Error status if there is an error in this box, editable otherwise.
                        if (this.status !== EditPanelStatus.READONLY) {
                            this.status = EditPanelStatus.ACTIVE;
                        }
                        return;

                    case EditFormSavingStatus.READY:
                        // After successfully saving, return all panels to readonly status.
                        this.status = EditPanelStatus.READONLY;
                        return;
                }
        }
    };

    private get status(): EditPanelStatus {
        return this._status;
    }

    private set status(status: EditPanelStatus) {
        if (this._status !== status) {
            this._status = status;
            this.$editForm.updateRegisteredPanel(this);
        }
    }

    /**
     * Returns true if all input elements within the panel are free of errors,
     * false otherwise.
     * Warning: potentially slow, because it has to check ALL input fields!
     */
    public get $valid(): boolean {
        return Object.keys(this.$validators)
        .every((key) => this.$validators[key]() === true);
    }

    /**
     * Returns true if some input element within the panel has an error,
     * false otherwise.
     * Faster than $valid if there are errors, because the check is finished as soon as
     * the first error is found.
     */
    public get $invalid(): boolean {
        return Object.keys(this.$validators)
        .some((key) => this.$validators[key]() !== true);
    }

    /**
     * Returns an object containing all errors found within the panel.
     * Always an object, but it may be empty if no errors are found.
     */
    public get $errors(): { [key: string]: Validator }[] {
        const errors: { [key: string]: Validator }[] = [];
        for (const key of Object.keys(this.$validators)) {
            const result = this.$validators[key]();
            if (result !== true) {
                errors[key] = result;
            }
        }
        return errors;
    }

    public get isStatusDefined(): boolean {
        return undefined !== this.status;
    }

    public activate = (): void => {
        if ([undefined, null].indexOf(this.activateCallback) < 0) {
            this.activateCallback();
        }
        this.status = EditPanelStatus.ACTIVE;
        this.$editForm.validateAll();
    };

    public save = (): void => {
        if ([undefined, null].indexOf(this.saveCallback) < 0) {
            void this.$editForm.processApiCallback(this.saveCallback);
        } else {
            void this.$editForm.save();
        }
    };

    public cancel = (): void => {
        this.$editForm.resetValidations();
        this.status = EditPanelStatus.READONLY;

        if (this.cancelCallback !== undefined) {
            this.cancelCallback();
        }

        this.$editForm.validateAll();
    };

    public successfullySaved = (): void => {
        this.status = EditPanelStatus.READONLY;
    };

    public errorWhileSaving = (): void => {
        this.status = EditPanelStatus.ACTIVE;
    };

    public get isEditable(): boolean {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR,
            EditPanelStatus.CREATE
        ].indexOf(this.status) >= 0;
    }

    public get showCancelButton(): boolean {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0
            && this.panelRight.editPanelButton;
    }

    public get showEditButton(): boolean {
        return [
            EditPanelStatus.READONLY
        ].indexOf(this.status) >= 0
            && this.panelRight.editPanelButton;
    }

    public get showSaveButton(): boolean {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0
            && this.panelRight.editPanelButton
            && !this.hideSaveButton;
    }

    public get showEditableContent(): boolean {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR,
            EditPanelStatus.CREATE
        ].indexOf(this.status) >= 0;
    }

    public get showReadonlyContent(): boolean {
        return [
            EditPanelStatus.READONLY
        ].indexOf(this.status) >= 0;
    }

    public get showErrorContent(): boolean {
        return [
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showSavingContent(): boolean {
        return false; // this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get saveButtonLoading(): boolean {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get disableCancelButton(): boolean {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get disableEditButton(): boolean {
        return (this.setDisableEditButton || !this.$editForm.canEnablePanel(this.name));
    }

    public get disableSaveButton(): boolean {
        return this.setDisableSaveButton || this.$editForm.$invalid || this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get disableInputElements(): boolean {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get readonlyTranscludeFilled(): boolean {
        return this.$transclude.isSlotFilled('readonly');
    }

    public get editableTranscludeFilled(): boolean {
        return this.$transclude.isSlotFilled('editable');
    }

    public get savingTranscludeFilled(): boolean {
        return this.$transclude.isSlotFilled('saving');
    }

    public get errorTranscludeFilled(): boolean {
        return this.$transclude.isSlotFilled('error');
    }
}

export class MoleculePanelEditComponent implements ng.IComponentOptions {
    public bindings = {
        activateCallback: '<?activate',
        cancelCallback: '<?cancel',
        changesAffectPayment: '<paid',
        hasExternalEditButton: '<?',
        hideCancelButton: '<',
        hideEditButton: '<',
        hideSaveButton: '<',
        initialStatus: '<',
        name: '@',
        panelIcon: '@icon',
        panelRight: '<',
        panelTitle: '@',
        panelSubTitle: '@?',
        saveCallback: '<?save',
        setDisableEditButton: '<?',
        setDisableSaveButton: '<?',
        startWithEditStatus: '<',
        typeOverride: '<panelType'
    };

    public require = {
        $editForm: '^moleculeFormEdit'
    };

    public transclude = {
        buttonsEdit: '?panelHeadAdditionalButtons',
        editable: '?panelContentEditable',
        error: '?panelContentError',
        readonly: '?panelContentReadonly',
        saving: '?panelContentSaving'
    };

    public controller =  MoleculePanelEditController;
    public controllerAs = '$editPanel';
    public template = require('./panel-edit.html');
}
