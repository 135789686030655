import * as ng from 'angular';

export class OrganPanelAppInstallSettingsController {
    public static $inject: string[] = [];

    public appSettings: any[] = [];
    public formElementValid: boolean[] = [];
    public serviceObject: any;
    public language: string;
    public bundleId: string;

    public $onInit() {
        for (const setting of this.appSettings) {
            this.formElementValid.push(true);
        }
    }

    public get panelCompleted() {
        return this.appSettings
            .every(
                (settings) => [undefined, ''].indexOf(this.serviceObject.appSettings[settings.key]) < 0
            )
            && this.formElementValid.every( (valid) => valid );
    }

    public set panelCompleted({}) {} // tslint:disable-line:no-empty
}

export class OrganPanelAppInstallSettingsComponent implements ng.IComponentOptions {
    public bindings = {
        appSettings: '<',
        bundleId: '<',
        language: '<',
        panelCompleted: '=?',
        serviceObject: '='
    };
    public controller = OrganPanelAppInstallSettingsController;
    public template = require('./app-settings.html');
}
