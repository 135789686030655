import * as ng from 'angular';

export class OrganEditPanelMailboxPermissionsController {
    public static $inject: string[] = [];

    public cancel;
    public $editFormOrganism;
    public panelRight;
}

export class OrganEditPanelMailboxPermissionsComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormMailbox'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./mailbox-permissions.html');
    public controller = OrganEditPanelMailboxPermissionsController;
}
