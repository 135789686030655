import * as ng from 'angular';
import './forwarder-list.scss';

export class MoleculeListsForwarderListController implements ng.IController {
    public static $inject: string[] = [];

    public forwarderList: any[];
    public listDisplayLimit = 2;

    $onInit() {
        this.forwarderList = this.forwarderList || [];
    }
}

export class MoleculeListsForwarderListComponent implements ng.IComponentOptions {
    public bindings = {
        forwarderList: '<'
    };
    public controller =  MoleculeListsForwarderListController;
    public template = require('./forwarder-list.html');
}
