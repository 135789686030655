import ng from 'angular';

import { AccountHelperService, AuthContextService, BillingRobotService, ModelHelper } from '@/services';
import * as Types from '@/types';
import { BillingApi, ViewTypes } from '@/types';

export class MoleculePanelEditRowRestorePriceController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        'accountHelper',
        'billingRobot'
    ];

    public isPaidUntilExceededOuter: boolean;
    public restorePrice: number;
    public restoreProduct: ViewTypes.RestoreProduct;
    public type: 'machine' | 'bundle' | 'mailbox' | 'database' | 'dns' | 'webspace';
    public filter: Types.Finding.Filter;
    public storageUpgrades: number;

    constructor(
        private $timeout: ng.ITimeoutService,
        public accountHelper: AccountHelperService,
        private billingRobot: BillingRobotService
    ) { }

    public $onInit = (): void => {
        this.restorePrice = 0;
        this._createFilter(this.restoreProduct.productCode);
        this._calculateRestorePrice();
    };

    public calculateMailbox = (): void => {
        if (this._isPaidUntilExceeded()) {
            const restoreEmail = this.restoreProduct as ViewTypes.RestoreProductEmail;
            if ([undefined, null, ''].indexOf(restoreEmail.bundleId) < 0) {
                return;
            }
            let storageUpgrades = 0;
            switch (restoreEmail.type) {
                case 'ExchangeMailbox':
                case 'ImapMailbox':
                    storageUpgrades = (restoreEmail.storageQuota
                        - ((restoreEmail as Types.EmailApi.ImapMailbox).storageQuotaIncluded || 0))
                        / 1024;
                    break;
            }
            void this.billingRobot.articlePurchasePriceList(this.filter)
                .then(ModelHelper.returnFindOneResponse)
                .then((data: BillingApi.ArticlePurchasePrice) => {
                    void this._filterProduct(data)
                        .then((restorePrice: number) => {
                            this.restorePrice = restorePrice;
                            // add storage upgrades to restore price if product has storageQuota
                            if (restoreEmail.productCode === 'email-imap-mailbox-12m' && storageUpgrades > 0) {
                                return this.billingRobot.articlePurchasePriceList({
                                            field: 'productCode',
                                            value: 'email-imap-mailbox-storage-12m'
                                        })
                                        .then(ModelHelper.returnFindOneResponse)
                                        .then((articlePrice: BillingApi.ArticlePurchasePrice) => {
                                            this.restorePrice += storageUpgrades
                                                * this._grossNetAmount(articlePrice);
                                        });
                            } else if (restoreEmail.productCode === 'email-msexchange-mailbox-1m' && storageUpgrades > 0) {
                                return this.billingRobot.articlePurchasePriceList({
                                            field: 'productCode',
                                            value: 'email-msexchange-mailbox-storage-1m'
                                        })
                                        .then(ModelHelper.returnFindOneResponse)
                                        .then((articlePrice: BillingApi.ArticlePurchasePrice) => {
                                            this.restorePrice += storageUpgrades
                                                * this._grossNetAmount(articlePrice);
                                        });
                            }
                        })
                        .then(this._updatePrice);
                });
        }
    };

    public calculateMachine = (): void => {
        if (this._isPaidUntilExceeded()) {
            void this.billingRobot.articlePurchasePriceList(this.filter)
                .then(ModelHelper.returnFindOneResponse)
                .then((articlePrice: BillingApi.ArticlePurchasePrice) => {
                    void this._filterProduct(articlePrice)
                        .then((restorePrice: number) => {
                            this.restorePrice = restorePrice;
                        })
                        .then(this._updatePrice);
                });
        }
    };

    public calculateBundle = (): void => {
        if (this._isPaidUntilExceeded()) {
            void this.billingRobot.articlePurchasePriceList(this.filter)
                .then(ModelHelper.returnFindOneResponse)
                .then((articlePrice: BillingApi.ArticlePurchasePrice) => {
                    void this._filterProduct(articlePrice)
                        .then((restorePrice: number) => {
                            this.restorePrice = restorePrice;
                        })
                        .then(this._updatePrice);
                });
        }
    };

    public calculateDatabase = (): void => {
        if (this._isPaidUntilExceeded()) {
            const restoreDatabase = this.restoreProduct as ViewTypes.RestoreProductDatabase;
            if ([undefined, null, ''].indexOf(restoreDatabase.bundleId) < 0) {
                return;
            }
            const storageUpgrades = (restoreDatabase.storageQuota
                - restoreDatabase.storageQuotaIncluded)
                / 512;
            void this.billingRobot.articlePurchasePriceList(this.filter)
            .then(ModelHelper.returnFindOneResponse)
            .then((data: BillingApi.ArticlePurchasePrice) => {
                void this._filterProduct(data)
                    .then((restorePrice: number) => {
                        this.restorePrice = restorePrice;
                        // add storage upgrades to restore price if product has storageQuota
                        if (restoreDatabase.productCode === 'database-mariadb-single-v1-1m' && storageUpgrades > 0) {
                            return this.billingRobot.articlePurchasePriceList({
                                        field: 'productCode',
                                        value: 'database-mariadb-single-storage-v1-1m'
                                    })
                                    .then(ModelHelper.returnFindOneResponse)
                                    .then((articlePrice: BillingApi.ArticlePurchasePrice) => {
                                        this.restorePrice += storageUpgrades
                                            * this._grossNetAmount(articlePrice);
                                    });
                        } else if (restoreDatabase.productCode === 'database-mariadb-single-v1-12m' && storageUpgrades > 0) {
                            return this.billingRobot.articlePurchasePriceList({
                                        field: 'productCode',
                                        value: 'database-mariadb-single-storage-v1-12m'
                                    })
                                    .then(ModelHelper.returnFindOneResponse)
                                    .then((articlePrice: BillingApi.ArticlePurchasePrice) => {
                                        this.restorePrice += storageUpgrades
                                            * this._grossNetAmount(articlePrice);
                                    });
                        }
                    })
                    .then(this._updatePrice);
            });
        }
    };

    public calculateDns = (): void => {
        if (this._isPaidUntilExceeded()) {
            void this.billingRobot.articlePurchasePriceList(this.filter)
                .then(ModelHelper.returnFindOneResponse)
                .then((articlePrice: BillingApi.ArticlePurchasePrice) => {
                    void this._filterProduct(articlePrice)
                        .then((restorePrice: number) => {
                            this.restorePrice = restorePrice;
                        })
                        .then(this._updatePrice);
                });
        }
    };

    public calculateWebspace = (): void => {
        if (this._isPaidUntilExceeded()) {
            const restoreWebhosting = this.restoreProduct as ViewTypes.RestoreProductWebhosting;
            const storageUpgrades = (restoreWebhosting.storageQuota
                - restoreWebhosting.storageQuotaIncluded)
                / 1024;
            void this.billingRobot.articlePurchasePriceList(this.filter)
                .then(ModelHelper.returnFindOneResponse)
                .then((data: BillingApi.ArticlePurchasePrice) => {
                    void this._filterProduct(data)
                        .then((restorePrice: number) => {
                            this.restorePrice = restorePrice;
                            // add storage upgrades to restore price if product has storageQuota
                            if (restoreWebhosting.productCode === 'webhosting-webspace-v1-1m' && storageUpgrades > 0) {
                                return this.billingRobot.articlePurchasePriceList({
                                            field: 'productCode',
                                            value: 'webhosting-webspace-storage-v1-1m'
                                        })
                                        .then(ModelHelper.returnFindOneResponse)
                                        .then((articlePrice: BillingApi.ArticlePurchasePrice) => {
                                            this.restorePrice += storageUpgrades
                                                * this._grossNetAmount(articlePrice);
                                        });
                            } else if (restoreWebhosting.productCode === 'webhosting-webspace-v1-12m' && storageUpgrades > 0) {
                                return this.billingRobot.articlePurchasePriceList({
                                            field: 'productCode',
                                            value: 'webhosting-webspace-storage-v1-12m'
                                        })
                                        .then(ModelHelper.returnFindOneResponse)
                                        .then((articlePrice: BillingApi.ArticlePurchasePrice) => {
                                            this.restorePrice += storageUpgrades
                                                * this._grossNetAmount(articlePrice);
                                        });
                            }
                        })
                        .then(this._updatePrice);
                });
        }
    };

    private _createFilter = (productCode: string): void => {
        this.filter = {
            field: 'productCode',
            value: productCode
        };
    };

    private _filterProduct = (data: BillingApi.ArticlePurchasePrice): Promise<number> => {
        return Promise.resolve(this._grossNetAmount(data));
    };

    private _updatePrice = (): void => {
        void this.$timeout((): void => {
            this.restorePrice /= 10000;
        });
    };

    private _grossNetAmount = (article: BillingApi.ArticlePurchasePrice): number => {
        if (AuthContextService.account.isCommercialCustomer) {
            return article.netAmount;
        }
        return article.grossAmount;
    };

    private _isPaidUntilExceeded = (): boolean => {
        const paidUntilDate = new Date((this.restoreProduct).paidUntil);
        const currentDate = new Date();
        this.isPaidUntilExceededOuter = currentDate.getTime() >= paidUntilDate.getTime();
        return this.isPaidUntilExceededOuter;
    };

    private _calculateRestorePrice = (): void => {
        switch (this.type) {
            case 'machine':
                this.calculateMachine();
                break;
            case 'bundle':
                this.calculateBundle();
                break;
            case 'mailbox':
                this.calculateMailbox();
                break;
            case 'database':
                this.calculateDatabase();
                break;
            case 'dns':
                this.calculateDns();
                break;
            case 'webspace':
                this.calculateWebspace();
                break;
            default:
                break;
        }
    };
}

export class MoleculePanelEditRowRestorePriceComponent implements ng.IComponentOptions {
    public bindings = {
        isPaidUntilExceededOuter: '=?',
        restoreProduct: '<',
        type: '@'
    };
    public controller = MoleculePanelEditRowRestorePriceController;
    public template = require('./panel-edit-row-restore-price.html');
}
