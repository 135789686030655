import {
    AlertManagerService,
    ValidateAllowedCharacters,
    ValidateMaxLength,
    ValidateMinLength,
    ValidateNotEmpty
} from '../../../../../services';

import * as ng from 'angular';

export class MoleculePanelEditRowDomainDirectoryProtectionEditUserController implements ng.IController {
    public static $inject: string[] = ['$translate', 'alertManager'];

    public isMarkedRemoval = false;
    public isEditing = false;
    public originalUserName: string;
    public userName: any;
    public newPassword: string;
    public isReadonly: boolean;
    public deletedUsers: string[];
    public changedUsers: any[];
    public vHost: any;
    public isAssignedToLocation = false;

    // Validate username.
    public userNameValidationErrorList: any[] = [];
    public userNameValidationInstructions: any[] = [
        {
            settings: null,
            validator: new ValidateMinLength(this.$translate, 4)
        },
        {
            settings: null,
            validator: new ValidateMaxLength(this.$translate, 32)
        },
        {
            settings: null,
            validator: new ValidateNotEmpty(this.$translate)
        }
    ];

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService
    ) {}

    public $onInit() {
        this.originalUserName = this.userName;
        this.newPassword = '';
        this.userNameValidationErrorList = [];
    }

    public get canSave() {
        return (
            ([undefined, null, ''].indexOf(this.newPassword) < 0)
            && this.userNameValidationErrorList.length <= 0
        );
    }

    public toggleEdit = () => {
        if (!this.isReadonly) {
            // Check if the user is bound to a location.
            this.vHost.locations.forEach(
                (vHostLoc) => {
                    if (vHostLoc.restrictToHttpUsers.indexOf(this.originalUserName) >= 0) {
                        this.isAssignedToLocation = true;
                    }
                }
            );

            this.isEditing = !this.isEditing;
            this.newPassword = '';
        }
    };

    public confirmChanges = () => {
        // End edit mode.
        this.isEditing = false;

        // Find existing change.
        let foundAt = -1;
        this.changedUsers.forEach((val, idx) => {
            if (val.old === this.originalUserName) {
                foundAt = idx;
            }
        });

        // Insert new change or override any existing one.
        const changedEntry = {old: this.originalUserName, new: { name: this.userName, password: this.newPassword }};
        if (foundAt >= 0) {
            this.changedUsers[foundAt] = changedEntry;
        } else {
            this.changedUsers.push(changedEntry);
        }
    };

    public cancelChanges = () => {
        this.isEditing = false;
        this.userName = this.originalUserName;
        this.newPassword = '';

        this.changedUsers.forEach((val, idx) => {
            if (val.old === this.originalUserName) {
                this.changedUsers.splice(idx, 1);
            }
        });
    };

    public toggleDelete = () => {
        if (this.deletedUsers.indexOf(this.originalUserName) < 0) {
            // Check if the removed user is used in any path. Show error if the case.
            let errorString = '';
            this.vHost.locations.forEach(
                (vHostLoc) => {
                    if (vHostLoc.restrictToHttpUsers.indexOf(this.originalUserName) >= 0) {
                        errorString = this.$translate.instant(
                            /* translationId */ 'TR_110419-992b16_TR', {
                                username: this.originalUserName
                            }
                        );
                    }
                }
            );

            if (errorString.length <= 0) {
                // Mark the user for removal.
                this.deletedUsers.push(this.originalUserName);
                this.isMarkedRemoval = true;
            } else {
                // Show error.
                this.alertManager.error(errorString);
            }
        } else {
            this.deletedUsers.splice(this.deletedUsers.indexOf(this.originalUserName), 1);
            this.isMarkedRemoval = false;
        }
    };
}

export class MoleculePanelEditRowDomainDirectoryProtectionEditUserComponent implements ng.IComponentOptions {
    public bindings = {
        changedUsers: '=',
        deletedUsers: '=',
        isReadonly: '<',
        userName: '<',
        vHost: '<'
    };

    public controller = MoleculePanelEditRowDomainDirectoryProtectionEditUserController;
    public template = require('./panel-edit-row-domain-directory-protection-edit-user.html');
}
