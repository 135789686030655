import * as ng from 'angular';
import './status-general.scss';

export class MoleculeStatusGeneralController implements ng.IController {
    public static $inject: string[] = [];

    public status: string;
    public usedStorage: number;
    public availableStorage: number;
    public showAbsolute: boolean;
    public infoText: string;
    public objectType: string;
    public unit: string;
    public restrictions: any;
    public deletionScheduled = '';
    public deletionType = '';
    public showTerminationTerm = false;

    public get showInfoText() {
        return this.infoText !== undefined;
    }

    public get showStorage() {
        return this.usedStorage !== undefined;
    }
}

export class MoleculeStatusGeneralComponent implements ng.IComponentOptions {
    public bindings = {
        status: '@',
        usedStorage: '@',
        availableStorage: '@',
        showAbsolute: '<',
        infoText: '@',
        objectType: '@',
        unit: '@',
        restrictions: '<',
        deletionScheduled: '<',
        deletionType: '<',
        showTerminationTerm: '<?'
    };
    public controller =  MoleculeStatusGeneralController;
    public template = require('./status-general.html');
}
