import { ManagedApplicationRobotService } from '../managed-application';
import { ModelHelper } from '../model-helper';

export class StorageJobModelService {
    public static $inject: string[] = ['managedApplicationRobot'];

    constructor(
        private storageRobot: ManagedApplicationRobotService
    ) {}

    public list = (limit?, page?, filters?, sort?) => {
        sort = sort || {
                field: 'jobDisplayName',
                order: 'ASC'
            };
        page = page || 1;

        return this.storageRobot.jobsFind(filters, limit, page, sort)
            .then(ModelHelper.returnListResponse);
    };

    public findOne = (id: string) => {
        return this.storageRobot.jobsFind({ field: 'jobId', value: id }, 1, 1)
            .then(ModelHelper.returnFindOneResponse);
    };

    public findById = (ids: string[]) => {
        const filter = { subFilterConnective: 'OR', subFilter: ids.map(this.idFilter) };

        return this.storageRobot.jobsFind(filter, 0, 1)
            .then(this.returnResponseDate);
    };

    private idFilter = (id: string) => ({ field: 'jobId', value: id });

    private returnResponseDate = (result) => result.response.data;
}
