import { LinkListOverviewItem } from '../../atomic-components/molecules/links/link-list-overview/link-list-overview';
import { OverviewModel } from '../abstract';
import { RequestCancellerService } from '../request-canceller';
import { DocumentModelService } from './document-model';

export class InvoiceOverviewModel extends OverviewModel {

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = null;
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters({}) {
        return;
    }
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['documentModel']
    );

    readonly service = 'billing';
    readonly objectType = 'Invoices';
    readonly idField = 'documentNumber';
    readonly defaultFilterFields = ['documentNumber'];

    public activeMassAction: string | boolean = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};
    public cutomizedModelListMethods: boolean;

    protected listCallbacks = [{ callback: this.documentModel.listInvoices }];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private documentModel: DocumentModelService
    ) {
        super(RequestCanceller);

        // declare multi action availably methods
        this.actionLinksAvailabilities = {};

        // declare multi action availably methods
        this.cutomizedModelListMethods = false;
    }

    public actionLinks = (): LinkListOverviewItem[] => {
        return [] as LinkListOverviewItem[];
    };
}
