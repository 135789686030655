import * as ng from 'angular';

import orderBy from 'lodash.orderby';

import { UiRights } from '@/configuration/rights';
import { AuthContextService, NavigationService, ResourceHelperService, ResourceModelService } from '@/services';
import { MachineApi } from '@/types/machine-api';

export class OrganEditPanelMachineNetworkInterfacesController {
    public static $inject: string[] = ['$translate', 'navigation', 'resourceHelper', 'resourceModel'];

    public networkInterfaces: MachineApi.NetworkInterface[] | any[];
    public disableEditButton: boolean;
    public userHasRightNetworkList: boolean;

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private navigation: NavigationService,
        private resourceHelper: ResourceHelperService,
        private resourceModel: ResourceModelService
    ) {}

    public $onChanges = async () => {
        this.userHasRightNetworkList = AuthContextService.isGranted(UiRights.RES_NETWORK_LIST);

        let publicIndex = 0;
        let privateIndex = 0;

        this.networkInterfaces = orderBy(this.networkInterfaces,
            ['networkType', 'ipv4PrimaryAddress', 'networkId'],
            ['desc', 'asc', 'desc']);

        // tslint:disable-next-line:prefer-for-of
        for (let index = 0; index < this.networkInterfaces?.length; index++ ) {
            if (this.networkInterfaces[index].networkId && this.userHasRightNetworkList === true) {
                this.networkInterfaces[index].network =
                    await this.resourceModel.findOne('Network', this.networkInterfaces[index].networkId);
            }

            if (this.networkInterfaces[index].networkType === 'publicNetwork') {
                publicIndex++;
                this.networkInterfaces[index].interfaceName = `${this.$translate.instant('TR_170420-611ec4_TR')} ${publicIndex}`;
            } else {
                privateIndex++;
                this.networkInterfaces[index].interfaceName = `${this.$translate.instant('TR_170420-677abc_TR')} ${privateIndex}`;
            }
        }
    };

    public activate = () => {
        this.navigation.go('machine.virtualmachines.id.edit.interfaces');
    };

}

export class OrganEditPanelMachineNetworkInterfacesComponent implements ng.IComponentOptions {
    public bindings = {
        disableEditButton: '<',
        networkInterfaces: '<'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./machine-network-interfaces.html');
    public controller = OrganEditPanelMachineNetworkInterfacesController;
}
