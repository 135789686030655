import * as ng from 'angular';
import { FormDropDownItems } from '../drop-down';

import './drop-down-with-filter.scss';

/**
    These components offer two different types of items.

    Variant 1:
    A list of name, value objects.
    Here, the name is displayed as an option in the dropdown.

    Example:

    items: [
        {'value': 'AF', 'name': 'Afghanistan'},
        {'value': 'EG', 'name': 'Egypt'},
        ....
    ];

    Variant 2:
    A list of names (list of names string[]), value objects
    This format displays a table with the specified columns (headings) in the dropdown list.

    Example:
    items: [
        {
            'names': [
                'bvf-zusatzdomain.de',
                'Bob Hosting.com'
            ],
            'value': '180823456932456'
        },
        {
            'names': [
                'rtzrzrzrtz.at',
                'Bob Hosting.com'
            ],
            'value': '181126285309982'
        },
        ...
    ];
*/

export class MoleculeFormDropDownWithFilterController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public selectedOption: string[]|string;
    public showOptions = false;
    public items: FormDropDownItems[] = [];
    public headings: string[];
    public placeholder: string;
    public disabled: boolean;
    public popoverPosition: string;
    public displayWhenSelected: number;
    public modifier: string;

    public callbackOnChange: (selected: any) => any;

    private _searchTerm: string;
    private _selectedNameCache: string;

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.placeholder = this.placeholder
            || this.$translate.instant('BUNDLE.DELETE.DOMAIN-ACTION.CHOOSE');
    }

    public searchFilter = (item) => {
        if (this._searchTerm === null || this._searchTerm === undefined) {
            return true;
        }

        if (item.names !== undefined && Array.isArray(item.names)) {
            // names as array
            return item.names.some((n) => {
                return n.toLowerCase().indexOf(this._searchTerm.toLowerCase()) >= 0;
            });
        }

        // name as string
        return item.name !== undefined && item.name.toLowerCase().indexOf(this._searchTerm.toLowerCase()) >= 0;
    };

    public toggleOptions() {
        if (!this.isDisabled || this.showOptions) {
            this.showOptions = !this.showOptions;
        }
    }

    public selectOption = (item) => {
        this._selectedNameCache = '';

        this.selectedOption = item.value;
        this.toggleOptions();
        this._searchTerm = null;
        if (this.callbackOnChange !== undefined) {
            this.callbackOnChange(item);
        }
    };

    public get selectedName() {
        if (!this.showOptions) {
            if (this.selectedOption === undefined) {
                return '';
            }
            return this._getValueOfName();
        } else {
            if ([undefined, null].indexOf(this._searchTerm) === -1) {
                return this._searchTerm;
            }
        }

        return '';
    }

    public set selectedName(value) {
        this._searchTerm = value;
    }

    public get headingsDefined() {
        return this.headings !== undefined;
    }

    public get isGivenMultipleNames() {
        if ([undefined, null].indexOf(this.items) >= 0 || [undefined, null, 0].indexOf(this.items.length) >= 0) {
            return;
        }
        if (this.items[0].names !== undefined && Array.isArray(this.items[0].names)) {
            return true;
        } else if (this.items[0].name !== undefined && typeof this.items[0].name === 'string') {
            return false;
        }
    }

    public get isDisabled() {
        return this.disabled || this.items === undefined || this.items.length === 0;
    }

    public escCallback = (event: KeyboardEvent) => {
        this.toggleOptions();
        (event.target as HTMLElement).blur();
    };

    private _getValueOfName = () => {
        if (this._selectedNameCache !== undefined && this._selectedNameCache.length > 0) {
            return this._selectedNameCache;
        }
        this.items.some((item) => {
            if (
                ng.isString(this.selectedOption)
                && ng.isString(item.value)
                && (this.selectedOption ).toLowerCase() === (item.value ).toLowerCase()
            ) {
                this._selectedNameCache = this.isGivenMultipleNames ? item.names[this.displayWhenSelected] : item.name;
                return true;
            }

            if (this.selectedOption === item.value || this.selectedOption === item.name) {
                this._selectedNameCache = this.isGivenMultipleNames ? item.names[this.displayWhenSelected] : item.name;
                return true;
            }
            return false;
        });

        return this._selectedNameCache;
    };
}

export class MoleculeFormDropDownWithFilterComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnChange: '<',
        disabled: '<',
        displayWhenSelected: '<',
        headings: '<',
        items: '<',
        modifier: '@?',
        placeholder: '@',
        popoverPosition: '@',
        selectedOption: '='
    };
    public controller =  MoleculeFormDropDownWithFilterController;
    // tslint:disable-next-line: max-line-length
    public template = require('./drop-down-with-filter.html');
}
