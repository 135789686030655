import * as ng from 'angular';
import {
    AlertManagerService,
    BundleHelperService,
    DomainHelperService,
    DomainModelService,
    NavigationService
} from '../../../../../services';
import * as Types from '../../../../../types';

export class OrganismEditFormBundleAddDomainController {

    public static $inject: string[] = [
        '$translate',
        '$timeout',
        'alertManager',
        'bundleHelper',
        'domainHelper',
        'domainModel',
        'navigation',
        'vhostModel',
        'webspaceModel'
    ];

    public originalBundle: any;
    public userPanelRight: any;
    public domain: string;

    private _domainItems: { name: string; value: string }[] = [];
    private _possibleDomainsLoaded = false;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private $timeout: ng.ITimeoutService,
        private alertManager: AlertManagerService,
        private bundleHelper: BundleHelperService,
        private domainHelper: DomainHelperService,
        private domainModel: DomainModelService,
        private navigation: NavigationService
    ) {}

    public $onInit = () => {
        // initialize domainItems for dropdown
        const domainContingentTlds = this.bundleHelper.getDomainContingentTlds(this.originalBundle);
        if (this.bundleHelper.checkBundleContingentsWithProductCodePart('domain-', this.originalBundle)) {
            this.domainHelper.getPossibleBundlesIncludingDomains(this.originalBundle, domainContingentTlds)
            .then(
                (domainItems) => {
                    this._domainItems = domainItems;
                    this._possibleDomainsLoaded = true;
                }
            );
        } else {
            this._possibleDomainsLoaded = true;
        }
    };

    public set domainItems({}) {} // tslint:disable-line:no-empty
    public get domainItems() {
        return this._domainItems;
    }

    public set loadingPossibleDomainsToAdd({}) {} // tslint:disable-line:no-empty
    public get loadingPossibleDomainsToAdd() {
        return !this._possibleDomainsLoaded;
    }

    public save = () => {
        this.domainModel.findOne(this.domain).then(
            (domain) => {
                this.domainModel.domainAttachToBundle(this.originalBundle.id, domain.name).then(
                    () => {
                        this.alertManager.success(this.$translate.instant('TR_100119-c541f4_TR'));
                        this.$timeout(() => {
                            this.navigation.go('bundle.id.dashboard');
                        },
                        1000);
                    },
                    () => {
                        this.alertManager.error(this.$translate.instant('TR_100119-736eb8_TR'));
                    }
                );
            }
        );
    };

    public cancel = () => {
        this.navigation.go('bundle.id.dashboard');
    };
}

export class OrganismEditFormBundleAddDomainComponent implements ng.IComponentOptions {
    public bindings = {
        originalBundle: '<bundle',
        userPanelRight: '='
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormBundleAddDomainController;
    public template = require('./bundle-add-domain.html');
}
