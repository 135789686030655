import ng from 'angular';
import { ProductsModelService } from '@/services/products/products-model';
import { AccountApi } from '@/types';
import { ViewTypes } from '@/types/view-types';


export class OrganSslWizardProductConfigCertificateTypeController {
    public static $inject: string[] = [
        'productsModel',
        '$scope'
    ];

    public account: AccountApi.AbstractAccount;
    public metaData: ViewTypes.ProductConfigSslObject;
    public service = 'ssl';
    public sslBrandLogos: { imgLink: string; productCodeIdentifier: string }[] = [
        {
            imgLink: 'https://static.hosting.zone/images/ssl-logos/rapidssl.svg',
            productCodeIdentifier: 'ssl-geotrust-rapidssl'
        },
        {
            imgLink: 'https://static.hosting.zone/images/ssl-logos/geotrust.svg',
            productCodeIdentifier: '(ssl-geotrust-quicksslpremium|ssl-geotrust-truebizid)'
        },
        {
            imgLink: '/assets/images/logos/Sectigo_Logo_Web.svg',
            productCodeIdentifier: 'ssl-sectigo'
        },
        {
            imgLink: '/assets/images/logos/DigiCert.svg',
            productCodeIdentifier: 'ssl-digicert'
        }
    ];
    public productFamilies = [
        'dv-certificates',
        'ov-certificates',
        'ev-certificates',
        'email-certificates'
    ];
    public productSelectionOverlay: ViewTypes.ProductSelectionOverlay[];
    private _productCodeList: string[];

    public isWwwOnTldIncluded: boolean = false;

    public constructor(
        private productsModel: ProductsModelService,
        private $scope: ng.IScope,
    ) {
        this.productSelectionOverlay = [];
        for (const logo of this.sslBrandLogos) {
            this.productSelectionOverlay.push(
                {
                    action: {
                        type: 'product',
                        mode: 'extend'
                    },
                    target: {
                        field: 'productCodeTemplate',
                        useRegex: true,
                        regex: new RegExp(`^${logo.productCodeIdentifier}`, 'i')
                    },
                    data: ([
                        {
                            field: 'productImage',
                            newValue: logo.imgLink
                        }
                    ] as ViewTypes.ProductSelectionOverlayAdd[])
                }
            );
        }
    }

    public get sslProductCode() {
        return this.metaData.sslProductCode;
    }

    public set sslProductCode(sslProductCode) {
        this.metaData.sslProductCode = sslProductCode;
        if ([null, undefined].indexOf(sslProductCode) < 0) {
            this.productCodeList = [ng.copy(sslProductCode)];
            this.requestProductSpecification();
        } else {
            this.productCodeList = [];
        }
    }

    public set productCodeList(list) {
        this._productCodeList = ng.copy(list);
    }
    public get productCodeList() {
        return this._productCodeList;
    }

    public requestProductSpecification = () => {
        this.productsModel.findOne(this.metaData.sslProductCode).then(
            (reply) => {
                const tmp = ng.copy(this.metaData);
                tmp.settings.productSpecification = ng.copy(reply.response.specificationItems);
                this.metaData = tmp;
                this.$scope.$emit('productSpecificationChanged', tmp.settings.productSpecification);
                this.$scope.$broadcast('productSpecificationChanged', tmp.settings.productSpecification);
            }
        );
    };
}

export class OrganSslWizardProductConfigCertificateTypeComponent implements ng.IComponentOptions {
    public bindings = {
        metaData: '=',
        account: '<'
    };
    public request = {

    }
    public template = require('./config-certificate-type.html');
    public controller = OrganSslWizardProductConfigCertificateTypeController;
}
