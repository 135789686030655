import * as Types from '@/types';
import ng, { IScope } from 'angular';
import { GenericInjectedRequests } from './setup';
import {
    AccountModelService,
    DepositModelService,
    OrchestratorRobotService,
} from '@/services';
import { OrchestrationPoll } from '@/services/setup/orchestration-poll';
import { PanelHeaderData } from '@/atomic-components/molecules/panels';
import { GenericSetup } from '@/components/setup/setup';

type APISingleResponse<T> = Types.UI.APISingleResponse<T>;
type BankAccount = Types.BillingApi.BankAccount;
type PaymentMethod = Types.BillingApi.PaymentMethod;

export class TemplateSetupController {
    public static $inject: string[] = [
        'accountModel',
        'depositModel',
        'orchestratorRobot',
        '$rootScope',
        '$translate',
    ];

    public pageHeaderData: PanelHeaderData;

    public orderId: string = '';
    public genericInjectionRequests: GenericInjectedRequests = {
        createBankAccount: (iban: string, accountHolder: string, bic: string, dateOfBirth: Date): Promise<APISingleResponse<BankAccount>> => {
            return this.depositModel.bankAccountCreate(iban, accountHolder, bic, dateOfBirth);
        },
        checkPaymentMethodsAvailability: (): Promise<PaymentMethod[]> => {
            return this.depositModel.paymentMethodAvailability();
        },
        getBankAccount: (): Promise<BankAccount[]> => {
            return this.accountModel.ownBankAccountsFind();
        },
    };

    constructor(
        protected accountModel: AccountModelService,
        protected depositModel: DepositModelService,
        protected orchestratorRobot: OrchestratorRobotService,
        protected $rootScope: IScope,
        protected $translate: ng.translate.ITranslateService,
    ){}

    public $onInitGeneric(setupInstance: GenericSetup): void {
        if (!this.pageHeaderData) {
            this.pageHeaderData = {
                backwardLink: 'old-dashboard',
                backwardText: this.$translate.instant('TR_090119-e73cf8_TR'),
                panelHeaderRoute: '',
                panelHeaderRouteParams: {},
                panelIcon: 'box-alt',
                panelTitle: this.$translate.instant('18249206-2af0-4e97-abe6-51184efa0ce2'),
            };
        }

        const orchestrationPoll = new OrchestrationPoll(this.orchestratorRobot, setupInstance);
        void orchestrationPoll.poll(this.orderId);

        setupInstance.loadInitialData();
    }
}
