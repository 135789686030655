/* eslint-disable */

import { MachineProductFamilies } from '@/configuration';
import { InstallerModelService, OrchestratorRobotService } from '@/services';
import * as ng from 'angular';
import { WizardCreateObject } from '../../general';
import { MachineWizardConfirmation } from './';

export class OrganismMachineWizardConfirmViewController {
    public static $inject: string[] = [
        '$translate',
        'installerModel',
        'localInterval',
        'machineWizardConfirmation',
        'orchestratorRobot'
    ];

    private _apiProductObject: any; // Required information for the products that need to be ordered.
    private wizardCreateObjectList: WizardCreateObject[] = []; // List of running jobs.

    public constructor(
        private $translate: ng.translate.ITranslateService,
        public installerModel: InstallerModelService,
        private localInterval: ng.IIntervalService,
        public machineWizardConfirmation: MachineWizardConfirmation,
        private orchestratorRobot: OrchestratorRobotService
    ) {}

    public $onDestroy(): void {
        this.localInterval.cancel(this.machineWizardConfirmation.checkJobStatusIntervalHandler);
    }

    public $onInit(): any {
        let createJob: WizardCreateObject;
        this.machineWizardConfirmation.useOrchestrationService = false;
        this.machineWizardConfirmation.selectedProductFamily = this._apiProductObject.productFamily;
        switch (this._apiProductObject.productFamily) {
            case MachineProductFamilies.cloudServer:
                createJob = {
                    callback:
                        () => {
                            let osData = this._apiProductObject.apiObject.osInstallerData;
                            if (osData && typeof osData === 'object') {
                                osData.defaultLocale = "de";
                            }
                            return this.installerModel
                                .installServer(
                                    this._apiProductObject.apiObject.virtualMachine,
                                    {
                                        poolId: this._apiProductObject.apiObject.poolId,
                                        virtualMachineHostId: this._apiProductObject.apiObject.virtualMachineHostId
                                    },
                                    this._apiProductObject.apiObject.osId,
                                    osData,
                                    this._apiProductObject.apiObject.accountId,
                                    this._apiProductObject.apiObject.voucher?.voucherCode ?? undefined,
                                );
                        },
                    children: [],
                    labelText: this.$translate.instant('9fd32096-6a1f-43c6-a467-4ef75d7593e7'),
                    objectType: 'VirtualMachine'
                };
                this.wizardCreateObjectList.push(createJob);
                break;

            case MachineProductFamilies.managedCloudServer:
                createJob = {
                    callback:
                        () => {
                            return this.installerModel
                                .installManagedServer(
                                    this._apiProductObject.apiObject.virtualMachine,
                                    {
                                        poolId: this._apiProductObject.apiObject.poolId,
                                        virtualMachineHostId: this._apiProductObject.apiObject.virtualMachineHostId
                                    },
                                    this._apiProductObject.apiObject.accountId
                                );
                        },
                    children: [],
                    labelText: this.$translate.instant('TR_130319-63b110_TR'),
                    objectType: 'VirtualMachine'
                };
                this.wizardCreateObjectList.push(createJob);
                break;

            case MachineProductFamilies.ecommerceServer:
                createJob = {
                    callback:
                        () => {
                            return this.installerModel.getDefaultPoolIdForNewManagedMachine(
                                this._apiProductObject.accountId,
                                this._apiProductObject.apiObject.name
                            ).then(
                                (poolId) => {
                                    this._apiProductObject.apiObject.poolId = poolId;
                                    return this.orchestratorRobot.order(
                                        this._apiProductObject.apiObject,
                                        this._apiProductObject.accountId
                                    );
                                }
                            );
                        },
                    children: [],
                    labelText: this.$translate.instant('TR_280520-fbe763_TR'),
                    objectType: 'VirtualMachine',
                    objectSubType: 'eCommerceServer'
                };

                this.wizardCreateObjectList.push(createJob);
                break;

            case MachineProductFamilies.individualServer:
                createJob = {
                    callback:
                        () => {
                            return this.installerModel.installIndividualServer(
                                this._apiProductObject.apiObject
                            );
                        },
                    children: [],
                    labelText: this.$translate.instant('TR_090620-a6051a_TR'),
                    objectType: 'VirtualMachine',
                    objectSubType: 'individualServer'
                };

                this.wizardCreateObjectList.push(createJob);
                break;

            default:
                console.error('not yet implemented!');
                break;
        }

        // Walk the request chain and call every entry in order.
        this.machineWizardConfirmation.startOrderProcess(this.wizardCreateObjectList);
    }
}

export class OrganismMachineWizardConfirmViewComponent implements ng.IComponentOptions {
    public bindings = {
        _apiProductObject: '<apiProductObject'
    };

    public template = require('./machine-wizard-confirm-view.html');
    public controller = OrganismMachineWizardConfirmViewController;
}
