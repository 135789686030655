import {
    BundleHandler,
    DatabaseHandler,
    DomainHandler,
    MailboxHandler,
    VHostHandler,
    WebspaceHandler
} from './specific-handlers';

import * as Types from '../../../types';

/**
 * Intended as the main class to be called. Supplies static methods to wrap API objects
 * in helper classes.
 */
export class ApiObject {
    public static bundle: (bundle: Types.BundleApi.Bundle) => BundleHandler
    = (bundle) => new BundleHandler(bundle);

    public static database: (database: Types.DatabaseApi.Database) => DatabaseHandler
    = (database) => new DatabaseHandler(database);

    public static domain: (domain: Types.DomainApi.Domain) => DomainHandler
    = (domain) => new DomainHandler(domain);

    public static mailbox: (mailbox: Types.EmailApi.Mailbox) => MailboxHandler
    = (mailbox) => new MailboxHandler(mailbox);

    public static vHost: (vHost: Types.WebhostingApi.VHost) => VHostHandler
    = (vHost) => new VHostHandler(vHost);

    public static webspace: (webspace: Types.WebhostingApi.Webspace) => WebspaceHandler
    = (webspace) => new WebspaceHandler(webspace);
}
