import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import {
    AccountModelService,
    AuthContextService
} from '../../../../../services';
import { FormDropDownItems } from '../../../../molecules/forms/form-elements';
import { WizardObject } from '../../../../molecules/wizard/wizard-supervisor/wizard-supervisor';
import { OrganismCreateBundleController } from './';

export class OrganismCreateBundleConfigController {
    public static $inject: string[] = ['accountModel', 'authContext'];

    public $createOrganism: OrganismCreateBundleController;
    public serviceObject: WizardObject;
    public subAccountDropdownItems: FormDropDownItems[] = [];
    public bundleProductData: null | any = null;
    public resetDomainRegisterTransfer = false;
    public vhostValidationErrors: any[] = [];

    // public webhostingProductCode: string = '';
    public databaseContingents: any[] = [];
    public domainContingents: any[] = [];
    public formCompleted = {
        // tslint:disable:no-empty
        accesses: ()    => {},
        bundle: ()      => {},
        database: ()    => {},
        domain: ()      => {},
        projectName: () => {},
        vhost: ()       => {},
        webspace: ()    => {}
        // tslint:enable:no-empty
    };

    private _hasSubaccounts = false;

    constructor(
        private accountModel: AccountModelService,
        private authContext: AuthContextService
    ) {}

    public $onInit() {
        this.getSubaccounts();
        this.getDatabaseContingents();
        this.getDomainContingents();
    }

    public getSubaccounts = () => {
        if (this.authContext.isGranted(UiRights.ACC_SUBACCOUNT_LIST)) {
            // Load subaccounts of the reseller
            this.accountModel.listSubaccounts().then(
                (reply) => {
                    reply.data.map(
                        (subAccount: any) => this.subAccountDropdownItems.push({
                            name: subAccount.name,
                            value: subAccount.id
                        })
                    );
                    this._hasSubaccounts = this.subAccountDropdownItems.length > 0;
            });
        }
        this._hasSubaccounts = false;
    };

    public get showAccountSelection() {
        if (!this._hasSubaccounts
            && [undefined, null, ''].indexOf(this.serviceObject.settings.createData.accountId) >= 0
        ) {
            this.onAccountChange();
        }

        return this._hasSubaccounts;
    }

    public set showAccountSelection({}) {} // tslint:disable-line:no-empty

    public get showInitializing() {
        return !this.showBundleConfiguration
            && [undefined, null, ''].indexOf(this.serviceObject.settings.accountId) < 0
            && [undefined, null].indexOf(this.serviceObject.selectedProduct) < 0;
    }

    public set showInitializing({}) {} // tslint:disable-line:no-empty

    public get showBundleConfiguration() {
        return [undefined, null].indexOf(this.bundleProductData) < 0
            && this.serviceObject.display.sufficientCreditAvailable;
    }

    public set showBundleConfiguration({}) {} // tslint:disable-line:no-empty

    public get formIsCompleted() {
        if (!this.showBundleConfiguration) {
            return false;
        }

        let completed = true;
        for (const part in this.formCompleted) {
            if (!this.formCompleted[part]) {
                completed = false;
            }
        }
        return completed;
    }

    public getDatabaseContingents = () => {
        if (this.authContext.isGranted(UiRights.DB_DATABASE_CREATE)) {
            this.databaseContingents = null;
        }

        if ([undefined, null].indexOf(this.serviceObject.display) < 0
            && [undefined, null].indexOf(this.serviceObject.display.contingents) < 0
        ) {
            this.databaseContingents = this.serviceObject.display.contingents.filter((contingentItem: any) => {
                return contingentItem.service === 'database';
            });
        } else {
            this.databaseContingents = [];
        }
    };

    public get webspaceProductCode() {
        if (!this.authContext.isGranted(UiRights.WEB_WEBSPACE_CREATE)) {
            return '';
        }

        if ([undefined, null].indexOf(this.serviceObject.display) < 0
            && [undefined, null].indexOf(this.serviceObject.display.contingents) < 0
        ) {
            const contingent = this.serviceObject.display.contingents.filter((contingentItem: any) => {
                return contingentItem.service === 'webhosting';
            });

            if (contingent.length >= 1 && contingent[0].capacity >= 1) {
                return contingent[0].productCodes[0];
            }
        }
        return '';
    }

    public getDomainContingents = () => {
        if (!this.authContext.isGrantedAny([
            UiRights.DOM_DOMAINS_CREATE_WITH_CONTACTS,
            UiRights.DOM_DOMAINS_CREATE_WITH_DEFAULTS,
            UiRights.DOM_DOMAINS_CREATE_WITH_OWN_NS
        ])) {
            this.domainContingents = null;
            return;
        }

        if ([undefined, null].indexOf(this.serviceObject.display) < 0
            && [undefined, null].indexOf(this.serviceObject.display.contingents) < 0
        ) {
            this.domainContingents = this.serviceObject.display.contingents.filter((contingentItem: any) => {
                return contingentItem.service === 'domain';
            });
        } else {
            this.domainContingents = [];
        }
    };

    public onAccountChange = (accountId?: string) => {
        accountId = accountId || this.serviceObject.settings.createData.accountId;
        if ([undefined, null, ''].indexOf(accountId) < 0 && accountId !== this.authContext.account.id) {
            this.accountModel.findOne(accountId)
                .then((account) => {
                    this.serviceObject.settings.account = account;
                    this.serviceObject.settings.createData.accountId = account.id;
                }
            );
        } else {
            this.serviceObject.settings.account = this.authContext.account;
            this.serviceObject.settings.createData.accountId = this.authContext.account.id;
        }
    };

    public vhostHasChanged = () => {
        this.resetDomainRegisterTransfer = true;
    };
}

export class OrganismCreateBundleConfigComponent implements ng.IComponentOptions {
    public require = {
        $createOrganism: '^organismCreateBundle'
    };
    public bindings = {
        bundleProductData: '<',
        serviceObject: '='
    };
    public template = require('./bundle-config.html');
    public controller = OrganismCreateBundleConfigController;
}
