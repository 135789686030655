import * as ng from 'angular';

import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateDomainRecordsEditController {
    public static $inject: string[] = ['$translate'];

    public zone: any;
    public records: any[];
    public useDefaults;
    public pageHeaderData: PanelHeaderData = {
        backwardLink: 'domain.domains.id.edit',
        panelHeaderRoute: '',
        panelIcon: 'globe',
        panelTitle: ''
    };

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit() {
        this.pageHeaderData.panelTitle = this.$translate.instant('TR_100119-467116_TR', { zoneName: this.zone.name });
        this.pageHeaderData.backwardText = this.$translate.instant(
            /* translationId */ 'TR_110119-1ef0c8_TR', { domainName: this.zone.name }
        );
    }
}

export class TemplateDomainRecordsEditComponent implements ng.IComponentOptions {
    public bindings = {
        records: '<',
        useDefaults: '<',
        zone: '<'
    };
    public template = require('./domain-records-edit-template.html');
    public controller = TemplateDomainRecordsEditController;
}
