import ng from 'angular';
import { AuthContextService, NavigationService } from '@/services';
import { DnsApi, ViewTypes } from '@/types';

import './records-simple.scss';

export class OrganViewPanelRecordsSimpleController {
    public static $inject: string[] = ['authContext', 'navigation'];

    public records: DnsApi.Record[];
    public zone: DnsApi.ZoneConfig;
    public defaultNameserver: ViewTypes.Nameservers[];
    public hideEditButton = false;
    public hideHeader: boolean;
    public hidePriority: boolean;
    public showLargeZoneInfo: boolean;
    public showTtl = false;
    public template = false;

    constructor(
        private authContext: AuthContextService,
        private navigation: NavigationService
    ) {}

    public $onInit(): void {
        this.showLargeZoneInfo = this.showLargeZoneInfo || false;
        this.hideHeader = this.hideHeader || false;
        this.hidePriority = this.hidePriority || false;
    }

    public get foreignRecords(): DnsApi.Record[] {
        // we want to show all records to resellers
        if (this.authContext.account.isCommercialCustomer) {
            return this.records;
        }
        const foreignRecords = [];
        for (const record of this.records) {
            const isDefaultNameServer = [undefined, null].indexOf(this.defaultNameserver) < 0
                && this.defaultNameserver.some((ns) => ns.name === record.content);
            let isDefaultMxRecord = false;
            if (
                [null, undefined].indexOf(this.authContext.accountDefaults) < 0
                && [null, undefined].indexOf(this.authContext.accountDefaults.mxRecords) < 0
            ) {
                isDefaultMxRecord = this.authContext.accountDefaults.mxRecords.some(
                    (mx) => mx.content === record.content);
            }
            if (record.type !== 'NS' && record.type !== 'MX') {
                foreignRecords.push(record);
            } else if (record.type === 'NS' && !isDefaultNameServer) {
                foreignRecords.push(record);
            } else if (record.type === 'MX' && !isDefaultMxRecord) {
                foreignRecords.push(record);
            }
        }
        return foreignRecords;
    }

    public get mxRecordsAreInternal(): boolean {
        // we only want to show this to non-resellers
        if (this.authContext.account.isCommercialCustomer) {
            return false;
        }
        return this.records.some(
            (record) => {
                let isDefaultMxRecord = false;
                if (
                    [null, undefined].includes(this.authContext.accountDefaults)
                    && [null, undefined].includes(this.authContext.accountDefaults.mxRecords)
                ) {
                    isDefaultMxRecord = this.authContext.accountDefaults.mxRecords.some(
                        (mx) => mx.content === record.content);
                }
                return record.type === 'MX' && isDefaultMxRecord;
            }
        );
    }

    public get nsRecordsAreInternal(): boolean {
        if (this.authContext.account.isCommercialCustomer) {
            return false;
        }
        return this.records.some(
            (record) => {
                return record.type === 'NS'
                    && [undefined, null].indexOf(this.defaultNameserver) < 0
                    && this.defaultNameserver.some((ns) => ns.name === record.content);
            }
        );
    }

    public gotToRecordsEdit = (): void => {
        void this.navigation.go(
            'dns.zones.id.records-edit',
            {
                zoneId: this.zone.id,
                fromZoneEdit: 'dns.zones.id.edit'
            }
        );
    };
}

export class OrganViewPanelRecordsSimpleComponent implements ng.IComponentOptions {
    public bindings = {
        defaultNameserver: '<',
        hideEditButton: '<?',
        hideHeader: '<',
        hidePriority: '<?',
        records: '<',
        showLargeZoneInfo: '<?',
        showTtl: '<?',
        template: '<?',
        zone: '<'
    };
    public controller = OrganViewPanelRecordsSimpleController;
    public template = require('./records-simple.html');
}
