import * as ng from 'angular';
import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';

export class TemplateExchangeOrganizationEditController {
    public static $inject: string[] = ['$translate', 'authContext'];

    public modifier = '';
    public pageHeaderData: PanelHeaderData;

    public organization;
    public userPanelRight;

    public faqArticleIdList: string[] = [
        'email-exchange-definition',
        'email-exchange-server'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit = () => {
        const organization = this.organization;

        this.pageHeaderData = {
            backwardLink: 'email.organizations.overview',
            backwardText: this.$translate.instant('TR_110119-854aac_TR'),
            panelHeaderRoute: '',
            panelIcon: 'envelope',
            panelTitle: organization.name
        };

        this.userPanelRight = {
            deletion: {
                delete: this.authContext.isGranted(UiRights.EMAIL_ORGANIZATION_DELETE),
                deletePermanently: this.authContext.isGranted(UiRights.EMAIL_ORGANIZATION_DELETE),
                editPanelButton: this.authContext.isGranted(UiRights.EMAIL_ORGANIZATION_DELETE)
            },
            general: {
                editPanelButton: this.authContext.isGranted(UiRights.EMAIL_ORGANIZATION_EDIT)
            }
        };
    };
}

export class TemplateExchangeOrganizationEditComponent implements ng.IComponentOptions {
    public bindings = {
        organization: '<'
    };
    public controller = TemplateExchangeOrganizationEditController;
    public controllerAs = '$TemplateExchangeOrganizationEditCtrl';
    public template = require('./organization-edit-template.html');
}
