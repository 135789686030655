import { FamilyProductBoxObject, ProductBoxObject, ScrollToValidationError } from '@/services';
import * as ng from 'angular';
import { ProductFamiliesConst } from '../../../../../configuration/billing';
import { AsteriskNoteService, WizardNewHelperService } from '../../../../../services';
import * as Types from '../../../../../types';
import { AccountApi, DnsApi } from '../../../../../types';
import { ViewTypes } from '../../../../../types/view-types';

export class OrganismDnsWizardConfigViewController {
    public static $inject: string[] = ['$state', '$timeout', '$translate', 'asteriskNote', 'wizardNewHelper'];

    public callback: (metaData: ViewTypes.ProductConfigDnsObject) => {};
    public configCompleted: boolean; // used in template
    public dnsProductBoxData = [];
    public metaData: ViewTypes.ProductConfigDnsObject;
    public outerAccount: Types.AccountApi.Account;
    public outerProductCode: string;
    public outerProductFamily: string;
    public productFamilies = ProductFamiliesConst.dns;
    public productFamily: string;
    public productSelectionOverlay: ViewTypes.ProductSelectionOverlay[];
    public selectedProductCode: string = null;
    public service = 'dns';
    public sufficientCreditAvailable = true;
    public ddnsProductCode = 'dns-ddns-hostname-12m';
    public preselectedItemObject: { productFamily: string; productCode: string };
    public zone: DnsApi.ZoneConfig;
    public zoneHasBeenPreselected = false;

    private _account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    private _dnsModes = [];
    private _metaData: ViewTypes.ProductConfigDnsObject;

    public constructor(
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        private asteriskNote: AsteriskNoteService,
        private wizardNewHelper: WizardNewHelperService
    ) {}

    public $onInit() {
        this.zone = this.$state.$current.locals.globals.zone;
        this.zoneHasBeenPreselected = typeof this.zone?.nameUnicode === 'string';

        if (this.outerProductCode && this.outerProductFamily) {
            this.preselectedItemObject = { productFamily: this.outerProductFamily, productCode: this.outerProductCode };
        } else if (this.outerProductFamily) {
            if (this.zone && this.zone.type !== 'SLAVE') {
                const slaveProductCode = 'slave';
                this.outerProductCode = slaveProductCode;
                this.selectedProductCode = slaveProductCode;
                this.preselectedItemObject = { productFamily: this.outerProductFamily, productCode: slaveProductCode };
            } else {
                this.preselectedItemObject = { productFamily: this.outerProductFamily, productCode: null };
            }
        }
        /**
         * These are the DNS Zone create modes They are used to extend the product selection
         * The Product Overlay object itself is build in the _productSelectionOverlayData methode.
         * They are also used to decide when to hide the asterisk texts.
         */
        this._dnsModes = [
            {
                mode: 'quick',
                name: this.$translate.instant('TR_090920-70a353_TR'),
                shortDescription: this.$translate.instant('TR_110119-a7c8df_TR')
            },
            {
                mode: 'expert',
                name: this.$translate.instant('TR_090920-9605b0_TR'),
                shortDescription: this.$translate.instant('TR_110119-c43141_TR')
            },
            {
                mode: 'copy',
                name: this.$translate.instant('TR_090920-f2f0b2_TR'),
                shortDescription: this.$translate.instant('TR_110119-168ddf_TR')
            },
            {
                mode: 'template',
                name: this.$translate.instant('TR_090920-a86415_TR'),
                shortDescription: this.$translate.instant('TR_110119-449aff_TR')
            }
        ];

        if (!this.zoneHasBeenPreselected) {
            this._dnsModes.push({
                mode: 'slave',
                name: this.$translate.instant('TR_090920-b5e9ab_TR'),
                shortDescription: this.$translate.instant('TR_110119-276252_TR')
            });
        }

        this.productSelectionOverlay = this._productSelectionOverlayData();
        this.asteriskNote.resetNodes();

        if ([undefined, null].indexOf(this.outerAccount) < 0) {
            this.account = this.outerAccount;
        }
    }

    public $doCheck() {
        /**
         * Okay, maybe not the most performant solution, but ey, you got a better idea?
         */
        if (this.metaData !== this._metaData) {
            this._metaData = ng.copy(this.metaData);
        }
    }

    get account(): AccountApi.Account | AccountApi.Subaccount {
        return this._account;
    }

    set account(value: AccountApi.Account | AccountApi.Subaccount) {
        if (
            [undefined, null].indexOf(value) < 0
            && value !== this._account
            && [undefined, null].indexOf(this.metaData) < 0
        ) {
            this.metaData.account = value;
        }

        this._account = value;
    }

    public get buttonText() { // used in template
        if (this.zoneHasBeenPreselected) {
            return this.$translate.instant('TR_101120-c66f1e_TR');
        }

        return this.productFamily === 'dns-zone'
            ? this.$translate.instant('DNS.GENERAL.FORM.CREATE')
            : this.$translate.instant('TR_110119-6e44c0_TR');
    }

    public get hideAsteriskText() { // used in template
        return [null, undefined].indexOf(this.selectedProductCode) >= 0
            || this._dnsModes.some((mode) => mode.mode === this.selectedProductCode);
    }

    public set configurationIsCompleted({}) {} // tslint:disable-line:no-empty
    public get configurationIsCompleted() {
        return this.configCompleted && this.sufficientCreditAvailable;
    }

    public productChangeCallback = (selectedProduct) => {
        this.selectedProductCode = selectedProduct.productCode;
    };

    public scrollToError = () => {
        ScrollToValidationError.scrollToError(this.$timeout);
    };

    public finishConfiguration = () => {
        if (this.callback !== undefined) {
            this.metaData.productCode = this.selectedProductCode;
            this.callback(this.metaData);
        }
    };

    private _productSelectionOverlayData = () => {
        const overlayData: ViewTypes.ProductSelectionOverlay[] = [
            {
                action: {
                    type: 'family',
                    mode: this.zoneHasBeenPreselected ? 'remove' : 'extend'
                },
                target: {
                    field: 'family',
                    value: 'ddns-hostnames'
                },
                data: ([
                    {
                        field: 'name',
                        newValue: this.$translate.instant('TR_130319-d18157_TR')
                    },
                    {
                        field: 'shortDescription',
                        newValue: this.$translate.instant('TR_150920-a3b8f0_TR')
                    }
                ] as ViewTypes.ProductSelectionOverlayAdd[])
            },
            {
                action: {
                    type: 'family',
                    mode: 'add',
                    addInFront: true
                },
                data: [
                    ({
                        description: null,
                        family: 'dns-zone',
                        hidePrice: true,
                        name: this.$translate.instant('e7791f8c-44a9-45f6-b38c-4b2d7f3ae22a'),
                        products: this._dnsModes.map(
                            (createMode) => (
                                ({
                                    billingCycles: null,
                                    description: '',
                                    factSheetItems: [],
                                    family: 'dns-zone',
                                    hidePrice: true,
                                    keywordsDescriptions: [],
                                    name: createMode.name,
                                    productCode: createMode.mode,
                                    productCodeTemplate: null,
                                    recommend: false,
                                    service: 'dns',
                                    shortDescription: createMode.shortDescription,
                                    specificationItems: [],
                                    summaryKeywords: [],
                                    type: 'ProductBoxObject'
                                } as ProductBoxObject)
                            )
                        ),
                        service: 'dns',
                        shortDescription: this.$translate.instant('TR_150920-70e157_TR'),
                        specificationItems: undefined,
                        summaryKeywords: undefined,
                        type: 'FamilyProductBoxObject'
                    } as FamilyProductBoxObject)
                ]
            }
        ];

        return overlayData;
    };
}

export class OrganismDnsWizardConfigViewComponent implements ng.IComponentOptions {
    public bindings = {
        callback: '<',
        productFamily: '=',
        outerAccount: '<',
        outerProductCode: '<',
        outerProductFamily: '<'
    };
    public template = require('./dns-wizard-config-view.html');
    public controller = OrganismDnsWizardConfigViewController;
}
