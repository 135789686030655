import ng from 'angular';
import { UiLanguageDefaultSettingsConst } from '@/configuration';
import { AuthContextService, CountryListService, UserSettingsModelService } from '@/services';

export class MoleculeFormDropDownCountriesController implements ng.IController {
    public static $inject: string[] = ['$timeout', 'userSettingsModel', 'countryList'];

    public disabled: boolean;
    public countryListComplete = false;
    public sizeModifier: string;
    public popoverModifier: string;
    public overviewLoad: (selection: any) => any;
    public placeholder: string;
    public countries: any[] = [];
    public model: any;
    public defaultOption = '';
    public required: boolean;

    constructor(
        private $timeout: ng.ITimeoutService,
        private userSettingsModel: UserSettingsModelService,
        private countryList: CountryListService
    ) {}

    public $onInit() {
        this.getRawList();
        this.defaultOption = [undefined, null, ''].indexOf(AuthContextService.user.language) >= 0
            ? UiLanguageDefaultSettingsConst.languageApiIso.toUpperCase()
            : AuthContextService.user.language;
        this.$timeout(() => {
            this.countryListComplete = true;
        });
    }

    public getRawList = () => {
        const countryList = this.countryList.getRawList();
        this.countries = [];
        for (const key of Object.keys(countryList)) {
            this.countries.push({
                name: countryList[key],
                value: key
            });
        }

        // Sort by the name of a country instead of its ISO-code.
        this.countries = this.countries.sort(
            (a, b) => a.name.localeCompare(b.name, 'de', { sensitivity: 'base' })
        );
    };
}

export class MoleculeFormDropDownCountriesComponent implements ng.IComponentOptions {
    public bindings = {
        disabled: '<',
        language: '<',
        model: '=',
        overviewLoad: '<',
        placeholder: '@',
        popoverModifier: '@',
        required: '<?',
        sizeModifier: '@?',
    };
    public controller =  MoleculeFormDropDownCountriesController;
    // tslint:disable-next-line: max-line-length
    public template = require('./drop-down-countries.html');
}
