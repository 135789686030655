import * as ng from 'angular';

export class NumberSeparation {
    public static $inject: string[] = [];

    public static Factory(
    ) {
        const numberSeparationFilter: any = (value: string) => {
            if ([undefined, null, ''].indexOf(value) >= 0) {
                return;
            }
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        };

        return numberSeparationFilter;
    }
}
