import ng from 'angular';
import q from 'q';
import {
    AuthContextService,
    DomainComposerService,
    VhostModelService,
    WebhostingHelperService
} from '@/services';
import { AccountApi, BundleApi, ResourceApi, ViewTypes, WebhostingApi } from '@/types';

import { DomainTypes } from '@/atomic-components/organs';
import { UiRights } from '@/configuration';

export class OrganismDomainWizardWrapperController {
    public static $inject: string[] = ['$timeout', 'domainComposer', 'vhostModel', 'webhostingHelper'];

    public account: AccountApi.Account;
    public bundle: BundleApi.Bundle;
    public managedServerWebspaceItems: WebhostingApi.Webspace[];
    public outerAccount: AccountApi.Account | AccountApi.Subaccount;
    public ownerAccount: AccountApi.Account | AccountApi.Subaccount;
    public webspace: WebhostingApi.Webspace;
    public webserverRessource: ResourceApi.Webserver;

    public wizardComposerObject: ViewTypes.WizardComposerObject = {
        apiObject: {},
        summaryObject: []
    };
    public viewType = 'config';

    constructor(
        private $timeout: ng.ITimeoutService,
        private domainComposer: DomainComposerService,
        private vhostModel: VhostModelService,
        private webhostingHelper: WebhostingHelperService
    ) {}

    public loadSummaryView = (metadata: ViewTypes.WizardProductsConfigDomain): void => { // used in template
        this.wizardComposerObject.summaryObject = [];
        void this._getPhpIniDefaults(metadata.webspace).then((phpIniDefaults) => {
            metadata.phpIniDefaults = phpIniDefaults;
            if (metadata.domainType === DomainTypes.REGISTER) {
                this._buildDomainApiObject(metadata as ViewTypes.ProductConfigDomainDomainObject);
            } else {
                void this._buildVhostApiObject(metadata as ViewTypes.ProductConfigDomainVhostObject);
            }
        });
    };

    public loadConfirmView = (): void => { // used in template
        this.viewType = 'confirm';
    };

    private _buildDomainApiObject = (metadata: ViewTypes.ProductConfigDomainDomainObject): void => {
        void this.domainComposer.buildDomainApiObject(metadata).then(
            (composerObject) => {
                if (composerObject) {
                    void this.$timeout(() => {
                        this.viewType = 'summary';
                        this.wizardComposerObject = composerObject;
                    });
                }
            }
        );
    };

    private _buildVhostApiObject = (
        metadata: ViewTypes.ProductConfigDomainVhostObject
    ): Promise<void|ViewTypes.WizardComposerObject> => {
        return this.domainComposer.buildVhostApiObject(metadata).then(
            (composerObject) => {
                void this.$timeout(() => {
                    this.wizardComposerObject = composerObject;
                    this.viewType = 'confirm';
                });
            }
        );
    };

    private _getPhpIniDefaults = async (webspace: WebhostingApi.Webspace): Promise<unknown>  => {
        if (webspace && AuthContextService.isGranted(UiRights.WEB_OBJECT_LIST)) {
            const phpVersion = await this.vhostModel.defaultPhpVersion();

            return this.webhostingHelper.getDefaultPhpIniSet(
                webspace.id,
                phpVersion,
                false
            );
        }

        return q.when(null);
    };
}

export class OrganismDomainWizardWrapperComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<?',
        managedServerWebspaceItems: '<?',
        ownerAccount: '<?',
        webspace: '<?',
        webserverRessource: '<?'
    };
    public template = require('./domain-wizard-wrapper.html');
    public controller = OrganismDomainWizardWrapperController;
}
