import * as ng from 'angular';
import { DateWrapper } from '../../../../../services';
import { ValidateOptions } from '../../../forms/form-elements';

export class MoleculePanelEditRowDateController implements ng.IController {
    public static $inject: string[] = [];

    public date: DateWrapper;
    public minYear: number;
    public maxYear: number;
    public validationOptions: ValidateOptions;
    public registerToForm: boolean;
    public validationErrorList: any[];
    public onChangeCallback: () => {};
    public callbackOnlyOnCompleteDate: boolean;
}

export class MoleculePanelEditRowDateComponent implements ng.IComponentOptions {
    public transclude = {
        label: 'rowLabel'
    };
    public bindings = {
        callbackOnlyOnCompleteDate: '<?',
        date: '=',
        maxYear: '@',
        minYear: '@',
        onChangeCallback: '<?',
        registerToForm: '<',
        validationErrorList: '=?',
        validationOptions: '<'
    };
    public controller = MoleculePanelEditRowDateController;
    public template = require('./panel-edit-row-date.html');
}
