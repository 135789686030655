import { ModelHelper } from '../model-helper';
import { DnsRobotService } from './robot';

import * as ng from 'angular';
import * as Types from '../../types';

export class DnsTemplateModelService {
    public static $inject: string[] = ['dnsRobot'];

    constructor(
        private dnsRobot: DnsRobotService
    ) {}

    public list = (
        limit?: number,
        page?: number,
        filters?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions,
        timeout?: number
    ) => {
        sort = sort || {
            field: 'templateName',
            order: 'ASC'
        };
        page = page || 1;

        return this.dnsRobot.listTemplates(filters, limit, page, sort, timeout).then(ModelHelper.returnListResponse);
    };

    public findOne = (id: string|number) => {
        return this.dnsRobot.listTemplatesWithoutPagination({ field: 'templateId', value: `${id}` }, 1, 1)
            .then(ModelHelper.returnFindOneResponse);
    };

    public findById = (ids: string[]) => {
        const filter: Types.Finding.Filter = { subFilterConnective: 'OR', subFilter: ids.map(this.idFilter) };

        return this.dnsRobot.listTemplatesWithoutPagination(filter, 0, 1).then((result) => result.response.data);
    };

    public findRecords = (templateId: string|number) => {
        return this.dnsRobot.listTemplateRecordsWithoutPagination(
            { field: 'templateId', value: `${templateId}` },
            0,
            1,
            { field: 'recordTemplateType', order: 'ASC' }
        )
        .then(
            (result) => {
                const value = result.response.data;

                for (const record of value) {
                    // Entferne .##DOMAIN##
                    if (record.name === '##DOMAIN##') {
                        record.name = '';
                    } else {
                        record.name = record.name.slice(0, -11);
                    }
                }

                return value;
            }
        );
    };

    public create = (template, records: Types.DnsApi.Record[], owner: string) => {
        records = this.appendRecords(records);

        return this.dnsRobot.createTemplate(template, records, owner);
    };

    public delete = (templates) => {
        const promises = templates.map(this.deleteOne);

        return Promise.all(promises);
    };

    public update = (template, records: Types.DnsApi.Record[], replacements) => {
        records = this.appendRecords(records);

        return this.dnsRobot.recreateTemplate(template, records, replacements);
    };

    /* THIS NAME IS WRONG!!! But update already exists. */
    public rename = (template) => {
        return this.dnsRobot.updateTemplate(template);
    };

    private idFilter = (id) => {
        return { field: 'templateId', value: id };
    };

    private appendRecords = (records: Types.DnsApi.Record[]) => {
        const innerRecords = ng.copy(records);

        for (const record of innerRecords) {
            if (record.name === '') {
                record.name = '##DOMAIN##';
            } else {
                record.name = record.name + '.##DOMAIN##';
            }
        }

        return innerRecords;
    };

    private deleteOne = (template) => {
        return this.dnsRobot.deleteTemplate(template.id);
    };
}
