import * as ng from 'angular';
import {
    AlertManagerService,
    AuthContextService,
    WebspaceModelService
} from '../../../../../services';

export class OrganismEditFormWebspaceAdminOptionsController {
    public static $inject: string[] = ['$translate', 'alertManager', 'webspaceModel'];

    public originalWebspace: any;
    public webspace: any = {};
    public userPanelRight: any;
    public storageProductInfo: any;
    public storagePriceInfo: any;
    public additionalStorageItems = 0;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private webspaceModel: WebspaceModelService
    ) {}

    public enable = () => {
        return this.webspaceModel.enableWebspace(this.webspace).then(
            (res) => {
                this.alertManager.success(this.$translate.instant('TR_110119-315292_TR'));

                return res;
            }
        );
    };

    public disable = (comment: string) => {
        return this.webspaceModel.disableWebspace(this.webspace, AuthContextService.account.id, comment).then(
            (res) => {
                this.alertManager.success(this.$translate.instant('TR_110119-529d9b_TR'));

                return res;
            }
        );
    };

    public update = (comment) => {
        const restriction = this.webspace.restrictions
        .filter((innerRestriction) => innerRestriction.type === 'disabled')[0];

        restriction.internalComment = comment;
        return this.webspaceModel.webspaceUpdateRestriction(this.webspace.id, restriction).then(
            (res) => {
                this.alertManager.success(this.$translate.instant('TR_110119-18b847_TR'));

                return res;
            }
        );
    };

    public $onInit = () => {
        this.additionalStorageItems = 0;
        this.webspace = ng.copy(this.originalWebspace);
    };

}

export class OrganismEditFormWebspaceAdminOptionsComponent implements ng.IComponentOptions {
    public bindings = {
        originalWebspace: '<webspace',
        userPanelRight: '='
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormWebspaceAdminOptionsController;
    public template = require('./webspace-admin-options.html');
}
