import * as ng from 'angular';
import { ValidateMax, ValidateMin, ValidateNotEmpty, ValidateNumber } from '../../../../../services';
import { InputOptionsType } from '../../../../../types/input-types';
import { FormInputModelOptions } from '../../../../atoms/form-elements';

export class MoleculeInputTextNumberController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public validationErrorList = [];
    public value: string;
    public placeholder: string;
    public callbackOnChange: (arg0: any) => any;
    public callbackOnBlur: (arg0: any) => any;
    public callbackOnBlurParams: any;
    public disabled: boolean;
    public disableRandomisedId: boolean;
    public modelOptions: FormInputModelOptions;
    public registerToForm: boolean;
    public options: InputOptionsType;
    public validationInstructions: any[];
    public inputId: string;
    public callbackOnClear: (arg0: any) => any;
    public callbackOnClearParams: any;
    public clearable: boolean;
    public catchFocus: boolean;
    public step: string;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.validationInstructions = [];
        if (this.options !== undefined && this.options !== null) {
            if (this.options.allowEmpty !== undefined && !this.options.allowEmpty) {
                this.validationInstructions.push({
                    instructions: null,
                    validator: new ValidateNotEmpty(this.$translate)
                });
            }
        }
        let allowComma = false;
        let requireInteger = false;
        let minPrecision = null;
        let maxPrecision = null;
        if (this.options !== undefined && this.options !== null) {
            if (this.options.allowComma !== undefined && this.options.allowComma !== null) {
                allowComma = this.options.allowComma;
            }
            if (this.options.requireInteger !== undefined && this.options.requireInteger !== null) {
                requireInteger = this.options.requireInteger;
            }
            if (this.options.minPrecision !== undefined) {
                minPrecision = this.options.minPrecision;
            }
            if (this.options.maxPrecision !== undefined) {
                maxPrecision = this.options.maxPrecision;
            }
        }

        this.validationInstructions.push({
            instructions: null,
            validator: new ValidateNumber(this.$translate, allowComma, requireInteger, minPrecision, maxPrecision)
        });

        if ([undefined, null].indexOf(this.options) < 0) {
            if ([undefined, null].indexOf(this.options.maximum) < 0) {
                this.validationInstructions.push({
                    instructions: null,
                    validator: new ValidateMax(this.$translate, this.options.maximum)
                });
            }

            if ([undefined, null].indexOf(this.options.minimum) < 0) {
                this.validationInstructions.push({
                    instructions: null,
                    validator: new ValidateMin(this.$translate, this.options.minimum)
                });
            }
        }
    }

    public onPaste = (paste) => {
        // implement callback on paste here when needed!
    };
}

export class MoleculeInputTextNumberComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnBlur: '<',
        callbackOnBlurParams: '<',
        callbackOnChange: '<',
        callbackOnClear: '<',
        callbackOnClearParams: '<',
        catchFocus: '<?',
        clearable: '<',
        disabled: '<',
        disableRandomisedId: '<?',
        inputId: '@?',
        modelOptions: '<',
        options: '<',
        placeholder: '@',
        registerToForm: '<',
        step: '@?',
        validationErrorList: '=?',
        value: '='
    };
    public controller = MoleculeInputTextNumberController;
    public template = require('./input-text-with-validation-wrapper.html');
}
