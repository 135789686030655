import * as ng from 'angular';
import * as q from 'q';

import { UIRegExp, UiRights } from '../../../../configuration';
import {
    AccountModelService, AuthContextService, BillingRobotService, NavigationService
} from '../../../../services';
import * as Types from '../../../../types';
import {
    EditFormMessage, EditFormSavingStatus, EditFormStatus, EditPanelStatus,
    MoleculeFormEditController, PanelType
} from '../../forms';

type Validator = () => true|any;

export class MoleculePanelDeleteSubaccountController implements ng.IController {
    public static $inject: string[] = [
        '$state',
        '$translate',
        'accountModel',
        'authContext',
        'billingRobot',
        'navigation'
    ];

    // Bindings
    public name = 'deleteOwnAccount';
    public panelIcon: string;
    public agreedToDeleteAccount = false;
    public initialStatus: EditPanelStatus;
    public deleteButtonText: string = this.$translate.instant('GENERAL.FORM.DELETE');
    public panelTitle: string = this.$translate.instant('TR_240119-1ac314_TR');
    public readonly type = PanelType.MISCELLANEOUS_ACTION;

    // Required parent controller
    public $editForm: MoleculeFormEditController;
    public canCheckRenewableObjects = false;
    public hasRenewableObjects: boolean;
    public hasSubaccounts: boolean;

    public $validators: { [key: string]: Validator } = {};

    private status: EditPanelStatus = EditPanelStatus.READONLY;

    private account: Types.AccountApi.Subaccount;

    constructor(
        $state: ng.ui.IStateService,
        private $translate,
        private accountModel: AccountModelService,
        private authContext: AuthContextService,
        billingRobot: BillingRobotService,
        private navigation: NavigationService
    ) {
        this.account = $state.$current.locals.globals.account;

        this.canCheckRenewableObjects = this.authContext.isGranted(UiRights.BIL_LIST_RENEWABLE_OBJECTS);

        if (this.canCheckRenewableObjects) {
            billingRobot.findRenewableObjects({ field: 'AccountId', value: this.account.id }, 1)
            .then(
                (result) => this.hasRenewableObjects = [undefined, null].indexOf(result.response) < 0
                && [undefined, null].indexOf(result.response.data) < 0
                && result.response.data.length > 0
            );
        }

        if (this.authContext.isGranted(UiRights.ACC_SUBACCOUNT_LIST)) {
            accountModel.listSubaccountsWithoutPagination(1, 1, { field: 'AccountId', value: this.account.id })
            .then(
                (result) => this.hasSubaccounts = result.data.length > 0
            );
        }
    }

    public get showPanel() {
        return !(
                UIRegExp.IsNumeric.test('' + this.account.id)
                && parseInt(this.account.id, 10) < 1000
            )
            && this.authContext.isGranted(UiRights.ACC_SUBACCOUNT_DELETE);
    }

    public get panelDisabled() {
        return !this.canCheckRenewableObjects
        || this.hasRenewableObjects
        || this.hasSubaccounts;
    }

    public $onInit = () => {
        this.$editForm.register(this);

        if ([undefined, null].indexOf(this.initialStatus) < 0) {
            this.status = this.initialStatus;
        }
    };

    public $onDestroy = () => {
        this.$editForm.unregister(this);
    };

    public onMessage = (message: EditFormMessage<any>) => {
        switch (message.type) {
            default: return;

            case 'statusUpdate':
                switch (message.message) {
                    default: return;

                    case EditFormSavingStatus.ERROR:
                        if (this.status !== EditPanelStatus.READONLY) {
                            this.status = EditPanelStatus.ACTIVE;
                        }

                        return;

                    case EditFormSavingStatus.READY:
                        this.status = EditPanelStatus.READONLY;
                        return;
                }
        }
    };

    public get deleteButtonModifier() {
        return this.agreedToDeleteAccount ? 'active' : 'inactive';
    }

    public get $valid() {
        return Object.keys(this.$validators)
        .every((key) => this.$validators[key]() === true);
    }

    public get $invalid() {
        return Object.keys(this.$validators)
        .some((key) => this.$validators[key]() !== true);
    }

    public get $errors() {
        const errors = {};
        for (const key of Object.keys(this.$validators)) {
            const result = this.$validators[key]();
            if (result !== true) {
                errors[key] = result;
            }
        }
        return errors;
    }

    public activate = () => {
        this.status = EditPanelStatus.ACTIVE;
    };

    public delete = () => {
        if (this.$editForm.$invalid) {
            return;
        }

        this.$editForm.processApiCallback(
            () => this.accountModel.deleteSubaccount(this.account.id)
            .then(
                (res) => {
                    if (res.status !== 'error') {
                        this.navigation.go('reseller.subaccounts.overview');
                    } else {
                        return q.reject();
                    }
                },
                () => {
                    // Also redirect to overview on error.
                    return q.reject();
                }
            )
        );
    };

    public cancel = () => {
        this.status = EditPanelStatus.READONLY;
        this.agreedToDeleteAccount = false;
    };

    public cancelDeletion = () => {
        this.status = EditPanelStatus.READONLY;
        this.$editForm.cancelDeletion();
    };

    public successfullySaved = () => {
        this.status = EditPanelStatus.READONLY;
    };

    public errorWhileSaving = () => {
        this.status = EditPanelStatus.ACTIVE;
    };

    public get isEditable() {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showCancelButton() {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showEditButton() {
        return [
            EditPanelStatus.READONLY
        ].indexOf(this.status) >= 0;
    }

    public get showSaveButton() {
        if (EditPanelStatus.DELETIONSCHEDULED === this.status) {
            return false;
        }
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showEditableContent() {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showReadonlyContent() {
        return [
            EditPanelStatus.READONLY
        ].indexOf(this.status) >= 0;
    }

    public get showSavingContent() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get saveButtonLoading() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get disableCancelButton() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get disableEditButton() {
        return !this.$editForm.canEnablePanel(this.name);
    }

    public get disableSaveButton() {
        return this.$editForm.$invalid
            || (this.$editForm.$status === EditFormStatus.SAVING)
            || (this.agreedToDeleteAccount !== true);
    }

    public get disableInputElements() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }
}

export class MoleculePanelDeleteSubaccountComponent implements ng.IComponentOptions {
    public bindings = {
        initialStatus: '<'
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public controller =  MoleculePanelDeleteSubaccountController;
    public controllerAs = '$deletePanel';
    public template = require('./panel-delete-subaccount.html');
}
