import * as ng from 'angular';
import { UiLanguagesConst } from '../../../configuration/system';
import { GetEntryPointService } from '../../../services/get-entry-point';

import './see-you-soon.scss';

export class TemplateSeeYouSoonController implements ng.IController {
    public static $inject: string[] = ['$translate', 'getEntryPoint'];

    public logo: string;
    public company: string;

    public transl = {
        loginAgain: '',
        logoutSuccess: '',
        seeYouSoon: '',
        thankYou: ''
    };

    constructor(
        private $translate: ng.translate.ITranslateService,
        private getEntryPoint: GetEntryPointService
    ) {}

    public $onInit() {
        this.logo = this.getEntryPoint.logoPath;
        this.company = this.getEntryPoint.name;

        let lang: string = UiLanguagesConst[new URL(document.URL).searchParams.get('lang')];
        lang = (lang === undefined) ? UiLanguagesConst.en : lang;

        this.$translate.use(lang);

        this.transl = {
            loginAgain: this.$translate.instant('TR_190819-f759cc_TR'),
            logoutSuccess: this.$translate.instant('TR_190819-6e07cf_TR'),
            seeYouSoon: this.$translate.instant('TR_190819-404fb3_TR'),
            thankYou: this.$translate.instant('TR_190819-113ce0_TR', { comp: this.company })
        };
    }
}

export class TemplateSeeYouSoonComponent implements ng.IComponentOptions {
    public template = require('./see-you-soon.html');
    public controller = TemplateSeeYouSoonController;
}
