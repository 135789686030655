import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';

export class TemplateCronjobEditController {
    public static $inject: string[] = ['$state', '$translate', 'authContext'];

    public modifier = '';
    public pageHeaderData: PanelHeaderData;

    public cronjob;

    public userPanelRight;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit = () => {
        const cronjob = this.cronjob;

        const type = {
            bash: this.$translate.instant('TR_140119-da0704_TR'),
            php: this.$translate.instant('WEBHOSTING.CRON.OPTION.TYPE.PHP'),
            url: this.$translate.instant('WEBHOSTING.CRON.OPTION.TYPE.URL')
        }[cronjob.type];

        const target = {
            bash: cronjob.script,
            php: cronjob.script,
            url: cronjob.url
        }[cronjob.type];

        const bundle = this.$state.$current.locals.globals.bundle;
        const webspace = this.$state.$current.locals.globals.webspace;

        this.pageHeaderData = {
            backwardLink: (bundle && bundle.id)
                ? 'bundle.id.cronjobs.overview'
                : 'webhosting.webspaces.id.cronjobs.overview',
            backwardText: this.$translate.instant('TR_140119-fff82f_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: (bundle && bundle.id)
                ? {bundleId: bundle.id}
                : {webspaceId: webspace.id},
            panelIcon: 'calendar-alt',
            panelTitle: `${type}: ${target}`
        };

        this.userPanelRight = {
            general: {
                editPanelButton: this.authContext.isGranted(UiRights.WEB_WEBSPACE_EDIT)
            }
        };
    };
}

export class TemplateCronjobEditComponent implements ng.IComponentOptions {
    public bindings = {
        cronjob: '<'
    };
    public controller = TemplateCronjobEditController;
    public controllerAs = '$TemplateCronjobEditCtrl';
    public template = require('./cronjob-edit-template.html');
}
