import * as ng from 'angular';
import {
    OrganismEditFormMachineController,
} from '../../../../../atomic-components';
import {
    AlertManagerService,
    MachineModelService,
} from '../../../../../services';
import * as Types from '../../../../../types';
import {
    EditPanelStatus,
    MoleculeFormEditController,
    PanelType,
} from '../../../../molecules';

export class OrganEditPanelMachineRescueController {
    public static $inject: string[] = ['$translate', 'alertManager', 'machineModel'];

    public $editFormMolecule: MoleculeFormEditController;
    public $editFormOrganism: OrganismEditFormMachineController;
    public panelRight: any;
    public panelTypeOverride = PanelType.MISCELLANEOUS_ACTION;
    public reset = false;
    public machine: Types.MachineApi.VirtualMachine;
    public checkingMachineStatus: boolean;
    public listOrder: Types.ViewTypes.ListOrder = Types.ViewTypes.ListOrder.NewItemLast;
    public errorMessage: string;

    private validatorId: number;
    private _password: string;
    private keys: string[] = [];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private machineModel: MachineModelService,
    ) {}

    // tslint:disable-next-line:no-empty
    public cancelCallback: () => void = () => {};

    public $onInit() {
        this.rescueModeActive = this.$editFormOrganism.machine.rescue === 'on';
        this.validatorId = this.$editFormMolecule.registerValidator(this as any);
    }

    public $onDestroy() {
        this.$editFormMolecule.unregisterValidator(this.validatorId);
    }

    public get rescueModeActive() {
        return this.$editFormOrganism.machine.rescue === 'on';
    }

    public set rescueModeActive(value) {
        if (
            [null, undefined].indexOf(this.$editFormOrganism.machine) < 0
            && this.$editFormOrganism.machine.rescue !== (value === true ? 'on' : 'off')
        ) {
            this.$editFormOrganism.machine.rescue = (value === true ? 'on' : 'off');
        }
        this.$editFormMolecule.validateAll();
    }

    public get password() {
        return this._password;
    }

    public set password(value) {
        this._password = value;

        this.$editFormMolecule.validateAll();
    }

    public validate = () => {
        if ([undefined, null].indexOf(this.$editFormMolecule.panel('rescue')) < 0
            && this.$editFormMolecule.panel('rescue').status !== EditPanelStatus.ACTIVE) {
            return true;
        }

        if (this.rescueModeActive) {
            return [undefined, null, ''].indexOf(this.password) < 0;
        } else {
            return true;
        }
    };

    public saveRescueMode = () => {
        if (this.rescueModeActive) {
            this.enableRescueMode();
        } else {
            this.disableRescueMode();
        }
    };

    public enableRescueMode = () => {
        this.machineModel.enableRescue(
            this.$editFormOrganism.machine.id,
            this.password,
            this.keys,
            this.reset,
        ).then((apiResponse) => {
            if (apiResponse.response) {
                this.alertManager.success(this.$translate.instant('TR_050219-554bab_TR'));
            }
            this.$editFormOrganism.updateMachineSettings();
        });
    };

    public disableRescueMode = () => {
        this.machineModel.disableRescue(
            this.$editFormOrganism.machine.id,
            this.reset,
        ).then((apiResponse) => {
            if (apiResponse.response) {
                this.alertManager.success(this.$translate.instant('TR_050219-55eb94_TR'));
            }
            this.$editFormOrganism.updateMachineSettings();
        });
    };

    public cancel = () => {
        this.keys = [];
        this.reset = false;
        this.password = '';
        if ([undefined, null].indexOf(this.cancelCallback) === -1) {
            this.cancelCallback();
        }
    };

    public addOnPaste = (pasteEvent: ClipboardEvent): { entryList: string[], validationError: string } => {
        this.errorMessage = '';

        const clipboardData: string = pasteEvent.clipboardData.getData('text/plain');
        const fieldData = (pasteEvent.target as HTMLInputElement).value;

        if (fieldData != '') {
            return { entryList: [], validationError: '' };
        }

        const validatedKeys = this.getItemsToAdd(clipboardData);
        if (validatedKeys.entryList.length > 1 && validatedKeys.validationError === '') {
            pasteEvent.preventDefault();
            return validatedKeys;
        }
        return { entryList: [], validationError: ''};
    }

    public callbackOnBlur = (input: string) => {
        const splitEntries = this._splitIntoKeys(input).map((key) => this._splitIntoParts(key));
        const validatedKeys = this._validateKeys(splitEntries);
        if (validatedKeys.validationError === '') {
            this.errorMessage = '';
        }
    }

    public getItemsToAdd = (entryString: string): { entryList: string[], validationError: string } => {
        const splitEntries = this._splitIntoKeys(entryString).map((key) => this._splitIntoParts(key));
        const validatedKeys = this._validateKeys(splitEntries);

        if (validatedKeys.validationError != '') {
            this.errorMessage = validatedKeys.validationError;
            return { entryList: [], validationError: validatedKeys.validationError };
        } else {
            return validatedKeys;
        }
    }

    public formatItem = (value: string): string => {
        let formattedItemString: string = '';
        const itemValueArray = value.split(' ');
        if (itemValueArray[1].length > 10) {
            formattedItemString =
                itemValueArray[0] + ' '
                + itemValueArray[1].slice(0, 5) + ' ... '
                + itemValueArray[1].slice(-5) + ' '
                + itemValueArray.slice(2).join(' ');
        } else {
            formattedItemString = value;
        }

        return formattedItemString;
    }

    private _validateKeys = (entryList: string[]): { entryList: string[], validationError: string } => {
        let validationError = '';
        if (!entryList.every((entry) => entry.split(' ').length >= 2)) {
            validationError = this.$translate.instant('TR_050624-f89b86_TR');
        }
        const duplicatesFound: boolean = Array.from(new Set(entryList)).length != entryList.length;
        const intersect: string[] = entryList.filter((entry) => this.keys.includes(entry));
        if (duplicatesFound || intersect.length > 0) {
            validationError = this.$translate.instant('TR_040624-41f273_TR');
        }

        return { entryList, validationError }
    }

    private _splitIntoKeys = (text: string): string[] => {
        return text
            .split(/[\r\n]+/)
            .map((entry) => entry.trim())
            .filter((entry) => entry != '');
    }

    private _splitIntoParts = (key: string): string => {
        return key
            .split(' ')
            .map((part) => part.trim())
            .filter((entry) => entry != '')
            .join(' ');
    }
}

export class OrganEditPanelMachineRescueComponent implements ng.IComponentOptions {
    public bindings = {
        cancelCallback: '<cancel',
        checkingMachineStatus: '<',
        panelRight: '<',
    };
    public require = {
        $editFormMolecule: '^moleculeFormEdit',
        $editFormOrganism: '^organismEditFormMachine',
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./machine-rescue.html');
    public controller = OrganEditPanelMachineRescueController;
}
