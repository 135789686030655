import * as ng from 'angular';

import merge from 'lodash.merge';

import { Contingent } from '@/atomic-components';
import { FormatMegabyte } from '@/filters';
import {
    AuthContextService, MailboxModelService, PriceModelService
} from '../';
import {
    ExternalForwarderProductFamily,
    InternalForwarderProductCode,
    InternalForwarderProductFamily,
    SpamProfiles,
    UiLanguagesConst
} from '../../configuration';
import * as Types from '../../types';
import { EmailApi, Finding } from '../../types';
import { ViewTypes } from '../../types/view-types';
import { ProductsModelService } from './../products/products-model';

export interface IReqeustSchema {
    response: {
        name: string;
        shortDescription: string;
    };
    errors: any[];
    warnings: any[];
}

export class EmailComposerService {
    public static $inject: string[] = [
        '$translate',
        'formatMegabyteFilter',
        'mailboxModel',
        'productsModel'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private formatMegabyteFilter: FormatMegabyte | any,
        private mailboxModel: MailboxModelService,
        private productsModel: ProductsModelService
    ) {}

    // JEDE PRODUKTGRUPPE BEKOMMT EINE EIGENE BUILD-FUNKTION
    // Siehe `machine`-composer (app/services/machine/machine-composer.ts)

    public buildEmailImapApiObject = async (metadata: ViewTypes.ProductConfigEmailObject) => {
        const mergedSpamProfile = this._mergeSpamObject(metadata.spamProfile);

        let productFamilyInfo = await this.productsModel
            .findProductFamilyDescription(
                'email',
                metadata.productFamily,
                UiLanguagesConst[AuthContextService.user.language]
        );
        productFamilyInfo = productFamilyInfo.response;

        const productCodeInfo = await this.productsModel
            .findOne(
                metadata.productCode,
                UiLanguagesConst[AuthContextService.user.language],
                true
            )
            .then((res) => res.response);

        const spamProtectionLevels = {
            low: {
                label: this.$translate.instant('EMAIL.SPAM.LEVEL.LOW')
            },
            medium: {
                label: this.$translate.instant('EMAIL.SPAM.LEVEL.MEDIUM')
            },
            high: {
                label: this.$translate.instant('EMAIL.SPAM.LEVEL.HIGH')
            }
        };

        const composerObject: Types.ViewTypes.WizardComposerObject = {
            productFamily: metadata.productFamily,
            apiObject: {
                autoconfigureDns: metadata.autoconfigureDns,
                mailbox: {
                    accountId: metadata.account.id,
                    emailAddress: metadata.mailbox.emailAddress,
                    forwarderTargets: metadata.mailbox.forwarderTargets,
                    isAdmin: metadata.isAdmin,
                    productCode: metadata.productCode,
                    spamFilter: mergedSpamProfile,
                    storageQuota: metadata.storageQuota,
                    type: 'ImapMailbox'
                },
                password: metadata.password,
                skipDnsCheck: metadata.skipDnsCheck
            },
            summaryObject: [
                { // placeholder
                    account: metadata.account,
                    productCode: metadata.productCode,
                    factSheets: [
                        {
                            label: this.$translate.instant('TR_070519-16189c_TR'),
                            value: productFamilyInfo.name,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('TR_110219-fc0ae3_TR'),
                            value: productCodeInfo.name,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('TR_110119-631fe6_TR'),
                            value: metadata.mailbox.emailAddress,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('TR_110119-53b84a_TR'),
                            value: spamProtectionLevels[mergedSpamProfile.spamLevel].label,
                            description: null,
                            type: 'string'
                        }
                    ],
                    product: {
                        name: metadata.productCode,
                        nameType: 'productCode',
                        shortDescription: productFamilyInfo.shortDescription
                    }
                }
            ]
        };

        if (metadata.mailbox.forwarderTargets?.length > 0) {
            composerObject.summaryObject[0].factSheets.push(
                {
                    label: this.$translate.instant('544258f5-cc97-47bd-a699-88949d0439a7'),
                    value: '',
                    description: metadata.mailbox.forwarderTargets,
                    type: 'stringArray'
                });
        }

        return Promise.resolve(
            this.additionalStorageSummary(
                this._addContingent(composerObject, metadata.contingent),
                metadata
            )
        );
    };

    public buildEmailCatchallApiObject = async (metadata: ViewTypes.ProductConfigCatchallObject) => {
        const mergedSpamProfile = this._mergeSpamObject(metadata.spamProfile);

        const productFamilyInfo = await (this.productsModel
            .findProductFamilyDescription(
                'email',
                metadata.productFamily,
                UiLanguagesConst[AuthContextService.user.language]
            ) as Promise<any>)
            .then((res: IReqeustSchema) => res.response);

        const productCodeInfo = await this.productsModel
            .findOne(
                metadata.productCode,
                UiLanguagesConst[AuthContextService.user.language],
                true
            )
            .then((res) => res.response);

        const spamProtectionLevels = {
            low: {
                label: this.$translate.instant('EMAIL.SPAM.LEVEL.LOW')
            },
            medium: {
                label: this.$translate.instant('EMAIL.SPAM.LEVEL.MEDIUM')
            },
            high: {
                label: this.$translate.instant('EMAIL.SPAM.LEVEL.HIGH')
            }
        };
        const emailAddress = `*@${metadata.mailbox.emailAddress}`;

        const composerObject: Types.ViewTypes.WizardComposerObject = {
            productFamily: metadata.productFamily,
            apiObject: {
                autoconfigureDns: metadata.autoconfigureDns,
                mailbox: {
                    accountId: metadata.account.id,
                    emailAddress: emailAddress,
                    forwarderTarget: metadata.mailbox.forwarderTarget,
                    productCode: metadata.productCode,
                    spamFilter: mergedSpamProfile,
                    type: 'CatchAll'
                },
                skipDnsCheck: metadata.skipDnsCheck
            },
            summaryObject: [
                { // placeholder
                    account: metadata.account,
                    productCode: metadata.productCode,
                    factSheets: [
                        {
                            label: this.$translate.instant('TR_070519-16189c_TR'),
                            value: productFamilyInfo.name,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('TR_110219-fc0ae3_TR'),
                            value: productCodeInfo.name,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('TR_110119-631fe6_TR'),
                            value: emailAddress,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('abb61689-d9e2-4ad9-a40d-8d7eeb18f078'),
                            value: metadata.mailbox.forwarderTarget,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('TR_110119-53b84a_TR'),
                            value: spamProtectionLevels[mergedSpamProfile.spamLevel].label,
                            description: null,
                            type: 'string'
                        }
                    ],
                    product: {
                        name: metadata.productCode,
                        nameType: 'productCode',
                        shortDescription: productFamilyInfo.shortDescription
                    }
                }
            ]
        };
        return Promise.resolve(this._addContingent(composerObject, metadata.contingent));
    };

    public buildEmailMailingListApiObject = async (metadata: ViewTypes.ProductConfigMailingListObject) => {
        const mergedSpamProfile = this._mergeSpamObject(metadata.mailbox.spamFilter);

        let productFamilyInfo = await this.productsModel
            .findProductFamilyDescription(
                'email',
                metadata.productFamily,
                UiLanguagesConst[AuthContextService.user.language]
        );
        productFamilyInfo = productFamilyInfo.response;

        const productCodeInfo = await this.productsModel
            .findOne(
                metadata.productCode,
                UiLanguagesConst[AuthContextService.user.language],
                true
            )
            .then((res) => res.response);

        const spamProtectionLevels = {
            low: {
                label: this.$translate.instant('EMAIL.SPAM.LEVEL.LOW')
            },
            medium: {
                label: this.$translate.instant('EMAIL.SPAM.LEVEL.MEDIUM')
            },
            high: {
                label: this.$translate.instant('EMAIL.SPAM.LEVEL.HIGH')
            }
        };
        const accessModeTranslation = {
            owner: {
                name: this.$translate.instant('e23e4e3f-030f-4e2c-b826-ca73686abb06')
            },
            members: {
                name: this.$translate.instant('69e776dd-a8bd-44fe-88f9-aa135410f470')
            },
            everyone: {
                name: this.$translate.instant('TR_110119-b212cd_TR')
            }
        };
        const replyToModeTranslation = {
            list: {
                name: this.$translate.instant('TR_110119-7b3b04_TR')
            },
            self: {
                name: this.$translate.instant('TR_110119-595d94_TR')
            },
            specifiedAddress: {
                name: this.$translate.instant('TR_110119-725707_TR')
            }
        };
        const allowHtmlTranslation = {
            true: {
                name: this.$translate.instant('28687262-290b-4cf8-9ddf-025808ca7134')
            },
            false: {
                name: this.$translate.instant('f20dbc67-c49c-4477-b36c-984b27119328')
            }
        };

        const composerObject: Types.ViewTypes.WizardComposerObject = {
            productFamily: metadata.productFamily,
            apiObject: {
                accountId: metadata.account.id,
                autoconfigureDns: metadata.autoconfigureDns,
                mailbox: {
                    accessMode: metadata.mailbox.accessMode,
                    allowHtmlMails: metadata.mailbox.allowHtmlMails,
                    digestSize: metadata.mailbox.digestSize,
                    emailAddress: metadata.mailbox.emailAddress,
                    members: metadata.mailbox.members,
                    name: metadata.mailbox.name,
                    owners: metadata.mailbox.owners,
                    productCode: metadata.productCode,
                    replyToEmailAddress: metadata.mailbox.replyToEmailAddress,
                    replyToMode: metadata.mailbox.replyToMode,
                    spamFilter: mergedSpamProfile,
                    subjectPrefix: metadata.mailbox.subjectPrefix,
                    type: 'MailingList'
                },
                skipDnsCheck: metadata.skipDnsCheck
            },
            summaryObject: [
                { // placeholder
                    account: metadata.account,
                    productCode: metadata.productCode,
                    factSheets: [
                        {
                            label: this.$translate.instant('TR_070519-16189c_TR'),
                            value: productFamilyInfo.name,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('TR_110219-fc0ae3_TR'),
                            value: productCodeInfo.name,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('TR_110119-631fe6_TR'),
                            value: metadata.mailbox.emailAddress,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('87ed06e9-04bc-4152-b0ea-41f6b0a3c6da'),
                            value: metadata.mailbox.subjectPrefix,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('8996d4f9-41e3-4f86-8781-a01e6b4114be'),
                            value: accessModeTranslation[metadata.mailbox.accessMode].name,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('3c74151b-b242-426d-8c00-6711f0375391'),
                            value: replyToModeTranslation[metadata.mailbox.replyToMode].name,
                            description:
                                metadata.mailbox.replyToMode === 'specifiedAddress'
                                    ? metadata.mailbox.replyToEmailAddress : null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('2e66cba6-b5a6-414f-a0fa-61b807be6bc7'),
                            value: allowHtmlTranslation[String(!!metadata.mailbox.allowHtmlMails)].name,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('e23e4e3f-030f-4e2c-b826-ca73686abb06'),
                            value: '',
                            description: metadata.mailbox.owners,
                            type: 'stringArray'
                        },
                        {
                            label: this.$translate.instant('69e776dd-a8bd-44fe-88f9-aa135410f470'),
                            value: '',
                            description: metadata.mailbox.members,
                            type: 'stringArray'
                        },
                        {
                            label: this.$translate.instant('TR_110119-53b84a_TR'),
                            value: spamProtectionLevels[mergedSpamProfile.spamLevel].label,
                            description: null,
                            type: 'string'
                        }
                    ],
                    product: {
                        name: metadata.productCode,
                        nameType: 'productCode',
                        shortDescription: productFamilyInfo.shortDescription
                    }
                }
            ]
        };

        return Promise.resolve(this._addContingent(composerObject, metadata.contingent));
    };

    public buildEmailSmtpForwarderApiObject = async (metadata: ViewTypes.ProductConfigSmtpForwarderObject) => {
        const mergedSpamProfile = this._mergeSpamObject({ spamLevel: 'medium' });

        let productFamilyInfo = await this.productsModel
            .findProductFamilyDescription(
                'email',
                metadata.productFamily,
                UiLanguagesConst[AuthContextService.user.language]
        );
        productFamilyInfo = productFamilyInfo.response;

        const productCodeInfo = await this.productsModel
            .findOne(
                metadata.productCode,
                UiLanguagesConst[AuthContextService.user.language],
                true
            )
            .then((res) => res.response);

        const portInt = parseInt(String(metadata.mailbox.forwarderPort), 10);
        const composerObjectPort = isNaN(portInt) ? 25 : portInt;

        const composerObject: Types.ViewTypes.WizardComposerObject = {
            productFamily: metadata.productFamily,
            apiObject: {
                accountId: metadata.account.id,
                autoconfigureDns: metadata.autoconfigureDns,
                mailbox: {
                    emailAddress: metadata.mailbox.emailAddress,
                    server: metadata.mailbox.forwarderServer,
                    port: composerObjectPort,
                    productCode: metadata.productCode,
                    spamFilter: mergedSpamProfile,
                    type: 'SmtpForwarder'
                } as EmailApi.SmtpForwarder,
                skipDnsCheck: metadata.skipDnsCheck
            },
            summaryObject: [
                { // placeholder
                    account: metadata.account,
                    productCode: metadata.productCode,
                    factSheets: [
                        {
                            label: this.$translate.instant('TR_070519-16189c_TR'),
                            value: productFamilyInfo.name,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('TR_110219-fc0ae3_TR'),
                            value: productCodeInfo.name,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('TR_110119-631fe6_TR'),
                            value: metadata.mailbox.emailAddress,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('TR_210119-d6a6a2_TR'),
                            value: metadata.mailbox.forwarderServer,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('795b4768-b0b2-4337-a12e-11b92d4f2ea4'),
                            value: composerObjectPort,
                            description: null,
                            type: 'string'
                        }
                    ],
                    product: {
                        name: metadata.productCode,
                        nameType: 'productCode',
                        shortDescription: productFamilyInfo.shortDescription
                    }
                }
            ]
        };

        return Promise.resolve(this._addContingent(composerObject, metadata.contingent));
    };

    public buildEmailExternalForwarderApiObject = async (metadata: ViewTypes.ProductConfigExternalForwarderObject) => {
        const mergedSpamProfile = this._mergeSpamObject(metadata.spamProfile);

        const { productFamily, productCode } = await this._forwarderProductCode(metadata);

        const productFamilyInfo = await (this.productsModel
            .findProductFamilyDescription(
                'email',
                productFamily,
                UiLanguagesConst[AuthContextService.user.language]
            ) as Promise<any>)
            .then((res) => {
                return res.response;
            });

        const productCodeInfo = await this.productsModel
            .findOne(
                productCode,
                UiLanguagesConst[AuthContextService.user.language],
                true
            )
            .then((res) => res.response);

        const spamProtectionLevels = {
            low: {
                label: this.$translate.instant('EMAIL.SPAM.LEVEL.LOW')
            },
            medium: {
                label: this.$translate.instant('EMAIL.SPAM.LEVEL.MEDIUM')
            },
            high: {
                label: this.$translate.instant('EMAIL.SPAM.LEVEL.HIGH')
            }
        };

        const composerObject: Types.ViewTypes.WizardComposerObject = {
            productFamily: productFamily,
            apiObject: {
                autoconfigureDns: metadata.autoconfigureDns,
                mailbox: {
                    accountId: metadata.account.id,
                    emailAddress: metadata.mailbox.emailAddress,
                    forwarderTargets: metadata.mailbox.forwarderTargets,
                    productCode: productCode,
                    spamFilter: mergedSpamProfile,
                    type: 'Forwarder'
                },
                skipDnsCheck: metadata.skipDnsCheck
            },
            summaryObject: [
                { // placeholder
                    account: metadata.account,
                    productCode: productCode,
                    factSheets: [
                        {
                            label: this.$translate.instant('TR_070519-16189c_TR'),
                            value: productFamilyInfo.name,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('TR_110219-fc0ae3_TR'),
                            value: productCodeInfo.name,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('TR_110119-631fe6_TR'),
                            value: metadata.mailbox.emailAddress,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('544258f5-cc97-47bd-a699-88949d0439a7'),
                            value: '',
                            description: metadata.mailbox.forwarderTargets,
                            type: 'stringArray'
                        },
                        {
                            label: this.$translate.instant('TR_110119-53b84a_TR'),
                            value: spamProtectionLevels[mergedSpamProfile.spamLevel].label,
                            description: null,
                            type: 'string'
                        }
                    ],
                    product: {
                        name: productCode,
                        nameType: 'productCode',
                        shortDescription: productFamilyInfo.shortDescription
                    }
                }
            ]
        };

        return Promise.resolve(this._addContingent(composerObject, metadata.contingent));
    };

    public buildEmailExchangeMailboxApiObject = async (metadata: ViewTypes.ProductConfigExchangeMailboxObject) => {
        const mergedSpamProfile = this._mergeSpamObject(metadata.mailbox.spamFilter);

        const productFamilyInfo = await (this.productsModel
            .findProductFamilyDescription(
                'email',
                metadata.productFamily,
                UiLanguagesConst[AuthContextService.user.language]
            ) as Promise<any>)
            .then((res: IReqeustSchema) => res.response);

        const productCodeInfo = await this.productsModel
            .findOne(
                metadata.productCode,
                UiLanguagesConst[AuthContextService.user.language],
                true
            )
            .then((res) => res.response);

        const composerObject: Types.ViewTypes.WizardComposerObject = {
            productFamily: metadata.productFamily,
            apiObject: {
                mailboxCreate: {
                    accountId: metadata.account.id,
                    password: metadata.password,
                    autoconfigureDns: false,
                    mailbox: {
                        firstName: metadata.mailbox.firstName,
                        lastName: metadata.mailbox.lastName,
                        organizationId: metadata.organization.id,
                        password: metadata.password,
                        storageQuota: metadata.mailbox.storageQuota,
                        emailAddress: metadata.mailbox.emailAddress,
                        productCode: metadata.productCode,
                        spamFilter: mergedSpamProfile,
                        type: 'ExchangeMailbox'
                    } as EmailApi.ExchangeMailbox,
                    skipDnsCheck: metadata.skipDnsCheck
                },
                organizationCreate: {
                    accountId: metadata.account.id,
                    organization: {
                        comment: metadata.organization.comment,
                        name: metadata.organization.name
                    }
                }
            },
            summaryObject: [
                { // placeholder
                    account: metadata.account,
                    productCode: metadata.productCode,
                    factSheets: [
                        {
                            label: this.$translate.instant('TR_070519-16189c_TR'),
                            value: productFamilyInfo.name,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('TR_110219-fc0ae3_TR'),
                            value: productCodeInfo.name,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('TR_110119-631fe6_TR'),
                            value: metadata.mailbox.emailAddress,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('TR_110119-84aa83_TR'),
                            value: metadata.mailbox.firstName,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('TR_110119-05662f_TR'),
                            value: metadata.mailbox.lastName,
                            description: null,
                            type: 'string'
                        },
                        {
                            label: this.$translate.instant('TR_090119-2d59db_TR'),
                            value: this.formatMegabyteFilter(metadata.mailbox.storageQuota),
                            description: null,
                            type: 'string'
                        }
                    ],
                    product: {
                        name: metadata.productCode,
                        nameType: 'productCode',
                        shortDescription: productFamilyInfo.shortDescription
                    }
                }
            ]
        };

        if ([undefined, null, ''].indexOf(metadata.organization.id) < 0) {
            const { data }: { data: { name; comment }[] } = await this.mailboxModel.listOrganization(1, 1, {
                value: metadata.organization.id,
                field: 'organizationId'
            });
            const organizationInfos = [
                {
                    label: this.$translate.instant('64f1c6bb-ee5f-40df-9ae3-d419ee7ea193'),
                    value: data[0].name,
                    description: null,
                    type: 'string'
                },
                {
                    label: this.$translate.instant('TR_100119-e937e9_TR'),
                    value: data[0].comment,
                    description: null,
                    type: 'string'
                }
            ];
            // tslint:disable-next-line: max-line-length
            composerObject.summaryObject[0].factSheets.push(...(organizationInfos as ViewTypes.WizardSummaryViewDataObject[]));
        } else {
            const organizationInfos = [
                {
                    label: this.$translate.instant('64f1c6bb-ee5f-40df-9ae3-d419ee7ea193'),
                    value: metadata.organization.name,
                    description: null,
                    type: 'string'
                },
                {
                    label: this.$translate.instant('TR_100119-e937e9_TR'),
                    value: metadata.organization.comment,
                    description: null,
                    type: 'string'
                }
            ];
            // tslint:disable-next-line: max-line-length
            composerObject.summaryObject[0].factSheets.push(...(organizationInfos as ViewTypes.WizardSummaryViewDataObject[]));
        }

        return Promise.resolve(
            this.additionalStorageSummary(composerObject, metadata)
        );
    };

    private _mergeSpamObject = (spamFilter: ViewTypes.SpamFilter): ViewTypes.SpamFilter => {
        const defaultSpamObject = (SpamProfiles.filter((profile) => {
            return profile.values.spamLevel === spamFilter.spamLevel;
        }))[0];
        if (defaultSpamObject) {
            return merge(defaultSpamObject.values, spamFilter);
        }
        // use default medium spam object as constant definition if spamFilter.spamLevel was not defined
        return merge({
            bannedFilesChecks: false,
            deleteSpam: false,
            headerChecks: true,
            malwareChecks: true,
            modifySubjectOnSpam: true,
            spamChecks: true,
            spamLevel: 'medium',
            useGreylisting: true
        }, spamFilter);
    };

    private _addContingent = (composerObject: ViewTypes.WizardComposerObject, contingent: Contingent) => {
        if (!contingent || contingent.type.toLocaleLowerCase() === 'standalone') {
            return composerObject;
        }
        switch (contingent.type.toLocaleLowerCase()) {
            case 'pool':
                composerObject.apiObject.poolId = contingent.id;
                composerObject.summaryObject[0].contingent = contingent;
                break;
            case 'bundle':
                composerObject.apiObject.mailbox.bundleId = contingent.id;
                composerObject.summaryObject[0].contingent = contingent;
                break;
        }
        return composerObject;
    };

    private _forwarderProductCode = async (metadata: ViewTypes.ProductConfigExternalForwarderObject) => {
        const filterEmails: Finding.Filter = {
            subFilter: [],
            subFilterConnective: 'OR'
        };
        for (const iterator of metadata.mailbox.forwarderTargets) {
            filterEmails.subFilter.push({
                field: 'MailboxEmailAddressUnicode',
                value: iterator
            });
        }

        const result = await this.mailboxModel.list(metadata.mailbox.forwarderTargets.length, 1, filterEmails);
        if (result.data.length === metadata.mailbox.forwarderTargets.length) {
            return {
                productFamily: InternalForwarderProductFamily,
                productCode: InternalForwarderProductCode
            };
        }
        return {
            productFamily: ExternalForwarderProductFamily,
            productCode: metadata.productCode
        };
    };

    private additionalStorageSummary(
        composerObject: ViewTypes.WizardComposerObject,
        metadata: ViewTypes.ProductConfigExchangeMailboxObject | ViewTypes.ProductConfigEmailObject
    ) {
        if (metadata.additionalStorageInfo?.amount > 0) {
            composerObject.summaryObject.push(
                { // placeholder
                    account: metadata.account,
                    productCode: metadata.additionalStorageInfo.storageItem.productCode,
                    factSheets: [
                        {
                            description: '',
                            label: this.$translate.instant('dc44bf44-77e8-4da8-9e0c-36bf36a945f1'),
                            type: 'integer',
                            value: metadata.additionalStorageInfo.amount
                        },
                        {
                            description: '',
                            label: this.$translate.instant('TR_STORAGE_TR'),
                            type: 'string',
                            value: this.formatMegabyteFilter(metadata.additionalStorageInfo.storageItem.value)
                        }
                    ],
                    product: {
                        name: metadata.additionalStorageInfo.storageItem.productCode,
                        nameType: 'productCode'
                    },
                    priceMultiplication: metadata.additionalStorageInfo.amount
                }
            );
        }
        return composerObject;
    }
}
