import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateCronjobOverviewController {
    public static $inject: string[] = ['$state', '$translate'];

    public pageHeaderData: PanelHeaderData;
    public bundle;
    public overviewModel;
    public overviewServiceData;
    public expertView: boolean;
    public faqArticleIdList: string[] = [
        'webspace-cronjob-definition',
        'webspace-cronjob-setup',
        'webspace-cronjob-php'
    ];

    private simpleFilter;
    private extendedFilters;
    private overviewUiSettings;
    private webspace;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        if ([undefined, null].indexOf(this.simpleFilter) === -1) {
            this.expertView = this.overviewUiSettings.expertView || this.simpleFilter.show;
        } else {
            this.expertView = this.overviewUiSettings.expertView || false;
        }

        const stateInBundle = this.$state.current.name.split('.')[0] === 'bundle';
        const stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';

        const bundleId = [undefined, null, ''].indexOf(this.bundle) < 0
            ? this.bundle.id
            : this.webspace.bundleId;

        let panelHeaderBackwardLink: string;
        let panelHeaderBackwardText: string;
        let panelHeaderRouteParams: { [key: string]: any };

        // How about this?
        // switch (true) {
        //  case 'stateInBundle':
        switch (this.$state.current.name.split('.')[0]) {
            case 'bundle':
                panelHeaderBackwardText = this.$translate.instant('TR_090119-a32485_TR');
                panelHeaderBackwardLink = 'bundle.id.dashboard';
                panelHeaderRouteParams = {bundleId: bundleId};
                break;
            case 'managed-servers':
                panelHeaderBackwardText = this.$translate.instant('TR_140119-e915b4_TR');
                panelHeaderBackwardLink = 'managed-servers.id.webspaces.id.edit';
                panelHeaderRouteParams = {
                    machineId: this.$state.$current.locals.globals.machine.id,
                    webspaceId: this.$state.$current.locals.globals.webspace.id
                };
                break;
            default:
                panelHeaderBackwardText = this.$translate.instant('TR_140119-e915b4_TR');
                panelHeaderBackwardLink = 'webhosting.webspaces.id.edit';
                panelHeaderRouteParams = {webspaceId: this.webspace.id};
        }

        this.pageHeaderData = {
            backwardLink: panelHeaderBackwardLink,
            backwardText: panelHeaderBackwardText,
            panelHeaderRoute: '',
            panelHeaderRouteParams: panelHeaderRouteParams,
            panelIcon: 'calendar-alt',
            panelTitle: this.$translate.instant('TR_140119-2da745_TR')
        };

        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter
        };
    }

    public expandWrapper = (toggle) => {
        this.expertView = toggle.expand;
    };
}

export class TemplateCronjobOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<',
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<',
        webspace: '<'
    };
    public controller = TemplateCronjobOverviewController;
    public controllerAs = 'TemplateCronjobOverviewCtrl';
    public template = require('./cronjob-overview-template.html');
}
