import * as ng from 'angular';
import { UI } from '../../../../../types';

export class OrganEditPanelWebspacePhpBoostController {
    public static $inject: string[] = ['$translate'];

    public cancel;
    public $editFormOrganism;
    public panelRight;
    public oldTotal: number;
    public panelTitle: string;
    public boostData: UI.phpBoostOptionsInterface;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.panelTitle = this.$translate.instant('TR_070619-458016_TR');
        this.oldTotal = ng.copy(this.$editFormOrganism.webspace.storageQuota);
    }
}

export class OrganEditPanelWebspacePhpBoostComponent implements ng.IComponentOptions {
    public bindings = {
        boostData: '=',
        cancel: '<',
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormWebspacePhpBoost'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./webspace-php-boost.html');
    public controller = OrganEditPanelWebspacePhpBoostController;
}
