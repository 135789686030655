import * as ng from 'angular';

export class MoleculeTextCanBeEmptyController implements ng.IController {
    public static $inject: string[] = [];

    public _value: any;
    public finalValue: string;

    public get value() {
        return this._value;
    }

    public set value(newValue) {
        if (newValue !== this._value) {
            this._value = newValue;

            this._getValue();
        }
    }

    private _getValue = () => {
        if ([null, ''].indexOf(this._value) >= 0) {
            this.finalValue = undefined;
        } else {
            if (typeof this._value === 'number') {
                this.finalValue = this._value + '';
            } else {
                this.finalValue = this._value;
            }
        }
    };
}

export class MoleculeTextCanBeEmptyComponent implements ng.IComponentOptions {
    public bindings = {
        value: '<'
    };

    public controller = MoleculeTextCanBeEmptyController;
    public template = require('./text-can-be-empty.html');
}
