import * as ng from 'angular';
import * as Types from '../../../../../types';

import { BundleComposerService } from '../../../../../services';
import { ViewTypes } from '../../../../../types/view-types';

export class OrganismBundleWizardWrapperController {
    public static $inject: string[] = ['$timeout', 'bundleComposer'];

    public outerAccount: Types.AccountApi.Account;
    public outerProductCode: string; // used in template
    public productFamily: string;
    public account: Types.AccountApi.Account;
    public productCode: string;

    public wizardComposerObject: Types.ViewTypes.WizardComposerObject = {
        apiObject: {},
        summaryObject: []
    };
    public viewType = 'config';

    constructor(
        private $timeout: ng.ITimeoutService,
        private bundleComposer: BundleComposerService
    ) {}

    public loadSummaryView = (metadata: ViewTypes.ProductConfigBundleObject) => { // used in template
        this.wizardComposerObject.summaryObject = [];
        metadata.productFamily = this.productFamily;

        this.bundleComposer.buildBundleApiObject(metadata).then(
            (composerObject) => {
                this.$timeout(() => {
                    this.viewType = 'summary';
                    this.wizardComposerObject = composerObject;
                });
            }
        );
    };

    public loadConfirmView = () => { // used in template
        this.viewType = 'confirm';
    };
}

export class OrganismBundleWizardWrapperComponent implements ng.IComponentOptions {
    public bindings = {
        outerAccount: '<?account',
        outerProductCode: '<?productCode'
    };
    public template = require('./bundle-wizard-wrapper.html');
    public controller = OrganismBundleWizardWrapperController;
}
