import * as ng from 'angular';
import * as Types from '../../../../../types';

export class MoleculeFormAppSelectorController implements ng.IController {
    public static $inject: string[] = ['languageSettingFilter'];

    public apps: Types.AppInstaller.AppSpecification[];
    public isOsInstall = false;
    public selectedAppName: string | number;
    public dropdownItems: any[];
    public appItems: any[];
    public language: string;
    public callbackOnAppChange: (selection: any) => any;
    public popoverPosition: string;

    constructor(
        private languageSettingFilter
    ) {}

    public $onInit() {
        this.dropdownItems = [];
        this.appItems = [];

        for (const app of this.apps) {
            this.dropdownItems.push({
                name: `${app.data.provides.info.name} (${app.data.provides.info.version})`,
                value: app.general.pkgName
            });

            this.appItems[app.general.pkgName] = app;
        }
    }

    public get appSelected() {
        return this.selectedAppName !== undefined && this.selectedAppName !== null;
    }

    public get selectedApp() {
        return this.appItems[this.selectedAppName];
    }

    public set selectedApp(value: any) {
        if (value !== undefined && value !== null) {
            this.selectedAppName = value.general.pkgName;
        }
    }

    public get appDescription() {
        if (this.language === undefined) {
            return '';
        }

        if (
            this.selectedApp.data.provides.info.translations[this.languageSettingFilter(this.language, 'short')]
            !== undefined
        ) {
            return this.selectedApp.data.provides.info.translations[this.languageSettingFilter(this.language, 'short')];
        } else {
            return this.selectedApp.data.provides.info.translations.de;
        }
    }
}

export class MoleculeFormAppSelectorComponent implements ng.IComponentOptions {
    public bindings = {
        apps: '<',
        callbackOnAppChange: '<',
        isOsInstall: '<',
        language: '<',
        popoverPosition: '@',
        selectedApp: '='
    };
    public controller =  MoleculeFormAppSelectorController;
    public template = require('./app-selector.html');
}
