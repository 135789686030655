import * as ng from 'angular';
import {
    OrganismEditFormMachineController
} from '../../../../../atomic-components';
import { UiRights } from '../../../../../configuration';
import {
    AlertManagerService,
    AuthContextService,
    MachineModelService
} from '../../../../../services';
import { FormDropDownItems, PanelType } from '../../../../molecules';

export class OrganEditPanelMachinePowerController {
    public static $inject: string[] = ['$translate', '$q', 'alertManager', 'authContext', 'machineModel'];

    public $editFormOrganism: OrganismEditFormMachineController;
    public panelTypeOverride = PanelType.MISCELLANEOUS_ACTION;
    public panelRight;
    public powerAction = '';

    private _enhancedPanelRight;
    private actionsWhenPower = {
        isOff: [] as FormDropDownItems[],
        isOn: [] as FormDropDownItems[]
    };

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private $q: ng.IQService,
        private alertManager: AlertManagerService,
        private authContext: AuthContextService,
        private machineModel: MachineModelService
    ) {}

    // tslint:disable-next-line:no-empty
    public cancelCallback: () => void = () => {};

    public $onInit = () => {

        this.actionsWhenPower = {
            isOff: [
                { value: 'on', name: this.$translate.instant('TR_110119-4d2afa_TR') }
            ],
            isOn: [
                { value: 'reboot', name: this.$translate.instant('TR_110119-367c9a_TR') },
                { value: 'shutdown', name: this.$translate.instant('TR_110119-f1cfc9_TR') },
                { value: 'off', name: this.$translate.instant('TR_110119-f4a260_TR') },
                { value: 'reset', name: this.$translate.instant('TR_110119-4eee4f_TR') }
            ]
        };

        if (this.$editFormOrganism.machine.power === 'off') {
            this.powerAction = 'on';
        } else {
            this.powerAction = '';
        }
    };

    public get enhancedPanelRight() {
        if ([undefined, null].indexOf(this._enhancedPanelRight) >= 0) {
            this._enhancedPanelRight = ng.copy(this.panelRight);
            this._enhancedPanelRight.originalButtonRight = this._enhancedPanelRight.editPanelButton;
            this._enhancedPanelRight.forbiddenDueToManagedType = false;

            if (
                [undefined, null, '', 'none'].indexOf(this.$editFormOrganism.machine?.managementType) < 0
                && !this.authContext.isGranted(UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN)
            ) {
                this._enhancedPanelRight.forbiddenDueToManagedType = true;
                this._enhancedPanelRight.editPanelButton = false;
            }
        }

        return this._enhancedPanelRight;
    }

    public get powerActions(): FormDropDownItems[] {
        if (!this.$editFormOrganism || !this.$editFormOrganism.machine) {
            return [];
        }

        return this.$editFormOrganism.machine.power === 'off'
            ? this.actionsWhenPower.isOff
            : this.actionsWhenPower.isOn;
    }

    public executePowerAction = () => {
        switch (this.powerAction) {
            default: return this.$q.reject(this.$translate.instant('TR_110119-51d276_TR'));
            case 'on': return this.machineModel.powerOn([this.$editFormOrganism.machine]).then(
                (apiResponse) => {
                    this.alertManager.success(this.$translate.instant('TR_060219-ce011f_TR'));
                    this.$editFormOrganism.updateMachineSettings();
                    return apiResponse;
                }
            );
            case 'shutdown': return this.machineModel.shutdown([this.$editFormOrganism.machine]).then(
                (apiResponse) => {
                    this.alertManager.success(this.$translate.instant('TR_060219-fa30e4_TR'));
                    this.$editFormOrganism.updateMachineSettings();
                    return apiResponse;
                }
            );
            case 'off': return this.machineModel.powerOff([this.$editFormOrganism.machine]).then(
                (apiResponse) => {
                    this.alertManager.success(this.$translate.instant('TR_060219-ab547a_TR'));
                    this.$editFormOrganism.updateMachineSettings();
                    return apiResponse;
                }
            );
            case 'reboot': return this.machineModel.reboot([this.$editFormOrganism.machine]).then(
                (apiResponse) => {
                    this.alertManager.success(this.$translate.instant('TR_060219-ae379c_TR'));
                    this.$editFormOrganism.updateMachineSettings();
                    return apiResponse;
                }
            );
            case 'reset': return this.machineModel.reset([this.$editFormOrganism.machine]).then(
                (apiResponse) => {
                    this.alertManager.success(this.$translate.instant('TR_060219-1a7976_TR'));
                    this.$editFormOrganism.updateMachineSettings();
                    return apiResponse;
                }
            );
        }
    };

    public cancel = () => {
        this.$onInit();
        if ([undefined, null].indexOf(this.cancelCallback) === -1) {
            this.cancelCallback();
        }
    };
}

export class OrganEditPanelMachinePowerComponent implements ng.IComponentOptions {
    public bindings = {
        cancelCallback: '<cancel',
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormMachine'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./machine-power.html');
    public controller = OrganEditPanelMachinePowerController;
}
