import * as ng from 'angular';
import { LinkListItem, PanelHeaderData } from '@/atomic-components/molecules/';
import { GetEntryPointService } from '@/services';

import './dashboard-widget-welcome.scss';
export class OrganDashboardWidgetWelcomeController implements ng.IController {
    public static $inject: string[] = ['$translate', 'getEntryPoint'];

    public linkItems: LinkListItem[] = [];
    public panelHeader: PanelHeaderData = {
        panelIcon: '',
        panelTitle: '',
        panelHeaderRoute: ''
    };

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private getEntryPoint: GetEntryPointService
    ) {
        this.panelHeader.panelTitle = this.getEntryPoint.name !== 'Platform'
            ? this.$translate.instant(
                /* translationID */ 'TR_310321-e95616_TR',
                { entryPointName: this.getEntryPoint.name }
            )
            : this.$translate.instant('TR_310321-159231_TR');
    }
}

export class OrganDashboardWidgetWelcomeComponent implements ng.IComponentOptions {
    public template = require('./dashboard-widget-welcome.html');
    public controller = OrganDashboardWidgetWelcomeController;
}
