import * as ng from 'angular';
import { UiLanguagesConst, UiRights } from '../configuration';
import {
    AuthContextService,
    AuthenticationRobotService,
    BillingRobotService,
    BundleModelService,
    DashboardHelperService,
    DomainModelService,
    MachineModelService,
    ManagedApplicationRobotService,
    NavigationService,
    StorageModelService,
    UserSettingsModelService,
    WebspaceModelService
} from '../services';
import * as stateUtilityFunctions from '../services/state-utility-functions';
import * as Types from '../types';
import { PanicMode } from './../services/panic-mode';

export const STATES_ROUTES = [
    {
        name: 'authenticated',

        state: {
            abstract: true,
            onEnter: () => {
                ng.element(document.getElementsByTagName('body')[0]).removeClass('login');
            },
            resolve: {
                userHasRightAdminSystemSuperUserRead: () => {
                    // used in wrapper.html!!!!
                    return AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_SUPER_USER_READ);
                },
                hasManagedServer: (
                    machineModel: MachineModelService
                ) => {
                    if (AuthContextService.isGranted(UiRights.MACHINE_VM_LIST)) {
                        const filter = {
                            field: 'virtualMachineManagementType',
                            value: 'platform'
                        };
                        return machineModel.listWithoutPagination(1, 1, filter).then(
                            (res) => res.data.length > 0,
                            () => false
                        );
                    }

                    return false;
                },
                hasNextcloudServer: (
                    authContext: AuthContextService,
                    storageModel: StorageModelService
                ) => {
                    if (authContext.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_LIST)) {
                        return storageModel.listNextcloudsWithoutPagination(1, 1, null, null).then(
                            (res) => res.data.length > 0,
                            () => false
                        );
                    }

                    return false;
                },
                showAccounts: stateUtilityFunctions.showAccounts,
                uiSettings: (
                    $rootScope: ng.IRootScopeService|any,
                    $translate: ng.translate.ITranslateService,
                    userSettingsModel: UserSettingsModelService
                ) => {
                    return userSettingsModel.loadSettings()
                        .then(
                            (uiSettings) => {
                                uiSettings = JSON.parse(uiSettings);

                                return new Promise((resolve) => {
                                    $translate.use(UiLanguagesConst[AuthContextService.user.language]);
                                    return resolve();
                                }).then(() => uiSettings);

                            },
                            () => {
                                // Obacht! Dieser leere Handler verhindert, das der state-change von der AVV-Seite zum
                                // Dashboard schiefgeht mit der Meldung:
                                // Possibly unhandled rejection: cancelled by state change using navigation service.
                                // Sollte in Zukunft eine bessere Möglichkeit gefunden werden...
                                // Kommt auch vor in `app/services/account/user-settings-manager.ts`.
                            }
                        );
                }
            },
            views: {
                'sidemenu@': {
                    template: `<organism-sidebar
                                    has-managed-server="$resolve.hasManagedServer"
                                    has-nextcloud-server="$resolve.hasNextcloudServer"
                               ></organism-sidebar>`
                },
                'wrapper@': {
                    template: require('../components/global/wrapper.html')
                }
            }
        }
    },
    {
        name: 'panic',
        state: {
            onEnter: (navigation: NavigationService) => {
                PanicMode.start();
                navigation.go('dashboard');
            },
            url: '/panic'
        }
    },
    {
        name: 'logout',
        state: {
            data: {
                authenticate: false
            },
            onEnter: (
                authenticationRobot: AuthenticationRobotService
            ) => {
                ng.element(document.getElementsByTagName('body')[0]).addClass('login');
                authenticationRobot.logout();
            },
            url: '/logout'
        }
    },
    {
        name: 'avv',
        state: {
            url: '/avv',
            views: {
                'wrapper@': {
                    template: '<template-avv></template-avv>'
                }
            }
        }
    },
    {
        name: 'see-you-soon',
        state: {
            data: {
                authenticate: false
            },
            onEnter: (navigation: NavigationService) => {
                if (AuthContextService.authenticated) {
                    navigation.go('dashboard');
                }
            },
            url: '/see-you-soon?{lang}',
            views: {
                'wrapper@': {
                    template: '<template-see-you-soon></template-see-you-soon>'
                }
            }
        }
    },
    {
        name: 'pageloaderror',
        state: {
            parent: 'authenticated',
            url: '/error',
            views: {
                'content@authenticated': {
                    template: '<template-page-load-error></template-page-load-error>'
                }
            }
        }
    },
    {
        name: 'dashboard',
        state: {
            parent: 'authenticated',
            url: '/',
            onEnter: async (
                dashboardHelper: DashboardHelperService,
                navigation: NavigationService,
                $timeout: ng.ITimeoutService
            ) => {
                if (await dashboardHelper.hasNextloudDashboardTemplate()) {
                    void $timeout(() => {
                        void navigation.go('nextcloud-dashboard');
                    });
                } else {
                    void $timeout(() => {
                        void navigation.go('old-dashboard');
                    });
                }
            }
        }
    },
    {
        name: 'nextcloud-dashboard',
        state: {
            parent: 'authenticated',
            resolve: {
                isAdmin: () => AuthContextService.isRootOrCompanyAccount
            },
            url: '/nextcloud-dashboard',
            views: {
                'content@authenticated': {
                    template: `<template-main-new-dashboard
                                is-admin="$resolve.isAdmin"
                            ></template-main-new-dashboard>`
                }
            }
        }
    },
    {
        name: 'nextcloud-dashboard.edit',
        state: {
            parent: 'authenticated',
            resolve: {
                isAdmin: () => AuthContextService.isRootOrCompanyAccount
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-main-new-dashboard-edit
                        is-admin="$resolve.isAdmin"
                    ></template-main-new-dashboard-edit>`
                }
            }
        }
    },
    {
        name: 'old-dashboard',
        state: {
            parent: 'authenticated',
            resolve: {
                bundles: (isAdmin: boolean, bundleModel: BundleModelService, products: string[] | false) => {
                    if (
                        !AuthContextService.isGranted(UiRights.BIL_BUNDLE_LIST)
                        || isAdmin
                        || ( products !== false && products.indexOf('Bundle') < 0 ) // user has no Bundles
                    ) {
                        return [];
                    }
                    return bundleModel.listWithoutPagination(
                        null,
                        null,
                        null,
                        {field: 'bundleLastChangeDate', order: 'DESC'}).then((bundles) => bundles.data
                    );
                },
                isAdmin: () => AuthContextService.isRootOrCompanyAccount,
                nextcloudServers: (
                    isAdmin: boolean,
                    managedApplicationRobot: ManagedApplicationRobotService,
                    hasNextcloudServer
                ) => {
                    if (
                        !AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_LIST)
                        || isAdmin
                        || !hasNextcloudServer
                    ) {
                        return [];
                    }

                    return managedApplicationRobot.nextcloudsListWithoutPagination(
                        null,
                        3,
                        1,
                        {field: 'NextcloudLastChangeDate', order: 'DESC'}
                    )
                    .then((apiResponse) => apiResponse.response.data);
                },
                products: (isAdmin: boolean, billingRobot: BillingRobotService) => {
                    if (!AuthContextService.isGranted(UiRights.BIL_LIST_RENEWABLE_OBJECTS) || isAdmin) {
                        return false;
                    }

                    return billingRobot.findRenewableObjects(
                            {
                                field: 'AccountId',
                                value: AuthContextService.user.accountId
                            },
                            null,
                            null,
                            null
                        )
                        .then(
                            (apiResponse) => {
                                if (
                                    [undefined, null, []].indexOf(apiResponse.response) < 0
                                    && [undefined, null, []].indexOf(apiResponse.response.data) < 0
                                ) {
                                    return apiResponse.response.data
                                    .map((product: Types.BillingApi.RenewableObject) => product.objectType)
                                    .filter(
                                        (productType: string, currentIndex: number, productTypesArray: string[]) => {
                                            return productTypesArray.indexOf(productType) === currentIndex;
                                        }
                                    );
                                }
                            }
                        );
                },
                singleDomains: (domainModel: DomainModelService) => {
                    if (!AuthContextService.isGranted(UiRights.DOM_DOMAINS_LIST)) {
                        return [];
                    }
                    return domainModel.list(
                        null,
                        null,
                        null,
                        {field: 'domainLastChangeDate', order: 'DESC'}).then((domains) => domains.data
                    );
                },
                singleWebspaces: (isAdmin: boolean, webspaceModel: WebspaceModelService) => {
                    if (!AuthContextService.isGranted(UiRights.WEB_OBJECT_LIST) || isAdmin) {
                        return [];
                    }
                    return webspaceModel.list(
                        null,
                        null,
                        null,
                        {field: 'webspaceLastChangeDate', order: 'DESC'}).then((webspaces) => webspaces.data
                    );
                },
                virtualMachines: (isAdmin: boolean, machineModel: MachineModelService, products: string[] | false) => {
                    if (
                        !AuthContextService.isGrantedAny([UiRights.MACHINE_VM_LIST, UiRights.MACHINE_PM_LIST])
                        || isAdmin
                        || ( products !== false && products.indexOf('VirtualMachine') < 0 ) // user has no VMs
                    ) {
                        return [];
                    }

                    return machineModel.listWithoutPagination(
                        10,
                        1,
                        null,
                        {field: 'VirtualMachineLastChangeDate', order: 'DESC'}
                    )
                    .then((servers) => servers.data);
                }
            },
            url: '/dashboard-view',
            views: {
                'content@authenticated': {
                    template: `<template-main-dashboard
                        is-admin="$resolve.isAdmin"
                        bundles="$resolve.bundles"
                        virtual-machines="$resolve.virtualMachines"
                        nextcloud-servers="$resolve.nextcloudServers"
                        single-webspaces="$resolve.singleWebspaces"
                        single-domains="$resolve.singleDomains"
                    ></template-main-dashboard>`
                }
            }
        }
    },
    {
        name: 'fromcart',
        state: {
            parent: 'authenticated',
            url: '/from-cart',
            views: {
                'content@authenticated': {
                    template: '<template-from-cart></template-from-cart>'
                }
            }
        }
    },
    {
        name: 'resource',

        state: {
            abstract: true,
            parent: 'authenticated',
            url: '/resource'
        }
    },
    {
        name: '404',

        state: {
            data: {
                authenticate: false
            },
            views: {
                'wrapper@': {
                    template: require('../components/global/404/404.html')
                }
            }
        }
    },
    {
        name: 'wizardglobal',
        state: {
            parent: 'authenticated',
            url: '/wizard-global',
            views: {
                'content@authenticated': {
                    template: '<template-wizard-global></template-wizard-global>'
                }
            }
        }
    }
];
