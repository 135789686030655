import * as ng from 'angular';
import { PanelHeaderData } from '@/atomic-components/molecules/panels';
import { DashboardOverviewObject } from '@/atomic-components/organisms/overview';
import { UiRights } from '@/configuration';
import { AuthContextService, FilterCacheService, MailboxOverviewModel } from '@/services';

export class TemplateMailboxDashboardController {
    public static $inject: string[] = ['$translate', 'authContext', 'filterCache', 'mailboxOverviewModel'];

    public userHasRightEmailMailboxList = AuthContextService.isGranted(UiRights.EMAIL_MAILBOX_LIST);
    public pageHeaderData: PanelHeaderData;
    public dashboardOverview: DashboardOverviewObject;
    public faqArticleIdList: string[] = [
        'email-mailbox-create',
        'email-outgoing-debug',
        'email-mailbox-storage-full'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private filterCache: FilterCacheService,
        private mailboxOverviewModel: MailboxOverviewModel
    ) {}

    public $onInit = () => {
        const backwardRoute = '';
        this.pageHeaderData = {
            backwardLink: backwardRoute,
            backwardText: '',
            panelHeaderRoute: backwardRoute,
            panelHeaderRouteParams: null,
            panelIcon: 'envelope',
            panelTitle: this.$translate.instant('TR_110119-718cd7_TR')
        };

        this.dashboardOverview = {
            // List of table service data
            overviewsData: [
                {
                    listOptions: {
                        limit: 6,
                        sort: {
                            field: 'MailboxLastChangeDate',
                            order: 'DESC'
                        }
                    },
                    overviewModel: this.mailboxOverviewModel,
                    panelHeaderData: {
                        includeShowAllLink: true,
                        includeShowDetailsLink: false,
                        panelHeaderRoute: 'email.mailboxes.overview',
                        panelHeaderRouteParams: {},
                        panelIcon: 'envelope',
                        panelTitle: this.$translate.instant('MENU.CONTEXT.EMAIL.ADDRESSES')
                    },
                    serviceItemList: []
                }
            ],
            // Dashboard main menu panel
            panelData: {
                addtionalActionData: {
                    // Addition action panel
                    linkList: [
                        {
                            isGranted: this.authContext.isGranted(UiRights.EMAIL_JOBS_LIST),
                            route: 'email.mailboxes.jobs',
                            routeParams: {},
                            text: this.$translate.instant('MENU.CONTEXT.JOBS')
                        }
                    ]
                },
                mainHeader: {
                    icon: 'envelope',
                    isGranted: this.authContext.isGrantedAny([
                            UiRights.EMAIL_EXCHANGE_CREATE,
                            UiRights.EMAIL_FORWARDER_CREATE,
                            UiRights.EMAIL_IMAPMAILBOX_CREATE
                    ]),
                    route: 'email.mailboxes.new',
                    routeParams: {},
                    text: this.$translate.instant('TR_110119-85a51b_TR')
                }
            },
            simpleFilter: this.filterCache.resolveSimpleFilter('Mailboxes')
        };
    };
}

export class TemplateMailboxDashboardComponent implements ng.IComponentOptions {
    public controller = TemplateMailboxDashboardController;
    public controllerAs = '$TemplateMailboxDashboardCtrl';
    public template = require('./dashboard-template.html');
}
