import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import * as Types from '../../../types';

export class TemplateDatabaseUserNewController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;

    public originalUser: Types.WebhostingApi.User;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'database.users.overview',
            backwardText: this.$translate.instant('TR_100119-32d822_TR'),
            panelHeaderRoute: '',
            panelIcon: 'user',
            panelTitle: this.$translate.instant('TR_140119-db47e4_TR')
        };
    };
}

export class TemplateDatabaseUserNewComponent implements ng.IComponentOptions {
    public controller = TemplateDatabaseUserNewController;
    public controllerAs = '$TemplateDatabaseUserNewCtrl';
    public template = require('./database-user-new-template.html');
}
