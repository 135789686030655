import { DomainInfoHelperService } from '@/services';
import { Validator } from './abstract-validator';
import { AlphaNumericCaseInsensitivePlusUmlauts } from '@/configuration';

export class ValidateDomainName extends Validator {
    private _maxLength = null;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private domainInfoHelper: DomainInfoHelperService,
        maxLength?: number
    ) {
        super();
        if (maxLength) {
            this._maxLength = maxLength;
        }
    }

    public validate = (value) => {
        const reply = [];

        if (value[value.length - 1] === '.') {
            reply.push({
                text: this.$translate.instant('TR_170119-f0b358_TR')
            });
        }

        if (this._maxLength && (value.length > this._maxLength)) {
            reply.push({ text: this.$translate.instant('TR_170119-7778c3_TR') });
        }

        let illegalCharactersFound = '';
        let illegalCharactersMessage = '';
        const allowedCharacters = AlphaNumericCaseInsensitivePlusUmlauts.concat('ß-.');

        for (const char of value) {
            if (allowedCharacters.indexOf(char) < 0 && illegalCharactersFound.indexOf(char) < 0) {
                if (char !== ' ') {
                    illegalCharactersMessage += char;
                } else {
                    illegalCharactersMessage += ' ' + this.$translate.instant('TR_170119-11fb1a_TR') + ' ';
                }

                illegalCharactersFound += char;
            }
        }

        if (illegalCharactersFound.length > 0) {
            reply.push({
                text: this.$translate.instant('TR_170119-9c4384_TR', {
                    illegalCharactersMessage: illegalCharactersMessage
                })
            });
        }

        return reply;
    };

    public isComplete = (value) => {
        if (!this.domainInfoHelper.hasValidTld(value)) {
            return false;
        }
        const parts = value.split('.');
        const lookupTld = this.domainInfoHelper.getDomainTld(parts);
        const tldParts = lookupTld.unicode.split('.');

        return parts.every(part => {
            const isTldPart = tldParts.some(tldPart => {
                return tldPart === part;
            });

            if (!isTldPart) {
                return this.validate(part).length === 0;
            }

            return true;
        });
    };
}
