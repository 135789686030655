import { Validator } from './abstract-validator';

export class ValidateRegularExpression extends Validator {
    private expression: RegExp;

    constructor(
        private $translate: ng.translate.ITranslateService,
        expression: string,
        flags?: string,
        private allowEmpty: boolean = false,
        private searchMatches: boolean = false
    ) {
        super();

        if (flags) {
            this.expression = RegExp(expression, flags);
        } else {
            this.expression = RegExp(expression);
        }
    }

    public validate = (value: string): { text: string }[] => {
        if (this.allowEmpty && value.length < 1) {
            return [];
        } else if (this.searchMatches && this.expression.test(value)) {
            return [{ text: this.$translate.instant('TR_170119-9cab93_TR') }];
        } else if (!this.expression.test(value)) {
            return [{ text: this.$translate.instant('TR_170119-9cab93_TR') }];
        }

        return [];
    };
}
