import * as ng from 'angular';

export class MoleculeChartEmailRestrictionOverviewRowController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public restrictions;
    public restrictionLabel: string;

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.restrictionLabel = '';

        if ([undefined, null].indexOf(this.restrictions) >= 0) {
            return;
        }

        this.restrictions.some(
            (restriction) => {
                switch (restriction.type) {
                    case 'smtpDisabled':
                        const isUserAccessibleRestriction = this.restrictions.some(
                            (singleRestriction) => singleRestriction.accountId !== null
                        );
                        if (isUserAccessibleRestriction) {
                            this.restrictionLabel = this.$translate.instant('TR_090119-30e90a_TR');
                        } else {
                            this.restrictionLabel = this.$translate.instant('TR_280619-564c3e_TR');
                        }
                        return true;
                    case 'overquota':
                    case 'overQuota':
                        this.restrictionLabel = this.$translate.instant('c147f2ad-9a3f-4bb6-8687-cb4169ce5e9e');
                        return true;
                    case 'noTargets':
                        this.restrictionLabel = this.$translate.instant('TR_090119-c7901f_TR');
                        return true;
                    default:
                        break;
                }
            }
        );
    }
}

export class MoleculeChartEmailRestrictionOverviewRowComponent implements ng.IComponentOptions {
    public bindings = {
        restrictions: '<'
    };

    public controller =  MoleculeChartEmailRestrictionOverviewRowController;

    /* tslint:disable-next-line:max-line-length */
    public template = require('./restriction-overview-email-row.html');
}
