import { MachineApi } from '@/types';
import { FunctionRelayService } from '../function-relay';
import { ModelHelper } from '../model-helper';
import { RequestStatus } from '../rpc-client';
import { MachineRobotService } from './robot';

import * as ng from 'angular';

export class MachineModelService {
    public static $inject: string[] = [
        '$translate',
        'functionRelay',
        'machineRobot'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private functionRelay: FunctionRelayService,
        private machineRobot: MachineRobotService
    ) {}

    public list = (limit?: any, page?: any, filters?: any, sort?: any, cancel?: any) => {
        sort = sort || {
                field: 'virtualMachineName',
                order: 'ASC'
            };
        page = page || 1;

        return this.machineRobot.listVms(filters, limit, page, sort, cancel)
            .then(ModelHelper.returnListResponse);
    };

    public listWithoutPagination = (limit?: any, page?: any, filters?: any, sort?: any, cancel?: any) => {
        sort = sort || {
                field: 'virtualMachineName',
                order: 'ASC'
            };
        page = page || 1;

        return this.machineRobot.listVmsWithoutPagination(filters, limit, page, sort, cancel)
            .then(ModelHelper.returnListResponse);
    };

    public listPhysicalMachines = (limit?: any, page?: any, filters?: any, sort?: any, cancel?: any) => {
        sort = sort || {
            field: 'PhysicalMachineName',
            order: 'ASC'
        };
        page = page || 1;

        return this.machineRobot.listPhysicalMachinesWithoutPagination(filters, limit, page, sort, cancel)
            .then(ModelHelper.returnListResponse);
    };

    public findOne = (type: any, id: any, limit?: any, page?: any, sort?: any) => {
        limit = limit || 0;
        page = page || 1;
        const filter = {field: type + 'Id', value: id};

        switch (type) {
            case 'physicalMachine':
                return this.machineRobot.listPhysicalMachinesWithoutPagination(filter, limit, page, sort)
                    .then(ModelHelper.returnFindOneResponse);
            default:
                return Promise.resolve([]);
        }
    };

    public findOneById = (id: any) => {
        return this.machineRobot.listVmsWithoutPagination({field: 'virtualMachineId', value: id}, 10, 1)
            .then(ModelHelper.returnFindOneResponse) as Promise<MachineApi.VirtualMachine>;
    };

    public vncSessionCreate = (machineId: string) => {
        return this.machineRobot.vncSessionCreate(machineId)
            .then((apiResponse) => { return apiResponse.response; });
    };

    public getProductByProductCode = (productCode: string) => {
        return this.machineRobot.productsListWithoutPagination(
            { productCode: productCode, language: this.$translate.use() }
        )
            .then(ModelHelper.returnFindOneResponse);
    };

    public findById = (ids: any) => {
        const filter = {subFilterConnective: 'OR', subFilter: ids.map(this.idFilter)};

        return this.machineRobot.listVmsWithoutPagination(filter, 0, 1)
            .then(ModelHelper.returnFindResponse);
    };

    public create = (virtualMachine: any) => {
        return this.machineRobot.createVm(virtualMachine)
            .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
    };

    public changeProduct = (virtualMachineId: any, product: any, reboot: any, backupEnabled?: boolean) => {
        return this.machineRobot.changeVmProduct(virtualMachineId, product, reboot, backupEnabled)
            .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
    };

    public delete = (machine: any, executionDate: any) => {
        return this.machineRobot.deleteVirtualMachine(machine.id, executionDate);
    };

    public cancelDeletion = (machineId: any) => {
        return this.machineRobot.cancelVirtualMachineDeletion(machineId);
    };

    public findOneByName = (name: any) => {
        return this.machineRobot.listVmsWithoutPagination({field: 'virtualMachineName', value: name}, 1, 1)
            .then(ModelHelper.returnFindOneResponse);
    };

    public restore = (machines: any) => {
        const promises = machines.map(this.restoreOne);

        return Promise.all(promises);
    };

    public powerOn = (machines: any) => {
        const promises = machines.map(this.powerOnOne);

        return Promise.all(promises);
    };

    public powerOff = (machines: any) => {
        const promises = machines.map(this.powerOffOne);

        return Promise.all(promises);
    };

    public reboot = (machines: any) => {
        const promises = machines.map(this.rebootOne);

        return Promise.all(promises);
    };

    public reset = (machines: any) => {
        const promises = machines.map(this.resetOne);

        return Promise.all(promises);
    };

    public shutdown = (machines: any) => {
        const promises = machines.map(this.shutdownOne);

        return Promise.all(promises);
    };

    public enableRescue = (virtualMachineId: any, rootPassword: any, publicKeys: any, reset: any) => {
        return this.machineRobot.enableRescue(virtualMachineId, rootPassword, publicKeys, reset);
    };

    public disableRescue = (virtualMachineId: any, reset: any) => {
        return this.machineRobot.disableRescue(virtualMachineId, reset);
    };

    public purgeRestorable = (virtualMachineId: any) => {
        return this.machineRobot.purgeRestorableVirtualMachine(virtualMachineId);
    };

    public reinstallManaged = (virtualMachineId: any) => {
        return this.machineRobot.reinstallManagedServer(virtualMachineId);
    };

    public setRDNS = (ipAddress: any, fqdn: any) => {
        return this.machineRobot.setRDNS(ipAddress, fqdn);
    };

    public updateMachine = (machine: any, reboot?: any) => {
        reboot = reboot || false;

        return this.machineRobot.updateMachine(machine, reboot);
    };

    private refreshDeposit = (status: RequestStatus = RequestStatus.SUCCESSFUL) => (result: any) => {
        this.functionRelay.call('updateDeposit', {});

        if (status === RequestStatus.SUCCESSFUL) {
            return result;
        } else {
            return Promise.reject(result);
        }
    };

    private idFilter = (id: any) => {
        return {field: 'virtualMachineId', value: id};
    };

    private restoreOne = (virtualMachine: any) => {
        return this.machineRobot.restoreVm(virtualMachine.id);
    };

    private powerOnOne = (virtualMachine: any) => {
        return this.machineRobot.powerOnVm(virtualMachine.id);
    };

    private powerOffOne = (virtualMachine: any) => {
        return this.machineRobot.powerOffVm(virtualMachine.id);
    };

    private rebootOne = (virtualMachine: any) => {
        return this.machineRobot.rebootVm(virtualMachine.id);
    };

    private resetOne = (virtualMachine: any) => {
        return this.machineRobot.resetVm(virtualMachine.id);
    };

    private shutdownOne = (virtualMachine: any) => {
        return this.machineRobot.shutdownVm(virtualMachine.id);
    };
}
