import * as ng from 'angular';
export class OrganNewPanelMailingListController {
    public static $inject: string[] = ['$translate'];

    public settings: any;
    public maxMembers: number;
    public inWizard: boolean;
    public readOnly: boolean;
    public accessModeDropdownItems: { name: string; value: string }[];
    public replyToModeDropdownItems: { name: string; value: string }[];
    public accessModeComments: any;
    public replyToModeComments: any;
    public unAddedOwnerEmail = '';
    public unAddedMemberEmail = '';

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    $onInit() {
        this.readOnly = this.readOnly || false;
        this.settings.owners = this.settings.owners || [];
        this.settings.allowHtmlMails = this.settings.allowHtmlMails || false;
        this.settings.members = this.settings.members || [];
        if (this.settings.members.length > 0) {
            this.settings.members = this.settings.members.sort();
        }
        this.accessModeDropdownItems = [{
            name: this.$translate.instant('e23e4e3f-030f-4e2c-b826-ca73686abb06'),
            value: 'owner'
        }, {
            name: this.$translate.instant('69e776dd-a8bd-44fe-88f9-aa135410f470'),
            value: 'members'
        }, {
            name: this.$translate.instant('TR_110119-b212cd_TR'),
            value: 'everyone'
        }];
        this.replyToModeDropdownItems = [{
            name: this.$translate.instant('TR_110119-7b3b04_TR'),
            value: 'list'
        }, {
            name: this.$translate.instant('TR_110119-595d94_TR'),
            value: 'self'
        }, {
            name: this.$translate.instant('TR_110119-725707_TR'),
            value: 'specifiedAddress'
        }];
        this.accessModeComments = {
            owner: this.$translate.instant('TR_110119-9ebcac_TR'),
            members: this.$translate.instant('TR_110119-a349cd_TR'),
            everyone: this.$translate.instant('TR_110119-0007ee_TR')
        };
        this.replyToModeComments = {
            list: this.$translate.instant('TR_110119-52f6d6_TR'),
            self: this.$translate.instant('TR_110119-e2a108_TR'),
            specifiedAddress: this.$translate.instant('TR_110119-2bfc9a_TR')
        };
    }

    public get accessIsDefined() {
        return this.settings.accesMode !== undefined;
    }

    public get accesModeComment() {
        if (this.settings.accesMode === undefined) {
            return '';
        }
        return this.accessModeComments[this.settings.accesMode];
    }

    public get replyToModeIsDefined() {
        return this.settings.replyToMode !== undefined;
    }

    public get replyToModeComment() {
        if (this.settings.replyToMode === undefined) {
            return '';
        }
        return this.replyToModeComments[this.settings.replyToMode];
    }

    public get accessMode() {
        let accessMode = '';

        if (this.settings !== undefined) {
            this.accessModeDropdownItems.map(item => {
                if (item.value === this.settings.accessMode) {
                    accessMode = item.name;
                }
            });
        }

        return accessMode;
    }

    public get replyToMode() {
        if (this.settings !== undefined) {
            return this.replyToModeComments[this.settings.replyToMode];
        }
        return '';
    }

    public get selectedReplyToMode() {
        return '';
    }
}

export class OrganNewPanelMailingListComponent implements ng.IComponentOptions {
    public bindings = {
        inWizard: '<',
        maxMembers: '<',
        readOnly: '<',
        settings: '=',
        unAddedOwnerEmail: '=?',
        unAddedMemberEmail: '=?'
    };
    public template = require('./mailing-list.html');
    public controller = OrganNewPanelMailingListController;
}
