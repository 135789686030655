import * as ng from 'angular';
import './panel-edit-row-info.scss';

export class MoleculePanelEditRowInfoController implements ng.IController {
    public grow: boolean;
    public className: string;

    public $onInit() {
        if ([undefined, null].indexOf(this.grow) >= 0) {
            this.grow = true;
        }
    }
}

export class MoleculePanelEditRowInfoComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        className: '@?',
        grow: '<?'
    };
    public controller = MoleculePanelEditRowInfoController;
    public template = require('./panel-edit-row-info.html');
}
