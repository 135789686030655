import { ModelHelper } from '../model-helper';
import { DnsRobotService } from './robot';

import * as Types from '../../types';

export class DnsJobModelService {
    public static $inject: string[] = ['dnsRobot'];

    constructor(
        private dnsRobot: DnsRobotService
    ) {}

    public list = (
        limit: number,
        page: number,
        filters: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions
    ) => {
        sort = sort || {
            field: 'AccountId',
            order: 'ASC'
        };
        page = page || 1;

        return this.dnsRobot.listJobs(filters, limit, page, sort).then(ModelHelper.returnListResponse);
    };

    public findRunningJobsForZone = (zone: Types.DnsApi.ZoneConfig) => {
        const filter = {
            subFilter: [
                { field: 'jobObjectId', value: zone.id },
                { field: 'jobStatus', relation: 'unequal', value: 'successful' },
                { field: 'jobStatus', relation: 'unequal', value: 'failed' },
                { field: 'jobStatus', relation: 'unequal', value: 'canceled' }
            ],
            subFilterConnective: 'AND'
        };

        return this.dnsRobot.listJobs(filter, 25, 1, null);
    };

    public findOne = (id: string) => {
        return this.dnsRobot.listJobs({ field: 'jobId', value: id }, 1, 1)
            .then(ModelHelper.returnFindOneResponse).then(
                (job) => {
                    // @todo API Workaround
                    job.errors = this.parseJobError(job.errors);

                    return job;
                }
            );
    };

    public findById = (ids: string[]) => {
        const filter: Types.Finding.Filter = {
            subFilter: ids.map((id) => ({ field: 'jobId', value: id })),
            subFilterConnective: 'OR'
        };

        return this.dnsRobot.listJobs(filter, 0, 1).then((result) => result.response.data);
    };

    private parseJobError = (error: string) => {
        if (error === '' || !error) {
            return [];
        }

        return JSON.parse(error);
    };
}
