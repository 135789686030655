import * as ng from 'angular';
import { NavigationService } from '../../../../../services';
import { EditPanelStatus } from '../../../../molecules/forms/form-edit/form-edit';

export class OrganEditPanelDomainAppInstallController {
    public static $inject: string[] = ['$state', 'navigation'];

    public $editFormMolecule;
    public panelRight: any;
    public status: EditPanelStatus;
    public name = 'domain-app-install';

    constructor(
        private $state: ng.ui.IStateService,
        private navigation: NavigationService
    ) {}

    public $onInit() {
        this.$editFormMolecule.register(this);
        this.status = EditPanelStatus.READONLY;
    }

    public goToEdit = () => {
        const stateInBundle = this.$state.current.name.split('.')[0] === 'bundle';
        const stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';

        switch (true) {
            case stateInBundle:
                this.navigation.go('bundle.id.domains.id.app-install');
                break;
            case stateInMachine:
                this.navigation.go('managed-servers.id.webspaces.id.vhosts.id.app-install',
                    {
                        machineId: this.$state.$current.locals.globals.machine.id,
                        vHostId: this.$state.$current.locals.globals.vHost.id,
                        webspaceId: this.$state.$current.locals.globals.webspace.id
                    }
                );
                break;
            default:
                this.navigation.go('webhosting.webspaces.id.vhosts.id.app-install');
        }
    };

    public get disableEditButton() {
        return !this.$editFormMolecule.canEnablePanel(this.name);
    }
}

export class OrganEditPanelDomainAppInstallComponent implements ng.IComponentOptions {
    public bindings = {
        panelRight: '<'
    };
    public require = {
        $editFormMolecule: '^moleculeFormEdit'
    };
    public controller = OrganEditPanelDomainAppInstallController;
    public controllerAs = '$editPanelOrgan';
    public template = require('./domain-app-install.html');
}
