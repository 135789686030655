import * as ng from 'angular';
import { AuthContextService, NavigationService } from '../../../../../../services';
import { OrganismOverviewController, OverviewDataObject } from '../../../../../organisms/overview/overview';

export class MoleculeOverviewRowPanelMachineJobController implements ng.IController {
    public static $inject: string[] = ['authContext', 'navigation'];

    public overviewData: OverviewDataObject;
    public overviewLoad: (selection: any) => any;
    public internalPaginationLimit: number;
    public OrganismOverview: OrganismOverviewController;

    constructor(
        private authContext: AuthContextService,
        private navigation: NavigationService
    ) {}

    public get showAccounts() {
        return this.OrganismOverview.globals.showAccounts;
    }

    public get showTriggeredBy() {
        return this.authContext.isRootOrCompanyAccount;
    }
}

export class MoleculeOverviewRowPanelMachineJobComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelMachineJobController;
    public controllerAs = 'OverviewMachineCtrl';
    public template = require('./machine-job-row.html');
}
