import * as ng from 'angular';

export class MoleculeButtonRevokeController implements ng.IController {
    public static $inject: string[] = [];

    public revoke: () => void;
    public modifier = '';
    public disabled = false;
}

export class MoleculeButtonRevokeComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        disabled: '<',
        modifier: '@?',
        revoke: '<'
    };
    public controller =  MoleculeButtonRevokeController;
    public template = require('./button-revoke.html');
}
