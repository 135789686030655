import * as ng from 'angular';
import { UiRights } from '../../configuration';
import {
    AccountModelService,
    addHiddenContactsFilter,
    AuthContextService,
    BundleModelService,
    DnsZoneModelService,
    DomainContactsOverviewModel,
    DomainContactsVerificationOverviewModel,
    DomainHandleModelService,
    DomainJobModelService,
    DomainJobOverviewModel,
    DomainModelService,
    DomainOverviewModel,
    DomainTransferOverviewModel,
    FilterCacheService,
    NameserverSetModelService,
    NavigationService,
    NominetRegistrarListService,
    RedirectionModelService,
    RedirectionOverviewModel,
    RedirectOnNotFoundCallback,
    SystemHelperService,
    UserSettingsManagerService,
    WebspaceModelService
} from '../../services';
import * as Types from '../../types';
import { BundleApi } from '../../types';
import Bundle = BundleApi.Bundle;

export const DOMAIN_ROUTES = [
    {
        name: 'domain',
        state: {
            abstract: true,
            parent: 'authenticated',
            url: '/domain'
        }
    },
    {
        name: 'domain.dashboard',
        state: {
            data: {
                isGrantedAny: [
                    UiRights.DOM_CONTACTS_LIST,
                    UiRights.DOM_DOMAINS_LIST,
                    UiRights.DOM_JOBS_LIST
                ]
            },
            onEnter: (navigation: NavigationService, userSettingsManager: UserSettingsManagerService) => {
                userSettingsManager.getUiSettings().then(
                    (result) => {
                        if (result?.overviewSettings?.DomainOverviewCtrl?.expertView) {
                            navigation.go('domain.domains.overview');
                        }
                    }
                );
            },
            resolve: {
                webspaces: (authContext: AuthContextService, webspaceModel: WebspaceModelService) => {
                    if (authContext.isGranted(UiRights.WEB_OBJECT_LIST)) {
                        return webspaceModel.list().then((res) => {
                            return res.data;
                        });
                    }

                    return [];
                }
            },
            url: '/',
            views: {
                'content@authenticated': {
                    template: '<template-domain-dashboard webspaces="$resolve.webspaces"></template-domain-dashboard>'
                }
            }
        }
    },
    {
        name: 'domain.setup',
        state: {
            abstract: true,
            url: '/setup',
        }
    },
    {
        name: 'domain.setup.orderId',
        state: {
            resolve: {
                orderId: (
                    $stateParams: ng.ui.IStateParamsService,
                ) => {
                    return $stateParams.orderId;
                },
            },
            url: '/{orderId}',
            views: {
                'content@authenticated': {
                    template: '<template-domain-setup order-id="$resolve.orderId"></template-domain-setup>'
                }
            }
        }
    },
    {
        name: 'domain.availability-check',
        state: {
            data: {
                isGrantedAny: [
                    UiRights.DOM_DOMAINS_CREATE_WITH_CONTACTS,
                    UiRights.DOM_DOMAINS_CREATE_WITH_DEFAULTS,
                    UiRights.DOM_DOMAINS_CREATE_WITH_OWN_NS
                ]
            },
            url: '/availability-check',
            views: {
                'content@authenticated': {
                    template: '<template-domain-availability-check></template-domain-availability-check>'
                }
            }
        }
    },
    {
        name: 'domain.domains',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.DOM_DOMAINS_LIST
            },
            url: '/domains'
        }
    },
    {
        name: 'domain.domains.overview',
        state: {
            data: {
                isGranted: UiRights.DOM_DOMAINS_LIST
            },
            resolve: {
                extendedFilters: ($stateParams: ng.ui.IStateParamsService, filterCache: FilterCacheService) => {
                    if ($stateParams.fancyFilter !== undefined && $stateParams.fancyFilter !== '') {
                        return JSON.parse($stateParams.fancyFilter);
                    }
                    return filterCache.resolveExtendedFilters('Domain');
                },
                overviewModel: (domainOverviewModel: DomainOverviewModel) => domainOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('DomainOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('Domain');
                }
            },
            url: '/overview/{fancyFilter}',
            views: {
                'content@authenticated': {
                    template: `<template-domain-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                ></template-domain-overview>`
                }
            }
        }
    },
    {
        name: 'domain.domains.id',
        state: {
            abstract: true,
            resolve: {
                domain: (
                    $stateParams: ng.ui.IStateParamsService,
                    domainModel: DomainModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return domainModel.findOne($stateParams.domainId).then(null, redirectOnNotFound);
                }
            },
            url: '/id/{domainId}'
        }
    },
    {
        name: 'domain.domains.id.edit',
        state: {
            onEnter: (systemHelper: SystemHelperService) => {
                // Dient nur zur Initialisierung des countryListCache
                // aus timing Gründen onEnter (race condition)
                systemHelper.getCountryRawList();
            },
            resolve: {
                accountSystemNameserver: (nameservers, defaultNameservers) => {
                    return nameservers.concat(defaultNameservers);
                },
                defaultNameservers: (domain, nameserverSetModel: NameserverSetModelService) => {
                    return nameserverSetModel.getDefault(domain.accountId);
                },
                nameservers: (domain, nameserverSetModel: NameserverSetModelService) => {
                    return nameserverSetModel.list(50, 1, { field: 'AccountId', value: domain.accountId })
                    .then((reply) => reply.data);
                },
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                },
                tags: (nominetRegistrarList: NominetRegistrarListService) => {
                    return nominetRegistrarList.getList();
                },
                zone: (dnsZoneModel: DnsZoneModelService, domain) => {
                    const filters: Types.Finding.Filter = {
                        subFilter: [
                            {field: 'zoneName', value: domain.nameUnicode},
                            {field: 'zoneNameUnicode', value: domain.nameUnicode}
                        ],
                        subFilterConnective: 'OR'
                    };
                    return dnsZoneModel.findZones(filters, 1, 1, null, true);
                }
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-domain-product-edit
                        domain="$resolve.domain"
                        zone="$resolve.zone"
                        show-object-id="$resolve.showObjectId"
                        tags="$resolve.tags"
                    ></template-domain-product-edit>`
                }
            }
        }
    },
    {
        name: 'domain.domains.id.move',
        state: {
            data: {
                isGranted: UiRights.DOM_DOMAINS_MOVE
            },
            resolve: {
                domains: (domainModel: DomainModelService) => {
                    return domainModel.list().then(
                        (domains) => domains.data
                    );
                },
                selectedDomains: (domain) => [domain],
                bundles: (
                    bundleModel: BundleModelService,
                    domain: Types.DomainApi.Domain
                ): Types.BundleApi.Bundle[] => {
                    if (domain.bundleId) {
                        return bundleModel.findById([domain.bundleId]) as unknown as Types.BundleApi.Bundle[];
                    }

                    return [];
                },

                subAccounts: (accountModel: AccountModelService) => {
                    return accountModel.listSubaccounts().then((reply) => reply.data);
                }
            },
            url: '/move',
            views: {
                'content@authenticated': {
                    template: `<template-domain-move
                        bundles="$resolve.bundles"
                        selected-domains="$resolve.selectedDomains"
                        domains="$resolve.domains"
                        sub-accounts="$resolve.subAccounts"
                    ></template-domain-move>`
                }
            }
        }
    },
    {
        name: 'domain.domains.id.remove-from-bundle',
        state: {
            data: {
                isGranted: UiRights.ADMIN_SYSTEM_DOMAIN_SUPPORT
            },
            resolve: {
                bundle: (domain, bundleModel: BundleModelService) => {
                    return bundleModel.findOneById(domain.bundleId).then((result) => result[0]);
                }
            },
            url: '/remove-from-bundle',
            views: {
                'content@authenticated': {
                    template: `<template-domain-remove-from-bundle
                        bundle="$resolve.bundle"
                        domain="$resolve.domain"
                    ></template-domain-remove-from-bundle>`
                }
            }
        }
    },
    {
        name: 'domain.domains.id.records',
        state: {
            data: {
                isGranted: UiRights.DOM_DOMAINS_EDIT
            },
            resolve: {
                records: (dnsZoneModel: DnsZoneModelService, zone) => {
                    return dnsZoneModel.findRecords(zone.id);
                },
                zone: (dnsZoneModel: DnsZoneModelService, domain) => {
                    return dnsZoneModel.findByName([domain.name]).then((zones) => zones[0]);
                }
            },
            url: '/records',
            views: {
                'content@authenticated': {
                    template: `<template-domain-records-edit
                                zone="$resolve.zone"
                                records="$resolve.records"
                                ></template-domain-records-edit>`
                }
            }
        }
    },
    {
        name: 'domain.domains.jobs',
        state: {
            data: {
                isGranted: UiRights.DOM_JOBS_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('DomainJobs');
                },
                overviewModel: (domainJobOverviewModel: DomainJobOverviewModel) => domainJobOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('DomainOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('DomainJobs');
                }
            },
            url: '/jobs',
            views: {
                'content@authenticated': {
                    template: `<template-domain-job-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                ></template-domain-job-overview>`
                }
            }
        }
    },
    {
        name: 'domain.domains.jobs.id',
        state: {
            abstract: true,
            resolve: {
                job: (
                    $stateParams: ng.ui.IStateParamsService,
                    domainJobModel: DomainJobModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return domainJobModel.findOne($stateParams.jobId).then(null, redirectOnNotFound);
                }
            },
            url: '/id/{jobId}'
        }
    },
    {
        name: 'domain.domains.jobs.id.details',
        state: {
            url: '/details',
            views: {
                'content@authenticated': {
                    template: `<template-domain-job-details
                                    job="$resolve.job"
                                ></template-domain-job-details>`
                }
            }
        }
    },
    {
        name: 'domain.domains.new',
        state: {
            data: {
                isGrantedAll: [UiRights.DOM_DOMAINS_CREATE_WITH_DEFAULTS, UiRights.DOM_CONTACTS_LIST]
            },
            params: {
                accountId: { value: null, squash: true },
                domainList: { value: null, squash: true },
                domainSearchString: { value: null, squash: true },
                domainType: { value: null, squash: true },
                selectedDomains: { value: null, squash: true },
                webspaceId: { value: null, squash: true }
            },
            resolve: {
                webspace: (
                    $stateParams: ng.ui.IStateParamsService,
                    redirectOnNotFound: RedirectOnNotFoundCallback,
                    webspaceModel: WebspaceModelService
                ) => {
                    if (AuthContextService.isGranted(UiRights.WEB_OBJECT_LIST)
                        && [undefined, null].indexOf($stateParams.webspaceId) < 0
                    ) {
                        return webspaceModel.findOne($stateParams.webspaceId).then(null, redirectOnNotFound);
                    }

                    return null;
                }
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: `<template-domain-wizard
                                webspace="$resolve.webspace"
                            ></template-domain-wizard>`
                }
            }
        }
    },
    {
        name: 'domain.domains.ids',
        state: {
            abstract: true,
            resolve: {
                selectedDomains: (domainModel: DomainModelService, $stateParams: ng.ui.IStateParamsService) => {
                    return domainModel.findById(JSON.parse($stateParams.domainIds));
                }
            },
            url: '/ids/{domainIds}'
        }
    },
    {
        name: 'domain.domains.ids.edit-bulk',
        state: {
            data: {
                isGranted: UiRights.DOM_DOMAINS_EDIT
            },
            resolve: {
                contacts: (
                    domainHandleModel: DomainHandleModelService,
                    selectedDomains
                ) => {
                    const filter = addHiddenContactsFilter({
                        field: 'AccountId',
                        value: selectedDomains[0].accountId
                    });

                    return domainHandleModel.list(undefined, undefined, filter).then((reply) => reply.data);
                },
                defaultNameservers: (
                    nameserverSetModel: NameserverSetModelService,
                    selectedDomains
                ) => {
                    return nameserverSetModel.getDefault(selectedDomains[0].accountId);
                },
                domains: (domainModel: DomainModelService) => {
                    return domainModel.list().then((domains) => domains.data);
                },
                nameservers: (
                    nameserverSetModel: NameserverSetModelService,
                    selectedDomains
                ) => {
                    const filter = {
                        field: 'AccountId',
                        value: selectedDomains[0].accountId
                    };

                    return nameserverSetModel.list(undefined, undefined, filter).then((reply) => reply.data);
                },
                subAccounts: (accountModel: AccountModelService) => {
                    return accountModel.listSubaccounts().then((reply) => reply.data);
                }
            },
            url: '/edit-bulk',
            views: {
                'content@authenticated': {
                    template: `<template-domain-edit-bulk
                        selected-domains="$resolve.selectedDomains"
                        domains="$resolve.domains"
                        sub-accounts="$resolve.subAccounts"
                        contacts="$resolve.contacts"
                        nameservers="$resolve.nameservers"
                        default-nameservers="$resolve.defaultNameservers"
                    ></template-domain-edit-bulk>`
                }
            }
        }
    },
    {
        name: 'domain.domains.ids.move-bulk',
        state: {
            data: {
                isGranted: UiRights.DOM_DOMAINS_MOVE
            },
            resolve: {
                domains: (domainModel: DomainModelService) => {
                    return domainModel.list().then(
                        (domains) => domains.data
                    );
                },
                bundles: (
                    bundleModel: BundleModelService,
                    selectedDomains: Types.DomainApi.Domain[]
                ): Types.BundleApi.Bundle[] => {
                    const bundleIds = selectedDomains
                        .filter((domain: Types.DomainApi.Domain) => !!domain.bundleId)
                        .map((domain: Types.DomainApi.Domain) => domain.bundleId);

                    if (bundleIds.length > 0) {
                        return bundleModel.findById(bundleIds) as unknown as Types.BundleApi.Bundle[];
                    }

                    return [];
                },
                subAccounts: (accountModel: AccountModelService) => {
                    return accountModel.listSubaccounts().then((res) => res.data);
                }
            },
            url: '/move-bulk',
            views: {
                'content@authenticated': {
                    template: `<template-domain-move-bulk
                        bundles="$resolve.bundles"
                        domains="$resolve.domains"
                        selected-domains="$resolve.selectedDomains"
                        sub-accounts="$resolve.subAccounts"
                    ></template-domain-move-bulk>`
                }
            }
        }
    },
    {
        name: 'domain.contacts',
        state: {
            abstract: true,
            url: '/contacts'
        }
    },
    {
        name: 'domain.contacts.new',
        state: {
            data: {
                isGranted: UiRights.DOM_CONTACTS_CREATE
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-domain-contact-new></template-domain-contact-new>'
                }
            }
        }
    },
    {
        name: 'domain.contacts.overview',
        state: {
            data: {
                isGranted: UiRights.DOM_CONTACTS_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('DomainContacts');
                },
                overviewModel: (
                    domainContactsOverviewModel: DomainContactsOverviewModel
                ) => domainContactsOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('DomainOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('DomainContacts');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-domain-contacts-overview
                        simple-filter="$resolve.simpleFilter"
                        extended-filters="$resolve.extendedFilters"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        overview-model="$resolve.overviewModel"
                    ></template-domain-contacts-overview>`
                }
            }
        }
    },
    {
        name: 'domain.contacts.id',
        state: {
            abstract: true,
            resolve: {
                contact: (
                    $stateParams: ng.ui.IStateParamsService,
                    domainHandleModel: DomainHandleModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return domainHandleModel.findOne($stateParams.contactId).then(null, redirectOnNotFound);
                }
            },
            url: '/id/{contactId}'
        }
    },
    {
        name: 'domain.contacts.id.edit',
        state: {
            data: {
                isGrantedAll: [UiRights.DOM_CONTACTS_LIST, UiRights.DOM_CONTACTS_EDIT]
            },
            resolve: {
                contactIsExtended: (authContext: AuthContextService, contact) => {
                    return authContext.account.parentAccountId === contact.accountId
                    || contact.placeholderForUnreadableSupplierContact;
                }
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-domain-contacts-edit
                        contact="$resolve.contact"
                        contact-is-extended="$resolve.contactIsExtended"
                        ></template-domain-contacts-edit>`
                }
            }
        }
    },
    {
        name: 'domain.contacts.id.copy',
        state: {
            data: {
                isGranted: UiRights.DOM_CONTACTS_CREATE
            },
            url: '/copy',
            views: {
                'content@authenticated': {
                    template: `<template-domain-contacts-edit
                        contact="$resolve.contact"
                        mode="'copy'"
                        ></template-domain-contacts-edit>`
                }
            }
        }
    },
    {
        name: 'domain.contacts.id.domains',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.DOM_DOMAINS_LIST
            },
            url: '/domains'
        }
    },
    {
        name: 'domain.contacts.id.domains.overview',
        state: {
            data: {
                isGranted: UiRights.DOM_DOMAINS_LIST
            },
            resolve: {
                extendedFilters: ($stateParams: ng.ui.IStateParamsService, filterCache: FilterCacheService) => {
                    if ($stateParams.fancyFilter !== undefined && $stateParams.fancyFilter !== '') {
                        return JSON.parse($stateParams.fancyFilter);
                    }
                    return filterCache.resolveExtendedFilters('Domain');
                },
                overviewModel: (domainOverviewModel: DomainOverviewModel) => domainOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('DomainOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('Domain');
                }
            },
            url: '/overview/{fancyFilter}',
            views: {
                'content@authenticated': {
                    template: `<template-domain-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                    contact="$resolve.contact"
                                ></template-domain-overview>`
                }
            }
        }
    },
    {
        name: 'domain.contacts.verification',
        state: {
            data: {
                isGranted: UiRights.DOM_CONTACTS_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('DomainContacts');
                },
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('DomainOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('DomainContacts');
                },
                verificationOverviewModel: (
                    domainContactsVerificationOverviewModel: DomainContactsVerificationOverviewModel
                ) => domainContactsVerificationOverviewModel
            },
            url: '/verification',
            views: {
                'content@authenticated': {
                    template: `<template-domain-contacts-verification
                        overview-model="$resolve.verificationOverviewModel"
                        simple-filter="$resolve.simpleFilter"
                        extended-filters="$resolve.extendedFilters"
                        overview-ui-settings="$resolve.overview-ui-settings"
                    ></template-domain-contacts-verification>`
                }
            }
        }
    },
    {
        name: 'domain.redirections',
        state: {
            abstract: true,
            url: '/redirections'
        }
    },
    {
        name: 'domain.redirections.overview',
        state: {
            data: {
                isGranted: UiRights.WEB_REDIRECTIONS_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('DomainRedirections');
                },
                overviewModel: (redirectionOverviewModel: RedirectionOverviewModel) => redirectionOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('DomainOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('DomainRedirections');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-redirection-overview
                        simple-filter="$resolve.simpleFilter"
                        extended-filters="$resolve.extendedFilters"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        overview-model="$resolve.overviewModel"
                    ></template-redirection-overview>`
                }
            }
        }
    },
    {
        name: 'domain.redirections.id',
        state: {
            abstract: true,
            resolve: {
                redirection: (
                    $stateParams: ng.ui.IStateParamsService,
                    redirectionModel: RedirectionModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return redirectionModel.findOne($stateParams.redirectionId).then(null, redirectOnNotFound);
                }
            },
            url: '/id/{redirectionId}'
        }
    },
    {
        name: 'domain.redirections.id.edit',
        state: {
            data: {
                isGranted: UiRights.WEB_REDIRECTIONS_CREATE
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-redirection-edit
                        redirection="$resolve.redirection"
                        ></template-redirection-edit>`
                }
            }
        }
    },
    {
        name: 'domain.redirections.new',
        state: {
            data: {
                isGranted: UiRights.WEB_REDIRECTIONS_CREATE
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-redirection-wizard></template-redirection-wizard>'
                }
            }
        }
    },
    {
        name: 'domain.transfers',
        state: {
            data: {
                isGranted: UiRights.DOM_JOBS_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('DomainTransfers');
                },
                overviewModel: (
                    domainTransferOverviewModel: DomainTransferOverviewModel
                ) => domainTransferOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('DomainOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('DomainTransfers');
                }
            },
            url: '/transfers',
            views: {
                'content@authenticated': {
                    template: `<template-domain-transfer-overview
                        simple-filter="$resolve.simpleFilter"
                        extended-filters="$resolve.extendedFilters"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        overview-model="$resolve.overviewModel"
                    ></template-domain-transfer-overview>`
                }
            }
        }
    },
    {
        name: 'domain.authinfotwo',
        state: {
            data: {
                isGranted: UiRights.DOM_DOMAINS_AUTHINFO_CREATE
            },
            url: '/authinfotwo',
            views: {
                'content@authenticated': {
                    template: '<template-domain-auth-info-two></template-domain-auth-info-two>'
                }
            }
        }
    },
    {
        name: 'domain.reports',
        state: {
            data: {
                isGranted: UiRights.DOM_DOMAINS_LIST
            },
            url: '/reports',
            views: {
                'content@authenticated': {
                    template: '<template-domain-reports></template-domain-reports>'
                }
            }
        }
    }
];
