import ng from 'angular';
import {
    ArticleSearchModelService, NavigationService
} from '@/services';

export class MoleculeHelpdeskSearchController implements ng.IController {
    public static $inject: string[] = [
        '$location',
        '$state',
        '$timeout',
        '$translate',
        'articleSearchModel',
        'navigation',
    ];

    public searchValue: string;
    public searchPredictions: any = [];
    public model: string;
    public selectedElement = -1;
    public showPredictions = true;
    public errorMessage = '';

    private _searchTimeout: any = null;

    constructor(
        public $location: ng.ILocationService,
        public $state: ng.ui.IStateService,
        public $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private articleSearchModel: ArticleSearchModelService,
        private navigation: NavigationService
    ) {}

    public $onInit() {
        this.searchValue = this.$state.params.q;
    }

    public suggest = (input: { value: string }) => {
        this.errorMessage = ''; // Reset error message
        this.selectedElement = -1;

        if (input.value.toString().length >= 2) {
            if (this._searchTimeout !== null) {
                this.$timeout.cancel(this._searchTimeout);
            }

            this._searchTimeout = this.$timeout(() => {
                this.searchPredictions = this.articleSearchModel.searchArticles(
                    input.value.toString()
                ).then(
                    (reply) => {
                        this.searchPredictions = reply.data;
                        this.getErrorMessage(reply.data);
                    }
                );
            }, 150);
        } else {
            this.searchPredictions = {};
        }
    };

    public isSelectedElement = (index: number) => {
        return (index === this.selectedElement);
    };

    public selectElement = (index: number) => {
        this.selectedElement = index;
    };

    public displayPredictions = () => {
        this.showPredictions = true;
    };

    public hidePredictions = () => {
        this.$timeout(() => {
            this.showPredictions = false;
        }, 350);
    };

    public keyNavigation = (event: any) => {
        if ([undefined, null, ''].indexOf(this.searchPredictions) < 0) {
            if (event.key === 'ArrowUp') {
                if (this.selectedElement === -1 || this.selectedElement === 0) {
                    this.selectedElement = this.searchPredictions.length - 1;
                } else {
                    this.selectedElement = this.selectedElement - 1;
                }
            } else if (event.key === 'ArrowDown') {
                if (this.selectedElement === -1 || this.selectedElement === (this.searchPredictions.length - 1)) {
                    this.selectedElement = 0;
                } else {
                    this.selectedElement = this.selectedElement + 1;
                }
            } else if (event.key === 'Enter') {
                const errorMessageExists = [undefined, null, ''].indexOf(this.errorMessage) < 0;
                const searchPredictionsExists = this.searchPredictions !== undefined && this.searchPredictions.length > 0;

                if (!errorMessageExists && searchPredictionsExists && this.selectedElement >= 0) {
                    this.$location.url('helpdesk' + this.searchPredictions[this.selectedElement].article_url);
                } else {
                    this.showPredictions = false;
                    this.goToSearch();
                }
            }
        }
    };

    public getErrorMessage = (data: any) => {
        if (data.error !== undefined) {
            switch (data.error) {
                case 'document not found.':
                    this.errorMessage = this.$translate.instant('TR_270319-abdaf9_TR');
                    break;
                default:
                    this.errorMessage = this.$translate.instant('TR_280319-d98dd7_TR');
                    break;
            }

            if (this.errorMessage.length > 0) {
                this.searchPredictions = null;
            }
        }
    };

    public goToSearch = () => {
        if (this.searchValue === '') {
            this.searchValue = null;
        }

        this.navigation.go('helpdesk.dashboard', {
            q: this.searchValue
        });
    };
}

export class MoleculeHelpdeskSearchComponent implements ng.IComponentOptions {
    public bindings = {
        searchCallback: '<',
        searchPredictions: '=',
    };
    public controller = MoleculeHelpdeskSearchController;
    public template = require('./helpdesk-search.html');
}
