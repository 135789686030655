import * as ng from 'angular';
import './panel-edit-row-toggle.scss';

export class MoleculePanelEditRowToggleController implements ng.IController {
    public static $inject: string[] = ['$transclude', '$translate'];

    public disabled: any;
    public doNotReturnValue = false;
    public hideRowTextBlock: boolean;
    public model: any;
    public nowrap: boolean;
    public toggleText: string;
    public toggleTextOff: string;
    public toggleTextOn: string;
    public labelLeft = false;

    private onChangeCallback: ({ status }) => {};
    private onChangeCallbackParams: any;

    constructor(
        private $transclude: ng.ITranscludeFunction,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = () => {
        this.hideRowTextBlock = this.hideRowTextBlock || false;
        this.toggleText = this.toggleText || '';
        this.nowrap = this.nowrap || false;

        if (this.toggleText.length === 0) {
            this.nowrap = true;
        }

        // Default to showing An/Aus if no toggle-text is set.
        this.toggleTextOn = this.toggleTextOn
            || `(${this.$translate.instant('28687262-290b-4cf8-9ddf-025808ca7134')})`;
        this.toggleTextOff = this.toggleTextOff
            || `(${this.$translate.instant('f20dbc67-c49c-4477-b36c-984b27119328')})`;
    };

    public statusChange = (callback) => {
        if (this.onChangeCallback !== undefined) {
            if (this.onChangeCallbackParams !== undefined) {
                if (this.doNotReturnValue) {
                    this.onChangeCallback(this.onChangeCallbackParams);
                    return;
                }
                const paramsKey = 'params';
                callback[paramsKey] = this.onChangeCallbackParams;
            }
            this.onChangeCallback(callback);
        }
    };

    // Im template auskommentiert
    public issetLabel = () => {
        return this.$transclude.isSlotFilled('label');
    };

    public get textBlockGiven() {
        return this.$transclude.isSlotFilled('textblock');
    }
}

export class MoleculePanelEditRowToggleComponent implements ng.IComponentOptions {
    public transclude = {
        buttons: '?rowButtons',
        inlineText: '?rowInlineText',
        label: '?rowLabel',
        textblock: '?rowTextBlock'
    };
    public bindings = {
        disabled: '<',
        doNotReturnValue: '<?',
        hideRowTextBlock: '<?',
        labelLeft: '<?',
        model: '=',
        nowrap: '<',
        onChangeCallback: '<',
        onChangeCallbackParams: '<?',
        toggleText: '@',
        toggleTextOff: '@?',
        toggleTextOn: '@?'
    };
    public controller = MoleculePanelEditRowToggleController;
    public controllerAs = '$panelRow';
    public template = require('./panel-edit-row-toggle.html');
}
