import ng from 'angular';

import {
    DataObject,
    DomainInfo,
    DomainInfoHelperService,
    DomainModelService,
    MailboxModelService,
    ValidateEmail
} from '@/services';

import './email-address-list-revised.scss';
import { ViewTypes } from '@/types';
import ApiListResponse = ViewTypes.ApiListResponse;

export class MoleculePanelEditRowEmailAddressListRevisedController implements ng.IController {
    public static $inject: string[] = ['$translate', 'domainInfoHelper', 'domainModel', 'mailboxModel'];

    // Indices for edit and removal.
    public editingAddressIndex = -1;
    public indicesMarkedForRemoval: number[] = [];
    public forwardValidation: string = '';
    public listForwardValidation: string[] = [];
    public newForwarderValue = '';
    public placeholder: string;
    public removeInstantly: boolean;
    public showRequired: boolean;
    public validationInstructions: DataObject[];

    // Internal.
    private _isOpen = false;
    private _addresses: string[] = [];
    private _addressesInternal: string[] = [];
    private _selectedProductFamily: string;

    public get isOpen(): boolean {
        return this._isOpen;
    }
    public set isOpen(value: boolean) {
        this._isOpen = value;
    }

    public get addresses(): string[] {
        return this._addresses;
    }
    public set addresses(value: string[]) {
        this._addresses = value;
    }

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private domainInfoHelper: DomainInfoHelperService,
        private domainModel: DomainModelService,
        private mailboxModel: MailboxModelService
    ) {}

    public $onInit(): void {
        this.placeholder = this.placeholder || this.$translate.instant('TR_110119-594d9a_TR');
        if ([undefined, null].indexOf(this.removeInstantly) > -1) {
            this.removeInstantly = true;
        }
        if ([undefined].indexOf(this.showRequired) >= 0) {
            this.showRequired = false;
        }
    }

    public openInputField = (): void => {
        this.isOpen = true;
    };

    public acceptInputAndClose = (): void => {
        if (this.editingAddressIndex > -1) {
            this.listForwardValidation[this.editingAddressIndex] = '';
            const editedValue = this.addresses[this.editingAddressIndex];
            const duplicates = this.addresses.filter((address) =>
                address === editedValue
            );

            if (duplicates.length > 1) {
                this.addresses.splice(this.editingAddressIndex, 1);
            }
            const validationResult = new ValidateEmail(
                false,
                this.$translate,
                this.domainInfoHelper,
                false
            ).validate(editedValue);
            if (validationResult.length > 0 ) {
                this.listForwardValidation[this.editingAddressIndex] = validationResult[0].text;
                this.closeInputField();
                return;
            }

            this.checkForInternalMailbox(editedValue);

        } else if (
            this.newForwarderValue
            && this.newForwarderValue.length > 0
            && this.addresses.indexOf(this.newForwarderValue) < 0
        ) {
            this.forwardValidation = '';
            const validationResult = new ValidateEmail(
                false,
                this.$translate,
                this.domainInfoHelper,
                false
            ).validate(this.newForwarderValue);
            if (validationResult.length > 0 ) {
                this.forwardValidation = validationResult[0].text;
                this.closeInputField();
                return;
            }
            this.addresses.push(this.newForwarderValue);
            this.checkForInternalMailbox(this.newForwarderValue);
        }
        this.cancelInputAndClose();
    };

    public checkForInternalMailbox = (newForwarder: string): void => {
        const parts = newForwarder.split('@');
        void this.domainInfoHelper.getDomainInfo(parts[1]).then((resDomainInfo: DomainInfo) => {
            void this.mailboxModel.list(
                1,
                1,
                {
                    field: 'mailboxEmailAddressUnicode',
                    value: `${parts[0]}@${resDomainInfo.domainNameUnicode}`
                }
            ).then(
                (res: ApiListResponse) => {
                    if (res && res.data && res.data.length > 0) {
                        this._addressesInternal.push(newForwarder);
                    }
                }
            );
        });
    };

    public closeInputField = (): void => {
        this.isOpen = false;
    }

    public cancelInputAndClose = (): void => {
        this.isOpen = false;
        this.newForwarderValue = '';
        this.editingAddressIndex = -1;
    };

    public deleteAddress = (email: string): void => {
        if (this.removeInstantly) {
            this.addresses = this.addresses.filter(
                (address) => address !== email
            );
            this._addressesInternal = this._addressesInternal.filter(
                (internalAddress) => internalAddress !== email
            );
        } else {
            const indexOfAddress = this.addresses.indexOf(email);
            const indexInRemovalList = this.indicesMarkedForRemoval.indexOf(indexOfAddress);
            const indexInInternalForwaderList = this._addressesInternal.indexOf(email);
            if (indexInInternalForwaderList >= 0) {
                this._addressesInternal = this._addressesInternal.filter(
                    (internalAddress) => internalAddress !== email
                );
            }
            if (indexOfAddress > -1) {
                if (indexInRemovalList < 0) {
                    this.indicesMarkedForRemoval.push(indexOfAddress);
                } else {
                    this.indicesMarkedForRemoval = this.indicesMarkedForRemoval.filter(
                        (removalIndex) => removalIndex !== this.indicesMarkedForRemoval[indexInRemovalList]
                    );
                    this.checkForInternalMailbox(email);
                }
            }
        }
    };

    public editAddress = (email: string): void => {
        this.editingAddressIndex = this._addresses.indexOf(email);
        this._addressesInternal = this._addressesInternal.filter(
            (internalAddress) => internalAddress !== this.addresses[this.editingAddressIndex]
        );
    };

    public get targetsAllInternal(): boolean {
        if (this._selectedProductFamily === 'external-forwarders' || this.addresses.length === 0) {
            return false;
        }

        return this._addressesInternal.length === this.addresses.length;
    }
    public set targetsAllInternal(val: boolean) {/* do nothing */}

    public finishEdit = (email: string): void => {
        this.checkForInternalMailbox(this._addresses[this.editingAddressIndex]);
    };
}

export class MoleculePanelEditRowEmailAddressListRevisedComponent implements ng.IComponentOptions {
    public bindings = {
        _selectedProductFamily: '<selectedProductFamily',
        addresses: '=',
        removeInstantly: '<?',
        newForwarderValue: '=?',
        placeholder: '@?',
        showRequired: '<?',
        validationErrors: '=',
        targetsAllInternal: '=?'
    };

    public transclude = {
        label: '?rowLabel'
    };

    /* tslint:disable-next-line:max-line-length */
    public template = require('./email-address-list-revised.html');
    public controller = MoleculePanelEditRowEmailAddressListRevisedController;
}
