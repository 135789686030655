import ng from 'angular';
import { PanelHeaderData } from '@/atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateWebhostingVhostOverviewController {
    public static $inject: string[] = ['$state', '$translate'];

    public pageHeaderData: PanelHeaderData;
    public overviewServiceData: any;
    public expertView: boolean;
    public isLegacyPhpOverview = false;
    public overviewModel;

    private webspace;
    private simpleFilter;
    private extendedFilters;
    private overviewUiSettings;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit(): void {
        if ([undefined, null].indexOf(this.simpleFilter) === -1) {
            this.expertView = this.overviewUiSettings.expertView || this.simpleFilter.show;
        } else {
            this.expertView = this.overviewUiSettings.expertView || false;
        }

        this.webspace = this.webspace || null;

        let panelHeaderBackwardLink: string;
        let panelHeaderBackwardText: string;
        let panelHeaderRouteParams;

        switch (this.$state.current.name.split('.')[0]) {
            case 'webhosting':
                panelHeaderBackwardText = this.webspace !== null
                    ? this.$translate.instant('TR_140119-e915b4_TR')
                    : this.$translate.instant('TR_140119-285630_TR');
                panelHeaderBackwardLink = this.webspace !== null
                    ? 'webhosting.webspaces.id.edit'
                    : 'webhosting.dashboard';
                panelHeaderRouteParams = this.webspace !== null ? {webspaceId: this.webspace.id} : null;
                break;
            case 'managed-servers':
                if (this.$state.current.name.split('.')[1] === 'id') {
                    panelHeaderBackwardLink = 'managed-servers.id.webspaces.id.edit';
                    panelHeaderBackwardText = this.$translate.instant('TR_140119-e915b4_TR');
                    panelHeaderRouteParams = {
                        machineId: this.$state.$current.locals.globals.machine.id,
                        webspaceId: this.$state.$current.locals.globals.webspace.id
                    };
                } else {
                    panelHeaderBackwardLink = 'managed-servers.id.webspaces.overview';
                    panelHeaderBackwardText = this.$translate.instant('TR_140119-e915b4_TR');
                }
                break;
        }

        this.pageHeaderData = {
            backwardLink: panelHeaderBackwardLink,
            backwardText: panelHeaderBackwardText,
            panelHeaderRoute: '',
            panelHeaderRouteParams: panelHeaderRouteParams,
            panelIcon: 'globe',
            panelTitle: this.$translate.instant('TR_080119-acb35a_TR')
        };
        this.overviewServiceData = {
            additionData: {
                webspace: this.webspace
            },
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            objectId: [undefined, null].indexOf(this.webspace) < 0 ? this.webspace.id : null,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter
        };

        if (this.isLegacyPhpOverview) {
            this.pageHeaderData.panelTitle = this.$translate.instant('TR_060123-d86d08_TR');
        }
    }

    public expandWrapper = (toggle: {expand: boolean}): void => {
        this.expertView = toggle.expand;
    };
}

export class TemplateWebhostingVhostOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<',
        webspace: '<',
        isLegacyPhpOverview: '<?'
    };
    public controller = TemplateWebhostingVhostOverviewController;
    public controllerAs = 'TemplateWebhostingVhostOverviewCtrl';
    public template = require('./vhost-overview-template.html');
}
