export const transitEnabledTlds: string[] = [
    'de',
    'at',
    'uk',
    'co.uk',
    'me.uk',
    'net.uk',
    'org.uk',
    'ltd.uk',
    'plc.uk'
];
