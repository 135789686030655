import * as ng from 'angular';
import './link-dashboard-search-results.scss';

export class MoleculeLinkDashboardSearchResultsController implements ng.IController {
    public static $inject: string[] = [];

    public dashboardOverview;
    public serviceItemList;
}

export class MoleculeLinkDashboardSearchResultsComponent implements ng.IComponentOptions {
    public bindings = {
        dashboardOverview: '<',
        serviceItemList: '<'
    };
    public controller =  MoleculeLinkDashboardSearchResultsController;
    public template = require('./link-dashboard-search-results.html');
}
