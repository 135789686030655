import * as ng from 'angular';

import { WizardObject } from '@/atomic-components/molecules/wizard/';
import { AuthContextService, ProductHelperService, ScrollToValidationError } from '@/services';

export class OrganWizardWebspaceController implements ng.IController {
    public static $inject: string[] = ['$timeout', 'authContext'];

    public $serviceObjectContainer;
    public serviceObject: WizardObject;
    public vhostPanelComplete = false;
    public accessesPanelComplete = false;
    public defaultWebpaceStorageQuota: null|number = null;
    public additionalStorageItems = 0;
    public storageItem: any = {};

    private selectedContingent;
    private _selectedQuota;

    constructor(
        private $timeout: ng.ITimeoutService,
        private authContext: AuthContextService
    ) {}

    public $onInit() {
        this._checkWebspaceSettings();
        this.selectedContingent = this.selectedContingent || null;
    }

    public $onChanges(changes: ng.IOnChangesObject): void {
        if (this.serviceObject.settings.webspace === undefined) {
            this._checkWebspaceSettings();
        }

        if (changes.selectedContingent !== undefined && changes.selectedContingent.currentValue !== undefined) {
            this.storageQuotaChanged(this._selectedQuota);
        }

        if (changes.selectedProductCode?.currentValue !== undefined) {
            if (this.serviceObject.selectedProduct.specificationItems !== undefined) {
                const specification = ProductHelperService.wrapSpecificationItems(
                    this.serviceObject.selectedProduct.specificationItems
                );

                this.defaultWebpaceStorageQuota = specification.includedStorage?.intValue;
                (
                    this.serviceObject.display as Record<string, unknown>
                ).storageTemplateProductCode = specification.storageProductCode?.value as string;

                const billingCycle = this.serviceObject.selectedProduct.selectedPrice.billingCycle;

                (
                    this.serviceObject.display as Record<string, unknown>
                ).storageProductCode = (
                    specification.storageProductCode?.value as string
                ).replace(/##BILLING_CYCLE##/gi, `${billingCycle}`);
            }

            this._checkWebspaceSettings();
            this.serviceObject.settings.webspace.productCode = changes.selectedProductCode.currentValue;
        }

        if (changes.accountId !== undefined) {
            this.serviceObject.settings.accountId = changes.accountId.currentValue;
            this.serviceObject.settings.webspace.accountId = changes.accountId.currentValue;
        }
    }

    public get webspacePanelComplete() {
        const settings = this.serviceObject.settings;

        return settings.webspace !== undefined
        && [undefined, null, ''].indexOf(settings.webspace.productCode) === -1
        && settings.webspace.name !== '';
    }

    public get showSaveButton() {
        return this.webspacePanelComplete
        && this.vhostPanelComplete
        && this.accessesPanelComplete
        && this.serviceObject.display.sufficientCreditAvailable;
    }

    public scrollToError = () => {
        ScrollToValidationError.scrollToError(this.$timeout);
    };

    public storageQuotaChanged = (quota) => {
        this._selectedQuota = quota;

        if (this.defaultWebpaceStorageQuota < quota && this.serviceObject.display.contingentType !== 'pool') {
            this.serviceObject.children = [{
                amount: (quota - this.defaultWebpaceStorageQuota) / this.storageItem.value,
                label: this.storageItem.label,
                price: this.storageItem.price,
                unit: this.storageItem.value
            }];
        } else {
            this.serviceObject.children = [];
        }
    };

    private _checkWebspaceSettings = () => {
        if (this.serviceObject.settings.accountId === undefined) {
            this.serviceObject.settings.accountId = this.authContext.account.id;
        }

        if (this.serviceObject.settings.webspace === undefined) {
            this.serviceObject.settings.webspace = {
                accesses: [],
                accountId: this.serviceObject.settings.accountId,
                name: '',
                productCode: '',
                storageQuota: ng.copy(this.defaultWebpaceStorageQuota)
            };
        }

        if (this.serviceObject.display.storageProductCode === undefined) {
            this.serviceObject.display.storageProductCode = null;
            this.serviceObject.display.storageTemplateProductCode = null;
        }
    };
}

export class OrganWizardWebspaceComponent implements ng.IComponentOptions {
    public bindings = {
        accountId: '<',
        selectedContingent: '<',
        selectedProductCode: '<',
        serviceObject: '='
    };

    public require = {
        $serviceObjectContainer: '^moleculeWizardServiceObjectContainer'
    };

    public controller =  OrganWizardWebspaceController;
    public template = require('./wizard-webspace.html');
}
