import { CycleBillingTranslationConst } from '@/configuration/system';
import * as ng from 'angular';
import { CalculatePriceFilter } from '../../../../../filters';
import { AccountModelService, AuthContextService } from '../../../../../services';
import * as Types from '../../../../../types';

export class OrganismDisplayBundleContractDetails {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'accountModel',
        'articleDetailsFilter',
        'calculatePriceFilter',
        'formatMegabyteFilter'
    ];

    public bruttoNettoUserInfo = '';
    public isNettoPrice = true;
    public bundle: Types.BundleApi.Bundle;
    public bundlePrice: any = {};
    public includedProducts: any[] = [];
    public webspace: Types.WebhostingApi.Webspace;
    public isLoading = true;
    public bundleAccount: Types.AccountApi.Account | Types.AccountApi.Subaccount;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService,
        private articleDetailsFilter,
        private calculatePriceFilter: CalculatePriceFilter,
        private formatMegabyte
    ) {}

    public $onInit = () => {
        if (this.bundle.accountId !== AuthContextService.account.id) {
            this.accountModel.findOne(this.bundle.accountId).then(
                (result) => {
                    this.bundleAccount = result;

                    if ([undefined, null].indexOf(result) < 0) {
                        this.bruttoNettoUserInfo = this.bundleAccount.isCommercialCustomer
                        ? this.$translate.instant('TR_130219-775cda_TR')
                        : this.$translate.instant('9e7d08f5-0078-49f4-b75b-71749b060953');
                    }
                }
            );
        } else {
            this.bundleAccount = AuthContextService.account;

            this.bruttoNettoUserInfo = this.bundleAccount.isCommercialCustomer
            ? this.$translate.instant('TR_130219-775cda_TR')
            : this.$translate.instant('9e7d08f5-0078-49f4-b75b-71749b060953');
        }

        // get bundle price
        this.articleDetailsFilter({
            owner: this.bundle.accountId,
            productCode: this.bundle.productCode,
            quantity: 1
        }).then(
            (priceObject: any) => {
                this.bundlePrice = priceObject;
                this.$timeout(() => {
                    this.isLoading = false;
                });
            }
        );

        // get included services
        this.bundle.effectiveContingentUsage.map(
            (product) => {
                const currentProduct = {
                    capacity: product.totalCapacity,
                    productCodes: product.productCodes
                };
                this.includedProducts.push(currentProduct);
            }
        );
    };

    public get realPrice() {
        return this.calculatePriceFilter(
            this.bundlePrice.rawPrice,
            this.bundlePrice.vatRate,
            1,
            this.isNettoPrice ? 'net' : 'gross',
            null,
            false,
            this.bundlePrice.productCode,
            this.mapTranslationContractPeriod(this.bundlePrice.contractPeriod)
        );
    }

    public mapTranslationContractPeriod = (contractPeriodTranslated: string) => {
        for (const iterator in CycleBillingTranslationConst) {
            if (iterator in CycleBillingTranslationConst) {
                if (contractPeriodTranslated === this.$translate.instant(`${CycleBillingTranslationConst[iterator]}`)) {
                    return iterator;
                }
            }
        }
        return 'none';
    };

    public get realContractPeriod() {
        return this.bundlePrice.contractPeriod;
    }

    public get isShowPrice() {
        return ['-1', -1].indexOf(this.bundlePrice.rawPrice) < 0;
    }

    public webspaceStorageQuota = (productCodes) => {
        let storageQuota = '';

        if (this.webspace?.storageQuota) {
            storageQuota = this.$translate.instant('TR_120620-f0ac62_TR')
                + ` ${this.formatMegabyte(this.webspace.storageQuota)}`;
        }

        return storageQuota;
    };
}

export class OrganismDisplayBundleContractDetailsComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<',
        webspace: '<'
    };
    public controller = OrganismDisplayBundleContractDetails;
    public template = require('./bundle-contract-details.html');
}
