import { ViewTypes } from '@/types';
import { FormDescriptionSpec } from '@/types/view-types/form-description/form-description';
import ng from 'angular';

export class FormElementInputPasswordController implements ng.IComponentController {
    public static $inject: string[] = [];

    public disabled: boolean;
    public keenInput: FormDescriptionSpec.FieldObjectPassword;
    public keenOutput: (model: boolean, key: string) => any;
    public options: ViewTypes.PasswordOptions;
    public isValid: boolean;

    private _model: boolean;
    private key: string;
    private label: string;

    public $onInit = () => {
        this.disabled = this.disabled || false;
        this.key = this.keenInput.key || '';
        this.label = this.keenInput.ui.label || '';
        if (this.keenInput.validation) {
            this.options = {
                allowOtherCharacters: this.keenInput.validation.otherCharacters,
                characterPools: this.keenInput.validation.characterClasses,
                maxLength: this.keenInput.validation.maxLength,
                minCharacterPoolsUsed: this.keenInput.validation.minClasses,
                minLength: this.keenInput.validation.minLength
            };
        }
    };

    public get model() {
        return this._model;
    }

    public set model(value: boolean) {
        if (value !== this._model) {
            this._model = value;
            this.onChangeModel();
        }
    }

    private onChangeModel = () => {
        if (!this.isValid) {
            this.disabled = true;
            return;
        }

        if (this.keenOutput) {
            this.keenOutput(this._model, this.key);
        }
    };
}

export class FormElementInputPasswordComponent implements ng.IComponentOptions {
    public bindings = {
        disabled: '<?',
        keenInput: '<',
        keenOutput: '<',
        model: '=?'
    };
    public controller = FormElementInputPasswordController;
    public template = require('./form-element-input-password.html');
}
