import * as ng from 'angular';
import { InstallerModelService } from '../../../services';
import * as Types from '../../../types';

import './domain-app-install-template.scss';

export class TemplateDomainAppInstallController {
    public static $inject: string[] = ['$state', '$translate', 'installerModel'];
    public apps: any;
    public pageHeaderData: any;
    public domain: any;
    public vHost: Types.WebhostingApi.VHost;
    public webspace: Types.WebhostingApi.Webspace;
    public bundle: Types.BundleApi.Bundle;
    public bundleId: string;
    public appId: string;
    public formCompleted: boolean;
    public databaseRessource;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        public installerModel: InstallerModelService
    ) {}

    public $onInit() {
        let panelHeaderRoute: string;
        let panelHeaderRouteParams: any;

        switch (this.$state.current.name.split('.')[0]) {
            case 'bundle':
                panelHeaderRoute = 'bundle.id.domains.overview';
                panelHeaderRouteParams = { bundleId: this.bundle.id };
                this.bundleId = this.bundle.id;
                break;
            case 'managed-servers':
                panelHeaderRoute = 'managed-servers.id.webspaces.id.vhosts.overview';
                panelHeaderRouteParams = {
                    machineId: this.$state.$current.locals.globals.machine.id,
                    webspaceId: this.$state.$current.locals.globals.webspace.id
                };
                break;
            default:
                if (!!this.webspace.bundleId !== false) {
                    this.bundleId = this.webspace.bundleId;
                }
                panelHeaderRoute = 'webhosting.webspaces.id.vhosts.overview';
                panelHeaderRouteParams = {};
                break;
        }

        this.pageHeaderData = {
            backwardLink: panelHeaderRoute,
            backwardText: this.$translate.instant('TR_110119-7424eb_TR'),
            panelHeaderRoute: panelHeaderRoute,
            panelHeaderRouteParams: panelHeaderRouteParams,
            panelIcon: 'globe',
            panelTitle: this.$translate.instant('TR_110119-fb03d5_TR', { domainName: this.vHost.domainNameUnicode })
        };
    }
}

export class TemplateDomainAppInstallComponent implements ng.IComponentOptions {
    public bindings = {
        apps: '<',
        bundle: '<',
        databaseRessource: '<?',
        domain: '<',
        vHost: '<',
        webspace: '<'
    };
    public controller = TemplateDomainAppInstallController;
    public controllerAs = '$TemplateDomainAppInstallCtrl';
    public template = require('./domain-app-install-template.html');
}
