import * as ng from 'angular';

export class OrganEditPanelRoutedNetworksController {
    public static $inject: string[] = [];

    public $editFormOrganism;
    public cancel;
    public panelRight;
}

export class OrganEditPanelRoutedNetworksComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormNetwork'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./network-routed.html');
    public controller = OrganEditPanelRoutedNetworksController;
}
