import { UIRegExp } from '@/configuration';
import * as ng from 'angular';

export class MoleculePanelEditRowPriceController implements ng.IController {
    public static $inject: string[] = [];

    public readonly: boolean;
    public disabled: boolean;
    public callbackOnChange: (arg0: any) => any;
    public callbackOnChangeParams: any;
    public placeholder: string;
    public validationErrorList: any[]; // Used to pass custom validators if type 'custom' is used
    public validationInstructions: any[]; // Used to pass custom validators if type 'custom' is used
    public validationInstructionsOperator: any; // Used to pass custom validators if type 'custom' is used
    public catchFocus: boolean;
    public currency: 'EUR' | 'TRY' | 'USD' = 'EUR';

    private decimalLength: number;
    private _model = '';

    public $onInit = () => {
        this.decimalLength = this.decimalLength || 2;
    };

    public valueSeparatorSplit(value: string): string[] {
        return value.split(UIRegExp.CurrencySeparator);
    }

    public get model(): string {
        return this._model;
    }

    public set model(value: string) {
        // check if value is number only except commas
        if (
            [undefined, null].indexOf(value) < 0
            && !isNaN((this.valueSeparatorSplit(value).join('') as unknown as number))
        ) {
            while (value.startsWith('0') && !UIRegExp.CurrencySeparator.test(value)  && value.length !== 1) {
                // slice all starting 0 if not prepending a comma
                value = value.slice(1);
            }

            this._model = this.valueSeparatorSplit(value)
                .reduce(
                    (euros, cents, index, array) => (index + 1 === array.length)
                        ? [euros, cents.slice(0, this.decimalLength)].join('.')
                        : [euros, cents].join('')
                );
        }
    }
}

export class MoleculePanelEditRowPriceComponent implements ng.IComponentOptions {
    public transclude = {
        label: 'rowLabel'
    };
    public bindings = {
        callbackOnChange: '<',
        callbackOnChangeParams: '<',
        catchFocus: '<',
        disabled: '<',
        decimalLength: '<?',
        model: '=',
        placeholder: '@',
        readonly: '<',
        validationErrorList: '<',
        validationInstructions: '<',
        validationInstructionsOperator: '<'
    };
    public controller = MoleculePanelEditRowPriceController;
    public controllerAs = '$panelRow';
    public template = require('./panel-edit-row-price.html');
}
