import * as ng from 'angular';

export class TemplateDomainLocationsController {
    public static $inject: string[] = ['$state', '$translate'];
    public vHost: any;
    public pageHeaderData: any;
    public domain: any;
    public phpIni: any;
    public webspace: any;
    public bundle: any;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        let panelHeaderRoute: string;
        let panelHeaderRouteParams = {};
        let backwardText: string = this.$translate.instant(
            /* translationId */ 'TR_110119-1d344b_TR',
            { domainName: this.vHost.domainNameUnicode }
        );

        switch (this.$state.current.name.split('.')[0]) {
            case 'bundle':
                panelHeaderRoute = 'bundle.id.domains.id.edit';
                panelHeaderRouteParams = { bundleId: this.$state.$current.locals.globals.bundle.id };
                break;
            case 'managed-servers':
                panelHeaderRoute = 'managed-servers.id.webspaces.id.vhosts.id.edit';
                panelHeaderRouteParams = {
                    machineId: this.$state.$current.locals.globals.machine.id,
                    webspaceId: this.$state.$current.locals.globals.webspace.id
                };
                backwardText = this.$translate.instant('TR_110119-7424eb_TR');
                break;
            default:
                panelHeaderRoute = 'webhosting.webspaces.id.vhosts.id.edit';
                panelHeaderRouteParams = {};
        }

        this.pageHeaderData = {
            backwardLink: panelHeaderRoute,
            backwardText: backwardText,
            panelHeaderRoute: panelHeaderRoute,
            panelHeaderRouteParams: panelHeaderRouteParams,
            panelIcon: 'globe',
            panelTitle: this.$translate.instant('TR_110119-d86b2f_TR')
        };
    }
}

export class TemplateDomainLocationsComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<',
        phpIni: '<',
        vHost: '<',
        webspace: '<'
    };
    public template = require('./domain-locations-template.html');
    public controller = TemplateDomainLocationsController;
}
