import * as ng from 'angular';

import sortBy from 'lodash.sortby';

import { ViewTypes } from '@/types';
import { MoleculeInputTextNewPasswordController } from '../../forms/form-elements/form-elements-with-validation/input-text-new-password';

export class MoleculePasswordStrengthBarController implements ng.IController {
    public static $inject: string[] = [
        '$scope',
        '$translate'
    ];
    public $inputTextNewPassword: MoleculeInputTextNewPasswordController;
    public strengthBars: ViewTypes.StrengthBar[];
    public warningMessages: string[];

    constructor(
        private $scope: ng.IScope,
        private $translate: ng.translate.ITranslateService
    ) { }

    public $onInit = () => {
        this.warningMessages = [];
        this.$scope.$watch(() => this.$inputTextNewPassword.value, this._watchValue);
        this.strengthBars = this.$inputTextNewPassword.passwordSettings.characterPools
            .map((charPool) => {
                return {
                    status: 'inactive',
                    constraintType: 'charPool',
                    constraint: charPool
                };
            });
        if (this.$inputTextNewPassword.passwordSettings.minLength) {
            this.strengthBars.push({
                status: 'inactive',
                constraintType: 'minLength',
                constraint: this.$inputTextNewPassword.passwordSettings.minLength
            });
        }
        if (this.$inputTextNewPassword.passwordSettings.requireOtherCharacters) {
            this.strengthBars.push({
                status: 'inactive',
                constraintType: 'requireOtherCharacters',
                constraint: '!@#$%^&*()_=+-[]\\|{}"\':;?/>.<,`'
            });
        }
    };

    private _watchValue = (newValue: string, _oldValue: string) => {
        this.warningMessages = [];
        if ([undefined, null, ''].indexOf(newValue) >= 0) {
            this.strengthBars.forEach((bar) => bar.status = 'inactive');
            return;
        }
        this._addInputDependentContraints(newValue);
        this.strengthBars.forEach((bar) => {
            bar.status = this._barUsed(newValue, bar);
        });
        if (this.$inputTextNewPassword.passwordSettings?.minCharacterPoolsUsed) {
            const activeCharPool = this.strengthBars
                .filter((bar) => bar.constraintType === 'charPool' && bar.status === 'active');
            if (activeCharPool.length >= this.$inputTextNewPassword.passwordSettings?.minCharacterPoolsUsed) {
                this.strengthBars.forEach((bar) => {
                    if (['charPool'].indexOf(bar.constraintType) >= 0 && bar.status === 'error') {
                        this.warningMessages.push(
                            `${this.$translate.instant('TR_260820-e33951_TR')}: ${bar.constraint}`
                        );
                        bar.status = 'warning';
                    }
                });
            }
        }
        this.strengthBars = sortBy(this.strengthBars, (bar) => {
            switch (bar.status) {
                case 'active':
                    return 1;
                case 'warning':
                    return 2;
                case 'error':
                default:
                    return 3;
            }
        });
    };

    private _addInputDependentContraints = (newValue: string) => {
        if (
            this.$inputTextNewPassword.passwordSettings?.maxLength
            && !this.strengthBars.some((bar) => bar.constraintType === 'maxLength')
            && this.$inputTextNewPassword.passwordSettings.maxLength < newValue.length
        ) {
            this.strengthBars.push({
                status: 'error',
                constraintType: 'maxLength',
                constraint: this.$inputTextNewPassword.passwordSettings.maxLength
            });
        } else if (
            [undefined, null, ''].indexOf(newValue) >= 0
        ) {
            this.strengthBars = this.strengthBars.filter((bar) => bar.constraintType !== 'maxLength');
        }
    };

    private _barUsed = (value: string, bar: ViewTypes.StrengthBar) => {
        const chars = value.split('');
        switch (bar.constraintType) {
            case 'charPool':
            case 'requireOtherCharacters':
                return chars.some((char) => (bar.constraint as string).indexOf(char) >= 0) ? 'active' : 'error';
            case 'maxLength':
                return chars.length <= (bar.constraint as number) ? 'active' : 'error';
            case 'minLength':
                return chars.length >= (bar.constraint as number) ? 'active' : 'error';
            default:
                return 'error';
        }
    };
}

export class MoleculePasswordStrengthBarComponent implements ng.IComponentOptions {
    bindings = {
    };
    public require = {
        $inputTextNewPassword: '^moleculeInputTextNewPassword'
    };
    public controller = MoleculePasswordStrengthBarController;
    public template = require('./password-strength-bar.html');
}

