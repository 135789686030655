import * as ng from 'angular';

export class MoleculeButtonLockController implements ng.IController {
    public static $inject: string[] = [];

    public lock: () => void;
    public modifier = '';
    public disabled = false;
}

export class MoleculeButtonLockComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        disabled: '<',
        lock: '<',
        modifier: '@?'
    };
    public controller =  MoleculeButtonLockController;
    public template = require('./button-lock.html');
}
