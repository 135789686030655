// Bundle / vhost
import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';

export class TemplateDomainEditController {
    public static $inject: string[] = ['$state', '$translate'];

    public modifier = '';
    public pageHeaderData: PanelHeaderData;

    public bundle: any;
    public domain: any;
    public webspace: any;
    public vHost: any;
    public userPanelRight: any;
    public profiles: any[];
    public phpConfigurationMetaData: any[];
    public phpIniList: any[];
    public phpVersions: any[];
    public showObjectId: boolean;
    public domainNameUnicode: string;

    public faqArticleIdList: string[] = [
        'webspace-create-subdomain',
        'webspace-change-php-version',
        'ssl-activate'
    ];

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = () => {
        const vHost = [undefined, null].indexOf(this.vHost) === -1 ? this.vHost : {
            domainName: this.domain.name,
            domainNameUnicode: this.domain.nameUnicode
        };

        this.domainNameUnicode = vHost.domainNameUnicode;

        let backwardLink: string;
        let routeParams = {};

        switch (this.$state.current.name.split('.')[0]) {
            case 'bundle':
                backwardLink = 'bundle.id.domains.overview';
                routeParams = { bundleId: this.$state.$current.locals.globals.bundle.id };
                break;
            case 'managed-servers':
                backwardLink = 'managed-servers.id.webspaces.id.vhosts.overview';
                routeParams = {
                    machineId: this.$state.$current.locals.globals.machine.id,
                    webspaceId: this.$state.$current.locals.globals.webspace.id
                };
                break;
            default:
                backwardLink = 'webhosting.webspaces.id.vhosts.overview';
        }

        this.pageHeaderData = {
            backwardLink: backwardLink,
            backwardText: this.$translate.instant('TR_110119-7424eb_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: routeParams,
            panelIcon: 'globe',
            panelTitle: vHost.domainNameUnicode
        };

        this.userPanelRight = {
            appInstall: {
                editPanelButton: [undefined, null].indexOf(this.vHost) < 0 && this.vHost.status === 'active'
            },
            delete: {
                editPanelButton: true
            },
            dirProtection: {
                editPanelButton: true // AuthContextService.isGranted(UiRights.DOM_DOMAINS_EDIT)
            },
            general: {
                editPanelButton: true // AuthContextService.isGranted(UiRights.DOM_DOMAINS_EDIT)
            },
            locations: {
                editPanelButton: true
            },
            restore: {
                editPanelButton: AuthContextService.isGrantedAny([UiRights.WEB_VHOST_RESTORE])
            },
            phpOptions: {
                editPanelButton: true // AuthContextService.isGranted(UiRights.DOM_DOMAINS_EDIT)
            },
            ssl: {
                editPanelButton: true
            }
        };
    };
}

export class TemplateDomainEditComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<?',
        domain: '<',
        domainType: '<',
        phpConfigurationMetaData: '<',
        phpIniDefault: '<',
        phpIniList: '<',
        phpVersions: '<',
        profiles: '<',
        showObjectId: '<',
        vHost: '<',
        webspace: '<'
    };

    public controller = TemplateDomainEditController;
    public controllerAs = '$TemplateDomainEditCtrl';
    public template = require('./domain-edit-template.html');
}
