import ng from 'angular';
import {
    NavigationService
} from '@/services';

export const REDIRECT_ROUTES = [
    {
        name: 'fund-deposit',
        state: {
            data: {
                fundDeposit: true // Shortcut to make fund-deposit editable (currently edit-only)
            },
            onEnter: (navigation: NavigationService) => {
                void navigation.go('billing.payment-options');
            },
            parent: 'authenticated',
            url: '/fund-deposit' /* Used in E-Mails
                Do not change the name of "fund-deposit"
                Nur in Absprache mit Michi zu ändern */
        }
    },
    {
        name: 'change-billing-model',
        state: {
            onEnter: (navigation: NavigationService) => {
                void navigation.go('billing.payment-options');
            },
            parent: 'authenticated',
            url: '/change-billing-model' /* Used in E-Mails
                Do not change the name of "change-billing-model"
                Nur in Absprache mit Michi zu ändern */
        }
    },
    {
        name: 'redirect',
        state: {
            abstract: true,
            url: '/redirect'
        }
    },
    {
        name: 'redirect.fund-deposit',
        state: {
            data: {
                fundDeposit: true // Shortcut to make fund-deposit editable (currently edit-only)
            },
            onEnter: (navigation: NavigationService) => {
                void navigation.go('billing.payment-options');
            },
            url: '/fund-deposit' /* Used in E-Mails
                Do not change the name of "fund-deposit"
                Nur in Absprache mit Michi zu ändern */
        }
    },
    {
        name: 'redirect.change-billing-model',
        state: {
            onEnter: (navigation: NavigationService) => {
                void navigation.go('billing.payment-options');
            },
            url: '/change-billing-model' /* Used in E-Mails
                Do not change the name of "change-billing-model"
                Nur in Absprache mit Michi zu ändern */
        }
    },
    {
        name: 'redirect.password-reset',
        state: {
            data: {
                authenticate: false
            },
            onEnter: (navigation: NavigationService, $stateParams: ng.ui.IStateParamsService) => {
                void navigation.go('password-reset', $stateParams);
            },
            url: '/password-reset?email&code&validUntil'
        }
    },
    // certificate redirect
    {
        name: 'redirect.certificate-id',
        state: {
            onEnter: (
                $stateParams: ng.ui.IStateParamsService,
                navigation: NavigationService
            ) => {
                void navigation.go('ssl.certificates.id.edit', {
                    certificateId: $stateParams.redirectCertificateId
                });
            },
            url: '/certificate/{redirectCertificateId}'
        }
    },
    // storage redirect
    {
        name: 'redirect.storage-id',
        state: {
            onEnter: (
                $stateParams: ng.ui.IStateParamsService,
                navigation: NavigationService
            ) => {
                void navigation.go('storage.storage-products.id.edit', {
                    storageProductId: $stateParams.redirectStorageProductId
                });
            },
            url: '/storage/{redirectStorageProductId}'
        }
    },
    // nextcloud redirect
    {
        name: 'redirect.nextcloud-id',
        state: {
            onEnter: (
                $stateParams: ng.ui.IStateParamsService,
                navigation: NavigationService
            ) => {
                void navigation.go('nextcloud.id.edit', {
                    storageProductId: $stateParams.redirectNextcloudId
                });
            },
            url: '/nextcloud/{redirectNextcloudId}'
        }
    },
    // webhosting redirect
    {
        name: 'redirect.webhosting-id',
        state: {
            onEnter: (
                $stateParams: ng.ui.IStateParamsService,
                navigation: NavigationService
            ) => {
                void navigation.go('webhosting.webspaces.id.edit', {
                    webspaceId: $stateParams.redirectWebspaceId
                });
            },
            url: '/webspace/{redirectWebspaceId}'
        }
    },
    // vhost redirect
    {
        name: 'redirect.webhosting-id.vhost-id',
        state: {
            onEnter: (
                $stateParams: ng.ui.IStateParamsService,
                navigation: NavigationService
            ) => {
                void navigation.go('webhosting.webspaces.id.vhosts.id.edit', {
                    webspaceId: $stateParams.redirectWebspaceId,
                    vHostId: $stateParams.redirectvHostId
                });
            },
            url: '/domains/{redirectvHostId}'
        }
    },
    // network redirect
    {
        name: 'redirect.network-id',
        state: {
            onEnter: (
                $stateParams: ng.ui.IStateParamsService,
                navigation: NavigationService
            ) => {
                void navigation.go('network.networks.id.edit', {
                    networkId: $stateParams.redirectNetworkId
                });
            },
            url: '/network/{redirectNetworkId}'
        }
    },
    // virtualmachines redirect
    {
        name: 'redirect.virtualmachine-id',
        state: {
            onEnter: (
                $stateParams: ng.ui.IStateParamsService,
                navigation: NavigationService
            ) => {
                void navigation.go('machine.virtualmachines.id.edit', {
                    machineId: $stateParams.redirectMachineId
                });
            },
            url: '/virtualmachine/{redirectMachineId}'
        }
    },
    // mailbox redirect
    {
        name: 'redirect.mailbox-id',
        state: {
            onEnter: (
                $stateParams: ng.ui.IStateParamsService,
                navigation: NavigationService
            ) => {
                void navigation.go('email.mailboxes.id.edit', {
                    mailboxId: $stateParams.redirectMailboxId
                });
            },
            url: '/mailbox/{redirectMailboxId}'
        }
    },
    // domain redirect
    {
        name: 'redirect.domain-id',
        state: {
            onEnter: (
                $stateParams: ng.ui.IStateParamsService,
                navigation: NavigationService
            ) => {
                void navigation.go('domain.domains.id.edit', {
                    domainId: $stateParams.redirectDomainId
                });
            },
            url: '/domain/{redirectDomainId}'
        }
    },
    // dns redirect
    {
        name: 'redirect.dns-id',
        state: {
            onEnter: (
                $stateParams: ng.ui.IStateParamsService,
                navigation: NavigationService
            ) => {
                void navigation.go('dns.zones.id.edit', {
                    zoneId: $stateParams.redirectZoneId
                });
            },
            url: '/dns/{redirectZoneId}'
        }
    },
    // database redirect
    {
        name: 'redirect.database-id',
        state: {
            onEnter: (
                $stateParams: ng.ui.IStateParamsService,
                navigation: NavigationService
            ) => {
                void navigation.go('database.databases.id.edit', {
                    databaseId: $stateParams.redirectDatabaseId
                });
            },
            url: '/database/{redirectDatabaseId}'
        }
    },
    // bundle redirect
    {
        name: 'redirect.bundle-id',
        state: {
            onEnter: (
                $stateParams: ng.ui.IStateParamsService,
                navigation: NavigationService
            ) => {
                void navigation.go('bundle.id.dashboard', {
                    bundleId: $stateParams.redirectBundleId
                });
            },
            url: '/bundle/{redirectBundleId}'
        }
    }
];
