import ng from 'angular';
import { LinkListItem } from '@/atomic-components';
import { ProductAction } from '@/atomic-components/organisms/page-header-panel/page-header-panel';
import { AuthContextService, UserSettingsManagerService } from '@/services';
import './panel-header.scss';

export interface PanelHeaderData {
    allProductsQuantity?: number;
    backwardLink?: string;
    backwardText?: string;
    includeShowAllLink?: boolean;
    includeShowDetailsLink?: boolean;
    modifier?: string;
    pageHeaderType?: string;
    panelHeaderAllRoute?: string;
    panelHeaderAllRouteParams?: any;
    panelHeaderRoute: string;
    panelHeaderRouteParams?: any;
    panelIcon: string;
    panelIconPath?: boolean;
    panelItems?: LinkListItem[];
    panelTitle: string;
    panelType?: string;
    productActions?: ProductAction[];
    reloadState?: boolean;
    utilityStatus?: unknown;
}

export class MoleculePanelHeaderController implements ng.IController {
    public static $inject: string[] = ['userSettingsManager'];

    public allProductsQuantity: number;
    public panelHeaderAllRoute: string;
    public panelHeaderAllRouteParams: { [key: string]: unknown };
    public panelHeaderRoute: string;
    public panelHeaderRouteParams: { [key: string]: unknown };
    public panelIcon: string;
    public panelTitle: string;
    public modifier: string;
    public includeShowAllLink = false;
    public includeShowDetailsLink = false;
    public showDetailsText: string;
    public backwardLink: string;
    public backwardText: string;
    public linkAreaModifier: string;
    public infoText: string;
    public reloadState: boolean;
    public subHeadline: string;
    public expertViewSetting: boolean;

    constructor(
        private userSettingsManager: UserSettingsManagerService
    ) {}

    public $onInit(): void {
        if (this.includeShowAllLink) {
            if (!this.panelHeaderAllRoute) {
                this.panelHeaderAllRoute = this.panelHeaderRoute;
            }

            if (!this.panelHeaderAllRouteParams) {
                this.panelHeaderAllRouteParams = this.panelHeaderRouteParams || {};
            }

            if (
                (this.backwardLink !== undefined && this.backwardLink.length > 0)
                || this.includeShowDetailsLink
            ) {
                this.linkAreaModifier = '--expanded';
            }
        }

        // don't remove the authenticated check! The header is also used in the 2fa window, and then the
        // "not loged in" error get's cached
        if (AuthContextService.authenticated) {
            void this.userSettingsManager.getUiDisplaySettings().then((uiSettings) => {
                this.expertViewSetting = uiSettings?.expertViewSetting;

                if (uiSettings?.expertViewSetting) {
                    this.includeShowDetailsLink = !this.expertViewSetting;
                    this.includeShowAllLink = !this.expertViewSetting;
                }
            });
        }
    }

    public get showProductQuatity(): boolean {
        return this.allProductsQuantity
            && this.allProductsQuantity > 0;
    }

    public hasPanelRoute(): boolean {
        return ['', undefined, null].indexOf(this.panelHeaderRoute) < 0;
    }

    public get issetInfoText(): boolean {
        return [undefined, null, ''].indexOf(this.infoText) < 0;
    }
}

export class MoleculePanelHeaderComponent implements ng.IComponentOptions {
    public bindings = {
        allProductsQuantity: '<?',
        backwardLink: '@',
        backwardText: '@',
        includeShowAllLink: '<',
        includeShowDetailsLink: '<',
        infoText: '@',
        modifier: '@',
        panelHeaderAllRoute: '@?',
        panelHeaderAllRouteParams: '<?',
        panelHeaderRoute: '@',
        panelHeaderRouteParams: '<',
        panelIcon: '@',
        panelIconPath: '<?',
        panelTitle: '@',
        reloadState: '<',
        showDetailsText: '@',
        subHeadline: '@?'
    };
    public controller =  MoleculePanelHeaderController;
    public template = require('./panel-header.html');
}
