import * as ng from 'angular';

export class MoleculeButtonDeactivateController implements ng.IController {
    public static $inject: string[] = [];

    public disabled: boolean;
    public deactivate: () => void;
}

export class MoleculeButtonDeactivateComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        deactivate: '<',
        disabled: '<'
    };
    public controller =  MoleculeButtonDeactivateController;
    public template = require('./button-deactivate.html');
}
