import * as ng from 'angular';
import './banner.scss';

export class MoleculeHeadlineBannerController implements ng.IController {
    public static $inject: string[] = [];
    public color: 'blue' | 'green' | 'red';
    public size: 'medium' | 'large';
}

export class MoleculeHeadlineBannerComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        color: '@',
        size: '@'
    };
    public controller =  MoleculeHeadlineBannerController;
    public template = require('./banner.html');
}
