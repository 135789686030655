export class UtilHelperService {
    static sortByProperty(objectA: object, objectB: object, properyName: string) {
        const valueA = objectA[properyName].toUpperCase();
        const valueB = objectB[properyName].toUpperCase();
        if (valueA < valueB) {
            return -1;
        }
        if (valueA > valueB) {
            return 1;
        }
        return 0;
    }

    /**
     * @name getValueAtPathOfObject
     * @description Gets the value at path of object. Also known as magic wizardry.
     *
     * @param obj The object to query
     * @param path The path of the property to get
     * @param defaultValue The value returned for undefined resolved values.
     *
     * @source https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_get
     *
     * @note Only use when the path is not static.
     */
    static getValueAtPathOfObject = (obj: unknown, path: string, defaultValue?): string => {
        const travel = (regexp: RegExp) =>
            path.split(regexp)
                .filter(Boolean)
                .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
        const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
        return result === undefined || result === obj ? defaultValue : result;
    };
}
