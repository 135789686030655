import ng from 'angular';
import './pui-with-version.scss';

class AtomPuiWithVersionController implements ng.IController {
    public static $inject: string[] = [];

    public version = __VERSION__;
    public versionBuild = __VERSION_BUILD__;
    public versionDate = __VERSION_DATE__;
}

export class AtomPuiWithVersionComponent implements ng.IComponentOptions {
    public controller = AtomPuiWithVersionController;
    public template = require('./pui-with-version.html');
}
