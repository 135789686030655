import * as ng from 'angular';
import { ProductConfigManagedServerProjectObject } from '..';
import { ManagedServerProjectComposerService } from '../../../../../services';
import * as Types from '../../../../../types';

export class OrganismManagedProjectWizardWrapperController {
    public static $inject: string[] = ['$timeout', 'managedServerProjectComposer'];

    public machine;
    public ressourceServers;
    public accountEmailInUse = false;

    public wizardComposerObject: Types.ViewTypes.WizardComposerObject = {
        apiObject: {}
    };

    public viewType = 'config';

    constructor(
        private $timeout: ng.ITimeoutService,
        private managedServerProjectComposer: ManagedServerProjectComposerService
    ) {}

    public finishConfigCallback = (metadata: ProductConfigManagedServerProjectObject) => {
        this.managedServerProjectComposer.buildManagedProjectApiObject(metadata).then((res) => {
            // we do not need here a summary page!
            this.$timeout(() => {
                this.viewType = 'confirm';
                this.wizardComposerObject = res;
            });
        });
    };
}

export class OrganismManagedProjectWizardWrapperComponent implements ng.IComponentOptions {
    public bindings = {
        machine: '<',
        ressourceServers: '<'
    };
    public template = require('./managed-project-wizard-wrapper.html');
    public controller = OrganismManagedProjectWizardWrapperController;
}
