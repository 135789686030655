import * as ng from 'angular';
import { MoleculeFormEditController } from '@/atomic-components';

export class OrganEditPanelDomainGeneralController {
    public static $inject: string[] = [];

    public $editForm: MoleculeFormEditController;
    public cancel;
    public $editFormOrganism;
    public panelRight;
    public showObjectId: boolean;
}

export class OrganEditPanelDomainGeneralComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<',
        showObjectId: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormDomain',
        $editForm: '^moleculeFormEdit'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./domain-general.html');
    public controller = OrganEditPanelDomainGeneralController;
}
