import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import { CalculatePriceFilter } from '../../../../../filters';
import {
    AuthContextService,
    DateWrapper,
    DomainHelper,
    DomainModelService,
    NavigationService,
    PriceCacheService,
    PriceHelperService
} from '../../../../../services';

export class OrganEditPanelDomainProductRestoreController {
    public static $inject: string[] = [
        '$filter',
        '$translate',
        'authContext',
        'calculatePriceFilter',
        'domainModel',
        'navigation',
        'priceCache',
        'priceHelper'
    ];

    public domain: any;
    public $editFormMolecule;
    public restoreConfirm = false;
    public bundle = undefined;
    public restorePanelRight: any = {};

    private restorePrice;
    private renewPrice;

    constructor(
        private $filter: ng.IFilterService,
        protected $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private calculatePriceFilter: CalculatePriceFilter,
        private domainModel: DomainModelService,
        private navigation: NavigationService,
        private priceCache: PriceCacheService,
        private priceHelper: PriceHelperService
    ) {
        Object.defineProperty(
            this.restorePanelRight,
            'editPanelButton',
            {
                get: () => this.authContext.isGranted(UiRights.DOM_DOMAINS_RESTORE) && !this.bundleIsDeleted
            }
        );
    }

    public $onInit() {
        this.$editFormMolecule.restoreCallback = this.restore;
        this.setRestoreAndRenewPrice();
    }

    public get bundleIsDeleted() {
        return [undefined, null].indexOf(this.bundle) < 0 && this.bundle.status === 'restorable';
    }

    public restore = () => {
        this.domainModel.restore(this.domain.name).then(
            (reply) => {
                this.navigation.go('domain.domains.overview');
            }
        );
    };

    public cancel = () => {
        this.restoreConfirm = false;
    };

    public get totalPriceInfo() {
        if (
            this.domain.productCode !== undefined
            && DomainHelper.isPremiumDomain(this.domain.productCode)
        ) {
            return false;
        }
        let price = this.restorePrice.price;
        const vatRate = this.restorePrice.vatRate;
        const renewOn = new DateWrapper(new Date(this.domain.renewOn));
        const now = new DateWrapper(new Date());

        if (!(price > 0)) {
            return this.$translate.instant('TR_110119-2a2c5a_TR');
        }

        if (renewOn.toUnixTimestamp() <= now.toUnixTimestamp() && this.domain.bundleId === '') {
            price = price + this.renewPrice.price;
        }

        return this.$filter('currency')(
            this.calculatePriceFilter(
                price,
                vatRate,
                1,
                'gross',
                undefined,
                false,
                this.restorePrice.productCode,
                0
            ),
            this.priceHelper.getProductCurrency(this.restorePrice)
        );
    }

    public get restorePriceInfo() {
        if (
            this.domain.productCode !== undefined
            && DomainHelper.isPremiumDomain(this.domain.productCode)
        ) {
            return false;
        }
        const price = this.restorePrice.price;
        const vatRate = this.restorePrice.vatRate;

        if (!(price > 0)) {
            return this.$translate.instant('TR_110119-2a2c5a_TR');
        }

        return this.$filter('currency')(
            this.calculatePriceFilter(
                price,
                vatRate,
                1,
                'gross',
                undefined,
                false,
                this.restorePrice.productCode,
                0
            ),
            this.priceHelper.getProductCurrency(this.restorePrice)
        );
    }

    public get renewPriceInfo() {
        const renewOn = new DateWrapper(new Date(this.domain.renewOn));
        const now = new DateWrapper(new Date());
        if (
            (
                this.domain.productCode !== undefined
                && DomainHelper.isPremiumDomain(this.domain.productCode)
            )
            || (now.toUnixTimestamp() < renewOn.toUnixTimestamp())
            || this.domain.bundleId !== ''
        ) {
            return false;
        }
        const renewPrice = this.renewPrice.price;
        const vatRate = this.restorePrice.vatRate;

        if (!(renewPrice > 0)) {
            return this.$translate.instant('TR_110119-2a2c5a_TR');
        }

        return this.$filter('currency')(
            this.calculatePriceFilter(
                renewPrice,
                vatRate,
                1,
                'gross',
                undefined,
                false,
                this.renewPrice.productCode,
                0
            ),
            this.priceHelper.getProductCurrency(this.restorePrice)
        );
    }

    public get vatInfoText() {
        if (AuthContextService.account.isCommercialCustomer) {
            return this.$translate.instant('TR_231219-383755_TR');
        } else {
            return this.$translate.instant('TR_231219-e28c9a_TR');
        }
    }

    private setRestoreAndRenewPrice() {
        const tld = DomainHelper.getTld(this.domain.name);
        this.restorePrice = { price: null, vatRate: null, currency: 'eur', code: 'domain-' + tld + '-restore' };
        this.renewPrice = { price: null, vatRate: null, currency: 'eur', code: 'domain-' + tld + '-renew' };
        this.priceCache.listDomainPrices(tld).then(
            (result) => {
                result.map(
                    (product) => {
                        if (product.productCode === this.restorePrice.code) {
                            this.restorePrice.price = product.price;
                            this.restorePrice.vatRate = product.vatRate;
                            this.restorePrice.currency = product.currency;
                        }
                        if (product.productCode === this.renewPrice.code) {
                            this.renewPrice.price = product.price;
                            this.renewPrice.vatRate = product.vatRate;
                            this.renewPrice.currency = product.currency;
                        }
                    }
                );
            }
        );
    }
}

export class OrganEditPanelDomainProductRestoreComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<',
        domain: '<'
    };
    public require = {
        $editFormMolecule: '^moleculeFormEdit'
    };
    public controller = OrganEditPanelDomainProductRestoreController;
    public template = require('./domain-product-restore.html');
}
