import * as ng from 'angular';

export class OrganismEditFormProfileController implements ng.IController {
    public static $inject: string[] = [];

    public overviewUiSettings: any;
    public overviewUiDisplaySettings: any;
}

export class OrganismEditFormProfileComponent implements ng.IComponentOptions {
    public bindings = {
        overviewUiDisplaySettings: '<',
        overviewUiSettings: '<'
    };
    public controller = OrganismEditFormProfileController;
    public template = require('./profile.html');
}
