import * as ng from 'angular';
import * as Types from '../../../../types';

export class MoleculePanelServiceInfoZoneSystemNameserverController implements ng.IController {
    public static $inject: string[] = ['$state'];

    public zoneRecords: Types.DnsApi.Record[] = [];
    public nsRecords: Types.DnsApi.Record[] = [];

    public constructor(
        private $state: ng.ui.IStateService
    ) {}

    public $onInit() {
        this.nsRecords = this.zoneRecords.filter((record: Types.DnsApi.Record) => {
            const dnsZone = this.$state?.$current?.locals?.globals?.zone || {};
            return (record.name === dnsZone.name || record.name === dnsZone.nameUnicode)
                && record.type.toLowerCase() === 'ns';
        });
    }
}

export class MoleculePanelServiceInfoZoneSystemNameserverComponent implements ng.IComponentOptions {
    public bindings = {
        zoneRecords: '<'
    };
    public controller = MoleculePanelServiceInfoZoneSystemNameserverController;
    public controllerAs = '$panelServiceInfo';
    public template = require('./panel-service-info-zone-system-nameserver.html');
}
