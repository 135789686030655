import { FileSaverService } from '../../../../services';

import * as ng from 'angular';

export class MoleculeButtonTextDownloadController implements ng.IController {
    public static $inject: string[] = ['fileSaver'];

    public text = '';
    public filename: string;

    constructor(
        private fileSaver: FileSaverService
    ) {}

    public download = () => {
        this.fileSaver.saveAs(
            new Blob([this.text], {type: 'text/plain'}),
            this.filename + '.txt'
        );
    };
}

export class MoleculeButtonTextDownloadComponent implements ng.IComponentOptions {
    public controller =  MoleculeButtonTextDownloadController;

    public bindings = {
        filename: '@',
        text: '@'
    };
    public template = require('./button-text-download.html');
}
