import { LinkListItem } from '@/atomic-components';
import * as ng from 'angular';

export class MoleculePanelNextcloudAccessHelpController {
    public static $inject: string[] = ['$translate'];

    public linkList: LinkListItem[] = [
        {
            route: 'helpdesk',
            routeParams: {
                level1: 'produkte',
                level2: 'nextcloud',
                level3: 'einrichtung',
                level4: 'nextcloud-desktop-windows'
            },
            target: '_blank',
            text: this.$translate.instant('TR_110919-25d692_TR')
        },
        {
            route: 'helpdesk',
            routeParams: {
                level1: 'produkte',
                level2: 'nextcloud',
                level3: 'einrichtung',
                level4: 'nextcloud-ios'
            },
            target: '_blank',
            text: this.$translate.instant('TR_110919-ae64f3_TR')
        },
        {
            route: 'helpdesk',
            routeParams: {
                level1: 'produkte',
                level2: 'nextcloud',
                level3: 'einrichtung',
                level4: 'nextcloud-desktop-macos'
            },
            target: '_blank',
            text: this.$translate.instant('TR_110919-91bcf3_TR')
        },
        {
            route: 'helpdesk',
            routeParams: {
                level1: 'produkte',
                level2: 'nextcloud',
                level3: 'einrichtung',
                level4: 'nextcloud-android'
            },
            target: '_blank',
            text: this.$translate.instant('TR_110919-cd343f_TR')
        },
        {
            route: 'helpdesk',
            routeParams: {
                level1: 'produkte',
                level2: 'nextcloud',
                level3: 'einrichtung',
                level4: 'nextcloud-desktop-linux'
            },
            target: '_blank',
            text: this.$translate.instant('TR_110919-6d2026_TR')
        },
        {
            route: 'helpdesk',
            routeParams: {
                level1: 'produkte',
                level2: 'nextcloud'
            },
            target: '_blank',
            text: this.$translate.instant('TR_110919-b3caf7_TR')
        }
    ];

    constructor(private $translate: ng.translate.ITranslateService) {}
}

export class MoleculePanelNextcloudAccessHelpComponent implements ng.IComponentOptions {
    public controller = MoleculePanelNextcloudAccessHelpController;
    public template = require('./panel-nextcloud-access-help.html');
}
