import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import { ApiValue, CalculatePriceFilter } from '../../../../../filters';
import {
    AuthContextService,
    DepositModelService,
    PriceHelperService
} from '../../../../../services';
import { WizardHelperService } from '../../../../molecules/wizard/';
import { OrganismCreateBundleController } from './';

export class OrganismCreateBundleSummaryController {
    public static $inject: string[] = [
        'authContext',
        'calculatePriceFilter',
        'depositModel',
        'priceHelper',
        'wizardHelper'
    ];

    public serviceObject: any;
    public $createOrganism: OrganismCreateBundleController;
    public inContingent = false;
    public totalPrices: any[];
    public totalCost = 0;
    public panelInitialed = {
        // tslint:disable:no-empty
        webspace: ()    => {}
        // tslint:enable:no-empty
    };

    private depositInfo: any;
    private _taxType: string;

    constructor(
        private authContext: AuthContextService,
        private calculatePriceFilter: CalculatePriceFilter,
        private depositModel: DepositModelService,
        private priceHelper: PriceHelperService,
        private wizardHelper: WizardHelperService
    ) {}

    public $onInit() {
        this.getDepositInfo();
        this.totalPrices = this.getTotalPrice();
        this._setTaxType();
    }

    public get bundleCost() {
        const account = this.authContext.account.id === '1'
            ? this.serviceObject.settings.account
            : this.authContext.account;
        return this.wizardHelper.calculateProductPrice(
            this.serviceObject.selectedProduct.prices,
            account
        );
    }

    public get bundleCurrency() {
        return this.wizardHelper.getProductCurrency(this.serviceObject.selectedProduct.prices[0].price);
    }

    public get allPanelsInitialized() {
        let initialized = true;
        for (const panel in this.panelInitialed) {
            if (!this.panelInitialed[panel]) {
                initialized = false;
            }
        }
        return initialized;
    }

    public get domainCost() {
        if ([undefined, null].indexOf(this.serviceObject.settings.createData.domain) >= 0
            || [undefined, null].indexOf(this.serviceObject.settings.createData.domain.prices[0].price) >= 0
        ) {
            return null;
        }

        this.inContingent = false;
        if (this.serviceObject.settings.createData.domain.prices[0].price.price === null) {
            this.inContingent = true;
            return;
        } else if (this.serviceObject.settings.createData.domain.prices[0].price.price === -1) {
            this.inContingent = false;
        }

        const account = this.authContext.account.id === '1'
            ? this.serviceObject.settings.account
            : this.authContext.account;

        return this.wizardHelper.calculateProductPrice(
            this.serviceObject.settings.createData.domain.prices,
            account
        );
    }

    public get domainCurrency() {
        if ([undefined, null].indexOf(this.serviceObject.settings.createData.domain) >= 0
            || [null, -1].indexOf(this.serviceObject.settings.createData.domain.prices[0].price.price) >= 0
        ) {
            return;
        }

        return this.wizardHelper.getProductCurrency(this.serviceObject.settings.createData.domain.prices[0].price);
    }

    public get orderDomain() {
        if (this.serviceObject === undefined
            || this.serviceObject.settings === undefined
            || this.serviceObject.settings.createData === undefined
            || this.serviceObject.settings.createData.domain === null
        ) {
            return false;
        }

        return ['available', 'registered'].indexOf(
            this.serviceObject.settings.createData.domain.check.status
        ) >= 0;
    }

    public get chargeableButton() {
        return this.totalCost > 0;
    }

    public get balance() {
        if (this.depositInfo === null || this.depositInfo === undefined) {
            return null;
        }
        return ApiValue.parseApiValue(this.depositInfo.balance
            + this.depositInfo.voucherBalance - this.depositInfo.reserved) - this.totalCost;
    }

    public get isPrepaid() {
        if (this.authContext.isRootOrCompanyAccount || this._orderForSubaccount()) {
            return false;
        }

        if (!this.authContext.isGranted(UiRights.BIL_GET_DEPOSIT)) {
            return false;
        }

        if ([undefined, null].indexOf(this.depositInfo) >= 0) {
            return false;
        }

        return this.depositInfo.paymentType === 'prepaid';
    }

    public getTotalPrice = (showInEur?: boolean) => {
        showInEur = showInEur || false;
        const total = {};
        let vatRate: number;

        const bundleProduct = this.serviceObject.selectedProduct;
        const bundlePrice = bundleProduct.prices[0];
        if ([undefined, null].indexOf(bundleProduct) < 0) {
            if ([undefined, null].indexOf(bundlePrice) === -1
                && [undefined, null].indexOf(bundlePrice.price) < 0
                && bundlePrice.price.price !== -1
            ) {
                vatRate = bundlePrice.price.vatRate;
                const productPriceObj = {
                    exchangeRatio: bundlePrice.price.exchangeRatio !== null
                        ? bundlePrice.price.exchangeRatio.exchangeRatio
                        : null,
                    price: bundlePrice.price.price
                };

                total[bundlePrice.price.currency] = productPriceObj;
            }
        }

        const domainProduct = this.serviceObject.settings.createData.domain;
        if ([undefined, null].indexOf(domainProduct) < 0) {
            const domainPrice = domainProduct.prices[0];
            if ([undefined, null].indexOf(domainPrice) >= 0
                || [undefined, null].indexOf(domainPrice.price) >= 0
                || domainPrice.price.price === -1) {
                return;
            }

            if ([undefined, null].indexOf(total[domainPrice.price.currency]) >= 0) {
                total[domainPrice.price.currency] = {
                    exchangeRatio: [undefined, null].indexOf(domainPrice.price.exchangeRatio) < 0
                        ? domainPrice.price.exchangeRatio.exchangeRatio
                        : null,
                    price: 0
                };
            }

            if (domainPrice.price.promotionalPrice !== undefined && domainPrice.price.promotionalPrice !== null) {
                total[domainPrice.price.currency].price += domainPrice.price.promotionalPrice;
            } else {
                total[domainPrice.price.currency].price += domainPrice.price.price;
            }
        }

        this._setTaxType();
        return Object.keys(total).map(
            (key) => {
                const exchangeRatio = showInEur ? total[key].exchangeRatio : null;
                const priceObject = {
                    currency: key,
                    exchangeRatio: total[key].exchangeRatio,
                    totalPrice: this.calculatePriceFilter(
                            total[key].price,
                            vatRate,
                            1,
                            this._taxType,
                            exchangeRatio,
                            false,
                            total[key].productCode,
                            total[key].contractPeriod
                        ),
                    totalPriceInEuro: total[key].exchangeRatio !== null
                        ? this.calculatePriceFilter(
                            total[key].price,
                            vatRate,
                            1,
                            this._taxType,
                            total[key].exchangeRatio,
                            false,
                            total[key].productCode,
                            total[key].contractPeriod
                        )
                        : 0
                };
                this.totalCost +=  total[key].exchangeRatio !== null
                    ? priceObject.totalPriceInEuro
                    : priceObject.totalPrice;
                return priceObject;
            }
        );
    };

    public getCurrencyTotalPart = (object: any) => {
        return object.currency.toUpperCase();
    };

    public get accountHasPriceShowRight() {
        if (this.authContext.account.id === '1') {
            // only for root account, check selected account right
            return this.serviceObject.settings.account.rights.some(
                (right: string) => right === UiRights.BIL_LIST_ARTICLE_PRICES
            );
        }

        return this.authContext.account.rights.some((right: string) => right === UiRights.BIL_LIST_ARTICLE_PRICES);
    }

    private _orderForSubaccount = () => {
        return [undefined, null].indexOf(this.serviceObject.settings.createData.acountId) < 0
            && this.authContext.account.id !== this.serviceObject.settings.createData.acountId;
    };

    private getDepositInfo = () => {
        if (this.authContext.isGranted(UiRights.BIL_GET_DEPOSIT)) {
            this.depositModel.getDeposit().then(
                (reply) => this.depositInfo = reply
            );
        }
    };

    private _setTaxType = () => {
        this._taxType = this.authContext.account.id === '1'
            ? this.serviceObject.settings.account.isCommercialCustomer ? 'net' : 'gross'
            : this.authContext.account.isCommercialCustomer ? 'net' : 'gross';
    };
}

export class OrganismCreateBundleSummaryComponent implements ng.IComponentOptions {
    public require = {
        $createOrganism: '^organismCreateBundle'
    };
    public bindings = {
        serviceObject: '='
    };
    public template = require('./bundle-summary.html');
    public controller = OrganismCreateBundleSummaryController;
}
