import {
    DashboardWidgetModel,
    DashboardWidgetType
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model';
import {
    DashboardWidgetObjectDefinition
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model-operator';

export class DashboardWidgetEmptyHintModel extends DashboardWidgetModel {
    public static $inject: string[] = DashboardWidgetModel.$inject.concat([]);

    public static dashboardWidgetDefinition: DashboardWidgetObjectDefinition = {
        demoData: {},
        manualAddable: false,
        userPermissions: null,
        widgetDescription: [],
        widgetObject: {
            attribute: {
                id: 'organDashboardWidgetEmptyHint',
                width: 2,
                height: 1,
                x: 0,
                y: 0,
                noResize: true,
                autoPosition: true
            },
            componentTagName: 'organ-dashboard-widget-empty-hint',
            widgetModel: 'dashboardWidgetEmptyHintModel',
            version: '1.0'
        },
        widgetTitle: '',
        widgetType: DashboardWidgetType.custom
    };

    public dashboardWidgetDefinition: DashboardWidgetObjectDefinition;

    constructor() {
        super();

        this.dashboardWidgetDefinition = DashboardWidgetEmptyHintModel.dashboardWidgetDefinition;
    }
}
