import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import { AuthContextService, GetEntryPointService } from '../../../../../services';
import { DdnsModelService, DnsZoneModelService } from '../../../../../services/dns';
import * as Types from '../../../../../types';
import { DnsApi } from '../../../../../types';

export class OrganEditPanelDdnsHostGeneralController {
    public static $inject: string[] = ['$translate', 'authContext', 'dnsZoneModel', 'getEntryPoint', 'ddnsModel'];

    public hostname: DnsApi.DynamicDnsHostname;
    public showObjectId: boolean;
    public hostnameDropdownItems: any[];
    public domainSearchString = '';
    public ddnsUpdateServer = '';
    public callbackOnCancel: () => void;
    public panelRight = {
        editPanelButton: false
    };
    public existingDdnsHostnames: any[];
    public validationErrorList: any[] = [];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private dnsZoneModel: DnsZoneModelService,
        private getEntryPoint: GetEntryPointService,
        private ddnsModel: DdnsModelService
    ) {
        if (this.getEntryPoint.isHosting) {
            this.ddnsUpdateServer = 'http(s)://ddns.hosting.de';
        } else {
            this.ddnsUpdateServer = 'http(s)://ddns.routing.net';
        }
        this.panelRight.editPanelButton = this.authContext.isGranted(UiRights.DNS_DDNSHOSTNAME_EDIT);
    }

    public async $onInit() {
        this.createHostnameDropdownItems(this.hostname.accountId);
        const existingDdnsNames = await this.ddnsModel.getExistingDdnsNames();
        this.existingDdnsHostnames = existingDdnsNames.filter((hostnames) => hostnames !== this.hostname?.hostname);
    }

    public createHostnameDropdownItems = (accountId: string) => {
        const subFilterParts = [
            {
                field: 'accountId',
                value: accountId
            },
            {
                field: 'zoneStatus',
                value: 'active'
            }
        ] as Types.Finding.Filter[];

        if ([undefined, null, ''].indexOf(this.domainSearchString) < 0) {
            subFilterParts.push(
                {
                    field: 'zoneName',
                    value: `*${this.domainSearchString}*`
                }
            );
        }

        const filter: Types.Finding.Filter = {
            subFilter: subFilterParts,
            subFilterConnective: 'AND'
        };

        this.dnsZoneModel.list(undefined, undefined, filter).then(
            (reply) => {
                this.hostnameDropdownItems = reply.data.map(
                    (zone) => {
                        return {
                            name: zone.nameUnicode,
                            value: zone.name
                        };
                    }
                );
            }
        );
    };

    public callbackOnChange = (model: string) => {
        this.validationErrorList = [];
        if (this.existingDdnsHostnames?.indexOf(model) >= 0) {
            this.validationErrorList.push({
                text: this.$translate.instant('TR_190220-9c1436_TR')
            });
        }
    };

    public cancel = () => {
        if ([undefined, null].indexOf(this.callbackOnCancel) < 0) {
            this.callbackOnCancel();
        }
    };
}

export class OrganEditPanelDdnsHostGeneralComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnCancel: '<',
        hostname: '=',
        showObjectId: '<'
    };
    public template = require('./ddns-host-general.html');
    public controller = OrganEditPanelDdnsHostGeneralController;
}
