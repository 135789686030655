import * as ng from 'angular';
import {
    DateWrapper,
    ValidateDate
} from '../../../../../services';

export enum DeleteDateOptions {
    IMMEDIATE,
    END_OF_CONTRACT,
    SELECT_DATE
}

export class MoleculePanelEditRowDeleteDateController implements ng.IController {
    public static $inject: string[] = ['$filter', '$translate', 'localDateTimeFilter'];

    public date: DateWrapper;
    public deleteMode: DeleteDateOptions = DeleteDateOptions.IMMEDIATE;
    public endOfContract: string;
    public isFreeProduct: boolean;
    public maxYear: number;
    public minYear: number;
    public validationInstructions: any;
    public deleteModeOptions: any[] = [];
    public dateValidationErrorList: any[];
    public isTransitCancellation = false;
    public disconnectOnTransit = false;
    public isTransitTld = false;
    private today: DateWrapper;
    private endOfContractDate: DateWrapper;
    private _endOfContractDate: DateWrapper;

    constructor(
        private $filter: ng.IFilterService,
        protected $translate: ng.translate.ITranslateService,
        private localDateTimeFilter
    ) {
        this.today = new DateWrapper(new Date());
        this.minYear = this.today.year;
        this.maxYear = this.today.year + 10;
        this.validationInstructions = {
            instructions: null,
            validator: new ValidateDate(this.$translate, false, true)
        };
        this.dateValidationErrorList = [];
    }

    public $onInit() {
        this.date = new DateWrapper();
        this.date.setToday();

        // Hotfix 120.8 (PUI-5749):
        // Send the exact paidUntil date including hours+minutes+seconds to the backend (in UTC)
        this._endOfContractDate = new DateWrapper();
        this._endOfContractDate.dateObj = new Date(this.endOfContract);

        const paidUntilString = this.localDateTimeFilter(this.endOfContract, 'date');
        this.deleteModeOptions = [{
            label: this.$translate.instant('8c45521c-005d-4867-bcf0-f0ef38b03e48'),
            value: DeleteDateOptions.IMMEDIATE
        }, {
            label: this.isFreeProduct
                ? this.$translate.instant('TR_140120-6e202f_TR') + ' (' + paidUntilString + ')'
                : this.$translate.instant('TR_100119-fd6359_TR') + ' (' + paidUntilString + ')',
            value: DeleteDateOptions.END_OF_CONTRACT
        }, {
            label: this.$translate.instant('TR_100119-18817d_TR'),
            value: DeleteDateOptions.SELECT_DATE
        }];
    }

    public get showDatepicker() {
        return this.deleteMode === DeleteDateOptions.SELECT_DATE;
    }

    public get deleteDate() {
        switch (this.deleteMode) {
            case DeleteDateOptions.IMMEDIATE:
                return this.today;
            case DeleteDateOptions.END_OF_CONTRACT:
                return this._endOfContractDate;
            case DeleteDateOptions.SELECT_DATE:
                return this.dateValidationErrorList.length === 0 ? this.date : undefined;
        }
    }

    public set deleteDate(value: DateWrapper) {
        if (value !== undefined) {
            this.date = value;
        }
    }
}

export class MoleculePanelEditRowDeleteDateComponent implements ng.IComponentOptions {
    public bindings = {
        deleteDate: '=',
        disconnectOnTransit: '=',
        endOfContract: '@',
        isFreeProduct: '<',
        isTransitCancellation: '=',
        isTransitTld: '='
    };
    public controller = MoleculePanelEditRowDeleteDateController;
    // tslint:disable-next-line: max-line-length
    public template = require('./panel-edit-row-delete-date.html');
}
