import * as ng from 'angular';
import { AuthContextService, FaqManagerService } from '../../../../services';
import { LinkListItem } from '../../links/link-list/link-list';

export class MoleculePanelFaqController implements ng.IController {
    public static $inject: string[] = ['faqManager'];

    public faqItems: LinkListItem[];
    private faqArticleIdList: string[];

    constructor(
        private faqManager: FaqManagerService
    ) {}

    public $onInit() {
        const defaultArticles = [
            'email-mailbox-create',
            'domain-register-new',
            'billing-pay-bill'
        ];

        this.faqArticleIdList = this.faqArticleIdList || defaultArticles;

        if (AuthContextService.isDirectCustomer) {
            this.faqManager.faqItemList(this.faqArticleIdList).then((items) => {
                this.faqItems = items;
            });
        }
    }
}

export class MoleculePanelFaqComponent implements ng.IComponentOptions {
    public controller =  MoleculePanelFaqController;
    public bindings = {
        faqArticleIdList: '<'
    };
    public controllerAs = '$PanelFaqCtrl';
    public template = require('./panel-faq.html');
}
