import ng from 'angular';

import { DomainApi } from '@/types';
import { UiRights } from '@/configuration';
import { AlertManagerService, AuthContextService, DomainModelService } from '@/services';
import { EditPanelRight, FormDropDownItems, PanelType, ValidationErrorList } from '@/atomic-components/molecules';

export class OrganEditPanelDomainTransferUkController {
    public static $inject: string[] = ['$translate', 'alertManager', 'authContext', 'domainModel'];

    public domain: DomainApi.Domain;
    public panelRightSettings: EditPanelRight;
    public tags: { name: string; tag: unknown };
    public tagDropdownItems: FormDropDownItems[] = [];
    public changeTagErrorlist: ValidationErrorList = [];
    public selectedTag = '';
    public panelType: PanelType = PanelType.MISCELLANEOUS_ACTION;
    public userCanEdit: boolean = false;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private authContext: AuthContextService,
        private domainModel: DomainModelService
    ) {}

    public $onInit(): void {
        this.changeTagErrorlist = [];
        this.tagDropdownItems = this.tags.map((tag) => {
            return {
                name: tag.name,
                value: tag.tag
            };
        });
    }

    public isGranted = (userRight: string): boolean => {
        return this.authContext.isGranted(userRight);
    };

    public changeTag = (): boolean => {
        if (!this.authContext.isGranted(UiRights.DOM_DOMAINS_TRANSFER_OUT_PROCESS_REQUESTS)) {
            this.alertManager.error('You do not have the rights to perform this action');
            return false;
        }
    };

    public showPanel = (): boolean => {
        return this.authContext.isGranted(UiRights.DOM_DOMAINS_TRANSFER_OUT_PROCESS_REQUESTS);
    };

    public submitChangeTag = (): void => {
        void this.domainModel.changeTag([this.domain], this.selectedTag).then(() => {
            this.alertManager.success(this.$translate.instant('DOMAIN.MESSAGE.CHANGE-TAG.SUCCESS'));
        });
    };

    public get disableEditButton() {
        return !this.userCanEdit;
    }
}

export class OrganEditPanelDomainTransferUkComponent implements ng.IComponentOptions {
    public bindings = {
        domain: '=',
        panelRightSettings: '<panelRight',
        tags: '<',
        userCanEdit: '<?'
    };
    public controller = OrganEditPanelDomainTransferUkController;
    public template = require('./domain-transfer-uk.html');
}
