/* eslint-disable */
import * as Sentry from '@sentry/browser';

const UIRegExpInternal = {
    Hex: /[0-9A-Fa-f]{6}/,
    Opengpgkey: /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/][AQgw]==|[a-zA-Z0-9+/]{2}[AEIMQUYcgkosw048]=|)$/,
    Ipv6: /^(([0-9A-F]{1,4}:([0-9A-F]{1,4}:([0-9A-F]{1,4}:([0-9A-F]{1,4}:([0-9A-F]{1,4}:[0-9A-F]{0,4}|:[0-9A-F]{1,4})?|(:[0-9A-F]{1,4}){0,2})|(:[0-9A-F]{1,4}){0,3})|(:[0-9A-F]{1,4}){0,4})|:(:[0-9A-F]{1,4}){0,5})((:[0-9A-F]{1,4}){2}|:(25[0-5]|(2[0-4]|1[0-9]|[1-9])?[0-9])(\.(25[0-5]|(2[0-4]|1[0-9]|[1-9])?[0-9])){3})|(([0-9A-F]{1,4}:){1,6}|:):[0-9A-F]{0,4}|([0-9A-F]{1,4}:){7}:)$/,
    Ipv4Part: /^[0-9]{1,3}$/,
    CurrencySeparator: /[.,]/,
    ProductCodeToContractPeriod: /.+-([0-9]{1,2})m/g,
    BillingCyclePlaceholder: /##BILLING_CYCLE##/g,
    Tlsa: /^[0-3] [0-1] [0-2] [0-9a-fA-F\s]+$/,
    Caa: /^((0|128) (issue|issuewild|iodef) \".*\")$/,
    IsNumeric: /^\d+$/,
    EmailFirstPart: /^([a-zA-Z0-9_\-\.]+)$/ig,
    HasCsrRequestBegin: /-----BEGIN\s(NEW\s)?CERTIFICATE\sREQUEST-----/,
    HasCsrRequestEnd: /-----END\s(NEW\s)?CERTIFICATE\sREQUEST-----/,
    IsValidDateFormat: /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})Z$/,
    IsValidTld: /^(?:[a-z]{2,63}|xn--[a-z0-9]+)$/i,
    IsHostname: /^([a-zA-Z0-9\:][a-zA-Z0-9]\@)?(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])(\:[0-9]+)?$/gm,
    IsValidNextcloudUsersCharacters: /[a-zA-Z0-9_\\\-.@]/,
    RequestCancelled: /^Request .+ cancelled by state change using navigation service\.$/,
};

/*
    This results from a problem where the const regex taken from above always returns the same object.
    For a description of the error: https://stackoverflow.com/questions/4724701/regexp-exec-returns-null-sporadically

    To solve this in general, we wrap the object containing the regexes inside a proxy which returns a new RegExp Object every time
*/
export const UIRegExp = new Proxy(UIRegExpInternal, {
    get(target, name: keyof (typeof UIRegExpInternal)) {
        if (!UIRegExpInternal[name]) {
            const errorMessage = 'UIRegExp not found.';
            console.error(errorMessage);
            Sentry.captureMessage(errorMessage, {
                extra: {
                    regexp: name,
                }
            })
        }
        return new RegExp(UIRegExpInternal[name]);
    }
});
