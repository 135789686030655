import * as ng from 'angular';
import { UiRights } from '../../configuration';
import {
    AccountModelService,
    ApiKeyModelService,
    AuthContextService,
    DnsTemplateModelService,
    FilterCacheService,
    NameserverSetModelService,
    NavigationService,
    RedirectOnNotFoundCallback,
    RightModelService,
    UserModelService,
    UserRobotService,
    UserSettingsManagerService
} from '../../services';
import * as stateUtilityFunctions from '../../services/state-utility-functions';
import * as Types from '../../types';

export const ACCOUNT_ROUTES = [
    {
        name: 'profile',
        state: {
            parent: 'authenticated',
            resolve: {
                overviewUiDisplaySettings: (
                    userSettingsManager: UserSettingsManagerService
                ) => userSettingsManager.getUiDisplaySettings(),
                overviewUiSettings: (
                    userSettingsManager: UserSettingsManagerService
                ) => userSettingsManager.getSettingsForOverview()
            },
            url: '/profile',
            views: {
                'content@authenticated': {
                    template: `<template-profile
                        overview-ui-settings="$resolve.overviewUiSettings"
                        overview-ui-display-settings="$resolve.overviewUiDisplaySettings"
                    ></template-profile>`
                }
            }
        }
    },
    {
        name: 'profile.apikeys',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.ACC_ACCOUNT_APIKEYS_MANAGE
            },
            resolve: {
                allRights: (rightModel: RightModelService, userData: Types.AccountApi.User) => {
                    return rightModel.getListForApiKey(userData.id);
                },
                userData: (
                    userRobot: UserRobotService
                ) => userRobot.getUserBasicData().then((result) => {
                    return result.response;
                })
            },
            url: '/apikeys'
        }
    },
    {
        name: 'profile.apikeys.new',
        state: {
            url: '/new',
            views: {
                'content@authenticated': {
                    template: `<template-api-key-new
                        user-data="$resolve.userData"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        all-rights="$resolve.allRights"
                    ></template-api-key-new>`
                }
            }
        }
    },
    {
        name: 'profile.apikeys.id',
        state: {
            abstract: true,
            resolve: {
                apiKey: (
                    $stateParams: ng.ui.IStateParamsService,
                    apiKeyModel: ApiKeyModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return apiKeyModel.findOne($stateParams.apiKeyId)
                        .then(stateUtilityFunctions.check404('API-Key not found', 'api-key'))
                        .then(null, redirectOnNotFound);
                }
            },
            url: '/id/{apiKeyId}'
        }
    },
    {
        name: 'profile.apikeys.id.edit',
        state: {
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-api-key-edit
                        all-rights="$resolve.allRights"
                        api-key-data="$resolve.apiKey"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        user-data="$resolve.userData"
                    ></template-api-key-edit>`
                }
            }
        }
    },
    {
        comment: 'This should not be used anymore...',
        comment2: '^Can we delete it?',
        name: 'profile.twofactor',
        state: {
            data: {
                isGranted: UiRights.ACC_ACCOUNT_USER_EDIT
            },
            onEnter: (navigation: NavigationService, $stateParams: ng.ui.IStateParamsService) => {
                if (['enable', 'activate', 'require', 'disable'].indexOf($stateParams.twoFactorAction) < 0) {
                    void navigation.go('profile');
                }
            },
            resolve: {
                user: (authContext: AuthContextService) => authContext.user
            },
            url: '/twofactor/{twoFactorAction}',
            views: {
                'content@authenticated': {
                    template: '<template-under-construction>(Deprecated) Benutzerprofil</template-under-construction>'
                }
            }
        }
    },
    {
        name: 'account',
        state: {
            abstract: true,
            parent: 'authenticated',
            url: '/account'
        }
    },
    {
        name: 'account.dashboard',
        state: {
            data: {
                isGrantedAny: [
                    UiRights.ACC_ACCOUNT_BASICDATA_LIST,
                    UiRights.ACC_ACCOUNT_USER_LIST,
                    UiRights.UI_MISC_IS_DIRECT_CUSTOMER
                ]
            },
            url: '/',
            views: {
                'content@authenticated': {
                    template: '<template-account-dashboard></template-account-dashboard>'
                }
            }
        }
    },
    {
        name: 'account.dataprocessing',
        state: {
            data: {
                isGrantedAll: [
                    UiRights.UI_MISC_IS_DIRECT_CUSTOMER,
                    UiRights.BIL_LIST_DOCUMENTS
                ]
            },
            url: '/data-processing',
            views: {
                'content@authenticated': {
                    template: '<template-data-processing></template-data-processing>'
                }
            }
        }
    },
    {
        name: 'account.dataprocessing.suppliers',
        state: {
            data: {
                isGrantedAll: [
                    UiRights.UI_MISC_IS_DIRECT_CUSTOMER
                ]
            },
            url: '/suppliers',
            views: {
                'content@authenticated': {
                    template: '<template-suppliers></template-suppliers>'
                }
            }
        }
    },
    {
        name: 'account.dataprocessing.toms',
        state: {
            data: {
                isGrantedAll: [
                    UiRights.UI_MISC_IS_DIRECT_CUSTOMER
                ]
            },
            url: '/toms',
            views: {
                'content@authenticated': {
                    template: '<template-toms></template-toms>'
                }
            }
        }
    },
    {
        name: 'account.basicdata',
        state: {
            data: {
                isGranted: UiRights.ACC_ACCOUNT_BASICDATA_LIST
            },
            resolve: {
                basicData: (accountModel: AccountModelService) => {
                    return accountModel.getAccountBasicData();
                }
            },
            url: '/basicdata',
            views: {
                'content@authenticated': {
                    template: `<template-basic-data
                        mode="overview"
                        basic-data="$resolve.basicData">
                    </template-basic-data>`
                }
            }
        }
    },
    {
        name: 'account.contactdata',
        state: {
            data: {
                isGranted: UiRights.ACC_ACCOUNT_BASICDATA_LIST
            },
            resolve: {
                basicData: (accountModel: AccountModelService) => {
                    return accountModel.getAccountBasicData();
                }
            },
            url: '/contactdata',
            views: {
                'content@authenticated': {
                    template: `<template-basic-data mode="contactdata" basic-data="$resolve.basicData">
                    </template-basic-data>`
                }
            }
        }
    },
    {
        name: 'account.contactemail',
        state: {
            data: {
                isGranted: UiRights.ACC_ACCOUNT_BASICDATA_LIST
            },
            resolve: {
                basicData: (accountModel: AccountModelService) => {
                    return accountModel.getAccountBasicData();
                }
            },
            url: '/contactemail',
            views: {
                'content@authenticated': {
                    template: `<template-basic-data mode="contactemail" basic-data="$resolve.basicData">
                    </template-basic-data>`
                }
            }
        }
    },
    {
        name: 'account.contactdetails',
        state: {
            data: {},
            url: '/contact-details',
            views: {
                'content@authenticated': {
                    template: '<template-contact-details></template-basic-data>'
                }
            }
        }
    },
    {
        name: 'account.settings',

        state: {
            data: {
                // Das ist eine Lüge, aber wir wollen nicht allen Kunden diese Seite anzeigen
                // ^Eine Lüge? :D
                isGranted: UiRights.ACC_ACCOUNT_BASICDATA_LIST
            },
            resolve: {
                basicData: (accountModel: AccountModelService) => {
                    return accountModel.getAccountBasicData();
                }
            },
            url: '/settings',
            views: {
                'content@authenticated': {
                    template: '<template-account-settings basic-data="$resolve.basicData"></template-acount-settings>'
                }
            }
        }
    },
    {
        name: 'account.users',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.ACC_ACCOUNT_USER_LIST
            },
            url: '/users'
        }
    },
    {
        name: 'account.users.overview',
        state: {
            resolve: {
                account: (authContext: AuthContextService) => {
                    return authContext.account;
                },
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('AccountUserlistOverviewCtrl');
                },
                promoteToAdminAllowed: (authContext: AuthContextService) => {
                    return authContext.user.adminUser;
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-user-list
                        overview-ui-settings="$resolve.overviewUiSettings">
                    </template-user-list>`
                }
            }
        }
    },
    {
        name: 'account.users.new',
        state: {
            data: {
                isGranted: UiRights.ACC_ACCOUNT_USER_CREATE
            },
            resolve: {
                allRights: (rightModel: RightModelService) => rightModel.getList(),
                rightsTemplates: (rightModel: RightModelService, authContext: AuthContextService) => {
                    const filter: Types.Finding.Filter = {
                        subFilter: [
                            {
                                field: 'accountId',
                                value: authContext.account.id
                            },
                            {
                                field: 'rightsTemplateUsableForUsers',
                                value: '1'
                            }
                        ],
                        subFilterConnective: 'AND'
                    };

                    return rightModel.rightsTemplate(undefined, undefined, filter)
                    .then((reply) => reply.data);
                }
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: `<template-user-create
                        rights-templates="$resolve.rightsTemplates"
                        all-rights="$resolve.allRights">
                    </template-user-create>`
                }
            }
        }
    },
    {
        name: 'account.users.id',
        state: {
            abstract: true,
            resolve: {
                allRights: (rightModel: RightModelService) => {
                    return rightModel.getList();
                },
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('ApiKeyOverviewCtrl');
                },
                rightsTemplates: (
                    rightModel: RightModelService,
                    userData: any,
                    authContext: AuthContextService
                ): PromiseLike<any[]> => {
                    if (!authContext.isGranted(UiRights.ACC_ACCOUNT_RIGHTSTEMPLATE_LIST)) {
                        return Promise.resolve([]);
                    }
                    const filter: Types.Finding.Filter = {
                        subFilter: [
                            {
                                field: 'accountId',
                                value: userData.accountId
                            },
                            {
                                field: 'rightsTemplateUsableForUsers',
                                value: '1'
                            }
                        ],
                        subFilterConnective: 'AND'
                    };
                    return rightModel.rightsTemplate(undefined, undefined, filter).then(
                        (reply) => {
                            const ret = [];
                            for (const template of reply.data) {
                                if (template.usableForUsers) {
                                    ret.push(template);
                                }
                            }
                            return ret;
                        }
                    );
                },
                userData: (
                    redirectOnNotFound: RedirectOnNotFoundCallback,
                    userModel: UserModelService,
                    $stateParams: ng.ui.IStateParamsService
                ) => userModel.findOne($stateParams.userId).then(null, redirectOnNotFound)
            },
            url: '/id/{userId}'
        }
    },
    {
        name: 'account.users.id.edit',
        state: {
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-user-edit
                        user-data="$resolve.userData"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        all-rights="$resolve.allRights"
                        rights-templates="$resolve.rightsTemplates"
                    ></template-user-edit>`
                }
            }
        }
    },
    {
        name: 'account.users.id.admin',
        state: {
            data: {
                isGranted: UiRights.ACC_SUBACCOUNT_USER_PROMOTE_TO_ADMIN
            },
            onEnter: (navigation: NavigationService) => {
                if (!AuthContextService.isAdminUser) {
                    void navigation.go('account.users.overview');
                }
            },
            url: '/promote-to-admin',
            views: {
                'content@authenticated': {
                    template: '<template-user-edit-admin user-data="$resolve.userData"></template-user-edit-admin>'
                }
            }
        }
    },
    {
        name: 'account.users.id.twofactor',
        state: {
            onEnter: (navigation: NavigationService, $stateParams: ng.ui.IStateParamsService) => {
                if (['enable', 'activate', 'require', 'disable'].indexOf($stateParams.twoFactorAction) < 0) {
                    void navigation.go('account.users.id.edit');
                }
            },
            url: '/twofactor/{twoFactorAction}',
            views: {
                'content@authenticated': {
                    template: '<template-under-construction>Benutzerprofil</template-under-construction>'
                }
            }
        }
    },
    {
        name: 'account.users.id.apikeys',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.ACC_ACCOUNT_APIKEYS_MANAGE
            },
            resolve: {
                allRights: (rightModel: RightModelService, userData: Types.AccountApi.User) => {
                    return rightModel.getListForApiKey(userData.id);
                }
            },
            url: '/apikeys'
        }
    },
    {
        name: 'account.users.id.apikeys.new',
        state: {
            url: '/new',
            views: {
                'content@authenticated': {
                    template: `<template-api-key-new
                        user-data="$resolve.userData"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        rights-templates="$resolve.rightsTemplates"
                        all-rights="$resolve.allRights"
                    ></template-api-key-new>`
                }
            }
        }
    },
    {
        name: 'account.users.id.apikeys.id',
        state: {
            abstract: true,
            resolve: {
                apiKey: (
                    $stateParams: ng.ui.IStateParamsService,
                    apiKeyModel: ApiKeyModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return apiKeyModel.findOne($stateParams.apiKeyId)
                        .then(stateUtilityFunctions.check404('API-Key not found', 'api-key'))
                        .then(null, redirectOnNotFound);
                }
            },
            url: '/id/{apiKeyId}'
        }
    },
    {
        name: 'account.users.id.apikeys.id.edit',
        state: {
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-api-key-edit
                        all-rights="$resolve.allRights"
                        api-key-data="$resolve.apiKey"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        rights-templates="$resolve.rightsTemplates"
                        user-data="$resolve.userData"
                    ></template-api-key-edit>`
                }
            }
        }
    }
];

export const RESELLER_ROUTES = [
    {
        name: 'reseller',
        state: {
            abstract: true,
            parent: 'authenticated',
            url: '/reseller'
        }
    },
    {
        name: 'reseller.dashboard',
        state: {
            data: {
                isGranted: UiRights.ACC_SUBACCOUNT_LIST
            },
            url: '/dashboard',
            views: {
                'content@authenticated': {
                    template: '<template-reseller-dashboard></template-reseller-dashboard>'
                }
            }
        }
    },
    {
        name: 'reseller.settings',
        state: {
            data: {
                isGranted: UiRights.ACC_SUBACCOUNT_LIST
            },
            resolve: {
                dnsTemplates: (dnsTemplateModel: DnsTemplateModelService) => {
                    return dnsTemplateModel.list().then(
                        (reply) => {
                            return reply.data;
                        }
                    );
                },
                nameserverSets: (nameserverSetModel: NameserverSetModelService) => {
                    return nameserverSetModel.list().then(
                        (reply) => {
                            return reply.data;
                        }
                    );
                },
                rightsTemplates: (rightModel: RightModelService) => {
                    return rightModel.rightsTemplate().then(
                        (reply) => {
                            const ret = [];
                            for (const template of reply.data) {
                                if (template.usableForSubaccounts) {
                                    ret.push(template);
                                }
                            }
                            return ret;
                        }
                    );
                }
            },
            url: '/settings',
            views: {
                'content@authenticated': {
                    template: `<template-reseller-settings
                        rights-templates="$resolve.rightsTemplates"
                        dns-templates="$resolve.dnsTemplates"
                        nameserver-sets="$resolve.nameserverSets"
                    ></template-reseller-settings>`
                }
            }
        }
    },
    {
        data: {
            isGranted: UiRights.ACC_ACCOUNT_RIGHTSTEMPLATE_LIST
        },
        name: 'reseller.rightstemplates',
        state: {
            abstract: true,
            resolve: {
                permissions: (rightModel: RightModelService) => {
                    return rightModel.getList();
                }
            },
            url: '/rightstemplates'
        }
    },
    {
        name: 'reseller.rightstemplates.overview',
        state: {
            resolve: {
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('RightsTemplateOverviewCtrl');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-rights-templates
                        overview-ui-settings="$resolve.overviewUiSettings"
                    ></template-rights-templates>`
                }
            }
        }
    },
    {
        name: 'reseller.rightstemplates.id',
        state: {
            abstract: true,
            resolve: {
                rightsTemplate: (
                    $stateParams: ng.ui.IStateParamsService,
                    redirectOnNotFound: RedirectOnNotFoundCallback,
                    rightModel: RightModelService
                ) => {
                    return rightModel.findOneRightsTemplate($stateParams.rightsTemplateId)
                        .then(null, redirectOnNotFound);
                }
            },
            url: '/id/{rightsTemplateId}'
        }
    },
    {
        name: 'reseller.rightstemplates.id.edit',
        state: {
            data: {
                isGranted: UiRights.ACC_ACCOUNT_RIGHTSTEMPLATE_LIST
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-rights-template-edit
                        rights-template="$resolve.rightsTemplate"
                        permissions="$resolve.permissions"
                    ></template-rights-template-edit>`
                }
            }
        }
    },
    {
        name: 'reseller.rightstemplates.new',
        state: {
            data: {
                isGranted: UiRights.ACC_ACCOUNT_RIGHTSTEMPLATE_LIST
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: `<template-rights-template-new
                        permissions="$resolve.permissions">
                    </template-rights-template-new>`
                }
            }
        }
    },
    {
        name: 'reseller.subaccounts',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.ACC_SUBACCOUNT_LIST
            },
            url: '/subaccounts'
        }
    },
    {
        name: 'reseller.subaccounts.overview',
        state: {
            resolve: {
                extendedFilters: (
                    filterCache: FilterCacheService
                ) => filterCache.resolveExtendedFilters('reseller.subaccounts.overview'),
                overviewUiSettings: (
                    userSettingsManager: UserSettingsManagerService
                ) => userSettingsManager.getSettingsForOverview('AccountOverviewCtrl'),
                simpleFilter: (
                    filterCache: FilterCacheService
                ) => filterCache.resolveSimpleFilter('reseller.subaccounts.overview')
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-sub-account-overview
                        overview-ui-settings="$resolve.overviewUiSettings">
                    </template-sub-account-overview>`
                }
            }
        }
    },
    {
        name: 'reseller.subaccounts.new',
        state: {
            data: {
                isGranted: UiRights.ACC_SUBACCOUNT_CREATE
            },
            resolve: {
                allRights: (rightModel: RightModelService) => rightModel.getList(),
                rightsTemplates: (rightModel: RightModelService, authContext: AuthContextService) => {
                    const filter: Types.Finding.Filter = {
                        subFilter: [{
                            field: 'accountId',
                            value: authContext.account.id
                        }, {
                            field: 'rightsTemplateUsableForSubaccounts',
                            value: '1'
                        }],
                        subFilterConnective: 'AND'
                    };
                    return rightModel.rightsTemplate(undefined, undefined, filter).then((reply) => {
                        return reply.data;
                    });
                }
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: `<template-sub-account-create
                        rights-templates="$resolve.rightsTemplates"
                        all-rights="$resolve.allRights"
                    ></template-sub-account-create>`
                }
            }
        }
    },
    {
        name: 'reseller.subaccounts.id',
        state: {
            abstract: true,
            resolve: {
                account: (
                    $stateParams: ng.ui.IStateParamsService,
                    accountModel: AccountModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return accountModel.findOneSubaccount($stateParams.subaccountId)
                        .then(stateUtilityFunctions.check404('Subaccount not found'))
                        .then(null, redirectOnNotFound);
                },
                allRights: (account: Types.AccountApi.Subaccount, rightModel: RightModelService) => {
                    return rightModel.getListForSubaccount(account.parentAccountId);
                },
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('email.account');
                },
                language: (accountModel: AccountModelService) => {
                    return accountModel.getAccountBasicData()
                    .then((reply) => reply.language);
                },
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('EmailOverviewCtrl');
                },
                rightsTemplates: (rightModel: RightModelService, account: Types.AccountApi.AbstractAccount) => {
                    const filter: Types.Finding.Filter = {
                        subFilter: [
                            {
                                field: 'accountId',
                                value: account.parentAccountId
                            },
                            {
                                field: 'rightsTemplateUsableForSubaccounts',
                                value: '1'
                            }
                        ],
                        subFilterConnective: 'AND'
                    };
                    return rightModel.rightsTemplate(undefined, undefined, filter)
                    .then((reply) => reply.data);
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('email.account');
                }
            },
            url: '/id/{subaccountId}'
        }
    },
    {
        name: 'reseller.subaccounts.id.edit',
        state: {
            data: {
                isGranted: UiRights.ACC_SUBACCOUNT_EDIT
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template : `<template-sub-account-edit
                        sub-account-data="$resolve.account"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        simple-filter="$resolve.simpleFilter"
                        extended-filters="$resolve.extendedFilters"
                        parent-account-language="$resolve.language"
                        rights-templates="$resolve.rightsTemplates"
                        all-rights="$resolve.allRights"
                    ></template-sub-account-edit>`
                }
            }
        }
    },
    {
        name: 'reseller.subaccounts.id.users',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.ACC_SUBACCOUNT_USER_LIST
            },
            resolve: {
                allRights: (account: Types.AccountApi.Subaccount, rightModel: RightModelService) => {
                    return rightModel.getListForUser(account.id);
                }
            },
            url: '/users'
        }
    },
    {
        name: 'reseller.subaccounts.id.users.overview',
        state: {
            resolve: {
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('AccountUserlistOverviewCtrl');
                },
                promoteToAdminAllowed: () => true
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-user-list
                        overview-ui-settings="$resolve.overviewUiSettings">
                    </template-user-list>`
                }
            }
        }
    },
    {
        name: 'reseller.subaccounts.id.users.new',
        state: {
            data: {
                isGranted: UiRights.ACC_SUBACCOUNT_USER_CREATE
            },
            resolve: {
                rightsTemplates: (account: Types.AccountApi.AbstractAccount, rightModel: RightModelService) => {
                    const filter: Types.Finding.Filter = {
                        subFilter: [
                            {
                                field: 'accountId',
                                value: account.id
                            },
                            {
                                field: 'rightsTemplateUsableForUsers',
                                value: '1'
                            }
                        ],
                        subFilterConnective: 'AND'
                    };

                    return rightModel.rightsTemplate(undefined, undefined, filter)
                    .then((reply) => reply.data);
                }
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: `<template-user-create
                        rights-templates="$resolve.rightsTemplates"
                        all-rights="$resolve.allRights">
                    </template-user-create>`
                }
            }
        }
    },
    {
        name: 'reseller.subaccounts.id.users.id',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.ACC_SUBACCOUNT_USER_EDIT
            },
            resolve: {
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('ApiKeyOverviewCtrl');
                },
                rightsTemplates: (
                    rightModel: RightModelService,
                    userData: Types.AccountApi.User
                ): PromiseLike<Types.AccountApi.RightsTemplate[]> => {
                    if (!AuthContextService.isGranted(UiRights.ACC_ACCOUNT_RIGHTSTEMPLATE_LIST)) {
                        return Promise.resolve([]);
                    }

                    const filter: Types.Finding.Filter = {
                        subFilter: [
                            {
                                field: 'accountId',
                                value: userData.accountId
                            },
                            {
                                field: 'rightsTemplateUsableForUsers',
                                value: '1'
                            }
                        ],
                        subFilterConnective: 'AND'
                    };

                    return rightModel.rightsTemplate(undefined, undefined, filter).then(
                        (reply) => {
                            const ret = [];

                            for (const template of reply.data) {
                                if (template.usableForUsers) {
                                    ret.push(template);
                                }
                            }

                            return ret as Types.AccountApi.RightsTemplate[];
                        }
                    );
                },
                userData: (
                    $stateParams: ng.ui.IStateParamsService,
                    redirectOnNotFound: RedirectOnNotFoundCallback,
                    userModel: UserModelService
                ) => userModel.findOne($stateParams.userId).then(null, redirectOnNotFound)
            },
            url: '/id/{userId}'
        }
    },
    {
        name: 'reseller.subaccounts.id.users.id.edit',
        state: {
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-user-edit
                        user-data="$resolve.userData"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        all-rights="$resolve.allRights"
                        rights-templates="$resolve.rightsTemplates"
                    ></template-user-edit>`
                }
            }
        }
    },
    {
        name: 'reseller.subaccounts.id.users.id.twofactor',
        state: {
            onEnter: (navigation: NavigationService, $stateParams: ng.ui.IStateParamsService) => {
                if (['enable', 'activate', 'require', 'disable'].indexOf($stateParams.twoFactorAction) < 0) {
                    void navigation.go('reseller.subaccounts.id.users.id.edit');
                }
            },
            url: '/twofactor/{twoFactorAction}',
            views: {
                'content@authenticated': {
                    template: '<template-under-construction>Benutzerprofil</template-under-construction>'
                }
            }
        }
    },
    {
        name: 'reseller.subaccounts.id.users.id.apikeys',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.ACC_SUBACCOUNT_APIKEYS_MANAGE
            },
            resolve: {
                allRights: (userData: Types.AccountApi.User, rightModel: RightModelService) => {
                    return rightModel.getListForApiKey(userData.id);
                }
            },
            url: '/apikeys'
        }
    },
    {
        name: 'reseller.subaccounts.id.users.id.apikeys.new',
        state: {
            url: '/new',
            views: {
                'content@authenticated': {
                    template: `<template-api-key-new
                        user-data="$resolve.userData"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        rights-templates="$resolve.rightsTemplates"
                        all-rights="$resolve.allRights"
                    ></template-api-key-new>`
                }
            }
        }
    },
    {
        name: 'reseller.subaccounts.id.users.id.apikeys.id',
        state: {
            abstract: true,
            resolve: {
                apiKey: (
                    $stateParams: ng.ui.IStateParamsService,
                    apiKeyModel: ApiKeyModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return apiKeyModel.findOne($stateParams.apiKeyId).then(null, redirectOnNotFound);
                }
            },
            url: '/id/{apiKeyId}'
        }
    },
    {
        name: 'reseller.subaccounts.id.users.id.apikeys.id.edit',
        state: {
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-api-key-edit
                        user-data="$resolve.userData"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        rights-templates="$resolve.rightsTemplates"
                        all-rights="$resolve.allRights"
                        api-key-data="$resolve.apiKey"
                    ></template-api-key-edit>`
                }
            }
        }
    }
];
