import { LinkListOverviewItem, TextFilterField } from '../../atomic-components';
import { OverviewModel } from '../abstract';
import { RequestCancellerService } from '../request-canceller';

export class OrganizationOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', 'organizationModel']
    );

    public readonly service = 'email';
    public readonly objectType = 'ExchangeOrganization';
    public readonly idField = 'organizationId';
    public readonly defaultFilterFields = ['OrganizationName'];

    public activeMassAction: string | boolean = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};
    public availableFilters = [];

    protected listCallbacks = [{callback: this.organizationModel.list}];

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private organizationModel: any
    ) {
        super(RequestCanceller);
    }

    public actionLinks = (params: { [param: string]: any }): LinkListOverviewItem[] => {
        return [
            {
                route: 'email.organizations.new',
                text: this.$translate.instant('TR_160119-c7933d_TR')
            }
        ] as LinkListOverviewItem[];
    };
}
