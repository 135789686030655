import * as ng from 'angular';
import { InputPlaceholderConst } from '../../../../configuration';
import { GetEntryPointService } from '../../../../services';
import './panel-login.scss';

export class OrganPanelLoginController {
    public static $inject: string[] = ['getEntryPoint'];

    public credentials: any;
    public twofaExpired: boolean;
    public errorText: string;
    public inProgress: boolean;
    public InputPlaceholder = InputPlaceholderConst;
    public logo: string;

    public registrationUrl: string;

    private $editForm: { save: () => void };
    private lastEntryPoint = '';

    constructor(private getEntryPoint: GetEntryPointService) {}

    public $onInit() {
        this.inProgress = false;

        this.setUrls();
    }

    public submit = () => {
        this.$editForm.save();
    };

    public setUrls = () => {
        if (this.lastEntryPoint !== this.getEntryPoint.entryPoint) {
            this.lastEntryPoint = this.getEntryPoint.entryPoint;

            this.logo = this.getEntryPoint.logoPath;
            this.registrationUrl = this.getEntryPoint.registrationUrl;
        }
    };

    public goToRegistration = () => {
        window.location.href = this.registrationUrl;
    };

    public get showErrorText() {
        return this.errorText !== undefined && this.errorText.length > 0;
    }
}

export class OrganPanelLoginComponent implements ng.IComponentOptions {
    public bindings = {
        credentials: '=',
        errorText: '@',
        inProgress: '<',
        invalid: '<',
        twofaExpired: '<'
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public template = require('./panel-login.html');
    public controller = OrganPanelLoginController;
}
