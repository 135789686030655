import { Finding } from '@/types/finding';
import { keenRobotsCommon } from '@/types/keen-robots-common';

import { ProductApi } from '@/types/product-api';

export namespace UI {
    export type ListMethod<T> = (
        filter?: Finding.Filter,
        limit?: number,
        page?: number,
        sort?: Finding.SortOptions,
        timeout?: number,
        owner?: string
    ) => Promise<T>;

    /** Possible API response types */
    export enum APIResponseStatus { // eslint-disable-line no-shadow
        ERROR = 'error',
        PENDING = 'pending',
        SUCCESS = 'success'
    }

    /** General API response data type */
    export interface APIResponse<T> {
        errors: keenRobotsCommon.ErrorOrWarning[];
        metadata: {
            clientTransactionId: string;
            serverTransactionId?: string;
        };
        response: {data: T[]};
        status: APIResponseStatus;
        warnings: keenRobotsCommon.ErrorOrWarning[];
    }

    export interface APISingleResponse<T> {
        errors: keenRobotsCommon.ErrorOrWarning[];
        metadata: {
            clientTransactionId: string;
            serverTransactionId?: string;
        };
        response: T;
        status: APIResponseStatus;
        warnings: keenRobotsCommon.ErrorOrWarning[];
    }

    export interface APIResponses<T> {
        errors: keenRobotsCommon.ErrorOrWarning[];
        metadata: {
            clientTransactionId: string;
            serverTransactionId?: string;
        };
        responses: T[];
        status: APIResponseStatus;
        warnings: keenRobotsCommon.ErrorOrWarning[];
    }

    export interface Response<T>{
            limit: number;
            page: number;
            totalEntries: number;
            totalPages: number;
            data: T[];
    }

    /** Synchronous API response data type */
    export interface SynchronousAPIResponse<T> extends APIResponse<T> {
        status: APIResponseStatus.ERROR | APIResponseStatus.SUCCESS;
    }

    /** Asynchronous API response data type */
    export interface AsynchronousAPIResponse<T> extends APIResponse<T> {
        status: APIResponseStatus.ERROR | APIResponseStatus.PENDING;
    }

    // tslint:disable-next-line: class-name
    export interface phpBoostOptionsInterface {
        phpExecutionTime: phpBoostItemInterface;
        phpMemoryLimit: phpBoostItemInterface;
    }

    // tslint:disable-next-line: class-name
    export interface phpBoostItemInterface {
        included: number;
        max: number;
        step: number;
        current: number;
        productCode: string;
        orderAmount: number;
        price: unknown;
    }

    export interface nextcloudAddonLookupDefinition {
        fields: {
            apiName: string;
            defaultValue?: string|number;
            grabFromParent: boolean;
            uiName: string;
        }[];
        productCodeLookup: string;
        uiName: string;
    }

    export interface nextcloudAggregatedSingleProductAddonsData {
        included?: string|number;
        isExtensible: boolean;
        max?: string|number;
        productData?: ProductApi.Product;
        step?: string|number;
    }

    export interface nextcloudAggregatedProductAddonsData {
        officeUsers?: UI.nextcloudAggregatedSingleProductAddonsData;
        productData?: ProductApi.Product;
        storage?: UI.nextcloudAggregatedSingleProductAddonsData;
        talkUsers?: UI.nextcloudAggregatedSingleProductAddonsData;
        users?: UI.nextcloudAggregatedSingleProductAddonsData;
    }

    export interface RzOptions {
        floor?: number;
        ceil?: number;
        step?: number;
        precision?: number;
        minLimit?: number;
        maxLimit?: number;
        restrictedRange?: { from: number; to: number };
        minRange?: number;
        maxRange?: number;
        pushRange?: boolean;
        translate?: unknown;
        getLegend?: (value: number, sliderId: string) => string;
        id?: string;
        stepsArray?: unknown[];
        bindIndexForStepsArray?: boolean;
        disableAnimation?: boolean;
        draggableRange?: boolean;
        draggableRangeOnly?: boolean;
        showSelectionBar?: boolean;
        showSelectionBarEnd?: boolean;
        showOuterSelectionBars?: boolean;
        showSelectionBarFromValue?: number;
        getSelectionBarColor?: (minVal: number, maxVal?: number) => string;
        getTickColor?: (value: number) => string;
        getPointerColor?: (value: number, pointerType: unknown) => string;
        hidePointerLabels?: boolean;
        hideLimitLabels?: boolean;
        autoHideLimitLabels?: boolean;
        readOnly?: boolean;
        disabled?: boolean;
        interval?: number;
        showTicks?: boolean | number;
        showTicksValues?: boolean | number;
        ticksArray?: number[];
        ticksTooltip?: (value: number) => string;
        ticksValuesTooltip?: (value: number) => string;
        scale?: number;
        enforceStep?: boolean;
        enforceRange?: boolean;
        noSwitching?: boolean;
        onlyBindHandles?: boolean;
        boundPointerLabels?: boolean;
        mergeRangeLabelsIfSame?: boolean;
        labelOverlapSeparator?: string;
        onStart?: unknown;
        onChange?: unknown;
        onEnd?: unknown;
        rightToLeft?: boolean;
        vertical?: boolean;
        keyboardSupport?: boolean;
        reversedControls?: boolean;
        customTemplateScope?: unknown;
        logScale?: boolean;
        customValueToPosition?: (val: number, minVal: number, maxVal: number) => number;
        customPositionToValue?: (percent: number, minVal: number, maxVal: number) => number;
        selectionBarGradient?: {from: string; to: string};
        ariaLabel?: string;
        ariaLabelHigh?: string;
        ariaLabelledBy?: string;
        ariaLabelledByHigh?: string;
    }
}
