/* eslint-disable */

import * as ng from 'angular';
import * as q from 'q';
import { ProductConfigNextcloudObject } from '@/atomic-components/organisms/wizards';
import { NextcloudUnlimitedStorageValue, UiLanguagesConst } from '@/configuration';
import { FormatMegabyteFilter } from '@/filters';
import {
    AuthContextService,
    ManagedApplicationRobotService,
    ProductHelperService,
    WizardNewHelperService
} from '@/services';
import * as Types from '@/types';
import { ViewTypes } from '@/types';
import WizardComposerObject = ViewTypes.WizardComposerObject;
import { FormDescriptionSpec } from '@/types/view-types/form-description/form-description';

export class ManagedApplicationComposerService {
    public static $inject: string[] = [
        '$translate',
        'formatMegabyteFilter',
        'managedApplicationRobot',
        'productHelper',
        'wizardNewHelper'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private formatMegabyte: FormatMegabyteFilter,
        private managedApplicationRobot: ManagedApplicationRobotService,
        private productHelper: ProductHelperService,
        private wizardNewHelper: WizardNewHelperService
    ) {}

    public buildNextcloudApiObject = async (metadata: ProductConfigNextcloudObject): Promise<WizardComposerObject> => {
        let ncBillingCycleTranslation: string;
        const promises = [];

        /**
         * Die Composer Service Klasse wird später, anhand der aus der Konfigurations-Komponent stammenden Metadaten
         * das für den 'api create request' nötige API Objekt und für die Summaryseite benötige Zusammenfassungs-
         * informationen zusammenbauen
         *
         * return dummy data to develop summary component
         */
        let storage: Number = metadata.productMaxStorageQuota;
        if (metadata.addons?.storage) {
            storage = metadata.addons.storage.current;
        };
        const composerObject: WizardComposerObject = {
            apiObject: {
                nextcloud: {
                    accountId: metadata.account.id,
                    backupEnabled: !!metadata.nextcloudBackupProductCode,
                    enabledApps: [],
                    maxNumberOfUsers: 0,
                    name: metadata.displayname,
                    productCode: metadata.nextcloudProductCode
                },
                users: [
                    {
                        password: metadata.nextcloudUser.password,
                        user: {
                            emailAddress: metadata.nextcloudUser.user.emailAddress,
                            enabled: true,
                            groups: [],
                            name: metadata.nextcloudUser.user.name,
                            storageQuota: storage, // NextcloudUnlimitedStorageValue,
                            username: metadata.nextcloudUser.user.username
                        }
                    }
                ],
                appConfigs: [],
                voucher: metadata.voucher
            },
            summaryObject: []
        } as WizardComposerObject;
        // Build summary object
        promises.push(this.productHelper.getProductFromProductCode(metadata.nextcloudProductCode));
        promises.push(this.wizardNewHelper.factSheetConditioner(metadata.nextcloudProductCode));

        if (metadata.nextcloudBackupProductCode !== null) {
            promises.push(this.productHelper.getProductFromProductCode(metadata.nextcloudBackupProductCode));
            promises.push(this.wizardNewHelper.factSheetConditioner(metadata.nextcloudBackupProductCode));
        }

        return q.all(promises).then(async (promiseResolves) => {
            let nextcloudFactSheets: ViewTypes.WizardSummaryViewDataObject[] = [];
            let nextcloudProduct: Types.ProductApi.AbstractProduct;
            let nextcloudBackupFactSheet: ViewTypes.WizardSummaryViewDataObject[] = [];
            let nextcloudBackupProduct: Types.ProductApi.AbstractProduct;

            promiseResolves.map((resolve: unknown, index: number) => {
                switch (index) {
                    case 0:
                        nextcloudProduct = resolve;
                        break;
                    case 1:
                        ncBillingCycleTranslation = this.$translate.instant(
                            this.productHelper.getBillingCycleFromNumber(
                                nextcloudProduct.billingCycle
                            )
                        );
                        nextcloudFactSheets = [{
                            description: '',
                            label: this.$translate.instant('TR_110419-f5d08d_TR'),
                            type: ('string' as const),
                            value: ncBillingCycleTranslation
                            // @ts-ignore TS2769
                        }].concat(resolve);
                        break;
                    case 2:
                        nextcloudBackupProduct = resolve;
                        break;
                    case 3:
                        nextcloudBackupFactSheet = [{
                            description: '',
                            label: this.$translate.instant('TR_110419-f5d08d_TR'),
                            type: ('string' as const),
                            value: this.$translate.instant(
                                this.productHelper.getBillingCycleFromNumber(
                                    nextcloudBackupProduct.billingCycle
                                )
                            )
                            // @ts-ignore TS2769
                        }].concat(resolve);
                        break;
                }
            });

            composerObject.summaryObject.push({
                account: metadata.account,
                factSheets: nextcloudFactSheets,
                product: nextcloudProduct,
                productCode: metadata.nextcloudProductCode
            });

            if (metadata.nextcloudBackupProductCode !== null) {
                composerObject.summaryObject.push({
                    account: metadata.account,
                    factSheets: nextcloudBackupFactSheet,
                    product: nextcloudBackupProduct,
                    productCode: metadata.nextcloudBackupProductCode
                });
            }

            if (metadata.addons.storage && metadata.addons.storage.amount > 0) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                composerObject.apiObject.nextcloud.storageQuota = metadata.addons.storage.current;
                composerObject.apiObject.users.forEach(
                    (user) => {
                        user.storageQuota = metadata.addons.storage.current;
                    }
                );

                const storageFactSheet = await this.wizardNewHelper.factSheetConditioner(
                    metadata.addons.storage.productCode
                );

                composerObject.summaryObject.push(
                    {
                        account: metadata.account,
                        factSheets: [{
                            description: '',
                            label: this.$translate.instant('TR_110419-f5d08d_TR'),
                            type: ('string' as const),
                            value: ncBillingCycleTranslation
                        }, {
                            description: '',
                            label: this.$translate.instant('dc44bf44-77e8-4da8-9e0c-36bf36a945f1'),
                            type: ('integer' as const),
                            value: `${metadata.addons.storage.amount} x`
                                + ` ${this.formatMegabyte(metadata.addons.storage.step)}`
                        }].concat(storageFactSheet),
                        product: metadata.addons.storage.productData,
                        productCode: metadata.addons.storage.productCode,
                        priceMultiplication: metadata.addons.storage.amount
                    }
                );
            }

            if (metadata.addons.users && metadata.addons.users.amount > 0) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                composerObject.apiObject.nextcloud.maxNumberOfUsers = metadata.addons.users.current;

                const usersFactSheet = await this.wizardNewHelper.factSheetConditioner(
                    metadata.addons.users.productCode
                );

                composerObject.summaryObject.push(
                    {
                        account: metadata.account,
                        factSheets: [{
                            description: '',
                            label: this.$translate.instant('TR_110419-f5d08d_TR'),
                            type: ('string' as const),
                            value: ncBillingCycleTranslation
                        }, {
                            description: '',
                            label: this.$translate.instant('dc44bf44-77e8-4da8-9e0c-36bf36a945f1'),
                            type: ('integer' as const),
                            value: `${metadata.addons.users.amount} x ${metadata.addons.users.step}`
                                + ` ${this.$translate.instant('TR_220519-49de95_TR')}`
                        }].concat(usersFactSheet),
                        product: metadata.addons.users.productData,
                        productCode: metadata.addons.users.productCode,
                        priceMultiplication: metadata.addons.users.amount
                    }
                );
            }

            if (metadata.addons.talkUsers && metadata.addons.talkUsers.amount > 0) {
                const appId = 'talk_hpb';
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                composerObject.apiObject.nextcloud.talkUsers = metadata.addons.talkUsers.current;
                composerObject.apiObject.nextcloud.enabledApps.push(appId);
                const appConfig = await this.getAppconfigDefaults(appId);

                if (appConfig !== null) {
                    composerObject.apiObject.appConfigs.push(appConfig);
                }

                const talkUsersFactSheet = await this.wizardNewHelper.factSheetConditioner(
                    metadata.addons.talkUsers.productCode
                );

                composerObject.summaryObject.push(
                    {
                        account: metadata.account,
                        factSheets: [{
                            description: '',
                            label: this.$translate.instant('TR_110419-f5d08d_TR'),
                            type: ('string' as const),
                            value: ncBillingCycleTranslation
                        }, {
                            description: '',
                            label: this.$translate.instant('dc44bf44-77e8-4da8-9e0c-36bf36a945f1'),
                            type: ('string' as const),
                            value: `${metadata.addons.talkUsers.amount} x ${metadata.addons.talkUsers.step}`
                                + ` ${this.$translate.instant('TR_220519-49de95_TR')}`
                        }].concat(talkUsersFactSheet),
                        product: metadata.addons.talkUsers.productData,
                        productCode: metadata.addons.talkUsers.productCode,
                        priceMultiplication: metadata.addons.talkUsers.amount
                    }
                );
            }

            if (metadata.addons.officeUsers && metadata.addons.officeUsers.amount > 0) {
                const appId = 'collabora';
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                composerObject.apiObject.nextcloud.officeUsers = metadata.addons.officeUsers.current;
                composerObject.apiObject.nextcloud.enabledApps.push(appId);
                const appConfig = await this.getAppconfigDefaults(appId);

                if (appConfig !== null) {
                    composerObject.apiObject.appConfigs.push(appConfig);
                }

                const officeUsersFactSheet = await this.wizardNewHelper.factSheetConditioner(
                    metadata.addons.officeUsers.productCode
                );

                composerObject.summaryObject.push(
                    {
                        account: metadata.account,
                        factSheets: [{
                            description: '',
                            label: this.$translate.instant('TR_110419-f5d08d_TR'),
                            type: ('string' as const),
                            value: ncBillingCycleTranslation
                        }, {
                            description: '',
                            label: this.$translate.instant('dc44bf44-77e8-4da8-9e0c-36bf36a945f1'),
                            type: ('string' as const),
                            value: `${metadata.addons.officeUsers.amount} x ${metadata.addons.officeUsers.step}`
                                + ` ${this.$translate.instant('TR_220519-49de95_TR')}`
                        }].concat(officeUsersFactSheet),
                        product: metadata.addons.officeUsers.productData,
                        productCode: metadata.addons.officeUsers.productCode,
                        priceMultiplication: metadata.addons.officeUsers.amount
                    }
                );
            }

            return composerObject;
        });
    };

    private getAppconfigDefaults = async (appId: string) => {
        const apiResponse = await this.managedApplicationRobot
            .nextcloudAppGetConfiguration(
                appId,
                null,
                UiLanguagesConst[AuthContextService.user.language]
            )
            .then((response) => response.response);

        if (apiResponse.appConfigSchema === null) {
            return null;
        }

        const appConfiguration: unknown = {};
        const configSchema: FormDescriptionSpec.FormDescriptionLayout = JSON.parse(apiResponse.appConfigSchema);

        for (const field of configSchema.data.fields) {
            // @ts-ignore: TS7053
            appConfiguration[field.key] = field.default;
        }

        return { appId: appId, configuration: JSON.stringify(appConfiguration) };
    };
}
