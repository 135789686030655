import ng from 'angular';

import { InternalForwarderProductFamilyMapper } from '@/configuration';
import { CalculatePriceFilter } from '@/filters';
import {
    AuthContextService, PriceHelperService, ProductBoxContent, ProductHelperService
} from '@/services';
import * as Types from '@/types';

import { WizardHelperService, WizardObject } from '../../../wizard';

export class MoleculeFormProductBoxController implements ng.IController {
    public static $inject: string[] = [
        '$state',
        'authContext',
        'calculatePriceFilter',
        'priceHelper',
        'productHelper',
        'wizardHelper',
        '$translate'
    ];
    public account: Types.AccountApi.Account;
    public boxContent: ProductBoxContent;
    public isSelected: boolean;
    public showAllItems = false;
    public ignoreTabIndex = false;
    public isCurrentProduct = false;
    public displayPrice = true;
    public hideContractPeriod = false;

    public recommend = false;
    public recommendAny = false;

    private numberElementsShown = 0;
    private elementsShown: any = [];
    private maxElementsPerBox = 5;
    private keywordsDescriptions: any = [];
    private selectedContingent: null|string = null;
    private serviceObject: WizardObject;
    private possibleContingents = null;
    private externalCostsMoney: boolean;

    constructor(
        private $state: ng.ui.IStateService,
        private authContext: AuthContextService,
        private calculatePriceFilter: CalculatePriceFilter,
        private priceHelper: PriceHelperService,
        private productHelper: ProductHelperService,
        private wizardHelper: WizardHelperService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit(): void {
        this._checkPossibleContingents();
        if ('keywordsDescriptions' in this.boxContent) {
            this.keywordsDescriptions = this.boxContent.keywordsDescriptions;
        }
        this.elementsShown = [];
        this.numberElementsShown = 0;
    }

    public getTabIndex = () => {
        if (this.ignoreTabIndex === true) {
            return '-1';
        }
        return '0';
    };

    public $onChanges(changes: any) {
        if (changes.account && changes.account.currentValue !== undefined) {
            this.possibleContingents = null;
            this._checkPossibleContingents(undefined, changes.account.currentValue.id);
        }
        if (changes.selectedContingent !== undefined) {
            if (changes.selectedContingent.currentValue === null) {
                // reset possible contingents
                this.possibleContingents = null;
            } else {
                // selected contingents has changed in wizardPoolBundleAvailability molecule component (selectbox)
                const contingentObjectId = changes?.selectedContingent?.currentValue?.value
                    ? changes.selectedContingent.currentValue.value
                    : changes.selectedContingent.currentValue;
                this._checkPossibleContingents(contingentObjectId);
            }
        }
    }

    public getFactLabelTranslation = (property, raw?) => {
        raw = raw || false;

        if (!(property.keyword in this.elementsShown) && this.numberElementsShown <= this.maxElementsPerBox) {
            this.numberElementsShown++;
            this.elementsShown[property.keyword] = this.wizardHelper.getFactsheetTranslatedDescription(
                this.keywordsDescriptions,
                property
            );
        }

        return this.wizardHelper.getFactsheetLabelTranslation(this.elementsShown[property.keyword], raw);
    };

    public isFactValueBool = (property) => {
        const factDetail = this.getFactLabelTranslation(property, true);
        if ([undefined, null].indexOf(factDetail.type) >= 0) {
            return false;
        }
        return factDetail.type.toLowerCase() === 'bool';
    };

    public getFactValueTranslation = (property) => {
        return this.wizardHelper.getFactValueTranslation(
            this.getFactLabelTranslation(property, true),
            property
        );
    };

    public isInSummary = (property: any) => {
        if (this.showElement(property.keyword) === false && this.numberElementsShown === this.maxElementsPerBox) {
            return false;
        }
        if (this.boxContent.summaryKeywords === undefined) {
            return true;
        }
        for (const keyword of this.boxContent.summaryKeywords) {
            if (keyword.category === property.category && keyword.keyword === property.keyword) {
                return true;
            }
        }
        return false;
    };

    public get isSelectedContingent() {
        return [undefined, null].indexOf(this.selectedContingent) === -1;
    }

    public get pricesExist() {
        return this.boxContent.prices.every((price) => [undefined, null].indexOf(price.price) === -1
            && price.price !== -1);
    }

    public get freeOfCharge() {
        return this.possibleContingents !== null
            || (
                this.isSelectedContingent
                && this.isSelected
            )
            || (
                this.$state.$current.locals.globals.hasManagedServer
                && this.$state.$current.locals.globals.databaseRessource
            );
    }

    public set freeOfCharge({}) {} // tslint:disable-line:no-empty

    public get chargeable() {
        return !this.freeOfCharge && (!this.isSelectedContingent || !this.isSelected);
    }

    public set chargeable({}) {} // tslint:disable-line:no-empty

    public get costsMoney() {
        return (this.externalCostsMoney || this.chargeable) && !this.productIsInternalForwarder;
    }
    public set costsMoney({}) {} // tslint:disable-line:no-empty

    public get cost() {
        return this.wizardHelper.calculateProductPrice(
            this.boxContent.prices, this.authContext.account
        );
    }

    public get currency() {
        return this.wizardHelper.getProductCurrency(this.boxContent.prices[0].price) + ' ';
    }

    public get hasBillingCycles() {
        if ([undefined, null].indexOf(this.boxContent.prices) >= 0 || this.boxContent.prices.length === 0) {
            return false;
        }
        if (this.boxContent.prices.length > 1) {
            return true;
        }
        return (
            this.boxContent.prices[0].billingCycle !== null
            || this.boxContent.prices[0].billingCycle !== undefined
        );
    }

    public get productIsInternalForwarder() {
        return this.boxContent.family === InternalForwarderProductFamilyMapper
            && this.serviceObject.display.internalForwarder;
    }

    public set productIsInternalForwarder({}) {} // tslint:disable-line:no-empty

    public get contractPeriod() {
        if (this.boxContent.prices === undefined || this.boxContent.prices.length === 0) {
            return undefined;
        }
        return this.boxContent.prices[0].price.contractPeriod;
    }

    public get hasCost() {
        return this._hasPricesPrice(this.boxContent.prices)
            && this.boxContent.prices !== undefined
            && this.boxContent.prices.length > 0;
    }

    public get moreThanFiveItems() {
        if (this.boxContent.factSheetItems === undefined) {
            return false;
        }

        return this.boxContent.factSheetItems
        .filter((item) => this.isInSummary(item))
        .length > 5;
    }

    public getBillingCycleTranslation = (cycle) => {
        return this.wizardHelper.getBillingCycleTranslation(cycle);
    };

    public toggleShowAll = () => {
        this.showAllItems = !this.showAllItems;
    };

    private _hasPricesPrice = (prices) => {
        // check if list of prices has a price witch is not equal -1
        // -1 => account has no showPriceInfo rights
        if ([undefined, null].indexOf(this.boxContent.prices) >= 0) {
            return false;
        }

        return prices.some((priceObject) =>
            [undefined, null].indexOf(priceObject.price) < 0
            && [undefined, null, -1].indexOf(priceObject.price.price) < 0
        );
    };

    private showElement = (element: string) => {
        return element in this.elementsShown || this.elementsShown.hasOwnProperty(element);
    };

    private _checkPossibleContingents = (contingentObjectId?: string, accountId?: string) => {
        if ([undefined, null].indexOf(this.boxContent.prices) >= 0 || this.boxContent.prices.length < 1) {
            return;
        }
        const givenProductCode = this.boxContent.prices[0].productCode;
        if (contingentObjectId !== undefined) {
            this.productHelper.getContingentByObjectId(contingentObjectId, givenProductCode).then(
                (cObjectRes) => {
                    this.possibleContingents = cObjectRes;
                }
            );
        } else if (this.$state.$current.locals.globals.bundle) {
            this.possibleContingents = this.productHelper.checkBundleOfPossibleContingent(
                this.$state.$current.locals.globals.bundle,
                givenProductCode
            );
        } else {
            this.productHelper.productRegisterOnProductCode(givenProductCode, accountId).then((res) => {
                this.possibleContingents = res;
            });
        }
    };
}

export class MoleculeFormProductBoxComponent implements ng.IComponentOptions {
    public bindings = {
        account: '<',
        boxContent: '<',
        displayPrice: '<',
        hideContractPeriod: '<?',
        ignoreTabIndex: '<',
        isCurrentProduct: '<',
        isSelected: '<',
        recommend: '<?',
        recommendAny: '<?',
        selectedContingent: '<',
        serviceObject: '<',
        externalCostsMoney: '=?costsMoney'
    };
    public template = require('./product-box.html');
    public controller =  MoleculeFormProductBoxController;
}
