import * as ng from 'angular';
import { ProductsModelService, ValidateFqdn, ValidateLineNumberMaximum } from '../../../../../../services';
import { ViewTypes } from '../../../../../../types/view-types';

export class OrganSslWizardProductConfigCertificateAdditionalDomainsController {
    public static $inject: string[] = [
        '$translate',
        'productsModel'
    ];

    public metaData: ViewTypes.ProductConfigSslObject;
    public sslValidationObject: ViewTypes.SslValidationObject;
    public additionalDomainsListValidationInstructions: any;
    public validationInstructionsOperator: string;

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private productsModel: ProductsModelService
    ) {}

    public $onInit() {
        this.additionalDomainsListValidationInstructions = [{
            instructions: null,
            validator: new ValidateFqdn(this.$translate, false, true)
        },
        {
            instructions: null,
            validator:new ValidateLineNumberMaximum(
                this.$translate,
                this.productSpecification('maxAdditionalDomainNames'),
                this.$translate.instant('TR_201119-712d0e_TR'))
        }];
        this.validationInstructionsOperator = '&&';
    }

    public set additionalDomains(additionalDomains: string) {
        this.metaData.additionalDomains = additionalDomains.split('\n');
    }
    public get additionalDomains() {
        return this.metaData.additionalDomains.join('\n');
    }

    public get additionalDomainsUsed() {
        if (this.metaData.additionalDomains.length !== 0
            && [null, undefined, ''].indexOf(this.metaData.additionalDomains[0]) >= 0) {
            return 0;
        }
        return this.metaData.additionalDomains.length;
    }

    public productSpecification(name: string) {
        const result = this.metaData.settings.productSpecification.filter(
            (spec: { name: string; value: any }) => spec.name === name);

        if (result.length > 0) {
            return parseInt(result[0].value, 10);
        }
        return 0;
    }
}

export class OrganSslWizardProductConfigCertificateAdditionalDomainsComponent implements ng.IComponentOptions {
    public bindings = {
        metaData: '<',
        sslValidationObject: '=',
        sslProductCode: '<'
    };
    public template = require('./config-certificate-additional-domains.html');
    public controller = OrganSslWizardProductConfigCertificateAdditionalDomainsController;
}
