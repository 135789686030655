import * as ng from 'angular';

export class MoleculePanelEditRowCheckboxController implements ng.IController {
    public static $inject: string[] = [];

    public model: boolean;
    public checkboxText: string;
    public modifier: string;
    public stroke: boolean;
    public disabled: boolean;
    public callbackOnClick: () => {};
    public hideLabel: boolean;

    public $onInit() {
        this.model = this.model || false;
        this.checkboxText = this.checkboxText || '';
        this.stroke = this.stroke || false;
        this.disabled = this.disabled || false;
    }
}

export class MoleculePanelEditRowCheckboxComponent implements ng.IComponentOptions {
    public transclude = {
        label: '?rowLabel',
        value: '?rowValue'
    };
    public bindings = {
        callbackOnClick: '<',
        checkboxText: '@',
        disabled: '<',
        hideLabel: '<?',
        model: '=model',
        modifier: '@',
        stroke: '<'
    };
    public controller = MoleculePanelEditRowCheckboxController;
    public controllerAs = '$panelRow';
    public template = require('./panel-edit-row-checkbox.html');
}
