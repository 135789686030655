import * as ng from 'angular';
import { OrganismOverviewController, OverviewDataObject } from '../../../../../organisms';

export class MoleculeOverviewRowPanelDdnsHostnamesController implements ng.IController {
    public static $inject: string[] = [];

    public overviewData: OverviewDataObject;
    public OrganismOverview: OrganismOverviewController;
    public overviewLoad: (selection: any) => any;
    public internalPaginationLimit: number;

    public get showAccounts() {
        return this.OrganismOverview.globals.showAccounts;
    }
}

export class MoleculeOverviewRowPanelDdnsHostnamesComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelDdnsHostnamesController;
    public controllerAs = 'OverviewDdnsHostnamesCtrl';
    public template = require('./ddns-hostnames-row.html');
}
