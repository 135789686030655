export * from './backup/';
export * from './database/';
export * from './dns-record/panel-create-dns-record';
export * from './location/panel-create-location';
export * from './nextcloud-group-folder/panel-create-nextcloud-group-folder';
export * from './two-f-a/';
export * from './user/';
export * from './user-access/';
export * from './webspace/';

import './panel-create-new.scss';
import './panel-create.scss';
