import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';

export class TemplateBundleDomainsTransferController {
    public static $inject: string[] = ['$state', '$translate', 'authContext'];

    public modifier = '';
    public pageHeaderData: PanelHeaderData;
    public domains: any;
    public faqArticleIdList: string[] = [
        'domain-authcode-definition',
        'domain-create-authcode'
    ];

    public userPanelRight;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            panelHeaderRoute: '',
            panelIcon: 'doamin',
            panelTitle: this.$translate.instant('TR_090119-86f4b3_TR'),
            backwardText: this.$translate.instant('TR_090119-a32485_TR'),
            backwardLink: 'bundle.id.dashboard',
            panelHeaderRouteParams: { bundleId: this.$state.$current.locals.globals.bundle.id }
        };

        this.userPanelRight = {
            transfer: {
                editPanelButton: this.authContext.isGranted(UiRights.DOM_DOMAINS_AUTHINFO_CREATE)
            }
        };
    };
}

export class TemplateBundleDomainsTransferComponent implements ng.IComponentOptions {
    public bindings = {
        domains: '<'
    };
    public controller = TemplateBundleDomainsTransferController;
    public controllerAs = '$TemplateBundleDomainsTransferCtrl';
    public template = require('./domains-transfer-template.html');
}
