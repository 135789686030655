import * as ng from 'angular';
import * as q from 'q';
import {
    AlertManagerService,
    DnsZoneModelService,
    NavigationService
} from '../../../../../services';

import './dns-record-edit.scss';

export class OrganismEditFormDnsRecordsController {
    public static $inject: string[] = ['$state', '$translate', 'alertManager', 'dnsZoneModel', 'navigation'];

    public defaultNameserver: any[];
    public zone: any;
    public records: any[];
    public originalRecords: any[];
    public recordsMarkedForDeletion: string[] = [];
    public changedRecords: string[] = [];
    public addedRecords: string[] = [];
    public editMode = true;
    public templateRecords = [];
    public nonTemplateRecords = [];

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private dnsZoneModel: DnsZoneModelService,
        private navigation: NavigationService
    ) {}

    public $onInit() {
        this.originalRecords = ng.copy(this.records);
        this.templateRecords = ng.copy(
            this.records.filter((record) => [undefined, null, ''].indexOf(record.recordTemplateId) < 0)
        );
        this.nonTemplateRecords = ng.copy(
            this.records.filter((record) => [undefined, null, ''].indexOf(record.recordTemplateId) >= 0)
        );
    }

    public save = () => {
        const remove = [];
        const add = [];
        const changedRecords = JSON.parse(JSON.stringify(this.records));

        for (let record of changedRecords) {
            record = this._normalizeRecord(record);
        }
        for (const record of this.originalRecords) {
            if (this.recordsMarkedForDeletion.indexOf(record.id) >= 0) {
                remove.push(record);
            } else if (this.changedRecords.indexOf(record.id) >= 0) {
                add.push(this.getRecordWithId(record.id, changedRecords));
                remove.push(record);
            }
        }
        for (const record of changedRecords) {
            if (this.addedRecords.indexOf(record.id) >= 0 && this.recordsMarkedForDeletion.indexOf(record.id) < 0) {
                add.push(record);
            }
        }

        return this.dnsZoneModel.updateZone(this.zone, add, remove).then(
            (reply) => {
                if (['pending', 'success'].indexOf(reply.status) >= 0) {
                    this.alertManager.success(this.$translate.instant('TR_100119-1250ee_TR'));
                    if ([undefined, null, ''].indexOf(this.$state.params.fromZoneEdit) === -1) {
                        this.navigation.go(this.$state.params.fromZoneEdit, {zoneId: this.zone.id}, {reload: true});
                        return q.reject(false);
                    } else {
                        this.editMode = false;
                        this.records = ng.copy(reply.response.records);
                        this.originalRecords = ng.copy(reply.response.records);
                        this.templateRecords = ng.copy(
                            this.records.filter((record) => [undefined, null, ''].indexOf(record.recordTemplateId) < 0)
                        );
                        this.nonTemplateRecords = ng.copy(
                            this.records.filter((record) => [undefined, null, ''].indexOf(record.recordTemplateId) >= 0)
                        );
                        this.changedRecords = [];
                        this.recordsMarkedForDeletion = [];
                        return reply;
                    }
                }

                return q.reject(false);
            });
    };

    public getRecordWithId = (id: string, recordList: any[]) => {
        for (const record of recordList) {
            if (record.id === id) {
                return record;
            }
        }
    };

    private _normalizeRecord = (record: { name: string; type: any; priority: number; ttl: number }) => {
        record.name = record.name.lastIndexOf('.') === record.name.length - 1
            ? record.name + this.zone.name
            : record.name + '.' + this.zone.name;

        record.ttl = Number(record.ttl);

        if (['mx', 'srv'].indexOf(record.type.toLowerCase()) === -1) {
            delete record.priority;
        } else {
            record.priority = +record.priority;
        }

        return record;
    };
}

export class OrganismEditFormDnsRecordsComponent implements ng.IComponentOptions {
    public bindings = {
        defaultNameserver: '<?',
        records: '<',
        zone: '<'
    };
    public controller = OrganismEditFormDnsRecordsController;
    public template = require('./dns-records-edit.html');
}
