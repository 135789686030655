import * as ng from 'angular';
import { PanelType } from '@/atomic-components/molecules/forms/form-edit/form-edit';
import {
    OrganismEditFormNextcloudController
} from '@/atomic-components/organisms/forms/edit-forms/nextcloud-edit/nextcloud-edit';
import { ManagedApplicationRobotService, NavigationService } from '@/services';
import { ManagedApplicationApi, UI } from '@/types';

export class OrganEditPanelNextcloudGroupsController {
    public static $inject: string[] = ['$timeout', 'managedApplicationRobot', 'navigation'];
    public $editFormOrganism: OrganismEditFormNextcloudController;
    public componentInitialized = false;
    public typeOverride = PanelType.MISCELLANEOUS_ACTION;
    public availableUsers: ManagedApplicationApi.NextcloudUser[];
    public groups: ManagedApplicationApi.NextcloudUserGroup[];
    public groupBeingEdited: boolean|number = false;

    public constructor(
        private $timeout: ng.ITimeoutService,
        private managedApplicationRobot: ManagedApplicationRobotService,
        private navigation: NavigationService
    ) {}

    public $onInit(): void {
        void this.managedApplicationRobot.nextcloudGroupUserMatrixGet(this.$editFormOrganism.nextcloud.id)
            .then(
                (apiResponse: UI.SynchronousAPIResponse<ManagedApplicationApi.NextcloudGroupMatrixResult>) => {
                    const groups = apiResponse.response.groups;
                    const users = apiResponse.response.users;
                    const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

                    this.availableUsers = users;

                    void this.$timeout(() => {
                        this.groups = groups.map(
                            (group: { group: ManagedApplicationApi.NextcloudGroup; usernames: string[] }) => {
                                // eslint-disable-next-line @typescript-eslint/unbound-method
                                group.usernames.sort(collator.compare);

                                return ({
                                    name: group.group.name,
                                    members: group.usernames.map((username) => {
                                        const targetUser = users.filter((ncUser) => ncUser.username === username);
                                        return targetUser[0];
                                    }),
                                    props: {
                                        isNewGroup: false,
                                        isBeingEdited: false,
                                        isMarkedForRemoval: false,
                                        hasBeenChanged: false,
                                        displayEmptyGroupsError: false
                                    }
                                } as ManagedApplicationApi.NextcloudUserGroup);
                            }
                        );
                        this.componentInitialized = true;
                    });
                }
            );
    }

    public cancel = (): void => {
        this.navigation.reloadCurrentState();
        this.$editFormOrganism.groupsToDelete = [];
    };

    public addNewGroup = (): void => {
        this.groups.unshift({
            members: [],
            name: '',
            props: {
                displayEmptyGroupsError: false,
                hasBeenChanged: false,
                isBeingEdited: true,
                isMarkedForRemoval: false,
                isNewGroup: true
            }
        });
        this.groupBeingEdited = 0;
    };

    public get disableSaveButton(): boolean {
        if (this.groups === undefined) {
            return true;
        }

        return this.groups.some(
            (group) => group.props.isBeingEdited === true
        );
    }

    public get disableAddButton(): boolean {
        if (this.groups === undefined) {
            return true;
        }

        return this.groups.some(
            (group) => group.props.isNewGroup === true
        );
    }
}

export class OrganEditPanelNextcloudGroupsComponent implements ng.IComponentOptions {
    public require = {
        $editFormOrganism: '^organismEditFormNextcloud'
    };

    public controllerAs = '$editPanelOrgan';
    public template = require('./nextcloud-groups.html');
    public controller = OrganEditPanelNextcloudGroupsController;
}
