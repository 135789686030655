import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels';
import { DashboardOverviewObject } from '../../../atomic-components/organisms/overview';
import { UiRights } from '../../../configuration';
import { AuthContextService, DomainOverviewModel, FilterCacheService } from '../../../services';
import * as Types from '../../../types';

export class TemplateDomainDashboardController {
    public static $inject: string[] = ['$translate', 'authContext', 'domainOverviewModel', 'filterCache'];

    public userHasRightDomDomainsList = AuthContextService.isGranted(UiRights.DOM_DOMAINS_LIST); // used in template!
    public pageHeaderData: PanelHeaderData;
    public dashboardOverview: DashboardOverviewObject;
    public webspaces: Types.WebhostingApi.Webspace[] = [];
    public faqArticleIdList: string[] = [
        'domain-register-new',
        'domain-create-authcode',
        'domain-terminate'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private domainOverviewModel: DomainOverviewModel,
        private filterCache: FilterCacheService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: '',
            backwardText: '',
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: 'globe',
            panelTitle: this.$translate.instant('TR_110119-97efac_TR')
        };

        this.dashboardOverview = {
            // List of table service data
            overviewsData: [
                {
                    listOptions: {
                        limit: 6,
                        sort: {
                            field: 'DomainLastChangeDate',
                            order: 'DESC'
                        }
                    },
                    overviewModel: this.domainOverviewModel,
                    panelHeaderData: {
                        includeShowAllLink: true,
                        includeShowDetailsLink: false,
                        panelHeaderRoute: 'domain.domains.overview',
                        panelHeaderRouteParams: {},
                        panelIcon: 'globe',
                        panelTitle: this.$translate.instant('TR_140119-aeb48a_TR')
                    },
                    serviceItemList: []
                }
            ],
            // Dashboard main menu panel
            panelData: {
                addtionalActionData: {
                    // Addition action panel
                    linkList: [
                        {
                            isGranted: this.authContext.isGranted(UiRights.DOM_JOBS_LIST),
                            route: 'domain.domains.jobs',
                            routeParams: {},
                            text: this.$translate.instant('MENU.CONTEXT.JOBS')
                        },
                        {
                            isGranted: this.authContext.isGranted(UiRights.DOM_CONTACTS_LIST),
                            route: 'domain.contacts.overview',
                            routeParams: {},
                            text: this.$translate.instant('DOMAIN.GENERAL.HANDLES')
                        },
                        {
                            isGranted: this.authContext.isGranted(UiRights.WEB_REDIRECTIONS_LIST),
                            route: 'domain.redirections.overview',
                            routeParams: {},
                            text: this.$translate.instant('MENU.CONTEXT.DOMAIN.REDIRECTIONS')
                        },
                        {
                            isGranted: this.authContext.isGranted(UiRights.DOM_CONTACTS_LIST),
                            route: 'domain.contacts.verification',
                            routeParams: {},
                            text: this.$translate.instant('GENERAL.JOB.ACTION.CONTACTOWNERVERIFICATION')
                        },
                        {
                            isGranted: this.authContext.isGranted(UiRights.DOM_DOMAINS_CREATE_WITH_DEFAULTS)
                                && this.webspaces.length >= 1,
                            route: 'domain.domains.new',
                            routeParams: {domainType: 'subdomain'},
                            text: this.$translate.instant('TR_090119-719dc6_TR')
                        },
                        {
                            isGranted: this.authContext.isGrantedAny([
                                UiRights.DOM_DOMAINS_CREATE_WITH_CONTACTS,
                                UiRights.DOM_DOMAINS_CREATE_WITH_DEFAULTS,
                                UiRights.DOM_DOMAINS_CREATE_WITH_OWN_NS
                            ]),
                            route: 'domain.availability-check',
                            routeParams: {},
                            text: this.$translate.instant('MENU.CONTEXT.DOMAIN.AVAIL-CHECK')
                        },
                        {
                            isGranted: this.authContext.isGranted(UiRights.DOM_JOBS_LIST),
                            route: 'domain.transfers',
                            routeParams: {},
                            text: this.$translate.instant('MENU.CONTEXT.DOMAIN.TRANSFER-OUT')
                        },
                        {
                            isGranted: this.authContext.isGranted(UiRights.DOM_DOMAINS_LIST),
                            route: 'domain.reports',
                            routeParams: {},
                            text: this.$translate.instant('MENU.CONTEXT.DOMAIN.REPORTS')
                        },
                        {
                            isGranted: this.authContext.isGranted(UiRights.DOM_DOMAINS_AUTHINFO_CREATE),
                            route: 'domain.authinfotwo',
                            routeParams: {},
                            text: this.$translate.instant('TR_110119-c1e706_TR')
                        },
                        {
                            isGranted: this.authContext.isGranted(UiRights.DOM_DOMAINS_CREATE_WITH_DEFAULTS)
                                && this.webspaces.length >= 1,
                            route: 'domain.domains.new',
                            routeParams: {domainType: 'external'},
                            text: this.$translate.instant('TR_090119-91145a_TR')
                        }
                    ]
                },
                mainHeader: {
                    icon: 'globe',
                    route: 'domain.domains.new',
                    routeParams: {},
                    text: this.$translate.instant('9e7c3a49-73df-4f43-988f-02fe8bf56a67')
                }
            },
            simpleFilter: this.filterCache.resolveSimpleFilter('Domain')
        };
    };
}

export class TemplateDomainDashboardComponent implements ng.IComponentOptions {
    public bindings = {
        webspaces: '<?'
    };
    public controller = TemplateDomainDashboardController;
    public controllerAs = '$TemplateDomainDashboardCtrl';
    public template = require('./domain-dashboard.html');
}
