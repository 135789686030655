import { ManagedApplicationApi } from '@/types/managed-application-api';

export interface StorageAppConfig {
    enabledApps: string[];
    appConfigurations: ManagedApplicationApi.NextcloudAppConfigInput[];
}

export class StorageAppConfigService {
    public static $inject: string[] = [];

    private _storageAppConfig: StorageAppConfig;

    public saveStorageApps = (
        id: string,
        enabledApps: string[],
        appConfigurations: ManagedApplicationApi.NextcloudAppConfigInput[]
    ): StorageAppConfigService => {
        const config = {
            enabledApps: enabledApps,
            appConfigurations: appConfigurations
        };
        this._storageAppConfig = this._validateStorageAppConfig(config);
        localStorage.setItem(id, JSON.stringify(this._storageAppConfig));
        return this;
    };

    public saveStorageEnabledAppConfig = (
        id: string,
        enabledApps: string[]
    ): StorageAppConfigService => {
        const config = {
            enabledApps: enabledApps,
            appConfigurations: this._storageAppConfig?.appConfigurations
        };
        this._storageAppConfig = this._validateStorageAppConfig(config);
        localStorage.setItem(id, JSON.stringify(this._storageAppConfig));
        return this;
    };

    public saveStorageAppConfigs = (
        id: string,
        appConfigurations: ManagedApplicationApi.NextcloudAppConfigInput[]
    ): StorageAppConfigService => {
        const config = {
            enabledApps: this._storageAppConfig?.enabledApps,
            appConfigurations: appConfigurations
        };
        this._storageAppConfig = this._validateStorageAppConfig(config);
        localStorage.setItem(id, JSON.stringify(this._storageAppConfig));
        return this;
    };

    public saveStorageAppConfig = (
        id: string,
        appConfiguration: ManagedApplicationApi.NextcloudAppConfigInput
    ): StorageAppConfigService => {
        const _appConfigurations = [];
        const configs = this._storageAppConfig?.appConfigurations?.
            filter((config: unknown) => [undefined, null].includes(config) === false);
        _appConfigurations.push(...configs);
        if ([undefined, null].includes(appConfiguration) === false) {
            _appConfigurations.push(appConfiguration);
        }
        const config = {
            enabledApps: this._storageAppConfig?.enabledApps,
            appConfigurations: _appConfigurations
        };
        this._storageAppConfig = this._validateStorageAppConfig(config);
        localStorage.setItem(id, JSON.stringify(this._storageAppConfig));
        return this;
    };

    public loadStorageAppConfig = (id: string): StorageAppConfig => {
        if (!window.localStorage) {
            throw Error(`Browser not supported for Storage App Settings ${navigator.userAgent}`);
        }
        this._storageAppConfig = JSON.parse(localStorage.getItem(id)) as StorageAppConfig;
        if ([null, undefined].includes(this._storageAppConfig)) {
            return this._storageAppConfig;
        }
        this._storageAppConfig = this._validateStorageAppConfig(this._storageAppConfig);
        return this._storageAppConfig;
    };

    public deleteStorageAppConfig = (id: string): void => {
        localStorage.removeItem(id);
        this._storageAppConfig = null;
    };

    private _validateStorageAppConfig(_storageAppConfig: StorageAppConfig): StorageAppConfig {
        if (_storageAppConfig.appConfigurations?.every((app) => _storageAppConfig.enabledApps.includes(app.appId))) {
            return _storageAppConfig;
        }
        for (const configs of _storageAppConfig.appConfigurations) {
            if (!_storageAppConfig.enabledApps.includes(configs.appId)) {
                _storageAppConfig.enabledApps.push(configs.appId);
            }
        }
        return _storageAppConfig;
    }
}
