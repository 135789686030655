import * as ng from 'angular';
import './circle.scss';

export class AtomCircleController implements ng.IController {
    public static $inject: string[] = [];

    public addonPercentage: string;
    public reachedPercentage: number;
    public statusModifier = '';
    public ignoreStatusModifier = false;

    // Display
    public size: number;

    public $onInit() {
        if ([undefined, null, 0].indexOf(this.size) >= 0) {
            this.size = 50;
        }

        if (Number(this.reachedPercentage) && !this.ignoreStatusModifier) {
            if (Number(this.reachedPercentage) <= 50) {
                return;
            }
            if (Number(this.reachedPercentage) > 50 && Number(this.reachedPercentage) <= 80) {
                this.statusModifier = 'atom-chart-circle--medium';
            } else {
                this.statusModifier = 'atom-chart-circle--high';
            }
        }
    }

    public get totalPercentage() {
        if ([undefined, null, ''].indexOf(this.addonPercentage) >= 0) {
            return 100;
        }

        return 100 + parseInt(`${this.addonPercentage}`, 10);
    }

    public showCircle() {
        return ['', '0', 0, undefined].indexOf(this.reachedPercentage) < 0;
    }
}

export class AtomCircleComponent implements ng.IComponentOptions {
    public bindings = {
        addonPercentage: '<',
        ignoreStatusModifier: '<',
        reachedPercentage: '<',
        size: '<',
        statusModifier: '@'
    };

    public controller = AtomCircleController;
    public template = require('./circle.html');
}
