import * as ng from 'angular';
import { DnsZoneModelService, RedirectionModelService } from '../../../../../services';

export class WizardServiceHelperRedirection {
    public static $inject: string[] = ['redirectionModel', 'dnsZoneModel'];

    constructor(
        private redirectionModel: RedirectionModelService,
        private dnsZoneModel: DnsZoneModelService
    ) {}

    public orderRedirection = (settings: any) => {
        const metaTags = [];
        if (settings.type === 'frame') {
            if (settings.metaTagAuthor !== undefined && settings.metaTagAuthor.length > 0) {
                metaTags.push({
                    content: settings.metaTagAuthor,
                    name: 'author'
                });
            }
            if (settings.metaTagKeywords !== undefined && settings.metaTagKeywords.length > 0) {
                metaTags.push({
                    content: settings.metaTagKeywords,
                    name: 'keywords'
                });
            }
            if (settings.metaTagDescription !== undefined && settings.metaTagDescription.length > 0) {
                metaTags.push({
                    content: settings.metaTagDescription,
                    name: 'description'
                });
            }
        }

        const domainNames = this.extractDomainNames(settings.domainName);
        return domainNames.map((domainName) => {
            const redirection = {
                domainName: domainName,
                metaTags: settings.type === 'frame' ? metaTags : undefined,
                redirectionUrl: settings.redirectionUrl,
                title: (settings.title !== undefined && settings.type === 'frame') ? settings.title : undefined,
                type: settings.type
            };
            return this.redirectionModel.create(redirection, settings.accountId).then(
                (reply) => {
                    if (
                        settings.adjustZone
                        && ['success', 'pending'].indexOf(reply.status) > 0
                    ) {
                        const currentZone = settings.zone.filter((zone) => {
                            return domainName === zone.name;
                        });
                        this.dnsZoneModel.findRecords(currentZone[0].id, {
                            field: 'recordType',
                            value: 'A'
                        }).then(
                            records => {
                                const targetIp = '213.160.69.3';
                                const recordsToDelete: any[] = [];
                                const recordsToAdd: any[] = [];
                                const alreadyCorrect: string[] = [];
                                const recordNames: string[] = [domainName, 'www.' + domainName];
                                for (const record of records) {
                                    for (const recordName of recordNames) {
                                        if (record.name === recordName) {
                                            if (record.content !== targetIp) {
                                                recordsToDelete.push(record);
                                            } else {
                                                alreadyCorrect.push(record.name);
                                            }
                                        }
                                    }
                                }
                                for (const recordName of recordNames) {
                                    if (alreadyCorrect.indexOf(recordName) < 0) {
                                        recordsToAdd.push({
                                            content: targetIp,
                                            name: recordName,
                                            ttl: 86400,
                                            type: 'A'
                                        });
                                    }
                                }
                                this.dnsZoneModel.updateZone(currentZone[0], recordsToAdd, recordsToDelete);
                            }
                        );
                    }
                    return reply;
                }
            );
        });
    };

    private extractDomainNames = (domainNames: string) => {
        if ([undefined, null, ''].indexOf(domainNames) >= 0) {
            return [];
        }

        return domainNames
            .split(/\r?\n/)
            .filter((line) => line.trim().length > 0)
            .filter((line, index, lines) => lines.indexOf(line) === index);
    };
}
