import ng from 'angular';
import { Contingent, ContingentType, DomainTypes } from '@/atomic-components/organs';
import { DomainOrderProductCodeSuffixes, HandleDefaultLabels } from '@/configuration';
import { AccountApi, BundleApi, DomainApi, ResourceApi, ViewTypes, WebhostingApi } from '@/types';

export class OrganismDomainWizardProductConfigController {
    public static $inject: string[] = ['$stateParams', '$timeout', '$translate'];

    public contactModalIsOpen = false;
    public domainSearchData: ViewTypes.WizardDomainSearchDataObject;
    public metadata: ViewTypes.ProductConfigDomainDomainObject;
    public nameserverComplete = false;
    public outerContingent: Contingent;
    public sufficientCreditAvailable = true;
    public hideSufficientCreditCheck = false;
    public vhostType: DomainTypes = DomainTypes.REGISTER;
    public webspace: WebhostingApi.Webspace;
    public webserverRessource: ResourceApi.Webserver;
    public formCompleted: Record<string, boolean> = {
        dnsRecordsUpdater: false,
        domainSelection: false,
        domainContacts: false,
        nameserver: true,
        vhost: true,
        webspace: false
    };

    private _account: AccountApi.Account | AccountApi.Subaccount;
    private _availableDomainListOfProductCodes: string[] = [];
    private _bundle: BundleApi.Bundle;
    private _chargebleDomainProductCodes: string[] = [];
    private _contingentProducts: ViewTypes.DomainContingentProduct[] = [];
    private _domainList: ViewTypes.WizardDomainObject[] = [];
    private _domainListComesFromOuter: boolean;
    private _selectedContingent: Contingent;
    private _selectedDomainListCached: string;
    private _selectedAccount: string;

    constructor(
        private $stateParams: ng.ui.IStateParamsService,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit(): void {
        this._domainListComesFromOuter = this.$stateParams.domainList;
        this._setMetadata();
        this.selectedDomainList = this.selectedDomainList || [];
    }

    public $doCheck(): void {
        if (this.metadata?.account?.id !== this._selectedAccount) {
            this._selectedAccount = this.metadata?.account?.id;
            this._updateDomainContacts();
        }

        if (JSON.stringify(this.metadata.selectedContingent) !== JSON.stringify(this._selectedContingent)) {
            this._checkSelectedContingent();
            this._selectedContingent = this.metadata.selectedContingent;
        }

        if (JSON.stringify(this.metadata.selectedDomains) !== this._selectedDomainListCached) {
            this.hideSufficientCreditCheck = true;
            this._selectedDomainListCached = JSON.stringify(this.metadata.selectedDomains);
            this._setProductCodeLists();
            this._domainList = this.domainList;

            void this.$timeout(() => {
                this.hideSufficientCreditCheck = false;
            });
        }

        if (JSON.stringify(this._domainList) !== JSON.stringify(this.domainList)) {
            this.hideSufficientCreditCheck = true;
            this._domainList = this.domainList;
            this._setProductCodeLists();

            void this.$timeout(() => {
                this.hideSufficientCreditCheck = false;
            });
        }
    }

    public set domainList(_) {/* */}
    public get domainList(): ViewTypes.WizardDomainObject[] {
        return this.domainSearchData.domainInfo;
    }

    public set configCompleted(_) {/* */}
    public get configCompleted(): boolean {
        if (!this.sufficientCreditAvailable) {
            return false;
        }

        return Object.keys(this.formCompleted).every((key) => this.formCompleted[key] !== false);
    }

    public set showMainConfiguration(_) {/* */}
    public get showMainConfiguration(): boolean {
        return this.formCompleted.domainSelection
            && this.formCompleted.webspace
            && this.sufficientCreditAvailable;
    }

    public set selectedDomainList(list: ViewTypes.WizardSelectedDomainObject[]) {
        if (this.metadata !== undefined) {
            this.metadata.selectedDomains = list;
        }
    }
    public get selectedDomainList(): ViewTypes.WizardSelectedDomainObject[] {
        if (!this.metadata?.selectedDomains) {
            return [];
        }
        return this.metadata.selectedDomains;
    }

    public get zoneUpdateDomains(): ViewTypes.WizardSelectedDomainObject[] {
        return this.selectedDomainList;
    }

    public set availableDomainListOfProductCodes(_) {/* */}
    public get availableDomainListOfProductCodes(): string[] {
        return this._availableDomainListOfProductCodes;
    }

    public set chargebleDomainProductCodes(_) {/* */}
    public get chargebleDomainProductCodes(): string[] {
        return this._chargebleDomainProductCodes;
    }

    public set enableDomainContactsPanel (_) {/* */}
    public get enableDomainContactsPanel (): boolean {
        if (!this.formCompleted.domainSelection) {
            return false;
        }

        return this.sufficientCreditAvailable;
    }

    public set enableDnsSettingPanel(_) {/* */}
    public get enableDnsSettingPanel(): boolean {
        return this.formCompleted.domainContacts
            && !this.contactModalIsOpen
            && this.nameserverComplete
            && this.formCompleted.nameserver;
    }

    public set hideNameserverConfigPanel(_) {/* */}
    public get hideNameserverConfigPanel(): boolean {
        if (!this.formCompleted.domainContacts) {
            return true;
        }

        if (this.metadata?.webspace
            && this.metadata.createWebspace
        ) {
            return true;
        }

        return (this.metadata?.webspace
            || this.contactModalIsOpen) as boolean;
    }

    public set showVhostConfigPanel(_) {/* */}
    public get showVhostConfigPanel(): boolean {
        if (!this.formCompleted.domainContacts) {
            return false;
        }

        if (this.metadata.createWebspace
            || this._hasContingentWithWebspace()
        ) {
            return true;
        }

        return this.metadata?.webspace
            && !this.contactModalIsOpen;
    }

    public set enableDnsSettingPanel1(_) {/* */}
    public get enableDnsSettingPanel1(): boolean {
        return this.formCompleted.domainContacts
            && !this.contactModalIsOpen
            && this.nameserverComplete
            && this.formCompleted.nameserver;
    }

    public get showDnsSettingPanel(): boolean {
        if (
            this.showVhostConfigPanel
            && (
                !this.formCompleted.vhost
                || !this.metadata.vhost
            )
        ) {
            // config with webspace and vhost config not completed
            return false;
        }

        if (
            this.hideNameserverConfigPanel
            && (
                !this.formCompleted.nameserver
                || !this.nameserverComplete
            )
        ) {
            return false;
        }

        return true;
    }

    public set contingentProducts(_) {/* */}
    public get contingentProducts(): ViewTypes.DomainContingentProduct[] {
        return this._contingentProducts;
    }

    public set selectedNameservers(_) {/* */}
    public get selectedNameservers(): ViewTypes.Nameservers[] | boolean {
        if (!this.metadata?.nameserver?.values) {
            return false;
        }

        return this.metadata.nameserver.values.nameservers === null
            ? this.metadata.nameserver.ownNameservers
            : this.metadata.nameserver.values.nameservers;
    }

    public set externalNameservers(_) {/* */}
    public get externalNameservers(): boolean {
        return this.metadata?.nameserver?.values?.type
            ? this.metadata.nameserver?.values.type === 'external'
            : false;
    }

    public set selectedNameserverId(_) {/* */}
    public get selectedNameserverId(): string {
        return this.metadata?.nameserver?.values?.id
            ? this.metadata.nameserver.values.id
            : undefined;
    }

    public set selectedVhost(_) {/* */}
    public get selectedVhost(): WebhostingApi.VHost {
        return this.metadata?.vhost;
    }

    public set selectedWebspace(_) {/* */}
    public get selectedWebspace(): WebhostingApi.Webspace {
        return this.metadata?.webspace;
    }

    private _hasContingentWithWebspace = (): boolean => {
        return [ContingentType.bundle].indexOf(this.metadata?.selectedContingent?.type) >= 0
        && !!this.metadata?.selectedContingent?.misc?.webspace;
    };

    private _setProductCodeLists = (): void => {
        this._availableDomainListOfProductCodes = [];
        this._chargebleDomainProductCodes = [];
        this.domainList.filter((domain) => {
            let checkDomainProductCode = true;

            if (this._domainListComesFromOuter) { // ??
                // if domainList comes from stateParams (domain checker), check only productcode of selected Domains
                checkDomainProductCode = this.selectedDomainList.some((selectedDomain) => {
                    return selectedDomain.domainName === domain.domain.domainNameUnicode;
                });
            }

            if (checkDomainProductCode) {
                const productCodeSuffix = domain.domain.status === 'available'
                    ? DomainOrderProductCodeSuffixes.renew
                    : DomainOrderProductCodeSuffixes.transfer;

                for (const price of domain.prices) {
                    const productCodeSuffixIndex = price.productCode.indexOf(productCodeSuffix);
                    if (
                        productCodeSuffixIndex === (price.productCode.length - productCodeSuffix.length)
                    ) {
                        // set domain productcode and add productcode to list of all available domain list
                        domain.productCode = price.productCode;
                        this._availableDomainListOfProductCodes.push(price.productCode);
                    }
                }
            }
        });

        for (const selectedDomain of this.selectedDomainList) {
            // add productcode to selected domain list to check available sufficient credit
            // if domain is not available in bundle contingents
            if (!selectedDomain.domainObject.inBundle) {
                const productCodeSuffix = selectedDomain.status === 'available'
                    ? DomainOrderProductCodeSuffixes.create
                    : DomainOrderProductCodeSuffixes.transfer;

                for (const price of selectedDomain.domainObject.prices) {
                    const productCodeSuffixIndex = price.productCode.indexOf(productCodeSuffix);
                    if (productCodeSuffixIndex === (price.productCode.length - productCodeSuffix.length)) {
                        this._chargebleDomainProductCodes.push(price.productCode);
                    }
                }
            }
        }

        // add productcode to contingent product list
        this._contingentProducts = this._availableDomainListOfProductCodes.map((code: string) => {
            return {
                productCode: code,
                type: 'Product'
            };
        });
    };

    private _checkSelectedContingent = (): void => {
        if (this.webspace) {
            this.metadata.webspace = ng.copy(this.webspace);
            this.metadata.bundle = this._bundle;
        } else if (
            this.metadata.selectedContingent.type === ContingentType.bundle
            && this.metadata.selectedContingent.misc
        ) {
            this.metadata.webspace = this.metadata.selectedContingent.misc.webspace;
            this.metadata.bundle = this.metadata.selectedContingent.misc.bundle;
        } else {
            this.metadata.bundle = this._bundle;
        }
    };

    private _setMetadata = (): void => {
        this._setOuterContingent();
        // NOTICE: Default Domain Contacts must be set here, otherwise they will be overwritten
        // when initializing the OrganCreateDomainContactsComponent!
        const domainContacts = HandleDefaultLabels.map(
            (handle) => {
                const defaultContactName = `defaultContact${handle.contactType.charAt(0).toUpperCase() + handle.contactType.slice(1)}Id`;
                return {
                    contactType: handle.contactType as DomainApi.DomainContactType,
                    contactId: this._account.domainSettings[defaultContactName],
                    label: this.$translate.instant(handle.label)
                };
            }
        );
        this._selectedAccount = this._account?.id;
        this.metadata = {
            account: this._account,
            bundle: this._bundle,
            contacts: domainContacts,
            createWebspace: false,
            dns: {
                type: 'system',
                values: {
                    dnsSecOptions: null,
                    ip: null,
                    masterIp: null,
                    mxServer: null,
                    records: [],
                    replacements: {
                        ipv4Replacement: undefined,
                        ipv6Replacement: undefined,
                        mxipv4Replacement: undefined,
                        mxipv6Replacement: undefined
                    },
                    templateChain: false,
                    templateId: null
                }
            },
            zoneUpdateData: null,
            domainType: DomainTypes.REGISTER,
            nameserver: {
                ownNameservers: [],
                values: {
                    default: false,
                    id: null,
                    hasGlueRecord: false,
                    nameservers: null,
                    type: null
                }
            },
            phpIniDefaults: null,
            selectedContingent: this._selectedContingent,
            selectedDomains: [],
            vhost: null,
            vhostExists: false,
            webspace: this.webspace || null,
            webserverRessource: this.webserverRessource
        };
    };

    private _updateDomainContacts = (): void => {
        if (this.metadata?.contacts && this._account?.domainSettings) {
            this.metadata.contacts = HandleDefaultLabels.map(
                (handle) => {
                    const defaultContactName = `defaultContact${handle.contactType.charAt(0).toUpperCase() + handle.contactType.slice(1)}Id`;
                    return {
                        contactType: handle.contactType as DomainApi.DomainContactType,
                        contactId: this._account.domainSettings[defaultContactName],
                        label: this.$translate.instant(handle.label)
                    };
                }
            );
        }
    };

    private _setOuterContingent = (): void => {
        if (this._bundle) {
            this.outerContingent = new Contingent(
                this._bundle.id,
                this._bundle.name,
                ContingentType.bundle
            );
            this.outerContingent.addMisc('bundle', this._bundle);
            this.outerContingent.addMisc('webspace', this.webspace);
        } else {
            this.outerContingent = new Contingent(
                '',
                this.$translate.instant('TR_100119-b13e52_TR'),
                ContingentType.standalone
            );
        }

    };
}

export class OrganismDomainWizardProductConfigComponent implements ng.IComponentOptions {
    public bindings = {
        _account: '<account',
        _bundle: '<?bundle',
        configCompleted: '=',
        domainSearchData: '=',
        metadata: '=',
        selectedDomainList: '=',
        webspace: '<?',
        webserverRessource: '<?'
    };
    public template = require('./domain-wizard-domain-config.html');
    public controller = OrganismDomainWizardProductConfigController;
}
