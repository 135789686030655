import ng from 'angular';

import * as Types from '@/types';
import {
    AlertManagerService,
    DomainModelService,
    NavigationService
} from '../../../../../services';

export class OrganismEditFormDomainRemoveFromBundleController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'domainModel',
        'navigation'
    ];

    public bundle: Types.BundleApi.Bundle;
    public domain: Types.DomainApi.Domain;
    public callout: any = {};
    public userHasAgreed = true; // Reserved for a checkbox if we ever make this available to users

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private domainModel: DomainModelService,
        private navigation: NavigationService
    ) {}

    public $onInit = () => {
        this.callout.message = this.$translate.instant(
            /* translationId */ 'TR_061020-a10181_TR',
            {
                bundle: this.bundle,
                domain: this.domain
            }
        );
    };

    public removeFromBundle = async () => {
        const result = await this.domainModel.domainDetachFromBundle(this.domain.name);

        if (result.status === 'success') {
            this.alertManager.success(this.$translate.instant('TR_061020-86953c_TR'));
            await this.navigation.go('domain.domains.id.edit');
        } else {
            this.alertManager.error(this.$translate.instant('TR_061020-c2940b_TR'));
        }

        return Promise.reject(false);
    };
}

export class OrganismEditFormDomainRemoveFromBundleComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<',
        domain: '<'
    };
    public controller = OrganismEditFormDomainRemoveFromBundleController;
    public template = require('./domain-remove-from-bundle.html');
}
