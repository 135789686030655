import * as ng from 'angular';
import { SwitchUserManagerService } from '../../../../services';

export class AtomLogoutAsController implements ng.IController {
    public static $inject: string[] = ['switchUserManager'];

    constructor(
        private switchUserManager: SwitchUserManagerService
    ) {}

    public logoutAs = () => {
        this.switchUserManager.leave();
    };
}

export class AtomLogoutAsComponent implements ng.IComponentOptions {
    public transclude = true;
    public template = '<span class="atom-logout-as" ng-click="$ctrl.logoutAs()"><ng-transclude></ng-transclude></span>';
    public controller =  AtomLogoutAsController;
}
