import ng from 'angular';

import {
    AlertManagerService, DateWrapper, DomainHandleModelService
} from '@/services';
import { SystemHelperService } from '@/services/helpers';
import { FormDropDownItems, DomainContactPanelMode, DomainContactModalEditSettings, EditPanelRight } from '@/atomic-components/molecules';
import { DomainApi, UI } from '@/types';

export class OrganismEditFormDomainContactController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'domainHandleModel',
        'systemHelper'
    ];

    public userPanelRight: EditPanelRight;
    public contact: DomainApi.Contact;
    public contactIsExtended = false;
    public genderDropdownItems: FormDropDownItems[] = [];
    public languageDropdownItems: FormDropDownItems[] = [];
    public editSettings: DomainContactModalEditSettings;
    public panelMode: DomainContactPanelMode;

    private _originalEmail: string;
    private _loadingState = true;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private domainHandleModel: DomainHandleModelService,
        public systemHelper: SystemHelperService
    ) {}

    public $onInit = async (): Promise<void> => {
        this._originalEmail = ng.copy(this.contact.emailAddress);
        await this._checkRestrictedOwnerChange();
        this._loadingState = false;
    };

    public get loadingState(): boolean {
        return this._loadingState;
    }

    public get isUpdateBlocked(): boolean {
        return !!this.editSettings?.updateBlocked;
    }

    public get blockedDomains(): string {
        if (!this.editSettings?.updateBlockedByDomainsUnicode) {
            return;
        }

        return this.editSettings?.updateBlockedByDomainsUnicode.join(', ');
    }

    public saveContact = (): Promise<{ response: unknown}> => {
        if (
            typeof this.contact?.extDateOfBirth === 'object'
            && (this.contact?.extDateOfBirth as DateWrapper)?.dateString
        ) {
            this.contact.extDateOfBirth = (this.contact.extDateOfBirth as DateWrapper).dateString;
        }

        return this.domainHandleModel.update(this.contact, this.editSettings.designateAgent).then(
            (result: UI.APISingleResponse<DomainApi.Contact>) => {
                if (['pending', 'success'].indexOf(result.status) >= 0) {
                    this.alertManager.success(this.$translate.instant('DOMAIN.MESSAGE.HANDLE.UPDATE.SUCCESS'));

                    return { response: result};
                }

                return Promise.reject();
            }
        );
    };

    private _checkRestrictedOwnerChange = (): Promise<void> => {
        return this.domainHandleModel.contactUpdateIsRestrictedOwnerChange(this.contact).then(
            (response) => {
                this.editSettings = {
                    changedEmailIsIcannOwnerChange: null,
                    designateAgent: false,
                    isIcannOwnerChange: response.isIcannOwnerChange,
                    updateBlocked: response.updateBlocked,
                    updateBlockedByDomainsAce: response.updateBlockedByDomainsAce,
                    updateBlockedByDomainsUnicode: response.updateBlockedByDomainsUnicode,
                    originalEmail: !this.contact.emailAddress ? '' : this._originalEmail
                };

                if (response.updateBlocked) {
                    this.userPanelRight.editPanelButton = false;
                }

                this._loadingState = false;
            },
            () => {
                this._loadingState = false;
            }
        );
    };
}

export class OrganismEditFormDomainContactComponent implements ng.IComponentOptions {
    public bindings = {
        contact: '<',
        contactIsExtended: '<',
        panelMode: '<',
        userPanelRight: '<'
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormDomainContactController;
    public template = require('./domain-contact-edit.html');
}
