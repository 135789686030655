import { OrchestratorRobotService } from './robot';

export class OrchestratorModelService {
    public static $inject: string[] = ['orchestratorRobot'];

    constructor(
        private orchestratorRobot: OrchestratorRobotService
    ) {}

    public installWizardObject = (WizardObject: any) => {
        const orchestratorObject = this.createOrchestratorObject(WizardObject);

        return this.orchestratorRobot.order(orchestratorObject);
    };

    private createOrchestratorObject = (WizardObject: any) => {
        if (WizardObject.selectedProduct.family === 'ecommerce-virtual-machines') {
            return this.createEcommerceVmOrchestratorObject(WizardObject);
        }
        return this.createManagedVmOrchestratorObject(WizardObject);
    };

    private createManagedVmOrchestratorObject = (WizardObject: any) => {
        return {
            items: [],
            productCode: WizardObject.selectedProduct.selectedPrice.productCode,
            type: 'ManagedVirtualMachine'
        };
    };

    private createEcommerceVmOrchestratorObject = (WizardObject: any) => {
        const orchestratorObject = {
            items: [],
            name: WizardObject.settings.name,
            productCode: WizardObject.selectedProduct.selectedPrice.productCode,
            type: 'ManagedVirtualMachine'
        };

        if ([undefined, null, ''].indexOf(WizardObject.settings.domain) < 0) {
            const webspaceObject = {
                items: [],
                name: WizardObject.settings.domain,
                productCode: 'webhosting-webspace-v1-1m',
                type: 'Webspace'
            };

            webspaceObject.items.push({
                billingMode: 'standalone',
                createMode: WizardObject.settings.domainAction,
                domainNameUnicode: WizardObject.settings.domain,
                items: [{
                    items: [{
                        appId: WizardObject.settings.appSettings.appId,
                        installData: JSON.stringify(WizardObject.settings.appSettings.appSettings),
                        type: 'Application'
                    }],
                    productCode: 'database-mariadb-single-v1-1m',
                    type: 'ApplicationDatabase'
                }],
                type: 'Domain'
            });

            orchestratorObject.items.push(webspaceObject);
        }

        return orchestratorObject;
    };
}
