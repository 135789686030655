import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateSslCertificateJobDetailsController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;
    public job: any;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: 'ssl.certificates.jobs',
            backwardText: this.$translate.instant('TR_210219-9a5f9f_TR'),
            panelHeaderRoute: '',
            panelIcon: 'shield',
            panelTitle: this.$translate.instant('TR_210219-e954be_TR', { jobName: this.job.displayName })
        };
    };
}

export class TemplateSslCertificateJobDetailsComponent implements ng.IComponentOptions {
    public bindings = {
        job: '<'
    };
    public controller = TemplateSslCertificateJobDetailsController;
    public template = require('./ssl-certificate-job-details-template.html');
}
