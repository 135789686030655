import * as ng from 'angular';
import * as q from 'q';

import {
    DatabaseModelService,
    DatabaseUserModelService
} from '../../../../../services';
import { WizardCreateObject } from '../../general';
import { DatabaseWizardConfirmation } from './database-wizard-confirmation';

export class OrganismDatabaseWizardConfirmViewController {
    public static $inject: string[] = [
        '$translate',
        'databaseModel',
        'databaseUserModel',
        'databaseWizardConfirmation'
    ];

    public bundleId = '';
    public wizardCreateObjectList: WizardCreateObject[] = [];
    public nextSteps: any[] = [];
    public apiProductObject: any; // Required information for the products that need to be ordered.

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private databaseModel: DatabaseModelService,
        private databaseUserModel: DatabaseUserModelService,
        public databaseWizardConfirmation: DatabaseWizardConfirmation
    ) {}

    public $onInit() {
        if (!this.apiProductObject.apiObject.database) {
            return;
        }

        const databaseJob = {
            callback: () => this.databaseModel.create(
                this.apiProductObject.apiObject.database,
                undefined,
                undefined,
                this.apiProductObject.apiObject.databaseServerId
            ),
            labelText: this.$translate.instant('TR_110220-b1f8f2_TR'),
            objectType: 'Database'
        };

        if (this.apiProductObject.apiObject.database.accesses[0].userId === undefined) {
            this.wizardCreateObjectList.push({
                callback: () => this.databaseUserModel.createUser(
                    this.apiProductObject.apiObject.user,
                    this.apiProductObject.apiObject.user.password
                )
                .then(
                    (response) => {
                        this.apiProductObject.apiObject.database.accesses[0].userId = response.id;
                        return response;
                    }
                ),
                labelText: this.$translate.instant('TR_240220-a0c31e_TR'),
                objectType: 'User',
                children: [databaseJob]
            });
        } else {
            this.wizardCreateObjectList.push(databaseJob);
        }
    }
}

export class OrganismDatabaseWizardConfirmViewComponent implements ng.IComponentOptions {
    public bindings = {
        apiProductObject: '<'
    };

    public template = require('./database-wizard-confirm-view.html');
    public controller = OrganismDatabaseWizardConfirmViewController;
}
