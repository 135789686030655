import { DirectDebit } from '../../configuration';
import { Validator } from './abstract-validator';

import * as ng from 'angular';

export class ValidateIban extends Validator {
    constructor(private $translate: ng.translate.ITranslateService) {
        super();
    }

    public validate = (value: string) => {
        if (value === undefined || value.length === 0) {
            return [{ text: this.$translate.instant('TR_090119-2705b2_TR') }];
        }

        const countryIsoCode = value.substring(0, 2);

        if (DirectDebit.allowed.indexOf(countryIsoCode.toUpperCase()) === -1) {
            return [{ text: this.$translate.instant('TR_170119-e7b669_TR') }];
        }

        return [];
    };
}
