import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import {
    AccountModelService,
    AlertManagerService,
    AuthContextService,
    ErrorMapperService
} from '../../../../../services';
import * as Types from '../../../../../types';

export class OrganismEditFormBasicDataController implements ng.IController {
    public static $inject: string[] = ['$translate', 'accountModel', 'authContext', 'errorMapper', 'alertManager'];

    public basicData: Types.AccountApi.Account;
    public accountAllowedAdvertisingMethods: string[];
    public accountName: string;
    public accountNameAddition: string;
    public accountCity: string;
    public accountStreet: string;
    public accountZipCode: string;
    public accountPhoneNumber: string;
    public accountFaxNumber: string;
    public accountCountryModel: string;
    public accountCountryName: string;
    public accountCustomerNumber: string;
    public contactEmail: string;
    public invoiceEmail: string;
    public newsletterEmail: string;
    public technicalContactEmail: string;
    public panelRightSettings: any;
    public contactDataInitialStatus: any;
    public contactEmailInitialStatus: any;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService,
        private authContext: AuthContextService,
        private errorMapper: ErrorMapperService,
        private alertManager: AlertManagerService
    ) {}

    public $onInit() {
        this.resetAccountData();
        this.resetContactEmail();
        this.setPanelRightSettings();
    }

    public resetAccountData = () => {
        this.accountAllowedAdvertisingMethods = this.basicData.allowedAdvertisingMethods;
        this.accountName = this.basicData.name;
        this.accountNameAddition = this.basicData.addition;
        this.accountCity = this.basicData.city;
        this.accountStreet = this.basicData.street;
        this.accountZipCode = this.basicData.zipCode;
        this.accountPhoneNumber = this.basicData.phoneNumber;
        this.accountFaxNumber = this.basicData.faxNumber;
        this.accountCountryModel = this.basicData.country.toUpperCase();
        this.accountCustomerNumber = this.basicData.customerNumber;
    };

    public resetContactEmail = () => {
        this.contactEmail = this.basicData.emailAddress;
        this.invoiceEmail = this.basicData.emailAddressInvoice;
        this.newsletterEmail = this.basicData.emailAddressNewsletter;
        this.technicalContactEmail = this.basicData.emailAddressTechnicalContact;
    };

    public saveAccountData = () => {
        const self = this;
        const basicDataBackup = {
            allowedAdvertisingMethods: this.basicData.allowedAdvertisingMethods,
            addition: this.basicData.addition,
            city: this.basicData.city,
            country: this.basicData.country,
            faxNumber: this.basicData.faxNumber,
            phoneNumber: this.basicData.phoneNumber,
            street: this.basicData.street,
            zipCode: this.basicData.zipCode
        };
        this.basicData.allowedAdvertisingMethods = this.accountAllowedAdvertisingMethods;
        this.basicData.addition = this.accountNameAddition;
        this.basicData.city = this.accountCity;
        this.basicData.street = this.accountStreet;
        this.basicData.zipCode = this.accountZipCode;
        this.basicData.phoneNumber = this.accountPhoneNumber;
        this.basicData.faxNumber = this.accountFaxNumber;
        this.basicData.country = this.accountCountryModel.toLowerCase();
        this.accountModel.updateAccount(this.basicData).then(
            (apiResponse) => {
                this.alertManager.success(this.$translate.instant('TR_060219-4befa0_TR'));
                return apiResponse;
            },
            (reason) => {
                self.alertManager.error(self.errorMapper.map(reason));
                self.basicData.allowedAdvertisingMethods = basicDataBackup.allowedAdvertisingMethods;
                self.basicData.addition = basicDataBackup.addition;
                self.basicData.city = basicDataBackup.city;
                self.basicData.street = basicDataBackup.street;
                self.basicData.zipCode = basicDataBackup.zipCode;
                self.basicData.phoneNumber = basicDataBackup.phoneNumber;
                self.basicData.faxNumber = basicDataBackup.faxNumber;
                self.basicData.country = basicDataBackup.country;
                self.resetAccountData();
            }
        );
    };

    public saveContactEmail = () => {
        const self = this;
        const basicDataBackup = {
            emailAddress: this.basicData.emailAddress,
            emailAddressInvoice: this.basicData.emailAddressInvoice,
            emailAddressNewsletter: this.basicData.emailAddressNewsletter,
            emailAddressTechnicalContact: this.basicData.emailAddressTechnicalContact
        };
        this.basicData.emailAddress = this.contactEmail;
        this.basicData.emailAddressInvoice = this.invoiceEmail;
        this.basicData.emailAddressNewsletter = this.newsletterEmail;
        this.basicData.emailAddressTechnicalContact = this.technicalContactEmail;
        this.accountModel.updateAccount(this.basicData).then(
            (apiResponse) => {
                this.alertManager.success(this.$translate.instant('TR_060219-4befa0_TR'));
                return apiResponse;
            },
            (reason) => {
                self.alertManager.error(self.errorMapper.map(reason));
                self.basicData.emailAddress = basicDataBackup.emailAddress;
                self.basicData.emailAddressInvoice = basicDataBackup.emailAddressInvoice;
                self.basicData.emailAddressNewsletter = basicDataBackup.emailAddressNewsletter;
                self.basicData.emailAddressTechnicalContact = basicDataBackup.emailAddressTechnicalContact;
                self.resetContactEmail();
            }
        );
    };

    public setPanelRightSettings = () => {
        const rightsGranted = this.authContext.isGranted(UiRights.ACC_ACCOUNT_BASICDATA_EDIT);
        if (rightsGranted) {
            this.panelRightSettings = {
                editPanelButton: true
            };
        } else {
            this.panelRightSettings = {
                editPanelButton: false
            };
        }
    };
}

export class OrganismEditFormBasicDataComponent implements ng.IComponentOptions {
    public bindings = {
        basicData: '<',
        contactDataInitialStatus: '<',
        contactEmailInitialStatus: '<'
    };
    public controller = OrganismEditFormBasicDataController;
    public template = require('./basic-data-edit.html');
}
