import ng from 'angular';

import { AuthContextService, WebhostingUserModelService } from '@/services';
import * as Types from '@/types';

export class MoleculePanelRowOrchestrationWebspaceAccessSettingsController {
    public static $inject: string[] = ['$timeout', '$translate', 'webhostingUserModel'];

    public onlyNewAccessAvailable = true;
    public webspaceUserAccess: Types.ViewTypes.WebspaceUserAccessObject;
    public accessTypeButtons: { value: Types.ViewTypes.UserAccessType; label: string }[];
    public isValid = false;
    public formIsReady = false;
    public accountId: string;
    public accessHeadings: string[] = [];
    public viewTypes = Types.ViewTypes;

    private callbackOnChange;

    constructor(
        private $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        private webhostingUserModel: WebhostingUserModelService
    ) {}

    public $onInit = () => {
        this.formIsReady = false;
        this.isValid = false;

        if (this.webspaceUserAccess === null) {
            this.webspaceUserAccess = {
                accessType: Types.ViewTypes.UserAccessType.new,
                userId: undefined,
                userName: '',
                name: '',
                password: '',
                comments: '',   // currently not supported by the API
                sshKey: '',     // currently not supported by the API
                homeDir: '',    // currently not supported by the API
                status: '',
                accessLevel: {  // currently not supported by the API
                    sshAccess: false,
                    ftpAccess: false,
                    statsAccess: false,
                    ftpLimited: false
                }
            };
        }

        this.accessHeadings = [
            this.$translate.instant('TR_110119-76b4ba_TR'),
            this.$translate.instant('TR_100119-e937e9_TR')
        ];

        this.accessTypeButtons = [
            { value: Types.ViewTypes.UserAccessType.existing, label: this.$translate.instant('TR_110119-d043dc_TR') },
            { value: Types.ViewTypes.UserAccessType.new, label: this.$translate.instant('TR_110119-b61be5_TR') }
        ];

        this._checkForAvailabelAccesses();
    };

    get choosenAccessId(): string {
        return this.webspaceUserAccess.userId;
    }

    set choosenAccessId(value: string) {
        this.webspaceUserAccess.userId = value;
    }

    public userAccessTypeChangeCallback = (accessType: Types.ViewTypes.UserAccessType) => {
        if (accessType === Types.ViewTypes.UserAccessType.new) {
            this.webspaceUserAccess.userId = undefined;
        } else {
            this.webspaceUserAccess.name = '';
            this.webspaceUserAccess.password = '';
        }
        this.validateForm();
    };

    public validateForm = () => {
        this.$timeout( () => {
            this.isValid = [undefined, null, ''].indexOf(this.webspaceUserAccess.userId) < 0
                || (
                    [undefined, null, ''].indexOf(this.webspaceUserAccess.name) < 0
                    && [undefined, null, ''].indexOf(this.webspaceUserAccess.password) < 0
                );
        });

        if ([undefined, null, ''].indexOf(this.callbackOnChange)) {
            this.callbackOnChange();
        }
    };

    public listWebspaceUsers = (
        limit?: number,
        page?: number,
        filters?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions,
        timeout?: number
    ) => {
        const filterObject: any = {
            subFilter: [{
                field: 'accountId',
                value: AuthContextService.account.id
            }],
            subFilterConnective: 'AND'
        };
        if ([undefined, null].indexOf(filters) >= 0) {
            filterObject.subFilter.push({
                field: 'userName',
                value: '*'
            });
        } else {
            filterObject.subFilter.push(filters);
        }
        return this.webhostingUserModel.list(limit, page, filterObject, sort, timeout);
    };

    private _checkForAvailabelAccesses = () => {
        this.webhostingUserModel.list(null, null, { field: 'accountId', value: this.accountId })
            .then((accesses) => {
                if (accesses.data.length > 0) {
                    this.onlyNewAccessAvailable = false;
                    this.webspaceUserAccess.accessType = Types.ViewTypes.UserAccessType.existing;
                } else {
                    this.onlyNewAccessAvailable = true;
                    this.webspaceUserAccess.accessType = Types.ViewTypes.UserAccessType.new;
                }
                this.formIsReady = true;
            });
    };
}

export class MoleculePanelRowOrchestrationWebspaceAccessSettingsComponent implements ng.IComponentOptions {
    public bindings = {
        accountId: '<',
        callbackOnChange: '<?',
        webspaceUserAccess: '=',
        isValid: '='
    };

    public template = require('./orchestration-webspace-access-settings.html');
    public controller = MoleculePanelRowOrchestrationWebspaceAccessSettingsController;
}
