import * as ng from 'angular';

import { FormDropDownItems, OrganismEditFormMailboxController } from '@/atomic-components';
import { DataObject, MailboxModelService, ValidateFqdn, ValidateIp } from '@/services';

type MailboxEditPanelRight = {
    editPanelButton: true;
    editPassword: true;
};
export class OrganEditPanelMailboxGeneralController {
    public static $inject: string[] = ['$timeout', '$translate', 'mailboxModel'];

    public cancel: () => void;
    public $editFormOrganism: OrganismEditFormMailboxController;
    public panelRight: MailboxEditPanelRight;
    public imapMailboxes: FormDropDownItems[];
    public showObjectId: boolean;
    public smtpForwarderValidationInstructions: DataObject[];
    public forwarderListErrors: { text: string }[] = [];

    private _changePassword = false;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private mailboxModel: MailboxModelService
    ) {}

    public $onInit = (): void => {
        this.smtpForwarderValidationInstructions = [
            {
                instructions: null,
                validator: new ValidateFqdn(this.$translate)
            },
            {
                instructions: null,
                validator: new ValidateIp(this.$translate, false)
            }
        ];

        void this.mailboxModel.getImapMailboxes(this.$editFormOrganism.mailbox.accountId).then(
            (mailboxes) => this.imapMailboxes = mailboxes.map(
                (mailbox: { emailAddressUnicode: string }) => ({
                    name: mailbox.emailAddressUnicode,
                    value: mailbox.emailAddressUnicode
                })
            )
        );

        // Sort forwarder targets alphabetically.
        if (this.$editFormOrganism?.mailbox?.forwarderTargets) {
            this.$editFormOrganism.mailbox.forwarderTargets = (
                this.$editFormOrganism.mailbox.forwarderTargets as string[]
            ).sort();
        }
    };

    get changePassword(): boolean {
        return this._changePassword;
    }

    set changePassword(value: boolean) {
        if (value !== this._changePassword && value === false) {
            this.$editFormOrganism.password = null;
        }
        this._changePassword = value;
    }

    public cancelCallback = (): void => {
        this.changePassword = false;
        if (this.cancel) {
            void this.$timeout(() => {
                this.cancel();
            });
        }
    };

    public get disableEditButton(): boolean {
        return this.$editFormOrganism.mailbox.type === 'MailingList'
            || this.$editFormOrganism.mailboxHasUnfinishedJob;
    }

    public showPasswordLineInReadonlyMode = (): boolean => {
        return ['ExchangeMailbox', 'ImapMailbox'].indexOf(this.$editFormOrganism.mailbox.type) >= 0
            && this.panelRight.editPassword as boolean;
    };
}

export class OrganEditPanelMailboxGeneralComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<',
        showObjectId: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormMailbox'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./mailbox-general.html');
    public controller = OrganEditPanelMailboxGeneralController;
}
