import * as ng from 'angular';
import { NavigationService } from '../../../../../services';
import {
    EditPanelStatus,
    PanelType
} from '../../../../molecules/forms/form-edit/form-edit';

export class OrganEditPanelDomainLocationsController {
    public static $inject: string[] = ['$state', 'navigation'];

    public type: PanelType = PanelType.COST_NEUTRAL;
    public name = 'domain-locations';
    public status: EditPanelStatus;
    public panelRight: any;
    public $editFormMolecule;

    constructor(
        private $state: ng.ui.IStateService,
        private navigation: NavigationService
    ) {}

    public $onInit() {
        this.$editFormMolecule.register(this);
        this.status = EditPanelStatus.READONLY;
    }

    public goToEdit = () => {
        let route = 'webhosting.webspaces.id.vhosts.id.locations-edit';
        if (this.$state.current.name.indexOf('bundle') === 0) {
            route = 'bundle.id.domains.id.locations-edit';
        } else if (this.$state.current.name.indexOf('managed-servers') === 0) {
            route = 'managed-servers.id.webspaces.id.vhosts.id.locations-edit';
        }
        this.navigation.go(route);
    };

    public get disableEditButton() {
        return !this.$editFormMolecule.canEnablePanel(this.name);
    }
}

export class OrganEditPanelDomainLocationsComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<'
    };
    public require = {
        $editFormMolecule: '^moleculeFormEdit'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./domain-locations.html');
    public controller = OrganEditPanelDomainLocationsController;
}
