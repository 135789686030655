import * as ng from 'angular';

import { FormDescriptionSpec } from '@/types/view-types/form-description/form-description';
import { ValidationErrorList } from '@/atomic-components/molecules/lists/validation-error-list/validation-error-list';

export class FormElementInputMultiSelectController implements ng.IComponentController {
    public static $inject: string[] = [];

    public keenInput: FormDescriptionSpec.FieldObjectMultiSelect;
    public keenOutput: (outputValue: string[], key: string) => void;
    public options: { value: string; name: string; active: boolean }[];
    private _model: string[];
    private key: string;  // Used in controller.
    private label: string; // Used in controller.
    private validationErrorList: ValidationErrorList = []; // Used in controller.

    public $onInit = (): void => {
        this.label = this.keenInput?.ui?.label || '';
        this.key = this.keenInput?.key;
        this.options = [];
        for (const option of this.keenInput?.ui?.options as { value: string; name: string; active: boolean }[]) {
            option.active = false;
            if (this.keenInput?.default?.includes(option.value)) {
                option.active = true;
            }
            this.options.push(option);
        }
        if (((this.keenInput as FormDescriptionSpec.FieldObject).default as string[])?.length > 0) {
            this.model = ((this.keenInput as FormDescriptionSpec.FieldObject).default as string[]);
        } else {
            this.model = null;
        }
    };

    public checkboxChanged = (option: FormDescriptionSpec.FieldUiOptionsType & { active: boolean }): void => {
        option.active = !option.active;
        if (!this.model) {
            this.model = [];
        }
        if (option.active) {
            this.model = this.model.concat(option.value);
        } else {
            this.model = this.model.filter((value) => value !== option.value);
        }
    };

    public get model(): string[] {
        return this._model;
    }

    public set model(value: string[]) {
        this._model = value;
        if (this.keenInput?.ui?.error?.length > 0) {
            if (!this._model || this._model.length < 1) {
                this.validationErrorList = [{ text: this.keenInput.ui.error }];
            } else {
                this.validationErrorList = [];
            }
        }
        this.onChangeModel();
    }

    private onChangeModel = () => {
        if (this.keenOutput) {
            this.keenOutput(this._model, this.key);
        }
    };
}

export class FormElementInputMultiSelectComponent implements ng.IComponentOptions {
    public bindings = {
        keenInput: '<',
        keenOutput: '<'
    };
    public controller = FormElementInputMultiSelectController;
    public template = require('./form-element-input-multi-select.html');
}
