import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import { AlertManagerService, AuthContextService, DomainModelService } from '../../../../../services';

export class OrganEditPanelDomainTransferController {
    public static $inject: string[] = ['$translate', 'alertManager', 'authContext', 'domainModel'];

    public domain: any;
    public panelRightSettings: any;
    private originalTransferlockSetting: boolean;
    public userCanEdit: boolean = false;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private authContext: AuthContextService,
        private domainModel: DomainModelService
    ) {}

    public $onInit() {
        this.originalTransferlockSetting = this.domain.transferLockEnabled;
    }

    public cancel = () => {
        this.domain.transferLockEnabled = this.originalTransferlockSetting;
    };

    public get showAuthCodeValue() {
        return this.transferAllowed
            && !this.domain.transferLockEnabled
            && this.domain.authInfo !== '';
    }

    public get showAuthCodeMessage() {
        return !this.domain.transferLockEnabled && this.domain.authInfo === '';
    }

    public get transferAllowed() {
        return !this.domain.transferLockEnabled;
    }

    public set transferAllowed(value: boolean) {
        this.domain.transferLockEnabled = !value;
    }

    public showPanel = () => {
        return this.authContext.isGranted(UiRights.DOM_DOMAINS_TRANSFER_OUT_PROCESS_REQUESTS);
    };

    public get disableEditButton() {
        return !this.userCanEdit;
    }
}

export class OrganEditPanelDomainTransferComponent implements ng.IComponentOptions {
    public bindings = {
        domain: '=',
        panelRightSettings: '<panelRight',
        userCanEdit: '<?'
    };
    public controller = OrganEditPanelDomainTransferController;
    public template = require('./domain-transfer.html');
}
