import { ModelHelper } from '../model-helper';
import { DomainRobotService } from './robot';

import * as Types from '../../types';

export class DomainJobModelService {
    public static $inject: string[] = ['domainRobot'];

    constructor(
        private domainRobot: DomainRobotService
    ) {}

    public list = (
        limit?,
        page = 1,
        filters?,
        sort?
    ) => {
        sort = sort || {
            field: 'JobAddDate',
            order: 'DESC'
        };

        return this.domainRobot.listJobs(filters, limit, page, sort)
            .then(ModelHelper.returnListResponse)
            .then(
                (result) => {
                    // @todo API Workaround
                    result.data.forEach(
                        (job) => {
                            job.errors = this.parseJobError(job.errors);
                        }
                    );

                    return result;
                }
            );
    };

    public findRunningJobsForDomain = (domain: Types.DomainApi.Domain) => {
        const filter = {
            subFilter: [
                { field: 'jobObjectType', value: 'Domain' },
                { field: 'jobObjectId', value: domain.id },
                { field: 'jobStatus', relation: 'unequal', value: 'successful' },
                { field: 'jobStatus', relation: 'unequal', value: 'failed' },
                { field: 'jobStatus', relation: 'unequal', value: 'canceled' }
            ],
            subFilterConnective: 'AND'
        };

        return this.domainRobot.listJobs(filter, 25, 1, null);
    };

    public listWithoutPagination = (
        limit?,
        page = 1,
        filters?,
        sort?
    ) => {
        sort = sort || {
            field: 'JobAddDate',
            order: 'DESC'
        };

        return this.domainRobot.listJobsWithoutPagination(filters, limit, page, sort)
            .then(ModelHelper.returnListResponse)
            .then(
                (result) => {
                    // @todo API Workaround
                    result.data.forEach(
                        (job) => {
                            job.errors = this.parseJobError(job.errors);
                        }
                    );

                    return result;
                }
            );
    };

    public findOne = (id: string) => this.domainRobot.listJobs({field: 'jobId', value: id}, 1, 1)
        .then(ModelHelper.returnFindOneResponse)
        .then(
            (job) => {
                // @todo API Workaround
                job.errors = this.parseJobError(job.errors);
                return job;
            }
        );

    public findById = (ids: string[]) => this.domainRobot.listJobs(
        { subFilterConnective: 'OR', subFilter: ids.map(this.idFilter) },
        0,
        1
    )
    .then(this.returnResponseDate)
    .then(
        (result) => {
            // @todo API Workaround
            result.forEach(
                (job) => job.errors = this.parseJobError(job.errors)
            );

            return result;
        }
    );

    public setTransferAck = (domainNames) => Promise.all(
        domainNames.map(this.setTransferAckOne)
    );

    public setTransferNack = (domainNames, reason) => Promise.all(
        domainNames.map(
            (domainName) => this.setTransferNackOne(domainName, reason)
        )
    );

    public resendEmail = (jobId) => this.domainRobot.jobResendEmail(jobId);

    private parseJobError = (error: string) => (!error || error === '')
        ? []
        : JSON.parse(error);

    private idFilter = (id) => ({field: 'jobId', value: id});

    private returnResponseDate = (result) => result.response.data;

    private setTransferAckOne = (domainName) => this.domainRobot.setTransferAck(domainName);

    private setTransferNackOne = (domainName, reason) => this.domainRobot.setTransferNack(domainName, reason);
}
