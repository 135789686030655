import * as ng from 'angular';
import { PanelHeaderData } from '@/atomic-components/molecules/panels';
import {
    AlertManagerService,
    NextcloudHelperService,
    StorageAppConfigService
} from '@/services';
import { NavigationService } from '@/services/navigation';
import { StorageModelService } from '@/services/storage/storage-model';
import { ManagedApplicationApi, UI } from '@/types';
import { FormDescriptionSpec } from '@/types/view-types/form-description/form-description';

export class TemplateStorageProductConfigController {
    public static $inject: string[] = [
        '$state',
        '$timeout',
        '$translate',
        'alertManager',
        'navigation',
        'nextcloudHelper',
        'storageAppConfig',
        'storageModel'
    ];

    public currentConfig: Record<string, unknown>;
    public faqArticleIdList: string[] = [];
    public pageHeaderData: PanelHeaderData;
    public nextcloudHasUnfinishedJob = false;
    public componentInitialized = false;
    public storageProduct: ManagedApplicationApi.Nextcloud;
    public nextcloudConfiguration: {
        configSchema: string;
        currentConfiguration: string;
    };
    public formIsDirty = false;
    public configSchema: FormDescriptionSpec.FormDescriptionLayout;
    public nextcloudConfigurationObject: Record<string, unknown> = {};

    private _statePrefix: string;
    private _initialState: {
        configSchema: FormDescriptionSpec.FormDescriptionLayout;
        nextcloudConfigurationObject: Record<string, unknown>;
    };

    constructor(
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private navigation: NavigationService,
        private nextcloudHelper: NextcloudHelperService,
        private storageAppConfig: StorageAppConfigService,
        private storageModel: StorageModelService
    ) {}

    public $onInit(): void {
        void this.nextcloudHelper.checkNextcloudJobRunning(this.storageProduct.id).then((jobRunning: boolean) => {
            this.nextcloudHasUnfinishedJob = jobRunning;
            this.componentInitialized = true;
        });

        if (this.$state.current.name.startsWith('nextcloud')) {
            this._statePrefix = 'nextcloud.id.';
        } else {
            this._statePrefix = 'storage.storage-products.id.';
        }

        this.pageHeaderData = {
            backwardLink: this._statePrefix + 'edit',
            backwardText: this.$translate.instant('TR_071020-722106_TR'),
            panelHeaderRoute: '',
            panelIcon: '/assets/images/logos/nextcloud-bw.svg',
            panelIconPath: true,
            panelTitle: this.storageProduct.name + ' - ' + this.$translate.instant('TR_110119-ad4b8c_TR')
        };

        this.currentConfig = JSON.parse(this.nextcloudConfiguration.currentConfiguration);
        this.configSchema = JSON.parse(this.nextcloudConfiguration.configSchema);

        this.nextcloudConfigurationObject = this._getCurrentNextcloudConfig(
            this.configSchema.data.fields,
            this.currentConfig
        );

        this._initialState = {
            configSchema: this.configSchema,
            nextcloudConfigurationObject: this.nextcloudConfigurationObject
        };
    }

    public configOptionChangedByUser = (value: unknown, elementName: string): void => {
        if (!!elementName === false || this.nextcloudConfigurationObject[elementName] === value) {
            return;
        }

        this.nextcloudConfigurationObject[elementName] = value;
        this.formIsDirty = true;
    };

    public upgradeNextcloudInstance = (): void => {
        for (const key of Object.keys(this.nextcloudConfigurationObject)) {
            if ([null, undefined, NaN].includes(this.nextcloudConfigurationObject[key] as any)) {
                const field = this.configSchema.data.fields.find(field => field.key === key);
                if (![null, undefined].includes(field.default)) {
                    this.nextcloudConfigurationObject[key] = field.default;
                }
            }
        }

        void this.storageModel
            .nextcloudUpdate(
                this.storageProduct,
                null,
                JSON.stringify(this.nextcloudConfigurationObject)
            )
            .then((response: UI.SynchronousAPIResponse<ManagedApplicationApi.Nextcloud>) => {
                if (['successful', 'pending'].indexOf(response.status) >= 0) {
                    this.alertManager.success(this.$translate.instant('TR_240621-658d84_TR'));
                    void this.navigation.go(this._statePrefix + 'edit', null, {
                        reload: true
                    });
                }
            });
    };

    public cancel = (): void => {
        this.componentInitialized = false;
        this.configSchema = this._initialState.configSchema;
        this.nextcloudConfigurationObject = this._initialState.nextcloudConfigurationObject;

        void this.$timeout(() => {
            this.componentInitialized = true;
        });

        void this.$timeout(() => {
            this.formIsDirty = false;
        }, 100);
    };

    private _getCurrentNextcloudConfig = (
        fields: FormDescriptionSpec.FieldObject[],
        currentConfig: Record<string, unknown>
    ): Record<string, unknown> => {
        return this._overlayCurrentNextcloudConfigValues(
            this._loadApiDefaultConfigValues(fields),
            currentConfig,
            fields
        );
    };

    private _loadApiDefaultConfigValues = (fields: FormDescriptionSpec.FieldObject[]): Record<string, unknown> => {
        const nextcloudConfig: Record<string, unknown> = {};

        for (const field of fields) {
            nextcloudConfig[field.key] = field.default;
        }

        return nextcloudConfig;
    };

    private _overlayCurrentNextcloudConfigValues = (
        defaultConfig: Record<string, unknown>,
        currentConfig: Record<string, unknown>,
        fields: FormDescriptionSpec.FieldObject[]
    ): Record<string, unknown> => {
        const nextcloudConfig = ng.copy(defaultConfig);

        for (const configOptionKey of Object.keys(currentConfig)) {
            nextcloudConfig[configOptionKey] = currentConfig[configOptionKey];
        }

        this.configSchema.data.fields = fields.map((field) => {
            field.value = nextcloudConfig[field.key];
            return field;
        });

        return nextcloudConfig;
    };
}

export class TemplateStorageProductConfigComponent implements ng.IComponentOptions {
    public bindings = {
        nextcloudConfiguration: '<',
        storageProduct: '<'
    };
    public controller = TemplateStorageProductConfigController;
    public template = require('./storage-product-config-template.html');
}
