import ng from 'angular';

import { knownManagedSslProductCodesConst, UiRights } from '@/configuration';
import { CalculatePriceFilter, ConvertAmountFilter } from '@/filters';
import { AuthContextService, DepositModelService, PriceModelService, SslCertificateModelService } from '@/services';
import { BillingApi, SslApi } from '@/types';
import { PanelType } from '@/atomic-components/molecules/forms/form-edit/form-edit';
import { FormDropDownItems } from '@/atomic-components/molecules/forms/form-elements/drop-down/drop-down';
import { OrganismEditFormDomainController } from '@/atomic-components/organisms';
import { EditPanelRight } from '@/atomic-components/molecules';

export interface SslOptions {
    type: string;
    isReadOnly: boolean;
    types: FormDropDownItems[];
    sslIndividualRoute: string;
    sslIndividualRouteParams: {
        bundleId: string;
        domainId: string;
        webspaceId: string;
        vHostId: string;
    };
    profiles: {name: string; value: string}[];
}

export class OrganEditPanelDomainSslController {
    public static $inject: string[] = [
        '$translate',
        'calculatePriceFilter',
        'convertAmountFilter',
        'depositModel',
        'priceModel',
        'sslCertificateModel'
    ];

    public $editFormOrganism: OrganismEditFormDomainController;
    public panelRight: EditPanelRight;
    public userConfirmedPayment = false;
    public panelTypeOverride: PanelType = PanelType.MISCELLANEOUS_ACTION;
    public rapidSslType = 'rapid-ssl';
    public sectigoType = 'sectigo';
    public selectedType: string = '';
    public initialSslType: string;
    public displayInsufficientCreditWarning = false;
    public _vhostSslOrdered: boolean = false;
    private rapidSslProductCode = 'ssl-geotrust-rapidssl-12m';
    private sectigoProductCode = 'ssl-sectigo-dv-multi-domain-managed-12m';
    private selectedPrice: BillingApi.ArticlePurchasePrice;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private calculatePriceFilter: CalculatePriceFilter,
        private convertAmountFilter: ConvertAmountFilter,
        private depositModel: DepositModelService,
        private priceModel: PriceModelService,
        private sslCertificateModel: SslCertificateModelService
    ) {}

    public $onInit = (): void => {
        this.initialSslType = this.$editFormOrganism.sslOptions.type;
        if (this.initialSslType === this.rapidSslType || this.initialSslType === this.sectigoType) {
            this.setSslType( this.initialSslType );
            this.userConfirmedPayment = true;
        }
    };

    private setSslType( sslType: string ) {
        switch (sslType) {
            case this.rapidSslType:
                this.getPriceInfo( this.rapidSslType );
                this.selectedType = this.rapidSslType;
                break;
            case this.sectigoType:
                this.getPriceInfo( this.sectigoType );
                this.selectedType = this.sectigoType;
                break;
        }
    }

    public get vhostSslOrdered(): boolean {
        this._vhostSslOrdered = this.$editFormOrganism.vHost.sslSettings?.managedSslStatus == "ordered";
        return this._vhostSslOrdered;
    }

    public cancelCallback: () => void = () => {/* */};

    public get profileName(): string {
        if ([null, undefined].indexOf(this.$editFormOrganism.vHost.sslSettings) !== -1) {
            return '';
        }

        const profileObject = this.$editFormOrganism.sslOptions.profiles
        .filter((profile) => profile.value === this.$editFormOrganism.vHost.sslSettings.profile)[0];

        return profileObject.name;
    }

    public get isUnknownManagedSslType(): boolean {
        return [null, undefined].indexOf(this.$editFormOrganism.vHost.sslSettings) < 0
            && [null, undefined, ''].concat(knownManagedSslProductCodesConst)
                .indexOf(this.$editFormOrganism.vHost.sslSettings.managedSslProductCode) < 0;
    }

    public get panelDisabled(): boolean {
        return this.isUnknownManagedSslType
            || this.$editFormOrganism.sslOptions.isReadOnly
            || this._vhostSslOrdered;
    }

    public get saveButtonDisabled(): boolean { // used in template
        if (this.$editFormOrganism.autoValidationErrors.length > 0) {
            return true;
        }
        return ['rapid-ssl', 'sectigo'].indexOf(this.$editFormOrganism.sslOptions.type) >= 0
            && (this.userConfirmedPayment === false || this.displayInsufficientCreditWarning);
    }

    public errorDetail = (error, detailKey: string): boolean => {
        //       return error.details.filter((detail) => detail.key === detailKey)[0].value as boolean;
        return error.details.find((detail) => detail.key === detailKey).value as boolean;

    };

    public save = (): Promise<void> => {
        return void (this.$editFormOrganism.save())
            .then(() => this.certificatesPending);
    };

    public cancel = (): void => {
        if ([undefined, null].indexOf(this.cancelCallback) === -1) {
            this.cancelCallback();
        }
    };

    private sslTypeChanged = (newType: string): void => {
        if (newType === this.rapidSslType && this.selectedPrice === undefined) {
            this.getPriceInfo( this.rapidSslProductCode );
        }
        if (newType === this.sectigoType && this.selectedPrice === undefined) {
            this.getPriceInfo( this.sectigoProductCode );
        }

        this.$editFormOrganism.sslTypeChanged();
    };

    public get showMissingCertWarning(): boolean {
        // checks if cert is custom
        // and if there is additionally either no ssl settings or no cert
        if (
            this.$editFormOrganism.sslOptions.type === 'custom'
            && ( this.$editFormOrganism.vHost.sslSettings === null
            || this.$editFormOrganism.vHost.sslSettings.certificates === '')
            ){
            return true;
        }

        return false;
    }

    public get priceInfoText(): string {
        const net = this.$translate.instant('BILLING.LABEL.NET');
        const gross = this.$translate.instant('BILLING.LABEL.GROSS');
        const annually = this.$translate.instant('GENERAL.ANNUALLY');

        if (this.selectedPrice === undefined || this.selectedPrice.netAmount === undefined) {
            return '';
        }
        const netPrice = this.calculatePriceFilter(
            this.selectedPrice.netAmount,
            this.selectedPrice.amounts[0].vatRate,
            1,
            'net',
            undefined,
            false,
            this.selectedPrice.productCode,
            12
        );
        const grossPrice = this.calculatePriceFilter(
            this.selectedPrice.netAmount,
            this.selectedPrice.amounts[0].vatRate,
            1,
            'gross',
            undefined,
            false,
            this.selectedPrice.productCode,
            12
        );
        if (
            ( this.initialSslType !== this.rapidSslType && this.initialSslType !== this.sectigoType )
            && AuthContextService.isGranted(UiRights.BIL_GET_DEPOSIT)
            && this.depositModel.sufficientCreditForOrder(grossPrice * 10000) < 0
        ) {
            this.displayInsufficientCreditWarning = true;
        }
        if (AuthContextService.account.isCommercialCustomer) {
            return `${this.convertAmountFilter(netPrice)} ${this.selectedPrice.currency.toUpperCase()}
                ${net} (${this.convertAmountFilter(grossPrice)} ${this.selectedPrice.currency.toUpperCase()}
                ${gross} ${annually})`;
        } else {
            return `${this.convertAmountFilter(grossPrice)} ${this.selectedPrice.currency}
(${gross}) ${annually})`;
        }
    }

    private getPriceInfo = (productCode: string): void => {
        void this.priceModel.getPriceByProductCode(productCode).then(
            (price) => {
                this.selectedPrice = price;
            }
        );
    };
}

export class OrganEditPanelDomainSslComponent implements ng.IComponentOptions {
    public bindings = {
        cancelCallback: '<cancel',
        panelRight: '<'
    };

    public require = {
        $editFormOrganism: '^organismEditFormDomain'
    };

    public controllerAs = '$editPanelOrgan';
    public template = require('./domain-ssl.html');
    public controller = OrganEditPanelDomainSslController;
}
