import * as ng from 'angular';

export class OrganPanelAppInstallVhostSettingsController {
    public static $inject: string[] = [];

    public vhostSettings: any[] = [];
    public serviceObject: any;
    public language: string;
    public bundleId: string;

    public get panelCompleted() {
        return this.vhostSettings.every(
            (settings) => {
                return this.serviceObject.appSettings[settings.key] !== undefined;
            }
        );
    }

    // tslint:disable-next-line:no-empty
    public set panelCompleted({}) {}
}

export class OrganPanelAppInstallVhostSettingsComponent implements ng.IComponentOptions {
    public bindings = {
        vhostSettings: '<',
        serviceObject: '=',
        language: '<',
        panelCompleted: '=?',
        bundleId: '<'
    };
    public controller = OrganPanelAppInstallVhostSettingsController;
    public template = require('./vhost-settings.html');
}
