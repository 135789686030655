import * as ng from 'angular';
import { FormDropDownItems } from '@/atomic-components';
import * as Types from '@/types';

export class OrganEditPanelDomainMoveAccountController {
    public static $inject: string[] = ['$timeout', '$translate'];

    public bundles: Types.BundleApi.Bundle[] = [];
    public selectedDomains: Types.DomainApi.Domain[] = [];
    public subAccounts: Types.AccountApi.Subaccount[];
    public accountDropdownItems: FormDropDownItems[] = [];
    public selectedAccount: string;
    public callbackOnTargetAccountSelect: (accountId: string) => void;
    public domainAccountIds: string[] = [];
    public moveNotPossibleError = '';

    constructor(
        private $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onChanges(changes: ng.IOnChangesObject): void {
        if (changes.selectedDomains !== undefined) {
            this._setMoveNotPossibleError();
        }

        if (changes.subAccounts !== undefined) {
            this._buildSubaccountItems();
        }
    }

    public targetAccountSelected = (): void => {
        this.moveNotPossibleError = '';
        if (this.callbackOnTargetAccountSelect !== undefined && this.selectedAccount) {
            void this.$timeout(() => {
                const unmovableBundleDomain = this._getUnmovableBundleDomain();
                this._setMoveNotPossibleError();

                if (unmovableBundleDomain.length === 0) {
                    this.callbackOnTargetAccountSelect(this.selectedAccount);
                }
            });
        }
    };

    private _getUnmovableBundleDomain = (): Types.DomainApi.Domain[] => {
        return this.selectedDomains.filter(
            (domain) => !!domain.bundleId
                && this.bundles
                    .filter((bundle) => bundle.id === domain.bundleId)
                    .some((bundle) => bundle.accountId !== this.selectedAccount)
        );
    };

    private _setMoveNotPossibleError = (): void => {
        this.moveNotPossibleError = '';

        if (this.selectedAccount) {
            const unmovableBundleDomain = this._getUnmovableBundleDomain();

            if (unmovableBundleDomain.length > 0) {
                this.moveNotPossibleError = [
                    this.$translate.instant('TR_120821-57d197_TR'),
                    unmovableBundleDomain.map((domain) => domain.nameUnicode).join(', ')
                ].join(' ');
            }
        }
    };

    private _buildSubaccountItems = () => {
        this.accountDropdownItems = [];
        this.subAccounts.map(
            (subaccount) => {
                this.accountDropdownItems.push({
                    name: subaccount.name,
                    value: subaccount.id
                });
            }
        );
    };
}

export class OrganEditPanelDomainMoveAccountComponent implements ng.IComponentOptions {
    public bindings = {
        bundles: '<',
        selectedDomains: '<',
        callbackOnTargetAccountSelect: '<',
        domain: '<?',
        domainAccountIds: '<?',
        moveNotPossibleError: '=',
        selectedAccount: '=',
        subAccounts: '<'
    };
    public controller = OrganEditPanelDomainMoveAccountController;
    public template = require('./domain-move-account.html');
}
