import ng from 'angular';
import {
    addHiddenContactsFilter,
    AuthContextService,
    DomainHandleModelService,
    FilterUtils
} from '@/services';
import { DomainApi, ViewTypes } from '@/types';

import './domain-contact-search-modal.scss';

export class MoleculeDomainContactSearchModalController implements ng.IController {
    public static $inject: string[] = ['$timeout', 'domainHandleModel'];

    public _showModal: boolean;
    public searchTerm = '';
    public searchResults: ViewTypes.EntityWithPagination<DomainApi.Contact> = {
        data: [],
        pagination: {
            currentPage: null,
            entries: null,
            limit: null
        }
    };
    public owner: string;
    public handleList: ViewTypes.DomainContactObject[] = [];
    public selectedHandleType: string;
    public selectedContact = '';
    public checkboxSelected = {
        admin: false,
        owner: false,
        tech: false,
        zone: false
    };

    private confirmCallback: () => void;

    constructor(
        private $timeout: ng.ITimeoutService,
        private domainHandleModel: DomainHandleModelService
    ) {}

    public $onInit(): void {
        this.owner = this.owner || AuthContextService.account.id;
        this.searchTerm = this.searchTerm || '';
        this.checkboxSelected[this.selectedHandleType as DomainApi.DomainContactType] = true;
        this.updateItems();
    }

    public get showModal(): boolean {
        return this._showModal;
    }

    public set showModal(value: boolean) {
        this._showModal = value;
        if (value) {
            this.updateItems();
        }
    }

    public selectContact = (contactId: string): void => {
        this.selectedContact = contactId;
    };

    public isSelectedContact = (contactId: string): boolean => {
        return this.selectedContact === contactId;
    };

    public disabledContactType = (type: string): boolean => {
        return !this.handleList.some((handle) => handle.contactType === type);
    };

    public get isValidContactAndCheckboxes(): boolean {
        const isCheckboxSelected = !(Object.keys(this.checkboxSelected)
            .every((e: DomainApi.DomainContactType) => !this.checkboxSelected[e])
        );
        const isContactSelected  = [undefined, null, ''].indexOf(this.selectedContact) < 0;
        return (isCheckboxSelected && isContactSelected);
    }

    public applyContact = (): void => {
        for (const contactType in this.checkboxSelected) {
            if (this.checkboxSelected[contactType as DomainApi.DomainContactType] === true) {
                this.handleList.some((handle) => {
                    if (handle.contactType === contactType) {
                        void this.$timeout(() => {
                            handle.contactId = this.selectedContact;
                        });
                        return true;
                    }

                    return false;
                });
            }
        }

        if (this.confirmCallback !== undefined) {
            return this.confirmCallback();
        }
    };

    public updateItems = (limit = 5, page = 1): void => {
        if (typeof limit === 'boolean') {
            limit = this.searchResults.pagination.limit;
            page = this.searchResults.pagination.currentPage;
        }

        const filterFields = ['ContactHandle', 'ContactName', 'ContactOrganization', 'ContactStreet', 'ContactId'];

        const additionalContactFilters = [
            {
                field: 'UsableForDomainInAccount',
                value: this.owner
            }
        ];

        const simpleFilterSpecialCases = [
            FilterUtils.buildSpecialCases.id('ContactId'),
            FilterUtils.buildSpecialCases.customField()
        ];

        const filters = FilterUtils.buildFullFilter(
            [],
            this.searchTerm,
            filterFields,
            simpleFilterSpecialCases,
            additionalContactFilters
        );

        void this.domainHandleModel.list(
            limit,
            page,
            addHiddenContactsFilter(filters),
            undefined,
            null,
            this.owner
        ).then((reply) => { this.searchResults = reply; });
    };
}

export class MoleculeDomainContactSearchModalComponent implements ng.IComponentOptions {
    public bindings = {
        cancelCallback: '<',
        confirmCallback: '<?',
        owner: '=',
        selectableFor: '<?',
        selectedHandleType: '<',
        showModal: '=',
        handleList: '='
    };
    public controller = MoleculeDomainContactSearchModalController;
    public template = require('./domain-contact-search-modal.html');
}
