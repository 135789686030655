import { DatabaseUserObject } from '../../../../molecules';

export class OrganNewPanelDatabaseUserController {
    public static $inject: string[] = ['$timeout', '$translate', 'accountModel', 'authContext'];

    public newUserObject: DatabaseUserObject; // is used in template
    public cancel: any;
    public $newFormOrganism: any;
}

export class OrganNewPanelDatabaseUserComponent implements ng.IComponentOptions {
    public bindings = {
        newUserObject: '='
    };
    public require = {
        $newFormOrganism: '^organismNewFormDatabaseUser'
    };
    public controller = OrganNewPanelDatabaseUserController;
    public controllerAs = '$newPanelOrgan';
    public template = require('./database-user-new.html');
}
