import {
    RpcClient,
    RpcClientService
} from '@/services/rpc-client';
import { Finding } from '@/types/finding';

export const NetworkClientService = [
    'rpcClient',
    (platformRpcClient: RpcClientService) => platformRpcClient.Factory('/api/resource/v1/json/', 'network')
];

export class NetworkRobotService {
    public static $inject: string[] = ['resourceClient'];

    constructor(
        private resourceClient: RpcClient
    ) {}

    public list(filter, limit, page, sort) {
        return this.resourceClient.request('networksFind')
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public listWithoutPagination(filter, limit, page, sort) {
        return this.resourceClient.request('networksFind')
        .skipPagination()
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public listPrivateIpAddressRanges(filter?: Finding.Filter, limit?, page?, sort?, timeout?) {
        return this.resourceClient.request('privateIpAddressRangesFind')
        .timeout(timeout)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public listPublicIpAddressRanges(filter?: Finding.Filter, limit?: number, page?: number, sort?, timeout?) {
        return this.resourceClient.request('publicIpAddressRangesFind')
        .timeout(timeout)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }
}
