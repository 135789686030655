import { LinkListOverviewItem } from '@/atomic-components';
import { AccountFilterField, TextFilterField } from '@/atomic-components';
import { OverviewModel } from '@/services';
import { RequestCancellerService } from '@/services';
import { RightModelService } from '@/services';
import { AccountModelService } from '@/services';

export class RightsTemplateOverviewModelService extends OverviewModel {

    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', 'rightModel']
    );

    readonly service = 'rightsTemplate';
    readonly objectType = 'RightsTemplate';
    readonly idField = 'id';
    readonly defaultFilterFields = ['rightstemplatename'];

    public activeMassAction = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};

    protected listCallbacks = [{callback: this.rightModel.rightsTemplate}];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private rightModel: RightModelService,
        private accountModel: AccountModelService
    ) {
        super(RequestCanceller);
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('rightstemplatename', this.$translate.instant('TR_140119-796b28_TR')),
                new AccountFilterField('accountId', this.$translate.instant('TR_140119-b3b187_TR'))
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }

    public actionLinks = (params: { [param: string]: any }): LinkListOverviewItem[] => {
        return [
            {
                text: this.$translate.instant('TR_140119-d46484_TR'),
                route: 'reseller.rightstemplates.new'
            }
        ] as LinkListOverviewItem[];
    };

}
