import {
    DashboardWidgetModel,
    DashboardWidgetType
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model';
import {
    DashboardWidgetObjectDefinition
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model-operator';

export class DashboardWidgetFaqNextcloudModel extends DashboardWidgetModel {
    public static $inject: string[] = DashboardWidgetModel.$inject.concat([]);

    public static dashboardWidgetDefinition: DashboardWidgetObjectDefinition = {
        demoData: {},
        manualAddable: false,
        userPermissions: null,
        widgetDescription: [
            /* translationId */ 'TR_010221-8a5711_TR'
        ],
        widgetObject: {
            attribute: {
                id: 'organDashboardWidgetFaqNextcloud',
                width: 1,
                height: 1,
                x: 0,
                y: 0,
                noResize: true,
                autoPosition: true
            },
            componentTagName: 'organ-dashboard-widget-faq-nextcloud',
            widgetModel: 'dashboardWidgetFaqNextcloudModel',
            version: '1.0'
        },
        widgetTitle: 'Helpdesk', // /* translationId */ 'TR_290121-3e8ec1_TR',
        widgetType: DashboardWidgetType.custom
    };

    public dashboardWidgetDefinition: DashboardWidgetObjectDefinition;

    constructor() {
        super();

        this.dashboardWidgetDefinition = DashboardWidgetFaqNextcloudModel.dashboardWidgetDefinition;
    }
}
