import * as ng from 'angular';

import {
    PanelHeaderData
} from '../../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateNextcloudAdminAccessController {
    public static $inject: string[] = ['$state', '$translate'];

    public pageHeaderData: PanelHeaderData;
    public nextcloud;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.pageHeaderData = {
            backwardLink: this.$state.current.name.indexOf('storage-products') >= 0
                ? 'storage.storage-products.id.edit'
                : 'nextcloud.id.edit',
            backwardText: this.$translate.instant('TR_120819-1528d4_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: { storageProductId: this.nextcloud.id },
            panelIcon: '/assets/images/logos/nextcloud-bw.svg',
            panelIconPath: true,
            panelTitle: this.$translate.instant('TR_070720-f5a487_TR')
        };
    }
}

export class TemplateNextcloudAdminAccessComponent implements ng.IComponentOptions {
    public bindings = {
        nextcloud: '<'
    };
    public controller = TemplateNextcloudAdminAccessController;
    public template = require('./nextcloud-admin-access-template.html');
}
