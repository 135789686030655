import * as ng from 'angular';
import { ViewTypes } from '../../../../../types/view-types';

export class OrganismEmailWizardProductConfigSmtpForwarderController implements ng.IController {
    public metadata: ViewTypes.ProductConfigSmtpForwarderObject;

    public selectedProductCode: string = null;
    public vhostValidationErrors: string[] = []; // used in template
    public service = 'email';

    public currentForwarderTarget: string;
    public forwarderListErrors: any;
    public internalForwarder: any;
    public dnsValidationMessage: string;
    public emailValidationErrorList: string[] = [];

    public formCompleted = {
        emailAddress: () => [undefined, null, ''].indexOf(this.metadata?.mailbox?.emailAddress) < 0
        && this.emailValidationErrorList.length === 0
    };

    public set configCompleted({}) {} /* tslint:disable-line:no-empty */ // used by parent
    public get configCompleted() { // used by parent
        for (const part in this.formCompleted) {
            if (!this.formCompleted[part]) {
                return false;
            }
        }

        return true;
    }

    public get showSmtpSettings() {
        return this.formCompleted.emailAddress();
    }
}

export class OrganismEmailWizardProductConfigSmtpForwarderComponent implements ng.IComponentOptions {
    public bindings = {
        configCompleted: '=configCompleted',
        metadata: '='
    };

    public template = require('./email-wizard-product-config-smtp-forwarder.html');
    public controller = OrganismEmailWizardProductConfigSmtpForwarderController;
}
