import * as ng from 'angular';
import { ManagedMachineProjectObject } from '../../../organisms';

export class OrganManagedMachineProjectConfirmationWebspaceController implements ng.IController {
    public static $inject: string[] = [];

    public projectData: ManagedMachineProjectObject;

    public get userCreatingStatus() {
        let status = 'creating';

        if (this.projectData.errorCreating.webspaceUsers === 1) {
            status = 'failed';
        } else if (this.projectData.finishedCreating.webspaceUsers === 1) {
            status = 'installed';
        }
        return status;
    }

    public get webspaceCreatingStatus() {
        let status = 'creating';

        if (this.projectData.errorCreating.webspaceUsers === 1) {
            status = 'abort';
        } else if (this.projectData.errorCreating.webspaces === 1) {
            status = 'failed';
        }  else if (this.projectData.finishedCreating.webspaces === 1) {
            status = 'installed';
        }

        return status;
    }
}

export class OrganManagedMachineProjectConfirmationWebspaceComponent implements ng.IComponentOptions {
    public bindings = {
        projectData: '='
    };

    public template = require('./webspace.html');
    public controller =  OrganManagedMachineProjectConfirmationWebspaceController;
}
