import * as ng from 'angular';

export interface IGrid {
    text: string;
    icon?: string;
    filterValue: string;
    enabled: boolean;
}

export type IGridArray = IGrid[];

export class MoleculePanelGridController implements ng.IController {
    public static $inject: string[] = [];

    public grid: IGridArray;
    public title: string;
    public filterValues: string[];
    public singleSelection = false;

    public $onInit = (): void => {
        this.filterValues = [];
    };

    public togglePanel = (entry: IGrid): void => {
        entry.enabled = !entry.enabled;
        if (this.singleSelection) {
            this.grid.forEach((_entry) => { if (_entry.filterValue !== entry.filterValue) _entry.enabled = false; });
        }
        this.filterValues = this.grid
        .filter((_entry) => _entry.enabled)
        .map((_entry) => _entry.filterValue);
    };

    public hasBlueBackground = (entry: IGrid) => {
        return entry.enabled && entry.filterValue !== 'virtual_installed';
    };

    public hasGreenBackground = (entry: IGrid) => {
        return entry.enabled && entry.filterValue === 'virtual_installed';
    };
}

export class MoleculePanelGridComponent implements ng.IComponentOptions {
    public bindings = {
        grid: '<',
        title: '@',
        filterValues: '=?',
        singleSelection: '<?'
    };
    public controller = MoleculePanelGridController;
    public template = require('./panel-grid.html');
}
