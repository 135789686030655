import * as ng from 'angular';
import './overlay-modal.scss';

export class MoleculeOverlayModalController implements ng.IController {
    public static $inject: string[] = ['$timeout', '$translate'];

    public model = false;
    public icon: string;
    public title: string;

    public applyCallback: () => any;
    public applyText: string;
    public cancelCallback: () => void;
    public applyHide = false;
    public applyDisable: boolean;
    public doNotCloseOnApply = false;
    public newIcon = false;
    public buttonSaveName: string;

    private _body: HTMLBodyElement;
    private _model: boolean;

    constructor(
        private $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService
    ) {
        document.body.classList.remove('modal-open');
    }

    public $onInit() {
        this._model = ng.copy(this.model);
        this._body = document.getElementsByTagName('body')[0];
        this.icon = this.icon || '';
        this.applyText = this.applyText || this.$translate.instant('TR_100119-806fd1_TR');
        this.applyHide = this.applyHide || false;
    }

    public $doCheck() {
        if (this.model !== this._model) {
            this._handleModelChange();
            this._model = ng.copy(this.model);
        }
    }

    public get applyIsDefined() {
        return this.applyCallback !== undefined;
    }

    public cancelClick = () => {
        this.$timeout(() => {
            this.cancel();
        });

        if (this.cancelCallback) {
            this.cancelCallback();
        }
    };

    public apply = () => {
        if (!this.applyIsDefined) {
            this.cancel();
        }

        const callbackResponse = this.applyCallback();

        if (callbackResponse.then === undefined) {
            this.cancel();
        }

        callbackResponse.then(
            (response) => {
                if (response !== 0) {
                    this.cancel();
                }
            }
        );
    };

    public outsideClick = (event: any) => {
        if (
            [undefined, null].indexOf(event) < 0
            && [undefined, null].indexOf(event.path) < 0
            && !event.path.some((path: HTMLElement) => path === document.getElementById('modalDialog'))
        ) {
            this.cancelClick();
        }
    };

    private _handleModelChange = () => {
        if (this._body !== undefined) {
            if (this.model) {
                this._body.classList.add('modal-open');
            } else {
                this._body.classList.remove('modal-open');
            }
        }
    };

    private cancel = () => {
        this.model = false;
    };
}

export class MoleculeOverlayModalComponent implements ng.IComponentOptions {
    public bindings = {
        applyCallback: '<?',
        applyDisable: '<?',
        applyHide: '<?',
        applyText: '@?',
        buttonSaveName: '@?',
        cancelCallback: '<?',
        doNotCloseOnApply: '<?',
        icon: '@?',
        model: '=',
        newIcon: '<?',
        title: '@?'
    };
    public controller =  MoleculeOverlayModalController;
    public transclude = true;
    public template = require('./overlay-modal.html');
}
