import ng from 'angular';
import { AlertManagerService, ApiErrorModel, WebspaceModelService } from '@/services';
import { WebhostingApi } from '@/types';

export class OrganEditPanelWebspaceGeneralController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'apiErrorModel',
        'webspaceModel'
    ];

    public webspace: WebhostingApi.Webspace;
    public showObjectId: boolean;
    public panelRight;
    private originalWebspace: WebhostingApi.Webspace;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private webspaceModel: WebspaceModelService
    ) {}

    public $onInit(): void {
        this.webspace = JSON.parse(JSON.stringify(this.originalWebspace));
    }

    public save = (): WebhostingApi.Webspace | PromiseLike<WebhostingApi.Webspace> => {
        return this.webspaceModel.update(this.webspace).then((webspace) => {
            if (!this.apiErrorModel.apiResponseHasError(webspace)) {
                this.alertManager.success(this.$translate.instant('TR_240419-bf0089_TR'));
                return webspace as WebhostingApi.Webspace;
            }
            return Promise.reject(webspace);
        });
    };

    public cancel = (): void => {
        const tmp = ng.copy(this.originalWebspace);
        this.webspace.comments = tmp.comments;
        this.webspace.name = tmp.name;
    };
}

export class OrganEditPanelWebspaceGeneralComponent implements ng.IComponentOptions {
    public bindings = {
        panelRight: '=',
        originalWebspace: '<webspace',
        showObjectId: '<'
    };
    public controller = OrganEditPanelWebspaceGeneralController;
    public template = require('./webspace-general.html');
}
