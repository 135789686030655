import * as ng from 'angular';

export class JobExecutionDate {
    public static $inject: string[] = ['$translate'];

    public static Factory(
        $translate: ng.translate.ITranslateService
    ) {
        return (value: string) => value === ''
            ? $translate.instant('8c45521c-005d-4867-bcf0-f0ef38b03e48')
            : value;
    }
}
