import * as ng from 'angular';
import { WizardHelperService } from '@/atomic-components/molecules/wizard/';
import {
    BillingHelperService,
    PriceCacheService,
    ProductHelperService,
    ProductsModelService
} from '@/services';
import * as Types from '@/types';

export class OrganCreateSummaryWebspaceController implements ng.IController {
    public static $inject: string[] = ['$translate', 'billingHelper', 'priceCache', 'productsModel', 'wizardHelper'];

    public contingents: any;
    public panelHidden = false;
    public webspaceObject: any;
    public inContingent = false;

    private _productCode: string;
    private _productCodeSet = false;
    private _storageQuotaSet = false;
    private _priceSet = false;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private billingHelper: BillingHelperService,
        private priceCache: PriceCacheService,
        private productsModel: ProductsModelService,
        private wizardHelper: WizardHelperService
    ) {}

    public $onInit() {
        this._checkContingents();
        if ([undefined, null, ''].indexOf(this._productCode) < 0) {
            this.webspaceObject.webspace.productCode = this._productCode;
            this._setWebspaceProductData (this._productCode);
        }
    }

    public get cost() {
        if (!this._priceSet) {
            return '';
        }

        if (this.inContingent) {
            return;
        }

        if (this.webspaceObject.prices[0].price.price === null) {
            this.inContingent = true;
            return;
        }

        if (this.webspaceObject.prices[0].price.price === -1) {
            this.inContingent = false;
            return '';
        }

        return this.wizardHelper.calculateProductPrice(this.webspaceObject.prices);
    }

    public get currency() {
        if (!this._priceSet) {
            return '';
        }

        if ([undefined, null].indexOf(this.webspaceObject) >= 0
            || [null, -1].indexOf(this.webspaceObject.prices[0].price.price) >= 0
        ) {
            return;
        }

        return this.wizardHelper.getProductCurrency(this.webspaceObject.prices[0].price);
    }

    public get initialized() {
        return this._productCodeSet
            && this._storageQuotaSet
            && this._priceSet;
    }

    public set initialized({}) {} // tslint:disable-line:no-empty

    public getPrice() {
        return this.priceCache.listWebhostingPrices()
            .then((priceList) => {
                priceList.some((price: any) => {
                    if (price.productCode === this.webspaceObject.webspace.productCode) {
                        this.webspaceObject.prices = [{
                            billingCycle: this.billingHelper.getBillingCycle(price),
                            price: this.inContingent ? null : price,  // tslint:disable-line
                            productCode: price.productCode // tslint:disable-line
                        }];
                        this._priceSet = true;
                        return true;
                    }

                    return false;
                });
            }
        ) as ng.IPromise<Types.BillingApi.ArticlePurchasePrice>;
    }

    private _checkContingents = () => {
        if ([undefined, null].indexOf(this.contingents) >= 0) {
            if ([undefined, null, ''].indexOf(this.webspaceObject.webspace.productCode) >= 0) {
                // sent sentry errors
                return;
            }
            this.inContingent = false;
            this._productCodeSet = true;
            this._storageQuotaSet = true;
            this.getPrice();
            return;
        }

        this.contingents.map((contingent: any) => {
            switch (contingent.service) {
                case 'webhosting':
                    this.inContingent = true;
                    this._setWebspaceProductData(contingent.productCodes[0]);
                    break;
                case 'webspace-storage':
                    if (contingent.capacity > 0 && contingent.productCodes.length > 0) {
                        const capacity = +contingent.capacity;
                        this.productsModel.findProduct(contingent.productCodes[0], this.$translate.use())
                            .then((res) => {
                                const storageSpecification = ProductHelperService.wrapSpecificationItems(
                                    res.response.specificationItems
                                ).storage;

                                if (storageSpecification !== undefined) {
                                    this.webspaceObject.webspace.storageQuota += storageSpecification.intValue * capacity;
                                    this._storageQuotaSet = true;
                                }
                            }
                        );
                    }
                    break;
                default:
                    // nothing to do here
            }
        });
    };

    private _setWebspaceProductData = (productCode: string) => {
        this.productsModel.findProduct(productCode, this.$translate.use())
            .then((res) => {
                const specification = ProductHelperService.wrapSpecificationItems(res.response.specificationItems);

                if (specification.includedStorage) {
                    this.webspaceObject.webspace.storageQuota += specification.includedStorage.intValue;
                    this._productCodeSet = true;
                    this.getPrice();
                }
            }
        );
    };
}

export class OrganCreateSummaryWebspaceComponent implements ng.IComponentOptions {
    public bindings = {
        _productCode: '@?productCode',
        contingents: '<?',
        initialized: '=',
        panelHidden: '<?',
        webspaceObject: '<'
    };
    public template = require('./webspace-summary.html');
    public controller =  OrganCreateSummaryWebspaceController;
}
