import * as ng from 'angular';
import * as q from 'q';

import { AuthContextService, NavigationService } from '@/services';

import { UiLanguagesConst } from '../../../../../configuration/system';
import { StorageModelService } from '../../../../../services';
import { PanelType } from '../../../../molecules/forms/form-edit/form-edit';
import {
    OrganismEditFormNextcloudController
} from '../../../../organisms/forms/edit-forms/nextcloud-edit/nextcloud-edit';
import { ManagedApplicationApi } from './../../../../../types/managed-application-api';

export class OrganEditPanelNextcloudPluginsController {
    public static $inject: string[] = [
        '$state',
        'navigation',
        'storageModel'
    ];

    public $editFormOrganism: OrganismEditFormNextcloudController;
    public panelType: PanelType = PanelType.MISCELLANEOUS_ACTION;
    public pluginsEnabled: ManagedApplicationApi.NextcloudApp[];

    public constructor(
        private $state: ng.ui.IStateService,
        private navigation: NavigationService,
        private storageModel: StorageModelService
    ) {}

    public $onInit() {
        this.storageModel.nextcloudAppList(
            this.$editFormOrganism.originalNextcloud.id,
            UiLanguagesConst[AuthContextService.user.language]
        ).then((res) => {
            this.pluginsEnabled = res.response.data
                .map((plugin: ManagedApplicationApi.NextcloudApp) => {
                    plugin.enabled = this.$editFormOrganism.originalNextcloud.enabledApps.indexOf(plugin.id) >= 0;
                    plugin.notAvailable = plugin.availableInNextcloudProducts?.length > 0;
                    return plugin;
                })
                .filter((plugin: ManagedApplicationApi.NextcloudApp) => plugin.enabled);
        });
    }

    public goToAppCenter = () => {
        let stateName = 'storage.storage-products.id.app-center';
        if (this.$state.current.name.startsWith('nextcloud')) {
            stateName = 'nextcloud.id.app-center';
        }
        this.navigation.go(stateName,
            {
                storageProductId: this.$editFormOrganism.originalNextcloud.id
            },
            {
                reload: true
            }
        );
    };
}

export class OrganEditPanelNextcloudPluginsComponent implements ng.IComponentOptions {
    public require = {
        $editFormOrganism: '^organismEditFormNextcloud'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./nextcloud-plugins.html');
    public controller = OrganEditPanelNextcloudPluginsController;
}
