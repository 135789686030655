import * as ng from 'angular';

export class MoleculeButtonNextStepController implements ng.IController {
    public static $inject: string[] = [];

    public modifier = '';
    public disabled = false;
    public callback: () => void;
}

export class MoleculeButtonNextStepComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        callback: '<nextStepCallback',
        disabled: '<',
        modifier: '@?'
    };
    public controller =  MoleculeButtonNextStepController;
    public template = require('./button-next-step.html');
}
