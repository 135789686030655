import { FunctionRelayService } from '../function-relay';
import { ModelHelper } from '../model-helper';
import { RequestStatus } from '../rpc-client';
import { EmailRobotService } from './robot';

import * as q from 'q';

export class OrganizationModelService {
    public static $inject: string[] = ['emailRobot', 'functionRelay'];

    constructor(
        private emailRobot: EmailRobotService,
        private functionRelay: FunctionRelayService
    ) {}

    public list = (limit?, page?, filters?, sort?) => {
        sort = sort || {
            field: 'organizationName',
            order: 'ASC'
        };
        page = page || 1;

        return this.emailRobot.listOrganizations(filters, limit, page, sort)
        .then(ModelHelper.returnListResponse);
    };

    public listWithoutPagination = (limit?, page?, filters?, sort?) => {
        sort = sort || {
            field: 'organizationName',
            order: 'ASC'
        };
        page = page || 1;

        return this.emailRobot.listOrganizationsWithoutPagination(filters, limit, page, sort)
        .then(ModelHelper.returnListResponse);
    };

    public findOne = (id) => {
        return this.emailRobot.listOrganizationsWithoutPagination({field: 'organizationId', value: id}, 1, 1)
        .then(ModelHelper.returnFindOneResponse);
    };

    public findById = (ids) => {
        const filter = { subFilterConnective: 'OR', subFilter: ids.map(this.idFilter) };
        return this.emailRobot.listOrganizationsWithoutPagination(filter, 0, 1)
        .then(ModelHelper.returnFindResponse);
    };

    public create = (organization, owner?) => {
        return this.emailRobot.createOrganization(organization, owner)
        .then(this.returnResponse)
        .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
    };

    public update = (organization) => {
        return this.emailRobot.updateOrganization(organization)
        .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
    };

    public delete = (organizations) => {
        return q.all(organizations.map(this.deleteOne));
    };

    private refreshDeposit = (status: RequestStatus = RequestStatus.SUCCESSFUL) => (result) => {
        this.functionRelay.call('updateDeposit', {});
        if (status === RequestStatus.SUCCESSFUL) {
            return result;
        } else {
            return q.reject(result);
        }
    };

    private idFilter = (id) => {
        return { field: 'organizationId', value: id };
    };

    private returnResponse = (result) => {
        return result.response;
    };

    private deleteOne = (organization) => {
        return this.emailRobot.deleteOrganization(organization.id);
    };
}
