import ng from 'angular';
import './welcome-panel.scss';

export class TemplateSetupWelcomePanelController {
    public static $inject: string[] = []
    constructor(){}

    public orderComplete: boolean;

    get orderCompleteStatus(): boolean {
        return this.orderComplete;
    }
}

export class TemplateSetupWelcomePanelComponent implements ng.IComponentOptions {
    public bindings = {
        orderComplete: '<',
    };
    public template = require('./welcome-panel.html');
    public controller = TemplateSetupWelcomePanelController;
}
