import * as ng from 'angular';

import {
    OrganismOverviewController, OverviewDataObject
} from '../../../../../organisms/overview/overview';

import { AuthContextService } from '../../../../../../services/auth-context';

export class MoleculeOverviewRowPanelUserController implements ng.IController {
    public static $inject: string[] = ['$state', '$translate'];

    public overviewData: OverviewDataObject;
    public overviewLoad: (selection: any) => any;
    public internalPaginationLimit: number;
    public OrganismOverview: OrganismOverviewController;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public getTwoFactorAuthDisplay = (setting: any) => {
        switch (setting) {
            case 'disabled':
                return this.$translate.instant('GENERAL.OBJECT.STATUS.DISABLED');
            case 'active' :
                return this.$translate.instant('2e87b37f-477a-49e4-b799-896bf4cbc762');
            case 'waitingForActivation':
                return this.$translate.instant('TR_140119-23fb95_TR');
        }
    };

    public translateBoolean = (bol: boolean): string => {
        const yesNoMap = {
            false: 'TR_100519-bcd1c0_TR',
            true: 'TR_100519-9ea5da_TR'
        };

        return this.$translate.instant(yesNoMap[bol.toString()]);
    };

    public get resellerRoute() {
        return this.$state.current.name.indexOf('subaccounts') >= 0;
    }

    public get showAccounts() {
        return this.OrganismOverview.globals.showAccounts;
    }

    public get isAdminUser(): boolean {
        return AuthContextService.isAdminUser;
    }
}

export class MoleculeOverviewRowPanelUserComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };

    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };

    public controller =  MoleculeOverviewRowPanelUserController;
    public template = require('./users-row.html');
}
