import ng from 'angular';
import { UiRights } from '@/configuration';
import { ApiValue, FloatToApi } from '@/filters';
import {
    AccountModelService,
    AlertManagerService,
    AuthContextService,
    BillingHelperService,
    DomainHandleModelService,
    ErrorMapperService,
    NavigationService,
    UserOverviewModelService
} from '@/services';
import { AccountApi, DomainApi, Finding, ViewTypes } from '@/types';
import { EditPanelRight, IDomainContactNameList } from '@/atomic-components';

import './sub-account-edit.scss';

interface IEventHumanReadableEmail {
    markedForRemoval: boolean;
    value: string;
}
interface ISubAccountNotificationData {
    eventDocumentFormat: string;
    eventHumanReadableEmailAddresses: IEventHumanReadableEmail[];
    eventMachineReadableEmailAddresses: IEventHumanReadableEmail[];
    receiveSubaccountEvents: boolean;
    readableEmail: boolean;
    machineReadableEmail?: boolean;
}

export class OrganismEditFormSubAccountController implements ng.IController {
    public static $inject: string[] = [
        '$translate',
        'accountModel',
        'alertManager',
        'billingHelper',
        'domainHandleModel',
        'errorMapper',
        'navigation',
        'userOverviewModel'
    ];

    public userHasRightAccAccountUserList = AuthContextService.isGranted(
        UiRights.ACC_ACCOUNT_USER_LIST
    ); // used in template!
    public subAccountData: AccountApi.Subaccount;
    public rightsTemplates: AccountApi.RightsTemplate[];
    public allRights: unknown[];
    public rightsInTemplate: string[];
    public subAccountBasicData: {
        addition: string;
        allowedAdvertisingMethods: string[];
        city: string;
        comments: string;
        contactName: string;
        contactTitle: string;
        country: string;
        customerNumber: string;
        isCommercialCustomer: boolean;
        name: string;
        parentAccountLanguage?: string;
        street: string;
        title: string;
        zipCode: string;
    };
    public subAccountContactData: {
        phoneNumber: string;
        faxNumber: string;
        emailAddress: string;
        emailAddressNewsletter: string;
        emailAddressTechnicalContact: string;
        emailAddressInvoice: string;
    };
    public subAccountBillingData: AccountApi.SubaccountBillingSettings;
    public subAccountNotificationData: ISubAccountNotificationData;
    public subAccountDomainHandles: ViewTypes.DomainContactObject[] = [];
    public domainHandleTypes: string[] = ['Owner', 'Admin', 'Tech', 'Zone'];
    public handleLabels: { contactType: string; label: string}[] = [
        { contactType: 'owner', label: /* translationId */ '79134e90-56a4-4816-bf30-f97c09642428' },
        { contactType: 'admin', label: /* translationId */ 'd18a253e-7318-4484-a57d-8c15dc660f56' },
        { contactType: 'tech', label: /* translationId */'3167f6f0-c06f-404d-a46a-0844f88bf408' },
        { contactType: 'zone', label: /* translationId */ '49cff6be-32f9-4167-b2f2-1fad856c2fb5' }
    ];
    public domainContactList: IDomainContactNameList[] = [];
    public parentAccountLanguage: string;
    public panelRightSettings: EditPanelRight;
    public overviewModel: UserOverviewModelService;
    public accountPath: unknown = [];
    public overviewServiceData: {
        extendedFilters: Finding.Filter[];
        overviewUiSettings: unknown;
        simpleFilter: Finding.Filter;
    };

    private simpleFilter: Finding.Filter;
    private extendedFilters: Finding.Filter[];
    private overviewUiSettings: unknown;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService,
        private alertManager: AlertManagerService,
        private billingHelper: BillingHelperService,
        private domainHandleModel: DomainHandleModelService,
        private errorMapper: ErrorMapperService,
        private navigation: NavigationService,
        public userOverviewModel: UserOverviewModelService
    ) {}

    public $onInit = async (): Promise<void> => {
        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter
        };

        this.resetSubAccountBasicData();
        this.resetSubAccountContactData();
        this.resetSubAccountBillingData();
        this.resetSubAccountNotificationSettings();
        await this.resetSubAccountDomainSettings();

        void this.accountModel.getPath(this.subAccountData.id).then(
            (response): unknown => this.accountPath = response
        );
    };

    public resetSubAccountBasicData = (): void => {
        this.subAccountBasicData = {
            addition: this.subAccountData.addition,
            allowedAdvertisingMethods: this.subAccountData.allowedAdvertisingMethods,
            city: this.subAccountData.city,
            comments: this.subAccountData.comments,
            contactName: this.subAccountData.contactName,
            contactTitle: this.subAccountData.contactTitle,
            country: this.subAccountData.country.toUpperCase(),
            customerNumber: this.subAccountData.customerNumber,
            isCommercialCustomer: this.subAccountData.isCommercialCustomer,
            name: this.subAccountData.name,
            parentAccountLanguage: this.parentAccountLanguage,
            street: this.subAccountData.street,
            title: this.subAccountData.title,
            zipCode: this.subAccountData.zipCode
        };
    };

    public saveSubAccountBasicData = (): void => {
        const subAccountBackup = JSON.stringify(this.subAccountData);

        this.subAccountData.name = this.subAccountBasicData.name;
        this.subAccountData.addition = this.subAccountBasicData.addition;
        this.subAccountData.allowedAdvertisingMethods = this.subAccountBasicData.allowedAdvertisingMethods;
        this.subAccountData.customerNumber = this.subAccountBasicData.customerNumber;
        this.subAccountData.isCommercialCustomer = this.subAccountBasicData.isCommercialCustomer;
        this.subAccountData.street = this.subAccountBasicData.street;
        this.subAccountData.zipCode = this.subAccountBasicData.zipCode;
        this.subAccountData.city = this.subAccountBasicData.city;
        this.subAccountData.country = this.subAccountBasicData.country.toLowerCase();
        this.subAccountData.comments = this.subAccountBasicData.comments;

        if (this.subAccountBasicData.title) {
            this.subAccountData.title = this.subAccountBasicData.title;
        } else {
            delete this.subAccountData.title;
        }
        if (this.subAccountBasicData.contactName) {
            this.subAccountData.contactName = this.subAccountBasicData.contactName;
        } else {
            delete this.subAccountData.contactName;
        }
        if (this.subAccountBasicData.contactTitle) {
            this.subAccountData.contactTitle = this.subAccountBasicData.contactTitle;
        } else {
            delete this.subAccountData.contactTitle;
        }

        this.accountModel.updateSubaccount(this.subAccountData).then(
            () => {
                this.alertManager.success(this.$translate.instant('TR_110119-b6c0e9_TR'));
                this.resetSubAccountBasicData();
            },
            (reason) => {
                this.alertManager.error(this.errorMapper.map(reason));
                this.subAccountData = JSON.parse(subAccountBackup);
                this.resetSubAccountBasicData();
            }
        );
    };

    public resetSubAccountContactData = (): void => {
        this.subAccountContactData = {
            emailAddress: this.subAccountData.emailAddress,
            emailAddressInvoice: this.subAccountData.emailAddressInvoice,
            emailAddressNewsletter: this.subAccountData.emailAddressNewsletter,
            emailAddressTechnicalContact: this.subAccountData.emailAddressTechnicalContact,
            faxNumber: this.subAccountData.faxNumber,
            phoneNumber: this.subAccountData.phoneNumber
        };
    };

    public saveSubAccountContactData = (): void => {
        const subAccountContactDataBackup = {
            emailAddress: this.subAccountData.emailAddress,
            emailAddressInvoice: this.subAccountData.emailAddressInvoice,
            emailAddressNewsletter: this.subAccountData.emailAddressNewsletter,
            emailAddressTechnicalContact: this.subAccountData.emailAddressTechnicalContact,
            faxNumber: this.subAccountData.faxNumber,
            phoneNumber: this.subAccountData.phoneNumber
        };

        this.subAccountData.phoneNumber = this.subAccountContactData.phoneNumber;
        this.subAccountData.faxNumber = this.subAccountContactData.faxNumber;
        this.subAccountData.emailAddress = this.subAccountContactData.emailAddress;
        this.subAccountData.emailAddressNewsletter = this.subAccountContactData.emailAddressNewsletter;
        this.subAccountData.emailAddressTechnicalContact = this.subAccountContactData.emailAddressTechnicalContact;
        this.subAccountData.emailAddressInvoice = this.subAccountContactData.emailAddressInvoice;

        this.accountModel.updateSubaccount(this.subAccountData).then(
            () => {
                this.alertManager.success(this.$translate.instant('TR_110119-2d98a7_TR'));
                this.resetSubAccountContactData();
            },
            (reason) => {
                const backup = subAccountContactDataBackup;

                this.alertManager.error(this.errorMapper.map(reason));

                this.subAccountData.phoneNumber = backup.phoneNumber;
                this.subAccountData.faxNumber = backup.faxNumber;
                this.subAccountData.emailAddress = backup.emailAddress;
                this.subAccountData.emailAddressNewsletter = backup.emailAddressNewsletter;
                this.subAccountData.emailAddressTechnicalContact = backup.emailAddressTechnicalContact;
                this.subAccountData.emailAddressInvoice = backup.emailAddressInvoice;

                this.resetSubAccountBasicData();
            }
        );
    };

    public resetSubAccountBillingData = (): void => {
        const purchaseMarkup = this.billingHelper.convertRealValueToDisplayValue(
            this.subAccountData.billingSettings.purchaseMarkup
        );

        this.subAccountBillingData = {
            creditLimit: ApiValue.parseApiValue(this.subAccountData.billingSettings.creditLimit),
            paymentType: this.subAccountData.billingSettings.paymentType,
            purchaseMarkup: purchaseMarkup,
            renewalsReportEnabled: this.subAccountData.billingSettings.renewalsReportEnabled,
            renewalsReportHorizonInDays: this.subAccountData.billingSettings.renewalsReportHorizonInDays,
            renewalsReportIntervalInDays: this.subAccountData.billingSettings.renewalsReportIntervalInDays,
            type: 'SubaccountBillingSettings'
        };
    };

    public saveSubAccountBillingData = (): void => {
        const subAccountBillingDataBackup = {
            creditLimit: this.subAccountData.billingSettings.creditLimit,
            paymentType: this.subAccountData.billingSettings.paymentType,
            purchaseMarkup: this.subAccountData.billingSettings.purchaseMarkup
        };

        this.subAccountData.billingSettings.paymentType = this.subAccountBillingData.paymentType;

        if (this.subAccountBillingData.paymentType !== 'external') {
            this.subAccountData.billingSettings.creditLimit = FloatToApi.convertNumericToApiInt(
                this.subAccountBillingData.creditLimit
            );
        }

        this.subAccountData.billingSettings.purchaseMarkup = this.billingHelper
            .convertDisplayValueToRealValue(this.subAccountBillingData.purchaseMarkup);

        this.accountModel.updateSubaccount(this.subAccountData).then(
            () => {
                this.alertManager.success(this.$translate.instant('TR_110119-0304c7_TR'));
                this.resetSubAccountBillingData();
            },
            (reason) => {
                this.alertManager.error(this.errorMapper.map(reason));
                this.subAccountData.billingSettings.creditLimit = subAccountBillingDataBackup.creditLimit;
                this.subAccountData.billingSettings.purchaseMarkup = subAccountBillingDataBackup.purchaseMarkup;
                this.resetSubAccountBillingData();
            }
        );
    };

    public resetSubAccountNotificationSettings = (): void => {
        const eventHumanReadableEmailAddresses: IEventHumanReadableEmail[] = [];
        const eventMachineReadableEmailAddresses: IEventHumanReadableEmail[] = [];

        for (const address of this.subAccountData.messageSettings.eventHumanReadableEmailAddresses) {
            eventHumanReadableEmailAddresses.push({
                markedForRemoval: false,
                value: address
            });
        }

        for (const address of this.subAccountData.messageSettings.eventMachineReadableEmailAddresses) {
            eventMachineReadableEmailAddresses.push({
                markedForRemoval: false,
                value: address
            });
        }

        this.subAccountNotificationData = {
            eventDocumentFormat: ([undefined, null, ''].indexOf(this.subAccountData.messageSettings.eventDocumentFormat) < 0)
                ? this.subAccountData.messageSettings.eventDocumentFormat
                : 'json',
            eventHumanReadableEmailAddresses: eventHumanReadableEmailAddresses,
            eventMachineReadableEmailAddresses: eventMachineReadableEmailAddresses,
            readableEmail: this.subAccountData.messageSettings.eventDeliveryMethods.indexOf('humanReadableEmail') >= 0,
            machineReadableEmail: this.subAccountData.messageSettings.eventDeliveryMethods.indexOf('machineReadableEmail') >= 0,
            receiveSubaccountEvents: this.subAccountData.messageSettings.receiveSubaccountEvents
        };
    };

    public saveSubAccountNotificationSettings = (): void => {
        const notificationSettingsBackup = JSON.stringify(this.subAccountData.messageSettings);

        if (
            this.subAccountNotificationData.readableEmail
            && this.subAccountData.messageSettings.eventDeliveryMethods.indexOf('humanReadableEmail') < 0
        ) {
            this.subAccountData.messageSettings.eventDeliveryMethods.push('humanReadableEmail');
        } else if (
            !this.subAccountNotificationData.readableEmail
            && this.subAccountData.messageSettings.eventDeliveryMethods.indexOf('humanReadableEmail') >= 0
        ) {
            this.subAccountData.messageSettings.eventDeliveryMethods.splice(
                this.subAccountData.messageSettings.eventDeliveryMethods.indexOf('humanReadableEmail'),
                1
            );
        }

        if (this.subAccountNotificationData.readableEmail) {
            this.subAccountData.messageSettings.eventHumanReadableEmailAddresses = this.subAccountNotificationData
                .eventHumanReadableEmailAddresses
                .filter(
                    (item) => !item.markedForRemoval
                ).map(
                    (item) => item.value
                );
        }

        this.subAccountData.messageSettings.receiveSubaccountEvents =
            this.subAccountNotificationData.receiveSubaccountEvents;

        if (
            this.subAccountNotificationData.machineReadableEmail
            && this.subAccountData.messageSettings.eventDeliveryMethods.indexOf('machineReadableEmail') < 0
        ) {
            this.subAccountData.messageSettings.eventDeliveryMethods.push('machineReadableEmail');
        } else if (
            !this.subAccountNotificationData.machineReadableEmail
            && this.subAccountData.messageSettings.eventDeliveryMethods.indexOf('machineReadableEmail') >= 0
        ) {
            this.subAccountData.messageSettings.eventHumanReadableEmailAddresses = [];
            this.subAccountData.messageSettings.eventDocumentFormat = '';
            this.subAccountData.messageSettings.eventDeliveryMethods.splice(
                this.subAccountData.messageSettings.eventDeliveryMethods.indexOf('machineReadableEmail'),
                1
            );
        }

        if (this.subAccountNotificationData.machineReadableEmail) {
            this.subAccountData.messageSettings.eventDocumentFormat = this.subAccountNotificationData
                .eventDocumentFormat;

            this.subAccountData.messageSettings.eventMachineReadableEmailAddresses = this.subAccountNotificationData
                .eventMachineReadableEmailAddresses
                .filter(
                    (item) => !item.markedForRemoval
                ).map(
                    (item) => item.value
                );
        }

        this.accountModel.updateSubaccount(this.subAccountData).then(
            () => {
                this.alertManager.success(this.$translate.instant('TR_110119-28c924_TR'));
                this.resetSubAccountNotificationSettings();
            },
            (reason) => {
                this.alertManager.error(this.errorMapper.map(reason));

                this.subAccountData.messageSettings = JSON.parse(notificationSettingsBackup);

                this.resetSubAccountNotificationSettings();
            }
        );
    };

    public resetSubAccountDomainSettings = async (): Promise<void> => {
        this.subAccountDomainHandles = [];
        const labelPrefix = `${this.$translate.instant('55e57c03-21be-437e-8fb6-9833641f9791')} `;

        this.domainHandleTypes.map(
            (handleType) => {
                const handleId = (this.subAccountData.domainSettings as unknown as Record<string, string>)[`defaultContact${handleType}Id`];
                this.subAccountDomainHandles.push({
                    contactType: handleType.toLocaleLowerCase() as DomainApi.DomainContactType,
                    contactId: handleId,
                    label: labelPrefix + this._getHandleLabelFromType(handleType.toLocaleLowerCase())
                });
            }
        );

        await this.reloadDomainContactList();
    };

    public saveSubAccountDomainSettings = (): void => {
        const defaultContactsBackup = {
            admin: this.subAccountData.domainSettings.defaultContactAdminId,
            owner: this.subAccountData.domainSettings.defaultContactOwnerId,
            tech: this.subAccountData.domainSettings.defaultContactTechId,
            zone: this.subAccountData.domainSettings.defaultContactZoneId
        };

        this.domainHandleTypes.map(
            (handleType) => {
                let handleTypeId;
                this.subAccountDomainHandles.some(
                    (handle) => {
                        if (handle.contactType.toLocaleLowerCase() === handleType.toLocaleLowerCase()) {
                            handleTypeId = handle.contactId;
                            return true;
                        }

                        return false;
                    }
                );
                (this.subAccountData.domainSettings as unknown as Record<string, string>)[`defaultContact${handleType}Id`] = handleTypeId;
            }
        );

        this.accountModel.updateSubaccount(this.subAccountData).then(
            () => {
                this.alertManager.success(this.$translate.instant('TR_110119-ecec61_TR'));
                this.navigation.reloadCurrentState(true);
            },
            async (reason) => {
                this.alertManager.error(this.errorMapper.map(reason));
                this.subAccountData.domainSettings.defaultContactAdminId = defaultContactsBackup.admin;
                this.subAccountData.domainSettings.defaultContactOwnerId = defaultContactsBackup.owner;
                this.subAccountData.domainSettings.defaultContactTechId = defaultContactsBackup.tech;
                this.subAccountData.domainSettings.defaultContactZoneId = defaultContactsBackup.zone;
                await this.resetSubAccountDomainSettings();
            }
        );
    };

    public reloadDomainContactList = async (): Promise<void> => {
        await this.domainHandleModel.findHandle(
            [{ field: 'UsableForDomainInAccount', value: this.subAccountData.id }],
            100
        )
        .then(
            (responses: DomainApi.Contact[]) => {
                this.domainContactList = responses.map(
                    (contact: DomainApi.Contact) => ({
                        names: [contact.handle, contact.name, this.getAddress(contact), contact.organization],
                        value: contact.id
                    })
                );
            }
        );
    };

    public saveRights = (): void => {
        void this.accountModel.updateSubaccount(this.subAccountData).then(
            () => this.alertManager.success(this.$translate.instant('TR_110119-178b02_TR'))
        );
    };

    public getDomainContactTypeString = (type: string): string => {
        switch (type) {
            case 'org': return this.$translate.instant('TR_100119-77ee69_TR');
            case 'person': return this.$translate.instant('TR_100119-74543b_TR');
            default: return '';
        }
    };

    private getAddress = (contact: DomainApi.Contact): string => {
        return [
            [undefined, null].indexOf(contact.street) < 0 ? contact.street : '',
            [undefined, null, ''].indexOf(contact.city) < 0 ? contact.city : '',
            [undefined, null].indexOf(contact.country) < 0 ? contact.country : ''
        ]
        .filter((part) => part.length > 0)
        .join(', ');
    };

    private _getHandleLabelFromType = (handleType: string): string => {
        let label: string;
        this.handleLabels.some(
            (handle) => {
                if (handle.contactType === handleType) {
                    label = this.$translate.instant(handle.label);
                    return true;
                }

                return false;
            }
        );

        return label;
    };
}

export class OrganismEditFormSubAccountComponent implements ng.IComponentOptions {
    public bindings = {
        allRights: '<',
        extendedFilters: '<',
        overviewUiSettings: '<',
        panelRightSettings: '<',
        parentAccountLanguage: '<',
        rightsTemplates: '<',
        simpleFilter: '<',
        subAccountData: '<'
    };

    public controller = OrganismEditFormSubAccountController;
    public template = require('./sub-account-edit.html');
}
