import { AuthContextService } from '@/services/auth-context';
import { RouteFinderService, RouteRightsList } from '@/services/route-finder';
import * as ng from 'angular';


export class MoleculePanelLinkPermissionTesterController implements ng.IController {
    public static $inject: string[] = ['routeFinder'];

    public constructor(
        private routeFinder: RouteFinderService,
    ) {}

    public routeRights: RouteRightsList;
    public link: string = '';

    public searchLinkPermission = () => {
        this.routeRights = this.routeFinder.getPermissionRouteTable(this.link);
    }

    public get showPermissionTester() {
        return AuthContextService.isRoot;
    }
}

export class MoleculePanelLinkPermissionTesterComponent implements ng.IComponentOptions {
    public controller =  MoleculePanelLinkPermissionTesterController;
    public template = require('./panel-link-permission-tester.html');
}
