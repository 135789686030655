import * as ng from 'angular';
import * as q from 'q';
import {
    AlertManagerService,
    AuthContextService,
    WebspaceModelService
} from '../../../../../services';

enum EmailSenderStatus {
    ALLOWED = 1,
    DEFERRED = 2,
    DISABLED = 3
}

export class OrganEditPanelWebspaceAdminOptionsController {

    public get webspaceToggleStatus() {
        return this.webspaceActive ? this.$translate.instant('GENERAL.ACTIVATE') : this.$translate.instant('TR_GENERAL.DISABLED_TR');
    }

    public get removableRestrictions() {
        return this.$editFormOrganism.webspace.restrictions.filter(
            restriction => [1, restriction.accountId].indexOf(AuthContextService.account.id) >= 0
        );
    }
    public static $inject: string[] = ['$translate', '$sce', 'alertManager', 'webspaceModel'];

    public $editFormOrganism: any;
    public panelRight: any;

    public webspaceActive: boolean;
    public originalWebspaceActive: boolean;
    public mailSettings = {
        sendEmailOption: null,
        limitMail: false,
        mailLimit: 1
    };
    public sendMailOptions: { value: EmailSenderStatus; label: string }[];

    public comment = '';
    public initalComment = '';
    private originalMailSettings: any;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private $sce: ng.ISCEService,
        private alertManager: AlertManagerService,
        private webspaceModel: WebspaceModelService
    ) {}

    $onInit() {
        this.webspaceActive = !this.$editFormOrganism.webspace.restrictions.some(restriction => restriction.type === 'disabled');
        this.originalWebspaceActive = ng.copy(this.webspaceActive);
        this.comment = this._getDisabledWebspaceComment();
        this.initalComment = ng.copy(this.comment);
        this.mailSettings.sendEmailOption = this._getMailSendOption();
        this.mailSettings.limitMail = this.$editFormOrganism.webspace.emailChecksActivated;
        this.mailSettings.mailLimit = this.$editFormOrganism.webspace.emailRateLimitQuota;
        this.originalMailSettings = ng.copy(this.mailSettings);
        this.sendMailOptions = [{
            value: EmailSenderStatus.ALLOWED,
            label: this.$translate.instant('TR_140119-5b55af_TR')
        }, {
            value: EmailSenderStatus.DEFERRED,
            label: this.$translate.instant('TR_140119-5364ea_TR')
        }, {
            value: EmailSenderStatus.DISABLED,
            label: this.$translate.instant('TR_140119-cb7786_TR')
        }];
    }

    public getWebspaceStatusPanelHeaderTitle = () => {
        if (this.$editFormOrganism.webspace.status === 'pending') {
            return this.$translate.instant('TR_140119-1b5bc6_TR');
        }
        return this.originalWebspaceActive
            ? this.$translate.instant('TR_140119-310901_TR')
            : this.$translate.instant('TR_140119-f1eae1_TR');
    };

    public cancelSettings = () => {
        this.mailSettings.sendEmailOption = ng.copy(this.originalMailSettings.sendEmailOption);
    };

    public cancelBan = () => {
        this.comment = ng.copy(this.initalComment);
        this.webspaceActive = ng.copy(this.originalWebspaceActive);
    };

    public cancelLimit = () => {
        this.mailSettings.limitMail = ng.copy(this.originalMailSettings.limitMail);
        this.mailSettings.mailLimit = ng.copy(this.originalMailSettings.mailLimit);
    };

    public updateWebspaceStatus = () => {
        if (this.webspaceActive) {
            return this.$editFormOrganism.enable();
        } else {
            if (!this.originalWebspaceActive) {
                return this.$editFormOrganism.update(this.comment);
            }
            return this.$editFormOrganism.disable(this.comment);
        }
    };

    public setMailSettings = () => {
        let promise;

        switch (this.mailSettings.sendEmailOption) {
            case EmailSenderStatus.ALLOWED:
                promise = this.webspaceModel.emailSenderActivate(this.$editFormOrganism.webspace.id);
                break;
            case EmailSenderStatus.DEFERRED:
                promise = this.webspaceModel.emailSenderDefer(this.$editFormOrganism.webspace.id);
                break;
            case EmailSenderStatus.DISABLED:
                promise = this.webspaceModel.emailSenderDisable(this.$editFormOrganism.webspace.id);
                break;
            default:
                break;
        }

        promise.then(
            res => {
                if (res.status !== 'error') {
                    this.alertManager.success(this.$translate.instant('TR_140119-cc26ce_TR'));
                }
                return res;
            },
            () => q.reject(false)
        );
    };

    public setMailLimit = () => {
        const webspaceCopy = ng.copy(this.$editFormOrganism.webspace);
        webspaceCopy.emailChecksActivated = this.mailSettings.limitMail;
        webspaceCopy.emailRateLimitQuota = this.mailSettings.mailLimit;

        return this.webspaceModel.update(webspaceCopy).then(res => {
            this.alertManager.success(this.$translate.instant('TR_140119-a648d5_TR'));
            return res;
        });
    };

    public commentReadonly = (comment) => {
        return this.$sce.trustAsHtml(comment.split('\n').join('<br />'));
    };

    private _getMailSendOption = () => {
        let sendOption = 1;
        this.$editFormOrganism.webspace.restrictions.map(restriction => {
            switch (restriction.type) {
                case 'mailSendingDisabled':
                    sendOption = 3;
                    break;
                case 'mailSendingDeferred':
                    sendOption = 2;
                    break;
            }
        });

        return sendOption;
    };

    private _getDisabledWebspaceComment = () => {
        let comment = '';
        this.$editFormOrganism.webspace.restrictions
            .filter(restriction => restriction.type === 'disabled')
            .map(restriction => comment = restriction.internalComment);
        return comment;
    };

}

export class OrganEditPanelWebspaceAdminOptionsComponent implements ng.IComponentOptions {
    public bindings = {
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormWebspaceAdminOptions'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./webspace-admin-options.html');
    public controller = OrganEditPanelWebspaceAdminOptionsController;
}
