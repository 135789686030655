import * as ng from 'angular';
import { UiLanguagesConst } from '@/configuration';
import { AuthContextService, ProductHelperService, ProductsModelService } from '@/services';
import { CacheService } from '@/services/cache';
import { ManagedApplicationRobotService } from '@/services/managed-application';
import { ProductApi, UI } from '@/types';

export class NextcloudHelperService {
    public static $inject: string[] = [
        'cache',
        'managedApplicationRobot',
        'productsModel'
    ];

    constructor(
        private cache: CacheService,
        private managedApplicationRobot: ManagedApplicationRobotService,
        private productsModel: ProductsModelService
    ) {}

    public checkNextcloudJobRunning = (nextcloudId: string): PromiseLike<boolean> => {
        this.cache.get('managedapplication::jobsFind').clear();

        return this.managedApplicationRobot.jobsFind(
            {
                subFilter: [
                    { field: 'jobObjectType', value: 'Nextcloud' },
                    { field: 'jobObjectId', value: nextcloudId },
                    { field: 'jobStatus', relation: 'unequal', value: 'successful' },
                    { field: 'jobStatus', relation: 'unequal', value: 'failed' },
                    { field: 'jobStatus', relation: 'unequal', value: 'canceled' }
                ],
                subFilterConnective: 'AND'
            }
        ).then((res) => res.response.data.length > 0);
    };

    public checkNextcloudJobSupport = (nextcloudId: string): PromiseLike<boolean> => {
        this.cache.get('managedapplication::jobsFind').clear();

        return this.managedApplicationRobot.jobsFind(
            {
                subFilter: [
                    { field: 'jobObjectType', value: 'Nextcloud' },
                    { field: 'jobObjectId', value: nextcloudId },
                    { field: 'jobStatus', relation: 'equal', value: 'support' }
                ],
                subFilterConnective: 'AND'
            }
        ).then((res) => res.response.data.length > 0);
    };

    public productHasSpecificationItem(key: string, nextcloudProduct: ProductApi.AbstractProduct): boolean {
        if (!nextcloudProduct) {
            return false;
        }

        return ProductHelperService.wrapSpecificationItems(nextcloudProduct.specificationItems)[key] !== undefined;
    }

    public getValueFromProductSpecification(
        key: string,
        nextcloudProduct: ProductApi.AbstractProduct
    ): string | number | boolean | string[] | { key: string; value: string }[] {
        if (!nextcloudProduct) {
            return '';
        }

        return ProductHelperService.wrapSpecificationItems(nextcloudProduct.specificationItems)[key]?.value as any;
    }

    public aggregateProductAddonsData
        = async (productCode: string): Promise<UI.nextcloudAggregatedProductAddonsData> => {
        const aggregatedData: UI.nextcloudAggregatedProductAddonsData = {};

        // list of available Addons and what specification items should be looked up
        const addonProducts: UI.nextcloudAddonLookupDefinition[] = [
            {
                fields: [
                    { apiName: 'storageQuotaMaximum', grabFromParent: true, uiName: 'max' },
                    { apiName: 'storageQuotaIncluded', grabFromParent: true, uiName: 'included' },
                    { apiName: 'storage', grabFromParent: false, uiName: 'step' }
                ],
                productCodeLookup: 'additionalStorageProductCode',
                uiName: 'storage'
            },
            {
                fields: [
                    { apiName: 'nextcloudUsersMaximum', grabFromParent: true, uiName: 'max' },
                    { apiName: 'nextcloudUsersIncluded', grabFromParent: true, uiName: 'included' },
                    { apiName: 'additionalUsers', grabFromParent: false, uiName: 'step' }
                ],
                productCodeLookup: 'additionalUsersProductCode',
                uiName: 'users'
            },
            {
                fields: [
                    { apiName: 'talkUsersMaximum', grabFromParent: true, uiName: 'max' },
                    { apiName: 'talkUsersIncluded', defaultValue: 0, grabFromParent: true, uiName: 'included' },
                    { apiName: 'additionalTalkUsers', grabFromParent: false, uiName: 'step' }
                ],
                productCodeLookup: 'additionalTalkUsersProductCode',
                uiName: 'talkUsers'
            },
            {
                fields: [
                    { apiName: 'officeUsersMaximum', grabFromParent: true, uiName: 'max' },
                    { apiName: 'officeUsersIncluded', defaultValue: 0, grabFromParent: true, uiName: 'included' },
                    { apiName: 'additionalOfficeUsers', grabFromParent: false, uiName: 'step' }
                ],
                productCodeLookup: 'additionalOfficeUsersProductCode',
                uiName: 'officeUsers'
            }
        ];

        // lookup the Nextcloud Product details
        const apiProductSearch = await this.productsModel.findOne(
            productCode,
            UiLanguagesConst[AuthContextService.user.language as 'de'|'en'],
            true
        );
        const parent = ng.copy(apiProductSearch.response) as ProductApi.Product;
        aggregatedData.productData = parent;

        // aggregate the product details for all available addons
        for (const addon of addonProducts) {
            const currentProduct: UI.nextcloudAggregatedSingleProductAddonsData =
                // @ts-ignore TS2339
                aggregatedData[addon.uiName] =
                { isExtensible: false };

            // check if the addon is available
            currentProduct.isExtensible = this.productHasSpecificationItem(
                addon.productCodeLookup,
                parent
            );

            if (currentProduct.isExtensible) {
                // if it is available grab the data from the product api
                const addonProductCode = this.getValueFromProductSpecification(
                    addon.productCodeLookup,
                    parent
                );
                const apiAddonSearch = await this.productsModel.findOne(
                    addonProductCode as string,
                    UiLanguagesConst[AuthContextService.user.language as 'de'|'en'],
                    true
                );
                currentProduct.productData = ng.copy(apiAddonSearch.response) as ProductApi.Product;

                for (const field of addon.fields) {
                    // @ts-ignore TS2339
                    currentProduct[field.uiName] = this.getValueFromProductSpecification(
                        field.apiName,
                        field.grabFromParent ? parent : currentProduct.productData
                    );

                    // @ts-ignore TS2339
                    if ((currentProduct[field.uiName] || '') === '' && field.defaultValue !== undefined) {
                        // @ts-ignore TS2339
                        currentProduct[field.uiName] = field.defaultValue;
                    }

                    // @ts-ignore TS2339
                    if (typeof currentProduct[field.uiName] === 'string' && /\d/.test(currentProduct[field.uiName])) {
                        // @ts-ignore TS2339
                        currentProduct[field.uiName] = parseInt(currentProduct[field.uiName], 10);
                    }
                }
            }
        }

        return aggregatedData;
    };
}
