import {
    ValidateRegularExpression
} from '../../../../../services';
import { FormInputModelOptions } from '../../../../atoms/form-elements';
import { Validator } from './../../../panels/panel-edit/panel-edit';

import * as ng from 'angular';
import { OrganEditPanelBillingPaymentRedeemVoucherComponent } from '../../../../organs';

export class MoleculeInputTextDnsRecordNameController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public validationErrorList = [];
    public value: string;
    public placeholder: string;
    public callbackOnChange: (arg0: any) => any;
    public callbackOnBlur: (arg0: any) => any;
    public callbackOnBlurParams: any;
    public customCallbackOnEnter: () => {};
    public doNotSaveOnFormEnter: boolean;
    public disabled: boolean;
    public modelOptions: FormInputModelOptions;
    public registerToForm: boolean;
    public validationInstructions: any[];
    public options: any;
    public recordType: string;
    public inputId: string;
    public callbackOnClear: (arg0: any) => any;
    public callbackOnClearParams: any;
    public clearable: boolean;
    private _value: string;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {
    }

    public $onInit() {
        this.validationInstructions = [];
        this.validationErrorList = [];
        this._value = ng.copy(this.value);
    }

    public $doCheck() {
        if (!ng.equals(this.value, this._value)) {
            this.validationInstructions = [];
            this.validationErrorList = [];
            this._value = ng.copy(this.value);
            this._setValidator(true);
        }
    }

    public onPaste = (paste) => {
        // implement callback on paste here when needed!
    };

    private _setValidator = (onChange: boolean = false) => {
        const allowEmpty = true;
        const searchMatches = true;
        if (this.recordType === undefined) {
            return;
        }

        const match = (/^([\*\-_\.a-zA-Z0-9])*$/).source;
        const flags = 'igm';

        const validator = {
            instructions: null,
            validator: new ValidateRegularExpression(this.$translate, match, flags, allowEmpty, searchMatches)
        };

        if (validator) {
            this.validationInstructions.push(validator);
            this.validationErrorList = validator.validator.validate(this.value);
        }
    };
}

export class MoleculeInputTextDnsRecordNameComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnBlur: '<',
        callbackOnBlurParams: '<',
        callbackOnChange: '<',
        callbackOnClear: '<',
        callbackOnClearParams: '<',
        clearable: '<',
        customCallbackOnEnter: '<',
        disabled: '<',
        doNotSaveOnFormEnter: '<',
        inputId: '@?',
        modelOptions: '<',
        options: '<',
        placeholder: '@',
        recordType: '<',
        registerToForm: '<',
        validationErrorList: '=?',
        value: '='
    };

    public controller = MoleculeInputTextDnsRecordNameController;
    public template = require('./input-text-with-validation-wrapper.html');
}
