import * as ng from 'angular';
import { OrganismOverviewController, OverviewDataObject } from '../../../../../organisms';

import './nameserver-set-row.scss';

export class MoleculeOverviewRowPanelNameserverSetController implements ng.IController {
    public static $inject: string[] = ['mailboxModel'];

    public overviewData: OverviewDataObject;
    public overviewLoad: (selection: any) => any;
    public internalPaginationLimit: number;
    public OrganismOverview: OrganismOverviewController;

    public get showAccounts() {
        return this.OrganismOverview.globals.showAccounts;
    }

    public setCheckboxId = (item): string => {
        return `checkbox-${item.name.replace(/ /g, '-')}`;
    };
}

export class MoleculeOverviewRowPanelNameserverSetComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelNameserverSetController;
    public template = require('./nameserver-set-row.html');
}
