import * as ng from 'angular';
import * as q from 'q';

import { ProductConfigManagedServerProjectObject } from '../../../atomic-components/organisms/wizards';
import * as Configurations from '../../../configuration';
import * as Types from '../../../types';
import { VhostModelService } from '../../webhosting/vhost-model';
import { AuthContextService } from './../../auth-context';
import { DatabaseAccessLevelsAllRightsConst } from '../../../configuration';

export enum ManagedMachineProjectSubaccountTypes {
    EXISTEND,
    NEW,
    NONE
}
export class ManagedServerProjectComposerService {
    public static $inject: string[] = ['$translate', 'vhostModel'];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private vhostModel: VhostModelService
    ) {}

    public buildManagedProjectApiObject = async (metadata: ProductConfigManagedServerProjectObject) => {
        const composerObject: Types.ViewTypes.WizardComposerObject = {
            apiObject: {
                accountId: null,
                accountType: metadata.accountType,
                adminUser: null,
                newAccount: null,
                database: null,
                databaseUser: null,
                projectName: metadata.projectName,
                vhost: null,
                webspace: null,
                webspaceUser: null
            }
        };

        // set Account data
        switch (metadata.accountType) {
            case ManagedMachineProjectSubaccountTypes.NEW:
                delete metadata.newSubAccount.formvalid;
                composerObject.apiObject.newAccount = this._mergeSubAccount(metadata.newSubAccount);
                composerObject.apiObject.adminUser = {
                    emailAddress: composerObject.apiObject.newAccount.emailAddress,
                    language: Configurations.UiLanguagesReverseConst[this.$translate.use()],
                    name: composerObject.apiObject.newAccount.name
                };
                break;
            default:
                composerObject.apiObject.accountId = metadata.accountId;
        }

        // set database data
        if (metadata.createDatabase) {
            composerObject.apiObject.databaseUser = {
                password: metadata.databasePassword,
                user: {
                    accountId: metadata.accountType === 1 ? null : metadata.accountId,
                    name: metadata.projectName.replace(' ', '_').toLowerCase()
                }
            };
            composerObject.apiObject.database = {
                databaseObject: {
                    accesses: [{
                        accessLevel: DatabaseAccessLevelsAllRightsConst,
                        userId: null
                    }],
                    name: metadata.projectName,
                    storageQuota: 0
                },
                databaseCapacity: metadata.databaseCapacity,
                databaseServerId: metadata.databaseServerId,
                poolId: metadata.databasePoolId
            };
        }

        // set webspace data
        if (metadata.createWebspace) {
            const defaultVhost = await this.vhostModel.defaultVhostObject();
            composerObject.apiObject.vhost = {
                phpIni: Configurations.VhostPhpIniDefaultObjectConst,
                vhost: defaultVhost,
                sslPrivateKey: '',
                setHttpUserPasswords: []
            };
            // Adjust some vhost default configuration options
            composerObject.apiObject.vhost.vhost.domainName = metadata.domainName;
            composerObject.apiObject.vhost.vhost.domainNameUnicode = metadata.domainName;
            composerObject.apiObject.vhost.vhost.webRoot = metadata.domainName;

            composerObject.apiObject.webspaceUser = {
                password: metadata.webspacePassword,
                name: metadata.projectName.replace(' ', '_').toLowerCase()
            };
            composerObject.apiObject.webspace = {
                poolId: metadata.webserverPoolId,
                userId: null,
                webserverId: metadata.webserverId,
                webspaceName: metadata.projectName
            };
        }

        return q.when(composerObject);
    };

    private _mergeSubAccount = (newAccount) => {
        const defaultSubaccountObject = {
            billingSettings: {
                creditLimit: 0,
                paymentType: 'external',
                purchaseMarkup: 0,
                renewalsReportEnabled: false,
                retailMarkup: 10000
            },
            country: '',
            dnsSettings: {
                defaultTemplateId: ''
            },
            domainSettings: {
                defaultContactAdminId: '',
                defaultContactOwnerId: '',
                defaultContactTechId: '',
                defaultContactZoneId: '',
                monthlyPaymentEnabled: false
            },
            emailAddress: '',
            isCommercialCustomer: false,
            language: Configurations.UiLanguagesReverseConst[this.$translate.use()],
            messageSettings: {
                eventDeliveryMethods: [],
                eventDocumentFormat: '',
                eventHumanReadableEmailAddresses: [],
                eventMachineReadableEmailAddresses: [],
                eventWebhookUrl: '',
                messageTemplateBehavior: 'skipToRoot',
                receiveSubaccountEvents: false
            },
            name: '',
            parentAccountId: AuthContextService.account.id,
            password: '',
            resellerBillingSettings: {
                activePaymentMethods: [],
                availablePaymentMethods: [],
                endUserCancellationPolicy: '',
                payPalUser: '',
                retailMarkup: 10000,
                showEndUserCancellationPolicyAboveAmount: 0,
                stripePublicApiKey: ''
            },
            rights: [],
            rightsTemplateId: ''
        };

        return Object.assign(defaultSubaccountObject, newAccount);
    };
}
