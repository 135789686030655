import * as ng from 'angular';
import * as q from 'q';

import {
    OverviewModelUtilityChart
} from '@/atomic-components/molecules/charts/utility-status/utility-status';
import {
    LinkListOverviewItem
} from '@/atomic-components/molecules/links/link-list-overview/link-list-overview';
import {
    AccountFilterField, SelectFilterField, TextFilterField
} from '@/atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { UiRights } from '@/configuration';
import { ObjectStatusFilter } from '@/filters/common-job-and-object-filters';
import { OverviewModel } from '../abstract';
import { AuthContextService } from '../auth-context';
import { RequestCancellerService } from '../request-canceller';
import { MailboxModelService } from './mailbox-model';

export class MailboxOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', 'mailboxModel', '$state', 'objectTypeFilter', 'objectStatusFilter']
    );

    public readonly service = 'email';
    public readonly objectType = 'Mailbox';
    public readonly idField = 'mailboxId';
    public readonly defaultFilterFields = ['MailboxEmailAddressUnicode'];

    public activeMassAction: string | boolean = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};

    protected listCallbacks = [];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private mailboxModel: MailboxModelService,
        private $state: ng.ui.IStateService,
        private objectTypeFilter,
        private objectStatusFilter: ObjectStatusFilter
    ) {
        super(RequestCanceller);

        // declare multi action availably methods
        this.actionLinksAvailabilities = {
            deletableMailboxes: this.deletableMailboxes,
            restorableMailboxes: this.restorableMailboxes,
            lockableMailboxes: this.lockableMailboxes,
            unlockableMailboxes: this.unlockableMailboxes
        };

        this.listCallbacks = [{
            callback: this.listEmails
        }];
    }

    public listEmails = (limit?, page?, filters?, sort?) => {
        if (this.$state.current.name.split('.').indexOf('dashboard') >= 0) {
            return this.mailboxModel.listWithoutPagination(limit, page, filters, sort);
        } else {
            const filtersAreProvided = [undefined, null, []].indexOf(filters) < 0
            const organizationIdIsFiltered = filtersAreProvided && (
                (
                    filters
                    && filters.field
                    && filters.field === 'OrganizationId'
                )
                ||
                (
                    filters
                    && [undefined, null, []].indexOf(filters.subFilter) < 0
                    && filters.subFilter.length > 0
                    && filters.subFilter.some( (filter) => filter.field === 'OrganizationId')
                )
            );

            if ( filtersAreProvided && organizationIdIsFiltered ) {
                return this.mailboxModel.listByOrganization(limit, page, filters, sort);
            } else {
                return this.mailboxModel.list(limit, page, filters, sort);
            }
        }
    };

    public actionLinks = (params): LinkListOverviewItem[] => {
        let createRoute = 'email.mailboxes.new';
        let createRouteParams = null;
        let inStateManagedServers = false;

        switch (this.$state.current.name.split('.')[0]) {
            case 'bundle':
                createRoute = 'bundle.id.mailboxes.new';
                createRouteParams = { bundleId: this.$state.$current.locals.globals.bundle.id };
                break;
            case 'managed-servers':
                createRoute = 'managed-servers.id.mailboxes.new';
                createRouteParams = { machineId: this.$state.$current.locals.globals.machine.id };
                inStateManagedServers = true;
                break;
            default:
                // nothing to do here
        }
        const actionLinks = [
            {
                isGranted: AuthContextService.isGrantedAny(
                    [UiRights.EMAIL_MAILBOX_DELETE]
                ),
                linkList: [
                    {
                        group: 'delete',
                        groupName: this.$translate.instant('TR_150119-26e918_TR'),
                        isClickable: 'deletableMailboxes',
                        isGranted: AuthContextService.isGranted(UiRights.EMAIL_MAILBOX_DELETE),
                        onclickMethode: () => this.activeMassAction = 'delete',
                        route: '',
                        text: this.$translate.instant('TR_150119-436c60_TR')
                    },
                    {
                        group: 'delete',
                        isClickable: 'restorableMailboxes',
                        isGranted: AuthContextService.isGranted(UiRights.EMAIL_MAILBOX_RESTORE),
                        onclickMethode:  () => this.activeMassAction = 'restore',
                        route: '',
                        text: this.$translate.instant('TR_150119-04f07d_TR')
                    },
                    {
                        group: 'delete',
                        isClickable: 'restorableMailboxes',
                        isGranted: AuthContextService.isGranted(UiRights.EMAIL_MAILBOX_DELETE),
                        onclickMethode:  () => this.activeMassAction = 'deleteRestorable',
                        route: '',
                        text: this.$translate.instant('TR_150119-2baaac_TR')
                    },
                    {
                        group: 'restrictions',
                        groupName: this.$translate.instant('TR_111219-42f902_TR'),
                        isClickable: 'lockableMailboxes',
                        isGranted: AuthContextService.isGrantedAll([
                            UiRights.EMAIL_MAILBOX_EDIT,
                            UiRights.EMAIL_CATCHALL_EDIT
                        ]),
                        onclickMethode: () => this.activeMassAction = 'lock',
                        route: '',
                        text: this.$translate.instant('TR_111219-e3b58f_TR')
                    },
                    {
                        group: 'restrictions',
                        isClickable: 'unlockableMailboxes',
                        isGranted: AuthContextService.isGrantedAll([
                            UiRights.EMAIL_MAILBOX_EDIT,
                            UiRights.EMAIL_CATCHALL_EDIT
                        ]),
                        onclickMethode: () => this.activeMassAction = 'unlock',
                        route: '',
                        text: this.$translate.instant('TR_111219-5720d2_TR')
                    }
                ],
                onlyOnExperView: true,
                route: '',
                text: this.$translate.instant('TR_140119-080106_TR'),
                type: 'multi'
            },
            {
                isGranted: AuthContextService.isGrantedAny([
                    UiRights.EMAIL_IMAPMAILBOX_CREATE,
                    UiRights.EMAIL_EXCHANGE_CREATE,
                    UiRights.EMAIL_FORWARDER_CREATE
                ]),
                route: createRoute,
                routeParams: createRouteParams,
                text: this.$translate.instant('TR_150119-4637be_TR')
            }
        ] as LinkListOverviewItem[];

        if (!inStateManagedServers) {
            actionLinks.unshift({
                isGranted: AuthContextService.isGrantedAny([
                    UiRights.EMAIL_ORGANIZATION_LIST,
                    UiRights.EMAIL_JOBS_LIST
                ]),
                linkList: [
                    {
                        group: 'redirect',
                        isGranted: AuthContextService.isGranted(UiRights.EMAIL_JOBS_LIST),
                        route: 'email.mailboxes.jobs',
                        text: this.$translate.instant('MENU.CONTEXT.JOBS')
                    }
                ],
                route: '',
                text: this.$translate.instant('TR_100119-a22157_TR'),
                type: 'multi'
            });
        }

        return actionLinks;
    };

    /**
     * Mailbox in Bundle, set utilityChart for status chartValue
     */

    public bundleUtilityChart = (): OverviewModelUtilityChart => {
        return {
            inUse: true,
            infoText: this.$translate.instant('TR_150119-25a47d_TR'),
            maxUse: true,
            productcodes: ['email-imap-mailbox', 'email-forwarder-external'],
            showAbsolute: true
        };
    };

    /**
     *  Submit methods
     */
    public deleteSubmit = (mailboxes, settings: any) => {
        const promises = mailboxes
            .filter((mailbox) => this.selected[mailbox.id])
            .filter((mailbox) => ['ordered', 'restorable'].indexOf(mailbox.status) < 0)
            .filter((mailbox) => [undefined, null].indexOf(mailbox.deletionScheduledFor) >= 0)
            .map((mailbox) => this.mailboxModel.delete([mailbox], this.getDeletionDate(mailbox, settings)));

        return q.all(promises);
    };

    public restoreSubmit(mailboxes) {
        const restoreMailboxes = Object.keys(this.selected).map((elem) => {
            if (!this.selected[elem]) {
                return false;
            }
            return this.getObjectFromId(mailboxes, elem);
        });

        return this.mailboxModel.restore(restoreMailboxes);
    }

    public deleteRestoreSubmit(mailboxes) {
        const deleteMailboxes = Object.keys(this.selected).map((elem) => {
            if (!this.selected[elem]) {
                return false;
            }
            return this.getObjectFromId(mailboxes, elem);
        });

        return q.all(deleteMailboxes.map((object) => object.id).map(this.mailboxModel.purgeRestorable));
    }

    public lockSubmit(mailboxes) {
        const promises = mailboxes
            .filter((mailbox) => this.selected[mailbox.id])
            .map((mailbox) => {
                const apiObject = {
                    mailboxId: mailbox.id,
                    restrictionsToAdd: [],
                    restrictionsToUpdate: [],
                    restrictionIdsToDelete: [],
                    createEvent: false
                };

                apiObject.restrictionsToAdd.push({
                    accountId: AuthContextService.account.id,
                    information: this.$translate.instant('TR_121219-3cf044_TR'),
                    internalComment: this.$translate.instant('TR_111219-b2090b_TR'),
                    type: 'smtpDisabled'
                });

                return this.mailboxModel.mailboxModifyRestrictions(apiObject);
            });

        return q.all(promises);
    }

    public unlockSubmit(mailboxes) {
        const promises = mailboxes
            .filter((mailbox) => this.selected[mailbox.id])
            .map((mailbox) => {
                const apiObject = {
                    mailboxId: mailbox.id,
                    restrictionsToAdd: [],
                    restrictionsToUpdate: [],
                    restrictionIdsToDelete: [],
                    createEvent: false
                };

                for (const restriction of mailbox.restrictions) {
                    apiObject.restrictionIdsToDelete.push(restriction.id);
                }

                return this.mailboxModel.mailboxModifyRestrictions(apiObject);
            });

        return q.all(promises);
    }

    /**
     *  Action availabbility definitions
     */
    public deletableMailboxes = (mailboxes) => {
        if (mailboxes.length === 0) {
            return false;
        }

        let selected = 0;
        const deleteable = Object.keys(this.selected).filter((elem) => {
            if (!this.selected[elem]) {
                return false;
            }
            selected += 1;

            return ['active', 'overQuota', 'restricted'].indexOf(this.getObjectFromId(mailboxes, elem).status) >= 0;
        });

        return deleteable.length > 0 && deleteable.length === selected;
    };

    public restorableMailboxes = (mailboxes) => {
        if (mailboxes.length === 0) {
            return false;
        }

        let selected = 0;
        const restorable = Object.keys(this.selected).filter((elem) => {
            if (!this.selected[elem]) {
                return false;
            }
            selected += 1;

            return ['restorable'].indexOf(this.getObjectFromId(mailboxes, elem).status) >= 0;
        });

        return restorable.length > 0 && restorable.length === selected;
    };

    public lockableMailboxes = (mailboxes) => {
        if (mailboxes.length === 0) {
            return false;
        }

        let selected = 0;
        const restrictable = Object.keys(this.selected).filter((elem) => {
            if (!this.selected[elem]) {
                return false;
            }
            selected += 1;

            return ['active', 'overQuota'].indexOf(this.getObjectFromId(mailboxes, elem).status) >= 0;
        });

        return restrictable.length > 0 && restrictable.length === selected;
    };

    public unlockableMailboxes = (mailboxes) => {
        if (mailboxes.length === 0) {
            return false;
        }

        let selected = 0;
        const unlockable = Object.keys(this.selected).filter((elem) => {
            if (!this.selected[elem]) {
                return false;
            }
            selected += 1;

            return ['restricted'].indexOf(this.getObjectFromId(mailboxes, elem).status) >= 0;
        });

        return unlockable.length > 0 && unlockable.length === selected;
    };

    private getDeletionDate = (mailbox: any, settings: any) => {
        if (settings.actiontype === 'sofort') {
            return null;
        }
        if (settings.actiontype === 'endOfContract') {
            return new Date(mailbox.paidUntil);
        }
        if (settings.actiontype === 'date') {
            return settings.deleteDate.dateObj;
        }
    };

    private getObjectFromId = (objects, objectId) => {
        return objects.filter((object) => object.id === objectId)[0];
    };

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('MailboxEmailAddressUnicode', this.$translate.instant('TR_150119-547c32_TR')),
                new TextFilterField('OrganizationId', 'hidden'),
                new SelectFilterField(
                    'MailboxType',
                    this.$translate.instant('TR_070519-16189c_TR'),
                    [
                        {
                            name: this.objectTypeFilter('Catchall'),
                            value: 'CatchAll'
                        },
                        {
                            name: this.objectTypeFilter('ExchangeMailbox'),
                            value: 'ExchangeMailbox'
                        },
                        {
                            name: this.objectTypeFilter('Forwarder'),
                            value: 'Forwarder'
                        },
                        {
                            name: this.objectTypeFilter('ImapMailbox'),
                            value: 'ImapMailbox'
                        },
                        {
                            name: this.objectTypeFilter('MailingList'),
                            value: 'MailingList'
                        },
                        {
                            name: this.objectTypeFilter('SmtpForwarder'),
                            value: 'SmtpForwarder'
                        }
                    ]
                ),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR')),
                new SelectFilterField(
                    'MailboxStatus',
                    this.$translate.instant('TR_140119-8e4b60_TR'),
                    [
                        {
                            name: this.objectStatusFilter('creating'),
                            value: 'creating'
                        },
                        {
                            name: this.objectStatusFilter('blocked'),
                            value: 'blocked'
                        },
                        {
                            name: this.objectStatusFilter('active'),
                            value: 'active'
                        },
                        {
                            name: this.objectStatusFilter('restorable'),
                            value: 'restorable'
                        }
                    ]
                )
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters({}) {} // tslint:disable-line:no-empty
}
