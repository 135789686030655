import { Validator } from './abstract-validator';
import { ValidateDns } from './validate-dns';

export class ValidateSrv extends Validator {
    private dnsValidator;

    constructor(private $translate: ng.translate.ITranslateService) {
        super();
        this.dnsValidator = new ValidateDns($translate);
    }

    public validate = (value: string) => {
        const values = value.split(' ');

        if (values.length !== 3) {
            return [{
                text: this.$translate.instant('TR_170119-084561_TR')
            }];
        }

        const weight = parseInt(values[0], 10);
        const port = parseInt(values[1], 10);
        const server = values[2];

        // This will fail in IE because isInteger is not supported.
        if (!(Number as any).isInteger(weight) || weight < 0 || weight > 65535) {
            return [{
                text: this.$translate.instant('TR_170119-084561_TR')
            }];
        }

        // This will fail in IE because isInteger is not supported.
        if (!(Number as any).isInteger(port) || port < 0 || port > 65535) {
            return [{
                text: this.$translate.instant('TR_170119-084561_TR')
            }];
        }

        if (this.dnsValidator.validate(server).length > 0) {
            return [{
                text: this.$translate.instant('TR_170119-084561_TR')
            }];
        }

        return [];
    };
}
