import * as ng from 'angular';
import { ApiErrorModel, BundleModelService } from '../../../../../services';
import { OrchestratorRobotService } from '../../../../../services/orchestrator/robot';
import * as Types from '../../../../../types';
import { WizardObject } from '../../../../molecules/wizard';
import { WizardDatabaseObject, WizardVhostDomainObject, WizardWebspaceObject } from '../../../../organs/create';

export interface WizardCreateDataObject {
    accountId: string;
    projectName: string;
    bundle?: Types.OrchestrationApi.Bundle;
    database?: WizardDatabaseObject;
    databaseAccess?: any;
    domain?: WizardVhostDomainObject;
    vhost?: WizardVhostDomainObject;
    webspace?: WizardWebspaceObject;
    webspaceAccess?: any;
}

export class OrganismCreateBundleController {
    public static $inject: string[] = [
        'apiErrorModel',
        'bundleModel',
        'orchestratorRobot'
    ];

    public serviceObject: WizardObject;
    public createStep = 'config';
    public bundleProductData: null | any = null;
    public jobOrderId: string = null;
    public apiOrderRequestError = false;

    private _selectedProduct = null;

    constructor(
        private apiErrorModel: ApiErrorModel,
        private bundleModel: BundleModelService,
        private orchestratorRobot: OrchestratorRobotService
    ) {}

    public $onInit() {
        this.serviceObject.selectedProduct = null;
        if ([undefined, null].indexOf(this.serviceObject.display.sufficientCreditAvailable) >= 0) {
            this.serviceObject.display.sufficientCreditAvailable = true;
        }
        this.serviceObject.display.contingents = [
            { service: 'database', codeStartsWith: 'database', productCodes: [], capacity: 0},
            { service: 'domain', codeStartsWith: 'domain', productCodes: [], capacity: 0},
            { service: 'webhosting', codeStartsWith: 'webhosting-bundle-webspace-', productCodes: [], capacity: 0},
            { service: 'webspace-storage', codeStartsWith: 'webhosting-bundle-webspace-storage', productCodes: [], capacity: 0}, // tslint:disable-line
            { service: 'email', codeStartsWith: 'email', productCodes: [], capacity: 0}
        ];
        this.serviceObject.settings.createData = {
            // tslint:disable:object-literal-sort-keys
            accountId: '',
            projectName: '',
            bundle: {} as Types.OrchestrationApi.Bundle,
            database: {} as WizardDatabaseObject,
            domain: {} as WizardVhostDomainObject,
            vhost: {} as WizardVhostDomainObject,
            webspace: {} as WizardWebspaceObject
            // tslint:enable:object-literal-sort-keys
        } as WizardCreateDataObject;
    }

    public $doCheck() {
        if (this.serviceObject.selectedProduct !== this._selectedProduct) {
            // watchout: User selected a product
            this._selectedProduct = this.serviceObject.selectedProduct;
            if (this._selectedProduct !== null) {
                // get selected bundle product data
                this._getBundleProductData();
            }
        }
    }

    public switchToConfiguration = () => {
        this.createStep = 'config';
    };

    public finishConfiguration = () => {
        this.createStep = 'summary';
    };

    public switchToSummary = () => {
        this.apiErrorModel.destroyErrorList();
        this.createStep = 'summary';
    };

    public createBundle = () => {
        this.apiOrderRequestError = false;
        this.createStep = 'ordering';
        const createData = ng.copy(this.serviceObject.settings.createData);
        const orchestratorObject = createData.bundle;

        if (!this._checkServiceOrchestratorObject('webspace')) {
            // webspace/orchestrator object is corrupted, through error
            return;
        }

        /**
         * Add vHost/domain to webspace
         */
        if (createData.domain !== null) {
            if (!this._checkServiceOrchestratorObject('domain')) {
                // domain/orchestrator object is corrupted, through error
                return;
            }
            if (typeof createData.domain.orchestratorObject.domainNameUnicode.toLowerCase === 'function') {
                createData.domain.orchestratorObject.domainNameUnicode =
                    createData.domain.orchestratorObject.domainNameUnicode.toLowerCase();
            }

            createData.webspace.orchestratorObject.items.push(createData.domain.orchestratorObject);
        } else {
            if (!this._checkServiceOrchestratorObject('vhost')) {
                // vhost/orchestrator object is corrupted, through error
                return;
            }
            if (typeof createData.vhost.orchestratorObject.domainNameUnicode.toLowerCase === 'function') {
                createData.vhost.orchestratorObject.domainNameUnicode =
                    createData.vhost.orchestratorObject.domainNameUnicode.toLowerCase();
            }
            createData.webspace.orchestratorObject.items.push(createData.vhost.orchestratorObject);
        }

        /**
         * Add webspace and webspace access
         */
        orchestratorObject.items.push(createData.webspace.orchestratorObject);

        if (createData.database.createDatabase) {
            if (!this._checkServiceOrchestratorObject('database')) {
                // database/orchestrator object is corrupted, through error
                return;
            }
            /**
            * Add database
            */
            createData.database.orchestratorObject.billingMode = 'bundle';
            for (let i = 1; i <= createData.database.capacity; i++) {

                orchestratorObject.items.push(createData.database.orchestratorObject);
            }
        }

        /**
         * Send order request
         */
        return this.orchestratorRobot.order(orchestratorObject, createData.accountId)
            .then(
                (res) => {
                    if (res.status !== 'error') {
                        this.jobOrderId = res.response;
                    } else {
                        // do something on error ...
                        this.apiOrderRequestError = true;
                    }
                },
                (err: any) => {
                    this.apiOrderRequestError = true;
                    return err;
                }
            ) as ng.IPromise<any>
        ;
    };

    private _checkServiceOrchestratorObject = (service: string) => {
        if ([undefined, null].indexOf(this.serviceObject.settings.createData[service]) >= 0
            || [undefined, null].indexOf(this.serviceObject.settings.createData[service].orchestratorObject) >= 0
        ) {
            this.apiOrderRequestError = true;
            return false;
        }
        return true;
    };

    private _getBundleProductData = () => {
        const filter = { field: 'BundleProductProductCode', value: this._selectedProduct.prices[0].productCode };
        this.bundleModel.productsList(
            filter,
            null,
            1,
            null,
            null,
            this.serviceObject.settings.createData.accountId
        ).then((bundleProduct) => {
            this._setContingents(bundleProduct.data[0]);
            this.bundleProductData = bundleProduct.data[0];
        });
    };

    private _setContingents = (contingentData: any) => {
        this.serviceObject.display.contingents = this.serviceObject.display.contingents.map((contingent: any) => {
            contingentData.contingents.some((data: any) => {
                if (data.productCodes[0].indexOf(contingent.codeStartsWith) === 0) {
                    contingent.productCodes = data.productCodes;
                    contingent.capacity = data.capacity;
                    return true;
                }

                return false;
            });

            return contingent;
        });
    };
}

export class OrganismCreateBundleComponent implements ng.IComponentOptions {
    public bindings = {
        serviceObject: '<'
    };
    public template = require('./bundle-create.html');
    public controller = OrganismCreateBundleController;
}
