import * as ng from 'angular';

/**
 * @deprecated use MoleculeButtonCopyComponent instead!
 */
export class MoleculeButtonTextCopyController implements ng.IController {
    public static $inject: string[] = ['$timeout'];

    public text = '';
    public helpText = '';
    private textCopied = false;

    constructor(
        private $timeout: ng.ITimeoutService
    ) {}

    public reactToCopy = ($event: ng.IAngularEvent) => {
        this.textCopied = true;

        $event.preventDefault();
        $event.stopPropagation();

        this.$timeout(
            () => {
                this.textCopied = false;
            }, 500
        );
    };

    public get additionalClass() {
        if (this.textCopied) {
            return 'button--blue';
        } else {
            return '';
        }
    }
}

export class MoleculeButtonTextCopyComponent implements ng.IComponentOptions {
    public controller =  MoleculeButtonTextCopyController;
    public transclude = true;

    public bindings = {
        helpText: '@',
        text: '@'
    };
    public template = require('./button-text-copy.html');
}
