import * as ng from 'angular';
import * as Types from '@/types';

import { ModelHelper } from '../model-helper';
import { RequestStatus} from '../rpc-client';
import { WebhostingRobotService } from './robot';
import { FunctionRelayService } from '../function-relay';
import { WebhostingApi } from '@/types';

export class WebspaceModelService {
    public static $inject: string[] = ['webhostingRobot', '$q', '$rootScope', 'functionRelay'];

    constructor(
        private webhostingRobot: WebhostingRobotService,
        private $q: ng.IQService,
        private $rootScope: ng.IRootScopeService,
        private functionRelay: FunctionRelayService
    ) {}

    public list = (limit?, page?, filters?, sort?) => {
        sort = sort || {
                field: 'webspaceName',
                order: 'ASC'
            };
        page = page || 1;

        return this.webhostingRobot.listWebspaces(filters, limit, page, sort)
            .then(ModelHelper.returnListResponse);
    };

    public listWithoutPagination = (limit?, page?, filters?, sort?) => {
        sort = sort || {
                field: 'webspaceName',
                order: 'ASC'
            };
        page = page || 1;

        return this.webhostingRobot.listWebspacesWithoutPagination(filters, limit, page, sort)
            .then(value => ModelHelper.returnListResponse<WebhostingApi.Webspace>(value));
    };

    public findOne = (id: string) => {
        return this.webhostingRobot.listWebspacesWithoutPagination({field: 'webspaceId', value: id}, 1, 1)
            .then(ModelHelper.returnFindOneResponse) as Promise<WebhostingApi.Webspace>;
    };

    public findById = (ids: string[]) => {
        const filter = {subFilterConnective: 'OR', subFilter: ids.map(this.idFilter)};
        return this.webhostingRobot.listWebspacesWithoutPagination(filter, 0, 1)
            .then(ModelHelper.returnFindResponse);
    };

    public findByBundleId = (bundleId: string) => {
        const filter = { field: 'bundleId', value: bundleId };
        return this.webhostingRobot.listWebspacesWithoutPagination(filter, 0, 1)
            .then(ModelHelper.returnFindOneResponse);
    };

    public findByUserId = (userId: string) => {
        return this.webhostingRobot.listWebspacesWithoutPagination(
            {field: 'webspaceAccessesUserId', value: userId}, 10, 1)
            .then(ModelHelper.returnListResponse);
    };

    public findOneByBundleId = (bundleId: string) => {
        return this.webhostingRobot.listWebspacesWithoutPagination(
            {field: 'bundleId', value: bundleId}, 1, 1)
            .then((webspace) => {
                if (webspace.response.data.length >= 1) {
                    return webspace.response.data[0];
                }
                return null;
            });
    };

    public create = (webspace, accesses, owner, poolId?, resourceId?) => {
        return this.webhostingRobot.createWebspace(webspace, accesses, owner, poolId, resourceId)
            .then(this.returnResponse)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED))
            .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
    };

    public update = (webspace, expectedCost: boolean|number = false) => {
        if (expectedCost !== false) {
            return this.webhostingRobot.updateWebspace(webspace, webspace.accesses, expectedCost)
                .then(this.refresh(), this.refresh(RequestStatus.FAILED))
                .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
        }

        return this.webhostingRobot.updateWebspace(webspace, webspace.accesses)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED))
            .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
    };

    public setMinPhpVersion = (webspaceId: string, phpVersion: string) => {
        return this.webhostingRobot.setMinPhpVersion(webspaceId, phpVersion)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED))
            .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
    };

    public cancelDelete: (webspaces: Types.WebhostingApi.Webspace[]) => Promise<any>
    = (webspaces) => {
        const promises = webspaces.map(this.cancelDeleteOnce);

        return Promise.all(promises);
    };

    public cancelDeleteOnce = (webspace: Types.WebhostingApi.Webspace): Promise<any> => {
        return this.webhostingRobot.cancelDeleteWebspace(webspace.id);
    };

    public deleteOne = (webspaceId: string, execDate: Date): Promise<any> => {
        if (execDate !== undefined && execDate !== null) {
            execDate.setUTCHours(0, 0, 0, 0);
        }
        return this.webhostingRobot.deleteWebspace(webspaceId, execDate)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public purgeRestorable = (webspaceId) => {
        return this.webhostingRobot.purgeRestorable(webspaceId)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public restore = (webspaces) => {
        const promises = webspaces.map(this.restoreOne);
        return this.$q.all(promises)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public disableWebspace = (webspace, accountId, comment) => {
        const alreadyDisabled = webspace.restrictions.some(
            (restriction) => {
                return restriction.type === 'disabled';
            }
        );
        if (!alreadyDisabled) {
            const restriction = {
                accountId: accountId,
                type: 'disabled',
                internalComment: comment
            };
            return this.webhostingRobot.disableWebspace(webspace, webspace.id, restriction);
        }
    };

    public webspaceUpdateRestriction = (webspaceId, restriction) => {
        return this.webhostingRobot.webspaceUpdateRestriction(webspaceId, restriction);
    };

    public enableWebspace = (webspace) => {
        const restrictions = webspace.restrictions.filter((restriction) => restriction.type === 'disabled');
        if (restrictions.length > 0) {
            return this.webhostingRobot.enableWebspace(webspace.id, restrictions[0].id);
        }
    };

    public emailSenderActivate = (webspaceId) => {
        return this.webhostingRobot.emailSenderActivate(webspaceId);
    };

    public emailSenderDisable = (webspaceId) => {
        return this.webhostingRobot.emailSenderDisable(webspaceId);
    };

    public emailSenderDefer = (webspaceId) => {
        return this.webhostingRobot.emailSenderDefer(webspaceId);
    };

    private refresh = (status: RequestStatus = RequestStatus.SUCCESSFUL) => (result) => {
        this.$rootScope.$broadcast('webhosting.webspaces.refresh');
        if (status === RequestStatus.SUCCESSFUL) {
            return result;
        } else {
            return this.$q.reject(result);
        }
    };

    private refreshDeposit = (status: RequestStatus = RequestStatus.SUCCESSFUL) => (result) => {
        this.functionRelay.call('updateDeposit', {});
        if (status === RequestStatus.SUCCESSFUL) {
            return result;
        } else {
            return this.$q.reject(result);
        }
    };

    private idFilter = (id) => {
        return {field: 'webspaceId', value: id};
    };

    private returnResponse = (result) => {
        return result.response;
    };

    private restoreOne = (webspace) => {
        return this.webhostingRobot.restoreWebspace(webspace.id);
    };
}
