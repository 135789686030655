/* eslint-disable @typescript-eslint/no-explicit-any */
import * as ng from 'angular';
import {
    FormDropDownItems
} from '@/atomic-components';
import { WebspaceAccessPasswordCharacterPoolsConst } from '@/configuration';
import { AuthContextService } from '@/services';

export class MoleculePanelCreateUserAccessController implements ng.IController {
    public static $inject: string[] = ['$scope', '$translate'];

    public accessLevels: Record<string, any>;
    public disabledAccessLevels: any[] = [];

    public availableAccesses: any[];
    public passwordValidationOptions = {
        characterPools: WebspaceAccessPasswordCharacterPoolsConst,
        maxLength: 128,
        minCharacterPoolsUsed: 3
    };

    public homedirPaths: FormDropDownItems[] = [
        { value: '/html/', name: '/html/' },
        { value: '/data/', name: '/data/' }
    ];

    public accountId: string;
    public homeDir: string;
    public comment: string;
    public sshKey: string;

    constructor(
        private $scope: ng.IScope,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = (): void => {

        this.accessLevels = {
            ftpAccess: { label: this.$translate.instant('TR_100119-ca0b1e_TR'), value: false, disabled: false },
            sshAccess: { label: this.$translate.instant('TR_100119-fecdf6_TR'), value: false, disabled: false },
            statsAccess: { label: this.$translate.instant('TR_100119-77f707_TR'), value: false, disabled: false },
            ftpLimited: { label: this.$translate.instant('TR_100119-54245d_TR'), value: false, disabled: false }
        };

        this.accountId = this.accountId || AuthContextService.account.id;

        this.$scope.$watch(() => this.accessLevels.ftpAccess.value,     this._watchFtpAccess);
        this.$scope.$watch(() => this.accessLevels.ftpLimited.value,    this._watchFtpLimited);
        this.$scope.$watch(() => this.accessLevels.sshAccess.value,     this._watchSshAccess);
        this.$scope.$watch(() => this.accessLevels.statsAccess.value,   this._watchStatsAccess);
    };

    private _watchFtpAccess = (newValue: any, oldValue: any): void => {
        if (newValue === oldValue) {
            return;
        }
        this.accessLevels.ftpAccess.value = newValue;
        if (newValue) {
            this.accessLevels.ftpLimited.value = false;
            this.accessLevels.ftpLimited.disabled = true;
        } else {
            this.accessLevels.ftpLimited.disabled = false;
        }
    };

    private _watchSshAccess = (newValue: any, oldValue: any): void => {
        if (newValue === oldValue) {
            return;
        }
        this.accessLevels.sshAccess.value = newValue;
        if (newValue) {
            this.accessLevels.ftpLimited.value = false;
            this.accessLevels.ftpLimited.disabled = true;
        } else {
            this.accessLevels.ftpLimited.disabled = false;
        }
    };

    private _watchStatsAccess = (newValue: any, oldValue: any): void => {
        if (newValue === oldValue) {
            return;
        }
    };

    private _watchFtpLimited = (newValue: any, oldValue: any): void => {
        if (newValue === oldValue) {
            return;
        }
        this.accessLevels.ftpLimited.value = newValue;
        if (newValue) {
            this.accessLevels.ftpAccess.value = false;
            this.accessLevels.ftpAccess.disabled = true;
            this.accessLevels.sshAccess.value = false;
            this.accessLevels.sshAccess.disabled = true;
        } else {
            this.accessLevels.ftpAccess.disabled = false;
            this.accessLevels.sshAccess.disabled = false;
        }
    };
}

export class MoleculePanelCreateUserAccessComponent implements ng.IComponentOptions {
    public bindings = {
        homeDir: '=',
        comment: '=',
        sshKey: '=',
        accessLevels: '='
    };
    public template = require('./panel-create-user-access.html');
    public controller = MoleculePanelCreateUserAccessController;
}
