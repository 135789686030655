import { AccountFilterField, ContactFilterField, GroupedSelectFilterField, SelectFilterField, TextFilterField } from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { JobOverviewModel, OverviewModel } from '../abstract';
import { RequestCancellerService } from '../request-canceller';

export class MachineJobOverviewModel extends JobOverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['machineJobModel', '$translate']
    );

    readonly service = 'machineJob';

    protected listCallbacks = [{callback: this.machineJobModel.list}];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private machineJobModel: any,
        protected $translate: ng.translate.ITranslateService
    ) {
        super(RequestCanceller);
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('jobDisplayName', this.$translate.instant('TR_140119-796b28_TR')),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR')),
                new SelectFilterField(
                    'jobAction',
                    this.$translate.instant('TR_140119-3b64a8_TR'),
                    [{
                        name: this.$translate.instant('TR_140119-50d91c_TR'),
                        value: 'create'
                    }, {
                        name: this.$translate.instant('TR_140119-38c852_TR'),
                        value: 'install'
                    }, {
                        name: this.$translate.instant('TR_140119-a1847d_TR'),
                        value: 'update'
                    }, {
                        name: this.$translate.instant('GENERAL.JOB.ACTION.RESET'),
                        value: 'reset'
                    }, {
                        name: this.$translate.instant('TR_140119-aee71e_TR'),
                        value: 'delete'
                    }, {
                        name: this.$translate.instant('TR_140119-20aa42_TR'),
                        value: 'purgeRestorable'
                    }, {
                        name: this.$translate.instant('TR_140119-e815cb_TR'),
                        value: 'powerOff'
                    }, {
                        name: this.$translate.instant('TR_140119-23d089_TR'),
                        value: 'shutdown'
                    }, {
                        name: this.$translate.instant('TR_140119-af4f0a_TR'),
                        value: 'enableRescue'
                    }, {
                        name: this.$translate.instant('TR_140119-c03d41_TR'),
                        value: 'disableRescue'
                    }]),
                new SelectFilterField(
                    'jobStatus',
                    this.$translate.instant('TR_140119-8e4b60_TR'),
                    [{
                        name: this.$translate.instant('TR_140119-888f29_TR'),
                        value: 'timed'
                    }, {
                        name: this.$translate.instant('TR_140119-601a80_TR'),
                        value: 'new'
                    }, {
                        name: this.$translate.instant('TR_140119-d5047f_TR'),
                        value: 'inProgress'
                    }, {
                        name: this.$translate.instant('TR_140119-8bd7bf_TR'),
                        value: 'successful'
                    }, {
                        name: this.$translate.instant('TR_140119-8399ef_TR'),
                        value: 'failed'
                    }, {
                        name: this.$translate.instant('TR_140119-7acde1_TR'),
                        value: 'support'
                    }, {
                        name: this.$translate.instant('TR_140119-a235c9_TR'),
                        value: 'canceled'
                    }, {
                        name: this.$translate.instant('TR_140119-5b87d8_TR'),
                        value: 'done'
                    }]
                )
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }
}
