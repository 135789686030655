import * as Types from '../../types';

import {
    RpcClient,
    RpcClientService
} from '../rpc-client';

export const ProductsClientService = [
    'rpcClient',
    (platformRpcClient: RpcClientService): RpcClient => platformRpcClient.Factory('/api/product/v1/json/', 'product')
];

export class ProductsRobotService {
    public static $inject: string[] = ['productsClient'];

    constructor(
        private productsClient: RpcClient
    ) {}

    public keywordCategoryDescriptionsFind(
        categories: string[],
        language?: string
    ): PromiseLike<Types.UI.APIResponse<Types.ProductApi.FactSheetKeywordCategoryDescription[]>> {
        return this.productsClient.request('keywordCategoryDescriptionsFind')
            .useCache()
            .cacheTimeout(1000 * 60 * 15)
            .skipPagination()
            .execute(
                {
                    categories: categories,
                    language: language
                }
            ) as PromiseLike<Types.UI.APIResponse<Types.ProductApi.FactSheetKeywordCategoryDescription[]>>;
    }

    public keywordDescriptionsFind(
        keywords: Types.ProductApi.FactSheetKeyword[],
        language?: string
    ): PromiseLike<Types.UI.APIResponses<Types.ProductApi.FactSheetKeywordDescription>> {
        return this.productsClient.request('keywordDescriptionsFind')
            .useCache()
            .cacheTimeout(1000 * 60 * 15)
            .skipPagination()
            .execute(
                {
                    keywords: keywords,
                    language: language
                }
            ) as PromiseLike<Types.UI.APIResponses<Types.ProductApi.FactSheetKeywordDescription>>;
    }

    public productFind(
        productCode: string,
        language?: string,
        accountId?: string
    ): PromiseLike<Types.UI.APISingleResponse<Types.ProductApi.Product | Types.ProductApi.TemplateProduct>> {
        return this.productsClient.request('productFind')
            .useCache()
            .cacheTimeout(1000 * 60 * 15)
            .skipPagination()
            .execute(
                {
                    productCode: productCode,
                    language: language,
                    accountId: accountId
                }
            ) as PromiseLike<Types.UI.APISingleResponse<Types.ProductApi.Product | Types.ProductApi.TemplateProduct>>;
    }

    public productsFind(
        service: string,
        productFamily: string,
        language?: string,
        withRelatedProducts?: boolean
    ): PromiseLike<Types.UI.APIResponse<Types.ProductApi.ProductsFindResult>> {
        return this.productsClient.request('productsFind')
            .useCache()
            .cacheTimeout(1000 * 60 * 15)
            .skipPagination()
            .execute(
                {
                    service: service,
                    productFamily: productFamily,
                    language: language,
                    withRelatedProducts: withRelatedProducts
                }
            ) as PromiseLike<Types.UI.APIResponse<Types.ProductApi.ProductsFindResult>>;
    }

    public productFamilyDescriptionFind(
        service: string,
        productFamily: string,
        language?: string
    ): PromiseLike<Types.UI.APIResponse<Types.ProductApi.ProductFamilyDescription>> {
        return this.productsClient.request('productFamilyDescriptionFind')
            .useCache()
            .cacheTimeout(1000 * 60 * 15)
            .skipPagination()
            .execute(
                {
                    service: service,
                    productFamily: productFamily,
                    language: language
                }
            ) as PromiseLike<Types.UI.APIResponse<Types.ProductApi.ProductFamilyDescription>>;
    }
}
