import * as ng from 'angular';
import { LinkListItem } from '../../links/link-list/link-list';

export interface AdditionalActionDataObject {
    linkList: LinkListItem[] | {
        left: LinkListItem[];
        right?: LinkListItem[]; // right link list is optional
    };
}

export class MoleculePanelAdditionalActionsController implements ng.IController {
    public static $inject: string[] = [];

    public depLinkListLeft: LinkListItem[];
    public depLinkListRight: LinkListItem[];

    public linkList: LinkListItem[];

    public $onInit() {
        this.depLinkListLeft = this.depLinkListLeft || [];
        this.depLinkListRight = this.depLinkListRight || [];

        if (this.linkList === undefined) {
            this.linkList = this.depLinkListLeft.concat(this.depLinkListRight);
        }
    }
}

export class MoleculePanelAdditionalActionsComponent implements ng.IComponentOptions {
    public bindings = {
        depLinkListLeft: '<linkListLeft', // deprectad
        depLinkListRight: '<linkListRight', // deprectad
        linkList: '<'
    };
    public controller =  MoleculePanelAdditionalActionsController;
    public template = require('./panel-additional-actions.html');
}
