export * from './custom/dashboard-widget-account-balance-model';
export * from './custom/dashboard-widget-account-balance';
export * from './custom/dashboard-widget-additional-links-model';
export * from './custom/dashboard-widget-additional-links';
export * from './custom/dashboard-widget-domain-search-model';
export * from './custom/dashboard-widget-domain-search';
export * from './custom/dashboard-widget-empty-hint-model';
export * from './custom/dashboard-widget-empty-hint';
export * from './custom/dashboard-widget-faq-nextcloud-model';
export * from './custom/dashboard-widget-faq-nextcloud';
export * from './custom/dashboard-widget-overview-contact-verification-model';
export * from './custom/dashboard-widget-overview-contact-verification';
export * from './custom/dashboard-widget-overview-domain-transfers-out-model';
export * from './custom/dashboard-widget-overview-domain-transfers-out';
export * from './custom/dashboard-widget-product-bundle-model';
export * from './custom/dashboard-widget-product-bundle';
export * from './custom/dashboard-widget-product-nextcloud-model';
export * from './custom/dashboard-widget-product-nextcloud';
export * from './custom/dashboard-widget-product-webspace-model';
export * from './custom/dashboard-widget-product-webspace';
export * from './custom/dashboard-widget-support-model';
export * from './custom/dashboard-widget-support';
export * from './custom/dashboard-widget-welcome-model';
export * from './custom/dashboard-widget-welcome';
export * from './system/dashbaord-widget-sepa-verification-notifier-model';
export * from './system/dashbaord-widget-sepa-verification-notifier';
export * from './system/dashboard-widget-avv-privacy-notifier-model';
export * from './system/dashboard-widget-avv-privacy-notifier';
export * from './system/dashboard-widget-faq';
export * from './system/dashboard-widget-incomplete-user-profile-model';
export * from './system/dashboard-widget-incomplete-user-profile';
export * from './system/dashboard-widget-sepa-info-model';
export * from './system/dashboard-widget-sepa-info';
export * from './system/dashboard-widget-sms-validation-model';
export * from './system/dashboard-widget-sms-validation';
export * from './dashboard-widget-model';
export * from './dashboard-widget-model-operator';
