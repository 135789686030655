/* eslint-disable */

import * as ng from 'angular';
import { WizardHelperService } from '@/atomic-components/molecules/wizard/wizard-helper';
import {
    ContractPeriodeToBillingCycleConst,
    CycleBillingTranslationConst,
    ProductCodetemplatePlaceholder,
    ProductContingentsObject,
    UiLanguagesConst,
    UiRights
} from '@/configuration';
import {
    ApiErrorModel,
    AuthContextService,
    ProductsModelService,
    BillingHelperService,
    BundleModelService,
    ResourceModelService
} from '@/services';
import { ProductBoxObject } from '@/services/products/';
import * as Types from '@/types';

export interface ProductContingentsObject {
    object: Types.BundleApi.Bundle | Types.ResourceApi.Pool;
    productCode: string;
    type: string;
}

class ProductSpecificationItemWrapper {
    constructor(private specificationItem: Types.ProductApi.SpecificationItem) {}

    public get value(): unknown {
        return this.specificationItem.value;
    }

    public get isTrue(): boolean {
        return this.specificationItem.value === true;
    }

    public get isFalse(): boolean {
        return this.specificationItem.value === false;
    }

    public get intValue(): number {
        if (typeof this.specificationItem.value === 'number') {
            return this.specificationItem.value;
        } else {
            throw new Error('Value is not a number.');
        }
    }
}

export class ProductHelperService {
    public static $inject: string[] = [
        '$state',
        'apiErrorModel',
        'billingHelper',
        'bundleModel',
        'productsModel',
        'resourceModel',
        'wizardHelper'
    ];

    public static wrapSpecificationItems(
        specificationItems: Types.ProductApi.SpecificationItem[]
    ): { [key: string]: ProductSpecificationItemWrapper } {
        const wrappedItems: { [key: string]: ProductSpecificationItemWrapper } = {};

        if (Array.isArray(specificationItems)) {
            for (const item of specificationItems) {
                wrappedItems[item.name] = new ProductSpecificationItemWrapper(item);
            }
        }

        return wrappedItems;
    }

    constructor(
        private $state: ng.ui.IStateService,
        private apiErrorModel: ApiErrorModel,
        private billingHelper: BillingHelperService,
        private bundleModel: BundleModelService,
        private productsModel: ProductsModelService,
        private resourceModel: ResourceModelService,
        private wizardHelper: WizardHelperService
    ) {}

    public getContingentObjectId = (productCode: string, accountId?: string): PromiseLike<string | null> => {
        if (!this._contingentsPossibleInState()) {
            return null;
        }

        return this.productRegisterOnProductCode(productCode, accountId).then(
            (contingentObjects) => {
                if (Array.isArray(contingentObjects)) {
                    if (contingentObjects.length === 1) {
                        return contingentObjects[0].object.id;
                    } else if (contingentObjects.length > 1) {
                        let contingent: string;

                        const routeParts = this.$state.current.name.split('.');
                        for (const contingentObject of contingentObjects) {
                            if (routeParts[0] === 'bundle' && contingentObject.type === 'bundle') {
                                // Orders within a bundle
                                contingent = contingentObject.object.id;
                                break;
                            } else if (contingentObject.type === 'machine') {
                                // Orders in the product area: pool contingent should be preferred
                                contingent = contingentObject.object.id;
                                break;
                            }
                        }

                        return contingent === undefined
                            // Orders in the product area: if there is no pool contingent, take first bundle
                            ? contingentObjects[0].object.id
                            : contingent;
                    }
                }

                return null;
            }
        );
    };

    public productRegisterOnProductCode = (
        productCode: string,
        accountId?: string
    ): PromiseLike<ProductContingentsObject[] | null> => {
        const promises: PromiseLike<unknown>[] = [];
        accountId = accountId || AuthContextService.account.id;

        if ([undefined, null, ''].indexOf(productCode) >= 0) {
            return Promise.resolve(null);
        }

        if (AuthContextService.isGranted(UiRights.BIL_BUNDLE_LIST)) {
            promises.push(this.getBundleContingentsBasedOnProductCode(productCode, accountId));
        }

        promises.push(this.getPoolContingentBasedOnProductCode(productCode, accountId));

        return Promise.all(promises).then(
            (res) => {
                const returnContingentObject: ProductContingentsObject[] = [];

                res.map((contingentObject: Types.BundleApi.Bundle | Types.ResourceApi.Pool, index: number) => {
                    if (contingentObject !== null) {
                        switch (index) {
                            case 0: // Bundle Service
                                returnContingentObject.push({
                                    object: contingentObject,
                                    productCode: productCode,
                                    type: 'bundle'
                                } as ProductContingentsObject);
                                break;

                            case 1: // Machine Service
                                returnContingentObject.push({
                                    object: contingentObject,
                                    productCode: productCode,
                                    type: 'machine'
                                } as ProductContingentsObject);
                                break;

                            default:
                                // nothing to do here
                        }
                    }
                });

                return returnContingentObject.length > 0
                    ? returnContingentObject
                    : null;
            }
        );
    };

    public getBundleContingentsBasedOnProductCode = (
        productCode: string,
        accountId: string
    ): PromiseLike<Types.BundleApi.BundleContingentUsage> => {
        if (!this._contingentsPossibleInState()) {
            return null;
        }

        return this.bundleModel.listWithoutPagination(500, 1, { field: 'accountId', value: accountId}).then(
            (bundleResponse) => {
                let bundleWithContingent: Types.BundleApi.Bundle = null;

                for (const bundle of bundleResponse.data) {
                    bundleWithContingent = this.checkBundleOfPossibleContingent(bundle, productCode);

                    if (bundleWithContingent !== null) {
                        break;
                    }
                }

                return bundleWithContingent;
            }
        ) as PromiseLike<Types.BundleApi.BundleContingentUsage>;
    };

    public getPoolContingentBasedOnProductCode = (
        productCode: string,
        accountId: string
    ): PromiseLike<Types.ResourceApi.Pool> => {
        if (!this._contingentsPossibleInState()) {
            return null;
        }

        return this.wizardHelper.inPool(undefined, accountId, productCode).then(
            (pools: Types.ResourceApi.Pool[]) => pools.length > 0
                ? pools[0]
                : null
        ) as PromiseLike<Types.ResourceApi.Pool>;
    };

    public checkBundleOfPossibleContingent = (
        bundle: Types.BundleApi.Bundle,
        productCode: string
    ): Types.BundleApi.Bundle => {
        if (!this._contingentsPossibleInState()) {
            return null;
        }

        let bundleWithContingent: Types.BundleApi.Bundle = null;

        if (this._checkBundleHasAvailableContingent(bundle, productCode)) {
            bundleWithContingent = bundle;
        }

        return bundleWithContingent;
    };

    public getContingentByObjectId = (
        objectId: string,
        productCode: string
    ): PromiseLike<Types.BundleApi.Bundle | Types.ResourceApi.Pool> => {
        const promises = [];

        promises.push(this.bundleModel.findOne(objectId, true));
        promises.push(this.resourceModel.findOne('Pool', objectId, 1));

        return Promise.all(promises).then(
            (contingentObjects: (Types.BundleApi.Bundle | Types.ResourceApi.Pool)[]) => {
                let objectHasAvailableContingentForProductCode = false;
                let contingentFound: Types.BundleApi.Bundle | Types.ResourceApi.Pool = null;

                contingentObjects.some((
                    cObject: Types.BundleApi.Bundle | Types.ResourceApi.Pool,
                    index: number
                ) => {
                    if (
                        (
                            [undefined, null].indexOf(cObject) < 0
                            && !Array.isArray(cObject)
                        )
                        || (
                            Array.isArray(cObject)
                            && cObject.length > 0
                        )
                    ) {
                        switch (index) {
                            case 0: // bundleObject
                                objectHasAvailableContingentForProductCode = this._checkBundleHasAvailableContingent(
                                    cObject as Types.BundleApi.Bundle,
                                    productCode
                                );
                                break;

                            case 1: // PoolObject
                                objectHasAvailableContingentForProductCode = this._checkPoolHasAvailableContingent(
                                    cObject as Types.ResourceApi.Pool,
                                    productCode
                                );
                                break;
                        }
                    }

                    if (objectHasAvailableContingentForProductCode) {
                        contingentFound = cObject;
                        return true;
                    }

                    return false;
                });

                return contingentFound;
            }
        ) as PromiseLike<Types.BundleApi.Bundle | Types.ResourceApi.Pool>;
    };

    public getBillingCycleFromNumber = (
        cycle: keyof typeof ContractPeriodeToBillingCycleConst
    ): string | null => {
        return [1, 3, 6, 12, 24].indexOf(cycle) >= 0
            ? this.getReadableBillingCycleMonthsFromWording(ContractPeriodeToBillingCycleConst[cycle])
            : null;
    };

    public getReadableBillingCycleMonthsFromWording = (
        cycleWord: keyof typeof CycleBillingTranslationConst
    ): string | null => {
        return typeof cycleWord === 'string'
            ? CycleBillingTranslationConst[cycleWord]
            : null;
    };

    public getProductCodeFromProductBoxObject = (
        productBoxObject: ProductBoxObject,
        billingCycle?: number | string
    ): string => {
        if (productBoxObject !== null && 'billingCycles' in productBoxObject) {
            if (productBoxObject.productCode !== null) {
                return productBoxObject.productCode;
            } else if (productBoxObject.billingCycles !== null) {
                billingCycle = billingCycle
                    || this.billingHelper.sortBillingCyclesAsc(productBoxObject.billingCycles)[0];
                if (billingCycle !== undefined) {
                    if (typeof billingCycle !== 'string') {
                        billingCycle = billingCycle.toString();
                    }
                    return productBoxObject.productCodeTemplate.replace(
                        ProductCodetemplatePlaceholder,
                        billingCycle
                    );
                }
            }
        }

        return null;
    };

    public getProductFromProductCode = (
        productCodes: string[] | string,
        replaceTemplatePlaceholders?: boolean
    ): PromiseLike<Types.ProductApi.Product | Types.ProductApi.Product[]> => {
        replaceTemplatePlaceholders = replaceTemplatePlaceholders || true;
        productCodes = Array.isArray(productCodes) ? productCodes : [productCodes];

        const promises = [];

        for (let i = 1; i <= productCodes.length; i++)
        {
            promises.push(
                this.productsModel.findOne(
                    productCodes[(i - 1)],
                    UiLanguagesConst[AuthContextService.user.language],
                    replaceTemplatePlaceholders
                )
            ); // domain product code
        }

        return Promise.all(promises).then(
            (promiseResolves) => {
                const results = promiseResolves.map(
                    (resolve: Types.UI.APIResponse<Types.ProductApi.Product>) => {
                        if (this.apiErrorModel.apiResponseHasError(resolve)) {
                            return {
                                addGracePeriod: 0,
                                billingCycle: 1,
                                description: '',
                                factSheetItems: [],
                                family: '',
                                featured: false,
                                language: UiLanguagesConst[AuthContextService.user.language],
                                name: '',
                                productCode: productCodes,
                                productCodeTemplate: productCodes,
                                productType: '',
                                requiredCreationRight: '',
                                restoreGracePeriod: null,
                                shortDescription: '',
                                specificationItems: [],
                                type: 'Product',
                                verificationAlternatives: []
                            } as Types.ProductApi.Product;
                        } else {
                            return resolve.response;
                        }
                    }
                );

                if (results.length === 1) {
                    return results[0];
                }

                return results;
            }
        );
    };

    public formatAdditionalProductCodes = (additionalCodes: string[], billingCycles: number | number[]): string[]  => {
        let formattedAdditionalCodes: string[] = [];
        additionalCodes.forEach(additionalCode => {
            // TODO
            // might need more testing?

            let billingCycle: number;
            if (
                Array.isArray(billingCycles) &&
                billingCycles.length > 0
            ) {
                billingCycle = this.billingHelper.sortBillingCyclesAsc(billingCycles)[0];
            } else if (typeof billingCycles === 'number') {
                billingCycle = billingCycles;
            }

            if ([undefined, null].indexOf(billingCycle) < 0) {
                formattedAdditionalCodes.push(additionalCode.replace(
                    ProductCodetemplatePlaceholder,
                    billingCycle + ''
                ));
            }
        })
        return formattedAdditionalCodes;
    };

    private _contingentsPossibleInState = (): boolean => {
        const routeParts = this.$state.current.name.split('.');
        return ProductContingentsObject.serviceList.indexOf(routeParts[0].toLowerCase()) >= 0;
    };

    private _checkPoolHasAvailableContingent = (pool: Types.ResourceApi.Pool, productCode: string): boolean => {
        return this.wizardHelper.poolHasContingents(pool, productCode);
    };

    private _checkBundleHasAvailableContingent = (bundle: Types.BundleApi.Bundle, productCode: string): boolean => {
        return bundle.effectiveContingentUsage.some(
            (bundleContingent) => {
                const foundContigent = bundleContingent.productCodes.some(
                    (bundleProductCode) => {
                        return bundleProductCode === productCode;
                    }
                );

                return foundContigent && bundleContingent.availableCapacity > 0;
            }
        );
    };
}
