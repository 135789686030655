import * as ng from 'angular';
import { NavigationService } from '../../../../../services';

import './records-expert.scss';

export class OrganViewPanelRecordsExpertController {
    public static $inject: string[] = ['navigation'];
    public records: any;
    public zone: any;

    constructor(
        private navigation: NavigationService
    ) {}

    public gotToRecordsEdit = () => {
        this.navigation.go('dns.zones.id.records-edit');
    };
}

export class OrganViewPanelRecordsExpertComponent implements ng.IComponentOptions {
    public bindings = {
        records: '<',
        zone: '<'
    };
    public controller = OrganViewPanelRecordsExpertController;
    public template = require('./records-expert.html');
}
