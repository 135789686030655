import * as ng from 'angular';

export class AtomTextEditController implements ng.IController {
    public static $inject: string[] = [];

    public modifier: string;

    $onInit() {
        this.modifier = this.modifier || '';
    }
}

export class AtomTextEditComponent implements ng.IComponentOptions {
    public bindings = {
        modifier: '@?'
    };
    public controller =  AtomTextEditController;
    public template = require('./text-edit.html');
}
