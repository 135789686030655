import * as ng from 'angular';
import { WizardServiceHelperDomain } from '../../../wizard/wizard-service-object-container/service-helpers';

export class MoleculePanelEditRowNameserverSelectController implements ng.IController {
    public static $inject: string[] = ['$timeout', 'wizardServiceHelperDomain', '$translate'];

    public selectedNameservers: any;
    public ownNameservers: any[];
    public nameservers: any[];
    public defaultNameservers: any;
    public dropdownItems: any[];
    public domainNames: string[];
    public hasGlueRecord = false;

    private _enforceOwnNameservers = false;

    constructor(
        private $timeout: ng.ITimeoutService,
        private wizardServiceHelperDomain: WizardServiceHelperDomain,
        protected $translate: ng.translate.ITranslateService
    ) {}

    $onInit() {
        this.hasGlueRecord = this.hasGlueRecord || false;
        this.createDropdownItems();
    }

    public get enforceOwnNameservers() {
        return this._enforceOwnNameservers;
    }

    public set enforceOwnNameservers(value) {
        if (!this._enforceOwnNameservers && value) {
            this.$timeout(() => this.selectedNameservers = null);
        }

        this._enforceOwnNameservers = value;
    }

    public onNameserverOptionChange = (selection) => {
        this.$timeout(() => {
            if ([undefined, null].indexOf(this.selectedNameservers) >= 0) {
                this.hasGlueRecord = false;
            } else {
                if (
                    this.wizardServiceHelperDomain.checkDomainHasGlueRecord(
                        this.selectedNameservers,
                        [{ domainName: this.domainNames[0] }]
                    )
                ) {
                    this.hasGlueRecord = true;
                } else {
                    this.hasGlueRecord = false;
                }
            }

            return this.hasGlueRecord;
        });
    };

    private createDropdownItems = () => {
        this.dropdownItems = [];
        const title = this.defaultNameservers.nameservers.join(' ');

        this.dropdownItems.push({
            title: this.$translate.instant('TR_110119-053f7e_TR'),
            items: [{
                name: title,
                value: this.defaultNameservers.nameservers
            }]
        });

        const ownNameservers = {
            title: this.$translate.instant('TR_100119-ff3f23_TR'),
            items: []
        };

        ownNameservers.items = this.nameservers.map(
            server => {
                return {
                    name: server.name,
                    value: server.nameservers
                };
            }
        );

        if (ownNameservers.items.length > 0) {
            this.dropdownItems.push(ownNameservers);
        }

        this.dropdownItems.push({
            title: this.$translate.instant('TR_100119-7afa7b_TR'),
            items: [{
                name: this.$translate.instant('TR_100119-3d31fe_TR'),
                value: null
            }]
        });
    };
}

export class MoleculePanelEditRowNameserverSelectComponent implements ng.IComponentOptions {
    public bindings = {
        defaultNameservers: '<',
        domainNames: '<',
        enforceOwnNameservers: '<?',
        nameservers: '<',
        ownNameservers: '=',
        selectedNameservers: '='
    };
    public controller = MoleculePanelEditRowNameserverSelectController;
    public template = require('./panel-edit-row-nameserver-select.html');
}
