import ng from 'angular';

import { UiRights } from '@/configuration';
import {
    ApiObject,
    AuthContextService,
    BundleHelperService,
    DomainHelperService,
    MailboxModelService
} from '@/services';
import { LinkListItem } from '@/atomic-components/molecules';

export interface BundleDashboardDataObject {
    linkList: {
        top: LinkListItem[];
        Footer: LinkListItem[];
    };
}

export class MoleculePanelBundleDashboardController implements ng.IController {
    public static $inject: string[] = [
        'authContext',
        'bundleHelper',
        'domainHelper',
        'mailboxModel',
        '$translate'
    ];

    public bundleItem;
    public domainList: any[] = [];
    public loadingDomainList = true;
    public linkListDomains: LinkListItem[] = [];
    public linkListFooter: LinkListItem[] = [];
    public contractActiveButCanceled = false;

    constructor(
        private authContext: AuthContextService,
        private bundleHelper: BundleHelperService,
        private domainHelper: DomainHelperService,
        private mailboxModel: MailboxModelService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.linkListDomains = this.linkListDomains || [];
        let hasMailboxes = false;

        this.mailboxModel.listWithoutPagination(1, 1, { field: 'BundleId', value: this.bundleItem.id })
        .then(
            (result) => hasMailboxes = result.data.length > 0
        )
        .then(
            () => {
                const bundleHandler = ApiObject.bundle(this.bundleItem);
                const returnData = {
                    data: []
                };

                if (!bundleHandler.hasWebspaceContingent || !this.authContext.isGranted(UiRights.WEB_OBJECT_LIST)) {
                    return this.domainHelper.stdOverviewOnLoadMethod(returnData, this.bundleItem.id, undefined, true);
                }
                return this.bundleHelper.getBundleVhosts(this.bundleItem);
            }
        )
        .then(
            (result: any) => {
                if (result.data !== undefined) {
                    this.linkListDomains = result.data
                    .filter(
                        (domain: any, index: number, array: any[]) => !array.some(
                            (otherDomain: any, otherIndex: number) => otherIndex !== index
                                && otherDomain.domainNameUnicode.indexOf(domain.domainNameUnicode) > 0
                        )
                    )
                    .map(
                        (domain: any) => {
                            if ([undefined, null].indexOf(domain.id) >= 0) {
                                return {
                                    route: 'bundle.id.domains.new',
                                    routeParams: {
                                        bundleId: this.bundleItem.id,
                                        domainSearchString: domain.domainNameUnicode
                                    },
                                    text: this.$translate.instant('70787000-7f0a-421d-9e1b-5ffe804172f0')
                                        + ': '
                                        + domain.domainNameUnicode
                                };
                            }
                            if ([undefined, null].indexOf(domain.vHostId) < 0) {
                                return {
                                    route: 'bundle.id.domains.id.edit',
                                    routeParams: {
                                        bundleId: this.bundleItem.id,
                                        domainId: domain.id,
                                        vhost: 'vhost'
                                    },
                                    text: domain.domainNameUnicode,
                                    type: domain.type
                                };
                            } else {
                                return {
                                    route: 'bundle.id.domains.id.edit',
                                    routeParams: {
                                        bundleId: this.bundleItem.id,
                                        domainId: domain.id,
                                        vhost: 'domain'
                                    },
                                    text: domain.domainNameUnicode,
                                    type: domain.type
                                };
                            }
                        }
                    );
                }

                if (this.linkListDomains.length > 5) {
                    this.linkListDomains = this.linkListDomains.slice(0, 5);
                    this.linkListDomains.push({
                        route: 'bundle.id.domains.overview',
                        routeParams: { bundleId: this.bundleItem.id },
                        text: this.$translate.instant('TR_100119-800d0e_TR'),
                        type: 'overviewLink'
                    });
                }

                if (this.bundleItem.status === 'restorable') {
                    this.linkListFooter = [
                        {
                            route: 'bundle.id.restore',
                            routeParams:  {bundleId: this.bundleItem.id},
                            text: this.$translate.instant('BUNDLE.RESTORE.TITLE')
                        }
                    ];
                } else if (this.bundleItem.status === 'active') {
                    this.linkListFooter = [
                        {
                            route: 'bundle.id.domains.new',
                            routeParams:  {bundleId: this.bundleItem.id},
                            text: this.$translate.instant('TR_090119-fec2dd_TR')
                        }
                    ];
                } else {
                    this.linkListFooter = [
                        {
                            reloadState: true,
                            route: hasMailboxes ? 'bundle.id.mailboxes.overview' : 'bundle.id.mailboxes.new',
                            routeParams: {bundleId: this.bundleItem.id},
                            text: hasMailboxes
                            ? this.$translate.instant('70b095b0-37e5-44d7-ae26-efca854c2c11')
                            : this.$translate.instant('TR_100119-e24e28_TR')
                        },
                        {
                            route: 'bundle.id.domains.overview',
                            routeParams:  {bundleId: this.bundleItem.id},
                            text: this.$translate.instant('TR_140119-aeb48a_TR')
                        }
                    ];
                }
                this.loadingDomainList = false;
            }
        );
    }
}

export class MoleculePanelBundleDashboardComponent implements ng.IComponentOptions {
    public bindings = {
        bundleItem: '<',
        linkListBottom: '<',
        linkListTop: '<'
    };
    public controller =  MoleculePanelBundleDashboardController;
    public template = require('./panel-bundle-dashboard.html');
}
