import * as ng from 'angular';
import { GeneralCreateService, JobStatusObject } from '../../../../../services';

export class OrganCreateConfirmationVhostController implements ng.IController {
    public static $inject: string[] = ['generalCreate'];

    public vhostObject: any;
    public apiOrderRequestError = false;
    public someCreateJobInSupportStatus = false;
    public jobStatusDomain: null | JobStatusObject;
    public jobStatusVhost: null | JobStatusObject;
    public jobStatusZone: null | JobStatusObject;

    constructor(
        private generalCreate: GeneralCreateService
    ) {}

    public get vhostCreatingStatus() {
        return this.generalCreate.getJobStatusForTemplate(
            this.jobStatusVhost,
            this.someCreateJobInSupportStatus,
            this.apiOrderRequestError
        );
    }

    public set vhostCreatingStatus({}) {} // tslint:disable-line:no-empty

    public get domainCreatingStatus() {
        return this.generalCreate.getJobStatusForTemplate(
            this.jobStatusDomain,
            this.someCreateJobInSupportStatus,
            this.apiOrderRequestError
        );
    }

    public set domainCreatingStatus({}) {} // tslint:disable-line:no-empty

    public get zoneCreatingStatus() {
        return this.generalCreate.getJobStatusForTemplate(
            this.jobStatusZone,
            this.someCreateJobInSupportStatus,
            this.apiOrderRequestError
        );
    }

    public set zoneCreatingStatus({}) {} // tslint:disable-line:no-empty
}

export class OrganCreateConfirmationVhostComponent implements ng.IComponentOptions {
    public bindings = {
        apiOrderRequestError: '<?',
        jobStatusDomain: '<',
        jobStatusVhost: '<',
        jobStatusZone: '<',
        someCreateJobInSupportStatus: '<?',
        vhostObject: '<'
    };
    public template = require('./vhost-confirmation.html');
    public controller =  OrganCreateConfirmationVhostController;
}
