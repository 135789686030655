import * as ng from 'angular';

export class MoleculeOverviewRowPanelDashboardBundlesController implements ng.IController {
    public static $inject: string[] = [];

    public searchValue = '';
    public serviceItemList: { length: number };

    public noBundlesOnDashboard = () => {
        return this.serviceItemList.length === 0;
    };

    public get showLinkToOverview() {
        return this.serviceItemList.length > 0;
    }
}

export class MoleculeOverviewRowPanelDashboardBundlesComponent implements ng.IComponentOptions {
    public bindings = {
        searchValue: '<',
        serviceItemList: '<'
    };

    public controller =  MoleculeOverviewRowPanelDashboardBundlesController;
    public controllerAs = 'DashboardOverviewCtrl';
    public template = require('./dashboard-bundles.html');
}
