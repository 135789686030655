import * as ng from 'angular';

import * as Types from '../../../../../types';
import { SeletionItem } from './item-box-new-generic';

export class MoleculeFormItemSelectNewGenericController implements ng.IController {
    public static $inject: string[] = [
        'authContext'
    ];

    public account: Types.AccountApi.Subaccount;
    public items: SeletionItem[];
    public changeCallback: (params) => void;
    public selectedItem: SeletionItem;

    public $onChanges(changes: ng.IOnChangesObject) {
        if (this.changeCallback != null) {
            this.changeCallback(changes);
        }
    }

    public select = (item: SeletionItem) => {
        this.selectedItem = item;
    };

    public isSelectedProduct = (item: SeletionItem) => {
        return this.selectedItem === item;
    };
}

export class MoleculeFormItemSelectNewGenericComponent implements ng.IComponentOptions {
    public controller =  MoleculeFormItemSelectNewGenericController;
    public bindings = {
        changeCallback: '<',
        items: '<',
        selectedItem: '='
    };
    public template = require('./item-select-new-generic.html');
}
