import ng from 'angular';
import { MoleculeFormEditController } from '@/atomic-components/molecules/forms/form-edit/form-edit';
import {
    OrganismEditFormWebspaceUsersController
} from '@/atomic-components/organisms/forms/edit-forms/';
import { UiRights, WebspaceAccessPasswordCharacterPoolsConst } from '@/configuration';
import { AuthContextService, NavigationService, WebhostingUserModelService } from '@/services';
import { Finding, ViewTypes, WebhostingApi} from '@/types';

export class OrganEditPanelWebspaceUsersController {
    public static $inject: string[] = ['navigation', 'webhostingUserModel'];

    // Dependency injections
    public $editFormMolecule: MoleculeFormEditController;
    public $editFormOrganism: OrganismEditFormWebspaceUsersController;

    // Public parameters
    public panelRight: Record<string, boolean>;
    public searchString = '';

    private _loadingWebspaceUserOnNewAccess = true;
    private _morePossibleExisitingWebspaceUsers = false;

    public constructor(
        private navigation: NavigationService,
        public webhostingUserModel: WebhostingUserModelService
    ) {}

    public $onInit(): void {
        this.panelRight = {
            createUser:  AuthContextService.isGranted(UiRights.WEB_USER_CREATE),
            deleteUser:  AuthContextService.isGranted(UiRights.WEB_USER_DELETE),
            editPanelButton: ['active', 'restricted'].indexOf(this.$editFormOrganism.webspace.status) >= 0
                && AuthContextService.isGrantedAny(
                    [UiRights.WEB_USER_EDIT, UiRights.WEB_USER_DELETE, UiRights.WEB_USER_CREATE]
                ),
            editUser:  AuthContextService.isGranted(UiRights.WEB_USER_EDIT)
        };
    }

    public get loadingWebspaceUserOnNewAccess(): boolean {
        return this._loadingWebspaceUserOnNewAccess;
    }
    public set loadingWebspaceUserOnNewAccess(value) {
        this._loadingWebspaceUserOnNewAccess = value;
    }

    public set morePossibleExisitingWebspaceUsers(value) {
        this._morePossibleExisitingWebspaceUsers = value;
    }
    public get morePossibleExisitingWebspaceUsers(): boolean {
        return this._morePossibleExisitingWebspaceUsers;
    }

    public addNewUser = async (): Promise<void> => {
        this.loadingWebspaceUserOnNewAccess = true;
        const availableWebhostingUser = await this.listAvailableAccountWebhostingUsers();
        this.morePossibleExisitingWebspaceUsers = availableWebhostingUser.data.length > 0;
        this.loadingWebspaceUserOnNewAccess = false;

        const props = this.addDefaultWebspaceUserObjectProperties('new');
        this.$editFormOrganism.userAccessList.unshift({
            accessLevel: {
                ftpAccess: false,
                ftpLimited: false,
                sshAccess: false,
                statsAccess: false
            },
            accessType: ViewTypes.UserAccessType.new,
            comments: '',
            homeDir: '',
            name: '',
            password: '',
            sshKey: '',
            props: props,
            userId: undefined,
            userName: '',
            status: 'active'
        });
    };

    public listAvailableAccountWebhostingUsers = (
        limit?: number,
        page?: number,
        filters?: Finding.Filter,
        sort?: Finding.SortOptions,
        owner?: string
    ): PromiseLike<ViewTypes.ApiListResponse> => {
        const filterObject: Finding.Filter = {
            subFilter: [{
                field: 'accountId',
                value: this.$editFormOrganism.webspace.accountId
            }],
            subFilterConnective: 'AND'
        };

        if ([undefined, null].indexOf(filters) >= 0) {
            filterObject.subFilter.push({
                field: 'userName',
                value: '*'
            });
        } else {
            if (filters.field === 'userName') {
                filterObject.subFilter.push(filters);
            } else {
                filterObject.subFilter.push({
                    field: 'userName',
                    value: '*'
                });
            }
        }

        return this.webhostingUserModel
            .list(limit, page, filterObject, sort, owner)
            .then(
                (
                    webspaceUserListResponse: ViewTypes.ApiListResponse
                ) => {
                    (webspaceUserListResponse.data as WebhostingApi.User[]) = webspaceUserListResponse.data.filter(
                        (webspaceUser: WebhostingApi.User) => {
                            return !this.$editFormOrganism.userAccessList.some(
                                (access) => access.userId === webspaceUser.id
                            );
                        }
                    );

                    return webspaceUserListResponse;
                }
            );
    };

    public get disableAddButton(): boolean {
        return this.$editFormOrganism.userAccessList.some((user) => user.props.isBeingEdited === true);
    }

    public addDefaultWebspaceUserObjectProperties = (
        objectType: string = ViewTypes.UserAccessType.existing
    ): ViewTypes.WebspaceUserAccessPropsObject => {
        const isNewUser = objectType === 'new';
        const isBeeingEdited = objectType === 'new';
        const newAccessCreateType = objectType === 'new' ? ViewTypes.UserAccessType.new : undefined;

        return {
            isMarkedForRemoval: false,
            isNewUser: isNewUser,
            isBeingEdited: isBeeingEdited,
            isHidden: false,
            newAccessCreateType: newAccessCreateType,
            type: objectType,
            validationErrorList: {
                accessLevel: [],
                homeDir: [],
                name: []
            },
            passwordValidationOptions: {
                characterPools: WebspaceAccessPasswordCharacterPoolsConst,
                maxLength: 128,
                minCharacterPoolsUsed: 3,
                minLength: 8
            }
        };
    };

    public cancel = (): void => {
        this.navigation.reloadCurrentState();
    };
}

export class OrganEditPanelWebspaceUsersComponent implements ng.IComponentOptions {
    public bindings = {
        validationInstructions: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormWebspaceUsers'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./webspace-users.html');
    public controller = OrganEditPanelWebspaceUsersController;
}
