import * as ng from 'angular';
import { WizardHelperService, WizardObject } from '../';
import { AuthContextService, DepositModelService } from '../../../../services';

export class MoleculeWizardSufficientCreditController implements ng.IController {
    public static $inject: string[] = ['depositModel', 'wizardHelper'];

    public sufficientCreditAvailable = true;

    private _children = '';
    private _mainProductInContingent = false;
    private serviceObject: WizardObject;
    private selectedPrice: any;

    constructor(
        private depositModel: DepositModelService,
        private wizardHelper: WizardHelperService
    ) {}

    public $onInit() {
        this._children = JSON.stringify(this.serviceObject.children);
        // to set depositInfo
        this.depositModel.reloadDepositInfo().then(() => this.depositModel.sufficientCreditForOrder(0));
    }

    public $onChanges(changes: any) {
        // watch on selected product
        if (changes.selectedPrice !== undefined && [undefined, null].indexOf(this.serviceObject.selectedProduct) < 0) {
            this.sufficientCreditAvailable = this._calculateRemainingDeposit(
                changes.selectedPrice.currentValue !== undefined
                && Array.isArray(changes.selectedPrice.currentValue)
                    ? changes.selectedPrice.currentValue[0]
                    : undefined
            );
        }
        if (changes._mainProductInContingent !== undefined) {
            this.sufficientCreditAvailable = this._calculateRemainingDeposit();
        }
    }

    public $doCheck() {
        // watch on changed children
        if (JSON.stringify(this.serviceObject.children) !== this._children) {
            this._children = JSON.stringify(this.serviceObject.children);
            this.sufficientCreditAvailable = this._calculateRemainingDeposit();
        }
    }

    public get hideSufficientCreditHint() {
        if ((
                ([undefined, null].indexOf(this.serviceObject.selectedProduct) >= 0
                || [undefined, null].indexOf(this.selectedPrice) >= 0)
            )
            && this.serviceObject.children.length === 0
        ) {
            // if no product is selected or no price is selected and no children are set
            this.sufficientCreditAvailable = true;
        }

        return this.sufficientCreditAvailable;
    }

    private _calculateRemainingDeposit = (selectedPrice?) => {
        if (
            AuthContextService.isRootOrCompanyAccount
            || !AuthContextService.isMainUser
            || AuthContextService.account.billingSettings.paymentType === 'external'
        ) {
            return true;
        }

        let cost = 0;
        let creditAfterPurchase = null;

        if (selectedPrice !== undefined
            && JSON.stringify(selectedPrice) !== JSON.stringify(this.serviceObject.selectedProduct.selectedPrice)
        ) {
            this.serviceObject.selectedProduct.selectedPrice = selectedPrice;
        }

        this.wizardHelper
            .getTotalPrice(this.serviceObject, true)
            .map((obj: any) => {
                cost += obj.totalPrice;
            }
        );

        if (cost >= 0) {
            creditAfterPurchase = this.depositModel.sufficientCreditForOrder(+(cost * 10000).toFixed(0));
        }

        if (creditAfterPurchase === null) {
            // No deposit data...
            return true;
        }

        if (creditAfterPurchase < 0) {
            // The main product is ordered in quota and no
            // additional add-ons (e.g. storage space) are ordered in children.
            if ([null, false].indexOf(this._mainProductInContingent) < 0 && this.serviceObject.children.length === 0) {
                return true;
            }
            // If add-on products are available (e.g. domains, memory extension etc.)
            // we check if the products are ordered in bundles.
            if (this.serviceObject.children.length > 0)  {
                return this.serviceObject.children.every((child: any) => child.inBundle);
            }
        }

        return creditAfterPurchase >= 0;
    };

}

export class MoleculeWizardSufficientCreditComponent implements ng.IComponentOptions {
    public bindings = {
        _mainProductInContingent: '<?inContingent',
        selectedPrice: '<?',
        serviceObject: '<',
        sufficientCreditAvailable: '=?'
    };
    // tslint:disable-next-line
    public template = require('./wizard-service-sufficient-credit.html');
    public controller =  MoleculeWizardSufficientCreditController;
}
