import * as ng from 'angular';

export class MoleculeButtonConfirmController implements ng.IController {
    public static $inject: string[] = [];

    public confirm: (confirmParams?: any) => void;
    public confirmParam: any;
    public disabled: boolean;
    public hideIcon: boolean;

    public $onInit() {
        this.hideIcon = this.hideIcon || false;
    }

    public get getHideIcon() {
        if (this.hideIcon) {
            return true;
        } else {
            return false;
        }
    }

    public buttonClicked = () => {
        if (this.confirmParam) {
            this.confirm(this.confirmParam);
        } else {
            this.confirm();
        }
    };
}

export class MoleculeButtonConfirmComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        confirm: '<',
        confirmParam: '<?',
        disabled: '<',
        hideIcon: '<'
    };
    public controller =  MoleculeButtonConfirmController;
    public template = require('./button-confirm.html');
}
