import * as Types from '@/types';
import {
    GenericSetup,
    OrderItemDisplayLine,
    OrderItemStatus,
    GenericInjectedRequests,
    MaybeLoading,
} from '@/components/setup/setup';
import { sleep } from '@/services/helpers/helpers-without-framework';
import { ManagedApplicationRobotService, SentryErrorEmitterService } from '@/services';

type OrchestrationJob = Types.ViewTypes.OrchestrationJob;
type Nextcloud = Types.ManagedApplicationApi.Nextcloud;
type APISingleResponse<T> = Types.UI.APISingleResponse<T>;

export type StorageAdditionalInjectedRequests = {
    loadNextcloud: (id: string) => Promise<Nextcloud>,
    updateNextcloud: (nextcloud: Nextcloud) => Promise<APISingleResponse<Nextcloud>>,
}

export type InjectedRequests = GenericInjectedRequests & StorageAdditionalInjectedRequests;

export class StorageSetup extends GenericSetup {

    // fetched async from API
    private item: MaybeLoading<Nextcloud> = {state: 'waiting'};
    public finalStatus: string[] = ['successful', 'failed', 'canceled'];

    constructor(
        private managedApplicationRobot: ManagedApplicationRobotService,
        protected requests: InjectedRequests,
        private applyWrapper?: (fn: () => void) => void,
    ) {
        super();
        applyWrapper ??= (fn: () => void) => fn();
    }

    protected jobIsDone = (job: OrchestrationJob) => {
        switch (job.objectType) {
            case 'Storage':
                if (this.item.state == 'waiting') {
                    this.item = {state: 'loading'};
                    this.requests.loadNextcloud(job.objectId).then(
                        (nextcloud: Nextcloud) => {
                            this.item = { state: 'loaded', data: nextcloud };
                        },
                    );
                }
                break;
        }

    }

    protected postprocessOrderItemDisplayLines = () => {}

    protected orderItemDisplayLinesBeforeJobIsLoaded = (): OrderItemDisplayLine[] => {
        // Loading data, to avoid jumping element positions
        const lines: OrderItemDisplayLine[] = [];
        lines.push(new OrderItemDisplayLine('Storage', 'Storage', OrderItemStatus.PENDING));
        return lines;
    }

    public nextcloudPoll = async (productId: string) => {
        const jobFilter = { field: 'JobObjectId', value: productId };
        // use jobsFind mit Filter statt loadNextcloud
        let pollAgain = true;
        do {
            const jobs = await this.managedApplicationRobot.jobsFind(jobFilter);
            // It's no orchestration job, that gets updated, but still fits. Maybe rename function?
            this.updateOrchestrationJobs(jobs.response.data);

            for (const order of jobs.response.data) {
                if (this.finalStatus.indexOf(order.status) >= 0) {
                    pollAgain = false;
                }
            }
            await sleep(2000);
        } while (pollAgain)
    }
}
