import { UIRegExp } from '@/configuration';
import { translate } from 'angular';
import { Validator } from './abstract-validator';

/**
    https://tools.ietf.org/html/rfc4255

    Validierung:
    * `name` muss in der Zone liegen
    * `content` hat 3 Teile getrennt durch ' ' (Leerzeichen)
        ** Erster Teil ist "algorithm", angegeben als Zahl
        ** Zweiter Teil ist "fingerprint type", angegeben als Zahl
        ** Dritter Teil ist der Fingerprint in Hex-Darstellung
    * Beispiel für validen `content`: `2 1 123456789abcdef67890123456789abcdef67890`

    Die erlaubten Werte für Algorithm (1, 2, 3, 4, 6) und Fingerprint Type (1, 2) sind bei der IANA aufgelistet:
    https://www.iana.org/assignments/dns-sshfp-rr-parameters/dns-sshfp-rr-parameters.xhtml
*/

export class ValidateSshfp extends Validator {
    constructor(private $translate: translate.ITranslateService) {
        super();
    }

    public validate = (value: string): { text: string }[] => {
        const parts = value.split(' ');

        if (parts.length !== 3 || parts.some((val) => val.length === 0)) {
            return [
                {
                    text: this.$translate.instant('TR_131020-30d0aa_TR')
                }
            ];
        }

        const algorithm = parseInt(parts[0], 10);
        if (isNaN(algorithm) || ![1, 2, 3, 4, 6].includes(algorithm)) {
            return [
                {
                    text: this.$translate.instant('TR_131020-87fc10_TR')
                }
            ];
        }

        const fingerprintType = parseInt(parts[1], 10);
        if (isNaN(fingerprintType) || ![1, 2].includes(fingerprintType)) {
            return [
                {
                    text: this.$translate.instant('TR_131020-6e2ff6_TR')
                }
            ];
        }

        if (!UIRegExp.Hex.test(parts[2])) {
            return [
                {
                    text: this.$translate.instant('TR_240222-5fe53b_TR')
                }
            ];
        }

        return [];
    };
}
