import * as ng from 'angular';
import { PanicMode } from './../../../services/panic-mode';

export class TemplatePageLoadErrorController implements ng.IComponentController {
    public get panicModeActive() {
        return PanicMode.active;
    }
}

export class TemplatePageLoadErrorComponent implements ng.IComponentOptions {
    public controller = TemplatePageLoadErrorController;
    public template = require('./page-load-error.html');
}
