import * as ng from 'angular';
import { UiRights } from '../../configuration';
import {
    DatabaseJobOverviewModel,
    DatabaseOverviewModel,
    DatabaseUserModelService,
    FilterCacheService,
    NavigationService,
    RedirectOnNotFoundCallback,
    RestrictionHelperService
} from '../../services';
import { UserSettingsManagerService } from '../../services/account';
import { BackupModelService } from '../../services/backup';
import { DatabaseJobModelService, DatabaseModelService, DatabaseUserOverviewModel } from '../../services/database';
import * as stateUtilityFunctions from '../../services/state-utility-functions';

export const DATABASE_ROUTES = [
    {
        name: 'database',
        state: {
            abstract: true,
            parent: 'authenticated',
            url: '/database'
        }
    },
    {
        name: 'database.dashboard',
        state: {
            data: {
                isGranted: UiRights.DB_DATABASE_LIST
            },
            onEnter: (navigation: NavigationService, userSettingsManager: UserSettingsManagerService) => {
                userSettingsManager.getUiSettings().then(
                    (result) => {
                        if (result?.overviewSettings?.DatabaseOverviewCtrl?.expertView) {
                            navigation.go('database.databases.overview');
                        }
                    }
                );
            },
            resolve: {},
            url: '/dashboard',
            views: {
                'content@authenticated': {
                    template: '<template-database-dashboard></template-database-dashboard>'
                }
            }
        }
    },
    {
        name: 'database.databases',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.DB_DATABASE_LIST
            },
            url: '/databases'
        }
    },
    {
        name: 'database.databases.new',
        state: {
            data: {
                isGranted: UiRights.DB_DATABASE_CREATE
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-database-wizard></template-database-wizard>'
                }
            }
        }
    },
    {
        name: 'database.databases.id',
        state: {
            abstract: true,
            resolve: {
                backups: (backupModel: BackupModelService, database) => {
                    return backupModel.list('database', 'database', database.id);
                },
                database: (
                    $stateParams: ng.ui.IStateParamsService,
                    databaseModel: DatabaseModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return databaseModel.findOneById($stateParams.databaseId)
                        .then(stateUtilityFunctions.check404('Database not found'))
                        .then(null, redirectOnNotFound);
                },
                databaseUsers: (database, databaseUserModel: DatabaseUserModelService) => {
                    return databaseUserModel.list(100, null, {field: 'accountId', value: database.accountId})
                    .then(stateUtilityFunctions.check404('Database not found'))
                    .then((response) => {
                        return response.data;
                    });
                }
            },
            url: '/id/{databaseId}'
        }
    },
    {
        name: 'database.databases.id.edit',
        state: {
            data: {
                isGranted: UiRights.DB_DATABASE_EDIT
            },
            resolve: {
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-database-edit
                        database="$resolve.database"
                        database-users="$resolve.databaseUsers"
                        backups="$resolve.backups"
                        show-object-id="$resolve.showObjectId"
                    ></template-database-edit>`
                }
            }
        }
    },
    {
        name: 'database.databases.id.backups',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.BACKUP_BACKUP_LIST
            },
            resolve: {
                backups:
                    (database, backupModel: BackupModelService) => [undefined, null, ''].indexOf(database) === -1
                        ? backupModel.list('database', 'database', database.id)
                        : []
            },
            url: '/backups'
        }
    },
    {
        name: 'database.databases.id.backups.overview',
        state: {
            url: '/overview',
            views: {
                'content@authenticated': {
                    // tslint:disable-next-line: max-line-length
                    template: '<template-database-backups backups="$resolve.backups" database="$resolve.database"></template-database-backups>'
                }
            }
        }
    },
    {
        name: 'database.databases.id.restrictions',
        state: {
            data: {
                isGranted: UiRights.DB_DATABASE_EDIT
            },
            resolve: {
                restrictionHelper: (
                    database,
                    restrictionHelper: RestrictionHelperService,
                    databaseModel: DatabaseModelService
                ) => {
                    restrictionHelper.init(database, 'database', databaseModel, 'databaseModifyRestrictions');
                    return restrictionHelper;
                },
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/restrictions',
            views: {
                'content@authenticated': {
                    template: `<template-restrictions-edit
                        api-object="$resolve.database"
                        restriction-helper="$resolve.restrictionHelper"
                        show-object-id="$resolve.showObjectId"
                    ></template-restrictions-edit>`
                }
            }
        }
    },
    {
        name: 'database.databases.overview',
        state: {
            data: {
                isGranted: UiRights.DB_DATABASE_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('Databases');
                },
                overviewModel: (databaseOverviewModel: DatabaseOverviewModel) => databaseOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('DatabaseOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('Databases');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-database-overview
                        simple-filter="$resolve.simpleFilter"
                        extended-filters="$resolve.extendedFilters"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        overview-model="$resolve.overviewModel"
                    ></template-database-overview>`
                }
            }
        }
    },
    {
        name: 'database.jobs',
        state: {
            data: {
                isGranted: UiRights.DB_JOBS_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('DatabaseJobs');
                },
                overviewModel: (databaseJobOverviewModel: DatabaseJobOverviewModel) => databaseJobOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('DatabaseOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('DatabaseJobs');
                }
            },
            url: '/jobs',
            views: {
                'content@authenticated': {
                    template: `<template-database-job-overview
                        simple-filter="$resolve.simpleFilter"
                        extended-filters="$resolve.extendedFilters"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        overview-model="$resolve.overviewModel"
                    ></template-database-job-overview>`
                }
            }
        }
    },
    {
        name: 'database.jobs.id',
        state: {
            abstract: true,
            resolve: {
                job: (
                    $stateParams: ng.ui.IStateParamsService,
                    databaseJobModel: DatabaseJobModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return databaseJobModel.findOne($stateParams.jobId).then(null, redirectOnNotFound);
                }
            },
            url: '/id/{jobId}'
        }
    },
    {
        name: 'database.jobs.id.details',
        state: {
            url: '/details',
            views: {
                'content@authenticated': {
                    // tslint:disable-next-line: max-line-length
                    template: `<template-database-job-details
                                    job="$resolve.job"
                                ></template-database-job-details>`
                }
            }
        }
    },
    {
        name: 'database.users',
        state: {
            abstract: true,
            url: '/users'
        }
    },
    {
        name: 'database.users.overview',
        state: {
            data: {
                isGranted: UiRights.DB_USER_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('DatabaseUsers');
                },
                overviewModel: (databaseUserOverviewModel: DatabaseUserOverviewModel) => databaseUserOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('WebspaceOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('DatabaseUsers');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-database-user-overview
                        simple-filter="$resolve.simpleFilter"
                        extended-filters="$resolve.extendedFilters"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        overview-model="$resolve.overviewModel"
                    ></template-database-user-overview>`
                }
            }
        }
    },
    {
        name: 'database.users.id',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.DB_USER_EDIT
            },
            resolve: {
                user: (
                    $stateParams: ng.ui.IStateParamsService,
                    databaseUserModel: DatabaseUserModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => databaseUserModel.findOneById($stateParams.userId).then(null, redirectOnNotFound)
            },
            url: '/id/{userId}'
        }
    },
    {
        name: 'database.users.id.edit',
        state: {
            url: '/edit',
            views: {
                'content@authenticated': {
                    // tslint:disable-next-line: max-line-length
                    template: '<template-database-user-edit user="$resolve.user"></template-database-user-edit user="$resolve.user">'
                }
            }
        }
    },
    {
        name: 'database.users.id.databases',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.DB_DATABASE_LIST
            },
            url: '/databases'
        }
    },
    {
        name: 'database.users.id.databases.overview',
        state: {
            data: {
                isGranted: UiRights.DB_DATABASE_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('Databases');
                },
                overviewModel: (databaseOverviewModel: DatabaseOverviewModel) => databaseOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('DatabaseOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('Databases');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-database-overview
                        simple-filter="$resolve.simpleFilter"
                        extended-filters="$resolve.extendedFilters"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        overview-model="$resolve.overviewModel"
                    ></template-database-overview>`
                }
            }
        }
    },
    {
        name: 'database.users.new',
        state: {
            data: {
                isGranted: UiRights.DB_USER_CREATE
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    // tslint:disable-next-line: max-line-length
                    template: '<template-database-user-new></template-database-user-new>'
                }
            }
        }
    }
];
