export class OrganDashboardWidgetDomainSearchController implements ng.IController {
    public static $inject: string[] = [];

    public tldPriceList: string[] = ['de', 'eu', 'at', 'com', 'net'];
}

export class OrganDashboardWidgetDomainSearchComponent implements ng.IComponentOptions {
    public template = require('./dashboard-widget-domain-search.html');
    public controller = OrganDashboardWidgetDomainSearchController;
}
