import * as ng from 'angular';
import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import {
    DashboardOverviewObject
} from '../../../atomic-components/organisms/overview/dashboard-overviews/dashboard-overviews';
import { UiRights } from '../../../configuration';
import {
    AuthContextService,
    FilterCacheService,
    VhostOverviewModel,
    WebspaceOverviewModel
} from '../../../services';

export class TemplateWebhostingDashboardController {
    public static $inject: string[] = [
        '$translate',
        'authContext',
        'filterCache',
        'webspaceOverviewModel',
        'vhostOverviewModel'
    ];

    public userHasRightWebObjectList = AuthContextService.isGranted(UiRights.WEB_OBJECT_LIST); // used in template!
    public pageHeaderData: PanelHeaderData;
    public dashboardOverview: DashboardOverviewObject;
    public faqArticleIdList: string[] = [
        'webspace-expand-memory'
    ];
    public applicationList: any;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private filterCache: FilterCacheService,
        private webspaceOverviewModel: WebspaceOverviewModel,
        private vhostOverviewModel: VhostOverviewModel
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            panelHeaderRoute: '',
            panelIcon: 'server',
            panelTitle: this.$translate.instant('TR_140119-5d4ef1_TR')
        };

        this.dashboardOverview = {
            // List of table service data
            overviewsData: [
                // Webspaces
                {
                    listOptions: { // optional
                        additionalFilters: [],
                        limit: 4,
                        objectId: null,
                        sort: {
                            field: 'WebspaceLastChangeDate',
                            order: 'DESC'
                        }
                    },
                    overviewModel: this.webspaceOverviewModel,
                    panelHeaderData: {
                        includeShowAllLink: true,
                        includeShowDetailsLink: false,
                        panelHeaderRoute: 'webhosting.webspaces.overview',
                        panelHeaderRouteParams: {},
                        panelIcon: 'server',
                        panelTitle: this.$translate.instant('WEBHOSTING.GENERAL.WEBSPACES')
                    },
                    serviceItemList: [] // optional - is additional set in dashboard overviews organism, if not defined
                },
                // vHost / Domains
                {
                    listOptions: {
                        limit: 6,
                        sort: {
                            field: 'VHostLastChangeDate',
                            order: 'DESC'
                        }
                    },
                    overviewModel: this.vhostOverviewModel,
                    panelHeaderData: {
                        includeShowAllLink: true,
                        includeShowDetailsLink: false,
                        panelHeaderRoute: 'webhosting.vhosts.overview',
                        panelHeaderRouteParams: {},
                        panelIcon: 'globe',
                        panelTitle: this.$translate.instant('TR_140119-aeb48a_TR')
                    },
                    serviceItemList: []
                }
            ],
            // Dashboard main menu panel
            panelData: {
                addtionalActionData: {
                    // Addition action panel
                    linkList: [
                        {
                            isGranted: this.authContext.isGrantedAny([
                                UiRights.WEB_USER_EDIT,
                                UiRights.WEB_USER_DELETE,
                                UiRights.WEB_USER_CREATE
                            ]),
                            route: 'webhosting.users.overview',
                            routeParams: {},
                            text: this.$translate.instant('TR_140119-9183af_TR')
                        },
                        {
                            isGranted: this.authContext.isGranted(UiRights.WEB_JOBS_LIST),
                            route: 'webhosting.webspaces.jobs',
                            routeParams: {},
                            text: this.$translate.instant('MENU.CONTEXT.JOBS')
                        }
                    ]
                },
                mainHeader: [
                    {
                        icon: 'server',
                        isGranted: this.authContext.isGranted(UiRights.WEB_WEBSPACE_CREATE),
                        route: 'webhosting.webspaces.new',
                        routeParams: {},
                        text: this.$translate.instant('TR_140119-4987e0_TR')
                    },
                    {
                        icon: 'server',
                        isGranted: this.authContext.isGranted(UiRights.WEB_WEBSPACE_CREATE),
                        route: 'webhosting.vhosts.new',
                        routeParams: {},
                        text: this.$translate.instant('TR_090119-fec2dd_TR')
                    }
                ]
            },
            simpleFilter: this.filterCache.resolveSimpleFilter('WebspacesAndVhosts')
        };
    };
}

export class TemplateWebhostingDashboardComponent implements ng.IComponentOptions {
    public bindings = {
        applicationList: '<'
    };
    public controller = TemplateWebhostingDashboardController;
    public controllerAs = '$TemplateWebhostingDashboardCtrl';
    public template = require('./dashboard-template.html');
}
