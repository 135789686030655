import * as ng from 'angular';
import { AsterikNoteObject, AsteriskNoteService, AsteriskNoteType } from './../../../services/billing/asterisk-note-service';

export class MoleculeAsteriskOutputController implements ng.IController {
    public static $inject: string[] = ['$translate', 'asteriskNote'];

    constructor(
        private $translate: ng.translate.ITranslateService,
        public asteriskNote: AsteriskNoteService
    ) {}

    public showNoteText = (asteriskNote: AsterikNoteObject) => {
        return asteriskNote.type !== AsteriskNoteType.DYNAMIC_NOTE
            ? this.$translate.instant(asteriskNote.value)
            : asteriskNote.value;
    };

    public get notesAreEmpty() {
        return this.asteriskNote.notes.length === 0
            || this.asteriskNote.notes[0] === null
            || this.asteriskNote.notes[0] === undefined;
    }
}

export class MoleculeAsteriskOutputComponent implements ng.IComponentOptions {
    public bindings = {
        noteType: '<'
    };
    public controller =  MoleculeAsteriskOutputController;
    public template = require('./asterisk-note-output.html');
}
