import { UserRobotService } from '@/services/account/robot';
import { AuthContextService } from '@/services/auth-context';
import { ModelHelper } from '@/services/model-helper';
import * as Types from '@/types';

export class RightModelService {
    public static $inject: string[] = ['userRobot'];

    constructor(
        private userRobot: UserRobotService
    ) {}

    public getList = (): PromiseLike<Types.AccountApi.RightGroup[]> => {
        return this.userRobot.listRights(this.language)
            .then((result) => result.responses);
    };

    public getListForSubaccount = (parentAccountId: string): PromiseLike<Types.AccountApi.RightGroup[]> => {
        return this.userRobot.listRightsForSubaccount(parentAccountId, this.language)
            .then((result) => result.responses);
    };

    public getListForUser = (accountId: string): PromiseLike<Types.AccountApi.RightGroup[]> => {
        return this.userRobot.listRightsForUser(accountId, this.language)
            .then((result) => result.responses);
    };

    public getListForApiKey = (userId: string): PromiseLike<Types.AccountApi.RightGroup[]> => {
        return this.userRobot.listRightsForApiKey(userId, this.language)
            .then((result) => result.responses);
    };

    public rightsTemplate = (
        limit?: number,
        page?: number,
        filters?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions
    ): PromiseLike<Types.ViewTypes.EntityWithPagination<Types.AccountApi.RightsTemplate>> => {
        sort = sort || null;
        page = page || 1;

        return this.userRobot.listRightsTemplate(filters, limit, page, sort)
            // eslint-disable-next-line @typescript-eslint/unbound-method
            .then(ModelHelper.returnListResponse);
    };

    public findOneRightsTemplate = (id: string) => {
        return this.userRobot.listRightsTemplateWithoutPagination(
            { field: 'rightsTemplateId', value: id.toString() },
            1,
            1
        )
        .then(ModelHelper.returnFindOneResponse);
    };

    public findByIdRightsTemplate = (ids: string[]) => {
        const filter: Types.Finding.Filter = { subFilterConnective: 'OR', subFilter: ids.map(this.idFilter) };

        return this.userRobot.listRightsTemplateWithoutPagination(filter, 0, 1)
        .then(ModelHelper.returnFindResponse);
    };

    public createRightsTemplate = (rightsTemplate: Types.AccountApi.RightsTemplate) => {
        return this.userRobot.createRightsTemplate(rightsTemplate);
    };

    public updateRightsTemplate = (rightsTemplate: Types.AccountApi.RightsTemplate) => {
        return this.userRobot.updateRightsTemplate(rightsTemplate);
    };

    public deleteRightsTemplates = (rightsTemplates: Types.AccountApi.RightsTemplate[]) => {
        const promises = rightsTemplates.map(this.deleteOneRightsTemplate);
        return Promise.all(promises);
    };

    public getAvailableRightsSubaccount = (accountId: string) => {
        return this.userRobot.getAvailableRightsSubaccount(accountId)
        .then(this.returnResponses);
    };

    public getAvailableRightsUser = (accountId: string) => {
        return this.userRobot.getAvailableRightsUser(accountId)
        .then(this.returnResponses);
    };

    public getAvailableRightsApiKey = (userId: string) => {
        return this.userRobot.getAvailableRightsApiKey(userId)
        .then(this.returnResponses);
    };

    private get language(): string {
        const language = AuthContextService.user.language;

        if ([undefined, null, ''].indexOf(language) >= 0) {
            return 'de';
        }

        return language.substr(0, 2);
    }

    private returnResponses = <T>(result: { responses: T }): T => {
        return result.responses;
    };

    private idFilter = (id: string): Types.Finding.Filter => {
        return { field: 'rightsTemplateId', value: id };
    };

    private deleteOneRightsTemplate = (rightsTemplate: Types.AccountApi.RightsTemplate): PromiseLike<any> => {
        return this.userRobot.deleteRightsTemplate(rightsTemplate.id);
    };
}
