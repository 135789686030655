import * as ng from 'angular';

import { NavigationService } from '@/services';
import { TopLevelDomains, UiRights } from '../../../../configuration';
import { AuthContextService, DomainModelService } from '../../../../services';

import './panel-domain-check.scss';

export class MoleculePanelDomainCheckController implements ng.IController {
    public static $inject: string[] = [
        '$state',
        '$translate',
        'authContext',
        'domainModel',
        'navigation'
    ];

    public selectedDomains: any[] = [];
    public numberOfSelectedDomains: number;
    public domainSearchString = '';
    public panelVisible = false;

    public dropdownSelection = TopLevelDomains.groups[0].name;
    public dropdownItems = TopLevelDomains.groups.map(
        (group) => ({ name: this.$translate.instant(group.name), value: group.name })
    );

    private _domainInfo: any[] = [];

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        public authContext: AuthContextService,
        private domainModel: DomainModelService,
        private navigation: NavigationService
    ) {}

    public $onInit = () => {
        this.panelVisible = this.authContext.isGrantedAny([
            UiRights.DOM_DOMAINS_CREATE_WITH_CONTACTS,
            UiRights.DOM_DOMAINS_CREATE_WITH_DEFAULTS,
            UiRights.DOM_DOMAINS_CREATE_WITH_OWN_NS
        ]);
    };

    public get selectedEndings() {
        return TopLevelDomains.groups.filter(
            (group) => group.name === this.dropdownSelection
        )[0].tlds;
    }

    public get domainInfo() {
        return this._domainInfo;
    }

    public set domainInfo(value: any[]) {
        if (
            this.$state.current.name === 'dashboard'
            && [undefined, null, []].indexOf(value) < 0
            && value.length === 1
            && ['alreadyRegistered'].indexOf(value[0].domain.status) >= 0
        ) {
            this.domainModel.listWithoutPagination(1, 1,
                { field: 'DomainNameUnicode', value: value[0].domain.domainNameUnicode })
                .then(
                    (result) => {
                        this._domainInfo = value;
                        if (result.data && result.data.length === 1) {
                            this._domainInfo[0].domain.id = result.data[0].id;
                        }
                    }
                );
        } else {
            this._domainInfo = value;
        }
    }

    public goToWizard = () => {
        const domainSearchString = this.selectedDomains
            .map((sd) => sd.domainName)
            .join('\n');

        const domainInfo = this.domainInfo.filter((df) => {
            return this.selectedDomains.some((sd) => sd.domainName === df.domain.domainName);
        });

        this.navigation.go('domain.domains.new',
            {
                domainSearchString: domainSearchString,
                domainList: domainInfo,
                selectedDomains: this.selectedDomains
            }
        );
    };
}

export class MoleculePanelDomainCheckComponent implements ng.IComponentOptions {
    public controller =  MoleculePanelDomainCheckController;
    public template = require('./panel-domain-check.html');
}
