import * as ng from 'angular';
import * as q from 'q';
import { UiRights } from '../../../../../configuration';
import {
    AlertManagerService,
    AuthContextService,
    DatabaseJobModelService,
    DatabaseUserModelService,
    NavigationService
} from '../../../../../services';
import { EditPanelStatus } from '../../../../molecules/forms/form-edit/form-edit';

export class OrganismEditFormDatabaseUserController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'databaseJobModel',
        'databaseUserModel',
        'navigation'
    ];

    public originalUser: any;
    public user: any;
    public userPanelRight: any;
    public initialStatus = EditPanelStatus.ACTIVE;
    public userHasUnfinishedJob = false;
    public hasAgreedToDeleteUser = false;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private databasejobModel: DatabaseJobModelService,
        private databaseUserModel: DatabaseUserModelService,
        private navigation: NavigationService
    ) {}

    public $onInit = () => {
        this.user = ng.copy(this.originalUser);

        if (AuthContextService.isGranted(UiRights.DB_JOBS_LIST)) {
            this.databasejobModel.findRunningJobsForUser(this.originalUser)
            .then(
                (result) => {
                    this.userHasUnfinishedJob = result.status === 'success'
                    && result.response.data.length > 0;
                }
            );
        }
    };

    public save = () => {
        return this.databaseUserModel.updateUser(this.user, this.user.password).then(
            (user) => {
                if (user.status !== 'error') {
                    this.alertManager.success(this.$translate.instant('TR_100119-54df5d_TR'));
                    return user;
                }

                return q.reject(user);
            },
            (err) => q.reject(err)
        );
    };

    public delete = () => {
        return this.databaseUserModel.deleteUsers([this.user])
        .then(
            () => {
                this.navigation.go('database.users.overview', {}, { reload: true });
                return q.reject();
            }
        );
    };

    public cancelGeneral = () => {
        this.user = ng.copy(this.originalUser);
    };

    public cancelDelete = () => {
        this.hasAgreedToDeleteUser = false;
    };
}

export class OrganismEditFormDatabaseUserComponent implements ng.IComponentOptions {
    public bindings = {
        originalUser: '<user',
        userPanelRight: '='
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormDatabaseUserController;
    public template = require('./database-user-edit.html');
}
