import * as ng from 'angular';
import * as Types from '../../../../../types';

export class MoleculePanelEditRowEmailCheckerDnsController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public checkedEmailResponse: Types.EmailApi.CheckEmailAddressResult;
    public setAutoConfigureDns = false;
    public showDnsAutoConfigDetails: boolean;
    public dnsAdjustmentsInformationText: string;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.setAutoConfigureDns = ng.copy(this.checkedEmailResponse.dnsAutoconfigurationPossible);
        this.showDnsAutoConfigDetails = !this.setAutoConfigureDns;
        this.dnsAdjustmentsInformationText = this.checkedEmailResponse.dnsAutoconfigurationPossible
            ? this.$translate.instant('TR_081019-577fcc_TR')
            : this.$translate.instant('TR_170519-21b39a_TR');
    }

    public get showComponent() {
        return [undefined, null].indexOf(this.checkedEmailResponse) < 0
            && this.checkedEmailResponse.dnsConfigurationRequired
            && [undefined, null].indexOf(this.showDnsAutoConfigDetails) < 0;
    }

    public set showComponent({}) {} // tslint:disable-line:no-empty

    public get hideCheckbox() {
        return this.checkedEmailResponse !== undefined
            ? !this.checkedEmailResponse.dnsAutoconfigurationPossible
            : true;
    }

    public set hideCheckbox({}) {} // tslint:disable-line:no-empty
}

export class MoleculePanelEditRowEmailCheckerDnsComponent implements ng.IComponentOptions {
    public bindings = {
        checkedEmailResponse: '<',
        setAutoConfigureDns: '='
    };
    public controller = MoleculePanelEditRowEmailCheckerDnsController;
    public template = require('./panel-edit-row-email-checker-dns.html');
}
