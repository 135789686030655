import * as ng from 'angular';

import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { DisableFilter } from '../../../atomic-components/organisms/overview/overview';

export class TemplateMachinePoolOverviewController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;
    public overviewServiceData;
    public expertView: boolean;
    public disableFilter: DisableFilter;
    public overviewModel;
    public simpleFilter;
    public extendedFilters;
    public overviewUiSettings;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit() {
        this.expertView = true;  // only expertview on job overviews
        this.disableFilter = {
            fancyFilter: false,
            searchFilter: false
        };

        this.pageHeaderData = {
            backwardLink: 'machine.dashboard',
            backwardText: this.$translate.instant('9a606991-45b5-4ec6-a927-ccfbaf9b24ae'),
            panelHeaderRoute: '',
            panelIcon: 'server',
            panelTitle: this.$translate.instant('TR_140119-fceb91_TR')
        };

        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewService: 'machinePool',
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter,
            sort: {
                field: 'poolLastChangeDate',
                order: 'DESC'
            }
        };
    }

    public expandWrapper = (toggle) => {
        this.expertView = toggle.expand;
    };
}

export class TemplateMachinePoolOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public template = require('./machine-pool-overview-template.html');
    public controller = TemplateMachinePoolOverviewController;
    public controllerAs = 'TemplateMachinePoolOverviewCtrl';
}
