import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import { AuthContextService } from '../../../../../services';
import * as Types from '../../../../../types';

import './database-config.scss';

export interface WizardDatabaseObject {
    capacity: number;
    createDatabase: boolean;
    orchestratorObject: Types.OrchestrationApi.Database;
    prices: any[];
}

export class OrganCreateConfigurationDatabaseController implements ng.IController {
    public static $inject: string[] = [];

    public userHasRightDbDatabaseCreate = AuthContextService.isGranted(UiRights.DB_DATABASE_CREATE);
    public databaseObject: WizardDatabaseObject;
    public databaseValidationOptions = {
        maximum: 10,
        minimum: 1
    };

    private _contingents: any;
    private _required = false;
    private _contingentsComparison: any[];

    public $onInit() {
        if (this._required) {
            // if _required isset, toogle in template will not shown.
            // So we set createDatabase to true
            this.databaseObject.createDatabase = true;
        }

        if ([undefined, null].indexOf(this.databaseObject) >= 0
            || Object.keys(this.databaseObject).length === 0
        ) {
            this._setDatabaseObject();
        }

        // set max ....
        this._setContingentValidations();
    }

    public $onChanges(changes: any) {
        if (changes._contingents !== undefined && changes._contingents.isFirstChange()) {
            this._contingentsComparison = ng.copy(this._contingents);
        }
    }

    public $doCheck() {
        if (JSON.stringify(this._contingents) !== JSON.stringify(this._contingentsComparison)) {
            // contingents has changed
            this._contingentsComparison = ng.copy(this._contingents);
            this._setContingentValidations();
        }
    }

    public get completed() {
        if (!this._required) {
            if (this.databaseObject.createDatabase) {
                return this._qtyIsValid();
            }
            return true;
        }

        return this.databaseObject.createDatabase
            && this._qtyIsValid();
    }

    public set completed({}) {} // tslint:disable-line:no-empty

    public get showCapacityIndication() {
        return this.databaseObject.createDatabase
            && this.databaseValidationOptions.maximum > 1;
    }

    private _setDatabaseObject = () => {
        this.databaseObject = {
            capacity: 1,
            createDatabase: false,
            orchestratorObject: {
                billingMode: 'standalone',
                password: '',
                productCode: '',
                storage: 0,
                type: 'Database',
                userName: ''
            },
            prices: []
        };
    };

    private _qtyIsValid = () => {
        return this.databaseObject.capacity >= this.databaseValidationOptions.minimum
            && this.databaseObject.capacity <= this.databaseValidationOptions.maximum;
    };

    private _setContingentValidations = () => {
        if ([undefined, null].indexOf(this._contingents) >= 0) {
            return;
        }
        this._contingents.some((contingent: any) => {
            if (contingent.service === 'database') {
                this.databaseValidationOptions.maximum = contingent.capacity;
                this.databaseObject.orchestratorObject.productCode = contingent.productCodes[0];
                if (this.databaseObject.capacity > contingent.capacity) {
                    // reset choosen capacity to contingent max, if capacity is greater than contingent max
                    this.databaseObject.capacity = contingent.capacity;
                }
                return true;
            }
            return false;
        });
    };
}

export class OrganCreateConfigurationDatabaseComponent implements ng.IComponentOptions {
    public bindings = {
        _contingents: '<?contingents',
        _required: '<?required',
        completed: '=',
        databaseObject: '='
    };

    public template = require('./database-config.html');
    public controller =  OrganCreateConfigurationDatabaseController;
}
