import * as ng from 'angular';
import { FormatMegabyteFilter } from '@/filters';
import { UI } from '@/types';

import './php-boost-changer.scss';

export class MoleculeFormPhpBoostChangerController implements ng.IController {
    public static $inject: string[] = [
        'formatMegabyteFilter'
    ];

    public oldTotal: number;
    public used: number;
    public unit: string;
    public step: number;
    public min: number;
    public max: number;
    public additionalItems: number;
    public productCode: string;
    public productType: string;
    public hideUsedSpace = false;
    public onChangeCallback: () => unknown;

    protected sliderOptions: UI.RzOptions;

    private cache: { [key: string]: number } = {};

    constructor(
        private formatMegabyte: FormatMegabyteFilter
    ) {}

    public $onInit(): void {
        this.additionalItems = this.additionalItems || 0;

        // Slider options
        // https://github.com/angular-slider/angularjs-slider
        // <!--
        const dynamicSliderColor = (value: number): string => {
            if (value <= ((this.min + this.max) / 3)) {
                return '#eb2f3b'; // red
            }
            if (value <= ((this.min + this.max) / 3 * 2)) {
                return '#f99a1c'; // orange
            }
            return '#10bd78'; // green
        };

        this.sliderOptions = {
            // Values
            ceil: this.max,
            disableAnimation: true,
            floor: this.min,
            showTicks: true,
            step: this.step,

            // Functions
            getPointerColor: dynamicSliderColor,
            getTickColor: dynamicSliderColor,
            onChange: () => {
                if (this.onChangeCallback) {
                    this.onChangeCallback();
                }
            },
            translate: (value: number|string) => {
                if (this.unit === 'MB') {
                    return this.formatMegabyte(value);
                }
                return (value as string) + ' ' + this.unit;
            }
        };
        // -->
    }

    public get model(): number {
        if (this.additionalItems === 0 || this.step === undefined) {
            return ng.copy(this.oldTotal);
        }

        if ([undefined, null].indexOf(this.cache[this.additionalItems]) >= 0) {
            this.cache[this.additionalItems] = ng.copy(this.oldTotal)
                + this.step * this.additionalItems;
        }
        return this.cache[this.additionalItems];
    }

    public set model(value: number) {
        if (this.step === undefined) {
            return;
        }
        this.additionalItems = (value - this.oldTotal) / this.step;
    }

    public get change(): number {
        return this.model - this.oldTotal;
    }
}

export class MoleculeFormPhpBoostChangerComponent implements ng.IComponentOptions {
    public bindings = {
        additionalItems: '=?',
        hideUsedSpace: '<?',
        max: '<',
        min: '<',
        model: '=?',
        oldTotal: '<',
        onChangeCallback: '<?',
        productCode: '<',
        productType: '@?',
        step: '<',
        unit: '@',
        used: '<'
    };
    public controller =  MoleculeFormPhpBoostChangerController;
    public template = require('./php-boost-changer.html');
}
