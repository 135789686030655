import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import {
    AuthContextService, DomainHelperService
} from '../../../../../services';

export class OrganEditPanelDomainBasicInfoController {
    public static $inject: string[] = [];

    public domain: any;
    public accountName: string;
    public bundleId: string;
    public domainType = '';
    public showBundleInfo: boolean;
    public showObjectId: boolean;

    public $onInit() {
        this.showBundleInfo = false;
        if (
            [null, undefined].indexOf(this.domain.bundleId) < 0
            && this.domain.bundleId !== ''
            && AuthContextService.isGranted(UiRights.BIL_BUNDLE_LIST)
        ) {
            this.bundleId = this.domain.bundleId;
        }
        if ([null, undefined].indexOf(this.bundleId) < 0 && this.bundleId !== '') {
            this.showBundleInfo = true;
            this.domainType = DomainHelperService.getDomainType(this.domain, this.bundleId);
        }
    }

    public $onChanges(changes: ng.IOnChangesObject) {
        if (
            changes.bundleId !== undefined
            && changes.bundleId.previousValue !== changes.bundleId.currentValue
        ) {
            if (
                AuthContextService.isGranted(UiRights.BIL_BUNDLE_LIST)
                && [null, undefined].indexOf(this.bundleId) < 0 && this.bundleId !== ''
            ) {
                this.showBundleInfo = true;
                this.domainType = DomainHelperService.getDomainType(this.domain, this.bundleId);
            }
        }
    }
}

export class OrganEditPanelDomainBasicInfoComponent implements ng.IComponentOptions {
    public bindings = {
        bundleId: '<?',
        domain: '<',
        showObjectId: '<'
    };
    public controller = OrganEditPanelDomainBasicInfoController;
    public template = require('./domain-basic-info.html');
}
