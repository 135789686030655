import { DnsWizardConfirmation } from '@/atomic-components';
import { DdnsModelService, DnsZoneModelService } from '@/services';
import * as ng from 'angular';
import { WizardCreateObject } from '../../general';

export class OrganismDnsWizardConfirmViewController {
    public static $inject: string[] = [
        '$translate',
        'ddnsModel',
        'dnsWizardConfirmation',
        'dnsZoneModel',
        'localInterval'
    ];

    public bundleId = '';
    public wizardCreateObjectList: WizardCreateObject[];
    public nextSteps: any[] = [];
    public isLoading = true;

    private _apiProductObject: any;

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private ddnsModel: DdnsModelService,
        public dnsWizardConfirmation: DnsWizardConfirmation,
        private dnsZoneModel: DnsZoneModelService,
        private localInterval: ng.IIntervalService
    ) {} // tslint:disable-line:no-empty

    public $onDestroy() {
        this.localInterval.cancel(this.dnsWizardConfirmation.checkJobStatusIntervalHandler);
        this.isLoading = false;
    }

    public $onInit() {
        this.isLoading = true;
        this.wizardCreateObjectList = [];
        this.dnsWizardConfirmation.selectedProductFamily = this._apiProductObject.productFamily;
        const apiObject = this._apiProductObject.apiObject;
        let createJob: WizardCreateObject;

        switch (this._apiProductObject.productFamily) {
            case 'dns-zone':
                this.dnsWizardConfirmation.useOrchestrationService = false;

                switch (this._apiProductObject.productCode) {
                    case 'slave':
                        for (const zoneConfig of apiObject.zoneConfig) {
                            this.wizardCreateObjectList.push({
                                callback: () => {
                                    if (this._apiProductObject.isRecreate) {
                                        return this.dnsZoneModel.updateSlave(zoneConfig);
                                    } else {
                                        return this.dnsZoneModel.createSlave(
                                            zoneConfig.name,
                                            zoneConfig.masterIp,
                                            {},
                                            zoneConfig.accountId
                                        );
                                    }
                                },
                                children: [],
                                labelText: `${zoneConfig.name} (${this.$translate.instant('TR_150920-aaebf8_TR')})`,
                                objectType: 'Zone',
                                additionalFlags: { isRecreate: this._apiProductObject.isRecreate }
                            });
                        }
                        break;

                    case 'expert':
                    case 'quick':
                    case 'copy':
                        for (const zoneConfig of apiObject.zoneConfig) {
                            this.wizardCreateObjectList.push({
                                callback: () => {
                                    if (this._apiProductObject.isRecreate) {
                                        return this.dnsZoneModel.reCreateNative(
                                            {
                                                accountId: zoneConfig.owner,
                                                name: zoneConfig.name,
                                                type: zoneConfig.type
                                            },
                                            zoneConfig.records,
                                            zoneConfig.nameserverSetId,
                                            zoneConfig.dnsSecOptions,
                                            zoneConfig.options,
                                            zoneConfig.type
                                        );
                                    } else {
                                        return this.dnsZoneModel.createNative(
                                            zoneConfig.name,
                                            zoneConfig.records,
                                            zoneConfig.options,
                                            zoneConfig.nameserverSetId,
                                            zoneConfig.type,
                                            zoneConfig.dnsSecOptions,
                                            zoneConfig.owner
                                        );
                                    }
                                },
                                children: [],
                                labelText: `${zoneConfig.name} `
                                    + `(${this.$translate.instant('97f4ed90-36d2-41fe-8e52-2455b38f7397')})`,
                                objectType: 'Zone',
                                additionalFlags: { isRecreate: this._apiProductObject.isRecreate }
                            });
                        }
                        break;

                    case 'template':
                        for (const zoneConfig of apiObject.zoneConfig) {
                            this.wizardCreateObjectList.push({
                                callback: () => {
                                    return this.dnsZoneModel.createWithTemplate(
                                        zoneConfig.name,
                                        zoneConfig.templateId,
                                        zoneConfig.tieTemplateToZone,
                                        zoneConfig.dnsTemplateReplacements,
                                        zoneConfig.nameserverSetId,
                                        {},
                                        zoneConfig.account.id,
                                        zoneConfig.records,
                                        this._apiProductObject.isRecreate
                                    );
                                },
                                children: [],
                                labelText: `${zoneConfig.name} `
                                    + `(${this.$translate.instant('97f4ed90-36d2-41fe-8e52-2455b38f7397')})`,
                                objectType: 'Zone',
                                additionalFlags: { isRecreate: this._apiProductObject.isRecreate }
                            });
                        }
                        break;

                    default:
                        throw new Error(
                            `${this._apiProductObject.productFamily} - Mode`
                            + `${this._apiProductObject.productFamily} is not implemented!`
                        );
                }
                break;

            case 'ddns-hostnames':
                if (apiObject.existingUser) {
                    createJob = {
                        callback:
                            () => {
                                return this.ddnsModel.createDdnsHost(
                                    apiObject.existingUserId,
                                    apiObject.fqdn,
                                    apiObject.comment,
                                    apiObject.productCode,
                                    apiObject.account.id
                                );
                            },
                        children: [],
                        labelText: this.$translate.instant('TR_280920-c5e339_TR'),
                        objectType: 'DynamicDnsHostname'
                    };
                } else {
                    createJob = {
                        callback:
                            () => {
                                return this.ddnsModel.createDdnsUser(apiObject.newUser, apiObject.password);
                            },
                        children: [
                            {
                                callback: (ddnsUserResults) => {
                                    const ddnsUserId = ddnsUserResults.response.id;
                                    return this.ddnsModel.createDdnsHost(
                                        ddnsUserId,
                                        apiObject.fqdn,
                                        apiObject.comment,
                                        apiObject.productCode,
                                        apiObject.account.id
                                    );
                                },
                                children: [],
                                labelText: this.$translate.instant('TR_280920-c5e339_TR'),
                                objectType: 'DynamicDnsHostname'
                            }
                        ],
                        labelText: this.$translate.instant('TR_280920-34da90_TR'),
                        objectType: 'DynamicDnsCredentials'
                    };
                }

                this.wizardCreateObjectList.push(createJob);
                break;

            default:
                throw new Error(`Product Family ${this._apiProductObject.productFamily} is not implemented!`);
        }
        this.isLoading = false;
    }
}

export class OrganismDnsWizardConfirmViewComponent implements ng.IComponentOptions {
    public bindings = {
        _apiProductObject: '<apiProductObject',
        wizardCreateObjectList: '<'
    };

    public template = require('./dns-wizard-confirm-view.html');
    public controller = OrganismDnsWizardConfirmViewController;
}
