import * as ng from 'angular';
import { GetEntryPointService } from '../../../../services';
import './main-logo.scss';

class AtomLogoMainController implements ng.IController {
    public static $inject: string[] = ['getEntryPoint'];

    public logoPath: string;
    private lastEntryPoint: string;

    constructor(
        private getEntryPoint: GetEntryPointService
    ) {}

    public $onInit() {
        this.logoPath = this.getEntryPoint.logoPath;
    }
}

export class AtomLogoMainComponent implements ng.IComponentOptions {
    public controller = AtomLogoMainController;
    public template = require('./main-logo.html');
}
