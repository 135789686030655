import { DocumentHelperService } from '../../../../services';
import { LinkListItem } from '../../links/';

import * as ng from 'angular';

export class MoleculePanelServiceInfoBillingInvoiceController implements ng.IController {
    public static $inject: string[] = ['documentHelper', '$translate'];

    public linkList: LinkListItem[];
    private invoice;

    constructor(
        private documentHelper: DocumentHelperService,
        protected $translate: ng.translate.ITranslateService
    ) {
        this.linkList = [
            {
                text: this.$translate.instant('TR_100119-c204ce_TR'),
                onclickMethode: this.pdfDownloadInvoice
            }, {
                text: this.$translate.instant('TR_100119-44cf9d_TR'),
                onclickMethode: this.csvDownloadInvoice
            }
        ];
    }

    public pdfDownloadInvoice = () => {
        this.documentHelper.pdfDownloadInvoice(this.invoice);
    };

    public csvDownloadInvoice = () => {
        this.documentHelper.csvDownloadInvoice(this.invoice);
    };
}

export class MoleculePanelServiceInfoBillingInvoiceComponent implements ng.IComponentOptions {
    public bindings = {
        invoice: '<'
    };
    public controller = MoleculePanelServiceInfoBillingInvoiceController;
    public controllerAs = '$panelServiceInfo';
    public template = require('./panel-service-info-billing-invoice.html');
}
