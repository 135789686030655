import {
    TemplateBillingPricelistAbstractOverviewComponent,
    TemplateBillingPricelistAbstractOverviewController
} from '../abstract/pricelist-abstract-template';

export class TemplateBillingPricelistDnsOverviewController extends TemplateBillingPricelistAbstractOverviewController {
    protected options = {
        panelTitleTranslationId: /* translationId */ 'TR_080619-d0fcc9_TR',
        simpleFilterField: 'productName'
    };

    public expandWrapper = (toggle) => {
        this.expertView = toggle.expand;
    };
}

export class TemplateBillingPricelistDnsOverviewComponent extends TemplateBillingPricelistAbstractOverviewComponent {
    public controller = TemplateBillingPricelistDnsOverviewController;
    public template = require('./pricelist-dns-template.html');
}
