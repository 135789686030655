import * as ng from 'angular';

import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { AuthContextService, NavigationService } from '../../../services';
import * as Types from '../../../types';

export class TemplateUserEditController implements ng.IController {
    public static $inject: string[] = ['$state', '$translate', 'navigation'];

    public userData: Types.AccountApi.User;
    public apiKeys: any;
    public pageHeaderData: PanelHeaderData;
    public allRights: any[];

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.pageHeaderData = {
            backwardLink: this.$state.current.name.split('.').indexOf('subaccounts') < 0
                ? 'account.users.overview'
                : 'reseller.subaccounts.id.edit',

            backwardText: this.$state.current.name.split('.').indexOf('subaccounts') < 0
                ? this.$translate.instant('TR_090119-9aec59_TR')
                : this.$translate.instant('TR_280219-5dacf4_TR'),

            panelHeaderRoute: this.$state.current.name.split('.').indexOf('subaccounts') < 0
                ? 'account.users.overview'
                : 'reseller.subaccounts.id.edit',
            panelIcon: 'user',
            panelTitle: this.userData.name,
            productActions: []
        };

        if (AuthContextService.isAdminUser && this.$state.current.name.split('.').indexOf('subaccounts') < 0) {
            this.pageHeaderData.productActions.push({
                route: 'account.users.id.admin',
                title: this.$translate.instant('TR_091019-a26695_TR')
            });
        }
    }
}

export class TemplateUserEditComponent implements ng.IComponentOptions {
    public bindings = {
        allRights: '<',
        overviewUiSettings: '<',
        rightsTemplates: '<',
        userData: '<'
    };
    public controller =  TemplateUserEditController;
    public template = require('./user-edit.html');
}
