import * as ng from 'angular';
import * as q from 'q';
import {
    AlertManagerService,
    NavigationService,
    VhostModelService,
    WebspaceModelService
} from '../../../../../services';
import * as Types from '../../../../../types';
import { UI } from '../../../../../types';

export class OrganismEditFormWebspacePhpBoostController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'navigation',
        'vhostModel',
        'webspaceModel'
    ];

    public originalWebspace: any;
    public webspace: Types.WebhostingApi.Webspace;
    public userPanelRight: any;
    public boostData: UI.phpBoostOptionsInterface;
    public additionalStorageItems = 0;
    public vhosts: Types.WebhostingApi.VHost[] = [];
    public vHostsToAdjust: { [vHostId: string]: boolean } = {};
    private initalExecutionTime: number;
    private initalMemoryLimit: number;
    public billingCycle: number;
    public selectedLegacyPHPProduct: string|null = null;
    public legacyProductPriceAccepted = false;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private navigation: NavigationService,
        private vhostModel: VhostModelService,
        private webspaceModel: WebspaceModelService
    ) {}

    public $onInit = () => {
        this.webspace = ng.copy(this.originalWebspace);
        this.initalExecutionTime = ng.copy(this.boostData.phpExecutionTime.current);
        this.initalMemoryLimit = ng.copy(this.boostData.phpMemoryLimit.current);

        this.vhostModel.listWithoutPagination(
            11,
            1,
            { field: 'WebspaceId', value: this.webspace.id }
        )
        .then((response) => this.vhosts = response.data);
    };

    public savePHPAddons = () => {
        this.webspaceModel.update(this.webspace, -1)
        .then(
            () => {
                let vHosts = [];

                switch (this.vhosts.length) {
                    case 1:
                        vHosts = this.vhosts;
                        break;

                    case 11: break;

                    default:
                        vHosts = this.vhosts.filter((vHost) => this.vHostsToAdjust[vHost.id]);
                        break;
                }

                return q.all(
                    vHosts
                    .map(
                        (vHost) => this.vhostModel.vhostPhpIniList(vHost.id)
                        .then(
                            (phpIni: Types.WebhostingApi.PhpIni) => {
                                phpIni.values = phpIni.values.map(
                                    (value) => {
                                        switch (value.key) {
                                            case 'memory_limit':
                                                value.value = `${this.webspace.phpMemory}M`;
                                                break;

                                            case 'max_execution_time':
                                                value.value = `${this.webspace.phpExecutionTime}`;
                                                break;

                                            default: break;
                                        }

                                        return value;
                                    }
                                );

                                return this.vhostModel.update(vHost, phpIni);
                            }
                        )
                    )
                );
            }
        )
        .then(
            () => {
                this.alertManager.success(this.$translate.instant('TR_170619-86e539_TR'));
                this.navigation.reloadCurrentState();
            }
        );
        this.originalWebspace = ng.copy(this.webspace);
    };

    public saveLegacyPHP = () => {
        if (this.selectedLegacyPHPProduct !== this.originalWebspace.legacyPhpAddonProductCode
            && this.legacyProductPriceAccepted === true
        ) {
            this.webspace.legacyPhpAddonProductCode = this.selectedLegacyPHPProduct;
            this.webspaceModel.update(this.webspace, -1)
                .then(
                    () => {
                        this.alertManager.success(this.$translate.instant('TR_170619-86e539_TR'));
                        this.originalWebspace = ng.copy(this.webspace);
                        this.navigation.reloadCurrentState();
                    }
                )
            ;
            this.alertManager.success(this.$translate.instant('TR_100123-98ecde_TR'));
            this.navigation.reloadCurrentState();
        } else {
            this.alertManager.info(this.$translate.instant('TR_100123-0b38a6_TR'));
            this.navigation.reloadCurrentState();
        }
    };

    public cancelStorage = () => {
        this.boostData.phpExecutionTime.current = ng.copy(this.initalExecutionTime);
        this.boostData.phpMemoryLimit.current = ng.copy(this.initalMemoryLimit);
        this.webspace.storageQuota = this.originalWebspace.storageQuota;
        Object.keys(this.boostData).forEach((boostOption) => {
            this.boostData[boostOption].orderAmount = 0;
        });
    };

    public cancelLegacyPhp = () => {
        this.selectedLegacyPHPProduct = this.originalWebspace.legacyPhpAddonProductCode;
        this.legacyProductPriceAccepted = false;
    };
}

export class OrganismEditFormWebspacePhpBoostComponent implements ng.IComponentOptions {
    public bindings = {
        boostData: '=',
        originalWebspace: '<webspace',
        userPanelRight: '=',
        billingCycle: '<'
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormWebspacePhpBoostController;
    public template = require('./webspace-php-boost-edit.html');
}
