import * as ng from 'angular';
import {
    AuthContextService,
    BillingRobotService,
    DateWrapper,
    DocumentModelService,
    UserModelService
} from '../../../../../services';

export class MoleculePanelEditRowSepaController implements ng.IController {
    public static $inject: string[] = ['authContext', 'billingRobot', 'documentModel', 'userModel'];

    public validationErrorList: any[];
    public sepaData: { iban: string; bic: string };
    public hasOpenInvoices = false;
    public usersDateOfBirthIsSet = false;
    public usersDateOfBirth: DateWrapper = new DateWrapper();
    public amountOpenInvoices = 0;
    public bankAccount: any;
    public readonlyMode: boolean;
    public loadData: boolean;
    public dateSelectMinYear: number;
    public dateSelectMaxYear: number;

    public isCurrentlyPrepaid = this.authContext.billingSettings.paymentType === 'prepaid';

    private bicObsoleteCountries: string[] = [];

    constructor(
        private authContext: AuthContextService,
        private billingRobot: BillingRobotService,
        private documentModel: DocumentModelService,
        private userModel: UserModelService
    ) {
        const today = new DateWrapper(new Date());
        this.dateSelectMinYear = today.year - 120;
        this.dateSelectMaxYear = today.year - 15;

        this.billingRobot.directDebitCountriesFind()
            .then(
                (result) => this.bicObsoleteCountries = result.response.data
                    .filter((country) => !country.bicRequired)
                    .map((country) => country.country)
            );
    }

    public $onInit() {
        this.userModel.findOne(this.authContext.user.id).then(
            (response) => {
                this.usersDateOfBirthIsSet = [null, undefined].indexOf(response.dateOfBirth) === -1;
                if (this.usersDateOfBirthIsSet) {
                    this.usersDateOfBirth.dateObj = new Date(response.dateOfBirth);
                }
            }
        );
        this.documentModel.getUnpaidInvoices().then(
            (response) => {
                this.amountOpenInvoices = response.pagination.entries;
                this.hasOpenInvoices = response.pagination.entries > 0;
            }
        );
    }

    public get bicIsRequired() {
        if (this.sepaData.iban.length < 2) {
            return false;
        }

        return this.bicObsoleteCountries.every(
            (country) => this.sepaData.iban.indexOf(country) !== 0
        );
    }
}

export class MoleculePanelEditRowSepaComponent implements ng.IComponentOptions {
    public bindings = {
        loadData: '<',
        readonlyMode: '=',
        sepaData: '=',
        usersDateOfBirth: '=',
        usersDateOfBirthIsSet: '=',
        validationErrorList: '='
    };
    public controller = MoleculePanelEditRowSepaController;
    public template = require('./panel-edit-row-sepa.html');
}
