import * as ng from 'angular';
import './circle-value.scss';

export class AtomCircleValueController implements ng.IController {
    public static $inject: string[] = ['$sce'];

    public circleValue = '';

    constructor(private $sce: ng.ISCEService) {}

    public trustAsHtml = () => {
        if (this.circleValue !== undefined) {
            return this.$sce.trustAsHtml(this.circleValue);
        }
        return '';
    };
}

export class AtomCircleValueComponent implements ng.IComponentOptions {
    public bindings = {
        circleValue: '@'
    };

    public template = '<div class="flex-circle-value" ng-bind-html="$ctrl.trustAsHtml()"></div>';
    public controller = AtomCircleValueController;
}
