import * as ng from 'angular';

export class MoleculePanelCreateUserController implements ng.IController {
    public static $inject: string[] = [];
    public showGeneratedUser = true;
}

export class MoleculePanelCreateUserComponent implements ng.IComponentOptions {
    public bindings = {
        showGeneratedUser: '<?',
        name: '=',
        password: '='
    };
    public template = require('./panel-create-user.html');
    public controller = MoleculePanelCreateUserController;
}
