import * as ng from 'angular';
import './text-chip.scss';

export class AtomTextChipController implements ng.IController {
    public static $inject: string[] = [];

    public content: string;
    public isRemovable = true;
    public isActive = true;
    public callbackOnClose: (arg0: any) => any;
    public callbackOnCloseParam: any;

    public deactivate = () => {
        this.isActive = false;

        if ([undefined, null].indexOf(this.callbackOnClose) < 0) {
            this.callbackOnClose(this.callbackOnCloseParam);
        }
    };
}

export class AtomTextChipComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        callbackOnClose: '<',
        callbackOnCloseParam: '<',
        content: '<',
        isActive: '=',
        isRemovable: '<'
    };
    public template = require('./text-chip.html');
    public controller =  AtomTextChipController;
}
