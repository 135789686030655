import * as ng from 'angular';
import './drop-down-menu.scss';

export class MoleculeFormDropDownMenuController implements ng.IController {
    public static $inject: string[] = [];

    public showOptions = false;
    public popoverModifier: string;
    public overviewLoad: () => any;
    public hideChevron: boolean;
    public direction: string;
    public sizeModifier: string;
    public disabled = false;

    public toggleMenu() {
        if (!this.disabled) {
            this.showOptions = !this.showOptions;
        }
    }

    public outerElementClick() {
        if (this.showOptions) {
            this.showOptions = false;
        }
    }
}

export class MoleculeFormDropDownMenuComponent implements ng.IComponentOptions {
    public bindings = {
        direction: '@',
        disabled: '<',
        hideChevron: '<',
        overviewLoad: '&',
        popoverModifier: '@',
        sizeModifier: '@'
    };
    public transclude = {
        body: 'dropDownBody',
        head: 'dropDownHead'
    };
    public controller =  MoleculeFormDropDownMenuController;
    public template = require('./drop-down-menu.html');
}
