import * as q from 'q';

import {
    LinkListOverviewItem
} from '../../atomic-components/molecules/links/link-list-overview/link-list-overview';
import {
    AccountFilterField, SelectFilterField, TextFilterField
} from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { UiRights } from '../../configuration';
import { DomainApi } from '../../types';
import { OverviewModel } from '../abstract';
import { AuthContextService } from '../auth-context';
import { DateWrapper } from '../date';
import { RedirectionModelService } from '../domain/redirection-model';
import { RequestCancellerService } from '../request-canceller';

export class RedirectionOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat([
        '$translate',
        'objectTypeFilter',
        'redirectionModel'
    ]);

    public readonly service = 'redirections';
    public readonly idField = 'redirectionId';
    public readonly objectType = 'Redirection';
    public readonly defaultFilterFields = ['redirectionDomainName', 'redirectionRedirectionUrl'];

    public activeMassAction: string | boolean = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};

    protected listCallbacks = [{callback: this.redirectionModel.list}];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private objectTypeFilter,
        private redirectionModel: RedirectionModelService
    ) {
        super(RequestCanceller);

        // declare multi action availably methods
        this.actionLinksAvailabilities = {
            deletableRedirects: this.deletableRedirects
        };
    }

    public actionLinks = (): LinkListOverviewItem[] => {
        return [
            {
                isGranted: AuthContextService.isGrantedAny([UiRights.DOM_DOMAINS_DELETE]),
                linkList: [
                    {
                        group: 'delete',
                        groupName: this.$translate.instant('TR_140119-aee71e_TR'),
                        isClickable: 'deletableRedirects',
                        isGranted: AuthContextService.isGranted(UiRights.DOM_DOMAINS_DELETE),
                        onclickMethode: () => this.activeMassAction = 'delete',
                        route: '',
                        text: this.$translate.instant('TR_051119-7a861c_TR')
                    }
                ],
                onlyOnExperView: true,
                route: '',
                text: this.$translate.instant('TR_140119-080106_TR'),
                type: 'multi'
            },
            {
                route: 'domain.redirections.new',
                text: this.$translate.instant('WEBHOSTING.VHOST.REDIRECT.NEW.TITLE')
            }
        ];
    };

    public deletableRedirects = (redirections: any[]): boolean => {
        for (const redirection of redirections) {
            if (
                this.selected[redirection.id] && ['ordered', 'restorable'].indexOf(redirection.status) < 0
                    && [undefined, null].indexOf(redirection.deletionScheduledFor) >= 0
            ) {
                return true;
            }
        }
        return false;
    };

    public deleteSubmit = (redirections: DomainApi.Domain[], settings: any) => {
        let promises = [];

        const deletableRedirections = redirections
            .filter((redirection) => [undefined, null, false].indexOf(this.selected[redirection.id]) < 0)
            .filter((redirection) => ['ordered', 'restorable'].indexOf(redirection.status) < 0)
            .filter((redirection) => [undefined, null, ''].indexOf(redirection.deletionScheduledFor) >= 0);

        promises = deletableRedirections.map(
            (domain) => {
                const execDate = this._getDeletionDate(domain, settings);
                return this.redirectionModel.deleteOnePlaned(domain, execDate);
            }
        );

        return q.all(promises);
    };

    private _getDeletionDate = (domain: any, settings: any) => {
        if (settings.actiontype === 'instant') {
            return new DateWrapper(new Date());
        }
        if (settings.actiontype === 'endOfContract') {
            return new DateWrapper(domain.currentContractPeriodEnd);
        }
        if (settings.actiontype === 'date') {
            return settings.deleteDate.dateObj;
        }
    };

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('redirectionDomainName', this.$translate.instant('07445a86-7211-449f-bfa1-d761f73e8786')),
                new TextFilterField('redirectionRedirectionUrl', this.$translate.instant('abb61689-d9e2-4ad9-a40d-8d7eeb18f078')),
                new AccountFilterField('accountId', this.$translate.instant('TR_140119-b3b187_TR')),
                new SelectFilterField(
                    'redirectionType',
                    this.$translate.instant('TR_070519-16189c_TR'),
                    [
                        {
                            name: this.objectTypeFilter(/* translationId */ 'TR_110119-c2483c_TR'),
                            value: '301'
                        },
                        {
                            name: this.objectTypeFilter(/* translationId */ 'TR_160119-e7a06c_TR'),
                            value: '302'
                        },
                        {
                            name: this.objectTypeFilter(/* translationId */ 'TR_160119-3e125f_TR'),
                            value: 'frame'
                        }
                    ]
                )
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }
}
