import * as ng from 'angular';
import {
    OrganismDashboardOverviewsController,
    OrganismOverviewController,
    OrganismPricelistOverviewController
} from '../../../../../organisms/overview';

import './overview-row-panel-overlay.scss';

export class MoleculeOverviewRowPanelOverlayController implements ng.IController {
    public static $inject: string[] = [];

    // tslint:disable-next-line:max-line-length
    public overviewCtrl: OrganismOverviewController | OrganismPricelistOverviewController | OrganismDashboardOverviewsController;
    private OrganismOverview: OrganismOverviewController;
    private OrganismPricelistOverviewController: OrganismPricelistOverviewController;
    private OrganismDashboardOverview: OrganismDashboardOverviewsController;

    public $onInit() {
        this._setOverviewCrtl();
    }

    public get showOverlay() {
        return !this.overviewCtrl.overviewLoaded;
    }

    private _setOverviewCrtl = () => {
        if ([null, undefined].indexOf(this.OrganismPricelistOverviewController) === -1) {
            this.overviewCtrl = this.OrganismPricelistOverviewController;
        } else if ([null, undefined].indexOf(this.OrganismDashboardOverview) === -1) {
            this.overviewCtrl = this.OrganismDashboardOverview;
        } else {
            this.overviewCtrl = this.OrganismOverview;
        }
    };
}

export class MoleculeOverviewRowPanelOverlayComponent implements ng.IComponentOptions {
    public require = {
        OrganismDashboardOverview: '?^organismDashboardOverviews',
        OrganismOverview: '?^organismOverview',
        OrganismPricelistOverviewController: '?^organismPricelistOverview'
    };
    public controller =  MoleculeOverviewRowPanelOverlayController;
    public template = require('./overview-row-panel-overlay.html');
}
