import * as ng from 'angular';
import { LongDebounceInterval, UiRights } from '../../../../configuration';
import { AuthContextService, LocalstorageHelperService } from '../../../../services';
import { OrganismOverviewController, OrganismPricelistOverviewController } from '../../../organisms/overview';
import { FilterField } from './filter-field.class';

import './fancy-filter-fields.scss';

export interface AdditionalFancyFilterFieldRouteObject {
    field: string;
    value: string|number|[];
}

export class MoleculeFancyFilterFieldsController implements ng.IController {
    public static $inject: string[] = ['$rootScope', 'authContext', 'localstorageHelper'];

    public longDebounceInterval = LongDebounceInterval;
    private $overviewOrganism: OrganismOverviewController;
    private $pricelistOverviewOrganism: OrganismPricelistOverviewController;
    private _availableFilters: FilterField<any>[] = [];
    private _overviewLoad;
    private _overviewOrganism;

    constructor(
        private $rootScope: ng.IRootScopeService,
        private authContext: AuthContextService,
        private localstorageHelper: LocalstorageHelperService
    ) {}

    public $onInit() {
        this._setOverviewOrganismData();
        if (!this.authContext.isGranted(UiRights.ACC_SUBACCOUNT_LIST)) {
            this._availableFilters = this._availableFilters
            .filter(
                (availableFilter) => availableFilter.type !== 'accounts'
            );
        }
        if (!this.authContext.isGranted(UiRights.DOM_CONTACTS_LIST)) {
            this._availableFilters = this._availableFilters
            .filter(
                (availableFilter) => availableFilter.type !== 'contacts'
            );
        }
        this.$rootScope.$on(
            '$stateChangeStart',
            () => {
                this.localstorageHelper.setSearchFiltersForCurrentRoute(
                    this._overviewOrganism.overviewData.filters,
                    this._availableFilters
                );
            }
        );
    }

    public get fields(): FilterField<any>[] {
        return this._availableFilters;
    }

    public reset = () => {
        for (const filter of this._availableFilters) {
            filter.value = null;
        }

        this.apply();
    };

    public apply = () => {
        this._overviewLoad(false, false, true);
    };

    private _setOverviewOrganismData = () => {
        if ([null, undefined].indexOf(this.$overviewOrganism) >= 0) {
            this._availableFilters = this.$pricelistOverviewOrganism.overviewServiceData.availableFilters;
            this._overviewLoad = this.$pricelistOverviewOrganism.overviewLoad;
            this._overviewOrganism = this.$pricelistOverviewOrganism;
        } else {
            this._availableFilters = this.$overviewOrganism.overviewModel.availableFilters;
            this._overviewLoad = this.$overviewOrganism.overviewLoad;
            this._overviewOrganism = this.$overviewOrganism;
        }

        // get last user search from localstorage
        const localStorageData = this.localstorageHelper.getLastSearchSettingsForCurrentUser();

        if (localStorageData !== null && this._availableFilters !== null) {
            this._availableFilters.forEach((filter, index) => {
                const specificFilter = localStorageData.fancyFilters.filter(
                    (savedFilter) => savedFilter.filterField === filter.filterField
                );

                if (specificFilter[0] && specificFilter[0]._value !== undefined) {
                    this._availableFilters[index].value = specificFilter[0]._value;
                }
            });

            this._overviewLoad(false, false, true);
        }
    };
}

export class MoleculeFancyFilterFieldsComponent implements ng.IComponentOptions {
    public require = {
        $overviewOrganism: '?^organismOverview',
        $pricelistOverviewOrganism: '?^organismPricelistOverview'
    };

    public controller =  MoleculeFancyFilterFieldsController;
    public controllerAs = '$fancyFilter';
    public template = require('./fancy-filter-fields.html');
}
