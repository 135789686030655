import { AuthContextService } from '../auth-context';
import { CacheService } from '../cache';
import { NavigationService } from '../navigation';
import {
    RpcClient,
    RpcClientService
} from '../rpc-client';

import * as q from 'q';
import * as Types from '../../types';

export const AccountClientService = [
    'rpcClient',
    (platformRpcClient: RpcClientService) => platformRpcClient.Factory('/api/account/v1/json/', 'account')
];

export class UserRobotService {
    public static $inject: string[] = ['accountClient', 'cache', 'navigation'];

    constructor(
        private accountClient: RpcClient,
        private cache: CacheService,
        private navigation: NavigationService
    ) {}

    public clearCache = () => {
        this.cache.get('account::subaccountsFind').clear();
        return this;
    };

    public listAccounts = (
        filter?: Types.Finding.Filter,
        limit?: number,
        page?: number,
        sort?: Types.Finding.SortOptions
    ) => {
        return this.accountClient.request('subaccountsFind')
        .useCache()
        .cacheTimeout(1000 * 60 * 15)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public updateAccount = (account: Types.AccountApi.Account) => {
        return this.accountClient.request('accountUpdate')
            .execute({ account: account });
    };

    public deleteAccount: () => q.IPromise<void> = () => {
        return this.accountClient.request('accountDelete').execute({});
    };

    public listSubaccounts = (
        filter?: Types.Finding.Filter,
        limit?: number,
        page?: number,
        sort?: Types.Finding.SortOptions
    ) => {
        return this.accountClient.request('subaccountsFind')
        .useCache()
        .cacheTimeout(1000 * 60 * 15)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public listSubaccountsWithoutPagination = (
        filter?: Types.Finding.Filter,
        limit?: number,
        page?: number,
        sort?: Types.Finding.SortOptions
    ) => {
        return this.accountClient.request('subaccountsFind')
        .useCache()
        .cacheTimeout(1000 * 60 * 15)
        .skipPagination()
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public createSubaccount = (
        subaccount: Types.AccountApi.Subaccount,
        user: Types.AccountApi.User,
        password: string
    ) => {
        return this.accountClient.request('subaccountCreate')
        .bustCache('account', 'subaccountsFind')
        .execute({ subaccount: subaccount, adminUser: user, password: password });
    };

    public getOwnUserFlags = () => {
        return this.accountClient.request('getOwnUserFlags')
        .useCache()
        .cacheTimeout(1000 * 60 * 60)
        .execute({});
    };

    public setOwnUserFlag = (key: string, value: string) => {
        return this.accountClient.request('setOwnUserFlag')
        .bustCache('account', 'getOwnUserFlags')
        .execute({ userFlag: { key: key, value: value} });
    };

    public updateSubaccount = (subaccount: Types.AccountApi.Subaccount) => {
        return this.accountClient.request('subaccountUpdate')
        .bustCache('account', 'subaccountsFind')
        .execute({ subaccount: subaccount });
    };

    public deleteSubaccount = (subaccountId: string) => {
        return this.accountClient.request('subaccountDelete')
        .bustCache('account', 'subaccountsFind')
        .execute({ subaccountId: subaccountId });
    };

    public enableSubaccount = (subaccountId: string) => {
        return this.accountClient.request('subaccountEnable')
        .bustCache('account', 'subaccountsFind')
        .execute({ subaccountId: subaccountId });
    };

    public disableSubaccount = (subaccountId: string) => {
        return this.accountClient.request('subaccountDisable')
        .bustCache('account', 'subaccountsFind')
        .execute({ subaccountId: subaccountId });
    };

    public listUsers = (
        filter?: Types.Finding.Filter,
        limit?: number,
        page?: number,
        sort?: Types.Finding.SortOptions
    ) => {
        return this.accountClient.request('usersFind')
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public listUsersWithoutPagination = (
        filter?: Types.Finding.Filter,
        limit?: number,
        page?: number,
        sort?: Types.Finding.SortOptions
    ) => {
        return this.accountClient.request('usersFind')
        .skipPagination()
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public createUser = (
        user: Types.AccountApi.User,
        password: string,
        owner: string
    ) => {
        return this.accountClient.request('userCreate')
        .ownerAccountId(owner)
        .execute(
            {
                password: password,
                user: user
            }
        );
    };

    public updateUser = (
        user: Types.AccountApi.User,
        oldPassword: string,
        newPassword: string
    ) => {
        return this.accountClient.request('userUpdate')
        .execute(
            {
                executingUserPassword: oldPassword,
                newPassword: newPassword,
                user: user
            }
        );
    };

    public updateOwnProfile = (profile: Types.AccountApi.UserProfile) => {
        return this.accountClient.request('userSetOwnProfile').execute({ profile: profile });
    };

    public userSetOwnPassword = (oldPassword: string, newPassword: string) => {
        return this.accountClient.request('userSetOwnPassword')
        .execute(
            {
                newPassword: newPassword,
                oldPassword: oldPassword
            }
        );
    };

    public userSetDashboardConfiguration = (uiMainDashboardConfiguration: string) => {
        return this.accountClient
            .request('userSetOwnUiMainDashboardConfiguration')
            .execute({
                uiMainDashboardConfiguration: uiMainDashboardConfiguration
            });
    };

    public userGetDashboardConfiguration = () => {
        return this
            .accountClient.request('getUsersUiMainDashboardConfiguration')
            .execute({});
    };

    public deleteUser = (userId: string) => {
        return this.accountClient.request('userDelete').execute({ userId: userId });
    };

    public listApiKeys = (
        filter?: Types.Finding.Filter,
        limit?: number,
        page?: number,
        sort?: Types.Finding.SortOptions
    ) => {
        return this.accountClient.request('apikeysFind')
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public listApiKeysWithoutPagination = (
        filter?: Types.Finding.Filter,
        limit?: number,
        page?: number,
        sort?: Types.Finding.SortOptions
    ) => {
        return this.accountClient.request('apikeysFind')
        .skipPagination()
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public createApiKey = (apiKey: Types.AccountApi.ApiKey) => {
        return this.accountClient.request('apiKeyCreate').execute({ apiKey: apiKey });
    };

    public updateApiKey = (apiKey: Types.AccountApi.ApiKey) => {
        return this.accountClient.request('apiKeyUpdate').execute({ apiKey: apiKey });
    };

    public deleteApiKey = (apiKeyId: string) => {
        return this.accountClient.request('apiKeyDelete').execute({ apiKeyId: apiKeyId });
    };

    public listRights = (language: string) => {
        return this.accountClient.request('getUsersRightsTree').execute({ language: language });
    };

    public listRightsForSubaccount = (parentAccountId: string, language: string) => {
        return this.accountClient.request('getAvailableRightsTreeForSubaccount')
        .execute(
            {
                language: language,
                parentAccountId: parentAccountId
            }
        );
    };

    public listRightsForUser = (accountId: string, language: string) => {
        return this.accountClient.request('getAvailableRightsTreeForUser')
        .execute(
            {
                language: language,
                accountId: accountId
            }
        );
    };

    public listRightsForApiKey = (userId: string, language: string) => {
        return this.accountClient.request('getAvailableRightsTreeForApiKey')
        .execute(
            {
                language: language,
                userId: userId
            }
        );
    };

    public getAccountBasicData = () => {
        return this.accountClient.request('getOwnAccount').execute({});
    };

    public getUserBasicData = () => {
        return this.accountClient.request('getOwnUser').execute({});
    };

    public getAccountSettings = (owner?: string) => {
        let promise: q.IPromise<any>;
        if (owner && owner !== AuthContextService.account.id) {
            promise = this.listSubaccounts({ field: 'subaccountId', value: owner })
            .then(
                (result) => {
                    return {
                        response: result.response.data[0]
                    };
                }
            );
        } else {
            promise = this.getAccountBasicData();
        }

        return promise.then((res) => {
            return {
                billingSettings: res.response.billingSettings,
                dnsSettings: res.response.dnsSettings,
                domainSettings: res.response.domainSettings,
                messageSettings: res.response.messageSettings,
                whiteLabelSettings: res.response.whiteLabelSettings
            };
        });
    };

    public loginAsSubAccount = (accountId) => {
        return this.accountClient.request('loginAsSubaccount')
        .bustAllCaches()
        .execute({ accountId: accountId })
        .then(
            (result) => {
                if (
                    (
                        result.response.account !== undefined
                        && result.response.account.enabled === undefined
                    )
                    || result.response.account.enabled
                ) {
                    if ([undefined, null].indexOf(result.response.user) >= 0) {
                        this.navigation.toLogin();
                        return;
                    }

                    AuthContextService.resetContextFromResponse(result.response);
                }
            }
        );
    };

    public logoutOfSubAccount = () => {
        return this.accountClient.request('logoutOfSubaccount')
        .bustAllCaches()
        .execute({})
        .then(
            (result) => {
                if (
                    (
                        result.response.account !== undefined
                        && result.response.account.enabled === undefined
                    )
                    || result.response.account.enabled
                ) {
                    if ([undefined, null].indexOf(result.response.user) >= 0) {
                        this.navigation.toLogin();
                        return;
                    }

                    AuthContextService.resetContextFromResponse(result.response);
                }
            }
        );
    };

    public getUserOwnUiSettings = () => {
        return this.accountClient.request('getUsersUiSettings')
        .useCache()
        .cacheTimeout(1000 * 60 * 60)
        .execute({});
    };

    public setUserOwnUiSettings = (uiSettings) => {
        return this.accountClient.request('userSetOwnUiSettings')
        .bustCache('account', 'getUsersUiSettings')
        .execute({ uiSettings: uiSettings });
    };

    public listRightsTemplate = (
        filter?: Types.Finding.Filter,
        limit?: number,
        page?: number,
        sort?: Types.Finding.SortOptions
    ) => {
        return this.accountClient.request('rightsTemplatesFind')
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public listRightsTemplateWithoutPagination = (
        filter?: Types.Finding.Filter,
        limit?: number,
        page?: number,
        sort?: Types.Finding.SortOptions
    ) => {
        return this.accountClient.request('rightsTemplatesFind')
        .skipPagination()
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public createRightsTemplate = (rightsTemplate: Types.AccountApi.RightsTemplate) => {
        return this.accountClient.request('rightsTemplateCreate').execute({ rightsTemplate: rightsTemplate });
    };

    public updateRightsTemplate = (rightsTemplate: Types.AccountApi.RightsTemplate) => {
        return this.accountClient.request('rightsTemplateUpdate').execute({ rightsTemplate: rightsTemplate });
    };

    public deleteRightsTemplate = (rightsTemplateId: string) => {
        return this.accountClient.request('rightsTemplateDelete').execute({ rightsTemplateId: rightsTemplateId });
    };

    public userRegenerateTwoFactorAuthenticationOneTimePasswords = (password: string) => {
        return this.accountClient.request('userRegenerateTwoFactorAuthenticationOneTimePasswords')
        .execute({ executingUserPassword: password });
    };

    public userActivateTwoFactorAuthentication = (
        userId: string,
        twoFactorAuthToken: string,
        password: string
    ) => {
        return this.accountClient.request('userActivateTwoFactorAuthentication')
        .execute({ userId: userId, twoFactorAuthToken: twoFactorAuthToken, password: password });
    };

    public userEnableTwoFactorAuthentication = (
        userId: string,
        type: string,
        password: string
    ) => {
        return this.accountClient.request('userEnableTwoFactorAuthentication')
        .execute({ userId: userId, type: type, executingUserPassword: password });
    };

    public userDisableTwoFactorAuthentication = (userId: string, password: string) => {
        return this.accountClient.request('userDisableTwoFactorAuthentication')
        .execute({ userId: userId, executingUserPassword: password });
    };

    public userRequire2FAAuth = (userId: string) => {
        return this.accountClient.request('userRequireTwoFactorAuthentication').execute({ userId: userId });
    };

    public userChangeOwnEmailAddress = (email: string, password: string) => {
        return this.accountClient.request('userChangeOwnEmailAddress')
        .execute({ emailAddress: email, password: password });
    };

    public userChangeEmailAddress = (
        userId: string,
        emailAddress: string,
        password: string
    ) => {
        return this.accountClient.request('userChangeEmailAddress')
        .execute({ userId: userId, emailAddress: emailAddress, executingUserPassword: password });
    };

    public getAvailableRightsSubaccount = (accountId: string) => {
        return this.accountClient.request('getAvailableRightsForSubaccount').execute({ parentAccountId: accountId });
    };

    public getAvailableRightsUser = (accountId: string) => {
        return this.accountClient.request('getAvailableRightsForUser').execute({ accountId: accountId});
    };

    public getAvailableRightsApiKey = (userId: string) => {
        return this.accountClient.request('getAvailableRightsForApiKey').execute({ userId: userId });
    };

    public userPromoteToAdmin = (userId: string, oldAdminPassword: string) => {
        return this.accountClient.request('userPromoteToAdmin')
        .execute({ userId: userId, oldAdminPassword: oldAdminPassword });
    };

    public subaccountUserPromoteToAdmin = (userId: string, executingUserPassword: string) => {
        return this.accountClient.request('subaccountUserPromoteToAdmin')
        .bustCache('account', 'subaccountsFind')
        .execute({ userId: userId, executingUserPassword: executingUserPassword });
    };

    public accountStartSmsVerification(phoneNumber: string) {
        return this.accountClient.request('accountStartSmsVerification')
            .execute({ phoneNumber: phoneNumber });
    }

    public accountFinishSmsVerification(confirmationCode: string) {
        return this.accountClient.request('accountFinishSmsVerification')
            .execute({ confirmationCode: confirmationCode });
    }

    public accountGetNotifications = () => {
        return this.accountClient
            .request('accountGetNotifications')
            .execute({});
    };

    public accountAckNotification = (notificationId: string) => {
        return this.accountClient
            .request('accountAckNotification')
            .execute({ notificationId: notificationId });
    };
}
