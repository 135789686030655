import * as ng from 'angular';

import { LongDebounceInterval } from '@/configuration';

import { NavigationService } from '../../../../services';
import {
    DisableFilter, OrganismOverviewController, OrganismPricelistOverviewController
} from '../../../organisms/overview';

import './fancy-filter-bar.scss';

export class MoleculeFancyFilterBarController implements ng.IController {
    public static $inject: string[] = ['$translate', 'navigation'];

    public inputPlaceholder: string;
    public disableFilter: DisableFilter;
    public overviewCtrl: OrganismOverviewController | OrganismPricelistOverviewController | any;
    public searchButtonFocus: boolean;
    public reloadStateOnRefresh: boolean;
    public overviewModel;
    public longDebounceInterval = LongDebounceInterval;

    private $overviewOrganism: OrganismOverviewController;
    private $overviewPricelistOrganism: OrganismPricelistOverviewController;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private navigation: NavigationService
    ) {}

    public $onInit() {
        this.overviewCtrl = this.$overviewOrganism;
        this.inputPlaceholder = this.$translate.instant('GENERAL.SEARCH');

        if ([null, undefined].indexOf(this.overviewCtrl) >= 0) {
            this.overviewCtrl = this.$overviewPricelistOrganism;
        }
    }

    public refreshOverview = () => {
        if (this.reloadStateOnRefresh !== undefined && this.reloadStateOnRefresh === true) {
            this.navigation.reloadCurrentState();

            return;
        }

        if (this.overviewCtrl.overviewLoad !== undefined) {
            if (this.overviewCtrl.dataNotLoadedOnInit === true) {
                this.overviewCtrl.overviewLoad(false, true, true);
            } else {
                this.overviewCtrl.overviewLoad(false, false, true);
            }
        }
    };

    public refreshOnKeyEnter = (event) => {
        if (event.keyCode === 13) {
            this.refreshOverview();
        }
    };

    public resetSearchString = () => {
        this.searchValue = '';
        this.refreshOverview();
    };

    public searchButtonToggleFocus = () => {
        this.searchButtonFocus = !this.searchButtonFocus;
    };

    public get showSearchBar() {
        return !(
            (
                this.overviewCtrl.overviewModel
                && this.overviewCtrl.overviewModel.defaultFilterFields.length < 1
            )
            || this.disableFilter.searchFilter
        );
    }

    public get searchValue() {
        if (
            [undefined, null].indexOf(this.overviewCtrl) >= 0
            && [undefined, null].indexOf(this.overviewCtrl.overviewData) >= 0
        ) {
            return '';
        }

        return this.overviewCtrl.overviewData.filters.simpleFilter.value;
    }

    public set searchValue(value: string) {
        this.overviewCtrl.overviewData.filters.simpleFilter.value = value;
    }
}

export class MoleculeFancyFilterBarComponent implements ng.IComponentOptions {
    public bindings = {
        disableFilter: '<',
        overviewModel: '<',
        reloadStateOnRefresh: '<',
        toggleFancyFilter: '&'
    };

    public require = {
        $overviewOrganism: '?^organismOverview',
        $overviewPricelistOrganism: '?^organismPricelistOverview'
    };

    public controller = MoleculeFancyFilterBarController;
    public template = require('./fancy-filter-bar.html');
}
