import * as ng from 'angular';
import { DnsHelperService } from '../../../../../services';
import { EditPanelStatus, PanelType } from '../../../../molecules';
import { RecordContentChangeObj } from '../../../../organisms';

export class OrganEditPanelRecordChangeController implements ng.IController {
    public static $inject: string[] = ['dnsHelper'];

    public account: string;
    public oldContent = '';
    public newContent = '';
    public considerSubaccounts = false;
    public considerTemplates = false;
    public initialStatus: EditPanelStatus = EditPanelStatus.ACTIVE;
    public panelTypeOverride: PanelType = PanelType.MISCELLANEOUS_ACTION;
    public apiObject: RecordContentChangeObj;
    public $editForm;
    public recordType: string;
    public recordTypeDropdownItems: any[] = [{
        name: 'A',
        value: 'A'
    }, {
        name: 'AAAA',
        value: 'AAAA'
    }, {
        name: 'ALIAS',
        value: 'ALIAS'
    }, {
        name: 'CAA',
        value: 'CAA'
    }, {
        name: 'CNAME',
        value: 'CNAME'
    }, {
        name: 'MX',
        value: 'MX'
    }, {
        name: 'NS',
        value: 'NS'
    }, {
        name: 'PTR',
        value: 'PTR'
    }, {
        name: 'SRV',
        value: 'SRV'
    }, {
        name: 'TLSA',
        value: 'TLSA'
    }, {
        name: 'TXT',
        value: 'TXT'
    }];

    constructor(
        private dnsHelper: DnsHelperService
    ) {}

    public get contentValidationType() {
        if (this.apiObject.type === undefined) {
            return 'default';
        }
        return this.dnsHelper.getRecordValidationType(this.apiObject.type);
    }

    public confirm = () => {
        return this.$editForm.save();
    };

    public get disableConfirmButton() {
        return this.$editForm.$invalid || this.apiObject.type === undefined;
    }
}

export class OrganEditPanelRecordChangeComponent implements ng.IComponentOptions {
    public bindings = {
        apiObject: '='
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public controller = OrganEditPanelRecordChangeController;
    public template = require('./record-change.html');
}
