import * as ng from 'angular';
import * as q from 'q';
import { UiRights } from '../../../../../configuration';
import { UserModelService } from '../../../../../services/account/user-model';
import { AuthContextService } from '../../../../../services/auth-context';
import { ApiErrorModel } from '../../../../../services/errors/api-error-model';
import { NavigationService } from '../../../../../services/navigation';
import * as Types from '../../../../../types';

export class OrganismEditFormUserAdminController implements ng.IController {
    public static $inject: string[] = [
        'apiErrorModel',
        'navigation',
        'userModel'
    ];

    public userData: Types.AccountApi.User;
    public passwordOwn: string;
    public panelRightAdminSettings: any;

    private originalUserData: Types.AccountApi.User;

    constructor(
        private apiErrorModel: ApiErrorModel,
        private navigation: NavigationService,
        private userModel: UserModelService
    ) {}

    public $onInit() {
        this.originalUserData = ng.copy(this.userData);

        this.setPanelRightSettings();
        this.resetUserEditAdmins();
    }

    public setPanelRightSettings = () => {
        if (AuthContextService.isGranted(UiRights.ACC_SUBACCOUNT_USER_PROMOTE_TO_ADMIN)) {
            this.panelRightAdminSettings = {
                editPanelButton: true
            };

        }
    };

    public save = () => {
        this.apiErrorModel.destroyErrorList();

        if (this.userData.adminUser === this.originalUserData.adminUser) {
            this.resetUserEditAdmins();
        } else {
            this.userModel.userPromoteToAdmin(this.userData.id, this.passwordOwn).then(
                (res) => {
                    this.navigation.go('account.users.overview', {}, { reload: true });
                    return q.reject(false);
                }
            );
        }

    };

    public resetUserEditAdmins = () => {
        this.userData.adminUser = this.originalUserData.adminUser;
        this.passwordOwn = '';
    };

    public get errorList() {
        return this.apiErrorModel.errorObjectList;
    }
}

export class OrganismEditFormUserAdminComponent implements ng.IComponentOptions {
    public bindings = {
        userData: '='
    };

    public controller = OrganismEditFormUserAdminController;
    public template = require('./user-edit-admin.html');
}
