import * as ng from 'angular';

export interface InformationNodeObject {
    headline?: string;
    text: string[];
}

export class MoleculeInformationNodeController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public information: InformationNodeObject;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public get issetHeadline(): boolean {
        return [undefined, null, ''].indexOf(this.information.headline) < 0;
    }
}

export class MoleculeInformationNodeComponent implements ng.IComponentOptions {
    public bindings = {
        information: '<'
    };
    public controller =  MoleculeInformationNodeController;
    public template = require('./information-node.html');
}
