import * as ng from 'angular';
import '@/atomic-components/molecules/panels/';
import { UiRights } from '@/configuration';
import {
    AuthContextService,
    BillingRobotService,
    BundleModelService,
    DomainModelService,
    MachineModelService,
    ManagedApplicationRobotService,
    WebspaceModelService
} from '@/services/';
import * as Types from '@/types';
import './panel-additional-links.scss';

interface ReturnDataObject {
    productType: string;
    hasRight: boolean;
    products: unknown[];
    panelData: {
        iconNameNew?: boolean;
        iconNamePath?: boolean;
        iconName: string;
        overviewRoute?: string;
        overviewRouteText?: string;
        createRoute?: string;
        createRouteText?: string;
    };
}

export class MoleculePanelAdditionalLinksController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'billingRobot',
        'bundleModel',
        'domainModel',
        'machineModel',
        'managedApplicationRobot',
        'webspaceModel'
    ];

    public availableProductTypeList: unknown[] = [];

    private _productListLoaded: boolean;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private billingRobot: BillingRobotService,
        private bundleModel: BundleModelService,
        private domainModel: DomainModelService,
        private machineModel: MachineModelService,
        private managedApplicationRobot: ManagedApplicationRobotService,
        private webspaceModel: WebspaceModelService
    ) {
        this._productListLoaded = false;
    }

    public $onInit(): void {
        this.loadServiceProducts();
    }

    public loadServiceProducts = (): void => {
        const promises = [];

        promises.push(this.getBundleProducts());
        promises.push(this.getNextcloudProducts());
        promises.push(this.getSingleDomainProducts());
        promises.push(this.getSingleWebspaceProducts());
        promises.push(this.getManagedCloudServerProducts());
        promises.push(this.getCloudServerProducts());

        void Promise
            .all((promises))
            .then(
                (serviceProductTypes) => {
                    this.availableProductTypeList = [];
                    for (const productType of serviceProductTypes) {
                        if (productType.hasRight) {
                            this.availableProductTypeList = this.availableProductTypeList.concat(productType);
                        }
                    }
                    void this.$timeout(() => this._productListLoaded = true);
                }
            );
    };

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set productListLoaded({}) {}
    public get productListLoaded(): boolean {
        return this._productListLoaded;
    }

    public getBundleProducts = async (): Promise<ReturnDataObject> => {
        return await this._getProductsOfProductType({
            productType: 'bundle',
            hasRight: AuthContextService.isGranted(UiRights.BIL_BUNDLE_LIST),
            products: [],
            panelData: {
                iconName: 'boxes-alt',
                overviewRoute: 'bundle.overview',
                overviewRouteText: this.$translate.instant('TR_150419-9e942e_TR'),
                createRoute: 'bundle.new',
                createRouteText: this.$translate.instant('TR_090321-6ca8b6_TR')
            }
        });
    };

    public getNextcloudProducts = async (): Promise<ReturnDataObject> => {
        return await this._getProductsOfProductType({
            productType: 'nextcloud',
            hasRight: AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_LIST),
            products: [],
            panelData: {
                iconNamePath: true,
                iconName: '/assets/images/logos/nextcloud-bw.svg',
                overviewRoute: 'storage.storage-products.overview',
                overviewRouteText: this.$translate.instant('TR_250919-a155ba_TR'),
                createRoute: 'storage.storage-products.new',
                createRouteText: this.$translate.instant('TR_090321-024739_TR')
            }
        });
    };

    public getSingleDomainProducts = async (): Promise<ReturnDataObject> => {
        return await this._getProductsOfProductType({
            productType: 'singleDomain',
            hasRight: AuthContextService.isGranted(UiRights.DOM_DOMAINS_LIST),
            products: [],
            panelData: {
                iconName: 'globe',
                overviewRoute: 'domain.domains.overview',
                overviewRouteText: this.$translate.instant('TR_090321-eb53cb_TR'),
                createRoute: 'domain.domains.new',
                createRouteText: this.$translate.instant('TR_090321-9c402b_TR')
            }
        });
    };

    public getSingleWebspaceProducts = async (): Promise<ReturnDataObject> => {
        return await this._getProductsOfProductType({
            productType: 'singleWebspace',
            hasRight: AuthContextService.isGranted(UiRights.WEB_OBJECT_LIST),
            products: [],
            panelData: {
                iconName: 'server',
                overviewRoute: 'webhosting.webspaces.overview',
                overviewRouteText: this.$translate.instant('TR_150419-aa0bf1_TR'),
                createRoute: 'webhosting.webspaces.new',
                createRouteText: this.$translate.instant('TR_090321-cde4eb_TR')
            }
        });
    };

    public getCloudServerProducts = async (): Promise<ReturnDataObject> => {
        return await this._getProductsOfProductType({
            productType: 'cloudServer',
            hasRight: AuthContextService.isGrantedAny([UiRights.MACHINE_VM_LIST, UiRights.MACHINE_PM_LIST]),
            products: [],
            panelData: {
                iconName: 'cloud',
                overviewRoute: 'machine.dashboard',
                overviewRouteText: this.$translate.instant('TR_150419-4bf773_TR'),
                createRoute: 'managed-servers.new',
                createRouteText: this.$translate.instant('TR_090321-c7f6d3_TR')
            }
        });
    };

    public getManagedCloudServerProducts = async (): Promise<ReturnDataObject> => {
        return await this._getProductsOfProductType({
            productType: 'managedCloudServer',
            hasRight: AuthContextService.isGrantedAny([UiRights.MACHINE_VM_LIST, UiRights.MACHINE_PM_LIST]),
            products: [],
            panelData: {
                iconName: 'cloud-upload',
                overviewRoute: 'managed-servers.overview',
                overviewRouteText: this.$translate.instant('TR_150419-7ac2a1_TR'),
                createRoute: 'managed-servers.new',
                createRouteText: this.$translate.instant('TR_090321-124b72_TR')
            }
        });
    };

    private _getProductsOfProductType = async (returnData: ReturnDataObject): Promise<ReturnDataObject> => {
        if (!returnData) {
            return;
        }

        // check needed right
        if (!returnData.hasRight) {
            return returnData;
        }

        switch (returnData.productType) {
            case 'bundle':
                return await this.bundleModel
                    .listWithoutPagination()
                    .then(
                        (apiResponse) => {
                            returnData.products = apiResponse.data;
                            return returnData;
                        }
                    );
            case 'cloudServer':
                return await this._issetRenewableVirtualMachineProducts()
                    .then(
                        (renewableProducts: boolean) => {
                            if (renewableProducts) {
                                return this.machineModel
                                    .listWithoutPagination()
                                    .then(
                                        (apiResponse) => {
                                            returnData.products = this._getServerTypes(apiResponse.data, 'none');
                                            return returnData;
                                        }
                                    );
                            }

                            return returnData;
                        }
                    );
            case 'managedCloudServer':
                return await this._issetRenewableVirtualMachineProducts()
                    .then(
                        (renewableProducts: boolean) => {
                            if (renewableProducts) {
                                return this.machineModel
                                    .listWithoutPagination()
                                    .then(
                                        (apiResponse) => {
                                            returnData.products = this._getServerTypes(
                                                apiResponse.data, 'platform'
                                            );
                                            return returnData;
                                        }
                                    );
                            }

                            return returnData;
                        }
                    );
            case 'nextcloud':
                return await this.managedApplicationRobot
                    .nextcloudsListWithoutPagination(null, 1, 1)
                    .then(
                        (apiResponse) => {
                            returnData.products = apiResponse.response.data;
                            return returnData;
                        }
                    );
            case 'singleDomain':
                return await this.domainModel
                    .list()
                    .then(
                        (apiResponse) => {
                            returnData.products = apiResponse.data;
                            return returnData;
                        }
                    );
            case 'singleWebspace':
                return await this.webspaceModel
                    .list()
                    .then(
                        (apiResponse) => {
                            returnData.products = apiResponse.data;
                            return returnData;
                        }
                    );
            default:
                return returnData;
        }
    };

    private _issetRenewableVirtualMachineProducts = (): PromiseLike<boolean> => {
        if (!AuthContextService.isGranted(UiRights.BIL_LIST_RENEWABLE_OBJECTS)) {
            return Promise.resolve(false);
        }

        const filter = {
            field: 'AccountId',
            value: AuthContextService.user.accountId
        };

        return this.billingRobot.findRenewableObjects(filter).then(
            (apiResponse) => {
                if (
                    [undefined, null, []].indexOf(apiResponse.response) < 0
                    && [undefined, null, []].indexOf(apiResponse.response.data) < 0
                ) {
                    return (apiResponse.response.data as Types.BillingApi.RenewableObject[])
                        .map((product) => product.objectType)
                        .filter(
                            (productType: string, currentIndex: number, productTypesArray: string[]) => {
                                return productTypesArray.indexOf(productType) === currentIndex;
                            }
                        )
                        .indexOf('VirtualMachine') < 0;
                }

                return false;
            }
        );
    };

    private _getServerTypes = (serverList: Types.MachineApi.VirtualMachine[], type: string): unknown[] => {
        return serverList.map(
            (server) => {
                return server.managementType !== undefined
                && server.managementType === type;
            }
        );
    };
}

export class MoleculePanelAdditionalLinksComponent implements ng.IComponentOptions {
    public controller =  MoleculePanelAdditionalLinksController;
    public template = require('./panel-additional-links.html');
}
