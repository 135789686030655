import * as ng from 'angular';
import { MoleculePanelEditTableController } from '../../../panel-edit/table/panel-edit-table';
import {
    DatabaseAccessLevel,
    DatabaseAccessObject,
    DatabaseUserObject,
    MoleculePanelRowDatabaseAccessController
} from '../../../panel-rows/panel-row-access/database/database-access';

export class MoleculePanelCreateDatabaseAccessController implements ng.IController {
    public static $inject: string[] = ['$timeout'];

    public onAdd: (CreatedatabaseAccessObject) => any;
    public $panelEditTable: MoleculePanelEditTableController;
    public newDatabaseUserObject: DatabaseUserObject;
    public newAccessObject: DatabaseAccessObject;
    public setPageOverlay: boolean;
    public isValid = false;
    public $moleculeFormEdit;
    public accountId: string;
    public databaseId: string;
    public removeFromList: any[] = [];

    public resetAccess = false;
    public setAccessObject = false;

    constructor(
        private $timeout: ng.ITimeoutService
    ) {}

    $onInit() {
        this.setPageOverlay = this.setPageOverlay || false;
        this.$moleculeFormEdit.saveCallback = this.addAccess;
    }

    public addAccess = (): void => {
        this._setAccessObject();

        const returnObject = {
            access: this.newAccessObject,
            user: this.newDatabaseUserObject
        };
        this.$timeout(() => {
            this.onAdd(returnObject);
        });
        this.$panelEditTable.createNewObject = false;
        this._resetAccess();
    };

    private _resetAccess = () => {
        this.resetAccess = true;
        this.$timeout(() => {
            this.resetAccess = false;
        }, 10);
    };

    private _setAccessObject = () => {
        this.setAccessObject = true;
        this.$timeout(() => {
            this.setAccessObject = false;
        }, 10);
    };
}

export class MoleculePanelCreateDatabaseAccessComponent implements ng.IComponentOptions {
    public bindings = {
        accountId: '<',
        databaseId: '<',
        onAdd: '<',
        setPageOverlay: '<',
        removeFromList: '<'
    };
    public require = {
        $panelEditTable: '^moleculePanelEditTable',
        $moleculeFormEdit: '^moleculeFormEdit'
    };
    public controller =  MoleculePanelCreateDatabaseAccessController;
    public controllerAs = '$createDatabaseAccessCtrl';
    public template = require('./panel-create-database-access.html');
}
