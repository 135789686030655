import * as ng from 'angular';
import { FormDropDownItems } from '../../../../forms/form-elements/drop-down/drop-down';

export class MoleculePanelEditRowDropdownGroupsController implements ng.IController {
    public static $inject: string[] = [];

    public defaultOption: any;
    public disabled: boolean;
    public disableRandomisedId: boolean;
    public inputId: string;
    public items: FormDropDownItems[];
    public model: any;
    public onChangeCallback: (arg0: any) => any;
    public overviewLoad: (selection: any) => any;
    public placeholder: string;
    public popoverModifier: string;
    public popoverPosition: string;
    public sizeModifier: string;
    public dropDownType: string;
}

export class MoleculePanelEditRowDropdownGroupsComponent implements ng.IComponentOptions {
    public transclude = {
        label: 'rowLabel'
    };
    public bindings = {
        defaultOption: '<',
        disabled: '<',
        disableRandomisedId: '<?',
        inputId: '@?',
        items: '<',
        model: '=',
        onChangeCallback: '<',
        overviewLoad: '<',
        placeholder: '@',
        popoverModifier: '@',
        popoverPosition: '@',
        sizeModifier: '@',
        dropDownType: '@'
    };
    public controller = MoleculePanelEditRowDropdownGroupsController;
    public controllerAs = '$panelRow';
    public template = require('./panel-edit-row-dropdown-groups.html');
}
