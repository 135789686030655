import ng from 'angular';

import { EditPanelRight, EditPanelStatus } from '@/atomic-components';
import {
    AlertManagerService,
    AuthContextService,
    DateWrapper,
    DomainHandleModelService,
    NavigationService
} from '@/services';
import { DomainApi } from '@/types';

export class OrganismNewFormDomainContactController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'authContext',
        'domainHandleModel',
        'navigation'
    ];

    public disableSaveButton = true;
    public userPanelRight: EditPanelRight;
    public initialStatus: EditPanelStatus;
    public contact: DomainApi.Contact;

    public errorType = 'create';

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private authContext: AuthContextService,
        private domainHandleModel: DomainHandleModelService,
        private navigation: NavigationService
    ) {}

    public $onInit(): void {
        this.initialStatus = EditPanelStatus.CREATE;
        this.contact.id = null;
        this.contact.accountId = this.contact.accountId || this.authContext.account.id;
    }

    public saveContact = (): Promise<void> => {
        if (
            [undefined, null].indexOf(this.contact.extDateOfBirth) < 0
            && typeof this.contact.extDateOfBirth === 'object'
            && (this.contact.extDateOfBirth as DateWrapper).dateString
        ) {
            this.contact.extDateOfBirth = (this.contact.extDateOfBirth as DateWrapper).dateString;
        }

        return this.domainHandleModel.create(this.contact).then(
            (result) => {
                if (result.status !== undefined && ['pending', 'success'].indexOf(result.status) >= 0) {
                    this.alertManager.success(this.$translate.instant('TR_310119-cb745b_TR'));
                    void this.navigation.go('domain.contacts.id.edit', { contactId: result.response.id });
                    return;
                }
                return Promise.reject();
            }
        );
    };
}

export class OrganismNewFormDomainContactComponent implements ng.IComponentOptions {
    public bindings = {
        contact: '<',
        userPanelRight: '='
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismNewFormDomainContactController;
    public template = require('./domain-contact-new.html');
}
