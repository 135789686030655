import * as ng from 'angular';
import { UiLanguagesConst } from '../../../../configuration';
import {
    ApiErrorObject,
    AuthContextService,
    NavigationService,
    ProductsModelService,
    UserSettingsModelService
} from '../../../../services';

import './wizard-order-confirmation.scss';

export class MoleculeWizardOrderConfirmationController implements ng.IController {
    public static $inject: string[] = [
        '$state',
        '$translate',
        'authContext',
        'navigation',
        'productsModel',
        'userSettingsModel'
    ];
    public orderResponses: any[];
    public productListItems: any[] = [];
    public requestsWithError = false;
    public apiErrorList: ApiErrorObject[] = [];

    constructor(
        private $state: ng.ui.IStateService,
        protected $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private navigation: NavigationService,
        private productsModel: ProductsModelService,
        private userSettingsModel: UserSettingsModelService
    ) {}

    public $onInit() {
        const language = UiLanguagesConst[this.authContext.user.language];

        this.orderResponses.map(
            (object, index: number) => {
                const responseType = this.orderResponses[index].type;
                if (object !== undefined || object.response !== undefined) {
                    if (Array.isArray(object.response)) {
                        object.response.map((obj: any) => this.setProductItem(obj, language, responseType));
                    } else {
                        this.setProductItem(object, language, responseType);
                    }
                }
            }
        );
    }

    public setProductItem = (object: any, language: string, type?: string) => {
        if (object?.response?.productCode !== undefined && object.response.productCode.indexOf('domain') < 0) {
            this.productsModel.findOne(object.response.productCode, language).then(
                (product) => {
                    this.productListItems.push(
                        this.createProductListItem(object.response, product.response)
                    );
                }
            );
        } else {
            if (object.response !== undefined) {
                this._manageResponseObject(object.response, object?.type || type);
                return;
            }
        }
        this._manageResponseObject(object);
    };

    public openLinkToProduct = (route: string, routeParams?: any) => {
        this.navigation.go(route, routeParams);
    };

    public reload = () => this.navigation.reloadCurrentState();

    public goToDashboard = () => {
        const currentStateParts = this.$state.current.name.split('.');

        switch (currentStateParts[0]) {
            default: return this.navigation.go('dashboard', {}, { reload: true });

            case 'domain': return this.navigation.go('domain.dashboard', {}, { reload: true });
            case 'dns': return this.navigation.go('dns.dashboard', {}, { reload: true });
            case 'ssl': return this.navigation.go('ssl.dashboard', {}, { reload: true });
            case 'email': return this.navigation.go('email.dashboard', {}, { reload: true });
            case 'webhosting': return this.navigation.go('webhosting.dashboard', {}, { reload: true });
            case 'database': return this.navigation.go('database.dashboard', {}, { reload: true });
            case 'machine': return this.navigation.go('machine.dashboard', {}, { reload: true });
            case 'managed-machine': return this.navigation.go('managed-servers.overview', {}, { reload: true });
            case 'bundle':
                if (currentStateParts.indexOf('id') < 0) {
                    return this.navigation.go('bundle.dashboard', {}, { reload: true });
                }

                switch (currentStateParts[3]) {
                    default: return this.navigation.go('bundle.id.dashboard', {}, { reload: true });

                    case 'mailboxes': return this.navigation.go('bundle.id.mailboxes.overview', {}, { reload: true });
                    case 'domains': return this.navigation.go('bundle.id.domains.overview', {}, { reload: true });
                    case 'databases': return this.navigation.go('bundle.id.databases.overview', {}, { reload: true });
                }

        }
    };

    private _manageResponseObject = (object, type?: string) => {
        type = object.type || type;

        switch ( true ) {
            case type === 'domains':
                this.productListItems.push(
                    this.createDomainItem(
                        object.response || object
                    )
                );
                break;
            case type === 'vhost':
                object?.response?.map(
                    (item: any) => {
                        this.productListItems.push(this.createVhostItem(item));
                    }
                );
                break;
            case ['301', '302', 'frame'].indexOf(object?.response?.type) >= 0:
                this.productListItems.push({
                    route: 'domain.redirections.overview',
                    text: this.$translate.instant('TR_100119-688348_TR')
                            + ': '
                            + object.response.domainNameUnicode
                            + ' -> '
                            + object.response.redirectionUrl
                });
                break;
            default:
                // nothing to do here
        }
    };

    private createProductListItem = (itemInfo: any, productInfo: any) => {
        switch (productInfo.productType) {
            case 'managed-virtual-machine':
            case 'virtual-machine':
                const route = this.$state.current.name.split('.')[0] === 'managed-servers'
                    ? 'managed-servers.id.dashboard'
                    : 'machine.virtualmachines.id.edit';

                return {
                    route: route,
                    routeParams: { machineId: itemInfo.id },
                    text: productInfo.name + ': ' + itemInfo.name
                };
            case 'imap-mailbox':
            case 'exchange-mailbox':
            case 'mailing-list':
            case 'external-forwarder':
            case 'internal-forwarder':
            case 'msexchange-mailbox':
            case 'smtp-forwarder':
            case 'catchall':
                const mailboxRoute = this.$state.current.name.startsWith('bundle')
                    ? 'bundle.id.mailboxes.id.edit'
                    : 'email.mailboxes.id.edit';

                const mailboxRouteParams: any = { mailboxId: itemInfo.id };

                if (itemInfo.bundleId) {
                        mailboxRouteParams.bundleId = itemInfo.bundleId;
                }

                return {
                    route: mailboxRoute,
                    routeParams: mailboxRouteParams,
                    text: productInfo.name + ': ' + itemInfo.emailAddressUnicode
                };

            case 'ssl-certificate': return {
                /**
                 *  We cannot link here directly to the newly created service object (edit page),
                 * since the response does not contain an ObjectId.
                 **/
                route: 'ssl.certificates.overview',
                text: this.$translate.instant('TR_100119-1cb3a6_TR')
            };

            case 'database': return {
                route: 'database.databases.id.edit',
                routeParams: { databaseId: itemInfo.id },
                text: productInfo.name + ': ' + itemInfo.name
            };

            case 'webspace':
                switch (this.$state.current.name.split('.')[0]) {
                    case 'managed-servers':
                        return {
                            route: 'managed-servers.id.webspaces.id.edit',
                            routeParams: {
                                machineId: this.$state.$current.locals.globals.machine.id,
                                webspaceId: itemInfo.id
                            },
                            text: productInfo.name + ': ' + itemInfo.name
                        };
                    default:
                        return {
                            route: 'webhosting.webspaces.id.edit',
                            routeParams: { webspaceId: itemInfo.id },
                            text: productInfo.name + ': ' + itemInfo.name
                        };
                }

            default: return;
        }
    };

    private createDomainItem = (item: any) => {
        if (this.$state.current.name.split('.')[0] === 'managed-servers') {
            return {
                route: 'managed-servers.id.webspaces.id.vhosts.id.edit',
                routeParams: {
                    machineId: this.$state.$current.locals.globals.machine.id,
                    vHostId: item.vhostId,
                    webspaceId: this.$state.$current.locals.globals.webspace.id
                },
                text: item.name
            };
        }

        return {
            route: 'domain.domains.id.edit',
            routeParams: { domainId: item.id },
            text: item.name
        };
    };

    private createVhostItem = (item: any) => {
        return {
            route: 'webhosting.webspaces.id.vhosts.id.edit',
            routeParams: { webspaceId: item.webspaceId, vHostId: item.id },
            text: this.$translate.instant('TR_140119-3834d8_TR') + ': ' + item.domainNameUnicode
        };
    };
}

export class MoleculeWizardOrderConfirmationComponent implements ng.IComponentOptions {
    public bindings = {
        apiErrorList: '<',
        orderResponses: '<',
        requestsWithError: '<'
    };
    public template = require('./wizard-order-confirmation.html');
    public controller =  MoleculeWizardOrderConfirmationController;
}
