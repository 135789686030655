import { Validator } from './abstract-validator';

export class ValidatePasswordMatch extends Validator {

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {
        super();
    }

    public validate = (value, compare) => {
        const reply = [];
        let comparison = '';

        if (Array.isArray(compare)) {
            comparison = compare[0];
        } else {
            comparison = compare;
        }

        if (value !== comparison) {
            reply.push({
                text: this.$translate.instant('TR_170119-c96131_TR')
            });
        }

        return reply;
    };
}
