import * as ng from 'angular';
import { MoleculeMenuMainController } from '@/atomic-components/molecules/menu';
import { WizardConfirmationBase } from '@/atomic-components/organisms/wizards';
import { UiRights } from '@/configuration';
import {
    ApiErrorModel,
    AuthContextService,
    ErrorMapperService,
    ManagedApplicationRobotService,
    OrchestratorRobotService
} from '@/services/';

export class NextcloudWizardConfirmation extends WizardConfirmationBase {
    public static $inject: string[] = WizardConfirmationBase.$inject.concat(['managedApplicationRobot']);

    constructor(
        protected $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        protected apiErrorModel: ApiErrorModel,
        protected errorMapper: ErrorMapperService,
        protected localInterval: ng.IIntervalService,
        protected orchestratorRobot: OrchestratorRobotService,
        private managedApplicationRobot: ManagedApplicationRobotService
    ) {
        super(
            $timeout,
            $translate,
            apiErrorModel,
            errorMapper,
            localInterval,
            orchestratorRobot
        );

        if (!AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_JOBS_LIST)) {
            this.isJobDependendWizard = false;
            this.fillNextSteps = () => {
                this.nextSteps.push({
                    iconName: 'chevron-right',
                    linkText: this.$translate.instant('TR_210720-f800ff_TR'),
                    route: 'nextcloud.overview',
                    routeOptions: { reload: true },
                    routeParams: {}
                });
            };
        }
    }

    public getJobByFilter = (filter: unknown, cacheTimeout: number, objectType?: string) => {
        return this.managedApplicationRobot
            .jobsFind(
                filter,
                null,
                null,
                null,
                true,
                cacheTimeout
            )
            .then(this.normalizeResponseObject);
    };

    public callbackAfterFinishedRequests = (): void => {
        for (const createdObject of this.createRequests) {
            if (this.isJobFinished(createdObject)) {
                // Reload main menu to add managed server menu entry
                MoleculeMenuMainController.triggerRefresh('NextcloudServer');
            }
        }

    };
}
