import * as ng from 'angular';

import { Reducer } from '../../../../../services/helpers/property-reducer';
import { AccountApi } from '../../../../../types';
import { ViewTypes } from '../../../../../types/view-types';

export class OrganismBundleWizardProductConfigEmailController {
    public static $inject: string[] = [];

    public metadata: ViewTypes.ProductConfigBundleObject;

    public selectedProductCode: string = null;
    public vhostValidationErrors: string[] = []; // used in template
    public service = 'bundle';

    public formCompleted = {
        bundleData: false
    };

    private _account: AccountApi.Account | AccountApi.Subaccount;
    private _selectedProductCode: string; // will be needed at some point in the future

    get account(): AccountApi.Account | AccountApi.Subaccount {
        return this._account;
    }

    set account(value: AccountApi.Account | AccountApi.Subaccount) {
        // this check looks strange, but it is correct! Do not change it ;)
        if (value && this.metadata && this.metadata.account) {
            this.metadata.account = value;
        }

        this._account = value;
    }

    public set configCompleted({}) {} /* tslint:disable-line:no-empty */ // used by parent

    public get configCompleted() { // used by parent
        for (const part in this.formCompleted) {
            if (!this.formCompleted[part]) {
                return false;
            }
        }

        return true;
    }

    public $onInit = () => {
        this._setMetadata();
    };

    public bundleDataChanged = () => { // used in template
        this.formCompleted.bundleData =
            Reducer.len(this.metadata.bundleName) > 0
            &&  Reducer.len(this.metadata.domain.nameUnicode) > 0;
    };

    private _setMetadata = () => {
        this.metadata = {
            account: this._account,
            bundleName: '',
            productCode: undefined,
            productFamily: '',
            domain: {
                nameUnicode: '',
                authCode: '',
                vHostOnly: false
            }
        };
    };
}

export class OrganismBundleWizardProductConfigEmailComponent implements ng.IComponentOptions {
    public bindings = {
        _selectedProductCode: '<selectedProductCode',
        account: '<account',
        configCompleted: '=configCompleted',
        metadata: '='
    };
    public template = require('./bundle-wizard-product-config-email.html');
    public controller = OrganismBundleWizardProductConfigEmailController;
}
