/* eslint-disable */

import ng from 'angular';
import { ProductHelperService } from '@/services/helpers';
import * as Types from '@/types';
import { add } from 'lodash';

export class SslComposerService {
    public static $inject: string[] = [
        '$translate',
        'productHelper'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private productHelper: ProductHelperService
    ) { }

    public buildSslApiObject = (
        metaData: Types.ViewTypes.ProductConfigSslObject
    ): PromiseLike<Types.ViewTypes.WizardComposerObject> => {
        if (metaData === undefined) {
            return Promise.reject({
                error: 'metaData is not defined'
            });
        }

        const composerObject: Types.ViewTypes.WizardComposerObject = {
            apiObject: {
                orderItem: {
                    items: undefined,
                    name: undefined,
                    productCode: undefined,
                    type: undefined
                },
                ownerAccountId: undefined
            },
            summaryObject: []
        };

        const additionalProductCodes = metaData.billingInfos.map(bi => bi.productCode);
        const productCodes = [metaData.sslProductCode, ...additionalProductCodes];

        /* TODO: Anstatt product code: array von product codes (für additional domains) */
        return this.productHelper.getProductFromProductCode(productCodes).then(
            () => {
                composerObject.productFamily = metaData.sslProductFamily;

                this._getSummaryObject(composerObject, metaData);
                this._getApiObject(composerObject, metaData);

                return composerObject;
            });
    };

    /* Fill composerObject with information from metaData.
       For display in wizard summary page. */
    private _getSummaryObject(
        composerObject: Types.ViewTypes.WizardComposerObject,
        metaData: Types.ViewTypes.ProductConfigSslObject
    ): void {

        const sslProductName = metaData.sslProductCode;
        const mainFactSheets: Types.ViewTypes.WizardSummaryViewDataObject[] = [];

        mainFactSheets.push({
            description: '',
            labelType: 'productCode',
            label: sslProductName,
            type: 'string',
            value: '1x'
        });

        if ([undefined, null, ''].indexOf(metaData.settings?.email) < 0) {
            mainFactSheets.push({
                description: '',
                labelType: 'translation',
                label: this.$translate.instant('bf139ed6-d397-4348-889a-90363f11dae0'),
                type: 'string',
                value: metaData.settings.email
            });
        }

        if (metaData.sslBillingCycle) {
            mainFactSheets.push({
                description: '',
                labelType: 'translation',
                label: this.$translate.instant('3ae628f1-02c5-4564-bfe5-06174b184462'),
                type: 'string',
                value: metaData.sslBillingCycle
            });
        }

        let sslProductFactSheets: any;
        const domainNames = metaData.domainsByProductCode[ metaData.sslProductCode ];
        if (domainNames && domainNames.length && domainNames.length > 0) {
            const sslProductDomainNames = {
                description: domainNames,
                labelType: 'string',
                label: this.$translate.instant('TR_020823-20b766_TR'),
                type: 'string',
                value: ''
            } as Types.ViewTypes.WizardSummaryViewDataObject;
            sslProductFactSheets = [...mainFactSheets, sslProductDomainNames];
        } else {
            sslProductFactSheets = mainFactSheets;
        }

        composerObject.summaryObject.push({
            account: metaData.account,
            factSheets: sslProductFactSheets,
            product: {
                name: metaData.sslProductCode,
                nameType: 'productCode',
                shortDescription: metaData.shortDescription
            },
            productCode: metaData.sslProductCode
        });

        // const productSpecification = ProductHelperService.wrapSpecificationItems(
        //     metaData.settings.productSpecification
        // );

        for (const bi of metaData.billingInfos) {
            if (bi.productCode === metaData.sslProductCode) {
                continue;
            }

            const summaryObject = {
                account: metaData.account,
                factSheets: [{
                    description: [] as string[],
                    labelType: 'productCode',
                    label: bi.productCode,
                    type: 'string',
                    value: `${bi.quantity}x`
                }],
                product: {
                    name: bi.productCode,
                    nameType: 'productCode'
                },
                productCode: bi.productCode,
                priceMultiplication: bi.quantity
            };

            const domainNames = metaData.domainsByProductCode[ bi.productCode ];
            if (domainNames && domainNames.length && domainNames.length > 0) {
                summaryObject.factSheets[0].description = domainNames;
            }

            composerObject.summaryObject.push( summaryObject );
        }
    }

    private _getAPIValidationType(input: string): string {
        switch (input) {
            case 'email':
                return 'validateViaEmail';
            case 'http':
                return 'validateViaFile';
            case 'dns':
                return 'validateViaDns';
            default:
                return input;
        }
    }

    private _getApiObject(
        composerObject: Types.ViewTypes.WizardComposerObject,
        metaData: Types.ViewTypes.ProductConfigSslObject
    ): void {
        // Das ist nicht das korrekte composer apiObject (Types.ViewTypes.WizardComposerObject) !!!
        // Wird von mir erst einmal nicht angepackt!
        composerObject.apiObject = {
            ssl: {
                order: {
                    additionalDomainNames: metaData.additionalDomains,
                    adminContact: {
                        emailAddress: metaData.contacts.administrationContact.fields.emailAddress.value,
                        firstName: metaData.contacts.administrationContact.fields.firstName.value,
                        lastName: metaData.contacts.administrationContact.fields.lastName.value,
                        phoneNumber: metaData.contacts.administrationContact.fields.phoneNumber.value,
                        title: metaData.contacts.administrationContact.fields.title.value
                    },
                    approverEmailAddress: metaData.settings.email,
                    autoRenew: false,
                    csr: metaData.sslValidationObject.csrRequestEncoded,
                    organization: {
                        organizationName: metaData.contacts.organizationContact.fields.organizationName.value,
                        city: metaData.contacts.organizationContact.fields.city.value,
                        region: metaData.contacts.organizationContact.fields.region.value,
                        country: metaData.contacts.organizationContact.fields.country.value,
                        addressLine1: metaData.contacts.organizationContact.fields.addressLine1.value,
                        addressLine2: metaData.contacts.organizationContact.fields.addressLine2.value,
                        postalCode: metaData.contacts.organizationContact.fields.postalCode.value,
                        phoneNumber: metaData.contacts.organizationContact.fields.phoneNumber.value,
                        faxNumber: metaData.contacts.organizationContact.fields.faxNumber.value
                    },
                    productCode: metaData.sslProductCode,
                    techContact: {
                        emailAddress: metaData.contacts.technicalContact.fields.emailAddress.value,
                        firstName: metaData.contacts.technicalContact.fields.firstName.value,
                        lastName: metaData.contacts.technicalContact.fields.lastName.value,
                        phoneNumber: metaData.contacts.technicalContact.fields.phoneNumber.value,
                        title: metaData.contacts.technicalContact.fields.title.value
                    },
                    type: (
                        metaData.sslProductFamily === 'dv-certificates'
                            ? 'DomainValidatedCertificateOrder'
                            : 'OrganizationValidatedCertificateOrder'
                    ),
                    validationType: this._getAPIValidationType(metaData.settings.validationMethod)
                },
                ownerAccountId: metaData.account.id
            }
        };
        let businessCategory = metaData.contacts?.organizationContact?.fields?.businessCategory?.value;
        if (businessCategory) {
            composerObject.apiObject.ssl.order.organization.businessCategory = businessCategory;
        }
    }
}
// tslint:enable: max-line-length
