import { FormInputModelOptions } from '@/atomic-components';
import * as ng from 'angular';
import { DepositModelService, ValidateNextcloudUser } from '../../../../../services';

export class MoleculeInputTextNextcloudUserController implements ng.IController {
    public static $inject: string[] = ['$translate', 'depositModel'];

    public inputType: string;
    public validationErrorList;
    public value: string;
    public placeholder: string;
    public validationInstructions: any[];
    public customCallbackOnEnter;
    public inputId: string;
    public modelOptions: FormInputModelOptions;
    public callbackOnClear: (arg0: any) => any;
    public callbackOnClearParams: any;
    public clearable: boolean;
    public callbackOnBlur: (arg0: any) => any;
    public callbackOnBlurParams: any;
    public callbackOnChange: (arg0: any) => any;
    public callbackOnChangeParams: any;

    private validator;
    private validationErrorListOuter: any[] = [];
    private _validationErrorListOuter: any[] = [];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private depositModel: DepositModelService
    ) {}

    public $onInit() {
        // Validate nextcloud usernames.
        this.inputType = 'nextcloudUser';
        this.value = this.value || '';
        this.validationErrorList = this.validationErrorList || [];
        this._validationErrorListOuter = ng.copy(this.validationErrorListOuter);
        this.validator = new ValidateNextcloudUser(this.$translate, this.validationErrorListOuter);

        this.validationInstructions = [{
            instructions: null,
            validator: this.validator
        }];
    }

    public $doCheck() {
        if (!ng.equals(this.validationErrorListOuter, this._validationErrorListOuter)) {
            // Holy shit, creepy hack - but there's no other way, is there?
            this.validator.updateValidatorErrors(this.validationErrorListOuter);
            this.validationErrorList = this.validator.validate(this.value);
            this._validationErrorListOuter = ng.copy(this.validationErrorListOuter);
        }
    }
}

export class MoleculeInputTextNextcloudUserComponent implements ng.IComponentOptions {
    public bindings = {
        callbackOnBlur: '<?',
        callbackOnBlurParams: '<?',
        callbackOnChange: '<?',
        callbackOnChangeParams: '<?',
        callbackOnClear: '<',
        callbackOnClearParams: '<',
        clearable: '<',
        inputId: '@?',
        modelOptions: '<?',
        placeholder: '@',
        validationErrorList: '=',
        validationErrorListOuter: '=',
        value: '='
    };
    public controller = MoleculeInputTextNextcloudUserController;
    public template = require('./input-text-with-validation-wrapper.html');
}
