import * as ng from 'angular';
import {
    OrganismDashboardOverviewsController
} from '../../../../../organisms/overview/dashboard-overviews/dashboard-overviews';

export class MoleculeOverviewRowPanelDashboardContactsVerificationController implements ng.IController {
    public static $inject: string[] = [];

    public serviceItemList: any;
    public OrganismDashboardOverview: OrganismDashboardOverviewsController;
    public service: string;
    public objectType: string;
}

export class MoleculeOverviewRowPanelDashboardContactsVerificationComponent implements ng.IComponentOptions {
    public require = {
        OrganismDashboardOverview: '^organismDashboardOverviews'
    };
    public bindings = {
        objectType: '@',
        service: '@',
        serviceItemList: '<'
    };
    public controller =  MoleculeOverviewRowPanelDashboardContactsVerificationController;
    public controllerAs = 'DashboardOverviewCtrl';
    public template = require('./dashboard-contacts-verification.html');
}
