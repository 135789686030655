/* eslint-disable */

import * as ng from 'angular';

import { UIRegExp } from '@/configuration';

import { Validator } from './abstract-validator';

export class ValidateFqdn extends Validator {
    private acceptPlaceholders: boolean;
    private isMultiselect: boolean;

    constructor(
        private $translate: ng.translate.ITranslateService,
        acceptPlaceholders?: boolean,
        isMultiSelect?: boolean
    ) {
        super();
        this.acceptPlaceholders = acceptPlaceholders !== undefined ? acceptPlaceholders : false;
        this.isMultiselect = isMultiSelect !== undefined ? isMultiSelect : false;
    }

    public validate = (value: string) => {
        if (this.isMultiselect) {
            const userInput = value.split('\n');
            if (userInput.length === 0 || (userInput.length === 1 && userInput[0] === '')) {
                return [];
            }
            const returnErrorList: any[] = [];
            userInput.forEach((fqdn, index) => {
                this.validateFqdn(fqdn).forEach(
                    (error) => {
                        error.text = (index + 1) + ': ' + error.text;
                        returnErrorList.push(error);
                    }
                );
            });
            return returnErrorList;
        } else {
            return this.validateFqdn(value);
        }
    };

    private validateFqdn = (value: string) => {
        if (this.acceptPlaceholders && (value === '##DOMAIN##')) {
            return [];
        }

        if (!this.isMultiselect && (value === undefined || value.length === 0)) {
            return [{ text: this.$translate.instant('TR_090119-2705b2_TR') }];
        }
        if (!this.isMultiselect && (value === undefined || value.length < 4 || value.length > 255)) {
            return [{ text: this.$translate.instant('TR_170119-d27206_TR') }];
        }

        if (value.indexOf('.') === -1) {
            return [{ text: this.$translate.instant('TR_170119-ccdbf7_TR') }];
        }

        if (value.indexOf('/') >= 0) {
            return [{ text: this.$translate.instant('TR_170119-7b26f5_TR') }];
        }

        if (value.indexOf('http://') >= 0 || value.indexOf('https://') >= 0) {
            return [{ text: this.$translate.instant('TR_170119-b05884_TR') }];
        }

        let reply: any[] = [];
        const labels = value.split('.');

        const validDomainLabels = labels.every(
            (label) => {
                const domainLabel = new ValidateDomainLabel(this.$translate);
                reply = domainLabel.validate(label);
                return reply.length === 0;
            }
        );

        if (!validDomainLabels) {
            return reply;
        }

        const tld = labels.pop();
        const tldMatch = UIRegExp.IsValidTld.exec(tld);

        if (this.acceptPlaceholders && (tld === '##DOMAIN##')) {
            return [];
        }

        if (tld.length < 2 || tldMatch === null || tldMatch.length !== 1) {
            return [{ text: this.$translate.instant('TR_170119-fee58e_TR') }];
        }

        return reply;
    };
}

export class ValidateDomainLabel extends Validator {
    constructor(private $translate: ng.translate.ITranslateService) {
        super();
    }

    public validate = (value: string) => {
        const reply: any[] = [];

        if (value.length < 1 || value.length > 63) {
            return [{ text: this.$translate.instant('WEBHOSTING.WEBSPACE.DOMAINNAME.VALIDATION.FQDN')}];
        }

        if (value.indexOf('-') === 0 || value.indexOf('-') === value.length - 1) {
            return [{ text: this.$translate.instant('TR_050319-fbb3ee_TR') }];
        }

        if (
            value.indexOf('@') >= 0
            || value.indexOf(' ') >= 0
            || value.indexOf('.') >= 0
            || value.indexOf('/') >= 0
            || value.indexOf(':') >= 0
        ) {
            return [{ text: this.$translate.instant('TR_050319-4ccee4_TR') }];
        }

        return reply;
    };
}
