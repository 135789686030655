import ng from 'angular';
import {
    DnsRobotService,
    NameserverSetModelService,
    SystemHelperService
} from '@/services';
import { BundleHandler } from '@/services/helpers/api-object-handlers/specific-handlers';
import { BundleApi, DnsApi, DomainApi, ViewTypes, WebhostingApi } from '@/types';

export interface DomainHelperObjectCache {
    nameserSets: ViewTypes.DomainWizardNameserverSets[];
    systemNameserver: Record<string, ViewTypes.DomainWizardNameserverSets[]>;
    zoneNameservers: ViewTypes.DomainWizardNameserverSets[];
    zones: DnsApi.ZoneConfig[];
}

export interface DomainWizardSettings {
    bundle?: BundleApi.Bundle;
    bundleHandler: BundleHandler;
    dns?: ViewTypes.DomainWizardDnsSettingsObject;
    nameservers?: ViewTypes.NameserverValues;
    nameserverType?: string;
    webspace?: WebhostingApi.Webspace;
}

export class WizardServiceHelperDomain {
    public static $inject: string[] = [
        'dnsRobot',
        'nameserverSetModel',
        'systemHelper',
        '$translate'
    ];

    public objectCache: DomainHelperObjectCache = {
        nameserSets: [],
        systemNameserver: {},
        zoneNameservers: [],
        zones: []
    };

    private _nameserverSets: ViewTypes.DomainWizardNameserverSets[] = [];

    constructor(
        private dnsRobot: DnsRobotService,
        private nameserverSetModel: NameserverSetModelService,
        private systemHelper: SystemHelperService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public buildRequestContactObject = (
        domainContacts: {
            admin: string;
            owner: string;
            tech: string;
            zone: string;
        }
    ): DomainApi.DomainContact[] => {
        return ['admin', 'owner', 'tech', 'zone']
        .map(
            (type) => {
                return {
                    contact: this.systemHelper.getPropertyOfObject(
                        domainContacts,
                        type as keyof ViewTypes.DomainContactObject
                    ),
                    type: type
                };
            }
        );
    };

    public getNameserverSets = (accountId: string): unknown => {
        const ssFilters = { field: 'accountId', value: accountId };
        const ssSort = {
            field: 'nameserverSetName',
            order: 'ASC'
        };

        this._resetNameserverSets();
        return this.dnsRobot.listNameserverSet(ssFilters, 100, 1, ssSort, accountId)
            .then(
                (result: { response: ViewTypes.ApiListResponse}) => {
                    return this.nameserverSetModel.getDefault(accountId)
                        .then(
                            (defaultServer: DnsApi.NameserverSet) => {
                                const systemNameserverSet: ViewTypes.DomainWizardNameserverSets = {
                                    items: [{
                                        name: this._getNameserverSetString(defaultServer.nameservers),
                                        value: {
                                            default: true,
                                            id: defaultServer.id,
                                            nameservers: defaultServer.nameservers.map(this._setNameserverObject),
                                            type: [undefined, null].indexOf(defaultServer.type) >= 0
                                                ? 'system'
                                                : defaultServer.type
                                        }
                                    }],
                                    title: this.$translate.instant('TR_100119-7c5288_TR')
                                };

                                this._nameserverSets[0] = systemNameserverSet;
                                this.objectCache.nameserSets[0] = systemNameserverSet;
                                Object.defineProperty(
                                    this.objectCache.systemNameserver,
                                    accountId, {
                                        value: systemNameserverSet,
                                        writable: true
                                    }
                                );

                                if (result.response.data.length > 0) {
                                    result.response.data.forEach(
                                        (nameserverSet: DnsApi.NameserverSet) => {
                                            const nsSet = {
                                                name: `${nameserverSet.name}
                                                    (${this._getNameserverSetString(nameserverSet.nameservers)})`,
                                                value: {
                                                    default: nameserverSet.defaultNameserverSet,
                                                    id: nameserverSet.id,
                                                    nameservers: nameserverSet.nameservers.map(
                                                        this._setNameserverObject
                                                    ),
                                                    type: nameserverSet.type
                                                }
                                            };

                                            Object.defineProperty(
                                                this.objectCache.nameserSets,
                                                nameserverSet.id,
                                                {
                                                    value: nsSet,
                                                    writable: true
                                                }
                                            );

                                            if (nameserverSet.defaultNameserverSet === true) {
                                                this._nameserverSets[0].items[0] = nsSet;
                                            } else {
                                                this._setNameserverSetListObject();
                                                this._nameserverSets[2].items.push(nsSet);
                                            }
                                        }
                                    );
                                }

                                return this._nameserverSets;
                            }
                        );
                }
            );
    };

    public checkDomainHasGlueRecord = (
        selectedNameservers: ViewTypes.Nameservers[],
        domains: ViewTypes.WizardSelectedDomainObject[]
    ): boolean => {
        if ([undefined, null].indexOf(selectedNameservers) >= 0) {
            return false;
        }

        return selectedNameservers.some((nameserver) => {
            return domains.some((domain) => {
                if (nameserver.name?.indexOf(domain.domainName) >= 0) {
                    domain.glueRecord = true;
                    return true;
                }
                return false;
            });
        });
    };

    private _getNameserverSetString = (nameservers: string[]): string => {
        if ([undefined, null].indexOf(nameservers) >= 0) {
            return '';
        }

        return nameservers.map((serverName) => serverName).join(', ');
    };

    private _setNameserverObject = (ns: string): ViewTypes.Nameservers => {
        return { name: ns, ips: [], ipsRequired: false };
    };

    private _resetNameserverSets = (): void => {
        this._nameserverSets = [
            {
                items: [{
                    name: '',
                    value: {
                        default: true,
                        id: '',
                        nameservers: [],
                        type: 'system'
                    }
                }],
                title: this.$translate.instant('TR_100119-7c5288_TR')
            },
            {
                items: [{
                    name: this.$translate.instant('TR_100119-3d31fe_TR'),
                    value: {
                        default: false,
                        id: null,
                        nameservers: null,
                        type: 'system'
                    }
                }],
                title: this.$translate.instant('TR_100119-7afa7b_TR')
            }
        ];
    };

    private _setNameserverSetListObject = (): void => {
        if (this._nameserverSets.length === 2) {
            // List object of saved nameserver sets is not defined
            this._nameserverSets.push({
                items: [],
                title: this.$translate.instant('TR_100119-ff3f23_TR')
            });
        }
    };
}
