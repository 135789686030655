import * as ng from 'angular';

export type DomainStateFilter = (domaminStatus: string, domainDeletionType: string) => string;

export class DomainState {
    public static $inject: string[] = ['$translate'];

    private static domainStatusList = {
        'active':            /* translationId */ '35b2882a-2c6b-4291-b7de-963835933109',
        // (gelöscht, wird nach kurzer Zeit ganz verschwinden)
        'deleted':           /* translationId */ 'GENERAL.OBJECT.STATUS.DELETED',
        // (gelöscht, wird nach kurzer Zeit ganz verschwinden)
        'deleting':          /* translationId */ 'GENERAL.OBJECT.STATUS.DELETING',
        'failed':            /* translationId */ 'GENERAL.OBJECT.STATUS.FAILED',
        'ordered':           /* translationId */ 'GENERAL.OBJECT.STATUS.ORDERED',
        'restorable':        /* translationId */ 'GENERAL.OBJECT.STATUS.RESTORABLE',
        'restore:pending': /* translationId */ 'GENERAL.OBJECT.STATUS.RESTORE.PENDING',
        'restricted':        /* translationId */ 'GENERAL.OBJECT.STATUS.RESTRICTED'
    };

    private static deletionTypeList = {
        delete:   /* translationId */ 'TR_020519-840915_TR',
        withdraw: /* translationId */ 'GENERAL.JOB.ACTION.DOMAINWITHDRAWSCHEDULED'
    };

    public static Factory($translate: ng.translate.ITranslateService) {
        return (domainStatus: string, domainDeletionType: string) => {
            let status = $translate.instant(DomainState.domainStatusList[domainStatus]);

            if (['active', 'failed'].indexOf(domainStatus) >= 0 && domainDeletionType !== '') {
                status = $translate.instant(DomainState.deletionTypeList[domainDeletionType]);
            }

            return status;
        };
    }
}
