import * as ng from 'angular';

export class OrganEditPanelMailingListController {
    public static $inject: string[] = [];

    public $editFormOrganism;
    public panelRight;
    public settings;
    public maxMailingListMembers: number;

    private mailbox: any;

    $onInit() {
        this.settings = ng.copy(this.mailbox);
    }

    public cancel = () => {
        this.mailbox.name = ng.copy(this.settings.name);
        this.mailbox.subjectPrefix = ng.copy(this.settings.subjectPrefix);
        this.mailbox.accessMode = ng.copy(this.settings.accessMode);
        this.mailbox.replyToMode = ng.copy(this.settings.replyToMode);
        this.mailbox.replyToEmailAddress = ng.copy(this.settings.replyToEmailAddress);
        this.mailbox.allowHtmlMails = ng.copy(this.settings.allowHtmlMails);
        this.mailbox.owners = ng.copy(this.settings.owners);
        this.mailbox.members = ng.copy(this.settings.members);
    };
}

export class OrganEditPanelMailingListComponent implements ng.IComponentOptions {
    public bindings = {
        panelRight: '<',
        mailbox: '=',
        maxMailingListMembers: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormMailbox'
    };
    public controllerAs = '$editPanelOrgan';
    public controller = OrganEditPanelMailingListController;
    public template = require('./mailing-list.html');
}
