import * as ng from 'angular';

export class MoleculeButtonDownController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public disabled: boolean;
    public down: () => void;
    public title: string;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit = () => {
        this.title = this.title || this.$translate.instant('TR_090119-c5be74_TR');
    };
}

export class MoleculeButtonDownComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        disabled: '<',
        down: '<',
        title: '@?'
    };
    public controller =  MoleculeButtonDownController;
    public template = require('./button-down.html');
}
