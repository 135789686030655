import ng from 'angular';
import { ITranslateParams, ITranslationKey, RobotTranslate } from '@/services/translate';

// in controller
const translateProvider: ng.IServiceProviderFactory = (): ng.IServiceProvider => {
    return new RobotTranslate();
};

// <span translate="TR_190919-86a0a8_TR" translate-values="{ replace: 'gunter' }"></span>
const translateDirective = (
    $translate: RobotTranslate,
    $parse: ng.IParseService,
    $compile: ng.ICompileService,
    $rootScope: ng.IRootScopeService
): ng.IDirective => {
    return {
        restrict: 'AE',
        scope: true,
        compile: (tElememt, tAttr) => {
            return (scope: ng.IScope, iElement: JQLite, iAttr: ng.IAttributes) => {
                const interpolateParams = {};

                if (iAttr.translate) {
                    if (iAttr.translateValues) {
                        ng.extend(interpolateParams, $parse(iAttr.translateValues)(scope));
                    }
                    const translation = $translate.instant(iAttr.translate, interpolateParams, $compile, $rootScope);
                    iElement.empty().append(translation || iAttr.translate);
                }
            };
        }
    };
};
translateDirective.$inject = ['$translate', '$parse', '$compile', '$rootScope'];

const translateFilterFactory = ($translate: RobotTranslate) => {
    return (input: ITranslationKey, params: ITranslateParams) => $translate.instant(input, params);
};
translateFilterFactory.$inject = ['$translate'];

export default ng
    .module('robot.translate', ['ng'])
    .provider('$translate', translateProvider)
    .directive('translate', translateDirective)
    .filter('translate', translateFilterFactory);
