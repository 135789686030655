import { Validator } from './abstract-validator';

import * as ng from 'angular';

export class ValidateMin extends Validator {
    private minimum: number;
    private specialValues: number[] = [];

    constructor(private $translate: ng.translate.ITranslateService, minimum: number, specialValues?: number[]) {
        super();
        this.minimum = minimum;
        if ([undefined, null].indexOf(specialValues) < 0) {
            this.specialValues = specialValues;
        }
    }

    public validate = (value: number) => {
        value = Number(value);

        // Make sure that the typecast worked.
        if (isNaN(value)) {
            // Returns no error, since this validation is supposed to be done in validate-number
            return [];
        }

        // Allows certain special values to be excluded from check
        if (this.specialValues.indexOf(value) >= 0) {
            return [];
        }

        if (this.minimum > value) {
            return [{
                text: this.$translate.instant('TR_170119-88c753_TR', { minimum: String(this.minimum)})
            }];
        }

        return [];
    };
}

export class ValidateMax extends Validator {
    private maximum: number;
    private specialValues: number[] = [];

    constructor(private $translate: ng.translate.ITranslateService, maximum: number, specialValues?: number[]) {
        super();
        this.maximum = maximum;
        if ([undefined, null].indexOf(specialValues) < 0) {
            this.specialValues = specialValues;
        }
    }

    public validate = (value: number) => {
        value = Number(value);

        // Make sure that the typecast worked.
        if (isNaN(value)) {
            // Returns no error, since this validation is supposed to be done in validate-number
            return [];
        }

        // Allows certain special values to be excluded from check
        if (this.specialValues.indexOf(value) >= 0) {
            return [];
        }

        if (this.maximum < value) {
            return [
                {
                    text: this.$translate.instant('TR_170119-3a5efb_TR', { maximum: String(this.maximum) })
                }
            ];
        }

        return [];
    };
}

export class ValidateStringMax extends Validator {
    private maximum: number;

    constructor(private $translate: ng.translate.ITranslateService, maximum: number) {
        super();
        this.maximum = maximum;
    }

    public validate = (value: string) => {
        value = String(value);

        if (value.length > this.maximum) {
            return [
                {
                    text: this.$translate.instant('TR_170119-99b679_TR', { maximum: String(this.maximum) })
                }
            ];
        }

        return [];
    };
}

export class ValidateStringMin extends Validator {
    private minimum: number;

    constructor(private $translate: ng.translate.ITranslateService, minimum: number) {
        super();
        this.minimum = minimum;
    }

    public validate = (value: string) => {
        value = String(value);

        if (value.length < this.minimum) {
            return [
                {
                    text: this.$translate.instant('TR_170119-a59948_TR', { minimum: String(this.minimum) })
                }
            ];
        }

        return [];
    };
}
