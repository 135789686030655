import * as ng from 'angular';
import { OrganismPricelistOverviewController } from '../../../organisms/overview/';
import { DisableFilter } from '../../../organisms/overview/overview';

export class MoleculePanelPricelistOverviewHeaderController implements ng.IController {
    public static $inject: string[] = [];

    public fancyFilterExpand: boolean;
    public disableFilter: DisableFilter;
    public expandWrapper: (arg0: any) => any;
    public OverviewCtrl: OrganismPricelistOverviewController;

    $onInit() {
        this.fancyFilterExpand = true;
    }
}

export class MoleculePanelPricelistOverviewHeaderComponent implements ng.IComponentOptions {
    public require = {
        OverviewPricelistCtrl: '^organismPricelistOverview'
    };
    public bindings = {
        overviewLoad: '<',
        disableFilter: '<'
    };
    public controller =  MoleculePanelPricelistOverviewHeaderController;
    public controllerAs = 'PanelPricelistOverviewHeader';
    public template = require('./panel-pricelist-overview-header.html');
}
