import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import {
    AlertManagerService,
    ApiErrorModel,
    AuthContextService,
    DateWrapper,
    MachineModelService,
    NavigationService,
    MachineJobModelService,
    SentryErrorEmitterService,
} from '../../../../../services';
import * as Types from '../../../../../types';
import { EditPanel, EditPanelStatus } from '../../../../molecules/forms';
import { WebhostingApi } from '@/types';

export class OrganismEditFormMachineDeleteController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'alertManager',
        'apiErrorModel',
        'machineModel',
        'navigation',
        'machineJobModel',
    ];

    // delete-panel data
    public cancelDeletion: () => void;
    public callbackCancelMachineChanges: () => void;
    public callbackUpdateMachineStatus: () => void;
    public checkingMachineStatus: boolean;
    public delete: () => void;
    public deleteDate: DateWrapper;
    public editPanels: { [name: string]: EditPanel };
    public hasAgreedToDeleteMachine = false;
    public initialStatus: any;
    public machine: Types.MachineApi.VirtualMachine;
    public machineHasUnfinishedJob = false;
    public machineStatusSupport: boolean;
    public reinstallTitle = '';
    public selectedRestoreAction = '';
    public showObjectId: boolean;

    public userPanelRight = {
        deletion: {
            delete: this.deleteRightsGranted,
            deletePermanently: this.deleteRightsGranted,
            editPanelButton: this.deleteRightsGranted,
        },
        general: {
            editPanelButton: this.editRightsGranted,
        },
        restore: {
            deletePermanently: this.deleteRightsGranted,
            editPanelButton: this.editRightsGranted,
            restore: this.restoreRightsGranted,
        }
    };

    private _isPaidUntilExceeded: boolean;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private machineModel: MachineModelService,
        private navigation: NavigationService,
        private machineJobModel: MachineJobModelService,
    ) {}

    public $onInit = () => {
        this.delete = this.deleteFunction;
        this.cancelDeletion = this.cancelDeletionFunction;

        this.initialStatus = this.machine.deletionScheduledFor !== null
            ? EditPanelStatus.DELETIONSCHEDULED
            : EditPanelStatus.READONLY;

        this.machineJobModel.findRunningJobsForMachine(this.machine).then((innerJobResult) => {
            this.machineHasUnfinishedJob = innerJobResult.status === 'success' && innerJobResult.response.data.length > 0;
        });
    }

    public set isPaidUntilExceeded(value: boolean) {
        this._isPaidUntilExceeded = value;
    }

    public get isPaidUntilExceeded(): boolean {
        return this._isPaidUntilExceeded;
    }

    public get isIndividualMachine(): boolean {
        return this.machine.managementType === "individual";
    }

    // when the machine is an individual server and the user is allowed to edit it return true, otherwise false
    public get userMayEdit(): boolean {
        return (!this.isIndividualMachine || this.showSuperUserOptions()) && !this.isDeleting;
    }

    public get isDeleting(): boolean {
        return this.machine.status === 'deleting';
    }

    public get enableDeletion(): boolean {
        return this.hasAgreedToDeleteMachine && this.deleteDate !== undefined;
    }

    public get isJobRunning(): boolean {
        // TODO findJobs request
        return this.machineHasUnfinishedJob;
    }

    public get showDeletePanel(): boolean {
        return ['restorable', 'deleting'].indexOf(this.machine.status) < 0 && this.userPanelRight.deletion.editPanelButton;
    }

    public get showRestorePanel(): boolean {
        return this.machine.status === 'restorable';
    }

    public get deleteRightsGranted(): boolean {
        let res = this.machine?.managementType !== 'individual'
            ? AuthContextService.isGranted(UiRights.MACHINE_VM_DELETE)
            : AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN);
        return res;
    }

    public get editRightsGranted(): boolean {
        let res = this.machine?.managementType !== 'individual'
            ? AuthContextService.isGranted(UiRights.MACHINE_VM_EDIT)
            : AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN);
        return res;
    }

    public get restoreRightsGranted(): boolean {
        let res = this.machine?.managementType !== 'individual'
            ? AuthContextService.isGranted(UiRights.MACHINE_VM_RESTORE)
            : AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN);
        return res;
    }

    public cancelTerminate = () => {
        this.hasAgreedToDeleteMachine = false;
        if (this.callbackCancelMachineChanges) {
            this.callbackCancelMachineChanges();
        }
    };

    public showSuperUserOptions = () => {
        let res = AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN);
        return res;
    };

    public isManagedServer = () => {
        if ([undefined].indexOf(this.machine.managementType) === 0) {
            return false;
        }

        return this.machine.managementType !== 'none';
    };

    public updateCallback = () => {
        this.navigation.reloadCurrentState();
    }

    public deleteFunction = () => {
        this.apiErrorModel.destroyErrorList();
        // TODO this.machineModel.delete when failing doesnt show error and instead does nothing PUI-7304
        if (this.deleteDate === undefined) {
            return;
        }
        this.machineModel.delete(this.machine, this.deleteDate?.isToday() ? null : this.deleteDate?.dateObj)
            .then(
                () => {
                    this.alertManager.success(this.$translate.instant('TR_100119-d6dbda_TR'));
                    this.$timeout(
                        () => {
                            this.navigation.reloadCurrentState();
                        },
                        300
                    );
                }
            );
    };

    public cancelDeletionFunction = () => {
        if (this.machine.deletionScheduledFor !== null) {
            this.machineModel.cancelDeletion(this.machine.id).then(
                (reply) => {
                    this.alertManager.success(this.$translate.instant('TR_230119-105bfd_TR'));

                    this.$timeout(
                        () => {
                            this.navigation.reloadCurrentState();
                        },
                    ).then(() => { return reply }).then(() => {
                        if (this.callbackCancelMachineChanges) {
                            this.callbackCancelMachineChanges();
                        }
                    });
                },
                (error) => {
                    SentryErrorEmitterService.sendSentryReport(error);
                }
            );
        }
    };

    public restoreOrPurge = () => {
        this.apiErrorModel.destroyErrorList();

        if (this.selectedRestoreAction === 'restore') {
            this.machineModel.restore([this.machine]).then(
                () => {
                    this.alertManager.success(this.$translate.instant('TR_100119-6a9a06_TR'));
                    this.navigation.reloadCurrentState();
                }
            );
        } else if (this.selectedRestoreAction === 'purge') {
            this.machineModel.purgeRestorable(this.machine.id).then(
                () => {
                    this.alertManager.success(this.$translate.instant('TR_100119-d6dbda_TR'));
                    this.navigation.go('machine.virtualmachines.overview', {}, { reload: true });
                }
            );
        }
    };
}

export class OrganismEditFormMachineDeleteComponent implements ng.IComponentOptions {
    public bindings = {
        cancelDeletion: '=?',
        callbackCancelMachineChanges: '<?',
        machine: '<?',
        delete: '=',
        updateCallback: '=?',
    };
    public controller = OrganismEditFormMachineDeleteController;
    public template = require('./machine-delete.html');
}
