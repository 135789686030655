import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';

export class TemplateDatabaseEditController {
    public static $inject: string[] = ['$state', '$translate'];

    public pageHeaderData: PanelHeaderData;

    public database;
    public databaseUsers;
    public backups;

    public userPanelRight;
    public showObjectId: boolean;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = () => {
        const database = this.database;
        const stateInBundle = this.$state.current.name.split('.').indexOf('bundle') >= 0;
        const stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';

        let panelHeaderEditRestrictionsRoute = 'database.databases.id.restrictions';
        let panelHeaderBackwardLink;
        let panelHeaderRouteParams;

        switch (true) {
            case stateInBundle:
                panelHeaderEditRestrictionsRoute = 'bundle.id.databases.id.restrictions';
                panelHeaderBackwardLink = 'bundle.id.databases.overview';
                panelHeaderRouteParams = { bundleId: this.$state.$current.locals.globals.bundle.id };
                break;
            case stateInMachine:
                panelHeaderEditRestrictionsRoute = 'managed-servers.id.databases.id.restrictions';
                panelHeaderBackwardLink = 'managed-servers.id.databases.overview';
                panelHeaderRouteParams = { machineId: this.$state.$current.locals.globals.machine.id };
                break;
            default:
                panelHeaderEditRestrictionsRoute = 'database.databases.id.restrictions';
                panelHeaderBackwardLink = 'database.databases.overview';
                panelHeaderRouteParams = null;
        }

        this.pageHeaderData = {
            backwardLink: panelHeaderBackwardLink,
            backwardText: this.$translate.instant('TR_100119-12711e_TR'),
            panelHeaderRoute: panelHeaderRouteParams,
            panelIcon: 'database',
            panelTitle: database.name
        };

        if (
            AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_SUPER_USER_READ)
            || (
                this.database.accountId !== AuthContextService.account.id
                && AuthContextService.isGranted(UiRights.DB_DATABASE_EDIT)
            )
        ) {
            this.pageHeaderData.productActions = [
                {
                    route: panelHeaderEditRestrictionsRoute,
                    title: this.$translate.instant('TR_250519-7f279b_TR')
                }
            ];
        }

        this.userPanelRight = {
            delete: {
                editPanelButton: AuthContextService.isGranted(UiRights.DB_DATABASE_DELETE)
            },
            general: {
                editPanelButton: AuthContextService.isGranted(UiRights.DB_DATABASE_EDIT)
            },
            restore: {
                editPanelButton: AuthContextService.isGranted(UiRights.DB_DATABASE_RESTORE)
            },
            storageQuota: {
                editPanelButton: AuthContextService.isGranted(UiRights.DB_DATABASE_EDIT)
            },
            users: {
                editPanelButton: AuthContextService.isGranted(UiRights.DB_USER_EDIT)
            }
        };
    };
}

export class TemplateDatabaseEditComponent implements ng.IComponentOptions {
    public bindings = {
        backups: '<',
        database: '<',
        databaseUsers: '<',
        showObjectId: '<'
    };
    public template = require('./database-edit-template.html');
    public controller = TemplateDatabaseEditController;
    public controllerAs = '$TemplateDatabaseEditCtrl';
}
