import * as ng from 'angular';
import { InputPlaceholderConst } from '../../../../../configuration';

export class OrganNewPanelWebhostingUserGeneralController {
    public static $inject: string[] = [];

    public cancel: any;
    public $newFormOrganism: any;
    public panelRight: any;
    public InputPlaceholder = InputPlaceholderConst;

}

export class OrganNewPanelWebhostingUserGeneralComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<'
    };
    public require = {
        $newFormOrganism: '^organismNewFormWebhostingUser'
    };
    public controllerAs = '$newPanelOrgan';
    public template = require('./webhosting-user-general.html');
    public controller = OrganNewPanelWebhostingUserGeneralController;
}
