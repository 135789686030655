import { ApiObject } from './../../../../../services/helpers/api-object-handlers/main';
import * as ng from 'angular';

import {
    WebhostingRobotService,
    WebspaceModelService
} from '@/services';
import { WizardCreateObject } from '../../general';
import { WebspaceWizardConfirmation } from './webspace-wizard-confirmation';

export class OrganismWebspaceWizardConfirmViewController {
    public static $inject: string[] = [
        '$translate',
        'webhostingRobot',
        'webspaceModel',
        'webspaceWizardConfirmation'
    ];

    public bundleId = '';
    public wizardCreateObjectList: WizardCreateObject[] = [];
    public nextSteps: any[] = [];
    public apiProductObject: any; // Required information for the products that need to be ordered.

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private webhostingRobot: WebhostingRobotService,
        private webspaceModel: WebspaceModelService,
        public webspaceWizardConfirmation: WebspaceWizardConfirmation
    ) {}

    public $onInit(): void {
        const jobs = {
            labelText: this.$translate.instant('TR_090119-f7a0fb_TR'),
            objectType: 'WebspaceUser',
            callback: () => {
                if (this.apiProductObject.apiObject.user.userType === 'new') {
                    return this.webhostingRobot.createUser(
                        this.apiProductObject.apiObject.user.user,
                        this.apiProductObject.apiObject.user.password
                    ).then((response) => response.response);
                } else {
                    return Promise.resolve({
                        id: this.apiProductObject.apiObject.user.user.id
                    });
                }
            },
            children: [{
                labelText: this.$translate.instant('TR_140119-4987e0_TR'),
                objectType: 'Webspace',
                callback: (prevJob: any) => {
                    this.apiProductObject.apiObject.webspace.accesses[0].userId = prevJob.id;
                    return this.webspaceModel.create(
                        this.apiProductObject.apiObject.webspace.webspace,
                        this.apiProductObject.apiObject.webspace.accesses,
                        undefined,
                        this.apiProductObject.apiObject.poolId,
                        this.apiProductObject.apiObject.webserverId
                    );
                },
                children: undefined
            }]
        };

        if (this.apiProductObject.apiObject.vhost.assignDomain) {
            jobs.children[0].children = [{
                labelText: this.$translate.instant('TR_171219-072231_TR'),
                objectType: 'Vhost',
                callback: (prevJob: any) => {
                    this.apiProductObject.apiObject.vhost.vhost.webspaceId = prevJob.id;
                    return this.webhostingRobot.createVHost(
                        this.apiProductObject.apiObject.vhost.vhost,
                        this.apiProductObject.apiObject.vhost.phpIni,
                        this.apiProductObject.apiObject.vhost.sslPrivateKey,
                        this.apiProductObject.apiObject.vhost.httpUsers
                    );
                }
            }];
        }

        this.wizardCreateObjectList.push(jobs);
    }
}

export class OrganismWebspaceWizardConfirmViewComponent implements ng.IComponentOptions {
    public bindings = {
        apiProductObject: '<'
    };

    public template = require('./webspace-wizard-confirm-view.html');
    public controller = OrganismWebspaceWizardConfirmViewController;
}
