import * as ng from 'angular';
import {
    ArticleSearchModelService
} from '../../../../services';

import './category.scss';

export class OrganismHelpdeskCategoryController implements ng.IController {
    public static $inject: string[] = [
        '$stateParams',
        '$translate',
        'articleSearchModel'
    ];

    public childCategories: {} = {};
    public currentCategory: {};
    public currentCategoryPath: string;
    public pageHeaderData = '';
    public rootCategories: {};
    public rootCategoryPath: string;
    public topArticles: {
        filter?: any;
        slice?: any;
    };

    constructor(
        private $stateParams: ng.ui.IUrlRouterService,
        private $translate: ng.translate.ITranslateService,
        private articleSearchModel: ArticleSearchModelService
    ) {}

    public $onInit() {
        let path = '';
        let parent = '';
        for (let i = 1; i <= 6; i++) {
            if (this.$stateParams['level' + i] !== null) {
                path = path + '/' + this.$stateParams['level' + i];
                if (i > 1) {
                    parent = parent + '/' + this.$stateParams['level' + (i - 1)];
                }
            }
        }
        path = path + '/';
        this.currentCategoryPath = path;
        this.rootCategoryPath = '/' + this.$stateParams.level1 + '/';
        parent = parent + '/';
        this.articleSearchModel.getCurrentCategory(parent, path).then(
            (reply) => {
                this.currentCategory = reply;
                if (typeof reply !== 'object' || !('title' in reply)) {
                    this.articleSearchModel.reportError(this.$translate.instant('TR_110119-2200e1_TR'));
                }
            }
        );
        this.articleSearchModel.getRootCategories().then(
            (reply) => {
                this.rootCategories = reply.data;
            }
        );
        this.articleSearchModel.getCategories(path).then(
            (reply) => {
                this.childCategories = reply.data;
            }
        );
        this.articleSearchModel.getArticlesByCategoryIncludingSubcategories(path, 60).then(
            (reply) => {
                const foundArticles: string[] = [];
                this.topArticles = reply.data;
                this.topArticles = this.topArticles.filter(
                    (article) => {
                        if (foundArticles.indexOf(article.title) === -1) {
                            foundArticles.push(article.title);
                            return true;
                        }
                        return false;
                    }
                );
                this.topArticles = this.topArticles.slice(0, 30);
            }
        );
    }

}

export class OrganismHelpdeskCategoryComponent implements ng.IComponentOptions {
    public bindings = {};
    public template = require('./category.html');
    public controller = OrganismHelpdeskCategoryController;
}
