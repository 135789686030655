import {
    DashboardWidgetModel,
    DashboardWidgetType
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model';
import {
    DashboardWidgetObjectDefinition
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model-operator';

export class DashboardWidgetOverviewDomainTransfersOutModel extends DashboardWidgetModel {
    public static $inject: string[] = DashboardWidgetModel.$inject.concat([]);

    public static dashboardWidgetDefinition: DashboardWidgetObjectDefinition = {
        demoData: [
            {
                id: '1234567890',
                domainNameUnicode: 'example.com',
                accountId: '',
                state: 'waiting'
            }, {
                id: '1234567891',
                domainNameUnicode: 'test-domain-transfer.de',
                accountId: '',
                state: 'waiting'
            }
        ],
        manualAddable: true,
        userPermissions: {
            isGranted: 'DOM_CONTACTS_LIST'
        },
        widgetDescription: [
            /* translationId */ 'TR_150321-a3c25c_TR'
        ],
        widgetObject: {
            attribute: {
                id: 'organDashboardWidgetOverviewDomainTransfersOut',
                width: 1,
                height: 1,
                x: 0,
                y: 0,
                noResize: true,
                autoPosition: true
            },
            componentTagName: 'organ-dashboard-widget-overview-domain-transfers-out',
            widgetModel: 'dashboardWidgetOverviewDomainTransfersOutModel',
            version: '1.0'
        },
        widgetTitle: /* translationId */ 'TR_020221-3e003e_TR',
        widgetType: DashboardWidgetType.custom
    };

    public dashboardWidgetDefinition: DashboardWidgetObjectDefinition;

    constructor() {
        super();

        this.dashboardWidgetDefinition = DashboardWidgetOverviewDomainTransfersOutModel.dashboardWidgetDefinition;
    }
}
