import * as q from 'q';
import {ApiErrorModel, DnsTemplateModelService, DnsZoneModelService} from '../../../../../services';
import { PanelType } from '../../../../molecules';

import * as ng from 'angular';
import * as Types from '../../../../../types';

interface TemplateWithRecords extends Types.DnsApi.Template {
    records?: Types.DnsApi.RecordTemplate[];
}

export class OrganEditPanelDnsZoneTemplateController {
    public static $inject: string[] = ['apiErrorModel', 'dnsTemplateModel', 'dnsZoneModel'];

    public panelRight;
    public zone: Types.DnsApi.ZoneConfig;
    public originalZone: Types.DnsApi.ZoneConfig;
    public overrideRecords = false;
    public records: Types.DnsApi.Record[] = [];
    public dnsSecOptions: Types.DnsApi.DnsSecOptions;

    public templates: TemplateWithRecords[] = [];

    public readonly panelTypeOverride = PanelType.MISCELLANEOUS_ACTION;

    constructor(
        private apiErrorModel: ApiErrorModel,
        public dnsTemplateModel: DnsTemplateModelService,
        private dnsZoneModel: DnsZoneModelService
    ) {}

    public $onInit() {
        this.originalZone = JSON.parse(JSON.stringify(this.zone));

        this.templates = [];

        this.dnsTemplateModel.list(1000, 1, { field: 'AccountId', value: this.zone.accountId })
        .then(
            (response) => response.data.map(
                (template) => this.dnsTemplateModel.findRecords(template.id)
                .then(
                    (records) => {
                        template.records = records;
                        this.templates.push(template);
                    }
                )
            )
        );
    }

    public get tieToTemplate() {
        return this.zone.templateValues !== null;
    }

    public set tieToTemplate(value: boolean) {
        if (value) {
            this.zone.templateValues = {
                templateId: '',
                templateReplacements: {
                    ipv4Replacement: '',
                    ipv6Replacement: '',
                    mailIpv4Replacement: '',
                    mailIpv6Replacement: ''
                },
                tieToTemplate: true
            };
        } else {
            this.zone.templateValues = null;
        }
    }

    public get selectedTemplate() {
        if (!this.tieToTemplate) {
            return undefined;
        }

        const templates = this.templates.filter((template) => template.id === this.zone.templateValues.templateId);

        if (templates.length < 1) {
            return undefined;
        }

        return templates[0];
    }

    public get showIpv4Replacement() {
        if (this.selectedTemplate === undefined) {
            return false;
        }

        return this.selectedTemplate.records.some((record) => record.content.indexOf('##IPV4##') >= 0);
    }

    public get showIpv6Replacement() {
        if (this.selectedTemplate === undefined) {
            return false;
        }

        return this.selectedTemplate.records.some((record) => record.content.indexOf('##IPV6##') >= 0);
    }

    public get showMxIpv4Replacement() {
        if (this.selectedTemplate === undefined) {
            return false;
        }

        return this.selectedTemplate.records.some((record) => record.content.indexOf('##MX_IPV4##') >= 0);
    }

    public get showMxIpv6Replacement() {
        if (this.selectedTemplate === undefined) {
            return false;
        }

        return this.selectedTemplate.records.some((record) => record.content.indexOf('##MX_IPV6##') >= 0);
    }

    public get showReplacements() {
        return this.showIpv4Replacement
        || this.showIpv6Replacement
        || this.showMxIpv4Replacement
        || this.showMxIpv6Replacement;
    }

    public save = () => {
        if (JSON.stringify(this.zone.templateValues) === JSON.stringify(this.originalZone.templateValues)) {
            return;
        }

        if (this.zone.templateValues === null) {
            return this.dnsZoneModel.untie([this.originalZone]);
        } else {
            if (
                this.originalZone.templateValues !== null
                && this.originalZone.templateValues.templateId === this.zone.templateValues.templateId
            ) {
                this.records = this.records.filter((record) => {
                    return record.recordTemplateId === null
                        && record.type.toLowerCase() !== 'soa';
                });
                return this.dnsZoneModel.reCreateNative(this.zone, this.records, undefined, this.dnsSecOptions);
            }

            return this.dnsZoneModel.tieToTemplate(
                [this.originalZone],
                this.zone.templateValues.templateId,
                this.zone.templateValues.templateReplacements,
                this.overrideRecords
            ).then(
                (apiResponse) => {
                    return this.apiErrorModel.apiResponseHasError(apiResponse) ? q.reject(false) : apiResponse;
                }
            );
        }
    };

    public cancel = () => {
        this.zone = JSON.parse(JSON.stringify(this.originalZone));
    };

    public listTemplates = (
        limit?: number,
        page?: number,
        filters?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions,
        timeout?: number
    ) => {
        const filterObject: any = {
            subFilter: [{
                field: 'accountId',
                value: this.zone.accountId.toString()
            }],
            subFilterConnective: 'AND'
        };
        if ([undefined, null].indexOf(filters) >= 0) {
            filterObject.subFilter.push({
                field: 'templateName',
                value: '*'
            });
        } else {
            filterObject.subFilter.push(filters);
        }
        return this.dnsTemplateModel.list(limit, page, filterObject, sort, timeout);
    };
}

export class OrganEditPanelDnsZoneTemplateComponent implements ng.IComponentOptions {
    public bindings = {
        dnsSecOptions: '<',
        panelRight: '<',
        records: '<',
        zone: '<'
    };

    public controller = OrganEditPanelDnsZoneTemplateController;
    public controllerAs = '$editPanelOrgan';
    public template = require('./dns-zone-template.html');
}
