import * as Types from '@/types';

export namespace PermissionGroupSelectFunctions {
    export const getPermissionsInGroup = (group: Types.AccountApi.RightGroup): string[] => {
        let permissions = [];

        for (const permission of group.rights) {
            permissions.push(permission.value);
        }

        for (const subgroup of group.subgroups) {
            permissions = permissions.concat(getPermissionsInGroup(subgroup));
        }

        return permissions;
    };

    export const isGroupSelected = (group: Types.AccountApi.RightGroup, selectedPermissions: string[]): boolean => {
        if (!group || !selectedPermissions) {
            return false;
        }

        return getPermissionsInGroup(group).every(
            (permission) => selectedPermissions.indexOf(permission) >= 0
        );
    };

    export const isGroupPartiallySelected = (
        group: Types.AccountApi.RightGroup,
        selectedPermissions: string[]
    ): boolean => {
        if (!group || !selectedPermissions) {
            return false;
        }

        let selected = 0;
        let missing = 0;

        for (const permission of getPermissionsInGroup(group)) {
            if (selectedPermissions.indexOf(permission) >= 0) {
                selected++;
            } else {
                missing++;
            }

            if (selected > 0 && missing > 0) {
                return true;
            }
        }

        return (selected > 0 && missing > 0);
    };

    export const isGroupInTemplate = (
        group: Types.AccountApi.RightGroup,
        templatePermissions: string[]
    ): boolean => {
        return getPermissionsInGroup(group).every(
            (permission) => templatePermissions.indexOf(permission) >= 0
        );
    };

    export const setSelection = (
        group: Types.AccountApi.RightGroup,
        selectedPermissions: string[],
        templatePermissions: string[],
        selectBoxStatus: boolean
    ): string[] => {
        let result: string[] = JSON.parse(JSON.stringify(selectedPermissions));

        if (selectBoxStatus) {
            for (const permission of getPermissionsInGroup(group)) {
                if (result.indexOf(permission)) {
                    result.push(permission);
                }
            }
        } else {
            result = result.filter(
                (permission) => getPermissionsInGroup(group).indexOf(permission) < 0
                || templatePermissions.indexOf(permission) >= 0
            );
        }

        return result;
    };
}
