import * as ng from 'angular';
import { ObjectStatusFilter } from '../../../../filters';
import { ApiObjectHelper, ApiObjectHelperService, NavigationService } from '../../../../services';
import { OrganismGlobalSearchController } from '../../../organisms/search/global-search';

export class MoleculeGlobalSearchResultController {
    public static $inject: string[] = ['$rootScope', 'apiObjectHelper', 'navigation', 'objectStatusFilter'];

    public data: any;
    public search: (data: any) => void;
    public $globalSearchOrganism: OrganismGlobalSearchController;
    public title: string;
    public status: string;
    public loginLinkOnly: boolean;
    public loadingEdit: boolean;
    public loadingBundle: boolean;
    public type: string;
    public loading = false;

    private objectHelper: ApiObjectHelper;

    constructor(
        private $rootScope: ng.IRootScopeService|any,
        private apiObjectHelper: ApiObjectHelperService,
        private navigation: NavigationService,
        private objectStatusFilter: ObjectStatusFilter
    ) {}

    public $onInit = () => {
        this.objectHelper = this.apiObjectHelper.getType(this.data);

        this.title = this.objectHelper.getName(this.data);
        this.status = this.objectHelper.getStatus(this.data);
        this.loginLinkOnly = (this.objectHelper.type === 'account');
        this.loadingEdit = false;
        this.loadingBundle = false;
        this.type = this.objectHelper.type;

        switch (this.type) {
            default:
                if ([undefined, null, ''].indexOf(this.data.status) < 0) {
                    const status = this.objectStatusFilter(this.data.status);
                    this.title += ` (${status})`;
                }
                break;

            case 'redirection':
                this.title += ` -> ${this.data.redirectionUrl}[${this.data.type}]`;
                break;

            case 'handle':
                this.title += ` ${this.data.name}`;
                break;

            case 'account':
                if ([undefined, null, ''].indexOf(this.data.customerNumber) < 0) {
                    this.title += ` (${this.data.customerNumber})`;
                }
                break;
        }
    };

    public goToEdit = () => {
        this.objectHelper.goToEditPage(this.data);
        this.loadingEdit = true;
        this.showLoading();
    };

    public goToBundle = () => {
        if (this.data.bundleId) {
            this.navigation.go('bundle.id.dashboard', { bundleId: this.data.bundleId });
            this.loadingBundle = true;
            this.showLoading();
        }
    };

    private showLoading = () => {
        this.loading = true;

        const deRegister = this.$rootScope.$on(
            '$stateChangeSuccess',
            () => {
                this.$globalSearchOrganism.hide();

                this.loadingEdit = false;
                this.loadingBundle = false;
                this.loading = false;

                deRegister();
            }
        );
    };
}

export class MoleculeGlobalSearchResultComponent {
    public controller = MoleculeGlobalSearchResultController;
    public controllerAs = '$searchResult';

    public require = {
        $globalSearchOrganism: '^organismGlobalSearch'
    };

    public bindings = {
        data: '=',
        search: '&'
    };

    public template = require('./global-search-result.html');
}
