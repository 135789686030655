import * as ng from 'angular';
import * as Configurations from '../../../../../configuration';
import { DateWrapper } from '../../../../../services';
import { DomainContactHelperService, SystemHelperService } from '../../../../../services/helpers';
import { EditPanelStatus } from '../../../../molecules/forms/form-edit/form-edit';
import { DomainContactPanelMode } from '../../../../molecules/panels/';

export class OrganEditPanelDomainContactAdvancedController {
    public static $inject: string[] = ['domainContactHelper', 'systemHelper'];

    public $editFormMolecule;
    public $organismCtrl;
    public panelRight;
    public initialStatus: EditPanelStatus;
    public contact;
    public extDateOfBirth: DateWrapper;
    public currentDate: Date;
    public panelMode: DomainContactPanelMode;
    public configurations = Configurations;

    private _contactOrig;
    private _contactPartParams = [
        'extGender',
        'extLanguage',
        'extForeignResidentIdentificationNumber',
        'extCompanyNumber',
        'extCompanyNumberCountry',
        'extTradeMarkName',
        'extTaxId',
        'extTaxIdCountry',
        'extDateOfBirth',
        'extPlaceOfBirth',
        'extPlaceOfBirthPostalCode',
        'extCountryOfBirth',
        'extVatId',
        'extVatIdCountry'
    ];

    constructor(
        public domainContactHelper: DomainContactHelperService,
        public systemHelper: SystemHelperService
    ) {}

    public $onInit() {
        this._contactOrig = ng.copy(this.contact);
        this.panelMode = this.$organismCtrl.panelMode || null;
        this.currentDate = new Date();
        if ([undefined, null].indexOf(this.contact) >= 0) {
            return;
        }
        if ([undefined, null, ''].indexOf(this.contact.extDateOfBirth) < 0) {
            this.extDateOfBirth = new DateWrapper(this.contact.extDateOfBirth);
        }
    }
    public $onDestroy() {
        this.cancel();
    }

    public cancel = () => {
        this._contactPartParams.map((param) => this.contact[param] = ng.copy(this._contactOrig[param]));
    };

    public setExtBirthdayDate = (selection) => {
        this.contact.extDateOfBirth = selection.dateString;
    };
}
export class OrganEditPanelDomainContactAdvancedComponent implements ng.IComponentOptions {
    public bindings = {
        $organismCtrl: '<organismCtrl',
        contact: '=',
        initialStatus: '<?',
        panelRight: '<'
    };
    public require = {
        $editFormMolecule: '^moleculeFormEdit'
    };
    public template = require('./domain-contact-advanced.html');
    public controller = OrganEditPanelDomainContactAdvancedController;
    public controllerAs = '$editPanelOrgan';
}
