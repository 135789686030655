import * as ng from 'angular';

import { MailboxModelService } from '../../../../../services';

export class OrganViewPanelEmailsOfOrganizationController implements ng.IController {
    public static $inject: string[] = ['$translate', 'mailboxModel'];

    public organizationId: string;
    public amountOfEmailAddresses = 0;
    public panelHeaderRoute: string;
    public panelHeaderRouteParams;
    public showAllLink = true;

    private _utilityStatus = null;

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private mailboxModel: MailboxModelService
    ) {}

    public $onInit() {
        this.panelHeaderRoute = 'email.mailboxes.overview';
        this.panelHeaderRouteParams = {
            fancyFilters: [
                { field: 'OrganizationId', value: this.organizationId }
            ]
        };
        this._checkEmailAddresses();
    }

    public _checkEmailAddresses = () => {
        this.mailboxModel.findMailAdressesByOrganization(this.organizationId, 1).then(
            (res) => {
                this.amountOfEmailAddresses = res.pagination.entries;
            }
        );
    };

    public set utilityStatus({}) {} // tslint:disable-line:no-empty
    public get utilityStatus() {
        if (this.amountOfEmailAddresses === 0) {
            return;
        }

        if (this._utilityStatus !== null) {
            return this._utilityStatus;
        }

        this._utilityStatus = {
            infoText: this.$translate.instant('TR_090120-860017_TR'),
            inUse: this.amountOfEmailAddresses,
            isStorage: false,
            showAbsolute: true
        };
        return this._utilityStatus;
    }

    public set showPanel({}) {} // tslint:disable-line:no-empty
    public get showPanel() {
        return this.amountOfEmailAddresses > 0;
    }
}

export class OrganViewPanelEmailsOfOrganizationComponent implements ng.IComponentOptions {
    public bindings = {
        organizationId: '<'
    };
    public controller = OrganViewPanelEmailsOfOrganizationController;
    public template = require('./emails-of-organization.html');
}
