import * as ng from 'angular';

export class OrganViewPanelDataProcessingMainController {
    public static $inject: string[] = ['$timeout'];

    public nearBottom: boolean;

    constructor(
        private $timeout: ng.ITimeoutService
    ) {}

    public $onInit() {
        this.nearBottom = false;

        this.$timeout(() => {
            const element = document.getElementById('contract');
            ng.element(element).bind('scroll', () => this._handleScrollCheck(element));
        }, 1000);
    }

    public scrollDown = () => {
        const element = document.getElementById('contract');
        const scrollToPosition =  element.scrollTop + (element.parentElement.clientHeight - 75);
        element.scrollTo({top: scrollToPosition, behavior: 'smooth'});
    };

    private _handleScrollCheck = (target: HTMLElement): void => {
        this.$timeout(() => {
            this.nearBottom = (target.scrollHeight - target.scrollTop) <= (target.clientHeight + 100);
        });
    };
}

export class OrganViewPanelDataProcessingMainComponent implements ng.IComponentOptions {
    public bindings = {
        nearBottom: '='
    };
    public controller = OrganViewPanelDataProcessingMainController;
    public template = require('./data-processing-main.html');
}
