import { Validator } from './abstract-validator';

import * as ng from 'angular';

export class ValidateCronjobParameter extends Validator {
    constructor(private $translate: ng.translate.ITranslateService) {
        super();
    }

    public validate = (value: string) => {
        if ([undefined, null, ''].indexOf(value) >= 0) {
            return [{ text: this.$translate.instant('TR_170119-e093d7_TR') }];
        }

        return [];
    };
}
