import { UiRights } from '@/configuration';
import * as ng from 'angular';
import { WizardConfirmationBase } from '../../general/confirm';
import {
    ApiErrorModel, AuthContextService,
    DatabaseRobotService,
    ErrorMapperService,
    OrchestratorRobotService
} from './../../../../../services/';

export class DatabaseWizardConfirmation extends WizardConfirmationBase {
    public static $inject: string[] = WizardConfirmationBase.$inject.concat(['databaseRobot']);

    constructor(
        protected $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        protected apiErrorModel: ApiErrorModel,
        protected errorMapper: ErrorMapperService,
        protected localInterval: ng.IIntervalService,
        protected orchestratorRobot: OrchestratorRobotService,
        private databaseRobot: DatabaseRobotService
    ) {
        super(
            $timeout,
            $translate,
            apiErrorModel,
            errorMapper,
            localInterval,
            orchestratorRobot
        );

        if (!AuthContextService.isGranted(UiRights.DB_JOBS_LIST)) {
            this.isJobDependendWizard = false;
            this.fillNextSteps = () => {
                this.nextSteps.push({
                    iconName: 'database',
                    linkText: this.$translate.instant('TR_100119-8e343a_TR'),
                    route: 'database.databases.overview',
                    routeOptions: { reload: true },
                    routeParams: {}
                });
            };
        }
    }

    public getJobByFilter = (filter, cacheTimeout, objectType?: string) => {
        switch (objectType) {
            case 'Database':
                return this.databaseRobot.listJobs(filter)
                    .then(this.normalizeResponseObject);

            case 'User':
                return this.databaseRobot.listJobs(filter)
                    .then(this.normalizeResponseObject);

            default:
                return undefined;
        }
    };

    public callbackAfterFinishedRequests = () => {
        for (const createdObject of this.createRequests) {
            if (createdObject.objectType === 'Database' && this.isJobFinished(createdObject)) {
                this.nextSteps.push({
                    iconName: 'database',
                    linkText: this.$translate.instant('TR_240220-728bc2_TR'),
                    route: 'database.databases.id.edit',
                    routeOptions: { reload: true },
                    routeParams: { databaseId: createdObject.result.id }
                });
            }
        }
    };
}
