import ng from 'angular';
import { AlertManagerService, UserSettingsModelService } from '../';
import { SentryErrorEmitterService } from '@/services';
import * as Sentry from '@sentry/browser';

export class HelpdeskRobotService {
    public static $inject: string[] = ['$http', '$translate', 'alertManager', 'userSettingsModel'];

    // public readonly apiEndpoint = '/localapi/helpdesk'; //debug endpoint
    public readonly apiEndpoint = '/api/helpdesk'; // live endpoint
    private currentArticle: {} = {};
    private language = 'de';

    constructor(
        private $http: ng.IHttpService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private userSettingsModel: UserSettingsModelService
    ) {
        // todo: uncomment when there are enough english articles!
        // this.language = AuthContextService.user.language;
    }

    public getTemplateName = (stateParams: ng.ui.IStateParamsService) => {
        let path = '';
        // build article path string
        for (let i = 1; i <= 5; i++) {
            if (stateParams['level' + i] !== null) {
                path = path + '/' + stateParams['level' + i];
            }
        }
        path = path + '/';
        return this.isArticle(path).then((isArticle: boolean) => {
            if (isArticle) {
                return 'article';
            } else {
                return 'category';
            }
        });
    };

    public getCurrentArticle = () => {
        return this.currentArticle;
    };

    public isArticle = (path: string) => {
        return this.getArticleByPath(path).then(
            (reply) => {
                if ('error' in (reply.data as any)) {
                    return false;
                }
                this.currentArticle = reply.data;
                return true;
            }
        );
    };

    public getArticleByPath = (path: string) => {
        const requestString = path.replace('//', '/');
        return this.sendHttpGetRequest('getArticleByPath', requestString);
    };

    public getArticleMetaByIdList = (articleIdList: string) => {
        return this.sendHttpGetRequest('getArticlesMetadata', articleIdList);
    };

    public getArticleMetaByCategory = (category: string) => {
        return this.sendHttpGetRequest('getArticlesByCategory', category);
    };

    public getArticlesByCategory = (path: string, amount: number = 5) => {
        const requestPath: string = path.replace('//', '/');
        return this.sendHttpGetRequest('getArticlesByCategory', requestPath, amount);
    };

    public getArticlesByCategoryIncludingSubcategories = (path: string, amount: number = 5) => {
        const requestPath: string = path.replace('//', '/');
        return this.sendHttpGetRequest('getArticlesByCategoryIncludingSubcategories', requestPath, amount);
    };

    public getCategories = (parent: string) => {
        const requestPath: string = parent.replace('//', '/');
        return this.sendHttpGetRequest('getCategories', requestPath);
    };

    public searchArticles = (search: string, amount: number = 5) => {
        return this.sendHttpGetRequest('searchArticles', encodeURI(search), amount);
    };

    public reportError = (message: string) => {
        this.alertManager.error(message);
    };

    private sendHttpGetRequest = (
        apiMethod: string,
        requestData: string,
        amount: number = 0,
        cacheRequest: boolean = true
    ) => {
        let url = this.apiEndpoint + '/' + apiMethod + '/' + this.language;
        if (amount > 0) {
            url = url + '/' + amount.toString();
        }
        url = url + '/' + requestData;
        const requestPromise = this.$http.get(
            url,
            {cache: cacheRequest}
        );
        requestPromise.then(
            // tslint:disable-next-line:no-empty
            () => {},
            (response) => {
                let errorMessage = response.data;
                if ('error' in response.data) {
                    errorMessage = response.data.error;
                }
                this.alertManager.error(
                    this.$translate.instant('TR_140119-dfa88e_TR') + ' '
                    + this.$translate.instant('TR_140119-bc41eb_TR') + ': '
                    + response.status + ' API-Fehlermedung: ' + errorMessage
                );
            }
        ).catch(e => {
            SentryErrorEmitterService.sendSentryReport(
                e,
                {message: "Helpdesk API error. If this happens often, check Helpdesk API."},
                {service: "helpdesk/robot", apiMethod: "sendHttpGetRequest"},
                Sentry.Severity.Warning
            );
            this.alertManager.error(
                this.$translate.instant('TR_140119-dfa88e_TR') + ' '
                + this.$translate.instant('TR_070519-c2830c_TR')
            );
        });
        return requestPromise;
    };
}
