import * as ng from 'angular';
import {
    DashboardWidgetModelOperator,
    DashboardWidgetObjectDefinition
} from '@/atomic-components/organs/dashboard-widgets';
import { DashboardWidgetObject } from '@/atomic-components/organs/dashboard-widgets';
import { DashboardHelperService } from '@/services/helpers';

export class OrganismDynamicDashboardAddController implements ng.IController {
    public static $inject: string[] = [
        '$scope',
        '$timeout',
        '$translate',
        'dashboardHelper'
    ];

    public addableWidgets: DashboardWidgetObjectDefinition[] = [];
    public addCallback: () => void;
    public dashboardOperator: DashboardWidgetModelOperator;
    public demoData: Record<string, unknown> | unknown[];
    public panelTitel: string;
    public showWidgetAddPopup: boolean;
    public widgetsToAdd: DashboardWidgetObjectDefinition[];
    public widgetsToAddSelected: boolean[] = [];

    private _activeCustomWidgets: DashboardWidgetObject[] = [];
    private _editableWidgetActive = -1;
    private _popupElement: HTMLElement;
    private _showWidgetAddPopup: boolean;
    private _demoContainerStyle = { width: 'auto' };

    constructor(
        private $scope: ng.IScope,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private dashboardHelper: DashboardHelperService

    ) {
        this.panelTitel = this.$translate.instant('TR_010221-5c98a8_TR');
    }

    public $onInit = (): void => {
        this._popupElement = document.getElementsByClassName('organism-dynamic-add-widget-popup')[0] as HTMLElement;
        this.widgetsToAdd = this.widgetsToAdd || [];
        this.showWidgetAddPopup = this.showWidgetAddPopup || false;
        this._showWidgetAddPopup = ng.copy(this.showWidgetAddPopup);
    };

    public $doCheck(): void {
        if (this.showWidgetAddPopup !== this._showWidgetAddPopup) {
            this._showWidgetAddPopup = ng.copy(this.showWidgetAddPopup);
            if (this.showWidgetAddPopup) {
                this.widgetsToAddSelected = [];
                this._setCustomStorageObjects();
                this._setDemoWidgetContainerToOriginalWidth();

                let loopIndex = 0;
                do {
                    // set addable widget checkbox selection to unselected
                    this.widgetsToAddSelected.push(false);
                    loopIndex++;
                } while (loopIndex < (this.addableWidgets.length - 1));

                this._popupElement.classList.remove('hidden');
                this._popupElement.classList.add('visible');
            } else {
                void this.$timeout(() => {
                    this._popupElement.classList.add('hidden');
                    this._popupElement.classList.remove('visible');
                }, 500); // transition in css .activ class set to 500ms - change this change this in css too
            }
        }
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set demoContainerStyle({}) {}
    public get demoContainerStyle(): Record<string, string> {
        return this._demoContainerStyle;
    }

    public widgetsToAddCallback = (
        value: { index: number; widget: DashboardWidgetObjectDefinition }
    ): void => {
        void this.$timeout(() => {
            if (this.widgetsToAddSelected[value.index]) {
                this.widgetsToAdd.push(value.widget);
            } else {
                this.widgetsToAdd = this.widgetsToAdd.filter((widget: DashboardWidgetObjectDefinition) => {
                    return widget.widgetObject.componentTagName !== value.widget.widgetObject.componentTagName;
                });
            }
        });
    };

    public closeAddWidgetPopup = (): void => {
        this.showWidgetAddPopup = false;
        this._editableWidgetActive = -1;
    };

    public setEditableWidgetActive = (
        index: number,
        widget: DashboardWidgetObjectDefinition
    ): void => {
        this._addDemoWidgetToHtml(widget);

        if (this._editableWidgetActive === index) {
            this._editableWidgetActive = -1;
            return;
        }

        this._editableWidgetActive = index;
    };

    public addWidgets = (): void => {
        if (this.addCallback) {
            this.addCallback();
        }
        this.showWidgetAddPopup = false;
        this._editableWidgetActive = -1;
    };

    public isWidgetAlreadyActiveOnDashboard = (widget: DashboardWidgetObjectDefinition): boolean => {
        const active = this._activeCustomWidgets.some(activWidget => {
            return activWidget.attribute.id === widget.widgetObject.attribute.id;
        });

        return active;
    };

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set numberOfSelectedWidgetToAdd({}) {}
    public get numberOfSelectedWidgetToAdd(): number {
        return this.widgetsToAddSelected.filter((widget) => widget).length;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set editableWidgetActive({}) {}
    public get editableWidgetActive(): number {
        return this._editableWidgetActive;
    }

    private _setCustomStorageObjects = (): void => {
        const customStorageWidgets = this.dashboardOperator.customStorageWidgetsInDashboard();
        this._activeCustomWidgets = customStorageWidgets.customerWidgets;
    };

    private _addDemoWidgetToHtml = (widget: DashboardWidgetObjectDefinition): void => {
        const containerElementId = 'demo-' + String(widget.widgetObject.attribute.id);
        this.demoData = widget.demoData;
        this.dashboardHelper.addOutsideWidget(widget.widgetObject, containerElementId, this.$scope);
    };

    private _setDemoWidgetContainerToOriginalWidth = (): void => {
        const widgetContainerWidth = document.getElementsByClassName('organism-dynamic-dashboard')[0].clientWidth;
        this._demoContainerStyle = { width: `${widgetContainerWidth}px`};
    };
}

export class OrganismDynamicDashboardAddComponent implements ng.IComponentOptions {
    public bindings = {
        addCallback: '<',
        addableWidgets: '<',
        dashboardOperator: '<',
        showWidgetAddPopup: '=',
        widgetsToAdd: '='
    };
    public controller = OrganismDynamicDashboardAddController;
    public template = require('./dynamic-dashboard-add.html');
}
