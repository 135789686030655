import * as ng from 'angular';
import { NavigationService } from '@/services';

import './dashboard-widget-empty-hint.scss';

export class OrganDashboardWidgetEmptyHintController implements ng.IController {
    public static $inject: string[] = ['navigation'];

    public constructor(
        private navigation: NavigationService
    ) {}

    public changeToEditMode = (): void => {
        void this.navigation.go('nextcloud-dashboard.edit', null, {reload: true});
    };
}

export class OrganDashboardWidgetEmptyHintComponent implements ng.IComponentOptions {
    public template = require('./dashboard-widget-empty-hint.html');
    public controller = OrganDashboardWidgetEmptyHintController;
}
