import { DateTimeWrapper } from '../services/dateTime';

export class DateTimeFilter {
    public static $inject: string[] = ['$translate'];

    private static cache = [];

    public static Factory(
        $translate: ng.translate.ITranslateService
    ) {
        const localDateTimeFilter: any = (apiDateTimeString: any, mode  = 'dateTime') => {
            const locale = $translate.use().substr(0, 2);
            return DateTimeFilter.getLocalTime(apiDateTimeString, locale, mode);
        };
        localDateTimeFilter.$stateful = true;
        return localDateTimeFilter;
    }

    private static getLocalTime(apiDateTimeString: string, locale: string, mode: string) {
        if ([undefined, null, ''].indexOf(apiDateTimeString) >= 0) {
            return '';
        }

        let localizedDateTime = '';
        const cacheHit = DateTimeFilter.cache.filter((cachedDate) => {
            return cachedDate.utc === locale + '_' + mode + '_' + apiDateTimeString;
        });
        if (cacheHit.length > 0) {
            const singleHit = cacheHit.pop();
            return singleHit.local;
        } else {
            const DateTime = new DateTimeWrapper(apiDateTimeString);
            switch (mode) {
                case 'dateTime':
                default:
                    localizedDateTime = DateTime.getLocalDayTimeString(locale, true);
                    break;
                case 'dateTimeMax':
                    localizedDateTime = DateTime.getLocalDayTimeString(locale);
                    break;
                case 'date':
                    localizedDateTime = DateTime.getLocalDateString(locale, true);
                    break;
                case 'dateMax':
                    localizedDateTime = DateTime.getLocalDateString(locale);
                    break;
                case 'dateTimeSeconds':
                    localizedDateTime = DateTime.getLocalDayTimeStringWithSeconds(locale, true);
                    break;
            }
            DateTimeFilter.cache.push({ utc: locale + '_' + mode + '_' + apiDateTimeString, local: localizedDateTime });
            return localizedDateTime;
        }
    }
}
