import * as ng from 'angular';
import { WizardObject } from '../../wizard-supervisor/wizard-supervisor';

export class MoleculeWizardSummaryItemBundleController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public serviceObject: WizardObject;
    public productInformations;

    private numberElementsShown = 0;
    private elementsShown: any = [];
    private maxElementsPerBox = 5;
    private keywordsDescriptions: any = [];

    private _selectedAccesses = 0;

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {}

    $onInit() {
        this.productInformations = this.serviceObject.display.factSheetItems.filter(factSheetItem => {
            return factSheetItem.productCodeTemplate === this.serviceObject.selectedProduct.selectedPrice.productCode;
        })[0];

        for (const i in this.serviceObject.settings.userInfo) {
            if (
                typeof this.serviceObject.settings.userInfo[i] === 'boolean'
                && this.serviceObject.settings.userInfo[i]
            ) {
                this._selectedAccesses++;
            }
        }

        if ('keywordsDescriptions' in this.productInformations) {
            this.keywordsDescriptions = this.productInformations.keywordsDescriptions;
        }
        this.elementsShown = [];
        this.numberElementsShown = 0;
    }

    public addAccessSeperator = (index) => {
        return index < (this._selectedAccesses - 1);
    };

    public getFactLabelTranslation = (propertyKeyword, raw?) => {
        raw = raw || false;

        const property = this.productInformations.factSheetItems.filter(item => item.keyword === propertyKeyword)[0];

        if (!(property.keyword in this.elementsShown) && this.numberElementsShown <= this.maxElementsPerBox) {
            this.numberElementsShown++;
            this.elementsShown[property.keyword] = this._getTranslatedDescription(property);
        }

        [
            {
                name: this.$translate.instant('TR_100119-228e74_TR'),
                replacement: this.$translate.instant('TR_STORAGE_TR')
            },
            {
                name: this.$translate.instant('TR_100119-3c1927_TR'),
                replacement: this.$translate.instant('TR_100119-215b41_TR')
            },
            {
                name: this.$translate.instant('TR_100119-b3bc3c_TR'),
                replacement: this.$translate.instant('TR_100119-9e2539_TR')
            }
        ]
        .forEach(
            evil => {
                if (this.elementsShown[property.keyword].name === evil.name) {
                    this.elementsShown[property.keyword].name = evil.replacement;
                }
            }
        );

        return raw ? this.elementsShown[property.keyword] : this.elementsShown[property.keyword].name;
    };

    private _getTranslatedDescription = (property) => {
        const keywordDescription = this.keywordsDescriptions.filter(description => {
            return description.category === property.category && description.keyword === property.keyword;
        });

        return keywordDescription.length > 0 ? keywordDescription[0] : property;
    };
}

export class MoleculeWizardSummaryItemBundleComponent implements ng.IComponentOptions {
    public bindings = {
        serviceObject: '='
    };
    public controller =  MoleculeWizardSummaryItemBundleController;
    public template = require('./wizard-summary-bundle.html');
}
