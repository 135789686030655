import * as ng from 'angular';
import { ModelHelper } from '../model-helper';
import { RequestStatus } from '../rpc-client';
import { DatabaseUserObject } from './../../atomic-components/molecules/panels/panel-rows/panel-row-access/database/database-access';
import { DatabaseRobotService } from './robot';

export class DatabaseUserModelService {
    public static $inject: string[] = ['databaseRobot', '$q', '$rootScope'];

    constructor(
        private databaseRobot: DatabaseRobotService,
        private $q: ng.IQService,
        private $rootScope: ng.IRootScopeService
    ) {}

    public list = (limit?: number, page?: number, filters?, sort?, cancel?, owner?) => {
        sort = sort || {
            field: 'userName',
            order: 'ASC'
        };
        page = page || 1;

        return this.databaseRobot.listDatabaseUsers(filters, limit, page, sort, {timeout: cancel}, owner)
            .then(ModelHelper.returnListResponse);
    };

    public listWithoutPagination = (limit?: number, page?: number, filters?, sort?, cancel?, owner?) => {
        sort = sort || {
            field: 'userName',
            order: 'ASC'
        };
        page = page || 1;

        return this.databaseRobot.listDatabaseUsersWithoutPagination(filters, limit, page, sort, {timeout: cancel})
            .then(ModelHelper.returnListResponse);
    };

    public findOneById = (id) => {
        return this.databaseRobot.listDatabaseUsersWithoutPagination({field: 'userId', value: id}, 1, 1)
            .then(ModelHelper.returnFindOneResponse);
    };

    public findById = (ids) => {
        const filter = {subFilterConnective: 'OR', subFilter: ids.map(this.idFilter)};
        return this.databaseRobot.listDatabaseUsersWithoutPagination(filter, 0, 1)
            .then(ModelHelper.returnFindResponse);
    };

    public findOneByName = (name) => {
        return this.databaseRobot.listDatabaseUsersWithoutPagination({field: 'userName', value: name}, 1, 1)
            .then(ModelHelper.returnFindOneResponse);
    };

    public getTotal = () => {
        return this.databaseRobot.listDatabaseUsers(undefined, 1, 1)
            .then(ModelHelper.returnTotalEntries);
    };

    public createUser = (user: DatabaseUserObject, password: string, owner?) => {
        // Dirty fix für PUI-6894.
        delete user.password
        return this.databaseRobot.userCreate(user, password, owner)
            .then(this.returnResponse)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public updateUser = (user: DatabaseUserObject, password: string) => {
        // Dirty fix für PUI-6894.
        delete user.password
        return this.databaseRobot.userUpdate(user, password)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public deleteUsers = (users) => {
        const promises = users.map(this.deleteOne);
        return this.$q.all(promises)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };
    private refresh = (status: RequestStatus = RequestStatus.SUCCESSFUL) => (result) => {
        this.$rootScope.$broadcast('database.user.refresh');
        if (status === RequestStatus.SUCCESSFUL) {
            return result;
        } else {
            return this.$q.reject(result);
        }
    };

    private idFilter = (id) => {
        return {field: 'userId', value: id};
    };

    private deleteOne = (user) => {
        return this.databaseRobot.userDelete(user.id);
    };

    private returnResponse = (result) => {
        return result.response;
    };
}
