import * as ng from 'angular';
import {
    DashboardOverviewObject,
    PanelHeaderData
} from '../../../atomic-components/';
import { UiRights } from '../../../configuration';
import { AuthContextService, DnsZoneOverviewModel, FilterCacheService } from '../../../services';

export class TemplateDnsDashboardController {
    public static $inject: string[] = ['$translate', 'authContext', 'dnsZoneOverviewModel', 'filterCache'];

    public hasDnsZoneListRight = AuthContextService.isGranted(UiRights.DNS_ZONES_LIST); // used in template!
    public pageHeaderData: PanelHeaderData;
    public dashboardOverview: DashboardOverviewObject;
    public faqArticleIdList: string[] = [
        'dns-entries',
        'dynamic-dns',
        'dns-mx-entries'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private dnsZoneOverviewModel: DnsZoneOverviewModel,
        private filterCache: FilterCacheService
    ) {}

    public $onInit = () => {
        const backwardRoute = '';
        this.pageHeaderData = {
            backwardLink: backwardRoute,
            backwardText: '',
            panelHeaderRoute: backwardRoute,
            panelHeaderRouteParams: null,
            panelIcon: 'location-arrow',
            panelTitle: this.$translate.instant('TR_100119-85c37b_TR')
        };

        this.dashboardOverview = {
            // List of table service data
            overviewsData: [
                {
                    listOptions: {
                        limit: 6,
                        sort: {
                            field: 'ZoneLastChangeDate',
                            order: 'DESC'
                        }
                    },
                    overviewModel: this.dnsZoneOverviewModel,
                    panelHeaderData: {
                        includeShowAllLink: true,
                        includeShowDetailsLink: false,
                        panelHeaderRoute: 'dns.zones.overview',
                        panelHeaderRouteParams: {},
                        panelIcon: 'location-arrow',
                        panelTitle: this.$translate.instant('STATE.DNS.ZONE.PAGE-TITLE')
                    },
                    serviceItemList: []
                }
            ],
            // Dashboard main menu panel
            panelData: {
                addtionalActionData: {
                    linkList: [
                        {
                            isGranted: this.authContext.isGranted(UiRights.DNS_NSSETS_LIST),
                            route: 'dns.nameserversets.overview',
                            routeParams: {},
                            text: this.$translate.instant('DOMAIN.NAMESERVER.SELECT-TYPES.NAMESERVER-SETS')
                        },
                        {
                            isGranted: this.authContext.isGranted(UiRights.DNS_TEMPLATES_LIST),
                            route: 'dns.templates.overview',
                            routeParams: {},
                            text: this.$translate.instant('MENU.CONTEXT.DNS.TEMPLATES')
                        },
                        {
                            isGranted: this.authContext.isGranted(UiRights.DNS_ZONES_LIST),
                            route: 'dns.zones.jobs',
                            routeParams: {},
                            text: this.$translate.instant('MENU.CONTEXT.JOBS')
                        },
                        {
                            isGranted: this.authContext.isGranted(UiRights.DNS_DDNSCREDENTIALS_LIST),
                            route: 'dns.ddns.credentials.overview',
                            routeParams: {},
                            text: this.$translate.instant('TR_200319-418d09_TR')
                        },
                        {
                            isGranted: this.authContext.isGranted(UiRights.DNS_ZONES_LIST),
                            route: 'dns.zones.content-search',
                            routeParams: {},
                            text: this.$translate.instant('MENU.CONTEXT.DNS.CONTENT-SEARCH')
                        },
                        {
                            isGranted: this.authContext.isGranted(UiRights.DNS_ZONES_EDIT),
                            route: 'dns.zones.content-change',
                            routeParams: {},
                            text: this.$translate.instant('MENU.CONTEXT.DNS.CONTENT-CHANGER')
                        },
                        {
                            isGranted: this.authContext.isGranted(UiRights.DNS_DDNSHOSTNAME_LIST),
                            route: 'dns.ddns.hostnames.overview',
                            routeParams: {},
                            text: this.$translate.instant('TR_130319-9c1524_TR')
                        }
                    ]
                },
                mainHeader: {
                    icon: 'location-arrow',
                    isGranted: this.authContext.isGranted(UiRights.DNS_ZONES_CREATE),
                    route: 'dns.zones.new',
                    routeParams: {},
                    text: this.$translate.instant('STATE.DNS.ZONE.NEW.PAGE-TITLE')
                }
            },
            simpleFilter: this.filterCache.resolveSimpleFilter('DNSZones')
        };
    };
}

export class TemplateDnsDashboardComponent implements ng.IComponentOptions {
    public bindings = {};
    public controller = TemplateDnsDashboardController;
    public controllerAs = '$TemplateDnsDashboardCtrl';
    public template = require('./dashboard-template.html');
}
