import * as ng from 'angular';
import * as Types from '../../../types';
import { Reducer } from '../property-reducer';
import { ApiObjectHandler } from './abstract-handler';

export class BundleHandler extends ApiObjectHandler<Types.BundleApi.Bundle> {
    public get hasDatabaseContingent(): boolean {
        if (this.wrappedObject.effectiveContingentUsage === undefined) {
            return false;
        }
        return this.wrappedObject.effectiveContingentUsage.some(
            (contingent) => contingent.productCodes.some(
                (productCode) => productCode.indexOf('database') === 0
            )
        );
    }

    public get hasDomainContingent(): boolean {
        if (this.wrappedObject.effectiveContingentUsage === undefined) {
            return false;
        }
        return this.wrappedObject.effectiveContingentUsage.some(
            (contingent) => contingent.productCodes.some(
                (productCode) => productCode.indexOf('domain-') === 0
            )
        );
    }

    public get domainContingentCount() {
        let domainContingent = 0;
        if (this.wrappedObject.effectiveContingentUsage === undefined) {
            return domainContingent;
        }
        this.wrappedObject.effectiveContingentUsage.forEach(
            (contingent) => {
                if (contingent.productCodes.some( (pc) => pc.indexOf('domain-') === 0)) {
                    domainContingent = contingent.availableCapacity;
                }
        });
        return domainContingent;
    }

    public get hasEmailContingent(): boolean {
        if (this.wrappedObject.effectiveContingentUsage === undefined) {
            return false;
        }
        return this.wrappedObject.effectiveContingentUsage.some(
            (contingent) => contingent.productCodes.some(
                (productCode) => productCode.indexOf('email-') === 0
            )
        );
    }

    public get hasWebspaceContingent(): boolean {
        if (this.wrappedObject.effectiveContingentUsage === undefined) {
            return false;
        }
        return this.wrappedObject.effectiveContingentUsage.some(
            (contingent) => contingent.productCodes.some(
                (productCode) => productCode.indexOf('webhosting-bundle-webspace') === 0
            )
        );
    }
}
