import * as ng from 'angular';

import { WizardConfirmationBase } from '../../general/confirm';

import { UiRights } from '@/configuration';
import {
    ApiErrorModel, AuthContextService, DnsJobModelService, DomainJobModelService, OrchestratorRobotService,
    WebhostingJobModelService, WebhostingRobotService
} from '@/services/';
import { ErrorMapperService } from '@/services/error-mapper';

export class DomainWizardConfirmation extends WizardConfirmationBase {
    public static $inject: string[] = WizardConfirmationBase.$inject.concat([
        'dnsJobModel',
        'domainJobModel',
        'webhostingJobModel',
        'webhostingRobot'
    ]);

    public ignoreErrorCodesInErrorPanel = ['30001', '40001'];
    public knownJobType = ['Domain', 'Vhost', 'Zone', 'Webspace'];

    constructor(
        protected $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        protected apiErrorModel: ApiErrorModel,
        protected errorMapper: ErrorMapperService,
        protected localInterval: ng.IIntervalService,
        protected orchestratorRobot: OrchestratorRobotService,
        private dnsJobModel: DnsJobModelService,
        private domainJobModel: DomainJobModelService,
        private webhostingJobModel: WebhostingJobModelService,
        private webhostingRobot: WebhostingRobotService
    ) {
        super(
            $timeout,
            $translate,
            apiErrorModel,
            errorMapper,
            localInterval,
            orchestratorRobot
        );

        if (!AuthContextService.isGrantedAll(
            [
                UiRights.DB_JOBS_LIST,
                UiRights.DOM_JOBS_LIST,
                UiRights.DNS_ZONES_LIST,
                UiRights.WEB_JOBS_LIST
            ]
        )) {
            this.isJobDependendWizard = false;
            this.fillNextSteps = () => {
                this.nextSteps.push({
                    iconName: 'globe',
                    linkText: this.$translate.instant('TR_080119-acb35a_TR'),
                    route: 'domain.domains.overview',
                    routeOptions: { reload: true },
                    routeParams: {}
                });
            };
        }
    }

    public getJobByFilter = (filter, cacheTimeout, objectType?: string) => {
        switch (objectType) {
            case 'Vhost':
                return this.webhostingRobot.listJobs(filter)
                    .then(this.normalizeResponseObject);

            case 'Domain':
                return this.domainJobModel.list(null, null, filter)
                    .then(this.normalizeResponseObject);

            case 'Zone':
                return this.dnsJobModel.list(null, null, filter)
                    .then(this.normalizeResponseObject);

            case 'Webspace':
                // eslint-disable-next-line no-case-declarations
                let webspaceId: string;
                filter.subFilter.some((filterPart) => {
                    if (filterPart.field === 'jobObjectId') {
                        webspaceId = filterPart.value;
                        return true;
                    }
                    return false;
                });
                return this.webhostingJobModel.findRunningJobsForWebspaceId(webspaceId)
                    .then(this.normalizeResponseObject);

            default:
                return undefined;
        }
    };
}
