import * as ng from 'angular';

export class MoleculeInformationSslManualValidationController implements ng.IController {
    public static $inject: string[] = [];
    public showValidationInstructions = false;
    public sslJob;
    public validationInfos = [];
    public $onInit(): void {
        if (!!this.sslJob && !!this.sslJob.validationInfos && this.sslJob.validationInfos.length > 0) {
            this.validationInfos = this.sslJob.validationInfos;
            this.showValidationInstructions = true;
        } else {
            this.showValidationInstructions = false;
        }
    }
}

export class MoleculeInformationSslManualValidationComponent implements ng.IComponentOptions {
    public bindings = {
        sslJob: '='
    };
    public controller = MoleculeInformationSslManualValidationController;
    public template = require('./ssl-manual-validation.html');
}
