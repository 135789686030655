/* eslint-disable */

import { UiRights } from '../../configuration';
import * as Types from '../../types';
import { MachineApi } from '../../types';
import { AuthContextService } from '../auth-context';
import {
    RpcClient,
    RpcClientService
} from '../rpc-client';
export const MachineClientService = [
    'rpcClient',
    (platformRpcClient: RpcClientService) => platformRpcClient.Factory('/api/machine/v1/json/', 'machine')
];

export class MachineRobotService {
    public static $inject: string[] = ['machineClient'];

    constructor(
        private machineClient: RpcClient
    ) {}

    public listVms = (filter, limit, page, sort?, timeout?, owner?) => {
        return this.machineClient.request('virtualMachinesFind')
        .timeout(timeout)
        .ownerAccountId(owner)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public listVmsWithoutPagination = (filter, limit, page, sort?, timeout?, owner?) => {
        return this.machineClient.request('virtualMachinesFind')
        .skipPagination()
        .timeout(timeout)
        .ownerAccountId(owner)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public createVm = (virtualMachine) => {
        return this.machineClient.request('virtualMachineCreate')
        .bustCache('billing', 'depositGet')
        .execute({ virtualMachine: virtualMachine });
    };

    public vncSessionCreate = (virtualMachineId: string) => {
        return this.machineClient.request('vncSessionCreate')
            .execute({ virtualMachineId: virtualMachineId });
    };

    public createManagedVm = (virtualMachine, databaseResourcePoolId, webserverResourcePoolId) => {
        return this.machineClient.request('virtualMachineCreate')
        .bustCache('billing', 'depositGet')
        .execute(
            {
                databaseResourcePoolId: databaseResourcePoolId,
                virtualMachine: virtualMachine,
                webserverResourcePoolId: webserverResourcePoolId
            }
        );
    };

    public createIndividualVm = (
        virtualMachine: MachineApi.VirtualMachine,
        virtualMachineSpecification: MachineApi.VirtualMachineSpecification,
        diskSpecification: MachineApi.DiskSpecification,
        retailPrice: number,
        managementType: 'none' | 'individual' | 'platform',
        virtualMachineHostId: 'auto' | string
    ) => {
        if (virtualMachineHostId === 'auto') {
            virtualMachineHostId = undefined;
        }
        return this.machineClient.request('individualVirtualMachineCreate')
            .bustCache('billing', 'depositGet')
            .execute(
                {
                    virtualMachine: virtualMachine,
                    virtualMachineSpecification: virtualMachineSpecification,
                    diskSpecification: diskSpecification,
                    retailPrice: retailPrice,
                    managementType: managementType,
                    virtualMachineHostId: virtualMachineHostId
                }
            );
    };

    public changeVmProduct = (virtualMachineId, product, reset, backupEnabled: boolean = false) => {
        return this.machineClient.request('virtualMachineChangeProduct')
        .bustCache('billing', 'depositGet')
        .execute({
            virtualMachineId: virtualMachineId,
            productCode: product,
            reset: reset,
            backupEnabled: backupEnabled,
        });
    };

    public cancelVirtualMachineDeletion = (virtualMachineId) => {
        return this.machineClient.request('virtualMachineDeletionCancel')
        .execute({ virtualMachineId: virtualMachineId });
    };

    public deleteVirtualMachine = (virtualMachineId, executionDate) => {
        if (executionDate === null) {
            return this.machineClient.request('virtualMachineDelete')
        .execute({ virtualMachineId: virtualMachineId });
        }
        return this.machineClient.request('virtualMachineDelete')
        .execute({ virtualMachineId: virtualMachineId, execDate: executionDate });
    };

    public restoreVm = (virtualMachineId) => {
        return this.machineClient.request('virtualMachineRestore')
        .bustCache('billing', 'depositGet')
        .execute({ virtualMachineId: virtualMachineId });
    };

    public shutdownVm = (virtualMachineId) => {
        return this.machineClient.request('virtualMachineShutdown')
        .execute({ virtualMachineId: virtualMachineId });
    };

    public powerOffVm = (virtualMachineId) => {
        return this.machineClient.request('virtualMachinePowerOff')
        .execute({ virtualMachineId: virtualMachineId });
    };

    public powerOnVm = (virtualMachineId) => {
        return this.machineClient.request('virtualMachinePowerOn')
        .execute({ virtualMachineId: virtualMachineId });
    };

    public purgeRestorableVirtualMachine = (virtualMachineId) => {
        return this.machineClient.request('virtualMachinePurgeRestorable')
        .execute({ virtualMachineId: virtualMachineId });
    };

    public rebootVm = (virtualMachineId) => {
        return this.machineClient.request('virtualMachineReboot')
        .execute({ virtualMachineId: virtualMachineId });
    };

    public resetVm = (virtualMachineId) => {
        return this.machineClient.request('virtualMachineReset')
        .execute({ virtualMachineId: virtualMachineId });
    };

    public enableRescue = (virtualMachineId, rootPassword, publicKeys, reset) => {
        return this.machineClient.request('virtualMachineEnableRescue')
        .execute(
            {
                publicKeys: publicKeys,
                reset: reset,
                rootPassword: rootPassword,
                virtualMachineId: virtualMachineId
            }
        );
    };

    public disableRescue = (virtualMachineId, reset) => {
        return this.machineClient.request('virtualMachineDisableRescue')
        .execute({ virtualMachineId: virtualMachineId, reset: reset });
    };

    public listJobs = (filter, limit, page, sort?) => {
        return this.machineClient.request('jobsFind')
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public jobsFind = (
        filter: Types.Finding.Filter = null,
        limit: number = 25,
        page: number = 1,
        sort: Types.Finding.SortOptions = null,
        skipPagination: boolean = false,
        cacheTimeout?: number
    ) /* : q.IPromise<Types.ManagedApplicationApi.FindJobsResult> */ => {
        cacheTimeout = cacheTimeout || (1000 * 60 * 15);
        return this.machineClient.request('jobsFind')
            .useCache()
            .cacheTimeout(cacheTimeout)
            .skipPagination(skipPagination)
            .execute(
                {
                    filter: filter,
                    limit: limit,
                    page: page,
                    sort: sort
                }
            );
    };

    public getProducts = () => {
        return this.machineClient.request('productsList')
        .skipPagination()
        .execute({ virtualMachineId: '' })
        .then(
            (response) => {
                if (
                    AuthContextService.isGranted(UiRights.MACHINE_MANAGED_SERVER_CREATE)
                    && AuthContextService.isGranted(UiRights.RES_POOL_CREATE)
                ) {
                    return response.response.data;
                } else {
                    return response.response.data.filter(
                        (product) =>  !product.productCode.split('-').includes('managed')
                    );
                }
            }
        );
    };

    public productsListWithoutPagination = (data: any) => {
        return this.machineClient.request('productsList')
        .skipPagination()
        .execute(data);
    };

    public reinstallManagedServer = (virtualMachineId: string) => {
        return this.machineClient.request('managedServerReinstall')
        .execute({ virtualMachineId: virtualMachineId });
    };

    public listPhysicalMachines = (filter, limit, page, sort?, timeout?, owner?) => {
        return this.machineClient.request('physicalMachinesFind')
        .timeout(timeout)
        .ownerAccountId(owner)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public listPhysicalMachinesWithoutPagination = (filter, limit, page, sort?, timeout?, owner?) => {
        return this.machineClient.request('physicalMachinesFind')
        .timeout(timeout)
        .ownerAccountId(owner)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public setRDNS = (ipAddress, fqdn) => {
        return this.machineClient.request('ipAddressSetRdns')
        .execute({ ipAddress: ipAddress, fqdn: fqdn });
    };

    public updateMachine = (virtualMachine, reset) => {
        return this.machineClient.request('virtualMachineUpdate')
        .execute({ virtualMachine: virtualMachine, reset: reset });
    };

    public individualVirtualMachineCreate = (
        virtualMachine,
        virtualMachineSpecification,
        diskSpecification,
        retailPrice,
        managementType
    ) => {
        return this.machineClient.request('individualVirtualMachineCreate')
        .bustCache('billing', 'depositGet')
        .execute(
            {
                diskSpecification: diskSpecification,
                managementType: managementType,
                retailPrice: retailPrice,
                virtualMachine: virtualMachine,
                virtualMachineSpecification: virtualMachineSpecification
            }
        );
    };
}
