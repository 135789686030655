import * as ng from 'angular';
import { UiLanguagesConst, UiLanguagesReverseConst } from '../configuration';

export class LanguageSetting {
    public static Factory() {
        const filterFunction: any = (languageSetting: string, target: string) => {
            switch (target) {
                case 'long': return UiLanguagesConst[languageSetting];
                case 'short': return UiLanguagesReverseConst[languageSetting];
                default: return '';
            }
        };

        return filterFunction;
    }
}
