import ng from 'angular';

import { PanelHeaderData } from '@/atomic-components/molecules/panels';
import { UiLanguagesConst } from '@/configuration/system';
import {
    AsteriskNoteType,
    AuthContextService,
    NextcloudHelperService,
    StorageAppConfigService
} from '@/services';
import { NavigationService } from '@/services/navigation';
import { StorageModelService } from '@/services/storage/storage-model';
import { ManagedApplicationApi } from '@/types';
import { FormDescriptionSpec } from '@/types/view-types/form-description/form-description';

export class TemplateStorageProductAppConfigController {
    public static $inject: string[] = [
        '$state',
        '$translate',
        'navigation',
        'nextcloudHelper',
        'storageAppConfig',
        'storageModel'
    ];

    public asteriskNoteType: AsteriskNoteType;
    public faqArticleIdList: string[] = [];
    public pageHeaderData: PanelHeaderData;
    public nextcloudHasUnfinishedJob = false;
    public storageProduct: ManagedApplicationApi.Nextcloud;
    public appConfiguration: {
        appConfigSchema: string;
        appCurrentConfig: string;
        appId: string;
    };

    public appConfigSchema: FormDescriptionSpec.FormDescriptionLayout;
    public appCurrentConfig: any;
    public appId: string;
    public app: ManagedApplicationApi.NextcloudApp;
    public enabled: boolean;
    public requiresConfiguration: boolean;
    public quotaChangeConfirmed = false;
    public clearUserConfirmed = false;
    public toggleAppInstallationState = false;
    private _addedUsers: { attribute: string; addedUsers: number };
    private _statePrefix: string;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private navigation: NavigationService,
        private nextcloudHelper: NextcloudHelperService,
        private storageAppConfig: StorageAppConfigService,
        private storageModel: StorageModelService
    ) {}

    public $onInit = async (): Promise<void> => {
        this.asteriskNoteType = AuthContextService.account.isCommercialCustomer
                ? AsteriskNoteType.VAT_EXCLUDED
                : AsteriskNoteType.VAT_INCLUDED;
        void this.nextcloudHelper.checkNextcloudJobRunning(this.storageProduct.id).then((jobRunning: boolean) => {
            this.nextcloudHasUnfinishedJob = jobRunning;
        });
        if (this.$state.current.name.startsWith('nextcloud')) {
            this._statePrefix = 'nextcloud.';
        } else {
            this._statePrefix = 'storage.storage-products.';
        }
        const stateName = 'id.app-center';
        this.pageHeaderData = {
            backwardLink: this._statePrefix + stateName,
            backwardText: this.$translate.instant('TR_271020-3df190_TR'),
            panelHeaderRoute: '',
            panelIcon: '/assets/images/logos/nextcloud-bw.svg',
            panelIconPath: true,
            panelTitle: this.storageProduct.name
        };
        this.appCurrentConfig = JSON.parse(this.appConfiguration.appCurrentConfig);
        this.appConfigSchema = JSON.parse(this.appConfiguration.appConfigSchema);
        this.appId = this.appConfiguration.appId;
        await this.storageModel
            .nextcloudAppList(this.storageProduct.id, UiLanguagesConst[AuthContextService.user.language])
            .then((response) => {
                const appList: ManagedApplicationApi.NextcloudApp[] = response.response.data;
                this.app = appList.find((app) => app.id === this.appId);
            });
        const storageAppConfig = this.storageAppConfig.loadStorageAppConfig(this.storageProduct.id);
        const tempAppConfig = storageAppConfig?.appConfigurations.find(
            (conf) => conf.appId === this.appId
        );
        if (tempAppConfig) {
            const configuration = JSON.parse(tempAppConfig.configuration);
            for (const key in configuration) {
                if ([undefined, null].indexOf(configuration[key]) < 0) {
                    this.appConfigSchema.data.fields.forEach((field) => {
                        if (key === field.key) {
                            field.default = configuration[key];
                        }
                    });
                }
            }
        } else if (this.appCurrentConfig) {
            this.appConfigSchema.data.fields.forEach((field) => {
                if (this.appCurrentConfig[field.key]) {
                    field.default = this.appCurrentConfig[field.key];
                }
            });
        }
        this.enabled = this.storageProduct.enabledApps.includes(this.appId);
    };

    public save = (
        keenData: FormDescriptionSpec.FieldObject[],
        enabled: boolean
    ): void => {
        let storageAppConfig;

        // first lets make sure the selected app is not in the list of enabled apps
        this.storageProduct.enabledApps = this.storageProduct.enabledApps.filter((app) => app !== this.appId);

        if (enabled) {
            if (this.appId === 'talk') {
                this.storageProduct.enabledApps = this.storageProduct.enabledApps.filter((app) => app !== 'talk_hpb');
            }
            if (this.appId === 'talk_hpb') {
                this.storageProduct.enabledApps = this.storageProduct.enabledApps.filter((app) => app !== 'talk');
            }
            this.storageProduct.enabledApps.push(this.appId);
            if (keenData) {
                const configuration: Record<string, any> = {};
                for (const conf of keenData) {
                    configuration[conf.key] = conf.value;
                }
                storageAppConfig = [
                    {
                        appId: this.$state.params.appId,
                        configuration: JSON.stringify(configuration)
                    }
                ];
            }
        }

        if (['collabora', 'talk_hpb'].includes(this.app.id)
            &&  typeof this._addedUsers?.attribute === 'string'
            &&  typeof this._addedUsers?.addedUsers === 'number'
        ) {
            if (enabled) {
                (this.storageProduct as Record<string, any>)[this._addedUsers.attribute] = this._addedUsers.addedUsers;
            } else if (this.clearUserConfirmed) {
                (this.storageProduct as Record<string, any>)[this._addedUsers.attribute] =  (this.storageProduct as Record<string, any>)[`${this._addedUsers.attribute}Included`];
            }
        }

        // If selected collobora users = 0, deinstall collabora app
        if (this.app.id === 'collabora' && this._addedUsers?.addedUsers === 0) {
            storageAppConfig = undefined;
        }

        void this.storageModel
            .nextcloudUpdate(this.storageProduct, storageAppConfig)
            .then((response: { status: string }) => {
                if (['successful', 'pending'].indexOf(response.status) >= 0) {
                    const stateName = 'id.app-center';
                    void this.navigation.go(this._statePrefix + stateName, null, {
                        reload: true
                    });
                }
            });
    };

    public cancel = (): void => {
        const stateName = 'id.app-center';
        void this.navigation.go(this._statePrefix + stateName);
    };

    public toggleAppInstallationCallback = (value: boolean): void => {
        this.toggleAppInstallationState = value;
    };

    public addUsersCallback = (value: { attribute: string; addedUsers: number }): void => {
        this._addedUsers = value;
    };

    public confirmationCallback = (value: boolean): void => {
        this.quotaChangeConfirmed = value;
    };

    public clearUserCallback = (value: boolean): void => {
        this.clearUserConfirmed = value;
    };

    public get showAsteriskOutput(): boolean {
        return !!(this.app?.productCode);
    }

    public get disabledSaveButton(): boolean {
        return this.nextcloudHasUnfinishedJob
            || (!this.quotaChangeConfirmed && this.addedUsersIncreased);
    }

    public get addedUsersIncreased(): boolean {

        if (this.appId === 'talk_hpb') {
            const existingUsers: number = (this.storageProduct as Record<string, any>).talkUsers;
            return this._addedUsers?.addedUsers > existingUsers;
        }
        if (this.appId === 'collabora') {
            const existingUsers: number = (this.storageProduct as Record<string, any>).officeUsers;
            return this._addedUsers?.addedUsers > existingUsers;
        }
        return false;
    }

    public get showClearUsersConfirm(): boolean {
        return (this.enabled === this.toggleAppInstallationState)
            && this._currentUserIncreased();
    }

    private _currentUserIncreased = (): boolean => {
        switch (true) {
            case ['talk_hpb'].includes(this.app?.id):
                return (this.storageProduct as Record<string, any>).talkUsers
                    > (this.storageProduct as Record<string, any>).talkUsersIncluded;
            case ['collabora'].includes(this.app?.id):
                return (this.storageProduct as Record<string, any>).officeUsers
                    > (this.storageProduct as Record<string, any>).officeUsersIncluded;
        }
    };

    public get showUserConfiguration(): boolean {
        return (this.enabled !== this.toggleAppInstallationState)
            && ['collabora', 'talk_hpb'].includes(this.app.id);
    }
}

export class TemplateStorageProductAppConfigComponent implements ng.IComponentOptions {
    public bindings = {
        appConfiguration: '<',
        storageProduct: '<'
    };
    public controller = TemplateStorageProductAppConfigController;
    public template = require('./storage-product-app-config-template.html');
}
