import * as ng from 'angular';
import {
    BundleModelService,
    DatabaseModelService,
    DnsZoneModelService,
    DomainModelService,
    VhostModelService,
    WebspaceModelService
} from '../../../../services';
import { ViewTypes } from '../../../../types/view-types';

import './wizard-orchestration-job-confirmation.scss';

export class MoleculeWizardOrchestrationJobConfirmationController implements ng.IController {
    public static $inject: string[] = [
        '$translate',
        'bundleModel',
        'databaseModel',
        'dnsZoneModel',
        'domainModel',
        'vhostModel',
        'webspaceModel'
    ];

    public orchestrationProductId:      string;
    public orchestrationProductType:    string;
    public bundleJobInfo:               ViewTypes.BundleJobInfo[] | any = [];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private bundleModel: BundleModelService,
        private databaseModel: DatabaseModelService,
        private dnsZoneModel: DnsZoneModelService,
        private domainModel: DomainModelService,
        private vhostModel: VhostModelService,
        private webspaceModel: WebspaceModelService
    ) {}

    public async $onInit() {
        switch (this.orchestrationProductType) {
            case 'Bundle':
                const bundleModelResponse = await this.bundleModel.findOneById(this.orchestrationProductId);
                bundleModelResponse.forEach(bundle => {
                    this.bundleJobInfo.push({
                        name: this.$translate.instant('BUNDLE.GENERAL.BUNDLE'),
                        value: bundle.name,
                        link: {
                            route: 'bundle.id.dashboard',
                            routeOptions: {
                                reload: false
                            },
                            routeParams: {
                                bundleId: bundle.id
                            }
                        }
                    });
                });
                break;

            case 'Zone':
                const zoneModelResponse = await this.dnsZoneModel.findOne(this.orchestrationProductId);
                this.bundleJobInfo.push({
                    name: this.$translate.instant('97f4ed90-36d2-41fe-8e52-2455b38f7397'),
                    value: zoneModelResponse.nameUnicode,
                    link: {
                        route: 'dns.zones.id.edit',
                        routeOptions: {
                            reload: false
                        },
                        routeParams: {
                            zoneId: zoneModelResponse.id
                        }
                    }
                },
                {
                    name: this.$translate.instant('TR_190319-19713d_TR'),
                    value: zoneModelResponse.type
                },
                {
                    name: this.$translate.instant('TR_190319-465ff2_TR'),
                    value: zoneModelResponse.emailAddress
                });
                break;

            case 'Domain':
                const domainModelResponse = await this.domainModel.findOne(this.orchestrationProductId);
                this.bundleJobInfo.push({
                    name: this.$translate.instant('31c24bd9-e8de-4aa4-a0ed-502bf59f3086'),
                    value: domainModelResponse.nameUnicode,
                    link: {
                        route: 'domain.domains.id.edit',
                        routeOptions: {
                            reload: false
                        },
                        routeParams: {
                            domainId: domainModelResponse.id
                        }
                    }
                });

                domainModelResponse.nameservers.forEach(ns => {
                    this.bundleJobInfo.push(
                        {
                            name: this.$translate.instant('fe88028d-a019-42b8-b958-06b99fc9acf6'),
                            value: ns.name
                        }
                    );
                });
                break;

            case 'Webspace':
                const webspaceModelResponse = await this.webspaceModel.findOne(this.orchestrationProductId);
                this.bundleJobInfo.push({
                    name: this.$translate.instant('TR_110119-315292_TR'),
                    value: webspaceModelResponse.name,
                    link: {
                        route: 'webhosting.webspaces.id.edit',
                        routeOptions: {
                            reload: false
                        },
                        routeParams: {
                            webspaceId: webspaceModelResponse.id
                        }
                    }
                },
                {
                    name: this.$translate.instant('6d300513-16d8-46d4-92c3-0a2df38feb83'),
                    value: webspaceModelResponse.serverIpv4
                },
                {
                    name: this.$translate.instant('47f176d6-e2ca-47a3-9ad1-273260be395c'),
                    value: webspaceModelResponse.serverIpv6
                });

                webspaceModelResponse.accesses.forEach(access => {
                    this.bundleJobInfo.push(
                        {
                            name: this.$translate.instant('TR_100119-ee0d59_TR'),
                            value: this.$translate.instant('TR_070519-7cc2b2_TR',  { accessUserName: access.userName})
                        }
                    );
                });
                break;

            case 'VHost':
                const vhostModelResponse = await this.vhostModel
                    .findOne(this.orchestrationProductId);
                const webspaceVhostModelResponse = await this.webspaceModel
                    .findOne(vhostModelResponse.webspaceId);
                const bundleVhostModelResponse = await this.bundleModel
                    .findOne(webspaceVhostModelResponse.bundleId);
                this.bundleJobInfo.push({
                    name: this.$translate.instant('TR_170120-61cd34_TR'),
                    value: vhostModelResponse.webRoot,
                    link: {
                        route: 'bundle.id.domains.id.edit',
                        routeOptions: {
                            reload: false
                        },
                        routeParams: {
                            bundleId: bundleVhostModelResponse.id,
                            domainId: vhostModelResponse.id,
                            vhost: 'vhost'
                        }
                    }
                },
                {
                    name: this.$translate.instant('254530de-a67b-4e17-9579-d9c467476676'),
                    value: vhostModelResponse.serverType
                },
                {
                    name: this.$translate.instant('TR_120319-52df93_TR'),
                    value: `http://${vhostModelResponse.systemAlias}`,
                    link: {
                        url: `http://${vhostModelResponse.systemAlias}`,
                        routeTarget: '_blank'
                    }
                },
                {
                    name: this.$translate.instant('15df9d2a-909b-49af-87f8-49e1aa4cc76a'),
                    value: vhostModelResponse.phpVersion
                },
                {
                    name: this.$translate.instant('127915f5-243f-4fbf-90cc-32e00cd8f1c3'),
                    value: `http://${vhostModelResponse.domainName}`,
                    link: {
                        url: `http://${vhostModelResponse.domainName}`,
                        routeTarget: '_blank'
                    }
                });
                break;

            case 'Database':
                const databaseModelResponse = await this.databaseModel.findOneById(this.orchestrationProductId);
                this.bundleJobInfo.push({
                    name: this.$translate.instant('TR_120319-52df93_TR'),
                    value: databaseModelResponse.hostName,
                    link: {
                        route: 'database.databases.id.edit',
                        routeOptions: {
                            reload: false
                        },
                        routeParams: {
                            databaseId: databaseModelResponse.id
                        }
                    }
                },
                {
                    name: this.$translate.instant('TR_090119-1201bd_TR'),
                    value: databaseModelResponse.dbName
                });

                databaseModelResponse.accesses.forEach((access, index) => {
                    this.bundleJobInfo.push(
                        {
                            name: this.$translate.instant('TR_100119-ee0d59_TR'),
                            value: this.$translate.instant('TR_070519-7cc2b2_TR', { accessUserName: access.dbLogin })
                        }
                    );
                });
                break;

            default:
                break;
        }
    }
}

export class MoleculeWizardOrchestrationJobConfirmationComponent implements ng.IComponentOptions {
    public bindings = {
        orchestrationProductId: '<',
        orchestrationProductType: '<'
    };
    public template = require('./wizard-orchestration-job-confirmation.html');
    public controller =  MoleculeWizardOrchestrationJobConfirmationController;
}
