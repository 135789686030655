import ng from 'angular';
import { PanelHeaderData } from '@/atomic-components';
import { UiRights } from '@/configuration';
import { AuthContextService } from '@/services';

export class TemplateBillingDashboardController {
    public static $inject: string[] = ['$translate', 'authContext'];

    public pageHeaderData: PanelHeaderData;
    public leftPanelArea: PanelHeaderData[] = [];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            backwardLink: '',
            backwardText: this.$translate.instant('TR_090119-7857df_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: 'money-bill-alt',
            panelTitle: this.$translate.instant('TR_090119-b0fd78_TR')
        };

        const leftPanelViews: PanelHeaderData[] = [];
        if (this.authContext.isGranted(UiRights.BIL_LIST_DOCUMENTS)) {
            leftPanelViews.push(
                {
                    includeShowAllLink: true,
                    panelHeaderRoute: 'billing.invoices.overview',
                    panelIcon: 'paperclip',
                    panelItems: [],
                    panelTitle: this.$translate.instant('MENU.MAIN.BILLING.INVOICE'),
                    panelType: 'invoices'
                }
            );
        }
        if (this.authContext.isGrantedAll( [UiRights.BIL_GET_DEPOSIT, UiRights.BIL_DEPOSIT_FUND])) {
            leftPanelViews.push(
                {
                    includeShowAllLink: true,
                    panelHeaderRoute: 'billing.payment-options',
                    panelIcon: 'tasks-alt',
                    panelItems: [
                        {
                            route: 'billing.payment-options',
                            text: this.$translate.instant('TR_090119-0c1351_TR')
                        },
                        {
                            route: 'billing.payment-options',
                            text: this.$translate.instant('TR_090119-93d160_TR')
                        },
                        {
                            route: 'billing.payment-options',
                            text: this.$translate.instant('STATE.ACCOUNT.VOUCHER.PAGE-TITLE')
                        }
                    ],
                    panelTitle: this.$translate.instant('TR_090119-a8b028_TR'),
                    panelType: 'paymentoptions'
                }
            );
        }
        if (this.authContext.isGranted(UiRights.BIL_LIST_PAYMENTS)) {
            leftPanelViews.push(
                {
                    includeShowAllLink: true,
                    panelHeaderRoute: 'billing.payments-overview',
                    panelIcon: 'history',
                    panelItems: [],
                    panelTitle: this.$translate.instant('TR_040619-4ca9a1_TR'),
                    panelType: 'paymentoverview'
                }
            );
        }
        if (
            this.authContext.isGrantedAny(
                [
                    UiRights.BIL_LIST_ARTICLE_PRICES,
                    UiRights.BIL_LIST_BOOKINGS,
                    UiRights.BIL_LIST_DOCUMENTS,
                    UiRights.BIL_LIST_PAYMENTS,
                    UiRights.BIL_LIST_PRICES_DATABASE,
                    UiRights.BIL_LIST_PRICES_DNS,
                    UiRights.BIL_LIST_PRICES_DOMAIN,
                    UiRights.BIL_LIST_PRICES_EMAIL,
                    UiRights.BIL_LIST_PRICES_MACHINE,
                    UiRights.BIL_LIST_PRICES_SSL,
                    UiRights.BIL_LIST_PRICES_WEBHOSTING
                ]
            )
        ) {
            leftPanelViews.push(
                {
                    includeShowAllLink: true,
                    panelHeaderRoute: 'billing.pricelists',
                    panelIcon: 'funnel-dollar',
                    panelTitle: this.$translate.instant('TR_080119-5c3488_TR'),
                    panelType: 'price-lists'
                }
            );
        }

        this.leftPanelArea = ng.copy(leftPanelViews)
        .map(
            (view) => {
                view.panelTitle = this.$translate.instant(view.panelTitle);
                if ([undefined, null].indexOf(view.panelItems) < 0) {
                    view.panelItems = view.panelItems
                    .map(
                        (item) => {
                            item.text = this.$translate.instant(item.text);
                            return item;
                        }
                    );
                }

                return view;
            }
        );
    };
}

export class TemplateBillingDashboardComponent implements ng.IComponentOptions {
    public bindings = {};
    public controller = TemplateBillingDashboardController;
    public controllerAs = '$TemplateBillingDashboardCtrl';
    public template = require('./dashboard-template.html');
}
