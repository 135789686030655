import * as ng from 'angular';
import { ManagedApplicationApi } from '@/types';

export class OrganismEditFormNextcloudUpgradeController {
    public static $inject: string[] = [];

    public nextcloud: ManagedApplicationApi.Nextcloud;
}

export class OrganismEditFormNextcloudUpgradeComponent implements ng.IComponentOptions {
    public bindings = {
        nextcloud: '<'
    };
    public controller = OrganismEditFormNextcloudUpgradeController;
    public template = require('./nextcloud-upgrade.html');
}
