import {
    AlertManagerService,
    AuthContextService,
    NavigationService,
    RestrictionHelperService
} from '../../../../../services';
import { PanelType } from '../../../../molecules';

import * as ng from 'angular';

import './restrictions-edit.scss';

export class OrganEditPanelRestrictionsEditController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'alertManager',
        'navigation'
    ];

    public panelTypeOverride: PanelType = PanelType.MISCELLANEOUS_ACTION;
    public apiObject;
    public restrictionHelper: RestrictionHelperService;
    public showObjectId: boolean;
    public showNewRestrictionPanel = false;
    public currentUsersRestriction;
    public restrictionList;
    public initialRestrictionList;
    public markedForRemoval = [];
    public markedForNotifyOnRemoval = {};
    public newRestriction;
    public restrictionTypesDropdownOptions = [];

    private _removeKeyCallbacks: any = {};

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private navigation: NavigationService
    ) {}

    public $onInit = () => {
        this.restrictionList = this.restrictionHelper.getRestrictionList();
        this.initialRestrictionList = ng.copy(this.restrictionList);
        this.currentUsersRestriction = this.restrictionHelper.getRestrictionForUser(AuthContextService.account.id);
        this.initNewRestriction();
        this.restrictionHelper.getAvailableRestrictionTypes().map(
            (type) => {
                if (this.restrictionHelper.isUserEditableType(type.restriction)) {
                    this.restrictionTypesDropdownOptions.push({
                        name: type.name,
                        value: type.restriction
                    });
                }
            }
        );
    };

    public validate = () => {
        return true;
    };

    public isMarkedForRemoval = (ElementId) => { // used in template
        return this.markedForRemoval.indexOf(ElementId) >= 0;
    };

    public canBeEdited = (restrictionElement) => { // used in template
        return restrictionElement.accountId !== null
            && !this.isMarkedForRemoval(restrictionElement.id)
            && this.restrictionHelper.isUserEditableType(restrictionElement.type);
    };

    public addRestriction = () => { // used in template
        this.showNewRestrictionPanel = true;
    };

    public closeAddRestriction = () => { // used in template
        this.showNewRestrictionPanel = false;
        this.initNewRestriction();
    };

    public removeRestriction = (ElementId) => { // used in template
        if ([undefined, null].indexOf(this._removeKeyCallbacks[ElementId]) >= 0) {
            this._removeKeyCallbacks[ElementId] = () => {
                if (this.markedForRemoval.indexOf(ElementId) >= 0) {
                    for (let i = 0; i < this.markedForRemoval.length; i++) {
                        if (this.markedForRemoval[i] === ElementId) {
                            this.markedForRemoval.splice(i, 1);
                        }
                    }
                } else {
                    this.markedForRemoval.push(ElementId);
                }
            };
        }
        return this._removeKeyCallbacks[ElementId];
    };

    public resetRestrictions = (apiResponse?) => {
        if (apiResponse !== undefined) {
            this.restrictionList = this.restrictionHelper.getRestrictionList();
            this.initialRestrictionList = ng.copy(this.restrictionList);
            this.currentUsersRestriction = this.restrictionHelper.getRestrictionForUser(AuthContextService.account.id);
        } else {
            this.restrictionList = ng.copy(this.initialRestrictionList);
        }
        this.markedForRemoval = [];
        this.showNewRestrictionPanel = false;
        this.initNewRestriction();
    };

    public saveCallback = () => {
        const identField = this.restrictionHelper.getIdIdentField();
        const apiObject = {
            restrictionIdsToDelete: [],
            restrictionsToAdd: [],
            restrictionsToUpdate: this.getChangedRestrictions()
        };
        apiObject[identField.apiField] = this.apiObject[identField.objectField];
        if (this.markedForRemoval.length > 0) {
            apiObject.restrictionIdsToDelete = this.markedForRemoval;
        }
        if (this.showNewRestrictionPanel) {
            apiObject.restrictionsToAdd.push(this.newRestriction);
        }
        this.restrictionHelper.model[this.restrictionHelper.apiMethod](apiObject).then(
            () => {
                this.alertManager.success(this.$translate.instant('TR_250519-ecc871_TR'));
                this.$timeout( () => {this.navigation.reloadCurrentState(); }, 500);
            },
            (apiError) => {
                this.resetRestrictions();
                return apiError;
            }
        );
    };

    private getChangedRestrictions = () => {
        const changedRestrictions = [];
        const restrictionsList = this.restrictionList.restrictions;
        const initialRestrictionList = this.initialRestrictionList.restrictions;
        const restrictionTypeList = Object.keys(restrictionsList);
        restrictionTypeList.forEach(
            (restrictionType) => {
                restrictionsList[restrictionType].forEach(
                    (restriction, index) => {
                        if (
                            restriction.information !== initialRestrictionList[restrictionType][index].information
                            || restriction.internalComment
                                    !== initialRestrictionList[restrictionType][index].internalComment
                        ) {
                            changedRestrictions.push(restriction);
                        }
                    }
                );
            }
        );
        return changedRestrictions;
    };

    private initNewRestriction = () => {
        this.newRestriction = {
            accountId: AuthContextService.account.id,
            information: '',
            internalComment: '',
            type: this.restrictionHelper.getDefaultType(),
            createEvent: false
        };
    };
}

export class OrganEditPanelRestrictionsEditComponent implements ng.IComponentOptions {
    public bindings = {
        apiObject: '<',
        restrictionHelper: '<',
        showObjectId: '<'
    };
    public controller = OrganEditPanelRestrictionsEditController;
    public template = require('./restrictions-edit.html');
}
