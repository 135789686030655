import ng from 'angular';

import { SystemHelperService } from '@/services';

export type HandlePropertyFilter = (value: string|number) => string|number;

type THandleProperty = {
    city: string;
    country:  string;
    emailAddress:  string;
    extCaLegalType:  string;
    extCatIntendedUsage:  string;
    extCompanyNumber:  string;
    extCompanyNumberCountry:  string;
    extCountryOfBirth:  string;
    extDateOfBirth:  string;
    extGender:  string;
    extIdentificationCardCountry:  string;
    extIdentificationCardIssueDate:  string;
    extIdentificationCardIssuingAuthority: string;
    extIdentificationCardNumber:  string;
    extIdentificationCardValidUntil: string;
    extNationality:  string;
    extPlaceOfBirth:  string;
    extPlaceOfBirthPostalCode:  string;
    extProAuthorityName:  string;
    extProAuthorityUrl:  string;
    extProLicenseNumber:  string;
    extProProfession:  string;
    extTaxId:  string;
    extTaxIdCountry:  string;
    extTradeMarkCountry: string;
    extTradeMarkDateOfApplication: string;
    extTradeMarkDateOfRegistration: string;
    extTradeMarkName:  string;
    extTradeMarkRegisterNumber: string;
    extTradeMarkRegistrationAuthority: string;
    extTradingName:  string;
    extTravelUniqueIdentificationNumber:  string;
    extUkType:  string;
    extVatId:  string;
    extVatIdCountry:  string;
    extXxxMemberId:  string;
    faxNumber:  string;
    name:  string;
    organization:  string;
    phoneNumber:  string;
    postalCode:  string;
    state:  string;
    street:  string;
};
export class HandleProperty {
    public static $inject: string[] = ['$translate', 'systemHelper'];

    public static Factory(
        $translate: ng.translate.ITranslateService,
        systemHelper: SystemHelperService
    ): HandlePropertyFilter {
        const propertyMap: THandleProperty = {
            city: $translate.instant('d25a5fcf-9137-442b-9842-9d226a02409a'),
            country: $translate.instant('0c29de61-bfdb-4f7b-9b5e-d8e99190b64c'),
            emailAddress: $translate.instant('TR_080119-8dd4a1_TR'),
            extCaLegalType: $translate.instant('DOMAIN.HANDLE.LEGAL-FORM.LABEL') + ' (.ca)',
            extCatIntendedUsage: $translate.instant('DOMAIN.HANDLE.INTENDED-USES.LABEL') + ' (.cat)',
            extCompanyNumber: $translate.instant('TR_150119-5ae402_TR'),
            extCompanyNumberCountry: $translate.instant('DOMAIN.HANDLE.COMPANY-NUMBER-COUNTRY.LABEL'),
            extCountryOfBirth: $translate.instant('DOMAIN.HANDLE.PLACE-OF-BIRTH-COUNTRY.LABEL'),
            extDateOfBirth: $translate.instant('DOMAIN.HANDLE.DATE-OF-BIRTH.LABEL'),
            extGender: $translate.instant('DOMAIN.HANDLE.GENDER.LABEL'),
            extIdentificationCardCountry: $translate.instant('TR_150119-fcb354_TR'),
            extIdentificationCardIssueDate: $translate.instant('TR_150119-df5325_TR'),
            extIdentificationCardIssuingAuthority:
                $translate.instant('35df828d-25c2-456b-8b85-46c9072ccfc8')
                    + ' (' + $translate.instant('DOMAIN.HANDLE.HEADLINE.IDENTITY-CARD') + ')',
            extIdentificationCardNumber: $translate.instant('DOMAIN.HANDLE.IDENTIFICATION-CARD-NUMBER.LABEL'),
            extIdentificationCardValidUntil:
                $translate.instant('DOMAIN.HANDLE.VALID-UNTIL.LABEL')
                    + ' (' + $translate.instant('DOMAIN.HANDLE.HEADLINE.IDENTITY-CARD') + ')',
            extNationality: $translate.instant('TR_181220-ee530e_TR') + ' (.eu)',
            extPlaceOfBirth: $translate.instant('DOMAIN.HANDLE.PLACE-OF-BIRTH.LABEL'),
            extPlaceOfBirthPostalCode: $translate.instant('DOMAIN.HANDLE.PLACE-OF-BIRTH-ZIPCODE.LABEL'),
            extProAuthorityName: $translate.instant('35df828d-25c2-456b-8b85-46c9072ccfc8') + ' (.pro)',
            extProAuthorityUrl: $translate.instant('DOMAIN.HANDLE.ISSUING-WEBSITE.LABEL') + ' (.pro)',
            extProLicenseNumber: $translate.instant('DOMAIN.HANDLE.LICENSE-NUMBER.LABEL') + ' (.pro)',
            extProProfession: $translate.instant('DOMAIN.HANDLE.PROFESSION.LABEL') + ' (.pro)',
            extTaxId: $translate.instant('DOMAIN.HANDLE.TAX-ID.LABEL'),
            extTaxIdCountry: $translate.instant('DOMAIN.HANDLE.TAX-ID-COUNTRY.LABEL'),
            extTradeMarkCountry:
                $translate.instant('DOMAIN.HANDLE.COUNTRY-OF-REGISTRATION.LABEL')
                    + ' (' + $translate.instant('DOMAIN.HANDLE.HEADLINE.BRAND') + ')',
            extTradeMarkDateOfApplication:
                $translate.instant('DOMAIN.HANDLE.APPLICATION-DATE.LABEL')
                    + ' (' + $translate.instant('DOMAIN.HANDLE.HEADLINE.BRAND') + ')',
            extTradeMarkDateOfRegistration:
                $translate.instant('DOMAIN.HANDLE.REGISTRY-NUMBER.LABEL')
                    + ' (' + $translate.instant('DOMAIN.HANDLE.HEADLINE.BRAND') + ')',
            extTradeMarkName: $translate.instant('DOMAIN.HANDLE.BRANDNAME.LABEL'),
            extTradeMarkRegisterNumber:
                $translate.instant('DOMAIN.HANDLE.REGISTRY-NUMBER.LABEL')
                + ' (' + $translate.instant('DOMAIN.HANDLE.HEADLINE.BRAND') + ')',
            extTradeMarkRegistrationAuthority:
                $translate.instant('35df828d-25c2-456b-8b85-46c9072ccfc8')
                    + ' (' + $translate.instant('DOMAIN.HANDLE.HEADLINE.BRAND') + ')',
            extTradingName: $translate.instant('DOMAIN.HANDLE.TRADING-NAME.LABEL'),
            extTravelUniqueIdentificationNumber: $translate.instant('TR_190220-faa00d_TR'),
            extUkType: $translate.instant('TR_070519-16189c_TR') + ' (.uk)',
            extVatId: $translate.instant('DOMAIN.HANDLE.VAT-ID.LABEL'),
            extVatIdCountry: $translate.instant('DOMAIN.HANDLE.VAT-ID-COUNTRY.LABEL'),
            extXxxMemberId: $translate.instant('DOMAIN.HANDLE.MEMBERSHIP-ID.LABEL') + ' (.xxx)',
            faxNumber: $translate.instant('ACCOUNT.BASICDATA.CONTACT.FAX-NUMBER.LABEL'),
            name: $translate.instant('DOMAIN.HANDLE.NAME.LABEL'),
            organization: $translate.instant('TR_090119-9044b3_TR'),
            phoneNumber: $translate.instant('DOMAIN.HANDLE.PHONE.LABEL'),
            postalCode: $translate.instant('DOMAIN.HANDLE.ZIPCODE.LABEL'),
            state: $translate.instant('DOMAIN.HANDLE.STATE.LABEL'),
            street: $translate.instant('DOMAIN.HANDLE.ADDRESS.LABEL')
        };

        return (value: string | number) => Object.prototype.hasOwnProperty.call(propertyMap, value)
            ? systemHelper.getPropertyOfObject(propertyMap, value as keyof THandleProperty)
            : value
        ;
    }

    constructor(
        private $translate: ng.translate.ITranslateService,
        private systemHelper: SystemHelperService
    ) {}
}

export class HandleProperties {
    public static $inject: string[] = ['handlePropertyFilter'];

    public static Factory(handlePropertyFilter: (value: string|number, index: number, array: unknown[]) => void) {
        return (properties: string|number[]) => Array.isArray(properties)
            ? properties.map(handlePropertyFilter)
            : properties;
    }
}
