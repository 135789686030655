import * as ng from 'angular';

export class FunctionRelayService {
    private static functionArray = [];

    public call(functionName, argumentMap) {
        const functionObject = this.getFunction(functionName);

        if (ng.isFunction(functionObject.function)) {
            functionObject.function(argumentMap);
        } else {
            functionObject.calls.push(argumentMap);
        }
    }

    public register(functionName, functionReference) {
        const functionObject = this.getFunction(functionName);
        functionObject.function = functionReference;

        if (functionObject.calls.length > 0) {
            ng.forEach(
                functionObject.calls,
                (argumentMap) => functionObject.function(argumentMap)
            );
        }
    }

    private getFunction(functionName) {
        const functionObjects = FunctionRelayService.functionArray
        .filter((functionObject) => functionObject.name === functionName);

        if (functionObjects.length === 1) {
            return functionObjects[0];
        } else {
            FunctionRelayService.functionArray.push({
                calls: [],
                function: null,
                name: functionName
            });

            return this.getFunction(functionName);
        }
    }
}
