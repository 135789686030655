import ng from 'angular';

import { ApiKeyModelService } from '@/services/account/api-key-model';
import { AuthContextService } from '@/services/auth-context';
import * as Types from '@/types';
import { EditPanelStatus } from '@/atomic-components/molecules';

export class OrganismNewFormApiKeyController {
    public static $inject: string[] = ['$state', 'apiKeyModel'];

    public userPanelRight;
    public initialStatus = EditPanelStatus.ACTIVE;
    public newApiKey: null|string;
    public allRights: any[];
    public userData: Types.AccountApi.User;
    public rightsInTemplates: any[];
    public panelRightSettings: any;
    public apiKeyObject;

    private _apiKeyObjectTmp = {
        allowedIps: [],
        name: '',
        rights: [],
        userId: ''
    };
    private originalUserData: Types.AccountApi.User;

    constructor(
        private $state: ng.ui.IStateService,
        private apiKeyModel: ApiKeyModelService
    ) {}

    public $onInit(): void {
        this.originalUserData = ng.copy(this.userData);
        this.resetForm();
        this.panelRightSettings = {
            editPanelButton: false
        };
    }

    public get showApiKeyRights() {
        return this.apiKeyObject.name.length > 0;
    }

    public get successfullyGeneratedKey() {
        return this.newApiKey !== null;
    }

    public get confirmationRoute() {
        if (this.$state.current.name.split('.').indexOf('subaccounts') < 0) {
            return AuthContextService.isOwnUser(this.userData) ? 'profile' : 'account.users.id.edit';
        }

        return 'reseller.subaccounts.id.users.id.edit';
    }

    public resetForm = (): void => {
        this.apiKeyObject = ng.copy(this._apiKeyObjectTmp);
        this.userData = ng.copy(this.originalUserData);
        this.apiKeyObject.userId = this.userData.id;
        this.newApiKey = null;
    };

    public save = () => {
        const newApiKey = this.apiKeyObject;
        newApiKey.rights = this.userData.rights;
        newApiKey.allowedIps = this._splitArray(newApiKey.allowedIps);

        return this.apiKeyModel.create(newApiKey).then(
            (result) => {
                this.newApiKey = result.key;
                return Promise.reject(false);
            },
            () => Promise.reject(false)
        );
    };

    private _splitArray = (value) => {
        if (typeof value !== 'string') {
            return [];
        }

        if (value === undefined || value === '') {
            return [];
        }
        return value.split('\n');
    };

}

export class OrganismNewFormApiKeyComponent implements ng.IComponentOptions {
    public bindings = {
        allRights: '<',
        rightsInTemplate: '<',
        userData: '<'
    };

    public controllerAs = '$editFormOrganism';
    public controller = OrganismNewFormApiKeyController;
    public template = require('./api-key-new.html');
}
