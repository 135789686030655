/* eslint-disable */

import { VhostProductCodesConst } from '../../configuration';
import { DatabaseModelService } from '../database';
import { FunctionRelayService } from '../function-relay';
import { ResourceRobotService } from '../resource';
import { MachineRobotService } from './../machine/robot';
import { AppInstallerRobotService, OsInstallerRobotService } from './robots';

import * as ng from 'angular';

function getRandomInt(min: any, max: any) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export class InstallerModelService {
    public static $inject: string[] = [
        'appInstallerRobot',
        'databaseModel',
        'functionRelay',
        'machineRobot',
        'osInstallerRobot',
        'resourceRobot'
    ];

    public installFinished = false;
    public installFailed = false;
    public redirectionTargetPath = '';

    constructor(
        private appInstallerRobot: AppInstallerRobotService,
        private databaseModel: DatabaseModelService,
        private functionRelay: FunctionRelayService,
        private machineRobot: MachineRobotService,
        private osInstallerRobot: OsInstallerRobotService,
        private resourceRobot: ResourceRobotService
    ) {}

    public list = (type: any, container: any) => {
        switch (type.toLowerCase()) {
            case 'app':
                return this.appInstallerRobot.listAppInstallers(container).then(this.parseInstallerListResult);

            case 'os':
                return this.osInstallerRobot.listOsInstallers(container, undefined).then(this.parseInstallerListResult);

            case 'vm':
                return this.osInstallerRobot.listOsInstallers(undefined, container).then(this.parseInstallerListResult);

            default:
                break;
        }
    };

    public installServer = (
        virtualMachine: any,
        data: any,
        osId: any,
        osData: any,
        owner: any,
        voucherCode?: string
    ) => {
        return this.osInstallerRobot.installVm(
            virtualMachine,
            data.poolId,
            data.virtualMachineHostId,
            osId,
            osData,
            owner,
            voucherCode ?? undefined,
        )
        .then(this.refreshDeposit);
    };

    public installManagedServer = (virtualMachine: any, data: any, owner: any) => {
        if ([undefined, null, ''].indexOf(data.poolId) >= 0) {
            // Given data has no poolId.
            // Get all pools of account
            return this._getPoolsOfAccount(owner)
                .then(this._getDefaultPool)
                .then((defaultPool) => {
                    if (defaultPool === null) {
                        // No default pool in account
                        // Create new default pools
                        return this.resourceRobot.createPool({
                            accountId: owner,
                            defaultPool: true,
                            name: virtualMachine.name
                        })
                        .then((result) => {
                            data.poolIdForCreatedResources = result.response.id;
                            return this._installManagedServer(virtualMachine, data, owner);
                        });
                    } else {
                        // Default pool found in account
                        // Use pool id of default pool to create managed server
                        data.poolIdForCreatedResources = defaultPool.id;
                        return this._installManagedServer(virtualMachine, data, owner);
                    }
                })
                .then(this.refreshDeposit);
        }
    };

    public installIndividualServer = async (apiObject: any) => {
        return await this.machineRobot.createIndividualVm(
            apiObject.virtualMachine,
            apiObject.virtualMachineSpecification,
            apiObject.diskSpecification,
            apiObject.retailPrice,
            apiObject.managementType,
            apiObject.virtualMachineHostId
        );
    };

    public getDefaultPoolIdForNewManagedMachine = async (accountId: any, virtualMachineName: any) => {
        const existingPools = await this._getPoolsOfAccount(accountId);
        const currentDefaultPool = this._getDefaultPool(existingPools);

        if (currentDefaultPool === null) { // No default pool in account
            // Create new default pools
            const newPoolResponse = await this.resourceRobot.createPool(
                {
                    accountId: accountId,
                    defaultPool: true,
                    name: virtualMachineName
                }
            );

            return await newPoolResponse.response.id;
        } else { // Default pool found in account
            // return default pool id
            return await currentDefaultPool.id;
        }
    };

    public installOs = (virtualMachineId: any, osId: any, data: any, owner: any) => {
        return this.osInstallerRobot.installOs(virtualMachineId, osId, data, owner)
        .then(this.refreshDeposit);
    };

    public installApp = (vHost: any, appId: string, data: any) => {


        if ([undefined, null].indexOf(data.database) >= 0) {
            return;
        }

        if (!data.database.createNew) {
            data.database = data.database.id;

            return this.appInstallerRobot.installApp(vHost.id, appId, data).then(() => true);
        }

        const dbName = vHost.domainNameUnicode + ' - ' + appId + getRandomInt(0, 1000);
        const dbConfig: any = {
            accesses: [],
            name: dbName,
            productCode: '',
            storageQuota: 512,
            accountId: vHost.accountId
        };

        if ([undefined, null, ''].indexOf(data.database.bundleId) < 0) {
            dbConfig.bundleId = data.database.bundleId;
            dbConfig.productCode = data.database.bundleDatabaseProduct;
        } else {
            dbConfig.productCode = VhostProductCodesConst.appInstall.database[0];
        }

        return this._createDatabase(appId, dbConfig, vHost, data);
    };

    private refreshDeposit = (result: any) => {
        this.functionRelay.call('updateDeposit', {});
        return result;
    };

    private parseInstallerListResult(result: any) {
        return {
            applications: result.response.applications.map((current: any) => JSON.parse(current)),
            categories: result.response.categories
        };
    }

    private _createDatabase = (appId: any, dbConfig: any, vHost: any, data: any) => {
        const dataCopy = ng.copy(data);

        return this.databaseModel.create(dbConfig, vHost.accountId)
        .then(
            (reply) => {
                dataCopy.database = reply.id;
                return this.appInstallerRobot.installApp(vHost.id, appId, dataCopy);
            }
        )
        .then((appInstalled) => appInstalled.status !== 'error');
    };

    private _getPoolsOfAccount = (accountId: any) => {
        return this.resourceRobot.listPoolsWithoutPagination(
            { field: 'accountId', value: accountId },
            null,
            1
        ).then((poolResponse) => {
            return poolResponse.response;
        });
    };

    private _getDefaultPool = (poolList: any) => {
        poolList = poolList.data !== undefined ? poolList.data : poolList;
        return (poolList && poolList[0] ? poolList[0] : null);
    };

    private _installManagedServer = (virtualMachine: any, data: any, owner: any) => {
        return this.osInstallerRobot.managedServerCreate(
            virtualMachine,
            data.poolIdForCreatedResources,
            data.poolId,
            data.virtualMachineHostId,
            owner
        );
    };
}
