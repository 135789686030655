import * as ng from 'angular';
import {
    RpcClient,
    RpcClientService
} from '../rpc-client';

import * as Types from '../../types';

export const SslClientService = [
    'rpcClient',
    (platformRpcClient: RpcClientService) => platformRpcClient.Factory('/api/ssl/v1/json/', 'ssl')
];

export class SslRobotService {
    public static $inject: string[] = ['sslClient'];

    constructor(
        private sslClient: RpcClient
    ) {}

    public jobsFind = (
        filter: Types.Finding.Filter = null,
        limit: number = 25,
        page: number = 1,
        sort: Types.Finding.SortOptions = null,
        skipPagination: boolean = false,
        cacheTimeout?: number
    ) /* : q.IPromise<Types.ManagedApplicationApi.FindJobsResult> */ => {
        cacheTimeout = cacheTimeout || (1000 * 60 * 15);

        return this.sslClient.request('jobsFind')
        //    .useCache()
        //    .cacheTimeout(cacheTimeout)
            .skipPagination(skipPagination)
            .execute(
                {
                    filter: filter,
                    limit: limit,
                    page: page,
                    sort: sort
                }
            );
    };

    public certificateCreateHints = (csr: string, accountId: string, productCode: string, names: string[]): PromiseLike<any> => {
        return this.sslClient.request('certificateCreateHints')
            .execute({
                accountId: accountId,
                csr: csr,
                productCode: productCode,
                names: names})
            .then( (result) => { return result; } );
    };
    
    public certificateDetailsGet = (certificateId): PromiseLike<any> => {
        return this.sslClient.request('certificateDetailsGet')
        //    .useCache(true)
            .execute({ certificateId: certificateId })
            .then(
                (result) => {
                    return result;
                }
            );
    };

    public certificateGet = (certificateId): PromiseLike<any> => {
        return this.sslClient.request('certificateGet')
        //    .useCache(true)
            .execute({ certificateId: certificateId })
            .then(
                (result) => {
                    return result;
                }
            );
    };

    public checkAutoValidationCapable = (
        names: string[],
        ownerAccountId: string,
        productCode: string
    ) => {
        return this.sslClient.request('checkAutoValidationCapable')
            .useCache(true)
            .cacheTimeout(60)
            .ownerAccountId(ownerAccountId)
            .execute({ names: names, productCode: productCode });
    };

    public certificateRevoke = (certificateId) => {
        return this.sslClient.request('certificateRevoke')
            .bustCache('billing', 'depositGet')
            .execute({ certificateId: certificateId });
    };

    public certificatesFind = (filter?, limit?, page?, sort?) => {
        return this.sslClient.request('certificatesFind')
            .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public certificatesFindWithoutPagination = (filter, limit, page, sort) => {
        return this.sslClient.request('certificatesFind')
            .skipPagination()
            .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public csrDecode = (csr, productCode?) => {
        return this.sslClient.request('csrDecode')
            .execute({ csr: csr, productCode: productCode });
    };

    public domainApproverList = (domainName) => {
        return this.sslClient.request('domainApproverList')
            .useCache(true)
            .cacheTimeout(3600)
            .execute({ domainName: domainName });
    };

    public orderCancel = (certificateId) => {
        return this.sslClient.request('orderCancel')
            .execute({ certificateId: certificateId });
    };

    public orderContinue = (certificateId) => {
        return this.sslClient.request('orderContinue')
            .execute({ certificateId: certificateId });
    };

    public orderCreate = (order, owner: string) => {
        return this.sslClient.request('orderCreate')
            .bustCache('billing', 'depositGet')
            .ownerAccountId(owner)
            .execute({ order: order });
    };

    public orderResendApproverEmail = (certificateId) => {
        return this.sslClient.request('orderResendApproverEmail')
            .execute({ certificateId: certificateId });
    };

    public orderValidateParameters = (order) => {
        return this.sslClient.request('orderValidateParameters')
            .execute({ order: order });
    };

    public listJobs = (filter, limit, page, sort?) => {
        return this.sslClient.request('jobsFind')
            .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public certificateReissue = (certificateId: string, csr?: string) => {
        return this.sslClient.request('certificateReissue')
            .bustCache('billing', 'depositGet')
            .execute({ certificateId: certificateId, csr: csr });
    };
}
