import ng from 'angular';
import { UiRights } from '@/configuration';
import { AuthContextService } from '@/services';
import { DomainApi } from '@/types';
import { EditPanelStatus } from '@/atomic-components/molecules/forms/form-edit/form-edit';
import { DomainContactPanelMode } from '@/atomic-components/molecules/panels';
import { DomainHandleModelService } from '@/services';

import './domain-contact-create-edit-modal.scss';

export interface DomainContactModalEditSettings {
    changedEmailIsIcannOwnerChange: boolean;
    designateAgent: boolean;
    isIcannOwnerChange: boolean;
    originalEmail: string;
    updateBlocked: boolean;
    updateBlockedByDomainsAce: string[];
    updateBlockedByDomainsUnicode: string[];
}

export class MoleculeDomainContactCreateEditModalController implements ng.IController {
    public static $inject: string[] = ['$translate', 'domainHandleModel'];

    public showModal: boolean;
    public contact: DomainApi.Contact;
    public confirmCallback: () => void;
    public cancelCallback: Promise<void>;
    public initialStatus: EditPanelStatus;
    public panelMode: DomainContactPanelMode;
    public modalTitle: string;
    public buttonText: string;
    public hideButton = false;
    public doNotCloseOnApply: boolean;
    public accountBinding: undefined | string;
    public editSettings: DomainContactModalEditSettings;
    public selectedAccountId: string;
    public panelRight = {
        editPanelButton: false
    };

    private _panelMode: DomainContactPanelMode;
    private _loading = true;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private domainHandleModel: DomainHandleModelService
    ) {}

    public $onInit(): void {
        void this._setInitialStatus();
    }

    public $doCheck(): void {
        if (this.panelMode !== this._panelMode) {
            void this._setInitialStatus(this.panelMode);
            this._panelMode = ng.copy(this.panelMode);
        }
    }

    public $onDestroy(): void {
        this.initialStatus = undefined;
        this.panelMode = undefined;
        this.editSettings = undefined;
    }

    public get isLoading(): boolean {
        return this._loading;
    }

    public get isUpdateBlocked(): boolean {
        return !!this.editSettings?.updateBlocked;
    }

    public get blockedDomains(): string {
        if (!this.editSettings?.updateBlockedByDomainsUnicode) {
            return;
        }

        return this.editSettings?.updateBlockedByDomainsUnicode.join(', ');
    }

    public set designateAgent(_) {/* */}
    public get designateAgent(): boolean {
        if (this.editSettings === undefined) {
            return false;
        }

        return this.editSettings.designateAgent;
    }

    private _setInitialStatus = async (panelMode?: DomainContactPanelMode): Promise<void> => {
        this._loading = true;
        panelMode = panelMode || this.panelMode;
        if (panelMode === DomainContactPanelMode.DISPLAY) {
            this.modalTitle = this.$translate.instant('DOMAIN.DIRECTIVES.HANDLE-CHOOSER.BUTTONS.VIEW.TITLE');
            this.panelRight.editPanelButton = false;
            this.hideButton = true;
            this.initialStatus = EditPanelStatus.READONLY;
        } else {
            this.hideButton = false;
            switch (panelMode) {
                case DomainContactPanelMode.ADD:
                    this.accountBinding = this.selectedAccountId;
                    this.modalTitle = this.$translate.instant('TR_040219-fedf38_TR');
                    this.buttonText = this.$translate.instant('TR_100119-ff20c0_TR');
                    this.initialStatus = EditPanelStatus.CREATE;
                    break;
                case DomainContactPanelMode.EDIT:
                    this.modalTitle = this.$translate.instant('TR_040219-d80f09_TR');
                    this.buttonText = this.$translate.instant('TR_130319-909e96_TR');
                    await this.domainHandleModel.contactUpdateIsRestrictedOwnerChange(this.contact).then(
                        (response) => {
                            this.editSettings = {
                                changedEmailIsIcannOwnerChange: null,
                                designateAgent: false,
                                isIcannOwnerChange: response.isIcannOwnerChange,
                                updateBlocked: response.updateBlocked,
                                updateBlockedByDomainsAce: response.updateBlockedByDomainsAce,
                                updateBlockedByDomainsUnicode: response.updateBlockedByDomainsUnicode,
                                originalEmail: [undefined, null].indexOf(this.contact) >= 0
                                    ? ''
                                    : ng.copy(this.contact.emailAddress)
                            };

                            if (response.updateBlocked) {
                                this.initialStatus = EditPanelStatus.READONLY;
                                this.hideButton = true;
                            } else {
                                this.initialStatus = EditPanelStatus.ACTIVE;
                            }
                        }
                    );
                    break;
                case DomainContactPanelMode.COPY:
                    this.accountBinding = this.contact.accountId;
                    this.modalTitle = this.$translate.instant('DOMAIN.DIRECTIVES.HANDLE-CHOOSER.BUTTONS.COPY.TITLE');
                    this.buttonText = this.$translate.instant('DOMAIN.DIRECTIVES.HANDLE-CHOOSER.BUTTONS.COPY.TITLE');
                    this.initialStatus = EditPanelStatus.CREATE;
                    break;
                default:
                    // nothing to do here
            }
        }

        this.panelRight.editPanelButton = !this._contactIsExtended
            && AuthContextService.isGranted(UiRights.DOM_CONTACTS_EDIT);
        this._loading = false;
    };

    private _contactIsExtended = (): boolean => {
        return AuthContextService.account.parentAccountId === this.contact.accountId
            || this.contact.placeholderForUnreadableSupplierContact;
    };
}

export class MoleculeDomainContactCreateEditModalComponent implements ng.IComponentOptions {
    public bindings = {
        cancelCallback: '<',
        confirmCallback: '<',
        contact: '=',
        designateAgent: '=?',
        doNotCloseOnApply: '<?',
        panelMode: '=',
        showModal: '=',
        selectedAccountId: '<'
    };
    public controller = MoleculeDomainContactCreateEditModalController;
    public template = require('./domain-contact-create-edit-modal.html');
}
