import * as ng from 'angular';
import { WebhostingHelperService, WebspaceRestrictionData } from '../../../../services';

export class MoleculeStatusWebspaceController implements ng.IController {
    public static $inject: string[] = ['webhostingHelper'];

    public webspace;
    public restrictions: WebspaceRestrictionData[];

    constructor(
        private webhostingHelper: WebhostingHelperService
    ) {}

    $onInit() {
        if ([undefined, null].indexOf(this.webspace) >= 0) {
            return;
        }
        this.restrictions = this.webhostingHelper.handleWebspaceRestriction(this.webspace);
    }

    public get deletionScheduledFor() {
        return this.webspace.deletionScheduledFor;
    }

    public get status() {
        return this.webspace.status;
    }
}

export class MoleculeStatusWebspaceComponent implements ng.IComponentOptions {
    public bindings = {
        webspace: '<'
    };
    public controller =  MoleculeStatusWebspaceController;
    public controllerAs = '$status';
    public template = require('./status-webspace.html');
}
