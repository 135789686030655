import * as ng from 'angular';

export class OrganDashboardWidgetFaqController implements ng.IController {
    public static $inject: string[] = [];

    public faqArticleIdList: string[];

    public $onInit() {
        this.faqArticleIdList = this.faqArticleIdList || [
            'webspace-ftp-access',
            'domain-register-new',
            'ssl-activate'
        ];
    }
}

export class OrganDashboardWidgetFaqComponent implements ng.IComponentOptions {
    public bindings = {
        faqArticleIdList: '<?'
    };

    public template = require('./dashboard-widget-faq.html');
    public controller = OrganDashboardWidgetFaqController;
}
