import * as ng from 'angular';

export class TrackErrorDirective {

    public static Factory() {
        const directive = () => {
            return new TrackErrorDirective();
        };

        directive.$inject = [];

        return directive;
    }

    public restrict = 'A';
    public require = ['ngModel', '^showErrors'];

    public link = (
        $scope: ng.IScope|any,
        $element: ng.IAugmentedJQuery,
        $attributes: ng.IAttributes,
        controllers: any[]
    ) => {
        const ngModelCtrl = controllers[0];
        const showErrorsCtrl = controllers[1];

        const isValid = () => {
            if ($scope.validationOptions && $scope.validationOptions.dontValidatePristine && !ngModelCtrl.$dirty) {
                return true;
            }

            return ngModelCtrl.$valid;
        };

        showErrorsCtrl.valid = isValid();

        $scope.$watch(
            isValid,
            (valid) => {
                showErrorsCtrl.valid = valid;
            }
        );
    };
}
