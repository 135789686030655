import * as ng from 'angular';
import { AuthContextService, NavigationService } from '../../../../../services';

export class MoleculePanelAvvPrivacyNotifierController implements ng.IController {
    public static $inject: string[] = ['$translate', 'navigation'];

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private navigation: NavigationService
    ) {}

    public goRouteDataprocessing = () => {
        this.navigation.go('account.dataprocessing');
    };

    public get isShowAgreement() {
        // Only show data processing notification to users with the following characteristics:
        return AuthContextService.isDirectCustomer
            && AuthContextService.isAdminUser
            && AuthContextService.isMainUser
            && [undefined, null]
                .indexOf(AuthContextService.billingSettings.dataProcessingAgreementSignedOn) >= 0;
    }
}

export class MoleculePanelAvvPrivacyNotifierComponent implements ng.IComponentOptions {
    public controller =  MoleculePanelAvvPrivacyNotifierController; public template = require('./panel-avv-privacy-notifier.html');
}
