import { ErrorMapperService } from './error-mapper';
import { ApiErrorModel, ApiErrorObject } from './errors';

import * as ng from 'angular';

/**
 * Visual settings can be found in assets/styles/components/_alerts.scss
 */
export class AlertManagerService {
    public static $inject: string[] = [
        '$translate',
        'apiErrorModel',
        'errorMapper',
        'toastr',

        '$sce'
    ];

    private static robotMap = {
        account: 'Konto',
        billing: 'Rechnungs',
        database: 'Datenbank',
        dns: 'DNS',
        domain: 'Domain',
        email: 'E-Mail',
        machine: 'Cloud-Server',
        ssl: 'SSL',
        webhosting: 'Webhosting'
    };

    private static isTitleKey(value: any) {
        return value.endsWith('-TITLE');
    }

    private static getTranslations(translations, code?) {
        const keys = Object.keys(translations);

        // let titleKeyIndex = keys.findIndex(this.isTitleKey);
        const titleKeyIndex = keys.map(
            (v, i) => {
                return {value: translations[v], index: i};
            }
        )
        .filter((v) => AlertManagerService.isTitleKey(v.value))
        .map((v) => v.index)
        [0];

        const titleKey = keys[titleKeyIndex];
        keys.splice(titleKeyIndex, 1);
        const msgKey = keys[0];

        return {
            msg: translations[msgKey],
            code: code ? code : null,
            title: translations[titleKey]
        };
    }

    constructor(
        private $translate: ng.translate.ITranslateService,
        private apiErrorModel: ApiErrorModel,
        private errorMapper: ErrorMapperService,
        private toastr: ng.toastr.IToastrService,

        public $sce: ng.ISCEService
    ) {}

    /**
     * General settings are made in app.ts 'toastrConfig'
     */

    public info(message: string, fallback?: any) {
        const msg = this.getMessage(message, fallback);

        return  this.toastr.info(
            this.$translate.instant(msg),
            this.$translate.instant('TR_060519-ba3444_TR'),
            {
                onTap: null,
                progressBar: true,
                tapToDismiss: true
            }
        );
    }

    public success = (message: string, fallback?: any, forceTitleLanguage?: string) => {
        const msg = this.getMessage(message, fallback);

        return this.toastr.success(
            this.$translate.instant(msg),
            this.$translate.instant('TR_150119-663195_TR'),
            {
                onTap: null,
                progressBar: true,
                tapToDismiss: true
            }
        );
    };

    public warning(message: string, fallback?: undefined) {
        const msg = this.getMessage(message, fallback);

        return this.toastr.warning(
            this.$translate.instant(msg),
            this.$translate.instant('b792c98e-acbc-45ab-9e3a-c40e945a8fcb'),
            {
                // Keep open until closed with click
                closeButton: true,
                extendedTimeOut: 0,
                progressBar: false,
                tapToDismiss: true,
                timeOut: 0
            }
        );
    }

    public error = (message: string, fallback?: any, errorCode?: number, transactionId?: string) => {
        const msg = this.getMessage(message, fallback);
        let serverTransactionIdText = '';

        if (
            [undefined, null].indexOf(errorCode) < 0
            // errorCode === 19999 &&
            && [undefined, null].indexOf(transactionId) < 0
        ) {
            serverTransactionIdText = ' (Server Transaction ID: ' + transactionId + ')';
        }

        const translations = [
            msg,
            'GENERAL.TOASTR.ERROR-TITLE'
        ];

        let _error;
        try {
            _error = AlertManagerService.getTranslations(translations, errorCode);
        } catch (error) {
            _error = error;
        } finally {
            const errorObject: ApiErrorObject = {
                code: this._getErrorCode(_error),
                contextObject: serverTransactionIdText,
                contextPath: '',
                details: [],
                text: String(_error.msg),
                value: ''
            };
            this.apiErrorModel.addErrorToList(errorObject);
        }
    };

    public robotNotAvailable(robot: string) {
        const message = this.$translate.instant('ERRORS.ROBOTS.NOT-AVAILABLE.MESSAGE',
            { robot: AlertManagerService.robotMap[robot] });
        this.toastr.error(message, this.$translate.instant('ERRORS.ROBOTS.NOT-AVAILABLE.TITLE'));
    }

    public robotInMaintenance(robot: string) {
        const message = this.$translate.instant('ERRORS.ROBOTS.IN-MAINTENANCE.MESSAGE',
            { robot: AlertManagerService.robotMap[robot] });
        this.toastr.error(message, this.$translate.instant('ERRORS.ROBOTS.IN-MAINTENANCE.TITLE'));
    }

    private getMessage(message: string, fallback?: any) {
        if (message !== null && ['object'].indexOf(typeof message) >= 0) {
            message = this.errorMapper.map(message);
        }
        return message ? message : fallback;
    }

    private _getErrorCode(error: { msg: any; title?: any; code?: any }) {
        const errorCode = error.code ? error.code : error.msg.match(/\d+/g);
        return errorCode;
    }
}
