import ng from 'angular';
import { InputPlaceholderConst, UiRights } from '@/configuration';
import {
    AuthContextService,
    DomainInfoHelperService,
    DomainModelService,
    VhostModelService
} from '@/services';
import * as Types from '@/types';
import {
    DropdownElementData,
    MoleculeFormDropDownRevisedComponent,
    MoleculeFormDropDownRevisedController
} from './../drop-down-revised';

export class MoleculeFormDropDownRevisedEmailController extends MoleculeFormDropDownRevisedController {
    public static $inject: string[] = [
        '$element',
        '$stateParams',
        '$timeout',
        '$translate',
        '$window',
        'domainInfoHelper',
        'domainModel',
        'vhostModel'
    ];

    // misc class objects
    public isShowSelected: boolean;

    // Checks & validation.
    public skipDnsCheck = false;
    public dnsValidation = false;
    public populateOptionsList = false;
    public message = '';
    public checkedEmailResponse: Types.EmailApi.CheckEmailAddressResult;
    public accountId: string;
    public productCode = '';
    public dnsValidationCode = '';
    public inputPlaceholder = InputPlaceholderConst;

    /**
     * Keep both email-parts separate.
     */
    public emailFirstPart: string;
    public emailSecondPart: string;

    /**
     * Validator.
     */
    public validationErrors: any[] = [];

    /**
     * Using value as the new model.
     */
    private _value: any;
    public get value() {
        return this._value;
    }
    public set value(newValue) {
        if ([undefined, null].indexOf(newValue)) {
            return;
        }
        this._value = newValue;
        this.model = newValue;
    }

    /**
     * The raw filter value, un-formatted.
     */
    private _rawFilterValue: string;
    public get rawFilterValue() {
        return this._rawFilterValue;
    }
    public set rawFilterValue(newRawFilterValue: string) {
        if ([undefined, null].indexOf(newRawFilterValue) >= 0) {
            this._rawFilterValue = '';
            return;
        }

        this._rawFilterValue = newRawFilterValue;
        const foundAtSignAt = newRawFilterValue.indexOf('@');
        this.filterValue = foundAtSignAt >= 0
            ? newRawFilterValue.substr(foundAtSignAt + 1)
            : newRawFilterValue;
        this.value = newRawFilterValue;
    }

    public get newEntriesPlaceholder(): string {
        return this.options?.length === 0 && !this.isCustomisable
            ? this.$translate.instant('TR_010420-38aade_TR')
            : 'user@example.com';
    }

    constructor(
        public $element: ng.IAugmentedJQuery,
        public $stateParams: ng.ui.IStateParamsService,
        public $timeout: ng.ITimeoutService,
        public $translate: ng.translate.ITranslateService,
        public $window: ng.IWindowService,
        private domainInfoHelper: DomainInfoHelperService,
        private domainModel: DomainModelService,
        private vhostModel: VhostModelService
    ) {
        super($element, $timeout, $translate, $window);
        this.emailFirstPart = '';
        this.emailSecondPart = '';
    }

    /**
     * Initialize.
     */
    public $onInit() {
        this.isShowSelected = this.isShowSelected || false;

        if (this.populateOptionsList) {
            void this.createDomainDropdownItems();
        }
    }

    public createDomainDropdownItems = async () => {
        const accountId = this.accountId ? this.accountId : AuthContextService.account.id;
        let domainList: any[] = [];
        const domainDropdownItems: any[] = [];

        if (AuthContextService.isGrantedAll([UiRights.DOM_DOMAINS_LIST])) {
            const domainFilter = {
                subFilter: [
                    {
                        field: 'accountId',
                        value: accountId
                    },
                    {
                        field: 'domainStatus',
                        value: 'active'
                    }
                ] as Types.Finding.Filter[],
                subFilterConnective: 'AND'
            };

            if ([undefined, null, ''].indexOf(this.$stateParams.bundleId) < 0) {
                domainFilter.subFilter.push({
                    field: 'bundleId',
                    value: this.$stateParams.bundleId
                });
            }

            const domainListApi = await this.domainModel.list(25, 1, domainFilter);

            domainListApi.data.forEach(
                (domain: any) => {
                    domainList.push(domain);
                    domainDropdownItems.push({
                        name: domain.nameUnicode,
                        value: domain.nameUnicode
                    });
                }
            );

            if (AuthContextService.isGrantedAll([UiRights.WEB_VHOST_LIST, UiRights.WEB_OBJECT_LIST])) {
                const vhostFilter = {
                    subFilter: [
                        {
                            field: 'accountId',
                            value: accountId
                        },
                        {
                            field: 'VHostStatus',
                            value: 'active'
                        }
                    ] as Types.Finding.Filter[],
                    subFilterConnective: 'AND'
                };

                const vhostList = await this.vhostModel.list(25, 1, vhostFilter);

                if (vhostList.data && vhostList.data.length > 0) {

                    const missingVhosts: any[] = [];

                    // Filter vhosts with the existing domainList
                    for (const el of vhostList.data) {
                        if (domainList.every((domain) => domain.name !== el.domainName)) {
                            missingVhosts.push(el);
                        }
                    }

                    // Add to domainList
                    domainList = domainList.concat(missingVhosts);
                    domainList.forEach((el) => {
                        if (el.domainName || el.domainNameUnicode) {
                            el.name = el.domainName;
                            el.nameUnicode = el.domainNameUnicode;
                        }
                    });
                    domainList.sort((a, b) => {
                        return a.nameUnicode.localeCompare(b.nameUnicode);
                    });

                    // Add to domainDropdownItems
                    missingVhosts.forEach((el) => {
                        domainDropdownItems.push({
                            name: el.domainNameUnicode,
                            value: el.domainNameUnicode
                        });
                    });
                    domainDropdownItems.sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    });
                }
            }
        }
        this.options = domainDropdownItems;
    };

    public get filteredOptions(): (string | DropdownElementData)[] {
        if (!this.options) {
            return [];
        }

        if (this._rawFilterValue.indexOf('@') < 0) {
            return this.options;
        }

        // Skip filtering for now if a search-function is active.
        if (this.searchFunction) {
            return this.options;
        }

        // Filter by ID.
        if (this.filterValue[0] === '#') {
            return this.options.filter(
                (option) => (option as DropdownElementData).value === this.filterValue.slice(1)
            );
        }

        // Filter for string-array.
        if (typeof this.options[0] === 'string') {
            return (this.options as string[]).filter(
                (option) => {
                    const isContainFilter: boolean =
                        option
                        .toLowerCase()
                        .indexOf(
                            this.filterValue.toString().toLowerCase()
                        ) >= 0;
                    const isEmptyFilter: boolean = this.filterValue === '';

                    if (this.isShowSelected) {
                        return isContainFilter;
                    }
                    return isEmptyFilter || (!isEmptyFilter && isContainFilter);
                }
            ).filter(
                (_, optionIndex) => {
                    // Respect limit.
                    if (this.showLimit && !this.isShowAllResults) {
                        return optionIndex < this.showLimit;
                    }
                    return true;
                }
            );
        }

        // Filter for `DropdownElementData`.
        return (this.options as DropdownElementData[]).filter(
            (option) => {
                let isContainFilter = false;
                if (option.value && (option.value + '').toLowerCase().indexOf(
                    this.filterValue.toString().toLowerCase()
                ) >= 0) {
                    isContainFilter = true;
                }
                if (
                    option.name
                    && option
                        .name
                        .toString()
                        .toLowerCase()
                        .indexOf(
                            this.filterValue.toString().toLowerCase()) >= 0
                ) {
                    isContainFilter = true;
                }
                if (option.names) {
                    option.names.forEach(
                        (optionName) => {
                            if (
                                typeof optionName === 'string'
                                && optionName
                                    .toLowerCase()
                                    .indexOf(
                                        this.filterValue.toString().toLowerCase()) >= 0
                            ) {
                                isContainFilter = true;
                            }
                        }
                    );
                }

                const isEmptyFilter: boolean = this.filterValue === '';

                if (this.isShowSelected) {
                    return isContainFilter;
                }
                return isEmptyFilter || (!isEmptyFilter && isContainFilter);
            }
        ).filter(
            (_, optionIndex) => {
                // Respect limit.
                if (this.showLimit && !this.isShowAllResults) {
                    return optionIndex < this.showLimit;
                }
                return true;
            }
        );
    }

    /**
     * Get the current lowest tab-index. Can become -1 when 'Eintrag xxx hinzufügen' exists.
     */
    public get minimumTabIndex() {
        return (this.isCustomisable && this.filterValue !== '' && this._rawFilterValue.indexOf('.') >= 0 ? -1 : 0);
    }

    /**
     * Set back the tab-index to the top-most position based on being customizable.
     * 0 = default, -1 = with custom option
     */
    public resetTabIndexToTop = () => {
        this.tabIndex = this.minimumTabIndex;
    };

    /**
     * Select the next option.
     */
    public incrementTabIndex = () => {
        this.open();
        if (this.isShowSelected  && this.filterValue === '' && this._rawFilterValue.indexOf('.') >= 0) {
            if (this.tabIndex >= this.options.length - 1) {
                this.tabIndex = 0;
            } else {
                this.tabIndex++;
            }
        } else {
            const min = this.minimumTabIndex;
            if (this.tabIndex >= this.filteredOptions.length - 1) {
                this.tabIndex = min;
            } else {
                this.tabIndex++;
            }
        }
    };

    /**
     * Select the previous option.
     */
    public decrementTabIndex = () => {
        this.open();
        if (this.isShowSelected  && this.filterValue === '') {
            if (this.tabIndex <= 0) {
                this.tabIndex = this.options.length - 1;
            } else {
                this.tabIndex--;
            }
        } else {
            const min = this.minimumTabIndex;
            if (this.tabIndex <= min) {
                this.tabIndex = this.filteredOptions.length - 1;
            } else {
                this.tabIndex--;
            }
        }
    };

    public get filterMatchesOption() {
        const matches = this.filteredOptions.filter((option: DropdownElementData) => option.value === this.filterValue);
        return matches.length > 0;
    }

    /**
     * Select an option.
     */
    public select = (optionIndex: number, isMouseClick?: boolean): boolean => {  // tslint:disable-next-line

        if (this.filterValue !== '' && this.filteredOptions.length <= 0) {
            this.resetTabIndexToTop();
        }

        // Add new custom entry.
        if (this.filterValue !== '' && this.tabIndex === -1 && [false, null, undefined].indexOf(isMouseClick) >= 0) {
            if (this.isCustomisable) {
                this.addFilterAsCustomOption();
            }

            if (this.callbackOnChange) {
                this.callbackOnChange(this._model);
            }

            return true;
        }

        this.tabIndex = optionIndex;

        // Set the selected element in the original list.
        if (this.isShowSelected && this.filterValue === '') {
            this.selectedElement = (0 < this.options.length && this.options.length > optionIndex)
                ? this.options[optionIndex]
                : this.options[0];
        } else {
            this.selectedElement =
                (
                    0 < this.filteredOptions.length
                    && this.filteredOptions.length > optionIndex
                )
                ? this.filteredOptions[optionIndex]
                : this.options[0];

            if (this.selectedElement === undefined) {
                return false;
            }

            if (this._rawFilterValue.indexOf('@') >= 0) {
                this.emailFirstPart = this._rawFilterValue.substr(0, this._rawFilterValue.indexOf('@'));
                this.emailSecondPart = (this.selectedElement as DropdownElementData).name;
                this.rawFilterValue = `${this.emailFirstPart}@${this.emailSecondPart}`;
            } else if (this.filterValue.indexOf('@') < 0) {
                this.emailFirstPart = this.filterValue;
                this.emailSecondPart = (this.selectedElement as DropdownElementData).value;
                this.rawFilterValue = `${this.emailFirstPart}@${this.emailSecondPart}`;
            } else {
                if (
                    (this.selectedElement as DropdownElementData).value.indexOf('@') < 0
                    && this._rawFilterValue !== (this.selectedElement as DropdownElementData).value
                ) {
                    this.filterValue = '@' + this._rawFilterValue;
                    if (
                        this.options.filter(
                            (option: DropdownElementData) => option.value === '@' + this._rawFilterValue
                        ).length < 0
                    ) {
                        this.addFilterAsCustomOption();
                    }
                } else {
                    this.emailFirstPart = '';
                    this.emailSecondPart = (this.selectedElement as DropdownElementData).value;
                    this.rawFilterValue = `${this.emailFirstPart}@${this.emailSecondPart}`;
                }
            }
        }

        this.close();

        // Update tab-index to fit the new size of the filtered items.
        if (this.isShowSelected  && this.filterValue === '') {
            this.tabIndex = this.tabIndex >= this.options.length
                ? this.options.length - 1
                : this.tabIndex;
        } else {
            this.tabIndex = this.tabIndex >= this.filteredOptions.length
                ? this.filteredOptions.length - 1
                : this.tabIndex;
        }

        this.focusFilterTextInputField();
        this.updateModel();
        if (this.callbackOnChange) {
            this.callbackOnChange(this._model);
        }

        this.setCursorInFrontOfAt();
        return true;
    };

    /**
     * Put the cursor in front of the `@` sign.
     */
    public setCursorInFrontOfAt() {
        if (this._rawFilterValue[0] === '@') {
            const inputFieldEl: HTMLInputElement = document.querySelector('.open .dropdown-text');
            if (inputFieldEl) {
                this.$timeout(() => {
                    inputFieldEl.focus();
                    const startAt = this.rawFilterValue.indexOf('@') || 0;
                    inputFieldEl.setSelectionRange(startAt, startAt);
                });
            }
        }
    }

    /**
     * Create a new custom option and add it to the list.
     */
    public addFilterAsCustomOption = () => {
        // Skip empty entries.
        if (this.filterValue.length <= 0) {
            return false;
        }

        // Remove previously added custom options beforehand.
        this.options = this.options.filter((option: DropdownElementData) => option.value !== this.filterValue);
        this.customOptions = this.customOptions.filter((custom: DropdownElementData) => custom.value !== this.filterValue);

        // Add a new custom option and select it.
        this.options.push({ name: this.filterValue, value: this.filterValue});
        this.customOptions.push({ name: this.filterValue, value: this.filterValue});
        this.filterValue = '';
        this.select(this.options.length - 1);
    };

    public onFocus = () => {
        this.isFocused = true;
        this.isOpen = true;
    };

    public lostFocus = () => {
        this.$timeout(() => {
            this.isFocused = false;
            this.isOpen = false;
        }, 1000);
    };
}

export class MoleculeFormDropDownRevisedEmailComponent extends MoleculeFormDropDownRevisedComponent {
    public transclude = {
        label: 'rowLabel'
    };

    public bindings: any = {
        ...this.bindings,
        accountId: '<',
        autoConfigureDns: '=',
        message: '=',
        productCode: '<',
        rawFilterValue: '=model',
        populateOptionsList: '<?',
        skipDnsCheck: '=?',
        validationErrors: '=?'
    };

    public controller = MoleculeFormDropDownRevisedEmailController; public template = require('./drop-down-revised-email.html');

    constructor() {
        super();
        // Removing the original model from the parent class to prevent interfering with the `model`-alias.
        delete(this.bindings.model);
    }
}
