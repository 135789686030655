import * as ng from 'angular';
import { AuthContextService } from '../../../../services';

export class MoleculePanelServiceInfoAccountController implements ng.IController {
    public static $inject: string[] = ['$state', '$translate', 'authContext'];

    public subaccountId: string;
    public showAccount = false;
    public showObjectId = false;

    public idLabel: string;
    public idValue: string;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit() {
        if ([undefined, null].indexOf(this.$state.$current) < 0
            && [undefined, null].indexOf(this.$state.$current.locals) < 0
            && [undefined, null].indexOf(this.$state.$current.locals.globals) < 0) {
            if (this.$state.$current.locals.globals.bundle) {
                this.subaccountId = this.$state.$current.locals.globals.bundle.accountId;
                this.showAccount = this.$state.$current.locals.globals.bundle.accountId !== this.authContext.account.id;
            }

            if (this.$state.$current.locals.globals.webspace) {
                this.subaccountId = this.$state.$current.locals.globals.webspace.accountId;
                /* tslint:disable-next-line:max-line-length */
                this.showAccount = this.$state.$current.locals.globals.webspace.accountId !== this.authContext.account.id;
            }

            if (this.$state.$current.locals.globals.zone) {
                this.subaccountId = this.$state.$current.locals.globals.zone.accountId;
                this.showAccount = this.$state.$current.locals.globals.zone.accountId !== this.authContext.account.id;
            }

            if (this.$state.$current.locals.globals.storageProduct) {
                this.subaccountId = this.$state.$current.locals.globals.storageProduct.accountId;
                this.showAccount = this.$state.$current.locals.globals.storageProduct.accountId !== this.authContext.account.id; // tslint:disable-line:max-line-length
            }
        }

        const activeRoute: string[] = this.$state.current.name.split('.');
        const isActiveCategory = (route: string[], category: string): boolean => {
            const lastIdIdx = route.lastIndexOf('id');
            return lastIdIdx < 0 ? false : route[lastIdIdx - 1] === category;
        };
        if (isActiveCategory(activeRoute, 'webspaces')) {
            this.idLabel = this.$translate.instant('TR_080219-831f05_TR');
            this.idValue = this.$state.params.webspaceId;
        } else if (isActiveCategory(activeRoute, 'zones')) {
            this.idLabel = this.$translate.instant('TR_130219-b53b84_TR');
            this.idValue = this.$state.params.zoneId;
        } else if (isActiveCategory(activeRoute, 'bundle')) {
            this.idLabel = this.$translate.instant('TR_140219-a8b651_TR');
            this.idValue = this.$state.params.bundleId;
        } else {
            this.showObjectId = false;
        }
    }
}

export class MoleculePanelServiceInfoAccountComponent implements ng.IComponentOptions {
    public bindings = {
        showObjectId: '<'
    };
    public controller = MoleculePanelServiceInfoAccountController;
    public template = require('./panel-service-info-account.html');
}
