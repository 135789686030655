import * as ng from 'angular';
import { UiRights } from '../../../../configuration';
import { EasterEggDays } from '../../../../configuration/time-specifications';
import { AuthContextService } from '../../../../services';
import './user-action.scss';

export class MoleculeButtonUserActionController implements ng.IController {
    public static $inject: string[] = ['authContext', '$translate'];

    public showPopup = false;
    public loggedInSubaccount = false;

    public sectionTitle = '';
    public customerNumber: string;

    public userIcon = 'user';
    public icons = [
        'user-astronaut', 'user-crown', 'user-graduate', 'user-md', 'user-ninja', 'user-tie', 'dragon', 'cat', 'dog'
    ];

    private lastAccount = '';
    private lastPopup: any = {};

    private  panelItems = {
        ownAccount: [],
        subAccount: []
    };

    constructor(
        private authContext: AuthContextService,
        private $translate: ng.translate.ITranslateService
    ) {
        this.panelItems.ownAccount = [
            { text: this.$translate.instant('a2bb29be-15a7-4e98-99fb-467b7d576a34'), route: 'profile' },
            { text: this.$translate.instant('033be50a-858d-4924-bbb9-6fdca1269a1f'), route: 'account.settings' },
            { text: this.$translate.instant('TR_090119-58c091_TR'), route: 'logout' }
        ];

        this.panelItems.subAccount = [
            { text: this.$translate.instant('a2bb29be-15a7-4e98-99fb-467b7d576a34'), route: 'profile' },
            { text: this.$translate.instant('033be50a-858d-4924-bbb9-6fdca1269a1f'), route: 'account.settings' },
            { text: this.$translate.instant('TR_090119-78a3bb_TR'), isSetLogoutAs: true }
        ];

        this.sectionTitle = this.$translate.instant('TR_090119-629b5f_TR');
    }

    public $onInit() {
        this.customerNumber = this.authContext.account.customerNumber;
        this.setUserIcon();
    }

    public userActionClick(onlyOpen: boolean) {
        if ([true].indexOf(onlyOpen) >= 0) {
            this.showPopup = true;
        } else {
            this.showPopup = !this.showPopup;
        }
    }

    public isLoggedAsSubaccount = () => {
        return this.authContext.isLoggedAsSubaccount();
    };

    public setUserIcon = () => {
        if (EasterEggDays.aprilFoolsDay) {
            this.userIcon = this.icons[Math.floor(Math.random() * this.icons.length)];
        }
    };

    public get popup() {
        if (this.lastAccount !== this.authContext.account.id) {
            this.lastPopup = {
                panelHeaderRoute: '',
                panelItems: this.isLoggedAsSubaccount() ? this.panelItems.subAccount : this.panelItems.ownAccount,
                panelTitle: this.authContext.user.name
            };
        }

        return this.lastPopup;
    }

    public get loggedUser(): string {
        return this.authContext.user.name;
    }

    public get loggedAccount(): string {
        return this.authContext.account.name;
    }
}

export class MoleculeButtonUserActionComponent implements ng.IComponentOptions {
    public transclude = true;
    public controller = MoleculeButtonUserActionController;
    public template = require('./user-action.html');
}
