import * as ng from 'angular';
import { DomainHelperService, VhostModelService } from '../../../../services';
import { LinkListItem } from '../../links/link-list/link-list';
import './panel-bundle-dasboard-domain-list.scss';

export class MoleculePanelBundleDashboardDomainListController implements ng.IController {
    public static $inject: string[] = ['vhostModel'];

    public domainLinkList: LinkListItem[] = [];
    public overviewLinkItem: LinkListItem = {
        route: '',
        routeParams: {},
        text: ''
    };

    private vhostAndDomainChecks = [];

    constructor(
        private vhostModel: VhostModelService
    ) {}

    public showDomainOverviewLink = () => {
        return this.domainLinkList.some((domain) => {
            if (domain.type === 'overviewLink') {
                this.overviewLinkItem.route = domain.route;
                this.overviewLinkItem.routeParams = domain.routeParams;
                this.overviewLinkItem.text = domain.text;
                return true;
            }
            return false;
        });
    };

    public getReadableNameOfDomainType = (type: string) => {
        return DomainHelperService.getReadableNameOfDomainType(type, false);
    };

    public vhostAndDomain = (item: LinkListItem) => {
        const checkObject = this.vhostAndDomainChecks.filter((itemObject) => {
            return itemObject.text === item.text && ['checked', 'checking'].indexOf(itemObject.status) >= 0;
        });

        if (checkObject.length > 0) {
            return checkObject[0].value;
        }
        const indexOfNewCheckItem = this.vhostAndDomainChecks.length;
        this.vhostAndDomainChecks.push({
            status: 'checking',
            text: item.text,
            value: false
        });
        return this.vhostModel.listWithoutPagination(
            null,
            1,
            { field: 'VHostDomainNameUnicode', value: item.text}
        ).then((res) =>   {
            this.vhostAndDomainChecks[indexOfNewCheckItem].status = 'checked';
            this.vhostAndDomainChecks[indexOfNewCheckItem].value = res.data.length > 0;
        }) as ng.IPromise<boolean>;
    };
}

export class MoleculePanelBundleDashboardDomainListComponent implements ng.IComponentOptions {
    public bindings = {
        domainLinkList: '<'
    };
    public controller =  MoleculePanelBundleDashboardDomainListController;
    public template = require('./panel-bundle-dasboard-domain-list.html'); // tslint:disable-line
}
