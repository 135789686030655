export * from './backups/';
export * from './generic/generic';
export * from './headline/headline';
export * from './ip-addresses/ip-addresses';
export * from './nextcloud-groups/nextcloud-groups';
export * from './nextcloud-group-folders/nextcloud-group-folders';
export * from './nextcloud-plugins/nextcloud-plugins';
export * from './nextcloud-users/nextcloud-users';
export * from './users/database-accesses';
export * from './users/users';
export * from './users/webspace-users';

import './panel-edit-row-table.scss';
