import * as ng from 'angular';
import {AuthContextService, PriceHelperService} from '../../../../../services';
import { WizardHelperService, WizardObject } from '../../../../molecules/wizard/';

export class OrganCreateSummaryStorageController implements ng.IController {
    public static $inject: string[] = ['authContext', 'priceHelper', 'wizardHelper'];

    public contingent: any;
    public serviceObject: WizardObject;

    constructor(
        private authContext: AuthContextService,
        public priceHelper: PriceHelperService,
        private wizardHelper: WizardHelperService
    ) {}

    public getContractPeriod = () => {
        const priceObj =  this.serviceObject.selectedProduct.prices.filter((priceObject) => {
            return priceObject.productCode === this.serviceObject.settings.createData.nextcloud.productCode;
        })[0].billingCycle;

        return this.wizardHelper.getBillingCycleTranslation(
            priceObj
        );
    };

    public get cost() {
        const account = this.authContext.account.id === '1'
            ? this.serviceObject.settings.account
            : this.authContext.account;
        return this.wizardHelper.calculateProductPrice(
            this.serviceObject.selectedProduct.prices,
            account,
            this.serviceObject.settings.createData.nextcloud.productCode
        );
    }

    public set cost({}) {} // tslint:disable-line:no-empty

    public get currency() {
        return this.wizardHelper.getProductCurrency(this.serviceObject.selectedProduct.prices[0].price);
    }

    public set currency({}) {} // tslint:disable-line:no-empty

    public get backupCost() {
        const account = this.authContext.account.id === '1'
            ? this.serviceObject.settings.account
            : this.authContext.account;

        return this.priceHelper.calculatePrice(
            this.serviceObject.settings.createData.backup.price,
            account.isCommercialCustomer
        );
    }

    public get backupCurrency() {
        return this.wizardHelper.getProductCurrency(this.serviceObject.settings.createData.backup.price);
    }

}

export class OrganCreateSummaryStorageComponent implements ng.IComponentOptions {
    public bindings = {
        contingent: '<',
        serviceObject: '<'
    };
    public template = require('./storage-summary.html');
    public controller =  OrganCreateSummaryStorageController;
}
