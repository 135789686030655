import * as ng from 'angular';
import { SpamProfiles } from '../configuration';

export class SpamProfileService {
    public static $inject: string[] = ['$translate'];

    constructor(private $translate: ng.translate.ITranslateService) {}

    public getList = () => {
        const spamProfileList = [];
        SpamProfiles.forEach((spamProfile) => {
            spamProfile.label = this.$translate.instant(spamProfile.label);
            spamProfile.desc = this.$translate.instant(spamProfile.desc);
            spamProfileList.push(spamProfile);
        });
        return spamProfileList;
    };
}
