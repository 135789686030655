import * as ng from 'angular';

import { BundleModelService } from '..';
import { AccountFilterField,
    ContactFilterField,
    LinkListOverviewItem,
    OverviewDataObject,
    SelectFilterField,
    TextFilterField
} from '../../atomic-components';
import { transitEnabledTlds, UiRights } from '../../configuration';
import { BundleApi, DomainApi, WebhostingApi, ViewTypes } from '@/types';
import { ListCallbacks, OverviewModel } from '../abstract';
import { AlertManagerService } from '../alert-manager';
import { AuthContextService } from '../auth-context';
import { ModelHelper } from '../model-helper';
import { NavigationService } from '../navigation';
import { RequestCancellerService } from '../request-canceller';
import { VhostModelService, WebspaceModelService } from '../webhosting';
import { DomainModelService } from './';
import { DomainEnricher } from './DomainEnricher';

interface DomainWithVHost extends DomainApi.Domain{
    bundleVhostMapId?: string;
}

export class DomainOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        [
            '$translate',
            '$timeout',
            'alertManager',
            'domainModel',
            'authContext',
            '$q',
            'domainStateFilter',
            '$state',
            'navigation',
            'vhostModel',
            'webspaceModel',
            'bundleModel'
        ]
    );

    public activeMassAction: string | boolean = false;
    public selected: { [id: string]: boolean } = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};
    public overviewData: OverviewDataObject;
    public overviewUtilityChart = [];

    public readonly service = 'domain';
    public readonly objectType = 'DomainProduct';
    public readonly idField = 'domainId';
    public readonly defaultFilterFields = ['DomainNameUnicode'];

    protected prefixFilters = [{ field: 'DomainName', prefix: 'xn--' }];
    protected regexFilters = [{ field: 'DomainName', regex: /^.+\.xn--.+$/ }];
    protected listCallbacks: ListCallbacks[] = [];

    private _cachedAvailableFilters = null;
    private _domainBundleIdMap = {};
    private _bundleLookupRequestInProgress = false;
    private domainEnricher: DomainEnricher;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private $timeout: ng.ITimeoutService,
        private alertManager: AlertManagerService,
        private domainModel: DomainModelService,
        private authContext: AuthContextService,
        private $q: ng.IQService,
        private domainStateFilter,
        private $state: ng.ui.IStateService,
        private navigation: NavigationService,
        private vhostModel: VhostModelService,
        private webspaceModel: WebspaceModelService,
        private bundleModel: BundleModelService
    ) {
        super(RequestCanceller);
        this.domainEnricher = new DomainEnricher(this.vhostModel,
             this.webspaceModel,
              this.bundleModel,
               this.authContext);
        // declare multi action availably methods
        this.actionLinksAvailabilities = {
            authCodesAvailable: this.authCodesAvailable,
            bulkEditAvailable: this.bulkEditAvailable,
            deletableDomains: this.deletableDomains,
            deleteCancelableDomains: this.deleteCancelableDomains,
            moveBulkAvailable: this.moveBulkAvailable,
            restorableDomains: this.restorableDomains
        };

        // declare multi action availably methods
        this.cutomizedModelListMethods = false;

        // Define listCallbacks

        this.listCallbacks = [
            {
                callback: this.listDomains
            }
        ];
    }

    public get availableFilters(): unknown {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField(
                    'DomainNameUnicode',
                    this.$translate.instant('07445a86-7211-449f-bfa1-d761f73e8786')
                ),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR')),
                new SelectFilterField(
                    'DomainStatus',
                    this.$translate.instant('TR_020519-6764a9_TR'),
                    [
                        {
                            name: this.domainStateFilter('active', ''),
                            value: 'active'
                        },
                        {
                            name: this.domainStateFilter('ordered'),
                            value: 'ordered'
                        },
                        {
                            name: this.domainStateFilter('restorable'),
                            value: 'restorable'
                        },
                        {
                            name: this.domainStateFilter('failed', ''),
                            value: 'failed'
                        }
                    ]
                ),
                new SelectFilterField(
                    'DomainDeletionType',
                    this.$translate.instant('GENERAL.TERMINATION'),
                    [
                        {
                            name: this.domainStateFilter('active', 'delete'),
                            value: 'delete'
                        },
                        {
                            name: this.domainStateFilter('active', 'withdraw'),
                            value: 'withdraw'
                        }
                    ]
                ),
                new ContactFilterField('contactHandle', this.$translate.instant('DOMAIN.GENERAL.HANDLE'))
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }

    public listDomains = (limit?, page?, filters?, sort?): unknown => {
        return this.domainModel.list(limit, page, filters, sort)
        .then(this.enrichDomains.bind(this));

    };

    webSpaceFilterFactory(vHosts: WebhostingApi.VHost[]){
        return {
        subFilter:
            vHosts
            .filter(vhost => vhost.webspaceId)
            .map(vhost => {
                return {field:'webspaceId', value:vhost.webspaceId};
            }),
        subFilterConnective:'OR'
        };
    }
    vHostFilterFactory(domains: DomainApi.Domain[]){
        return {
        subFilter: domains.map(domain => {
            return { field: 'vhostDomainNameUnicode', value: domain.nameUnicode };
        }),
        subFilterConnective:'OR'
        };
    }

    bundleFilterFactory(webspaces: (WebhostingApi.Webspace|DomainApi.Domain)[]){
        return {
            subFilter: webspaces.map(webspace => {
                return { field: 'BundleId', value: webspace.bundleId };
            }),
            subFilterConnective:'OR'
            };
    }

    private async enrichDomains(value: ViewTypes.EntityWithPagination<DomainApi.Domain>): Promise<ViewTypes.EntityWithPagination<DomainApi.Domain>>{
        return this.domainEnricher.assignBundleNamesWithVhosts(value);

    }

    public actionLinks = (): LinkListOverviewItem[] => {
        return [
            {
                isGranted: this.authContext.isGrantedAny([
                    UiRights.DOM_CONTACTS_LIST,
                    UiRights.DOM_DOMAINS_LIST,
                    UiRights.DOM_DOMAINS_AUTHINFO_CREATE,
                    UiRights.DOM_DOMAINS_CREATE_WITH_CONTACTS,
                    UiRights.DOM_DOMAINS_CREATE_WITH_DEFAULTS,
                    UiRights.DOM_DOMAINS_CREATE_WITH_OWN_NS,
                    UiRights.DOM_JOBS_LIST,
                    UiRights.WEB_REDIRECTIONS_LIST
                ]),
                linkList: [
                    {
                        group: 'redirect',
                        isGranted: this.authContext.isGrantedAny([
                            UiRights.DOM_DOMAINS_CREATE_WITH_CONTACTS,
                            UiRights.DOM_DOMAINS_CREATE_WITH_DEFAULTS,
                            UiRights.DOM_DOMAINS_CREATE_WITH_OWN_NS
                        ]),
                        route: 'domain.availability-check',
                        text: this.$translate.instant('MENU.CONTEXT.DOMAIN.AVAIL-CHECK')
                    },
                    {
                        group: 'redirect',
                        isGranted: this.authContext.isGranted(UiRights.DOM_JOBS_LIST),
                        route: 'domain.domains.jobs',
                        text: this.$translate.instant('MENU.CONTEXT.JOBS')
                    },
                    {
                        group: 'redirect',
                        isGranted: this.authContext.isGranted(UiRights.DOM_CONTACTS_LIST),
                        route: 'domain.contacts.overview',
                        text: this.$translate.instant('DOMAIN.GENERAL.HANDLES')
                    },
                    {
                        group: 'redirect',
                        isGranted: this.authContext.isGranted(UiRights.DOM_CONTACTS_LIST),
                        route: 'domain.contacts.verification',
                        text: this.$translate.instant('GENERAL.JOB.ACTION.CONTACTOWNERVERIFICATION')
                    },
                    {
                        group: 'redirect',
                        isGranted: this.authContext.isGranted(UiRights.DOM_JOBS_LIST),
                        route: 'domain.transfers',
                        text: this.$translate.instant('MENU.CONTEXT.DOMAIN.TRANSFER-OUT')
                    },
                    {
                        group: 'redirect',
                        isGranted: this.authContext.isGranted(UiRights.WEB_REDIRECTIONS_LIST),
                        route: 'domain.redirections.overview',
                        text: this.$translate.instant('MENU.CONTEXT.DOMAIN.REDIRECTIONS')
                    },
                    {
                        group: 'redirect',
                        isGranted: this.authContext.isGranted(UiRights.DOM_DOMAINS_AUTHINFO_CREATE),
                        route: 'domain.authinfotwo',
                        text: this.$translate.instant('TR_110119-c1e706_TR')
                    },
                    {
                        group: 'redirect',
                        isGranted: this.authContext.isGranted(UiRights.DOM_DOMAINS_CREATE_WITH_DEFAULTS),
                        route: 'domain.domains.new',
                        routeParams: {domainType: 'subdomain'},
                        text: this.$translate.instant('TR_090119-719dc6_TR')
                    },
                    {
                        group: 'redirect',
                        isGranted: this.authContext.isGranted(UiRights.DOM_DOMAINS_CREATE_WITH_DEFAULTS),
                        route: 'domain.domains.new',
                        routeParams: {domainType: 'external'},
                        text: this.$translate.instant('TR_090119-91145a_TR')
                    },
                    {
                        group: 'redirect',
                        isGranted: this.authContext.isGranted(UiRights.DOM_DOMAINS_LIST),
                        route: 'domain.reports',
                        text: this.$translate.instant('MENU.CONTEXT.DOMAIN.REPORTS')
                    }
                ],
                route: '',
                text: this.$translate.instant('TR_100119-a22157_TR'),
                type: 'multi'
            },
            {
                isGranted: this.authContext.isGrantedAny([UiRights.DOM_DOMAINS_DELETE, UiRights.DOM_DOMAINS_RESTORE]),
                linkList: [
                    {
                        group: 'edit',
                        groupName: this.$translate.instant('TR_140119-7f09bd_TR'),
                        isClickable: 'bulkEditAvailable',
                        onclickMethode: this.goToEditBulk,
                        route: '',
                        text: this.$translate.instant('TR_140119-8f73cf_TR')
                    },
                    {
                        group: 'edit',
                        isClickable: 'moveBulkAvailable',
                        onclickMethode: this.goToMove,
                        route: '',
                        text: this.$translate.instant('TR_140119-b931d6_TR')
                    },
                    {
                        group: 'edit',
                        isClickable: 'authCodesAvailable',
                        onclickMethode: () => this.activeMassAction = 'domainAuthcodes',
                        route: '',
                        text: this.$translate.instant('TR_140119-7bda44_TR')
                    },
                    {
                        group: 'delete',
                        groupName: this.$translate.instant('TR_140119-aee71e_TR'),
                        isClickable: 'deletableDomains',
                        isGranted: this.authContext.isGranted(UiRights.DOM_DOMAINS_DELETE),
                        onclickMethode: () => this.activeMassAction = 'delete',
                        route: '',
                        text: this.$translate.instant('TR_140119-b3dc89_TR')
                    },
                    {
                        group: 'delete',
                        isClickable: 'deletableDomains',
                        isGranted: this.authContext.isGranted(UiRights.DOM_DOMAINS_DELETE),
                        onclickMethode: () => this.activeMassAction = 'transit',
                        route: '',
                        text: this.$translate.instant('TR_140119-f83418_TR')
                    },
                    {
                        group: 'delete',
                        isClickable: 'deleteCancelableDomains',
                        isGranted: true,
                        onclickMethode: () => this.activeMassAction = 'cancelDelete',
                        route: '',
                        text: this.$translate.instant('TR_140119-1f839e_TR')
                    },
                    {
                        group: 'restore',
                        groupName: this.$translate.instant('TR_140119-d1b7a4_TR'),
                        isClickable: 'restorableDomains',
                        isGranted: this.authContext.isGrantedAny([UiRights.DOM_DOMAINS_RESTORE]),
                        onclickMethode: () => this.activeMassAction = 'restore',
                        route: '',
                        text: this.$translate.instant('TR_140119-267817_TR')
                    }
                ],
                onlyOnExperView: true,
                route: '',
                text: this.$translate.instant('TR_140119-080106_TR'),
                type: 'multi'
            },
            {
                isGranted: this.authContext.isGrantedAny([
                    UiRights.DOM_DOMAINS_CREATE_WITH_CONTACTS,
                    UiRights.DOM_DOMAINS_CREATE_WITH_DEFAULTS,
                    UiRights.DOM_DOMAINS_CREATE_WITH_OWN_NS]
                ),
                route: this._getRoute('new'),
                routeParams: this._getRouteParams(),
                text: this.$translate.instant('TR_140119-f491e3_TR')
            }
        ] as LinkListOverviewItem[];
    };

    public deletableDomains = (domains: any[]) => {
        for (const domain of domains) {
            if (
                this.selected[domain.id] && ['ordered', 'restorable'].indexOf(domain.status) < 0
                && [undefined, null].indexOf(domain.deletionScheduledFor) >= 0
            ) {
                return true;
            }
        }
        return false;
    };

    public deleteCancelableDomains = (domains: any[]) => {
        for (const domain of domains) {
            if (this.selected[domain.id]
                && domain.deletionScheduledFor !== undefined
                && domain.deletionScheduledFor !== null
                && domain.status !== 'restorable') {
                return true;
            }
        }
        return false;
    };

    public restorableDomains = (domains: any[]) => {
        for (const domain of domains) {
            if (this.selected[domain.id] && domain.status === 'restorable') {
                return true;
            }
        }
        return false;
    };

    public deleteSubmit = (domains: any[], settings: any) => {
        let promises = [];

        const deletableDomains = domains
            .filter((domain) => [undefined, null, false].indexOf(this.selected[domain.id]) < 0)
            .filter((domain) => ['ordered', 'restorable'].indexOf(domain.status) < 0)
            .filter((domain) => [undefined, null, ''].indexOf(domain.deletionScheduledFor) >= 0);

        if (this.activeMassAction === 'transit') {
            const transitEnabledDomains = deletableDomains
            .filter(
                (domain) => transitEnabledTlds.some(
                    (tld) => [domain.name, domain.nameUnicode].some(
                        (name) => name.lastIndexOf(tld) === (name.length - tld.length)
                    )
                )
            );

            promises = transitEnabledDomains.map(
                (domain) => {
                    const execDate = this.getDeletionDate(domain, settings);
                    return this.domainModel.withdraw(
                        domain,
                        [undefined, null, false].indexOf(settings.disconnect) < 0,
                        execDate
                    );
                }
            );
        } else {
            promises = deletableDomains.map(
                (domain) => {
                    const execDate = this.getDeletionDate(domain, settings);
                    return this.domainModel.delete(domain, execDate);
                }
            );
        }

        return this.$q.all(promises);
    };

    public restoreSubmit = (domains: any[]) => {
        const domainList = domains.filter((domain) => {
            return this.selected[domain.id] && domain.status === 'restorable';
        });

        return ModelHelper.sequentiallyRequestApi(
            domainList,
            'name',
            this.domainModel,
            'restoreByDomainObject',
            /* translationID */'TR_070819-6042e9_TR',
            this.$timeout,
            this.alertManager
        );
    };

    public cancelDeleteSubmit = (domains: any[]) => {
        const itemsToSubmit = [];
        for (const domain of domains) {
            if (this.selected[domain.id]
                && domain.deletionScheduledFor !== undefined
                && domain.deletionScheduledFor !== null
            ) {
                itemsToSubmit.push(domain);
            }
        }
        return this.domainModel.cancelDelete(itemsToSubmit);
    };

    public callDomainOverviewList = (overviewData) => {
        const limit = overviewData.pagination !== undefined && overviewData.pagination.limit !== undefined
            ? overviewData.pagination.limit
            : 10;
        const currentPage = overviewData.pagination !== undefined && overviewData.pagination.currentPage !== undefined
            ? overviewData.pagination.currentPage
            : 1;
        const simpleFilterValue = overviewData.filters !== undefined
            && overviewData.filters.simpleFilter !== undefined
            && overviewData.filters.simpleFilter.value !== undefined
                ? overviewData.filters.simpleFilter.value
                : '';
        const additionalFilters = overviewData.additionalFilters !== undefined
            ? overviewData.additionalFilters
            : [];
        const objectId = overviewData.objectId ? overviewData.object : undefined;
        const index = [undefined, null].indexOf(overviewData.index) === -1 ? overviewData.index : undefined;
        const sort = overviewData.sort ? overviewData : undefined;

        return this.list(
            limit,
            currentPage,
            simpleFilterValue,
            additionalFilters,
            objectId,
            index,
            sort
        );
    };

    private getDeletionDate = (domain: any, settings: any) => {
        if (settings.actiontype === 'sofort') {
            return null;
        }
        if (settings.actiontype === 'endOfContract') {
            return new Date(domain.currentContractPeriodEnd);
        }
        if (settings.actiontype === 'date') {
            return settings.deleteDate.dateObj;
        }
    };

    private getSelectedDomains = () => {
        const domains: string[] = [];
        for (const key in this.selected) {
            if (this.selected[key]) {
                domains.push(key);
            }
        }
        return domains;
    };

    private bulkEditAvailable = (domains: any) => {
        const selectedDomains = domains.filter(
            (domain) => [undefined, null, false].indexOf(this.selected[domain.id]) < 0
        );

        if (selectedDomains.length < 1) {
            return false;
        }

        // 1. The comment refers to 'move', but this is about 'edit'... Something's fishy here.
        // 2. For bulk edit, this condition is no longer required as of PUI-5807.
        //    I'm leaving it as a comment in case somebody remembers that it should be in moveBulkAvailable.
        // if (selectedDomains.some((domain) => domain.accountId !== selectedDomains[0].accountId)) {
        //     // Can only move domains in bulk if they belong to the same account
        //     return false;
        // }

        return selectedDomains.every((domain) => domain.status === 'active');
    };

    private moveBulkAvailable = (domains: any) => {
        const selectedDomains = domains.filter(
            (domain) => [undefined, null, false].indexOf(this.selected[domain.id]) < 0
        );

        return selectedDomains.length > 0
            && selectedDomains.every((domain) => domain.status === 'active');
    };

    private authCodesAvailable = (domains: any) => {
        const selectedDomains = domains.filter(
            (domain) => [undefined, null, false].indexOf(this.selected[domain.id]) < 0
        );

        return selectedDomains.length > 0 && selectedDomains.every((domain) => domain.status === 'active');
    };

    private goToEditBulk = () => {
        this.navigation.go(
            'domain.domains.ids.edit-bulk',
            { domainIds: JSON.stringify(this.getSelectedDomains()) }
        );
    };

    private goToMove = () => {
        this.navigation.go(
            'domain.domains.ids.move-bulk',
            { domainIds: JSON.stringify(this.getSelectedDomains()) }
        );
    };

    private _getRoute = (type) => {
        const stateParts = this.$state.current.name.split('.');
        let route = 'domain.domains.';
        if (stateParts.indexOf('bundle') >= 0) {
            route = 'bundle.id.domains.';
        }

        return route + type;
    };

    private _getRouteParams = () => {
        const stateParts = this.$state.current.name.split('.');
        let params = {};
        if (stateParts.indexOf('bundle') >= 0) {
            params = { bundleId: this.$state.$current.locals.globals.bundle.id };
        }

        return params;
    };

}
