import './domain-name-selector.scss';

import ng from 'angular';

import { CalculatePriceFilter } from '@/filters';
import {
    DomainCheckerService, DomainModelService, PriceCacheService, PriceHelperService
} from '@/services';
import * as Types from '@/types';

export class MoleculeFormDomainNameSelectorController implements ng.IController {
    public static $inject: string[] = [
        'domainChecker',
        'priceCache',
        'calculatePriceFilter',
        'priceHelper',
        'domainModel'
    ];

    public domain: string;
    public placeholder: string;
    public ownedDomains: any[];
    public domainStatus = '';
    public domainPrice: number;
    public domainCurrency: string;
    public registerLater = false;

    private lastCheckedDomainName: string;

    constructor(
        private domainChecker: DomainCheckerService,
        private priceCache: PriceCacheService,
        private calculatePriceFilter: CalculatePriceFilter,
        private priceHelper: PriceHelperService,
        private domainModel: DomainModelService
    ) {}

    public $onInit() {
        this.domainModel.list().then(
            (reply) => {
                this.ownedDomains = [];
                reply.data.map(
                    (domain) => {
                        this.ownedDomains.push({
                            name: domain.nameUnicode,
                            value: domain.nameUnicode
                        });
                    }
                );
            }
        );
    }

    public checkDomainAvailability = () => {
        this.domainChecker.checkOne(this.domain).then(
            (reply) => {
                this.domainStatus = reply.status;
                this.lastCheckedDomainName = reply.domainNameUnicode;
                if (reply.status === 'available') {
                    this.getDomainPrice(reply.extension).then(
                        (ret) => {
                            this.domainPrice = ret.amount;
                            this.domainCurrency = ret.currency;
                        }
                    );
                }
            }
        );
    };

    public getDomainPrice = (tld: string) => {
        return this.priceCache.listDomainPrices(tld).then(
            (reply: Types.BillingApi.ArticlePurchasePrice[]) => {
                for (const price of reply) {
                    if (price.productCode.indexOf('create') >= 0) {
                        return {
                            amount: this.calculatePriceFilter(
                                price.netAmount,
                                price.amounts[0].vatRate,
                                1,
                                'gross',
                                null,
                                false,
                                price.productCode,
                                price.contractPeriod),
                            currency: this.priceHelper.getProductCurrency(price)
                        };
                    }
                }
            }
        );
    };

    public get price() {
        if (this.domain === this.lastCheckedDomainName && this.domainStatus === 'available' && !this.registerLater) {
            return this.domainPrice;
        }
        return undefined;
    }

    public set price(value) {
        return;
    }

    public get domainName() {
        if (this.domain !== this.lastCheckedDomainName) {
            return undefined;
        }
        if (this.domainStatus !== 'registered' && this.domainStatus !== 'available') {
            return undefined;
        }
        return this.domain;
    }

    public set domainName(value: string) {
        this.domain = value;
    }

    public get showPriceSettings() {
        return this.domain === this.lastCheckedDomainName && this.domainStatus === 'available';
    }

    public get showTransferLater() {
        return this.domainStatus === 'registered' && this.domain === this.lastCheckedDomainName;
    }

    public get action() {
        if (this.domain !== this.lastCheckedDomainName) {
            return undefined;
        }
        if (this.domainStatus === 'registered') {
            return 'vHostOnly';
        }
        if (this.domainStatus === 'available') {
            if (this.registerLater) {
                return 'vHostOnly';
            } else {
                return 'register';
            }
        }
        return undefined;
    }

    public set action(value) {
        return;
    }

}

export class MoleculeFormDomainNameSelectorComponent implements ng.IComponentOptions {
    public bindings = {
        action: '=',
        domainName: '=',
        placeholder: '@',
        price: '='
    };

    public controller =  MoleculeFormDomainNameSelectorController;
    public template = require('./domain-name-selector.html');
}
