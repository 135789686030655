import { LinkListOverviewItem } from '../../atomic-components/molecules/links/link-list-overview/link-list-overview';
import { UiRights } from '../../configuration';
import { OverviewModel } from '../abstract';
import { AuthContextService } from '../auth-context';
import { RequestCancellerService } from '../request-canceller';

export class CronjobOverviewModel extends OverviewModel {

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$state', 'authContext', '$translate']
    );

    readonly service = 'webhosting';
    readonly objectType = 'Cronjob';
    readonly idField = 'cronjobIndex';
    readonly defaultFilterFields = [];

    public activeMassAction: string | boolean = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};

    protected listCallbacks = [
        {
            callback: () => {
                return {
                    then: callback => callback({
                        data: JSON.parse(JSON.stringify(this.$state.$current.locals.globals.webspace.cronJobs))
                    })
                };
            }
        }
    ];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $state: ng.ui.IStateService,
        private authContext: AuthContextService,
        protected $translate: ng.translate.ITranslateService
    ) {
        super(RequestCanceller);
    }
    // public actionLinks = (): LinkListOverviewItem[] => {
    public actionLinks = (): LinkListOverviewItem[] => {
        const stateParts =  this.$state.current.name.split('.');

        let createRoute;
        let createRouteParams;

        switch (stateParts[0]) {
            case 'bundle':
                createRoute = 'bundle.id.cronjobs.new';
                createRouteParams = { bundleId: this.$state.$current.locals.globals.bundle.id };
                break;
            case 'managed-servers':
                createRoute = 'managed-servers.id.webspaces.id.cronjobs.new';
                createRouteParams = {
                    machineId: this.$state.$current.locals.globals.machine.id,
                    webspaceId: this.$state.$current.locals.globals.webspace.id
                };
                break;
            default:
                createRoute = 'webhosting.webspaces.id.cronjobs.new';
                createRouteParams = { webspaceId: this.$state.$current.locals.globals.webspace.id };
        }

        return [
            {
                isGranted: this.authContext.isGranted(UiRights.WEB_WEBSPACE_EDIT),
                route: createRoute,
                routeParams: createRouteParams,
                text: this.$translate.instant('TR_140119-c8319b_TR')
            }
        ];
    };
}
