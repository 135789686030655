import * as ng from 'angular';

export class OrganEditPanelDnsZoneExpertSettingsController {
    public static $inject: string[] = ['$timeout'];

    public zone: any;
    public dnsSecOptions: any;
    public dnsSecActive: boolean;
    public originalZone: any;
    public originalDnsSecOptions: any;
    public showExpertSettings = false;
    public userSettingShowExpertSettings: boolean;
    public panelRight = {
        editPanelButton: true
    };
    public dnsSecButtons: any[] = [
        {
            label: 'NSEC3',
            value: 'nsec3'
        },
        {
            label: 'NSEC',
            value: 'nsec'
        }
    ];

    constructor(private $timeout: ng.ITimeoutService) {}

    public $onInit() {
        this.originalZone = ng.copy(this.zone);
        this.originalDnsSecOptions = ng.copy(this.dnsSecOptions);
        this.dnsSecActive = ['presigned', 'automatic'].indexOf(this.zone.dnsSecMode.toLowerCase()) >= 0;
    }

    public cancel = () => {
        this.zone = ng.copy(this.originalZone);
        this.dnsSecOptions = ng.copy(this.originalDnsSecOptions);
        this.dnsSecActive = ['presigned', 'automatic'].indexOf(this.zone.dnsSecMode.toLowerCase()) >= 0;
        if (!this.userSettingShowExpertSettings) {
            this.showExpertSettings = false;
        }
    };

    public get transferAllowed() {
        return this.zone.type === 'MASTER';
    }

    public set transferAllowed(value: boolean) {
        this.zone.type = value ? 'MASTER' : 'NATIVE';
    }

    public dnsSecStateChanged = () => {
        this.$timeout(() => {
            if (this.dnsSecActive) {
                if ([null, undefined].indexOf(this.dnsSecOptions) >= 0) {
                    this.dnsSecOptions = {
                        nsecMode: 'nsec3'
                    };
                } else {
                    this.dnsSecOptions.nsecMode = 'nsec3';
                }
            }
        });
    };
}

export class OrganEditPanelDnsZoneExpertSettingsComponent implements ng.IComponentOptions {
    public bindings = {
        dnsSecActive: '=',
        dnsSecOptions: '=',
        showExpertSettings: '=',
        userSettingShowExpertSettings: '<',
        zone: '='
    };
    public template = require('./dns-zone-expert-settings.html');
    public controller = OrganEditPanelDnsZoneExpertSettingsController;
}
