import ng from 'angular';
import q from 'q';
import { DomainTypes } from '@/atomic-components/organs/create/configuration/domain/domain-type-selection';
import {
    CleanableStringType,
    DnsHelperService,
    DnsNameserverInfoObject,
    DnsUpdateDataObject,
    DnsZoneModelService,
    NameserverSetModelService,
    SentryErrorEmitterService,
    StringCleaner,
    SystemHelperService
} from '@/services/';
import { DnsApi, ViewTypes, WebhostingApi } from '@/types';

export interface ZoneRecordsToUpdate {
    addRecords: DnsApi.Record[];
    deleteRecords: DnsApi.Record[];
    modifyRecords: DnsApi.Record[];
}

export interface ZoneUpdateData {
    zone: DnsApi.ZoneConfig;
    records: ZoneRecordsToUpdate;
    update: boolean;
    create: boolean;
}

export interface IDomainListNormalized {
    domainName: string;
    domainNameUnicode: string;
    domainNameAce?: string;
}

export class OrganEditPanelDnsRecordsUpdaterController {
    public static $inject: string[] = ['$timeout', '$translate', 'dnsHelper', 'dnsZoneModel', 'nameserverSetModel', 'systemHelper'];

    public dnsSetting: {
        type: string;
        values: ViewTypes.DomainWizardDnsSettingsObject;
    };
    public dnsZone: DnsApi.ZoneConfig = null;
    public domain: IDomainListNormalized;
    public existendZoneRecords: DnsApi.Record[] = null;
    public nameservers: DnsNameserverInfoObject[];
    public vhost: WebhostingApi.VHost;
    public webspace: WebhostingApi.Webspace;
    public zoneName: string;
    public zoneNameUnicode: string;
    public externalNameservers = false;
    public enableAlias = false;
    public zoneUpdateData: ZoneUpdateData;

    private _accountId: string;
    private _complete = false;
    private _defaultNameserverSet: ViewTypes.DnsDefaultNameserverSet;
    private _domainType: DomainTypes;
    private _dnsZoneCache: DnsApi.ZoneConfig[] = [];
    private _checkedRecords = false;
    public _dnsSetting: string;

    public constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private dnsHelper: DnsHelperService,
        private dnsZoneModel: DnsZoneModelService,
        private nameserverSetModel: NameserverSetModelService,
        private systemHelper: SystemHelperService
    ) {}

    public async $onInit(): Promise<void> {
        this._dnsSetting = JSON.stringify(this.dnsSetting);
        this._defaultNameserverSet = await this.nameserverSetModel.getDefault(this._accountId);
        this._checkZoneUpdateDataObject();
        void this._checkDnsRecords();
    }

    public $onChanges(changes: ng.IOnChangesObject): void {
        switch (true) {
            case !changes.nameservers:
            case !changes.webspace:
            case !changes.externalNameservers:
            case !changes.enableAlias:
                void this._checkDnsRecords();
                break;
        }
    }

    public $doCheck(): void {
        if (JSON.stringify(this.dnsSetting) !== this._dnsSetting) {
            this._dnsSetting = JSON.stringify(this.dnsSetting);
            void this._checkDnsRecords();
        }
    }

    public set checkedRecords(_) {/* */}
    public get checkedRecords(): boolean {
        this._complete = this._checkedRecords;
        return this._checkedRecords;
    }

    public set dnsZoneHasToBeCreated(_) {/* */}
    public get dnsZoneHasToBeCreated(): boolean {
        return this.checkedRecords
            && this.zoneUpdateData.zone === null
            && this.zoneUpdateData.create;
    }

    public set dnsZoneExistsAndRecordsValid(_) {/* */}
    public get dnsZoneExistsAndRecordsValid(): boolean {
        return this.checkedRecords
            && this.zoneUpdateData.zone !== null
            && !this.recordsToUpdate;
    }

    public set externalDomainWithoutZoneHint(_) {/* */}
    public get externalDomainWithoutZoneHint(): boolean {
        return this._domainType !== DomainTypes.REGISTER
            && this.zoneUpdateData.zone === null;
    }

    public set recordsToUpdate(_) {/* */}
    public get recordsToUpdate(): boolean {
        if (!this.zoneUpdateData.records) {
            return false;
        }

        return this.zoneUpdateData.records?.addRecords?.length > 0
            || this.zoneUpdateData.records?.deleteRecords?.length > 0
            || this.zoneUpdateData.records?.modifyRecords?.length > 0;
    }

    public existendRecordWillBeModified = (
        record: DnsApi.Record,
        recordType: string,
        newContent: unknown
    ): boolean => {
        return this.existendZoneRecords.some(
            (existendRecord) =>
                existendRecord.id === record.id
                && this.systemHelper.getPropertyOfObject(
                    existendRecord, recordType as keyof DnsApi.Record
                ) !== newContent
        );
    };

    public getExistendRecordValue = (
        record: DnsApi.Record,
        recordType: string
    ): unknown => {
        let oldRecord: DnsApi.Record;

        for (const existendRecord of this.existendZoneRecords) {
            if (existendRecord.id === record.id) {
                oldRecord = existendRecord;
            }
        }

        return oldRecord
            ? this.systemHelper.getPropertyOfObject(oldRecord, recordType as keyof DnsApi.Record)
            : '';
    };

    public get viewableZoneName(): string {
        return this.$translate.instant('TR_060420-0f6bc4_TR', { zoneName: this.zoneNameUnicode });
    }

    private _checkDnsRecords = (): boolean | PromiseLike<void> => {
        this._checkedRecords = false;
        this.zoneNameUnicode = this._domainType === DomainTypes.REGISTER
            ? StringCleaner.clean(this.domain?.domainName).as(CleanableStringType.DomainNames)
            : StringCleaner.clean(this.domain?.domainNameUnicode).as(CleanableStringType.DomainNames);
        this.zoneName = this._domainType === DomainTypes.REGISTER
            ? StringCleaner.clean(this.domain?.domainNameAce).as(CleanableStringType.DomainNames)
            : StringCleaner.clean(this.domain?.domainName).as(CleanableStringType.DomainNames);

        this._checkZoneUpdateDataObject();

        if ([undefined, null, ''].indexOf(this.zoneNameUnicode) >= 0
            || (
                this.externalNameservers
                && !this.webspace
            )
            || (
                this.webspace
                && [undefined, null, ''].indexOf(this.zoneNameUnicode) >= 0
            )
        ) {
            this.zoneUpdateData = {
                zone: null,
                records: null,
                update: false,
                create: false
            };
            this.existendZoneRecords = null;
            this._checkedRecords = true;
            return false;
        }

        return this._getDnsZone()
            .then(this._getDnsRecords)
            .then(this._checkRecords)
            .then(() => {
                void this.$timeout(() => {
                    this._checkedRecords = true;
                });
            });
    };

    private _checkRecords = (): void => {
        if (this.zoneUpdateData?.zone) {
            let selectedNameservers = [];
            // eslint-disable-next-line no-extra-boolean-cast
            if (!!this.webspace) {
                for (const ns of this._defaultNameserverSet.nameservers) {
                    selectedNameservers.push({
                        name: ns,
                        ips: [] as string[],
                        ipsRequired: false
                    });
                }
            } else {
                selectedNameservers = this.nameservers;
            }

            const dnsData: DnsUpdateDataObject =  {
                dnsSetting: this.dnsSetting,
                existendRecords: ng.copy(this.existendZoneRecords),
                zone: this.zoneUpdateData.zone,
                // eslint-disable-next-line no-extra-boolean-cast
                nameservers: selectedNameservers
            };

            this.zoneUpdateData.update = true;
            this.zoneUpdateData.create = false;
            this.zoneUpdateData.records = this.dnsHelper.getZoneRecordsToUpdate(
                dnsData,
                this.webspace,
                this._domainType === DomainTypes.REGISTER
                    ? this.domain.domainNameAce
                    : this.domain.domainName,
                this._domainType,
                this.enableAlias
            );
        } else {
            this.zoneUpdateData.update = false;
            this.zoneUpdateData.create = this._domainType === DomainTypes.REGISTER;
            this.zoneUpdateData.records = null;
        }
    };

    private _getDnsZone = (): PromiseLike<null | DnsApi.ZoneConfig> => {
        const dnsZoneCached = this._dnsZoneCache.filter((zone) => zone.nameUnicode === this.zoneNameUnicode);
        if (dnsZoneCached.length >= 1) {
            this.zoneUpdateData.zone = dnsZoneCached[0];
            this._rewriteZoneNameOnIDNsCheck(dnsZoneCached[0]);
            return q.when(dnsZoneCached[0]) as q.IPromise<DnsApi.ZoneConfig>;
        }

        const zoneFilter = {
            subFilter: [
                { field: 'zoneName', value: this.zoneName },
                { field: 'zoneNameUnicode', value: this.zoneNameUnicode }
            ],
            subFilterConnective: 'OR'
        };

        return this.dnsZoneModel.listWithoutPagination(null, 1, zoneFilter)
            .then(
                (response: {data: DnsApi.ZoneConfig[]}) => {
                    let searchedZone: DnsApi.ZoneConfig = null;
                    response.data.some((zone: DnsApi.ZoneConfig) => {
                        if (zone.name === this.zoneName && zone.nameUnicode === this.zoneNameUnicode) {
                            searchedZone = zone;
                            return true;
                        }
                        return false;
                    });

                    if (searchedZone) {
                        this._dnsZoneCache.push(searchedZone);
                        this._rewriteZoneNameOnIDNsCheck(searchedZone);
                    }

                    this.zoneUpdateData.zone = searchedZone?.type.toLowerCase() === 'native'
                        ? searchedZone
                        : null;

                    return this.zoneUpdateData.zone;
                }
            );
    };

    private _checkZoneUpdateDataObject = (): void => {
        if (!this.domain) {
            SentryErrorEmitterService.sendSentryReport(
                'DNS records updater error',
                { message: 'Domain is not set. This shouldnt happen!', file: '.../organs/panels/.../dns-records-updater.ts' },
                { key: 'domains', service: 'dns-record-updater' }
            );
            return;
        }

        if (!this.zoneUpdateData) {
            this.zoneUpdateData = {
                zone: null,
                records: null,
                update: true,
                create: false
            };
        }
    };

    private _getDnsRecords = (dnsZone: DnsApi.ZoneConfig): PromiseLike<DnsApi.Record[]> => {
        if (dnsZone === null) {
            this.zoneUpdateData.update = false;
            this.existendZoneRecords = null;
            return q.when(null) as q.IPromise<DnsApi.Record[]>;
        }

        return this.dnsZoneModel.recordsList({ field: 'zoneConfigId', value: dnsZone.id })
            .then(
                (records) => {
                    this.existendZoneRecords = records.data;
                    return records.data as DnsApi.Record[];
                }
            );
    };

    private _rewriteZoneNameOnIDNsCheck = (zoneObject: DnsApi.ZoneConfig): void => {
        if (zoneObject.name !== zoneObject.nameUnicode) {
            // rewrite zoneName if is IDNs
            this.zoneName = zoneObject.name;
        }
    };
}

export class OrganEditPanelDnsRecordsUpdaterComponent implements ng.IComponentOptions {
    public bindings = {
        _accountId: '=accountId',
        _complete: '=?complete',
        _domainType: '<?domainType',
        dnsSetting: '<',
        domain: '=',
        externalNameservers: '<',
        nameservers: '<?',
        zoneUpdateData: '=',
        enableAlias: '<?',
        webspace: '<?'
    };
    public template = require('./dns-records-updater.html');
    public controller = OrganEditPanelDnsRecordsUpdaterController;
}
