import * as ng from 'angular';
import {
    AccountModelService,
    AuthContextService,
    NavigationService,
    ResourceRobotService
} from '../../../../../services';
import * as Types from '../../../../../types';
import { EditPanelStatus } from '../../../../molecules/forms/form-edit/form-edit';

export class OrganismNewFormPoolController {
    public static $inject: string[] = ['$q', 'accountModel', 'authContext', 'navigation', 'resourceRobot'];

    public user: Types.WebhostingApi.User;
    public accountId: string;
    public showAccountSelect = false;
    public defaultPool = false;
    public poolName: string;
    public userPanelRight: any;
    public initialStatus: any = EditPanelStatus.ACTIVE;

    constructor(
        private $q: ng.IQService,
        accountModel: AccountModelService,
        private authContext: AuthContextService,
        private navigation: NavigationService,
        private resourceRobot: ResourceRobotService
    ) {
        accountModel.listSubaccountsWithoutPagination(1)
        .then(
            (res) => this.showAccountSelect = res.data.length > 0
        );
    }

    public save = () => {
        const pool = {
            defaultPool: this.defaultPool,
            name: this.poolName
        };
        return this.resourceRobot.createPool(pool, this.accountId)
        .then(
            () => {
                this.navigation.go('machine.pools', {}, { reload: true });
                return this.$q.reject();
            }
        );
    };

    public $onInit = () => {
        this.accountId = this.authContext.account.id;
        this.defaultPool = false;
        this.poolName = '';
    };
}

export class OrganismNewFormPoolComponent implements ng.IComponentOptions {
    public bindings = {
        userPanelRight: '='
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismNewFormPoolController;
    public template = require('./machine-pool-new.html');
}
