import * as ng from 'angular';
import { UiRights } from '@/configuration';
import { AuthContextService } from '@/services';
import { PanelHeaderData } from '@/atomic-components';

import './pricelists-template.scss';

export interface LeftPanelAreaObject {
    includeShowAllLink: boolean;
    panelHeaderRoute: string;
    panelIcon: string;
    panelTitle: string;
    panelType: string;
}

export class TemplateBillingPricelistsController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;
    public leftPanelArea: PanelHeaderData[] = [];

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {
        this.pageHeaderData = {
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: 'funnel-dollar',
            panelTitle: this.$translate.instant('TR_080119-5c3488_TR')
        };

        if (
            AuthContextService.isDirectCustomer
            || ['prepaid', 'postpaid'].indexOf(AuthContextService.billingSettings.paymentType) >= 0
        ) {
            this.pageHeaderData.backwardLink = 'billing.dashboard';
            this.pageHeaderData.backwardText = this.$translate.instant('TR_090119-ebc8d5_TR');
        }

        if (AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_DATABASE)) {
            this.leftPanelArea.push(
                {
                    includeShowAllLink: true,
                    panelHeaderRoute: 'billing.pricelists.promotions',
                    panelIcon: 'funnel-dollar',
                    panelTitle: this.$translate.instant('TR_090119-6e01db_TR'),
                    panelType: 'actions'
                }
            );
        }

        if (
            AuthContextService.isGrantedAny(
                [
                    UiRights.BIL_LIST_PRICES_DATABASE,
                    UiRights.BIL_LIST_PRICES_DNS,
                    UiRights.BIL_LIST_PRICES_DOMAIN,
                    UiRights.BIL_LIST_PRICES_EMAIL,
                    UiRights.BIL_LIST_PRICES_MACHINE,
                    UiRights.BIL_LIST_PRICES_SSL,
                    UiRights.BIL_LIST_PRICES_WEBHOSTING
                ]
            )
        ) {
            this.leftPanelArea.push(
                {
                    includeShowAllLink: true,
                    panelHeaderRoute: 'billing.pricelists.changes',
                    panelIcon: 'funnel-dollar',
                    panelTitle: this.$translate.instant('TR_090119-5a4dda_TR'),
                    panelType: 'changes'
                }
            );
        }

        if (AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_MACHINE)) {
            this.leftPanelArea.push(
                {
                    includeShowAllLink: true,
                    panelHeaderRoute: 'billing.pricelists.machines',
                    panelIcon: 'funnel-dollar',
                    panelTitle: this.$translate.instant('TR_090119-ad5c6b_TR'),
                    panelType: 'cloudserver'
                }
            );
        }

        if (AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_DNS)) {
            this.leftPanelArea.push(
                {
                    includeShowAllLink: true,
                    panelHeaderRoute: 'billing.pricelists.dns',
                    panelIcon: 'funnel-dollar',
                    panelTitle: this.$translate.instant('03d67c8e-683a-4982-ac59-5637dfe27b71'),
                    panelType: 'dns'
                }
            );
        }

        if (AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_DOMAIN)) {
            this.leftPanelArea.push(
                {
                    includeShowAllLink: true,
                    panelHeaderRoute: 'billing.pricelists.domains',
                    panelIcon: 'funnel-dollar',
                    panelTitle: this.$translate.instant('TR_090119-0b30af_TR'),
                    panelType: 'domains'
                }
            );
        }

        if (AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_SSL)) {
            this.leftPanelArea.push(
                {
                    includeShowAllLink: true,
                    panelHeaderRoute: 'billing.pricelists.ssl',
                    panelIcon: 'funnel-dollar',
                    panelTitle: this.$translate.instant('TR_090119-c1e94f_TR'),
                    panelType: 'ssl'
                }
            );
        }

        if (AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_EMAIL)) {
            this.leftPanelArea.push(
                {
                    includeShowAllLink: true,
                    panelHeaderRoute: 'billing.pricelists.mailboxes',
                    panelIcon: 'funnel-dollar',
                    panelTitle: this.$translate.instant('c78b82a7-e70c-4ea5-a1da-52a99244c3b1'),
                    panelType: 'mailboxes'
                }
            );
        }

        if (AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_WEBHOSTING)) {
            this.leftPanelArea.push(
                {
                    includeShowAllLink: true,
                    panelHeaderRoute: 'billing.pricelists.webhosting',
                    panelIcon: 'funnel-dollar',
                    panelTitle: this.$translate.instant('TR_090119-12c8cf_TR'),
                    panelType: 'webhosting'
                }
            );
        }

        if (AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_DATABASE)) {
            this.leftPanelArea.push(
                {
                    includeShowAllLink: true,
                    panelHeaderRoute: 'billing.pricelists.databases',
                    panelIcon: 'funnel-dollar',
                    panelTitle: this.$translate.instant('TR_090119-f0ccfb_TR'),
                    panelType: 'databases'
                }
            );
        }


        if (
            AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_MANAGEDAPPLICATION)
        ) {
            this.leftPanelArea.push(
                {
                    includeShowAllLink: true,
                    panelHeaderRoute: 'billing.pricelists.storage-product',
                    panelIcon: 'funnel-dollar',
                    panelTitle: this.$translate.instant('TR_140519-4f0af6_TR'),
                    panelType: 'storage-product'
                }
            );
        }
    }
}

export class TemplateBillingPricelistsComponent implements ng.IComponentOptions {
    public controller = TemplateBillingPricelistsController;
    public controllerAs = 'TemplateBillingPriceListsCtrl';
    public template = require('./pricelists-template.html');
}
