import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateWebspaceUserOverviewController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;
    public overviewServiceData;
    public expertView: boolean;
    public overviewModel;
    public faqArticleIdList: string[] = [
        'webspace-ftp-access',
        'webspace-file-directory',
        'webspace-ftp-folders'
    ];
    private simpleFilter;
    private extendedFilters;
    private overviewUiSettings;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit() {
        if ([undefined, null].indexOf(this.simpleFilter) === -1) {
            this.expertView = this.overviewUiSettings.expertView || this.simpleFilter.show;
        } else {
            this.expertView = this.overviewUiSettings.expertView || false;
        }

        this.pageHeaderData = {
            backwardLink: 'webhosting.dashboard',
            backwardText: this.$translate.instant('TR_140119-285630_TR'),
            panelHeaderRoute: undefined,
            panelHeaderRouteParams: null,
            panelIcon: 'folder',
            panelTitle: this.$translate.instant('TR_090119-a31e5e_TR')
        };

        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter
        };
    }

    public expandWrapper = (toggle) => {
        this.expertView = toggle.expand;
    };
}

export class TemplateWebspaceUserOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public controller = TemplateWebspaceUserOverviewController;
    public controllerAs = 'TemplateWebspaceUserOverviewCtrl';
    public template = require('./user-overview-template.html');
}
