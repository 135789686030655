import * as ng from 'angular';
import {
    AlertManagerService,
    DatabaseModelService,
    DateWrapper,
    NavigationService
} from '../../../../../services';
import {
    EditPanelStatus,
    MoleculeFormEditController
} from '../../../../molecules/forms';

export class OrganEditPanelDatabaseProductDeleteController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'databaseModel',
        'navigation'
    ];

    public database: any;
    public $editFormMolecule: MoleculeFormEditController;
    public initialStatus: EditPanelStatus;
    public deletionConfirmed = false;
    public paid: boolean;
    public deletionType = 'immediate';
    public deletionDate: DateWrapper;
    public deletionTypeItems: any[] = [];

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private databaseModel: DatabaseModelService,
        private navigation: NavigationService
    ) {}

    public $onInit() {
        this.deletionTypeItems = [{
            label: this.$translate.instant('8c45521c-005d-4867-bcf0-f0ef38b03e48'),
            value: 'immediate'
        }, {
            label: this.$translate.instant('TR_110119-0c8d3a_TR'),
            value: 'endOfContract'
        }, {
            label: this.$translate.instant('TR_110119-69fa60_TR'),
            value: 'date'
        }];

        this.$editFormMolecule.deleteCallback = this.delete;
        this.initialStatus = this.database.deletionScheduledFor !== null
            ? EditPanelStatus.DELETIONSCHEDULED
            : EditPanelStatus.READONLY;
    }

    public delete = () => {
        this.databaseModel
            .deleteOne(this.database, (this.deletionDate.isToday() ? null : this.deletionDate.dateObj))
            .then(
                (reply) => {
                    this.database = JSON.parse(JSON.stringify(reply.response));
                    if (this.paid) {
                        this.alertManager.success(this.$translate.instant('TR_230119-a6905c_TR'));
                    } else {
                        this.alertManager.success(this.$translate.instant('TR_230119-b2aff9_TR'));
                    }
                    return reply;
                }
            );
    };

    public cancel = () => {
        this.deletionConfirmed = false;
    };

    public get enableDeletion() {
        return this.deletionConfirmed && (this.deletionType !== 'date' || this.deletionDate !== undefined);
    }
}

export class OrganEditPanelDatabaseProductDeleteComponent implements ng.IComponentOptions {
    public bindings = {
        database: '=',
        initialStatus: '<',
        paid: '<'
    };
    public require = {
        $editFormMolecule: '^moleculeFormEdit'
    };
    public controller = OrganEditPanelDatabaseProductDeleteController;
    public template = require('./database-product-delete.html');
}
