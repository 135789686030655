import * as ng from 'angular';

export class OrganEditPanelSslCertificateRevokeController {
    public static $inject: string[] = [];

    public $editFormOrganism;
    public panelRight;
    public revokeConfirm = false;
    public cancelCallback: () => void = () => {}; // tslint:disable-line:no-empty
    public get enableRevoking() {
        return this.revokeConfirm;
    }

    public cancel = () => {
        this.revokeConfirm = false;
        if ([undefined, null].indexOf(this.cancelCallback) === -1) {
            this.cancelCallback();
        }
    };
}

export class OrganEditPanelSslCertificateRevokeComponent implements ng.IComponentOptions {
    public bindings = {
        cancelCallback: '<cancel',
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormSslCertificate'
    };
    public controller = OrganEditPanelSslCertificateRevokeController;
    public controllerAs = '$editPanelOrgan';
    public template = require('./ssl-certificate-revoke.html');
}
