import * as ng from 'angular';
import { UiRights } from '../../../../configuration';
import {
    AuthContextService,
    DepositModelService,
    FunctionRelayService
} from '../../../../services';
import {
    LinkListItem
} from '../../links/link-list/link-list';

import './user-action.scss';

export class MoleculePopupUserActionController implements ng.IController {
    public static $inject: string[] = ['$timeout', 'authContext', 'depositModel', 'functionRelay'];

    public modalIcon: string;
    public modalTitle: any;
    public modalLinkItems: LinkListItem;
    public subheadline: string;
    public hasCustomerNumber = false;

    private _lastAccountId: string;
    private _deposit = {
        balance: 0,
        effectiveBalance: 0,
        effectiveVoucherBalance: 0,
        positiveBalance: 0,
        reserved: 0,
        voucherBalance: 0
    };

    constructor(
        private $timeout: ng.ITimeoutService,
        private authContext: AuthContextService,
        private depositModel: DepositModelService,
        private functionRelay: FunctionRelayService
    ) {
        this.functionRelay.register(
            'updateDeposit',
            () => this.$timeout(
                this.updateDeposit,
                500
            )
        );
    }

    public $onInit() {
        if (this.account.customerNumber.length > 0) {
            this.hasCustomerNumber = true;
        }
    }

    public get account() {
        return this.authContext.account;
    }

    public get deposit() {
        if (this._lastAccountId !== this.authContext.account.id) {
            this._lastAccountId = this.authContext.account.id;
            this.updateDeposit();
        }
        return this._deposit;
    }

    public get showDeposit() {
        return this.authContext.isGranted(UiRights.BIL_GET_DEPOSIT);
    }

    private updateDeposit = () => {
        if (this.authContext.isGranted(UiRights.BIL_GET_DEPOSIT)) {
            this.depositModel.getDeposit()
                .then(
                    (deposit) => {
                        this._deposit.balance = deposit.balance;
                        this._deposit.voucherBalance = deposit.voucherBalance;
                        this._deposit.reserved = deposit.reserved;

                        this._deposit.positiveBalance = deposit.balance + deposit.voucherBalance;
                        this._deposit.effectiveVoucherBalance = ((deposit.voucherBalance - deposit.reserved) > 0)
                            ? (deposit.voucherBalance - deposit.reserved) : 0;
                        this._deposit.effectiveBalance = deposit.balance + deposit.voucherBalance - deposit.reserved;
                    }
                );
        }
    };

    public get showSubheadline() {
        return this.subheadline !== undefined;
    }

}

export class MoleculePopupUserActionComponent implements ng.IComponentOptions {
    public bindings = {
        modalIcon: '@',
        modalLinkItems: '<',
        modalTitle: '@',
        subheadline: '@'
    };
    public controller = MoleculePopupUserActionController;
    public template = require('./user-action.html');
}
