import ng from 'angular';

import { MoleculeFormEditController } from '@/atomic-components/molecules';
import { HandleConst } from '@/configuration';
import {
    addHiddenContactsFilter,
    AlertManagerService,
    ApiErrorModel,
    DomainContactValidatorService,
    DomainHandleModelService
} from '@/services';
import { HandlePropertyFilter } from '@/filters';
import { DateWrapper } from '@/services/date';
import { DomainApi, Finding, UI, ViewTypes } from '@/types';

import './panel-edit-row-contact.scss';

// eslint-disable-next-line no-shadow
export enum DomainContactPanelMode {
    CLOSED,
    ADD,
    COPY,
    DISPLAY,
    EDIT,
    SEARCH
    // CREATE is missing (Country)
}

interface IValidationResults {
    missingProperties: string[];
    mustBeOfType: string[];
}

export class MoleculePanelEditRowContactController implements ng.IController {
    public static $inject: string[] = [
        'domainContactValidator',
        '$translate',
        '$timeout',
        'alertManager',
        'apiErrorModel',
        'domainHandleModel',
        'handlePropertyFilter'
    ];

    public $formEdit: MoleculeFormEditController;
    public selectedContact: string;
    public _mode: DomainContactPanelMode;
    public contact: DomainApi.Contact;
    public domains: unknown[];
    public owner: string;
    public numberOfSelectedDomains: number;
    public popoverPosition: string;
    public validationResults: IValidationResults = {
        missingProperties: [],
        mustBeOfType: []
    };
    public handleList: ViewTypes.DomainContactObject[] = [];
    public setValidation: () => void;
    public additionalContactFilters: Finding.Filter[];
    public simpleVersion: boolean;
    public type = 'fallback';
    public modalOpen = false;
    public designateAgent: undefined;
    public reloadDropdown = false;

    private lastContactValidation: string;
    private validContact: boolean;
    private selectedContactList: DomainApi.Contact[];
    public _handleListBackup: ViewTypes.DomainContactObject[];
    private _readOnly = false;

    constructor(
        private domainContactValidator: DomainContactValidatorService,
        private $translate: ng.translate.ITranslateService,
        private $timeout: ng.ITimeoutService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private domainHandleModel: DomainHandleModelService,
        private handlePropertyFilter: HandlePropertyFilter
    ) {}

    public $onInit(): void {
        this._handleListBackup = JSON.parse(JSON.stringify(this.handleList));
        this.simpleVersion = this.simpleVersion || false;
        this._mode = DomainContactPanelMode.CLOSED;
        const subFilters: Finding.Filter[] = [];
        this.handleList?.map((handle) => {
            if (handle.contactType === this.type) {
                this.selectedContact = handle.contactId;
            }

            subFilters.push({
                field: 'ContactId',
                value: handle.contactId
            } as Finding.Filter);
        });
        const contactsFilter: Finding.Filter = {
            subFilter: subFilters,
            subFilterConnective: 'OR'
        };

        void this.domainHandleModel.listWithoutPagination(4, 1, contactsFilter).then(
            (reply) => {
                this.selectedContactList = reply.data;
            }
        );
    }

    public $onChanges(changes: ng.IOnChangesObject): void {
        if (
            changes.numberOfSelectedDomains !== undefined
            && changes.numberOfSelectedDomains.currentValue !== undefined
        ) {
            void this.validateContact(true);
        }

        if (changes.contactBinding !== undefined) {
            void this.validateContact(true);
        }
    }

    public $doCheck(): void {
        this._hasChangedSelectedHandleFromHandleList();
    }

    public _hasChangedSelectedHandleFromHandleList = (): void => {
        if (this.handleList && this._handleListBackup) {
            const currentHandle = this.handleList.filter((handle) => handle.contactType === this.type)[0];
            const previousHandle = this._handleListBackup.filter((handle) => handle.contactType === this.type)[0];

            if (currentHandle.contactId !== previousHandle.contactId) {
                this._handleListBackup = JSON.parse(JSON.stringify(this.handleList));
                this.selectedContact = currentHandle.contactId;
                void this.validateContact(true);
            }
        }
    };

    public get missingPropertiesMessage(): string {
        let message = '';

        for (const property of this.validationResults.missingProperties) {
            message = `${message}${this.handlePropertyFilter(property) as string}, `;
        }

        return message.slice(0, -2);
    }

    public get mustBeOfTypeMessage(): string {
        let message = '';

        for (let property of this.validationResults.mustBeOfType) {
            property = this.capitalizeFirstLetter(property);
            message = `${message}${property} oder `;
        }

        return message.slice(0, -6);
    }

    public get readOnly(): boolean {
        return this._readOnly;
    }

    public get disableViewButton(): boolean {
        return [undefined, null, ''].indexOf(this.selectedContact) >= 0;
    }

    public get disableCopyButton(): boolean {
        if (this.readOnly) {
            return false;
        }

        return this.disableViewButton;
    }

    public get disableEditButton(): boolean {
        if (this.readOnly) {
            return false;
        }

        return [undefined, null, ''].indexOf(this.selectedContact) >= 0
            || this.isViewOnlyContact();
    }

    public get showSearchModal(): boolean {
        if (this.readOnly) {
            return false;
        }

        if (this._mode === DomainContactPanelMode.SEARCH) {
            this.modalOpen = true;
            return true;
        }
        return false;

    }

    public set showModal(_) {/* */}
    public get showModal(): boolean {
        if ([DomainContactPanelMode.SEARCH, DomainContactPanelMode.CLOSED].indexOf(this._mode) === -1) {
            this.modalOpen = true;
            return true;
        }
        return false;
    }

    public set mode(value) {
        this._mode = value;
    }

    public get mode(): DomainContactPanelMode {
        return this._mode;
    }

    public listCallback = (
        limit?: number,
        page?: number,
        filters?: Finding.Filter,
        sort?: Finding.SortOptions
    ): Promise<unknown> => {
        const tmpFilters = {
            subFilter: [
                {
                    field: 'UsableForDomainInAccount',
                    value: this.owner
                }
            ],
            subFilterConnective: 'AND'
        };

        if ([undefined, null].indexOf(filters) < 0) {
            tmpFilters.subFilter.push(filters as { field: string; value: string });
        }

        return this.domainHandleModel.list(
            limit,
            page,
            addHiddenContactsFilter(tmpFilters, this.selectedContact),
            sort
        );
    };

    public additionalFields = (replyData: DomainApi.Contact): unknown => {
        const resultObject = {type: '', handle: '', name: ''};

        if (replyData.type) {
            (resultObject).type = replyData.type;
            if (resultObject.type === 'person') {
                resultObject.type = this.$translate.instant('TR_100119-74543b_TR');
            } else if (resultObject.type === 'org') {
                resultObject.type = this.$translate.instant('TR_100119-77ee69_TR');
            }
            if (replyData.type === 'org' && [undefined, null, ''].indexOf(replyData.name) >= 0) {
                resultObject.name = replyData.organization || '';
            }
        }
        if (replyData.handle) {
            resultObject.handle = replyData.handle;
        }

        if (replyData.name) {
            resultObject.name = replyData.name;
        }

        return resultObject;
    };

    public confirmSearchedContact = (): void => {
        void this.$timeout(
            () => {
                this.close();
                this.handleList.some(
                    (handle) => {
                        if (handle.contactType === this.type) {
                            this.selectedContact = handle.contactId;
                            return true;
                        }

                        return false;
                    }
                );
                void this.validateContact(true);
            }
        );
    };

    public confirm = (): void => {
        const contactBeforeUpdate = ng.copy(this.contact);

        if (
            this.contact.extDateOfBirth
            && typeof this.contact.extDateOfBirth === 'object'
            && (this.contact.extDateOfBirth as DateWrapper).dateString
        ) {
            this.contact.extDateOfBirth = (this.contact.extDateOfBirth as DateWrapper).dateString;
        }

        if ([DomainContactPanelMode.COPY, DomainContactPanelMode.ADD].indexOf(this._mode) >= 0) {
            void this.domainHandleModel.create(this.contact).then(
                (data: UI.APISingleResponse<DomainApi.Contact>) => {
                    if (!this.apiErrorModel.apiResponseHasError(data)) {
                        this.alertManager.success(/* translationID */ 'TR_310119-cb745b_TR');
                        this._setSelectedContact(data.response.id, true);
                        this.close();
                    } else {
                        // workaround so the user changes don't get lost on error
                        this.contact = contactBeforeUpdate;
                    }
                    void this.validateContact(true);
                }
            );
        }

        if (this._mode === DomainContactPanelMode.EDIT) {
            void this.domainHandleModel.update(this.contact, this.designateAgent).then(
                (data: UI.APISingleResponse<DomainApi.Contact>) => {
                    if (!this.apiErrorModel.apiResponseHasError(data)) {
                        this.alertManager.success(/* translationID */ 'TR_310119-cb745b_TR');
                        this._setSelectedContact(data.response.id, true);
                        this.close();
                    } else {
                        // workaround so the user changes don't get lost on error
                        this.contact = contactBeforeUpdate;
                    }
                    void this.validateContact(true);
                }
            );
        }
    };

    public validateOnContactChange = (): void => {
        void this.validateContact(true);
    };

    public capitalizeFirstLetter = (str: string): string => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    public handleDisplayFilter = (item: DomainApi.Contact): string => {
        let handleName = `${item.handle} | `;

        if (['org', 'role'].indexOf(item.type) >= 0) {
            handleName += `${item.organization}
                (${this.$translate.instant(HandleConst.HandleTypes.labels[item.type])})`;
        } else {
            handleName += `${item.name}
                (${this.$translate.instant(HandleConst.HandleTypes.labels[item.type] as string)})`;

            if (item.organization.length > 0) {
                handleName += ` | ${item.organization}`;
            }
        }

        return handleName;
    };

    public searchContacts = (): void => {
        this._mode = DomainContactPanelMode.SEARCH;
    };

    public addContact = (): void => {
        this.contact = {
            city: undefined,
            country: undefined,
            street: [''],
            type: undefined
        };

        this._mode = DomainContactPanelMode.ADD;
    };

    public viewContact = (): void => {
        void this.domainHandleModel.findOne(this.selectedContact).then(
            (reply: DomainApi.Contact) => {
                void this.$timeout(() => {
                    this.contact = reply;
                    this._mode = DomainContactPanelMode.DISPLAY;
                });
            }
        );
    };

    public editContact = (): void => {
        void this.domainHandleModel.findOne(this.selectedContact).then(
            (reply: DomainApi.Contact) => {
                void this.$timeout(() => {
                    this.contact = reply;
                    this._mode = DomainContactPanelMode.EDIT;
                });
            }
        );
    };

    public copyContact = (): void => {
        void this.domainHandleModel.findOne(this.selectedContact).then(
            (reply: DomainApi.Contact) => {
                void this.$timeout(() => {
                    this.contact = ng.copy(reply);
                    delete(this.contact.addDate);
                    delete(this.contact.handle);
                    delete(this.contact.id);
                    delete(this.contact.placeholderForUnreadableSupplierContact);
                    this.contact.accountId = this.owner;
                    this._mode = DomainContactPanelMode.COPY;
                });
            }
        );
    };

    public close = (): void => {
        this.modalOpen = false;
        this._mode = DomainContactPanelMode.CLOSED;
    };

    private isViewOnlyContact = (): boolean => {
        if ([undefined, null, ''].indexOf(this.selectedContact) >= 0
            || !(this.selectedContactList instanceof Array)
            || !this.selectedContactList.some((contact) => contact.id === this.selectedContact)
        ) {
            return false;
        }
        const currentContact = this.selectedContactList.filter(
            (contact) => contact.id === this.selectedContact
        )[0];
        if (currentContact.placeholderForUnreadableSupplierContact === undefined) {
            return false;
        }
        return currentContact.placeholderForUnreadableSupplierContact === true;
    };

    private validateContact = (validateSet?: boolean): Promise<void> => {
        if (!this.setValidation) {
            return;
        }

        return void this.$timeout(
            () => {
                if ([undefined, null, ''].indexOf(this.selectedContact) >= 0) {
                    this.validationResults = {
                        missingProperties: [],
                        mustBeOfType: []
                    };
                    return;
                }

                const tlds = this.domains.map((domain) => domain.tld as string);

                let hash: string;
                if (this.contact === undefined) {
                    hash = JSON.stringify([
                        this.selectedContact,
                        this.type,
                        tlds,
                        this.owner
                    ]);
                } else {
                    hash = JSON.stringify(this.contact);
                }

                if (this.lastContactValidation === hash) {
                    return;
                }

                this.lastContactValidation = hash;

                void this.domainContactValidator.contactUsableFor(
                    this.selectedContact,
                    this.type,
                    tlds,
                    this.owner
                )
                .then(
                    (reply: IValidationResults) => {
                        this.validationResults = reply;

                        if (reply.missingProperties.length > 0 || reply.mustBeOfType.length > 0) {
                            this.validContact = false;
                        } else {
                            this.validContact = true;

                        }
                        this.validContact = reply.missingProperties.length > 0 || reply.mustBeOfType.length > 0
                            ? false
                            : true;

                        if (validateSet) {
                            void this.$timeout(() => this.setValidation(), 50);
                        }

                        return this.validContact;
                    }
                );
            }, 50
        );
    };

    private _setSelectedContact = (
        contactId: string,
        skipCheck = false
    ): void => {
        this.reloadDropdown = true;
        void this.$timeout(() => {
            this.selectedContact = (this.validContact || skipCheck) ? contactId : undefined;
            this.reloadDropdown = false;
        });
    };
}

export class MoleculePanelEditRowContactComponent implements ng.IComponentOptions {
    public transclude = true;
    public require = {
        $formEdit: '^moleculeFormEdit'
    };
    public bindings = {
        _readOnly: '<readOnly',
        contactBinding: '<',
        domains: '<',
        handleList: '=',
        modalOpen: '=?',
        numberOfSelectedDomains: '<',
        owner: '<',
        popoverPosition: '@',
        selectedContact: '=',
        setValidation: '<',
        simpleVersion: '<',
        type: '<'
    };
    public controller = MoleculePanelEditRowContactController;
    public template = require('./panel-edit-row-contact.html');
}
