import * as ng from 'angular';

import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { DisableFilter } from '../../../atomic-components/organisms/overview/overview';

export class TemplateDomainJobOverviewController {
    public static $inject: string[] = ['$state', '$translate'];

    public pageHeaderData: PanelHeaderData;
    public bundle;
    public overviewServiceData;
    public expertView: boolean;
    public disableFilter: DisableFilter;
    public overviewModel;

    private simpleFilter;
    private extendedFilters;
    private overviewUiSettings;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.expertView = true; // only expertview on job overviews
        this.disableFilter = {
            fancyFilter: false,
            searchFilter: false
        };

        if (this.bundle === undefined) {
            this.bundle = null;
        }

        const inBundleState = this.$state.current.name.split('.').indexOf('bundle') >= 0;

        this.pageHeaderData = {
            backwardLink: inBundleState ? 'bundle.id.dashboard' : 'domain.dashboard',
            backwardText: inBundleState
                ? this.$translate.instant('TR_090119-a32485_TR')
                : this.$translate.instant('TR_100119-28bdd0_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: 'globe',
            panelTitle: this.$translate.instant('TR_110119-912849_TR')
        };

        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewService: 'domainJob',
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter,
            sort: {
                field: 'jobAddDate',
                order: 'DESC'
            }
        };
    }
}

export class TemplateDomainJobOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<',
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public controller = TemplateDomainJobOverviewController;
    public controllerAs = 'TemplateDomainJobOverviewCtrl';
    public template = require('./domain-job-overview-template.html');
}
