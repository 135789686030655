import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import { AuthContextService, ValidateNumber } from '../../../../../services';
import { OrganismEditFormMachineController } from '../../../../organisms';

export class OrganEditPanelMachineGeneralController {
    public static $inject: string[] = ['$translate', 'authContext'];

    public userHasRightResVmhostList = AuthContextService.isGranted(UiRights.RES_VMHOST_LIST); // used in template!
    public $editFormOrganism: OrganismEditFormMachineController;
    public panelRight;
    public blockedPortsValidationSettings: any[];
    public showObjectId: boolean;

    private initialMachineState: any;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {
        this.blockedPortsValidationSettings = [{
            instructions: null,
            validator: new ValidateNumber(this.$translate, false, true)
        }];
    }

    /* tslint:disable-next-line:no-empty */
    public cancelCallback: () => void = () => {};

    public $onInit = () => {
        this.initialMachineState = ng.copy(this.$editFormOrganism.machine);
    };

    public get isSuperAdmin() {
        return this.authContext.isGranted(UiRights.ADMIN_SYSTEM_GENERAL_SUPPORT);
    }

    public cancel = () => {
        this.$editFormOrganism.machine = ng.copy(this.initialMachineState);
        if ([undefined, null].indexOf(this.cancelCallback) === -1) {
            this.cancelCallback();
        }
    };

    public get allowHostnameChange() {
        switch (this.$editFormOrganism.machine.managementType) {
            case 'none': return true;
            case 'individual': return AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN);
            case 'platform': return AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_SUPER_USER_EDIT);
        }
    }
}

export class OrganEditPanelMachineGeneralComponent implements ng.IComponentOptions {
    public bindings = {
        cancelCallback: '<cancel',
        panelRight: '<',
        showObjectId: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormMachine'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./machine-general.html');
    public controller = OrganEditPanelMachineGeneralController;
}
