import * as ng from 'angular';
import { DocumentHelperService } from '../../../../services';

export class MoleculePanelInvoiceInformationDetailsController implements ng.IController {
    public static $inject: string[] = ['documentHelper'];

    public invoice;

    constructor(
        private documentHelper: DocumentHelperService
    ) {}

    public getInvoiceReminderLevel() {
        const reminders = (this.invoice.reminderLevels.length - 1);
        if (reminders > -1) {
            return this.invoice.reminderLevels[reminders].level;
        }
    }

    public hasOutstandingSum = () => {
        return !this.invoice.paymentReceived && this.documentHelper.outstandingInvoicePriceTotal(this.invoice) > 0;
    };
}

export class MoleculePanelInvoiceInformationDetailsComponent implements ng.IComponentOptions {
    public bindings = {
        invoice: '='
    };
    public controller = MoleculePanelInvoiceInformationDetailsController;
    public template = require('./panel-invoice-information-details.html');
}
