import * as ng from 'angular';

export class MoleculeButtonAbortController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public abort: () => void;
    public disabled: boolean;

}

export class MoleculeButtonAbortComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        abort: '<',
        disabled: '<'
    };
    public controller =  MoleculeButtonAbortController;
    public template = require('./button-abort.html');
}
