import * as ng from 'angular';

export class MoleculeInformationRequiredRecordChangeListController implements ng.IController {
    public static $inject: string[] = [];
}
export class MoleculeInformationRequiredRecordChangeListComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        requiredRecordChanges: '<'
    };
    public controller = MoleculeInformationRequiredRecordChangeListController;
    public template = require('./required-record-change-list.html');
}
