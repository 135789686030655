import * as ng from 'angular';

export class MoleculeButtonCancelController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public cancel: () => void;
    public cancelParam: any;
    public disabled: boolean;
}

export class MoleculeButtonCancelComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        cancel: '<',
        cancelParam: '<',
        disabled: '<'
    };
    public controller =  MoleculeButtonCancelController;
    public template = require('./button-cancel.html');
}
