import * as ng from 'angular';
import { MoleculeFormEditController } from '../../../../molecules/forms/form-edit/form-edit';
import { OrganismEditFormUserController } from '../../../../organisms/forms/edit-forms/user-edit/user-edit';

export class OrganEditPanelUserDataController implements ng.IController {
    public static $inject: string[] = ['$translate'];
    public userName: string;
    public userLanguage: string;
    public panelRightSettings: any;
    public cancelCallbackFunction: () => void;
    public $editForm: MoleculeFormEditController;
    public $formOrganism: OrganismEditFormUserController;
    public languageOptions = [];

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {
        this.languageOptions = [
            {
                name: this.$translate.instant('ACCOUNT.PROFILE.LANGUAGE.OPTIONS.GERMAN'),
                value: 'de'
            }, {
                name: this.$translate.instant('ACCOUNT.PROFILE.LANGUAGE.OPTIONS.ENGLISH'),
                value: 'en'
            }
        ];
    }

    public getLanguageDisplay = () => {
        switch (this.userLanguage) {
            case 'de': {
                return this.$translate.instant('ACCOUNT.PROFILE.LANGUAGE.OPTIONS.GERMAN');
            }
            case 'en': {
                return this.$translate.instant('ACCOUNT.PROFILE.LANGUAGE.OPTIONS.ENGLISH');
            }
        }
    };
}

export class OrganEditPanelUserDataComponent implements ng.IComponentOptions {
    public bindings = {
        cancelCallbackFunction: '<',
        panelRightSettings: '<',
        userLanguage: '=',
        userName: '='
    };

    public require = {
        $editForm: '^moleculeFormEdit',
        $formOrganism: '^organismEditFormUser'
    };

    public template = require('./edit-panel-user-data.html');
    public controller = OrganEditPanelUserDataController;
}
