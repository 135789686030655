import { UIRegExp } from '@/configuration';

import { NavigationService } from './navigation';

export type RedirectOnNotFoundCallback = (error: any) => any;

export class RedirectOnNotFoundProvider {
    public $get = [
        'navigation',
        (navigation: NavigationService) => {
            return (error: any) => {
                if (error?.name === 'NotFoundError') {
                    return navigation.go('404');
                }

                if (typeof error?.message === 'string' && UIRegExp.RequestCancelled.test(error.message)) {
                    // If this happens, this request is no longer relevant, anyway. Just eat the error.
                    return;
                }

                return Promise.reject(error);
            };
        }
    ];
}
