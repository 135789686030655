import * as ng from 'angular';
import { MoleculeFormEditController } from '../../../forms';

export class MoleculePanelEditRowSslHstsController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public hstsMaxAgeOptions = [];
    public activated: string | boolean = true;
    public maxAge = '15552000';
    public includeSubdomains = false;
    public allowPreload = false;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    $onInit() {
        this.hstsMaxAgeOptions = [
            { value: 0, name: '0' },
            { value: 3600, name: '1 ' + this.$translate.instant('GENERAL.HOUR') },
            { value: 86400, name: '1 ' + this.$translate.instant('GENERAL.DAY') },
            { value: 604800, name: '7 ' + this.$translate.instant('GENERAL.DAYS') },
            { value: 1209600, name: '14 ' + this.$translate.instant('GENERAL.DAYS') },
            { value: 2592000, name: '30 ' + this.$translate.instant('GENERAL.DAYS') },
            { value: 7776000, name: '90 ' + this.$translate.instant('GENERAL.DAYS') },
            { value: 15552000, name: '180 ' + this.$translate.instant('GENERAL.DAYS') }
        ];
    }
}

export class MoleculePanelEditRowSslHstsComponent implements ng.IComponentOptions {
    public bindings = {
        activated: '<',
        maxAge: '=',
        includeSubdomains: '=',
        allowPreload: '='
    };
    public controller = MoleculePanelEditRowSslHstsController;
    public controllerAs = '$panelRow';
    public template = require('./panel-edit-row-ssl-hsts.html');
}
