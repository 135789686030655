
import ng from 'angular';
import { AccountApi, ViewTypes } from '@/types';
import { DomainContactValidatorService } from '@/services/domain';
import { EditPanelRight } from '@/atomic-components';
import { DomainApi } from '@/types';

export interface IDomainContactNameList {
    names: string[];
    value: string;
}

export class OrganEditPanelDomainSettingsController implements ng.IController {
    public static $inject: string[] = ['$translate', 'domainContactValidator'];

    public saveCallbackFunction: () => void;
    public domainContactList: IDomainContactNameList[] = [];
    public panelRightSettings: EditPanelRight;
    public subAccountData: AccountApi.Account;
    public contactSetValidationErrors: DomainApi.ContactSetUsableProblem[];
    public contactDropDownHeadings: string[] = [];
    public handleList: ViewTypes.DomainContactObject[] = [];
    public initialHandleList: ViewTypes.DomainContactObject[] = [];

    private _translations = [
        { key: 'contact', translationId: 'TR_090119-c5d503_TR' },
        { key: 'name', translationId: 'TR_140119-796b28_TR' },
        { key: 'address', translationId: 'TR_090119-7fc8c2_TR' },
        { key: 'organization', translationId: 'TR_090119-9044b3_TR' }
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private domainContactValidator: DomainContactValidatorService
    ) {
        this._translations.map((translation) => {
            this.contactDropDownHeadings.push(this.$translate.instant(translation.translationId));
        });
    }

    public $onInit = (): void => {
        this.initialHandleList = {...this.handleList};
    };

    public getContactName(handleId: string): string {
        let contactName = '';
        this.domainContactList.some(
            (contact) => {
                if (contact.value === handleId) {
                    contactName = contact.names[1];
                    return true;
                }

                return false;
            }
        );

        return contactName;
    }

    public get contactsSet(): boolean {
        return this.handleList.every((handle) => ![undefined, null, ''].includes(handle.contactId));
    }

    public cancelCallbackFunction: () => void = () => {/* */};

    public cancel = (): void => {
        this.handleList = {...this.initialHandleList};

        if (this.cancelCallbackFunction) {
            this.cancelCallbackFunction();
        }
    };
}

export class OrganEditPanelDomainSettingsComponent implements ng.IComponentOptions {
    public bindings = {
        cancelCallbackFunction: '<',
        domainContactList: '=',
        handleList: '=',
        panelRightSettings: '<',
        saveCallbackFunction: '<',
        subAccountData: '<'
    };

    public template = require('./domain-settings-panel.html');
    public controller = OrganEditPanelDomainSettingsController;
}
