import ng from 'angular';

export class TemplateSetupOrderIdNotFoundController {
    public static $inject: string[] = []
    constructor(){}
}

export class TemplateSetupOrderIdNotFoundComponent implements ng.IComponentOptions {
    public bindings = {};
    public template = require('./order-id-not-found-panel.html');
    public controller = TemplateSetupOrderIdNotFoundController;
}
