import {
    CleanableStringType,
    DnsZoneModelService,
    DomainCheckerService,
    StringCleaner,
    WebspaceModelService
} from './';

import * as ng from 'angular';

export class IntegrationHelperService {
    public static $inject: string[] = ['domainChecker', 'dnsZoneModel'];

    constructor(
        private domainChecker: DomainCheckerService,
        private dnsZoneModel: DnsZoneModelService
    ) {}

    public domainApiStatus = (domainName) => {
        this.domainChecker.checkOne(domainName).then(
            (result) => result,
            () => false
        );
    };

    public getZone = (domainName) => {
        return this.dnsZoneModel.findOneByName(domainName)
            .then(
                (zone) => {
                    if (zone !== undefined) {
                        return zone;
                    } else {
                        return 'notSet';
                    }
                }
            );
    };

    public getZoneRecords = (zoneId) => {
        const filter = { field: 'zoneConfigId', value: zoneId };
        const sort = {
            field: 'recordName',
            order: 'ASC'
        };

        return this.dnsZoneModel.recordsList(filter, 0, 1, sort)
            .then((records) => records.data);
    };
}

export class WebspaceIntegrationHelperService {
    public static $inject: string[] = [
        'integrationHelper',
        'webspaceModel',
        'domainChecker',
        'dnsZoneModel'
    ];

    private data: any = {};
    private vhost;
    private defaultRecord =  {
        content: '',
        name: '',
        ttl: 86400,
        type: 'A',
        zoneConfigId: ''
    };

    constructor(
        private integrationHelper: IntegrationHelperService,
        private webspaceModel: WebspaceModelService,
        private domainChecker: DomainCheckerService,
        private dnsZoneModel: DnsZoneModelService
    ) {}

    public getARecords = () => {
        this.data.checkRecordNames = [];
        this.data.recordsToAdjust = 0;
        this.data.aRecords = 0;

        if ([undefined, null, ''].indexOf(this.vhost.domainNameUnicode) >= 0) {
            return;
        }

        const domainName = (this.vhost.domainName === '')
            ? this.vhost.domainNameUnicode
            : this.vhost.domainName;

        if (
            this.data.domainStatus.status === 'extensionDoesNotExist'
            && domainName.indexOf('www.') !== 0
        ) {
            this.data.checkRecordNames.push(domainName.replace(this.data.zoneName, ''));
        } else if (this.vhost.enableAlias === true) {
            this.data.checkRecordNames = [domainName.replace('www.', ''), 'www.' + domainName.replace('www.', '')];
        } else if (
            this.data.domainStatus.status === 'extensionDoesNotExist'
            && domainName.indexOf('www.') === 0
        ) {
            this.data.checkRecordNames.push('www.');
        } else {
            this.data.checkRecordNames.push(domainName.replace('www.', ''));
        }

        if (this.data.records.length > 0) {
            this.data.records.filter(
                (record) => {
                    record.show = false;

                    if (record.type === 'A') {
                        if (this.checkRecordName(record.name)) {
                            this.data.aRecords++;

                            if (record.content !== this.data.webserverIP) {
                                record.newContent = this.data.webserverIP;
                                record.adjust = true;
                                this.data.recordsToAdjust++;
                            } else {
                                record.adjust = false;
                            }

                            record.show = true;
                        }
                    }
                }
            );
        }
    };

    public getWebspaceVhost = (vHost) => {
        this.data = this.resetData();
        this.vhost = vHost;
        const domainName = [undefined, null, ''].indexOf(vHost.domainNameUnicode) < 0
            ? vHost.domainNameUnicode
            : vHost.domainName;

        if (domainName === '') {
            this.data.enableAlias = false;

            return Promise.resolve(this.data);
        }

        this.data.zoneName = StringCleaner
            .clean(domainName)
            .as(CleanableStringType.DomainNames);

        return this.getDomainStatus()
            .then(() => {
                if ([undefined, ''].indexOf(vHost.webspaceId) < 0) {
                    this.getWebspaceData(vHost.webspaceId);
                }
            })
            .then(this.getZoneData)
            .then(this.checkARecords)
            .then(
                () => Promise.resolve(this.data)
            );
    };

    public adjustRecords = () => {
        this.data.records.forEach(
            (record) => {
                if (record.newContent !== undefined) {
                    record.content = record.newContent;
                }
            }
        );

        const domainName = (this.vhost.domainName === '')
            ? this.vhost.domainNameUnicode
            : this.vhost.domainName;

        if (this.data.checkRecordNames.length > 0) {
            this.data.checkRecordNames.forEach(
                (name) => {
                    const newRecord = ng.copy(this.defaultRecord);
                    newRecord.content = this.data.webserverIP;
                    newRecord.zoneConfigId = this.data.zone.id;
                    if (name === 'www.') {
                        newRecord.name = name + this.data.zoneName;
                    } else if (this.data.zoneName.indexOf(name) === 0) {
                        newRecord.name = this.data.zoneName;
                    } else {
                        newRecord.name = domainName;
                    }

                    this.data.records.push(newRecord);
                }
            );
        }

        this.dnsZoneModel.updateNative(this.data.zone, this.data.records);
    };

    private resetData = () => {
        return {
            aRecords: 0,
            adjustRecordList: [],
            adjustRecords: false,
            checkRecordNames: [],
            domainStatus: {},
            enableAlias: true,
            records: [],
            recordsToAdjust: 0,
            webserverIP: '',
            zone: {},
            zoneExists: false,
            zoneName: ''
        };
    };

    private getZoneData = () => {
        return this.integrationHelper.getZone(this.data.zoneName)
            .then(
                (zone) => {
                    this.data.zoneExists = !([undefined, 'notSet'].indexOf(zone.status) >= 0);
                    this.data.zone = zone;

                    return this.integrationHelper.getZoneRecords(zone.id);
                }
            )
            .then(
                (records) => {
                    this.data.records = records;
                    this.getARecords();

                    return this.data;
                }
            );
    };

    private getDomainStatus = async () => {
        try {
            const result = await this.domainChecker.checkOne(this.data.zoneName);

            if (result === undefined) {
                this.data = this.resetData();
            } else {
                this.data.domainStatus = result;

                if (this.data.domainStatus.status === 'extensionDoesNotExist') {
                    if (this.data.zoneName.indexOf('www.') === 0) {
                        this.data.zoneName = this.data.zoneName.replace('www.', '');
                        this.data.enableAlias = true;
                    } else {
                        this.data.zoneName = this.data.domainStatus.domainSuffix;
                        this.data.enableAlias = false;
                    }
                }
            }
        } catch ({}) { // eslint-disable-line no-empty-pattern
            return Promise.reject('apiError');
        }
    };

    private checkRecordName = (recordName) => {
        return this.data.checkRecordNames.some(
            (filter) => {
                if (recordName.indexOf(filter) === 0) {
                    const index = this.data.checkRecordNames.indexOf(filter);
                    this.data.checkRecordNames.splice(index, 1);

                    return true;
                }

                return false;
            }
        );
    };

    private getWebspaceData = async (webspaceId) => {
        if (webspaceId === undefined) {
            return;
        }

        const webspace = await this.webspaceModel.findOne(webspaceId);

        this.data.webserverIP = webspace.serverIpv4;
    };

    private checkARecords = () => {
        if (!this.data.zoneExists) {
            const newRecord = ng.copy(this.defaultRecord);
            newRecord.name = this.data.domainStatus.domainName;
            newRecord.content = this.data.webserverIP;
            newRecord.zoneConfigId = this.data.zone.id;

            this.data.recordsToAdjust = 1;
            this.data.adjustRecordList = [newRecord];
        }

        this._checkMissingRecords();
    };

    private _checkMissingRecords = ()  => {
        if (this.data.checkRecordNames?.length === 0) {
            return;
        }

        this.data.checkRecordNames.forEach(
            (missingRecord) => {
                let recordName;

                if (
                    missingRecord === this.data.domainStatus.domainName
                    || this.data.domainStatus.domainName.indexOf(missingRecord) === 0
                ) {
                    recordName = this.data.domainStatus.domainName;
                } else if (missingRecord.indexOf(this.data.domainStatus.domainName) > 0) {
                    const subPartOfSubdomain = missingRecord.replace(this.data.domainStatus.domainName, '');
                    recordName = subPartOfSubdomain + this.data.domainStatus.domainName;
                } else {
                    recordName = missingRecord + this.data.domainStatus.domainName;
                }

                const newRecord = ng.copy(this.defaultRecord);
                newRecord.name = recordName;
                newRecord.content = this.data.webserverIP;
                newRecord.zoneConfigId = this.data.zone.id;
                this.data.recordsToAdjust += 1;
                this.data.adjustRecordList.push(newRecord);
            }
        );
    };
}
