import * as ng from 'angular';

import { AuthContextService } from '../../../../../services';
import { EditPanelStatus } from '../../../../molecules/forms/form-edit/form-edit';

export class OrganEditPanelRightsTemplateGeneralController implements ng.IController {
    public static $inject: string[] = ['authContext', '$translate'];
    public rightsTemplate: any;
    public panelRight: any;
    public createNew: boolean;
    public accounts: any[];
    public users: any[];
    public usableForRadioButtons = [];
    private rightsTemplateBackup: any;

    constructor(
        private authContext: AuthContextService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.usableForRadioButtons = [{
            label: this.$translate.instant('TR_110119-972081_TR'),
            value: 'accounts'
        }, {
            label: this.$translate.instant('TR_220519-49de95_TR'),
            value: 'users'
        }];

        this.rightsTemplateBackup = ng.copy(this.rightsTemplate, this.rightsTemplateBackup);
        if (this.createNew) {
            this.panelRight = {};
        } else {
            this.panelRight = {editPanelButton: true};
        }
        if (this.rightsTemplate.accountId === undefined || this.rightsTemplate.accountId === null) {
            this.rightsTemplate.accountId = this.authContext.account.id;
        }
    }

    public cancel = () => {
        this.rightsTemplate = ng.copy(this.rightsTemplateBackup);
    };

    public get usableFor() {
        if (this.rightsTemplate.usableForSubaccounts) {
            return 'accounts';
        }
        if (this.rightsTemplate.usableForUsers) {
            return 'users';
        }
        return null;
    }

    public set usableFor(value) {
        this.rightsTemplate.usableForSubaccounts = value === 'accounts';
        this.rightsTemplate.usableForUsers = value === 'users';
    }

    public get disabledUseRadioButtons() {
        if (this.createNew) {
            return false;
        }
        if (this.rightsTemplate.usableForSubaccounts) {
            return this.accounts.some(
                (account) => {
                    return account.rightsTemplateId === this.rightsTemplate.id;
                }
            );
        }
        if (this.rightsTemplate.usableForUsers) {
            return this.users.some(
                (user) => {
                    return user.rightsTemplateId === this.rightsTemplate.id;
                }
            );
        }
    }

    public get initialPanelStatus() {
        if (this.createNew) {
            return EditPanelStatus.ACTIVE;
        } else {
            return EditPanelStatus.READONLY;
        }
    }

}

export class OrganEditPanelRightsTemplateGeneralComponent implements ng.IComponentOptions {
    public bindings = {
        accounts: '<',
        createNew: '<',
        rightsTemplate: '=',
        users: '<'
    };
    public template = require('./rights-template-general.html');
    public controller = OrganEditPanelRightsTemplateGeneralController;
}
