import * as ng from 'angular';

import { InputOptionsType } from '@/types/input-types/input-text';
import { FormDescriptionSpec } from '@/types/view-types/form-description/form-description';
import { ValidationErrorList } from '@/atomic-components/molecules/lists/validation-error-list/validation-error-list';

export class FormElementInputNumberController implements ng.IComponentController {
    public static $inject: string[] = [];

    public disabled: boolean;
    public keenInput: FormDescriptionSpec.FieldObjectNumber;
    public keenOutput: (model: number, key: string) => void;
    private _model: string;
    private key: string;
    private label: string;
    private validationErrorList: ValidationErrorList = [];
    private _validationOptions: InputOptionsType = {};

    public $onInit = () => {
        this.disabled = this.disabled || false;
        if (isNaN(this.model)) {
            this.model = 0;
        }
        this.key = this.keenInput.key || '';
        this.label = this.keenInput.ui.label || '';
        if (this.keenInput.validation) {
            this.validationOptions(this.keenInput.validation);
        }
        if ([undefined, null].indexOf(((this.keenInput as FormDescriptionSpec.FieldObject).value as string)) < 0) {
            this._model = ((this.keenInput as FormDescriptionSpec.FieldObject).value as string);
        } else if ([undefined, null].indexOf(((this.keenInput as FormDescriptionSpec.FieldObject).default as string)) < 0) {
            this._model = ((this.keenInput as FormDescriptionSpec.FieldObject).default as string);
        }
    };

    public get model(): number {
        return parseFloat(this._model);
    }

    public set model(value: number) {
        if (isNaN(value)) {
            this._model = '';
        } else if (value !== parseFloat(this._model)) {
            this._model = String(value);
        }
        if (this.keenOutput) {
            this.keenOutput(parseFloat(this._model), this.key);
        }
    }

    public validationOptions(value: FormDescriptionSpec.FieldObjectNumberValidation) {
        this._validationOptions = {
            minimum: value.minValue || undefined,
            maximum: value.maxValue || undefined,
            minPrecision: value.minPrecision || undefined,
            maxPrecision: value.maxPrecision || undefined
        };
    }

    private onChangeModel = () => {
        if (this.keenOutput) {
            this.keenOutput(parseFloat(this._model), this.key);
        }
    };
}

export class FormElementInputNumberComponent implements ng.IComponentOptions {
    public bindings = {
        keenInput: '<',
        keenOutput: '<?',
        model: '=?',
        disabled: '<?'
    };
    public controller = FormElementInputNumberController;
    public template = require('./form-element-input-number.html');
}
