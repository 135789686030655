import * as ng from 'angular';
import * as Types from '../../../../../types';

export class OrganCreateDomainRegistrationController implements ng.IController {
    public static $inject: string[] = [];

    public metadata: Types.ViewTypes.ProductConfigDomainDomainObject;
    public domainSearchData: Types.ViewTypes.WizardDomainSearchDataObject;

    $onInit() {
        if (this.metadata.selectedDomains === undefined) {
            this.metadata.selectedDomains = [];
        }
    }

    public set completed({}) {} // tslint:disable-line:no-empty
    public get completed() {
        return this.metadata.selectedDomains.length > 0;
    }

    public set showAvailableDomainsList({}) {} // tslint:disable-line:no-empty
    public get showAvailableDomainsList() {
        return  this.domainSearchData.domainInfo.length > 0;
    }
}

export class OrganCreateDomainRegistrationComponent implements ng.IComponentOptions {
    public bindings = {
        completed: '=',
        domainSearchData: '=',
        metadata: '='
    };
    public controller =  OrganCreateDomainRegistrationController;
    public template = require('./domain-registration-config.html');
}
