import { AccountFilterField, ContactFilterField, GroupedSelectFilterField, SelectFilterField, TextFilterField } from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { JobOverviewModel, OverviewModel } from '../abstract';
import { RequestCancellerService } from '../request-canceller';

export class SslJobOverviewModel extends JobOverviewModel {

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('jobDisplayName', this.$translate.instant('TR_140119-796b28_TR')),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR')),
                new SelectFilterField('jobAction', this.$translate.instant('TR_140119-3b64a8_TR'), [
                    {
                        name: this.$translate.instant('SSL.JOB-TYPE.AUTHORIZE-NAME'),
                        value: 'authorizeName'
                    },
                    {
                        name: this.$translate.instant('TR_140119-a1847d_TR'),
                        value: 'update'
                    },
                    {
                        name: this.$translate.instant('SSL.JOB-TYPE.ORDER'),
                        value: 'order'
                    },
                    {
                        name: this.$translate.instant('SSL.JOB-TYPE.ORDER'),
                        value: 'delete'
                    },
                    {
                        name: this.$translate.instant('GENERAL.JOB.ACTION.DELETE-PERMANENTLY'),
                        value: 'purgeRestorable'
                    },
                    {
                        name: this.$translate.instant('TR_140119-d46abf_TR'),
                        value: 'deleteScheduled'
                    },
                    {
                        name: this.$translate.instant('GENERAL.JOB.ACTION.DELETION-CANCEL'),
                        value: 'deletionCancel'
                    },
                    {
                        name: this.$translate.instant('SSL.JOB-TYPE.REISSUE'),
                        value: 'reissue'
                    },
                    {
                        name: this.$translate.instant('SSL.JOB-TYPE.RENEW'),
                        value: 'renew'
                    },
                    {
                        name: this.$translate.instant('TR_140119-d1b7a4_TR'),
                        value: 'restore'
                    },
                    {
                        name:this.$translate.instant('SSL.JOB-TYPE.REVOKE'),
                        value: 'revoke'
                    }
                ]),
                new SelectFilterField('jobStatus', this.$translate.instant('TR_140119-8e4b60_TR'), [
                    {
                        name: this.$translate.instant('GENERAL.JOB.STATUS.TIMED'),
                        value: 'timed'
                    },
                    {
                        name: this.$translate.instant('GENERAL.JOB.STATUS.NEW'),
                        value: 'new'
                    },
                    {
                        name: this.$translate.instant('GENERAL.JOB.STATUS.IN-PROGRESS'),
                        value: 'inProgress'
                    },
                    {
                        name: this.$translate.instant('TR_291220-55e674_TR'),
                        value: 'restart'
                    },
                    {
                        name: this.$translate.instant('GENERAL.JOB.STATUS.SUCCESS'),
                        value: 'successful'
                    },
                    {
                        name: this.$translate.instant('GENERAL.JOB.STATUS.FAILED'),
                        value: 'failed'
                    },
                    {
                        name: this.$translate.instant('GENERAL.JOB.STATUS.SUPPORT'),
                        value: 'support'
                    },
                    {
                        name: this.$translate.instant('GENERAL.JOB.STATUS.CANCELED'),
                        value: 'canceled'
                    },
                    {
                        name: this.$translate.instant('GENERAL.JOB.STATUS.DONE'),
                        value: 'done'
                    }
                ])
            ];
        }

        return  this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', 'sslJobModel']
    );

    readonly service = 'ssl.job';

    protected listCallbacks = [{callback: this.sslJobModel.list}];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private sslJobModel: any
    ) {
        super(RequestCanceller);
    }
}
