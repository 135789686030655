import * as ng from 'angular';

import './drop-down-breadcrumb.scss';

export class MoleculeFormDropDownBreadcrumbController implements ng.IController {
    public static $inject: string[] = [];

    public breadcrumb;
    public showOptions: boolean;
    public $moleculeBreadcrumb;
    public modifier: string;

    public $onInit() {
        this.showOptions = false;
        this.breadcrumb.value = this.breadcrumb.value || null;
        this.modifier = this.modifier || 'medium';
    }

    public toggleOptions() {
        this.showOptions = !this.showOptions;
    }

    public inputClick() {
        if (!this.showOptions) {
            this.showOptions = true;
        }
    }

    public outerElementClick() {
        if (this.showOptions) {
            this.showOptions = false;
        }
    }

    public onKeyup = (event: KeyboardEvent) => {
        if (event.keyCode === 13 || event.keyCode === 27) { // Enter or ESC
            this.toggleOptions();
        }
    };
}

export class MoleculeFormDropDownBreadcrumbComponent implements ng.IComponentOptions {
    public require = {
        $moleculeBreadcrumb: '^moleculeBreadcrumb'
    };
    public bindings = {
        breadcrumb: '<',
        modifier: '@?'
    };
    public controller =  MoleculeFormDropDownBreadcrumbController;
// tslint:disable-next-line: max-line-length
    public template = require('./drop-down-breadcrumb.html');
}
