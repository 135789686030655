import {
    DomainRobotService,
    FunctionRelayService,
    ModelHelper
} from '../';

import { RequestStatus } from '../rpc-client';

import * as Types from '../../types';
import { DomainApi } from '../../types';

export class DomainModelService {
    public static $inject: string[] = [
        'domainRobot',
        'functionRelay'
    ];

    constructor(
        private domainRobot: DomainRobotService,
        private functionRelay: FunctionRelayService
    ) {}

    public list = (limit?, page?, filters?, sort?, cancel?, owner?) => {
        sort = sort || {
            field: 'domainName',
            order: 'ASC'
        };
        page = page || 1;
        return this.domainRobot.listDomains(filters, limit, page, sort, cancel, owner)
        .then(value => ModelHelper.returnListResponse<DomainApi.Domain>(value));
    };

    public listWithoutPagination = (limit?, page?, filters?, sort?, cancel?, owner?) => {
        sort = sort || {
            field: 'domainName',
            order: 'ASC'
        };
        page = page || 1;
        return this.domainRobot.listDomainsWithoutPagination(filters, limit, page, sort, cancel, owner)
        .then(value => ModelHelper.returnListResponse<DomainApi.Domain>(value));
    };

    public findOne = (id) => {
        return this.domainRobot.listDomains({field: 'domainId', value: id}, 10, 1)
        .then(ModelHelper.returnFindOneResponse) as Promise<Types.DomainApi.Domain>;
    };

    public findById = (ids) => {
        const filter: Types.Finding.Filter = { subFilterConnective: 'OR', subFilter: ids.map(this.idFilter) };

        return this.domainRobot.listDomains(filter, 0, 1)
        .then(this.returnResponseDate);
    };

    public findByName = (names: string[]) => {
        const filter: Types.Finding.Filter = { subFilterConnective: 'OR', subFilter: names.map(this.nameFilter) };

        return this.domainRobot.listDomains(filter, 0, 1)
        .then(this.returnResponseDate);
    };

    public delete = (domain, execDate) => {
        if (execDate !== undefined && execDate !== null) {
            execDate.setUTCHours(0, 0, 0, 0);
        }

        return this.domainRobot.deleteDomain(domain.name, execDate);
    };

    public domainDetachFromBundle = (domainName: string) => {
        return this.domainRobot.domainDetachFromBundle(domainName);
    };

    public cancelDelete: (domains: Types.DomainApi.Domain[]) => Promise<any>
    = (domains) => {
        const promises = domains.map(this.cancelDeleteOnce);

        return Promise.all(promises);
    };

    public withdraw = (domain, disconnect, execDate?) => {
        if (execDate !== undefined && execDate !== null) {
            execDate.setUTCHours(0, 0, 0, 0);
        }
        return this.domainRobot.withdrawDomain(domain.name, disconnect, execDate);
    };

    public register = (domain, owner) => {
        return this.domainRobot.createDomain(domain, owner)
        .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
    };

    public transfer = (domain, transferData) => {
        return this.domainRobot.transferDomain(domain, transferData)
        .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
    };

    public restore = (domainName) => {
        return this.domainRobot.restoreDomain(domainName)
        .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
    };

    public restoreByDomainObject = (domain) => {
        return this.domainRobot.restoreDomain(domain.name)
        .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
    };

    public update = (domain, designateAgent?, dnsSecKeysToAdd?, dnsSecKeysToRemove?) => {
        const actingAs = ([undefined, null, false, ''].indexOf(designateAgent) >= 0) ? undefined : 'designatedAgent';

        return this.domainRobot.updateDomain(domain, actingAs, dnsSecKeysToAdd, dnsSecKeysToRemove)
        .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
    };

    public createAuthInfo2 = (domains, owner) => {
        const promises = domains.map((domain) => {
            this.createAuthInfo2Once(domain, owner);
        });

        return Promise.all(promises).then(
            this.refreshDeposit(),
            this.refreshDeposit(RequestStatus.FAILED)
        );
    };

    public dnsSecKeysList = (domainName: string) => {
        return this.domainRobot.dnsSecKeysList(domainName)
        .then(this.returnResponses)
        .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
    };

    public dnsSecUpdate = (
        domainName: string,
        add: Types.DomainApi.DnsSecData[],
        remove: Types.DomainApi.DnsSecData[]
    ) => {
        return this.domainRobot.dnsSecKeyUpdate(domainName, add, remove)
        .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
    };

    public changeTag = (domains, tag) => {
        const promises = domains.map(
            (domain) => {
                return this.domainRobot.domainChangeTag(domain.name, tag);
            }
        );

        return Promise.all(promises).then(
            this.refreshDeposit(),
            this.refreshDeposit(RequestStatus.FAILED)
        );
    };

    public domainUpdateIsIcannOwnerChange = (domain) => {
        return this.domainRobot.domainUpdateIsIcannOwnerChange(domain).then(this.returnResponse);
    };

    public domainAttachToBundle = (bundleId: string, domainName: string) => {
        return this.domainRobot.domainAttachToBundle(bundleId, domainName);
    };

    public removeFromBundle = (domainName) => {
        return this.domainRobot.domainChangeBundle(domainName, 'standalone', null);
    };

    public domainMoveQuery = (domains: string[], destinationAccountId: string) => {
        return this.domainRobot.domainMoveQuery(domains, destinationAccountId);
    };

    public domainMove = (domains: string[], destinationAccountId: string, contactOperations: any[]) => {
        return this.domainRobot.domainMove(domains, destinationAccountId, contactOperations);
    };

    private cancelDeleteOnce = (domain) => {
        return this.domainRobot.cancelDelete(domain.name);
    };

    private createAuthInfo2Once = (domainName, owner) => {
        return this.domainRobot.domainCreateAuthInfo2(domainName, owner);
    };

    private refreshDeposit = (status: RequestStatus = RequestStatus.SUCCESSFUL) => (result) => {
        this.functionRelay.call('updateDeposit', {});

        if (status === RequestStatus.SUCCESSFUL) {
            return result;
        } else {
            return Promise.reject(result);
        }
    };

    private idFilter: (id: string) => Types.Finding.Filter = (id) => {
        return {field: 'domainId', value: id};
    };

    private nameFilter: (name: string) => Types.Finding.Filter = (name) => {
        return {field: 'domainNameUnicode', value: name};
    };

    private returnResponse = (result) => {
        return result.response;
    };

    private returnResponses = (result) => {
        return result.responses;
    };

    private returnResponseDate = (result) => {
        return result.response.data;
    };
}
