import ng from 'angular';

import { DnsHelperService } from '@/services';
import { DnsApi, ViewTypes } from '@/types';
import { MoleculeFormEditController, ValidationErrorList } from '@/atomic-components/molecules';

import './panel-edit-row-dns-record.scss';

export class MoleculePanelEditRowDnsRecordController implements ng.IController {
    public static $inject: string[] = ['dnsHelper', '$timeout'];

    public $editForm: MoleculeFormEditController;
    public record: ViewTypes.ProductDnsRecordObject;
    public recordEditedCopy: DnsApi.Record;
    public records: DnsApi.Record[];
    public originalRecord: ViewTypes.ProductDnsRecordObject;
    public placeholders: boolean;
    public editMode: boolean; // Panel editable
    public recordsHaveBeenSplit = false;
    public recordsInEditMode = 0;
    public recordsMarkedForDeletion: string[];
    public changedRecords: string[];
    public addedRecords: string[];
    public recordTypeDropdownItems: {name: string; value: string}[];
    public nameValidationErrors: ValidationErrorList[];
    public contentValidationErrors: ValidationErrorList[];
    public ttlValidationErrors: ValidationErrorList[];
    public priorityValidationErrors: ValidationErrorList[];
    public templateCreator: boolean;
    public addOnDomainPlaceholder = '';
    public zoneName: string;
    public zoneNameUnicode: string;
    public wasSaved = false;
    public isDnsTemplateNew = false;

    private _isEditable = false;

    constructor(
        private dnsHelper: DnsHelperService,
        private $timeout: ng.ITimeoutService
    ) {
    }

    public $onInit(): void {
        this.zoneNameUnicode = this.zoneNameUnicode === undefined ? this.zoneName : this.zoneNameUnicode;
        this._splitRecordNames();
        this.originalRecord = ng.copy(this.record);

        this.placeholders = this.placeholders !== false;
        if (this.wasAdded) {
            this.isEditable = true;
        }

        if (this.templateCreator) {
            this.addOnDomainPlaceholder = '##DOMAIN##';
        } else if (this.zoneName !== undefined && this.zoneName.length > 0) {
            this.addOnDomainPlaceholder = this.zoneNameUnicode;
        }

        if ([undefined, null].indexOf(this.record.id) < 0 && this.record.id.substring(0, 8) === 'template') {
            this.addedRecords.push(this.record.id);
        }
    }

    /**
     * @returns Content editable
     */
    public get isEditable(): boolean {
        return this._isEditable;
    }

    public set isEditable(value: boolean) {
        if (value && !this._isEditable) {
            this.recordsInEditMode++;
        } else if (!value && this._isEditable) {
            this.recordsInEditMode--;
        }

        this._isEditable = value;
    }

    public get showEditable(): boolean {
        if (this._formFieldHasError) {
            this.isEditable = true;
        }
        return (this.editMode && this.isEditable);
    }

    public get e2eElementId(): string {
        if (this.record.id && this.record.id.startsWith('newRecord_')) {
            return this.record.id;
        }

        const elementIdPrefix = this.record.name === '*' ? 'star' : this.record.name;
        return `${elementIdPrefix}_${this.addOnDomainPlaceholder}`;
    }

    public setEditable = (): void => {
        this.isEditable = true;
        this._setEditMarker();
    };

    public toggleDelete = (): void => {
        if (!this.wasSaved && this.wasAdded) {
            this.contentValidationErrors = [];
            this.ttlValidationErrors = [];
            this.priorityValidationErrors = [];
            this.nameValidationErrors = [];
            this.isEditable = false;
            void this.$timeout(
                () => {
                    this.addedRecords.splice(this.addedRecords.indexOf(this.record.id), 1);
                    this.records.splice(this.records.indexOf(this.record), 1);
                }
            );
            return;
        }
        if (this.markedForDeletion) {
            this.recordsMarkedForDeletion.splice(this.recordsMarkedForDeletion.indexOf(this.record.id), 1);
        } else {
            this.recordsMarkedForDeletion.push(this.record.id);
        }
    };

    public cancel = (): void => {
        if (this.wasSaved) {
            this.record = ng.copy(this.recordEditedCopy);
        } else {
            this.record = ng.copy(this.originalRecord);
        }
        this.changedRecords.splice(this.changedRecords.indexOf(this.record.id), 1);
        this.contentValidationErrors = [];
        this.ttlValidationErrors = [];
        this.priorityValidationErrors = [];
        this.nameValidationErrors = [];
        void this.$timeout(
            () => {
                this.$editForm.validateAll();
                this.isEditable = false;
                this._setEditMarker();
            }
        );
    };

    public closeEditPanel = (): void => {
        if (this.wasSaved) {
            this.record = ng.copy(this.recordEditedCopy);
        } else {
            this.record = ng.copy(this.originalRecord);
        }
        this.isEditable = false;
        this._setEditMarker();
    };

    public save = (): void => {
        this.wasSaved = true;
        this.recordEditedCopy = ng.copy(this.record);
        const originalRecord = ng.copy(this.originalRecord);
        originalRecord.isBeingEdited = true;
        // nullify priority if not mr or srv record
        // (eg.:user might have switched back and forth from A <--> MX)
        if (['mx', 'srv'].indexOf(this.record.type.toLowerCase()) < 0) {
            this.record.priority = null;
        }
        // make sure types of ttl and priority fields are ot the same type
        if (typeof this.record.ttl === 'string') {
            originalRecord.ttl = '' + originalRecord.ttl;
        }
        if (typeof this.record.priority === 'string') {
            originalRecord.priority = '' + originalRecord.priority;
        }
        if (ng.toJson(this.record) !== ng.toJson(originalRecord)) {
            this.records.forEach((record) => {
                if (record.id  === this.record.id) {
                    record = ng.copy(this.record);
                }
            });
            this.changedRecords.push(this.record.id);
        } else {
            // field might have ben changed back and forth so if it is now the same remove it from changedRecords array
            this.changedRecords.splice(this.changedRecords.indexOf(this.record.id), 1);
        }
        const lastIndex = (this.record.name.length - 1);
        if (this.record.name.lastIndexOf('.') === lastIndex) {
            // remove point at end of domain name
            this.record.name = this.record.name.substring(0, lastIndex);
        }
        this.isEditable = false;
        this._setEditMarker();
    };

    public get markedForDeletion(): boolean {
        return this.record !== undefined && this.recordsMarkedForDeletion.indexOf(this.record.id) >= 0;
    }

    public get wasEdited(): boolean {
        return this.record !== undefined && this.changedRecords.indexOf(this.record.id) >= 0;
    }

    public get wasAdded(): boolean {
        return this.record !== undefined && this.addedRecords.indexOf(this.record.id) >= 0;
    }

    public get contentValidationType(): boolean {
        return this.record !== undefined
            && this.dnsHelper.getRecordValidationType(this.record.type) !== 'notEmpty';
    }

    public get disableCloseButton(): boolean {
        return this._formFieldHasError;
    }

    public revalidate = (): void => {
        void this.$timeout(
            () => {
                this.$editForm.validateAll();
            }
        );
    };

    private _splitRecordNames = (): void => {
        if (this.recordsHaveBeenSplit) {
            return;
        }

        this.recordsHaveBeenSplit = true;

        this.records = this.records.map((record) => {
            const regEx = new RegExp(this.zoneName + '$', 'g');
            record.name = record.name.replace(regEx, '');
            const lastIndex = (record.name.length - 1);
            if (record.name.lastIndexOf('.') === lastIndex) {
                // remove point at end of domain name
                record.name = record.name.substring(0, lastIndex);
            }
            return record;
        });
    };

    private _setEditMarker = (): void => {
        void this.$timeout(() => {
            this.record.isBeingEdited = this.showEditable ? true : undefined;
        });
    };

    private get _formFieldHasError(): boolean {
        const ttlPrioNameCheck = (this.ttlValidationErrors !== undefined && this.ttlValidationErrors.length > 0)
            || (this.priorityValidationErrors !== undefined && this.priorityValidationErrors.length > 0)
            || (this.nameValidationErrors !== undefined && this.nameValidationErrors.length > 0);

        if (['nullmx'].indexOf(this.record.type.toLowerCase()) >= 0) {
            return ttlPrioNameCheck;
        }

        return (this.contentValidationErrors !== undefined  && this.contentValidationErrors.length > 0)
            || ttlPrioNameCheck;
    }
}

export class MoleculePanelEditRowDnsRecordComponent implements ng.IComponentOptions {
    public bindings = {
        addedRecords: '<',
        changedRecords: '=',
        editMode: '<',
        isDnsTemplateNew: '<?',
        placeholders: '<',
        record: '=',
        records: '=',
        recordsHaveBeenSplit: '=?',
        recordsInEditMode: '=',
        recordsMarkedForDeletion: '=',
        recordTypeDropdownItems: '<',
        templateCreator: '<',
        zoneName: '<',
        zoneNameUnicode: '<?'
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public controller = MoleculePanelEditRowDnsRecordController;
    public template = require('./panel-edit-row-dns-record.html');
}
