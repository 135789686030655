import * as ng from 'angular';
import { AlertManagerService, DomainJobModelService } from '../../../../../../services';
import { OrganismOverviewController } from '../../../../../organisms/overview/overview';

import './multi-domain-transfer-action.scss';

export class MoleculePanelOverviewHeaderMultiDomainTransferActionController implements ng.IController {
    public static $inject: string[] = ['alertManager', 'domainJobModel', '$translate'];

    public OrganismOverview: OrganismOverviewController;
    public mode: string;
    public confirmed = false;

    constructor(
        private alertManager: AlertManagerService,
        private domainJobModel: DomainJobModelService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public abort = () => {
        this.OrganismOverview.overviewModel.activeMassAction = false;
    };

    public confirm = () => {
        const domainNames = this.OrganismOverview.selectedItems.map(
            (domain) => {
                return domain.domainNameUnicode;
            }
        );
        switch (this.mode) {
            case 'ack':
                this.ack(domainNames);
                break;
            case 'nack':
                this.nack(domainNames);
                break;
        }
    };

    public ack = (domainNames: string[]) => {
        this.domainJobModel.setTransferAck(domainNames)
        .then(
            (result) => {
                if (result) {
                    this.alertManager.success(this.$translate.instant('TR_100119-31af40_TR'));
                    this.abort();
                    this.OrganismOverview.onLoad();
                }
            },
            (e) => {
                console.error('error', e);
            }
        );
    };

    public nack = (domainNames: string[]) => {
        this.domainJobModel.setTransferNack(domainNames, '')
        .then(
            (result) => {
                if (result) {
                    this.alertManager.success(this.$translate.instant('TR_100119-70da47_TR'));
                    this.abort();
                    this.OrganismOverview.onLoad();
                }
            },
            (e) => {
                console.error('error', e);
            }
        );
    };
}

export class MoleculePanelOverviewHeaderMultiDomainTransferActionComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        mode: '@'
    };
    public controller =  MoleculePanelOverviewHeaderMultiDomainTransferActionController;
    public template = require('./multi-domain-transfer-action.html');
}
