import {
    AccountFilterField,
    SelectFilterField,
    TextFilterField
} from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { JobOverviewModel, OverviewModel } from '../abstract';
import { RequestCancellerService } from '../request-canceller';

export class EmailJobOverviewModel extends JobOverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', 'emailJobModel', 'objectTypeFilter']
    );

    public readonly service = 'email';

    protected listCallbacks = [{callback: this.emailJobModel.list}];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private emailJobModel: any,
        private objectTypeFilter
    ) {
        super(RequestCanceller);
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('jobMailboxEmailAddressUnicode', this.$translate.instant('TR_140119-796b28_TR')),
                new SelectFilterField('jobObjectType', this.$translate.instant('TR_070519-16189c_TR'), [
                    {
                        name: this.objectTypeFilter('ExchangeMailbox'),
                        value: 'ExchangeMailbox'
                    },
                    {
                        name: this.objectTypeFilter('ImapMailbox'),
                        value: 'ImapMailbox'
                    },
                    {
                        name: this.objectTypeFilter('Forwarder'),
                        value: 'Forwarder'
                    },
                    {
                        name: this.objectTypeFilter('Organization'),
                        value: 'Organization'
                    }
                ]),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR')),
                new SelectFilterField('jobAction', this.$translate.instant('TR_140119-3b64a8_TR'), [
                    {
                        name: this.$translate.instant('TR_140119-a1847d_TR'),
                        value: 'update'
                    }, {
                        name: this.$translate.instant('TR_140119-50d91c_TR'),
                        value: 'create'
                    }, {
                        name: this.$translate.instant('GENERAL.JOB.ACTION.DELETE'),
                        value: 'delete'
                    }, {
                        name: this.$translate.instant('GENERAL.JOB.ACTION.DELETE-PERMANENTLY'),
                        value: 'purgeRestorable'
                    }, {
                        name: this.$translate.instant('TR_140119-d46abf_TR'),
                        value: 'deleteScheduled'
                    }, {
                        name: this.$translate.instant('GENERAL.JOB.ACTION.DELETION-CANCEL'),
                        value: 'deletionCancel'
                    }, {
                        name: this.$translate.instant('TR_140119-d1b7a4_TR'),
                        value: 'restore'
                    }
                ]),
                new SelectFilterField('jobStatus', this.$translate.instant('TR_140119-8e4b60_TR'), [
                    {
                        name: this.$translate.instant('GENERAL.JOB.STATUS.SCHEDULED'),
                        value: 'timed'
                    }, {
                        name: this.$translate.instant('GENERAL.JOB.STATUS.NEW'),
                        value: 'new'
                    }, {
                        name: this.$translate.instant('GENERAL.JOB.STATUS.IN-PROGRESS'),
                        value: 'inProgress'
                    }, {
                        name: this.$translate.instant('GENERAL.JOB.STATUS.SUCCESS'),
                        value: 'successful'
                    }, {
                        name: this.$translate.instant('GENERAL.JOB.STATUS.FAILED'),
                        value: 'failed'
                    }, {
                        name: this.$translate.instant('GENERAL.JOB.STATUS.SUPPORT'),
                        value: 'support'
                    }, {
                        name: this.$translate.instant('GENERAL.JOB.STATUS.CANCELED'),
                        value: 'canceled'
                    }, {
                        name: this.$translate.instant('GENERAL.JOB.STATUS.DONE'),
                        value: 'done'
                    }
                ])
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }
}
