import { DdnsModelService, WizardNewHelperService } from '@/services';
import * as ng from 'angular';
import * as Types from '../../../../../types';
import { AccountApi, ViewTypes } from '../../../../../types';

export class OrganismDnsWizardProductConfigDdnsHostController {
    public static $inject: string[] = [
        '$timeout',
        'ddnsModel',
        'wizardNewHelper'
    ];
    public metadata: ViewTypes.ProductConfigDdnsObject;
    public service = 'dns';
    public formCompleted = {
        // tslint:disable:no-empty
        funds: () => this.sufficientCreditAvailable === true,
        fqdn: () => [null, undefined].indexOf(this.metadata.fqdn) < 0,
        user: () => this.metadata?.existingUserId?.length > 0
            || (this.metadata?.password?.length > 0
                && this.metadata?.newUser?.name?.length > 0
                && this.metadata?.newUser?.userName?.length > 0
            )
        // tslint:enable:no-empty
    };
    public productCodeList: string[] = [];
    public availableDdnsUsers: any[];
    public availableDdnsUsersDropdownItems: any[];
    public zoneId: string;
    public validationErrorListGeneral: any[] = [];
    public hostHasBeenSelected = false;
    public sufficientCreditAvailable = true;
    public productCode;

    private _account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    private _lastCheckedAccountId: string;
    private _getUserListRequestInProgress = false;

    constructor(
        private $timeout: ng.ITimeoutService,
        private ddnsModel: DdnsModelService,
        private wizardNewHelper: WizardNewHelperService
    ) {}

    public $onInit = () => {
        this._setMetadata();
        this._updateAvailableDdnsUsersDropdownItems(this.availableDdnsUsers);
        this.productCodeList = [this.productCode];
    };

    public get ddnsUserList() {
        if (this.metadata.account.id !== this._lastCheckedAccountId && !this._getUserListRequestInProgress) {
            this._getUserListRequestInProgress = true;
            this.ddnsModel.credentialsListForAccount(this.metadata.account.id).then(
                (apiResponse) => {
                    this._updateAvailableDdnsUsersDropdownItems(apiResponse);
                    this._lastCheckedAccountId = ng.copy(this.metadata.account.id);
                    this._getUserListRequestInProgress = false;
                }
            );
        }
        return this.availableDdnsUsersDropdownItems;
    }

    public set ddnsUserList(userlist) { // to make angular happy
        return;
    }

    public get account(): AccountApi.Account | AccountApi.Subaccount {
        return this._account;
    }

    public set account(value: AccountApi.Account | AccountApi.Subaccount) {
        if (value && this.metadata && this.metadata.account) {
            this.metadata.account = value;
            this.metadata.newUser.accountId = value.id;
        }

        this._account = value;
    }

    public set configCompleted(value: boolean) {} // tslint:disable-line:no-empty
    public get configCompleted() {
        let completed = true;

        for (const part in this.formCompleted) {
            if (!this.formCompleted[part]()) {
                completed = false;
            }
        }

        return completed;
    }

    public callbackOnHostSelected = (model: string | undefined) => {
        this.hostHasBeenSelected = [undefined].indexOf(model) < 0;
    };

    private _setMetadata = () => {
        this.metadata = {
            account: this._account,
            fqdn: undefined,
            comment: undefined,
            productCode: undefined,
            productFamily: undefined,
            existingUserId: undefined,
            newUser: {
                accountId: undefined,
                comments: undefined,
                name: undefined,
                userName: undefined
            },
            password: undefined
        };
    };

    private _updateAvailableDdnsUsersDropdownItems = (availableDdnsUsers: any[]) => {
        if (availableDdnsUsers === undefined) {
            this.availableDdnsUsersDropdownItems = [];
            return;
        }

        this.availableDdnsUsersDropdownItems = availableDdnsUsers.map(
            (user) => {
                return {
                    names: [ user.name, user.comments ],
                    value: user.id
                };
            }
        );
    };
}

export class OrganismDnsWizardProductConfigDdnsHostComponent implements ng.IComponentOptions {
    public bindings = {
        account: '<account',
        configCompleted: '=configCompleted',
        metadata: '=',
        productCode: '<'
    };
    public template = require('./dns-wizard-product-config-ddns-host.html');
    public controller = OrganismDnsWizardProductConfigDdnsHostController;
}
