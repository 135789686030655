import * as ng from 'angular';
import * as q from 'q';
import {
    AuthContextService,
    BillingHelperService,
    DomainCheckerService,
    DomainInfoHelperService,
    NameserverSetModelService,
    ProductHelperService,
    WizardNewHelperService
} from '../';
import { BillingCycleToContractPeriodeConst, BundleProductFamilies, UiLanguagesConst } from '../../configuration';
import * as Types from '../../types';
import { DomainApi, ProductApi } from '../../types';
import { ViewTypes } from '../../types/view-types';

export class BundleComposerService {
    public static $inject: string[] = [
        '$translate',
        'billingHelper',
        'domainChecker',
        'domainInfoHelper',
        'nameserverSetModel',
        'productHelper',
        'wizardNewHelper'
    ];

    private _domainStatus: DomainApi.DomainStatusResult;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private billingHelper: BillingHelperService,
        private domainChecker: DomainCheckerService,
        private domainInfoHelper: DomainInfoHelperService,
        private nameserverSetModel: NameserverSetModelService,
        private productHelper: ProductHelperService,
        private wizardNewHelper: WizardNewHelperService
    ) {}

    public buildBundleApiObject = (metadata: ViewTypes.ProductConfigBundleObject) => {
        let composerObject: Types.ViewTypes.WizardComposerObject;
        const promises = [];
        let bundleContingents;
        let domainInfo;
        let domainOrderProductCode;
        let defaultNameserverSet;
        let bundleProduct;
        let contractPeriod;
        let bundleFactSheets = [];

        // HERE BE DRAGONS !!!
        // don't change the order of these elements! If you do also adjust the mapping below in the q.all block!
        promises.push(this.billingHelper.getPriceByProductCode(metadata.productCode)); // bundle price
        promises.push(this._getDomainProductInfoPromise(metadata)); // domain product info
        promises.push(this.productHelper.getProductFromProductCode(metadata.productCode)); // bundle product
        promises.push(this.wizardNewHelper.factSheetConditioner(metadata.productCode)); // fact sheet items
        promises.push(this.wizardNewHelper.getBundleContingents(metadata.productCode, metadata.account.id));
        if (metadata.productFamily === BundleProductFamilies.email) {
            promises.push(this.nameserverSetModel.getDefault(metadata.account.id)); // default nameservers
        }

        return q.all(promises).then((promiseResolves) => {
            promiseResolves.map(
                (resolve: any, index: number) => {
                    switch (index) {
                        case 0: // bundle price -> billing cycle
                            contractPeriod = resolve.contractPeriod;
                            break;

                        case 1: // domain product info
                            domainInfo = resolve;
                            this._domainStatus = domainInfo.domainStatus;
                            domainOrderProductCode = domainInfo.productCode;
                            break;

                        case 2: // bundle product
                            bundleProduct = resolve;
                            break;

                        case 3: // fact sheet items
                            bundleFactSheets = [{ // Laufzeit
                                description: '',
                                label: this.$translate.instant('TR_110419-f5d08d_TR'),
                                type: 'string',
                                value: this.$translate.instant(
                                    this.productHelper.getReadableBillingCycleMonthsFromWording(contractPeriod)
                                )
                            }].concat(resolve);
                            break;

                        case 4: // bundle contingents
                            bundleContingents = resolve;
                            break;

                        case 5: // default nameservers (email bundle only)
                            defaultNameserverSet = resolve.nameservers.map(
                                (nameserver) => {
                                    return {name: nameserver, ips: [], ipsRequired: false};
                                }
                            );
                            break;
                    }
            });

            if (metadata.productFamily === BundleProductFamilies.email) {
                composerObject = this.wizardNewHelper.getEmailBundleComposerBaseObject(
                    metadata,
                    domainOrderProductCode,
                    bundleContingents,
                    defaultNameserverSet
                );
            } else if (metadata.productFamily === BundleProductFamilies.webspace) {
                composerObject = this.wizardNewHelper.getWebhostingBundleComposerBaseObject(
                    metadata,
                    domainOrderProductCode,
                    bundleContingents
                );
            }

            composerObject.summaryObject.push({
                account: metadata.account,
                factSheets: bundleFactSheets,
                product: bundleProduct,
                productCode: metadata.productCode
            });

            if (!metadata.domain.vHostOnly) {
                composerObject.summaryObject.push( {
                    account: metadata.account,
                    factSheets: [],
                    product: {
                        addGracePeriod: 0,
                        billingCycle: BillingCycleToContractPeriodeConst[contractPeriod],
                        description: '',
                        factSheetItems: [],
                        family: '',
                        featured: false,
                        language: UiLanguagesConst[AuthContextService.user.language],
                        name: this.$translate.instant('TR_090119-5ebc34_TR')
                            + ': '
                            + this._domainStatus.domainNameUnicode,
                        productCode: domainOrderProductCode,
                        productCodeTemplate: null,
                        productType: '',
                        requiredCreationRight: '',
                        restoreGracePeriod: null,
                        shortDescription: '',
                        specificationItems: [],
                        type: 'Product',
                        verificationAlternatives: []
                    } as ProductApi.Product,
                    productCode: domainOrderProductCode,
                    bundleProductCode: metadata.productCode,
                    bundleContingents: bundleContingents,
                    domainName: metadata.domain.nameUnicode,
                    objectAction: domainInfo.action
                });
            }
            return composerObject;
        }); // .catch(error => alert(error));
    };

    private _getDomainProductInfoPromise = (metadata) => {
        return this.domainChecker.check(
            [metadata.domain.nameUnicode],
            metadata.account.id
        ).then(
            (domainList) => {
                return this.domainInfoHelper.getDomainInfo(metadata.domain.nameUnicode).then(
                    (domainInfo) => {
                        const domainStatusObject = domainList[0];
                        const action = this._getDomainActionByStatus(domainStatusObject.status);
                        return {
                            action: action,
                            domainStatus: domainStatusObject,
                            productCode: domainInfo.productCodes[action] === undefined
                                ? 'error' : domainInfo.productCodes[action]
                        };
                    }
                );
            }
        );
    };

    private _getDomainActionByStatus = (domainStatus: string) => {
        switch (domainStatus) {
            case 'available':
                return 'create';
            case 'registered':
                return 'transfer';
            default:
                return 'error: ' + domainStatus;
        }
    };
}
