import { AuthContextService } from '../';
import { LinkListOverviewItem } from '../../atomic-components/molecules';
import { TextFilterField } from '../../atomic-components/molecules/search/fancy-filter-fields';
import { UiRights } from '../../configuration';
import { OverviewModel } from '../abstract';
import { RequestCancellerService } from '../request-canceller';
import {ApiKeyModelService } from './api-key-model';

export class ApiKeyOverviewModelService extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$state', '$translate', 'apiKeyModel']
    );

    public readonly service = 'api-keys';
    public readonly objectType = 'Apikey';
    public readonly idField = 'apiKeyID';
    public readonly defaultFilterFields = ['ApiKeyName'];

    public activeMassAction = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};

    protected listCallbacks = [{ callback: this.apiKeyModel.list }];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private apiKeyModel: ApiKeyModelService
    ) {
        super(RequestCanceller);
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('name', this.$translate.instant('TR_140119-cc5115_TR'))
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters({/* value*/}) {
        return;
    }

    public actionLinks = (): LinkListOverviewItem[] => {
        let route = '';
        let routeParams = null;

        switch (true) {
            case this.$state.current.name.split('.').indexOf('subaccounts') >= 0:
                route = 'reseller.subaccounts.id.users.id.apikeys.new';
                routeParams = {
                    subaccountId: this.$state.$current.locals.globals.account.id,
                    userId: this.$state.$current.locals.globals.userData.id
                };
                break;
            case this.$state.current.name.includes('account.users'):
                route = 'account.users.id.apikeys.new';
                routeParams = {
                    accountId: AuthContextService.account.id,
                    userId: AuthContextService.user.id
                };
                break;
            default:
                route = 'profile.apikeys.new';
                routeParams = {
                    userId: AuthContextService.user.id
                };
                break;
        }

        return [
            {
                isGranted: AuthContextService.isGranted(UiRights.ACC_ACCOUNT_APIKEYS_MANAGE),
                route: route,
                routeParams: routeParams,
                text: this.$translate.instant('TR_140119-48c391_TR')
            }
        ] as LinkListOverviewItem[];
    };
}
