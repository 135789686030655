import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { DisableFilter } from '../../../atomic-components/organisms/overview/overview';

export class TemplateDnsJobOverviewController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;
    public bundle;
    public overviewServiceData;
    public expertView: boolean;
    public disableFilter: DisableFilter;

    public overviewModel;
    private simpleFilter;
    private extendedFilters;
    private overviewUiSettings;

    constructor(private $translate: ng.translate.ITranslateService) { }

    public $onInit() {
        this.expertView = true; // only expertview on job overviews
        this.disableFilter = {
            fancyFilter: false,
            searchFilter: false
        };

        this.pageHeaderData = {
            backwardLink: 'dns.dashboard',
            backwardText: this.$translate.instant('TR_100119-a3c01f_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: 'location-arrow',
            panelTitle: this.$translate.instant('TR_100119-2f2448_TR')
        };

        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewService: 'dnsJob',
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: this.simpleFilter,
            sort: {
                field: 'jobAddDate',
                order: 'DESC'
            }
        };
    }
}

export class TemplateDnsJobOverviewComponent implements ng.IComponentOptions {

    public bindings = {
        bundle: '<',
        extendedFilters: '<',
        overviewModel: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public controller = TemplateDnsJobOverviewController;
    public controllerAs = 'TemplateDnsJobOverviewCtrl';
    public template = require('./dns-job-overview-template.html');
}
