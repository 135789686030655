import * as ng from 'angular';
import { AuthContextService } from './../../../../../services/auth-context';

export class OrganNewSubAccountBasicDataController {
    public static $inject: string[] = ['$translate'];

    public newSubAccount: any;
    public onAccountChange: (parentAccountId: string) => void;
    public commercialCustomerDropdownItems: any[] = [{
        name: this.$translate.instant('TR_110119-1ab706_TR'),
        value: true
    }, {
        name: this.$translate.instant('ACCOUNT.BASICDATA.ACCOUNT-TYPE.ENDUSER'),
        value: false
    }];
    public languageDropdownItems: any[] = [{
        name: this.$translate.instant('ACCOUNT.PROFILE.LANGUAGE.OPTIONS.GERMAN'),
        value: 'DE'
    }, {
        name: this.$translate.instant('ACCOUNT.PROFILE.LANGUAGE.OPTIONS.ENGLISH'),
        value: 'EN'
    }];
    public titleDropdownItems: any[] = [{
        name: this.$translate.instant('TR_110119-5eaaa9_TR'),
        value: 'Mr'
    }, {
        name: this.$translate.instant('TR_110119-f53bc7_TR'),
        value: 'Ms'
    }, {
        name: this.$translate.instant('TR_110119-548e63_TR'),
        value: 'Company'
    }, {
        name: this.$translate.instant('TR_111219-d7acc4_TR'),
        value: 'RegisteredAssociation'
    }];
    public companyTitleDropdownItems: any[] = [{
        name: this.$translate.instant('TR_110119-5eaaa9_TR'),
        value: 'Mr'
    }, {
        name: this.$translate.instant('TR_110119-f53bc7_TR'),
        value: 'Ms'
    }];

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.newSubAccount.country = 'DE';
        this.newSubAccount.title = null;
        this.newSubAccount.allowedAdvertisingMethods = false;

        // Use getter/setter magic to reset specific models based on a selected title.
        let tmpTitle = this.newSubAccount.title;
        Object.defineProperty(this.newSubAccount, 'title', {
            get: () => { return tmpTitle; },
            set: (newTitle) => {
                if (
                    ['Company', 'RegisteredAssociation'].indexOf(tmpTitle) >= 0
                    && ['Company', 'RegisteredAssociation'].indexOf(newTitle) < 0
                ) {
                    this.newSubAccount.contactTitle = undefined;
                    this.newSubAccount.contactName = undefined;
                }
                tmpTitle = newTitle;
            }
        });
    }

    public get isDirectCustomer() {
        return AuthContextService.isDirectCustomer;
    }
}

export class OrganNewSubAccountBasicDataComponent implements ng.IComponentOptions {
    public bindings = {
        newSubAccount: '=',
        onAccountChange: '<'
    };
    public template = require('./sub-account-basic-data.html');
    public controller = OrganNewSubAccountBasicDataController;
}
