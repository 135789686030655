import * as ng from 'angular';

import { CheckboxStatusChange } from '@/atomic-components/molecules/forms/form-elements/checkbox/checkbox';
import { MoleculePanelEditController } from '@/atomic-components/molecules/panels';
import {
    PermissionGroupSelectFunctions
} from '@/atomic-components/molecules/panels/panel-rows/panel-edit-row-permission/group-select-functions';
import * as Types from '@/types';

export class MoleculePanelEditRowPermissionGroupController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public $editPanel: MoleculePanelEditController;

    public extended = false;
    public originalPermissions: string[];
    public selectedPermissions: string[];
    public templatePermissions: string[];
    public selectAllCheckboxTitle: string;

    private _permissionGroup: Types.AccountApi.RightGroup;

    public constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public get permissionGroup(): Types.AccountApi.RightGroup {
        return this._permissionGroup;
    }

    public set permissionGroup(permissionGroup: Types.AccountApi.RightGroup) {
        this._permissionGroup = permissionGroup;

        if (permissionGroup) {
            this.selectAllCheckboxTitle = PermissionGroupSelectFunctions
                .getPermissionsInGroup(permissionGroup)
                .join(', ');
        }
    }

    public get editable(): boolean {
        return this.$editPanel.isEditable;
    }

    public get selectAllIndeterminate(): boolean {
        return PermissionGroupSelectFunctions.isGroupPartiallySelected(this.permissionGroup, this.selectedPermissions);
    }

    public get selectAllDisabled(): boolean {
        return PermissionGroupSelectFunctions.isGroupInTemplate(this.permissionGroup, this.templatePermissions);
    }

    public get selectAllStatusChange(): CheckboxStatusChange {
        if (!this.originalPermissions) {
            return 'none';
        }

        const removed = this.originalPermissions
        .filter(
            (permission) => PermissionGroupSelectFunctions.getPermissionsInGroup(this.permissionGroup)
            .indexOf(permission) >= 0
        )
        .filter(
            (permission) => this.selectedPermissions.indexOf(permission) < 0
        )
        .length;

        const added = this.selectedPermissions
        .filter(
            (permission) => PermissionGroupSelectFunctions.getPermissionsInGroup(this.permissionGroup)
            .indexOf(permission) >= 0
        )
        .filter(
            (permission) => this.originalPermissions.indexOf(permission) < 0
        )
        .length;

        if (added > 0 && removed > 0) {
            return 'modified';
        } else if (added > 0) {
            return 'added';
        } else if (removed > 0) {
            return 'removed';
        } else {
            return 'none';
        }
    }

    public get selectAllSelected(): boolean {
        return PermissionGroupSelectFunctions.isGroupSelected(this.permissionGroup, this.selectedPermissions);
    }

    public set selectAllSelected(selected: boolean) {
        this.selectedPermissions = PermissionGroupSelectFunctions.setSelection(
            this.permissionGroup,
            this.selectedPermissions,
            this.templatePermissions,
            selected
        );
    }

    public get groupRightCount(): string {
        const permissions = PermissionGroupSelectFunctions.getPermissionsInGroup(this.permissionGroup) || [];
        const selected = permissions.filter((permission) => (this.selectedPermissions || []).indexOf(permission) >= 0);

        return `(${selected.length} ${this.$translate.instant('TR_110119-e3b6d8_TR')} `
        + `${permissions.length} ${this.$translate.instant('TR_110119-28a32f_TR')})`;
    }
}

export class MoleculePanelEditRowPermissionGroupComponent implements ng.IComponentOptions {
    public bindings = {
        originalPermissions: '<',
        permissionGroup: '<group',
        selectedPermissions: '=',
        templatePermissions: '<'
    };

    public require = {
        $editPanel: '?^moleculePanelEdit'
    };

    public template = require('./panel-edit-row-permission-group.html');
    public controller = MoleculePanelEditRowPermissionGroupController;
}
