import * as ng from 'angular';

export class MoleculeButtonDeleteController implements ng.IController {
    public static $inject: string[] = [ '$translate' ];
    public active: boolean;
    public delete: (arg: any) => void;
    public deleteParam: any;
    public disabled = false;
    public title: string = undefined;
    public titleCancel: string = undefined;
    public titleDisabled: string = undefined;

    constructor( private $translate: ng.translate.ITranslateService) {}

    public $onInit() {
        if (this.active == undefined) {
            this.active = true;
        }
        if (this.title == undefined) {
            this.title = this.$translate.instant('TR_140119-aee71e_TR');
        }
        if (this.titleCancel == undefined) {
            this.titleCancel = this.$translate.instant('TR_220923-6587c4_TR');
        }
        if (this.titleDisabled == undefined) {
            this.titleDisabled = this.$translate.instant('TR_220923-50ff5f_TR');
        }
    }

    public getTitle(): string {
        let result: string = undefined;
        if (this.disabled) {
            result = this.titleDisabled;
        } else {
            if (this.active) {
                result = this.title;
            } else {
                result = this.titleCancel;
            }
        }
        return result;
    }

}

export class MoleculeButtonDeleteComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        active: '<',
        delete: '<',
        deleteParam: '<',
        disabled: '<',
        title: '@?',
        titleCancel: '@?',
        titleDisabled: '@?'
    };
    public controller =  MoleculeButtonDeleteController;
    public template = require('./button-delete.html');
}
