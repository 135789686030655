interface IRecordTypes {
    name: string;
    value: string;
}

export interface IDnsZone {
    RecordTypes: IRecordTypes[];
    TemplatePlaceholder: any;
}

export const DnsZoneConst: IDnsZone = {
    RecordTypes: [
        { name: 'A', value: 'A' },
        { name: 'AAAA', value: 'AAAA' },
        { name: 'ALIAS', value: 'ALIAS' },
        { name: 'CAA', value: 'CAA' },
        { name: 'CERT', value: 'CERT' },
        { name: 'CNAME', value: 'CNAME' },
        { name: 'DS', value: 'DS' },
        { name: 'MX', value: 'MX' },
        { name: 'NS', value: 'NS' },
        { name: 'NULLMX', value: 'NULLMX' },
        { name: 'OPENPGPKEY', value: 'OPENPGPKEY' },
        { name: 'PTR', value: 'PTR' },
        { name: 'SRV', value: 'SRV' },
        { name: 'SSHFP', value: 'SSHFP' },
        { name: 'TLSA', value: 'TLSA' },
        { name: 'TXT', value: 'TXT' }
    ],
    TemplatePlaceholder: {
        domain: '##DOMAIN##',
        ipv4: '##IPV4##',
        ipv6: '##IPV6##',
        mxipv4: '##MX_IPV4##',
        mxipv6: '##MX_IPV6##'
    }
};

export enum DnsTypes {
    A = 'A',
    AAAA = 'AAAA',
    ALIAS = 'ALIAS',
    CAA = 'CAA',
    CERT = 'CERT',
    CNAME = 'CNAME',
    DS = 'DS',
    MX = 'MX',
    NS = 'NS',
    NULLMX = 'NULLMX',
    OPENPGPKEY = 'OPENPGPKEY',
    PTR = 'PTR',
    SRV = 'SRV',
    SSHFP = 'SSHFP',
    TLSA = 'TLSA',
    TXT = 'TXT'
}

export const RecordNameDomainPrefixes = {
    OPENPGPKEY: '_openpgpkey'
};
