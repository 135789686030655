import ng from 'angular';

import { WebspaceModelService } from '@/services/';
import * as Types from '@/types';

interface UserOrAccess extends Types.WebhostingApi.User, Types.WebhostingApi.WebspaceAccess {}

export class OrganViewPanelAccessLinkedToWebspacesHintController {
    public static $inject: string[] = ['webspaceModel'];

    public access: UserOrAccess;

    private _accessWebspaceListLoading = true;
    private _accessWebspaceList: Types.WebhostingApi.Webspace[] = [];

    constructor(
        private webspaceModel: WebspaceModelService
    ) {}

    public $onInit(): void {
        this._getAccessWebspaceList();
    }

    public get showAccessWebspaceHint(): boolean {
        return this._accessWebspaceList.length >= 2;
    }

    public set accessWebspaceList(_) {} // eslint-disable-line @typescript-eslint/no-empty-function
    public get accessWebspaceList(): boolean | Types.WebhostingApi.Webspace[] {
        if (this._accessWebspaceList?.length === 0) {
            return false;
        }

        return this._accessWebspaceList;
    }

    public set accessWebspaceListLoading(_) {} // eslint-disable-line @typescript-eslint/no-empty-function
    public get accessWebspaceListLoading(): boolean {
        return this._accessWebspaceListLoading;
    }

    private _getAccessWebspaceList = (): void => {
        let accessId: string;
        if (this.access?.userId) {
            accessId = this.access.userId;
        } else if (this.access?.id) {
            accessId = this.access.id;
        } else {
            return;
        }

        this._accessWebspaceListLoading = true;
        void this.webspaceModel.listWithoutPagination(
            null,
            1,
            { field: 'webspaceAccessesUserId', value: accessId }
        ).then(
            (webspaces) => {
                this._accessWebspaceList = webspaces.data;
                this._accessWebspaceListLoading = false;
            }
        );
    };
}

export class OrganViewPanelAccessLinkedToWebspacesHintComponent implements ng.IComponentOptions {
    public bindings = {
        access: '<'
    };
    public controller = OrganViewPanelAccessLinkedToWebspacesHintController;
    public template = require('./access-linked-to-webspaces-hint.html');
}
