import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateDnsZoneRecordsEditController {
    public static $inject: string[] = ['$state', '$translate'];

    public zone: any;
    public records: any[];
    public defaultNameserver: any[];
    public pageHeaderData: PanelHeaderData = {
        backwardLink: this.$state.current.name.replace('.records-edit', '.edit'),
        backwardText: '',
        panelHeaderRoute: '',
        panelHeaderRouteParams: {},
        panelIcon: 'location-arrow',
        panelTitle: ''
    };

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.pageHeaderData.panelTitle = this.$translate.instant(
            /* translationId */ 'TR_100119-467116_TR', { zoneName: this.zone.nameUnicode }
        );
        this.pageHeaderData.backwardText = this.$translate.instant(
            /* translationId */ 'TR_100119-932046_TR', { zoneName: this.zone.nameUnicode }
        );
    }
}

export class TemplateDnsZoneRecordsEditComponent implements ng.IComponentOptions {
    public bindings = {
        defaultNameserver: '<',
        records: '<',
        zone: '<'
    };
    public template = require('./records-edit-template.html');
    public controller = TemplateDnsZoneRecordsEditController;
}
