import * as ng from 'angular';

export class ShowErrorsDirective {

    public static Factory() {
        const directive = ($timeout) => new ShowErrorsDirective($timeout);

        directive.$inject = ['$timeout'];

        return directive;
    }

    public restrict = 'A';
    public require = ['showErrors', '^form'];
    public controllerAs = '$showErrors';

    private trackErrorDelayTime = 500;

    constructor(private $timeout: ng.ITimeoutService) {}

    public link = (scope: ng.IScope, element: ng.IAugmentedJQuery, attributes: ng.IAttributes, controllers: any[]) => {
        const showErrorsElement = element;
        const showErrorsCtrl = controllers[0];
        const formCtrl = controllers[1];
        let blurred = false;
        let trackErrorEl = element[0].querySelector('[track-error]');
        let trackError = (trackErrorEl);
        let eventEl: Element;
        let inputName: string;

        const isInvalid = () => {
            if (trackError) {
                return !showErrorsCtrl.valid;
            } else if ([undefined, null].indexOf(formCtrl) < 0 && [undefined].indexOf(formCtrl[inputName]) < 0) {
                return !formCtrl[inputName].$valid;
            } else {
                return false;
            }
        };

        const setEventListener = (otherElement) => {
            otherElement.addEventListener(
                'blur',
                () => {
                    blurred = true;
                    showErrorsElement.toggleClass('has-error', isInvalid());
                },
                trackError
            );
            otherElement.addEventListener(
                'focus',
                () => {
                    blurred = false;
                },
                trackError
            );
        };

        if (trackErrorEl) {
            this.$timeout(
                () => {
                    trackErrorEl = element[0].querySelector('[track-error]');
                    trackError = (trackErrorEl);
                    eventEl = trackErrorEl;

                    setEventListener(eventEl);
                },
                this.trackErrorDelayTime
            );
        } else {
            eventEl = element[0].querySelector('[name]');
            inputName = eventEl.getAttribute('name');

            if (!inputName) {
                throw new Error(
                    "show-errors element has no child input elements with a 'name' or 'track-error' attribute"
                );
            }

            setEventListener(eventEl);
        }

        scope.$watch(
            isInvalid,
            (invalid) => {
                if (!blurred && invalid) {
                    return;
                }

                element.toggleClass('has-error', invalid);
            }
        );

        scope.$on(
            'show-errors-check-validity',
            () => {
                element.toggleClass('has-error', isInvalid());
            }
        );
    };

    public controller = function() {
        this.invalid = false;
    };
}
