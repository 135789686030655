import { Validator } from './abstract-validator';

export class ValidateLineNumberMinimum extends Validator {

    constructor(
        private $translate: ng.translate.ITranslateService,
        private minLength: number,
        private name: string
    ) {
        super();
        this.minLength = minLength;
    }

    public validate = (value: string) => {
        const lines = value.split('\n');

        if (this.minLength > lines.length) {
            return [
                {
                    text: this.$translate.instant('TR_030220-08a07e_TR', { minLength: this.minLength, Name: this.name })
                }
            ];
        }

        return [];
    };
}

export class ValidateLineNumberMaximum extends Validator {

    constructor(
        private $translate: ng.translate.ITranslateService,
        private maxLength: number,
        private name: string
    ) {
        super();
    }

    public validate = (value: string) => {
        const lines = value.split('\n');

        if (this.maxLength < lines.length) {
            return [
                {
                    text: this.$translate.instant('TR_030220-e8f2e4_TR', { maxLength: this.maxLength, Name: this.name })
                }
            ];
        }

        return [];
    };
}
