import * as ng from 'angular';
import {
    PanelHeaderData
} from '@/atomic-components/molecules/panels/panel-header/panel-header';
import { UiRights } from '@/configuration';
import { AuthContextService } from '@/services';
import * as Types from '@/types';

export class TemplateWebspacePhpMinVersionController {
    public static $inject: string[] = [
        '$state',
        '$translate',
    ];

    public pageHeaderData: PanelHeaderData;
    public webspace: Types.WebhostingApi.Webspace;
    public phpVersions: Types.WebhostingApi.phpVersions;
    public userPanelRight;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
    ) {}

    public $onInit = (): void => {
        const routeState = this.$state.current.name.split('.')[0];

        this.pageHeaderData = {
            backwardLink: routeState === 'webhosting' ? 'webhosting.webspaces.id.edit'
                : routeState === 'managed-servers' ? 'managed-servers.id.webspaces.id.edit'
                : 'bundle.id.dashboard',
            backwardText:
                routeState === 'bundle'
                ? this.$translate.instant('TR_090119-a32485_TR')
                : this.$translate.instant('TR_140119-e915b4_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: routeState === 'managed-servers'
                ?  {
                    machineId: (this.$state.$current.locals.globals.machine as Types.MachineApi.VirtualMachine).id,
                    webspaceId: (this.$state.$current.locals.globals.webspace as Types.WebhostingApi.Webspace).id
                } : routeState === 'bundle'
                ? { bundleId: this.webspace.bundleId }
                : null,
            panelIcon: 'wrench',
            panelTitle: this.$translate.instant('TR_250123-663f01_TR')
        };

        this.userPanelRight = {
            minPhpVersion: {
                editPanelButton: AuthContextService.isGranted(UiRights.WEB_WEBSPACE_EDIT)
            }
        };
    };
}

export class TemplateWebspacePhpMinVersionComponent implements ng.IComponentOptions {
    public bindings = {
        webspace: '<',
        phpVersions: '<'
    };
    public controller = TemplateWebspacePhpMinVersionController;
    public template = require('./webspace-php-min-version-template.html');
}
