import { PanelHeaderData } from '../../../atomic-components/molecules/panels';

import * as ng from 'angular';

export class TemplateEmailWizardController {
    public static $inject: string[] = ['$state', '$translate'];

    public bundle;
    public machine;
    public pool;
    public pageHeaderData: PanelHeaderData;
    public productCode: string;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        const stateInBundle = this.$state.current.name.split('.')[0] === 'bundle';
        const stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';

        let panelHeaderBackwardLink;
        let panelHeaderRouteParams;
        let panelHeaderBackwardText;

        switch (true) {
            case stateInBundle:
                panelHeaderBackwardLink = 'bundle.id.mailboxes.overview';
                panelHeaderRouteParams = { bundleId: this.bundle.id };
                panelHeaderBackwardText = this.$translate.instant('TR_110119-c8ec44_TR');
                this.productCode = 'email-imap-mailbox-12m';
                break;
            case stateInMachine:
                panelHeaderBackwardLink = 'managed-servers.id.dashboard';
                panelHeaderRouteParams = { machineId: this.machine.id };
                panelHeaderBackwardText = this.$translate.instant('TR_220319-1ac2e3_TR');
                break;
            default:
                panelHeaderBackwardLink = 'email.dashboard';
                panelHeaderRouteParams = null;
                panelHeaderBackwardText = this.$translate.instant('TR_110119-c8ec44_TR');

        }

        this.pageHeaderData = {
            backwardLink: panelHeaderBackwardLink,
            backwardText: panelHeaderBackwardText,
            panelHeaderRoute: '',
            panelHeaderRouteParams: panelHeaderRouteParams,
            panelIcon: 'envelope',
            panelTitle: this.$translate.instant('TR_100119-e24e28_TR')
        };
    }
}

export class TemplateEmailWizardComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<',
        machine: '<?',
        pool: '<?'
    };
    public template = require('./email-wizard-template.html');
    public controller = TemplateEmailWizardController;
}
