import * as ng from 'angular';
import { PanelHeaderData } from './../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateManagedProjectWizardNewController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;
    public ressourceServers;

    private  machine;
    private databaseRessource;
    private webserverRessource;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.ressourceServers = {
            database: [undefined, null].indexOf(this.databaseRessource)
                ? this.databaseRessource
                : null,
            webserver: [undefined, null].indexOf(this.webserverRessource)
                ? this.webserverRessource
                : null
        };
        this.pageHeaderData = {
            backwardLink: 'managed-servers.id.dashboard',
            backwardText: this.$translate.instant('TR_220319-1ac2e3_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: { machineId: this.machine.id},
            panelIcon: 'cloud-upload',
            panelTitle: this.$translate.instant('TR_140319-9b2952_TR')
        };
    }
}

export class TemplateManagedProjectWizardNewComponent  implements ng.IComponentOptions {
    public bindings = {
        databaseRessource: '<',
        machine: '<',
        webserverRessource: '<'
    };
    public template = require('./managed-project-wizard-new-template.html');
    public controller = TemplateManagedProjectWizardNewController;
}
