import { FunctionRelayService } from '../function-relay';
import { ManagedApplicationRobotService } from '../managed-application';
import { ModelHelper } from '../model-helper';

import * as ng from 'angular';
import * as Types from '../../types';

export class StorageModelService {
    public static $inject: string[] = [
        '$translate',
        'functionRelay',
        'managedApplicationRobot'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private functionRelay: FunctionRelayService,
        private managedApplicationRobot: ManagedApplicationRobotService
    ) {}

    public listNextclouds = (limit?, page?, filters?, sort?, cancel?) => {
        sort = sort || {
                field: 'NextcloudName',
                order: 'ASC'
            };
        page = page || 1;

        return this.managedApplicationRobot.nextcloudsList(filters, limit, page, sort, cancel)
        .then(ModelHelper.returnListResponse);
    };

    public listNextcloudsWithoutPagination = (
        limit?,
        page?,
        filters?,
        sort?,
        cancel?,
        owner?,
        cacheTimeout?: number
    ) => {
        sort = sort || {
                field: 'NextcloudName',
                order: 'ASC'
            };
        page = page || 1;

        return this.managedApplicationRobot.nextcloudsListWithoutPagination(
            filters, limit, page, sort, cancel, owner, cacheTimeout
        )
        .then(ModelHelper.returnListResponse);
    };

    public nextcloudCreate = (
        nextcloud: Types.ManagedApplicationApi.Nextcloud,
        nextcloudUser: Types.ManagedApplicationApi.NextcloudUserWithPassword[],
        appConfigs: Types.ManagedApplicationApi.NextcloudAppConfigInput[],
        voucherCode?: string
    ) => {
        return this.managedApplicationRobot.nextcloudCreate(nextcloud, nextcloudUser, appConfigs, voucherCode);
    };

    public nextcloudAppList = (nextcloudId: string, language?: string) => {
        return this.managedApplicationRobot.nextcloudAppsList(nextcloudId, language);
    };

    public nextcloudAppGetConfiguration = (appId: string, nextcloudId?: string) => {
        return this.managedApplicationRobot.nextcloudAppGetConfiguration(appId, nextcloudId);
    };

    public nextcloudUpdate = (
        nextcloud: Types.ManagedApplicationApi.Nextcloud,
        appConfigs?: Types.ManagedApplicationApi.NextcloudAppConfigInput[],
        nextcloudConfiguration?: string
    ) => {
        return this.managedApplicationRobot.nextcloudUpdate(nextcloud, appConfigs, nextcloudConfiguration);
    };

    public findOne = (id, cacheTimeout?: number) => {
        return this.managedApplicationRobot.nextcloudsListWithoutPagination(
            {field: 'nextcloudId', value: id},
            1,
            1,
            undefined,
            undefined,
            undefined,
            cacheTimeout
        ).then(ModelHelper.returnFindOneResponse) as Promise<Types.ManagedApplicationApi.Nextcloud>;
    };
}
