import { DateWrapper } from '../../../../services';
import { FormDropDownGroupsItems } from '../../forms/form-elements/drop-down/drop-down-groups/drop-down-groups';

import * as ng from 'angular';
import * as Types from '../../../../types';

/**
 * Specification for select options in dropdown filters.
 */
interface SelectOption {
    name: string;
    value: string;
    disabled?: boolean;
}

export abstract class FilterField<ValueType> {
    abstract readonly type: string;
    private _value: ValueType | undefined | null | '';
    private _allowEmptyString = false;

    constructor(readonly filterField: string | string[], readonly placeholder: string, readonly relation?: string) {}

    public abstract get filter(): Types.Finding.Filter;

    public get value(): ValueType | undefined | null | '' {
        return this._value;
    }

    public set value(newValue: ValueType | undefined | null | '' ) {
        this._value = newValue;
        this.onSetCallback();
    }

    public allowEmptyString: (value: boolean) => FilterField<ValueType>
    = (value) => {
        this._allowEmptyString = value;

        return this;
    };

    protected onSetCallback = () => {}; // tslint:disable-line:no-empty

    protected get isEmpty(): boolean {
        const invalid = [undefined, null];

        if (!this._allowEmptyString) {
            invalid.push('');
        }

        return invalid.some(emptyValue => emptyValue === this.value);
    }
}

export class TextFilterField extends FilterField<string> {
    readonly type = 'text';

    public get filter() {
        if (this.isEmpty) {
            return null;
        }

        if (Array.isArray(this.filterField)) {
            return {
                subFilterConnective: 'OR',
                subFilter: this.filterField.map(
                    (filterField) => {
                        return {
                            field: filterField,
                            value: `*${this.value}*`
                        };
                    }
                )
            };
        } else {
            return {
                field: this.filterField,
                value: `*${this.value}*`
            };
        }
    }
}

export class SelectFilterField extends FilterField<string> {
    readonly type = 'select';

    constructor(filterField: string, placeholder: string, public selectOptions: SelectOption[]) {
        super(filterField, placeholder);
    }

    public get filter() {
        if (this.isEmpty) {
            return null;
        }

        if (Array.isArray(this.filterField)) {
            return {
                subFilterConnective: 'OR',
                subFilter: this.filterField.map(
                    (filterField) => {
                        return {
                            field: filterField,
                            value: this.value
                        };
                    }
                )
            };
        } else {
            return {
                field: this.filterField,
                value: this.value
            };
        }
    }
}

export class ApiSelectFilterField extends FilterField<string> {
    readonly type = 'apiselect';

    constructor(
        filterField: string,
        placeholder: string,
        public filterFields: string[],
        public findByIdFunction,
        public listFunction,
        public idField,
        public searchMapping
    ) {
        super(filterField, placeholder);
    }

    public get filter() {
        if (this.isEmpty) {
            return null;
        }

        if (Array.isArray(this.filterField)) {
            return {
                subFilterConnective: 'OR',
                subFilter: this.filterField.map(
                    (filterField) => {
                        return {
                            field: filterField,
                            value: this.value
                        };
                    }
                )
            };
        } else {
            return {
                field: this.filterField,
                value: this.value
            };
        }
    }
}

export class AccountFilterField extends FilterField<string> {
    readonly type = 'accounts';

    constructor(filterField: string, placeholder: string) {
        super(filterField, placeholder);
    }

    public get filter() {
        if (this.isEmpty) {
            return null;
        }

        if (Array.isArray(this.filterField)) {
            return {
                subFilterConnective: 'OR',
                subFilter: this.filterField.map(
                    (filterField) => {
                        return {
                            field: filterField,
                            value: this.value
                        };
                    }
                )
            };
        } else {
            return {
                field: this.filterField,
                value: this.value
            };
        }
    }
}

export class ContactFilterField extends FilterField<string> {
    readonly type = 'contacts';

    constructor(filterField: string, placeholder: string) {
        super(filterField, placeholder);
    }

    public get filter() {
        if (this.isEmpty) {
            return null;
        }

        if (Array.isArray(this.filterField)) {
            return {
                subFilterConnective: 'OR',
                subFilter: this.filterField.map(
                    (filterField) => {
                        return {
                            field: filterField,
                            value: this.value
                        };
                    }
                )
            };
        } else {
            return {
                field: this.filterField,
                value: this.value
            };
        }
    }
}

export class DdnsUserFilterField extends FilterField<string> {
    readonly type = 'ddns-user';

    constructor(filterField: string, placeholder: string) {
        super(filterField, placeholder);
    }

    public get filter() {
        if (this.isEmpty) {
            return null;
        }

        if (Array.isArray(this.filterField)) {
            return {
                subFilterConnective: 'OR',
                subFilter: this.filterField.map(
                    (filterField) => {
                        return {
                            field: filterField,
                            value: this.value
                        };
                    }
                )
            };
        } else {
            return {
                field: this.filterField,
                value: this.value
            };
        }
    }
}

export class DateFilterField extends FilterField<string> {
    readonly type = 'date';
    public startDate: DateWrapper;
    public endDate: DateWrapper;

    constructor(filterField: string, placeholder: string) {
        super(filterField, placeholder);
    }

    public get filter() {
        if (
            (
                [undefined, null].indexOf(this.startDate) >= 0
                || [undefined, null].indexOf(this.startDate.dateString) >= 0
            )
            && (
                [undefined, null].indexOf(this.endDate) >= 0
                || [undefined, null].indexOf(this.endDate.dateString) >= 0
            )
        ) {
            return null;
        }

        if (
            [undefined, null].indexOf(this.startDate) >= 0
            || [undefined, null].indexOf(this.startDate.dateString) >= 0
        ) {
            this.value = this.endDate.dateString;

            return {
                field: this.filterField,
                value: this.endDate.dateString,
                relation: 'less'
            } as Types.Finding.Filter;
        }

        if (
            [undefined, null].indexOf(this.endDate) >= 0
            || [undefined, null].indexOf(this.endDate.dateString) >= 0
        ) {
            this.value = this.startDate.dateString;

            return {
                field: this.filterField,
                value: this.startDate.dateString,
                relation: 'greater'
            } as Types.Finding.Filter;
        }

        this.value = this.startDate.dateString;

        return {
            subFilterConnective: 'AND',
            subFilter: [
                {
                    field: this.filterField,
                    value: this.startDate.dateString,
                    relation: 'greater'
                },
                {
                    field: this.filterField,
                    value: this.endDate.dateString,
                    relation: 'less'
                }
            ]
        } as Types.Finding.Filter;
    }

    protected onSetCallback = () => {
        if ([undefined, null, ''].indexOf(this.value) >= 0) {
            this.startDate = undefined;
            this.endDate = undefined;
        }
    };
}

export class GroupedSelectFilterField extends FilterField<string> {
    readonly type = 'groupedSelect';

    constructor(filterField: string, placeholder: string, public selectGroups: FormDropDownGroupsItems[]) {
        super(filterField, placeholder);
    }

    public get filter() {
        if (this.isEmpty) {
            return null;
        }

        if (Array.isArray(this.filterField)) {
            return {
                subFilterConnective: 'OR',
                subFilter: this.filterField.map(
                    (filterField) => {
                        return {
                            field: filterField,
                            value: this.value
                        };
                    }
                )
            };
        } else {
            return {
                field: this.filterField,
                value: this.value
            };
        }
    }
}

export class ShowHiddenObjectsFilterField extends FilterField<boolean> {
    readonly type = 'showHiddenObjects';

    constructor(filterField: string, public label: string) {
        super(filterField, '');
    }

    public get filter() {
        if (this.value) {
            return null;
        }

        return {
            field: this.filterField as string,
            value: '0'
        };
    }
}
