import * as ng from 'angular';
import { SpamProfile } from '../../configuration';

export class EmailHelper {
    // Ist dass hier eigentlich korrekt??? - im Wizard prüfen wir,
    // alle Weiterleitungsziele müssen interne Adressen sein???
    public static isExternalForwarder: (forwarderTargets: string[], domainName: string) => boolean
    = (forwarderTargets: string[], domainName: string) => {
        return forwarderTargets.some((target) => [undefined, domainName].indexOf(target.split('@')[1]) < 0);
    };

    public static getSelectedSpamProfile = (spamProfiles: SpamProfile[], spamSettings: any): SpamProfile => {
        // When only the spamLevel property is set in the spamSettings, it gets picked as default.
        if (spamSettings.spamLevel && Object.keys(spamSettings).length === 1) {
            spamSettings = spamProfiles.find(
                (spamProfile) => spamProfile.values.spamLevel === spamSettings.spamLevel
            )?.values;
        }

        const profile = spamProfiles.slice(1).filter((singleProfile) => {
            return JSON.stringify(singleProfile.values) === JSON.stringify(spamSettings);
        })[0];

        if (profile) {
            return profile;
        }

        return spamProfiles[0];
    };
}
