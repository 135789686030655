import * as ng from 'angular';
import { MoleculeFormEditController } from '@/atomic-components';
import { DepositModelService } from '@/services';

export class MoleculePanelEditRowPaymentMethodAvailabilityController implements ng.IController {
    public static $inject: string[] = ['$timeout', '$translate', 'depositModel'];

    public $editForm: MoleculeFormEditController;
    public calloutMessage = '';

    private _isAvailable = true;
    private _paymentMethod: string;
    private _paymentMethodAvailability: {available: boolean; paymentMethod: string}[];
    private _validatorIndex: number;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        depositModel: DepositModelService
    ) {
        void depositModel.paymentMethodAvailability().then(
            (result) => {
                this._paymentMethodAvailability = result;

                if (this._paymentMethod) {
                    this.checkAvailability();
                }
            }
        );
    }

    public $onInit = (): void => {
        if (this.$editForm) {
            this._validatorIndex = this.$editForm.registerValidator(this);
        }
    };

    public $onDestroy = (): void => {
        if (this.$editForm) {
            this.$editForm.unregisterValidator(this._validatorIndex);
        }
    };

    public set isAvailable(isAvailable) {
        // Allow setting the initial value before it is set internally
        // (This way, the input fields in the payment type aren't visible for half a second first)
        if (!this._paymentMethod || this._paymentMethodAvailability.length === 0) {
            void this.$timeout(() => this._isAvailable = isAvailable);
        }
    }

    public get isAvailable(): boolean {
        return this._isAvailable;
    }

    public get paymentMethod(): string {
        return this._paymentMethod;
    }

    public set paymentMethod(paymentMethod) {
        if (this._paymentMethod === paymentMethod) {
            return;
        }

        this._paymentMethod = paymentMethod;

        if (!this._paymentMethod) {
            this.isAvailable = true;

            return;
        }

        if (this._paymentMethodAvailability) {
            this.checkAvailability();
        }
    }

    public validate = (): boolean => {
        return this._isAvailable;
    };

    private checkAvailability = (): void => {
        if (this.paymentMethod === 'banktransfer') {
            void this.$timeout(() => this._isAvailable = true);
            this.triggerFormValidation();

            return;
        }

        this.updateCalloutMessage();

        let methodToCheck = this._paymentMethod;

        if (methodToCheck === 'googlepay') {
            // Google Pay is covered by stripe
            methodToCheck = 'stripe';
        }

        for (const method of this._paymentMethodAvailability) {
            if (method.paymentMethod.toLowerCase() === methodToCheck.toLowerCase()) {
                void this.$timeout(() => this._isAvailable = method.available);
                this.triggerFormValidation();

                return;
            }
        }

        void this.$timeout(() => this._isAvailable = false);
        this.triggerFormValidation();
    };

    private triggerFormValidation = (): void => {
        if (this.$editForm) {
            this.$editForm.validate(this._validatorIndex);
        }
    };

    private updateCalloutMessage = (): void => {
        switch (this._paymentMethod.toLowerCase()) {
            case 'directdebit':
                this.calloutMessage = this.$translate.instant('TR_141020-7b1bea_TR');
                break;

            case 'iyzico':
                this.calloutMessage = this.$translate.instant('TR_141020-785822_TR');
                break;

            case 'paypal':
                this.calloutMessage = this.$translate.instant('TR_141020-f02c63_TR');
                break;

            case 'googlepay':
            case 'stripe':
                this.calloutMessage = this.$translate.instant('TR_141020-93cca9_TR');
                break;

            default:
                this.calloutMessage = this.$translate.instant('TR_141020-8fdd1c_TR');
                break;
        }
    };
}

export class MoleculePanelEditRowPaymentMethodAvailabilityComponent implements ng.IComponentOptions {
    public require = {
        $editForm: '?^moleculeFormEdit'
    };

    public bindings = {
        isAvailable: '=?',
        paymentMethod: '<'
    };

    public controller = MoleculePanelEditRowPaymentMethodAvailabilityController;
    public controllerAs = '$panelRow';
    public template = require('./panel-edit-row-payment-method-availability.html');
}
