import * as ng from 'angular';

export interface ApiErrorObject {
    code: number;
    contextObject?: string;
    contextPath?: string;
    details?: any[];
    text: string;
    value?: string;
}

export interface ApiExpectedErrorList {
    lookup: {
        code?: number;
        value?: string;
        contextObject?: string;
        contextPath?: string;
    };
    userNotice: {
        type: 'error' | 'information';
        message: string;
    };
}

export class ApiErrorModel {
    public static $inject: string[] = [];
    public ignoreErrorCodes = [];
    public expectedErrorList: ApiExpectedErrorList[] = [];

    private _errorList: ApiErrorObject[] = [];

    public addErrorToList = (apiErrorObject: ApiErrorObject) => {
        if (!this.ignoreError(apiErrorObject)) {
            this._errorList.push(apiErrorObject);
        }
    };

    public removeErrorFromIgnoreList = (errorCode: string | number) => {
        const errorCodeAlwaysInIgnoreList = this.ignoreErrorCodes.some((code) => {
            return +code === +errorCode;
        });

        if (errorCodeAlwaysInIgnoreList) {
            this.ignoreErrorCodes = this.ignoreErrorCodes.filter((code) => {
                return +code !== +errorCode;
            });
        }
    };

    public addErrorToIgnoreList = (errorCode: string | number) => {
        const errorCodeAlwaysInIgnoreList = this.ignoreErrorCodes.some((code) => {
            return +code === +errorCode;
        });

        if (!errorCodeAlwaysInIgnoreList) {
            this.ignoreErrorCodes.push(+errorCode);
        }
    };

    public get errorObjectList(): ApiErrorObject[] {
        return this._errorList;
    }

    public set errorObjectList(value: ApiErrorObject[]) {
        this._errorList = value.filter(
            (error) => {
                if (Array.isArray(error.code)) {
                    return !error.code.some(
                        (codeString) => this.ignoreErrorCodes.some(
                            (ignoreErrorCode) => +codeString === +ignoreErrorCode
                        )
                    );
                } else {
                    return !this.ignoreErrorCodes.some(
                        // Cast to integer
                        (ignoreErrorCode) => +error === +ignoreErrorCode
                    );
                }
            }
        );
    }

    public ignoreError = (apiErrorObject: ApiErrorObject) => {
        if (this.expectedErrorList.length > 0 && !Array.isArray(apiErrorObject.code)) {
            for (const expectedError of this.expectedErrorList) {
                let allAttributesFound = true;

                for (const attribute of Object.keys(expectedError.lookup)) {
                    if (apiErrorObject[attribute] !== expectedError.lookup[attribute]) {
                        allAttributesFound = false;
                    }
                }

                if (allAttributesFound) {
                    return true;
                }
            }
        }

        if (Array.isArray(apiErrorObject.code)) {
            return apiErrorObject.code.some(
                (codeString) => this.ignoreErrorCodes.some(
                    // Cast to integer
                    (ignoreErrorCode) => +codeString === +ignoreErrorCode
                )
            );
        } else {
            return this.ignoreErrorCodes.some(
                // Cast to integer
                (ignoreErrorCode) => +apiErrorObject.code === +ignoreErrorCode
            );
        }
    };

    public destroyErrorList = () => {
        this.errorObjectList = [];
    };

    public apiResponseHasError = (response) => {
        if (Array.isArray(response)) {
            return response[0].response !== undefined
            ? false
            : response.some((err) => err instanceof Error);
        }

        return response.response !== undefined
        ? false
        : response instanceof Error;
    };

    public issetApiErrors = () => {
        return this._errorList.length;
    };
}
