import * as ng from 'angular';
import * as Types from '../../../../../types';

export class OrganCreateConfigurationBundleController implements ng.IController {
    public static $inject: string[] = [];

    public bundleObject: Types.OrchestrationApi.Bundle;
    public panelHidden = false;
    public required = false;

    private _projectName = '';
    private _selectedProduct;

    public $onChanges(changes: any) {
        this._setBundleObject();

        if (changes._projectName !== undefined) {
            this.bundleObject.name = changes._projectName.currentValue;
        }

        if (changes._selectedProduct !== undefined
            && changes._selectedProduct.currentValue.prices !== undefined
            && changes._selectedProduct.currentValue.prices.length >= 1
        ) {
            this.bundleObject.productCode = changes._selectedProduct.currentValue.prices[0].productCode;
        }
    }

    public get completed() {
        if (!this.required) {
            return true;
        }

        return [undefined, null, ''].indexOf(this.bundleObject.name) < 0
            && [undefined, null, ''].indexOf(this.bundleObject.productCode) < 0;
    }

    public set completed({}) {} // tslint:disable-line:no-empty

    private _setBundleObject = () => {
        if ([undefined, null].indexOf(this.bundleObject) >= 0
            || Object.keys(this.bundleObject).length === 0
        ) {
            this.bundleObject = {
                items: [],
                name: '',
                productCode: '',
                type: 'Bundle'
            };
        }
    };
}

export class OrganCreateConfigurationBundleComponent implements ng.IComponentOptions {
    public bindings = {
        _projectName: '<?projectName',
        _selectedProduct: '<?selectedProduct',
        bundleObject: '=',
        completed: '=',
        panelHidden: '<?',
        required: '<?'
    };

    public template = require('./bundle-config.html');
    public controller =  OrganCreateConfigurationBundleController;
}
