import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateNameserverSetEditController {
    public static $inject: string[] = ['$translate'];

    public nameserverSet: any;
    public pageHeaderData: PanelHeaderData = {
        panelTitle: '',
        panelIcon: 'location-arrow',
        panelHeaderRoute: '',
        backwardText: this.$translate.instant('TR_100119-47c6f2_TR'),
        backwardLink: 'dns.nameserversets.overview',
        panelHeaderRouteParams: {}
    };

    constructor(private $translate: ng.translate.ITranslateService) {}

    $onInit() {
        this.pageHeaderData.panelTitle = this.nameserverSet.name;
    }
}

export class TemplateNameserverSetEditComponent implements ng.IComponentOptions {

    public bindings = {
        nameserverSet: '<'
    };
    public template = require('./nameserver-set-edit-template.html');
    public controller = TemplateNameserverSetEditController;
}
