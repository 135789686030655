import * as ng from 'angular';

export class MoleculeButtonMenuController implements ng.IController {
    public static $inject: string[] = [];

    public disabled: boolean;
    public toggle: () => void;
}

export class MoleculeButtonMenuComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        disabled: '<',
        toggle: '<'
    };
    public controller =  MoleculeButtonMenuController;
    public template = require('./button-menu.html');
}
