import {
    DashboardWidgetAttributObject,
    DashboardWidgetObjectDefinition
} from '@/atomic-components/organs/dashboard-widgets/dashboard-widget-model-operator';
import { AuthContextService } from '@/services';

export enum DashboardWidgetType {
    custom = 'custom',
    system = 'system'
}

export interface DashboardWidgetObject {
    attribute: DashboardWidgetAttributObject;
    componentTagName: string;
    widgetModel: string;
    version: string;
}

export abstract class DashboardWidgetModel {
    public static $inject: string[] =  [];

    public dashboardWidgetDefinition: DashboardWidgetObjectDefinition;

    public static checkWidgetUserPermissions = (widgetModel: DashboardWidgetModel): boolean => {
        let hasUserPermission = true;
        const userPermissions = widgetModel.dashboardWidgetDefinition.userPermissions;

        if (!userPermissions) {
            return hasUserPermission;
        }

        if (hasUserPermission && userPermissions.isGranted) {
            hasUserPermission = AuthContextService.isGranted(userPermissions.isGranted);
        }

        if (hasUserPermission && userPermissions.isGrantedAny) {
            hasUserPermission = AuthContextService.isGrantedAny(userPermissions.isGrantedAny);
        }

        if (hasUserPermission && userPermissions.isGrantedAll) {
            hasUserPermission = AuthContextService.isGrantedAll(userPermissions.isGrantedAll);
        }

        if (hasUserPermission && userPermissions.notRootOrCompanyAccount) {
            hasUserPermission = !AuthContextService.isRootOrCompanyAccount;
        }

        if (hasUserPermission && userPermissions.checkCurrentUserAccountComplete) {
            hasUserPermission = AuthContextService.isCurrentUserAccountComplete;
        }

        return hasUserPermission;
    };

    public additionalAvaiableConditionsFullfilled = (): PromiseLike<boolean | void> => {
        return Promise.resolve(true);
    };

}
