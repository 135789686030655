import * as ng from 'angular';

export class OrganEditPanelDatabaseUsersGeneralController {
    public static $inject: string[] = [];

    public cancel: any;
    public $editFormOrganism: any;
    public panelRight: any;
    public displayDbUsername = false;

    public $onInit() {
        this.displayDbUsername = ([undefined, null, ''].indexOf(this.$editFormOrganism.user) === -1);
    }

    public isChangeUserPassword = (access) => {
        if (!access || !access.password) {
            return false;
        }

        return access.password?.length > 0;
    };
}

export class OrganEditPanelDatabaseUsersGeneralComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormDatabaseUser'
    };
    public controller = OrganEditPanelDatabaseUsersGeneralController;
    public controllerAs = '$editPanelOrgan';
    // tslint:disable-next-line: max-line-length
    public template = require('./database-users-general.html');
}
