import * as ng from 'angular';

export class OrganViewPanelSslCertificateGenerellController {
    public certificate: any;
    public showObjectId: boolean;

    public get showExternalOrderId() {
        return this.certificate.externalOrderId !== undefined && this.certificate.externalOrderId.length > 0;
    }

    public get linkToSupplier() {
        if (['quicksslpremium', 'truebusiness'].includes(this.certificate.brand)) {
            return 'https://www.geotrust.com/support/contact/';
        }
        if (this.certificate.brand === 'rapidssl') {
            return 'https://www.rapidssl.com/support/contact/';
        }
        return '';
    }
}

export class OrganViewPanelSslCertificateGenerellComponent implements ng.IComponentOptions {
    public bindings = {
        certificate: '<certificate',
        showObjectId: '<'
    };
    public controller = OrganViewPanelSslCertificateGenerellController;
    public controllerAs = '$viewPanelOrgan';
    public template = require('./ssl-certificate-generell-view.html');
}
