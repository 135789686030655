import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateNextcloudWizardController {
    public static $inject: string[] = ['$state', '$translate'];

    public pageHeaderData: PanelHeaderData;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit(): void {
        const steps = this.$state.current.name.split('.');
        const stateInNextcloud = steps[0] === 'nextcloud';
        this.pageHeaderData = {
            backwardLink: stateInNextcloud
                ? steps[1] === 'new' ? 'nextcloud.overview' : 'nextcloud.id.edit'
                : 'storage.dashboard',
            backwardText: stateInNextcloud
                ? steps[1] === 'new'
                    ? this.$translate.instant('TR_220519-98c7e4_TR')
                    : this.$translate.instant('TR_010819-62941c_TR')
                : this.$translate.instant('TR_220519-98c7e4_TR'), // storage: TR_150519-cfa086_TR
            panelHeaderRoute: '',
            panelHeaderRouteParams: {},
            panelIcon: '/assets/images/logos/nextcloud-bw.svg',
            panelIconPath: true,
            panelTitle: this.$translate.instant('TR_200519-c06c78_TR')
        };
    }
}

export class TemplateNextcloudWizardComponent implements ng.IComponentOptions {
    public bindings = {};
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    public template = require('./nextcloud-wizard-template.html');
    public controller = TemplateNextcloudWizardController;
}
