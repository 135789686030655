import * as ng from 'angular';

export class TemplateAccountSettingsController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public basicData;

    public pageHeaderData = {
        backwardLink: 'account.dashboard',
        backwardText: this.$translate.instant('TR_080119-b2bbbd_TR'),
        panelHeaderRoute: 'account.settings',
        panelIcon: 'sliders-h',
        panelTitle: this.$translate.instant('STATE.ACCOUNT.SETTINGS.PAGE-TITLE')
    };

    constructor(private $translate: ng.translate.ITranslateService) {}
}

export class TemplateAccountSettingsComponent implements ng.IComponentOptions {
    public bindings = {
        basicData: '<'
    };
    public template = require('./settings.html');
    public controller = TemplateAccountSettingsController;
}
