/* eslint-disable */

import { MachineProductFamilies, UiRights } from '@/configuration';
import { AsteriskNoteService, AuthContextService, ScrollToValidationError } from '@/services';
import * as ng from 'angular';
import { ProductFamiliesConst } from '../../../../../configuration/billing';
import * as Types from '../../../../../types';
import { AccountApi } from '../../../../../types';
import { ViewTypes } from '../../../../../types/view-types';
export class OrganismMachineWizardConfigViewController {
    public static $inject: string[] = [
        '$timeout',
        'asteriskNote'
    ];

    public callback: (metaData: ViewTypes.ProductConfigMachineObject) => {};
    public outerAccount: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    public outerProductCode: string; // todo: has to be implemented (if set product has to be preselected)
    public productCodeList: string[] = [];
    public productFamilies: string[] = ProductFamiliesConst.machines;
    public productFamily: string;
    public service = 'machine';
    public sufficientCreditAvailable = true;
    public hideUserSelection = false;
    public selectedProductCode: string = null;
    public metaData: ViewTypes.ProductConfigMachineObject;
    public configCompleted: boolean; // used in template
    public machineProductFamilies = MachineProductFamilies; // used in template
    public showVoucherField = true;
    public voucher: ViewTypes.ProductConfigVoucher;

    private _account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    private _metaData: any;
    private _selectedProductCode: string = null;
    private _individualServerProductFamily = 'individual-virtual-machines';
    private _backupProdutCode = '';

    public constructor(
        private $timeout: ng.ITimeoutService,
        private asteriskNote: AsteriskNoteService
    ) {}

    public $onInit() {
        if ( // Admins can also configure individual cloud servers
            AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN)
            && !this.productFamilies.includes(this._individualServerProductFamily)
        ) {
            this.productFamilies.push(this._individualServerProductFamily);
        }

        if ([undefined, null].indexOf(this.outerAccount) < 0) {
            this.account = this.outerAccount;
        }

        this.asteriskNote.resetNodes();
    }

    public $doCheck() {
        /**
         * Okay, maybe not the most performant solution, but ey, you got a better idea?
         */
        if (
            [undefined, null].indexOf(this.metaData) < 0
            && JSON.stringify(this.metaData) !== JSON.stringify(this._metaData)
        ) {
            this._metaData = ng.copy(this.metaData);
        }

        if (this.selectedProductCode !== this._selectedProductCode) {
            this.productCodeList = this.productCodeList.filter((element) => element !== this._selectedProductCode);

            if ([undefined, null].indexOf(this.selectedProductCode) < 0) {
                this.productCodeList.push(this.selectedProductCode);
            }

            this._selectedProductCode = ng.copy(this.selectedProductCode);
        }

        // TODO this can get into a callback method called from backup
        if (
            this.metaData?.machineBackupProductCode &&
            this.productCodeList.findIndex((code) => code === this.metaData?.machineBackupProductCode) < 0)
        {
            this.productCodeList.push(this.metaData?.machineBackupProductCode);
            this._backupProdutCode = this.metaData?.machineBackupProductCode;
        } else if (!this.metaData?.machineBackupProductCode && this._backupProdutCode) {
            const backupProductCodeIndex: number = this.productCodeList.findIndex((code) => code === this._backupProdutCode);
            if (backupProductCodeIndex < 0) return;
            this.productCodeList.splice(backupProductCodeIndex, 1);
            this._backupProdutCode = '';
        }
    }

    // tslint:disable-next-line: cyclomatic-complexity
    public unsavedChangesExist = () => {
        switch (this.productFamily) {
            case this.machineProductFamilies.cloudServer:
            case this.machineProductFamilies.managedCloudServer:
            case this.machineProductFamilies.ecommerceServer:
                const cloudServer = (this.metaData );
                if (cloudServer.virtualMachine?.name?.length > 0
                    || cloudServer.virtualMachine?.hostName?.length > 0
                    || cloudServer.virtualMachine?.os?.rootUserPass?.length > 0
                ) {
                    return true;
                }
                break;
            case this.machineProductFamilies.individualServer:
                const individualServer = (this.metaData as unknown as ViewTypes.ProductConfigMachineIndividualObject);
                    // server general
                if (individualServer.virtualMachine?.name.length > 0
                    || individualServer.individualProperties?.price !== '0'
                    || individualServer.individualProperties?.managementType !== 'none'
                    || individualServer.individualProperties?.virtualMachineHostId !== 'auto'
                    // server specs
                    || individualServer.individualProperties?.cpu?.cpuCount > 1
                    || individualServer.individualProperties?.memory?.capacity > 1
                    || individualServer.individualProperties?.disk?.capacity !== 10
                    || individualServer.individualProperties?.cpu?.cpuQuota !== 80000
                    || individualServer.individualProperties?.diskControllerType !== 'virtio-scsi'
                    || individualServer.individualProperties?.disk?.type !== 'local'
                    || individualServer.individualProperties?.disk?.Bps !== 100
                    || individualServer.individualProperties?.disk?.Iops !== 300
                    || individualServer.individualProperties?.architecture !== 'x86_64'
                    || individualServer.individualProperties?.networkBandwidth !== 100
                ) {
                    return true;
                }
                break;
        }
        return false;
    };

    public get isIndividualServer() {
        return this.productFamily === this._individualServerProductFamily;
    }

    public get account(): AccountApi.Account | AccountApi.Subaccount {
        return this._account;
    }

    public set account(value: AccountApi.Account | AccountApi.Subaccount) {
        if (
            [undefined, null].indexOf(value) < 0
            && value !== this._account
            && [undefined, null].indexOf(this.metaData) < 0
        ) {
            this.metaData.account = value;
        }

        this._account = value;
    }

    public scrollToError = () => {
        ScrollToValidationError.scrollToError(this.$timeout);
    };

    public finishConfiguration = () => {
        if (this.callback !== undefined) {
            this.metaData.productCode = this.selectedProductCode;
            this.metaData.productFamily = this.productFamily;
            this.callback(this.metaData);
        }
    };

    public voucherCodeSelected = (voucher: ViewTypes.ProductConfigVoucher): void => {
        void this.$timeout(() => {
            if (this.metaData) this.metaData.voucher = voucher;
            this.voucher = voucher;
        });
    };

    public get canSeePrices() {
        return AuthContextService.isGranted(UiRights.BIL_LIST_ARTICLE_PRICES);
    }

}

export class OrganismMachineWizardConfigViewComponent implements ng.IComponentOptions {
    public bindings = {
        callback: '<',
        productFamily: '=',
        outerAccount: '<',
        outerProductCode: '<'
    };

    public template = require('./machine-wizard-config-view.html');
    public controller = OrganismMachineWizardConfigViewController;
}
