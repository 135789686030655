import { DdnsModelService } from '../services/dns';

export class DdnsUserName {
    public static $inject: string[] = ['ddnsModel'];

    public static Factory(ddnsModel: DdnsModelService) {
        const cache = {};

        const ddnsUserNameFilter: any = (id: string, field = 'name') => {
            if (cache[id]) {
                if (cache[id] !== 'waiting') {
                    return cache[id][field];
                }
                return undefined;
            }

            cache[id] = 'waiting';

            return ddnsModel.getCredentialsById(id).then(
                (result) => {
                    cache[result.id] = result;
                }
            );
        };

        ddnsUserNameFilter.$stateful = true;
        return ddnsUserNameFilter;
    }
}
