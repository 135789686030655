import ng from 'angular';

import { PanelHeaderData } from '@/atomic-components/molecules';
import { DashboardOverviewObject } from '@/atomic-components/organisms';
import { FilterCacheService, ManagedMachineOverviewModel } from '@/services';

export class TemplateMachineManagedOverviewController {
    public static $inject: string[] = ['$translate', 'managedMachineOverviewModel', 'filterCache'];

    public leftPanelArea: PanelHeaderData[];
    public modifier = '';
    public dashboardOverview: DashboardOverviewObject;
    public showObjectId: string;
    public pageHeaderData: PanelHeaderData = {
        panelHeaderRoute: '',
        panelIcon: 'cloud-upload',
        panelTitle: this.$translate.instant('TR_210319-db468e_TR')
    };
    public faqArticleIdList: string[] = [
        'webspace-ftp-access',
        'domain-register-new',
        'ssl-activate'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private managedMachineOverviewModel: ManagedMachineOverviewModel,
        private filterCache: FilterCacheService
    ) {}

    public $onInit() {
        this.dashboardOverview = {
            // List of table service data
            overviewsData: [
                {
                    listOptions: {
                        limit: 5,
                        sort: {field: 'virtualMachineLastChangeDate', order: 'DESC'}
                    },
                    overviewModel: this.managedMachineOverviewModel,
                    panelHeaderData: {
                        includeShowAllLink: false,
                        includeShowDetailsLink: false,
                        panelHeaderRoute: '',
                        panelHeaderRouteParams: {},
                        panelIcon: 'cloud-upload',
                        panelTitle: this.$translate.instant('TR_210319-db468e_TR')
                    },
                    serviceItemList: []
                }
            ],
            // Dashboard main menu panel
            panelData: {
                mainHeader: {
                    icon: '',
                    route: 'managed-servers.new',
                    routeParams: {},
                    text: this.$translate.instant('TR_210319-ecef81_TR')
                }
            }
        };
    }
}

export class TemplateMachineManagedOverviewComponent implements ng.IComponentOptions {
    public binding = {
        showObjectId: '<'
    };
    public controller = TemplateMachineManagedOverviewController;
    public controllerAs = 'TemplateMachineManagedOverviewCtrl';
    public template = require('./machine-managed-overview-template.html');
}
