import * as ng from 'angular';

import { ViewTypes } from '@/types/view-types';
import { WizardCreateObject } from '../../general';
import { EmailWizardConfirmation } from './';
import { MailboxModelService } from './../../../../../services/email/mailbox-model';

export class OrganismEmailWizardConfirmViewController {
    public static $inject: string[] = [
        '$translate',
        'emailWizardConfirmation',
        'localInterval',
        'mailboxModel'
    ];

    public isLoading = true;
    // Required information for the products that need to be ordered.
    private _apiProductObject: ViewTypes.WizardComposerObject;
    private wizardCreateObjectList: WizardCreateObject[] = []; // List of running jobs.

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private emailWizardConfirmation: EmailWizardConfirmation,
        private localInterval: ng.IIntervalService,
        private mailboxModel: MailboxModelService
    ) {}

    public $onDestroy() {
        this.localInterval.cancel(this.emailWizardConfirmation.checkJobStatusIntervalHandler);
        this.isLoading = false;
    }

    public $onInit() {
        this.isLoading = true;
        this.wizardCreateObjectList = [];
        this.emailWizardConfirmation.selectedProductFamily = this._apiProductObject.productFamily;

        switch (this._apiProductObject.productFamily) {
            case 'imap-mailboxes':
                this.emailWizardConfirmation.useOrchestrationService = false;
                this.wizardCreateObjectList.push({
                    callback:
                        () => {
                            return this.mailboxModel.createMailbox(
                                this._apiProductObject.apiObject.mailbox,
                                this._apiProductObject.apiObject.password,
                                undefined,
                                this._apiProductObject.apiObject.poolId,
                                this._apiProductObject.apiObject.autoconfigureDns,
                                this._apiProductObject.apiObject.skipDnsCheck
                            );
                        },
                    children: [],
                    labelText: this.$translate.instant('TR_140720-4789be_TR'),
                    objectType: 'ImapMailbox'
                });
                break;

            case 'catchalls':
                this.emailWizardConfirmation.useOrchestrationService = false;
                this.wizardCreateObjectList.push({
                    callback:
                        () => {
                            return this.mailboxModel.createMailbox(
                                this._apiProductObject.apiObject.mailbox,
                                undefined,
                                this._apiProductObject.apiObject.accountId,
                                undefined,
                                this._apiProductObject.apiObject.autoconfigureDns,
                                this._apiProductObject.apiObject.skipDnsCheck
                            );
                        },
                    children: [],
                    labelText: this.$translate.instant('TR_140720-44297d_TR'),
                    objectType: 'CatchAll'
                });
                break;

            case 'mailing-lists':
                this.emailWizardConfirmation.useOrchestrationService = false;
                this.wizardCreateObjectList.push({
                    callback:
                        () => {
                            return this.mailboxModel.createMailbox(
                                this._apiProductObject.apiObject.mailbox,
                                undefined,
                                this._apiProductObject.apiObject.accountId,
                                undefined,
                                this._apiProductObject.apiObject.autoconfigureDns,
                                this._apiProductObject.apiObject.skipDnsCheck
                            );
                        },
                    children: [],
                    labelText: this.$translate.instant('TR_160720-90df05_TR'),
                    objectType: 'MailingList'
                });
                break;

            case 'smtp-forwarders':
                this.emailWizardConfirmation.useOrchestrationService = false;
                this.wizardCreateObjectList.push({
                    callback:
                        () => {
                            return this.mailboxModel.createMailbox(
                                this._apiProductObject.apiObject.mailbox,
                                undefined,
                                this._apiProductObject.apiObject.accountId,
                                undefined,
                                this._apiProductObject.apiObject.autoconfigureDns,
                                this._apiProductObject.apiObject.skipDnsCheck
                            );
                        },
                    children: [],
                    labelText: this.$translate.instant('TR_270720-215a0c_TR'),
                    objectType: 'SmtpForwarder'
                });
                break;

            case 'msexchange-mailboxes':
                this.emailWizardConfirmation.useOrchestrationService = false;

                // tslint:disable-next-line: max-line-length
                if ([undefined, null].indexOf(this._apiProductObject.apiObject.mailboxCreate.mailbox.organizationId) < 0) {
                    const mailboxCreateObject: WizardCreateObject = {
                        callback: () => {
                            return this.mailboxModel.createMailbox(
                                this._apiProductObject.apiObject.mailboxCreate.mailbox,
                                this._apiProductObject.apiObject.mailboxCreate.password,
                                this._apiProductObject.apiObject.mailboxCreate.accountId,
                                undefined,
                                this._apiProductObject.apiObject.autoconfigureDns,
                                this._apiProductObject.apiObject.skipDnsCheck
                            );
                        },
                        labelText: this.$translate.instant('TR_081020-c8a237_TR'),
                        objectType: 'ExchangeMailbox'
                    };
                    this.wizardCreateObjectList.push(mailboxCreateObject);
                } else {
                    const organizationCreateObject: WizardCreateObject = {
                        callback: () => {
                            return this.mailboxModel.createOrganization(
                                this._apiProductObject.apiObject.organizationCreate.organization,
                                this._apiProductObject.apiObject.organizationCreate.accountId
                            );
                        },
                        children: [
                            {
                                callback: (parentResponse) => {
                                    this._apiProductObject.apiObject.mailboxCreate.mailbox.organizationId =
                                        parentResponse.response.id;
                                    return this.mailboxModel.createMailbox(
                                        this._apiProductObject.apiObject.mailboxCreate.mailbox,
                                        this._apiProductObject.apiObject.mailboxCreate.password,
                                        this._apiProductObject.apiObject.mailboxCreate.accountId,
                                        undefined,
                                        this._apiProductObject.apiObject.autoconfigureDns,
                                this._apiProductObject.apiObject.skipDnsCheck
                                    );
                                },
                                labelText: this.$translate.instant('TR_081020-c8a237_TR'),
                                objectType: 'ExchangeMailbox'
                            }
                        ],
                        labelText: this.$translate.instant('TR_081020-b5b9f3_TR'),
                        objectType: 'Organization'
                    };
                    this.wizardCreateObjectList.push(organizationCreateObject);
                }
                break;

            case 'external-forwarders':
            case 'internal-forwarders':
                this.emailWizardConfirmation.useOrchestrationService = false;
                this.wizardCreateObjectList.push({
                    callback:
                        () => {
                            return this.mailboxModel.createMailbox(
                                this._apiProductObject.apiObject.mailbox,
                                undefined,
                                undefined,
                                undefined,
                                this._apiProductObject.apiObject.autoconfigureDns,
                                this._apiProductObject.apiObject.skipDnsCheck
                            );
                        },
                    children: [],
                    labelText: this.$translate.instant('TR_140720-4789be_TR'),
                    objectType: 'Forwarder'
                });
                break;

            default:
                throw new Error(`${this._apiProductObject.productFamily} not yet implemented!`);
        }

        this.isLoading = false;
    }
}

export class OrganismEmailWizardConfirmViewComponent implements ng.IComponentOptions {
    public bindings = {
        _apiProductObject: '<apiProductObject'
    };

    public template = require('./email-wizard-confirm-view.html');
    public controller = OrganismEmailWizardConfirmViewController;
}
