import * as ng from 'angular';
import { AlertManagerService } from '../../../../services';
import { OrganismEditFormDatabaseController } from '../../../organisms/forms/edit-forms/database-edit/database-edit';
import {
    EditFormMessage,
    EditFormSavingStatus,
    EditFormStatus,
    EditPanelStatus,
    MoleculeFormEditController,
    PanelType
} from '../../forms/form-edit/form-edit';

type Validator = () => true|any;

export class MoleculePanelDatabaseWipeController implements ng.IController {
    public static $inject: string[] = ['$translate', 'alertManager'];

    // Bindings
    public name: string;
    public panelIcon: string;
    public changesAffectPayment: boolean;
    public agreements = false;
    public readonly type = PanelType.DATABASE_WIPE;

    // Required parent controller
    public $editForm: MoleculeFormEditController;
    public $editFormOrganism: OrganismEditFormDatabaseController;

    public $validators: { [key: string]: Validator } = {};

    private status: EditPanelStatus = EditPanelStatus.READONLY;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService
    ) {}

    public $onInit = () => {
        this.$editForm.register(this);
    };

    public $onDestroy = () => {
        this.$editForm.unregister(this);
    };

    public get deleteButtonModifier() {
        return this.agreements ? 'active' : 'inactive';
    }

    public onMessage = (message: EditFormMessage<any>) => {
        switch (message.type) {
            default: return;

            case 'statusUpdate':
                switch (message.message) {
                    default: return;

                    case EditFormSavingStatus.SAVING:
                        // Currently doesn't have an effect here,
                        // all logic using "saving" status already uses the form's status.
                        return;

                    case EditFormSavingStatus.ERROR:
                        // TODO: Use logic. Error status if there is an error in this box, editable otherwise.
                        if (this.status !== EditPanelStatus.READONLY) {
                            this.status = EditPanelStatus.ACTIVE;
                        }
                        return;

                    case EditFormSavingStatus.READY:
                        // After successfully saving, return all panels to readonly status.
                        this.status = EditPanelStatus.READONLY;
                        return;
                }
        }
    };

    public get panelTitle(): string {
        return 'Datenbank leeren';
    }

    /**
     * Returns true if all input elements within the panel are free of errors,
     * false otherwise.
     * Warning: potentially slow, because it has to check ALL input fields!
     */
    public get $valid() {
        return Object.keys(this.$validators)
        .every((key) => this.$validators[key]() === true);
    }

    /**
     * Returns true if some input element within the panel has an error,
     * false otherwise.
     * Faster than $valid if there are errors, because the check is finished as soon as
     * the first error is found.
     */
    public get $invalid() {
        return Object.keys(this.$validators)
        .some((key) => this.$validators[key]() !== true);
    }

    /**
     * Returns an object containing all errors found within the panel.
     * Always an object, but it may be empty if no errors are found.
     */
    public get $errors() {
        const errors = {};
        for (const key of Object.keys(this.$validators)) {
            const result = this.$validators[key]();
            if (result !== true) {
                errors[key] = result;
            }
        }
        return errors;
    }

    public activate = () => {
        this.status = EditPanelStatus.ACTIVE;
    };

    public delete = () => {
        if (this.$editForm.$invalid) {
            return;
        }

        this.$editFormOrganism.databaseWipe().then(
            (res) => {
                // This is a workaround. Form edit panel status is not set on this component
                // TODO: We have to extract form edit panel functionality in a global service to use anywhere
                this.status = EditPanelStatus.READONLY;
                this.alertManager.success(this.$translate.instant('TR_140119-9923ba_TR'));
            }
        );
    };

    public cancel = () => {
        this.status = EditPanelStatus.READONLY;
        this.$editFormOrganism.cancelWipe();
    };

    public successfullySaved = () => {
        this.status = EditPanelStatus.READONLY;
    };

    public errorWhileSaving = () => {
        this.status = EditPanelStatus.ACTIVE;
    };

    public get isEditable() {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showCancelButton() {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0

        || this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get showEditButton() {
        return [
            EditPanelStatus.READONLY
        ].indexOf(this.status) >= 0;
    }

    public get showSaveButton() {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0

        || this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get showEditableContent() {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0

        || this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get showReadonlyContent() {
        return [
            EditPanelStatus.READONLY
        ].indexOf(this.status) >= 0;
    }

    public get showErrorContent() {
        return [
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showSavingContent() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get saveButtonLoading() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get disableCancelButton() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get disableEditButton() {
        return !this.$editForm.canEnablePanel(this.name);
    }

    public get disableSaveButton() {
        return this.$editForm.$invalid
            || (this.$editForm.$status === EditFormStatus.SAVING)
            || (this.agreements !== true);
    }

    public get disableInputElements() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }
}

export class MoleculePanelDatabaseWipeComponent implements ng.IComponentOptions {
    public bindings = {
        agreements: '<',
        changesAffectPayment: '<paid',
        name: '@',
        panelIcon: '@icon',
        panelRight: '<'
    };
    public require = {
        $editForm: '^moleculeFormEdit',
        $editFormOrganism: '^organismEditFormDatabase'
    };
    public transclude = {
        editable: 'panelContentEditable',
        error: '?panelContentError',
        readonly: 'panelContentReadonly',
        saving: '?panelContentSaving'
    };
    public controller =  MoleculePanelDatabaseWipeController;
    public controllerAs = '$deletePanel';
    public template = require('./panel-database-wipe.html');
}
