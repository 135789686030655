import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { WizardObject } from '../../../atomic-components/molecules/wizard/wizard-supervisor/wizard-supervisor';

export class TemplateWebspaceNewController {
    public static $inject: string[] = ['$state', '$translate'];

    public profiles;
    public machine;
    public pool;
    public webserverRessource;

    public pageHeaderData: PanelHeaderData;

    public serviceObjects: WizardObject[] = [];

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        const stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';

        const panelHeaderRouteParams = stateInMachine ? { machineId: this.machine.id} : null;
        const panelHeaderBackwardLink = stateInMachine ? 'managed-servers.id.dashboard' : 'webhosting.dashboard';
        const panelHeaderBackwardText = stateInMachine
            ? this.$translate.instant('TR_220319-1ac2e3_TR')
            : this.$translate.instant('TR_140119-285630_TR');

        this.pageHeaderData = {
            backwardLink: panelHeaderBackwardLink,
            backwardText: panelHeaderBackwardText,
            panelHeaderRoute: '',
            panelHeaderRouteParams: panelHeaderRouteParams,
            panelIcon: 'server',
            panelTitle: this.$translate.instant('TR_140119-4563cf_TR')
        };

        this.serviceObjects = [{
                active: true,
                children: [],
                completed: false,
                display: {
                    inMachine: stateInMachine,
                    machine: [undefined, null].indexOf(this.machine) < 0
                    ? this.machine
                    : null,
                    pool: [undefined, null].indexOf(this.pool) < 0
                        ? this.pool
                        : null,
                    productFamilySelectTitle: this.$translate.instant('TR_090119-8c1394_TR'),
                    profiles: this.profiles,
                    showFamiliesWithSingleProduct: false
                },
                installFunction: null,
                productFamilies: ['webspaces'],
                selectedProduct: null,
                service: 'webhosting',
                settings: {
                    selectedContingent: [undefined, null].indexOf(this.machine)
                        ? {
                            name: this.machine.name,
                            poolId: this.pool.id,
                            type: 'pool',
                            value: this.webserverRessource.id
                        }
                        : null
                }
        }];
    }
}

export class TemplateWebspaceNewComponent implements ng.IComponentOptions {
    public bindings = {
        machine: '<?',
        pool: '<?',
        profiles: '<',
        webserverRessource: '<?'
    };
    public controller = TemplateWebspaceNewController;
    public template = require('./webspace-new-template.html');
}
