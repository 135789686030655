import * as ng from 'angular';
import * as Types from '../../../../../types';

export class OrganismEditFormNextcloudOrderBackupController {
    public nextcloud: Types.ManagedApplicationApi.Nextcloud;
}

export class OrganismEditFormNextcloudOrderBackupComponent implements ng.IComponentOptions {
    public bindings = {
        nextcloud: '<'
    };

    public controller = OrganismEditFormNextcloudOrderBackupController;
    public template = require('./nextcloud-order-backup.html');
}
