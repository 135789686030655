import * as ng from 'angular';

import './pool-details-modal.scss';

export class OrganViewPanelPoolDetailsModalController implements ng.IController {
    public displayPanel: boolean;
    public pool: any = {};
    public resources: any = { DB: [], WH: [] };

    public panelVisible = () => {
        return this.displayPanel;
    };

    public hidePanel = () => {
        this.displayPanel = false;
    };
}

export class OrganViewPanelPoolDetailsModalComponent implements ng.IComponentOptions {
    public bindings = {
        displayPanel: '=',
        pool: '<',
        resources: '<'
    };
    public controller = OrganViewPanelPoolDetailsModalController;
    public template = require('./pool-details-modal.html');
}
