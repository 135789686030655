import { WebhostingUserModelService } from '../../../../services';
import { WebspaceAccessObject, WebspaceUserObject, WizardObject } from '../../../molecules';

import * as ng from 'angular';

export class OrganWizardWebspaceAccessesController implements ng.IController {
    public static $inject: string[] = [];

    public serviceObject: WizardObject;
    public accountId: string;
    public isValid = false;

    public $onInit() {
        if (this.serviceObject.display.accessType === undefined) {
            this.serviceObject.display.accessType = null;
        }

        if (this.serviceObject.settings.access === undefined) {
            this.serviceObject.settings.access = {};
        }
    }

    public $onChanges(changes) {
        if (changes.accountId !== undefined) {
            this.serviceObject.settings.choosenAccessId = undefined;
        }
    }

    public get complete() {
        switch (this.serviceObject.display.accessType) {
            case '1':
                return [undefined, null, ''].indexOf(this.serviceObject.settings.choosenAccessId) === -1
                && this.isValid;
            case '2':
                return this.isValid;
            default:
                break;
        }
    }

    /* tslint:disable-next-line:no-empty */
    public set complete({}) {}
}

export class OrganWizardWebspaceAccessesComponent implements ng.IComponentOptions {
    public bindings = {
        accountId: '<',
        complete: '=',
        serviceObject: '='
    };
    public controller =  OrganWizardWebspaceAccessesController;
    public template = require('./accesses.html');
}
