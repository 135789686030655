/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-function */
import * as ng from 'angular';

import debounce from 'lodash.debounce';

import { WizardNewHelperService } from '@/services/helpers/wizard-helper';
import { ViewTypes } from '@/types/view-types';

export class OrganismEmailWizardProductConfigForwarderController {
    public static $inject: string[] = [
        '$scope',
        '$translate',
        'wizardNewHelper'
    ];

    public metadata: ViewTypes.ProductConfigExternalForwarderObject;

    public selectedProductCode: string = null;
    public vhostValidationErrors: string[] = []; // used in template
    public service = 'email';

    public lastDomainSearchString: string;
    public currentForwarderTarget: string;
    public emailValidationErrorList: any[] = [];
    public forwarderListErrors: any[] = [];
    public showPleaseSelectTheCorrectProduct = false;
    public correctBundleId: string;
    public maxStorage: number;
    public spamFilter: any;
    public dnsValidationMessage: string;
    public selectTheCorrectProductTranslation: string;

    public emailAddressInputChanged;

    public formCompleted = {
        emailAddress: () => [undefined, null, ''].indexOf(this.metadata.mailbox.emailAddress) < 0
            && this.emailValidationErrorList.length === 0,

        forwardTargets: () => this.metadata.mailbox.forwarderTargets?.length > 0
            && (this.forwarderListErrors.length === 0
                || this.forwarderListErrors.every((errorArray) => errorArray.length === 0))
    };

    constructor(
        private $scope: ng.IScope,
        private $translate: ng.translate.ITranslateService,
        private wizardNewHelper: WizardNewHelperService
    ) {}

    public set configCompleted({}) {} // used by parent
    public get configCompleted(): boolean { // used by parent
        for (const part in this.formCompleted) {
            if (!(this.formCompleted as Record<string, () => boolean>)[part]()) {
                return false;
            }
        }

        return true;
    }

    public $onInit = (): void => {
        this.metadata.mailbox.emailAddress = '';
        this.metadata.mailbox.forwarderTargets = [];
        this.metadata.spamProfile = {
            spamLevel: 'medium'
        };
        this.$scope.$watch(
            () => this.metadata?.contingent?.id,
            () => this.checkForCorrectBundle(),
            true
        );
        this.emailAddressInputChanged = debounce(this.checkForCorrectBundle, 500);
    };

    public get showRedirects(): boolean {
        return this.formCompleted.emailAddress();
    }

    public get showSpamProtection(): boolean {
        return this.showRedirects;
    }

    public checkForCorrectBundle = (): void => {
        void this.wizardNewHelper.checkDomainCorrectBundle(this.metadata, this.correctBundleId)
            .then((result) => {
                this.showPleaseSelectTheCorrectProduct = result.showPleaseSelectTheCorrectProduct;
                this.selectTheCorrectProductTranslation = this.$translate
                    .instant(result.translation, { bundleName: result.bundleName });
                this.correctBundleId = result.correctBundleId;
            });
    };
}

export class OrganismEmailWizardProductConfigForwarderComponent implements ng.IComponentOptions {
    public bindings = {
        configCompleted: '=configCompleted',
        metadata: '='
    };

    public template = require('./email-wizard-product-config-forwarder.html');
    public controller = OrganismEmailWizardProductConfigForwarderController;
}
