import * as ng from 'angular';

import { LongDebounceInterval } from '@/configuration';

import {
    EditFormMessage,
    EditFormSavingStatus,
    EditFormStatus,
    EditPanelStatus,
    MoleculeFormEditController,
    PanelType
} from '../../../forms/form-edit/form-edit';
import { Validator } from '../panel-edit';

import './panel-edit-table.scss';

export class MoleculePanelEditTableController implements ng.IController {
    public static $inject: string[] = ['$sce'];

    public cancelCallback: () => void;
    public changesAffectPayment: boolean;
    public name: string;
    public panelIcon: string;
    public panelTitle: string;
    public panelRight: PanelType = PanelType.COST_NEUTRAL;
    public saveCallback: () => void;
    public typeOverride: PanelType;
    public createNewObject: boolean;
    public $editForm: MoleculeFormEditController;
    public $validators: { [key: string]: Validator } = {};
    public status: EditPanelStatus = EditPanelStatus.READONLY;
    public addButtonText: string;
    public floatingTable: boolean;
    public longDebounceInterval = LongDebounceInterval;

    // Set switch status, if edit button is clicked and open edit view.
    // With this parameter (if true) you can see in the row molecule panel or in layers below the panel edit component
    // Whether the data has been reset (for example) by the original data (cancel button)
    // This parameter must be reset in the lower controller (controller of Template Molecule) if the data has been reset
    // from the original data
    public resetDataFromOriginalData: boolean;

    private _disableAddButton: boolean;
    private _disableSaveButton: boolean;
    private addCallback: () => void | undefined;
    private hideEditButton: boolean;
    private hideSaveButton: boolean;
    private hideAddButton: boolean;
    private showAlwaysAddButton: boolean;
    private searchString: string;
    private informationText: string;
    private editOnInit: boolean;

    constructor(
        private $sce: ng.ISCEService
    ) {}

    public $onInit = () => {
        if (this.editOnInit) {
            this.activate();
        }
        this.createNewObject = false;
        // hide buttons flags
        this.hideEditButton = this.hideEditButton || false;
        this.hideSaveButton = this.hideSaveButton || false;
        this.hideAddButton = this.hideAddButton || false;
        // show always button flags
        this.showAlwaysAddButton = this.showAlwaysAddButton || false;
        // disabled button flags
        this._disableAddButton = this._disableAddButton || false;
        this.$editForm.register(this);
    };

    public $onDestroy = () => {
        this.$editForm.unregister(this);
    };

    public get type(): PanelType {
        if ([undefined, null].indexOf(this.typeOverride) < 0) {
            return this.typeOverride;
        }
        return this.changesAffectPayment ? PanelType.COST_EFFECTIVE : PanelType.COST_NEUTRAL;
    }

    public trustAsHtml() {
        if (this.informationText !== undefined) {
            return this.$sce.trustAsHtml(this.informationText);
        }
        return '';
    }

    public onMessage = (message: EditFormMessage<any>) => {
        switch (message.type) {
            default: return;

            case 'statusUpdate':
                switch (message.message) {
                    default: return;

                    case EditFormSavingStatus.SAVING:
                        // Currently doesn't have an effect here,
                        // all logic using "saving" status already uses the form's status.
                        return;

                    case EditFormSavingStatus.ERROR:
                        // TODO: Use logic. Error status if there is an error in this box, editable otherwise.
                        if (this.status !== EditPanelStatus.READONLY) {
                            this.status = EditPanelStatus.ACTIVE;
                        }
                        return;

                    case EditFormSavingStatus.READY:
                        // After successfully saving, return all panels to readonly status.
                        this.status = EditPanelStatus.READONLY;
                        return;
                }
        }
    };

    public activate = () => {
        this.status = EditPanelStatus.ACTIVE;
        // Set flag for lower controller, to reset data from original data
        this.resetDataFromOriginalData = true;
    };

    public openCreate = () => {
        if (this.addCallback !== undefined) {
            this.addCallback();
        }
        this.createNewObject = true;
    };

    public closeCreate = () => {
        this.createNewObject = false;
    };

    public create = () => {
        this.status = EditPanelStatus.READONLY;
    };

    public cancel = () => {
        this.status = EditPanelStatus.READONLY;
        // Fallback, if this parameter is not used and reset in lower controller
        this.resetDataFromOriginalData = false;
        this.cancelCallback();
    };

    public get saveButtonLoading() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public save = () => {
        if (this.$editForm.$invalid || this.hideSaveButton) {
            return;
        }

        if (this.saveCallback) {
            const oldCallback = ng.copy(this.$editForm.saveCallback);
            this.$editForm.saveCallback = (this.saveCallback as any);
            this.$editForm.save();
            this.$editForm.saveCallback = oldCallback;
        } else {
            this.$editForm.save();
        }
    };

    public get showEditButton() {
        return [
            EditPanelStatus.READONLY
        ].indexOf(this.status) >= 0
        && !this.hideEditButton;
    }

    public get showCancelButton() {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showSaveButton() {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0
        && !this.hideSaveButton;
    }

    public get showAddButton() {
        return ([
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0
        && !this.hideAddButton)
        || this.showAlwaysAddButton;
    }

    public get showReadonlyContent() {
        return [
            EditPanelStatus.READONLY
        ].indexOf(this.status) >= 0;
    }

    public get showEditableContent() {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get disableSaveButton() {
        return this._disableSaveButton || this.$editForm.$invalid || this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get disableCancelButton() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get addButtonTextDefined() {
        return this.addButtonText !== undefined;
    }

    public get disableEditButton() {
        return (this._disableAddButton || !this.$editForm.canEnablePanel(this.name));
    }

    public get disableAddButton() {
        if ([
                'nextcloud-group-folders',
                'database-users',
                'nextcloud-users',
                'nextcloud-groups'
            ].indexOf(this.name) >= 0) {
            // Unfavorable hack for database/nextcloud-users and nextcloud group folders
            return this._disableAddButton;
        }
        return (this._disableAddButton || !this.$editForm.canEnablePanel(this.name));
    }
}

export class MoleculePanelEditTableComponent implements ng.IComponentOptions {
    public bindings = {
        _disableAddButton: '<disableAddButton',
        _disableSaveButton: '<disableSaveButton',
        addButtonText: '@',
        addCallback: '<?',
        cancelCallback: '<cancel',
        changesAffectPayment: '<paid',
        editOnInit: '<?',
        floatingTable: '<',
        hideAddButton: '<',
        hideEditButton: '<',
        hideSaveButton: '<',
        informationText: '<',
        name: '@',
        panelIcon: '@icon',
        panelRight: '<',
        panelTitle: '@title',
        saveCallback: '<?',
        showAlwaysAddButton: '<',
        searchString: '=',
        typeOverride: '<type'
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public transclude = {
        buttons: '?panelButtons',
        content: 'panelContent'
    };
    public controller =  MoleculePanelEditTableController;
    public controllerAs = '$editPanelTable';
    public template = require('./panel-edit-table.html');
}
