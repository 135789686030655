import * as ng from 'angular';
import { IStorageItem, MoleculeFormEditController } from '../../../forms';
import './panel-edit-row-storage-quota-changer.scss';

export class MoleculePanelEditRowStorageQuotaChangerController implements ng.IController {
    public static $inject: string[] = ['$timeout', '$translate'];

    public $editForm: MoleculeFormEditController;
    public max: number;
    public min: number;
    public step: number;
    public unit: string;
    public used: number;
    public model: number;
    public oldTotal: number;
    public additionalStorageItems: number;
    public customConfirmationText: string;
    public _orderConfirmed: boolean;
    public productCode: string;
    public productType: string;
    public storageItem: IStorageItem;
    public onChange: (value: unknown) => {};
    public inPool = false;
    public hideUsedSpace = false;
    public skipConfirmation: boolean;

    private registrationIndex: number;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = (): void => {
        this._orderConfirmed = false;
        this.registrationIndex = this.$editForm.registerValidator(this);
        this.$editForm.validatorStatus[this.registrationIndex] = true;
    };

    public get confirmationText(): string {
        if (this.customConfirmationText) {
            return this.customConfirmationText;
        }

        return this.$translate.instant('ce187a0f-8047-41aa-adbd-8e0bf0ba11bc');
    }

    public get orderConfirmed(): boolean {
        if ([undefined, null, false].indexOf(this.skipConfirmation) < 0) {
            return true;
        }

        return this._orderConfirmed;
    }

    public set orderConfirmed(value: boolean) {
        this._orderConfirmed = value;
        this.setValidationStatus();
    }

    public $onDestroy = (): void => {
        this.$editForm.unregisterValidator(this.registrationIndex);
        this.$editForm.validatorStatus[this.registrationIndex] = true;
    };

    public onChangeCallback = (): void => {
        this.setValidationStatus();

        if (this.$editForm.validatorStatus[this.registrationIndex] && this.onChange !== undefined) {
            void this.$timeout(() => {
                this.onChange(this.model);
            });
        }
    };

    public toggle = (): void => {
        this.orderConfirmed = !this.orderConfirmed;
        this.onChangeCallback();
    };

    private setValidationStatus = () => {
        void this.$timeout(
            () => {
                this.$editForm.validatorStatus[this.registrationIndex] = (this.model <= this.oldTotal)
                || this.inPool
                || ([undefined, null, false].indexOf(this.skipConfirmation) < 0)
                || this._orderConfirmed;
            }
        );
    };
}

export class MoleculePanelEditRowStorageQuotaChangerComponent implements ng.IComponentOptions {
    public transclude = {
        label: 'rowLabel'
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public bindings = {
        additionalStorageItems: '=',
        customConfirmationText: '@?confirmationText',
        hideUsedSpace: '<?',
        inPool: '<',
        max: '<',
        min: '<',
        model: '=?',
        oldTotal: '<',
        onChange: '<',
        orderConfirmed: '=?',
        productCode: '<',
        productType: '@',
        skipConfirmation: '<',
        step: '=?',
        storageItem: '=',
        unit: '@',
        used: '<'
    };
    public controller = MoleculePanelEditRowStorageQuotaChangerController;
    public controllerAs = '$panelRow';
    public template = require('./panel-edit-row-storage-quota-changer.html');
}
