
import { AuthContextService } from '@/services/auth-context';
import { RouteFinderService } from '@/services/route-finder';
import ng from 'angular';

/*
 * Map files property of input type file to ng-model directive if file selection changed
 */
export class LinkPermissionsDirective implements ng.IDirective {
    public restrict = 'E';
    public static Factory(): any {
        const directive = (
            $translate: ng.translate.ITranslateService,
            routeFinder: RouteFinderService,
        ) => new LinkPermissionsDirective($translate, routeFinder);

        directive.$inject = ['$translate', 'routeFinder'] as string[];

        return directive;
    }

    constructor(
        private $translate: ng.translate.ITranslateService,
        private routeFinder: RouteFinderService,
    ) {}

    public link: ng.IDirectiveLinkFn<ng.IScope, JQLite, ng.IAttributes, ng.IController> = (scope, link, attr) => {
        if (!AuthContextService.authenticated) {
            return;
        }
        if (!AuthContextService.isRootOrCompanyAccount) {
            return;
        }
        if ([undefined, null, ''].includes(attr.href)) {
            return;
        }
        const elements: JQLite = ng.element(document.createElement('div'));
        const { optionalRights, requiredRights } = this.routeFinder.getPermissionsTable(attr.href);
        const optionalRightsElement = ng.element(document.createElement('div'));
        optionalRightsElement.prepend(`${this.$translate.instant('TR_290322-dadb86_TR')}: \n`);
        if (optionalRights.length > 0) {
            optionalRightsElement.append(optionalRights.join('\n'));
        } else {
            optionalRightsElement.append('---');
        }
        elements.append(optionalRightsElement);
        const requiredRightsElement = ng.element(document.createElement('div'));
        requiredRightsElement.prepend(`${this.$translate.instant('TR_290322-eecda0_TR')}: \n`);
        if (requiredRights.length > 0) {
            requiredRightsElement.append(requiredRights.join('\n'));
        } else {
            requiredRightsElement.append('---');
        }
        elements.append(requiredRightsElement);

        [
            'absolute',
            'hidden',
            'z-lightbox',
        ].forEach(cssClass => elements.addClass(cssClass))

        const subElements = [requiredRightsElement, optionalRightsElement];
        subElements.forEach(element => {
            [
                'bg-gray-100',
                'text-mib',
                'p-1',
                'border',
                'border-gray-400',
                'whitespace-pre',
            ].forEach(cssclass => {
                element.addClass(cssclass);
            });
            elements.append(element);
        });
        link.append(elements);

        link.bind('mouseenter', (event) => {
            if (!event.altKey) {
                return;
            }
            elements.removeClass('hidden');
        });
        link.bind('mouseleave', (event) => {
            elements.addClass('hidden');
        });
    }
}
