import { RestrictionHelperService } from '../../../../../services';

import * as ng from 'angular';

export class OrganismEditFormRestrictionsController {
    public apiObject;
    public restrictionHelper: RestrictionHelperService;
    public showObjectId: boolean;
}

export class OrganismEditFormRestrictionsComponent implements ng.IComponentOptions {
    public bindings = {
        apiObject: '<',
        restrictionHelper: '<',
        showObjectId: '<'
    };

    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormRestrictionsController;
    public template = require('./restrictions-edit.html');
}
