import { UiRights } from '../../../../configuration';
import { Breadcrumb, BreadcrumbItem } from '../breadcrumb';
import { BreadcrumbTopicModel, TopicCases } from './topic-model';

export class BreadcrumbDatabaseTopicModel extends BreadcrumbTopicModel {
    public static $inject: string[] = BreadcrumbTopicModel.$inject.concat(['breadcrumbDomainTopicModel']);
    public readonly topicName = 'database';
    public readonly specialTopicCases: TopicCases;

    /**
     *   List of route parts, which are excluded from mail class generalRoutes methode
     */
    public readonly excludeGeneralRouteParts = ['overview', 'edit', 'new'];

    private databaseActionDropdownItems: BreadcrumbItem[] = [];

    constructor(protected $state: ng.ui.IStateService, protected $translate: ng.translate.ITranslateService) {
        super($state, $translate);

        this.databaseActionDropdownItems = [
            { part: 'overview', route: 'database.databases.overview', isGranted: UiRights.DB_DATABASE_LIST },
            { part: 'jobs', route: 'database.jobs', isGranted: UiRights.DB_JOBS_LIST },
            { part: 'new', route: 'database.databases.new', isGranted: UiRights.DB_DATABASE_CREATE },
            {
                part: 'users',
                route: 'database.users.overview',
                name: 'Datenbank Zugänge',
                isGranted: UiRights.DB_USER_LIST
            }
        ];

        this.specialTopicCases = {
            details: this.jobDetailsRoute,
            edit: this.getEditRoute,
            id: this.getIdRoute,
            jobs: () => this.getProductActionDropdown(this.databaseActionDropdownItems),
            new: () => this.getProductActionDropdown(this.databaseActionDropdownItems),
            overview: this.getOverviewsRoute,
            users: () => this.getProductActionDropdown(this.databaseActionDropdownItems)
        };
    }

    public getIdRoute = (): Breadcrumb[] => {
        let breadcrumbs = [];

        if (this.steps[this.index + 1] === 'restrictions') {
            breadcrumbs = this.getProductActionDropdown(this.databaseActionDropdownItems, {}, 'overview');
            breadcrumbs.push({
                active: false,
                isGranted: UiRights.DB_DATABASE_EDIT,
                route: 'database.databases.id.edit',
                routeParams: { databaseId: this.globals.database.id },
                type: 'text',
                value: this.getActiveDropdownValue({ part: 'edit' })
            });
            return breadcrumbs;
        }
    };

    public getFirstLevelData = (): Breadcrumb[] => {
        this.breadcrumbs.push({
            active: false,
            type: 'text',
            value: this.$translate.instant('MENU.MAIN.PRODUCTS.LABEL')
        });

        let route = 'database.dashboard';
        let routeParams = {};
        const isGranted = UiRights.DB_DATABASE_LIST;

        if (this.inBundle()) {
            route = 'bundle.id.databases.dashboard';
            routeParams = { bundleId: this.globals.bundle.id };
        }

        this.breadcrumbs = this.breadcrumbs.concat(
            this.getServiceListDropdownBox({
                active: this.steps.length === 0,
                isGranted: isGranted,
                name: this.$translate.instant('TR_090119-f0ccfb_TR'),
                route: route,
                routeParams: routeParams,
                step: 'database'
            })
        );

        return this.breadcrumbs;
    };

    public getOverviewsRoute = (): Breadcrumb[] => {
        switch (this.steps[this.index - 1]) {
            case 'databases':
                return this.getProductActionDropdown(this.databaseActionDropdownItems, UiRights.DB_DATABASE_LIST);
            default:
                break;
        }
    };

    public getEditRoute = (): Breadcrumb[] => {
        let breadcrumbs: Breadcrumb[] = [];
        let route = '';
        let routeParams = {};

        if (this.steps[this.index - 2] === 'databases') {
            route = 'database.databases.id.edit';
            routeParams = { databaseId: this.globals.database.id };
            breadcrumbs = this.getProductActionDropdown(this.databaseActionDropdownItems, routeParams, 'overview');
        }

        breadcrumbs.push({
            active: true,
            isGranted: UiRights.DB_DATABASE_EDIT,
            route: route,
            routeParams: routeParams,
            type: 'text',
            value: this.getActiveDropdownValue({ part: 'edit' })
        });
        return breadcrumbs;
    };
}
