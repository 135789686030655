import ng from 'angular';

import { UiRights } from '@/configuration';
import {
    AlertManagerService, ApiKeyModelService, AuthContextService, NavigationService
} from '@/services';
import * as Types from '@/types';
import { EditPanelStatus } from '@/atomic-components/molecules';

export class OrganismEditFormApiKeyController {
    public static $inject: string[] = [
        '$state',
        '$translate',
        'alertManager',
        'apiKeyModel',
        'navigation'
    ];

    public userPanelRight: any;
    public initialStatus = EditPanelStatus.ACTIVE;
    public allRights: any[];
    public userData: Types.AccountApi.User;
    public rightsInTemplates: any[];
    public panelRightSettings: any;
    public apiKeyObject: any;
    public disableSaveButton = false;

    public hasAgreedToDeleteKey = false;

    private originalApiKeyObject: string;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiKeyModel: ApiKeyModelService,
        private navigation: NavigationService
    ) {}

    public $onInit(): void {
        this.originalApiKeyObject = JSON.stringify(this.apiKeyObject);

        this.panelRightSettings = {
            editPanelButton: this._isOwnUser
                ? AuthContextService.isGranted(UiRights.ACC_ACCOUNT_APIKEYS_MANAGE)
                : AuthContextService.isGranted(UiRights.ACC_SUBACCOUNT_APIKEYS_MANAGE)
        };
        this.apiKeyObject.allowedIps = this._joinArray(this.apiKeyObject.allowedIps);
    }

    public cancel = () => {
        this.apiKeyObject = JSON.parse(this.originalApiKeyObject);

        this.apiKeyObject.allowedIps = this._joinArray(this.apiKeyObject.allowedIps);
    };

    public cancelDelete = () => {
        this.hasAgreedToDeleteKey = false;
    };

    public save = () => {
        this.apiKeyObject.allowedIps = this._splitArray(this.apiKeyObject.allowedIps);
        this.disableSaveButton = true;

        return this.apiKeyModel.update(this.apiKeyObject).then(
            (res) => {
                this.alertManager.success(this.$translate.instant('ACCOUNT.MESSAGE.API-KEY.EDIT.SUCCESS'));
                this.navigation.go(this.$state.current.name, {}, { reload: true });
                return Promise.reject(false);
            },
            (err) => {
                this.alertManager.error(this.$translate.instant('ACCOUNT.MESSAGE.API-KEY.EDIT.ERROR'));
                this.disableSaveButton = false;
                return Promise.reject('unable to save API key');
            }
        );
    };

    public delete = () => {
        this.apiKeyModel.delete([this.apiKeyObject])
        .then (
            () => {
                this.alertManager.success(this.$translate.instant('TR_060219-923f74_TR'));
                let route = 'reseller.subaccounts.id.users.id.edit';
                if (!(this.$state.current.name.split('.').includes('subaccounts'))) {
                    route = this._isOwnUser ? 'profile' : 'account.users.id.edit';
                }
                this.navigation.go(route);
            }
        );
    };

    private _joinArray = (ips: string[]) => {
        return ips.join('\n');
    };

    private _splitArray = (value) => {
        if (typeof value !== 'string') {
            return [];
        }

        if (value === undefined || value === '') {
            return [];
        }
        return value.split('\n');
    };

    private get _isOwnUser() {
        return AuthContextService.isOwnUser(this.userData);
    }
}

export class OrganismEditFormApiKeyComponent implements ng.IComponentOptions {
    public bindings = {
        allRights: '<',
        apiKeyObject: '<',
        rightsInTemplate: '<',
        userData: '<'
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormApiKeyController;
    public template = require('./api-key-edit.html');
}
