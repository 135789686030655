export class OrganViewPanelSslCertificateDetailController {
    public certificate: any;
}

export class OrganViewPanelSslCertificateDetailComponent implements ng.IComponentOptions {
    public bindings = {
        certificate: '<certificate'
    };
    public controller = OrganViewPanelSslCertificateDetailController;
    public controllerAs = '$viewPanelOrgan';
    public template = require('./ssl-certificate-detail-view.html');
}
