import * as ng from 'angular';
import { DdnsHostnameOverviewModel } from '../../../../services';

export class OrganPanelDdnsHostnameOverviewController implements ng.IController {
    public static $inject: string[] = [
        'ddnsHostnameOverviewModel'
    ];

    public overviewServiceData: any;
    public overviewUiSettings = { expertView: false, loadDataOnInit: true, paginationLimit: 10 };
    public userId: string;

    constructor(
        public overviewModel: DdnsHostnameOverviewModel
    ) {
        overviewModel.showMinimalVersion();
    }

    public $onInit() {
        this.overviewServiceData = {
            extendedFilters: null,
            objectId: this.userId,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: { value: '' }
        };
    }
}

export class OrganPanelDdnsHostnameOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        userId: '<'
    };
    public template = require('./ddns-hosts-overview.html');
    public controller = OrganPanelDdnsHostnameOverviewController;
}
