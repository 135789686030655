import * as ng from 'angular';
import { UiLanguagesConst } from '../configuration';
import { ApiErrorModel, AuthContextService, PriceModelService } from '../services';
import { ProductsModelService } from '../services/products';

export type TranslateProductCodeFilter = (productCode: string, owner?: string) => string;

export class TranslateProductCode {
    public static Factory(
        $translate: ng.translate.ITranslateService,
        apiErrorModel: ApiErrorModel,
        priceModel: PriceModelService,
        productsModel: ProductsModelService
    ) {
        // product not found Error is expected to occur here when a product is missing in ProductService (PUI-5509)
        apiErrorModel.ignoreErrorCodes.push('130003');
        const cache = {};
        const translateProductCodeFilter: any = (productCode: string, owner?: string) => {
            if (productCode === undefined) {
                return undefined;
            }

            if (cache[productCode]) {
                if (cache[productCode] !== 'waiting') {
                    return cache[productCode];
                }
                return undefined;
            }

            cache[productCode] = 'waiting';
            const language = UiLanguagesConst[AuthContextService.user.language];
            const response: string = [
                {
                    pattern: '^$',
                    translate: () => {
                        return $translate.instant('PRODUCTCODES.NONE');
                    }
                },
                {
                    pattern: '^domain\\-[a-z\.]+(\\-[0-9]+)?\\-(create|renew|transfer|update|close|restore)$',
                    // tslint:disable-next-line:no-shadowed-variable
                    translate: (productCode: string) => {
                        const parts = productCode.split('-');
                        const translationId = 'PRODUCTCODES.PATTERN.DOMAIN-' + parts[parts.length - 1].toUpperCase();
                        const tld = ' (.' + productCode.split('-')[1] + ')';
                        return $translate.instant(translationId) + tld;
                    }
                },
                {
                    pattern: '^domain\\-[a-z\.]+(\\-[0-9]+)?\\-(create|renew|transfer|restore)\\-premium$',
                    // tslint:disable-next-line:no-shadowed-variable
                    translate: (productCode: string) => {
                        const parts = productCode.split('-');
                        const translationId = 'PRODUCTCODES.PATTERN.DOMAIN-'
                            + parts[parts.length - 2].toUpperCase() + '-PREMIUM';
                        const tld = ' (.' + productCode.split('-')[1] + ')';
                        return $translate.instant(translationId) + tld;
                    }
                },
                {
                    pattern: '^domain\\-[a-z\.]+(\\-[0-9]+)?\\-owner\\-change$',
                    // tslint:disable-next-line:no-shadowed-variable
                    translate: (productCode: string) => {
                        const tld = ' (.' + productCode.split('-')[1] + ')';
                        return $translate.instant('PRODUCTCODES.PATTERN.DOMAIN-OWNER-CHANGE') + tld;
                    }
                },
                {
                    pattern: '^.*$',
                    // tslint:disable-next-line:no-shadowed-variable
                    translate: (productCode: string) => {
                        const translationId = 'PRODUCTCODES.SPECIFIC.' + productCode.toUpperCase();
                        return $translate.instant(translationId);
                    }
                }
            ]
            .map(
                (translator) => {
                    const regex = new RegExp(translator.pattern);
                    if (regex.test(productCode)) {
                        return translator.translate(productCode);
                    } else {
                        return undefined;
                    }
                }
            )
            .reduce(
                (previous, current) => {
                    return TranslateProductCode.empty(previous) ? current : previous;
                }
            );

            if (response === undefined || response.indexOf(productCode.toUpperCase()) >= 0) {
                if (productCode.startsWith('bundle-')) {
                    priceModel.getPriceByProductCode(productCode, owner).then(
                        (apiResponse) => {
                            if (apiResponse) {
                                cache[productCode] = apiResponse.name;
                            } else {
                                cache[productCode] = undefined;
                            }
                        }
                    );
                } else {
                    productsModel.findOne(productCode, language).then(
                        (apiResponse) => {
                            if (
                                [undefined, null, []].indexOf(apiResponse.response) >= 0
                                || [undefined, null].indexOf(apiResponse.response.name) >= 0
                            ) {
                                cache[productCode] = undefined;
                            } else {
                                cache[productCode] = apiResponse.response.name;
                            }
                        }
                    );
                }
            } else {
                cache[productCode] = response;
            }
        };

        translateProductCodeFilter.$stateful = true;
        return translateProductCodeFilter;
    }

    private static empty(value: string) {
        return [undefined, null, '', [], {}].indexOf(value) >= 0;
    }
}
