/* eslint-disable */

import * as ng from 'angular';
import { CsrDecoderService } from '../../../../../services';

export class OrganEditPanelSslCertificateReissueController {
    public static $inject: string[] = ['$q', '$timeout', 'csrDecoder'];

    public $editFormOrganism;
    public panelRight;
    public cancelCallback: () => void;
    public reissueConfirm = false;
    public changeCsr = false;

    public csr = '';

    private meta = {
        csr: {
            valid: false,
            decodeFailed: false,
            decodedData: null,
            isWildcard: false
        },
        emailCheckActive: false
    };

    constructor(
        private $q: ng.IQService,
        private $timeout: ng.ITimeoutService,
        private csrDecoder: CsrDecoderService
    ) {}

    public get csrData() {
        return this.meta.csr.decodedData;
    }

    public get enableReissuing() {
        return this.reissueConfirm;
    }

    public cancel = () => {
        this.reissueConfirm = false;
        if ([undefined, null].indexOf(this.cancelCallback) === -1) {
            this.cancelCallback();
        }
    };

    public csrFailed = (error) => {
        this.meta.csr.decodedData = null;

        if (error.code === 50002) {
            this.meta.csr.valid = false;
        } else {
            this.meta.csr.decodeFailed = true;
        }
    };

    public csrSucceeded = (response) => {
        this.meta.csr.decodedData = response.csr;
        this.meta.csr.isWildcard = response.csr.domainName.startsWith('*.');

        return [];
    };

    public checkCsr = () => {
        this.$timeout(
            () => {
                this.meta.csr.valid = true;
                this.meta.csr.decodeFailed = false;

                if ([undefined, null, ''].indexOf(this.csr) >= 0) {
                    return;
                }

                this.csrDecoder.decode(this.csr)
                .then(
                    response => {
                        if (response.errors && response.errors[0]) {
                            return this.$q.reject(response.errors[0]);
                        }
                        return response;
                    }
                )
                .then(this.csrSucceeded, this.csrFailed);
            }
        );
    };
}

export class OrganEditPanelSslCertificateReissueComponent implements ng.IComponentOptions {
    public bindings = {
        csr: '=',
        cancelCallback: '<cancel',
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormSslCertificate'
    };
    public controller = OrganEditPanelSslCertificateReissueController;
    public controllerAs = '$editPanelOrgan';
    public template = require('./ssl-certificate-reissue.html');
}
