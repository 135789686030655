import * as ng from 'angular';

import { MachineApi } from '@/types/machine-api';
import { UiRights } from '../../../../configuration';
import { AuthContextService } from '../../../../services';
import * as Types from '../../../../types';
import './panel-service-info.scss';

export class MoleculePanelServiceInfoController implements ng.IController {
    public static $inject: string[] = ['$state'];

    public callbackUpdateMachineStatus: () => void;
    public checkingMachineStatus: boolean;
    public checkingForJobsInterval: ng.IPromise<any>;
    public dnsResolver = ['95.129.51.51', '80.244.244.244'];
    public isRoot = false;
    public hideSshCommand = true;
    public serviceObject: any;
    public service: string;
    public userHasRightResVmhostList = AuthContextService.isGranted(UiRights.RES_VMHOST_LIST); // used in template!
    public sshCommand: string;

    constructor(
        private $state: ng.ui.IStateService
    ) {}

    public $onInit() {
        this.service = this.service || '';

        if (AuthContextService.isRootOrCompanyAccount) {
            this.isRoot = true;
        }

        if (this.serviceObject?.accesses?.length > 0) {
            const sshUsers = this.serviceObject?.accesses?.filter((access) => access.sshAccess === true);

            if (sshUsers.length > 0) {
                this.sshCommand = `ssh ${sshUsers[0].userName}@${this.serviceObject.hostName} -p 2244`;
                this.hideSshCommand = false;
            }
        }
    }

    public checkForRunningMachineJobs = async () => {
        if (this.callbackUpdateMachineStatus) {
            await this.callbackUpdateMachineStatus();
        }
    };

    public get accountDefaults(): Types.AccountApi.AccountDefaults {
        return AuthContextService.accountDefaults;
    }

    public getTemplate = () => require('./panel-service-info-' + this.service + '.html?external');

    /* Used for machine info panel */
    public terminalAllowed = () => {
        const machine: Types.MachineApi.VirtualMachine =
        [undefined, null].indexOf(this.serviceObject.power) < 0 ? this.serviceObject : undefined;

        if (!machine) {
            return false;
        }

        return machine.power === 'on'
        && machine.status === 'active'
        && (
            machine.managementType === 'none'
            || AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN)
        );
    };

    /* Used for machine info panel */
    public openTerminal = ($event) => {
        const machine = [undefined, null].indexOf(this.serviceObject.power) < 0 ? this.serviceObject : undefined;
        if (!machine) {
            return false;
        }

        if ($event) {
            $event.stopPropagation();
        }

        window.open(
            this.$state.href('machine.virtualmachines.id.vnc', { machineId:machine.id }),
            `terminal_vm_${machine.id}`,
            'menubar=no,location=yes,resizable=no,scrollbars=no,status=yes,width=1024,height=928'
        );
    };

    /**
     * get publicNetworkInterface
     */
    public get publicNetworkInterface() {
        const publicInterface = this.serviceObject?.networkInterfaces?.find(
            (iface: MachineApi.NetworkInterface) => iface.networkType === 'publicNetwork'
        );

        return publicInterface === undefined ? this.serviceObject.networkInterfaces[0] : publicInterface;
    }

    public get state() {
        return this.$state.current.name;
    }
}

export class MoleculePanelServiceInfoComponent implements ng.IComponentOptions {
    public bindings = {
        callbackUpdateMachineStatus: '<?',
        checkingMachineStatus: '<?',
        machineStatusRefreshed: '<?',
        service: '@',
        serviceObject: '<'
    };
    public controller = MoleculePanelServiceInfoController;
    public controllerAs = '$panelServiceInfo';
    public template = require('./panel-service-info.html');
}
