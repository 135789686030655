import * as ng from 'angular';
import * as q from 'q';

import { UIRegExp } from '@/configuration';

import {
    AccountModelService, AlertManagerService, AuthContextService, NavigationService,
    RightModelService
} from '../../../../../services';
import * as Types from '../../../../../types';

export class OrganismEditFormSubAccountCreateController {
    public static $inject: string[] = [
        '$translate',
        'accountModel',
        'alertManager',
        'navigation',
        'rightModel'
    ];

    public newSubAccount: any;
    public rightsTemplates: any[];
    public rightsTemplatesDropdownItems: string|any[];
    public allRights: any[];
    public password: string;
    public newUser: any = {};
    public humanReadableEmails: boolean;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService,
        private alertManager: AlertManagerService,
        private navigation: NavigationService,
        private rightModel: RightModelService
    ) {}

    public $onInit() {
        this.newSubAccount = {
            parentAccountId: AuthContextService.account.id,
            rights: [],
            rightsTemplateId: ''
        };
        this.createRightsTemplatesDropdownItems(this.rightsTemplates);
    }

    public updateRightsTemplates = (parentAccountId: string) => {
        const filter: Types.Finding.Filter = {
            subFilter: [
                {
                    field: 'accountId',
                    value: parentAccountId
                },
                {
                    field: 'rightsTemplateUsableForSubAccounts',
                    value: '1'
                }
            ],
            subFilterConnective: 'AND'
        };

        this.rightModel.rightsTemplate(undefined, undefined, filter).then(
            (reply) => {
                if (!reply.data
                    .some((template: { id: any }) => {
                        return template.id === this.newUser.rightsTemplateId;
                    })
                ) {
                    this.newUser.rightsTemplateId = '';
                }
                this.createRightsTemplatesDropdownItems(reply.data);
            }
        );
    };

    public createAccount = () => {
        this.newSubAccount.messageSettings = {
            eventDeliveryMethods: [],
            eventDocumentFormat: '',
            eventHumanReadableEmailAddresses: this.humanReadableEmails
                ? this.newSubAccount.messageSettings.eventMachineReadableEmailAddresses
                : [],
            eventMachineReadableEmailAddresses: this.newSubAccount.messageSettings.eventMachineReadableEmailAddresses,
            eventWebhookUrl: '',
            messageTemplateBehavior: 'skipToRoot',
            receiveSubaccountEvents: this.newSubAccount.messageSettings.receiveSubaccountEvents
        };

        this.newSubAccount.resellerBillingSettings = {
            activePaymentMethods: [],
            availablePaymentMethods: [],
            endUserCancellationPolicy: '',
            payPalUser: '',
            retailMarkup: 10000,
            showEndUserCancellationPolicyAboveAmount: 0,
            stripePublicApiKey: ''
        };

        let paymentType;
        if (this.newSubAccount.parentAccountId === '1') {
            paymentType = 'company';
        } else if (
            UIRegExp.IsNumeric.test(this.newSubAccount.parentAccountId)
            && parseInt(this.newSubAccount.parentAccountId, 10) < 1000
        ) {
            // I'm setting this to a fixed value here. If we are ever told to offer the choice,
            // this is the case in which you can choose between 'prepaid' and 'postpaid';
            // in the other two cases, the values stay fixed.
            paymentType = 'prepaid';
        } else {
            paymentType = 'external';
        }

        this.newSubAccount.billingSettings = {
            creditLimit: 0,
            paymentType: paymentType,
            purchaseMarkup: (AuthContextService.account.id === '1') ? 10000 : 0,
            renewalsReportEnabled: false,
            retailMarkup: 10000
        };

        this.newSubAccount.dnsSettings = {
            defaultTemplateId: ''
        };

        this.newSubAccount.domainSettings = {
            defaultContactAdminId: '',
            defaultContactOwnerId: '',
            defaultContactTechId: '',
            defaultContactZoneId: '',
            monthlyPaymentEnabled: false
        };

        this.newSubAccount.allowedAdvertisingMethods = this.newSubAccount.allowedAdvertisingMethods ? ['email'] : null;

        this.accountModel.createSubaccount(this.newSubAccount, this.newUser, this.password).then(
            (res) => {
                if (res.status !== 'error') {
                    this.alertManager.success(this.$translate.instant('TR_110119-a1f3a3_TR'));
                    this.navigation.go('reseller.subaccounts.id.edit', { subaccountId: res.response.id });
                }

                return q.reject(false);
            }
        );
    };

    public get disableSaveButton(): boolean {
        return ['name', 'country', 'language', 'emailAddress', 'isCommercialCustomer']
        .some((attribute) => [undefined, null, ''].indexOf(this.newSubAccount[attribute]) >= 0)

        ||        ['name', 'language']
        .some((attribute) => [undefined, null, ''].indexOf(this.newUser[attribute]) >= 0)

        || [undefined, null, ''].indexOf(this.password) >= 0;
    }

    private createRightsTemplatesDropdownItems = (rightsTemplates: any[]) => {
        this.rightsTemplatesDropdownItems = [{
            name: 'keine',
            value: ''
        }];
        for (const template of rightsTemplates) {
            this.rightsTemplatesDropdownItems.push({
                name: template.name,
                value: template.id
            });
        }
    };
}

export class OrganismEditFormSubAccountCreateComponent implements ng.IComponentOptions {
    public bindings = {
        allRights: '<',
        rightsTemplates: '<'
    };
    public controller = OrganismEditFormSubAccountCreateController;
    public template = require('./sub-account-create.html');
}
