import { UIRegExp } from '@/configuration';

import { Validator } from './abstract-validator';

export class ValidateTlsa extends Validator {
    constructor(private $translate: ng.translate.ITranslateService) {
        super();
    }

    public validate = (value: string) => {
        if (UIRegExp.Tlsa.test(value)) {
            return [];
        } else {
            return [{ text: this.$translate.instant('TR_170119-421d09_TR') }];
        }
    };
}
