import * as ng from 'angular';
import {
    AlertManagerService,
    ApiErrorModel,
    AuthContextService,
    DateTimeWrapper,
    NavigationService,
    ProductHelperService,
    ProductsModelService,
    SslCertificateModelService
} from '../../../../../services';
import { UiRights } from '@/configuration';

interface Certificate {
    brand: string;
    endDate: any;
    id: string;
    productCode: string;
    status: string;
    validationType: string;
    product?: string;
    isManaged?: boolean;
}

export class OrganismEditFormSslCertificateController implements ng.IController {
    public static $inject: string[] = [
        '$q',
        '$translate',
        'alertManager',
        'apiErrorModel',
        'navigation',
        'productsModel',
        'sslCertificateModel'
    ];

    public certificate: Certificate;
    public panelRightSettings: any;
    public panelReissueRightSettings: any;
    public hasAgreedToReissueCertificate = false;
    public hasAgreedToRevokeCertificate = false;
    public reissueCsr = '';
    public showReissuePanel = false;
    public showObjectId: boolean;

    constructor(
        private $q: ng.IQService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        public navigation: NavigationService,
        private productsModel: ProductsModelService,
        private sslCertificateModel: SslCertificateModelService
    ) {}

    public $onInit = (): void => {
        const lang = this.$translate.use() as string;
        this.panelReissueRightSettings = ng.copy(this.panelRightSettings);

        if (
            this.certificate?.isManaged === true
            && !AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_SUPER_USER_EDIT)
        ) {
            this.panelReissueRightSettings.reissuePanel = false;
        }

        void this.productsModel.findOne(this.certificate.productCode, lang)
        .then(
            (result) => {
                const maxReissues = ProductHelperService.wrapSpecificationItems(
                    result.response.specificationItems
                ).maxReissues?.intValue;

                if (maxReissues === undefined) {
                    return;
                }

                this.showReissuePanel = maxReissues > 0 || maxReissues === -1;
            }
        );
    };

    public get waitingForEmailValidation() {
        return this.certificate.status === 'creating' && this.certificate.validationType === 'validateViaEmail';
    }

    public get supportUrl() {
        if (this.certificate.brand === 'symantec') {
            if (this.certificate.product.indexOf('RapidSSL') === 0) {
                return 'https://www.rapidssl.com/support/contact/';
            } else {
                return 'https://www.geotrust.com/support/contact/';
            }
        }
        return '';
    }

    public get brandName() {
        if (this.certificate.brand === 'symantec') {
            if (this.certificate.product.indexOf('RapidSSL') === 0) {
                return 'RapidSSL';
            } else {
                return 'GeoTrust';
            }
        }
        return '';
    }

    public get showSupportLink() {
        return this.certificate.brand === 'symantec';
    }

    public certIsOutdated = () => {
        const now = new DateTimeWrapper();
        now.setToday();
        const certEnd = new DateTimeWrapper(this.certificate.endDate);
        return certEnd.toUnixTimestamp() < now.toUnixTimestamp();
    };

    public resendVerificationMail = () => {
        this.sslCertificateModel.resendApproverEmails([this.certificate]).then(
            () => {
                this.alertManager.success(this.$translate.instant('TR_110119-0fd78a_TR'));
            });
    };

    public cancelReissue = () => {
        this.reissueCsr = '';
        this.hasAgreedToRevokeCertificate = false;
    };

    public cancelRevoke = () => {
        this.hasAgreedToRevokeCertificate = false;
    };

    public reissueCertificate = () => {
        return this.sslCertificateModel.reissueCertificate(this.certificate.id, this.reissueCsr)
        .then(
            (res) => {
                if (!this.apiErrorModel.apiResponseHasError(res)) {
                    this.alertManager.success(this.$translate.instant('TR_110119-7ace6a_TR'));
                    return res;
                }

                return this.$q.reject(false);
            }
        );
    };

    public revokeCertificate = () => {
        return this.sslCertificateModel.revoke([this.certificate])
        .then(
            (res) => {
                if (!this.apiErrorModel.apiResponseHasError(res)) {
                    this.alertManager.success(this.$translate.instant('TR_110119-d2d552_TR'));
                    return res;
                }

                return this.$q.reject(false);
            },
            (error) => {
                return this.$q.reject(error);
            }
        );
    };
}

export class OrganismEditFormSslCertificateComponent implements ng.IComponentOptions {
    public bindings = {
        certificate: '<',
        panelRightSettings: '<',
        showObjectId: '<'
    };
    public controller = OrganismEditFormSslCertificateController;
    public controllerAs = '$editFormOrganismSslCertificate';
    public template = require('./ssl-certificate-edit.html');
}
