import { AuthContextService, DnsZoneModelService } from '../../../../../services';

import * as ng from 'angular';
import * as Types from '../../../../../types';

import './record-content-search.scss';

export class OrganismRecordContentSearchController implements ng.IController {
    public static $inject: string[] = ['$translate', 'authContext', 'dnsZoneModel'];

    public account: string;
    public content = '';
    public recordType: string = null;
    public foundRecords: any = [];
    public searchInProgress = false;

    public recordTypeDropdownItems: { name: string; value: string }[] = [{ name: '', value: null }]
    .concat(
        ['A', 'AAAA', 'ALIAS', 'CAA', 'CNAME', 'MX', 'NS', 'PTR', 'SRV', 'TLSA', 'TXT']
        .map((type) => ({ name: type, value: type }))
    );

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private dnsZoneModel: DnsZoneModelService
    ) {
        this.recordTypeDropdownItems[0].name = this.$translate.instant('TR_180219-b5bef7_TR');
    }

    public $onInit = () => {
        this.account = this.authContext.account.id;
    };

    public searchRecords = (limit = 25, page = 1) => {
        if (typeof limit === 'boolean') {
            limit = this.foundRecords.pagination.limit;
            page = this.foundRecords.pagination.currentPage;
        }
        this.searchInProgress = true;
        const filter: Types.Finding.Filter = this._getFilter();
        this.dnsZoneModel.recordsList(filter, limit, page, undefined, this.account)
        .then(
            (reply) => {
                this.searchInProgress = false;
                this.foundRecords = reply;
            }
        );
    };

    private _getFilter = () => {
        const filter: Types.Finding.Filter = {
            subFilter: [
                {
                    field: 'recordContent',
                    value: this.content
                }
            ],
            subFilterConnective: 'AND'
        };

        if (this.recordType !== null) {
            filter.subFilter.push({
                field: 'recordType',
                value: this.recordType
            });
        }

        return filter;
    };

    public get showSearchResults() {
        return this.foundRecords !== undefined && this.foundRecords.data !== undefined;
    }

}

export class OrganismRecordContentSearchComponent implements ng.IComponentOptions {
    public controller = OrganismRecordContentSearchController;
    public template = require('./record-content-search.html');
}
