import * as ng from 'angular';

import { UIRegExp } from '@/configuration/regex';
import { FormDescriptionSpec } from '@/types/view-types/form-description/form-description';

export class FormElementInputColorRGBController implements ng.IComponentController {
    public static $inject: string[] = ['$translate'];

    public keenInput: FormDescriptionSpec.FieldObjectColorRgb;
    public keenOutput: (model: string, key: string) => any;
    private _model: string;
    private key: string;
    private label: string;
    private validationErrorList: { text: string}[];

    public $onInit = () => {
        this.model = this.keenInput.default || this.model || '';
        this.key = this.keenInput.key || '';
        this.label = this.keenInput.ui.label ||  '';
        if ([undefined, null].indexOf(((this.keenInput as unknown as FormDescriptionSpec.FieldObject).value as string)) < 0) {
            this._model = ((this.keenInput as unknown as FormDescriptionSpec.FieldObject).value as string);
        } else if ([undefined, null].indexOf(((this.keenInput as unknown as FormDescriptionSpec.FieldObject).default as string)) < 0) {
            this._model = ((this.keenInput as unknown as FormDescriptionSpec.FieldObject).default as string);
        }
    };

    public get model() {
        return this._model;
    }

    public set model(value: string) {
        this._model = value;
        this.validationErrorList = [];
        if (!this._isHexValue(value)) {
            this.validationErrorList.push({text: this.keenInput.ui.error});
        }
        if (this.keenOutput && this.key) {
            this.keenOutput(this._model, this.key);
        }
    }

    private _isHexValue = (value: string): boolean => {
        if (!value) {
            return false;
        }
        if (value.startsWith('#')) {
            // be carefull - last-index-issue on global flag
            // see here: https://stackoverflow.com/questions/13586786/regexp-test-returns-different-result-for-same-str-depending-on-how-where-i
            const regex = new RegExp(UIRegExp.Hex.source, UIRegExp.Hex.flags);
            return regex.test(value.slice(1));
        }
        return false;
    };
}

export class FormElementInputColorRGBComponent implements ng.IComponentOptions {
    public bindings = {
        keenInput: '<',
        keenOutput: '<?',
        model: '=?'
    };
    public controller = FormElementInputColorRGBController;
    public template = require('./form-element-input-color-rgb.html');
}
