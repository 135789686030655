import * as ng from 'angular';
import { UiRights } from '../../../../../../configuration';
import { AuthContextService, VhostModelService } from '../../../../../../services';
import { LinkListItem } from '../../../../links/link-list/link-list';

export class MoleculeOverviewRowPanelDashboardPanelWebspaceController implements ng.IController {
    public static $inject: string[] = ['vhostModel', '$translate'];

    public userHasRightBilBundleList = AuthContextService.isGranted(UiRights.BIL_BUNDLE_LIST); // used in template!
    public webspaceItem;
    public domainList: any[] = [];
    public loadingDomainList = true;
    public linkListDomains: LinkListItem[] = [];
    public linkListFooter: LinkListItem[] = [];

    constructor(
        private vhostModel: VhostModelService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.linkListDomains = this.linkListDomains || [];
        const webspaceId = this.webspaceItem.id;

        this.vhostModel.listWithoutPagination(
            6,
            1,
            { field: 'WebspaceId', value: webspaceId },
            { field: 'vhostLastChangeDate', order: 'DESC' }
        ).then(
            (result) => {
                this.linkListDomains = result.data
                .filter(
                    (domain: any, index: number, array: any[]) => !array.some(
                        (otherDomain: any, otherIndex: number) => otherIndex !== index && otherDomain.domainNameUnicode
                            .indexOf(domain.domainNameUnicode) > 0
                    )
                )
                .map(
                    (domain: any) => ({
                        route: 'webhosting.webspaces.id.vhosts.id.edit',
                        routeParams: { webspaceId: webspaceId, vHostId: domain.id },
                        text: domain.domainNameUnicode
                    })
                );

                if (this.linkListDomains.length > 5) {
                    this.linkListDomains = this.linkListDomains.slice(0, 5);
                    this.linkListDomains.push({
                        route: 'webhosting.webspaces.id.vhosts.overview',
                        routeParams: { webspaceId: webspaceId },
                        text: this.$translate.instant('TR_100119-800d0e_TR')
                    });
                }

                if (this.linkListDomains.length === 0) {
                    this.linkListFooter = [
                        {
                            route: 'webhosting.webspaces.id.vhosts.new',
                            routeParams:  { webspaceId: webspaceId },
                            text: this.$translate.instant('TR_100119-4a31ea_TR')
                        }
                    ];
                } else {
                    this.linkListFooter = [
                        {
                            route: 'webhosting.webspaces.id.vhosts.overview',
                            routeParams:  { webspaceId: webspaceId },
                            text: this.$translate.instant('TR_140119-aeb48a_TR')
                        }
                    ];
                }
                this.loadingDomainList = false;
            }
        );
    }
}

export class MoleculeOverviewRowPanelDashboardPanelWebspaceComponent implements ng.IComponentOptions {
    public bindings = {
        webspaceItem: '<'
    };
    public controller =  MoleculeOverviewRowPanelDashboardPanelWebspaceController;
    public template = require('./dashboard-webspace-panel.html');
}
