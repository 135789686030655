import * as ng from 'angular';
import { LinkListItem } from '../../links/link-list/link-list';

import './popup-multi-actions.scss';

export class MoleculePopupMultiActionsController implements ng.IController {
    public static $inject: string[] = [];

    public icon: string;
    public title: string;
    public isDisabled: boolean;
    public actionLinkItems: LinkListItem;
    public popupActive: boolean;

    $onInit() {
        this.popupActive = false;
    }

    public togglePopup() {
        this.popupActive = !this.popupActive;
    }

    public popupWatchClose() {
        this.togglePopup();
    }

    public outerElementClick() {
        if (this.popupActive) {
            this.popupActive = false;
        }
    }

}

export class MoleculePopupMultiActionsComponent implements ng.IComponentOptions {
    public bindings = {
        icon: '@',
        title: '@',
        isDisabled: '<',
        actionLinkItems: '<',
        toggleOverview: '<'
    };
    public controller =  MoleculePopupMultiActionsController;
    public template = require('./popup-multi-actions.html');
}
