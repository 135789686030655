import * as ng from 'angular';
import {
    EditFormMessage,
    EditFormSavingStatus,
    EditFormStatus,
    EditPanelStatus,
    MoleculeFormEditController,
    PanelType
} from '../../forms/form-edit/form-edit';

type Validator = () => true|any;

export class MoleculePanelActionController implements ng.IController {
    public static $inject: string[] = ['$transclude'];

    // Bindings
    public actionCallback: () => void;
    public cancelCallback: () => void;
    public name: string;
    public panelIcon: string;
    public panelTitle: string;
    public changesAffectPayment: boolean;
    public panelRight;
    public disableConfirmButton = false;
    public actionButtonText: string;
    public actionButtonSaveText = '';
    readonly type = PanelType.MISCELLANEOUS_ACTION;

    // Required parent controller
    public $editForm: MoleculeFormEditController;

    public $validators: { [key: string]: Validator } = {};

    private status: EditPanelStatus = EditPanelStatus.READONLY;

    constructor(
        private $transclude
    ) {}

    public $onInit = () => {
        this.$editForm.register(this);

        if ([undefined, null, ''].indexOf(this.actionButtonSaveText) >= 0) {
            this.actionButtonSaveText = this.actionButtonText;
        }
    };

    public $onDestroy = () => {
        this.$editForm.unregister(this);
    };

    public onMessage = (message: EditFormMessage<any>) => {
        switch (message.type) {
            default: return;

            case 'statusUpdate':
                switch (message.message) {
                    default: return;

                    case EditFormSavingStatus.SAVING:
                        // Currently doesn't have an effect here,
                        // all logic using "saving" status already uses the form's status.
                        return;

                    case EditFormSavingStatus.ERROR:
                        // TODO: Use logic. Error status if there is an error in this box, editable otherwise.
                        if (this.status !== EditPanelStatus.READONLY) {
                            this.status = EditPanelStatus.ACTIVE;
                        }

                        return;

                    case EditFormSavingStatus.READY:
                        // After successfully saving, return all panels to readonly status.
                        this.status = EditPanelStatus.READONLY;

                        return;
                }
        }
    };

    public get confirmButtonModifier() {
        return this.disableEditButton ? 'inactive' : 'active';
    }

    /**
     * Returns true if all input elements within the panel are free of errors,
     * false otherwise.
     * Warning: potentially slow, because it has to check ALL input fields!
     */
    public get $valid() {
        return Object.keys(this.$validators).every(key => this.$validators[key]() === true);
    }

    /**
     * Returns true if some input element within the panel has an error,
     * false otherwise.
     * Faster than $valid if there are errors, because the check is finished as soon as
     * the first error is found.
     */
    public get $invalid() {
        return Object.keys(this.$validators).some(key => this.$validators[key]() !== true);
    }

    /**
     * Returns an object containing all errors found within the panel.
     * Always an object, but it may be empty if no errors are found.
     */
    public get $errors() {
        const errors = {};
        for (const key of Object.keys(this.$validators)) {
            const result = this.$validators[key]();

            if (result !== true) {
                errors[key] = result;
            }
        }

        return errors;
    }

    public activate = () => {
        this.status = EditPanelStatus.ACTIVE;
    };

    public confirm = () => {
        if (this.$editForm.$invalid) {
            return;
        }

        if ([undefined, null].indexOf(this.actionCallback) < 0) {
            this.actionCallback();
        }
    };

    public cancel = () => {
        this.status = EditPanelStatus.READONLY;
        this.cancelCallback();
    };

    public successfullySaved = () => {
        this.status = EditPanelStatus.READONLY;
    };

    public errorWhileSaving = () => {
        this.status = EditPanelStatus.ACTIVE;
    };

    public get isEditable() {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showCancelButton() {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0
            && (!!this.panelRight === false || this.panelRight.editPanelButton);
    }

    public get showEditButton() {
        return [
            EditPanelStatus.READONLY
        ].indexOf(this.status) >= 0
            && (!!this.panelRight === false || this.panelRight.editPanelButton);
    }

    public get showSaveButton() {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0
            && (!!this.panelRight === false || this.panelRight.editPanelButton);
    }

    public get showEditableContent() {
        return [
            EditPanelStatus.ACTIVE,
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showReadonlyContent() {
        return [
            EditPanelStatus.READONLY
        ].indexOf(this.status) >= 0;
    }

    public get showErrorContent() {
        return [
            EditPanelStatus.ERROR
        ].indexOf(this.status) >= 0;
    }

    public get showSavingContent() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get saveButtonLoading() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get disableCancelButton() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get disableEditButton() {
        return !this.$editForm.canEnablePanel(this.name);
    }

    public get disableSaveButton() {
        return this.$editForm.$invalid
            || (this.$editForm.$status === EditFormStatus.SAVING)
            || (this.disableConfirmButton === true);
    }

    public get disableInputElements() {
        return this.$editForm.$status === EditFormStatus.SAVING;
    }

    public get readonlyTranscludeFilled() {
        return this.$transclude.isSlotFilled('readonly');
    }

    public get editableTranscludeFilled() {
        return this.$transclude.isSlotFilled('editable');
    }

    public get savingTranscludeFilled() {
        return this.$transclude.isSlotFilled('saving');
    }

    public get errorTranscludeFilled() {
        return this.$transclude.isSlotFilled('error');
    }
}

export class MoleculePanelActionComponent implements ng.IComponentOptions {
    public bindings = {
        actionButtonSaveText: '@saveButtonText',
        actionButtonText: '@buttonText',
        actionCallback: '<callback',
        cancelCallback: '<cancel',
        changesAffectPayment: '<paid',
        disableConfirmButton: '<',
        name: '@',
        panelIcon: '@icon',
        panelRight: '<',
        panelTitle: '@title'
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public transclude = {
        editable: '?panelContentEditable',
        error: '?panelContentError',
        readonly: '?panelContentReadonly',
        saving: '?panelContentSaving'
    };
    public controller =  MoleculePanelActionController;
    public controllerAs = '$actionPanel';
    public template = require('./panel-action.html');
}
