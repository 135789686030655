import * as ng from 'angular';
import { OrganismOverviewController, OverviewDataObject } from '../../../../../organisms/overview/overview';

export class MoleculeOverviewRowPanelWebhostingUserController implements ng.IController {
    public static $inject: string[] = [];

    public overviewData: OverviewDataObject;
    public overviewLoad: (selection: any) => any;
    public internalPaginationLimit: number;
    public OrganismOverview: OrganismOverviewController;

    public get showAccounts() {
        return this.OrganismOverview.globals.showAccounts;
    }
}

export class MoleculeOverviewRowPanelWebhostingUserComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelWebhostingUserController;
    public controllerAs = 'OverviewWebhostingUserCtrl';
    public template = require('./webhosting-user-row.html');
}
