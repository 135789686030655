import * as ng from 'angular';
import { FormDropDownItems } from '../../../../forms/form-elements/drop-down/drop-down';

export class MoleculePanelEditRowDropdownWithFilterController implements ng.IController {
    public static $inject: string[] = [];

    public items: FormDropDownItems[];
    public model: any;
    public headings: string[];
    public displayWhenSelected: number;
    public disabled: boolean;
    public callbackOnChange: (callbackParam: any) => any;
}

export class MoleculePanelEditRowDropdownWithFilterComponent implements ng.IComponentOptions {
    public transclude = {
        label: 'rowLabel'
    };
    public bindings = {
        callbackOnChange: '<',
        disabled: '<',
        displayWhenSelected: '<',
        headings: '<',
        items: '<',
        model: '='
    };
    public controller = MoleculePanelEditRowDropdownWithFilterController;
// tslint:disable-next-line: max-line-length
    public template = require('./panel-edit-row-dropdown-with-filter.html');
}
