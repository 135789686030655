import { ApiValue, FloatToApi } from '../../../../../filters';
import {
    AccountModelService,
    AlertManagerService,
    AuthContextService,
    AuthenticationRobotService,
    BillingHelperService,
    NavigationService
} from '../../../../../services';

import * as ng from 'angular';
import {AccountApi} from '../../../../../types';
import * as Types from '../../../../../types';

export enum ResellerDataSection {
    PAYMENT = 'payment',
    DEFAULTS = 'defaults',
    COMPANY = 'company',
    WHITELABEL = 'whitelabel'
}

export class OrganismEditFormResellerDataController implements ng.IController {
    public static $inject: string[] = [
        '$translate',
        'accountModel',
        'alertManager',
        'authenticationRobot',
        'billingHelper',
        'navigation'
    ];

    public section: ResellerDataSection;
    public account: Types.AccountApi.Account;
    public resellerWhiteLabelSettings: AccountApi.AccountResellerWhiteLabelSettings;
    public companyBankAccount: AccountApi.CompanyBankAccount = {
        accountNumber: '',
        bankName: '',
        bic: ''
    };
    public rightsTemplates: any[];
    public rightsTemplateDropdownItems: any[];
    public dnsTemplates: any[];
    public dnsTemplateDropdownItems: any[];
    public nameserverSets: any[];
    public nameserverSetDropdownItems: any[];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService,
        private alertManager: AlertManagerService,
        private authenticationRobot: AuthenticationRobotService,
        private billingHelper: BillingHelperService,
        private navigation: NavigationService
    ) {}

    public $onInit = () => {
        this.reset();
    };

    /** Get percentage added to / substracted from the purchase price. Necessary because actual value is a factor. */
    public get retailMarkup() {
        return this.billingHelper.convertRealValueToDisplayValue(this.account.resellerBillingSettings.retailMarkup);
    }

    /** Set factor multiplied with the purchase price.
     * Necessary because displayed value is a percantage added / substracted. */
    public set retailMarkup(value: string|number) {
        this.account.resellerBillingSettings.retailMarkup = this.billingHelper.convertDisplayValueToRealValue(value);
    }

    public get resellerWhiteLableSettingsEnabled() {
        return this.account.resellerWhiteLabelSettings !== null;
    }

    public set resellerWhiteLableSettingsEnabled(value: boolean) {
        if (value) {
            this.account.resellerWhiteLabelSettings = ng.copy(this.resellerWhiteLabelSettings);
        } else {
            this.resellerWhiteLabelSettings = ng.copy(this.account.resellerWhiteLabelSettings);
            this.account.resellerWhiteLabelSettings = null;
        }
    }

    public get resellerBankSettingsEnabled() {
        return this.account.resellerWhiteLabelSettings !== null
            && this.account.resellerWhiteLabelSettings.companyBankAccount !== null;
    }

    public set resellerBankSettingsEnabled(value: boolean) {
        if (value) {
            this.account.resellerWhiteLabelSettings.companyBankAccount = ng.copy(this.companyBankAccount);
        } else {
            this.companyBankAccount = ng.copy(this.account.resellerWhiteLabelSettings.companyBankAccount);
            this.account.resellerWhiteLabelSettings.companyBankAccount = null;
        }
    }

    public get whitelabelConfirmationUrl() {
        return this.account.resellerWhiteLabelSettings.confirmationUrl;
    }

    public set whitelabelConfirmationUrl(value: string) {
        this.account.resellerWhiteLabelSettings.confirmationUrl = value;
    }

    public get whitelabelDomainRegistrationTermsUrl() {
        return this.account.resellerWhiteLabelSettings.domainRegistrationTermsUrl;
    }

    public set whitelabelDomainRegistrationTermsUrl(value: string) {
        this.account.resellerWhiteLabelSettings.domainRegistrationTermsUrl = value;
    }

    public get defaultRightsTemplateName() {
        if (this.rightsTemplates === undefined
            || this.account.resellerDefaults.defaultSubaccountRightsTemplateId === undefined
            || this.account.resellerDefaults.defaultSubaccountRightsTemplateId === '') {
            return '';
        }
        for (const template of this.rightsTemplates) {
            if (template.id === this.account.resellerDefaults.defaultSubaccountRightsTemplateId) {
                return template.name;
            }
        }
        return '';
    }

    public get defaultDnsTemplateName() {
        if (this.dnsTemplates === undefined
            || this.account.resellerDefaults.defaultDnsTemplate === undefined
            || this.account.resellerDefaults.defaultDnsTemplate === '') {
            return '';
        }
        for (const template of this.dnsTemplates) {
            if (template.id === this.account.resellerDefaults.defaultDnsTemplate) {
                return template.name;
            }
        }
        return '';
    }

    public get defaultNameserverSetName() {
        if (this.nameserverSets === undefined
            || this.account.resellerDefaults.defaultDnsNameserverSet === undefined
            || this.account.resellerDefaults.defaultDnsNameserverSet === '') {
            return '';
        }
        for (const nameserverSet of this.nameserverSets) {
            if (nameserverSet.id === this.account.resellerDefaults.defaultDnsNameserverSet) {
                return nameserverSet.name;
            }
        }
        return '';
    }

    public get defaultNameserverSetNameServers() {
        if (this.nameserverSets === undefined
            || this.account.resellerDefaults.defaultDnsNameserverSet === undefined
            || this.account.resellerDefaults.defaultDnsNameserverSet === '') {
            return [];
        }
        for (const nameserverSet of this.nameserverSets) {
            if (nameserverSet.id === this.account.resellerDefaults.defaultDnsNameserverSet) {
                return nameserverSet.nameservers;
            }
        }
        return [];
    }

    // Get Creditlimit for commercial subaccounts.
    public get subaccountCreditLimitCommercial() {
        return ApiValue.parseApiValue(this.account.resellerBillingSettings.defaultCreditLimitForCommercialSubaccounts);
    }

    // Set creditlimit for commercial subaccounts.
    public set subaccountCreditLimitCommercial(value: string) {
        this.account.resellerBillingSettings.defaultCreditLimitForCommercialSubaccounts = FloatToApi.convertNumericToApiInt(value); // tslint:disable:max-line-length
    }

    // Get Creditlimit for end-user subaccounts.
    public get subaccountCreditLimitEndUser() {
        return ApiValue.parseApiValue(this.account.resellerBillingSettings.defaultCreditLimitForEndUserSubaccounts);
    }

    // Set creditlimit for end-commercial subaccounts.
    public set subaccountCreditLimitEndUser(value: string) {
        this.account.resellerBillingSettings.defaultCreditLimitForEndUserSubaccounts = FloatToApi.convertNumericToApiInt(value); // tslint:disable:max-line-length
    }
    public get mxRecordContents() {
        if (this.account.resellerDefaults.defaultMxRecords === undefined) {
            return [];
        }
        return this.account.resellerDefaults.defaultMxRecords.map(
            (record) => {
                return record.content;
            }
        );
    }

    public get addressReadOnly() {
        if (
            this.account.resellerWhiteLabelSettings.address === undefined
            || this.account.resellerWhiteLabelSettings.address === ''
        ) {
            return '-';
        }
        return this.account.resellerWhiteLabelSettings.address.replace(/([\r\n]+)/gm, ', ');
    }

    /** Since this page is always about the own account, we can just fetch the data from the authContext
     * instead of binding to an outside variable. */
    public reset = () => {
        this.account = ng.copy(AuthContextService.account);
        if (this.account.resellerBillingSettings === null) {
            this.account.resellerBillingSettings.defaultCreditLimitForCommercialSubaccounts = 0;
            this.account.resellerBillingSettings.defaultCreditLimitForEndUserSubaccounts = 0;
        } else {
            this.account.resellerBillingSettings.defaultCreditLimitForCommercialSubaccounts /= 10000;
            this.account.resellerBillingSettings.defaultCreditLimitForEndUserSubaccounts /= 10000;
            this.account.resellerBillingSettings.showEndUserCancellationPolicyAboveAmount /= 10000;
        }
        if (this.account.resellerWhiteLabelSettings !== null) {
            this.resellerWhiteLabelSettings = ng.copy(this.account.resellerWhiteLabelSettings);
            if (this.account.resellerWhiteLabelSettings.companyBankAccount !== null) {
                this.companyBankAccount = ng.copy(this.account.resellerWhiteLabelSettings.companyBankAccount);
            }
        } else {
            this.resellerWhiteLabelSettings = {
                name: '',
                address: '',
                phoneNumber: '',
                emailAddress: '',
                website: '',
                companyBankAccount: null,
                alternativeEmailSender: '',
                emailAddressForInfoOnlyEmails: '',
                emailAddressForActionRequiredEmails: '',
                confirmationUrl: '',
                domainRegistrationTermsUrl: ''
            };
        }
        if (this.section === ResellerDataSection.DEFAULTS) {
            this.rightsTemplateDropdownItems = [{
                name: 'Keine',
                value: ''
            }];
            this.rightsTemplates.map(
                (template) => {
                    this.rightsTemplateDropdownItems.push({
                        name: template.name,
                        value: template.id
                    });
                }
            );
            this.dnsTemplateDropdownItems = [{
                name: 'Keine',
                value: ''
            }];
            this.dnsTemplates.map(
                (template) => {
                    this.dnsTemplateDropdownItems.push({
                        name: template.name,
                        value: template.id
                    });
                }
            );
            this.nameserverSetDropdownItems = [{
                name: 'Keine',
                value: ''
            }];
            this.nameserverSets.map(
                (nameserver) => {
                    this.nameserverSetDropdownItems.push({
                        name: nameserver.name,
                        value: nameserver.id
                    });
                }
            );
        }
    };

    public save = () => {
        const accountWithConvertedPrices = ng.copy(this.account);
        accountWithConvertedPrices.resellerBillingSettings.defaultCreditLimitForCommercialSubaccounts *= 10000;
        accountWithConvertedPrices.resellerBillingSettings.defaultCreditLimitForEndUserSubaccounts *= 10000;
        accountWithConvertedPrices.resellerBillingSettings.showEndUserCancellationPolicyAboveAmount *= 10000;

        this.accountModel.updateAccount(accountWithConvertedPrices)
        .then(
            // Necessary because state refresh DOES NOT reload the page,
            // so changed account data is not loaded implicitly.
            () => {
                this.alertManager.success(this.$translate.instant('TR_140119-5f2290_TR'));
                return this.authenticationRobot.refreshAccountData();
            }
        )
        .then(() => this.navigation.reloadCurrentState());
    };
}

export class OrganismEditFormResellerDataComponent implements ng.IComponentOptions {
    public bindings = {
        dnsTemplates: '<',
        nameserverSets: '<',
        rightsTemplates: '<',
        section: '<'
    };

    public controller = OrganismEditFormResellerDataController;
    public template = require('./reseller-data-edit.html');
}
