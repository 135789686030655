import './panel-service-info-external-records.scss';

import ng from 'angular';

import { UiRights } from '@/configuration';
import { ArticleDetails, ConvertAmountFilter } from '@/filters';
import {
    AuthContextService, CleanableStringType, DomainInfo, DomainInfoHelperService, PriceCacheService,
    StringCleaner, WebspaceIntegrationHelperService
} from '@/services';
import * as Types from '@/types';

export interface MovementPanelText {
    panelTitle: string;
    priceInfo: string;
    buttonInfoText: string;
}

export class MoleculePanelServiceInfoExternalRecordsController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'articleDetailsFilter',
        'convertAmountFilter',
        'domainInfoHelper',
        'priceCache',
        'webspaceIntegrationHelper'
    ];

    public domainStatus: string;
    public webspace: Types.WebhostingApi.Webspace;

    public dnsZone: Types.DnsApi.Zone;
    public domainList: Types.DomainApi.Domain[] = [];
    public domainSearchString = '';
    public inBundleAvailable = false;
    public movementPanelText: MovementPanelText;
    public selectedDomains: Types.DomainApi.Domain[] = [];
    public transferPrice: null | boolean | {} = null;

    private _bundle: Types.BundleApi.Bundle;
    private _domainInfoData: DomainInfo;
    private _domainPrice: null | boolean | {} = null;
    private _domainType: string;
    private _vHost: Types.WebhostingApi.VHost;

    constructor(
        private $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        private articleDetailsFilter: ArticleDetails,
        private convertAmountFilter: ConvertAmountFilter,
        private domainInfoHelper: DomainInfoHelperService,
        private priceCache: PriceCacheService,
        private webspaceIntegrationHelper: WebspaceIntegrationHelperService
    ) {}

    public $onInit(): void {
        this._domainType = this._domainType || 'domain';
        void this.webspaceIntegrationHelper
            .getWebspaceVhost(this._vHost)
            .then(
                (result) => {
                    this.dnsZone = result;
                    this._getDomainInfo();
                }
            );
    }

    public set isRegisterable(_) {}
    public get isRegisterable(): boolean {
        if (this._domainType === 'domain'
            || !this._domainInfoData
            || !this._domainInfoData.domainStatus
        ) {
            return false;
        }

        return ['available', 'canNotCheck', 'registered'].indexOf(this._domainInfoData.domainStatus.status) >= 0;
    }

    public isRegisterableInBundle = (): boolean => {
        if (this._bundle === undefined) {
            return false;
        }

        const productCodePrefix = 'domain-' + this._vHost.domainName.split('.').slice(1).join('.') + '-';
        return this._bundle.effectiveContingentUsage.some((contingent) => {
            return contingent.availableCapacity > 0 && contingent.productCodes.some((productCode) => {
                return productCode.startsWith(productCodePrefix);
            });
        });
    };

    private _getDomainInfo = (): void => {
        const domainNameUnicode = StringCleaner
            .clean(this._vHost.domainNameUnicode)
            .as(CleanableStringType.DomainNames);
        void this.domainInfoHelper.getDomainInfo(domainNameUnicode, true, +this._vHost.accountId)
            .then(
                (domainInfo) => {
                    this._domainInfoData = domainInfo;
                    this.domainSearchString = domainInfo.domainNameUnicode;
                    if (domainInfo.domainStatus) {
                        this._setSelectedDomainsArray(domainInfo);
                    }
                    this._getDomainPrice(this._getDomainAction());
                }
            );
    };

    private _setSelectedDomainsArray = (domainInfo: Types.DomainApi.Domain): void => {
        this.selectedDomains = [{
            amount: 1,
            domainName: domainInfo.domainNameUnicode,
            domainObject: {
                domain: domainInfo.domainStatus,
                prices: [],
                productCode: this._domainInfoData.productCodes[this._getDomainAction() as string]
            },
            inBundle: this.isRegisterableInBundle(),
            label: domainInfo.domainNameUnicode,
            price: undefined,
            status: domainInfo.domainStatus.status,
            tld: domainInfo.domainStatus?.domainSuffix
        }];

        void this.priceCache.listDomainPrices(domainInfo.domainStatus.extension)
            .then(
                (ret) => {
                    this.selectedDomains[0].domainObject.prices = ret;
                    this.selectedDomains[0].price = ret.filter((price) => {
                        return price.productCode === this.selectedDomains[0].domainObject.productCode;
                    })[0];
                }
            );
    };

    private _setMovementPanelText = (): void => {
        this.movementPanelText = {
            buttonInfoText: this.$translate.instant('70787000-7f0a-421d-9e1b-5ffe804172f0'),
            panelTitle: this.$translate.instant('9e7c3a49-73df-4f43-988f-02fe8bf56a67'),
            priceInfo: ''
        };

        switch (this._domainInfoData?.domainStatus?.status) {
            case 'authInfo':
            case 'transfer':
                this.movementPanelText = {
                    buttonInfoText: this.$translate.instant('TR_100119-126af7_TR'),
                    panelTitle: this.$translate.instant('TR_100119-ddaa6a_TR'),
                    priceInfo: ''
                };
                break;
            case 'canNotCheck':
                this.movementPanelText = {
                    buttonInfoText: this.$translate.instant('TR_070720-ba9d77_TR'),
                    panelTitle: this.$translate.instant('9e7c3a49-73df-4f43-988f-02fe8bf56a67'),
                    priceInfo: ''
                };
                break;
        }
    };

    private _getDomainAction = (): string | boolean => {
        let domainAction: string | boolean;
        switch (this._domainInfoData?.domainStatus?.status) {
            case 'available':
                domainAction = 'create';
                break;
            case 'canNotCheck':
                domainAction = 'authInfo';
                break;
            case 'registered':
                domainAction = 'transfer';
                break;
            default:
                domainAction = false;
        }

        return domainAction;
    };

    private _getDomainPrice = (domainAction: string | boolean): void => {
        // set movementPanelText object
        this._setMovementPanelText();

        // ToDo: Change this part, if price-domain-component is available (new domain wizard)
        if (!this.showPrice) {
            return;
        }

        // check, if bundle contingent for this domain is available
        if (this.isRegisterableInBundle()) {
            this.movementPanelText.priceInfo = this.$translate.instant('TR_100119-029da3_TR');
            this.domainPrice = false;
            this.inBundleAvailable = true;
            return;
        }

        this.inBundleAvailable = false;

        if (domainAction !== false) {
            this.articleDetailsFilter({
                owner: AuthContextService.account.id,
                productCode: this._domainInfoData.productCodes[domainAction as string],
                quantity: 1
            }).then(
                (priceObject: ArticleDetails) => {
                    void this.$timeout(() => {
                        this.domainPrice = priceObject;
                        this.movementPanelText.priceInfo = '<span class=\'prefix-text\'>'
                            + this.$translate.instant('TR_100119-6a789b_TR')
                            + '</span> '
                            + this.convertAmountFilter(priceObject.price.owner.single[this.priceType])
                            + ' '
                            + priceObject.currency.owner
                            + '/'
                            + priceObject.contractPeriod;
                    });
                }
            );
        }
    };

    public set priceType(_) {}
    public get priceType(): 'net' | 'gross' {
        if ([undefined, null].indexOf(AuthContextService.account) >= 0) {
            return 'gross';
        }
        return AuthContextService.account.isCommercialCustomer ? 'net' : 'gross';
    }

    public set showPrice(_) {}
    public get showPrice(): boolean {
        if ([undefined, null].indexOf(AuthContextService.account) >= 0) {
            return false;
        }
        return AuthContextService.isGranted(UiRights.BIL_LIST_ARTICLE_PRICES)
            && [undefined, null, '0', '1'].indexOf(AuthContextService.account.parentAccountId) < 0;
    }

    public set domainPrice(value) {
        this._domainPrice = value;
    }

    public get domainPrice(): typeof this._domainPrice {
        return this._domainPrice;
    }

    public set domainPriceLoaded(_) {}
    public get domainPriceLoaded(): boolean {
        return [null, undefined].indexOf(this.domainPrice) < 0;
    }
}

export class MoleculePanelServiceInfoExternalRecordsComponent implements ng.IComponentOptions {
    public bindings = {
        _bundle: '<bundle',
        _domainType: '<domainType',
        _vHost: '<vHost'
    };
    public controller = MoleculePanelServiceInfoExternalRecordsController;
    public controllerAs = '$panelServiceInfo';
    public template = require('./panel-service-info-external-records.html');
}
