import { RequestCounterService } from '../../scripts/development/request-counter';
import { AlertManagerService } from '../alert-manager';
import { CacheService } from '../cache';
import { ErrorMapperService } from '../error-mapper';
import { NavigationService } from '../navigation';
import { Request } from './request';

import * as ng from 'angular';

export class RpcClient {
    constructor(
        private $http: ng.IHttpService,
        private alertManager: AlertManagerService,
        private cache: CacheService,
        private errorMapper: ErrorMapperService,
        private navigation: NavigationService,
        private requestCounter: RequestCounterService,
        private baseUrl: string,
        private robot: string
    ) {}

    public request: (method: string) => Request
    = (method) => new Request(
        this.cache,
        this.$http,
        this.alertManager,
        this.errorMapper,
        this.navigation,
        this.requestCounter,
        this.baseUrl,
        this.robot,
        method
    );
}

export class RpcClientService {
    public static $inject: string[] = [
        '$http',
        'alertManager',
        'cache',
        'errorMapper',
        'navigation',
        'requestCounter'
    ];

    constructor(
        private $http: ng.IHttpService,
        private alertManager: AlertManagerService,
        private cache: CacheService,
        private errorMapper: ErrorMapperService,
        private navigation: NavigationService,
        private requestCounter: RequestCounterService
    ) {}

    public Factory(baseUrl: string, robot: string) {
        return new RpcClient(
            this.$http,
            this.alertManager,
            this.cache,
            this.errorMapper,
            this.navigation,
            this.requestCounter,
            baseUrl,
            robot
        );
    }
}
