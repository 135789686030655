
export enum AsteriskNoteType {
    ANNUALLY_CONTRACT_PERIOD = /* translationId */ 'TR_300320-cc8f55_TR',
    CONVERTED_FOREIGN_CURRENCY = /* translationId */ 'TR_270320-1155ed_TR',
    CONVERTED_FOREIGN_CURRENCY_TOTAL = /* translationId */ 'TR_270320-ecd284_TR',
    CONVERTED_FOREIGN_CURRENCY_TOTAL_TAX = /* translationId */ 'TR_300320-79faf1_TR',
    /**
     * Asterik type DYNAMIC_NOTE is not unique and can be added as often as desired.
     * Just note that every asterisk text with this type gets its own numbering, no matter if the texts are identical
     */
    DYNAMIC_NOTE = 'DYNAMIC_NOTE',
    VAT_EXCLUDED = /* translationId */ 'TR_231219-383755_TR',
    VAT_INCLUDED = /* translationId */ 'TR_231219-e28c9a_TR',
    VAT_NONUNIFORM = /* translationId */ 'TR_140524-37833a_TR',
}

export interface AsterikNoteObject {
    number: number;
    type: string;
    value: string;
}

export class AsteriskNoteService {
    public static $inject: string[] = ['$translate'];

    public notes: AsterikNoteObject[] = [];

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public addNote = (noteType: AsteriskNoteType, dynamicNoteText?: string) => {
        if (!noteType) {
            return;
        }

        if (noteType !== AsteriskNoteType.DYNAMIC_NOTE && this.preExistingNote(noteType)) {
            return this.getAsterikNoteNumber(noteType);
        }
        /**
         * This if-query is a fallback for passing a dynamic TextNode without text
         * content (null, undefined, ''). Should not happen. But if it does, several
         * asterisk texts (*x) are created without any text. Of course it doesn't look
         * that good.
         */
        if (noteType === AsteriskNoteType.DYNAMIC_NOTE && [undefined, null, ''].indexOf(dynamicNoteText) >= 0) {
            return;
        }

        const dynamicNoteNumber = this.getAsteriskDynamicNumber(dynamicNoteText);
        if (noteType === AsteriskNoteType.DYNAMIC_NOTE && dynamicNoteNumber) {
            return dynamicNoteNumber;
        }

        const newNote = {
            number: this.notes.length + 1,
            type: noteType,
            value: noteType !== AsteriskNoteType.DYNAMIC_NOTE
                ? noteType
                : dynamicNoteText
        };
        this.notes.push(newNote);
        return newNote.number;
    };

    public getAsterikNoteNumber = (noteType: AsteriskNoteType) => {
        let asteriskNoteNumber = 0;

        this.notes.some((note: AsterikNoteObject, index: number) => {
            if (note.type === noteType) {
                asteriskNoteNumber = note.number;
                return true;
            }
            return false;
        });

        return asteriskNoteNumber;
    };

    public getAsteriskDynamicNumber = (dynamicNoteText: string) => {
        const dynamicNoteExists = this.notes.filter((note: AsterikNoteObject, index: number) => {
            return dynamicNoteText && note.value === dynamicNoteText;
        });

        return dynamicNoteExists.length > 0
            ? dynamicNoteExists[0].number
            : 0;
    };

    public getAsterikNoteIndex = (noteType: AsteriskNoteType) => {
        let asteriskNoteIndex: boolean | number = false;
        if (this.preExistingNote(noteType)) {
            this.notes.some((note: AsterikNoteObject, index: number) => {
                if (note.type === noteType) {
                    asteriskNoteIndex = index;
                    return true;
                }

                return false;
            });
        }

        return asteriskNoteIndex;
    };

    public preExistingNote = (noteType: AsteriskNoteType) => {
        const asteriskNoteNumber = this.getAsterikNoteNumber(noteType);
        return asteriskNoteNumber > 0;
    };

    public resetNodes = () => {
        this.notes = [];
    };

    public outputAsteriskNodeStar = (noteType: AsteriskNoteType, dynamicNoteText?: string) => {
        return noteType !== AsteriskNoteType.DYNAMIC_NOTE
            ? this.getAsterikNoteNumber(noteType)
            : this.getAsteriskDynamicNumber(dynamicNoteText);
    };

    public outputNote = (noteType: AsteriskNoteType) => {
        const asteriskNoteIndex = this.getAsterikNoteIndex(noteType);
        if (typeof asteriskNoteIndex === 'number') {
            const asteriskNoteNumber = this.getAsterikNoteNumber(noteType);
            const asteriskNoteText = noteType !== AsteriskNoteType.DYNAMIC_NOTE
                ? this.$translate.instant(this.notes[asteriskNoteIndex].value)
                : this.notes[asteriskNoteIndex].value;

            return asteriskNoteNumber + '. ' + asteriskNoteText;
        }
    };

}
