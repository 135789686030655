import * as ng from 'angular';

export class MoleculePanelEditRowColorRGBController implements ng.IController {
    public static $inject: string[] = [];

    public model: string;
}

export class MoleculePanelEditRowColorRGBComponent implements ng.IComponentOptions {
    public bindings = {
        model: '='
    };

    public transclude = {
        label: '?rowLabel'
    };

    public controller = MoleculePanelEditRowColorRGBController;
    public template = require('./panel-edit-row-color-rgb.html');
}
