import * as ng from 'angular';
import { UiRights } from '../../../../../../configuration';
import { AuthContextService, NavigationService } from '../../../../../../services';
import {
    OrganismDashboardOverviewsController
} from '../../../../../organisms/overview/dashboard-overviews/dashboard-overviews';

export class GenericMoleculeOverviewRowPanelDashboardController implements ng.IController {
    public static $inject: string[] = ['$state', 'authContext', 'navigation'];

    public userHasRightBilBundleList = AuthContextService.isGranted(UiRights.BIL_BUNDLE_LIST); // used in template!
    public serviceItemList: any[];
    public OrganismDashboardOverview: OrganismDashboardOverviewsController;
    public service: string;
    public objectType: string;
    public sideEntryPoint: string;
    public stateGlobals: any;

    constructor(
        private $state: ng.ui.IStateService,
        private authContext: AuthContextService,
        private navigation: NavigationService
    ) {}

    public $onInit() {
        this.sideEntryPoint = this.$state.current.name.split('.')[0];
        this.stateGlobals = this.$state.$current.locals.globals;
    }

    public get showAccounts() {
        return (this.OrganismDashboardOverview.globals || {} as any).showAccounts;
    }

    public isGranted = (rightName: string) => {
        return this.authContext.isGranted(rightName);
    };

    public clickBundle = (bundleId: string, event: any) => {
        event.stopPropagation();
        this.navigation.go('bundle.id.dashboard', { bundleId: bundleId });
    };

    public isWebsite = (item: any) => {
        if (!item.locations) {
            return true;
        }

        return item.locations.some(
            (location: any) => location.matchType === 'default' && location.locationType === 'generic'
        );
    };
}

export abstract class GenericMoleculeOverviewRowPanelDashboardComponent implements ng.IComponentOptions {
    public require = {
        OrganismDashboardOverview: '^organismDashboardOverviews'
    };

    public bindings = {
        objectType: '@',
        service: '@',
        serviceItemList: '<'
    };

    public controller =  GenericMoleculeOverviewRowPanelDashboardController;
    public controllerAs = 'DashboardOverviewCtrl';
    protected abstract type: string;
}
