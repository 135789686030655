import ng from 'angular';
import { DomainTypes } from './domain-type-selection';
import { InputPlaceholderConst, UiRights } from '@/configuration';
import { DropdownElementData } from '@/atomic-components/molecules';
import { ZoneUpdateData } from '@/atomic-components/organs';
import {
    AuthContextService,
    DataObject,
    DnsNameserverInfoObject,
    DnsTemplateModelService,
    SystemHelperService,
    ValidateDns,
    WizardNewHelperService
} from '@/services';
import { DnsApi, Finding, ViewTypes, WebhostingApi } from '@/types';
import { ApiObject } from '@/services/helpers/api-object-handlers/main';
import { WizardServiceHelperDomain } from '@/atomic-components/molecules/wizard';

export class OrganCreateDomainNameserversController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        'authContext',
        'wizardServiceHelperDomain',
        'dnsTemplateModel',
        'systemHelper',
        'wizardNewHelper',
        '$translate'
    ];

    public customMailserverFeatureDisabledForNow = true; // See PUI-5327
    public customeNameserverValidation: DataObject[];
    public dnsTemplateDropdownItems: DropdownElementData[] = [];
    public hasDnsTemplates = false;
    public hasGlueRecord = false;
    public metadata: ViewTypes.ProductConfigDomainDomainObject;
    public nameserversComplete = false;
    public nameserverDropdownItems: DropdownElementData[] = [];
    public nameserverTypeButtons: {value: string; label: string}[];
    public ownNameservers: ViewTypes.OwnNameserver[] = [];
    public requiredReplacementTypes: ViewTypes.RequiredReplacementTypes;
    public ipv4ValidationErrorList: { text: string }[] = [];
    public masterIpValidationErrorList: { text: string }[];

    protected inputPlaceholder = InputPlaceholderConst;

    private _hashes: {[key: string]: string[]} = {};
    private _webspace: WebhostingApi.Webspace;
    private _ownNameservers: ViewTypes.OwnNameserver[] = [];
    private _nameservers: ViewTypes.NameserverDefinition;

    constructor(
        private $timeout: ng.ITimeoutService,
        private authContext: AuthContextService,
        private wizardServiceHelperDomain: WizardServiceHelperDomain,
        private dnsTemplateModel: DnsTemplateModelService,
        private systemHelper: SystemHelperService,
        private wizardNewHelper: WizardNewHelperService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit(): void {
        this._nameservers = ng.copy(this.metadata?.nameserver);
        this._webspace = ng.copy(this.metadata.webspace);
        if (this._webspace) {
            this.metadata.dns.type = 'webspace';
        }
        this._setNameserverButtonTypes();
        this.customeNameserverValidation = [{
            instructions: null,
            validator: new ValidateDns(this.$translate, true)
        }];

        if (this.metadata.nameserver.ownNameservers) {
            this.ownNameservers = this.metadata.nameserver.ownNameservers;
        } else {
            this.metadata.nameserver.ownNameservers = [];
        }

        this.setWebspaceNameServer();
        this.setNameserverSets();
        this.setDnsTemplates();
        this._checkNameserverComplete();
    }

    public $doCheck(): void {
        if (JSON.stringify(this._webspace) !== JSON.stringify(this.metadata.webspace)) {
            if (this.metadata?.webspace) {
                this.setWebspaceNameServer();
                this.metadata.dns.type = 'webspace';
            } else {
                this.metadata.dns.values.ip = '';
                this.metadata.dns.type = 'empty';
            }
            this._webspace = ng.copy(this.metadata.webspace);
        }
        if  (this._checkDnsRecordsUpdaterOnOwnNameserverChange()) {
            this._checkIpsOfNameserverIsRequired();
        }

        if (JSON.stringify(this.metadata?.nameserver) !== JSON.stringify(this._nameservers)) {
            this._nameservers = ng.copy(this.metadata?.nameserver);
            this._checkNameserverComplete();
        }
    }

    public onNameserverOptionChange = (selection: ViewTypes.NameserverValues): void => {
        let nameserverType: string;

        void this.$timeout(() => {
            if (selection.hasGlueRecord && this.metadata.selectedDomains.length > 1) {
                this.metadata.nameserver.ownNameservers.map((nameserver) => {
                    nameserver.name = '';
                    nameserver.ips = [];
                    nameserver.nameserverErrors = [];
                    nameserver.ipsRequired = false;
                    return nameserver;
                });
            }

            this._checkIpsOfNameserverIsRequired(selection.nameservers);

            switch (selection.type) {
                case 'system':
                    nameserverType = [undefined, null, '', 'external'].indexOf(this.metadata.dns.type) >= 0
                        ? 'empty'
                        : this.metadata.dns.type;
                    break;
                case 'external':
                default:
                    nameserverType = 'external';
            }
            this.metadata.dns.type = nameserverType;
            this._checkNameserverComplete();
        });
    };

    public onNameserverTypeChange = (): void => {
        void this.$timeout(() => {
            this.metadata.dns.values = this.wizardNewHelper.setDnsSettings(this.metadata);
        });
    };

    public set systemNameserverType(_) {/* */} // tslint:disable-line:no-empty
    public get systemNameserverType(): boolean {
        return this.metadata.nameserver?.values?.type === 'system';
    }

    public set zoneTypeSelectionViewable(_) {/* */}
    public get zoneTypeSelectionViewable(): boolean {
        if (
            this._allDomainsHaveZones()
            || this._noSystemNameserverSelectedOrNameserverHasGlueRecords()
            || this.wizardNewHelper.ownNameserverSelected(this.metadata)
        ) {
            // on following cases, we do not show zone type selection
            // - all selected domains have zone
            // - no system nameserver selected
            // - nameserver has glue records
            // - own nameserver are given
            return false;
        }

        return true;
    }

    public issetCustomerNameserver = (): boolean => {
        if (!this.metadata.nameserver.values) {
            return false;
        }
        const isset = this.metadata.nameserver.values.nameservers === null
            && !this.metadata.nameserver.values.default
            && this.metadata.nameserver.values.id === null;

        if (isset) {
            this.metadata.dns.type = 'external';
        }

        return isset;
    };

    public setDnsTemplates = (): void => {
        void this.dnsTemplateModel.list(1).then(
            (reply: {data: DnsApi.RecordTemplate[]; pagination: unknown}) => {
                if (reply.data && reply.data.length > 0) {
                    this.hasDnsTemplates = true;
                }
                if (
                    this.authContext.account.dnsSettings
                    && this.authContext.account.dnsSettings.defaultTemplateId !== ''
                ) {
                    void this.findDnsTemplateById(this.authContext.account.dnsSettings.defaultTemplateId)
                        .then((template: DnsApi.RecordTemplate) => {
                            if (template && template.id !== undefined) {
                                this.metadata.dns.values.templateId = template.id;
                                this.metadata.dns.type = 'template';
                                this.getDnsTemplateData(template.id);
                            }
                            this._setNameserverButtonTypes();
                        });
                } else {
                    this._setNameserverButtonTypes();
                }
            }
        );
    };

    public findDnsTemplateById = (id: string): Promise<DnsApi.RecordTemplate> => {
        return this.dnsTemplateModel.findOne(id);
    };

    public findDnsTemplates = (
        limit?: number,
        page?: number,
        filter?: Finding.Filter,
        sort?: Finding.SortOptions
    ): Promise<ViewTypes.EntityWithPagination<DnsApi.FindRecordTemplatesResult>> => {
        return this.dnsTemplateModel.list(
            limit, page, filter, sort
        ) as Promise<ViewTypes.EntityWithPagination<DnsApi.FindRecordTemplatesResult>>;
    };

    public set hasMultiDomainWithNameserverGlue(_) {/* */}
    public get hasMultiDomainWithNameserverGlue(): boolean {
        return this.metadata.selectedDomains.length > 1
            && this.metadata.nameserver.values.hasGlueRecord;
    }

    public set abortRegistrationOfSingleDomainWithNameserverGlueRecords(_) {/* */}
    public get abortRegistrationOfSingleDomainWithNameserverGlueRecords(): boolean {
        /**
         * Allow only one domain transfer with nameserver glue records!
         */
        return this.metadata.selectedDomains?.length > 0
            && this.metadata.selectedDomains[0].status !== 'registered'
            && this.metadata.nameserver.values.hasGlueRecord;
    }

    public set complete(_) {/* */}
    public get complete(): boolean {
        if (
            !this._checkGlueRecords(this.metadata.nameserver)   // Check GlueRecords and IP-addresses are set
            || (
                // has nameserver glue and multiple domains are selected
                this.metadata.nameserver?.values?.hasGlueRecord
                && this.metadata.selectedDomains?.length > 1
            )
        ) {
            this.nameserversComplete = false;
            return false;
        }

        if (
            this.metadata.domainType !== DomainTypes.REGISTER
            || this.metadata.webspace
        ) {
            this.nameserversComplete = true;
            return true;
        }

        let completed = false;

        // check custome nameserver entries
        if (this.isOwnNameserversSelected) {
            const ownNameserverHasChanged = this._checkDnsRecordsUpdaterOnOwnNameserverChange();
            completed = this._customeNameserverCompleted();

            if (completed && ownNameserverHasChanged) {
                return void this.$timeout(() => {
                    this.nameserversComplete = true;
                });
            }

            this.nameserversComplete = completed;
            return completed;
        }

        switch (this.metadata.dns.type) {
            case 'quick':
                completed = this._quickInfoComplete();
                break;
            case 'slave':
                completed = this._slaveInfoComplete();
                break;
            case 'template':
                completed = this._templateInfoComplete();
                break;
            case 'external':
            case 'empty':
            default:
                completed = true;
        }

        this.nameserversComplete = completed;
        return completed;
    }

    public set domainNames(_) {/* */}
    public get domainNames(): string[] {
        const hash = JSON.stringify(this.metadata.selectedDomains);

        if (!this._hashes[hash]) {
            this._hashes[hash] = [];
            this.metadata.selectedDomains.forEach(
                (domain) => {
                    this._hashes[hash].push(domain.domainName);
                    if (domain?.domainNameAce && domain?.domainName !== domain?.domainNameAce) {
                        this._hashes[hash].push(domain.domainNameAce);
                    }
                }
            );
        }

        return this._hashes[hash];
    }

    public set nameserverIsDefault(_) {/* */}
    public get nameserverIsDefault(): boolean {
        return this.metadata?.nameserver?.values?.default;
    }

    public set nameserverIsSet(_) {/* */}
    public get nameserverIsSet(): boolean {
        return !this.nameserverIsDefault
                && this.metadata?.nameserver?.values?.nameservers !== null;
    }

    public set showDnsTemplate(_) {/* */}
    public get showDnsTemplate(): boolean {
        return this.zoneTypeSelectionViewable
            && (this.nameserverIsSet || this.nameserverIsDefault)
            && this.metadata?.dns?.type === 'template';
    }

    public set showDnsTemplateSettings(_) {/* */}
    public get showDnsTemplateSettings(): boolean {
        return this.zoneTypeSelectionViewable
            && this.showDnsTemplate
            && this.metadata.dns
            && [undefined, null, ''].indexOf(this.metadata.dns?.values?.templateId) < 0;
    }

    public set showQuickModeOptions(_) {/* */}
    public get showQuickModeOptions(): boolean {
        return this.zoneTypeSelectionViewable
            && (this.nameserverIsSet || this.nameserverIsDefault)
            && this.metadata.dns.type === 'quick';
    }

    public set showMasterIpSetting(_) {/* */}
    public get showMasterIpSetting(): boolean {
        return this.zoneTypeSelectionViewable
            && (this.nameserverIsSet || this.nameserverIsDefault)
            && this.metadata.dns.type === 'slave';
    }

    public set isOwnNameserversSelected(_) {/* */}
    public get isOwnNameserversSelected(): boolean {
        return this.metadata.nameserver.values.nameservers === null;
    }

    public set showNameserverSettings(_) {/* */}
    public get showNameserverSettings(): boolean {
        if (this.metadata.bundle) {
            const bundleHandler = ApiObject.bundle(this.metadata.bundle);
            return bundleHandler
                && !bundleHandler.hasWebspaceContingent;
        }

        return !this.metadata.webspace;
    }

    public getDnsTemplateData = (template: string): void => {
        if ([undefined, null, ''].indexOf(template) < 0) {
            void this.dnsTemplateModel.findRecords(template).then(
                (reply) => {
                    this.metadata.dns.values.records = reply;
                }
            );
        }
    };

    private _checkNameserverComplete = (): boolean => {
        if (
            this.metadata.nameserver?.values?.id !== null
            || this.metadata.nameserver?.values?.nameservers !== null
        ) {
            this.nameserversComplete = true;
            return true;
        }
        return false;
    };

    private _setNameserverButtonTypes = (): void => {
        this.nameserverTypeButtons = [];

        if (!this.metadata.nameserver.values) {
            return;
        }

        this.metadata.dns.type = 'empty';

        this.nameserverTypeButtons.push({ value: 'quick', label: this.$translate.instant('TR_110119-009f9e_TR') });
        this.nameserverTypeButtons.push({ value: 'empty', label: this.$translate.instant('TR_110119-310af0_TR') });

        // empty
        if (this.hasDnsTemplates) {
            this.nameserverTypeButtons.push(
                { value: 'template', label: this.$translate.instant('TR_110119-817fa3_TR') }
            );
        }
        this.nameserverTypeButtons.push({ value: 'slave', label: this.$translate.instant('TR_110119-5d0744_TR') });
    };

    private _checkIpsOfNameserverIsRequired = (
        nameservers?: ViewTypes.Nameservers[]
    ): boolean => {
        nameservers = nameservers || this.metadata.nameserver.values.nameservers;

        if (this.metadata.nameserver.values.default
            || this.metadata.nameserver.values.id !== null
        ) {
            // Defaultnameserver are selected
            this.metadata.nameserver.values.hasGlueRecord = this.wizardServiceHelperDomain.checkDomainHasGlueRecord(
                nameservers,
                this.metadata.selectedDomains
            );
        } else if (this.metadata.nameserver.values.id === null) {
            // Own nameserver
            this.metadata.nameserver.values.hasGlueRecord = this.wizardServiceHelperDomain.checkDomainHasGlueRecord(
                    this.metadata.nameserver.ownNameservers,
                    this.metadata.selectedDomains
                );
        }

        return this.metadata.nameserver.values.hasGlueRecord;
    };

    private setNameserverSets = (): void => {
        void this.wizardNewHelper.getNameserverSets(this.metadata.account.id)
            .then((nameserverSets) => {
                void this.$timeout(() => {
                    this.metadata.nameserver.values = this.wizardNewHelper.objectCache.nameserSets[0].items[0].value;
                    this.nameserverDropdownItems = nameserverSets;
                    this._checkIpsOfNameserverIsRequired();
                });
            });
    };

    private setWebspaceNameServer = (): void => {
        if (
            this.authContext.isGranted(UiRights.WEB_OBJECT_LIST)
            && this.metadata.webspace
        ) {
            this.metadata.dns.values = this.wizardNewHelper.setDnsSettings(this.metadata);
            this.metadata.dns.type = 'webspace';
        }
    };

    private  _checkGlueRecords = (nameserverObject: ViewTypes.NameserverDefinition): boolean => {
        const nameserverlist = this.isOwnNameserversSelected
            ? nameserverObject.ownNameservers
            : nameserverObject.values.nameservers;

        return nameserverlist.every((nameserver) => {
            if (nameserver.ipsRequired) {
                return nameserver?.ips?.length > 0;
            }

            return true;
        });
    };

    private _customeNameserverCompleted = (): boolean => {
        if (this.metadata.nameserver.ownNameservers?.length < 2) {
            return false;
        }

        return this.metadata.nameserver.ownNameservers.every((entry: ViewTypes.OwnNameserver) => {
            if ([undefined, null, ''].indexOf(entry.name) >= 0) {
                return false;
            }

            if (entry.nameserverErrors !== undefined && entry.nameserverErrors.length > 0) {
                return false;
            }

            return true;
        });
    };

    private _checkDnsRecordsUpdaterOnOwnNameserverChange = (): boolean => {
        if (JSON.stringify(this._ownNameservers) === JSON.stringify(this.metadata?.nameserver?.ownNameservers)) {
            return false;
        }

        const previousOwnNameserver = this._getNameserversRawData(this._ownNameservers);
        const currentOwnNameserver = this._getNameserversRawData(this.metadata?.nameserver?.ownNameservers);
        this._ownNameservers = ng.copy(this.metadata?.nameserver?.ownNameservers);

        return JSON.stringify(previousOwnNameserver) !== JSON.stringify(currentOwnNameserver);
    };

    private _getNameserversRawData = (nameservers: DnsNameserverInfoObject[]): DnsNameserverInfoObject[] => {
        if (!nameservers) {
            return [];
        }
        return nameservers.map(
            (nameserver) => ({
                ips: nameserver.ips,
                ipsRequired: nameserver.ipsRequired,
                name: nameserver.name
            })
        );
    };

    private _allDomainsHaveZones = (): boolean => {
        if (!this.metadata.zoneUpdateData) {
            return false;
        }

        const zoneData = Object.entries(this.metadata.zoneUpdateData as Record<string, keyof ZoneUpdateData>[]);
        const listOfSelectedDomains = this.metadata.selectedDomains.map((domain) => domain.domainNameAce);
        let noZoneNeedToCreate = true;
        for (const [domain, value] of zoneData) {
            if (listOfSelectedDomains.includes(domain) && value.create) {
                noZoneNeedToCreate = false;
            }
        }

        return noZoneNeedToCreate;
    };

    private _noSystemNameserverSelectedOrNameserverHasGlueRecords = (): boolean => {
        return this.metadata.nameserver.values.hasGlueRecord
            || (
                this.metadata.nameserver.values
                && !this.systemNameserverType
            );
    };

    private _slaveInfoComplete = (): boolean => {
        return this.metadata?.dns?.values?.masterIp?.length > 0
            && this.masterIpValidationErrorList.length === 0;
    };

    private _quickInfoComplete = (): boolean => {
        return this.metadata?.dns?.values?.ip?.length > 0
            && this.ipv4ValidationErrorList.length === 0;
    };

    private _templateInfoComplete = (): boolean => {
        if (
            [undefined, null, ''].indexOf(this.metadata.dns.values.templateId) >= 0
            || !this.requiredReplacementTypes
            || Object.keys(this.requiredReplacementTypes).length === 0
        ) {
            return false;
        }

        return Object.keys(this.requiredReplacementTypes).every((type: string) => {
            if (
                this.systemHelper.getPropertyOfObject(
                    this.requiredReplacementTypes,
                    type as keyof ViewTypes.RequiredReplacementTypes
                )
            ) {
                const replacementTypeValue = this.systemHelper.getPropertyOfObject(
                    this.metadata.dns?.values?.replacements,
                    type as keyof ViewTypes.DomainWizardDnsValuesReplacements
                );

                return replacementTypeValue && replacementTypeValue.length > 0;
            }

            return true;
        });
    };
}

export class OrganCreateDomainNameserversComponent implements ng.IComponentOptions {
    public bindings = {
        _domainZoneLists: '<domainZoneLists',
        complete: '=',
        metadata: '=',
        nameserversComplete: '='
    };
    public template = require('./domain-nameserver-config.html');
    public controller =  OrganCreateDomainNameserversController;
}
