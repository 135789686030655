export class ApiError extends Error {
    public name = 'ApiError';
    public apiResponse: any;
    public context: any;
    public code: number;
    constructor(errObj: any, public serverTransactionId?: string) {
        super(errObj.text);

        this.code = errObj.code;
        this.apiResponse = errObj;
        if (errObj.context) {
            this.context = errObj.context;
        }
    }
}
