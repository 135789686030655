import * as ng from 'angular';
import { LinkListOverviewItem } from '../../atomic-components/molecules/links/link-list-overview/link-list-overview';
import {
    AccountFilterField,
    SelectFilterField,
    TextFilterField
} from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { UiRights } from '../../configuration';
import { OverviewModel } from '../abstract';
import { AuthContextService } from '../auth-context';
import { RequestCancellerService } from '../request-canceller';
import { VhostModelService } from './vhost-model';

export class LegacyPhpVhostOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$q', '$state', 'vhostModel', 'authContext', 'objectStatusFilter', '$translate']
    );

    public readonly service = 'webhosting';
    public readonly objectType = 'LegacyPHPVHost';
    public readonly idField = 'vhostId';
    public readonly defaultFilterFields = ['VHostDomainNameUnicode'];

    public activeMassAction: string | boolean = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};

    protected prefixFilters = [{ field: 'VHostDomainNameAce', prefix: 'xn--' }];
    protected regexFilters = [{ field: 'VHostDomainNameAce', regex: /^.+\.xn--.+$/ }];

    protected listCallbacks = [];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $q: ng.IQService,
        private $state: ng.ui.IStateService,
        private vhostModel: VhostModelService,
        private authContext: AuthContextService,
        private objectStatusFilter,
        protected $translate: ng.translate.ITranslateService
    ) {
        super(RequestCanceller);

        // declare multi action availably methods
        this.actionLinksAvailabilities = {
            deletableDomains: this.deletableDomains,
            deletePermantlyDomains: this.deletePermantlyDomains,
            restorableDomains: this.restorableDomains
        };

        this.listCallbacks = [{
            callback: this.listVhosts
        }];
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('VhostDomainNameUnicode', this.$translate.instant('TR_140119-617fff_TR')),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR')),
                new SelectFilterField(
                    'VhostStatus',
                    this.$translate.instant('TR_140119-8e4b60_TR'),
                    [
                        {
                            name: this.objectStatusFilter('active'),
                            value: 'active'
                        },
                        {
                            name: this.objectStatusFilter('restorable'),
                            value: 'restorable'
                        }
                    ]
                )
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }

    public listVhosts = (limit?, page?, filters?, sort?, webspaceId?) => {
        const filter = { field: 'VHostUsesLegacyPhpVersion', value: '1' };

        if (filters) {
            filters = {
                subFilter: [filters, filter],
                subFilterConnective: 'AND'
            };
        } else {
            filters = filter;
        }

        return this.vhostModel.listWithoutPagination(limit, page, filters, sort, webspaceId);
    };

    public actionLinks = (): LinkListOverviewItem[] => {

        return [
            {
                isGranted: this.authContext.isGranted(UiRights.WEB_VHOST_EDIT),
                route: this.$state.$current.locals.globals.bundle
                    ? 'bundle.id.domains.minphpversion'
                    : 'webhosting.webspaces.id.vhosts.minphpversion',
                routeParams: {
                    machineId: null,
                    webspaceId: this.$state.params.webspaceId,
                    bundleId: this.$state.$current.locals.globals?.bundle?.id
                },
                text: this.$translate.instant('TR_300123-fdf071_TR')
            }
        ] as LinkListOverviewItem[];
    };

    public deletableDomains = (domains: any[]) => {
        const selectedDomains = domains.filter((domain) => this.selected[domain.id]);

        if (selectedDomains.length === 0) {
            return false;
        }

        return selectedDomains.every(
            (domain) => ['restorable'].indexOf(domain.status) < 0
            && [undefined, null].indexOf(domain.deletionScheduledFor) >= 0
        );
    };

    public deletePermantlyDomains = (domains: any[]) => {
        const selectedDomains = domains.filter((domain) => this.selected[domain.id]);

        if (selectedDomains.length === 0) {
            return false;
        }

        return selectedDomains.every(
            (domain) => domain.status === 'restorable'
        );
    };

    public restorableDomains = (domains: any[]) => {
        for (const domain of domains) {
            if (this.selected[domain.id] && domain.status === 'restorable') {
                return true;
            }
        }
        return false;
    };

    public deleteSubmit = (domains: any[], settings: any) => {
        let promises = [];

        const deletableDomains = domains
            .filter((domain) => [undefined, null, false].indexOf(this.selected[domain.id]) < 0)
            .filter((domain) => ['restorable'].indexOf(domain.status) < 0)
            .filter((domain) => [undefined, null, ''].indexOf(domain.deletionScheduledFor) >= 0);

        promises = deletableDomains.map(
            (domain) => {
                return this.vhostModel.delete(domain);
            }
        );

        return this.$q.all(promises);
    };

    public deleteRestoreSubmit = (domains: any) => {
        const domainsToBeDeleted = Object.keys(this.selected)
        .map(
            (elem) => this.selected[elem] ? this.getObjectFromId(domains, elem) : false
        );

        return this.$q.all(domainsToBeDeleted.map((domain) => domain.id).map(this.vhostModel.purgeRestorable));
    };

    public restoreSubmit = (domains: any[]) => {
        const domainList = domains.filter((domain) => {
            return this.selected[domain.id] && domain.status === 'restorable';
        });

        return this.vhostModel.restore(domainList);
    };

    public getObjectFromId = (objects, objectId) => {
        return objects.filter((object) => {
            return object.id === objectId;
        })[0];
    };

    public callVhostOverviewList = (overviewData) => {
        const limit = overviewData.pagination !== undefined && overviewData.pagination.limit !== undefined
            ? overviewData.pagination.limit
            : 10;
        const currentPage = overviewData.pagination !== undefined && overviewData.pagination.currentPage !== undefined
            ? overviewData.pagination.currentPage
            : 1;
        const simpleFilterValue = overviewData.filters !== undefined
            && overviewData.filters.simpleFilter !== undefined
            && overviewData.filters.simpleFilter.value !== undefined
                ? overviewData.filters.simpleFilter.value
                : '';
        const additionalFilters = overviewData.additionalFilters !== undefined
            ? overviewData.additionalFilters
            : [];
        const objectId = overviewData.objectId ? overviewData.object : undefined;
        const index = [undefined, null].indexOf(overviewData.index) === -1  ? overviewData.index : undefined;
        const sort = overviewData.sort ? overviewData : undefined;

        return this.list(
            limit,
            currentPage,
            simpleFilterValue,
            additionalFilters,
            objectId,
            index,
            sort
        );
    };
}
