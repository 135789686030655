import * as ng from 'angular';
import { StaffConst } from '../../../../configuration';
import { AuthContextService } from '../../../../services';

import './supporter-block.scss';

export class MoleculeSupporterBlockController implements ng.IController {
    public static $inject: string[] = ['getEntryPoint'];

    public backgroundSize: 'sm';
    public supportPhone: string = AuthContextService.accountOrganizationInformation.companyPhoneNumber;
    public supportPhoneMin ='';
    public supportMail: string = AuthContextService.accountOrganizationInformation.companyEmailAddress;
    public staff: {name: string; title: string} = {
        name: StaffConst.kahrs.name,
        title: StaffConst.kahrs.title
    };

    // Call getEntryPoint
    public $onInit(): void {
        this.supportPhoneMin = this.supportPhone.replace(/([() _-])/ig, '');
    }
}

export class MoleculeSupporterBlockComponent implements ng.IComponentOptions {
    public bindings = {
        backgroundSize: '@?'
    };
    public controller =  MoleculeSupporterBlockController;
    public template = require('./supporter-block.html');
}
