import { DatabaseAccessPasswordCharacterPoolsConst } from '../char-pools';

export type DatabaseAccessLevelsAllRightsType = 'read' | 'write' | 'schema';

export const DatabaseAccessLevelsAllRightsConst = [
    'read',
    'write',
    'schema'
];

export const DatabaseAccessObjectConst = {
    accessLevel: DatabaseAccessLevelsAllRightsConst,
    databaseId: '',
    userId: ''
};

export const DatabaseUserObjectConst = {
    accountId: '',
    comments: '',
    id: '',
    name: '',
    password: null
};

export const DatabaseObjectConst = {
    accesses: [],
    accountId: '',
    name: '',
    productCode: '',
    storageQuota: 0
};

export const DatabasePasswordValidationOptions = {
    characterPools: DatabaseAccessPasswordCharacterPoolsConst,
    illegalCharacters: '§/',
    maxLength: 128,
    minCharacterPoolsUsed: 3
};
