import * as ng from 'angular';

export class MoleculeButtonActivateController implements ng.IController {
    public static $inject: string[] = [];

    public disabled: boolean;
    public activate: () => void;
}

export class MoleculeButtonActivateComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        activate: '<',
        disabled: '<'
    };
    public controller =  MoleculeButtonActivateController;
    public template = require('./button-activate.html');
}
