import * as ng from 'angular';

import { ApiObject } from '../../../../../services';
import * as Types from '../../../../../types';
import { DomainTypes } from '../../../../organs';

export class OrganismDomainWizardConfigViewController {
    public static $inject: string[] = ['$state', '$stateParams', '$translate'];

    public bundle: Types.BundleApi.Bundle;
    public bundleHandler;
    public callback: (metaData: Types.ViewTypes.WizardProductsConfigDomain) => {};
    public configCompleted = false;
    public domainTypes = DomainTypes;
    public outerAccount: Types.AccountApi.Account;
    public ownerAccount:  Types.AccountApi.Account;
    public metadata: Types.ViewTypes.WizardProductsConfigDomain;
    public selectedDomainType: DomainTypes;
    public service = 'domain';
    public selectedDomainList: any[] = [];
    public sufficientCreditAvailable = true;
    public webspace: Types.WebhostingApi.Webspace;
    public webserverRessource: Types.ResourceApi.Webserver;
    public domainSearchData: Types.ViewTypes.WizardDomainSearchDataObject = {
        domainInfo: [],
        domainSearchString: '',
        numberOfSelectedDomains: 0
    };

    private _hideDomainTypeSelectionOnStart = true;
    private _account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    private _managedServerWebspaceItems: Types.WebhostingApi.Webspace[] = [];

    public constructor(
        private $state: ng.ui.IStateService,
        private $stateParams: ng.ui.IStateParamsService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = () => {
        if (this.bundle) {
            this.bundleHandler = ApiObject.bundle(this.bundle);
        }
    };

    public unsavedChangesExist = () => {
        return this.domainSearchData?.domainSearchString?.length > 0;
    };

    public finishConfiguration = () => {
        if (this.callback !== undefined) {
            this.callback(this.metadata);
        }
    };

    get account(): Types.AccountApi.Account | Types.AccountApi.Subaccount {
        return this._account;
    }

    set account(value: Types.AccountApi.Account | Types.AccountApi.Subaccount) {
        if (
            [undefined, null].indexOf(value) < 0
            && value !== this._account
            && [undefined, null].indexOf(this.metadata) < 0
        ) {
            this.metadata.account = value;
        }

        this._account = value;
    }

    public set configurationIsCompleted({}) {} // tslint:disable-line:no-empty
    public get configurationIsCompleted() {
        return this.sufficientCreditAvailable
            && this.configCompleted;
    }

    public get orderButtonText() {
        return [DomainTypes.EXTERNAL, DomainTypes.SUBDOMAIN].includes(this.selectedDomainType)
            ? this.$translate.instant('252de112-5642-40b6-b8b0-643e6a04e469')
            : this.$translate.instant('TR_110119-6e44c0_TR');
    }

    public set hideDomainTypeSelectionOnStart({}) {} // tslint:disable-line:no-empty
    public get hideDomainTypeSelectionOnStart() {
        const routeParts = this.$state.current.name.split('.');
        if (this.$state.$current?.locals?.globals?.$stateParams?.domainType) {
            // user comes from route with was stateParams domainType
            switch (this.$state.$current.locals.globals.$stateParams.domainType) {
                default:
                case 'register':
                    this.selectedDomainType = DomainTypes.REGISTER;
                    break;
                case 'external':
                    this.selectedDomainType = DomainTypes.EXTERNAL;
                    break;
                case 'subdomain':
                    this.selectedDomainType = DomainTypes.SUBDOMAIN;
                    break;
            }
            this._hideDomainTypeSelectionOnStart = true;
        } else if ([undefined, null].indexOf(this.$stateParams.domainList) === -1
            && this.$stateParams.domainList.length > 0) {
            // user comes from domaincheck panle - only domain register/transfer allowed
            // isnt this not the same as before??
            this.selectedDomainType = DomainTypes.REGISTER;
            this._hideDomainTypeSelectionOnStart = true;
        } else if (this.domainSearchData.domainInfo.length > 0) {
            // domainInfo is set (perhaps, backlink of summary page), hide domainTypeSelection panel
            // same here as before???
            this._hideDomainTypeSelectionOnStart = false;
        } else if (this.bundleHandler !== undefined
            && !this.bundleHandler.hasWebspaceContingent) {
            // user comes from email bundle
            this.selectedDomainType = DomainTypes.REGISTER;
            this._hideDomainTypeSelectionOnStart = true;
        } else {
            this._hideDomainTypeSelectionOnStart = false;
        }
        return this._hideDomainTypeSelectionOnStart;
    }
}

export class OrganismDomainWizardConfigViewComponent implements ng.IComponentOptions {
    public bindings = {
        _managedServerWebspaceItems: '<?managedServerWebspaceItems',
        bundle: '<',
        callback: '<',
        outerAccount: '<',
        ownerAccount: '<?',
        webspace: '<?',
        webserverRessource: '<?'
    };

    public template = require('./domain-wizard-config-view.html');
    public controller = OrganismDomainWizardConfigViewController;
}
