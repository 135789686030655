import { AccountApi, ViewTypes } from '@/types';
import * as ng from 'angular';

export class OrganismMachineWizardProductConfigManagedVirtualMachineController {
    public static $inject: string[] = [];

    public metadata: ViewTypes.ProductConfigMachineObject;
    public _selectedProductCode: string = null;
    public service = 'machine';
    public hostNameValidationOptions = {
        allowEmpty: true
    };
    public formCompleted = {
        name: () => this.metadata?.virtualMachine?.name?.length > 0
    };

    private _account: AccountApi.Account | AccountApi.Subaccount;

    get account(): AccountApi.Account | AccountApi.Subaccount {
        return this._account;
    }

    set account(value: AccountApi.Account | AccountApi.Subaccount) {
        // this check looks strange, but it is correct! Do not change it ;)
        if (value && this.metadata && this.metadata.account) {
            this.metadata.account = value;
        }

        this._account = value;
    }

    public get selectedProductCode(): string {
        return this._selectedProductCode;
    }
    public set selectedProductCode(value: string) {
        if (this._selectedProductCode !== value) {
            this._selectedProductCode = value;

            if ([undefined, null, ''].indexOf(value)
                && this.metadata !== undefined
            ) {
                this.metadata.productCode = value;
            }
        }
    }

    public set configCompleted({}) {} /* tslint:disable-line:no-empty */ // used by parent
    public get configCompleted() { // used by parent
        for (const part in this.formCompleted) {
            if (!this.formCompleted[part]()) {
                return false;
            }
        }

        return true;
    }

    public $onInit = () => {
        this._setMetadata();
    };

    private _setMetadata = () => {
        this.metadata = {
            account: this._account,
            productCode: this.selectedProductCode,
            productFamily: undefined,
            virtualMachine: {
                name: ''
            }
        };
    };
}

export class OrganismMachineWizardProductConfigManagedVirtualMachineComponent implements ng.IComponentOptions {
    public bindings = {
        account: '<',
        configCompleted: '=configCompleted',
        metadata: '=',
        selectedProductCode: '='
    };

    public template = require('./machine-wizard-product-config-managed-virtual-machine.html');
    public controller = OrganismMachineWizardProductConfigManagedVirtualMachineController;
}
