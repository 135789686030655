/**
 * Returns the amount of VAT for a product.
 *
 * @param productCode Used to determine how the VAT is calculated.
 * @param startDate The start date of the product. I guess this SHOULD just default to `new Date()`...
 * @param billingPeriod The billing period in months.
 * @param netPrice The net price in API format.
 * @param accountCountry The country of the current user. Leave this as 'de' for the shopping cart.
 * @param usualVatRate The VAT rate returned from the API. Will only be used if the country is NOT 'de'.
 * @returns The VAT amount in API format.
 */
export function getVatAmount(
    startDate: Date,
    billingPeriod: number,
    netPrice: number,
    accountCountry: string = 'de',
    usualVatRate: number = 0
): number {
    // If the current user is from anywhere but Germany, simply use the usual VAT rate.
    if (accountCountry.toLowerCase() !== 'de') {
        return calculateVatAmount(netPrice, usualVatRate);
    }

    /*
     * "Dauerschuldverhältnis"
     *
     * For all products EXCEPT domains and SSL certificates,
     * the customer pays for us to deliver a service for a certain timespan.
     * The VAT will be 16% ONLY for those sub-periods which end after 2020-07-01 AND by 2020-12-31.
     */

    let totalVatAmount = 0;

    if (billingPeriod) {
        for (let month = 1; month <= billingPeriod; month ++) {
            const endDate = new Date(startDate.getTime());
            const endMonth = endDate.getMonth() + month;

            endDate.setFullYear(endDate.getFullYear() + Math.floor(endMonth / 12));
            endDate.setMonth(endMonth % 12);

            /*
            * Note: endDate can now contain bullshit dates like 2021-02-31.
            * For current purposes, this is "good enough", since I use lexicographic comparison
            * to determine whether or not the date is in the relevant range.
            * It is not, however, "correct", and if we plan to use this for more than the currently
            * expected amount of time, we'll want to fix this.
            */

            if (dateIsInRange(endDate)) {
                totalVatAmount += calculateVatAmount(netPrice / billingPeriod, 1600);
            } else {
                totalVatAmount += calculateVatAmount(netPrice / billingPeriod, usualVatRate);
            }
        }
    } else {
        totalVatAmount = calculateVatAmount(netPrice, usualVatRate);
    }

    return totalVatAmount;
}

/**
 * Check whether a date is in the relevant range.
 *
 * @param date The date to be checked.
 * @param startDate The start date of the relevant range.
 * @param endDate The end date of the relevant range.
 */
function dateIsInRange(
    date: Date,
    startDate: string = '2020-07-01',
    endDate: string = '2020-12-31'
): boolean {
    // Get date part of ISO time string
    const datePart = date.toISOString().split('T')[0];

    return startDate <= datePart && datePart <= endDate;
}

/**
 * Separate helper function in case we need to do fancy rounding magic at this point again...
 *
 * @param netPrice The net price in API format.
 * @param vatRate The VAT rate in API format.
 * @returns The VAT amount in API format.
 */
function calculateVatAmount(netPrice: number, vatRate: number): number {
    return netPrice * vatRate / 10000;
}
