import * as ng from 'angular';

import * as Types from '@/types';
import { BundleProductFamilies, ProductFamiliesConst } from '@/configuration';
import { AsteriskNoteService, ScrollToValidationError, WizardNewHelperService } from '@/services';
import { AccountApi, ViewTypes } from '@/types';

export class OrganismBundleWizardConfigViewController {
    public static $inject: string[] = [
        '$timeout',
        'asteriskNote',
        'wizardNewHelper'
    ];

    public callback: (metaData: ViewTypes.ProductConfigBundleObject) => void;
    public outerAccount: Types.AccountApi.Account;
    public outerProductCode: string; // todo: has to be implemented (if set product has to be preselected)
    public productCodeList: string[] = [];
    public productFamilies: string[] = ProductFamiliesConst.bundle;
    public productFamily: string;
    public service = 'bundle';
    public sufficientCreditAvailable = true;
    public selectedProductCode: string = null;
    public metaData: ViewTypes.ProductConfigBundleObject;
    public configCompleted: boolean; // used in template

    private _account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    private _metaData: ViewTypes.ProductConfigBundleObject;
    private _selectedProductCode: string = null;
    private _lastCheckedDomain = '';
    private _lastDomainProductCode = '';

    public constructor(
        private $timeout: ng.ITimeoutService,
        private asteriskNote: AsteriskNoteService,
        private wizardNewHelper: WizardNewHelperService
    ) {}

    public $onInit(): void {
        this.asteriskNote.resetNodes();
        if ([undefined, null].indexOf(this.outerAccount) < 0) {
            this.account = this.outerAccount;
        }
    }

    public unsavedChangesExist = (): boolean => {
        return this.metaData?.bundleName?.length > 0
            || this.metaData?.domain?.nameUnicode?.length > 0
            || this.metaData?.accesses?.password?.length > 0;
    };

    public $doCheck(): void {
        /**
         * Okay, maybe not the most performant solution, but ey, you got a better idea?
         */
        if (this.metaData !== this._metaData) {
            if (
                this.metaData
                && this.metaData.domain
                && !this.metaData.domain.vHostOnly
                && this.metaData.domain.nameUnicode !== this._lastCheckedDomain
            ) {
                void this.wizardNewHelper.getBillableDomainProductCode(
                    this.metaData.domain.nameUnicode,
                    this.selectedProductCode,
                    this.account.id
                ).then(
                    (response) => {
                        if (response === '') {
                            return;
                        }

                        if (this._lastDomainProductCode !== '') {
                            this.productCodeList = this.productCodeList.filter(
                                (element) => element !== this._lastDomainProductCode
                            );
                        }

                        this.productCodeList.push(response);
                        this._lastDomainProductCode = response;
                    }
                );

                this._lastCheckedDomain = this.metaData.domain.nameUnicode;
            }

            this._metaData = ng.copy(this.metaData);
        }

        if (this.selectedProductCode !== this._selectedProductCode) {
            this.productCodeList = this.productCodeList.filter((element) => element !== this._selectedProductCode);

            if ([undefined, null].indexOf(this.selectedProductCode) < 0) {
                this.productCodeList.push(this.selectedProductCode);
            }

            this._selectedProductCode = ng.copy(this.selectedProductCode);
        }
    }

    get account(): AccountApi.Account | AccountApi.Subaccount {
        return this._account;
    }

    set account(value: AccountApi.Account | AccountApi.Subaccount) {
        if (
            [undefined, null].indexOf(value) < 0
            && value !== this._account
            && [undefined, null].indexOf(this.metaData) < 0
        ) {
            this.metaData.account = value;
        }

        this._account = value;
    }

    public get isEmailBundle(): boolean { // used in template
        return this.productFamily === BundleProductFamilies.email;
    }

    public get isWebhostingBundle(): boolean { // used in template
        return this.productFamily === BundleProductFamilies.webspace;
    }

    public set configurationIsCompleted(_value: unknown) { /* do nothing */ }
    public get configurationIsCompleted(): boolean {
        return this.configCompleted && this.sufficientCreditAvailable;
    }

    public scrollToError = (): void => {
        ScrollToValidationError.scrollToError(this.$timeout);
    };

    public finishConfiguration = (): void => {
        if (this.callback !== undefined) {
            this.metaData.productCode = this.selectedProductCode;
            this.callback(this.metaData);
        }
    };
}

export class OrganismBundleWizardConfigViewComponent implements ng.IComponentOptions {
    public bindings = {
        callback: '<',
        productFamily: '=',
        outerAccount: '<',
        outerProductCode: '<'
    };
    public template = require('./bundle-wizard-config-view.html');
    public controller = OrganismBundleWizardConfigViewController;
}
