import * as ng from 'angular';
import { ArticleSearchModelService } from '../../../../services';

export class OrganismHelpdeskDashboardController implements ng.IController {
    public static $inject: string[] = ['$translate', 'articleSearchModel'];

    public pageHeaderData = '';
    public rootCategories;
    public searchValue: string;
    public apiDown = false;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private articleSearchModel: ArticleSearchModelService
    ) {}

    public $onInit() {
        this.articleSearchModel.getRootCategories().then(
            (reply: any) => {
                if (reply.data.error !== undefined) {
                    this.apiDown = true;
                    return;
                }
                this.rootCategories = reply.data;
                if (typeof reply.data !== 'object' || !('title' in reply.data[0])) {
                    this.articleSearchModel.reportError(this.$translate.instant('TR_110119-75f5bc_TR'));
                }
            },
            (error) => {
                this.articleSearchModel.reportError(this.$translate.instant('TR_110119-9510c0_TR'));
            }
        );
    }
}

export class OrganismHelpdeskDashboardComponent implements ng.IComponentOptions {
    public bindings = {
        searchValue: '<'
    };
    public controller = OrganismHelpdeskDashboardController;
    public template = require('./dashboard.html');
}
