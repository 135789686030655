import * as ng from 'angular';
import * as Types from '../../../../../types';
import { VhostModelService, WebspaceModelService } from './../../../../../services/';
import { DomainTypes } from './../../../create';

export class OrganCreateConfigurationVhostController implements ng.IController {
    public static $inject: string[] = ['$translate', 'vhostModel', 'webspaceModel'];

    public configCompletedVhost: boolean;
    public domainnameUnicode: string;
    public metadata: Types.ViewTypes.ProductConfigDomainDomainObject;
    public vhostNameUnicode: string;
    public webspaceName: string;

    private _checkingExisitingVhosts = true;
    private _dismatch = false;
    private _existingsVhosts: Types.WebhostingApi.VHost[] = [];
    private _vhostType: DomainTypes = DomainTypes.EXTERNAL;
    private _webspaceId: string;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private vhostModel: VhostModelService,
        private webspaceModel: WebspaceModelService
    ) {}

    public $onInit(): void {
        this._checkExistingVhosts();
        this._webspaceId = ng.copy(this.metadata?.webspace?.id);

        if (this.metadata.selectedDomains?.length > 0) {
            this.domainnameUnicode = this.metadata.selectedDomains[0].domainName;
        }
    }

    public $doCheck(): void {
        if (this.metadata.webspace !== null
            && this.metadata?.webspace?.id !== this._webspaceId
        ) {
            if (!this.existingVhosts && this.metadata.vhost) {
                this.metadata.vhost.webspaceId = this.metadata.webspace
                    ? this.metadata.webspace.id
                    : '-1';
            }

            this._webspaceId = this.metadata.webspace
                ? ng.copy(this.metadata.webspace.id)
                : null;
            this.webspaceName = this.metadata.webspace
                ? this.metadata.webspace.name
                : '';
        }
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set createDomain({}) {}
    public get createDomain(): boolean {
        return this._vhostType === DomainTypes.REGISTER;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set createSubdomain({}) {}
    public get createSubdomain(): boolean {
        return this._vhostType === DomainTypes.SUBDOMAIN;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set createExternalDomain({}) {}
    public get createExternalDomain(): boolean {
        return this._vhostType === DomainTypes.EXTERNAL;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set panelTitle({}) {}
    public get panelTitle(): string {
        if (this.createExternalDomain) {
            return this.$translate.instant('TR_110119-94ce69_TR');
        }
        if (this.createSubdomain) {
            return this.$translate.instant('TR_110119-4bd718_TR');
        }

        return this.$translate.instant('TR_180220-ea78c2_TR');
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set existingVhosts({}) {}
    public get existingVhosts(): number {
        return this._existingsVhosts.length;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set listOfExistingVhosts({}) {}
    public get listOfExistingVhosts(): string[] {
        return this._existingsVhosts.map((vhost) => vhost.domainNameUnicode);
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set configCompleted({}) {}
    public get configCompleted(): boolean {
        if (this.existingVhosts === 1) {
            return !this._dismatch;
        }

        return this.configCompletedVhost;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set checkingExisitingVhosts({}) {}
    public get checkingExisitingVhosts(): boolean {
        return this._checkingExisitingVhosts;
    }

    private _checkExistingVhosts = (): void => {
        if (!this.metadata.selectedDomains || this.metadata.selectedDomains.length === 0) {
            this._existingsVhosts = [];
            this.metadata.vhost = null;
            this.metadata.vhostExists = false;
            this._checkingExisitingVhosts = false;
            return;
        }

        this._checkingExisitingVhosts = true;
        const filters = {
            subFilter: this.metadata.selectedDomains.map(
                (domain) => ({ field: 'vhostDomainNameUnicode', value: domain.domainName })
            ),
            subFilterConnective: 'OR'
        };

        void this.vhostModel.listWithoutPagination(null, 1, filters)
            .then(
                (vhosts) => {
                    this._existingsVhosts = vhosts.data;
                    this._checkingExisitingVhosts = false;
                    this.metadata.vhostExists = vhosts.data.length === 1;

                    if (this.metadata.vhostExists) {
                        this.metadata.vhost = vhosts.data[0];
                        this.vhostNameUnicode = this.listOfExistingVhosts[0];
                        return this.webspaceModel.findOne(this.metadata.vhost.webspaceId);
                    }

                    this.metadata.vhost = null;
                    this.vhostNameUnicode = '';
                    return null;
                }
            )
            .then(
                (vhostWebspace: Types.WebhostingApi.Webspace) => {
                    this.webspaceName = vhostWebspace
                        ? vhostWebspace.name
                        : '';
                }
            );
    };
}

export class OrganCreateConfigurationVhostComponent implements ng.IComponentOptions {
    public bindings = {
        _vhostType: '<?vhostType',
        configCompleted: '=',
        metadata: '='
    };

    public template = require('./vhost-config.html');
    public controller =  OrganCreateConfigurationVhostController;
}
