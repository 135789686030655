import {
    AlertManagerService,
    AuthContextService,
    LocalstorageHelperService,
    NavigationService,
    OverviewHelperService,
    OverviewModel
} from '@/services';
import {
    LinkListItem,
    OverviewModelUtilityChart,
    UtilityStatusObject
} from '../../molecules';
import { DEFAULT_TIMEOUT_REFRESH_INTERVAL } from '@/configuration/system';

import ng from 'angular';
import * as Types from '@/types';

import './overview.scss';

interface AdditionOverviewData {
    bundle?: any;
    webspace?: any;
}

export interface DisableFilter {
    fancyFilter: boolean;
    searchFilter: boolean;
}

export interface OverviewDataObject {
    filters: {
        simpleFilter: any;
        extendedFilters: any;
    };

    pagination: {
        currentPage: number;
        entries: number;
        limit: number;
    };

    serviceItemList: any[];
    objectId?: string;
    additionalFilters?: any[];
    additionData?: AdditionOverviewData;
    sort?: {};
}

interface OverviewLoadServiceCounter {
    serviceName: string;
    counter: number;
}

export class OrganismOverviewController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        '$state',
        '$stateParams',
        'alertManager',
        'localstorageHelper',
        'navigation',
        'overviewHelper'
    ];

    public expandWrapper: (arg0: any) => any;
    public expandWrapperValue: boolean;
    public disableFilter: DisableFilter;
    public overviewData: OverviewDataObject;
    public bundle: Types.BundleApi.Bundle;
    public webspace: Types.WebhostingApi.Webspace;
    public expertView: boolean;
    public availableFilters: any;
    public overviewServiceData: any;
    public rowData: any;
    public overviewModel: OverviewModel;
    public overviewLoaded: boolean;
    public accountId: string;
    public dataNotLoadedOnInit = false;
    public hideTopBar = false;
    public reloadStateOnRefresh = false;

    public sortOrder: Types.Finding.SortOptions;

    public internalPaginationLimit: number;

    private _actionLinks: LinkListItem[] = undefined;
    private _overviewLoaded: OverviewLoadServiceCounter[] = [];

    constructor(
        private $timeout: ng.ITimeoutService,
        private $state: ng.ui.IStateService,
        private $stateParams: ng.ui.IStateParamsService,
        private alertManager: AlertManagerService,
        private localstorageHelper: LocalstorageHelperService,
        private navigation: NavigationService,
        private overviewHelper: OverviewHelperService
    ) {}

    public get globals() {
        if ([undefined, null].indexOf(this.$state.$current.locals) >= 0) {
            return {
                globals: null
            };
        }

        return this.$state.$current.locals.globals;
    }

    public $onInit = () => {
        if ([undefined, null].indexOf(this.overviewModel.availableFilters) === -1) {
            this.overviewModel.availableFilters.forEach((filter) => filter.value = null);
        }
        this.expandWrapperValue = false;

        this.disableFilter = this._setdDisableFilter();

        this.overviewData = {
            additionData: this.overviewServiceData.additionData,
            additionalFilters: this.additionalFilters,
            filters: {
                extendedFilters: this.overviewServiceData.extendedFilters,
                simpleFilter: this.overviewServiceData.simpleFilter
            },
            objectId: this.overviewServiceData.objectId || null,
            pagination: {
                currentPage: 1,
                entries: 0,
                limit: this.overviewServiceData.overviewUiSettings.paginationLimit
            },
            serviceItemList: [],
            sort: this.overviewServiceData.sort || null
        };

        const localStorageData = this.localstorageHelper.getLastSearchSettingsForCurrentUser();
        if (localStorageData !== null && localStorageData.filters) {
            this.overviewData.filters = localStorageData.filters;
        }

        if (this.overviewServiceData.overviewUiSettings.expertView === true) {
            this.overviewData.filters.simpleFilter.show = true;
        }

        this.internalPaginationLimit = this.overviewServiceData.overviewUiSettings.paginationLimit;

        // Initial page load
        void this.$timeout(() => {
            void this.onLoad();
        });
    };

    public isGranted = (rightName: string): boolean => {
        return AuthContextService.isGranted(rightName);
    };

    public get selectedItems() {
        const self = this;
        return this.overviewData.serviceItemList.filter((item) => {
            return self.isSelected(item.id);
        });
    }

    public get selectedAll(): boolean {
        return !this.overviewData.serviceItemList.some(
            (item) => [undefined, null, false].indexOf(this.overviewModel.selected[item.id]) >= 0
        );
    }

    public set selectedAll(value: boolean) {
        if (value) {
            this.overviewData.serviceItemList.map((item) => this.overviewModel.selected[item.id] = true);
        } else {
            this.overviewModel.selected = {};
        }
    }

    public set selectedAllIndeterminate(_) {/*  */}
    public get selectedAllIndeterminate(): unknown {
        return this.overviewHelper.selectedAllIndeterminate(
            this.overviewData.serviceItemList,
            this.overviewModel.selected
        );
    }

    private get additionalFilters(): Types.Finding.Filter[] {
        const filters: Types.Finding.Filter[] = [];

        /**
         * Note: Until the API images filters for bundleIds in jobs,
         * we see here that bundleId is not set as a filter for jobs
         * Markus: I think it makes more sense to query the service here,
         * which should explicitly query the filter with the bundleid, as
         * the service in which the bundleid should not be queried with.
         */
        if (this.bundle && ['email', 'database', 'domain'].indexOf(this.overviewModel.service) >= 0) {
            filters.push({
                field: 'BundleId',
                value: this.bundle.id
            });
        }
        if (this.accountId !== undefined) {
            filters.push({
                field: 'AccountId',
                value: this.accountId
            });
        }

        switch (this.$state.current.name) {
            case 'account.users.id.edit':
            case 'reseller.subaccounts.id.users.id.edit':
                filters.push({
                    field: 'UserId',
                    value: this.$state.$current.locals.globals.userData.id
                });
                break;
            case 'account.users.overview':
                filters.push({
                    field: 'AccountId',
                    value: AuthContextService.account.id
                });
                break;
            case 'billing.payments-overview':
                filters.push({
                    subFilter: [
                        {
                            field: 'service',
                            relation: 'unequal',
                            value: 'billing'
                        },
                        {
                            field: 'service',
                            relation: 'unequal',
                            value: ''
                        }
                    ],
                    subFilterConnective: 'AND'
                });
                break;
            case 'database.users.id.databases.overview':
                filters.push({
                    field: 'DatabaseAccessesUserId',
                    value: this.$state.$current.locals.globals.user.id
                });
                break;
            case 'dns.ddns.credentials.id.edit':
                filters.push({
                    field: 'DynamicDnsHostnameCredentialsId',
                    value: this.$state.$current.locals.globals.ddnsCredentials.id
                });
                break;
            case 'dns.templates.id.linked-zones.overview':
                filters.push({
                    field: 'TemplateId',
                    value: this.$state.$current.locals.globals.template.id
                });
                break;
            case 'domain.contacts.id.domains.overview':
                filters.push({
                    field: 'contactHandle',
                    value: this.$state.$current.locals.globals.contact.handle
                });
                break;
            case 'managed-servers.id.databases.overview':
                filters.push({
                    field: 'DatabaseServerId',
                    value: this.$state.$current.locals.globals.databaseRessource.id
                });
                break;
            case 'managed-servers.id.mailboxes.overview':
                filters.push({
                    field: 'poolId',
                    value: this.$state.$current.locals.globals.pool.id
                });
                break;
            case 'managed-servers.id.webspaces.overview':
                filters.push({
                    field: 'webserverId',
                    value: this.$state.$current.locals.globals.webserverRessource.id
                });
                break;
            case 'profile':
                filters.push({
                    field: 'UserId',
                    value: AuthContextService.user.id
                });
                break;
            case 'ssl.certificates.id.jobs':
                filters.push({
                    field: 'JobDisplayName',
                    value: this.$state.$current.locals.globals.certificate.commonName
                });
                break;
            case 'webhosting.webspaces.id.vhosts.overview':
                filters.push({
                    field: 'WebspaceId',
                    value: this.$stateParams.webspaceId
                });
                break;
            default:
                break;
        }
        return filters;
    }

    /**
     * actionLinks()
     *
     * - actionLinks are defined in SERVICE-overview-model
     * - Include massactions links (Massenaktionen)
     *
     * @returns LinkListItem
     */
    public get actionLinks(): LinkListItem[] {
        if (!this.overviewModel) {
            return [];
        }

        if (this._actionLinks === undefined && this.overviewModel.actionLinks !== undefined) {
            this._actionLinks = this.overviewModel.actionLinks(
                {
                    bundle: this.bundle ? this.bundle : undefined
                }
            );
        }

        return this._actionLinks;
    }

    /**
     * Overview load function from outside
     */
    public overviewLoad = (
        parameterThatIsSometimesThePaginationLimitForSomeReason?: number | boolean,
        forceLoad?: boolean,
        fancyFilterSearch?: boolean
    ): void => {
        this.overviewLoaded = false;
        this.slowLoadChecker();
        void this.$timeout(() => {
            void this.onLoad(
                ([undefined, null].indexOf(parameterThatIsSometimesThePaginationLimitForSomeReason) === -1),
                forceLoad,
                ([undefined, null].indexOf(fancyFilterSearch) === -1)
            );
        });
    };

    public onLoad = (scrollToTop?: boolean, forceLoad?: boolean, fancyFilterSearch?: boolean) => {
        if (this.overviewModel === undefined) {
            console.error('overviewModel is not defined');
        }
        if (fancyFilterSearch) {
            this.overviewData.pagination.currentPage = 1;
        }
        if ([undefined, false].indexOf(forceLoad) >= 0) {
            if (
                this._getServiceLoadedCounter() === 0
                && this.$state.current.name.indexOf('.cronjobs.overview') < 0
                && [undefined, null].indexOf(this._uiSettingsDataLoading()) === -1
                && !this._uiSettingsDataLoading()
                && [undefined, null, ''].indexOf(this.overviewData.filters.simpleFilter.value) >= 0
            ) {
                // Do not load data on first call - UiSettings
                this.dataNotLoadedOnInit = true;
                this.overviewLoaded = true;
                return;
            }
            this._setServiceLoadedCounter();
        } else {
            if (this.overviewLoaded === undefined) {
                this.overviewLoaded = true;
            }
        }
        if (this.overviewLoaded === undefined) {
            this.overviewLoaded = true;
        }
        this.dataNotLoadedOnInit = false;
        if (
            this.overviewModel.cutomizedModelListMethods !== undefined
                && this.overviewModel.cutomizedModelListMethods !== false
        ) {
            /**
             *  if a customized overview onload method exists,
             *  perform this customized method
             */
            this.overviewModel.overviewData = this.overviewData;
            if ([undefined, null].indexOf(this.overviewModel.cutomizedModelListMethods) === -1) {
                return (
                    this.overviewModel.cutomizedModelListMethods as { [key: string]: () => ng.IPromise<any> }
                )
                .overviewOnLoad()
                .then(this.onPageData(scrollToTop));
            }
        }
        // Call default overview onLoad method
        return this.overviewModel.list(
            this.overviewData.pagination.limit,
            this.overviewData.pagination.currentPage,
            this.overviewData.filters.simpleFilter.value,
            this.additionalFilters,
            this.overviewData.objectId || null,
            null,
            [undefined, null].indexOf(this.sortOrder) < 0 ? this.sortOrder : this.overviewData.sort
        ).then(
            this.onPageData(scrollToTop)
        );
    };

    public toggleSelected = (item: any) => {
        this.overviewModel.selected[item.id] = !this.overviewModel.selected[item.id];
    };

    public clickRow(item: any, itemEdit?: boolean, $event?: any): void {
        if(item === undefined) {
            return;
        }
        if (item.status === 'deleting' || this.overviewHelper === undefined) {
            return;
        }
        if ($event) {
            $event.stopPropagation();
        }
        itemEdit = itemEdit || false; // itemEdit isset true, if in all cases the edit route should be called!
        this.overviewHelper.handleRowClickRoutes(
            this.overviewModel.service,
            this.overviewModel.objectType,
            item, this.overviewModel.selected,
            this.bundle,
            this.expertView,
            itemEdit
        );
    }

    public clickBundle = (bundleId: string, event): void => {
        if (event !== undefined) {
            event.stopPropagation();
        }
        void this.navigation.go('bundle.id.dashboard', {bundleId: bundleId});
    };

    /**
     *  Action link availabily check - call overview model method
     */
    public actionLinkClickable = (linkMethod) => {
        if ([undefined, null, '', true, false].indexOf(linkMethod) >= 0) {
            return typeof linkMethod === 'boolean' ? linkMethod : true;
        }

        return this.overviewModel.actionLinksAvailabilities[linkMethod](this.overviewData.serviceItemList);
    };

    /**
     *  Submit Action Methode - call overview-model Methode
     */
    public submitMassAction = (submitObject) => {
        const actionMethode = submitObject.hasOwnProperty('methode') ? submitObject.methode : submitObject;
        if (this.overviewModel[actionMethode] === undefined) {
            return Promise.reject(false);
        }
        return this.overviewModel[actionMethode](this.overviewData.serviceItemList, submitObject);
    };

    /**
     *  Optional: utilityChart information on bundle
     */
    public bundleUtilityChart = (): false | UtilityStatusObject => {
        let overviewModelUtilityChart: OverviewModelUtilityChart = null;
        let effectiveContingentUsage = null;

        if ([undefined, null].indexOf(this.bundle) < 0 && this.overviewModel.bundleUtilityChart !== undefined) {
            overviewModelUtilityChart = this.overviewModel.bundleUtilityChart();
            effectiveContingentUsage = this.getEffectiveContingentUsage(overviewModelUtilityChart.productcodes);

            return {
                inUse: overviewModelUtilityChart.inUse ? effectiveContingentUsage.usedCapacity : '0',
                infoText: overviewModelUtilityChart.infoText,
                // isStorage?: string,
                maxUse: overviewModelUtilityChart.maxUse ? effectiveContingentUsage.totalCapacity : '0',
                showAbsolute: overviewModelUtilityChart.showAbsolute
            };
        }

        return false;
    };

    private _setdDisableFilter = () => {
        const disableFilter = this.overviewModel.getDisableFilter();
        if (disableFilter !== null) {
            return disableFilter;
        }

        return this.disableFilter || {
            fancyFilter: false,
            searchFilter: false
        };
    };

    private onPageData = (scrollToTop?: boolean) => {
        return (result: any) => {
            void this.$timeout(
                () => {
                    // reset selected items after reload
                    this._setServiceLoadedCounter();
                    this.overviewLoaded = true;

                    let preventScrolling = false;

                    if (this.$state.current.name === 'reseller.subaccounts.id.edit') {
                        // Quickfix because Michael Ernst is annoyed by the page jumping
                        // to the top when the user list is actually at the bottom...
                        preventScrolling = true;
                    }

                    // Scrolls to the top of the site
                    if (scrollToTop && !preventScrolling) {
                        window.scrollTo(0, 0);
                    }

                    this.overviewModel.selected = {};

                    // Set overview data
                    this.overviewData.serviceItemList = result.data;

                    if ([undefined, null].indexOf(result.pagination) < 0) {
                        this.overviewData.pagination = result.pagination;
                        this.internalPaginationLimit = result.pagination.limit;
                    }
                    this.disableFilter = this._setdDisableFilter();
                }
            );
        };
    };

    // If the overview needs more than 5s to load show a message
    private slowLoadChecker = (): void => {
        void this.$timeout(() => {
            if (!this.overviewLoaded) {
                this.alertManager.info(/* translationID */'TR_060519-4f289c_TR');
            }
        }, DEFAULT_TIMEOUT_REFRESH_INTERVAL);
    };

    private getEffectiveContingentUsage = (
        productcodes: string[]
    ): {capacity: string; capacityUsed: string} => {
        let contingentUsage = {
            capacity: '0',
            capacityUsed: '0'
        };

        if (this.bundle !== null) {
            this.bundle.effectiveContingentUsage
            .filter(
                (usage) => usage.productCodes.some(
                    (code) => productcodes.some(
                        (pcode) => code.indexOf(pcode) >= 0
                    )
                )
            )
            .map(
                // Assumption: This only applies to one contingent
                (usage) => contingentUsage = {
                    capacity: String(usage.totalCapacity),
                    capacityUsed: String(usage.usedCapacity)
                }
            );
        }

        return contingentUsage;
    };

    private isSelected = (elem: string | number) => {
        return this.overviewModel.selected[elem];
    };

    private _uiSettingsDataLoading = () => {
        return this.overviewServiceData.overviewUiSettings.loadDataOnInit;
    };

    private _getServiceLoadedCounter = (): number => {
        if (!this._issetServiceLoadedCounter()) {
            this._setServiceLoadedCounter();
        }
        let serviceCounter: number;
        this._overviewLoaded.some((service) => {
            if (service.serviceName.toLowerCase() === this.overviewModel.objectType.toLowerCase()) {
                serviceCounter = service.counter;
                return true;
            }
            return false;
        });
        return serviceCounter;
    };

    private _setServiceLoadedCounter = (): void => {
        if (!this._issetServiceLoadedCounter()) {
            this._overviewLoaded.push({serviceName: this.overviewModel.objectType, counter: 0});
            return;
        }

        this._overviewLoaded.some((service) => {
            if (service.serviceName.toLowerCase() === this.overviewModel.objectType.toLowerCase()) {
                service.counter++;
                return true;
            }
            return false;
        });
    };

    private _issetServiceLoadedCounter = () => {
        return this._overviewLoaded.some((service) => {
            if (service.serviceName !== undefined) {
                return service.serviceName.toLowerCase() === this.overviewModel.objectType.toLowerCase();
            }
            return;
        });
    };
}

export class OrganismOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        accountId: '<',
        bundle: '<',
        disableFilter: '<',
        expandWrapper: '<',
        expertView: '<',
        hideTopBar: '<?',
        overviewModel: '<',
        overviewServiceData: '<',
        reloadStateOnRefresh: '<',
        webspace: '<'
    };

    public controller =  OrganismOverviewController;
    public controllerAs = 'OverviewCtrl';
    public template = require('./overview.html');
}
