/* eslint-disable */

import * as ng from 'angular';
import { OverviewDataObject } from '../../../../../../organisms';
import {
    AsteriskNoteType,
    AuthContextService,
} from '@/services/';

export class MoleculeOverviewPricelistRowPanelSslController implements ng.IController {
    public static $inject: string[] = [];

    public overviewData: OverviewDataObject;
    public vatAsteriskNoteType: AsteriskNoteType = AsteriskNoteType.VAT_NONUNIFORM;

    public getVatHint(product: any, cycle: string) {
        if (
            product.prices[cycle]?.amounts?.length > 1
            && product.prices[cycle]?.grossAmount > 0
            && !AuthContextService.account.isCommercialCustomer
        ) {
            return true;
        }
        return false;
    }

    public starsText = {
        1: 0,
        2: 0
    };

    public getValidationType = (product: any): string => {
        return product.validationType;
    };

    public addInfoStars = (product: any) => {
        const parts = product.productCodeTemplate.split('-');
        if (parts.includes('san')) {
            this.starsText[1]++;
            return product.productName + '*';
        } else if (parts.includes('additional')) {
            this.starsText[2]++;
            return product.productName + '**';
        }

        return product.productName;
    };

    private nonEmpty = (value: any) => {
        return [undefined, null, ''].indexOf(value) < 0;
    };
}

export class MoleculeOverviewPricelistRowPanelSslComponent implements ng.IComponentOptions {
    public require = {
        organismPricelistOverview: '^organismPricelistOverview'
    };
    public bindings = {
        overviewData: '<'
    };
    public controller =  MoleculeOverviewPricelistRowPanelSslController;
    public controllerAs = 'OverviewPricelistCtrl';
    public template = require('./pricelist-ssl-row.html'); // tslint:disable-line:max-line-length
}
