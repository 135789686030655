import ng from 'angular';
import { ContingentType } from '@/atomic-components';
import * as Configurations from '@/configuration';
import {
    AuthContextService,
    WebhostingHelperService
} from '@/services/index';
import { VhostModelService } from '@/services/webhosting/vhost-model';
import { ProductApi, ViewTypes } from '@/types';

export class DomainComposerService {
    public static $inject: string[] = [
        '$translate',
        'vhostModel',
        'webhostingHelper'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private vhostModel: VhostModelService,
        private webhostingHelper: WebhostingHelperService
    ) {}

    public buildDomainApiObject = (
        metadata: ViewTypes.ProductConfigDomainDomainObject
    ): Promise<ViewTypes.WizardComposerObject> => {
        const promises = [];
        const composerObject: ViewTypes.WizardComposerObject = {
            apiObject: {},
            summaryObject: []
        };

        for (const domain of metadata.selectedDomains) {
            promises.push(this._getDomainCreateData(metadata, domain));
        }

        return Promise.all(promises).then(
            (results) => {
                let apiObject: ViewTypes.DomainWizardApiObject;
                try {
                    const newWebspace = metadata.createWebspace
                        ? this._setWebspaceCreateObject(metadata)
                        : null;
                    apiObject = {
                        account: metadata.account,
                        bundle: metadata.bundle,
                        createWebspace: metadata.createWebspace,
                        dns: metadata.dns,
                        domainType: metadata.domainType,
                        domains: [],
                        phpIniDefaults: metadata.phpIniDefaults,
                        selectedContingent: metadata.selectedContingent,
                        webspace: metadata.webspace || newWebspace
                    } as ViewTypes.DomainWizardApiObject;

                    for (const apiObjectResult of results) {
                        apiObject.domains.push(apiObjectResult);
                        const sheetFacts: ViewTypes.WizardSummaryViewDataObject[] = [];
                        sheetFacts.push({
                            description: '',
                            label: this.$translate.instant('fe88028d-a019-42b8-b958-06b99fc9acf6'),
                            type: 'stringArray',
                            value: apiObjectResult.domainOrderObject.nameservers.map((ns) => ns.name)
                        });

                        if (metadata.createWebspace) {
                            sheetFacts.push({
                                description: '',
                                label: this.$translate.instant('4eb55a6b-58cc-4c6e-9086-81556fbb5d67'),
                                type: 'string',
                                value: newWebspace.webspace.name
                            });
                        }

                        const productObject = {
                            addGracePeriod: apiObjectResult?.priceObject?.addGracePeriod,
                            billingCycle: Configurations.BillingCycleToContractPeriodeConst[
                                apiObjectResult?.priceObject?.contractPeriod as Configurations.ContractPeriodWord
                            ],
                            language: Configurations.UiLanguagesConst[AuthContextService.user.language],
                            name: apiObjectResult.domainOrderObject.name,
                            productCode: apiObjectResult.productCode,
                            type: 'Product'
                        } as ProductApi.Product;

                        // Set bundleContingent to show price component
                        const bundleContingents = [];
                        if ([undefined, null, ''].indexOf(apiObjectResult.domainOrderObject.bundleId) < 0) {
                            bundleContingents.push({
                                capacity: this._getContingentAvailableCapacity(
                                    metadata.selectedContingent?.misc?.bundle?.effectiveContingentUsage,
                                    apiObjectResult.productCode
                                ),
                                codeStartsWith: 'domain',
                                productCodes: this._getContingentAvailableProductCodes(
                                    metadata.selectedContingent?.misc?.bundle?.effectiveContingentUsage,
                                    apiObjectResult.productCode
                                ),
                                service: 'domain'
                            });
                        }

                        composerObject.summaryObject.push({
                            account: metadata.account,
                            bundleContingents: bundleContingents,
                            domainName: apiObjectResult.domainOrderObject.name,
                            factSheets: sheetFacts,
                            product: productObject,
                            productCode: productObject.productCode
                        });
                    }
                } catch (err) {
                    console.error(err);
                    // Perhaps send error to sentry
                    return;
                }

                composerObject.apiObject = apiObject;
                return composerObject;
            }
        );
    };

    public buildVhostApiObject = async (
        metadata: ViewTypes.ProductConfigDomainVhostObject
    ): Promise<ViewTypes.WizardComposerObject> => {
        if (metadata.vhost && !metadata.vhost?.webspaceId) {
            // safe is safe ;-)
            metadata.vhost.webspaceId = metadata.webspace.id;
        }

        const phpVersion = await this.vhostModel.defaultPhpVersion();

        return this.webhostingHelper
            .getDefaultPhpIniSet(metadata.vhost.webspaceId, phpVersion, false)
            .then(
                (phpIniDefaults) => {
                    return {
                        apiObject: {
                            accountId: metadata.account.id,
                            dns: metadata.dns,
                            dnsZone: metadata.zoneUpdateData[metadata.vhost.domainNameUnicode]?.update
                                ? metadata.zoneUpdateData[metadata.vhost.domainNameUnicode].zone
                                : undefined,
                            domainType: metadata.domainType,
                            phpIniDefaults: phpIniDefaults,
                            vhost: metadata.vhost,
                            webspace: metadata.webspace,
                            updateRecords: metadata.zoneUpdateData[metadata.vhost.domainNameUnicode]?.update,
                            zoneRecordsToUpdate: metadata.zoneUpdateData[metadata.vhost.domainNameUnicode]?.records
                        }
                    } as Types.ViewTypes.WizardComposerObject;
                }
            );
    };

    public buildRedirectionApiObject = (
        metadata: ViewTypes.ProductConfigDomainRedirectionObject
    ): ViewTypes.WizardComposerObject => {
        const composerObject: ViewTypes.WizardComposerObject = {
            apiObject: {},
            summaryObject: []
        };

        // Remove commas from the domain-name-list.
        metadata.domainName = metadata.domainName.split(/,|\n/)
            .filter((domain) => (/\w/.exec(domain)) !== null)
            .join(',\n');

        composerObject.apiObject = {
            redirection: {
                domainName: metadata.domainName,
                redirectionUrl: metadata.redirectionUrl,
                type: metadata.type
            },
            ownerAccountId: metadata.account.id,
            adjustZone: metadata.adjustZone
        };

        if (metadata.type === 'frame') {
            composerObject.apiObject.redirection.title = metadata.title;
            composerObject.apiObject.redirection.metaTags = [
                { content: metadata.metaTagAuthor, name: 'author' },
                { content: metadata.metaTagKeywords, name: 'keywords' },
                { content: metadata.metaTagDescription, name: 'description' }
            ];
        }

        const totalDomains = metadata.domainName.split(',').length;

        composerObject.summaryObject.push({
            account: metadata.account,
            productCode: 'webhosting-redirection',
            factSheets: [
                {
                    label: this.$translate.instant('TR_110219-fc0ae3_TR'),
                    value: this.$translate.instant('TR_100119-688348_TR'),
                    description: null,
                    type: 'string'
                },
                {
                    label: this.$translate.instant('7ea53445-8800-4172-9008-fa68668ea806'),
                    value: metadata.domainName,
                    description: null,
                    type: 'string'
                },
                {
                    label: this.$translate.instant('TR_070519-16189c_TR'),
                    value: metadata.type,
                    description: null,
                    type: 'string'
                },
                {
                    label: this.$translate.instant('abb61689-d9e2-4ad9-a40d-8d7eeb18f078'),
                    value: metadata.redirectionUrl,
                    description: null,
                    type: 'string'
                }
            ],
            product: {
                name: 'webhosting-redirection',
                nameType: 'productCode',
                shortDescription: ''
            },
            priceMultiplication: totalDomains
        });

        if (metadata.type === 'frame') {
            composerObject.summaryObject[0]?.factSheets.push({
                label: this.$translate.instant('TR_261020-992ba0_TR'),
                description: [
                    this.$translate.instant('TR_261020-2c841c_TR', { title: metadata.title }),
                    this.$translate.instant('TR_261020-1e5153_TR', { author: metadata.metaTagAuthor }),
                    this.$translate.instant('TR_261020-b8ce63_TR', { description: metadata.metaTagDescription }),
                    this.$translate.instant('TR_261020-9ea8c8_TR', { keywords: metadata.metaTagKeywords })
                ],
                value: null,
                type: 'stringArray'
            });
        }

        return composerObject;
    };

    private _getContingentAvailableCapacity = (
        bundleEffectiveContingentUsage: any,
        productCode: string
    ): number => {
        if (!bundleEffectiveContingentUsage) {
            return 0;
        }

        let availableCapacity = 0;
        for (const usage of bundleEffectiveContingentUsage) {
            const productCodeFound = usage.productCodes.some((usageProductCode: string) => {
                if (usageProductCode === productCode) {
                    availableCapacity = usage.availableCapacity;
                    return true;
                }
                return false;
            });

            if (productCodeFound) {
                break;
            }
        }
        return availableCapacity;
    };

    private _getContingentAvailableProductCodes = (
        bundleEffectiveContingentUsage: any,
        productCode: string
    ): string[] => {
        if (!bundleEffectiveContingentUsage) {
            return [];
        }

        let availableProductCodes: string[] = [];
        for (const usage of bundleEffectiveContingentUsage) {
            const productCodeFound = usage.productCodes.some(
                (usageProductCode: string) => usageProductCode === productCode
            );

            if (productCodeFound) {
                availableProductCodes = usage.productCodes;
                break;
            }
        }
        return availableProductCodes;
    };

    private _getDomainCreateData = (
        metadata: ViewTypes.ProductConfigDomainDomainObject,
        domain: ViewTypes.WizardSelectedDomainObject
    ): Promise<ViewTypes.DomainCreateData> => {
        const bundleId = (
            metadata.selectedContingent
            && metadata.selectedContingent.type === ContingentType.bundle
            && domain.inBundle
        )
            ? metadata.selectedContingent.id                    // Contingent is selected
            : domain.inBundle ? metadata?.bundle?.id : null;    // Create domain in bundle or single domain

        const issetTransferOwnerHandling = domain.domainObject.domain.transferOwnerHandling === 'sync';

        const domainCreateData = {
            authCode: domain.domainObject.domain.authCode,
            domainOrderObject: {
                accountId: metadata.account.id,
                bundleId: bundleId,
                contacts: metadata.contacts
                    .filter((handle) => !(issetTransferOwnerHandling && 'owner' === handle.contactType))
                    .map((handle: ViewTypes.DomainContactObject) => ({
                        contact: handle.contactId,
                        type: handle.contactType
                    })
                ),
                name: domain.domainName,
                nameserverSetId: null,
                nameservers: [],
                nameserverType: metadata.nameserver.values?.type,
                transferLockEnabled: true
            },
            vhost: metadata.vhost,
            vhostExists: metadata.vhostExists,
            createZone: metadata.zoneUpdateData[domain.domainName]?.create,
            dnsZone: metadata.zoneUpdateData[domain.domainName]?.zone,
            updateRecords: metadata.zoneUpdateData[domain.domainName]?.update,
            zoneRecordsToUpdate: metadata.zoneUpdateData[domain.domainName]?.records,
            // todo: remove in other components
            // domainZoneLists: metadata.domainZoneLists as Types.ViewTypes.WizardDomainZoneListObject,
            orderType: domain.status,
            priceObject: domain.price,
            productCode: domain.domainObject.productCode
        } as unknown as ViewTypes.DomainCreateData;

        const nameserverObject = this._setNameserverList(metadata.nameserver.values);
        switch (true) {
            // Own nameserver are given - no zone action is needed
            default:
            case metadata.nameserver.values.nameservers === null:
                // tslint:disable-next-line:max-line-length
                domainCreateData.domainOrderObject.nameservers = metadata.nameserver.ownNameservers.filter(
                    (nameserver) => ({ name: nameserver.name, ips: nameserver.ips})
                );
                domainCreateData.domainOrderObject.nameserverType = 'external';
                break;
            // External Nameserverset is given - no dns action is needed
            case metadata.nameserver.values?.type === 'external':
                domainCreateData.domainOrderObject.nameservers = nameserverObject.nameservers;
                domainCreateData.domainOrderObject.nameserverSetId = nameserverObject.nameserverId;
                break;
            case metadata.nameserver.values?.type === 'system':
                domainCreateData.domainOrderObject.nameservers = nameserverObject.nameservers;
                domainCreateData.domainOrderObject.nameserverSetId = nameserverObject.nameserverId;
                break;
        }

        return Promise.resolve(domainCreateData);
    };

    private _setWebspaceCreateObject = (
        metadata: ViewTypes.ProductConfigDomainDomainObject
    ): ViewTypes.WebspaceCreateObject => {
        return {
            accesses: [],
            poolId: metadata.webserverRessource.poolId,
            webserverId: metadata.webserverRessource.id,
            webspace: {
                accountId: metadata.account.id,
                name: 'Webspace: ' + Math.random().toString(36).slice(-6).toUpperCase(), // Someon has a better idea??
                productCode: Configurations.ManagedServerProjectProductCodes.webspace,
                storageQuota: 0
            }
        };
    };

    private _setNameserverList = (
        dataValues: ViewTypes.DataValues
    ): ViewTypes.NameserverListValues => {
        let nameservers = ng.copy(dataValues.nameservers);

        if (!nameservers) {
            return;
        }

        nameservers = nameservers.map(
            (nameserver) => ({
                name: nameserver.name,
                ips: nameserver.ipsRequired
                    ? nameserver.ips
                    : []
            })
        );

        return {
            nameserverId: dataValues.id,
            nameservers: nameservers
        };
    };
}
