import * as ng from 'angular';
import { AuthContextService } from '../../../../../../services';
import { OrganismOverviewController, OverviewDataObject } from '../../../../../organisms/overview/overview';

export class MoleculeOverviewRowPanelApiKeysController implements ng.IController {
    public static $inject: string[] = [ '$state', 'authContext' ];

    public overviewData: OverviewDataObject;
    public overviewLoad: (selected: any) => any;
    public internalPaginationLimit: number;
    public OrganismOverview: OrganismOverviewController;
    public userId: string = this.authContext.user.id;

    constructor(
        private $state: ng.ui.IStateService,
        private authContext: AuthContextService
    ) {}

    public allowedIpsDisplay = (allowedIps: string[]) => {
        let reply = '';
        for (const ip of allowedIps) {
            reply += ip + ', ';
        }
        return reply === '' ? '*' : reply.slice (0, reply.length - 2);
    };

    public get getRoute() {
        if (this.$state.current.name === 'profile') {
            return 'profile.apikeys.id.edit';
        } else if (this.$state.current.name === 'reseller.subaccounts.id.users.id.edit') {
            return 'reseller.subaccounts.id.users.id.apikeys.id.edit';
        }
        return 'account.users.id.apikeys.id.edit';
    }
}

export class MoleculeOverviewRowPanelApiKeysComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        internalPaginationLimit: '<',
        overviewData: '<',
        overviewLoad: '<'
    };
    public controller =  MoleculeOverviewRowPanelApiKeysController;
    public template = require('./api-keys-row.html');
}
