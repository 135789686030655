import * as ng from 'angular';

import { AlphaNumericCaseInsensitive } from '@/configuration';
import {
    ValidateAllowedCharacters, ValidateMaxLength, ValidateMinLength, ValidateNotEmpty
} from '@/services';
import { ViewTypes } from '../../../../../types';

export class OrganCreateConfigurationMachineOsUserController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        '$translate'
    ];

    public metadata: ViewTypes.ProductConfigMachineObject;
    public userNameValidationInstructions: {}[];

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.userNameValidationInstructions = [
            {
                settings: null,
                validator: new ValidateMinLength(this.$translate, 4)
            },
            {
                settings: null,
                validator: new ValidateMaxLength(this.$translate, 12)
            },
            {
                settings: null,
                validator: new ValidateNotEmpty(this.$translate)
            },
            {
                settings: null,
                validator: new ValidateAllowedCharacters(AlphaNumericCaseInsensitive, this.$translate)
            }
        ];
    }

    get extraUserRequiredForInstallation(): boolean {
        return /ubuntu/.test(this.metadata.virtualMachine.os.id);
    }

    public set completed({}) {} // tslint:disable-line:no-empty
}

export class OrganCreateConfigurationMachineOsUserComponent implements ng.IComponentOptions {
    public bindings = {
        metadata: '='
    };

    public template = require('./machine-os-user-config.html');
    public controller =  OrganCreateConfigurationMachineOsUserController;
}
