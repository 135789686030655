import { LongDebounceInterval } from '@/configuration';
import { DnsTemplateModelService, WizardNewHelperService } from '@/services';
import * as ng from 'angular';
import * as Types from '../../../../../types';
import { AccountApi, ViewTypes } from '../../../../../types';

export class OrganismDnsWizardProductConfigZoneQuickController {
    public static $inject: string[] = [
        '$timeout',
        'wizardNewHelper'
    ];

    public longDebounceInterval = LongDebounceInterval;
    public metadata: ViewTypes.ProductConfigDnsObject;
    public service = 'dns';
    public nameserverDropdownItems: any[];
    public dnsRecordsPreview: any[];
    public nameserversComplete = false;
    public formCompleted = {
        // tslint:disable:no-empty
        fqdn: () => [null, undefined].indexOf(this.metadata.fqdnList) < 0 && this.metadata.fqdnList.length > 0,
        ipAddress: () => [null, undefined].indexOf(this.metadata.ipAddress) < 0,
        nsSettings: () => {
            return this.metadata.nameserver.values.default
                || (
                    this.metadata.nameserver.ownNameserversComplete
                    && this.metadata.nameserver.ownNameservers.length >= 2
                )
                || [undefined, null, ''].indexOf(this.metadata.nameserver.values.id) < 0;
        }
        // tslint:enable:no-empty
    };

    private _account: Types.AccountApi.Account | Types.AccountApi.Subaccount;

    constructor(
        private $timeout: ng.ITimeoutService,
        private wizardNewHelper: WizardNewHelperService
    ) {}

    public $onInit = () => {
        this._setMetadata();
        this._setNameserverSets();
    };

    public get ownNameserversComplete(): boolean {
        return this.metadata.nameserver.ownNameserversComplete;
    }
    public set ownNameserversComplete(value: boolean) {
        if (
            this.metadata.nameserver.ownNameserversComplete !== value
            && value
        ) {
            this.setRecords();
        }

        this.metadata.nameserver.ownNameserversComplete = value;
    }

    public get account(): AccountApi.Account | AccountApi.Subaccount {
        return this._account;
    }
    public set account(value: AccountApi.Account | AccountApi.Subaccount) {
        if (value && this.metadata && this.metadata.account) {
            this.metadata.account = value;
        }

        this._account = value;
    }

    public set configCompleted({}) {} // tslint:disable-line:no-empty
    public get configCompleted() {
        let completed = true;

        for (const part in this.formCompleted) {
            if (!this.formCompleted[part]()) {
                completed = false;
            }
        }

        return completed;
    }

    public onNameserverOptionChange = (selection: any) => {
        this.$timeout(() => {
            this.setRecords();
        });
    };

    public setRecords = () => {
        this.$timeout(() => {
            let nsList = [];

            if ([null, undefined].indexOf(this.metadata.nameserver.values.nameservers) < 0) {
                nsList = this.metadata.nameserver.values.nameservers;
            } else if ([null, undefined].indexOf(this.metadata.nameserver.ownNameservers) < 0) {
                nsList = this.metadata.nameserver.ownNameservers;
            }

            this.metadata.records = [{
                content: this._getServerIp(),
                name: '',
                ttl: 86400,
                type: 'A'
            }];

            if (this.metadata.settings.wwwRecord) {
                this.metadata.records.push({
                    content: this._getServerIp(),
                    name: 'www',
                    ttl: 86400,
                    type: 'A'
                });
            }

            if (this.metadata.settings.starRecord) {
                this.metadata.records.push({
                    content: this._getServerIp(),
                    name: '*',
                    ttl: 86400,
                    type: 'A'
                });
            }

            for (const record of nsList) {
                this.metadata.records.push({
                    content: record.name,
                    name: '',
                    ttl: 86400,
                    type: 'NS'
                });
            }

            this.metadata.records.push({
                content: 'mail.' + this.metadata.fqdnList[0],
                name: '',
                priority: 10,
                ttl: 86400,
                type: 'MX'
            });

            this.metadata.records.push({
                content: this._getServerIp(),
                name: 'mail',
                ttl: 86400,
                type: 'A'
            });
        });
    };

    private _getServerIp = () => {
        const serverIp = this.metadata.ipAddress;
        return [undefined, null].indexOf(serverIp) < 0 ? serverIp : '';
    };

    private _setNameserverSets = () => {
        this.wizardNewHelper.getNameserverSets(this.metadata.account.id)
            .then((nameserverSets) => {
                this.metadata.nameserver.values = this.wizardNewHelper.objectCache.nameserSets[0].items[0].value;
                this.nameserverDropdownItems = nameserverSets;
            });
    };

    private _setMetadata = () => {
        this.metadata = {
            account: this._account,
            fqdnList: [],
            ipAddress: undefined,
            productCode: undefined,
            productFamily: undefined,
            records: [],
            nameserver: {
                ownNameservers: [],
                ownNameserversComplete: false,
                values: {
                    default: false,
                    id: null,
                    nameservers: null,
                    type: null
                }
            },
            settings: {
                wwwRecord: false,
                starRecord: false
            }
        };
    };
}

export class OrganismDnsWizardProductConfigZoneQuickComponent implements ng.IComponentOptions {
    public bindings = {
        account: '<account',
        configCompleted: '=configCompleted',
        metadata: '='
    };
    public template = require('./dns-wizard-product-config-zone-quick.html');
    public controller = OrganismDnsWizardProductConfigZoneQuickController;
}
