import * as ng from 'angular';
import { DomainContactHelperService, SystemHelperService } from '../../../../../services/helpers';

export class OrganEditPanelDomainContactDataController {
    public static $inject: string[] = ['domainContactHelper', 'systemHelper'];

    public $editFormMolecule;
    public $organismCtrl;
    public contact;
    public panelRight;
    public canadianLegalForms: any[] = [];
    public britishLegalForms: any[] = [];

    private _contactOrig;
    private _contactPartParams = [
        'extAeroIdentificationNumber',
        'extAeroPassword',
        'extCaLegalType',
        'extCatIntendedUsage',
        'extNationality',
        'extTravelUniqueIdentificationNumber',
        'extUkType',
        'extXxxMemberId'
    ];

    constructor(
        public domainContactHelper: DomainContactHelperService,
        private systemHelper: SystemHelperService
    ) {}

    public $onInit() {
        if (this.contact !== undefined && [undefined, null].indexOf(this.contact.type) >= 0) {
            this.contact.type = 'person';
        }
        this._contactOrig = ng.copy(this.contact);
        this.canadianLegalForms = [
            {value: 'CCO', name: 'Corporation (Canada or Canadian province or territory)'},
            {value: 'CCT', name: 'Canadian citizen'},
            {value: 'RES', name: 'Permanent Resident of Canada'},
            {value: 'GOV', name: 'Government or government entity in Canada'},
            {value: 'EDU', name: 'Canadian Educational Institution'},
            {value: 'ASS', name: 'Canadian Unincorporated Association'},
            {value: 'HOP', name: 'Canadian Hospital'},
            {value: 'PRT', name: 'Partnership Registered in Canada'},
            {value: 'TDM', name: 'Trade-mark registered in Canada (by a non-Canadian owner)'},
            {value: 'TRD', name: 'Canadian Trade Union'},
            {value: 'PLT', name: 'Canadian Political Party'},
            {value: 'LAM', name: 'Canadian Library, Archive or Museum'},
            {value: 'TRS', name: 'Trust established in Canada'},
            {value: 'ABO', name: 'Aboriginal Peoples (individuals or groups) indigenous to Canada'},
            {value: 'INB', name: 'Indian Band recognized by the Indian Act of Canada'},
            {value: 'LGR', name: 'Legal Representative of a Canadian Citizen or Permanent Resident'},
            {value: 'OMK', name: 'Official mark registered in Canada'},
            {value: 'MAJ', name: 'Her Majesty the Queen'}
        ];

        this.britishLegalForms = [
            {value: 'LTD', name: 'LTD'},
            {value: 'PLC', name: 'PLC'},
            {value: 'PTNR', name: 'PTNR'},
            {value: 'STRA', name: 'STRA'},
            {value: 'LLP', name: 'LLP'},
            {value: 'IP', name: 'IP'},
            {value: 'IND', name: 'IND'},
            {value: 'SCH', name: 'SCH'},
            {value: 'RCHAR', name: 'RCHAR'},
            {value: 'GOV', name: 'GOV'},
            {value: 'CRC', name: 'CRC'},
            {value: 'STAT', name: 'STAT'},
            {value: 'OTHER', name: 'OTHER'},
            {value: 'FIND', name: 'FIND'},
            {value: 'FCORP', name: 'FCORP'},
            {value: 'FOTHER', name: 'FOTHER'}
        ];
    }

    public $onDestroy() {
        this.cancel();
    }

    public cancel = () => {
        this._contactPartParams.map((param) => this.contact[param] = ng.copy(this._contactOrig[param]));
    };

    public getCanadianLegalForms = () => {
        let canadianLegalForms;
        this.canadianLegalForms.some((form) => {
            if (form.value === this.contact.extCaLegalType) {
                canadianLegalForms = form.name;
                return true;
            }
            return false;
        });
        return canadianLegalForms;
    };

    public getBritishLegalForms = () => {
        let britishLegalForms;
        this.britishLegalForms.some((form) => {
            if (form.value === this.contact.extUkType) {
                britishLegalForms = form.name;
                return true;
            }
            return false;
        });
        return britishLegalForms;
    };
}
export class OrganEditPanelDomainContactDataComponent implements ng.IComponentOptions {
    public bindings = {
        $organismCtrl: '<organismCtrl',
        contact: '=',
        panelRight: '<'
    };
    public require = {
        $editFormMolecule: '^moleculeFormEdit'
    };
    public template = require('./domain-contact-data.html');
    public controller = OrganEditPanelDomainContactDataController;
    public controllerAs = '$editPanelOrgan';
}
