import * as ng from 'angular';
import { UiRights } from '../../../../../../configuration';
import { AuthContextService } from '../../../../../../services';
import {
    OrganismEditFormBackupsController
} from '../../../../../organisms/forms/edit-forms/backups/backups';
import { MoleculeFormEditController } from '../../../../forms/form-edit/form-edit';
import { MoleculePanelEditTableController } from '../../../panel-edit/table/panel-edit-table';

export class MoleculePanelEditRowTableBackupsController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public $panelEditTable: MoleculePanelEditTableController; // speicher button aktivieren
    public $organismEditForm: any;
    public $editForm: MoleculeFormEditController;
    public name: string;
    public showRestorationConfirmation = false;
    public hideAdditionalBackups = true;
    public backupSelectedForRestoration: any;

    private $organismEditFormBackups: OrganismEditFormBackupsController;
    private backupType: string;
    private service: string;
    private weekDayList: string[];

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {
        this.weekDayList = [
            this.$translate.instant('TR_090119-5628fc_TR'),
            this.$translate.instant('TR_090119-95868e_TR'),
            this.$translate.instant('TR_090119-742493_TR'),
            this.$translate.instant('TR_090119-44258f_TR'),
            this.$translate.instant('TR_110119-fb860b_TR'),
            this.$translate.instant('TR_090119-d36c66_TR'),
            this.$translate.instant('TR_090119-65e966_TR')
        ];
    }

    public $onInit() {
        this.getTemplate();
    }

    public get showRestoreButton() {
        switch (this.service) {
            case 'database':
                return AuthContextService.isGranted(UiRights.BACKUP_RESTORE_DATABASE);
            case 'webhosting':
                return AuthContextService.isGranted(UiRights.BACKUP_RESTORE_WEBSPACE);
            default:
                return true;
        }
    }

    public get backupList() {
        if (!this.$organismEditFormBackups.backups) {
            return [];
        }

        return this.$organismEditFormBackups.backups
        .filter((backup) => backup.type === this.backupType);
    }

    public openRestoreConfirmation = (backup: any) => {
        this.backupSelectedForRestoration = backup;
        this.showRestorationConfirmation = true;
    };

    public closeRestoreConfirmation = () => {
        this.backupSelectedForRestoration = undefined;
        this.showRestorationConfirmation = false;
    };

    public showAllBackupsToggle = () => {
        this.hideAdditionalBackups = !this.hideAdditionalBackups;
    };

    public restoreBackups = (backup: any) => {
        this.$organismEditFormBackups.restoreBackups(backup);
    };

    public getTemplate = () => require(`./backups-${this.backupType}.html?external`);

    public showWeekday(date) {
        // @ToDo: TRANSLATE!!!
        return this.$translate.instant('TR_170719-cdf3cd_TR', { dayOfWeek: this.weekDayList[new Date(date).getDay()] });
    }
}

export class MoleculePanelEditRowTableBackupsComponent implements ng.IComponentOptions {
    public require = {
        $editForm: '^moleculeFormEdit',
        $organismEditFormBackups: '^organismEditFormBackups',
        $panelEditTable: '^moleculePanelEditTable'
    };

    public bindings = {
        backupType: '@type',
        name: '@',
        service: '@'
    };

    public controller = MoleculePanelEditRowTableBackupsController;
    public controllerAs = '$PanelEditRowTableBackup';
    public template = require('./backups.html');
}
