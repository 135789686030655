import * as ng from 'angular';

export class MoleculeButtonRemoveController implements ng.IController {
    public static $inject: string[] = [];

    public disabled: boolean;
    public modifier = '';
    public remove: (attr?: any) => void;
    public removeAttribute: any;
}

export class MoleculeButtonRemoveComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        disabled: '<',
        modifier: '@?',
        remove: '<',
        removeAttribute: '<?'
    };
    public controller =  MoleculeButtonRemoveController;
    public template = require('./button-remove.html');
}
