/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-function */
import * as ng from 'angular';

import uniqBy from 'lodash.uniqby';

import { ValidationError } from '@/atomic-components';
import { UiRights } from '@/configuration';
import {
    AuthContextService,
    DomainModelService,
    VhostModelService
} from '@/services';
import { MailboxModelService } from '@/services/email/mailbox-model';
import { Finding, EmailApi } from '@/types';
import * as Types from '@/types';
import { ViewTypes } from '@/types/view-types';

type DomainListResponse = Types.DomainApi.Domain | Types.WebhostingApi.VHost;
interface ApiListResponse {
    data: unknown[];
    pagination: {
        currentPage: unknown;
        entries: unknown;
        limit: unknown;
    };
}

export class OrganismEmailWizardProductConfigCatchallController implements ng.IController {
    public static $inject: string[] = [
        '$stateParams',
        'domainModel',
        'mailboxModel',
        'vhostModel'
    ];

    public metadata: ViewTypes.ProductConfigCatchallObject;
    public showNoForwarderTargets: boolean;
    public formCompleted = {
        emailAddress:           () => [undefined, null, ''].indexOf(this.metadata?.mailbox.emailAddress) < 0,
        forwarderTarget:        () => [undefined, null, ''].indexOf(this.metadata?.mailbox.forwarderTarget) < 0,
        spamProfile:            () => [undefined, null].indexOf(this.metadata?.spamProfile) < 0
    };

    private catchAllDomainValidationErrors: ValidationError[] = []; // used in template
    private lastDomainSearchString: string;
    private targetEmails: Types.EmailApi.Mailbox[] = [];

    constructor(
        private $stateParams: ng.ui.IStateParamsService,
        private domainModel: DomainModelService,
        private mailboxModel: MailboxModelService,
        private vhostModel: VhostModelService

    ) {}

    public set configCompleted({}) {} // used by parent
    public get configCompleted(): boolean { // used by parent
        for (const part in this.formCompleted) {
            if (!(this.formCompleted as Record<string, () => boolean>)[part]()) {
                return false;
            }
        }

        return true;
    }

    public get showCatchallRedirect(): boolean {
        return this.formCompleted.emailAddress();
    }

    public get showSpamProtection(): boolean {
        return this.formCompleted.forwarderTarget() && this.showCatchallRedirect;
    }

    public get fakeCatchallDomainEmail(): string {
        if ([undefined, null, ''].indexOf(this.metadata.mailbox.emailAddress) < 0) {
            return `*@${this.metadata.mailbox.emailAddress}`;
        } else {
            return '';
        }
    }

    public $onInit = async (): Promise<void> => {
        const availableForwarderTargets = await this.listForwarderTargets();
        this.showNoForwarderTargets = availableForwarderTargets?.data?.length === 0;
    };

    public checkAvailableDomains = async (
        domainFilter: Finding.Filter,
        vhostFilter: Finding.Filter
    ): Promise<DomainListResponse[]> => {
        const allList: DomainListResponse[] = [];
        if (AuthContextService.isGrantedAll([UiRights.DOM_DOMAINS_LIST])) {
            const domainList = await this.domainModel.list(25, 1, domainFilter);
            allList.push(...domainList.data);
        }
        if (AuthContextService.isGrantedAll([UiRights.WEB_VHOST_LIST, UiRights.WEB_OBJECT_LIST])) {
            const vhostList = await this.vhostModel.list(25, 1, vhostFilter);
            allList.push(...vhostList.data);
        }

        return allList;
    };

    public createCatchallDomainDropdownItems = async (
        limit?: number,
        page?: number,
        filter?: Finding.Filter,
        sort?: string
    ): Promise<{data: Types.DomainApi.Domain[]}> => {
        const domainFilter = {
            subFilter: [
                {
                    field: 'accountId',
                    value: this.metadata.account.id
                },
                {
                    field: 'domainStatus',
                    value: 'active'
                }
            ] as Types.Finding.Filter[],
            subFilterConnective: 'AND'
        };

        if (filter) {
            domainFilter.subFilter.push(filter);
        }

        if ([undefined, null, ''].indexOf(this.$stateParams.bundleId) < 0) {
            domainFilter.subFilter.push({
                field: 'bundleId',
                value: this.$stateParams.bundleId
            });
        }

        if ([undefined, null, ''].indexOf(this.lastDomainSearchString) < 0) {
            domainFilter.subFilter.push(
                {
                    subFilter: [
                        {
                            field: 'accountId',
                            value: this.metadata.account.id
                        },
                        {
                            field: 'DomainNameUnicode',
                            value: `*${this.lastDomainSearchString}*`
                        },
                        {
                            field: 'DomainName',
                            value: `*${this.lastDomainSearchString}*`
                        }
                    ],
                    subFilterConnective: 'OR'
                }
            );
        }

        const vhostFilter = {
            subFilter: [
                {
                    field: 'accountId',
                    value: this.metadata.account.id
                },
                {
                    field: 'VHostStatus',
                    value: 'active'
                }
            ] as Types.Finding.Filter[],
            subFilterConnective: 'AND'
        };

        if (filter) {
            vhostFilter.subFilter.push({
                field: 'VHostDomainNameUnicode',
                value: `*${filter.value}*`
            });
        }

        const allDomains = await this.checkAvailableDomains(domainFilter, vhostFilter);

        const domainsUniq = uniqBy(allDomains.map(
            (domain) => {
                if ((domain as Types.WebhostingApi.VHost).domainNameUnicode) {
                    // eslint-disable-next-line max-len
                    (domain as Types.DomainApi.Domain).nameUnicode = (domain as Types.WebhostingApi.VHost).domainNameUnicode;
                }
                return domain;
            }),
            (entry) => (entry as Types.DomainApi.Domain).nameUnicode
        ) as Types.DomainApi.Domain[];

        return {
            data: domainsUniq.sort(
                (a, b) =>  a.nameUnicode.localeCompare(b.nameUnicode)
            )
        };
    };

    public listForwarderTargets = (
        limit?: unknown,
        page?: unknown,
        filters?: unknown,
        sort?: unknown
    ): PromiseLike<ApiListResponse> => {
        return this.mailboxModel.list(limit, page, filters, sort)
            .then(
                (result: ApiListResponse) => {
                    result.data = result.data.filter(
                        (mailbox: EmailApi.Forwarder | EmailApi.ImapMailbox) => {
                            if (
                                ['ExchangeMailbox', 'ImapMailbox'].indexOf(mailbox?.type) >= 0
                                && mailbox.forwarderTargets?.length === 0
                            ) {
                                this.targetEmails.push(mailbox);
                                return true;
                            }
                        }
                    );
                    return result;
                }
            );
    };
}

export class OrganismEmailWizardProductConfigCatchallComponent implements ng.IComponentOptions {
    public bindings = {
        configCompleted: '=configCompleted',
        metadata: '='
    };

    public template = require('./email-wizard-product-config-catchall.html');
    public controller = OrganismEmailWizardProductConfigCatchallController;
}
