import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels/panel-header/panel-header';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';

export class TemplateBillingPaymentOptionsController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;
    public userPanelRight;
    public token: string|undefined;
    public payerId: string|undefined;
    public paymentId: string|undefined;
    public action: string|undefined;
    public paymentServiceType: string;

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = () => {
        this.pageHeaderData = {
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: 'tasks-alt',
            panelTitle: this.$translate.instant('TR_090119-a8b028_TR')
        };

        if (
            AuthContextService.isDirectCustomer
            || (
                !AuthContextService.isRootOrCompanyAccount
                && AuthContextService.isGrantedAny([
                    UiRights.BIL_LIST_PRICES_DATABASE,
                    UiRights.BIL_LIST_PRICES_DNS,
                    UiRights.BIL_LIST_PRICES_DOMAIN,
                    UiRights.BIL_LIST_PRICES_EMAIL,
                    UiRights.BIL_LIST_PRICES_MACHINE,
                    UiRights.BIL_LIST_PRICES_SSL,
                    UiRights.BIL_LIST_PRICES_WEBHOSTING
                ])
            )
        ) {
            this.pageHeaderData.backwardLink = 'billing.dashboard';
            this.pageHeaderData.backwardText = this.$translate.instant('TR_090119-ebc8d5_TR');
        }

        this.userPanelRight = {
            pamentMethod: {
                editPanelButton: AuthContextService.isGranted(UiRights.DOM_DOMAINS_EDIT)
            },
            paymentRechargeDeposit: {
                editPanelButton: AuthContextService.isGranted(UiRights.BIL_DEPOSIT_FUND)
            },
            paymentRedeemVoucher: {
                editPanelButton: true
            }
        };
    };
}

export class TemplateBillingPaymentOptionsComponent implements ng.IComponentOptions {
    public bindings = {
        action: '@?',
        payerId: '<?',
        paymentId: '<?',
        paymentServiceType: '@?',
        token: '<?'
    };
    public controller = TemplateBillingPaymentOptionsController;
    public template = require('./payment-options-template.html');
}
