import * as ng from 'angular';
import {
    RightsTemplateOverviewModelService,
    UserOverviewModelService
} from '../../../services';

export class TemplateUserListController implements ng.IController {
    public static $inject: string[] = ['$translate', 'rightsTemplateOverviewModel', 'userOverviewModel'];

    public overviewServiceData;
    public expertView = false;

    public pageHeaderData = {
        backwardLink: 'account.dashboard',
        backwardText: this.$translate.instant('TR_080119-b2bbbd_TR'),
        panelHeaderRoute: 'account.users.overview',
        panelIcon: 'user',
        panelTitle: this.$translate.instant('TR_080619-b37734_TR')
    };

    private extendedFilters = null;
    private overviewUiSettings;

    constructor(
        private $translate: ng.translate.ITranslateService,
        public rightsTemplateOverviewModel: RightsTemplateOverviewModelService,
        public userOverviewModel: UserOverviewModelService
    ) {}

    public $onInit() {
        this.overviewServiceData = {
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: { value: '' }
        };
    }
}

export class TemplateUserListComponent implements ng.IComponentOptions {
    public bindings= {
        allRights: '<',
        editableRights: '<',
        overviewUiSettings: '<',
        rightsTemplates: '<',
        userData: '<'
    };
    public template = require('./user-list.html');
    public controller =  TemplateUserListController;
}
