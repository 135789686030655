import * as Types from '@/types';
import {
    GenericSetup,
    OrderItemDisplayLine,
    OrderItemStatus,
    GenericInjectedRequests,
    MaybeLoading,
} from '@/components/setup/setup';

type OrchestrationJob = Types.ViewTypes.OrchestrationJob;
type VirtualMachine = Types.MachineApi.VirtualMachine;
type APISingleResponse<T> = Types.UI.APISingleResponse<T>;

export type MachineAdditionalInjectedRequests = {
    loadVirtualMachine: (id: string) => Promise<VirtualMachine>,
    updateVirtualMachine: (vm: VirtualMachine) => Promise<APISingleResponse<VirtualMachine>>,
}

export type InjectedRequests = GenericInjectedRequests & MachineAdditionalInjectedRequests;

export class MachineSetup extends GenericSetup {

    // fetched async from API
    private item: MaybeLoading<VirtualMachine> = {state: 'waiting'};

    constructor(
        protected requests: InjectedRequests,
        private applyWrapper?: (fn: () => void) => void,
    ) {
        super();
        applyWrapper ??= (fn: () => void) => fn();
    }

    protected jobIsDone = (job: OrchestrationJob) => {
        // switch (job.objectType) {
        //     case 'VirtualMachine':
        //         if (this.item.state == 'waiting') {
        //             this.item = {state: 'loading'};
        //             this.requests.loadVirtualMachine(job.objectId).then(
        //                 (vm: VirtualMachine) => {
        //                     this.item = { state: 'loaded', data: vm };
        //                 },
        //             );
        //         }
        //         break;
        // }
    }

    protected postprocessOrderItemDisplayLines = () => {}

    protected orderItemDisplayLinesBeforeJobIsLoaded = (): OrderItemDisplayLine[] => {
        // Loading data, to avoid jumping element positions
        const lines: OrderItemDisplayLine[] = [];
        lines.push(new OrderItemDisplayLine('VirtualMachine', 'VirtualMachine', OrderItemStatus.PENDING));
        return lines;
    }
}
