import * as ng from 'angular';

import { AuthContextService } from '../../../../../services';

export class OrganViewPanelDataProcessingSuppliersController {
    public supplierInfo: any = {
        abcDomain: {
            city: 'Yerevan',
            country: 'Armenia',
            name: 'ABC Domain LLC',
            services: 'Domaindienstleistungen',
            street: '4 Hrachya Kochar str, "Barekamutyun" Business Center, 2nd floor'
        },
        ascioTechnologies: {
            city: 'Copenhagen',
            country: 'Denmark',
            name: 'Ascio Technologies, Inc. Danmark',
            services: 'Domaindienstleistungen',
            street: 'Ørestads Boulevard 108, 10. Sal.'
        },
        cityInkasso: {
            city: '65929 Frankfurt am Main',
            country: 'Deutschland',
            name: 'City Inkasso GmbH',
            services: 'Inkassodienstleistungen',
            street: 'Königsteiner Str. 22'
        },
        elbTunisie: {
            city: 'Tunis',
            country: 'TN.',
            name: 'ELB Tunisie',
            services: 'Domaindienstleistungen',
            street: 'Espace Tunis Bloc C app 4-1'
        },
        epagDomainservices: {
            city: '53133 Bonn',
            country: 'Deutschland',
            name: 'EPAG Domainservices GmbH',
            services: 'Domaindienstleistungen',
            street: 'Niebuhrstr. 16b'
        },
        hostingDe: {
            city: '52064 Aachen',
            country: 'Deutschland',
            name: 'hosting.de GmbH',
            services: 'Serverdienstleistungen',
            street: 'Franzstr. 51'
        },
        httpNet: {
            city: '52064 Aachen',
            country: 'Deutschland',
            name: 'http.net Internet GmbH',
            services: 'Domainregistrierungen, SSL-Zertifikate',
            street: 'Franzstr. 51'
        },
        internetInvest: {
            city: '01033 Kiev',
            country: 'Ukraine',
            name: 'Internet Invest Ltd (Imena.ua)',
            services: 'Domaindienstleistungen',
            street: 'Gaidara St. 50'
        },
        jscLaquo: {
            city: '123308 Moscow',
            country: 'Russian Federation',
            name: 'JSC «RU-CENTER»',
            services: 'Domaindienstleistungen',
            street: '2/1, 3d Khoroshevskaya str.'
        },
        keenlogics: {
            city: '52064 Aachen',
            country: 'Deutschland',
            name: 'keenlogics GmbH',
            services: 'Softwareentwicklung',
            street: 'Franzstr. 51'
        },
        aUndOFischer: {
            city: '21423 Winsen (Luhe)',
            country: 'Deutschland',
            name: 'A&O Fischer GmbH & Co. KG',
            services: 'Postversand',
            street: 'Maybachstraße 9'
        },
        movingDomains: {
            city: '52064 Aachen',
            country: 'Deutschland',
            name: 'Moving Domains GmbH',
            services: 'Domainregistrierungen, SSL-Zertifikate',
            street: 'Franzstr. 51'
        },
        movingInternet: {
            city: '52064 Aachen',
            country: 'Deutschland',
            name: 'moving internet GmbH',
            services: 'Management',
            street: 'Franzstr. 51'
        },
        openContact: {
            city: 'Minsk',
            country: 'Belarus',
            name: 'Open Contact Ltd.',
            services: 'Domaindienstleistungen',
            street: '17 Kalvariyskaya St., office 610a'
        },
        papakiInternetServices: {
            city: 'P.C. 71601 HERACLION CRETE',
            country: 'Greece',
            name: 'PAPAKI INTERNET SERVICES LTD',
            services: 'Domaindienstleistungen',
            street: 'INDUSTRIAL AREA - S STREET'
        },
        regicaNet: {
            city: '10000 Zagreb',
            country: 'Croatia',
            name: 'REGica.NET d.o.o',
            services: 'Domaindienstleistungen',
            street: 'Garićgradska 18'
        },
        securityDatabase: {
            city: '2034 PG Haarlem',
            country: 'The Netherlands',
            name: 'Security Database BV',
            services: 'Domaindienstleistungen',
            street: 'Engelandlaan 41'
        },
        siliciumNetwork: {
            city: '1137 Budapest',
            country: 'Hungary',
            name: 'Silicium Network Informatikai Kft.',
            services: 'Domaindienstleistungen',
            street: 'Radnóti Miklós utca 11. Fsz. 2.'
        },
        towebSarl: {
            city: 'L-1528 Luxembourg',
            name: 'Toweb Sarl',
            services: 'Domaindienstleistungen',
            street: '11-13 Boulevard de la Foire'
        },
        webglobe: {
            city: '821 09 Bratislava',
            country: 'Slovakia',
            name: 'Webglobe – Yegon, s.r.o.',
            services: 'Domaindienstleistungen',
            street: 'Stará Prievozská 2'
        }
    };

    public supplierLists = {
        hostingDe: [
            'movingInternet',
            'httpNet',
            'keenlogics',
            'aUndOFischer',
            'cityInkasso'
        ],
        httpNet: [
            'movingInternet',
            'movingDomains',
            'keenlogics',
            'hostingDe',
            'aUndOFischer',
            'cityInkasso',
            'abcDomain',
            'ascioTechnologies',
            'elbTunisie',
            'epagDomainservices',
            'internetInvest',
            'jscLaquo',
            'openContact',
            'papakiInternetServices',
            'regicaNet',
            'securityDatabase',
            'siliciumNetwork',
            'towebSarl',
            'webglobe'
        ],
        other: [
            'movingInternet',
            'httpNet',
            'hostingDe',
            'keenlogics',
            'aUndOFischer',
            'cityInkasso'
        ]
    };

    public get suppliers() {
        switch (AuthContextService.account.parentAccountId) {
            case '3': return this.supplierLists.httpNet;
            case '4': return this.supplierLists.hostingDe;
            default: return this.supplierLists.other;
        }
    }

}

export class OrganViewPanelDataProcessingSuppliersComponent implements ng.IComponentOptions {
    public controller = OrganViewPanelDataProcessingSuppliersController;
    public template = require('./suppliers.html');
}
